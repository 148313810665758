import { useCallback, useMemo } from 'react'

import { useNumberOfAddableOrders, useIsSimulation, useOrdersLimit } from '@/hooks'
import { useEditingStateWithCtx } from '@/atoms'

import { useTexts } from './useTexts'
import { useCanUnassign } from './useCanUnassign'

interface OrderAccumulator {
  unassignedOrderStepIds: string[]
  assignedOrderStepIds: string[]
}
export function useBulkViewActionsData(orders: uui.domain.client.rm.ExtendedOrderStep[]) {
  const { setEditing } = useEditingStateWithCtx('order')
  const addableOrders = useNumberOfAddableOrders()
  const isSimulation = useIsSimulation()
  const ordersLimit = useOrdersLimit()
  const texts = useTexts()

  const canUnassign = useCanUnassign(orders)

  const { unassignedOrderStepIds, assignedOrderStepIds } = useMemo(
    () =>
      orders.reduce<OrderAccumulator>(
        (acc, order) => {
          if (order.isUnassigned) {
            acc.unassignedOrderStepIds.push(order.id)
          } else {
            acc.assignedOrderStepIds.push(order.id)
          }

          return acc
        },
        {
          unassignedOrderStepIds: [],
          assignedOrderStepIds: [],
        },
      ),
    [orders],
  )

  const onStartEditing = useCallback(() => {
    setEditing(orders.map(o => o.id))
  }, [orders, setEditing])

  const duplicateDisabledTooltipTitle = texts.duplicateDisabledTooltipTitle(isSimulation)
  const duplicateDisabledTooltipSubtitle = texts.duplicateDisabledTooltipSubtitle(
    addableOrders,
    isSimulation,
    ordersLimit,
  )

  return {
    allUnassigned: unassignedOrderStepIds.length === orders.length,
    allAssigned: assignedOrderStepIds.length === orders.length,
    canExportOrders: addableOrders >= orders.length,
    canDuplicateOrders: addableOrders >= orders.length,
    duplicateDisabledTooltipSubtitle,
    duplicateDisabledTooltipTitle,
    unassignedOrderStepIds,
    assignedOrderStepIds,
    onStartEditing,
    isSimulation,
    ordersLimit,
    canUnassign,
    canUnassignTitle: texts.disabledForLockedRouteTooltipTitle,
  }
}
