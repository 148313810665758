import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function StopWatch(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M21 1v3h2v1.09375C11.80078 6.10547 3 15.54297 3 27c0 12.1328 9.8672 22 22 22s22-9.8672 22-22c0-11.45703-8.80078-20.89453-20-21.90625V4h2V1zm19.25 4.15625l-2.03125 2 4.625 4.625 2-2.03125zm-25.84375 10.25c.2539 0 .4922.08594.6875.28125L24.5 25.0625c.16406-.04297.3203-.0625.5-.0625 1.10547 0 2 .89453 2 2 0 .1797-.01953.33594-.0625.5l1.59375 1.625c.39063.39063.39063 1.01563 0 1.40625-.1953.1953-.46094.28125-.71875.28125-.2578 0-.4922-.08594-.6875-.28125L25.5 28.9375c-.16406.04297-.3203.0625-.5.0625-1.10547 0-2-.89453-2-2 0-.1797.01953-.33594.0625-.5l-9.375-9.40625c-.39063-.39063-.39063-1.01563 0-1.40625.1953-.1953.46484-.28125.71875-.28125z" />
      </svg>
    </IconContainer>
  )
}
