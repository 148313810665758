import type { FormFields, FormErrors, TimeRangeType } from '../../hooks/formFields'

import { useSelector } from 'react-redux'
import { RadioGroup, TimePicker } from '@workwave-tidal/tidal/form-ui'
import { Form } from '@workwave-tidal/tidal/form-ui/layout'

import { selectUserAccountPreferences } from '@/features/domain/user'

import { useControllerActions } from '../../hooks/useControllerActions'
import { useTexts } from '../../useTexts'

import { useTimeRangeOptions } from './hooks/useTimeRangeOptions'
import { useErrorLabels } from './hooks/useErrorLabels'
import { useTypeOptions } from './hooks/useTypeOptions'
import { useFormEffects } from './hooks/useFormEffects'

export function Body() {
  const { timeFormat } = useSelector(selectUserAccountPreferences)
  const timeRangeOptions = useTimeRangeOptions()
  const typeOptions = useTypeOptions()
  const errorLabels = useErrorLabels()
  const texts = useTexts()

  const { onConfirm } = useControllerActions()

  useFormEffects()

  const format = timeFormat === 'HH:mm' ? '24' : '12'

  return (
    <Form disableScroll sx={{ mt: 3 }} onEnter={onConfirm} fieldsSpacing={3}>
      <RadioGroup<'type', TimeRangeType, FormFields, FormErrors>
        validateOn="blur focus change"
        label={texts.trackingMode}
        options={typeOptions}
        name="type"
      />

      <TimePicker<'timeRangeStart', FormFields, FormErrors>
        optionValues={timeRangeOptions}
        label={texts.timeRangeStart}
        errorLabels={errorLabels}
        name="timeRangeStart"
        allowUnlistedValues
        format={format}
        // TODO: This is a temporary fix for the issue with the time picker not being able to be displayed
        componentsProps={{
          popper: {
            sx: {
              zIndex: 9999,
            },
          },
        }}
      />

      <TimePicker<'timeRangeEnd', FormFields, FormErrors>
        optionValues={timeRangeOptions}
        label={texts.timeRangeEnd}
        errorLabels={errorLabels}
        allowUnlistedValues
        format={format}
        name="timeRangeEnd"
        // TODO: This is a temporary fix for the issue with the time picker not being able to be displayed
        componentsProps={{
          popper: {
            sx: {
              zIndex: 9999,
            },
          },
        }}
      />
    </Form>
  )
}
