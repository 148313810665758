import type { LayerUID } from '../../../typings'

import { useEffect } from 'react'

import { getMap } from '../../../../atoms/map/utils/getMap'
import { findLayer } from '../../../utils/findLayer'
import { getInteraction } from '../../../../interactions/interactionMetadata'

export const useMapLayerInteractive = (uid: LayerUID, interactive: boolean) => {
  useEffect(() => {
    try {
      const layer = findLayer(getMap(), uid)

      if (interactive) {
        getInteraction('rm-mouse-single-click-interaction')?.enableLayer(layer)
        getInteraction('rm-mouse-double-click-interaction')?.enableLayer(layer)
        getInteraction('rm-mouse-move-interaction')?.enableLayer(layer)
      } else {
        getInteraction('rm-mouse-single-click-interaction')?.disableLayer(layer)
        getInteraction('rm-mouse-double-click-interaction')?.disableLayer(layer)
        getInteraction('rm-mouse-move-interaction')?.disableLayer(layer)
      }
    } catch (e) {}
  }, [uid, interactive])
}
