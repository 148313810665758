import { type GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useState,
  type MutableRefObject,
} from 'react'

type ModalsContext = {
  onShowAddCompanyModal: () => void
  onShowRenameCompanyModal: () => void
  onShowDisableCompanyModal: () => void
  onShowDeleteCompanyModal: () => void
  onCompanyAdded: (companyId: string) => void
  onSetCompanyId: (data: string) => void
  companyId?: string
  companyTableApiRef?: MutableRefObject<GridApiPro>
}

export const modalsContext = createContext<ModalsContext>({
  onShowAddCompanyModal: () => undefined,
  onShowRenameCompanyModal: () => undefined,
  onShowDisableCompanyModal: () => undefined,
  onShowDeleteCompanyModal: () => undefined,
  onSetCompanyId: () => undefined,
  onCompanyAdded: () => undefined,
  companyId: undefined,
  companyTableApiRef: undefined,
})

interface Props {
  onShowAddCompanyModal: () => void
  onShowRenameCompanyModal: () => void
  onShowDisableCompanyModal: () => void
  onShowDeleteCompanyModal: () => void
}

export function ModalsContextProvider(props: PropsWithChildren<Props>) {
  const {
    children,
    onShowAddCompanyModal,
    onShowRenameCompanyModal,
    onShowDisableCompanyModal,
    onShowDeleteCompanyModal,
  } = props
  const [companyId, onSetCompanyId] = useState<string | undefined>(() => undefined)
  const companyTableApiRef = useGridApiRef()

  const onCompanyAdded = (companyId: string) => {
    if (!companyTableApiRef.current) return

    const rowIndex = companyTableApiRef.current.getRowIndexRelativeToVisibleRows(companyId)
    if (rowIndex === -1) return

    companyTableApiRef.current.selectRow(companyId, true, true)
    companyTableApiRef.current.scrollToIndexes({ rowIndex })
  }

  const rApi = useRef({
    onShowAddCompanyModal,
    onShowRenameCompanyModal,
    onShowDisableCompanyModal,
    onShowDeleteCompanyModal,
    onCompanyAdded,
  })

  useEffect(() => {
    rApi.current.onShowAddCompanyModal = onShowAddCompanyModal
    rApi.current.onShowRenameCompanyModal = onShowRenameCompanyModal
    rApi.current.onShowDisableCompanyModal = onShowDisableCompanyModal
    rApi.current.onShowDeleteCompanyModal = onShowDeleteCompanyModal
    rApi.current.onCompanyAdded = onCompanyAdded
  })

  const context = useMemo<ModalsContext>(() => {
    return {
      onShowAddCompanyModal: () => {
        rApi.current.onShowAddCompanyModal()
      },
      onShowRenameCompanyModal: () => {
        rApi.current.onShowRenameCompanyModal()
      },
      onShowDisableCompanyModal: () => {
        rApi.current.onShowDisableCompanyModal()
      },
      onShowDeleteCompanyModal: () => {
        rApi.current.onShowDeleteCompanyModal()
      },
      onCompanyAdded: (companyId: string) => {
        rApi.current.onCompanyAdded(companyId)
      },
      onSetCompanyId,
      companyId,
      companyTableApiRef,
    }
  }, [companyId, companyTableApiRef])

  return <modalsContext.Provider value={context}>{children}</modalsContext.Provider>
}

export function useModalsContext() {
  return useContext(modalsContext)
}
