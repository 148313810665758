import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehicles } from '@/features/domain/vehicle'

// Retrieve the unified vehicle with the input deviceId
export function useRetrieveUnifiedVehicleFromDevice(deviceId: string) {
  const unifiedVehicles = useSelector(selectVehicles)

  return useMemo(() => {
    const uv = Object.values(unifiedVehicles).find(
      uv => uv.isPhysicalDevice && uv.device.deviceId === deviceId,
    )

    if (!uv) {
      throw new Error('Unified vehicle not found')
    }

    return uv
  }, [deviceId, unifiedVehicles])
}
