import { useCallback } from 'react'

import { useIsUnmounted, useNotification } from '@/hooks'
import { resetApiKey } from '@/features/domain/account'
import { useAppDispatch } from '@/store'

import { useController } from '../../../hooks/useController'
import { useTexts } from './useTexts'

export function useOnSubmit() {
  const { update, close } = useController()

  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const isUnmounted = useIsUnmounted()

  return useCallback(async () => {
    update({ status: 'submitting' })

    // if the component has been unmounted during the async operation stop here
    if (isUnmounted()) return

    try {
      const thunkResult = await dispatch(resetApiKey())

      if (!resetApiKey.fulfilled.match(thunkResult)) {
        throw new Error(thunkResult.payload?.message ?? 'Internal error')
      }

      toast.success(texts.resetApiKeySuccess, { autoClose: 5000 })
      update({ status: 'ready' })
    } catch (error) {
      toast.error(texts.resetApiKeyError, { autoClose: 5000 })
      update({ status: 'ready' })
    } finally {
      close?.()
    }
  }, [toast, texts, dispatch, isUnmounted, close, update])
}
