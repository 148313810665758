import type Geometry from 'ol/geom/Geometry'
import type { DrawEditableAreaInteraction } from '../../../interactions/EditableAreaInteraction'

import Feature from 'ol/Feature'

import { updateEditableArea } from '../../../atoms/editableArea/core/updateEditableArea'

export function createOnAreaCreate(drawArea: DrawEditableAreaInteraction) {
  /**
   * Save the Geometry of the created Area and set the Editable Area Store into `edit` mode.
   */
  return (points: uui.domain.LatLng[], feature: Feature<Geometry>) => {
    updateEditableArea(prev => {
      // force the feature ID to match the active Area ID
      feature.setId(prev.id)

      // disable DRAW interaction
      drawArea.setActive(false)

      // Update the Editable Area Store
      const id = prev.id
      const color = prev.mode !== 'none' ? prev.color : '#303030'
      return { mode: 'edit', id, points, color }
    })
  }
}
