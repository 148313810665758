import { Separator } from '@/components/Separator'

import { type GpsInfoWithColor } from '../../types'
import { CloseButton } from '../../../../elements/CloseButton'

import { StatusAndHeading } from './components/StatusAndHeading'
import { SpeedStop } from './components/SpeedStop'
import { Info } from './components/Info'

interface Props {
  currentPoint: GpsInfoWithColor
}
export function Overview(props: Props) {
  const { currentPoint } = props

  return (
    <>
      <Info point={currentPoint} />

      <Separator />

      <StatusAndHeading point={currentPoint} />

      <Separator />

      <SpeedStop point={currentPoint as uui.domain.client.gps.wwgps.GpsInfo} />

      <CloseButton />
    </>
  )
}
