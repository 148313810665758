import { WarningCard } from '@local/components'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function SuccessBody() {
  const {
    data: {
      values: { notificationWay },
    },
  } = useController()

  const texts = useTexts()

  if (!notificationWay) return null

  return (
    <WarningCard
      description={texts.getSuccessDescription(notificationWay)}
      title={texts.getSuccessTitle(notificationWay)}
      preset="success"
    />
  )
}
