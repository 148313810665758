import { useEffect } from 'react'
import * as Mousetrap from 'mousetrap'

type CB = (e: KeyboardEvent, combo: string) => any
const keyMap: Map<CB, string | string[]> = new Map()

export const useMouseTrap = (
  trigger: string | string[],
  callback: CB,
  preventDefault: boolean = true,
  keybindingsDisabled: boolean = false,
) => {
  useEffect(() => {
    if (keybindingsDisabled) {
      return
    }

    const cb = (mutableEvent: KeyboardEvent, combo: string) => {
      if (preventDefault) {
        // `returnValue` is needed by IE11
        mutableEvent.preventDefault
          ? mutableEvent.preventDefault()
          : (mutableEvent.returnValue = false)
      }

      callback(mutableEvent, combo)
    }
    Mousetrap.bind(trigger, cb)

    // map goes by callback rather than key bc of possible duplicates
    if (!keyMap.has(callback)) {
      keyMap.set(callback, trigger)
    }

    return () => {
      keyMap.delete(callback)
      Mousetrap.unbind(trigger)
    }
  }, [trigger, callback, preventDefault, keybindingsDisabled])
}
