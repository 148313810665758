import { ResourceModel } from '@bryntum/schedulerpro'

export function routeStartSorter(item1: ResourceModel, item2: ResourceModel) {
  const routeStart1 = item1.getData(
    'routeStart',
  ) as uui.domain.client.rm.SchedulerResource['routeStart']
  const routeStart2 = item2.getData(
    'routeStart',
  ) as uui.domain.client.rm.SchedulerResource['routeStart']

  if (!routeStart1?.current?.value && !routeStart2?.current?.value) return 0
  if (!routeStart1?.current?.value) return 1
  if (!routeStart2?.current?.value) return -1

  return routeStart1.current.value > routeStart2.current.value ? 1 : -1
}
