import type { Theme } from '@mui/material'
import type { GridPanelProps } from '@mui/x-data-grid-pro'

import { forwardRef } from 'react'
import { makeStyles } from '@mui/styles'
import { GridPanel, useGridApiRef } from '@mui/x-data-grid-pro'

export interface PanelProps extends GridPanelProps {}
type MyPanelStyleProps = {
  height: number | null
}

const useMyPanelStyles = makeStyles<Theme, MyPanelStyleProps>({
  paper: ({ height }) => ({
    maxHeight: height ? height : 450,
  }),
})

export const Panel = forwardRef<HTMLDivElement, PanelProps>(function MyPanel(props, ref) {
  const apiRef = useGridApiRef()

  const classes = useMyPanelStyles({
    height: apiRef?.current?.windowRef?.current?.offsetHeight ?? 0,
  })

  return (
    <GridPanel
      ref={ref}
      {...props}
      classes={{
        paper: classes.paper,
      }}
      placeholder={undefined}
    />
  )
})
