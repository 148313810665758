import { Fragment } from 'react'

interface Props {
  ids: number[]
}

type Row = [number, number | undefined, number | undefined]

/**
 * Render the passed codes in columns. The columns are sorted vertically.
 */
export function JiraLinks(props: Props) {
  const sortedCodes = props.ids.filter(onlyUnique).sort()

  const rows: Row[] = []
  const numberOfColumns = 3
  const numberOfRows = Math.ceil(sortedCodes.length / numberOfColumns)

  for (let i = 0; i < numberOfRows; i++) {
    rows.push([sortedCodes[i], sortedCodes[i + numberOfRows], sortedCodes[i + numberOfRows * 2]])
  }

  return (
    <>
      {rows.map((row, i) => {
        return (
          <Fragment key={`JiraLinksRow-${i}`}>
            {row[0] && (
              <>
                - <JiraLink id={row[0]} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </>
            )}
            {row[1] && (
              <>
                - <JiraLink id={row[1]} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </>
            )}
            {row[2] && (
              <>
                - <JiraLink id={row[2]} />
              </>
            )}
            <br />
          </Fragment>
        )
      })}
    </>
  )
}

/**
 * Remove duplicates from an array
 */
// see: https://stackoverflow.com/a/14438954
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

/**
 * Render a link to Jira
 */
function JiraLink(props: { id: number }) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`https://workwave.atlassian.net/browse/RM-${props.id}`}
    >
      RM-{props.id}
    </a>
  )
}
