import { Archived } from '@/icons'
import { FlexBox } from '@/local/components'

import { Container } from '../../elements/Container'
import { ArchivedControls } from './components/ArchivedControls'
import { useTexts } from './useTexts'

interface Props {
  userType: uui.domain.rm.RouteManagerUserType
}

export function FooterArchived(props: Props) {
  const { userType } = props
  const texts = useTexts()

  return (
    <Container color="$pureWhite" background="$nightRider">
      <FlexBox vAlignContent="center" grow={0} shrink={0} zIndex={10}>
        <Archived size={16} color="$pureWhite" marginRight={14} />

        {texts.archivedAlert}
      </FlexBox>

      <FlexBox grow={1} shrink={0} zIndex={10} marginRight="auto">
        <ArchivedControls userType={userType} />
      </FlexBox>
    </Container>
  )
}
