import { useSelector } from 'react-redux'

import { selectVehiclesListStructure } from '@/features/domain/lists'
import { useEditingStateWithCtx } from '@/atoms'

import { useSearchList } from './useSearchList'

export function useFilter() {
  const { editing } = useEditingStateWithCtx('vehicle')
  const listStructure = useSelector(selectVehiclesListStructure)
  const { query, setQuery } = useSearchList()

  return {
    query,
    editing,
    setQuery,
    noEntries: listStructure.totalItems === 0,
  }
}
