import type { ReactNode } from 'react'

import { makeStyles } from '@mui/styles'
import { HorizontalLayout } from '@/components/layout'

interface Props {
  children?: ReactNode
}

const useStyles = makeStyles({
  container: {
    '& > * + *': {
      marginLeft: 8,
    },
  },
})

export function ButtonsContainer(props: Props) {
  const classes = useStyles()
  return (
    <HorizontalLayout width="auto" className={classes.container}>
      {props.children}
    </HorizontalLayout>
  )
}
