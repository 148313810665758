import { useCallback } from 'react'
import { useIntl } from '@/intl'

export function useResetButtonTitle() {
  const { translate } = useIntl()

  return useCallback(
    (
      routesCount: number,
      dispatchedRoutesCount: number,
      canBeUpdated?: boolean,
      dispatched?: boolean,
    ) => {
      const disabled = !canBeUpdated || !dispatched

      if (disabled) {
        return translate({ id: 'dispatchPanel.tooltips.resetId.disabled' })
      }

      const diffCount = routesCount - dispatchedRoutesCount

      if (diffCount > 0) {
        return translate({
          id: 'dispatchPanel.tooltips.partial.resetId',
          values: { count: diffCount },
        })
      }

      return translate({ id: 'dispatchPanel.tooltips.resetId.enabled' })
    },
    [translate],
  )
}
