import { useMemo } from 'react'

import { useCrudSelection } from '@/atoms'

import { useEditing } from '../../../hooks/useEditing'

export const useViewType = () => {
  const { editingEntity } = useEditing('trafficArea')
  const [trafficAreaSelection] = useCrudSelection('trafficArea')

  return useMemo(() => {
    const noTrafficAreaSelected = trafficAreaSelection.length === 0

    if (noTrafficAreaSelected && editingEntity === 'traffic-area') {
      return 'create-traffic-area'
    }

    if (trafficAreaSelection.length === 1) {
      return editingEntity === 'traffic-area' ? 'single-edit' : 'single-view'
    }

    if (trafficAreaSelection.length > 1) {
      return 'bulk-view'
    }

    return 'none'
  }, [editingEntity, trafficAreaSelection])
}
