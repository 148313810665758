import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    twoDaysNotificationStatus: (time: string) =>
      translate({
        id: `sms4.settings.communication.notifications.twoDaysSettings.status`,
        values: { time },
      }),

    tomorrowNotificationStatus: (time: string) =>
      translate({
        id: `sms4.settings.communication.notifications.tomorrowSettings.status`,
        values: { time },
      }),

    todayNotificationStatus: (time: string) =>
      translate({
        id: `sms4.settings.communication.notifications.todaySettings.status`,
        values: { time },
      }),

    earlyNotificationStatus: (time?: string) => {
      if (time)
        return translate({
          id: `sms4.settings.communication.notifications.earlySettings.status`,
          values: { time },
        })

      return translate({
        id: `sms4.settings.communication.notifications.earlySettings.noTolerance`,
      })
    },

    delayedNotificationStatus: (time?: string) => {
      if (time)
        return translate({
          id: `sms4.settings.communication.notifications.delayedSettings.status`,
          values: { time },
        })

      return translate({
        id: `sms4.settings.communication.notifications.delayedSettings.noTolerance`,
      })
    },

    almostThereNotificationStatus: (time: string) => {
      return translate({
        id: `sms4.settings.communication.notifications.almostThereSettings.status`,
        values: { time },
      })
    },

    canceledNotificationStatus: translate({
      id: `sms4.settings.communication.notifications.canceledSettings.status`,
    }),

    notCompletedNotificationStatus: translate({
      id: `sms4.settings.communication.notifications.notCompletedSettings.status`,
    }),

    completedNotificationStatus: translate({
      id: `sms4.settings.communication.notifications.completedSettings.status`,
    }),

    notAvailable: translate({
      id: `sms4.settings.communication.notifications.generic.notAvailable`,
    }),

    disabled: translate({
      id: `sms4.settings.communication.notifications.generic.disabled`,
    }),

    notificationWay: (notificationWay: uui.domain.client.rm.NotificationWay) => {
      switch (notificationWay) {
        case 'EMAIL':
          return translate({ id: `sms4.settings.communication.notificationType.email` })
        case 'PHONE':
          return translate({ id: `sms4.settings.communication.notificationType.phone` })
      }
    },
  }))

  return api
}
