import type { MainSelectionCategory } from '../typings'

import { mainAtom, createDefaultMainSelection } from '../main'

export const resetAllMainSelection = (...skipCategories: MainSelectionCategory[]) => {
  if (skipCategories.length === 0) {
    // Object.assign is required otherwise Valtio won't update the atom
    Object.assign(mainAtom.selection, createDefaultMainSelection())
  } else {
    for (const key of Object.keys(mainAtom.selection)) {
      const category = key as MainSelectionCategory

      if (skipCategories.includes(category)) continue

      mainAtom.selection[category] = []
    }
  }

  return mainAtom.selection
}
