// see https://github.com/lukeed/clsx/blob/master/clsx.d.ts

export interface ClassArray extends Array<ClassValue> {}
export type ClassValue =
  | ClassArray
  | Record<string, any>
  | string
  | number
  | null
  | boolean
  | undefined

function toVal(mix: ClassValue) {
  let str = ''
  let y = ''

  if (mix) {
    if (typeof mix === 'object') {
      if (Array.isArray(mix)) {
        for (let k = 0; k < mix.length; k++) {
          if (mix[k] && (y = toVal(mix[k]))) {
            str && (str += ' ')
            str += y
          }
        }
      } else {
        for (const k in mix) {
          if (mix[k] && (y = toVal(k))) {
            str && (str += ' ')
            str += y
          }
        }
      }
    } else if (typeof mix !== 'boolean' && typeof mix !== 'function') {
      str && (str += ' ')
      str += mix
    }
  }

  return str
}

export function toggleCss(...classes: ClassValue[]): string {
  let i = 0
  let x = ''
  let str = ''

  while (i < classes.length) {
    if ((x = toVal(classes[i++]))) {
      str && (str += ' ')
      str += x
    }
  }
  return str
}
