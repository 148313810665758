import { useCallback } from 'react'

import { usePopupApi, useRoutingLayout } from '@/atoms'

export function useSetLayout() {
  const [{ layout }, setLayout] = useRoutingLayout()
  const { close: closePopup } = usePopupApi()

  const setToMap = useCallback(() => {
    closePopup()

    setLayout({ mode: 'map' })
  }, [closePopup, setLayout])

  const setToSplit = useCallback(() => {
    closePopup()

    setLayout({
      mode: 'split',
      bottomElement: layout.mode === 'scheduler' ? 'scheduler' : 'orders-grid',
    })
  }, [setLayout, closePopup, layout.mode])

  return {
    setToMap,
    setToSplit,
  }
}
