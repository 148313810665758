import styled, { keyframes } from 'styled-components'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

type Props = {
  iconPreset?: 'spinner' | 'squaredDepot' | 'pin' | 'search'
}

const fixAnimation = ({ iconPreset }: Props) => {
  if (iconPreset !== 'spinner') {
    return `animation: none;`
  }
  return ''
}

const grayIcons = ['pin', 'spinner', 'squaredDepot']

const LocationEditorIcon = styled.div<Props>`
  width: 16px;
  color: ${p =>
    p.iconPreset && grayIcons.includes(p.iconPreset)
      ? p.theme.colors.$silver
      : p.theme.colors.$scienceBlue};
  margin-right: 10px;

  animation: ${rotate360} 1s linear infinite;
  ${fixAnimation};
`

LocationEditorIcon.displayName = 'LocationEditorIcon'
export default LocationEditorIcon
