import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

export type Params = Partial<
  uui.domain.client.rm.Territory['notificationConfig']['delayedSettings']
>

/**
 * Update the Delayed notification config.
 */
export const updateDelayedNotificationConfig = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Params,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/territory/updateDelayedNotificationConfig', async (payload: Params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/territory/updateDelayedNotificationConfig', {
      category: 'rpc',
      type: 'rpc/territory/updateDelayedNotificationConfig',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {updateDelayedNotificationConfig}`,
      { tags: ['rpc', 'territory'], info: error },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Update of mobile app interaction failed`,
      error,
    })
  }
})
