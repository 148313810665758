import { Props, TagsListTexts } from './typings'

export default function defaultRenderText(
  field: TagsListTexts,
  _: Props,
  collapsed?: boolean,
  count?: number,
): string {
  switch (field) {
    case 'addTag':
      return 'Add Tag'
    case 'suggestedListTitle':
      return 'Suggested Tags'
    case 'searchPlaceholder':
      return 'Search Tag'
    case 'loadMoreTag':
      const hasCount = typeof count === 'number'
      if (hasCount) {
        return `… ${count} more`
      }
      return 'Load more'
    case 'suggestionsListMessage':
      return 'Select any Tags to Add'
    case 'noResult':
      return 'No results for this search'
    case 'maxCountReached':
      return 'Maximum number of tags reached'
    case 'noTags':
      return 'No Tags'
    case 'bulkTitle':
      return 'Shared by all orders'
    case 'addToAll':
      return 'Add to all'
    case 'tagsListMessage':
      return 'Select any Tags to remove it'
    case 'remove':
      const countText = typeof count === 'number' ? `(${count})` : ``
      return `Remove ${countText}`
    case 'toggleVisibility':
      return collapsed ? 'Show' : 'Hide'
    default:
      return field
  }
}
