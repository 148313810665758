import { Box, Stack, Typography } from '@mui/material'

import { Root } from './components/Root'
import { ActionBar } from './components/ActionBar'

import { type ModalHeaderProps } from './types'

export function ModalHeader(props: ModalHeaderProps) {
  const { testId = 'ModalHeader', avatar, title, subtitle, actions } = props
  const trackId = props.trackId ?? testId

  return (
    <Root data-testid={testId} data-trackid={trackId}>
      {Boolean(avatar) ? (
        <Box display="flex" alignItems="center" mr={2}>
          {avatar}
        </Box>
      ) : (
        <div />
      )}

      <Stack flexShrink={0} minWidth={0}>
        {typeof title === 'string' ? (
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
        ) : (
          title
        )}
        {typeof subtitle === 'string' ? (
          <Typography variant="body2" noWrap color={t => t.vars.palette.text.secondary}>
            {subtitle}
          </Typography>
        ) : (
          subtitle
        )}
      </Stack>

      <ActionBar actions={actions} />
    </Root>
  )
}
