import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderSteps } from '@/features/domain/order'

export function useOrders(ids: workwave.DeepReadonly<string[]>) {
  const extendedOrderSteps = useSelector(selectOrderSteps)

  return useMemo(() => {
    return ids.reduce<uui.domain.client.rm.ExtendedOrderStep[]>((acc, id) => {
      const extendedOrderStep = extendedOrderSteps[id]
      if (extendedOrderStep) {
        acc.push(extendedOrderStep)
      }

      return acc
    }, [])
  }, [extendedOrderSteps, ids])
}
