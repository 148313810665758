export function computeEventsPermission(
  vehiclesCount: number,
  isLoadingGpsData: boolean,
  trackingProvider: uui.domain.server.gps.TrackingProvider,
  deviceIds?: string[],
) {
  if (trackingProvider !== 'WWGPS') return 'hidden'
  if (isLoadingGpsData) return 'disabledLoadingGpsData'
  if (!deviceIds || deviceIds.length === 0) return 'disabledNoDevice'
  if (vehiclesCount === 0) return 'disabled'

  return 'enabled'
}
