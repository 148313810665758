import { Stroke, Style } from 'ol/style'
import VectorTileSource from 'ol/source/VectorTile'
import VectorTileLayer from 'ol/layer/VectorTile'
import MVT from 'ol/format/MVT'

import { authenticatedMVTTileLoadFunction } from './authenticatedMVTTileLoadFunction'

// Define a union type for traffic flow kinds
type TrafficFlowKind =
  | 'free' // free flowing, not disturbed, traffic
  | 'slow' // slowly moving traffic
  | 'none' // no traffic flow due to blockage or closure
  | 'minor' // minor traffic delays
  | 'unknown' // traffic status unknown
  | 'queuing' // the traffic is congested but still moves slowly
  | 'stationary' // stationary traffic, congestion

// Define a new Style instance for green traffic flow
const green = new Style({
  stroke: new Stroke({
    color: '#4AC459', // green color
    width: 2, // 2 pixel width stroke
  }),
})

// Define a new Style instance for orange traffic flow
const orange = new Style({
  stroke: new Stroke({
    color: '#FA8207', // orange color
    width: 2, // 2 pixel width stroke
  }),
})

// Define a new Style instance for red traffic flow
const red = new Style({
  stroke: new Stroke({
    color: '#FA221C', // red color
    width: 2, // 2 pixel width stroke
  }),
})

// Define a new Style instance for hard red traffic flow
const hard_red = new Style({
  stroke: new Stroke({
    color: '#B71718', // darker red color
    width: 2, // 2 pixel width stroke
  }),
})

export function createLiveTrafficLayer(url: string, visible: boolean) {
  const layer = new VectorTileLayer({
    properties: { id: 'liveTrafficTileLayer' },
    visible,

    source: new VectorTileSource({
      format: new MVT(),
      url,
      tileLoadFunction: authenticatedMVTTileLoadFunction,
    }),

    style: (feature, _resolution) => {
      // Get the properties of the current feature
      const props = feature.getProperties()

      // Ignore features that are not traffic flow data
      if (props.layer !== 'traffic_flow') return

      // Get the traffic flow kind from the feature properties
      const kind = props.kind as TrafficFlowKind

      switch (kind) {
        // Ignore features that have no traffic flow data
        case 'unknown':
          return

        // Ignore features with no traffic flow due to blockage or closure,
        // since it is possible that a route have to pass through such a section
        case 'none':
          return

        // Use the green style for free flowing traffic
        case 'free':
          return green

        // Use the orange style for slow or minor delay traffic
        case 'minor':
        case 'slow':
          return orange

        // Use the red style for congested traffic
        case 'queuing':
          return red

        // Use the dark red style for stationary traffic
        case 'stationary':
          return hard_red
      }
    },
  })

  return layer
}
