import type { FormFields, FormErrors } from '../formFields'
import type { FormSingleField } from '@workwave-tidal/tidal/form-fairy'

import { useEffect } from 'react'

import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

export function useUpdateEmailRelatedFields() {
  const api = useFormApi<FormFields, FormErrors>()

  useEffect(() => {
    function updateDisableState(emailField: Readonly<FormSingleField<string>>) {
      const empty = isEmailEmpty(emailField.value)
      api.setFieldDisabledState('showContactEmail', empty)
      api.setFieldDisabledState('useCompanyEmailAsReplyTo', empty)
    }

    const emailField = api.getField('companyEmail')
    updateDisableState(emailField)

    return api.subscribeToField('companyEmail', updateDisableState)
  }, [api])
}

function isEmailEmpty(email: string) {
  return email.trim().length === 0
}
