import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../renderingQueue'
import { getMap } from '../../atoms/map/utils/getMap'
import { findLayer } from '../utils/findLayer'

import { createRoutePolylineFeature } from './utils/createRoutePolylineFeature'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Initialize the Features for all the existent RoutePolylines
 */
export async function initializeRoutePolylineFeatures(
  markers: uui.domain.ui.map.markers.MapMarkers['routePolyline'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['routePolyline'],
) {
  const map = getMap()
  const layer = findLayer(map, 'routePolyline')

  const features: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const marker of Object.values(markers)) {
    await nonblockingRender.next()

    features.push(createRoutePolylineFeature(marker, mapStyles))
  }

  // add all new features to the layer
  layer.getSource()?.addFeatures(features)
}
