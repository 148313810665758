import { useCallback, memo, useRef, Fragment } from 'react'

import { Scrollbar } from '@workwave-tidal/tidal/components'

import { useDidUpdate } from '@/local/hooks'

import { WarningTriangle } from '@/icons'

import FlexColumn from '../../../lib/FlexColumn'
import FlexRow from '../../../lib/FlexRow'

import { LocationEditorText, AssetListType } from '../../typings'
import renderText from '../../intl'

import AssetListContainer from './elements/AssetListContainer'
import AssetListTitle from './elements/AssetListTitle'
import AssetListItemComp from './components/AssetListItem'

import IconWrapper from './elements/AssetListIcon/IconWrapper'
import AssetListText from './elements/AssetListText'

import MinQueryLengthState from './components/MinQueryLengthState'
import LoadingState from './components/LoadingState'
import EmptyState from './components/EmptyState'

import { Props } from './typings'

const isYourAssetType = (type: AssetListType) => type === 'depot' || type === 'gpsPlace'

const AssetList = (props: Props) => {
  const { height = 100, assetList, onChange, location, closeList, type, selectedIndex } = props

  const prevSelectedIndex = useRef<number>(-1)

  const handleOnClick = useCallback(
    (asset: uui.domain.client.gps.SearchOnMapItem) => () => {
      onChange(asset, location)
      closeList()
    },
    [onChange, location, closeList],
  )

  useDidUpdate(() => {
    if (selectedIndex !== -1 && prevSelectedIndex.current !== selectedIndex) {
      prevSelectedIndex.current = selectedIndex

      const targetId = assetList[selectedIndex] ? assetList[selectedIndex].id : ''
      const targetElem = document.getElementById(targetId)

      targetElem?.scrollIntoView()
    }
  }, [selectedIndex, assetList])

  const showLoading = type === 'loading'
  const showEmptyState = type === 'not-found'
  const showEmptySearch = type === 'add-search' && assetList.length === 0
  const showSearchResult = assetList.length > 0 && !showEmptyState
  const showAugmentSearchMsg =
    type === 'add-search' &&
    assetList.length > 0 &&
    isYourAssetType(assetList[assetList.length - 1].type)

  return (
    <AssetListContainer data-testid="location-editor__asset-list__container">
      {showLoading && <LoadingState />}
      {showEmptyState && <EmptyState {...props} />}
      {showEmptySearch && <MinQueryLengthState {...props} />}

      {showSearchResult && (
        <div style={{ width: '100%', height }}>
          <Scrollbar hideOverflow="x">
            <FlexColumn style={{ marginRight: 13 }}>
              {assetList.map((asset, idx, coll) => {
                const prevAsset = idx > 0 ? coll[idx - 1] : undefined
                const insertHeader =
                  idx === 0 ||
                  (prevAsset && isYourAssetType(prevAsset.type) && !isYourAssetType(asset.type))

                const headerText = isYourAssetType(asset.type)
                  ? LocationEditorText.yourAssets
                  : LocationEditorText.addresses
                const headerMarginTop = isYourAssetType(asset.type) || idx === 0 ? 0 : 15

                return (
                  <Fragment key={asset.id}>
                    {insertHeader ? (
                      <AssetListTitle style={{ marginBottom: 10, marginTop: headerMarginTop }}>
                        {renderText(headerText)}
                      </AssetListTitle>
                    ) : null}

                    <AssetListItemComp
                      onClick={handleOnClick(asset)}
                      asset={asset}
                      key={asset.id}
                      id={asset.id}
                      selected={selectedIndex === idx}
                    />
                  </Fragment>
                )
              })}

              {showAugmentSearchMsg ? (
                <FlexColumn
                  style={{
                    alignSelf: 'center',
                    opacity: 0.7,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <AssetListTitle style={{ marginTop: 15 }}>
                    <FlexRow>
                      <IconWrapper>
                        <WarningTriangle />
                      </IconWrapper>
                      {renderText(LocationEditorText.addresses)}
                    </FlexRow>
                  </AssetListTitle>

                  <AssetListText>{renderText(LocationEditorText.addDetailsToSearch)}</AssetListText>
                </FlexColumn>
              ) : null}
            </FlexColumn>
          </Scrollbar>
        </div>
      )}
    </AssetListContainer>
  )
}

export default memo(AssetList)
