import type { Props, DropdownItem } from './typings'

import { clsx } from '@/utils'

import { default as Dropdown, Props as DropdownProps } from '../Dropdown'

import { rowHeight } from './components/Row'

import { renderText } from './utils/renderText'
import { useData } from './useData'
import { useRenderers } from './useRenderers'

export function GenericDropdown(props: Props) {
  const { state, actions } = useData({
    onChanged: props.onChanged,
    onFocus: props.onFocus,
    onCancel: props.onCancel,
    selectedIds: props.selectedIds,
    values: props.values,
  })

  const { renderFooter, renderItems, renderNextArrow, renderPrevArrow } = useRenderers()

  const { status, filteredValues } = state

  const dropdownProps: DropdownProps<DropdownItem, string> = {
    selectedIds: props.selectedIds,
    status,
    values: filteredValues,
    rowHeight,
    editable: props.editable ?? true,
    openWithArrows: props.openWithArrows ?? true,
    extraIcon: props.extraIcon,
    showResetIcon: props.showResetIcon ?? true,
    name: props.name,
    placeholder: props.placeholder ?? '',

    onSuggestionChanged: props.onSuggestionChanged,
    style: props.style,
    height: props.height,
    disabled: props.disabled,
    keyboardEventDisabled: props.keyboardEventDisabled,

    render: renderItems(props.height, props.RowIcon, props.rowClassName),
    renderText,
    itemIdResolver: itemIdResolver,

    onChanged: actions.handleOnChange,
    onFocus: actions.handleOnFocus,
    onBlur: actions.handleOnBlur,
    onCancel: actions.handleOnCancel,
    onInputChanged: actions.handleInputChanged,

    renderPrevArrow: renderPrevArrow(props.renderArrows, actions.handleOnClickPrev),
    renderNextArrow: renderNextArrow(props.renderArrows, actions.handleOnClickNext),
    renderFooter: renderFooter(state, props.footerSecondaryText ?? ''),

    mode: 'renderProps',
  }

  const dropdownClassName = clsx('o-dropdown-wrapper', props.className)

  return <Dropdown className={dropdownClassName} {...dropdownProps} />
}

function itemIdResolver(item: DropdownItem) {
  return item.id
}
