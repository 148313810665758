import { PureComponent } from 'react'

import {
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyLabelInfo,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'
import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'

import * as OrderTexts from '../../../intl'
import { Props, FieldMode } from '../typings'
import * as Texts from '../intl'

export default class ServiceTime extends PureComponent<Props> {
  render() {
    const { serviceTime } = this.props

    const text =
      serviceTime === FieldMode.mixed ? (
        OrderTexts.getMixedText()
      ) : (
        <FormatSeconds seconds={serviceTime} format="DURATION_HH_MM" />
      )

    return (
      <>
        <ReadonlyBlock half>
          <ReadonlyLabel hasContent primary>
            {Texts.getServiceTimeText()}
            <ReadonlyLabelInfo primary empty>
              (hh:mm)
            </ReadonlyLabelInfo>
          </ReadonlyLabel>

          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{text}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
