import type { TrafficTimeWindowsFormValues } from '../../types'
import type { FormRenderProps } from 'react-final-form'

import { FormLayout, SectionTitle, TextButton } from '@/forms-legacy'
import { Plus, Cross } from '@/icons'

import { TimeWindowControlRow } from './components/TimeWindowControlRow'
import { RemoveButton } from './components/RemoveButton'
import { EditTimeWindowsFieldWrapper } from './components/EditTimeWindowsFieldWrapper'
import { TimeWindowField } from './components/TimeWindowField'
import { useManipulateTimeWindows } from './hooks/useManipulateTimeWindows'
import { useTexts } from './hooks/useTexts'

interface Props {
  formRenderProps: FormRenderProps<TrafficTimeWindowsFormValues>
}

const formLayoutStyle = { padding: '5px 16px' }
const timeWindowContainerStyle = { width: '100%' }

export function Form(props: Props) {
  const { formRenderProps } = props

  const values = formRenderProps.form.getState().values
  const { onAddTimeWindow, reachLimitOfTimeWindows, onHideTimeWindow1, onHideTimeWindow2 } =
    useManipulateTimeWindows(formRenderProps)

  const texts = useTexts()

  return (
    <FormLayout style={formLayoutStyle}>
      <TextButton type="button" disabled={reachLimitOfTimeWindows} onClick={onAddTimeWindow}>
        <Plus size={8} />
        {texts.addTimeWindow}
      </TextButton>

      <div hidden={!values.timeWindow1Visible} style={timeWindowContainerStyle}>
        <TimeWindowControlRow>
          <RemoveButton type="button" onClick={onHideTimeWindow1}>
            <Cross />
          </RemoveButton>
          <SectionTitle>{texts.timeWindow1}</SectionTitle>
        </TimeWindowControlRow>
        <EditTimeWindowsFieldWrapper>
          <TimeWindowField name="timeWindow1" />
        </EditTimeWindowsFieldWrapper>
      </div>

      <div hidden={!values.timeWindow2Visible} style={timeWindowContainerStyle}>
        <TimeWindowControlRow>
          <RemoveButton type="button" onClick={onHideTimeWindow2}>
            <Cross />
          </RemoveButton>
          <SectionTitle>{texts.timeWindow2}</SectionTitle>
        </TimeWindowControlRow>
        <EditTimeWindowsFieldWrapper>
          <TimeWindowField name="timeWindow2" />
        </EditTimeWindowsFieldWrapper>
      </div>
    </FormLayout>
  )
}
