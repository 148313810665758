import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Email } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  disabled: boolean
}

export function SendMessageButton(props: Props) {
  const { disabled, onClick } = props
  const texts = useTexts()

  return (
    <ReadOnlyTooltip
      render={preventEditing => (
        <NavigoIconButton
          onClick={onClick}
          disabled={preventEditing || disabled}
          text={texts.sendMessage}
          Icon={<Email size={20} color={preventEditing || disabled ? '$silver' : '$nightRider'} />}
          testid="navigo-drivers-actions-sendMessageButton"
        />
      )}
    />
  )
}
