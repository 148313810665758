import { useLoadingState } from '@/atoms'

import { LoadingScreen } from './LoadingScreen'

export function LoadingState() {
  const { type, message } = useLoadingState()

  switch (type) {
    case 'blocking':
      return <LoadingScreen message={message} />

    // TODO: Processing loading state have been commented out because Product has to provide us
    //       details about how to show such kind of information. We will not remove the snippet
    //       because could be helpful.

    // case 'processing':
    //   return (
    //     <FullscreenContainer>
    //       <FlexBox vAlignContent="top" hAlignContent="center" marginTop={10} h="100%">
    //         {message}
    //       </FlexBox>
    //     </FullscreenContainer>
    //   )

    default:
    case 'none':
      return null
  }
}
