import type { NotificationLimits } from '../types'
import { useMemo } from 'react'

const hotwordsRegexp = new RegExp(/@@(.*?)@@/gm)

export function useMessageLength(
  limits: NotificationLimits,
  text: string,
  includeUnSubscriptionInstruction?: boolean,
  includeTrackingLink?: boolean,
): number {
  return useMemo(() => {
    let messageLength = 0

    // Retrieve tokens in text
    const hotwords = text.match(hotwordsRegexp)?.map(hotword => hotword.replace(/@@/g, ''))

    // Adds length of the message without hotwords/links/instructions
    messageLength += text.replace(hotwordsRegexp, '').length

    // If hotwords are present add the correct length from each one
    for (const hotword of hotwords || []) {
      if (!limits[hotword]) continue

      messageLength += limits[hotword]
    }

    // If we need to include the tracking link, add the Tracking Link length
    if (includeTrackingLink) {
      messageLength += limits.TrackingLink ?? 0
    }

    // If we need to include the tracking link, add the Unsubscribe length
    if (includeUnSubscriptionInstruction) {
      messageLength += limits.Unsubscribe ?? 0
    }

    return messageLength
  }, [limits, text, includeUnSubscriptionInstruction, includeTrackingLink])
}
