// Importing necessary types and interfaces from other files and libraries
import type { FormMutation } from '@workwave-tidal/tidal/form-fairy'
import type { FormErrors, FormFields } from '../../../formFields'
import { configuredFields } from '../configuredFields'

// Defining a function that takes in an API object, an object representing which form fields have been changed, and an initialChanged object (which is not used in this function)
export const configuredFieldsMutation: FormMutation<FormFields, FormErrors> = (
  api,
  changed,
  _initialChanged, // unused parameter
) => {
  // Creating an empty object to store the values that will be returned at the end
  const toReturn = {}

  // Looping over each field in the configuredFields array
  for (const field of configuredFields) {
    // Checking if the 'isChecked' property of the current field has been changed
    const isChecked = changed[`${field}Checked`]

    // If the 'isChecked' property has not been changed, continue to the next iteration of the loop
    if (isChecked === undefined) continue

    // Getting the current value of the field using the 'getField' method of the API object
    const { value } = api.getField(field)

    // If the field is checked, set its value to 'REQUIRED_TO_COMPLETE' or its actual value, depending on whether its value is 'DO_NOT_REQUIRE' or not
    if (isChecked) {
      toReturn[field] = value !== 'DO_NOT_REQUIRE' ? value : 'REQUIRED_TO_COMPLETE'
    }
    // If the field is not checked, set its value to 'DO_NOT_REQUIRE'
    else {
      toReturn[field] = 'DO_NOT_REQUIRE'
    }
  }

  // Return the object containing the final values for each field
  return toReturn
}
