import type { CrudSelection } from '../typings'

import { useEffect } from 'react'

import { resetCrudSelection } from '../write/resetCrudSelection'

export function useResetCrudSelectionOnUnmount(category: keyof CrudSelection) {
  useEffect(() => {
    return () => {
      resetCrudSelection(category)
    }
  }, [category])
}
