import { DndProvider } from 'react-dnd'
import { BrowserRouter } from 'react-router-dom'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { theme } from '@/styles'
import { store } from '@/store'

import { SharedProviders } from './providers/SharedProviders'
import { GlobalStyleProvider } from './providers/GlobalStyleProvider'
import { App } from './App'
import { ErrorBoundary } from './ErrorBoundary'
import '@bryntum/schedulerpro/schedulerpro.material.css'

/**
 * RouteManager' root component.
 */
export function Root() {
  return (
    <ErrorBoundary>
      <>
        <GlobalStyleProvider />

        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            <SharedProviders store={store} theme={theme}>
              <App />
            </SharedProviders>
          </BrowserRouter>
        </DndProvider>
      </>
    </ErrorBoundary>
  )
}
