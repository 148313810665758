import type { PlaceChip } from '../../../components/Navigator/typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectPlaces } from '@/features/domain/place'

import { useNavigator as useCoreNavigator } from '../../../components/Navigator/hooks/useNavigator'

export const usePlacesNavigator = () => {
  const {
    ids,
    pagination: { page, setPage },
  } = useCoreNavigator()
  const places = useSelector(selectPlaces)

  const selectedPlaces = ids.reduce<uui.domain.client.gps.wwgps.Place[]>((acc, id) => {
    acc.push(places[id])

    return acc
  }, [])

  const chips: PlaceChip[] = useMemo(
    () =>
      selectedPlaces.map(place => ({
        source: place,
        type: 'place',
        id: place.id,
      })),
    [selectedPlaces],
  )

  const currentPlaces =
    page === -1 || !selectedPlaces?.[page] ? selectedPlaces : [selectedPlaces[page]]

  const currentPlaceIds = page === -1 || !ids?.[page] ? ids : [ids[page]]

  return { page, chips, currentPlaces, currentPlaceIds, setPage }
}
