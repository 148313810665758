export const standardTransformers = {
  string: {
    parse: (v: string) => v,
    serialize: (v: string) => v,
    isValid: (v: string) => typeof v === 'string',
  },

  integer: {
    parse: (v: string) => parseInt(v, 10),
    serialize: (v: number) => Math.round(v).toFixed(),
    isValid: (v: number) => typeof v === 'number' && !Number.isNaN(v),
  },

  float: {
    parse: (v: string) => parseFloat(v),
    serialize: (v: number) => v.toString(),
    isValid: (v: number) => typeof v === 'number' && !Number.isNaN(v),
  },

  boolean: {
    parse: (v: string) => v === 'true',
    serialize: (v: boolean) => (v ? 'true' : 'false'),
    isValid: (v: boolean) => typeof v === 'boolean',
  },

  timestamp: {
    parse: (v: string) => new Date(parseInt(v, 10)),
    serialize: (v: Date) => v.valueOf().toString(),
    isValid: (v: Date) => v instanceof Date,
  },

  isoDateTime: {
    parse: (v: string) => new Date(v),
    serialize: (v: Date) => v.toISOString(),
    isValid: (v: Date) => v instanceof Date,
  },

  json: {
    parse: JSON.parse,
    serialize: JSON.stringify,
    isValid: (v: unknown) => v !== undefined && v !== null,
  },
} as const
