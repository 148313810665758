import { useCallback } from 'react'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'

export function useActions() {
  const { editing, setEditing } = useEditingStateWithCtx('driver')
  const [selection, setSelection] = useCrudSelection('drivers')

  const onCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  return { editing, onCreate }
}
