import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'
import type { FeatureLike } from 'ol/Feature'

export const drawFeatureMetadata = {
  isEditableArea(feature?: FeatureLike): boolean {
    return feature?.get('editable-area') ?? false
  },
  setAsEditableArea(feature?: Feature<Geometry>) {
    feature?.set('editable-area', true)
  },
  clearEditableAreaStatus(feature?: Feature<Geometry>) {
    feature?.unset('editable-area')
  },

  isValid(feature?: FeatureLike): boolean {
    return feature?.get('valid') ?? true
  },
  setValid(feature?: Feature<Geometry>, valid: boolean = true) {
    feature?.set('valid', valid)
  },
  removeValid(feature?: Feature<Geometry>) {
    feature?.unset('valid')
  },

  isInvalid(feature?: FeatureLike): boolean {
    return feature?.get('invalid') ?? false
  },
  setInvalid(feature?: Feature<Geometry>, invalid: boolean = false) {
    feature?.set('invalid', invalid)
  },
  removeInvalid(feature?: Feature<Geometry>) {
    feature?.unset('invalid')
  },

  getActiveIndex(feature?: FeatureLike): number {
    return feature?.get('activeIndex') ?? -1
  },
  setActiveIndex(feature?: Feature<Geometry>, index: number = -1) {
    feature?.set('activeIndex', index)
  },
  removeActiveIndex(feature?: Feature<Geometry>) {
    feature?.unset('activeIndex')
  },

  getLastChanged(feature?: FeatureLike): number | undefined {
    return feature?.get('last-changed')
  },
  setLastChanged(feature?: Feature<Geometry>, timestamp: number = 0) {
    feature?.set('last-changed', timestamp)
  },
  removeLastChanged(feature?: Feature<Geometry>) {
    feature?.unset('last-changed')
  },
} as const
