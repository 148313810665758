import type { CSSProperties } from 'react'

import { memo } from 'react'
import { Box, Skeleton, Stack } from '@mui/material'

interface Props {
  style?: CSSProperties
}

function FormLoadingComponent(props: Props) {
  const { style } = props

  return (
    <Box padding={2} maxHeight="100%" overflow="hidden" style={style}>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={30} />
        <Skeleton />
        <Skeleton width="75%" />
        <Skeleton width="75%" />
        <Skeleton width="50%" />
        <Skeleton variant="rectangular" height={30} />
        <Skeleton />
        <Skeleton width="75%" />
        <Skeleton width="75%" />
        <Skeleton width="50%" />
        <Skeleton variant="rectangular" height={30} />
        <Skeleton />
        <Skeleton width="75%" />
        <Skeleton width="75%" />
        <Skeleton width="50%" />
      </Stack>
    </Box>
  )
}

export const FormLoading = memo(FormLoadingComponent)
