type OrderId = string
type OrderStepType = 'p' | 'd'
type TypedOrderStepId = `${OrderId}-${OrderStepType}`

type ParsedOrderStepId = {
  orderId: string
  orderStepId: TypedOrderStepId
  orderStepType: OrderStepType
}

/**
 * Extract the Order Id and other relevant info from the Order Step id.
 */
export function parseOrderStepId(orderStepId: string): ParsedOrderStepId {
  if (!orderStepId.endsWith('-p') && !orderStepId.endsWith('-d')) {
    throw new Error(`Not a valid order step id (${orderStepId})`)
  }

  const orderId = orderStepId.slice(0, -2)
  const orderStepType = orderStepId.slice(-1) as OrderStepType

  return {
    orderId,
    orderStepId: orderStepId as TypedOrderStepId,
    orderStepType,
  }
}

export function getOrderStep(id: string, order: uui.domain.client.rm.ExtendedOrder) {
  switch (order.order.type) {
    case 'd':
    case 's':
      return order.order.delivery

    case 'p':
      return order.order.pickup

    case 'pd': {
      return id.endsWith('-d') ? order.order.delivery : order.order.pickup
    }
  }
}

export function getExtendedOrderStep(id: string, order: uui.domain.client.rm.ExtendedOrder) {
  switch (order.type) {
    case 'd':
    case 's':
      return order.extendedDelivery

    case 'p':
      return order.extendedPickup

    case 'pd': {
      return id.endsWith('-d') ? order.extendedDelivery : order.extendedPickup
    }
  }
}

export function getOrderStepId(orderId: string, type: 'pickup' | 'delivery') {
  return type === 'pickup' ? `${orderId}-p` : `${orderId}-d`
}
