import { Hidden } from '@mui/material'

import { Grow } from '@/components/layout'

import { HeaderSlice } from '../../elements/HeaderSlice'
import { useHeader } from '../../hooks/useHeader'
import { HorizontalNav } from './components/HorizontalNav'
import { DropdownNav } from './components/DropdownNav'

export function MainNav() {
  const { userType } = useHeader()

  return (
    <Grow xAxis yAxis>
      <Hidden lgDown>
        <HeaderSlice data-testid="header__nav-menu-expanded" gutter={12} paddingX={10}>
          <HorizontalNav userType={userType} />
        </HeaderSlice>
      </Hidden>

      <Hidden lgUp>
        <HeaderSlice data-testid="header__nav-menu-collapsed" gutter={12} paddingX={10}>
          <DropdownNav userType={userType} />
        </HeaderSlice>
      </Hidden>
    </Grow>
  )
}
