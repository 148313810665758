import { useCallback } from 'react'
import { resetMapStyles } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

export const useResetStyles = () => {
  const dispatch = useAppDispatch()

  return useCallback(() => {
    dispatch(resetMapStyles())
  }, [dispatch])
}
