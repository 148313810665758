import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    disclaimerVehicleAssociationDeletion: translate({
      id: 'settings.integrations.deleteTenantSource.disclaimerVehicleAssociationDeletion',
    }),
    disclaimerDataNoMoreCollecting: translate({
      id: 'settings.integrations.deleteTenantSource.disclaimerDataNoMoreCollecting',
    }),
    disclaimerPermanentAction: translate({
      id: 'settings.integrations.deleteTenantSource.disclaimerPermanentAction',
    }),
    deleteButtonTitle: translate({
      id: 'settings.integrations.deleteTenantSource.deleteButtonTitle',
    }),
    cancelButtonTitle: translate({
      id: 'settings.integrations.deleteTenantSource.cancelButtonTitle',
    }),
    formDescription: translate({ id: 'settings.integrations.deleteTenantSource.formDescription' }),
    formError: translate({ id: 'settings.integrations.deleteTenantSource.formError' }),
    headerTitle: translate({ id: 'settings.integrations.deleteTenantSource.headerTitle' }),
  }))

  return api
}
