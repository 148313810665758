import type { FormConfig } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'

export function createFormValidation(): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'disclaimerPermanentAction',
      fields: 'disclaimerPermanentAction',
      validator: formApi => {
        const { value } = formApi.getField('disclaimerPermanentAction')

        const error: FormErrors = {
          id: 'disclaimerPermanentActionNotAccepted',
          field: 'disclaimerPermanentAction',
          message: '',
        }

        const valid = value === true

        return {
          [error.id]: !valid ? error : null,
        }
      },
    },
    {
      id: 'disclaimerDataNoMoreCollecting',
      fields: 'disclaimerDataNoMoreCollecting',
      validator: formApi => {
        const { value } = formApi.getField('disclaimerDataNoMoreCollecting')

        const error: FormErrors = {
          id: 'disclaimerDataNoMoreCollectingNotAccepted',
          field: 'disclaimerDataNoMoreCollecting',
          message: '',
        }

        const valid = value === true

        return {
          [error.id]: !valid ? error : null,
        }
      },
    },
    {
      id: 'disclaimerVehicleAssociationDeletion',
      fields: 'disclaimerVehicleAssociationDeletion',
      validator: formApi => {
        const { value } = formApi.getField('disclaimerVehicleAssociationDeletion')

        const error: FormErrors = {
          id: 'disclaimerVehicleAssociationDeletionNotAccepted',
          field: 'disclaimerVehicleAssociationDeletion',
          message: '',
        }

        const valid = value === true

        return {
          [error.id]: !valid ? error : null,
        }
      },
    },
  ]
}
