import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'
import { findFeature } from '../../findFeature'

import { findTrafficAreaMarker } from './findTrafficAreaMarker'
import { updateTrafficAreaFeature } from './updateTrafficAreaFeature'
import { createTrafficAreaFeature } from './createTrafficAreaFeature'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Update the rendered style and position for all the TrafficAreas marked to be updated in a Changelog coming form the data-layer
 */
export async function changelogUpdateTrafficAreaFeatures(
  layer: VectorLayer<VectorSource>,
  trafficProfiles: uui.domain.ui.map.markers.MapMarkers['trafficProfile'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['trafficProfile'],
  selection: Set<string>,
  trafficProfileIds: IterableIterator<string>,
) {
  nonblockingRender.reset()

  for (const profileId of trafficProfileIds) {
    for (const markerId in trafficProfiles[profileId]) {
      await nonblockingRender.next()

      const marker = findTrafficAreaMarker(trafficProfiles[profileId], markerId)
      const feature = findFeature(layer, marker.id)
      const selected = selection.has(markerId)

      if (feature) {
        updateTrafficAreaFeature(mapStyles, marker, feature, selected)
      } else {
        layer.getSource()?.addFeature(createTrafficAreaFeature(marker, mapStyles, selected))
      }
    }
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
