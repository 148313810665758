import { VerticalLayout } from '@/components/layout'
import { Text } from '@/local/components'

interface Props {
  place: uui.domain.client.gps.wwgps.Place
}

export function Single(props: Props) {
  const { place } = props

  return (
    <VerticalLayout
      data-trackid="navigo-places-overview-details-single"
      data-testid="navigo-places-overview-details-single"
      justifyContent="space-between"
    >
      <Text size="$p2" weight="$semiBold">
        {place.name}
      </Text>
      <Text size="$p3">{place.address}</Text>
    </VerticalLayout>
  )
}
