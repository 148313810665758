import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Report(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 22 22">
        <path d="M16.0769231 0c-.4660455 0-.8461539.38010838-.8461539.84615385V21.1538462c0 .4660454.3801084.8461538.8461539.8461538h5.0769231C21.6198916 22 22 21.6198916 22 21.1538462V.84615385C22 .38010839 21.6198916 0 21.1538462 0h-5.0769231zM8.46153846 6.76923077c-.46604546 0-.84615384.38010838-.84615384.84615385V21.1538462c0 .4660454.38010838.8461538.84615384.8461538h5.07692304c.4660455 0 .8461539-.3801084.8461539-.8461538V7.61538462c0-.46604547-.3801084-.84615385-.8461539-.84615385H8.46153846zm.84615385 1.69230769h3.38461539V20.3076923H9.30769231V8.46153846zM.84615385 13.5384615C.38010839 13.5384615 0 13.9185699 0 14.3846154v6.7692308C0 21.6198916.38010838 22 .84615385 22h5.07692307c.46604546 0 .84615385-.3801084.84615385-.8461538v-6.7692308c0-.4660455-.38010839-.8461539-.84615385-.8461539H.84615385z" />
      </svg>
    </IconContainer>
  )
}
