import { useCallback, createRef, RefObject, useState, useRef, useEffect } from 'react'
import { Stack } from '@mui/material'

import { useMount, useDidUpdate } from '@/local/hooks'

import FlexRow from '../../lib/FlexRow'

import TextInputState, { ChangeEventHandler } from '../../fields/v2/enhancers/TextInputState'

import { Props } from '../typings'
import { getLabelFromLocation } from '../utils'

import FieldContainer from '../elements/FieldContainer'
import FieldWrapper from '../elements/FieldWrapper'

import LocationEditorTextInput from './LocationEditorTextInput'
import { EmptyStateType } from './AssetList/typings'
import AdditionalModes from './AdditionalModes'
import WarningCard from './WarningCard'
import AssetList from './AssetList'
import Icon from './Icon'

const noop = () => undefined

enum KeyboardEventKeys {
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Escape = 'Escape',
  Enter = 'Enter',
  SpaceBar = ' ',
}

export const ExpandedState = (props: Props) => {
  const {
    testId,
    status,
    location,
    collapsed,
    onTextChange,
    closeList,
    assetList,
    queryMinLength,
    onChange,
    onInputBlur = noop,
    onKeyDown,
    loading,
  } = props

  const keyDownRef = useRef<any>(undefined)
  const inputRef: RefObject<HTMLInputElement> = createRef()

  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const [inputValue, setInputValue] = useState<string>(getLabelFromLocation(location))
  const [pristine, setPristine] = useState<boolean>(true)

  const getOnHandleOnKeyDown = useCallback(
    () => (event: KeyboardEvent) => {
      if (onKeyDown) {
        const prevent = !!onKeyDown(event)
        if (prevent) {
          return
        }
      }

      switch (event.key) {
        case KeyboardEventKeys.Escape:
          closeList()
          break

        case KeyboardEventKeys.ArrowUp:
          if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1)
          }
          break

        case KeyboardEventKeys.ArrowDown:
          const maxIndex = assetList.length - 1

          if (selectedIndex < maxIndex) {
            setSelectedIndex(selectedIndex + 1)
          }
          break

        case KeyboardEventKeys.Enter:
          if (selectedIndex > -1) {
            onChange(assetList[selectedIndex], location)
            closeList()
          }
          break
      }
    },
    [onKeyDown, onChange, assetList, selectedIndex, closeList, location],
  )

  useMount(() => {
    keyDownRef.current = getOnHandleOnKeyDown()
    document.addEventListener('keydown', keyDownRef.current)

    const input = inputRef.current
    if (input) {
      input.focus()
      input.setSelectionRange(0, input.value.length)
    }
  })

  // unmount management
  useEffect(() => {
    return () => {
      if (keyDownRef.current) {
        document.removeEventListener('keydown', keyDownRef.current)
      }
      keyDownRef.current = undefined
    }
  }, [])

  useDidUpdate(() => {
    setInputValue(getLabelFromLocation(location))
  }, [location])

  useDidUpdate(() => {
    if (keyDownRef.current) {
      document.removeEventListener('keydown', keyDownRef.current)
    }

    keyDownRef.current = getOnHandleOnKeyDown()
    document.addEventListener('keydown', keyDownRef.current)
  }, [assetList, selectedIndex])

  const handleOnTextChange = useCallback(
    (value: string) => {
      setPristine(false)
      setInputValue(value)
      onTextChange(value, location)
    },
    [onTextChange, location],
  )

  const showLoading = loading
  const showAddSearch: boolean = !showLoading && (inputValue.length < queryMinLength || pristine)
  const showEmpty: boolean = !showLoading && !showAddSearch && assetList.length === 0

  const type: EmptyStateType = showLoading
    ? 'loading'
    : showEmpty
    ? 'not-found'
    : showAddSearch
    ? 'add-search'
    : 'show-list'

  return (
    <Stack spacing={2} alignItems="flex-start">
      <FieldContainer style={{ padding: 0 }}>
        <FieldWrapper primary>
          <FlexRow>
            <Icon {...props} collapsed={collapsed} />

            <TextInputState
              value={inputValue}
              delay={300}
              onChange={handleOnTextChange}
              render={(text: string, wrappedOnChange: ChangeEventHandler) => (
                <LocationEditorTextInput
                  onChange={e => wrappedOnChange(e.target.value)}
                  value={text}
                  readOnly={collapsed}
                  onBlur={onInputBlur}
                  name="location-editor__input"
                  ref={inputRef}
                  data-testid={testId}
                  data-trackid={testId}
                />
              )}
            />
          </FlexRow>
        </FieldWrapper>
      </FieldContainer>

      {status !== 'ok' && <WarningCard {...props} />}

      <AdditionalModes {...props} />

      <AssetList {...props} type={type} selectedIndex={selectedIndex} />
    </Stack>
  )
}
