import type { ChangeEvent, MouseEvent } from 'react'
import type { UpdateAction } from '../useTimePicker'
import type { TimeFormat } from '../../types'

import { useCallback } from 'react'
import { convertTimeInput } from '../../utils'

interface Params {
  updateComponentState: React.Dispatch<UpdateAction>
  allowUnlistedValues: boolean
  timeFormat: TimeFormat
  options: number[]
}

export function useChangeActions(params: Params) {
  const { updateComponentState, allowUnlistedValues, timeFormat, options } = params

  // Called when a change on the dropdown is triggered
  const onSelectChange = useCallback(
    (e: MouseEvent<HTMLLIElement>) => {
      const clickedElement = e.target as HTMLLIElement

      updateComponentState({
        timeValue: clickedElement.value,
        open: false,
      })
    },
    [updateComponentState],
  )

  // Called when the value of the TextInput changes
  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      const parsedInput = convertTimeInput(value, timeFormat)
      const isInvalid =
        parsedInput === 'invalid' || (!allowUnlistedValues && !options.includes(parsedInput))

      updateComponentState({ open: !isInvalid, inputValue: value })
    },
    [allowUnlistedValues, timeFormat, options, updateComponentState],
  )

  return { onSelectChange, onInputChange }
}
