import { validateName } from './validations/validateName'
import { validateColor } from './validations/validateColor'
import { validateDeviceId } from './validations/validateDeviceId'

export function validateRmData(
  values: uui.domain.ui.forms.RouteManagerVehicleData,
  lockedNames: string[],
  allFormValues:
    | uui.domain.ui.forms.RmVehicleFormValues
    | uui.domain.ui.forms.Rm360VehicleFormValues
    | uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics,
): uui.domain.ui.forms.GpsVehicleDataValidation {
  const { name, color, deviceId } = values

  const nameValidation = validateName(name, lockedNames)
  const colorValidation = validateColor(color)
  const deviceIdValidation = validateDeviceId(
    deviceId,
    allFormValues && 'telematics' in allFormValues ? allFormValues.telematics : undefined,
  )

  return {
    name: nameValidation,
    color: colorValidation,
    deviceId: deviceIdValidation,
  }
}
