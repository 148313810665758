import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

type RoutePolylineFeatureMetadata = {
  mode: 'on' | 'off'
  type: 'routePolyline'
}

export function setRoutePolylineFeatureMetadata<Field extends keyof RoutePolylineFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
  value: RoutePolylineFeatureMetadata[Field],
) {
  target.set(field, value)
}

export function getRoutePolylineFeatureMetadata<Field extends keyof RoutePolylineFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
): RoutePolylineFeatureMetadata[Field] | undefined {
  return target.get(field)
}

export function removeRoutePolylineFeatureMetadata<
  Field extends keyof RoutePolylineFeatureMetadata,
>(target: Feature<Geometry>, field: Field) {
  target.unset(field)
}
