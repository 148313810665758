import { createRef, RefObject, Component, KeyboardEvent, FocusEvent } from 'react'
import { clsx } from '@/utils'

import ScrollableList, { Props as ScrollableListProps, ScrollableListScrolltoIndex } from '../List'
import InputFieldText, { Props as InputFieldTextProps } from '../fields/InputFieldText'
import { KeyboardEventKeys, KeyboardDirections } from '../keyboardEnums'
import { Props, IDropdown, TextToRender } from './typings'
import { State, getItemIndex, setText, setVisibleIndex, setStatus } from './state'

export default class Dropdown<T, S> extends Component<Props<T, S>, State<S>> implements IDropdown {
  readonly inputRef: RefObject<HTMLInputElement>

  constructor(props: Props<T, S>) {
    super(props)
    this.inputRef = createRef()

    this.state = {
      status: 'closed',
      textToRender: { text: '' },
      isManagingFocus: false,
      visibleIndex: { index: -1 },
    }

    const stateFromProps = this.updateStateFromProps(this.state, props)
    if (stateFromProps) {
      this.state = { ...this.state, ...stateFromProps }
    }
  }

  componentDidUpdate(prevProps: Props<T, S>, prevState: State<S>) {
    const { onTextChanged } = this.props
    const { textToRender: prevTextToRender } = prevState
    const { textToRender: stateTextToRender } = this.state

    let textToRender: TextToRender<S> = stateTextToRender
    const stateFromProps = this.updateStateFromProps(this.state, this.props, prevProps)

    if (stateFromProps) {
      this.setState(() => stateFromProps as any)
      if (stateFromProps.textToRender) {
        textToRender = stateFromProps.textToRender
      }
    }

    if (onTextChanged && textToRender !== prevTextToRender) {
      onTextChanged(textToRender)
    }
  }

  private updateStateFromProps(
    currentState: State<S>,
    nextProps: Props<T, S>,
    prevProps?: Props<T, S>,
  ) {
    const {
      selectedIds: prevSelectedIds = null,
      renderText: prevRenderText = null,
      itemIdResolver: prevItemIdResolver = null,
      status: prevStatus = null,
    } = prevProps || {}

    const { selectedIds, values, renderText, itemIdResolver, status } = nextProps

    const { textToRender: currentTextToRender, status: currentStatus } = currentState

    if (
      selectedIds !== prevSelectedIds ||
      renderText !== prevRenderText ||
      itemIdResolver !== prevItemIdResolver ||
      status !== prevStatus
    ) {
      const textToRender: TextToRender<S> = renderText(null, values, selectedIds)
      const textChanged: boolean =
        JSON.stringify(currentTextToRender) !== JSON.stringify(textToRender)

      const { suggestedId } = textToRender
      const visibleIndexId: S | undefined = suggestedId || selectedIds[0] || undefined
      const visibleIndex: ScrollableListScrolltoIndex = getItemIndex(
        values,
        itemIdResolver,
        visibleIndexId,
      )

      const result: Partial<State<S>> = {
        ...(textChanged ? { visibleIndex } : {}),
        ...(textChanged ? { textToRender } : {}),
        ...(status !== currentStatus ? { status } : {}),
      }
      return result
    }
  }

  public setFocus(): void {
    if (this.inputRef.current) {
      this.inputRef.current.focus()
    }
  }

  private clearHighlightedRow() {
    this.setState(setVisibleIndex({ index: -1 }))
  }

  private moveHighlightedRow(direction: KeyboardDirections) {
    const { values, selectedIds, onSuggestionChanged, itemIdResolver } = this.props
    const {
      visibleIndex: { index },
    } = this.state

    if (
      (index < 1 && direction === KeyboardDirections.UP) ||
      (index >= values.length - 1 && direction === KeyboardDirections.DOWN)
    ) {
      return
    }

    const step: number = direction === KeyboardDirections.UP ? -1 : 1
    const newIndex: number = index + step

    this.setState(
      setVisibleIndex({
        index: newIndex,
        direction: newIndex > index ? 'down' : 'up',
      }),
    )

    if (onSuggestionChanged) {
      const itemId: S = itemIdResolver(values[newIndex])
      onSuggestionChanged(itemId, values, selectedIds)
    }
  }

  private selectHighlightedRow(step: number = 0) {
    const { onChanged, values } = this.props
    const {
      visibleIndex: { index },
    } = this.state

    const newIndex: number = index + step
    if (newIndex < 0 || newIndex >= values.length) return

    onChanged([values[newIndex]])
  }

  private handleOnBlur = (event: FocusEvent<HTMLElement>) => {
    const { onBlur } = this.props
    if (onBlur) {
      onBlur(event)
    }
  }

  private handleOnFocus = (event: FocusEvent<HTMLElement>) => {
    const { onFocus } = this.props
    if (onFocus) {
      onFocus(event)
    }
  }

  private handleInputChange = (text: string): void => {
    const { renderText, values, selectedIds, itemIdResolver, onInputChanged } = this.props

    const textToRender = renderText(text, values, selectedIds)

    const { suggestedId } = textToRender
    const visibleIndex = getItemIndex(values, itemIdResolver, suggestedId)

    this.setState(setText<S>(textToRender))
    this.setState(setVisibleIndex(visibleIndex))

    if (onInputChanged) {
      onInputChanged(textToRender)
    }
  }

  private handleKeyboard = (event: KeyboardEvent<HTMLElement>) => {
    const { onCancel, editable, openWithArrows } = this.props
    const { status } = this.state
    const { key, defaultPrevented } = event

    if (defaultPrevented) return
    event.preventDefault()

    const open: boolean = status === 'open'

    if (open) {
      switch (key) {
        case KeyboardEventKeys.ArrowDown:
          this.moveHighlightedRow(KeyboardDirections.DOWN)
          break

        case KeyboardEventKeys.ArrowUp:
          this.moveHighlightedRow(KeyboardDirections.UP)
          break

        case KeyboardEventKeys.Escape:
          this.clearHighlightedRow()
          if (onCancel) onCancel()

          if (this.inputRef.current) {
            this.inputRef.current.blur()
          }
          break

        case KeyboardEventKeys.Enter:
          this.selectHighlightedRow()
          break
      }
    } else {
      switch (key) {
        case KeyboardEventKeys.ArrowDown:
          if (openWithArrows) {
            this.setState(setStatus('open'))
          } else {
            if (!editable) {
              this.selectHighlightedRow(1)
            }
          }
          break

        case KeyboardEventKeys.ArrowRight:
          if (!editable) {
            this.selectHighlightedRow(1)
          }
          break

        case KeyboardEventKeys.ArrowUp:
          if (openWithArrows) {
            this.setState(setStatus('open'))
          } else {
            this.selectHighlightedRow(-1)
          }
          break

        case KeyboardEventKeys.ArrowLeft:
          if (!editable) {
            this.selectHighlightedRow(-1)
          }
          break

        case KeyboardEventKeys.Enter:
          this.selectHighlightedRow()
          break
      }
    }
  }

  private renderHeader() {
    const {
      values,
      disabled = false,
      selectedIds,
      name,
      placeholder,
      editable,
      extraIcon,
      extraLabel,
      renderPrevArrow,
      renderNextArrow,
      renderExtraControls,
    } = this.props

    const {
      textToRender: { text, suggestedText },
    } = this.state

    const inputFieldProps: InputFieldTextProps = {
      type: 'text',
      name: name || 'o-dropdown-header__input',
      readonly: !editable,
      value: text,
      extraIcon,
      extraLabel,
      showResetIcon: false,
      suggestedText,
      renderExtraControls,
      onChange: this.handleInputChange,
      onReset: this.handleInputChange,
      onFocus: this.handleOnFocus,
      onBlur: this.handleOnBlur,
      onKeyUp: editable ? this.handleKeyboard : undefined,
    }

    inputFieldProps.innerRef = this.inputRef

    if (!suggestedText && placeholder) {
      Object.assign(inputFieldProps, { placeholder })
    }

    return (
      <>
        <InputFieldText className="o-dropdown-header__input" {...inputFieldProps} />
        {renderPrevArrow && (
          <span className="o-dropdown-header__prev-btn" data-testid="dropdown-header__prev-btn">
            {renderPrevArrow(values, selectedIds, disabled)}
          </span>
        )}
        {renderNextArrow && (
          <span className="o-dropdown-header__next-btn" data-testid="dropdown-header__next-btn">
            {renderNextArrow(values, selectedIds, disabled)}
          </span>
        )}
      </>
    )
  }

  private renderList() {
    const { render, values, onChanged, rowHeight, selectedIds } = this.props
    const { visibleIndex, status } = this.state

    if (status === 'closed') return null

    const scrollableListProps: ScrollableListProps<T, S> = {
      selectedIds,
      visibleIndex,
      values,
      render,
      onChanged,
      rowHeight,
      keyboardEventDisabled: true,
      mode: 'renderProps',
    }

    return <ScrollableList {...scrollableListProps} />
  }

  private renderFooter() {
    const { renderFooter, values, disabled, selectedIds } = this.props
    return renderFooter ? renderFooter(values, selectedIds, !!disabled) : null
  }

  render() {
    const { className = '', style, disabled, editable, keyboardEventDisabled } = this.props

    const rootClassName = clsx({
      [className]: true,
      'o-ww-dropdown': true,
      'is-disabled': !!disabled,
    })

    return (
      <div
        className={rootClassName}
        style={style}
        tabIndex={0}
        onKeyUp={
          disabled || keyboardEventDisabled ? undefined : editable ? undefined : this.handleKeyboard
        }
        onBlur={disabled ? undefined : editable ? undefined : this.handleOnBlur}
        onFocus={disabled ? undefined : editable ? undefined : this.handleOnFocus}
      >
        <header className="o-dropdown-header">{this.renderHeader()}</header>
        <div className="o-dropdown-list" data-testid="dropdown__list">
          {this.renderList()}
        </div>
        <footer className="o-dropdown-footer">{this.renderFooter()}</footer>
      </div>
    )
  }
}
