import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    anonymousUser: translate({ id: 'transactionBar.messages.anonymousUser' }),
    lockedByUser: (owner: string) =>
      translate({ id: 'transactionBar.messages.lockedByUser', values: { owner } }),
  }))

  return api
}
