import type { HTMLAttributes } from 'react'
import type { Option } from '../../../../types'
import type { FormFields, FormErrors } from '../../formFields'

import { ListSubheader, MenuItem } from '@mui/material'
import { Select } from '@workwave-tidal/tidal/form-ui'

import { useTexts } from './hooks/useTexts'
import { useOptions } from './hooks/useOptions'

export function Topic() {
  const texts = useTexts()
  const options = useOptions()

  return (
    <Select<'topic', FormFields, FormErrors, Option>
      selectProps={{
        items: options,
        label: texts.label,
        getLabel,
        getOptionDisabled,
        optionMode: 'custom',
        renderOption,
      }}
      testId="topic"
      name="topic"
      validateOn="blur focus"
    />
  )
}

function getLabel(option: Option) {
  return option?.label ?? ''
}

function getOptionDisabled(option: Option) {
  return option.type === 'header' || (option.type === 'option' && !!option.disabled)
}

function renderOption(props: HTMLAttributes<HTMLLIElement>, option: Option) {
  switch (option.type) {
    case 'header':
      return <ListSubheader key={option.id}>{option.label}</ListSubheader>

    case 'option':
      return (
        <MenuItem {...props} key={option.value} value={option.value} disabled={option.disabled}>
          {option.label}
        </MenuItem>
      )
  }
}
