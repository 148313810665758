import { useController } from '../../../hooks/useController'

import { ChooseRecipientHeader } from './components/ChooseRecipientHeader'
import { ComposeMessageHeader } from './components/ComposeMessageHeader'
import { ResultHeader } from './components/ResultHeader'

export function ShareLocationHeader() {
  const { status } = useController()

  switch (status) {
    case 'composeMessage':
      return <ComposeMessageHeader />
    case 'chooseRecipient':
      return <ChooseRecipientHeader />
    default:
      return <ResultHeader />
  }
}
