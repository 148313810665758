import type OlMap from 'ol/Map'

import { registerLassoInteraction } from './registerLassoInteraction/registerLassoInteraction'
import { registerMouseMoveInteraction } from './registerMouseMoveInteraction'
import { registerMouseSingleClickInteraction } from './registerMouseSingleClickInteraction'
import { registerMouseDoubleClickInteraction } from './registerMouseDoubleClickInteraction'
import { registerMovePinInteraction } from './registerMovePinInteraction'
import { registerDrawEditableAreaInteraction } from './registerDrawEditableAreaInteraction'
import { registerModifyEditableAreaInteraction } from './registerModifyEditableAreaInteraction'
import { registerDrawEditableRoadSegmentInteraction } from './registerDrawEditableRoadSegmentInteraction'
import { registerModifyEditableRoadSegmentInteraction } from './registerModifyEditableRoadSegmentInteraction'

export const registerMapInteractions = (map: OlMap) => {
  registerMouseMoveInteraction(map)

  registerMouseSingleClickInteraction(map)
  registerMouseDoubleClickInteraction(map)

  registerLassoInteraction(map)
  registerMovePinInteraction(map)

  registerDrawEditableAreaInteraction(map)
  registerModifyEditableAreaInteraction(map)

  registerDrawEditableRoadSegmentInteraction(map)
  registerModifyEditableRoadSegmentInteraction(map)
}
