import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectTelematicsDeviceTransientStatuses,
  selectTelematicsTenantSources,
} from '@/features/domain/account'

export function useGotIntegrationErrors() {
  const deviceTransientStatuses = useSelector(selectTelematicsDeviceTransientStatuses)
  const tenantSources = useSelector(selectTelematicsTenantSources)

  return useMemo(() => {
    const gotDeviceError = Object.values(deviceTransientStatuses).some(ts => ts.status === 'ERROR')
    const gotTenantSourceError = Object.values(tenantSources).some(ts => ts.statusCode !== 2000)

    return gotDeviceError || gotTenantSourceError
  }, [tenantSources, deviceTransientStatuses])
}
