import type { FeatureLike } from 'ol/Feature'

import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'

export const createLassoStyle = () => {
  const styles: Record<string, Style[]> = {}
  const white = [255, 255, 255, 1]
  const blue = [0, 153, 255, 1]
  const width = 3

  styles['Polygon'] = [
    new Style({
      stroke: new Stroke({
        color: [80, 80, 80, 0.5],
        width: width,
      }),
    }),
    new Style({
      fill: new Fill({
        color: [255, 255, 255, 0.5],
      }),
    }),
  ]
  styles['MultiPolygon'] = styles['Polygon']

  styles['LineString'] = [
    new Style({
      stroke: new Stroke({
        color: white,
        width: width + 2,
      }),
    }),
    new Style({
      stroke: new Stroke({
        color: blue,
        width: width,
      }),
    }),
  ]
  styles['MultiLineString'] = styles['LineString']

  styles['Circle'] = styles['Polygon'].concat(styles['LineString'])

  styles['Point'] = [
    new Style({
      image: new CircleStyle({
        radius: width * 2,
        fill: new Fill({
          color: [0, 255, 0, 0.5],
        }),
        stroke: new Stroke({
          color: white,
          width: width / 2,
        }),
      }),
      zIndex: Infinity,
    }),
  ]
  styles['MultiPoint'] = styles['Point']

  styles['GeometryCollection'] = styles['Polygon'].concat(styles['LineString'], styles['Point'])

  return (feature: FeatureLike, _resolution: number): Style[] => {
    const type = feature.getGeometry()?.getType() ?? 'Point'
    return styles[type]
  }
}
