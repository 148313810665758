import { useController } from './useController'

// the consumers of this hook aren't rendered when there is an issue so we can safely
// narrow the type avoiding the consumers to repeat the `if (ctrl.data.issue === 'none')`
// check again and again

export const useModalData = () => {
  const ctrl = useController()

  if (ctrl.data.issue === 'none') {
    return ctrl.data
  }

  throw new Error(
    `useModalDataWithoutIssue expect issue to be none it received ${ctrl.data.issue} instead`,
  )
}
