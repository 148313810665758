import type { Validation } from '../../formFields'
import { useMemo } from 'react'
import { useTexts } from './useTexts'

const preparationTimeStartField = 'preparationTimeStart' as const
const preparationTimeEndField = 'preparationTimeEnd' as const

export function usePreparationValidations(): Validation[] {
  const texts = useTexts()

  return useMemo(() => {
    const validatePreparationTimeStart: Validation = {
      id: 'validatePreparationTimeStart',
      validator: formApi => {
        const { value: preparationTimeStartValue } = formApi.getField(preparationTimeStartField)
        const { value: preparationTimeEndValue } = formApi.getField(preparationTimeEndField)

        const incoherenceErrorID = 'preparationTimeStart_incoherence'
        const gotIncoherence =
          preparationTimeStartValue !== -1 && preparationTimeEndValue !== -1
            ? preparationTimeStartValue > preparationTimeEndValue
            : false

        const incoherenceError = {
          id: incoherenceErrorID,
          message: texts.prepTimeIncoherenceError,
          priority: 20,
          field: preparationTimeStartField,
        }

        return {
          [incoherenceErrorID]: gotIncoherence ? incoherenceError : null,
        }
      },
    }

    const validatePreparationTimeEnd: Validation = {
      id: 'validatePreparationTimeEnd',
      validator: formApi => {
        const { value: preparationTimeStartValue } = formApi.getField(preparationTimeStartField)
        const { value: preparationTimeEndValue } = formApi.getField(preparationTimeEndField)

        const incoherenceErrorID = 'preparationTimeEnd_incoherence'

        const gotIncoherence =
          preparationTimeStartValue !== -1 && preparationTimeEndValue !== -1
            ? preparationTimeStartValue > preparationTimeEndValue
            : false

        const incoherenceError = {
          id: incoherenceErrorID,
          message: 'The end of the Prep time cannot be before its start',
          priority: 20,
          field: preparationTimeEndField,
        }

        return {
          [incoherenceErrorID]: gotIncoherence ? incoherenceError : null,
        }
      },
    }

    return [validatePreparationTimeStart, validatePreparationTimeEnd]
  }, [texts])
}
