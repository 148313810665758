import type {
  MonthData,
  Days,
  DayProps,
  MonthlySelectionAsRecord,
  SelectionAsRecord,
  MonthlyValidSelection,
} from '../../typings'

import { useMemo } from 'react'
import { eachDayOfInterval, format, getDate } from 'date-fns/esm'

import { useDateFnsOptions } from '@/hooks'

import { matchHighlightProducer } from './matchHighlightProducer'

type Props = {
  months: MonthData[]
  selection?: MonthlySelectionAsRecord
  highlightedDays?: SelectionAsRecord
}

type DaysByMonth = Record<string, Days>

export function useMonthDays(props: Props): DaysByMonth {
  const options = useDateFnsOptions()
  const { highlightedDays = {}, selection, months } = props

  return useMemo<DaysByMonth>(() => {
    const daysByMonth: DaysByMonth = {}

    for (const month of months) {
      const { start, end, id: monthId } = month

      const dates = eachDayOfInterval({
        start,
        end,
      })

      const days = dates.map<DayProps>((date, index) => {
        const label = format(date, 'd', options)
        const id = format(date, 'yyyyMMdd')
        const dateNumber = getDate(date) as MonthlyValidSelection

        const selected = !!selection && selection.hasOwnProperty(dateNumber)

        const cosmetic = selected ? 'edge-of-selection' : 'normal'

        const firstOfRow = index % 7 === 0

        return {
          id,
          date,
          label,
          cosmetic,
          firstOfRow,
          type: 'day',
          isToday: false,
          dot: matchHighlightProducer(highlightedDays)(dateNumber),
        }
      })

      daysByMonth[monthId] = days
    }

    return daysByMonth
  }, [months, options, highlightedDays, selection])
}
