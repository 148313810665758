import { useState } from 'react'

import { useConfigureModalController, useModalController, useCloseModal } from '@/atoms'

type ModalState = 'ready' | 'submitting' | 'invalid'
type ModalData = {
  driverIds: string[]
  deletableDriverIds: string[]
  validationResult?: uui.domain.server.rm.DeleteDriverValidationResults
}
type ModalProps = {
  driverIds: string[]
  validationResult?: uui.domain.server.rm.DeleteDriverValidationResults
}

function getDeletableDriverIds(
  driverIds: string[],
  validationResult?: uui.domain.server.rm.DeleteDriverValidationResults,
) {
  if (!validationResult) return driverIds

  const driverIdsToDelete = new Set<string>()

  for (const driverId in validationResult) {
    const result = validationResult[driverId]

    if (result.valid) {
      driverIdsToDelete.add(driverId)
    }
  }

  return Array.from(driverIdsToDelete)
}

export const modalId = 'deleteDriverModal'

export const useController = () => useModalController<ModalData, ModalState>(modalId)

export const useConfigureController = ({ driverIds, validationResult }: ModalProps) => {
  const close = useCloseModal()

  let allValidDrivers = true

  for (const driverId in validationResult) {
    if (!validationResult[driverId].valid) {
      allValidDrivers = false
      break
    }
  }

  const deletableDriverIds = getDeletableDriverIds(driverIds, validationResult)

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      status: (allValidDrivers ? 'ready' : 'invalid') as ModalState,
      data: {
        driverIds,
        validationResult,
        deletableDriverIds,
      },
    }),
  }))

  return useConfigureModalController<ModalData, ModalState>(modalId, options)
}
