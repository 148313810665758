import type { FitMapOptions, PendingFitMapMode } from '../utils/fitMap'

import { useCallback, useRef, useEffect } from 'react'

import { fitMapToSelection } from '../utils/fitMap'

export const useFitMapToSelection = (options?: FitMapOptions) => {
  const optionsRef = useRef(options)
  useEffect(() => void (optionsRef.current = options), [options])

  return useCallback((preventIfVisible?: boolean, pendingFitMap?: PendingFitMapMode) => {
    const finalPreventIfVisible =
      typeof preventIfVisible === 'boolean'
        ? preventIfVisible
        : optionsRef.current?.preventIfVisible

    fitMapToSelection(
      { ...optionsRef.current, preventIfVisible: finalPreventIfVisible },
      pendingFitMap,
    )
  }, [])
}
