import { emptyCrudSelection, emptyMainSelection } from '@/atoms'
import { getDomainMarkerData } from '../markerChangelog/domain/getDomainMarkerData'
import { updateMapSelection } from './core/updateMapSelection'
import { getMapSelectionContext } from './read/getMapSelectionContext'
import { subscribeToCrudMapSelection } from './subscribeToCrudMapSelection'
import { subscribeToMainMapSelection } from './subscribeToMainMapSelection'

let unsubscribeAll: (() => void) | undefined

export function subscribeMapToSelection() {
  if (unsubscribeAll) return unsubscribeAll

  const unsubscribeMainMapSelection = subscribeToMainMapSelection()
  const unsubscribeCrudMapSelection = subscribeToCrudMapSelection()

  unsubscribeAll = () => {
    unsubscribeMainMapSelection()
    unsubscribeCrudMapSelection()
  }

  return unsubscribeAll
}

export function unsubscribeMapFromSelection() {
  unsubscribeAll?.()
  unsubscribeAll = undefined
}

export async function clearMapSelection() {
  const mapSelectionContext = getMapSelectionContext()

  if (mapSelectionContext.type !== 'main') return

  const { mapMarkers, mapStyles, extendedOrderSteps, breadcrumbTimeRange } = getDomainMarkerData()

  const action = updateMapSelection(
    mapMarkers,
    mapStyles,
    mapSelectionContext,
    emptyMainSelection,
    emptyCrudSelection,
    extendedOrderSteps,
    breadcrumbTimeRange,
  )

  await action()
}
