import { FlexRow, ReadonlyLabel, ReadonlyBlock, TextButton } from '@/forms-legacy'

import { Triangle } from './Triangle'

interface Props {
  title: string
  description?: string
  collapsed: boolean
  onToggle: () => void
}

export function GpsDataViewToggler(props: Props) {
  const { title, description, collapsed, onToggle } = props

  return (
    <>
      <TextButton style={{ display: 'flex', alignItems: 'center' }} onClick={onToggle}>
        <FlexRow style={{ marginTop: 20 }} justifyContent="space-between">
          <ReadonlyLabel style={{ marginBottom: 0 }} primary>
            {title}
          </ReadonlyLabel>
          <Triangle style={{ transform: `rotate(${collapsed ? '180deg' : '0deg'})` }} />
        </FlexRow>
      </TextButton>
      {!!description && (
        <FlexRow>
          <ReadonlyBlock>
            <ReadonlyLabel small>{description}</ReadonlyLabel>
          </ReadonlyBlock>
        </FlexRow>
      )}
    </>
  )
}
