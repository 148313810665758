import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Voice(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 13 18">
        <path d="M6.363 0c-2.188 0-3.96 1.723-3.96 3.859v.101h2.7a.36.36 0 0 1 0 .72h-2.7v.72h2.7a.36.36 0 0 1 0 .72h-2.7v.72h2.7a.36.36 0 0 1 0 .72h-2.7v.821c0 2.136 1.772 3.859 3.96 3.859 2.188 0 3.96-1.723 3.96-3.859V7.56h-2.7a.36.36 0 1 1 0-.72h2.7v-.72h-2.7a.36.36 0 1 1 0-.72h2.7v-.72h-2.7a.36.36 0 1 1 0-.72h2.7v-.101C10.323 1.723 8.551 0 6.363 0zM.895 6.165a.313.313 0 0 0-.247.315v1.8c0 2.777 2.002 5.106 4.635 5.614v1.946h2.16v-1.946c2.632-.508 4.635-2.837 4.635-5.614v-1.8a.314.314 0 1 0-.63 0v1.8a5.085 5.085 0 0 1-10.17 0v-1.8a.314.314 0 0 0-.349-.315H.895zM2.943 16.56c-.69 0-1.249.563-1.249 1.249L1.683 18l9.315-.023.011-.168c0-.687-.56-1.249-1.249-1.249H2.943z" />
      </svg>
    </IconContainer>
  )
}
