import type { FormFields } from '../../../formFields'
import type { BulkLoadsError } from '../../primitives/BulkLoads/types'

import { Divider } from '@mui/material'

import { WarningTriangle } from '@/icons'
import { WarningCard } from '@/local/components'

import { BulkLoads } from '../../primitives/BulkLoads'

import { useFieldStatus } from './hooks/useFieldStatus'
import { useTexts } from './hooks/useTexts'

interface Props {
  orders: uui.domain.client.rm.ExtendedOrderStep[]
}

export function Loads(props: Props) {
  const texts = useTexts()
  const status = useFieldStatus(props.orders)

  if (status === 'all-service') return null

  return (
    <>
      <BulkLoads<'loads', FormFields, BulkLoadsError>
        name="loads"
        label={texts.loads}
        addLoadLabel={texts.addLoad}
        maxLoadsForOrder={5}
        disabled={status === 'at-least-one-service'}
        testId="loads"
      />
      {status === 'at-least-one-service' && (
        <WarningCard
          preset="warning"
          title={texts.waringCardTitle}
          description={texts.waringCardText}
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
        />
      )}
      <Divider />
    </>
  )
}
