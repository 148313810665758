import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

type DeviceEventFeatureMetadata = {
  mode: 'on' | 'off' | 'maximized'
  type: 'deviceEvent'
  ids: Record<string, true>
}

export const setDeviceEventFeatureMetadata = <Field extends keyof DeviceEventFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
  value: DeviceEventFeatureMetadata[Field],
) => {
  target.set(field, value)
}

export const getDeviceEventFeatureMetadata = <Field extends keyof DeviceEventFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
): DeviceEventFeatureMetadata[Field] | undefined => {
  return target.get(field)
}

export const removeDeviceEventFeatureMetadata = <Field extends keyof DeviceEventFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
) => {
  target.unset(field)
}
