import { Listener, Unsubscriber, PubSub } from './typings'

export function createPubSub<A = never>(): PubSub<A> {
  const listeners: Set<Listener<A>> = new Set()

  const listen = (listener: Listener<A>): Unsubscriber => {
    listeners.add(listener)
    return () => listeners.delete(listener)
  }

  const clearListeners = () => listeners.clear()

  const notify = (action: A) => listeners.forEach((listener): void => listener(action))

  return {
    clearListeners,
    listen,
    notify,
  }
}
