import { type ResourceModel } from '@bryntum/schedulerpro'
import { type CSSProperties } from 'react'
import { Flag } from '@/icons'

import { getViolationsColor } from '../../../../../styles/colors'
import { useAggregateData } from './hooks/useAggregateData'

interface Props {
  record: ResourceModel
}

const containerStyle: CSSProperties = { display: 'flex', flexDirection: 'column' }
const flagContainer: CSSProperties = { width: 16, height: 16, fill: getViolationsColor() }
const rowStyle: CSSProperties = {
  flexDirection: 'row',
  alignItems: 'center',
  display: 'flex',
  gap: 8,
}

export function ViolationsTooltip(props: Props) {
  const { record } = props

  const hasViolations = record.getData(
    'hasViolations',
  ) as uui.domain.client.rm.SchedulerResource['hasViolations']

  const violations = useAggregateData(record)

  if (!hasViolations) return null

  return (
    <div style={containerStyle}>
      {violations.map((violation, index) => (
        <div style={rowStyle} key={`violation-${index}`}>
          <div style={flagContainer}>
            <Flag size={20} color="$outrageousRed" />
          </div>
          {violation}
        </div>
      ))}
    </div>
  )
}
