import type { AxiosResponse } from 'axios'

import axios from 'axios'
import { httpDefaultResponse } from './httpDefaultResponse'

type LoginRequest = {
  serverUrl: string
  username: string
  password: string
}

export async function login(request: LoginRequest) {
  const { serverUrl, ...params } = request

  const response: AxiosResponse<uui.domain.server.UserProfile> = await axios.post(
    `${serverUrl}/loginuui`,
    params,
  )

  return httpDefaultResponse<uui.domain.server.UserProfile>(response)
}
