import { ButtonPrimitive } from './ButtonPrimitive'

export type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export function IconButton(props: Props) {
  const { children, ref, ...p } = props

  return <ButtonPrimitive {...p}>{children}</ButtonPrimitive>
}
