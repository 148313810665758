import { useResetCrudSelectionOnUnmount } from '@/atoms'

import { List } from './List'
import View from './Single/View'
import BulkView from './Bulk/View'
import SingleEdit from './Single/Edit'
import { useSimulationsRoot } from './useSimulationsRoot'

export function SimulationsRoot() {
  const { editing, simulations, selection, selectedSimulations } = useSimulationsRoot()
  const bulk = selectedSimulations.length > 1
  const single = selectedSimulations.length === 1

  const simulation = selectedSimulations[0]

  useResetCrudSelectionOnUnmount('simulations')

  return (
    <>
      <List editing={editing} simulations={simulations} selection={selection} />
      {single && !editing && simulation && <View simulation={simulation} selection={selection} />}
      {bulk && <BulkView simulations={selectedSimulations} selection={selection} />}
      {!bulk && editing && <SingleEdit simulation={simulation} />}
    </>
  )
}
