import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCustomerNotificationsSettings } from '@/features/domain/territory'

export type TodayNotificationValues = ReturnType<typeof useTodayNotificationValues>
/**
 * That hook grabs todayNotification values from the domains, than filter some of them out and
 * does some type conversion where required
 */
export function useTodayNotificationValues() {
  const notificationSettings = useSelector(selectCustomerNotificationsSettings)

  return useMemo(() => {
    const { windowOfCommitmentToleranceMins, ...rest } = notificationSettings['todaySettings']

    return Object.assign({}, rest, {
      // Number -> string conversion (actually RadioGroup does not manage numbers)
      windowOfCommitmentToleranceMins: windowOfCommitmentToleranceMins.toString(),
    })
  }, [notificationSettings])
}
