import type OlMap from 'ol/Map'

import { getOrderMarkerIdFromOrderStepIds } from '../../atoms/selection/read/getOrderMarkerIdFromOrderStepIds'
import { createNonBlockingRenderByTime } from '../../renderingQueue'

import { getLayerMetadata, setLayerMetadata } from '../layerMetadata'
import { findLayer } from '../utils/findLayer'
import { findFeature } from '../findFeature'

import { findOrderMarker } from './utils/findOrderMarker'
import { getOrderFeatureStyle } from './utils/getOrderFeatureStyle'

const emptySet = new Set<string>()
const nonblockingRender = createNonBlockingRenderByTime()

export const updateOrderFeaturesByEditingState = async (
  map: OlMap,
  editingEntities: workwave.DeepReadonly<string[]>,
  mapMarkers: uui.domain.ui.map.markers.MapMarkers['order'],
  _mapStyles: uui.domain.ui.map.markers.MapStyles['order'],
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
) => {
  const layer = findLayer(map, 'order')

  // retrieve selected features
  const layerSelection = getLayerMetadata(layer, 'selection') as Set<string>

  const orderMarkerIds = getOrderMarkerIdFromOrderStepIds(editingEntities, extendedOrderSteps)

  nonblockingRender.reset()

  for (const markerId of orderMarkerIds) {
    await nonblockingRender.next()

    const marker = findOrderMarker(mapMarkers, markerId)
    if (!marker) {
      console.warn(
        `Trying to select Order marker with lat-lng: ${markerId} for ID: ${markerId} but no feature exist on the map`,
      )
      continue
    }

    const feature = findFeature(layer, marker.id)

    if (!feature) {
      throw new Error(
        `Trying to select Order marker with lat-lng: ${markerId} for ID: ${markerId} but no feature exist on the map`,
      )
    }

    const selected = layerSelection?.has(markerId)

    // check that every id in the markers (not filtered) is included in the editing entities
    // if not the feature will be not hidden
    let hide = true
    for (const [id, value] of Object.entries(marker.ids)) {
      if (value.filtered || editingEntities.includes(id)) continue
      hide = false
      break
    }

    if (hide) {
      const style = getOrderFeatureStyle(marker, 'off', emptySet, selected)
      feature.setStyle(style)
    }
  }

  // store features hidden because of an editing state
  setLayerMetadata(layer, 'editingEntities', orderMarkerIds)
}
