import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    fiveMins: translate({
      id: 'vehicles.form.edit.generalSettings.fields.idleStart.preset.fiveMins',
    }),
    tenMins: translate({
      id: 'vehicles.form.edit.generalSettings.fields.idleStart.preset.tenMins',
    }),
    thirtyMins: translate({
      id: 'vehicles.form.edit.generalSettings.fields.idleStart.preset.thirtyMins',
    }),
    custom: translate({ id: 'vehicles.form.edit.generalSettings.fields.idleStart.preset.custom' }),
    label: translate({ id: 'vehicles.form.driverBehaviors.idleStartLimit' }),
    info: translate({ id: 'vehicles.form.edit.generalSettings.fields.idleStart.custom.info' }),
    customLabel: translate({
      id: 'vehicles.form.edit.generalSettings.fields.idleStart.custom.label',
    }),
    min: (label: string) => `${label} (${translate({ id: 'global.min' })})`,
  }))

  return api
}
