import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    notAssigned: translate({ id: 'setup.driverAssignment.form.dropdownValue.noDriverAssigned' }),
    externalDriver: translate({ id: 'global.externalDriver' }),
    unassignedDrivers: translate({
      id: 'setup.driverAssignments.dropdown.header.unassignedDrivers',
    }),
    driversAlreadyInUse: translate({
      id: 'setup.driverAssignments.dropdown.header.driversAlreadyInUse',
    }),
    driversAlreadyInUseTooltipTitle: translate({
      id: 'setup.driverAssignments.dropdown.header.driversAlreadyInUse.tooltip.title',
    }),
    driversAlreadyInUseTooltipDescription: translate({
      id: 'setup.driverAssignments.dropdown.header.driversAlreadyInUse.tooltip.description',
    }),
  }))

  return api
}
