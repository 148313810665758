import { useFormatSeconds } from '@/hooks'

interface Props {
  seconds: number
  format?: Parameters<typeof useFormatSeconds>[1]
}

export function FormatSeconds(props: Props) {
  const { seconds, format = 'LONG' } = props
  const value = useFormatSeconds(seconds, format)

  return <>{value}</>
}
