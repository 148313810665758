import { renderToStaticMarkup } from 'react-dom/server'
import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { selectUserConfiguration } from '@/features/domain/user'
import { store } from '@/store'
import { intl } from '@/intl'

import { OrdersNotCompletedCell } from '../../../components/cells/OrdersNotCompletedCell'
import { ordersNotCompletedSorter } from '../sorters/ordersNotCompletedSorter'

export function createOrdersNotCompletedColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    text: intl.translate({ id: 'rm.scheduler.column.ordersExecutedReschedule' }),
    type: 'template',
    field: 'ordersExecutedReschedule',
    width,
    editor: false,
    hidden,
    region: 'cols',

    template: ({ record, field, value }) => {
      const state = store.getState()
      const { language } = selectUserConfiguration(state)

      return renderToStaticMarkup(
        <OrdersNotCompletedCell
          record={record as ResourceModel}
          field={field}
          value={value}
          language={language}
        />,
      )
    },

    sortable: ordersNotCompletedSorter,
    resizable: true,
    groupable: false,
  }
}
