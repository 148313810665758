import { proxy, snapshot } from 'valtio'

import { domainProxy } from '@/store'

export type schedulerLastTimeSpan = {
  start?: Date
  end?: Date
  firstVisibleRowId?: string
}

type schedulerLastTimeSpanAtom = { positions: schedulerLastTimeSpan }

// ---------------------------------------------------------------------------
// Default values
// ---------------------------------------------------------------------------
const schedulerLastDefaultTimeSpan: schedulerLastTimeSpan = {}

// ------------------------------------
// Scroll positions atom
// ------------------------------------

export const schedulerLastTimeSpanAtom = proxy<schedulerLastTimeSpanAtom>({
  positions: schedulerLastDefaultTimeSpan,
})

// ---------------------------------------------------------------------------
// Read functions
// ---------------------------------------------------------------------------

export function getSchedulerLastTimeSpan() {
  return snapshot(schedulerLastTimeSpanAtom).positions
}

// ---------------------------------------------------------------------------
// Write functions
// ---------------------------------------------------------------------------

type SetSchedulerLastTimeSpan = (prev: schedulerLastTimeSpan) => schedulerLastTimeSpan
type SetSchedulerLastTimeSpanParam =
  | SetSchedulerLastTimeSpan
  | Partial<schedulerLastTimeSpan>
  | 'reset'

export function resetSchedulerLastTimeSpan() {
  delete schedulerLastTimeSpanAtom.positions.firstVisibleRowId
  delete schedulerLastTimeSpanAtom.positions.start
  delete schedulerLastTimeSpanAtom.positions.end
}

export const setSchedulerLastTimeSpan = (valueOrFunc: SetSchedulerLastTimeSpanParam) => {
  if (valueOrFunc === 'reset') return resetSchedulerLastTimeSpan()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(
      schedulerLastTimeSpanAtom.positions,
      valueOrFunc(schedulerLastTimeSpanAtom.positions),
    )
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      schedulerLastTimeSpanAtom.positions[field] = valueOrFunc[field]
    }
  }

  return schedulerLastTimeSpanAtom
}

export function setSchedulerTimeSpan(start: Date, end: Date) {
  setSchedulerLastTimeSpan(prev => {
    return { ...prev, start, end }
  })
}

export function setSchedulerTimeSpanFirstVisibleRow(firstVisibleRowId: string) {
  setSchedulerLastTimeSpan(prev => {
    return { ...prev, firstVisibleRowId }
  })
}

// ---------------------------------------------------------------------------
// SUBSCRIBE METHOD
// ---------------------------------------------------------------------------

export function subscribeSchedulerTimeSpanChangeCalendarRange() {
  return domainProxy.subscribeToNotifications(
    notification => {
      if (notification.notificationType === 'changeCalendarRange') {
        setSchedulerLastTimeSpan('reset')
      }
    },
    ['changeCalendarRange'],
  )
}
export function subscribeSchedulerTimeSpanReplaceDomainData() {
  return domainProxy.subscribeToDataChangeSuccess(evt => {
    if (evt.mode !== 'replace') return

    setSchedulerLastTimeSpan('reset')
  })
}
