import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    routeTitle: translate({ id: 'contextualMenu.stylesOnMap.route.orderBlock.title' }),
    routeSubtitle: translate({ id: 'contextualMenu.stylesOnMap.route.orderBlock.subTitle' }),
    routeShow: translate({ id: 'contextualMenu.stylesOnMap.order.show' }),
    routeReduce: translate({ id: 'contextualMenu.stylesOnMap.order.reduce' }),
    routeHide: translate({ id: 'contextualMenu.stylesOnMap.order.hide' }),

    polylineTitle: translate({ id: 'contextualMenu.stylesOnMap.route.pathBlock.title' }),
    polylineSubtitle: translate({ id: 'contextualMenu.stylesOnMap.route.pathBlock.subTitle' }),
    polylineShow: translate({ id: 'contextualMenu.stylesOnMap.polyline.show' }),
    polylineHide: translate({ id: 'contextualMenu.stylesOnMap.polyline.hide' }),

    disabledForBeta: translate({ id: 'global.disabledForBeta' }),
  }))

  return api
}
