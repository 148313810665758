import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    delete: (count: number) =>
      translate({
        id: 'form.drivers.modal.delete.action.delete',
        values: { count },
      }),

    ok: translate({ id: 'form.drivers.modal.delete.action.ok' }),
    cancel: translate({ id: 'form.drivers.modal.delete.action.cancel' }),
    comeBackLater: translate({ id: 'form.drivers.modal.delete.action.comeBackLater' }),
    deleteRemainingDrivers: (count: number) =>
      translate({
        id: 'form.drivers.modal.delete.action.deleteRemainingDrivers',
        values: { count },
      }),
  }))

  return api
}
