import styled from 'styled-components'

interface Props {
  selected?: boolean
}

export const IconsListIcon = styled.button<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  margin: 5px;
  outline: 0;
  border-width: 0;
  padding: 0;

  border-radius: 4px;

  background: ${p => (p.selected ? p.theme.colors.$scienceBlue : p.theme.colors.$whiteSmoke)};
  color: ${p => (p.selected ? p.theme.colors.$pureWhite : p.theme.colors.$nightRider)};

  cursor: pointer;

  transition: background 0.3s ease;

  &:hover {
    background: ${p => (p.selected ? p.theme.colors.$scienceBlue : p.theme.colors.$silver)};
  }

  > * {
    height: 34px;
  }
`

IconsListIcon.displayName = 'IconsListIcon'
