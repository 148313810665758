import { useCallback } from 'react'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { deleteTrafficProfiles } from '@/features/domain/traffic'
import { type ListHeaderSecondaryActionRef } from '@/components/List'
import { useAppDispatch } from '@/store'

import { useGoBack } from '../../../hooks/useGoBack'

import { useOnImportKML } from './useOnImportKML'

export function useActions(
  trafficProfileId: string,
  listRef: ListHeaderSecondaryActionRef | null,
  onShowModal: () => void,
) {
  const dispatch = useAppDispatch()
  const { editing, setEditing } = useEditingStateWithCtx('trafficArea')
  const [, setSelection] = useCrudSelection('trafficArea')

  const onCreate = useCallback(() => {
    setSelection([])
    setEditing([])
  }, [setSelection, setEditing])

  const importCb = useOnImportKML(trafficProfileId)
  const goBack = useGoBack()

  const onImportKML = useCallback(() => {
    importCb()
    listRef?.close()
  }, [importCb, listRef])

  const onGoBack = useCallback(() => {
    goBack()
    listRef?.close()
  }, [goBack, listRef])

  const onShowEditTrafficProfileModal = useCallback(() => {
    onShowModal()
    listRef?.close()
  }, [onShowModal, listRef])

  const onDeleteProfile = useCallback(() => {
    dispatch(deleteTrafficProfiles([trafficProfileId]))
    goBack()
    listRef?.close()
  }, [listRef, trafficProfileId, dispatch, goBack])

  return {
    editing,
    onCreate,
    onImportKML,
    onGoBack,
    onShowEditTrafficProfileModal,
    onDeleteProfile,
  }
}
