import styled from 'styled-components'

import { HorizontalLayout } from '@/components/layout'

export const ButtonsContainer = styled(HorizontalLayout)`
  & > * + * {
    margin-left: 8px;
  }
`
ButtonsContainer.displayName = 'ButtonsContainer'
