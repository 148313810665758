import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    edit: translate({ id: 'global.edit' }),
    delete: translate({ id: 'global.delete' }),
    export: translate({ id: 'global.export' }),
    editAll: translate({ id: 'global.editAll' }),
    unassign: translate({ id: 'global.unassign' }),
    deleteAll: translate({ id: 'global.deleteAll' }),
    exportAll: translate({ id: 'global.exportAll' }),
    duplicate: translate({ id: 'global.duplicate' }),
    unassignAll: translate({ id: 'global.unassignAll' }),
    style: translate({ id: 'rm.contextual.depot.style' }),
    fitIn: translate({ id: 'rm.contextual.order.fitIn' }),
    fitInAll: translate({ id: 'rm.contextual.order.fitInAll' }),
    duplicateAll: translate({ id: 'global.duplicateAll' }),
    styleAll: translate({ id: 'rm.contextual.depot.styleAll' }),
    copyOrders: (count: number) =>
      translate({ id: count > 1 ? 'global.copyAllToOperations' : 'global.copyToOperations' }),

    duplicateDisabledTooltipTitle: (
      addableOrders: number,
      ordersToDuplicate: number,
      isSimulation: boolean,
    ) => {
      const canAddOrders = addableOrders >= ordersToDuplicate

      const id =
        canAddOrders && isSimulation
          ? 'rm.contextual.order.duplicate.disabled.simulations.title'
          : 'rm.contextual.order.duplicate.disabled.operations.title'

      return translate({ id })
    },

    disabledForLockedRouteTooltipTitle: translate({
      id: 'rm.contextual.order.unassign.tooltip.disabledForLockedRoute',
      values: { count: 1 },
    }),

    duplicateDisabledTooltipSubtitle: (
      addableOrders: number,
      ordersToDuplicate: number,
      isSimulation: boolean,
      ordersLimit: number,
    ) => {
      if (addableOrders === 0) {
        const id = isSimulation
          ? 'rm.contextual.order.duplicate.disabled.simulations.subtitle'
          : 'rm.contextual.order.duplicate.disabled.operations.subtitle'
        return translate({ id, values: { count: ordersLimit } })
      }

      const canAddOrders = addableOrders >= ordersToDuplicate

      const id =
        canAddOrders && isSimulation
          ? 'rm.contextual.order.duplicate.disabled.simulations.tooMuch.subtitle'
          : 'rm.contextual.order.duplicate.disabled.operations.tooMuch.subtitle'

      return translate({ id, values: { addableOrders, ordersLimit } })
    },
  }))

  return api
}
