import { FlexRow } from '@/forms-legacy'
import { Left, Right } from '@/icons'
import { useVehicleFormException } from '@/atoms'

import { ExceptionSliderButton } from './ExceptionSliderButton'

interface Props {
  recurrences: uui.domain.rm.Recurrence[]
}

export function ExceptionSliderControls(props: Props) {
  const { recurrences } = props
  const [settings, setExceptionSettings] = useVehicleFormException()

  const currentExceptionIndex = recurrences.findIndex(rec => rec.source === settings.exception)

  const prevRecurrence = recurrences[currentExceptionIndex - 1]?.source
  const nextRecurrence = recurrences[currentExceptionIndex + 1]?.source

  return (
    <FlexRow useAutoWidth>
      <ExceptionSliderButton
        disabled={!prevRecurrence}
        onClick={() => setExceptionSettings({ exception: prevRecurrence! })}
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Left size={10} />
      </ExceptionSliderButton>
      <ExceptionSliderButton
        disabled={!nextRecurrence}
        onClick={() => setExceptionSettings({ exception: nextRecurrence! })}
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          marginLeft: 2,
        }}
      >
        <Right size={10} />
      </ExceptionSliderButton>
    </FlexRow>
  )
}
