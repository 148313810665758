import type Feature from 'ol/Feature'

import Circle from 'ol/geom/Circle'
import Geometry from 'ol/geom/Geometry'
import { Stroke, Style, Fill } from 'ol/style'

import { gis } from '@/server-data'

const whiteStroke = new Stroke({ color: [255, 255, 255, 1] })
const circleFill = new Fill({ color: [0, 122, 255, 0.3] })

export const circleStyle = new Style({
  fill: circleFill,
  stroke: whiteStroke,
  geometry: (feature: Feature<Geometry>) => {
    const geometry = feature?.getGeometry()

    if (geometry instanceof Geometry && geometry.getType() === 'Circle') {
      const circle = geometry as Circle
      const centerCoordinate = circle.getCenter()
      const radius = circle.getRadius()

      const outerLatLng = gis.computeOffset(
        gis.fromCoordinateToLatLng(centerCoordinate),
        radius,
        90,
      )
      const outerCoordinate = gis.fromLatLngToCoordinate(outerLatLng)

      const dx = centerCoordinate[0] - outerCoordinate[0]
      const dy = centerCoordinate[1] - outerCoordinate[1]
      const radiusInCoordinates = Math.sqrt(dx * dx + dy * dy)

      return new Circle(centerCoordinate, radiusInCoordinates)
    }
  },
})
