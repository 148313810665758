import type { ItemWithPosition, ItemsHeightsGetter } from '../typings'
import { getItemOrGroupHeader } from './guards'

type Params<T> = {
  itemsById: Record<string, T>
  list: uui.domain.ui.list.RenderItem[]
  getItemHeights: ItemsHeightsGetter<T>
}

type Result<T> = { itemsTotalHeight: number; itemsWithPosition: ItemWithPosition<T>[] }

export const getItemsWithPosition = <T>(params: Params<T>): Result<T> => {
  const { itemsById, list, getItemHeights } = params
  const itemsWithPosition: ItemWithPosition<T>[] = []

  let scrollY = 0
  for (let i = 0, n = list.length; i < n; i++) {
    const item = getItemOrGroupHeader<T>(list[i], itemsById)
    if (!item) continue

    const itemWithPosition = {
      item,
      y: scrollY,
      height: getItemHeights(item, list),
    }

    itemsWithPosition.push(itemWithPosition)
    scrollY += itemWithPosition.height
  }

  return { itemsTotalHeight: scrollY, itemsWithPosition }
}
