import type { SearchableListTypes } from '@/forms-legacy'
import type { DeviceInfoItem } from '../types'

import { useCallback, MouseEvent } from 'react'

import { Box } from '@mui/material'
import { FlexRow, FlexColumn, ReadonlyLabel, Dot, Checkbox } from '@/forms-legacy'
import { colorsUtils } from '@/utils'

import { DeviceListItemWrapper } from './DeviceListItemWrapper'
import { TransparentButton } from './TransparentButton'

const checkboxStyle = { marginRight: 6, pointerEvents: 'none' } as const
const labelStyle = { marginRight: 6 } as const
const noop = () => undefined

export function DeviceListItem(props: SearchableListTypes.ListItemProps<DeviceInfoItem>) {
  const {
    onChange,
    value,
    disabled,
    readonly,
    selected,
    value: { id, name, color: rawColor },
  } = props

  const onClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { shiftKey, metaKey, ctrlKey, altKey } = event
      onChange([value], { shiftKey, metaKey, ctrlKey, altKey })
    },
    [onChange, value],
  )

  const color = colorsUtils.getValidHexColor(rawColor)

  return (
    <DeviceListItemWrapper key={id}>
      <FlexColumn primary>
        <FlexRow>
          <TransparentButton type="button" onClick={onClick} disabled={disabled}>
            <Checkbox
              checked={!!selected}
              disabled={disabled || readonly}
              id={id}
              onChange={noop}
              style={checkboxStyle}
            />
            {name}
          </TransparentButton>
        </FlexRow>
      </FlexColumn>
      <FlexColumn>
        <ReadonlyLabel small style={labelStyle}>
          {id}
        </ReadonlyLabel>
      </FlexColumn>
      <FlexColumn>
        <Box width={16} height={16} marginRight={1}>
          <Dot innerColor={color} outerColor={color} />
        </Box>
      </FlexColumn>
    </DeviceListItemWrapper>
  )
}
