import styled from 'styled-components'

import { WithTheme } from '../../../theme'

interface Props extends workwave.Span {
  checked?: boolean
  disabled?: boolean
  focus?: boolean
}

type ThemedProps = WithTheme<Props>

const getBackground = ({ theme: { colors }, checked, disabled }: ThemedProps) => {
  if (disabled) {
    return checked ? colors.$whiteSmoke : colors.$pureWhite
  }

  return checked ? colors.$scienceBlue : colors.$pureWhite
}

const getBorderColor = ({ theme: { colors }, checked, disabled }: ThemedProps) => {
  if (disabled) {
    return checked ? colors.$whiteSmoke : colors.$alabaster
  }

  return checked ? colors.$scienceBlue : colors.$whiteSmoke
}

const getHoverBorderColor = ({ theme: { colors }, disabled }: ThemedProps) =>
  disabled ? colors.$alabaster : colors.$scienceBlue

const getColor = ({ theme: { colors } }: ThemedProps) => colors.$pureWhite
const getCursor = ({ disabled }: ThemedProps) => (disabled ? 'not-allowed' : 'pointer')

const span = styled.span<Props>``
const styledComponent = styled(span).attrs<Props>(() => ({
  className: 'o-molecule-checkbox-hack',
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-radius: 3px;
  border-color: ${getBorderColor};
  border-width: 2px;
  cursor: pointer;
  background: ${getBackground};
  color: ${getColor};
  cursor: ${getCursor};
  transition: color 0.3s ease;
  transition-property: color, background, border-color, outline;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    border-color: ${getHoverBorderColor};
  }

  &:focus-within:not(:disabled) {
    box-shadow: 0 0 3px rgba(255, 0, 0, 0.4);
  }
`

styledComponent.displayName = 'CheckboxIcon'
export default styledComponent
