import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    title: (count: number) =>
      translate({ id: 'modals.exportRoutes.header.title', values: { count } }),

    filterTitle: translate({ id: 'modals.exportRoutes.labels.filter' }),
    filterDescription: translate({ id: 'modals.exportRoutes.labels.filter.description' }),
    formatTitle: translate({ id: 'modals.exportRoutes.labels.format' }),
    cancelButton: translate({ id: 'modals.exportRoutes.button.cancel' }),
    exportButton: (count: number) =>
      translate({ id: 'modals.exportRoutes.button.export', values: { count } }),

    genericExportRoutesError: translate({ id: 'modals.exportRoutes.errors.generic' }),
    includePod: translate({ id: 'modals.exportRoutes.labels.includePod' }),
    includeBarcode: translate({ id: 'modals.exportRoutes.labels.includeBarcode' }),
    invalidDataSimulationEnvTitle: translate({
      id: 'modals.exportRoutes.invalidData.simulation.title',
    }),
    invalidDataSimulationEnvDesc: translate({
      id: 'modals.exportRoutes.invalidData.simulation.description',
    }),
    invalidDataRoutesEmptyTitle: translate({
      id: 'modals.exportRoutes.invalidData.routesEmpty.title',
    }),
    invalidDataRoutesEmptyHeaderTitle: (count: number) =>
      translate({
        id: 'modals.exportRoutes.invalidData.routesEmpty.header.title',
        values: { count },
      }),
    invalidDataRoutesEmptyDesc: (count: number) =>
      translate({
        id: 'modals.exportRoutes.invalidData.routesEmpty.description',
        values: { count },
      }),
    printButton: translate({ id: 'modals.exportRoutes.button.print' }),

    filterSelectedLabel: translate({ id: 'modals.exportRoutes.filter.current' }),
    filterDispatchedLabel: translate({ id: 'modals.exportRoutes.filter.approved' }),
    filterSelectedDescription: translate({ id: 'modals.exportRoutes.filter.current.description' }),
    filterSelectedDisabledTooltip: (count: number) =>
      translate({ id: 'modals.exportRoutes.filter.current.disabled.tooltip', values: { count } }),
    filterDispatchedDescription: translate({
      id: 'modals.exportRoutes.filter.approved.description',
    }),

    formatXLSX: translate({ id: 'modals.exportRoutes.exportFormat.xlsx' }),
    formatCSV: translate({ id: 'modals.exportRoutes.exportFormat.csv' }),

    extraColumnTitle: translate({ id: 'modals.exportRoutes.extraColumns.title' }),
    extraColumnDescription: translate({ id: 'modals.exportRoutes.extraColumns.description' }),

    customField: (count: number) =>
      translate({ id: 'modals.exportRoutes.extraColumns.customFields', values: { count } }),
    loads: (count: number) =>
      translate({ id: 'modals.exportRoutes.extraColumns.loads', values: { count } }),
    selectAll: translate({ id: 'modals.exportRoutes.extraColumns.selectAll' }),
    deselectAll: translate({ id: 'modals.exportRoutes.extraColumns.deselectAll' }),

    notAllRoutesDispatchedWarningId:
      'modals.exportRoutes.extraColumns.notAllRoutesDispatchedWarning',

    noApprovedRoutesWarning: translate({
      id: 'modals.exportRoutes.extraColumns.noApprovedRoutesWarning',
    }),
    noApprovedRouteWarning: translate({
      id: 'modals.exportRoutes.extraColumns.noApprovedRouteWarning',
    }),
    dismiss: translate({
      id: 'modals.exportRoutes.button.dismiss',
    }),
    exportButtonWarning: (count: number) =>
      translate({
        id: 'modals.exportRoutes.button.warning',
        values: { count },
      }),

    advanced: {
      title: translate({ id: 'modals.exportRoutes.advanced.title' }),
      keepEnglishColumns: translate({ id: 'modals.exportRoutes.advanced.keepEnglishColumns' }),
      keepEnglishColumnsDescription: translate({
        id: 'modals.exportRoutes.advanced.keepEnglishColumns.description',
      }),
    },

    nextStepButton: translate({ id: 'modals.exportRoutes.nextStepButton' }),
    prevStepButton: translate({ id: 'modals.exportRoutes.prevStepButton' }),
    preparingExport: translate({ id: 'modals.exportRoutes.preparingExport' }),
    emptySelectionWarning: translate({ id: 'modals.exportRoutes.emptySelectionWarning' }),

    cannotExportPodForCalendarRange: translate({
      id: 'modals.exportRoutes.cannotExportPodForCalendarRange',
    }),
    customizeColumns: translate({ id: 'modals.exportRoutes.customizeColumns' }),
    customizeColumnsDescription: translate({
      id: 'modals.exportRoutes.customizeColumnsDescription',
    }),
    advancedButtonDisabled: translate({ id: 'modals.exportRoutes.advancedButtonDisabled' }),
  }))

  return api
}
