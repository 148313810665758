import { useRef } from 'react'
import { useSelector } from 'react-redux'

import { selectEnableDynamicLoads } from '@/features/domain/territory'
import { useComponentSize } from '@/local/hooks'

import { StatsContainer } from '../StatsContainer'
import { useNavigoView } from '../../../hooks/useNavigoView'
import { RenderFrom } from '../../../../../components/RenderFrom'
import { TypeBlock } from './components/TypeBlock'
import { LoadsBlock } from './components/LoadsBlock'
import { AutoTimeBlock } from './components/AutoTimeBlock'
import { TrackDataBlock } from './components/TrackDataBlock'
import { StatusIndicator } from './components/StatusIndicator'
import { PlannedEtaBlock } from './components/PlannedEtaBlock'
import { TimeWindowsBlock } from './components/TimeWindowsBlock'
import { ExecutionDetailsButton } from './components/ExecutionDetailsButton'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

const statusIndicatorWidth = 58
const trackDataBlockWidth = 90
const executionDetailsButtonWidth = 50
const autoTimeBlockBlockWidth = 106
const plannedEtaBlockWidth = 120
const typeBlockWidth = 130
const timeWindowsBlockWidth = 200
const loadsBlockWidth = 150

const gap = 24

export function ExecutedOrderStats(props: Props) {
  const { extOrder } = props

  const ref = useRef<HTMLDivElement | null>(null)
  const [{ width }] = useComponentSize(ref)

  const enableDynamicLoads = useSelector(selectEnableDynamicLoads)
  const {
    navigate: { goToExecutionDetails },
  } = useNavigoView()

  if (extOrder.isUnassigned) {
    throw new Error(`Unable to render executed order stats of an unassigned order ${extOrder.id}`)
  }

  const {
    routeId,
    violations,
    stepStartSec,
    orderStepType,
    order: { loads },
    orderStep: { id, timeWindows, serviceTimeSec, trackingData },
  } = extOrder

  const renderStatus = trackingData.status === 'missing' ? false : true
  const renderAutoTimeIn = trackingData.autoTimeInSec > 0 && trackingData.autoTimeOutSec > 0

  const statusIndicatorRequiredWidth = renderStatus ? statusIndicatorWidth + gap : 0
  const trackDataBlockRequiredWidth = statusIndicatorRequiredWidth + trackDataBlockWidth + gap
  const executionDetailsBlockRequiredWidth =
    trackDataBlockRequiredWidth + executionDetailsButtonWidth + gap
  const autoTimeBlockRequiredWidth = renderAutoTimeIn
    ? executionDetailsBlockRequiredWidth + autoTimeBlockBlockWidth + gap
    : executionDetailsBlockRequiredWidth
  const plannedEtaBlockRequiredWidth = autoTimeBlockRequiredWidth + plannedEtaBlockWidth + gap
  const typeBlockRequiredWidth = plannedEtaBlockRequiredWidth + typeBlockWidth + gap
  const timeWindowsBlockRequiredWidth = typeBlockRequiredWidth + timeWindowsBlockWidth + gap
  const loadsBlockRequiredWidth =
    timeWindowsBlockRequiredWidth + (Object.keys(loads).length / 3) * loadsBlockWidth + gap

  return (
    <StatsContainer ref={ref}>
      {renderStatus && (
        <RenderFrom width={width} renderFrom={statusIndicatorRequiredWidth}>
          <StatusIndicator status={trackingData.status} />
        </RenderFrom>
      )}

      <RenderFrom width={width} renderFrom={trackDataBlockRequiredWidth}>
        <TrackDataBlock trackingData={trackingData} />
      </RenderFrom>

      <RenderFrom width={width} renderFrom={executionDetailsBlockRequiredWidth}>
        <ExecutionDetailsButton onClick={goToExecutionDetails} />
      </RenderFrom>

      {renderAutoTimeIn && (
        <RenderFrom width={width} renderFrom={autoTimeBlockRequiredWidth}>
          <AutoTimeBlock trackingData={trackingData} />
        </RenderFrom>
      )}

      <RenderFrom width={width} renderFrom={plannedEtaBlockRequiredWidth}>
        <PlannedEtaBlock routeId={routeId} orderStepId={id} stepStartSec={stepStartSec} />
      </RenderFrom>

      <RenderFrom width={width} renderFrom={typeBlockRequiredWidth}>
        <TypeBlock type={orderStepType} serviceTimeSec={serviceTimeSec} />
      </RenderFrom>

      <RenderFrom width={width} renderFrom={timeWindowsBlockRequiredWidth}>
        <TimeWindowsBlock timeWindows={timeWindows} violations={violations} />
      </RenderFrom>

      <RenderFrom width={width} renderFrom={loadsBlockRequiredWidth}>
        <LoadsBlock
          loads={loads}
          trackingData={trackingData}
          showDynamicLoads={enableDynamicLoads}
        />
      </RenderFrom>
    </StatsContainer>
  )
}
