import type { FormValidator } from '@workwave-tidal/tidal/form-fairy'
import type { FormErrors, FormFields } from '../../formFields'

import { useMemo } from 'react'

import { useTexts } from '../useTexts'

export function useNameValidation() {
  const texts = useTexts()

  return useMemo(() => {
    const validator: FormValidator<FormFields, FormErrors> = formApi => {
      const field = formApi.getField('name')

      return {
        nameRequiredError:
          field.required && field.value.trim().length === 0
            ? {
                id: 'nameRequiredError',
                message: texts.errors.required,
                field: 'name',
                priority: 10,
              }
            : null,
      }
    }

    return {
      validator,
      fields: 'name' as const,
      id: 'nameValidator',
    }
  }, [texts])
}
