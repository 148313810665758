import { BackButton } from '../../../../elements/BackButton'
import { useVehiclesNavigator } from '../../hooks/useVehiclesNavigator'

import { Styler } from './components/Styler'

interface Props {
  reset: () => void
}

export function MapStyle(props: Props) {
  const { reset } = props
  const { currentVehicleIds } = useVehiclesNavigator()

  return (
    <>
      <Styler vehicleIds={currentVehicleIds} />
      <BackButton testid="navigo-vehicle-mapStyles-back" onClick={reset} />
    </>
  )
}
