import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Lasso(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 13">
        <path d="M8.745 1c-.602-.005-1.226.044-1.866.148-1.703.278-3.192.904-4.264 1.742-1.072.84-1.777 1.965-1.583 3.172.14.86.708 1.536 1.48 2.017-.097.216-.158.453-.158.71 0 .629.326 1.143.804 1.49l-.735 1.476c-.16.324-.03.716.29.876.319.16.708.03.867-.293l.802-1.614.071.008c.055.006.11.013.166.013 1.103 0 2.119-.706 2.243-1.752.412-.02.829-.056 1.259-.125 1.703-.277 3.192-.904 4.264-1.744 1.072-.838 1.777-1.963 1.583-3.17-.195-1.207-1.216-2.052-2.495-2.508-.64-.23-1.363-.372-2.139-.425-.192-.013-.39-.02-.589-.02zm-.546 1.317c.364-.018.715-.013 1.052.007.67.046 1.281.171 1.79.352 1.018.364 1.557.914 1.648 1.487.093.57-.248 1.265-1.1 1.932-.852.67-2.161 1.24-3.676 1.485-.49.081-.958.104-1.418.11-.359-.457-.928-.75-1.552-.828-.104-.016-.212-.028-.324-.028-.402 0-.788.099-1.13.267-.695-.349-1.104-.784-1.18-1.245-.091-.573.25-1.27 1.102-1.938.85-.667 2.161-1.237 3.676-1.484.379-.061.75-.1 1.112-.117zM4.953 8.18c-.101-.028-.212-.044-.334-.044-.298 0-.553.1-.723.23-.159.127-.247.285-.247.422 0 .199.19.44.515.565.043.01.086.026.127.044.098.025.207.043.328.043.615 0 .971-.372.971-.652 0-.058-.025-.122-.055-.186-.018-.033-.043-.068-.071-.102l-.044-.05-.022-.023c-.043-.039-.088-.077-.142-.112-.019-.012-.039-.02-.058-.03l-.035-.016c-.066-.033-.132-.066-.21-.09z" />
      </svg>
    </IconContainer>
  )
}
