import { type ReactNode } from 'react'

import { type ChipProps } from './types'
import { Chip } from './Chip'

type Props = {
  prevChip?: ChipProps
  nextChip?: ChipProps
  children?: ReactNode
}

export function DragTooltip(props: Props) {
  const { prevChip, nextChip, children } = props
  return (
    <div style={{ display: 'flex' }}>
      {prevChip && <Chip {...prevChip} />}
      <div
        style={{
          margin: 0,
          display: 'flex',
          padding: `0 8px`,
          overflow: 'hidden',
          alignItems: 'center',
          borderRadius: '4px',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        }}
      >
        <div style={{ margin: 0 }}>{children}</div>
      </div>
      {nextChip && <Chip {...nextChip} />}
    </div>
  )
}
