import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../renderingQueue'
import { getLayerMetadata, setLayerMetadata } from '../layerMetadata'

import { updateDeviceEventPointsFeature } from './utils/deviceEventPoints/updateDeviceEventPointsFeature'

import { findDeviceEventMarker } from './utils/findDeviceEventMarker'
import { findFeature } from './utils/findFeature'

const nonblockingRender = createNonBlockingRenderByTime()

export async function updateDeviceEventsMapSelection(
  layer: VectorLayer<VectorSource>,
  mapMarkers: uui.domain.ui.map.markers.MapMarkers['deviceEvent'],
  selection: Set<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  // retrieve selected items in layers (points and stops)
  const layerSelection = (getLayerMetadata(layer, 'selection') ?? new Set()) as Set<string>

  const updatedSelection = new Set<string>()

  // if prev and next are empty do nothing

  const deviceEventsToDeselect = new Set(layerSelection)

  nonblockingRender.reset()

  for (const id of selection) {
    await nonblockingRender.next()

    // event id format is 'markerId@UID'
    const markerId = id.split('@')[0]
    const marker = findDeviceEventMarker(mapMarkers, markerId)

    if (!marker) {
      throw new Error(
        `Trying to select DeviceEvent point with ID: ${id} but there's no relative marker`,
      )
    }

    deviceEventsToDeselect.delete(id)

    if (layerSelection.has(id)) {
      // id was previously selected and it's still selected
      // do nothing
      continue
    }

    const feature = findFeature(layer, id)

    // It is possible that some event w/out latLng exists and consequently also his feature doesn't exists
    if (!feature) continue

    // the id is a new selection

    updateDeviceEventPointsFeature(feature, marker.events[id], true, breadcrumbTimeRange)

    updatedSelection.add(id)
  }

  nonblockingRender.reset()

  for (const id of deviceEventsToDeselect) {
    await nonblockingRender.next()

    // points/stops id format is 'markerId@UID'
    const markerId = id.split('@')[0]
    const marker = findDeviceEventMarker(mapMarkers, markerId)

    if (!marker) {
      throw new Error(
        `Trying to select DeviceEvent point with ID: ${id} but there's no relative marker`,
      )
    }

    const feature = findFeature(layer, id)

    // It is possible that some event w/out latLng exists and consequently also his feature doesn't exists
    if (!feature) continue

    // the id is a new selection
    // deselect the deviceEvent
    updateDeviceEventPointsFeature(feature, marker.events[id], false, breadcrumbTimeRange)
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
