import { useCallback } from 'react'
import { useTexts } from './useTexts'

export function useFormatLanguage() {
  const texts = useTexts()

  return useCallback(
    (languageCode: uui.domain.Language) => {
      switch (languageCode) {
        case 'en':
          return texts.english
        case 'de':
          return texts.german
        case 'es':
          return texts.spanish
        case 'it':
          return texts.italian
        case 'nl':
          return texts.dutch
        case 'fr':
          return texts.french
        case 'pt':
          return texts.portuguese
        default:
          return languageCode
      }
    },
    [texts],
  )
}
