import type { DropdownStatus, TextToRender } from '../Dropdown'
import type { DropdownItem } from './typings'
import type { ScrollableListChangeEventHandler } from '../List'

import { useCallback, useState, useEffect } from 'react'

import { filterValues } from './utils/filterValues'

export interface State {
  status: DropdownStatus
  filteredValues: DropdownItem[]
  searchText: string
}

interface Props {
  onChanged: ScrollableListChangeEventHandler<DropdownItem>
  onFocus?: () => void
  onCancel?: () => void
  selectedIds: string[]
  values: DropdownItem[]
}

export function useData(props: Props) {
  const [state, setState] = useState<State>(() => ({
    status: 'closed',
    filteredValues: props.values,
    searchText: '',
  }))

  const { onChanged, onFocus, onCancel, selectedIds, values } = props
  const { filteredValues, searchText } = state

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      searchText,
      filteredValues: filterValues(searchText, values),
    }))
  }, [values, searchText])

  const handleOnChange = useCallback(
    (items: DropdownItem[]) => {
      onChanged(items)
      setState(prevState => ({
        ...prevState,
        status: 'closed',
        searchText: '',
      }))
    },
    [onChanged],
  )

  const handleOnFocus = useCallback(() => {
    onFocus
      ? onFocus()
      : setState(prevState => ({
          ...prevState,
          status: 'open',
        }))
  }, [onFocus])

  const handleOnBlur = useCallback(() => {
    setTimeout(() => {
      setState(prevState => ({
        ...prevState,
        status: 'closed',
        searchText: '',
      }))
    }, 250)
  }, [])

  const handleOnCancel = useCallback(() => {
    onCancel
      ? onCancel()
      : setState(prevState => ({
          ...prevState,
          status: 'closed',
          searchText: '',
        }))
  }, [onCancel])

  const handleInputChanged = useCallback((textToRender: TextToRender<string>) => {
    setState(prevState => ({
      ...prevState,
      searchText: textToRender.text,
    }))
  }, [])

  const handleOnClickPrev = useCallback(() => {
    let index = filteredValues.findIndex(({ id }) => selectedIds.includes(id))
    if (index > 0) {
      let item: DropdownItem | null = null
      index = index - 1

      do {
        item = filteredValues[index]
        item = item?.disabled ? null : item
        index = index - 1
      } while (!item && index > -1)

      if (item) {
        handleOnChange([item])
      }
    }
  }, [handleOnChange, selectedIds, filteredValues])

  const handleOnClickNext = useCallback(() => {
    let index = filteredValues.findIndex(({ id }) => selectedIds.includes(id))
    if (index < filteredValues.length - 1) {
      let item: DropdownItem | null = null
      index = index + 1

      do {
        item = filteredValues[index]
        item = item?.disabled ? null : item
        index = index + 1
      } while (!item && index < filteredValues.length)

      if (item) {
        handleOnChange([item])
      }
    }
  }, [selectedIds, filteredValues, handleOnChange])

  return {
    state,
    actions: {
      handleOnChange,
      handleOnFocus,
      handleOnBlur,
      handleOnCancel,
      handleInputChanged,
      handleOnClickPrev,
      handleOnClickNext,
    },
  }
}
