import { Paper } from '@mui/material'
import { HorizontalLayout } from '@/components/layout'
import { useAppLanguage } from '@/atoms'

import { LogoArea } from './components/LogoArea'
import { MenuArea } from './components/MenuArea'
import { MainNav } from './components/MainNav'

import { HeaderContainer } from './elements/HeaderContainer'

import { useHeader } from './hooks/useHeader'

export function Header() {
  // guarantees that the component is re-rendered when the language changes
  useAppLanguage()

  const { authenticated, planType } = useHeader()

  if (!authenticated) return null

  return (
    <Paper elevation={4}>
      <HeaderContainer planType={planType}>
        <HorizontalLayout alignItems="center" justifyContent="space-between">
          <LogoArea />

          <MainNav />

          <MenuArea />
        </HorizontalLayout>
      </HeaderContainer>
    </Paper>
  )
}
