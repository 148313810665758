import { styled, Button } from '@mui/material'
import { CloudUpload } from '@mui/icons-material'
import { type InputHTMLAttributes, type DetailedHTMLProps } from 'react'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export function FileInputButton(props: Props) {
  return (
    <Button component="label" variant="contained" tabIndex={-1} startIcon={<CloudUpload />}>
      Upload file
      <VisuallyHiddenInput type="file" {...props} />
    </Button>
  )
}
