import type { OnSelectCallbackParams } from '@/components/StructuredVirtualList'

import { useCallback } from 'react'

import { fitMapToSelection } from '@/map'
import { setAllMainSelection } from '@/atoms'

import { getDeviceIdFromUnifiedVehicleId, isUnassociatedDevice } from '@/server-data'
import { useDataForList } from './useDataForList'

function handleOnSelect(params: OnSelectCallbackParams<'tracking'>) {
  const vehicleIds: string[] = []
  const deviceIds: string[] = []

  for (const id of params.newSelectedIds) {
    if (id.startsWith('group')) continue

    if (isUnassociatedDevice(id)) {
      deviceIds.push(getDeviceIdFromUnifiedVehicleId(id))
    } else {
      vehicleIds.push(id)
    }
  }

  setAllMainSelection({ unifiedVehicles: vehicleIds, devices: deviceIds })

  // try to select it right away and
  // queue a pending fitMap request
  // it will be applied by the destination view when it opens
  fitMapToSelection({ preventIfVisible: true }, 'add')
}

export function useListHandlers() {
  const { structure, deselectAll, selection } = useDataForList()

  const handleOnSelectAll = useCallback(() => {
    const allSelected = structure.shownItems <= selection.length

    const vehicleIds: string[] = []
    const deviceIds: string[] = []

    for (const listItem of structure.list) {
      if (typeof listItem === 'string') {
        if (isUnassociatedDevice(listItem)) {
          deviceIds.push(getDeviceIdFromUnifiedVehicleId(listItem))
        } else {
          vehicleIds.push(listItem)
        }
      }
    }

    if (allSelected) {
      deselectAll()
    } else {
      setAllMainSelection({ unifiedVehicles: vehicleIds, devices: deviceIds })

      // try to select it right away and
      // queue a pending fitMap request
      // it will be applied by the destination view when it opens
      fitMapToSelection({ preventIfVisible: true }, 'add')
    }
  }, [selection, deselectAll, structure])

  return {
    handleOnSelect,
    handleOnSelectAll,
  }
}
