import { useMemo, useCallback } from 'react'
import { parse, isBefore, isAfter } from 'date-fns/esm'

const now = new Date()

export function useChips(sourceDates: string[], onChange: (dates: Date[]) => void) {
  const dates = useMemo(() => {
    const dates = [...sourceDates]
    dates.sort(sortDates)
    return dates
  }, [sourceDates])

  const onRemoveChip = useCallback(
    (date: string) => {
      const index = sourceDates.indexOf(date)
      if (index === -1) {
        if (process.env.NODE_ENV === 'development') {
          throw new Error('cannot find date')
        }
        return
      }

      const dates = [...sourceDates]
      dates.splice(index, 1)

      onChange(dates.map(date => parse(date, 'yyyyMMdd', now)))
    },
    [onChange, sourceDates],
  )

  return {
    dates,
    onRemoveChip,
  }
}

function sortDates(a: string, b: string) {
  const dateA = parse(a, 'yyyyMMdd', now)
  const dateB = parse(b, 'yyyyMMdd', now)

  if (isBefore(dateA, dateB)) return -1
  if (isAfter(dateA, dateB)) return 1
  return 0
}
