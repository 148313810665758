import { useCallback } from 'react'
import { useNavigate } from '@/routing'
import { useController } from './useController'

export const useControllerActions = () => {
  const { close } = useController()
  const navigate = useNavigate<uui.routing.Settings>(true)

  const onGoToIntegrations = useCallback(() => {
    close?.()
    navigate('integrations')
  }, [navigate, close])

  const onCancel = useCallback(() => {
    close?.()
  }, [close])

  return {
    onGoToIntegrations,
    onCancel,
  }
}
