import { makeStyles } from '@mui/styles'

import { theme } from '@local/components'

export const useStyles = makeStyles(muiTheme => ({
  container: {
    backgroundColor: theme.colors.$pureWhite,
    height: '100%',
    width: 320,
  },
  list: {
    marginRight: muiTheme.spacing(2),
    maxHeight: '100%',
    padding: muiTheme.spacing(2),
    paddingRight: 0,
  },
}))
