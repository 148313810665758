import styled from 'styled-components'

import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from 'react'

type Props = {
  type?: string
  name?: string
  value?: string | number | string[] | undefined
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  onKeyPress?: KeyboardEventHandler<HTMLTextAreaElement>
  placeholder?: string
  readOnly?: boolean
  disabled?: boolean
}

const BaseTextArea = styled.textarea<Props>`
  width: 100%;
  height: 100%;
  outline: 0;
  border-width: 0;
  background: transparent;
  padding: 0;
  color: inherit;
  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
  resize: none;
  height: 100px;
  padding: 10px 0;

  &::placeholder {
    color: ${p => p.theme.colors.$paleSky};
  }
`

BaseTextArea.displayName = 'BaseTextArea'

export default BaseTextArea
