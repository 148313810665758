import { Box, type BoxProps } from '@mui/material'
import { type PropsWithChildren } from 'react'

type Props = BoxProps

export function IllustrationContainer(props: PropsWithChildren<Props>) {
  const { children, minHeight = 100, ...rest } = props

  // Greedy container that will take all the space available, it stops at the maxHeight
  return (
    <Box
      width="100%"
      {...rest}
      minHeight={minHeight}
      flexShrink={1}
      flexGrow={1}
      position="relative"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Box>
    </Box>
  )
}
