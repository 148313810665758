import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    available: translate({ id: 'vehicles.form.exceptions.field.available' }),
    fieldAvailable: (available: boolean) =>
      translate({
        id: available
          ? 'vehicles.form.exceptions.field.available.available'
          : 'vehicles.form.exceptions.field.available.unavailable',
      }),
    startLocation: translate({ id: 'vehicles.form.exceptions.vehicle.startLocation' }),
    endLocation: translate({ id: 'vehicles.form.exceptions.vehicle.endLocation' }),
    notSet: translate({ id: 'global.notSet' }),
    workingHours: translate({ id: 'vehicles.form.exceptions.vehicle.workingHours' }),
    workingDayStart: translate({ id: 'vehicles.form.exceptions.vehicle.workingDayStart' }),
    workingDayEnd: translate({ id: 'vehicles.form.exceptions.vehicle.workingDayEnd' }),
    workingDayEndInfo: translate({ id: 'vehicles.form.exceptions.vehicle.workingDayEnd.info' }),
    flexibleStart: translate({ id: 'vehicles.form.exceptions.vehicle.workingDayStart.flexible' }),
    breaksDuration: translate({ id: 'vehicles.form.exceptions.vehicle.breaks.duration' }),
    breaksStart: translate({ id: 'vehicles.form.exceptions.vehicle.breaks.from' }),
    breaksEnd: translate({ id: 'vehicles.form.exceptions.vehicle.breaks.to' }),
    breaksAdd: translate({ id: 'vehicles.form.exceptions.vehicle.breaks.add' }),
    breaks: translate({ id: 'vehicles.form.exceptions.vehicle.breaks' }),
    regionsList: translate({ id: 'vehicles.form.exceptions.vehicle.regions.label' }),
    constraints: translate({ id: 'vehicles.form.exceptions.vehicle.constraints' }),
    maxOrders: translate({ id: 'vehicles.form.exceptions.vehicle.maxOrders' }),
    maxDistance: (extra: string) =>
      translate({
        id: 'vehicles.form.exceptions.vehicle.maxMileage',
        values: { extra },
      }),
    maxDrivingTimeSec: (extra: string) =>
      translate({
        id: 'vehicles.form.exceptions.vehicle.maxDrivingHours',
        values: { extra },
      }),
    maxWorkingTimeSec: (extra: string) =>
      translate({
        id: 'vehicles.form.exceptions.vehicle.maxWorkingHours',
        values: { extra },
      }),
    minWorkingTimeSec: (extra: string) =>
      translate({
        id: 'vehicles.form.exceptions.vehicle.minWorkingHours',
        values: { extra },
      }),
    minWorkingTimeSecInfo: (extra: string) =>
      translate({
        id: 'vehicles.form.exceptions.vehicle.minWorkingHours.info',
        values: { extra },
      }),
    loads: translate({ id: 'vehicles.form.exceptions.vehicle.loads' }),
    tags: translate({ id: 'vehicles.form.exceptions.vehicle.tags' }),
    speedFactor: translate({ id: 'vehicles.form.exceptions.vehicle.speed' }),
    costs: translate({ id: 'vehicles.form.exceptions.vehicle.costs' }),
    activationCost: (extra: string) =>
      translate({
        id: 'vehicles.form.exceptions.vehicle.costs.fixed',
        values: { extra },
      }),

    serviceTimeCost: (extra: string) =>
      translate({
        id: 'vehicles.form.exceptions.vehicle.costs.serviceTime',
        values: { extra },
      }),
    drivingTimeCost: (extra: string) =>
      translate({
        id: 'vehicles.form.exceptions.vehicle.costs.drivingTime',
        values: { extra },
      }),
    idleTimeCost: (extra: string) =>
      translate({
        id: 'vehicles.form.exceptions.vehicle.costs.idleTime',
        values: { extra },
      }),
    breakTimeCost: (extra: string) =>
      translate({
        id: 'vehicles.form.exceptions.vehicle.costs.breakTime',
        values: { extra },
      }),
    kmCost: (extra: string, metric: boolean) =>
      translate({
        id: metric
          ? 'vehicles.form.exceptions.vehicle.costs.perKm'
          : 'vehicles.form.exceptions.vehicle.costs.perMile',
        values: { extra },
      }),
    perStopCost: (extra: string) =>
      translate({
        id: 'vehicles.form.exceptions.vehicle.costs.perStop',
        values: { extra },
      }),
    notes: translate({ id: 'vehicles.form.exceptions.vehicle.notes' }),
    preparationTime: translate({ id: 'vehicles.form.exceptions.vehicle.preparationTime' }),
    preparationTimeInfo: translate({
      id: 'vehicles.form.exceptions.vehicle.preparationTime.helper',
    }),
    closeOutTime: translate({ id: 'vehicles.form.exceptions.vehicle.closeOutTime' }),
    closeOutTimeInfo: translate({ id: 'vehicles.form.exceptions.vehicle.closeOutTime.helper' }),
  }))

  return api
}
