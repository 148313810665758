import { useCallback, useRef, useState, useEffect } from 'react'

import { useAppDispatch } from '@/store'
import { updateOrder } from '@/features/domain/order'
import { useNotification } from '@/hooks'
import { useResetEditingState } from '@/atoms'

export const useOnUpdateOrder = () => {
  const dispatch = useAppDispatch()

  const stopEditing = useResetEditingState()

  const mounted = useRef<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const toast = useNotification()

  const onUpdateOrder = useCallback(
    async (updateRequest: uui.domain.ui.forms.OrderUpdate, pristine?: boolean) => {
      try {
        if (pristine) {
          stopEditing()
          return
        }

        setSubmitting(true)

        const thunkResult = await dispatch(updateOrder(updateRequest))

        if (mounted.current) {
          setSubmitting(false)
        }

        if (!updateOrder.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, toast],
  )

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return {
    submitting,
    onUpdateOrder,
  }
}
