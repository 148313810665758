import { Grow } from '@/components/layout'

import { Single } from './components/Single'
import { Bulk } from './components/Bulk'

interface Props {
  places: uui.domain.client.gps.wwgps.Place[]
}

export function Details(props: Props) {
  const { places } = props

  if (places.length === 0) return null

  return (
    <Grow
      data-trackid="navigo-places-overview-details"
      data-testid="navigo-places-overview-details"
      xAxis
    >
      {places.length > 1 ? <Bulk places={places} /> : <Single place={places[0]} />}
    </Grow>
  )
}
