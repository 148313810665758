import { Component } from 'react'

import { Block, Label, FieldMessage, Barcodes, RestoreFieldButton } from '@/forms-legacy'

import { Props } from './typings'

type BarcodeError = {
  invalidValues: string[]
  text: string
}

export default class BarcodesField extends Component<Props> {
  render() {
    const {
      label,
      name,
      info,
      half = false,
      wrappedInputProps,
      formProps: {
        input: { value, onChange },
        meta: { error: rawError, dirty, initial },
      },
      testid,
    } = this.props

    const error = rawError as BarcodeError

    const invalidValues = new Set<string>(error?.invalidValues || [])

    return (
      <Block half={half} error={!!error} dirty={!!dirty} data-testid={testid}>
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <Barcodes
          {...wrappedInputProps}
          onChange={onChange}
          value={value}
          invalidValues={invalidValues}
        />
        {!!error && <FieldMessage error>{error.text}</FieldMessage>}
        {!!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }
}
