import type { ReactNode } from 'react'

import { useState, useCallback } from 'react'

export interface ExtraProps {
  collapsed: boolean
  itemsCount: number
  itemsThreshold: number
  onShowAll: () => void
  onHideAll: () => void
  onToggle: () => void
}

interface Props<T> {
  list: T[]
  renderListItem: (item: T, extraProps: ExtraProps) => ReactNode
  itemsThreshold: number
  renderHeader?: (extraProps: ExtraProps) => ReactNode
  renderLoadMore?: (extraProps: ExtraProps) => ReactNode
}

export function CollapsibleList<T>(props: Props<T>) {
  const [collapsed, setCollapsed] = useState(true)

  const onHideAll = useCallback(() => {
    setCollapsed(false)
  }, [])

  const onShowAll = useCallback(() => {
    setCollapsed(true)
  }, [])

  const onToggle = useCallback(() => {
    setCollapsed(prev => !prev)
  }, [])

  const { list, itemsThreshold, renderHeader, renderLoadMore, renderListItem } = props

  const extraProps: ExtraProps = {
    collapsed,
    itemsCount: list.length,
    itemsThreshold,
    onHideAll: onHideAll,
    onShowAll: onShowAll,
    onToggle: onToggle,
  }

  const listToRender: T[] =
    list.length > itemsThreshold && collapsed ? list.slice(0, itemsThreshold) : list

  return (
    <>
      {!!renderHeader && renderHeader(extraProps)}
      {listToRender.map((item: T) => renderListItem(item, extraProps))}
      {!!renderLoadMore && renderLoadMore(extraProps)}
    </>
  )
}
