import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function ShowLabelDepot(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 19 22">
        <path d="M3.297 16.696a9.5 9.5 0 1 1 12.405 0A3 3 0 0 0 13 15H6a3 3 0 0 0-2.703 1.696zM9.5 5L5.45 7.931 5 8.256v4.508c0 .13.101.236.225.236H7.7a.231.231 0 0 0 .225-.236v-2.12c0-.91.707-1.65 1.575-1.65s1.575.74 1.575 1.65v2.12c0 .13.101.236.225.236h2.475a.231.231 0 0 0 .225-.236V8.26l-.45-.325L9.5 5z" />
        <path d="M6 16h7a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2zm1.121 3.604a.46.46 0 0 0 .129.336.43.43 0 0 0 .321.132.42.42 0 0 0 .318-.132.467.467 0 0 0 .126-.336.467.467 0 0 0-.126-.336.42.42 0 0 0-.318-.132.43.43 0 0 0-.321.132.46.46 0 0 0-.129.336zm1.998 0a.46.46 0 0 0 .129.336.43.43 0 0 0 .321.132.42.42 0 0 0 .318-.132.467.467 0 0 0 .126-.336.467.467 0 0 0-.126-.336.42.42 0 0 0-.318-.132.43.43 0 0 0-.321.132.46.46 0 0 0-.129.336zm1.998 0a.46.46 0 0 0 .129.336.43.43 0 0 0 .321.132.42.42 0 0 0 .318-.132.467.467 0 0 0 .126-.336.467.467 0 0 0-.126-.336.42.42 0 0 0-.318-.132.43.43 0 0 0-.321.132.46.46 0 0 0-.129.336z" />
      </svg>
    </IconContainer>
  )
}
