import { format, addSeconds, startOfToday } from 'date-fns/esm'

export function isMultipleValues(levels: number[]) {
  if (levels.length < 2) return false
  return levels.some(level => level !== levels[0])
}

export function getDrivingTimeFactorLabel(factor: number) {
  return `${getDrivingTimeFactorFromSpeedFactor(factor)}x`
}

function getDrivingTimeFactorFromSpeedFactor(speedFactor: number) {
  return parseFloat((speedFactor / 100 + 1).toFixed(2))
}

export function secToTimeHHmm(
  sec: number,
  openLabel: string = '',
  timeFormat: uui.domain.client.TimeFormat = 'hh:mm aa',
) {
  if (!Number.isFinite(sec)) return ''
  if (sec === -1) return openLabel

  const time = addSeconds(startOfToday(), sec)
  return format(time, timeFormat)
}
