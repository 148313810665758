import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../../../hooks/useController'
import { useTexts } from '../../../hooks/useTexts'

export function SingleDriver() {
  const { close } = useController()
  const texts = useTexts()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton testid="modal-delete-drivers-cancel" onClick={close} variant="contained">
          {texts.comeBackLater}
        </ModalFooterButton>
      }
    />
  )
}
