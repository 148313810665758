import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    editLabel: translate({ id: 'settings.integrations.view.telematics.editLabel' }),
    editCredentials: translate({ id: 'settings.integrations.view.telematics.editCredentials' }),
    editNameAndCredentials: translate({
      id: 'settings.integrations.view.telematics.editNameAndCredentials',
    }),
    addNewConnection: translate({ id: 'settings.integrations.view.telematics.addNewConnection' }),
    deleteConnection: translate({ id: 'settings.integrations.view.telematics.deleteConnection' }),
    headerTitle: translate({ id: 'settings.integrations.view.headerTitle' }),
    headerDescription: translate({ id: 'settings.integrations.view.headerDescription' }),
    noActivityYet: translate({
      id: 'settings.integrations.view.telematics.integrationDevicesTable.column.lastActivity.noActivityTooltip',
    }),
    activeIntegrationsTitle: translate({
      id: 'settings.integrations.view.telematics.activeIntegrations.title',
    }),
    discoverIntegrations: translate({
      id: 'settings.integrations.view.telematics.discoverIntegrations.title',
    }),
    seeAllIntegrations: translate({
      id: 'settings.integrations.view.telematics.seeAllIntegrations',
    }),
    azugaDescription: translate({ id: 'settings.integrations.view.telematics.azuga.description' }),
    linxupDescription: translate({
      id: 'settings.integrations.view.telematics.linxup.description',
    }),
    verizonDescription: translate({
      id: 'settings.integrations.view.telematics.verizon.description',
    }),

    noTrackingAlertTitle: translate({
      id: 'settings.integrations.view.telematics.noTrackingAlert.title',
    }),
    noTrackingAlertDescription: translate({
      id: 'settings.integrations.view.telematics.noTrackingAlert.description',
    }),

    otherTrackingProviderAlertTitle: translate({
      id: 'settings.integrations.view.telematics.otherTrackingProviderAlert.title',
    }),
    otherTrackingProviderAlertDescription: translate({
      id: 'settings.integrations.view.telematics.otherTrackingProviderAlert.description',
    }),
    deleting: translate({ id: 'global.deleting' }),
    integrations: translate({ id: 'settings.integrations.view.telematics.integrations' }),
    errorCode: (errorCode: string) =>
      translate({ id: 'settings.integrations.view.telematics.errorCode', values: { errorCode } }),
    tenantSourceErrorMessages: {
      tooltip: {
        3000: translate({
          id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3000.tooltip',
        }),
        3100: translate({
          id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3100.tooltip',
        }),
        3500: translate({
          id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3500.tooltip',
        }),
        3550: translate({
          id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3550.tooltip',
        }),
        3600: translate({
          id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3600.tooltip',
        }),
        4000: translate({
          id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.4000.tooltip',
        }),
        4100: translate({
          id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.4100.tooltip',
        }),
        4500: translate({
          id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.4500.tooltip',
        }),
        10100: translate({
          id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.10100.tooltip',
        }),
        10110: translate({
          id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.10110.tooltip',
        }),
      },
      toast: {
        title: {
          3000: (source: string) =>
            translate({
              id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3000.toast.title',
              values: { source },
            }),
          3100: (source: string) =>
            translate({
              id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3100.toast.title',
              values: { source },
            }),
          3500: (source: string) =>
            translate({
              id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3500.toast.title',
              values: { source },
            }),
          3550: (source: string) =>
            translate({
              id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3550.toast.title',
              values: { source },
            }),
          3600: (source: string) =>
            translate({
              id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3600.toast.title',
              values: { source },
            }),
          10100: (source: string) =>
            translate({
              id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.10100.toast.title',
              values: { source },
            }),
          10110: (source: string) =>
            translate({
              id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.10110.toast.title',
              values: { source },
            }),
        },
        description: {
          3550: (source: string) =>
            translate({
              id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3550.toast.description',
              values: { source },
            }),
          3600: (source: string) =>
            translate({
              id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.3600.toast.description',
              values: { source },
            }),
          10100: (source: string) =>
            translate({
              id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.10100.toast.description',
              values: { source },
            }),
          10110: (source: string) =>
            translate({
              id: 'settings.integrations.view.telematics.tenantSourceErrorMessages.10110.toast.description',
              values: { source },
            }),
        },
      },
    },

    totalUsedLicenses: (usedLicenses: number) =>
      translate({
        id: 'settings.integrations.view.telematics.totalUsedLicenses',
        values: { usedLicenses },
      }),
    ofAvailable: translate({
      id: 'settings.integrations.view.telematics.totalUsedLicenses.available',
    }),
    totalLicencesAvailable: (totalLicenses: number) =>
      translate({
        id: 'settings.integrations.view.telematics.totalLicencesAvailable',
        values: { totalLicenses },
      }),

    licensesInUse: translate({ id: 'settings.integrations.view.telematics.licensesInUse' }),
    integrationSource: translate({ id: 'settings.integrations.view.telematics.integrationSource' }),
    integrationDevicesTable: {
      header: {
        placeholder: translate({
          id: 'settings.integrations.view.telematics.integrationDevicesTable.header.placeholder',
        }),
      },
      localeTexts: {
        noRowsLabel: translate({
          id: 'settings.integrations.view.telematics.integrationDevicesTable.noRows',
        }),
      },
      column: {
        name: {
          title: translate({
            id: 'settings.integrations.view.telematics.integrationDevicesTable.column.name.title',
          }),
        },
        vehicle: {
          title: translate({
            id: 'settings.integrations.view.telematics.integrationDevicesTable.column.vehicle.title',
          }),
        },
        territory: {
          title: translate({
            id: 'settings.integrations.view.telematics.integrationDevicesTable.column.territory.title',
          }),
        },
        lastActivity: {
          title: translate({
            id: 'settings.integrations.view.telematics.integrationDevicesTable.column.lastActivity.title',
          }),
        },
      },
    },

    noIntegrations: {
      title: translate({ id: 'settings.integrations.view.telematics.noIntegrations.title' }),
      description: translate({
        id: 'settings.integrations.view.telematics.noIntegrations.description',
      }),
      action: translate({ id: 'settings.integrations.view.telematics.noIntegrations.action' }),
      noLicenses: {
        alert: translate({
          id: 'settings.integrations.view.telematics.noIntegrations.noLicenses',
        }),
        tooltip: translate({
          id: 'settings.integrations.view.telematics.noIntegrations.noLicenses.tooltip',
        }),
      },
    },
    reconnect: translate({
      id: 'global.reconnect',
    }),
  }))

  return api
}
