import styled from 'styled-components'

type Props = {
  centered?: boolean
  inheritBg?: boolean
}

const ACTIONS_MIN_WIDTH = 30
const ACTIONS_MAX_WIDTH = 50

const SecondaryColumnHeaderActionsRow = styled.div<Props>`
  position: relative;
  flex: 0 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${p => (p.centered ? 'center' : 'space-evenly')};
  width: 100%;
  height: 60px;
  padding: 0 50px 2px;

  ${p => (p.inheritBg ? 'background: inherit;' : '')}

  & > * {
    min-width: ${ACTIONS_MIN_WIDTH}px;
    max-width: ${ACTIONS_MAX_WIDTH}px;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  & > * + * {
    margin-left: ${p => (p.centered ? 20 : 0)}px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -8px;
    left: 0;
    right: 0;
    height: calc(100% + 8px);
    background: ${p => p.theme.colors.$whiteSmoke};
  }
`

SecondaryColumnHeaderActionsRow.displayName = 'SecondaryColumnHeaderActionsRow'
export default SecondaryColumnHeaderActionsRow
