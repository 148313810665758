import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

interface Payload {
  password: string
  driverId: string
}

/**
 * Update a Driver.
 *
 * @private
 */
export const updatePassword = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/driver/updatePassword', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/driver/updatePassword', {
      category: 'rpc',
      type: 'rpc/driver/updatePassword',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {updateDriverPassword}`,
      { tags: ['rpc', 'driver'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Update driver password Failed`,
      error,
    })
  }
})
