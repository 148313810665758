import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    filterPlaceholder: translate({
      id: 'form.drivers.header.filterPlaceholder',
    }),
  }))

  return api
}
