import type { MenuOption } from '../types'

import { useState, useCallback } from 'react'

import { CircularProgress, IconButton, MenuItem, Stack } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { DropdownMenu } from '@/components/DropdownMenu'

import { Tooltip } from '@/components/primitives/Tooltip'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { useTexts } from '../hooks/useTexts'

interface Props {
  moreActions: MenuOption[]
}

const fullWidthSx = { width: '100%' }

export function PopMenu(props: Props) {
  const { moreActions } = props

  const texts = useTexts()

  const [open, setOpen] = useState(false)

  const handleClose = useCallback(async (option: MenuOption) => {
    await option.action()
    setOpen(false)
  }, [])

  return (
    <DropdownMenu
      open={open}
      setOpen={setOpen}
      autoWidth
      trigger={
        <Tooltip placement="top" title={texts.more}>
          <IconButton component="span">
            <MoreVert />
          </IconButton>
        </Tooltip>
      }
    >
      {moreActions.map(option => (
        <ReadOnlyTooltip
          key={option.id}
          ignoreReasons={option.ignoreReasons ?? []}
          render={preventEditing => (
            <MenuItem
              sx={fullWidthSx}
              color={option.color}
              onClick={() => handleClose(option)}
              disabled={preventEditing}
              data-testid="menu-list-item"
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center" width={150}>
                {option.title}
                {option.validating && <CircularProgress size={12} color="primary" />}
              </Stack>
            </MenuItem>
          )}
        />
      ))}
    </DropdownMenu>
  )
}
