import type { ChipShape, CoreItem, Modes, OrderChip } from '../../../components/Navigator/typings'

import { Component } from 'react'

import { clsx } from '@/utils'
import { theme } from '@/local/components'
import { isDark, getColorValue } from '@/styles'

import { getBackgroundColor, getRows } from '../utils'
import { Chip } from '../../../components/Navigator/components/Chip'

const defaultColor: string = theme.colors.$shadyLady

export interface Props<T extends CoreItem, K> {
  item: T
  mode: Modes
  selected?: boolean
  onClick: () => void
  className?: string
  extraItemRendererProps: K
  loopIndex: number
}

export class OrderItemRenderer extends Component<Props<OrderChip, Record<string, any>>> {
  render() {
    const {
      item: { source },
      onClick,
      className: cls = '',
    } = this.props

    const { isUnassigned } = source

    const color = !source.isUnassigned ? getColorValue(source.color) : defaultColor
    const violations = !source.isUnassigned ? source.violations : []
    const hasBeenExecuted = !source.isUnassigned && source.hasBeenExecuted
    const routeStepLabel = !source.isUnassigned ? source.routeStepLabel : undefined

    const className = clsx({
      [cls]: true,
      'o-order-chip': true,
    })

    const background = getBackgroundColor(!!hasBeenExecuted, !!isUnassigned, color)
    const textColor = isDark(background) ? theme.colors.$pureWhite : theme.colors.$nightRider

    const chipProps = {
      ...getRows(routeStepLabel),
      color: textColor,
      background,
      borderColor: isUnassigned ? theme.colors.$silver : undefined,
      onClick,
      shape: 'squared' as ChipShape,
      sliceColor: hasBeenExecuted ? color : undefined,
      className,
      hasViolation: Array.isArray(violations) && violations.length > 0,
    }
    return <Chip {...chipProps} />
  }
}
