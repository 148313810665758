import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'

import { useApplicationMode } from '@/atoms'
import { Trash } from '@/icons'

import { useActions } from '../hooks/useActions'
import { useTexts } from '../hooks/useTexts'

interface Props {
  view: 'default' | 'delete'
  setView: (view: 'default' | 'delete') => void
}

export function Actions(props: Props) {
  const { setView } = props
  const [appMode] = useApplicationMode()
  const actions = useActions(setView)
  const texts = useTexts()

  const readonly = appMode === 'readonly'

  return (
    <SecondaryColumnHeaderActionsRow>
      <SecondaryColumnHeaderActionButton
        label={texts.delete}
        icon={<Trash />}
        onClick={actions.onDelete}
        disabled={readonly}
        testid="geofence-delete"
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
