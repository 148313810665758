import type {
  TerritoryConfig as TerritoryConfigType,
  TerritoryConfigError,
} from '../../../../../../types'

import { useCallback } from 'react'
import { Stack, Divider } from '@mui/material'

import { Header } from './components/Header'
import { DriversRestriction } from './components/DriversRestriction'
import { VehiclesRestriction } from './components/VehiclesRestriction'

interface Props {
  territoryName: string
  territoryId: string

  value: TerritoryConfigType
  error?: TerritoryConfigError
  onChange: (territoryId: string, value: TerritoryConfigType) => void
  onRemoveTerritory: (territoryId: string) => void

  territoriesConfig: uui.domain.client.rm.TerritoriesDriversAndVehicles
}

export function TerritoryConfig(props: Props) {
  const {
    value,
    error,
    onChange,
    territoryId,
    territoryName,
    onRemoveTerritory,
    territoriesConfig,
  } = props

  const handleOnRemoveTerritory = useCallback(() => {
    onRemoveTerritory(territoryId)
  }, [territoryId, onRemoveTerritory])

  const handleOnChange = useCallback(
    (value: TerritoryConfigType) => {
      onChange(territoryId, value)
    },
    [territoryId, onChange],
  )

  return (
    <Stack spacing={2} data-testid={territoryId}>
      <Header title={territoryName} onRemoveTerritory={handleOnRemoveTerritory} />
      <VehiclesRestriction
        territoryId={territoryId}
        fieldValue={value}
        error={error}
        onChange={handleOnChange}
        territoriesConfig={territoriesConfig}
      />
      <DriversRestriction
        territoryId={territoryId}
        fieldValue={value}
        error={error}
        onChange={handleOnChange}
        territoriesConfig={territoriesConfig}
      />

      <span>
        {/* the negative margin is required to let the divider to be
        attached to the left and right corners of the container */}
        <Divider sx={{ marginLeft: -4, width: 'calc(100% + 64px)' }} />
      </span>
    </Stack>
  )
}
