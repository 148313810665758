import { applyAlphaToColor } from '@/local/components'
import { styled, Button } from '@mui/material'
import { theme } from '@/styles'

export const navigationButtonWidth = 20

export const NavigationButton = styled(Button, {
  name: 'NavigationButton',
})(({ disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: `${navigationButtonWidth}px`,
  maxWidth: `${navigationButtonWidth}px`,
  height: `${navigationButtonWidth}px`,
  padding: 0,
  margin: 0,
  outline: 0,
  borderWidth: 0,
  borderRadius: '4px',
  background: 'transparent',
  color: disabled ? theme.colors.$whiteSmoke : theme.colors.$nightRider,
  cursor: disabled ? 'default' : 'pointer',
  transition: 'background 0.2s ease, color 0.2s ease',
  '&:hover': {
    color: disabled ? theme.colors.$shadyLady : theme.colors.$scienceBlue,
    background: disabled ? 'transparent' : applyAlphaToColor(theme.colors.$scienceBlue, 0.1),
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
}))
