import { ListHeaderCounter, ListHeaderTitle, ListHeaderTitleContainer } from '@/components/List'

import { useTexts } from './hooks/useTexts'
import { useTitle } from './hooks/useTitle'

export function Title() {
  const { editing, selection, listStructure, onSelectAll, noEntries } = useTitle()

  const texts = useTexts()

  return (
    <ListHeaderTitleContainer>
      <ListHeaderTitle>{texts.title}</ListHeaderTitle>

      <ListHeaderCounter
        allSelected={selection.length >= listStructure.shownItems}
        disabled={editing}
        onClick={onSelectAll}
        readonly={noEntries}
      >
        {listStructure.shownItems}
      </ListHeaderCounter>
    </ListHeaderTitleContainer>
  )
}
