import { useSelector } from 'react-redux'

import { selectTerritory } from '@/features/domain/territory'
import { selectUserConfiguration, selectUserType } from '@/features/domain/user'

export function useUserAndTerritoryType() {
  const userType = useSelector(selectUserType)
  const territory = useSelector(selectTerritory)
  const userConfig = useSelector(selectUserConfiguration)

  const isAdmin = userType === 'admin'
  const isRmOnly = userConfig.userType === 'rmOnly'

  const noNotificationsAllowed = territory.notificationBehavior === 'NO_NOTIFICATIONS'

  if (!isAdmin) return 'other'

  if (isRmOnly) return 'rmOnlyAdmin'

  if (noNotificationsAllowed) return 'rm360AdminNoNotificationsAllowed'

  return 'rm360Admin'
}
