import { FormError, FormField } from '@workwave-tidal/tidal/form-fairy'

const disclaimerDataNoMoreCollecting: FormField<boolean> = {
  value: false,
}

const disclaimerVehicleAssociationDeletion: FormField<boolean> = {
  value: false,
}

const disclaimerPermanentAction: FormField<boolean> = {
  value: false,
}

export const formFields = {
  disclaimerPermanentAction,
  disclaimerDataNoMoreCollecting,
  disclaimerVehicleAssociationDeletion,
}

export type FormFields = typeof formFields
export type FormErrors = FormError<keyof FormFields>
