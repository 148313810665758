import { Box } from '@mui/material'
import { theme } from '@/styles'

export function Separator() {
  return (
    <Box
      height={2}
      width="100%"
      bgcolor={theme.colors.$whiteSmoke}
      marginTop={2}
      marginBottom={2}
    />
  )
}
