import { useVehicleFormException } from '@/atoms'
import { Exceptions } from './Exceptions'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function ExceptionProvider(props: Props) {
  const { unifiedVehicle } = props
  const [{ exception }] = useVehicleFormException()

  if (!unifiedVehicle.hasRoutingLicense) return null

  const vehicleSettings = unifiedVehicle.vehicle.settings[exception]
  if (!vehicleSettings) return null

  return <Exceptions vehicleSettings={vehicleSettings} unifiedVehicle={unifiedVehicle} />
}
