import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    sortByAscending: translate({ id: 'deviceEvents.list.sorter.sortBy.ascending' }),
    sortByDescending: translate({
      id: 'deviceEvents.list.sorter.sortBy.descending',
    }),
    sortAscending: translate({ id: 'deviceEvents.list.sorter.sortBy.direction.ascending' }),
    sortDescending: translate({ id: 'deviceEvents.list.sorter.sortBy.direction.descending' }),
  }))

  return api
}
