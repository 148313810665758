import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function OrdersGrid(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M13.67 4c.5523 0 1 .44772 1 1v14c0 .5523-.4477 1-1 1h-3.34c-.55228 0-1-.4477-1-1V5c0-.55228.44772-1 1-1h3.34Zm3 15c0 .5523.4477 1 1 1H21c.5523 0 1-.4477 1-1V5c0-.55228-.4477-1-1-1h-3.33c-.5523 0-1 .44772-1 1v14ZM6.33 20c.55229 0 1-.4477 1-1V5c0-.55228-.44772-1-1-1H3c-.55229 0-1 .44772-1 1v14c0 .5523.44772 1 1 1h3.33Z" />
      </svg>
    </IconContainer>
  )
}
