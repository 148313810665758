import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { findTrafficAreaMarker } from './findTrafficAreaMarker'
import { updateTrafficAreaFeature } from './updateTrafficAreaFeature'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Update Traffics features when related Map styles change
 */
export async function mapStylesUpdateTrafficAreaFeatures(
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.Area>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['trafficProfile'],
  selection: Set<string>,
) {
  const features = layer.getSource()?.getFeatures()

  if (!features) {
    throw new Error('Features not found (source is undefined)')
  }

  nonblockingRender.reset()

  for (let index = 0; index < features.length; index++) {
    await nonblockingRender.next()

    const feature = features[index]

    if (!feature) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Trying to update Traffic marker style but no feature exist at index ${index}`,
        )
      }

      continue
    }

    const featureId = feature.getId() as string | undefined

    if (!featureId) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Trying to update Traffic marker style but the feature found at index ${index} has no ID`,
        )
      }

      continue
    }

    const marker = findTrafficAreaMarker(markers, featureId)
    const customMode = marker.mode

    // do not process marker with custom mode
    // custom mode is are managed through a marker changelog
    if (customMode) continue

    const selected = selection.has(marker.id)
    updateTrafficAreaFeature(mapStyles, marker, feature, selected)
  }
}
