import { WarningTriangle, InfoCartoonCloud } from '@/icons'
import { useTexts } from '../useTexts'

export function useWarningCardData(territory: uui.domain.client.rm.Territory) {
  const texts = useTexts()

  switch (territory.statusReasons.length) {
    case 0:
      return {
        Icon: <InfoCartoonCloud size={14} color="$scienceBlue" />,
        description: texts.addWarningCardDescription,
        title: texts.addWarningCardTitle,
        preset: 'info',
      } as const

    default:
      return {
        Icon: <WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />,
        description: texts.replaceWarningCardDescription,
        title: texts.replaceWarningCardTitle,
        preset: 'warning',
      } as const
  }
}
