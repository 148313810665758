import * as durationUtils from '../../utils/smartUtils/duration'

export const isStepTypeOrder = (type: uui.domain.client.rm.RouteStep['type']): boolean =>
  type === 'pickup' || type === 'delivery'

export const isOrderRelated = ({ type }: uui.domain.client.rm.RouteStep): boolean =>
  isStepTypeOrder(type)

export const isStepTypeVehicle = (type: uui.domain.client.rm.RouteStep['type']): boolean =>
  type === 'departure' || type === 'arrival'

export const isVehicleRelated = ({ type }: uui.domain.client.rm.RouteStep): boolean =>
  isStepTypeVehicle(type)

export const isStepTypeBreak = (type: uui.domain.client.rm.RouteStep['type']): boolean =>
  type === 'brk'

export const isBreak = ({ type }: uui.domain.client.rm.RouteStep): boolean => isStepTypeBreak(type)

export const is = ({ type }: uui.domain.client.rm.RouteStep, otherType: string): boolean =>
  type === otherType

export const getServiceTimeSec = (step: uui.domain.client.rm.RouteStep): number => {
  const { endSec, startSec } = step
  return isOrderRelated(step) ? endSec - startSec : 0
}

export const getBreakTimeSec = ({
  type,
  endSec,
  startSec,
}: uui.domain.client.rm.RouteStep): number => (type === 'brk' ? 0 : endSec - startSec)

export const getDepartureTimeSec = (route: uui.domain.client.rm.Route): number => {
  return route.steps.length > 0 ? getScheduleSec(route.steps[0]) : -1
}

export const getArrivalTimeSec = (route: uui.domain.client.rm.Route): number => {
  if (!route.steps.length) {
    return -1
  }

  const step = route.steps[route.steps.length - 1]
  return getScheduleSec(step) + getServiceTimeSec(step)
}

// export const getRouteScheduleSec = (route: uui.domain.client.rm.Route, idx: number): number => {
//   return getScheduleSec(route[idx])
// }

export const getScheduleSec = ({
  type,
  endSec,
  arrivalSec,
  startSec,
}: uui.domain.client.rm.RouteStep): number => {
  if (type === 'departure') {
    return endSec
  } else if (type === 'arrival') {
    return arrivalSec
  }
  return startSec
}

export function getStopsCount(
  route: uui.domain.client.rm.Route,
  vehicleSettings: uui.domain.server.rm.VehicleSettings,
): number {
  const count = route.steps.reduce<number>(
    (count, step) => (isOrderRelated(step) ? step.stopIdx : count),
    0,
  )

  return vehicleSettings.departureDepotId ? count : count + 1
}

export const getRouteDepartureSec = (steps: uui.domain.server.rm.RouteStep[] = []) => {
  return steps.length > 0 ? getRouteStepSec(steps[0]) : -1
}

export const getRouteArrivalSec = (steps: uui.domain.server.rm.RouteStep[] = []) => {
  return steps.length > 0 ? getRouteStepSec(steps[steps.length - 1], true) : -1
}

const getRouteStepSec = (step: uui.domain.server.rm.RouteStep, end?: boolean): number => {
  switch (step.type) {
    case 'departure':
      return step.endSec

    case 'arrival':
      return step.arrivalSec

    default:
      return end ? step.endSec : step.startSec
  }
}

export const getOrderStepIdsFromRouteSteps = (
  steps: uui.domain.server.rm.RouteStep[],
): string[] => {
  return steps.reduce<string[]>((list, step) => {
    if (step.ref) {
      list.push(step.type === 'pickup' ? `${step.ref}-p` : `${step.ref}-d`)
    }
    return list
  }, [])
}

// ----------------------------------------------------------------------------------

interface ApprovedRouteStats {
  mileage: number
  timeSec: number
  drivingTime: number
}

export const getApprovedRouteStats = (route: uui.domain.client.rm.Route): ApprovedRouteStats => {
  const { steps } = route

  let mileage: number = 0
  let drivingTime: number = 0

  steps.forEach(({ distanceToNextMt, driveToNextSec }: uui.domain.client.rm.RouteStep) => {
    mileage += distanceToNextMt > 0 ? distanceToNextMt : 0
    drivingTime += driveToNextSec > 0 ? driveToNextSec : 0
  })

  return {
    mileage,
    drivingTime,
    timeSec: getArrivalTimeSec(route) - getDepartureTimeSec(route),
  }
}

export const getViolationsCount = (violations: uui.domain.rm.RouteViolations): number => {
  const loads = violations?.loads ? violations.loads.length : 0
  const steps = violations?.steps
    ? Object.entries(violations.steps).reduce(
        (total: number, [, errors]) => total + errors.length,
        0,
      )
    : 0
  const vehicle = violations?.vehicle ? violations.vehicle.length : 0

  // if loads violations  we have to remove 1 to the count of violations
  // otherwise we count 1 violations that  is already included in the vehicle violations
  const compensation: number = loads > 0 ? -1 : 0

  return loads + steps + vehicle + compensation
}

export const getRouteStepId = (step: uui.domain.client.rm.RouteStep): string => {
  if (isOrderRelated(step)) {
    return step.ref ? `${step.ref}-${step.type.charAt(0)}` : `routeStep-${step.stopIdx}`
  }

  if (isVehicleRelated(step)) {
    return step.type
  }

  if (isBreak(step)) {
    return `${step.type}-${step.startSec}-${step.endSec}`
  }

  return ''
}

export const getOrderStepSymbol = (orderStep: uui.domain.client.rm.OrderStep): string => {
  return orderStep.type === 'p' ? '▴' : '▾'
}

export const getServiceTimeLabel = (
  translate: uui.domain.intl.Translate,
  orderStep?: uui.domain.client.rm.OrderStep,
): string => {
  const duration = orderStep
    ? durationUtils.formatSeconds(translate)(orderStep.serviceTimeSec, 'EXTRA_SHORT')
    : ''
  const serviceTimeLabel = orderStep ? `${getOrderStepSymbol(orderStep)} ${duration}` : ''

  return serviceTimeLabel
}
