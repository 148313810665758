import styled from 'styled-components'

export const ReadonlyTimeWindowExceptionsContainer = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  > * + * {
    margin-top: 10px;
  }
`

ReadonlyTimeWindowExceptionsContainer.displayName = 'ReadonlyTimeWindowExceptionsContainer'
