import { useState, useCallback, MouseEvent } from 'react'

import { IconButton, Tooltip } from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'

import { DropdownMenu, DropdownMenuItem } from '@/components/DropdownMenu'

import { useTexts } from './useTexts'

interface Props {
  onDeleteAllTags: () => void
  deleteAllTagsDisabled?: boolean
}

const noop = (e: MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault()
  return
}

export function Menu(props: Props) {
  const { onDeleteAllTags, deleteAllTagsDisabled } = props

  const [open, setOpen] = useState(false)
  const texts = useTexts()

  const onRemoveAllAndCloseMenu = useCallback(() => {
    onDeleteAllTags()
    setOpen(false)
  }, [onDeleteAllTags])

  return (
    <DropdownMenu
      setOpen={setOpen}
      open={open}
      autoWidth
      trigger={
        // href="#" onClick={noop} ==> Hack to have an <a> component and not a button
        <Tooltip title={texts.moreTooltip}>
          <IconButton href="#" onClick={noop}>
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <Tooltip title={deleteAllTagsDisabled ? texts.deleteAllTagsDisabledTooltip : ''}>
        <span>
          <DropdownMenuItem
            size="$l"
            weight="$regular"
            onClick={onRemoveAllAndCloseMenu}
            testid="bulk-tags-remove-all"
            warn
            disabled={deleteAllTagsDisabled}
          >
            {texts.removeAll}
          </DropdownMenuItem>
        </span>
      </Tooltip>
    </DropdownMenu>
  )
}
