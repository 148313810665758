import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCustomerNotificationsSettings } from '@/features/domain/territory'

export type AlmostThereNotificationValues = ReturnType<typeof useAlmostThereNotificationValues>

/**
 * That hook grabs almostThereNotification values from the domains, than filter some of them out and
 * does some type conversion where required
 */
export function useAlmostThereNotificationValues() {
  const notificationSettings = useSelector(selectCustomerNotificationsSettings)

  return useMemo(() => {
    const { proximityThresholdMins, ...rest } = notificationSettings['almostThereSettings']

    return {
      ...rest,
      proximityThresholdMins: proximityThresholdMins.toString(),
    }
  }, [notificationSettings])
}
