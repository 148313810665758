import { Stack, Typography } from '@mui/material'

import { useTexts } from '../../../../useTexts'

import { Username } from './components/Username'
import { Password } from './components/Password'

export function VerizonConnect() {
  const texts = useTexts()

  return (
    <Stack spacing={2} width="100%">
      <Typography variant="caption">{texts.verizonConnect.instructions}</Typography>

      <Username />
      <Password />
    </Stack>
  )
}
