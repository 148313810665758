export const colorNames = {
  '269AFD': 'Dodger Blue',
  '0088D3': 'Blue Cola',
  A8D7FA: 'Non-Photo Blue',
  '87D1EE': 'Baby Blue',
  '5EBAD9': 'Blue Jeans',
  '5E79BC': 'Glaucous',
  '1E538F': 'Cobalt Blue',
  '091B65': 'Cool Blue ',
  '32CFBC': 'Maximun Blue-Green',
  '0FB1B4': 'Tiffany',
  '94C6D1': 'Sky Blue',
  '94CAB3': 'Turquoise Green',
  '32BC97': 'Mountain Meadow',
  '638A8D': 'Steel Teal',
  '117C8E': 'Metallic Seaweed',
  '0D4D59': 'Midnight Green',
  '80E220': 'Alien',
  '50B86B': 'Emerald',
  A2E384: 'Light Green',
  B1CB5D: 'Green-Yellow',
  BBAE5F: 'Olive',
  A3B67E: 'Olivine',
  '8F9A4B': 'Moss Green',
  '536B3B': 'Dark Olive',
  FF9D14: 'Dark Tangerine',
  F0C366: 'Tangerine Yellow',
  FDD8A3: 'Deep Champagne',
  FFDA3A: 'Banana',
  FECB4D: 'Summer Yellow',
  D8C9AF: 'Dark Vanilla',
  EFBC8D: 'Powder Pink',
  '89411B': 'Saddle Brown',
  ED4A11: 'Vitamin Orange',
  DD2A0C: 'Vermilion',
  DE7B66: 'Terra Cotta',
  C54C1F: 'Brick Red',
  D57074: 'Candy Pink',
  C56A3D: 'Copper',
  '9B151C': 'Ruby Red',
  '623A2E': 'Chocolate',
  '8A38F5': 'Violet',
  '745EC5': 'Indigo',
  A990DD: 'Lilac',
  E580C9: 'Orchid pink',
  FEA5A9: 'Flamingo',
  D38DAB: 'Lavender',
  A5758B: 'Mulberry',
  '474E95': 'Amethyst',
}
