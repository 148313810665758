import type {
  FormField,
  FormSingleField,
  UseFormFieldOptions,
  NarrowFieldsByValueType,
} from '@workwave-tidal/tidal/form-fairy'

import type { CourierFilterError } from '../../../../../types'

import { useCallback, useRef } from 'react'

import { Dialog, Stack, Box, FormHelperText } from '@mui/material'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'

import { TerritoriesList } from './components/TerritoriesList'
import { EmptyTerritories } from './components/EmptyTerritories'
import { AddTerritory } from './components/AddTerritory'
import { Header } from './components/Header'
import { useDialogState } from './hooks/useDialogState'
import { useTerritories } from './hooks/useTerritories'

type RequiredFormField = uui.domain.client.rm.CourierFilter

type Props<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends CourierFilterError = CourierFilterError,
> = {
  name: FIELD_NAME
  territoriesConfig: uui.domain.client.rm.TerritoriesDriversAndVehicles
  territoryNames: Record<string, string> // <territoryId, territoryName>
} & UseFormFieldOptions<FIELDS, ERROR>

const transitionDuration = { exit: 100 }

export function CourierFilterField<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends CourierFilterError = CourierFilterError,
>(props: Props<FIELD_NAME, FIELDS, ERROR>) {
  const { name, territoriesConfig, territoryNames, ...options } = props

  const rootRef = useRef<HTMLDivElement | null>(null)

  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const { field, errors, fieldApi } = useFormField<FIELD_NAME, PartialForm, CourierFilterError>(
    name,
    options as UseFormFieldOptions<PartialForm, CourierFilterError>,
  )

  const { visible, value: fieldValue } = field
  const { change, validate } = fieldApi

  const { open: dialogOpen, onOpen: onOpenDialog, onClose: onCloseDialog } = useDialogState()

  const territories = useTerritories(
    fieldValue,
    fieldApi.change,
    territoriesConfig,
    territoryNames,
    onCloseDialog,
    rootRef,
  )

  const onChangeAndValidate = useCallback(
    (value: uui.domain.client.rm.CourierFilter) => {
      change(value)
      validate()
    },
    [change, validate],
  )

  const hasNoTerritories = Object.keys(fieldValue).length === 0

  // message will be set only when the value has no entry
  const invalidMessage = errors[0]?.message ?? ''

  if (!visible) return null

  return (
    <Stack spacing={2} ref={rootRef} data-testid="courier-filter">
      <Header onOpenDialog={onOpenDialog} disabled={!territories.canAddNewTerritory} />
      <EmptyTerritories onAddTerritory={onOpenDialog} visible={hasNoTerritories} />
      <TerritoriesList
        error={errors[0]}
        fieldValue={fieldValue}
        apiChange={onChangeAndValidate}
        territoryNames={territoryNames}
        territoriesConfig={territoriesConfig}
      />
      <Box hidden={!invalidMessage} paddingBottom={2}>
        <FormHelperText error>{invalidMessage}</FormHelperText>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={onCloseDialog}
        data-testid="dialog"
        transitionDuration={transitionDuration}
      >
        <AddTerritory
          onClose={onCloseDialog}
          options={territories.options}
          onAddTerritory={territories.onAddTerritory}
        />
      </Dialog>
    </Stack>
  )
}
