import styled from 'styled-components'

const ItemRendererRowSeparator = styled.div`
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 0;
  height: 1px;
  background: ${p => p.theme.colors.$silver};
`

ItemRendererRowSeparator.displayName = 'ItemRendererRowSeparator'
export default ItemRendererRowSeparator
