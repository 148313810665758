import type { Patch } from 'immer'

export function computeChangesSummary(patches: Patch[]) {
  const summary: uui.domain.ui.forms.VehicleFormChangesSummary = {}

  for (const patch of patches) {
    const root = patch.path[0] ?? ''
    switch (root) {
      case 'vehicle':
        summary.rmVehicle = true
        break

      case 'driverBehaviors':
        summary.driverBehaviors = true
        break

      case 'speedMonitor':
        summary.speedMonitor = true
        break

      case 'gpsTags':
        summary.tags = true
        break

      case 'deviceConfiguration':
        summary.deviceConfiguration = true
        break

      case 'idleStartThreshold':
        summary.idleStart = true
        break

      case 'device':
        summary.device = true
        break
    }
  }
  return summary
}
