import { styled } from '@mui/material'

export const RadioSection = styled('div', { name: 'RadioSection' })(({ theme }) => ({
  marginBottom: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  justifyContent: 'space-between',
  flexDirection: 'column',
  display: 'flex',
  fontSize: 15,
  minWidth: 286,
}))
