import type { FormErrors, FormFields } from '../../../formFields'
import { useSelector } from 'react-redux'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { useDisableSubmit } from '@/components/formUi/hooks/useDisableSubmit'
import { selectTerritoryNotificationBehavior } from '@/features/domain/territory'
import { useIsForeignUser } from '../../../hooks/useIsForeignUser'

export function useSaveButtonDisabled() {
  const { disableSubmit, submitting } = useDisableSubmit<FormFields, FormErrors>()
  const { field: statusField } = useFormField<'status', FormFields, FormErrors>('status')
  const isForeignUser = useIsForeignUser()

  const advancedManagementDisabled =
    useSelector(selectTerritoryNotificationBehavior) !== 'EMAIL_AND_PHONE'

  return (
    disableSubmit ||
    submitting ||
    ((advancedManagementDisabled || isForeignUser) && statusField.value === 'ADVANCED')
  )
}
