import type { AxiosResponse } from 'axios'

export const httpDefaultResponse = <T = any>(response: AxiosResponse<T>) => {
  const { status, data } = response

  if (status !== 200) {
    return Promise.reject(response)
  }

  return data
}
