import { FlexBox, WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useTexts } from './useTexts'

export function FailedToLoadNotifications() {
  const texts = useTexts()

  return (
    <FlexBox column vAlignContent="top" hAlignContent="center" padding="34px 32px">
      <WarningCard
        preset="alert"
        title={texts.failureTitle}
        Icon={<WarningTriangle />}
        description={texts.failureDescription}
      />
    </FlexBox>
  )
}
