import type { BulkFieldValue } from '../../../types'
import type { Priority } from '../../../formFields'

export function computePriority(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
): BulkFieldValue<Priority> {
  const priority = orders[0].order.priority
  const exact = orders.every(o => o.order.priority === priority)

  if (exact) {
    return {
      status: 'exact',
      value: `${priority}`,
    }
  }

  return {
    status: 'mixed',
  }
}
