import styled from 'styled-components'

type Props = {
  active: boolean
}

export const TabOuter = styled.span<Props>`
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  height: 100%;
  background: ${p => (p.active ? p.theme.colors.$pureWhite : p.theme.colors.$silver)};
  border-radius: 3px;
  align-items: center;
  justify-content: center;
`

TabOuter.displayName = 'TabOuter'
