import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    fieldDisabled: translate({ id: 'order.form.bulk.loads.fieldDisabled.tooltip' }),
    addLoadTooltip: (disableReason: 'noLoads' | 'limitReached' | undefined, maxLoads: number) => {
      switch (disableReason) {
        case 'noLoads':
          return translate({ id: 'order.form.bulk.loads.addLoadsTooltip.noLoads' })
        case 'limitReached':
          return translate({
            id: 'order.form.bulk.loads.addLoad.disabled.tooltip',
            values: { maxLoads },
          })
        default:
          return translate({ id: 'order.form.bulk.loads.addLoadsTooltip' })
      }
    },
  }))

  return api
}
