import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { setBreadcrumbFeatureMetadata } from '../../breadcrumbFeatureMetadata'
import { getBreadcrumbStopsFeatureStyle } from './getBreadcrumbStopsFeatureStyle'

export function updateBreadcrumbStopsFeature(
  mapStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
  marker: uui.domain.ui.map.markers.Breadcrumb,
  feature: Feature<Geometry>,
  stop: uui.domain.ui.map.markers.BreadcrumbPoint,
  selected: boolean,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
): Feature<Geometry> {
  // ------------------------
  // Feature Metadata
  // ------------------------

  const mode: uui.domain.ui.map.markers.MapStyles['breadcrumb']['mode'] =
    marker.mode ?? mapStyles.mode

  setBreadcrumbFeatureMetadata(feature, 'mode', mode)
  setBreadcrumbFeatureMetadata(feature, 'type', 'breadcrumbStops')

  // ------------------------
  // Feature Style
  // ------------------------

  const style = getBreadcrumbStopsFeatureStyle(stop, mode, selected, breadcrumbTimeRange)
  feature.setStyle(style)

  return feature
}
