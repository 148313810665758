import styled from 'styled-components'

type Props = {
  autoWidth?: boolean
}

export const DropdownMenuList = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: ${p => (p.autoWidth ? 'auto' : '214px')};
  outline: 0;
`
DropdownMenuList.displayName = 'DropdownMenuList'
