import { createSelector } from '@reduxjs/toolkit'
import { selectUserProfile, selectUserConfiguration } from '@/features/domain/user'
import { selectTerritory } from '@/features/domain/territory'

export const selectData = createSelector(
  selectTerritory,
  selectUserProfile,
  selectUserConfiguration,
  (territory, profile, userConfig) => {
    let email = 'unknown'
    if (userConfig.userType === 'gpsOnly') {
      email = profile.gpsUser?.email ?? 'unknown'
    } else {
      email = profile.user.email
    }

    return {
      territoryName: territory.name,
      email,
    }
  },
)
