import { PureComponent } from 'react'

import { SecondaryColumnHeader, ViewManager } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'

import SimulationAvatar from '../../components/SimulationAvatar'
import DetailRow from '../../components/DetailRow'

import ActionsManager, { ViewTypes as ActionsManagerTypes } from './components/ActionsManager'
import ViewDetails from './ViewDetails'
import { Props } from './typings'

export class SingleView extends PureComponent<Props> {
  private handleAction = () => {
    const { resetSelection } = this.props
    resetSelection()
  }

  render() {
    const { simulation } = this.props

    if (!simulation) {
      return null
    }

    const { name, label, vehiclesCount, ordersCount } = simulation

    return (
      <FormColumn width={484} testid="form__column-secondary">
        <SecondaryColumnHeader
          title={name}
          description={''}
          action={this.handleAction}
          avatar={<SimulationAvatar simulationsCount={1} />}
          customDescription={
            <DetailRow vehiclesCount={vehiclesCount} ordersCount={ordersCount} selected={false} />
          }
        />

        <ViewManager<ActionsManagerTypes>
          view={ActionsManagerTypes.default}
          render={(view, changeView) => (
            <ActionsManager {...this.props} view={view} changeView={changeView} />
          )}
        />

        <ViewDetails name={name} label={label} />
      </FormColumn>
    )
  }
}
