import { type SchedulerEventModel } from '@bryntum/schedulerpro'
import { useMemo } from 'react'
import { differenceInSeconds } from 'date-fns/esm'

export function useTooltipData(eventRecord: SchedulerEventModel) {
  return useMemo(() => {
    const startDate = eventRecord.startDate as Date
    const endDate = eventRecord.endDate as Date

    const durationSeconds = differenceInSeconds(endDate as Date, startDate as Date)

    return {
      end: endDate,
      type: 'break',
      start: startDate,
      durationSeconds,
    }
  }, [eventRecord])
}
