import { useState } from 'react'

import { useIntl } from '@/intl'
import { RejectError } from '@/features/actions/login'

export function useActionsTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    forgotPasswordSuccess: translate({ id: 'login-next.forgotPassword.submit.success' }),
    forgotPasswordError: translate({ id: 'login-next.forgotPassword.submit.error' }),

    loginError: (error: RejectError | undefined): string => {
      // hides all the error type behind one single error message

      switch (error?.type) {
        case 'LOGIN_ERROR_ACCOUNT_INACTIVE':
          return translate({ id: 'login-next.login.submit.errors.account.inactive' })

        case 'LOGIN_ERROR_ACCOUNT_EXPIRED':
          return translate({ id: 'login-next.login.submit.errors.account.expired' })

        case 'LOGIN_ERROR_ACCOUNT_SUSPENDED':
          return translate({ id: 'login-next.login.submit.errors.account.suspended' })

        case 'LOGIN_ERROR_USER_DISABLED':
          return translate({ id: 'login-next.login.submit.errors.user.disabled' })

        case 'LOGIN_ERROR_NO_USER':
        case 'invalid-login':
          return translate({ id: 'login-next.login.submit.errors.invalid' })

        case 'too-many-sessions':
          return translate({ id: 'login-next.login.submit.errors.tooManySessions' })

        case 'LOGIN_ERROR_USER_FILTERED_GUEST_NO_COMPANIES':
          return translate({ id: 'login-next.login.submit.errors.account.noCompany' })

        case 'LOGIN_ERROR_USER_NO_TERRITORIES':
          return translate({ id: 'login-next.login.submit.errors.account.noTerritory' })

        case 'proxy-error':
        case 'unknown':
        default:
          return translate({ id: 'login-next.login.submit.errors.failed' })
      }
    },
  }))

  return api
}
