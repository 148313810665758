import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    unlimited: translate({ id: 'scheduler.popOver.approvedMileage.unlimited' }),
    title: (metric: boolean) =>
      translate({
        id: metric
          ? 'scheduler.popOver.approvedMileage.title.km'
          : 'scheduler.popOver.approvedMileage.title.mi',
      }),
    approvedMileage: (value: string, total: string, saturation: string, metric: boolean) =>
      translate({
        id: metric
          ? 'scheduler.popOver.approvedMileage.km'
          : 'scheduler.popOver.approvedMileage.mi',
        values: { value, total, saturation },
      }),
  }))

  return api
}
