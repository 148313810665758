import { Component, MouseEvent } from 'react'

import { clsx } from '@/utils'
import { Left, Right } from '@/icons'

export type Directions = 'left' | 'right'

export interface Props {
  direction: Directions
  callback: () => void
  disabled?: boolean
  invisible?: boolean
  className?: string
  title?: string
}

export default class Arrow extends Component<Props> {
  handleOnClick = (_: MouseEvent<HTMLElement>): void => {
    const { callback } = this.props
    callback()
  }

  render() {
    const {
      className = '',
      direction = 'left',
      disabled = false,
      invisible = false,
      title = '',
    } = this.props

    const iconPreset = direction
    const rootClassName = clsx({
      [className]: true,
      'o-arrow': true,
      'is-disabled': disabled,
      'is-invisible': invisible,
      [`move-${iconPreset}`]: true,
    })

    return (
      <button
        className={rootClassName}
        disabled={disabled}
        onClick={this.handleOnClick}
        title={title}
      >
        <span className="o-arrow__icon">{iconPreset === 'left' ? <Left /> : <Right />}</span>
      </button>
    )
  }
}
