import { Tooltip } from '@/components/primitives/Tooltip'

import { Counter } from './components/Counter'
import { useTexts } from './useTexts'

interface Props {
  allSelected: boolean
  routesCounter: number
  toggleSelectAll: () => void
}

export function RoutesCounter(props: Props) {
  const texts = useTexts()
  const { allSelected, routesCounter, toggleSelectAll } = props

  const color = allSelected ? 'secondary' : undefined
  const title = allSelected ? texts.unselectAll : texts.selectAll

  const testid = 'scheduler-routes-counter'

  return (
    <Tooltip title={title} placement="bottom">
      <Counter
        onClick={toggleSelectAll}
        label={routesCounter}
        color={color}
        size="small"
        data-testid={testid}
        data-trackid={testid}
      />
    </Tooltip>
  )
}
