import { LocationPinTester } from './components/LocationPinTester'
import { SearchOnMapTester } from './components/SearchOnMapTester'
import { MapStats } from './components/MapStats'

export function MapTesters() {
  return (
    <>
      <h1>Map</h1>

      <hr />

      <MapStats />

      <hr />

      <h2>LocationPinTester</h2>
      <LocationPinTester />

      <h2>SearchOnMapTester</h2>
      <SearchOnMapTester />
    </>
  )
}
