import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { updateCompany } from '@/features/domain/company'

import { useTexts } from '../../../hooks/useTexts'

export function useEnableCompany(company: uui.domain.client.rm.Company) {
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const texts = useTexts()

  return useCallback(async () => {
    const result = await dispatch(updateCompany({ ...company, enabled: true }))

    if (updateCompany.rejected.match(result)) {
      throw new Error(result.payload?.message ?? 'Internal error')
    }

    toast.success(texts.toast.enableCompanySuccess)
  }, [toast, dispatch, texts, company])
}
