import type { ReactNode } from 'react'

import { Divider, styled } from '@mui/material'

import { theme } from '@/styles'

import { VerticalLayout } from './VerticalLayout'

type Props = {
  width: number
  children?: ReactNode
  testid?: string
}

export function FormColumn(props: Props) {
  return (
    <VerticalLayout width={props.width} alignItems="center" data-testid={props.testid}>
      {props.children}
      <Separator />
    </VerticalLayout>
  )
}

const Separator = styled(Divider, { name: 'Separator' })({
  position: 'absolute',
  top: 0,
  left: '100%',
  width: '1px',
  height: '100%',
  backgroundColor: theme.colors.$silver,
  zIndex: 4,
})
