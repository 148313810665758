import type { GridValueGetterParams } from '@mui/x-data-grid-pro'

export function getExecutedLoad(params: GridValueGetterParams<uui.domain.client.rm.FormattedData>) {
  const executedLoads = params.row.executedLoads
  const executedLoadName = params.field.replace('formattedData.executedLoads.', '')

  return typeof executedLoads[executedLoadName] === 'number'
    ? executedLoads[executedLoadName] / 100
    : '-'
}
