import { Icon, Style } from 'ol/style'
import spriteSheetUrl_min_1_9 from '@/resources/map-markers/orderLabels_min_1_9.png'
import spriteSheetUrl_min_10_99 from '@/resources/map-markers/orderLabels_min_10_99.png'
import spriteSheetUrl_min_100_249 from '@/resources/map-markers/orderLabels_min_100_249.png'
import spriteSheetUrl_min_250_399 from '@/resources/map-markers/orderLabels_min_250_399.png'
import spriteSheetUrl_min_400_549 from '@/resources/map-markers/orderLabels_min_400_549.png'
import spriteSheetUrl_min_550_699 from '@/resources/map-markers/orderLabels_min_550_699.png'
import spriteSheetUrl_min_700_849 from '@/resources/map-markers/orderLabels_min_700_849.png'
import spriteSheetUrl_min_850_999 from '@/resources/map-markers/orderLabels_min_850_999.png'

const orderLabelStylesCache: Map<string, Style> = new Map()

function computeSpriteSheetUrl(sprite) {
  switch (sprite) {
    case 'min_1_9':
      return spriteSheetUrl_min_1_9
    case 'min_10_99':
      return spriteSheetUrl_min_10_99
    case 'min_100_249':
      return spriteSheetUrl_min_100_249
    case 'min_250_399':
      return spriteSheetUrl_min_250_399
    case 'min_400_549':
      return spriteSheetUrl_min_400_549
    case 'min_550_699':
      return spriteSheetUrl_min_550_699
    case 'min_700_849':
      return spriteSheetUrl_min_700_849
    case 'min_850_999':
      return spriteSheetUrl_min_850_999
    default:
      throw new Error(`Unknown sprite: ${sprite}`)
  }
}

export function getSecondaryLabelStyle(
  secondaryGridPlacement: uui.domain.ui.map.markers.MultiSpriteGridPlacement,
  mode: uui.domain.ui.map.markers.MapStyles['order']['mode'],
  color: 'white' | 'black',
  selected: boolean,
) {
  const [x, y, cellSize, sprite] = secondaryGridPlacement
  const cacheId = `${x}_${y}_${sprite}_${selected ? 'selected' : 'not-selected'}_${color}`

  if (orderLabelStylesCache.has(cacheId)) {
    return orderLabelStylesCache.get(cacheId)
  }

  const spriteSheetUrl = computeSpriteSheetUrl(sprite)

  const style = new Style({
    zIndex: selected ? 2 : mode === 'maximized' ? 1 : undefined,
    image: new Icon({
      src: spriteSheetUrl,
      color: color === 'black' ? 'rgba(0, 0, 0, 255)' : undefined,
      crossOrigin: 'anonymous',

      scale: 0.5,
      opacity: 1.0,
      rotation: 0.0,
      rotateWithView: true,

      size: [cellSize, cellSize],
      offset: [x, y],

      anchor: [0.5, 0.5],
      displacement: [0, 5.5],

      anchorOrigin: 'top-left',
      anchorYUnits: 'fraction',
      anchorXUnits: 'fraction',
    }),
  })

  orderLabelStylesCache.set(cacheId, style)

  return style
}
