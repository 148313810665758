import { format } from 'date-fns'

export function getDateFormats() {
  const year = new Date().getFullYear()
  const exampleDate = new Date(year, 11, 31)

  return {
    MDY: format(exampleDate, 'MM/dd/yyyy'),
    DMY: format(exampleDate, 'dd/MM/yyyy'),
    YMD: format(exampleDate, 'yyyy/MM/dd'),
    MMMDY: format(exampleDate, 'MMM dd yyyy'),
    DMMMY: format(exampleDate, `dd MMM yyyy`),
  }
}

export const timeFormats = {
  'hh:mm aa': '2:50 PM',
  'HH:mm': '14:50',
}

export const distanceFormats = {
  IMPERIAL: 'Miles',
  METRIC: 'Km',
}
