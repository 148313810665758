import { Entry } from '../Entry'
import { JiraLinks } from './JiraLinks'

const ids = [10365, 10289, 10280, 10548, 10513, 10534, 10516]

export function Entry20210401() {
  return (
    <Entry title="04/01/2021 - v3.0.0-beta-1 fixes">
      Full list of Jira issues fixed:
      <br />
      <br />
      <JiraLinks ids={ids} />
      <br />
      <br />
    </Entry>
  )
}
