import { useSelector } from 'react-redux'

import { NumericRangeField } from '@/forms-legacy'
import { selectUserConfiguration } from '@/features/domain/user'

import { useTexts } from './useTexts'
import { renderText } from './renderText'

const numericStepWrappedInputProps = {
  step: 5,
}

interface Props {
  defaultValue?: number
}

export function MaxSpeedLimit(props: Props) {
  const { defaultValue } = props

  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const metric = userConfig.distanceFormat === 'METRIC'

  return (
    <NumericRangeField
      name="device.maxSpeedLimit.threshold"
      testid="device.maxSpeedLimit"
      label={texts.label}
      stepLabel={texts.unitOfMeasure(texts.stepLabel, metric)}
      disableValue={-1}
      defaultValue={defaultValue}
      wrappedInputProps={numericStepWrappedInputProps}
      renderText={renderText}
    />
  )
}
