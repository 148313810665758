import { StyledComponent } from '@emotion/styled'
import { styled, InputLabel, type InputLabelProps } from '@mui/material'

/**
 * ATTENTION: Until form-ui will fully support MUI theming this allow
 * us to change form fields without affecting all usages of MUI TextField
 */
export const FormUIInputLabel: StyledComponent<InputLabelProps> = styled(InputLabel, {
  name: 'FormUIInputLabel',
})({
  // The margin top has been added to prevent the label to going outside of the component area
  top: 5,
})
