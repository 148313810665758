import { InspectPinMode, Map as MainMap, MapLayer } from '@/map'
import { SearchOnMap } from '@/components/SearchOnMap'
import { useGetCurrentLocation } from './hooks/useGetCurrentLocation'

export function Map() {
  const center = useGetCurrentLocation()

  return (
    <MainMap selectionContext="main" mapId="ww-map" center={center}>
      <InspectPinMode mode="normal" />

      <MapLayer uid="order" interactive visible />
      <MapLayer uid="region" visible />
      <MapLayer uid="roadSegment" visible />
      <MapLayer uid="geofence" visible={false} />
      <MapLayer uid="place" visible interactive />
      <MapLayer uid="device" visible interactive />
      <MapLayer uid="vehicle" visible interactive />

      <SearchOnMap />
    </MainMap>
  )
}
