import { renderToStaticMarkup } from 'react-dom/server'
import { ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { intl } from '@/intl'

import { VehicleCell, VehicleTooltip } from '../../../components/cells/VehicleCell'
import { vehicleSorter } from '../sorters/vehicleSorter'

export function createVehicleColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    text: intl.translate({ id: 'rm.scheduler.column.name' }),
    type: 'template',
    field: 'vehicleName',
    width,
    editor: false,
    region: 'cols',
    hidden,
    sortable: vehicleSorter,

    template: ({ record, field, value }) =>
      renderToStaticMarkup(
        <VehicleCell record={record as ResourceModel} field={field} value={value} />,
      ),

    resizable: true,
    groupable: false,

    tooltipRenderer: ({ record }) => {
      const hasViolations = record.getData(
        'hasViolations',
      ) as uui.domain.client.rm.SchedulerResource['hasViolations']

      const violations = record.getData('violations') as uui.domain.rm.RouteViolations

      if (!hasViolations || (violations.vehicle?.length ?? 0) === 0) return ''

      return renderToStaticMarkup(<VehicleTooltip record={record as ResourceModel} />)
    },
  }
}
