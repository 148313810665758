import styled from 'styled-components'

import { getColorWithAlpha } from '@/local/components'

interface Props {
  padY: number
}

export const OptionsContainer = styled.div<Props>`
  position: relative;
  z-index: 20;
  width: auto;
  padding: ${p => p.padY || 0}px 6px;
  background: ${p => p.theme.colors.$pureWhite};
  box-shadow: 0 1px 3px 0 ${getColorWithAlpha('$pureBlack', 0.25)};
  border-radius: 6px;
  overflow: hidden;
`

OptionsContainer.displayName = 'OptionsContainer'
