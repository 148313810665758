import styled from 'styled-components'

export const TimeWindowControlRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 10px;

  > * {
    flex: 0 0 auto;
  }

  > * + * {
    margin-left: 4px;
    margin-top: 0;
    margin-bottom: 0;
  }
`

TimeWindowControlRow.displayName = 'TimeWindowControlRow'
