import { useCallback } from 'react'

import { resetEditingState, useCrudSelection } from '@/atoms'
import { useAppDispatch } from '@/store'
import { addTrafficProfile } from '@/features/domain/traffic'
import { composeRecurrenceId } from '@/server-data'

export function useActions() {
  const dispatch = useAppDispatch()
  const [, setTrafficProfileSelection] = useCrudSelection('trafficProfile')

  const onCreateRecurrence = useCallback(
    async (recurrence: uui.domain.rm.Recurrence) => {
      const thunkResult = await dispatch(addTrafficProfile(recurrence))
      if (!addTrafficProfile.fulfilled.match(thunkResult)) {
        throw new Error(thunkResult.payload?.message ?? 'Internal error')
      }

      resetEditingState()
      setTrafficProfileSelection([thunkResult.payload])
    },
    [dispatch, setTrafficProfileSelection],
  )

  const onOpenExistingRecurrence = useCallback(
    (recurrence: uui.domain.rm.Recurrence) => {
      const recurrenceId = composeRecurrenceId(recurrence.type, recurrence.value)

      resetEditingState()
      setTrafficProfileSelection([recurrenceId])
    },
    [setTrafficProfileSelection],
  )

  return {
    onCancel: resetEditingState,
    onCreateRecurrence,
    onOpenExistingRecurrence,
  }
}
