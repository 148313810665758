import { useMemo } from 'react'

export function useVehiclesType(unifiedVehicles: uui.domain.client.UnifiedVehicle[]) {
  return useMemo(() => {
    const gpsOnly = unifiedVehicles.every(uv => uv.isPhysicalDevice && !uv.hasRoutingLicense)
    if (gpsOnly) {
      return 'gpsOnly'
    }

    const routingOnly = unifiedVehicles.every(uv => !uv.isPhysicalDevice && uv.hasRoutingLicense)
    if (routingOnly) {
      return 'routingOnly'
    }

    return 'mixed'
  }, [unifiedVehicles])
}
