import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function OrdersVisible(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 18 20">
        <path d="M0 3c0-1.65686 1.34314-3 3-3h10c1.6569 0 3 1.34314 3 3v10c0 1.6569-1.3431 3-3 3H9.15466L8 18l-1.15466-2H3c-1.65686 0-3-1.3431-3-3V3Zm4.36 7.559V11h3.708v-.639H6.403c-.15 0-.309.006-.477.018-.168.012-.33.024-.486.036.456-.474.858-.912 1.206-1.314.354-.408.63-.795.828-1.161.204-.372.306-.738.306-1.098 0-.51-.153-.918-.459-1.224-.306-.312-.726-.468-1.26-.468-.372 0-.696.081-.972.243-.27.156-.525.366-.765.63l.423.423c.168-.192.354-.354.558-.486.204-.138.426-.207.666-.207.366 0 .639.105.819.315.18.21.27.48.27.81 0 .306-.105.636-.315.99-.21.348-.516.741-.918 1.179-.402.432-.891.936-1.467 1.512Zm5.9316-1.494c-.114.57-.189 1.215-.225 1.935h.765c.024-.558.066-1.068.126-1.53.06-.462.15-.894.27-1.296.12-.408.288-.81.504-1.206.216-.402.495-.819.837-1.251v-.459H8.86963v.639h2.86197c-.378.522-.684 1.038-.918 1.548-.228.504-.402 1.044-.522 1.62Z" />
      </svg>
    </IconContainer>
  )
}
