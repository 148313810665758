import type { CrudSelection } from './typings'

import { proxy } from 'valtio'
import { devtools } from 'valtio/utils'
import { getDevPreference } from '@/dev'

export const createDefaultCrudSelection = (): CrudSelection => ({
  depots: [],
  devices: [],
  driverAssignments: [],
  drivers: [],
  unifiedVehicles: [],
  regions: [],
  trafficArea: [],
  trafficProfile: [],
  routingSegments: [],
  simulations: [],
  users: [],
  alerts: [],
  geofences: [],
  places: [],
  deviceEvents: [],
  reports: [],
})

export const crudAtom = proxy<{ selection: CrudSelection }>({
  selection: createDefaultCrudSelection(),
})

if (getDevPreference('crudSelectionDevTools') === 'enable') {
  devtools(crudAtom, 'crudSelectionAtom')
}
