import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    toast: {
      success: translate({ id: 'editTrafficProfileModal.toast.success' }),
    },
    header: {
      title: (recurrence: string) =>
        translate({ id: 'editTrafficProfileModal.header.title', values: { recurrence } }),
    },
    footer: {
      update: translate({ id: 'editTrafficProfileModal.footer.update' }),
      cancel: translate({ id: 'editTrafficProfileModal.footer.cancel' }),
    },
    form: {
      trafficDescription: translate({ id: 'editTrafficProfileModal.form.trafficDescription' }),
      drivingTime: translate({ id: 'editTrafficProfileModal.form.drivingTime' }),
    },
  }))

  return api
}
