import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    sortAscending: translate({
      id: 'traffic.trafficProfile.list.sorter.sortBy.direction.ascending',
    }),
    sortDescending: translate({
      id: 'traffic.trafficProfile.list.sorter.sortBy.direction.descending',
    }),
  }))

  return api
}
