import type { Integration } from '@sentry/types'
import type { Environment } from './config/getSentryEnvironment'
import type {
  AddEntryInfo,
  JournalOptions,
  CreateJournalTargetApi,
  CreateJournalTargetCreator,
  PartialJournalTargetOptions,
} from '@/journal'

import * as Sentry from '@sentry/browser'

import { createJournalTarget } from '@/journal'

import { getSentryEnvironment } from './config/getSentryEnvironment'
import { getEnvVariables } from './config/getEnvVariables'
import { getSentryConfig } from './config/getSentryConfig'
import { createSentryException } from './utils/createSentryException'
import { createSentryBreadcrumb } from './utils/createSentryBreadcrumb'

type SamplingRange = 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9 | 1.0

type SentryOptions = {
  maxBreadcrumbs?: number
  sampleRate?: SamplingRange
  autoSessionTracking?: boolean
  tracesSampleRate?: SamplingRange
  environment?: Environment
}

type SentryTargetOptions = PartialJournalTargetOptions & SentryOptions

type SentryTargetApi = {
  _?: never
}

const defaultOptions: SentryTargetOptions = {
  disabled: false,
}

const createSentryTargetApi: CreateJournalTargetApi<SentryTargetOptions, SentryTargetApi> = (
  options: SentryTargetOptions,
  _journalOptions: JournalOptions<SentryTargetApi>,
) => {
  const envVars = getEnvVariables()
  // const debug = process.env.NODE_ENV === 'development'
  const disableSentry = !!envVars.DISABLE_SENTRY
  const uiThread = typeof document !== 'undefined'
  const environment = options.environment ?? getSentryEnvironment(globalThis.location.href)

  // FIXME: Restore Error Tracking only in production environments once Sentry has been fine tuned
  // if (disableSentry || process.env.NODE_ENV !== 'production') {
  if (disableSentry) {
    console.group()
    console.log(
      `%c ${uiThread ? 'UI' : 'Data'} Thread`,
      'background: red; color: white; font-weight: bold; display: block;',
    )
    console.log(
      '%c Error Tracking Disabled',
      'background: red; color: white; font-weight: bold; display: block;',
    )
    console.groupEnd()

    return Promise.resolve({
      addEntry: () => Promise.resolve(),
      getApi: () => ({}),
    })
  } else {
    console.group()
    console.log(
      `%c ${uiThread ? 'UI' : 'Data'} Thread `,
      'background: blue; color: white; font-weight: bold; display: block;',
    )
    console.log(
      '%c Error Tracking Enabled ',
      'background: blue; color: white; font-weight: bold; display: block;',
    )
    console.log(
      `%c Sentry Environment: ${environment} `,
      'background: blue; color: white; font-weight: bold; display: block;',
    )
    console.groupEnd()
  }

  const { dsn, sampleRate, tracesSampleRate, maxBreadcrumbs } = getSentryConfig(environment)

  const sentryIntegrations: Integration[] = [Sentry.breadcrumbsIntegration({ console: false })]

  if (uiThread) {
    // Add browser Tracing only for the UI thread
    sentryIntegrations.push(Sentry.browserTracingIntegration())
    sentryIntegrations.push(Sentry.replayIntegration())
  }

  // ,see: https://docs.sentry.io/platforms/javascript/configuration/options/
  Sentry.init({
    dsn,
    integrations: sentryIntegrations,

    release: `route-manager@${envVars.appVersion}`,

    // debug,
    environment,
    sampleRate,
    maxBreadcrumbs,
    autoSessionTracking: options.autoSessionTracking ?? true,

    tracesSampleRate,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // see: https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    // allowUrls: [],

    initialScope: {
      level: 'error',
    },

    // see:https://gist.github.com/impressiver/5092952
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver loop limit exceeded',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  })

  return new Promise(async resolve => {
    resolve({
      addEntry: async (entryInfo: AddEntryInfo) => {
        const { entry } = entryInfo

        // const { level: logLevel } = journalOptions
        // const canPrint =
        //   logLevel === 'verbose' || (logLevel === 'normal' && entry.severity !== 'log')
        // if (!canPrint) return

        const journalContext = getJournalContext(entry.tags)

        switch (entry.severity) {
          case 'error':
            Sentry.addBreadcrumb(createSentryBreadcrumb(entryInfo, 'error', journalContext))

            Sentry.captureException(createSentryException(entryInfo), {
              tags: { journalContext },
            })
            break

          case 'warn':
            Sentry.addBreadcrumb(createSentryBreadcrumb(entryInfo, 'warning', journalContext))
            break

          case 'log':
            Sentry.addBreadcrumb(createSentryBreadcrumb(entryInfo, 'log', journalContext))
            break
        }
      },

      getApi: () => {
        return {}
      },
    })
  })
}

export const createSentryTarget = (
  targetName: string = 'sentry',
): CreateJournalTargetCreator<SentryTargetOptions, SentryTargetApi> =>
  createJournalTarget<SentryTargetOptions, SentryTargetApi>(
    targetName,
    createSentryTargetApi,
    defaultOptions,
  )

export function setErrorTrackingStage(stage: string) {
  Sentry.setTag('stage', stage)
}
export function setErrorTrackingServerUrl(url: string) {
  Sentry.setTag('server_url', url)
}

export function setErrorTrackingUser(profile: uui.domain.client.UserProfile) {
  const {
    user,
    gpsUser,
    account: {
      id: accountId,
      licensingLimits,
      details: { companyName, country, timeZoneCode },
      credentials: { username },
    },
  } = profile

  Sentry.setUser({
    email: profile.user.email,
    username: gpsUser ? gpsUser.loginName : username,
  })

  Sentry.setContext('visitor', {
    username,

    // from RM account
    rmUsername: username,

    // from RM user profile
    name: user.name,
    email: user.email,
    surname: user.surname,
    rmUserRole: user.type,
    currency: user.settings.currency,
    uiLanguage: user.settings.language,
    timeFormat: user.settings.timeFormat,
    dateFormat: user.settings.dateFormat,
    distanceFormat: user.settings.distanceFormat,

    // from GPS user profile
    gpsId: gpsUser ? gpsUser.userId : undefined,
    gpsLogin: gpsUser ? gpsUser.loginName : undefined,
    gpsTimezone: gpsUser ? gpsUser.timezone : undefined,
    gpsRole: gpsUser ? gpsUser.role : undefined,
  })

  Sentry.setContext('account', {
    id: accountId,

    rmCountry: country,
    rmCompany: companyName,
    rmTimezone: timeZoneCode,
    rmPrivileges: licensingLimits.privileges,

    rmSuspended: licensingLimits.suspended,
    rmExpiryDate: licensingLimits.expiryDate,
  })

  Sentry.setTag('page_locale', user.settings.language)
}

export function clearErrorTrackingUser() {
  Sentry.getCurrentScope().setUser(null)
  Sentry.setContext('account', null)
  Sentry.setContext('visitor', null)
  Sentry.setContext('territory', null)

  Sentry.setTag('user_type', null)
  Sentry.setTag('page_locale', null)
}

export function setErrorTrackingTerritory(domain: uui.domain.DomainState) {
  const territory = domain.publicData.territory
  const userType = domain.publicData.userConfiguration.userType

  Sentry.setTag('user_type', userType)

  Sentry.setContext('territory', {
    rmHorizonOptDays: territory.licensingLimits.horizonOpt,
    rmOrderRadius: territory.licensingLimits.orderRadiusMt,
    rmOperationOrders: territory.licensingLimits.ordersOps,
    rmSimulationOrders: territory.licensingLimits.ordersSim,
    rmHorizonViewDays: territory.licensingLimits.horizonView,
    rmVehicleRadius: territory.licensingLimits.vehicleRadiusMt,
    rmTrackedVehicles: territory.licensingLimits.trackedVehicles,
    rmMaxStatusReasonsCount: territory.licensingLimits.maxStatusReasonsCount,
  })
}

export type JournalContext =
  | 'ui'
  | 'user'
  | 'store'
  | 'atoms'
  | 'domain'
  | 'remote'
  | 'external'
  | 'worker'
  | 'main'

const journalContexts = [
  'ui',
  'user',
  'store',
  'atoms',
  'domain',
  'remote',
  'external',
  'worker',
  'main',
]

function getJournalContext(tags: string[]): JournalContext | undefined {
  for (const ctx of journalContexts) {
    if (tags.includes(ctx)) {
      return ctx as JournalContext
    }
  }
}
