import type { PartialStoreState } from '../typings'
import { createSelector } from '@reduxjs/toolkit'
import { naturalSort } from '@/server-data'

export const selectVehicles = (state: PartialStoreState) => state.domain.publicData.domain.vehicles
export const selectVehiclesByVehicleId = (state: PartialStoreState) =>
  state.domain.publicData.domain.vehiclesByVehicleId
export const selectVehiclesByDeviceId = (state: PartialStoreState) =>
  state.domain.publicData.domain.vehiclesByDeviceId

export const selectRmVehicles = createSelector(selectVehicles, unifiedVehicles => {
  return Object.values(unifiedVehicles).reduce<Record<string, uui.domain.client.rm.Vehicle>>(
    (acc, curr) => {
      if (curr.hasRoutingLicense) {
        acc[curr.vehicleId] = curr.vehicle
      }
      return acc
    },
    {},
  )
})

export const selectLoadsFromVehicles = createSelector(selectVehicles, vehicles => {
  const loadsSet = new Set<string>()

  for (const vehicle of Object.values(vehicles)) {
    if (vehicle.hasRoutingLicense) {
      for (const settings of Object.values(vehicle.vehicle.settings)) {
        for (const load of Object.keys(settings.loadCapacities)) {
          loadsSet.add(load)
        }
      }
    }
  }

  return Array.from(loadsSet)
})

export const selectTagsFromVehicles = createSelector(selectVehicles, vehicles => {
  const tagsSet: Set<string> = new Set()

  for (const vehicle of Object.values(vehicles)) {
    if (vehicle.hasRoutingLicense) {
      for (const settings of Object.values(vehicle.vehicle.settings)) {
        for (const tag of settings.tags) {
          tagsSet.add(tag)
        }
      }
    }
  }

  return Array.from(tagsSet)
})

export const selectSortedRMVehicles = createSelector(selectVehicles, unifiedVehicles => {
  const vehicles: uui.domain.client.rm.Vehicle[] = []

  for (const v of Object.values(unifiedVehicles)) {
    if (v.hasRoutingLicense) {
      vehicles.push(v.vehicle)
    }
  }

  return Object.values(vehicles).sort((a, b) => naturalSort(a.externalId, b.externalId))
})

export const selectAllVehiclesHaveActiveLiveEta = createSelector(
  selectRmVehicles,
  unifiedVehicles => {
    const vehicles = Object.values(unifiedVehicles)
    if (vehicles.length === 0) return false

    return vehicles.every(v => v.edgeOverride === 'GPS')
  },
)
