import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    action: translate({ id: 'setup.driverAssistance.form.alert.action' }),
    off: {
      title: translate({ id: 'setup.driverAssistance.form.alert.off.title' }),
      description: translate({ id: 'setup.driverAssistance.form.alert.off.description' }),
    },
    suggest: {
      title: translate({ id: 'setup.driverAssistance.form.alert.suggest.title' }),
      description: translate({ id: 'setup.driverAssistance.form.alert.suggest.description' }),
    },
    require: {
      title: translate({ id: 'setup.driverAssistance.form.alert.require.title' }),
      description: translate({ id: 'setup.driverAssistance.form.alert.require.description' }),
    },
  }))

  return api
}
