import { useEffect, useRef } from 'react'
import { useListApi } from './useListApi'

/**
 * Scroll to the passed item on mount.
 */
export const useAutoScroll = (category: uui.domain.ui.list.Category, id: string | undefined) => {
  const listApi = useListApi(category)
  const idRef = useRef(id)

  useEffect(() => {
    return () => {
      idRef.current = undefined
    }
  }, [])

  useEffect(() => {
    if (idRef.current === undefined) return

    // LIST_IMPERATIVE_API: flow1, step2
    // The Lists (ex. the OrdersList) want to have the currently selected item shown by the StructuredVirtualList

    // LIST_IMPERATIVE_API: flow2, step1
    // Normal behavior: the List calls `scrollTo` before the StructuredVirtualList renders/subscribes
    // The list (ex. the OrdersList, VehiclesList) calls `scrollTo`

    // scrollTo returns a cleanup callback
    return listApi.scrollTo(idRef.current)
  }, [listApi])
}
