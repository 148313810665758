import { useCallback } from 'react'

import { timeShift } from '@/features/domain/demoTools'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export const useTimeShiftPlan = () => {
  const dispatch = useAppDispatch()
  const toast = useNotification()

  return useCallback(async () => {
    try {
      const result = await dispatch(timeShift())

      if (!timeShift.fulfilled.match(result)) {
        if (result.payload) {
          throw new Error(result.payload.message)
        }
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, toast])
}
