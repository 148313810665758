import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Depot(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M16.2072 4.15225C16.0839 4.06166 15.9161 4.06166 15.7928 4.15225L2.14277 14.1808C2.05301 14.2468 2 14.3515 2 14.4629V27.125C2 27.6082 2.39175 28 2.875 28H10.225C10.7083 28 11.1 27.6082 11.1 27.125V22.402C11.1 19.6959 13.2938 17.502 16 17.502C18.7062 17.502 20.9 19.6959 20.9 22.402V27.125C20.9 27.6082 21.2917 28 21.775 28H29.125C29.6082 28 30 27.6082 30 27.125V14.4629C30 14.3515 29.947 14.2468 29.8572 14.1808L16.2072 4.15225Z" />
      </svg>
    </IconContainer>
  )
}
