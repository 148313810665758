import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Content } from './Content'

interface Props {
  source: uui.domain.server.gps.telematics.Source
}

export function Root(props: Props) {
  const { source } = props

  return (
    <Modal modalId={modalId}>
      <Content source={source} />
    </Modal>
  )
}
