import styled from 'styled-components'

type Props = {
  half?: boolean
  aThird?: boolean
}

export const ReadonlyBlock = styled.div<Props>`
  width: ${p => (p.half ? '44%' : p.aThird ? '28%' : '100%')};
  margin-bottom: 14px;

  > * + * {
    margin-top: 8px;
  }
`

ReadonlyBlock.displayName = 'ReadonlyBlock'
