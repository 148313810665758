import type { SearchableListTypes } from '@/forms-legacy'
import type { DeviceInfoItem } from '../types'

import { useCallback } from 'react'

import { useIntl } from '@/intl'

export function useCreateDevicesRenderText() {
  const { translate } = useIntl()

  return useCallback(
    (
      key: SearchableListTypes.SearchableListText,
      props: SearchableListTypes.PublicProps<DeviceInfoItem>,
      count: number = 0,
    ) => {
      const { values } = props
      const total = values.length ?? 0

      switch (key) {
        case 'filterPlaceholder':
          return translate({
            id: 'gps.alerts.edit.fields.devicesList.filterPlaceholder',
          })

        case 'checkAll':
          return translate({
            id: 'gps.alerts.edit.fields.devicesList.checkAll',
            values: {
              count: total,
            },
          })

        case 'unCheckAll':
          return translate({
            id: 'gps.alerts.edit.fields.devicesList.uncheckAll',
            values: {
              count: total,
            },
          })

        case 'footerDescription':
          return translate({
            id: 'gps.alerts.edit.fields.devicesList.footerDescription',
            values: {
              total,
              checked: count,
            },
          })

        default:
          return key
      }
    },
    [translate],
  )
}
