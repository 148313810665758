import { type ReactNode } from 'react'

interface Props {
  totalItems: number
  shownItems: number

  noEntries: ReactNode
  noResults: ReactNode
  list: ReactNode
}

export function LayoutComposer(props: Props) {
  const { totalItems, shownItems, noEntries, noResults, list } = props

  if (totalItems === 0) {
    return noEntries
  }

  if (shownItems === 0) {
    return noResults
  }

  return list
}
