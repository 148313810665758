import { timeWindowUtils } from '@/utils'

export function formatBreaks(
  breaks: uui.domain.rm.Break[],
  workingDayStartSec: number,
): uui.domain.rm.Break[] {
  return breaks.map(b => {
    const { durationSec } = b

    const timeWindow = {
      startSec: b.startSec,
      endSec: b.endSec,
    } as const

    const { startSec, endSec } = timeWindowUtils.formatTimeWindow(timeWindow, workingDayStartSec)

    return {
      startSec,
      endSec,
      durationSec: Math.round(durationSec / 60),
    }
  })
}
