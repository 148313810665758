import styled from 'styled-components'

export const ReadonlyFieldLabelSeparator = styled.div`
  display: block;
  background: ${p => p.theme.colors.$silver};

  width: 1px;
  height: 20px;
`

ReadonlyFieldLabelSeparator.displayName = 'ReadonlyFieldLabelSeparator'
