import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectTerritories, selectTerritoryId } from '@/features/domain/territory'

export const useAvailableTerritories = () => {
  const territories = useSelector(selectTerritories)
  const currentTerritoryId = useSelector(selectTerritoryId)

  return useMemo(
    () => Object.values(territories).filter(t => t.id !== currentTerritoryId),
    [territories, currentTerritoryId],
  )
}
