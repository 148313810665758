import type { PublicProps as Props } from './typings'
import { PureComponent, RefObject, createRef } from 'react'
import { Field as FinalFormField } from 'react-final-form'

import { isDeepEqual } from '@/server-data'
import TextField from './TextField'

const identity = (a: string): string => a

export default class TextFieldWrapper extends PureComponent<Props> {
  private ref: RefObject<TextField>

  constructor(props: Props) {
    super(props)
    this.ref = createRef()
  }

  getInputRef = (): workwave.NullableHTMLInputElement => {
    if (this.ref.current) {
      return this.ref.current.getInputRef()
    }
  }

  render() {
    const { name, ...props } = this.props

    return (
      <FinalFormField
        {...props}
        name={name}
        parse={identity}
        isEqual={isDeepEqual}
        render={formProps => {
          return <TextField {...this.props} formProps={formProps} ref={this.ref} />
        }}
      />
    )
  }
}
