import { Car, Van, Truck, Pedestrian, Scooter, Pickup, Siren, Bus, LongTruck, Empty } from '@/icons'

interface Props {
  iconId: number | null
}

const size = 22

export function DeviceIcon(props: Props) {
  const { iconId } = props

  if (!iconId) return <Empty />

  switch (iconId) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 101:
      return <Car size={size} />

    case 102:
      return <Van size={size} />

    case 20:
    case 103:
      return <Truck size={size} />

    case 10:
    case 104:
      return <Pedestrian size={size} />

    case 105:
      return <Scooter size={size} />

    case 106:
      return <Pickup size={size} />

    case 107:
      return <Siren size={size} />

    case 108:
      return <Bus size={size} />

    case 109:
      return <LongTruck size={size} />

    default:
      return <Empty size={size} />
  }
}
