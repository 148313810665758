import { format } from 'date-fns/esm'

const getPluralShortMinutesText = (translate: uui.domain.intl.Translate, value: number) =>
  translate({ id: 'global.plural.short.minutes', values: { value } })

const getPluralShortHoursText = (translate: uui.domain.intl.Translate, value: number) =>
  translate({ id: 'global.plural.short.hours', values: { value } })

export const formatMinutes = (translate: uui.domain.intl.Translate, sec: number): string => {
  return getPluralShortMinutesText(translate, Math.floor(Math.abs(sec) / 60))
}

const getPluralShortDaysText = (translate: uui.domain.intl.Translate, value: number) =>
  translate({ id: 'global.plural.short.days', values: { value } })

const getPluralLongMinutesText = (translate: uui.domain.intl.Translate, value: number) =>
  translate({ id: 'global.plural.long.minutes', values: { value } })

const getPluralLongHoursText = (translate: uui.domain.intl.Translate, value: number) =>
  translate({ id: 'global.plural.long.hours', values: { value } })

const getPluralLongSecondsText = (translate: uui.domain.intl.Translate, value: number) =>
  translate({ id: 'global.plural.long.seconds', values: { value } })

const getPluralShortSecondsText = (translate: uui.domain.intl.Translate, value: number) =>
  translate({ id: 'global.plural.short.seconds', values: { value } })

const getPluralLongDaysText = (translate: uui.domain.intl.Translate, value: number) =>
  translate({ id: 'global.plural.long.days', values: { value } })

export type TimeDurationFormats =
  | 'EXTRA_SHORT'
  | 'SHORT'
  | 'LONG'
  | 'MINUTES_ONLY'
  | 'DURATION_HH_MM'
  | 'HOURS_ONLY'

// migrated from V2, original helper name: `formatDurationSec`
// see: https://github.com/WorkWave/via-volo-ui/blob/master/apps/uui/src/utils/time/time.ts
export const formatSeconds =
  (translate: uui.domain.intl.Translate) => (sec: number, fmt: TimeDurationFormats) => {
    const days = Math.floor(sec / 86400)
    const hours = Math.floor((sec % 86400) / 3600)
    const minutes = Math.floor((sec % 3600) / 60)
    const seconds = Math.floor((sec % 3600) % 60)
    const parts: string[] = []

    if (fmt === 'HOURS_ONLY') {
      const hh = hours <= 9 && hours > 0 ? `0${hours}` : hours.toString()
      return `${hh} h`
    }

    if (fmt === 'MINUTES_ONLY') {
      return formatMinutes(translate, sec)
    }

    if (fmt === 'DURATION_HH_MM') {
      const hh = hours <= 9 ? `0${hours}` : hours.toString()
      const mm = minutes <= 9 ? `0${minutes}` : minutes.toString()

      return `${hh}:${mm}`
    }

    if (days > 0) {
      let daysText: string = ''

      switch (fmt) {
        case 'SHORT':
        case 'EXTRA_SHORT':
          daysText = getPluralShortDaysText(translate, days)
          break
        default:
          daysText = getPluralLongDaysText(translate, days)
      }

      parts.push(daysText)
    }

    if (hours) {
      let hoursText: string = ''

      switch (fmt) {
        case 'SHORT':
        case 'EXTRA_SHORT':
          hoursText = getPluralShortHoursText(translate, hours)
          break
        default:
          hoursText = getPluralLongHoursText(translate, hours)
      }

      parts.push(hoursText)
    }

    if (typeof minutes === 'number') {
      let minutesText: string = ''

      switch (fmt) {
        case 'SHORT':
        case 'EXTRA_SHORT':
          minutesText = getPluralShortMinutesText(translate, minutes)
          break
        default:
          minutesText = getPluralLongMinutesText(translate, minutes)
      }

      parts.push(minutesText)
    }

    if (seconds) {
      if (fmt !== 'EXTRA_SHORT') {
        parts.push(
          fmt === 'LONG'
            ? getPluralLongSecondsText(translate, seconds)
            : getPluralShortSecondsText(translate, seconds),
        )
      }
    }

    return parts.join(' ')
  }

export const formatHour = (
  date: Date,
  preferences: uui.domain.client.rm.AccountPreferences,
): string => {
  const { timeFormat } = preferences

  return format(date, timeFormat)
}
