import { useMemo } from 'react'

import { useCopySelectedOrdersModal } from '@/components/modals/CopySelectedOrdersModal'
import { useDuplicateOrderModal } from '@/components/modals/DuplicateOrderModal'
import { useUnassignOrderModal } from '@/components/modals/UnassignOrderModal'
import { useResidentModalsApi } from '@/components/modals/ResidentModals'
import { useDeleteOrderModal } from '@/components/modals/DeleteOrderModal'
import { useFitInOrderModal } from '@/components/modals/FitInOrderModal'

import { Duplicate, Export, Trash, Edit, Unassign, Gears, CopyOrders } from '@/icons'
import {
  SecondaryColumnHeaderActionsRow,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsSeparator,
} from '@/forms-legacy'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { useTexts } from '../../useTexts'
import { useBulkViewActionsData } from './hooks/useBulkViewActionsData'

interface Props {
  orders: uui.domain.client.rm.ExtendedOrderStep[]
}

export function BulkViewActions(props: Props) {
  const { orders } = props
  const texts = useTexts()

  const { show: showDuplicateOrderModal, Modal: DuplicateOrderModal } = useDuplicateOrderModal()
  const { show: showUnassignOrderModal, Modal: UnassignOrderModal } = useUnassignOrderModal()
  const { show: showDeleteOrderModal, Modal: DeleteOrderModal } = useDeleteOrderModal()
  const {
    exportOrders: { show: showExportOrderModal },
  } = useResidentModalsApi()
  const { show: showFitInOrderModal, Modal: FitInOrderModal } = useFitInOrderModal()
  const { show: showCopySelectedOrdersModal, Modal: CopySelectedOrdersModal } =
    useCopySelectedOrdersModal()

  const {
    allAssigned,
    isSimulation,
    allUnassigned,
    onStartEditing,
    canDuplicateOrders,
    assignedOrderStepIds,
    unassignedOrderStepIds,
    duplicateDisabledTooltipTitle,
    duplicateDisabledTooltipSubtitle,
    canUnassign,
    canUnassignTitle,
  } = useBulkViewActionsData(orders)

  const unassignText = useMemo(() => {
    if (allAssigned) return texts.unassignAll

    return `${texts.unassign} (${assignedOrderStepIds.length})`
  }, [texts, allAssigned, assignedOrderStepIds.length])

  const fitInText = useMemo(() => {
    if (allUnassigned) return texts.fitInAll

    return `${texts.fitIn} (${unassignedOrderStepIds.length})`
  }, [texts, allUnassigned, unassignedOrderStepIds.length])

  if (orders.length === 0) return null

  return (
    <>
      <SecondaryColumnHeaderActionsRow>
        {isSimulation && (
          <ReadOnlyTooltip
            render={preventEditing => (
              <SecondaryColumnHeaderActionButton
                onClick={showCopySelectedOrdersModal}
                testid="orders-copy-to-operations"
                label={texts.copyAllToOperations}
                disabled={preventEditing}
                icon={<CopyOrders />}
              />
            )}
          />
        )}

        <ReadOnlyTooltip
          canEdit={canDuplicateOrders}
          placement="top"
          title={duplicateDisabledTooltipTitle}
          subtitle={duplicateDisabledTooltipSubtitle}
          render={preventEditing => (
            <SecondaryColumnHeaderActionButton
              className="pendo-order__form-bulkduplicate"
              testid="order__form-bulkduplicate"
              disabled={preventEditing}
              onClick={showDuplicateOrderModal}
              label={texts.duplicateAll}
              icon={<Duplicate />}
            />
          )}
        ></ReadOnlyTooltip>

        <SecondaryColumnHeaderActionButton
          label={texts.exportAll}
          icon={<Export />}
          onClick={showExportOrderModal}
          className="pendo-order__form-bulkexport"
          testid="order__form-bulkexport"
        />

        {unassignedOrderStepIds.length > 0 && (
          <ReadOnlyTooltip
            render={preventEditing => (
              <SecondaryColumnHeaderActionButton
                label={fitInText}
                icon={<Gears />}
                onClick={showFitInOrderModal}
                disabled={preventEditing}
                className="pendo-order__form-bulk-fitin"
                testid="order__form-bulk-fitin"
              />
            )}
          />
        )}

        {assignedOrderStepIds.length > 0 && (
          <ReadOnlyTooltip
            canEdit={canUnassign}
            title={canUnassignTitle}
            render={preventEditing => (
              <SecondaryColumnHeaderActionButton
                label={unassignText}
                icon={<Unassign />}
                onClick={showUnassignOrderModal}
                disabled={preventEditing}
                className="pendo-order__form-bulkunassign"
                testid="order__form-bulkunassign"
              />
            )}
          />
        )}

        <SecondaryColumnHeaderActionsSeparator />

        <ReadOnlyTooltip
          render={preventEditing => (
            <SecondaryColumnHeaderActionButton
              label={texts.deleteAll}
              icon={<Trash />}
              onClick={showDeleteOrderModal}
              disabled={preventEditing || !orders}
              className="pendo-order__form-bulkdelete"
              testid="orders__delete-bulk-button"
            />
          )}
        />

        <ReadOnlyTooltip
          render={preventEditing => (
            <SecondaryColumnHeaderActionButton
              label={texts.editAll}
              icon={<Edit />}
              onClick={onStartEditing}
              disabled={preventEditing}
              className="pendo-order__form-bulkedit"
              testid="orders__bulk-edit-button"
            />
          )}
        />
      </SecondaryColumnHeaderActionsRow>

      <CopySelectedOrdersModal
        orderStepIds={[...assignedOrderStepIds, ...unassignedOrderStepIds]}
      />
      <UnassignOrderModal orderStepIds={assignedOrderStepIds} />
      <FitInOrderModal orderStepIds={unassignedOrderStepIds} />
      <DuplicateOrderModal />
      <DeleteOrderModal />
    </>
  )
}
