import type OlMap from 'ol/Map'

import VectorImageLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { setLayerMetadata } from '../layerMetadata'

export const createDeviceEventLayer = (map: OlMap) => {
  // DeviceEvent layer
  const pointsLayer = new VectorImageLayer({ source: new VectorSource() })

  setLayerMetadata(pointsLayer, 'uid', 'deviceEvent')

  map.addLayer(pointsLayer)
}
