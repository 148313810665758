import { useCallback, useEffect, useRef } from 'react'

import { deleteRegions } from '@/features/domain/region'
import { ConfirmPanel as ConfirmPanelComp } from '@/forms-legacy'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useTexts } from '../hooks/useTexts'

interface Props {
  regions: uui.domain.client.rm.Region[]
  view: 'default' | 'delete'
  setView: (view: 'default' | 'delete') => void
}

export function ConfirmPanel(props: Props) {
  const { setView, regions } = props
  const mounted = useRef(true)

  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const onCancel = useCallback(() => {
    setView('default')
  }, [setView])

  const onConfirm = useCallback(async () => {
    try {
      const ids = regions.map(r => r.id)
      const result = await dispatch(deleteRegions(ids))

      if (!deleteRegions.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      if (mounted.current) {
        setView('default')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [setView, dispatch, toast, regions])

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <ConfirmPanelComp
      title={texts.confirmPanelTitle(regions.length)}
      description={texts.confirmPanelSubtitle}
      confirmationText={texts.delete}
      onCancel={onCancel}
      onConfirm={onConfirm}
      testid="setup-region__bulk-delete-confirm-panel"
    />
  )
}
