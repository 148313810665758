import { CircularProgress } from '@mui/material'

import { HorizontalLayout } from '@/components/layout'
import { LightBulb, EyeCrossed } from '@/icons'

import { NavigoIconButton } from '../../../../../../elements/NavigoIconButton'
import { ButtonsContainer } from './components/ButtonsContainer'
import { RoutePathStyler } from './components/RoutePathStyler'
import { RouteStyler } from './components/RouteStyler'

import { useOrderStepIdsForRoutes } from './hooks/useOrderStepIdsForRoutes'
import { useStylerActions } from './hooks/useStylerActions'
import { useTexts } from './useTexts'

interface Props {
  routeIds: string[]
}

export function Styler(props: Props) {
  const { routeIds } = props

  const orderStepIds = useOrderStepIdsForRoutes(routeIds)
  const { methodInExecution, actions } = useStylerActions(routeIds, orderStepIds)
  const texts = useTexts()

  return (
    <HorizontalLayout
      data-trackid="navigo-routes-mapStyles-styler"
      data-testid="navigo-routes-mapStyles-styler"
      justifyContent="start"
      height={42}
    >
      <RouteStyler orderStepIds={orderStepIds} />
      <RoutePathStyler routeIds={routeIds} />

      <ButtonsContainer>
        <NavigoIconButton
          Icon={
            methodInExecution === 'hideRoutes' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <EyeCrossed size={16} color="$nightRider" />
            )
          }
          testid="navigo-routes-mapStyles-hideRoutesButton"
          text={texts.hideRoute(routeIds.length)}
          onClick={actions.hideRoutes}
        />

        <NavigoIconButton
          testid="navigo-routes-mapStyles-popoutRoutesButton"
          Icon={
            methodInExecution === 'popoutRoutes' ? (
              <CircularProgress size={21} color="primary" />
            ) : (
              <LightBulb size={16} color="$nightRider" />
            )
          }
          text={texts.popoutRoute(routeIds.length)}
          onClick={actions.popoutRoutes}
        />
      </ButtonsContainer>
    </HorizontalLayout>
  )
}
