import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function FitToMap(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 17">
        <path d="M1 1.438C1 1.196 1.196 1 1.438 1H5.34c.177 0 .337.107.404.27.023.054.034.11.034.167 0 .114-.053.22-.135.303L4.35 3.036l1.918 1.918c.118.118.12.32 0 .438l-.875.875c-.12.12-.318.12-.438 0L3.036 4.349 1.74 5.644c-.124.124-.309.168-.47.101-.165-.067-.27-.227-.27-.404V1.437zm9.255-.169c.067-.164.227-.269.404-.269h3.903c.242 0 .438.196.438.438V5.34c0 .177-.107.337-.27.404-.054.023-.11.034-.168.034-.113 0-.218-.053-.302-.135L12.964 4.35l-1.918 1.918c-.12.12-.318.12-.438 0l-.875-.875c-.12-.12-.12-.318 0-.438l1.918-1.918-1.295-1.296c-.124-.124-.168-.309-.101-.47zM5.173 9.65c-.078 0-.16.025-.219.084l-1.918 1.918-1.296-1.295c-.084-.082-.189-.135-.302-.135-.057 0-.114.01-.169.034-.162.067-.269.227-.269.404v3.903c0 .242.198.438.438.438H5.34c.177 0 .337-.105.404-.27.067-.161.023-.346-.1-.47l-1.296-1.296 1.901-1.918c.12-.12.12-.318 0-.438l-.858-.858c-.06-.059-.141-.101-.219-.101zm5.435.084c.061-.059.141-.084.219-.084.078 0 .16.025.219.084l1.918 1.918 1.296-1.295c.124-.124.309-.168.47-.101.165.067.27.227.27.404v3.903c0 .242-.196.438-.438.438H10.66c-.177 0-.337-.107-.404-.27-.023-.054-.034-.11-.034-.168 0-.113.053-.218.135-.302l1.295-1.296-1.918-1.918c-.12-.12-.103-.318.017-.438l.858-.875z" />
      </svg>
    </IconContainer>
  )
}
