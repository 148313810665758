import type { FormErrors, FormFields } from './formFields'
import { FormProvider } from '@workwave-tidal/tidal/form-ui/layout'
import { useVoiceData } from '../../hooks/useVoiceData'
import { formFields } from './formFields'
import { VoiceForm } from './VoiceForm'
import { useFormConfig } from './hooks/useFormConfig'

type Props = {
  onDiscard: () => void
}

export function VoiceFormLayout(props: Props) {
  const { onDiscard } = props
  const [voice] = useVoiceData()
  const initConfig = useFormConfig(voice)

  return (
    <FormProvider<FormFields, FormErrors>
      name="voice-form"
      fields={formFields}
      verbose={false}
      config={initConfig}
    >
      <VoiceForm onDiscard={onDiscard} />
    </FormProvider>
  )
}
