import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    nameRequired: translate({ id: 'settings.rmAccount.edit.errors.name.required' }),
    nameMaxLength: translate({ id: 'settings.rmAccount.edit.errors.name.maxLength' }),

    surnameRequired: translate({ id: 'settings.rmAccount.edit.errors.surname.required' }),
    surnameMaxLength: translate({ id: 'settings.rmAccount.edit.errors.surname.maxLength' }),

    emailRequired: translate({ id: 'settings.rmAccount.edit.errors.email.required' }),
    emailValid: translate({ id: 'settings.rmAccount.edit.errors.email.invalid' }),

    usernameRequired: translate({ id: 'settings.rmAccount.edit.errors.username.required' }),
    usernameMinLength: translate({ id: 'settings.rmAccount.edit.errors.username.minLength' }),
    usernameMaxLength: translate({ id: 'settings.rmAccount.edit.errors.username.maxLength' }),
    usernameNonUnique: translate({ id: 'settings.rmAccount.edit.errors.username.nonUnique' }),
    allowedCharacters: translate({
      id: 'settings.rmAccount.edit.errors.username.allowedCharacters',
    }),
  }))

  return api
}

export type ValidationTexts = ReturnType<typeof useTexts>
