import { useContext } from 'react'

import { PopupContext } from '../context/PopupContext'

/**
 * Expose the popup's root container for rendering some components inside it.
 */
export function usePopupRootContainer() {
  const popup = useContext(PopupContext)

  if (popup.status !== 'open') return

  return popup.rootContainer
}
