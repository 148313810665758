import type { IconContainerProps } from './typings'
import { ValidColor, theme } from '@/local/components'
import { IconContainer } from './elements/IconContainer'

type Props = IconContainerProps & {
  background: ValidColor
  foreground: ValidColor
}

export function AddOrder(props: Props) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 23 24">
        <path
          fill={theme.colors[props.background]}
          d="M18.0000035 11.3790006c-1.9916411-.51732-4.1976161.0038934-5.7573628 1.5636401-1.4403193 1.4403193-1.9950544 3.4316942-1.6642054 5.2964886C9.97119082 19.0651225 9.39380531 20 9 20c-.70544771 0-2-3-3-3H3.23529412C1.44849052 17 0 15.5515095 0 13.7647059V3.23520215C0 1.44839855 1.44849052-.00009196 3.23529412-.00009196l11.52942698-.0000029c1.7868036 0 3.2352941 1.44849051 3.2352941 3.23529411l-.0000117 8.14380135z"
        />
        <path
          fill={props.foreground ? theme.colors[props.foreground] : 'currentColor'}
          d="M20.03553405 13.67157295c1.95262489 1.9526249 1.9521198 5.11894801 0 7.07106781-1.9521198 1.9521198-5.11844293 1.9526249-7.07106781 0-1.9521198-1.9521198-1.9521198-5.118948 0-7.0710678 1.9521198-1.95211981 5.118948-1.95211981 7.0710678 0zm-3.04373534 5.66614351l.0004372-1.63819819 1.63819826-.00043727c.27578296-.0004657.49357623-.22014469.49357065-.49168493.0004657-.2729544-.2206367-.49217121-.4926483-.4926483l-1.63914112-.00050558-.00003408-1.63866962c-.00094865-.27436875-.22063669-.49311407-.4926483-.4926483-.27342588.0009372-.49121915.22155905-.49168493.49357065l.00003416 1.63866969-1.63866962-.00003422c-.27484017.00046577-.4935763.22014475-.49404207.49215636.00047708.27295446.22016519.49075685.49359115.49170544l1.63866954.00003415.00050566 1.63914105c.00000565.27436874.22110812.49358549.49170544.49359115.27389731-.00046577.49216201-.22061619.49215636-.49404208z"
        />
      </svg>
    </IconContainer>
  )
}
