import type { RegionValidation } from '../typings'

import { useTexts } from './useTexts'

export const useComposeFieldsWithError = () => {
  const texts = useTexts()

  return (errors: RegionValidation) =>
    Object.keys(errors).map(field => {
      switch (field) {
        case 'name':
          return texts.labelName

        case 'enterCost':
          return texts.labelCost

        case 'enterTimeSec':
          return texts.labelTime

        case 'color':
          return texts.labelColor

        case 'points':
          return texts.labelPoints

        default:
          return ''
      }
    })
}
