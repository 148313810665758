import styled from 'styled-components'

const ReadonlyImageTextInfo = styled.p`
  width: 100%;
  font-size: ${p => p.theme.fonts.$s};
  font-style: ${p => p.theme.weights.$regular};
`

ReadonlyImageTextInfo.displayName = 'ReadonlyImageTextInfo'
export default ReadonlyImageTextInfo
