import OlMap from 'ol/Map'
import { Translate } from 'ol/interaction'
import Point from 'ol/geom/Point'

import { gis } from '@/server-data'

import { getPinFeatureMetadata } from '../../layers/pin/pinFeatureMetadata'
import { findLayer } from '../../layers/utils/findLayer'
import { moveInspectPin } from '../../atoms/inspectPin/core/moveInspectPin'
import { getInteraction, registerInteraction, setInteractionMetadata } from '../interactionMetadata'
import { moveLocationPin } from '../../atoms/locationPin/core/moveLocationPin'

export function registerMovePinInteraction(map: OlMap) {
  const uid = 'rm-move-pin-interaction'

  if (getInteraction(uid)) return

  const movePinInteraction = new Translate({
    layers: [findLayer(map, 'pin')],
  })

  movePinInteraction.on('translateend', translateEvent => {
    // support only single drag of a pin feature
    const feature = translateEvent.features.item(0)

    const pinId = feature.getId()
    const pinKind = getPinFeatureMetadata(feature, 'kind')

    // ATTENTION: `translateEvent.coordinate` is always slightly off from the final coordinate of the moved feature
    // probably as side-effect of some number rounding. It's preferable to use the moved features new coordinates.
    const featureCoordinates = (feature.getGeometry() as Point).getCoordinates()
    const latLng = gis.fromCoordinateToLatLng(featureCoordinates)

    if (pinKind === 'inspectPin') {
      moveInspectPin(latLng)
    }

    if (pinKind === 'locationPin' && pinId) {
      moveLocationPin(pinId as uui.domain.ui.map.LocationPinId, latLng)
    }
  })

  setInteractionMetadata(movePinInteraction, 'rmInteraction', true)
  setInteractionMetadata(movePinInteraction, 'uid', uid)
  registerInteraction(uid, movePinInteraction)

  map.addInteraction(movePinInteraction)
}
