import type { ReactNode, ReactElement, CSSProperties } from 'react'

import { useRef } from 'react'

import { Popover, styled } from '@mui/material'

import { Text } from '@/local/components'

import { useTooltip } from './hooks/useTooltip'
import { useTooltipPositioning } from './hooks/useTooltipPositioning'
import { Container, Props as CosmeticProps } from './components/Container'
import { useFixPopupPosition } from './hooks/useFixPopupPosition'

type WithTitle = { title: string | string[]; subtitle?: string | string[] }
type WithContent = { content: ReactElement }

type Props = {
  disableAutoFocus?: boolean
  disabled?: boolean
  placement: 'top' | 'bottom' | 'left' | 'right'
  children?: ReactNode
  float?: 'left' | 'right'
  flex?: string
  containerStyle?: CSSProperties
  delayMs?: number
} & Partial<CosmeticProps> &
  (WithContent | WithTitle)

type HoverAreaProps = Pick<Props, 'float' | 'flex'>

const CustomPopover = styled(Popover, { name: 'CustomPopover' })(() => ({
  pointerEvents: 'none',
}))

const HoverArea = styled('span', {
  name: 'HoverArea',
  shouldForwardProp: prop => prop !== 'flex' && prop !== 'float' && prop !== 'sx',
})<HoverAreaProps>(({ flex, float }) => ({
  display: 'flex',
  float,
  flex,
}))

export function Tooltip(props: Props) {
  const {
    disabled,
    maxWidth,
    textAlign,
    float,
    flex,
    disableAutoFocus = false,
    containerStyle = {},
    delayMs = 300,
  } = props
  const anchorEl = useRef<HTMLDivElement | null>(null)

  const { show, hide, visible } = useTooltip(delayMs)
  const { anchorOrigin, transformOrigin } = useTooltipPositioning(props.placement)

  const onPointerEnterHandler = disabled ? undefined : show
  const onPointerLeaveHandler = disabled ? undefined : hide

  const actionsRef = useFixPopupPosition()

  return (
    <>
      <HoverArea
        sx={containerStyle}
        ref={anchorEl}
        flex={flex}
        float={float}
        onPointerEnter={onPointerEnterHandler}
        onPointerLeave={onPointerLeaveHandler}
      >
        {props.children}
      </HoverArea>

      <CustomPopover
        open={visible && !disabled}
        action={actionsRef}
        anchorEl={anchorEl.current}
        elevation={0}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableAutoFocus={disableAutoFocus}
        disableScrollLock
        transitionDuration={0}
      >
        {'title' in props ? (
          <Container maxWidth={maxWidth} textAlign={textAlign} data-testid="tooltip-content">
            {typeof props.title === 'string' && (
              <Text size="$m" color="$pureWhite">
                {props.title}
              </Text>
            )}

            {Array.isArray(props.title) &&
              props.title.map((title, index) => (
                <Text key={`title-${index}`} size="$m" color="$pureWhite">
                  {title}
                </Text>
              ))}

            {typeof props.subtitle === 'string' && (
              <Text size="$m" color="$silver">
                {props.subtitle}
              </Text>
            )}

            {Array.isArray(props.subtitle) &&
              props.subtitle.map((subtitle, index) => (
                <Text key={`subtitle-${index}`} size="$m" color="$silver">
                  {subtitle}
                </Text>
              ))}
          </Container>
        ) : (
          <Container maxWidth={maxWidth} textAlign={textAlign} data-testid="tooltip-content">
            {props.content}
          </Container>
        )}
      </CustomPopover>
    </>
  )
}
