import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Load(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 20 16">
        <path d="M18 0h-7v4h3l-4 4-4-4h3V0H2C.895 0 0 .895 0 2v12c0 1.105.895 2 2 2h16c1.105 0 2-.895 2-2V2c0-1.105-.895-2-2-2zm-2 14H4v-3h12v3z" />
      </svg>
    </IconContainer>
  )
}
