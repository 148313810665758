import { useCallback, useEffect, useRef } from 'react'

import { useSelector } from 'react-redux'
import {
  selectVehicles,
  setVehiclesLock,
  selectVehiclesByVehicleId,
  createVehicleException,
} from '@/features/domain/vehicle'
import { approveRoutes, buildRoutes, exportRoutes, revokeRoutes } from '@/features/domain/route'
import { swapOrderSteps, invertOrderSteps } from '@/features/domain/order'

import {
  usePagination,
  setEditingState,
  setCrudSelection,
  setRoutingSidebar,
  setVehicleFormException,
} from '@/atoms'

import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { useNavigate } from '@/routing'
import { unproxify } from '@/utils'

import { fitMapToSelection } from '@/map'
import { useRoutesNavigator } from '../../../../../hooks/useRoutesNavigator'

export function useActions(showBuildProgressModal: () => void) {
  const { currentRouteIds, currentRoutes } = useRoutesNavigator()

  const appDispatch = useAppDispatch()
  const { setPage } = usePagination('vehicleTab')
  const navigate = useNavigate<uui.routing.Setup>(true)
  const toast = useNotification()

  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const unifiedVehicles = useSelector(selectVehicles)

  const currentRouteIdRef = useRef(currentRouteIds.length === 1 ? currentRouteIds[0] : null)
  useEffect(() => {
    currentRouteIdRef.current = currentRouteIds.length === 1 ? currentRouteIds[0] : null
  }, [currentRouteIds])

  /**
   * Toggle Route Details
   */
  const toggleRouteDetails = useCallback(() => {
    setRoutingSidebar(settings => {
      return settings.view === 'routeDetails' || !currentRouteIdRef.current
        ? { view: 'main' }
        : { view: 'routeDetails', routeId: currentRouteIdRef.current }
    })
  }, [])

  /**
   * Unlock routes
   */
  const lock = useCallback(async () => {
    try {
      const vehicleIds = currentRoutes.map(routeInfo => routeInfo.vehicleId)
      const result = await appDispatch(setVehiclesLock({ lockedStatus: true, vehicleIds }))

      if (!setVehiclesLock.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [appDispatch, toast, currentRoutes])

  /**
   * Unlock routes
   */
  const unlock = useCallback(async () => {
    try {
      const vehicleIds = currentRoutes.map(routeInfo => routeInfo.vehicleId)
      const result = await appDispatch(setVehiclesLock({ lockedStatus: false, vehicleIds }))

      if (!setVehiclesLock.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [appDispatch, toast, currentRoutes])

  /**
   * Swap orderSteps
   */
  const swap = useCallback(async () => {
    try {
      // Build route identifiers
      const result = await appDispatch(
        swapOrderSteps({
          srcRoute: { date: currentRoutes[0].dateAsString, vehicleId: currentRoutes[0].vehicleId },
          dstRoute: { date: currentRoutes[1].dateAsString, vehicleId: currentRoutes[1].vehicleId },
        }),
      )

      if (!swapOrderSteps.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [appDispatch, toast, currentRoutes])

  /**
   * Invert orderSteps
   */
  const invert = useCallback(async () => {
    try {
      // Build route identifiers
      const payload: uui.domain.client.rm.RouteIdentifier[] = currentRoutes.map(route => ({
        date: route.dateAsString,
        vehicleId: route.vehicleId,
      }))

      const result = await appDispatch(invertOrderSteps(payload))

      if (!invertOrderSteps.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [appDispatch, toast, currentRoutes])

  /**
   * Dispatch routes
   */
  const dispatch = useCallback(async () => {
    try {
      // Build route identifiers
      const routeIdentifiers: uui.domain.client.rm.RouteIdentifier[] = currentRoutes.map(route => ({
        vehicleId: route.vehicleId,
        date: route.dateAsString,
      }))

      const result = await appDispatch(
        approveRoutes({ mode: 'byRoutes', routes: routeIdentifiers }),
      )

      if (!approveRoutes.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [appDispatch, toast, currentRoutes])

  /**
   * Revoke routes
   */
  const revoke = useCallback(async () => {
    try {
      // Build route identifiers
      const routeIdentifiers: uui.domain.client.rm.RouteIdentifier[] = currentRoutes.map(route => ({
        vehicleId: route.vehicleId,
        date: route.dateAsString,
      }))

      const result = await appDispatch(revokeRoutes({ mode: 'byRoutes', routes: routeIdentifiers }))

      if (!revokeRoutes.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [appDispatch, toast, currentRoutes])

  /**
   * Export route details to driver via e-mail
   */
  const exportToDriver = useCallback(async () => {
    try {
      const result = await appDispatch(
        exportRoutes({
          routeIds: unproxify(currentRouteIds),
          config: {
            format: 'EMAIL',
            timeFormat: 'HH:mm',
            currentRoutes: true,
          },
        }),
      )

      if (!exportRoutes.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [appDispatch, toast, currentRouteIds])

  /**
   * Build current route
   */
  const build = useCallback(async () => {
    try {
      showBuildProgressModal()

      const result = await appDispatch(buildRoutes(unproxify(currentRouteIds)))

      if (!buildRoutes.fulfilled.match(result)) {
        throw new Error('Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [appDispatch, toast, showBuildProgressModal, currentRouteIds])

  /**
   * Edit current route
   */
  const edit = useCallback(async () => {
    if (currentRoutes.length !== 1) return

    const route = currentRoutes[0]
    const vehicleId = route.vehicleId
    const unifiedId = vehiclesByVehicleId[vehicleId]

    const unifiedVehicle = unifiedVehicles[vehiclesByVehicleId[vehicleId]]

    if (!unifiedVehicle) {
      throw new Error(`Cannot find vehicle with id: ${unifiedId}`)
    }
    if (!unifiedVehicle.hasRoutingLicense) {
      throw new Error(`Vehicle has not routing licence`)
    }

    const exceptionDate = route.dateAsString
    const settings = unifiedVehicle.vehicle.settings[exceptionDate]
    const create = settings === undefined

    if (create) {
      const result = await appDispatch(
        createVehicleException({ unifiedId, recurrence: exceptionDate }),
      )

      if (createVehicleException.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    }

    setCrudSelection('unifiedVehicles', [unifiedId])
    setVehicleFormException({ editMode: create ? 'create' : 'edit', exception: exceptionDate })
    setEditingState({ editing: true, ctx: 'vehicle', editingEntities: [vehicleId] })

    setPage(1)

    fitMapToSelection({ preventIfVisible: false }, 'add')

    navigate('vehicles')
  }, [navigate, setPage, appDispatch, unifiedVehicles, vehiclesByVehicleId, currentRoutes])

  const onExportManifest = useCallback(async () => {
    try {
      await appDispatch(
        exportRoutes({
          routeIds: unproxify(currentRouteIds),
          config: {
            format: 'HTML',
            currentRoutes: true,
          },
          autoDownloadFile: true,
        }),
      )
    } catch (e) {
      toast.error(e.message)
    }
  }, [appDispatch, toast, currentRouteIds])

  const onExportDispatchedManifest = useCallback(async () => {
    try {
      await appDispatch(
        exportRoutes({
          routeIds: unproxify(currentRouteIds),
          config: {
            format: 'HTML',
            currentRoutes: false,
          },
          autoDownloadFile: true,
        }),
      )
    } catch (e) {
      toast.error(e.message)
    }
  }, [appDispatch, toast, currentRouteIds])

  const canExportDispatchedManifest = useCallback(() => {
    return currentRoutes.some(route => !!route.approvedVersion)
  }, [currentRoutes])

  return {
    edit,
    lock,
    swap,
    build,
    invert,
    revoke,
    unlock,
    dispatch,
    exportToDriver,
    onExportManifest,
    toggleRouteDetails,
    onExportDispatchedManifest,
    canExportDispatchedManifest,
  }
}
