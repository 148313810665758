import type Geometry from 'ol/geom/Geometry'
import type {
  ExecutionEventMarker,
  PODMarker,
} from '../../../../atoms/executionEventMarkers/executionEventMarkers'

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { setFeatureMetadata } from '../../../featureMetadata'
import { getExecutionEventFeatureStyle } from './getExecutionEventFeatureStyle'

export function createExecutionEventFeature(
  marker: workwave.DeepReadonly<ExecutionEventMarker | PODMarker>,
): Feature<Geometry> {
  const { lonLat, type } = marker

  const feature = new Feature({ geometry: new Point(Array.from(lonLat)) })

  // use event type as unique ID
  feature.setId(type)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setFeatureMetadata(feature, 'type', 'executionEvents')

  // ------------------------
  // Feature Style

  const style = getExecutionEventFeatureStyle(marker)
  feature.setStyle(style)

  return feature
}
