import type { MonthNavigatorProps, YYYYMMDD } from '../typings'

import { useMemo } from 'react'
import {
  isBefore,
  isAfter,
  startOfMonth,
  endOfMonth,
  format,
  addMonths,
  subMonths,
  isSameMonth,
} from 'date-fns/esm'

import { useDateFnsOptions } from '@/hooks'
import { parseDate } from '../utils/parseDate'

type LabelFormat = 'month' | 'month-year'

type Props = {
  referenceDate: Date
  setReferenceDate: (date: Date) => void
  labelFormat: LabelFormat
  months: 1 | 2
  minDateAsString?: YYYYMMDD
  maxDateAsString?: YYYYMMDD
}

type Formats = Record<LabelFormat, string>

const labelFormats: Formats = {
  month: 'MMMM',
  'month-year': 'MMMM yyyy',
}

export const useMonthNavigatorProps = ({
  referenceDate,
  setReferenceDate,
  months,
  minDateAsString,
  maxDateAsString,
  labelFormat,
}: Props): MonthNavigatorProps => {
  const minDate = minDateAsString ? parseDate(minDateAsString) : undefined
  const maxDate = maxDateAsString ? parseDate(maxDateAsString) : undefined

  const options = useDateFnsOptions()

  return useMemo(() => {
    const currentStart = startOfMonth(referenceDate)
    const currentEnd = endOfMonth(addMonths(referenceDate, months - 1))

    const formatForLabel = labelFormats[labelFormat]

    const labels = [
      format(currentStart, formatForLabel, options),
      format(currentEnd, formatForLabel, options),
    ] as [string, string]

    const onClickPrev = () => {
      setReferenceDate(subMonths(currentStart, 1))
    }

    const onClickNext = () => {
      setReferenceDate(addMonths(currentStart, 1))
    }

    const disablePrev =
      minDate && (isBefore(currentStart, minDate) || isSameMonth(currentStart, minDate))

    const disableNext =
      maxDate && (isAfter(currentEnd, maxDate) || isSameMonth(currentEnd, maxDate))

    return {
      disablePrev,
      disableNext,
      labels: months === 1 ? [labels[0], undefined] : labels,
      onClickPrev,
      onClickNext,
    }
  }, [referenceDate, setReferenceDate, months, minDate, maxDate, labelFormat, options])
}
