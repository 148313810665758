import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState({
    cancelButtonTitle: translate({ id: 'demoTools.renameTerritory.modal.cancelButton.title' }),
    commandButtonTitle: translate({ id: 'demoTools.renameTerritory.modal.commandButton.title' }),
    genericError: translate({ id: 'demoTools.renameTerritory.error.generic' }),
    modalTitle: translate({ id: 'demoTools.renameTerritory.modal.title' }),
    modalSubTitle: (territoryName: string) =>
      translate({ id: 'demoTools.renameTerritory.modal.subtitle', values: { territoryName } }),
    inputFieldTitle: translate({ id: 'demoTools.renameTerritory.modal.inputField.title' }),
    transactionWarningCardTitle: translate({
      id: 'demoTools.renameTerritory.transaction.warningCard.title',
    }),
    transactionWarningCardDescription: translate({
      id: 'demoTools.renameTerritory.transaction.warningCard.description',
    }),
  })

  return api
}
