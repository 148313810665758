import { useCallback } from 'react'

import { useNotification, useIsUnmounted } from '@/hooks'
import { useAppDispatch } from '@/store'
import { importNewTerritory } from '@/features/domain/demoTools'

import { useTexts } from '../useTexts'
import { useController } from './useController'

/**
 * Manage import confirmation.
 */
export function useHandleOnConfirm() {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const isUnmounted = useIsUnmounted()

  const {
    close,
    data: { name, importedTerritory },
  } = useController()

  return useCallback(async () => {
    if (!name || !importedTerritory) return

    try {
      await dispatch(
        importNewTerritory({
          name,
          importedTerritory: JSON.parse(JSON.stringify(importedTerritory)),
        }),
      )
      if (isUnmounted()) return

      close?.()
    } catch (e) {
      toast.error(texts.genericError)
    }
  }, [name, importedTerritory, toast, texts, dispatch, close, isUnmounted])
}
