import { mapAtom } from '../../atoms/map/map'
import { findLayer } from '../utils/findLayer'

import { mapStylesUpdateOrderFeatures } from './utils/mapStylesUpdateOrderFeatures'

export const createUpdateOrderFeaturesMapStyleFromChangelog = (
  markers: uui.domain.ui.map.markers.MapMarkers['order'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['order'],
  selection: Set<string>,
  selectedOrderIds: Set<string>,
) => {
  // ATTENTION: Application of Map Style changes will not be cached if a delayed rendering mechanism will be implemented
  // After the whole set of delayed marker changelogs is applied a full refresh of Map Styles will be required

  return async () => {
    const map = mapAtom.map
    const layer = findLayer(map, 'order')

    // only the `update` field is populated in a mapStyles changelog
    await mapStylesUpdateOrderFeatures(layer, markers, mapStyles, selection, selectedOrderIds)
  }
}
