import type { PublicProps } from '../typings'

import { Component, KeyboardEvent } from 'react'

import { Search as Lens } from '@/icons'
import { SearchField } from '@/forms-legacy'

type Props = PublicProps & {
  filter: string
  onChangeFilter: (filter: string) => void
  onAddTag: (tag: string) => void
}

interface State {
  placeholder: string
}

export default class Search extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { renderText } = props
    this.state = {
      placeholder: renderText('searchPlaceholder', props),
    }
  }

  private handleOnKeyUp = (event: KeyboardEvent<HTMLElement>): void => {
    const { filter, onChangeFilter, enterToAdd, onAddTag } = this.props
    if (!enterToAdd) {
      return
    }
    const { key } = event
    if (key === 'Enter') {
      onChangeFilter('')
      onAddTag(filter)
    }
  }

  render() {
    const { onChangeFilter, filter } = this.props
    const { placeholder } = this.state

    return (
      <SearchField
        name="search"
        onChange={onChangeFilter}
        value={filter}
        placeholder={placeholder}
        extraIcon={<Lens />}
        onKeyUp={this.handleOnKeyUp}
      />
    )
  }
}
