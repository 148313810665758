import { OrdersSubMenu } from '../../OrdersSubMenu'
import { DepotsSubMenu } from '../../DepotsSubMenu'

interface Props {
  menuOpen: boolean
}

export function FilteredGuestSubMenu(props: Props) {
  const { menuOpen } = props

  return (
    <>
      <OrdersSubMenu parentOpen={menuOpen} />
      <DepotsSubMenu parentOpen={menuOpen} />
    </>
  )
}
