import styled from 'styled-components'

type Props = {
  large?: boolean
}

const LoadContainer = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: ${p => (p.large ? 380 : 250)}px;
  height: 28px;

  background: ${p => p.theme.colors.$whiteSmoke};
  border-radius: 6px;

  padding: 0 0 0 10px;
  border: 2px solid ${p => p.theme.colors.$whiteSmoke};
`
LoadContainer.displayName = 'LoadContainer'
export default LoadContainer
