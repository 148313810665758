import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'userManagement.3pc.header.title' }),
    addTooltip: (disabled?: boolean) =>
      translate({
        id: disabled
          ? 'userManagement.3pc.header.tooltip.addTerritory.disabled'
          : 'userManagement.3pc.header.tooltip.addTerritory',
      }),
  }))

  return api
}
