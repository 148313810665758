import type { FormFields, FormErrors } from '../../../formFields'
import type { FormValidator, FormError } from '@workwave-tidal/tidal/form-fairy'
import type { ValidationTexts } from '../useTexts'

export const createValidateSurname =
  (texts: ValidationTexts): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const { value, required } = formApi.getField('surname')

    const maxLengthError: FormError<keyof FormFields> = {
      id: 'surname-max-length',
      field: 'surname',
      message: texts.surnameMaxLength,
      priority: 3,
    }

    const requiredError: FormError<keyof FormFields> = {
      id: 'surname-required',
      field: 'surname',
      message: texts.surnameRequired,
      priority: 7,
    }

    const empty = value.trim().length === 0
    const invalidMaxLength = value.trim().length > 30

    return {
      [requiredError.id]: required && empty ? requiredError : null,
      [maxLengthError.id]: invalidMaxLength ? maxLengthError : null,
    }
  }
