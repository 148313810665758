import { Tooltip } from '@/components/primitives/Tooltip'

import { Row } from '../../Row'

import { useTexts } from '../useTexts'

interface Props {
  count: number
  onSelect: () => void
}

export function AllOrders(props: Props) {
  const { count, onSelect } = props
  const texts = useTexts()

  return (
    <Tooltip
      textAlign="left"
      placement="right"
      title={texts.orders(count)}
      subtitle={texts.ordersTooltip}
    >
      <Row
        onClick={onSelect}
        text={texts.all}
        disabled={count === 0}
        value={count}
        testId="plan-summary-all-orders"
      />
    </Tooltip>
  )
}
