import { useCallback } from 'react'

import { setMainSelection } from '@/atoms'
import { SendBackward } from '@/icons'

import { NavigoIconButton } from '../../../../../../elements/NavigoIconButton'
import { useTexts } from '../useTexts'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

export function GoToPairedButton(props: Props) {
  const { extOrder } = props
  const texts = useTexts()

  const goToPaired = useCallback(() => {
    if (extOrder.type !== 'pd' || !extOrder.delivery || !extOrder.pickup) return

    const pairedOrderStepId =
      extOrder.orderStepType === 'p' ? extOrder.delivery.id : extOrder.pickup.id

    setMainSelection('orderSteps', [pairedOrderStepId])
  }, [extOrder])

  return extOrder.type === 'pd' ? (
    <NavigoIconButton
      onClick={goToPaired}
      Icon={<SendBackward size={16} color="$nightRider" />}
      text={extOrder.orderStep.type === 'p' ? texts.goToDropoff : texts.goToPickup}
    />
  ) : null
}
