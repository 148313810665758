import { NearMeDisabled } from '@mui/icons-material'

import { Tooltip } from '@/components/primitives/Tooltip'
import {
  SecondaryColumnContentHorizontalRuler,
  SecondaryColumnContentContainer,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'

import { useAssignmentsInfo } from './hooks/useAssignmentsInfo'
import { useTexts } from './hooks/useTexts'

interface Props {
  assignments: uui.domain.client.rm.DriverAssignments
}

export function ViewDetails(props: Props) {
  const { assignments } = props

  const assignmentsInfo = useAssignmentsInfo(assignments)
  const texts = useTexts()

  return (
    <SecondaryColumnContentContainer>
      <SecondaryColumnContentHorizontalRuler bottomSpace />

      {assignmentsInfo.map(info => {
        const { driverLabel, driverId, id, vehicleLabel, hasDevice } = info

        return (
          <ReadonlyBlock half key={id}>
            <ReadonlyLabel empty={!driverId}>
              {vehicleLabel}
              {!hasDevice && (
                <Tooltip title={texts.notTracked} placement="bottom">
                  <NearMeDisabled
                    sx={{ fontSize: 20, display: 'inline-block', verticalAlign: 'bottom', ml: 0.5 }}
                  />
                </Tooltip>
              )}
            </ReadonlyLabel>

            {driverId && (
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>{driverLabel}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            )}
          </ReadonlyBlock>
        )
      })}
    </SecondaryColumnContentContainer>
  )
}
