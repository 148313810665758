import { styled } from '@mui/material'
import { theme } from '@/styles'

interface AvatarProps {
  color?: string
  size?: number
}

interface Props extends AvatarProps {
  elementsCount: number
}

const Avatar = styled('div', {
  name: 'BulkAvatar',
})<AvatarProps>(p => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flex: '0 0 auto',
    width: `${p.size ?? 40}px`,
    height: `${p.size ?? 40}px`,
    color: theme.colors.$pureWhite,
    background: p.color ?? theme.colors.$nightRider,
    border: `1px solid ${theme.colors.$pureWhite}`,
    borderRadius: '50%',
    marginRight: '16px',
    fontSize: theme.fonts.$p3,
    fontWeight: theme.weights.$semiBold,
  }
})

export function BulkAvatar(props: Props) {
  const { elementsCount, size, color } = props

  return <Avatar size={size} color={color}>{`x${elementsCount}`}</Avatar>
}
