import { useCallback, useMemo } from 'react'
import { listApiSubscribersAtom, setListSubscribers } from '../listApiSubscribers'

/**
 * Expose list imperative API.
 */
export function useListApi(category: uui.domain.ui.list.Category) {
  const reset = useCallback(() => {
    listApiSubscribersAtom.subscribers[category].callbacks.forEach(cb => cb('reset'))
  }, [category])

  const scrollTo = useCallback(
    (id: string) => {
      setListSubscribers(category, draft => {
        // save or clear the buffered id
        draft.scrollToBuffer = id

        if (id === undefined) return draft

        // LIST_IMPERATIVE_API: flow1, step3
        // All the subscribed components (the StructuredVirtualList and other passive listeners
        // if any) are called with the 'scrollTo' action

        let clearBuffer = false

        // the subscribed components specify if buffer must be cleared or not
        for (const cb of draft.callbacks) {
          if (cb('scrollTo', id)) {
            clearBuffer = true
          }
        }

        // LIST_IMPERATIVE_API: flow1, step5
        // The buffer is cleared

        // LIST_IMPERATIVE_API: flow2, step2
        // The StructuredVirtualList isn't subscribed yet so the `scrollToBuffer` isn't cleared

        if (clearBuffer) {
          draft.scrollToBuffer = undefined
        }

        return draft
      })

      // return a cleanup function that reset the scrollToBuffer in case no subscribers take care of doing that
      return () => {
        if (id === undefined) return

        setListSubscribers(category, draft => {
          if (draft.scrollToBuffer === id) {
            draft.scrollToBuffer = undefined
          }

          return draft
        })
      }
    },
    [category],
  )

  return useMemo(() => {
    return {
      reset,
      scrollTo,
    }
  }, [reset, scrollTo])
}
