import { Button } from '@mui/material'
import { ButtonToolbar, ButtonToolbarItem } from '@/local/components'
import { useTexts } from '../useTexts'

interface Props {
  onClose: () => void
  selectToday: () => void
  onConfirm: () => void
}

export function GpsOnlyToolbar(props: Props) {
  const texts = useTexts()
  const { onClose, onConfirm } = props

  return (
    <ButtonToolbar justifyContent="end">
      <ButtonToolbarItem gutter={10}>
        <Button
          data-trackid="territory-calendar__open-button"
          data-testid="territory-calendar__open-button"
          onClick={onConfirm}
          variant="contained"
          color="primary"
          type="submit"
          size="medium"
        >
          {texts.open}
        </Button>
      </ButtonToolbarItem>

      <ButtonToolbarItem gutter={10}>
        <Button
          data-trackid="territory-calendar__cancel-button"
          data-testid="territory-calendar__cancel-button"
          onClick={onClose}
          color="primary"
          variant="text"
          type="submit"
          size="medium"
        >
          {texts.cancel}
        </Button>
      </ButtonToolbarItem>
    </ButtonToolbar>
  )
}
