import { Component } from 'react'

import { ReadonlyLabel, ReadonlyBlock, HorizontalRuler } from '@/forms-legacy'

import DetectedTime from '../../../../../components/ExecutionEvents/elements/DetectedTime'
import DetectedServiceTime from '../../../../../components/ExecutionEvents/elements/DetectedServiceTime'

import * as Texts from '../../intl'
import { ExecutionEventsFormValues } from '../../typings'

import ReportedExecutionDetails from './components/ReportedExecutionDetails'
import { ReportedStatus } from './components/ReportedStatus'
import { ReportedStatusReason } from './components/ReportedStatusReason'
import { Props } from './typings'

const secondsInADay = 24 * 60 * 60

const parseTrackingData = (
  values: ExecutionEventsFormValues,
  workingDayStartSec: number,
): ExecutionEventsFormValues => {
  const { timeInSec, timeOutSec, ...rest } = values
  const isOvernight = workingDayStartSec > 0

  return {
    timeInSec:
      isOvernight && timeInSec < workingDayStartSec ? timeInSec + secondsInADay : timeInSec,
    timeOutSec:
      isOvernight && timeOutSec < workingDayStartSec ? timeOutSec + secondsInADay : timeOutSec,
    ...rest,
  }
}

export default class ExecutionEvents extends Component<Props> {
  render() {
    const {
      values,
      rescheduleBehavior,
      workingDayStartSec,
      territory: { statusReasons },
    } = this.props

    const showStatusReason = values.status === 'reschedule' && statusReasons.length > 0

    return (
      <ReadonlyBlock>
        <HorizontalRuler />
        <ReadonlyBlock>
          <ReadonlyLabel primary>{Texts.getExecutionDetailsTitle()}</ReadonlyLabel>
        </ReadonlyBlock>

        <ReportedExecutionDetails {...this.props} />

        <ReadonlyBlock>
          <ReportedStatus {...this.props} />
        </ReadonlyBlock>

        {showStatusReason && (
          <ReadonlyBlock>
            <ReportedStatusReason {...this.props} />
          </ReadonlyBlock>
        )}

        <ReadonlyBlock>
          <ReadonlyLabel primary>{Texts.getDetectedExecutionDetailsTitle()}</ReadonlyLabel>
        </ReadonlyBlock>

        <DetectedTime trackingData={values} rescheduleBehavior={rescheduleBehavior} />

        <DetectedServiceTime
          trackingData={parseTrackingData(values, workingDayStartSec)}
          rescheduleBehavior={rescheduleBehavior}
        />
      </ReadonlyBlock>
    )
  }
}
