import { PureComponent, ReactNode } from 'react'

import { FlexRow } from '@/forms-legacy'

import * as Texts from '../../intl'
import { Props, CustomField } from './typings'

import DeleteBtn from './components/DeleteBtn'
import UiCustomField from './components/CustomField'
import ErrorLabel from './components/ErrorLabel'

export default class CustomFields extends PureComponent<Props> {
  render() {
    const { customFields, onDelete, onChange, allowEmptyCustomFields } = this.props

    return (
      <div data-testid="custom-fields__list-container">
        {customFields.map(({ key, value }: CustomField): ReactNode => {
          const showErrorLabel = allowEmptyCustomFields ? false : value.length === 0

          return (
            <div key={key} style={{ width: '100%' }}>
              <FlexRow style={{ marginTop: 10 }}>
                <DeleteBtn style={{ marginRight: 10 }} onClick={() => onDelete(key)} />
                <UiCustomField label={key} value={value} onChange={onChange} />
              </FlexRow>
              {showErrorLabel && (
                <ErrorLabel style={{ marginLeft: 28 }} data-testid="custom-field__error-label">
                  {Texts.getNotValidValueText()}
                </ErrorLabel>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}
