import { useCallback } from 'react'
import { useSnapshot } from 'valtio'

import { mapAtom } from '../map'
import { showMapTrafficTiles } from '../utils/showMapTrafficTiles'

export const useShowMapTrafficTiles = () => {
  return [
    useSnapshot(mapAtom).showTraffic,

    useCallback((value: boolean) => {
      showMapTrafficTiles(value)
    }, []),

    useCallback(() => {
      showMapTrafficTiles(!mapAtom.showTraffic)
    }, []),
  ] as const
}
