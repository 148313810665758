import styled from 'styled-components'

import { GenericDropdown } from './GenericDropdown'

const styledComponent = styled(GenericDropdown)`
  .o-dropdown-header__input {
    background: ${p => p.theme.colors.$silver};
    border: none;
    border-radius: 6px;
  }

  .o-dropdown-header__input .o-input-field-text__extra-icon {
    width: 14px;
    margin-right: 10px;
    margin-left: 2px;
    color: ${p => p.theme.colors.$alabaster};
  }

  .o-input-field-text__suggested-text {
    /* FIXME: shitty hack to align the two texts*/
    transform: translateY(3px);
  }

  .o-dropdown-header__input .o-input-field-text__base-input::placeholder {
    color: ${p => p.theme.colors.$alabaster};
  }

  .o-dropdown-header__prev-btn .o-button,
  .o-dropdown-header__next-btn .o-button {
    padding: 6px 2px 6px 6px;
  }

  .o-dropdown-header__prev-btn .o-button {
    border-radius: 6px 0 0 6px;
  }

  .o-dropdown-header__next-btn .o-button {
    border-radius: 0 6px 6px 0;
  }

  .o-dropdown-wrapper-footer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 24px;
    padding: 0;
    padding-bottom: 4px;
    border: 0;
    border-radius: 0 0 8px 8px;
    background: ${p => p.theme.colors.$silver};
    color: ${p => p.theme.colors.$nightRider};
    font-size: ${p => p.theme.fonts.$s};
    font-weight: ${p => p.theme.weights.$regular};
    font-style: italic;
  }
`
export default styledComponent
