import { FlexBox } from '@/local/components'

import { useController } from '../../hooks/useController'

import { ErrorCard } from './components/ErrorCard'
import { InfoCard } from './components/InfoCard'

export function Body() {
  const ctrl = useController()

  return (
    <FlexBox padding="30px 0">{ctrl.data.issue === 'none' ? <InfoCard /> : <ErrorCard />}</FlexBox>
  )
}
