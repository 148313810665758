import { WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../../../useTexts'

export function ErrorCard() {
  const texts = useTexts()
  const ctrl = useController()

  if (ctrl.data.issue !== 'none') {
    return (
      <WarningCard
        preset="warning"
        Icon={<WarningTriangle size={14} />}
        title={texts.errorCardTitle}
        description={texts.errorCardText(ctrl.data.issue)}
      />
    )
  }

  return null
}
