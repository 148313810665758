import { useCallback, useEffect, useRef } from 'react'

import { ConfirmPanel as ConfirmPanelComp } from '@/forms-legacy'

import { deleteGeofences } from '@/features/domain/geofence'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useTexts } from '../hooks/useTexts'

interface Props {
  geofence: uui.domain.client.gps.wwgps.Geofence
  view: 'default' | 'delete'
  setView: (view: 'default' | 'delete') => void
}

export function ConfirmPanel(props: Props) {
  const { setView, geofence } = props
  const mounted = useRef(true)

  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const onCancel = useCallback(() => {
    setView('default')
  }, [setView])

  const onConfirm = useCallback(async () => {
    try {
      const result = await dispatch(deleteGeofences([geofence.id]))

      if (!deleteGeofences.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      if (mounted.current) {
        setView('default')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [setView, dispatch, toast, geofence])

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <ConfirmPanelComp
      title={texts.confirmPanelTitle}
      description={texts.confirmPanelSubtitle}
      confirmationText={texts.delete}
      onCancel={onCancel}
      onConfirm={onConfirm}
      testid="setup-geofence__single-delete-confirm-panel"
    />
  )
}
