import { Entry } from '../Entry'
import { JiraLinks } from './JiraLinks'

const ids = [
  10358, 10702, 10709, 10393, 10393, 10292, 10357, 10333, 10467, 10591, 10255, 10333, 10682, 10558,
  10356, 10616, 10353, 10630, 10369, 10674, 10314, 10316, 10673, 10614, 10429, 10537, 10321, 10277,
  10281, 10620, 10476, 10356, 10343, 10631, 10619, 10503, 10525, 10560, 10308, 10518, 10617, 10615,
  10613, 10270,
]

export function Entry20210420() {
  return (
    <Entry title="04/20/2021 - v3.0.0-beta-1 fixes">
      Full list of Jira issues fixed:
      <br />
      <br />
      <JiraLinks ids={ids} />
      <br />
      <br />
    </Entry>
  )
}
