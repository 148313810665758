import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    targetMapBody: translate({
      id: 'routingLayout.extractedMapWarningModal.header.body.map',
    }),
    targetSplitBody: translate({
      id: 'routingLayout.extractedMapWarningModal.header.body.split',
    }),
  }))

  return api
}
