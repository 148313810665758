import { useEffect, useRef } from 'react'

import { changeDeviceIdsForEvents as changeDeviceIdsForEventsAction } from '@/features/domain/ui'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useComputeSelectedDeviceIds } from './useComputeSelectedDeviceIds'

export function useUpdateDeviceIdForEvents() {
  const deviceIds = useComputeSelectedDeviceIds()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const changeDeviceIdForEvents = useRef(async (deviceIds: string[]) => {
    try {
      const result = await dispatch(changeDeviceIdsForEventsAction(deviceIds))

      if (!changeDeviceIdsForEventsAction.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e)
    }
  })

  useEffect(() => {
    const changeDeviceIds = changeDeviceIdForEvents.current
    changeDeviceIds(deviceIds)

    return () => {
      changeDeviceIds([])
    }
  }, [deviceIds])
}
