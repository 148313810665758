import { WarningCard } from '@local/components'

import { useTexts } from '../useTexts'

export function SuccessBody() {
  const texts = useTexts()

  return (
    <WarningCard
      description={texts.successDescription}
      title={texts.successTitle}
      preset="success"
    />
  )
}
