import { IconButton } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'

import { ListHeaderCounter, ListHeaderTitle, ListHeaderTitleContainer } from '@/components/List'

import { useGoBack } from '../../hooks/useGoBack'

import { useTitle } from './hooks/useTitle'

interface Props {
  title: string
}

export function Title(props: Props) {
  const { title } = props

  const { editing, selection, listStructure, onSelectAll, noEntries } = useTitle()
  const goBack = useGoBack()

  const showCounter = listStructure.shownItems > 0

  return (
    <ListHeaderTitleContainer>
      <IconButton size="small" onClick={goBack}>
        <ChevronLeft fontSize="inherit" />
      </IconButton>

      <ListHeaderTitle>{title}</ListHeaderTitle>

      {showCounter && (
        <ListHeaderCounter
          allSelected={selection.length >= listStructure.shownItems}
          disabled={editing}
          onClick={onSelectAll}
          readonly={noEntries}
        >
          {listStructure.shownItems}
        </ListHeaderCounter>
      )}
    </ListHeaderTitleContainer>
  )
}
