import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    passwordLengthValidation: translate({ id: 'login-next.resetPassword.validation.length' }),
    passwordNumberValidation: translate({ id: 'login-next.resetPassword.validation.number' }),
    passwordLowercaseValidation: translate({ id: 'login-next.resetPassword.validation.lowercase' }),
    passwordUppercaseValidation: translate({ id: 'login-next.resetPassword.validation.uppercase' }),
    passwordSpecialCharacterValidation: (specialCharacters: string) =>
      translate({
        id: 'login-next.resetPassword.validation.specialCharacter',
        values: { specialCharacters },
      }),
  }))

  return api
}
