import styled from 'styled-components'

export const ReadonlyTimeWindowExceptionsColumn = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > * + * {
    margin-top: 6px;
  }
`

ReadonlyTimeWindowExceptionsColumn.displayName = 'ReadonlyTimeWindowExceptionsColumn'
