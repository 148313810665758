import { useCallback } from 'react'

import { Box } from '@mui/material'
import {
  FlexRow,
  FlexColumn,
  TextButton,
  FormContent,
  ItemRendererTitle,
  ItemRendererLabel,
  ReadonlyFieldRowPart,
  SecondaryColumnContentContainer,
} from '@/forms-legacy'
import { intl } from '@/intl'
import { Plus } from '@/icons'
import { useVehicleFormException } from '@/atoms'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { getRecurrenceDescriptionMap, recurrenceToString } from '@/server-data'

import { ActiveBadge } from './components/ActiveBadge'
import { ExceptionRow } from './components/ExceptionRow'
import { SelectIndicator } from './components/SelectIndicator'
import { ExceptionsListHeader } from './components/ExceptionsListHeader'

import { useTexts } from './hooks/useTexts'
import { useRecurrences } from './hooks/useRecurrences'
import { useCurrentRecurrence } from './hooks/useCurrentRecurrence'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  switchToCreateNewExceptionView: () => void
  switchToDefaultView: () => void
}

export function ExceptionsList(props: Props) {
  const { unifiedVehicle, switchToCreateNewExceptionView, switchToDefaultView } = props

  const recurrencesDescriptionMap = getRecurrenceDescriptionMap(intl.translate)()

  const [settings, setException] = useVehicleFormException()
  const texts = useTexts()

  const onClick = useCallback(
    (recurrence: uui.domain.rm.Recurrence) => {
      if (settings.exception !== recurrence.source) {
        setException({ ...settings, exception: recurrence.source })
      }
      switchToDefaultView()
    },
    [switchToDefaultView, setException, settings],
  )

  const { recurrences, filter, setFilter } = useRecurrences(unifiedVehicle)
  const currentRecurrence = useCurrentRecurrence(unifiedVehicle, settings.exception)

  const currentRecurrenceText = !!currentRecurrence
    ? recurrenceToString(intl.translate)(currentRecurrence)
    : ''

  return (
    <>
      <ExceptionsListHeader
        filter={filter}
        setFilter={setFilter}
        recurrenceText={currentRecurrenceText}
        switchToDefaultView={switchToDefaultView}
      />
      <Box paddingLeft={2} paddingRight={2} width="100%">
        <ReadOnlyTooltip
          render={preventEditing => (
            <TextButton onClick={switchToCreateNewExceptionView} disabled={preventEditing}>
              <Plus size={8} />
              {texts.newException}
            </TextButton>
          )}
        />
      </Box>
      <FormContent footer={<></>}>
        <SecondaryColumnContentContainer marginLess>
          {recurrences.map(r => {
            const { source, type } = r
            const current = source === settings.exception

            return (
              <ExceptionRow key={r.source} type="button" onClick={() => onClick(r)}>
                <ReadonlyFieldRowPart primary>
                  <FlexColumn>
                    <FlexRow>
                      <ItemRendererTitle>{recurrenceToString(intl.translate)(r)}</ItemRendererTitle>
                      {current && (
                        <ActiveBadge style={{ marginLeft: 6 }}>{texts.current}</ActiveBadge>
                      )}
                    </FlexRow>
                    <ItemRendererLabel>{recurrencesDescriptionMap[type]}</ItemRendererLabel>
                  </FlexColumn>
                </ReadonlyFieldRowPart>
                <ReadonlyFieldRowPart>
                  <SelectIndicator>{texts.select}</SelectIndicator>
                </ReadonlyFieldRowPart>
              </ExceptionRow>
            )
          })}
        </SecondaryColumnContentContainer>
      </FormContent>
    </>
  )
}
