import { proxy, useSnapshot } from 'valtio'

import { parseRecurrence } from '@/server-data'

const initialValue = { value: null } as const

const recurrenceAtom = proxy<{ value: uui.domain.rm.Recurrence | null }>(initialValue)

export function setRecurrence(id?: string) {
  if (!id) return
  recurrenceAtom.value = parseRecurrence(id)
}

export function useRecurrence() {
  return useSnapshot(recurrenceAtom).value
}
