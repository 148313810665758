import { useCallback } from 'react'

import { logout as logoutAction } from '@/features/actions/logout'
import { resetAccountsData, useEnvironmentURLs } from '@/atoms'
import { useAppDispatch, useJournal } from '@/store'
import { useNotification } from '@/hooks'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export const useControllerActions = () => {
  const params = useEnvironmentURLs()
  const dispatch = useAppDispatch()
  const { close } = useController()
  const toast = useNotification()
  const journal = useJournal()
  const texts = useTexts()

  const logout = useCallback(async () => {
    try {
      close?.()
      const result = await dispatch(logoutAction(params))

      if (!logoutAction.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      // If the logout has been performed correctly, reset the atom
      resetAccountsData()
    } catch (e) {
      journal.remote('Logout failed', e, 'error')
      toast.error(texts.logoutError)
    }
  }, [dispatch, journal, close, toast, texts, params])

  return { logout }
}
