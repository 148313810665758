import type { FormConfig } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'

import { validateAddTenantSource } from '@/features/domain/gps'
import { type AppDispatch, store } from '@/store'

import { useTexts } from '../useTexts'

export function createVerizonConnectFormValidation(
  dispatch: AppDispatch,
  texts: ReturnType<typeof useTexts>,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'labelValidator',
      fields: 'label',
      validator: formApi => {
        const state = store.getState()

        const { tenantSources } = state.domain.publicData.domain.gps.telematics
        const { value } = formApi.getField('label')

        const labelRequiredError: FormErrors = {
          id: 'labelRequiredError',
          field: 'label',
          message: texts.verizonConnect.errors.labelRequired,
        }

        const empty = value.trim() === ''

        const labelUniqueError: FormErrors = {
          id: 'uniqueLabelError',
          field: 'label',
          message: texts.verizonConnect.errors.labelUnique,
        }

        const unique = Object.values(tenantSources).every(source => source.label !== value)

        return {
          [labelRequiredError.id]: empty ? labelRequiredError : null,
          [labelUniqueError.id]: !unique ? labelUniqueError : null,
        }
      },
    },
    {
      id: 'credentialsValidator',
      fields: 'credentials',
      validator: async formApi => {
        const { value: wrongCredentials } = formApi.getField('wrongCredentials')
        const { value: credentials } = formApi.getField('credentials')
        const { value: sourceId } = formApi.getField('sourceId')
        const { value: label } = formApi.getField('label')

        const { value } = formApi.getField('credentials')

        const usernameRequiredError: FormErrors = {
          id: 'usernameRequired',
          field: 'credentials',
          index: 0,
          message: texts.verizonConnect.errors.usernameRequired,
        }

        const passwordRequiredError: FormErrors = {
          id: 'passwordRequired',
          field: 'credentials',
          index: 1,
          message: texts.verizonConnect.errors.passwordRequired,
        }

        const username = value.find(v => v.fieldName === 'username')
        const password = value.find(v => v.fieldName === 'password')

        const usernameEmpty = username?.value.trim() === ''
        const passwordEmpty = password?.value.trim() === ''

        const wrongCredentialsUsernameError: FormErrors = {
          id: 'wrongCredentialsUsernameError',
          field: 'credentials',
          index: 0,
          message: texts.verizonConnect.errors.usernameIncorrect,
        }

        const wrongCredentialsPasswordError: FormErrors = {
          id: 'wrongCredentialsPasswordError',
          field: 'credentials',
          index: 1,
          message: texts.verizonConnect.errors.passwordIncorrect,
        }

        if (usernameEmpty || passwordEmpty || wrongCredentials) {
          return {
            [usernameRequiredError.id]: usernameEmpty ? usernameRequiredError : null,
            [passwordRequiredError.id]: passwordEmpty ? passwordRequiredError : null,
            [wrongCredentialsUsernameError.id]: wrongCredentials
              ? wrongCredentialsUsernameError
              : null,
            [wrongCredentialsPasswordError.id]: wrongCredentials
              ? wrongCredentialsPasswordError
              : null,
            credentialsAlreadyInUse: null,
          }
        }

        const response = await dispatch(
          validateAddTenantSource({
            credentials,
            sourceId,
            label,
          }),
        )

        if (validateAddTenantSource.rejected.match(response)) {
          const errorCode =
            response.payload?.type === 'rpcFailure' ? response.payload.error.errorCode : undefined

          if (errorCode === 101) {
            // An integration to this account already exist
            return {
              [usernameRequiredError.id]: null,
              [passwordRequiredError.id]: null,
              [wrongCredentialsUsernameError.id]: null,
              [wrongCredentialsPasswordError.id]: null,
              credentialsAlreadyInUse: {
                id: 'credentialsAlreadyInUse',
                field: 'credentials',
                index: 0,
                message: texts.verizonConnect.errors.credentialsAlreadyInUse,
              },
            }
          }

          throw new Error(response.payload?.message ?? 'Internal error')
        }

        return {
          [usernameRequiredError.id]: null,
          [passwordRequiredError.id]: null,
          [wrongCredentialsUsernameError.id]: null,
          [wrongCredentialsPasswordError.id]: null,
          credentialsAlreadyInUse: null,
        }
      },
    },
  ]
}
