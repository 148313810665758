import { InspectPinMode, Map as MainMap, MapLayer, ConnectMapToList, DrawArea } from '@/map'

export function Map() {
  return (
    <MainMap selectionContext="crud" selectionCategory="trafficArea" mapId="ww-map">
      <InspectPinMode mode="none" />

      <MapLayer uid="order" interactive={false} visible />
      <MapLayer uid="region" visible />
      <MapLayer uid="roadSegment" visible />
      <MapLayer uid="geofence" visible={false} />
      <MapLayer uid="trafficProfile" visible interactive />
      <MapLayer uid="routePolyline" visible />
      <MapLayer uid="place" visible />

      <ConnectMapToList category="trafficAreas" />

      <DrawArea />
    </MainMap>
  )
}
