import styled from 'styled-components'

export const DeviceIconButtonIcon = styled.div`
  display: block;
  width: 16px;
  color: currentColor;
  margin-right: 6px;
`

DeviceIconButtonIcon.displayName = 'DeviceIconButtonIcon'
