import { useCallback, useEffect, useState } from 'react'

export const useHandlers = (containerRef: React.RefObject<HTMLDivElement>, parentOpen: boolean) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false)

  const onMouseEnter = useCallback(() => setSubMenuOpen(true), [])
  const onClose = useCallback(() => setSubMenuOpen(false), [])

  const onFocus = useCallback(
    (event: React.FocusEvent<HTMLElement>) => {
      if (event.target === containerRef.current) {
        setSubMenuOpen(true)
      }
    },
    [containerRef],
  )

  useEffect(() => {
    if (!parentOpen) {
      setSubMenuOpen(false)
    }
  }, [parentOpen])

  return {
    onFocus,
    onClose,
    subMenuOpen,
    onMouseEnter,
  }
}
