import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton variant="contained" color="secondary" onClick={close}>
          {texts.ok}
        </ModalFooterButton>
      }
    />
  )
}
