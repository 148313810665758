import type { OrderChip } from '../../../components/Navigator/typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderSteps } from '@/features/domain/order'

import { useNavigator as useCoreNavigator } from '../../../components/Navigator/hooks/useNavigator'

export const useOrderStepsNavigator = () => {
  const {
    ids,
    pagination: { page, setPage },
  } = useCoreNavigator()

  const orderSteps = useSelector(selectOrderSteps)
  const [selectedIds, chips] = useMemo(() => {
    const selectedOrderSteps = ids.map(id => orderSteps[id]).filter(Boolean)

    const chips: OrderChip[] = selectedOrderSteps.map(order => ({
      id: order.id,
      source: order,
      type: 'orderStep',
    }))

    return [selectedOrderSteps, chips] as const
  }, [orderSteps, ids])

  const currentOrders = page === -1 || !selectedIds?.[page] ? selectedIds : [selectedIds[page]]

  const currentOrderIds = page === -1 || !ids?.[page] ? ids : [ids[page]]

  return { page, chips, currentOrders, currentOrderIds, setPage } as const
}
