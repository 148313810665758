import { useConvertDistance } from '@/hooks'

interface Props {
  meters: number
  decimals?: number
  printLabel?: boolean
}

export function ConvertDistance(props: Props) {
  const { meters, decimals, printLabel } = props
  const value = useConvertDistance(meters, decimals, printLabel)

  return <>{value}</>
}
