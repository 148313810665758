import { useCallback } from 'react'

import { SecondaryColumnHeader, FormBulkAvatar } from '@/forms-legacy'
import { resetCrudSelection } from '@/atoms'
import { FormColumn } from '@/components/layout'

import { useTexts } from './hooks/useTexts'

interface Props {
  devices: uui.domain.client.gps.wwgps.DeviceInfo[]
}

export function BulkView(props: Props) {
  const { devices } = props

  const onResetSelection = useCallback(() => {
    resetCrudSelection('devices')
  }, [])

  const texts = useTexts()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.headerTitle(devices.length)}
        description={texts.headerDescription}
        action={onResetSelection}
        avatar={<FormBulkAvatar size={devices.length} />}
      />
    </FormColumn>
  )
}
