import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (count: number) =>
      translate({ id: 'userManagement.viewUsers.title', values: { count } }),
    labels: {
      role: translate({ id: 'userManagement.viewUsers.labels.role' }),
    },
    mixed: translate({ id: 'userManagement.viewUsers.mixed' }),
    menu: {
      delete: translate({ id: 'userManagement.viewUsers.menu.delete' }),
    },
    tooltips: {
      close: translate({ id: 'userManagement.viewUsers.tooltips.close' }),
      delete: translate({ id: 'userManagement.viewUsers.tooltips.delete' }),
      more: translate({ id: 'userManagement.viewUsers.tooltips.more' }),
    },
  }))

  return api
}
