import type { FormValidator } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../../formFields'
import type { Texts } from '../useTexts'
import type { UsernameAvailable } from '../useIsUsernameAvailable'

export const createValidateUsername =
  (texts: Texts, isUsernameAvailable: UsernameAvailable): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const { value: username } = formApi.getField('username')

    const fieldChanged = username !== formApi.getInitialValues().username

    const usernameMinLength = 6
    const usernameMaxLength = 50

    const usernameRequiredError: FormErrors = {
      id: 'usernameRequiredError',
      message: texts.errors.required,
      field: 'username' as const,
      priority: 10,
    }

    const usernameUniqueError: FormErrors = {
      id: 'usernameUniqueError',
      message: texts.errors.unique,
      field: 'username' as const,
      priority: 15,
    }

    const usernameMinLengthError: FormErrors = {
      id: 'usernameMinLengthError',
      message: texts.errors.minLength(usernameMinLength),
      field: 'username' as const,
      priority: 15,
    }

    const usernameMaxLengthError: FormErrors = {
      id: 'usernameMaxLengthError',
      message: texts.errors.maxLength(usernameMaxLength),
      field: 'username' as const,
      priority: 15,
    }

    const requiredError = username.length === 0
    const minLengthError = username.length < usernameMinLength
    const maxLengthError = username.length > usernameMaxLength

    const canCallIsUsernameAvailable =
      !requiredError && !minLengthError && !maxLengthError && fieldChanged

    // username available status is only relevant if no other errors are present
    const [usernameStatus] = canCallIsUsernameAvailable
      ? await isUsernameAvailable(username)
      : ['available']

    return {
      [usernameRequiredError.id]: requiredError ? usernameRequiredError : null,
      [usernameMinLengthError.id]: minLengthError ? usernameMinLengthError : null,
      [usernameMaxLengthError.id]: maxLengthError ? usernameMaxLengthError : null,
      [usernameUniqueError.id]: usernameStatus !== 'available' ? usernameUniqueError : null,
    }
  }
