export function createEmptyDeviceData(metric: boolean): uui.domain.ui.forms.DeviceData {
  return {
    hasDriverBehaviorCapability: false,
    badges: {
      badges: [],
      originalTags: [],
    },
    braking: {
      hard: 100,
      harsh: 110,
      severe: 120,
      min: 100,
      max: 600,
    },
    acceleration: {
      hard: 100,
      harsh: 110,
      severe: 120,
      min: 100,
      max: 600,
    },
    cornering: {
      hard: 100,
      min: 100,
      max: 600,
    },
    speedLimit: null,
    idleStart: {
      custom: 5,
      min: 2,
      max: 720,
      enabled: false,
      preset: 'custom',
    },
    maxSpeedLimit: {
      min: metric ? 20 : 12,
      max: metric ? 320 : 200,
      threshold: metric ? 25 : 15,
    },
    maintenanceMileage: null,
  }
}
