import type { GeofenceFormValues } from '../types'
import { isDate } from 'date-fns/esm'
import { convertKmToMiles } from '@/local/server-data/utils/smartUtils/conversion'

import { neverEndingDateTime } from '../../../../../constants'

export const parseGeofenceFromFormValues = (
  formValues: GeofenceFormValues,
  metric: boolean,
): Omit<uui.domain.client.gps.wwgps.Geofence, 'id'> => {
  if (!formValues.location) {
    throw new Error('Cannot create a new geofence without a location')
  }

  const maxSpeed =
    typeof formValues.maxSpeed === 'number'
      ? metric
        ? Math.trunc(convertKmToMiles(formValues.maxSpeed))
        : formValues.maxSpeed
      : null

  return {
    name: formValues.name,
    type: formValues.type,
    userId: formValues.userId,
    radius: formValues.radius,
    repeat: formValues.repeat,
    location: formValues.location,
    timezone: formValues.timezone,
    deviceIds: formValues.deviceIds,
    latLng: formValues.location.latLng,
    maxSpeed: maxSpeed || null,
    latitude: formValues.location.latLng.lat,
    longitude: formValues.location.latLng.lng,
    organizationId: formValues.organizationId,
    $ISOEnd: formValues.startDateTime.toISOString(),
    $ISOStart: formValues.startDateTime.toISOString(),
    startTime: formValues.startDateTime.toISOString(),
    notificationAddresses: formValues.notificationAddresses.map(rcpt => rcpt.address),
    endTime:
      formValues.endDateTime && isDate(formValues.endDateTime)
        ? formValues.endDateTime.toISOString()
        : neverEndingDateTime,
  }
}
