import type { SelectionOptions } from '../../utils/applySelection'
import type { CrudSelection } from '../typings'

import { snapshot } from 'valtio'

import { parseModifier, applySelection } from '../../utils/applySelection'
import { crudAtom } from '../crud'
import { resetAllCrudSelection } from './resetAllCrudSelection'

type SetAllCrudSelection = (prev: workwave.DeepReadonly<CrudSelection>) => Partial<CrudSelection>

type SetAllCrudSelectionParam = SetAllCrudSelection | Partial<CrudSelection> | 'reset'

export const setAllCrudSelection = (
  valueOrFunc: SetAllCrudSelectionParam,
  options?: SelectionOptions,
) => {
  // reset
  if (valueOrFunc === 'reset') return resetAllCrudSelection()

  const { force, modifiers } = options ?? {}
  const { noModifiers, additive } = parseModifier(modifiers)

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    const nextSelection = valueOrFunc(snapshot(crudAtom.selection))

    for (const field of Object.keys(crudAtom.selection)) {
      if (force || noModifiers) {
        // overwrite the selection if provided or reset it
        crudAtom.selection[field] = nextSelection[field] ?? []
      } else if (additive) {
        // for additive bulk selection we don't want the toggle behavior offered by `applySelection`
        // do nothing if the new provided selection is empty
        if (nextSelection[field]) {
          crudAtom.selection[field] = Array.from(
            new Set([...crudAtom.selection[field], ...nextSelection[field]]),
          )
        }
      } else {
        // subtract the selection
        const prevSelection: string[] = crudAtom.selection[field]
        crudAtom.selection[field] = applySelection(prevSelection, nextSelection[field], options)
      }
    }
  } else {
    // atomic update
    for (const field of Object.keys(crudAtom.selection)) {
      if (force || noModifiers) {
        // overwrite the selection, Set it empty if no data has been provided
        crudAtom.selection[field] = valueOrFunc[field] ?? []
      } else if (additive) {
        // for additive bulk selection we don't want the toggle behavior offered by `applySelection`
        // do nothing if the new provided selection is empty
        if (valueOrFunc[field]) {
          crudAtom.selection[field] = Array.from(
            new Set([...crudAtom.selection[field], ...valueOrFunc[field]]),
          )
        }
      } else {
        // if the field has not been provided and modifiers are on do nothing on that category
        if (!valueOrFunc[field]) continue

        // subtract the selection
        const prevSelection: string[] = crudAtom.selection[field]
        crudAtom.selection[field] = applySelection(prevSelection, valueOrFunc[field], options)
      }
    }
  }
}
