import { type MouseEventHandler } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { Tooltip } from '../../../../../components/primitives/Tooltip'
import { ListItemIcon } from '../ListItemIcon'
import { useTexts } from './hooks/useTexts'

export type Props = {
  visible: boolean
  toggleVisibility: MouseEventHandler<HTMLElement>
}

export function ListItemVisibilityToggler(props: Props) {
  const { visible, toggleVisibility } = props
  const texts = useTexts()

  return (
    <Tooltip placement="bottom" title={texts.visibilityTooltip(visible)} disableAutoFocus>
      <ListItemIcon
        icon={visible ? <Visibility /> : <VisibilityOff />}
        onClick={toggleVisibility}
      />
    </Tooltip>
  )
}
