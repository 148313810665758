import { FilteredGuestSubMenu } from './components/FilteredGuestSubMenu'
import { GpsOnlySubMenu } from './components/GpsOnlySubMenu'
import { Rm360SubMenu } from './components/Rm360SubMenu'
import { RmSubMenu } from './components/RmSubMenu'

import { useMenuConfiguration } from './hooks/useMenuConfiguration'

interface Props {
  menuOpen: boolean
}

export function Menu(props: Props) {
  const { menuOpen } = props

  const configuration = useMenuConfiguration()

  switch (configuration) {
    case 'rm':
    case 'simulation':
      return <RmSubMenu menuOpen={menuOpen} />
    case 'rm360':
      return <Rm360SubMenu menuOpen={menuOpen} />
    case 'gpsOnly':
      return <GpsOnlySubMenu menuOpen={menuOpen} />
    case 'filteredGuest':
      return <FilteredGuestSubMenu menuOpen={menuOpen} />
  }
}
