import { useMemo } from 'react'

import { DeviceIconListField } from '@/forms-legacy'

import { getDeviceIcons } from '../utils/getDeviceIcons'
import { useTexts } from '../hooks/useTexts'

const icons = getDeviceIcons()

export function Icon() {
  const texts = useTexts()

  const wrappedInputProps = useMemo(
    () => ({
      closedText: texts.globalChange,
      openText: texts.globalCancel,
      description: texts.iconInfo,
      icons,
    }),
    [texts],
  )

  return (
    <DeviceIconListField
      name="gps.icon.icon"
      testid="deviceIcon"
      label={texts.icon}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
