import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'

import { findBreadcrumbMarker } from './findBreadcrumbMarker'

import { createBreadcrumbPathFeature } from './breadcrumbPath/createBreadcrumbPathFeature'
import { createBreadcrumbStopsFeatures } from './breadcrumbStops/createBreadcrumbStopsFeatures'
import { createBreadcrumbPointsFeatures } from './breadcrumbPoints/createBreadcrumbPointsFeatures'

type BreadcrumbLayers = {
  breadcrumbPathLayer: VectorLayer<VectorSource>
  breadcrumbPointsLayer: VectorLayer<VectorSource>
}

const nonblockingRender = createNonBlockingRenderByTime()

export async function changelogAddBreadcrumbFeatures(
  layers: BreadcrumbLayers,
  markers: Record<string, uui.domain.ui.map.markers.Breadcrumb>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
  selection: Set<string>,
  markerIds: IterableIterator<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  const pathFeatures: Feature<Geometry>[] = []
  const stopsFeatures: Feature<Geometry>[] = []
  const pointsFeatures: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findBreadcrumbMarker(markers, markerId)
    if (!marker) continue

    // Path features
    pathFeatures.push(createBreadcrumbPathFeature(marker, mapStyles))

    // Points features
    pointsFeatures.push(
      ...createBreadcrumbPointsFeatures(marker, mapStyles, selection, breadcrumbTimeRange),
    )
    // Stops features
    stopsFeatures.push(
      ...createBreadcrumbStopsFeatures(marker, mapStyles, selection, breadcrumbTimeRange),
    )
  }

  // add all new features to the layers
  const source = layers.breadcrumbPathLayer.getSource()

  if (source) {
    const pathLayerSource = layers.breadcrumbPathLayer.getSource()
    const pointsLayerSource = layers.breadcrumbPointsLayer.getSource()
    pathLayerSource?.addFeatures(pathFeatures)
    pathLayerSource?.addFeatures(stopsFeatures)

    pointsLayerSource?.addFeatures(pointsFeatures)
    pointsLayerSource?.addFeatures(stopsFeatures)
  }

  // update the stored collection
  setLayerMetadata(layers.breadcrumbPathLayer, 'selection', selection)
  setLayerMetadata(layers.breadcrumbPointsLayer, 'selection', selection)
}
