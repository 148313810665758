import type { ActionPermissions } from '../../hooks/useDriversNavigoMachine/types'

import { useDriversNavigator } from '../../hooks/useDriversNavigator'
import { DriverInfo } from './components/DriverInfo'
import { Details } from './components/Details'
import { Actions } from './components/Actions'

interface Props {
  expanded: boolean
  toggleExpanded: () => void
  permissions: ActionPermissions
}

export function Overview(props: Props) {
  const { expanded, permissions, toggleExpanded } = props

  const { currentDrivers } = useDriversNavigator()

  return (
    <>
      <Details drivers={currentDrivers} />

      <DriverInfo drivers={currentDrivers} />

      <Actions
        toggleExpanded={toggleExpanded}
        permissions={permissions}
        drivers={currentDrivers}
        expanded={expanded}
      />
    </>
  )
}
