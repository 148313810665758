import type Geometry from 'ol/geom/Geometry'

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { isVisibleOrder } from '@/server-data'

import { setOrderFeatureMetadata } from '../orderFeatureMetadata'
import { getOrderFeatureStyle } from './getOrderFeatureStyle'

export const createOrderFeature = (
  marker: uui.domain.ui.map.markers.Order,
  mapStyles: uui.domain.ui.map.markers.MapStyles['order'],
  selectedOrderIds: Set<string>,
  selected: boolean = false,
): Feature<Geometry> => {
  const { lonLat, id, ids } = marker

  const geometry = new Point(lonLat)
  const feature = new Feature({ geometry })

  // use marker ID (lat-lng) as unique ID
  feature.setId(id)

  const visibleIds = Object.keys(ids).reduce<Record<string, true>>((acc, id) => {
    if (isVisibleOrder(ids[id], mapStyles)) {
      acc[id] = true
    }

    return acc
  }, {})

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setOrderFeatureMetadata(feature, 'type', 'order')

  // store all linked Orders ID
  setOrderFeatureMetadata(feature, 'ids', visibleIds)

  // store all linked Orders ID
  setOrderFeatureMetadata(feature, 'selected', selected)

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['order']['mode'] = marker.mode ?? mapStyles.mode
  setOrderFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  const style = getOrderFeatureStyle(marker, mode, selectedOrderIds, selected)
  feature.setStyle(style)

  return feature
}
