import { subscribeCrudSelection } from '@/atoms'

import { updateDepotsMapSelection } from '../../layers/depot/updateDepotsMapSelection'
import { updatePlacesMapSelection } from '../../layers/place/updatePlacesMapSelection'
import { updateDevicesMapSelection } from '../../layers/device/updateDevicesMapSelection'
import { updateRegionsMapSelection } from '../../layers/region/updateRegionsMapSelection'
import { updateVehiclesMapSelection } from '../../layers/vehicle/updateVehiclesMapSelection'
import { updateGeofenceMapSelection } from '../../layers/geofence/updateGeofenceMapSelection'
import { updateDeviceEventsMapSelection } from '../../layers/deviceEvent/updateDeviceEventsMapSelection'
import { updateRoadSegmentsMapSelection } from '../../layers/roadSegment/updateRoadSegmentsMapSelection'
import { updateTrafficAreasMapSelection } from '../../layers/trafficArea/updateTrafficAreasMapSelection'
import { findLayer } from '../../layers/utils/findLayer'

import { getDomainMarkerData } from '../markerChangelog/domain/getDomainMarkerData'
import { getMap } from '../map/utils/getMap'

export function subscribeToCrudMapSelection() {
  const unsubscribeDepotsSelection = subscribeCrudSelection('depots', nextSelection => {
    const { mapMarkers, mapStyles } = getDomainMarkerData()
    const map = getMap()

    updateDepotsMapSelection(
      findLayer(map, 'depot'),
      mapMarkers.depot,
      mapStyles.depot,

      // selection,
      new Set(nextSelection),
    )
  })

  const unsubscribeRegionsSelection = subscribeCrudSelection('regions', nextSelection => {
    const { mapMarkers, mapStyles } = getDomainMarkerData()
    const map = getMap()

    updateRegionsMapSelection(
      findLayer(map, 'region'),
      mapMarkers.region,
      mapStyles.region,

      // selection,
      new Set(nextSelection),
    )
  })

  const unsubscribeRoadSegmentsSelection = subscribeCrudSelection(
    'routingSegments',
    nextSelection => {
      const { mapMarkers, mapStyles } = getDomainMarkerData()
      const map = getMap()

      updateRoadSegmentsMapSelection(
        findLayer(map, 'roadSegment'),
        mapMarkers.roadSegment,
        mapStyles.roadSegment,

        // selection,
        new Set(nextSelection),
      )
    },
  )

  const unsubscribeGeofencesSelection = subscribeCrudSelection('geofences', nextSelection => {
    const { mapMarkers, mapStyles } = getDomainMarkerData()
    const map = getMap()

    updateGeofenceMapSelection(
      findLayer(map, 'geofence'),
      mapMarkers.geofence,
      mapStyles.geofence,
      // selection,
      new Set(nextSelection),
    )
  })

  const unsubscribeTrafficAreasSelection = subscribeCrudSelection('trafficArea', nextSelection => {
    const { mapMarkers, mapStyles } = getDomainMarkerData()
    const map = getMap()

    updateTrafficAreasMapSelection(
      findLayer(map, 'trafficProfile'),
      mapMarkers.trafficProfile,
      mapStyles.trafficProfile,

      // selection,
      new Set(nextSelection),
    )
  })

  const unsubscribePlacesSelection = subscribeCrudSelection('places', nextSelection => {
    const { mapMarkers, mapStyles } = getDomainMarkerData()
    const map = getMap()

    updatePlacesMapSelection(
      findLayer(map, 'place'),
      mapMarkers.place,
      mapStyles.place,

      // selection,
      new Set(nextSelection),
    )
  })

  const unsubscribeDevicesSelection = subscribeCrudSelection('devices', nextSelection => {
    const { mapMarkers, mapStyles, licensingLimits } = getDomainMarkerData()
    const map = getMap()

    const gpsOnly = !!licensingLimits.gpsOnly
    const gpsEnabled = !!licensingLimits.allowGps

    // If not gps user let's bail out
    if (!gpsOnly && !gpsEnabled) return

    updateDevicesMapSelection(
      findLayer(map, 'device'),
      mapMarkers.device,
      mapStyles.device,

      // selection,
      new Set(nextSelection),
    )
  })

  const unsubscribeVehiclesSelection = subscribeCrudSelection('unifiedVehicles', nextSelection => {
    const { mapMarkers, mapStyles, licensingLimits } = getDomainMarkerData()
    const map = getMap()

    const gpsOnly = !!licensingLimits.gpsOnly
    const gpsEnabled = !!licensingLimits.allowGps

    // If not gps user let's bail out
    if (!gpsOnly && !gpsEnabled) return

    updateVehiclesMapSelection(
      findLayer(map, 'vehicle'),
      mapMarkers.vehicle,
      mapStyles.vehicle,

      // selection,
      new Set(nextSelection),
    )
  })

  const unsubscribeDeviceEventsSelection = subscribeCrudSelection('deviceEvents', nextSelection => {
    const { mapMarkers, licensingLimits, breadcrumbTimeRange } = getDomainMarkerData()
    const map = getMap()

    const gpsOnly = !!licensingLimits.gpsOnly
    const gpsEnabled = !!licensingLimits.allowGps

    // If not gps user let's bail out
    if (!gpsOnly && !gpsEnabled) return

    updateDeviceEventsMapSelection(
      findLayer(map, 'deviceEvent'),
      mapMarkers.deviceEvent,

      // selection,
      new Set(nextSelection),
      breadcrumbTimeRange,
    )
  })

  return () => {
    unsubscribeDepotsSelection()
    unsubscribeRegionsSelection()
    unsubscribeGeofencesSelection()
    unsubscribeRoadSegmentsSelection()
    unsubscribeTrafficAreasSelection()
    unsubscribePlacesSelection()
    unsubscribeDevicesSelection()
    unsubscribeVehiclesSelection()
    unsubscribeDeviceEventsSelection()
  }
}
