import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    newPassword: translate({
      id: 'settings.users.view.changePasswordModal.fields.newPassword.label',
    }),
  }))

  return api
}
