import type { AggregateLoad } from '../types'

import { Tooltip } from '@/components/primitives/Tooltip'
import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  aggregateLoad: AggregateLoad
}

const loadTestId = 'orders-view-dynamic-loads-load'
const loadLabelTestId = 'orders-view-dynamic-loads-load-label'
const loadValueTestId = 'orders-view-dynamic-loads-load-value'
const loadDeltaTestId = 'orders-view-dynamic-loads-load-delta'

export function AggregateLoad(props: Props) {
  const { aggregateLoad } = props
  const { label } = aggregateLoad
  const texts = useTexts()

  if (aggregateLoad.value === 'undeclared') {
    return (
      <ReadonlyBlock data-testid={loadTestId}>
        <ReadonlyLabel data-testid={loadLabelTestId}>{label}</ReadonlyLabel>

        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel empty data-testid={loadValueTestId}>
              {texts.undeclared}
            </ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyBlock>
    )
  }

  const { trackedValue: value, delta } = aggregateLoad
  const tooltipText = delta < 0 ? texts.loads.tooltipLess : texts.loads.tooltipMore

  return (
    <ReadonlyBlock data-testid={loadTestId}>
      <ReadonlyLabel data-testid={loadLabelTestId}>{label}</ReadonlyLabel>

      <ReadonlyField>
        <ReadonlyFieldRow style={{ justifyContent: 'space-between' }}>
          <ReadonlyFieldLabel data-testid={loadValueTestId}>{value}</ReadonlyFieldLabel>
          {delta !== 0 && (
            <Tooltip
              placement="bottom"
              title={tooltipText(`${Math.abs(delta)} ${aggregateLoad.label}`)}
            >
              <ReadonlyFieldLabel light data-testid={loadDeltaTestId}>
                {delta > 0 ? `+${delta}` : delta}
              </ReadonlyFieldLabel>
            </Tooltip>
          )}
        </ReadonlyFieldRow>
      </ReadonlyField>
    </ReadonlyBlock>
  )
}
