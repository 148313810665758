import type { ReactChild } from 'react'

import { Stack, Typography, Box } from '@mui/material'

interface Props {
  label: ReactChild
  action1?: ReactChild
  action2?: ReactChild
  action3?: ReactChild
}

export function FieldHeader(props: Props) {
  const { label, action1 = null, action2 = null, action3 = null } = props

  return (
    <Box paddingTop={2} paddingBottom={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography noWrap variant="body1">
          {label}
        </Typography>
        <Stack direction="row">
          {action1}
          {action2}
          {action3}
        </Stack>
      </Stack>
    </Box>
  )
}
