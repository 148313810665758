import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Lock } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  multiple: boolean
  disabled: boolean
}

export function LockButton(props: Props) {
  const { multiple, disabled, onClick } = props
  const texts = useTexts()

  return (
    <ReadOnlyTooltip
      canEdit={!disabled}
      title={texts.lockDisabled}
      render={preventEditing => (
        <NavigoIconButton
          onClick={onClick}
          disabled={preventEditing || disabled}
          Icon={<Lock size={14} color={preventEditing || disabled ? '$silver' : '$nightRider'} />}
          text={multiple ? texts.lockAll : texts.lock}
          testid="navigo-routes-overview-actions-lock"
        />
      )}
    />
  )
}
