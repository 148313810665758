import { useCallback } from 'react'

import { useNotification, useIsUnmounted } from '@/hooks'
import { useAppDispatch } from '@/store'
import { moveOrderStep } from '@/features/domain/order'

import { useController } from './useController'

export const useControllerActions = () => {
  const { close, data } = useController()
  const toast = useNotification()

  const dispatch = useAppDispatch()
  const isUnmounted = useIsUnmounted()

  const move = useCallback(
    async (moveMate: boolean) => {
      try {
        if (data.issue !== 'none') return

        const thunkResult = await dispatch(
          moveOrderStep({
            orderStepId: data.payload.orderStepId,
            routeId: data.payload.targetRouteId,
            seconds: data.payload.seconds,
            sourceRouteId: data.payload.sourceRouteId,
            moveMate,
          }),
        )

        if (!moveOrderStep.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        if (isUnmounted()) return
        close?.()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [close, data, toast, dispatch, isUnmounted],
  )

  return {
    move,
  }
}
