import { makeStyles } from '@mui/styles'

import { HorizontalLayout, VerticalLayout } from '@/components/layout'
import { Text } from '@/local/components'
import { Pin } from '@/icons'

import { useTexts } from '../useTexts'

const useStyles = makeStyles({
  container: {
    maxWidth: 260,
  },
})

export function Info() {
  const classes = useStyles()
  const texts = useTexts()

  return (
    <HorizontalLayout alignItems="center" className={classes.container}>
      <VerticalLayout
        width="50"
        justifyContent="center"
        alignItems="center"
        marginLeft={20}
        marginRight={30}
      >
        <Pin size={20} color="$nightRider" />

        <Text size="$s" color="$nightRider" textAlign="center">
          {texts.inspect}
        </Text>
      </VerticalLayout>

      <VerticalLayout justifyContent="center">
        <Text size="$m" color="$nightRider" weight="$semiBold" textAlign="left">
          {texts.mapInspectorTool}
        </Text>

        <Text size="$m" color="$nightRider" textAlign="left">
          {texts.inspectPointOnMap}
        </Text>
      </VerticalLayout>
    </HorizontalLayout>
  )
}
