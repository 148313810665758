import { Stack } from '@mui/material'

import { RouteViolationRow } from './RouteViolationRow'

interface Props {
  violations: uui.domain.rm.RouteViolations
}

export function RouteViolationsTooltipContent(props: Props) {
  const violations = props.violations.vehicle ?? []

  return (
    <Stack paddingY={0.5} spacing={1}>
      {violations.map(type => (
        <RouteViolationRow key={type} type={type} />
      ))}
    </Stack>
  )
}
