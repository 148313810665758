import { useState } from 'react'

import { useConfigureModalController, useModalController, useCloseModal } from '@/atoms'

type ModalState = 'ready' | 'submitting'
export type ModalData = {
  center: uui.domain.LatLng
  newLatLng: uui.domain.LatLng
  originalLatLng: uui.domain.LatLng

  proposedLabel?: string
}

export const useControllerById = (id: string) => useModalController<ModalData, ModalState>(id)

export function useConfigureController(center: uui.domain.LatLng, modalId: string) {
  const close = useCloseModal()

  // INITIALIZATION
  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: { center, originalLatLng: { ...center }, newLatLng: { ...center } },
    }),
  }))

  return useConfigureModalController<ModalData, ModalState>(modalId, options)
}
