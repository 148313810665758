export const deleteBarcode = (barcodes: string[], index: number, count: number = 1): string[] => {
  const tmp = [...barcodes]
  tmp.splice(index, count)
  return tmp
}

export const updateBarcodes = (barcodes: string[], item: string, itemIndex: number): string[] =>
  barcodes.map((value, index) => (index === itemIndex ? item : value))

export const addBarcode = (barcodes: string[], b: string = '') => [...barcodes, b]
