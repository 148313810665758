import styled from 'styled-components'

const ColumnFooter = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  background: ${p => p.theme.colors.$whiteSmoke};
  padding: 4px 16px;

  .o-column-footer__right-btn,
  .o-column-footer__left-btn {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  .o-column-footer__right-btn {
    left: auto;
    right: 16px;
  }
`

ColumnFooter.displayName = 'ColumnFooter'
export default ColumnFooter
