import { makeStyles } from '@mui/styles'
import { theme } from '@/styles'

export const useStyles = makeStyles({
  button: {
    height: '26px !important',
    padding: '6px !important',
    margin: '0 11px !important',
    '&:hover': { background: `${theme.colors.$whiteSmoke} !important` },
  },
})
