import { FieldMode } from '../../../components/ViewDetails'

export function computeBulkTimeWindows(timeWindows: uui.domain.rm.TimeWindow[][]) {
  return timeWindows.reduce(
    (
      acc: uui.domain.rm.TimeWindow[] | FieldMode.mixed,
      tws: uui.domain.rm.TimeWindow[],
      idx: number,
    ) => {
      if (idx === 0) {
        return [...tws]
      }

      if (acc === FieldMode.mixed) {
        return acc
      }

      if (acc.length !== tws.length) {
        return FieldMode.mixed
      }

      const [source1, source2] = acc
      const [tw1, tw2] = tws

      if (
        (source1 && !tw1) ||
        (!source1 && tw1) ||
        (source1 && tw1 && (tw1.endSec !== source1.endSec || tw1.startSec !== source1.startSec)) ||
        (source2 && !tw2) ||
        (!source2 && tw2) ||
        (source2 && tw2 && (tw2.endSec !== source2.endSec || tw2.startSec !== source2.startSec))
      ) {
        return FieldMode.mixed
      }

      return acc
    },
    [],
  )
}
