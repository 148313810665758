import type { ReactNode } from 'react'

import { ItemContainer } from './ItemContainer'

type Props = {
  className?: string
  warn?: boolean
  children?: ReactNode
}

export function DropdownTextItem(props: Props) {
  const { className, children } = props

  return <ItemContainer className={className}>{children}</ItemContainer>
}
