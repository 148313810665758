import type { VehicleForCopySettings } from '../typings'
import type { SearchableListTypes } from '@/forms-legacy'

import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: (recurrence: string) =>
      translate({
        id: 'vehicles.exception.copySettings.headerTitle',
        values: { recurrence },
      }),
    headerSubtitle: translate({
      id: 'vehicles.exception.copySettings.headerSubtitle',
    }),
    listTitle: translate({ id: 'vehicles.exception.copySettings.vehiclesList.selectVehicles' }),
    copySettings: (
      key: SearchableListTypes.SearchableListText,
      props: SearchableListTypes.Props<VehicleForCopySettings>,
      count: number = 0,
    ): string => {
      const { values } = props
      const total = values.length || 0

      switch (key) {
        case 'filterPlaceholder':
          return translate({
            id: 'vehicles.exception.copySettings.vehiclesList.filterPlaceholder',
          })
        case 'checkAll':
          return translate({
            id: 'vehicles.exception.copySettings.vehiclesList.checkAll',
            values: {
              count: total,
            },
          })

        case 'unCheckAll':
          return translate({
            id: 'vehicles.exception.copySettings.vehiclesList.uncheckAll',
            values: {
              count: total,
            },
          })

        case 'footerDescription':
          return translate({
            id: 'vehicles.exception.copySettings.vehiclesList.footerDescription',
            values: {
              total,
              checked: count,
            },
          })

        default:
          return key
      }
    },
    discard: translate({ id: 'vehicles.exception.copySettings.actions.discard' }),
    confirm: (count: number) =>
      translate({
        id: 'vehicles.exception.copySettings.actions.create',
        values: { count },
      }),
  }))

  return api
}
