import type { WeeklySelectionAsRecord, WeeklyValidSelection } from '../../typings'

import { useMemo } from 'react'

export const useSelectionData = (selection?: WeeklyValidSelection) => {
  return useMemo<WeeklySelectionAsRecord | undefined>(() => {
    if (typeof selection === 'number') {
      const result = {} as WeeklySelectionAsRecord
      result[selection] = 1
      return result
    }
    return undefined
  }, [selection])
}
