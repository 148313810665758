type Props = {
  children: React.ReactNode
  disabled?: boolean
}

export function UnstyledButton(props: Props) {
  const { children, disabled = false } = props

  return (
    <button disabled={disabled} className="o-scheduler-unstyled-button">
      {children}
    </button>
  )
}
