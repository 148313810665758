import { CSSProperties, PureComponent } from 'react'

import { ReadonlyLabel, FormLayout } from '@/forms-legacy'

import * as OrderTexts from '../../../intl'
import Order from './components/Order'
import OrderStep from './components/OrderStep'

import { FormProps as Props, OrderFormValues } from './typings'

export default class Form extends PureComponent<Props> {
  render() {
    const {
      formRenderProps: { form },
      maxTagsCount,
    } = this.props

    const { initialValues, values } = form.getState()
    const {
      type,
      pickup,
      delivery,
      orderStep: { type: orderStepType },
    } = values as OrderFormValues
    const { type: initialType } = initialValues as OrderFormValues

    const pickupAndDeliveryFromStart = initialType === 'pd'

    const showPickupAndDelivery = type === 'pd'

    const showPickup = type === 'p'

    const showDelivery = type === 'd' || type === 's'

    const showOrderStepPickup =
      showPickup ||
      (pickupAndDeliveryFromStart && orderStepType === 'p') ||
      (!pickupAndDeliveryFromStart && showPickupAndDelivery)

    const showOrderStepDelivery =
      showDelivery ||
      (pickupAndDeliveryFromStart && orderStepType === 'd') ||
      (!pickupAndDeliveryFromStart && showPickupAndDelivery)

    const pickupLabelTitleStyles: CSSProperties = {
      marginBottom: 20,
      marginTop: 10,
      paddingLeft: 0,
    }

    const deliveryLabelTitleStyles: CSSProperties = {
      marginBottom: 20,
      paddingLeft: 0,
    }

    return (
      <FormLayout>
        <Order {...this.props} type={type} />

        {showOrderStepPickup && (
          <>
            <ReadonlyLabel primary style={pickupLabelTitleStyles}>
              {OrderTexts.getPickupSettingsTitle()}
            </ReadonlyLabel>
            <OrderStep
              {...this.props}
              orderStepType="pickup"
              orderStep={pickup!}
              maxTagsCount={maxTagsCount}
              showLastRuler={showPickupAndDelivery}
            />
          </>
        )}

        {showOrderStepDelivery && (
          <>
            <ReadonlyLabel primary style={deliveryLabelTitleStyles}>
              {OrderTexts.getDeliverySettingsTitle()}
            </ReadonlyLabel>
            <OrderStep
              {...this.props}
              orderStepType="delivery"
              maxTagsCount={maxTagsCount}
              orderStep={delivery!}
            />
          </>
        )}
      </FormLayout>
    )
  }
}
