import { useCallback, useState, useEffect } from 'react'

import { resetCrudSelection } from '@/atoms'

import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { usePendingFitMapOnMount } from '@/map'

import { useRecurrence } from '../../hooks/useRecurrence'

import { ViewDetails } from '../../components/ViewDetails'
import { ActionsManager } from './components/ActionsManager'

import { useDriverAssignmentInfo } from './hooks/useDriverAssignmentInfo'

interface Props {
  driverAssignment: uui.domain.client.rm.DriverAssignmentsWithId
}

export function SingleView(props: Props) {
  const {
    driverAssignment: { assignments },
  } = props

  const recurrence = useRecurrence()

  const [view, setView] = useState<'default' | 'delete'>(() => 'default')
  const { description, label } = useDriverAssignmentInfo(recurrence)
  usePendingFitMapOnMount()

  const onReset = useCallback(() => {
    resetCrudSelection('driverAssignments')
  }, [])

  // reset to default view if the recurrence changes
  useEffect(() => {
    setView('default')
  }, [recurrence])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={label}
        description={description}
        action={onReset}
        avatar={null}
      />
      <ActionsManager view={view} setView={setView} />
      <FormContent footer={<></>}>
        <ViewDetails assignments={assignments} />
      </FormContent>
    </FormColumn>
  )
}
