import type { CSSProperties } from 'react'

import { useMemo } from 'react'
import { makeStyles } from '@mui/styles'
import { theme } from '@local/components'

export const useClasses = makeStyles(muiTheme => ({
  button: {
    marginBottom: muiTheme.spacing(2),
    opacity: 'var(--opacity)',
    textAlign: 'left',
    width: '100%',
  },

  container: {
    backgroundColor: 'var(--background-color)',
    color: 'var(--text-color)',
    padding: muiTheme.spacing(2),
    borderRadius: 4,
    width: '100%',
  },

  title: {
    fontWeight: theme.weights.$semiBold,
    fontSize: theme.fonts.$xl,
    marginBottom: 2,
  },

  subtitle: {
    color: 'var(--text-color)',
    fontSize: theme.fonts.$s,
    lineHeight: 1.25,
  },

  iconContainer: {
    color: 'var(--icon-color)',
  },
}))

export function useStyles(active: boolean, disabled: boolean) {
  const classes = useClasses()

  const styles = useMemo(() => {
    return {
      button: {
        '--opacity': disabled ? 0.5 : 1,
      } as CSSProperties,
      container: {
        '--background-color': active ? theme.colors.$scienceBlue : theme.colors.$alabaster,
        '--text-color': active ? theme.colors.$pureWhite : theme.colors.$nightRider,
      } as CSSProperties,
      subtitle: {
        '--text-color': active ? theme.colors.$pureWhite : theme.colors.$shadyLady,
      } as CSSProperties,
      icon: {
        '--icon-color': active ? theme.colors.$pureWhite : theme.colors.$pigmentGreen,
      } as CSSProperties,
    } as const
  }, [active, disabled])

  return [classes, styles] as const
}
