import { useMemo } from 'react'
import { colorPalette } from '@/server-data'

import { getDeviceIcons } from '../../utils/getDeviceIcons'

const defaultDeviceIconId = 103

export function useGpsVehicleData(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
): uui.domain.ui.forms.GpsVehicleData {
  if (!unifiedVehicle.isPhysicalDevice) {
    throw new Error('device not found in vehicle')
  }

  if (unifiedVehicle.trackingProvider !== 'WWGPS') {
    throw new Error('device is not a WWGPS device')
  }

  const { device } = unifiedVehicle

  return useMemo(() => {
    const iconsAsRecord = getDeviceIcons()
    const colors = [...colorPalette]
    const icons = Object.keys(iconsAsRecord).map(id => parseInt(id))

    if (device) {
      return {
        name: device.label,
        color: {
          color: device.iconColor1,
          colors,
        },
        icon: {
          icon: device.iconId,
          icons,
        },
      }
    }

    return {
      name: '',
      color: {
        color: colors[0],
        colors,
      },
      icon: {
        icon: defaultDeviceIconId,
        icons,
      },
    }
  }, [device])
}
