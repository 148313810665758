import { Skeleton } from '@mui/material'

import { FlexBox } from '@/local/components'

import { useOrderStepTrackingLink } from '../../../../orderNotificationAtom'

import { TrackingLinkFailed } from './components/TrackingLinkFailed'
import { TrackingLink } from './components/TrackingLink'
import { useTexts } from './useTexts'

type Props = {
  extendedOrderId: string
  status: 'loading' | 'failed' | 'ready'
}

export function OrderStepTrackingLink(props: Props) {
  const { extendedOrderId, status } = props

  const trackingLink = useOrderStepTrackingLink(extendedOrderId)
  const texts = useTexts()

  if (status === 'loading') {
    return (
      <FlexBox column vAlignContent="top" padding="22px 32px 8px" w="100%">
        <Skeleton variant="rectangular" height={16} width="30%" />
        <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: '12px' }} />
      </FlexBox>
    )
  }

  if (status === 'failed') {
    return <TrackingLinkFailed />
  }

  if (!trackingLink) return null

  return (
    <FlexBox column vAlignContent="top" padding="22px 32px 16px" w="100%">
      <TrackingLink link={trackingLink} text={texts.trackingLinkLabel} />
    </FlexBox>
  )
}
