import { useRef } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const api = useRef({
    cancel: translate({ id: 'v2.settings.mobileApp.modals.copySettings.footer.normal.secondary' }),
    replace: translate({
      id: 'v2.settings.mobileApp.modals.copySettings.footer.normal.primary',
    }),
    modalTitle: translate({ id: 'v2.settings.mobileApp.modals.copySettings.header.title' }),
    modalSubTitle: translate({
      id: 'v2.settings.mobileApp.modals.copySettings.header.subtitle',
    }),
    territoryDropdownTitle: translate({
      id: 'v2.settings.mobileApp.modals.copySettings.territoryDropdown.header',
    }),
    replaceWarningCardTitle: translate({
      id: 'v2.settings.mobileApp.modals.copySettings.messages.warning.title',
    }),
    replaceWarningCardDescription: translate({
      id: 'v2.settings.mobileApp.modals.copySettings.messages.warning.description',
    }),
    success: translate({
      id: 'v2.settings.mobileApp.modals.copySettings.messages.success.description',
    }),
    error: translate({
      id: 'v2.settings.mobileApp.modals.copySettings.messages.failed.description',
    }),
  })

  return api.current
}
