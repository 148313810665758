import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    autoSaveChanges: (remainingTime: string) =>
      translate({ id: 'transactionBar.messages.autoSaveChanges', values: { remainingTime } }),
    changesSaved: translate({ id: 'transactionBar.messages.saved' }),
    commitError: translate({ id: 'transactionBar.messages.commitError' }),
    commitSuccess: translate({ id: 'transactionBar.messages.commitSuccess' }),
    editSessionPaused: translate({ id: 'transactionBar.messages.editSessionPaused' }),
    editSessionOpenTitle: translate({ id: 'transactionBar.messages.editSessionOpen.title' }),
    editSessionOpenSubtitle: translate({ id: 'transactionBar.messages.editSessionOpen.subtitle' }),
    resetTimer: translate({ id: 'transactionBar.controls.resetTimer' }),
    resetTimerDisabled: translate({ id: 'transactionBar.controls.resetTimer.disabled' }),
    rollback: translate({ id: 'transactionBar.controls.rollback' }),
    rollbackDisabled: translate({ id: 'transactionBar.controls.rollback.disabled' }),
    undo: translate({ id: 'transactionBar.controls.undo' }),
    undoDisabled: translate({ id: 'transactionBar.controls.undo.disabled' }),
    redo: translate({ id: 'transactionBar.controls.redo' }),
    redoDisabled: translate({ id: 'transactionBar.controls.redo.disabled' }),
    saveChanges: translate({ id: 'transactionBar.controls.save' }),
    saveChangesDisabled: translate({ id: 'transactionBar.controls.save.disabled' }),
  }))

  return api
}
