import { usePagination } from '@/atoms'

import { usePendingFitMapOnMount } from '@/map'
import { GeneralSettings } from './GeneralSettings'
import { Exceptions } from './Exceptions'
interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function SingleView(props: Props) {
  const { unifiedVehicle } = props
  const { page } = usePagination('vehicleTab')

  usePendingFitMapOnMount()

  switch (page) {
    case 0:
      return <GeneralSettings unifiedVehicle={unifiedVehicle} />

    case 1:
      return <Exceptions unifiedVehicle={unifiedVehicle} />

    default:
      return null
  }
}
