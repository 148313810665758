import { FooterErrorItem } from '@/forms-legacy'

import * as Texts from '../../../../intl'

import { OrderStepValidation } from '../typings'
import { getErrorFieldHeader, getErrorField } from './utils'

export default function getOrderStepFieldsWithError(
  orderStepErrors: OrderStepValidation,
  showHeaders: boolean,
  type: uui.domain.client.rm.OrderStepIdentifier,
): FooterErrorItem[] {
  const fieldsWithError: FooterErrorItem[] = []

  if (showHeaders) {
    fieldsWithError.push(
      getErrorFieldHeader(
        type === 'p'
          ? Texts.getValidationErrorPickupHeaderText()
          : Texts.getValidationErrorDeliveryHeaderText(),
      ),
    )
  }

  if (orderStepErrors.tagsIn) {
    fieldsWithError.push(getErrorField(Texts.getTagsInLabelText(), showHeaders))
  }

  if (orderStepErrors.tagsOut) {
    fieldsWithError.push(getErrorField(Texts.getTagsOutLabelText(), showHeaders))
  }

  if (orderStepErrors.timeWindows) {
    fieldsWithError.push(getErrorField(Texts.getTimeWindowListText(), showHeaders))
  }

  if (orderStepErrors.timeWindowExceptions) {
    fieldsWithError.push(getErrorField(Texts.getTimeWindowExceptionListText(), showHeaders))
  }

  if (orderStepErrors.customFields) {
    fieldsWithError.push(getErrorField(Texts.getFieldLabelCustomFieldsText(), showHeaders))
  }

  if (orderStepErrors.phone) {
    fieldsWithError.push(getErrorField(Texts.getFieldLabelPhoneText(), showHeaders))
  }

  if (orderStepErrors.email) {
    fieldsWithError.push(getErrorField(Texts.getFieldLabelEmailText(), showHeaders))
  }

  if (orderStepErrors.location) {
    fieldsWithError.push(getErrorField(Texts.getFieldLabelAddressText(), showHeaders))
  }

  return fieldsWithError
}
