import styled from 'styled-components'

export const ReadonlyList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 6px;

  &::after {
    content: '';
    width: 30%;
  }

  & > * {
    margin-top: 6px;
    width: 30%;
  }
`

ReadonlyList.displayName = 'ReadonlyList'
