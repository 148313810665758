import { Add } from '@mui/icons-material'

import { ListHeaderActions, ListHeaderPrimaryActionButton } from '@/components/List'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../../../../useTexts'

import { useActions } from './hooks/useActions'

export function Actions() {
  const { editing, onCreate, isLoading } = useActions()

  const texts = useTexts()

  return (
    <ListHeaderActions>
      <ReadOnlyTooltip
        ignoreReasons={['courier']}
        placement="bottom"
        canEdit={!isLoading}
        title={texts.disabledForLoadingTooltip}
        render={preventEditing => (
          <Tooltip title={texts.addDriverAssignment} placement="bottom">
            <ListHeaderPrimaryActionButton
              className="pendo-setup-vehicleassignment__newassignment"
              disabled={preventEditing || editing}
              trackId="setup-vehicleassignment__create-new-button"
              onClick={onCreate}
            >
              <Add />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
    </ListHeaderActions>
  )
}
