import styled from 'styled-components'
import { Component, MouseEvent } from 'react'
import { clsx } from '@/utils'

export type Props = {
  value: string
  active: boolean
  className?: string
  readonly?: boolean
  onClick: (data: string) => void
}

class MeridiemButton extends Component<Props> {
  handleClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const { onClick, value } = this.props
    onClick(value)
  }

  render() {
    const { className = '', value, active, readonly = false } = this.props

    const rootClassName = clsx({
      [className]: true,
      'o-meridiem-button': true,
      'is-active': active,
      'is-readonly': readonly,
    })

    return (
      <button className={rootClassName} onClick={this.handleClick} disabled={active || readonly}>
        <span className="o-meridiem-button__inner">{value}</span>
      </button>
    )
  }
}

export const SIZE = 34
const INDICATOR_HEIGHT = 2

const StyledMeridiemButton = styled(MeridiemButton)`
  border-width: 0;
  border-radius: 0;
  background: transparent;
  padding: 0;
  outline: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};

  width: ${SIZE}px;
  height: 100%;
  text-transform: uppercase;
  background: ${p => p.theme.colors.$pureWhite};
  color: ${p => p.theme.colors.$silver};
  cursor: pointer;
  transition: 0.3s ease;
  transition-property: background, opacity;

  .o-meridiem-button__inner {
    position: relative;
  }

  .o-meridiem-button__inner::after {
    content: '';
    position: absolute;
    bottom: ${-INDICATOR_HEIGHT}px;
    left: 0;
    width: 100%;
    height: ${INDICATOR_HEIGHT}px;
    background: ${p => p.theme.colors.$silver};
    transform: scale(0, 1);
    transition: transform 0.2s ease;
  }

  &.is-active {
    background: ${p => p.theme.colors.$alabaster};
    color: ${p => p.theme.colors.$shadyLady};
  }

  &.is-readonly {
    cursor: not-allowed;
    opacity: 0.1;
  }

  &.is-active .o-meridiem-button__inner::after {
    transform: scale(1, 1);
  }

  &:not(.is-readonly):not(.is-active):hover .o-meridiem-button__inner::after {
    transform: scale(0.5, 1);
  }
`

StyledMeridiemButton.displayName = 'StyledMeridiemButton'

export default StyledMeridiemButton
