import { snapshot } from 'valtio'

import { editableRoadSegment } from '../editableRoadSegment'

import { removeEditableRoadSegment } from './removeEditableRoadSegment'

export type UpdateEditableRoadSegment = (
  prev: uui.domain.ui.map.EditableRoadSegment,
) => uui.domain.ui.map.EditableRoadSegment

export type UpdateEditableRoadSegmentParam =
  | UpdateEditableRoadSegment
  | Partial<uui.domain.ui.map.EditableRoadSegment>
  | 'reset'

export function updateEditableRoadSegment(valueOrFunc: UpdateEditableRoadSegmentParam) {
  // reset
  if (valueOrFunc === 'reset') return removeEditableRoadSegment()
  if (valueOrFunc === undefined) return removeEditableRoadSegment()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    editableRoadSegment.roadSegment = valueOrFunc(snapshot(editableRoadSegment.roadSegment))
  } else if (valueOrFunc) {
    Object.assign(editableRoadSegment.roadSegment, valueOrFunc)
  }

  return snapshot(editableRoadSegment.roadSegment)
}
