import type { FormConfig } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'
import type { ValidationTexts } from './useTexts'

import { createValidateMessage } from './createValidateMessage'

export function createFormValidation(
  texts: ValidationTexts,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'message',
      fields: 'message',
      validator: createValidateMessage(texts),
    },
  ]
}
