import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import { FileCopy } from '@mui/icons-material'

import { Plus, Flash, PaperPlane } from '@/icons'
import { Tooltip } from '@/components/primitives/Tooltip'
import { selectUserConfiguration } from '@/features/domain/user'
import { useCopyOrdersModal } from '@/components/modals/CopyOrdersModal'
import { ActionButton } from '@/components/primitives/buttons/ActionButton'
import { useBuildProgressModal } from '@/components/modals/BuildProgressModal'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { useItemsPermissions } from '../hooks/useItemsPermissions'
import { useActions } from '../hooks/useActions'
import { useStyles } from '../hooks/useStyles'
import { useTexts } from '../useTexts'

import { ImportWizardButton } from './ImportWizardButton'
import { Separator } from './Separator'

export function Operations() {
  const { show: showBuildProgressModal, Modal: BuildProgressModal } = useBuildProgressModal()
  const { show: showCopyOrdersModal, Modal: CopyOrdersModal } = useCopyOrdersModal()
  const {
    addOrderPermissions,
    buildRoutesPermissions,
    dispatchRoutesPermissions,
    copyToOperationsPermissions,
  } = useItemsPermissions()

  const { addOrder, buildRoutes, showDispatchPanel } = useActions(
    showBuildProgressModal,
    showCopyOrdersModal,
  )

  const { planType } = useSelector(selectUserConfiguration)
  const classes = useStyles()
  const texts = useTexts()

  const isArchivedDay = planType === 'archived'

  return (
    <Stack marginTop={2} padding="0 24px 24px 16px" spacing={1} width="100%">
      <ReadOnlyTooltip
        placement="right"
        canEdit={!addOrderPermissions.disabled}
        title={addOrderPermissions.tooltip.title}
        subtitle={addOrderPermissions.tooltip.subtitle}
        render={preventEditing => (
          <ActionButton
            onClick={addOrder}
            text={texts.addOrder}
            disabled={preventEditing}
            secondaryButton={<ImportWizardButton disabled={preventEditing} />}
            testid="sidebar-operations__add-order-button"
            Icon={<Plus size={14} color={preventEditing ? '$silver' : '$nightRider'} />}
          />
        )}
      />

      <Separator />

      <ReadOnlyTooltip
        placement="right"
        canEdit={!buildRoutesPermissions.disabled}
        title={buildRoutesPermissions.tooltip.title}
        subtitle={buildRoutesPermissions.tooltip.subtitle}
        forceState={buildRoutesPermissions.tooltip.forceState}
        render={preventEditing => (
          <ActionButton
            onClick={buildRoutes}
            text={texts.buildRoutes}
            disabled={preventEditing}
            testid="sidebar-operations__build-button"
            Icon={<Flash size={14} color={preventEditing ? '$silver' : '$nightRider'} />}
          />
        )}
      />

      <Separator />

      {isArchivedDay ? (
        /**
         * That tooltip can't actually be managed via `ReadOnlyTooltip` since it doesn't represent
         * a change to the archived day but to Operations
         **/
        <Tooltip
          placement="right"
          disabled={!copyToOperationsPermissions.disabled}
          title={copyToOperationsPermissions.tooltip.title}
          subtitle={copyToOperationsPermissions.tooltip.subtitle}
        >
          <ActionButton
            Icon={
              <FileCopy
                color={copyToOperationsPermissions.disabled ? 'disabled' : undefined}
                className={classes.copyIcon}
              />
            }
            disabled={copyToOperationsPermissions.disabled}
            testid="sidebar-operations__copy-button"
            onClick={showCopyOrdersModal}
            text={texts.copy}
          />
        </Tooltip>
      ) : (
        <ReadOnlyTooltip
          render={preventEditing => (
            <ActionButton
              Icon={
                <PaperPlane
                  size={14}
                  color={
                    preventEditing || dispatchRoutesPermissions.disabled ? '$silver' : '$nightRider'
                  }
                />
              }
              testid="sidebar-operations__dispatch-routes-button"
              disabled={preventEditing || dispatchRoutesPermissions.disabled}
              onClick={showDispatchPanel}
              text={texts.dispatchRoutes}
            />
          )}
        />
      )}

      <BuildProgressModal />
      <CopyOrdersModal />
    </Stack>
  )
}
