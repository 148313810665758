import { Box } from '@mui/material'

import { WarningCard } from '@local/components'
import { WarningTriangle } from '@/icons'

import { useTexts } from './useTexts'

export function NotificationLimitations() {
  const texts = useTexts()

  return (
    <Box paddingBottom={4}>
      <WarningCard
        description={texts.notificationLimitationDescription}
        title={texts.notificationLimitationTitle}
        Icon={<WarningTriangle size={16} color="$pureWhite" background="$darkOrange" />}
        preset="warning"
      />
    </Box>
  )
}
