import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    sortBy: (field: string) => {
      switch (field) {
        case 'name':
          return translate({ id: 'simulation.sorter.sortBy.name' })
        case 'ordersCount':
          return translate({ id: 'simulation.sorter.sortBy.orders' })
        case 'vehiclesCount':
          return translate({ id: 'simulation.sorter.sortBy.vehicles' })
        case 'lastEdit':
          return translate({ id: 'simulation.sorter.sortBy.lastEdit' })
        default:
          console.warn(`Unknown geofence field: ${field}`)
          return ''
      }
    },

    sortByField: (field: string) =>
      translate({ id: 'simulation.sorter.sortBy', values: { field } }),

    sortAscending: translate({ id: 'simulation.sorter.sortBy.direction.ascending' }),
    sortDescending: translate({ id: 'simulation.sorter.sortBy.direction.descending' }),
  }))

  return api
}
