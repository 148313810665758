import { DropdownMenuItem } from '@/components/DropdownMenu'

import { useRoutingLayoutMenu } from '../hooks/useRoutingLayoutMenu'
import { useTexts } from '../useTexts'
import { TickIcon } from './TickIcon'
import { Spacer } from './Spacer'

export function SchedulerLayoutItem() {
  const texts = useTexts()
  const { setToScheduler, layoutMode } = useRoutingLayoutMenu()

  return (
    <DropdownMenuItem
      onClick={setToScheduler}
      LeftIcon={layoutMode === 'scheduler' ? <TickIcon /> : <Spacer />}
      data-testid="header__layout-menu-scheduler"
    >
      {texts.scheduler}
    </DropdownMenuItem>
  )
}
