import styled from 'styled-components'

interface ClickableContainerProps {
  marginBottom?: number
}

export const ClickableContainer = styled.button<ClickableContainerProps>`
  position: relative;
  background: transparent;
  outline: 0;
  margin: 0;
  border-width: 0;
  border-radius: 4px;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  width: 100%;
  height: auto;

  padding: 0 4px 0 10px;
  margin-bottom: ${p => p.marginBottom || 0}px;
  text-align: left;

  transition: background 0.25s ease-out;
  will-change: background;

  &:disabled {
    color: currentColor;
  }

  &:not(:disabled):hover {
    background: ${p => p.theme.colors.$whiteSmoke};
  }
`

ClickableContainer.displayName = 'ClickableContainer'
