import type {
  DrawEditableRoadSegmentInteraction,
  ModifyEditableRoadSegmentInteraction,
} from '../../../interactions/EditableRoadSegmentInteraction'

import Feature from 'ol/Feature'
import LineString from 'ol/geom/LineString'

import { gis } from '@/server-data'

import { drawFeatureMetadata } from '../../../interactions/EditableRoadSegmentInteraction/utils/drawFeatureMetadata'

import { getMap } from '../../../atoms/map/utils/getMap'
import { findLayer } from '../../../layers/utils/findLayer'

import { changeRoadSegmentColor } from './createChangeRoadSegmentColor'

export function createOnRoadSegmentModeChange(
  drawRoadSegment: DrawEditableRoadSegmentInteraction,
  modifyRoadSegment: ModifyEditableRoadSegmentInteraction,
) {
  let prevMode: uui.domain.ui.map.EditableRoadSegment['mode'] = 'none'
  let prevType: uui.domain.ui.map.EditableRoadSegment['type'] = 'disabled'

  /**
   * Listen to the Editable RoadSegment Store changes and update the drawing into the Map accordingly.
   * It's responsible to activate and deactivate the `draw` and `modify` interactions.
   */
  return (RoadSegment: uui.domain.ui.map.EditableRoadSegment) => {
    const { mode } = RoadSegment

    if (prevType !== RoadSegment.type) {
      // Update the Active RoadSegment color when it change in the Editable RoadSegment Store
      changeRoadSegmentColor(drawRoadSegment, modifyRoadSegment, RoadSegment.type)
      prevType = RoadSegment.type
    }

    // if `RoadSegment.mode` is not changed it means that the change is coming from the Map interactions.
    // Nothing to do here.
    if (prevMode === RoadSegment.mode) return

    switch (mode) {
      // Start drawing a new RoadSegment
      case 'create':
        modifyRoadSegment?.setActive(false)
        drawRoadSegment?.setActive(true)
        break

      // Start editing an RoadSegment
      case 'edit': {
        const map = getMap()
        // try to retrieve the active drawing feature
        const layer = findLayer(map, 'draw')
        let feature = layer.getSource()?.getFeatureById(RoadSegment.id)

        // if the feature doesn't exist it means the editing is not part of the creation of a new RoadSegment.
        // The editing started from an Edit action in a form.
        // We need to create a feature to support the edit of the RoadSegment.
        if (!feature) {
          // convert available points to the Map's coordinate system
          const coords: number[][] = [
            RoadSegment.editableSegment.start,
            RoadSegment.editableSegment.end,
          ]?.map(point => gis.fromLatLngToCoordinate(point))

          // if there's no point we stop here.
          // It shouldn't be possible but it's here for extra safety at runtime.
          if (coords.length === 0) {
            drawRoadSegment?.setActive(false)
            modifyRoadSegment?.setActive(false)

            if (process.env.NODE_ENV === 'development') {
              throw new Error(`Trying to edit an RoadSegment without points.`)
            }
            return
          }

          // create the feature and add it to the `draw` layer
          feature = new Feature({ geometry: new LineString(coords) })
          drawFeatureMetadata.setAsEditableRoadSegment(feature)
          feature.setId(RoadSegment.id)
          layer.getSource()?.addFeature(feature)

          const extent = feature.getGeometry()?.getExtent()
          if (extent) {
            map.getView().fit(extent, { padding: [80, 80, 80, 80], maxZoom: 17 })
          }
        }

        // disable the DRAW interaction and enable the MODIFY interaction
        drawRoadSegment?.setActive(false)
        modifyRoadSegment?.setActive(true)
        break
      }

      // stop editing or drawing
      case 'none': {
        // disable the interactions
        drawRoadSegment?.setActive(false)
        modifyRoadSegment?.setActive(false)

        // clear the map layer of any feature
        const layer = findLayer(getMap(), 'draw')
        layer.getSource()?.clear(true)
        break
      }
    }

    // store the previous RoadSegment Mode
    prevMode = RoadSegment.mode
  }
}
