import { useEffect, useState } from 'react'

export function useIsCreating() {
  const [creating, setCreating] = useState(false)

  useEffect(() => {
    // FIXME: implement this once we are able to set the form in the editing state from the scheduler
    // const storedException: string | undefined = getApplicationStorageValue(
    //   StorageKey.vehicleCreationException,
    //   false,
    //   true,
    // )
    // if (!!storedException) {
    //   setIsCreating(true)
    // }
    if (false) {
      setCreating(true)
    }
  }, [])

  return creating
}
