import { useMemo } from 'react'

import { parseRecurrence, getRecurrenceLabel } from '@/server-data'
import { intl } from '@/intl'

import { useTexts } from './useTexts'

export function useItemInfos(id: string) {
  const texts = useTexts()

  return useMemo(() => {
    const recurrence = parseRecurrence(id)
    const label = !!recurrence
      ? getRecurrenceLabel(recurrence.type, recurrence.value, intl.translate)
      : ''

    const description = !!recurrence ? texts.recurrenceDescription(recurrence.type) : ''

    return {
      label,
      description,
    }
  }, [texts, id])
}
