import { useSelector } from 'react-redux'

import { useTransactionState } from '@/atoms'
import { Tooltip } from '@/components/primitives/Tooltip'
import { isGpsOnly } from '@/features/domain/user'

import { useTexts } from './useTexts'
import { RmDropdown } from './components/RmDropdown'
import { GpsOnlyDropdown } from './components/GpsOnlyDropdown'

export function TerritoryDropdownMenu() {
  const transaction = useTransactionState()
  const gpsOnly = useSelector(isGpsOnly)
  const texts = useTexts()

  const disabledMenu = transaction.mode === 'controlled'

  return (
    <Tooltip
      placement="bottom"
      title={texts.editingInProgressTitle}
      subtitle={texts.editingInProgressSubtitle}
      disabled={!disabledMenu}
    >
      {gpsOnly ? <GpsOnlyDropdown /> : <RmDropdown disabled={disabledMenu} />}
    </Tooltip>
  )
}
