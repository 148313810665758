import { FormatDateInterval } from '@/components/smartUtils/time/FormatDateInterval'
import { HorizontalLayout, VerticalLayout } from '@/components/layout'
import { Text } from '@/local/components'
import { Truck, Order } from '@/icons'

import { EntityButton } from './components/EntityButton'

import { useEntityRelations } from './hooks/useEntityRelations'
import { useTexts } from './useTexts'

const ordersButtonStyle = { marginLeft: 10 }

export function EntityRelations() {
  const texts = useTexts()
  const {
    orders,
    actions,
    vehicles,
    interval: { start, end },
  } = useEntityRelations()
  const noOrders = orders.length === 0
  const noVehicles = vehicles.length === 0

  return (
    <HorizontalLayout width="auto" alignItems="center">
      <EntityButton
        autoWidth
        disabled={noVehicles}
        Icon={<Truck size={18} />}
        onClick={actions.setVehiclesSelection}
      >
        <VerticalLayout width="auto" justifyContent="center">
          <Text size="$m">
            {vehicles.length} {texts.vehicles(vehicles.length)}
          </Text>
          <Text size="$xs">
            {texts.on} <FormatDateInterval start={start} end={end} />
          </Text>
        </VerticalLayout>
      </EntityButton>

      <EntityButton
        autoWidth
        disabled={noOrders}
        style={ordersButtonStyle}
        Icon={<Order size={11} />}
        onClick={actions.setOrderStepsSelection}
      >
        <VerticalLayout width="auto" justifyContent="center">
          <Text size="$m">
            {orders.length} {texts.orders(orders.length)}
          </Text>
          <Text size="$xs">
            {texts.on} <FormatDateInterval start={start} end={end} />
          </Text>
        </VerticalLayout>
      </EntityButton>
    </HorizontalLayout>
  )
}
