import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { selectUserConfigurationType } from '@/features/domain/user'

import { useAvailableDevices } from './useAvailableDevices'
import { useTexts } from './useTexts'

export function useTrackingOptionRadioValues(deviceId?: string) {
  const uiAccountType = useSelector(selectUserConfigurationType)
  const availableDevices = useAvailableDevices()
  const texts = useTexts()

  const hasDevice = !!deviceId
  const enabledForPhysicalDevices =
    uiAccountType === 'rm360' || uiAccountType === 'rm360PhysicalDevicesOnly'

  const physicalDeviceDisabled =
    !enabledForPhysicalDevices || (!hasDevice && availableDevices.length === 0)

  return useMemo(() => {
    return [
      {
        label: texts.mobileApp,
        value: 'mobileApp',
      },
      {
        label: texts.physicalDevice,
        value: 'physicalDevice',
        disabled: physicalDeviceDisabled,
        disabledTitle: physicalDeviceDisabled ? texts.physicalDeviceDisabled : undefined,
      },
    ]
  }, [texts, physicalDeviceDisabled])
}
