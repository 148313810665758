import { Box, Typography } from '@mui/material'

import { Form } from './Form'
import { useSelectedOrders } from './hooks/useSelectedOrders'

export function AddLoadCfToOrderStep() {
  const { selectedOrders } = useSelectedOrders()

  if (selectedOrders.length !== 1) {
    const message =
      selectedOrders.length === 0
        ? 'To insert dynamic loads/cf first select an order'
        : 'To insert dynamic loads/cf you have to select only one order'
    return (
      <Box>
        <Typography variant="body1">{message}</Typography>
      </Box>
    )
  }

  return (
    <>
      <Form order={selectedOrders[0]} />
    </>
  )
}
