import { proxy, useSnapshot } from 'valtio'

type TesterModalState = 'open' | 'closed'
const testerModalStateAtom = proxy<{ state: TesterModalState }>({ state: 'closed' })

// ---------------------------------------------------------------------------
// Write functions
// ---------------------------------------------------------------------------

type SetTesterModalState = (prev: TesterModalState) => TesterModalState
type SetTesterModalStateParam = SetTesterModalState | 'open' | 'closed' | 'reset'

export function resetTesterModalState() {
  testerModalStateAtom.state = 'closed'
}

export const setTesterModalState = (valueOrFunc: SetTesterModalStateParam) => {
  if (valueOrFunc === 'reset') return resetTesterModalState()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(testerModalStateAtom.state, valueOrFunc(testerModalStateAtom.state))
  } else {
    testerModalStateAtom.state = valueOrFunc
  }

  return testerModalStateAtom
}

export function useTesterModalState() {
  return [useSnapshot(testerModalStateAtom).state, setTesterModalState] as const
}
