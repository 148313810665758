import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { AddOrder } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  disabled: boolean
}

export function CreateOrderButton(props: Props) {
  const { disabled, onClick } = props
  const texts = useTexts()

  return (
    <ReadOnlyTooltip
      render={preventEditing => {
        const buttonColor = preventEditing || disabled ? '$shadyLady' : '$nightRider'

        return (
          <NavigoIconButton
            disabled={preventEditing || disabled}
            onClick={onClick}
            Icon={<AddOrder size={17} background={buttonColor} foreground="$shadyLady" />}
            text={texts.newOrder}
          />
        )
      }}
    />
  )
}
