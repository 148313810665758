import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    timeFormat: translate({ id: 'settings.languageAndFormats.edit.fields.timeFormat.label' }),
    dateFormat: translate({ id: 'settings.languageAndFormats.edit.fields.dateFormat.label' }),
    distanceFormat: translate({
      id: 'settings.languageAndFormats.edit.fields.distanceFormat.label',
    }),
  }))

  return api
}
