import type { AxiosResponse } from 'axios'

import axios from 'axios'
import { httpDefaultResponse } from './httpDefaultResponse'
/**
 * How the env variable work?
 * Env variable is useful to receive the correct reset password link based on the actual env
 *
 * PROD:
 * env === undefined || <unknownValue> -> v2 link (will be v3 after GA release)
 * env === 'legacy'                    -> v2
 * env === 'beta'                      -> v3 (for the moment, maybe it will be removed in the future)
 *
 * QA:
 * env === undefined || <unknownValue> -> v3
 * env === 'legacy'                    -> v2
 * env === 'beta'                      -> v3 (also if not necessary since is the default value)
 *
 * DEV/TEST
 * env === undefined || <unknownValue> -> v3
 * env === 'legacy'                    -> v2
 * env === 'beta'                      -> v3
 */

type Request = {
  serverUrl: string
  username: string
}

export async function recoverPassword({ username, serverUrl }: Request) {
  const isBeta = globalThis.location.href.includes('beta.')
  const env = isBeta ? 'beta' : undefined

  const response: AxiosResponse<any> = await axios.get(`${serverUrl}/askresetpwuui`, {
    params: { username: encodeURI(username), env },
  })

  return httpDefaultResponse(response)
}
