import type { PopoverPosition, PopoverOrigin } from '@mui/material'

import { MultipleOrders } from './components/MultipleOrders'
import { SingleOrder } from './components/SingleOrder'
import { useOrders } from './hooks/useOrders'

interface Props {
  ids: workwave.DeepReadonly<string[]>
  transformOrigin: PopoverOrigin
  anchorPosition: PopoverPosition
}

export function OrderTooltip(props: Props) {
  const { ids, ...tooltipProps } = props

  const extOrderSteps = useOrders(ids)

  if (extOrderSteps.length === 0) return null

  return ids.length === 1 ? (
    <SingleOrder extOrderStep={extOrderSteps[0]} {...tooltipProps} />
  ) : (
    <MultipleOrders extOrderSteps={extOrderSteps} {...tooltipProps} />
  )
}
