import { Badge } from '@mui/material'

import { InvisibleButton, Text } from '@/local/components'
import { Tooltip } from '@/components/primitives/Tooltip'

import { TabOuter } from './components/TabOuter'

type Props = {
  label: string
  onClick: () => void
  active: boolean
  disabled?: boolean
  className?: string
  testid?: string

  tooltipEnabled?: boolean
  tooltipTitle?: string
  error?: boolean
}

export function Tab(props: Props) {
  const {
    onClick,
    label,
    active,
    disabled,
    className,
    testid,
    tooltipTitle = '',
    tooltipEnabled = false,
    error = false,
  } = props

  // NOTE: The display hack in the badge has been made because the invisible prop looks like is not working here
  const slotProps = {
    badge: {
      style: { transform: 'translateX(11px)', display: error ? 'block' : 'none' },
    },
  }

  return (
    <InvisibleButton
      textAlign="center"
      width="100%"
      height="100%"
      onClick={onClick}
      disabled={disabled}
      className={className}
      data-testid={testid}
      data-trackid={testid}
    >
      <TabOuter active={active}>
        <Tooltip placement="top" title={tooltipTitle} disabled={!tooltipEnabled}>
          <Badge badgeContent="" color="error" variant="dot" slotProps={slotProps}>
            <Text
              size="$h4"
              weight="$semiBold"
              color={disabled ? (active ? '$nightRider' : '$shadyLady') : '$nightRider'}
              ellipsis
            >
              {label}
            </Text>
          </Badge>
        </Tooltip>
      </TabOuter>
    </InvisibleButton>
  )
}
