import { useSelector } from 'react-redux'
import { useCallback } from 'react'

import { useIsUnmounted } from '@/hooks'

import { fileUtils } from '@/utils'
import { timeUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

import { parseFileContent } from './utils/parseFileContent'
import { useController } from './useController'

/**
 * Manage the user changing the file to import.
 */
export const useHandleOnChangeFile = () => {
  const userConfig = useSelector(selectUserConfiguration)
  const isUnmounted = useIsUnmounted()

  const { updateData } = useController()
  const { openFile, readFile } = fileUtils

  return useCallback(async () => {
    try {
      const file: File = await openFile()
      if (isUnmounted()) return

      const result = await readFile(file, { asText: true })
      if (isUnmounted()) return

      if (result.type === 'resolved') {
        updateData({
          importedTerritory: parseFileContent(result.file),
          fileName: file.name,
          lastModifiedDate: timeUtils.formatDate(userConfig)(new Date(file.lastModified)),
        })
      }
    } catch (e) {
      // do nothing, the user cancelled the open file dialog
    }
  }, [openFile, readFile, updateData, userConfig, isUnmounted])
}
