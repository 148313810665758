import styled from 'styled-components'

import { ReadonlyLabel } from '@/forms-legacy'

export const FooterDescription = styled(ReadonlyLabel).attrs({
  className: 'o-footer-description',
})`
  font-size: ${p => p.theme.fonts.$s};
`

FooterDescription.displayName = 'FooterDescription'
