import { useState, useEffect } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

import { TerritoryReference } from '../typings'
import { useFetchDemoTerritories } from './useFetchDemoTerritories'

type ModalStatus = 'ready' | 'failed' | 'loading'
interface ModalData {
  id?: string
  territories: TerritoryReference[]
}

export const modalId = 'importDemoTerritory'

export const useController = () => useModalController<ModalData, ModalStatus>(modalId)
export const useConfigureController = () => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      invalid: true,
      status: 'loading' as ModalStatus,
      data: { territories: [] },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalStatus>(modalId, options)
  const {
    update,
    data: { id },
  } = ctrl

  // EFFECTS

  useFetchDemoTerritories()

  // Invalid state
  useEffect(() => {
    update({ invalid: !id })
  }, [id, update])

  return ctrl
}
