import { useCallback, useRef, useState, useEffect } from 'react'

import { createOrder } from '@/features/domain/order'
import { setMainSelection, useListApi, useResetEditingState } from '@/atoms'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export const useOnCreateOrder = () => {
  const dispatch = useAppDispatch()

  const stopEditing = useResetEditingState()

  const mounted = useRef<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const toast = useNotification()
  const { scrollTo } = useListApi('orderSteps')

  const onCreateOrder = useCallback(
    async (createRequest: uui.domain.ui.forms.OrderCreate) => {
      try {
        setSubmitting(true)
        const thunkResult = await dispatch(createOrder(createRequest))

        if (mounted.current) {
          setSubmitting(false)
        }

        if (!createOrder.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()

        if (!mounted.current) return

        setMainSelection('orderSteps', thunkResult.payload)

        scrollTo(thunkResult.payload[0])
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, scrollTo, toast],
  )

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return {
    submitting,
    onCreateOrder,
  }
}
