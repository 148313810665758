import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Suitcase(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 9 7">
        <path d="M2.88235 0v.41176H.82353C.36853.41176 0 .7803 0 1.2353v2.4706c0 .45417.36935.82352.82353.82352h6.58823c.45418 0 .82353-.36935.82353-.82353V1.2353c0-.455-.36853-.82353-.82353-.82353H5.35294V0H2.88235zm1.2353 2.88235c.2273 0 .41176.18447.41176.41177 0 .2273-.18447.41176-.41176.41176-.2273 0-.41177-.18447-.41177-.41176 0-.2273.18447-.41177.41177-.41177zM0 5.12374v1.05273C0 6.63147.36853 7 .82353 7h6.58823c.455 0 .82353-.36853.82353-.82353V5.12374c-.24335.14164-.52211.2292-.82353.2292H.82353c-.30141 0-.58018-.08756-.82353-.2292z" />
      </svg>
    </IconContainer>
  )
}
