import { useCallback } from 'react'
import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import { MultiSelect } from '@workwave-tidal/tidal/components'

import { useControllerActions } from '../../hooks/useControllerActions'
import { type Tag, useController } from '../../hooks/useController'

import { useTexts } from './useTexts'

export function Body() {
  const texts = useTexts()
  const actions = useControllerActions()
  const { data } = useController()

  const isOptionEqualToValue = useCallback(
    (option1: Tag, option2: Tag) => option1.id === option2.id,
    [],
  )

  const matchCriteriaDisabled = data.badgeFilter.length < 2

  return (
    <Stack spacing={2} sx={{ mt: 2 }} minHeight={368}>
      <MultiSelect<Tag>
        items={data.badges}
        label={texts.filterByBadge}
        value={data.badgeFilter}
        getId={o => o.id}
        getLabel={o => o.label}
        density="compact"
        onChange={actions.onChangeFilter}
        inputMode="normal"
        optionMode="normal"
        autocompleteProps={{ isOptionEqualToValue }}
      />

      <Stack>
        <FormControlLabel
          control={
            <Checkbox
              id="includeDevicesWithNoBadges"
              checked={data.includeDevicesWithNoBadges}
              onChange={actions.onChangeIncludeDevicesWithNoBadges}
            />
          }
          label={texts.showEntitiesWithoutBadge}
        />

        <FormControlLabel
          control={
            <Checkbox
              id="matchCriteria"
              checked={data.filterOperator === 'and'}
              disabled={matchCriteriaDisabled}
              onChange={actions.onChangeFilterOperator}
            />
          }
          label={texts.matchCriteria}
        />
      </Stack>
    </Stack>
  )
}
