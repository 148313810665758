import { useCallback } from 'react'

import { resetCrudSelection } from '@/atoms'

import { useSetTrafficAreasFilter } from './useSetTrafficAreasFilter'

export function useGoBack() {
  const setTrafficAreasFilter = useSetTrafficAreasFilter()

  return useCallback(() => {
    setTrafficAreasFilter([])
    resetCrudSelection('trafficProfile')
    resetCrudSelection('trafficArea')
  }, [setTrafficAreasFilter])
}
