import { useVehicleFormException } from '@/atoms'
import { Exception } from './Exception'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  transactionOpen: boolean
}

export function ExceptionProvider(props: Props) {
  const { unifiedVehicle, transactionOpen } = props

  const [{ exception }] = useVehicleFormException()

  if (!unifiedVehicle.hasRoutingLicense) return null

  const vehicleSettings = unifiedVehicle.vehicle.settings[exception]
  if (!vehicleSettings) return null

  return (
    <Exception
      exception={exception}
      unifiedVehicle={unifiedVehicle}
      transactionOpen={transactionOpen}
      vehicleSettings={vehicleSettings}
    />
  )
}
