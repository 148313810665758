import { ExecutionEventMarkerType } from '../../../atoms/executionEventMarkers/executionEventMarkers'
import coordinates from './execution-events.json'

const cellSize = coordinates?.side

if (cellSize === undefined) {
  throw new Error(`Impossible to find the cellSize for map Execution Events markers`)
}

export function getExecutionEventGridPlacement(
  type: ExecutionEventMarkerType,
): uui.domain.ui.map.markers.GridPlacement {
  const coords = coordinates?.coordinates?.[type]?.[type]

  if (!coords) {
    throw new Error(`Impossible to find Grid Placement for a Map Execution Event of type: ${type}`)
  }

  return [coords[0], coords[1], cellSize]
}
