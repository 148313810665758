import type { SxProps, Theme } from '@mui/material'
import { Stack, Typography } from '@mui/material'
import { Person as PersonIcon } from '@mui/icons-material'

interface Props {
  name: string
  driverId: string
}

const iconStyle: SxProps<Theme> = { color: 'grey.600' }

export function Driver(props: Props) {
  const { name, driverId } = props

  const color = driverId === '-' ? 'grey.600' : 'primary.text'

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      borderBottom="1px solid"
      paddingBottom={2}
      borderColor="grey.300"
      data-testid="routeDriverHeader"
    >
      <PersonIcon sx={iconStyle} />
      <Typography data-testid="driverName" color={color}>
        {name}
      </Typography>
    </Stack>
  )
}
