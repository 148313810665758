import { type Calculation } from 'final-form-calculate'

export function createTelematicsDecorators(
  tenantSources: uui.domain.client.gps.telematics.TenantSource[],
): Calculation[] {
  return [
    {
      field: 'telematics.gpsTracking',
      updates: (
        isTracked: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics['telematics']['gpsTracking'],
        _,
        allValues: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics,
        _prevValues: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics,
      ) => {
        const tenantSourceId = isTracked && tenantSources.length === 1 ? tenantSources[0].id : ''

        return {
          rm: { ...allValues.rm, deviceId: isTracked ? allValues.rm?.deviceId ?? '' : '' },
          telematics: {
            ...allValues.telematics,
            tenantSourceId,
          },
        }
      },
    },
    {
      field: 'telematics.tenantSourceId',
      updates: (
        _tenantSourceId: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics['telematics']['tenantSourceId'],
        _,
        allValues: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics,
        prevValues: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics,
      ) => {
        if (Object.keys(prevValues).length === 0) return allValues

        return {
          rm: { ...allValues.rm, deviceId: '' },
          telematics: allValues.telematics,
        }
      },
    },
  ]
}
