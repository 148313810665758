import { PureComponent, ChangeEvent } from 'react'

import HorizontalRadio from './HorizontalRadio'
import HorizontalRadioContainer from './HorizontalRadioContainer'
import HorizontalRadioLabel from './HorizontalRadioLabel'
import HorizontalRadioLabelText from './HorizontalRadioLabelText'
import HorizontalRadioLabelExtraText from './HorizontalRadioLabelExtraText'
import HorizontalRadioGroupContainer from './HorizontalRadioGroupContainer'

import { Props } from './typings'

export default class HorizontalRadioGroup extends PureComponent<Props> {
  private handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props
    const {
      target: { value },
    } = event
    onChange(value)
  }

  private renderOptions() {
    const {
      values,
      value: selectedValue,
      name,
      disabled: parentDisabled,
      colors,
      hideSelectionUnderline,
      multiline = false,
      elementStyle = {},
    } = this.props

    return values.map(
      (
        {
          disabled: itemDisabled,
          disabledTitle: itemDisabledTitle,
          label,
          value,
          extraLabel,
          className = '',
          trackid,
        },
        idx,
        collection,
      ) => {
        const selected: boolean = selectedValue === value
        const disabled: boolean = !!parentDisabled || !!itemDisabled

        const first: boolean = idx === 0
        const last: boolean = idx === collection.length - 1

        return (
          <HorizontalRadioLabel
            key={value}
            disabled={disabled}
            selected={selected}
            colors={colors}
            first={first}
            last={last}
            multiline={multiline}
            style={elementStyle}
            title={itemDisabled ? itemDisabledTitle : ''}
            data-testid="horizontal-radio__label"
          >
            <HorizontalRadioContainer
              className={className}
              data-testid="horizontal-radio-group__container"
              data-trackid={trackid}
            >
              <HorizontalRadio
                name={name}
                value={value}
                checked={selected}
                onChange={this.handleOptionChange}
                disabled={disabled}
                data-testid="horizontal-radio__input"
              />
            </HorizontalRadioContainer>
            <HorizontalRadioLabelText
              selected={selected}
              hideSelectionUnderline={hideSelectionUnderline}
              multiline={multiline}
            >
              {label}
              {!!extraLabel && (
                <HorizontalRadioLabelExtraText>{extraLabel}</HorizontalRadioLabelExtraText>
              )}
            </HorizontalRadioLabelText>
          </HorizontalRadioLabel>
        )
      },
    )
  }

  render() {
    const { colors, hideOuterBorder, testid } = this.props

    return (
      <HorizontalRadioGroupContainer
        hideOuterBorder={hideOuterBorder}
        colors={colors}
        data-testid={testid}
      >
        {this.renderOptions()}
      </HorizontalRadioGroupContainer>
    )
  }
}
