import { Stack } from '@mui/material'
import { ModalContent } from '@/components/Modal'

import { useLocationEditorMap } from './hooks/useLocationEditorMap'
import { useOnSubmit } from './hooks/useOnSubmit'

import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { Data } from './components/Data'
import { Map } from './components/Map'

type Props = {
  center: uui.domain.LatLng
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery'
  pinType: uui.domain.ui.map.LocationPinType
  onChange: (payload: uui.domain.client.Location) => void
}

export function Form(props: Props) {
  const { pinId, onChange, pinType } = props
  const onSubmit = useOnSubmit(pinId, onChange)

  // Initialize the map
  const map = useLocationEditorMap(pinId, pinType)

  return (
    <ModalContent
      maxWidth={800}
      footer={<Footer onSubmit={onSubmit} map={map} pinId={pinId} />}
      header={<Header pinId={pinId} />}
    >
      <Stack width={586}>
        <Map map={map} pinId={pinId} />
        <Data pinId={pinId} map={map} />
      </Stack>
    </ModalContent>
  )
}
