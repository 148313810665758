import { memo, useCallback } from 'react'
import { NearMe, Tag } from '@mui/icons-material'

import {
  ListItem,
  ListItemAvatarRounded,
  ListItemContent,
  ListItemRow,
  ListItemTitle,
  ListItemVisibilityToggler,
  ListItemTag,
} from '@/components/List'

import { type RenderItemProps } from '@/components/StructuredVirtualList'

import { getColorValue } from '@/styles'

import { DeviceIcon } from '../../../../components/DeviceAvatar/DeviceIcon'

import { useTexts } from '../../hooks/useTexts'
import { useIsGpsOnly } from '../../../hooks/useIsGpsOnly'
import { useVisibility } from '../../hooks/useVisibility'

import { useBadges } from './useBadges'

type Props = Extract<RenderItemProps<'devices'>, { type: 'item' }>

export const DeviceItem = memo((props: Props) => {
  const { selected, item, onItemClick, onItemDoubleClick } = props

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])

  const [visible, toggleVisibility] = useVisibility(item.deviceId)

  const gpsOnly = useIsGpsOnly()

  const label = item.label

  const deviceId = item.deviceId
  const virtual = false
  const allowGps = true

  const badges = useBadges(deviceId)
  const badgesCount = badges.length
  const hasNoBadges = badgesCount === 0

  const texts = useTexts()

  const showDeviceText = !item.virtual && !item.iconId

  return (
    <ListItem
      onClick={onClick}
      selected={selected}
      onDoubleClick={onDoubleClick}
      invisibleOnMap={!visible}
      avatar={
        <ListItemAvatarRounded color={getColorValue(item.iconColor1)}>
          {showDeviceText ? label : <DeviceIcon iconId={item.iconId} />}
        </ListItemAvatarRounded>
      }
      right={
        gpsOnly ? null : (
          <ListItemVisibilityToggler toggleVisibility={toggleVisibility} visible={visible} />
        )
      }
    >
      <ListItemTitle rightAdornment={<ListItemTag label={texts.deviceBadge} />}>
        {label}
      </ListItemTitle>

      <ListItemRow testId="device-type-row">
        <ListItemContent icon={<NearMe />}>
          {texts.device(allowGps, virtual, false, false)}
        </ListItemContent>
      </ListItemRow>

      <ListItemRow testId="device-badges-row">
        <ListItemContent empty={hasNoBadges} icon={<Tag />}>
          {texts.badges(badges)}
        </ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})
DeviceItem.displayName = 'DeviceItem'
