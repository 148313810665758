import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectTrafficProfilesListStructure } from '@/features/domain/lists'

export function useTitle() {
  const listStructure = useSelector(selectTrafficProfilesListStructure)

  const [selection] = useCrudSelection('trafficProfile')
  const { editing } = useEditingStateWithCtx('trafficProfile')

  return {
    editing,
    selection,
    listStructure,
  }
}
