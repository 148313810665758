import { ReactElement } from 'react'

import { CircularButton, FlexRow, FlexColumn, TextInput } from '@/forms-legacy'
import { Cross } from '@/icons'

import { BarcodeRowProps as Props } from './typings'

const BarcodeRow = (props: Props): ReactElement => {
  const {
    barcode,
    onRemove,
    onUpdate,
    fieldProps: { invalidValues, readonlyReason },
    readonly,
  } = props

  const title = readonly ? readonlyReason : ''

  return (
    <FlexRow alignItems="flex-start" style={{ marginTop: 10 }} title={title}>
      <FlexColumn style={{ marginRight: 6 }}>
        <CircularButton
          Icon={<Cross />}
          onClick={onRemove}
          style={{ marginTop: 7 }}
          disabled={readonly}
        />
      </FlexColumn>
      <FlexColumn primary>
        <TextInput
          hasError={invalidValues.has(barcode)}
          name="barcode"
          value={barcode}
          onChange={onUpdate}
          disabled={readonly}
        />
      </FlexColumn>
    </FlexRow>
  )
}

export default BarcodeRow
