// import type { FallbackRender } from '@sentry/react'
import type { ReactElement } from 'react'

import * as Sentry from '@sentry/react'

import { StyledEngineProvider } from '@mui/material'
import { TidalThemeProvider } from '@workwave-tidal/tidal/styles'

import { GenericErrorPage } from '@/sections'

import { MuiTheme } from '@/styles'

type Props = {
  children: ReactElement
}

export function ErrorBoundary(props: Props) {
  const { children } = props

  return (
    <Sentry.ErrorBoundary
      fallback={fallBackProps => {
        const {
          error,
          componentStack,
          // resetError,
        } = fallBackProps

        if (error instanceof Error) {
          console.log(error.toString(), componentStack)
        } else {
          console.log(
            `An error has been caught by Sentry.ErrorBoundary but is not an istance of Error`,
            error,
          )
        }

        return (
          <StyledEngineProvider injectFirst>
            <TidalThemeProvider theme={MuiTheme}>
              <GenericErrorPage />
            </TidalThemeProvider>
          </StyledEngineProvider>
        )
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
