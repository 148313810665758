import type { Props } from './typings'
import styled from 'styled-components'

export const HeaderLinkWrapper = styled.span<Props>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  color: ${p => p.theme.colors.$pureWhite};
  opacity: ${p => (p.disabled ? 0.2 : 1)};
  padding: 0 10px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: currentColor;
    transform: scale(0, 1);
    transform-origin: left center;
    transition: transform 0.3s ease;
  }

  .active &::after {
    transform: scale(1, 1);
  }
`

HeaderLinkWrapper.displayName = 'HeaderLinkWrapper'
