import type { PopupPlugin } from '../types'
import { journal } from '@/server-data'

/**
 * Log the different popup states
 */
export function createPopupLoggerPlugin() {
  const plugin: PopupPlugin = {
    onOpen: ({ id }) => {
      journal.main('Popup opened', { info: { id } })
    },

    onLoad: ({ id }) => {
      journal.main('Popup loaded', { info: { id } })
    },

    onClose: ({ id }) => {
      journal.main('Popup closed', { info: { id } })
    },

    onError: ({ errorType, id }) => {
      journal.main('Popup error', { info: { id, errorType } }, 'error')
    },
  }

  return plugin
}
