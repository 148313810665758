import type { FormErrors, FormFields } from '../formFields'

import { useEffect, useRef } from 'react'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'

export function useNotificationWay() {
  const {
    formApi,
    field: { value: notificationWay },
  } = useFormField<'notificationWay', FormFields, FormErrors>('notificationWay', {
    fieldsOfInterest: ['value'],
  })

  const firstUpdate = useRef(true)

  useEffect(() => {
    /**
     * Prevent any update during the first execution to not trigger changes to the form state that
     * will bring the status to indeterminate (due to a change of required fields)
     */
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    // SUBJECT must be visible and required for EMAIL notifications
    formApi.setFieldVisibleState('subjectCustom', notificationWay === 'EMAIL')
    formApi.setFieldRequiredState('subjectCustom', notificationWay === 'EMAIL')

    // EMAIL BACKUP must be invisible for EMAIL notifications
    formApi.setFieldVisibleState('backupNotification', notificationWay === 'PHONE')
  }, [formApi, notificationWay])
}
