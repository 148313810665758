import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { deleteAlerts } from '@/features/domain/alert'
import { useNotification } from '@/hooks'
import { useEditingStateWithCtx } from '@/atoms'

export function useActions(id: string) {
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const { setEditing } = useEditingStateWithCtx('alert')

  const onDeleteAlert = useCallback(async () => {
    try {
      const result = await dispatch(deleteAlerts([id]))

      if (deleteAlerts.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, toast, id])

  const onStartEdit = useCallback(() => {
    setEditing([id])
  }, [setEditing, id])

  return {
    onDeleteAlert,
    onStartEdit,
  }
}
