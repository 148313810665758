import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function OrderEvent(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path
          d="m3,8c0,-2.20914 1.79086,-4 4,-4l18,0c2.2091,0 4,1.79086 4,4l0,16c0,2.2091 -1.7909,4 -4,4l-18,0c-2.20914,0 -4,-1.7909 -4,-4l0,-16z"
          fill="black"
          fillOpacity="0.87"
        />
      </svg>
    </IconContainer>
  )
}
