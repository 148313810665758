import { ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useEnvironmentURLs } from '@/atoms'
import { conversionUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'
import { OnRemovePOD, FlexRow, PodListField } from '@/forms-legacy'

import ReadonlyImage from '../../../../../../components/Pods/elements/ReadonlyImage'

import * as OrderTexts from '../../../intl'

import { Props } from '../typings'

import DeleteBtn from './DeleteBtn'
import NoneLabel from './NoneLabel'

type PodPictureProps = {
  onRemove: OnRemovePOD
  picture: uui.domain.client.rm.MediaPod
  index: number
  userConfig: uui.domain.UserConfiguration
} & {
  onTogglePod: Props['onTogglePod']
  activePodMarker: Props['activePodMarker']
}

function PodPicture(props: PodPictureProps) {
  const { picture, onTogglePod, onRemove, index, userConfig, activePodMarker } = props
  const { sec, text, token, latLng } = picture

  const { podsBaseUrl } = useEnvironmentURLs()

  const time = conversionUtils.convertTime(userConfig)(sec)
  const title = OrderTexts.getCustomerSignatureLabel()
  const src = `${podsBaseUrl}${token}`

  return (
    <FlexRow key={`pod_picture_${index}`}>
      <DeleteBtn onClick={() => onRemove(picture.uuid)} style={{ marginRight: 10 }} />
      <ReadonlyImage
        uuid={picture.uuid}
        onToggle={onTogglePod}
        src={src}
        title={title}
        text={text || ''}
        time={time}
        latLng={latLng || undefined}
        layout={'thumbnail'}
        selected={activePodMarker?.uid === picture.uuid}
        fitImageToContainer
      />
    </FlexRow>
  )
}

export function PodPictures(props: Props) {
  const { activePodMarker, onTogglePod } = props
  const userConfig = useSelector(selectUserConfiguration)

  const renderItems = useMemo(
    () =>
      (values: uui.domain.client.rm.Pod[], onRemove: OnRemovePOD): ReactNode => {
        return (
          <>
            {values.length > 0 &&
              values.map((picture, index) => (
                <PodPicture
                  picture={picture as uui.domain.client.rm.MediaPod}
                  index={index}
                  activePodMarker={activePodMarker}
                  onTogglePod={onTogglePod}
                  userConfig={userConfig}
                  onRemove={onRemove}
                  key={index}
                />
              ))}
            {values.length === 0 && (
              <NoneLabel style={{ marginLeft: 10 }}>{OrderTexts.getNoneText()}</NoneLabel>
            )}
          </>
        )
      },
    [activePodMarker, onTogglePod, userConfig],
  )

  return (
    <PodListField
      name="formPods.pictures"
      label={OrderTexts.getPicturesLabel()}
      renderItems={renderItems}
    />
  )
}
