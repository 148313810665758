import { Info } from '@/icons'
import { WarningCard } from '@/local/components'

import { useTexts } from './useTexts'

interface Props {
  email: string
  date: string
}

export function UnsubscribeDataEmail(props: Props) {
  const { date, email } = props
  const texts = useTexts()

  return (
    <WarningCard
      preset="info"
      title={texts.unsubscribeEmailTitle}
      Icon={<Info size={14} color="$sailBlue" />}
      description={texts.unsubscribeEmailDescription(email, date)}
    />
  )
}
