import { IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'

interface Props {
  onEdit: () => void
  disabled?: boolean
  testId?: string
}

export function EditButton(props: Props) {
  const { onEdit, disabled, testId = 'edit-button' } = props

  return (
    <IconButton onClick={onEdit} disabled={disabled} data-testid={testId}>
      <Edit />
    </IconButton>
  )
}
