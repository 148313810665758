import type { Components } from '@mui/material'
import { theme } from '@/styles'

export const MuiAccordionSummary: Components['MuiAccordionDetails'] = {
  styleOverrides: {
    root: {
      padding: 0,
      minHeight: 44,
      transition: 'none',
      borderRadius: '3px',

      '&.Mui-expanded': {
        minHeight: 44,
      },
      '& > .MuiAccordionSummary-content': {
        minHeight: 44,
        margin: 0,
      },
      '& > .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
      },
      '& > .MuiAccordionSummary-expandIconWrapper': {
        marginRight: 0,
        padding: 12,
      },

      '&:hover': {
        background: theme.colors.$whiteSmoke,
      },
    },
  },
}
