import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { useReadOnly, useFormTransaction, useStopEditingOnUnmount } from '@/hooks'
import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { useResetEditingState, useEnvironmentQa } from '@/atoms'

import { FormLoading } from '@/components/FormLoading'

import { useFormInitialValue } from './hooks/useFormInitialValue'

import { useOnSubmit } from './hooks/useOnSubmit'
import { useComposeFieldsWithError } from './hooks/useComposeFieldsWithError'
import { useTexts } from './hooks/useTexts'
import { Footer } from './components/Footer'
import { useRetrieveUnifiedVehicleFromDevice } from './hooks/useRetrieveUnifiedVehicleFromDevice'
import { validate } from './validate'

import { Form } from './Form'
interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
}

export function SingleEdit(props: Props) {
  const { device } = props

  const unifiedVehicle = useRetrieveUnifiedVehicleFromDevice(device.deviceId)
  const initialValues = useFormInitialValue(unifiedVehicle)

  const { readonly } = useReadOnly()
  const onSubmit = useOnSubmit(unifiedVehicle)
  const onCancel = useResetEditingState()
  const transactionState = useFormTransaction()
  const transactionOpen = transactionState === 'ready'

  const qa = useEnvironmentQa()

  const composeFieldsWithError = useComposeFieldsWithError()
  const texts = useTexts()

  useStopEditingOnUnmount()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <FinalForm<uui.domain.ui.forms.GpsVehicleFormValues>
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        render={(formRenderProps: FormRenderProps<uui.domain.ui.forms.GpsVehicleFormValues>) => {
          const { handleSubmit, submitting } = formRenderProps

          const {
            form: { getState },
          } = formRenderProps
          const formState = getState()

          const { valid, pristine, errors, values } = formState
          const saveDisabled = pristine || !valid || readonly

          const fieldsWithError = composeFieldsWithError(
            errors as uui.domain.ui.forms.GpsVehicleFormValidationValues,
          )

          return (
            <>
              <SecondaryColumnHeader
                title={texts.headerTitle}
                description={texts.headerDescription(device.deviceId)}
                avatar={null}
              />
              <FormContent
                qa={qa}
                testId="devicesSingleEdit"
                footer={
                  transactionOpen ? (
                    <Footer
                      fieldsWithError={fieldsWithError}
                      handleSubmit={handleSubmit}
                      saveDisabled={saveDisabled}
                      readonly={readonly}
                      submitting={submitting}
                      onCancel={onCancel}
                      pristine={pristine}
                      creating={false}
                      valid={valid}
                      texts={texts}
                    />
                  ) : undefined
                }
              >
                {transactionOpen ? (
                  <Form unifiedVehicle={unifiedVehicle} values={values} />
                ) : (
                  <FormLoading />
                )}
              </FormContent>
            </>
          )
        }}
      />
    </FormColumn>
  )
}
