import { useEffect, useLayoutEffect, useRef } from 'react'

import { subscribeToEditableArea, updateEditableArea } from '@/map'

export function useConnectDrawAreaField(
  changeValue: (value: uui.domain.LatLng[]) => void,
  areaId: uui.domain.ui.map.EditableArea['id'],
  areaColor: string,
  areaInitialPoints?: uui.domain.LatLng[],
) {
  const rData = useRef({ areaId, areaColor, areaInitialPoints, changeValue })
  useLayoutEffect(
    () => void (rData.current = { areaId, areaColor, areaInitialPoints, changeValue }),
  )

  // INIT Edit/creation session
  useEffect(() => {
    const { areaId, areaColor, areaInitialPoints } = rData.current

    if (areaInitialPoints && areaInitialPoints?.length > 0) {
      // AREA EDIT
      updateEditableArea(() => ({
        id: areaId,
        mode: 'edit',
        color: areaColor,
        points: areaInitialPoints,
      }))
    } else {
      // AREA CREATION
      updateEditableArea(() => ({ id: areaId, mode: 'create', color: areaColor }))
    }

    return () => {
      // RESET on unmount
      updateEditableArea(() => ({ id: 'none', mode: 'none', color: '#303030' }))
    }
  }, [])

  // UPDATE ATOM SHAPE COLOR
  // Form event affecting the ATOM
  useEffect(() => {
    updateEditableArea({ color: areaColor })
  }, [areaColor])

  // UPDATE STORED POINTS
  // Atom event affecting the Form
  useEffect(() => {
    const { areaInitialPoints, changeValue } = rData.current
    let prevPoints = areaInitialPoints

    subscribeToEditableArea(area => {
      if (area.mode === 'edit') {
        if (prevPoints === area.points) return

        prevPoints = [...area.points]
        changeValue(prevPoints)
      }
    }, false)
  }, [])
}
