import { Component, ReactNode } from 'react'

import { ButtonDelete, TimeWindowException } from '@/forms-legacy'
import { Cross } from '@/icons'
import { clsx } from '@/utils'

import {
  Callback,
  DeleteCallback,
  TimeWindowException as TimeWindowExceptionType,
} from '../TimeWindowExceptionList'

export type Props = {
  id: number
  action: DeleteCallback
  onChange: Callback
  className?: string
  item: TimeWindowExceptionType
  renderError?: (date: string | Date, index: number) => ReactNode
  renderDateError?: (date: string | Date) => ReactNode
  h24?: boolean
}

export default class TimeWindowExceptionListItem extends Component<Props> {
  private handleChange = (date: Date, list: uui.domain.rm.TimeWindow[]): void => {
    const { onChange, id } = this.props

    const timeWindowExceptionType: TimeWindowExceptionType = {
      date,
      list,
    }
    onChange(timeWindowExceptionType, id)
  }

  private handleDelete = () => {
    const { action, id } = this.props
    action(id)
  }

  render() {
    const { className = '', item, renderDateError, renderError, h24 } = this.props
    const { date, list } = item

    const rootClassName = clsx({
      [className]: true,
      'o-time-window-exception-list-item': true,
    })

    return (
      <div className={rootClassName}>
        <ButtonDelete
          onClick={this.handleDelete}
          className="o-time-window-list-item__button"
          data-testid="time-window-exception-list-item__delete-button"
        >
          <Cross />
        </ButtonDelete>
        <TimeWindowException
          className="o-time-window-exception-list-item__item"
          date={date}
          timeWindowList={list}
          onChange={this.handleChange}
          renderError={renderError}
          renderDateError={renderDateError}
          h24={h24}
        />
      </div>
    )
  }
}
