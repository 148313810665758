import type { FormErrors, FormFields } from '../../../formFields'
import type { NotificationFormType } from '@/notificationForms'

import { HotwordsField } from '../../../../HotwordsField'
import { useTexts } from '../../../useTexts'
import { useMessageSettings } from './hooks/useMessageSettings'

interface Props {
  notificationWay: uui.domain.client.rm.NotificationWay
  notification: NotificationFormType
}

export function Message(props: Props) {
  const { notificationWay, notification } = props

  const {
    includeMessageUnsubscriptionInstructions,
    includeMessageTrackingLink,
    notificationLimits,
    notificationType,
  } = useMessageSettings(notification)
  const texts = useTexts()

  const label = notificationWay === 'PHONE' ? texts.smsMessageLabel : texts.emailMessageLabel

  return (
    <HotwordsField<'templateCustom', FormFields, FormErrors>
      includeUnsubscriptionInstruction={includeMessageUnsubscriptionInstructions}
      includeTrackingLink={includeMessageTrackingLink}
      notificationLimits={notificationLimits}
      notificationType={notificationType}
      name="templateCustom"
      testId="templateCustom"
      label={label}
    />
  )
}
