import { useSelector } from 'react-redux'

import { selectPhysicalDevices } from '@/features/domain/device'
import { isGpsOnly, isRm as selectIsRm, selectUserConfiguration } from '@/features/domain/user'

export const usePermissions = () => {
  const isRm = useSelector(selectIsRm)
  const gpsOnlyUser = useSelector(isGpsOnly)
  const physicalDevices = useSelector(selectPhysicalDevices)

  const { planType, userType } = useSelector(selectUserConfiguration)

  const isSimulation = planType === 'simulation'
  const viewerUser = userType === 'rmViewer' || userType === 'courier'
  const guestUser = userType === 'rmGuest'

  const isRmWithPhysicalDevices = isRm && physicalDevices.length > 0

  return {
    showHelp: true,
    showSetup: !guestUser,
    showDevTools: !gpsOnlyUser && !guestUser,
    showDashboard: !gpsOnlyUser && !guestUser && !viewerUser,
    showLayoutMenu: !gpsOnlyUser && !guestUser,
    showUserConsole:
      (!isSimulation && !guestUser && !viewerUser && isRmWithPhysicalDevices) || gpsOnlyUser,
  }
}
