const DistanceFormat = {
  METRIC: 'METRIC',
  IMPERIAL: 'IMPERIAL',
} as const

const TimeFormat: Record<uui.domain.server.TimeFormat, uui.domain.client.TimeFormat> = {
  H12: 'hh:mm aa',
  H24: 'HH:mm',
} as const

const LONG_DATE_MAP = {
  MDY: 'MM/dd/yyyy',
  DMY: 'dd/MM/yyyy',
  YMD: 'yyyy/MM/dd',
  MMMDY: 'MMM dd, yyyy',
  DMMMY: 'dd MMM yyyy',
} as const

const SHORT_DATE_MAP = {
  MDY: 'MMM dd',
  DMY: 'dd MMM',
  YMD: 'MMM dd',
  MMMDY: 'MMM dd',
  DMMMY: 'dd MMM',
} as const

const SHORT_DATE_MAP_WITH_EXT_MONTH = {
  MDY: 'MMMM dd',
  DMY: 'dd MMMM',
  YMD: 'MMMM dd',
  MMMDY: 'MMMM dd',
  DMMMY: 'dd MMMM',
} as const

const DATE_PARSING_MAP = {
  MDY: ['MM/dd/yyyy', 'MMM D', 'MMM D yyyy', 'yyyyMMdd', 'yyyy/M/D', 'M/D/yyyy', 'M/D/YY'],
  DMY: ['dd/MM/yyyy', 'MMM D', 'MMM D yyyy', 'yyyyMMdd', 'yyyy/M/D', 'D/M/yyyy', 'D/M/YY'],
  YMD: ['MM/dd/yyyy', 'MMM D', 'MMM D yyyy', 'yyyyMMdd', 'yyyy/M/D', 'M/D/yyyy', 'M/D/YY'],
  MMMDY: ['MM/dd/yyyy', 'MMM D', 'MMM D yyyy', 'yyyyMMdd', 'yyyy/M/D', 'M/D/yyyy', 'M/D/YY'],
  DMMMY: ['dd/MM/yyyy', 'MMM D', 'MMM D yyyy', 'yyyyMMdd', 'yyyy/M/D', 'D/M/yyyy', 'D/M/YY'],
}

export function convertAccountDateFormatLong(format: uui.domain.DateFormat) {
  return LONG_DATE_MAP[format] ?? LONG_DATE_MAP.MDY
}

export function convertAccountDateFormatShort(format: uui.domain.DateFormat) {
  return SHORT_DATE_MAP[format] ?? SHORT_DATE_MAP.MDY
}

export function convertAccountDateParsingFormat(format: uui.domain.DateFormat) {
  return DATE_PARSING_MAP[format] ?? DATE_PARSING_MAP.MDY
}

export function convertAccountDateFormatShortWithExtendedMonth(format: uui.domain.DateFormat) {
  return SHORT_DATE_MAP_WITH_EXT_MONTH[format] ?? SHORT_DATE_MAP_WITH_EXT_MONTH.MDY
}

export function convertAccountDistanceFormat(format: uui.domain.DistanceFormat) {
  return DistanceFormat[format] ?? DistanceFormat.METRIC
}

export function convertServerTimeFormat(format: uui.domain.server.TimeFormat) {
  return TimeFormat[format] ?? TimeFormat.H24
}

export function convertToServerTimeFormat(format: uui.domain.client.TimeFormat) {
  return format === TimeFormat.H12 ? 'H12' : 'H24'
}
