import type { PlaceFormValues } from '../types'

export function computeFormInitialValues(
  place: uui.domain.client.gps.wwgps.Place,
): PlaceFormValues {
  return {
    id: place.id,
    name: place.name,
    location: place.location,
  }
}
