import type { FormErrors, FormFields } from '../formFields'
import { Box } from '@mui/material'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { Checkbox } from '@workwave-tidal/tidal/form-ui'
import { useTexts } from '../hooks/useTexts'
import { OrdersType } from './OrdersType'

export function SignatureField() {
  const texts = useTexts()
  const { formApi } = useFormField<'includeSignature', FormFields, FormErrors>('includeSignature')
  const {
    field: { value: isChecked },
  } = useFormField<'includeSignatureChecked', FormFields, FormErrors>('includeSignatureChecked')
  const { submitting, disabled } = formApi.getMeta()
  const formDisabled = submitting || disabled

  return (
    <>
      <Checkbox<'includeSignatureChecked', FormFields, FormErrors>
        name="includeSignatureChecked"
        label={texts.signature}
        testId="driver-assistance-form-signature-field"
        disabled={formDisabled}
        validateOn="blur change"
      />
      {isChecked && (
        <Box marginLeft={2}>
          <OrdersType name="includeSignature" disabled={formDisabled} />
        </Box>
      )}
    </>
  )
}
