import type { FormFields, FormErrors } from '../../formFields'

import { TextField } from '@workwave-tidal/tidal/form-ui'

import { useTexts } from './hooks/useTexts'

export function Message() {
  const texts = useTexts()

  return (
    <TextField<'message', FormFields, FormErrors>
      label={texts.label}
      textfieldProps={{
        InputProps: {
          multiline: true,
          minRows: 5,
          maxRows: 10,
        },
      }}
      name="message"
      validateOn="blur focus"
    />
  )
}
