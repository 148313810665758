import { RenderFrom } from '../../../../../../components/RenderFrom'
import { PodBlock } from './PodBlock'
import { TrackDataBlock } from './TrackDataBlock'
import { StatusIndicator } from './StatusIndicator'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
  containerWidth: number
}

const statusIndicatorWidth = 27
const trackDataBlockWidth = 80

const statusIndicatorRequiredWidth = statusIndicatorWidth
const trackDataBlockRequiredWidth = statusIndicatorRequiredWidth + trackDataBlockWidth

export function ExecutedBlock(props: Props) {
  const {
    containerWidth,
    extOrder: {
      id,
      orderStep: { trackingData },
    },
  } = props

  return (
    <>
      <RenderFrom width={containerWidth} renderFrom={statusIndicatorRequiredWidth}>
        <StatusIndicator status={trackingData.status} />
      </RenderFrom>

      <RenderFrom width={containerWidth} renderFrom={trackDataBlockRequiredWidth}>
        <TrackDataBlock trackingData={trackingData} />
      </RenderFrom>

      <PodBlock id={id} pods={trackingData.pods} />
    </>
  )
}
