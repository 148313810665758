import type { HotwordId } from '../types'
import type {
  FormError,
  FormFieldApi,
  FormValidator,
  FormSingleField,
  NarrowFieldsByValueType,
} from '@workwave-tidal/tidal/form-fairy'
import type { Notifications } from '@/notificationForms'

import { useEffect } from 'react'

import { computeMatches } from '../utils'
import { useConfigureHotwordsByNotification } from './useConfigureHotwordsByNotification'
import { useTexts } from './useTexts'

type RequiredFormField = string
type PartialForm = Record<string, FormSingleField<RequiredFormField>>

export function useValidation<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends PartialForm,
  ERRORS extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
>(
  notificationType: Notifications,
  fieldName: FIELD_NAME,
  fieldApi: FormFieldApi<FIELD_NAME, FIELDS, ERRORS>,
) {
  const texts = useTexts()
  const { hotwordIds } = useConfigureHotwordsByNotification(notificationType)

  useEffect(() => {
    const validationName = `${fieldName}-validation`
    fieldApi.addValidation(validationName, validateHotwords(fieldName, hotwordIds, texts))

    return () => {
      fieldApi.removeValidation(validationName)
    }
  }, [fieldApi, texts, hotwordIds, fieldName])
}

function validateHotwords<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormSingleField>,
  ERRORS extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
>(
  fieldName: FIELD_NAME,
  hotwordIds: HotwordId[],
  texts: ReturnType<typeof useTexts>,
): FormValidator<FIELDS, ERRORS> {
  return formApi => {
    const field = formApi.getField(fieldName) as FormSingleField<RequiredFormField>
    const invalidHotword = getInvalidHotwords(field.value, hotwordIds)

    const validationRequiredId = `${fieldName}-validation-required`
    const validationInvalidId = `${fieldName}-validation-invalid`

    const errorForRequired: FormError<keyof FIELDS> = {
      id: validationRequiredId,
      field: fieldName,
      message: texts.required,
      priority: 10,
    }

    const errorForInvalidHotword: FormError<keyof FIELDS> = {
      id: validationInvalidId,
      field: fieldName,
      message: texts.invalidHotword(invalidHotword),
      priority: 9,
    }

    const invalidForRequired = field.value.trim().length === 0
    const invalidForInvalidHotword = invalidHotword.length > 0

    return {
      [validationRequiredId]: invalidForRequired ? errorForRequired : null,
      [validationInvalidId]: invalidForInvalidHotword ? errorForInvalidHotword : null,
    }
  }
}

function getInvalidHotwords(text: string, hotwordIds: HotwordId[]) {
  const hotwordsRegexp = new RegExp(/@@(.*?)@@/gm)
  const matches = computeMatches(text, hotwordsRegexp)

  for (const match of matches) {
    if (!(hotwordIds as string[]).includes(match)) {
      return `@@${match ?? ''}@@`
    }
  }

  return ''
}
