import { FlexBox } from '@/local/components'
import { Offline } from '@/icons'

import { Container } from '../../elements/Container'
import { useTexts } from './useTexts'

export function FooterOffline() {
  const texts = useTexts()
  return (
    <Container color="$pureWhite" background="$outrageousRed">
      <FlexBox vAlignContent="center" grow={0} shrink={0} zIndex={10}>
        <Offline size={16} color="$pureWhite" marginRight={14} />

        {texts.offlineAlert}
      </FlexBox>
    </Container>
  )
}
