import type { FormErrors, FormFields } from '../../formFields'

import { useMemo } from 'react'

import { RadioGroup } from '@workwave-tidal/tidal/form-ui'

import { distanceFormats } from '../../../../formats'
import { useTexts } from './useTexts'

export function DistanceFormat() {
  const texts = useTexts()

  const options = useMemo(
    () =>
      Object.entries(distanceFormats).map(([value, label]) => ({
        value,
        label,
      })),
    [],
  )

  return (
    <RadioGroup<'distanceFormat', string, FormFields, FormErrors>
      validateOn="blur focus"
      options={options}
      testId="distanceFormat"
      label={texts.distanceFormat}
      name="distanceFormat"
    />
  )
}
