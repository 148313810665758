import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { proxy, snapshot, useSnapshot } from 'valtio'

import { selectVehicles } from '@/features/domain/vehicle'
import { useCrudSelection } from './selection/crud'

interface VehicleFormException {
  exception: string
  editMode: 'create' | 'edit'
}
interface VehicleFormExceptionAtom {
  settings: VehicleFormException
}

// ------------------------------------
// Default values
// ------------------------------------
function getInitialVehicleFormException() {
  return { exception: 'any', editMode: 'edit' } as VehicleFormException
}

// ------------------------------------
// Exception settings atom
// ------------------------------------

const createDefaultVehicleFormException = (): VehicleFormExceptionAtom => ({
  settings: getInitialVehicleFormException(),
})

export const vehicleFormExceptionAtom = proxy<VehicleFormExceptionAtom>(
  createDefaultVehicleFormException(),
)

// ------------------------------------
// Write functions
// ------------------------------------

type SetVehicleFormException = (prev: VehicleFormException) => VehicleFormException
type SetVehicleFormExceptionParam =
  | SetVehicleFormException
  | Partial<VehicleFormException>
  | 'reset'

export function resetVehicleFormException() {
  vehicleFormExceptionAtom.settings = getInitialVehicleFormException()
}

export function setVehicleFormException(valueOrFunc: SetVehicleFormExceptionParam) {
  if (valueOrFunc === 'reset') resetVehicleFormException()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(vehicleFormExceptionAtom.settings, valueOrFunc(vehicleFormExceptionAtom.settings))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      vehicleFormExceptionAtom.settings[field] = valueOrFunc[field]
    }
  }

  return vehicleFormExceptionAtom
}

// ------------------------------------
// Read functions
// ------------------------------------

export const getVehicleFormException = (immutable: boolean = true) => {
  return immutable ? snapshot(vehicleFormExceptionAtom.settings) : vehicleFormExceptionAtom.settings
}

// ------------------------------------
// React Hooks
// ------------------------------------

export function useVehicleFormException() {
  return [useSnapshot(vehicleFormExceptionAtom).settings, setVehicleFormException] as const
}

/**
 * That hook ensures that the exception selection will be reset if the exception is not more
 * available in the global state or if the vehicle selection changes
 */
export function useResetVehicleFormException() {
  const [selectedVehicles] = useCrudSelection('unifiedVehicles')
  const vehicles = useSelector(selectVehicles)
  const firstRun = useRef(true)

  // If the selection changes reset the atom
  useEffect(() => {
    // ------------------------------------------------------------
    // !!! That effect should not be executed at the first load !!!
    // ------------------------------------------------------------
    if (firstRun.current) {
      firstRun.current = false
      return
    }
    setVehicleFormException('reset')
  }, [selectedVehicles])

  // If the exception is no more available reset the atom
  useEffect(() => {
    // ------------------------------------------------------------
    // !!! That effect should not be executed at the first load !!!
    // ------------------------------------------------------------
    if (firstRun.current) {
      firstRun.current = false
      return
    }

    const selectedVehicle = vehicles[selectedVehicles[0]]

    if (
      selectedVehicle?.hasRoutingLicense &&
      selectedVehicle.vehicle.settings[vehicleFormExceptionAtom.settings.exception]
    ) {
      return
    }

    setVehicleFormException('reset')
  }, [vehicles, selectedVehicles])
}
