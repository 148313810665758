import { type SchedulerEventModel } from '@bryntum/schedulerpro'

import { RouteStepTooltip } from './components/RouteStepTooltip'
import { SetupTimeTooltip } from './components/SetupTimeTooltip'
import { BreakStepTooltip } from './components/BreakStepTooltip'
import { IdleTimeTooltip } from './components/IdleTimeTooltip'

interface Props {
  eventRecord: SchedulerEventModel
  userConfig: uui.domain.UserConfiguration
}

export function EventTooltip(props: Props) {
  const { eventRecord, userConfig } = props

  const type = eventRecord.getData('type') as uui.domain.client.rm.SchedulerEvent['type']

  switch (type) {
    case 'pickup':
    case 'delivery':
      return <RouteStepTooltip eventRecord={eventRecord} userConfig={userConfig} />

    case 'preparation':
    case 'closeOut':
      return <SetupTimeTooltip eventRecord={eventRecord} userConfig={userConfig} />

    case 'brk':
      return <BreakStepTooltip eventRecord={eventRecord} userConfig={userConfig} />

    case 'idle-time':
      return <IdleTimeTooltip eventRecord={eventRecord} userConfig={userConfig} />

    default:
      return null
  }
}
