import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    mode: translate({ id: 'setup.driverAssistance.requires.assistanceMode' }),
    description: translate({ id: 'setup.driverAssistance.requires.assistanceModeDescriptions' }),
    off: translate({ id: 'setup.driverAssistance.view.mode.off' }),
    suggest: translate({ id: 'setup.driverAssistance.view.mode.suggest' }),
    require: translate({ id: 'setup.driverAssistance.view.mode.require' }),
    save: translate({ id: 'setup.driverAssistance.save' }),
    cancel: translate({ id: 'setup.driverAssistance.cancel' }),
    checkin: translate({ id: 'setup.driverAssistance.suggest.checkIn' }),
    checkout: translate({ id: 'setup.driverAssistance.suggest.checkOut' }),
    picture: translate({ id: 'setup.driverAssistance.fields.picture' }),
    completed: translate({ id: 'setup.driverAssistance.requires.REQUIRED_TO_COMPLETE' }),
    notCompleted: translate({ id: 'setup.driverAssistance.requires.REQUIRED_TO_NOT_COMPLETE' }),
    all: translate({ id: 'setup.driverAssistance.requires.ALWAYS_REQUIRED' }),
    signature: translate({ id: 'setup.driverAssistance.fields.signature' }),
    audio: translate({ id: 'setup.driverAssistance.fields.audio' }),
    note: translate({ id: 'setup.driverAssistance.fields.note' }),
    status: translate({ id: 'setup.driverAssistance.fields.status' }),
    helperText: translate({ id: 'setup.driverAssistance.fields.checkInOutHelperText' }),
    title: translate({ id: 'setup.DriverAssistance.view.title' }),
    headerDescription: translate({ id: 'setup.driverAssistance.view.description' }),
  }))

  return api
}
