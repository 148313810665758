import type { OrderSteps, PairedOrderSteps } from '../typings'

import { useCallback, useEffect, useState } from 'react'

import { useReadOnly } from '@/hooks'
import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

import { useSelectedOrderSteps } from './useSelectedOrderSteps'
import { useAutoSubmit } from './useAutoSubmit'

export type ModalMode = 'empty' | 'single' | 'bulk'
type ModalState = 'ready' | 'pending' | 'invalidForEmptyOrders'
interface ModalData {
  mode: ModalMode
  cloneAlsoPairedStep: boolean
  selectedOrderSteps: OrderSteps
  pairedOrderSteps: PairedOrderSteps
}

export const modalId = 'duplicateOrder'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = () => {
  const close = useCloseModal()
  const { readonly } = useReadOnly()
  const { selectedOrderSteps, pairedOrderSteps } = useSelectedOrderSteps()
  const { ids } = selectedOrderSteps

  const getModalMode = useCallback((): ModalMode => {
    if (ids.length > 1) return 'bulk'
    if (ids.length > 0) return 'single'
    return 'empty'
  }, [ids.length])

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        pairedOrderSteps,
        selectedOrderSteps,
        mode: getModalMode(),
        cloneAlsoPairedStep: true,
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const {
    data: { mode },
    status,
    update,
    updateData,
  } = ctrl

  // EFFECTS

  // External transaction
  useEffect(() => {
    switch (readonly) {
      case true:
        update({ status: 'pending' })
        break

      case false:
        if (status !== 'pending') return

        update({ status: 'ready' })
        break
    }
  }, [readonly, status, update])

  // Invalid state management
  useEffect(() => {
    updateData({ mode: getModalMode() })
  }, [getModalMode, updateData])

  // Invalid state management
  useEffect(() => {
    if (mode === 'empty') {
      update({ invalid: true, status: 'invalidForEmptyOrders' })
      return
    }

    update({ invalid: false, status: 'ready' })
  }, [mode, update])

  /**
   * If the user selected one or more orders without paireds or, in the selection,
   * all paireds are already present, the modal should submit automatically and not appear.
   */
  useAutoSubmit()

  return ctrl
}
