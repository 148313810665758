import type { FormProps } from '../types'

import { NotificationForm } from '../../../../../../notificationForms/sections/notifications/components/NotificationForm'
import { NotificationView } from '../../../../../../notificationForms/sections/notifications/components/NotificationView'

import { useFormStateController } from '../../hooks/useFormStateController'
import { TomorrowNotificationView } from './components/TomorrowNotificationView'

import { useTomorrowNotificationInitForm } from './hooks/useTomorrowNotificationInitForm'
import { useTomorrowNotificationSubmit } from './hooks/useTomorrowNotificationSubmit'
import { useTomorrowNotificationConfig } from './hooks/useTomorrowNotificationConfig'
import { useTomorrowNotificationValues } from './hooks/useTomorrowNotificationValues'
import { useTomorrowNotificationToggle } from './hooks/useTomorrowNotificationToggle'
import { useTexts } from './useTexts'

/**
 * Renders the Tomorrow notification form providing all the domain data and apis.
 */
export function TomorrowNotification(props: FormProps) {
  const { inEditing, setEditingState } = props

  const values = useTomorrowNotificationValues()

  const { toggleDescription, onToggle } = useTomorrowNotificationToggle(values)
  const { edit, cancel } = useFormStateController(setEditingState)
  const onSubmit = useTomorrowNotificationSubmit()
  const config = useTomorrowNotificationConfig()
  const texts = useTexts()

  // Render the Form inside a FormRoot component that will expose the FormState API through a React.Context
  if (inEditing) {
    return (
      <NotificationForm
        initHook={useTomorrowNotificationInitForm}
        subtitle={texts.description}
        notification="tomorrow"
        onSubmit={onSubmit}
        title={texts.title}
        cancel={cancel}
        values={values}
        config={config}
      />
    )
  }

  return (
    <NotificationView
      Form={<TomorrowNotificationView values={values} />}
      toggleSubtitle={toggleDescription}
      subtitle={texts.description}
      notification="tomorrow"
      onToggle={onToggle}
      title={texts.title}
      values={values}
      onEdit={edit}
    />
  )
}
