import { format } from 'date-fns/esm'

import { Calendar } from '@/components/Calendar'
import { useUpdateOrderCountsByDate } from '@/hooks'

import { useTexts } from '../useTexts'

import { useArchivedCalendarData } from './hooks/useArchivedCalendarData'
import { useOnCalendarChange } from './hooks/useOnCalendarChange'

type Props = {
  selection: Date
  today: Date
  onChange: (date: Date) => void
  firstDayNotArchived: Date
}

export function ArchivedCalendar(props: Props) {
  const { selection, firstDayNotArchived, today, onChange } = props

  const texts = useTexts()

  // Updates order counts at every open
  useUpdateOrderCountsByDate()

  const onCalendarChange = useOnCalendarChange(onChange)
  const { calendarData, todayAsString, daysWithOrders } = useArchivedCalendarData(
    today,
    firstDayNotArchived,
  )

  return (
    <Calendar
      months={1}
      layout="date"
      selection={selection}
      today={todayAsString}
      onChange={onCalendarChange}
      minDate={calendarData.minDate}
      maxDate={calendarData.maxDate}
      highlightedDays={daysWithOrders}
      tooltipIntlText={texts.ordersTooltip}
      disabledDays={calendarData.disabledDays}
      initialVisibleDate={format(selection, 'yyyyMMdd')}
    />
  )
}
