import { CircularProgress } from '@mui/material'

import { VerticalLayout } from '@/components/layout'
import { Text } from '@/local/components'
import { ShowLabelDepot, DepotCircle, HideDepot } from '@/icons'

import { NavigoIconButton } from '../../../../../../elements/NavigoIconButton'
import { useActions } from '../../hooks/useActions'
import { useData } from '../../hooks/useData'
import { StylerContainer } from './components/StylerContainer'
import { ButtonsContainer } from './components/ButtonsContainer'
import { useTexts } from './useTexts'

export function Styler() {
  const { activeMapStyle } = useData()
  const { methodInExecution, actions } = useActions()
  const texts = useTexts()

  return (
    <StylerContainer>
      <VerticalLayout justifyContent="space-between" width="auto" marginRight={24}>
        <Text size="$p3" weight="$semiBold">
          {texts.title}
        </Text>

        <Text size="$p3">{texts.subtitle}</Text>
      </VerticalLayout>

      <ButtonsContainer>
        <NavigoIconButton
          text={texts.show}
          onClick={actions.showDepotLabels}
          active={activeMapStyle === 'label'}
          Icon={
            methodInExecution === 'showDepotLabels' ? (
              <CircularProgress size={18} color="primary" />
            ) : (
              <ShowLabelDepot size={16} color="$nightRider" />
            )
          }
        />

        <NavigoIconButton
          text={texts.reduce}
          onClick={actions.showDepots}
          active={activeMapStyle === 'on'}
          Icon={
            methodInExecution === 'showDepots' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <DepotCircle size={16} color="$nightRider" />
            )
          }
        />

        <NavigoIconButton
          text={texts.hide}
          onClick={actions.hideDepots}
          active={activeMapStyle === 'off'}
          Icon={
            methodInExecution === 'hideDepots' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <HideDepot size={16} color="$silver" />
            )
          }
        />
      </ButtonsContainer>
    </StylerContainer>
  )
}
