import OlMap from 'ol/Map'

import { findLayer } from '../../layers/utils/findLayer'

import { getInteraction, registerInteraction, setInteractionMetadata } from '../interactionMetadata'
import { ModifyEditableAreaInteraction } from '../EditableAreaInteraction'

export const registerModifyEditableAreaInteraction = (map: OlMap) => {
  const uid = 'rm-modify-editable-area-interaction'

  if (getInteraction(uid)) return

  // -------------------------------------------
  // Initialize the Interaction

  const drawLayer = findLayer(map, 'draw')

  const modifyEditableArea = new ModifyEditableAreaInteraction(drawLayer, {
    // set the base style for the `draw` layer
    color: '#303030',
  })

  // by default the Interaction is disabled
  modifyEditableArea.setActive(false)

  // -------------------------------------------
  // set Interaction Metadata

  setInteractionMetadata(modifyEditableArea, 'rmInteraction', true)
  setInteractionMetadata(modifyEditableArea, 'uid', uid)
  registerInteraction(uid, modifyEditableArea)

  // -------------------------------------------
  // Add the interaction to the map

  // TODO: is this redundant with `registerInteraction` ???
  map.addInteraction(modifyEditableArea)
}
