import { toast } from 'react-toastify'

import { setCustomRouteStyle } from '@/features/domain/ui'
import { store } from '@/store'

import { setRouteLoadingState } from '../../../../../atoms/routeLoadingState'

export async function toggleRouteVisibility(
  id: string,
  routeVisible: uui.domain.ui.map.markers.RouteMapStyle,
) {
  const mode = routeVisible === 'on' ? 'off' : 'on'

  setRouteLoadingState(mutableState => {
    mutableState.visibility[id] = true
    return mutableState
  })

  try {
    const result = await store.dispatch(setCustomRouteStyle({ id, mode }))

    if (setCustomRouteStyle.rejected.match(result)) {
      throw new Error(result.payload?.message ?? 'Internal error')
    }
  } catch (e) {
    toast.error(e.message)
  }

  setRouteLoadingState(mutableState => {
    delete mutableState.visibility[id]
    return mutableState
  })
}
