import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    status: {
      label: translate({ id: 'voice.form.fields.status.label' }),
      values: {
        off: translate({ id: 'voice.form.fields.status.value.off' }),
        basic: translate({ id: 'voice.form.fields.status.value.basic' }),
        advanced: translate({ id: 'voice.form.fields.status.value.advanced' }),
      },
    },
    restrictions: {
      label: translate({ id: 'voice.form.fields.restrictions.label' }),
    },
    forbidDriverCallsToCompletedOrderSteps: {
      label: translate({ id: 'voice.form.fields.forbidDriverCallsToCompletedOrderSteps.label' }),
    },
    forbidDriverCallsToIncompleteOrderSteps: {
      label: translate({ id: 'voice.form.fields.forbidDriverCallsToIncompleteOrderSteps.label' }),
    },
    callbackConfigStatus: {
      label: translate({ id: 'voice.form.fields.callbackConfigStatus.label' }),
      values: {
        off: translate({ id: 'voice.form.fields.callbackConfigStatus.value.off' }),
        backOffice: translate({ id: 'voice.form.fields.callbackConfigStatus.value.backOffice' }),
      },
    },
    backOfficePhone: {
      label: translate({ id: 'voice.form.fields.backOfficePhone.label' }),
      error: {
        format: translate({ id: 'voice.form.fields.backOfficePhone.error.format' }),
        required: translate({ id: 'voice.form.fields.backOfficePhone.error.required' }),
      },
      caption: translate({ id: 'voice.form.fields.backOfficePhone.caption' }),
      hint: translate({ id: 'voice.form.fields.backOfficePhone.hint' }),
    },
    automatedMessageEnabled: {
      label: translate({ id: 'voice.form.fields.automatedMessageEnabled.label' }),
      values: {
        on: translate({ id: 'voice.form.fields.automatedMessageEnabled.value.on' }),
        off: translate({ id: 'voice.form.fields.automatedMessageEnabled.value.off' }),
      },
    },
    automatedMessage: {
      label: translate({ id: 'voice.form.fields.automatedMessage.label' }),
      error: {
        required: translate({ id: 'voice.form.fields.automatedMessage.error.required' }),
      },
    },
    automatedMessageVoice: {
      notAvailable: translate({ id: 'voice.form.fields.automatedMessageVoice.notAvailable' }),
      label: translate({ id: 'voice.form.fields.automatedMessageVoice.label' }),
      values: {
        female: translate({ id: 'voice.form.fields.automatedMessageVoice.value.female' }),
        male: translate({ id: 'voice.form.fields.automatedMessageVoice.value.male' }),
      },
    },
  }))

  return api
}
