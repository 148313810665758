import type { RecurrencesMap } from '../../typings'

import { Calendar } from '@/components/Calendar'

import { useWeeklyCalendarProps } from './useWeeklyCalendarProps'

type Props = {
  recurrenceMap: RecurrencesMap
  onChange: (date: Date) => void
  date: Date | null
}

export function WeeklyCalendar(props: Props) {
  const { recurrenceMap, onChange, date } = props
  const calendarProps = useWeeklyCalendarProps(recurrenceMap, onChange, date)

  return <Calendar {...calendarProps} />
}
