import { slice } from './domainSlice'

// ----------------------------------------------------
// ----------------------------------------------------
// Middleware config

import { replaceDomainData } from './core/actions'
import { updateDomainData } from './core/actions/updateDomainData'
import * as transaction from './transaction/actions'

export type { FeatureState } from './typings'

export default slice.reducer

// ----------------------------------------------------
// ----------------------------------------------------
// core

export * from './core'

export const serializableMiddlewareIgnoreList = [
  replaceDomainData.type,
  updateDomainData.fulfilled.type,
  transaction.commitTransaction.fulfilled.type,
]
