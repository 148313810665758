import { Entry } from '../Entry'

export function Entry20210129() {
  return (
    <Entry title="01/29/2021 - v3.0.0-alpha-2">
      <ul>
        <li>The lists auto-scroll to the last selected item</li>
        <li>The plan can be time-shifted</li>
      </ul>
    </Entry>
  )
}
