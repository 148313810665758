import { Divider, Stack } from '@mui/material'

import { useAppLanguage } from '@/atoms'

import { useController } from '../../../../hooks/useController'

import { FormatPicker } from './components/FormatPicker'
import { FilterPicker } from './components/FilterPicker'
import { Advanced } from './components/Advanced'
import { CustomizeColumns } from './components/ConfigureColumns'

export function Body() {
  const {
    data: { isSimulation },
  } = useController()
  const [{ language }] = useAppLanguage()

  return (
    <Stack height="100%" overflow="auto" padding={4} gap={4}>
      {isSimulation ? null : (
        <>
          <FilterPicker />
          <Divider />
        </>
      )}
      <FormatPicker />
      <Divider />
      <Stack gap={2}>
        {<CustomizeColumns />}
        {language !== 'en' && <Advanced />}
      </Stack>
    </Stack>
  )
}
