import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    save: translate({
      id: 'sms4.settings.communication.notifications.form.footer.save',
    }),
    cancel: translate({
      id: 'sms4.settings.communication.notifications.form.footer.cancel',
    }),
    reset: translate({
      id: 'sms4.settings.communication.notifications.form.footer.reset',
    }),
    test: translate({
      id: 'sms4.settings.communication.notifications.form.footer.test',
    }),
  }))

  return api
}
