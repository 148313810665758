import { Dropdown } from '@/forms-legacy'
import { DropdownItem } from '../typings'

export const renderText = (
  text: workwave.NullableString,
  values: DropdownItem[],
  selectedIds: string[],
): Dropdown.TextToRender<string> => {
  if (typeof text === 'string') {
    let match: workwave.Nullable<DropdownItem> = values.find(({ label }) =>
      label.toLowerCase().startsWith(text.toLowerCase()),
    )

    let showSuggestionText: boolean = !!match

    if (!match) {
      match = values.find(({ label }) => label.includes(text))
      showSuggestionText = false
    }

    return {
      text,
      suggestedText: showSuggestionText && match ? match.label : undefined,
      suggestedId: match ? match.id : undefined,
    }
  }

  if (selectedIds.length > 0 && text === null) {
    const selectedId: string = selectedIds[0]
    const item: workwave.Nullable<DropdownItem> = values.find(({ id }) => selectedId === id)

    if (item) {
      return { text: item.label }
    }
  }

  if (selectedIds.length === 0 && text === null && values.length) {
    const item: DropdownItem = values[0]
    return { text: item.label }
  }

  return { text: '' }
}
