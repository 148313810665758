import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    breaks: translate({ id: 'global.breaks' }),
    starting: translate({ id: 'rm.contextual.route.starting' }),
    workingHours: translate({ id: 'setup.vehicles.fields.workingHours' }),
  }))

  return api
}
