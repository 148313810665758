import { useResetCrudSelectionOnUnmount } from '@/atoms'
import { useRefreshGpsData } from '@/hooks'

import { useDevicesRoot } from './hooks/useDevicesRoot'
import { List } from './List'
import { SingleView } from './Single/View'
import { BulkView } from './Bulk/View'
import { SingleEdit } from './Single/Edit'

export function DevicesRoot() {
  const { editing, devices, selectedDevices, selection } = useDevicesRoot()
  const bulk = selectedDevices.length > 1
  const single = selectedDevices.length === 1

  const device = selectedDevices[0]

  useResetCrudSelectionOnUnmount('devices')
  useRefreshGpsData('unifiedVehicles')

  return (
    <>
      <List editing={editing} devices={devices} selection={selection} />
      {single && !editing && device && <SingleView device={device} />}
      {bulk && <BulkView devices={selectedDevices} />}
      {editing && !bulk && <SingleEdit device={device} />}
    </>
  )
}
