import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    add: translate({ id: 'global.add' }),
    regions: translate({ id: 'setup.regions.list.yourRegions' }),
    addRegion: translate({ id: 'setup.regions.createNew' }),
    importKml: translate({ id: 'global.importKML' }),
    importKmlFailed: translate({ id: 'global.importKML.failed' }),
    filterPlaceholder: translate({ id: 'setup.regions.filterPlaceholder' }),
  }))

  return api
}
