import { type MouseEventHandler } from 'react'
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List as MuiList,
  Stack,
  Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { SyncAlt, Mail } from '@mui/icons-material'

import { selectUserConfiguration } from '@/features/domain/user'
import { timeUtils } from '@/server-data'

interface Props {
  entries: uui.domain.SocketLogEntry[]
  selectedEntryId?: string
  setSelectedEntryId: (entry: string) => void
}
export function List(props: Props) {
  const { entries, selectedEntryId, setSelectedEntryId } = props
  const userConfig = useSelector(selectUserConfiguration)
  const formatTime = timeUtils.formatTime(userConfig)

  return (
    <Stack maxHeight={500} overflow="hidden scroll" width={400} flexShrink={0}>
      <MuiList dense>
        {entries.map((entry, index) => {
          const onClick: MouseEventHandler<HTMLDivElement> = e => {
            e.preventDefault()
            e.stopPropagation()

            setSelectedEntryId(entry.internalId)
            return false
          }
          return (
            <ListItemButton
              key={`entry_${index}`}
              onClick={onClick}
              selected={entry.internalId === selectedEntryId}
            >
              <ListItemIcon>
                {entry.type === 'request' ? (
                  <SyncAlt fontSize="small" />
                ) : (
                  <Mail fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography component="p" variant="caption" fontWeight={500}>
                    {entry.method}
                  </Typography>
                }
                secondary={
                  <Typography component="p" variant="caption">
                    {`${formatTime(new Date(entry.timestamp), true)} ${
                      entry.type === 'request' && entry.duration !== undefined
                        ? `(${entry.duration}ms)`
                        : ''
                    }`}
                  </Typography>
                }
              />
            </ListItemButton>
          )
        })}
      </MuiList>
    </Stack>
  )
}
