import type { MainSelection } from './typings'

export type { MainSelection, MainSelectionCategory } from './typings'

export { getMainSelection } from './read/getMainSelection'
export { subscribeMainSelection } from './read/subscribeMainSelection'
export { subscribeAllMainSelection } from './read/subscribeAllMainSelection'

export { setMainSelection } from './write/setMainSelection'
export { resetMainSelection } from './write/resetMainSelection'
export { setAllMainSelection } from './write/setAllMainSelection'
export { cleanUpMainSelection } from './write/cleanUpMainSelection'
export { resetAllMainSelection } from './write/resetAllMainSelection'

export { useMainSelection } from './hooks/useMainSelection'
export { useNavigoSelection } from './hooks/useNavigoSelection'
export { useAllMainSelection } from './hooks/useAllMainSelection'

export const emptyMainSelection: MainSelection = {
  breadcrumbs: [],
  depots: [],
  drivers: [],
  orderSteps: [],
  places: [],
  routes: [],
  unifiedVehicles: [],
  devices: [],
}
