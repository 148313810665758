import { useEffect, useRef } from 'react'
import { startOfMonth, isEqual, addMonths } from 'date-fns/esm'

/**
 *  That hook ensures that if the selection changes and is not showed,
 *  the calendar scrolls to it
 **/
export function useFocusOnSelection(
  referenceDate: Date,
  setReferenceDate: (date: Date) => void,
  selectionStart?: Date,
  focusOnSelection?: boolean,
) {
  const referenceDateRef = useRef<Date>(referenceDate)

  useEffect(() => {
    referenceDateRef.current = referenceDate
  }, [referenceDate])

  useEffect(() => {
    if (!focusOnSelection || !selectionStart) return

    const selectionStartOfMonth = startOfMonth(selectionStart)

    // If the start of the month of the current selection is not in the first or second showed month
    // set a new reference date
    if (
      !isEqual(selectionStartOfMonth, referenceDateRef.current) &&
      !isEqual(selectionStartOfMonth, addMonths(referenceDateRef.current, 1))
    ) {
      setReferenceDate(selectionStartOfMonth)
    }
  }, [setReferenceDate, focusOnSelection, selectionStart])
}
