import type { BreakReadonlyValue } from '../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { timeWindowUtils } from '@/utils'
import { durationUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

import { intl } from '@/intl'

import { secToTimeHHmm } from '../utils/secToTimeHHmm'

import { useTexts } from './useTexts'

export function useParseBreaksData(
  breaks: uui.domain.rm.Break[],
  workingDayStartSec: number,
): BreakReadonlyValue[] {
  const texts = useTexts()
  const { timeFormat } = useSelector(selectUserConfiguration)
  const formatDurationSec = durationUtils.formatSeconds(intl.translate)

  return useMemo(() => {
    return breaks.map(b => {
      const timeWindow = { startSec: b.startSec, endSec: b.endSec }
      const { startSec, endSec } = timeWindowUtils.formatTimeWindow(timeWindow, workingDayStartSec)

      const from = secToTimeHHmm(startSec, '', timeFormat)
      const to = secToTimeHHmm(endSec, '', timeFormat)

      return {
        id: `${startSec}-${endSec}-${b.durationSec}`,
        text: formatDurationSec(b.durationSec, 'LONG'),
        description: `${texts.from} ${from} ${texts.to} ${to}`,
      }
    })
  }, [breaks, timeFormat, texts, formatDurationSec, workingDayStartSec])
}
