import { Grow, FormColumn, ComponentSize, ListOverlayEditCover } from '@/components/layout'
import { ListFooter, LayoutComposer, NoEntries, NoResults } from '@/components/List'
import { useAutoScroll } from '@/atoms'
import { StructuredVirtualList } from '@/components/StructuredVirtualList'

import { ListLoading } from '@/components/ListLoading'
import { Item } from './Item'

import { Header } from './components/Header'
import { useTexts } from './hooks/useTexts'
import { useDataForList } from './hooks/useDataForList'
import { useListHandlers } from './hooks/useListHandlers'

interface Props {
  devices: Record<string, uui.domain.client.gps.wwgps.DeviceInfo>
  selection: workwave.DeepReadonly<string[]>
  editing: boolean
}

const getItemHeight = () => 80
const getItemId = (device: uui.domain.client.gps.wwgps.DeviceInfo) => device.deviceId

export function List(props: Props) {
  const { devices, selection, editing } = props

  const { handleOnSelect, handleOnSelectAll, handleOnDoubleClick } = useListHandlers(selection)
  const { structure, lastSelectedDevice, isLoading } = useDataForList()

  const texts = useTexts()

  const allSelected = selection.length >= structure.shownItems
  const multiSelect = selection.length > 1

  useAutoScroll('devices', lastSelectedDevice)

  return (
    <FormColumn width={320} testid="form__column-primary">
      <Header />

      <Grow yAxis xAxis position="relative">
        <LayoutComposer
          totalItems={structure.totalItems}
          shownItems={structure.shownItems}
          noResults={
            <NoResults title={texts.noResults.title} description={texts.noResults.description} />
          }
          noEntries={
            <NoEntries title={texts.noEntries.title} description={texts.noEntries.description} />
          }
          list={
            <ComponentSize
              render={size => {
                // TODO: reset the scroll when the list options change
                return isLoading ? (
                  <ListLoading />
                ) : (
                  <>
                    <StructuredVirtualList
                      itemsById={devices}
                      structure={structure}
                      listApiCategory="devices"
                      getItemHeights={getItemHeight}
                      getItemId={getItemId}
                      RenderItem={Item}
                      listHeight={size.height}
                      selectedItemIds={selection}
                      onSelect={handleOnSelect}
                      onDoubleClick={handleOnDoubleClick}
                      buffer={50}
                    />
                    {editing && <ListOverlayEditCover />}
                  </>
                )
              }}
            />
          }
        />
      </Grow>

      {multiSelect && (
        <ListFooter
          label={texts.selectBtn(allSelected)}
          onToggleSelection={handleOnSelectAll}
          checked={allSelected}
        />
      )}
    </FormColumn>
  )
}
