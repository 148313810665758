import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useNavigate } from '@/routing'
import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { selectPlan } from '@/features/domain/history'
import { selectSimulationsListStructure } from '@/features/domain/lists'
import { changeTerritory, selectTerritory } from '@/features/domain/territory'
import { resetCrudSelection, useEditingStateWithCtx } from '@/atoms'

export function useDataForList(selection: workwave.DeepReadonly<string[]>) {
  const plan = useSelector(selectPlan)
  const territory = useSelector(selectTerritory)

  const dispatch = useAppDispatch()
  const toast = useNotification()
  const navigate = useNavigate(true)
  const structure = useSelector(selectSimulationsListStructure)
  const { setEditing } = useEditingStateWithCtx('simulation')

  const onSetEditing = useCallback(() => {
    resetCrudSelection('simulations')
    setEditing(Array.from(selection))
  }, [setEditing, selection])

  const onOpenOperations = useCallback(
    () => dispatch(changeTerritory({ target: 'ops', territoryId: territory.id })),
    [dispatch, territory.id],
  )

  const onDoubleClick = useCallback(async () => {
    try {
      const result = await dispatch(
        changeTerritory({
          target: 'simulation',
          simulationId: selection[0],
          territoryId: territory.id,
        }),
      )

      if (!changeTerritory.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      navigate('home')
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, navigate, toast, territory.id, selection])

  return {
    structure,
    territory,
    onSetEditing,
    onDoubleClick,
    planId: plan.id,
    handleOnOpenOperations: onOpenOperations,
  }
}
