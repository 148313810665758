import { Checkbox } from '@workwave-tidal/tidal/form-ui'

import { type FormErrors, type FormFields } from '../formFields'
import { useTexts } from '../hooks/useTexts'

export function LimitedAccess() {
  const texts = useTexts()

  return (
    <Checkbox<'limitedAccess', FormFields, FormErrors>
      label={texts.labels.limitedAccess}
      validateOn="blur focus change"
      name="limitedAccess"
      testId="limitedAccess"
      helperText={texts.helperText.limitedAccess}
    />
  )
}
