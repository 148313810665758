import type { ImportWizardCookieData } from '../types'

import { getImportWizardCookie } from './cookie'

/**
 * check if cookie expired
 * if not parse importWizard cookie
 * @returns Data if no errors false instead
 */
export function parseImportWizardCookie() {
  try {
    const rawImportWizardImportData = getImportWizardCookie()
    if (!rawImportWizardImportData) {
      return undefined
    }

    const cookieData = JSON.parse(atob(rawImportWizardImportData))

    if (!isImportWizardCookieData(cookieData)) {
      console.warn(`The cookie does not contain valid ImportWizard cookie data`)
      console.log(cookieData)
      return undefined
    }

    return cookieData
  } catch (e) {
    console.warn(e)
    return undefined
  }
}

function isImportWizardCookieData(data: any): data is ImportWizardCookieData {
  const importWizardCookieData = data as ImportWizardCookieData

  if (
    !importWizardCookieData.webSessionId ||
    !importWizardCookieData.territoryId ||
    !importWizardCookieData.initiatorUrl
  ) {
    return false
  }

  return true
}
