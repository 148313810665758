import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Timezone(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 14 13">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.5C0 0.224 0.224 0 0.5 0C0.57 0 0.631266 0.0122031 0.697266 0.0332031L3.5 0.854492V9.87695L0.378906 8.97559C0.162906 8.92009 0 8.733 0 8.5V0.5ZM4.5 0.899414L8.5 0.0927735V6.13379C8.8025 5.92183 9.13852 5.75676 9.5 5.64844V0.125L12.6094 1.02246C12.8299 1.08996 13 1.2625 13 1.5V6.55762C13.6173 7.18928 14 8.05106 14 9C14 10.9271 12.4271 12.5 10.5 12.5C8.70657 12.5 7.22199 11.1368 7.02441 9.39453L4.5 9.89844V0.899414ZM10.5 6.5C11.8866 6.5 13 7.61337 13 9C13 10.3866 11.8866 11.5 10.5 11.5C9.11337 11.5 8 10.3866 8 9C8 7.61337 9.11337 6.5 10.5 6.5ZM10 7.5C9.99622 7.22423 10.2164 6.99747 10.4922 6.99316C10.6273 6.99105 10.7576 7.04374 10.8532 7.13922C10.9489 7.23471 11.0019 7.36485 11 7.5V9C11 9.1326 10.9473 9.25976 10.8535 9.35352L10.1035 10.1035C9.97811 10.2341 9.79188 10.2868 9.61666 10.2411C9.44144 10.1954 9.3046 10.0586 9.25893 9.88334C9.21325 9.70812 9.26586 9.52189 9.39648 9.39648L10 8.79297V7.5Z"
        />
      </svg>
    </IconContainer>
  )
}
