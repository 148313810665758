import styled from 'styled-components'

type Props = {
  showBackground?: boolean
  centerItems?: boolean
  hideBottomSpacing?: boolean
  fullPage?: boolean
}

const FooterRowFormControls = styled.div<Props>`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: ${p => (p.showBackground ? p.theme.colors.$alabaster : 'transparent')};
  align-items: center;
  justify-content: space-between;
  padding: 18px 36px;
  padding-bottom: ${p => (p.hideBottomSpacing ? 0 : 18)}px;
  width: ${p => (p.fullPage ? '484px' : '100%')};
  ${p => (p.fullPage ? 'margin: 0 auto' : '')};
`

FooterRowFormControls.displayName = 'FooterRowFormControls'
export default FooterRowFormControls
