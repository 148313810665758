import Control, { Options as ControlOptions } from 'ol/control/Control'
import { easeOut } from 'ol/easing'
import { CLASS_CONTROL, CLASS_UNSELECTABLE } from 'ol/css'
import { isMapLocked } from '../atoms/map/mapApi'
import { getTexts } from './registerMapControls/texts'

type ChangeTexts = {
  zoomIn?: {
    label?: string
    tooltip?: string
  }
  zoomOut?: {
    label?: string
    tooltip?: string
  }
}

export interface Options extends ControlOptions {
  duration: number
  className: string
  zoomInLabel: string | HTMLElement
  zoomOutLabel: string | HTMLElement
  zoomInTipLabel: string
  zoomOutTipLabel: string
  delta: number
  target: string | HTMLElement
  minZoom: number
  maxZoom: number
  disabled: boolean
}

const defaultOptions = (): Options => {
  const texts = getTexts()
  return {
    duration: 250,
    className: 'ol-zoom',
    zoomInLabel: '+',
    zoomOutLabel: '-',
    zoomInTipLabel: texts.zoomInToolTip,
    zoomOutTipLabel: texts.zoomOutToolTip,
    delta: 1,
    target: '',
    element: document.createElement('div'),
    minZoom: 1,
    maxZoom: 19,
    disabled: false,
  }
}

const disabledStyle: string = 'background: #666; opacity: 0.4; cursor: not-allowed;'

export class ZoomMapControl extends Control {
  protected inElement: HTMLButtonElement = document.createElement('button')
  protected outElement: HTMLButtonElement = document.createElement('button')
  protected options: Options

  constructor(protected parmOptions: Partial<Options> = {}) {
    super({
      ...defaultOptions(),
      ...parmOptions,
    })
    const options = {
      ...defaultOptions(),
      ...parmOptions,
    }
    this.options = options
    this.init(options)
  }

  protected init(options: Options) {
    this.createInElement()
    this.createOutElement()

    const cssClasses = `${options.className} ${CLASS_UNSELECTABLE} ${CLASS_CONTROL}`
    this.element.className = cssClasses
    this.appendButtons()
  }

  protected createInElement() {
    this.inElement.className = this.options.className + '-in'
    this.inElement.setAttribute('type', 'button')
    if (this.options.disabled) {
      this.inElement.setAttribute('disabled', 'disabled')
    }
    this.inElement.title = this.options.zoomInTipLabel
    this.inElement.style.cssText = this.options.disabled ? disabledStyle : ''

    this.inElement.appendChild(
      typeof this.options.zoomInLabel === 'string'
        ? document.createTextNode(this.options.zoomInLabel)
        : this.options.zoomInLabel,
    )

    this.inElement.addEventListener('click', this.handleOnClickPlus)
  }

  protected createOutElement() {
    this.outElement.className = this.options.className + '-out'
    this.outElement.setAttribute('type', 'button')
    if (this.options.disabled) {
      this.outElement.setAttribute('disabled', 'disabled')
    }
    this.outElement.title = this.options.zoomOutTipLabel
    this.outElement.style.cssText = this.options.disabled ? disabledStyle : ''

    this.outElement.appendChild(
      typeof this.options.zoomOutLabel === 'string'
        ? document.createTextNode(this.options.zoomOutLabel)
        : this.options.zoomOutLabel,
    )

    this.outElement.addEventListener('click', this.handleOnClickMinus)
  }

  protected appendButtons() {
    this.element.appendChild(this.inElement)
    this.element.appendChild(this.outElement)
  }

  protected handleOnClickPlus = (event: MouseEvent) => {
    event.preventDefault()
    this.zoomByDelta(this.options.delta)
  }

  protected handleOnClickMinus = (event: MouseEvent) => {
    event.preventDefault()
    this.zoomByDelta(-this.options.delta)
  }

  protected zoomByDelta = (delta: number) => {
    if (isMapLocked()) return

    const map = this.getMap()
    const view = map?.getView()
    if (!view) {
      // the map does not have a view, so we can't act
      // upon it
      return
    }

    const currentZoom = view.getZoom()
    if (currentZoom !== undefined) {
      const newZoom = currentZoom + delta
      const normalizedZoom = Math.max(Math.min(newZoom, this.options.maxZoom), this.options.minZoom)

      if (this.options.duration > 0) {
        if (view.getAnimating()) {
          view.cancelAnimations()
        }
        view.animate({
          zoom: normalizedZoom,
          duration: this.options.duration,
          easing: easeOut,
        })
      } else {
        view.setZoom(normalizedZoom)
      }
    }
  }

  protected redraw() {
    this.inElement.removeEventListener('click', this.handleOnClickPlus)
    this.outElement.removeEventListener('click', this.handleOnClickMinus)
    this.inElement = document.createElement('button')
    this.outElement = document.createElement('button')
    this.element.innerHTML = ''
    this.createInElement()
    this.createOutElement()
    this.appendButtons()
  }

  public dispose() {
    this.inElement.removeEventListener('click', this.handleOnClickPlus)
    this.outElement.removeEventListener('click', this.handleOnClickMinus)
    this.element.remove()
  }

  public enable() {
    this.options.disabled = false
    this.redraw()
  }

  public disable() {
    this.options.disabled = true
    this.redraw()
  }

  public changeTexts(texts: ChangeTexts) {
    this.options.zoomInLabel = texts?.zoomIn?.label ?? this.options.zoomInLabel
    this.options.zoomInTipLabel = texts?.zoomIn?.tooltip ?? this.options.zoomInTipLabel

    this.options.zoomOutLabel = texts?.zoomOut?.label ?? this.options.zoomOutLabel
    this.options.zoomOutTipLabel = texts?.zoomOut?.tooltip ?? this.options.zoomOutTipLabel
    this.redraw()
  }

  public resetTexts() {
    const options = defaultOptions()
    this.options.zoomInLabel = options.zoomInLabel
    this.options.zoomInTipLabel = options.zoomInTipLabel

    this.options.zoomOutLabel = options.zoomOutLabel
    this.options.zoomOutTipLabel = options.zoomOutTipLabel
    this.redraw()
  }
}
