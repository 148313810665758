import { DropDownItem } from '@/components/Dropdown'

type Best = 'best'

export type BestOptionOr<T> = T | Best
export type Vehicle = uui.domain.client.rm.Vehicle
export type ExtendedOrderStep = uui.domain.client.rm.ExtendedOrderStep
export type Route = uui.domain.client.rm.Route
export type RouteStep = uui.domain.client.rm.RouteStep

export const isBestOption = (canBeBestItem: BestOptionOr<any>): canBeBestItem is Best =>
  canBeBestItem === 'best'

export interface DropDownItemDate extends DropDownItem {
  date: BestOptionOr<Date>
}

export interface DropDownItemVehicle extends DropDownItem {
  vehicle: BestOptionOr<Vehicle>
}
export interface DropDownItemStep extends DropDownItem {
  routeStep: BestOptionOr<RouteStep>
}

export interface StepProps {
  step: RouteStep
  route: Route
  activeStepId: string
  nextStep?: RouteStep
}
