import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function MapStyle(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M1 2.167C1 1.522 1.522 1 2.167 1H11.5c.645 0 1.167.522 1.167 1.167V4.5c0 .645-.522 1.167-1.167 1.167H2.167C1.522 5.667 1 5.145 1 4.5V2.167zm12.321.872c.106-.181.302-.292.512-.289h.584c.322 0 .583.261.583.583v2.582c0 1.148-.844 2.132-1.978 2.307h-.001l-4.615.71c-.574.088-.99.573-.99 1.153v.993c.348.202.584.574.584 1.005v1.75C8 14.478 7.478 15 6.833 15c-.644 0-1.166-.522-1.166-1.167v-1.75c0-.43.236-.803.583-1.005v-.993c0-1.148.845-2.132 1.979-2.306l4.614-.71c.574-.088.99-.574.99-1.154V3.917c-.21.003-.406-.108-.512-.29-.106-.181-.106-.406 0-.588z" />
      </svg>
    </IconContainer>
  )
}
