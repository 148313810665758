import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    note: translate({ id: 'global.note' }),
    picture: translate({ id: 'global.picture' }),
    signature: translate({ id: 'global.signature' }),
    voiceMemo: translate({ id: 'global.voiceMemo' }),
    proofsOfDelivery: translate({ id: 'global.POD' }),
    clickToExpand: translate({ id: 'global.clickToExpand' }),
  }))

  return api
}
