import { produce } from 'immer'
import { useCallback } from 'react'

import { getLocationPin, getMapInfo, moveLocationPin, updateLocationPin } from '@/map'

export function useLocationActions(pinId: uui.domain.ui.map.LocationPinId) {
  const fixInvalidAddress = useCallback(() => {
    const pin = getLocationPin(pinId, true)

    if (!pin) return

    const { center } = getMapInfo()
    moveLocationPin(pinId, center)
  }, [pinId])

  const confirmLowAccuracy = useCallback(() => {
    const locationInfo = getLocationPin(pinId, true)?.pin.location

    if (!locationInfo) return

    updateLocationPin(pinId, prevPin =>
      produce(prevPin, draft => {
        draft.pin.location!.status = 'OK'
      }),
    )
  }, [pinId])

  const renameLocation = useCallback(
    (nextAddress: string) => {
      const location = getLocationPin(pinId, true)?.pin.location

      if (!location) return

      updateLocationPin(pinId, prevPin =>
        produce(prevPin, draft => {
          draft.pin.location!.address = nextAddress
        }),
      )
    },
    [pinId],
  )

  return { fixInvalidAddress, confirmLowAccuracy, renameLocation } as const
}
