import { Box, styled } from '@mui/material'

export const MapViewport = styled(Box, { name: 'MapViewportComponent' })(() => ({
  backgroundColor: 'white',
  margin: 0,
  padding: 0,
  height: '100%',
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 2,
  '& #map-container': {
    height: '100%',
  },
  '&:fullscreen #map-container': {
    height: '100vh',
  },
}))
