import { Fragment } from 'react'

import { WarningTriangle } from '@/icons'
import { useFormatSeconds } from '@/hooks'
import { Checkbox } from '@/components/primitives/Checkbox'
import { WarningCard, Text } from '@/local/components'
import { VerticalLayout } from '@/components/layout/VerticalLayout'
import { HorizontalLayout } from '@/components/layout/HorizontalLayout'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

const Block = Fragment

export function Body() {
  const { status, data } = useController()
  const texts = useTexts()
  const actions = useControllerActions()

  const { selectedOrderSteps, pairedOrderSteps, unassignAlsoPaired } = data
  const isBulk = selectedOrderSteps.length > 1
  const serviceTimeSec =
    pairedOrderSteps?.length === 1 ? pairedOrderSteps[0].orderStep.serviceTimeSec : 0

  const serviceTime = useFormatSeconds(serviceTimeSec)

  return (
    <VerticalLayout marginTop={32}>
      <Block>
        <WarningCard
          title={
            status !== 'pending'
              ? texts.warningCardTitle(selectedOrderSteps.length)
              : texts.transactionWarningCardTitle
          }
          description={
            status !== 'pending'
              ? texts.warningCardDescription
              : texts.transactionWarningCardDescription
          }
          preset="warning"
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
        />
      </Block>

      {!!pairedOrderSteps && pairedOrderSteps.length > 0 && (
        <Block>
          <HorizontalLayout marginTop={32}>
            <Checkbox
              checked={unassignAlsoPaired}
              onChange={actions.onUnassignAlsoPairedChange}
              style={{ marginTop: 4 }}
            />
            <VerticalLayout>
              <Text size="$h2" weight="$semiBold" color="$nightRider" marginLeft={5} ellipsis>
                {isBulk
                  ? texts.unassignAlsoBulk
                  : texts.unassignAlso(selectedOrderSteps[0].orderStepType)}
              </Text>
              <Text
                size="$p3"
                weight="$regular"
                color="$nightRider"
                marginLeft={5}
                marginTop={2}
                ellipsis
              >
                {isBulk
                  ? texts.subtitleBulk(pairedOrderSteps.length)
                  : texts.subtitle({
                      name: selectedOrderSteps[0].order.name,
                      serviceTime: serviceTimeSec !== undefined ? serviceTime : null,
                    })}
              </Text>
            </VerticalLayout>
          </HorizontalLayout>
        </Block>
      )}
    </VerticalLayout>
  )
}
