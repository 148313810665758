import { useCallback } from 'react'

import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { resetCrudSelection } from '@/atoms'
import { trafficAreasUtils } from '@/utils'
import { deleteTrafficAreas } from '@/features/domain/traffic'

import { useTrafficProfile } from '../../../../hooks/useTrafficProfile'

import { useTrafficAreas } from './useTrafficAreas'

export function useActions() {
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const trafficProfile = useTrafficProfile()
  const trafficAreas = useTrafficAreas()

  const profileId = trafficProfile.id

  const onDelete = useCallback(async () => {
    try {
      const areaIds = trafficAreas.map(area => area.id)
      const result = await dispatch(deleteTrafficAreas({ areaIds, profileId }))

      if (!deleteTrafficAreas.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      resetCrudSelection('trafficArea')
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, toast, profileId, trafficAreas])

  const onExport = useCallback(() => {
    try {
      trafficAreasUtils.exportAreasToKML(trafficAreas, profileId)
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, trafficAreas, profileId])

  return {
    onDelete,
    onExport,
  }
}
