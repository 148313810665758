export const enum FieldMode {
  mixed = 'mixed',
  hidden = 'hidden',
}

export interface BulkViewData {
  // single and bulk data
  eligibility: string | FieldMode.mixed
  loads: Record<string, number | FieldMode.mixed>
  priority: string | FieldMode.mixed
  serviceTime: number | FieldMode.mixed
  tagsIn: string[]
  tagsOut: string[]
  timeWindows: uui.domain.rm.TimeWindow[] | FieldMode.mixed
  vehicle: string | FieldMode.mixed
  violations: uui.domain.rm.RouteStepViolationsType[]
}

export interface Props extends BulkViewData {
  // generic data
  notificationBehavior?: uui.domain.client.rm.Territory['notificationBehavior']

  // single mode only data
  customFields: Record<string, string> | FieldMode.hidden
  notes: string | FieldMode.mixed | FieldMode.hidden
  timeWindowExceptions: uui.domain.rm.TimeWindowExceptions | FieldMode.hidden
  type: uui.domain.client.rm.OrderStepIdentifier | FieldMode.hidden
  phone: string | FieldMode.mixed | FieldMode.hidden
  email: string | FieldMode.mixed | FieldMode.hidden
  company: string | null | FieldMode.mixed
  barcodes: string[] | FieldMode.hidden
  allowBarcodes: boolean
  useMetric: boolean

  integrationData?: uui.domain.client.rm.OrderIntegrationData

  // accountPreferences: uui.domain.server.rm.AccountPreferences
}
