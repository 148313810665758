import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

/**
 * Fetch route data required by Navigo for Routes
 *
 */
export const getTravelledDistance = createAsyncThunk<
  // Return type of the breadcrumbIds creator
  uui.domain.actions.rpc.route.GetTravelledDistanceActionResult['result'],
  // Type of first argument
  string,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/route/getTravelledDistance', async (routeId, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/route/getTravelledDistance', {
      category: 'rpc',
      type: 'rpc/route/getTravelledDistance',
      routeId,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {getTravelledDistance}`,
      { tags: ['rpc', 'route'], info: { payload: routeId, error } },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `GetTravelledDistance route failed`,
      error,
    })
  }
})
