import { FormLayout } from '@/forms-legacy'

import { RmData } from '../components/RmData'

interface Props {
  disableTrackingCheckbox: boolean
}

export function Form(props: Props) {
  const { disableTrackingCheckbox } = props

  return (
    <FormLayout>
      <RmData disableTrackingCheckbox={disableTrackingCheckbox} />
    </FormLayout>
  )
}
