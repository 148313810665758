import type { GridValueGetterParams } from '@mui/x-data-grid-pro'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import { conversionUtils } from '@/server-data'

export function useGetScheduleTimeValue() {
  const userConfig = useSelector(selectUserConfiguration)

  return useCallback(
    (params: GridValueGetterParams) => {
      const fieldName = params.field.replace('formattedData.', '')

      if (!isFinite(params.row[fieldName])) return '-'

      return conversionUtils.convertTime(userConfig)(params.row[fieldName], false)
    },
    [userConfig],
  )
}
