import { type SchedulerEventModel } from '@bryntum/schedulerpro'

import { durationUtils, timeUtils } from '@/server-data'

import { intl } from '@/intl'

import { Typography } from '../Typography'
import { Stack } from '../Stack'

import { useTooltipData } from './hooks/useTooltipData'
import { useTexts } from './useTexts'

const delayThresholdSeconds = 60

interface Props {
  eventRecord: SchedulerEventModel
  userConfig: uui.domain.UserConfiguration
}

export function RouteStepTooltip(props: Props) {
  const { userConfig } = props
  const {
    name,
    end,
    label,
    start,
    delay,
    status,
    violations = [],
    serviceTime,
  } = useTooltipData(props.eventRecord)
  const texts = useTexts()

  const hasViolations = violations.length > 0

  const showDelayDetails = !!delay

  const isArrivalOnTime = delay
    ? Math.abs(delay.arrivalDelaySeconds) <= delayThresholdSeconds
    : false

  const arrivalColor =
    delay && delay.arrivalDelaySeconds !== 0
      ? delay.arrivalDelaySeconds < 0
        ? '#4caf50'
        : '#ef5350'
      : undefined

  const isDepartureOnTime = delay
    ? Math.abs(delay.departureDelaySeconds) <= delayThresholdSeconds
    : false

  const departureColor =
    delay && delay.departureDelaySeconds !== 0
      ? delay.departureDelaySeconds < 0
        ? '#4caf50'
        : '#ef5350'
      : undefined

  const bothOnTime = isArrivalOnTime && isDepartureOnTime

  return (
    <Stack spacing={0.75}>
      <Stack direction="row" alignItems="center" spacing={0.75}>
        {hasViolations && (
          <div style={{ width: 13, height: 13, fill: 'var(--scheduler-error)' }}>
            <svg viewBox="0 0 32 32">
              <path d="M5 2.46875V29h2v-8.34375L27.03125 12z" />
            </svg>
          </div>
        )}
        <Typography variant="h6">{name}</Typography>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack>
          <Typography variant="caption" color="#bdbdbd">
            {texts.id}
          </Typography>

          <Typography variant="subtitle2">{label}</Typography>
        </Stack>

        <Stack flexGrow={2}>
          <Typography variant="caption" color="#bdbdbd">
            {texts.status}
          </Typography>

          <Typography
            variant="subtitle2"
            color={
              status === 'reschedule' ? 'warning.light' : status === 'done' ? '#4caf50' : undefined
            }
          >
            {texts.statusDescription(status)}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Stack>
          <Typography variant="caption" color="#bdbdbd">
            {texts.arrival}
          </Typography>
          <Typography variant="body2">{timeUtils.formatTime(userConfig)(start)}</Typography>

          {showDelayDetails && (
            <Typography variant="subtitle2" color={arrivalColor}>
              {isArrivalOnTime && !bothOnTime
                ? texts.onTime
                : !!delay.arrivalDelaySeconds &&
                  `${delay.arrivalDelaySeconds > 0 ? '+' : '-'}${durationUtils.formatMinutes(
                    intl.translate,
                    delay.arrivalDelaySeconds,
                  )}`}
            </Typography>
          )}
        </Stack>

        <Stack>
          <Typography variant="caption" color="#bdbdbd">
            {texts.service}
          </Typography>
          <Typography variant="subtitle2">
            {serviceTime !== undefined
              ? durationUtils.formatMinutes(intl.translate, serviceTime)
              : null}
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="caption" color="#bdbdbd">
            {texts.departure}
          </Typography>

          <Typography variant="subtitle2">{timeUtils.formatTime(userConfig)(end)}</Typography>

          {showDelayDetails && (
            <Typography variant="subtitle2" color={departureColor}>
              {isDepartureOnTime && !bothOnTime
                ? texts.onTime
                : !!delay.departureDelaySeconds &&
                  `${delay.departureDelaySeconds > 0 ? '+' : '-'}${durationUtils.formatMinutes(
                    intl.translate,
                    delay.departureDelaySeconds,
                  )}`}
            </Typography>
          )}
        </Stack>
      </Stack>

      {hasViolations && (
        <Stack>
          <Typography variant="caption" color="#bdbdbd">
            {texts.violations}
          </Typography>

          <Stack>
            {violations.map(v => (
              <Typography key={v} variant="subtitle2">
                {v}
              </Typography>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
