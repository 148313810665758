import { useRoutingSidebar } from '@/atoms'
import { RouteDetailsOn, RouteDetailsOff } from '@/icons'

import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'

import { useTexts } from '../useTexts'

interface Props {
  disabled: boolean
  onClick: () => void
}
export function RouteDetailsButton(props: Props) {
  const { disabled, onClick } = props
  const [{ view }] = useRoutingSidebar()
  const texts = useTexts()

  const active = view === 'routeDetails'

  const Icon = active ? (
    <RouteDetailsOn size={20} color="$shadyLady" />
  ) : (
    <RouteDetailsOff size={20} color="$shadyLady" />
  )

  return (
    <NavigoIconButton
      text={texts.routeDetails}
      Icon={Icon}
      testid="navigo-routes-overview-actions-routeDetails"
      active={active}
      onClick={onClick}
      disabled={disabled}
    />
  )
}
