import { useSelector } from 'react-redux'

import { SecondaryColumnHeader, FormContent, FormBulkAvatar } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { selectTrafficTimeWindows } from '@/features/domain/traffic'

import { ViewDetails } from '../../ViewDetails'

import { ActionsManager } from './components/ActionsManager'
import { useGoBack } from './hooks/useGoBack'
import { useTrafficAreas } from './hooks/useTrafficAreas'
import { useTexts } from './hooks/useTexts'
import { useViewDetails } from './hooks/useViewDetails'

export function BulkView() {
  const timeWindows = useSelector(selectTrafficTimeWindows)
  const trafficAreas = useTrafficAreas()
  const goBack = useGoBack()
  const texts = useTexts()
  const { levels, timeWindowsLevels } = useViewDetails(trafficAreas)

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.title(trafficAreas.length)}
        description={texts.description}
        action={goBack}
        avatar={<FormBulkAvatar size={trafficAreas.length} />}
      />
      <ActionsManager />
      <FormContent footer={<div />}>
        <ViewDetails
          timeWindows={timeWindows}
          levels={levels}
          timeWindowsLevels={timeWindowsLevels}
        />
      </FormContent>
    </FormColumn>
  )
}
