import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRegions } from '@/features/domain/region'

export function useSortedRegions() {
  const regions = useSelector(selectRegions)

  return useMemo(() => {
    return Object.values(regions).sort((a, b) => a.name.localeCompare(b.name))
  }, [regions])
}
