import { useCallback, useState } from 'react'

export function useInteractions(value: string, setValue: (value: string) => void) {
  const [mouseInside, setMouseInside] = useState(false)
  const [focus, setFocus] = useState(false)

  const onClear = useCallback(() => {
    setValue('')
  }, [setValue])

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value)
    },
    [setValue],
  )

  const onBlur = useCallback(() => {
    setFocus(false)
    setMouseInside(false)
  }, [])

  const onFocus = useCallback(() => {
    setFocus(true)
    setMouseInside(true)
  }, [])

  const onMouseEnter = useCallback(() => {
    setMouseInside(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setMouseInside(false)
  }, [])

  const adornmentVisible = (focus || mouseInside) && value.length > 0

  return {
    onFocus,
    onClear,
    onChange,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    adornmentHidden: !adornmentVisible,
  }
}
