import { subscribe } from 'valtio'

import { addOrUpdateInspectPinFeature } from '../../layers/pin/inspectPin/addOrUpdateInspectPinFeature'
import { removeInspectPinFeature } from '../../layers/pin/inspectPin/removeInspectPinFeature'
import { getInspectPin } from './core/getInspectPin'
import { inspectPin } from './inspectPin'

export function connectMapToInspectPin() {
  return subscribe(inspectPin, () => {
    const pin = getInspectPin(true)

    if (pin) {
      // create or update the inspect pin
      addOrUpdateInspectPinFeature(pin)
    } else {
      // try to remove the inspect pin
      removeInspectPinFeature()
    }
  })
}
