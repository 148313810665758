import { useSelector } from 'react-redux'

import { selectUserConfiguration, selectUserProfile } from '@/features/domain/user'

export const useRmLinks = () => {
  const userProfile = useSelector(selectUserProfile)
  const { userType } = useSelector(selectUserConfiguration)

  const canShow = {
    simulations: !userProfile.isViewOnly && userType !== 'rmViewer' && userType !== 'courier',
  }

  return {
    canShow,
  }
}
