import { Calendar } from '@/components/Calendar'

import { useOnCalendarProps } from './useOnCalendarProps'

export type Props = {
  dates: string[]
  initialVisibleDate: string
  onChange: (dates: Date[]) => void
  onReferenceDateChange: (newReferenceDate: Date) => void
}

export function OnCalendar(props: Props) {
  const calendarProps = useOnCalendarProps(props)

  return (
    <Calendar
      {...calendarProps}
      initialVisibleDate={props.initialVisibleDate}
      onReferenceDateChange={props.onReferenceDateChange}
    />
  )
}
