import type { Props } from './types'

import { styled } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

import { useCallbacks } from './hooks/useCallbacks'
import { useSlots } from './hooks/useSlots'

const DataGridComponent = styled(DataGridPro, { name: 'DataGridComponent', skipSx: true })({
  '.MuiDataGrid-scrollArea': {
    width: 80,
  },
})

export function DataGrid(props: Props) {
  const {
    rows,
    apiRef,
    columns,
    sortModel,
    rowHeight,
    hideFooter,
    sortingMode,
    sortingOrder,
    headerHeight,
    selectionModel,
    onRowDoubleClick,
    onColumnOrderChange,
    onColumnWidthChange,
    onSelectionModelChange,
    onColumnVisibilityModelChange,
    ...componentProps
  } = props

  const { components, componentsProps } = useSlots(props)
  const callbacks = useCallbacks({
    onColumnVisibilityModelChange,
    onSelectionModelChange,
    onColumnOrderChange,
    onColumnWidthChange,
    onRowDoubleClick,
  })

  return (
    <DataGridComponent
      {...componentProps}
      rows={rows}
      apiRef={apiRef}
      columns={columns}
      sortModel={sortModel}
      rowHeight={rowHeight}
      hideFooter={hideFooter}
      components={components}
      sortingMode={sortingMode}
      sortingOrder={sortingOrder}
      headerHeight={headerHeight}
      selectionModel={selectionModel}
      componentsProps={componentsProps}
      onRowDoubleClick={callbacks.onRowDoubleClick}
      onColumnWidthChange={callbacks.onColumnWidthChange}
      onColumnOrderChange={callbacks.onColumnOrderChange}
      onSelectionModelChange={callbacks.onSelectionModelChange}
      onColumnVisibilityModelChange={callbacks.onColumnVisibilityModelChange}
    />
  )
}
