import { useMemo } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { format } from 'date-fns/esm'

import { timeUtils } from '@/server-data'

import { selectUserConfiguration } from '@/features/domain/user'
import { dateUtils } from '@/utils'
import { useTexts } from '../../../../../useTexts'

type Props = {
  date: string
  driverDeletionData: {
    lastActivityDate: string
    driver: uui.domain.client.rm.ExtendedDriver
  }[]
}

export function DriverDeletionDay(props: Props) {
  const { date, driverDeletionData } = props
  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const { deletionDate, deletionDayOfWeek } = useMemo(() => {
    const d = dateUtils.parseDate(date)
    const deletionDate = timeUtils.formatDate(userConfig)(d)
    const deletionDayOfWeek = format(d, 'EEEE')

    return { deletionDate, deletionDayOfWeek }
  }, [userConfig, date])

  return (
    <Stack spacing={1} mb={2} data-testid="non-deletable-drivers-by-date">
      {driverDeletionData.map(d => (
        <Box
          key={d.driver.id}
          width="calc(100% - 8px)"
          border={1}
          padding={2}
          overflow="hidden"
          data-testid="non-deletable-driver"
          borderColor="#E0E0E0"
          borderRadius={0.5}
        >
          <Typography data-testid="non-deletable-driver-name" fontWeight={600}>
            {d.driver.driver.name}
          </Typography>
          <Typography data-testid="non-deletable-driver-email" color="#00000060">
            {d.driver.driver.deployment.email}
          </Typography>
        </Box>
      ))}

      <Box data-testid="non-deletable-driver-deletion-date">
        <Typography color="#00000060" component="div">
          <FormattedMessage
            id={texts.canBeDeletedFrom}
            values={{
              b: chunks => (
                <Typography fontWeight={600} display="inline">
                  {chunks}
                </Typography>
              ),
              deletionDate,
              deletionDayOfWeek,
            }}
          />
        </Typography>
      </Box>
    </Stack>
  )
}
