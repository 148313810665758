import type { BulkLoadPartial } from '../../../../types'

// return a set of loads tha can be added to all orders
export function useComputeAddablePartialLoads(
  partial: BulkLoadPartial[],
  loadsDistribution: Record<string, string[]>,
  maxLoadsForOrder: number,
) {
  return partial.reduce<Set<string>>((acc, partialLoad) => {
    // check if adding the partial load will still holds maxLoadsForOrder
    const addable = Object.values(loadsDistribution).every(loads => {
      const count = loads.includes(partialLoad.name) ? loads.length : loads.length + 1
      return count <= maxLoadsForOrder
    })

    if (addable) {
      acc.add(partialLoad.name)
    }
    return acc
  }, new Set<string>())
}
