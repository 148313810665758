import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function RouteDetailsOn(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 17 16">
        <path d="M8.49967 1.33301C4.81967 1.33301 1.83301 4.31967 1.83301 7.99967C1.83301 11.6797 4.81967 14.6663 8.49967 14.6663C12.1797 14.6663 15.1663 11.6797 15.1663 7.99967C15.1663 4.31967 12.1797 1.33301 8.49967 1.33301ZM9.16634 11.333H7.83301V7.33301H9.16634V11.333ZM9.16634 5.99967H7.83301V4.66634H9.16634V5.99967Z" />
      </svg>
    </IconContainer>
  )
}
