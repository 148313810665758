import type { FormErrors, FormFields } from './formFields'
import { Box, Button, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useDisableSubmit } from '@/components/formUi/hooks/useDisableSubmit'
import { useTexts } from './hooks/useTexts'

type Props = {
  onDiscard: () => void
  onSubmit: () => void
}

export function DriverAssistanceFormFooter(props: Props) {
  const { onDiscard, onSubmit } = props
  const texts = useTexts()
  const theme = useTheme()
  const { disableSubmit, submitting } = useDisableSubmit<FormFields, FormErrors>()

  return (
    <Box
      gap={theme.spacing(2)}
      display="flex"
      component="footer"
      paddingTop={4}
      paddingLeft={4}
      paddingRight={4}
      flexDirection="row"
      justifyContent="flex-start"
    >
      <LoadingButton
        size="medium"
        type="submit"
        color="primary"
        variant="contained"
        onClick={onSubmit}
        loading={submitting}
        disabled={disableSubmit}
        data-testid="driver-assistance-form-send-button"
      >
        {texts.save}
      </LoadingButton>
      <Button
        type="button"
        size="medium"
        color="primary"
        onClick={onDiscard}
        variant="text"
        data-testid="driver-assistance-form-cancel-button"
      >
        {texts.cancel}
      </Button>
    </Box>
  )
}
