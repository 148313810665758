import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Flash(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M2.70819928,16 C2.65123858,16 2.59285396,15.9870648 2.53874149,15.9583206 C2.38352332,15.8749624 2.30947442,15.6938735 2.36073904,15.5257198 L4.19060163,9.47218211 L0.364266882,9.47218211 C0.223289072,9.47218211 0.0965516176,9.39026111 0.0353187799,9.26378644 C-0.024489767,9.13587465 -0.0074015587,8.9849671 0.0794634093,8.87430185 L7.02439599,0.137488943 C7.13404524,0.000954061902 7.32201553,-0.039287879 7.47580941,0.0411960028 C7.63102757,0.121679885 7.7065004,0.299894194 7.65950792,0.468047927 L5.95211101,6.52877158 L9.63604402,6.52877158 C9.77559754,6.52877158 9.90375928,6.60925546 9.96356783,6.73716725 C10.0248007,6.86507941 10.0077125,7.0159866 9.92084749,7.12665184 L2.99300275,15.8620276 C2.92180206,15.9525717 2.8164246,16 2.70819928,16 Z" />
      </svg>
    </IconContainer>
  )
}
