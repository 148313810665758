import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    setupTime: {
      title: (type: 'preparation' | 'closeOut') =>
        translate({
          id:
            type === 'preparation'
              ? 'scheduler.popContent.setupTime.title.preparation'
              : 'scheduler.popContent.setupTime.title.closeOut',
        }),
      startAt: translate({ id: 'scheduler.popContent.setupTime.startAt' }),
      timeSpan: translate({ id: 'scheduler.popContent.setupTime.timeSpan' }),
      endAt: translate({ id: 'scheduler.popContent.setupTime.endAt' }),
    },
  }))

  return api
}
