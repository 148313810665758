import { useMemo } from 'react'
import { type GridColDef, type GridColumns, type GridValueGetterParams } from '@mui/x-data-grid-pro'

import { NameCell } from '../columns/NameCell'
import { StatusCell } from '../columns/StatusCell'
import { useTexts } from './useTexts'

export function useColumns() {
  const texts = useTexts()

  return useMemo<GridColumns<uui.domain.client.rm.Company>>(() => {
    const nameColumn: GridColDef<uui.domain.client.rm.Company> = {
      valueGetter: (params: GridValueGetterParams) => params.row.name,
      headerName: texts.headers.name,
      renderCell: NameCell,
      field: 'name',
      hide: false,

      hideable: false,
      pinnable: false,
      filterable: false,
      editable: false,
      resizable: false,

      disableColumnMenu: true,
      flex: 1,
    }

    const statusColumn: GridColDef<uui.domain.client.rm.Company> = {
      valueGetter: (params: GridValueGetterParams) => params.row.enabled,
      headerName: texts.headers.status,
      renderCell: StatusCell,
      field: 'status',
      hideable: false,
      pinnable: false,
      filterable: false,
      editable: false,
      resizable: false,
      disableColumnMenu: true,

      flex: 1,
      cellClassName: 'no-focus-outline',
      sortComparator: (e1: boolean, e2: boolean) => {
        if (e1 === e2) return 0
        return e1 ? -1 : 1
      },
    }

    return [nameColumn, statusColumn]
  }, [texts])
}
