import { useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

type ModalState = 'ready'
interface ModalData {
  previewUrl: string
}

export const modalId = 'trackingPagePreview'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = (previewUrl: string) => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        previewUrl,
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)

  return ctrl
}
