import type { MouseEventHandler } from 'react'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material'

import { theme } from '@/styles'
import { ImportOrders } from '@/icons'
import { useAppDispatch } from '@/store'
import { importWizardSession } from '@/utils'
import { useImportWizardLink } from '@/routing'
import { Tooltip } from '@/components/primitives/Tooltip'
import { selectUserConfiguration } from '@/features/domain/user'
import { startImportWizard } from '@/features/domain/transaction'

import { useTexts } from './useTexts'

interface Props {
  disabled: boolean
}

interface ComponentProps {
  onClick: MouseEventHandler<HTMLDivElement>
}

const ImportWizardButtonComponent = styled('div', {
  name: 'ImportWizardButton',
})<ComponentProps>(() => ({
  border: 0,
  outline: 0,
  padding: 8,
  display: 'flex',
  borderRadius: 2.5,
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  backgroundColor: 'transparent',

  '&:hover': {
    backgroundColor: theme.colors.$silver,
  },
}))

export function ImportWizardButton(props: Props) {
  const { disabled } = props

  const { planType } = useSelector(selectUserConfiguration)
  const url = useImportWizardLink()
  const dispatch = useAppDispatch()
  const texts = useTexts()

  const setImportWizardCookie = useCallback(async () => {
    if (!['operations', 'simulation'].includes(planType)) {
      throw new Error(
        `It is not possible to import orders when planType = ${planType}. It should not happen`,
      )
    }

    // Retrieve cookie content from server-data
    const response = await dispatch(startImportWizard(globalThis.location.href))

    if (startImportWizard.rejected.match(response) || !response.payload) {
      throw new Error('Request failed')
    }

    importWizardSession.initializeImport(response.payload)

    // Redirect to import wizard
    window.location.href = url
  }, [dispatch, planType, url])

  const onClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    e => {
      e.stopPropagation()
      setImportWizardCookie()
    },
    [setImportWizardCookie],
  )

  return (
    <Tooltip placement="top" title={texts.addOrders} disabled={disabled}>
      <ImportWizardButtonComponent
        onClick={onClick}
        data-trackid="sidebar-operations__import-orders-button"
        data-testid="sidebar-operations__import-orders-button"
      >
        <ImportOrders size={14} color={disabled ? '$silver' : '$nightRider'} />
      </ImportWizardButtonComponent>
    </Tooltip>
  )
}
