import type { MutableRefObject } from 'react'

import { useCallback, useMemo } from 'react'

export function useTerritories(
  fieldValue: uui.domain.client.rm.CourierFilter,
  apiChange: (fieldValue: uui.domain.client.rm.CourierFilter) => void,
  territoriesConfig: uui.domain.client.rm.TerritoriesDriversAndVehicles,
  territoryNames: Record<string, string>, // <territoryId, territoryName>
  onCloseDialog: () => void,
  rootRef: MutableRefObject<HTMLDivElement | null>,
) {
  const onAddTerritory = useCallback(
    (territoryId: string) => {
      const territory = territoriesConfig[territoryId]
      if (!territory) {
        throw new Error('cannot find territory with provided territoryId')
      }
      if (fieldValue[territoryId]) {
        throw new Error('territoryId is already set into field value')
      }

      apiChange({
        ...fieldValue,
        [territoryId]: {
          driverIds: ['*'],
          vehicleIds: ['*'],
        },
      })
      onCloseDialog()

      if (rootRef.current) {
        scrollTerritoryIntoView(rootRef.current, territoryId)
      }
    },
    [fieldValue, apiChange, territoriesConfig, onCloseDialog, rootRef],
  )

  const options = useMemo(() => {
    const opts: { id: string; label: string; disabled: boolean }[] = []
    const keys = Object.keys(territoryNames)

    for (const id of keys) {
      // skip option that has already set
      const disabled = !!fieldValue[id]

      opts.push({
        id,
        label: territoryNames[id],
        disabled,
      })
    }

    return opts
  }, [territoryNames, fieldValue])

  const canAddNewTerritory = useMemo(() => {
    return Object.keys(territoriesConfig).some(id => !fieldValue[id])
  }, [fieldValue, territoriesConfig])

  return {
    options,
    onAddTerritory,
    canAddNewTerritory,
  }
}

function scrollTerritoryIntoView(root: HTMLDivElement, territoryId: string) {
  const item = root.querySelector(`[data-id="${territoryId}"]`)

  item?.scrollIntoView()
}
