import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { selectRegionMapStyles, setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

export function useHideRegionWhileEditing(editing: boolean, regionId?: string) {
  const dispatch = useAppDispatch()

  // ATTENTION: this REF is intentionally created and not updated
  const rOriginalMapStyle = useRef(useSelector(selectRegionMapStyles))

  useEffect(() => {
    if (!regionId || !editing) return

    // access previously active region Map Styles
    const originalMapStyle = rOriginalMapStyle.current
    const customMode: uui.domain.ui.map.markers.MapMode | undefined =
      originalMapStyle.custom[regionId]

    if (customMode === 'off') return

    // hide the feature
    dispatch(
      setCustomMapStyle({
        customStyle: {
          type: 'region',
          mode: 'off',
          ids: [regionId],
        },
      }),
    )

    return () => {
      dispatch(
        setCustomMapStyle({
          customStyle: {
            type: 'region',
            mode: customMode ?? 'on',
            ids: [regionId],
          },
        }),
      )
    }
  }, [dispatch, editing, regionId])
}
