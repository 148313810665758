import type { TerritoryDetails } from './useTerritoriesDetails'

import { Box, Typography } from '@mui/material'

interface Props {
  detail: TerritoryDetails
}

export function TerritoryDetail(props: Props) {
  const { detail } = props
  return (
    <Box data-testid="courier-territory">
      <Typography variant="caption" color="textSecondary">
        {detail.name}
      </Typography>
      <br />
      <Typography variant="caption" color="textSecondary">{`- ${detail.vehicles}`}</Typography>
      <br />
      <Typography variant="caption" color="textSecondary">{`- ${detail.drivers}`}</Typography>
    </Box>
  )
}
