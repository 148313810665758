import { useSelector } from 'react-redux'
import { selectTerritoryLicensingLimits } from '@/features/domain/territory'
import { useIsSimulation } from './useIsSimulation'

export function useOrdersLimit() {
  const territoryLicensingLimits = useSelector(selectTerritoryLicensingLimits)
  const isSimulation = useIsSimulation()

  return isSimulation ? territoryLicensingLimits.ordersSim : territoryLicensingLimits.ordersOps
}
