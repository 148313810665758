import { PureComponent, ChangeEvent } from 'react'

import Radio from './Radio'
import RadioHack from './RadioHack'
import RadioContainer from './RadioContainer'
import RadioLabel from './RadioLabel'
import RadioLabelText from './RadioLabelText'
import RadioLabelExtraText from './RadioLabelExtraText'
import RadioGroupContainer from './RadioGroupContainer'

import { Props } from './typings'

export default class RadioGroup extends PureComponent<Props> {
  private handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props
    const {
      target: { value },
    } = event
    onChange(value)
  }

  private renderOptions() {
    const { values, value: selectedValue, name, disabled: parentDisabled } = this.props

    return values.map(({ disabled: itemDisabled, label, value, extraLabel }) => {
      const selected: boolean = selectedValue === value
      const disabled: boolean = !!parentDisabled || !!itemDisabled

      return (
        <RadioLabel key={value} disabled={disabled}>
          <RadioContainer>
            <RadioHack selected={selected} />
            <Radio
              name={name}
              value={value}
              checked={selected}
              onChange={this.handleOptionChange}
              disabled={disabled}
            />
          </RadioContainer>
          <RadioLabelText selected={selected}>
            {label}
            {!!extraLabel && <RadioLabelExtraText>{extraLabel}</RadioLabelExtraText>}
          </RadioLabelText>
        </RadioLabel>
      )
    })
  }

  render() {
    return <RadioGroupContainer>{this.renderOptions()}</RadioGroupContainer>
  }
}
