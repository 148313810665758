import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { timeUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export function useFormattedDate(startTs: number) {
  const userConfig = useSelector(selectUserConfiguration)
  const formatDate = timeUtils.formatDateTime(userConfig)

  return useMemo(() => {
    return formatDate(new Date(startTs))
  }, [startTs, formatDate])
}
