import styled from 'styled-components'
import ActionButton, { Props } from '../../../ActionButton'

const SecondaryColumnHeaderActionButton = styled(ActionButton)<Props>`
  color: ${p => p.theme.colors.$nightRider};
  font-size: ${p => p.theme.fonts.$xs};
  font-weight: ${p => p.theme.weights.$semiBold};
  padding: 0;

  &.is-active,
  &:hover:not(:disabled) {
    color: ${p => p.theme.colors.$nightRider};
  }

  .o-button__ico {
    width: 20px;
    height: 20px;
  }

  &:not(.is-rounded) .o-button__ico {
    width: 20px;
    height: 20px;
  }

  &:hover:not(:disabled) .o-button__ico {
    color: ${p => p.theme.colors.$nightRider};
    transform: translateX(-50%) scale(1);
  }

  &:disabled {
    cursor: not-allowed;
  }
`

SecondaryColumnHeaderActionButton.displayName = 'SecondaryColumnHeaderActionButton'
export default SecondaryColumnHeaderActionButton
