import { PureComponent } from 'react'
import { Field as FinalFormField, FieldRenderProps, FieldProps } from 'react-final-form'

import { RestoreFieldButton, Block, Label, FieldMessage } from '@/forms-legacy'
import RadioGroup, { Props as RadioGroupProps } from './primitives/RadioGroup'

export interface Props extends FieldProps<string, any> {
  name: string
  label: string
  info?: string
  half?: boolean
  wrappedInputProps: Omit<RadioGroupProps, 'onChange' | 'value' | 'name'>
  // ATTENTION: Supported only by some Form Fields -- `hideRestoreButton` Added as part of the Guest Role feature
  hideRestoreButton?: boolean
}

export default class RadioGroupField extends PureComponent<Props> {
  private renderField = (formProps: FieldRenderProps<string>) => {
    const { label, name, info, half = false, wrappedInputProps, hideRestoreButton } = this.props
    const {
      input: { value, onChange },
      meta: { error, dirty, initial },
    } = formProps

    return (
      <Block half={half} error={!!error} dirty={!!dirty}>
        <Label htmlFor={name}>
          {label}
          {!hideRestoreButton && (
            <RestoreFieldButton
              dirty={dirty}
              initial={initial}
              onChange={onChange}
              style={{ marginLeft: 12 }}
            />
          )}
        </Label>
        <RadioGroup {...wrappedInputProps} name={name} onChange={onChange} value={value} />
        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }

  render() {
    const { name, ...props } = this.props
    return <FinalFormField {...props} name={name} render={this.renderField} />
  }
}
