import type { AddLoadOption as OptionProps } from '../../../types'

import { Box, Stack, Typography } from '@mui/material'

type Props = OptionProps & {
  selected: boolean
  ordersCount: number
}

export function Option(props: Props) {
  const { label, selected, ordersCount, shareCount, newOption } = props

  return (
    <Box
      key={label}
      paddingLeft={2}
      paddingRight={2}
      width="100%"
      sx={{ background: selected ? 'action.hover' : undefined }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        {newOption ? (
          <Typography variant="body1" color="primary.main">
            {label.toLocaleUpperCase()}
          </Typography>
        ) : (
          <Typography variant="body1" color="text.primary">
            {label}
          </Typography>
        )}

        {shareCount > 0 && (
          <Typography variant="body2" color="text.disabled">
            {shareCount}/{ordersCount}
          </Typography>
        )}
      </Stack>
    </Box>
  )
}
