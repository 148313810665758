import { useSelector } from 'react-redux'
import { selectRoutes } from '@/features/domain/route'

export function useExtendedRoute(
  routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable,
): uui.domain.client.rm.ExtendedRoute | undefined {
  const extRoutes = useSelector(selectRoutes)

  return extRoutes[routeInfo.id]
}
