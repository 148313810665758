import { castDraft } from 'immer'
import { computeChangesSummary } from '../computeChangesSummary'

import { patchRm360DataTelematics } from './patchRm360DataTelematics'
import { patchRm360DataWwgps } from './patchRm360DataWwgps'

export function patchRm360Data(
  trackingProvider: uui.domain.server.gps.TrackingProvider,
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  formValues: uui.domain.ui.forms.Rm360VehicleFormValues,
  deviceAggregateInfo: Record<string, uui.domain.client.gps.wwgps.DeviceAggregateInfo>,
  metric: boolean,
) {
  if (trackingProvider === 'none') throw new Error('trackingProvider is required')

  const [result, patches] =
    trackingProvider === 'WWGPS'
      ? patchRm360DataWwgps(unifiedVehicle, formValues, deviceAggregateInfo, metric)
      : patchRm360DataTelematics(unifiedVehicle, formValues)

  return { unifiedVehicle: castDraft(result), summary: computeChangesSummary(patches) }
}
