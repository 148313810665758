import type { PublicProps as Props } from './types'

import { useEditing } from './hooks/useEditing'
import { PreferencesView } from './PreferencesView'
import { PreferencesForm } from './PreferencesForm'

export function Preferences(props: Props) {
  const {
    notificationPreferences,
    supportedLanguages,
    updatePreferences,
    setEditingState,
    inEditing,
  } = props
  const { onCancel, onEdit } = useEditing(setEditingState)

  switch (inEditing) {
    case true:
      return (
        <PreferencesForm
          onCancel={onCancel}
          formValues={notificationPreferences}
          updatePreferences={updatePreferences}
          supportedLanguages={supportedLanguages}
        />
      )
    case false:
      return (
        <PreferencesView
          onEdit={onEdit}
          formValues={notificationPreferences}
          supportedLanguages={supportedLanguages}
        />
      )
  }
}
