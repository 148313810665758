import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserProfile } from '@/features/domain/user'

export function useToken() {
  const userProfile = useSelector(selectUserProfile)

  return useMemo(() => {
    return userProfile.gpsConsoleJwt
  }, [userProfile])
}
