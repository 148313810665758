import type { RecurrencesMap } from '../../typings'
import type { MonthCalendarPublicProps, MonthlyValidSelection } from '@/components/Calendar'

import { useSelector } from 'react-redux'
import { useMemo, useCallback } from 'react'

import { parse, format, setDate, getDate } from 'date-fns/esm'
import { selectUserConfiguration } from '@/features/domain/user'

const now = new Date()

export function useMonthlyCalendarProps(
  recurrenceMap: RecurrencesMap,
  onChangeCallback: (date: Date) => void,
  date: Date | null,
): MonthCalendarPublicProps {
  const { today } = useSelector(selectUserConfiguration)
  const todayDate = parse(today, 'yyyyMMdd', now)

  const selection = useMemo<MonthlyValidSelection>(
    () => getDate(date || todayDate) as MonthlyValidSelection,
    [date, todayDate],
  )

  const onChange = useCallback(
    (newSelection: MonthlyValidSelection, currentSelection?: MonthlyValidSelection) => {
      if (currentSelection && currentSelection === newSelection) {
        return
      }
      const epoch = new Date(1970, 0, 1)
      onChangeCallback(setDate(epoch, newSelection))
    },
    [onChangeCallback],
  )

  return useMemo(() => {
    const daysWithRecurrences =
      !!recurrenceMap && recurrenceMap['MONTHLY']
        ? recurrenceMap['MONTHLY'].reduce((acc, curr) => {
            if (curr.value) {
              const key = format(curr.value, 'yyyyMMdd')
              acc[key] = 1
            }
            return acc
          }, {})
        : undefined

    return {
      today,
      layout: 'monthly',
      selection,
      onChange,
      highlightedDays: daysWithRecurrences,
    }
  }, [onChange, recurrenceMap, today, selection])
}
