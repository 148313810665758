import type { ExecutionEventMarkers } from '../executionEventMarkers'

import { snapshot } from 'valtio'

import { executionEventMarkers } from '../executionEventMarkers'
import { clearExecutionEventMarkers } from './clearExecutionEventMarkers'

export type SetExecutionEventMarkers = (
  prev: workwave.DeepReadonly<ExecutionEventMarkers>,
) => ExecutionEventMarkers

export type SetExecutionEventMarkersParam =
  | SetExecutionEventMarkers
  | Partial<ExecutionEventMarkers>
  | 'reset'

export function setExecutionEventMarkers(valueOrFunc: SetExecutionEventMarkersParam) {
  // reset
  if (valueOrFunc === 'reset') return clearExecutionEventMarkers()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(executionEventMarkers, valueOrFunc(snapshot(executionEventMarkers)))
  } else {
    Object.assign(executionEventMarkers, valueOrFunc)
  }

  return snapshot(executionEventMarkers)
}
