import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    labelName: translate({ id: 'setup.traffic.edit.labels.name' }),
    edit: translate({ id: 'setup.traffic.edit.title' }),
    drivingTime: translate({ id: 'setup.traffic.defaultDrivingTimeLabelText' }),
    timeWindows: translate({ id: 'setup.traffic.timeWindowsDrivingTimeText' }),
  }))

  return api
}
