import { useSelector } from 'react-redux'
import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { getCalendarizedSettings } from '@/server-data'

export function useVehicleSettings(vehicleId: string, date: Date) {
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const unifiedVehicles = useSelector(selectVehicles)
  const uvId = vehiclesByVehicleId[vehicleId]

  if (!uvId) {
    throw new Error('Error while trying to retrieve working hours for a unexistent vehicle')
  }

  const uv = unifiedVehicles[uvId]

  if (!uv) {
    throw new Error('Error while trying to retrieve working hours for a unexistent vehicle')
  }

  if (!uv.hasRoutingLicense) {
    throw new Error(
      'Error while trying to retrieve working hours for vehicle without driving license',
    )
  }

  return getCalendarizedSettings(uv.vehicle, date)
}
