import type { CourierFilterError } from '../../../../types'

import { TerritoryConfig } from './components/TerritoryConfig'

import { useTerritories } from './hooks/useTerritories'
import { useActions } from './hooks/useActions'

interface Props {
  fieldValue: uui.domain.client.rm.CourierFilter
  apiChange: (value: uui.domain.client.rm.CourierFilter) => void
  error?: CourierFilterError
  territoriesConfig: uui.domain.client.rm.TerritoriesDriversAndVehicles
  territoryNames: Record<string, string>
}

export function TerritoriesList(props: Props) {
  const { fieldValue, error, apiChange, territoriesConfig, territoryNames } = props
  const territories = useTerritories(fieldValue, territoryNames)
  const { onChange, onRemoveTerritory } = useActions(fieldValue, apiChange)

  return (
    <>
      {territories.map(t => (
        <TerritoryConfig
          value={t.config}
          key={t.territoryId}
          onChange={onChange}
          territoryId={t.territoryId}
          error={error?.territoryErrors?.[t.territoryId]}
          territoryName={t.territoryName}
          onRemoveTerritory={onRemoveTerritory}
          territoriesConfig={territoriesConfig}
        />
      ))}
    </>
  )
}
