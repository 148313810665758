import { Box } from '@mui/material'
import { useSettingsEditing } from '../../hooks/useSettingsEditing'
import { useRefreshRmAccountData } from './hooks/useRefreshRmAccountData'

import { RmAccountForm } from './RmAccountForm'
import { RmAccountView } from './RmAccountView'

export function RmAccount() {
  // Refresh the Api Key every time a user accesses the Account section
  useRefreshRmAccountData()

  const { editing, onEdit, onDiscard } = useSettingsEditing('rm-account', 'reset')

  return (
    <Box display="flex" width="100%" height="100%">
      {!editing && <RmAccountView onEdit={onEdit} />}
      {editing && <RmAccountForm onDiscard={onDiscard} />}
    </Box>
  )
}
