import { FormColumn } from '@/components/layout'

import { List } from './components/List'
import { Header } from './components/Header'
import { useEditTrafficProfileModal } from './components/EditTrafficProfileModal'

interface Props {
  trafficProfile: uui.domain.client.rm.TrafficProfile
}

export function ListOfTrafficAreas(props: Props) {
  const { trafficProfile } = props
  const { Modal: EditTrafficProfileModal, show: showModal } = useEditTrafficProfileModal()

  return (
    <FormColumn width={320} testid="form__column-primary">
      <Header trafficProfile={trafficProfile} onShowEditTrafficProfileModal={showModal} />
      <List />
      <EditTrafficProfileModal trafficProfile={trafficProfile} />
    </FormColumn>
  )
}
