/**
 * Create a new location pin from scratch
 */
export function createLocationPin(
  id: uui.domain.ui.map.LocationPinId,
  latLng: uui.domain.LatLng,
  pin?: Partial<uui.domain.ui.map.LocationPin['pin']>,
  info?: uui.domain.ui.map.LocationPin['info'],
  hidden: boolean = false,
  pinLabel: string | undefined = undefined,
): uui.domain.ui.map.LocationPin {
  if (id === 'geofenceFormPin') {
    const geofenceInfo = info as uui.domain.ui.map.LocationPinGeofenceInfo

    if (!geofenceInfo?.source.type || !geofenceInfo.source.name)
      throw new Error('A geofence Location Pin require a correct info to be instanced')

    return {
      id,
      hidden,
      pin: {
        radius: 0,
        type: 'normal',
        status: 'normal',
        ...pin,
        latLng,
      },
      label: pinLabel,
      readonly: false,
      info: { ...geofenceInfo },
    }
  }

  return {
    id,
    hidden,
    pin: {
      radius: 0,
      type: 'normal',
      status: 'normal',
      ...pin,
      latLng,
    },
    label: pinLabel,
    readonly: false,
    info: { ...info },
  }
}
