import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    layout: translate({ id: 'app.header.layout' }),
    map: translate({ id: 'app.header.layout.map' }),
    layoutDisabled: translate({ id: 'app.header.layout.disabled' }),
    scheduler: translate({ id: 'app.header.layout.scheduler' }),
    orderGrid: translate({ id: 'app.header.layout.orderGrid' }),
    splitView: translate({ id: 'app.header.layout.splitView' }),
    extractMap: translate({ id: 'app.header.layout.extractMap' }),
    extractedMap: translate({ id: 'app.header.layout.extractedMap' }),
    extractedMapGotoTab: translate({ id: 'app.header.layout.extractedMap.gotoTab' }),
    extractedMapCloseTab: translate({ id: 'app.header.layout.extractedMap.closeTab' }),
  }))

  return api
}
