import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { conversionUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export const useConvertSpeed = (
  metersPerSeconds: number,
  decimals?: number,
  printLabel?: boolean,
) => {
  const userConfig = useSelector(selectUserConfiguration)

  return useMemo(
    () => conversionUtils.convertSpeed(userConfig)(metersPerSeconds, decimals, printLabel),
    [userConfig, metersPerSeconds, decimals, printLabel],
  )
}
