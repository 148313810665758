import styled from 'styled-components'
import Checkbox from './Checkbox'

const SIZE = 14
const BORDER_SIZE = 1
const ICO_SIZE = 10

export type { Props } from './Checkbox'

const StyledCheckbox = styled(Checkbox)`
  position: relative;
  width: ${SIZE}px;
  height: ${SIZE}px;
  display: block;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    user-select: none;
  }

  .o-checkbox__field {
    opacity: 0;
    z-index: 10;
    user-select: none;
    cursor: pointer;
  }

  &.is-disabled .o-checkbox__field {
    cursor: not-allowed;
  }

  &.is-disabled .o-checkbox__hack {
    background: ${p => p.theme.colors.$nightRider};
  }

  .o-checkbox__hack {
    border: ${BORDER_SIZE}px solid ${p => p.theme.colors.$nightRider};
    background: ${p => p.theme.colors.$pureWhite};
    color: ${p => p.theme.colors.$pureWhite};
    z-index: 5;
    transition: 0.3s ease;
    transition-property: background, border-color;
  }

  .o-checkbox__ico {
    display: block;
    width: ${ICO_SIZE}px;
    margin-top: ${(SIZE - (ICO_SIZE + 2 * BORDER_SIZE)) / 2}px;
    margin-left: auto;
    margin-right: auto;
    transform: scale(0);
    transition: transform 0.3s ease;
  }

  .o-checkbox__hack.is-checked {
    background: ${p => p.theme.colors.$pigmentGreen};
    border-color: ${p => p.theme.colors.$pigmentGreen};
  }

  .o-checkbox__hack.is-checked .o-checkbox__ico {
    transform: scale(1);
  }

  &:not(.is-disabled):hover .o-checkbox__hack {
    border-color: ${p => p.theme.colors.$pigmentGreen};
  }

  &:not(.is-disabled):hover .o-checkbox__hack.is-checked .o-checkbox__ico {
    transform: scale(0.6);
  }
`

StyledCheckbox.displayName = 'StyledCheckbox'

export default StyledCheckbox
