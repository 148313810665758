import { useEffect } from 'react'

import { resetCrudSelection } from '@/atoms'

import { resetPendingFitMap } from '@/map'
import { useAppDispatch } from '@/store'
import { setCustomMapStyle, updateMapStyle } from '@/features/domain/ui'

import { useTrafficProfile } from './useTrafficProfile'

export function useEnsureTrafficRegionVisibility() {
  const dispatch = useAppDispatch()
  const trafficProfile = useTrafficProfile()

  useEffect(() => {
    const ids = trafficProfile?.regions.map(area => area.id) ?? []

    if (ids.length === 0) return

    dispatch(
      setCustomMapStyle({
        customStyle: {
          ids,
          mode: 'on',
          type: 'trafficProfile',
        },
      }),
    )

    return () => {
      resetCrudSelection('trafficArea')
      resetPendingFitMap()
      dispatch(
        updateMapStyle({
          mode: 'off',
          type: 'trafficProfile',
        }),
      )
    }
  }, [dispatch, trafficProfile])
}
