import type { PublicProps as Props } from './types'
import { Field as FinalFormField, FieldRenderProps } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import { DrawRoadSegmentField } from './DrawRoadSegmentField'

export function DrawRoadSegmentFieldWrapper(props: Props) {
  const { name } = props

  return (
    <FinalFormField
      {...props}
      name={name}
      isEqual={isDeepEqual}
      render={(
        formProps: FieldRenderProps<{ start: uui.domain.LatLng; end: uui.domain.LatLng }>,
      ) => {
        return <DrawRoadSegmentField {...props} formProps={formProps} />
      }}
    />
  )
}
