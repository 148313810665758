import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    errorGeocodingGeofences: translate({ id: 'gps.geofences.list.geocodingError' }),
  }))

  return api
}
