import type { FormErrors, FormFields } from './formFields'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Box, IconButton, Stack } from '@mui/material'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { Add } from '@mui/icons-material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { selectMaxReasonsForNotCompletedOrders } from '@/features/domain/territory'

import { ReasonsForNotCompletedOrdersCount } from '../ReasonsForNotCompletedOrdersCount'

import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'
import { ReasonsForNotCompletedOrdersMenu } from './ReasonsForNotCompletedOrdersMenu'

export function ReasonsForNotCompletedOrdersFormHeader() {
  const actions = useActions()
  const texts = useTexts()
  const maxAllowedReasons = useSelector(selectMaxReasonsForNotCompletedOrders)

  const {
    field: { value },
    formApi,
  } = useFormField<'reasonsForNotCompletedOrders', FormFields, FormErrors>(
    'reasonsForNotCompletedOrders',
  )

  const { submitting, disabled } = formApi.getMeta()
  const formDisabled = submitting || disabled

  const addIsBlocked = formDisabled || value.length === maxAllowedReasons

  const handleAddClick = useCallback(() => {
    if (addIsBlocked) return

    actions.addReason()
  }, [actions, addIsBlocked])

  const addTooltipTitle = addIsBlocked ? texts.maxReasonsReached(maxAllowedReasons) : texts.add

  return (
    <Stack direction="row">
      <ReasonsForNotCompletedOrdersCount reasonsCount={value.length} />
      <Box marginLeft="auto" display="flex" alignItems="center">
        <Tooltip title={addTooltipTitle} placement="top">
          <IconButton
            onClick={handleAddClick}
            disabled={addIsBlocked}
            data-testid="reason-for-orders_add-reason"
          >
            <Add />
          </IconButton>
        </Tooltip>
        <ReasonsForNotCompletedOrdersMenu />
      </Box>
    </Stack>
  )
}
