import { GeofenceAvatarIcon } from './GeofenceAvatarIcon'

interface Props {
  type: 'arriving' | 'departing' | 'arr_dep'
  selected?: boolean
  marginRight: number
  size: number
}

export function GeofenceAvatar(props: Props) {
  const { size, type, marginRight, selected = false } = props

  return (
    <div>
      <GeofenceAvatarIcon
        type={type}
        size={size - 2}
        selected={selected}
        marginRight={marginRight}
      />
    </div>
  )
}
