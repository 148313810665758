import type { RadioGroupValue } from '@/forms-legacy'
import type { GeofenceFormValues } from '../../types'

import { useMemo } from 'react'
import { useFormState } from 'react-final-form'

import { HorizontalRadioGroupField } from '@/forms-legacy'
import { theme } from '@/styles'
import { useTexts } from './useTexts'

export function GeofenceType() {
  const { values } = useFormState()

  const texts = useTexts()

  const { type: geofenceType } = values as GeofenceFormValues

  const wrappedInputProps = useMemo(() => {
    const colors = {
      background: theme.colors.$pureWhite,
      backgroundSelected: theme.colors.$nightRider,
      color: theme.colors.$nightRider,
      colorSelected: theme.colors.$pureWhite,
      border: theme.colors.$nightRider,
      borderSize: 1,
    }

    const arriving: RadioGroupValue = {
      label: texts.arriving,
      value: 'arriving',
    }

    const departing: RadioGroupValue = {
      label: texts.departing,
      value: 'departing',
    }

    const arrivingDeparting: RadioGroupValue = {
      label: texts.arrDep,
      value: 'arr_dep',
    }

    return { colors, values: [arriving, departing, arrivingDeparting] }
  }, [texts])

  return (
    <HorizontalRadioGroupField
      name="type"
      label={texts.typeTitle}
      wrappedInputProps={wrappedInputProps}
      info={texts.typeDescription(geofenceType)}
    />
  )
}
