import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../renderingQueue'
import { mapAtom } from '../../atoms/map/map'
import { setLayerMetadata } from '../layerMetadata'
import { findLayer } from '../utils/findLayer'

import { createOrderFeature } from './utils/createOrderFeature'
import { setOrderLayerMetadata } from './orderLayerMetadata'

const nonblockingRender = createNonBlockingRenderByTime()

export const initializeOrderFeatures = async (
  markers: uui.domain.ui.map.markers.MapMarkers['order'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['order'],
  selection: Set<string>,
  selectedOrderIds: Set<string>,
) => {
  const map = mapAtom.map
  const layer = findLayer(map, 'order')

  const features: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const marker of Object.values(markers)) {
    await nonblockingRender.next()

    const selected = selection.has(marker.id)
    features.push(createOrderFeature(marker, mapStyles, selectedOrderIds, selected))
  }

  // add all new features to the layer
  layer.getSource()?.addFeatures(features)

  // keep track of selected items in the current layer
  setLayerMetadata(layer, 'selection', new Set(selection))
  setOrderLayerMetadata(layer, 'selectedOrderIds', selectedOrderIds)
}
