import { useCallback, useRef, useEffect, useLayoutEffect, useMemo } from 'react'

import { Scrollbar } from '@workwave-tidal/tidal/components'

import { ItemRenderPreset, DropDownItem, DropDownOption } from '../../typings'
import { isValidOption } from '../../utils'

import { OptionsContainer } from './elements/OptionsContainer'
import { ItemRendererDefaultSpec } from './itemRenderer'
import { getOnHandleOnKeyDown } from './keyboardHandlers'
import { Props } from './typings'

const getItemRendererSpec = (preset: ItemRenderPreset) => {
  switch (preset) {
    case 'default':
    default:
      return ItemRendererDefaultSpec
  }
}

const getItemHeightByPreset = (preset: ItemRenderPreset) => {
  switch (preset) {
    case 'default':
    default:
      return ItemRendererDefaultSpec.getItemHeight
  }
}

const getListHeight = (options: DropDownOption[], preset: ItemRenderPreset): number => {
  const getItemHeight = getItemHeightByPreset(preset)

  return options.reduce((acc, item, index) => acc + getItemHeight(item, index), 0)
}

const verticalPad = 20

export const Options = (props: Props) => {
  const {
    maxListHeight,
    options,
    itemRenderPreset,
    activeIndex,
    setActiveIndex,
    onChange,
    onHeaderClick,
    onSecondaryAction,
    closeList,
    ...rest
  } = props

  const propsRef = useRef(props)
  useEffect(() => {
    propsRef.current = props
  }, [props])

  const RendererSpec = useMemo(() => getItemRendererSpec(itemRenderPreset), [itemRenderPreset])
  const actualListHeight = useMemo(
    () => getListHeight(options, itemRenderPreset),
    [options, itemRenderPreset],
  )

  const keyDownRef = useRef<any>(undefined)

  useLayoutEffect(() => {
    keyDownRef.current = getOnHandleOnKeyDown(() => propsRef.current, scrollToElement)
    document.addEventListener('keydown', keyDownRef.current)

    const selectedItem = options.find(i => isValidOption(i) && (i as DropDownItem).selected)

    if (selectedItem) {
      const el = document.getElementById(selectedItem.id)
      if (el) {
        scrollToElement(el)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // unmount management
  useEffect(() => {
    return () => {
      if (keyDownRef.current) {
        document.removeEventListener('keydown', keyDownRef.current)
      }
      keyDownRef.current = undefined
    }
  }, [])

  const scrollToElement = useCallback((elem: HTMLElement) => {
    elem.scrollIntoView()
  }, [])

  const height = actualListHeight < maxListHeight ? actualListHeight : maxListHeight - verticalPad

  return (
    <OptionsContainer {...rest} padY={verticalPad / 2}>
      <div style={{ height, width: '100%' }}>
        <Scrollbar hideOverflow="x">
          {options.map((item, index) => {
            const highlighted = activeIndex === index

            return (
              <RendererSpec.renderer
                key={item.id}
                item={item}
                index={index}
                highlighted={highlighted}
                onHover={() => setActiveIndex(index)}
                onChange={(item, index, options, e) => {
                  const preventCloseList = onChange(item as DropDownItem, index, options, e)
                  if (!preventCloseList) {
                    closeList()
                  }
                }}
                onHeaderClick={onHeaderClick}
                onSecondaryAction={onSecondaryAction}
                options={options}
                data-testid="dropdown-list-option"
              />
            )
          })}
        </Scrollbar>
      </div>
    </OptionsContainer>
  )
}
