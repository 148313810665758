import type { MouseEvent } from 'react'
import type { FormErrors, FormFields } from '../formFields'

import { useFormApi, useFormMeta } from '@workwave-tidal/tidal/form-fairy'
import { Box, Button, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useTexts } from '../hooks/useTexts'

type Props = {
  creating: boolean
  onDiscard: () => void
  onSubmit: (event: MouseEvent<HTMLButtonElement>) => Promise<void>
}

export function Footer(props: Props) {
  const { onDiscard, onSubmit, creating } = props

  const {
    meta: { submitting, validating },
  } = useFormMeta<FormFields, FormErrors>()
  const formApi = useFormApi<FormFields, FormErrors>()
  const theme = useTheme()
  const texts = useTexts()

  const { status } = formApi.getMeta()

  return (
    <Box
      gap={theme.spacing(2)}
      display="flex"
      component="footer"
      paddingTop={4}
      paddingLeft={4}
      paddingRight={4}
      flexDirection="row"
      justifyContent="flex-start"
    >
      <LoadingButton
        size="medium"
        type="submit"
        color="primary"
        onClick={onSubmit}
        variant="contained"
        loading={submitting}
        disabled={submitting || validating || status === 'invalid'}
      >
        {creating ? texts.buttons.create : texts.buttons.save}
      </LoadingButton>
      <Button type="button" size="medium" color="primary" onClick={onDiscard} variant="text">
        {texts.buttons.cancel}
      </Button>
    </Box>
  )
}
