import styled from 'styled-components'

const styledComponent = styled.div.attrs({
  className: 'o-warning-overlay__dialog-title',
})`
  padding: 5px 0;
  font-size: 19px;
  font-weight: bold;
`

export default styledComponent
