import { Component, ReactNode } from 'react'

import { ReadonlyLabel, ReadonlyField, FlexRow } from '@/forms-legacy'

import { PublicPropsBulk as Props } from '../../typings'

import LoadPlaceholder from '../LoadPlaceholder'
import CollapseButton from '../CollapseButton'

import TextButton from './elements/TextButton'
import DeleteBtn from './elements/DeleteBtn'
import LoadLabel from './elements/LoadLabel'

interface State {
  collapsed: boolean
}

const toggleCollapsed =
  () =>
  ({ collapsed }: State) => ({
    collapsed: !collapsed,
  })

export default class PartialLoads extends Component<Props, State> {
  readonly state: State = {
    collapsed: false,
  }

  private handleToggleCollapsed = () => {
    this.setState(toggleCollapsed())
  }

  private handleRemoveAll = () => {
    const { onRemovePartialLoads, partial } = this.props
    onRemovePartialLoads(partial)
  }

  private renderPartialLoad = ([key, val]): ReactNode => {
    const { onRemovePartialLoads, total, onAddPartialLoads, forceDisableAddLoad, renderText } =
      this.props
    const load: uui.domain.client.rm.Order['loads'] = {
      [key]: 0,
    }
    return (
      <FlexRow key={key} style={{ marginBottom: 5 }}>
        <ReadonlyField style={{ justifyContent: 'space-between', height: 28 }}>
          <FlexRow useAutoWidth>
            <DeleteBtn style={{ marginRight: 10 }} onClick={() => onRemovePartialLoads(load)} />
            <LoadLabel bold>{key}</LoadLabel>
          </FlexRow>
          <LoadLabel>
            {val}/{total}
          </LoadLabel>
        </ReadonlyField>
        <TextButton
          style={{ marginLeft: 22 }}
          disabled={forceDisableAddLoad}
          onClick={() => onAddPartialLoads(load)}
        >
          {renderText('addToAll')}
        </TextButton>
      </FlexRow>
    )
  }

  render() {
    const { partialLoadsLabel, partial, renderText } = this.props
    const { collapsed } = this.state
    const partialCount: number = Object.keys(partial).length

    return (
      <div style={{ marginTop: 10 }}>
        <FlexRow>
          <ReadonlyLabel primary style={{ display: 'flex', alignItems: 'baseline' }}>
            {partialLoadsLabel}
            {partialCount > 0 && (
              <CollapseButton
                collapsed={collapsed}
                onToggle={this.handleToggleCollapsed}
                text={renderText('toggleButton', collapsed ? 1 : 0)}
              />
            )}
          </ReadonlyLabel>
          <TextButton style={{ marginLeft: 'auto' }} remove onClick={this.handleRemoveAll}>
            {renderText('removeLoads', partialCount)}
          </TextButton>
        </FlexRow>
        {partialCount === 0 && <LoadPlaceholder>{renderText('noLoads')}</LoadPlaceholder>}
        {!collapsed && Object.entries(partial).map(this.renderPartialLoad)}
      </div>
    )
  }
}
