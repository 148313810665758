import { proxy, subscribe } from 'valtio'

type ColumnSelection = string[]

type ColumnSelectionAtom = { selection: ColumnSelection }

// ---------------------------------------------------------------------------
// Default values
// ---------------------------------------------------------------------------
const defaultColumnSelection: ColumnSelection = [
  'name',
  'eligibilityType',
  'eligibilityDate',
  'forcevehicleid',
  'priority',
  'loads',
  'company',
  'P_address',
  'P_addresscheck',
  'P_lat',
  'P_lng',
  'P_notes',
  'P_servicetime',
  'P_tagsin',
  'P_tagsout',
  'P_tws_s',
  'P_tws_e',
  'P_tws_s_1',
  'P_tws_e_1',
  'P_tws_s_2',
  'P_tws_e_2',
  'P_customfields',
  'P_phone',
  'P_email',
  'P_barcodes',
  'D_address',
  'D_addresscheck',
  'D_lat',
  'D_lng',
  'D_notes',
  'D_servicetime',
  'D_tagsin',
  'D_tagsout',
  'D_tws_s',
  'D_tws_e',
  'D_tws_s_1',
  'D_tws_e_1',
  'D_tws_s_2',
  'D_tws_e_2',
  'D_customfields',
  'D_phone',
  'D_email',
  'D_barcodes',
  'S_address',
  'S_addresscheck',
  'S_lat',
  'S_lng',
  'S_notes',
  'S_servicetime',
  'S_tagsin',
  'S_tagsout',
  'S_tws_s',
  'S_tws_e',
  'S_tws_s_1',
  'S_tws_e_1',
  'S_tws_s_2',
  'S_tws_e_2',
  'S_customfields',
  'S_phone',
  'S_email',
  'S_barcodes',
]

// ---------------------------------------------------------------------------
// Restore from Local Storage
// ---------------------------------------------------------------------------

const localStorageId = 'routemanager/v1/atoms/exportOrdersModal/columnSelection'

const localStorageValue = localStorage.getItem(localStorageId)
const initialSelection = (
  localStorageValue ? JSON.parse(localStorageValue) : defaultColumnSelection
) as ColumnSelection

const createDefaultSelection = (): ColumnSelectionAtom => ({
  selection: initialSelection,
})

export const columnSelectionAtom = proxy<ColumnSelectionAtom>(createDefaultSelection())

export function resetColumnSelection() {
  columnSelectionAtom.selection = defaultColumnSelection
}

export const setColumnSelection = (value: string[]) => {
  columnSelectionAtom.selection = value
}

subscribe(columnSelectionAtom, () => {
  localStorage.setItem(localStorageId, JSON.stringify(columnSelectionAtom.selection))
})
