import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

export const exportRoutesFromCalendar = createAsyncThunk<
  // Return type of the payload creator
  string | uui.domain.actions.rpc.route.EmailExportResult | null,
  // First argument to the payload creator
  uui.domain.actions.rpc.route.ExportRoutesFromCalendarAction['payload'],
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/route/exportFromCalendar', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/route/exportFromCalendar', {
      category: 'rpc',
      type: 'rpc/route/exportFromCalendar',
      payload,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {exportRoutesFromCalendar}`,
      { tags: ['rpc', 'order'], info: { payload, error } },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Export routes from calendar [${payload.dates.join(
        ', ',
      )}] [${payload.territoryIds.join(', ')}] failed`,
      error,
    })
  }
})
