import { useCallback, useState } from 'react'
import { updateMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'showDevicesWithLabels' | 'showDevices' | 'hideDevices'
export function useActions() {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const showDevicesWithLabels = useCallback(async () => {
    setMethodInExecution('showDevicesWithLabels')

    await dispatch(updateMapStyle({ type: 'device', mode: 'label' }))

    setMethodInExecution(null)
  }, [dispatch])

  const showDevices = useCallback(async () => {
    setMethodInExecution('showDevices')

    await dispatch(updateMapStyle({ type: 'device', mode: 'on' }))

    setMethodInExecution(null)
  }, [dispatch])

  const hideDevices = useCallback(async () => {
    setMethodInExecution('hideDevices')

    await dispatch(updateMapStyle({ type: 'device', mode: 'off' }))

    setMethodInExecution(null)
  }, [dispatch])

  return { methodInExecution, actions: { showDevicesWithLabels, showDevices, hideDevices } }
}
