import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    cancel: translate({ id: 'settings.messagingPlan.phoneNumbersModal.controls.cancel' }),

    copyPhoneNumbersSuccess: translate({
      id: 'settings.messagingPlan.phoneNumbersModal.controls.copyNumbers.success',
    }),

    copyPhoneNumbersError: translate({
      id: 'settings.messagingPlan.phoneNumbersModal.controls.copyNumbers.error',
    }),

    copyNumbers: translate({
      id: 'settings.messagingPlan.phoneNumbersModal.controls.copyNumbers',
    }),
  }))

  return api
}
