import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCalendarRange } from '@/features/domain/ui'
import { selectDeviceEvents } from '@/features/domain/deviceEvents'
import { getDeviceId } from '@/server-data'
import { selectDrivers } from '@/features/domain/driver'

export function useEvents(vehicles: uui.domain.client.UnifiedVehicle[]) {
  const calendarRange = useSelector(selectCalendarRange)
  const deviceEvents = useSelector(selectDeviceEvents)
  const drivers = useSelector(selectDrivers)

  return useMemo(() => {
    if (calendarRange.minDate !== calendarRange.maxDate) return 0

    let eventsCount = 0

    for (const vehicle of vehicles) {
      const deviceId = getDeviceId(drivers, vehicle, calendarRange.minDate)

      if (!deviceId) continue

      eventsCount += Object.keys(deviceEvents?.[deviceId]?.[calendarRange.minDate] ?? []).length
    }

    return eventsCount
  }, [vehicles, drivers, calendarRange, deviceEvents])
}
