import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

/**
 * Delete company
 *
 * @private
 */
export const deleteCompany = createAsyncThunk<
  // Return type of the payload creator
  Record<string, string>,
  // First argument to the payload creator
  string,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/company/delete', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/company/delete', {
      category: 'rpc',
      type: 'rpc/company/delete',
      id: payload,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {deleteCompany}`,
      { tags: ['rpc', 'company'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Delete company failed`,
      error,
    })
  }
})
