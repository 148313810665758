import styled from 'styled-components'
import { RADIUS } from './BaseInputWrapper'

const MeridiemButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  background: ${p => p.theme.colors.$alabaster};
  border-top-right-radius: ${RADIUS}px;
  border-bottom-right-radius: ${RADIUS}px;
`

MeridiemButtonWrapper.displayName = 'MeridiemButtonWrapper'

export default MeridiemButtonWrapper
