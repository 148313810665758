import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Gps(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 26 26">
        <path d="M26 0L0 12h14v14" />
      </svg>
    </IconContainer>
  )
}
