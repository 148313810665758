import type { ItemWithPosition, ModifierKeys } from '../typings'
import { getItemIdOrGroupHeaderId } from './guards'
import { isRenderGroupHeader } from './isRenderGroupHeader'

type Params<T> = {
  clickedItemId: string
  modifiers: ModifierKeys
  prevSelectedIds: workwave.DeepReadonly<string[]>
  items: ItemWithPosition<T>[]
  getItemId: (item: T) => string
}

export const getUpdatedSelection = <T>(params: Params<T>): string[] => {
  const {
    items,
    getItemId,
    clickedItemId,
    prevSelectedIds,
    modifiers: { altKey, ctrlKey, metaKey, shiftKey },
  } = params

  let newIds: string[] = [...prevSelectedIds]

  if (ctrlKey || metaKey) {
    if (newIds.includes(clickedItemId)) {
      newIds = newIds.filter(id => id !== clickedItemId)
    } else {
      newIds.push(clickedItemId)
    }
  } else if (altKey) {
    newIds = newIds.filter(id => id !== clickedItemId)
  } else if (shiftKey) {
    const lastSelectedId =
      (!!newIds.length ? newIds[newIds.length - 1] : undefined) ?? clickedItemId

    const fromI = items.findIndex(
      ({ item }) => getItemIdOrGroupHeaderId(item, getItemId) === lastSelectedId,
    )
    const toI = items.findIndex(
      ({ item }) => getItemIdOrGroupHeaderId(item, getItemId) === clickedItemId,
    )
    const start = toI >= fromI ? fromI : toI
    const end = toI >= fromI ? toI : fromI
    const reverse = toI < fromI

    let selectedIds = items.slice(start, end + 1).reduce<string[]>((acc, { item }) => {
      if (!isRenderGroupHeader(item)) {
        acc.push(getItemIdOrGroupHeaderId(item, getItemId))
      }
      return acc
    }, [])
    selectedIds = reverse ? selectedIds.reverse() : selectedIds
    newIds = [...newIds, ...selectedIds]
  } else {
    newIds = [clickedItemId]
  }

  // removing duplicates
  newIds = newIds.filter((id, i) => newIds.lastIndexOf(id) === i)

  return newIds
}
