import styled from 'styled-components'
import { applyAlphaToColor } from '@/local/components'

export type Props = {
  htmlFor?: string
  className?: string
}

const InputFieldLabel = styled.label`
  display: block;
  color: ${p => applyAlphaToColor(p.theme.colors.$pureBlack, 0.8)};
  line-height: 1;
  font-size: ${p => p.theme.fonts.$p3};
`

InputFieldLabel.displayName = 'InputFieldLabel'

export default InputFieldLabel
