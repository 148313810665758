import { Stack, Typography } from '@mui/material'
import { type ColumnsPickerProps } from './types'
import { useColumnsPicker } from './hooks/useColumnsPicker'
import { AllToggler } from './components/AllToggler'
import { Column } from './components/Column'
import { NoResults } from './components/NoResults'
import { SearchBar } from './components/SearchBar'

export function ColumnsPicker(props: ColumnsPickerProps) {
  const { localeText, columns, selection, filter, onChangeFilter, warningCard } = props
  const { toggleAllProps, onChangeColumn } = useColumnsPicker(props)

  return (
    <Stack gap={2} paddingX={3} paddingY={4}>
      <Stack>
        <Typography variant="body1">{localeText.manageColumns}</Typography>
        <Typography variant="caption">{localeText.manageColumnsDescription}</Typography>
      </Stack>
      {!!warningCard && warningCard}
      <SearchBar filter={filter} onChangeFilter={onChangeFilter} localeText={localeText} />

      {columns.length === 0 ? (
        <NoResults localeText={localeText} />
      ) : (
        <Stack
          gap={1}
          paddingX={1.5}
          overflow="auto"
          maxHeight="100%"
          height="100%"
          data-testid="columns-picker-columns-panel"
          data-trackid="columns-picker-columns-panel"
        >
          <AllToggler {...toggleAllProps} localeText={localeText} />
          <Stack>
            {columns.map(column => (
              <Column
                key={column.id}
                {...column}
                onChange={onChangeColumn}
                checked={selection.includes(column.id)}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
