import styled from 'styled-components'

type Props = {
  bold?: boolean
}

const TagLabel = styled.p<Props>`
  margin: 0;
  font-size: ${p => (p.bold ? p.theme.fonts.$m : p.theme.fonts.$s)};
  font-weight: ${p => p.theme.weights.$semiBold};
  color: ${p => p.theme.colors.$nightRider};
`
TagLabel.displayName = 'TagLabel'
export default TagLabel
