import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'

import { mapAtom } from '../map'
import { refreshScaleControl } from '../../../controls/registerMapControls'

export const useSyncMapScaleControl = () => {
  const { distanceFormat } = useSelector(selectUserConfiguration)

  useEffect(() => {
    const metricSystem = distanceFormat === 'METRIC'
    refreshScaleControl(mapAtom.map, metricSystem)
  }, [distanceFormat])
}
