import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    emptyArchivedDaySubtitle: translate({
      id: 'transactionBar.controls.copy.emptyArchivedDay.subtitle',
    }),
    emptyArchivedDayTitle: translate({ id: 'transactionBar.controls.copy.emptyArchivedDay.title' }),
    archivedAlert: translate({ id: 'transactionBar.messages.archivedAlert' }),
    copy: translate({ id: 'transactionBar.controls.copy' }),
  }))

  return api
}
