import { proxy, useSnapshot } from 'valtio'

type TransactionResolution = 'none' | 'commit' | 'autoCommit' | 'rollback'

const initialValue = { value: 'none' } as const

export const transactionResolutionAtom = proxy<{ value: TransactionResolution }>(initialValue)

export function setTransactionResolution(value: TransactionResolution) {
  transactionResolutionAtom.value = value
}

export function resetTransactionResolution() {
  transactionResolutionAtom.value = initialValue.value
}

export const useTransactionResolution = () => {
  const transactionResolution = useSnapshot(transactionResolutionAtom).value

  return {
    transactionResolution,
    setTransactionResolution,
    resetTransactionResolution,
  }
}
