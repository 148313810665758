import { useCallback } from 'react'

import { useEditingStateWithCtx } from '@/atoms'
import { type ListHeaderSecondaryActionRef } from '@/components/List'

export function useActions(listRef: ListHeaderSecondaryActionRef | null) {
  const { editing, setEditing } = useEditingStateWithCtx('trafficProfile')

  const onCreate = useCallback(() => {
    setEditing(['create-traffic-profile'])
  }, [setEditing])

  const onEditTimeWindows = useCallback(() => {
    setEditing(['edit-time-windows'])
    listRef?.close()
  }, [setEditing, listRef])

  return { editing, onCreate, onEditTimeWindows }
}
