import { useEffect } from 'react'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useOrdersGrid } from '@/atoms'
import { useColumnsPanel } from './hooks/useColumnsPanel'
import { ColumnManagerPanel } from './components/ColumnManagerPanel'

export interface ColumnsPanelProps {
  onToggleAllColumns?: (visible: boolean) => void
  lockedColumns?: string[]
}

export function ColumnsPanel(props: ColumnsPanelProps) {
  const { lockedColumns = [], onToggleAllColumns } = props
  const [
    {
      settings: { columnsPanelSortBy },
    },
    setOrdersGridAtom,
  ] = useOrdersGrid()

  const apiRef = useGridApiContext()

  const { data, actions } = useColumnsPanel(
    apiRef,
    lockedColumns,
    columnsPanelSortBy,
    onToggleAllColumns,
  )

  useEffect(() => {
    setOrdersGridAtom(state => ({
      ...state,
      columnsPanelSortBy: data.sortMethod,
    }))
  }, [data.sortMethod, setOrdersGridAtom])

  if (!apiRef) return null

  return <ColumnManagerPanel data={data} actions={actions} apiRef={apiRef} />
}
