import { notifyDefaultValuesSubscribers } from './defaultValueSubscribers'

interface StoredValue {
  uid: string
  value: unknown
}

const defaultValuesMap = new Map<string, StoredValue[]>()

export function setDefaultValue<T>(key: string, uid: string, value: T): T {
  const values: StoredValue[] = defaultValuesMap.get(key) ?? []

  if (values.length > 0) {
    console.warn(
      `useQueryState -- Trying to set a default values for ${key}, but its value has been already set to: ${values[0].value}`,
    )
  }
  values.push({ uid, value })
  defaultValuesMap.set(key, values)

  if (values.length === 1) {
    notifyDefaultValuesSubscribers<T>(key, values[0].value as T)
  }

  return values[0].value as T
}

export function deleteDefaultValue<T>(key: string, uid: string): T | undefined {
  const values: StoredValue[] | undefined = defaultValuesMap.get(key)

  if (!values) return

  const index = values.findIndex(v => v, uid === uid)
  if (index > -1) {
    values.splice(index, 1)
  }

  if (values.length > 0) {
    console.warn(`useQueryState -- Default values for ${key} it's now set to: ${values[0]?.value}`)
  }

  notifyDefaultValuesSubscribers<T>(key, values[0]?.value as T)

  defaultValuesMap.set(key, values)

  return values[0]?.value as T
}

export function getDefaultValue<T>(key: string): T | undefined {
  return defaultValuesMap.get(key)?.[0]?.value as T
}
