import { validateRmData } from '../validations/validateRmData'
import { validateDeviceData } from '../validations/validateDeviceData'

export function createValidate(lockedNames: string[]) {
  return (
    values:
      | uui.domain.ui.forms.Rm360VehicleFormValues
      | uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics,
  ): uui.domain.ui.forms.Rm360VehicleFormValidationValues => {
    const rm = validateRmData(values.rm, lockedNames, values)
    if ('telematics' in values) {
      return {
        rm,
      }
    }

    const deviceValidation =
      values.trackingOption === 'physicalDevice' ? validateDeviceData(values.device) : undefined

    return {
      rm,
      device: deviceValidation,
    }
  }
}
