import type { FormFields, FormErrors } from '../../../formFields'

import { Stack, Divider } from '@mui/material'

import { BulkTags } from '../../primitives/BulkTags'
import { useUpdateTagsWarning } from './hooks/useUpdateTagsWarning'
import { useValidateTags } from './hooks/useValidateTags'
import { useTexts } from './hooks/useTexts'

export function Tags() {
  const texts = useTexts()

  useUpdateTagsWarning()
  useValidateTags()

  return (
    <Stack>
      <BulkTags<'tagsIn', FormFields, FormErrors>
        name="tagsIn"
        label={texts.tagsIn}
        addTagLabel={texts.addTagsInModalTitle}
        testId="requiredTags"
      />
      <Divider />
      <BulkTags<'tagsOut', FormFields, FormErrors>
        name="tagsOut"
        label={texts.tagsOut}
        addTagLabel={texts.addTagsOutModalTitle}
        testId="bannedTags"
      />
    </Stack>
  )
}
