import { createRef, PureComponent, RefObject } from 'react'

import TextInputState, { ChangeEventHandler } from '../enhancers/TextInputState'

import Block from '../edit/Block'
import Label from '../edit/Label'
import FieldMessage from '../edit/FieldMessage'

import RestoreFieldButton from '../primitives/RestoreFieldButton'
import TextInput from './TextInput'

import { Props } from './typings'

export default class TextField extends PureComponent<Props> {
  private inputRef: RefObject<HTMLInputElement> = createRef()

  getInputRef = (): workwave.NullableHTMLInputElement => {
    if (this.inputRef?.current) {
      return this.inputRef.current
    }
  }

  render() {
    const {
      label,
      name,
      info,
      half = false,
      disabled = false,
      wrappedInputProps,
      formProps: {
        input: { value, onChange },
        meta: { error, dirty, initial },
      },
      testid,
    } = this.props

    return (
      <Block half={half} error={!!error} dirty={!!dirty} data-testid={testid} data-trackid={testid}>
        <Label htmlFor={name}>
          {label}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <TextInputState
          value={value}
          delay={300}
          onChange={onChange}
          render={(text: string, wrappedOnChange: ChangeEventHandler) => (
            <TextInput
              type="text"
              {...wrappedInputProps}
              name={name}
              onChange={wrappedOnChange}
              value={text}
              innerRef={this.inputRef}
              disabled={disabled}
            />
          )}
        />

        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }
}
