import { useState } from 'react'
import { useSelector } from 'react-redux'

import { useIntl } from '@/intl'
import { selectUserConfiguration } from '@/features/domain/user'

export function useTexts() {
  const { translate } = useIntl()
  const userConfig = useSelector(selectUserConfiguration)
  const { currency } = userConfig

  const [api] = useState(() => ({
    costs: translate({ id: 'rm.summary.costs' }),
    costsValue: (amount: number) =>
      translate({ id: 'rm.summary.costs.value', values: { amount, currency } }),
    costsTooltip: translate({ id: 'rm.summary.costs.title' }),
  }))

  return api
}
