import type { ReactNode } from 'react'

import styled from 'styled-components'

import { Route } from '@/icons'
import { isDark } from '@/styles'
import { Text } from '@/local/components'

interface Props {
  backgroundColor: string
  onClick?: () => void
  children?: ReactNode
  testId?: string
}

const textStyle = { display: 'flex', alignItems: 'center' }
const StyledButton = styled.button<Props>`
  outline: 0;
  border-width: 0;
  border-radius: 8px;
  padding: 2px 8px;
  background-color: ${p => p.backgroundColor};
  cursor: ${p => (!!p.onClick ? 'pointer' : 'inherit')};
  flex-shrink: 0;
`

StyledButton.displayName = 'StyledButton'

export function RouteTag(props: Props) {
  const { backgroundColor, children, onClick, testId } = props
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor}
      data-testid={testId}
      data-trackid={testId}
    >
      <Text
        size="$p3"
        color={isDark(backgroundColor) ? '$pureWhite' : '$nightRider'}
        style={textStyle}
      >
        <Route size={15} marginRight={4} />
        {children}
      </Text>
    </StyledButton>
  )
}
