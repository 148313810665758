import styled from 'styled-components'
import { EventHandler, KeyboardEventHandler } from 'react'

export const DEFAULT_SIZE = 28

export const LEFT_PAD = 10
export const RADIUS = 6
export const BORDER = 2
export const BUTTON_SIZE = 14

export const RIGHT_PAD = LEFT_PAD * 2 + BUTTON_SIZE

const setHeight = ({ multi = false, height = DEFAULT_SIZE }) => {
  if (multi) return ``
  return `
    height: ${height}px;
    line-height: ${height - 2 * BORDER}px;
  `
}

type Props = {
  className?: string
  multi?: boolean
  onBlur?: EventHandler<any>
  onFocus?: EventHandler<any>
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>
  disabled?: boolean
  children: React.ReactNode
}

const BaseInputWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding-left: ${LEFT_PAD}px;
  padding-right: ${RIGHT_PAD}px;
  border-radius: ${RADIUS}px;
  ${setHeight};

  opacity: ${p => (p.disabled ? 0.1 : 1)};
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};

  color: ${p => p.theme.colors.$pureBlack};
  font-size: ${p => p.theme.fonts.$p3};

  border: ${BORDER}px solid ${p => p.theme.colors.$alabaster};

  transition: background 0.3s ease;

  &.is-readonly {
    color: ${p => p.theme.colors.$silver};
    background: ${p => p.theme.colors.$alabaster};
    cursor: auto;
  }
`

BaseInputWrapper.displayName = 'BaseInputWrapper'
export default BaseInputWrapper
