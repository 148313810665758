import { PureComponent } from 'react'

import {
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'

import { Props, FieldMode } from '../typings'
import * as Texts from '../intl'

export default class Type extends PureComponent<Props> {
  render() {
    const { type } = this.props

    if (type === FieldMode.hidden) {
      return null
    }

    let labelText = Texts.getNoopText()
    switch (type) {
      case 'p':
        labelText = Texts.getPickupText()
        break

      case 'd':
        labelText = Texts.getDeliveryText()
        break

      case 's':
        labelText = Texts.getServiceText()
        break
    }

    return (
      <>
        <ReadonlyBlock half>
          <ReadonlyLabel primary>{Texts.getTypeText()}</ReadonlyLabel>

          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{labelText}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
