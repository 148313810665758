import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    edit: translate({ id: 'global.edit' }),
    sendMessage: translate({ id: 'global.sendMessage' }),
    vehicleAssignment: translate({ id: 'global.vehicleAssignment' }),
    disabledForBeta: translate({ id: 'global.disabledForBeta' }),
  }))

  return api
}
