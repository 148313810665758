import { Box, type BoxProps, styled } from '@mui/material'
import { type ComponentType } from 'react'

export const Root = styled(Box)<BoxProps>(({ theme: { spacing } }) => {
  return {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    overflow: 'hidden',
    gap: spacing(1),
    width: '100%',
    padding: `${spacing(2)} ${spacing(4)}`,
  }
}) as ComponentType<BoxProps>
