import type { UpdateCompanyDetailsPayload } from './types'

import { useMemo, useCallback } from 'react'

import { useSelector } from 'react-redux'

import {
  selectNotificationConfigCompanyDetails,
  updateNotificationConfig,
  selectTerritoryId,
} from '@/features/domain/territory'
import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'

// this function will set to null every string field that is empty
function normalizeEmptyValues(payload: UpdateCompanyDetailsPayload) {
  const { companyEmail, companyName, companyPhone, companySlogan, ...rest } = payload

  return {
    ...rest,
    companyEmail: normalizeEmptyValue(companyEmail),
    companyPhone: normalizeEmptyValue(companyPhone),
    companyName: normalizeEmptyValue(companyName),
    companySlogan: normalizeEmptyValue(companySlogan),
  }
}

function normalizeEmptyValue(value: string) {
  return value.trim().length === 0 ? null : value
}

export function useTrackingPageProps(
  inEditing: boolean,
  setEditingState: (inEditing: boolean) => void,
) {
  const trackingAppData = useSelector(selectNotificationConfigCompanyDetails)
  const territoryId = useSelector(selectTerritoryId)
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const updateTrackingAppData = useCallback(
    async (payload: UpdateCompanyDetailsPayload) => {
      try {
        const notificationConfig = normalizeEmptyValues(payload)

        const result = await dispatch(
          updateNotificationConfig({
            territoryId,
            notificationConfig,
          }),
        )

        if (!updateNotificationConfig.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        return true
      } catch (error) {
        toast.error(error.message)

        return false
      }
    },
    [dispatch, toast, territoryId],
  )

  return useMemo(() => {
    return {
      trackingAppData,
      setEditingState,
      inEditing,
      updateTrackingAppData,
    }
  }, [trackingAppData, setEditingState, updateTrackingAppData, inEditing])
}
