import { useEffect } from 'react'
import { useSnapshot } from 'valtio'

import { setExecutionEventMarkers } from '../core/setExecutionEventMarkers'
import { executionEventMarkers } from '../executionEventMarkers'
import { getExecutionEventMarkers } from './utils/getExecutionEventMarkers'

export function useExecutionEventMarkers(orderStep?: uui.domain.client.rm.ExtendedOrderStep) {
  useEffect(() => {
    setExecutionEventMarkers({ events: getExecutionEventMarkers(orderStep) })
  }, [orderStep])

  return useSnapshot(executionEventMarkers).events
}
