import { type ResourceModel } from '@bryntum/schedulerpro'
import { type CSSProperties } from 'react'
import { Flag } from '@/icons'

import { getViolationsColor } from '../../../../../styles/colors'
import { useAggregateData } from './hooks/useAggregateData'
import { useTexts } from './useTexts'

interface Props {
  record: ResourceModel
}

const containerStyle: CSSProperties = { display: 'flex', flexDirection: 'column' }
const flagContainerStyle: CSSProperties = {
  height: 16,
  width: 16,
  fill: getViolationsColor(),
}

const titleStyle: CSSProperties = {
  letterSpacing: '0.0075em',
  marginBottom: '0.35rem',
  fontWeight: 500,
  fontSize: '1.25rem',
}

const subtitleStyle: CSSProperties = {
  letterSpacing: '0.0033333em',
  marginBottom: '0.35rem',
  fontWeight: 400,
  fontSize: '0.75rem',
  color: 'var(--scheduler-gray5)',
}

const rowStyle: CSSProperties = {
  flexDirection: 'row',
  alignItems: 'center',
  display: 'flex',
  gap: 8,
}

export function VehicleTooltip(props: Props) {
  const { record } = props

  const violations = useAggregateData(record)
  const texts = useTexts()

  const hasViolations = record.getData(
    'hasViolations',
  ) as uui.domain.client.rm.SchedulerResource['hasViolations']

  const vehicleName = record.getData(
    'vehicleName',
  ) as uui.domain.client.rm.SchedulerResource['vehicleName']

  if (!hasViolations) return null

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>{vehicleName}</div>

      <div style={subtitleStyle}>{texts.violations}</div>

      <div style={containerStyle}>
        {violations.map((violation, index) => (
          <div style={rowStyle} key={`violation-${index}`}>
            <div style={flagContainerStyle}>
              <Flag size={20} />
            </div>
            <div>{violation}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
