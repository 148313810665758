import type { ReactNode } from 'react'

import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldParagraph,
} from '@/forms-legacy'

import * as OrderTexts from '../../../intl'
import { Props } from '../typings'
import ReadonlyFieldTime from './ReadonlyFieldTime'

export function PodNotes(props: Props) {
  const {
    pods: { note },
  } = props

  let noteTime: ReactNode = ''
  let noteText: ReactNode = OrderTexts.getNoneText()

  if (note) {
    const { sec, text, uuid } = note
    noteTime = <ConvertTime seconds={sec} showSeconds={false} />

    if (uuid !== 'EMPTY_NOTE_UUID') {
      noteText = text
    }
  }

  return (
    <>
      <ReadonlyBlock style={{ marginTop: 12 }}>
        <ReadonlyLabel primary>{OrderTexts.getPODTitle()}</ReadonlyLabel>
      </ReadonlyBlock>

      <ReadonlyBlock>
        <ReadonlyLabel>{OrderTexts.getNoteLabel()}</ReadonlyLabel>

        <ReadonlyField>
          {noteTime && (
            <ReadonlyFieldRow>
              <ReadonlyFieldTime style={{ width: '100%', marginTop: 12 }}>
                {noteTime}
              </ReadonlyFieldTime>
            </ReadonlyFieldRow>
          )}
          <ReadonlyFieldParagraph style={{ marginBottom: 12, marginTop: noteTime ? 8 : 12 }}>
            {noteText}
          </ReadonlyFieldParagraph>
        </ReadonlyField>
      </ReadonlyBlock>
    </>
  )
}
