import { useCallback, useState } from 'react'

import { createSimulation, updateSimulation } from '@/features/domain/simulation'
import { useResetEditingState } from '@/atoms'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useSingleEditSimulationActions() {
  const [submitting, setSubmitting] = useState(false)

  const dispatch = useAppDispatch()
  const toast = useNotification()
  const isUnmounted = useIsUnmounted()

  const onUpdateSimulation = useCallback(
    async (values: uui.domain.actions.rpc.simulation.UpdateSimulationAction['params']) => {
      try {
        setSubmitting(true)

        const result = await dispatch(updateSimulation(values))

        if (!updateSimulation.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e.message)
      }

      if (isUnmounted()) return

      setSubmitting(false)
    },
    [dispatch, toast, isUnmounted],
  )

  const onCreateSimulation = useCallback(
    async (values: uui.domain.actions.rpc.simulation.CreateSimulationAction['params']) => {
      try {
        setSubmitting(true)

        const result = await dispatch(createSimulation(values))

        if (!createSimulation.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e.message)
      }

      if (isUnmounted()) return

      setSubmitting(false)
    },
    [dispatch, toast, isUnmounted],
  )

  const stopEditing = useResetEditingState()

  return { actions: { onUpdateSimulation, onCreateSimulation, stopEditing }, submitting }
}
