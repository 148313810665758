import { WarningCard } from '@local/components'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function ErrorBody() {
  const {
    data: {
      values: { notificationWay },
    },
  } = useController()

  const texts = useTexts()
  if (!notificationWay) return null

  return (
    <WarningCard
      description={texts.getErrorDescription(notificationWay)}
      title={texts.getErrorTitle(notificationWay)}
      preset="alert"
    />
  )
}
