import type { IconContainerProps } from './typings'
import { theme, ValidColor } from '@/local/components'

import { IconContainer } from './elements/IconContainer'

type Props = IconContainerProps & {
  background?: ValidColor
}

export function WarningTriangle(props: Props) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path
          fill={props.background ? theme.colors[props.background] : 'currentColor'}
          d="M14.875 12.171l-5.853-9.51a1.2 1.2 0 00-2.044 0l-5.853 9.51A1.2 1.2 0 002.147 14h11.706a1.2 1.2 0 001.022-1.829z"
        />
        <path
          fill={props.color ? theme.colors[props.color] : 'black'}
          d="M7.28 5.286A.967.967 0 018 5c.293 0 .53.093.72.282.184.187.28.424.28.708 0 .155-.16 1.182-.477 3.082a.306.306 0 01-.302.256h-.425a.306.306 0 01-.301-.254C7.165 7.173 7 6.144 7 5.99c0-.28.096-.515.28-.704zm1.425 6.443C8.508 11.91 8.272 12 8 12c-.272 0-.508-.092-.705-.27A.856.856 0 017 11.072c0-.256.098-.477.295-.662A.995.995 0 018 10.135c.272 0 .508.091.705.276a.877.877 0 01.295.662.856.856 0 01-.295.656z"
        />
      </svg>
    </IconContainer>
  )
}
