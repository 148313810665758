import type { EnvironmentCore } from '../environment'
import { productionHostname, defaultProductionConfig } from '../environment'
import { normalizeEnv } from './normalizeEnv'

export const generateEnvironmentFile = (source: Record<string, unknown>): EnvironmentCore => {
  try {
    const httpHost = typeof source.httpHost === 'string' ? source.httpHost : ''
    const httpPort = typeof source.httpPort === 'number' ? source.httpPort : 443
    const qa = source.qa === 'enable' || source.qa === 'disable' ? source.qa : 'disable'
    const env = normalizeEnv(source.env)

    return { httpHost, httpPort, qa, env }
  } catch (error) {
    if (window.location.hostname === productionHostname) {
      // avoid disasters in production
      return defaultProductionConfig
    } else {
      throw error
    }
  }
}
