import { useCallback } from 'react'

import {
  SecondaryColumnHeaderActionsRow,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsSeparator,
} from '@/forms-legacy'
import { useEditingStateWithCtx } from '@/atoms'
import { Trash, Edit, ArrowRoundedUpEdge } from '@/icons'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { useTexts } from '../hooks/useTexts'
import { useOnExport } from '../hooks/useOnExport'

interface Props {
  region: uui.domain.client.rm.Region
  view: 'default' | 'delete'
  setView: (view: 'default' | 'delete') => void
}

export function Actions(props: Props) {
  const { region, setView } = props
  const texts = useTexts()
  const { setEditing } = useEditingStateWithCtx('region')
  const onExport = useOnExport(region)

  const onDelete = useCallback(() => {
    setView('delete')
  }, [setView])

  const handleStartEditing = useCallback(() => setEditing([region.id]), [setEditing, region])

  return (
    <SecondaryColumnHeaderActionsRow>
      <SecondaryColumnHeaderActionButton
        label={texts.exportKml}
        icon={<ArrowRoundedUpEdge />}
        onClick={onExport}
        testid="pendo-setup-region__form-exportkml"
      />

      <SecondaryColumnHeaderActionsSeparator />

      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={onDelete}
            disabled={preventEditing}
            testid="setup-region__delete-button"
          />
        )}
      />
      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.edit}
            icon={<Edit />}
            onClick={handleStartEditing}
            disabled={preventEditing}
            data-trackid="pendo-setup-region__form-edit"
            testid="setup-region__edit-button"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
