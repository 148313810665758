import type { Theme } from '@/local/components'
import type { CSSProperties, MouseEvent } from 'react'
import type { DropDownItem } from '../../../../typings'

import { useCallback } from 'react'
import styled from 'styled-components'

import {
  H3,
  P3,
  FlexBox,
  InvisibleButton as InvisibleButtonCmp,
  theme,
  getColorWithAlpha,
} from '@/local/components'

import { IconContainer } from '../../elements/IconContainer'

import { getMarginTop } from './utils'
import { itemHeight as height } from './constants'
import { ItemProps as Props } from './typings'

const alpha = 0.1

function getColor(item: DropDownItem, { colors }: Theme) {
  const { selected, disabled } = item
  if (selected) {
    return colors.$pureWhite
  }
  if (disabled) {
    return colors.$silver
  }
  return colors.$nightRider
}

function getBackground(item: DropDownItem, highlighted: boolean, { colors }: Theme) {
  const { selected, accent, disabled } = item

  if (selected) {
    return colors.$scienceBlue
  }

  if (!highlighted || disabled) {
    return colors.$pureWhite
  }

  switch (accent) {
    case 'error':
      return getColorWithAlpha('$outrageousRed', alpha)
    case 'warning':
      return getColorWithAlpha('$darkOrange', alpha)
    case 'default':
    default:
      return getColorWithAlpha('$scienceBlue', alpha)
  }
}

function getCursor(item: DropDownItem) {
  return item.disabled ? 'default' : 'pointer'
}

export function Item(props: Props) {
  const { item, highlighted, onChange, onHover, options, index, ...rest } = props

  const background = getBackground(item, !!highlighted, theme)
  const color = getColor(item, theme)
  const cursor = getCursor(item)

  const handleOnClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      onChange(item, index, options, e)
    },
    [onChange, item, options, index],
  )

  const handleOnHover = useCallback(() => {
    onHover()
  }, [onHover])

  const style: CSSProperties = {
    height,
    color,
    background,
    cursor,
    borderRadius: 3,
    transition: 'background 0.15s ease-out',
    transitionProperty: 'background color',
    padding: '0 6px',
  }

  return (
    <InvisibleButton
      {...rest}
      onClick={handleOnClick}
      style={{
        display: 'block',
        width: '100%',
        marginTop: getMarginTop(item, index),
      }}
      onMouseEnter={handleOnHover}
      disabled={item.disabled}
      id={item.id}
    >
      <FlexBox w="100%" style={style}>
        <FlexBox
          shrink
          h="100%"
          vAlignContent="center"
          data-testid="dropdown-option-label-container"
        >
          {!!item.Icon && <IconContainer>{item.Icon}</IconContainer>}
          <H3 ellipsis>{item.label}</H3>
        </FlexBox>
        {!!item.extraLabel && (
          <FlexBox column grow hAlignContent="right" h="100%" vAlignContent="center">
            <P3>{item.extraLabel}</P3>
          </FlexBox>
        )}
      </FlexBox>
    </InvisibleButton>
  )
}

export const InvisibleButton = styled(InvisibleButtonCmp)`
  &:disabled {
    opacity: 1;
  }
`
