import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDevicesAggregateInfo } from '@/features/domain/device'

export function useDeviceInfo(deviceId: string) {
  const deviceAggregateInfo = useSelector(selectDevicesAggregateInfo)

  return useMemo(() => {
    return deviceAggregateInfo[deviceId]
  }, [deviceAggregateInfo, deviceId])
}
