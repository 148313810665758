import { useTheme } from '@mui/material'
import { type IconContainerProps } from '@/icons'
import { EventAlertIcon } from '@/components/EventAlertIcon'

export interface Props {
  eventTypeId: uui.domain.client.gps.wwgps.EventAlert['eventTypeId']
  size?: number
}

const iconProps: Partial<IconContainerProps> = {
  as: 'div',
  size: 29,
}

export function Avatar(props: Props) {
  const { eventTypeId } = props

  const theme = useTheme()

  return (
    <span style={{ color: theme.palette.action.active }}>
      <EventAlertIcon eventTypeId={eventTypeId} iconProps={iconProps} />
    </span>
  )
}
