import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    territoriesListTitleText: translate({ id: 'territoriesListModal.territoryList.title' }),
    openedTerritoryText: translate({ id: 'territoriesListModal.territory.opened' }),
    vehicleStatsText: (opsVehiclesCount: number, licenseOpsVehicleCount: number) =>
      translate({
        id: 'territoriesListModal.territory.vehicleStats',
        values: { opsVehiclesCount, licenseOpsVehicleCount },
      }),
    transactionWarningCardTitle: translate({
      id: 'territoriesListModal.transaction.warningCard.title',
    }),
    transactionWarningCardDescription: translate({
      id: 'territoriesListModal.transaction.warningCard.description',
    }),
    footerBtnOpenText: translate({ id: 'territoriesListModal.footer.button.open' }),
    footerBtnCancelText: translate({ id: 'territoriesListModal.footer.button.cancel' }),
    modalTitle: translate({ id: 'territoriesListModal.title' }),
    modalSubtitle: translate({ id: 'territoriesListModal.subtitle' }),
  }))

  return api
}
