import { Button as ButtonCmp } from '@/local/components'

import { useTexts } from './useTexts'

interface Props {
  onClick: () => void
}

export function Button(props: Props) {
  const { onClick } = props
  const texts = useTexts()

  return (
    // this will make the button to occupy his natural width
    // since the Stack component force the first level to be 100% with align-items: stretch
    <div>
      <ButtonCmp
        className="pendo-orderform_order-notification-tab-view-button"
        data-testid="orderform_order-notification-tab-view-button"
        data-trackid="orderform_order-notification-tab-view-button"
        autoWidth
        preset="cancel"
        onClick={onClick}
      >
        {texts.viewSettings}
      </ButtonCmp>
    </div>
  )
}
