import { proxy, subscribe } from 'valtio'

type ExportRoutesColumnSelection = string[]

type ExportRoutesColumnSelectionAtom = { selection: ExportRoutesColumnSelection }

// ---------------------------------------------------------------------------
// Default values
// ---------------------------------------------------------------------------
const defaultExportRoutesColumnSelection: ExportRoutesColumnSelection = [
  'vehicle',
  'territoryName',
  'driver',
  'date',
  'stepnumber',
  'stopnumber',
  'ordernumber',
  'currentscheduleDate',
  'currentschedule',
  'plannedvehicle',
  'plannedscheduledate',
  'plannedschedule',
  'steptype',
  'ETA2days',
  'ETA1day',
  'ETAToday',
  'timeindate',
  'timein',
  'timeoutdate',
  'timeout',
  'autotimeindate',
  'autotimein',
  'autotimeoutdate',
  'autotimeout',
  'statusval',
  'statusdate',
  'statustime',
  'name',
  'address',
  'lat',
  'lng',
  'distance',
  'drivesec',
  'idlesec',
  'totdistance',
  'totworkingsec',
  'totdrivesec',
  'totidlesec',
  'totservicesec',
  'totstops',
  'totorders',
  'notes',
  'vehicletags',
  'tagsin',
  'tagsout',
  'servicetimesec',
  'loads',
  'tws',
  'customfields',
  'podnote',
  'podbarcodes',
  'email',
  'phone',
  'company',
]

// ---------------------------------------------------------------------------
// Restore from Local Storage
// ---------------------------------------------------------------------------

const localStorageId = 'routemanager/v1/atoms/exportRoutesModal/exportRoutescolumnSelection'

const localStorageValue = localStorage.getItem(localStorageId)
const initialSelection = (
  localStorageValue ? JSON.parse(localStorageValue) : defaultExportRoutesColumnSelection
) as ExportRoutesColumnSelection

const createDefaultSelection = (): ExportRoutesColumnSelectionAtom => ({
  selection: initialSelection,
})

export const exportRoutesColumnSelectionAtom = proxy<ExportRoutesColumnSelectionAtom>(
  createDefaultSelection(),
)

type SetExportRoutesColumnSelection = (
  prev: ExportRoutesColumnSelection,
) => ExportRoutesColumnSelection
export type SetExportRoutesColumnSelectionParam =
  | SetExportRoutesColumnSelection
  | ExportRoutesColumnSelection
  | 'reset'

export function resetExportRoutesColumnSelection() {
  exportRoutesColumnSelectionAtom.selection = defaultExportRoutesColumnSelection
}

export const setExportRoutesColumnSelection = (value: string[]) => {
  exportRoutesColumnSelectionAtom.selection = value
}

subscribe(exportRoutesColumnSelectionAtom, () => {
  localStorage.setItem(localStorageId, JSON.stringify(exportRoutesColumnSelectionAtom.selection))
})
