import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState({
    cancelButtonTitle: translate({ id: 'demoTools.requestMaker.modal.cancelButton.title' }),
    modalTitle: translate({ id: 'demoTools.requestMaker.modal.title' }),
    tabs: {
      requestMaker: translate({ id: 'demoTools.requestMaker.modal.tabs.requestMaker' }),
      requestLog: translate({ id: 'demoTools.requestMaker.modal.tabs.requestLog' }),
    },
    requestMaker: {
      useCurrentTxnId: translate({
        id: 'demoTools.requestMaker.modal.requestMaker.useCurrentTxnId',
      }),
      response: translate({ id: 'demoTools.requestMaker.modal.requestMaker.response' }),
      request: translate({ id: 'demoTools.requestMaker.modal.requestMaker.request' }),
      method: translate({ id: 'demoTools.requestMaker.modal.requestMaker.method' }),
      send: translate({ id: 'demoTools.requestMaker.modal.requestMaker.send' }),
    },
    requestLog: {
      openInRequestMaker: translate({
        id: 'demoTools.requestMaker.modal.requestLog.openInRequestMaker',
      }),
      disableRecording: translate({
        id: 'demoTools.requestMaker.modal.requestLog.disableRecording',
      }),
      enableRecording: translate({ id: 'demoTools.requestMaker.modal.requestLog.enableRecording' }),
      replay: translate({ id: 'demoTools.requestMaker.modal.requestLog.replay' }),
      clear: translate({ id: 'global.clear' }),
    },
  })

  return api
}
