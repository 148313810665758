import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function PaperPlane(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 17 17">
        <path d="M16.868.071a.335.335 0 00-.366-.03L.176 8.884a.334.334 0 00-.174.327.335.335 0 00.23.29l4.64 1.514-.449 4.594a.334.334 0 00.2.342.336.336 0 00.387-.085l2.927-3.307 4.408 4.345a.338.338 0 00.563-.159L16.989.42a.336.336 0 00-.12-.348zM5.555 10.953l6.637-5.367-4.523 6.262-2.475 2.794.361-3.69z" />
      </svg>
    </IconContainer>
  )
}
