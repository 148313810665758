import { gis } from '@/server-data'

export function createEmptyLocation(
  src?: Partial<uui.domain.client.Location>,
): uui.domain.client.Location {
  const latLng = src?.latLng ?? gis.createLatLng(0, 0)

  return {
    latLng,
    rooftopLatLng: src?.rooftopLatLng,
    address: src?.address ?? '',
    geoAddress: src?.geoAddress ?? '',
    source: src?.source ?? 'MANUAL',
    status: src?.status ?? 'OK',
    rawLatLngAsString: gis.latLngToString(latLng),
  }
}

export function cloneLocation(src: uui.domain.client.Location): uui.domain.client.Location {
  const {
    latLng: srcLatLng,
    rooftopLatLng: srcRooftopLatLng,
    address = '',
    geoAddress = '',
    source = 'MANUAL',
    status = 'OK',
  } = src

  const latLng = { ...srcLatLng }
  const rooftopLatLng = srcRooftopLatLng ? { ...srcRooftopLatLng } : undefined

  return {
    latLng,
    rooftopLatLng,
    address,
    geoAddress,
    source,
    status,
    rawLatLngAsString: gis.latLngToString(latLng),
  }
}
