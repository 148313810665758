import type OlMap from 'ol/Map'

import { createExecutionEventsLayer } from './executionEvents/createExecutionEventsLayer'
import { createRoutePolylineLayer } from './routePolyline/createRoutePolylineLayer'
import { createDeviceEventLayer } from './deviceEvent/createDeviceEventLayer'
import { createTrafficAreaLayer } from './trafficArea/createTrafficAreaLayer'
import { createRoadSegmentLayer } from './roadSegment/createRoadSegmentLayer'
import { createVoiceEventsLayer } from './voiceEvents/createVoiceEventsLayer'
import { createBreadcrumbLayers } from './breadcrumb/createBreadcrumbLayers'
import { createGeofenceLayer } from './geofence/createGeofenceLayer'
import { createVehicleLayer } from './vehicle/createVehicleLayer'
import { createDeviceLayer } from './device/createDeviceLayer'
import { createRegionLayer } from './region/createRegionLayer'
import { createOrderLayer } from './order/createOrderLayer'
import { createDepotLayer } from './depot/createDepotLayer'
import { createPlaceLayer } from './place/createPlaceLayer'
import { createDrawLayer } from './draw/createDrawLayer'
import { createPinLayer } from './pin/createPinLayer'

export const initializeMapLayers = (map: OlMap) => {
  // ATTENTION: the order of creation matters. It'll affect a layer's z-index.

  // Marker layers
  createRoutePolylineLayer(map)
  createRegionLayer(map)
  createTrafficAreaLayer(map)
  createRoadSegmentLayer(map)
  createBreadcrumbLayers(map)
  createPlaceLayer(map)
  createOrderLayer(map)
  createDepotLayer(map)
  createGeofenceLayer(map)
  createDeviceLayer(map)
  createVehicleLayer(map)
  createDeviceEventLayer(map)

  // order execution events and PODs
  createExecutionEventsLayer(map)

  // order voice events
  createVoiceEventsLayer(map)

  // inspect and location pins layer
  createPinLayer(map)

  // Editable Area and Editable Segment layer
  createDrawLayer(map)
}
