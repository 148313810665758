import { useRef } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const api = useRef({
    cancel: translate({ id: 'settings.startEndRoute.copyModal.button.cancel' }),
    copy: translate({
      id: 'settings.startEndRoute.copyModal.button.copy',
    }),

    modalTitle: translate({
      id: 'settings.startEndRoute.copyModal.modalCopyReasonsTitle',
    }),
    modalSubTitle: translate({
      id: 'settings.startEndRoute.copyModal.modalCopyReasonsSubtitle',
    }),

    territoryDropdownTitle: translate({
      id: 'settings.startEndRoute.copyModal.territoriesTitle',
    }),

    success: translate({
      id: 'settings.startEndRoute.copyModal.success.messageDescription',
    }),
    error: translate({
      id: 'settings.startEndRoute.copyModal.failure.messageDescription',
    }),
  })

  return api.current
}
