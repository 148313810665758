import { intl } from '@/intl'
import { recurrenceToString } from '@/server-data'

export function filterRecurrences(
  filter: string,
  recurrences: uui.domain.rm.Recurrence[],
): uui.domain.rm.Recurrence[] {
  return recurrences.filter(r => {
    const text = recurrenceToString(intl.translate)(r).toLocaleLowerCase()
    return filter.length === 0 || text.includes(filter.toLocaleLowerCase())
  })
}
