import { useState } from 'react'

import { useIntl } from '@/intl'

// test comment
export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    searchPlaceholder: translate({ id: 'setup.regions.filterPlaceholder' }),
    importKML: translate({ id: 'global.importKML' }),
    importKMLFailed: translate({ id: 'global.importKML.failed' }),
    yourRegions: translate({ id: 'setup.regions.list.yourRegions' }),
    createNew: translate({ id: 'setup.regions.createNew' }),
    entitiesCount: (count: number) =>
      translate({
        id: 'setup.regions.list.footerCount',
        values: { count },
      }),
    entitiesCountWithSelection: (selected: number, count: number) =>
      translate({
        id: 'setup.regions.list.footerCountWithSelection',
        values: { count, selected },
      }),
    selectBtn: (allSelected: boolean): string =>
      translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' }),
    visibilityBtn: (someVisible?: boolean): string =>
      translate({
        id: someVisible ? 'global.hideAll' : 'global.showAll',
      }),
    noEntries: {
      title: translate({ id: 'regions.list.noEntries.title' }),
      description: translate({ id: 'regions.list.noEntries.description' }),
      action: translate({ id: 'regions.list.noEntries.action' }),
    },
    noResults: {
      title: translate({ id: 'regions.list.noResults.title' }),
      description: translate({ id: 'regions.list.noResults.description' }),
    },
  }))

  return api
}
