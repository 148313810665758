import type { ChangeViewHandler } from '../../../../ViewManager'

export enum ViewType {
  default,
  create,
}

export type NotificationEditorRecipient = {
  prefix: 'sms' | 'email'
  address: string
}

export type CheckDuplicateRecipientType = (value: string) => boolean

export type AddRecipientType = (recipient: NotificationEditorRecipient) => void

export type RenderNotificationEditorText = (field: NotificationEditorText) => string

export interface ActionsManagerProps extends Omit<Props, 'onChange'> {
  view: ViewType
  changeView: ChangeViewHandler<ViewType>
  isDuplicatedRecipient: CheckDuplicateRecipientType
  onAddRecipient: AddRecipientType
}

export interface AddRecipientProps {
  onCancel: () => void
  onAdd: AddRecipientType
  isDuplicatedRecipient: CheckDuplicateRecipientType
  renderText: RenderNotificationEditorText
  defaultEmailAddress?: string
  allowSms?: boolean
}

export enum NotificationEditorText {
  emailButton = 'emailButton',
  smsButton = 'smsButton',
  invalidEmailMessage = 'invalidEmailMessage',
  invalidSmsMessage = 'invalidSmsMessage',
  duplicatedRecipientMessage = 'duplicatedRecipientMessage',
  addRecipientButton = 'addRecipientButton',
  discardRecipientButton = 'discardRecipientButton',
  addContactButton = 'addContactButton',
  emailTypeLabel = 'emailTypeLabel',
  smsTypeLabel = 'smsTypeLabel',
  addRecipientInputEmailPlaceholder = 'addRecipientInputEmailPlaceholder',
  addRecipientInputSmsPlaceholder = 'addRecipientInputSmsPlaceholder',
  addRecipientInputMessage = 'addRecipientInputMessage',
}

export interface Props {
  value: NotificationEditorRecipient[]
  renderText: RenderNotificationEditorText
  onChange: (values: NotificationEditorRecipient[]) => void
  defaultEmailAddress?: string
  allowSms?: boolean
}
