import { ModalFooterButton } from '@/components/Modal'

import { useModalData } from '../../../hooks/useModalData'
import { useController } from '../../../hooks/useController'
import { useTexts } from '../../../useTexts'

import { useButtonClassName } from './hooks/useButtonClassName'

export function ButtonCancel() {
  const ctrl = useController()
  const data = useModalData()
  const texts = useTexts()

  const { type, suffix } = useButtonClassName(data)

  const className = `pendo-moving-${type}_dont-move${suffix}`
  const testid = `moving-${type}_dont-move${suffix}`

  return (
    <ModalFooterButton
      testid={testid}
      className={className}
      title={texts.btnCancelTitle}
      onClick={ctrl.close}
    >
      {texts.btnCancel}
    </ModalFooterButton>
  )
}
