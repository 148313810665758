import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    reset: translate({ id: 'settings.rmAccount.view.resetApiKeyModal.controls.send' }),
    cancel: translate({ id: 'settings.rmAccount.view.resetApiKeyModal.controls.cancel' }),

    resetApiKeySuccess: translate({ id: 'settings.rmAccount.view.resetApiKeyModal.toast.success' }),
    resetApiKeyError: translate({ id: 'settings.rmAccount.view.resetApiKeyModal.toast.error' }),
  }))

  return api
}
