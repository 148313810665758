import { Info } from '@/icons'
import { WarningCard } from '@/local/components'
import { useTexts } from './useTexts'

interface Props {
  phoneNumber: string
  date: string
}

export function UnsubscribeDataSms(props: Props) {
  const { date, phoneNumber } = props
  const texts = useTexts()

  return (
    <WarningCard
      preset="info"
      title={texts.unsubscribeSmsTitle}
      Icon={<Info size={14} color="$sailBlue" />}
      description={texts.unsubscribeSmsDescription(phoneNumber, date)}
    />
  )
}
