import type MapBrowserEvent from 'ol/MapBrowserEvent'

import OlMap from 'ol/Map'
import { pointerMove } from 'ol/events/condition'

import { findLayer } from '../../layers/utils/findLayer'
import { subscribeToMouseMove } from '../reactions/mouseMove/subscribeToMouseMove'
import { MouseInteraction } from '../MouseInteraction'
import { getInteraction, registerInteraction, setInteractionMetadata } from '../interactionMetadata'

function mouseMoveCondition(mapBrowserEvent: MapBrowserEvent<UIEvent>) {
  // If the target !== canvas, it means that the pointer is hover a map control
  // We don't want mouseOver event in such situations.

  return (
    mapBrowserEvent.originalEvent.target instanceof HTMLCanvasElement &&
    pointerMove(mapBrowserEvent)
  )
}

export const registerMouseMoveInteraction = (map: OlMap) => {
  const uid = 'rm-mouse-move-interaction'

  if (getInteraction(uid)) return

  const mouseInteraction = new MouseInteraction(uid, {
    multi: false,
    changeCursor: true,
    stopPropagation: false,
    condition: mouseMoveCondition,
    subscribers: [subscribeToMouseMove],
    disabledLayers: [
      findLayer(map, 'pin'),
      findLayer(map, 'executionEvents'),
      findLayer(map, 'draw'),
    ],
  })

  setInteractionMetadata(mouseInteraction, 'rmInteraction', true)
  setInteractionMetadata(mouseInteraction, 'uid', uid)
  registerInteraction(uid, mouseInteraction)

  map.addInteraction(mouseInteraction)
}
