import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    type: translate({ id: 'global.type' }),
    pickup: translate({ id: 'global.pickup' }),
    service: translate({ id: 'global.service' }),
    delivery: translate({ id: 'global.delivery' }),
    serviceTime: translate({ id: 'global.serviceTime' }),
  }))

  return api
}
