import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { timeUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export function useCreateFormatTime() {
  const userConfig = useSelector(selectUserConfiguration)

  return useCallback(
    (source: Date, showSeconds: boolean = false) =>
      timeUtils.formatTime(userConfig)(source, showSeconds),
    [userConfig],
  )
}
