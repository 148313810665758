// Creates a duplicate-free version of an array
export function uniq<T>(ids: T[]): T[] {
  return [...new Set(ids)]
}

// Creates an array of unique values that are included in all of the provided arrays
export function intersection(arrays: string[][]): string[] {
  if (arrays.length === 0) return []
  if (arrays.length === 1) return arrays[0]

  let first = [...arrays[0]]

  for (const array of arrays) {
    first = intersect(first, array)
  }

  return first
}

function intersect(a: string[], b: string[]) {
  return a.filter(value => b.includes(value))
}
