import { Component, Fragment, ReactNode } from 'react'
import { addDays, isSameDay } from 'date-fns/esm'

import { ListActionButton } from '@/forms-legacy'
import { AddRounded } from '@/icons'
import { clsx } from '@/utils'

import { DEFAULT_NEW_VALUE as DefaultTimeWindowValue } from '../TimeWindowList'

import { getAddNewTWExceptionText } from './intl'
import TimeWindowExceptionListItem from './TimeWindowExceptionListItem'

export type TimeWindowException = {
  date: Date
  list: uui.domain.rm.TimeWindow[]
}

export type Props = {
  list: TimeWindowException[]
  onChange: (list: TimeWindowException[]) => void
  max?: number
  className?: string
  today?: Date
  renderError?: (date: string | Date, index: number) => ReactNode
  renderDateError?: (date: string | Date) => ReactNode
  disabled?: boolean
  h24?: boolean
}

export type Callback = (timeWindow: TimeWindowException, id: number) => void
export type DeleteCallback = (id: number) => void

const DEFAULT_MAX = 2
const todayClient = new Date()

const isDateInList = (date: Date, list: TimeWindowException[]): boolean => {
  const foundIndex = list.findIndex((item: TimeWindowException) => {
    const { date: dateItem } = item
    return isSameDay(date, dateItem)
  })
  return foundIndex !== -1
}

export default class TimeWindowList extends Component<Props> {
  private getFirstDayAvailable = (start: Date, list: TimeWindowException[]): Date => {
    if (!isDateInList(start, list)) return start
    let loopDate = start
    do {
      loopDate = addDays(loopDate, 1)
    } while (isDateInList(loopDate, list))
    return loopDate
  }

  private getDefaultValue = (): TimeWindowException => {
    const { today = todayClient, list } = this.props
    const date = this.getFirstDayAvailable(today, list)
    return {
      date,
      list: [DefaultTimeWindowValue],
    }
  }

  private handleAdd = (): void => {
    const { list, onChange } = this.props
    const defaultNewValue = this.getDefaultValue()
    onChange([...list, defaultNewValue])
  }

  private handleRemove = (index: number): void => {
    const { list, onChange } = this.props
    const updatedCollection = [...list]
    updatedCollection.splice(index, 1)
    onChange(updatedCollection)
  }

  private handleChange = (timeWindowException: TimeWindowException, id: number): void => {
    const { list, onChange } = this.props
    const updatedCollection: TimeWindowException[] = list.map(
      (item: TimeWindowException, index: number): TimeWindowException => {
        if (id === index) {
          return timeWindowException
        }
        return item
      },
    )
    onChange(updatedCollection)
  }

  private renderSingle = (item: TimeWindowException, index: number): ReactNode => {
    const { renderError, renderDateError, h24 } = this.props

    return (
      <Fragment key={`timeWindow-exception-row-${index}`}>
        <TimeWindowExceptionListItem
          key={`timeWindow-exception-${index}`}
          id={index}
          item={item}
          action={this.handleRemove}
          onChange={this.handleChange}
          renderError={renderError}
          renderDateError={renderDateError}
          h24={h24}
        />
      </Fragment>
    )
  }

  render() {
    const { className = '', list, max = DEFAULT_MAX } = this.props

    const rootClassName = clsx({
      [className]: true,
      'o-time-window-exception-list': true,
    })

    return (
      <div className={rootClassName}>
        <ListActionButton
          icon={<AddRounded />}
          onClick={this.handleAdd}
          disabled={list.length >= max}
          testid="time-window-exception__add-button"
        >
          {getAddNewTWExceptionText()}
        </ListActionButton>
        <div className="o-time-window-exception-list__list">
          {list?.map((item: TimeWindowException, index: number) => this.renderSingle(item, index))}
        </div>
      </div>
    )
  }
}
