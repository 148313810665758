export const barcodesSeparator: string = ','
export const barcodeMaxLength: number = 100
export const barcodesNotAllowedCharacters = [',']

export type BarcodesValidationResult =
  | {
      type: 'allValid'
    }
  | {
      type: 'emptyValue'
    }
  | {
      type: 'maxCountViolation'
    }
  | {
      type: 'maxLengthViolation'
      invalidValues: string[]
    }
  | {
      type: 'notAllowedCharactersViolation'
      invalidValues: string[]
    }

type ValidateBarcodesOptions = {
  allowEmptyValues: boolean
}

const defaultBarcodesOptions: ValidateBarcodesOptions = {
  allowEmptyValues: false,
}
export const validateBarcodes = (
  source: string[] | string,
  maxBarcodes: number,
  options: Partial<ValidateBarcodesOptions> = {},
): BarcodesValidationResult => {
  const { allowEmptyValues } = {
    ...defaultBarcodesOptions,
    ...options,
  }
  const barcodes = Array.isArray(source) ? source : splitBarcodes(source)

  if (barcodes.length > maxBarcodes) {
    return { type: 'maxCountViolation' }
  }

  if (!allowEmptyValues && barcodes.some(b => b.length === 0)) {
    return { type: 'emptyValue' }
  }

  const invalidLengthBarcodes = barcodes.filter(b => b.length > barcodeMaxLength)
  if (invalidLengthBarcodes.length > 0) {
    return { type: 'maxLengthViolation', invalidValues: invalidLengthBarcodes }
  }

  const invalidContentBarcodes = barcodes.filter(b =>
    barcodesNotAllowedCharacters.some(invalidString => b.includes(invalidString)),
  )
  if (invalidContentBarcodes.length > 0) {
    return {
      type: 'notAllowedCharactersViolation',
      invalidValues: invalidContentBarcodes,
    }
  }

  return { type: 'allValid' }
}

export const splitBarcodes = (
  barcodes: string = '',
  separator: string = barcodesSeparator,
): string[] => {
  return barcodes
    .split(separator)
    .map(barcode => barcode.trim())
    .filter(b => b.length > 0)
}

export const areValidBarcodes = (barcodesValidation: BarcodesValidationResult): boolean =>
  barcodesValidation.type === 'allValid'

export const getBarcodesInvalidValues = (
  barcodesValidation: BarcodesValidationResult,
): string[] => {
  switch (barcodesValidation.type) {
    case 'notAllowedCharactersViolation':
    case 'maxLengthViolation':
      return barcodesValidation.invalidValues
    default:
      return []
  }
}
