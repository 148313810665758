import { PureComponent } from 'react'
import {
  Field as FinalFormField,
  FieldProps as FFieldProps,
  FieldRenderProps as FFieldRenderProps,
} from 'react-final-form'

import { Block, Label, InnerBlock, FieldMessage, RestoreFieldButton } from '@/forms-legacy'
import { isDeepEqual } from '@/server-data'

import { StyledInputStep as InputStep, Props as InputStepProps } from './InputStep'

export type FieldProps = FFieldProps<number, any>
export type FieldRenderProps = FFieldRenderProps<number>

export interface Props extends FieldProps {
  name: string
  label: string
  info?: string
  half?: boolean
  wrappedInputProps: Omit<InputStepProps, 'onChange' | 'value' | 'name'>
  testid?: string
}

export default class NumericStepperField extends PureComponent<Props> {
  private renderField = (formProps: FieldRenderProps) => {
    const { label, name, info, half = false, wrappedInputProps, testid } = this.props
    const {
      input: { value, onChange },
      meta: { error, dirty, initial },
    } = formProps

    return (
      <Block error={!!error} dirty={!!dirty} data-testid={testid}>
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <InnerBlock half={half}>
          <InputStep {...wrappedInputProps} name={name} onChange={onChange} value={value} />
        </InnerBlock>
        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }

  render() {
    const { name, ...props } = this.props
    return <FinalFormField {...props} isEqual={isDeepEqual} name={name} render={this.renderField} />
  }
}
