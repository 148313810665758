import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useAppLanguage } from '@/atoms'
import { createIntl } from '@/local/server-data/utils/intl'
import { selectUserConfiguration } from '@/features/domain/user'
import { useIntl } from '@/intl'

import { type Column } from './useController'

export function useCreateColumns() {
  const { translate: enTranslate } = useIntl()
  const [{ language }] = useAppLanguage()
  const { distanceFormat } = useSelector(selectUserConfiguration)

  return useCallback(
    async (
      defs: uui.domain.actions.rpc.route.ExportRouteColumnsDef,
      keepEnglishColumns: boolean,
    ) => {
      try {
        const columns: Column[] = []

        const translate = await createLocalTranslate(keepEnglishColumns, language, enTranslate)
        if (!translate) {
          throw new Error('cannot compte translate function')
        }

        for (const c of defs.columns) {
          const uom = translate({
            id:
              distanceFormat === 'METRIC'
                ? 'modals.exportRoutes.unitsOfMeasure.kilometers'
                : 'modals.exportRoutes.unitsOfMeasure.miles',
          })

          columns.push({
            id: c,
            // @ts-expect-error
            label: translate({ id: `modals.exportRoutes.column.${c}`, values: { uom } }),
          })
        }

        for (const c of defs.cfs) {
          columns.push({
            id: c,
            label: translate({
              id: `modals.exportRoutes.column.customfields`,
              values: { value: createRemovePrefix('CF_')(c) },
            }),
          })
        }

        for (const c of defs.loads) {
          columns.push({
            id: c,
            label: translate({
              id: `modals.exportRoutes.column.loads`,
              values: { value: createRemovePrefix('L_')(c) },
            }),
          })
        }

        columns.sort((a, b) => a.label.localeCompare(b.label))

        return columns
      } catch (e) {
        console.error(e)
        return [] as Column[]
      }
    },
    [language, enTranslate, distanceFormat],
  )
}

async function createLocalTranslate(
  keepEnglishColumns: boolean,
  language: uui.domain.Language,
  translate: uui.domain.intl.Translate,
) {
  try {
    // user is not interested into having different lang
    if (!keepEnglishColumns) return translate

    // current lang is already english
    if (language === 'en') return translate

    // lang is not en and user want to keep english columns
    const intl = await createIntl('en')

    await intl.ready

    return intl.translate
  } catch (e) {
    console.error(e)
  }
}

function createRemovePrefix(prefix: string) {
  return function (value: string) {
    if (value.startsWith(prefix)) {
      return value.slice(prefix.length)
    }

    return value
  }
}
