import { useMemo } from 'react'

import { useCurrentOrders } from './useCurrentOrders'

export type PairedOrder = uui.domain.client.rm.OrderStep & { id: string }

export const useSelectedOrders = () => {
  const selectedOrders = useCurrentOrders()

  // Retrieves all paired orders not already selected
  const pairedOrders = useMemo<PairedOrder[]>(
    () =>
      selectedOrders.reduce((list: PairedOrder[], order) => {
        if (!order) return list

        if (order.type === 'pd') {
          const pairedOrderStep = order.orderStepType === 'p' ? order.delivery : order.pickup

          if (pairedOrderStep) {
            const paired = { ...pairedOrderStep, id: order.pairedId }
            const pairedNotFound = selectedOrders.findIndex(order => order.id === paired.id) === -1

            if (pairedNotFound) list.push(paired)
          }
        }

        return list
      }, []),
    [selectedOrders],
  )

  return { selectedOrders, pairedOrders } as const
}
