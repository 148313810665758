import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'voice.view.title' }),
    subtitle: translate({ id: 'voice.view.subtitle' }),
    actions: {
      edit: translate({ id: 'voice.view.actions.edit' }),
      copy: translate({ id: 'voice.view.actions.copy' }),
    },
    labels: {
      status: translate({ id: 'voice.view.labels.status' }),
      restrictions: translate({ id: 'voice.view.labels.restrictions' }),
      preference: translate({ id: 'voice.view.labels.preference' }),
      messageOnCallback: translate({ id: 'voice.view.labels.messageOnCallback' }),
      message: translate({ id: 'voice.view.labels.message' }),
      voicePreference: translate({ id: 'voice.view.labels.voicePreference' }),
      phone: translate({ id: 'voice.view.labels.phone' }),
    },
    value: {
      status: {
        off: translate({ id: 'voice.view.values.status.off' }),
        basic: translate({ id: 'voice.view.values.status.basic' }),
        advanced: translate({ id: 'voice.view.values.status.advanced' }),
      },
      restrictions: {
        completedOrders: translate({ id: 'voice.view.values.restrictions.completedOrders' }),
        incompletedOrders: translate({ id: 'voice.view.values.restrictions.incompletedOrders' }),
        noRestrictions: translate({ id: 'voice.view.values.restrictions.noRestrictions' }),
      },
      preference: {
        on: translate({ id: 'voice.view.values.preference.on' }),
        off: translate({ id: 'voice.view.values.preference.off' }),
      },
      messageOnCallback: {
        on: translate({ id: 'voice.view.values.messageOnCallback.on' }),
        off: translate({ id: 'voice.view.values.messageOnCallback.off' }),
      },
    },
  }))

  return api
}
