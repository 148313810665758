import { styled } from '@mui/material'

export const DisabledBadge = styled('div', {
  name: 'DisabledBadge',
})(({ theme }) => ({
  backgroundColor: theme.palette.grey[400],
  borderRadius: 14.5,
  height: 29,
  width: 29,
}))
