import styled from 'styled-components'

interface Props {
  size?: number
  selected?: boolean
}

export const AvatarContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  color: ${p => (p.selected ? p.theme.colors.$pureWhite : p.theme.colors.$nightRider)};
  overflow: hidden;
  height: ${p => p.size ?? 32}px;
  width: ${p => p.size ?? 32}px;
  font-size: ${p => p.theme.fonts.$p2};
`

AvatarContainer.displayName = 'AvatarContainer'
