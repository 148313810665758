import type { SegmentType } from '../../../interactions/EditableRoadSegmentInteraction/utils/color'

import type {
  DrawEditableRoadSegmentInteraction,
  ModifyEditableRoadSegmentInteraction,
} from '../../../interactions/EditableRoadSegmentInteraction'

/**
 * Update the Active RoadSegment color when it change in the Editable RoadSegment Store
 */
export function changeRoadSegmentColor(
  drawRoadSegment: DrawEditableRoadSegmentInteraction,
  modifyRoadSegment: ModifyEditableRoadSegmentInteraction,
  type: SegmentType,
) {
  // draw RoadSegment uses it's own overlay layer while drawing the shape
  drawRoadSegment?.updateColor(type)

  // modify RoadSegment uses `draw` layer style
  modifyRoadSegment?.updateColor(type)
}
