import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'
import { Body } from './Body'
import { Header } from './Header'
import { Footer } from './Footer'

interface Props {
  startRevision: number
  numberOfImportedOrdersWithLowAccuracy: number
  numberOfImportedOrders: number
}

export function Content(props: Props) {
  const { startRevision, numberOfImportedOrders, numberOfImportedOrdersWithLowAccuracy } = props

  const { ready } = useConfigureController(
    startRevision,
    numberOfImportedOrders,
    numberOfImportedOrdersWithLowAccuracy,
  )

  if (!ready) return null

  return (
    <ModalContent header={<Header />} footer={<Footer />}>
      <Body />
    </ModalContent>
  )
}
