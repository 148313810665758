import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    off: translate({ id: 'voice.form.alert.off' }),
    basic: translate({ id: 'voice.form.alert.basic' }),
    foreign: translate({ id: 'voice.form.alert.foreign' }),
    advanced: translate({ id: 'voice.form.alert.advanced' }),
    learnMore: translate({ id: 'voice.form.alert.learnMore' }),
    advancedDisabled: translate({ id: 'voice.form.alert.advanced.disabled' }),
    advancedDisabledOnTerritory: translate({ id: 'voice.form.alert.advanced.disabledOnTerritory' }),
  }))

  return api
}
