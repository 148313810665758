import { useState, useMemo, useCallback, KeyboardEvent, useEffect } from 'react'

import { InputFieldText } from '@/forms-legacy'

import { numberUtils } from '@/utils'
import { Props, NumericValue } from './typings'

enum KeyboardEventKeys {
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Escape = 'Escape',
  Enter = 'Enter',
  SpaceBar = ' ',
}

const parseValue = (
  value: number,
  decimalSeparator: numberUtils.DecimalSeparator,
): NumericValue => {
  return { value, label: numberUtils.fromNumberToString(value, decimalSeparator) }
}

const composeValue = (
  label: string,
  currentValue: NumericValue,
  decimalSeparator: numberUtils.DecimalSeparator,
): NumericValue => {
  // zero and not parsable numbers
  if (
    label === '' ||
    label === '0' ||
    label === '.' ||
    label === ',' ||
    label === '0.' ||
    label === '0,'
  ) {
    if (currentValue.value === 0 && currentValue.label === label) {
      return currentValue
    }

    return { label, value: 0 }
  }

  // check for invalid chars
  const testFunction =
    decimalSeparator === ','
      ? numberUtils.validNumberMetricRegexp
      : numberUtils.validNumberImperialRegexp

  if (!testFunction.test(label)) {
    return currentValue
  }

  const parsedValue = numberUtils.fromStringToNumber(label)

  // parsed value has not been changed, only label
  if (parsedValue === currentValue.value) {
    if (currentValue.label === label) {
      return currentValue
    }

    return {
      label,
      value: currentValue.value,
    }
  }

  // decimal number written without a leading zero
  if (
    parsedValue < 1 &&
    (currentValue.label.startsWith('.') || currentValue.label.startsWith(','))
  ) {
    return {
      label,
      value: parsedValue,
    }
  }

  if (label.endsWith('.') || label.endsWith(',')) {
    return {
      label,
      value: parsedValue,
    }
  }

  return parseValue(parsedValue, decimalSeparator)
}

function NumericInput(props: Props) {
  const { value, onChange, name, step = 1, className = '', useMetric = false, ...restProps } = props

  const decimalSeparator: numberUtils.DecimalSeparator = useMemo(
    () => numberUtils.getDecimalSeparator(useMetric),
    [useMetric],
  )

  const [currentValue, setValue] = useState<NumericValue>(parseValue(props.value, decimalSeparator))

  const handleChange = useCallback(
    (val: string): void => {
      const updatedValue = composeValue(val, currentValue, decimalSeparator)
      if (updatedValue !== currentValue) {
        setValue(updatedValue)
        onChange(updatedValue.value)
      }
    },
    [currentValue, onChange, decimalSeparator],
  )

  const handleBlur = useCallback(() => {
    if (`${currentValue.value}` !== currentValue.label) {
      setValue(parseValue(currentValue.value, decimalSeparator))
    }
  }, [currentValue, decimalSeparator])

  const handleKeyboardEvent = useCallback(
    (event: KeyboardEvent<HTMLElement>): void => {
      let direction: number = NaN

      switch (event.key) {
        case KeyboardEventKeys.ArrowDown:
          event.stopPropagation()
          event.preventDefault()
          direction = -1
          break

        case KeyboardEventKeys.ArrowUp:
          event.stopPropagation()
          event.preventDefault()
          direction = 1
          break
      }

      if (isNaN(direction)) return

      const updatedNumber = currentValue.value + step * direction
      const updatedLabel = updatedNumber.toFixed(2)
      const updatedValue = composeValue(updatedLabel, currentValue, decimalSeparator)

      if (updatedValue !== currentValue) {
        setValue(updatedValue)
        onChange(updatedValue.value)
      }
    },
    [currentValue, step, onChange, decimalSeparator],
  )

  useEffect(() => {
    setValue(parseValue(value, decimalSeparator))
  }, [decimalSeparator, value])

  return (
    <InputFieldText
      {...restProps}
      type="text"
      name={name}
      value={currentValue.label}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyboardEvent}
      className={className}
    />
  )
}

export default NumericInput
