import { renderToStaticMarkup } from 'react-dom/server'
import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'

import { intl } from '@/intl'

import { TotalTimeCell, TotalTimeTooltip } from '../../../components/cells/TotalTimeCell'

import { totalTimeSorter } from '../sorters/totalTimeSorter'

export function createTotalTimeColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    text: intl.translate({ id: 'rm.scheduler.column.totalTime' }),
    type: 'template',
    width,
    field: 'totalTime',
    editor: false,
    region: 'cols',
    hidden,

    template: ({ record, field, value }) => {
      return renderToStaticMarkup(
        <TotalTimeCell record={record as ResourceModel} field={field} value={value} />,
      )
    },

    sortable: totalTimeSorter,
    resizable: true,
    groupable: false,

    tooltipRenderer: ({ record }) => {
      const available = record.getData('available') as boolean

      if (!available) return ''

      return renderToStaticMarkup(<TotalTimeTooltip record={record as ResourceModel} />)
    },
  }
}
