import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import LineString from 'ol/geom/LineString'

import { setRoadSegmentFeatureMetadata } from '../roadSegmentFeatureMetadata'
import { getRoadSegmentFeatureStyle } from './getRoadSegmentFeatureStyle'

/**
 * Update a RoadSegment feature style and position on the map
 */
export function updateRoadSegmentFeature(
  mapStyles: uui.domain.ui.map.markers.MapStyles['roadSegment'],
  marker: uui.domain.ui.map.markers.RoadSegment,
  feature: Feature<Geometry>,
  selected: boolean = false,
): Feature<Geometry> {
  // ------------------------
  // Feature Geometry
  // ------------------------

  feature.setGeometry(new LineString([marker.start, marker.end]))

  // ------------------------
  // Feature Metadata
  // ------------------------

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['roadSegment']['mode'] =
    marker.mode ?? mapStyles.mode
  setRoadSegmentFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getRoadSegmentFeatureStyle(marker, mode, selected))

  return feature
}
