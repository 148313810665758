import { isDeepEqual } from '@/server-data'

export function areGpsTagsChanged(
  prev: uui.domain.client.gps.wwgps.Tag[],
  next: uui.domain.client.gps.wwgps.Tag[],
) {
  if (prev.length !== next.length) return true

  return !isDeepEqual(
    prev.sort((itemA, itemB) => parseInt(itemA.id) - parseInt(itemB.id)),
    next.sort((itemA, itemB) => parseInt(itemA.id) - parseInt(itemB.id)),
  )
}
