export const layoutSizes: Map<string, number> = new Map()

// Contextual Menu
layoutSizes.set('CM.height', 54)
layoutSizes.set('CM.lateralPadding', 20)
layoutSizes.set('CM.lateralGutter', 24)
layoutSizes.set('CM.innerHeight', 42)
layoutSizes.set('CM.infoOuterHeight', 36)
layoutSizes.set('CM.Info.width', 260)

// Chips
layoutSizes.set('CHIP.height', 36)
layoutSizes.set('CHIP.width', 42)

// Expanded Nav
layoutSizes.set('ExpNav.gutter', 8)

// Map Controls
layoutSizes.set('MC.height', 54)
layoutSizes.set('MC.lateralPadding', 12)
layoutSizes.set('MC.lateralGutter', 24)
layoutSizes.set('MC.innerHeight', 42)
layoutSizes.set('MC.infoOuterHeight', 36)
layoutSizes.set('MC.Info.width', 260)
