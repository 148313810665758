import { type ResourceModel } from '@bryntum/schedulerpro'
import { useMemo } from 'react'
import { useTexts } from '../useTexts'

const maxViolationsShowed: number = 2

export function useAggregateData(record: ResourceModel) {
  const texts = useTexts()

  return useMemo(() => {
    const hasViolations = record.get(
      'hasViolations',
    ) as uui.domain.client.rm.SchedulerResource['hasViolations']

    const violations = record.get('violations') as uui.domain.rm.RouteViolations

    if (!hasViolations || (violations.vehicle?.length ?? 0) === 0) return []

    const result: string[] = []
    const { vehicle = [] } = violations

    result.push(...vehicle.map(texts.vehicleViolationDescription))

    if (result.length > maxViolationsShowed) {
      return [
        ...result.slice(0, maxViolationsShowed),
        texts.more(result.length - maxViolationsShowed),
      ]
    }

    return result
  }, [record, texts])
}
