import { useEffect, useMemo, useState } from 'react'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { TextField } from '@workwave-tidal/tidal/components'

import { type FormErrors, type FormFields } from '../../../../../formFields'
import { useTexts } from '../../../../../useTexts'

export function Password() {
  const [password, setPassword] = useState<string>('')
  const { fieldApi, errors } = useFormField<'credentials', FormFields, FormErrors>('credentials')
  const texts = useTexts()

  const { helperText, error } = useMemo(() => {
    const passwordErrors = errors.filter(e => e.index === 1)
    if (passwordErrors.length === 0) {
      return {
        helperText: '',
        error: false,
      }
    }

    return {
      helperText: passwordErrors[0].message,
      error: true,
    }
  }, [errors])

  useEffect(() => {
    const passwordCredential = fieldApi
      .getField()
      .value.find(credential => credential.fieldName === 'username') ?? {
      fieldName: 'username',
      value: '',
    }

    fieldApi.change([passwordCredential, { fieldName: 'password', value: password }])
  }, [password, fieldApi])

  return (
    <TextField
      type="password"
      name="password"
      size="medium"
      label={texts.verizonConnect.passwordLabel}
      value={password}
      error={error}
      onChange={e => setPassword(e.target.value)}
      helperText={helperText}
      InputProps={{
        autoComplete: 'one-time-code',
      }}
    />
  )
}
