import styled from 'styled-components'
import { HorizontalRadioGroupColors } from './typings'

type Props = {
  hideOuterBorder?: boolean
  colors?: HorizontalRadioGroupColors
}

const styledComponent = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  border-radius: 5px;
  border-width: ${p => p.colors?.borderSize ?? 1}px;
  border-style: ${p => (p.hideOuterBorder ? 'hidden' : 'solid')};
  border-color: ${p => p.colors?.border ?? p.theme.colors.$nightRider};
`

styledComponent.displayName = 'HorizontalRadioGroupContainer'
export default styledComponent
