import { useCallback } from 'react'

import { useLocateFieldName } from './useLocateFieldName'

type Key = keyof uui.domain.ui.forms.ExceptionFormValuesValidation

export function useGetFieldsWithError() {
  const locateFieldName = useLocateFieldName()

  return useCallback(
    (errors: uui.domain.ui.forms.ExceptionFormValuesValidation) => {
      return Object.keys(errors).map((key: Key) => locateFieldName(key))
    },
    [locateFieldName],
  )
}
