import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    approvedRoutes: (count: number, deltaCount: number) =>
      translate({
        id: 'rm.contextual.route.counter.approvedRoutes',
        values: { count, deltaCount },
      }),
    routesWithExecutionEvents: (count: number) =>
      translate({ id: 'rm.contextual.route.counter.routesWithExecutionEvents', values: { count } }),
  }))

  return api
}
