import { FormControl, Typography, Slider, Stack } from '@mui/material'

import { useTexts } from '../../../hooks/useTexts'
import { useControllerActions } from '../../../hooks/useControllerActions'
import { useController } from '../../../hooks/useController'

import { IconButton } from './IconButton'
import { Rabbit } from './icons/Rabbit'
import { Tortoise } from './icons/Tortoise'
import { marks, snapToNext, snapToPrev } from './marks'

function valueLabelFormat(value: number) {
  return `${parseFloat((Math.abs(value) / 100).toFixed(2))}x`
}

export function DrivingTime() {
  const texts = useTexts()
  const { onChangeBaseTrafficFactor } = useControllerActions()
  const {
    data: {
      formValues: { baseTrafficFactor },
    },
  } = useController()

  // this functions are re-created every time baseTrafficFactor changes
  // there is no value to memoize them
  function onSnapToPrev() {
    onChangeBaseTrafficFactor(new Event(''), snapToPrev(baseTrafficFactor))
  }
  function onSnapToNext() {
    onChangeBaseTrafficFactor(new Event(''), snapToNext(baseTrafficFactor))
  }

  return (
    <FormControl data-testid="driving-time-field">
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        {texts.form.drivingTime}
      </Typography>

      <Stack direction="row" alignItems="flex-start" gap={1}>
        <IconButton onClick={onSnapToPrev} data-testid="driving-time-prev-btn">
          <Rabbit />
        </IconButton>
        <Slider
          size="small"
          min={-500}
          max={-50}
          value={baseTrafficFactor}
          onChange={onChangeBaseTrafficFactor}
          valueLabelFormat={valueLabelFormat}
          marks={marks}
          valueLabelDisplay="auto"
        />
        <IconButton onClick={onSnapToNext} data-testid="driving-time-next-btn">
          <Tortoise />
        </IconButton>
      </Stack>
    </FormControl>
  )
}
