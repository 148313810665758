import { useMemo } from 'react'

export function useGetDriversOfOrders(
  extOrderSteps: uui.domain.client.rm.ExtendedOrderStep[],
): string[] {
  return useMemo(() => {
    const drivers = extOrderSteps.reduce<Set<string>>((acc, extOrder) => {
      if (!extOrder.isUnassigned && extOrder.driverId) {
        acc.add(extOrder.driverId)
      }
      return acc
    }, new Set<string>())

    return [...drivers]
  }, [extOrderSteps])
}
