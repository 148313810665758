import { type ReactNode, type CSSProperties } from 'react'

interface Props {
  children: ReactNode
  style?: CSSProperties
}

export function SingleColumnContainer(props: Props) {
  const { children, style } = props

  return (
    <div className="o-scheduler-single-column" style={style}>
      {children}
    </div>
  )
}
