import { parse } from 'date-fns/esm'

const referenceDate = new Date()

export const parseDate = (date: string): Date => {
  try {
    return parse(date, 'yyyyMMdd', referenceDate)
  } catch (e) {
    // TODO: what to do here? Journal?
    throw new Error(`Cannot parse ${date}`)
  }
}

export const getDateAtMidnight = (date: Date = new Date()): Date => {
  const year: number = date.getFullYear()
  const month: number = date.getMonth()
  const day: number = date.getDate()
  return new Date(year, month, day)
}
