import { naturalSort } from '@/server-data'

const getAssetWeight = (a: uui.domain.client.gps.SearchOnMapItem): number => {
  switch (a.type) {
    case 'depot':
    case 'gpsPlace':
      return 1

    case 'address':
      return 2

    case 'place':
      return 3
  }
}

export const sortAssetList = (
  a: uui.domain.client.gps.SearchOnMapItem,
  b: uui.domain.client.gps.SearchOnMapItem,
) => {
  const weightA = getAssetWeight(a)
  const weightB = getAssetWeight(b)

  if (weightA === weightB) {
    return naturalSort(getLabelFromAsset(a), getLabelFromAsset(b))
  }

  return weightA - weightB
}

export const getLabelFromLocation = ({ geoAddress }: uui.domain.client.Location): string =>
  geoAddress

export const getLabelFromAsset = (a: uui.domain.client.gps.SearchOnMapItem): string => a.address

export const getPlaceNameFromAsset = (a: uui.domain.client.gps.SearchOnMapItem): string => {
  if (a.type === 'place') {
    return a.place.name
  }
  return ''
}
