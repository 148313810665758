import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderMapStyles } from '@/features/domain/ui'

import { useOrderStepsNavigator } from '../../../hooks/useOrderStepsNavigator'
import { useNavigoView } from '../../../hooks/useNavigoView'

export const useData = () => {
  const {
    navigate: { goToOverview },
  } = useNavigoView()
  const { currentOrderIds } = useOrderStepsNavigator()
  const mapStyles = useSelector(selectOrderMapStyles)

  /**
   * This methods retrieve the correct active style for the current order(s).
   * If there are different styles for the chosen orders it will return undefined.
   */
  const activeMapStyle = useMemo(() => {
    let commonMapStyle: 'on' | 'off' | 'maximized' | undefined

    for (const id of currentOrderIds) {
      const orderMapStyle = mapStyles.custom[id] ?? mapStyles.mode

      if (commonMapStyle && orderMapStyle !== commonMapStyle) return

      commonMapStyle = orderMapStyle
    }

    return commonMapStyle
  }, [mapStyles, currentOrderIds])

  return {
    currentOrderIds,
    mapStyles,
    goToOverview,
    activeMapStyle,
  }
}
