import { useSelector } from 'react-redux'

import { resetMainSelection } from '@/atoms'
import { selectTrackingListStructure } from '@/features/domain/lists'
import { useListSelection } from './useListSelection'

export function useDataForList() {
  const structure = useSelector(selectTrackingListStructure)
  const selection = useListSelection()

  const lastSelectedVehicle = selection.length > 0 ? selection[selection.length - 1] : undefined

  const deselectAll = () => {
    resetMainSelection('unifiedVehicles')
    resetMainSelection('devices')
  }

  return {
    selection,
    structure,
    deselectAll,
    lastSelectedVehicle,
  }
}
