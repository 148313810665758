import type { Props } from './typings'

import { Block, Label, FieldMessage, RestoreFieldButton, DrivingTimeSlider } from '@/forms-legacy'

export default function DrivingTimeSliderField(props: Props) {
  const {
    half,
    name,
    label,
    wrappedInputProps,
    info,
    testid,
    formProps: {
      input: { value, onChange },
      meta: { error, dirty, initial },
    },
  } = props

  return (
    <Block half={half} error={!!error} dirty={!!dirty} data-testid={testid} data-trackid={testid}>
      <Label htmlFor={name}>
        {label}{' '}
        <RestoreFieldButton
          dirty={dirty}
          initial={initial}
          onChange={onChange}
          style={{ marginLeft: 12 }}
        />
      </Label>
      <DrivingTimeSlider {...wrappedInputProps} name={name} onChange={onChange} value={value} />
      {!!error && <FieldMessage error>{error}</FieldMessage>}
      {!error && !!info && <FieldMessage>{info}</FieldMessage>}
    </Block>
  )
}
