import { intl } from '@/intl'

export const getAddCustomFieldText = (): string =>
  intl.translate({
    id: 'components.customFields.collapsed.add',
  })

export const getSearchFieldPlaceholderText = (): string =>
  intl.translate({
    id: 'components.customFields.search.placeholder',
  })

export const getLoadMoreText = (count: number): string =>
  intl.translate({
    id: 'components.customFields.suggestionsList.loadMore',
    values: { count },
  })

export const getAvailableCustomFieldsText = (count: number): string =>
  intl.translate({
    id: 'components.customFields.suggestionsList.available',
    values: { count },
  })

export const getBottomMessageText = (): string =>
  intl.translate({
    id: 'components.customFields.suggestionsList.bottomMessage',
  })

export const getNoResultText = (): string =>
  intl.translate({
    id: 'components.customFields.suggestionsList.noResults',
  })

export const getNotValidValueText = (): string =>
  intl.translate({
    id: 'components.customFields.noValidValue',
  })

export const getMaxCountReachedText = (): string =>
  intl.translate({
    id: 'components.customFields.maxCountReached',
  })
