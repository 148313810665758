import { useEffect } from 'react'
import { produce } from 'immer'
import { setListSubscribers } from '../listApiSubscribers'

/**
 * Subscribe to the list imperative API
 */
export function useSubscribeListApi(
  category: uui.domain.ui.list.Category,
  callback: uui.domain.ui.list.SubscriptionCallback,
) {
  useEffect(() => {
    const cb = callback

    setListSubscribers(category, prev => {
      let clearBuffer = false

      // the list clears the buffer, other passive listeners don't
      if (prev.scrollToBuffer !== undefined) {
        // LIST_IMPERATIVE_API: flow2, step4
        // If a scrollToBuffer exists, the callback is immediately called, and the StructuredVirtualList
        // clears the buffer

        const scrollToBuffer = prev.scrollToBuffer
        if (!!scrollToBuffer && callback('scrollTo', scrollToBuffer)) {
          clearBuffer = true
        }
      } else {
      }

      return produce(prev, draft => {
        if (clearBuffer) {
          draft.scrollToBuffer = undefined
        }
        draft.callbacks.push(callback)
      })
    })

    return () => {
      setListSubscribers(category, draft => {
        draft.callbacks = draft.callbacks.filter(callback => callback !== cb)

        return draft
      })
    }
  }, [category, callback])
}
