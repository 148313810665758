import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    mixed: translate({ id: 'order.form.bulk.mixed' }),
    email: translate({ id: 'orders.edit.field.email' }),
  }))

  return api
}
