import { produce } from 'immer'

import { reverseGeocodeLocation } from '../../../utils/reverseGeocodeLocation'
import { getMapInfo } from '../../map/hooks/useGetMapInfo'

import { updateLocationPin } from './updateLocationPin'
import { getLocationPin } from './getLocationPin'

/**
 * Create the location pin and immediately reverse geocode it.
 */
export async function moveLocationPin(
  id: uui.domain.ui.map.LocationPinId,
  nextLatLng: uui.domain.LatLng,
) {
  const { bounds } = getMapInfo()

  const targetPin = getLocationPin(id, true)

  if (!targetPin) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`Trying to move a non-existent LocationPin with ID: ${id}`)
    }
    return
  }

  // set loading status to true and latLng to the new coordinates
  updateLocationPin(id, prev => {
    return produce(prev, draft => {
      draft.loading = true
      draft.pin.latLng = nextLatLng
    })
  })

  const location = await reverseGeocodeLocation(nextLatLng, bounds, targetPin.pin.snapToRoad)

  if (!location) {
    updateLocationPin(id, { loading: false })
    return
  }

  // update the location pin
  updateLocationPin(id, prev => {
    return produce(prev, draft => {
      draft.loading = false
      draft.pin.location = location
      draft.pin.latLng = location.latLng
      draft.pin.location.address = location.geoAddress
    })
  })
}
