import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({
      id: 'userManagement.3pc.driversRestriction.title',
    }),
    fieldLabel: translate({ id: 'userManagement.3pc.driversRestriction.title' }),
    placeholder: translate({ id: 'userManagement.3pc.driversRestriction.placeholder' }),
    selectAll: (count: number) =>
      translate({ id: 'userManagement.3pc.selectAll', values: { count } }),
    deselectAll: (count: number) =>
      translate({ id: 'userManagement.3pc.deselectAll', values: { count } }),
  }))

  return api
}
