import { ChangeEvent, Component } from 'react'
import { clsx } from '@/utils'
import { Tick } from '@/icons'

export type Props = {
  checked: boolean
  className?: string
  onChange: (value: boolean, e?: ChangeEvent<HTMLElement>) => void
  disabled?: boolean
}
export default class Checkbox extends Component<Props> {
  handleChange = (e: ChangeEvent<HTMLElement>): void => {
    const { onChange, checked } = this.props
    onChange(!checked, e)
  }

  render() {
    const { className = '', checked, disabled } = this.props
    const rootClassName = clsx({
      'o-checkbox': true,
      'is-disabled': !!disabled,
      [className]: true,
    })
    const hackClassName = clsx({
      'o-checkbox__hack': true,
      'is-checked': checked,
    })

    return (
      <span className={rootClassName}>
        <span className={hackClassName}>
          <span className="o-checkbox__ico">
            <Tick />
          </span>
        </span>
        <input
          className="o-checkbox__field"
          type="checkbox"
          onChange={this.handleChange}
          checked={checked}
          disabled={disabled}
        />
      </span>
    )
  }
}
