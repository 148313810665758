import type { PopoverOrigin } from '@mui/material'

import { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderMapStyles } from '@/features/domain/ui'
import { useResizeObserver } from '@/hooks'

import { useMap } from '../../../atoms/map/mapApi'
import { useMapTooltip } from '../../../atoms/mapTooltip'

const transformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

const standardMarkerOffset = -20
const maximizedMarkerOffset = -25

export const useMapTooltipController = () => {
  const [{ map }] = useMap()
  const [mapTooltip] = useMapTooltip()
  const { mode, custom } = useSelector(selectOrderMapStyles)

  const [containerCoords, setContainerCoords] = useState<DOMRect>(
    () => map.getTargetElement()?.getBoundingClientRect() ?? { top: 0, left: 0 },
  )

  // Keep container coordinates updated also after resize
  useResizeObserver(map.getTargetElement(), (entries: ResizeObserverEntry[]) => {
    setContainerCoords(entries[0].target.getBoundingClientRect())
  })

  // Fix transformOrigin if the marker is maximized
  const verticalOffset = useMemo(() => {
    if (mapTooltip.type === 'none' || mapTooltip.ids.length === 0) return standardMarkerOffset

    let gotMaximizedStyle = false

    for (const tooltipId in mapTooltip.ids) {
      if (custom[tooltipId] === 'maximized') {
        gotMaximizedStyle = true
        break
      }
    }

    if (!gotMaximizedStyle && mode !== 'maximized') return standardMarkerOffset

    return maximizedMarkerOffset
  }, [mapTooltip, mode, custom])

  //Calculate absolute coords based on the map container
  const anchorPosition = useMemo(() => {
    if (mapTooltip.type === 'none') return

    return {
      left: mapTooltip.coordinates[0] + containerCoords.left,
      top: mapTooltip.coordinates[1] + containerCoords.top + verticalOffset,
    }
  }, [containerCoords, verticalOffset, mapTooltip])

  return { mapTooltip, anchorPosition, transformOrigin }
}
