import type { CrudSelection } from '../typings'
import type { SetCrudSelectionParam } from '../write/setCrudSelection'

import { useSnapshot } from 'valtio'
import { useCallback } from 'react'

import { crudAtom } from '../crud'
import { setCrudSelection } from '../write/setCrudSelection'

export const useCrudSelection = <Category extends keyof CrudSelection = keyof CrudSelection>(
  category: Category,
) => {
  return [
    useSnapshot(crudAtom).selection[category],
    useCallback(
      (params: SetCrudSelectionParam<Category>) => setCrudSelection(category, params),
      [category],
    ),
  ] as const
}
