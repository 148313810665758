export function NoResults() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 174 262"
      style={{ maxWidth: '100%', height: '100%' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m70.422 125.936-21.1824.851c-3.94-1.427-8.0559-2.312-12.2345-2.629-.9822.147-2.4825.773.1778 2.281 2.6604 1.508 5.4136 3.217 5.1506 4.315-.2629 1.099-2.4593 1.091-2.5366 2.73-.0516 1.088 3.4775.642 10.5873-1.338l17.9497 3.643 2.0881-9.853Zm-.5506-22.735L50.4136 92.8692c-4.3362-.4493-8.5905-1.4901-12.6444-3.0934-.8507-.5801-1.9721-1.8406 1.1987-2.0494 3.1708-.2088 6.4962-.5878 6.7746-1.7169.2784-1.1291-1.8096-2.1345-1.1445-3.7044.4382-1.0311 3.5316.9796 9.2803 6.0322l18.4601 4.6866-2.467 10.1771Zm44.8716-17.8887c3.016-1.7091 5.552-4.2302 6.558-7.7335 2.884-10.0537-11.601-12.8919-16.04-8.9014-4.439 3.9905-8.7155 16.6891-5.6221 18.8235 1.2451.8507 4.4781 1.0518 8.0971.3403l-6.666 12.5208 11.012 4.006 2.661-19.0557Z"
        fill="#E1EBF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.854 71.2143c1.199 1.3766 1.686 3.3332 2.22 5.8543.675-.0167 1.336.2007 1.87.6153.534.4146.908 1.001 1.059 1.6598.151.6589.07 1.3497-.23 1.9554-.3.6058-.8 1.0893-1.416 1.3686.31 1.1291.681 2.3201 1.145 3.5962 3.72-.7193 7.935-5.2357 6.898-11.4457 1.246-11.2446-15.297-10.0073-17.4-12.745-1.965 3.5034-1.137 7.2232 5.854 9.1411Z"
        fill="#699DD7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m117.305 144.586-23.9742 60.67-16.0782 51.289h-6.3493l24.5774-111.959h21.8243Z"
        fill="#E1EBF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.253 144.582c.255 21.322 3.975 50.021 4.571 51.351.391.892 14.08 12.16 41.065 33.804l-4.377 4.647c-31.832-18.56-48.485-29.078-49.959-31.553-2.204-3.712-9.404-37.894-15.1736-58.28l23.8736.031Z"
        fill="#E1EBF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.46 228.183c1.086.326 2.112.824 3.039 1.477.962.867 1.854 1.808 2.669 2.815l-17.71 21.097c-2.274-.967-2.986-2.111-2.143-3.41.843-1.3 1.547-2.39 2.081-3.272l3.866-13.479c.041-.122.123-.226.233-.294.056-.031.117-.05.181-.057.064-.007.128 0 .19.018l2.057.658c1.131-.459 2.153-1.149 3.001-2.027.61-.86 1.039-1.837 1.26-2.869.036-.122.094-.238.17-.34.081-.101.181-.185.294-.248.112-.062.236-.102.364-.116.125-.023.253-.023.379 0l.069.047Zm-87.6115 25.986c.4489 1.042.7258 2.149.8198 3.279-.0452 1.299-.1926 2.592-.4408 3.867H52.6804c-.7115-2.366-.3016-3.642 1.2451-3.828 1.5467-.186 2.8227-.363 3.8668-.518l12.8068-5.707c.1194-.047.2519-.047.3712 0 .0603.021.1155.055.1621.099.0466.044.0836.097.1086.156l.8275 1.995c1.0771.573 2.2629.913 3.4801.998 1.0485-.089 2.0687-.386 3.0007-.874.1167-.055.2427-.086.3712-.093.129-.003.2575.018.3789.062.1193.047.2293.115.3249.201.0941.087.1703.192.2242.309v.054Z"
        fill="#4383CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m101.186 251.263-30.1606-3.279 22.3733-103.398h27.0673l-19.28 106.677Z"
        fill="#699DD7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m161.509 222.192-19.798 23.433c-20.829-22.773-32.984-37.634-36.464-44.584-3.48-6.95-6.5915-25.768-9.3344-56.455h30.4314c1.511 27 2.542 41.127 3.094 42.38.551 1.253 11.242 12.995 32.071 35.226Z"
        fill="#A1C1E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.56 107.853c-17.7875 10.169-36.9513 14.485-61.7685 15.923l1.9179 11.415c23.6649 4.864 36.4949 6.89 50.5006-4.447 3.519-2.854 11.6-13.874 16.171-19.489l-6.821-3.402Z"
        fill="#1565C0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.781 154.825H85.8984c9.7135-29.625 14.6756-49.959 14.8876-61.0023 0-.5645 1.051-1.5467 1.508-1.423 3.361.9435 6.97.5878 10.827-1.0672 10.139 16.3485 13.657 36.4795 15.66 63.4925Z"
        fill="#EBEDEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.437 91.3456c-12.2967.1005-32.2417 2.3201-55.2414-5.0346l-2.2737 11.3452C69.307 108.831 86.9087 117.501 102.337 116.271c-6.6971 28.73-6.3877 52.248 8.399 52.248h22.861c3.634-24.407-5.909-58.249-14.756-75.3174-.225-.5479-.606-1.018-1.095-1.3519-.49-.334-1.066-.5173-1.658-.5273h-6.644l-.007.0232Z"
        fill="#4383CC"
      />
      <path
        d="m39.2251 82.2653 1.7091 72.2087c0 1.415.727 2.559 1.5467 2.544l2.5908-.062c.812 0 1.4539-1.191 1.423-2.606l-1.7091-72.2085c0-1.4152-.727-2.552-1.5468-2.5366l-2.5984.0542c-.8043.0464-1.4462 1.1909-1.4153 2.6062Z"
        fill="#699DD7"
      />
      <path
        d="M61.0175 77.7434c-5.5633 2.9321-11.7233 4.5535-18.0092 4.7402-6.286.1866-12.5314-1.0663-18.2589-3.6632-5.7274-2.5969-10.7854-6.4689-14.78711-11.32-4.00175-4.8512-6.84137-10.553-8.3018-16.6698C.20006 44.7139.157475 38.3442 1.536 32.2084c1.37852-6.1357 4.14164-11.875 8.07817-16.7792C13.5507 10.525 18.5564 6.58568 24.2486 3.91247 29.9409 1.23926 36.169-.0971174 42.4569.00549222h.8971C52.4465.322445 61.1786 3.63923 68.1886 9.43866c7.0101 5.79944 11.904 13.75554 13.9188 22.62764 2.0148 8.8721 1.0371 18.1615-2.7807 26.4198-3.8177 8.2583-10.2608 15.0211-18.3246 19.2341l.0154.0232Zm18.8314-35.0795c.3302-9.3797-2.8393-18.547-8.8923-25.7198C64.9036 9.77134 56.3997 5.10564 47.098 3.85405c-9.3017-1.25158-18.7364 1.00039-26.4701 6.31815-7.7336 5.3177-13.21354 13.3212-15.37439 22.4545-2.16084 9.1334-.84822 18.7439 3.68269 26.9632 4.5309 8.2194 11.9564 14.4603 20.8328 17.5094 8.8764 3.0491 18.5695 2.6886 27.1949-1.0115 8.6253-3.7001 15.5666-10.4753 19.4744-19.0085 2.0728-4.5345 3.2317-9.4329 3.4106-14.4154Z"
        fill="#699DD7"
      />
      <path
        opacity=".5"
        d="M4.24731 40.2202c-.31015 9.349 2.86634 18.4795 8.91219 25.6173 6.0459 7.1378 14.5294 11.7731 23.8021 13.0053 9.2727 1.2322 18.6724-1.0267 26.3726-6.3379 7.7001-5.3111 13.1509-13.2952 15.2931-22.4007 2.1422-9.1056.8229-18.6824-3.7016-26.8696-4.5245-8.1871-11.9311-14.39991-20.7806-17.43116C45.2957 2.7722 35.6354 3.139 27.0413 6.83256 18.4471 10.5261 11.533 17.2827 7.64236 25.7894c-2.07075 4.5398-3.22451 9.4439-3.39505 14.4308Z"
        fill="#D4E3F3"
      />
      <path
        d="m40.3056 132.18 6.6973-2.606.6604 3.282-2.9689.742-3.34.742s-1.9341.494-2.2266-.371c-.3789-1.121 1.1778-1.789 1.1778-1.789Zm-1.9848-49.6304c1.8251-.5723 9.6437 4.1452 9.6437 4.1452l.3945 5.2743-9.4118-1.0672s-2.6681-.6806-2.8615-3.3177c-.1933-2.6372.4099-4.4546 2.2351-5.0346Z"
        fill="#E1EBF7"
      />
    </svg>
  )
}
