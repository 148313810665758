import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'

import { FlexBox, Text } from '@/local/components'
import { selectUserConfiguration } from '@/features/domain/user'

import { Letter } from '@/icons'

import { GoToSettingsButton } from './GoToSettingsButton'
import { useTexts } from './useTexts'

const alignmentStyle = { alignItems: 'center', display: 'flex' }

export function NoNotifications() {
  const { rmAdmin } = useSelector(selectUserConfiguration)
  const texts = useTexts()

  return (
    <FlexBox column vAlignContent="top" hAlignContent="center">
      <Stack spacing={2} sx={alignmentStyle}>
        <Letter size={24} />
        <Stack spacing={1} sx={alignmentStyle}>
          <Text weight="$semiBold" size="$l">
            {texts.title}
          </Text>
          <Text size="$s" color="$shadyLady">
            {texts.description(rmAdmin)}
          </Text>
        </Stack>
        {rmAdmin && <GoToSettingsButton />}
      </Stack>
    </FlexBox>
  )
}
