import { Box, type BoxProps, styled } from '@mui/material'
import { type ComponentType } from 'react'

export const Root = styled(Box)<BoxProps>(({ theme: { spacing } }) => {
  return {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    overflow: 'hidden',
    gap: spacing(0),
    padding: `${spacing(3)} ${spacing(4)} ${spacing(2)}`,
  }
}) as ComponentType<BoxProps>
