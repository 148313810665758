import type { MonthNavigatorProps as Props } from '../typings'
import { Box, styled } from '@mui/material'
import { HorizontalLayout } from '@/components/layout'
import { Left, Right } from '@/icons'
import { Text } from '@/local/components'
import { monthWidth, monthSpace } from '../constants'
import { navigationButtonWidth, NavigationButton } from './NavigationButton'

const btnSize = navigationButtonWidth + 6

export function MonthNavigator(props: Props) {
  const { labels, disablePrev, disableNext, onClickPrev, onClickNext, hideNavigation } = props

  const labelWidth =
    labels.length === 2
      ? monthWidth - btnSize
      : labels.length === 1
        ? monthWidth - 2 * btnSize
        : monthWidth

  return (
    <HorizontalLayout justifyContent="center" width="auto" style={{ marginBottom: 12 }}>
      {!hideNavigation && (
        <NavigationButton
          disabled={disablePrev}
          onClick={onClickPrev}
          data-testid="prev-month"
          data-trackid="calendar-prev-month"
        >
          <Left size={12} />
        </NavigationButton>
      )}
      {labels.map((label, index) =>
        label ? (
          <MonthLabelContainer index={index} width={labelWidth} key={index}>
            <Text uppercase weight="$semiBold" size="$h3" color="$paleSky">
              {label}
            </Text>
          </MonthLabelContainer>
        ) : null,
      )}
      {!hideNavigation && (
        <NavigationButton
          disabled={disableNext}
          onClick={onClickNext}
          data-testid="next-month"
          data-trackid="calendar-next-month"
        >
          <Right size={12} />
        </NavigationButton>
      )}
    </HorizontalLayout>
  )
}

type MonthLabelContainerProps = {
  index: number
  width: number
}

export const MonthLabelContainer = styled(Box, {
  name: 'MonthLabelContainer',
})<MonthLabelContainerProps>(({ index, width }) => ({
  textAlign: 'center',
  marginLeft: `${index > 0 ? monthSpace : 0}px`,
  width: `${width}px`,
}))
