import type { BarcodesValidationResult, BarcodeStatusOrUnScanned } from './typings'
import { intl } from '@/intl'
import { LoadsListText, TagsListTexts, TagsListProps } from '@/forms-legacy'

export const getCreateNewText = (): string => intl.translate({ id: 'orders.list.createNewOrder' })

export const getSegmentsCountText = (selected: number, count: number): string =>
  intl.translate({
    id: selected > 0 ? 'orders.list.footerCountWithSelection' : 'orders.list.footerCount',
    values: { count, selected },
  })

export const getCopyToOperations = () => intl.translate({ id: 'global.copyToOperations' })

export const getSelectButtonText = (allSelected: boolean): string =>
  intl.translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' })

export const getVisibilityButtonText = (someVisible?: boolean): string =>
  intl.translate({
    id: someVisible ? 'global.hideAll' : 'global.showAll',
  })

export const getUnnamedOrderText = (): string => intl.translate({ id: 'orders.unnamedOrder' })

export const getETAText = (): string => intl.translate({ id: 'orders.eta' })

export const getExecutionDetailsText = (): string =>
  intl.translate({ id: 'orders.form.executionEvents' })
export const getExecutionDetailsTextDisabled = (): string =>
  intl.translate({ id: 'v3.orders.form.executionEvents.disabledInSimulation' })

export const getNotificationText = (): string => intl.translate({ id: 'orders.form.notifications' })
export const getNotificationTextDisabled = (): string =>
  intl.translate({ id: 'v3.orders.form.notifications.disabledInSimulation' })

export const getOrderInformationText = (): string =>
  intl.translate({ id: 'orders.form.orderInformation' })

export const getOrderImportText = (): string => intl.translate({ id: 'orders.list.importOrders' })

export const getYourOrdersText = (): string => intl.translate({ id: 'orders.list.yourOrders' })

export const getCustomFieldText = (): string => intl.translate({ id: 'orders.customFields' })

export const getGoToPairedText = (type: uui.domain.client.rm.OrderStepIdentifier): string => {
  const id = type === 'd' ? 'orders.goTo.pickup' : 'orders.goTo.dropoff'

  return intl.translate({ id })
}

export const getPairedOutOfRangeTitle = (
  type: uui.domain.client.rm.OrderStepIdentifier,
  orderName: string,
): string => {
  const id =
    type === 'd'
      ? 'orders.goTo.pairedOutOfRange.pickup.title'
      : 'orders.goTo.pairedOutOfRange.dropoff.title'

  return intl.translate({ id, values: { orderName } })
}

export const getPairedOutOfRangeSubtitle = (
  type: uui.domain.client.rm.OrderStepIdentifier,
  vehicleName: string,
  date: string,
): string => {
  const id =
    type === 'd'
      ? 'orders.goTo.pairedOutOfRange.pickup.subtitle'
      : 'orders.goTo.pairedOutOfRange.dropoff.subtitle'

  return intl.translate({
    id,
    values: { vehicleName, date },
  })
}

export const getExecutionDetailsTitle = (): string =>
  intl.translate({ id: 'orders.form.reportedExecutionDetails' })
export const getReportedTimeInLabel = (): string =>
  intl.translate({ id: 'orders.form.reportedTimeIn' })
export const getReportedTimeOutLabel = (): string =>
  intl.translate({ id: 'orders.form.reportedTimeOut' })
export const getReportedStatusWithDate = (date: string): string =>
  intl.translate({ id: 'orders.form.reportedStatusWithDate', values: { date } })
export const getReportedStatus = (): string => intl.translate({ id: 'orders.form.reportedStatus' })

export const getDetectedExecutionDetailsTitle = (): string =>
  intl.translate({ id: 'orders.form.detectedExecutionDetails' })
export const getDetectedTimeInLabel = (): string =>
  intl.translate({ id: 'orders.form.detectedTimeIn' })
export const getDetectedTimeOutLabel = (): string =>
  intl.translate({ id: 'orders.form.detectedTimeOut' })

export const getCalculatedServiceTimeText = (): string =>
  intl.translate({ id: 'orders.form.calculatedServiceTime' })
export const getBasedOnDetectedTime = (): string =>
  intl.translate({ id: 'orders.form.basedOnDetectedTime' })
export const getBasedOnReportedTime = (): string =>
  intl.translate({ id: 'orders.form.basedOnReportedTime' })

export const getNoneText = (): string => intl.translate({ id: 'order.status.missing' })
export const getUnableToCalculateText = (): string =>
  intl.translate({ id: 'global.unableToCalculate' })

export const getOrderStatusText = (
  status: workwave.Nullable<uui.domain.client.rm.OrderStepTrackingData['status']>,
): string => {
  if (!status) {
    return getNoneText()
  }

  switch (status) {
    case 'missing':
      return intl.translate({ id: 'order.status.missing' })

    case 'reschedule':
      return intl.translate({ id: 'order.status.reschedule' })

    default:
    case 'done':
      return intl.translate({ id: 'order.status.done' })
  }
}

export const getPODTitle = (): string => intl.translate({ id: 'global.POD' })
export const getNoteLabel = (): string => intl.translate({ id: 'global.note' })
export const getPicturesLabel = (): string => intl.translate({ id: 'global.pictures' })
export const getPictureLabel = (): string => intl.translate({ id: 'global.picture' })
export const getVoiceMemosLabel = (): string => intl.translate({ id: 'global.voiceMemos' })
export const getVoiceMemoLabel = (): string => intl.translate({ id: 'global.voiceMemo' })
export const getSignaturesLabel = (): string => intl.translate({ id: 'global.signatures' })
export const getCustomerSignatureLabel = (): string =>
  intl.translate({ id: 'global.customerSignature' })

export const getMixedText = (): string => intl.translate({ id: 'orders.mixed' })

export const getMixedAddressesText = (): string => intl.translate({ id: 'orders.mixedAddresses' })

export const getFormErrorsTitleText = (): string =>
  intl.translate({ id: 'orders.edit.formErrorsTitle' })

export const getFormSaveText = (): string => intl.translate({ id: 'orders.edit.save' })

export const getPriorityLowestText = (): string => intl.translate({ id: 'orders.priority.lowest' })
export const getPriorityLowerText = (): string => intl.translate({ id: 'orders.priority.lower' })
export const getPriorityNormalText = (): string => intl.translate({ id: 'orders.priority.normal' })
export const getPriorityHigherText = (): string => intl.translate({ id: 'orders.priority.higher' })
export const getPriorityHighestText = (): string =>
  intl.translate({ id: 'orders.priority.highest' })

export const getOrderTypePickupText = (): string =>
  intl.translate({ id: 'orders.edit.field.orderType.pickup' })
export const getOrderTypeDeliveryText = (): string =>
  intl.translate({ id: 'orders.edit.field.orderType.delivery' })
export const getOrderTypePickupDeliveryText = (): string =>
  intl.translate({ id: 'orders.edit.field.orderType.pickupDelivery' })
export const getOrderTypeServiceText = (): string =>
  intl.translate({ id: 'orders.edit.field.orderType.service' })
export const getDeletePairedFirstMessage = () => intl.translate({ id: 'orders.deleteFirstPaired' })

export const getFieldLabelNameText = (): string => intl.translate({ id: 'orders.edit.field.name' })
export const geFieldLabelNotesText = (): string => intl.translate({ id: 'orders.edit.field.notes' })
export const geFieldLabelEligibilityText = (): string =>
  intl.translate({ id: 'orders.edit.field.eligibility' })
export const getFieldLabelLoadsText = (): string =>
  intl.translate({ id: 'orders.edit.field.loads' })

export const getFieldLabelCustomFieldsText = (): string =>
  intl.translate({ id: 'orders.edit.field.customFields' })

export const getTagsInLabelText = (): string => intl.translate({ id: 'orders.edit.field.tags.in' })
export const getTimeWindowListText = (): string =>
  intl.translate({ id: 'orders.edit.field.timeWindowList' })
export const getTimeWindowExceptionListText = (): string =>
  intl.translate({ id: 'orders.edit.field.timeWindowExceptionList' })

export const getTagsOutLabelText = (): string =>
  intl.translate({ id: 'orders.edit.field.tags.out' })
export const geFieldLabelPriorityText = (): string =>
  intl.translate({ id: 'orders.edit.field.priority' })
export const geFieldLabelOrderTypeText = (): string =>
  intl.translate({ id: 'orders.edit.field.orderType' })
export const geFieldLabelServiceTimeText = (): string =>
  intl.translate({ id: 'orders.edit.field.serviceTime' })

export const getFieldLabelAddressText = (): string =>
  intl.translate({ id: 'orders.edit.field.address' })
export const getFieldLabelPhoneText = (): string =>
  intl.translate({ id: 'orders.edit.field.phone' })
export const getFieldLabelEmailText = (): string =>
  intl.translate({ id: 'orders.edit.field.email' })

export const getValidationErrorPickupHeaderText = (): string =>
  intl.translate({ id: 'orders.edit.validation.header.pickup' })
export const getValidationErrorDeliveryHeaderText = (): string =>
  intl.translate({ id: 'orders.edit.validation.header.delivery' })
export const getValidationErrorNameText = (): string =>
  intl.translate({ id: 'orders.edit.validation.name' })
export const getValidationErrorEligibilityDateText = (): string =>
  intl.translate({ id: 'orders.edit.validation.eligibility.date' })
export const getValidationErrorEligibilityDatesText = (): string =>
  intl.translate({ id: 'orders.edit.validation.eligibility.dates' })
export const getValidationErrorLoadsText = (): string =>
  intl.translate({ id: 'orders.edit.validation.loads' })
export const getValidationErrorTagsCollisionText = (): string =>
  intl.translate({ id: 'orders.edit.validation.tags.collision' })
export const getValidationErrorTimeWindowsValuesText = (): string =>
  intl.translate({ id: 'orders.edit.validation.timeWindow.startEndError' })
export const getValidationErrorTimeWindowExceptionInvalidDateText = (): string =>
  intl.translate({ id: 'orders.edit.validation.timeWindowException.invalidDate' })
export const getValidationErrorCustomFieldText = (): string =>
  intl.translate({ id: 'orders.edit.validation.customFields.noValidValue' })
export const getValidationErrorEmailText = (): string =>
  intl.translate({ id: 'orders.edit.validation.email.invalid' })
export const getValidationErrorPhoneText = (): string =>
  intl.translate({ id: 'orders.edit.validation.phone.invalid' })
export const getValidationErrorServiceTimeText = (): string =>
  intl.translate({ id: 'orders.edit.validation.serviceTime.invalid' })

export const getUndefinedLoadText = (): string =>
  intl.translate({ id: 'components.loadList.undefinedLoad' })

export const getPickupSettingsTitle = (): string =>
  intl.translate({ id: 'orders.edit.titles.pickup' })
export const getDeliverySettingsTitle = (): string =>
  intl.translate({ id: 'orders.edit.titles.delivery' })
export const getContactInformationTitle = (): string =>
  intl.translate({ id: 'orders.edit.titles.contactInformation' })

export const getPartialLoadsTitle = (): string =>
  intl.translate({ id: 'components.loadList.partialLoads.title' })

export const getPartialTagsTitle = (): string =>
  intl.translate({ id: 'components.tagsList.partialTags.title' })

// BUlK related

export const getFormBulkSaveText = (count: number): string =>
  intl.translate({
    id: 'orders.edit.bulk.save',
    values: { count },
  })

export const getBulkGenericInfoMessageText = (): string =>
  intl.translate({ id: 'orders.edit.bulk.genericInfoMessage' })

export const getBulkMixedServiceTimesText = (): string =>
  intl.translate({ id: 'orders.edit.bulk.field.serviceTime.mixedValues' })
export const getBulkMixedTimeWindowsText = (): string =>
  intl.translate({ id: 'orders.edit.bulk.field.timeWindows.mixedValues' })
export const getBulkRemoveAllServiceTimesText = (): string =>
  intl.translate({ id: 'orders.edit.bulk.field.serviceTime.removeAll' })

export const getBulkTimeWindowsInfoText = (): string =>
  intl.translate({ id: 'orders.edit.bulk.field.timeWindows.info' })

export const getBulkMixedAssignmentsText = (): string =>
  intl.translate({ id: 'orders.edit.bulk.field.forcedVehicleId.mixedValues' })
export const getBulkRemoveAllAssignmentsText = (): string =>
  intl.translate({ id: 'orders.edit.bulk.field.forcedVehicleId.removeAll' })

export const getBulkEligibilityOnMissingValueText = (): string =>
  intl.translate({ id: 'orders.edit.bulk.field.eligibility.on.missing' })
export const getBulkEligibilityByMissingValueText = (): string =>
  intl.translate({ id: 'orders.edit.bulk.field.eligibility.by.missing' })

export const getPodShowOnMapText = (show: boolean): string =>
  show
    ? intl.translate({ id: 'orders.pod.hideOnMap' })
    : intl.translate({ id: 'orders.pod.showOnMap' })

export const getExportFileTypeTitleText = (): string =>
  intl.translate({ id: 'orders.form.export.fileType.title' })

export const getExportFileTypeSubtitleText = (): string =>
  intl.translate({ id: 'orders.form.export.fileType.subtitle' })

export const getExportButtonText = (): string =>
  intl.translate({ id: 'orders.form.export.fileType.buttonText' })

export const getDeleteConfirmTitleText = (isBulk: boolean): string =>
  intl.translate({ id: 'orders.form.delete.title', values: { count: isBulk ? 2 : 1 } })

export const getDeleteConfirmSubTitleText = (): string =>
  intl.translate({ id: 'orders.form.delete.subtitle' })

export const getDeleteOnlySelectedText = (): string =>
  intl.translate({ id: 'orders.form.delete.button.onlySelected' })

export const getDeleteAlsoPairedText = (): string =>
  intl.translate({ id: 'orders.form.delete.button.alsoPaired' })

export const getUnassignConfirmTitleText = (isBulk: boolean): string =>
  intl.translate({
    id: 'orders.form.unassign.title',
    values: { count: isBulk ? 2 : 1 },
  })

export const getUnassignConfirmSubTitleText = (): string =>
  intl.translate({ id: 'orders.form.unassign.subtitle' })

export const getUnassignOnlySelectedText = (): string =>
  intl.translate({ id: 'orders.form.unassign.button.onlySelected' })

export const getUnassignAlsoPairedText = (): string =>
  intl.translate({ id: 'orders.form.unassign.button.alsoPaired' })

export const getFilterPlaceholderText = (): string =>
  intl.translate({ id: 'orders.form.filterPlaceholder' })

export const getFitInText = (): string => intl.translate({ id: 'rm.contextual.order.fitIn' })

export const getSubmitButtonTitle = (
  planLocked: boolean,
  valid: boolean,
  pristine: boolean,
): string => {
  if (!valid) {
    return intl.translate({ id: 'orders.form.submitButton.disabled.title.invalid' })
  }

  if (planLocked) {
    return intl.translate({ id: 'orders.form.submitButton.disabled.title.planLocked' })
  }

  if (pristine) {
    return intl.translate({ id: 'orders.form.submitButton.disabled.title.pristine' })
  }

  return ''
}
export const getBarcodesFieldText = (): string =>
  intl.translate({ id: 'orders.field.barcode.label' })

export const getBarcodesAddNewText = (): string =>
  intl.translate({ id: 'orders.field.barcode.btn.addNew' })

export const getBarcodesValidationText = (
  validation: BarcodesValidationResult,
  maxCount: number,
  maxLength: number,
): string => {
  switch (validation.type) {
    case 'maxCountViolation':
      return intl.translate({
        id: 'orders.field.barcode.validation.maxCountViolation',
        values: { count: maxCount },
      })
    case 'maxLengthViolation':
      return intl.translate({
        id: 'orders.field.barcode.validation.maxLengthViolation',
        values: { count: maxLength },
      })

    case 'notAllowedCharactersViolation':
      return intl.translate({
        id: 'orders.field.barcode.validation.notAllowedCharactersViolation',
      })

    case 'emptyValue':
      return intl.translate({
        id: 'orders.field.barcode.validation.emptyValue',
      })

    default:
      return ''
  }
}

export const getBarcodesStatusText = (status: BarcodeStatusOrUnScanned): string => {
  switch (status) {
    case 'SCANNED':
      return intl.translate({
        id: 'orders.field.barcode.status.scanned',
      })
    case 'MISSING_BARCODE':
      return intl.translate({
        id: 'orders.field.barcode.status.missingBarcode',
      })
    case 'MISSING_PACKAGE':
      return intl.translate({
        id: 'orders.field.barcode.status.missingPackage',
      })
    case 'UNREADABLE':
      return intl.translate({
        id: 'orders.field.barcode.status.unreadable',
      })
    case 'UNSCANNED':
      return intl.translate({
        id: 'orders.field.barcode.status.unscanned',
      })

    default:
      return ''
  }
}

export const getReadonlyReasonText = (): string =>
  intl.translate({ id: 'orders.field.barcode.title.readonly' })

export const getAddBarcodesButtonDisabledText = (): string =>
  intl.translate({ id: 'orders.field.barcode.addButton.disabled.title' })

export const getUnassignedText = (): string => intl.translate({ id: 'global.unassigned' })

export const getAssignedText = (): string => intl.translate({ id: 'global.assigned' })

export const getAllOrdersText = (): string => intl.translate({ id: 'global.allOrders' })

export const getPickupText = (): string => intl.translate({ id: 'global.pickup' })

export const getPickupAndDeliveryText = (): string =>
  intl.translate({ id: 'global.pickupDelivery' })

export const getDeliveryText = (): string => intl.translate({ id: 'global.delivery' })

export const getServiceText = (): string => intl.translate({ id: 'global.service' })

export const getGlobalNoneText = (): string => intl.translate({ id: 'global.none' })

export const getOrdersText = (): string => intl.translate({ id: 'global.orders' })

export const getDownloadText = (): string => intl.translate({ id: 'global.download' })

export const getEligibilityLabel = (
  { type }: uui.domain.client.rm.Eligibility,
  dateAsString: string,
): string => {
  switch (type) {
    case 'any':
      return intl.translate({ id: 'global.eligibility.any' })

    case 'on':
      return intl.translate({
        id: 'global.eligibility.on',
        values: { date: dateAsString },
      })

    case 'by':
      return intl.translate({
        id: 'global.eligibility.by',
        values: { date: dateAsString },
      })
  }
}

export const getDuplicateText = (): string => intl.translate({ id: 'global.duplicate' })

export const getExportText = (): string => intl.translate({ id: 'global.export' })

export const getUnassignText = (): string => intl.translate({ id: 'global.unassign' })

export const getDeleteText = (): string => intl.translate({ id: 'global.delete' })

export const getEditText = (): string => intl.translate({ id: 'global.edit' })

export const getCancelText = (): string => intl.translate({ id: 'global.cancel' })

export const getLoadsRenderText = (
  field: LoadsListText,
  count: number = 0,
  filter: string = '',
): string => {
  switch (field) {
    case 'addLoad':
      return intl.translate({
        id: 'components.loadList.collapsed.addLoad',
      })

    case 'maxCountReached':
      return intl.translate({
        id: 'components.loadList.maxCountReached',
      })

    case 'searchPlaceholder':
      return intl.translate({
        id: 'components.loadList.search.placeholder',
      })

    case 'loadMore':
      return intl.translate({
        id: 'components.loadList.suggestionsList.loadMore',
        values: { count },
      })

    case 'availableLoads':
      return intl.translate({
        id: 'components.loadList.suggestionsList.availableLoads',
        values: { count },
      })

    case 'suggestionsBottomMessage':
      return intl.translate({
        id: 'components.loadList.suggestionsList.bottomMessage',
      })

    case 'noResult':
      return intl.translate({
        id: 'components.loadList.suggestionsList.noResults',
      })

    case 'alreadyUsed':
      return intl.translate({
        id: 'components.loadList.suggestionsList.alreadyUsed',
        values: { filter },
      })

    case 'notValidValue':
      return intl.translate({
        id: 'components.loadList.loads.noValidValue',
      })

    case 'notValidMinValue':
      return intl.translate({
        id: 'components.loadList.loads.noValidMin',
      })

    case 'removeLoads':
      return intl.translate({
        id: 'components.loadList.removeWithCount',
        values: { count },
      })

    case 'addToAll':
      return intl.translate({
        id: 'components.loadList.addToAll',
      })

    case 'noLoads':
      return intl.translate({
        id: 'components.loadList.noLoads',
      })

    case 'sharedLoad':
      return intl.translate({
        id: 'components.loadList.loads.title',
      })

    case 'mixedValue':
      return intl.translate({
        id: 'global.mixed',
      })

    case 'toggleButton':
      const collapsed = !!count
      return intl.translate({
        id: collapsed ? 'global.show' : 'global.hide',
      })

    default:
      return field
  }
}

export const getTagsRenderText = (
  field: TagsListTexts,
  props: TagsListProps,
  collapsed?: boolean,
  count?: number,
) => {
  switch (field) {
    case 'addTag':
      const { isBanList } = props
      return intl.translate({
        id: isBanList
          ? 'components.tagsList.collapsed.addTag.out'
          : 'components.tagsList.collapsed.addTag.in',
      })

    case 'suggestedListTitle':
      return intl.translate({
        id: 'components.tagsList.suggestionsList.title',
      })

    case 'searchPlaceholder':
      return intl.translate({
        id: 'components.tagsList.search.placeholder',
      })

    case 'loadMoreTag':
      return intl.translate({
        id: 'components.tagsList.suggestionsList.loadMore',
        values: { count: count || 0 },
      })

    case 'suggestionsListMessage':
      return intl.translate({
        id: 'components.tagsList.suggestionsList.bottomMessage',
      })

    case 'noResult':
      return intl.translate({
        id: 'components.tagsList.suggestionsList.noResults',
      })

    case 'maxCountReached':
      return intl.translate({
        id: 'components.tagsList.maxCountReached',
      })

    case 'noTags':
      return intl.translate({
        id: 'components.tagsList.noTags',
      })

    case 'bulkTitle':
      return intl.translate({
        id: 'components.tagsList.bulkMode.title',
      })
    case 'addToAll':
      return intl.translate({
        id: 'components.tagsList.addToAll',
      })

    case 'tagsListMessage':
      return intl.translate({
        id: 'components.tags.suggestionsList.bottomMessage',
      })

    case 'remove':
      return intl.translate({
        id: 'components.tagsList.removeWithCount',
        values: { count: count || 0 },
      })

    case 'toggleVisibility':
      return intl.translate({
        id: collapsed ? 'global.show' : 'global.hide',
      })

    default:
      return field
  }
}

export const getReportedStatusReason = (): string =>
  intl.translate({ id: 'orders.form.reportedStatusReason' })

export const getDisabledForBeta = (): string => intl.translate({ id: 'global.disabledForBeta' })

export const disabledTooltipTitle = () =>
  intl.translate({ id: 'global.disabledTooltip.transactionInProgress.title' })

export const disabledTooltipSubtitle = (): string =>
  intl.translate({
    id: 'global.disabledTooltip.transactionInProgress.subtitle',
  })

export const disabledCreateTooltipTitle = (isSimulation: boolean): string => {
  const id = isSimulation
    ? 'orders.list.createNewOrder.disabled.simulations.title'
    : 'orders.list.createNewOrder.disabled.operations.title'

  return intl.translate({ id })
}

export const disabledCreateTooltipSubtitle = (isSimulation: boolean, count: number): string => {
  const id = isSimulation
    ? 'orders.list.createNewOrder.disabled.simulations.subtitle'
    : 'orders.list.createNewOrder.disabled.operations.subtitle'

  return intl.translate({ id, values: { count } })
}

// Updated in v3
export const getFieldLabelVehicleAssignmentText = () =>
  intl.translate({
    id: 'order.form.single.field.forceVehicleId.label',
  })

export const getAutomaticAssignmentText = () =>
  intl.translate({
    id: 'order.form.single.field.forceVehicleId.noneLabel',
  })
