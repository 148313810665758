import styled from 'styled-components'

const styledComponent = styled.button.attrs<workwave.Button>(() => ({
  className: 'o-try-notification-button',
}))`
  margin: 0;
  padding: 0 14px;
  border: 0;
  border-radius: 4px;
  height: 26px;
  width: 90px;
  line-height: 22px;

  background: ${p => p.theme.colors.$whiteSmoke};
  color: ${p => p.theme.colors.$nightRider};

  font-size: ${p => p.theme.fonts.$m};
  font-weight: ${p => p.theme.weights.$bold};
  outline: 0;
  transition: 0.3s ease;
  transition-property: background, color;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${p => p.theme.colors.$whiteSmoke};
    color: ${p => p.theme.colors.$pureWhite};
  }
`

styledComponent.displayName = 'TryNotificationButton'
export default styledComponent
