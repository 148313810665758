import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (count: number) =>
      translate({
        id: 'form.drivers.modal.delete.header.title',
        values: { count },
      }),

    invalidSingleTitle: translate({ id: 'form.drivers.modal.delete.header.invalid.single.title' }),
  }))

  return api
}
