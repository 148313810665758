import { Grow, VerticalLayout, HorizontalLayout } from '@/components/layout'
import { Text } from '@/local/components'
import { useInspectPin } from '@/map'
import { Gps } from '@/icons'

import { useTexts } from '../useTexts'

export function Position() {
  const [inspectPin] = useInspectPin()
  const texts = useTexts()

  if (!inspectPin) return null

  return (
    <Grow xAxis>
      <VerticalLayout justifyContent="center" alignItems="start" marginLeft={25}>
        <HorizontalLayout alignItems="center">
          <Text size="$h4" color="$silver">
            {texts.lat}
          </Text>
          <Text size="$h4" color="$nightRider" marginLeft={5}>
            {inspectPin.latLng.lat.toFixed(4)}
          </Text>
          <Text size="$h4" color="$silver" marginLeft={10}>
            {texts.lng}
          </Text>
          <Text size="$h4" color="$nightRider" marginLeft={5}>
            {inspectPin.latLng.lng.toFixed(4)}
          </Text>
        </HorizontalLayout>

        <HorizontalLayout alignItems="center">
          <Gps size={10} color="$sailBlue" marginLeft={-15} marginRight={5} />

          <Text size="$h4" color="$silver" italic>
            {inspectPin.status === 'ready' ? inspectPin.location.geoAddress : texts.geocoding}
          </Text>
        </HorizontalLayout>
      </VerticalLayout>
    </Grow>
  )
}
