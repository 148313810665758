import type { TrafficAreaFormValues } from '../types'

import { useState, useCallback } from 'react'

import { updateTrafficArea, addTrafficArea } from '@/features/domain/traffic'
import { useResetEditingState, useCrudSelection } from '@/atoms'
import { geo } from '@/server-data'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useOnSubmit(trafficProfile: uui.domain.client.rm.TrafficProfile) {
  const stopEditing = useResetEditingState()
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const [trafficAreaSelection, setTrafficAreaSelection] = useCrudSelection('trafficArea')
  const recurrence = trafficProfile.id

  const [submitting, setSubmitting] = useState<boolean>(false)

  const onUpdate = useCallback(
    async (formValues: TrafficAreaFormValues) => {
      try {
        setSubmitting(true)
        const area = computeTrafficRegionFromFormValues(formValues)
        if (trafficAreaSelection.length !== 1) {
          throw new Error('Traffic area selection has to have 1 element')
        }
        const id = trafficAreaSelection[0]
        const thunkResult = await dispatch(updateTrafficArea({ area, id, recurrence }))

        if (updateTrafficArea.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, toast, trafficAreaSelection, recurrence],
  )

  const onCreate = useCallback(
    async (formValues: TrafficAreaFormValues) => {
      try {
        setSubmitting(true)
        const area = computeTrafficRegionFromFormValues(formValues)
        const thunkResult = await dispatch(addTrafficArea({ area, recurrence }))

        if (!addTrafficArea.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        setTrafficAreaSelection([thunkResult.payload])

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, toast, recurrence, setTrafficAreaSelection],
  )

  return {
    submitting,
    onUpdate,
    onCreate,
  }
}

function computeTrafficRegionFromFormValues(
  formValues: TrafficAreaFormValues,
): uui.domain.server.rm.TrafficRegion {
  const { name, firstTimeWindowValue, secondTimeWindowValue, level, points } = formValues

  const timeWindowLevels: number[] = []
  if (firstTimeWindowValue !== undefined) {
    timeWindowLevels.push(parseValueFromSliderValue(firstTimeWindowValue))
  }
  if (secondTimeWindowValue !== undefined) {
    timeWindowLevels.push(parseValueFromSliderValue(secondTimeWindowValue))
  }

  return {
    level: parseValueFromSliderValue(level),
    name,
    poly: points.map(latLng => geo.latLngToJSON(latLng)),
    timeWindowLevels,
  }
}

function parseValueFromSliderValue(level: number) {
  return Math.abs(level) - 50
}
