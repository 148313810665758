import type { BreadcrumbData } from '../types'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  getBreadcrumbData,
  selectBreadcrumbTimeRange,
  reverseGeocodeBreadcrumbPoint,
} from '@/features/domain/breadcrumb'

import { selectLivePositions } from '@/features/domain/device'
import { selectCalendarRange } from '@/features/domain/ui'
import { useMainSelection } from '@/atoms'
import { useAppDispatch } from '@/store'

export function useBreadcrumbsData() {
  const [selection, setMainSelection] = useMainSelection('breadcrumbs')
  const breadcrumbTimeRange = useSelector(selectBreadcrumbTimeRange)
  const { minDate, maxDate } = useSelector(selectCalendarRange)
  const livePositions = useSelector(selectLivePositions)
  const dispatch = useAppDispatch()

  const [data, setData] = useState<BreadcrumbData>()

  const deviceId = selection.length !== 1 ? undefined : selection[0].split('@')[0]
  const deviceLivePosition = deviceId ? livePositions[deviceId] : undefined

  useEffect(() => {
    let effectCleared = false
    async function fetchData() {
      try {
        // Can't fetch data for more than one day
        if (minDate !== maxDate) return

        if (selection.length === 0) return

        if (selection.length > 1) {
          throw new Error('Cannot get data for more than one breadcrumbId')
        }

        // Fetch all the points related to the breadcrumb
        const response = await dispatch(
          getBreadcrumbData({ deviceId: selection[0].split('@')[0], date: minDate }),
        )

        if (getBreadcrumbData.rejected.match(response)) {
          throw new Error('Request failed')
        }

        if (effectCleared) return

        if (response.payload.breadcrumb.fetched) {
          const {
            breadcrumb: { points },
            indexesByBreadcrumbId,
          } = response.payload

          // Ensure that the selected point hasn't been filtered out
          const pointIndex = indexesByBreadcrumbId[selection[0]]

          // If the point no more exists let's reset the selection and bail-out
          if (pointIndex === undefined) {
            setMainSelection('reset')
            return
          }

          setData(response.payload)

          const selectedPoint = points[pointIndex]

          // If the point is already geocoded just bail-out
          if (selectedPoint.address) return

          // Geocode breadcrumb point
          const reverseGeocodeResponse = await dispatch(
            reverseGeocodeBreadcrumbPoint({ pointId: selection[0], date: minDate }),
          )

          if (effectCleared) return

          if (reverseGeocodeBreadcrumbPoint.rejected.match(reverseGeocodeResponse)) {
            throw new Error('Failed to reverse-geocode breadcrumb point')
          }

          fetchData()
        } else {
          setData(response.payload)
        }
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()

    return () => {
      effectCleared = true
    }
  }, [
    dispatch,
    setMainSelection,
    breadcrumbTimeRange,
    minDate,
    maxDate,
    deviceLivePosition,
    selection,
  ])

  return data
}
