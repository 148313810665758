import type { FormFields, FormErrors } from './formFields'

import { FormProvider } from '@/formUi'
import { FormColumn } from '@/components/layout'
import { useFormTransaction, useStopEditingOnUnmount } from '@/hooks'
import { useResetEditingState } from '@/atoms'

import { Form } from './components/Form'
import { useFormData } from './hooks/useFormData'
import { useCreateFormConfig } from './hooks/useCreateFormConfig'
import { formFields } from './formFields'

type Props = {
  orders: uui.domain.client.rm.ExtendedOrderStep[]
}

export function BulkEdit(props: Props) {
  const { orders } = props
  // access form initial values and a function update the data when the form is saved
  const [initialValues, updateOrders] = useFormData(orders)
  const createFormConfig = useCreateFormConfig(initialValues)

  const onDiscard = useResetEditingState()
  const transactionState = useFormTransaction()

  useStopEditingOnUnmount()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <FormProvider<FormFields, FormErrors>
        name="orders-bulk-edit-form"
        fields={formFields}
        config={createFormConfig}
        verbose={false}
      >
        <Form
          onDiscard={onDiscard}
          updateOrders={updateOrders}
          orders={orders}
          transactionState={transactionState}
        />
      </FormProvider>
    </FormColumn>
  )
}
