import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    delete: translate({ id: 'global.delete' }),
    edit: translate({ id: 'global.edit' }),
    subtitle: translate({ id: 'gps.alerts.view.delete.subtitle' }),
    title: translate({
      id: 'gps.alerts.view.delete.title',
      values: { selected: 1 },
    }),
  }))

  return api
}
