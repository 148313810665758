import { Tooltip } from '@/components/primitives/Tooltip'
import { Lasso, LassoPlus, LassoMinus } from '@/icons'
import { useMapSelectionMode } from '@/map'

import { useTexts } from '../useTexts'

export function TriggerIcon() {
  const [mapSelectionMode] = useMapSelectionMode()
  const texts = useTexts()

  switch (mapSelectionMode) {
    case 'normal':
      return (
        <Tooltip title={texts.lasso} placement="bottom">
          <Lasso size={18} color="$nightRider" />
        </Tooltip>
      )
    case 'lasso':
      return (
        <Tooltip title={texts.addToSelection} subtitle={texts.quitLasso} placement="bottom">
          <Lasso size={18} color="$scienceBlue" />
        </Tooltip>
      )
    case 'lassoAdditive':
      return (
        <Tooltip title={texts.addToSelection} subtitle={texts.quitLasso} placement="bottom">
          <LassoPlus size={18} color="$scienceBlue" />
        </Tooltip>
      )
    case 'lassoSubtractive':
      return (
        <Tooltip title={texts.removeFromSelection} subtitle={texts.quitLasso} placement="bottom">
          <LassoMinus size={18} color="$scienceBlue" />
        </Tooltip>
      )
  }
}
