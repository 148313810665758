import type {
  FormField,
  FormSingleField,
  NarrowFieldsByValueType,
} from '@workwave-tidal/tidal/form-fairy'
import type { BulkFieldValue } from '../../../../types'
import type { BulkTimeWindowsError } from '../types'

import { useCallback, useRef, useEffect } from 'react'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'

type RequiredFormField = BulkFieldValue<[uui.domain.rm.TimeWindow?, uui.domain.rm.TimeWindow?]>

export function useActions<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends BulkTimeWindowsError = BulkTimeWindowsError,
>(name: FIELD_NAME) {
  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const {
    field: { value },
    fieldApi: { change: apiChange, validate },
  } = useFormField<FIELD_NAME, PartialForm, ERROR>(name)

  const fieldValueRef = useRef(value)
  useEffect(() => {
    fieldValueRef.current = value
  }, [value])

  const onDeleteAll = useCallback(() => {
    apiChange({ status: 'exact', value: [] })
    validate()
  }, [apiChange, validate])

  const onAddTimeWindow = useCallback(() => {
    if (fieldValueRef.current.status === 'mixed') {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('Cannot add a time window on a mixed field')
      }
      return
    }

    const newTimeWindow = { startSec: 9 * 3600, endSec: 18 * 3600 } as uui.domain.rm.TimeWindow

    switch (fieldValueRef.current.value.length) {
      case 0:
        apiChange({ status: 'exact', value: [newTimeWindow] })
        validate()
        break

      case 1:
        apiChange({ status: 'exact', value: [fieldValueRef.current.value[0], newTimeWindow] })
        validate()
        break

      case 2:
        if (process.env.NODE_ENV === 'development') {
          throw new Error('Cannot add a new time window, time windows count limit is 2')
        }
        break
    }
  }, [apiChange, validate])

  const onUpdateTimeWindow = useCallback(
    (timeWindow: uui.domain.rm.TimeWindow, timeWindowIndex: 0 | 1) => {
      if (fieldValueRef.current.status === 'mixed') {
        if (process.env.NODE_ENV === 'development') {
          throw new Error('Cannot update a time window on a mixed field')
        }
        return
      }

      const timeWindowTarget = fieldValueRef.current.value[timeWindowIndex]
      if (!timeWindowTarget) {
        if (process.env.NODE_ENV === 'development') {
          throw new Error('Cannot find the time window to update')
        }
        return
      }

      switch (timeWindowIndex) {
        case 0:
          const hasSecondValue = !!fieldValueRef.current.value[1]

          apiChange({
            status: 'exact',
            value: hasSecondValue ? [timeWindow, fieldValueRef.current.value[1]] : [timeWindow],
          })
          validate()
          break

        case 1:
          if (!fieldValueRef.current.value[0]) {
            if (process.env.NODE_ENV === 'development') {
              throw new Error('Cannot find the time window')
            }
            break
          }

          apiChange({ status: 'exact', value: [fieldValueRef.current.value[0], timeWindow] })
          validate()
          break
      }
    },
    [apiChange, validate],
  )

  const onRemoveTimeWindow = useCallback(
    (timeWindowIndex: 0 | 1) => {
      if (fieldValueRef.current.status === 'mixed') {
        if (process.env.NODE_ENV === 'development') {
          throw new Error('Cannot update a time window on a mixed field')
        }
        return
      }

      const value = [...fieldValueRef.current.value] as [
        uui.domain.rm.TimeWindow?,
        uui.domain.rm.TimeWindow?,
      ]
      value.splice(timeWindowIndex, 1)
      apiChange({ status: 'exact', value })
      validate()
    },
    [apiChange, validate],
  )

  return {
    onDeleteAll,
    onAddTimeWindow,
    onUpdateTimeWindow,
    onRemoveTimeWindow,
  }
}
