import { Text } from './Text'
import { Props } from './typings'

export function H3(props: Props) {
  const { children = '', as, className = '', style, size, weight, ...p } = props

  return (
    <Text size="$h3" weight="$semiBold" as={'h3'} style={style} className={className} {...p}>
      {children}
    </Text>
  )
}
