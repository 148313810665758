import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function LightBulb(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 15 20">
        <path d="M7.52976.15238C4.0067.24911 1.04911 3.09137.95238 6.52143c-.09672 2.28423.96354 4.38616 2.67857 5.625.76265.57292 1.13095 1.43601 1.13095 2.29167h5.7143c0-.85566.37573-1.71875 1.04166-2.29167 1.71503-1.14211 2.76785-3.13616 2.76785-5.32738 0-3.7128-3.04315-6.7634-6.75595-6.66667zM4.7619 15.39048v.95238c0 1.11235.59524 1.59226 1.4881 1.78571.17113.59524.73289 1.07143 1.36905 1.07143.63616 0 1.19791-.4762 1.36905-1.07143.89285-.19345 1.4881-.67336 1.4881-1.78571v-.95238H4.7619z" />
      </svg>
    </IconContainer>
  )
}
