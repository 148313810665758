import styled from 'styled-components'

export const InputRangeScaleButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  position: absolute;
  top: 0;
  width: 50px;
  height: 15px;
  line-height: 15px;
  text-align: center;

  font-size: ${p => p.theme.fonts.$s};
  color: ${p => p.theme.colors.$shadyLady};
  background: transparent;

  margin: 0;
  padding: 0;
  outline: 0;
  border-width: 0;

  transition: background 0.3s ease;
  transform: translateX(-50%);

  &:hover {
    background: ${p => p.theme.colors.$whiteSmoke};
  }
`

InputRangeScaleButton.displayName = 'InputRangeScaleButton'
