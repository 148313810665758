import type { CSSProperties } from 'react'
import type { FormFields, FormErrors } from '../formFields'

import { Divider } from '@mui/material'

import { useFormMeta } from '@workwave-tidal/tidal/form-fairy'
import { FormContainer, FormContent } from '@/formUi'
import { FormLoading } from '@/components/FormLoading'

import { useOnSubmit } from '../hooks/useOnSubmit/useOnSubmit'
import { FieldsContainer } from './FieldsContainer'
import { FormFooter } from './FormFooter'

import { Notes } from './fields/Notes'
import { RequiredVehicle } from './fields/RequiredVehicle'
import { Priority } from './fields/Priority'
import { ServiceTime } from './fields/ServiceTime'
import { Tags } from './fields/Tags'
import { Loads } from './fields/Loads'
import { TimeWindows } from './fields/TimeWindows'
import { Eligibility } from './fields/Eligibility'
import { Email } from './fields/Email'
import { Phone } from './fields/Phone'
import { Company } from './fields/Company'

interface Props {
  onDiscard: () => void
  updateOrders: (data: uui.domain.ui.forms.BulkOrderUpdate) => Promise<boolean>
  transactionState: 'ready' | 'loading'
  orders: uui.domain.client.rm.ExtendedOrderStep[]
}

const formStyles: CSSProperties = {
  display: 'block',
  height: '100%',
  width: '100%',
  overflowY: 'hidden',
}

export function Form(props: Props) {
  const { onDiscard, updateOrders, orders, transactionState } = props

  // const texts = useTexts()

  const {
    meta: { submitting, status },
  } = useFormMeta<FormFields, FormErrors>()

  // create a submit function linked to
  // - the domain update function
  // - the form API
  // - the application editing status
  const onSubmit = useOnSubmit(updateOrders, onDiscard, orders)

  return (
    // ATTENTION: The custom style attached to the `form` is required until we fix the dynamic scrollable content.
    // see: https://workwave.atlassian.net/browse/RM-11796
    <form style={formStyles} onSubmit={onSubmit}>
      <FormContainer width={484} testid="orders-bulk-form">
        <FormContent
          footer={
            <FormFooter
              onDiscard={onDiscard}
              submitting={submitting}
              pristine={status === 'pristine'}
              hidden={transactionState === 'loading'}
              ordersCount={orders.length}
              testId="orders-bulk-form_footer"
            />
          }
        >
          {transactionState === 'loading' ? (
            <FormLoading />
          ) : (
            <FieldsContainer>
              <Company />
              <Eligibility />
              <Loads orders={orders} />
              <RequiredVehicle />
              <Priority />
              <Phone />
              <Email />
              <ServiceTime />
              <Divider />
              <TimeWindows />
              <Tags />
              <Notes />
            </FieldsContainer>
          )}
        </FormContent>
      </FormContainer>
    </form>
  )
}
