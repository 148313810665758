import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { selectDrivers } from '@/features/domain/driver'

import { getMatchingRecurrenceByDate } from '@/server-data'

export function useData(routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable) {
  const { dateAsString } = routeInfo

  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const extVehicles = useSelector(selectVehicles)
  const drivers = useSelector(selectDrivers)

  const uvId = vehiclesByVehicleId[routeInfo.vehicleId]
  const uv = extVehicles[uvId]

  const extDriver = useMemo(() => {
    if (!uv.hasRoutingLicense) return

    const matchingRecurrence = getMatchingRecurrenceByDate(
      dateAsString,
      Object.keys(uv.driversByRecurrence),
    )

    const driverAndDeviceId = uv.driversByRecurrence[matchingRecurrence]
    if (!driverAndDeviceId.associated) return

    return drivers[driverAndDeviceId.driverId]
  }, [drivers, dateAsString, uv])

  return {
    extDriver,
    vehicleName: uv.hasRoutingLicense ? uv.vehicle.externalId : '',
    idx: uv.hasRoutingLicense
      ? uv.vehicle.idx !== -1
        ? uv.vehicle.idx
        : uv.vehicle.externalId
      : '',
  }
}
