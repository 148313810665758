import type { BulkTags } from '../../../types'

export function computeTags(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
  tagType: 'in' | 'out',
  maxCount: number,
): BulkTags {
  const bulkTags: BulkTags['tags'] = {}
  const tagsStats: Record<string, number> = {}

  // count all the tags, {maxCount} tags per order are allowed
  const exactTags = new Set<string>()

  for (const order of orders) {
    const tags = tagType === 'in' ? order.orderStep.tagsIn : order.orderStep.tagsOut

    for (const tag of tags) {
      tagsStats[tag] = tagsStats[tag] ?? 0
      tagsStats[tag]++
    }

    const ordersCount = orders.length

    for (const [tag, count] of Object.entries(tagsStats)) {
      const isExact = count === ordersCount

      if (isExact) {
        exactTags.add(tag)
      }

      bulkTags[tag] =
        count === ordersCount
          ? {
              name: tag,
              status: 'exact',
              // a dedicated function will update the warning prop
              // warning cannot be calculated without know both bulkTags collections
              warning: false,
            }
          : {
              name: tag,
              status: 'partial',
              // a dedicated function will update the warning prop
              // warning cannot be calculated without know both bulkTags collections
              warning: false,
              count,
            }
    }
  }

  return {
    tags: bulkTags,
    remaining: maxCount - exactTags.size,
    affectedOrdersCount: orders.length,
  }
}
