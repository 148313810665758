import styled from 'styled-components'

import { applyAlphaToColor } from '@/local/components'

export const InputRangeBadge = styled.div`
  position: absolute;
  top: 0;
  width: 50px;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
  text-align: center;

  font-size: ${p => p.theme.fonts.$p3};
  color: ${p => p.theme.colors.$pureWhite};
  background: ${_ => applyAlphaToColor('#000', 0.8)};

  margin: 0;
  padding: 0;
  outline: 0;

  transform: translate(-50%, -100%);

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 3px 0 3px;
    border-color: ${_ => applyAlphaToColor('#000', 0.8)} transparent transparent transparent;
    transform: translateX(-4px);
  }
`

InputRangeBadge.displayName = 'InputRangeBadge'
