import { PureComponent } from 'react'

import {
  SecondaryColumnHeader,
  SecondaryColumnContentHorizontalRuler,
  ConfirmPanel,
} from '@/forms-legacy'
import { FormColumn } from '@/components/layout'

import * as Texts from '../../intl'

import SimulationAvatar from '../../components/SimulationAvatar'
import DetailRow from '../../components/DetailRow'

import BulkViewActions from './BulkViewActions'
import { Props } from './typings'

interface SimulationData {
  ordersCount: number
  vehiclesCount: number
}

const getSimulationsData = (simulations: uui.domain.client.rm.SimulationInfo[]) =>
  simulations.reduce<SimulationData>(
    (acc, simulation) => {
      const { vehiclesCount, ordersCount } = simulation
      acc.ordersCount += ordersCount
      acc.vehiclesCount += vehiclesCount
      return acc
    },
    {
      ordersCount: 0,
      vehiclesCount: 0,
    },
  )

interface State {
  showConfirmPanel: boolean
}

const setShowConfirmPanel = (showConfirmPanel: boolean) => () => ({
  showConfirmPanel,
})

export class BulkView extends PureComponent<Props, State> {
  state: State = {
    showConfirmPanel: false,
  }

  private handleOnShowConfirmPanel = () => {
    this.setState(setShowConfirmPanel(true))
  }

  private handleOnHideConfirmPanel = () => {
    this.setState(setShowConfirmPanel(false))
  }

  private handleOnDelete = () => {
    const { simulations, deleteSimulations } = this.props
    const ids = simulations.map(s => s.id)
    deleteSimulations(ids)
    this.handleOnHideConfirmPanel()
  }

  private handleAction = () => {
    const { resetSelection } = this.props
    resetSelection()
  }

  render() {
    const { simulations } = this.props

    const { showConfirmPanel } = this.state

    const { vehiclesCount, ordersCount } = getSimulationsData(simulations)
    const simulationsCount: number = simulations.length

    return (
      <FormColumn width={484} testid="form__column-secondary">
        <SecondaryColumnHeader
          title={Texts.getSimulationsCountText(simulationsCount)}
          description={''}
          action={this.handleAction}
          avatar={<SimulationAvatar simulationsCount={simulationsCount} />}
          customDescription={
            <DetailRow vehiclesCount={vehiclesCount} ordersCount={ordersCount} selected={false} />
          }
        />
        {!showConfirmPanel && (
          <BulkViewActions {...this.props} onShowConfirmPanel={this.handleOnShowConfirmPanel} />
        )}
        {showConfirmPanel && (
          <ConfirmPanel
            title={Texts.getSimulationsDeleteConfirmTitle(simulationsCount)}
            description={Texts.getSimulationsDeleteConfirmDescription()}
            onCancel={this.handleOnHideConfirmPanel}
            confirmationText={Texts.getSimulationsDeleteConfirmButtonText()}
            onConfirm={this.handleOnDelete}
          />
        )}
        <div style={{ marginLeft: 30, marginRight: 30 }}>
          <SecondaryColumnContentHorizontalRuler bottomSpace />
        </div>
      </FormColumn>
    )
  }
}
