import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    travelledDistance: translate({ id: 'rm.contextual.route.travelledDistance' }),
    approvedLength: translate({ id: 'rm.contextual.route.approvedLength' }),
    distanceLeft: translate({ id: 'rm.contextual.route.distanceLeft' }),
    plannedStart: translate({ id: 'rm.contextual.route.plannedStart' }),
    plannedEnd: translate({ id: 'rm.contextual.route.plannedEnd' }),
    notSet: translate({ id: 'global.notSet' }),
  }))

  return api
}
