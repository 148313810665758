import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function AddRounded(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 26 26">
        <path d="M22.0598 3.9402c-5.00222-5.00223-13.11738-5.00223-18.1196 0-5.00223 5.00222-5.00223 13.11738 0 18.1196 5.00222 5.00223 13.11738 5.00223 18.1196 0 5.00223-5.00222 5.00223-13.11738 0-18.1196zm-8.07924 16.25516l-1.96388-.00276c-.35908 0-.65186-.29278-.65186-.65186v-4.90556H6.45926c-.35908 0-.65186-.29278-.6491-.65462l-.00552-1.96112c.00552-.35908.29554-.6491.65462-.65462h4.90556V6.4648c.00276-.36185.29554-.65464.65462-.65464h1.96112c.36184-.00276.6574.2928.65462.65463v4.9l4.9028.00276c.36184-.00276.65738.29278.65186.65186l.00552 1.96112c-.00276.36184-.29554.65462-.65738.6574l-4.9028-.00278v4.90556c-.00552.35908-.29554.6491-.65462.65462z" />
      </svg>
    </IconContainer>
  )
}
