import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    searchPlaceholder: translate({ id: 'vehicles.form.filterVehiclesPlaceholder' }),
    yourVehicles: translate({ id: 'vehicles.form.list.yourVehicles' }),
    createNew: translate({ id: 'vehicles.form.createNew' }),

    deselectAll: translate({
      id: 'global.deselectAll',
    }),
    selectAll: translate({
      id: 'global.selectAll',
    }),
    hideAll: translate({
      id: 'global.hideAll',
    }),
    showAll: translate({
      id: 'global.showAll',
    }),

    selectBtn: (allSelected: boolean): string =>
      translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' }),

    disabledTooltipTitle: (isSimulation: boolean) => {
      const id = isSimulation
        ? 'vehicles.form.createNew.disabled.simulations.title'
        : 'vehicles.form.createNew.disabled.operations.title'
      return translate({ id })
    },

    disabledForLoadingTooltipTitle: translate({
      id: 'global.gpsLoading.disableEdit',
    }),

    disabledTooltipSubtitle: (isSimulation: boolean, count: number) => {
      const id = isSimulation
        ? 'vehicles.form.createNew.disabled.simulations.subtitle'
        : 'vehicles.form.createNew.disabled.operations.subtitle'
      return translate({ id, values: { count } })
    },
    filterDefault: translate({ id: 'vehicles.form.filter.default' }),
    filterRmOnly: translate({ id: 'vehicles.form.filter.rmOnly' }),
    filterGpsOnly: translate({ id: 'vehicles.form.filter.gpsOnly' }),

    listHeader: {
      title: translate({ id: 'vehicles.list.header.title' }),
      filterPlaceholder: translate({ id: 'vehicles.form.filterVehiclesPlaceholder' }),
    },
    sortBy: translate({ id: 'vehicles.list.header.sortBy' }),
    sortByNameButton: translate({ id: 'vehicles.list.header.sortBy.name' }),
    sortByIdButton: translate({ id: 'vehicles.list.header.sortBy.id' }),
    sortByAscendingButton: translate({ id: 'vehicles.list.header.sortBy.ascending' }),
    sortByDescendingButton: translate({ id: 'vehicles.list.header.sortBy.descending' }),

    noEntries: {
      title: translate({ id: 'vehicles.list.noEntries.title' }),
      description: translate({ id: 'vehicles.list.noEntries.description' }),
      action: translate({ id: 'vehicles.list.noEntries.action' }),
    },
    noResults: {
      title: translate({ id: 'vehicles.list.noResults.title' }),
      description: translate({ id: 'vehicles.list.noResults.description' }),
    },
  }))

  return api
}
