import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { useCallback, useState } from 'react'
import { Tooltip } from '@/components/primitives/Tooltip'
import { MenuOption } from '../ViewUser/hooks/useMenuOptions'
import { useMenuOptions } from './hooks/useMenuOptions'
import { useTexts } from './hooks/useTexts'

export function ViewUsersMenu() {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const options = useMenuOptions()
  const texts = useTexts()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = useCallback((option: MenuOption) => {
    if (option.action) {
      option.action()
    }

    setAnchorEl(null)
  }, [])

  return (
    <>
      <Tooltip placement="bottom" title={texts.tooltips.more}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map(option => (
          <MenuItem key={option.id} onClick={() => handleClose(option)}>
            <Typography color={option.color}>{option.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
