import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectPlaces } from '@/features/domain/place'
import { locationUtils } from '@/utils'
import { getMapInfo, useInspectPin } from '@/map'

export function useNewPlaceValue() {
  const places = useSelector(selectPlaces)
  const { center: mapCenter } = getMapInfo()

  const [inspectPin] = useInspectPin()

  const initialLatLngFromMapPin =
    inspectPin?.mode === 'converted' && inspectPin.target === 'place'
      ? inspectPin.latLng
      : undefined

  const center = initialLatLngFromMapPin ?? mapCenter

  return useMemo<uui.domain.client.gps.wwgps.Place>(() => {
    const name = computeUniqueName(Object.values(places), 'New place')
    const location = locationUtils.createEmptyLocation({ latLng: center })

    return {
      id: '-1',
      name,
      location,
      latLng: location.latLng,
      latitude: location.latLng.lat,
      longitude: location.latLng.lng,
    }
  }, [places, center])
}

// create a map where keys are the segment names
// the values are the number of the occurrences
function createMapOfNames(places: uui.domain.client.gps.wwgps.Place[]): Record<string, number> {
  const map: Record<string, number> = {}
  for (const { name } of places) {
    map[name] = map[name] ? map[name] + 1 : 1
  }
  return map
}

// assign a unique name to the place to be imported
// by adding a suffix with the number of that name occur in the regions
function computeUniqueName(places: uui.domain.client.gps.wwgps.Place[], idealName: string) {
  const namesMap = createMapOfNames(places)

  if (namesMap[idealName]) {
    return `${idealName} (${namesMap[idealName]})`
  }

  return idealName
}
