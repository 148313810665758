import type { NotificationFormValues } from '../../../../../../../notificationForms/sections/notifications/components/NotificationForm'

import { useCallback } from 'react'

import { updateEarlyNotificationConfig } from '@/features/domain/territory'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { convertValues } from '../convertValues'

/**
 * That hook returns a submit method for EarlyNotification Form
 */
export function useEarlyNotificationSubmit() {
  const dispatch = useAppDispatch()
  const toast = useNotification()

  return useCallback(
    async (formValues: NotificationFormValues) => {
      try {
        const result = await dispatch(updateEarlyNotificationConfig(convertValues(formValues)))

        if (!updateEarlyNotificationConfig.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        return true
      } catch (error) {
        toast.error(error.message)

        return false
      }
    },
    [dispatch, toast],
  )
}
