import type { TestValues } from '../types'
import type { NotificationFormType } from '@/notificationForms'

import { useState } from 'react'

import { useConfigureModalController, useModalController, useCloseModal } from '@/atoms'

type ModalState = 'ready' | 'submitting' | 'success' | 'error'
interface ModalData {
  notification: NotificationFormType
  values: TestValues
}

export const modalId = 'testPhoneNotification'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = (notification: NotificationFormType, values: TestValues) => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        notification,
        values,
      },
    }),
  }))

  return useConfigureModalController<ModalData, ModalState>(modalId, options)
}
