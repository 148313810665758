import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { produce } from 'immer'

import { useAppDispatch } from '@/store'
import { setListOptions } from '@/features/domain/lists/actions'

import { selectUsersListOptions } from '@/features/domain/lists'

export const allUserTypes = ['viewer', 'planner', 'guest', 'courier'] as const
type FilterableUserTypes = (typeof allUserTypes)[number]

type UserFilterStatus = {
  type: 'all' | FilterableUserTypes
}

export function useFilterList() {
  const dispatch = useAppDispatch()
  const listOptions = useSelector(selectUsersListOptions)

  const setFilter = useCallback(
    async (filter: { type: 'all' | FilterableUserTypes }) => {
      const nextOptions = produce(listOptions, draft => {
        draft.filter =
          filter.type === 'all'
            ? []
            : [
                {
                  field: 'type',
                  values: [filter.type],
                  operator: 'or',
                },
              ]
      })

      // avoid triggering any calculations in case the group didn't change
      if (nextOptions === listOptions) return

      const request = await dispatch(setListOptions({ category: 'users', options: nextOptions }))
      return setListOptions.fulfilled.match(request)
    },
    [dispatch, listOptions],
  )

  return {
    filter: listOptions.filter.reduce<UserFilterStatus>(
      (acc, { values }, index) => {
        switch (index) {
          case 0:
            acc.type = values.length === 1 ? (values[0] as FilterableUserTypes) : 'all'
            break
        }

        return acc
      },
      { type: 'all' } as UserFilterStatus,
    ),
    setFilter,
  }
}
