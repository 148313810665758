import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { proxy, useSnapshot } from 'valtio'

import { selectDriverAssignments } from '@/features/domain/driverAssignments'
import { useCrudSelection } from './selection/crud'

interface EditMode {
  editMode: 'create' | 'edit'
}
interface DriverAssignmentEditModeAtom {
  editMode: EditMode
}

// ------------------------------------
// Default values
// ------------------------------------
function getInitialEditMode() {
  return { editMode: 'edit' } as EditMode
}

// ------------------------------------
// Exception settings atom
// ------------------------------------

const createDefaultEditMode = (): DriverAssignmentEditModeAtom => ({
  editMode: getInitialEditMode(),
})

export const driverAssignmentEditModeAtom = proxy<DriverAssignmentEditModeAtom>(
  createDefaultEditMode(),
)

// ------------------------------------
// Write functions
// ------------------------------------

type SetEditMode = (prev: EditMode) => EditMode
type SetEditModeParam = SetEditMode | Partial<EditMode> | 'reset'

export function resetDriverAssignmentEditMode() {
  driverAssignmentEditModeAtom.editMode = getInitialEditMode()
}

export function setDriverAssignmentEditMode(valueOrFunc: SetEditModeParam) {
  if (valueOrFunc === 'reset') resetDriverAssignmentEditMode()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(
      driverAssignmentEditModeAtom.editMode,
      valueOrFunc(driverAssignmentEditModeAtom.editMode),
    )
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      driverAssignmentEditModeAtom.editMode[field] = valueOrFunc[field]
    }
  }

  return driverAssignmentEditModeAtom
}

// ------------------------------------
// React Hooks
// ------------------------------------

export function useDriverAssignmentEditMode() {
  return [
    useSnapshot(driverAssignmentEditModeAtom).editMode.editMode,
    setDriverAssignmentEditMode,
  ] as const
}

/**
 * That hook ensures that the exception selection will be reset if the exception is not more
 * available in the global state or if the driver assignment selection changes
 */
export function useResetDriverAssignmentEditMode() {
  const [selectedDriverAssignment] = useCrudSelection('driverAssignments')
  const driverAssignments = useSelector(selectDriverAssignments)
  const firstRun = useRef(true)

  // If the selection changes reset the atom
  useEffect(() => {
    // ------------------------------------------------------------
    // !!! That effect should not be executed at the first load !!!
    // ------------------------------------------------------------
    if (firstRun.current) {
      firstRun.current = false
      return
    }
    setDriverAssignmentEditMode('reset')
  }, [selectedDriverAssignment])

  // If the exception is no more available reset the atom
  useEffect(() => {
    // ------------------------------------------------------------
    // !!! That effect should not be executed at the first load !!!
    // ------------------------------------------------------------
    if (firstRun.current) {
      firstRun.current = false
      return
    }

    const selectedDriverAssignmentsExists = !!driverAssignments[selectedDriverAssignment[0]]

    if (selectedDriverAssignmentsExists) return

    setDriverAssignmentEditMode('reset')
  }, [driverAssignments, selectedDriverAssignment])
}
