import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../../typings'

/**
 * Refreshes GPS Data
 *
 * @private
 */
export const editTenantSourceCredentials = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  { tenantSourceId: string; credentials: uui.domain.server.gps.telematics.Credential[] },
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/gps/telematics/editTenantSourceCredentials', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/gps/telematics/editTenantSourceCredentials', {
      category: 'rpc',
      payload,
      type: 'rpc/gps/telematics/editTenantSourceCredentials',
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {editTenantSourceCredentials}`,
      { tags: ['rpc'] },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Creation of Telematics tenant source credentials failed`,
      error,
    })
  }
})
