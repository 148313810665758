import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'

type BreadcrumbFeatureMetadata = {
  mode: 'on' | 'off' | 'maximized'
  type: 'breadcrumbPoints' | 'breadcrumbStops' | 'breadcrumbPath'
  ids: Record<string, true>
}

export const setBreadcrumbFeatureMetadata = <Field extends keyof BreadcrumbFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
  value: BreadcrumbFeatureMetadata[Field],
) => {
  target.set(field, value)
}

export const getBreadcrumbFeatureMetadata = <Field extends keyof BreadcrumbFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
): BreadcrumbFeatureMetadata[Field] | undefined => {
  return target.get(field)
}

export const removeBreadcrumbFeatureMetadata = <Field extends keyof BreadcrumbFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
) => {
  target.unset(field)
}
