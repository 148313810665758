import { BulkSelect } from '@/components/BulkSelect'

import { type FormFields, type FormErrors } from '../../../formFields'

import { useTexts } from './useTexts'
import { useOptions } from './useOptions'

export function RequiredVehicle() {
  const texts = useTexts()
  const options = useOptions()

  return (
    <BulkSelect<'forceVehicleId', string, FormFields, FormErrors>
      name="forceVehicleId"
      mixedLabel={texts.mixed}
      options={options}
      label={texts.requiredVehicle}
      testId="forceVehicleId"
    />
  )
}
