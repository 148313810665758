import { IconButton, Tooltip } from '@mui/material'
import { Add } from '@mui/icons-material'

import { useTexts } from './useTexts'

interface Props {
  testId?: string
  disabled?: boolean
  onClick: () => void
}

export function AddTimeWindowAction(props: Props) {
  const { disabled, onClick, testId } = props
  const texts = useTexts()

  return (
    <Tooltip title={disabled ? texts.addTimeWindowDisabledTooltip : texts.addTimeWindowTooltip}>
      {/* A disabled element does not fire events. */}
      {/* Tooltip needs to listen to the child element's events to display the title. */}
      <span>
        <IconButton
          onClick={onClick}
          disabled={disabled}
          data-testid={testId}
          data-trackid={testId}
        >
          <Add />
        </IconButton>
      </span>
    </Tooltip>
  )
}
