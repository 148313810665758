import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    multipleValues: translate({ id: 'global.multipleValues' }),
    none: translate({ id: 'global.none' }),
    title: translate({ id: 'setup.regions.view.single.title' }),
    cost: translate({ id: 'setup.regions.view.single.additionaCost' }),
    time: translate({ id: 'setup.regions.view.single.additionalDrivingTime' }),
  }))

  return api
}
