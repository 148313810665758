import { styled } from '@mui/material'

export const IconBackground = styled('div', {
  name: 'IconBackground',
})({
  backgroundColor: 'white',
  justifyContent: 'center',
  borderRadius: 14.5,
  alignItems: 'center',
  position: 'relative',
  display: 'flex',
  height: 29,
  width: 29,
})
