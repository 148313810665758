import type {
  ExecutionEventMarker,
  PODMarker,
  ExecutionEventMarkerType,
} from './executionEventMarkers'

import { snapshot, subscribe } from 'valtio'
import { subscribeKey } from 'valtio/utils'

import { isDeepEqual } from '@/server-data'
import { addExecutionEventFeature } from '../../layers/executionEvents/features/executionEvent/addExecutionEventFeature'
import { updateExecutionEventFeature } from '../../layers/executionEvents/features/executionEvent/updateExecutionEventFeature'
import { removeExecutionEventFeature } from '../../layers/executionEvents/features/executionEvent/removeExecutionEventFeature'

import { addPodFeature } from '../../layers/executionEvents/features/pod/addPodFeature'
import { updatePodFeature } from '../../layers/executionEvents/features/pod/updatePodFeature'
import { removePodFeature } from '../../layers/executionEvents/features/pod/removePodFeature'

import { changeExecutionEventFeaturesVisibility } from '../../layers/executionEvents/features/changeExecutionEventFeaturesVisibility'

import { executionEventMarkers } from './executionEventMarkers'

export function connectMapToExecutionEvents() {
  // ---------------------------------------------------------------------
  // Execution Events
  // ---------------------------------------------------------------------

  const activeEvents = new Set<ExecutionEventMarkerType>()

  const prevEvents: ExecutionEventMarker[] | null = null
  const unsubscribeFromEvents = subscribe(executionEventMarkers, () => {
    const events = snapshot(executionEventMarkers).events

    if (isDeepEqual(events, prevEvents)) return

    const removedEvents = new Set(activeEvents)

    for (const event of Object.values(events)) {
      if (!event) continue

      if (activeEvents.has(event.type)) {
        //  update the marker
        updateExecutionEventFeature(event)
      } else {
        // create the marker
        addExecutionEventFeature(event)
      }

      removedEvents.delete(event.type)
      activeEvents.add(event.type)
    }

    for (const eventType of removedEvents) {
      activeEvents.delete(eventType)

      // try to remove the marker
      removeExecutionEventFeature(eventType)
    }
  })

  // ---------------------------------------------------------------------
  // POD
  // ---------------------------------------------------------------------

  let prevPod: workwave.DeepReadonly<PODMarker | null> = null
  const unsubscribeFromPod = subscribe(executionEventMarkers, () => {
    const pod = snapshot(executionEventMarkers).pod

    if (isDeepEqual(pod, prevPod)) return

    if (!prevPod && pod) {
      // add
      addPodFeature(pod)
    }

    if (prevPod && pod) {
      // update
      updatePodFeature(pod)
    }

    if (prevPod && !pod) {
      // remove
      removePodFeature(prevPod.type)
    }

    prevPod = pod
  })

  // ---------------------------------------------------------------------
  // Layer Visibility
  // ---------------------------------------------------------------------

  const unsubscribeFromVisible = subscribeKey(
    executionEventMarkers,
    'visible',
    (visible: boolean) => {
      changeExecutionEventFeaturesVisibility(visible)
    },
  )

  return () => {
    unsubscribeFromEvents()
    unsubscribeFromPod()
    unsubscribeFromVisible()
  }
}
