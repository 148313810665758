import { useState, useEffect, useCallback } from 'react'

import { Actions } from './Actions'
import { ConfirmPanel } from './ConfirmPanel'

interface Props {
  trafficArea: uui.domain.client.rm.TrafficRegion
}

export function ActionsManager(props: Props) {
  const { trafficArea } = props
  const [view, setView] = useState<'default' | 'delete'>(() => 'default')

  useEffect(() => {
    setView('default')
  }, [trafficArea])

  const onDelete = useCallback(() => {
    setView('delete')
  }, [setView])

  const onCancel = useCallback(() => {
    setView('default')
  }, [setView])

  switch (view) {
    case 'delete':
      return <ConfirmPanel trafficArea={trafficArea} onCancel={onCancel} />
    case 'default':
      return <Actions trafficArea={trafficArea} onDelete={onDelete} />
  }
}
