import { CircularProgress } from '@mui/material'

import { VerticalLayout, HorizontalLayout, Grow } from '@/components/layout'
import { Polyline, PolylineHide } from '@/icons'
import { Text } from '@/local/components'

import { NavigoIconButton } from '../../../../../../../../elements/NavigoIconButton'

import { ButtonsContainer } from '../ButtonsContainer'
import { useTexts } from '../useTexts'

import { useActiveRoutePathMapStyle } from './hooks/useActiveRoutePathMapStyle'
import { useRoutePathActions } from './hooks/useRoutePathActions'

interface Props {
  routeIds: string[]
}

export function RoutePathStyler(props: Props) {
  const { routeIds } = props

  const { methodInExecution, actions } = useRoutePathActions(routeIds)
  const activeRoutePathMapStyle = useActiveRoutePathMapStyle(routeIds)
  const texts = useTexts()

  return (
    <Grow
      data-trackid="navigo-routes-mapStyles-routePathStyle"
      data-testid="navigo-routes-mapStyles-routePathStyle"
      xAxis
    >
      <HorizontalLayout alignItems="center">
        <VerticalLayout
          justifyContent="space-between"
          width="auto"
          marginRight={24}
          marginLeft={24}
        >
          <Text size="$p3" weight="$semiBold">
            {texts.polylineTitle}
          </Text>

          <Text size="$p3">{texts.polylineSubtitle}</Text>
        </VerticalLayout>

        <ButtonsContainer>
          <NavigoIconButton
            Icon={
              methodInExecution === 'showRoutePaths' ? (
                <CircularProgress size={21} color="primary" />
              ) : (
                <Polyline size={20} color="$nightRider" />
              )
            }
            testid="navigo-routes-mapStyles-routePathStyle-on"
            active={activeRoutePathMapStyle === 'on'}
            onClick={actions.showRoutePaths}
            text={texts.polylineShow}
          />

          <NavigoIconButton
            testid="navigo-routes-mapStyles-routePathStyle-off"
            Icon={
              methodInExecution === 'hideRoutePaths' ? (
                <CircularProgress size={21} color="primary" />
              ) : (
                <PolylineHide size={20} color="$nightRider" />
              )
            }
            active={activeRoutePathMapStyle === 'off'}
            onClick={actions.hideRoutePaths}
            text={texts.polylineHide}
          />
        </ButtonsContainer>
      </HorizontalLayout>
    </Grow>
  )
}
