import OlMap from 'ol/Map'

import { findLayer } from '../../layers/utils/findLayer'

import { getInteraction, registerInteraction, setInteractionMetadata } from '../interactionMetadata'
import { ModifyEditableRoadSegmentInteraction } from '../EditableRoadSegmentInteraction'

export const registerModifyEditableRoadSegmentInteraction = (map: OlMap) => {
  const uid = 'rm-modify-editable-roadSegment-interaction'

  if (getInteraction(uid)) return

  // -------------------------------------------
  // Initialize the Interaction

  const drawLayer = findLayer(map, 'draw')

  const modifyEditableRoadSegment = new ModifyEditableRoadSegmentInteraction(drawLayer, {
    // set the base style for the `draw` layer
    type: 'disabled',
  })

  // by default the Interaction is disabled
  modifyEditableRoadSegment.setActive(false)

  // -------------------------------------------
  // set Interaction Metadata

  setInteractionMetadata(modifyEditableRoadSegment, 'rmInteraction', true)
  setInteractionMetadata(modifyEditableRoadSegment, 'uid', uid)
  registerInteraction(uid, modifyEditableRoadSegment)

  // -------------------------------------------
  // Add the interaction to the map

  // TODO: is this redundant with `registerInteraction` ???
  map.addInteraction(modifyEditableRoadSegment)
}
