import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    runningOn: (count: number) => translate({ id: 'gps.alerts.runningOn', values: { count } }),
  }))

  return api
}
