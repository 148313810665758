import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'
import type { OptionalSelectionModifiers } from '@/atoms'

import { setCrudSelection } from '@/atoms'

import { getConnectedList } from '../../../../atoms/selection/read/getConnectedList'
import { getMapSelectionContext } from '../../../../atoms/selection/read/getMapSelectionContext'

export function clickRoadSegment(
  feature: Feature<Geometry>,
  modifiers: OptionalSelectionModifiers,
) {
  const connectedList = getConnectedList()
  const mapSelectionContext = getMapSelectionContext()

  const roadSegmentId = feature.getId()?.toString()

  if (!roadSegmentId) return

  const selection = [roadSegmentId]

  // select
  if (mapSelectionContext.type === 'crud') {
    if (mapSelectionContext.category === 'routingSegments') {
      setCrudSelection('routingSegments', selection, { modifiers })

      // try to scroll to
      if (
        connectedList.status === 'connected' &&
        connectedList.category === 'roadSegments' &&
        !connectedList.disabled
      ) {
        connectedList.scrollTo(roadSegmentId)
      }
    }
  }
}
