import type { OnSelectCallbackParams } from '@/components/StructuredVirtualList'

import { useCallback } from 'react'

import { fitMapToSelection } from '@/map'
import { useCrudSelection } from '@/atoms'

import { useDataForList } from './useDataForList'

export function useListHandlers() {
  const [selection] = useCrudSelection('deviceEvents')
  const { structure, deselectAll, setSelection } = useDataForList()

  const handleOnSelect = useCallback(
    (params: OnSelectCallbackParams<'eventInfos'>) => {
      const ids: string[] = []

      for (const id of params.newSelectedIds) {
        if (id.startsWith('group')) continue

        ids.push(id)
      }

      setSelection(ids)

      // try to select it right away and
      // queue a pending fitMap request
      // it will be applied by the destination view when it opens
      fitMapToSelection({ preventIfVisible: true }, 'add')
    },
    [setSelection],
  )

  const handleOnSelectAll = useCallback(() => {
    const allSelected = structure.shownItems <= selection.length

    const ids: string[] = []

    for (const listItem of structure.list) {
      if (typeof listItem === 'string') {
        ids.push(listItem)
      }
    }

    if (allSelected) {
      deselectAll()
    } else {
      setSelection(ids)

      // try to select it right away and
      // queue a pending fitMap request
      // it will be applied by the destination view when it opens
      fitMapToSelection({ preventIfVisible: true }, 'add')
    }
  }, [setSelection, selection, deselectAll, structure])

  return {
    handleOnSelect,
    handleOnSelectAll,
  }
}
