import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectTrafficProfiles } from '@/features/domain/traffic'
import { useCrudSelection } from '@/atoms'

export const useTrafficAreas = () => {
  const [selectedTrafficProfiles] = useCrudSelection('trafficProfile')
  const trafficProfiles = useSelector(selectTrafficProfiles)

  if (selectedTrafficProfiles.length !== 1) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('One traffic profile expected')
    }
  }

  const selectedTrafficProfile = selectedTrafficProfiles[0]

  return useMemo(() => {
    if (!selectedTrafficProfile) return {} as Record<string, uui.domain.client.rm.TrafficRegion>

    const trafficProfile = trafficProfiles[selectedTrafficProfile]
    const regions = trafficProfile.regions.reduce<
      Record<string, uui.domain.client.rm.TrafficRegion>
    >((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {})
    return regions
  }, [selectedTrafficProfile, trafficProfiles])
}
