import type { LoadReadonlyValue } from '../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { numberUtils } from '@/utils'
import { selectUserConfiguration } from '@/features/domain/user'

type LoadCapacities = Record<string, number>

export function useParseLoadsData(
  loadCapacities: LoadCapacities,
  minLoadCapacities: LoadCapacities,
) {
  const userConfig = useSelector(selectUserConfiguration)
  const metric = userConfig.distanceFormat === 'METRIC'

  return useMemo(() => {
    const decimalSeparator = numberUtils.getDecimalSeparator(metric)
    return Object.entries(loadCapacities).map<LoadReadonlyValue>(([name, value]) => {
      const result: LoadReadonlyValue = {
        name,
        value: numberUtils.fromNumberToString(value, decimalSeparator),
      }

      if (minLoadCapacities[name] !== undefined) {
        result.min = numberUtils.fromNumberToString(minLoadCapacities[name], decimalSeparator)
      }

      return result
    })
  }, [loadCapacities, minLoadCapacities, metric])
}
