import type { ExecutionEventMarker } from '../../../../atoms/executionEventMarkers/executionEventMarkers'

import { mapAtom } from '../../../../atoms/map/map'
import { findLayer } from '../../../utils/findLayer'

import { refreshExecutionEventFeature } from '../utils/refreshExecutionEventFeature'

export function updateExecutionEventFeature(
  eventMarker: workwave.DeepReadonly<ExecutionEventMarker>,
) {
  const map = mapAtom.map
  const layer = findLayer(map, 'executionEvents')
  const feature = layer.getSource()?.getFeatureById(eventMarker.type)

  if (!feature) {
    throw new Error('Feature not found (source is undefined)')
  }

  // update the pin
  refreshExecutionEventFeature(eventMarker, feature)

  return feature
}
