import type {
  SelectionAsRecord,
  MonthlyValidSelection,
  MonthlySelectionAsRecord,
} from '../../typings'

import { getDate } from 'date-fns/esm'

import { parseDate } from '../../utils/parseDate'

export const matchHighlightProducer = (selectionAsRecord: SelectionAsRecord) => {
  const keys = Object.keys(selectionAsRecord)
  const monthlySelection =
    keys.length > 0
      ? keys.reduce<MonthlySelectionAsRecord>((acc, curr) => {
          const dateDay = getDate(parseDate(curr)) as MonthlyValidSelection
          acc[dateDay] = 1
          return acc
        }, {} as MonthlySelectionAsRecord)
      : undefined

  return (date: MonthlyValidSelection): boolean => !!monthlySelection && !!monthlySelection[date]
}
