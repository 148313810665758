import { useEffect, useState } from 'react'
import { useAppDispatch } from '@/store'
import { fetchUsersList } from '@/features/domain/user'

type FetchStatus = 'ready' | 'failed' | 'fetching'

/**
 * Refresh the users list (the server doesn't notify the client when the users change).
 */
export const useFetchUsersListOnMount = () => {
  const dispatch = useAppDispatch()
  const [status, setStatus] = useState<FetchStatus>('ready')

  useEffect(() => {
    let effectCleaned = false

    const fetchUsers = async () => {
      try {
        if (effectCleaned) return

        setStatus('fetching')
        const response = await dispatch(fetchUsersList())

        if (effectCleaned) return

        setStatus(fetchUsersList.fulfilled.match(response) ? 'ready' : 'failed')
      } catch (e) {
        if (effectCleaned) return

        setStatus('failed')
      }
    }

    fetchUsers()

    return () => {
      effectCleaned = true
    }
  }, [dispatch])

  return status
}
