import { useCallback, useMemo } from 'react'
import { Divider, ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material'
import { Check } from '@mui/icons-material'

import { SortAscending, SortDescending } from '@/icons'
import { ListHeaderFilterMenuTitle, ListHeaderSorter } from '@/components/List'

import { useTexts } from '../../useTexts'
import { useSorter } from './hooks/useSorter'

export function Sorter() {
  const { field, setField, direction, editing, setDirection, noEntries, groupBy, setGroupBy } =
    useSorter()
  const texts = useTexts()

  const triggerText = useMemo(
    () => `${texts.sortBy(field === 'order.name' ? texts.sortByName : texts.sortByEta)}`,
    [texts, field],
  )

  const onSortByName = useCallback(() => {
    setField('order.name')
  }, [setField])

  const onSortByEta = useCallback(() => {
    setField('ui.sort.eta')
  }, [setField])

  const onSortAscending = useCallback(() => {
    setDirection('asc')
  }, [setDirection])

  const onSortDescending = useCallback(() => {
    setDirection('desc')
  }, [setDirection])

  const onGroupByNone = useCallback(() => {
    setGroupBy(undefined)
  }, [setGroupBy])

  const onGroupByRoute = useCallback(() => {
    setGroupBy('ui.sort.route')
  }, [setGroupBy])

  if (noEntries) return null

  return (
    <ListHeaderSorter triggerText={triggerText} disabled={editing}>
      <MenuItem
        onClick={onSortByName}
        data-testid="list-header-sorter-sortby-name"
        data-trackid="list-header-sorter-sortby-name"
      >
        {field === 'order.name' && (
          <ListItemIcon>
            <Check color="primary" />
          </ListItemIcon>
        )}
        <ListItemText inset={field !== 'order.name'} primary={texts.sortByName} />
      </MenuItem>

      <MenuItem
        onClick={onSortByEta}
        data-testid="list-header-sorter-sortby-eta"
        data-trackid="list-header-sorter-sortby-eta"
      >
        {field === 'ui.sort.eta' && (
          <ListItemIcon>
            <Check color="primary" />
          </ListItemIcon>
        )}
        <ListItemText inset={field !== 'ui.sort.eta'} primary={texts.sortByEta} />
      </MenuItem>

      <Divider />

      <MenuItem
        onClick={onSortAscending}
        data-testid="list-header-sorter-sortDirection-ascending"
        data-trackid="list-header-sorter-sortDirection-ascending"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          align-items="center"
          width="100%"
          gap={2}
        >
          <Stack direction="row">
            {direction === 'asc' && (
              <ListItemIcon>
                <Check color="primary" />
              </ListItemIcon>
            )}

            <ListItemText inset={direction !== 'asc'} primary={texts.sortOrderAscending} />
          </Stack>

          <Stack justifyContent="center" color="action.active">
            <SortAscending size={20} />
          </Stack>
        </Stack>
      </MenuItem>

      <MenuItem
        onClick={onSortDescending}
        data-testid="list-header-sorter-sortDirection-descending"
        data-trackid="list-header-sorter-sortDirection-descending"
      >
        <Stack
          justifyContent="space-between"
          align-items="center"
          direction="row"
          width="100%"
          gap={2}
        >
          <Stack direction="row" color="light">
            {direction === 'desc' && (
              <ListItemIcon>
                <Check color="primary" />
              </ListItemIcon>
            )}

            <ListItemText inset={direction !== 'desc'} primary={texts.sortOrderDescending} />
          </Stack>

          <Stack justifyContent="center" color="action.active">
            <SortDescending size={20} />
          </Stack>
        </Stack>
      </MenuItem>

      <Divider />
      <ListHeaderFilterMenuTitle>{texts.groupBy}</ListHeaderFilterMenuTitle>

      <MenuItem
        onClick={onGroupByNone}
        data-testid="list-header-sorter-groupby-route"
        data-trackid="list-header-sorter-groupby-route"
      >
        <Stack
          justifyContent="space-between"
          align-items="center"
          direction="row"
          width="100%"
          gap={2}
        >
          <Stack direction="row" color="light">
            {!groupBy && (
              <ListItemIcon>
                <Check color="primary" />
              </ListItemIcon>
            )}

            <ListItemText inset={!!groupBy} primary={texts.groupByNone} />
          </Stack>
        </Stack>
      </MenuItem>

      <MenuItem
        onClick={onGroupByRoute}
        data-testid="list-header-sorter-groupby-route"
        data-trackid="list-header-sorter-groupby-route"
      >
        <Stack
          justifyContent="space-between"
          align-items="center"
          direction="row"
          width="100%"
          gap={2}
        >
          <Stack direction="row" color="light">
            {groupBy === 'ui.sort.route' && (
              <ListItemIcon>
                <Check color="primary" />
              </ListItemIcon>
            )}

            <ListItemText inset={groupBy !== 'ui.sort.route'} primary={texts.groupByRoute} />
          </Stack>
        </Stack>
      </MenuItem>
    </ListHeaderSorter>
  )
}
