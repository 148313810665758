import type { LocationEditorProps } from '../typings'

import { produce } from 'immer'
import { useEffect } from 'react'

import { isDeepEqual } from '@/server-data'
import { getLocationPin, updateLocationPin } from '@/map'

export function useConnectFormFieldToLocationPin(options: LocationEditorProps) {
  const { pinId, pinRadius, hidden, pinInfo, connectedPlaceId, pinType } = options

  // Hidden state, controlled by the form
  useEffect(() => {
    const pin = getLocationPin(pinId, true)

    if (!pin) return
    if (typeof hidden !== 'boolean') return

    updateLocationPin(pinId, { hidden })
  }, [pinId, hidden])

  // Form Radius changed
  useEffect(() => {
    const pin = getLocationPin(pinId, true)

    if (!pin) return
    if (typeof pinRadius !== 'number') return

    if (pin.pin.radius !== pinRadius) {
      updateLocationPin(pinId, prevPin =>
        produce(prevPin, draft => {
          draft.pin.radius = pinRadius
        }),
      )
    }
  }, [pinId, pinRadius])

  // Pin Info changed
  useEffect(() => {
    const pin = getLocationPin(pinId, true)

    if (!pin) return
    if (typeof pinInfo !== 'object') return
    if (isDeepEqual(pin.info, pinInfo)) return

    updateLocationPin(pinId, { info: pinInfo })
  }, [pinId, pinInfo])

  // Connected Place ID changed
  useEffect(() => {
    const pin = getLocationPin(pinId, true)

    if (!pin) return

    // NOTE: Below you can find a type check that we were performing before https://github.com/WorkWave/route-manager/pull/826
    // The team didn't remember the motivation behind the check but preferred to track the ref with that comment.

    // if (typeof connectedPlaceId !== 'string') return

    updateLocationPin(pinId, prevPin =>
      produce(prevPin, draft => {
        draft.pin.type = connectedPlaceId ? 'depot' : pinType
      }),
    )
    // ATTENTION: pinType should be statically defined by the owner form
  }, [pinId, pinType, connectedPlaceId])
}
