import type { NavigoContext, NavigoEvents, NavigoStates } from './types'

import { createMachine } from 'xstate'

export const navigoPlaceMachine = createMachine<NavigoContext, NavigoEvents, NavigoStates>(
  {
    predictableActionArguments: true,
    initial: 'overview',

    context: {
      expanded: true,
    },

    states: {
      overview: {
        on: {
          GO_TO_MAP_STYLE: 'mapStyle',
          TOGGLE_EXPANDED: { actions: ['toggleExpanded'] },
        },
      },

      mapStyle: {
        on: { RESET: { target: 'overview', actions: 'expand' } },
      },
    },
  },
  {
    actions: {
      expand: mutableContext => {
        mutableContext.expanded = true
      },
      toggleExpanded: mutableContext => {
        mutableContext.expanded = !mutableContext.expanded
      },
    },
  },
)
