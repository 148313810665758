import styled from 'styled-components'
import Button from '../../../components/Button'

const styledComponent = styled(Button)`
  display: block;
  color: ${p => p.theme.colors.$pureWhite};
  background: ${p => p.theme.colors.$silver};
  font-size: ${p => p.theme.fonts.$m};
  border: none;
  border-radius: 6px;
  height: 100%;

  &:hover {
    background: ${p => p.theme.colors.$shadyLady};
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:disabled:hover {
    background: ${p => p.theme.colors.$shadyLady};
  }
`
export default styledComponent
