import { useMemo } from 'react'
import { RenderItemProps } from '@/components/StructuredVirtualList'
import { UserItem } from '../UserItem/UserItem'

export function useUsersListItem() {
  return useMemo(
    () => (props: RenderItemProps<'users'>) =>
      props.type === 'item' ? <UserItem {...props} /> : <></>,
    [],
  )
}
