import type Feature from 'ol/Feature'

import Point from 'ol/geom/Point'
import Circle from 'ol/geom/Circle'
import Geometry from 'ol/geom/Geometry'
import CircleStyle from 'ol/style/Circle'
import { Stroke, Style, Fill } from 'ol/style'

const blackStroke = new Stroke({ color: [0, 0, 0, 1] })
const blackFill = new Fill({ color: [0, 0, 0, 1] })

export const centerStyle = new Style({
  image: new CircleStyle({
    fill: blackFill,
    stroke: blackStroke,
    radius: 4,
  }),
  geometry: (feature: Feature<Geometry>) => {
    const geometry = feature?.getGeometry()

    if (geometry instanceof Geometry && geometry.getType() === 'Circle') {
      const circle = geometry as Circle
      return new Point(circle.getCenter())
    }
  },
})
