import { type SchedulerEventModel } from '@bryntum/schedulerpro'

import { durationUtils, timeUtils } from '@/server-data'
import { intl } from '@/intl'

import { Typography } from '../Typography'
import { Stack } from '../Stack'

import { useTooltipData } from './hooks/useTooltipData'
import { useTexts } from './useTexts'

interface Props {
  eventRecord: SchedulerEventModel
  userConfig: uui.domain.UserConfiguration
}

export function BreakStepTooltip(props: Props) {
  const { eventRecord, userConfig } = props
  const { start, durationSeconds, end } = useTooltipData(eventRecord)

  const texts = useTexts()

  return (
    <Stack spacing={0.75}>
      <Stack direction="row">
        <Typography variant="h6">{texts.title}</Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Stack flexGrow={1}>
          <Typography variant="body2">{texts.startAt}</Typography>
          <Typography variant="subtitle2">{timeUtils.formatTime(userConfig)(start)}</Typography>
        </Stack>

        <Stack flexGrow={1}>
          <Typography variant="body2">{texts.timeSpan}</Typography>
          <Typography variant="subtitle2">
            {durationUtils.formatMinutes(intl.translate, durationSeconds)}
          </Typography>
        </Stack>

        <Stack flexGrow={1}>
          <Typography variant="body2">{texts.endAt}</Typography>
          <Typography variant="subtitle2">{timeUtils.formatTime(userConfig)(end)}</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
