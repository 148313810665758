import { Box } from '@mui/material'

import { useSetupEditing } from '../../hooks/useSetupEditing'
import { useRefreshReasonsForNotCompletedOrders } from './hooks/useRefreshReasonsForNotCompletedOrders'
import { DriverActivityForm } from './components/DriverActivityForm'
import { DriverActivityView } from './components/DriverActivityView/DriverActivityView'

export function DriverActivity() {
  const { editing, onEdit, onDiscard } = useSetupEditing('driver-activity', 'reset')

  // Refresh reasons for orders from the BE to avoid the presence of old data
  useRefreshReasonsForNotCompletedOrders()

  return (
    <Box display="flex" width="100%" height="100%">
      {!editing && <DriverActivityView onEdit={onEdit} />}
      {editing && <DriverActivityForm onDiscard={onDiscard} />}
    </Box>
  )
}
