import { Component } from 'react'

import { Suitcase } from '@/icons'

import AvatarContainer from './AvatarContainer'

type Props = {
  simulationsCount: number
}

export default class SimulationAvatar extends Component<Props> {
  render() {
    const { simulationsCount } = this.props

    return (
      <AvatarContainer>
        {simulationsCount === 1 && <Suitcase size={16} />}
        {simulationsCount > 1 && simulationsCount}
      </AvatarContainer>
    )
  }
}
