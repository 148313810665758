import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    violations: translate({ id: 'rm.scheduler.popOver.order.violations' }),

    stepsViolations: (count: number) =>
      translate({ id: 'rm.scheduler.popOver.stepsViolationsRecap', values: { count } }),

    vehicleViolations: (count: number) =>
      translate({ id: 'rm.scheduler.popOver.vehicleViolationsRecap', values: { count } }),

    vehicleViolationDescription: (violation: uui.domain.rm.RouteViolationsType) => {
      switch (violation) {
        case 'LD':
          return translate({ id: 'rm.scheduler.violations.vehicle.maxLoad' })
        case 'TWS':
          return translate({ id: 'rm.scheduler.violations.vehicle.twStart' })
        case 'TWE':
          return translate({ id: 'rm.scheduler.violations.vehicle.twEnd' })
        case 'DT':
          return translate({ id: 'rm.scheduler.violations.vehicle.drivingTime' })
        case 'WT':
          return translate({ id: 'rm.scheduler.violations.vehicle.workingTime' })
        case 'ML':
          return translate({ id: 'rm.scheduler.violations.vehicle.maxMileage' })
        case 'OR':
          return translate({ id: 'rm.scheduler.violations.vehicle.maxOrders' })
        case 'NLD':
          return translate({ id: 'rm.scheduler.violations.vehicle.negativeLoadValue' })
      }
    },

    more: (count: number): string =>
      translate({ id: 'rm.scheduler.popOver.moreText', values: { count } }),
  }))

  return api
}
