import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const handlers = useControllerActions()

  const {
    close,
    status,
    invalid,
    data: { submitting },
  } = useController()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          disabled={invalid || submitting || status !== 'ready'}
          onClick={handlers.onSubmit}
          testid="modal__button-update"
          variant="contained"
          loading={submitting}
        >
          {texts.footer.update}
        </ModalFooterButton>
      }
      secondaryAction={<ModalFooterButton onClick={close}>{texts.footer.cancel}</ModalFooterButton>}
    />
  )
}
