import { PureComponent } from 'react'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  ReadonlyBadge,
  FlexRow,
} from '@/forms-legacy'

import * as OrderTexts from '../../../intl'

import { parseSecondToText } from '../utils'
import { Props } from '../typings'

export default class ReportedTime extends PureComponent<Props> {
  render() {
    const {
      trackingData: { timeInSec, timeOutSec },
    } = this.props

    const timeInDisabled: boolean = timeInSec === -1
    const timeOutDisabled: boolean = timeOutSec === -1

    const timeInText = parseSecondToText(timeInSec)
    const timeOutText = parseSecondToText(timeOutSec)

    return (
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <ReadonlyBlock half>
          <FlexRow>
            <ReadonlyBadge style={{ marginLeft: 6 }} background="#ff9500" disabled={timeInDisabled}>
              1
            </ReadonlyBadge>
            <ReadonlyLabel>{OrderTexts.getReportedTimeInLabel()}</ReadonlyLabel>
          </FlexRow>

          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{timeInText}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>

        <ReadonlyBlock half>
          <FlexRow>
            <ReadonlyBadge
              style={{ marginLeft: 6 }}
              background="#ff9500"
              disabled={timeOutDisabled}
            >
              2
            </ReadonlyBadge>
            <ReadonlyLabel>{OrderTexts.getReportedTimeOutLabel()}</ReadonlyLabel>
          </FlexRow>

          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{timeOutText}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      </FlexRow>
    )
  }
}
