import { InspectPinMode, Map as MainMap, MapLayer } from '@/map'

export function Map() {
  return (
    <MainMap mapId="ww-map" selectionContext="crud" selectionCategory="deviceEvents">
      <InspectPinMode mode="normal" />

      {/* <MapLayer uid="order" interactive visible />
      <MapLayer uid="region" visible />
      <MapLayer uid="roadSegment" visible />
      <MapLayer uid="geofence" visible={false} />
      <MapLayer uid="routePolyline" visible />
      <MapLayer uid="place" visible /> */}
      <MapLayer uid="deviceEvent" visible />

      {/* TODO: Add remaining layers once they are implemented */}
    </MainMap>
  )
}
