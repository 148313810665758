import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDrivers } from '@/features/domain/driver'

export function useDriversByEmail() {
  const drivers = useSelector(selectDrivers)

  return useMemo<Record<string, uui.domain.client.rm.ExtendedDriver>>(() => {
    const driversMapByEmail = {}
    for (const driver of Object.values(drivers)) {
      driversMapByEmail[driver.driver.deployment.email] = driver
    }
    return driversMapByEmail
  }, [drivers])
}
