import { useCallback } from 'react'

import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from '@/store'

import { isDriverEmailAvailable } from '@/features/domain/driver/actions'
// import { validateEmail } from '@/features/domain/generic/actions'

type ValidationResult = 'available' | 'invalid' | 'inUse'

export const useValidateDriverEmail = () => {
  const dispatch = useAppDispatch()

  const validateDriverEmail = useCallback<(email: string) => Promise<ValidationResult>>(
    async (email: string) => {
      const emailAvailableRequest = await dispatch(isDriverEmailAvailable(email))

      if (isDriverEmailAvailable.fulfilled.match(emailAvailableRequest)) {
        return unwrapResult(emailAvailableRequest)
      }

      throw new Error('Server error')
    },
    [dispatch],
  )

  return validateDriverEmail
}
