import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    popOutOrder: (selectedOrders: number) =>
      translate({
        id: 'contextualMenu.stylesOnMap.order.popOut',
        values: { count: selectedOrders },
      }),
  }))

  return api
}
