import type { LayerUID } from '../../../typings'

import { useEffect, useRef } from 'react'

import { getMap } from '../../../../atoms/map/utils/getMap'
import { findLayer } from '../../../utils/findLayer'
import { getInteraction } from '../../../../interactions/interactionMetadata'

export const useUpdateOnUnmount = (
  uid: LayerUID,
  visibleOnUnmount?: boolean,
  interactiveOnUnmount?: boolean,
) => {
  const api = useRef({ uid, visibleOnUnmount, interactiveOnUnmount })

  useEffect(() => {
    api.current.uid = uid
    api.current.visibleOnUnmount = visibleOnUnmount
    api.current.interactiveOnUnmount = interactiveOnUnmount
  }, [uid, visibleOnUnmount, interactiveOnUnmount])

  useEffect(
    () => () => {
      const { uid, visibleOnUnmount, interactiveOnUnmount } = api.current

      try {
        const layer = findLayer(getMap(), uid)

        if (typeof visibleOnUnmount === 'boolean') {
          layer.setVisible(visibleOnUnmount)
        }

        if (typeof interactiveOnUnmount === 'boolean') {
          if (interactiveOnUnmount) {
            getInteraction('rm-mouse-single-click-interaction')?.enableLayer(layer)
            getInteraction('rm-mouse-double-click-interaction')?.enableLayer(layer)
            getInteraction('rm-mouse-move-interaction')?.enableLayer(layer)
          } else {
            getInteraction('rm-mouse-single-click-interaction')?.disableLayer(layer)
            getInteraction('rm-mouse-double-click-interaction')?.disableLayer(layer)
            getInteraction('rm-mouse-move-interaction')?.disableLayer(layer)
          }
        }
      } catch (e) {}
    },
    [],
  )
}
