import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Trash(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M42 5H32V3c0-1.65234-1.34766-3-3-3h-8c-1.65234 0-3 1.34766-3 3v2H8c-.55078 0-1 .44922-1 1s.44922 1 1 1h1.08594l3.60937 40.51563C12.8203 48.90623 14.0039 50 15.39065 50h19.21484c1.38672 0 2.5703-1.09375 2.6953-2.48438L40.91408 7H42c.5547 0 1-.44922 1-1s-.4453-1-1-1zM20 44c0 .5547-.44922 1-1 1s-1-.4453-1-1V11c0-.55078.44922-1 1-1s1 .44922 1 1zm0-41c0-.55078.44922-1 1-1h8c.55078 0 1 .44922 1 1v2H20zm6 41c0 .5547-.44922 1-1 1s-1-.4453-1-1V11c0-.55078.44922-1 1-1s1 .44922 1 1zm6 0c0 .5547-.4453 1-1 1s-1-.4453-1-1V11c0-.55078.4453-1 1-1s1 .44922 1 1z" />
      </svg>
    </IconContainer>
  )
}
