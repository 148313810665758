import type { FormValues } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields } from '../../formFields'

interface Args {
  formValues: FormValues<FormFields>
  userConfiguration: uui.domain.UserConfiguration
  weekStartsOn: uui.domain.WeekStartsOn
  uiSettings?: uui.domain.server.rm.UserUISettings
}

export function createUpdateUserPayload(
  args: Args,
): uui.domain.actions.rpc.user.UpdateUserAction['payload'] {
  const { formValues, userConfiguration, uiSettings, weekStartsOn } = args
  const {
    id,
    name,
    type,
    email,
    limitedAccess,
    surname,
    enabled,
    username,
    courierFilter,
    territoryFilter: territoryFilterValue,
    isCompanyFiltered,
    companies: companiesValue,
  } = formValues
  let territoryFilter: string[]

  const companies = companiesValue.map(company => company.id)

  switch (type) {
    case 'courier':
      territoryFilter = Object.keys(courierFilter)
      break

    default:
      territoryFilter =
        limitedAccess === false ? ['*'] : territoryFilterValue.map(territory => territory.id)
      break
  }

  const baseParams = {
    id,
    name,
    enabled,
    surname,
    username,
    email,
    territoryFilter,
    settings: userConfiguration,
    uiData: uiSettings ?? {
      version: 1 as const,
      exportOrders: {
        format: 'CSV' as 'CSV' | 'XLSX',
        includeBarcodes: true,
      },
      exportRoutes: {
        format: 'CSV' as 'CSV' | 'XLSX',
        includePod: true,
        includeBarcodes: true,
        loads: [],
        customFields: [],
      },
      weekStartsOn,
    },

    // by default this parameters are always present
    isCompanyFiltered,
    companies,
  }

  const user: uui.domain.actions.rpc.user.UpdateUserAction['payload']['user'] =
    type === 'courier'
      ? {
          type,
          ...baseParams,
          courierFilter,
        }
      : { type, ...baseParams }

  return {
    user,
  }
}
