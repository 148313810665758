import { Component } from 'react'

import { clsx } from '@/utils'
import { Cross } from '@/icons'
import { ButtonDelete } from '@/forms-legacy'

import TimeWindow, { Props as TimeWindowProps } from '../../TimeWindow'
import { Callback, DeleteCallback } from '../TimeWindowList'

export interface Props {
  id: number
  action: DeleteCallback
  onChange: Callback
  className?: string
  item: uui.domain.rm.TimeWindow
  h24?: boolean
}

export default class TimeWindowListItem extends Component<Props> {
  handleChange = (value: uui.domain.rm.TimeWindow): void => {
    const { onChange, id } = this.props
    onChange(value, id)
  }

  handleDelete = () => {
    const { action, id } = this.props
    action(id)
  }

  render() {
    const { className = '', item, h24 } = this.props
    const rootClassName = clsx({
      [className]: true,
      'o-breaks-list-item': true,
    })

    const { startSec, endSec } = item

    const timeWindowProps: TimeWindowProps = {
      startSec,
      endSec,
      onChange: this.handleChange,
      className: 'o-time-window-list-item__item',
      name: 'time-window',
      h24,
    }

    return (
      <div className={rootClassName}>
        <ButtonDelete
          type="button"
          onClick={this.handleDelete}
          className="o-time-window-list-item__button"
          data-testid="time-window-list-item__delete-button"
        >
          <Cross />
        </ButtonDelete>
        <TimeWindow {...timeWindowProps} />
      </div>
    )
  }
}
