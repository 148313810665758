import Draw, { DrawEvent, Options } from 'ol/interaction/Draw'

import Feature from 'ol/Feature'
import { getMapSelectionMode } from '../atoms/map/utils/getMapSelectionMode'

export class LassoInteraction extends Draw {
  constructor(options: Options) {
    super(options)

    // TODO: if this class will be added/removed dynamically we must remove the listeners to avoid memory leaks?
    this.on('change:active', this.handleChangeActiveState)
  }

  protected handleChangeActiveState = () => {
    if (this.getActive()) {
      this.enableDisableOnEsc()
    } else {
      this.disableDisableOnEsc()
    }

    this.setCursor()
  }

  protected handleAbortOnEsc = (event: KeyboardEvent) => {
    // Abort on pressing ESC
    if (
      event.key === 'Escape' ||
      // IE/Edge specific value
      event.key === 'Esc'
    ) {
      event.stopPropagation()
      this.abortDrawing()

      // the event will not fire unless the user started drawing
      // we want it to emit always
      this.dispatchEvent(new DrawEvent('drawabort', new Feature()))
    }
  }

  protected setCursor() {
    const map = this.getMap()
    if (!map) return

    const view = map.getViewport()
    if (!view) return

    // access currently active selectionMode
    const selectionMode = getMapSelectionMode()

    // always reset
    view.classList.remove('ol-pointer-lasso')
    view.classList.remove('ol-pointer-lasso-additive')
    view.classList.remove('ol-pointer-lasso-subtractive')

    switch (selectionMode) {
      case 'lasso':
        view.classList.add('ol-pointer-lasso')
        break

      case 'lassoAdditive':
        view.classList.add('ol-pointer-lasso-additive')
        break

      case 'lassoSubtractive':
        view.classList.add('ol-pointer-lasso-subtractive')
        break
    }
  }

  protected enableDisableOnEsc() {
    window.addEventListener('keydown', this.handleAbortOnEsc)
  }

  protected disableDisableOnEsc() {
    window.removeEventListener('keydown', this.handleAbortOnEsc)
  }
}
