import type { GridColumnMenuProps } from '@mui/x-data-grid-pro'

import { forwardRef } from 'react'
import {
  GridColumnMenuContainer,
  GridColumnsMenuItem,
  GridFilterMenuItem,
  HideGridColMenuItem,
} from '@mui/x-data-grid-pro'

import { SortGridMenuItems } from './components/SortGridMenuItems'

export interface ColumnMenuProps extends GridColumnMenuProps {
  lockedColumns?: string[]
}

export const ColumnMenu = forwardRef<HTMLUListElement, ColumnMenuProps>(function GridColumnMenu(
  props: ColumnMenuProps,
  ref,
) {
  const { hideMenu, currentColumn, open, lockedColumns } = props

  const canHide = !lockedColumns?.includes(currentColumn.field)

  return (
    <GridColumnMenuContainer
      currentColumn={currentColumn}
      hideMenu={hideMenu}
      open={open}
      ref={ref}
    >
      <SortGridMenuItems onClick={hideMenu} column={currentColumn!} />
      <GridFilterMenuItem onClick={hideMenu} column={currentColumn!} />
      {canHide && <HideGridColMenuItem onClick={hideMenu} column={currentColumn!} />}
      <GridColumnsMenuItem onClick={hideMenu} column={currentColumn!} />
    </GridColumnMenuContainer>
  )
})
