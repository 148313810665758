import { Component, ReactNode } from 'react'

import { DatePicker, InputFieldLabel } from '@/forms-legacy'
import { clsx } from '@/utils'

import TimeWindowList from '../TimeWindowList'

import { getDateText } from './intl'

export type Props = {
  date: Date
  timeWindowList: uui.domain.rm.TimeWindow[]
  onChange: (date: Date, timeWindowList: uui.domain.rm.TimeWindow[]) => void
  className?: string
  renderError?: (date: string | Date, index: number) => ReactNode
  renderDateError?: (date: string | Date) => ReactNode
  h24?: boolean
}

export default class TimeWindowException extends Component<Props> {
  private renderError = (index: number): ReactNode => {
    const { renderError, date } = this.props
    return renderError ? renderError(date, index) : null
  }

  private handleOnChangeDate = (date: Date): void => {
    const { onChange, timeWindowList } = this.props
    onChange(date, timeWindowList)
  }

  private handleOnChangeTimeWindowList = (timeWindowList: uui.domain.rm.TimeWindow[]): void => {
    const { onChange, date } = this.props
    onChange(date, timeWindowList)
  }

  render() {
    const { className = '', date, timeWindowList, renderDateError, h24 } = this.props

    const rootClassName = clsx({
      [className]: true,
      'o-time-window-exception': true,
    })

    return (
      <div className={rootClassName}>
        <InputFieldLabel>{getDateText()}: </InputFieldLabel>
        <DatePicker
          value={date}
          name="time-window-exception-date-picker"
          onChange={this.handleOnChangeDate}
        />

        {renderDateError?.(date)}

        <TimeWindowList
          list={timeWindowList}
          onChange={this.handleOnChangeTimeWindowList}
          renderError={this.renderError}
          h24={h24}
        />
      </div>
    )
  }
}
