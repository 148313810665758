import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    addTimeWindow: translate({ id: 'setup.traffic.timeWindows.addNew' }),
    timeWindow1: translate({ id: 'setup.traffic.timeWindow.1' }),
    timeWindow2: translate({ id: 'setup.traffic.timeWindow.2' }),
  }))

  return api
}
