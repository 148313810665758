import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    titleCreating: translate({ id: 'userManagement.create.title' }),
    titleEditing: translate({ id: 'userManagement.edit.title' }),
    placeholders: {
      name: translate({ id: 'userManagement.edit.placeholders.name' }),
      surname: translate({ id: 'userManagement.edit.placeholders.surname' }),
      email: translate({ id: 'userManagement.edit.placeholders.email' }),
      username: translate({ id: 'userManagement.edit.placeholders.username' }),
      password: translate({ id: 'userManagement.edit.placeholders.password' }),
      territories: translate({ id: 'userManagement.edit.placeholders.territories' }),
    },
    labels: {
      name: translate({ id: 'userManagement.edit.labels.name' }),
      surname: translate({ id: 'userManagement.edit.labels.surname' }),
      email: translate({ id: 'userManagement.edit.labels.email' }),
      username: translate({ id: 'userManagement.edit.labels.username' }),
      password: translate({ id: 'userManagement.edit.labels.password' }),
      role: translate({ id: 'userManagement.edit.labels.role' }),
      roles: {
        planner: translate({ id: 'userManagement.edit.labels.role.planner' }),
        viewer: translate({ id: 'userManagement.edit.labels.role.viewer' }),
        guest: translate({ id: 'userManagement.edit.labels.role.guest' }),
        courier: translate({ id: 'userManagement.create.labels.role.courier' }),
      },
      access: translate({ id: 'userManagement.edit.labels.access' }),
      limitedAccess: translate({ id: 'userManagement.edit.labels.limitedAccess' }),
      territories: {
        all: translate({ id: 'userManagement.edit.labels.access.all' }),
        limited: translate({ id: 'userManagement.edit.labels.access.limited' }),
      },
      territoriesFilter: translate({ id: 'userManagement.edit.labels.territories' }),
      territory: translate({ id: 'userManagement.edit.labels.territory' }),
      isCompanyFiltered: translate({ id: 'userManagement.edit.labels.isCompanyFiltered' }),
      companies: translate({ id: 'userManagement.edit.labels.companies' }),
    },
    buttons: {
      create: translate({ id: 'userManagement.create.buttons.create' }),
      save: translate({ id: 'userManagement.edit.buttons.save' }),
      cancel: translate({ id: 'userManagement.edit.buttons.cancel' }),
    },
    errors: {
      required: translate({ id: 'userManagement.edit.errors.required' }),
      maxLength: (count: number) =>
        translate({ id: 'userManagement.edit.errors.maxLength', values: { count } }),
      minLengthPassword: (count: number) =>
        translate({ id: 'userManagement.edit.errors.minLength.password', values: { count } }),
      minLength: (count: number) =>
        translate({ id: 'userManagement.edit.errors.minLength', values: { count } }),
      format: translate({ id: 'userManagement.edit.errors.format' }),
      unique: translate({ id: 'userManagement.edit.errors.unique' }),
      territoryFilter: translate({ id: 'userManagement.edit.errors.territoryFilter' }),
      edit: translate({ id: 'userManagement.edit.error' }),
      courierFilter: {
        noTerritoriesDefined: translate({ id: 'userManagement.3pc.error.noTerritoriesDefined' }),
        noDrivers: translate({ id: 'userManagement.3pc.error.noDrivers' }),
        noVehicles: translate({ id: 'userManagement.3pc.error.noVehicles' }),
      },
      companies: translate({ id: 'userManagement.edit.errors.companies.required' }),
    },
    helperText: {
      isCompanyFiltered: translate({ id: 'userManagement.helperText.isCompanyFiltered' }),
      limitedAccess: translate({ id: 'userManagement.helperText.limitedAccess' }),
    },
    tooltip: {
      isCompanyFiltered: {
        disabled: translate({ id: 'userManagement.tooltip.isCompanyFiltered.disabled' }),
      },
    },
    multiSelect: {
      companies: {
        clearAll: (count: number) =>
          translate({ id: 'userManagement.multiSelect.companies.clearAll', values: { count } }),
        selectAll: (count: number) =>
          translate({ id: 'userManagement.multiSelect.companies.selectAll', values: { count } }),
        noOptions: translate({ id: 'userManagement.multiSelect.companies.noOptions' }),
        clearSearch: translate({ id: 'userManagement.multiSelect.companies.clearSearch' }),
      },
      territories: {
        clearAll: (count: number) =>
          translate({ id: 'userManagement.multiSelect.territories.clearAll', values: { count } }),
        selectAll: (count: number) =>
          translate({ id: 'userManagement.multiSelect.territories.selectAll', values: { count } }),
        noOptions: translate({ id: 'userManagement.multiSelect.territories.noOptions' }),
        clearSearch: translate({ id: 'userManagement.multiSelect.territories.clearSearch' }),
      },
    },
  }))

  return api
}

export type Texts = ReturnType<typeof useTexts>
