import { useRef } from 'react'
import { Stack } from '@mui/material'
import { MapControls } from '@/components/MapControls'
import { OrdersGrid } from '@/components/OrdersGrid'
import { Scheduler } from '@/scheduler'
import { Navigo } from '@/components/Navigo'

import { Map } from '../Map'

import { VerticalResizeHandle } from './components/VerticalResizeHandle'
import { useSchedulerHeight } from './hooks/useSchedulerHeight'

interface Props {
  visibleItem: 'scheduler' | 'orders-grid'
}

export function SplitLayout(props: Props) {
  const { visibleItem } = props

  const containerRef = useRef<HTMLDivElement | null>(null)

  const { dragging, onMouseDown, height } = useSchedulerHeight(
    containerRef.current?.offsetHeight ?? 0,
  )

  return (
    <Stack width="100%" height="100%" overflow="hidden" ref={containerRef}>
      {/* No Extract Map management here. The Map and the Extract Map cannot co-exist. */}
      <MapControls enableInspectPin canFilterOrders />
      <Map />
      <Stack minHeight={height} position="relative" width="100%">
        {visibleItem === 'scheduler' ? (
          <Scheduler
            ResizeHandle={<VerticalResizeHandle onMouseDown={onMouseDown} dragging={dragging} />}
          />
        ) : (
          <OrdersGrid
            ResizeHandle={<VerticalResizeHandle onMouseDown={onMouseDown} dragging={dragging} />}
          />
        )}
        <Navigo />
      </Stack>
    </Stack>
  )
}
