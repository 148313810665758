import { Component, KeyboardEvent } from 'react'

import { Search as Lens } from '@/icons'
import { SearchField } from '@/forms-legacy'

import * as Texts from '../intl'

export interface Props {
  onChange: (filter: string) => void
  onAdd: (key: string) => void
  filter: string
}

interface State {
  placeholder: string
}

export default class Search extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      placeholder: Texts.getSearchFieldPlaceholderText(),
    }
  }

  handleOnKeyUp = (event: KeyboardEvent<HTMLElement>): void => {
    const { filter, onAdd, onChange } = this.props
    const { key } = event
    if (key === 'Enter') {
      onChange('')
      onAdd(filter)
    }
  }

  render() {
    const { onChange, filter } = this.props
    const { placeholder } = this.state

    return (
      <SearchField
        name="search"
        onKeyUp={this.handleOnKeyUp}
        onChange={onChange}
        value={filter}
        placeholder={placeholder}
        extraIcon={<Lens />}
      />
    )
  }
}
