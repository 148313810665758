import { useState, useRef, useCallback, useEffect, useReducer } from 'react'
import {
  useInspectPin,
  addInspectPin,
  useToggleInspectPin,
  useLocationPin,
  moveLocationPin,
} from '@/map'

const meters = 10000

export function LocationPinTester() {
  const [currentLocationPinId, setCurrentLocationPinId] = useState('')
  const [inspect, setInspectPin] = useInspectPin()
  const [orderForm_pickup] = useLocationPin('orderForm_pickup')
  const [orderForm_delivery] = useLocationPin('orderForm_delivery')
  const [depotFormPin] = useLocationPin('depotFormPin')
  const [placeFormPin] = useLocationPin('placeFormPin')
  const [geofenceFormPin] = useLocationPin('geofenceFormPin')
  const toggleInspectPin = useToggleInspectPin()
  const [, forceRender] = useReducer(s => s + 1, 0)

  const setInspectPinToConverted = useCallback(() => {
    if (!locationPins.current.inspect.locationPin) {
      console.log('Toggle the location pin first')
    }

    // pin placed on Parco Forlanini, Milan territory, it should be snapped to "Via Salesina,
    // 20134 Milan MI, Italy" lat: 45.4671 lng: 9.26153 once converted to a new order
    const location = { lat: 45.466849, lng: 9.260279 }
    addInspectPin(location)

    // @ts-expect-error
    locationPins.current.inspect.updater(pin => ({
      ...pin,
      type: 'converted',
      target: 'order',
    }))
  }, [])

  const locationPins = useRef({
    inspect: { locationPin: inspect, updater: setInspectPin },
    orderForm_pickup: {
      locationPin: orderForm_pickup,
      updater: (nextLatLng: uui.domain.LatLng) => moveLocationPin('orderForm_pickup', nextLatLng),
    },
    orderForm_delivery: {
      locationPin: orderForm_delivery,
      updater: (nextLatLng: uui.domain.LatLng) => moveLocationPin('orderForm_delivery', nextLatLng),
    },
    depotFormPin: {
      locationPin: depotFormPin,
      updater: (nextLatLng: uui.domain.LatLng) => moveLocationPin('depotFormPin', nextLatLng),
    },
    placeFormPin: {
      locationPin: placeFormPin,
      updater: (nextLatLng: uui.domain.LatLng) => moveLocationPin('placeFormPin', nextLatLng),
    },
    geofenceFormPin: {
      locationPin: geofenceFormPin,
      updater: (nextLatLng: uui.domain.LatLng) => moveLocationPin('geofenceFormPin', nextLatLng),
    },
  } as const)

  useEffect(() => {
    locationPins.current = {
      ...locationPins.current,
      inspect: { ...locationPins.current.inspect, locationPin: inspect },
      orderForm_pickup: { ...locationPins.current.orderForm_pickup, locationPin: orderForm_pickup },
      orderForm_delivery: {
        ...locationPins.current.orderForm_delivery,
        locationPin: orderForm_delivery,
      },
      depotFormPin: { ...locationPins.current.depotFormPin, locationPin: depotFormPin },
      placeFormPin: { ...locationPins.current.placeFormPin, locationPin: placeFormPin },
      geofenceFormPin: { ...locationPins.current.geofenceFormPin, locationPin: geofenceFormPin },
    }

    forceRender()
  }, [inspect, orderForm_pickup, orderForm_delivery, depotFormPin, placeFormPin, geofenceFormPin])

  // values calculated on default zoom for Ste's MIlan territory
  const handleOnUpClick = useCallback(() => {
    const { locationPin: prevPin, updater } = locationPins.current[currentLocationPinId]

    if (!prevPin) return

    updater({
      lat: prevPin.pin.location.lat,
      lng: prevPin.pin.location.lng - ((9.33339 - 9.209167) * meters) / 10000,
    })
  }, [currentLocationPinId])

  const handleOnLeftClick = useCallback(() => {
    const { locationPin: prevPin, updater } = locationPins.current[currentLocationPinId]

    if (!prevPin) return

    updater({
      lat: prevPin.pin.location.lat - ((45.484082 - 45.481976) * meters) / 10000,
      lng: prevPin.pin.location.lng,
    })
  }, [currentLocationPinId])

  const handleOnRightClick = useCallback(() => {
    const { locationPin: prevPin, updater } = locationPins.current[currentLocationPinId]

    if (!prevPin) return

    updater({
      lat: prevPin.pin.location.lat + ((45.484082 - 45.481976) * meters) / 10000,
      lng: prevPin.pin.location.lng,
    })
  }, [currentLocationPinId])

  const handleOnDownClick = useCallback(() => {
    const { locationPin: prevPin, updater } = locationPins.current[currentLocationPinId]

    if (!prevPin) return

    updater({
      lat: prevPin.pin.location.lat,
      lng: prevPin.pin.location.lng + ((9.33339 - 9.209167) * meters) / 10000,
    })
  }, [currentLocationPinId])

  return (
    <div>
      Choose the Location Pin between the active ones
      <br />
      {Object.entries(locationPins.current).map(
        ([id, { locationPin }]) =>
          !!locationPin && (
            <span key={id} style={{ padding: 10 }}>
              <input
                type="radio"
                value={id}
                id={id}
                checked={id === currentLocationPinId}
                onChange={e => e.currentTarget.value && setCurrentLocationPinId(id)}
              />
              <label htmlFor={id}>{id}</label>
            </span>
          ),
      )}
      {!!currentLocationPinId && (
        <>
          <br />
          Move location pin
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;<button onClick={handleOnUpClick}>Up</button>
          <br />
          <button onClick={handleOnLeftClick}>Left</button>
          <button onClick={handleOnRightClick}>Right</button>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;<button onClick={handleOnDownClick}>Down</button>
          <pre>
            {JSON.stringify(locationPins.current[currentLocationPinId].locationPin, null, 2)}
          </pre>
        </>
      )}
      <button onClick={toggleInspectPin}>Toggle location pin</button>
      <button onClick={setInspectPinToConverted}>Set inspect pin</button>
    </div>
  )
}
