import { PureComponent } from 'react'

import {
  SecondaryColumnContentHorizontalRuler,
  SecondaryColumnContentContainer,
} from '@/forms-legacy'

import BannedTags from './elements/BannedTags'
import CustomFields from './elements/CustomFields'
import Barcodes from './elements/Barcodes'
import Eligibility from './elements/Eligibility'
import { Company } from './elements/Company'
import Exceptions from './elements/Exceptions'
import Loads from './elements/Loads'
import Notes from './elements/Notes'
import Priority from './elements/Priority'
import RequiredTags from './elements/RequiredTags'
import ServiceTime from './elements/ServiceTime'
import TimeWindows from './elements/TimeWindows'
import Type from './elements/Type'
import Vehicle from './elements/Vehicle'
import ContactInformation from './elements/ContactInformation'
import { IntegrationData } from './elements/IntegrationData'

import { Props } from './typings'

export default class ViewDetails extends PureComponent<Props> {
  render() {
    const { type, allowBarcodes, integrationData } = this.props
    const showLoads: boolean = type !== 's'

    return (
      <SecondaryColumnContentContainer>
        <SecondaryColumnContentHorizontalRuler bigBottomSpace />

        {!!integrationData && <IntegrationData integrationData={integrationData} />}
        <Company {...this.props} />
        <Eligibility {...this.props} />
        {showLoads && <Loads {...this.props} />}
        <Vehicle {...this.props} />
        <Priority {...this.props} />
        <Type {...this.props} />
        <ContactInformation {...this.props} />
        <ServiceTime {...this.props} />
        <TimeWindows {...this.props} />
        <Exceptions {...this.props} />
        <RequiredTags {...this.props} />
        <BannedTags {...this.props} />
        <Notes {...this.props} />
        <CustomFields {...this.props} />
        {allowBarcodes && <Barcodes {...this.props} />}
      </SecondaryColumnContentContainer>
    )
  }
}
