import { useUnassignOrderModal } from '@/components/modals/UnassignOrderModal'
import { useBuildProgressModal } from '@/components/modals/BuildProgressModal'
import { useResidentModalsApi } from '@/components/modals/ResidentModals'

export const useModalActions = (routesOrderStepIds: string[]) => {
  const { show: showUnassignOrderModal, Modal: UnassignOrderModal } = useUnassignOrderModal()
  const { show: showBuildProgressModal, Modal: BuildProgressModal } = useBuildProgressModal()
  const {
    exportRoutes: { show: showExportRoutesModal },
  } = useResidentModalsApi()

  return {
    modalActions: {
      showExportRoutesModal,
      showUnassignOrderModal,
      showBuildProgressModal,
    },
    modals: (
      <>
        <UnassignOrderModal orderStepIds={routesOrderStepIds} />
        <BuildProgressModal />
      </>
    ),
  }
}
