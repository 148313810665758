import { ReactNode } from 'react'

import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'

import { ReadonlyBlock, ReadonlyLabel } from '@/forms-legacy'
import { useEnvironmentURLs } from '@/atoms'

import * as OrderTexts from '../../../intl'
import { Props } from '../typings'
import ReadonlyImage from './ReadonlyImage'

export function PodSignatures(props: Props) {
  const {
    pods: { signatures },
    activePodMarker,
    onTogglePod,
  } = props

  const { podsBaseUrl } = useEnvironmentURLs()

  let signatureTime: ReactNode = ''
  let signatureTitle: string = ''
  let signatureSrc: string = ''
  let signatureText: string = ''
  let signatureLatLng: uui.domain.LatLng | undefined
  let signatureUuid: string = ''

  if (signatures) {
    const {
      customer: { sec, text, latLng, uuid },
    } = signatures
    signatureText = text || ''
    signatureTime = <ConvertTime seconds={sec} showSeconds={false} />
    signatureTitle = OrderTexts.getCustomerSignatureLabel()
    signatureSrc =
      signatures.customer.podType === 'signatures'
        ? `${podsBaseUrl}${signatures.customer.token}`
        : ''
    signatureUuid = uuid

    if (latLng) {
      signatureLatLng = latLng
    }
  }

  return (
    <>
      {!!signatures && (
        <ReadonlyBlock>
          <ReadonlyLabel>{OrderTexts.getSignaturesLabel()}</ReadonlyLabel>

          <ReadonlyImage
            latLng={signatureLatLng}
            onToggle={onTogglePod}
            src={signatureSrc}
            title={signatureTitle}
            time={signatureTime}
            text={signatureText}
            layout={'detail'}
            backgroundSize="70% auto"
            uuid={signatureUuid}
            selected={activePodMarker?.uid === signatures.customer.uuid}
            toggleLayoutDisabled
          />
        </ReadonlyBlock>
      )}
    </>
  )
}
