import { useMemo } from 'react'

const lastValidTimeOption = 1425 // 23:45
const step = 15 // 15 minutes

/**
 * That hook provides an array of numbers representing possible time options from 00:00 to 23:45
 * with a step of 15 minutes
 */
export function useNotificationTimeOptions() {
  return useMemo(() => {
    // Initialize the array with 0 (00:00)
    const times = [0]

    // Create an array of numbers representing possible time options from 00:00 to 23:45
    while (times[times.length - 1] < lastValidTimeOption) {
      times.push(times[times.length - 1] + step)
    }

    return times
  }, [])
}
