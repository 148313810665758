import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'

import { Footer } from './Footer'
import { Header } from './Header'
import { Body } from './Body'

interface Props {
  locationToShare?: uui.domain.LatLng
}

export function Content(props: Props) {
  const { ready } = useConfigureController(props.locationToShare)

  if (!ready) return null

  return (
    <ModalContent header={<Header />} footer={<Footer />}>
      <Body />
    </ModalContent>
  )
}
