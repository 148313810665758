import { Divider, Stack } from '@mui/material'

import { useAppLanguage } from '@/atoms'

import { FormatPicker } from './components/FormatPicker'
import { Advanced } from './components/Advanced'
import { CustomizeColumns } from './components/CustomizeColumns'

export function Body() {
  const [{ language }] = useAppLanguage()

  return (
    <Stack height="100%" overflow="auto" padding={4} gap={4}>
      <FormatPicker />
      <Divider />
      <Stack gap={2}>
        <CustomizeColumns />
        {language !== 'en' && <Advanced />}
      </Stack>
    </Stack>
  )
}
