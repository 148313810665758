import { unwrapResult } from '@reduxjs/toolkit'
import { AppDispatch, getReduxStore } from '@/store'

import { getPlace } from '@/features/domain/ui'

export async function geocodeSearchResult(
  locationPin: uui.domain.ui.map.LocationPin,
  place: uui.domain.client.gps.GeocodedPlace,
): Promise<uui.domain.client.Location | void> {
  const dispatch = getReduxStore().dispatch as AppDispatch

  // Reverse geocode the provided coordinates
  const request = await dispatch(getPlace({ place, snapToRoad: locationPin.pin.snapToRoad }))

  if (getPlace.fulfilled.match(request)) {
    return unwrapResult(request)
  }
}
