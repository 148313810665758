import type { NotificationFormType } from '@/notificationForms'

import { useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

type ModalState = 'ready'
interface ModalData {
  notification: NotificationFormType
  goingToEnable: boolean
  onToggle: () => void
  subtitle: string
  title: string
}

export const modalId = 'confirmNotificationToggle'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = (
  title: string,
  subtitle: string,
  notification: NotificationFormType,
  onToggle: () => void,
  goingToEnable: boolean,
) => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        notification,
        goingToEnable,
        onToggle,
        subtitle,
        title,
      },
    }),
  }))

  return useConfigureModalController<ModalData, ModalState>(modalId, options)
}
