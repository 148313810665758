import { type SchedulerPro } from '@bryntum/schedulerpro'
import { getSchedulerLastTimeSpan } from '../../../atoms/schedulerLastTimeSpan'

export function setInitialSchedulerZoom(scheduler?: SchedulerPro) {
  if (!scheduler) return

  const storedTimeSpan = getSchedulerLastTimeSpan()

  if (!storedTimeSpan?.start || !storedTimeSpan?.end) return

  // Since zoomToSpan does not work well when the zoom level is 0, we need to zoom in first
  scheduler.zoomInFull()

  // Zoom to the stored time span
  scheduler.zoomToSpan({
    rightMargin: 0,
    leftMargin: 0,
    startDate: storedTimeSpan.start,
    endDate: storedTimeSpan.end,
  })

  // console.log('stored time span', {
  //   firstVisibleRowId: storedTimeSpan.firstVisibleRowId,
  //   startDate: storedTimeSpan.start,
  //   endDate: storedTimeSpan.end,
  // })

  if (!storedTimeSpan.firstVisibleRowId) return

  scheduler.scrollRowIntoView(storedTimeSpan.firstVisibleRowId, {
    edgeOffset: 0,
    block: 'start',
    x: false,
  })
}
