import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    externalDriver: translate({ id: 'global.externalDriver' }),
    notTracked: translate({ id: 'driverAssignments.singleView.tooltip.notTracked' }),
  }))

  return api
}
