export function patchDeviceConfiguration(
  mutableDraft: uui.domain.client.gps.wwgps.DeviceConfiguration | undefined,
  formValues: uui.domain.ui.forms.DeviceData,
) {
  const { maintenanceMileage } = formValues
  if (!mutableDraft?.maintenanceMileageMethod || !maintenanceMileage) return

  mutableDraft.maintenanceMileageMethod =
    maintenanceMileage === 'useOdometer' ? 'preferOdometer' : 'preferDevice'

  return mutableDraft
}
