import { useSelector } from 'react-redux'

import { selectRegionsListStructure } from '@/features/domain/lists'
import { useEditingStateWithCtx } from '@/atoms'

import { useSearchList } from './useSearchList'

export function useFilter() {
  const { editing } = useEditingStateWithCtx('region')
  const { query, setQuery } = useSearchList()
  const structure = useSelector(selectRegionsListStructure)

  return {
    query,
    editing,
    setQuery,
    noEntries: structure.totalItems === 0,
  }
}
