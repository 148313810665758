import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    currency: translate({ id: 'settings.languageAndFormats.edit.fields.currency.label' }),
    language: translate({ id: 'settings.languageAndFormats.edit.fields.language.label' }),
    dateFormat: translate({ id: 'settings.languageAndFormats.edit.fields.dateFormat.label' }),
    timeFormat: translate({ id: 'settings.languageAndFormats.edit.fields.timeFormat.label' }),
    distanceFormat: translate({
      id: 'settings.languageAndFormats.edit.fields.distanceFormat.label',
    }),

    distanceFormatValue: (format: uui.domain.DistanceFormat) =>
      translate({
        id:
          format === 'METRIC' ? 'global.unitsOfMeasure.kilometers' : 'global.unitsOfMeasure.miles',
      }),
  }))

  return api
}
