import {
  ReadonlyLabel,
  ReadonlyLabelSup,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
  FlexRow,
} from '@/forms-legacy'

import { WarningRhombus } from '@/icons'

import { useCollapsibleState } from '../../../../../atoms/collapsibleState'
import { CollapseButton } from '../../../../../components/CollapseButton'

import { useTexts } from '../useTexts'
import { WarningIcon } from './WarningIcon'

interface Props {
  maxOrders?: string
  maxMileage: string
  maxDrivingHours?: string
  maxWorkingHours?: string
  minWorkingHours?: string
}

export function Constraints(props: Props) {
  const { maxOrders, maxMileage, maxDrivingHours, maxWorkingHours, minWorkingHours } = props

  const [collapsed, toggleCollapsed] = useCollapsibleState('constraints')
  const texts = useTexts()

  const maxMileageDisabled = !isNaN(parseInt(maxMileage)) && parseInt(maxMileage) === 0
  const workingHoursDisabled = !maxWorkingHours && !minWorkingHours

  return (
    <>
      <CollapseButton toggleCollapsed={toggleCollapsed} collapsed={collapsed}>
        {texts.constraints}
      </CollapseButton>
      {!collapsed && (
        <>
          <ReadonlyBlock half>
            <ReadonlyLabel empty={!maxOrders}>{texts.maxOrders}</ReadonlyLabel>
            {!!maxOrders && (
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyLabelSup style={{ marginRight: 3 }}>{texts.max}</ReadonlyLabelSup>
                  <ReadonlyFieldLabel>{maxOrders}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            )}
          </ReadonlyBlock>

          <ReadonlyBlock half>
            <ReadonlyLabel empty={maxMileageDisabled}>{texts.maxMileage}</ReadonlyLabel>
            {!maxMileageDisabled && (
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyLabelSup style={{ marginRight: 3 }}>{texts.max}</ReadonlyLabelSup>
                  <ReadonlyFieldLabel>{maxMileage}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            )}
          </ReadonlyBlock>

          <ReadonlyBlock half>
            <ReadonlyLabel empty={!maxDrivingHours}>{texts.maxDrivingHours}</ReadonlyLabel>
            {!!maxDrivingHours && (
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyLabelSup style={{ marginRight: 3 }}>{texts.max}</ReadonlyLabelSup>
                  <ReadonlyFieldLabel>
                    {maxDrivingHours ? maxDrivingHours : texts.notSet}
                  </ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            )}
          </ReadonlyBlock>

          <ReadonlyBlock>
            <ReadonlyLabel empty={workingHoursDisabled}>{texts.maxWorkingHours}</ReadonlyLabel>

            {!workingHoursDisabled && (
              <FlexRow>
                <ReadonlyBlock half>
                  <ReadonlyField>
                    <ReadonlyFieldRow>
                      <ReadonlyLabelSup style={{ marginRight: 3 }}>{texts.max}</ReadonlyLabelSup>
                      <ReadonlyFieldLabel>
                        {maxWorkingHours ? maxWorkingHours : texts.notSet}
                      </ReadonlyFieldLabel>
                    </ReadonlyFieldRow>
                  </ReadonlyField>
                </ReadonlyBlock>

                <ReadonlyBlock half style={{ marginLeft: 10, position: 'relative' }}>
                  <ReadonlyField style={{ position: 'relative' }}>
                    <ReadonlyFieldRow>
                      <ReadonlyLabelSup style={{ marginRight: 3 }}>{texts.min}</ReadonlyLabelSup>
                      <ReadonlyFieldLabel>
                        {minWorkingHours ? minWorkingHours : texts.notSet}
                      </ReadonlyFieldLabel>
                    </ReadonlyFieldRow>
                    {!!minWorkingHours && (
                      <WarningIcon>
                        <WarningRhombus />
                      </WarningIcon>
                    )}
                  </ReadonlyField>
                </ReadonlyBlock>
              </FlexRow>
            )}
            {!!minWorkingHours && (
              <ReadonlyLabel small style={{ marginTop: -8 }}>
                {texts.minWorkingHoursInfo}
              </ReadonlyLabel>
            )}
          </ReadonlyBlock>
        </>
      )}
    </>
  )
}
