import type { ExecutionEventMarker } from '../../../../atoms/executionEventMarkers/executionEventMarkers'

import { mapAtom } from '../../../../atoms/map/map'
import { findLayer } from '../../../utils/findLayer'
import { createExecutionEventFeature } from '../utils/createExecutionEventFeature'

export function addExecutionEventFeature(eventMarker: workwave.DeepReadonly<ExecutionEventMarker>) {
  const map = mapAtom.map
  const layer = findLayer(map, 'executionEvents')

  // create the marker
  const feature = createExecutionEventFeature(eventMarker)
  layer.getSource()?.addFeature(feature)

  return feature
}
