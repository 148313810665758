import { arePointsEqual } from './arePointsEqual'
import { linesIntersect } from './linesIntersect'

export function areSegmentsValid(
  sketchPoint: uui.domain.Point,
  prevSketchPoint: uui.domain.Point,
  nextSketchPoint: uui.domain.Point,
  points: uui.domain.Point[],
): boolean {
  let valid: boolean = true

  const { x: sketchX, y: sketchY } = sketchPoint

  let index: number = -1

  const lastIndex: number = points.length - 1

  const { x: prevX, y: prevY } = prevSketchPoint
  const { x: nextX, y: nextY } = nextSketchPoint

  pointsLoop: for (const point of points) {
    index += 1

    // last point is identical to the first
    if (index === lastIndex) break pointsLoop

    const nextIndex = index + 1
    const nextPoint = points[nextIndex]

    const samePrevSelectedSegment =
      arePointsEqual(point, prevSketchPoint) && arePointsEqual(nextPoint, sketchPoint)
    const sameNextSelectedSegment =
      arePointsEqual(point, sketchPoint) && arePointsEqual(nextPoint, nextSketchPoint)

    if (samePrevSelectedSegment || sameNextSelectedSegment) continue pointsLoop

    const { x: x1, y: y1 } = point
    const { x: x2, y: y2 } = nextPoint

    const intersectPrev = linesIntersect(x1, y1, x2, y2, prevX, prevY, sketchX, sketchY)

    if (intersectPrev.intersection) {
      const isCommonEdge = arePointsEqual(intersectPrev, prevSketchPoint)
      valid = !intersectPrev.colinear && isCommonEdge

      if (!valid) break pointsLoop
    }

    const intersectNext = linesIntersect(x1, y1, x2, y2, sketchX, sketchY, nextX, nextY)

    if (intersectNext.intersection) {
      const isCommonEdge = arePointsEqual(intersectNext, nextSketchPoint)
      valid = !intersectNext.colinear && isCommonEdge

      if (!valid) break pointsLoop
    }
  }

  return valid
}
