import { type SchedulerPro } from '@bryntum/schedulerpro'

import { startSchedulerRouteLoadingStateSync } from './routeLoadingState'
import { startSchedulerLoadingStateSync } from './loadingState'
import { startSchedulerDateFormatSync } from './dateFormat'
import { startShowAbsoluteValuesSync } from './absoluteValues'
import { startSchedulerReadonlySync } from './readonly'
import { startSelectionSync } from './selection'
import { startTimeSpanSync } from './timeSpan'

export function startSchedulerSync(scheduler: SchedulerPro) {
  const unsubscribeTimeSpanSync = startTimeSpanSync(scheduler)
  const unsubscribeSelectionSync = startSelectionSync(scheduler)
  const unsubscribeSchedulerReadonlySync = startSchedulerReadonlySync(scheduler)
  const unsubscribeShowAbsoluteValuesSync = startShowAbsoluteValuesSync(scheduler)
  const unsubscribeSchedulerDateFormatSync = startSchedulerDateFormatSync(scheduler)
  const unsubscribeSchedulerLoadingStateSync = startSchedulerLoadingStateSync(scheduler)
  const unsubscribeSchedulerRouteLoadingStateSync = startSchedulerRouteLoadingStateSync(scheduler)

  return () => {
    unsubscribeTimeSpanSync()
    unsubscribeSelectionSync()
    unsubscribeSchedulerReadonlySync()
    unsubscribeShowAbsoluteValuesSync()
    unsubscribeSchedulerDateFormatSync()
    unsubscribeSchedulerLoadingStateSync()
    unsubscribeSchedulerRouteLoadingStateSync()
  }
}
