import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

/**
 * Update reasons for orders values for the current territory
 */
export const updateTerritorySettings = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  uui.domain.actions.rpc.territory.UpdateTerritorySettingsAction['payload'],
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/territory/updateTerritorySettings', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/territory/updateTerritorySettings', {
      type: 'rpc/territory/updateTerritorySettings',
      category: 'rpc',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {updateTerritorySettings}`,
      { tags: ['rpc', 'territory'], info: { payload, error } },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Update of territory settings failed`,
      error,
    })
  }
})
