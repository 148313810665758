import { IconButton, Box } from '@mui/material'
import { Close } from '@mui/icons-material'

import { Tooltip } from '@/components/primitives/Tooltip'

interface Props {
  title: string
  onClose: () => void
}

export function CloseButton(props: Props) {
  const { title, onClose } = props

  return (
    <Box sx={{ marginLeft: 'auto' }}>
      <Tooltip placement="top" title={title}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
