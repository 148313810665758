import type { NotificationFormType } from '@/notificationForms'

import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Content } from './Content'

interface Props {
  notification: NotificationFormType
  goingToEnable: boolean
  onToggle: () => void
  subtitle: string
  title: string
}

export function Root(props: Props) {
  return (
    <Modal modalId={modalId}>
      <Content {...props} />
    </Modal>
  )
}
