import type { TimerState } from './useTransactionTimer'

import { useEffect, useRef } from 'react'
import { addSeconds, formatDuration, intervalToDuration } from 'date-fns/esm'

import { domainProxy, useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { useEditingState } from '@/atoms'
import { commitTransaction } from '@/features/domain/transaction'
import { setTransactionResolution } from '@/components/Footer/hooks/useTransactionResolution'

import { useTexts } from '../useTexts'
import { useTransactionTimer } from './useTransactionTimer'
import { useDomainDataChanged } from './useDomainDataChanged'

const getTransactionQuote = (
  status: TimerState,
  duration: number,
  elapsedTime: number,
  texts: ReturnType<typeof useTexts>,
) => {
  const getFormat = (time: number) => {
    if (time !== 0 && time % 60 === 0) return ['minutes']
    if (time > 60) return ['minutes', 'seconds']
    return ['seconds']
  }

  switch (status) {
    case 'ready':
      return { title: texts.editSessionOpenTitle, subtitle: texts.editSessionOpenSubtitle }

    case 'paused':
      return { title: texts.editSessionPaused }

    case 'expired':
      return { title: texts.changesSaved }

    case 'running':
      const start = new Date()
      const remainingTime = duration - elapsedTime
      const humanReadableRemainingTime = formatDuration(
        intervalToDuration({ start, end: addSeconds(start, remainingTime) }),
        {
          format: getFormat(remainingTime),
          zero: true,
        },
      )
      return { title: texts.autoSaveChanges(humanReadableRemainingTime) }
  }
}

export function useFooterTransaction(duration: number) {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const domainChanges = useDomainDataChanged()

  const { elapsed, state, progress, pause, resume, reset } = useTransactionTimer({
    autoStart: false,
    duration,
  })
  const timerState = useRef(state)
  const [editingState] = useEditingState()
  const { editing } = editingState

  useEffect(() => {
    reset()
  }, [reset, domainChanges])

  useEffect(() => {
    timerState.current = state
  }, [state])

  useEffect(() => {
    if ((timerState.current === 'paused' || timerState.current === 'ready') && !editing) {
      resume()
      return
    }

    if (timerState.current === 'running' && editing) {
      pause()
      return
    }
  }, [editing, pause, resume])

  useEffect(() => {
    if (state !== 'expired') return

    try {
      const execute = async () => {
        const result = await dispatch(commitTransaction())
        if (!commitTransaction.fulfilled.match(result)) throw new Error()
        setTransactionResolution('commit')
      }
      execute()
    } catch (e) {
      // something went wrong
      toast.error(texts.commitError)
    }
  }, [state, texts, toast, dispatch])

  // When a build routes is in progress, pause the active transaction until is finished
  useEffect(() => {
    return domainProxy.subscribeToNotifications(
      notification => {
        if (notification.notificationType !== 'buildProgress') return

        if (notification.payload === 'completed') {
          resume()
        } else {
          pause()
        }
      },
      ['buildProgress'],
    )
  }, [pause, resume])

  const { title, subtitle } = getTransactionQuote(state, duration, elapsed, texts)

  return {
    subtitle,
    progress,
    elapsed,
    resume,
    pause,
    reset,
    state,
    title,
  }
}
