import { Box, Typography } from '@mui/material'

import { useTexts } from '../../hooks/useTexts'

interface Props {
  companyPhone: string
  showContactPhone: boolean
  // companyEmail: companyEmailField,
  // showContactEmail: checkboxField,
  // useCompanyEmailAsReplyTo: checkboxField,
}

export function CompanyPhone(props: Props) {
  const { companyPhone, showContactPhone } = props

  const texts = useTexts()

  return (
    <Box paddingBottom={4} data-testid={texts.companyPhone} display="flex" flexDirection="column">
      <Typography variant="caption" color="textSecondary">
        {texts.companyPhone}
      </Typography>
      <Typography variant="body1">{companyPhone}</Typography>
      {showContactPhone && (
        <Typography variant="caption" color="textSecondary">
          - {texts.showContactPhone}
        </Typography>
      )}
    </Box>
  )
}
