import type { RouteDetailEntryEnd } from '../../../types'

interface Params {
  driverId: string
  routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable
  shiftEnd?: uui.domain.server.rm.TrackingDataFix
  driverName: string
  lastDriverId: string
  arrivalDepot: string
  routeTrackingData: uui.domain.server.rm.RouteTrackingDataRegistryEntry['routeTrackingData']
  mobileAppsTrackingType: 'ONLY_ORDERSTEPS' | 'ORDERSTEPS_AND_BOUND'
  endsAfterWorkingDayEnd: boolean
}

function getEntryType(
  mobileAppsTrackingType: 'ONLY_ORDERSTEPS' | 'ORDERSTEPS_AND_BOUND',
  driverId: string,
  lastDriverId: string,
  routeTrackingData: uui.domain.server.rm.RouteTrackingDataRegistryEntry['routeTrackingData'],
  shiftEnd?: uui.domain.server.rm.TrackingDataFix,
) {
  if (mobileAppsTrackingType === 'ONLY_ORDERSTEPS') {
    if (driverId === lastDriverId) {
      return 'routeEnd'
    }

    return 'driverEnd'
  }

  if (!routeTrackingData || !shiftEnd) {
    return lastDriverId === driverId ? 'routeEnd' : 'driverEnd'
  }

  return routeTrackingData?.endData.sec === shiftEnd?.sec ? 'routeEnd' : 'driverEnd'
}

export function computeEntryEndData({
  driverId,
  shiftEnd,
  routeInfo,
  driverName,
  arrivalDepot,
  lastDriverId,
  routeTrackingData,
  mobileAppsTrackingType,
  endsAfterWorkingDayEnd,
}: Params): RouteDetailEntryEnd | undefined {
  // Compute end type
  const type = getEntryType(
    mobileAppsTrackingType,
    driverId,
    lastDriverId,
    routeTrackingData,
    shiftEnd,
  )

  // Compute planned time
  const planned = {
    sec: routeInfo.approvedVersion?.routeArrivalSec ?? -1,
    location: arrivalDepot,
  }

  // Compute tracked time
  const tracked = shiftEnd ? { sec: shiftEnd.sec, location: shiftEnd.latLng } : { sec: -1 }

  switch (type) {
    case 'driverEnd':
      if (tracked.sec === -1 && driverName === '-') {
        return
      }
      return { type, data: tracked }

    case 'routeEnd':
      if (planned.sec === -1 && tracked.sec === -1 && driverName === '-') {
        return
      }
      return { type, data: { planned, tracked, endsAfterWorkingDayEnd } }
  }
}
