import styled from 'styled-components'

import { StopWatch } from '@/icons'
import { buttonReset } from '@/styles'
import { Text } from '@/local/components'
import { VerticalLayout } from '@/components/layout'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
}

const ButtonContainer = styled.button`
  ${buttonReset}
`

ButtonContainer.displayName = 'ButtonContainer'

export function ExecutionDetailsButton(props: Props) {
  const texts = useTexts()

  const testid = 'navigoorder-execution-details-button'

  return (
    <ButtonContainer
      onClick={props.onClick}
      data-testid={testid}
      data-trackid={testid}
      className="pendo-navigoorder-execution-details-button"
    >
      <VerticalLayout width={50} justifyContent="center" alignItems="center">
        <StopWatch color="$nightRider" size={17} />

        <Text size="$s" color="$nightRider" textAlign="center">
          {texts.executionDetails}
        </Text>
      </VerticalLayout>
    </ButtonContainer>
  )
}
