import { mapAtom } from '../../atoms/map/map'
import { findLayer } from '../utils/findLayer'

import { changelogRemoveDeviceFeatures } from './utils/changelogRemoveDeviceFeatures'
import { changelogAddDeviceFeatures } from './utils/changelogAddDeviceFeatures'
import { changelogUpdateDeviceFeatures } from './utils/changelogUpdateDeviceFeatures'

export const createUpdateDeviceFeaturesFromChangelog = (
  markers: uui.domain.ui.map.markers.MapMarkers['device'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['device'],
  selection: Set<string>,
  changelog: uui.domain.api.Changelog,
) => {
  // ATTENTION: Currently all changelog are applied as soon as they arrive, also when the map is not visible
  // `ignoreSelection` it will be used to avoid to apply any selection information to the map when applying cached updater functions.
  // after all cached updates will be applied a refresh selection action will be required
  return async (_ignoreSelection: boolean = false) => {
    const map = mapAtom.map
    const layer = findLayer(map, 'device')

    // remove
    await changelogRemoveDeviceFeatures(layer, selection, changelog.remove.values())

    // add
    await changelogAddDeviceFeatures(layer, markers, mapStyles, selection, changelog.add.values())

    // update
    await changelogUpdateDeviceFeatures(
      layer,
      markers,
      mapStyles,
      selection,
      changelog.update.values(),
    )
  }
}
