import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    emptyPlanSubtitle: translate({
      id: 'transactionBar.controls.copyToOperations.emptyPlan.subtitle',
    }),
    emptyPlanTitle: translate({ id: 'transactionBar.controls.copyToOperations.emptyPlan.title' }),
    copyToOperations: translate({ id: 'transactionBar.controls.copyToOperations' }),
    simulationAlert: translate({ id: 'transactionBar.messages.simulationAlert' }),
    openOperations: translate({ id: 'transactionBar.controls.openOperations' }),
    undoDisabled: translate({ id: 'transactionBar.controls.undo.disabled' }),
    redoDisabled: translate({ id: 'transactionBar.controls.redo.disabled' }),
    disabledForBeta: translate({ id: 'global.disabledForBeta' }),
    undo: translate({ id: 'transactionBar.controls.undo' }),
    redo: translate({ id: 'transactionBar.controls.redo' }),
  }))

  return api
}
