import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { MapControls } from '@/components/MapControls'

import { Map } from './components/Map'
import { DriverAssignmentsRoot } from './components/form/DriverAssignmentsRoot'

export function DriverAssignments() {
  return (
    <HorizontalLayout data-testid="setup-driver-assignments-root">
      <DriverAssignmentsRoot />

      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />
          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
