import styled from 'styled-components'
import Dropdown from './Dropdown'
import { Props as DropdownProps } from './typings'

export type Props = DropdownProps<any, any>

const StyledDropdown = styled(Dropdown)`
  position: relative;
  display: flex;
  flex-direction: column;
  outline: none;
  width: 100%;

  .o-dropdown-header {
    display: flex;
    flex-direction: row;
  }

  .o-dropdown-header > * + * {
    margin-left: 2px;
  }

  .o-dropdown-header__input {
    order: 0;
  }

  .o-dropdown-header__prev-btn {
    order: 1;
  }

  .o-dropdown-header__next-btn {
    order: 2;
  }

  .o-dropdown-header__input .o-input-field-text__base-input:disabled {
    cursor: pointer;
  }

  .o-dropdown-header__input .o-input-field-text__base-input::placeholder {
    color: ${p => p.theme.colors.$paleSky};
    font-weight: 400;
  }

  .o-dropdown-list {
  }

  .o-dropdown-footer {
  }

  &.is-disabled {
    pointer-events: none;
  }
`

StyledDropdown.displayName = 'StyledDropdown'

export default StyledDropdown
