import { useMemo } from 'react'

import { RmViewerLinks } from './components/RmViewerLinks'
import { RmGuestLinks } from './components/RmGuestLinks'
import { GpsOnlyLinks } from './components/GpsOnlyLinks'
import { Rm360Links } from './components/Rm360Links'
import { RmLinks } from './components/RmLinks'

interface Props {
  userType: uui.domain.UserType
}

export function HorizontalNav(props: Props) {
  const { userType } = props

  return useMemo(() => {
    switch (userType) {
      case 'rmOnly':
        return <RmLinks />

      case 'rmGuest':
        return <RmGuestLinks />

      case 'gpsOnly':
        return <GpsOnlyLinks />

      case 'courier':
      case 'rmViewer':
        return <RmViewerLinks />

      case 'rm360':
      case 'rm360Legacy':
      case 'rm360VirtualDevicesOnly':
      case 'rm360PhysicalDevicesOnly':
        return <Rm360Links />
    }
  }, [userType])
}
