import { isValid, parse } from 'date-fns/esm'

import * as Texts from '../../../../intl'

import { OrderFormValues, ExtendedOrderValidation } from '../typings'
import validateOrderStep from './validateOrderStep'
import validateLoadField from './validateLoadField'

export default function validateForm(
  workingDayStartSec: number,
  notificationBehavior: uui.domain.client.rm.Territory['notificationBehavior'],
  pickupAndDeliveryFromStart: boolean,
  maxBarcodes: number,
  allowEmptyCustomFields: boolean,
  formInitialValues: OrderFormValues,
  validateEmail: (email: string) => Promise<boolean>,
  validatePristineContactInfo?: boolean,
) {
  return async (order: OrderFormValues): Promise<ExtendedOrderValidation> => {
    const {
      order: { name, eligibility, loads = {} },
      orderStep: { type: orderStepType },
      pickup,
      delivery,
      type,
    } = order

    const validation: ExtendedOrderValidation = {}

    const editingPickupFromPickupAndDelivery: boolean =
      pickupAndDeliveryFromStart && orderStepType === 'p'

    const checkPickup: boolean =
      (!pickupAndDeliveryFromStart && (type === 'p' || type === 'pd')) ||
      editingPickupFromPickupAndDelivery

    const checkDelivery: boolean =
      (!pickupAndDeliveryFromStart && type !== 'p') || !editingPickupFromPickupAndDelivery

    // ------------------------------------------
    // ------------------------------------------
    // ORDER

    // ------------------------------------------
    // name

    if (!name || name.trim().length === 0) {
      if (!validation.order) {
        validation.order = {}
      }

      validation.order.name = Texts.getValidationErrorNameText()
    }

    // ------------------------------------------
    // eligibility

    switch (eligibility.type) {
      case 'by':
        if (!isValid(parse(eligibility.date, 'yyyyMMdd', new Date()))) {
          if (!validation.order) validation.order = {}
          validation.order.eligibility = Texts.getValidationErrorEligibilityDateText()
        }
        break

      case 'on':
        if (eligibility.dates.length === 0) {
          if (!validation.order) validation.order = {}
          validation.order.eligibility = Texts.getValidationErrorEligibilityDatesText()
        }
        break
    }

    // ------------------------------------------
    // loads

    const areLoadsValid: boolean = validateLoadField(loads)

    if (!areLoadsValid) {
      if (!validation.order) {
        validation.order = {}
      }

      validation.order.loads = Texts.getValidationErrorLoadsText()
    }

    // ------------------------------------------
    // PICKUP

    if (checkPickup && pickup) {
      const pickupValidation = await validateOrderStep(
        pickup,
        workingDayStartSec,
        eligibility,
        notificationBehavior,
        maxBarcodes,
        allowEmptyCustomFields,
        formInitialValues,
        validateEmail,
        validatePristineContactInfo,
      )

      if (Object.keys(pickupValidation).length > 0) {
        validation.pickup = pickupValidation
      }
    }

    // ------------------------------------------
    // DELIVERY/SERVICE

    if (checkDelivery && delivery) {
      const deliveryValidation = await validateOrderStep(
        delivery,
        workingDayStartSec,
        eligibility,
        notificationBehavior,
        maxBarcodes,
        allowEmptyCustomFields,
        formInitialValues,
        validateEmail,
        validatePristineContactInfo,
      )

      if (Object.keys(deliveryValidation).length > 0) {
        validation.delivery = deliveryValidation
      }
    }

    return validation
  }
}
