import { useMemo } from 'react'
import { SegmentedMessage } from 'sms-segments-calculator'

type NotificationLimits = Record<string, number>

export function useComputeSegmentsCount(
  limits: NotificationLimits,
  fieldText: string,
  includeUnSubscriptionInstruction?: boolean,
  includeTrackingLink?: boolean,
): number {
  return useMemo(() => {
    let text = fieldText

    if (includeUnSubscriptionInstruction) {
      text += ` ${generateStringWithLength(limits.Unsubscribe ?? 0)}`
    }
    if (includeTrackingLink) {
      text += ` ${generateStringWithLength(limits.TrackingLink ?? 0)}`
    }

    text = replaceTokens(text, limits)

    const segments = new SegmentedMessage(text)
    return segments.segmentsCount
  }, [fieldText, includeUnSubscriptionInstruction, includeTrackingLink, limits])
}

function replaceTokens(inText: string, limits: NotificationLimits) {
  let replacedText = inText

  for (const key in limits) {
    replacedText = replacedText.replace(
      new RegExp(`@@${key}@@`, 'g'),
      generateStringWithLength(limits[key]),
    )
  }
  return replacedText
}

function generateStringWithLength(length: number) {
  return Array(length + 1).join('a')
}
