import styled from 'styled-components'
import ButtonReset from './ButtonReset'

const ButtonDelete = styled(ButtonReset).attrs({ type: 'button' })`
  background: ${p => p.theme.colors.$outrageousRed};

  &:hover {
    background: ${p => p.theme.colors.$pureWhite};
    color: ${p => p.theme.colors.$outrageousRed};
  }
`

ButtonDelete.displayName = 'ButtonDelete'

export default ButtonDelete
