import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectApprovedPlans } from '@/features/domain/routeplan'

export function useApprovedLength(route: uui.domain.client.rm.Route) {
  const { date, vehicleId } = route
  const approvedPlans = useSelector(selectApprovedPlans)
  const approvedPlan = approvedPlans[date]

  return useMemo(() => {
    if (!approvedPlan) return
    const approvedRoute = approvedPlan.approvedRoutes[vehicleId]

    if (!approvedRoute) return

    let approvedLength = 0
    approvedRoute.route.steps.forEach(step => {
      approvedLength += step.distanceToNextMt > 0 ? step.distanceToNextMt : 0
    })

    return approvedLength
  }, [approvedPlan, vehicleId])
}
