import { Entry } from '../Entry'

export function Entry20210121() {
  return (
    <Entry title="01/21/2021 - v3.0.0-alpha1">
      Please note:
      <ul>
        <li>Every area/component is not completed yet and could contain bugs</li>
        <li>Most of UI/Forms actions are not yet invoking the server</li>
      </ul>
      Until now, you can
      <ul>
        <li>Login</li>
        <li>Change territory</li>
        <li>Change active range</li>
        <li>Play with the Simulations</li>
        <li>Play with the Drivers</li>
      </ul>
    </Entry>
  )
}
