import { useCollapsibleState } from '../../../../atoms/collapsibleState'
import { CollapseButton } from '../../../../components/CollapseButton'

import { MaxOrders } from '../fields/MaxOrders'
import { MaxDistance } from '../fields/MaxDistance'
import { MaxDrivingTimeSec } from '../fields/MaxDrivingTimeSec'
import { MaxWorkingTimeSec } from '../fields/MaxWorkingTimeSec'
import { MinDrivingTimeSec } from '../fields/MinDrivingTimeSec'
import { useTexts } from '../hooks/useTexts'

interface Props {
  metric: boolean
}

export function Constraints(props: Props) {
  const { metric } = props
  const [collapsed, toggleCollapsed] = useCollapsibleState('constraints')

  const texts = useTexts()

  return (
    <div className="o-pendo__vehicle-form__constraints">
      <CollapseButton toggleCollapsed={toggleCollapsed} collapsed={collapsed}>
        {texts.constraints}
      </CollapseButton>
      <div hidden={collapsed}>
        <>
          <MaxOrders />
          <MaxDistance metric={metric} />

          <MaxDrivingTimeSec />
          <MaxWorkingTimeSec />

          <MinDrivingTimeSec />
        </>
      </div>
    </div>
  )
}
