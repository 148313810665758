import { FlexBox, Stack } from '@/local/components'
import { useVoiceEventMarkers } from '@/map'

import { NoNotifications } from './components/NoNotifications'
import { OrderStepNotificationItem } from './components/OrderStepNotificationItem'

interface Props {
  notifications: workwave.DeepReadonly<uui.domain.client.rm.OrderStepNotification[]>
  showModal: (data: uui.domain.client.rm.OrderStepNotification) => void
}

export function OrderStepNotificationList(props: Props) {
  const { notifications, showModal } = props
  useVoiceEventMarkers()

  return (
    <FlexBox column vAlignContent="top" hAlignContent="center" padding="16px 32px 32px">
      <Stack space="l">
        {notifications.length > 0 ? (
          notifications.map((item, key) => (
            <OrderStepNotificationItem item={item} key={key} showModal={showModal} />
          ))
        ) : (
          <NoNotifications />
        )}
      </Stack>
    </FlexBox>
  )
}
