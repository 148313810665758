import type { BulkLoadExact } from '../../../../../types'

import { Stack } from '@mui/material'
import { ExactLoad } from './ExactLoad'

import { useFindLoadToFocus } from './hooks/useFindLoadToFocus'

interface Props {
  loads: BulkLoadExact[]
  onDeleteLoad: (name: string) => void
  onUpdateLoad: (name: string, value: string) => void
  invalidLoads?: Record<string, string[]>
  ordersCount: number
}

export function ExactList(props: Props) {
  const { loads, onDeleteLoad, invalidLoads, onUpdateLoad, ordersCount } = props
  const loadToFocus = useFindLoadToFocus(loads)

  if (loads.length === 0) return null

  return (
    <Stack spacing={2}>
      {loads.map(load => {
        return (
          <ExactLoad
            key={load.name}
            load={load}
            onDeleteLoad={onDeleteLoad}
            errors={invalidLoads?.[load.name]}
            onUpdateLoad={onUpdateLoad}
            autoFocus={loadToFocus === load.name}
            ordersCount={ordersCount}
          />
        )
      })}
    </Stack>
  )
}
