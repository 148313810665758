import { FormHelperText } from '@mui/material'

interface Props {
  error?: boolean
  helperText?: string
  errorText?: string
}

export function FieldMessage(props: Props) {
  const { error, errorText, helperText } = props
  return (
    <>
      {!!error && !!errorText && <FormHelperText error>{errorText}</FormHelperText>}
      {!!helperText && !error && <FormHelperText>{helperText}</FormHelperText>}
    </>
  )
}
