import { Typography } from '@mui/material'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export const InvalidBody = () => {
  const texts = useTexts()
  const {
    data: { initialSelection },
  } = useController()

  return (
    <Typography sx={{ paddingY: 3 }} variant="body2">
      {texts.invalidDataEmptyDesc(initialSelection.length)}
    </Typography>
  )
}
