import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    ok: translate({ id: 'importWizardResultModal.confirm' }),

    view: translate({ id: 'importWizardResultModal.lowAccuracyDescription.view' }),

    modalTitle: translate({ id: 'importWizardResultModal.header.title' }),
    ordersImported: (count: number) =>
      translate({ id: 'importWizardResultModal.ordersImported', values: { count } }),
    lowAccuracyOrders: (count: number) =>
      translate({ id: 'importWizardResultModal.lowAccuracyDescription', values: { count } }),
  }))

  return api
}
