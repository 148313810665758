import type { CoreItem, LayoutRendererProps } from '../../typings'

import { Component, ReactNode } from 'react'
import StyledLayoutRenderer from './StyledCollapsedLayoutRenderer'

export type ChipShape = 'squared' | 'rounded'

export interface ExtraLayoutRendererProps {
  chipShape?: ChipShape
  hideNonSelectedChips?: boolean
  onClick?: () => void
  renderSummary?: () => ReactNode
  hideRewind?: boolean
  forceDisableNext?: boolean
  forceDisableNextTitle?: string
  forceDisablePrev?: boolean
  forceDisablePrevTitle?: string
  rewindToFirst?: boolean
  showFastForward?: boolean
  disabled?: boolean
}

export interface Props<T extends CoreItem>
  extends LayoutRendererProps<T, ExtraLayoutRendererProps, any> {
  className?: string
}

export default class CollapsedLayoutRenderer<T extends CoreItem> extends Component<Props<T>> {
  render() {
    // @ts-expect-error
    return <StyledLayoutRenderer {...this.props} />
  }
}
