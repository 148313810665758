import { Box, Button } from '@mui/material'

import { useTexts } from '../../hooks/useTexts'

interface Props {
  onEdit: () => void
}

export function Actions(props: Props) {
  const { onEdit } = props

  const texts = useTexts()

  return (
    <Box display="flex" paddingBottom={4}>
      <Button
        data-testid="edit-button"
        data-trackid="edit-button"
        className="pendo-notification-preferences-edit-button"
        onClick={onEdit}
        variant="text"
        color="primary"
        size="small"
      >
        {texts.edit}
      </Button>
    </Box>
  )
}
