import type { ExecutionEventsFormValues } from '../../typings'

import { useField } from 'react-final-form'

import { Block, Label, ReadonlyLabel, RestoreFieldButton } from '@/forms-legacy'
import { isDeepEqual } from '@/server-data'

import { useTexts } from './useTexts'
import { CustomFields } from './CustomFields'

const testId = 'custom-fields-field'

function identity(a: ExecutionEventsFormValues['customFields']) {
  return a
}

const fieldConfig = { parse: identity, isEqual: isDeepEqual }

export function CustomFieldsField() {
  const formProps = useField('customFields', fieldConfig)
  const texts = useTexts()

  const {
    input: { value, onChange },
    meta: { error, dirty, initial },
  } = formProps

  const handleOnChange = (key: string, customFieldValue: string) => {
    onChange({ ...value, [key]: { ...value?.[key], value: customFieldValue } })
  }

  return (
    <Block error={!!error} dirty={!!dirty} data-testid={testId}>
      <Label>
        <ReadonlyLabel style={{ paddingLeft: 0 }} primary>
          {texts.title}
        </ReadonlyLabel>

        <RestoreFieldButton
          dirty={dirty}
          initial={initial}
          onChange={onChange}
          style={{ marginLeft: 12 }}
        />
      </Label>
      <CustomFields onChange={handleOnChange} value={value} error={error} />
    </Block>
  )
}
