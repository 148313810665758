import { useEffect, useState } from 'react'

export function useOpenWithDelay(delay: number = 500) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    let effectCleaned = false

    const timer = setTimeout(() => {
      if (effectCleaned) return

      setOpen(true)
    }, delay)

    return () => {
      effectCleaned = true
      clearTimeout(timer)
    }
  }, [delay])

  return open
}
