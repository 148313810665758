import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectTerritory } from '@/features/domain/territory'

export function useTimezone() {
  const territory = useSelector(selectTerritory)

  return useMemo(() => territory.timeZoneCode, [territory])
}
