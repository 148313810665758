import type { MouseInteractionSubscriber } from '../../MouseInteraction'

import Feature from 'ol/Feature'

import { getOrderFeatureMetadata } from '../../../layers/order/orderFeatureMetadata'
import { getFeatureMetadata } from '../../../layers/featureMetadata'

import { doubleClickDepots } from './utils/doubleClickDepots'
import { doubleClickOrders } from './utils/doubleClickOrders'
import { doubleClickPlaces } from './utils/doubleClickPlaces'
import { doubleClickDevices } from './utils/doubleClickDevices'
import { doubleClickRegions } from './utils/doubleClickRegions'
import { doubleClickVehicles } from './utils/doubleClickVehicles'
import { doubleClickRoadSegment } from './utils/doubleClickRoadSegment'
import { doubleClickTrafficAreas } from './utils/doubleClickTrafficAreas'

export const subscribeToMouseDoubleClick: MouseInteractionSubscriber = (
  features,
  _layersByFeature,
  _modifiers,
  _mapBrowserEvent,
) => {
  if (features.length === 0) return

  // currently only single feature double-click is supported through mouse-click interactions
  const feature = features[0]

  // narrow FeatureLike to Feature
  if (!(feature instanceof Feature)) return

  const featureType = getFeatureMetadata(feature, 'type')

  switch (featureType) {
    case 'order':
      doubleClickOrders(getOrderFeatureMetadata(feature, 'ids'))
      break

    case 'depot':
      doubleClickDepots(feature.getId() as string | undefined)
      break

    case 'region':
      doubleClickRegions(feature.getId() as string | undefined)
      break

    case 'roadSegment':
      doubleClickRoadSegment(feature.getId() as string | undefined)
      break

    case 'trafficProfile':
      doubleClickTrafficAreas(feature.getId() as string | undefined)
      break

    case 'place':
      doubleClickPlaces(feature.getId() as string | undefined)
      break

    case 'device':
      doubleClickDevices(feature.getId() as string | undefined)
      break

    case 'vehicle':
      doubleClickVehicles(feature.getId() as string | undefined)
      break

    // TODO: add other supported double-clickable features
    default:
      break
  }
}
