import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Cornering(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 26 26">
        <path d="M13 0C5.839844 0 0 5.839844 0 13s5.839844 13 13 13 13-5.839844 13-13S20.160156 0 13 0zm0 3c5.01953 0 9.14453 3.65625 9.875 8.46875C17.070313 11.210938 16.67578 9 13 9c-3.67578 0-3.996094 2.210938-9.875 2.46875C3.85547 6.65625 7.98047 3 13 3zm0 7.59375c1.30078 0 2.40625 1.10547 2.40625 2.40625S14.30078 15.40625 13 15.40625 10.59375 14.30078 10.59375 13 11.69922 10.59375 13 10.59375zM3.1875 15H8c1.601563 0 3 1.5 3 3v4.8125C7.0625 22.023438 3.976563 18.9375 3.1875 15zM18 15h4.8125c-.789062 3.9375-3.875 7.023438-7.8125 7.8125V18c0-1.5 1.5-3 3-3z" />
      </svg>
    </IconContainer>
  )
}
