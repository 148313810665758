import type { MouseEvent } from 'react'

import { useCallback } from 'react'

import {
  DropdownMenuItem,
  DropdownTextItem,
  DropdownListSeparator,
} from '@/components/DropdownMenu'
import { TransactionInProgressTooltip } from '@/components/smartUtils/plan/TransactionInProgressTooltip'
import { CollapseText } from '@/components/CollapseText'
import { Text } from '@/local/components'

import { useTexts } from '../../useTexts'
import { useChangeTerritory } from './hooks/useChangeTerritory'

interface Props {
  setOpen: (open: boolean) => void
  territories: uui.domain.client.rm.Territory[]
  hasTerritoryMenuOptions: boolean
}

export function TerritoryButtons(props: Props) {
  const { territories, setOpen, hasTerritoryMenuOptions } = props

  const changeTerritory = useChangeTerritory()
  const texts = useTexts()

  const onClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setOpen(false)

      if (event.currentTarget.dataset.id) {
        changeTerritory(event.currentTarget.dataset.id)
      }
    },
    [changeTerritory, setOpen],
  )

  return (
    <>
      <DropdownTextItem>
        <Text color="$paleSky" size="$h4" uppercase>
          {texts.territories}
        </Text>
      </DropdownTextItem>

      <div data-testid="territoryDropdown__territories-list">
        {territories.map(territory => (
          <TransactionInProgressTooltip
            key={territory.id}
            placement="left"
            render={preventEditing => (
              <DropdownMenuItem
                disabled={preventEditing}
                data-id={territory.id}
                onClick={onClick}
                testid="territoriesList-territoryItem"
              >
                <CollapseText finalCharsToKeep={10}>{territory.name}</CollapseText>
              </DropdownMenuItem>
            )}
          />
        ))}
      </div>

      {hasTerritoryMenuOptions && <DropdownListSeparator />}
    </>
  )
}
