import type { ChangeEvent } from 'react'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns/esm'

import { importSomeOrdersFromSimulation } from '@/features/domain/simulation'
import { selectUserConfiguration } from '@/features/domain/user'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useController } from './useController'

export function useControllerActions() {
  const userConfiguration = useSelector(selectUserConfiguration)
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const {
    data: { mode, selection, orderStepIds },
    updateData,
    update,
    close,
  } = useController()

  const onModeChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, mode: 'replace' | 'add') => {
      updateData({ mode })
    },
    [updateData],
  )

  const onSelectionChange = useCallback(
    (selection: Date) => {
      updateData({ selection })
    },
    [updateData],
  )

  const onConfirm = useCallback(async () => {
    try {
      update({ status: 'submitting' })

      // Since the actions require moving to Operations we need to close the modal,
      // otherwise the modal will disappear and appear again
      close?.()

      if (userConfiguration.planType !== 'simulation') {
        throw new Error('The planType must be Simulation when exporting to Operations ')
      }

      const result = await dispatch(
        importSomeOrdersFromSimulation({
          date: format(selection, 'yyyyMMdd'),
          simulationId: userConfiguration.planId,
          orderStepIds,
          mode,
        }),
      )

      if (importSomeOrdersFromSimulation.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [userConfiguration, orderStepIds, selection, dispatch, update, close, toast, mode])

  return { onModeChange, onSelectionChange, onConfirm }
}
