import { useCallback } from 'react'
import { Typography } from '@mui/material'

import { useTexts } from '../hooks/useTexts'
import { useController } from '../hooks/useController'

export const InvalidBody = () => {
  const texts = useTexts()
  const {
    status,
    data: { initiallySelectedRoutes },
  } = useController()

  const initiallySelectedRoutesCount = initiallySelectedRoutes.length

  const getDescription = useCallback(() => {
    switch (status) {
      case 'invalidForEmptyRoutes':
        return texts.invalidDataRoutesEmptyDesc(initiallySelectedRoutesCount)
      case 'invalidForSimulationEnvironment':
        return texts.invalidDataSimulationEnvDesc
      default:
        return ''
    }
  }, [status, texts, initiallySelectedRoutesCount])

  return (
    <Typography sx={{ paddingY: 3 }} variant="body2">
      {getDescription()}
    </Typography>
  )
}
