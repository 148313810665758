import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
} from '@mui/material'

import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { useState } from 'react'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useController } from '../../../../../hooks/useController'
import { useControllerActions } from '../../../../../hooks/useControllerActions'
import { useTexts } from '../../../../../useTexts'

export function Advanced() {
  const [expanded, setExpanded] = useState(false)
  const texts = useTexts()

  const {
    data: { keepEnglishColumns, customizeColumns },
  } = useController()

  const { onChangeKeepEnglishColumns } = useControllerActions()

  const advancedButtonDisabled = !customizeColumns
  const advancedButtonTooltipDisabled = !advancedButtonDisabled

  const ArrowIcon = expanded ? KeyboardArrowUp : KeyboardArrowDown

  return (
    <Box pl={4}>
      <Stack alignItems="flex-start">
        <Tooltip
          disabled={advancedButtonTooltipDisabled}
          placement="bottom"
          title={texts.advancedDisabledTooltip}
        >
          <Button
            variant="text"
            onClick={() => setExpanded(p => !p)}
            disabled={advancedButtonDisabled}
            sx={{ justifyContent: 'flex-start', width: '100%' }}
            data-testid="export-route-territory-export-advanced-submenu"
            data-trackid="export-advanced-submenu"
            fullWidth={false}
          >
            {texts.advanced.title}
            <ArrowIcon sx={{ mr: 1 }} />
          </Button>
        </Tooltip>

        <Accordion expanded={expanded}>
          <AccordionSummary sx={{ display: 'none' }} />
          <AccordionDetails>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={keepEnglishColumns}
                    onChange={(_, checked) => onChangeKeepEnglishColumns(checked)}
                    data-testid="export-route-territory-export-keep-english-columns"
                  />
                }
                label={texts.advanced.keepEnglishColumns}
              />
              <FormHelperText sx={{ ml: 4, mb: 0, mt: -1 }}>
                {texts.advanced.keepEnglishColumnsDescription}
              </FormHelperText>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Box>
  )
}
