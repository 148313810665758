import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Fork(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 12 12">
        <path d="M0 0l.744 4.667 1.312-1.313 1.167 1.167c.42.42.787.942 1.02 1.502.187-.7.515-1.404.934-2.056-.186-.281-.412-.51-.598-.744L3.383 2.027 4.667.744 0 0zm11.2 0L6.533.744l1.298 1.298-1.166 1.166a6.802 6.802 0 0 0-1.998 4.813V11.2h1.866V8.02c0-1.293.513-2.538 1.459-3.485L9.158 3.37l1.298 1.298L11.2 0z" />
      </svg>
    </IconContainer>
  )
}
