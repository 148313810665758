import { styled } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'
import { DropdownMenuItem } from '@/components/DropdownMenu'

import { useRoutingLayoutMenu } from '../hooks/useRoutingLayoutMenu'
import { useTexts } from '../useTexts'

const Icon = <OpenInNew fontSize="small" />

const ExtractedMapDropdownMenuItem = styled(DropdownMenuItem)`
  /* Empirically set to get the item as tall as the NestedDropDown component used by ExtractedMapSubMenu */
  height: 35px;
`

export function ExtractMapItem() {
  const texts = useTexts()
  const { openExtractedMap } = useRoutingLayoutMenu()

  return (
    <ExtractedMapDropdownMenuItem
      onClick={openExtractedMap}
      data-testid="header__layout-menu-extracted-map"
      Icon={Icon}
    >
      {texts.extractMap}
    </ExtractedMapDropdownMenuItem>
  )
}
