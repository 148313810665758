import type { EditingStateContext } from '@/atoms'

import { updateOrderFeaturesByEditingState } from '../../layers/order/updateOrderFeaturesByEditingState'
import { getDomainMarkerData } from '../../atoms/markerChangelog/domain/getDomainMarkerData'
import { getMap } from '../../atoms/map/utils/getMap'

export function applyMapEditingState(
  ctx: EditingStateContext,
  editingEntities: workwave.DeepReadonly<string[]>,
) {
  const map = getMap()
  const { mapMarkers, mapStyles, extendedOrderSteps } = getDomainMarkerData()

  // hide selected entities when required
  switch (ctx) {
    case 'order': {
      updateOrderFeaturesByEditingState(
        map,
        editingEntities,
        mapMarkers.order,
        mapStyles.order,
        extendedOrderSteps,
      )
      break
    }

    case 'region':
    case 'place':
    case 'geofence':
    case 'segment': {
      // TODO: add logic when the map layers is available
      // TODO: add logic when the map layers is available
      break
    }

    // all other entities don't require to hide anything when selected
    default:
      break
  }
}
