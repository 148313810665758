import type { ReactNode } from 'react'
import type { Theme } from '@/local/components'
import type { Store } from '@/store'

import { TidalThemeProvider } from '@workwave-tidal/styles'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { StyledEngineProvider } from '@mui/material'
import { MuiTheme } from '@/styles'

import { WarningOverlay } from '../components/WarningOverlay'
import { AppIntlProvider } from './AppIntlProvider'

type SharedProvidersProps = {
  theme: Theme
  store: Store
  children?: ReactNode
}

/**
 * Wraps the children with all the providers of RouteManager.
 */
export function SharedProviders(props: SharedProvidersProps) {
  const { store, theme } = props

  return (
    <Provider store={store}>
      <AppIntlProvider>
        <StyledEngineProvider injectFirst>
          <TidalThemeProvider theme={MuiTheme}>
            <ThemeProvider theme={theme}>
              <>
                {props.children}
                <WarningOverlay />
              </>
            </ThemeProvider>
          </TidalThemeProvider>
        </StyledEngineProvider>
      </AppIntlProvider>
    </Provider>
  )
}
