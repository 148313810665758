import { intl } from '@/intl'

export function renderText(field: 'enabled' | 'disabled'): string {
  switch (field) {
    case 'enabled':
      return intl.translate({ id: 'global.enabled' })

    case 'disabled':
      return intl.translate({ id: 'global.disabled' })

    default:
      return field
  }
}
