import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Star(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M15.967988 5.758693c-.07323-.232318-.27777-.40403-.520189-.439383l-4.744829-.689377L8.579286.329537c-.217166-.439383-.93937-.439383-1.15906 0l-2.12116 4.300396-4.744829.689377c-.242418.035352-.446958.20454-.522714.439383-.075755.234842-.012626.492412.164137.661599l3.434257 3.3484-.810586 4.724628c-.040403.242418.05808.489886.25757.633822.19949.143936.462109.164138.679275.047979l4.244842-2.229742 4.242317 2.229742c.095958.050503.19949.07323.303023.07323.133835 0 .265144-.040403.378778-.121209.19949-.14646.297972-.391404.25757-.633822l-.810586-4.724628 3.434256-3.3484c.176763-.171713.237368-.426757.161612-.6616z" />
      </svg>
    </IconContainer>
  )
}
