import styled from 'styled-components'

import { FlexColumn } from '@/forms-legacy'

import StepButton from './StepButton'

const StepControlsContainer = styled(FlexColumn)`
  position: relative;
  width: 24px;
  height: calc(100% - 2px);
  order: 2;
  margin-right: 1px;

  ${StepButton}  {
    position: absolute;
    left: 0;
    width: 100%;
  }

  ${StepButton}:first-child {
    top: 0;
    border-top-right-radius: 4px;
  }

  ${StepButton}:last-child {
    bottom: 0;
    border-bottom-right-radius: 4px;
  }
`

StepControlsContainer.displayName = 'StepControlsContainer'
export default StepControlsContainer
