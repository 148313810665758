import styled from 'styled-components'

const styledComponent = styled.span`
  color: ${p => p.theme.colors.$silver};
  font-weight: ${p => p.theme.weights.$regular};
  padding-left: 2px;
`

styledComponent.displayName = 'RadioLabelExtraText'
export default styledComponent
