import {
  SecondaryColumnContentHorizontalRuler,
  SecondaryColumnContentContainer,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'
import { useIntl } from '@/intl'
import { durationUtils } from '@/server-data'

import { useTexts } from './hooks/useTexts'

interface Props {
  additionalDrivingTimeSec: number
  malus: number
  mixed?: boolean
}

export function ViewDetails(props: Props) {
  const { additionalDrivingTimeSec, malus, mixed } = props

  const texts = useTexts()
  const { translate } = useIntl()
  const formatDurationSec = durationUtils.formatSeconds(translate)

  if (mixed) {
    return (
      <SecondaryColumnContentContainer>
        <SecondaryColumnContentHorizontalRuler bottomSpace />

        <ReadonlyBlock half>
          <ReadonlyLabel primary>{texts.segmentType}</ReadonlyLabel>

          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{texts.multipleValues}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      </SecondaryColumnContentContainer>
    )
  }

  return (
    <SecondaryColumnContentContainer>
      <SecondaryColumnContentHorizontalRuler bottomSpace />

      {malus > 0 && (
        <ReadonlyBlock half>
          <ReadonlyLabel primary>{texts.segmentType}</ReadonlyLabel>
          <ReadonlyLabel>{texts.singleMalus}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{texts.blocked}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      )}

      {additionalDrivingTimeSec > 0 && (
        <ReadonlyBlock half>
          <ReadonlyLabel primary>{texts.segmentType}</ReadonlyLabel>
          <ReadonlyLabel>{texts.additionalTime}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>
                {formatDurationSec(additionalDrivingTimeSec, 'SHORT')}
              </ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      )}

      {additionalDrivingTimeSec === 0 && malus === 0 && (
        <ReadonlyBlock half>
          <ReadonlyLabel primary>{texts.segmentType}</ReadonlyLabel>
          <ReadonlyLabel>{texts.singleDisabled}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{texts.disabled}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      )}
    </SecondaryColumnContentContainer>
  )
}
