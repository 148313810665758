import { renderToStaticMarkup } from 'react-dom/server'
import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'

import { intl } from '@/intl'

import { TimeLeftCell, TimeLeftTooltip } from '../../../components/cells/TimeLeftCell'

export function createTimeLeftColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    text: intl.translate({ id: 'rm.scheduler.column.timeLeft' }),
    type: 'template',
    width,
    field: 'timeLeft',
    editor: false,
    region: 'cols',
    hidden,

    template: ({ record, field, value }) => {
      return renderToStaticMarkup(
        <TimeLeftCell record={record as ResourceModel} field={field} value={value} />,
      )
    },

    resizable: true,
    groupable: false,

    tooltipRenderer: ({ record }) => {
      const available = record.getData(
        'available',
      ) as uui.domain.client.rm.SchedulerResource['available']

      if (!available) return ''

      return renderToStaticMarkup(<TimeLeftTooltip record={record as ResourceModel} />)
    },
  }
}
