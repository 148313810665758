import type { RoadSegmentFormValidation } from '../types'

import { useTexts } from './useTexts'

export const useComposeFieldsWithError = () => {
  const texts = useTexts()

  return (errors: RoadSegmentFormValidation) => {
    return Object.keys(errors).reduce<string[]>((acc, field) => {
      switch (field) {
        case 'name':
          acc.push(texts.labelName)
          return acc

        case 'additionalDrivingTimeSec':
          acc.push(texts.labelAdditionalDrivingTimeSec)
          return acc

        default:
          return acc
      }
    }, [])
  }
}
