import type XYZ from 'ol/source/XYZ'

import { mapAtom } from '../map'

import { createTileLayer } from './createTileLayer'
import { getTilesLayer } from './getTilesLayer'
import { getTileUrl } from './tiles'

export function createOrUpdateTilesLayer(lng?: string) {
  let tilesLayer = getTilesLayer()

  const { tile: tileType, authenticationToken } = mapAtom

  if (!authenticationToken) {
    throw new Error(`The authentication token is not valid.`)
  }

  if (tilesLayer) {
    const source = tilesLayer.getSource() as XYZ

    if (!source) {
      throw new Error(`The Map Tile Source is not valid.`)
    }

    if (!authenticationToken) {
      throw new Error(`The authentication token is not valid.`)
    }

    source.setUrl(getTileUrl({ tileType }))
  } else {
    // initialize Map layer only once
    tilesLayer = createTileLayer({
      // use static reference to tile type value for initial creation
      tileType,
      tileSize: 256,
      lng,
    })

    tilesLayer.set('uid', 'tilesLayer')

    // add it to the map below all the other layers
    mapAtom.map.getLayers().insertAt(0, tilesLayer)
  }
}
