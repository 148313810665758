import type { Props } from '../typings'
import { useRef, useCallback, useMemo, useEffect } from 'react'

import {
  clearExecutionEventMarkers,
  setExecutionEventMarkers,
  useExecutionEventMarkers,
  usePendingFitMapOnMount,
  useActivePodMarker,
  togglePoDMarker,
  fitMap,
} from '@/map'

export function useExecutionEventTab(props: Props) {
  const { order, tab } = props

  const activePodMarker = useActivePodMarker()
  const eventMarkers = useExecutionEventMarkers(order)
  const executionEventPositions = useMemo(
    () => eventMarkers.map(marker => marker.latLng),
    [eventMarkers],
  )

  const podRef = useRef(activePodMarker)
  useEffect(() => void (podRef.current = activePodMarker), [activePodMarker])

  // toggle active PoD map marker
  const handleUpdateExecutionEventPodMarkers = useCallback(
    (podUid: string, latLng: uui.domain.LatLng) => togglePoDMarker(podUid, latLng),
    [],
  )

  const onSetPodOnMap = useCallback(
    (uuid: string, latLng?: uui.domain.LatLng) => {
      if (latLng) {
        handleUpdateExecutionEventPodMarkers(uuid, latLng)

        if (podRef.current?.uid !== uuid) {
          fitMap([latLng], { minResolution: 3, preventIfVisible: true }, 'clear')
        }
      }
    },
    [handleUpdateExecutionEventPodMarkers],
  )

  // When the active tab change manage visibility of execution event markers and fitMap when required
  useEffect(() => {
    const podTab = 1

    setExecutionEventMarkers({ visible: tab === podTab ? true : false })

    if (tab === podTab && executionEventPositions.length > 0) {
      fitMap(executionEventPositions, { minResolution: 3, preventIfVisible: true }, 'clear')
    }
  }, [tab, executionEventPositions])

  useEffect(() => {
    // reset event markers on unmount
    return () => {
      setExecutionEventMarkers({ visible: false })
      clearExecutionEventMarkers()
    }
  }, [])

  // try to fit pending on mount
  usePendingFitMapOnMount()

  return {
    activePodMarker,
    onSetPodOnMap,
  }
}
