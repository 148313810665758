import { ReactNode, useCallback } from 'react'
import { ReadonlyBlock, ReadonlyField, ReadonlyFieldRow, ReadonlyLabel } from '@/forms-legacy'
import { parseNotificationAddress } from '@/utils'
import { useTexts } from '../hooks/useTexts'

interface Props {
  notificationAddresses: string[]
}
export function NotificationAddresses(props: Props) {
  const { notificationAddresses } = props

  const texts = useTexts()

  const renderAddresses = useCallback(() => {
    if (notificationAddresses.length === 0) {
      return null
    }

    return notificationAddresses.map((address: string, index: number): ReactNode => {
      const { address: notificationAddress } = parseNotificationAddress(address)

      return (
        <ReadonlyField key={`notificationAddress_${index}`}>
          <ReadonlyFieldRow>
            <ReadonlyLabel>{notificationAddress}</ReadonlyLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      )
    })
  }, [notificationAddresses])

  return (
    <ReadonlyBlock>
      <ReadonlyLabel>{texts.alertContacts}</ReadonlyLabel>
      {renderAddresses()}
    </ReadonlyBlock>
  )
}
