import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    vehicles: (count: number) => translate({ id: 'global.plural.vehicles', values: { count } }),
    all: translate({ id: 'global.all' }),
    violated: translate({ id: 'rm.summary.violated' }),
    violatedTooltip: translate({ id: 'rm.summary.vehicle.violated.title' }),
    vehiclesTooltip: translate({ id: 'rm.summary.vehicles.title' }),
  }))

  return api
}
