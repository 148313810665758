/**
 * Retrieve a Traffic Area Marker by its ID or throws an Error if it cannot be found
 */
export function findTrafficAreaMarker(
  markers: Record<string, uui.domain.ui.map.markers.Area>,
  areaId: string,
) {
  const marker = markers[areaId]

  if (!marker) {
    throw new Error(`Unknown TrafficArea Map Marker with ID: ${areaId}`)
  }

  return marker
}
