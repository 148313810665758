import { useMemo } from 'react'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'
import { FormatDateTime } from '@/components/smartUtils/time/FormatDateTime'

import { useTexts } from './hooks/useTexts'

interface Props {
  timestamp: number
}

export function EventTime(props: Props) {
  const { timestamp } = props

  const texts = useTexts()

  const date = useMemo(() => new Date(timestamp), [timestamp])

  return (
    <ReadonlyBlock half>
      <ReadonlyLabel>{texts.eventDate}</ReadonlyLabel>
      <ReadonlyField>
        <ReadonlyFieldRow>
          <ReadonlyFieldLabel>
            <FormatDateTime source={date} />
          </ReadonlyFieldLabel>
        </ReadonlyFieldRow>
      </ReadonlyField>
    </ReadonlyBlock>
  )
}
