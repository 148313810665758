import { ResourceModel } from '@bryntum/schedulerpro'
import { schedulerPreferencesAtom } from '../../schedulerPreferences'

export function ordersDoneSorter(item1: ResourceModel, item2: ResourceModel) {
  const ordersExecutedDone1 = item1.getData(
    'ordersExecutedDone',
  ) as uui.domain.client.rm.SchedulerResource['ordersExecutedDone']

  const ordersExecutedDone2 = item2.getData(
    'ordersExecutedDone',
  ) as uui.domain.client.rm.SchedulerResource['ordersExecutedDone']

  if (schedulerPreferencesAtom.preferences.showAbsoluteValues) {
    return ordersExecutedDone1.absolute - ordersExecutedDone2.absolute
  }

  return ordersExecutedDone1.percentage - ordersExecutedDone2.percentage
}
