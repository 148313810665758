import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectTerritoryLicensingLimits } from '@/features/domain/territory'
import { selectVehicles } from '@/features/domain/vehicle'

// return how many vehicles can be tracked via GPS
export function useCountRemainingTrackedVehicle() {
  const { trackedVehicles } = useSelector(selectTerritoryLicensingLimits)

  const vehiclesData = useSelector(selectVehicles)

  return useMemo(() => {
    const unifiedVehicles = Object.values(vehiclesData)
    let currentlyTrackedVehiclesCount = 0

    for (const unifiedVehicle of unifiedVehicles) {
      if (unifiedVehicle.hasRoutingLicense && unifiedVehicle.vehicle.tracked) {
        currentlyTrackedVehiclesCount += 1
      }
    }

    return trackedVehicles - currentlyTrackedVehiclesCount
  }, [vehiclesData, trackedVehicles])
}
