export const processSubtractiveSelection = (
  prevSelection: string[],
  nextSelection: string[],
): string[] => {
  if (nextSelection.length === 1) {
    // Single ID selected

    const id = nextSelection[0]
    const targetIndex = prevSelection.indexOf(id)

    // Removes it
    if (targetIndex > -1) {
      prevSelection.splice(targetIndex, 1)
    }

    return prevSelection
  } else {
    // Multiple IDs selected
    const idsSet = new Set(prevSelection)

    for (const id of nextSelection) {
      idsSet.delete(id)
    }

    return [...idsSet]
  }
}
