import styled from 'styled-components'

import FlexColumn from '../../lib/FlexColumn'

const LocationEditorFieldWrapper = styled(FlexColumn)`
  height: 100%;
  background: ${p => p.theme.colors.$pureWhite};
  border: 2px solid ${p => p.theme.colors.$scienceBlue};
  padding: 0 10px;
  border-radius: 5px;
  outline: 0;

  > * {
    height: 100%;
  }
`

LocationEditorFieldWrapper.displayName = 'LocationEditorFieldWrapper'
export default LocationEditorFieldWrapper
