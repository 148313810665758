import type { MonthData, YYYYMMDD } from '../typings'

import { useMemo } from 'react'
import {
  addDays,
  subDays,
  startOfMonth,
  endOfMonth,
  getDay,
  format,
  addMonths,
  min,
  max,
  isSameMonth,
  getDate,
  isAfter,
} from 'date-fns/esm'

import { useDateFnsOptions } from '@/hooks'
import { parseDate } from '../utils/parseDate'

const daysInAWeek = 7

export const useMonths = (
  firstMonthReferenceDate: Date,
  showNeighboringMonth: boolean = false,
  monthsCount: number = 1,
  minDateAsString?: YYYYMMDD,
  maxDateAsString?: YYYYMMDD,
  labelFormat: string = 'EEEEEE',
): MonthData[] => {
  const options = useDateFnsOptions()

  return useMemo<MonthData[]>(() => {
    const result: MonthData[] = []
    const { weekStartsOn } = options

    let referenceDate = firstMonthReferenceDate

    const minDate = minDateAsString ? parseDate(minDateAsString) : undefined
    const maxDate = maxDateAsString ? parseDate(maxDateAsString) : undefined

    for (let i = 0; i < monthsCount; i++) {
      const startOfMonthDate = startOfMonth(referenceDate)
      const endOfMonthDate = endOfMonth(referenceDate)

      if (maxDate && isAfter(startOfMonthDate, maxDate)) {
        continue
      }

      const firstDay =
        minDate && isSameMonth(minDate, startOfMonthDate)
          ? max([startOfMonthDate, minDate])
          : startOfMonthDate

      // Let's calculate the days between the start of the month and the start of the week
      let daysToStartOfWeek = 0
      while (getDay(subDays(startOfMonthDate, daysToStartOfWeek)) !== weekStartsOn) {
        daysToStartOfWeek++
      }

      // Let's calculate the days between the first day and the start of the month
      const daysToStartOfMonth = getDate(firstDay) - 1

      // The final offset is the sum of daysToStartOfWeek and daysToStartOfMonth
      const prevOffset = showNeighboringMonth ? 0 : daysToStartOfWeek + daysToStartOfMonth

      const lastDay =
        maxDate && isSameMonth(maxDate, startOfMonthDate)
          ? min([endOfMonthDate, maxDate])
          : endOfMonthDate

      const nextOffset = showNeighboringMonth ? 0 : (7 - weekStartsOn) % daysInAWeek

      const start = showNeighboringMonth ? subDays(firstDay, prevOffset) : firstDay
      const end = showNeighboringMonth ? addDays(lastDay, nextOffset) : lastDay

      const label = format(referenceDate, labelFormat, options)
      const id = firstDay.toISOString()

      const offset = [prevOffset, nextOffset] as const
      referenceDate = addMonths(referenceDate, 1)

      result.push({
        id,
        label,
        start,
        end,
        offset,
      })
    }

    return result
  }, [
    firstMonthReferenceDate,
    showNeighboringMonth,
    options,
    monthsCount,
    minDateAsString,
    maxDateAsString,
    labelFormat,
  ])
}
