import { useSelector } from 'react-redux'
import { useEffect } from 'react'

import { useFormApi, useFormField } from '@workwave-tidal/tidal/form-fairy'
import { selectGpsTrackingProvider } from '@/features/domain/user'
import { type FormErrors, type FormFields } from '../../../formFields'

export function useCoordinateFields(creating: boolean) {
  const formApi = useFormApi<FormFields, FormErrors>()
  const {
    field: { value: trackDriver },
  } = useFormField<'trackDriver', FormFields, FormErrors>('trackDriver')
  const trackingProvider = useSelector(selectGpsTrackingProvider)

  useEffect(() => {
    formApi.setFieldVisibleState('new-password', creating)
  }, [formApi, creating])

  useEffect(() => {
    formApi.setFieldVisibleState('gpsDeviceId', trackDriver && trackingProvider !== 'telematics')
  }, [formApi, trackDriver, trackingProvider])

  useEffect(() => {
    formApi.setFieldVisibleState('trackDriver', trackingProvider !== 'telematics')
  }, [formApi, trackingProvider])
}
