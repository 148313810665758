import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    name: translate({ id: 'vehicles.form.edit.generalSettings.fields.name.label' }),
    color: translate({ id: 'vehicles.form.edit.generalSettings.fields.color.label' }),
    colorInfo: translate({ id: 'vehicles.form.edit.generalSettings.fields.color.info' }),
    globalEdit: translate({ id: 'global.edit' }),
    globalCancel: translate({ id: 'global.cancel' }),
    globalChange: translate({ id: 'global.change' }),
    headerTitle: translate({ id: 'vehicles.form.edit.generalSettings.trackingSettings.title' }),
    headerSubtitle: translate({ id: 'vehicles.form.edit.generalSettings.trackingSettings.info' }),
    tracked: translate({ id: 'vehicles.form.edit.generalSettings.fields.tracked.label' }),
    trackedWarning: translate({
      id: 'vehicles.form.edit.generalSettings.fields.tracked.reachLimit',
    }),
    liveEta: translate({ id: 'vehicles.form.edit.generalSettings.fields.liveEta.label' }),
  }))

  return api
}
