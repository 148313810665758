import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { clearPolylinesQueue, fetchRoutePolylines } from '@/features/domain/route'
import { selectRoutesInfoInRange } from '@/features/domain/scheduler'
import { useAppDispatch } from '@/store'

export function PolylineTester() {
  const routeInfos = useSelector(selectRoutesInfoInRange)
  const dispatch = useAppDispatch()

  const onClick = useCallback(async () => {
    const routeIds = Object.keys(routeInfos)
    const result = await dispatch(fetchRoutePolylines(routeIds))

    if (fetchRoutePolylines.rejected.match(result)) {
      throw new Error(result.payload?.message ?? 'Internal error')
    }
  }, [dispatch, routeInfos])

  const onClear = useCallback(async () => {
    const result = await dispatch(clearPolylinesQueue())

    if (clearPolylinesQueue.rejected.match(result)) {
      throw new Error(result.payload?.message ?? 'Internal error')
    }
  }, [dispatch])

  return (
    <>
      <button onClick={onClick}>Test Fetch</button>
      <button onClick={onClear}>Clear</button>
    </>
  )
}
