import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

/**
 * Copy start end route configuration from the current territory to another territory
 */
export const configureTelematicsDemo = createAsyncThunk<
  // Return type of the payload creator
  Record<string, string[]>,
  // First argument to the payload creator
  void,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/territory/configureTelematicsDemo', async (_params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/territory/configureTelematicsDemo', {
      type: 'rpc/territory/configureTelematicsDemo',
      category: 'rpc',
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result as Record<string, string[]>
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {configureTelematicsDemo}`,
      { tags: ['rpc', 'territory'], info: { error } },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Copy of start end route configuration failed`,
      error,
    })
  }
})
