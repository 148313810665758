import { useSelector } from 'react-redux'

import { selectUserAccountPreferences } from '@/features/domain/user'
import { timeUtils } from '@/server-data'
import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'
import { HorizontalLayout, VerticalLayout } from '@/components/layout'
import { Text } from '@/local/components'

import { useTexts } from '../useTexts'

interface Props {
  breaks: uui.domain.rm.Break[]
}

export function BreaksBlock(props: Props) {
  const { breaks } = props

  const texts = useTexts()

  const accountPreferences = useSelector(selectUserAccountPreferences)

  return (
    <HorizontalLayout
      data-trackid="navigo-routes-overview-details-single-vehicleStats-breaksBlock"
      data-testid="navigo-routes-overview-details-single-vehicleStats-breaksBlock"
      justifyContent="space-between"
      marginLeft={24}
      width="auto"
    >
      <Text size="$p3">{`${texts.breaks}:`}&nbsp;&nbsp;</Text>

      <VerticalLayout>
        {breaks.map((breakItem, index) => (
          <HorizontalLayout
            data-trackid="navigo-routes-overview-details-single-vehicleStats-breaksBlock-break"
            data-testid="navigo-routes-overview-details-single-vehicleStats-breaksBlock-break"
            key={index}
          >
            <Text
              data-testid="navigo-routes-overview-details-single-vehicleStats-breaksBlock-break-duration"
              weight="$semiBold"
              size="$p3"
            >
              <FormatSeconds seconds={breakItem.durationSec} format="MINUTES_ONLY" />
              &nbsp;&nbsp;
            </Text>

            <Text size="$p3">{`${texts.starting}`}&nbsp;&nbsp;</Text>

            <Text
              data-testid="navigo-routes-overview-details-single-vehicleStats-breaksBlock-break-interval"
              size="$p3"
            >
              {timeUtils.formatSecondsFromMidnight(
                breakItem.startSec,
                accountPreferences.timeFormat,
              )}
              {' - '}
              {timeUtils.formatSecondsFromMidnight(breakItem.endSec, accountPreferences.timeFormat)}
            </Text>
          </HorizontalLayout>
        ))}
      </VerticalLayout>
    </HorizontalLayout>
  )
}
