import type { FormErrors, FormFields } from '../../../hooks/formFields'

import { useEffect } from 'react'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { useEmailRequired } from './useEmailRequired'
import { useEmailValid } from './useEmailValid'

export function useValidations() {
  const formApi = useFormApi<FormFields, FormErrors>()
  const emailRequired = useEmailRequired()
  const emailValid = useEmailValid()

  useEffect(() => {
    formApi.addValidation('email-required', emailRequired, 'email')
    formApi.addValidation('email-valid', emailValid, 'email')

    return () => {
      formApi.removeValidation('email-required')
      formApi.removeValidation('email-valid')
    }
  }, [formApi, emailRequired, emailValid])
}
