import StoreLinksRow from '../../elements/StoreLinksRow'
import StoreLinksContainer from '../../elements/StoreLinksContainer'
import StoreLinksTitle from '../../elements/StoreLinksTitle'
import DialogMessage from '../../elements/DialogMessage'

export default function StoreLinks() {
  return (
    <StoreLinksContainer>
      <DialogMessage>
        Mobile devices are not yet supported. Please download the following mobile apps:
      </DialogMessage>

      <StoreLinksTitle>WorkWave GPS app:</StoreLinksTitle>
      <StoreLinksRow>
        <img
          style={{ height: '35px' }}
          src={'public/externals/appStoreBlack.svg'}
          onClick={() =>
            window.open('https://apps.apple.com/us/app/workwave-gps/id1377143394', '_blank')
          }
        />

        <img
          style={{ height: '50px' }}
          src={'public/externals/google-play-badge.svg'}
          onClick={() =>
            window.open(
              'https://play.google.com/store/apps/details?id=com.workwave.WWGPS_Mobile',
              '_blank',
            )
          }
        />
      </StoreLinksRow>

      <StoreLinksTitle>{`WorkWave RouteManager Driver's app:`}</StoreLinksTitle>
      <StoreLinksRow>
        <img
          style={{ height: '35px' }}
          src={'public/externals/appStoreBlack.svg'}
          onClick={() =>
            window.open(
              'https://apps.apple.com/us/app/workwave-route-manager/id1012288924',
              '_blank',
            )
          }
        />

        <img
          style={{ height: '50px' }}
          src={'public/externals/google-play-badge.svg'}
          onClick={() =>
            window.open('https://play.google.com/store/apps/details?id=com.mds.volo', '_blank')
          }
        />
      </StoreLinksRow>
    </StoreLinksContainer>
  )
}
