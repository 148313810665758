import { type CSSProperties } from 'react'
import { type ResourceModel } from '@bryntum/schedulerpro'

import { getRouteLoadingState } from '../../../atoms/routeLoadingState'

import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { UnstyledButton } from '../../Scheduler/components/UnstyledButton'
import { Spinner } from '../../Scheduler/components/Spinner'
import { Unlock } from '../icons/Unlock'
import { Lock } from '../icons/Lock'

interface Props {
  disabled: boolean
  record: ResourceModel
  field: string
  value: boolean
}

const cellContainerStyle: CSSProperties = {
  cursor: 'pointer',
}

const lockIconStyle = {
  '--font-size': '14px',
} as CSSProperties

const unlockIconStyle = {
  '--font-size': '14px',
  marginLeft: -3,
} as CSSProperties

export function LockedCell(props: Props) {
  const { value, record, disabled } = props

  const available = record.getData(
    'available',
  ) as uui.domain.client.rm.SchedulerResource['available']

  const id = record.getData('id') as uui.domain.client.rm.SchedulerResource['id']

  const { lockStatus } = getRouteLoadingState()

  const showLoader = lockStatus[id] === true || lockStatus['ALL'] === true

  if (!available) return null

  return (
    <SingleColumnContainer style={cellContainerStyle}>
      {showLoader ? (
        <Spinner size={14} />
      ) : value ? (
        <UnstyledButton disabled={disabled}>
          <Lock className="o-scheduler-svg-icon--color-black" style={lockIconStyle} />
        </UnstyledButton>
      ) : (
        <UnstyledButton disabled={disabled}>
          <Unlock className="o-scheduler-svg-icon--color-gray10" style={unlockIconStyle} />
        </UnstyledButton>
      )}
    </SingleColumnContainer>
  )
}
