import { useState, useCallback } from 'react'

import { Ask } from '@/icons'

import { Tooltip } from '@/components/primitives/Tooltip'
import { useSupportTicketModal } from '@/components/modals/SupportTicketModal'
import { DropdownMenu, MenuIconTrigger, DropdownMenuItem } from '@/components/DropdownMenu'

import { useGiveFeedbackModal } from './modals/GiveFeedbackModal'
import { useTexts } from './useTexts'

const supportCenterUrl = 'https://workwave.force.com/routemanager/s/'

export function HelpDropdownMenu() {
  const [open, setOpen] = useState(false)
  const texts = useTexts()
  const { Modal: SupportTicketModal, show: showSupportTicketModal } = useSupportTicketModal()
  const { Modal: GiveFeedbackModal, show: showGiveFeedbackModal } = useGiveFeedbackModal()

  const close = useCallback(() => setOpen(false), [])

  const onOpenSupportTicketModal = useCallback(() => {
    showSupportTicketModal()
    close()
  }, [close, showSupportTicketModal])

  const onOpenGiveFeedbackModal = useCallback(() => {
    showGiveFeedbackModal()
    close()
  }, [close, showGiveFeedbackModal])

  return (
    <>
      <DropdownMenu
        open={open}
        setOpen={setOpen}
        trigger={
          <Tooltip placement="bottom" title={texts.help}>
            <MenuIconTrigger
              menuOpen={open}
              Icon={<Ask size={20} color="$pureWhite" />}
              className="pendo-bluebar__help-menu"
              testid="help-center-menu"
            />
          </Tooltip>
        }
      >
        <a
          onClick={close}
          target="_blank"
          href={supportCenterUrl}
          rel="noopener noreferrer"
          className="pendo-bluebar__help-support-center-button"
        >
          <DropdownMenuItem external testid="help-support-center">
            {texts.supportCenter}
          </DropdownMenuItem>
        </a>

        <DropdownMenuItem
          className="pendo-bluebar__help-support-ticket-button"
          onClick={onOpenSupportTicketModal}
          testid="help-open-ticket"
        >
          {texts.openSupportTicket}
        </DropdownMenuItem>

        <DropdownMenuItem
          className="pendo-bluebar__help-give-feedback-button"
          onClick={onOpenGiveFeedbackModal}
          testid="help-give-feedback"
        >
          {texts.giveFeedback}
        </DropdownMenuItem>
      </DropdownMenu>
      <SupportTicketModal />
      <GiveFeedbackModal />
    </>
  )
}
