import { useTexts } from '../../useTexts'

import { getShortCustomFieldFieldName } from './getShortCustomFieldName'
import { getCustomField } from './getCustomField'

export function createCustomFieldColumn(
  colDef: { field: string; hide?: boolean; width?: number },
  texts: ReturnType<typeof useTexts>,
  enableDynamicCustomFields: boolean,
) {
  return {
    type: 'string',
    filterable: false,
    valueGetter: getCustomField,
    hide: colDef?.hide ?? true,
    width: colDef?.width ?? 200,
    field: colDef?.field ?? `formattedData.customFields.${colDef.field}`,
    headerName: `${getShortCustomFieldFieldName(colDef.field)} (${
      enableDynamicCustomFields ? texts.configuredCustomField : texts.customField
    })`,
  }
}
