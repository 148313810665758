import { ListHeaderFilterInput, ListHeaderFilterContainer } from '@/components/List'

import { useTexts } from './hooks/useTexts'
import { useFilter } from './hooks/useFilter'

export function Filter() {
  const { query, setQuery, editing } = useFilter()

  const texts = useTexts()

  return (
    <ListHeaderFilterContainer>
      <ListHeaderFilterInput
        placeholder={texts.filterPlaceholder}
        disabled={editing}
        setQuery={setQuery}
        query={query}
      />
    </ListHeaderFilterContainer>
  )
}
