import type { FormErrors, FormFields } from '../../formFields'
import type { FormConfig } from '@workwave-tidal/tidal/form-fairy'

import { useStandardPasswordValidations } from '@/hooks'

import { useNameValidation } from './useNameValidation'
import { useEmailValidation } from './useEmailValidation'

export function useValidations(
  creating: boolean,
): FormConfig<FormFields, FormErrors>['validations'] {
  const nameValidation = useNameValidation()
  const emailValidation = useEmailValidation()
  const { validations: passwordValidations } = useStandardPasswordValidations<
    FormFields,
    FormErrors
  >('new-password')

  return creating
    ? [nameValidation, emailValidation, ...passwordValidations]
    : [nameValidation, emailValidation]
}
