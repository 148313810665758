import { Text } from '@/local/components'
import { HorizontalLayout, VerticalLayout } from '@/components/layout'

import { TimeWindow } from './TimeWindow'

import { useTexts } from './useTexts'

interface Props {
  timeWindows: uui.domain.rm.TimeWindow[]
  violations?: uui.domain.rm.RouteStepViolationsType[]
}

export function TimeWindowsBlock(props: Props) {
  const texts = useTexts()

  const { timeWindows, violations } = props
  const gotTimeWindowViolation = violations?.some(type => type === 'TW') ?? false

  return (
    <HorizontalLayout width="auto" data-testid="TimeWindowsBlock" style={{ flexShrink: 0 }}>
      <Text size="$p3">{`${texts.timeWindows}:`}&nbsp;</Text>
      <VerticalLayout width="auto" justifyContent="space-between">
        {timeWindows.length > 0 ? (
          timeWindows.map((timeWindow, index) => (
            <TimeWindow
              key={index}
              timeWindow={timeWindow}
              gotViolations={gotTimeWindowViolation}
            />
          ))
        ) : (
          <Text size="$p3">{texts.any}</Text>
        )}
      </VerticalLayout>
    </HorizontalLayout>
  )
}
