import { FormLayout, HorizontalRuler } from '@/forms-legacy'
import { RmData } from '../../components/RmData'

import { TelematicsTracking } from './components/TelematicsTracking'
import { WwgpsTracking } from './components/WwgpsTracking'

interface Props {
  disableTrackingCheckbox: boolean
  trackingProvider: uui.domain.server.gps.TrackingProvider
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  initialValues:
    | uui.domain.ui.forms.Rm360VehicleFormValues
    | uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics
  values:
    | uui.domain.ui.forms.Rm360VehicleFormValues
    | uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics
}

export function Form(props: Props) {
  const { disableTrackingCheckbox, unifiedVehicle, trackingProvider, initialValues, values } = props

  return (
    <FormLayout>
      <RmData disableTrackingCheckbox={disableTrackingCheckbox} />

      <HorizontalRuler />

      {trackingProvider === 'WWGPS' ? (
        <WwgpsTracking
          unifiedVehicle={unifiedVehicle}
          values={values as uui.domain.ui.forms.Rm360VehicleFormValues}
        />
      ) : (
        <TelematicsTracking
          initialValues={initialValues as uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics}
          values={values as uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics}
        />
      )}
    </FormLayout>
  )
}
