import { useCallback } from 'react'

import { requestSmsSubscriptionChange } from '@/features/domain/account'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export function useRequestChangePlan() {
  const { update, close } = useController()
  const isUnmounted = useIsUnmounted()
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  return useCallback(async () => {
    try {
      update({ status: 'submitting' })

      const thunkResult = await dispatch(requestSmsSubscriptionChange())

      if (!requestSmsSubscriptionChange.fulfilled.match(thunkResult)) {
        throw new Error(thunkResult.payload?.message ?? 'Internal error')
      }
      toast.error(texts.requestChangePlanSuccess)

      if (isUnmounted()) return

      close?.()
    } catch (e) {
      toast.error(texts.requestChangePlanError)
      update({ status: 'ready' })
    }
  }, [dispatch, toast, texts, close, update, isUnmounted])
}
