import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'

import { enableUser } from '@/features/domain/user/actions'
import { useTexts } from './useTexts'

type Payload = uui.domain.actions.rpc.user.EnableUserAction['payload']

export function useEnableUser() {
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  return useCallback<(payload: Payload) => Promise<boolean>>(
    async (payload: Payload) => {
      const request = await dispatch(enableUser(payload))

      if (enableUser.fulfilled.match(request)) {
        return true
      }

      toast.error(texts.enableUserError)

      return false
    },
    [dispatch, texts, toast],
  )
}
