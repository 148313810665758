import { OrderTooltip } from './components/OrderTooltip'

import { useMapTooltipController } from './hooks/useMapTooltipController'

export function MapTooltipController() {
  const { mapTooltip, anchorPosition, ...tooltipProps } = useMapTooltipController()

  if (mapTooltip.type === 'none' || !anchorPosition) return null

  if (mapTooltip.ids.length === 0) {
    throw new Error('Impossible to show a tooltip without an id')
  }

  switch (mapTooltip.type) {
    case 'order':
      return <OrderTooltip ids={mapTooltip.ids} anchorPosition={anchorPosition} {...tooltipProps} />
    default:
      return null
  }
}
