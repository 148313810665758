import { useEffect } from 'react'
import { resetNavigoView } from '@/atoms'

import { useNavigator } from '../components/Navigator/hooks/useNavigator'
import { useNavigoCategorySelection } from './useNavigoCategorySelection'

export const useResetOnUnmount = () => {
  const { ids } = useNavigoCategorySelection()

  const {
    pagination: { reset },
  } = useNavigator()

  // Reset the Navigo mode and the paginator when a change happens on the selection
  // NOTE: It is required to reset the Navigo and the paginator once the ids changes
  // because a new set of ids means a new selection
  useEffect(() => {
    reset()
    resetNavigoView()

    // Resets the Navigo mode and the paginator on unmount
    return () => {
      reset()
      resetNavigoView()
    }
  }, [reset, ids])
}
