import type { TrafficTimeWindowsFormValues } from './types'

import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { LeftArrow } from '@/icons'
import { useTransactionState, useEnvironmentQa, useResetEditingState } from '@/atoms'
import { ColumnHeaderRow, TextButton, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { useFormTransaction } from '@/hooks'
import { FormLoading } from '@/components/FormLoading'

import { EditTimeWindowsHeader } from './components/EditTimeWindowsHeader'
import { Form } from './components/Form'
import { useTexts } from './hooks/useTexts'
import { useFormInitialValue } from './hooks/useFormInitialValue'
import { useFormValidation } from './hooks/useFormValidation'
import { useOnSubmit } from './hooks/useOnSubmit'

export function EditTimeWindows() {
  const texts = useTexts()

  const initialValues = useFormInitialValue()
  const validateForm = useFormValidation()
  const { onUpdate, submitting } = useOnSubmit()
  const { mode } = useTransactionState()
  const resetEditingState = useResetEditingState()

  const qa = useEnvironmentQa()

  const transactionState = useFormTransaction()
  const transactionOpen = transactionState === 'ready'

  const planLocked = mode === 'external'

  return (
    <FormColumn width={320} testid="form__column-primary">
      <FinalForm<TrafficTimeWindowsFormValues>
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={onUpdate}
        render={(formRenderProps: FormRenderProps<TrafficTimeWindowsFormValues>) => {
          const { form, handleSubmit } = formRenderProps
          const { pristine, valid } = form.getState()

          const disabled = !valid || submitting || planLocked

          return (
            <>
              <EditTimeWindowsHeader>
                {transactionOpen ? (
                  <ColumnHeaderRow>
                    <TextButton onClick={resetEditingState}>
                      <LeftArrow size={8} />
                      {texts.cancel}
                    </TextButton>
                    <TextButton
                      onClick={handleSubmit}
                      disabled={disabled}
                      title={texts.saveTitle(valid, planLocked, pristine)}
                    >
                      {texts.save}
                    </TextButton>
                  </ColumnHeaderRow>
                ) : (
                  <div />
                )}
              </EditTimeWindowsHeader>
              <FormContent qa={qa} footer={<></>}>
                {transactionOpen ? <Form formRenderProps={formRenderProps} /> : <FormLoading />}
              </FormContent>
            </>
          )
        }}
      />
    </FormColumn>
  )
}
