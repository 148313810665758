import { Stack, Typography } from '@mui/material'

import { useTexts } from '../../../../useTexts'
import { ApiKey } from './components/ApiKey'

export function Azuga() {
  const texts = useTexts()

  return (
    <Stack spacing={2} width="100%">
      <Typography variant="caption">{texts.azuga.instructions}</Typography>

      <ApiKey />
    </Stack>
  )
}
