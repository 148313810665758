import type { PlaceFormValues } from '../types'

import { useState, useCallback } from 'react'

import { createPlace, updatePlace } from '@/features/domain/place'

import { setCrudSelection, useListApi, useResetEditingState } from '@/atoms'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { parsePlaceFormValues } from '../utils/parsePlaceFormValues'

export const useOnSubmit = () => {
  const [submitting, setSubmitting] = useState(false)
  const { scrollTo } = useListApi('places')
  const stopEditing = useResetEditingState()
  const isUnmounted = useIsUnmounted()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const onCreate = useCallback(
    async (formValues: PlaceFormValues) => {
      try {
        setSubmitting(true)

        const place = parsePlaceFormValues(formValues)
        const thunkResult = await dispatch(createPlace(place))

        if (!isUnmounted()) {
          setSubmitting(false)
        }

        if (createPlace.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()

        setCrudSelection('places', [thunkResult.payload])

        scrollTo(thunkResult.payload)
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, isUnmounted, stopEditing, scrollTo, toast],
  )

  const onUpdate = useCallback(
    async (formValues: PlaceFormValues) => {
      try {
        setSubmitting(true)

        const place = parsePlaceFormValues(formValues)
        const thunkResult = await dispatch(updatePlace(place))

        if (!isUnmounted()) {
          setSubmitting(false)
        }

        if (updatePlace.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, isUnmounted, stopEditing, toast],
  )

  return {
    submitting,
    onCreate,
    onUpdate,
  }
}
