import type { ReactNode, RefObject, ReactElement } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'

import { TriggerContent } from './components/TriggerContent'

interface Props {
  open: boolean
  disabled?: boolean
  anchorElRef: RefObject<HTMLLIElement>
  Icon?: ReactElement
  tooltipTitle?: string
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right'
  children?: ReactNode
  fontSize?: number
  fontWeight?: number
}

export function Trigger(props: Props) {
  const { tooltipTitle, tooltipPlacement = 'bottom', ...rest } = props

  return tooltipTitle ? (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
      <TriggerContent {...rest} />
    </Tooltip>
  ) : (
    <TriggerContent {...rest} />
  )
}
