import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    title: translate({
      id: 'companies.addModal.title',
    }),
    footer: {
      add: translate({ id: 'companies.addModal.footer.add' }),
      cancel: translate({ id: 'companies.addModal.footer.cancel' }),
    },
    validation: {
      empty: translate({ id: 'companies.addModal.form.validation.name.empty' }),
      maxLength: (limit: number) =>
        translate({
          id: 'companies.addModal.form.validation.name.maxLength',
          values: { limit },
        }),
      alreadyExists: translate({ id: 'companies.addModal.form.validation.name.alreadyExists' }),
    },
    form: {
      name: {
        label: translate({ id: 'companies.addModal.form.name.label' }),
      },
    },
    toast: {
      success: translate({ id: 'companies.addModal.toast.success' }),
    },
  }))

  return api
}
