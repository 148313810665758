import type { DropdownItem } from '@/forms-legacy'

import { useMemo } from 'react'

import { naturalSort } from '@/server-data'

import { useCreateAlertLabel } from '../../../../../../../hooks/useCreateAlertLabel'

const eventAlertTypes: uui.domain.server.gps.wwgps.EventAlertType[] = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 125, 130, 140, 150, 160, 170, 180, 190, 200,
  210, 220, 230, 300, 310, 400, 410, 420, 430, 440, 450, 460, 500, 505, 510, 520, 530, 540,
]

export function useDropdownOptions() {
  const createAlertLabel = useCreateAlertLabel()

  return useMemo<DropdownItem[]>(() => {
    const options = eventAlertTypes.reduce<DropdownItem[]>((acc, eventType) => {
      acc.push({
        id: `${eventType}`,
        label: createAlertLabel(eventType),
      })
      return acc
    }, [])

    options.sort(sortOptions)
    return options
  }, [createAlertLabel])
}

function sortOptions(a: DropdownItem, b: DropdownItem) {
  return naturalSort(a.label, b.label)
}
