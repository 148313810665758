import {
  useState,
  useEffect,
  forwardRef,
  useCallback,
  type MouseEvent,
  type ReactNode,
  type MutableRefObject,
} from 'react'
import { IconButton, Menu, type PopoverOrigin } from '@mui/material'
import { MoreVert } from '@mui/icons-material'

import { Tooltip } from '../../primitives/Tooltip'
import { useTexts } from './hooks/useTexts'

const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }
const buttonStyle = { minWidth: 40 }

type Props = {
  children?: ReactNode
  disabled?: boolean
}

export type ListHeaderSecondaryActionRef = {
  close: () => void
}

export const ListHeaderSecondaryActionButton = forwardRef(
  (props: Props, ref: MutableRefObject<ListHeaderSecondaryActionRef>) => {
    const { children, disabled = false } = props
    const texts = useTexts()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const onToggleClick = useCallback((event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }, [])

    const onSecondaryActionsMenuClose = useCallback(() => {
      setAnchorEl(null)
    }, [])

    useEffect(() => {
      // eslint-disable-next-line no-param-reassign
      ref.current = { close: onSecondaryActionsMenuClose }
    }, [ref, onSecondaryActionsMenuClose])

    const open = Boolean(anchorEl)

    return (
      <>
        <Tooltip title={texts.more} placement="bottom">
          <IconButton
            data-trackid="list-header-secondary-actions-menu-toggler"
            data-testid="list-header-secondary-actions-menu-toggler"
            disabled={disabled}
            onClick={onToggleClick}
            size="small"
            sx={buttonStyle}
          >
            <MoreVert />
          </IconButton>
        </Tooltip>

        <Menu
          open={open}
          onClose={onSecondaryActionsMenuClose}
          anchorEl={anchorEl}
          data-testid="list-header-secondary-actions-menu"
          anchorOrigin={anchorOrigin}
        >
          {children}
        </Menu>
      </>
    )
  },
)
