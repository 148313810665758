import type { CSSProperties } from 'react'
import { Box, Grid, Typography } from '@mui/material'

import { OrderEvent } from '@/icons'

import { useTexts } from '../hooks/useTexts'

interface Props {
  ordersCount: number
}

const containerStyle: CSSProperties = {
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  display: 'flex',
}

const typographyStyle: CSSProperties = {
  lineHeight: '32px',
  position: 'absolute',
  left: 0,
  top: 0,
}

export function RouteOrdersEvent(props: Props) {
  const { ordersCount } = props
  const texts = useTexts()

  if (ordersCount === 0) return null

  return (
    <Grid maxWidth="100%" container spacing={1} data-testid="routeOrdersEvent">
      <Grid item xs={2}>
        <Box style={containerStyle}>
          <OrderEvent size={32} />
          <Typography
            fontWeight={700}
            textAlign="center"
            fontSize={10}
            height={'100%'}
            width={'100%'}
            color="white"
            style={typographyStyle}
            data-testid="ordersCount"
          >
            {`x${ordersCount}`}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={10}>
        <Typography variant="h6" data-testid="eventTitle">
          {texts.orders(ordersCount)}
        </Typography>
      </Grid>
    </Grid>
  )
}
