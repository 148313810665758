import { PureComponent } from 'react'

import { ReadonlyBlock, ReadonlyLabel, SecondaryColumnContentHorizontalRuler } from '@/forms-legacy'

import * as OrderTexts from '../../intl'

import ReportedTime from './elements/ReportedTime'
import { ReportedStatus } from './elements/ReportedStatus'
import DetectedTime from './elements/DetectedTime'
import DetectedServiceTime from './elements/DetectedServiceTime'
import { ReportedStatusReason } from './elements/ReportedStatusReason'

import { Props } from './typings'

export class ExecutionEvents extends PureComponent<Props> {
  render() {
    return (
      <>
        <SecondaryColumnContentHorizontalRuler bigBottomSpace />

        <ReadonlyBlock>
          <ReadonlyLabel primary>{OrderTexts.getExecutionDetailsTitle()}</ReadonlyLabel>
        </ReadonlyBlock>

        <ReportedTime {...this.props} />
        <ReportedStatus {...this.props} />
        <ReportedStatusReason {...this.props} />

        <ReadonlyBlock>
          <ReadonlyLabel primary>{OrderTexts.getDetectedExecutionDetailsTitle()}</ReadonlyLabel>
        </ReadonlyBlock>

        <DetectedTime {...this.props} />
        <DetectedServiceTime {...this.props} />

        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
