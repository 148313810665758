import styled from 'styled-components'

import { FlexRow } from '@/forms-legacy'

import HourPickerButton from './HourPickerButton'

const HourPickerControls = styled(FlexRow)`
  flex: 0 0 auto;
  background: ${p => p.theme.colors.$pureWhite};
  height: 28px;
  margin-left: 4px;
  width: auto;

  ${HourPickerButton}:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${HourPickerButton}:last-child {
    margin-left: 2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

HourPickerControls.displayName = 'HourPickerControls'
export default HourPickerControls
