import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { produce } from 'immer'

import { useAppDispatch } from '@/store'
import { setListOptions } from '@/features/domain/lists/actions'

import { selectEventAlertsListOptions } from '@/features/domain/lists'

export const allEventTypeIds: uui.domain.server.gps.wwgps.EventAlertType[] = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 125, 130, 140, 150, 160, 170, 180, 190, 200,
  210, 220, 230, 300, 310, 400, 410, 420, 430, 440, 450, 460, 500, 505, 510, 520, 530, 540,
]

type AlertFilterStatus = {
  eventTypeId: 'all' | uui.domain.server.gps.wwgps.EventAlertType
}

export function useFilterList() {
  const dispatch = useAppDispatch()
  const listOptions = useSelector(selectEventAlertsListOptions)

  const setFilter = useCallback(
    async (filter: { eventTypeId: 'all' | uui.domain.server.gps.wwgps.EventAlertType }) => {
      const nextOptions = produce(listOptions, draft => {
        draft.filter = [
          {
            field: 'eventTypeId',
            values: filter.eventTypeId === 'all' ? allEventTypeIds : [filter.eventTypeId],
            operator: 'or',
          },
        ]
      })

      // avoid triggering any calculations in case the group didn't change
      if (nextOptions === listOptions) return

      const request = await dispatch(
        setListOptions({ category: 'eventAlerts', options: nextOptions }),
      )
      return setListOptions.fulfilled.match(request)
    },
    [dispatch, listOptions],
  )

  return {
    filter: listOptions.filter.reduce(
      (acc, { values }, index) => {
        switch (index) {
          case 0:
            acc.eventTypeId =
              values.length === 1
                ? (values[0] as uui.domain.server.gps.wwgps.EventAlertType)
                : 'all'
            break
        }

        return acc
      },
      { eventTypeId: 'all' } as AlertFilterStatus,
    ),
    setFilter,
  }
}
