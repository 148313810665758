import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'settings.gpsFormats.view.headerTitle' }),
    headerDescription: translate({ id: 'settings.gpsFormats.view.headerDescription' }),

    language: translate({ id: 'settings.gpsFormats.view.language' }),
    dateFormat: translate({ id: 'settings.gpsFormats.view.dateFormat' }),
    timeFormat: translate({ id: 'settings.gpsFormats.view.timeFormat' }),
    distanceFormat: translate({ id: 'settings.gpsFormats.view.distanceFormat' }),
  }))

  return api
}
