import { useCallback } from 'react'
import { Button } from '@mui/material'

import { useReadOnly } from '@/hooks'
import { useAutoScroll } from '@/atoms'
import { ListLoading } from '@/components/ListLoading'
import { ListFooter, LayoutComposer, NoEntries, NoResults } from '@/components/List'
import {
  StructuredVirtualList,
  type OnSelectCallbackParams,
} from '@/components/StructuredVirtualList'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Grow, FormColumn, ComponentSize, ListOverlayEditCover } from '@/components/layout'

import { useDataForList } from './hooks/useDataForList'
import { useTexts } from './hooks/useTexts'
import { Header } from './components/Header'
import { Item } from './Item'

interface Props {
  drivers: Record<string, uui.domain.client.rm.ExtendedDriver>
  selection: workwave.DeepReadonly<string[]>
  editing: boolean
}

const getItemHeight = () => 59

export function DriversList(props: Props) {
  const { drivers, selection, editing } = props

  const {
    isLoading,
    structure,
    setCreate,
    setEditing,
    selectDrivers,
    lastSelectedDriver,
    toggleSelection,
  } = useDataForList()

  const { readonly } = useReadOnly()
  const texts = useTexts()

  useAutoScroll('drivers', lastSelectedDriver)

  const handleOnSelect = useCallback(
    (params: OnSelectCallbackParams<'drivers'>) => {
      selectDrivers(params.newSelectedIds)
    },
    [selectDrivers],
  )

  const handleOnDoubleClick = useCallback(() => {
    if (readonly) return

    setEditing([...selection])
  }, [readonly, setEditing, selection])

  const action = (
    <ReadOnlyTooltip
      canEdit={!isLoading && !editing}
      forceState={editing || isLoading ? 'enabled' : undefined}
      title={
        editing ? texts.disabledForEditingTooltip : isLoading ? texts.disabledForLoadingTooltip : ''
      }
      render={preventEditing => (
        <Button
          size="large"
          variant="outlined"
          onClick={setCreate}
          disabled={preventEditing}
          data-testid="create-driver_text-button"
          data-trackid="create-driver_text-button"
        >
          {texts.noEntries.action}
        </Button>
      )}
    />
  )

  const allSelected = selection.length >= structure.shownItems
  const multiSelect = selection.length > 1

  return (
    <FormColumn width={320} testid="form__column-primary">
      <Header />

      <Grow yAxis xAxis position="relative" data-testid="drivers-list-root">
        <LayoutComposer
          totalItems={structure.totalItems}
          shownItems={structure.shownItems}
          noResults={
            <NoResults title={texts.noResults.title} description={texts.noResults.description} />
          }
          noEntries={
            <NoEntries
              title={texts.noEntries.title}
              description={texts.noEntries.description}
              action={action}
            />
          }
          list={
            isLoading ? (
              <ListLoading />
            ) : (
              <ComponentSize
                render={size => {
                  // TODO: reset the scroll when the list options change
                  return (
                    <>
                      <StructuredVirtualList
                        itemsById={drivers}
                        structure={structure}
                        listApiCategory="drivers"
                        getItemHeights={getItemHeight}
                        RenderItem={Item}
                        listHeight={size.height}
                        selectedItemIds={selection}
                        onSelect={handleOnSelect}
                        onDoubleClick={handleOnDoubleClick}
                        buffer={50}
                      />
                      {editing && <ListOverlayEditCover />}
                    </>
                  )
                }}
              />
            )
          }
        />
      </Grow>

      {multiSelect && (
        <ListFooter
          label={texts.selectBtn(allSelected)}
          onToggleSelection={toggleSelection}
          checked={allSelected}
        />
      )}
    </FormColumn>
  )
}
