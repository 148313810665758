import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { parseISO, isBefore, isEqual } from 'date-fns/esm'

import { selectStartOfToday } from '@/features/domain/user'
import { selectCalendarRangeInterval } from '@/features/domain/ui'

export function useTrackingDisabled() {
  const startOfToday = useSelector(selectStartOfToday)
  const interval = useSelector(selectCalendarRangeInterval)

  return useMemo(() => {
    const today = parseISO(startOfToday)

    const multipleDaysSelected = !isEqual(interval.start, interval.end)
    const futureDateSelected = isBefore(today, interval.start)

    return multipleDaysSelected || futureDateSelected
  }, [startOfToday, interval])
}
