import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Attention(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 13 13">
        <path d="M6.5 0C2.90934 0 0 2.90934 0 6.5S2.90934 13 6.5 13 13 10.09066 13 6.5 10.09066 0 6.5 0zm0 10.64925c-.50781 0-.85482-.39144-.85482-.89925 0-.52262.36182-.89925.85482-.89925.52262 0 .85482.37663.85482.89925 0 .50781-.3322.89925-.85482.89925zm.33643-3.43408c-.12907.44222-.53744.44857-.67286 0-.15657-.5184-.71305-2.48405-.71305-3.75993 0-1.68424 2.10742-1.6927 2.10742 0 0 1.28434-.5861 3.30078-.72151 3.75993z" />
      </svg>
    </IconContainer>
  )
}
