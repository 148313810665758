import type { FormFields, FormErrors } from '../../formFields'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { changePassword, selectUsers, updateUser } from '@/features/domain/user'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useController } from '../../../hooks/useController'
import { useTexts } from './useTexts'

export function useOnSubmit(setFirstSubmitDone: (value: boolean) => void) {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const isUnmounted = useIsUnmounted()
  const {
    update,
    close,
    data: { userId },
  } = useController()
  const formApi = useFormApi<FormFields, FormErrors>()
  const users = useSelector(selectUsers)
  const user = users[userId]

  return useCallback(async () => {
    try {
      formApi.setSubmitting(true)
      update({ status: 'submitting' })
      setFirstSubmitDone(true)

      const valid = await formApi.validate()

      if (!valid) {
        formApi.setSubmitting(false)
        update({ status: 'ready' })
        return
      }

      const formValues = formApi.getValues()

      const result = await dispatch(
        updateUser({
          user,
          password: formValues['new-password'],
        }),
      )

      if (changePassword.rejected.match(result)) {
        const message = result.payload?.message ?? 'Internal error'

        throw new Error(message)
      }

      toast.success(texts.changePasswordSuccess)

      if (isUnmounted()) return

      // Clear FormState submitting mode
      formApi.setSubmitting(false)
      update({ status: 'ready' })

      close?.()
    } catch (e) {
      formApi.setSubmitting(false)
      update({ status: 'ready' })

      toast.error(e.message)
    }
  }, [formApi, dispatch, isUnmounted, toast, texts, close, setFirstSubmitDone, update, user])
}
