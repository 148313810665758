import { FormLayout, HorizontalRuler } from '@/forms-legacy'

import { NotificationEditor } from './fields/NotificationEditor'
import { GeofenceRepeat } from './fields/GeofenceRepeat'
import { StartDateTime } from './fields/StartDateTime'
import { GeofenceName } from './fields/GeofenceName'
import { GeofenceType } from './fields/GeofenceType'
import { EndDateTime } from './fields/EndDateTime'
import { DevicesList } from './fields/DevicesList'
import { MaxSpeed } from './fields/MaxSpeed'
import { Timezone } from './fields/Timezone'
import { Address } from './fields/Address'
import { Radius } from './fields/Radius'

export function Form() {
  return (
    <FormLayout>
      <GeofenceName />
      <HorizontalRuler />

      <GeofenceType />
      <HorizontalRuler />

      <Address />
      <HorizontalRuler />

      <Radius />
      <HorizontalRuler />

      <MaxSpeed />
      <HorizontalRuler />

      <StartDateTime />
      <HorizontalRuler />

      <EndDateTime />
      <HorizontalRuler />

      <Timezone />
      <HorizontalRuler />

      <GeofenceRepeat />
      <HorizontalRuler />

      <DevicesList />
      <HorizontalRuler />

      <NotificationEditor />
    </FormLayout>
  )
}
