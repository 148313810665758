import styled from 'styled-components'
import TextInput from '../../../../../TextInput'

const styledComponent = styled(TextInput).attrs({
  className: 'o-try-notifcation-text-input',
})`
  border: 0;
`
styledComponent.displayName = 'TryNotificationTextInput'
export default styledComponent
