import type Point from 'ol/geom/Point'
import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'
import type { OptionalSelectionModifiers } from '@/atoms'

import { getOrderFeatureMetadata } from '../../../../layers/order/orderFeatureMetadata'

import { resetMapTooltip, setMapTooltip } from '../../../../atoms/mapTooltip'
import { mapAtom } from '../../../../atoms/map/map'

export function showOrderTooltip(
  feature: Feature<Geometry>,
  _modifiers: OptionalSelectionModifiers,
) {
  const orderIdsRecord = getOrderFeatureMetadata(feature, 'ids')

  if (orderIdsRecord) {
    const ids = Object.keys(orderIdsRecord)

    if (ids.length > 0) {
      const geometry = feature.getGeometry() as Point
      const coordinates = geometry.getCoordinates()
      const pixelCoordinates = mapAtom.map.getPixelFromCoordinate(coordinates)

      setMapTooltip({ type: 'order', coordinates: pixelCoordinates, ids })
    } else {
      resetMapTooltip()
    }
  } else {
    resetMapTooltip()
  }
}
