import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'

import { setBreadcrumbFeatureMetadata } from '../../breadcrumbFeatureMetadata'
import { getBreadcrumbPointsFeatureStyle } from './getBreadcrumbPointsFeatureStyle'

export function updateBreadcrumbPointsFeature(
  mapStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
  marker: uui.domain.ui.map.markers.Breadcrumb,
  feature: Feature<Geometry>,
  point: uui.domain.ui.map.markers.BreadcrumbPoint,
  selected: boolean,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
): Feature<Geometry> {
  // ------------------------
  // Feature Metadata
  // ------------------------

  const mode: uui.domain.ui.map.markers.MapStyles['breadcrumb']['mode'] =
    marker.mode ?? mapStyles.mode

  setBreadcrumbFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style
  // ------------------------

  const style = getBreadcrumbPointsFeatureStyle(point, mode, selected, breadcrumbTimeRange)
  feature.setStyle(style)

  return feature
}
