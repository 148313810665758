import { ViewHeader, ViewContent, ViewContainer, ViewFieldsLayout, ViewField } from '@/formUi'

import { useTexts } from './hooks/useTexts'
import { useTimezone } from './hooks/useTimezone'

export function Territory() {
  const texts = useTexts()
  const timezone = useTimezone()

  return (
    <ViewContainer testid="driver-activity-view">
      <ViewContent>
        <ViewHeader title={texts.title} subtitle={texts.description} />

        <ViewFieldsLayout>
          <ViewField label={texts.timezone}>{timezone}</ViewField>
        </ViewFieldsLayout>
      </ViewContent>
    </ViewContainer>
  )
}
