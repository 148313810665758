import styled from 'styled-components'

type Props = {
  htmlFor?: string
  bold?: boolean
  small?: boolean
}

const Label = styled.label.attrs<Props>(() => ({
  'data-testid': 'form__label',
}))<Props>`
  display: flex;
  position: relative;
  width: 100%;
  color: ${p => p.theme.colors.$nightRider};
  font-size: ${p => (p.small ? p.theme.fonts.$s : p.theme.fonts.$m)};
  font-weight: ${p => (p.bold ? p.theme.weights.$semiBold : p.theme.weights.$regular)};
  padding-left: 4px;
  align-items: baseline;
`

Label.displayName = 'Label'

export default Label
