import { useCallback, useState } from 'react'

import { setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

import { useData } from './useData'

type Action = 'popoutDepots' | 'showDepotLabels' | 'showDepots' | 'hideDepots'
export const useActions = () => {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const { currentDepotIds: ids } = useData()

  const popoutDepots = useCallback(async () => {
    setMethodInExecution('popoutDepots')

    await dispatch(
      setCustomMapStyle({
        mapMode: 'off',
        customStyle: { type: 'depot', mode: 'label', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const showDepotLabels = useCallback(async () => {
    setMethodInExecution('showDepotLabels')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'depot', mode: 'label', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const showDepots = useCallback(async () => {
    setMethodInExecution('showDepots')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'depot', mode: 'on', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const hideDepots = useCallback(async () => {
    setMethodInExecution('hideDepots')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'depot', mode: 'off', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  return { methodInExecution, actions: { popoutDepots, showDepotLabels, showDepots, hideDepots } }
}
