import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    done: translate({ id: 'global.done' }),
    status: translate({ id: 'global.status' }),
    totalServiceTime: translate({ id: 'rm.contextual.order.totalServiceTime' }),
  }))

  return api
}
