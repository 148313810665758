import coordinates from './pins.json'

const cellSize = coordinates?.side

if (cellSize === undefined) {
  throw new Error(`Impossible to find the cellSize for map pin markers`)
}

export function getPinGridPlacement(
  symbol: uui.spriteSheet.PinSymbol,
  cellType: uui.spriteSheet.PinCells,
): uui.domain.ui.map.markers.GridPlacement {
  const coordsByTpe = coordinates.coordinates[
    symbol
  ] as uui.spriteSheet.ColorCoordinates<uui.spriteSheet.PinSymbol>

  const coords = coordsByTpe?.[cellType] as [x: number, y: number]

  if (!coords) {
    throw new Error(`Impossible to find Grid Placement for a Map Pin of type: ${symbol}`)
  }

  return [coords[0], coords[1], cellSize]
}
