import type { PublicProps as Props } from './typings'
import { PureComponent } from 'react'

import LoadsList from './LoadsList'

interface State {
  suggestions: string[]
  filter: string
}

const computeSuggestions = (props: Props, filter: string): string[] => {
  const { value, suggestions } = props
  const keysToFilter = Object.keys(value)

  return suggestions.reduce((acc: string[], s: string) => {
    const isAlreadyUsed: boolean = keysToFilter.includes(s)
    const isIncludedInFilter: boolean = s.startsWith(filter)

    if (!isAlreadyUsed && isIncludedInFilter) {
      acc.push(s)
    }

    return acc
  }, [])
}

const setFilter = (filter: string) => () => ({
  filter,
})

const setSuggestions = (suggestions: string[]) => () => ({
  suggestions,
})

export default class LoadsListContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      suggestions: computeSuggestions(props, ''),
      filter: '',
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { value: prevValue, suggestions: prevSuggestions } = prevProps
    const { value, suggestions } = this.props
    const { filter: prevFilter } = prevState
    const { filter } = this.state

    if (prevValue !== value || prevSuggestions !== suggestions || filter !== prevFilter) {
      this.setState(setSuggestions(computeSuggestions(this.props, filter)))
    }
  }

  private handleOnChangeFilter = (filter: string) => {
    this.setState(setFilter(filter))
  }

  render() {
    const { filter } = this.state
    const { suggestions, ...props } = this.props
    const { suggestions: stateSuggestions } = this.state

    return (
      <LoadsList
        {...props}
        suggestions={stateSuggestions}
        allSuggestions={suggestions}
        onChangeFilter={this.handleOnChangeFilter}
        filter={filter}
      />
    )
  }
}
