import type { MapSelectionMode } from '../map'

import { useCallback } from 'react'
import { useSnapshot } from 'valtio'

import { mapAtom } from '../map'

export const useMapSelectionMode = () => {
  return [
    useSnapshot(mapAtom).selectionMode,

    useCallback((value: MapSelectionMode) => {
      mapAtom.selectionMode = value
    }, []),
  ] as const
}
