import { Icon, Style } from 'ol/style'
import mapPinSpriteSheet from '@/resources/map-markers/pins.png'
import { getPinGridPlacement } from '../../../coordinates/getPinGridPlacement'

const mapStylesCache: Map<string, Style> = new Map()

export function getLocationPinStyle(
  type: uui.domain.ui.map.LocationPin['pin']['type'],
  pinStatus: uui.domain.ui.map.LocationPin['pin']['status'] | 'loading',
) {
  const spriteSheetUrl = mapPinSpriteSheet

  const coordinates = getPinGridPlacement(getIconPinType(type), pinStatus)

  const cellSize = coordinates[2]
  const x = coordinates[0]
  const y = coordinates[1]

  const cacheId = `locationPin_${x}_${y}_${pinStatus}}`

  if (mapStylesCache.has(cacheId)) {
    const cached = mapStylesCache.get(cacheId)

    if (cached) return cached
  }

  const style = new Style({
    image: new Icon({
      src: spriteSheetUrl,
      crossOrigin: 'anonymous',

      scale: 0.5,
      opacity: 1.0,
      rotation: 0.0,
      rotateWithView: true,

      offset: [x, y],
      size: [cellSize, cellSize],

      anchor: [0.5, 21],
      anchorOrigin: 'bottom-left',
      anchorYUnits: 'pixels',
      anchorXUnits: 'fraction',
    }),
  })

  mapStylesCache.set(cacheId, style)

  return style
}

function getIconPinType(
  type: uui.domain.ui.map.LocationPin['pin']['type'],
): uui.spriteSheet.PinSymbol {
  switch (type) {
    case 'depot':
      return 'depot'

    case 'place':
      return 'place'

    case 'normal':
    case 'geofence':
    case 'loading':
    default:
      return 'bullsEye'
  }
}
