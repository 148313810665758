import { useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

import { fileUtils } from '@/utils'

type RawParsedFile = fileUtils.RawParsedFile

interface useDragAndDropProps {
  onDrop: (territoryFile: RawParsedFile | null) => void
}

export const useDragAndDrop = (props: useDragAndDropProps) => {
  const { onDrop } = props

  const handleDrop = useCallback(
    async (item: { files: any[] }) => {
      const file = item.files?.[0] ?? null

      if (file) {
        try {
          const result = await fileUtils.readFile(file, { asText: true })
          onDrop(result.type === 'resolved' ? result.file : null)
        } catch (e) {
          // do nothing, the user cancelled the open file dialog
        }
      }
    },
    [onDrop],
  )

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop: handleDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  return { canDrop, isOver, drop }
}
