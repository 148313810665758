import { useMemo } from 'react'

import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { NoDispatch } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  multiple: boolean
  permission:
    | 'hidden'
    | 'enabled'
    | 'disabledBecauseInThePast'
    | 'disabledBecauseGotExecutionEvents'
  onClick: () => void
}

export function RevokeButton(props: Props) {
  const { onClick, permission, multiple } = props
  const texts = useTexts()

  const tooltipTitle = useMemo(() => {
    switch (permission) {
      case 'disabledBecauseGotExecutionEvents':
        return texts.disabledBecauseGotExecutionEvents

      case 'disabledBecauseInThePast':
        return texts.disabledDispatchRoutesPast

      default:
        return ''
    }
  }, [texts, permission])

  if (permission === 'hidden') return null

  const disabled = permission.startsWith('disabled')

  return (
    <ReadOnlyTooltip
      canEdit={!disabled}
      title={tooltipTitle}
      render={preventEditing => (
        <NavigoIconButton
          onClick={onClick}
          disabled={preventEditing || disabled}
          text={multiple ? texts.revokeAll : texts.revoke}
          Icon={
            <NoDispatch size={17} color={preventEditing || disabled ? '$silver' : '$nightRider'} />
          }
          testid="navigo-routes-overview-actions-revoke"
        />
      )}
    />
  )
}
