import { DevicesSubMenu } from '../../DevicesSubMenu'
import { PlacesSubMenu } from '../../PlacesSubMenu'

interface Props {
  menuOpen: boolean
}

export function GpsOnlySubMenu(props: Props) {
  const { menuOpen } = props

  return (
    <>
      <DevicesSubMenu parentOpen={menuOpen} />
      <PlacesSubMenu parentOpen={menuOpen} />
    </>
  )
}
