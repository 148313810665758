import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Arrow(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 12 12">
        <path d="M6.312 6L3.106 2.713c-.143-.147-.141-.381.003-.528l.573-.575c.148-.147.384-.147.53.001L8.29 5.735c.073.073.109.168.109.265s-.036.192-.11.265L4.213 10.39c-.146.148-.382.148-.53.001l-.573-.575c-.144-.147-.146-.381-.003-.528L6.312 6z" />
      </svg>
    </IconContainer>
  )
}
