import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { renderToStaticMarkup } from 'react-dom/server'

import { selectUserConfiguration } from '@/features/domain/user'
import { store } from '@/store'
import { intl } from '@/intl'

import { RouteEnd, RouteEndTooltip } from '../../../components/cells/RouteEnd'

import { routeEndSorter } from '../sorters/routeEndSorter'

export function createRouteEndColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  const userConfig = selectUserConfiguration(store.getState())

  return {
    text: intl.translate({ id: 'rm.scheduler.column.routeEnd' }),
    type: 'template',
    field: 'routeEnd',
    width,
    editor: false,
    hidden,
    region: 'cols',
    sortable: routeEndSorter,
    resizable: true,
    groupable: false,
    template: ({ record, value }) => {
      return renderToStaticMarkup(
        <RouteEnd value={value} record={record as ResourceModel} userConfig={userConfig} />,
      )
    },
    tooltipRenderer: ({ record }) => {
      const routeEnd = record.getData(
        'routeEnd',
      ) as uui.domain.client.rm.SchedulerResource['routeEnd']

      if (!routeEnd) return '-'

      const userConfig = selectUserConfiguration(store.getState())

      return renderToStaticMarkup(
        <RouteEndTooltip record={record as ResourceModel} userConfig={userConfig} />,
      )
    },
  }
}
