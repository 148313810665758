import { ReactElement, useCallback, useState, useEffect } from 'react'

import { ListActionButton, FlexColumn } from '@/forms-legacy'
import { AddRounded } from '@/icons'

import BarcodeRow from './BarcodeRow'
import { deleteBarcode, updateBarcodes, addBarcode } from './utils'
import { Props } from './typings'

const getReadonlyIndexes = (
  values: string[],
  sourcePods: uui.domain.client.rm.BarcodePod[],
): Set<number> => {
  const pods = [...sourcePods]

  const result: Set<number> = new Set()

  values.forEach((barcode, index) => {
    const podIndex = pods.findIndex(p => p.barcode === barcode)
    if (podIndex >= 0) {
      result.add(index)
      pods.splice(podIndex, 1)
    }
  })

  return result
}

const Barcodes = (props: Props): ReactElement => {
  const { maxBarcodes, labelButton, addButtonDisabledTitle = '' } = props
  const { value, onChange, podsBarcodes, addButtonClassName, ...barcodeProps } = props

  const [readonlyIndexes, setReadonlyIndexes] = useState<Set<number>>(
    getReadonlyIndexes(value, podsBarcodes),
  )

  useEffect(() => {
    setReadonlyIndexes(getReadonlyIndexes(value, podsBarcodes))
  }, [value, podsBarcodes])

  const addButtonDisabled: boolean = value.length >= maxBarcodes

  const onAddBarcode = useCallback(() => {
    onChange(addBarcode(value))
  }, [onChange, value])

  const onDeleteBarcode = useCallback(
    (index: number) => {
      onChange(deleteBarcode(value, index))
    },
    [onChange, value],
  )

  const onUpdateBarcode = useCallback(
    (b: string, index: number) => {
      onChange(updateBarcodes(value, b, index))
    },
    [onChange, value],
  )

  return (
    <>
      <ListActionButton
        icon={<AddRounded />}
        onClick={onAddBarcode}
        disabled={addButtonDisabled}
        className={addButtonClassName}
        title={addButtonDisabled ? addButtonDisabledTitle : ''}
        testid="barcodes__add-btn"
      >
        {labelButton}
      </ListActionButton>
      <FlexColumn primary data-testid="barcodes-items">
        {value.map((barcode: string, index: number) => {
          return (
            <BarcodeRow
              key={`${index}`}
              onRemove={() => onDeleteBarcode(index)}
              onUpdate={(barcode: string) => onUpdateBarcode(barcode, index)}
              barcode={barcode}
              fieldProps={barcodeProps}
              readonly={readonlyIndexes.has(index)}
            />
          )
        })}
      </FlexColumn>
    </>
  )
}

export default Barcodes
