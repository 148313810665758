import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Wrench(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M13.871 10.04c-1.33 1.331-3.068 1.175-4.762.146l-5.683 5.697a10.58 10.58 0 01-3.309-3.31l5.697-5.684C4.785 5.197 4.63 3.457 5.96 2.127 7.178.91 10.145-.461 11.895.15l-2.64 2.637a.934.934 0 000 1.318l2.64 2.637a.93.93 0 001.318 0l2.637-2.637c.609 1.75-.76 4.717-1.979 5.934z" />
      </svg>
    </IconContainer>
  )
}
