import type { TimerState } from './hooks/useTransactionTimer'

import { useMemo } from 'react'
import { Box } from '@mui/material'

import { Check, Pause, Spinner, WarningRhombus } from '@/icons'
import { FlexBox } from '@/local/components'

import { Container } from '../../elements/Container'
import { ProgressBar } from './elements/ProgressBar'
import { useFooterTransaction } from './hooks/useFooterTransaction'
import { TransactionControls } from './components/TransactionControls'

type Props = {
  offline: boolean
}

type TransactionIconProps = { state: TimerState }

const IconProps = { size: 18, marginRight: 14 }

function TransactionIcon(props: TransactionIconProps) {
  switch (props.state) {
    case 'ready':
      return <WarningRhombus {...IconProps} color="$outrageousRed" />

    case 'running':
      return <Spinner {...IconProps} animation="spin" />

    case 'paused':
      return <Pause {...IconProps} />

    case 'expired':
      return <Check {...IconProps} />

    default:
      return null
  }
}

export function FooterTransaction(_props: Props) {
  const { reset, progress, state, title, subtitle } = useFooterTransaction(120)

  // Added a useMemo to prevent the re-render of the component at every timer tick
  const transactionControls = useMemo(
    () => <TransactionControls transactionState={state} onResetTimer={reset} />,
    [reset, state],
  )

  return (
    <Container color="$pureWhite" background="$nightRider" data-testid="transaction-bar-root">
      <ProgressBar progress={progress} />

      <FlexBox vAlignContent="center" grow={0} shrink={0} zIndex={10}>
        <TransactionIcon state={state} />
        <Box>
          {title}{' '}
          {subtitle && (
            <Box fontWeight="fontWeightMedium" display="inline">
              {subtitle}
            </Box>
          )}
        </Box>
      </FlexBox>

      <FlexBox grow={1} shrink={0} zIndex={10} marginRight="auto">
        {transactionControls}
      </FlexBox>
    </Container>
  )
}
