import { Alert } from '@/components/Alert'
import { WarningTriangle } from '@/icons'

import { useTexts } from './useTexts'

export function RedirectWarning() {
  const texts = useTexts()

  return (
    <Alert
      text={texts.redirectAlert}
      preset="warning"
      Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
    />
  )
}
