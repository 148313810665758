import { type SchedulerPro } from '@bryntum/schedulerpro'
import { addSeconds } from 'date-fns/esm'

export async function setFullTimeSpan(
  scheduler: SchedulerPro,
  calendarRangeInterval: { start: Date; end: Date },
  dayStartOffset: number,
  dayLength: number,
) {
  const { start } = calendarRangeInterval

  // ---------------------------------------------------------------------------------------------
  // Set the full time span
  // ---------------------------------------------------------------------------------------------

  const startDate = addSeconds(start, dayStartOffset)
  const endDate = addSeconds(start, dayStartOffset + dayLength)

  //console.log('set MAIN time span', { startDate: startDate, endDate: endDate })

  await scheduler.setTimeSpan(startDate, endDate)
}
