import styled from 'styled-components'

const styledComponent = styled.div.attrs<workwave.Div>(() => ({
  className: 'o-clear-selection-icon',
}))`
  position: relative;
  color: ${p => p.theme.colors.$pureWhite};
  background: ${p => p.theme.colors.$silver};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  user-select: none;
  pointer-events: none;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 9px;
    transform: translate(-50%, -50%);
  }
`

styledComponent.displayName = 'ClearSelectionIcon'
export default styledComponent
