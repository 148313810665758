import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

interface Payload {
  area: uui.domain.server.rm.TrafficRegion
  recurrence: string
}

/**
 * Update/Insert a traffic area
 *
 * @private
 */
export const addTrafficArea = createAsyncThunk<
  // Return type of the payload creator
  string,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/traffic/addTrafficArea', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const response = await rpc('rpc/traffic/addTrafficArea', {
      category: 'rpc',
      type: 'rpc/traffic/addTrafficArea',
      area: payload.area,
      recurrence: payload.recurrence,
    })
    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }
    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {addTrafficArea}`,
      { tags: ['rpc', 'traffic'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Add traffic area failed`,
      error,
    })
  }
})
