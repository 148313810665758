import { Fragment, PureComponent } from 'react'

import {
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldParagraph,
} from '@/forms-legacy'

import * as OrderTexts from '../../../intl'

import { Props, FieldMode } from '../typings'
import * as Texts from '../intl'

export default class Notes extends PureComponent<Props> {
  render() {
    const { notes } = this.props

    if (notes === FieldMode.hidden) {
      return null
    }

    const text = notes === FieldMode.mixed ? OrderTexts.getMixedText() : notes

    return (
      <>
        <ReadonlyBlock>
          <ReadonlyLabel primary empty={notes.length === 0}>
            {Texts.getNotesText()}
          </ReadonlyLabel>

          {notes.length > 0 && (
            <ReadonlyField>
              <ReadonlyFieldParagraph>
                {text.split('\n').map((s, i) => (
                  <Fragment key={i}>
                    {s}
                    <br />
                  </Fragment>
                ))}
              </ReadonlyFieldParagraph>
            </ReadonlyField>
          )}
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
