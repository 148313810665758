import type { QueryParameters } from '@/hooks'

import { useCallback } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

import { login } from '@/features/actions/login'
import { useNavigate } from '@/routing'
import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { recoverPasswordService } from '@/services'
import { updateAccountDataToken, useEnvironmentURLs } from '@/atoms'

import { useActionsTexts } from './useActionsTexts'

export function useLoginActions(query: QueryParameters) {
  const toast = useNotification()
  const texts = useActionsTexts()
  const dispatch = useAppDispatch()
  const navigate = useNavigate<uui.routing.MainSections>(true)
  const { serverUrl } = useEnvironmentURLs()

  const territoryId = query?.territoryId ?? undefined
  const simulationId = query?.simulationId ?? undefined

  const onLogin = useCallback(
    async data => {
      const { username, password /* rememberMe */ } = data

      try {
        const loginRequest = await dispatch(
          login({ username, password, serverUrl, territoryId, simulationId }),
        )

        if (login.fulfilled.match(loginRequest)) {
          const result = unwrapResult(loginRequest)

          // Now we're forcing rememberMe to TRUE, please replace with following line
          // when remember me will be managed correctly.
          //
          // updateAccountDataToken(username, result.authtoken, rememberMe)
          updateAccountDataToken(username, result.authtoken, true)
        } else {
          const message = texts.loginError(loginRequest.payload)
          toast.error(message)
        }
      } catch (e) {
        // un-expected error
        navigate('error')
      }
    },
    [dispatch, navigate, toast, serverUrl, texts, territoryId, simulationId],
  )

  const forgotPassword = useCallback(
    async (data: { username: string }) => {
      const { username } = data

      try {
        await recoverPasswordService({ username, serverUrl })
        toast.success(texts.forgotPasswordSuccess)
      } catch (e) {
        toast.error(texts.forgotPasswordError)
      }
    },
    [texts, toast, serverUrl],
  )

  return { onLogin, forgotPassword }
}
