import type { FormProps } from '../types'

import { NotificationForm } from '../../../../../../notificationForms/sections/notifications/components/NotificationForm'
import { NotificationView } from '../../../../../../notificationForms/sections/notifications/components/NotificationView'

import { useFormStateController } from '../../hooks/useFormStateController'
import { useLiveEtaStatus } from '../../hooks/useLiveEtaStatus'

import { DelayedNotificationView } from './components/DelayedNotificationView'

import { useDelayedNotificationInitForm } from './hooks/useDelayedNotificationInitForm'
import { useDelayedNotificationSubmit } from './hooks/useDelayedNotificationSubmit'
import { useDelayedNotificationConfig } from './hooks/useDelayedNotificationConfig'
import { useDelayedNotificationValues } from './hooks/useDelayedNotificationValues'
import { useDelayedNotificationToggle } from './hooks/useDelayedNotificationToggle'
import { useTexts } from './useTexts'

/**
 * Renders the Delayed notification form providing all the domain data and apis.
 */
export function DelayedNotification(props: FormProps) {
  const { inEditing, setEditingState } = props

  const values = useDelayedNotificationValues()

  const { toggleDescription, onToggle } = useDelayedNotificationToggle(values)
  const { edit, cancel } = useFormStateController(setEditingState)
  const onSubmit = useDelayedNotificationSubmit()
  const config = useDelayedNotificationConfig()
  const liveEtaStatus = useLiveEtaStatus()
  const texts = useTexts()

  const available = liveEtaStatus !== 'notActive' && liveEtaStatus !== 'notAvailableForTheUser'

  // Render the Form inside a FormRoot component that will expose the FormState API through a React.Context
  if (inEditing) {
    return (
      <NotificationForm
        initHook={useDelayedNotificationInitForm}
        subtitle={texts.description}
        notification={'delayed'}
        onSubmit={onSubmit}
        title={texts.title}
        cancel={cancel}
        values={values}
        config={config}
      />
    )
  }

  return (
    <NotificationView
      Form={<DelayedNotificationView values={values} />}
      toggleSubtitle={toggleDescription}
      subtitle={texts.description}
      notification={'delayed'}
      available={available}
      onToggle={onToggle}
      title={texts.title}
      values={values}
      onEdit={edit}
    />
  )
}
