import { useCallback } from 'react'

import { resetMainSelection, useEditingStateWithCtx } from '@/atoms'

export function useActions() {
  const { setEditing } = useEditingStateWithCtx('order')
  const onCloseView = useCallback(() => {
    resetMainSelection('orderSteps')
  }, [])

  const onStartEditing = useCallback((selection: string[]) => setEditing(selection), [setEditing])

  return {
    onCloseView,
    onStartEditing,
  }
}
