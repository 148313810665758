import type { FormFields, FormErrors } from './formFields'

import { FormProvider } from '@workwave-tidal/tidal/form-ui/layout'

import { useRmAccountData } from '../hooks/useRmAccountData'

import { useCreateFormConfig } from './hooks/useCreateFormConfig'
import { RmAccountForm } from './RmAccountForm'
import { formFields } from './formFields'

type Props = {
  onDiscard: () => void
}

export function RmAccountFormProvider(props: Props) {
  const { onDiscard } = props

  // access form initial values and a function update the data when the form is saved
  const [account, updateAccount] = useRmAccountData()

  // create a memoized configuration including initial form valuers and form validation
  const createFormConfig = useCreateFormConfig(account)

  return (
    <FormProvider<FormFields, FormErrors>
      config={createFormConfig}
      name="rm-account-form"
      fields={formFields}
      verbose={false}
    >
      <RmAccountForm onDiscard={onDiscard} updateAccount={updateAccount} />
    </FormProvider>
  )
}
