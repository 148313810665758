import { Entry } from '../Entry'

export function Entry20210309() {
  return (
    <Entry title="03/10/2021 - v3.0.0-alpha-8">
      <ul>
        <li>Fix the map markers when the calendar range changes</li>
        <li>The Navigo for the inspect pin is available</li>
      </ul>
    </Entry>
  )
}
