import { Text } from '@/local/components'
import { VerticalLayout } from '@/components/layout'

import { useTexts } from './useTexts'

export function NotExecutedBlock() {
  const texts = useTexts()

  return (
    <VerticalLayout width="auto" justifyContent="space-between" alignItems="start">
      <Text size="$s" weight="$semiBold" color="$nightRider">
        {texts.noExecutionDetailsTitle}
      </Text>

      <Text size="$s" color="$nightRider">
        {texts.noExecutionDetailsDescription}
      </Text>
    </VerticalLayout>
  )
}
