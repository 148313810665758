import type { MapSelectionContext } from '../../selection/mapSelection'
import type { MainSelection, CrudSelection } from '@/atoms'

import { createUpdateBreadcrumbFeaturesFromChangelog } from '../../../layers/breadcrumb/createUpdateBreadcrumbFeaturesFromChangelog'
import { createUpdateDeviceEventFeaturesFromChangelog } from '../../../layers/deviceEvent/createUpdateDeviceEventFeaturesFromChangelog'
import { connectToRenderingQueue } from '../../../renderingQueue'

import { getMapMainSelection } from '../../selection/utils/getMapMainSelection'
import { getMapCrudSelection } from '../../selection/utils/getMapCrudSelection'

const emptyStringSelection = new Set<string>()

const [getTicketAndRunAction] = connectToRenderingQueue()

function prepareAction(
  markers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
  mapSelectionContext: MapSelectionContext,
  mainSelection: workwave.DeepReadonly<MainSelection>,
  crudSelection: workwave.DeepReadonly<CrudSelection>,
  category: 'breadcrumbs' | 'deviceEvents',
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  switch (category) {
    case 'breadcrumbs':
      const mainMapSelection = getMapMainSelection(mapSelectionContext, mainSelection)

      return createUpdateBreadcrumbFeaturesFromChangelog(
        markers.breadcrumb,
        mapStyles.breadcrumb,
        mapSelectionContext.type === 'main' ? mainMapSelection.breadcrumbs : emptyStringSelection,
        {
          add: new Set(),
          remove: new Set(),
          update: new Set(Object.keys(markers.breadcrumb)),
        },
        breadcrumbTimeRange,
      )

    case 'deviceEvents':
      const crudMapSelection = getMapCrudSelection(mapSelectionContext, crudSelection)

      return createUpdateDeviceEventFeaturesFromChangelog(
        markers.deviceEvent,
        mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.deviceEvents,
        {
          add: new Set(),
          remove: new Set(),
          update: new Set(Object.keys(markers.deviceEvent)),
        },
        breadcrumbTimeRange,
      )
  }
}

export function updateMarkerLayerAfterBreadcrumbTimeRangeChange(
  markers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
  mapSelectionContext: MapSelectionContext,
  mainSelection: workwave.DeepReadonly<MainSelection>,
  crudSelection: workwave.DeepReadonly<CrudSelection>,
  category: 'breadcrumbs' | 'deviceEvents',
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  const action = prepareAction(
    markers,
    mapStyles,
    mapSelectionContext,
    mainSelection,
    crudSelection,
    category,
    breadcrumbTimeRange,
  )

  if (action) {
    getTicketAndRunAction(action, `updateMarkerAfterBreadcrumbTimeRangeChange`)
  }
}
