import {
  Card,
  Stack,
  Avatar,
  styled,
  CardMedia,
  Typography,
  IconButton,
  CardContent,
} from '@mui/material'
import { Add } from '@mui/icons-material'

type Props = {
  description?: string
  integration: uui.domain.server.gps.telematics.Source
  connections: number
  onClick?: () => void
  logo: string
}

const DiscoverIntegrationAvatar = styled(Avatar, {
  name: 'DiscoverIntegrationAvatar',
})(({ theme }) => ({
  borderColor: theme.palette.grey['A100'],
  borderStyle: 'solid',
  borderWidth: 1,
}))

export function DiscoverIntegrationCard(props: Props) {
  const { integration, connections, logo, onClick, description = '' } = props

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Stack direction="row" spacing={2}>
          <DiscoverIntegrationAvatar variant="rounded">
            <CardMedia component="img" image={logo} alt={integration.label} />
          </DiscoverIntegrationAvatar>

          <Stack direction="column" width="100%" spacing={2}>
            <Stack direction="row" width="100%" justifyContent="center">
              <Stack justifyContent="center" flexGrow={1}>
                <Typography variant="subtitle3">{integration.label}</Typography>
              </Stack>

              {onClick && (
                <IconButton
                  onClick={onClick}
                  data-testid="discover-integration-card-add-button"
                  data-trackid="discover-integration-card-add-button"
                >
                  <Add />
                </IconButton>
              )}
            </Stack>

            <Stack direction="column" width="100%">
              <Typography variant="body1">{description}</Typography>
              {connections > 0 && (
                <Typography variant="caption">{connections} connections</Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
