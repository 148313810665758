import { useCollapsibleState } from '../../../../atoms/collapsibleState'
import { CollapseButton } from '../../../../components/CollapseButton'

import { ActivationCost } from '../fields/ActivationCost'
import { ServiceTimeCost } from '../fields/ServiceTimeCost'
import { DrivingTimeCost } from '../fields/DrivingTimeCost'
import { IdleTimeCost } from '../fields/IdleTimeCost'
import { BreakTimeCost } from '../fields/BreakTimeCost'
import { KmCost } from '../fields/KmCost'
import { PerStopCost } from '../fields/PerStopCost'
import { useTexts } from '../hooks/useTexts'

interface Props {
  metric: boolean
  currency: string
}

export function Costs(props: Props) {
  const { metric, currency } = props
  const [collapsed, toggleCollapsed] = useCollapsibleState('costs')

  const texts = useTexts()

  return (
    <div className="o-pendo__vehicle-form__costs">
      <CollapseButton collapsed={collapsed} toggleCollapsed={toggleCollapsed}>
        {texts.costs}
      </CollapseButton>
      <div hidden={collapsed}>
        <ActivationCost metric={metric} currency={currency} />
        <ServiceTimeCost metric={metric} currency={currency} />
        <DrivingTimeCost metric={metric} currency={currency} />
        <IdleTimeCost metric={metric} currency={currency} />
        <BreakTimeCost metric={metric} currency={currency} />
        <KmCost metric={metric} currency={currency} />
        <PerStopCost metric={metric} currency={currency} />
      </div>
    </div>
  )
}
