import type { Option } from '../../../../../types'
import { useMemo } from 'react'

import { useTexts } from './useTexts'

export function useOptions() {
  const texts = useTexts()

  return useMemo<Option[]>(() => {
    const options: Option[] = [
      // Routing section
      {
        id: 'header-routing',
        type: 'header',
        label: texts.routing,
      },
      {
        type: 'option',
        label: texts.depotsVehicleDrivers,
        value: 'Depots, Vehicles or Drivers',
      },
      {
        type: 'option',
        label: texts.regionsTrafficRoads,
        value: 'Regions, Traffic or Road Edits',
      },
      {
        type: 'option',
        label: texts.optimizing,
        value: 'Optimizing or Dispatching',
      },
      {
        type: 'option',
        label: texts.mobileApp,
        value: 'Mobile App',
      },
      // Notification section
      {
        id: 'header-notifications',
        type: 'header',
        label: texts.notifications,
      },
      {
        type: 'option',
        label: texts.sms,
        value: 'SMS',
      },
      {
        type: 'option',
        label: texts.email,
        value: 'Email',
      },
      // Tracking section
      {
        id: 'header-tracking',
        type: 'header',
        label: texts.tracking,
      },
      {
        type: 'option',
        label: texts.virtualTracking,
        value: 'Virtual Tracking',
      },
      {
        type: 'option',
        label: texts.physicalTracking,
        value: 'Physical Tracking',
      },
      // Other section
      {
        id: 'header-other',
        type: 'header',
        label: texts.other,
      },
      {
        type: 'option',
        label: texts.api,
        value: 'API',
      },
      {
        type: 'option',
        label: texts.users,
        value: 'Users (Planners/Viewers/Guests)',
      },
      {
        type: 'option',
        label: texts.styleOnMap,
        value: 'Style on Map',
      },
      {
        type: 'option',
        label: texts.geocoding,
        value: 'Geocoding or Address Recognition',
      },
      {
        type: 'option',
        label: texts.feedback,
        value: 'Feedback',
      },
      {
        type: 'option',
        label: texts.other,
        value: 'Other',
      },
    ]

    return options
  }, [texts])
}
