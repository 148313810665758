import type { DropDownHeader } from '../../../../typings'
import type { HeaderProps as Props } from './typings'

import { useCallback } from 'react'

import { FlexBox, H4, InvisibleButton } from '@/local/components'
import { Tooltip } from '@/components/primitives/Tooltip'

import { itemHeight as height } from './constants'
import { getMarginTop } from './utils'

export function Header(props: Props) {
  const { item, index, onHeaderClick, options } = props

  const style = {
    height,
    padding: '0 6px',
    marginTop: getMarginTop(item, index),
  }

  const handleOnClick = useCallback(
    (item: DropDownHeader, e) => {
      if (onHeaderClick) {
        onHeaderClick(item, index, options, e)
      }
    },
    [onHeaderClick, index, options],
  )

  return (
    <InvisibleButton
      onClick={e => handleOnClick(item, e)}
      style={{ display: 'block', width: '100%', cursor: onHeaderClick ? 'pointer' : 'default' }}
      id={item.id}
    >
      <FlexBox w="100%" style={style} vAlignContent="center">
        {!!item.tooltip ? (
          <Tooltip
            placement="right"
            title={item.tooltip.title}
            subtitle={item.tooltip.description}
            textAlign="left"
          >
            <H4 color="$paleSky" uppercase>
              {item.label}
            </H4>
          </Tooltip>
        ) : (
          <H4 color="$paleSky" uppercase>
            {item.label}
          </H4>
        )}
      </FlexBox>
    </InvisibleButton>
  )
}
