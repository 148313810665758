import { FormHeader } from '@workwave-tidal/tidal/form-ui/layout'
import { ViewContainer, ViewContent, ViewFieldsLayout, ViewField } from '@/formUi'

import { useLanguageAndFormatsData } from '../hooks/useLanguageAndFormatsData'
import { useFormatLanguage } from '../hooks/useFormatLanguage'

import { getDateFormats, timeFormats } from '../../formats'

import { LanguageAndFormatsViewActions } from './components/LanguageAndFormatsViewActions'
import { useTexts } from './hooks/useTexts'

type Props = {
  onEdit: () => void
}

export function LanguageAndFormatsView(props: Props) {
  const { onEdit } = props

  const [languageAndPreferences] = useLanguageAndFormatsData()
  const formatLanguage = useFormatLanguage()
  const texts = useTexts()

  const dateFormats = getDateFormats()

  return (
    <ViewContainer testid="settings-preferences-and-formats">
      <ViewContent>
        <FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />

        <ViewFieldsLayout>
          {/* View Main Actions */}
          <LanguageAndFormatsViewActions onEdit={onEdit} />
          {/* View Fields */}
          <ViewField label={texts.language}>
            {formatLanguage(languageAndPreferences.language)}
          </ViewField>
          <ViewField label={texts.dateFormat}>
            {dateFormats[languageAndPreferences.dateFormat]}
          </ViewField>
          <ViewField label={texts.timeFormat}>
            {timeFormats[languageAndPreferences.timeFormat]}
          </ViewField>
          <ViewField label={texts.distanceFormat}>
            {texts.distanceFormatValue(languageAndPreferences.distanceFormat)}
          </ViewField>
          <ViewField label={texts.currency}>{languageAndPreferences.currency.value}</ViewField>
        </ViewFieldsLayout>
      </ViewContent>
    </ViewContainer>
  )
}
