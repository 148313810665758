import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useControllerActions } from '../../hooks/useControllerActions'
import { useController } from '../../hooks/useController'
import { useTexts } from '../../useTexts'

import { SubmitIcon } from './components/SubmitIcon'

export function Footer() {
  const { onConfirm } = useControllerActions()
  const texts = useTexts()
  const {
    close,
    status,
    data: { mode, planType },
  } = useController()

  const submitting = status === 'submitting'

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          Icon={<SubmitIcon mode={mode} />}
          testid={computeTestId(mode, planType === 'archived')}
          disabled={submitting}
          loading={submitting}
          onClick={onConfirm}
          variant="contained"
        >
          {mode === 'add' ? texts.addOrders : texts.replaceOrders}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          onClick={close}
          disabled={submitting}
          testid={
            planType === 'archived'
              ? 'copyarchivedday_modal__button-cancel'
              : 'copytooperations_modal__button-cancel'
          }
        >
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}

function computeTestId(mode: 'add' | 'replace', archived: boolean) {
  switch (mode) {
    case 'add':
      return archived ? 'copyarchivedday_modal__button-add' : 'copytooperations_modal__button-add'

    case 'replace':
      return archived
        ? 'copyarchivedday_modal__button-replace'
        : 'copytooperations_modal__button-replace'
  }
}
