import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (collapsed?: boolean) =>
      translate({ id: collapsed ? 'global.expand' : 'global.collapse' }),
  }))

  return api
}
