import styled from 'styled-components'

import { default as Comp, Props } from '../../InputFieldText'

const getResetButtonTop = ({ multi }: Props) => (multi ? '18%' : '50%')
const getResetRight = ({ multi, showCounter }: Props) =>
  multi ? '-3px' : showCounter ? '12px' : '0px'
const getResetBottom = ({ multi }: Props) => (multi ? '8%' : 'auto')

const getPaddingRight = ({ multi, showCounter }: Props) => (showCounter && !multi ? '44px' : '34px')

const comp = styled(Comp)<Props>``
const styledComponent = styled(comp).attrs<Props>({
  className: 'o-text-input',
})`
  border-color: ${p => (p.hasError ? p.theme.colors.$outrageousRed : p.theme.colors.$nightRider)};
  background-color: ${p => p.theme.colors.$pureWhite};
  padding-right: ${getPaddingRight};

  .o-input-field-text__reset-button {
    top: ${getResetButtonTop};
  }

  .o-input-field-text__counter {
    right: ${getResetRight};
    bottom: ${getResetBottom};
  }
`

styledComponent.displayName = 'TextInput'
export default styledComponent
