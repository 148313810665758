import { useCallback, useEffect } from 'react'

import { fetchSmsPhoneNumbers } from '@/features/domain/account'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useController } from './useController'

export function useFetchPhoneNumbers() {
  const { update } = useController()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const fetchPhoneNumbers = useCallback(async () => {
    try {
      const thunkResult = await dispatch(fetchSmsPhoneNumbers())

      if (fetchSmsPhoneNumbers.rejected.match(thunkResult)) {
        throw new Error(thunkResult.payload?.message ?? 'Internal error')
      }

      update({ status: 'ready', data: { phoneNumbers: thunkResult.payload } })
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, toast, update])

  useEffect(() => {
    update({ status: 'pending' })
    fetchPhoneNumbers()
  }, [update, fetchPhoneNumbers])
}
