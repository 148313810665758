import { createGpsReportsCookie, deleteGpsReportsCookie } from './utils/cookie'
import { parseGpsReportsCookie } from './utils/parseGpsReportsCookie'

const state = parseGpsReportsCookie()

export const gpsReportsSession = {
  getState() {
    return state
  },

  initializeGpsReports(base64EncodedContent: string) {
    createGpsReportsCookie(base64EncodedContent)
  },

  deleteCookie() {
    deleteGpsReportsCookie()
  },
}
