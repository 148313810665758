import type {
  YYYYMMDD,
  CalendarClickType,
  MonthlyValidSelection,
  MonthCalendarProps as Props,
} from '../../typings'

import { useCallback } from 'react'
import { getDate } from 'date-fns/esm'

import { HorizontalLayout, VerticalLayout } from '@/components/layout'

import { Month } from '../../components/Month'
import { useMonths } from '../../hooks/useMonths'
import { parseDate } from '../../utils/parseDate'

import { useSelectionData } from './useSelectionData'
import { useMonthDays } from './useMonthDays'

const monthWith31Days = new Date('2020-10-01')
const minDateAsString = '20201001'
const maxDateAsString = '20201031'

export function Monthly(props: Props) {
  const { highlightedDays, onChange, selection, allowAutoMonthHeight, dotColor } = props

  const months = useMonths(monthWith31Days, false, 1, minDateAsString, maxDateAsString, 'd')

  const selectionData = useSelectionData(selection)

  const daysByMonth = useMonthDays({
    months,
    selection: selectionData,
    highlightedDays,
  })

  const handleOnClick = useCallback(
    (day?: YYYYMMDD, type: CalendarClickType = 'click') => {
      if (day) {
        const dayDate = getDate(parseDate(day)) as MonthlyValidSelection
        onChange(dayDate, selection, type)
      }
    },
    [onChange, selection],
  )

  return (
    <VerticalLayout height="auto" alignItems="center">
      <HorizontalLayout width="auto">
        {months.map(
          (month, index) =>
            daysByMonth[month.id] && (
              <Month
                index={index}
                key={month.id}
                dotColor={dotColor}
                onClick={handleOnClick}
                days={daysByMonth[month.id]}
                allowAutoMonthHeight={allowAutoMonthHeight}
              />
            ),
        )}
      </HorizontalLayout>
    </VerticalLayout>
  )
}
