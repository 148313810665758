import { useMemo } from 'react'

import { NotificationBody } from './NotificationBody'

interface Props {
  body: string
}

export function SMSBody(props: Props) {
  const { body } = props
  const rows = useMemo(() => body.split('\n'), [body])

  return <NotificationBody rows={rows} />
}
