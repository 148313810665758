import type { Props } from './types'

import { Stack } from '@mui/material'
import { SecondaryColumnContentHorizontalRuler } from '@/forms-legacy'

import { useAggregateLoadsCf } from './hooks/useAggregateLoadsCf'
import { DynamicLoads } from './components/DynamicLoads'
import { DynamicCustomFields } from './components/DynamicCustomFields'

export function DynamicLoadsCf(props: Props) {
  const { aggregateCustomFields, aggregateLoads } = useAggregateLoadsCf(props)

  const hasLoads = aggregateLoads.length > 0
  const hasCustomFields = aggregateCustomFields.length > 0

  if (!hasLoads && !hasCustomFields) return null

  return (
    <Stack mt="4px" spacing={1}>
      {hasLoads && <DynamicLoads aggregateLoads={aggregateLoads} />}
      {hasCustomFields && <DynamicCustomFields aggregateCustomFields={aggregateCustomFields} />}
      <SecondaryColumnContentHorizontalRuler />
    </Stack>
  )
}
