import type { Options as IconOptions } from 'ol/style/Icon'
import { Icon, Style } from 'ol/style'

import devicesSpriteSheet from '@/resources/map-markers/devicesAndVehicles.png'

import { genericStyles } from '../../genericStyles'

const deviceArrowStylesCache: Map<string, Style> = new Map()

export const getDeviceArrowFeatureStyle = (
  marker: uui.domain.ui.map.markers.Device,
  mode: uui.domain.ui.map.markers.MapStyles['device']['mode'],
) => {
  if (mode === 'off' || !marker.arrowGrid) return genericStyles.hidden

  const coordinates = marker.arrowGrid

  const spriteSheetUrl = devicesSpriteSheet
  const cellSize = coordinates[2]
  const x = coordinates[0]
  const y = coordinates[1]

  const cacheId = `${x}_${y}`

  if (deviceArrowStylesCache.has(cacheId)) {
    const cachedStyle = deviceArrowStylesCache.get(cacheId)

    if (cachedStyle) return cachedStyle
  }

  const opts: IconOptions = {
    anchor: [0.5, 0.5],
    anchorOrigin: 'top-left',
    anchorYUnits: 'fraction',
    anchorXUnits: 'fraction',
  }

  const style = new Style({
    image: new Icon({
      src: spriteSheetUrl,
      crossOrigin: 'anonymous',

      scale: 0.5,
      opacity: 1.0,
      rotation: 0.0,
      rotateWithView: true,

      size: [cellSize, cellSize],
      offset: [x, y],

      ...opts,
    }),
  })

  deviceArrowStylesCache.set(cacheId, style)

  return style
}
