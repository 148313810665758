import styled from 'styled-components'

const ReadonlyAudioLeftContainer = styled.div`
  display: flex;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 auto;
  padding: 0 10px;
  color: ${p => p.theme.colors.$nightRider};
  overflow: hidden;

  & > * {
    margin: 0;
    margin-top: 4px;
    display: block;
    width: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & > * + * {
    margin-left: 0;
  }
`

ReadonlyAudioLeftContainer.displayName = 'ReadonlyAudioLeftContainer'
export default ReadonlyAudioLeftContainer
