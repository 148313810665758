import type { AsyncThunkApiConfig } from '@/store'
import type { RejectError } from '../../typings'

import { createAsyncThunk } from '@reduxjs/toolkit'

type Payload = {
  recurrence: string
  assignments: uui.domain.client.rm.DriverAssignments
}

/**
 * Create a "local" driver assignment exception
 * This action will not call any server api, just store locally the new exception
 * This allows us to cancel the creation without interfere with the transaction
 */
export const createLocalDriverAssignment = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/driverAssignment/createLocal', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const result = await rpc('rpc/driverAssignment/createLocal', {
      category: 'rpc',
      type: 'rpc/driverAssignment/createLocal',
      ...payload,
    })
    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {createLocalDriverAssignment}`,
      { tags: ['rpc', 'driverAssignments'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Creation of driverAssignments failed`,
      error,
    })
  }
})
