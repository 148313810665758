import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'

export const findBreadcrumbFeature = (
  pointsLayer: VectorLayer<VectorSource>,
  pathLayer: VectorLayer<VectorSource>,
  id: string,
): Feature<Geometry> | undefined => {
  let feature: Feature<Geometry> | undefined

  // Check if the feature is on the points layer
  feature = pointsLayer.getSource()?.getFeatureById(id) ?? undefined

  if (feature) return feature

  // Check if the feature is on the stops layer
  feature = pathLayer.getSource()?.getFeatureById(id) ?? undefined

  if (feature) return feature
}
