import { isDeepEqual } from '@/server-data'

/**
 * This function creates a function that checks if the value passed to it is equal to the previous value passed to it.
 * The use case for this is to avoid storing ref values to the previous value and comparing them manually.
 * @param value initial value to compare
 * @returns  a function that checks if the value passed to it is equal to the previous value passed to it.
 */
export function createIsValueEqual<T>(value: T) {
  let oldValue = value
  return (newValue: T) => {
    const equals = isDeepEqual(oldValue, newValue)
    oldValue = newValue
    return equals
  }
}
