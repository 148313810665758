import { Fragment } from 'react'
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'

import { useControllerActions } from '../../../../../hooks/useControllerActions'
import { useFilterOptions } from '../../../../../hooks/useFilterOptions'
import { useController } from '../../../../../hooks/useController'
import { useTexts } from '../../../../../hooks/useTexts'

import { IncludePod } from './IncludePod'

const routesFilterTestId = 'export-route-routes-filter'

export function FilterPicker() {
  const {
    data: { filter },
  } = useController()

  const handlers = useControllerActions()
  const filterOptions = useFilterOptions()

  const texts = useTexts()

  return (
    <Box>
      <FormLabel id="filter-picker">{texts.filterTitle}</FormLabel>
      <RadioGroup
        aria-labelledby="filter-picker"
        value={filter}
        onChange={handlers.onChangeFilter}
        data-testid={routesFilterTestId}
        data-trackid={routesFilterTestId}
      >
        {filterOptions.map(opt => {
          const showIncludePod = opt.value === 'selected' && filter === 'selected'

          return (
            <Fragment key={opt.value}>
              <span>
                <FormControlLabel
                  value={opt.value}
                  control={<Radio data-testid={`export-route-filter-${opt.value}`} />}
                  label={opt.label}
                  disabled={opt.disabled}
                />
                <Stack gap={1} pl={4}>
                  <Typography
                    variant="caption"
                    sx={theme => ({
                      color: opt.disabled ? theme.vars.palette.action.disabled : undefined,
                    })}
                  >
                    {opt.description}
                  </Typography>
                  {showIncludePod && <IncludePod />}
                </Stack>
              </span>
            </Fragment>
          )
        })}
      </RadioGroup>
    </Box>
  )
}
