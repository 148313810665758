import type { ReactElement } from 'react'
import { Box } from '@mui/material'
interface Props {
  maxWidth?: string | number
  children: ReactElement | ReactElement[]
  testid?: string
  width?: string | number
}
export function FormContainer(props: Props) {
  return (
    <Box
      data-testid={props.testid}
      maxWidth={props.maxWidth}
      width={props.width ?? 442}
      flexDirection="column"
      paddingBottom={2}
      paddingTop={2}
      overflow="auto"
      display="flex"
      height="100%"
    >
      {props.children}
    </Box>
  )
}
