import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

/**
 * Search places on the map
 *
 * @private
 */
export const tryOpenTransaction = createAsyncThunk<
  // Return type of the params creator
  boolean,
  // First argument to the payload creator
  void,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('transaction/startEditing', async (_params, thunkApi) => {
  const rpc = thunkApi.extra.domainProxy.getRpc()

  try {
    const result = await rpc('rpc/transaction/tryOpen', {
      category: 'rpc',
      type: 'rpc/transaction/tryOpen',
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    return true
  } catch (e) {
    thunkApi.extra.journal.main(
      'Unknown error on {openTransaction}',
      { tags: ['rpc', 'transaction'] },
      'error',
    )

    return false
  }
})
