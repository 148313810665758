import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectGpsTrackingProvider } from '@/features/domain/user'

import { WithTravelledDistance } from './components/WithTravelledDistance'
import { Regular } from './components/Regular'

interface Props {
  violations: uui.domain.rm.RouteViolations
  routeId: string
  start: number
  end: number
}

export function TimeBlock(props: Props) {
  const trackingProvider = useSelector(selectGpsTrackingProvider)

  const canShowTravelledDistance = useMemo(() => {
    if (trackingProvider === 'telematics') {
      return true
    }

    return false
  }, [trackingProvider])

  switch (canShowTravelledDistance) {
    case true:
      return <WithTravelledDistance {...props} />
    case false:
      return <Regular {...props} />
  }
}
