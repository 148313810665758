import { useController } from '../../hooks/useController'
import { RegularHeader } from './components/RegularHeader'
import { InvalidHeader } from './components/InvalidHeader'

export function Header() {
  const { status } = useController()

  switch (status) {
    case 'ready':
    case 'submitting':
      return <RegularHeader />

    case 'invalid':
      return <InvalidHeader />
  }
}
