import { ReadonlyBlock, ReadonlyLabel } from '@/forms-legacy'
import { useTexts } from '../hooks/useTexts'

interface Props {
  repeat: 'continuous' | 'one-time'
}

export function GeofenceRepeat(props: Props) {
  const { repeat } = props

  const texts = useTexts()

  return (
    <ReadonlyBlock half>
      <ReadonlyLabel>{texts.repeatType(repeat)}</ReadonlyLabel>
    </ReadonlyBlock>
  )
}
