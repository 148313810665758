import styled from 'styled-components'

type Props = {
  half?: boolean
}

const InnerBlock = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${p => (p.half ? '44%' : '100%')};
  margin: 5px 0 0;

  > * + * {
    margin-top: 5px;
  }
`
InnerBlock.displayName = 'InnerBlock'
export default InnerBlock
