import { useTexts } from '../../useTexts'

import { getShortLoadFieldName } from './getShortLoadName'
import { getLoad } from './getLoad'

export function createLoadColumn(
  colDef: { field: string; hide?: boolean; width?: number },
  texts: ReturnType<typeof useTexts>,
  enableDynamicLoads: boolean,
) {
  return {
    type: 'number',
    filterable: false,
    valueGetter: getLoad,
    hide: colDef?.hide ?? true,
    width: colDef?.width ?? 200,
    field: colDef?.field ?? `formattedData.loads.${colDef.field}`,
    headerName: `${getShortLoadFieldName(colDef.field)} (${
      enableDynamicLoads ? texts.configuredLoad : texts.load
    })`,
  }
}
