import { useController } from '../../hooks/useController'

import { SuccessFooter } from './components/SuccessFooter'
import { ErrorFooter } from './components/ErrorFooter'
import { ReadyFooter } from './components/ReadyFooter'

export function Footer() {
  const { status } = useController()

  switch (status) {
    case 'ready':
    case 'submitting':
      return <ReadyFooter />

    case 'success':
      return <SuccessFooter />

    case 'error':
      return <ErrorFooter />
  }
}
