import type { AsyncThunkApiConfig } from '@/store'
import type { AlertPropsVariantOverrides } from '@mui/material'

import type { RejectError } from '../../typings'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { unproxify } from '@/utils'

/**
 * Clone order steps.
 *
 * @private
 */
export const updateOrders = createAsyncThunk<
  // Return type of the payload creator
  AlertPropsVariantOverrides,
  // First argument to the payload creator
  uui.domain.ui.forms.BulkOrderUpdate,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/order/bulkUpdate', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const result = await rpc('rpc/order/bulkUpdate', {
      category: 'rpc',
      type: 'rpc/order/bulkUpdate',
      update: unproxify(payload),
    })
    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {cloneOrders}`,
      { tags: ['rpc', 'order'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Orders update Failed`,
      error,
    })
  }
  return []
})
