import { useController } from '../../hooks/useController'
import { InvalidFooter } from './components/InvalidFooter'
import { RegularFooter } from './components/RegularFooter'

export function Footer() {
  const { status } = useController()

  switch (status) {
    case 'ready':
    case 'submitting':
      return <RegularFooter />

    case 'invalid':
      return <InvalidFooter />
  }
}
