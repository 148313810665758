import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    on: translate({ id: 'global.on' }),
    style: translate({ id: 'rm.contextual.styleOnMap' }),
    notAvailableInThePast: translate({ id: 'rm.contextual.devices.notAvailableInThePast' }),

    devicesCounter: (count: number) =>
      translate({ id: 'global.counter.devices', values: { count } }),
  }))

  return api
}
