import type { AsyncThunkApiConfig } from '@/store'
import type { RejectError } from '../../typings'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { unproxify } from '@/utils'

type Params = uui.domain.actions.rpc.simulation.ImportSomeOrdersFromSimulationAction['params']

/**
 * Import some orders from a simulation into Operations of the Active Territory.
 *
 * @private
 */
export const importSomeOrdersFromSimulation = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Params,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/simulation/importSomeOrdersFromSimulation', async (params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/simulation/importSomeOrdersFromSimulation', {
      category: 'rpc',
      type: 'rpc/simulation/importSomeOrdersFromSimulation',
      params: unproxify(params),
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      'Unknown error on {importSomeOrdersFromSimulation}',
      { tags: ['rpc', 'simulation'] },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Deleting Simulations Failed',
      error,
    })
  }
})
