import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    popoutDepots: (selectedDepots: number) =>
      translate({
        id: 'contextualMenu.stylesOnMap.depot.popOut',
        values: { count: selectedDepots },
      }),
  }))

  return api
}
