import { Entry } from '../Entry'
import { JiraLinks } from './JiraLinks'

const ids = [
  10564, 10564, 10764, 10763, 10761, 10133, 10759, 10471, 10762, 10760, 10753, 10779, 10776, 10782,
  10777, 10672, 10569, 10526, 10773, 10717, 10664,
]

export function Entry20210505() {
  return (
    <Entry title="05/05/2021 - v3.0.0-beta-1 fixes">
      Full list of Jira issues fixed:
      <br />
      <br />
      <JiraLinks ids={ids} />
      <br />
      <br />
    </Entry>
  )
}
