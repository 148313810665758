import { useCallback } from 'react'

import { useEditingStateWithCtx, useVehicleFormException } from '@/atoms'
import { createVehicleException } from '@/features/domain/vehicle'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useActions(unifiedVehicle: uui.domain.client.UnifiedVehicle) {
  const { unifiedId } = unifiedVehicle
  const [, setExceptionSettings] = useVehicleFormException()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const { setEditing } = useEditingStateWithCtx('vehicle')

  const onOpenExisting = useCallback(
    (r: uui.domain.rm.Recurrence) => {
      setExceptionSettings({ editMode: 'edit', exception: r.source })
      setEditing([unifiedId])
    },
    [unifiedId, setEditing, setExceptionSettings],
  )

  const onCreate = useCallback(
    async (r: uui.domain.rm.Recurrence) => {
      try {
        setExceptionSettings({ editMode: 'create', exception: r.source })
        const result = await dispatch(
          createVehicleException({ unifiedId, recurrence: r.source, startFromNormalDay: true }),
        )
        if (!createVehicleException.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        setEditing([unifiedId])
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, toast, unifiedId, setEditing, setExceptionSettings],
  )

  return {
    onOpenExisting,
    onCreate,
  }
}
