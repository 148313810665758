import type { TimeFormat } from '../types'

import { startOfToday, format, parseISO, differenceInSeconds } from 'date-fns'

// Meridiem Regex
const meridiemReg = '(?<meridiem>[AaPp])'

// Separators Regex
const separatorsReg = '[:.]'

// These three regex will match all the 24h cases
const formats24 = {
  fourDigits: `(2[0-4]|[0-1][0-9])${separatorsReg}?([0-5]?[0-9])`,
  threeDigits: `([0-9]${separatorsReg}?[0-5][0-9])`,
  lessThanThreeDigits: `([0-9]${separatorsReg}[0-9])|(2[0-4]|[0-1][0-9]|([0-9]$))`,
}

// These three regex will match all the 12h cases
const formats12 = {
  fourDigits: `(0[0-9]|1[0-2])${separatorsReg}[0-5]?[0-9])`,
  threeDigits: `([0-9]${separatorsReg}?[0-5][0-9])`,
  lessThanThreeDigits: `(1[0-2]|0[0-9]|[0-9])`,
}

// Full 24h regex
const regEx24 = `^(?<time24>${formats24.fourDigits}|${formats24.threeDigits}|${formats24.lessThanThreeDigits})`

// Full 12h regex
const regEx12 = `^(?<time12>(${formats12.fourDigits}|${formats12.threeDigits}|${formats12.lessThanThreeDigits}) ?${meridiemReg}`

// Final regex
const regEx = new RegExp(`${regEx12}|${regEx24}`)

// Cache object
const convertTimeInputCache: Map<string, number | 'invalid'> = new Map()

/**
 * This method tries to convert a time-like input to the number of seconds
 * from the midnight to the value.
 *
 * Valid formats (NOTE: meridiem case is ignored and the value can be partial => a/p/A/P):
 * 22:30 am/pm | 22:30am/pm | 22.30 am/pm | 22.30am/pm | 2230 am/pm | 2230am/pm | 4:00 am/pm
 * 1am/pm | 1 | 01 | 0100 | 100
 *
 * If the value can't be parsed the method will return `invalid`.
 */
export function convertTimeInput(input: string, defaultFormat: TimeFormat = 'HH:mm') {
  const timeInput = input.trim()

  // Check for cached results
  const cacheId = `${input}${defaultFormat}`
  if (convertTimeInputCache.has(cacheId)) {
    const cachedResult = convertTimeInputCache.get(cacheId)

    if (cachedResult) return cachedResult
  }

  const groups = timeInput.match(regEx)?.groups

  // If no named matching groups has been found, return 'invalid'
  if (!groups) return 'invalid'

  // If a meridiem (also partial) has been found, let's treat the string
  // as a 12h time. All the other cases can be managed with 24h format

  let timeValue = groups.meridiem ? groups.time12 : groups.time24

  // ------------------------------------------------------
  // START: Let's transform the value in a hmma/HHmm format
  // ------------------------------------------------------

  // If a separator has been found, let's split the value in hours and
  // minutes and fill it with zeroes
  const separator = timeValue.match(/[:.]/)
  if (separator) {
    const [hours, minutes] = timeValue.split(separator[0])
    timeValue = `${hours.padStart(2, '0')}${minutes.padEnd(2, '0')}`
  }
  // If no separator is present, just fill the value with zeroes
  else {
    switch (timeValue.length) {
      case 3:
        // 100 => 0100
        timeValue = `0${timeValue}`
        break
      case 2:
        // 10 => 1000
        timeValue = `${timeValue}00`
        break
      case 1:
        // 1 => 0100
        timeValue = `0${timeValue}00`
        break
    }
  }

  // That hack allows date-fns to parse correctly 24:00
  if (timeValue === '24' || timeValue === '2400') {
    timeValue = '0000'
  }

  const today = startOfToday()

  // Let's create a valid ISO-8601 time string
  let hoursString = timeValue.substr(0, 2)
  switch (groups?.meridiem) {
    case 'a':
    case 'A':
      if (hoursString === '12') {
        hoursString = '00'
      }
      timeValue = `${hoursString}${timeValue.substr(2, 2)}`
      break

    case 'p':
    case 'P':
      /**
       * If time is between 12:00 pm and 12:59 pm there's no need to add 12 hours since the time is
       * already ok (12:15 pm => 12:15 in 24h format)
       */
      if (hoursString === '12') break

      // Let's add 12 hours to match 24h format (3:00 pm => 15:00 in 24h format)
      const hours = parseInt(hoursString, 10)
      timeValue = `${hours + 12}${timeValue.substr(2, 2)}`
      break

    /**
     * Let's fix ambiguous cases where a value without a meridiem could lead
     * to some unwanted conversions like 12:00
     * that in 24h format should be converted to 12:00 (12:00pm)
     * but in 12h format should be converted to 12:00am
     */
    default:
      if (defaultFormat === 'hh:mm aa') {
        const hours = parseInt(hoursString, 10)
        if (hours === 12) {
          timeValue = `00${timeValue.substr(2, 2)}`
        }
      }
      break
  }

  const toParse = `${format(today, 'yyyy-MM-dd')}T${timeValue}`

  // ------------------------------------------------------
  // END
  // ------------------------------------------------------

  // Get the seconds from midnight to the calculated value
  const conversionResult = differenceInSeconds(parseISO(toParse), startOfToday())
  const result = isNaN(conversionResult) ? 'invalid' : conversionResult

  // Store the result into the cache
  convertTimeInputCache.set(cacheId, result)

  return result
}

/**
 * These tests have been used during the development, can be useful once we will introduce testing
 *

test("correctly converts time inputs", () => {
 expect(convertTimeInput("12:00am")).toBe(0);
  expect(convertTimeInput("12:15am")).toBe(15 * 60);
  expect(convertTimeInput("12:15pm")).toBe(12 * 3600 + 15 * 60);
  expect(convertTimeInput("03pm")).toBe(3600 * 15);
  expect(convertTimeInput("2230pm")).toBe(22 * 3600 + 30 * 60);
  expect(convertTimeInput("22:11", "hh:mm aa")).toBe(3600 * 22 + 11 * 60);
  expect(convertTimeInput("22.11", "hh:mm aa")).toBe(3600 * 22 + 11 * 60);
  expect(convertTimeInput("22:11", "HH:mm")).toBe(3600 * 22 + 11 * 60);
  expect(convertTimeInput("22.11", "HH:mm")).toBe(3600 * 22 + 11 * 60);
  expect(convertTimeInput("223pm")).toBe(3600 * 14 + 23 * 60);
  expect(convertTimeInput("2230p")).toBe(3600 * 22 + 30 * 60);
  expect(convertTimeInput("1122")).toBe(3600 * 11 + 22 * 60);
  expect(convertTimeInput("1122", "hh:mm aa")).toBe(3600 * 11 + 22 * 60);
  expect(convertTimeInput("1122", "HH:mm")).toBe(3600 * 11 + 22 * 60);
  expect(convertTimeInput("22:01")).toBe(3600 * 22 + 1 * 60);
  expect(convertTimeInput("22.01")).toBe(3600 * 22 + 1 * 60);
  expect(convertTimeInput("223p")).toBe(3600 * 14 + 23 * 60);
  expect(convertTimeInput("223am")).toBe(3600 * 2 + 23 * 60);
  expect(convertTimeInput("10:00 am")).toBe(3600 * 10);
  expect(convertTimeInput("10:00 AM")).toBe(3600 * 10);
  expect(convertTimeInput("10.00 AM")).toBe(3600 * 10);
  expect(convertTimeInput("10:00 pm")).toBe(3600 * 22);
  expect(convertTimeInput("10.00 pm")).toBe(3600 * 22);
  expect(convertTimeInput("10:00 PM")).toBe(3600 * 22);
  expect(convertTimeInput("10.00 PM")).toBe(3600 * 22);
  expect(convertTimeInput("21:00 AM")).toBe(3600 * 21);
  expect(convertTimeInput("21.00 AM")).toBe(3600 * 21);
  expect(convertTimeInput("22:00 pm")).toBe(3600 * 22);
  expect(convertTimeInput("22.00 pm")).toBe(3600 * 22);
  expect(convertTimeInput("22:00 PM")).toBe(3600 * 22);
  expect(convertTimeInput("22.00 PM")).toBe(3600 * 22);
  expect(convertTimeInput("22:00 am")).toBe(3600 * 22);
  expect(convertTimeInput("22.00 am")).toBe(3600 * 22);
  expect(convertTimeInput("22:00 AM")).toBe(3600 * 22);
  expect(convertTimeInput("22.00 AM")).toBe(3600 * 22);
  expect(convertTimeInput("4:00 AM")).toBe(3600 * 4);
  expect(convertTimeInput("4.00 AM")).toBe(3600 * 4);
  expect(convertTimeInput("4:00 PM")).toBe(3600 * 16);
  expect(convertTimeInput("4.00 PM")).toBe(3600 * 16);
  expect(convertTimeInput("223a")).toBe(3600 * 2 + 23 * 60);
  expect(convertTimeInput("223")).toBe(3600 * 2 + 23 * 60);
  expect(convertTimeInput("223", "HH:mm")).toBe(3600 * 2 + 23 * 60);
  expect(convertTimeInput("223", "hh:mm aa")).toBe(3600 * 2 + 23 * 60);
  expect(convertTimeInput("004")).toBe(4 * 60);
  expect(convertTimeInput("004", "hh:mm aa")).toBe(4 * 60);
  expect(convertTimeInput("004", "HH:mm")).toBe(4 * 60);
  expect(convertTimeInput("040")).toBe(40 * 60);
  expect(convertTimeInput("040", "hh:mm aa")).toBe(40 * 60);
  expect(convertTimeInput("040", "HH:mm")).toBe(40 * 60);
  expect(convertTimeInput("10:00am")).toBe(3600 * 10);
  expect(convertTimeInput("10.00am")).toBe(3600 * 10);
  expect(convertTimeInput("10:00AM")).toBe(3600 * 10);
  expect(convertTimeInput("10.00AM")).toBe(3600 * 10);
  expect(convertTimeInput("10:00PM")).toBe(3600 * 22);
  expect(convertTimeInput("10.00PM")).toBe(3600 * 22);
  expect(convertTimeInput("10:00pm")).toBe(3600 * 22);
  expect(convertTimeInput("10.00pm")).toBe(3600 * 22);
  expect(convertTimeInput("22:00am")).toBe(3600 * 22);
  expect(convertTimeInput("22.00am")).toBe(3600 * 22);
  expect(convertTimeInput("22:00AM")).toBe(3600 * 22);
  expect(convertTimeInput("22.00AM")).toBe(3600 * 22);
  expect(convertTimeInput("22:00pm")).toBe(3600 * 22);
  expect(convertTimeInput("22.00pm")).toBe(3600 * 22);
  expect(convertTimeInput("22:00PM")).toBe(3600 * 22);
  expect(convertTimeInput("22.00PM")).toBe(3600 * 22);

  expect(convertTimeInput("10:00")).toBe(3600 * 10);
  expect(convertTimeInput("10.00")).toBe(3600 * 10);
  expect(convertTimeInput("22:00")).toBe(3600 * 22);
  expect(convertTimeInput("22.00")).toBe(3600 * 22);
  expect(convertTimeInput("12:00")).toBe(3600 * 12);
  expect(convertTimeInput("12:00", "hh:mm aa")).toBe(0);
  expect(convertTimeInput("12:00", "HH:mm")).toBe(3600 * 12);
  expect(convertTimeInput("16:0")).toBe(3600 * 16);
  expect(convertTimeInput("16.0")).toBe(3600 * 16);
  expect(convertTimeInput("4:0")).toBe(3600 * 4);
  expect(convertTimeInput("4.0")).toBe(3600 * 4);
  expect(convertTimeInput("4:10")).toBe(3600 * 4 + 10 * 60);
  expect(convertTimeInput("4.10")).toBe(3600 * 4 + 10 * 60);
  expect(convertTimeInput("4:1")).toBe(3600 * 4 + 10 * 60);
  expect(convertTimeInput("4.1")).toBe(3600 * 4 + 10 * 60);
  expect(convertTimeInput("00.1")).toBe(10 * 60);
  expect(convertTimeInput("0:1")).toBe(10 * 60);
  expect(convertTimeInput("0.1")).toBe(10 * 60);
  expect(convertTimeInput("04:0")).toBe(3600 * 4);
  expect(convertTimeInput("04.0")).toBe(3600 * 4);
  expect(convertTimeInput("16:0")).toBe(3600 * 16);
  expect(convertTimeInput("16.0")).toBe(3600 * 16);
  expect(convertTimeInput("16:00")).toBe(3600 * 16);
  expect(convertTimeInput("16.00")).toBe(3600 * 16);
  expect(convertTimeInput("10am")).toBe(3600 * 10);
  expect(convertTimeInput("10AM")).toBe(3600 * 10);
  expect(convertTimeInput("10 am")).toBe(3600 * 10);
  expect(convertTimeInput("10 AM")).toBe(3600 * 10);
  expect(convertTimeInput("22am")).toBe(3600 * 22);
  expect(convertTimeInput("22pm")).toBe(3600 * 22);
  expect(convertTimeInput("22PM")).toBe(3600 * 22);
  expect(convertTimeInput("1000")).toBe(3600 * 10);
  expect(convertTimeInput("22")).toBe(3600 * 22);
  expect(convertTimeInput("20")).toBe(3600 * 20);
  expect(convertTimeInput("02")).toBe(3600 * 2);
  expect(convertTimeInput("2")).toBe(3600 * 2);
  expect(convertTimeInput("0100")).toBe(60 * 60);
  expect(convertTimeInput("0010")).toBe(60 * 10);
  expect(convertTimeInput("1 am")).toBe(60 * 60);
  expect(convertTimeInput("1 AM")).toBe(60 * 60);
  expect(convertTimeInput("959")).toBe(60 * 9 * 60 + 59 * 60);
  expect(convertTimeInput("0000")).toBe(60 * 0);
  expect(convertTimeInput("0001")).toBe(60 * 1);
  expect(convertTimeInput("2101")).toBe(60 * 60 * 21 + 1 * 60);
  expect(convertTimeInput("1AM")).toBe(60 * 60);
  expect(convertTimeInput("1am")).toBe(60 * 60);
  expect(convertTimeInput("1pm")).toBe(60 * 60 * 13);
  expect(convertTimeInput("24")).toBe(60 * 0);
  expect(convertTimeInput("12")).toBe(60 * 60 * 12);
  expect(convertTimeInput("0")).toBe(60 * 0);
  expect(convertTimeInput("24:00")).toBe(0);
  expect(convertTimeInput("24.00")).toBe(0);
  expect(convertTimeInput("20:14")).toBe(3600 * 20 + 14 * 60);
  expect(convertTimeInput("14:3")).toBe(3600 * 14 + 30 * 60);
  expect(convertTimeInput("14.3")).toBe(3600 * 14 + 30 * 60);

  expect(convertTimeInput("14:7")).toBe("invalid");
  expect(convertTimeInput("14:74")).toBe("invalid");

  expect(convertTimeInput("am")).toBe("invalid");
  expect(convertTimeInput("99")).toBe("invalid");

  expect(convertTimeInput("2412")).toBe("invalid");

  expect(convertTimeInput("24:44")).toBe("invalid");
  expect(convertTimeInput("24:74")).toBe("invalid");
});


 */
