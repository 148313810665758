import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    messageTitle: translate({ id: 'sendMessageToDriverModal.message.title' }),
    messageErrorEmpty: translate({ id: 'sendMessageToDriverModal.message.errors.empty' }),
    messagePlaceholder: translate({ id: 'sendMessageToDriverModal.message.placeholder' }),
    messageErrorLength: translate({ id: 'sendMessageToDriverModal.message.errors.length' }),
  }))

  return api
}
