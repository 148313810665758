import type { ActionStatus } from '../../../../../hooks/useVehiclesNavigoMachine/types'

import { useMemo } from 'react'

import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Edit } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  permission: ActionStatus | 'disabledLoadingGpsData' | 'disabledMultipleVehicles'
  onClick: () => void
}

export function EditButton(props: Props) {
  const { permission, onClick } = props
  const texts = useTexts()

  const disabled = permission.startsWith('disabled')

  const tooltipTitle = useMemo(() => {
    switch (permission) {
      case 'disabledLoadingGpsData':
        return texts.disabledLoadingGpsData

      case 'disabledMultipleVehicles':
        return texts.disabledMultipleVehicles

      case 'disabled':
        return texts.disabledForBeta

      default:
        return ''
    }
  }, [texts, permission])

  return (
    <ReadOnlyTooltip
      canEdit={!disabled}
      title={tooltipTitle}
      render={preventEditing => (
        <NavigoIconButton
          text={texts.edit}
          onClick={onClick}
          disabled={preventEditing || disabled}
          testid="navigo-vehicle-overview-actions-edit"
          Icon={<Edit size={17} color={preventEditing || disabled ? '$silver' : '$nightRider'} />}
        />
      )}
    />
  )
}
