import { useCallback } from 'react'

import { useNotification, useTimeShiftPlan, useIsUnmounted } from '@/hooks'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export function useControllerActions() {
  const timeShiftPlan = useTimeShiftPlan()
  const { close } = useController()
  const isUnmounted = useIsUnmounted()
  const toast = useNotification()
  const texts = useTexts()

  const onConfirmTimeShiftPlan = useCallback(async () => {
    try {
      await timeShiftPlan()
      if (isUnmounted()) return

      close?.()
    } catch (e) {
      toast.error(texts.genericError)
    }
  }, [close, texts, toast, timeShiftPlan, isUnmounted])

  return {
    onConfirmTimeShiftPlan,
  }
}
