import styled from 'styled-components'

import { setAlpha } from '@/styles'

import { Props } from './Button'

const defaultIconSize = 8
const height = 30

type IconContainerProps = {
  placement: 'left' | 'right'
}

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  margin-top: 2px;
  ${p => (p.placement === 'left' ? 'padding-right: 8px;' : '')};
`

export const ButtonPrimitive = styled.button<Props>`
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  width: ${p => (p.autoWidth ? 'auto' : '100%')};
  color: ${p => {
    if (!!p.color) return p.theme.colors[p.color]
    if (!p.preset) return p.defaultValue

    switch (p.preset) {
      case 'cancel':
        return p.theme.colors.$nightRider
      case 'textButton':
        return p.theme.colors.$scienceBlue
      case 'alert':
      case 'confirm':
      case 'footerButton':
        return p.theme.colors.$pureWhite
    }
  }};
  background-color: ${p => {
    if (!p.preset) {
      const color = p.theme.colors[p?.background ?? '$alabaster']
      const alpha = Math.min(Math.max(p?.backgroundAlpha ?? 1, 0), 1)
      return setAlpha(color, alpha)
    }

    switch (p.preset) {
      case 'cancel':
        return p.theme.colors.$alabaster
      case 'confirm':
        return p.theme.colors.$scienceBlue
      case 'alert':
        return p.theme.colors.$outrageousRed
      case 'textButton':
      case 'footerButton':
        return 'transparent'
      default:
        return p.defaultValue
    }
  }};

  ${p =>
    !!p.ellipsis
      ? `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    `
      : ''};
  height: ${height}px;
  border-radius: 4px;
  margin-top: ${p => `${p?.marginTop ?? 0}px`};
  margin-bottom: ${p => `${p?.marginBottom ?? 0}px`};
  margin-left: ${p => `${p?.marginLeft ?? 0}px`};
  margin-right: ${p => `${p?.marginRight ?? 0}px`};
  text-align: ${p => p?.textAlign ?? 'center'};
  outline: ${p => (p.canFocus ? 'auto' : '0')};

  padding-top: ${p => `${p?.paddingTop ?? 0}px`};
  padding-bottom: ${p => `${p?.paddingBottom ?? 0}px`};
  padding-left: ${p => `${p?.paddingLeft ?? 0}px`};
  padding-right: ${p => `${p?.paddingRight ?? 0}px`};

  // Right padding for absolute placed icon
  ${p =>
    p.iconPlacement === 'absoluteRight' && !!p.Icon
      ? `padding-right: ${p.Icon?.props?.size ?? defaultIconSize + 12}px;`
      : ''}

  font-size: ${p => p.theme.fonts.$h3};
  font-weight: ${p => p.theme.weights.$semiBold};
  cursor: pointer;
  border-width: 0;

  transition: color 0.2s ease, background 0.3s ease;

  .o-molecule-button__icon {
    position: absolute;
    top: 50%;
    right: ${p => `${p?.Icon?.props?.size ?? defaultIconSize}px`};
    width: ${p => `${p?.Icon?.props?.size ?? defaultIconSize}px`};
    transform: translateY(-50%);
  }

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
    cursor: default;
  }

  &:hover&:enabled {
    background-color: ${p => {
      if (!p.preset || p?.hover) {
        const color = p.theme.colors[p?.hover?.background ?? p?.background ?? '$alabaster']
        const alpha = Math.min(Math.max(p?.hover?.backgroundAlpha ?? p?.backgroundAlpha ?? 1, 0), 1)
        return setAlpha(color, alpha)
      }

      switch (p.preset) {
        case 'footerButton':
          return setAlpha(p.theme.colors.$pureWhite, 0.1)
      }
    }};
  }
`

ButtonPrimitive.displayName = 'ButtonPrimitive'
