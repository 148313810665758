import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'

import { findRoutePolylineMarker } from './findRoutePolylineMarker'
import { createRoutePolylineFeature } from './createRoutePolylineFeature'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Add new Features for all the RoutePolylines marked for creation in a Changelog coming form the data-layer
 */
export async function changelogAddRoutePolylineFeatures(
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.RoutePolyline>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['routePolyline'],
  markerIds: IterableIterator<string>,
) {
  const features: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findRoutePolylineMarker(markers, markerId)

    features.push(createRoutePolylineFeature(marker, mapStyles))
  }

  // add all new features to the layer
  layer.getSource()?.addFeatures(features)
}
