import { ExecutionEventsFormValues, FormProofOfDelivery } from './typings'

const secondsInADay = 24 * 60 * 60

export const VEHICLE_ASSIGNMENT_AUTOMATIC_ID = 'AUTOMATIC_ASSIGNMENT'

const fromPodBarcodeToPodBarcodeOrUnscanned = (
  podBarcodes: uui.domain.client.rm.BarcodePod[],
  orderStep: uui.domain.client.rm.OrderStep,
): uui.domain.client.rm.BarcodePod[] => {
  const { barcodes: osBarcodes } = orderStep

  const clonePodBarcodes = [...podBarcodes]

  const barcodesInOs = osBarcodes.map(barcode => {
    const podIndex = clonePodBarcodes.findIndex(barcodePod => barcodePod.barcode === barcode)
    if (podIndex >= 0) {
      const result = clonePodBarcodes.splice(podIndex, 1)
      return result[0]
    }
    const unscanned: uui.domain.client.rm.BarcodePod = {
      uuid: 'unscanned',
      sec: -1,
      text: '',
      barcode,
      barcodeStatus: 'UNSCANNED',
      podType: 'barcodes',
      latLng: null,
    }
    return unscanned
  })

  // this case should never happen but it can
  // this one are the barcodes that can be deleted but a pending update
  // from the mobile apps could be queued already when the deletion request resolves
  return [...barcodesInOs, ...clonePodBarcodes]
}

const emptyProofOfDeliveryContainer: uui.domain.client.rm.PodContainer = {
  pictures: undefined,
  note: undefined,
  signatures: undefined,
  audios: undefined,
  barcodes: [],
}

const getPodsFromMap = (
  pods: workwave.Nullable<Record<string, uui.domain.client.rm.Pod>>,
): uui.domain.client.rm.Pod[] => {
  if (!pods) {
    return []
  }
  return Array.from(Object.values(pods))
}

const getFormPods = (
  pods: uui.domain.client.rm.PodContainer,
  os: uui.domain.client.rm.OrderStep,
): FormProofOfDelivery => {
  const { audios, note, pictures, signatures, barcodes } = pods

  return {
    note: note ? note.text || '' : '',
    signatures: signatures?.customer ? [signatures.customer] : [],
    audios: getPodsFromMap(audios),
    pictures: getPodsFromMap(pictures),
    barcodes: fromPodBarcodeToPodBarcodeOrUnscanned(barcodes || [], os),
  }
}

const getFormTrackingData = (
  td: uui.domain.client.rm.OrderStepTrackingData,
): uui.domain.client.rm.OrderStepTrackingData => {
  const { timeInSec, timeOutSec, ...rest } = td

  return {
    timeInSec: timeInSec > secondsInADay ? timeInSec - secondsInADay : timeInSec,
    timeOutSec: timeOutSec > secondsInADay ? timeOutSec - secondsInADay : timeOutSec,
    ...rest,
  }
}

const generateEmptyValue = (os: uui.domain.client.rm.OrderStep): ExecutionEventsFormValues => {
  return {
    isEmpty: true,
    vehicleId: '',
    timeInSec: -1,
    timeInLatLng: undefined,
    timeOutSec: -1,
    timeOutLatLng: undefined,
    status: 'missing',
    statusReason: '',
    statusSec: -1,
    statusLatLng: undefined,
    autoTimeInSec: 0,
    autoTimeInLatLng: undefined,
    autoTimeOutSec: 0,
    autoTimeOutLatLng: undefined,
    latestTrackedLoadLatLng: null,
    latestTrackedLoadSec: -1,
    pods: {
      pictures: undefined,
      signatures: undefined,
      note: undefined,
      audios: undefined,
      barcodes: [],
    },
    dynamicLoads: {},
    customFields: {},
    formPods: {
      pictures: [],
      signatures: [],
      note: '',
      audios: [],
      barcodes: fromPodBarcodeToPodBarcodeOrUnscanned([], os),
    },
  }
}

const clonePod = (pod: uui.domain.client.rm.Pod): uui.domain.client.rm.Pod => {
  return {
    ...pod,
    latLng: pod.latLng ? { ...pod.latLng } : null,
  }
}

const clonePods = (
  podContainer: uui.domain.client.rm.PodContainer,
): uui.domain.client.rm.PodContainer => {
  const audios = podContainer.audios
    ? Object.entries(podContainer.audios).reduce<Record<string, uui.domain.client.rm.Pod>>(
        (acc, [key, pod]) => {
          acc[key] = clonePod(pod)
          return acc
        },
        {},
      )
    : undefined

  const pictures = podContainer.pictures
    ? Object.entries(podContainer.pictures).reduce<Record<string, uui.domain.client.rm.MediaPod>>(
        (acc, [key, pod]) => {
          acc[key] = clonePod(pod) as uui.domain.client.rm.MediaPod
          return acc
        },
        {},
      )
    : undefined

  return {
    audios,
    barcodes: podContainer.barcodes?.map(pod => clonePod(pod) as uui.domain.client.rm.BarcodePod),
    note: podContainer.note
      ? (clonePod(podContainer.note) as uui.domain.client.rm.NotePod)
      : undefined,
    pictures,
    signatures: podContainer.signatures?.customer
      ? { customer: clonePod(podContainer.signatures?.customer) }
      : undefined,
  }
}

const cloneTrackingData = (
  td: uui.domain.client.rm.OrderStepTrackingData,
): uui.domain.client.rm.OrderStepTrackingData => {
  return {
    ...td,
    timeInLatLng: td.timeInLatLng ? [...td.timeInLatLng] : undefined,
    timeOutLatLng: td.timeOutLatLng ? [...td.timeOutLatLng] : undefined,
    statusLatLng: td.statusLatLng ? [...td.statusLatLng] : undefined,
    autoTimeInLatLng: td.autoTimeInLatLng ? [...td.autoTimeInLatLng] : undefined,
    autoTimeOutLatLng: td.autoTimeOutLatLng ? [...td.autoTimeOutLatLng] : undefined,
    pods: clonePods(td.pods),
  }
}

export const getFormInitialValues = (
  source?: uui.domain.client.rm.ExtendedOrderStep,
): ExecutionEventsFormValues => {
  if (!source?.hasVehicle) {
    throw new Error('can not generate tracking data if no source order or not vehicle available')
  }

  if (!source?.orderStep.trackingData) {
    return generateEmptyValue(source.orderStep)
  }

  const trackingData = cloneTrackingData(source.orderStep.trackingData)

  if (!trackingData.status) {
    trackingData.status = 'missing'
  }

  const formTrackingData = getFormTrackingData(trackingData)

  const podsContainer = trackingData.pods || emptyProofOfDeliveryContainer

  return {
    ...formTrackingData,
    dynamicLoads: computeDynamicLoadsInitialValues(source.order.loads, trackingData.loadTrackedMap),
    customFields: computeCustomFieldsInitialValues(
      source.orderStep.customFields,
      trackingData.customFieldTrackedMap,
    ),
    formPods: getFormPods(podsContainer, source.orderStep),
  }
}

function computeDynamicLoadsInitialValues(
  loads: uui.domain.client.rm.Order['loads'],
  loadTrackedMap: uui.domain.client.rm.OrderStepTrackingData['loadTrackedMap'],
) {
  const dynamicLoads: ExecutionEventsFormValues['dynamicLoads'] = {}

  for (const load of Object.keys(loads)) {
    const trackedValue = loadTrackedMap?.[load]
    dynamicLoads[load] = trackedValue
      ? {
          value: trackedValue.value / 100,
          disabled: false,
          sec: trackedValue.timeSec,
        }
      : {
          disabled: true,
        }
  }

  return dynamicLoads
}

function computeCustomFieldsInitialValues(
  orderStepCustomFields: uui.domain.client.rm.OrderStep['customFields'],
  customFieldTrackedMap: uui.domain.client.rm.OrderStepTrackingData['customFieldTrackedMap'],
) {
  const customFields: ExecutionEventsFormValues['customFields'] = {}

  for (const cf of Object.keys(orderStepCustomFields)) {
    const trackedValue = customFieldTrackedMap?.[cf]
    customFields[cf] = trackedValue
      ? {
          value: trackedValue.value,
          disabled: false,
          sec: trackedValue.timeSec,
        }
      : {
          disabled: true,
        }
  }

  return customFields
}

const getDeletedPods = (value: uui.domain.client.rm.Pod[], initial: uui.domain.client.rm.Pod[]) => {
  const res: string[] = []

  for (const initialPod of initial) {
    const index = value.findIndex(p => p.uuid === initialPod.uuid)
    if (index === -1) {
      res.push(initialPod.uuid)
    }
  }
  return res
}

const getUpdatedBarcodePods = (
  value: uui.domain.client.rm.BarcodePod[],
  initial: uui.domain.client.rm.BarcodePod[],
): uui.domain.client.rm.BarcodePod[] => {
  return value.filter(pod => {
    const initialPod = initial.find(p => p.uuid === pod.uuid)
    if (!initialPod) return false
    return pod.barcodeStatus !== initialPod.barcodeStatus
  })
}

const patchUpdate = (
  fieldName: string,
  formValues: ExecutionEventsFormValues,
  initialValues: ExecutionEventsFormValues,
  workingDayStartSec: number,
) => {
  switch (fieldName) {
    case 'timeOutSec':
    case 'timeInSec': {
      const isOvernight = workingDayStartSec > 0
      const fieldValue = fieldName === 'timeInSec' ? formValues.timeInSec : formValues.timeOutSec

      if (fieldValue === -1) {
        // -1 means that the field has to be deleted
        return {}
      }

      const value =
        isOvernight && fieldValue < workingDayStartSec ? fieldValue + secondsInADay : fieldValue

      return {
        [fieldName]: value,
      }
    }

    case 'status':
      return {
        status: formValues.status,
      }

    case 'statusReason':
      return {
        statusReason: formValues.statusReason,
      }

    case 'formPods.note': {
      if (formValues.formPods.note.trim().length === 0) {
        // in this case no note field will be set, and it will be set in the remove
        return {}
      }
      return {
        note: formValues.formPods.note,
      }
    }

    case 'formPods.barcodes': {
      return {
        barcodes: getUpdatedBarcodePods(
          formValues.formPods.barcodes,
          initialValues.formPods.barcodes,
        ),
      }
    }

    case 'dynamicLoads': {
      return {
        dynamicLoads: computeDynamicLoads(formValues.dynamicLoads, initialValues.dynamicLoads),
      }
    }

    case 'customFields': {
      return {
        customFields: computeCustomFields(formValues.customFields, initialValues.customFields),
      }
    }
  }
}

function computeDynamicLoads(
  fieldValue: ExecutionEventsFormValues['dynamicLoads'],
  initialValue: ExecutionEventsFormValues['dynamicLoads'],
) {
  return Object.entries(fieldValue).reduce<
    uui.domain.ui.forms.ExecutionEventsUpdate['dynamicLoads']
  >((acc, [key, value]) => {
    // if the field is disabled, skip it
    if (value.disabled) return acc

    const original = initialValue[key]
    // filter out the values that are not changed
    if (original.disabled || original.value === value.value) return acc

    acc[key] = {
      value: value.value * 100,
      sec: value.sec,
    }
    return acc
  }, {})
}

function computeCustomFields(
  fieldValue: ExecutionEventsFormValues['customFields'],
  initialValue: ExecutionEventsFormValues['customFields'],
) {
  return Object.entries(fieldValue).reduce<
    uui.domain.ui.forms.ExecutionEventsUpdate['customFields']
  >((acc, [key, value]) => {
    // if the field is disabled, skip it
    if (value.disabled) return acc

    const original = initialValue[key]
    // filter out the values that are not changed
    if (original.disabled || original.value === value.value) return acc

    acc[key] = {
      value: value.value,
      sec: value.sec,
    }
    return acc
  }, {})
}

const patchRemove = (
  fieldName: string,
  formValues: ExecutionEventsFormValues,
  initialValues: ExecutionEventsFormValues,
) => {
  switch (fieldName) {
    case 'timeOutSec':
    case 'timeInSec': {
      const fieldValue = fieldName === 'timeInSec' ? formValues.timeInSec : formValues.timeOutSec
      if (fieldValue !== -1) {
        return {}
      }

      return {
        [fieldName]: true,
      }
    }

    case 'formPods.note': {
      if (formValues.formPods.note.trim().length === 0) {
        // in this case no note field will be set, and it will be set in the remove
        return {
          note: true,
        }
      }

      return {}
    }

    case 'formPods.pictures': {
      return {
        pictures: getDeletedPods(formValues.formPods.pictures, initialValues.formPods.pictures),
      }
    }

    case 'formPods.audios': {
      return {
        audios: getDeletedPods(formValues.formPods.audios, initialValues.formPods.audios),
      }
    }

    case 'formPods.signatures': {
      return {
        signatures: getDeletedPods(
          formValues.formPods.signatures,
          initialValues.formPods.signatures,
        ),
      }
    }
  }
}

const generateUpdate = (
  formValues: ExecutionEventsFormValues,
  initialValues: ExecutionEventsFormValues,
  dirtyFields: string[],
  workingDayStartSec: number,
): Partial<uui.domain.ui.forms.ExecutionEventsUpdate> => {
  let update: Partial<uui.domain.ui.forms.ExecutionEventsUpdate> = {}

  for (const field of dirtyFields) {
    update = {
      ...update,
      ...patchUpdate(field, formValues, initialValues, workingDayStartSec),
    }
  }

  return update
}

const generateRemove = (
  formValues: ExecutionEventsFormValues,
  initialValues: ExecutionEventsFormValues,
  dirtyFields: string[],
): Partial<uui.domain.ui.forms.ExecutionEventsRemove> => {
  let remove: Partial<uui.domain.ui.forms.ExecutionEventsRemove> = {}

  for (const field of dirtyFields) {
    remove = {
      ...remove,
      ...patchRemove(field, formValues, initialValues),
    }
  }

  return remove
}

export const getExecutionEventsChanges = (
  id: string,
  formValues: ExecutionEventsFormValues,
  initialValues: ExecutionEventsFormValues,
  dirtyFields: string[],
  workingDayStartSec: number,
): uui.domain.ui.forms.ExecutionEventsChanges => {
  const update = generateUpdate(formValues, initialValues, dirtyFields, workingDayStartSec)
  const remove = generateRemove(formValues, initialValues, dirtyFields)

  return {
    id,
    update,
    remove,
  }
}
