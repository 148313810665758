import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (exception: string) =>
      translate({ id: 'vehicles.form.exceptionSlider.title', values: { exception } }),
    subtitle: (count: number) =>
      count === 0
        ? translate({
            id: 'vehicles.form.exceptionSlider.subtitle.noException',
          })
        : translate({
            id: 'vehicles.form.exceptionSlider.subtitle',
            values: { count },
          }),
    showAll: translate({ id: 'global.showAll' }),
  }))

  return api
}
