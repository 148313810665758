import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRoutes } from '@/features/domain/route'
import { selectOrderSteps } from '@/features/domain/order'

function getOrderStepIdsFromRouteInfos(
  routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[],
  extRoutes: Record<string, uui.domain.client.rm.ExtendedRoute>,
) {
  return routeInfos.reduce<Set<string>>((acc, info) => {
    const extRoute = extRoutes[info.id]

    if (!extRoute) return acc

    extRoute.steps.forEach(aggregatedSteps => {
      aggregatedSteps.steps.forEach(step => {
        if (step.orderStepId) {
          acc.add(step.orderStepId)
        }
      })
    })

    return acc
  }, new Set<string>())
}

export function useOrdersData(routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[]) {
  const extRoutes = useSelector(selectRoutes)
  const orderSteps = useSelector(selectOrderSteps)

  // Retrieve orderStepIds for each routeInfo
  const orderStepIds = useMemo(
    () => Array.from(getOrderStepIdsFromRouteInfos(routeInfos, extRoutes)),
    [extRoutes, routeInfos],
  )

  // Compute total violations number
  const totalViolations = useMemo(() => {
    let total = 0

    for (const orderStepId of orderStepIds) {
      const orderStep = orderSteps[orderStepId]

      total += orderStep.isUnassigned ? 0 : orderStep.violations?.length ?? 0
    }

    return total
  }, [orderSteps, orderStepIds])

  const activeRange = useMemo(() => {
    const dates = routeInfos.map(info => info.dateAsString).sort()
    if (dates.length === 0) return

    const startDate = dates[0]
    const endDate = dates[dates.length - 1]

    return startDate !== endDate ? { startDate, endDate } : { startDate }
  }, [routeInfos])

  return {
    activeRange,
    orderStepIds,
    totalViolations,
  }
}
