import { useEffect } from 'react'

import { useInitializeMapOnTerritoryChange } from '@/hooks'
import { subscribeSchedulerProject } from '@/scheduler'
import { useDomainMessage } from '@/store'
import { subscribeMap } from '@/map'

import {
  subscribeSchedulerTimeSpanChangeCalendarRange,
  subscribeSchedulerTimeSpanReplaceDomainData,
  subscribeChangeCalendarRange,
  subscribeReplaceDomainData,
  subscribeTransactionState,
  subscribeApplicationMode,
  subscribeLoadingState,
  subscribeAccountsData,
  subscribeAppLanguage,
} from '@/atoms'
import { createMapTiles } from '../../map/atoms/map/utils/createMapTiles'

export function DomainSubscriptions() {
  // toast notifications
  useDomainMessage()

  // Fit map on all orders and depots once the territory changes
  useInitializeMapOnTerritoryChange()

  useEffect(() => {
    const unsubscribeSchedulerProject = subscribeSchedulerProject()

    // Subscribe atoms to domain notifications
    subscribeSchedulerTimeSpanChangeCalendarRange()
    subscribeSchedulerTimeSpanReplaceDomainData()
    subscribeChangeCalendarRange()
    subscribeReplaceDomainData()
    subscribeTransactionState()
    subscribeApplicationMode()
    subscribeAccountsData()
    subscribeLoadingState()
    subscribeAppLanguage()
    createMapTiles()
    subscribeMap()

    return () => {
      unsubscribeSchedulerProject()
    }
  }, [])

  return null
}
