import { CustomStyles, CssNormalize } from '@/styles'
import { CssNormalize as CoreCssNormalize } from '@/local/components'

/**
 * Adds all the global styles.
 */
export const GlobalStyleProvider = () => {
  return (
    <>
      <CssNormalize />
      <CoreCssNormalize />
      <CustomStyles />
    </>
  )
}
