import styled from 'styled-components'

const styledComponent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  margin-right: 16px;

  font-size: ${p => p.theme.fonts.$p2};
  font-weight: ${p => p.theme.weights.$semiBold};

  color: ${p => p.theme.colors.$pureWhite};
  background: ${p => p.theme.colors.$nightRider};
  border: 1px solid ${p => p.theme.colors.$pureWhite};
  border-radius: 50%;
`

styledComponent.displayName = 'AvatarContainer'
export default styledComponent
