import type { PermissionData } from '../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfigurationType } from '@/features/domain/user'

/**
 * This hook computes every data required to compose permissions
 */
export function usePermissionsData(): PermissionData {
  const userType = useSelector(selectUserConfigurationType)

  return useMemo(() => {
    return {
      rmOnly: userType === 'rmOnly',
    }
  }, [userType])
}
