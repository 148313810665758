import type { DrawAreaProps as Props } from './types'

// import { Block, Label, FieldMessage } from '@/forms-legacy'
import { useConnectDrawAreaField } from './useConnectDrawAreaField'

export function DrawAreaField(props: Props) {
  const {
    formProps: {
      input: { onChange },
      meta: { initial: editableAreaInitialPoints },
    },

    editableAreaId,
    editableAreaColor,
  } = props

  // connect and initialize to the EditableArea atom!
  useConnectDrawAreaField(onChange, editableAreaId, editableAreaColor, editableAreaInitialPoints)

  return null
}
