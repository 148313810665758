import { useRef } from 'react'

import { RenderFrom } from '@/components/Navigo/components/RenderFrom'
import { VerticalLayout, HorizontalLayout } from '@/components/layout'
import { useComponentSize } from '@/local/hooks'
import { Text } from '@/local/components'

import { useRoutesWithExecutionEventsCount } from './hooks/useRoutesWithExecutionEventsCount'
import { useApprovedRoutesCount } from './hooks/useApprovedRoutesCount'
import { useTexts } from './useTexts'

interface Props {
  routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[]
}

export function Bulk(props: Props) {
  const { routeInfos } = props

  const ref = useRef<HTMLDivElement | null>(null)
  const [{ width }] = useComponentSize(ref)

  const routesWithExecutionEventsCount = useRoutesWithExecutionEventsCount(routeInfos)
  const approvedCount = useApprovedRoutesCount(routeInfos)
  const texts = useTexts()

  const title = texts.approvedRoutes(approvedCount, routeInfos.length - approvedCount)
  const subtitle = texts.routesWithExecutionEvents(routesWithExecutionEventsCount)

  return (
    <HorizontalLayout
      data-trackid="navigo-routes-overview-stats-bulk"
      data-testid="navigo-routes-overview-stats-bulk"
      ref={ref}
    >
      <RenderFrom width={width} renderFrom={250}>
        <VerticalLayout ref={ref} justifyContent="space-between">
          <Text testid="navigo-routes-overview-stats-approvedRoutes" size="$p3">
            {title}
          </Text>
          <Text testid="navigo-routes-overview-stats-routesWithExecutionEvents" size="$p3">
            {subtitle}
          </Text>
        </VerticalLayout>
      </RenderFrom>
    </HorizontalLayout>
  )
}
