import { produce } from 'immer'

import { gis, locationIsInvalid } from '@/server-data'
import { locationUtils } from '@/utils'

import { reverseGeocodeLocation } from '../../../utils/reverseGeocodeLocation'
import { getMapInfo } from '../../map/hooks/useGetMapInfo'

import { locationPin } from '../locationPin'

import { updateLocationPin } from './updateLocationPin'
import { createLocationPin } from './utils/createLocationPin'

/**
 * Create the location pin and immediately reverse geocode it.
 */
export async function addLocationPin(
  id: uui.domain.ui.map.LocationPinId,
  latLng: uui.domain.LatLng,
  pin?: Partial<uui.domain.ui.map.LocationPin['pin']>,
  info: Partial<uui.domain.ui.map.LocationPin['info']> = {},
  hidden: boolean = false,
  pinLabel: string | undefined = undefined,
) {
  const { bounds } = getMapInfo()

  // immediately add the locationPin.
  // It will be updated later
  const targetPin = createLocationPin(id, latLng, pin, info, hidden, pinLabel)
  locationPin.pins[id] = targetPin

  if (!targetPin) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`Failed to add a LocationPin with ID: ${id}`)
    }
    return
  }

  const pinLatLngInvalid = !gis.validLatLng(targetPin.pin.latLng.lat, targetPin.pin.latLng.lng)

  const locationInvalid = !!targetPin.pin.location && locationIsInvalid(targetPin.pin.location)

  // If the locationInfo object contains invalid lat-lng
  const locationWithError =
    pinLatLngInvalid || locationInvalid
      ? locationUtils.createEmptyLocation({
          address: undefined,
          geoAddress:
            targetPin.pin.location && targetPin.pin.location.geoAddress.trim().length > 0
              ? targetPin.pin.location.geoAddress
              : gis.latLngToString(targetPin.pin.latLng),
          source: targetPin.pin.location ? targetPin.pin.location.source : 'MANUAL',
          status: 'GEOCODING_ERROR',
        })
      : undefined

  // If the locationInfo object is valid and it's been already pre-populated do nothing
  if (
    !locationWithError &&
    targetPin.pin.location &&
    targetPin.pin.location.geoAddress.trim().length > 0
  ) {
    return
  }

  if (!locationWithError) {
    // the provided location is valid
    // try to reverse-geocode it

    // set loading status to true
    updateLocationPin(id, { loading: true })

    const location = await reverseGeocodeLocation(
      targetPin.pin.latLng,
      bounds,
      targetPin.pin.snapToRoad,
    )

    if (!location) {
      updateLocationPin(id, { loading: false })
      return
    }

    // update the location pin
    return updateLocationPin(id, prev => {
      return produce(prev, draft => {
        draft.loading = false
        draft.pin.location = location
        draft.pin.latLng = location.latLng
        draft.pin.location.address = location.geoAddress
      })
    })
  }
}
