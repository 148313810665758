import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function ShowVehicle(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 21 20">
        <path
          d="M8.65.01c.44 0 7.82-.12 8.69.74s.72 8.41.72 8.87A9.46 9.46 0 0 0 8.75.01z"
          fill="rgb(195, 195, 195)"
        ></path>
        <path
          d="M8.5 1.09A8.5 8.5 0 1 1 0 9.59a8.51 8.51 0 0 1 8.5-8.5z"
          fill="rgb(48, 48, 48)"
        ></path>
      </svg>
    </IconContainer>
  )
}
