import type { MouseEventHandler } from 'react'

import { Button } from '@mui/material'

type Props = {
  label: string
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  testId?: string
  className?: string
  onClick: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  variant?: 'text' | 'contained' | 'outlined'
}

export function FormActionButton(props: Props) {
  const {
    label,
    onClick,
    testId,
    disabled,
    color = 'primary',
    variant = 'text',
    className = '',
  } = props

  return (
    <Button
      size="small"
      color={color}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      className={className}
      data-testid={testId}
      data-trackid={testId}
    >
      {label}
    </Button>
  )
}
