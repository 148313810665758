import type { InspectPin } from '../inspectPin'

import { useSnapshot } from 'valtio'

import { setInspectPin } from '../core/setInspectPin'
import { inspectPin } from '../inspectPin'

/**
 * Get the current inspect pin and subscribe to its updates.
 */

export function useInspectPin(): [InspectPin, typeof setInspectPin] {
  return [useSnapshot(inspectPin).pin, setInspectPin]
}
