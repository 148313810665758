import styled from 'styled-components'

import { NumericInput, NumericInputProps } from '@/forms-legacy'

interface Props extends NumericInputProps {
  useSpace?: boolean
  noRightRadius?: boolean
}

const LoadField = styled(NumericInput)<Props>`
  width: 120px;

  border-width: 0;
  color: ${p => p.theme.colors.$nightRider};
  background: ${p => p.theme.colors.$pureWhite};

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  ${p =>
    p.noRightRadius
      ? `
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`
      : ''}

  margin-right: ${p => (p.useSpace ? 1 : 0)}px;

  height: 24px;
`
LoadField.displayName = 'LoadField'
export default LoadField
