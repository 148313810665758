import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'
import { Body } from './Body'
import { Header } from './Header'
import { Footer } from './Footer'
import { PendingBody } from './PendingBody'

type Props = {
  orderStepIds: string[]
}

export function Content(props: Props) {
  const { ready, status } = useConfigureController(props.orderStepIds)

  if (!ready) return null

  return (
    <ModalContent header={<Header />} footer={<Footer />}>
      {status === 'pending' ? <PendingBody /> : <Body />}
    </ModalContent>
  )
}
