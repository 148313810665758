import { useEffect, useLayoutEffect, useRef } from 'react'

import { subscribeToEditableRoadSegment, updateEditableRoadSegment } from '@/map'

export function useConnectDrawRoadSegmentField(
  changeValue: (value: { start: uui.domain.LatLng; end: uui.domain.LatLng }) => void,
  roadSegmentId: uui.domain.ui.map.EditableRoadSegment['id'],
  roadSegmentType: 'blocked' | 'delayed' | 'disabled',
  roadSegmentCoordinates?: { start: uui.domain.LatLng; end: uui.domain.LatLng },
) {
  const rData = useRef({ roadSegmentId, roadSegmentType, roadSegmentCoordinates, changeValue })
  useLayoutEffect(
    () =>
      void (rData.current = {
        roadSegmentId,
        roadSegmentType,
        roadSegmentCoordinates,
        changeValue,
      }),
  )

  // INIT Edit/creation session
  useEffect(() => {
    const { roadSegmentId, roadSegmentType, roadSegmentCoordinates } = rData.current

    if (roadSegmentCoordinates) {
      // ROAD SEGMENT EDIT
      updateEditableRoadSegment(() => ({
        editableSegment: roadSegmentCoordinates,
        type: roadSegmentType,
        id: roadSegmentId,
        mode: 'edit',
      }))
    } else {
      // ROAD SEGMENT CREATION
      updateEditableRoadSegment(() => ({
        type: roadSegmentType,
        id: roadSegmentId,
        mode: 'create',
      }))
    }

    return () => {
      // RESET on unmount
      updateEditableRoadSegment(() => ({ id: 'none', mode: 'none', type: 'disabled' }))
    }
  }, [])

  // UPDATE ATOM SHAPE COLOR
  // Form event affecting the ATOM
  useEffect(() => {
    updateEditableRoadSegment({ type: roadSegmentType })
  }, [roadSegmentType])

  // UPDATE STORED POINTS
  // Atom event affecting the Form
  useEffect(() => {
    const { roadSegmentCoordinates, changeValue } = rData.current
    let prevCoords = roadSegmentCoordinates

    subscribeToEditableRoadSegment(roadSegment => {
      if (roadSegment.mode === 'edit') {
        if (prevCoords === roadSegment.editableSegment) return

        prevCoords = roadSegment.editableSegment
        changeValue(roadSegment.editableSegment)
      }
    }, false)
  }, [])
}
