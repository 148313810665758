import { type Components } from '@mui/material'

export const MuiTypography: Components['MuiTypography'] = {
  variants: [
    {
      props: { variant: 'avatar' },
      style: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '14px',
        letterSpacing: '0.14px',
      },
    },
  ],
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    avatar: true
  }
}
