import { useHandleOnConfirm } from './useHandleOnConfirm'
import { useHandleOnChangeFile } from './useHandleOnChangeFile'
import { useHandleOnNameChange } from './useHandleOnNameChange'
import { useHandleOnDragAndDropFile } from './useHandleOnDragAndDropFile'

export const useControllerActions = () => {
  return {
    handleOnConfirm: useHandleOnConfirm(),
    handleOnChangeFile: useHandleOnChangeFile(),
    handleOnNameChange: useHandleOnNameChange(),
    handleOnDragAndDropFile: useHandleOnDragAndDropFile(),
  }
}
