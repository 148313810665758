import { validateMaxSpeedLimit } from './validations/validateMaxSpeedLimit'
import { validateIdleStart } from './validations/validateIdleStart'
import { validateDriverBehaviors } from './validations/validateDriverBehaviors'
import { validatePartialDriverBehaviors } from './validations/validatePartialDriverBehaviors'
import { validateSpeedLimit } from './validations/validateSpeedLimit'

export function validateDeviceData(
  deviceData: uui.domain.ui.forms.DeviceData,
): uui.domain.ui.forms.DeviceDataValidation {
  const {
    hasDriverBehaviorCapability,
    acceleration,
    cornering,
    braking,
    speedLimit,
    maxSpeedLimit,
    idleStart,
  } = deviceData

  const maxSpeedLimitError = validateMaxSpeedLimit(maxSpeedLimit)
  const idleStartError = validateIdleStart(idleStart)

  const brakingError = validateDriverBehaviors(braking, hasDriverBehaviorCapability)
  const accelerationError = validateDriverBehaviors(acceleration, hasDriverBehaviorCapability)
  const corneringError = validatePartialDriverBehaviors(cornering, hasDriverBehaviorCapability)

  const speedLimitError = validateSpeedLimit(speedLimit)

  return {
    braking: brakingError,
    acceleration: accelerationError,
    cornering: corneringError,
    speedLimit: !!speedLimitError
      ? {
          threshold: speedLimitError,
        }
      : undefined,
    maxSpeedLimit:
      hasDriverBehaviorCapability && !!maxSpeedLimitError
        ? {
            threshold: maxSpeedLimitError,
          }
        : undefined,

    idleStart: !!idleStartError
      ? {
          custom: idleStartError,
        }
      : undefined,
  }
}
