import { VerticalLayout } from '@/components/layout'
import { Text } from '@/local/components'

import { useViolationsCountFromRoutes } from '../hooks/useViolationsCountFromRoutes'
import { RouteViolationTag } from './components/RouteViolationTag'
import { useTexts } from './useTexts'

interface Props {
  routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[]
}

export function Bulk(props: Props) {
  const { routeInfos } = props

  const routeViolations = useViolationsCountFromRoutes(routeInfos)
  const texts = useTexts()

  return (
    <VerticalLayout
      data-trackid="navigo-routes-overview-details-bulk"
      data-testid="navigo-routes-overview-details-bulk"
      justifyContent="space-between"
    >
      <Text size="$l" weight="$semiBold" testid="navigo-routes-overview-details-bulk-routesCount">
        {texts.routes(routeInfos.length)}
      </Text>

      {(routeViolations.constraintsCount > 0 || routeViolations.violationsCount > 0) && (
        <RouteViolationTag violations={routeViolations} />
      )}
    </VerticalLayout>
  )
}
