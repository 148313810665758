import { useCallback } from 'react'
import { proxy, useSnapshot } from 'valtio'

export type ModalState = {
  modalId: string
  config: {
    maxHeight: number
    disableBackdropClick: boolean
    disableEscapeKeyDown: boolean
  }
}

type ModalStateAtom = {
  state: ModalState
}

// ------------------------------------
// Default values
// ------------------------------------

const defaultModalState = {
  modalId: 'none',
  config: {
    maxHeight: 500,
    disableBackdropClick: false,
    disableEscapeKeyDown: false,
  },
}

// ------------------------------------
// Modal state atom
// ------------------------------------

const modalStateAtom = proxy<ModalStateAtom>({
  state: defaultModalState,
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetModalState = (prev: ModalState) => ModalState
type SetModalStateParam = SetModalState | Partial<ModalState> | 'reset'

export function resetModalState() {
  modalStateAtom.state = { ...defaultModalState }
}

export function setModalState(valueOrFunc: SetModalStateParam) {
  if (valueOrFunc === 'reset') resetModalState()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(modalStateAtom.state, valueOrFunc(modalStateAtom.state))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      modalStateAtom.state[field] = valueOrFunc[field]
    }
  }

  return modalStateAtom
}

// ------------------------------------
// React Hooks
// ------------------------------------

export function useShowModal(modalId: string, config?: ModalState['config']) {
  return useCallback(() => setModalState({ modalId, config: config }), [modalId, config])
}

export function useCloseModal() {
  return resetModalState
}

export function useCurrentModal() {
  return useSnapshot(modalStateAtom).state
}
