export function countVehicleViolations(violations: uui.domain.rm.RouteViolations) {
  if (!violations.vehicle) return 0

  let count = violations.vehicle.length

  if (violations.loads) {
    count -= 1 // remove one if there are loads
    count += violations.loads.length
  }

  return count
}

export function countStepsViolations(violations: uui.domain.rm.RouteViolations) {
  if (!violations.steps) return 0

  return Object.values(violations.steps).reduce<number>(
    (count, stepViolations) => count + stepViolations.length,
    0,
  )
}

export function countViolations(violations: uui.domain.rm.RouteViolations) {
  const vehicleViolations = countVehicleViolations(violations)
  const stepsViolations = countStepsViolations(violations)

  return vehicleViolations + stepsViolations
}
