import { useCallback } from 'react'

import { setMainSelection } from '@/atoms'

export function useSelectRoute(extOrder: uui.domain.client.rm.ExtendedOrderStep) {
  return useCallback(() => {
    if (extOrder.isUnassigned) return

    setMainSelection('routes', [extOrder.routeId])
  }, [extOrder])
}
