import { styled } from '@mui/material'
import { applyAlphaToColor } from '@/local/components'

type Props = {
  blocking?: boolean
}

export const FullscreenContainer = styled('div', {
  name: 'FullscreenContainer',
  shouldForwardProp: prop => prop !== 'blocking',
})<Props>(({ blocking }) => ({
  top: 0,
  left: 0,
  zIndex: 1000,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  position: 'fixed',
  willChange: 'transform, opacity',
  transition: 'opacity 1000ms ease',
  flexDirection: 'column',
  pointerEvents: blocking ? 'auto' : 'none',
  backgroundColor: applyAlphaToColor('#FFFFFF', blocking ? 0.9 : 0),
}))
