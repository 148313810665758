import { PureComponent } from 'react'
import { Field as FinalFormField, FieldRenderProps } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import VehicleLoadsListField from './VehicleLoadsListField'
import { PublicProps as Props } from './typings'

export default class LoadsFieldWrapper extends PureComponent<Props> {
  render() {
    const { name, ...props } = this.props

    return (
      <FinalFormField
        {...props}
        name={name}
        isEqual={isDeepEqual}
        render={(formProps: FieldRenderProps<any>) => {
          return <VehicleLoadsListField {...this.props} formProps={formProps} />
        }}
      />
    )
  }
}
