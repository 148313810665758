import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Spinner(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M16 4c-1.10547 0-2 .89453-2 2s.89453 2 2 2 2-.89453 2-2-.89453-2-2-2zm7.0625 2.65625c-1.2422 0-2.25 1.0078-2.25 2.25s1.0078 2.25 2.25 2.25 2.25-1.0078 2.25-2.25-1.0078-2.25-2.25-2.25zM8.90625 7.1875c-.96484 0-1.75.78516-1.75 1.75s.78516 1.75 1.75 1.75 1.75-.78516 1.75-1.75-.78516-1.75-1.75-1.75zM26 13.5c-1.3789 0-2.5 1.1211-2.5 2.5s1.1211 2.5 2.5 2.5 2.5-1.1211 2.5-2.5-1.1211-2.5-2.5-2.5zm-20 1c-.82813 0-1.5.67188-1.5 1.5 0 .82813.67188 1.5 1.5 1.5.82813 0 1.5-.67188 1.5-1.5 0-.82813-.67188-1.5-1.5-1.5zm17.09375 5.5625c-1.65625 0-3 1.34375-3 3s1.34375 3 3 3 3-1.34375 3-3-1.34375-3-3-3zM8.6875 21.84375c-.5703.1172-1 .64453-1 1.25 0 .6914.5586 1.25 1.25 1.25s1.25-.5586 1.25-1.25-.5586-1.25-1.25-1.25c-.08594 0-.16797-.01563-.25 0zM16 25c-.55078 0-1 .44922-1 1s.44922 1 1 1 1-.44922 1-1-.44922-1-1-1z" />
      </svg>
    </IconContainer>
  )
}
