import { TextField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

const wrappedInputProps = {
  maxLength: 32,
}

export function Name() {
  const texts = useTexts()
  return (
    <TextField
      name="gps.name"
      label={texts.name}
      testid="deviceName"
      wrappedInputProps={wrappedInputProps}
    />
  )
}
