import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

interface Payload {
  latLng: uui.domain.LatLng
  bounds?: uui.domain.LatLngBounds
}

/**
 * Search places on the map
 *
 * @private
 */
export const gpsReverseGeocode = createAsyncThunk<
  // Return type of the payload creator
  uui.domain.client.Location,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('ui/gpsReverseGeocode', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/ui/gpsReverseGeocode', {
      category: 'rpc',
      type: 'rpc/ui/gpsReverseGeocode',
      params: payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    return result.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {gpsReverseGeocode}`,
      { tags: ['rpc', 'ui', 'gpsReverseGeocode'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Reverse geocode failed`,
      error,
    })
  }
})
