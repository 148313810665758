export const processAdditiveSelection = (
  prevSelection: string[],
  nextSelection: string[],
): string[] => {
  if (nextSelection.length === 1) {
    // Single ID selected

    const id = nextSelection[0]
    const targetIndex = prevSelection.indexOf(id)

    if (targetIndex > -1) {
      // Removes it
      prevSelection.splice(targetIndex, 1)
    } else {
      // Adds it
      prevSelection.push(id)
    }

    return [...prevSelection]
  } else {
    // Multiple IDs selected
    const idsSet = new Set(prevSelection)
    const toggleAllIds = !nextSelection.some(id => !prevSelection.includes(id))

    // remove all IDs if all of them are already selected
    // otherwise add missing IDs and keep the existing ones
    const action = toggleAllIds ? idsSet.delete : idsSet.add

    for (const id of nextSelection) {
      action.call(idsSet, id)
    }

    return [...idsSet]
  }
}
