import styled from 'styled-components'

export const ReadonlyTimeWindowExceptionsRow = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  width: 100%;
`

ReadonlyTimeWindowExceptionsRow.displayName = 'ReadonlyTimeWindowExceptionsRow'
