import type { HotwordId } from '../types'
import type { Notifications } from '@/notificationForms'

import { useMemo } from 'react'
import { useTexts } from './useTexts'

export function useConfigureHotwordsByNotification(notificationType: Notifications) {
  const texts = useTexts()

  return useMemo(() => {
    const hotwordIds = computeHotwordIds(notificationType)
    const hotwordsMap = computeHotwordsMap(hotwordIds, texts)
    return {
      hotwordIds,
      hotwordsMap,
    }
  }, [notificationType, texts])
}

function computeHotwordIds(notificationType: Notifications): HotwordId[] {
  switch (notificationType) {
    case 'todaySettings':
    case 'twoDaysSettings':
    case 'tomorrowSettings':
      return ['DriverName', 'ETARange', 'OrderAddress', 'OrderName', 'PlannedDate']

    case 'notCompletedSettings':
    case 'completedSettings':
      return ['DriverName', 'OrderAddress', 'OrderName', 'PlannedDate']

    case 'canceledSettings':
      return ['OrderAddress', 'OrderName']

    case 'earlySettings':
    case 'delayedSettings':
      return ['DriverName', 'ETA', 'OrderAddress', 'OrderName', 'PlannedDate']

    case 'almostThereSettings':
      return ['DriverName', 'ETA', 'ETAthreshold', 'OrderAddress', 'OrderName', 'PlannedDate']
  }
}

function computeHotwordsMap(hotwords: HotwordId[], texts: ReturnType<typeof useTexts>) {
  const res: Record<string, string> = {}
  for (const hotword of hotwords) {
    res[hotword] = texts.hotwordLabel(hotword) ?? ''
  }
  return res
}
