import type { CollapsibleListExtraProps as Props } from '@/forms-legacy'

import { FlexRow, ReadonlyLabel, CollapseButton } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'

export function Header(props: Props) {
  const { itemsCount, collapsed, onToggle, itemsThreshold } = props

  const texts = useTexts()

  return (
    <FlexRow>
      <ReadonlyLabel primary empty={itemsCount === 0} style={{ marginBottom: 0 }}>
        {texts.monitoredVehicles(itemsCount)}
      </ReadonlyLabel>
      {itemsCount > itemsThreshold && (
        <CollapseButton
          text={collapsed ? texts.showAll : texts.hideAll}
          onToggle={onToggle}
          collapsed={collapsed}
        />
      )}
    </FlexRow>
  )
}
