import { useRef, useCallback, useMemo } from 'react'
import { FormControlLabel, MenuItem, Radio, Typography } from '@mui/material'
import {
  ListHeaderFilterInput,
  ListHeaderFilterMenu,
  ListHeaderFilterContainer,
  type ListHeaderSecondaryActionRef,
} from '@/components/List'

import { useTexts } from '../../../../useTexts'
import { useFilter } from './hooks/useFilter'

export function Filter() {
  const { query, filter, setFilter, setQuery, editing, noEntries } = useFilter()

  const texts = useTexts()

  const filterMenuRef = useRef<ListHeaderSecondaryActionRef>(null)
  const filterActive = useMemo(() => filter.type !== 'all', [filter])

  const onAllTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'all',
    })
  }, [setFilter])

  const onAnyTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'any',
    })
  }, [setFilter])

  const onAnnualTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'annual',
    })
  }, [setFilter])

  const onMonthlyTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'monthly',
    })
  }, [setFilter])

  const onWeeklyTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'weekly',
    })
  }, [setFilter])

  const onOneTimeTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'oneTime',
    })
  }, [setFilter])

  const onClearAllClick = useCallback(() => {
    setFilter({ type: 'all' })
    filterMenuRef.current?.close()
  }, [setFilter])

  const disabled = editing || noEntries

  return (
    <ListHeaderFilterContainer>
      <ListHeaderFilterInput
        placeholder={texts.filterPlaceholder}
        disabled={disabled}
        setQuery={setQuery}
        testId="list-header-filter-input"
        query={query}
      />

      <ListHeaderFilterMenu filterActive={filterActive} ref={filterMenuRef} disabled={disabled}>
        <MenuItem
          onClick={onAllTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-all"
          data-trackid="list-header-filter-filterOption-type-all"
        >
          <FormControlLabel
            label={texts.filterTypeAll}
            control={
              <Radio
                size="small"
                value="all"
                checked={filter.type === 'all'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>
        <MenuItem
          onClick={onAnyTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-any"
          data-trackid="list-header-filter-filterOption-type-any"
        >
          <FormControlLabel
            label={texts.filterTypeDefault}
            control={
              <Radio
                size="small"
                value="any"
                checked={filter.type === 'any'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onAnnualTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-annual"
          data-trackid="list-header-filter-filterOption-type-annual"
        >
          <FormControlLabel
            label={texts.filterTypeAnnual}
            control={
              <Radio
                size="small"
                value="annual"
                checked={filter.type === 'annual'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onMonthlyTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-monthly"
          data-trackid="list-header-filter-filterOption-type-monthly"
        >
          <FormControlLabel
            label={texts.filterTypeMonthly}
            control={
              <Radio
                size="small"
                value="monthly"
                checked={filter.type === 'monthly'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onWeeklyTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-weekly"
          data-trackid="list-header-filter-filterOption-type-weekly"
        >
          <FormControlLabel
            label={texts.filterTypeWeekly}
            control={
              <Radio
                size="small"
                value="weekly"
                checked={filter.type === 'weekly'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onOneTimeTypeFilterClick}
          divider={filterActive}
          data-testid="list-header-filter-filterOption-type-oneTime"
          data-trackid="list-header-filter-filterOption-type-oneTime"
        >
          <FormControlLabel
            label={texts.filterTypeOneTime}
            control={
              <Radio
                size="small"
                value="oneTime"
                checked={filter.type === 'oneTime'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        {filterActive && (
          <MenuItem
            onClick={onClearAllClick}
            data-testid="list-header-filter-filterOption-clear"
            data-trackid="list-header-filter-filterOption-clear"
          >
            <Typography fontSize={15} color="primary">
              {texts.clearAll}
            </Typography>
          </MenuItem>
        )}
      </ListHeaderFilterMenu>
    </ListHeaderFilterContainer>
  )
}
