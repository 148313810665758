import { Interaction, Translate } from 'ol/interaction'

import { getMap } from '../atoms/map/map'
import { LassoInteraction } from './LassoInteraction'
import { MouseInteraction } from './MouseInteraction'
import {
  DrawEditableAreaInteraction,
  ModifyEditableAreaInteraction,
} from './EditableAreaInteraction'
import {
  DrawEditableRoadSegmentInteraction,
  ModifyEditableRoadSegmentInteraction,
} from './EditableRoadSegmentInteraction'

type InteractionUID =
  | 'rm-mouse-single-click-interaction'
  | 'rm-mouse-double-click-interaction'
  | 'rm-mouse-move-interaction'
  | 'rm-move-pin-interaction'
  | 'rm-lasso-interaction'
  | 'rm-draw-editable-area-interaction'
  | 'rm-modify-editable-area-interaction'
  | 'rm-draw-editable-roadSegment-interaction'
  | 'rm-modify-editable-roadSegment-interaction'

type InteractionMetadata = {
  uid: InteractionUID
  rmInteraction: boolean
}

export function setInteractionMetadata<Field extends keyof InteractionMetadata>(
  target: Interaction,
  field: Field,
  value: InteractionMetadata[Field],
) {
  target.set(field, value)
}

export function getInteractionMetadata<Field extends keyof InteractionMetadata>(
  target: Interaction,
  field: Field,
): InteractionMetadata[Field] | undefined {
  return target.get(field)
}

export function removeInteractionMetadata<Field extends keyof InteractionMetadata>(
  target: Interaction,
  field: Field,
) {
  target.unset(field)
}

type InteractionType = {
  'rm-mouse-single-click-interaction': MouseInteraction
  'rm-mouse-double-click-interaction': MouseInteraction
  'rm-mouse-move-interaction': MouseInteraction
  'rm-move-pin-interaction': Translate
  'rm-lasso-interaction': LassoInteraction
  'rm-draw-editable-area-interaction': DrawEditableAreaInteraction
  'rm-modify-editable-area-interaction': ModifyEditableAreaInteraction
  'rm-draw-editable-roadSegment-interaction': DrawEditableRoadSegmentInteraction
  'rm-modify-editable-roadSegment-interaction': ModifyEditableRoadSegmentInteraction
}
export function getInteraction<
  Field extends InteractionUID = InteractionUID,
  Value = InteractionType[Field],
>(uid: Field): Value | undefined {
  const interaction = getMap().getInteractions().get(uid)

  switch (uid) {
    case 'rm-mouse-move-interaction':
    case 'rm-mouse-single-click-interaction':
    case 'rm-mouse-double-click-interaction':
      return interaction as Value

    case 'rm-lasso-interaction':
      return interaction as Value

    case 'rm-move-pin-interaction':
      return interaction as Value

    case 'rm-draw-editable-area-interaction':
      return interaction as Value

    case 'rm-modify-editable-area-interaction':
      return interaction as Value

    case 'rm-draw-editable-roadSegment-interaction':
      return interaction as Value

    case 'rm-modify-editable-roadSegment-interaction':
      return interaction as Value

    default:
  }
}

export function registerInteraction(uid: InteractionUID, interaction: Interaction) {
  getMap().getInteractions().set(uid, interaction)
}
