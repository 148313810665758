import { Dispatch, SetStateAction, useCallback } from 'react'
import { useCrudSelection } from '@/atoms'
import { useSettingsEditing } from '../../../../hooks/useSettingsEditing'

type Props = {
  setQuery: Dispatch<SetStateAction<string>>
}

export function useCreateNewUser(props: Props) {
  const { setQuery } = props
  const { onEdit } = useSettingsEditing('user-management', 'none')
  const [, setSelection] = useCrudSelection('users')

  return useCallback(() => {
    setSelection([])
    setQuery('')
    onEdit()
  }, [setSelection, setQuery, onEdit])
}
