import type { Props } from './typings'
import { Plus } from '@/icons'

import IconContainer from '../IconContainer'

import BaseBtn from './BaseBtn'

export function LoadListItem(props: Props) {
  const { children, onClick, disabled } = props

  return (
    <BaseBtn onClick={onClick} disabled={disabled}>
      <IconContainer>
        <Plus />
      </IconContainer>
      {children}
    </BaseBtn>
  )
}
