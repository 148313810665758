import { proxy } from 'valtio'

export type VoiceEventMarker = {
  id: string
  latLng: uui.domain.LatLng
  lonLat: [lon: number, lat: number]
  visible: boolean
}

export type VoiceEventMarkerAtom = {
  event: VoiceEventMarker | null
}

export const voiceEventMarker = proxy<VoiceEventMarkerAtom>({
  event: null,
})
