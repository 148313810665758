import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function PlusCopy(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 14 14">
        <path d="M2.8.4V1c0 .220914.179086.4.4.4h8.4c.552285 0 1 .447715 1 1v8.4c0 .220914.179086.4.4.4h.6c.220914 0 .4-.179086.4-.4V1.5c0-.828427-.671573-1.5-1.5-1.5H3.2c-.220914 0-.4.179086-.4.4zM1.4 2.8C.6265 2.8 0 3.4265 0 4.2v8.4c0 .7735.6265 1.4 1.4 1.4h8.4c.7735 0 1.4-.6265 1.4-1.4V4.2c0-.7735-.6265-1.4-1.4-1.4H1.4zm3.9 2.8h.6c.220914 0 .4.179086.4.4v1.7H8c.220914 0 .4.179086.4.4v.6c0 .220914-.179086.4-.4.4H6.3v1.7c0 .220914-.179086.4-.4.4h-.6c-.220914 0-.4-.179086-.4-.4V9.1H3.2c-.220914 0-.4-.179086-.4-.4v-.6c0-.220914.179086-.4.4-.4h1.7V6c0-.220914.179086-.4.4-.4z" />
      </svg>
    </IconContainer>
  )
}
