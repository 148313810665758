import type { FormFields, FormErrors } from '../../../formFields'

import { BulkTextField } from '../../primitives/BulkTextField'
import { useTexts } from './useTexts'

export function Email() {
  const texts = useTexts()

  return (
    <BulkTextField<'email', FormFields, FormErrors>
      name="email"
      mixedLabel={texts.mixed}
      label={texts.email}
      validateOn="blur"
    />
  )
}
