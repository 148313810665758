import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { selectTrafficRegionMapStyles, setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'
import { useCrudSelection } from '@/atoms'

import { useEditing } from '../../../../../../hooks/useEditing'

export function useHideTrafficAreasWhileEditing() {
  const dispatch = useAppDispatch()

  const [trafficAreaSelection] = useCrudSelection('trafficArea')

  const { editingEntity } = useEditing('trafficArea')
  const editing = editingEntity === 'traffic-area'

  const areaId = trafficAreaSelection.length === 1 ? trafficAreaSelection[0] : undefined

  // ATTENTION: this REF is intentionally created and not updated
  const rOriginalMapStyle = useRef(useSelector(selectTrafficRegionMapStyles))

  useEffect(() => {
    if (!areaId || !editing) return

    // access previously active region Map Styles
    const originalMapStyle = rOriginalMapStyle.current
    const customMode: uui.domain.ui.map.markers.MapMode | undefined =
      originalMapStyle.custom[areaId]

    if (customMode === 'off') return

    // hide the feature
    dispatch(
      setCustomMapStyle({
        customStyle: {
          type: 'trafficProfile',
          mode: 'off',
          ids: [areaId],
        },
      }),
    )

    return () => {
      dispatch(
        setCustomMapStyle({
          customStyle: {
            type: 'trafficProfile',
            mode: customMode ?? 'on',
            ids: [areaId],
          },
        }),
      )
    }
  }, [dispatch, editing, areaId])
}
