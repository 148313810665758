import type { ReactNode } from 'react'

import { Menu } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { usePlacement } from './hooks/usePlacement'

interface Props {
  open: boolean
  onClose: () => void
  placement: 'left' | 'right'
  anchorEl: HTMLLIElement | null
  children?: ReactNode
}

const useStyles = makeStyles({
  // Set pointer events to 'none' to prevent the invisible Popover div
  // from capturing events for clicks and hovers
  menu: { pointerEvents: 'none' },

  childrenContainer: {
    pointerEvents: 'auto',
    width: 214,
  },
})

export function SubMenu(props: Props) {
  const { open, anchorEl, onClose } = props
  const classes = useStyles()
  const { anchorOrigin, transformOrigin } = usePlacement(props.placement)

  return (
    <Menu
      disableAutoFocus
      open={open}
      autoFocus={false}
      onClose={onClose}
      anchorEl={anchorEl}
      disableEnforceFocus
      className={classes.menu}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <div className={classes.childrenContainer}>{props.children}</div>
    </Menu>
  )
}
