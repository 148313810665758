import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Box,
  Typography,
  styled,
} from '@mui/material'

type Props = {
  description?: string
  integration: uui.domain.server.gps.telematics.Source
  disabled?: boolean
  onClick?: () => void
  logo: string
}

const DisableForeground = styled(Box, { name: 'DisableForeground' })(() => ({
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  top: 0,
}))

export function IntegrationCard(props: Props) {
  const { integration, disabled, logo, onClick, description = '' } = props

  return (
    <Card>
      <CardActionArea
        onClick={onClick}
        disabled={disabled}
        data-testid="available-integration-card"
        data-trackid="available-integration-card"
      >
        <CardMedia component="img" image={logo} alt={integration.label} />

        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {integration.label}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>

        {disabled && <DisableForeground />}
      </CardActionArea>
    </Card>
  )
}
