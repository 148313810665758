import type { SingleViewActionsProps } from '../typings'

import { PureComponent } from 'react'

import { ConfirmPanel, ChangeViewHandler } from '@/forms-legacy'

import * as OrdersTexts from '../../../intl'
import PairedOrderPanel from '../../../components/PairedOrderPanel'
import { getPairedOrderStep } from '../../../utils'

import { SingleViewActions } from './SingleViewActions/SingleViewActions'

export enum ViewTypes {
  default,
  delete,
  unassign,
}

export interface Props extends SingleViewActionsProps {
  view: ViewTypes
  changeView: ChangeViewHandler<ViewTypes>
}

export default class ActionsManager extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { order: prevOrder } = prevProps
    const { order } = this.props

    if (prevOrder !== order) {
      this.reset()
    }
  }

  private handleOnDeleteOnlySelected = (): void => {
    const { order, deleteOrderSteps } = this.props

    if (order) {
      deleteOrderSteps([order.orderStep.id])
      this.reset()
    }
  }

  private handleOnDeleteAlsoPaired = (): void => {
    const { order, deleteOrderSteps } = this.props

    if (order) {
      const paired = getPairedOrderStep(order.order, order.orderStep.type)

      const ids = [order.id]
      if (paired) {
        ids.push(paired.id)
      }

      deleteOrderSteps(ids)
      this.reset()
    }
  }

  private handleOnUnassignOnlySelected = (): void => {
    const { unassignOrderSteps, order } = this.props

    if (order) {
      unassignOrderSteps([order.orderStep.id])
      this.reset()
    }
  }

  private handleOnUnassignAlsoPaired = (): void => {
    const { unassignOrderSteps, order } = this.props

    if (order) {
      const paired = getPairedOrderStep(order.order, order.orderStep.type)

      const ids = [order.id]
      if (paired) {
        ids.push(paired.id)
      }

      unassignOrderSteps(ids)
      this.reset()
    }
  }

  private reset = (): void => {
    const { changeView } = this.props
    changeView(ViewTypes.default)
  }

  render() {
    const { view, order: extendedOrder } = this.props

    let hasPairedOrderStep: boolean = false

    if (extendedOrder) {
      const {
        order,
        orderStep: { type: orderStepType },
      } = extendedOrder

      hasPairedOrderStep = !!getPairedOrderStep(order, orderStepType)
    }

    switch (view) {
      case ViewTypes.delete:
        return hasPairedOrderStep ? (
          <PairedOrderPanel
            title={OrdersTexts.getDeleteConfirmTitleText(false)}
            subtitle={OrdersTexts.getDeleteConfirmSubTitleText()}
            onCancel={this.reset}
            onOnlySelected={this.handleOnDeleteOnlySelected}
            onAlsoPaired={this.handleOnDeleteAlsoPaired}
            onlySelectedButtonText={OrdersTexts.getDeleteOnlySelectedText()}
            alsoPairedButtonText={OrdersTexts.getDeleteAlsoPairedText()}
          />
        ) : (
          <ConfirmPanel
            onCancel={this.reset}
            onConfirm={this.handleOnDeleteOnlySelected}
            title={OrdersTexts.getDeleteConfirmTitleText(false)}
            description={OrdersTexts.getDeleteConfirmSubTitleText()}
            confirmationText={OrdersTexts.getDeleteText()}
            testid="orders__single-delete-confirm-panel"
          />
        )

      case ViewTypes.unassign:
        return hasPairedOrderStep ? (
          <PairedOrderPanel
            title={OrdersTexts.getUnassignConfirmTitleText(false)}
            subtitle={OrdersTexts.getUnassignConfirmSubTitleText()}
            onCancel={this.reset}
            onOnlySelected={this.handleOnUnassignOnlySelected}
            onAlsoPaired={this.handleOnUnassignAlsoPaired}
            onlySelectedButtonText={OrdersTexts.getUnassignOnlySelectedText()}
            alsoPairedButtonText={OrdersTexts.getUnassignAlsoPairedText()}
          />
        ) : (
          <ConfirmPanel
            onCancel={this.reset}
            onConfirm={this.handleOnUnassignOnlySelected}
            title={OrdersTexts.getUnassignConfirmTitleText(true)}
            description={OrdersTexts.getUnassignConfirmSubTitleText()}
            confirmationText={OrdersTexts.getUnassignText()}
            testid="confirm-unassign-single-order"
          />
        )
    }

    return <SingleViewActions {...this.props} />
  }
}
