import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: (count: number) => `${count} ${translate({ id: 'setup.depots.view.bulk.title' })}`,
    headerDescription: translate({ id: 'setup.depots.view.bulk.description' }),
    confirmPanelTitle: (count: number) =>
      translate({ id: 'setup.depots.delete.title', values: { count } }),
    confirmPanelSubtitle: translate({ id: 'setup.depots.delete.subtitle' }),
    delete: translate({ id: 'global.deleteAll' }),
  }))

  return api
}
