import { Box, Typography } from '@mui/material'
import { useTexts } from './useTexts'

type Props = {
  reasonsCount: number
}

export function ReasonsForNotCompletedOrdersCount(props: Props) {
  const { reasonsCount } = props
  const texts = useTexts()

  return (
    <Box>
      <Typography variant="body1">{texts.reasonsTitle}</Typography>
      <Typography variant="caption" color="textSecondary">
        {texts.reasonsSubtitle(reasonsCount)}
      </Typography>
    </Box>
  )
}
