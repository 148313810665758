import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { intl } from '@/intl'
import { DropDownField, DropdownItem } from '@/forms-legacy'
import { selectUserConfiguration } from '@/features/domain/user'

import { conversionUtils } from '@/server-data'

import * as OrderTexts from '../../../intl'

import { Props } from '../typings'

const parseSecondToText = (seconds: number, userConfig: uui.domain.UserConfiguration): string =>
  seconds > 0 ? conversionUtils.convertTime(userConfig)(seconds) : OrderTexts.getNoneText()

export const composeValues = (): DropdownItem[] => [
  {
    id: 'done',
    label: intl.translate({ id: 'orders.stepTrackingDataStatus.done' }),
  },
  {
    id: 'reschedule',
    label: intl.translate({ id: 'orders.stepTrackingDataStatus.reschedule' }),
  },
  {
    id: 'missing',
    label: intl.translate({ id: 'orders.stepTrackingDataStatus.none' }),
  },
]

export function ReportedStatus(props: Props) {
  const values = useMemo(() => composeValues(), [])
  const userConfig = useSelector(selectUserConfiguration)

  const {
    values: { statusSec },
  } = props

  const reportedStatusLabel: string =
    statusSec > 0
      ? OrderTexts.getReportedStatusWithDate(parseSecondToText(statusSec, userConfig))
      : OrderTexts.getReportedStatus()

  return (
    <DropDownField
      testid="ReportedStatus-root"
      name="status"
      label={reportedStatusLabel}
      values={values}
    />
  )
}
