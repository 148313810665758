import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import Point from 'ol/geom/Point'
import Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'
import { findFeature } from '../../findFeature'

import { setDeviceEventFeatureMetadata } from '../deviceEventFeatureMetadata'

import { getDeviceEventPointsFeatureStyle } from './deviceEventPoints/getDeviceEventPointsFeatureStyle'
import { updateDeviceEventPointsFeature } from './deviceEventPoints/updateDeviceEventPointsFeature'
import { findDeviceEventMarker } from './findDeviceEventMarker'

const nonblockingRender = createNonBlockingRenderByTime()

export async function changelogUpdateDeviceEventFeatures(
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.DeviceEvents>,
  selection: Set<string>,
  markerIds: IterableIterator<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findDeviceEventMarker(markers, markerId)
    if (!marker) {
      console.warn(
        `Trying to update DeviceEvent feature with deviceId: ${markerId} but no marker exist on the map`,
      )
      continue
    }

    const newPointFeatures: Feature<Point>[] = []

    // ------------------------------------------------------
    // UPDATE POINTS FEATURES
    // ------------------------------------------------------

    for (const eventId in marker.events) {
      const event = marker.events[eventId]
      const feature = findFeature(layer, event.id)

      // If the point exists, update it
      if (feature) {
        updateDeviceEventPointsFeature(feature, event, selection.has(event.id), breadcrumbTimeRange)
        continue
      }

      //If the point does not exists, create it
      const newPointFeature = new Feature({ geometry: new Point(event.lonLat) })

      setDeviceEventFeatureMetadata(newPointFeature, 'type', 'deviceEvent')
      newPointFeature.setStyle(
        getDeviceEventPointsFeatureStyle(event, selection.has(eventId), breadcrumbTimeRange),
      )
      newPointFeature.setId(event.id)

      newPointFeatures.push(newPointFeature)
    }

    // add all new features to the layer
    layer.getSource()?.addFeatures(newPointFeatures)
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
