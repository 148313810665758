import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'
import { findFeature } from '../../findFeature'

const nonblockingRender = createNonBlockingRenderByTime()

export const changelogRemoveOrderFeatures = async (
  layer: VectorLayer<VectorSource>,
  selection: Set<string>,
  markerIds: IterableIterator<string>,
) => {
  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const feature = findFeature(layer, markerId)

    // if a feature is not find to be removed fail silently
    if (feature) {
      layer.getSource()?.removeFeature(feature)
    }
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
