import type { FormApi as FinalFormApi } from 'final-form'

import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { produce } from 'immer'

import { selectWorkingDayStartSec } from '@/features/domain/territory'
import { selectStartOfToday, selectUserConfiguration } from '@/features/domain/user'

import { parseRecurrence } from '@/server-data'

import { computeVehicleSettingsFromFormValues } from '../utils/computeVehicleSettingsFromFormValues'

import { useActions } from './useActions'

type FormApi = FinalFormApi<
  uui.domain.ui.forms.ExceptionFormValues,
  uui.domain.ui.forms.ExceptionFormValues
>

export function useOnSubmit(unifiedVehicle: uui.domain.client.UnifiedVehicle) {
  const [submitting, setSubmitting] = useState(false)
  const workingDayStartSec = useSelector(selectWorkingDayStartSec)
  const startOfToday = useSelector(selectStartOfToday)
  const userConfig = useSelector(selectUserConfiguration)

  const { onUpdateVehicleSettings } = useActions(unifiedVehicle)

  const onSubmit = useCallback(
    (_: uui.domain.ui.forms.ExceptionFormValues, formApi: FormApi) => {
      const { getState } = formApi
      setSubmitting(true)

      // FIXME: this is a workaround to get updated "fresh" values from some components
      // that doesn't trigger the onChange callbacks without losing its focus
      setTimeout(() => {
        const { values } = getState()
        const formValues = values as uui.domain.ui.forms.ExceptionFormValues

        if (!unifiedVehicle.hasRoutingLicense) {
          if (process.env.NODE_ENV === 'development') {
            throw new Error('Exception edit - onSubmit, vehicle has no routing license')
          }
          setSubmitting(false)
          return
        }

        const [exceptionId, settings] = computeVehicleSettingsFromFormValues(
          formValues,
          userConfig,
          workingDayStartSec,
        )

        const exception = parseRecurrence(exceptionId)

        // flagging the applyFromDate let the server to create a "before" exception
        // that will not create any inconsistencies between past recurrences and the futures
        if (exception && exception.type !== 'DATE') {
          produce<uui.domain.client.rm.VehicleSettings>(settings, draft => {
            draft.applyFromDate = startOfToday
          })
        }

        onUpdateVehicleSettings(exceptionId, settings)

        setSubmitting(false)
      }, 250)
    },
    [userConfig, workingDayStartSec, unifiedVehicle, onUpdateVehicleSettings, startOfToday],
  )

  return { submitting, onSubmit }
}
