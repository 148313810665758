import { useCallback } from 'react'

import { ViewActions, ViewActionButton } from '@/formUi'

import { useNotification } from '@/hooks'
import { copyToClipboard } from '@/utils'

import { useResetApiKeyModal } from '../../modals/ResetApiKeyModal'
import { useTexts } from './useTexts'

type Props = {
  apiKey: string | undefined
}

export function RmAccountViewApiKeyActions(props: Props) {
  const { apiKey } = props

  const { Modal: ResetKeyModal, show: showResetKeyModal } = useResetApiKeyModal()
  const toast = useNotification()
  const texts = useTexts()

  const onCopyApiKey = useCallback(async () => {
    if (!apiKey) return

    const copied = await copyToClipboard(apiKey)
    copied
      ? toast.success(texts.copyApiKeySuccess, { autoClose: 5000 })
      : toast.error(texts.copyApiKeyError, { autoClose: 5000 })
  }, [toast, texts, apiKey])

  return (
    <>
      <ViewActions>
        <ViewActionButton
          testId="copy-api-key-button"
          onClick={onCopyApiKey}
          label={texts.copy}
          disabled={!apiKey}
        />

        <ViewActionButton
          testId="reset-api-key-button"
          onClick={showResetKeyModal}
          label={texts.reset}
          disabled={!apiKey}
          color="error"
        />
      </ViewActions>

      {/* Inject the Modal's Root */}
      <ResetKeyModal />
    </>
  )
}
