import { intl } from '@/intl'

const aMinute = 60
const anHour = 3600

/**
 * Returns a label indicating the relative time difference based on the given delta in seconds.
 * @param deltaSeconds - The time difference in seconds.
 * @returns The label indicating the relative time difference or '-' if the delta is negative or undefined if the delta is > 1 hour.
 */
export function getRelativeTimeDifferenceLabel(deltaSeconds: number) {
  if (deltaSeconds < 0) return '-'

  if (deltaSeconds < aMinute) {
    return intl.translate({ id: 'global.justNow' })
  }

  if (deltaSeconds < anHour) {
    return intl.translate({ id: 'global.someMinutesAgo' })
  }
}
