import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'companies.home.title' }),
    add: translate({ id: 'companies.home.add' }),

    hero: {
      title: translate({ id: 'companies.hero.title' }),
      description: translate({ id: 'companies.hero.description' }),
      externalLink: translate({ id: 'companies.hero.externalLink' }),
    },
  }))

  return api
}
