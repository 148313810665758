import OlMap from 'ol/Map'
import { doubleClick } from 'ol/events/condition'

import { findLayer } from '../../layers/utils/findLayer'
import { subscribeToMouseDoubleClick } from '../reactions/doubleClick/subscribeToMouseDoubleClick'
import { MouseInteraction } from '../MouseInteraction'
import { getInteraction, registerInteraction, setInteractionMetadata } from '../interactionMetadata'

export const registerMouseDoubleClickInteraction = (map: OlMap) => {
  const uid = 'rm-mouse-double-click-interaction'

  if (getInteraction(uid)) return

  const mouseInteraction = new MouseInteraction(uid, {
    multi: false,
    condition: doubleClick,
    stopPropagation: true,
    allowDuplicateEvents: true,
    subscribers: [subscribeToMouseDoubleClick],
    disabledLayers: [
      findLayer(map, 'pin'),
      findLayer(map, 'executionEvents'),
      findLayer(map, 'draw'),
    ],
  })

  setInteractionMetadata(mouseInteraction, 'rmInteraction', true)
  setInteractionMetadata(mouseInteraction, 'uid', uid)
  registerInteraction(uid, mouseInteraction)

  map.addInteraction(mouseInteraction)
}
