import { validateDriverBehaviorsData } from './validateDriverBehaviorsData'

export function validatePartialDriverBehaviors(
  value: uui.domain.ui.forms.PartialBehaviorsData,
  hasDriverBehaviorCapability: boolean,
): string | undefined {
  const { hard } = value
  const hardValidationValue = validateDriverBehaviorsData(hard, value)

  if (!hasDriverBehaviorCapability) return

  if (typeof hardValidationValue === 'string') {
    return hardValidationValue
  }
}
