import { useOrderStepsNavigator } from '../../hooks/useOrderStepsNavigator'
import { Bulk } from './components/Bulk'
import { Single } from './components/Single'

const growStyle = { padding: '2px 0', maxWidth: 260, minWidth: 260, height: '100%' }
export function Details() {
  const { currentOrders } = useOrderStepsNavigator()
  if (currentOrders.length === 0) return null

  return (
    <div style={growStyle}>
      {currentOrders.length > 1 ? (
        <Bulk extOrders={currentOrders} />
      ) : (
        <Single extOrder={currentOrders[0]} />
      )}
    </div>
  )
}
