import styled from 'styled-components'

const TextButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  overflow: visible;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font: inherit;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  color: ${p => p.color ?? p.theme.colors.$scienceBlue};
  font-size: ${p => p.theme.fonts.$p2};
  font-weight: ${p => p.theme.weights.$regular};

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

TextButton.displayName = 'TextButton'
export default TextButton
