import styled from 'styled-components'

type Props = {
  primary?: boolean
  gap?: number
}

export const ReadonlyFieldRowPart = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: ${p => (p.primary ? '0 1 auto' : '0 0 auto')};
  width: ${p => (p.primary ? '100%' : 'auto')};
  gap: ${p => p.gap ?? 0}px;
  overflow: hidden;
`

ReadonlyFieldRowPart.displayName = 'ReadonlyFieldRowPart'
