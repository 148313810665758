import type { FormProps } from '../types'

import { TrackingPage } from '@/notificationForms'
import { useTrackingPageProps } from './useTrackingPageProps'

/**
 * Render the TrackingPage form form providing all the domain data and apis.
 */
export function TrackingPageProvider(props: FormProps) {
  const { setEditingState, inEditing } = props

  const { updateTrackingAppData, trackingAppData } = useTrackingPageProps(
    inEditing,
    setEditingState,
  )

  return (
    <TrackingPage
      updateTrackingAppData={updateTrackingAppData}
      trackingAppData={trackingAppData}
      setEditingState={setEditingState}
      inEditing={inEditing}
    />
  )
}
