import { useFormatTime } from '@/hooks'

interface Props {
  source: Date
}

export function FormatTime(props: Props) {
  const { source } = props
  const value = useFormatTime(source)

  return <>{value}</>
}
