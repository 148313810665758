import type { FeatureLike } from 'ol/Feature'

import LineString from 'ol/geom/LineString'
import Geometry from 'ol/geom/Geometry'
import { Fill, Style } from 'ol/style'
import Circle from 'ol/geom/Circle'

import { MAX_LENGTH_IN_METERS } from '../../constants'
import { drawStyleCommon } from './common'

const common = drawStyleCommon()

export function createCircle() {
  return new Style({
    fill: new Fill({
      color: common.white(0.8),
    }),

    geometry: (feature: FeatureLike) => {
      const geometry = feature?.getGeometry()

      if (geometry instanceof Geometry && geometry.getType() === 'LineString') {
        const line = geometry as LineString
        if (line.getLength() <= MAX_LENGTH_IN_METERS) return

        const coordinates = [...(line.getCoordinates().at(0) ?? [])]

        if (!coordinates || coordinates.length === 0) return

        // first point
        return new Circle(coordinates, MAX_LENGTH_IN_METERS)
      }
    },
  })
}
