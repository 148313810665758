import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRoadSegments } from '@/features/domain/roadSegment'
import * as geo from '@/local/server-data/utils/geo'
import { getColor } from '../utils/getColor'

// new segments should have a unique name
// "New Segment" should be the base
// suffix (n) should be  applied if there are already other occurrences
const defaultPoint = [0, 0] as uui.domain.server.LatLngTuple
export function useNewRoadSegmentValue() {
  const roadSegments = useSelector(selectRoadSegments)

  return useMemo<uui.domain.client.rm.SegmentException>(() => {
    const name = computeUniqueName(Object.values(roadSegments), 'New Segment')

    return {
      startPoint: geo.latLngFromJSON(defaultPoint),
      endPoint: geo.latLngFromJSON(defaultPoint),
      color: getColor('disabled'),
      additionalDrivingTimeSec: 0,
      id: '@@new-segment-id@@',
      profileId: 'default',
      start: defaultPoint,
      end: defaultPoint,
      type: 'disabled',
      malus: 10,
      index: -1,
      name,
    }
  }, [roadSegments])
}

// create a map where keys are the segment names
// the values are the number of the occurrences
function createMapOfNames(
  segmentExceptions: uui.domain.client.rm.SegmentException[],
): Record<string, number> {
  const map: Record<string, number> = {}
  for (const { name } of segmentExceptions) {
    map[name] = map[name] ? map[name] + 1 : 1
  }
  return map
}

// assign a unique name to the road segment to be imported
// by adding a suffix with the number of that name occur in the regions
function computeUniqueName(
  segmentExceptions: uui.domain.client.rm.SegmentException[],
  idealName: string,
) {
  const namesMap = createMapOfNames(segmentExceptions)

  if (namesMap[idealName]) {
    return `${idealName} (${namesMap[idealName]})`
  }

  return idealName
}
