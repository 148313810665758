import { proxy, snapshot, useSnapshot } from 'valtio'

export type RouteLoadingState = {
  lockStatus: Record<string, true>
  visibility: Record<string, true>
}

export type RouteLoadingStateAtom = {
  status: RouteLoadingState
}

export const routeLoadingStateAtom = proxy<RouteLoadingStateAtom>({
  status: { lockStatus: {}, visibility: {} },
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetRouteLoadingState = (prev: RouteLoadingState) => RouteLoadingState
type SetRouteLoadingStateParam = SetRouteLoadingState | Partial<RouteLoadingState> | 'reset'

export function resetRouteLoadingState() {
  routeLoadingStateAtom.status = { lockStatus: {}, visibility: {} }
}

export function setRouteLoadingState(valueOrFunc: SetRouteLoadingStateParam) {
  if (valueOrFunc === 'reset') resetRouteLoadingState()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(routeLoadingStateAtom.status, valueOrFunc(routeLoadingStateAtom.status))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      routeLoadingStateAtom.status[field] = valueOrFunc[field]
    }
  }

  return routeLoadingStateAtom
}

// ---------------------------------------------------------------------------
// Read functions
// ---------------------------------------------------------------------------

export function getRouteLoadingState(immutable: boolean = false) {
  return immutable ? snapshot(routeLoadingStateAtom.status) : routeLoadingStateAtom.status
}

// ---------------------------------------------------------------------------
// React Hooks
// ---------------------------------------------------------------------------

export function useRouteLoadingState() {
  return [useSnapshot(routeLoadingStateAtom).status, setRouteLoadingState] as const
}
