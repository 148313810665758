import { PureComponent } from 'react'

import { Block, Label, FieldMessage, RestoreFieldButton, CustomFieldsList } from '@/forms-legacy'

import { Props } from './typings'

export default class CustomFieldsListField extends PureComponent<Props> {
  render() {
    const {
      label,
      name,
      info,
      half = false,
      wrappedInputProps,
      formProps: {
        input: { value, onChange },
        meta: { error, dirty, initial },
      },
      testid,
    } = this.props

    return (
      <Block half={half} error={!!error} dirty={!!dirty} data-testid={testid}>
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <CustomFieldsList {...wrappedInputProps} onChange={onChange} value={value} />
        {/*
          Error messages directly computed by the inner component
          {!!error && <FieldMessage error>{error}</FieldMessage>}
        */}
        {!!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }
}
