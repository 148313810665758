import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function RouteStart(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M12.2798 25.4029L21.7178 17.5439C22.6776 16.7446 22.6781 15.2709 21.7189 14.471L12.2809 6.6001C10.9784 5.51385 9 6.44005 9 8.13607L9 23.866C9 25.5613 10.977 26.4877 12.2798 25.4029Z" />
        <path d="M12.2798 25.4029L21.7178 17.5439C22.6776 16.7446 22.6781 15.2709 21.7189 14.471L12.2809 6.6001C10.9784 5.51385 9 6.44005 9 8.13607L9 23.866C9 25.5613 10.977 26.4877 12.2798 25.4029Z" />
        <path d="M12.2798 25.4029L21.7178 17.5439C22.6776 16.7446 22.6781 15.2709 21.7189 14.471L12.2809 6.6001C10.9784 5.51385 9 6.44005 9 8.13607L9 23.866C9 25.5613 10.977 26.4877 12.2798 25.4029Z" />
        <path
          d="M12.2798 25.4029L21.7178 17.5439C22.6776 16.7446 22.6781 15.2709 21.7189 14.471L12.2809 6.6001C10.9784 5.51385 9 6.44005 9 8.13607L9 23.866C9 25.5613 10.977 26.4877 12.2798 25.4029Z"
          fillOpacity="0.7"
        />
        <path
          d="M12.2798 25.4029L21.7178 17.5439C22.6776 16.7446 22.6781 15.2709 21.7189 14.471L12.2809 6.6001C10.9784 5.51385 9 6.44005 9 8.13607L9 23.866C9 25.5613 10.977 26.4877 12.2798 25.4029Z"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    </IconContainer>
  )
}
