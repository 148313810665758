import type { CSSProperties } from 'react'

import { useMemo } from 'react'
import { makeStyles } from '@mui/styles'

import { theme } from '@/local/components'

export interface Props extends workwave.Div {
  dimension: number
  speed?: 'slow' | 'normal' | 'fast'
}

const getOffset = ({ dimension }: Props) => dimension + dimension / 2

const getDuration = ({ speed }: Props) => {
  switch (speed) {
    case 'slow':
      return 2
    case 'fast':
      return 0.5
    case 'normal':
    default:
      return 1
  }
}

const useClasses = makeStyles({
  '@keyframes flashingDotsAnimation': {
    '0%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
  flashingDots: {
    animation: '$flashingDotsAnimation var(--duration) infinite linear alternate',
    backgroundColor: theme.colors.$pureBlack,
    color: theme.colors.$pureBlack,
    animationDelay: 'var(--secondDotDelay)',
    height: 'var(--dimension)',
    width: 'var(--dimension)',
    position: 'relative',
    left: 'var(--left)',
    borderRadius: '50%',
    marginLeft: 2,

    '&::before, &::after': {
      animation: '$flashingDotsAnimation var(--duration) infinite linear alternate',
      backgroundColor: theme.colors.$pureBlack,
      color: theme.colors.$pureBlack,
      height: 'var(--dimension)',
      width: 'var(--dimension)',
      display: 'inline-block',
      position: 'absolute',
      borderRadius: '50%',
      content: '" "',
      top: 0,
    },

    '&::before': {
      left: 'var(--firstDotLeft)',
      animationDelay: 0,
    },

    '&::after': {
      left: 'var(--lastDotLeft)',
      animationDelay: 'var(--lastDotDelay)',
    },
  },
})

function useStyles(props: Props) {
  const classes = useClasses()

  const styles = useMemo(() => {
    return {
      flashingDots: {
        '--firstDotLeft': `-${getOffset(props)}px`,
        '--lastDotLeft': `${getOffset(props)}px`,
        '--left': `${getOffset(props) + 2}px`,
        '--dimension': `${props.dimension}px`,
        '--secondDotDelay': `${getDuration(props) / 2}s`,
        '--lastDotDelay': `${getDuration(props)}s`,
        '--duration': `${getDuration(props)}s`,
      } as CSSProperties,
    } as const
  }, [props])

  return [classes, styles] as const
}

export function FlashingDots(props: Props) {
  const [classes, styles] = useStyles(props)

  return <div style={styles.flashingDots} className={classes.flashingDots} />
}
