import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Pause(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 17 17">
        <path d="M5,2 C5.55228475,2 6,2.44771525 6,3 L6,13 C6,13.5522847 5.55228475,14 5,14 L4,14 C3.44771525,14 3,13.5522847 3,13 L3,3 C3,2.44771525 3.44771525,2 4,2 L5,2 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,13 C13,13.5522847 12.5522847,14 12,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,3 C10,2.44771525 10.4477153,2 11,2 L12,2 Z"></path>
      </svg>
    </IconContainer>
  )
}
