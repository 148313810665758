import type { MainSelection } from '@/atoms'

export const getOrderMarkerIdFromOrderStepIds = (
  orderStepId: workwave.DeepReadonly<MainSelection['orderSteps']>,
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
) => {
  const markerIds = new Set<string>()

  for (const id of orderStepId) {
    const extOrder = extendedOrderSteps[id]

    if (!extOrder) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`The Map is trying to select an unknown Order ID: ${id}`)
      }
      continue
    }

    // ignore selected order at depot
    if (!extOrder.atDepot) {
      const latLng = extOrder.location.rawLatLngAsString
      markerIds.add(latLng)
    }
  }

  return markerIds
}
