import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'userManagement.3pc.view.fieldTitle' }),
    territoriesCount: (count: number) =>
      translate({ id: 'userManagement.3pc.view.territoriesCount', values: { count } }),
    allDrivers: translate({ id: 'userManagement.3pc.view.allDrivers' }),
    allVehicles: translate({ id: 'userManagement.3pc.view.allVehicles' }),
    vehiclesCount: (count: number) =>
      translate({ id: 'userManagement.3pc.view.vehiclesCount', values: { count } }),
    driversCount: (count: number) =>
      translate({ id: 'userManagement.3pc.view.driversCount', values: { count } }),
  }))

  return api
}
