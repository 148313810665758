import { Alert, Checkbox, FormControlLabel, FormLabel, Stack, Typography } from '@mui/material'
import { useController } from '../../../../../hooks/useController'
import { useControllerActions } from '../../../../../hooks/useControllerActions'
import { useTexts } from '../../../../../hooks/useTexts'

const includePodTestId = 'export-route-include-pod'

export function NoApprovedRoutes() {
  const {
    data: { includePod, selectedRoutes },
  } = useController()

  const handlers = useControllerActions()

  const texts = useTexts()

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <FormLabel id="filter-picker">{texts.filterTitle}</FormLabel>
        <Typography variant="body1">{texts.filterSelectedLabel}</Typography>
        <Typography variant="caption">{texts.filterSelectedDescription}</Typography>
      </Stack>

      <FormControlLabel
        data-testid={includePodTestId}
        data-trackid={includePodTestId}
        control={
          <Checkbox size="small" checked={includePod} onChange={handlers.onChangeIncludePod} />
        }
        label={texts.includePod}
      />

      <Alert
        sx={{
          '& .MuiAlert-icon': {
            alignItems: 'center',
          },
        }}
        severity="info"
      >
        {selectedRoutes.length === 1 ? texts.noApprovedRouteWarning : texts.noApprovedRoutesWarning}
      </Alert>
    </Stack>
  )
}
