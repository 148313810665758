import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectGeofences } from '@/features/domain/geofence'

type GeofenceData =
  | {
      geofence: true
      geofenceId: string
      geofenceNoLongerExists: boolean
    }
  | { geofence: false }

export function useGeofenceData(event: uui.domain.client.gps.wwgps.EventInfo) {
  const geofences = useSelector(selectGeofences)

  return useMemo<GeofenceData>(() => {
    if (event.type !== 'geofence-trigger') {
      return { geofence: false }
    }

    const geofenceId = event.data.alert_id ?? 'no-id'
    const geofenceNoLongerExists = !geofences[geofenceId]

    return {
      geofence: true,
      geofenceId,
      geofenceNoLongerExists,
    }
  }, [event, geofences])
}
