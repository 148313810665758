import { schedulerPreferencesAtom } from '../../schedulerPreferences'
import { unavailableRouteFilter } from '../filters'
import { project } from '../project'

let lastShowUnavailableRoutesValue = schedulerPreferencesAtom.preferences.showUnavailableRoutes

export function syncUnavailableRoutesVisibility() {
  // If the showUnavailableRoutes value hasn't changed, don't do anything
  if (lastShowUnavailableRoutesValue === schedulerPreferencesAtom.preferences.showUnavailableRoutes)
    return

  // Update the showUnavailableRoutes value
  lastShowUnavailableRoutesValue = schedulerPreferencesAtom.preferences.showUnavailableRoutes

  if (schedulerPreferencesAtom.preferences.showUnavailableRoutes) {
    project.resourceStore.removeFilter('unavailableRouteFilter')
  } else {
    project.resourceStore.addFilter(unavailableRouteFilter)
  }
}
