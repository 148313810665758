import { useRef } from 'react'
import { useSelector } from 'react-redux'

import { selectEnableDynamicLoads } from '@/features/domain/territory'

import { useComponentSize } from '@/local/hooks'

import { RenderFrom } from '../../../../../components/RenderFrom'
import { StatsContainer } from '../StatsContainer'
import { TimeWindowsBlock } from './components/TimeWindowsBlock'
import { GoToPairedButton } from './components/GoToPairedButton'
import { PlannedEtaBlock } from './components/PlannedEtaBlock'
import { LoadsBlock } from './components/LoadsBlock'
import { TypeBlock } from './components/TypeBlock'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

const plannedEtaBlockWidth = 105
const typeBlockWidth = 130
const timeWindowsBlockWidth = 200
const loadsBlockWidth = 150

const typeBlockRequiredWidth = typeBlockWidth
const plannedEtaBlockRequiredWidth = typeBlockRequiredWidth + plannedEtaBlockWidth
const timeWindowsBlockRequiredWidth = plannedEtaBlockRequiredWidth + timeWindowsBlockWidth

export function OrderStats(props: Props) {
  const { extOrder } = props

  const ref = useRef<HTMLDivElement | null>(null)
  const [{ width }] = useComponentSize(ref)

  const enableDynamicLoads = useSelector(selectEnableDynamicLoads)

  if (extOrder.isUnassigned || extOrder.hasBeenExecuted) return null

  const {
    routeId,
    violations,
    stepStartSec,
    orderStepType,
    order: { loads },
    orderStep: { id, timeWindows, serviceTimeSec, trackingData },
  } = extOrder

  const loadsBlockRequiredWidth =
    timeWindowsBlockRequiredWidth + (Object.keys(loads).length / 3) * loadsBlockWidth

  return (
    <StatsContainer ref={ref}>
      <RenderFrom width={width} renderFrom={typeBlockRequiredWidth}>
        <TypeBlock type={orderStepType} serviceTimeSec={serviceTimeSec} />
        <GoToPairedButton extOrder={extOrder} />
      </RenderFrom>

      <RenderFrom width={width} renderFrom={plannedEtaBlockRequiredWidth}>
        <PlannedEtaBlock routeId={routeId} orderStepId={id} stepStartSec={stepStartSec} />
      </RenderFrom>

      <RenderFrom width={width} renderFrom={timeWindowsBlockRequiredWidth}>
        <TimeWindowsBlock timeWindows={timeWindows} violations={violations} />
      </RenderFrom>

      <RenderFrom width={width} renderFrom={loadsBlockRequiredWidth}>
        <LoadsBlock
          loads={loads}
          trackingData={trackingData}
          showDynamicLoads={enableDynamicLoads}
        />
      </RenderFrom>
    </StatsContainer>
  )
}
