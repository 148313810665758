import { useCallback, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'

import { validateDriverDelete } from '@/features/domain/driver'
import { useEditingStateWithCtx, resetCrudSelection } from '@/atoms'
import { useAppDispatch } from '@/store'

import { useDeleteDriverModal } from '../../../../modals/DeleteDriver'
import { useChangePasswordModal } from '../../../../modals/ChangePassword'

import { useTexts } from './useTexts'

export function useViewHeaderProps(driver: uui.domain.client.rm.Driver) {
  const texts = useTexts()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { setEditing } = useEditingStateWithCtx('driver')
  const { show: showDeleteModal } = useDeleteDriverModal()
  const { show: showChangePasswordModal } = useChangePasswordModal()

  const [validatingDelete, setValidatingDelete] = useState<boolean>(false)
  const [deleteValidationResult, setDeleteValidationResult] = useState<
    uui.domain.server.rm.DeleteDriverValidationResults | undefined
  >()

  const { id: driverId, name } = driver

  const onClose = useCallback(() => {
    resetCrudSelection('drivers')
  }, [])

  const onEdit = useCallback(() => setEditing([driverId]), [setEditing, driverId])

  const textsToExport = useMemo(() => {
    return {
      title: name,
    }
  }, [name])

  const onDelete = useCallback(async () => {
    setValidatingDelete(true)

    const result = await dispatch(validateDriverDelete([driverId]))

    if (validateDriverDelete.rejected.match(result)) {
      throw new Error(result.payload?.message ?? 'Internal error')
    }

    setDeleteValidationResult(result.payload)
    setValidatingDelete(false)
    showDeleteModal()
  }, [dispatch, driverId, setValidatingDelete, showDeleteModal])

  const moreActions = useMemo(
    () => [
      {
        id: 'delete',
        title: texts.delete(1),
        action: onDelete,
        disabled: false,
        validating: validatingDelete,
      },
      {
        id: 'changePassword',
        title: texts.changePassword,
        action: async () => {
          showChangePasswordModal()
        },
        disabled: false,
        color: theme.palette.error.main,
      },
    ],
    [onDelete, texts, showChangePasswordModal, validatingDelete, theme],
  )

  return {
    onDelete,
    onClose,
    onEdit,
    texts: textsToExport,
    moreActions,
    validatingDelete,
    deleteValidationResult,
  }
}
