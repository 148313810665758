import type { PopupPlugin } from '../types'
import { toast } from 'react-toastify'
import { intl } from '@/intl'

const extractedMapClosed = (): string => intl.translate({ id: 'extractedMapClosed.toast.close' })

/**
 * Show a toast notification every time the popup is closed.
 */
export function createShowToastOnClosePlugin() {
  const plugin: PopupPlugin = {
    onClose: () => {
      toast.success(extractedMapClosed())
    },
  }

  return plugin
}
