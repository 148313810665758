import type { RouteDetailEntry } from '../../types'
import { RouteDetailsForm } from './components/RouteDetailsForm'
import { RouteDetailsView } from './components/RouteDetailsView'

interface Props {
  date: string
  editing: boolean
  onDiscard: () => void
  vehicleId: string
  routeColor: string
  routeDetailsData: RouteDetailEntry[]
  clearEditingState: () => void
}

export function RouteDetailsContent(props: Props) {
  const { editing, routeDetailsData, routeColor, vehicleId, date, onDiscard, clearEditingState } =
    props

  switch (editing) {
    case true:
      return (
        <RouteDetailsForm
          date={date}
          onDiscard={onDiscard}
          vehicleId={vehicleId}
          routeColor={routeColor}
          routeDetailsData={routeDetailsData}
          clearEditingState={clearEditingState}
        />
      )

    case false:
      return <RouteDetailsView routeDetailsData={routeDetailsData} routeColor={routeColor} />
  }
}
