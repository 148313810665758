import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const { onDisableCompany } = useControllerActions()

  const { close, status, invalid } = useController()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          disabled={invalid || status !== 'ready'}
          onClick={onDisableCompany}
          testid="disable-company-modal__button-disable"
          variant="contained"
          color="primary"
        >
          {texts.footer.disable}
        </ModalFooterButton>
      }
      secondaryAction={<ModalFooterButton onClick={close}>{texts.footer.cancel}</ModalFooterButton>}
    />
  )
}
