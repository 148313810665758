import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { findPlaceMarker } from './findPlaceMarker'
import { updatePlaceFeature } from './updatePlaceFeature'

const nonblockingRender = createNonBlockingRenderByTime()

export const mapStylesUpdatePlaceFeatures = async (
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.Place>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['place'],
  selection: Set<string>,
) => {
  const features = layer.getSource()?.getFeatures() ?? []

  nonblockingRender.reset()

  for (let index = 0; index < features.length; index++) {
    await nonblockingRender.next()

    const feature = features[index]

    if (!feature) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Trying to update Place marker style but no feature exist at index ${index}`,
        )
      }

      continue
    }

    const featureId = feature.getId() as string | undefined

    if (!featureId) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Trying to update Place marker style but the feature found at index ${index} has no ID`,
        )
      }

      continue
    }

    const marker = findPlaceMarker(markers, featureId)

    if (!marker) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Trying to update Place marker style but the feature found at index ${index} has no ID`,
        )
      }

      continue
    }

    const customMode = marker.mode

    // do not process marker with custom mode
    // custom mode is are managed through a marker changelog
    if (customMode) continue

    const selected = selection.has(marker.id)
    updatePlaceFeature(mapStyles, marker, feature, selected)
  }
}
