import type { FormFields, FormErrors } from '../../../../../hooks/formFields'

import { useEffect } from 'react'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { usePhoneNumberRequired } from './usePhoneNumberRequired'
import { usePhoneNumberValid } from './usePhoneNumberValid'

export function useValidations() {
  const formApi = useFormApi<FormFields, FormErrors>()
  const phoneNumberRequired = usePhoneNumberRequired()
  const phoneNumberValid = usePhoneNumberValid()

  useEffect(() => {
    formApi.addValidation('phone-number-required', phoneNumberRequired, 'phoneNumber')
    formApi.addValidation('phone-number-valid', phoneNumberValid, 'phoneNumber')

    return () => {
      formApi.removeValidation('phone-number-required')
      formApi.removeValidation('phone-number-valid')
    }
  }, [formApi, phoneNumberRequired, phoneNumberValid])
}
