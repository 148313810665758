import type { SelectionRange } from '../../typings'

import { useMemo } from 'react'
import {
  format,
  isEqual,
  subDays,
  endOfMonth,
  startOfMonth,
  eachDayOfInterval,
  addDays,
} from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { useDaysWithOrders } from '@/hooks'
import { selectTerritory } from '@/features/domain/territory'

export function useOperationsCalendarData(
  firstDayNotArchived: Date,
  today: Date,
  selection?: SelectionRange,
) {
  const {
    licensingLimits: { horizonView },
  } = useSelector(selectTerritory)
  const daysWithOrders = useDaysWithOrders()

  const calendarSelection = useMemo(() => {
    return {
      selectionStart: selection?.min || today,
      selectionEnd: selection?.max,
    }
  }, [today, selection])

  const todayAsString = useMemo<string>(() => format(today, 'yyyyMMdd'), [today])

  const calendarData = useMemo(() => {
    const minDate = startOfMonth(firstDayNotArchived)
    const lastSelectableDate = addDays(firstDayNotArchived, horizonView - 1)
    const maxDate = endOfMonth(lastSelectableDate)

    /**
     * Let's disable all dates between the minimum date on the calendar and the first NOT archived day
     *
     * NOTE: If firstDayNotArchived is equal to the start of his month (minDate) we don't have to
     *       disable anything before it
     */
    const disabledDates = !isEqual(minDate, firstDayNotArchived)
      ? eachDayOfInterval({
          start: minDate,
          end: subDays(firstDayNotArchived, 1),
        })
      : []

    const disabledDays: Record<string, number> = disabledDates.reduce((acc, d) => {
      acc[format(d, 'yyyyMMdd')] = 1
      return acc
    }, {})

    return {
      minDate,
      maxDate,
      disabledDays,
      lastSelectableDate,
      minDateAsString: format(minDate, 'yyyyMMdd'),
      maxDateAsString: format(maxDate, 'yyyyMMdd'),
      firstNonArchivedDay: format(firstDayNotArchived, 'yyyyMMdd'),
    }
  }, [horizonView, firstDayNotArchived])

  return {
    calendarSelection,
    daysWithOrders,
    todayAsString,
    calendarData,
  }
}
