import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { MapControls } from '@/components/MapControls'

import { DriversRoot } from './form/DriversRoot'
import { Map } from './components/Map'

export function Drivers() {
  return (
    <HorizontalLayout data-testid="setup-drivers-root">
      <DriversRoot />

      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />

          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
