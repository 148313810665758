import type Feature from 'ol/Feature'

import Icon from 'ol/style/Icon'
import { Style } from 'ol/style'
import Point from 'ol/geom/Point'
import Geometry from 'ol/geom/Geometry'

import { gis } from '@/server-data'

import { getGeofenceSvgText } from '../../../../../utils/getGeofenceSvgText'
import { mapAtom } from '../../../../../atoms/map/map'

const labelStylesCache: Map<string, Style> = new Map()

export function getGeofenceLabelStyle(
  type: uui.domain.ui.map.markers.Geofence['type'],
  label: string,
) {
  const labelCacheId = `${type}_${label}`

  if (labelStylesCache.has(labelCacheId)) {
    const cachedLabelStyle = labelStylesCache.get(labelCacheId)

    if (cachedLabelStyle) return cachedLabelStyle
  }

  const labelToRender = `${getGeofenceTypeIcon(type)} ${label}`

  const style = new Style({
    image: new Icon({
      opacity: 1,
      scale: 1,
      src:
        'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(getGeofenceSvgText(labelToRender)),
    }),

    geometry: (feature: Feature<Geometry>) => {
      if (!feature) return

      const radius = feature.getProperties().radius as number
      if (!radius) return

      const geometry = feature.getGeometry()

      if (geometry instanceof Geometry && geometry.getType() === 'Point') {
        const center = geometry as Point
        const centerCoordinate = center.getCoordinates()

        const bottomLatLng = gis.computeOffset(
          gis.fromCoordinateToLatLng(centerCoordinate),
          radius,
          180,
        )
        const bottomCoordinate = gis.fromLatLngToCoordinate(bottomLatLng)

        let bottomPoint = new Point(bottomCoordinate)
        const bottomPixels = mapAtom.map.getPixelFromCoordinate(bottomCoordinate)

        const [x, y] = bottomPixels
        const shiftedBottomCoord = mapAtom.map.getCoordinateFromPixel([x, y + 20])
        bottomPoint = new Point(shiftedBottomCoord)

        return bottomPoint
      }
    },
  })

  labelStylesCache.set(labelCacheId, style)
  return style
}

function getGeofenceTypeIcon(type: uui.domain.ui.map.markers.Geofence['type']) {
  switch (type) {
    case 'arriving':
      return '&#8592;'
    case 'departing':
      return '&#8594;'
    default:
      return '&#8644;'
  }
}
