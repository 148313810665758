import type { RouteDetailEntry } from '../../../types'

import { computePreparationTimeEntry } from './computePreparationTimeEntry'
import { computeCloseoutTimeEntry } from './computeCloseoutTimeEntry'
import { computeEntryStartData } from './computeEntryStartData'
import { computeEntryEndData } from './computeEntryEndData'
import { computeOrdersCount } from './computeOrdersCount'

interface Params {
  driverId: string
  extRoute?: uui.domain.client.rm.ExtendedRoute
  routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable
  driverName: string
  arrivalDepot: string
  lastDriverId: string
  firstDriverId: string
  departureDepot: string
  routeTrackingData: uui.domain.server.rm.RouteTrackingDataRegistryEntry['routeTrackingData']
  driverTrackingData: uui.domain.server.rm.RouteTrackingDataRegistryEntry['driverTrackingData']
  endsAfterWorkingDayEnd: boolean
  mobileAppsTrackingType: 'ONLY_ORDERSTEPS' | 'ORDERSTEPS_AND_BOUND'
  executedOrderStepsByDriver?: Record<string, string[]>
  startsBeforeWorkingDayStart: boolean
}

export function computeRouteDetailEntry({
  driverId,
  extRoute,
  routeInfo,
  driverName,
  arrivalDepot,
  lastDriverId,
  firstDriverId,
  departureDepot,
  routeTrackingData,
  driverTrackingData = {},
  executedOrderStepsByDriver = {},
  endsAfterWorkingDayEnd,
  mobileAppsTrackingType,
  startsBeforeWorkingDayStart,
}: Params): RouteDetailEntry {
  const trackingData = driverTrackingData?.[driverId] ?? undefined
  const shiftStart = trackingData?.shiftStart ?? undefined
  const shiftEnd = trackingData?.shiftEnd ?? undefined

  const start = computeEntryStartData({
    driverId,
    routeInfo,
    driverName,
    shiftStart,
    firstDriverId,
    departureDepot,
    routeTrackingData,
    mobileAppsTrackingType,
    startsBeforeWorkingDayStart,
  })

  const end = computeEntryEndData({
    driverId,
    shiftEnd,
    routeInfo,
    driverName,
    arrivalDepot,
    lastDriverId,
    routeTrackingData,
    endsAfterWorkingDayEnd,
    mobileAppsTrackingType,
  })

  const preparation = computePreparationTimeEntry({
    extRoute,
    driverId,
    approvedRoute: routeInfo.approvedVersion,
    firstDriverId,
    departureDepot,
    routeTrackingData,
  })

  const closeout = computeCloseoutTimeEntry({
    extRoute,
    driverId,
    arrivalDepot,
    lastDriverId,
    approvedRoute: routeInfo.approvedVersion,
    routeTrackingData,
  })

  const ordersCount = computeOrdersCount(routeInfo, driverId, executedOrderStepsByDriver)

  const gotTrackedStart = start
    ? start.type === 'routeStart'
      ? start.data.tracked.sec !== -1
      : start.data.sec !== -1
    : false

  const gotTrackedEnd = end
    ? end.type === 'routeEnd'
      ? end.data.tracked.sec !== -1
      : end.data.sec !== -1
    : undefined

  const gotTrackedPreparation = preparation?.type ?? 'planned-only' !== 'planned-only'
  const gotTrackedCloseout = closeout?.type ?? 'planned-only' !== 'planned-only'

  const gotTrackingData =
    gotTrackedStart || gotTrackedEnd || gotTrackedPreparation || gotTrackedCloseout

  //console.log(gotTrackingData, driverName)
  const type = gotTrackingData ? 'entry' : 'placeholder'

  return {
    end,
    type,
    start,
    closeout,
    driverId,
    driverName,
    ordersCount,
    preparation,
  }
}
