import { useState } from 'react'
import { Tabs, Stack, Tab } from '@mui/material'
import { AddTestData } from './components/AddTestData'
import { DeviceStatusTester } from './components/DeviceStatusTester'
import { TenantSourceTester } from './components/TenantSourceTester'

export function TelematicsTesters() {
  const [tab, setTab] = useState(0)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  return (
    <Stack spacing={2}>
      <h1>Telematics</h1>

      <Tabs value={tab} onChange={handleChange}>
        <Tab label="GPS Positions" />
        <Tab label="States" />
      </Tabs>

      {tab === 0 ? (
        <AddTestData />
      ) : (
        <>
          <TenantSourceTester />
          <hr />
          <DeviceStatusTester />
          <hr />
        </>
      )}
    </Stack>
  )
}
