import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Info(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 20 20">
        <path d="M10 .8A9.2 9.2 0 0 0 .8 10a9.2 9.2 0 0 0 9.2 9.2 9.2 9.2 0 0 0 9.2-9.2A9.2 9.2 0 0 0 10 .8Zm0 3.6a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4Zm1.6 10.8H8.4v-.8h.8V9.2h-.8v-.8H10.8v6h.8v.8Z" />
      </svg>
    </IconContainer>
  )
}
