import type { EnvironmentType } from '@/atoms'
import { useEnvironment } from '@/atoms'

/**
 * Get the correct hostname based on the current one.
 */
export function useHostname() {
  const environment = useEnvironment()
  return getHostname(environment?.env ?? 'production')
}

function getHostname(env: EnvironmentType) {
  switch (env) {
    case 'local':
      // remember to set it in your own machine's hosts file
      return 'local.routemanager.workwave.com'

    case 'dev':
      return 'dev.routemanager.workwave.com'

    case 'qa':
      return 'qa.routemanager.workwave.com'

    case 'test-a':
      return 'test-a.routemanager.workwave.com'

    case 'test-b':
      return 'test-b.routemanager.workwave.com'

    case 'production':
    default:
      return 'routemanager.workwave.com'
  }
}
