import { useCloseModal, useShowModal } from '@/atoms'

import { Root } from './Root'
import { modalId } from './hooks/useController'

export function usePhoneNumbersModal() {
  const show = useShowModal(modalId)
  const close = useCloseModal()

  return {
    show,
    close,
    Modal: Root,
  }
}
