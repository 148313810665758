import {
  setEditingState,
  setCrudSelection,
  setVehicleFormException,
  setPagination,
  getPagination,
} from '@/atoms'

import { selectVehicles, createVehicleException } from '@/features/domain/vehicle'
import { type ControlledNavigateFunction } from '@/routing'
import { type ReadOnlyStatus } from '@/hooks'
import { store } from '@/store'

/**
 * Start vehicle editing and navigate to the correct page.
 */
export async function editVehicleException(
  unifiedId: string,
  exceptionDate: string,
  readonlyStatus: ReadOnlyStatus,
  navigate: ControlledNavigateFunction<uui.routing.Routing | uui.routing.Setup>,
) {
  const vehicles = selectVehicles(store.getState())
  const unifiedVehicle = vehicles[unifiedId]

  if (!unifiedVehicle) {
    throw new Error(`Cannot find vehicle with id: ${unifiedId}`)
  }

  if (!unifiedVehicle.hasRoutingLicense) {
    throw new Error(`Vehicle has not routing license`)
  }

  if (readonlyStatus.readonly) {
    setCrudSelection('unifiedVehicles', [unifiedId])
    navigate('vehicles')
    return
  }

  const settings = unifiedVehicle.vehicle.settings[exceptionDate]
  const create = settings === undefined
  if (create) {
    const result = await store.dispatch(
      createVehicleException({ unifiedId, recurrence: exceptionDate }),
    )
    if (!createVehicleException.fulfilled.match(result)) {
      throw new Error(result.payload?.message ?? 'Internal error')
    }
  }

  setCrudSelection('unifiedVehicles', [unifiedId])
  setVehicleFormException({ editMode: create ? 'create' : 'edit', exception: exceptionDate })
  setPagination({ vehicleTab: { ...getPagination().vehicleTab, current: 1 } })
  setEditingState({ ctx: 'vehicle', editing: true, editingEntities: [unifiedId] })
  navigate('vehicles')
}
