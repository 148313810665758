import { useController } from '../../../../hooks/useController'

import { GpsOnlyBody } from './GpsOnlyBody'
import { RmBody } from './RmBody'

export function ComposeMessageBody() {
  const {
    data: { gpsOnly },
  } = useController()

  return gpsOnly ? <GpsOnlyBody /> : <RmBody />
}
