import { ConfirmPanel as ConfirmPanelComp } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'
import { useActions } from './hooks/useActions'

interface Props {
  places: uui.domain.client.gps.wwgps.Place[]
  setView: (view: 'default' | 'delete') => void
}

export function ConfirmPanel(props: Props) {
  const { setView, places } = props
  const actions = useActions(places, setView)

  const texts = useTexts()

  return (
    <ConfirmPanelComp
      title={texts.confirmPanelTitle(places.length)}
      description={texts.confirmPanelSubtitle}
      confirmationText={texts.delete}
      onCancel={actions.onSwitchToDefaultPanel}
      onConfirm={actions.onDelete}
      testid="setup-place__bulk-delete-confirm-panel"
    />
  )
}
