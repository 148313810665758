import type { BulkFieldValue } from '../../../types'

import { durationUtils } from '@/server-data'
import { intl } from '@/intl'

export function computeServiceTimeSec(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
): BulkFieldValue<string> {
  const serviceTimeSec = orders[0].orderStep.serviceTimeSec
  const exact = orders.every(o => o.orderStep.serviceTimeSec === serviceTimeSec)
  const formatSeconds = durationUtils.formatSeconds(intl.translate)

  if (exact) {
    return {
      status: 'exact',
      value: formatSeconds(serviceTimeSec, 'DURATION_HH_MM'),
    }
  }

  return {
    status: 'mixed',
  }
}
