import { useMemo } from 'react'

import { useSelectLastLoginAccountData } from '@/atoms'

export function useLoginInitialValues() {
  const accountData = useSelectLastLoginAccountData()

  return useMemo(() => {
    return { username: accountData?.username ?? '', rememberMe: true }
  }, [accountData])
}
