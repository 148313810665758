import { useCallback } from 'react'

import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { invalidateSpeedEvent } from '@/features/domain/deviceEvents'

export function useActions(
  event: uui.domain.client.gps.wwgps.EventInfo,
  setView: (view: 'default' | 'delete') => void,
) {
  const isUnmounted = useIsUnmounted()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const onCancel = useCallback(() => {
    setView('default')
  }, [setView])

  const onConfirm = useCallback(async () => {
    try {
      if (event.type !== 'device-speed-alert') return

      const result = await dispatch(invalidateSpeedEvent(event))

      if (!invalidateSpeedEvent.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      if (!isUnmounted()) {
        setView('default')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [setView, dispatch, toast, event, isUnmounted])

  return {
    onCancel,
    onConfirm,
  }
}
