import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

type GeofenceFeatureMetadata = {
  mode: 'on' | 'off' | 'label'
  type: 'geofence'
  hasViolations: boolean
  ordersCount: number
  ordersWithViolationsCount: number
}

export const setGeofenceFeatureMetadata = <Field extends keyof GeofenceFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
  value: GeofenceFeatureMetadata[Field],
) => {
  target.set(field, value)
}

export const getGeofenceFeatureMetadata = <Field extends keyof GeofenceFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
): GeofenceFeatureMetadata[Field] | undefined => {
  return target.get(field)
}

export const removeGeofenceFeatureMetadata = <Field extends keyof GeofenceFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
) => {
  target.unset(field)
}
