import { User, Numbers, Wrench } from '@/icons'
import { Link } from '@/routing'

import { useTexts } from '../useTexts'

import { SectionButton } from './SectionButton'

type SettingsRoutes = uui.routing.Settings

export function GpsSidebar() {
  const texts = useTexts()

  return (
    <>
      <Link<SettingsRoutes> to="account">
        <SectionButton
          text={texts.account}
          Icon={<User size={18} />}
          testid="settings__account-button"
        />
      </Link>

      <Link<SettingsRoutes> to="language-and-formats">
        <SectionButton
          text={texts.formats}
          Icon={<Numbers size={18} />}
          testid="settings__language-and-formats-button"
        />
      </Link>

      <Link<SettingsRoutes> to="personalization">
        <SectionButton
          text={texts.personalization}
          Icon={<Wrench size={18} />}
          testid="settings__personalization-button"
        />
      </Link>
    </>
  )
}
