import type { LayerUID } from '../../typings'

import { useMapLayerInteractive } from './hooks/useMapLayerInteractive'
import { useMapLayerVisible } from './hooks/useMapLayerVisible'
import { useUpdateOnUnmount } from './hooks/useUpdateOnUnmount'
import { useMapLayerOpacity } from './hooks/useMapLayerOpacity'

type Props = {
  uid: LayerUID
  opacity?: number
  visible?: boolean
  interactive?: boolean
  visibleOnUnmount?: boolean
  interactiveOnUnmount?: boolean
}

export function MapLayer(props: Props) {
  const {
    uid,
    visible = true,
    interactive = false,
    opacity = 1,
    visibleOnUnmount,
    interactiveOnUnmount,
  } = props

  useMapLayerVisible(uid, visible)
  useMapLayerOpacity(uid, opacity)
  useMapLayerInteractive(uid, interactive)
  useUpdateOnUnmount(uid, visibleOnUnmount, interactiveOnUnmount)

  return null
}
