import { Grid } from '@mui/material'

import { RouteManager } from '@workwave-tidal/icons/logos'

export function CenteredLogo() {
  return (
    <Grid
      container
      width="100%"
      height="100%"
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <RouteManager width={350} />
      </Grid>
    </Grid>
  )
}
