import { Entry } from '../Entry'

export function Entry20211216() {
  return (
    <Entry title="16/12/2021 - v3.0.0-beta-12">
      Link to the{' '}
      <a
        href="https://workwave.atlassian.net/projects/RM/versions/12323/tab/release-report-all-issues"
        target="_blank"
        rel="noreferrer"
      >
        Jira Release
      </a>
    </Entry>
  )
}
