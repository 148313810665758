import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function ExternalLink(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M6.53886572,0.894736842 C6.21533528,0.894736842 5.92452295,1.08859648 5.79970411,1.3881617 C5.67588382,1.68672838 5.74461227,2.02932414 5.97328039,2.25799226 L6.96207968,3.24679216 L1.03318466,9.17568718 C0.772325439,9.42614096 0.667245426,9.7980502 0.758470293,10.1479825 C0.849695159,10.4979149 1.12297005,10.7711897 1.47290243,10.8624145 C1.8228348,10.9536392 2.19474401,10.8485591 2.44519772,10.5876998 L8.37409254,4.65880482 L9.36289243,5.64760471 C9.59156055,5.87527428 9.93415632,5.94500108 10.232723,5.82118079 C10.3315795,5.78024021 10.4192183,5.72049891 10.4921125,5.64760471 C10.6388995,5.50081775 10.7261479,5.29870409 10.7261479,5.08201858 L10.7261479,1.6943573 C10.7261479,1.25299788 10.3678868,0.894736842 9.92652739,0.894736842 L6.53886572,0.894736842 Z" />
      </svg>
    </IconContainer>
  )
}
