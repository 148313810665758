import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'

import { Trash } from '@/icons'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { useTexts } from './hooks/useTexts'

export interface Props {
  onDelete: () => void
}

export function Actions(props: Props) {
  const { onDelete } = props
  const texts = useTexts()

  return (
    <SecondaryColumnHeaderActionsRow>
      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={onDelete}
            disabled={preventEditing}
            testid="setup-alert__form-bulkdelete"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
