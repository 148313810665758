import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

interface Payload {
  address: string
  snapToRoad?: boolean
  assetTypes?: ('depot' | 'place')[]
  bounds?: uui.domain.LatLngBounds
  excludeOutOfTerritoryItems?: boolean
}

/**
 * Search places on the map
 *
 * @private
 */
export const searchOnMap = createAsyncThunk<
  // Return type of the payload creator
  uui.domain.client.gps.SearchOnMapItem[],
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('ui/searchOnMap', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/ui/searchOnMap', {
      category: 'rpc',
      type: 'rpc/ui/searchOnMap',
      params: payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    return result.items
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {searchOnMap}`,
      { tags: ['rpc', 'ui', 'searchOnMap'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Search on Map failed`,
      error,
    })
  }
})
