import type { TodayNotificationValues } from './hooks/useTodayNotificationValues'

export function convertValues(formValues: TodayNotificationValues) {
  // Convert windowOfCommitmentToleranceMins from string to number
  const windowOfCommitmentToleranceMins = parseInt(formValues.windowOfCommitmentToleranceMins)

  return {
    ...formValues,
    windowOfCommitmentToleranceMins,
  }
}
