import { useCallback, useRef } from 'react'
import { Add, FileUpload } from '@mui/icons-material'

import {
  ListHeaderActions,
  ListHeaderPrimaryActionButton,
  type ListHeaderSecondaryActionRef,
} from '@/components/List'

import { useCanAddNewOrder, useIsSimulation, useOrdersLimit } from '@/hooks'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../../useTexts'

import { useActions } from './hooks/useActions'

export function Actions() {
  const { editing, onCreate, onImport } = useActions()

  const canAddNewOrder = useCanAddNewOrder()

  const isSimulation = useIsSimulation()
  const ordersLimit = useOrdersLimit()
  const texts = useTexts()

  const secondaryActionMenuRef = useRef<ListHeaderSecondaryActionRef>(null)

  const onImportOrderItemClick = useCallback(() => {
    secondaryActionMenuRef.current?.close()
    onImport()
  }, [onImport])

  return (
    <ListHeaderActions>
      <ReadOnlyTooltip
        placement="bottom"
        subtitle={texts.disabledCreateTooltipSubtitle(isSimulation, ordersLimit)}
        canEdit={canAddNewOrder}
        title={texts.disabledCreateTooltipTitle(isSimulation)}
        render={preventEditing => (
          <Tooltip title={texts.addOrder} placement="bottom">
            <ListHeaderPrimaryActionButton
              disabled={preventEditing || editing || !canAddNewOrder}
              className="pendo-setup__new-order"
              trackId="orders__create-new-button"
              onClick={onCreate}
            >
              <Add />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
      <ReadOnlyTooltip
        placement="bottom"
        subtitle={texts.disabledCreateTooltipSubtitle(isSimulation, ordersLimit)}
        canEdit={canAddNewOrder}
        title={texts.disabledCreateTooltipTitle(isSimulation)}
        render={preventEditing => (
          <Tooltip title={texts.importOrders} placement="bottom">
            <ListHeaderPrimaryActionButton
              trackId="orders__import-button"
              testId="list-header-secondary-action-import-orders"
              className="pendo-setup__new-order"
              disabled={preventEditing || editing || !canAddNewOrder}
              onClick={onImportOrderItemClick}
            >
              <FileUpload />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
    </ListHeaderActions>
  )
}
