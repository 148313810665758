import type { ItemWithPosition } from '../typings'
import {
  getStringifiedItemIds,
  placeSeparatorAtEnds,
  getIdsFromStringifiedIds,
} from './stringifiedItemIdsHelpers'
import { getVisibleAtTopItemsOnly, getFirstVisibleAtTopItem } from './visibleItemsHelpers'
import { getItemIdOrGroupHeaderId } from './guards'

export type UpdateScrollWhenEndsUpdateOptions = {
  type: 'updateScrollWhenEndsUpdate'
  addedAtTop?: boolean
  removedFromTop?: boolean
  addedAtBottom?: boolean
  removedFromBottom?: boolean
}

type Params<T> = {
  scrollHeight: number
  currentScroll: number
  prevStringifiedIds?: string
  getItemId: (item: T) => string
  newItems: ItemWithPosition<T>[]
  prevVisibleItems: ItemWithPosition<T>[]
  scrollUpdateMode: UpdateScrollWhenEndsUpdateOptions
}

type Result<T> =
  | {
      success: true
      itemToMaintainVisible: ItemWithPosition<T>
    }
  | {
      success: false
    }

export const maintainScrollOnEndsUpdate = <T>(params: Params<T>): Result<T> => {
  const {
    newItems,
    getItemId,
    scrollHeight,
    currentScroll,
    prevVisibleItems,
    scrollUpdateMode,
    prevStringifiedIds,
  } = params

  const addedAtTop =
    typeof scrollUpdateMode.addedAtTop === 'boolean' ? scrollUpdateMode.addedAtTop : true
  const addedAtBottom =
    typeof scrollUpdateMode.addedAtBottom === 'boolean' ? scrollUpdateMode.addedAtBottom : true
  const removedFromTop =
    typeof scrollUpdateMode.removedFromTop === 'boolean' ? scrollUpdateMode.removedFromTop : true
  const removedFromBottom =
    typeof scrollUpdateMode.removedFromBottom === 'boolean'
      ? scrollUpdateMode.removedFromBottom
      : true

  // input check
  if (!prevStringifiedIds || !newItems.length) {
    return { success: false }
  }

  // if no possible cases are enabled, no calculations are needed
  if (!addedAtTop && !removedFromTop && !addedAtBottom && !removedFromBottom) {
    return { success: false }
  }

  // if the changes are only at the ends, a common item must exist. It will be used as the pivot to
  // break the list and analyze the "top" and the "bottom" of the list itself
  const prevItemIds = getIdsFromStringifiedIds(prevStringifiedIds)
  let firstCommonItemId: string | undefined
  for (let i = 0, n = prevItemIds.length; i < n && firstCommonItemId === undefined; i++) {
    const firstCommonItem = newItems.find(
      ({ item }) => getItemIdOrGroupHeaderId(item, getItemId).toString() === prevItemIds[i],
    )
    if (firstCommonItem) {
      firstCommonItemId = getItemIdOrGroupHeaderId(firstCommonItem.item, getItemId).toString()
    }
  }
  if (firstCommonItemId === undefined) {
    return {
      success: false,
    }
  }

  const newStringifiedIds = getStringifiedItemIds(newItems, getItemId)

  let prevItemsBeforeCommonItemId = ''
  let newItemsBeforeCommonItemId = ''
  let prevItemAfterCommonItemId = ''
  let newItemsAfterCommonItemId = ''

  const itemsHaveBeen = {
    addedAtTop: false,
    addedAtBottom: false,
    removedFromTop: false,
    removedFromBottom: false,
  }

  // If there are no changes the previously visible item should remain at the same position.
  // ATTENTION: the component heights could have been changed!
  if (!prevStringifiedIds || newStringifiedIds === prevStringifiedIds) {
    const firstCommonItem = getFirstVisibleAtTopItem({
      items: prevVisibleItems,
      scrollY: currentScroll,
      scrollHeight,
    })
    if (firstCommonItem) {
      return {
        success: true,
        itemToMaintainVisible: firstCommonItem,
      }
    } else {
      return {
        success: false,
      }
    }
  }

  // The `itemsHaveBeen` boolean are set here
  firstCommonItemId = placeSeparatorAtEnds(firstCommonItemId)
  if (!prevStringifiedIds.startsWith(firstCommonItemId)) {
    prevItemsBeforeCommonItemId = placeSeparatorAtEnds(
      prevStringifiedIds.split(firstCommonItemId)[0],
    )
  }
  if (!prevStringifiedIds.endsWith(firstCommonItemId)) {
    prevItemAfterCommonItemId = placeSeparatorAtEnds(prevStringifiedIds.split(firstCommonItemId)[1])
  }
  if (!newStringifiedIds.startsWith(firstCommonItemId)) {
    newItemsBeforeCommonItemId = placeSeparatorAtEnds(newStringifiedIds.split(firstCommonItemId)[0])
  }
  if (!newStringifiedIds.endsWith(firstCommonItemId)) {
    newItemsAfterCommonItemId = placeSeparatorAtEnds(newStringifiedIds.split(firstCommonItemId)[1])
  }
  if (prevItemsBeforeCommonItemId !== newItemsBeforeCommonItemId) {
    itemsHaveBeen.removedFromTop = prevItemsBeforeCommonItemId.endsWith(newItemsBeforeCommonItemId)
    itemsHaveBeen.addedAtTop = newItemsBeforeCommonItemId.endsWith(prevItemsBeforeCommonItemId)
  }
  if (prevItemAfterCommonItemId !== newItemsAfterCommonItemId) {
    itemsHaveBeen.addedAtBottom = newItemsAfterCommonItemId.startsWith(prevItemAfterCommonItemId)
    itemsHaveBeen.removedFromBottom =
      prevItemAfterCommonItemId.startsWith(newItemsAfterCommonItemId)
  }

  const success =
    (addedAtTop && itemsHaveBeen.addedAtTop) ||
    (removedFromTop && itemsHaveBeen.removedFromTop) ||
    (addedAtBottom && itemsHaveBeen.addedAtBottom) ||
    (removedFromBottom && itemsHaveBeen.removedFromBottom)

  if (success) {
    // ATTENTION: prevVisibleItems contains even the buffered items, prevUserVisibleItems is going to
    // only the visible by the user ones
    const prevUserVisibleItems = getVisibleAtTopItemsOnly({
      items: prevVisibleItems,
      scrollY: currentScroll,
      scrollHeight,
    })

    let firstUserVisibleItem: ItemWithPosition<T> | undefined
    for (let i = 0, n = prevUserVisibleItems.length; i < n && !firstUserVisibleItem; i++) {
      const item = prevUserVisibleItems[i]
      if (
        newStringifiedIds.includes(
          placeSeparatorAtEnds(getItemIdOrGroupHeaderId(item.item, getItemId).toString()),
        )
      ) {
        firstUserVisibleItem = item
      }
    }

    let itemToMaintainVisible: ItemWithPosition<T> | undefined
    // The first item visible by the user could have been removed. This happens when it was in the
    // top (or the bottom) part of the list and some items, including the first visible one, has been removed
    // from the top(or the bottom)
    if (!firstUserVisibleItem) {
      itemToMaintainVisible =
        itemsHaveBeen.removedFromTop && removedFromTop
          ? newItems[0]
          : itemsHaveBeen.removedFromBottom && removedFromBottom
          ? (itemToMaintainVisible = newItems[newItems.length - 1])
          : undefined
    } else if (firstUserVisibleItem) {
      itemToMaintainVisible = firstUserVisibleItem
    }

    if (itemToMaintainVisible) {
      return {
        success: true,
        itemToMaintainVisible,
      }
    }
  }

  return {
    success: false,
  }
}
