/**
 * @private
 */
export const getUserHome = (userType: uui.domain.UserType) => {
  switch (userType) {
    case 'rmGuest':
      return 'orders'

    case 'gpsOnly':
      return 'tracking'

    default:
      return 'routing'
  }
}
