import { useState } from 'react'
import { Typography, Stack, Button, Alert } from '@mui/material'

import { Loads } from './Loads'
import { CustomFields } from './CustomFields'
import { useOnSubmit } from './hooks/useOnSubmit'

interface Props {
  order: uui.domain.client.rm.ExtendedOrderStep
}

export function Form(props: Props) {
  const { order } = props

  const {
    order: { loads: initialLoads },
    orderStep: { customFields: initialCustomFields, id },
  } = order

  const isRouteApproved = order.isUnassigned ? false : order.isRouteApproved

  const [loads, setLoads] = useState<Record<string, number>>(() =>
    Object.entries(initialLoads).reduce((acc, [load, value]) => {
      acc[load] = value / 100
      return acc
    }, {}),
  )

  const [customFields, setCustomFields] = useState<Record<string, string>>(
    () => initialCustomFields,
  )

  const onSubmit = useOnSubmit()

  const handleOnChangeLoad = (key: string, value: number) => {
    setLoads(prev => ({ ...prev, [key]: value }))
  }

  const handleOnChangeCustomField = (key: string, value: string) => {
    setCustomFields(prev => ({ ...prev, [key]: value }))
  }

  const loadsCount = Object.keys(loads).length
  const customFieldsCount = Object.keys(customFields).length

  if (loadsCount === 0 && customFieldsCount === 0) {
    return <Typography>No loads or custom fields set for this order</Typography>
  }

  return (
    <Stack spacing={3}>
      <Stack width={'100%'} spacing={4} direction="row">
        <Loads value={loads} onChange={handleOnChangeLoad} />
        <CustomFields value={customFields} onChange={handleOnChangeCustomField} />
      </Stack>
      <Stack spacing={2}>
        <div>
          <Button
            variant="contained"
            disabled={!isRouteApproved}
            onClick={() => onSubmit(loads, customFields, id)}
          >
            save
          </Button>
        </div>
        {!isRouteApproved && (
          <Alert color="error">
            To insert execution data the route associated with this order has to be approved
          </Alert>
        )}
      </Stack>
    </Stack>
  )
}
