import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function DevTools(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path
          transform="translate(1.000000, 2.000000)"
          d="M14,2.86363636 L14,1.68181818 C14,1.02886364 13.4779167,0.5 12.8333333,0.5 L1.16666667,0.5 C0.522083333,0.5 0,1.02886364 0,1.68181818 L0,2.86363636 L14,2.86363636 Z M0.583333333,4.04545455 L0.583333333,12.3181818 C0.583333333,12.9711364 1.10541667,13.5 1.75,13.5 L12.25,13.5 C12.8945833,13.5 13.4166667,12.9711364 13.4166667,12.3181818 L13.4166667,4.04545455 L0.583333333,4.04545455 Z M9.94408333,7.76640909 L8.40525,8.92695455 L8.97691667,10.7818182 C9.03525,10.9720909 8.8865,11.1363636 8.71966667,11.1363636 C8.66833333,11.1363636 8.61466667,11.121 8.56508333,11.0849545 L7,9.96104545 L5.43491667,11.0849545 C5.38591667,11.1204091 5.33225,11.1363636 5.28033333,11.1363636 C5.1135,11.1363636 4.96416667,10.9720909 5.02308333,10.7818182 L5.59475,8.92695455 L4.05591667,7.76640909 C3.84941667,7.611 3.95616667,7.27890909 4.21341667,7.27595455 L6.13141667,7.2535 L6.74566667,5.41281818 C6.78708333,5.28931818 6.89325,5.22727273 7,5.22727273 C7.10675,5.22727273 7.21291667,5.28931818 7.25433333,5.41281818 L7.86858333,7.2535 L9.78658333,7.27595455 C10.0438333,7.27890909 10.1505833,7.611 9.94408333,7.76640909 Z"
        />
      </svg>
    </IconContainer>
  )
}
