import { useEffect } from 'react'
import { useAppDispatch } from '@/store'
import {
  refreshVoiceConfig,
  refreshMobileAppInteractionSettings,
} from '@/features/domain/territory'

export function useRefreshVoice() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    async function refresh() {
      const result = await dispatch(refreshVoiceConfig())

      if (refreshMobileAppInteractionSettings.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    }

    refresh()
  }, [dispatch])
}
