import { useSelector } from 'react-redux'

import { timeWindowUtils } from '@/utils'
import { usePendingFitMapOnMount } from '@/map'
import { FormColumn } from '@/components/layout'
import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { selectRmVehicles } from '@/features/domain/vehicle'
import { selectCompanies } from '@/features/domain/company'
import { selectTerritory, selectTerritoryLicensingLimits } from '@/features/domain/territory'

import { ViewDetails, FieldMode } from '../../components/ViewDetails'

import { useActions } from './useActions'
import { useBulkViewData } from './useBulkViewData'
import { FormBulkAvatar } from './components/FormBulkAvatar'
import { BulkViewActions } from './components/BulkViewActions'

interface Props {
  orders: uui.domain.client.rm.ExtendedOrderStep[]
}

export function BulkView(props: Props) {
  const vehiclesMap = useSelector(selectRmVehicles)
  const companiesMap = useSelector(selectCompanies)
  const territory = useSelector(selectTerritory)
  const licensingLimits = useSelector(selectTerritoryLicensingLimits)

  const workingDayStartSec = territory.workingDayStartSec
  const allowBarcodes = licensingLimits.trackedVehicles > 0

  const { orders } = props

  const {
    description,
    loads,
    serviceTime,
    eligibility,
    priority,
    tagsIn,
    tagsOut,
    timeWindows,
    title,
    vehicle,
    violations,
    useMetric,
    phone,
    notes,
    email,
    company,
  } = useBulkViewData(orders, vehiclesMap, companiesMap)

  const actions = useActions()

  usePendingFitMapOnMount()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={title}
        description={description}
        action={actions.onCloseView}
        avatar={<FormBulkAvatar size={orders.length} />}
      />

      <BulkViewActions orders={orders} />

      <FormContent footer={<div />}>
        <ViewDetails
          notificationBehavior={territory.notificationBehavior}
          // ---- supported by bulk mode
          eligibility={eligibility}
          loads={loads}
          priority={priority}
          serviceTime={serviceTime}
          tagsIn={tagsIn}
          tagsOut={tagsOut}
          vehicle={vehicle}
          company={company}
          timeWindows={
            timeWindows !== FieldMode.mixed
              ? timeWindowUtils.formatTimeWindows(timeWindows, workingDayStartSec)
              : timeWindows
          }
          violations={violations}
          // ---- unsupported by bulk mode
          customFields={FieldMode.hidden}
          notes={notes}
          timeWindowExceptions={FieldMode.hidden}
          type={FieldMode.hidden}
          phone={phone}
          email={email}
          barcodes={FieldMode.hidden}
          allowBarcodes={allowBarcodes}
          useMetric={useMetric}
        />
      </FormContent>
    </FormColumn>
  )
}
