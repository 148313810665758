import { useState, useCallback } from 'react'

export function useGpsDataCollapsed() {
  const [collapsed, setCollapsed] = useState(true)

  const toggleCollapsed = useCallback(() => {
    setCollapsed(prev => !prev)
  }, [])

  return { collapsed, setCollapsed, toggleCollapsed }
}
