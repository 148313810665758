import { type ResourceModel } from '@bryntum/schedulerpro'

import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'

import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../Scheduler/components/Typography'

interface Props {
  record: ResourceModel
  field: string
  value: number
}

export function TimeLeftCell(props: Props) {
  const { value } = props

  return (
    <SingleColumnContainer>
      <Typography fontSize={12}>
        {!value || value <= 0 ? '-' : <FormatSeconds seconds={value} format="EXTRA_SHORT" />}
      </Typography>
    </SingleColumnContainer>
  )
}
