import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectPlaceMapStyles } from '@/features/domain/ui'

export const useActivePlaceMapStyle = (placeIds: string[]) => {
  const placeMapStyles = useSelector(selectPlaceMapStyles)

  /**
   * This methods retrieve the correct active style for the the place's places.
   * If there are different styles for the chosen places it will return undefined.
   */
  return useMemo(() => {
    let commonMapStyle: 'on' | 'off' | undefined

    for (const id of placeIds) {
      const placeMapStyle = placeMapStyles.custom[id] ?? placeMapStyles.mode

      if (commonMapStyle && placeMapStyle !== commonMapStyle) return

      commonMapStyle = placeMapStyle
    }

    return commonMapStyle
  }, [placeMapStyles, placeIds])
}
