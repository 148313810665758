import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    live: translate({ id: 'setBreadcrumbTimeRangeModal.body.live' }),
    custom: translate({ id: 'setBreadcrumbTimeRangeModal.body.custom' }),
    cancel: translate({ id: 'setBreadcrumbTimeRangeModal.footer.cancel' }),
    showData: translate({ id: 'setBreadcrumbTimeRangeModal.footer.submit' }),
    headerTitle: translate({ id: 'setBreadcrumbTimeRangeModal.header.title' }),
    timeRangeEnd: translate({ id: 'setBreadcrumbTimeRangeModal.body.timeRangeEnd' }),
    trackingMode: translate({ id: 'setBreadcrumbTimeRangeModal.body.trackingMode' }),
    headerSubtitle: translate({ id: 'setBreadcrumbTimeRangeModal.header.subtitle' }),
    timeRangeStart: translate({ id: 'setBreadcrumbTimeRangeModal.body.timeRangeStart' }),
    timeRangeStartInFutureError: translate({
      id: 'setBreadcrumbTimeRangeModal.error.timeRangeStartInTheFuture',
    }),
    timeRangeEndInFutureError: translate({
      id: 'setBreadcrumbTimeRangeModal.error.timeRangeEndInTheFuture',
    }),
    timeRangeStartAfterEndError: translate({
      id: 'setBreadcrumbTimeRangeModal.error.timeRangeStartAfterEnd',
    }),
  }))

  return api
}

export type ValidationTexts = ReturnType<typeof useTexts>
