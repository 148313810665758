import {
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyLabel,
} from '@/forms-legacy'
import { useTexts } from '../hooks/useTexts'

interface Props {
  radius: number
}

export function Radius(props: Props) {
  const { radius } = props
  const texts = useTexts()

  return (
    <ReadonlyBlock half>
      <ReadonlyLabel>{texts.radius}</ReadonlyLabel>
      <ReadonlyField>
        <ReadonlyFieldRow>
          <ReadonlyFieldLabel>{radius} m</ReadonlyFieldLabel>
        </ReadonlyFieldRow>
      </ReadonlyField>
    </ReadonlyBlock>
  )
}
