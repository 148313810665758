import {
  SecondaryColumnContentHorizontalRuler,
  SecondaryColumnContentContainer,
  HorizontalRuler,
} from '@/forms-legacy'

import { NotificationAddresses } from './components/NotificationAddresses'
import { StartEndDateTime } from './components/StartEndTime'
import { GeofenceRepeat } from './components/GeofenceRepeat'
import { MaximumSpeed } from './components/MaximumSpeed'
import { DevicesList } from './components/DevicesList'
import { Timezone } from './components/Timezone'
import { Radius } from './components/Radius'

interface Props {
  geofence: uui.domain.client.gps.wwgps.Geofence
}

export function ViewDetails(props: Props) {
  const { geofence } = props

  return (
    <SecondaryColumnContentContainer>
      <SecondaryColumnContentHorizontalRuler bottomSpace />

      <Radius radius={geofence.radius} />
      <HorizontalRuler />

      <MaximumSpeed maxSpeed={geofence.maxSpeed} />
      <HorizontalRuler />

      <StartEndDateTime startTime={geofence.startTime} endTime={geofence.endTime} />
      <HorizontalRuler />

      <GeofenceRepeat repeat={geofence.repeat} />
      <HorizontalRuler />

      <Timezone timezone={geofence.timezone} />
      <HorizontalRuler />

      <DevicesList deviceIds={geofence.deviceIds} />
      <HorizontalRuler />

      <NotificationAddresses notificationAddresses={geofence.notificationAddresses} />
    </SecondaryColumnContentContainer>
  )
}
