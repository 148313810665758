import type { GpsReportsCookieData } from '@/utils'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { gpsReportsSession } from '@/utils'
import { selectTerritoryId } from '@/features/domain/territory'

import { useHostname } from './useHostname'
import { useAutoLoginQueryString } from './useAutoLoginQueryString'
import { useMapStylesQueryString } from './useMapStylesQueryString'

/**
 * Generate the cookie for the GPS Reports app.
 */
export function useGpsReportsLink() {
  const hostname = useHostname()
  const loginAs = useAutoLoginQueryString()
  const mapStyles = useMapStylesQueryString()
  const gpsReportsLink = `${window.location.protocol}//reports.${hostname}/#/tracking/reports?${loginAs}&${mapStyles}`

  const territoryId = useSelector(selectTerritoryId)

  const createGpsReportsCookie = useCallback(
    (deviceIds: number[]) => {
      const cookieData: GpsReportsCookieData = {
        territoryId,
        selectedDevices: deviceIds,
      }
      const cookieContent = JSON.stringify(cookieData)

      gpsReportsSession.initializeGpsReports(btoa(cookieContent))
    },
    [territoryId],
  )

  return {
    gpsReportsLink,
    createGpsReportsCookie,
  }
}
