import { setCrudSelection, setEditingState } from '@/atoms'
import { getRouterNavigator } from '@/routing'
import { gis } from '@/server-data'

import { fitMap } from '../../../../atoms/map/utils/fitMap'
import { getDomainMarkerData } from '../../../../atoms/markerChangelog/domain/getDomainMarkerData'

export function doubleClickRegions(regionId?: string) {
  if (!regionId) return

  const { mapMarkers } = getDomainMarkerData()

  const region = mapMarkers.region[regionId]

  if (!region) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`Trying to double click a non-existent Region with ID: ${regionId}`)
    }

    return
  }

  const latLngCoordinates = region.points.map(gis.fromCoordinateToLatLng)

  // Set the new selection for both selectionContexts
  setCrudSelection('regions', [regionId])

  // queue a pending fitMap request
  // it will be applied by the destination view when it opens
  fitMap(latLngCoordinates, { preventIfVisible: true }, 'add')

  // Navigate to the target page
  setEditingState({ editing: true, ctx: 'region', editingEntities: [regionId] })
  const navigate = getRouterNavigator()
  navigate('/setup/regions')
}
