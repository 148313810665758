import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, resetCrudSelection, useEditingStateWithCtx, useIsLoading } from '@/atoms'
import { selectDriversListStructure } from '@/features/domain/lists'

export const useDataForList = () => {
  const { setEditing } = useEditingStateWithCtx('driver')

  const structure = useSelector(selectDriversListStructure)
  const [selection, select] = useCrudSelection('drivers')
  const isLoading = useIsLoading('fetchGpsData')

  const lastSelectedDriver = selection.length > 0 ? selection[selection.length - 1] : undefined

  const allEntitiesSelected = structure.shownItems <= selection.length

  const handleDeselectAll = useCallback(() => {
    resetCrudSelection('drivers')
  }, [])

  const setCreate = useCallback(() => {
    resetCrudSelection('drivers')
    setEditing([...selection])
  }, [setEditing, selection])

  const toggleSelection = useCallback(() => {
    // TS can't infer string[] with a simple `filter`
    const ids = structure.list.reduce<string[]>((acc, item) => {
      if (typeof item === 'string') {
        acc.push(item)
      }
      return acc
    }, [])

    const allSelected = ids.length <= selection.length
    if (allSelected) {
      resetCrudSelection('drivers')
    } else {
      select(ids)
    }
  }, [select, selection, structure])

  return {
    isLoading,
    structure,
    setCreate,
    setEditing,
    lastSelectedDriver,
    selectDrivers: select,
    deselectAll: handleDeselectAll,
    allEntitiesSelected,
    toggleSelection,
  }
}
