import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

type Props = {
  onSubmit: () => void
}

export function Footer(props: Props) {
  const { onSubmit } = props

  const texts = useTexts()

  const { close, status } = useController()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          disabled={status !== 'ready'}
          loading={status !== 'ready'}
          onClick={onSubmit}
          color="error"
          testid="modal__button-delete"
        >
          {texts.deleteButtonTitle}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton variant="contained" onClick={close} disabled={status !== 'ready'}>
          {texts.cancelButtonTitle}
        </ModalFooterButton>
      }
    />
  )
}
