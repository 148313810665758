import type { SelectionOptions, SelectionModifiers } from './typings'

export const parseModifier = (modifiers?: SelectionOptions['modifiers']): SelectionModifiers => {
  const { altKey, ctrlKey, metaKey, shiftKey } = modifiers ?? {}

  const additive = !altKey && (ctrlKey || metaKey || shiftKey)
  const subtractive = altKey && !(ctrlKey || metaKey || shiftKey)
  const noModifiers = !additive && !subtractive

  return {
    noModifiers,
    additive: !!additive,
    subtractive: !!subtractive,
  }
}
