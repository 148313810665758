import type Feature from 'ol/Feature'

import CircleStyle from 'ol/style/Circle'
import { Fill, Stroke, Style } from 'ol/style'

import Point from 'ol/geom/Point'
import Polygon from 'ol/geom/Polygon'
import Geometry from 'ol/geom/Geometry'

import { drawFeatureMetadata } from '../../utils/drawFeatureMetadata'
import { drawStyleCommon } from './common'

const common = drawStyleCommon()

export const selectedPoint = new Style({
  image: new CircleStyle({
    radius: common.pointRadius,
    fill: new Fill({
      color: common.blue(1),
    }),
    stroke: new Stroke({
      color: common.white(),
      width: common.pointBorderWidth,
    }),
  }),
  zIndex: Infinity,
  geometry: (feature: Feature<Geometry>) => {
    const invalid = drawFeatureMetadata.isInvalid(feature)
    if (invalid) return

    // return the coordinates of the first ring of the polygon
    const geometry = feature?.getGeometry()
    if (geometry instanceof Geometry && geometry.getType() === 'Polygon') {
      const activeIndex = drawFeatureMetadata.getActiveIndex(feature)

      if (activeIndex > -1) {
        const poly = geometry as Polygon
        const activePoint = poly.getCoordinates().at(0)?.at(activeIndex)
        if (activePoint) return new Point(activePoint)
      }
    }
  },
})
