import { useEffect, useRef } from 'react'
import { useController } from './useController'

/**
 * Automatically update the territory name when the user selects a new file. If the user updated the
 * territory name manually, this hooks does nothing in order to preserve the user choice.
 * Please note: if the user changes the name then is restore the original one, this hook works treats
 * the name as "never changed".
 */
export function useUpdateNameOnFileChange() {
  const {
    updateData,
    data: { importedTerritory, name },
  } = useController()

  const api = useRef({
    name,
    previousImportName: '',
    updateData,
  })
  useEffect(() => {
    api.current.name = name
    api.current.updateData = updateData
  }, [updateData, name])

  useEffect(() => {
    const { name, previousImportName, updateData } = api.current

    const importingName = importedTerritory?.territory.name ?? ''

    // store if the name changed compared to the previous import (1st step)
    const nameChanged = previousImportName !== name

    // update the name to the latest imported territory (2nd step)
    // Please note: the order matters! This step must be performed before short-circuiting the flow
    // because it must store the name of the latest imported file, not the latest name automatically set
    api.current.previousImportName = importingName

    // the name must not be automatically set if the user manually changed the name (3rd step)
    if (nameChanged) return

    // update the territory name (4th step)
    updateData({ name: importingName })
  }, [importedTerritory])
}
