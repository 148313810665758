import { useCallback, useState } from 'react'

import { setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'showRoutePaths' | 'hideRoutePaths'

export const useRoutePathActions = (ids: string[]) => {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const showRoutePaths = useCallback(async () => {
    setMethodInExecution('showRoutePaths')

    await dispatch(
      setCustomMapStyle({
        customStyle: { type: 'routePolyline', mode: 'on', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const hideRoutePaths = useCallback(async () => {
    setMethodInExecution('hideRoutePaths')

    await dispatch(
      setCustomMapStyle({
        customStyle: { type: 'routePolyline', mode: 'off', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  return { methodInExecution, actions: { showRoutePaths, hideRoutePaths } }
}
