import { useRef, useCallback, useMemo } from 'react'
import { FormControlLabel, MenuItem, Radio, Typography } from '@mui/material'

import { naturalSort } from '@/server-data'
import {
  ListHeaderFilterMenu,
  ListHeaderFilterInput,
  ListHeaderFilterContainer,
  type ListHeaderSecondaryActionRef,
} from '@/components/List'

import { useCreateAlertLabel } from '../../../../hooks/useCreateAlertLabel'
import { useTexts } from '../../useTexts'

import { allEventTypeIds } from './hooks/useFilterList'
import { useFilter } from './hooks/useFilter'

export function Filter() {
  const { query, filter, setFilter, setQuery, editing, noEntries } = useFilter()

  const createAlertLabel = useCreateAlertLabel()
  const texts = useTexts()

  const filterMenuRef = useRef<ListHeaderSecondaryActionRef>(null)
  const filterActive = useMemo(() => filter.eventTypeId !== 'all', [filter])

  const onClearAllClick = useCallback(() => {
    setFilter({ eventTypeId: 'all' })
    filterMenuRef.current?.close()
  }, [setFilter])

  const filterOptions = useMemo(
    () =>
      allEventTypeIds
        .map(id => ({ id, label: createAlertLabel(id) }))
        .sort((a, b) => naturalSort(a.label, b.label)),
    [createAlertLabel],
  )

  const disabled = editing || noEntries

  return (
    <ListHeaderFilterContainer>
      <ListHeaderFilterInput
        placeholder={texts.filterPlaceholder}
        disabled={disabled}
        setQuery={setQuery}
        testId="list-header-filter-input"
        query={query}
      />

      <ListHeaderFilterMenu filterActive={filterActive} ref={filterMenuRef} disabled={disabled}>
        <MenuItem
          data-testid="list-header-filter-filterOption-type-all"
          data-trackid="list-header-filter-filterOption-type-all"
          onClick={onClearAllClick}
        >
          <FormControlLabel
            label={texts.any}
            control={
              <Radio
                size="small"
                value="all"
                checked={filter.eventTypeId === 'all'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        {filterOptions.map(({ id, label }) => (
          <MenuItem
            key={id}
            onClick={() => setFilter({ eventTypeId: id })}
            data-testid={`list-header-filter-filterOption-eventId-${id}`}
            data-trackid={`list-header-filter-filterOption-eventId-${id}`}
          >
            <FormControlLabel
              label={label}
              control={
                <Radio
                  size="small"
                  value={id}
                  checked={filter.eventTypeId !== 'all' && filter.eventTypeId === id}
                  readOnly
                  disableRipple
                />
              }
            />
          </MenuItem>
        ))}

        {filterActive && (
          <MenuItem
            onClick={onClearAllClick}
            data-testid="list-header-filter-filterOption-clear"
            data-trackid="list-header-filter-filterOption-clear"
          >
            <Typography fontSize={15} color="primary">
              {texts.clearAll}
            </Typography>
          </MenuItem>
        )}
      </ListHeaderFilterMenu>
    </ListHeaderFilterContainer>
  )
}
