import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'
import LineString from 'ol/geom/LineString'

import { setBreadcrumbFeatureMetadata } from '../../breadcrumbFeatureMetadata'
import { getBreadcrumbPathFeatureStyle } from './getBreadcrumbPathFeatureStyle'

export const updateBreadcrumbPathFeature = (
  mapStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
  marker: uui.domain.ui.map.markers.Breadcrumb,
  feature: Feature<Geometry>,
): Feature<Geometry> => {
  // ------------------------
  // Feature Geometry
  // ------------------------
  let line: number[][] = []

  // Filter the line coordinates by given interval
  if (marker.fetched) {
    switch (marker.lineVisibleRange) {
      case 'all':
        line = marker.line
        break

      case 'nothing':
        line = []
        break

      default:
        line = marker.line.slice(
          marker.lineVisibleRange.start,
          marker.lineVisibleRange.end === -1 ? undefined : marker.lineVisibleRange.end,
        )
        break
    }
  }

  feature.setGeometry(new LineString(line))

  // ------------------------
  // Feature Metadata
  // ------------------------

  const mode: uui.domain.ui.map.markers.MapStyles['breadcrumb']['mode'] =
    marker.mode ?? mapStyles.mode

  setBreadcrumbFeatureMetadata(feature, 'mode', mode)
  setBreadcrumbFeatureMetadata(feature, 'type', 'breadcrumbPath')

  // ------------------------
  // Feature Style
  // ------------------------

  const style = getBreadcrumbPathFeatureStyle(marker, mode)
  feature.setStyle(style)

  return feature
}
