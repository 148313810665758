import { memo, useCallback } from 'react'
import { useTheme } from '@mui/material'
import { Timeline, DirectionsCar } from '@mui/icons-material'

import {
  ListItem,
  ListItemAvatarRounded,
  ListItemContent,
  ListItemRow,
  ListItemTitle,
} from '@/components/List'
import { type RenderItemProps } from '@/components/StructuredVirtualList'

import { FormatDateTime } from '@/components/smartUtils/time/FormatDateTime'

import { useEventName } from '../../../../hooks/useEventName'
import { useDeviceLabel } from '../../../../hooks/useDeviceLabel'
import { EventInfoIcon } from './EventInfoIcon'

type Props = Extract<RenderItemProps<'eventInfos'>, { type: 'item' }>

export const EventInfoItem = memo((props: Props) => {
  const { selected, item, onItemClick, onItemDoubleClick } = props
  const theme = useTheme()

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])

  const name = useEventName(item.type)
  const deviceLabel = useDeviceLabel(item.deviceId)

  return (
    <ListItem
      onClick={onClick}
      selected={selected}
      onDoubleClick={onDoubleClick}
      avatar={
        <ListItemAvatarRounded
          color={theme.palette.action.active}
          textColor={theme.palette.common.white}
        >
          <EventInfoIcon eventType={item.type} size={28} />
        </ListItemAvatarRounded>
      }
    >
      <ListItemTitle>{name}</ListItemTitle>

      <ListItemRow testId="event-item-date">
        <ListItemContent icon={<Timeline />}>
          <FormatDateTime source={new Date(item.ts)} />
        </ListItemContent>
      </ListItemRow>

      <ListItemRow testId="event-item-vehicle">
        <ListItemContent icon={<DirectionsCar />}>{deviceLabel}</ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})

EventInfoItem.displayName = 'EventInfoItem'
