import type { PartialStoreState } from '../typings'

export const selectTerritories = (state: PartialStoreState) => state.domain.publicData.territories
export const selectTerritory = (state: PartialStoreState) => state.domain.publicData.territory
export const selectTerritoryId = (state: PartialStoreState) => state.domain.publicData.territory.id
export const selectTerritoryCountry = (state: PartialStoreState) =>
  state.domain.publicData.territory.country
export const selectTerritoryCenter = (state: PartialStoreState) =>
  state.domain.publicData.territory.center

export const selectTerritoryLicensingLimits = (state: PartialStoreState) =>
  state.domain.publicData.territory.licensingLimits

export const selectReasonsForNotCompletedOrders = (state: PartialStoreState) =>
  state.domain.publicData.territory.statusReasons

export const selectMobileAppInteraction = (state: PartialStoreState) =>
  state.domain.publicData.territory.mobileAppInteraction

export const selectMaxReasonsForNotCompletedOrders = (state: PartialStoreState) =>
  state.domain.publicData.territory.licensingLimits.maxStatusReasonsCount

export const selectTerritoryStartFromDate = (state: PartialStoreState) =>
  state.domain.publicData.territory.startFromDate

export const selectWorkingDayStartSec = (state: PartialStoreState) =>
  state.domain.publicData.territory.workingDayStartSec

export const selectLiveEta = (state: PartialStoreState) =>
  (state.domain.publicData.territory.rescheduleEdgeLocation ?? 'ROUTESTEP') === 'GPS'

export const selectRescheduleBehavior = (state: PartialStoreState) =>
  state.domain.publicData.territory.rescheduleBehavior

export const selectCustomerNotificationsSettings = (state: PartialStoreState) =>
  state.domain.publicData.territory.notificationConfig

export const selectVoiceConfig = (state: PartialStoreState) =>
  state.domain.publicData.territory.voiceConfig

export const selectVoiceLanguageOptions = (state: PartialStoreState) =>
  state.domain.publicData.territory.voiceLanguageOptions

export const selectTerritoryNotificationBehavior = (state: PartialStoreState) =>
  state.domain.publicData.territory.notificationBehavior

export const selectNotificationConfig = (state: PartialStoreState) =>
  state.domain.publicData.territory.notificationConfig

export const selectNotificationPlanDetails = (state: PartialStoreState) =>
  state.domain.publicData.territory.notificationConfig.planDetails

export const selectEnableDynamicLoads = (state: PartialStoreState) =>
  state.domain.publicData.territory.enableDynamicLoads

export const selectEnableDynamicCustomFields = (state: PartialStoreState) =>
  state.domain.publicData.territory.enableDynamicCustomFields

export const selectNotificationPreferences = (state: PartialStoreState) => {
  const { companyFromName, dateFormat, timeFormat, notificationLanguage } =
    state.domain.publicData.territory.notificationConfig

  return {
    companyFromName,
    dateFormat,
    timeFormat,
    notificationLanguage,
  }
}

export const selectNotificationSupportedLanguages = (state: PartialStoreState) =>
  state.domain.publicData.territory.notificationConfig.supportedLanguages

export const selectNotificationConfigCompanyDetails = (state: PartialStoreState) => {
  const {
    companyEmail,
    companyLogoToken,
    companyName,
    companyPhone,
    companySlogan,
    showContactEmail,
    showContactPhone,
    testURL,
    notificationLanguage,
    showAudioPod,
    showEtaRange,
    showNotePod,
    showPicturePod,
    showSignaturePod,
    useCompanyEmailAsReplyTo,
  } = state.domain.publicData.territory.notificationConfig

  return {
    companyEmail,
    companyLogoToken,
    companyName,
    companyPhone,
    companySlogan,
    showContactEmail,
    showContactPhone,
    testURL,
    notificationLanguage,
    showAudioPod,
    showEtaRange,
    showNotePod,
    showPicturePod,
    showSignaturePod,
    useCompanyEmailAsReplyTo,
  }
}
