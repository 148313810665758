import type {
  Days,
  DayProps,
  WeeklySelectionAsRecord,
  SelectionAsRecord,
  WeeklyValidSelection,
} from '../../typings'

import { useMemo } from 'react'
import { addDays, eachDayOfInterval, format, getDay } from 'date-fns/esm'

import { useDateFnsOptions } from '@/hooks'

import { matchHighlightProducer } from './matchHighlightProducer'

type Props = {
  selection?: WeeklySelectionAsRecord
  highlightedDays?: SelectionAsRecord
}

const sunday = new Date(1970, 1, 1)

export function useWeeklyDays(props: Props): Days {
  const options = useDateFnsOptions()
  const { highlightedDays = {}, selection } = props

  return useMemo<Days>(() => {
    const start = addDays(sunday, options.weekStartsOn)
    const end = addDays(start, 6)

    const dates = eachDayOfInterval({
      start,
      end,
    })

    return dates.map<DayProps>((date, index) => {
      const label = format(date, 'EEEEEE', options)
      const id = format(date, 'yyyyMMdd')
      const dayNumber = getDay(date) as WeeklyValidSelection

      const selected = !!selection && selection.hasOwnProperty(dayNumber)
      const cosmetic = selected ? 'edge-of-selection' : 'normal'
      const firstOfRow = index % 7 === 0

      return {
        id,
        date,
        label,
        dot: matchHighlightProducer(highlightedDays)(dayNumber),
        cosmetic,
        firstOfRow,
        type: 'day',
        isToday: false,
      }
    })
  }, [options, highlightedDays, selection])
}
