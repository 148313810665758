import type { ReactElement } from 'react'
import type { Theme } from '@mui/material'

import { makeStyles } from '@mui/styles'

interface Props {
  children: ReactElement | ReactElement[]
  spacing?: number
  name: string
}

const useStyles = makeStyles<Theme, { spacing?: number }>(muiTheme => ({
  container: props => ({
    marginBottom: muiTheme.spacing(props.spacing ?? 4),
  }),
}))

export function FieldBox(props: Props) {
  const classes = useStyles({ spacing: props.spacing })

  return (
    <div className={classes.container} data-testid={props.name}>
      {props.children}
    </div>
  )
}
