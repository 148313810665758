import { type ReactNode } from 'react'
import { Stack } from '@mui/material'

type Props = {
  testId?: string
  children?: ReactNode
}

export function ListHeaderActions(props: Props) {
  const { children, testId = 'list-header-actions' } = props

  return (
    <Stack
      data-trackid={testId}
      data-testid={testId}
      useFlexGap
      direction="row"
      flexShrink={0}
      gap={0.5}
    >
      {children}
    </Stack>
  )
}
