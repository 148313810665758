import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    confirmPanelTitle: (count: number) =>
      translate({
        id: 'gps.places.view.delete.title',
        values: { selected: count },
      }),
    confirmPanelSubtitle: translate({ id: 'gps.places.view.delete.subtitle' }),
    delete: translate({ id: 'global.deleteAll' }),
    export: translate({ id: 'global.exportAll' }),
  }))

  return api
}
