import type { PlaceFormValues } from '../types'

export function parsePlaceFormValues(values: PlaceFormValues): uui.domain.client.gps.wwgps.Place {
  const { name, location, ...rest } = values
  const {
    geoAddress,
    latLng: { lat, lng },
  } = location

  return {
    ...rest,
    name,
    location,
    latLng: location.latLng,
    latitude: lat,
    longitude: lng,
    address: geoAddress,
    city: '',
    state: '',
    zipCode: '',
  }
}
