import { PureComponent } from 'react'

import {
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  ReadonlyTimeWindow,
} from '@/forms-legacy'
import * as OrderTexts from '../../../intl'

import * as Texts from '../intl'
import { Props, FieldMode } from '../typings'

import ViolationIcon from './ViolationIcon'

export default class TimeWindows extends PureComponent<Props> {
  render() {
    const { timeWindows, violations } = this.props

    const timeWindowViolation: boolean = violations.includes('TW')

    return (
      <>
        <ReadonlyBlock half>
          <ReadonlyLabel primary empty={timeWindows.length === 0}>
            {Texts.getTimeWindowsText()}
            {timeWindowViolation && <ViolationIcon />}
          </ReadonlyLabel>

          {timeWindows === FieldMode.mixed ? (
            <ReadonlyField>
              <ReadonlyFieldRow>
                <ReadonlyFieldLabel>{OrderTexts.getMixedText()}</ReadonlyFieldLabel>
              </ReadonlyFieldRow>
            </ReadonlyField>
          ) : (
            <>
              {timeWindows[0] && <ReadonlyTimeWindow timeWindow={timeWindows[0]} />}
              {timeWindows[1] && <ReadonlyTimeWindow timeWindow={timeWindows[1]} />}
            </>
          )}
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
