import { snapshot } from 'valtio'

import { editableArea } from '../editableArea'

import { removeEditableArea } from './removeEditableArea'

export type UpdateEditableArea = (
  prev: workwave.DeepReadonly<uui.domain.ui.map.EditableArea>,
) => uui.domain.ui.map.EditableArea

export type UpdateEditableAreaParam =
  | UpdateEditableArea
  | Partial<uui.domain.ui.map.EditableArea>
  | 'reset'

export function updateEditableArea(valueOrFunc: UpdateEditableAreaParam) {
  // reset
  if (valueOrFunc === 'reset') return removeEditableArea()
  if (valueOrFunc === undefined) return removeEditableArea()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    editableArea.area = valueOrFunc(snapshot(editableArea.area))
  } else if (valueOrFunc) {
    Object.assign(editableArea.area, valueOrFunc)
  }

  return snapshot(editableArea.area)
}
