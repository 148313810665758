import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { parse, addDays, isBefore, isWithinInterval, eachDayOfInterval } from 'date-fns/esm'

import { useCanAddNewOrder, useIsSimulation, useOrdersLimit, useReadOnly } from '@/hooks'
import { selectLicensingLimits, selectUserConfiguration } from '@/features/domain/user'
import { selectOrderStepIdsInRange } from '@/features/domain/order'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectTerritory } from '@/features/domain/territory'
import { timeUtils } from '@/server-data'

import { useTexts } from '../useTexts'

const enabled = { disabled: false, tooltip: { title: '', subtitle: '' } }

export function useItemsPermissions() {
  const orderStepIdsInRange = useSelector(selectOrderStepIdsInRange)
  const userConfiguration = useSelector(selectUserConfiguration)
  const { privileges } = useSelector(selectLicensingLimits)
  const canAddNewOrder = useCanAddNewOrder()
  const { start, end } = useSelector(selectCalendarRangeInterval)
  const isSimulation = useIsSimulation()
  const ordersLimit = useOrdersLimit()
  const { cause } = useReadOnly()
  const texts = useTexts()

  const {
    licensingLimits: { horizonOpt },
  } = useSelector(selectTerritory)

  return useMemo(() => {
    const permissions = {
      copyToOperationsPermissions: enabled,
      dispatchRoutesPermissions: enabled,
      buildRoutesPermissions: {
        ...enabled,
        tooltip: { ...enabled.tooltip, forceState: undefined },
      },
      addOrderPermissions: enabled,
    }

    // Check Add orders permissions
    permissions.addOrderPermissions = {
      disabled: !canAddNewOrder,
      tooltip: {
        subtitle: texts.addOrderDisabledTooltipSubtitle(isSimulation, ordersLimit),
        title: texts.addOrderDisabledTooltipTitle(isSimulation),
      },
    }

    // Check Build Routes Permissions
    // const startOfTodayDate = parseISO(userConfiguration.startOfToday)
    const startOfTodayDate = parse(userConfiguration.today, 'yyyyMMdd', new Date())
    const calendarRangeInterval = eachDayOfInterval({ start, end })
    const startInPast = isBefore(start, startOfTodayDate)
    const endInPast = isBefore(end, startOfTodayDate)
    let forceState: 'enabled' | undefined
    let disabled: boolean = false
    let title: string = ''
    let subtitle: string = ''

    const privilegesSet = new Set<uui.domain.server.rm.UserPrivilege>([...privileges])
    // If the users
    if ((startInPast || endInPast) && !privilegesSet.has('DEMO') && !privilegesSet.has('DEV')) {
      const fullPastOptimization = startInPast && endInPast
      forceState = fullPastOptimization ? 'enabled' : undefined

      disabled = fullPastOptimization
      title = fullPastOptimization
        ? texts.fullPastOptimizationTitle
        : texts.partialPastOptimizationTitle
      subtitle = fullPastOptimization
        ? texts.fullPastOptimizationSubtitle
        : texts.partialPastOptimizationSubtitle
    } else {
      if (calendarRangeInterval.length > horizonOpt) {
        // If the user selected an interval bigger than horizonOpt
        disabled = true
        title = texts.overMaxOptimizableDaysTitle(horizonOpt)
      } else {
        const optIntervalStart = parse(userConfiguration.startFromDate, 'yyyyMMdd', new Date())
        const optIntervalEnd = addDays(startOfTodayDate, horizonOpt)
        const optInterval = { start: optIntervalStart, end: optIntervalEnd }

        disabled = calendarRangeInterval.some(d => !isWithinInterval(d, optInterval))
        title = texts.overOptimizationHorizonTitle
        subtitle = texts.overOptimizationHorizonSubtitle(
          timeUtils.formatDate(userConfiguration)(optIntervalStart),
          timeUtils.formatDate(userConfiguration)(optIntervalEnd),
        )
      }
    }

    permissions.buildRoutesPermissions = {
      disabled,
      tooltip: {
        title,
        subtitle,
        //@ts-expect-error
        forceState,
      },
    }

    // Check Copy to Operations Permissions

    const gotOrdersToCopy = Object.keys(orderStepIdsInRange).length > 0
    const isReadOnlyUser = cause === 'courier' || cause === 'rmViewer' || cause === 'rmGuest'

    permissions.copyToOperationsPermissions = {
      disabled: isReadOnlyUser || !gotOrdersToCopy,
      tooltip: {
        subtitle: isReadOnlyUser ? '' : texts.emptyArchivedDaySubtitle,
        title: isReadOnlyUser ? texts.viewerUserTitle : texts.emptyArchivedDayTitle,
      },
    }

    return permissions
  }, [
    orderStepIdsInRange,
    userConfiguration,
    canAddNewOrder,
    isSimulation,
    ordersLimit,
    horizonOpt,
    privileges,
    cause,
    start,
    texts,
    end,
  ])
}
