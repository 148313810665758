import { useMemo } from 'react'

type DriversValues = {
  mobileFutureDaysVisibility: uui.domain.client.rm.MobileFutureDaysVisibility | 'mixed'
  summarizeFutureMobileRoute: boolean | 'mixed'
}

export function useDriversValues(drivers: uui.domain.client.rm.ExtendedDriver[]) {
  return useMemo<DriversValues>(() => {
    const firstDriver = drivers[0].driver
    const allMobileFutureDaysVisibilityEquals = drivers.every(
      d => d.driver.mobileFutureDaysVisibility === firstDriver.mobileFutureDaysVisibility,
    )
    const allSummarizeFutureMobileRoute = drivers.every(
      d => d.driver.summarizeFutureMobileRoute === firstDriver.summarizeFutureMobileRoute,
    )

    return {
      mobileFutureDaysVisibility: allMobileFutureDaysVisibilityEquals
        ? firstDriver.mobileFutureDaysVisibility
        : 'mixed',
      summarizeFutureMobileRoute: allSummarizeFutureMobileRoute
        ? firstDriver.summarizeFutureMobileRoute
        : 'mixed',
    }
  }, [drivers])
}
