import type { FormConfig } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'
import type { ValidationTexts } from '../../useTexts'

import { createValidateForm } from './createValidateForm'

export function createValidations(
  isToday: boolean,
  transitions: uui.domain.TimezoneTransition[],
  texts: ValidationTexts,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'formValidation',
      validator: createValidateForm(isToday, transitions, texts),
    },
  ]
}
