import { Box, Button, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useTexts } from './hooks/useTexts'

type Props = {
  disableSubmit: boolean
  submitting: boolean
  onDiscard: () => void
  onSubmit: () => void
}

export function DriverActivityFooter(props: Props) {
  const { onSubmit, onDiscard, disableSubmit, submitting } = props
  const texts = useTexts()

  const theme = useTheme()

  return (
    <Box
      gap={theme.spacing(2)}
      display="flex"
      component="footer"
      paddingTop={4}
      paddingLeft={4}
      paddingRight={4}
      flexDirection="row"
      justifyContent="flex-start"
    >
      <LoadingButton
        size="medium"
        type="submit"
        color="primary"
        variant="contained"
        onClick={onSubmit}
        loading={submitting}
        disabled={disableSubmit}
        data-trackid="driver-activity-edit-save-button"
        data-testid="driver-activity-edit-save-button"
      >
        {texts.save}
      </LoadingButton>

      <Button
        type="button"
        size="medium"
        color="primary"
        onClick={onDiscard}
        variant="text"
        data-trackid="driver-activity-edit-cancel-button"
        data-testid="driver-activity-edit-cancel-button"
      >
        {texts.cancel}
      </Button>
    </Box>
  )
}
