import { ModalFooter, ModalFooterButton } from '@/components/Modal'
import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const actions = useControllerActions()
  const { status, invalid, close } = useController()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          disabled={invalid || status !== 'ready'}
          onClick={actions.onDeleteTerritory}
          variant="contained"
          color="error"
        >
          {texts.commandButtonTitle}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton onClick={close}>{texts.cancelButtonTitle}</ModalFooterButton>
      }
    />
  )
}
