import type {
  FormField,
  FormError,
  FormValues as FormValuesType,
} from '@workwave-tidal/tidal/form-fairy'

const companyNameField: FormField<string> = {
  value: '',
  required: false,
}

const companySloganField: FormField<string> = {
  value: '',
  required: false,
}

const companyPhoneNumber: FormField<string> = {
  value: '',
  required: false,
}

const companyEmailField: FormField<string> = {
  value: '',
  required: false,
}

const checkboxField: FormField<boolean> = {
  value: false,
  required: false,
}

export const formFields = {
  companyName: companyNameField,
  companySlogan: companySloganField,
  companyPhone: companyPhoneNumber,
  showContactPhone: checkboxField,
  companyEmail: companyEmailField,
  showContactEmail: checkboxField,
  useCompanyEmailAsReplyTo: checkboxField,
  showEtaRange: checkboxField,
  showPicturePod: checkboxField,
  showSignaturePod: checkboxField,
  showAudioPod: checkboxField,
  showNotePod: checkboxField,
}

export type FormFields = typeof formFields
export type FormValues = FormValuesType<FormFields>
export type FormErrors = FormError<keyof FormFields>
