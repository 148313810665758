import OlMap from 'ol/Map'
import { Extent } from 'ol/extent'
import Geometry from 'ol/geom/Geometry'

import { createNonBlockingRenderByTime } from '../../../../renderingQueue'
import { getOrderFeatureMetadata } from '../../../../layers/order/orderFeatureMetadata'
import { findLayer } from '../../../../layers/utils/findLayer'

const nonblockingRender = createNonBlockingRenderByTime()

export async function selectOrderFeatureInExtent(
  map: OlMap,
  lassoPolygon: Geometry,
  lassoExtent: Extent,
) {
  const orderLayer = findLayer(map, 'order')
  const selectedIds: string[] = []

  const featuresInExtent = orderLayer.getSource()?.getFeaturesInExtent(lassoExtent) ?? []

  nonblockingRender.reset()

  for (const feature of featuresInExtent) {
    await nonblockingRender.next()

    const mode = getOrderFeatureMetadata(feature, 'mode')

    if (mode === 'off') continue

    const idsRecord = getOrderFeatureMetadata(feature, 'ids')

    if (idsRecord) {
      const ids = Object.keys(idsRecord)

      if (ids.length > 0) {
        const extent = feature.getGeometry()?.getExtent()

        if (extent && lassoPolygon.intersectsExtent(extent)) {
          selectedIds.push(...ids)
        }
      }
    }
  }

  return selectedIds
}
