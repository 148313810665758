import { type DetailedHTMLProps, type ButtonHTMLAttributes } from 'react'
import { clsx } from '@/utils'

type BaseButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

type Props = BaseButtonProps

export function Link(props: Props) {
  const { children, className, ...rest } = props

  return (
    <button className={clsx(className, 'o-scheduler-link')} {...rest}>
      {children}
    </button>
  )
}
