import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    breakTitle: translate({ id: 'v3.scheduler.dragTooltip.break.title' }),
  }))

  return api
}
