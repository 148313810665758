import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { type Option } from '@/components/BulkSelect'

import { selectCompaniesAsArray } from '@/features/domain/company'

import { NONE_OPTION } from '../../../../../constants'

import { useTexts } from './useTexts'

export function useOptions() {
  const companies = useSelector(selectCompaniesAsArray)
  const texts = useTexts()

  return useMemo(() => {
    const noneOption = { value: NONE_OPTION, label: texts.none }
    return [noneOption, ...companies.map(mapCompanyToDropdownItem)]
  }, [companies, texts])
}

function mapCompanyToDropdownItem(company: uui.domain.client.rm.Company): Option<string> {
  return {
    value: company.id,
    label: company.name,
    disabled: !company.enabled,
  }
}
