import { CircularProgress } from '@mui/material'

import { StrikedEye } from '@/icons'
import { useMatchMapSelectionContext } from '@/map'
import { NestedDropdown, DropdownMenuItem } from '@/components/DropdownMenu'

import { ActiveIcon } from '../ActiveIcon'

import { useRegionsMapStyle } from './hooks/useRegionsMapStyle'
import { useHasRegions } from './hooks/useHasRegions'
import { useActions } from './hooks/useActions'
import { useTexts } from './useTexts'

interface Props {
  parentOpen: boolean
}

export function RegionsSubMenu(props: Props) {
  const { parentOpen } = props

  const {
    methodInExecution,
    actions: { showRegions, hideRegions },
  } = useActions()
  const activeStyle = useRegionsMapStyle()
  const hasRegions = useHasRegions()
  const texts = useTexts()

  const menuDisabled = useMatchMapSelectionContext('crud', 'regions')

  const tooltipTitle = menuDisabled
    ? texts.disabledInRegionSection
    : hasRegions === true
    ? undefined
    : texts.noRegions

  return (
    <NestedDropdown
      placement="left"
      disabled={menuDisabled || !hasRegions}
      parentOpen={parentOpen}
      tooltipTitle={tooltipTitle}
      triggerContent={texts.regions}
      Icon={
        hasRegions && activeStyle === 'off' ? <StrikedEye size={12} color="$silver" /> : undefined
      }
    >
      <DropdownMenuItem
        external
        onClick={showRegions}
        Icon={
          methodInExecution === 'showRegions' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'on'} />
          )
        }
      >
        {texts.on}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={hideRegions}
        Icon={
          methodInExecution === 'hideRegions' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'off'} />
          )
        }
      >
        {texts.off}
      </DropdownMenuItem>
    </NestedDropdown>
  )
}
