import { getInspectPin } from './core/getInspectPin'
import { removeInspectPin as clearInspectPin } from './core/removeInspectPin'

function handleKeyboardEvents(event: KeyboardEvent) {
  const pin = getInspectPin(true)

  if (!pin) return

  // Abort on pressing ESC
  if (
    event.key === 'Escape' ||
    // IE/Edge specific value
    event.key === 'Esc'
  ) {
    event.stopPropagation()
    clearInspectPin()
  }
}

export function subscribeToKeyboardEventToRemoveInspectPin() {
  window.addEventListener('keydown', handleKeyboardEvents)

  return () => {
    window.removeEventListener('keydown', handleKeyboardEvents)
  }
}
