import { Stack, Typography } from '@mui/material'

import { ViewField } from '@/formUi'

import { useTexts } from '../../hooks/useTexts'

interface Props {
  mobileFutureDaysVisibility: uui.domain.client.rm.MobileFutureDaysVisibility | 'mixed'
  summarizeFutureMobileRoute: boolean | 'mixed'
}

export function DriverApp(props: Props) {
  const { mobileFutureDaysVisibility, summarizeFutureMobileRoute } = props
  const texts = useTexts()

  return (
    <Stack data-testid="DriverApp">
      <Typography variant="subtitle3" pb={3}>
        {texts.driverAppTitle}
      </Typography>

      <ViewField label={texts.labels.mobileFutureDaysVisibility}>
        {texts.values.mobileFutureDaysVisibility(mobileFutureDaysVisibility)}
      </ViewField>

      <ViewField label={texts.labels.summarizeFutureMobileRoute}>
        {texts.values.summarizeFutureMobileRoute(summarizeFutureMobileRoute)}
      </ViewField>
    </Stack>
  )
}
