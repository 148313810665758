import { ViewActionButton, ViewActions } from '@/formUi'

import { ChangePlanButton } from '../../../ChangePlanButton'
import { usePhoneNumbersModal } from './modals/PhoneNumbersModal'
import { useTexts } from './useTexts'

export function PlanDetailsViewActions() {
  const { Modal: PhoneNumbersModal, show: showPhoneNumbersModal } = usePhoneNumbersModal()
  const texts = useTexts()

  return (
    <>
      <ViewActions>
        <ChangePlanButton />

        <ViewActionButton
          testId="settings-communication-phone-numbers-button"
          onClick={showPhoneNumbersModal}
          label={texts.phoneNumbers}
        />
      </ViewActions>

      {/* Inject the Modal's Roots */}
      <PhoneNumbersModal />
    </>
  )
}
