import { IconButton } from '@/components/primitives/buttons'
import { Tooltip } from '@/components/primitives/Tooltip'
import { InspectPin } from '@/icons'

import { useStyles } from '../../hooks/useStyles'
import { useTexts } from '../../useTexts'

import { useInspectPinToggle } from './hooks/useInspectPinToggle'

interface Props {
  disabled: boolean
}

export function InspectPinToggle(props: Props) {
  const { inspectPin, toggleInspectPin } = useInspectPinToggle()
  const classes = useStyles()
  const texts = useTexts()

  const active = inspectPin?.mode === 'normal'

  const iconColor = active ? '$scienceBlue' : '$nightRider'

  const testid = 'map-controls-inspect-tool'

  return (
    <Tooltip placement="bottom" title={active ? texts.inspectToolOff : texts.inspectTool}>
      <IconButton
        disabled={props.disabled}
        onClick={toggleInspectPin}
        className={classes.button}
        data-testid={testid}
        data-trackid={testid}
      >
        <InspectPin size={18} color={iconColor} />
      </IconButton>
    </Tooltip>
  )
}
