import { HorizontalLayout } from '@/components/layout'
import { Text } from '@/local/components'
import { Tooltip } from '@/components/primitives/Tooltip'

import { RouteBadge } from './components/RouteBadge'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

export function NameAndRoute(props: Props) {
  const { extOrder } = props

  return (
    <HorizontalLayout alignItems="center" height="auto">
      {extOrder.order.name && (
        <div style={{ overflow: 'hidden' }}>
          <Tooltip title={extOrder.order.name} placement="top">
            <Text size="$l" weight="$semiBold" marginRight={extOrder.isUnassigned ? 0 : 6} ellipsis>
              {extOrder.order.name}
            </Text>
          </Tooltip>
        </div>
      )}

      {!extOrder.isUnassigned && <RouteBadge extOrder={extOrder} />}
    </HorizontalLayout>
  )
}
