export function computeOrdersCount(
  routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable,
  driverId: string,
  executedOrderStepsByDriver: Record<string, string[]>,
) {
  const ordersExecutedByOtherDrivers = Object.entries(executedOrderStepsByDriver).reduce(
    (acc, [executorDriverId, orderStepIds]) => {
      if (driverId === executorDriverId) return acc

      return acc + orderStepIds.length
    },
    0,
  )

  return routeInfo.driverId === driverId
    ? routeInfo.ordersCount - ordersExecutedByOtherDrivers
    : executedOrderStepsByDriver[driverId]?.length ?? 0
}
