import type { FormFields, FormErrors } from '../../formFields'

import { Box, Typography, useTheme } from '@mui/material'

import { useFormMeta } from '@workwave-tidal/tidal/form-fairy'
import { FormHeader, Form } from '@workwave-tidal/tidal/form-ui/layout'

import { useTexts } from './hooks/useTexts'
import { useFormActions } from './hooks/useFormActions'
import { FormFooter } from './components/FormFooter'
import { MobileFutureDaysVisibility } from './fields/MobileFutureDaysVisibility'
import { SummarizeFutureMobileRoute } from './fields/SummarizeFutureMobileRoute'

type Props = {
  drivers: uui.domain.client.rm.ExtendedDriver[]
}

export function EditDrivers(props: Props) {
  const { drivers } = props

  const texts = useTexts()
  const theme = useTheme()

  const { onSubmit, onDiscard, onEnter } = useFormActions(drivers)

  const driversCount = Object.keys(drivers).length

  const {
    meta: { submitting, validating },
  } = useFormMeta<FormFields, FormErrors>()

  return (
    <Box width={360} height="100%" display="flex" paddingY={2}>
      <Form
        fieldsSpacing={4}
        fieldsPadding={`0 ${theme.spacing(4)}`}
        onEnter={onEnter}
        header={<FormHeader title={texts.title(driversCount)} subtitle="" />}
        footer={
          <FormFooter
            texts={texts.footer}
            onSubmit={onSubmit}
            onDiscard={onDiscard}
            submitting={submitting}
            disableSubmit={submitting || validating}
          />
        }
      >
        <Typography variant="subtitle3">{texts.driverAppTitle}</Typography>

        <MobileFutureDaysVisibility />
        <SummarizeFutureMobileRoute />
      </Form>
    </Box>
  )
}
