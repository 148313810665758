import { SentEmailsCounter } from '../SentEmailsCounter'

import { PlanDetailsWarningCard } from './components/PlanDetailsWarningCard'
import { PlanDetailsViewActions } from './components/PlanDetailsViewActions'
import { ConsumptionCounter } from './components/ConsumptionCounter'

interface Props {
  counters: uui.domain.server.CountryNotificationCounters
  subscription: uui.domain.server.SmsSubscription
}

export function PlanDetails(props: Props) {
  const { counters, subscription } = props

  const { emailCountStartTs, emailCount, smsCount, callCredits } = counters

  const {
    expiryTs,
    plan: { smsCount: smsLimit, allowSmsOverage, trial, name },
  } = subscription

  const emailStartDate = new Date(emailCountStartTs)
  const smsPlanExpireDate = new Date(expiryTs)

  return (
    <>
      <PlanDetailsViewActions />

      <ConsumptionCounter
        planName={name}
        smsLimit={smsLimit}
        expireDate={smsPlanExpireDate}
        smsCount={smsCount}
        callCredits={callCredits}
      />

      <SentEmailsCounter startDate={emailStartDate} emailCount={emailCount} />

      <PlanDetailsWarningCard allowSmsOverage={allowSmsOverage} trial={trial} />
    </>
  )
}
