import { DropdownMenuItem } from '@/components/DropdownMenu'

import { useRoutingLayoutMenu } from '../hooks/useRoutingLayoutMenu'
import { useTexts } from '../useTexts'
import { TickIcon } from './TickIcon'
import { Spacer } from './Spacer'

export function MapLayoutItem() {
  const texts = useTexts()
  const { setToMap, layoutMode } = useRoutingLayoutMenu()

  return (
    <DropdownMenuItem
      onClick={setToMap}
      LeftIcon={layoutMode === 'map' ? <TickIcon /> : <Spacer />}
      data-testid="header__layout-menu-map"
    >
      {texts.map}
    </DropdownMenuItem>
  )
}
