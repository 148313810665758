import type { PublicProps as Props } from './typings'
import { PureComponent } from 'react'
import { Field as FinalFormField, FieldRenderProps } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import { LocationEditorField } from './LocationEditorField'

export default class LocationEditorFieldWrapper extends PureComponent<Props> {
  render() {
    const { name, ...props } = this.props

    return (
      <FinalFormField
        {...props}
        name={name}
        isEqual={isDeepEqual}
        render={(formProps: FieldRenderProps<uui.domain.client.Location>) => {
          return <LocationEditorField {...this.props} formProps={formProps} />
        }}
      />
    )
  }
}
