import { parseOrderStepId } from '@/local/server-data/utils/orders'
import { latLngFromJSON } from '@/local/server-data/utils/geo'
import * as orderHelpers from '@/local/server-data/domain/helpers/order'
import * as recurrenceHelpers from '@/local/server-data/utils/recurrences'

import { createLocation } from './location'
import { generateFormattedData } from './formattedData'
import { computeExtendedOrderStepStatus } from './utils/computeExtendedOrderStepStatus'

let uid = 0

const generateEmptyOrderStepTrackingData = () => ({
  timeInSec: -1,
  timeOutSec: -1,
  statusSec: -1,
  autoTimeInSec: -1,
  autoTimeOutSec: -1,
  pods: {},
  statusReason: '',
  latestTrackedLoadSec: -1,
  latestTrackedLoadLatLng: null,
})

export function createOrders(source: uui.domain.server.rm.RoutePlan['orders']) {
  return source.reduce<Record<string, uui.domain.client.rm.Order>>((ordersAcc, order) => {
    ordersAcc[order.id] = createOrder(order)
    return ordersAcc
  }, {})
}

export function createOrder(source: uui.domain.server.rm.Order): uui.domain.client.rm.Order {
  const pickup = source.pickup ? createOrderStep(source.pickup, `${source.id}-p`, 'p') : undefined

  const delivery = source.delivery
    ? createOrderStep(source.delivery, `${source.id}-d`, source.isService ? 's' : 'd')
    : undefined

  uid++

  let type: uui.domain.client.rm.OrderIdentifier = 'p'

  if (pickup && delivery) {
    type = 'pd'
    return Object.assign(source, { uid, type, pickup, delivery })
  } else if (pickup) {
    type = 'p'
    return Object.assign(source, { uid, type, pickup, delivery: undefined })
  } else if (delivery) {
    type = source.isService ? 's' : 'd'
    return Object.assign(source, { uid, type, delivery, pickup: undefined })
  }

  throw new Error('Received an Order without any OrderStep!')
}

export function createOrderStep(
  source: uui.domain.server.rm.OrderStep,
  id: string,
  type: uui.domain.client.rm.OrderStepIdentifier,
): uui.domain.client.rm.OrderStep {
  const depotId = source.depotId
  const location = source.location

  // ATTENTION: required to comply TS 4+ delete operator rules
  const tmp = source as any
  delete tmp.depotId
  delete tmp.location

  if (!depotId && !location) {
    throw new Error(`OrderStep: ${id} is missing both location and depotId fields.`)
  }

  return Object.assign(
    source,
    {
      id,
      type,
      trackingData: createOrderStepTrackingData(
        source.trackingData ?? generateEmptyOrderStepTrackingData(),
        source.barcodes,
      ),
    },

    // ATTENTION: the server guarantees that one between location and depotId it'll be always provided
    !!location
      ? {
          atDepot: false,
          location: createLocation(location),
        }
      : {
          atDepot: true,
          depotId: depotId!,
        },
  )
}

export function createOrderStepFromOrderUpdate(
  source: uui.domain.server.rm.OrderStepUpdate,
  id: string,
  type: uui.domain.client.rm.OrderStepIdentifier,
): uui.domain.client.rm.OrderStep {
  const depotId = source.depotId
  const location = source.location

  if (!depotId && !location) {
    throw new Error(
      `OrderStep: ${id}, created from a Changeset is missing both location and depotId fields.`,
    )
  }

  return {
    id,
    type,

    barcodes: source.barcodes ?? [],

    customFields: source.customFields
      ? Object.entries(source.customFields).reduce((customFieldsAcc, [key, val]) => {
          if (val) customFieldsAcc[key] = val
          return customFieldsAcc
        }, {})
      : {},

    email: source.email ?? undefined,
    phone: source.phone ?? undefined,

    notes: source.notes ?? '',
    serviceTimeSec: source.serviceTimeSec ?? -1,
    tagsIn: source.tagsIn ?? [],
    tagsOut: source.tagsOut ?? [],

    timeWindowExceptions: source.timeWindowExceptions
      ? Object.entries(source.timeWindowExceptions).reduce((twAcc, [key, val]) => {
          if (val) twAcc[key] = val
          return twAcc
        }, {})
      : {},

    timeWindows: source.timeWindows ?? [],

    // ATTENTION: the server guarantees that one between location and depotId it'll be always provided
    ...(!!location
      ? {
          atDepot: false,
          location: createLocation(location),
        }
      : {
          atDepot: true,
          depotId: depotId!,
        }),

    // ATTENTION: we assume that an OrderStep created from a changeset will always have empty trackingData
    trackingData: createOrderStepTrackingData(
      generateEmptyOrderStepTrackingData(),
      source.barcodes,
    ),
  }
}

export function createOrderStepTrackingData(
  source: uui.domain.server.rm.OrderStepTrackingData,
  barcodes: string[] = [],
): uui.domain.client.rm.OrderStepTrackingData {
  return Object.assign(source, {
    isEmpty: orderHelpers.isEmptyTrackingData(source, source.pods),
    status: source.status ?? 'missing',
    pods: source.pods
      ? createPodContainer(source.pods, barcodes)
      : barcodes.length > 0
      ? {
          barcodes: barcodes.map(
            barcode =>
              ({
                barcode,
                sec: -1,
                latLng: null,
                uuid: barcode,
                podType: 'barcodes',
                barcodeStatus: 'UNSCANNED',
              } as uui.domain.client.rm.BarcodePod),
          ),
        }
      : {},
  })
}

export function createPodContainer(
  source: uui.domain.server.rm.PodContainer,
  barcodes: string[] = [],
): uui.domain.client.rm.PodContainer {
  const podContainer: uui.domain.client.rm.PodContainer = Object.assign(
    {},
    source.audios ? { audios: createPods(source.audios, 'audios') } : undefined,

    source.signatures ? { signatures: createPods(source.signatures, 'signatures') } : undefined,

    source.pictures ? { pictures: createPods(source.pictures, 'pictures') } : undefined,

    source.note ? { note: createPod(source.note, 'note', 'note_pod') } : undefined,

    source.barcodes && source.barcodes.length > 0
      ? {
          barcodes: source.barcodes.map(pod => createBarcodePod(pod, 'barcodes', pod.barcode)),
        }
      : undefined,
  )

  // ATTENTION: Client-side we must create a POD for every unscanned POD defined by the OrderStep
  if (barcodes.length > 0) {
    if (podContainer.barcodes?.length !== barcodes.length) {
      podContainer.barcodes = podContainer.barcodes ?? []

      const scannedBarcodes = podContainer.barcodes.map(pod => pod.barcode)

      const unscannedBarcodes = barcodes.filter(barcode => {
        const index = scannedBarcodes.indexOf(barcode)

        if (index > -1) {
          scannedBarcodes.splice(index, 1)
          return false
        }

        return true
      })

      for (const barcode of unscannedBarcodes) {
        podContainer.barcodes.push({
          barcode,
          sec: -1,
          latLng: null,
          uuid: barcode,
          podType: 'barcodes',
          barcodeStatus: 'UNSCANNED',
        })
      }
    }
  }

  return podContainer
}

export function createPods(
  source: Record<string, uui.domain.server.rm.Pod> | uui.domain.server.rm.BarcodePod[],
  podType: uui.domain.client.rm.PodType,
) {
  return Object.entries(source).reduce<Record<string, uui.domain.client.rm.Pod>>(
    (podsAcc, [key, pod]) => {
      podsAcc[key] = createPod(pod, podType, key)
      return podsAcc
    },
    {},
  )
}

export function createPod(
  source: uui.domain.server.rm.Pod,
  podType: uui.domain.client.rm.PodType,
  uuid: string,
): uui.domain.client.rm.Pod {
  switch (podType) {
    case 'audios':
    case 'pictures':
    case 'signatures':
      return createMediaPod(source, podType, uuid)

    case 'note':
      return createNotePod(source, podType, uuid)

    default:
      throw new Error('Invalid pod type ')
  }
}

export function createMediaPod(
  source: uui.domain.server.rm.Pod,
  podType: uui.domain.client.rm.MediaPod['podType'],
  uuid: string,
): uui.domain.client.rm.MediaPod {
  if (typeof source.token !== 'string') {
    throw new Error(`Pod: ${uuid} is missing the token property.`)
  }

  return Object.assign(source, {
    uuid,
    podType,
    sec: source.sec ?? -1,
    token: source.token ?? '',
    latLng: source.latLng ? latLngFromJSON(source.latLng) : null,
  })
}

export function createNotePod(
  source: uui.domain.server.rm.Pod,
  podType: uui.domain.client.rm.NotePod['podType'],
  uuid: string,
): uui.domain.client.rm.NotePod {
  return Object.assign(source, {
    uuid,
    podType,
    sec: source.sec ?? -1,
    text: source.text ?? '',
    latLng: source.latLng ? latLngFromJSON(source.latLng) : null,
  })
}

export function createBarcodePod(
  source: uui.domain.server.rm.BarcodePod,
  podType: uui.domain.client.rm.BarcodePod['podType'],
  uuid: string,
): uui.domain.client.rm.BarcodePod {
  return Object.assign(source, {
    uuid,
    podType,
    sec: source.sec ?? -1,
    latLng: source.latLng ? latLngFromJSON(source.latLng) : null,
  })
}

// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------
// Extended

type ExtendedOrderInCreation = {
  order: uui.domain.client.rm.Order
  type: uui.domain.client.rm.OrderIdentifier
  pickup?: uui.domain.client.rm.TransportExtendedOrderStep
  delivery?: uui.domain.client.rm.TransportExtendedOrderStep
}

export function createExtendedOrders(
  territory: uui.domain.client.rm.Territory,
  orders: Record<string, uui.domain.client.rm.Order>,
  routes: Record<string, uui.domain.client.rm.ExtendedRoute>,
  vehicles: Record<string, uui.domain.client.rm.Vehicle>,
  depots: Record<string, uui.domain.client.rm.Depot>,
  driverAssignments: Record<string, uui.domain.client.rm.DriverAssignments>,
  approvedPlans: Record<string, uui.domain.client.rm.ApprovedPlanData>,

  unassignedIds: string[],
  orderStepTrackingDataRegistry: Record<string, uui.domain.server.rm.GlobalOrderStepTrackingData>,
  userConfiguration: uui.domain.UserConfiguration,
  translate: uui.domain.intl.Translate,
) {
  // process all assigned orders
  const ordersInCreation = Object.entries(routes).reduce<Record<string, ExtendedOrderInCreation>>(
    (routesAcc, [routeId, extendedRoute]) => {
      const vehicle = vehicles[extendedRoute.route.vehicleId]

      if (!vehicle) {
        throw new Error(`Missing vehicle (${extendedRoute.route.vehicleId}) for route: ${routeId}`)
      }

      aggregatedStepsLoop: for (const aggregatedStep of extendedRoute.steps) {
        // skip all routeSteps with no linked orderSteps
        if (!aggregatedStep.linked) {
          continue aggregatedStepsLoop
        }

        const depot: uui.domain.client.rm.Depot | undefined = depots[aggregatedStep.depotId ?? '#']

        stepsLoop: for (const step of aggregatedStep.steps) {
          if (step.step.type === 'departure' || step.step.type === 'arrival') {
            // ATTENTION: this is required to prevent exceptions when arrival or departures are
            // at the depot
            // FIXME: verify if those two steps should be aggregated at the depot
            // This fixes situations were a route starts at a depot and has a departure step
            // @see https://github.com/WorkWave/route-manager/issues/17
            continue
          }

          const order = orders[step.step.ref ?? '#']

          if (!order) {
            throw new Error(`Missing order (${step.step.ref}) for route: ${routeId}`)
          }

          const extOrderStep = createTransportExtendedOrderStep(
            order,
            step,
            extendedRoute,
            vehicle,
            depot,
            territory,
            depots,
            vehicles,
            driverAssignments,
            approvedPlans,
            orderStepTrackingDataRegistry,
            userConfiguration,
            translate,
          )

          // --------------------------------------------------------------------------------
          // --------------------------------------------------------------------------------

          routesAcc[order.id] = {
            // keep possibly available data
            ...(routesAcc[order.id] ?? {}),

            // refresh the order data
            order,
            type: order.type,

            // add the right step
            ...(step.step.type === 'pickup'
              ? { pickup: extOrderStep }
              : { delivery: extOrderStep }),
          }
        }
      }

      //
      return routesAcc
    },
    {},
  )

  // process all unassigned orders
  for (const unassignedOrderStepId of unassignedIds) {
    const { orderId: unassignedOrderId } = parseOrderStepId(unassignedOrderStepId)

    const order = orders[unassignedOrderId]

    if (!order) {
      throw new Error(`Missing unassigned order (${unassignedOrderId})`)
    }

    const orderStep =
      unassignedOrderStepId.slice(-2) === '-p'
        ? order.type === 'p' || order.type === 'pd'
          ? order.pickup
          : undefined
        : order.type === 's' || order.type === 'd' || order.type === 'pd'
        ? order.delivery
        : undefined

    if (!orderStep) {
      throw new Error(`Missing unassigned orderStep (${unassignedOrderStepId})`)
    }

    const extOrderStep = createUnassignedExtendedOrderStep(
      order,
      orderStep.type,
      vehicles,
      depots,
      approvedPlans,
      orderStepTrackingDataRegistry,
      userConfiguration,
      translate,
    )

    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------

    ordersInCreation[order.id] = {
      // keep possibly available data
      ...(ordersInCreation[order.id] ?? {}),

      // refresh the order data
      order,
      type: order.type,

      // add the right step
      ...(orderStep.type === 'p' ? { pickup: extOrderStep } : { delivery: extOrderStep }),
    }
  }

  // compose the final Object of ExtendedOrder
  const final: Record<string, uui.domain.client.rm.ExtendedOrder> = {}

  for (const orderInCreation of Object.values(ordersInCreation)) {
    const { order, type, delivery, pickup } = orderInCreation

    switch (type) {
      case 'p':
        if (!pickup) {
          throw new Error(`Missing pickup data for Order ${order.id}`)
        }

        final[order.id] = { id: order.id, order, type, extendedPickup: pickup }
        break

      case 'd':
      case 's':
        if (!delivery) {
          throw new Error(`Missing delivery data for Order ${order.id}`)
        }

        final[order.id] = { id: order.id, order, type, extendedDelivery: delivery }
        break

      case 'pd':
        if (!pickup) {
          throw new Error(`Missing pickup data for Order ${order.id}`)
        }
        if (!delivery) {
          throw new Error(`Missing delivery data for Order ${order.id}`)
        }

        final[order.id] = {
          id: order.id,
          order,
          type,
          extendedDelivery: delivery,
          extendedPickup: pickup,
        }
        break
    }
  }

  return final
}

export function createUnassignedExtendedOrderStep(
  order: uui.domain.client.rm.Order,
  orderStepType: uui.domain.client.rm.OrderStepIdentifier,
  vehicles: Record<string, uui.domain.client.rm.Vehicle>,
  depots: Record<string, uui.domain.client.rm.Depot>,
  approvedPlans: uui.domain.client.rm.ApprovedPlans,
  orderStepTrackingDataRegistry: Record<string, uui.domain.server.rm.GlobalOrderStepTrackingData>,
  userConfiguration: uui.domain.UserConfiguration,
  translate: uui.domain.intl.Translate,
): uui.domain.client.rm.TransportExtendedOrderStep {
  const orderStep =
    orderStepType === 'p'
      ? order.type === 'p' || order.type === 'pd'
        ? order.pickup
        : undefined
      : order.type === 's' || order.type === 'd' || order.type === 'pd'
      ? order.delivery
      : undefined

  if (!orderStep) {
    throw new Error(`Missing unassigned orderStep (${orderStepType}) for order (${order.id})`)
  }

  const vehicle: uui.domain.client.rm.Vehicle | undefined = vehicles[order.forceVehicleId ?? '#']

  if (!vehicle && order.forceVehicleId) {
    throw new Error(`Missing forced vehicle (${order?.forceVehicleId}) for order: ${order.id}`)
  }

  const orderLocation = orderHelpers.getOrderStepLocation(orderStep, depots)
  const depot = orderStep.atDepot ? depots[orderStep.depotId] : undefined

  const pairedId =
    order.type === 'pd' && order.delivery && order.pickup
      ? orderStep === order.pickup
        ? order.delivery.id
        : order.pickup.id
      : undefined

  const pairedIdLabel = pairedId ? order.uid.toString() : undefined

  // --------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------
  // Composition of ExtendedOrderStep pieces

  const extOrderStepBase: uui.domain.client.rm.BaseTransportExtendedOrderStep = {
    id: orderStep.id,
    orderStepType: orderStep.type,

    location: orderLocation,
    latLng: orderLocation.latLng,
    lat: orderLocation.latLng.lat,
    lng: orderLocation.latLng.lng,

    isService: order.isService,
    hasPOD: false,
  }

  const extOrderStepPairedData: uui.domain.client.rm.TransportExtendedOrderStepPairedData =
    order.type === 'pd'
      ? {
          type: order.type,

          pairedId: pairedId!,
          pairedIdLabel: pairedIdLabel!,
        }
      : { type: order.type }

  const extOrderStepVehicleData: uui.domain.client.rm.TransportExtendedOrderStepVehicleData =
    vehicle
      ? {
          hasVehicle: true,

          vehicleId: vehicle.id,
          vehicleIdx: vehicle.idx,
          vehicleName: vehicle.externalId,
        }
      : {
          hasVehicle: false,
        }

  const extOrderStepDepotData: uui.domain.client.rm.TransportExtendedOrderStepDepotData = depot
    ? {
        atDepot: true,

        depot,
        depotId: depot.id,
      }
    : { atDepot: false }

  const extOrderStepWithoutFormattedData: uui.domain.client.rm.TransportExtendedOrderStepWithoutFormattedData =
    {
      ...extOrderStepBase,
      ...extOrderStepPairedData,
      ...extOrderStepVehicleData,
      ...extOrderStepDepotData,
      isUnassigned: true,
    }

  const extOrderStep: uui.domain.client.rm.TransportExtendedOrderStep = {
    ...extOrderStepWithoutFormattedData,
    formattedData: generateFormattedData(
      extOrderStepWithoutFormattedData,
      order,
      vehicles,
      approvedPlans,
      userConfiguration,
      orderStepTrackingDataRegistry,
      translate,
    ),
  }

  return extOrderStep
}

export function createUnassignedExtendedOrder(
  order: uui.domain.client.rm.Order,
  vehicles: Record<string, uui.domain.client.rm.Vehicle>,
  depots: Record<string, uui.domain.client.rm.Depot>,
  approvedPlans: Record<string, uui.domain.client.rm.ApprovedPlanData>,
  orderStepTrackingDataRegistry: Record<string, uui.domain.server.rm.GlobalOrderStepTrackingData>,
  userConfiguration: uui.domain.UserConfiguration,
  translate: uui.domain.intl.Translate,
): uui.domain.client.rm.ExtendedOrder {
  switch (order.type) {
    case 'p': {
      if (!order.pickup) {
        throw new Error(`Missing pickup data for Order ${order.id}`)
      }

      const pickup = createUnassignedExtendedOrderStep(
        order,
        order.type,
        vehicles,
        depots,
        approvedPlans,
        orderStepTrackingDataRegistry,
        userConfiguration,
        translate,
      )
      return { id: order.id, order, type: order.type, extendedPickup: pickup }
    }

    case 'd':
    case 's': {
      if (!order.delivery) {
        throw new Error(`Missing delivery data for Order ${order.id}`)
      }

      const delivery = createUnassignedExtendedOrderStep(
        order,
        order.type,
        vehicles,
        depots,
        approvedPlans,
        orderStepTrackingDataRegistry,
        userConfiguration,
        translate,
      )
      return { id: order.id, order, type: order.type, extendedDelivery: delivery }
    }

    case 'pd': {
      if (!order.pickup) {
        throw new Error(`Missing pickup data for Order ${order.id}`)
      }
      if (!order.delivery) {
        throw new Error(`Missing delivery data for Order ${order.id}`)
      }

      const pickup = createUnassignedExtendedOrderStep(
        order,
        'p',
        vehicles,
        depots,
        approvedPlans,
        orderStepTrackingDataRegistry,
        userConfiguration,
        translate,
      )

      const delivery = createUnassignedExtendedOrderStep(
        order,
        'd',
        vehicles,
        depots,
        approvedPlans,
        orderStepTrackingDataRegistry,
        userConfiguration,
        translate,
      )

      return {
        id: order.id,
        order,
        type: order.type,
        extendedDelivery: delivery,
        extendedPickup: pickup,
      }
    }
  }
}

export function createTransportExtendedOrderStep(
  order: uui.domain.client.rm.Order,
  step: uui.domain.client.rm.ExtendedRouteStep,

  extendedRoute: uui.domain.client.rm.ExtendedRoute,

  vehicle: uui.domain.client.rm.Vehicle,
  depot: uui.domain.client.rm.Depot | undefined,

  territory: uui.domain.client.rm.Territory,
  depots: Record<string, uui.domain.client.rm.Depot>,
  vehicles: Record<string, uui.domain.client.rm.Vehicle>,
  driverAssignments: Record<string, Record<string, string>>,
  approvedPlans: Record<string, uui.domain.client.rm.ApprovedPlanData>,
  orderStepTrackingDataRegistry: Record<string, uui.domain.server.rm.GlobalOrderStepTrackingData>,
  userConfiguration: uui.domain.UserConfiguration,
  translate: uui.domain.intl.Translate,
): uui.domain.client.rm.TransportExtendedOrderStep {
  const orderStep =
    step.step.type === 'pickup'
      ? order.type === 'p' || order.type === 'pd'
        ? order.pickup
        : undefined
      : order.type === 's' || order.type === 'd' || order.type === 'pd'
      ? order.delivery
      : undefined

  if (!orderStep) {
    throw new Error(`Missing orderStep for order: ${order.id}`)
  }

  const orderLocation = orderHelpers.getOrderStepLocation(orderStep, depots)

  const hasBeenExecuted = orderHelpers.isOrderStepExecuted(orderStep, territory.rescheduleBehavior)

  const approvedRoutes: Record<string, uui.domain.client.rm.ApprovedRoute> | undefined =
    approvedPlans?.[extendedRoute.route.date]?.approvedRoutes

  const driverAssignmentsRecurrences = Object.keys(driverAssignments)
  const matchingRecurrence = recurrenceHelpers.getMatchingRecurrenceByDate(
    extendedRoute.route.date,
    driverAssignmentsRecurrences,
  )
  const driverId: string | undefined =
    driverAssignments[matchingRecurrence]?.[extendedRoute.route.vehicleId]

  const hasPOD = orderHelpers.orderStepHasPOD(orderStep)

  const pairedId =
    order.type === 'pd' && order.delivery && order.pickup
      ? orderStep === order.pickup
        ? order.delivery.id
        : order.pickup.id
      : undefined

  const pairedIdLabel = pairedId ? order.uid.toString() : undefined

  // --------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------
  // Composition of ExtendedOrderStep pieces

  const extOrderStepBase: uui.domain.client.rm.BaseTransportExtendedOrderStep = {
    id: orderStep.id,
    orderStepType: orderStep.type,

    location: orderLocation,
    latLng: orderLocation.latLng,
    lat: orderLocation.latLng.lat,
    lng: orderLocation.latLng.lng,

    isService: order.isService,
    hasPOD,
  }

  const extOrderStepPairedData: uui.domain.client.rm.TransportExtendedOrderStepPairedData =
    order.type === 'pd'
      ? {
          type: order.type,

          pairedId: pairedId!,
          pairedIdLabel: pairedIdLabel!,
        }
      : { type: order.type }

  const extOrderStepVehicleData: uui.domain.client.rm.TransportExtendedOrderStepVehicleData =
    vehicle
      ? {
          hasVehicle: true,

          vehicleId: extendedRoute.route.vehicleId,
          vehicleIdx: vehicle.idx,
          vehicleName: vehicle.externalId,
        }
      : {
          hasVehicle: false,
        }

  const extOrderStepAssignedData: uui.domain.client.rm.TransportExtendedOrderStepAssignedData = {
    isUnassigned: false,

    routeId: extendedRoute.id,
    dateAsString: extendedRoute.route.date,

    stepStartSec: step.step.startSec,
    stepEndSec: step.step.endSec,
    routeStepLabel: step.step.displayLabel,
    color: extendedRoute.route.color,
    stepNumber: step.step.stopIdx,
    idleTimeSec: step.step.idleTimeSec,
    hasBeenExecuted,

    isRouteApproved:
      approvedRoutes && extendedRoute.route.vehicleId
        ? !!approvedRoutes[extendedRoute.route.vehicleId]
        : false,

    driverId,
    violations: step.hasViolations ? step.stepViolations : undefined,
  }

  const extOrderStepDepotData: uui.domain.client.rm.TransportExtendedOrderStepDepotData = depot
    ? {
        atDepot: true,

        depot,
        depotId: depot.id,
      }
    : { atDepot: false }

  const extOrderStepWithoutFormattedData: uui.domain.client.rm.TransportExtendedOrderStepWithoutFormattedData =
    {
      ...extOrderStepBase,
      ...extOrderStepPairedData,
      ...extOrderStepVehicleData,
      ...extOrderStepAssignedData,
      ...extOrderStepDepotData,
    }

  const extOrderStep: uui.domain.client.rm.TransportExtendedOrderStep = {
    ...extOrderStepWithoutFormattedData,
    formattedData: generateFormattedData(
      extOrderStepWithoutFormattedData,
      order,
      vehicles,
      approvedPlans,
      userConfiguration,
      orderStepTrackingDataRegistry,
      translate,
    ),
  }

  return extOrderStep
}

/**
 * The server-data emits a serialization-optimized Extended Order which avoids multiple
 * internal references of the same data. The optimized version needs to be converted by restoring
 * all the internal references, as needed by the UI.
 * If `orderStepsIdInRange` is passed, the calculation can be optimized.
 *
 * There are two consumers of this function:
 * - the UI extracting the order steps
 * - the lists that need a collection of {[id]: item} (take a look at `getItemValue` and its callers)
 * ATTENTION: except for the above-listed consumers, NO ONE MUST USE THIS FUNCTION!
 *
 * @private
 */
export function extractOrderStepsFromOrders(
  orders: Record<string, uui.domain.client.rm.ExtendedOrder>,
  orderStepsIdInRange?: Record<string, true>,
) {
  const output: Record<string, uui.domain.client.rm.ExtendedOrderStep> = {}
  let orderStepsInRange

  for (const orderId in orders) {
    if (orderStepsIdInRange) {
      // the orders without order steps in range are skipped
      orderStepsInRange = whichOrderStepsAreInRange(orders[orderId], orderStepsIdInRange)

      if (orderStepsInRange.length === 0) {
        continue
      }
    }

    Object.assign(output, extractOrderStepsFromOrder(orders[orderId], orderStepsInRange))
  }
  return output
}

export function extractOrdersGridRows(
  extOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
  listStructure: uui.domain.ui.list.ListStructure,
) {
  const rows: uui.domain.client.rm.FormattedData[] = listStructure.list.reduce<
    uui.domain.client.rm.FormattedData[]
  >((acc, orderStepId) => {
    if (typeof orderStepId !== 'string') return acc

    const extOrderStep = extOrderSteps[orderStepId]

    if (!extOrderStep) return acc

    acc.push(extOrderStep.formattedData)

    return acc
  }, [])

  return rows
}

/**
 * Detect which order steps are contained in the current orders and are in the current calendar range
 */
function whichOrderStepsAreInRange(
  order: uui.domain.client.rm.ExtendedOrder,
  orderStepsIdInRange: Record<string, true>,
) {
  const stepsInRange: ('dropoff' | 'pickup')[] = []

  if (
    (order.type === 'pd' || order.type === 'd' || order.type === 's') &&
    orderStepsIdInRange[`${order.id}-d`] === true
  ) {
    stepsInRange.push('dropoff')
  }

  if (
    (order.type === 'pd' || order.type === 'p') &&
    orderStepsIdInRange[`${order.id}-p`] === true
  ) {
    stepsInRange.push('pickup')
  }

  return stepsInRange
}

/**
 * Extracts all the Extended Order Steps from the serialization-optimized Extended Order. The
 * Extended Order Step is consumed by the UI while the original Extended Order is not.
 *
 * There are two consumers of this function:
 * - the UI extracting the order steps
 * - the lists that need a collection of {[id]: item} (take a look at `getItemValue` and its callers)
 * ATTENTION: except for the above-listed consumers, NO ONE MUST USE THIS FUNCTION!
 */
export function extractOrderStepsFromOrder(
  order: uui.domain.client.rm.ExtendedOrder,
  stepsToExtract?: ('pickup' | 'dropoff')[],
) {
  const output: Record<string, uui.domain.client.rm.ExtendedOrderStep> = {}
  if (order.type === 'p' || order.type === 'pd') {
    if (order.order.type === 'p' || order.order.type === 'pd') {
      if (!stepsToExtract || stepsToExtract.includes('pickup'))
        output[order.extendedPickup.id] = extractExtendedPickupFromOrder(order)
    }
  }

  if (order.type === 'd' || order.type === 's' || order.type === 'pd') {
    if (order.order.type === 'd' || order.order.type === 's' || order.order.type === 'pd') {
      if (!stepsToExtract || stepsToExtract.includes('dropoff'))
        output[order.extendedDelivery.id] = extractExtendedDropoffFromOrder(order)
    }
  }

  return output
}

/**
 * Extracts the Extended Pickup from the serialization-optimized Extended Order. The consumer must
 * check for itself that the order effectively includes a pickup.
 *
 * There are two consumers of this function:
 * - the `extractOrderStepsFromOrder`
 * - the lists that need a collection of {[id]: item} (take a look at `getItemValue` and its callers)
 * ATTENTION: except for the above-listed consumers, NO ONE MUST USE THIS FUNCTION!
 */
export function extractExtendedPickupFromOrder(
  order: uui.domain.client.rm.ExtendedOrder,
): uui.domain.client.rm.ExtendedOrderStep {
  if (order.type === 'p' || order.type === 'pd') {
    if (order.order.type === 'p' || order.order.type === 'pd') {
      return {
        ...order.extendedPickup,
        status: computeExtendedOrderStepStatus(order.extendedPickup, order.order.pickup),
        order: order.order,
        orderStep: order.order.pickup,
        pickup: order.order.pickup,
        delivery:
          order.type === 'pd' && order.order.type === 'pd' ? order.order.delivery : undefined,
      }
    }
  }

  throw new Error(`The passed order doesn't have a pickup (id: ${order.id}, type: ${order.type})`)
}

/**
 * Extracts the Extended Dropoff from the serialization-optimized Extended Order. The consumer must
 * check for itself that the order effectively includes a dropoff.
 *
 * There are two consumers of this function:
 * - the `extractOrderStepsFromOrder`
 * - the lists that need a collection of {[id]: item} (take a look at `getItemValue` and its callers)
 * ATTENTION: except for the above-listed consumers, NO ONE MUST USE THIS FUNCTION!
 */
export function extractExtendedDropoffFromOrder(
  order: uui.domain.client.rm.ExtendedOrder,
): uui.domain.client.rm.ExtendedOrderStep {
  if (order.type === 'd' || order.type === 's' || order.type === 'pd') {
    if (order.order.type === 'd' || order.order.type === 's' || order.order.type === 'pd') {
      return {
        ...order.extendedDelivery,
        status: computeExtendedOrderStepStatus(order.extendedDelivery, order.order.delivery),
        order: order.order,
        orderStep: order.order.delivery,
        delivery: order.order.delivery,
        pickup: order.type === 'pd' && order.order.type === 'pd' ? order.order.pickup : undefined,
      }
    }
  }

  throw new Error(`The passed order doesn't have a dropoff (id: ${order.id}, type: ${order.type})`)
}
