import { ViewField, ViewFieldsLayout } from '@/formUi'
import { useTexts } from './hooks/useTexts'
//import { Restrictions } from './Restrictions'

type Props = {
  voice: uui.domain.server.rm.VoiceConfig
}

export function BackOfficeAdvancedProperties(props: Props) {
  const { voice } = props
  const texts = useTexts()

  if (voice.status !== 'ADVANCED' || voice.callbackConfig.status !== 'BACK_OFFICE') return null

  return (
    <ViewFieldsLayout>
      {/*
      //TODO: Re-enable that field once it will be available
      <ViewField label={texts.labels.restrictions}>
        <Restrictions voice={voice} />
      </ViewField>
      */}
      <ViewField label={texts.labels.preference}>{texts.value.preference.on}</ViewField>
      <ViewField label={texts.labels.phone}>{voice.callbackConfig.backOfficePhone}</ViewField>
    </ViewFieldsLayout>
  )
}
