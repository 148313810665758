import type { LocationEditorFieldWrappedInputProps } from '@/forms-legacy'
import type { GeofenceFormValues } from '../../types'
import { useForm } from 'react-final-form'

import { useCallback, useMemo } from 'react'

import { LocationEditorField } from '@/forms-legacy'
import { useGetMapInfo } from '@/map'

import { useTexts } from './useTexts'

const wrappedInputProps: LocationEditorFieldWrappedInputProps = {
  modes: ['copyCoordinates'],
  height: 150,
}

export function Address() {
  const { center: mapCenter } = useGetMapInfo()
  const texts = useTexts()
  const form = useForm()

  const { values } = form.getState()
  const { name, type, radius } = values as GeofenceFormValues

  const onRadiusChanged = useCallback((r: number) => form.change('radius', r), [form])

  const pinInfo = useMemo(
    () => ({
      source: { name, type },
    }),
    [name, type],
  )

  return (
    <LocationEditorField
      name="location"
      inactive={false}
      pinInfo={pinInfo}
      pinType="geofence"
      snapToRoad={false}
      pinRadius={radius}
      useMapBounds={true}
      pinId="geofenceFormPin"
      label={texts.locationTitle}
      territoryCenter={mapCenter}
      onRadiusChanged={onRadiusChanged}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
