import { reverseGeocodeLocation } from '../../../utils/reverseGeocodeLocation'
import { getMapInfo } from '../../map/hooks/useGetMapInfo'
import { setInspectPin } from './setInspectPin'

export async function moveInspectPin(latLng: uui.domain.LatLng) {
  const { bounds } = getMapInfo()

  // immediately update the locationPin latLng.
  // The location it will be updated later
  setInspectPin({ latLng, status: 'loading' })

  const location = await reverseGeocodeLocation(latLng, bounds)

  if (location) {
    setInspectPin({ status: 'ready', location })
  }
}
