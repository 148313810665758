import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { SecondaryColumnHeader } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { usePendingFitMapOnMount } from '@/map'
import { resetCrudSelection } from '@/atoms'
import { selectTerritory } from '@/features/domain/territory'

import { BulkAvatar } from '../../components/BulkAvatar'

import { ActionsManager } from './components/ActionsManager'
import { useTexts } from './useTexts'

interface Props {
  places: uui.domain.client.gps.wwgps.Place[]
}

export function BulkView(props: Props) {
  const { places } = props
  const texts = useTexts()

  usePendingFitMapOnMount(true)

  const onReset = useCallback(() => {
    resetCrudSelection('places')
  }, [])

  const territory = useSelector(selectTerritory)

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.headerTitle(places.length)}
        description={texts.headerDescription(territory.name)}
        action={onReset}
        avatar={<BulkAvatar size={44} elementsCount={places.length} />}
        data-testid="places-readonly-header"
      />
      <ActionsManager places={places} />
    </FormColumn>
  )
}
