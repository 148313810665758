import type { VoiceEventMarker } from '../../../../atoms/voiceEventMarkers/voiceEventMarker'

import { mapAtom } from '../../../../atoms/map/map'
import { findLayer } from '../../../utils/findLayer'
import { createVoiceEventFeature } from '../utils/createVoiceEventFeature'

export function addVoiceEventFeature(eventMarker: workwave.DeepReadonly<VoiceEventMarker>) {
  const map = mapAtom.map
  const layer = findLayer(map, 'voiceEvents')

  // create the marker
  const feature = createVoiceEventFeature(eventMarker)
  layer.getSource()?.addFeature(feature)

  return feature
}
