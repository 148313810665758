import { type PropsWithChildren, memo, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { type Column as ColumnData } from '../../../types'

type DraggableItemProps = PropsWithChildren<
  ColumnData & {
    onMoveColumn: (id: string, afterId: string) => void
  }
>

export const DraggableItem = memo(function DraggableItem(props: DraggableItemProps) {
  const { id, onMoveColumn, children } = props
  const ref = useRef(null)

  const [{ isDragging }, connectDrag] = useDrag({
    type: 'COLUMN',
    item: { id },
    collect: monitor => {
      const result = {
        handlerId: monitor.getHandlerId(),
        isDragging: monitor.isDragging(),
      }
      return result
    },
  })

  const [, connectDrop] = useDrop({
    accept: 'COLUMN',
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        onMoveColumn(draggedId, id)
      }
    },
  })

  connectDrag(ref)
  connectDrop(ref)

  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0 : 1, cursor: 'grab' }}
      data-testid="columns-picker-sequence-column"
    >
      {children}
    </div>
  )
})
