import { useCallback } from 'react'
import { ReadonlyLabel, TextButton } from '@/forms-legacy'
import { useNavigate } from '@/routing'

import { useTexts } from './useTexts'

export function TrackingOptionDisclaimer() {
  const texts = useTexts()
  const navigate = useNavigate<uui.routing.Setup>(true)

  const onClick = useCallback(() => {
    navigate('driver-assignments')
  }, [navigate])

  return (
    <ReadonlyLabel small>
      {texts.trackingOption}
      <TextButton onClick={onClick} style={{ display: 'block', marginTop: 4 }}>
        {texts.info}
      </TextButton>
    </ReadonlyLabel>
  )
}
