import { useCallback } from 'react'
import { useIntl } from '@/intl'

export function useLocateFieldName() {
  const { translate } = useIntl()

  return useCallback(
    (key: keyof uui.domain.ui.forms.ExceptionFormValuesValidation) => {
      switch (key) {
        case 'workingDayInfo':
          return translate({ id: 'vehicles.form.exceptions.vehicle.workingHours' })

        case 'breaks':
          return translate({ id: 'vehicles.form.exceptions.vehicle.breaks' })

        case 'activationCost':
          return translate({
            id: 'vehicles.form.exceptions.vehicle.costs.fixed',
            values: { extra: '' },
          })

        case 'serviceTimeCost':
          return translate({
            id: 'vehicles.form.exceptions.vehicle.costs.serviceTime',
            values: { extra: '' },
          })

        case 'drivingTimeCost':
          return translate({
            id: 'vehicles.form.exceptions.vehicle.costs.drivingTime',
            values: { extra: '' },
          })

        case 'idleTimeCost':
          return translate({
            id: 'vehicles.form.exceptions.vehicle.costs.idleTime',
            values: { extra: '' },
          })

        case 'breakTimeCost':
          return translate({
            id: 'vehicles.form.exceptions.vehicle.costs.breakTime',
            values: { extra: '' },
          })

        case 'kmCost':
          return translate({
            id: 'vehicles.form.exceptions.vehicle.costs.perKm',
            values: { extra: '' },
          })

        case 'perStopCost':
          return translate({
            id: 'vehicles.form.exceptions.vehicle.costs.perStop',
            values: { extra: '' },
          })

        case 'maxOrders':
          return translate({ id: 'vehicles.form.exceptions.vehicle.maxOrders' })

        case 'maxDistanceMt':
          return translate({
            id: 'vehicles.form.exceptions.vehicle.maxMileage',
            values: { extra: '' },
          })

        case 'maxDrivingTimeSec':
          return translate({
            id: 'vehicles.form.exceptions.vehicle.maxDrivingHours',
            values: { extra: '' },
          })

        case 'maxWorkingTimeSec':
          return translate({
            id: 'vehicles.form.exceptions.vehicle.maxWorkingHours',
            values: { extra: '' },
          })

        case 'minWorkingTimeSec':
          return translate({
            id: 'vehicles.form.exceptions.vehicle.minWorkingHours',
            values: { extra: '' },
          })
        default:
          return key
      }
    },
    [translate],
  )
}
