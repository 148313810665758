import { type ResourceModel } from '@bryntum/schedulerpro'
import { useIsLoading } from '@/atoms'

import { schedulerPreferencesAtom } from '../../../atoms/schedulerPreferences'
import { DoubleColumnContainer } from '../../Scheduler/components/DoubleColumnContainer'
import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../Scheduler/components/Typography'
import { Spinner } from '../../Scheduler/components/Spinner'
import { Link } from '../../Scheduler/components/Link'

interface Props {
  record: ResourceModel
  field: string
  value: uui.domain.client.rm.SchedulerResource['driverName']
}

export function DriverCell(props: Props) {
  const { value } = props
  const { showApprovedRoutes } = schedulerPreferencesAtom.preferences
  const gpsDataLoading = useIsLoading('fetchGpsData')

  // If GPS data are still loading we cannot select/edit driver assignments
  if (gpsDataLoading) {
    return (
      <SingleColumnContainer>
        <Spinner size={14} />
      </SingleColumnContainer>
    )
  }

  if (value.approved && showApprovedRoutes) {
    return (
      <DoubleColumnContainer
        firstRow={
          <Link>
            <Typography fontSize={12}>{value.current}</Typography>
          </Link>
        }
        secondRow={<Typography fontSize={10}>{value.approved}</Typography>}
      />
    )
  }

  return (
    <SingleColumnContainer>
      <Link>
        <Typography fontSize={12}>{value.current}</Typography>
      </Link>
    </SingleColumnContainer>
  )
}
