import styled from 'styled-components'

const SIZE = 14

const CheckboxIconWrapper = styled.div`
  position: relative;
  width: ${SIZE}px;
  display: block;
`

CheckboxIconWrapper.displayName = 'CheckboxIconWrapper'
export default CheckboxIconWrapper
