import type { MapSelectionContext } from '../mapSelection'
import type { CrudSelection, MainSelection } from '@/atoms'

import { findLayer } from '../../../layers/utils/findLayer'
import { updateDepotsMapSelection } from '../../../layers/depot/updateDepotsMapSelection'
import { updateOrdersMapSelection } from '../../../layers/order/updateOrdersMapSelection'
import { updatePlacesMapSelection } from '../../../layers/place/updatePlacesMapSelection'
import { updateDevicesMapSelection } from '../../../layers/device/updateDevicesMapSelection'
import { updateRegionsMapSelection } from '../../../layers/region/updateRegionsMapSelection'
import { updateVehiclesMapSelection } from '../../../layers/vehicle/updateVehiclesMapSelection'
import { updateGeofenceMapSelection } from '../../../layers/geofence/updateGeofenceMapSelection'
import { updateDeviceEventsMapSelection } from '../../../layers/deviceEvent/updateDeviceEventsMapSelection'
import { updateRoadSegmentsMapSelection } from '../../../layers/roadSegment/updateRoadSegmentsMapSelection'
import { updateTrafficAreasMapSelection } from '../../../layers/trafficArea/updateTrafficAreasMapSelection'
import { updateBreadcrumbMapSelection } from '../../../layers/breadcrumb/utils/updateBreadcrumbMapSelection'

import { mapAtom } from '../../map/map'
import { parseOrderSelection } from '../read/parseOrderSelection'

import { getMapCrudSelection } from '../utils/getMapCrudSelection'
import { getMapMainSelection } from '../utils/getMapMainSelection'
import { getDomainMarkerData } from '../../markerChangelog/domain/getDomainMarkerData'

const emptyStringSelection = new Set<string>()

export const updateMapSelection = (
  mapMarkers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
  mapSelectionContext: MapSelectionContext,
  mainSelection: workwave.DeepReadonly<MainSelection>,
  crudSelection: workwave.DeepReadonly<CrudSelection>,
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) => {
  return async () => {
    const crudMapSelection = getMapCrudSelection(mapSelectionContext, crudSelection)
    const mainMapSelection = getMapMainSelection(mapSelectionContext, mainSelection)
    const { licensingLimits } = getDomainMarkerData()

    // Update Orders
    await updateOrdersMapSelection(
      findLayer(mapAtom.map, 'order'),
      mapMarkers.order,
      mapStyles.order,

      // selection,
      parseOrderSelection(mapSelectionContext, mainSelection.orderSteps, extendedOrderSteps),
      mapSelectionContext.type === 'main' ? new Set(mainSelection.orderSteps) : new Set(),
    )

    // Update Depots
    await updateDepotsMapSelection(
      findLayer(mapAtom.map, 'depot'),
      mapMarkers.depot,
      mapStyles.depot,
      mapSelectionContext.type === 'main' ? mainMapSelection.depots : crudMapSelection.depots,
    )

    // Update Regions
    await updateRegionsMapSelection(
      findLayer(mapAtom.map, 'region'),
      mapMarkers.region,
      mapStyles.region,
      mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.regions,
    )

    // Update Road Segments
    await updateRoadSegmentsMapSelection(
      findLayer(mapAtom.map, 'roadSegment'),
      mapMarkers.roadSegment,
      mapStyles.roadSegment,
      mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.routingSegments,
    )

    // Update Geofences
    await updateGeofenceMapSelection(
      findLayer(mapAtom.map, 'geofence'),
      mapMarkers.geofence,
      mapStyles.geofence,
      mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.geofences,
    )

    // Update Traffic area
    await updateTrafficAreasMapSelection(
      findLayer(mapAtom.map, 'trafficProfile'),
      mapMarkers.trafficProfile,
      mapStyles.trafficProfile,
      mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.trafficAreas,
    )

    // Update Places
    await updatePlacesMapSelection(
      findLayer(mapAtom.map, 'place'),
      mapMarkers.place,
      mapStyles.place,
      mapSelectionContext.type === 'main' ? mainMapSelection.places : crudMapSelection.places,
    )

    const gpsOnly = !!licensingLimits.gpsOnly
    const gpsEnabled = !!licensingLimits.allowGps

    // If not gps user let's bail out
    if (!gpsOnly && !gpsEnabled) return

    // Update Breadcrumbs (paths got no selection)
    await updateBreadcrumbMapSelection(
      findLayer(mapAtom.map, 'breadcrumbPoints'),
      findLayer(mapAtom.map, 'breadcrumbPath'),
      mapMarkers.breadcrumb,
      mapStyles.breadcrumb,
      mapSelectionContext.type === 'main' ? mainMapSelection.breadcrumbs : emptyStringSelection,
      breadcrumbTimeRange,
    )

    // Update Devices
    await updateDevicesMapSelection(
      findLayer(mapAtom.map, 'device'),
      mapMarkers.device,
      mapStyles.device,
      mapSelectionContext.type === 'main' ? mainMapSelection.devices : crudMapSelection.devices,
    )

    // Update Vehicles
    await updateVehiclesMapSelection(
      findLayer(mapAtom.map, 'vehicle'),
      mapMarkers.vehicle,
      mapStyles.vehicle,
      mapSelectionContext.type === 'main'
        ? mainMapSelection.unifiedVehicles
        : crudMapSelection.unifiedVehicles,
    )

    // Update Device Events
    await updateDeviceEventsMapSelection(
      findLayer(mapAtom.map, 'deviceEvent'),
      mapMarkers.deviceEvent,
      mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.deviceEvents,
      breadcrumbTimeRange,
    )
  }
}
