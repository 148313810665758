import type { SearchAssetOnMapType } from '../typings'
import type { MutableDropDownState } from './useDropDownStateRef'
import type { SearchOnMapResult } from '@/hooks'

import { useCallback, useEffect, useRef, useState } from 'react'

import { useSearchOnMap, useIsUnmounted } from '@/hooks'
import { updateLocationPin } from '@/map'
import { defaultQueryMinLength } from '../../../../LocationEditor'

type SearchOnMapItemsOptions = {
  queryDelay?: number
  snapToRoad?: boolean
  ignoreMapBounds?: boolean
  queryMinLength?: number
  assetTypes?: SearchAssetOnMapType[]
  pinId: uui.domain.ui.map.LocationPinId
}

export function useSearchOnMapItems(
  options: SearchOnMapItemsOptions,
  dropDownStateRef: MutableDropDownState,
) {
  const {
    pinId,
    assetTypes,
    snapToRoad,
    queryDelay,
    ignoreMapBounds,
    queryMinLength = defaultQueryMinLength,
  } = options

  const isUnmounted = useIsUnmounted()

  const [items, setItems] = useState<uui.domain.client.gps.SearchOnMapItem[]>([])

  const searchOnMapTimeoutIdRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)
  useEffect(
    () => () => {
      if (searchOnMapTimeoutIdRef.current) {
        clearTimeout(searchOnMapTimeoutIdRef.current)
      }
    },
    [],
  )

  const searchOnMap = useSearchOnMap({
    snapToRoad,
    assetTypes,
    ignoreMapBounds,
    delay: queryDelay,
    minLength: queryMinLength,
    excludeOutOfTerritoryItems: !!snapToRoad,
  })

  const cleatItems = useCallback(() => void setItems([]), [])

  const onTextChange = useCallback(
    (address: string, _relatedLocation: uui.domain.client.Location) => {
      if (isUnmounted()) return

      dropDownStateRef.current.setDropDownState({ loading: true })

      // set loading status to true
      updateLocationPin(pinId, { loading: true })

      // searchOnMap return a timeout ID, we clear it when the component unmounts
      searchOnMapTimeoutIdRef.current = searchOnMap(address, (result: SearchOnMapResult) => {
        if (isUnmounted()) return

        setItems(result.items)

        if (result.requestStatus !== 'pending') {
          // set loading status to true
          updateLocationPin(pinId, { loading: false })

          dropDownStateRef.current.setDropDownState({ loading: false })
        }
      })
    },
    [searchOnMap, dropDownStateRef, pinId, isUnmounted],
  )

  return { items, onTextChange, cleatItems } as const
}
