import type { MainSelectionCategory } from '@/atoms'

import { Depot, Truck, Order, Star } from '@/icons'

interface Props {
  category: MainSelectionCategory
}

export function CategoryIcon(props: Props) {
  switch (props.category) {
    case 'depots':
      return <Depot size={20} color="$pureWhite" />

    case 'devices':
    case 'unifiedVehicles':
      return <Truck size={20} color="$pureWhite" />

    case 'orderSteps':
      return <Order size={11} color="$pureWhite" />

    case 'places':
      return <Star size={12} color="$pureWhite" />

    default:
      return null
  }
}
