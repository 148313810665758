import type { DrivingTimeStep } from '@/forms-legacy'

import { useMemo } from 'react'

import { useTexts } from './useTexts'

export function useSpeedSliderSteps() {
  const texts = useTexts()

  return useMemo<DrivingTimeStep[]>(
    () => [
      {
        label: texts.half,
        value: 0,
      },
      {
        label: texts.normal,
        value: 50,
      },
      {
        label: texts.double,
        value: 150,
      },
    ],
    [texts],
  )
}
