import type {
  FormError,
  FormField,
  FormFieldApi,
  FormSingleField,
  NarrowFieldsByValueType,
} from '@workwave-tidal/tidal/form-fairy'

import { useCallback, useMemo } from 'react'

type RequiredFormField = string

// internal type used to resolve the connected Form Field to a `string` instead of a dynamically derived type,
type PartialForm = Record<string, FormSingleField<RequiredFormField>>

export function useActions<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
>(fieldApi: Readonly<FormFieldApi<FIELD_NAME, PartialForm, FormError<FIELD_NAME>>>) {
  const change = useCallback(
    (value: string) => {
      fieldApi.change(value)
      fieldApi.validate()
    },
    [fieldApi],
  )

  const onBlur = useCallback(() => {
    fieldApi.validate()
  }, [fieldApi])

  const onFocus = useCallback(() => {
    fieldApi.validate()
  }, [fieldApi])

  return useMemo(
    () => ({
      change,
      onBlur,
      onFocus,
    }),
    [change, onBlur, onFocus],
  )
}
