import { Scrollbar } from '@workwave-tidal/tidal/components'

import { OrderStepNotificationList } from './OrderStepNotificationList'
import { OrderStepTrackingLink } from './OrderStepTrackingLink'
import { OrderStepUnsubscribeInfo } from './OrderStepUnsubscribeInfo'
import { FailedToLoadNotifications } from './components/FailedToLoadNotifications'
import { useOrderStepNotificationStatus } from './hooks/useOrderStepNotificationStatus'
import { useOrderStepTrackingLinkStatus } from './hooks/useOrderStepTrackingLinkStatus'
import { useOrderStepNotificationUnsubscription } from './hooks/useOrderStepNotificationUnsubscription'

type Props = {
  extendedOrder: uui.domain.client.rm.ExtendedOrderStep
}

export function OrderStepNotification(props: Props) {
  const { extendedOrder } = props

  const trackingLinkStatus = useOrderStepTrackingLinkStatus(extendedOrder)
  const notificationStatus = useOrderStepNotificationStatus(extendedOrder)
  const unsubscriptionStatus = useOrderStepNotificationUnsubscription(extendedOrder)

  if (
    trackingLinkStatus === 'failed' &&
    notificationStatus === 'failed' &&
    unsubscriptionStatus === 'failed'
  ) {
    return <FailedToLoadNotifications />
  }

  return (
    <Scrollbar hideOverflow="x">
      <>
        <OrderStepTrackingLink status={trackingLinkStatus} extendedOrderId={extendedOrder.id} />
        <OrderStepUnsubscribeInfo status={unsubscriptionStatus} extendedOrder={extendedOrder} />
        <OrderStepNotificationList status={notificationStatus} extendedOrderId={extendedOrder.id} />
      </>
    </Scrollbar>
  )
}
