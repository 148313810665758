import { journal } from '@/server-data'

export async function copyToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (error) {
    journal.user(`copyToClipboard failed`, { tags: ['copyToClipboard'], info: error }, 'error')
    return false
  }
}
