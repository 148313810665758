import { useState, useCallback, useMemo } from 'react'

import { DeviceListItem } from '../components/DeviceListItem'
import { useDevices } from './useDevices'
import { useCreateDevicesRenderText } from './useCreateDevicesRenderText'

export function useWrappedInputProps() {
  const devices = useDevices()
  const [visibleDevices, setVisibleDevices] = useState(() => devices)
  const devicesRenderText = useCreateDevicesRenderText()

  const onFilter = useCallback(
    (query: string) => {
      const filter = query.toLocaleLowerCase().trim()

      const value = devices.filter(device => {
        return device.name.toLocaleLowerCase().trim().includes(filter)
      })

      setVisibleDevices(value)
    },
    [devices],
  )

  return useMemo(() => {
    return {
      ListItem: DeviceListItem,
      visibleRows: 8,
      rowHeight: 26,
      renderText: devicesRenderText,
      onFilter: onFilter,
      values: visibleDevices,
      selectionLimit: -1,
    }
  }, [onFilter, visibleDevices, devicesRenderText])
}
