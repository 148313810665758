import type { GpsTimezone } from '../GpsAccountForm/types'

import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectUserAccount } from '@/features/domain/user'
import { updateGpsTimezone } from '@/features/domain/account'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useTexts } from './useTexts'

type AccountData = {
  email: string
  username: string
  timezone: GpsTimezone
}

export function useGpsAccountData() {
  const texts = useTexts()
  const toast = useNotification()
  const account = useSelector(selectUserAccount)
  const dispatch = useAppDispatch()

  const accountData = useMemo<AccountData>(() => {
    return {
      email: account.details.email,
      username: account.credentials.username,
      timezone: account.details.timeZoneCode as GpsTimezone,
    }
  }, [account])

  const updateAccountTimezone = useCallback(
    async (timezone: GpsTimezone) => {
      try {
        const thunkResult = await dispatch(updateGpsTimezone(timezone))

        if (!updateGpsTimezone.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }
        toast.success(texts.updateAccountSuccess, { autoClose: 5000 })

        return true
      } catch (e) {
        toast.error(texts.updateAccountError, { autoClose: 5000 })
      }

      return false
    },
    [dispatch, toast, texts],
  )

  return [accountData, updateAccountTimezone] as const
}
