import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState({
    unassigned: translate({ id: 'map.tooltip.order.status.unassigned' }),
    notDispatched: translate({ id: 'map.tooltip.order.status.notDispatched' }),
    todo: translate({ id: 'map.tooltip.order.status.todo' }),
    completed: translate({ id: 'map.tooltip.order.status.completed' }),
    notCompleted: translate({ id: 'map.tooltip.order.status.notCompleted' }),
    undeclared: translate({ id: 'map.tooltip.order.status.undeclared' }),
  })

  return api
}
