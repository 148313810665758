import type { ExtraLayoutRendererProps } from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import type { BreadcrumbChip } from '../../../components/Navigator/typings'
import type { EntitiesToShow } from '../types'

import { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'

import { setMainSelection } from '@/atoms'
import { VerticalLayout } from '@/components/layout'
import { useMouseTrap } from '@/hooks'
import { fitMap } from '@/map'

import CollapsedLayoutRenderer from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import { Navigator } from '../../../components/Navigator'

import { useTexts } from '../useTexts'
import { BreadcrumbItemRenderer } from './BreadcrumbItemRenderer'

interface Props {
  entitiesToShow: EntitiesToShow
  chips: BreadcrumbChip[]
  page: number
}

export function BreadcrumbNavigator(props: Props) {
  const { chips, page, entitiesToShow } = props

  const texts = useTexts()

  const onChangeIndex = useCallback(
    index => {
      const { id, source } = chips[index]
      setMainSelection('breadcrumbs', [id])

      fitMap([source.latLng], {
        minResolution: entitiesToShow === 'stops' ? 14 : undefined,
        preventIfVisible: true,
        duration: 300,
      })
    },
    [entitiesToShow, chips],
  )

  const renderProps = useMemo<ExtraLayoutRendererProps>(() => {
    const disableArrows = entitiesToShow === 'stops' && chips[page].source.status === 'moving'

    return {
      chipShape: 'rounded',
      rewindToFirst: true,
      showFastForward: true,
      forceDisableNext: disableArrows,
      forceDisablePrev: disableArrows,
      forceDisableNextTitle: texts.disableNext,
      forceDisablePrevTitle: texts.disablePrev,
    }
  }, [texts, entitiesToShow, chips, page])

  useMouseTrap(
    'left',
    () => {
      onChangeIndex(page - 1)
    },
    true,
    page === 0,
  )

  useMouseTrap(
    'right',
    () => {
      onChangeIndex(page + 1)
    },
    true,
    page === chips.length - 1,
  )

  return (
    <Box height={46} minWidth={100}>
      <VerticalLayout
        data-trackid="navigo-breadcrumb-navigator"
        data-testid="navigo-breadcrumb-navigator"
        justifyContent="center"
        width="auto"
      >
        <Navigator<BreadcrumbChip, ExtraLayoutRendererProps, Record<string, any>>
          items={chips}
          selectedIndex={page}
          onChangeIndex={onChangeIndex}
          extraItemRendererProps={renderProps}
          ItemRenderer={BreadcrumbItemRenderer}
          extraLayoutRendererProps={renderProps}
          LayoutRenderer={CollapsedLayoutRenderer}
        />
      </VerticalLayout>
    </Box>
  )
}
