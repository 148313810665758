import type { RadioGroupValue } from '@/forms-legacy'
import type { GeofenceFormValues } from '../../types'

import { useCallback, useMemo } from 'react'
import { addDays, addYears, format, startOfToday } from 'date-fns'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import { DateTimeSelectorField } from '@/forms-legacy'
import { timeUtils } from '@/server-data'

import { theme } from '@/styles'
import { useTexts } from './useTexts'

const today: Date = new Date()
today.setHours(0, 0, 0, 0)

export function EndDateTime() {
  const { today: todayAsString } = useSelector(selectUserConfiguration)
  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()
  const form = useForm()

  const { values } = form.getState()
  const { startDateTime, endDateTime } = values as GeofenceFormValues

  const defaultCustomDate = addDays(startDateTime, 1)

  const calendarRange = useMemo(
    () => ({
      min: startOfToday(),
      max: addYears(today, 100),
    }),
    [],
  )

  const dateRenderer = useCallback(
    (date: Date): string => timeUtils.formatDateTime(userConfig)(date),
    [userConfig],
  )

  const radioValues = useMemo(() => {
    const neverEnd: RadioGroupValue = {
      label: texts.neverEnd,
      value: 'neverEnd',
    }

    const customDate: RadioGroupValue = {
      label: texts.customDate,
      value: 'customDate',
    }

    return [neverEnd, customDate]
  }, [texts])

  return (
    <DateTimeSelectorField
      allowNullValue
      name="endDateTime"
      label={texts.endGeofence}
      innerLabels={{
        calendar: texts.endDate,
        time: texts.endTime,
      }}
      wrappedInputProps={{
        compactCalendar: {
          dateRenderer,
          limits: calendarRange,
          extraLabels: {
            weekDays: texts.weekDaysLongText,
            tomorrow: texts.tomorrow,
            today: texts.today,
          },
        },
        hourPicker: {
          h24: userConfig.timeFormat === 'HH:mm',
          name: 'endDateTimeHourPicker',
          stepSecs: 60 * 10,
        },
        calendar: {
          months: 1,
          today: todayAsString,
          minDate: format(calendarRange.min, 'yyyyMMdd'),
          maxDate: format(calendarRange.max, 'yyyyMMdd'),
        },
        nullValueButtons: {
          defaultCustomDate,
          name: 'radioButtons',
          values: radioValues,
          value: !endDateTime ? 'neverEnd' : 'customDate',
          colors: {
            background: theme.colors.$pureWhite,
            backgroundSelected: theme.colors.$nightRider,
            color: theme.colors.$nightRider,
            colorSelected: theme.colors.$pureWhite,
            border: theme.colors.$nightRider,
            borderSize: 1,
          },
        },
      }}
    />
  )
}
