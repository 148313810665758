import { Stack } from '@mui/material'
import { useResetCrudSelectionOnUnmount } from '@/atoms'

import { useSettingsEditing } from '../../hooks/useSettingsEditing'
import { useFetchUsersListOnMount } from './hooks/useFetchUsersListOnMount'
import { useMode } from './hooks/useMode'

import { SingleEdit } from './SingleEdit'
import { ListUsers } from './ListUsers'
import { ViewUsers } from './ViewUsers'
import { ViewUser } from './ViewUser'

const rootStackSx = { flex: '0 1 auto', height: '100%' }

export function UserManagement() {
  const { onDiscard } = useSettingsEditing('user-management', 'reset')

  useFetchUsersListOnMount()
  useResetCrudSelectionOnUnmount('users')

  const { mode, selection, selectedUsers } = useMode()

  const listIsDisabled = mode === 'editUser' || mode === 'createUser'

  return (
    <Stack direction="row" sx={rootStackSx} data-testid="user-management">
      <ListUsers selection={selection} selectedUsers={selectedUsers} disabled={listIsDisabled} />

      {mode === 'viewUser' && <ViewUser user={selectedUsers[0]} selectedUsers={selectedUsers} />}

      {mode === 'editUser' && (
        <SingleEdit creating={false} user={selectedUsers[0]} onDiscard={onDiscard} />
      )}

      {mode === 'createUser' && <SingleEdit creating={true} onDiscard={onDiscard} />}

      {mode === 'moreUsersSelected' && <ViewUsers users={selectedUsers} selection={selection} />}
    </Stack>
  )
}
