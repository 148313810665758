import type { BulkLoads } from '../../../../types'

import { useMemo } from 'react'

export function useReachedMaxLoads(bulkLoads: BulkLoads, maxLoadsForOrder: number) {
  const { loadsDistribution } = bulkLoads

  return useMemo(() => {
    // loadsDistribution holds a set of all the loads for each order
    return Object.values(loadsDistribution).some(
      distributionSet => distributionSet.length >= maxLoadsForOrder,
    )
  }, [loadsDistribution, maxLoadsForOrder])
}
