import { timeWindowUtils } from '@/utils'

export default function validateTimeWindow(
  tw?: uui.domain.rm.TimeWindow,
  workingDayStartSec: number = 0,
): boolean {
  if (!tw) return true

  const normalizedTw = timeWindowUtils.normalizeTimeWindow(tw, workingDayStartSec)

  return timeWindowUtils.isValid(normalizedTw, workingDayStartSec)
}
