import type { FormValues } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields } from '../../formFields'

interface Args {
  formValues: FormValues<FormFields>
  weekStartsOn: uui.domain.WeekStartsOn
  userConfiguration: uui.domain.UserConfiguration
}

export function createCreateUserPayload(
  args: Args,
): uui.domain.actions.rpc.user.CreateUserAction['payload'] {
  const { formValues, userConfiguration, weekStartsOn } = args
  const {
    name,
    surname,
    username,
    email,
    territoryFilter: territoryFilterValue,
    type,
    limitedAccess,
    courierFilter,
    companies: companiesValue,
    isCompanyFiltered,
  } = formValues

  const companies = companiesValue.map(company => company.id)

  const newPassword = formValues['new-password']

  let territoryFilter: string[]

  switch (type) {
    case 'courier':
      territoryFilter = Object.keys(courierFilter)
      break

    default:
      territoryFilter =
        limitedAccess === false ? ['*'] : territoryFilterValue.map(territory => territory.id)
      break
  }

  const baseParams = {
    name,
    surname,
    username,
    enabled: true,
    email,
    territoryFilter,
    settings: userConfiguration,
    uiData: {
      version: 1 as const,
      exportOrders: {
        format: 'CSV' as 'CSV' | 'XLSX',
        includeBarcodes: true,
      },
      exportRoutes: {
        format: 'CSV' as 'CSV' | 'XLSX',
        includePod: true,
        includeBarcodes: true,
      },
      weekStartsOn,
    },

    companies,
    isCompanyFiltered,
  }

  const user =
    type === 'courier'
      ? {
          type,
          ...baseParams,
          courierFilter,
        }
      : { type, ...baseParams }

  return {
    user,
    password: newPassword,
  }
}
