import { PureComponent } from 'react'

import {
  HorizontalRadioGroupColors,
  RadioGroupValues,
  HorizontalRadioGroupField,
} from '@/forms-legacy'
import { theme } from '@/local/components'

import * as OrderTexts from '../../../../../../intl'
import { Props } from '../typings'

const priorityColors: HorizontalRadioGroupColors = {
  background: theme.colors.$silver,
  backgroundSelected: theme.colors.$nightRider,
  color: theme.colors.$nightRider,
  colorSelected: theme.colors.$pureWhite,
  border: theme.colors.$pureWhite,
  borderSize: 3,
}

const getPriorityValues = (): RadioGroupValues => [
  {
    label: OrderTexts.getPriorityLowestText(),
    value: -20,
  },
  {
    label: OrderTexts.getPriorityLowerText(),
    value: -10,
  },
  {
    label: OrderTexts.getPriorityNormalText(),
    value: 0,
  },
  {
    label: OrderTexts.getPriorityHigherText(),
    value: 10,
  },
  {
    label: OrderTexts.getPriorityHighestText(),
    value: 20,
  },
]

const stringToNumber = (value: string): number => parseInt(value, 10)

export default class PriorityField extends PureComponent<Props> {
  private readonly priorityValues: RadioGroupValues

  constructor(props: Props) {
    super(props)
    this.priorityValues = getPriorityValues()
  }

  render() {
    return (
      <HorizontalRadioGroupField
        name="order.priority"
        label={OrderTexts.geFieldLabelPriorityText()}
        parse={stringToNumber}
        wrappedInputProps={{
          hideOuterBorder: true,
          hideSelectionUnderline: true,
          colors: priorityColors,
          values: this.priorityValues,
        }}
        testid="priority"
      />
    )
  }
}
