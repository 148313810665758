export const enum KeyboardDirections {
  UP,
  RIGHT,
  DOWN,
  LEFT,
}

export enum KeyboardEventKeys {
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Escape = 'Escape',
  Enter = 'Enter',
  SpaceBar = ' ',
}

export interface ModifierKeys {
  altKey?: boolean
  ctrlKey?: boolean
  metaKey?: boolean
  shiftKey?: boolean
}
