import type { Props } from './typings'

import { Item } from './Item'
import { Header } from './Header'

export function ItemRendererDefault(props: Props) {
  const { item, ...p } = props

  if (item.kind === 'header') {
    return <Header {...p} item={item} />
  }

  return <Item {...p} item={item} />
}
