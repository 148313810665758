import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Content } from './Content'

interface Props {
  tenantSource: uui.domain.server.gps.telematics.TenantSource
}

export function Root(props: Props) {
  const { tenantSource } = props

  return (
    <Modal modalId={modalId}>
      <Content tenantSource={tenantSource} />
    </Modal>
  )
}
