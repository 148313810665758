import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'scheduler.popContent.break.title' }),
    startAt: translate({ id: 'scheduler.popContent.break.startAt' }),
    timeSpan: translate({ id: 'scheduler.popContent.break.timeSpan' }),
    endAt: translate({ id: 'scheduler.popContent.break.endAt' }),
  }))

  return api
}
