import styled from 'styled-components'

type Props = {
  small?: boolean
}

export const ReadonlyFieldRowRightSideLabel = styled.span<Props>`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  padding-left: 8px;

  width: ${p => (p.small ? '105px' : '56%')};
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background: ${p => p.theme.colors.$pureWhite};
  }
`

ReadonlyFieldRowRightSideLabel.displayName = 'ReadonlyFieldRowRightSideLabel'
