import { useJournal } from '@/store'
import { useReadOnly } from '@/hooks'
import { useTexts } from './useTexts'

type ReadOnlyTooltipData = {
  title: string
  subtitle?: string
} & (
  | {
      mode:
        | 'courier'
        | 'rmViewer'
        | 'rmGuest'
        | 'archived'
        | 'externalTransaction'
        | 'offline'
        | 'unknown'
      preventEditing: true
    }
  | {
      mode: 'controlled'
      preventEditing: boolean
    }
  | {
      mode: 'canEdit'
      preventEditing: false
    }
)

export type ReadOnlyReason =
  | 'canEdit'
  | 'offline'
  | 'rmGuest'
  | 'unknown'
  | 'courier'
  | 'rmViewer'
  | 'archived'
  | 'controlled'
  | 'externalTransaction'

export interface EditRule {
  canEdit: boolean
  title: string
  subtitle?: string
}

/**
 * Get the info needed to show/hide a read-only tooltip, considering some read-only levels:
 *
 * 1. the global ones: in some conditions, the plan can't be edited (such as there is an ongoing external
 * transaction, the user doesn't have the writing permissions, etc.)
 *
 * @example
 * const { preventEditing, title, subtitle } = useReadOnlyTooltipData()
 *
 *
 * 2. a provided rule: the consumer can pass a custom, context-aware, rule to prevent editing. The
 * rule is checked only if there aren't any app-wide read-only constraints
 * checked if there is not.
 *
 * @example
 * const { preventEditing, mode, title, subtitle } = useReadOnlyTooltipData({
 *   canEdit: isWorkingDay,
 *   tile: 'You cannot edit the plan during the weekends',
 * })
 *
 *
 * @param {EditRule} [editRule=undefined]
 *
 * @returns The detailed data to disable an edit action and show a tooltip.
 */
export function useReadOnlyTooltipData(
  editRule?: EditRule,
  ignoreReasons?: ReadOnlyReason[],
): ReadOnlyTooltipData {
  const { cause } = useReadOnly()
  const journal = useJournal()

  const texts = useTexts()

  if (cause === 'rmViewer' && !ignoreReasons?.includes('rmViewer'))
    return {
      mode: 'rmViewer',
      preventEditing: true,
      title: texts.disableEditForViewersTitle,
    }

  if (cause === 'courier' && !ignoreReasons?.includes('courier'))
    return {
      mode: 'courier',
      preventEditing: true,
      title: texts.disableEditForCouriersTitle,
    }

  if (cause === 'rmGuest' && !ignoreReasons?.includes('rmGuest'))
    return {
      mode: 'rmGuest',
      preventEditing: true,
      title: texts.disableEditForGuestsTitle,
    }

  if (cause === 'archived' && !ignoreReasons?.includes('archived'))
    return {
      mode: 'archived',
      preventEditing: true,
      title: texts.disableEditForArchivedDaysTitle,
    }

  if (cause === 'externalTransaction' && !ignoreReasons?.includes('externalTransaction'))
    return {
      mode: 'externalTransaction',
      preventEditing: true,
      title: texts.transactionInProgressTitle,
      subtitle: texts.transactionInProgressSubtitle,
    }

  if (cause === 'editable' && !!editRule) {
    // The title is mandatory if the edit action must be disabled
    if (!editRule.canEdit && !editRule.title) {
      journal.ui('No read-only tooltip title available', { info: { editRule } }, 'warn')

      if (process.env.NODE_ENV === 'development') {
        throw new Error('No "read-only" tooltip title available')
      }
    }

    return {
      mode: 'controlled',
      preventEditing: !editRule.canEdit,
      title: editRule.title || '',
      subtitle: editRule.subtitle,
    }
  }

  if (cause === 'offline' && !ignoreReasons?.includes('offline'))
    return {
      mode: 'offline',
      preventEditing: true,
      title: texts.disableEditForOffline,
    }

  if (cause === 'unknown' && !ignoreReasons?.includes('unknown'))
    return {
      preventEditing: true,
      title: 'Unknown',
      mode: 'unknown',
    }

  return {
    mode: 'canEdit',
    preventEditing: false,
    title: '',
    subtitle: '',
  }
}
