import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    labelName: translate({ id: 'setup.regions.edit.labels.name' }),
    labelColor: translate({ id: 'setup.regions.edit.labels.color' }),
    editTitle: translate({ id: 'setup.regions.edit.title' }),
    labelCost: translate({ id: 'setup.regions.edit.labels.cost' }),
    labelTime: translate({ id: 'setup.regions.edit.labels.time' }),
    labelPoints: translate({ id: 'setup.regions.edit.labels.points' }),

    errorName: translate({ id: 'setup.regions.edit.validation.name' }),
    errorCost: translate({ id: 'setup.regions.edit.validation.cost' }),
    errorTime: translate({ id: 'setup.regions.edit.validation.time' }),
    errorPoints: translate({ id: 'setup.regions.edit.validation.points' }),

    headerTitle: (name: string) => name || translate({ id: 'setup.regions.unnamedRegion' }),
    headerDescription: translate({ id: 'global.regions' }),
    footerTitle: translate({ id: 'setup.regions.edit.formErrorsTitle' }),
    submit: (id: string) =>
      id.length === 0
        ? translate({ id: 'setup.regions.edit.create' })
        : translate({ id: 'setup.regions.edit.save' }),
    submitTitle: (valid: boolean, planLocked: boolean, pristine: boolean) => {
      if (!valid) {
        return translate({
          id: 'regions.form.submitButton.disabled.title.invalid',
        })
      }
      if (planLocked) {
        return translate({
          id: 'regions.form.submitButton.disabled.title.planLocked',
        })
      }

      if (pristine) {
        return translate({
          id: 'regions.form.submitButton.disabled.title.pristine',
        })
      }
      return ''
    },
    cancel: translate({ id: 'global.cancel' }),
    change: translate({ id: 'global.change' }),

    creatingPolygon: [
      translate({ id: 'setup.regions.edit.creatingPolyRow1' }),
      translate({ id: 'setup.regions.edit.creatingPolyRow2' }),
      translate({ id: 'setup.regions.edit.creatingPolyRow3' }),
      translate({ id: 'setup.regions.edit.creatingPolyRow4' }),
    ],
    editingPolygon: [
      translate({ id: 'setup.regions.edit.editPolyRow1' }),
      translate({ id: 'setup.regions.edit.editPolyRow2' }),
      translate({ id: 'setup.regions.edit.editPolyRow3' }),
      translate({ id: 'setup.regions.edit.editPolyRow4' }),
    ],
  }))

  return api
}
