import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserProfile, isGpsOnly } from '@/features/domain/user'

export function useAccountEmail() {
  const userProfile = useSelector(selectUserProfile)
  const gpsOnly = useSelector(isGpsOnly)

  return useMemo(() => {
    const gpsOnlyEmail = userProfile?.gpsUser?.email
    const rmEmail = userProfile.user.email

    return gpsOnly && !!gpsOnlyEmail ? gpsOnlyEmail : rmEmail
  }, [userProfile, gpsOnly])
}
