import { mapAtom } from '../../../atoms/map/map'
import { findLayer } from '../../utils/findLayer'

export function removeInspectPinFeature() {
  const map = mapAtom.map
  const layer = findLayer(map, 'pin')
  const feature = layer.getSource()?.getFeatureById('inspectPin')

  if (feature) {
    layer.getSource()?.removeFeature(feature)
  }
}
