import type { FormErrors, FormFields } from '../../../formFields'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { selectUserConfiguration } from '@/features/domain/user'

export function useConnectedToStatusFields() {
  const { field, formApi } = useFormField<'status', FormFields, FormErrors>('status')
  const { userType } = useSelector(selectUserConfiguration)
  const is360 = userType === 'rm360' || userType === 'rm360Legacy'
  const fieldValue = field.value

  useEffect(() => {
    if (fieldValue === 'ADVANCED' && is360) {
      //TODO: Re-enable that field once it will be available
      // formApi.setFieldVisibleState('forbidDriverCallsToCompletedOrderSteps', true)
      // formApi.setFieldVisibleState('forbidDriverCallsToIncompleteOrderSteps', true)

      formApi.setFieldVisibleState('callbackConfigStatus', true)
      formApi.setFieldVisibleState('backOfficePhone', true)
    } else {
      //TODO: Re-enable that field once it will be available
      // formApi.setFieldVisibleState('forbidDriverCallsToCompletedOrderSteps', false)
      // formApi.setFieldVisibleState('forbidDriverCallsToIncompleteOrderSteps', false)

      formApi.setFieldVisibleState('callbackConfigStatus', false)
      formApi.setFieldVisibleState('backOfficePhone', false)
    }
  }, [fieldValue, formApi, is360])
}
