import { intl } from '@/intl'

import { validateDriverBehaviorsData } from './validateDriverBehaviorsData'

export function validateDriverBehaviors(
  fullBehaviorsData: uui.domain.ui.forms.FullBehaviorsData,
  hasDriverBehaviorCapability: boolean,
): string | undefined {
  const { hard, harsh, severe } = fullBehaviorsData
  const hardValidationValue = validateDriverBehaviorsData(hard, fullBehaviorsData)
  const harshValidationValue = validateDriverBehaviorsData(harsh, fullBehaviorsData)
  const severeValidationValue = validateDriverBehaviorsData(severe, fullBehaviorsData)

  if (!hasDriverBehaviorCapability) return

  if (typeof hardValidationValue === 'string') {
    return hardValidationValue
  }

  if (typeof harshValidationValue === 'string') {
    return harshValidationValue
  }

  if (typeof severeValidationValue === 'string') {
    return severeValidationValue
  }

  if (hard < harsh && harsh < severe) {
    return
  }

  return intl.translate({
    id: 'vehicles.form.edit.generalSettings.validation.driverBehaviors.range.noConsistent',
  })
}
