import type { AsyncThunkApiConfig } from '@/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type CountryCode = string | undefined

export const resetNotificationEmailCounter = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  CountryCode,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/account/resetNotificationEmailCounter', async (countryCode, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/account/resetNotificationEmailCounter', {
      type: 'rpc/account/resetNotificationEmailCounter',
      category: 'rpc',
      countryCode,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        error: result,
        message: result.errorMessage,
      })
    }
  } catch (error) {
    return thunkApi.rejectWithValue({
      error,
      type: 'unknown',
      message: 'Failed to fetch the Account Data',
    })
  }
})
