import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../../hooks/useController'

import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'

export function Footer() {
  const { status, close } = useController()

  const texts = useTexts()
  const submit = useOnSubmit()

  const submitting = status === 'submitting'

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          color="error"
          variant="contained"
          onClick={submit}
          loading={submitting}
          disabled={submitting}
          maxWidth="50%"
          testid="settings-rm-account-api-key-modal-reset-button"
        >
          {texts.reset}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          variant="text"
          onClick={close}
          disabled={submitting}
          testid="settings-rm-account-api-key-modal-cancel-button"
        >
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}
