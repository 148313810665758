import type { TerritoryConfig as TerritoryConfigType } from '../../../../../../../../../types'

import { useMemo, useCallback } from 'react'

export type Option = { label: string; value: string } & (
  | {
      special: false
    }
  | {
      special: true
      ids: string[]
      deselect?: boolean
    }
)

function getOptionLabel(opt: Option) {
  return opt.label
}

function isOptionEqualToValue(optA: Option, optB: Option) {
  return optA.value === optB.value
}

const autocompleteProps = { isOptionEqualToValue }

export function useAutocompleteProps(
  territoryId: string,
  fieldValue: TerritoryConfigType,
  onChangeField: (value: TerritoryConfigType) => void,
  territoriesConfig: uui.domain.client.rm.TerritoriesDriversAndVehicles,
) {
  const onChange = useCallback(
    (value: Option[]) => {
      onChangeField({ ...fieldValue, driverIds: value.map(val => val.value) })
    },
    [onChangeField, fieldValue],
  )

  const items = useMemo(() => {
    const driversMap = territoriesConfig[territoryId]?.drivers ?? {}

    return Object.entries(driversMap)
      .map(([id, label]) => ({
        label,
        value: id,
      }))
      .sort(sortOptions)
  }, [territoriesConfig, territoryId])

  const value = useMemo<Option[]>(() => {
    const driversMap = territoriesConfig[territoryId]?.drivers ?? {}
    const defaultLabel = process.env.NODE_ENV === 'development' ? 'not-found' : ''

    return fieldValue.driverIds.map(id => ({
      label: driversMap[id] ?? defaultLabel,
      value: id,
      special: false,
    }))
  }, [fieldValue, territoryId, territoriesConfig])

  const id = `${territoryId}-driver-autocomplete`

  return {
    id,
    value,
    items,
    onChange,
    getOptionLabel,
    autocompleteProps,
  }
}

function sortOptions(a: Option, b: Option) {
  return a.label.localeCompare(b.label)
}
