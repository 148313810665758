import { useAllCrudSelection, useAllMainSelection } from '@/atoms'
import { useMapSelectionContext } from '@/map'

export function useFitAll() {
  const [mainSelection] = useAllMainSelection()
  const [crudSelection] = useAllCrudSelection()
  const { type } = useMapSelectionContext()

  switch (type) {
    case 'crud':
      for (const category in crudSelection) {
        if (crudSelection[category].length) {
          return true
        }
      }

      return false

    case 'main':
      for (const category in mainSelection) {
        if (mainSelection[category].length) {
          return true
        }
      }

      return false

    case 'none':
    default:
      return false
  }
}
