import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Text } from '@/local/components'
import { Tooltip } from '@/components/primitives/Tooltip'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'
import { VerticalLayout, HorizontalLayout, Grow } from '@/components/layout'
import { getMatchingRecurrenceByDate, hasTrackingLicense } from '@/server-data'

import { useTexts } from '../../useTexts'

import { LastPosition } from './components/LastPosition'

interface Props {
  uv: uui.domain.client.UnifiedVehicle
}

const growStyle = { padding: '2px 0', maxWidth: 260, minWidth: 260, height: '100%' }

export function Single(props: Props) {
  const { uv: vehicle } = props

  const calendarRange = useSelector(selectCalendarRange)
  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const gps = useMemo(
    () =>
      hasTrackingLicense(
        vehicle,
        vehicle.hasRoutingLicense
          ? getMatchingRecurrenceByDate(
              calendarRange.minDate,
              Object.keys(vehicle.driversByRecurrence),
            )
          : undefined,
      ),
    [vehicle, calendarRange],
  )

  const name = vehicle.hasRoutingLicense ? vehicle.vehicle.externalId : ''

  const isToday =
    calendarRange.minDate === calendarRange.maxDate && calendarRange.minDate === userConfig.today

  const noLivePositionText = !gps ? texts.disabledNoDevice(name) : texts.livePositionNotAvailable

  return (
    <Grow
      data-trackid="navigo-vehicle-overview-details-single"
      data-testid="navigo-vehicle-overview-details-single"
      style={growStyle}
      xAxis
    >
      <VerticalLayout justifyContent="space-between">
        <HorizontalLayout alignItems="center" height="auto">
          <div style={{ overflow: 'hidden' }}>
            <Tooltip title={name} placement="top">
              <Text
                data-trackid="navigo-vehicle-overview-details-single-name"
                data-testid="navigo-vehicle-overview-details-single-name"
                size="$xl"
                weight="$semiBold"
                ellipsis
              >
                {name}
              </Text>
            </Tooltip>
          </div>
        </HorizontalLayout>

        {isToday && gps ? (
          <LastPosition vehicle={vehicle} />
        ) : (
          <div style={{ overflow: 'hidden' }}>
            <Tooltip title={noLivePositionText} placement="top">
              <Text
                data-trackid="navigo-vehicle-overview-details-single-lastPosition"
                data-testid="navigo-vehicle-overview-details-single-lastPosition"
                size="$h6"
                italic
                ellipsis
              >
                {noLivePositionText}
              </Text>
            </Tooltip>
          </div>
        )}
      </VerticalLayout>
    </Grow>
  )
}
