import type { DropdownItem } from '@/forms-legacy'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDevices } from '@/features/domain/device'

import { useAvailableDevices } from './useAvailableDevices'

export function useDeviceDropdownValues() {
  const devices = useSelector(selectDevices)
  const availableDevices = useAvailableDevices()

  return useMemo(() => {
    return Object.values(devices).reduce<DropdownItem[]>((acc, device) => {
      if (device.virtual) return acc
      acc.push({
        label: device.label,
        id: device.deviceId,
        disabled: !availableDevices.includes(device.deviceId),
      })
      return acc
    }, [])
  }, [availableDevices, devices])
}
