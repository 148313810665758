import type { View } from './types'

import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { SecondaryColumnHeader, FormBulkAvatar } from '@/forms-legacy'
import { resetCrudSelection } from '@/atoms'
import { selectUserConfigurationType } from '@/features/domain/user'
import { FormColumn } from '@/components/layout'

import { useTexts } from './hooks/useTexts'
import { useVehiclesType } from './hooks/useVehiclesType'

import { ActionsManager } from './ActionsManager'

interface Props {
  vehicles: uui.domain.client.UnifiedVehicle[]
}

export function BulkView(props: Props) {
  const { vehicles } = props

  const [view, setView] = useState<View>('default')

  const userType = useSelector(selectUserConfigurationType)
  const vehiclesType = useVehiclesType(vehicles)
  const onResetSelection = useCallback(() => {
    resetCrudSelection('unifiedVehicles')
  }, [])

  const texts = useTexts()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.headerTitle(vehicles.length)}
        description={texts.headerDescription(vehiclesType)}
        action={onResetSelection}
        avatar={<FormBulkAvatar size={vehicles.length} />}
      />
      {userType !== 'gpsOnly' && (
        <ActionsManager unifiedVehicles={vehicles} view={view} setView={setView} />
      )}
    </FormColumn>
  )
}
