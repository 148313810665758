import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function CloseoutTime(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 4C26.2091 4 28 5.79086 28 8V24C28 26.2091 26.2091 28 24 28H8C5.79086 28 4 26.2091 4 24V8C4 5.79086 5.79086 4 8 4H24ZM18.2703 16.8395C17.9919 16.5147 17.9919 16.0355 18.2703 15.7107L21.4645 11.9842C21.9885 11.3728 22.9902 11.7434 22.9902 12.5486L22.9902 20.0016C22.9902 20.8068 21.9885 21.1774 21.4645 20.566L18.2703 16.8395ZM12.1993 16.8395C11.9209 16.5147 11.9209 16.0355 12.1993 15.7107L15.3935 11.9842C15.9175 11.3728 16.9192 11.7434 16.9192 12.5486L16.9192 20.0016C16.9192 20.8068 15.9175 21.1774 15.3935 20.566L12.1993 16.8395ZM6.1283 15.7107C5.84991 16.0355 5.84991 16.5147 6.12831 16.8395L9.32245 20.566C9.84647 21.1774 10.8482 20.8068 10.8482 20.0016V12.5486C10.8482 11.7434 9.84646 11.3728 9.32245 11.9842L6.1283 15.7107Z"
        />
      </svg>
    </IconContainer>
  )
}
