import type { Filter, Format } from '../typings'

import { useEffect, useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

import { useData } from './useData'

export type Column = {
  id: string
  label: string
}

type ColumnsState =
  | {
      status: 'not-requested'
    }
  | {
      status: 'loading'
    }
  | {
      status: 'complete'
      columns: Column[]
    }
  | {
      status: 'error'
    }

type ModalState = 'ready' | 'pending' | 'invalidForEmptyRoutes' | 'invalidForSimulationEnvironment'
export interface ModalData {
  dates: string[]
  filter: Filter
  format: Format
  includePod: boolean
  singleFile: boolean
  wizardStep: 'configure-territories' | 'configure-format' | 'configure-columns'
  canExportPod: boolean
  columnsState: ColumnsState
  isSimulation: boolean
  territoryIds: string[]
  multiTerritory: boolean
  columnSelection: string[]
  includeBarcodes: boolean
  customizeColumns: boolean
  keepEnglishColumns: boolean
  territoriesErrorText?: string
  showEmptySelectionWarning: boolean
}

export const modalId = 'exportRoutesFromCalendar'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = (dates: string[], multiTerritory: boolean) => {
  const {
    canExportInSimulation,
    keepEnglishColumns,
    customizeColumns,
    includeBarcodes,
    currentRoutes,
    isSimulation,
    canExportPod,
    includePod,
    singleFile,
    format,
  } = useData(dates)

  const close = useCloseModal()

  // INITIALIZATION

  const wizardStep = multiTerritory
    ? ('configure-territories' as const)
    : ('configure-format' as const)

  const filter = currentRoutes ? ('selected' as const) : ('dispatched' as const)

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        dates,
        format,
        filter,
        includePod: currentRoutes ? includePod : false,
        singleFile,
        wizardStep,
        isSimulation,
        canExportPod,
        territoryIds: [],
        currentRoutes,
        multiTerritory,
        includeBarcodes,
        customizeColumns,
        keepEnglishColumns,
        canExportInSimulation,
        showEmptySelectionWarning: false,
        columnsState: {
          status: 'not-requested' as const,
        },
        columnSelection: [],
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const { update } = ctrl

  // Invalid state management
  useEffect(() => {
    if (isSimulation && !canExportInSimulation) {
      update({ invalid: true, status: 'invalidForSimulationEnvironment' })
      return
    }

    update({ invalid: false, status: 'ready' })
  }, [isSimulation, canExportInSimulation, update])

  return ctrl
}
