import type { CSSProperties } from 'react'
import type { Props } from './typings'

import { ReadonlyLabel } from '@/forms-legacy'
import { useToggle } from '@/hooks'

import * as Texts from '../../intl'

import { CustomFieldItem } from '../CustomFieldItem'
import SmallLabel from '../SmallLabel'

import SuggestionsListContainer from './SuggestionsListContainer'

const ellipsisStyle: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const LIMIT = 7

export function SuggestionsList(props: Props) {
  const { list, onAdd } = props
  const [useLimit, toggleUseLimit] = useToggle(true)

  const moreCount: number = list.length - (LIMIT + 1)
  const hasListItems: boolean = list.length > 0

  const showLoadMore: boolean = useLimit && moreCount > 0

  return (
    <>
      {hasListItems && (
        <>
          <ReadonlyLabel style={{ marginTop: 10 }}>
            {Texts.getAvailableCustomFieldsText(list.length)}
          </ReadonlyLabel>
          <SuggestionsListContainer>
            {list.map((l: string, idx: number) => {
              if (useLimit && idx > LIMIT) {
                return null
              }
              return (
                <CustomFieldItem key={l} onClick={() => onAdd(l)}>
                  <span style={ellipsisStyle}>{l}</span>
                </CustomFieldItem>
              )
            })}
            {showLoadMore && (
              <CustomFieldItem onClick={toggleUseLimit}>
                {Texts.getLoadMoreText(moreCount)}
              </CustomFieldItem>
            )}
          </SuggestionsListContainer>
          <SmallLabel>{Texts.getBottomMessageText()}</SmallLabel>
        </>
      )}
      {!hasListItems && <SmallLabel>{Texts.getNoResultText()}</SmallLabel>}
    </>
  )
}
