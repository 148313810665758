import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    canUnassignTooltipTitle: translate({
      id: 'orders.action.unassign.tooltip.disabledForLockedRoute',
      values: { count: 1 },
    }),
    duplicateDisabledTooltipTitle: (isSimulation: boolean) => {
      const id = isSimulation
        ? 'orders.duplicate.disabled.simulations.title'
        : 'orders.duplicate.disabled.operations.title'
      return translate({ id })
    },

    duplicateDisabledTooltipSubtitle: (isSimulation: boolean, count: number) => {
      const id = isSimulation
        ? 'orders.duplicate.disabled.simulations.subtitle'
        : 'orders.duplicate.disabled.operations.subtitle'
      return translate({ id, values: { count } })
    },
    editBtnTitle: (vehicleTracked: boolean) =>
      vehicleTracked
        ? ''
        : translate({ id: 'orders.executionEvents.action.edit.noVehicleTracked' }),
  }))

  return api
}
