import type { ReactChild } from 'react'

interface Props {
  children: ReactChild
}

const style = { paddingRight: 12 }

export function IconContainer(props: Props) {
  return <span style={style}>{props.children}</span>
}
