import { useCallback } from 'react'

import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from '@/store'

import { validateEmail } from '@/features/domain/generic/actions'

const cachedResults: Map<string, boolean> = new Map()

export const useValidateEmail = () => {
  const dispatch = useAppDispatch()

  const validationFunction = useCallback<(email: string) => Promise<boolean>>(
    async (email: string) => {
      // TS is not branching if conditions correctly using `cachedResults.has(email)`
      const cachedResult = cachedResults.get(email)
      if (cachedResult !== undefined) {
        return cachedResult
      }

      const validationEmailRequest = await dispatch(validateEmail([email]))
      if (validateEmail.fulfilled.match(validationEmailRequest)) {
        const valid = unwrapResult(validationEmailRequest)[email]

        cachedResults.set(email, valid)

        return valid
      }

      throw new Error('Email validation error')
    },
    [dispatch],
  )

  return validationFunction
}
