import type { RenderItemProps } from '@/components/StructuredVirtualList'

import { useMemo } from 'react'
import { OrderListItem } from './OrderListItem'

type Props = {
  workingDayStartSec: number
  userConfiguration: uui.domain.UserConfiguration
  routes: Record<string, uui.domain.client.rm.ExtendedRoute>
}

export const itemHeight = 80
export function useOrderListItem(props: Props) {
  const { workingDayStartSec, userConfiguration, routes } = props

  return useMemo(
    () => (props: RenderItemProps<'orderSteps'>) => {
      const route =
        props.type === 'item'
          ? props.item.isUnassigned
            ? undefined
            : routes[props.item.routeId]
          : undefined

      return props.type === 'item' ? (
        <OrderListItem
          item={props.item}
          route={route}
          selected={props.selected}
          onItemClick={props.onItemClick}
          userConfiguration={userConfiguration}
          workingDayStartSec={workingDayStartSec}
          onItemDoubleClick={props.onItemDoubleClick}
        />
      ) : null
    },
    [userConfiguration, workingDayStartSec, routes],
  )
}
