import { Actions } from './Actions'
import { ConfirmPanel } from './ConfirmPanel'

interface Props {
  event: uui.domain.client.gps.wwgps.EventInfo
  view: 'default' | 'delete'
  setView: (view: 'default' | 'delete') => void
}

export function ActionsManager(props: Props) {
  const { view } = props

  switch (view) {
    case 'delete':
      return <ConfirmPanel {...props} />
    case 'default':
      return <Actions {...props} />
  }
}
