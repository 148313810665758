import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function BreadcrumbParking(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 26 26">
        <path d="M11.9107692,18 L11.9107692,14.5138462 L13.2646154,14.5138462 C15.5661538,14.5138462 17.6646154,13.3461538 17.6646154,10.6553846 C17.6646154,7.88 15.6338462,7 13.2646154,7 L9,7 L9,18 L11.9107692,18 Z M13.1292308,12.2123077 L11.9107692,12.2123077 L11.9107692,9.30153846 L13.1292308,9.30153846 C14.2123077,9.30153846 14.8215385,9.67384615 14.8215385,10.6553846 C14.8215385,11.6369231 14.28,12.2123077 13.1292308,12.2123077 Z" />
      </svg>
    </IconContainer>
  )
}
