import type { FormFields, FormErrors } from '../../../hooks/formFields'

import { useCallback } from 'react'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { exportPlaces } from '@/features/domain/place'
import { unproxify } from '@/utils'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../../../useTexts'

export function useOnSubmit(placeIds: string[]) {
  const formApi = useFormApi<FormFields, FormErrors>()
  const { update, close } = useController()
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  // track the mounted state of the parent component
  const isUnmounted = useIsUnmounted()

  return useCallback(async () => {
    // Set FormState into submitting mode
    formApi.setSubmitting(true)
    update({ status: 'submitting' })

    // verify if the form is already valid
    const formIsValidBeforeSubmitting = formApi.getMeta().status === 'valid'

    // Validate the form if necessary, and wait for the validation result
    const formIsValid = formIsValidBeforeSubmitting || (await formApi.validate())

    // if the component has been unmounted during the async operation stop here
    if (isUnmounted()) return

    if (formIsValid) {
      try {
        const emailAddress = formApi.getField('email').value

        const thunkResult = await dispatch(
          exportPlaces({ placeIds: unproxify(placeIds), emailAddress }),
        )

        if (exportPlaces.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        // Clear FormState submitting mode
        formApi.setSubmitting(false)
        toast.success(texts.success)
        close?.()
      } catch (error) {
        // Clear FormState submitting mode
        formApi.setSubmitting(false)
        toast.error(texts.failure)
      }
    } else {
      // Clear FormState submitting mode
      formApi.setSubmitting(false)
      update({ status: 'ready' })
    }
  }, [formApi, dispatch, update, texts, toast, close, isUnmounted, placeIds])
}
