import type { FormFields, FormErrors } from './formFields'

import { Box } from '@mui/material'

import { FormValues } from '@workwave-tidal/tidal/form-fairy'
import { Form, FormHeader } from '@workwave-tidal/tidal/form-ui/layout'
import { FormValidationIndicator } from '@/formUi'

import { useTexts } from './hooks/useTexts'
import { useOnSubmit } from './hooks/useOnSubmit'
import { FormFooter } from './components/FormFooter'

import { Username } from './components/fields/Username'
import { Surname } from './components/fields/Surname'
import { Email } from './components/fields/Email'
import { Name } from './components/fields/Name'

type Props = {
  onDiscard: () => void
  updateAccount: (data: FormValues<FormFields>) => Promise<boolean>
}

export function RmAccountForm(props: Props) {
  const { onDiscard, updateAccount } = props

  const texts = useTexts()

  // create a submit function linked to
  // - the domain update function
  // - the form API
  // - the application editing status
  const onSubmit = useOnSubmit(updateAccount, onDiscard)

  return (
    <Box width={360} height="100%" data-testid="form__column-secondary" display="flex" paddingY={2}>
      <Form
        header={<FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />}
        footer={<FormFooter onDiscard={onDiscard} onSubmit={onSubmit} />}
        testId="rm-account-form"
        onEnter={onSubmit}
        fieldsSpacing={6}
        fieldsPadding="0 32px"
      >
        <FormValidationIndicator<FormFields, FormErrors> size={100} />

        <Name />

        <Surname />

        <Email />

        <Username />
      </Form>
    </Box>
  )
}
