import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useRequestChangePlan } from '../hooks/useRequestChangePlan'
import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

function openInfoPage() {
  const link = 'https://www.workwave.com/route-manager/sms-pricing/'
  window.open(link)
}

export function Footer() {
  const requestChangePlan = useRequestChangePlan()
  const { status, close } = useController()
  const texts = useTexts()

  const isLoading = status === 'submitting'

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          testid="settings-messaging-plan-change-plan-modal-request-button"
          onClick={requestChangePlan}
          disabled={isLoading}
          variant="contained"
          maxWidth="50%"
        >
          {texts.requestPlan}
        </ModalFooterButton>
      }
      tertiaryAction={
        <ModalFooterButton
          testid="settings-messaging-plan-change-plan-modal-more-info-button"
          onClick={openInfoPage}
          disabled={isLoading}
        >
          {texts.moreInfo}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          testid="settings-messaging-plan-change-plan-modal-cancel"
          onClick={close}
          variant="text"
        >
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}
