import type { FilterOptionsState } from '@mui/material'
import type { AddLoadOption as Option } from '../../../types'

import { useCallback } from 'react'

import { createFilterOptions } from '@mui/material'

import { useTexts } from './useTexts'

const filter = createFilterOptions<Option>()

export function useFilterOptions() {
  const texts = useTexts()

  return useCallback(
    (options: Option[], params: FilterOptionsState<Option>) => {
      const filtered = filter(options, params)

      const { inputValue } = params
      // Suggest the creation of a new value
      const isExisting = options.some(option => inputValue === option.label)
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          shareCount: -1,
          inputValue,
          label: texts.addOptionLabel(inputValue),
          newOption: true,
        })
      }

      return filtered
    },
    [texts],
  )
}
