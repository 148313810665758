import type { FormFields, FormErrors } from '../../../formFields'

import { BulkAutocomplete } from '../../primitives/BulkAutocomplete'
import { useTexts } from './useTexts'

export function ServiceTime() {
  const texts = useTexts()

  return (
    <BulkAutocomplete<'serviceTimeSec', FormFields, FormErrors>
      name="serviceTimeSec"
      mixedLabel={texts.mixed}
      label={texts.serviceTime}
      options={options}
      validateOn="blur"
      placeholder="hh:mm"
      testId="serviceTimeSec"
    />
  )
}

const options = [
  '00:01',
  '00:05',
  '00:10',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:30',
  '02:00',
  '04:00',
]
