import type { ItemWithPosition, ScrollUpdateMode } from '../typings'
import { maintainScrollOnEndsUpdate } from './maintainScrollOnEndsUpdate'
import { getItemIdOrGroupHeaderId } from './guards'

type Params<T> = {
  scrollHeight: number
  currentScroll: number
  prevStringifiedIds: string
  getItemId: (item: T) => string
  newItems: ItemWithPosition<T>[]
  scrollUpdateMode: ScrollUpdateMode
  prevVisibleItems: ItemWithPosition<T>[]
}

type MaintainScrollResult<T> =
  | {
      success: true
      itemToMaintainVisible: ItemWithPosition<T>
    }
  | {
      success: false
    }

export const getNewScrollValue = <T>(params: Params<T>): number => {
  const { scrollUpdateMode } = params

  if (scrollUpdateMode.type === 'alwaysReset') {
    return 0
  }

  if (scrollUpdateMode.type === 'doNothing') {
    return params.currentScroll
  }

  let result: MaintainScrollResult<T> = {
    success: false,
  }

  if (scrollUpdateMode.type === 'updateScrollWhenEndsUpdate') {
    result = maintainScrollOnEndsUpdate({
      ...params,
      scrollUpdateMode,
    })
  }

  if (!result.success) {
    return 0
  }

  const { itemToMaintainVisible } = result
  const itemToMaintainVisibleUpdated = params.newItems.find(
    ({ item }) =>
      getItemIdOrGroupHeaderId(item, params.getItemId) ===
      getItemIdOrGroupHeaderId(itemToMaintainVisible.item, params.getItemId),
  )
  if (!itemToMaintainVisibleUpdated) {
    return 0
  }

  const { currentScroll } = params
  const topOffset = currentScroll - itemToMaintainVisible.y
  return itemToMaintainVisibleUpdated.y + topOffset
}
