import { useCallback, useState } from 'react'
import { updateMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'showRoutePaths' | 'hideRoutePaths'
export function useActions() {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const showRoutePaths = useCallback(async () => {
    setMethodInExecution('showRoutePaths')

    await dispatch(updateMapStyle({ type: 'routePolyline', mode: 'on' }))

    setMethodInExecution(null)
  }, [dispatch])

  const hideRoutePaths = useCallback(async () => {
    setMethodInExecution('hideRoutePaths')

    await dispatch(updateMapStyle({ type: 'routePolyline', mode: 'off' }))

    setMethodInExecution(null)
  }, [dispatch])

  return { methodInExecution, actions: { showRoutePaths, hideRoutePaths } }
}
