import { useCallback } from 'react'

import {
  SecondaryColumnHeaderActionsRow,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsSeparator,
} from '@/forms-legacy'

import { Trash, Edit, AddException, PlusCopy } from '@/icons'
import { useEditingStateWithCtx, useVehicleFormException } from '@/atoms'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { useTexts } from '../hooks/useTexts'
import { useActions } from '../hooks/useActions'

interface Props {
  switchToCreateNewExceptionView: () => void
  switchToCopySettingsView: () => void
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function ExceptionActions(props: Props) {
  const { switchToCreateNewExceptionView, unifiedVehicle, switchToCopySettingsView } = props

  const texts = useTexts()
  const [settings, setExceptionSettings] = useVehicleFormException()
  const setException = useCallback(
    (exception: string) => {
      setExceptionSettings({ exception })
    },
    [setExceptionSettings],
  )
  const { onDeleteException } = useActions(setException)

  const { exception } = settings

  const { unifiedId } = unifiedVehicle
  const vehicleId = unifiedVehicle.hasRoutingLicense ? unifiedVehicle.vehicle.id : ''

  const deleteDisabled = !vehicleId || exception === 'any'

  const { setEditing } = useEditingStateWithCtx('vehicle')

  const onStartEditing = useCallback(() => {
    setExceptionSettings({ editMode: 'edit' })
    setEditing([unifiedId])
  }, [unifiedId, setEditing, setExceptionSettings])

  const onDeleteVehicleException = useCallback(() => {
    onDeleteException(vehicleId, exception)
  }, [exception, vehicleId, onDeleteException])

  return (
    <>
      <SecondaryColumnHeaderActionsRow>
        <ReadOnlyTooltip
          render={preventEditing => (
            <SecondaryColumnHeaderActionButton
              label={texts.newException}
              icon={<AddException />}
              onClick={switchToCreateNewExceptionView}
              disabled={preventEditing}
              className="pendo-setup-vehicle__addexception"
              testid="setup-vehicle__addexception"
            />
          )}
        />

        <ReadOnlyTooltip
          render={preventEditing => (
            <SecondaryColumnHeaderActionButton
              label={texts.copySettings}
              icon={<PlusCopy />}
              onClick={switchToCopySettingsView}
              disabled={preventEditing}
              className="pendo-setup-vehicle__copysettings"
              testid="setup-vehicle__copysettings"
            />
          )}
        />

        <ReadOnlyTooltip
          render={preventEditing => (
            <SecondaryColumnHeaderActionButton
              label={texts.deleteException}
              icon={<Trash />}
              onClick={onDeleteVehicleException}
              disabled={preventEditing || deleteDisabled}
              className="pendo-setup-vehicle__deleteexception"
              testid="setup-vehicle__deleteexception"
            />
          )}
        />

        <SecondaryColumnHeaderActionsSeparator />

        <ReadOnlyTooltip
          render={preventEditing => (
            <SecondaryColumnHeaderActionButton
              label={texts.editException(exception === 'any')}
              icon={<Edit />}
              onClick={onStartEditing}
              disabled={preventEditing}
              className="pendo-setup-vehicle__editexception"
              testid="setup-vehicle__editexception"
            />
          )}
        />
      </SecondaryColumnHeaderActionsRow>
    </>
  )
}
