import type { ReactElement } from 'react'
import type { Notifications } from '../../types'
import type { NotificationsListProps } from './components/NotificationsList'

import { useQueryState } from '@/routing'

export interface Props {
  NotificationsList: ReactElement<NotificationsListProps>
  TodayNotification: ReactElement
  EarlyNotification: ReactElement
  TwoDaysNotification: ReactElement
  TomorrowNotification: ReactElement
  DelayedNotification: ReactElement
  AlmostThereNotification: ReactElement
  CanceledNotification: ReactElement
  NotCompletedNotification: ReactElement
  CompletedNotification: ReactElement

  // Allows to show directly one of the notifications. It works only if `route` is 'notifications'
  // and query string is empty
  defaultNotification?: Notifications
}

/**
 * Renders the notification list and the current form.
 */
export function Forms(props: Props) {
  const { NotificationsList } = props

  // Manage internal navigation through the different notifications
  const [currentNotification] = useQueryState<Notifications>('notification')

  return (
    <>
      {NotificationsList}

      {/* Render the current notification form */}
      {currentNotification === 'todaySettings' && props.TodayNotification}
      {currentNotification === 'earlySettings' && props.EarlyNotification}
      {currentNotification === 'twoDaysSettings' && props.TwoDaysNotification}
      {currentNotification === 'tomorrowSettings' && props.TomorrowNotification}
      {currentNotification === 'delayedSettings' && props.DelayedNotification}
      {currentNotification === 'almostThereSettings' && props.AlmostThereNotification}
      {currentNotification === 'canceledSettings' && props.CanceledNotification}
      {currentNotification === 'notCompletedSettings' && props.NotCompletedNotification}
      {currentNotification === 'completedSettings' && props.CompletedNotification}
    </>
  )
}
