import type { FC } from 'react'
import type { WrappedComponentProps, IntlShape } from 'react-intl'

import { ReactElement, useEffect, memo } from 'react'
import { injectIntl } from 'react-intl'

/**
 * Expose the Intl APIs outside the components tree.
 */
export const translate: uui.domain.intl.Translate = options => {
  return getIntlAPI()?.formatMessage(options, options.values) || ''
}

let intlAPI: IntlShape | null

const getIntlAPI = (): typeof intlAPI => {
  if (!intlAPI) {
    // the intl APIs could be invoked before IntlManagerComp constructor has been executed. Check the original source code in the `uui` app
    throw new Error(`Intl Manager doesn't have references to intl API`)
  }
  return intlAPI
}

type Props = WrappedComponentProps & { children: ReactElement | null }

const IntlManagerComp: FC<Props> = memo(
  props => {
    const { intl } = props

    if (!intlAPI) {
      intlAPI = intl
    }

    useEffect(() => {
      intlAPI = intl
    }, [intl])

    return props.children
  },
  (prevProps: Props, nextProps: Props) => {
    const { intl: prevIntl } = prevProps
    const { intl } = nextProps

    return prevIntl === intl
  },
)

IntlManagerComp.displayName = 'IntlManagerComp'

export const IntlManager = injectIntl(IntlManagerComp)
