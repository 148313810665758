import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    newVehicle: translate({ id: 'vehicles.form.vehicle.defaultNewValue' }),
  }))

  return api
}
