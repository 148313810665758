import { useEffect, useState } from 'react'
import { useEnvironment } from '@/atoms'

function getEnvQa() {
  /* eslint-disable @typescript-eslint/prefer-ts-expect-error */
  // @ts-ignore
  return process.env.PUBLIC_QA
  /* eslint-enable @typescript-eslint/prefer-ts-expect-error */
}

export function useIsQaEnabled() {
  const [qa, setQa] = useState(getEnvQa())

  const configJson = useEnvironment()

  // The config.json overrides the .env files setting
  useEffect(() => {
    if (!configJson) return

    setQa(configJson.qa)
  }, [configJson])

  // Log if the QA mode sia enabled or not
  useEffect(() => {
    console.log(`%c QA ${qa} `, 'background: blue; color: white; display: block;')
  }, [qa])

  return qa === 'enable'
}
