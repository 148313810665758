import { useResetCrudSelectionOnUnmount } from '@/atoms'

import { useRoadsRoot } from './hooks/useRoadsRoot'
import { SingleView } from './Single/View'
import { SingleEdit } from './Single/Edit'
import { BulkView } from './Bulk/View'
import { List } from './List'

import { useHideRoadSegmentWhileEditing } from './hooks/useHideRoadSegmentWhileEditing'

export function RoadsRoot() {
  const { editing, roadSegments, selection, selectedRoadSegments } = useRoadsRoot()
  const bulk = selectedRoadSegments.length > 1
  const single = selectedRoadSegments.length === 1
  const roadSegment = selectedRoadSegments[0]

  useHideRoadSegmentWhileEditing(editing, selection[0])

  useResetCrudSelectionOnUnmount('routingSegments')

  return (
    <>
      <List editing={editing} roadSegments={roadSegments} selection={selection} />
      {single && !editing && roadSegment && <SingleView roadSegment={roadSegment} />}
      {bulk && <BulkView roadSegments={selectedRoadSegments} />}
      {editing && !bulk && <SingleEdit roadSegment={roadSegment} />}
    </>
  )
}
