import { Component, KeyboardEvent } from 'react'

import { Search as SearchIcon } from '@/icons'
import { SearchField } from '@/forms-legacy'

interface Props {
  onChange: (filter: string) => void
  onAddLoad: (key: string) => void
  filter: string
  placeholderText: string
}

interface State {
  placeholder: string
}

export default class Search extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      placeholder: props.placeholderText,
    }
  }

  handleOnKeyUp = (event: KeyboardEvent<HTMLElement>): void => {
    const { filter, onAddLoad } = this.props
    const { key } = event
    if (key === 'Enter' && filter.length > 0) {
      onAddLoad(filter)
    }
  }

  render() {
    const { onChange, filter } = this.props
    const { placeholder } = this.state

    return (
      <SearchField
        name="search"
        onKeyUp={this.handleOnKeyUp}
        onChange={onChange}
        value={filter}
        placeholder={placeholder}
        extraIcon={<SearchIcon />}
      />
    )
  }
}
