import { useSelector } from 'react-redux'

import { timeUtils } from '@/server-data'
import { Text } from '@/local/components'
import { HorizontalLayout } from '@/components/layout'
import { selectUserAccountPreferences } from '@/features/domain/user'

import { useTexts } from '../useTexts'

interface Props {
  timeWindow: uui.domain.rm.TimeWindow
}

export function WorkingHoursBlock(props: Props) {
  const { timeWindow } = props

  const accountPreferences = useSelector(selectUserAccountPreferences)
  const texts = useTexts()

  const startTime = timeUtils.formatSecondsFromMidnight(
    timeWindow.startSec,
    accountPreferences.timeFormat,
  )
  const endTime = timeUtils.formatSecondsFromMidnight(
    timeWindow.endSec,
    accountPreferences.timeFormat,
  )

  return (
    <HorizontalLayout
      data-trackid="navigo-routes-overview-details-single-vehicleStats-workingHoursBlock"
      data-testid="navigo-routes-overview-details-single-vehicleStats-workingHoursBlock"
      justifyContent="space-between"
      width="auto"
    >
      <Text size="$p3">{`${texts.workingHours}:`}&nbsp;</Text>

      <Text
        testid="navigo-routes-overview-details-single-vehicleStats-workingHoursBlock-value"
        weight="$semiBold"
        size="$p3"
      >
        {startTime}
        {' - '}
        {endTime}
      </Text>
    </HorizontalLayout>
  )
}
