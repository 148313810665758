import { useCallback } from 'react'

import { SingleStatus } from './elements/SingleStatus'

import { Props, BarcodeStatusOption } from './typings'

const BarcodesStatus = (props: Props) => {
  const { accountPreferences, onChange, options, value, disabled } = props

  const handleOnChange = useCallback(
    (pod: uui.domain.client.rm.BarcodePod, index: number) => {
      const updatedCollection = value.map((p, i) => (i === index ? pod : p))
      onChange(updatedCollection)
    },
    [onChange, value],
  )

  return (
    <>
      {value.map((pod, index) => {
        const opt: BarcodeStatusOption[] = options.map(o => {
          return {
            ...o,
            selected: pod.barcodeStatus === o.id,
          }
        })

        return (
          <SingleStatus
            value={pod}
            options={opt}
            accountPreferences={accountPreferences}
            onChange={podUpdated => handleOnChange(podUpdated, index)}
            disabled={disabled}
            key={index}
          />
        )
      })}
    </>
  )
}

export default BarcodesStatus
