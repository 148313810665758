import type { DriverAssignmentFormValues } from '../../typings'
import type { FieldRenderProps } from 'react-final-form'

import { Field as FinalFormField } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import { AssignmentsField } from './AssignmentsField'

export function AssignmentsFieldWrapper() {
  return (
    <FinalFormField
      name="assignments"
      isEqual={isDeepEqual}
      render={(formProps: FieldRenderProps<DriverAssignmentFormValues['assignments']>) => (
        <AssignmentsField formProps={formProps} />
      )}
    />
  )
}
