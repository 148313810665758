import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'sendMessageToDriverModal.title' }),
    shareLocation: translate({ id: 'sendMessageToDriverModal.shareLocation.title' }),
    gpsOnlyTitle: translate({ id: 'sendMessageToDriverModal.gpsOnly.title' }),
    gpsOnlySubtitle: translate({ id: 'sendMessageToDriverModal.gpsOnly.subtitle' }),
    drivers: (count: number) =>
      translate({
        id: 'sendMessageToDriverModal.shareLocation.chooseRecipient.drivers',
        values: { count },
      }),
    vehicles: (count: number) =>
      translate({
        id: 'sendMessageToDriverModal.shareLocation.chooseRecipient.vehicles',
        values: { count },
      }),
  }))

  return api
}
