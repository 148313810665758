import { useCallback } from 'react'

import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { resetCrudSelection } from '@/atoms'
import { trafficAreasUtils } from '@/utils'
import { deleteTrafficAreas } from '@/features/domain/traffic'

import { useEditing } from '../../../../../../hooks/useEditing'

import { useTrafficProfile } from '../../../../hooks/useTrafficProfile'

export function useActions(trafficArea: uui.domain.client.rm.TrafficRegion) {
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const trafficProfile = useTrafficProfile()
  const { setEditTrafficArea } = useEditing('trafficArea')

  const profileId = trafficProfile.id
  const areaId = trafficArea.id

  const onDelete = useCallback(async () => {
    try {
      const result = await dispatch(deleteTrafficAreas({ areaIds: [areaId], profileId }))

      if (!deleteTrafficAreas.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      resetCrudSelection('trafficArea')
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, toast, profileId, areaId])

  const onEdit = useCallback(() => {
    setEditTrafficArea(areaId)
  }, [areaId, setEditTrafficArea])

  const onExport = useCallback(() => {
    try {
      trafficAreasUtils.exportAreasToKML([trafficArea], profileId)
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, trafficArea, profileId])

  return {
    onDelete,
    onEdit,
    onExport,
  }
}
