import type { DepotFormValues } from './typings'

import { useState } from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { useFormTransaction, useReadOnly, useStopEditingOnUnmount } from '@/hooks'
import { usePendingFitMapOnMount, getMapInfo } from '@/map'
import { useEditingStateWithCtx, useEnvironmentQa } from '@/atoms'
import { Footer, SecondaryColumnHeader, FormContent } from '@/forms-legacy'

import { FormColumn } from '@/components/layout'

import { FormLoading } from '@/components/FormLoading'
import { computeFormInitialValues } from './utils'
import { useValidateForm } from './hooks/useValidateForm'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useComposeFieldsWithError } from './hooks/useComposeFieldsWithError'
import { useTexts } from './hooks/useTexts'
import { Form } from './Form'

interface Props {
  depot: uui.domain.client.rm.Depot
}

export function SingleEdit(props: Props) {
  const { depot } = props
  const { onCreate, onUpdate, submitting } = useOnSubmit()
  const { resetEditing } = useEditingStateWithCtx('depot')

  const [initialValues] = useState<DepotFormValues>(() =>
    computeFormInitialValues(getMapInfo().center, depot),
  )

  const validateForm = useValidateForm()
  const composeFieldsWithError = useComposeFieldsWithError()

  const { readonly } = useReadOnly()
  const texts = useTexts()

  const qa = useEnvironmentQa()

  // When the Edit form mounts
  // try to apply pending fitMap requests
  usePendingFitMapOnMount()

  useStopEditingOnUnmount()

  const transactionState = useFormTransaction()
  const transactionOpen = transactionState === 'ready'

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <FinalForm<DepotFormValues>
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={initialValues.id ? onUpdate : onCreate}
        render={(formRenderProps: FormRenderProps<DepotFormValues>) => {
          const { handleSubmit, form } = formRenderProps

          const { valid, validating, pristine, errors = {} } = form.getState()

          const saveDisabled = !valid || validating || readonly
          const fieldsWithError = composeFieldsWithError(errors)

          return (
            <>
              <SecondaryColumnHeader
                title={texts.headerTitle}
                description={texts.headerDescription}
                avatar={null}
              />
              <FormContent
                qa={qa}
                testId="depotSingleEdit"
                footer={
                  <>
                    {transactionOpen && (
                      <Footer
                        errorTitle={texts.footerTitle}
                        errors={fieldsWithError}
                        submitting={submitting}
                        primary={{
                          text: texts.submit(initialValues.id),
                          title: texts.submitTitle(valid, readonly, pristine),
                          disabled: saveDisabled,
                          onClick: handleSubmit,
                        }}
                        secondary={{
                          text: texts.cancel,
                          disabled: submitting,
                          onClick: resetEditing,
                        }}
                      />
                    )}
                  </>
                }
              >
                {transactionOpen ? (
                  <Form formRenderProps={formRenderProps} initialValues={initialValues} />
                ) : (
                  <FormLoading />
                )}
              </FormContent>
            </>
          )
        }}
      />
    </FormColumn>
  )
}
