import { useMemo } from 'react'

import { NumericInputField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  metric: boolean
  currency: string
}

export function ActivationCost(props: Props) {
  const { metric, currency } = props
  const texts = useTexts()

  const wrappedInputProps = useMemo(() => {
    return {
      step: 10,
      min: 0,
      useMetric: metric,
    }
  }, [metric])

  return (
    <NumericInputField
      name="activationCost"
      testid="activationCost"
      half
      label={texts.activationCost(`(${currency})`)}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
