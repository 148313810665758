import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    status: (status: 'moving' | 'stalling' | 'stopped') => {
      switch (status) {
        case 'moving':
          return translate({ id: 'rm.contextual.breadcrumbs.movingVehicle' })
        case 'stalling':
          return translate({ id: 'rm.contextual.breadcrumbs.stallingVehicle' })
        case 'stopped':
          return translate({ id: 'rm.contextual.breadcrumbs.stoppedVehicle' })
      }
    },

    heading: translate({ id: 'global.heading' }),
    noHeading: translate({ id: 'rm.contextual.breadcrumbs.noHeading' }),
  }))

  return api
}
