import { useMemo } from 'react'

import { useTexts } from './useTexts'

export interface TerritoryDetails {
  id: string
  name: string
  vehicles: string
  drivers: string
}

export function useTerritoriesDetails(
  courierFilter: uui.domain.client.rm.CourierFilter,
  territoryNames: Record<string, string>,
) {
  const texts = useTexts()

  const territoriesCount = Object.keys(courierFilter).length

  const territoriesDetails = useMemo<TerritoryDetails[]>(() => {
    const keys = Object.keys(courierFilter)

    const territoryDetails: TerritoryDetails[] = []

    for (const territoryId of keys) {
      const drivers = courierFilter[territoryId]?.driverIds ?? []
      const driversCount = drivers.length
      const hasAllDrivers = drivers.includes('*')

      const vehicles = courierFilter[territoryId]?.vehicleIds ?? []
      const vehiclesCount = vehicles.length
      const hasAllVehicles = vehicles.includes('*')

      territoryDetails.push({
        id: territoryId,
        name: territoryNames[territoryId] ?? '',
        drivers: hasAllDrivers ? texts.allDrivers : texts.driversCount(driversCount),
        vehicles: hasAllVehicles ? texts.allVehicles : texts.vehiclesCount(vehiclesCount),
      })
    }

    return territoryDetails
  }, [courierFilter, texts, territoryNames])

  return {
    territoriesCount,
    territoriesDetails,
  }
}
