import styled from 'styled-components'

import { HorizontalLayout } from '@/components/layout'

export const StatsContainer = styled(HorizontalLayout)`
  & > * + * {
    margin-left: 24px;
  }
`
StatsContainer.displayName = 'StatsContainer'
