import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from '@/intl'

import { selectCompaniesAsArray } from '@/features/domain/company'
import { DropdownItem, DropDownField } from '@/forms-legacy'

import { NONE_OPTION } from '../../../../../../constants'

export function CompanyField() {
  const values = useDropdownOptions()
  const texts = useTexts()

  return (
    <DropDownField name="order.companyId" values={values} testid="company" label={texts.label} />
  )
}

function useDropdownOptions(): DropdownItem[] {
  const companies = useSelector(selectCompaniesAsArray)
  const texts = useTexts()

  return useMemo(() => {
    const noneOption = { id: NONE_OPTION, label: texts.none }
    return [noneOption, ...companies.map(mapCompanyToDropdownItem)]
  }, [companies, texts])
}

function mapCompanyToDropdownItem(company: uui.domain.client.rm.Company): DropdownItem {
  return {
    id: company.id,
    label: company.name,
    disabled: !company.enabled,
  }
}

function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    none: translate({ id: 'global.none' }),
    label: translate({ id: 'orders.viewDetails.label.company' }),
  }))

  return api
}
