import { useSnapshot, proxy } from 'valtio'
import { useRef, useEffect } from 'react'
import { journal } from '@/server-data'
import { domainProxy } from '@/store'

// ---------------------------------------------------------------------------
// TYPES
// ---------------------------------------------------------------------------
export type ApplicationModeResult = 'normal' | 'readonly'

interface ApplicationMode {
  uiMode: uui.domain.api.ApplicationMode
  domainMode: uui.domain.api.ApplicationMode
  loadingType: uui.domain.api.LoadingState['type']
  connectionStatus: uui.domain.api.ConnectionStatus | 'uiOffline'
  transactionMode: uui.domain.api.Transaction['mode']
}

interface ApplicationModeComputed {
  isOffline: boolean
  mode: ApplicationModeResult
}

function createApplicationModeDefaultStatus(): ApplicationMode {
  return {
    uiMode: 'normal',
    loadingType: 'none',
    domainMode: 'normal',
    transactionMode: 'none',
    connectionStatus: 'online',
  }
}

// ---------------------------------------------------------------------------
// ATOM
// ---------------------------------------------------------------------------

export const applicationModeAtom = proxy<ApplicationMode & ApplicationModeComputed>({
  ...createApplicationModeDefaultStatus(),

  get isOffline() {
    return applicationModeAtom.connectionStatus !== 'online'
  },

  get mode() {
    if (
      applicationModeAtom.transactionMode === 'external' ||
      applicationModeAtom.connectionStatus !== 'online' ||
      applicationModeAtom.domainMode !== 'normal' ||
      applicationModeAtom.loadingType !== 'none' ||
      applicationModeAtom.uiMode !== 'normal'
    ) {
      return 'readonly'
    }

    return 'normal'
  },
})

// ---------------------------------------------------------------------------
// METHODS
// ---------------------------------------------------------------------------

export function getApplicationMode() {
  return applicationModeAtom.mode
}

function resetApplicationMode() {
  Object.assign(applicationModeAtom, createApplicationModeDefaultStatus())
  return applicationModeAtom.uiMode
}

type SetUiApplicationMode = (prev: ApplicationMode['uiMode']) => ApplicationMode['uiMode']

export function setApplicationMode(
  valueOrFunc: uui.domain.api.ApplicationMode | SetUiApplicationMode | 'reset',
) {
  // reset
  if (valueOrFunc === 'reset') return resetApplicationMode()

  if (typeof valueOrFunc === 'function') {
    applicationModeAtom.uiMode = valueOrFunc(applicationModeAtom.mode)
  } else {
    applicationModeAtom.uiMode = valueOrFunc
  }

  return applicationModeAtom.uiMode
}

export function setConnectionStatus(value: 'online' | 'uiOffline') {
  applicationModeAtom.connectionStatus = value
}

// ---------------------------------------------------------------------------
// REACT HOOKS
// ---------------------------------------------------------------------------

/**
 * @deprecated To know if the user can edit the plan, please leverage `useReadOnly` instead.
 */
export function useApplicationMode(): [ApplicationModeResult, typeof setApplicationMode] {
  return [useSnapshot(applicationModeAtom).mode, setApplicationMode]
}
export function useIsOffline() {
  return useSnapshot(applicationModeAtom).isOffline
}
export function useExternalTransactionExist() {
  return useSnapshot(applicationModeAtom).transactionMode === 'external'
}
export function usePendoTrackOffline() {
  const offlineStart = useRef<number>(-1)
  const offline = useSnapshot(applicationModeAtom).isOffline

  useEffect(() => {
    if (offline) {
      offlineStart.current = performance.now()
      return
    }

    if (offlineStart.current !== -1) {
      const offlineDuration = Math.trunc(performance.now() - offlineStart.current)

      const event: uui.domain.pendo.OfflineTracking = {
        type: 'User is back online',
        metadata: {
          offlineDuration,
        },
      }

      journal.pendo(event.type, {
        info: event.metadata,
      })

      offlineStart.current = -1
    }
  }, [offline])
}

// ---------------------------------------------------------------------------
// SUBSCRIBE METHOD
// ---------------------------------------------------------------------------

export function subscribeApplicationMode() {
  return domainProxy.subscribeToNotifications(
    notification => {
      if (notification.notificationType === 'applicationMode') {
        applicationModeAtom.uiMode = notification.payload
        applicationModeAtom.domainMode = notification.payload

        journal.atoms(`Domain Mode set to: ${notification.payload}`, {})
      }

      if (notification.notificationType === 'connectionStatus') {
        applicationModeAtom.connectionStatus = notification.payload
      }

      if (notification.notificationType === 'transactionState') {
        applicationModeAtom.transactionMode = notification.payload.mode
      }

      if (notification.notificationType === 'loadingState') {
        applicationModeAtom.loadingType = notification.payload.type
      }
    },
    ['applicationMode', 'connectionStatus', 'transactionState'],
  )
}
