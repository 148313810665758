import { Divider, Stack, Typography } from '@mui/material'

import { useTexts } from '../../../../useTexts'

import { Username } from './components/Username'
import { Password } from './components/Password'
import { Label } from './components/Label'

export function VerizonConnect() {
  const texts = useTexts()

  return (
    <Stack spacing={2} width="100%">
      <Label />

      <Divider />

      <Typography variant="caption">{texts.verizonConnect.instructions}</Typography>

      <Username />
      <Password />
    </Stack>
  )
}
