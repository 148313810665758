import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import Point from 'ol/geom/Point'
import { gis } from '@/server-data'

import { setPinFeatureMetadata } from '../../pinFeatureMetadata'
import { getLocationPinFeatureStyle } from './getLocationPinFeatureStyle'

export function refreshLocationPinFeature(
  pin: uui.domain.ui.map.LocationPin,
  feature: Feature<Geometry>,
) {
  const {
    info,
    pin: { latLng, radius },
  } = pin

  const lonLat = gis.fromLatLngToCoordinate(latLng)

  // ------------------------
  // Feature Map position
  // ------------------------

  const point = feature.getGeometry() as Point

  if (!point) {
    throw new Error(`Trying to update LocationPin [${pin.id}] without a valid geometry.`)
  }

  point.setCoordinates(lonLat)

  // ------------------------
  // Feature radius
  setPinFeatureMetadata(feature, 'radius', radius)

  // ------------------------
  // Feature info

  setPinFeatureMetadata(feature, 'info', info)

  // ------------------------
  // Feature Style

  const style = getLocationPinFeatureStyle(pin)
  feature.setStyle(style)

  return feature
}
