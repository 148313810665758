import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'
import type { DrawEditableRoadSegmentInteraction } from '../../../interactions/EditableRoadSegmentInteraction'

import { updateEditableRoadSegment } from '../../../atoms/editableRoadSegment/core/updateEditableRoadSegment'

export function createOnRoadSegmentCreate(drawRoadSegment: DrawEditableRoadSegmentInteraction) {
  /**
   * Save the Geometry of the created RoadSegment and set the Editable RoadSegment Store into `edit` mode.
   */
  return (
    editableSegment: { start: uui.domain.LatLng; end: uui.domain.LatLng },
    feature: Feature<Geometry>,
  ) => {
    updateEditableRoadSegment(prev => {
      // force the feature ID to match the active RoadSegment ID
      feature.setId(prev.id)

      // disable DRAW interaction
      drawRoadSegment.setActive(false)

      // Update the Editable RoadSegment Store
      const id = prev.id
      const type = prev.mode !== 'none' ? prev.type : 'disabled'
      return { mode: 'edit', id, editableSegment, type }
    })
  }
}
