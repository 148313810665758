import type { TrafficTimeWindowsFormValues } from '../types'

import { useState } from 'react'
import { useSelector } from 'react-redux'

import { timeWindowUtils } from '@/utils'
import { selectTrafficTimeWindows } from '@/features/domain/traffic'
import { selectWorkingDayStartSec } from '@/features/domain/territory'

// 6AM - 9AM
const defaultTimeWindow1 = {
  startSec: 3600 * 6,
  endSec: 3600 * 9,
} as const

// 4PM - 7PM
const defaultTimeWindow2 = {
  startSec: 3600 * 16,
  endSec: 3600 * 19,
}

export function useFormInitialValue() {
  const timeWindows = useSelector(selectTrafficTimeWindows)
  const workingDayStartSec = useSelector(selectWorkingDayStartSec)

  const [initialValues] = useState<TrafficTimeWindowsFormValues>(() => {
    const [tw1, tw2] = timeWindows

    const timeWindow1Visible = !!tw1
    const timeWindow2Visible = !!tw2

    return {
      timeWindow1: timeWindow1Visible
        ? timeWindowUtils.formatTimeWindow(tw1, workingDayStartSec)
        : defaultTimeWindow1,
      timeWindow2: timeWindow2Visible
        ? timeWindowUtils.formatTimeWindow(tw2, workingDayStartSec)
        : defaultTimeWindow2,
      timeWindow1Visible,
      timeWindow2Visible,
    }
  })

  return initialValues
}
