import { type SchedulerProConfig } from '@bryntum/schedulerpro'
import { addSeconds } from 'date-fns/esm'

import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { ControlledNavigateFunction } from '@/routing'
import { MovePairedOrdersPayload } from '@/components/modals/MovePairedOrdersModal'
import { selectDayRangeInfo } from '@/features/domain/scheduler'
import { store } from '@/store'

import { EventRenderer } from '../../components/Scheduler/components/EventRenderer'
import { getProject } from '../../atoms/project'

import { createOnBeforeEventDropFinalize } from './events/onBeforeEventDropFinalize'
import { onBeforeEventResizeFinalize } from './events/onBeforeEventResizeFinalize'
import { createOnEventDblClick } from './events/onEventDblClick'
import { onEventPartialResize } from './events/onEventPartialResize'
import { onBeforeEventResize } from './events/onBeforeEventResize'
import { createOnCellClick } from './events/onCellClick'
import { onBeforeEventDrag } from './events/onBeforeEventDrag'
import { onSelectionChange } from './events/onSelectionChange'
import { onEventMouseDown } from './events/onEventMouseDown'
import { onEventResizeEnd } from './events/onEventResizeEnd'

import { createSubGridConfigs } from './subGridConfigs'
import { isEventSelectable } from './isEventSelectable'
import { createColumnStore } from './columnStore'
import { createFeatures } from './features'
import { eventLayout } from './eventLayout'
import { navigator } from './navigator'

export function createSchedulerConfig(
  appendTo: string,
  navigate: ControlledNavigateFunction<uui.routing.Routing | uui.routing.Setup>,
  showMovePairedOrdersModal: (payload: MovePairedOrdersPayload) => void,
  onHeaderMenuBeforeShow: SchedulerProConfig['onHeaderMenuBeforeShow'],
): Partial<SchedulerProConfig> {
  const state = store.getState()

  const { dayStartOffset, dayLength } = selectDayRangeInfo(state)
  const calendarRangeInterval = selectCalendarRangeInterval(state)

  const onBeforeEventDropFinalize = createOnBeforeEventDropFinalize(showMovePairedOrdersModal)
  const onEventDblClick = createOnEventDblClick(navigate)
  const onCellClick = createOnCellClick(navigate)

  const subGridConfigs = createSubGridConfigs()

  const startDate = addSeconds(calendarRangeInterval.start, dayStartOffset)
  const endDate = addSeconds(calendarRangeInterval.start, dayStartOffset + dayLength)

  const features = createFeatures()
  const columns = createColumnStore()
  const project = getProject()

  return {
    displaySchedulingIssueResolutionPopup: false,
    updateTimelineContextOnScroll: false,
    ignoreDomEventsWhileScrolling: true,
    selectResourceOnEventNavigate: false,
    selectResourceOnScheduleClick: false,
    preserveScrollOnDatasetChange: true,
    onBeforeEventResizeFinalize,
    deselectAllOnScheduleClick: false,
    zoomKeepsOriginalTimespan: true,
    zoomOnTimeAxisDoubleClick: false,
    onBeforeEventDropFinalize,
    eventSelectionDisabled: true, // NOTE: This is set to true to prevent the default selection behavior of the scheduler from interfering with the custom selection behavior.
    onHeaderMenuBeforeShow,
    createEventOnDblClick: false,
    enableEventAnimations: false,
    onEventPartialResize,
    useInitialAnimation: false,
    onBeforeEventResize,
    milestoneLayoutMode: 'data',
    autoAdjustTimeAxis: false,
    managedEventSizing: false,
    transitionDuration: 0,
    onBeforeEventDrag,
    isEventSelectable,
    onSelectionChange,
    onEventMouseDown,
    multiEventSelect: true,
    onEventResizeEnd,
    onEventDblClick,
    milestoneAlign: 'start',
    resourceMargin: 0,
    subGridConfigs,
    destroyStores: false,
    eventRenderer: EventRenderer,
    hideAnimation: false,
    showAnimation: false,
    stickyHeaders: false,
    allowOverlap: true,
    allowCreate: false,
    eventLayout,
    onCellClick,
    viewPreset: 'preset-level-0',
    rowHeight: 23,
    barMargin: 0,
    //@ts-expect-error
    navigator,
    startDate,
    appendTo,
    features,
    columns,
    endDate,
    project,
  }
}
