import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    eta: translate({ id: 'global.eta' }),
    none: translate({ id: 'global.none' }),
    plannedTime: translate({ id: 'global.plannedTime' }),
  }))

  return api
}
