import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    searchPlaceholder: translate({ id: 'gps.eventslist.list.filterEvents' }),
    yourEvents: translate({ id: 'gps.eventslist.list.yourEvents' }),

    entitiesCount: (selected: number, count: number): string =>
      translate({
        id:
          selected > 0
            ? 'deviceEvents.list.footer.countWithSelection'
            : 'deviceEvents.list.footer.count',
        values: { count, selected },
      }),

    selectBtn: (allSelected: boolean): string =>
      translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' }),
    visibilityBtn: (someVisible?: boolean): string =>
      translate({
        id: someVisible ? 'global.hideAll' : 'global.showAll',
      }),
    noBadges: translate({ id: 'gps.trackinglist.list.badgeFilter.noBadges.title' }),
    noEntries: {
      title: translate({ id: 'deviceEvents.list.noEntries.title' }),
      description: translate({ id: 'deviceEvents.list.noEntries.description' }),
    },
    noResults: {
      title: translate({ id: 'deviceEvents.list.noResults.title' }),
      description: translate({ id: 'deviceEvents.list.noResults.description' }),
    },
  }))

  return api
}
