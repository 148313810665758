import styled from 'styled-components'

type Props = {
  primary?: boolean
  empty?: boolean
}

export const ReadonlyLabelInfo = styled.span<Props>`
  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => (p.primary ? p.theme.weights.$semiBold : p.theme.weights.$regular)};
  letter-spacing: ${p => (p.primary ? '-0.003rem' : 'inherit')};
  position: relative;
  color: ${p => (p.empty ? p.theme.colors.$paleSky : p.theme.colors.$nightRider)};
  padding-left: 6px;
  margin: 0;
  margin-bottom: ${p => (p.primary ? '12px' : '0')};
  user-select: none;
`

ReadonlyLabelInfo.displayName = 'ReadonlyLabelInfo'
