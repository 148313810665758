import { AtomsDevToolsTester } from './components/AtomsDevToolsTester'

export function DevPreferencesTester() {
  return (
    <>
      <h1>Dev Preferences</h1>
      <hr />

      <h2>Atoms devtools</h2>
      <AtomsDevToolsTester />
    </>
  )
}
