import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    routes: translate({ id: 'components.schedulerHeader.routes' }),
    orders: translate({ id: 'components.schedulerHeader.orders' }),
    show: translate({ id: 'components.schedulerHeader.show' }),
    group: translate({ id: 'components.schedulerHeader.group' }),
    editColumns: translate({ id: 'components.schedulerHeader.editColumns' }),
    groupByDate: translate({ id: 'components.schedulerHeader.groupByDate' }),
    groupByVehicle: translate({ id: 'components.schedulerHeader.groupByVehicle' }),
    unitMode: (showAbsoluteValues: boolean) =>
      showAbsoluteValues
        ? translate({ id: 'components.schedulerHeader.mode.absolute' })
        : translate({ id: 'components.schedulerHeader.mode.percentage' }),
    values: translate({ id: 'components.schedulerHeader.values' }),
    returnToOldScheduler: translate({ id: 'components.schedulerHeader.returnToOldScheduler' }),

    zoomInTooltipCommand: translate({ id: 'components.schedulerHeader.zoomIn.tooltip.command' }),
    zoomInTooltipKeysMacOs: translate({
      id: 'components.schedulerHeader.zoomIn.tooltip.keys.macos',
    }),
    zoomInTooltipKeysOthers: translate({
      id: 'components.schedulerHeader.zoomIn.tooltip.keys.others',
    }),
    zoomRoutesCommand: translate({ id: 'components.schedulerHeader.zoomRoutes.tooltip.command' }),
    zoomOutTooltipCommand: translate({ id: 'components.schedulerHeader.zoomOut.tooltip.command' }),
    zoomOutTooltipKeysMacOs: translate({
      id: 'components.schedulerHeader.zoomOut.tooltip.keys.macos',
    }),
    zoomOutTooltipKeysOthers: translate({
      id: 'components.schedulerHeader.zoomOut.tooltip.keys.others',
    }),
  }))

  return api
}
