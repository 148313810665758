import { is } from '@/utils'

/**
 * @private
 * Generates the absolute path from a relative one.
 * ATTENTION: every nested path should be unique.
 */
export const generateAbsolutePath = <Routes extends string>(path: Routes): string => {
  if (path === '/') return path

  if (is.routing.MainSections(path)) return removeStar(`/${path}`)

  if (is.routing.Setup(path)) return removeStar(`/setup/${path}`)
  if (is.routing.Error(path)) return removeStar(`/error/${path}`)
  if (is.routing.Routing(path)) return removeStar(`/routing/${path}`)
  if (is.routing.Settings(path)) return removeStar(`/settings/${path}`)

  throw new Error('Unknown route')
}

const removeStar = (path: string) => path.replace(/\*\//g, '')
