import {
  SecondaryColumnHeader,
  HorizontalRuler,
  FormContent,
  SecondaryColumnContentContainer,
} from '@/forms-legacy'
import { FormColumn } from '@/components/layout'

import { AlertAvatar } from '../../components/AlertAvatar'
import { useCreateAlertLabel } from '../../hooks/useCreateAlertLabel'

import { ActionsManager } from './components/ActionsManager'
import { NotificationAddresses } from './components/NotificationAddresses'
import { DevicesList } from './components/DevicesList/DevicesList'

import { useActions } from './hooks/useActions'

interface Props {
  alert: uui.domain.client.gps.wwgps.EventAlert
}

const avatarStyle = { marginRight: 16, border: 'none' }
const secondaryColumnContentContainerStyle = { marginTop: 16 }

export function SingleView(props: Props) {
  const { alert } = props

  const { name, eventTypeId, deviceIds, notificationAddresses } = alert
  const createAlertLabel = useCreateAlertLabel()

  const actions = useActions()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={name}
        description={createAlertLabel(eventTypeId)}
        action={actions.onClose}
        avatar={<AlertAvatar size={44} style={avatarStyle} eventTypeId={eventTypeId} />}
      />
      <ActionsManager alert={alert} />
      <FormContent footer={<div />}>
        <SecondaryColumnContentContainer style={secondaryColumnContentContainerStyle}>
          <DevicesList deviceIds={deviceIds} />
          <HorizontalRuler />
          <NotificationAddresses notificationAddresses={notificationAddresses} />
        </SecondaryColumnContentContainer>
      </FormContent>
    </FormColumn>
  )
}
