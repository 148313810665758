import type { DepotValidation } from '../typings'

import { useTexts } from './useTexts'

export const useComposeFieldsWithError = () => {
  const texts = useTexts()

  return (errors: DepotValidation) =>
    Object.keys(errors).map(field => {
      switch (field) {
        case 'name':
          return texts.labelName

        case 'setupCost':
          return texts.labelSetupCost

        case 'setupTime':
          return texts.labelSetupTime

        case 'location':
          return texts.labelLocation

        default:
          return ''
      }
    })
}
