import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function ArrowRoundedIn(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 25 20">
        <path d="M17.02655 2.9382c.31123.31706.31123.83111 0 1.14817-.31123.31705-.81584.31705-1.12707 0C12.68306.80976 7.47694.80219 4.27128 4.06783c-3.20565 3.26564-3.19823 8.56919.0182 11.8458 3.21642 3.27661 8.42254 3.28418 11.6282.01854.31123-.31706.81584-.31706 1.12707 0 .31123.31705.31123.8311 0 1.14816-3.82884 3.90049-10.04417 3.89145-13.88234-.01854C-.67576 13.1518-.68463 6.82016 3.1442 2.91967c3.82884-3.90049 10.04417-3.89145 13.88234.01854zm-4.53011 10.85108l-3.2856-3.2809A.71738.71738 0 0 1 9 10.00036c0-.19114.07556-.37365.21084-.50802l3.2856-3.28162c.28135-.28096.73685-.28096 1.01748 0 .14032.14011.21084.32406.21084.50801 0 .18395-.07052.3679-.21084.50802L11.46096 9.2775h6.81946c.39793 0 .71958.32119.71958.71855 0 .39664-.32165.71855-.71958.71855h-6.8281l2.0616 2.05865c.28136.28096.28136.73508 0 1.01603-.28135.28096-.73613.28096-1.01748 0z" />
      </svg>
    </IconContainer>
  )
}
