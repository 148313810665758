import styled from 'styled-components'
import { ValidColor } from '@/local/components'

type Props = {
  background: ValidColor
  color: ValidColor
}

export const Container = styled.div<Props>`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: start;
  align-items: center;
  padding: 5px 17px;
  font-size: ${p => p.theme.fonts.$p2};

  color: ${p => p.theme.colors[p.color]};
  background-color: ${p => p.theme.colors[p.background]};
  font-weight: ${p => p.theme.weights.$semiBold};
`

Container.displayName = 'Container'
