import { isBefore, isEqual, parse } from 'date-fns/esm'

export function isSingleArchivedDay(
  selectionStart?: Date,
  selectionEnd?: Date,
  firstNonArchivedDay?: string,
) {
  if (firstNonArchivedDay && selectionStart) {
    const isRange = selectionEnd ? !isEqual(selectionStart, selectionEnd) : false

    if (isRange) return false

    const firstNonArchivedDayAsDate = parse(firstNonArchivedDay, 'yyyyMMdd', new Date())

    return isBefore(selectionStart, firstNonArchivedDayAsDate)
  }

  return false
}
