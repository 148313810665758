import type { HorizontalRadioGroupColors } from '@/forms-legacy'

import { useMemo } from 'react'

import { HorizontalRadioGroupField } from '@/forms-legacy'
import { theme } from '@/local/components'

import { useTrackingOptionRadioValues } from '../hooks/useTrackingOptionRadioValues'
import { useTexts } from '../hooks/useTexts'

interface Props {
  deviceId?: string
}

const colors: HorizontalRadioGroupColors = {
  background: theme.colors.$pureWhite,
  backgroundSelected: theme.colors.$nightRider,
  color: theme.colors.$nightRider,
  colorSelected: theme.colors.$pureWhite,
  border: theme.colors.$nightRider,
  borderSize: 2,
}

export function TrackingOption(props: Props) {
  const { deviceId } = props
  const texts = useTexts()

  const radioGroupValues = useTrackingOptionRadioValues(deviceId)
  const wrappedInputProps = useMemo(() => {
    return {
      values: radioGroupValues,
      colors,
    }
  }, [radioGroupValues])

  return (
    <HorizontalRadioGroupField
      label={texts.trackingOptionLabel}
      info={texts.trackingOptionInfo}
      name="trackingOption"
      testid="trackingOption"
      wrappedInputProps={wrappedInputProps}
    />
  )
}
