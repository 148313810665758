import { MouseEventHandler, ReactNode } from 'react'

import styled from 'styled-components'

export interface Props {
  top?: boolean
  bottom?: boolean
  children?: ReactNode
  className?: string
  onClick?: MouseEventHandler<HTMLElement>
  disabled?: boolean
  testid?: string
}

const HEIGHT = 10
const RADIUS = 3

const setRadiusBorder = ({ top = false, bottom = false }: Props = {}) => {
  if (top) return `border-top-right-radius: ${RADIUS}px`
  if (bottom) return `border-bottom-right-radius: ${RADIUS}px`
  return ''
}

const StepButton = ({ children, onClick, disabled, className = '', testid }: Props) => (
  <button
    type="button"
    onClick={onClick}
    className={className}
    disabled={disabled}
    data-testid={testid}
  >
    {children}
  </button>
)

const StyledStepButton = styled(StepButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${HEIGHT}px;
  background: ${p => p.theme.colors.$alabaster};
  padding: 0;

  outline: 0;
  border-width: 0;
  color: ${p => p.theme.colors.$shadyLady};

  transition: 0.3s ease;
  transition-property: background, opacity;
  cursor: pointer;

  ${setRadiusBorder};

  &:hover {
    background: ${p => p.theme.colors.$shadyLady};
    color: ${p => p.theme.colors.$pureWhite};
  }

  & > * {
    width: ${HEIGHT}px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.1;
  }
`

StyledStepButton.displayName = 'StyledStepButton'

export default StyledStepButton
