import * as Texts from '../../intl'
import { SimulationFormValues, SimulationValidation } from './typings'

export const validationBlacklist: string[] = []

export const validateForm =
  (simulationsNames: string[]) =>
  (values: SimulationFormValues): SimulationValidation => {
    const { name: n = '', label: l = '', simulationDate } = values
    const name: string = n.trim()
    const label: string = l.trim()

    const validation: SimulationValidation = {}

    if (!name) {
      validation.name = Texts.getSimulationValidationEmptyName()
    }

    if (simulationsNames.includes(name)) {
      validation.name = Texts.getSimulationValidationUniqueName()
    }

    if (label.length > 255) {
      validation.label = Texts.getSimulationValidationNotesMaxLength()
    }

    if (simulationDate.type === 'fromArchived' && !simulationDate.date) {
      validation.simulationDate = Texts.getSimulationValidationDateNotSet()
    }

    return validation
  }

const getErrorsText = (field: string): string => {
  switch (field) {
    case 'name':
      return Texts.getSimulationNameFieldText()
    case 'label':
      return Texts.getSimulationLabelFieldText()

    default:
      return ''
  }
}

export const getFieldErrors = (errors: SimulationValidation): string[] => {
  const fieldErrors: string[] = Object.keys(errors)
    .filter(key => !validationBlacklist.includes(key) && typeof errors[key] === 'string')
    .map(key => getErrorsText(key))
  return fieldErrors
}
