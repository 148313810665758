import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function InspectPin(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M7.16 4.922C7.16 2.756 8.915 1 11.08 1S15 2.756 15 4.922c0 2.193-1.842 3.388-2.59 3.874-.132.086-.23.15-.278.193-.326.293-.595.882-.704 1.255-.053.18-.2.273-.348.277-.147-.004-.295-.096-.348-.277-.109-.373-.378-.962-.704-1.255-.048-.044-.146-.107-.278-.193-.748-.486-2.59-1.681-2.59-3.874zM1 3.278c0-.342.286-.6.61-.588.045.001.093.01.14.023l3.451.987c.19.053.39.057.58.01l.44-.111c-.104.384-.164.784-.174 1.198L6.04 4.8v8.906l2.8-.801V9.538l1.12 1.576v1.775l3.36.84V9.538l1.12-.894v5.767c0 .383-.36.664-.73.571L9.7 13.98c-.19-.048-.39-.044-.578.01l-3.443.983c-.13.037-.268.037-.398 0l-3.47-.991c-.48-.137-.811-.578-.811-1.078V3.278zm10.08.523c.619 0 1.12.502 1.12 1.121 0 .62-.501 1.12-1.12 1.12-.619 0-1.12-.5-1.12-1.12 0-.62.501-1.12 1.12-1.12zm-6.16.983l-2.8-.8v8.92l2.8.8v-8.92z" />
      </svg>
    </IconContainer>
  )
}
