import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

interface Payload {
  areas: uui.domain.server.rm.TrafficRegion[]
  recurrence: string
}

/**
 * Import traffic areas from KML file
 *
 * @private
 */
export const importTrafficAreasFromKML = createAsyncThunk<
  // Return type of the payload creator
  string[],
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/traffic/importTrafficAreasFromKML', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const response = await rpc('rpc/traffic/importTrafficAreasFromKML', {
      category: 'rpc',
      type: 'rpc/traffic/importTrafficAreasFromKML',
      areas: payload.areas,
      recurrence: payload.recurrence,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {importTrafficAreasFromKML}`,
      { tags: ['rpc', 'traffic'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Update time windows failed`,
      error,
    })
  }
})
