import styled from 'styled-components'
import { ValidColor } from '../../theme'
import { parseSizeValue } from '../utils/parseSizeValue'

interface Props extends workwave.Div {
  alignItems?: 'start' | 'center' | 'end'
  justifyContent?: 'start' | 'center' | 'end'
  bgColor?: ValidColor
  padding?: string | number
}

const item = styled.div<Props>``
export const ButtonToolbar = styled(item).attrs<Props>(() => ({
  className: 'o-button-toolbar',
}))`
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${p => p.justifyContent || 'center'};
  align-items: ${p => p.alignItems || 'center'};
  width: 100%;
  padding: ${p => parseSizeValue(p.padding ?? 0)};
  background-color: ${p => (p.bgColor ? p.theme.colors[p.bgColor] : 'transparent')};

  & > *:first-child {
    margin-left: 0;
  }
  & > *:last-child {
    margin-right: 0;
  }
`

ButtonToolbar.displayName = 'ButtonToolbar'
