import styled from 'styled-components'

type Props = {
  warn?: boolean
}

export const ItemContainer = styled.div<Props>`
  outline: 0;
  width: 100%;
  height: 32px;
  display: flex;
  border-width: 0;
  text-align: left;
  flex-wrap: nowrap;
  position: relative;
  align-items: center;
  padding: 0 0 0 16px;
  transition: background 0.3s ease;
  background: ${p => p.theme.colors.$pureWhite};
  font-weight: ${p => p.theme.weights.$semiBold};
  color: ${p => (p.warn ? p.theme.colors.$outrageousRed : p.theme.colors.$nightRider)};
`

ItemContainer.displayName = 'ItemContainer'
