import { setCrudSelection, setEditingState, setMainSelection } from '@/atoms'
import { getRouterNavigator } from '@/routing'
import { gis } from '@/server-data'

import { fitMap } from '../../../../atoms/map/utils/fitMap'
import { getMapSelectionContext } from '../../../../atoms/selection/read/getMapSelectionContext'
import { getDomainMarkerData } from '../../../../atoms/markerChangelog/domain/getDomainMarkerData'

export function doubleClickVehicles(vehicleId?: string) {
  if (vehicleId) {
    const { mapMarkers } = getDomainMarkerData()
    const { type: selectionCtx } = getMapSelectionContext()

    const latLngCoordinates: uui.domain.LatLng[] = []

    const vehicle = mapMarkers.vehicle[vehicleId]

    if (!vehicle) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`Trying to double click a non-existent Vehicle with ID: ${vehicleId}`)
      }

      return
    }

    latLngCoordinates.push(gis.fromCoordinateToLatLng(vehicle.lonLat))

    // Set the new selection for both selectionContexts
    setMainSelection('unifiedVehicles', [vehicleId])
    setCrudSelection('unifiedVehicles', [vehicleId])

    // queue a pending fitMap request
    // it will be applied by the destination view when it opens
    fitMap(latLngCoordinates, { preventIfVisible: true }, 'add')

    // If the double click has been performed in the Setup Map let's set the editing state
    if (selectionCtx === 'crud') {
      setEditingState({ editing: true, ctx: 'vehicle', editingEntities: [vehicleId] })
    }

    // Navigate to the target page
    const navigate = getRouterNavigator()
    navigate('/setup/vehicles')
  }
}
