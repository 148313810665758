import { PureComponent } from 'react'
import { Field as FinalFormField } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import PodListField from './PodListField'
import { PublicProps as Props, FieldRenderProps } from './typings'

export default class PodListFieldWrapper extends PureComponent<Props> {
  render() {
    const { name, ...props } = this.props
    return (
      <FinalFormField
        {...props}
        name={name}
        isEqual={isDeepEqual}
        render={(formProps: FieldRenderProps) => {
          return <PodListField {...this.props} formProps={formProps} />
        }}
      />
    )
  }
}
