import type { MapSelectionContext } from '../mapSelection'
import type { MainSelection } from '@/atoms'

import { gis } from '@/server-data'

const emptyArray = []

export const parseOrderSelection = (
  mapSelectionContext: MapSelectionContext,
  orderSelection: workwave.DeepReadonly<MainSelection['orderSteps']>,
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
) => {
  const selectedOrderStepId = mapSelectionContext.type === 'main' ? orderSelection : emptyArray

  const selection = new Set<string>()

  for (const id of selectedOrderStepId) {
    const extOrder = extendedOrderSteps[id]

    if (!extOrder) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`The Map is trying to select an unknown Order ID: ${id}`)
      }
      continue
    }

    const latLng = extOrder.location.rawLatLngAsString

    if (latLng === gis.nullIslandLatLngString) continue

    // ignore selected order at depot
    if (!extOrder.atDepot) {
      selection.add(latLng)
    }
  }

  return selection
}
