import type { CustomOptions, Options, StandardType } from '../types'
import { getParserByType } from './getParserByType'
import { isCustomType } from './isCustomType'
import { isNotDefined } from './isNotDefined'
import { isOptions } from './isOptions'
import { isStandardType } from './isStandardType'

type ParsedArguments<T> = {
  type: StandardType | 'custom'
  options: Options<T> | CustomOptions<T>
  defaultValue: T | undefined
}

export function parseArguments<T>(
  typeOrDefaultValueOrOptions?: any,
  defaultValueOrOptions?: any,
  options?: any,
): ParsedArguments<T> {
  // ------------------------------------
  // String short-hand
  // ------------------------------------

  if (
    isNotDefined(typeOrDefaultValueOrOptions) &&
    isNotDefined(defaultValueOrOptions) &&
    isNotDefined(options)
  ) {
    // console.log('1 simple string')

    // simple string
    return {
      type: 'string',
      defaultValue: undefined,
      options: {},
    }
  }

  if (
    isOptions<T>(typeOrDefaultValueOrOptions) &&
    isNotDefined(defaultValueOrOptions) &&
    isNotDefined(options)
  ) {
    // console.log('2 simple string with options')

    // simple string with options
    return {
      type: 'string',
      defaultValue: undefined,
      options: typeOrDefaultValueOrOptions,
    }
  }

  if (
    typeof typeOrDefaultValueOrOptions === 'string' &&
    isNotDefined(defaultValueOrOptions) &&
    isNotDefined(options)
  ) {
    if (
      !isStandardType(typeOrDefaultValueOrOptions) &&
      !isCustomType(typeOrDefaultValueOrOptions)
    ) {
      // console.log('3 simple string with default value')

      const { isValid } = getParserByType('string')
      if (!isValid(typeOrDefaultValueOrOptions as unknown)) {
        throw new Error(
          `useQueryState -- Invalid default value for type "${'string'}": ${defaultValueOrOptions}`,
        )
      }

      // simple string with default value
      return {
        type: 'string',
        defaultValue: typeOrDefaultValueOrOptions as any,
        options: {},
      }
    }
  }

  if (
    typeof typeOrDefaultValueOrOptions === 'string' &&
    isOptions<T>(defaultValueOrOptions) &&
    isNotDefined(options)
  ) {
    if (
      !isStandardType(typeOrDefaultValueOrOptions) &&
      !isCustomType(typeOrDefaultValueOrOptions)
    ) {
      // console.log('4 simple string with default value and options')

      const { isValid } = getParserByType('string')
      if (!isValid(typeOrDefaultValueOrOptions as unknown)) {
        throw new Error(
          `useQueryState -- Invalid default value for type "${'string'}": ${defaultValueOrOptions}`,
        )
      }

      // simple string with default value and options
      return {
        type: 'string',
        defaultValue: typeOrDefaultValueOrOptions as any,
        options: defaultValueOrOptions,
      }
    }
  }

  // ------------------------------------
  // Explicit value type
  // ------------------------------------

  if (!isStandardType(typeOrDefaultValueOrOptions) && !isCustomType(typeOrDefaultValueOrOptions)) {
    throw new Error(`useQueryState -- Unknown type "${typeOrDefaultValueOrOptions}`)
  }

  const finalType = typeOrDefaultValueOrOptions
  const finalOptions: CustomOptions<T> | Options<T> = isOptions<T>(defaultValueOrOptions)
    ? defaultValueOrOptions
    : isOptions<T>(options)
    ? options
    : {}
  const transformer = isCustomType(finalType)
    ? getParserByType(finalType, finalOptions)
    : getParserByType(finalType)

  if (!transformer?.isValid) {
    console.warn(`useQueryState -- Missing transformer for type "${finalType}"`)
    throw new Error(`useQueryState -- Missing transformer for type "${finalType}"`)
  }

  if (isStandardType(finalType) && isNotDefined(defaultValueOrOptions) && isNotDefined(options)) {
    // console.log('5 Explicit value type -- no CUSTOM')

    // Explicit value type
    return {
      type: finalType,
      defaultValue: undefined,
      options: {},
    }
  }

  if (
    (isStandardType(finalType) || isCustomType(finalType)) &&
    isOptions<T>(defaultValueOrOptions) &&
    isNotDefined(options)
  ) {
    // console.log('6 Explicit value type with options -- CUSTOM and STANDARD')

    // Explicit value type with options
    return {
      type: finalType,
      defaultValue: undefined,
      options: defaultValueOrOptions as CustomOptions<T>,
    }
  }

  if (
    isStandardType(finalType) &&
    transformer.isValid(defaultValueOrOptions) &&
    isNotDefined(options)
  ) {
    // console.log('7 Explicit value type with default value -- no CUSTOM')

    // Explicit value type with default value
    // ATTENTION: It cannot be custom mode if no options have been provided
    return {
      type: finalType,
      defaultValue: defaultValueOrOptions as T,
      options: {},
    }
  }

  if (
    isStandardType(finalType) &&
    transformer.isValid(defaultValueOrOptions) &&
    isOptions<T>(options)
  ) {
    // console.log('8 Explicit value type with default value and Options -- no CUSTOM')

    // Explicit value type with default value and Options
    return {
      type: finalType,
      defaultValue: defaultValueOrOptions as T,
      options,
    }
  }

  if (
    isCustomType(finalType) &&
    transformer.isValid(defaultValueOrOptions) &&
    isOptions<T>(options)
  ) {
    // console.log('9 Explicit CUSTOM value type with default value and Options')

    // Explicit value type with default value and Options
    return {
      type: finalType,
      defaultValue: defaultValueOrOptions as T,
      options,
    }
  }

  // console.warn({ finalType, defaultValueOrOptions, options })
  throw new Error('Something went very wrong')
}
