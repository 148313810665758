import { Component } from 'react'

import { ActionButtonSmall } from '@/forms-legacy'
import { TargetWithArrow, Download } from '@/icons'

import * as OrderTexts from '../../../../intl'

import ReadonlyAudioContainer from './elements/ReadonlyAudioContainer'
import ReadonlyAudioLeftContainer from './elements/ReadonlyAudioLeftContainer'
import ReadonlyAudioTextContainer from './elements/ReadonlyAudioTextContainer'
import ReadonlyAudioTextTime from './elements/ReadonlyAudioTextTime'
import ReadonlyAudioTextTitle from './elements/ReadonlyAudioTextTitle'
import ReadonlyAudioButtonsContainer from './elements/ReadonlyAudioButtonsContainer'
import ReadonlyAudioNativeContainer from './elements/ReadonlyAudioNativeContainer'
import ReadonlyAudioNativeComponent from './elements/ReadonlyAudioNativeComponent'

import { Props } from './typings'

export default class ReadonlyAudio extends Component<Props> {
  private handleToggle = () => {
    const { onToggle, latLng, uuid } = this.props
    onToggle(uuid, latLng)
  }

  render() {
    const { src, title, time, latLng, selected = false } = this.props

    return (
      <ReadonlyAudioContainer>
        <ReadonlyAudioLeftContainer>
          <ReadonlyAudioTextContainer>
            <ReadonlyAudioTextTitle>{title}</ReadonlyAudioTextTitle>
            <ReadonlyAudioTextTime>{time}</ReadonlyAudioTextTime>
          </ReadonlyAudioTextContainer>
        </ReadonlyAudioLeftContainer>

        <ReadonlyAudioButtonsContainer>
          <ActionButtonSmall
            name="ShowOnMapAction"
            label={OrderTexts.getPodShowOnMapText(selected)}
            Icon={<TargetWithArrow size={18} color="$shadyLady" />}
            callback={this.handleToggle}
            disabled={!latLng}
          />
          <ActionButtonSmall
            name="DownloadAction"
            label={OrderTexts.getDownloadText()}
            Icon={<Download size={26} color="$shadyLady" />}
            callback={() => undefined}
            href={src}
            renderAsDownloadableLink
          />
        </ReadonlyAudioButtonsContainer>

        <ReadonlyAudioNativeContainer>
          <ReadonlyAudioNativeComponent src={src} controls />
        </ReadonlyAudioNativeContainer>
      </ReadonlyAudioContainer>
    )
  }
}
