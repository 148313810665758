import type { DayToDispatch } from '../../typings'

import { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { eachDayOfInterval, addDays, format } from 'date-fns/esm'

import { dateUtils } from '@/utils'
import { selectRoutes } from '@/features/domain/route'
import { selectOrders } from '@/features/domain/order'
import { selectApprovedPlans } from '@/features/domain/routeplan'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectTerritoryLicensingLimits } from '@/features/domain/territory'

import { aggregateOrders } from './utils/aggregateOrders'
import { isRouteOutdated } from './utils/isRouteOutdated'
import { aggregateRoutesByDate } from './utils/aggregateRoutesByDate'
import { hasOrdersWithExecutionEvents } from './utils/hasOrdersWithExecutionEvents'

interface Result {
  dispatchedRoutesCount: number
  dispatchedDaysCount: number
  days: DayToDispatch[]
}

export function useDispatchDays() {
  const { today } = useSelector(selectUserConfiguration)

  const { horizonView, horizonOpt } = useSelector(selectTerritoryLicensingLimits)
  const routes = useSelector(selectRoutes)
  const rawOrders = useSelector(selectOrders)
  const approvedPlans = useSelector(selectApprovedPlans)

  const routesByDate = aggregateRoutesByDate(routes)

  const intervalLength = Math.min(horizonOpt, horizonView)

  return useMemo<Result>(() => {
    const orders = aggregateOrders(rawOrders)
    const startOfTodayDate = dateUtils.parseDate(today)
    const datesInterval = {
      start: startOfTodayDate,
      end: addDays(startOfTodayDate, intervalLength),
    }

    const dates = eachDayOfInterval(datesInterval)

    const todayAsString = today
    const tomorrowAsString = format(addDays(startOfTodayDate, 1), 'yyyyMMdd')

    let dispatchedDaysCount: number = 0

    const days = dates.map(date => {
      const dateAsString = format(date, 'yyyyMMdd')
      const routesAsArray = routesByDate[dateAsString] || []
      const routesCount = routesAsArray.length

      const approvedPlanData = approvedPlans[dateAsString]

      // check if any routes for the day have an edge
      const someRoutesAreAlreadyExecuted = routesAsArray.some(route => {
        return (route.route.edge?.timeSec ?? -1) > -1
      })

      // check if the approved plans has already a day or if any route for that day has an active edge
      const dispatched = !!approvedPlanData || someRoutesAreAlreadyExecuted

      const dispatchedRoutesCount = approvedPlanData?.approvedRoutes
        ? Object.keys(approvedPlanData?.approvedRoutes).length
        : 0

      const hasExecutionEvents = routesAsArray.some(r => hasOrdersWithExecutionEvents(r, orders))
      const anyRouteOutdated = routesAsArray.some(r => isRouteOutdated(r.route, approvedPlanData))

      const canBeUpdated = !hasExecutionEvents && anyRouteOutdated

      const canBeDispatched = !dispatched

      const ordersCount = routesAsArray.reduce((acc, route) => {
        return (
          acc +
          route.route.steps.filter(
            routeStep => routeStep.type === 'pickup' || routeStep.type === 'delivery',
          ).length
        )
      }, 0)

      if (dispatched) {
        dispatchedDaysCount++
      }

      return {
        id: dateAsString,
        date,
        dispatched,
        routesCount,
        ordersCount,
        dispatchedRoutesCount,
        canBeUpdated,
        canBeDispatched,
        canBeRevoked: !hasExecutionEvents,
        inProgress: hasExecutionEvents,
        isToday: todayAsString === dateAsString,
        isTomorrow: tomorrowAsString === dateAsString,
      }
    })

    return {
      days,
      dispatchedDaysCount,
      dispatchedRoutesCount: days.reduce((acc, curr) => acc + curr.dispatchedRoutesCount, 0),
    }
  }, [today, rawOrders, routesByDate, approvedPlans, intervalLength])
}
