import { Stack } from '@mui/material'

import { ExportAdornment } from '@/components/ExportAdornment'

import { useControllerActions } from '../../hooks/useControllerActions'
import { useController } from '../../hooks/useController'
import { MODAL_HEIGHT } from '../../constants'
import { Body } from './components/Body'

export function ConfigureTerritories() {
  const {
    data: { format },
  } = useController()

  const { onTerritoriesSelectionChange } = useControllerActions()

  return (
    <Stack mx={-4} direction="row" height={MODAL_HEIGHT}>
      <Stack flex="1 1 auto">
        <Body onTerritoriesSelectionChange={onTerritoriesSelectionChange} />
      </Stack>

      <Stack
        flex="0 0 auto"
        sx={{
          [`& > *`]: {
            height: '100%',
          },
        }}
      >
        <ExportAdornment logo={format} />
      </Stack>
    </Stack>
  )
}
