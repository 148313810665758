import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    emailRequired: translate({
      id: 'sms4.settings.communication.notifications.testModal.body.error.email.required',
    }),
    emailInvalid: translate({
      id: 'sms4.settings.communication.notifications.testModal.body.error.email.invalid',
    }),
    phoneRequired: translate({
      id: 'sms4.settings.communication.notifications.testModal.body.error.phone.required',
    }),
    phoneInvalid: translate({
      id: 'sms4.settings.communication.notifications.testModal.body.error.phone.invalid',
    }),

    phoneLabel: translate({
      id: 'sms4.settings.communication.notifications.testModal.body.label.phone',
    }),

    phoneHelper: translate({
      id: 'sms4.settings.communication.notifications.testModal.body.helper.phone',
    }),
  }))

  return api
}
