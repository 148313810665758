import { Component, MouseEventHandler } from 'react'

import { Left } from '@/icons'

import { DropdownItem } from '../typings'
import DropDownButton from './DropDownButton'
export interface Props {
  values: DropdownItem[]
  selectedIds: string[]
  onClick: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

export default class PrevButton extends Component<Props> {
  render() {
    const { values, selectedIds, disabled: dropDowndisabled, onClick } = this.props

    const noValues: boolean = values.length === 0
    const isFirst: boolean = !!selectedIds[0] && !!values[0] && values[0].id === selectedIds[0]
    const disabled = dropDowndisabled || noValues || isFirst

    return <DropDownButton type={'button'} icon={<Left />} disabled={disabled} onClick={onClick} />
  }
}
