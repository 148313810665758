import { useTransactionInProgress } from './useTransactionInProgress'
import { useTexts } from './useTexts'

type TransactionInProgressTooltipData = {
  title: string
  subtitle?: string
} & (
  | {
      mode: 'transactionInProgress'
      preventEditing: true
    }
  | {
      mode: 'canEdit'
      preventEditing: false
    }
)

export type TransactionInProgressReason = 'canEdit' | 'transactionInProgress'

/**
 * This is a custom version of the hook used internally by ReadonlyTooltip.
 */
export function useTransactionInProgressTooltipData(): TransactionInProgressTooltipData {
  const { cause } = useTransactionInProgress()
  const texts = useTexts()

  if (cause === 'transactionInProgress')
    return {
      mode: 'transactionInProgress',
      preventEditing: true,
      title: texts.transactionInProgressTitle,
    }

  return {
    mode: 'canEdit',
    preventEditing: false,
    title: '',
    subtitle: '',
  }
}
