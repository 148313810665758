import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Duplicate(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M7 2C4.19922 2 2 4.19922 2 7v27c0 2.80078 2.19922 5 5 5h27c2.80078 0 5-2.19922 5-5V7c0-2.80078-2.19922-5-5-5zm34 9v24c0 3.30078-2.69922 6-6 6H11v2c0 2.80078 2.19922 5 5 5h27c2.80078 0 5-2.19922 5-5V16c0-2.80078-2.19922-5-5-5z" />
      </svg>
    </IconContainer>
  )
}
