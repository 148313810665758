import { Box, Skeleton } from '@mui/material'

import { VerticalLayout, HorizontalLayout } from '@/components/layout'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Text } from '@/local/components'

import { type GpsInfoWithColor } from '../../../../../types'
import { useTexts } from '../useTexts'
import { DeviceTag } from './DeviceTag'

interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
  point: GpsInfoWithColor
}

export function DeviceInfo(props: Props) {
  const { device, point } = props
  const texts = useTexts()

  const text = `${device.label} - ${texts.breadcrumbPoint}`

  return (
    <Box minWidth={260} height={46}>
      <VerticalLayout justifyContent="space-between">
        <div style={{ maxWidth: '100%' }}>
          <Tooltip title={text} placement="top">
            <Text weight="$semiBold" ellipsis>
              {text}
            </Text>
          </Tooltip>
        </div>

        <HorizontalLayout alignItems="center">
          <DeviceTag device={device} />
          <Box marginLeft={1} style={{ overflow: 'hidden' }}>
            {point.address ? (
              <Tooltip title={point.address} placement="top">
                <Text size="$s" italic ellipsis>
                  {point.address}
                </Text>
              </Tooltip>
            ) : (
              <Skeleton width={200} height={29} animation="wave" />
            )}
          </Box>
        </HorizontalLayout>
      </VerticalLayout>
    </Box>
  )
}
