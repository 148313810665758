import { type CSSProperties, useCallback } from 'react'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { NewScheduler, OrdersGrid } from '@/icons'

import { SearchInput } from '@/components/primitives/SearchInput'
import * as LocalTexts from './intl'
import { Props } from './typings'

import { useShowHideScheduler } from './hooks/useShowHideScheduler'

const containerStyle: CSSProperties = { display: 'flex', alignItems: 'center' }

export const SearchOrdersBar = (props: Props) => {
  const {
    filteredOrdersIds,
    selectOrderSteps,
    showScheduler,
    showSearch,
    showSwitch,
    setFilter,
    filter,
  } = props

  const { onRadioChange, radioValue } = useShowHideScheduler(showScheduler)

  const handleOnFilterChange = useCallback(
    (value: string) => {
      setFilter(value)
    },
    [setFilter],
  )

  const handleOnCounterClick = useCallback(() => {
    if (filteredOrdersIds.length > 0) {
      // TODO: additional parameters are not handled yet
      // selectOrderSteps(filteredOrdersIds, undefined, true)
      selectOrderSteps(filteredOrdersIds)
    }
  }, [selectOrderSteps, filteredOrdersIds])

  return (
    <div style={containerStyle}>
      {showSwitch && (
        <ToggleButtonGroup
          data-testid="new-scheduler-switcher"
          data-trackid="new-scheduler-switcher"
          size="small"
          value={radioValue}
          exclusive
          onChange={onRadioChange}
        >
          <ToggleButton sx={{ padding: 0 }} value="scheduler">
            <Tooltip
              containerStyle={{ display: 'flex', padding: '6.375px', color: '#757575' }}
              placement="bottom"
              title="Scheduler"
            >
              <NewScheduler size={16} />
            </Tooltip>
          </ToggleButton>

          <ToggleButton sx={{ padding: 0 }} value="orders-grid">
            <Tooltip
              containerStyle={{ display: 'flex', padding: '6.375px', color: '#757575' }}
              placement="bottom"
              title="Orders grid"
            >
              <OrdersGrid size={16} />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {showSearch && (
        <SearchInput
          className="pendo-order__search"
          filter={filter}
          onChange={handleOnFilterChange}
          placeholder={LocalTexts.getSearchText()}
          extraLabelClassName="pendo-order__search-results"
          testId="scheduler-filter-orders"
          trackid="scheduler-filter-orders"
          extraLabelOnClick={filteredOrdersIds.length > 0 ? handleOnCounterClick : undefined}
          extraLabel={
            filter.trim().length > 0
              ? LocalTexts.getOrdersCounterText(filteredOrdersIds.length)
              : undefined
          }
        />
      )}
    </div>
  )
}
