import type TileSource from 'ol/source/Tile'
import type { MapTile } from '@/map'
import OlMap from 'ol/Map'

import { OverviewMap } from 'ol/control'
import { createXYZ } from 'ol/tilegrid'
import TileLayer from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'

import { getTileUrl, authenticatedRasterTileLoadFunction } from '@/map'

import { useTexts } from '../../useTexts'

let tileType: MapTile = 'normal'

export function registerOverviewMapControl(
  map: OlMap,
  tile: MapTile,
  mapTilesLayer: TileLayer<TileSource>,
  texts: ReturnType<typeof useTexts>,
) {
  tileType = tile
  const uniqueId = 'rm-overview-map'
  const controls = map.getControls()

  if (controls.get(uniqueId)) return

  const tileLayerSource = new XYZ({
    url: getTileUrl({
      tileType: tileType === 'normal' ? 'hybrid' : 'normal',
    }),
    tileLoadFunction: authenticatedRasterTileLoadFunction,
    tileGrid: createXYZ({
      // extent: Base_bounds,
      minZoom: 3,
      maxZoom: 42,
      tileSize: 64,
    }),
    crossOrigin: 'Anonymous',
  })

  const tileLayer = new TileLayer({
    preload: 0, // Infinity,
    source: tileLayerSource,
  })

  const overviewMapControl = new OverviewMap({
    layers: [tileLayer],
    className: 'ol-overviewmap ol-custom-overviewmap',
    collapsed: false,
    collapsible: false,
  })

  const overviewMapInstance = overviewMapControl.getOverviewMap()

  overviewMapInstance.on('click', _e => {
    // Update the tile type for the main map
    tileType = tileType === 'normal' ? 'hybrid' : 'normal'

    // Compute the tile type for the overview map
    const overviewMapTileType = tileType === 'normal' ? 'hybrid' : 'normal'

    // Update the tile type for the main map
    const source = mapTilesLayer.getSource() as XYZ
    if (!source) return

    // Update the tile type for the main map
    source.setUrl(getTileUrl({ tileType }))

    // Update the tile type for the overview map
    tileLayerSource.setUrl(
      getTileUrl({
        tileType: overviewMapTileType,
      }),
    )

    // Update the tooltip
    const overviewMapElement = overviewMapControl.getOverviewMap().getViewport()
      .firstChild as HTMLElement

    overviewMapElement.setAttribute(
      'title',
      tileType === 'hybrid' ? texts.switchToMap : texts.switchToSatellite,
    )
  })

  // Set the tooltip
  setTimeout(() => {
    const overviewMapElement = overviewMapControl.getOverviewMap().getViewport()
      .firstChild as HTMLElement

    overviewMapElement.setAttribute(
      'title',
      tile === 'hybrid' ? texts.switchToMap : texts.switchToSatellite,
    )
  }, 300)

  // Set the unique id to the control
  overviewMapControl.set('uid', uniqueId)

  // Add the control to the map
  map.addControl(overviewMapControl)

  // Add the control to the controls map
  controls.set(uniqueId, overviewMapControl)
}
