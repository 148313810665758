import { useSelector } from 'react-redux'

import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { selectTrafficTimeWindows } from '@/features/domain/traffic'

import { TrafficRegionAvatar } from '../../TrafficRegionAvatar'
import { useTrafficArea } from '../hooks/useTrafficArea'

import { ViewDetails } from '../../ViewDetails'

import { ActionsManager } from './components/ActionsManager'
import { useGoBack } from './hooks/useGoBack'
import { useTexts } from './hooks/useTexts'

export function SingleView() {
  const timeWindows = useSelector(selectTrafficTimeWindows)
  const trafficArea = useTrafficArea()
  const goBack = useGoBack()
  const texts = useTexts()

  if (!trafficArea) return null

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.title(trafficArea.name)}
        description={texts.description(trafficArea.name)}
        action={goBack}
        avatar={<TrafficRegionAvatar region={trafficArea} />}
      />
      <ActionsManager trafficArea={trafficArea} />
      <FormContent footer={<></>}>
        <ViewDetails
          timeWindows={timeWindows}
          levels={[trafficArea.level]}
          timeWindowsLevels={[trafficArea.timeWindowLevels]}
        />
      </FormContent>
    </FormColumn>
  )
}
