import { useTexts } from '../useTexts'

export function useWarningCardTexts(multipleOrderSteps: boolean, isForceFitting: boolean) {
  const texts = useTexts()

  if (multipleOrderSteps) {
    return {
      title: isForceFitting ? texts.forceAssignmentTitle : texts.fittingOptionsTitle,
      description: isForceFitting
        ? texts.multipleForceAssignmentText
        : texts.multipleFittingOptionsText,
    }
  }

  return {
    title: isForceFitting ? texts.forceAssignmentTitle : texts.fittingOptionsTitle,
    description: isForceFitting ? texts.forceAssignmentText : texts.fittingOptionsText,
  }
}
