import type { PreferencesProps } from '@/notificationForms'

import { useMemo, useCallback } from 'react'

import { useSelector } from 'react-redux'

import {
  selectNotificationSupportedLanguages,
  selectNotificationPreferences,
  updateNotificationConfig,
  selectTerritoryId,
} from '@/features/domain/territory'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function usePreferencesProps(
  inEditing: boolean,
  setEditingState: (inEditing: boolean) => void,
) {
  const supportedLanguages = useSelector(selectNotificationSupportedLanguages)
  const notificationPreferences = useSelector(selectNotificationPreferences)
  const territoryId = useSelector(selectTerritoryId)
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const updatePreferences = useCallback(
    async (payload: uui.domain.client.rm.NotificationPreferences) => {
      try {
        const result = await dispatch(
          updateNotificationConfig({ territoryId, notificationConfig: payload }),
        )

        if (!updateNotificationConfig.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        return true
      } catch (error) {
        toast.error(error.message)

        return false
      }
    },
    [dispatch, toast, territoryId],
  )

  return useMemo<PreferencesProps>(
    () => ({
      notificationPreferences,
      supportedLanguages,
      setEditingState,
      inEditing,
      updatePreferences,
    }),
    [supportedLanguages, updatePreferences, notificationPreferences, setEditingState, inEditing],
  )
}
