import type { FormValues } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields } from '../../../formFields'
import { configuredFields } from '../configuredFields'

// Define a function to compute initial values for the form based on the overrideValues passed in
export function computeInitialValues(overrideValues: uui.domain.server.rm.MobileAppInteraction) {
  const initValues: Partial<FormValues<FormFields>> = {}

  // Use a switch statement to handle different cases based on the overrideValues.type
  switch (overrideValues.type) {
    case 'ASSISTED_TIME_IN_OUT':
      // If the type is 'ASSISTED_TIME_IN_OUT', set the type to 'ASSISTED_TIME_OUT' and set checkIn to true
      initValues.type = 'ASSISTED_TIME_OUT'
      initValues.checkIn = true
      break

    case 'DRIVER_REQUIRED_FLOW':
      // If the type is 'DRIVER_REQUIRED_FLOW', set the type to the value of overrideValues.type
      initValues.type = overrideValues.type

      // Then iterate through the configuredFields array and set the values for each field if the value is not 'DO_NOT_REQUIRE'
      for (const field of configuredFields) {
        if (overrideValues.driverRequiredFlow[field] !== 'DO_NOT_REQUIRE') {
          // If the value is not 'DO_NOT_REQUIRE', set the value of the field and set the value of `${field}Checked` to true
          initValues[`${field}Checked`] = true
          initValues[field] = overrideValues.driverRequiredFlow[field]
        }
      }

      // Set checkIn to true and set checkOut to the value of overrideValues.driverRequiredFlow.mandatoryDeparture
      initValues.checkIn = true
      initValues.checkOut = overrideValues.driverRequiredFlow.mandatoryDeparture
      break

    default:
      // If the type is not 'ASSISTED_TIME_IN_OUT' or 'DRIVER_REQUIRED_FLOW', set the type to the value of overrideValues.type
      initValues.type = overrideValues.type
      break
  }

  /**
   * Iterate through the configuredFields array and set the values for each field
   * based on the values of `${field}Checked` and the corresponding field value in overrideValues
   **/
  for (const field of configuredFields) {
    if (initValues[`${field}Checked`]) {
      /**
       * If the value of `${field}Checked` is true, set the value of the field to the value of the
       * corresponding field in initValues if the value is not 'DO_NOT_REQUIRE', or set it to
       * 'REQUIRED_TO_COMPLETE' if the value is 'DO_NOT_REQUIRE'
       **/
      initValues[field] =
        initValues[field] !== 'DO_NOT_REQUIRE' ? initValues[field] : 'REQUIRED_TO_COMPLETE'
    } else {
      // If the value of `${field}Checked` is false, set the value of the field to 'DO_NOT_REQUIRE'
      initValues[field] = 'DO_NOT_REQUIRE'
    }
  }

  // Return the computed initial values
  return initValues
}
