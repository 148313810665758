import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  setTenantSourceState,
  setTelematicsDeviceStatus,
  debugSendTestDataToTelematics,
  debugSendPeriodicTestDataToTelematics,
} from '@/features/domain/demoTools'
import { selectTelematicsDevicesAsArrayWithActiveStatus } from '@/features/domain/device'
import { selectTelematicsTenantSourcesAsArray } from '@/features/domain/account'
import { useAppDispatch } from '@/store'

/**
 * Expose a test action that overrides the tenant source state.
 */
export function useExposeTelematicsActions(qaEnabled: boolean) {
  const dispatch = useAppDispatch()
  const devices = useSelector(selectTelematicsDevicesAsArrayWithActiveStatus)
  const tenantSources = useSelector(selectTelematicsTenantSourcesAsArray)

  const setTenantSourceStateAction = useCallback(
    async (payload: uui.domain.actions.rpc.demoTools.SetTenantSourceStateAction['payload']) => {
      dispatch(setTenantSourceState(payload))
    },
    [dispatch],
  )

  const setTelematicsDeviceStatusAction = useCallback(
    (payload: uui.domain.actions.rpc.demoTools.SetTelematicsDeviceStatusAction['payload']) => {
      dispatch(setTelematicsDeviceStatus(payload))
    },
    [dispatch],
  )

  const sendTestDataToTelematicsAction = useCallback(
    (payload: uui.domain.actions.rpc.demoTools.DebugSendTestDataToTelematicsAction['payload']) => {
      return dispatch(debugSendTestDataToTelematics(payload))
    },
    [dispatch],
  )

  const sendTestDataToTelematicsPeriodicallyAction = useCallback(
    (
      payload: uui.domain.actions.rpc.demoTools.DebugSendPeriodicTestDataToTelematicsAction['payload'],
    ) => {
      return dispatch(debugSendPeriodicTestDataToTelematics(payload))
    },
    [dispatch],
  )

  const getTenantSourceListAction = useCallback(() => {
    return tenantSources
  }, [tenantSources])

  const getTelematicsActiveDevicesAction = useCallback(() => {
    return devices
  }, [devices])

  // register/unregister the test action
  useEffect(() => {
    if (!qaEnabled) return

    globalThis.testActions ??= {}
    globalThis.telematics ??= {}
    globalThis.testActions.telematics = {
      sendTestDataToTelematicsPeriodically: sendTestDataToTelematicsPeriodicallyAction,
      getTelematicsActiveDevices: getTelematicsActiveDevicesAction,
      setTelematicsDeviceStatus: setTelematicsDeviceStatusAction,
      sendTestDataToTelematics: sendTestDataToTelematicsAction,
      setTenantSourceState: setTenantSourceStateAction,
      getTenantSourceList: getTenantSourceListAction,
    }

    return () => void delete globalThis.testActions?.setTenantSourceState
  }, [
    qaEnabled,
    getTenantSourceListAction,
    setTenantSourceStateAction,
    sendTestDataToTelematicsAction,
    setTelematicsDeviceStatusAction,
    getTelematicsActiveDevicesAction,
    sendTestDataToTelematicsPeriodicallyAction,
  ])
}
