import { Alert, Stack, Typography } from '@mui/material'
import { useTexts } from '../useTexts'

export function NoTenantSourcesAlert() {
  const texts = useTexts()

  return (
    <Stack paddingY={1} spacing={3}>
      <Alert severity="error">
        <Stack spacing={1}>
          <Typography variant="h6">{texts.noTenantSources.title}</Typography>
          <Typography variant="body2">{texts.noTenantSources.description}</Typography>
        </Stack>
      </Alert>
    </Stack>
  )
}
