export function drawStyleCommon() {
  return {
    white: (alpha = 1) => [255, 255, 255, alpha],
    blue: [0, 123, 255, 1],
    green: [46, 210, 72, 1],
    black: (alpha = 1) => [0, 0, 0, alpha],
    red: (alpha = 1) => [250, 97, 63, alpha],

    lineWidth: 2,
    pointRadius: 6,
    pointBorderWidth: 2,
  }
}
