import * as gis from './gis'

export const GEO_FLT_PRECISION: number = 1e6

export const encodeFloat = (flt: number): number => Math.floor(flt * GEO_FLT_PRECISION)

export const decodeFloat = (int: number): number => int / GEO_FLT_PRECISION

export const latLngToJSON = (latLng: uui.domain.LatLng): uui.domain.server.LatLngTuple => {
  return [encodeFloat(latLng.lat), encodeFloat(latLng.lng)]
}

export const latLngFromJSON = (latLng: uui.domain.server.LatLngTuple): uui.domain.LatLng => {
  return gis.createLatLng(decodeFloat(latLng[0]), decodeFloat(latLng[1]))
}

type CardinalDirections =
  | 'N'
  | 'NNE'
  | 'NE'
  | 'ENE'
  | 'E'
  | 'ESE'
  | 'SE'
  | 'SSE'
  | 'S'
  | 'SSW'
  | 'SW'
  | 'WSW'
  | 'W'
  | 'WNW'
  | 'NW'
  | 'NNW'
type CardinalDirectionInfo = { min: number; max: number }
const cardinalDirectionsMap = new Map<CardinalDirections, CardinalDirectionInfo>([
  ['N', { min: 0, max: 9 }],
  ['N', { min: 350, max: 360 }],
  ['NNE', { min: 9, max: 35 }],
  ['NE', { min: 35, max: 55 }],
  ['ENE', { min: 55, max: 80 }],
  ['E', { min: 80, max: 100 }],
  ['ESE', { min: 100, max: 125 }],
  ['SE', { min: 125, max: 145 }],
  ['SSE', { min: 145, max: 170 }],
  ['S', { min: 170, max: 190 }],
  ['SSW', { min: 190, max: 215 }],
  ['SW', { min: 215, max: 235 }],
  ['WSW', { min: 235, max: 260 }],
  ['W', { min: 260, max: 280 }],
  ['WNW', { min: 280, max: 305 }],
  ['NW', { min: 305, max: 325 }],
  ['NNW', { min: 325, max: 350 }],
])
export const computeCardinalDirection = (heading: number): CardinalDirections | 'INVALID' => {
  if (heading < 0 || heading > 360) {
    return 'INVALID'
  }

  if (heading === 0) {
    return 'N'
  }

  for (const [cardinalDirection, info] of cardinalDirectionsMap.entries()) {
    const { min, max } = info

    if (heading > min && heading <= max) {
      return cardinalDirection
    }
  }

  return 'INVALID'
}

const verboseCardinalDirections: Record<CardinalDirections, string> = {
  N: 'North',
  NNE: 'North North-East',
  NE: 'North-East',
  ENE: 'East North-East',
  E: 'East',
  ESE: 'East South-East',
  SE: 'South-East',
  SSE: 'South South-East',
  S: 'South',
  SSW: 'South South-West',
  SW: 'South-West',
  WSW: 'West South-West',
  W: 'West',
  WNW: 'West North-West',
  NW: 'North-West',
  NNW: 'North North-West',
} as const

export const computeVerboseCardinalDirection = (heading: number): string => {
  return verboseCardinalDirections[computeCardinalDirection(heading)] ?? 'unknown'
}

export const urlFromLatLng = (latLng: uui.domain.LatLng): string => {
  const { lat, lng } = latLng
  return `http://maps.google.com/?q=${lat},${lng}`
}
