import OlMap from 'ol/Map'
import { Extent } from 'ol/extent'
import Geometry from 'ol/geom/Geometry'

import { createNonBlockingRenderByTime } from '../../../../renderingQueue'
import { getDepotFeatureMetadata } from '../../../../layers/depot/depotFeatureMetadata'
import { findLayer } from '../../../../layers/utils/findLayer'

const nonblockingRender = createNonBlockingRenderByTime()

export async function selectDepotFeatureInExtent(
  map: OlMap,
  lassoPolygon: Geometry,
  lassoExtent: Extent,
) {
  const depotLayer = findLayer(map, 'depot')
  const selectedIds: string[] = []

  const featuresInExtent = depotLayer.getSource()?.getFeaturesInExtent(lassoExtent) ?? []

  nonblockingRender.reset()

  for (const feature of featuresInExtent) {
    await nonblockingRender.next()

    const mode = getDepotFeatureMetadata(feature, 'mode')
    if (mode === 'off') continue

    const recordId = feature.getId() as string

    if (recordId) {
      const extent = feature.getGeometry()?.getExtent()

      if (extent && lassoPolygon.intersectsExtent(extent)) {
        selectedIds.push(recordId)
      }
    }
  }

  return selectedIds
}
