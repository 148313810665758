import { useEffect } from 'react'

import { useNavigate } from '@/routing'

import { useComputeSelectedDeviceIds } from './useComputeSelectedDeviceIds'

// checks the selection of device/unified vehicles
// if selection drops to 0 redirect to tracking page
// use case is when user changes the territory

export function useRedirectWhenNoSelection() {
  const navigate = useNavigate<uui.routing.MainSections>(true)
  const selectionCount = useComputeSelectedDeviceIds().length

  useEffect(() => {
    if (selectionCount === 0) {
      navigate('tracking')
    }
  }, [selectionCount, navigate])
}
