import { PureComponent } from 'react'
import { Field as FinalFormField, FieldRenderProps } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import { TimeWindowField } from './TimeWindowField'
import { PublicProps as Props } from './typings'

export class TimeWindowFieldWrapper extends PureComponent<Props> {
  render() {
    const { name, ...props } = this.props

    return (
      <FinalFormField
        {...props}
        name={name}
        isEqual={isDeepEqual}
        render={(formProps: FieldRenderProps<any>) => {
          return <TimeWindowField {...this.props} formProps={formProps} />
        }}
      />
    )
  }
}
