import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { captureException } from '@sentry/react'
import { logoutService } from '@/services'

type Params = {
  serverUrl: string
}

type RejectError = {
  message: string
  error: Error
}
export const logout = createAsyncThunk<
  // Return type of the payload creator
  boolean,
  // First argument to the payload creator
  Params,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('lifecycle/logout', async (params, thunkApi) => {
  try {
    const { serverUrl } = params

    const authtoken = thunkApi.getState().domain.publicData.profile.authtoken

    // Let's silence the domainProxy to prevent offline notifications
    thunkApi.extra.domainProxy.preventNotifications(true)

    try {
      await logoutService({ authtoken, serverUrl })
    } catch (e) {
      console.error(e)
      captureException(e)
    }

    // Disconnect the socket
    const socketDisconnectResult = await thunkApi.extra.domainProxy.disconnect()

    return socketDisconnectResult
  } catch (error) {
    // If the logout fails let's re-enable the domainProxy notifications
    thunkApi.extra.domainProxy.preventNotifications(false)

    // TODO: add a proper localized message
    // JOURNAL: trigger the journal
    return thunkApi.rejectWithValue({
      message: 'Logout Failed',
      error,
    })
  }
})
