import { Box, styled } from '@mui/material'

import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { Tooltip } from '@/components/primitives/Tooltip'
import { theme } from '@/styles'
import { Bell } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  disabled: boolean
  count: number
}

const Counter = styled('div', { name: 'Counter' })({
  background: theme.colors.$sailBlue,
  color: theme.colors.$pureWhite,
  fontSize: theme.fonts.$xs,
  whiteSpace: 'nowrap',
  position: 'absolute',
  textAlign: 'center',
  padding: '0px 4px',
  lineHeight: '14px',
  borderRadius: 9,
  width: 'auto',
  minWidth: 18,
  height: 14,
  right: 0,
  top: 0,
})

export function EventsButton(props: Props) {
  const { disabled, onClick, count } = props

  const texts = useTexts()

  const iconColor = disabled ? '$silver' : '$nightRider'

  return (
    <Tooltip placement="top" disabled={!disabled} title={texts.disabledForBeta}>
      <Box position="relative">
        <NavigoIconButton
          onClick={onClick}
          disabled={disabled}
          text={texts.events}
          Icon={<Bell size={16} color={iconColor} />}
          testid="navigo-device-overview-actions-events"
        />

        <Counter>{count}</Counter>
      </Box>
    </Tooltip>
  )
}
