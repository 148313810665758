import styled from 'styled-components'

const styledComponent = styled.div.attrs({
  className: 'o-store-links__title',
})`
  padding-top: 10px;
  font-size: 12px;
`

export default styledComponent
