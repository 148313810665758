import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    backToLogin: translate({ id: 'login-next.resetPassword.backToLogin' }),
    resetPassword: {
      logo: translate({ id: 'login-next.resetPassword.title' }),
      fields: {
        newPassword: {
          info: '',
          label: translate({ id: 'login-next.resetPassword.fields.newPassword.label' }),
          required: translate({
            id: 'login-next.resetPassword.fields.newPassword.validations.required',
          }),
          placeholder: translate({ id: 'login-next.resetPassword.fields.newPassword.placeholder' }),
        },
        repeatPassword: {
          info: '',
          label: translate({ id: 'login-next.resetPassword.fields.repeatPassword.label' }),
          required: translate({
            id: 'login-next.resetPassword.fields.repeatPassword.validations.required',
          }),
          placeholder: translate({
            id: 'login-next.resetPassword.fields.repeatPassword.placeholder',
          }),
        },
      },
      reset: {
        label: translate({ id: 'login-next.resetPassword.reset.label' }),
        tooltip: '',
      },
      resetDescription: '',
      passwordMismatchError: translate({
        id: 'login-next.resetPassword.validations.passwordMismatch',
      }),
    },
  }))

  return api
}
