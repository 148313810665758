import { proxy } from 'valtio'

export type EnvironmentType = 'qa' | 'dev' | 'local' | 'test-a' | 'test-b' | 'production'

export interface EnvironmentCore {
  httpHost: string
  httpPort: number
  env: EnvironmentType
  qa: 'enable' | 'disable'
}

export const productionHostname = 'routemanager.workwave.com'

export const defaultProductionConfig: EnvironmentCore = {
  env: 'production',
  httpHost: 'https://wwrm.workwave.com',
  httpPort: 443,
  qa: 'disable',
}

interface EnvironmentConfig {
  core: EnvironmentCore
  urls: {
    serverUrl: string
    podsBaseUrl: string
    importWizardTemplateBaseUrl: string
  }
}

export type Environment = { status: 'pending' } | ({ status: 'ready' } & EnvironmentConfig)

export const environment = proxy<{ env: Environment }>({
  env: { status: 'pending' },
})
