import { useCallback } from 'react'

import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { resetCrudSelection } from '@/atoms'

import { usePendingFitMapOnMount } from '@/map'
import { useSegmentType } from '../../hooks/useSegmentType'
import { RoutingSegmentAvatar } from '../../components/RoutingSegmentAvatar'
import { ViewDetails } from '../../components/ViewDetails'

import { Actions } from './Actions'
import { useTexts } from './hooks/useTexts'

interface Props {
  roadSegment: uui.domain.client.rm.SegmentException
}

export function SingleView(props: Props) {
  const { roadSegment } = props
  const { name, malus, additionalDrivingTimeSec } = roadSegment

  usePendingFitMapOnMount()

  const segmentType = useSegmentType(roadSegment)
  const texts = useTexts()

  const onReset = useCallback(() => {
    resetCrudSelection('routingSegments')
  }, [])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.title(name)}
        description={texts.description(segmentType)}
        action={onReset}
        avatar={<RoutingSegmentAvatar segment={roadSegment} size={40} />}
        data-testid="roadSegments-readonly-header"
      />
      <Actions roadSegment={roadSegment} />
      <FormContent footer={<div />}>
        <ViewDetails additionalDrivingTimeSec={additionalDrivingTimeSec} malus={malus} />
      </FormContent>
    </FormColumn>
  )
}
