import { Stack, Alert, AlertTitle, Button, Tooltip } from '@mui/material'

import { useTexts } from './useTexts'

interface Props {
  onDeleteAllTimeWindows: () => void
  deleteAllTimeWindowsDisabled?: boolean
}

export function Mixed(props: Props) {
  const { onDeleteAllTimeWindows, deleteAllTimeWindowsDisabled } = props
  const texts = useTexts()

  return (
    <Stack spacing={1}>
      <Alert severity="warning">
        <AlertTitle>{texts.title}</AlertTitle>
        {texts.description}
      </Alert>
      <Stack direction="row" justifyContent="flex-end">
        <Tooltip
          title={deleteAllTimeWindowsDisabled ? texts.deleteAllTimeWindowsDisabledTooltip : ''}
        >
          <span>
            <Button
              variant="text"
              color="error"
              disabled={deleteAllTimeWindowsDisabled}
              onClick={onDeleteAllTimeWindows}
              data-testid="time-windows_delete-all"
            >
              {texts.removeAll}
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  )
}
