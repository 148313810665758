import { Completed, NotCompleted } from '@/icons'
import { Text } from '@/local/components'
import { VerticalLayout } from '@/components/layout'

import { useTexts } from './useTexts'

interface Props {
  status: uui.domain.client.rm.OrderStepTrackingData['status']
}

export function StatusIndicator(props: Props) {
  const texts = useTexts()

  if (props.status === 'reschedule') {
    return (
      <VerticalLayout
        width="auto"
        justifyContent="space-between"
        alignItems="center"
        data-testid="StatusIndicator"
      >
        <NotCompleted background="$outrageousRed" color="$pureWhite" size={24} />

        <Text size="$s" color="$nightRider">
          {texts.rescheduleStatus}
        </Text>
      </VerticalLayout>
    )
  }

  return (
    <VerticalLayout
      width="auto"
      justifyContent="space-between"
      alignItems="center"
      data-testid="StatusIndicator"
    >
      <Completed background="$pigmentGreen" color="$pureWhite" size={24} />

      <Text size="$s" color="$nightRider">
        {texts.doneStatus}
      </Text>
    </VerticalLayout>
  )
}
