import { ResourceModel } from '@bryntum/schedulerpro'
import { getAppLanguage } from '@/atoms'

export function vehicleSorter(item1: ResourceModel, item2: ResourceModel) {
  const vehicleName1 = item1.getData(
    'vehicleName',
  ) as uui.domain.client.rm.SchedulerResource['vehicleName']
  const vehicleName2 = item2.getData(
    'vehicleName',
  ) as uui.domain.client.rm.SchedulerResource['vehicleName']

  return vehicleName1.localeCompare(vehicleName2, getAppLanguage().language, {
    numeric: true,
    ignorePunctuation: false,
  })
}
