import { useEffect, useState } from 'react'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { TextField } from '@workwave-tidal/tidal/components'

import { type FormErrors, type FormFields } from '../../../../../formFields'
import { useTexts } from '../../../../../useTexts'

export function Password() {
  const [password, setPassword] = useState<string>('')
  const { fieldApi } = useFormField<'credentials', FormFields, FormErrors>('credentials')
  const texts = useTexts()

  useEffect(() => {
    const usernameCredential = fieldApi
      .getField()
      .value.find(credential => credential.fieldName === 'username') ?? {
      fieldName: 'username',
      value: '',
    }

    fieldApi.change([usernameCredential, { fieldName: 'password', value: password }])
  }, [password, fieldApi])

  return (
    <TextField
      type="password"
      name="password"
      size="medium"
      label={texts.verizonConnect.passwordLabel}
      value={password}
      onChange={e => setPassword(e.target.value)}
    />
  )
}
