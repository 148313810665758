import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    oneTimeTranslation: translate({ id: 'recurrences.oneTime' }),
    yearlyTranslation: translate({ id: 'recurrences.yearly' }),
    monthlyTranslation: translate({ id: 'recurrences.monthly' }),
    weeklyTranslation: translate({ id: 'recurrences.weekly' }),
  }))

  return api
}
