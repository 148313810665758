import { useCallback } from 'react'

import { useEditingStateWithCtx } from '@/atoms'
import { useNavigate } from '@/routing'
import { setInspectPin, setPendingFitMap, useInspectPin } from '@/map'

export function useInspectPinNavigoActions() {
  const { setEditing: setGeofenceEditing } = useEditingStateWithCtx('geofence')
  const { setEditing: setOrderEditing } = useEditingStateWithCtx('order')
  const { setEditing: setPlaceEditing } = useEditingStateWithCtx('place')
  const navigateSetup = useNavigate<uui.routing.Setup>(true)
  const navigate = useNavigate<uui.routing.Routing>(true)
  const [inspectPin] = useInspectPin()

  const createOrder = useCallback(() => {
    if (!inspectPin) return

    // Convert the inspect pin for order creation
    setPendingFitMap([inspectPin?.latLng])
    setInspectPin({ mode: 'converted', target: 'order' })

    // Set the order form to editing mode
    setOrderEditing([])

    // Navigate to the correct route
    navigate('orders')
  }, [setOrderEditing, navigate, inspectPin])

  const createGeofence = useCallback(() => {
    if (!inspectPin) return

    // Convert the inspect pin for order creation
    setPendingFitMap([inspectPin?.latLng])
    setInspectPin({ mode: 'converted', target: 'geofence' })

    // Set the order form to editing mode
    setGeofenceEditing([])

    // Navigate to the correct route
    navigateSetup('geofences')
  }, [setGeofenceEditing, navigateSetup, inspectPin])

  const createPlace = useCallback(() => {
    if (!inspectPin) return

    // Convert the inspect pin for place creation
    setPendingFitMap([inspectPin?.latLng])
    setInspectPin({ mode: 'converted', target: 'place' })

    // Set the place form to editing mode
    setPlaceEditing([])

    // Navigate to the correct route
    navigateSetup('places')
  }, [setPlaceEditing, navigateSetup, inspectPin])

  const close = useCallback(() => {
    setInspectPin('reset')
  }, [])

  return {
    close,
    createOrder,
    createGeofence,
    createPlace,
  }
}
