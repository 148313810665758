import { type ResourceModel } from '@bryntum/schedulerpro'
import { useMemo } from 'react'

import { useIntl } from '@/intl'
import { durationUtils } from '@/server-data'

import { useTexts } from '../useTexts'

export function useAggregateData(record: ResourceModel) {
  const texts = useTexts()
  const { translate } = useIntl()
  const formatDuration = durationUtils.formatSeconds(translate)

  return useMemo(() => {
    const approvedDrivingTime = record.get(
      'approvedDrivingTime',
    ) as uui.domain.client.rm.SchedulerResource['approvedDrivingTime']

    const approvedWorkingTime = record.get(
      'approvedWorkingTime',
    ) as uui.domain.client.rm.SchedulerResource['approvedWorkingTime']

    const approvedMaxVehicleWorkingTime = record.get(
      'approvedMaxVehicleWorkingTime',
    ) as uui.domain.client.rm.SchedulerResource['approvedMaxVehicleWorkingTime']

    const approvedMaxVehicleDrivingTime = record.get(
      'approvedMaxVehicleDrivingTime',
    ) as uui.domain.client.rm.SchedulerResource['approvedMaxVehicleDrivingTime']

    const approvedDrivingSaturation = record.get(
      'approvedDrivingSaturation',
    ) as uui.domain.client.rm.SchedulerResource['approvedDrivingSaturation']

    const approvedWorkingSaturation = record.get(
      'approvedWorkingSaturation',
    ) as uui.domain.client.rm.SchedulerResource['approvedWorkingSaturation']

    const approvedVehicleWorkingTimeWindow = record.get(
      'approvedVehicleWorkingTimeWindow',
    ) as uui.domain.client.rm.SchedulerResource['approvedVehicleWorkingTimeWindow']

    const approvedWorkingTimeTotal =
      approvedMaxVehicleWorkingTime > 0
        ? approvedMaxVehicleWorkingTime
        : approvedVehicleWorkingTimeWindow

    return {
      workingTime: {
        value: formatDuration(approvedWorkingTime, 'DURATION_HH_MM'),
        total:
          approvedWorkingTimeTotal > 0
            ? formatDuration(approvedWorkingTimeTotal, 'DURATION_HH_MM')
            : texts.unlimited,
        saturation: parseFloat(approvedWorkingSaturation.toFixed(2)),
      },
      drivingTime: {
        value: formatDuration(approvedDrivingTime, 'DURATION_HH_MM'),
        total:
          approvedMaxVehicleDrivingTime > 0
            ? formatDuration(approvedMaxVehicleDrivingTime, 'DURATION_HH_MM')
            : texts.unlimited,
        saturation: parseFloat(approvedDrivingSaturation.toFixed(2)),
      },
    }
  }, [record, formatDuration, texts])
}
