import type { LocationEditorProps } from './typings'

import { Block, Label, FieldMessage } from '@/forms-legacy'
import RestoreFieldButton from '../primitives/RestoreFieldButton'

import { useLocationActions } from './hooks/useLocationActions'
import { LocationEditorDropDown } from './LocationEditorDropDown'
import { useLocationAccuracy } from './hooks/useLocationAccuracy'
import { useFormFieldLocation } from './hooks/useFormFieldLocation'
import { useRemoveLocationPinOnLeaving } from './hooks/useRemoveLocationPinOnLeaving'
import { useConnectDropDownToLocationPin } from './hooks/useConnectDropDownToLocationPin'
import { useConnectLocationPinToFormField } from './hooks/useConnectLocationPinToFormField'
import { useConnectFormFieldToLocationPin } from './hooks/useConnectFormFieldToLocationPin'
import { useInitializeFormFieldLocationPin } from './hooks/useInitializeFormFieldLocationPin'
import { useConnectRestoreButtonToLocationPin } from './hooks/useConnectRestoreButtonToLocationPin'

export function LocationEditorField(props: LocationEditorProps) {
  const {
    name,
    info,
    label,
    pinId,
    formProps: {
      meta: { error, dirty, initial },
    },
    testid,
  } = props

  const location = useFormFieldLocation(props)

  useInitializeFormFieldLocationPin(props)
  useRemoveLocationPinOnLeaving(pinId)

  useConnectFormFieldToLocationPin(props)
  useConnectLocationPinToFormField(props)

  const handleOnRestore = useConnectRestoreButtonToLocationPin(props)
  const handleOnDropDownChange = useConnectDropDownToLocationPin(props)
  const { fixInvalidAddress, confirmLowAccuracy, renameLocation } = useLocationActions(pinId)

  const { status, withoutErrors } = useLocationAccuracy(location, error)

  return (
    <Block error={!!error} dirty={!!dirty} data-testid={testid}>
      <Label htmlFor={name}>
        {location?.address ?? label}
        <RestoreFieldButton
          dirty={dirty}
          initial={initial}
          onChange={handleOnRestore}
          style={{ marginLeft: 12 }}
        />
      </Label>

      <LocationEditorDropDown
        {...props}
        status={status}
        onDropDownChange={handleOnDropDownChange}
        onFixInvalidAddress={fixInvalidAddress}
        onConfirmLowAccuracy={confirmLowAccuracy}
        onRenameLocationChange={renameLocation}
      />

      {withoutErrors && !!info && <FieldMessage>{info}</FieldMessage>}
    </Block>
  )
}
