import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { type FormValidator, type FormConfig } from '@workwave-tidal/tidal/form-fairy'

import { selectCompanyByNameLowercase } from '@/features/domain/company'

import { type FormFields, type FormErrors } from '../formFields'
import { useTexts } from './useTexts'

type ValidationTexts = ReturnType<typeof useTexts>['validation']

export function useFormConfig(): FormConfig<FormFields, FormErrors> {
  const texts = useTexts()

  const companiesByName = useSelector(selectCompanyByNameLowercase)
  const companyByNameRef = useRef(companiesByName)
  useEffect(() => {
    companyByNameRef.current = companiesByName
  }, [companiesByName])

  // since the formConfig must not change (the form library will throw an error otherwise)
  // use the lazy initialization of the useState hook
  const [formConfig] = useState(() => ({
    validations: createFormValidation(texts.validation, companyByNameRef.current),
  }))

  return formConfig
}

const maxLength = 50

function createFormValidation(
  texts: ValidationTexts,
  companiesByName: Record<string, uui.domain.client.rm.Company>,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'name',
      fields: 'name',
      validator: createValidateName(texts, companiesByName),
    },
  ]
}

const createValidateName =
  (
    texts: ValidationTexts,
    companiesByName: Record<string, uui.domain.client.rm.Company>,
  ): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const { value: rawValue } = formApi.getField('name')
    const value = rawValue.toLowerCase()

    const emptyError: FormErrors = {
      id: 'invalidNameError',
      field: 'name',
      message: texts.empty,
      priority: 3,
    }

    const isEmpty = value.length === 0

    if (isEmpty) {
      return {
        invalidNameError: emptyError,
      }
    }

    const maxLengthError: FormErrors = {
      id: 'invalidNameError',
      field: 'name',
      message: texts.maxLength(maxLength),
      priority: 3,
    }

    const isTooLong = value.length > maxLength

    if (isTooLong) {
      return {
        invalidNameError: maxLengthError,
      }
    }

    const alreadyExistsError: FormErrors = {
      id: 'invalidNameError',
      field: 'name',
      message: texts.alreadyExists,
      priority: 3,
    }

    const alreadyExists = companiesByName[value]
    if (alreadyExists) {
      return {
        invalidNameError: alreadyExistsError,
      }
    }

    return {
      invalidNameError: null,
    }
  }
