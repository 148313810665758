import { useState } from 'react'

import { useStartTelematicsDemoModal } from '@/components/DemoTools/components/StartTelematicsDemoModal'
import { useImportDemoTerritoryModal } from '@/components/DemoTools/components/ImportDemoTerritoryModal'
import { useImportNewTerritoryModal } from '@/components/DemoTools/components/ImportNewTerritoryModal'
import { useRenameTerritoryModal } from '@/components/DemoTools/components/RenameTerritoryModal'
import { useDeleteTerritoryModal } from '@/components/DemoTools/components/DeleteTerritoryModal'
import { useChangeTerritoryModal } from '@/components/modals/ChangeTerritoryModal'
import { useTimeShiftPlanModal } from '@/components/DemoTools/components/TimeShiftPlanModal'

export const useTerritoryModals = () => {
  const { show: showStartTelematicsDemo, Modal: StartTelematicsDemoModal } =
    useStartTelematicsDemoModal()
  const { show: showImportNewTerritory, Modal: ImportNewTerritory } = useImportNewTerritoryModal()
  const { show: showRenameTerritory, Modal: RenameTerritory } = useRenameTerritoryModal()
  const { show: showDeleteTerritory, Modal: DeleteTerritory } = useDeleteTerritoryModal()
  const { show: showTimeShiftPlan, Modal: TimeShiftPlan } = useTimeShiftPlanModal()

  const { Modal: ChangeTerritory } = useChangeTerritoryModal()
  const { show: showImportDemoTerritory, Modal: ImportDemoTerritory } =
    useImportDemoTerritoryModal()

  const [territoryModals] = useState({
    showStartTelematicsDemo,
    showImportDemoTerritory,
    showImportNewTerritory,
    showDeleteTerritory,
    showRenameTerritory,
    showTimeShiftPlan,
    modals: (
      <>
        <ChangeTerritory />
        <TimeShiftPlan />
        <DeleteTerritory />
        <RenameTerritory />
        <ImportDemoTerritory />
        <ImportNewTerritory />
        <StartTelematicsDemoModal />
      </>
    ),
  })

  return territoryModals
}
