import { type SchedulerProConfig, type EventModel } from '@bryntum/schedulerpro'

import {
  selectOrderStepIdSchedulerMapping,
  selectSchedulerRoutes,
} from '@/features/domain/scheduler'
import { fitMapToSelection } from '@/map'
import { getMainSelection, setMainSelection } from '@/atoms'
import { store } from '@/store'

export const onEventMouseDown: SchedulerProConfig['onEventMouseDown'] = params => {
  const orderStepIdSchedulerMapping = selectOrderStepIdSchedulerMapping(store.getState())
  const { event, eventRecord, source } = params
  const { altKey, ctrlKey, metaKey, shiftKey } = event
  const modifiers = { altKey, ctrlKey, metaKey, shiftKey }

  const type = eventRecord.getData('type') as uui.domain.client.rm.SchedulerEvent['type']

  if (type === 'idle-time' || type === 'brk' || type === 'route-time-range-handles') return

  const isApprovedEvent = eventRecord.getData('isApproved')
  const selectedOrderSteps = getMainSelection(true).orderSteps
  const selectedOrderStepsSet = new Set(selectedOrderSteps)

  if (isApprovedEvent || !modifiers.shiftKey) {
    const steps = eventRecord.getData('steps') as uui.domain.client.rm.ExtendedOrderStep[]
    const stepIds = steps.map(step => step.id)

    const isAtDepot = eventRecord.getData('atDepot')

    // If event is a depot event and if some of the steps are already selected, we don't want to change the selection.
    if (isAtDepot && stepIds.some(id => selectedOrderStepsSet.has(id))) {
      return
    }

    // Add ids from substeps to the selection
    const idsToSelect = new Set<string>(stepIds)

    // If the event is on an approved route, add the event id for the current route to the selection
    if (eventRecord.getData('isApproved')) {
      idsToSelect.add(eventRecord.getData('id').replace('-approved', ''))
    }

    setMainSelection(
      'orderSteps',
      [...idsToSelect].filter(id => !!orderStepIdSchedulerMapping[id]),
      isApprovedEvent ? undefined : { modifiers },
    )

    // We need to wait for the selection to be updated before we can select the event.
    // This is required since approved events without a sibling event on a current route will not be present in the main selection.
    setTimeout(() => {
      // Fit map to selection
      fitMapToSelection({ preventIfVisible: true })
    }, 10)

    return
  }

  // If the user clicks on an event while holding the shift key, we want to select all the steps between the last selected step and the clicked step.
  const lastSelectedStepId =
    selectedOrderSteps.length > 0 ? selectedOrderSteps[selectedOrderSteps.length - 1] : undefined

  // If there's no last selected step, we just select the clicked event.
  if (!lastSelectedStepId) {
    // Add ids from substeps to the selection
    const idsToSelect = new Set<string>((eventRecord.getData('steps') ?? []).map(step => step.id))

    // If the event is on an approved route, add the event id for the current route to the selection
    if (eventRecord.getData('isApproved')) {
      idsToSelect.add(eventRecord.getData('id').replace('-approved', ''))
    }

    setMainSelection(
      'orderSteps',
      [...idsToSelect].filter(id => !!orderStepIdSchedulerMapping[id]),
      { modifiers },
    )

    // We need to wait for the selection to be updated before we can select the event.
    // This is required since approved events without a sibling event on a current route will not be present in the main selection.
    setTimeout(() => {
      fitMapToSelection({ preventIfVisible: true })
    }, 10)

    return
  }

  const lastSelectedStepResourceId = (
    source.eventStore.getById(lastSelectedStepId) as EventModel | undefined
  )?.resourceId

  const sameRoute =
    lastSelectedStepResourceId && eventRecord.resourceId === lastSelectedStepResourceId

  // If the last selected step and the clicked step are not on the same route let's add the clicked step to the selection.
  if (!sameRoute) {
    // Add ids from substeps to the selection
    const idsToSelect = new Set<string>((eventRecord.getData('steps') ?? []).map(step => step.id))

    // If the event is on an approved route, add the event id for the current route to the selection
    if (eventRecord.getData('isApproved')) {
      idsToSelect.add(eventRecord.getData('id').replace('-approved', ''))
    }
    setMainSelection(
      'orderSteps',
      [...idsToSelect].filter(id => !!orderStepIdSchedulerMapping[id]),
      { modifiers },
    )

    // We need to wait for the selection to be updated before we can select the event.
    // This is required since approved events without a sibling event on a current route will not be present in the main selection.
    setTimeout(() => {
      fitMapToSelection({ preventIfVisible: true })
    }, 10)

    return
  }

  const state = store.getState()
  const schedulerRoutes = selectSchedulerRoutes(state)
  const route = schedulerRoutes[eventRecord.resourceId]

  if (!route) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`Route ${eventRecord.resourceId} not found`)
    }
    return
  }

  const lastSelectedStepIndex = route.steps.findIndex(step => step.id === lastSelectedStepId)
  const clickedStepIndex = route.steps.findIndex(step => step.id === eventRecord.id)

  const startIndex = Math.min(lastSelectedStepIndex, clickedStepIndex)
  const endIndex = Math.max(lastSelectedStepIndex, clickedStepIndex)

  const finalSelection: string[] = []

  for (let i = startIndex; i <= endIndex; i++) {
    const step = route.steps[i]
    if (step.reload === true) {
      step.steps.forEach(s => {
        if (!finalSelection.includes(s.id)) {
          finalSelection.push(s.id)
        }
      })
    } else {
      finalSelection.push(step.id)
    }
  }

  setMainSelection('orderSteps', finalSelection, { modifiers })

  // We need to wait for the selection to be updated before we can select the event.
  // This is required since approved events without a sibling event on a current route will not be present in the main selection.
  setTimeout(() => {
    fitMapToSelection({ preventIfVisible: true })
  }, 10)
}
