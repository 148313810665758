import styled from 'styled-components'
import Comp, { Props } from './TimeWindowExceptionList'

const VERTICAL_GUTTER = 10

const getOpacity = ({ disabled }: Props) => (disabled ? 0.4 : 1)
const getPointerEvents = ({ disabled }: Props) => (disabled ? 'none' : 'auto')

const styledComponent = styled(Comp)`
  position: relative;
  width: 100%;

  opacity: ${getOpacity};
  pointer-events: ${getPointerEvents};

  > * + * {
    margin-top: ${VERTICAL_GUTTER}px;
  }
`
export default styledComponent
