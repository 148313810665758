import { useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

type ModalState = 'ready' | 'submitting'
interface ModalData {
  tenantSource: uui.domain.server.gps.telematics.TenantSource
}

export const modalId = 'editTenantSourceLabel'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = (
  tenantSource: uui.domain.server.gps.telematics.TenantSource,
) => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        tenantSource,
      },
    }),
  }))

  return useConfigureModalController<ModalData, ModalState>(modalId, options)
}
