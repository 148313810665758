import { useMemo } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'
import { IconButton } from '@/local/components'
import { Hamburger as HamburgerIcon } from '@/icons'
import { useToggleSidebar } from '@/hooks'

import { useTexts } from '../useTexts'

export function Hamburger() {
  const texts = useTexts()
  const { isSidebarOpen, toggleSidebar, canBeToggled } = useToggleSidebar()

  const hamburgerStyles = useMemo(
    () => ({
      willChange: 'transform',
      transition: 'transform 0.3s ease',
      transform: `rotate(${isSidebarOpen ? '90deg' : '0deg'})`,
    }),
    [isSidebarOpen],
  )

  return (
    <Tooltip
      placement="bottom"
      title={
        canBeToggled
          ? isSidebarOpen
            ? texts.collapseSidebar
            : texts.expandSidebar
          : texts.cannotCollapseSidebar
      }
    >
      <IconButton
        onClick={toggleSidebar}
        style={hamburgerStyles}
        disabled={!canBeToggled}
        data-testid="header__toggle-sidebar-button"
        data-trackid="header-hamburger"
        Icon={<HamburgerIcon size={14} color={canBeToggled ? '$pureWhite' : '$silver'} />}
      />
    </Tooltip>
  )
}
