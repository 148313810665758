import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'

export const useSimulationName = () => {
  const userConfig = useSelector(selectUserConfiguration)

  // An empty name cannot exists. That if-statement exists just for typings reasons.
  return userConfig.planType === 'simulation' ? userConfig.planName : ''
}
