import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'

import { findRegionMarker } from './findRegionMarker'
import { createRegionFeature } from './createRegionFeature'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Add new Features for all the Regions marked for creation in a Changelog coming form the data-layer
 */
export async function changelogAddRegionFeatures(
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.Area>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['region'],
  selection: Set<string>,
  markerIds: IterableIterator<string>,
) {
  const features: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findRegionMarker(markers, markerId)
    const selected = selection.has(markerId)

    features.push(createRegionFeature(marker, mapStyles, selected))
  }

  // add all new features to the layer
  layer.getSource()?.addFeatures(features)

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
