import { isValid, parse } from 'date-fns/esm'
import { renderToStaticMarkup } from 'react-dom/server'
import { type SchedulerProConfig } from '@bryntum/schedulerpro'

import { selectUserConfiguration } from '@/features/domain/user'
import { getDayLabelFromIndex } from '@/intl'
import { timeUtils } from '@/server-data'
import { store } from '@/store'

import { schedulerPreferencesAtom } from '../../../atoms/schedulerPreferences'

export const createGroupFeature = (): SchedulerProConfig['features']['group'] => {
  return {
    field:
      schedulerPreferencesAtom.preferences.groupBy === 'vehicleId' ? 'vehicleName' : 'dateAsString',

    renderer: params => {
      // if we are grouping by date the groupRowFor will be a date formatted as yyyyMMdd
      // otherwise it will be a vehicle name
      const { groupRowFor, record, column } = params

      if (column.getData('field') !== 'vehicleAvatar') return ''

      //@ts-expect-error
      const cell = params.cellElement as HTMLElement

      cell.classList.add('b-group-title')

      const parsedDate = parse(groupRowFor, 'yyyyMMdd', new Date())

      //@ts-expect-error
      const { collapsed } = record.meta

      return renderToStaticMarkup(
        <div className="o-scheduler-group-title-container">
          <div className="o-scheduler-group-title-container-caret">
            {collapsed ? (
              <svg viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
                <path d="m7 10 5 5 5-5z"></path>
              </svg>
            ) : (
              <svg viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
                <path d="m7 14 5-5 5 5z"></path>
              </svg>
            )}
          </div>

          {isValid(parsedDate)
            ? `${getDayLabelFromIndex(parsedDate.getDay(), 'short')} • ${timeUtils.formatDate(
                selectUserConfiguration(store.getState()),
              )(parsedDate)}`
            : groupRowFor}
        </div>,
      )
    },
  }
}
