import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import {
  selectTerritoryId,
  selectVoiceConfig,
  updateVoiceConfig as updateVoiceConfigAction,
} from '@/features/domain/territory'

import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useVoiceData() {
  const voiceConfig = useSelector(selectVoiceConfig)
  const territoryId = useSelector(selectTerritoryId)
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const updateVoiceConfig = useCallback(
    async (value: uui.domain.client.rm.VoiceConfig) => {
      try {
        const result = await dispatch(updateVoiceConfigAction({ territoryId, voiceConfig: value }))

        if (updateVoiceConfigAction.rejected.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error('ERROR') // @VOICE Replace it with correct localized messages
      }
    },
    [dispatch, toast, territoryId],
  )

  return [voiceConfig, updateVoiceConfig] as const
}
