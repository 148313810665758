import type { ReactChild } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'
import { ReadonlyLabel, TextButton, FlexRow } from '@/forms-legacy'

import { Triangle } from './Triangle'
import { useTexts } from './useTexts'

interface Props {
  collapsed: boolean
  toggleCollapsed: () => void
  children: ReactChild
}

export function CollapseButton(props: Props) {
  const { collapsed, toggleCollapsed, children } = props
  const texts = useTexts()

  return (
    <TextButton
      style={{ display: 'flex', alignItems: 'center', marginBottom: 14, width: '100%' }}
      onClick={toggleCollapsed}
      data-testid="collapsible-container__button"
      data-trackid="collapsible-container__button"
      data-collapsed={collapsed ? 'collapsed' : 'expanded'}
    >
      <FlexRow style={{ marginTop: 20 }} justifyContent="space-between">
        <Tooltip placement="top" title={texts.title(collapsed)}>
          <ReadonlyLabel style={{ marginBottom: 0 }} primary>
            {children}
          </ReadonlyLabel>
        </Tooltip>
        <Triangle style={{ transform: `rotate(${collapsed ? '180deg' : '0deg'})` }} />
      </FlexRow>
    </TextButton>
  )
}
