import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Stack, IconButton, Tooltip, Box, FormHelperText } from '@mui/material'

import { RemoveCircleOutline } from '@mui/icons-material'
import { selectUserConfiguration } from '@/features/domain/user'

import { TimePicker } from './components/TimePicker'
import { useTexts } from './hooks/useTexts'
import { useTimePickerOptions } from './hooks/useTimePickerOptions'

interface Props {
  onUpdateTimeWindow: (timeWindow: uui.domain.rm.TimeWindow) => void
  onRemoveTimeWindow: () => void
  validate: (force?: boolean) => void
  value: uui.domain.rm.TimeWindow
  disabled?: boolean
  errorMessage?: string
}

export function EditTimeWindow(props: Props) {
  const { onRemoveTimeWindow, value, onUpdateTimeWindow, validate, errorMessage } = props

  const texts = useTexts()
  const options = useTimePickerOptions()
  const { timeFormat } = useSelector(selectUserConfiguration)

  const onChangeStartSec = useCallback(
    (startSec: number) => {
      onUpdateTimeWindow({ startSec, endSec: value.endSec })
    },
    [onUpdateTimeWindow, value],
  )
  const onChangeEndSec = useCallback(
    (endSec: number) => {
      onUpdateTimeWindow({ startSec: value.startSec, endSec })
    },
    [onUpdateTimeWindow, value],
  )

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} alignItems="flex-start">
        <TimePicker
          value={value.startSec}
          label={texts.labelStart}
          onChange={onChangeStartSec}
          validate={validate}
          options={options}
          timeFormat={timeFormat}
          testId="time-window-startSec"
        />
        <TimePicker
          value={value.endSec}
          label={texts.labelEnd}
          onChange={onChangeEndSec}
          validate={validate}
          options={options}
          timeFormat={timeFormat}
          testId="time-window-endSec"
        />
        <Box>
          <Tooltip title={texts.deleteTimeWindowTooltip}>
            <IconButton color="error" onClick={onRemoveTimeWindow} sx={{ mt: 1 }}>
              <RemoveCircleOutline />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      {!!errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Stack>
  )
}
