import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Props } from '../typings'

import { Content } from './Content'

export function Root(props: Props) {
  return (
    <Modal modalId={modalId}>
      <Content payload={props.payload} />
    </Modal>
  )
}
