import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    labelName: translate({ id: 'gps.geofences.edit.nameTitle' }),
    footerTitle: translate({ id: 'gps.geofences.edit.formErrorsTitle' }),

    save: translate({ id: 'gps.geofences.edit.updateButton' }),
    create: translate({ id: 'gps.geofences.edit.createButton' }),
    saveTitle: (valid: boolean, planLocked: boolean, pristine: boolean) => {
      if (!valid) return translate({ id: 'geofences.form.submitButton.disabled.title.invalid' })

      if (planLocked)
        return translate({ id: 'geofences.form.submitButton.disabled.title.planLocked' })

      if (pristine) return translate({ id: 'geofences.form.submitButton.disabled.title.pristine' })

      return ''
    },
    cancel: translate({ id: 'global.cancel' }),
    headerTitle: translate({ id: 'gps.geofences.edit.headerTitle' }),
    validateRadius: translate({ id: 'gps.geofences.validation.invalidRadius' }),
    validateMaxSpeed: (min: number, max: number) =>
      translate({ id: 'gps.geofences.validation.invalidMaxSpeed', values: { min, max } }),
    headerDescription: translate({ id: 'gps.geofences.edit.headerDescription' }),
    validationMissingName: translate({ id: 'gps.places.edit.validationMissingName' }),
    validateEndDateTime: translate({ id: 'gps.geofences.validation.validateEndDateTime' }),
    validationDeviceRequired: translate({ id: 'gps.geofences.validation.deviceRequired' }),
    validateNotificationAddress: translate({ id: 'gps.geofences.validation.notificationAddress' }),
  }))

  return api
}
