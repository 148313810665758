import { useSnapshot } from 'valtio'
import { environment } from '../environment'

const defaultURLs = {
  serverUrl: '',
  podsBaseUrl: '',
  importWizardTemplateBaseUrl: '',
}

export const useEnvironmentURLs = () => {
  const { env } = useSnapshot(environment)

  return env.status === 'ready' ? env.urls : defaultURLs
}
