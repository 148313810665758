import type { MapSelectionContext } from '../mapSelection'
import { CrudSelectionCategory, MainSelectionCategory } from '@/atoms'

import { mapSelectionAtom } from '../mapSelection'

export function setMapSelectionContext(type: 'none'): void
export function setMapSelectionContext(type: 'main', category?: MainSelectionCategory): void
export function setMapSelectionContext(type: 'crud', category: CrudSelectionCategory): void
export function setMapSelectionContext(
  type: MapSelectionContext['type'],
  category?: CrudSelectionCategory | MainSelectionCategory,
): void {
  if (mapSelectionAtom.context.type === 'none' && mapSelectionAtom.context.type === type) return

  if (mapSelectionAtom.context.type === 'main' && mapSelectionAtom.context.type === type) {
    if (mapSelectionAtom.context.category === category) {
      return
    }
  }

  if (mapSelectionAtom.context.type === 'crud' && mapSelectionAtom.context.type === type) {
    if (mapSelectionAtom.context.category === category) {
      return
    }
  }

  if (type === 'none') {
    mapSelectionAtom.context.type = 'none'
    // @ts-expect-error category is not valid on `none` but we need to ensure is removed from the object
    delete mapSelectionAtom.context.category
  }

  if (type === 'main') {
    mapSelectionAtom.context.type = 'main'

    // ATTENTION: the condition will always be true, it's required to narrow the TS type
    if (mapSelectionAtom.context.type == 'main') {
      mapSelectionAtom.context.category = category as MainSelectionCategory
    }
  }

  if (type === 'crud') {
    // ATTENTION: we need to narrow `category` to ensure is not undefined
    if (category) {
      mapSelectionAtom.context.type = 'crud'

      // ATTENTION: the condition will always be true, it's required to narrow the TS type
      if (mapSelectionAtom.context.type == 'crud') {
        mapSelectionAtom.context.category = category as CrudSelectionCategory
      }
    }
  }
}
