import type { Props } from './typings'

import { useCallback } from 'react'
import {
  isTomorrow,
  isSameDay,
  isBefore,
  addDays,
  subDays,
  isToday,
  isAfter,
  getDay,
} from 'date-fns'

import { ReadonlyField, ReadonlyFieldRow } from '@/forms-legacy'

import { FlexColumn, FlexRow } from '../../../../lib'
import { ArrowButtonPosition } from './elements/ArrowButton/typings'
import DateSelectorLabel from './elements/DateSelectorLabel'
import ArrowButton from './elements/ArrowButton'
import Triangle from './elements/Triangle'

export function CompactDateSelector(props: Props) {
  const {
    value,
    onChange,
    onClick,
    dateRenderer,
    isOpen = false,
    limits,
    extraLabels: { today = undefined, tomorrow = undefined, hideDate = false, weekDays = [] } = {},
  } = props

  const date: Date = value

  const handleAddDay = useCallback(() => {
    onChange(addDays(date, 1))
  }, [onChange, date])

  const handleSubDay = useCallback(() => {
    onChange(subDays(date, 1))
  }, [onChange, date])

  const disablePrev = limits
    ? isSameDay(date, limits.min) ||
      isBefore(date, limits.min) ||
      isAfter(date, addDays(limits.max, 1))
    : false

  const disableNext = limits
    ? isSameDay(date, limits.max) ||
      isAfter(date, limits.max) ||
      isBefore(date, subDays(limits.min, 1))
    : false

  let extraLabel: string =
    !!today && isToday(date) ? today : !!tomorrow && isTomorrow(date) ? tomorrow : ''

  if (extraLabel.length === 0 && weekDays.length > 0) {
    extraLabel = ` • ${weekDays[getDay(date)]}` || ''
  }

  return (
    <FlexColumn style={{ width: '100%' }}>
      <FlexRow>
        <ReadonlyField style={{ marginRight: '2px', background: 'lightgreyish' }} onClick={onClick}>
          <ReadonlyFieldRow style={{ justifyContent: 'space-between', cursor: 'pointer' }}>
            <DateSelectorLabel>
              {!hideDate && dateRenderer(date)}
              {extraLabel}
            </DateSelectorLabel>
            <Triangle
              style={{
                transform: isOpen ? 'rotate(0)' : 'rotate(180deg)',
              }}
            />
          </ReadonlyFieldRow>
        </ReadonlyField>
        <ArrowButton
          buttonPosition={ArrowButtonPosition.left}
          onClick={handleSubDay}
          disabled={disablePrev}
        />
        <ArrowButton
          buttonPosition={ArrowButtonPosition.right}
          onClick={handleAddDay}
          disabled={disableNext}
        />
      </FlexRow>
    </FlexColumn>
  )
}
