import styled from 'styled-components'

const SecondaryColumnHeaderActionsSeparator = styled.div`
  width: 1px;
  min-width: 0;
  height: 33px;
  background: ${p => p.theme.colors.$shadyLady};
`

SecondaryColumnHeaderActionsSeparator.displayName = 'SecondaryColumnHeaderActionsSeparator'
export default SecondaryColumnHeaderActionsSeparator
