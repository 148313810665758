import { useCallback } from 'react'
import { Alert, Button, Stack, Typography } from '@mui/material'

import { useTexts } from '../../../../hooks/useTexts'

import { useGoToIntegrationsModal } from '../modals/GoToIntegrationsModal'

export function NoTenantSourcesAlert() {
  const { Modal: GoToIntegrationsModal, show: showGoToIntegrationsModal } =
    useGoToIntegrationsModal()
  const texts = useTexts()

  const onClick = useCallback(() => {
    showGoToIntegrationsModal()
  }, [showGoToIntegrationsModal])

  return (
    <Stack width="100%" marginY={2}>
      <Alert severity="info">
        <Stack alignItems="flex-start" spacing={1}>
          <Typography variant="body2">{texts.noTenantSourceAvailable.alert}</Typography>

          <Button onClick={onClick} variant="text">
            {texts.noTenantSourceAvailable.action}
          </Button>
        </Stack>
      </Alert>

      <GoToIntegrationsModal />
    </Stack>
  )
}
