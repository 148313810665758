import type { YYYYMMDD, SelectionAsRecord, IntervalSelectionData } from '../../../typings'

import { useMemo } from 'react'
import { eachDayOfInterval, subDays, parse, format, isBefore, max } from 'date-fns/esm'

import { parseDate } from '../../../utils/parseDate'
import { defaultMinDate } from '../../../constants'

const emptySelectionAsRecord: SelectionAsRecord = {}

const now = new Date()
export const useSelectionData = (
  minDateAsString: YYYYMMDD,
  maxDateAsString: YYYYMMDD,
  intervalLength: number = 0,
  selectionStartInput?: Date,
  selectionEndInput?: Date,
  firstNonArchivedDay?: YYYYMMDD,
) => {
  return useMemo<IntervalSelectionData>(() => {
    const selectionStart = selectionStartInput
    const selectionEnd = selectionEndInput || selectionStartInput

    if (!selectionStart || !selectionEnd) {
      return {
        selection: emptySelectionAsRecord,
        selectableInterval: undefined,
        edgeOfSelection: emptySelectionAsRecord,
      }
    }

    const minDate: Date = minDateAsString ? parseDate(minDateAsString) : defaultMinDate

    const selectedDays = eachDayOfInterval({
      start: selectionStart,
      end: selectionEnd,
    })

    const firstNonArchivedDate = firstNonArchivedDay ? parseDate(firstNonArchivedDay) : undefined

    const selectingArchivedDays = firstNonArchivedDate
      ? isBefore(selectionStart, firstNonArchivedDate)
      : false

    const selectableDayStart =
      // If is selecting an archived days range or there's a full selection, it is possible to select
      // all available days (minDate)
      selectingArchivedDays || selectionEndInput
        ? minDate
        : // If there's a pending selection, it's necessary to respect archived days limit
        firstNonArchivedDate
        ? max([firstNonArchivedDate, subDays(selectionStart, intervalLength)])
        : subDays(selectionStart, intervalLength)

    const selectableDayEnd = parse(maxDateAsString, 'yyyyMMdd', now)

    const selectableDays = eachDayOfInterval({
      start: selectableDayStart,
      end: selectableDayEnd,
    })

    const selection = selectedDays.reduce<SelectionAsRecord>((acc, date) => {
      const dateAsString = format(date, 'yyyyMMdd')
      acc[dateAsString] = 1
      return acc
    }, {})

    const selectableInterval = selectableDays.reduce<SelectionAsRecord>((acc, date) => {
      const dateAsString = format(date, 'yyyyMMdd')
      acc[dateAsString] = 1
      return acc
    }, {})

    const edgeOfSelection: SelectionAsRecord = {
      [format(selectionStart, 'yyyyMMdd')]: 1,
      [format(selectionEnd, 'yyyyMMdd')]: 1,
    }

    return {
      selection,
      selectableInterval,
      edgeOfSelection,
    }
  }, [
    selectionStartInput,
    firstNonArchivedDay,
    selectionEndInput,
    intervalLength,
    minDateAsString,
    maxDateAsString,
  ])
}
