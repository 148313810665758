import { useCallback } from 'react'
import differenceInSeconds from 'date-fns/esm/differenceInSeconds'

import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { updateExecutionEvents } from '@/features/domain/order'

export function useOnSubmit() {
  const dispatch = useAppDispatch()
  const toast = useNotification()

  return useCallback(
    async (loads: Record<string, number>, cfs: Record<string, string>, extOrderStepId: string) => {
      const now = new Date()
      const midnight = new Date(now)
      midnight.setHours(0, 0, 0, 0)

      const sec = Math.abs(differenceInSeconds(now, midnight))

      const dynamicLoads = Object.entries(loads).reduce((acc, [load, value]) => {
        if (value < 0) return acc
        acc[load] = {
          value: value * 100,
          sec,
        }
        return acc
      }, {})

      const customFields = Object.entries(cfs).reduce((acc, [cf, value]) => {
        if (!value) return acc
        acc[cf] = {
          value,
          sec,
        }
        return acc
      }, {})

      if (Object.keys(dynamicLoads).length === 0 && Object.keys(customFields).length === 0) {
        console.warn('No dynamic loads or custom fields to update')
        return
      }

      const request: uui.domain.ui.forms.ExecutionEventsChanges = {
        id: extOrderStepId,
        update: {
          dynamicLoads,
          customFields,
        },
      }

      const thunkResult = await dispatch(updateExecutionEvents(request))

      if (!updateExecutionEvents.fulfilled.match(thunkResult)) {
        toast.error(thunkResult.payload?.message ?? 'Internal error')
      }
    },
    [dispatch, toast],
  )
}
