export function Xls() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41">
      <g clipPath="url(#a)">
        <circle cx="20.5" cy="20.5918" r="20" fill="#4EA26C" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5 10.5918h-9c-1.1 0-2 .9-2 2v5c-1.1046 0-2 .8954-2 2v4c0 1.1046.8954 2 2 2v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-13l-5-5Zm-9 18v-3h12v3h-12Zm0-11h12v-1h-4v-4h-8v5Zm.2251 3.2598-.9365-1.7364h-1.1792l1.4492 2.4678-1.4868 2.5088h1.1894l.9639-1.7705.9639 1.7705h1.1826l-1.4834-2.5088 1.4458-2.4678h-1.1724l-.9365 1.7364Zm6.0118 3.2402v-.7998h-2.1738v-4.1768h-1.0254v4.9766h3.1992Zm3.2877-1.5415c.0274.0706.041.1504.041.2393 0 .1116-.0285.2119-.0854.3007-.0547.0889-.1402.1584-.2564.2085-.1139.0502-.2597.0752-.4375.0752-.1458 0-.2802-.0148-.4033-.0444-.1208-.0296-.2256-.0775-.3144-.1436-.0866-.066-.1539-.1526-.2017-.2597-.0479-.1071-.0718-.2381-.0718-.3931h-1.0288c0 .2803.0581.523.1743.728.1162.2051.2712.3737.4649.5059.1959.1322.4135.2313.6528.2974.2393.0638.4819.0957.728.0957.2735 0 .5207-.0308.7417-.0923.2211-.0616.4113-.1516.5708-.27.1595-.1185.2814-.2632.3658-.4341.0843-.1709.1264-.3646.1264-.5811 0-.2051-.0353-.3885-.1059-.5503-.0707-.1618-.1755-.3065-.3145-.4341s-.3122-.2426-.5195-.3452c-.2051-.1025-.4432-.1948-.7144-.2768-.1618-.0502-.3042-.1014-.4272-.1538-.1231-.0524-.2268-.1083-.3111-.1675-.0843-.0593-.1481-.1231-.1914-.1914-.041-.0684-.0615-.1447-.0615-.229 0-.1117.0296-.2119.0889-.3008.0592-.0912.1481-.1629.2666-.2153.1185-.0547.2666-.0821.4443-.0821.1846 0 .3373.0319.458.0957.1231.0638.2142.1516.2735.2632.0615.1094.0922.237.0922.3828h1.0186c0-.2962-.0763-.5594-.229-.7895-.1504-.2302-.3623-.4113-.6357-.5435-.2735-.1321-.5936-.1982-.9605-.1982-.2689 0-.5161.033-.7417.0991-.2233.0661-.417.1606-.5811.2837-.164.1208-.2916.2666-.3828.4375-.0911.1686-.1367.3566-.1367.564 0 .2141.0433.4021.1299.5639.0889.1618.2096.3042.3623.4273.155.1207.3327.2267.5332.3178.2028.0912.417.1732.6426.2461.2028.0638.368.1254.4956.1846.1276.0592.2267.1196.2974.1812.0706.0592.1184.1253.1435.1982Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5.591797h40v40H.5z" />
        </clipPath>
      </defs>
    </svg>
  )
}
