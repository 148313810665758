import { Fragment } from 'react'

import { WarningTriangle } from '@/icons'
import { useFormatSeconds } from '@/hooks'
import { Checkbox } from '@/components/primitives/Checkbox'
import { WarningCard, Text } from '@/local/components'
import { VerticalLayout } from '@/components/layout/VerticalLayout'
import { HorizontalLayout } from '@/components/layout/HorizontalLayout'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

const Block = Fragment

export function Body() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const {
    status,
    data: { selectedOrders, pairedOrders, deleteAlsoPaired, mode },
  } = useController()

  const serviceTimeSec =
    !!pairedOrders && pairedOrders?.length === 1 ? pairedOrders[0].serviceTimeSec : 0
  const serviceTime = useFormatSeconds(serviceTimeSec)

  return (
    <VerticalLayout marginTop={32}>
      <Block>
        <WarningCard
          title={
            status !== 'pending' ? texts.warningCardTitle(mode) : texts.transactionWarningCardTitle
          }
          preset="warning"
          description={
            status !== 'pending'
              ? texts.warningCardDescription
              : texts.transactionWarningCardDescription
          }
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
        />
      </Block>

      {!!pairedOrders && pairedOrders.length > 0 && (
        <Block>
          <HorizontalLayout marginTop={32}>
            <Checkbox
              style={{ marginTop: 4 }}
              checked={deleteAlsoPaired}
              onChange={handlers.onDeleteAlsoPairedChange}
            />

            <VerticalLayout>
              <Text size="$h2" weight="$semiBold" color="$nightRider" marginLeft={5} ellipsis>
                {mode === 'bulk'
                  ? texts.deleteAlsoBulk
                  : texts.deleteAlso(selectedOrders[0].orderStepType)}
              </Text>

              <Text
                ellipsis
                size="$p3"
                marginTop={2}
                marginLeft={5}
                weight="$regular"
                color="$nightRider"
              >
                {mode === 'bulk'
                  ? texts.subtitleBulk(pairedOrders.length)
                  : texts.subtitle({
                      name: selectedOrders[0].order.name,
                      serviceTime: serviceTimeSec !== undefined ? serviceTime : null,
                    })}
              </Text>
            </VerticalLayout>
          </HorizontalLayout>
        </Block>
      )}
    </VerticalLayout>
  )
}
