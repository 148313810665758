import { useEffect } from 'react'

import { selectOrderStepsListOptions, setOrdersFilter } from '@/features/domain/lists'
import { store, useAppDispatch } from '@/store'

export function useResetOrdersFilterOnMount() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    async function resetOrdersFilter() {
      const listOptions = selectOrderStepsListOptions(store.getState())
      const request = await dispatch(
        setOrdersFilter({ ...listOptions, search: { ...listOptions.search, query: '' } }),
      )
      setOrdersFilter.fulfilled.match(request)
    }
    resetOrdersFilter()
  }, [dispatch])
}
