import type { Props } from './typings'

import { Block, Label, FieldMessage, Breaks } from '@/forms-legacy'

import RestoreFieldButton from '../primitives/RestoreFieldButton'

export function BreaksField(props: Props) {
  const {
    label,
    name,
    info,
    half = false,
    testid,
    wrappedInputProps,
    formProps: {
      input: { value, onChange },
      meta: { error, dirty, initial },
    },
  } = props

  return (
    <Block half={half} error={!!error} dirty={!!dirty} data-testid={testid} data-trackid={testid}>
      <Label htmlFor={name}>
        {label}{' '}
        <RestoreFieldButton
          dirty={dirty}
          initial={initial}
          onChange={onChange}
          style={{ marginLeft: 12 }}
        />
      </Label>
      <Breaks {...wrappedInputProps} onChange={onChange} value={value} />
      {!!error && <FieldMessage error>{error}</FieldMessage>}
      {!!info && <FieldMessage>{info}</FieldMessage>}
    </Block>
  )
}
