import type { FormError, FormValues as FormValuesType } from '@workwave-tidal/tidal/form-fairy'

export const formFields = {
  oldPassword: {
    value: '',
    required: true,
  },
  newPassword: {
    value: '',
    required: true,
  },
}

export type FormFields = typeof formFields
export type FormValues = FormValuesType<FormFields>
export type FormErrors = FormError<keyof FormFields>
