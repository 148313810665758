import styled from 'styled-components'

import { Text } from '@/local/components'

import { useTexts } from './useTexts'

const textStyle = { display: 'flex', alignItems: 'center' }
const StyledDiv = styled.div`
  outline: 0;
  display: flex;
  align-items: center;
  border-width: 0;
  border-radius: 4px;
  padding: 0 6px;
  background-color: ${p => p.theme.colors.$silver};
  margin-left: 4px;
`

StyledDiv.displayName = 'StyledDiv'

export function MoreTag() {
  const texts = useTexts()
  return (
    <StyledDiv>
      <Text size="$p3" weight="$semiBold" color="$pureWhite" style={textStyle}>
        + {texts.more}
      </Text>
    </StyledDiv>
  )
}
