import type { MapSelectionContext } from '../mapSelection'
import type { MainSelection } from '@/atoms'

type MainMapSelection = {
  breadcrumbs: Set<string>
  depots: Set<string>
  devices: Set<string>
  drivers: Set<string>
  orderSteps: Set<string>
  places: Set<string>
  routes: Set<string>
  unifiedVehicles: Set<string>
}

export function getMapMainSelection(
  mapSelectionContext: MapSelectionContext,
  mainSelection: workwave.DeepReadonly<MainSelection>,
): MainMapSelection {
  const selection: MainMapSelection = {
    breadcrumbs: new Set(),
    depots: new Set(),
    devices: new Set(),
    drivers: new Set(),
    orderSteps: new Set(),
    places: new Set(),
    routes: new Set(),
    unifiedVehicles: new Set(),
  }

  if (mapSelectionContext.type !== 'main') {
    return selection
  }

  if (!mapSelectionContext.category) {
    // for main selection if no category is specified all selection are active
    for (const category of Object.keys(selection)) {
      selection[category] = new Set(mainSelection[category])
    }

    return selection
  }

  switch (mapSelectionContext.category) {
    case 'depots':
    case 'routes':
    case 'places':
    case 'drivers':
    case 'orderSteps':
    case 'breadcrumbs':
    case 'unifiedVehicles':
      // string[]
      selection[mapSelectionContext.category] = new Set(mainSelection[mapSelectionContext.category])
      break

    default:
      break
  }

  return selection
}
