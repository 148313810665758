import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useController } from '../../../../../hooks/useController'
import { useControllerActions } from '../../../../../hooks/useControllerActions'
import { useTexts } from '../../../../../hooks/useTexts'

export function ExportFilePicker() {
  const {
    data: { singleFile },
  } = useController()

  const handlers = useControllerActions()

  const texts = useTexts()

  return (
    <FormControl>
      <FormLabel id="file-export-type-picker">{texts.file}</FormLabel>
      <RadioGroup
        onChange={handlers.onChangeFileExportType}
        aria-labelledby="file-export-type-picker"
        value={singleFile}
      >
        <FormControlLabel
          value={true}
          control={<Radio data-testid="export-route-territory-export-one-file" />}
          label={texts.singleFileExport}
        />
        <FormControlLabel
          value={false}
          control={<Radio data-testid="export-route-territory-export-multiple-files" />}
          label={texts.oneFileEachTerritory}
        />
      </RadioGroup>
    </FormControl>
  )
}
