import { useState } from 'react'
import { useSelector } from 'react-redux'
import { differenceInDays } from 'date-fns/esm'

import { useDaysWithOrders, useUpdateOrderCountsByDate } from '@/hooks'
import { selectUserType } from '@/features/domain/user'
import { Calendar } from '@/components/Calendar'
import { Stack } from '@/local/components'

import { RedirectWarning } from './components/RedirectWarning'
import { GpsOnlyToolbar } from './components/GpsOnlyToolbar'
import { RmToolbar } from './components/RmToolbar'

import { useCalendarState } from './hooks/useCalendarState'
import { useData } from './hooks/useData'
import { useTexts } from './useTexts'

type Props = {
  onClose: () => void
  setExportDates: (dates: string[]) => void
  setMultiterritory: (multiTerritory: boolean) => void
  showExportRoutesFromCalendarModal: () => void
}

const containerStyle = { padding: '26px 22px' }

export function TerritoryCalendar(props: Props) {
  const { onClose, setExportDates, setMultiterritory, showExportRoutesFromCalendarModal } = props
  const [highlightToday, setHighlightToday] = useState(false)

  // Updates order counts at every open
  useUpdateOrderCountsByDate()

  const data = useData()
  const texts = useTexts()
  const daysWithOrders = useDaysWithOrders()
  const userType = useSelector(selectUserType)

  const {
    onConfirm,
    submitting,
    setSingleDate,
    setCalendarState,
    setCalendarToToday,
    shouldRedirectToRouting,
    calendarState: { start, end, count },
  } = useCalendarState(data.today, onClose)

  // If there's a defined interval, Territory Calendar should allow to select all possible dates.
  // We're choosing the maximum between today and min/max dates
  const maxIntervalLength =
    start && end
      ? Math.max(
          Math.abs(differenceInDays(data.minDate, data.today)),
          Math.abs(differenceInDays(data.maxDate, data.today)),
        )
      : data.maxIntervalLength

  const canExport = userType !== 'guest'

  return (
    <div style={containerStyle}>
      <Stack space="l">
        {data.gpsOnly ? (
          <Calendar
            layout="date"
            selection={start}
            onChange={setSingleDate}
            today={data.todayAsString}
            maxDate={data.todayAsString}
            minDate={data.minDateAsString}
            highlightToday={highlightToday}
            highlightedDays={daysWithOrders}
            months={data.numberOfMonthsToShow}
            tooltipIntlText={texts.ordersTooltip}
          />
        ) : (
          <Calendar
            layout="interval"
            selectionEnd={end}
            selectionStart={start}
            focusOnSelection={true}
            today={data.todayAsString}
            onChange={setCalendarState}
            minDate={data.minDateAsString}
            maxDate={data.maxDateAsString}
            highlightToday={highlightToday}
            disabledDays={data.disabledDays}
            highlightedDays={daysWithOrders}
            months={data.numberOfMonthsToShow}
            tooltipIntlText={texts.ordersTooltip}
            maxIntervalLength={maxIntervalLength}
            firstNonArchivedDay={data.firstNonArchivedDay}
          />
        )}

        {shouldRedirectToRouting && <RedirectWarning />}

        {data.gpsOnly ? (
          <GpsOnlyToolbar
            onClose={onClose}
            onConfirm={onConfirm}
            selectToday={setCalendarToToday}
          />
        ) : (
          <RmToolbar
            today={data.todayAsString}
            onClose={onClose}
            onConfirm={onConfirm}
            submitting={submitting}
            showExport={canExport}
            selectToday={setCalendarToToday}
            selectionEnd={end}
            setExportDates={setExportDates}
            selectionStart={start}
            daysWithOrders={daysWithOrders}
            selectedDaysCount={count}
            setHighlightToday={setHighlightToday}
            setMultiterritory={setMultiterritory}
            firstNonArchivedDay={data.firstNonArchivedDay}
            showExportRoutesFromCalendarModal={showExportRoutesFromCalendarModal}
          />
        )}
      </Stack>
    </div>
  )
}
