import * as gis from '@/local/server-data/utils/gis'

export const searchDepotsOnMap = (
  address: string,
  depots: Record<string, uui.domain.client.rm.Depot>,
  strategy: 'includes' | 'startsWith',
) => {
  const result: uui.domain.client.gps.SearchOnMapItem[] = []
  const query = address.trim()

  for (const depot of Object.values(depots)) {
    if (isInSearch(depot.name, query, strategy)) {
      result.push({
        type: 'depot',
        id: depot.id,
        name: depot.name,
        address: depot.name,
        location: depot.location,
      })
    }
  }

  return result
}

export const searchPlacesOnMap = (
  address: string,
  places: Record<number, uui.domain.client.gps.wwgps.Place>,
  strategy: 'includes' | 'startsWith',
) => {
  const query = address.trim()
  const result: uui.domain.client.gps.SearchOnMapItem[] = []

  for (const place of Object.values(places)) {
    const { name, latitude, longitude } = place
    if (isInSearch(place.name, query, strategy)) {
      result.push({
        type: 'gpsPlace',
        name,
        address: name,
        id: place.id,
        latLng: gis.createLatLng(latitude, longitude),
      })
    }
  }

  return result
}

const isInSearch = (
  rawSource: string,
  rawQuery: string,
  strategy: 'includes' | 'startsWith',
): boolean => {
  const source = rawSource.toLocaleLowerCase()
  const query = rawQuery.toLocaleLowerCase()
  switch (strategy) {
    case 'includes':
      return source.includes(query)
    case 'startsWith':
      return source.startsWith(query)
  }
}
