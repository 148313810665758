import { useSelector } from 'react-redux'
import { selectPlaceMapStyles } from '@/features/domain/ui'

export const usePlacesMapStyle = () => {
  const { mode, custom } = useSelector(selectPlaceMapStyles)

  if (Object.keys(custom).length > 0) return

  return mode
}
