import type { RegionFormValues } from '../typings'

import { useState, useCallback } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

import { updateRegion, createRegion } from '@/features/domain/region'

import { setCrudSelection, useListApi, useResetEditingState } from '@/atoms'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { parseRegionFromFormValues } from '../utils'

export const useOnSubmit = () => {
  const stopEditing = useResetEditingState()
  const { scrollTo } = useListApi('regions')
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const [submitting, setSubmitting] = useState<boolean>(false)
  const isUnMounted = useIsUnmounted()

  const onCreate = useCallback(
    async (formValues: RegionFormValues) => {
      try {
        setSubmitting(true)

        const region = parseRegionFromFormValues(formValues)
        const thunkResult = await dispatch(createRegion(region))

        if (!isUnMounted()) {
          setSubmitting(false)
        }

        if (createRegion.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        if (createRegion.fulfilled.match(thunkResult)) {
          stopEditing()

          if (isUnMounted()) return

          const createdRegionId = unwrapResult(thunkResult)

          // FIXME: remove this condition once the backend UPSERT will return an ID on creation
          if (!createdRegionId) return

          setCrudSelection('regions', [createdRegionId])

          scrollTo(createdRegionId)
        }
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, isUnMounted, stopEditing, scrollTo, toast],
  )

  const onUpdate = useCallback(
    async (formValues: RegionFormValues) => {
      try {
        setSubmitting(true)

        const region = parseRegionFromFormValues(formValues)
        const thunkResult = await dispatch(updateRegion(region))

        if (!isUnMounted()) {
          setSubmitting(false)
        }

        if (!updateRegion.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, isUnMounted, stopEditing, toast],
  )

  return {
    submitting,
    onCreate,
    onUpdate,
  }
}
