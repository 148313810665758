import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState({
    routes: (count: number) => translate({ id: 'map.tooltip.order.routes', values: { count } }),
  })

  return api
}
