import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    title: translate({
      id: 'companies.deleteModal.title',
    }),
    footer: {
      delete: translate({ id: 'companies.deleteModal.footer.delete' }),
      cancel: translate({ id: 'companies.deleteModal.footer.cancel' }),
    },
    validation: {
      notAllChecked: translate({ id: 'companies.deleteModal.form.validation.notAllChecked' }),
    },
    form: {
      title: translate({ id: 'companies.deleteModal.form.title' }),
      c1: translate({ id: 'companies.deleteModal.form.c1' }),
      c2: translate({ id: 'companies.deleteModal.form.c2' }),
      c3: translate({ id: 'companies.deleteModal.form.c3' }),
      c4: translate({ id: 'companies.deleteModal.form.c4' }),
    },
    toast: {
      success: translate({ id: 'companies.deleteModal.toast.success' }),
      usersLeftWithoutCompany: (count: number) =>
        translate({ id: 'companies.deleteModal.toast.usersLeftWithoutCompany', values: { count } }),
    },
  }))

  return api
}
