/**
 * NOTE: The only purpose of this component is to provide a way to replace MUI Stack component
 *       because of the issue with React renderers in Bryntum Scheduler.
 *       Please remove this component and use MUI Stack instead when the issue is fixed.
 */
import { type CSSProperties, type ReactNode } from 'react'

interface Props {
  justifyContent?: 'center'
  alignItems?: 'center'
  direction?: 'row' | 'column'
  children?: ReactNode
  flexGrow?: number
  spacing?: number
}

export function Stack(props: Props) {
  const {
    justifyContent,
    alignItems,
    direction = 'column',
    children,
    flexGrow,
    spacing = 0,
  } = props

  const style: CSSProperties = {
    justifyContent: justifyContent,
    flexDirection: direction,
    alignItems: alignItems,
    flexGrow: flexGrow ?? 0,
    gap: `${spacing * 8}px`,
    display: 'flex',
  }

  return <div style={style}>{children}</div>
}
