import { useCallback, useState } from 'react'

import { setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'popoutDevices' | 'showDeviceLabels' | 'showDevices' | 'hideDevices'
export const useActions = (ids: string[]) => {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const popoutDevices = useCallback(async () => {
    setMethodInExecution('popoutDevices')

    await dispatch(
      setCustomMapStyle({
        mapMode: 'off',
        customStyle: { type: 'device', mode: 'label', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const showDeviceLabels = useCallback(async () => {
    setMethodInExecution('showDeviceLabels')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'device', mode: 'label', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const showDevices = useCallback(async () => {
    setMethodInExecution('showDevices')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'device', mode: 'on', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const hideDevices = useCallback(async () => {
    setMethodInExecution('hideDevices')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'device', mode: 'off', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  return {
    methodInExecution,
    actions: { popoutDevices, showDeviceLabels, showDevices, hideDevices },
  }
}
