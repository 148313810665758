import { useMemo, useCallback } from 'react'

import { useEditingStateWithCtx, setMainSelection } from '@/atoms'
import { setPendingFitMap } from '@/map'
import { useNavigate } from '@/routing'

import { useCopySelectedOrdersModal } from '@/components/modals/CopySelectedOrdersModal'
import { useDuplicateOrderModal } from '@/components/modals/DuplicateOrderModal'
import { useUnassignOrderModal } from '@/components/modals/UnassignOrderModal'
import { useResidentModalsApi } from '@/components/modals/ResidentModals'
import { useDeleteOrderModal } from '@/components/modals/DeleteOrderModal'
import { useFitInOrderModal } from '@/components/modals/FitInOrderModal'

import { useOrderStepsNavigator } from '../../../../../hooks/useOrderStepsNavigator'

interface OrderAccumulator {
  unassignedOrders: uui.domain.client.rm.ExtendedOrderStep[]
  assignedOrders: uui.domain.client.rm.ExtendedOrderStep[]
  unassignedOrderIds: string[]
  assignedOrderIds: string[]
}

export const useActions = () => {
  const { currentOrders, currentOrderIds } = useOrderStepsNavigator()

  const { unassignedOrders, unassignedOrderIds, assignedOrders, assignedOrderIds } = useMemo(
    () =>
      currentOrders.reduce<OrderAccumulator>(
        (acc, order) => {
          if (order.isUnassigned) {
            acc.unassignedOrderIds.push(order.orderStep.id)
            acc.unassignedOrders.push(order)
          } else {
            acc.assignedOrderIds.push(order.orderStep.id)
            acc.assignedOrders.push(order)
          }

          return acc
        },
        {
          unassignedOrderIds: [],
          assignedOrderIds: [],
          unassignedOrders: [],
          assignedOrders: [],
        },
      ),
    [currentOrders],
  )

  const { setEditing } = useEditingStateWithCtx('order')
  const navigate = useNavigate(true)

  const { show: showFitInOrderModal, Modal: FitInOrderModal } = useFitInOrderModal()
  const {
    exportOrders: { show: showExportOrderModal },
  } = useResidentModalsApi()
  const { show: showDeleteOrderModal, Modal: DeleteOrderModal } = useDeleteOrderModal()
  const { show: showUnassignOrderModal, Modal: UnassignOrderModal } = useUnassignOrderModal()
  const { show: showDuplicateOrderModal, Modal: DuplicateOrderModal } = useDuplicateOrderModal()
  const { show: showCopySelectedOrdersModal, Modal: CopySelectedOrdersModal } =
    useCopySelectedOrdersModal()

  const goToEdit = useCallback(() => {
    // Set the new main selection
    setMainSelection('orderSteps', currentOrderIds)

    // Set the order form to editing mode
    setEditing(currentOrderIds)

    // queue a pending fitMap request
    // it will be applied by the destination view when it opens
    const latLngCoordinates = currentOrders.map(order => order.latLng)
    setPendingFitMap(latLngCoordinates)

    // Navigate to the correct route
    navigate('orders')
  }, [currentOrders, currentOrderIds, navigate, setEditing])

  return {
    currentOrders,
    assignedOrders,
    unassignedOrders,
    currentOrderIds,
    actions: {
      goToEdit,
      showCopySelectedOrdersModal,
      showFitInOrderModal,
      showExportOrderModal,
      showDeleteOrderModal,
      showUnassignOrderModal,
      showDuplicateOrderModal,
    },
    modals: (
      <>
        <FitInOrderModal orderStepIds={unassignedOrderIds} />
        <DeleteOrderModal />
        <DuplicateOrderModal />
        <UnassignOrderModal orderStepIds={assignedOrderIds} />
        <CopySelectedOrdersModal orderStepIds={currentOrderIds} />
      </>
    ),
  }
}
