import type { ReadFileOptions, ReadFileResult, Nullable } from './typings'

export function openFile(): Promise<File> {
  const input: Nullable<HTMLInputElement> = document.createElement('input')

  if (!input) throw new Error('Missing HTML INPUT')

  input.setAttribute('type', 'file')

  return new Promise((resolve, reject): void => {
    if (!document.body) {
      return reject({ type: 'withError', error: 'No Document Body' })
    }
    const originalFocusHandler = document.body.onfocus

    const handleChange = () => {
      clearListeners()
      if (!input.files) {
        return reject({ type: 'withError', error: 'No Files Selected' })
      }
      resolve(input.files[0])
    }

    // TODO: it smells too much as a hack but we are facing today's browsers limitations
    const handleFocus = () => {
      setTimeout(() => {
        if (input?.files?.length) {
          clearHandleFocus()
        } else {
          clearListeners()
          reject({ type: 'withError', error: 'Cancelled' })
        }
      }, 500)
    }

    const clearHandleFocus = () => {
      if (document.body && originalFocusHandler) {
        document.body.onfocus = originalFocusHandler
      }
    }
    const clearListeners = () => {
      clearHandleFocus()
      input.removeEventListener('change', handleChange)
    }

    input.addEventListener('change', handleChange)
    if (document.body) {
      document.body.onfocus = handleFocus
    }

    const event: MouseEvent = new MouseEvent('click', {
      cancelable: true,
    })
    input.dispatchEvent(event)
  })
}

export function readFile(file: File, options?: ReadFileOptions): Promise<ReadFileResult> {
  const { asText = false } = options || {}

  return new Promise((resolve, reject): void => {
    const reader: FileReader = new FileReader()
    const { name: fileName, size: fileSize } = file

    reader.addEventListener('loadend', () => {
      if (reader.error) {
        reject(reader.error)
      } else {
        const { result } = reader

        // currently we use only the string type for result
        const resultString: string = result as any as string

        if (asText) {
          resolve({
            type: 'resolved',
            file: {
              fileName,
              fileSize,
              mimeType: null,
              encoding: null,
              content: resultString,
              raw: resultString,
              file,
            },
          })
        }

        const contentStart = resultString.indexOf(',') + 1
        const content = resultString.slice(contentStart)

        const encodingAndMimeType = resultString.slice(0, contentStart)
        const mimeType = encodingAndMimeType.slice(0, encodingAndMimeType.indexOf(';'))
        const encoding = resultString.slice(
          encodingAndMimeType.indexOf(';') + 1,
          encodingAndMimeType.indexOf(','),
        )

        resolve({
          type: 'resolved',
          file: {
            fileName,
            fileSize,
            mimeType,
            encoding,
            content,
            raw: resultString,
            file,
          },
        })
      }
    })

    if (asText) {
      reader.readAsText(file)
    } else {
      reader.readAsDataURL(file)
    }
  })
}

export function saveKmlFile(content: string, filename: string): void {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:application/vnd.google-earth.kml+xml;charset=UTF-8,' + encodeURIComponent(content),
  )
  element.setAttribute('download', filename)
  element.click()
}
