import { useCallback } from 'react'

import { buildRoutes as buildRoutesRequest } from '@/features/domain/route'
import { setMainSelection, useEditingStateWithCtx, useRoutingSidebar } from '@/atoms'
import { getMapInfo, setPendingFitMap } from '@/map'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { useNavigate } from '@/routing'

import { useTexts } from '../useTexts'

export const useActions = (
  showBuildProgressModal: () => void,
  showCopyOrdersModal?: () => void,
) => {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const navigate = useNavigate(true)
  const { setEditing } = useEditingStateWithCtx('order')
  const [, setRoutingSidebar] = useRoutingSidebar()

  const addOrder = useCallback(() => {
    setMainSelection('orderSteps', 'reset')
    setPendingFitMap([getMapInfo().center])
    navigate('orders')
    setEditing([])
  }, [setEditing, navigate])

  const buildRoutes = useCallback(() => {
    try {
      showBuildProgressModal()
      dispatch(buildRoutesRequest())
    } catch (e) {
      toast.error(texts.buildRoutesError)
    }
  }, [texts, toast, dispatch, showBuildProgressModal])

  const exportToOperations = useCallback(async () => {
    showCopyOrdersModal?.()
  }, [showCopyOrdersModal])

  const showDispatchPanel = useCallback(() => {
    setRoutingSidebar({ view: 'dispatchPanel' })
  }, [setRoutingSidebar])

  return { addOrder, buildRoutes, showDispatchPanel, exportToOperations }
}
