import type { MutableDropDownState } from './useDropDownStateRef'

import { useCallback } from 'react'

export function useDropDownStatusChange(
  cleatItems: () => void,
  dropDownStateRef: MutableDropDownState,
) {
  const onOpen = useCallback(() => {
    const preventDefault = dropDownStateRef.current.loading

    if (!preventDefault) {
      dropDownStateRef.current.setDropDownState({ open: true })
    }

    return preventDefault
  }, [dropDownStateRef])

  const onClose = useCallback(() => {
    cleatItems()
    dropDownStateRef.current.setDropDownState({ loading: false, open: false })
  }, [cleatItems, dropDownStateRef])

  return { onOpen, onClose } as const
}
