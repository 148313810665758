import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    errorFetchingAccountData: translate({
      id: 'settings.gpsAccount.view.errorFetchingAccountData',
    }),

    updateAccountSuccess: translate({ id: 'settings.gpsAccount.edit.save.success' }),
    updateAccountError: translate({ id: 'settings.gpsAccount.edit.save.error' }),
  }))

  return api
}
