import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { useResetEditingState } from '@/atoms'
import { updateVehicle } from '@/features/domain/vehicle'
import { selectUserConfiguration } from '@/features/domain/user'

import { patchGpsData } from '../utils/patchGpsData'

export function useOnSubmit(uv: uui.domain.client.UnifiedVehicle) {
  const userConfig = useSelector(selectUserConfiguration)
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const stopEditing = useResetEditingState()

  const metric = userConfig.distanceFormat === 'METRIC'

  return useCallback(
    async (formValues: uui.domain.ui.forms.GpsVehicleFormValues) => {
      const patchedVehicleAndSummary = patchGpsData(uv, formValues, metric)
      try {
        const result = await dispatch(updateVehicle(patchedVehicleAndSummary))

        if (!updateVehicle.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [uv, metric, dispatch, stopEditing, toast],
  )
}
