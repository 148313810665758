import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
  ReadonlyFieldArrow,
  ReadonlyList,
} from '@/forms-legacy'

import { useTexts } from './useTexts'

interface Props {
  gpsTags: uui.domain.client.gps.wwgps.Tag[]
}

export function Badges(props: Props) {
  const { gpsTags } = props

  const texts = useTexts()
  const isListEmpty = gpsTags.length === 0

  return (
    <ReadonlyBlock>
      <ReadonlyLabel empty={isListEmpty} primary>
        {texts.badges}
      </ReadonlyLabel>
      {!isListEmpty && (
        <ReadonlyList style={{ marginTop: '-6px' }}>
          {gpsTags.map(tag => (
            <ReadonlyBlock key={tag.id}>
              <ReadonlyFieldArrow>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>{tag.value}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyFieldArrow>
            </ReadonlyBlock>
          ))}
        </ReadonlyList>
      )}
    </ReadonlyBlock>
  )
}
