import type { RouteViolationsCount } from '../../hooks/useViolationsCountFromRoutes'

import { styled } from '@mui/material'

import { HorizontalLayout } from '@/components/layout'
import { Text } from '@/local/components'
import { Flag } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  violations: RouteViolationsCount
}

const RouteViolationTagComp = styled('div', { name: 'RouteViolationTag' })(({ theme }) => ({
  outline: 0,
  marginTop: 2,
  borderWidth: 0,
  marginRight: 6,
  borderRadius: 4,
  padding: '0 6px',
  backgroundColor: theme.palette.warning.main,
}))

export function RouteViolationTag(props: Props) {
  const { violations } = props

  const texts = useTexts()

  const violationsSummary = `${texts.summaryViolations(violations.violationsCount)} •`
  const constraintsSummary = texts.summaryConstraints(violations.constraintsCount)

  return (
    <RouteViolationTagComp
      data-trackid="navigo-routes-overview-details-bulk-violations"
      data-testid="navigo-routes-overview-details-bulk-violations"
    >
      <HorizontalLayout alignItems="center" height={18}>
        <Flag color="$nightRider" size={13} marginRight={4} />

        <>
          <Text
            testid="navigo-routes-overview-details-bulk-violations-violationsSummary"
            color="$nightRider"
            weight="$bold"
            size="$p3"
          >
            {violationsSummary}
          </Text>
          &nbsp;
          <Text
            testid="navigo-routes-overview-details-bulk-violations-constraintsSummary"
            color="$nightRider"
            size="$p3"
          >
            {constraintsSummary}
          </Text>
        </>
      </HorizontalLayout>
    </RouteViolationTagComp>
  )
}
