import styled from 'styled-components'

type Props = {
  width?: number
  disabled?: boolean
}

const ConfirmButton = styled.button<Props>`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  padding: 0;
  border-width: 0;
  outline: 0;

  line-height: 1;

  width: ${p => p.width ?? 90}px;
  height: 18px;

  font-size: ${p => p.theme.fonts.$s};
  font-weight: ${p => p.theme.weights.$semiBold};
  color: ${p => p.theme.colors.$pureWhite};
  background: ${p => p.theme.colors.$outrageousRed};

  border-radius: 4px;

  opacity: ${p => (p.disabled ? 0.3 : 1)};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
`

ConfirmButton.displayName = 'ConfirmButton'
export default ConfirmButton
