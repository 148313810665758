import { Stack, Typography, Button } from '@mui/material'

import { useSupportTicketModal } from '@/components/modals/SupportTicketModal'

import { useTexts } from './useTexts'
import { useActions } from './useActions'

export function Page404() {
  const texts = useTexts()
  const actions = useActions()

  const { Modal } = useSupportTicketModal()

  return (
    <>
      <Stack spacing={4}>
        <Stack spacing={0}>
          <Typography variant="h3">{texts.title}</Typography>
          <Typography variant="h4">{texts.description}</Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={actions.onNavigateHome}>
            {texts.primaryAction}
          </Button>
          <Button variant="text" onClick={actions.onOpenSupportTicket}>
            {texts.secondaryAction}
          </Button>
        </Stack>
      </Stack>
      <Modal />
    </>
  )
}
