import { useCallback } from 'react'

import { setCrudSelection, useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { fileUtils, roadSegmentsUtils } from '@/utils'
import { importRoadSegmentsFromKML } from '@/features/domain/roadSegment'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useTexts } from '../../../../../useTexts'

export function useActions() {
  const { editing, setEditing } = useEditingStateWithCtx('segment')
  const [selection, setSelection] = useCrudSelection('routingSegments')
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  const onCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  const onImportKML = useCallback(async () => {
    try {
      const file = await fileUtils.openFile()
      if (!file) return

      const readFileResult = await fileUtils.readFile(file, { asText: true })

      if (readFileResult.type !== 'resolved') {
        throw new Error('cannot read file')
      }

      const segments = roadSegmentsUtils.KMLToSegments(readFileResult.file.content)
      const result = await dispatch(importRoadSegmentsFromKML(segments))

      if (!importRoadSegmentsFromKML.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      setCrudSelection('routingSegments', result.payload)
    } catch (e) {
      toast.error(texts.importKMLFailed)
    }
  }, [dispatch, toast, texts])

  return { editing, onCreate, onImportKML }
}
