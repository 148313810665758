import { Checkbox, FormControlLabel } from '@mui/material'
import { type LocaleText } from '../../../types'

interface AllTogglerProps {
  checked: boolean
  indeterminate: boolean
  onToggle: () => void
  localeText: LocaleText
  filter: string
}

export function AllToggler(props: AllTogglerProps) {
  const { checked, localeText, indeterminate, onToggle, filter } = props

  const label =
    filter.length > 0 ? localeText.selectAllColumnsFiltered : localeText.selectAllColumns

  return (
    <FormControlLabel
      data-testid="columns-picker-toggle-all-selected"
      control={<Checkbox checked={checked} indeterminate={indeterminate} onChange={onToggle} />}
      label={label}
    />
  )
}
