import { ReactNode, PureComponent } from 'react'
import { format } from 'date-fns/esm'

import {
  TimeWindowExceptionList,
  Block,
  Label,
  FieldMessage,
  RestoreFieldButton,
} from '@/forms-legacy'

import { formatField } from './utils'
import { Props } from './typings'

export default class TimeWindowExceptionListField extends PureComponent<Props> {
  private isFieldDirty(): boolean {
    const {
      formProps: {
        input: { value },
        meta: { dirty },
      },
    } = this.props

    if (Object.keys(value).length === 0) {
      return false
    }

    return !!dirty
  }

  private renderDateError = (date: string | Date): ReactNode => {
    const {
      formProps: {
        meta: { error },
      },
    } = this.props

    if (!error) {
      return null
    }

    const dateAsString: string = typeof date === 'string' ? date : format(date, 'yyyyMMdd')
    const errorInfo = error[dateAsString]

    if (errorInfo) {
      const { date: dateError } = errorInfo

      if (dateError) {
        return (
          <FieldMessage style={{ marginBottom: 10 }} error>
            {dateError}
          </FieldMessage>
        )
      }
    }

    return null
  }

  private renderError = (date: string | Date, index: number): ReactNode => {
    const {
      formProps: {
        meta: { error },
      },
    } = this.props

    if (!error) {
      return null
    }

    const dateAsString: string = typeof date === 'string' ? date : format(date, 'yyyyMMdd')
    const errorInfo = error[dateAsString]

    if (errorInfo) {
      const { timeWindows } = errorInfo

      if (timeWindows) {
        const errorString: string = Array.isArray(timeWindows)
          ? timeWindows[index] || ''
          : timeWindows

        if (errorString && errorString.trim().length > 0) {
          return (
            <FieldMessage style={{ marginBottom: 10 }} error>
              {errorString}
            </FieldMessage>
          )
        }
      }
    }

    return null
  }

  private handleOnRestore = (exceptions: uui.domain.rm.TimeWindowExceptions) => {
    const {
      formProps: {
        input: { onChange },
      },
    } = this.props

    onChange(formatField(exceptions))
  }

  render() {
    const {
      label,
      name,
      info,
      half = false,
      wrappedInputProps = {},
      formProps: {
        input: { value, onChange },
        meta: { error, initial },
      },
      testid,
    } = this.props

    const isDirty: boolean = this.isFieldDirty()

    return (
      <Block half={half} error={!!error} dirty={isDirty} data-testid={testid}>
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={isDirty}
            initial={initial}
            onChange={this.handleOnRestore}
            style={{ marginLeft: 12 }}
          />
        </Label>

        <TimeWindowExceptionList
          {...wrappedInputProps}
          onChange={onChange}
          list={value}
          renderError={this.renderError}
          renderDateError={this.renderDateError}
        />
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }
}
