import type { QueryParameters } from '@/hooks'

import { useState, useEffect, useRef } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

import {
  clearTokens,
  useEnvironmentURLs,
  updateAccountDataToken,
  useSelectAutoLoginAccountData,
} from '@/atoms'

import { autoLogin } from '@/features/actions/autoLogin'
import { useAppDispatch, useJournal } from '@/store'
import { useNavigate } from '@/routing'

import { resumeImport } from '@/utils'

type LoginRequest = Parameters<typeof autoLogin>[0]

type Props = {
  onComplete: (success: boolean) => void
  onLoadInitialDataComplete: () => void
  query: QueryParameters
}

export function AutoLoginFromLocalStorage(props: Props) {
  const { onComplete, onLoadInitialDataComplete, query } = props

  const journal = useJournal()
  const dispatch = useAppDispatch()
  const { serverUrl } = useEnvironmentURLs()

  const navigate = useNavigate()
  const { username, token } = useSelectAutoLoginAccountData() ?? {}

  const [request, setRequest] = useState<LoginRequest | undefined>()

  const api = useRef({
    query,
    journal,
    dispatch,
    navigate,
    serverUrl,
    onComplete,
    clearTokens,
    updateAccountDataToken,
    username,
    token,
    onLoadInitialDataComplete,
  })

  useEffect(() => {
    api.current = {
      query,
      journal,
      dispatch,
      navigate,
      serverUrl,
      onComplete,
      clearTokens,
      updateAccountDataToken,

      token,
      username,
      onLoadInitialDataComplete,
    }
  })

  useEffect(() => {
    const { journal, onComplete, username, token, serverUrl } = api.current

    if (username && !!token) {
      journal.main(`Try to login as ${username}`, { tags: ['bootstrap'] })

      // Resume pending imports if present
      const importData = resumeImport()
      setRequest({
        mode: 'token',
        username,
        token,
        serverUrl,
        importData,
        territoryId: query?.territoryId ?? undefined,
        simulationId: query?.simulationId ?? undefined,
      })
    } else {
      journal.main('Cannot autologin from localStorage ', {
        tags: ['bootstrap'],
        info: {
          username,
          token,
        },
      })

      // no account data to access
      onComplete(false)
    }
  }, [query])

  useEffect(() => {
    const { dispatch, navigate, clearTokens, updateAccountDataToken, onComplete } = api.current

    if (!request) return

    const execute = async () => {
      try {
        const loginRequest = await dispatch(autoLogin(request))

        if (autoLogin.fulfilled.match(loginRequest)) {
          // success
          const { clientUserProfile: profile, loadInitialDataPromise } = unwrapResult(loginRequest)
          loadInitialDataPromise.then(api.current.onLoadInitialDataComplete)

          // login successful, update account data atom (rememberMe = true for autologin)
          updateAccountDataToken(request.username, profile.authtoken, true)

          onComplete(true)
        } else {
          // managed error

          if (loginRequest.payload?.type === 'too-many-sessions') {
            navigate('error/too-many-sessions')
          } else {
            clearTokens()
          }

          // login Failed
          onComplete(false)
        }
      } catch (e) {
        clearTokens()
        navigate('error')
      }
    }

    execute()
  }, [request])

  return null
}
