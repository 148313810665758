import { ConvertDistance } from '@/components/smartUtils/conversion/ConvertDistance'
import { VerticalLayout, HorizontalLayout } from '@/components/layout'
import { Text } from '@/local/components'

import { useTexts } from '../useTexts'

interface Props {
  approvedLength?: number
  distanceLeft: number
}

export function DistanceBlock(props: Props) {
  const texts = useTexts()
  const { approvedLength, distanceLeft } = props

  const approvedLengthTitle = `${texts.approvedLength}:`
  const distanceLeftTitle = `${texts.distanceLeft}:`

  return (
    <VerticalLayout
      data-trackid="navigo-routes-overview-details-single-routeStats-distanceBlock"
      data-testid="navigo-routes-overview-details-single-routeStats-distanceBlock"
      justifyContent="space-between"
      width="auto"
    >
      <HorizontalLayout height="auto">
        <Text size="$p3">{approvedLengthTitle}&nbsp;</Text>

        <Text
          testid="navigo-routes-overview-details-single-routeStats-distanceBlock-approvedLength"
          weight="$semiBold"
          size="$p3"
        >
          {approvedLength !== undefined ? (
            <ConvertDistance meters={approvedLength} />
          ) : (
            texts.notSet
          )}
        </Text>
      </HorizontalLayout>

      <HorizontalLayout height="auto">
        <Text size="$p3">{distanceLeftTitle}&nbsp;</Text>

        <Text
          testid="navigo-routes-overview-details-single-routeStats-distanceBlock-distanceLeft"
          weight="$semiBold"
          size="$p3"
        >
          <ConvertDistance meters={distanceLeft} />
        </Text>
      </HorizontalLayout>
    </VerticalLayout>
  )
}
