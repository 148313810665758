import type { FixedIntervalCalendarProps as Props } from '../../typings'

import { HorizontalLayout, VerticalLayout } from '@/components/layout'

import { useMonthNavigatorProps } from '../../hooks/useMonthNavigatorProps'
import { MonthNavigator } from '../../components/MonthNavigator'
import { useMonths } from '../../hooks/useMonths'
import { Month } from '../../components/Month'

import { useFocusOnSelection } from './hooks/useFocusOnSelection'
import { useUpdateSelection } from './hooks/useUpdateSelection'
import { useSelectionData } from './hooks/useSelectionData'
import { useIntervalDays } from './hooks/useIntervalDays'
import { useHoveredDay } from './hooks/useHoveredDay'

export function FixedInterval(props: Props) {
  const {
    today,
    minDate,
    maxDate,
    onChange,
    dotColor,
    disabledDays,
    selectionEnd,
    referenceDate,
    intervalLength,
    highlightToday,
    selectionStart,
    highlightedDays,
    fixedRangeStart,
    tooltipIntlText,
    focusOnSelection,
    setReferenceDate,
    lastSelectableDate,
    firstNonArchivedDay,
    months: monthsCount,
    showNeighboringMonth,
    allowAutoMonthHeight,
  } = props

  // That hook that if the selection changes and is not already showed, the calendar scrolls to it
  useFocusOnSelection(referenceDate, setReferenceDate, selectionStart, focusOnSelection)

  const months = useMonths(referenceDate, showNeighboringMonth, monthsCount, minDate, maxDate)
  const [hoveredDay, onMouseOverDay] = useHoveredDay()

  const selectionData = useSelectionData(
    minDate,
    maxDate,
    intervalLength,
    selectionStart,
    selectionEnd,
    firstNonArchivedDay,
  )

  const daysByMonth = useIntervalDays({
    ...selectionData,
    firstNonArchivedDay,
    highlightedDays,
    tooltipIntlText,
    disabledDays,
    months,
    today,
    fixedRangeStart,
    selectionStart,
    highlightToday,
    intervalLength,
    lastSelectableDate,
    hoveredDay,
  })

  const monthNavigatorProps = useMonthNavigatorProps({
    referenceDate,
    setReferenceDate,
    months: monthsCount,
    minDateAsString: minDate,
    maxDateAsString: maxDate,
    labelFormat: 'month-year',
  })

  const handleOnClick = useUpdateSelection(onChange, lastSelectableDate, intervalLength)

  return (
    <VerticalLayout height="auto" alignItems="center">
      <MonthNavigator {...monthNavigatorProps} />
      <HorizontalLayout width="auto">
        {months.map(
          (month, index) =>
            daysByMonth[month.id] && (
              <Month
                index={index}
                key={month.id}
                showDaysOfWeek
                dotColor={dotColor}
                onClick={handleOnClick}
                days={daysByMonth[month.id]}
                onMouseOverDay={onMouseOverDay}
                allowAutoMonthHeight={allowAutoMonthHeight}
              />
            ),
        )}
      </HorizontalLayout>
    </VerticalLayout>
  )
}
