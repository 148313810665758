import { Text } from '@/local/components'
import { VerticalLayout, HorizontalLayout } from '@/components/layout'
import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'

import { useTexts } from './useTexts'

interface Props {
  trackingData: uui.domain.client.rm.OrderStepTrackingData
}

export function AutoTimeBlock(props: Props) {
  const texts = useTexts()
  const { autoTimeInSec, autoTimeOutSec } = props.trackingData

  return (
    <VerticalLayout width="auto" justifyContent="space-between" data-testid="AutoTimeBlock">
      <HorizontalLayout height="auto" justifyContent="end">
        <Text size="$p3">{`${texts.reportedTimeIn}:`}&nbsp;</Text>
        <Text size="$p3" weight="$semiBold">
          <ConvertTime seconds={autoTimeInSec} showSeconds={false} />
        </Text>
      </HorizontalLayout>

      <HorizontalLayout height="auto" justifyContent="end">
        <Text size="$p3">{`${texts.reportedTimeOut}:`}&nbsp;</Text>
        <Text size="$p3" weight="$semiBold">
          <ConvertTime seconds={autoTimeOutSec} showSeconds={false} />
        </Text>
      </HorizontalLayout>
    </VerticalLayout>
  )
}
