export function computeCenterOnMapPermission(
  isToday: boolean,
  activeMapStyle: 'on' | 'label' | 'off',
  isLoadingGpsData: boolean,
  deviceIds?: string[],
) {
  if (isLoadingGpsData) return 'disabledLoadingGpsData'
  if (!deviceIds || deviceIds.length === 0) return 'disabledNoDevice'
  if (activeMapStyle === 'off') return 'disabledNoMarker'
  if (!isToday) return 'disabled'

  return 'enabled'
}
