const cookieId = 'cookieUrl'

export function injectCookie(cookieUrl: string) {
  const script: HTMLScriptElement = document.createElement('script')
  script.id = cookieId
  script.src = cookieUrl
  window.document.head.appendChild(script)
}
export function removeInjectedCookie() {
  document.getElementById(cookieId)?.remove()
}
