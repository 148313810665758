import type { FormFields, FormErrors } from './formFields'

import { Box, Button, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useFormMeta } from '@workwave-tidal/tidal/form-fairy'

import { useTexts } from '../hooks/useTexts'
import { useSubmitDisabled } from './hooks/useSubmitDisabled'

interface Props {
  submitting: boolean
  onSubmit: () => void
  onCancel: () => void
}

export function FormFooter(props: Props) {
  const { onSubmit, onCancel } = props

  const submitDisabled = useSubmitDisabled()
  const {
    meta: { submitting },
  } = useFormMeta<FormFields, FormErrors>()
  const texts = useTexts()
  const theme = useTheme()

  return (
    <Box
      justifyContent="flex-start"
      flexDirection="row"
      component="footer"
      display="flex"
      paddingTop={4}
      paddingLeft={4}
      paddingRight={4}
      gap={theme.spacing(2)}
    >
      <LoadingButton
        className="pendo-preferences-form-save-button"
        disabled={submitDisabled}
        data-testid="save-button"
        data-trackid="save-button"
        onClick={onSubmit}
        loading={submitting}
        variant="contained"
        color="primary"
        size="medium"
      >
        {texts.save}
      </LoadingButton>

      <Button
        onClick={onCancel}
        type="button"
        variant="text"
        color="primary"
        size="medium"
        data-testid="cancel-button"
        data-trackid="cancel-button"
        className="pendo-preferences-form-cancel-button"
      >
        {texts.cancel}
      </Button>
    </Box>
  )
}
