import { subscribe } from 'valtio'

import { addLocationPinFeature } from '../../layers/pin/locationPin/addLocationPinFeature'
import { updateLocationPinFeature } from '../../layers/pin/locationPin/updateLocationPinFeature'
import { removeLocationPinFeature } from '../../layers/pin/locationPin/removeLocationPinFeature'

import { getAllLocationPin } from './core/getAllLocationPin'
import { locationPin } from './locationPin'

export function connectMapToLocationPin() {
  const activePins = new Set<uui.domain.ui.map.LocationPinId>()

  return subscribe(locationPin, () => {
    const pins = getAllLocationPin(true)

    const removedPins = new Set(activePins)

    for (const pin of Object.values(pins)) {
      if (!pin) continue

      if (activePins.has(pin.id)) {
        //  update a location pin
        updateLocationPinFeature(pin)
      } else {
        // create a location pin
        addLocationPinFeature(pin)
      }

      removedPins.delete(pin.id)
      activePins.add(pin.id)
    }

    for (const pinId of removedPins) {
      activePins.delete(pinId)

      // try to remove the location pin
      removeLocationPinFeature(pinId)
    }
  })
}
