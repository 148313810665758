import LineString from 'ol/geom/LineString'
import Geometry from 'ol/geom/Geometry'
import Point from 'ol/geom/Point'

import { genericStyles } from '../../../../layers/genericStyles'

export function createLabel() {
  const label = genericStyles.label.clone()

  label.setGeometry(feature => {
    const geometry = feature?.getGeometry()

    if (geometry instanceof Geometry && geometry.getType() === 'LineString') {
      const line = geometry as LineString
      const coords = line.getCoordinates()
      const textInstance = label.getText()

      textInstance.setOffsetY(-40)

      textInstance.setText(`${Math.round((line.getLength() * 100) / 100)} m`)

      if (coords.length > 1) {
        return new Point(coords[0])
      }
    }
  })

  return label
}
