import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { setDepotFeatureMetadata } from '../depotFeatureMetadata'
import { getDepotFeatureStyle } from './getDepotFeatureStyle'
import { getDepotFeatureLabelStyle } from './getDepotFeatureLabelStyle'

export const updateDepotFeature = (
  mapStyles: uui.domain.ui.map.markers.MapStyles['depot'],
  marker: uui.domain.ui.map.markers.Depot,
  feature: Feature<Geometry>,
  selected: boolean = false,
): Feature<Geometry> => {
  // ------------------------
  // Feature Metadata
  // ------------------------

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['depot']['mode'] = marker.mode ?? mapStyles.mode
  setDepotFeatureMetadata(feature, 'mode', mode)

  // OrdersWithViolationsCount is currently not used but can became useful in the future

  // update hasViolations
  setDepotFeatureMetadata(feature, 'hasViolations', marker.hasViolations)

  // update order counts
  setDepotFeatureMetadata(feature, 'ordersCount', marker.ordersCount)
  setDepotFeatureMetadata(feature, 'ordersWithViolationsCount', marker.ordersWithViolationsCount)

  // ------------------------
  // Feature Style

  feature.setStyle([
    getDepotFeatureStyle(marker, mode, selected),
    getDepotFeatureLabelStyle(marker, mode),
  ])

  return feature
}
