import { Alert, Stack, Typography } from '@mui/material'

import { useTexts } from '../../../../hooks/useTexts'

export function NoRemainingLicensesAlert() {
  const texts = useTexts()

  return (
    <Stack width="100%" marginY={2}>
      <Alert severity="info">
        <Stack alignItems="flex-start" spacing={1}>
          <Typography variant="body2">{texts.noRemainingLicenses.alert}</Typography>
        </Stack>
      </Alert>
    </Stack>
  )
}
