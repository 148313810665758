import { useCallback } from 'react'

import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function ErrorFooter() {
  const {
    close,
    update,
    data: { notification },
  } = useController()

  const texts = useTexts()

  const tryAgain = useCallback(() => {
    update({ status: 'ready' })
  }, [update])

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          className={`pendo-${notification}-test-modal-try-again`}
          testid={`${notification}-test-modal-try-again`}
          variant="contained"
          onClick={tryAgain}
          maxWidth="50%"
        >
          {texts.tryAgain}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          className={`pendo-${notification}-test-modal-close-after-error`}
          testid={`${notification}-test-modal-close-after-error`}
          onClick={close}
        >
          {texts.close}
        </ModalFooterButton>
      }
    />
  )
}
