import type { CSSProperties } from 'react'

import { useMemo } from 'react'
import { makeStyles } from '@mui/styles'

const useClasses = makeStyles({
  dot: {
    backgroundColor: 'var(--color)',
    borderRadius: 5,
    marginRight: 5,
    marginLeft: 5,
    height: 10,
    width: 10,
  },
})

export function useStyles(color: string) {
  const classes = useClasses()

  const styles = useMemo(() => {
    return {
      dot: {
        '--color': color,
      } as CSSProperties,
    } as const
  }, [color])

  return [classes, styles] as const
}
