import type { KeyboardEvent } from 'react'
import type { AutocompleteCloseReason } from '@mui/material'
import type { AddLoadOption as Option } from '../../../types'

import { useCallback, useState } from 'react'

export function useActions(
  options: Option[],
  onAddTag: (tagName: string, newOption?: boolean) => void,
  onClose: () => void,
) {
  const [open, setOpen] = useState(true)
  const [inputValue, setInputValue] = useState<string>('')

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const value = inputValue.trim()
      if (e.code === 'Enter' && value.length > 0) {
        onAddTag(value, isNewOption(value, options))
      }
    },
    [inputValue, onAddTag, options],
  )

  const onChange = useCallback(
    (_, selectedOption: Option | null) => {
      if (!selectedOption) return
      const label = selectedOption.inputValue ?? selectedOption.label
      onAddTag(label, selectedOption.newOption)
    },
    [onAddTag],
  )

  const onInputChange = useCallback((_, value) => setInputValue(value), [])

  const onOpen = useCallback(() => setOpen(true), [])

  const onCloseAutocomplete = useCallback(
    (_, reason: AutocompleteCloseReason) => {
      if (reason === 'escape') {
        onClose()
        return
      }
      setOpen(false)
    },
    [onClose],
  )

  return {
    open,
    inputValue,
    onKeyDown,
    onChange,
    onInputChange,
    onOpen,
    onCloseAutocomplete,
  }
}

function isNewOption(value: string, options: Option[]) {
  return !options.find(opt => opt.label === value)
}
