import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  differenceInCalendarDays,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  isSameDay,
  addMonths,
  addDays,
  subDays,
  format,
  parse,
} from 'date-fns/esm'

import { useDaysWithOrders } from '@/hooks'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectTerritoryLicensingLimits } from '@/features/domain/territory'

const now = new Date()

export function useControllerData() {
  const { horizonView } = useSelector(selectTerritoryLicensingLimits)
  const calendarRange = useSelector(selectCalendarRangeInterval)

  const { today, startFromDate, planType } = useSelector(selectUserConfiguration)
  const daysWithOrders = useDaysWithOrders(planType === 'simulation' ? 'ops' : 'active')

  const { start, end } = calendarRange

  // Computing min/max date
  const todayAsDate = useMemo(() => parse(today, 'yyyyMMdd', now), [today])
  const lastSelectableDate = useMemo(
    () => addDays(todayAsDate, horizonView - 1),
    [todayAsDate, horizonView],
  )
  const minDate = useMemo(() => startOfMonth(todayAsDate), [todayAsDate])
  const minDateAsString = useMemo(() => format(minDate, 'yyyyMMdd'), [minDate])

  const maxDate = useMemo(() => endOfMonth(addMonths(lastSelectableDate, 1)), [lastSelectableDate])
  const maxDateAsString = useMemo(() => format(maxDate, 'yyyyMMdd'), [maxDate])

  // Computing max interval length
  const intervalLength = useMemo(() => differenceInCalendarDays(end, start) + 1, [start, end])

  // Computing initial selection end
  const selectionEnd = useMemo(
    () => addDays(todayAsDate, intervalLength - 1),
    [todayAsDate, intervalLength],
  )

  // Computing disabled days
  const disabledDays = useMemo(() => {
    const disabledDates: Date[] = []

    if (!isSameDay(minDate, todayAsDate)) {
      disabledDates.push(
        ...eachDayOfInterval({
          start: minDate,
          end: subDays(todayAsDate, 1),
        }),
      )
    }

    if (!isSameDay(maxDate, lastSelectableDate)) {
      disabledDates.push(
        ...eachDayOfInterval({
          start: addDays(lastSelectableDate, 1),
          end: maxDate,
        }),
      )
    }

    return disabledDates.reduce<Record<string, number>>((acc, d) => {
      acc[format(d, 'yyyyMMdd')] = 1
      return acc
    }, {})
  }, [minDate, maxDate, todayAsDate, lastSelectableDate])

  return {
    today,
    planType,
    selectionEnd,
    disabledDays,
    calendarRange,
    startFromDate,
    intervalLength,
    daysWithOrders,
    minDateAsString,
    maxDateAsString,
    lastSelectableDate,
    selectionStart: todayAsDate,
  }
}
