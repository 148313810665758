import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type Payload = {
  vehicleId: string
  date: string
  timeWindow: uui.domain.rm.TimeWindow
}

/**
 * Update the Vehicle time window of a given exception
 * If exception do not exist it create a new one
 *
 * @private
 */
export const updateVehicleTimeWindow = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('rpc/vehicle/updateVehicleTimeWindow', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const { vehicleId, date, timeWindow } = payload

    const result = await rpc('rpc/vehicle/updateVehicleTimeWindow', {
      category: 'rpc',
      type: 'rpc/vehicle/updateVehicleTimeWindow',
      vehicleId,
      date,
      timeWindow,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {updateVehicleTimeWindow}`,
      { tags: ['rpc', 'vehicle'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Update vehicle time window failed`,
      error,
    })
  }
})
