import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

/**
 * Create depot
 *
 * @private
 */
export const createDepot = createAsyncThunk<
  // Return type of the payload creator
  string[],
  // First argument to the payload creator
  uui.domain.client.rm.Depot,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/depot/create', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/depot/create', {
      category: 'rpc',
      type: 'rpc/depot/create',
      depot: payload,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {createDepot}`,
      { tags: ['rpc', 'depots'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Create depot failed`,
      error,
    })
  }
})
