import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRoutes } from '@/features/domain/route'

export function useRoutesOrderStepIds(routeIds: string[]) {
  const extRoutes = useSelector(selectRoutes)

  return useMemo(
    () =>
      routeIds.reduce<string[]>((acc, routeId) => {
        const extRoute = extRoutes[routeId]
        if (!extRoute) return acc

        acc.push(...extRoute.route.orderStepsIds)

        return acc
      }, []),
    [extRoutes, routeIds],
  )
}
