import { ResourceModel } from '@bryntum/schedulerpro'
import { schedulerPreferencesAtom } from '../../schedulerPreferences'

export function approvedTimeSorter(item1: ResourceModel, item2: ResourceModel) {
  const approvedTime1 = item1.getData(
    'approvedTime',
  ) as uui.domain.client.rm.SchedulerResource['approvedTime']

  const approvedTime2 = item2.getData(
    'approvedTime',
  ) as uui.domain.client.rm.SchedulerResource['approvedTime']

  // It could have been written in a more concise way, but TypeScript is not able to infer the type of the object
  if (!approvedTime1 || !approvedTime2) {
    if (!approvedTime1 && approvedTime2) return -1
    if (approvedTime1 && !approvedTime2) return 1

    return 0
  }

  if (schedulerPreferencesAtom.preferences.showAbsoluteValues) {
    return approvedTime1.absolute - approvedTime2.absolute
  }

  return approvedTime1.percentage - approvedTime2.percentage
}
