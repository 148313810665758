import type Feature from 'ol/Feature'

import { Stroke, Style, Fill, Circle as CircleStyle } from 'ol/style'
import LineString from 'ol/geom/LineString'
import Geometry from 'ol/geom/Geometry'
import Point from 'ol/geom/Point'

import { genericStyles } from '../../genericStyles'

const roadSegmentMarkerStylesCache: Map<string, Style[]> = new Map()

const getStrokeColor = (selected: boolean): string => (selected ? '#007aff' : '#fff')
const getMarkerColor = (marker: uui.domain.ui.map.markers.RoadSegment): string => {
  switch (marker.type) {
    case 'blocked':
      return '#fa603f'
    case 'delayed':
      return '#ff9500'
    case 'disabled':
      return '#c3c3c3'
  }
}

/**
 * Return the right style to be used by a RoadSegment feature
 */
export function getRoadSegmentFeatureStyle(
  marker: uui.domain.ui.map.markers.RoadSegment,
  mode: uui.domain.ui.map.markers.MapStyles['roadSegment']['mode'],
  selected: boolean = false,
) {
  if (mode === 'off') return genericStyles.hidden

  const cacheId = `${marker.type}_${selected ? 1 : 0}`

  if (roadSegmentMarkerStylesCache.has(cacheId)) {
    const cachedStyle = roadSegmentMarkerStylesCache.get(cacheId)
    if (cachedStyle) return cachedStyle
  }

  const color = getMarkerColor(marker)

  const styles = [
    new Style({
      stroke: new Stroke({
        width: 4,
        color,
      }),
    }),
    new Style({
      zIndex: 2,
      image: new CircleStyle({
        radius: 10,
        fill: new Fill({ color }),
        stroke: new Stroke({
          color: getStrokeColor(!!selected),
          width: 2,
        }),
      }),

      geometry: (feature: Feature<Geometry>) => {
        const geometry = feature?.getGeometry()

        if (geometry instanceof Geometry && geometry.getType() === 'LineString') {
          const line = geometry as LineString
          const center = line.getCoordinateAt(0.5)

          return new Point(center)
        }
      },
    }),
  ]

  roadSegmentMarkerStylesCache.set(cacheId, styles)

  return styles
}
