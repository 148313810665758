import styled from 'styled-components'
import Badge from './Badge'

const HEIGHT = 18
const WIDTH = 28
const BORDER = 2

const ICON_SIZE = 9

const TINY_VERSION_HEIGHT = 14
const TINY_VERSION_MIN_WIDTH = 18
const TINY_VERSION_LATERAL_PAD = 4

const styledComponent = styled(Badge)`
  display: block;
  position: relative;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  line-height: ${HEIGHT - BORDER * 2 + 1}px;
  border-radius: ${HEIGHT / 2}px;
  padding: 0 ${HEIGHT / 4}px;
  white-space: nowrap;
  font-size: ${p => p.theme.fonts.$s};
  text-transform: uppercase;
  border: ${BORDER}px solid ${p => p.theme.colors.$pureWhite};
  color: ${p => p.theme.colors.$pureWhite};
  background: ${p => p.theme.colors.$outrageousRed};

  &.o-badge--tiny {
    width: auto;
    padding: 0 ${TINY_VERSION_LATERAL_PAD}px;
    height: ${TINY_VERSION_HEIGHT}px;
    line-height: ${TINY_VERSION_HEIGHT - BORDER * 2 + 1}px;
    text-align: center;
    font-size: ${p => p.theme.fonts.$xs};
    min-width: ${TINY_VERSION_MIN_WIDTH}px;
  }

  &.o-badge--rounded {
    width: ${HEIGHT}px;
    border-color: ${p => p.theme.colors.$pureWhite};
  }

  &.o-badge--tiny.o-badge--rounded {
    height: ${TINY_VERSION_HEIGHT}px;
    width: ${TINY_VERSION_HEIGHT}px;
    min-width: initial;
  }

  .o-badge__icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${ICON_SIZE}px;
    color: currentColor;
  }
`

export default styledComponent
