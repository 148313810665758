import { useSelector } from 'react-redux'

import { selectUserConfigurationType } from '@/features/domain/user'

type FormViewType = 'rmOnly' | 'gspOnly' | 'rm360'

export function useFormViewType(unifiedVehicle: uui.domain.client.UnifiedVehicle): FormViewType {
  const userType = useSelector(selectUserConfigurationType)

  switch (userType) {
    case 'rm360':
    case 'rm360PhysicalDevicesOnly':
    case 'rm360VirtualDevicesOnly':
      return !unifiedVehicle.hasRoutingLicense && unifiedVehicle.isPhysicalDevice
        ? 'gspOnly'
        : 'rm360'

    case 'gpsOnly':
      throw new Error(
        'Invalid user type. GPS only users should not be able to access this section.',
      )

    case 'courier':
    case 'rmViewer':
      return unifiedVehicle.hasRoutingLicense && unifiedVehicle.isPhysicalDevice
        ? 'rm360'
        : unifiedVehicle.hasRoutingLicense
        ? 'rmOnly'
        : 'gspOnly'

    case 'rmOnly':
    case 'rmGuest': // This is a fallback since it cannot access the section
    case 'rm360Legacy':
      return 'rmOnly'
  }
}
