import { Component } from 'react'

import { FlexRow } from '@/forms-legacy'
import { WarningShield } from '@/icons'

import { Props } from './typings'

import DeleteBtn from './components/DeleteBtn'
import Load from './components/Load'
import ErrorLabel from './components/ErrorLabel'
import WarningLabel from './components/WarningLabel'

export default class Loads extends Component<Props> {
  render() {
    const {
      loads,
      onDeleteLoad,
      onChangeLoad,
      suggestions,
      notInSuggestionListLabel,
      mixedNumericValue,
      renderText,
      useMetric,
    } = this.props

    return (
      <div data-testid="loads-list-container__div">
        {Object.entries(loads).map(([key, value]) => {
          const showErrorLabel: boolean = isNaN(value) || value <= 0
          const showNotInListLabel: boolean =
            !!notInSuggestionListLabel && !suggestions.includes(key)

          return (
            <div key={key} style={{ width: '100%' }}>
              <FlexRow style={{ marginTop: 10 }}>
                <DeleteBtn style={{ marginRight: 10 }} onClick={() => onDeleteLoad(key)} />

                <Load
                  label={key}
                  value={value}
                  onChange={onChangeLoad}
                  mixedNumericValue={mixedNumericValue}
                  mixedText={renderText('mixedValue')}
                  useMetric={useMetric}
                />

                {showNotInListLabel && (
                  <WarningLabel style={{ marginLeft: 10 }} data-testid="loads__warning-label">
                    <WarningShield size={10} marginRight={6} as="div" />
                    {notInSuggestionListLabel}
                  </WarningLabel>
                )}
              </FlexRow>

              {showErrorLabel && (
                <ErrorLabel style={{ marginLeft: 28 }} data-testid="loads__error-label">
                  {renderText('notValidValue')}
                </ErrorLabel>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}
