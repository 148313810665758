import styled from 'styled-components'

import TextInput from '../../TextInput'

const HourPickerInputText = styled(TextInput).attrs({
  className: 'o-hour-picker__input-text',
})`
  padding-right: 0;
  flex: 1 0 auto;
  width: 85px;

  .o-input-field-text__reset-button {
    display: none;
  }
`

HourPickerInputText.displayName = 'HourPickerInputText'
export default HourPickerInputText
