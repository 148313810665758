import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDevicesMapStyles } from '@/features/domain/ui'
import { useDevicesNavigator } from './useDevicesNavigator'

export function useStylerData() {
  const mapStyles = useSelector(selectDevicesMapStyles)
  const { currentDeviceIds } = useDevicesNavigator()

  /**
   * This methods retrieve the correct active style for the current device(s).
   * If there are different styles for the chosen devices it will return undefined.
   */
  const activeMapStyle = useMemo(() => {
    const appliedStyles = new Set<'on' | 'off' | 'label'>()

    // Using a Set allows to not create duplicated values
    currentDeviceIds.forEach(id => appliedStyles.add(mapStyles.custom[id] ?? mapStyles.mode))

    // If the Set is composed of just one element, means that the style is the same for all devices
    return appliedStyles.size === 1 ? appliedStyles.values().next().value : undefined
  }, [mapStyles, currentDeviceIds])

  return {
    mapStyles,
    activeMapStyle,
    currentDeviceIds,
  }
}
