import { useMemo } from 'react'
import { Footer as FooterComp } from '@/forms-legacy'

interface Props {
  handleSubmit: () => Promise<any> | void
  submitBtnClassName?: string
  fieldsWithError: string[]
  saveDisabled: boolean
  onCancel: () => void
  submitting: boolean
  errorTitle: string
  saveTitle: string
  cancel: string
  save: string
}

export function Footer(props: Props) {
  const {
    submitBtnClassName,
    fieldsWithError,
    handleSubmit,
    saveDisabled,
    errorTitle,
    submitting,
    saveTitle,
    onCancel,
    cancel,
    save,
  } = props

  const primary = useMemo(
    () => ({
      className: submitBtnClassName,
      disabled: saveDisabled,
      onClick: handleSubmit,
      title: saveTitle,
      text: save,
    }),
    [save, saveTitle, saveDisabled, handleSubmit, submitBtnClassName],
  )

  const secondary = useMemo(
    () => ({
      disabled: submitting,
      onClick: onCancel,
      text: cancel,
    }),
    [onCancel, cancel, submitting],
  )

  return (
    <FooterComp
      errors={fieldsWithError}
      errorTitle={errorTitle}
      submitting={submitting}
      secondary={secondary}
      primary={primary}
    />
  )
}
