import type { TagsListTexts } from '@/forms-legacy'
import { intl } from '@/intl'

export default function renderText(
  field: TagsListTexts,
  _,
  collapsed?: boolean,
  count?: number,
  filter?: string,
): string {
  switch (field) {
    case 'addTag':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.tags.addBadge',
      })

    case 'suggestedListTitle':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.tags.suggestedBadges',
      })

    case 'searchPlaceholder':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.tags.searchPlaceholder',
      })

    case 'loadMoreTag':
      const hasCount = typeof count === 'number'
      if (hasCount) {
        return intl.translate({
          id: 'vehicles.form.edit.generalSettings.tags.loadMore',
          values: { count },
        })
      }
      return intl.translate({
        id: 'global.loadMore',
      })

    case 'suggestionsListMessage':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.tags.suggestionsListMessage',
      })

    case 'noResult':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.tags.noResult',
        values: { filter: filter || '' },
      })

    case 'maxCountReached':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.tags.maxCountReached',
      })

    case 'noTags':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.tags.noBadges',
      })

    case 'bulkTitle':
      return ''

    case 'addToAll':
      return ''

    case 'tagsListMessage':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.tags.badgesList',
      })

    case 'remove':
      const countText = typeof count === 'number' ? `(${count})` : ``
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.tags.remove',
        values: { count: countText },
      })

    case 'toggleVisibility':
      return collapsed
        ? intl.translate({
            id: 'global.show',
          })
        : intl.translate({
            id: 'global.hide',
          })
    default:
      return field
  }
}
