import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    radius: translate({ id: 'global.radius' }),

    linkedVehicles: (allVehiclesLinked: boolean, count: number) => {
      const id = allVehiclesLinked
        ? 'gps.geofences.list.allVehiclesLinked'
        : 'gps.geofences.list.linkedVehicles'

      return translate({ id, values: { count } })
    },
  }))

  return api
}
