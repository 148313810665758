import type OlMap from 'ol/Map'

import VectorImageLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { setLayerMetadata } from '../layerMetadata'

export function createExecutionEventsLayer(map: OlMap) {
  const layer = new VectorImageLayer({ source: new VectorSource() })

  setLayerMetadata(layer, 'uid', 'executionEvents')
  layer.setVisible(false)

  map.addLayer(layer)
}
