import { type ColumnStore, type SchedulerPro } from '@bryntum/schedulerpro'
import { subscribe } from 'valtio/vanilla'

import { routeLoadingStateAtom } from '../atoms/routeLoadingState'

export function startSchedulerRouteLoadingStateSync(scheduler: SchedulerPro) {
  return subscribe(routeLoadingStateAtom, () => {
    const columnStore = scheduler.columns as ColumnStore
    const lockedColumn = columnStore.get('routeLock')
    const visibilityColumn = columnStore.get('routeVisible')

    scheduler.refreshColumn(lockedColumn)
    scheduler.refreshHeader(lockedColumn)
    scheduler.refreshColumn(visibilityColumn)
    scheduler.refreshHeader(visibilityColumn)
  })
}
