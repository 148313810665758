import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function SurroundedPin(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 15 15">
        <path d="M7.5 0C4.951154 0 2.884615 2.066538 2.884615 4.615385c0 3.461538 4.038462 4.615384 4.038462 6.923077 0 .318461.258461.576923.576923.576923.318462 0 .576923-.258462.576923-.576923 0-2.307693 4.038462-3.461539 4.038462-6.923077C12.115385 2.066538 10.048846 0 7.5 0zm0 3.461539c.6375 0 1.153846.516346 1.153846 1.153846S8.1375 5.76923 7.5 5.76923s-1.153846-.516346-1.153846-1.153846S6.8625 3.461539 7.5 3.461539zM4.258188 9.49444C1.861073 9.904056 0 10.786154 0 12.115385 0 14.008846 3.773077 15 7.5 15s7.5-.991154 7.5-2.884615c0-1.329231-1.861072-2.211329-4.258188-2.620944-.046731.046731-.09412.09302-.14085.138597-.315577.310962-.609141.60311-.845103.880033 2.660769.313269 4.090295 1.133852 4.090295 1.602314 0 .587884-2.240192 1.730769-6.346154 1.730769s-6.346154-1.142885-6.346154-1.730769c0-.468462 1.429525-1.289045 4.090295-1.602314-.236539-.276923-.530103-.569071-.845103-.880033-.046153-.045577-.093542-.091866-.14085-.138597z" />
      </svg>
    </IconContainer>
  )
}
