import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectUserConfiguration } from '@/features/domain/user'
import { timeUtils } from '@/server-data'

export function useCrateFormatValue() {
  const userConfiguration = useSelector(selectUserConfiguration)

  return useCallback(
    (value: uui.domain.client.rm.FormattedData['assignedTo']) => {
      if (!value) return '-'
      const { dateAsString, vehicle } = value
      return `${timeUtils.formatDate(userConfiguration)(dateAsString)} - ${vehicle}`
    },
    [userConfiguration],
  )
}
