import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({
      id: 'settings.messagingPlan.resetCounterModal.header.title',
    }),

    subtitle: translate({
      id: 'settings.messagingPlan.resetCounterModal.header.subtitle',
    }),

    resetCounter: translate({
      id: 'settings.messagingPlan.resetCounterModal.controls.resetCounter',
    }),

    cancel: translate({
      id: 'settings.messagingPlan.resetCounterModal.controls.cancel',
    }),

    areYouSureToReset: translate({
      id: 'settings.messagingPlan.resetCounterModal.body.areYouSure',
    }),
  }))

  return api
}
