import type { EnvironmentType } from '../environment'
import { journal } from '@/server-data'

import { productionHostname } from '../environment'

export const normalizeEnv = (env: string | unknown): EnvironmentType => {
  if (window.location.hostname === productionHostname && env !== 'production') {
    journal.main(`Invalid env for production`, { info: { env } }, 'error')

    return 'production'
  }

  const normalizedEnv =
    env === 'qa' ||
    env === 'dev' ||
    env === 'local' ||
    env === 'test-a' ||
    env === 'test-b' ||
    env === 'production'
      ? env
      : 'local'

  if (env !== normalizedEnv) {
    journal.main(`Invalid env '${env}'`, { info: { env: env } }, 'warn')
  }

  return normalizedEnv
}
