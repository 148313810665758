import { DropdownMenuItem } from '@/components/DropdownMenu'

import { useRoutingLayoutMenu } from '../hooks/useRoutingLayoutMenu'
import { useTexts } from '../useTexts'
import { TickIcon } from './TickIcon'
import { Spacer } from './Spacer'

export function OrderGridLayoutItem() {
  const texts = useTexts()
  const { setToOrderGrid, layoutMode } = useRoutingLayoutMenu()

  return (
    <DropdownMenuItem
      onClick={setToOrderGrid}
      LeftIcon={layoutMode === 'orders-grid' ? <TickIcon /> : <Spacer />}
      data-testid="header__layout-menu-orderGrid"
    >
      {texts.orderGrid}
    </DropdownMenuItem>
  )
}
