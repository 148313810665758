import { FlexBox } from '@/local/components'

import { StatusIndicatorContainer } from './StatusIndicatorContainer'
import { UpIcon } from './elements/UpIcon'
import { DownIcon } from './elements/DownIcon'
import { Props } from './typings'

const iconSize = 8

export function StatusIndicator(props: Props) {
  const { collapsed, onClick } = props

  const style = {
    justifyContent: collapsed ? 'space-evenly' : 'center',
  }

  return (
    <StatusIndicatorContainer {...props} onClick={onClick}>
      <FlexBox column hAlignContent="center" w={18} h={18} style={style}>
        <UpIcon size={iconSize} />
        {collapsed && <DownIcon size={iconSize} />}
      </FlexBox>
    </StatusIndicatorContainer>
  )
}
