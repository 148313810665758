import type { ReactElement } from 'react'

import { FlexBox, H2, P3, IconButton } from '@/local/components'
import { Cross } from '@/icons'

export interface Props {
  title: string
  subTitle: string
  onClose: () => void
  closeButtonClassName?: string
  Icon?: ReactElement
}

export function PanelHeader(props: Props) {
  const { title, subTitle, Icon, onClose, closeButtonClassName } = props

  return (
    <FlexBox
      w="100%"
      tint="$whiteSmoke"
      color="$nightRider"
      padding="14px 22px"
      vAlignContent="center"
    >
      {!!Icon && <FlexBox column>{Icon}</FlexBox>}
      <FlexBox column grow={1} gutter={18}>
        <H2>{title}</H2>
        {!!subTitle && <P3 style={{ marginTop: 2 }}>{subTitle}</P3>}
      </FlexBox>
      <FlexBox column>
        <IconButton
          onClick={onClose}
          Icon={<Cross size={10} />}
          className={closeButtonClassName}
          data-testid="dispatch-routes__close-button"
        />
      </FlexBox>
    </FlexBox>
  )
}
