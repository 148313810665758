import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import {
  SecondaryColumnHeader,
  SecondaryColumnContentHorizontalRuler,
  SecondaryColumnContentContainer,
  HorizontalRuler,
  FormContent,
} from '@/forms-legacy'

import { selectDevicesAggregateInfo } from '@/features/domain/device'
import { resetCrudSelection } from '@/atoms'
import { FormColumn } from '@/components/layout'

import { canRenderGpsDataToggler } from '../../../../utils/canRenderGpsDataToggler'
import { VehicleAvatar } from '../../../../components/VehicleAvatar'
import { GpsDataViewToggler } from '../../../../components/GpsDataViewToggler'
import { getLabel } from '../../../../utils/getLabel'
import { getDeviceId } from '../../../../utils/getDeviceId'

import { GpsData } from '../components/GpsData'

import { useGpsDataCollapsed } from '../hooks/useGpsDataCollapsed'
import { useCanEditGpsData } from './hooks/useCanEditGpsData'
import { useTexts } from './hooks/useTexts'

import { SingleViewGpsActions } from './SingleViewGpsActions'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function SingleViewGps(props: Props) {
  const { unifiedVehicle } = props

  const resetSelection = useCallback(() => {
    resetCrudSelection('unifiedVehicles')
  }, [])

  const { collapsed, toggleCollapsed } = useGpsDataCollapsed(unifiedVehicle)
  const deviceAggregateInfos = useSelector(selectDevicesAggregateInfo)

  const deviceId = getDeviceId(unifiedVehicle) ?? ''
  const vehicleLabel = getLabel(unifiedVehicle)
  const canRenderToggler = canRenderGpsDataToggler(unifiedVehicle, deviceAggregateInfos)
  const canEditGpsData = useCanEditGpsData()
  const texts = useTexts()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={vehicleLabel}
        description={texts.headerDescription(deviceId)}
        action={resetSelection}
        avatar={<VehicleAvatar vehicle={unifiedVehicle} marginRight={16} size={42} />}
        data-testid="vehicles-readonly-header"
      />
      {canEditGpsData && <SingleViewGpsActions unifiedVehicle={unifiedVehicle} />}
      <FormContent footer={<div />}>
        <SecondaryColumnContentContainer>
          {canEditGpsData && <SecondaryColumnContentHorizontalRuler bigBottomSpace />}
          {canRenderToggler && (
            <GpsDataViewToggler
              title={texts.expand}
              description={texts.deviceSettings(deviceId)}
              collapsed={collapsed}
              onToggle={toggleCollapsed}
            />
          )}
          {canRenderToggler && !collapsed && <GpsData unifiedVehicle={unifiedVehicle} />}

          <HorizontalRuler style={{ height: 0, marginBottom: 60 }} />
        </SecondaryColumnContentContainer>
      </FormContent>
    </FormColumn>
  )
}
