import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    depotHeaderTitle: translate({ id: 'lib.locationEditor.modal.header.title.depot' }),
    orderHeaderTitle: translate({ id: 'lib.locationEditor.modal.header.title.order' }),
    depotHeaderSubtitle: translate({ id: 'lib.locationEditor.modal.header.subtitle.depot' }),
    orderHeaderSubtitle: translate({ id: 'lib.locationEditor.modal.header.subtitle.order' }),
  }))

  return api
}
