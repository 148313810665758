import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

export const clearRequestLog = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  void,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('rpc/demoTools/requestLog/clearRequestLog', async (_params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/demoTools/requestLog/clearRequestLog', {
      category: 'rpc',
      type: 'rpc/demoTools/requestLog/clearRequestLog',
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }
  } catch (error) {
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Error while clearing request log',
      error,
    })
  }
})
