import { Stack } from '@mui/material'
// import { HasPrivileges } from '@/components/HasPrivileges'
import { Separator } from '@/components/Separator'

import { LiveTrafficToggle } from './components/LiveTrafficToggle'
import { InspectPinToggle } from './components/InspectPinToggle'
import { FreezeMapToggle } from './components/FreezeMapToggle'
// import { SwarmsToggle } from './components/SwarmsToggle'
import { FitAllToggle } from './components/FitAllToggle'
import { GlobalStyles } from './components/GlobalStyles'
import { MapTimeRange } from './components/MapTimeRange'
import { FilterOrders } from './components/FilterOrders'
import { Lasso } from './components/Lasso'

interface Props {
  disableInspectPin: boolean
  canFilterOrders: boolean
}

export function Controls(props: Props) {
  const { disableInspectPin, canFilterOrders } = props

  return (
    <Stack direction="row" alignItems="center" height={26}>
      <MapTimeRange />

      <LiveTrafficToggle />

      <FreezeMapToggle />

      <InspectPinToggle disabled={disableInspectPin} />

      <Separator />

      {/*
        I leave the component here since it will be implemented in the future
        <HasPrivileges demo dev>
          <SwarmsToggle />
        </HasPrivileges> */}

      <Lasso />

      <FitAllToggle />

      <GlobalStyles />

      {canFilterOrders && <FilterOrders />}
    </Stack>
  )
}
