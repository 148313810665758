import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Gear(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M50 28.79297v-7.6875l-7.34375-1.19922c-.3828-1.32813-.91016-2.60156-1.57422-3.8047l4.27734-6.09764-5.4375-5.4336-6.01953 4.32817c-1.21484-.67578-2.5-1.21094-3.83203-1.59375L28.7891-.0039h-7.6875l-1.1875 7.28515c-1.34375.3789-2.6289.90625-3.83593 1.57422L10.0703 4.5664 4.63673 10l4.22265 6.03906c-.67968 1.22266-1.21875 2.51563-1.60546 3.85938L0 21.10547v7.6875l7.2422 1.28125c.3867 1.34765.92186 2.64062 1.60546 3.86328L4.5664 39.92188 10 45.36328l6.04688-4.23437c1.21874.6719 2.5078 1.20316 3.84374 1.58597l1.21485 7.28907h7.6836l1.29687-7.3086c1.33984-.3906 2.6211-.92577 3.82812-1.60155L40 45.36327l5.4375-5.4414-4.34766-6.03516c.66797-1.20703 1.1914-2.48047 1.57032-3.8047zM25 32c-3.85938 0-7-3.14063-7-7 0-3.85938 3.14063-7 7-7 3.85938 0 7 3.14063 7 7 0 3.85938-3.14063 7-7 7z" />
      </svg>
    </IconContainer>
  )
}
