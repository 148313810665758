import { useCallback } from 'react'

import {
  SecondaryColumnHeaderActionsRow,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsSeparator,
} from '@/forms-legacy'
import { Trash, ArrowRoundedUpEdge } from '@/icons'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { useTexts } from '../hooks/useTexts'
import { useOnExport } from '../hooks/useOnExport'

interface Props {
  regions: uui.domain.client.rm.Region[]
  setView: (view: 'default' | 'delete') => void
}

export function Actions(props: Props) {
  const { regions, setView } = props

  const texts = useTexts()
  const onExport = useOnExport(regions)

  const onDelete = useCallback(() => {
    setView('delete')
  }, [setView])

  return (
    <SecondaryColumnHeaderActionsRow>
      <SecondaryColumnHeaderActionButton
        label={texts.exportKml}
        icon={<ArrowRoundedUpEdge />}
        onClick={onExport}
        data-trackid="pendo-setup-region__form-bulkexportkml"
      />

      <SecondaryColumnHeaderActionsSeparator />

      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={onDelete}
            disabled={preventEditing}
            data-trackid="pendo-setup-region__form-bulkdelete"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
