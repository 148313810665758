import OlMap from 'ol/Map'

import { findLayer } from '../../layers/utils/findLayer'

import { getInteraction, registerInteraction, setInteractionMetadata } from '../interactionMetadata'
import { DrawEditableAreaInteraction } from '../EditableAreaInteraction'

export const registerDrawEditableAreaInteraction = (map: OlMap) => {
  const uid = 'rm-draw-editable-area-interaction'

  if (getInteraction(uid)) return

  // -------------------------------------------
  // Initialize the Interaction

  const drawLayer = findLayer(map, 'draw')
  const drawSource = drawLayer.getSource()

  if (!drawSource) {
    throw new Error('Cannot register draw editable area interaction: Source is undefined')
  }

  const drawEditableArea = new DrawEditableAreaInteraction({
    source: drawSource,
    // set the base style for the `OVERLAY` layer
    color: '#303030',
  })

  // by default the Interaction is disabled
  drawEditableArea.setActive(false)

  // -------------------------------------------
  // set Interaction Metadata

  setInteractionMetadata(drawEditableArea, 'rmInteraction', true)
  setInteractionMetadata(drawEditableArea, 'uid', uid)
  registerInteraction(uid, drawEditableArea)

  // -------------------------------------------
  // Add the interaction to the map

  // TODO: is this redundant with `registerInteraction` ???
  map.addInteraction(drawEditableArea)
}
