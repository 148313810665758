import { Button } from '@mui/material'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Grow, FormColumn, ComponentSize, ListOverlayEditCover } from '@/components/layout'
import { StructuredVirtualList } from '@/components/StructuredVirtualList'
import { ListFooter, LayoutComposer, NoEntries, NoResults } from '@/components/List'
import { ListLoading } from '@/components/ListLoading'
import { useAutoScroll } from '@/atoms'

import { Header } from './components/Header'
import { Item } from './Item'

import { useQueryParameterSelection } from './hooks/useQueryParameterSelection'
import { useListHandlers } from './hooks/useListHandlers'
import { useDataForList } from './hooks/useDataForList'
import { useTexts } from './hooks/useTexts'

interface Props {
  vehicles: Record<string, uui.domain.client.UnifiedVehicle>
  selection: workwave.DeepReadonly<string[]>
  editing: boolean
}

// Return the height of the item
const getItemHeight = () => 78

// Return the id of the item
const getItemId = (item: uui.domain.client.UnifiedVehicle) => item.unifiedId

export function List(props: Props) {
  const { vehicles, selection, editing } = props

  const texts = useTexts()

  const { handleOnSelect, handleOnDoubleClick, handleOnSelectAll } = useListHandlers(selection)
  const { structure, setCreate, isLoading, lastSelectedVehicle } = useDataForList()

  // This is a hook that will scroll the list to the last selected item
  useAutoScroll('unifiedVehicles', lastSelectedVehicle)

  // This hook will handle the selection of an item if provided via query parameter
  useQueryParameterSelection()

  const allSelected = selection.length >= structure.shownItems
  const multiSelect = selection.length > 1

  return (
    <FormColumn width={320} testid="form__column-primary">
      {isLoading ? (
        <ListLoading />
      ) : (
        <>
          <Header />

          <Grow yAxis xAxis position="relative">
            <LayoutComposer
              totalItems={structure.totalItems}
              shownItems={structure.shownItems}
              noResults={
                <NoResults
                  title={texts.noResults.title}
                  description={texts.noResults.description}
                />
              }
              noEntries={
                <NoEntries
                  title={texts.noEntries.title}
                  description={texts.noEntries.description}
                  action={
                    <ReadOnlyTooltip
                      render={preventEditing => (
                        <Button
                          size="large"
                          variant="outlined"
                          disabled={preventEditing || editing}
                          onClick={setCreate}
                          data-testid="no-entries-add-item"
                          data-trackid="no-entries-add-item"
                        >
                          {texts.noEntries.action}
                        </Button>
                      )}
                    />
                  }
                />
              }
              list={
                <ComponentSize
                  render={size => {
                    return (
                      <>
                        <StructuredVirtualList<'unifiedVehicles'>
                          buffer={50}
                          onSelect={handleOnSelect}
                          getItemId={getItemId}
                          itemsById={vehicles}
                          structure={structure}
                          RenderItem={Item}
                          listHeight={size.height}
                          onDoubleClick={handleOnDoubleClick}
                          getItemHeights={getItemHeight}
                          selectedItemIds={selection}
                          listApiCategory="unifiedVehicles"
                        />
                        {editing && <ListOverlayEditCover />}
                      </>
                    )
                  }}
                />
              }
            />
          </Grow>

          {multiSelect && (
            <ListFooter
              label={texts.selectBtn(allSelected)}
              onToggleSelection={handleOnSelectAll}
              checked={allSelected}
            />
          )}
        </>
      )}
    </FormColumn>
  )
}
