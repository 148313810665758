import { Stack } from '@mui/material'

import { Calendar } from '@/components/Calendar'

import { Chips } from './Chips'
import { useOnCalendarProps } from './useOnCalendarProps'

export type Props = {
  dates: string[]
  initialVisibleDate: string
  onChange: (dates: Date[]) => void
  onReferenceDateChange: (newReferenceDate: Date) => void
}

export function OnCalendar(props: Props) {
  const { dates, onChange } = props
  const calendarProps = useOnCalendarProps(props)

  return (
    <Stack spacing={2}>
      <Calendar
        {...calendarProps}
        initialVisibleDate={props.initialVisibleDate}
        onReferenceDateChange={props.onReferenceDateChange}
      />
      <Chips dates={dates} onChange={onChange} />
    </Stack>
  )
}
