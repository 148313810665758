import { type SchedulerPro } from '@bryntum/schedulerpro'

import { selectUserConfiguration } from '@/features/domain/user'
import { domainProxy, store } from '@/store'

export function startSchedulerDateFormatSync(scheduler: SchedulerPro) {
  return domainProxy.subscribeToDataChangeSuccess(async _domainAction => {
    const timeFormat = selectUserConfiguration(store.getState()).timeFormat
    const displayDateFormat = timeFormat === 'hh:mm aa' ? 'hh:mm A' : timeFormat

    if (displayDateFormat === scheduler.displayDateFormat) return

    // eslint-disable-next-line no-param-reassign
    scheduler.displayDateFormat = displayDateFormat
  })
}
