import { produce } from 'immer'

import { geocodeSearchResult } from '../../../utils/geocodeSearchResult'

import { updateLocationPin } from './updateLocationPin'
import { getLocationPin } from './getLocationPin'

export async function setLocationPinToSearchResult(
  id: uui.domain.ui.map.LocationPinId,
  place: uui.domain.client.gps.GeocodedPlace,
  pinType: uui.domain.ui.map.LocationPinType = 'normal',
) {
  const targetPin = getLocationPin(id, true)

  if (!targetPin) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`Trying to update the address of a non-existent LocationPin with ID: ${id}`)
    }
    return
  }

  // set loading status to true
  updateLocationPin(id, { loading: true })

  // geocode the search result
  const location = await geocodeSearchResult(targetPin, place)

  if (!location) {
    updateLocationPin(id, { loading: false })
    return
  }

  // update the location pin
  updateLocationPin(id, prev => {
    return produce(prev, draft => {
      draft.loading = false
      draft.pin.type = pinType
      draft.pin.location = location
      draft.pin.latLng = location.latLng
      draft.pin.location.address = location.geoAddress
    })
  })
}
