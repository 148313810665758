import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { renderToStaticMarkup } from 'react-dom/server'

import { selectUserConfiguration } from '@/features/domain/user'
import { store } from '@/store'
import { intl } from '@/intl'

import { RouteStart, RouteStartTooltip } from '../../../components/cells/RouteStart'
import { routeStartSorter } from '../sorters/routeStartSorter'

export function createRouteStartColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  const userConfig = selectUserConfiguration(store.getState())

  return {
    text: intl.translate({ id: 'rm.scheduler.column.routeStart' }),
    type: 'template',
    field: 'routeStart',
    width,
    editor: false,
    hidden,
    region: 'cols',
    resizable: true,
    groupable: false,
    sortable: routeStartSorter,
    template: ({ record, value }) => {
      return renderToStaticMarkup(
        <RouteStart value={value} record={record as ResourceModel} userConfig={userConfig} />,
      )
    },
    tooltipRenderer: ({ record }) => {
      const routeStart = record.getData(
        'routeStart',
      ) as uui.domain.client.rm.SchedulerResource['routeStart']

      if (!routeStart) return '-'

      const userConfig = selectUserConfiguration(store.getState())

      return renderToStaticMarkup(
        <RouteStartTooltip record={record as ResourceModel} userConfig={userConfig} />,
      )
    },
  }
}
