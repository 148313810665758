import styled from 'styled-components'
interface Props {
  maxLines?: number
}

const ClampText = styled.div<Props>`
  display: -webkit-box;
  -webkit-line-clamp: ${p => (typeof p.maxLines === 'number' ? p.maxLines : 4)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`

ClampText.displayName = 'ClampText'

export default ClampText
