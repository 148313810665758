import type { TrafficTimeWindowsFormValues } from '../types'

import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectWorkingDayStartSec } from '@/features/domain/territory'
import { updateTimeWindows } from '@/features/domain/traffic'
import { useResetEditingState } from '@/atoms'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { timeWindowUtils } from '@/utils'

export const useOnSubmit = () => {
  const stopEditing = useResetEditingState()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const workingDayStartSec = useSelector(selectWorkingDayStartSec)

  const [submitting, setSubmitting] = useState<boolean>(false)

  const onUpdate = useCallback(
    async (formValues: TrafficTimeWindowsFormValues) => {
      try {
        setSubmitting(true)
        const timeWindows = computeTimeWindows(formValues, workingDayStartSec)
        const thunkResult = await dispatch(updateTimeWindows(timeWindows))

        if (updateTimeWindows.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, toast, workingDayStartSec],
  )

  return {
    submitting,
    onUpdate,
  }
}

function computeTimeWindows(formValues: TrafficTimeWindowsFormValues, workingDayStartSec: number) {
  const { timeWindow1, timeWindow2, timeWindow1Visible, timeWindow2Visible } = formValues
  const timeWindows: [uui.domain.rm.TimeWindow?, uui.domain.rm.TimeWindow?] = []

  if (!timeWindowUtils.isTimeWindowEmpty(timeWindow1) && !!timeWindow1Visible) {
    timeWindows.push(
      timeWindowUtils.formatTimeWindowForRPC(
        timeWindowUtils.normalizeTimeWindow(timeWindow1, workingDayStartSec),
        workingDayStartSec,
      ),
    )
  }

  if (!timeWindowUtils.isTimeWindowEmpty(timeWindow2) && !!timeWindow2Visible) {
    timeWindows.push(
      timeWindowUtils.formatTimeWindowForRPC(
        timeWindowUtils.normalizeTimeWindow(timeWindow2, workingDayStartSec),
        workingDayStartSec,
      ),
    )
  }

  return timeWindows
}
