import { Box, Stack } from '@mui/material'

import { Flag } from '@/icons'
import { Text } from '@/local/components'

import { useTexts } from './useTexts'

interface Props {
  type: uui.domain.rm.RouteViolationsType
}

export function RouteViolationRow(props: Props) {
  const { type } = props
  const texts = useTexts()

  return (
    <Stack direction="row">
      <Box color="warning.main">
        <Flag size={14} marginRight={4} />
      </Box>
      <Text size="$p3" color="$pureWhite">
        {texts.violation(type)}
      </Text>
    </Stack>
  )
}
