import { Skeleton } from '@mui/material'

import { FlexBox } from '@/local/components'

import { useOrderStepUnsubscribeData } from '../../../../orderNotificationAtom'

import { UnsubscribeDataSms } from './components/UnsubscribeData/UnsubscribeDataSms'
import { UnsubscribeDataEmail } from './components/UnsubscribeData/UnsubscribeDataEmail'
import { UnsubscribeDataFailed } from './components/UnsubscribeData/UnsubscribeDataFailed'

type Props = {
  extendedOrder: uui.domain.client.rm.ExtendedOrderStep
  status: 'loading' | 'failed' | 'ready'
}

export function OrderStepUnsubscribeInfo(props: Props) {
  const { extendedOrder, status } = props

  const unsubscribedData = useOrderStepUnsubscribeData(extendedOrder.id)

  if (status === 'loading') {
    return (
      <FlexBox column vAlignContent="top" padding="22px 32px 8px" w="100%">
        <Skeleton variant="rectangular" height={16} width="30%" />
        <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: '12px' }} />
      </FlexBox>
    )
  }

  if (status === 'failed') {
    return <UnsubscribeDataFailed />
  }

  if (
    !unsubscribedData ||
    (!unsubscribedData.email.unsubscribed && !unsubscribedData.sms.unsubscribed)
  )
    return null

  return (
    <FlexBox column vAlignContent="top" padding="22px 32px 16px" w="100%" style={{ gap: 16 }}>
      {unsubscribedData.email.unsubscribed && (
        <UnsubscribeDataEmail
          email={extendedOrder.orderStep.email ?? ''}
          date={unsubscribedData.email.unsubscribeDate}
        />
      )}
      {unsubscribedData.sms.unsubscribed && (
        <UnsubscribeDataSms
          phoneNumber={extendedOrder.orderStep.phone ?? ''}
          date={unsubscribedData.sms.unsubscribeDate}
        />
      )}
    </FlexBox>
  )
}
