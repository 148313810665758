export function NoEntries() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 261 261"
      style={{ maxWidth: '100%', height: '100%' }}
    >
      <g opacity=".1" filter="url(#a)">
        <path
          d="M.86377 136.004c0-68.4953 57.99463-124.0181 129.56823-124.0181 10.169.0012 20.304 1.1467 30.208 3.4142C172.115 5.65842 186.877.48224 202.032.886243c15.155.404007 29.61 6.359057 40.532 16.697757 5.496 5.2294 9.87 11.4886 12.865 18.4079 2.995 6.9194 4.549 14.3586 4.571 21.8784.014 11.0822-3.337 21.9162-9.625 31.1137 6.375 14.883 9.648 30.871 9.625 47.02C260 204.492 202.005 260 130.432 260 58.8584 260 .86377 204.492.86377 136.004Z"
          fill="#586A7A"
        />
      </g>
      <path
        d="M0 135.14C0 66.6444 57.9947 11.1216 129.568 11.1216c10.169.0012 20.304 1.1467 30.208 3.4142C171.251 4.79416 186.013-.382018 201.168.0219857 216.323.425989 230.778 6.38104 241.7 16.7197c5.496 5.2295 9.87 11.4886 12.865 18.408 2.995 6.9193 4.549 14.3585 4.571 21.8783.014 11.0822-3.337 21.9163-9.625 31.1138 6.375 14.8822 9.648 30.8712 9.625 47.0202 0 68.488-57.995 123.996-129.568 123.996C57.9947 259.136 0 203.628 0 135.14Z"
        fill="#F3F5F7"
      />
      <path
        d="M159.562 75.1494H47.7476c-2.5179 0-4.5581 1.6937-4.5581 3.7939v37.3287c0 2.091 1.9904 3.794 4.5581 3.794H159.562c2.517 0 4.558-1.703 4.558-3.794V78.9627c0-2.1002-2.041-3.8133-4.558-3.8133Z"
        fill="#699DD7"
      />
      <path
        d="M134.082 91.561H85.9846c-.5935 0-1.1628.2358-1.5825.6555-.4197.4197-.6555.989-.6555 1.5825 0 .5936.2358 1.1628.6555 1.5825.4197.4197.989.6555 1.5825.6555h48.0974c.593 0 1.163-.2358 1.582-.6555.42-.4197.656-.9889.656-1.5825 0-.5935-.236-1.1628-.656-1.5825-.419-.4197-.989-.6555-1.582-.6555Zm-20.058 8.0778H85.9509c-.5936 0-1.1628.2358-1.5825.6552-.4197.42-.6555.989-.6555 1.583 0 .593.2358 1.163.6555 1.582.4197.42.9889.656 1.5825.656h28.0731c.593 0 1.163-.236 1.582-.656.42-.419.656-.989.656-1.582 0-.594-.236-1.163-.656-1.583-.419-.4194-.989-.6552-1.582-.6552Z"
        fill="#E1EBF7"
      />
      <rect x="51.5669" y="84.5" width="25.1333" height="25.1333" rx="12.5667" fill="#fff" />
      <path
        d="M68.048 92.869c-.1257-.3707-.4775-.6346-.8922-.6346h-6.9117c-.4147 0-.7603.2639-.8922.6346l-1.307 3.7637v5.0263c0 .346.2828.629.6284.629h.6283c.3456 0 .6283-.283.6283-.629v-.628h7.54v.628c0 .346.2828.629.6284.629h.6283c.3456 0 .6283-.283.6283-.629v-5.0263L68.048 92.869Zm-7.8039 6.277c-.5215 0-.9425-.4209-.9425-.9425 0-.5215.421-.9425.9425-.9425.5215 0 .9425.421.9425.9425 0 .5216-.421.9425-.9425.9425Zm6.9117 0c-.5216 0-.9425-.4209-.9425-.9425 0-.5215.4209-.9425.9425-.9425.5215 0 .9425.421.9425.9425 0 .5216-.421.9425-.9425.9425Zm-7.8542-3.1416.9425-2.8275h6.9117l.9425 2.8275h-8.7967Z"
        fill="#091B2A"
        fillOpacity=".56"
      />
      <path
        d="M12.3326 20.3005H124.146c2.354 0 4.125 1.5879 4.125 3.3799v37.3098c0 1.7805-1.768 3.3606-4.125 3.3606H12.3326c-2.40254 0-4.12485-1.5773-4.12485-3.3606V23.6611c0-1.7895 1.768-3.3606 4.12485-3.3606Z"
        fill="#fff"
        stroke="#D3D7DC"
        strokeWidth=".866667"
      />
      <path
        d="M98.6667 36.2788H50.5696c-.5936 0-1.1628.2358-1.5825.6555-.4197.4197-.6555.9889-.6555 1.5825 0 .5935.2358 1.1628.6555 1.5825.4197.4197.9889.6555 1.5825.6555h48.0971c.5935 0 1.1628-.2358 1.5823-.6555.42-.4197.656-.989.656-1.5825 0-.5936-.236-1.1628-.656-1.5825-.4195-.4197-.9888-.6555-1.5823-.6555Zm-20.0579 8.0778h-28.073c-.5935 0-1.1628.2358-1.5825.6555-.4197.4197-.6554.9889-.6554 1.5825 0 .5935.2357 1.1628.6554 1.5825.4197.4197.989.6554 1.5825.6554h28.073c.5936 0 1.1628-.2357 1.5825-.6554.4197-.4197.6555-.989.6555-1.5825 0-.5936-.2358-1.1628-.6555-1.5825-.4197-.4197-.9889-.6555-1.5825-.6555Z"
        fill="#BAC1C8"
      />
      <rect x="16.0332" y="29.1958" width="25.1333" height="25.1333" rx="12.5667" fill="#A1C1E6" />
      <path
        d="M28.25 36.3657c-.0492-.0375-.1174-.0375-.1666 0l-5.5175 4.2039c-.0342.026-.0542.0664-.0542.1093v5.3331c0 .1897.1539.3436.3436.3436h2.9885c.1898 0 .3437-.1539.3437-.3436v-2.0746c0-1.0932.8861-1.9793 1.9792-1.9793 1.0931 0 1.9793.8861 1.9793 1.9793v2.0746c0 .1897.1538.3436.3436.3436h2.9885c.1898 0 .3436-.1539.3436-.3436v-5.3331c0-.0429-.02-.0833-.0541-.1093L28.25 36.3657Z"
        fill="#fff"
      />
      <path
        d="M83.1626 130.865H194.977c2.353 0 4.124 1.588 4.124 3.38v37.31c0 1.78-1.768 3.36-4.124 3.36H83.1626c-2.4025 0-4.1248-1.577-4.1248-3.36v-37.329c0-1.79 1.768-3.361 4.1248-3.361Z"
        fill="#fff"
        stroke="#D3D7DC"
        strokeWidth=".866667"
      />
      <path
        d="M169.497 146.843H121.4c-.594 0-1.163.236-1.583.656-.42.419-.655.989-.655 1.582 0 .594.235 1.163.655 1.583.42.419.989.655 1.583.655h48.097c.593 0 1.163-.236 1.582-.655.42-.42.656-.989.656-1.583 0-.593-.236-1.163-.656-1.582-.419-.42-.989-.656-1.582-.656Zm-20.058 8.078h-28.073c-.594 0-1.163.236-1.583.656-.419.419-.655.988-.655 1.582 0 .594.236 1.163.655 1.583.42.419.989.655 1.583.655h28.073c.593 0 1.163-.236 1.582-.655.42-.42.656-.989.656-1.583s-.236-1.163-.656-1.582c-.419-.42-.989-.656-1.582-.656Z"
        fill="#BAC1C8"
      />
      <rect x="87.0996" y="140.671" width="25.1333" height="25.1333" rx="12.5667" fill="#A1C1E6" />
      <path
        d="M99.2328 146.521c-2.4316 0-4.3983 1.966-4.3983 4.398 0 3.299 4.3983 8.168 4.3983 8.168s4.3982-4.869 4.3982-8.168c0-2.432-1.967-4.398-4.3982-4.398Zm0 5.969c-.8671 0-1.5708-.704-1.5708-1.571s.7037-1.571 1.5708-1.571c.8672 0 1.5712.704 1.5712 1.571s-.704 1.571-1.5712 1.571Z"
        fill="#fff"
      />
      <path
        d="M118.578 186.148h111.814c2.354 0 4.125 1.588 4.125 3.38v37.31c0 1.78-1.769 3.36-4.125 3.36H118.578c-2.402 0-4.125-1.577-4.125-3.36v-37.329c0-1.79 1.768-3.361 4.125-3.361Z"
        fill="#fff"
        stroke="#D3D7DC"
        strokeWidth=".866667"
      />
      <path
        d="M204.912 202.126h-48.097c-.593 0-1.163.236-1.582.656-.42.42-.656.989-.656 1.582 0 .594.236 1.163.656 1.583.419.42.989.655 1.582.655h48.097c.594 0 1.163-.235 1.583-.655.419-.42.655-.989.655-1.583 0-.593-.236-1.162-.655-1.582-.42-.42-.989-.656-1.583-.656Zm-20.058 8.078h-28.073c-.593 0-1.162.236-1.582.656-.42.419-.656.989-.656 1.582 0 .594.236 1.163.656 1.583.42.419.989.655 1.582.655h28.073c.594 0 1.163-.236 1.583-.655.42-.42.655-.989.655-1.583 0-.593-.235-1.163-.655-1.582-.42-.42-.989-.656-1.583-.656Z"
        fill="#BAC1C8"
      />
      <rect x="122.633" y="194.925" width="25.1333" height="25.1333" rx="12.5667" fill="#A1C1E6" />
      <g clipPath="url(#b)">
        <path
          d="m138.65 205.801-1.069-2.941c-.182-.496-.653-.829-1.181-.829h-1.633v1.256h1.633l.918 2.514h-3.023l-.226-.629h.698v-1.256h-3.142v1.256h1.099l1.144 3.142h-.421c-.276-1.401-1.451-2.438-2.922-2.507-1.759-.088-3.298 1.376-3.298 3.135 0 1.76 1.382 3.142 3.141 3.142 1.546 0 2.796-1.062 3.079-2.513h2.639c.277 1.401 1.452 2.438 2.922 2.507 1.759.081 3.299-1.376 3.299-3.142 0-1.759-1.383-3.142-3.142-3.142h-.515v.007Zm-6.51 3.77c-.251.735-.936 1.256-1.772 1.256-1.055 0-1.885-.829-1.885-1.885 0-1.055.83-1.885 1.885-1.885.836 0 1.521.522 1.772 1.257h-1.772v1.257h1.772Zm3.946-1.257h-.88l-.458-1.257h1.904c-.277.365-.478.786-.566 1.257Zm3.079 2.513c-1.056 0-1.885-.829-1.885-1.885 0-.584.258-1.087.66-1.432l.603 1.659 1.181-.428-.609-1.677c.018 0 .037-.007.056-.007 1.056 0 1.885.83 1.885 1.885 0 1.056-.836 1.885-1.891 1.885Z"
          fill="#fff"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.7 49.5522c2.263-2.6555 3.794-5.906 3.54-9.5556-.725-10.5061-15.366-8.1916-18.21-2.9022-2.844 5.2893-2.503 18.7557 1.176 19.7134 1.451.3773 4.585-.5514 7.762-2.4669l-1.995 14.1195h11.782L194.7 49.5522Z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.744 30.9954c1.553-.2539 5.731-.4281 6.529-.4281.487 0 1.023.1161 1.314-.341.29-.4571.152-.994.224-1.4511.073-.4571.204-.994.32-1.4511.335-1.3984.868-2.742 1.581-3.9906 1.31-2.3372 3.348-4.182 5.804-5.2531 2.796-1.1063 5.818-1.5196 8.808-1.2044 3.163.2539 6.021 1.2987 9.003 2.2782 2.823.9718 5.836 1.2627 8.793.8489 2.996-.4643 5.26-2.0896 7.792-3.6278 2.545-1.6738 5.553-2.5039 8.597-2.3725 2.808.3119 5.49 1.3358 7.792 2.9748 2.24 1.3486 4.012 3.3517 5.078 5.7391 1.103 2.6846 1.103 5.6304-.725 7.9812-2.641 3.4392-7.538 4.3534-11.608 4.2373-2.353-.0514-4.682-.4776-6.9-1.2625-2.967-1.0448-5.535-2.8152-8.307-4.2445-1.294-.681-2.655-1.2257-4.062-1.6253-1.371-.3918-2.721-.5659-3.962.2612-1.231.9203-2.571 1.6855-3.99 2.2783-1.438.5376-2.962.8128-4.498.8126-2.982 0-5.927-.9577-8.829-1.5817-2.358-.5079-4.89-1.0085-7.298-.5224-1.009.2032-2.474.624-2.844 1.6688 6.116 1.1101 8.517 8.7067 6.797 14.221-.935 2.9965-6.529 6.53-8.705 4.9265-1.379-1.0012-.545-2.3073 0-3.1707 1.037-1.6615 1.392-3.7874-.53-4.941-1.654-.9941-3.388 1.3713-3.424 1.364-.457 0-4.353-2.2565-7.088-5.4925-2.67.1524-3.077-1.6035-2.438-3.0473.638-1.4439 4.629-3.2288 6.776-3.5843Z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.226 189.061c6.29-13.924 10.839-26.04 12.667-32.44 3.127-10.949 5.507-20.591 6.109-24.183 1.451-8.583-11.028-9.084-12.674-4.818-2.496 6.451-6.849 27.666-12.073 59.7l5.971 1.741Zm72.956-43.693c-5.768-2.279-25.98-9.433-35.724-11.435-2.8-.581-5.513-1.125-8.067-1.611-7.589-1.451-11.514 12.24-4.208 13.292 18.195 2.62 43.987 5.326 46.199 5.631 3.091.421 5.427-4.441 1.8-5.87v-.007Z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m156.735 128.546 31.196-15.89v-3.461h-3.714c-30.186 7.7-45.61 12.632-46.272 14.794v.073l-.044.058c-5.985 8.235-9.569 48.416-10.744 51.427l11.47 1.713c4.353-16.267 18.42-27.572 18.043-47.089.094-.537.116-1.083.065-1.625Z"
        fill="#0E4686"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.361 137.997c2.858-.573 5.485-1.081 7.741-1.451 14.51-2.554 18.776-10.332 16.265-27.317h-26.785c-4.353 1.262-27.111 10.796-38.908 15.868-7.806 3.359-5.347 15.462-.928 18.603.005.082.03.162.072.233.044.065.104.117.174.152 23.789 9.86 42.608 4.687 48.891 6.341l2.902-9.171-9.424-3.258Z"
        fill="#1565C0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.243 143.9c1.137.063 2.256.308 3.315.726 1.153.61 2.254 1.311 3.294 2.097l-12.13 24.894c-2.452-.402-3.42-1.338-2.902-2.808.517-1.471.928-2.704 1.233-3.7l.479-14.084c0-.063.015-.125.044-.181.023-.061.06-.116.108-.16.049-.044.105-.079.167-.101.063-.011.126-.011.189 0l2.176.138c.991-.724 1.821-1.646 2.438-2.707.378-.99.558-2.045.53-3.105 0-.128.027-.254.079-.37.053-.121.13-.229.225-.319.093-.087.202-.156.32-.204.121-.043.248-.065.377-.065l.058-.051Zm-68.149 42.552c.448 1.047.725 2.159.819 3.294-.042 1.301-.19 2.597-.442 3.875h-27.685c-.721-2.38-.305-3.665 1.248-3.853 1.552-.189 2.837-.356 3.86-.515l12.87-5.732c.058-.029.123-.044.188-.044.063-.01.127-.01.189 0 .06.023.114.057.16.102.047.044.084.099.108.159l.828 2.003c1.083.574 2.274.918 3.496 1.008 1.057-.092 2.085-.393 3.026-.885.116-.054.242-.084.37-.087.132-.009.265.01.39.055.125.044.239.115.335.206.097.087.174.193.225.312.013.033.018.067.015.102Z"
        fill="#002D5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m157.535 95.7478-17.789-7.8142c-2.684-2.7557-5.73-5.1332-9.054-7.067-.871-.283-2.351-.3628-.769 1.9445 1.581 2.3073 3.141 4.8105 2.51 5.6231-.631.8126-2.467-.0798-3.192 1.2334-.484.8756 2.602 1.9252 9.257 3.149l13.342 10.1864 5.695-7.2552Zm71.911 2.1767-10.063 1.9953c10.118 18.8642 15.542 28.8582 16.273 29.9802 1.654 2.547.116 7.212-.457 9.664 3.576.835 2.176-4.542 6.682-3.417 4.128 1.023 7.624 3.628 11.47.109.471-.443.819-1.799-.791-2.503-4.019-1.742-9.954-4.999-10.883-6.262-1.339-1.736-5.421-11.592-12.246-29.5665h.015Z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m192.036 60.2632-4.244-.7256c-9.25 8.9679-13.022 30.5171-19.182 33.3758-5.804 2.6991-14.089.0871-23.898-3.4319l-3.01 6.7187c8.923 9.6498 27.924 17.7978 35.621 13.1398 12.312-7.444 15.156-34.0215 14.713-49.0768Z"
        fill="#A62E18"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.536 113.616h-37.558c-1.72 0-1.393-2.474-1.147-3.729 2.902-14.4315 14.046-29.9948 14.046-50.5354l10.839-1.6978c8.952 14.4096 12.058 32.1569 13.82 55.9622Z"
        fill="#F9FAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M214.276 99.0044c7.661 13.8146 14.099 23.7596 19.312 29.8346l7.872-1.545c-5.074-31.441-19.76-54.6589-44.059-69.6539h-4.317c-9.707 33.1437-12.367 55.2399-7.98 66.2869h33.808c-.683-8.445-2.237-16.797-4.636-24.9226Z"
        fill="#D63B1F"
      />
      <defs>
        <clipPath id="b">
          <path fill="#fff" d="M127.227 199.518h15.08v15.08h-15.08z" />
        </clipPath>
        <filter
          id="a"
          x="-.00289714"
          y="-.00240886"
          width="260.87"
          height="260.87"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation=".433333" result="effect1_foregroundBlur_6695_15539" />
        </filter>
      </defs>
    </svg>
  )
}
