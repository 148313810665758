import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function OrdersVisibleMinimized(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 20 21">
        <path d="M4.422 1.5a.5.5 0 1 1 0-1h2a.5.5 0 0 1 0 1h-2zm5 0a.5.5 0 1 1 0-1h2a.5.5 0 0 1 0 1h-2zm5 0a.5.5 0 1 1 0-1h1.059c.368 0 .729.053 1.075.157a.5.5 0 1 1-.287.958 2.735 2.735 0 0 0-.788-.115h-1.059zm3.645 1.84a.5.5 0 0 1 .945-.326c.127.367.196.754.204 1.159v1.05a.5.5 0 1 1-1 .001V4.182a2.728 2.728 0 0 0-.15-.841zm.15 4.884a.5.5 0 0 1 1 0v2a.5.5 0 1 1-1 0v-2zm0 5a.5.5 0 0 1 1 0v1.54c0 .179-.013.355-.038.53a.5.5 0 1 1-.99-.141c.018-.128.027-.257.027-.388v-1.54zm-1.452 3.957a.5.5 0 1 1 .47.883c-.511.271-1.08.422-1.68.435l-.58.001a.5.5 0 0 1-.002-1h.57c.433-.01.848-.12 1.222-.32zm-4.746 1.427a.5.5 0 1 1 .714.7l-1.402 1.428a.5.5 0 1 1-.713-.701l1.401-1.427zm-2.792 1.37a.5.5 0 0 1-.713.702L7.11 19.255a.5.5 0 1 1 .713-.701l1.404 1.424zM4.845 17.5a.5.5 0 1 1 0 1h-.423a3.72 3.72 0 0 1-1.766-.443.5.5 0 0 1 .473-.881 2.72 2.72 0 0 0 1.293.324h.423zm-3.137-2.394a.5.5 0 1 1-.992.123 3.78 3.78 0 0 1-.029-.436V13.14a.5.5 0 0 1 1 0v1.649c0 .107.008.212.02.317zm-.021-4.966a.5.5 0 1 1-1 0v-2a.5.5 0 1 1 1 0v2zm0-5a.5.5 0 1 1-1 0v-.905c0-.429.073-.85.213-1.246a.5.5 0 1 1 .943.333 2.73 2.73 0 0 0-.156.913v.905zm1.971-3.532a.5.5 0 0 1-.279-.96A3.74 3.74 0 0 1 4.362.5h.056a.5.5 0 1 1 .008 1h-.051c-.246.005-.486.041-.717.108z" />
        <path d="M6.965 4.442a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-6z"></path>
      </svg>
    </IconContainer>
  )
}
