import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    labelStart: translate({ id: 'order.form.bulk.timeWindows.from' }),
    labelEnd: translate({ id: 'order.form.bulk.timeWindows.to' }),

    deleteTimeWindowTooltip: translate({
      id: 'order.form.bulk.timeWindows.deleteTimeWindowTooltip',
    }),
  }))

  return api
}
