import { useState } from 'react'

import { Actions } from './Actions'
import { ConfirmPanel } from './ConfirmPanel'

interface Props {
  places: uui.domain.client.gps.wwgps.Place[]
}

export function ActionsManager(props: Props) {
  const { places } = props
  const [view, setView] = useState<'default' | 'delete'>(() => 'default')

  switch (view) {
    case 'delete':
      return <ConfirmPanel places={places} setView={setView} />
    case 'default':
      return <Actions places={places} setView={setView} />
  }
}
