const stepTo = (value: number, step: number, forward: boolean = true): number => {
  const operand = forward ? 1 : -1
  return value + operand * step
}

const snapForward = (value: number, step: number, mod: number) =>
  value > 0 ? value - mod + step : value + mod

const snapBackward = (value: number, step: number, mod: number) =>
  value > 0 ? value - mod : value + mod - step

export const snapToStep = (
  value: number,
  stepValue: number,
  forward: boolean = true,
  snap: boolean = true,
) => {
  const step = Math.abs(stepValue)
  const mod: number = Math.abs(value % step)
  const avoidLogic: boolean = !snap || mod === 0 || step < 2
  if (avoidLogic) return stepTo(value, step, forward)
  return forward ? snapForward(value, step, mod) : snapBackward(value, step, mod)
}
