import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

type RoadSegmentFeatureMetadata = {
  mode: 'on' | 'off'
  type: 'roadSegment'
}

export function setRoadSegmentFeatureMetadata<Field extends keyof RoadSegmentFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
  value: RoadSegmentFeatureMetadata[Field],
) {
  target.set(field, value)
}

export function getRoadSegmentFeatureMetadata<Field extends keyof RoadSegmentFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
): RoadSegmentFeatureMetadata[Field] | undefined {
  return target.get(field)
}

export function removeRoadSegmentFeatureMetadata<Field extends keyof RoadSegmentFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
) {
  target.unset(field)
}
