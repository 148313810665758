import { useMemo } from 'react'

import { computeCreateGeofencePermission } from './utils/computeCreateGeofencePermission'
import { computeDispatchToDriverPermission } from './utils/computeDispatchToDriverPermission'
import { computeSavePlacePermission } from './utils/computeSavePlacePermission'

import { usePermissionsData } from './hooks/usePermissionsData'

export function usePermissions() {
  const permissionsData = usePermissionsData()

  return useMemo(
    () => ({
      createGeofence: computeCreateGeofencePermission(permissionsData),
      dispatchToDriver: computeDispatchToDriverPermission(permissionsData),
      savePlace: computeSavePlacePermission(permissionsData),
    }),
    [permissionsData],
  )
}
