import type { Dimensions } from '@/local/hooks'

import { useRef, ReactElement } from 'react'

import { Box, styled } from '@mui/material'

import { useComponentSize } from '@/local/hooks'

type Props = {
  render: (size: Dimensions, resizing: boolean) => ReactElement
}

export function ComponentSize(props: Props) {
  const ref = useRef<HTMLDivElement | null>(null)
  const [size, resizing] = useComponentSize(ref)

  return <Container ref={ref}>{props.render(size, resizing)}</Container>
}

const Container = styled(Box, { name: 'Container' })({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})
