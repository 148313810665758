import type { CSSProperties } from 'react'

import { Depot } from '@/icons'
import { theme } from '@/local/components'
import { isDark } from '@/styles'

import AvatarContainer from './elements/AvatarContainer'

interface Props {
  depots: uui.domain.client.rm.Depot[]
  size: number
  style?: CSSProperties
  borderLess?: boolean
  className?: string
}

const useStyles = (depots: Props['depots'], style?: Props['style']) => {
  const background = depots.length === 1 ? `#${depots[0].color}` : theme.colors.$nightRider

  return {
    background,
    color: isDark(background) ? theme.colors.$pureWhite : theme.colors.$nightRider,
    marginRight: 16,
    ...style,
  }
}

export function DepotAvatar(props: Props) {
  const { depots, size, style = {}, className = '' } = props

  const multi = depots.length > 1
  const styles = useStyles(depots, style)

  return (
    <AvatarContainer style={styles} className={className} size={size}>
      {multi ? `X${depots.length}` : <Depot size={18} />}
    </AvatarContainer>
  )
}
