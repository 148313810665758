import type { LocationEditStatus } from '../../../../LocationEditor/typings'

import { locationHasWarning, locationIsOutsideTerritory } from '@/server-data'

export function useLocationAccuracy(location?: uui.domain.client.Location, error?: unknown) {
  const lowAccuracy = location ? locationHasWarning(location) : false
  const outOfTerritory = location ? locationIsOutsideTerritory(location) : false

  let status: LocationEditStatus = 'ok'

  if (outOfTerritory) {
    status = 'outOfTerritory'
  } else if (!!error && !outOfTerritory) {
    status = 'invalidLatLng'
  } else if (lowAccuracy) {
    status = 'lowAccuracy'
  }

  return { status, withoutErrors: !error && !outOfTerritory && !lowAccuracy } as const
}
