import { Box } from '@mui/material'

import { SpreadSheet } from './SpreadSheet'
import { Csv } from './Csv'
import { Xls } from './Xls'

interface Props {
  logo: 'CSV' | 'XLSX'
  adornmentWidth?: number
  spreadSheetWidth?: number
  logoWidth?: number
}

export function ExportAdornment(props: Props) {
  const { logo, adornmentWidth = 430, spreadSheetWidth = 300, logoWidth = 40 } = props

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={adornmentWidth}
      flexShrink={0}
      sx={theme => ({
        background: theme.vars.palette.background.default3,
      })}
    >
      <Box width={spreadSheetWidth} position="relative">
        <SpreadSheet />
        <Box
          position="absolute"
          top={0}
          left="50%"
          width={logoWidth}
          sx={{ transform: 'translate(-50%, -50%)' }}
        >
          {logo === 'CSV' && <Csv />}
          {logo === 'XLSX' && <Xls />}
        </Box>
      </Box>
    </Box>
  )
}
