import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useIsSimulation } from '@/hooks'
import { Trash, Edit, Duplicate } from '@/icons'
import { useEditingStateWithCtx } from '@/atoms'
import { selectTerritoryLicensingLimits } from '@/features/domain/territory'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'

interface Props {
  switchToDeleteView: () => void
  switchToCloneView: () => void
  unifiedId: string
  canAddNewVehicle: boolean
}

export function SingleViewRmActions(props: Props) {
  const { switchToDeleteView, switchToCloneView, unifiedId, canAddNewVehicle } = props

  const territoryLicensingLimits = useSelector(selectTerritoryLicensingLimits)
  const { setEditing } = useEditingStateWithCtx('vehicle')
  const isSimulation = useIsSimulation()

  const texts = useTexts()

  const onStartEdit = useCallback(() => setEditing([unifiedId]), [setEditing, unifiedId])

  const vehiclesLimit = isSimulation
    ? territoryLicensingLimits.vehiclesSim
    : territoryLicensingLimits.vehiclesOps

  return (
    <>
      <SecondaryColumnHeaderActionsRow>
        <ReadOnlyTooltip
          canEdit={canAddNewVehicle}
          title={texts.disabledCloneTooltipTitle(isSimulation)}
          subtitle={texts.disabledCloneTooltipSubtitle(isSimulation, vehiclesLimit)}
          render={preventEditing => (
            <SecondaryColumnHeaderActionButton
              label={texts.clone}
              icon={<Duplicate />}
              onClick={switchToCloneView}
              disabled={preventEditing || !canAddNewVehicle}
              className="pendo-setup-vehicle__duplicate"
              testid="setup-vehicle__duplicate"
            />
          )}
        />

        <ReadOnlyTooltip
          render={preventEditing => (
            <SecondaryColumnHeaderActionButton
              label={texts.delete}
              icon={<Trash />}
              onClick={switchToDeleteView}
              disabled={preventEditing}
              className="pendo-setup-vehicle__delete"
              testid="setup-vehicle__delete"
            />
          )}
        />

        <ReadOnlyTooltip
          render={preventEditing => (
            <SecondaryColumnHeaderActionButton
              label={texts.edit}
              icon={<Edit />}
              onClick={onStartEdit}
              disabled={preventEditing}
              className="pendo-setup-vehicle__edit"
              testid="setup-vehicle__edit"
            />
          )}
        />
      </SecondaryColumnHeaderActionsRow>
    </>
  )
}
