import { useCallback } from 'react'

import { Stack, FlexBox } from '@/local/components'

import { Kind } from './components/Kind'
import { Status } from './components/Status'
import { SentBy } from './components/SentBy'
import { SMSBody } from './components/SMSBody'
import { Button } from './components/Button'

interface Props {
  item: uui.domain.client.rm.OrderStepSmsNotification
  showModal: (data: uui.domain.client.rm.OrderStepSmsNotification) => void
}

export function SMSNotificationItem(props: Props) {
  const { item, showModal } = props

  const handleOnShowModal = useCallback(() => {
    showModal(item)
  }, [item, showModal])

  return (
    <Stack space="m">
      <Stack space="s">
        <FlexBox vAlignContent="center" w="100%" style={{ justifyContent: 'space-between' }}>
          <Kind kind={item.kind} />
          <Status
            status={item.status}
            errorCode={item.status === 'FAILED' ? item.errorCode : null}
          />
        </FlexBox>
        <SentBy sentTs={item.sentTs} />
        <SMSBody body={item.bodyPlainText} />
      </Stack>
      <Button onClick={handleOnShowModal} />
    </Stack>
  )
}
