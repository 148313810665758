import type { FormErrors, FormFields } from '../../../../formFields'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'

export function useValues() {
  const {
    field: { value: includeMessage },
  } = useFormField<'includeMessageUnsubscriptionInstructions', FormFields, FormErrors>(
    'includeMessageUnsubscriptionInstructions',
    {
      fieldsOfInterest: ['value'],
    },
  )
  const {
    field: { value: notificationWay },
  } = useFormField<'notificationWay', FormFields, FormErrors>('notificationWay', {
    fieldsOfInterest: ['value'],
  })

  return { showWarningCard: !includeMessage, notificationWay }
}
