import { useMemo } from 'react'
import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Unassign } from '@/icons'

import { type ActionPermissions } from '../hooks/usePermissions/types'
import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  permission: ActionPermissions['unassign']
  multiple: boolean
}

export function UnassignButton(props: Props) {
  const { multiple, onClick, permission } = props

  const texts = useTexts()

  const disabled = permission.startsWith('disabled')

  const { title, subtitle, forceState } = useMemo(() => {
    switch (permission) {
      case 'disabledAllRoutesLocked':
        return {
          title: texts.disabledAllRoutesLocked,
          forceState: undefined,
        }

      default:
        return {
          title: '',
          subtitle: '',
          forceState: undefined,
        }
    }
  }, [texts, permission])

  return (
    <ReadOnlyTooltip
      title={title}
      canEdit={!disabled}
      subtitle={subtitle}
      forceState={forceState}
      render={preventEditing => (
        <NavigoIconButton
          onClick={onClick}
          disabled={preventEditing}
          Icon={
            <Unassign size={17} color={disabled || preventEditing ? '$silver' : '$nightRider'} />
          }
          testid="navigo-routes-overview-actions-unassign"
          text={multiple ? texts.unassignAll : texts.unassign}
        />
      )}
    />
  )
}
