import { FileCopy } from '@mui/icons-material'

import { makeStyles } from '@mui/styles'

import { useSelector } from 'react-redux'
import { useCopyOrdersModal } from '@/components/modals/CopyOrdersModal'
import { selectOrderStepIdsInRange } from '@/features/domain/order'
import { Container } from '@/components/primitives/ControlStrip'
import { IconButton } from '@/components/primitives/buttons'
import { Tooltip } from '@/components/primitives/Tooltip'
import { theme } from '@/styles'
import { useTexts } from '../useTexts'

const useStyles = makeStyles({
  icon: {
    color: theme.colors.$pureWhite,
    fontSize: 15,
  },
})

interface Props {
  userType: uui.domain.rm.RouteManagerUserType
}

export function ArchivedControls(props: Props) {
  const { show: showCopyOrdersModal, Modal: CopyOrdersModal } = useCopyOrdersModal()
  const orderStepIdsInRange = useSelector(selectOrderStepIdsInRange)
  const classes = useStyles()
  const texts = useTexts()

  const disableCopy = Object.keys(orderStepIdsInRange).length === 0
  const { userType } = props

  const readOnlyUser = ['viewer', 'courier', 'guest'].includes(userType)

  if (readOnlyUser) return null

  return (
    <Container>
      <Tooltip
        title={disableCopy ? texts.emptyArchivedDayTitle : texts.copy}
        subtitle={disableCopy ? texts.emptyArchivedDaySubtitle : undefined}
        placement="top"
      >
        <IconButton onClick={showCopyOrdersModal} disabled={disableCopy}>
          <FileCopy color={disableCopy ? 'disabled' : undefined} className={classes.icon} />
        </IconButton>
      </Tooltip>

      <CopyOrdersModal />
    </Container>
  )
}
