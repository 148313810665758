import type { Style } from 'ol/style'

import { genericStyles } from '../../genericStyles'
import { getVehicleSelectionFeatureStyle } from './getVehicleSelectionFeatureStyle'
import { getVehicleOutlineFeatureStyle } from './getVehicleOutlineFeatureStyle'
import { getVehicleCircleFeatureStyle } from './getVehicleCircleFeatureStyle'
import { getVehicleArrowFeatureStyle } from './getVehicleArrowFeatureStyle'
import { getVehicleFeatureLabelStyle } from './getVehicleLabelFeatureStyle'
import { getVehicleIdFeatureStyle } from './getVehicleIdFeatureStyle'

const vehicleStylesCache: Map<string, Style[]> = new Map()

export const getVehicleFeatureStyles = (
  marker: uui.domain.ui.map.markers.Vehicle,
  mode: uui.domain.ui.map.markers.MapStyles['vehicle']['mode'],
  selected: boolean = false,
) => {
  if (mode === 'off') return genericStyles.hidden

  const { arrowGrid, circleGrid, label, idx, outlineGrid, selectionGrid } = marker

  let cacheId = `circle_${circleGrid[0]}_${circleGrid[1]}`

  cacheId += `_idx_${idx}`
  cacheId += `_selection_${selectionGrid[0]}_${selectionGrid[1]}`
  cacheId += `_${selected ? 'selected' : 'not-selected'}`
  cacheId += `_mode_${mode}`
  cacheId += `_label_${label}`

  if (arrowGrid) {
    cacheId += `_arrow_${arrowGrid[0]}_${arrowGrid[1]}`
  }

  if (outlineGrid) {
    cacheId += `_outline_${outlineGrid[0]}_${outlineGrid[1]}`
  }

  if (vehicleStylesCache.has(cacheId)) {
    const cachedStyle = vehicleStylesCache.get(cacheId)

    if (cachedStyle) return cachedStyle
  }

  const styles = [
    getVehicleSelectionFeatureStyle(marker, mode, selected),
    getVehicleOutlineFeatureStyle(marker, mode),
    getVehicleArrowFeatureStyle(marker, mode),
    getVehicleCircleFeatureStyle(marker, mode),
    getVehicleIdFeatureStyle(marker, mode),
    getVehicleFeatureLabelStyle(marker, mode),
  ]

  vehicleStylesCache.set(cacheId, styles)

  return styles
}
