import { Form } from '@workwave-tidal/tidal/form-ui/layout'

import { useControllerActions } from '../../hooks/useControllerActions'
import { WarningCard } from '../WarningCard'

import { Message } from './fields/Message'
import { Topic } from './fields/Topic'

export function TicketForm() {
  const { openSupportTicket } = useControllerActions()

  return (
    <Form
      disableScroll
      onEnter={openSupportTicket}
      fieldsSpacing={3}
      fieldsPadding="0"
      testId="ticketForm"
      sx={{
        mt: 4,
      }}
    >
      <Topic />
      <Message />
      <WarningCard />
    </Form>
  )
}
