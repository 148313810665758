import styled from 'styled-components'

type Props = {
  empty?: boolean
}

export const ReadonlyFieldLink = styled.a<Props>`
  color: ${p => (p.empty ? p.theme.colors.$paleSky : p.theme.colors.$nightRider)};
  font-size: ${p => p.theme.fonts.$p2};
  font-weight: ${p => p.theme.weights.$regular};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    text-decoration: underline;
  }
`

ReadonlyFieldLink.displayName = 'ReadonlyFieldLink'
