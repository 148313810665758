import { useFormViewType } from '../../../hooks/useFormViewType'

import { SingleEditRm360 } from './SingleEditRm360'
import { SingleEditRm } from './SingleEditRm'

interface Props {
  trackingProvider: uui.domain.server.gps.TrackingProvider
  transactionOpen: boolean
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  creating?: boolean
}

export function GeneralSettings(props: Props) {
  const { unifiedVehicle, creating, transactionOpen, trackingProvider } = props

  const formViewType = useFormViewType(unifiedVehicle)

  switch (formViewType) {
    case 'rmOnly':
      return (
        <SingleEditRm
          transactionOpen={transactionOpen}
          unifiedVehicle={unifiedVehicle}
          creating={creating}
        />
      )

    case 'rm360':
      return (
        <SingleEditRm360
          trackingProvider={trackingProvider}
          transactionOpen={transactionOpen}
          unifiedVehicle={unifiedVehicle}
          creating={creating}
        />
      )

    default:
      return null
  }
}
