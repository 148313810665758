import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useMainSelection } from '@/atoms'
import { getDeviceId } from '@/server-data'
import { selectDrivers } from '@/features/domain/driver'
import { selectVehicles } from '@/features/domain/vehicle'
import { selectCalendarRange } from '@/features/domain/ui'

export function useComputeSelectedDeviceIds() {
  const [unifiedVehiclesSelection] = useMainSelection('unifiedVehicles')
  const [devicesSelection] = useMainSelection('devices')
  const drivers = useSelector(selectDrivers)
  const calendarRange = useSelector(selectCalendarRange)
  const vehicles = useSelector(selectVehicles)

  return useMemo<string[]>(() => {
    if (calendarRange.minDate !== calendarRange.maxDate) return []
    const deviceIds = [...devicesSelection]

    unifiedVehiclesSelection.reduce<string[]>((acc, uvId) => {
      const vehicle = vehicles[uvId]
      const deviceId = getDeviceId(drivers, vehicle, calendarRange.minDate)

      if (!deviceId) return acc

      acc.push(deviceId)
      return acc
    }, deviceIds)

    return deviceIds
  }, [calendarRange, unifiedVehiclesSelection, drivers, devicesSelection, vehicles])
}
