import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

interface Payload {
  profileId: string
  areaIds: string[]
}

/**
 * Delete traffic areas
 *
 * @private
 */
export const deleteTrafficAreas = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/traffic/deleteTrafficAreas', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const response = await rpc('rpc/traffic/deleteTrafficAreas', {
      category: 'rpc',
      type: 'rpc/traffic/deleteTrafficAreas',
      areaIds: payload.areaIds,
      profileId: payload.profileId,
    })
    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {deleteTrafficAreas}`,
      { tags: ['rpc', 'traffic'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Delete traffic areas failed`,
      error,
    })
  }
})
