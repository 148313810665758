import type { PublicProps as Props } from './typings'

import { useCallback } from 'react'
import { ErrorBoundary, FallbackProps } from '@/components/ErrorBoundary'

import { Calendar } from './Calendar'

function ErrorFallback(props: FallbackProps) {
  return (
    <div style={{ color: 'red' }}>
      OPS!
      <button onClick={props.resetErrorBoundary}>Re try</button>
    </div>
  )
}

export function ErrorHandler(props: Props) {
  const handleOnError = useCallback((_error: Error, info: { componentStack: string }) => {
    console.error(info)
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleOnError}>
      <div data-testid="calendar">
        <Calendar {...props} />
      </div>
    </ErrorBoundary>
  )
}
