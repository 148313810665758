import { Box } from '@mui/material'
import { useSettingsEditing } from '../../hooks/useSettingsEditing'

import { GpsPersonalizationForm } from './GpsPersonalizationForm'
import { GpsPersonalizationView } from './GpsPersonalizationView'

export function GpsPersonalization() {
  const { editing, onEdit, onDiscard } = useSettingsEditing('personalization', 'reset')

  return (
    <Box display="flex" width="100%" height="100%">
      {!editing && <GpsPersonalizationView onEdit={onEdit} />}
      {editing && <GpsPersonalizationForm onDiscard={onDiscard} />}
    </Box>
  )
}
