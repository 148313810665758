import type { FormValidator } from '@workwave-tidal/tidal/form-fairy'
import type { FormErrors, FormFields } from '../../formFields'

import { useMemo } from 'react'

import { useTexts } from '../useTexts'
import { useValidateDriverEmail } from './useValidateDriverEmail'

export function useEmailValidation() {
  const texts = useTexts()

  const validationFunction = useValidateDriverEmail()

  return useMemo(() => {
    const validator: FormValidator<FormFields, FormErrors> = async formApi => {
      const field = formApi.getField('email')

      const validationResult = await validationFunction(field.value)

      const pristine = field.value === field.initialValue

      return {
        emailInvalidError:
          validationResult === 'invalid'
            ? {
                id: 'emailInvalidError',
                message: texts.errors.email.invalid,
                field: 'email',
                priority: 10,
              }
            : null,
        emailInUseError:
          // at submit the form will be validated
          // the isUse check has to be done only when the field is not pristine
          // otherwise an edit form will always be in an error state
          validationResult === 'inUse' && !pristine
            ? {
                id: 'emailInUseError',
                message: texts.errors.email.inUse,
                field: 'email',
                priority: 10,
              }
            : null,
      }
    }

    return {
      validator,
      fields: 'email' as const,
      id: 'emailValidator',
    }
  }, [texts, validationFunction])
}
