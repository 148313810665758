import type { RouteDetailEntry } from '../../../../types'

import { Box, Stack } from '@mui/material'
import { useSelector } from 'react-redux'

import { Form } from '@workwave-tidal/tidal/form-ui/layout'

import { selectUserConfiguration } from '@/features/domain/user'
import { useFormTransaction } from '@/hooks'
import { FormLoading } from '@/components/FormLoading'

import { RouteDetailsFormFooter } from './components/RouteDetailsFormFooter'
import { RoutePreparationEvent } from './components/RoutePreparationEvent'
import { RouteCloseoutEvent } from './components/RouteCloseoutEvent'
import { RouteOrdersEvent } from './components/RouteOrdersEvent'
import { StartEvent } from './components/StartEvent'
import { EndEvent } from './components/EndEvent'
import { Driver } from './components/Driver'

import { useTimePickerOptions } from './hooks/useTimePickerOptions'
import { useOnSubmit } from './hooks/useOnSubmit'

interface Props {
  routeDetailsData: RouteDetailEntry[]
  clearEditingState: () => void
  routeColor: string
  onDiscard: () => void
  vehicleId: string
  date: string
}

export function RouteDetailsForm(props: Props) {
  const { routeDetailsData, routeColor, vehicleId, date, onDiscard, clearEditingState } = props

  const transactionState = useFormTransaction()
  const { timeFormat } = useSelector(selectUserConfiguration)
  const timeOptions = useTimePickerOptions()
  const onSubmit = useOnSubmit(vehicleId, date, routeDetailsData, clearEditingState)

  const border = routeDetailsData.length > 1 ? '1px solid' : undefined
  const timePickerFormat = timeFormat === 'hh:mm aa' ? '12' : '24'

  const transactionOpen = transactionState === 'ready'

  return transactionOpen ? (
    <Form
      testId="routeDetailsForm"
      footer={<RouteDetailsFormFooter onDiscard={onDiscard} onSubmit={onSubmit} />}
    >
      <Stack spacing={1} paddingY={1}>
        {routeDetailsData.map((entry, index) => (
          <Box
            key={`routeDetail_${index}`}
            border={border}
            padding={1}
            borderColor="grey.300"
            data-testid="routeDriver"
            borderRadius={1}
          >
            <Stack padding={[0, 1]} spacing={3}>
              <Driver name={entry.driverName} />
              {entry.start && (
                <StartEvent
                  entry={entry.start}
                  driverId={entry.driverId}
                  routeColor={routeColor}
                  timeFormat={timePickerFormat}
                  timeOptions={timeOptions}
                />
              )}
              {entry.preparation && (
                <RoutePreparationEvent
                  data={entry.preparation}
                  routeColor={routeColor}
                  timeFormat={timePickerFormat}
                  timeOptions={timeOptions}
                />
              )}
              {entry.ordersCount > 0 && <RouteOrdersEvent ordersCount={entry.ordersCount} />}
              {entry.closeout && (
                <RouteCloseoutEvent
                  data={entry.closeout}
                  routeColor={routeColor}
                  timeFormat={timePickerFormat}
                  timeOptions={timeOptions}
                />
              )}
              {entry.end && (
                <EndEvent
                  entry={entry.end}
                  driverId={entry.driverId}
                  routeColor={routeColor}
                  timeFormat={timePickerFormat}
                  timeOptions={timeOptions}
                />
              )}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Form>
  ) : (
    <Box width="100%" height="100%">
      <FormLoading />
    </Box>
  )
}
