import { useSelector } from 'react-redux'
import { Box, Divider } from '@mui/material'

import { FormColumn } from '@/components/layout'
import { ViewHeader } from '@/components/formUi'
import { ViewContent, ViewField, ViewFieldsLayout } from '@/formUi'

import { selectGpsTrackingProvider } from '@/features/domain/user'

import { useDeleteDriverModal } from '../../../modals/DeleteDriver'
import { useChangePasswordModal } from '../../../modals/ChangePassword'

import { useViewHeaderProps } from './hooks/useViewHeaderProps'
import { useTexts } from './hooks/useTexts'
import { DriverApp } from './components/DriverApp'

interface Props {
  driver: uui.domain.client.rm.ExtendedDriver
}

export function SingleView(props: Props) {
  const { driver } = props
  const {
    driver: {
      id: driverId,
      name,
      deployment: { email },
    },
  } = driver

  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const viewHeaderProps = useViewHeaderProps(driver.driver)
  const texts = useTexts()
  const { Modal: DeleteDriverModal } = useDeleteDriverModal()
  const { Modal: ChangePasswordModal } = useChangePasswordModal()

  return (
    <FormColumn width={360} testid="form__column-secondary">
      <ViewHeader {...viewHeaderProps} testid="driver-view-header" />
      <ViewContent>
        <ViewFieldsLayout paddingTop={2}>
          <ViewField label={texts.labels.name}>{name}</ViewField>
          <ViewField label={texts.labels.email}>{email}</ViewField>
          <Divider />
          <Box mt={3}>
            <DriverApp driver={driver} trackingProvider={trackingProvider} />
          </Box>
        </ViewFieldsLayout>
      </ViewContent>
      <DeleteDriverModal
        driverIds={[driverId]}
        validationResult={viewHeaderProps.deleteValidationResult}
      />
      <ChangePasswordModal driverId={driverId} />
    </FormColumn>
  )
}
