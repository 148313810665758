import { format, isDate as isDateCheck } from 'date-fns/esm'

import * as Texts from '../../intl'

import { SimulationFormValues, SimulationDate } from './typings'

export const emptySimulationId: string = 'emptySimulationId'

type CalendarRange = {
  start: Date
  end: Date
}

const isDate = (a: CalendarRange | Date): a is Date => isDateCheck(a)

export const getFormInitialValues = (
  source: uui.domain.client.rm.SimulationInfo | undefined,
  today: Date,
  calendarSelection: CalendarRange | Date,
  isSelectingArchivedDate: boolean,
): SimulationFormValues => {
  if (source) {
    return {
      ...source,
      simulationDate: {
        type: 'fromScratch',
      },
    }
  }

  const simulationDate: SimulationDate = isSelectingArchivedDate
    ? {
        type: 'fromArchived',
        date: isDate(calendarSelection) ? calendarSelection : calendarSelection.start,
      }
    : {
        type: 'fromOperations',
        dates: isDate(calendarSelection)
          ? {
              min: calendarSelection,
              max: calendarSelection,
            }
          : {
              min: calendarSelection.start,
              max: calendarSelection.end,
            },
      }

  return {
    ...getEmptySimulation(today),
    simulationDate,
  }
}

export const getEmptySimulation = (today: Date): uui.domain.client.rm.SimulationInfo => {
  const todayAsString = formatDateAsString(today)

  return {
    id: emptySimulationId,
    name: Texts.getNewSimulationsDefaultName(),
    label: '',
    ordersCount: 0,
    vehiclesCount: 0,
    created: todayAsString,
    updated: todayAsString,
    revision: 0,
    routesCount: 0,
    version: 0,
  }
}

const formatDateAsString = (date: Date): string => format(date, 'yyyyMMdd')

export const computeCreationPayload = (
  values: SimulationFormValues,
): uui.domain.actions.rpc.simulation.CreateSimulationAction['params'] => {
  const { simulationDate, name, label } = values

  switch (simulationDate.type) {
    case 'fromScratch':
      return {
        mode: 'fromScratch',
        label,
        name,
        openAfterCreation: true,
      }

    case 'fromArchived':
      return {
        mode: 'fromArchived',
        label,
        name,
        date: formatDateAsString(simulationDate.date),
        openAfterCreation: true,
      }

    case 'fromOperations':
      return {
        mode: simulationDate.type,
        label,
        name,
        from: formatDateAsString(simulationDate.dates.min),
        to: formatDateAsString(simulationDate.dates.max || simulationDate.dates.min),
        openAfterCreation: true,
      }
  }
}
