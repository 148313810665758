import type { FormErrors, FormFields } from '../formFields'

import { useEffect, useRef } from 'react'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'

export function useTemplateCustom() {
  const {
    formApi,
    field: { value: templateCustom },
  } = useFormField<'templateCustom', FormFields, FormErrors>('templateCustom', {
    fieldsOfInterest: ['value'],
  })

  const firstUpdate = useRef<boolean>(true)

  useEffect(() => {
    /**
     * Prevent any update during the first execution to not trigger changes to the form state that
     * will bring the status to indeterminate (due to a change of required fields)
     */
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    // ETA RANGE (windowOfCommitmentToleranceMins) must be visible if @@ETARange@@ is present in the message
    const isEtaRangeHotwordPresent = templateCustom.indexOf('@@ETARange@@') !== -1
    formApi.setFieldVisibleState('windowOfCommitmentToleranceMins', isEtaRangeHotwordPresent)
  }, [formApi, templateCustom])
}
