import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function AddException(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 17 15">
        <path
          d="M8.125 5.879a4.251 4.251 0 0 0-4.258 4.238c0 2.312 1.883 4.258 4.258 4.258s4.258-1.946 4.258-4.258A4.251 4.251 0 0 0 8.125 5.88zM8 7.617h.25a.5.5 0 0 1 .5.5v1.375h1.375a.5.5 0 0 1 .5.5v.25a.5.5 0 0 1-.5.5H8.75v1.375a.5.5 0 0 1-.5.5H8a.5.5 0 0 1-.5-.5v-1.375H6.125a.5.5 0 0 1-.5-.5v-.25a.5.5 0 0 1 .5-.5H7.5V8.117a.5.5 0 0 1 .5-.5z"
          fill="#00AB55"
        />
        <path
          d="M.625.117C.249.117 0 .367 0 .742v7.5c0 .376.249.625.625.625h2.129c.124-.437.242-.874.43-1.25H1.25v-6.25H15v6.25h-1.992c.188.376.366.813.43 1.25h2.187c.376 0 .625-.249.625-.625v-7.5c0-.376-.249-.625-.625-.625h-15z"
          fill="currentColor"
        />
      </svg>
    </IconContainer>
  )
}
