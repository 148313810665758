import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectUserAccount, updateSessionUser } from '@/features/domain/user'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useTexts } from './useTexts'

type AccountData = {
  name: string
  email: string
  surname: string
  username: string
}

export function useRmAccountData() {
  const texts = useTexts()
  const toast = useNotification()
  const account = useSelector(selectUserAccount)
  const dispatch = useAppDispatch()

  const accountData = useMemo<AccountData>(() => {
    return {
      name: account.details.name,
      email: account.details.email,
      surname: account.details.surname,
      username: account.credentials.username.trim(),
    }
  }, [account])

  const updateAccountData = useCallback(
    async (data: AccountData) => {
      try {
        const thunkResult = await dispatch(updateSessionUser(data))

        if (!updateSessionUser.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }
        toast.success(texts.updateAccountSuccess, { autoClose: 5000 })

        return true
      } catch (e) {
        toast.error(texts.updateAccountError, { autoClose: 5000 })
      }

      return false
    },
    [dispatch, toast, texts],
  )

  return [accountData, updateAccountData] as const
}
