import { getCalendarizedSettings } from '@/server-data'
import { ModalData, MovePairedOrdersPayload } from '../typings'

export const computeModalDataValues = (
  payload: MovePairedOrdersPayload,
  date: string,
  order?: uui.domain.client.rm.Order,
  orderStep?: uui.domain.client.rm.OrderStep,
  pairedOrderStep?: uui.domain.client.rm.OrderStep,
  vehicle?: uui.domain.client.rm.Vehicle,
  pairedRoute?: uui.domain.client.rm.Route,
): ModalData => {
  if (!orderStep || !order || !pairedOrderStep) {
    return {
      issue: 'orderStepMissing',
    }
  }
  if (!vehicle) {
    return {
      issue: 'routeMissing',
    }
  }

  const settings = getCalendarizedSettings(vehicle, date)
  if (!settings.available) {
    return {
      issue: 'routeUnavailable',
    }
  }

  return {
    issue: 'none',
    pairedOrderStepUnAssigned: !pairedRoute,
    orderStep,
    pairedOrderStep,
    order,
    payload,
  }
}
