import type { RoadSegmentFormValues } from '../types'

export function getMalus(type: RoadSegmentFormValues['type']) {
  switch (type) {
    case 'blocked':
      return 10
    case 'delayed':
    case 'disabled':
      return 0
  }
}
