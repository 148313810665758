import styled from 'styled-components'

import InputFieldText from '../../../../components/fields/InputFieldText'

const styledComponent = styled(InputFieldText).attrs({
  className: 'o-column-header_input',
})`
  border-color: ${p => p.theme.colors.$pureWhite};
  background: ${p => p.theme.colors.$pureWhite};
  border-radius: 4px;
  cursor: auto;

  .o-input-field-text {
    color: ${p => p.theme.colors.$nightRider};
    background: ${p => p.theme.colors.$whiteSmoke};
  }

  .o-input-field-text__extra-icon {
    padding-left: 23px;
    margin-right: 10px;
    width: 40px;
  }

  .o-input-field-text__suggested-text {
    color: ${p => p.theme.colors.$silver};
  }

  .o-input-field-text__base-input::placeholder {
    color: ${p => p.theme.colors.$silver};
    font-weight: ${p => p.theme.weights.$regular};
  }
`

styledComponent.displayName = 'ColumnHeaderInput'
export default styledComponent
