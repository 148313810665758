import { createContext } from 'react'

export type LoadingResult = 'success' | 'popupBlocked' | 'unexistingPopup' | 'unknown'

export type Context =
  | {
      // The popup has been closed or never open
      status: 'close'
      // Api
      open: () => Promise<LoadingResult>
    }
  | {
      // The popup is opening right now
      status: 'opening'
      loadingPromise: Promise<LoadingResult>
      // Api
      close: () => boolean
    }
  | {
      // The popup is up and running, ready to render children
      status: 'open'
      rootContainer: HTMLElement
      // Api
      focus: () => boolean
      close: () => boolean
    }
  | {
      status: 'error'
      type:
        | 'popupBlocked' // The popup has been blocked
        | 'unexistingPopup' // The popup seems disappeared
        | 'unknown' // Something unexpected happened
      // Api
      open: () => Promise<LoadingResult>
    }

export const initialContextValue: Context = {
  status: 'close',
  open: () => {
    throw new Error('Missing popup context, add PopupRoot to your component tree')
  },
}

/**
 * Expose all the popup API.
 */
export const PopupContext = createContext<Context>(initialContextValue)
