import type { HotwordId } from '../types'

import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    addHotword: translate({ id: 'sms4.hotwordsField.addHotword' }),
    charactersCount: (count: number) =>
      translate({ id: 'sms4.hotwordsField.charactersCount', values: { count } }),
    segmentsCount: (count: number) =>
      translate({ id: 'sms4.hotwordsField.segmentsCount.short', values: { count } }),
    segmentsTooltip: translate({ id: 'sms4.hotwordsField.segmentsCount.tooltip' }),
    hotwordLabel: (id: HotwordId) => {
      // hotwords has no internationalization
      // I left this hardcoded
      switch (id) {
        case 'DriverName':
          return 'Driver Name'
        case 'ETARange':
          return 'ETA Range'
        case 'OrderAddress':
          return 'Order Address'
        case 'OrderName':
          return 'Order Name'
        case 'OrderStatus':
          return 'Order Status'
        case 'PlannedDate':
          return 'Planned Date'
        case 'ETA':
          return 'ETA'
        case 'ETAthreshold':
          return 'ETA Threshold'
      }
    },
    required: translate({ id: 'sms4.hotwordsField.required' }),
    learnMore: translate({ id: 'sms4.hotwordsField.learnMore' }),
    info: translate({ id: 'sms4.hotwordsField.dropdown.info' }),
    invalidHotword: (invalidHotword: string) =>
      translate({ id: 'sms4.hotwordsField.invalidHotword', values: { invalidHotword } }),
  }))

  return api
}
