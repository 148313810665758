import {
  createImmutableStateInvariantMiddleware,
  createSerializableStateInvariantMiddleware,
  configureStore,
} from '@reduxjs/toolkit'

import { toast } from 'react-toastify'

import { enablePatches } from 'immer'
import { intl } from '@/intl'
import { journal } from '@/server-data'

import { serializableMiddlewareIgnoreList } from '@/features/domain'

import { rootReducer } from './rootReducer'
import { createDomainMiddleware } from './middlewares/domainMiddleware'
import { domainProxy } from './domain/domainProxy'

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
  ignoredPaths: ['state.domain', 'domain.publicData'],
})

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  ignoredPaths: ['domain'],
  ignoredActions: [...serializableMiddlewareIgnoreList],
})

// _devMiddlewares extremely slow down the app with high volume of data, enable them only for debugging purposes
// @ts-expect-error the variable is declared for possible debugging needs
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const devMiddlewares =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
    ? [immutableInvariantMiddleware, serializableMiddleware]
    : []

enablePatches()

export const store = configureStore({
  reducer: rootReducer,

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          domainProxy,
          journal,
          intl,
          notification: toast,
          pendo: window.pendo,
        },
      },
      immutableCheck: false,
      serializableCheck: false,
    }).concat(/*...devMiddlewares,*/ createDomainMiddleware({ domainProxy })),

  enhancers: [],
})
