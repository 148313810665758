import { ResourceModel } from '@bryntum/schedulerpro'
import { schedulerPreferencesAtom } from '../../schedulerPreferences'

export function ordersExecutedSorter(item1: ResourceModel, item2: ResourceModel) {
  const ordersExecuted1 = item1.getData(
    'ordersExecuted',
  ) as uui.domain.client.rm.SchedulerResource['ordersExecuted']

  const ordersExecuted2 = item2.getData(
    'ordersExecuted',
  ) as uui.domain.client.rm.SchedulerResource['ordersExecuted']

  if (schedulerPreferencesAtom.preferences.showAbsoluteValues) {
    return ordersExecuted1.absolute - ordersExecuted2.absolute
  }

  return ordersExecuted1.percentage - ordersExecuted2.percentage
}
