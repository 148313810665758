import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    status: (
      lastPosition:
        | uui.domain.client.gps.wwgps.GpsInfo
        | uui.domain.client.gps.telematics.CurrentPositionGpsInfo,
      isHistorical: boolean,
    ) => {
      if (isHistorical) return translate({ id: 'rm.contextual.devices.notReportingDevice' })

      switch (lastPosition.status) {
        case 'moving':
          return translate({ id: 'rm.contextual.devices.movingDevice' })
        case 'stalling':
          return translate({ id: 'rm.contextual.devices.stallingDevice' })
        case 'stopped':
          return translate({ id: 'rm.contextual.devices.stoppedDevice' })
      }
    },

    lastReport: translate({ id: 'rm.contextual.devices.lastReport' }),
    odometer: translate({ id: 'global.odometer' }),
    heading: translate({ id: 'global.heading' }),
    since: translate({ id: 'global.since' }),
  }))

  return api
}
