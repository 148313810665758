import { makeStyles } from '@mui/styles'

import { HorizontalLayout, VerticalLayout } from '@/components/layout'
import { RouteViolationsTooltipContent } from '@/components/RouteViolationsTooltipContent'
import { getColorValue } from '@/styles'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useViolationsCountFromRoutes } from '../hooks/useViolationsCountFromRoutes'

import { DriverTag } from './components/DriverTag'
import { VehicleTag } from './components/VehicleTag'
import { NameAndDate } from './components/NameAndDate'
import { VehicleViolationsTag } from './components/VehicleViolationsTag'
import { useSingleActions } from './hooks/useSingleActions'
import { useData } from './hooks/useData'

interface Props {
  routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable
}

const useStyles = makeStyles({
  badgeContainer: {
    '& > *': {
      marginLeft: 5,
    },
    '& > :first-child': {
      marginLeft: 0,
    },
  },
})

export function Single(props: Props) {
  const { routeInfo } = props

  const { constraintsCount: vehicleViolations } = useViolationsCountFromRoutes([routeInfo])
  const { idx, vehicleName, extDriver } = useData(routeInfo)

  const actions = useSingleActions(routeInfo.vehicleId, extDriver?.id)
  const classes = useStyles()

  return (
    <VerticalLayout
      data-trackid="navigo-routes-overview-details-single"
      data-testid="navigo-routes-overview-details-single"
      justifyContent="space-between"
    >
      <NameAndDate idx={idx} date={routeInfo.date} />

      <HorizontalLayout className={classes.badgeContainer} marginTop={3}>
        {vehicleViolations > 0 && (
          <Tooltip
            placement="top"
            content={<RouteViolationsTooltipContent violations={routeInfo.violations} />}
          >
            <VehicleViolationsTag onClick={actions.selectVehicle}>
              {vehicleViolations}
            </VehicleViolationsTag>
          </Tooltip>
        )}

        {extDriver && <DriverTag onClick={actions.selectDriver}>{extDriver.driver.name}</DriverTag>}

        <VehicleTag
          vehicleName={vehicleName}
          onClick={actions.selectVehicle}
          backgroundColor={getColorValue(routeInfo.avatar.color)}
        >
          {`${idx}`}
        </VehicleTag>
      </HorizontalLayout>
    </VerticalLayout>
  )
}
