import type { FormProps } from '../types'

import { NotificationForm } from '../../../../../../notificationForms/sections/notifications/components/NotificationForm'
import { NotificationView } from '../../../../../../notificationForms/sections/notifications/components/NotificationView'

import { useFormStateController } from '../../hooks/useFormStateController'
import { useLiveEtaStatus } from '../../hooks/useLiveEtaStatus'
import { EarlyNotificationView } from './components/EarlyNotificationView'

import { useEarlyNotificationInitForm } from './hooks/useEarlyNotificationInitForm'
import { useEarlyNotificationSubmit } from './hooks/useEarlyNotificationSubmit'
import { useEarlyNotificationConfig } from './hooks/useEarlyNotificationConfig'
import { useEarlyNotificationValues } from './hooks/useEarlyNotificationValues'
import { useEarlyNotificationToggle } from './hooks/useEarlyNotificationToggle'
import { useTexts } from './useTexts'

/**
 * Renders the Early notification form providing all the domain data and apis.
 */
export function EarlyNotification(props: FormProps) {
  const { inEditing, setEditingState } = props

  const values = useEarlyNotificationValues()

  const { toggleDescription, onToggle } = useEarlyNotificationToggle(values)
  const { edit, cancel } = useFormStateController(setEditingState)
  const onSubmit = useEarlyNotificationSubmit()
  const config = useEarlyNotificationConfig()
  const liveEtaStatus = useLiveEtaStatus()
  const texts = useTexts()

  const available = liveEtaStatus !== 'notActive' && liveEtaStatus !== 'notAvailableForTheUser'

  // Render the Form inside a FormRoot component that will expose the FormState API through a React.Context
  if (inEditing) {
    return (
      <NotificationForm
        initHook={useEarlyNotificationInitForm}
        subtitle={texts.description}
        notification={'early'}
        onSubmit={onSubmit}
        title={texts.title}
        cancel={cancel}
        values={values}
        config={config}
      />
    )
  }

  return (
    <NotificationView
      Form={<EarlyNotificationView values={values} />}
      toggleSubtitle={toggleDescription}
      subtitle={texts.description}
      notification={'early'}
      available={available}
      onToggle={onToggle}
      title={texts.title}
      values={values}
      onEdit={edit}
    />
  )
}
