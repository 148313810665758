const KM_PER_MILE: number = 1.609344
const MILE_PER_KM: number = 0.621371

export const degreesToRadians = (deg: number) => deg * (Math.PI / 180)

export const radiansToDegrees = (rad: number) => rad / (Math.PI / 180)

// convert from cm/s to mph
export const cmsToMph = (cms: number) => cms / 44.704

// convert from cm/s to m/s
const cmsToms = (cms: number) => cms / 100

// convert from m/s to km/h
const msToKmh = (ms: number) => (ms / 1000) * 3600

// convert cm/s to km/h
export const cmsToKmh = (cms: number) => msToKmh(cmsToms(cms))

export const kmhToCms = (kmh: number) => 27.7778 * kmh

export const mphToCms = (mph: number) => 44.704 * mph

export const convertMilesToKilometers = (miles: number) => miles * KM_PER_MILE

export const convertKilometersToMiles = (km: number) => km * MILE_PER_KM

export const convertCostPerMilesToCostPerKilometers = (miles: number) => miles / KM_PER_MILE

export const convertCostPerKilometersToCostPerMiles = (km: number) => km / MILE_PER_KM
