import { TrackingOption } from './fields/TrackingOption'
import { DeviceId } from './fields/DeviceId'

interface Props {
  formValues: uui.domain.ui.forms.Rm360VehicleFormValues
}

export function TrackingOptions(props: Props) {
  const { formValues } = props

  const physicalDeviceSelected = formValues.trackingOption === 'physicalDevice'

  return (
    <>
      <TrackingOption deviceId={formValues.rm.deviceId} />
      {physicalDeviceSelected && <DeviceId />}
    </>
  )
}
