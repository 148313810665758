import { PureComponent, ReactNode } from 'react'

import { Block, Label, FieldMessage, TimeWindowList, RestoreFieldButton } from '@/forms-legacy'

import { Props } from './typings'

export default class TimeWindowListField extends PureComponent<Props> {
  private renderError = (index: number): ReactNode => {
    const {
      formProps: {
        meta: { error },
      },
    } = this.props

    if (!error) {
      return null
    }

    const errorString: string = Array.isArray(error) ? error[index] || '' : error

    return errorString && errorString.trim().length > 0 ? (
      <FieldMessage style={{ marginBottom: 10 }} error>
        {errorString}
      </FieldMessage>
    ) : null
  }

  render() {
    const {
      label,
      name,
      info,
      half = false,
      wrappedInputProps = {},
      formProps: {
        input: { value, onChange },
        meta: { error, dirty, initial },
      },
      testid,
    } = this.props

    return (
      <Block half={half} error={!!error} dirty={!!dirty} data-testid={testid}>
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <TimeWindowList
          {...wrappedInputProps}
          onChange={onChange}
          list={value}
          renderError={this.renderError}
        />
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }
}
