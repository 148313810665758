import { useCallback } from 'react'
import { cancelCreateVehicleException, updateVehiclesSettings } from '@/features/domain/vehicle'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { useResetEditingState, useVehicleFormException } from '@/atoms'

export function useActions(unifiedVehicle: uui.domain.client.UnifiedVehicle) {
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const [{ editMode, exception }, setExceptionSettings] = useVehicleFormException()

  const stopEditing = useResetEditingState()

  const onUpdateVehicleSettings = useCallback(
    async (exception: string, settings: uui.domain.client.rm.VehicleSettings) => {
      try {
        if (!unifiedVehicle.hasRoutingLicense) {
          if (process.env.NODE_ENV === 'development') {
            throw new Error('Vehicle must have the routing license to update the settings')
          }
          return
        }

        const vehicleId = unifiedVehicle.vehicle.id

        const result = await dispatch(
          updateVehiclesSettings({ exception, vehicleIds: [vehicleId], settings }),
        )

        if (!updateVehiclesSettings.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, toast, stopEditing, unifiedVehicle],
  )

  const onCancel = useCallback(async () => {
    try {
      if (editMode === 'create') {
        const result = await dispatch(
          cancelCreateVehicleException({
            recurrence: exception,
            unifiedId: unifiedVehicle.unifiedId,
          }),
        )

        if (!cancelCreateVehicleException.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      }

      setExceptionSettings({ exception: 'any' })
      stopEditing()
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, toast, stopEditing, unifiedVehicle, exception, setExceptionSettings, editMode])

  return {
    onCancel,
    onUpdateVehicleSettings,
  }
}
