import { type FormValues } from '../types'
import { normalizeTrafficFactor } from './trafficFactor'

export function normalizeFormValues(
  formValues: FormValues,
  source: uui.domain.client.rm.TrafficProfile,
): uui.domain.client.rm.TrafficProfile {
  return {
    ...source,
    description: formValues.description,
    baseTrafficFactor: normalizeTrafficFactor(formValues.baseTrafficFactor),
  }
}
