import { genericStyles } from '../../genericStyles'

import { centerStyle } from './styles/centerStyle'
import { circleStyle } from './styles/circleStyle'
import { getLabelStyle } from './styles/getLabelStyle'

/**
 * Return the right style to be used by a Geofence feature
 */
export function getGeofenceFeatureStyle(
  marker: uui.domain.ui.map.markers.Geofence,
  mode: uui.domain.ui.map.markers.MapStyles['geofence']['mode'],
  selected: boolean = false,
) {
  if (!selected || mode === 'off') return genericStyles.hidden

  return [centerStyle, circleStyle, getLabelStyle(marker.type, marker.label)]
}
