import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    ok: translate({
      id: 'routingLayout.extractedMapWarningModal.controls.ok',
    }),

    cancel: translate({
      id: 'routingLayout.extractedMapWarningModal.controls.cancel',
    }),
  }))

  return api
}
