import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrdersGridListOptions, selectOrdersGridListStructure } from '@/features/domain/lists'

export function useSearchedOrderStepIds() {
  const query = useSelector(selectOrdersGridListOptions).search.query
  const listStructure = useSelector(selectOrdersGridListStructure).list

  return useMemo(
    () => ({
      query,
      filteredOrderStepIds:
        // TS can't infer string[] with a simple `filter`
        listStructure.reduce<string[]>((acc, item) => {
          if (typeof item === 'string') {
            acc.push(item)
          }
          return acc
        }, []),
    }),
    [listStructure, query],
  )
}
