import { useState, useMemo } from 'react'

import { getRecurrences } from '../../../../../../utils/getRecurrences'
import { filterRecurrences } from '../utils/filterRecurrences'

export function useRecurrences(unifiedVehicle: uui.domain.client.UnifiedVehicle) {
  const [filter, setFilter] = useState('')

  const recurrences = useMemo(() => {
    const recurrences = getRecurrences(unifiedVehicle)
    return filterRecurrences(filter, recurrences)
  }, [unifiedVehicle, filter])

  return {
    recurrences,
    setFilter,
    filter,
  }
}
