import type { TimeRangeType } from '../../../hooks/formFields'

import { useMemo } from 'react'
import { useTexts } from '../../../useTexts'

export function useTypeOptions() {
  const texts = useTexts()

  return useMemo<{ value: TimeRangeType; label: string }[]>(
    () => [
      { value: 'live', label: texts.live },
      { value: 'custom', label: texts.custom },
    ],
    [texts],
  )
}
