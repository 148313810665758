import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { Letter } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  disabled: boolean
}

export function SendMessageToDriverButton(props: Props) {
  const { disabled, onClick } = props
  const texts = useTexts()

  return (
    <ReadOnlyTooltip
      render={preventEditing => {
        const buttonColor = preventEditing || disabled ? '$shadyLady' : '$nightRider'

        return (
          <NavigoIconButton
            disabled={preventEditing || disabled}
            onClick={onClick}
            Icon={<Letter size={17} color={buttonColor} />}
            text={texts.sendToDriver}
          />
        )
      }}
    />
  )
}
