import { Style } from 'ol/style'

import { getTexts } from '../../../controls/registerMapControls/texts'
import { genericStyles } from '../../genericStyles'

const depotLabelStylesCache: Map<string, Style> = new Map()

export const getDepotFeatureLabelStyle = (
  marker: uui.domain.ui.map.markers.Depot,
  mode: uui.domain.ui.map.markers.MapStyles['depot']['mode'],
) => {
  if (mode !== 'label') return genericStyles.hidden

  const texts = getTexts()

  let text = marker.label
  if (marker.ordersCount > 0) {
    text += `\n(${texts.orders(marker.ordersCount)})`
  }

  const cacheId = `${mode}_${text}`

  if (depotLabelStylesCache.has(cacheId)) {
    const cachedStyle = depotLabelStylesCache.get(cacheId)

    if (cachedStyle) return cachedStyle
  }

  const style = genericStyles.label.clone()
  const textInstance = style.getText()

  textInstance.setText(text)
  textInstance.setOffsetY(marker.ordersCount > 0 ? 30 : 23)

  depotLabelStylesCache.set(cacheId, style)

  return style
}
