import { useCallback, useRef, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

import { exportRoutes, getExportRouteColumns } from '@/features/domain/route/actions'
import { useNotification, useIsUnmounted, useDelayedCallback } from '@/hooks'
import {
  exportRoutesColumnSelectionAtom,
  setExportRoutesColumnSelection,
  setExportRoutesPreferences,
} from '@/atoms'
import { useAppDispatch } from '@/store'

import { type Filter, type Format } from '../typings'

import { useTexts } from './useTexts'
import { Column, useController } from './useController'
import { useCreateColumns } from './useCreateColumns'

export const useControllerActions = () => {
  const [isLoading, setIsLoading] = useState(false)
  const texts = useTexts()
  const toast = useNotification()
  const isUnmounted = useIsUnmounted()
  const dispatch = useAppDispatch()

  const {
    close,
    updateData,
    data: {
      filter,
      format,
      selectedRoutes,
      includePod,
      includeBarcodes,
      columnsState,
      keepEnglishColumns,
      columnSelection,
    },
  } = useController()

  const toastId = useRef<string | number>(-1)

  const [showToastAndCloseModalAfterTimer, clearToastTimer] = useDelayedCallback(() => {
    toastId.current = toast(texts.preparingExport, {
      autoClose: false,
      closeOnClick: true,
      theme: 'light',
    })
    close?.()
  }, 2000)

  const createColumns = useCreateColumns()

  const onChangeFormat = useCallback(
    (_e, value: string) => {
      if (!value) return
      updateData({ format: value as Format })
    },
    [updateData],
  )

  const onChangeFilter = useCallback(
    (_e, value: string) => {
      if (!value) return
      updateData({ filter: value as Filter })
    },
    [updateData],
  )

  const onChangeIncludePod = useCallback(
    (_e, checked) => updateData({ includePod: checked }),
    [updateData],
  )

  const onChangeIncludeBarcodes = useCallback(
    (_e, checked) => updateData({ includeBarcodes: checked }),
    [updateData],
  )

  const onExport = useCallback(async () => {
    try {
      if (columnSelection.length === 0) {
        updateData({ showEmptySelectionWarning: true })
        return
      }

      setIsLoading(true)

      const routeIds = selectedRoutes.map(route => route.id)

      if (columnsState.status !== 'complete') {
        throw new Error('columns status must be complete')
      }

      const columnsSet = columnsState.columns.reduce<Record<string, string>>((acc, curr) => {
        acc[curr.id] = curr.label
        return acc
      }, {})

      const requestedColumns = columnSelection.map(columnId => ({
        columnId,
        label: columnsSet[columnId],
      }))

      showToastAndCloseModalAfterTimer()

      const exportRequest = await dispatch(
        exportRoutes({
          routeIds,
          config: {
            format,
            includePod,
            // includeBarcodes: includeBarcodes,
            currentRoutes: filter === 'selected',
            requestedColumns,
          },
        }),
      )

      // DEBUG long wait
      // await new Promise(resolve => setTimeout(resolve, 5000))

      if (exportRoutes.fulfilled.match(exportRequest)) {
        const url = unwrapResult(exportRequest)
        if (typeof url === 'string') {
          window.open(url)
        }
      }

      setExportRoutesColumnSelection(columnSelection)

      toast.dismiss(toastId.current)
      clearToastTimer()

      setExportRoutesPreferences({
        format,
        includePod,
        currentRoutes: filter === 'selected',
        includeBarcodes,
        customizeColumns: true,
        keepEnglishColumns,
      })

      close?.()
    } catch (e) {
      toast.error(texts.genericExportRoutesError)
    }

    setIsLoading(false)
  }, [
    keepEnglishColumns,
    includeBarcodes,
    selectedRoutes,
    includePod,
    filter,
    format,
    dispatch,
    texts,
    close,
    toast,
    columnsState,
    columnSelection,
    clearToastTimer,
    showToastAndCloseModalAfterTimer,
    updateData,
  ])

  const onLegacyExport = useCallback(async () => {
    try {
      setIsLoading(true)

      const routeIds = selectedRoutes.map(route => route.id)

      showToastAndCloseModalAfterTimer()

      const exportRequest = await dispatch(
        exportRoutes({
          routeIds,
          config: {
            format,
            includePod,
            currentRoutes: filter === 'selected',
            includeBarcodes: true,
          },
        }),
      )

      // DEBUG long wait
      // await new Promise(resolve => setTimeout(resolve, 5000))

      if (exportRoutes.fulfilled.match(exportRequest)) {
        const url = unwrapResult(exportRequest)
        if (typeof url === 'string') {
          window.open(url)
        }
      }

      toast.dismiss(toastId.current)
      clearToastTimer()

      setExportRoutesPreferences({
        format,
        includePod,
        currentRoutes: filter === 'selected',
        includeBarcodes,
        customizeColumns: false,
        keepEnglishColumns,
      })

      close?.()
    } catch (e) {
      toast.error(texts.genericExportRoutesError)
    }

    setIsLoading(false)
  }, [
    showToastAndCloseModalAfterTimer,
    keepEnglishColumns,
    includeBarcodes,
    clearToastTimer,
    selectedRoutes,
    includePod,
    filter,
    format,
    dispatch,
    texts,
    close,
    toast,
  ])

  const onPrint = useCallback(async () => {
    try {
      setIsLoading(true)
      const routeIds = selectedRoutes.map(route => route.id)

      await dispatch(
        exportRoutes({
          routeIds,
          config: {
            format: 'HTML',
            includePod,
            includeBarcodes: includeBarcodes,
            currentRoutes: filter === 'selected',
          },
        }),
      )
      if (isUnmounted()) return

      close?.()
    } catch (e) {
      toast.error(texts.genericExportRoutesError)
    }

    setIsLoading(false)
  }, [
    selectedRoutes,
    includePod,
    includeBarcodes,
    filter,
    texts,
    close,
    toast,
    isUnmounted,
    dispatch,
  ])

  const onChangeKeepEnglishColumns = useCallback(
    (keepEnglishColumns: boolean) => {
      updateData({ keepEnglishColumns })
    },
    [updateData],
  )

  const onColumnSelectionChange = useCallback(
    (columnSelection: string[]) => {
      updateData({ columnSelection, showEmptySelectionWarning: false })
    },
    [updateData],
  )

  const onNextButtonClick = useCallback(async () => {
    try {
      updateData({ columnsState: { status: 'loading' } })

      const routeIds = selectedRoutes.map(route => route.id)
      const result = await dispatch(
        getExportRouteColumns({
          mode: 'standard',
          routeIds,
          currentRoutes: filter === 'selected',
          includePod,
        }),
      )

      if (getExportRouteColumns.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      const columns = await createColumns(result.payload, keepEnglishColumns)

      const columnSelection = replacePlaceholderInsideSelection(
        exportRoutesColumnSelectionAtom.selection,
        columns,
      )

      updateData({
        wizardStep: 'configure-columns',
        columnsState: { status: 'complete', columns },
        columnSelection,
      })
    } catch (e) {
      updateData({ columnsState: { status: 'error' } })
      toast.error(texts.genericExportRoutesError)
    }
  }, [
    updateData,
    keepEnglishColumns,
    dispatch,
    createColumns,
    texts,
    toast,
    filter,
    selectedRoutes,
    includePod,
  ])

  const onPrevButtonClick = useCallback(() => {
    updateData({ wizardStep: 'configure-format' })
  }, [updateData])

  const onChangeCustomizeColumns = useCallback(
    (customizeColumns: boolean) => {
      updateData({ customizeColumns })
    },
    [updateData],
  )

  return {
    onPrint,
    onExport,
    isLoading,
    onLegacyExport,
    onChangeFormat,
    onChangeFilter,
    onNextButtonClick,
    onPrevButtonClick,
    onChangeIncludePod,
    onChangeIncludeBarcodes,
    onChangeKeepEnglishColumns,
    onColumnSelectionChange,
    onChangeCustomizeColumns,
  }
}

function replacePlaceholderInsideSelection(selection: readonly string[], columns: Column[]) {
  const groups = {
    customfields: [] as string[],
    tws: [] as string[],
    loads: [] as string[],
  }

  for (const { id } of columns) {
    if (id.startsWith('CF_')) {
      groups.customfields.push(id)
    }
    if (id.startsWith('L_')) {
      groups.loads.push(id)
    }

    if (id.startsWith('tws')) {
      groups.tws.push(id)
    }
  }

  const columnsIds = new Set(columns.map(c => c.id))

  const result = selection.reduce<string[]>((acc, id) => {
    if (id === 'loads') {
      acc.push(...groups.loads)
      return acc
    }

    if (id === 'tws') {
      acc.push(...groups.tws)
      return acc
    }

    if (id === 'customfields') {
      acc.push(...groups.customfields)
      return acc
    }

    if (columnsIds.has(id)) {
      acc.push(id)
    }

    return acc
  }, [] as string[])

  return result
}
