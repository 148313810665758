import type Geometry from 'ol/geom/Geometry'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { setVehicleFeatureMetadata } from '../vehicleFeatureMetadata'
import { getVehicleFeatureStyles } from './getVehicleFeatureStyles'

export const createVehicleFeature = (
  marker: uui.domain.ui.map.markers.Vehicle,
  mapStyles: uui.domain.ui.map.markers.MapStyles['vehicle'],
  selected: boolean = false,
): Feature<Geometry> => {
  const { lonLat, id } = marker

  const feature = new Feature({ geometry: new Point(lonLat), label: new Point(lonLat) })

  // use marker ID (vehicleId) as unique ID
  feature.setId(id)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setVehicleFeatureMetadata(feature, 'type', 'vehicle')

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['vehicle']['mode'] = marker.mode ?? mapStyles.mode
  setVehicleFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getVehicleFeatureStyles(marker, mode, selected))

  return feature
}
