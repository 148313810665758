import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    lat: translate({ id: 'global.lat' }),
    lng: translate({ id: 'global.lng' }),
    inspect: translate({ id: 'mapcontrols.inspect' }),
    geocoding: translate({ id: 'contextualMenu.droppedPin.geocoding' }),
    savePlace: translate({ id: 'contextualMenu.droppedPin.saveplace' }),
    createOrder: translate({ id: 'contextualMenu.droppedPin.createOrder' }),
    createGeofence: translate({ id: 'contextualMenu.droppedPin.createGeofence' }),
    dispatchToDriver: translate({ id: 'contextualMenu.droppedPin.dispatchToDriver' }),
    mapInspectorTool: translate({ id: 'contextualMenu.droppedPin.mapInspectorTool' }),
    inspectPointOnMap: translate({ id: 'contextualMenu.droppedPin.mapInspectorToolDesc' }),
  }))

  return api
}
