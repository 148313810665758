import type { FormValidator } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../../formFields'
import type { TerritoryConfigError } from '../../../types'
import type { Texts } from '../useTexts'

export const createValidateCourierFilter =
  (texts: Texts): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const field = formApi.getField('courierFilter')
    const type = formApi.getField('type').value

    const noTerritoriesDefined = Object.keys(field.value).length === 0

    const territoriesError = Object.entries(field.value).reduce<
      Record<string, TerritoryConfigError>
    >((acc, [id, territoryConfig]) => {
      if (territoryConfig.driverIds.length === 0) {
        acc[id] ??= {}
        acc[id].drivers = texts.errors.courierFilter.noDrivers
      }
      if (territoryConfig.vehicleIds.length === 0) {
        acc[id] ??= {}
        acc[id].vehicles = texts.errors.courierFilter.noVehicles
      }

      return acc
    }, {})

    const hasTerritoriesError = Object.keys(territoriesError).length > 0

    const courierFilterErrors: FormErrors = {
      id: 'courierFilterErrors',
      message: noTerritoriesDefined ? texts.errors.courierFilter.noTerritoriesDefined : '',
      field: 'courierFilter' as const,
      priority: 15,
      territoryErrors: territoriesError,
    }

    const hasError = type === 'courier' && (noTerritoriesDefined || hasTerritoriesError)

    return {
      [courierFilterErrors.id]: hasError ? courierFilterErrors : null,
    }
  }
