import { useCallback } from 'react'
import { useTexts } from './useTexts'

export function useAutomatedMessageValidation() {
  const texts = useTexts()

  return useCallback(
    async formApi => {
      const automatedMessageEnabled = formApi.getField('automatedMessageEnabled').value
      const callbackConfigStatus = formApi.getField('callbackConfigStatus').value
      const automatedMessage = formApi.getField('automatedMessage').value.trim()
      const status = formApi.getField('status').value

      if (
        status !== 'ADVANCED' ||
        callbackConfigStatus === 'BACK_OFFICE' ||
        automatedMessageEnabled === 'OFF'
      )
        return { automatedMessageError: null }

      const isEmpty = automatedMessage.trim().length === 0

      if (!isEmpty) return { automatedMessageError: null }

      return {
        automatedMessageError: {
          id: 'automatedMessageError',
          field: 'automatedMessage' as const,
          message: texts.automatedMessage.error.required,
          priority: 10,
        },
      }
    },
    [texts],
  )
}
