import { Button, Stack, Typography } from '@mui/material'

import { useTexts } from '../hooks/useTexts'

interface Props {
  onShowModal: () => void
}

export function Header(props: Props) {
  const { onShowModal } = props
  const texts = useTexts()

  return (
    <Stack component="header" direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">{texts.title}</Typography>
      <Button
        data-testid="companies-header-add-company"
        data-trackid="companies-header-add-company"
        variant="contained"
        onClick={onShowModal}
      >
        {texts.add}
      </Button>
    </Stack>
  )
}
