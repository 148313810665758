import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'

import { ReadonlyBlock, ReadonlyLabel } from '@/forms-legacy'
import { useEnvironmentURLs } from '@/atoms'

import * as OrderTexts from '../../../intl'

import { Props } from '../typings'

import ReadonlyImage from './ReadonlyImage'

export function PodPictures(props: Props) {
  const {
    pods: { pictures },
    onTogglePod,
    activePodMarker,
  } = props

  const { podsBaseUrl } = useEnvironmentURLs()

  let picturesList: uui.domain.client.rm.Pod[] = []
  if (pictures) {
    picturesList = Object.values(pictures).map((picture: uui.domain.client.rm.Pod) => picture)
  }

  return (
    <>
      {picturesList.length > 0 && (
        <ReadonlyBlock>
          <ReadonlyLabel>{OrderTexts.getPicturesLabel()}</ReadonlyLabel>

          {picturesList.map((picture, idx) => {
            const { sec, text, latLng, uuid } = picture
            // const time: string = convertTime(sec, accountPreferences)
            const time = <ConvertTime seconds={sec} showSeconds={false} />
            const title: string = `${OrderTexts.getPicturesLabel()} ${idx + 1}`

            const src: string =
              picture.podType === 'pictures' ? `${podsBaseUrl}${picture.token}` : ''

            return (
              <ReadonlyImage
                onToggle={onTogglePod}
                key={`pod_picture_${idx}`}
                uuid={uuid}
                src={src}
                title={title}
                text={text || ''}
                time={time}
                latLng={latLng || undefined}
                layout={'thumbnail'}
                selected={activePodMarker?.uid === picture.uuid}
                fitImageToContainer
              />
            )
          })}
        </ReadonlyBlock>
      )}
    </>
  )
}
