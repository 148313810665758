import { useState, useCallback } from 'react'
import { Box } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { isDark } from '@/styles'
import { Tick } from '@/icons'

import ColorPickerChipButton from './ColorPickerChipButton'
import { colorNames } from './colorNames'

export type Props = {
  innerColor: string
  outerColor?: string
  selected?: boolean
  onClick?: () => void
  testid?: string
}

const normalizeColor = (color: string): string => {
  return color.startsWith('#') || color.startsWith('rgb') ? color : `#${color}`
}

export default function ColorPickerChip(props: Props) {
  const { innerColor, onClick, selected = false, testid } = props

  const [hover, setHover] = useState(false)

  const onMouseEnter = useCallback(() => {
    setHover(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setHover(false)
  }, [])

  const chipColor = normalizeColor(innerColor)

  const borderColor =
    selected && hover ? '#004D95' : selected ? '#2C6ECB' : hover ? '#E7E8E9' : '#FFFFFF'

  const tickColor = isDark(chipColor) ? '$pureWhite' : '$pureBlack'

  return (
    <ColorPickerChipButton
      type="button"
      onClick={onClick}
      data-testid={testid}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box position="relative">
        <Tooltip placement="top" title={colorNames[innerColor]}>
          <svg width="100%" height="100%" viewBox="0 0 31 32">
            <g>
              <path
                d="M18.9418 10.7934C19.2205 10.5115 19.6758 10.5115 19.9546 10.7934L20.6738 11.5207C20.9481 11.7981 20.9481 12.2447 20.6738 12.5222L14.374 18.8931C14.0952 19.175 13.6399 19.175 13.3612 18.8931L9.49516 14.9834C9.2208 14.706 9.2208 14.2594 9.49516 13.9819L10.2144 13.2546C10.4931 12.9727 10.9484 12.9727 11.2272 13.2546L13.6144 15.6688C13.7537 15.8097 13.9814 15.8097 14.1208 15.6688L18.9418 10.7934Z"
                fill="white"
              />

              <rect
                x="1.5"
                y="1.5"
                width="27"
                height="27"
                rx="7.5"
                fill={borderColor}
                stroke={borderColor}
                strokeWidth="3"
              />

              <rect
                x="4.5"
                y="4.5"
                width="21"
                height="21"
                rx="3.5"
                fill={chipColor}
                stroke="white"
                strokeWidth="3"
              />
            </g>
          </svg>
          {selected && (
            <Tick
              color={tickColor}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: 11,
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
        </Tooltip>
      </Box>
    </ColorPickerChipButton>
  )
}
