import { useCallback } from 'react'
import { useDisableUserConfirmationModal } from '../../modals/DisableUserConfirmationModal'
import { useEnableUser } from './useEnableUser'

export function useOnToggleUser() {
  const { show: showDisableUserConfirmationModal } = useDisableUserConfirmationModal()
  const enableUser = useEnableUser()

  return useCallback(
    async (enabled: boolean, userId: string) => {
      if (enabled) {
        showDisableUserConfirmationModal()
      } else {
        await enableUser({ userId, enabled: true })
      }
    },
    [enableUser, showDisableUserConfirmationModal],
  )
}
