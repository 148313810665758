import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type Params =
  | {
      territoryIds?: string[]
    }
  | undefined

export const fetchTerritoriesDriversAndVehicles = createAsyncThunk<
  // Return type of the payload creator
  uui.domain.client.rm.TerritoriesDriversAndVehicles,
  // First argument to the payload creator
  Params,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/territory/fetchTerritoriesDriversAndVehicles', async (params: Params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/territory/fetchTerritoriesDriversAndVehicles', {
      category: 'rpc',
      type: 'rpc/territory/fetchTerritoriesDriversAndVehicles',
      payload: params,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    return result.result
  } catch (error) {
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'FetchTerritoriesDriversAndVehicles Failed',
      error,
    })
  }
})
