import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState({
    sendHistoricalDataTitle: translate({
      id: 'demoTools.configureTelematicsDemo.sendHistoricalData.title',
    }),
    sendHistoricalDataDescription: translate({
      id: 'demoTools.configureTelematicsDemo.sendHistoricalData.description',
    }),
    sendHistoricalDataButton: translate({
      id: 'demoTools.configureTelematicsDemo.sendHistoricalData.button',
    }),

    configureTelematicsDemo: translate({ id: 'demoTools.configureTelematicsDemo' }),
    sendLiveDataTitle: translate({ id: 'demoTools.configureTelematicsDemo.sendLiveData.title' }),
    sendLiveDataDescription: translate({
      id: 'demoTools.configureTelematicsDemo.sendLiveData.description',
    }),
    sendLiveDataButton: translate({ id: 'demoTools.configureTelematicsDemo.sendLiveData.button' }),

    configureTitle: translate({ id: 'demoTools.configureTelematicsDemo.configure.title' }),
    configureDescription1: translate({
      id: 'demoTools.configureTelematicsDemo.configure.description.1',
    }),
    configureDescription2: translate({
      id: 'demoTools.configureTelematicsDemo.configure.description.2',
    }),
    configureDescription3: translate({
      id: 'demoTools.configureTelematicsDemo.configure.description.3',
    }),
    configureDescription4: translate({
      id: 'demoTools.configureTelematicsDemo.configure.description.4',
    }),
    configureDescription5: translate({
      id: 'demoTools.configureTelematicsDemo.configure.description.5',
    }),
    configureDescription6: translate({
      id: 'demoTools.configureTelematicsDemo.configure.description.6',
    }),
    configureButton: translate({ id: 'demoTools.configureTelematicsDemo.configure.button' }),

    closeButton: translate({ id: 'demoTools.configureTelematicsDemo.closeButton' }),
    warningTitle: translate({ id: 'demoTools.configureTelematicsDemo.warning.title' }),
    warningDescription1: translate({
      id: 'demoTools.configureTelematicsDemo.warning.description1',
    }),
    warningDescription2: translate({
      id: 'demoTools.configureTelematicsDemo.warning.description2',
    }),

    noTenantSources: {
      title: translate({ id: 'demoTools.configureTelematicsDemo.noTenantSources.title' }),
      description: translate({
        id: 'demoTools.configureTelematicsDemo.noTenantSources.description',
      }),
    },
  })

  return api
}
