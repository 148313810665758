import { useEffect } from 'react'
import { store } from '@/store'
import { selectMapMarkers } from '../../../../features/domain/mapMarkers'

function getBreadcrumbMapMarkerByDeviceId(deviceId: string) {
  const state = store.getState()

  return selectMapMarkers(state).breadcrumb[deviceId]
}

/**
 * Expose a method to get the breadcrumb map marker by device id for QA purposes
 */
export function useExposeGetBreadcrumbMapMarkerByDeviceId(qaEnabled: boolean) {
  useEffect(() => {
    if (!qaEnabled) return

    window.testActions ??= {}
    window.testActions.getBreadcrumbMapMarkerByDeviceId = getBreadcrumbMapMarkerByDeviceId

    return () => void delete window.testActions?.getBreadcrumbMapMarkerByDeviceId
  }, [qaEnabled])
}
