import { getSorterByField } from '../../columns/utils/getSorterByField'
import { schedulerPreferencesAtom } from '../../schedulerPreferences'
import { project } from '../project'

let lastSortersValue = schedulerPreferencesAtom.preferences.sorters[0]

export function syncProjectSorting() {
  // If the sorters value hasn't changed, don't do anything
  if (
    lastSortersValue.ascending === schedulerPreferencesAtom.preferences.sorters[0].ascending &&
    lastSortersValue.field === schedulerPreferencesAtom.preferences.sorters[0].field
  )
    return

  // Update the sorters value
  lastSortersValue = schedulerPreferencesAtom.preferences.sorters[0]

  project.resourceStore.sort({
    ascending: lastSortersValue.ascending,
    field: lastSortersValue.field.replace('dynamic-loads-', 'loads.'),
    fn: getSorterByField(lastSortersValue.field),
  })
}
