import { ReactElement } from 'react'

import { CircularButton, FlexRow, FlexColumn } from '@/forms-legacy'
import { Cross } from '@/icons'

import { BreakRowProps as Props } from './typings'

import BreakComponent from './Break'

const BreakRow = (props: Props): ReactElement => {
  const { break: brk, fieldProps, onRemove, onUpdate } = props

  return (
    <FlexRow alignItems="flex-start" style={{ marginTop: 10 }} data-testid="break">
      <FlexColumn style={{ marginRight: 6 }}>
        <CircularButton Icon={<Cross />} onClick={onRemove} style={{ marginTop: 34 }} />
      </FlexColumn>
      <FlexColumn primary>
        <BreakComponent value={brk} onChange={onUpdate} {...fieldProps} />
      </FlexColumn>
    </FlexRow>
  )
}

export default BreakRow
