import type { ChangeEvent } from 'react'

import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { unassignOrderSteps } from '@/features/domain/order'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export interface ModalHandlers {
  onCancel: () => void
  onDeleteOrder: () => void
}

export function useControllerActions() {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const {
    close,
    updateData,
    data: { selectedOrderSteps, pairedOrderSteps, unassignAlsoPaired },
  } = useController()

  const onUnassignAlsoPairedChange = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) =>
      updateData({ unassignAlsoPaired: checked }),
    [updateData],
  )

  const unassignOrders = useCallback(async () => {
    try {
      const idsToUnassign = selectedOrderSteps.map(order => order.orderStep.id)

      if (unassignAlsoPaired && !!pairedOrderSteps && pairedOrderSteps.length > 0) {
        idsToUnassign.push(...pairedOrderSteps.map(order => order.id))
      }

      const result = await dispatch(unassignOrderSteps(idsToUnassign))

      if (!unassignOrderSteps.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      close?.()
    } catch (e) {
      toast.error(texts.genericUnassignOrderError)
    }
  }, [pairedOrderSteps, selectedOrderSteps, unassignAlsoPaired, texts, toast, close, dispatch])

  return { unassignOrders, onUnassignAlsoPairedChange }
}
