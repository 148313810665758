import { useState, useCallback } from 'react'

import { Actions } from './Actions'
import { ConfirmPanel } from './ConfirmPanel'

export function ActionsManager() {
  const [view, setView] = useState<'default' | 'delete'>(() => 'default')

  const onDelete = useCallback(() => {
    setView('delete')
  }, [setView])

  const onCancel = useCallback(() => {
    setView('default')
  }, [setView])

  switch (view) {
    case 'delete':
      return <ConfirmPanel onCancel={onCancel} />
    case 'default':
      return <Actions onDelete={onDelete} />
  }
}
