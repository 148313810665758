import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    approvedTime: translate({ id: 'scheduler.popOver.approvedTime.title' }),
    unlimited: translate({ id: 'scheduler.popOver.approvedTime.unlimited' }),

    workingTime: (value: string, total: string, saturation: number) =>
      translate({
        id: 'scheduler.popOver.approvedTime.workingTime',
        values: { value, total, saturation },
      }),

    drivingTime: (value: string, total: string, saturation: number) =>
      translate({
        id: 'scheduler.popOver.approvedTime.title.drivingTime',
        values: { value, total, saturation },
      }),
  }))

  return api
}
