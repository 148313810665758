import { Alert, Box } from '@mui/material'

import { ColumnsConfigurator } from '@/components/ColumnsConfigurator'

import { useTexts } from '../useTexts'
import { MODAL_HEIGHT } from '../constants'
import { useController } from '../hooks/useController'
import { useControllerActions } from '../hooks/useControllerActions'

import { ExportConfigurator } from './ExportConfigurator/ExportConfigurator'

export function Body() {
  const {
    data: { wizardStep, columnsState, columnSelection, showEmptySelectionWarning },
  } = useController()
  const { onColumnSelectionChange } = useControllerActions()
  const texts = useTexts()

  switch (wizardStep) {
    case 'configure-format':
      return <ExportConfigurator />

    case 'configure-columns':
      switch (columnsState.status) {
        case 'complete':
          const warningCard = showEmptySelectionWarning ? (
            <Alert severity="error">{texts.emptySelectionWarning}</Alert>
          ) : null

          return (
            <Box height={MODAL_HEIGHT}>
              <ColumnsConfigurator
                columns={columnsState.columns}
                selection={columnSelection}
                onSelectionChange={onColumnSelectionChange}
                warningCard={warningCard}
              />
            </Box>
          )

        case 'error':
          // does make sense to show an error message here?
          return null

        // this states are not possible here but they are listed for completeness
        case 'loading':
        case 'not-requested':
          return null
      }

    default:
      throw new Error(`unhandled wizard step: ${wizardStep}`)
  }
}
