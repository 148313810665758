import { getRouterNavigator } from '@/routing'
import { setCrudSelection, setEditingState } from '@/atoms'
import { gis } from '@/server-data'

import { getDomainMarkerData } from '../../../../atoms/markerChangelog/domain/getDomainMarkerData'
import { fitMap } from '../../../../atoms/map/utils/fitMap'

export function doubleClickRoadSegment(roadSegmentId?: string) {
  if (!roadSegmentId) return

  const { mapMarkers } = getDomainMarkerData()

  const profileId = roadSegmentId.substring(0, roadSegmentId.lastIndexOf('_'))
  const roadSegment = mapMarkers.roadSegment[profileId][roadSegmentId]

  if (!roadSegment) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`Trying to double click a non-existent RoadSegment with ID: ${roadSegmentId}`)
    }

    return
  }

  // Set the new selection for both selectionContexts
  setCrudSelection('routingSegments', [roadSegmentId])

  // queue a pending fitMap request
  // it will be applied by the destination view when it opens
  fitMap(
    [gis.fromCoordinateToLatLng(roadSegment.start), gis.fromCoordinateToLatLng(roadSegment.end)],
    { preventIfVisible: true },
    'add',
  )

  // Navigate to the target page
  setEditingState({ editing: true, ctx: 'segment', editingEntities: [roadSegmentId] })
  const navigate = getRouterNavigator()
  navigate('/setup/roads')
}
