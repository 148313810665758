import { mapAtom } from '../../atoms/map/map'
import { findLayer } from '../utils/findLayer'

import { mapStylesUpdateDeviceEventPointsFeatures } from './utils/deviceEventPoints/mapStylesUpdateDeviceEventPointsFeatures'

export const createUpdateDeviceEventFeaturesMapStyleFromChangelog = (
  markers: uui.domain.ui.map.markers.MapMarkers['deviceEvent'],
  selection: Set<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) => {
  // ATTENTION: Application of Map Style changes will not be cached if a delayed rendering mechanism will be implemented
  // After the whole set of delayed marker changelogs is applied a full refresh of Map Styles will be required

  return async () => {
    const map = mapAtom.map

    // only the `update` field is populated in a mapStyles changelog
    await mapStylesUpdateDeviceEventPointsFeatures(
      findLayer(map, 'deviceEvent'),
      markers,
      selection,
      breadcrumbTimeRange,
    )
  }
}
