import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    smsButton: translate({ id: 'components.notificationEditor.smsButton' }),
    emailButton: translate({ id: 'components.notificationEditor.emailButton' }),
    invalidSmsMessage: translate({ id: 'components.notificationEditor.invalidSmsMessage' }),
    addRecipientButton: translate({ id: 'components.notificationEditor.addRecipientButton' }),
    invalidEmailMessage: translate({ id: 'components.notificationEditor.invalidEmailMessage' }),
    addRecipientInputMessage: translate({ id: 'components.notificationEditor.addRecipientButton' }),

    discardRecipientButton: translate({
      id: 'components.notificationEditor.discardRecipientButton',
    }),
    duplicatedRecipientMessage: translate({
      id: 'components.notificationEditor.duplicatedRecipientMessage',
    }),
    addRecipientInputSmsPlaceholder: translate({
      id: 'components.notificationEditor.addRecipientInputSmsPlaceholder',
    }),
    addRecipientInputEmailPlaceholder: translate({
      id: 'components.notificationEditor.addRecipientInputEmailPlaceholder',
    }),
  }))

  return api
}
