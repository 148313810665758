import { styled } from '@mui/material'

export const ListItemRow = styled('div', {
  name: 'ListItemRow',
})({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
})
