import styled from 'styled-components'

const LATERAL_PAD = 12

const styledComponent = styled.p.attrs<workwave.P>(() => ({
  className: 'o-date-selector__label',
}))`
  font-size: ${p => p.theme.fonts.$p3};
  position: relative;
  color: ${p => p.theme.colors.$nightRider};
  padding-left: ${LATERAL_PAD / 2}px;
  margin: 0;
  user-select: none;
`

styledComponent.displayName = 'DateSelectorLabel'
export default styledComponent
