import { useCallback, memo } from 'react'

import { AssetListItemProps as Props } from '../../../typings'
import { getLabelFromAsset, getPlaceNameFromAsset } from '../../../utils'

import AssetListText from '../elements/AssetListText'
import AssetListIcon from '../elements/AssetListIcon'
import AssetListItemWrapper from '../elements/AssetListItemWrapper'

const AssetListItem = (props: Props) => {
  const { asset, onClick, selected, id } = props

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick()
    }
  }, [onClick])

  const isPlace = asset.type === 'place'

  return (
    <AssetListItemWrapper
      id={id}
      onClick={handleOnClick}
      selected={selected}
      data-testid="location-editor__asset-list__item-wrapper"
      data-testlabel={asset.type}
    >
      <AssetListIcon {...props} />
      {!isPlace && <AssetListText>{getLabelFromAsset(asset)}</AssetListText>}
      {isPlace && (
        <div
          style={{
            display: 'flex',
            flex: '0 1 auto',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <AssetListText style={{ flex: '0 0 auto', maxWidth: '100%' }}>
            {getPlaceNameFromAsset(asset)}
            <span style={{ padding: '0 4px' }}>-</span>
          </AssetListText>
          <AssetListText style={{ flex: '0 1 auto', width: '100%' }} thin>
            {getLabelFromAsset(asset)}
          </AssetListText>
        </div>
      )}
    </AssetListItemWrapper>
  )
}

export default memo(AssetListItem)
