import type { GridCellParams } from '@mui/x-data-grid-pro'

import { Box } from '@mui/material'

import { useDotColor } from './hooks/useDotColor'
import { useStyles } from './hooks/useStyles'
import { useCrateFormatValue } from './hooks/useFormatValue'

type Props = { params: GridCellParams<uui.domain.client.rm.FormattedData['assignedTo']> }

function AssignedToCellRenderer(props: Props) {
  const {
    params: { value, row },
  } = props
  const orderStepId = row.id as string

  const color = useDotColor(orderStepId)
  const [classes, styles] = useStyles(color || '#FFFFFF')
  const formatValue = useCrateFormatValue()

  return (
    <span className="table-cell-truncate">
      <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
        {color && <div className={classes.dot} style={styles.dot} />}
        {formatValue(value)}
      </Box>
    </span>
  )
}

export function AssignedToCell(params: GridCellParams) {
  return <AssignedToCellRenderer params={params} />
}
