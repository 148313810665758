import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    mixed: translate({ id: 'order.form.bulk.loads.exact.mixed' }),
    undefinedLoad: translate({ id: 'order.form.bulk.loads.exact.undefinedLoad' }),
    removeTooltip: (count: number) =>
      translate({ id: 'order.form.bulk.loads.exact.removeTooltip', values: { count } }),
  }))

  return api
}
