import type { ValidationSummaryItem } from '@workwave-tidal/tidal/form-ui/utils/ValidationSummary'
import type { FormField, FormError } from '@workwave-tidal/tidal/form-fairy'

import { useMemo } from 'react'

import { getStandardPasswordValidations } from './validations/getStandardPasswordValidations'
import { useTexts } from './useTexts'

const specialCharacters = [
  `_`,
  `-`,
  `£`,
  `$`,
  `%`,
  `&`,
  `@`,
  `#`,
  `*`,
  `+`,
  `|`,
  `^`,
  `?`,
  `!`,
  `'`,
  `=`,
  `(`,
  `)`,
]

export function useStandardPasswordValidations<
  FORM_FIELDS extends Record<string, FormField>,
  FORM_ERRORS extends FormError<keyof FORM_FIELDS>,
>(field: keyof FORM_FIELDS) {
  const texts = useTexts()

  return useMemo(() => {
    const validations = getStandardPasswordValidations<FORM_FIELDS, FORM_ERRORS>(
      field,
      specialCharacters,
    )

    const validationSummaryItems = [
      {
        ids: [`${field as string}_too_short`],
        field,
        message: texts.passwordLengthValidation,
      },
      {
        ids: [`${field as string}_got_number`],
        field,
        message: texts.passwordNumberValidation,
      },
      {
        ids: [`${field as string}_got_uppercase_letter`],
        field,
        message: texts.passwordUppercaseValidation,
      },
      {
        ids: [`${field as string}_got_lowercase_letter`],
        field,
        message: texts.passwordLowercaseValidation,
      },
      {
        ids: [`${field as string}_got_special_character`],
        field,
        message: texts.passwordSpecialCharacterValidation(specialCharacters.join('')),
      },
    ] as ValidationSummaryItem<FORM_FIELDS>[]

    return { validations, validationSummaryItems }
  }, [texts, field])
}
