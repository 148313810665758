import { MoreVert } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { Tooltip } from '@/components/primitives/Tooltip'
import { useMenuOptions, MenuOption } from './hooks/useMenuOptions'
import { useTexts } from './hooks/useTexts'

type Props = {
  selectedUsers: uui.domain.client.rm.RouteManagerUser[]
}

export function UserMenu(props: Props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const options = useMenuOptions(props)
  const texts = useTexts()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = useCallback((option: MenuOption) => {
    if (option.action) {
      option.action()
    }

    setAnchorEl(null)
  }, [])

  return (
    <>
      <Tooltip placement="bottom" title={texts.tooltips.more}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          data-testid="user-more-btn"
          data-trackid="user-more-btn"
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map(option => (
          <MenuItem
            data-testid="user-more-item"
            key={option.id}
            onClick={() => handleClose(option)}
          >
            <Typography color={option.color}>{option.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
