import styled from 'styled-components'

const styledComponent = styled.div.attrs<workwave.Div>(() => ({
  className: 'o-alert-device-list-item-wrapper',
}))`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  font-size: ${p => p.theme.fonts.$p3};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 26px;
  padding: 0 13px;

  transition: background 0.3s ease;

  &:hover {
    background: ${p => p.theme.colors.$whiteSmoke};
  }
`

styledComponent.displayName = 'AlertDeviceListItemWrapper'
export default styledComponent
