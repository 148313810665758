import type { OnSelectCallbackParams as VirtualListOnSelectCallbackParams } from '@workwave-tidal/tidal/components/VirtualList'

import { Fragment, useCallback } from 'react'

import { VirtualList } from '@workwave-tidal/tidal/components'

import { H4, WarningCard, Stack } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'
import { useTerritoryListItem } from './TerritoryListItem'

const getItemHeights = () => 24
const listHeight = getItemHeights() * 6

const Block = Fragment

export function Body() {
  const texts = useTexts()
  const {
    status,
    data: { territories, territory, newTerritoryId },
    updateData,
  } = useController()

  const TerritoryListItem = useTerritoryListItem(territory?.id || '')

  const handleSelectTerritory = useCallback(
    ({ item }: VirtualListOnSelectCallbackParams<uui.domain.client.rm.Territory>) =>
      updateData({ newTerritoryId: item.id }),
    [updateData],
  )

  return (
    <Stack space="l">
      <Block>
        <H4 color="$paleSky" weight="$bold" uppercase marginTop={32} marginBottom={8}>
          {texts.territoriesListTitleText}
        </H4>
        {!!territory && (
          <VirtualList
            items={territories}
            listHeight={listHeight}
            RenderItem={TerritoryListItem}
            getItemHeights={getItemHeights}
            onSelect={handleSelectTerritory}
            selectedItemIds={newTerritoryId ? [newTerritoryId] : []}
            testId="territoryModal__listTerritories"
          />
        )}
      </Block>

      {status === 'pending' && (
        <Block>
          <WarningCard
            preset="warning"
            title={texts.transactionWarningCardTitle}
            description={texts.transactionWarningCardDescription}
            Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
          />
        </Block>
      )}
    </Stack>
  )
}
