import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { timezoneUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export const useCreateDate = (source: uui.domain.AcceptedDate) => {
  const userConfig = useSelector(selectUserConfiguration)

  return useMemo(() => timezoneUtils.createDate(userConfig)(source), [userConfig, source])
}
