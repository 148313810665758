import { useCrudSelection } from '@/atoms'

import { useTrafficProfile } from '../../../hooks/useTrafficProfile'

export function useTrafficArea() {
  const trafficProfile = useTrafficProfile()
  const [trafficAreaSelection] = useCrudSelection('trafficArea')

  if (trafficAreaSelection.length !== 1) {
    return
  }

  return trafficProfile.regions.find(r => r.id === trafficAreaSelection[0])
}
