import type { VoiceEventMarker } from '../../../../atoms/voiceEventMarkers/voiceEventMarker'

import { mapAtom } from '../../../../atoms/map/map'
import { findLayer } from '../../../utils/findLayer'

import { refreshVoiceEventFeature } from '../utils/refreshVoiceEventFeature'

export function updateVoiceEventFeature(eventMarker: workwave.DeepReadonly<VoiceEventMarker>) {
  const map = mapAtom.map
  const layer = findLayer(map, 'voiceEvents')
  const feature = layer.getSource()?.getFeatureById(eventMarker.id)

  if (!feature) {
    throw new Error('Feature not found (source is undefined)')
  }

  // update the pin
  refreshVoiceEventFeature(eventMarker, feature)

  return feature
}
