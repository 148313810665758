import type { GridColumns } from '@mui/x-data-grid-pro'

import { useMemo } from 'react'
import { snapshot } from 'valtio'
import { useSelector } from 'react-redux'

import { selectTerritory } from '@/features/domain/territory'
import { ordersGridAtom } from '@/atoms'

import { AssignedToCell } from '../../components/AssignedToCell'
import { AddressCell } from '../../components/AddressCell'
import { NameCell } from '../../components/NameCell'
import { useTexts } from '../../useTexts'

import { createExecutedCustomFieldColumn } from './createExecutedCustomFieldColumn'
import { useGetCommunicatedEtaRangeValue } from './useGetCommunicatedEtaRangeValue'
import { createExecutedLoadColumn } from './createExecutedLoadColumn'
import { createCustomFieldColumn } from './createCustomFieldColumn'
import { useGetScheduleTimeValue } from './useGetScheduleTimeValue'
import { useGetPlannedTimeValue } from './useGetPlannedTimeValue'
import { useGetTimeWindowValue } from './useGetTimeWindowValue'
import { useGetEligibilityValue } from './useGetEligibilityValue'

import { createGenericColumn } from './createGenericColumn'
import { useGetCompanyValue } from './useGetCompanyValue'
import { getShortFieldName } from './getShortFieldName'
import { createLoadColumn } from './createLoadColumn'
import { getValue } from './getValue'

export function useGridColumns(rows: uui.domain.client.rm.FormattedData[]) {
  const getTimeWindowValue = useGetTimeWindowValue()
  const { enableDynamicLoads, enableDynamicCustomFields } = useSelector(selectTerritory)
  const getScheduleTimeValue = useGetScheduleTimeValue()
  const getCommunicatedEtaRangeValue = useGetCommunicatedEtaRangeValue()
  const getPlannedTimeValue = useGetPlannedTimeValue()
  const getCompanyValue = useGetCompanyValue()
  const getEligibilityValue = useGetEligibilityValue()
  const texts = useTexts()

  return useMemo(() => {
    const {
      settings: { columns: atomsColumn },
    } = snapshot(ordersGridAtom)

    const createdColumnIds = new Set<string>()

    // Fixed columns
    const columns = atomsColumn.reduce<GridColumns>((acc, colDef) => {
      createdColumnIds.add(colDef.field)

      switch (colDef.field) {
        case 'formattedData.name':
          acc.push({
            valueGetter: getValue,
            headerName: texts[getShortFieldName(colDef.field)],
            renderCell: NameCell,
            field: colDef.field,
            hide: false,
            width: colDef.width || 200,
            filterable: false,
          })
          break

        case 'formattedData.orderId':
          acc.push({
            headerName: texts[getShortFieldName(colDef.field)],
            valueGetter: getValue,
            field: colDef.field,
            hide: false,
            width: colDef.width || 200,
            filterable: false,
          })
          break

        case 'formattedData.address':
          acc.push({
            headerName: texts[getShortFieldName(colDef.field)],
            renderCell: AddressCell,
            valueGetter: getValue,
            field: colDef.field,
            hide: colDef.hide,
            width: colDef.width || 200,
            filterable: false,
          })
          break

        case 'formattedData.assignedTo':
          acc.push({
            headerName: texts[getShortFieldName(colDef.field)],
            renderCell: AssignedToCell,
            valueGetter: getValue,
            field: colDef.field,
            hide: colDef.hide,
            width: colDef.width || 200,
            filterable: false,
          })
          break

        case 'formattedData.scheduleTime':
          acc.push({
            headerName: texts[getShortFieldName(colDef.field)],
            width: colDef.width || 200,
            valueGetter: getScheduleTimeValue,
            field: colDef.field,
            hide: colDef.hide,
            filterable: false,
          })
          break

        case 'formattedData.company':
          acc.push({
            headerName: texts[getShortFieldName(colDef.field)],
            width: colDef.width || 200,
            valueGetter: getCompanyValue,
            field: colDef.field,
            hide: colDef.hide,
            filterable: false,
          })
          break

        case 'formattedData.plannedTime':
          acc.push({
            headerName: texts[getShortFieldName(colDef.field)],
            width: colDef.width || 200,
            valueGetter: getPlannedTimeValue,
            field: colDef.field,
            hide: colDef.hide,
            filterable: false,
          })
          break

        case 'formattedData.communicatedEtaRanges.twoDaysNotification':
        case 'formattedData.communicatedEtaRanges.sameDayNotification':
        case 'formattedData.communicatedEtaRanges.oneDayNotification':
          acc.push({
            headerName: texts[getShortFieldName(colDef.field)],
            width: colDef.width || 200,
            valueGetter: getCommunicatedEtaRangeValue,
            field: colDef.field,
            hide: colDef.hide,
            filterable: false,
          })
          break

        case 'formattedData.timeWindow1':
        case 'formattedData.timeWindow2':
          acc.push({
            headerName: texts[getShortFieldName(colDef.field)],
            width: colDef.width || 200,
            valueGetter: getTimeWindowValue,

            field: colDef.field,
            hide: colDef.hide,
            filterable: false,
          })
          break

        case 'formattedData.eligibility':
          acc.push({
            headerName: texts[getShortFieldName(colDef.field)],
            width: colDef.width || 200,
            valueGetter: getEligibilityValue,
            field: colDef.field,
            hide: colDef.hide,
            filterable: false,
          })
          break

        case 'formattedData.shopifyOrderName':
        case 'formattedData.requiredVehicle':
        case 'formattedData.addressCheck':
        case 'formattedData.requiredTags':
        case 'formattedData.serviceTime':
        case 'formattedData.bannedTags':
        case 'formattedData.orderType':
        case 'formattedData.idleTime':
        case 'formattedData.barcodes':
        case 'formattedData.priority':
        case 'formattedData.pairId':
        case 'formattedData.status':
        case 'formattedData.stopNo':
        case 'formattedData.notes':
        case 'formattedData.phone':
        case 'formattedData.email':
          acc.push({
            headerName: texts[getShortFieldName(colDef.field)],
            width: colDef.width || 200,
            valueGetter: getValue,
            field: colDef.field,
            hide: colDef.hide,
            filterable: false,
          })
          break

        default:
          acc.push(
            createGenericColumn(colDef, texts, enableDynamicLoads, enableDynamicCustomFields),
          )
      }
      return acc
    }, [])

    // Dynamic columns
    for (const row of rows) {
      // ----------------------------------------------
      // Loads
      // ----------------------------------------------

      for (const loadName in row.loads) {
        const columnName = `formattedData.loads.${loadName}`
        // If the column has already been pushed continue
        if (createdColumnIds.has(columnName)) continue

        // Try to find if the column is stored in the atom
        const column = atomsColumn.find(col => col.field === loadName)

        columns.push(
          createLoadColumn(
            {
              field: column?.field ?? columnName,
              hide: column?.hide,
              width: column?.width,
            },
            texts,
            enableDynamicLoads,
          ),
        )
        createdColumnIds.add(columnName)
      }

      if (enableDynamicLoads) {
        for (const executedLoadName in row.executedLoads) {
          const columnName = `formattedData.executedLoads.${executedLoadName}`
          // If the column has already been pushed continue
          if (createdColumnIds.has(columnName)) continue

          // Try to find if the column is stored in the atom
          const column = atomsColumn.find(col => col.field === executedLoadName)

          columns.push(
            createExecutedLoadColumn(
              {
                field: column?.field ?? columnName,
                hide: column?.hide,
                width: column?.width,
              },
              texts,
            ),
          )
          createdColumnIds.add(columnName)
        }
      }

      // ----------------------------------------------
      // Custom fields
      // ----------------------------------------------
      for (const customField in row.customFields) {
        const columnName = `formattedData.customFields.${customField}`
        // If the column has already been pushed continue
        if (createdColumnIds.has(columnName)) continue

        // Try to find if the column is stored in the atom
        const column = atomsColumn.find(col => col.field === customField)

        columns.push(
          createCustomFieldColumn(
            {
              field: column?.field ?? columnName,
              hide: column?.hide,
              width: column?.width,
            },
            texts,
            enableDynamicCustomFields,
          ),
        )
        createdColumnIds.add(columnName)
      }

      if (enableDynamicCustomFields) {
        for (const customField in row.executedCustomFields) {
          const columnName = `formattedData.executedCustomFields.${customField}`
          // If the column has already been pushed continue
          if (createdColumnIds.has(columnName)) continue

          // Try to find if the column is stored in the atom
          const column = atomsColumn.find(col => col.field === customField)

          columns.push(
            createExecutedCustomFieldColumn(
              {
                field: column?.field ?? columnName,
                hide: column?.hide,
                width: column?.width,
              },
              texts,
            ),
          )
          createdColumnIds.add(columnName)
        }
      }
    }

    return columns
  }, [
    enableDynamicCustomFields,
    getScheduleTimeValue,
    getPlannedTimeValue,
    enableDynamicLoads,
    getCompanyValue,
    texts,
    rows,
    getTimeWindowValue,
    getCommunicatedEtaRangeValue,
    getEligibilityValue,
  ])
}
