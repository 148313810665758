import type { SelectionAsRecord } from '@/components/Calendar/typings'
import { isSameDay } from 'date-fns/esm'

interface Params {
  date: Date
  dateAsString: string
  selectionStart?: Date
  nextDayAsString: string
  selection: SelectionAsRecord
  edgeOfSelection?: SelectionAsRecord
}

/**
 * Compute some date useful
 */
export function getSelectionData(params: Params) {
  const { date, selection, edgeOfSelection, nextDayAsString, selectionStart, dateAsString } = params

  const selected = selection.hasOwnProperty(dateAsString)
  const isSelectionStart = !!selectionStart && isSameDay(selectionStart, date)
  const isOnTheEdge = !!edgeOfSelection && edgeOfSelection.hasOwnProperty(dateAsString)

  const isSelectionEnd =
    isOnTheEdge && !selection.hasOwnProperty(nextDayAsString) && Object.keys(selection).length >= 1

  return { selected, isOnTheEdge, isSelectionStart, isSelectionEnd }
}
