import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'
import { findFeature } from '../../findFeature'

import { findRoadSegmentMarker } from './findRoadSegmentMarker'
import { updateRoadSegmentFeature } from './updateRoadSegmentFeature'
import { createRoadSegmentFeature } from './createRoadSegmentFeature'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Update the rendered style and position for all the RoadSegments marked to be updated in a Changelog coming form the data-layer
 */
export async function changelogUpdateRoadSegmentFeatures(
  layer: VectorLayer<VectorSource>,
  routingProfiles: uui.domain.ui.map.markers.MapMarkers['roadSegment'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['roadSegment'],
  selection: Set<string>,
  routingProfileIds: IterableIterator<string>,
) {
  nonblockingRender.reset()

  for (const profileId of routingProfileIds) {
    for (const markerId in routingProfiles[profileId]) {
      await nonblockingRender.next()

      const marker = findRoadSegmentMarker(routingProfiles[profileId], markerId)
      const feature = findFeature(layer, marker.id)
      const selected = selection.has(markerId)

      if (feature) {
        updateRoadSegmentFeature(mapStyles, marker, feature, selected)
      } else {
        layer.getSource()?.addFeature(createRoadSegmentFeature(marker, mapStyles, selected))
      }
    }
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
