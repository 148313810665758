import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Freeze(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M9.578 1.586c.04-.13.159-.22.293-.224.076-.003.15.02.208.067.77.585 1.457 1.273 2.04 2.045.074.09.091.213.047.321-.045.107-.143.183-.259.196-.116.014-.23-.037-.297-.132-.548-.725-1.193-1.372-1.917-1.921-.108-.081-.155-.222-.115-.352zm-2.306.352c.039-.142.17-.24.317-.237.053.002.106.016.152.043 1.543.866 2.845 2.107 3.782 3.602.095.15.049.347-.1.441-.15.094-.346.048-.44-.102-.881-1.404-2.105-2.572-3.555-3.383-.13-.071-.193-.221-.156-.364zm-2.05.382c-.054-.002-.112.002-.17.014-.232.049-.425.236-.482.466-.057.238.02.462.17.611l.33.328 2.7 2.698c.125.125.127.327.002.451-.126.125-.327.125-.453 0L3.25 2.824c-.15-.15-.374-.224-.61-.168-.224.055-.407.238-.462.461-.058.24.018.463.17.612l3.795 3.796c.126.125.126.327 0 .453-.123.124-.325.124-.45 0L2.407 4.693c-.15-.147-.371-.22-.607-.162-.222.055-.404.235-.458.457-.058.238.018.462.167.612l3.736 3.742c.125.126.125.328 0 .452-.125.125-.328.125-.451 0L2.42 7.416l-.324-.326c-.148-.15-.373-.228-.61-.17-.233.054-.42.25-.467.482-.049.232.029.449.177.593l5.651 5.65c.56.56 1.295.798 2.03.798.736 0 1.487-.292 2.047-.853l1.347-1.347c.327-.326.574-.71.735-1.143l.714-2.26c.13-.346.327-.654.588-.916 0 0 .656-.653.873-.872l.22-.227c.173-.174.279-.413.279-.676 0-.529-.43-.958-.958-.958-.267 0-.507.109-.68.285l-2.041 2.04c-.363.36-.993.36-1.354 0L6.09 2.956l-.447-.447c-.109-.11-.257-.18-.422-.19zm-3.777 8.141c.054-.098.156-.162.268-.167.12-.004.233.059.292.163.897 1.552 2.205 2.838 3.773 3.71.104.053.169.158.172.275.003.116-.057.224-.158.283-.1.058-.223.058-.324 0-1.67-.928-3.06-2.295-4.017-3.948-.057-.096-.06-.216-.006-.316z" />
      </svg>
    </IconContainer>
  )
}
