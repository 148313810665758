import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function InvalidFooter() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          testid="export-order-invalid-button"
          variant="contained"
          color="error"
          onClick={close}
        >
          {texts.dismiss}
        </ModalFooterButton>
      }
    />
  )
}
