import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Signature(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 20 19">
        <path d="M17.961.207c-.598.078-1.161.546-1.357 1.08l-1.701 4.646L17.73 6.99l1.702-4.667c.215-.584-.092-1.76-.874-2.047a1.258 1.258 0 0 0-.598-.069zm-2.598.414a.69.69 0 0 0-.53.46l-1.816 4.852a.699.699 0 0 0 1.311.483l1.794-4.852a.699.699 0 0 0-.101-.696.695.695 0 0 0-.658-.247zM4.693 5.105c-.592.075-1.161.37-1.725.874-1.44 1.287-1.825 3.403-2.115 4.875-.106.534-.218 1.36-.138 2.207.04.423.112.86.322 1.265.21.405.583.79 1.08.966.524.186 1.061.146 1.495 0 .434-.147.782-.386 1.104-.598.253-.17.37-.158.575-.276-.106.422-.236.911.138 1.471.216.325.612.613 1.012.621.4.009.64-.16.828-.276.126-.077.195-.135.275-.184.084.176.27.38.483.483.348.173.638.156.828.161.19.006.27.02.276.023.233.104.313.293.782.644a.738.738 0 1 0 .897-1.173c-.259-.192-.489-.54-1.081-.804-.348-.153-.644-.156-.828-.161-.092-.003-.077.005-.115 0-.034-.107-.049-.219-.16-.345a1.141 1.141 0 0 0-.622-.345 1.51 1.51 0 0 0-.988.138c-.15.075-.225.14-.322.207.005-.08.014-.066.069-.3.092-.39.284-.841.16-1.47a.737.737 0 0 0-.666-.576c-1.035-.069-1.756.552-2.346.943a3.751 3.751 0 0 1-.782.414c-.21.072-.339.089-.528.023-.11-.04-.17-.092-.253-.253-.084-.161-.156-.434-.184-.736-.058-.603.051-1.336.138-1.77.284-1.443.767-3.274 1.655-4.07.411-.366.73-.504.943-.53a.592.592 0 0 1 .506.139c.345.264.704 1.031.782 1.862a.74.74 0 0 0 .805.667.74.74 0 0 0 .666-.805C7.553 7.292 7.162 6.2 6.303 5.542a2.234 2.234 0 0 0-1.61-.437zm9.773 1.954l-2.369 6.439c-.186.506-.5 2.892-.069 3.702l-.206.53a.353.353 0 0 0 .666.229l.207-.552c.834-.39 2.01-2.27 2.23-2.874l2.37-6.416-2.83-1.058z" />
      </svg>
    </IconContainer>
  )
}
