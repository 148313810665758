import { schedulerPreferencesAtom } from '../../schedulerPreferences'
import { approvedRouteFilter } from '../filters'
import { project } from '../project'

let lastShowApprovedRoutesValue = schedulerPreferencesAtom.preferences.showApprovedRoutes

export function syncApprovedRoutesVisibility() {
  // If the showApprovedRoutes value hasn't changed, don't do anything
  if (lastShowApprovedRoutesValue === schedulerPreferencesAtom.preferences.showApprovedRoutes)
    return

  // Update the showApprovedRoutes value
  lastShowApprovedRoutesValue = schedulerPreferencesAtom.preferences.showApprovedRoutes

  if (schedulerPreferencesAtom.preferences.showApprovedRoutes) {
    project.eventStore.removeFilter('approvedRouteFilter')
  } else {
    project.eventStore.addFilter(approvedRouteFilter)
  }
}
