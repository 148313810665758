import { useState } from 'react'

import { useSelector } from 'react-redux'
import { useConfigureModalController, useModalController, useCloseModal } from '@/atoms'

import { selectBreadcrumbTimeRange } from '@/features/domain/breadcrumb/cursors'

type ModalState = 'ready' | 'submitting'
interface ModalData {
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange
}

export const modalId = 'setBreadcrumbTimeRange'

export const useController = () => useModalController<ModalData, ModalState>(modalId)

export const useConfigureController = () => {
  const close = useCloseModal()
  const breadcrumbTimeRange = useSelector(selectBreadcrumbTimeRange)

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        breadcrumbTimeRange,
      },
    }),
  }))

  return useConfigureModalController<ModalData, ModalState>(modalId, options)
}
