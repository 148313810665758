import { useFormatCurrency, useFormatSeconds } from '@/hooks'

import { useTexts } from '../useTexts'

const notSetValue = '-'

export const useStats = (depots: uui.domain.client.rm.Depot[]) => {
  const texts = useTexts()
  const depotSetupCost = depots?.[0]?.setupCost ?? 0
  const setupCostValue = useFormatCurrency(depotSetupCost / 100)
  const setupTimeValue = useFormatSeconds(depots?.[0]?.setupTimeSec ?? 0, 'DURATION_HH_MM')

  const setupCost =
    depots.length === 1 && depots[0].setupCost > 0 ? `${setupCostValue}/${texts.each}` : notSetValue

  const setupTime =
    depots.length === 1 && depots[0].setupTimeSec > 0
      ? `${setupTimeValue}/${texts.each}`
      : notSetValue

  return {
    setupCost,
    setupTime,
  }
}
