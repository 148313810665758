import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { Footer, SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { useReadOnly, useStopEditingOnUnmount } from '@/hooks'
import { recurrenceToString, parseRecurrence } from '@/server-data'
import { FormLoading } from '@/components/FormLoading'
import { FormColumn } from '@/components/layout'
import { useEnvironmentQa } from '@/atoms'
import { intl } from '@/intl'

import { Tabs } from '../../../components/Tabs'
import { useFormViewType } from '../../../hooks/useFormViewType'

import { useTexts } from './hooks/useTexts'
import { useActions } from './hooks/useActions'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useOnValidate } from './hooks/useOnValidate'
import { useIsCreating } from './hooks/useIsCreating'
import { useFormInitialValue } from './hooks/useFormInitialValue'
import { useGetFieldsWithError } from './hooks/useGetFieldsWithError'

import { Form } from './Form'

interface Props {
  exception: string
  transactionOpen: boolean
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  vehicleSettings: uui.domain.client.rm.VehicleSettings
}

export function Exception(props: Props) {
  const { unifiedVehicle, vehicleSettings, exception, transactionOpen } = props
  const { onCancel } = useActions(unifiedVehicle)

  const texts = useTexts()
  const qa = useEnvironmentQa()
  const creating = useIsCreating()
  const onValidate = useOnValidate()
  const getFieldsWithError = useGetFieldsWithError()
  const formViewType = useFormViewType(unifiedVehicle)
  const { submitting, onSubmit } = useOnSubmit(unifiedVehicle)
  const initialValues = useFormInitialValue(exception, vehicleSettings)

  useStopEditingOnUnmount()

  const { readonly } = useReadOnly()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <FinalForm<uui.domain.ui.forms.ExceptionFormValues>
        initialValues={initialValues}
        validate={onValidate}
        onSubmit={onSubmit}
        render={(formRenderProps: FormRenderProps<uui.domain.ui.forms.ExceptionFormValues>) => {
          const { form, handleSubmit } = formRenderProps
          const { valid, pristine, errors, values } = form.getState()

          const disableStateInCreation = !valid
          const disableStateInEditing = !valid || pristine
          const saveDisabled =
            readonly || (creating ? disableStateInCreation : disableStateInEditing)

          const fieldsWithError = getFieldsWithError(
            errors as uui.domain.ui.forms.ExceptionFormValuesValidation,
          )

          const recurrence = recurrenceToString(intl.translate)(parseRecurrence(exception)!)

          return (
            <>
              <SecondaryColumnHeader
                title={texts.headerTitle}
                description={recurrence}
                avatar={null}
              >
                <Tabs unifiedVehicle={unifiedVehicle} formViewType={formViewType} disabled />
              </SecondaryColumnHeader>
              <FormContent
                qa={qa}
                testId="vehicleSingleEditException"
                footer={
                  transactionOpen ? (
                    <Footer
                      errorTitle={texts.errorTitle}
                      errors={fieldsWithError}
                      submitting={submitting}
                      primary={{
                        text: texts.save(recurrence, creating),
                        title: texts.saveTitle(valid, readonly, pristine),
                        disabled: saveDisabled,
                        onClick: handleSubmit,
                        className: creating
                          ? 'o-pendo__vehicle-form__create-exception'
                          : 'o-pendo__vehicle-form__update-exception',
                      }}
                      secondary={{
                        disabled: submitting,
                        text: texts.cancel,
                        onClick: onCancel,
                      }}
                    />
                  ) : undefined
                }
              >
                {transactionOpen ? (
                  <Form availability={values.available} initialValues={initialValues} />
                ) : (
                  <FormLoading />
                )}
              </FormContent>
            </>
          )
        }}
      />
    </FormColumn>
  )
}
