import { MapControls } from '@/components/MapControls'
import { HorizontalLayout, VerticalLayout, Grow } from '@/components/layout'
import { useResetOrdersFilterOnMount } from '@/hooks'
import { Navigo } from '@/components/Navigo'

import { TrackingList } from './components/TrackingList'
import { Map } from './components/Map'

export function Tracking() {
  useResetOrdersFilterOnMount()

  return (
    <HorizontalLayout data-testid="tracking-root">
      <TrackingList />
      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls enableInspectPin />
          <Map />
          <Navigo />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
