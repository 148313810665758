import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function CenterOnMap(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 19 19">
        <path d="M12.327 3.528c-1.008 1.499-2.58 2.835-2.648 2.888a.375.375 0 0 1-.467 0c-.067-.053-1.64-1.39-2.647-2.888a.375.375 0 0 1-.018-.39.38.38 0 0 1 .332-.197h1.319c.138-.824.233-2.227.329-2.361.138-.198.54-.336.919-.336.382 0 .834.138.975.336.096.134.138 1.537.276 2.36h1.315a.38.38 0 0 1 .332.199.375.375 0 0 1-.017.389zm-8.615 8.597a.375.375 0 0 1-.389.017.38.38 0 0 1-.198-.332v-1.315c-.823-.138-2.227-.18-2.361-.276-.198-.141-.336-.593-.336-.975 0-.378.138-.778.336-.92.134-.095 1.538-.19 2.361-.328V6.677a.38.38 0 0 1 .198-.332.375.375 0 0 1 .39.018C5.21 7.37 6.546 8.943 6.6 9.01a.375.375 0 0 1 0 .467c-.053.067-1.39 1.64-2.888 2.648zm2.853 2.835c1.007-1.499 2.58-2.835 2.647-2.888a.375.375 0 0 1 .467 0c.067.053 1.64 1.389 2.648 2.888a.375.375 0 0 1 .017.389.38.38 0 0 1-.332.198h-1.315c-.138.823-.18 2.227-.276 2.36-.141.199-.593.337-.975.337-.378 0-.778-.138-.92-.336-.095-.134-.19-1.538-.328-2.361H6.879a.38.38 0 0 1-.332-.198.375.375 0 0 1 .018-.39zm9.201-4.465v1.315a.376.376 0 0 1-.201.332.369.369 0 0 1-.385-.017c-1.5-1.008-2.836-2.58-2.889-2.648a.355.355 0 0 1-.08-.233.36.36 0 0 1 .08-.234c.053-.067 1.39-1.64 2.889-2.647a.369.369 0 0 1 .385-.018.376.376 0 0 1 .201.332v1.319c.824.138 2.227.233 2.362.329.198.138.332.54.332.919 0 .382-.134.834-.332.975-.135.096-1.538.138-2.362.276z" />
      </svg>
    </IconContainer>
  )
}
