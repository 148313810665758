import type { TimeFormat } from '../types'

import { useMemo } from 'react'
import { formatTime } from '../utils'

export function useOptions(optionValues: number[], timeFormat: TimeFormat) {
  return useMemo(
    () =>
      optionValues.map(value => ({
        label: formatTime(value, timeFormat),
        value,
      })),
    [optionValues, timeFormat],
  )
}
