import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    emailTitle: translate({ id: 'sendMessageToDriverModal.email.title' }),
    emailErrorEmpty: translate({ id: 'sendMessageToDriverModal.email.title' }),
    emailErrorFormat: translate({ id: 'sendMessageToDriverModal.email.errors.format' }),
    emailPlaceholder: translate({ id: 'sendMessageToDriverModal.email.placeholder' }),
  }))

  return api
}
