import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Tick(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 8 8">
        <path d="M6.5 1l1.08 1.126-4.714 4.518L.5 4.376 1.58 3.25l1.286 1.232z" />
      </svg>
    </IconContainer>
  )
}
