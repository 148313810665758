import { Text } from '@/local/components'
import { VerticalLayout, HorizontalLayout } from '@/components/layout'
import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'

import { useTexts } from './useTexts'

interface Props {
  trackingData: uui.domain.client.rm.OrderStepTrackingData
}

export function TrackDataBlock(props: Props) {
  const texts = useTexts()

  return (
    <VerticalLayout width="auto" justifyContent="space-between" data-testid="TrackDataBlock">
      <HorizontalLayout height="auto" justifyContent="end">
        <Text size="$p3">{`${texts.reportedTimeIn}:`}&nbsp;</Text>
        <Text size="$p3" weight="$semiBold">
          {props.trackingData.timeInSec !== -1 ? (
            <ConvertTime seconds={props.trackingData.timeInSec} showSeconds={false} />
          ) : (
            texts.none
          )}
        </Text>
      </HorizontalLayout>

      <HorizontalLayout height="auto" justifyContent="end">
        <Text size="$p3">{`${texts.reportedTimeOut}:`}&nbsp;</Text>
        <Text size="$p3" weight="$semiBold">
          {props.trackingData.timeOutSec !== -1 ? (
            <ConvertTime seconds={props.trackingData.timeOutSec} showSeconds={false} />
          ) : (
            texts.none
          )}
        </Text>
      </HorizontalLayout>
    </VerticalLayout>
  )
}
