import type { CrudSelection } from '../typings'

import { snapshot } from 'valtio'
import { subscribeKey } from 'valtio/utils'

import { crudAtom } from '../crud'

export const subscribeCrudSelection = <Category extends keyof CrudSelection = keyof CrudSelection>(
  category: Category,
  subscriber: (selection: workwave.DeepReadonly<CrudSelection[Category]>) => void,
) => subscribeKey(crudAtom.selection, category, selection => subscriber(snapshot(selection)))
