import { type SchedulerProConfig } from '@bryntum/schedulerpro'

import { createGroupFeature } from './group'
import { eventTooltip } from './eventTooltip'
import { eventResize } from './eventResize'
import { headerMenu } from './headerMenu'
import { eventDrag } from './eventDrag'

export const createFeatures = (): SchedulerProConfig['features'] => {
  const group = createGroupFeature()

  return {
    resourceNonWorkingTime: false,
    eventNonWorkingTime: false,
    timeAxisHeaderMenu: false,
    resourceTimeRanges: false,
    eventDragCreate: false,
    scheduleTooltip: false,
    dependencyEdit: false,
    nonWorkingTime: false,
    eventTooltip,
    scheduleMenu: false,
    dependencies: false,
    stickyEvents: false,
    cellTooltip: true,
    eventResize,
    headerMenu,
    mergeCells: false,
    headerZoom: true,
    timeRanges: true,
    eventDrag,

    eventEdit: false,
    eventMenu: false,
    cellEdit: false,
    cellMenu: false,
    taskEdit: false,
    group,
  }
}
