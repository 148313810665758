import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectStartOfToday } from '@/features/domain/user'
import { useMainSelection } from '@/atoms'
import { selectVehicles } from '@/features/domain/vehicle'
import { selectDrivers } from '@/features/domain/driver'
import { getDriver } from '@/server-data'

export const useDriverFromSelectedVehicle = () => {
  const [selection] = useMainSelection('unifiedVehicles')
  const driversMap = useSelector(selectDrivers)
  const vehicles = useSelector(selectVehicles)
  const today = useSelector(selectStartOfToday)

  const selectedVehicles = useMemo(
    () =>
      selection.reduce<uui.domain.client.UnifiedVehicle[]>((acc, uvId) => {
        if (!vehicles[uvId]) return acc

        acc.push(vehicles[uvId])

        return acc
      }, []),
    [vehicles, selection],
  )

  if (selectedVehicles.length !== 1 || !selectedVehicles[0].hasRoutingLicense) return undefined

  return getDriver(driversMap, selectedVehicles[0], today)
}
