import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, resetCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectDepotsListStructure } from '@/features/domain/lists'

export const useDataForList = () => {
  const { setEditing } = useEditingStateWithCtx('depot')
  const structure = useSelector(selectDepotsListStructure)
  const [selection, setSelection] = useCrudSelection('depots')

  const lastSelectedDepot = selection.length > 0 ? selection[selection.length - 1] : undefined

  const setCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  const deselectAll = () => resetCrudSelection('depots')

  return {
    structure,
    setCreate,
    setEditing,
    lastSelectedDepot,
    selectDepots: setSelection,
    deselectAll,
  }
}
