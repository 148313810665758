import type { FormProps } from '../types'

import { NotificationForm } from '../../../../../../notificationForms/sections/notifications/components/NotificationForm'
import { NotificationView } from '../../../../../../notificationForms/sections/notifications/components/NotificationView'

import { useFormStateController } from '../../hooks/useFormStateController'
import { CompletedNotificationView } from './components/CompletedNotificationView'

import { useCompletedNotificationSubmit } from './hooks/useCompletedNotificationSubmit'
import { useCompletedNotificationConfig } from './hooks/useCompletedNotificationConfig'
import { useCompletedNotificationValues } from './hooks/useCompletedNotificationValues'
import { useCompletedNotificationToggle } from './hooks/useCompletedNotificationToggle'
import { useTexts } from './useTexts'

const noop = () => undefined

/**
 * Renders the Completed notification form providing all the domain data and apis.
 */
export function CompletedNotification(props: FormProps) {
  const { inEditing, setEditingState } = props

  const values = useCompletedNotificationValues()

  const { toggleDescription, onToggle } = useCompletedNotificationToggle(values)
  const { edit, cancel } = useFormStateController(setEditingState)
  const onSubmit = useCompletedNotificationSubmit()
  const config = useCompletedNotificationConfig()
  const texts = useTexts()

  // Render the Form inside a FormRoot component that will expose the FormState API through a React.Context
  if (inEditing) {
    return (
      <NotificationForm
        subtitle={texts.description}
        notification="completed"
        onSubmit={onSubmit}
        title={texts.title}
        cancel={cancel}
        values={values}
        config={config}
        initHook={noop}
      />
    )
  }

  return (
    <NotificationView
      Form={<CompletedNotificationView values={values} />}
      toggleSubtitle={toggleDescription}
      subtitle={texts.description}
      notification="completed"
      onToggle={onToggle}
      title={texts.title}
      values={values}
      onEdit={edit}
      available
    />
  )
}
