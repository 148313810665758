import type { ReactElement } from 'react'
import type { Cancel } from 'axios'

import { isCancel } from 'axios'
import { IntlProvider } from 'react-intl'
import { useState, useEffect, useRef } from 'react'

import { useJournal } from '@/store'
import { IntlManager } from '@/intl'
import { useNavigate } from '@/routing'
import { useAppLanguage } from '@/atoms'
import { intlMessagesLoader } from '@/services'

/**
 * Loads the localized strings, both when the app starts and when the language changes.
 */
export function AppIntlProvider(props: Props) {
  const [error, setError] = useState<Error | undefined>()
  const [messages, setMessages] = useState<Record<string, string> | null>(null)
  const [{ language, nextLanguage }, setAppLanguage] = useAppLanguage()

  const api = useRef({
    navigateToError: useNavigate<uui.routing.Error>(true),
    journal: useJournal(),
    setAppLanguage,
  })

  // loads the next language messages
  useEffect(() => {
    let cancelLoading: () => void | undefined
    const executor = async () => {
      if (!nextLanguage) return

      const { setAppLanguage } = api.current
      const loader = intlMessagesLoader(nextLanguage)
      cancelLoading = loader.cancel

      try {
        setMessages(await loader.load())
        // avoiding flashing of the 'loading-language' page since the JSON loading is usually fast
        await delay(1000)
        setAppLanguage({ language: nextLanguage, nextLanguage: null })
      } catch (e) {
        if (!isCancel(e)) {
          setError(e)
        }
      }
    }
    executor()

    return () => {
      cancelLoading?.()
    }
  }, [nextLanguage])

  // triggers the first language loading
  useEffect(() => {
    if (!messages) {
      const { setAppLanguage } = api.current
      setAppLanguage(prevAppLanguage => ({
        ...prevAppLanguage,
        nextLanguage: prevAppLanguage.language,
      }))
    }
  }, [messages])

  useEffect(() => {
    const { navigateToError, journal } = api.current
    if (error) {
      console.warn('Intl loading Error', error)
      journal.main(`AppIntlProvider - Impossible to load language`, { info: error }, 'error')
      navigateToError('500')
    }
  }, [error])

  if (error || !messages) {
    return null
  }

  return (
    <IntlProvider defaultLocale="en" locale={language} messages={messages}>
      <IntlManager>{props.children}</IntlManager>
    </IntlProvider>
  )
}

type Props = {
  children: ReactElement | null
}

const delay = (delay: number) => new Promise(resolve => setTimeout(resolve, delay))

/**
 * Check if the error is an Axios Cancel object. The Cancel signature says that `message` always
 * exists and it's a string but that's not true since cancelling a request without a message results
 * in an `undefined` message. A Cancel object has not the `hasOwnPropertyKey` function so you
 * cannot leverage it neither. You can play with it here https://codesandbox.io/s/checking-promise-completion-on-axios-cancellation-lbl5j
 */
export function isAxiosCancel(error: any): error is Cancel {
  const e = error as Cancel
  const keys = Object.keys(e)
  return keys.length > 0 && keys[0] === 'message'
}
