import { type DomClassList, type EventModel } from '@bryntum/schedulerpro'
import { selectOrderStepsListOptions, selectOrderStepsListStructure } from '@/features/domain/lists'
import { store } from '@/store'
import { getProject } from '../project'

let lastQuery: string | undefined = undefined
let lastList: uui.domain.ui.list.RenderItem[] | undefined = undefined

export function syncProjectFilter() {
  const project = getProject()

  const state = store.getState()
  const query = selectOrderStepsListOptions(state).search.query
  const list = selectOrderStepsListStructure(state).list

  // If the query and list haven't changed, don't do anything
  if (lastQuery === query && lastList === list) return

  // Update the query and list
  lastQuery = query
  lastList = list

  // Update the filter
  const visibleOrderSteps = new Set<string>(list as string[])

  project.eventStore.forEach(
    (event: EventModel) => {
      const filterActive = query !== ''

      const atDepot =
        (event.getData('atDepot') as uui.domain.client.rm.SchedulerEvent['atDepot']) ?? false

      const type = event.getData('type') as uui.domain.client.rm.SchedulerEvent['type']
      const cls = event.cls as DomClassList

      switch (type) {
        case 'pickup':
        case 'delivery':
          // If the event is at depot, we need to check if any of the steps are visible
          if (atDepot) {
            const steps =
              (event.getData('steps') as uui.domain.client.rm.SchedulerEvent['steps']) ?? []

            const gotAtLeastOneVisibleStep = steps.some(step =>
              visibleOrderSteps.has(step.id.replace('-approved', '')),
            )
            if (gotAtLeastOneVisibleStep) {
              cls.remove('filtered')
            } else {
              cls.add('filtered')
            }
          } else {
            if (visibleOrderSteps.has((event.id as string).replace('-approved', '') as string)) {
              cls.remove('filtered')
            } else {
              cls.add('filtered')
            }
          }
          break

        default:
          // If event is not pickup or delivery, we need to check if it is visible
          if (filterActive) {
            cls.add('filtered')
          } else {
            cls.remove('filtered')
          }
      }
    },
    { includeFilteredOutRecords: true, includeCollapsedGroupRecords: true },
  )
}
