import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectTrafficTimeWindows } from '@/features/domain/traffic'
import { timeWindowUtils } from '@/utils'

export function useTimeWindowLabels() {
  const timeWindows = useSelector(selectTrafficTimeWindows)

  return useMemo(() => {
    const firstTimeWindowText = timeWindows[0] ? timeWindowUtils.toString(timeWindows[0]) : ''
    const secondTimeWindowText = timeWindows[1] ? timeWindowUtils.toString(timeWindows[1]) : ''

    return [firstTimeWindowText, secondTimeWindowText]
  }, [timeWindows])
}
