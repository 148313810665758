import { ValidColor, FlexBox, Text } from '@/local/components'

type Props = {
  backgroundColor?: ValidColor
  color?: ValidColor
  children?: string
  title?: string
}

export function Tag(props: Props) {
  const { backgroundColor = '$pureWhite', color, title = '' } = props

  return (
    <FlexBox
      tint={backgroundColor}
      backgroundAlpha={0.1}
      title={title}
      padX={8}
      padY={2}
      borderRadius="4"
    >
      <Text size="$h5" color={color} weight="$semiBold" uppercase>
        {props.children}
      </Text>
    </FlexBox>
  )
}
