import type { TimerState } from '../../../hooks/useTransactionTimer'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '@/store'
import {
  goToNextRevision,
  commitTransaction,
  rollbackTransaction,
  goToPreviousRevision,
} from '@/features/domain/transaction'
import { setTransactionResolution } from '@/components/Footer/hooks/useTransactionResolution'

import { useIsLoading } from '@/atoms'
import { selectData } from '../cursors/selectData'

export const useTransactionControls = (transactionState: TimerState, onResetTimer: () => void) => {
  const dispatch = useAppDispatch()

  const { prevRevision, nextRevision } = useSelector(selectData)
  const serverActionPrevented = useIsLoading()

  const ready = transactionState === 'ready'
  const paused = transactionState === 'paused'

  const handleOnUndo = useCallback(() => {
    dispatch(goToPreviousRevision())
    onResetTimer()
  }, [dispatch, onResetTimer])

  const handleOnRedo = useCallback(() => {
    dispatch(goToNextRevision())
    onResetTimer()
  }, [dispatch, onResetTimer])

  const handleOnRollback = useCallback(() => {
    setTransactionResolution('rollback')
    dispatch(rollbackTransaction())
  }, [dispatch])

  const handleOnResetTimer = useCallback(() => {
    onResetTimer()
  }, [onResetTimer])

  const handleOnApply = useCallback(() => {
    setTransactionResolution('commit')
    dispatch(commitTransaction())
  }, [dispatch])

  return {
    handleOnApply,
    handleOnRedo,
    handleOnResetTimer,
    handleOnRollback,
    handleOnUndo,

    disableSave: paused || ready || serverActionPrevented,
    disableRedo: nextRevision < 0 || serverActionPrevented,
    disableUndo: prevRevision < 0 || serverActionPrevented,
    disableRollback: paused || ready || serverActionPrevented,
  }
}
