import styled from 'styled-components'
import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from 'react'

import { WithTheme } from '@/local/components'

export const styles = (props: WithTheme<unknown>) => `
  width: 100%;
  height: 100%;
  outline: 0;
  border-width: 0;
  background: transparent;
  padding: 0;
  color: inherit;

  font-size: ${props.theme.fonts.$p2};
  font-weight: ${props.theme.weights.$semiBold};

  &::placeholder {
    color: ${props.theme.colors.$silver};
  }
`

export interface Props {
  type?: string
  name?: string
  value?: string | number | string[] | undefined
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>
  placeholder?: string
  readOnly?: boolean
  disabled?: boolean
}

const BaseInput = styled.input<Props>`
  ${styles};
`

BaseInput.displayName = 'BaseInput'

export default BaseInput
