import { useState, useEffect, useCallback } from 'react'

import { useJournal } from '@/store'

import { useOnTimerReset } from './useOnTimerReset'

type Options = {
  autoStart?: boolean
  duration?: number
  frequency?: number
}

type Timer = {
  elapsed: number
  state: TimerState
}

export type TimerState = 'ready' | 'running' | 'paused' | 'expired'

const tags = ['transaction', 'transaction-timer']
export const useTransactionTimer = (options: Options) => {
  const { autoStart = false, duration = 2 * 60, frequency = 1000 } = options

  const [timer, setTimer] = useState<Timer>({
    elapsed: 0,
    state: autoStart ? 'running' : 'ready',
  })

  const journal = useJournal()

  useEffect(() => {
    if (timer.state !== 'running') return

    let effectCleaned = false

    const executeTimer = () =>
      window.setTimeout(() => {
        if (effectCleaned) return

        setTimer(prevTimer => {
          const nextValue = prevTimer.elapsed + 1

          if (duration >= nextValue) {
            timeout = executeTimer()
            return { ...timer, elapsed: nextValue }
          }

          return { ...timer, state: 'expired' }
        })
      }, frequency)

    let timeout = executeTimer()

    return () => {
      window.clearTimeout(timeout)
      effectCleaned = true
    }
  }, [timer, duration, frequency])

  const pause = useCallback(
    () =>
      setTimer(prevTimer => {
        if (prevTimer.state === 'running') return { ...prevTimer, state: 'paused' }

        journal.main(`Trying to pause the transaction timer while is not running`, { tags }, 'warn')
        return prevTimer
      }),
    [journal],
  )

  const resume = useCallback(
    () =>
      setTimer(prevTimer => {
        if (prevTimer.state !== 'running') return { ...prevTimer, state: 'running' }

        journal.main(
          `Trying to resume the transaction timer while is already running `,
          { tags },
          'warn',
        )

        return prevTimer
      }),
    [journal],
  )

  const start = useCallback(
    () =>
      setTimer(prevTimer => {
        if (prevTimer.state !== 'running') return { ...prevTimer, state: 'running' }

        journal.main(
          `Trying to start the transaction timer while is already running `,
          { tags },
          'warn',
        )

        return prevTimer
      }),
    [journal],
  )

  const reset = useCallback(() => setTimer(prevTimer => ({ ...prevTimer, elapsed: 0 })), [])
  const stop = useCallback(() => setTimer({ state: 'ready', elapsed: 0 }), [])

  useOnTimerReset(reset)
  return {
    pause,
    resume,
    start,
    reset,
    stop,
    progress: Math.round(timer.elapsed + Number.EPSILON) / duration,
    ...timer,
  }
}
