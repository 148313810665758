import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'setup.territory.header.title' }),
    description: translate({ id: 'setup.territory.header.description' }),
    timezone: translate({ id: 'setup.territory.timezone' }),
  }))

  return api
}
