import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function ShowLabelVehicle(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 20 22">
        <path
          d="M9.593.482c.437.002 7.82-.125 8.685.74.865.865.746 8.41.74 8.863a9.44 9.44 0 0 0-2.769-6.834A9.437 9.437 0 0 0 9.593.482z"
          fill="rgb(195, 195, 195)"
        />
        <path
          d="M15.365 16.154A2.995 2.995 0 0 0 13 15H6c-.991 0-1.87.48-2.416 1.222l-.155-.15a8.5 8.5 0 1 1 11.936.082z"
          fill="rgb(48, 48, 48)"
        />
        <path
          d="M6 16h7a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2zm1.162 3.182a.46.46 0 0 0 .13.336.43.43 0 0 0 .32.132.42.42 0 0 0 .318-.132.467.467 0 0 0 .126-.336.467.467 0 0 0-.126-.336.42.42 0 0 0-.318-.132.43.43 0 0 0-.32.132.46.46 0 0 0-.13.336zm1.998 0a.46.46 0 0 0 .13.336.43.43 0 0 0 .32.132.42.42 0 0 0 .318-.132.467.467 0 0 0 .126-.336.467.467 0 0 0-.126-.336.42.42 0 0 0-.318-.132.43.43 0 0 0-.32.132.46.46 0 0 0-.13.336zm1.998 0a.46.46 0 0 0 .13.336.43.43 0 0 0 .32.132.42.42 0 0 0 .318-.132.467.467 0 0 0 .126-.336.467.467 0 0 0-.126-.336.42.42 0 0 0-.318-.132.43.43 0 0 0-.32.132.46.46 0 0 0-.13.336z"
          fill="rgb(151, 151, 151)"
        />
      </svg>
    </IconContainer>
  )
}
