import { useEffect, useState } from 'react'

export function useMainWindowVisible() {
  const [mainWindowVisible, setMainWindowVisible] = useState(true)

  useEffect(() => {
    const onVisibilityChange = () => {
      setMainWindowVisible(isMainWindowVisible())
    }

    window.addEventListener('visibilitychange', onVisibilityChange)

    return () => window.removeEventListener('visibilitychange', onVisibilityChange)
  }, [])

  return mainWindowVisible
}

function isMainWindowVisible() {
  return document.visibilityState === 'visible'
}
