import { useCallback } from 'react'
import { addSeconds } from 'date-fns/esm'

import { dateUtils } from '@/utils'
import { FlexBox } from '@/local/components'
import { Dropdown } from '@/components/Dropdown'
import { ReadonlyLabel, ReadonlyFieldRow, ReadonlyFieldRowPart } from '@/forms-legacy'
import { useFormatDateTime } from '@/hooks'
import { FormatDateTime } from '@/components/smartUtils/time/FormatDateTime'

import { SingleBarcodeProps, BarcodeStatusOption } from '../typings'

export const SingleStatus = (props: SingleBarcodeProps) => {
  const { value, onChange, options, disabled } = props
  const podDate = addSeconds(dateUtils.getDateAtMidnight(), value.sec)

  const title = useFormatDateTime(podDate)

  const handleOnChange = useCallback(
    (item: BarcodeStatusOption) => {
      onChange({
        ...value,
        barcodeStatus: item.id,
      })
    },
    [onChange, value],
  )

  return (
    <FlexBox w="100%" column padding={'0 0 10px'}>
      <ReadonlyFieldRow style={{ justifyContent: 'space-between' }}>
        <ReadonlyFieldRowPart primary>
          <ReadonlyLabel ellipsis truncateFromLeft>
            {value.barcode}
          </ReadonlyLabel>
        </ReadonlyFieldRowPart>
        {value.sec > 0 && (
          <ReadonlyFieldRowPart>
            <ReadonlyLabel small title={title}>
              <FormatDateTime source={podDate} />
            </ReadonlyLabel>
          </ReadonlyFieldRowPart>
        )}
      </ReadonlyFieldRow>
      <Dropdown
        onChange={handleOnChange}
        options={options}
        disabled={disabled}
        maxListHeight={112}
      />
    </FlexBox>
  )
}
