import { PureComponent } from 'react'

import { HorizontalRuler, TextField, EligibilityPickerField } from '@/forms-legacy'

import * as OrderTexts from '../../../../../intl'

import OrderContainer from './OrderContainer'

import PriorityField from './components/PriorityField'
import OrderTypeField from './components/OrderTypeField'
import VehicleAssignmentField from './components/VehicleAssignmentField'
import LoadsListField from './components/LoadsListField'
import { CompanyField } from './components/CompanyField'

import { Props } from './typings'

export default class Order extends PureComponent<Props> {
  render() {
    return (
      <OrderContainer>
        <TextField
          name="order.name"
          testid="order-name"
          label={OrderTexts.getFieldLabelNameText()}
        />
        <HorizontalRuler />

        <CompanyField />
        <HorizontalRuler />

        <EligibilityPickerField
          name="order.eligibility"
          testid="eligibility"
          label={OrderTexts.geFieldLabelEligibilityText()}
        />
        <HorizontalRuler />

        <LoadsListField {...this.props} />
        <HorizontalRuler />

        <VehicleAssignmentField {...this.props} />
        <HorizontalRuler />

        <PriorityField {...this.props} />
        <HorizontalRuler />

        <OrderTypeField {...this.props} />
        <HorizontalRuler />
      </OrderContainer>
    )
  }
}
