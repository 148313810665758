import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectDevicesNotAssignedToRmVehicles } from '@/features/domain/device'

export const useDevicesRoot = () => {
  const { editing } = useEditingStateWithCtx('device')
  const [selection] = useCrudSelection('devices')

  const devices = useSelector(selectDevicesNotAssignedToRmVehicles)

  const selectedDevices = useMemo(
    () => getSelectedDevices(selection, devices),
    [selection, devices],
  )

  return {
    devices,
    selectedDevices,
    editing,
    selection,
  }
}

const getSelectedDevices = (
  selection: workwave.DeepReadonly<string[]>,
  s: Record<string, uui.domain.client.gps.wwgps.DeviceInfo>,
): uui.domain.client.gps.wwgps.DeviceInfo[] => {
  return selection.reduce<uui.domain.client.gps.wwgps.DeviceInfo[]>((acc, id) => {
    if (s[id]) {
      acc.push(s[id])
    }
    return acc
  }, [])
}
