import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function PolylineHide(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 20 17">
        <path
          fillRule="evenodd"
          fillOpacity="0.48"
          clipRule="evenodd"
          d="M11.5 3c.8284 0 1.5-.67157 1.5-1.5 0-.828427-.6716-1.5-1.5-1.5S10 .671573 10 1.5c0 .82843.6716 1.5 1.5 1.5Zm1.0938.19522.8334.66666.3123-.39043-.8333-.66667-.3124.39044Zm-1.0005 1.32037.0625-1-.4991-.03118-.0625 1 .4991.03118Zm2.6672.01296.8333.66667.3124-.39044-.8334-.66666-.3123.39043Zm-2.7922 1.98704.0625-1-.4991-.03118-.0625 1 .4991.03118Zm-.125 2 .0625-1-.4991-.03118-.0625 1 .4991.03118ZM6.125 10.25h1.08333v-.5H6.125v.5Zm2.16667 0H9.375v-.5H8.29167v.5Zm-4.60973 1.9451.41667-.8333-.44722-.2236-.41666.8333.44721.2236Zm-.82859 1.6572.41192-.8238-.44721-.2236-.29778.5955C2.2526 13.1519 1.89404 13 1.5 13c-.828427 0-1.5.6716-1.5 1.5S.671573 16 1.5 16c.82843 0 1.5-.6716 1.5-1.5 0-.232-.05265-.4516-.14665-.6477ZM18 6.5c0 .82843-.6716 1.5-1.5 1.5S15 7.32843 15 6.5 15.6716 5 16.5 5s1.5.67157 1.5 1.5ZM11.5 12c.8284 0 1.5-.6716 1.5-1.5 0-.82843-.6716-1.5-1.5-1.5s-1.5.67157-1.5 1.5c0 .8284.6716 1.5 1.5 1.5ZM6 9.5c0 .8284-.67157 1.5-1.5 1.5S3 10.3284 3 9.5C3 8.67157 3.67157 8 4.5 8S6 8.67157 6 9.5Z"
        />
      </svg>
    </IconContainer>
  )
}
