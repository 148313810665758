import { intl } from '@/intl'

import { LocationEditorText, Props, LocationEditStatus } from './typings'
import { defaultQueryMinLength } from './constants'

export default function renderText(field: LocationEditorText, props?: Props): string {
  switch (field) {
    case LocationEditorText.copyCoordinates:
    case LocationEditorText.renameLocation:
    case LocationEditorText.renameLocationDisabledTitleText:
    case LocationEditorText.yourAssets:
    case LocationEditorText.addresses:
    case LocationEditorText.notFoundText:
    case LocationEditorText.notFoundTitle:
    case LocationEditorText.addSearchTitle:
    case LocationEditorText.copied:
    case LocationEditorText.addDetailsToSearch:
    case LocationEditorText.searchingResult:
      return intl.translate({ id: field })

    case LocationEditorText.addSearchText: {
      const count = props ? props.queryMinLength : defaultQueryMinLength
      return intl.translate({ id: field, values: { count } })
    }

    default:
      return field
  }
}

export const getInfoCardTitle = (
  status: LocationEditStatus,
  locationStatus: uui.domain.client.Location['status'],
) => {
  switch (status) {
    case 'outOfTerritory':
      return intl.translate({ id: LocationEditorText.infoCardOutOfTerritoryTitle })

    case 'lowAccuracy':
      switch (locationStatus) {
        case 'LOW_ACCURACY':
          return intl.translate({ id: LocationEditorText.infoCardLowAccuracyTitleLowAccuracy })
        case 'POSTCODE_ISSUE':
          return intl.translate({ id: LocationEditorText.infoCardLowAccuracyTitlePostcodeIssue })
        case 'POSTCODE_LEVEL_ACCURACY':
          return intl.translate({ id: LocationEditorText.infoCardLowAccuracyTitlePostcodeAccuracy })
      }

      return intl.translate({ id: LocationEditorText.infoCardLowAccuracyTitle })

    case 'invalidLatLng':
      return intl.translate({ id: LocationEditorText.infoCardInvalidLatLngTitle })

    default:
      return ''
  }
}

export const getInfoCardText = (
  status: LocationEditStatus,
  locationStatus: uui.domain.client.Location['status'],
) => {
  switch (status) {
    case 'outOfTerritory':
      return intl.translate({ id: LocationEditorText.infoCardOutOfTerritoryText })

    case 'lowAccuracy':
      switch (locationStatus) {
        case 'LOW_ACCURACY':
          return intl.translate({ id: LocationEditorText.infoCardLowAccuracyTextLowAccuracy })
        case 'POSTCODE_ISSUE':
          return intl.translate({ id: LocationEditorText.infoCardLowAccuracyTextPostcodeIssue })
        case 'POSTCODE_LEVEL_ACCURACY':
          return intl.translate({ id: LocationEditorText.infoCardLowAccuracyTextPostcodeAccuracy })
      }

      return intl.translate({ id: LocationEditorText.infoCardLowAccuracyText })

    case 'invalidLatLng':
      return intl.translate({ id: LocationEditorText.infoCardInvalidLatLngText })

    default:
      return ''
  }
}

export const getInfoCardAction = (status?: LocationEditStatus) => {
  switch (status) {
    case 'lowAccuracy':
      return intl.translate({ id: LocationEditorText.infoCardLowAccuracyAction })

    case 'invalidLatLng':
      return intl.translate({ id: LocationEditorText.infoCardInvalidLatLngAction })

    default:
      return ''
  }
}

export const getInfoCardSubText = (status?: LocationEditStatus) => {
  switch (status) {
    case 'outOfTerritory':
      return intl.translate({ id: LocationEditorText.infoCardOutOfTerritorySubText })

    default:
      return ''
  }
}
