import type { SegmentType } from '../../../types'

import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (name?: string) => name ?? translate({ id: 'setup.routing.view.unnamedSegment' }),
    description: (type: SegmentType) => {
      switch (type.type) {
        case 'disabled':
          return translate({ id: 'setup.routing.view.single.subTitle.disabled' })
        case 'blocked':
          return translate({ id: 'setup.routing.view.single.subTitle.blocked' })
        case 'delayed': {
          return translate({ id: 'setup.routing.view.single.subTitle.delayed' })
        }
      }
    },
    exportKML: translate({ id: 'global.exportKML' }),
    delete: translate({ id: 'global.delete' }),
    edit: translate({ id: 'global.edit' }),
  }))

  return api
}
