import { store } from './store'

export type {
  /* AppThunk */
  StoreState,
  AppDispatch,
  Store,
  AsyncThunkApiConfig,
} from './typings'
export { store } from './store'
export const getReduxStore = () => store

export type { DomainProxy } from './domain/domainProxy'
export { domainProxy } from './domain/domainProxy'

export { useAppDispatch } from './hooks/useAppDispatch'
export { useJournal } from './hooks/useJournal'
export { useDomainMessage } from './hooks/useDomainMessage'
