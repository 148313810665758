import { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { selectOrdersMap } from '@/features/domain/order'
import { selectRoutes } from '@/features/domain/route'

export function useRoutesWithExecutionEventsCount(
  routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[],
) {
  const orders = useSelector(selectOrdersMap)
  const extRoutes = useSelector(selectRoutes)

  return useMemo(() => {
    let count = 0

    for (const routeInfo of routeInfos) {
      const extRoute = extRoutes[routeInfo.id]

      // If there's no extended route means that no execution events can exists
      if (!extRoute) continue

      const { steps } = extRoute.route

      const containsAnEventTrack = steps.some(step => {
        if (!step.ref) return false

        const order = orders[step.ref]
        if (!order) return false

        if (order.type === 'p' || order.type === 'pd') {
          const { trackingData } = order.pickup
          return trackingData && !trackingData.isEmpty
        }

        if (order.type === 'd' || order.type === 's' || order.type === 'pd') {
          const { trackingData } = order.delivery
          return trackingData && !trackingData.isEmpty
        }
      })

      if (containsAnEventTrack) {
        count++
      }
    }

    return count
  }, [extRoutes, orders, routeInfos])
}
