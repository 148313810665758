import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    copy: translate({ id: 'rm.sidebar.copy' }),
    addOrder: translate({ id: 'rm.sidebar.addorder' }),
    buildRoutes: translate({ id: 'rm.sidebar.buildRoutes' }),
    disabledForBeta: translate({ id: 'global.disabledForBeta' }),
    dispatchRoutes: translate({ id: 'dispatchPanel.header.title' }),
    viewerUserTitle: translate({ id: 'global.viewers.disableEdit' }),
    copyToOperations: translate({ id: 'rm.sidebar.copyToOperations' }),
    buildRoutesError: translate({ id: 'rm.sidebar.buildRoutes.error' }),
    emptyPlanTitle: translate({ id: 'rm.sidebar.copy.emptyPlan.title' }),
    dispatchRoutesError: translate({ id: 'rm.sidebar.dispatchRoutes.error' }),
    emptyPlanSubtitle: translate({ id: 'rm.sidebar.copy.emptyPlan.subtitle' }),
    emptyArchivedDayTitle: translate({ id: 'rm.sidebar.copy.emptyArchivedDay.title' }),
    emptyArchivedDaySubtitle: translate({ id: 'rm.sidebar.copy.emptyArchivedDay.subtitle' }),

    fullPastOptimizationTitle: translate({
      id: 'rm.sidebar.fullPastOptimizationTooltip.title',
    }),

    fullPastOptimizationSubtitle: translate({
      id: 'rm.sidebar.fullPastOptimizationTooltip.subtitle',
    }),

    partialPastOptimizationTitle: translate({
      id: 'rm.sidebar.partialPastOptimizationTooltip.title',
    }),

    partialPastOptimizationSubtitle: translate({
      id: 'rm.sidebar.partialPastOptimizationTooltip.subtitle',
    }),

    overOptimizationHorizonTitle: translate({
      id: 'rm.sidebar.overOptimizationHorizonTooltip.title',
    }),

    overOptimizationHorizonSubtitle: (startDate: string, endDate: string) =>
      translate({
        id: 'rm.sidebar.overOptimizationHorizonTooltip.subtitle',
        values: { startDate, endDate },
      }),

    overMaxOptimizableDaysTitle: (maxOptimizableDays: number) =>
      translate({
        id: 'rm.sidebar.overMaxOptimizableDaysTooltip.title',
        values: { maxOptimizableDays },
      }),

    addOrderDisabledTooltipTitle: (isSimulation: boolean) => {
      const id = isSimulation
        ? 'rm.sidebar.addorder.disabled.simulations.title'
        : 'rm.sidebar.addorder.disabled.operations.title'
      return translate({ id })
    },

    addOrderDisabledTooltipSubtitle: (isSimulation: boolean, count: number) => {
      const id = isSimulation
        ? 'rm.sidebar.addorder.disabled.simulations.subtitle'
        : 'rm.sidebar.addorder.disabled.operations.subtitle'
      return translate({ id, values: { count } })
    },
  }))

  return api
}
