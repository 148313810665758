import type OlMap from 'ol/Map'
import type { Layers, LayerUID } from '../typings'
import { getLayerMetadata } from '../layerMetadata'

export const findLayer = <UID extends LayerUID = LayerUID, Result = Layers[UID]>(
  map: OlMap,
  uid: UID,
): Result => {
  for (let idx = 0; idx < map.getLayers().getLength(); idx++) {
    const layer = map.getLayers().item(idx)

    if (getLayerMetadata(layer, 'uid') === uid) {
      return layer as unknown as Result
    }
  }

  throw new Error(`Trying to access a non-existent Layer: [${uid}]`)
}
