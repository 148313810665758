import { useMemo } from 'react'

import { getRecurrences } from '../../../../../../utils/getRecurrences'

export function useCurrentRecurrence(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  exception: string,
) {
  const recurrences = getRecurrences(unifiedVehicle)
  return useMemo(() => {
    return recurrences.find(r => r.source === exception)
  }, [recurrences, exception])
}
