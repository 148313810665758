import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    addTagTooltip: (disableReason?: 'noTags' | 'limitReached') => {
      switch (disableReason) {
        case 'noTags':
          return translate({ id: 'order.form.bulk.tags.addTagTooltip.noTags' })
        case 'limitReached':
          return translate({ id: 'order.form.bulk.tags.addTagTooltip.limitReached' })
        default:
          return translate({ id: 'order.form.bulk.tags.addTagTooltip' })
      }
    },
  }))

  return api
}
