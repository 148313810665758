import { type SchedulerProConfig } from '@bryntum/schedulerpro'
import { getMainSelection, setMainSelection } from '@/atoms'

export const onSelectionChange: SchedulerProConfig['onSelectionChange'] = params => {
  const currentRouteSelection = new Set(getMainSelection().routes)
  const { selection } = params

  // If the selection is empty and the current selection is empty, do nothing
  if (selection.length === 0 && currentRouteSelection.size === 0) return

  if (selection.length !== currentRouteSelection.size) {
    setMainSelection(
      'routes',
      selection.map(r => r.getData('id')),
    )
  }

  // Check if there's a difference between the current selection and the new selection
  // This is required to prevent an infinite loop
  for (const item of selection) {
    const id = item.getData('id')

    // If the current selection does not contain the id, set the new selection
    if (!currentRouteSelection.has(id)) {
      const ids = selection.map(r => r.getData('id'))
      setMainSelection('routes', ids)
      return
    }
  }
}
