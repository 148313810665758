import styled from 'styled-components'

const ReadonlyAudioContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  /* height: 60px; */
  background: ${p => p.theme.colors.$alabaster};
  border-radius: 6px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 0;
  padding-bottom: 8px;
`

ReadonlyAudioContainer.displayName = 'ReadonlyAudioContainer'
export default ReadonlyAudioContainer
