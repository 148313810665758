import { useMemo } from 'react'

import { setMainSelection } from '@/atoms'
import { unproxify } from '@/utils'

import { Collapsible } from '../Collapsible'
import { Header } from '../Header'
import { Section } from '../Section'

import { useCollapsedState, setCollapsedState } from '../../hooks'
import { useTexts } from './useTexts'

import { AllOrders } from './components/AllOrders'
import { OrdersAssigned } from './components/OrdersAssigned'
import { OrdersNotDispatched } from './components/OrdersNotDispatched'
import { OrdersWithViolations } from './components/OrdersWithViolations'
import { OrdersExecuted } from './components/OrdersExecuted'
import { OrdersExecutedDone } from './components/OrdersExecutedDone'
import { OrdersExecutedReschedule } from './components/OrdersExecutedReschedule'
import { OrdersExecutedUndeclared } from './components/OrdersExecutedUndeclared'
import { OrdersToDo } from './components/OrdersToDo'
import { OrdersUnassigned } from './components/OrdersUnassigned'
import { OrdersExpiring } from './components/OrdersExpiring'

interface Props {
  snapshot: uui.domain.client.rm.PlanSummary
}

function setOrderCollapsedState(collapsed: boolean) {
  setCollapsedState('order', collapsed)
}

export function OrderPlanSummary(props: Props) {
  const { snapshot } = props
  const {
    order: {
      executedUndeclared,
      executedReschedule,
      executedDone,
      orders,
      assigned,
      ordersWithViolations,
      toDo,
      unassigned,
      unassignedHighPrio,
      dispatched,
    },
  } = snapshot

  // this value is not computed in server-data since it can be easy derived
  // notDispatched = assigned - dispatched
  const notDispatchedOrders = useMemo(
    () => assigned.filter(id => !dispatched.includes(id)),
    [assigned, dispatched],
  )

  const selectApi = useMemo(() => {
    return {
      selectAllOrders: () => setMainSelection('orderSteps', unproxify(snapshot.order.orders)),
      selectAssignedOrders: () =>
        setMainSelection('orderSteps', unproxify(snapshot.order.assigned)),
      selectOrdersWithViolations: () =>
        setMainSelection('orderSteps', unproxify(snapshot.order.ordersWithViolations)),
      selectOrdersExecutedUndeclared: () =>
        setMainSelection('orderSteps', unproxify(snapshot.order.executedUndeclared)),
      selectOrdersExecutedDone: () =>
        setMainSelection('orderSteps', unproxify(snapshot.order.executedDone)),
      selectOrdersExecutedReschedule: () =>
        setMainSelection('orderSteps', unproxify(snapshot.order.executedReschedule)),
      selectOrdersToDo: () => setMainSelection('orderSteps', unproxify(snapshot.order.toDo)),
      selectOrdersUnAssigned: () =>
        setMainSelection('orderSteps', unproxify(snapshot.order.unassigned)),
      selectOrdersExpiring: () =>
        setMainSelection('orderSteps', unproxify(snapshot.order.unassignedHighPrio)),
      selectOrdersNotDispatched: () => setMainSelection('orderSteps', notDispatchedOrders),
      selectOrdersExecuted: () =>
        setMainSelection('orderSteps', [
          ...unproxify(snapshot.order.executedDone),
          ...unproxify(snapshot.order.executedUndeclared),
          ...unproxify(snapshot.order.executedReschedule),
        ]),
    }
  }, [snapshot, notDispatchedOrders])

  // the executed values are computed here
  // the motivation behind this is to reduce
  // the duplication of arrays from server-data to the ui
  // since executed = executedDone + executedUndeclared + executedReschedule
  const executedCount = useMemo(() => {
    return executedUndeclared.length + executedReschedule.length + executedDone.length
  }, [executedUndeclared, executedReschedule, executedDone])

  const collapsed = useCollapsedState('order')
  const texts = useTexts()

  return (
    <Collapsible
      collapsed={collapsed}
      setCollapsed={setOrderCollapsedState}
      trigger={
        <Header
          testId="plan-summary-orders"
          text={texts.orders(orders.length)}
          value={collapsed ? orders.length : ''}
        />
      }
    >
      <Section indentation={1}>
        <AllOrders count={orders.length} onSelect={selectApi.selectAllOrders} />

        <OrdersAssigned count={assigned.length} onSelect={selectApi.selectAssignedOrders} />
        <OrdersNotDispatched
          count={notDispatchedOrders.length}
          onSelect={selectApi.selectOrdersNotDispatched}
        />

        <OrdersWithViolations
          count={ordersWithViolations.length}
          onSelect={selectApi.selectOrdersWithViolations}
        />

        <OrdersExecuted count={executedCount} onSelect={selectApi.selectOrdersExecuted} />
        <Section indentation={1}>
          <OrdersExecutedDone
            count={executedDone.length}
            onSelect={selectApi.selectOrdersExecutedDone}
          />
          <OrdersExecutedReschedule
            count={executedReschedule.length}
            onSelect={selectApi.selectOrdersExecutedReschedule}
          />
          <OrdersExecutedUndeclared
            count={executedUndeclared.length}
            onSelect={selectApi.selectOrdersExecutedUndeclared}
          />
        </Section>

        <OrdersToDo onSelect={selectApi.selectOrdersToDo} count={toDo.length} />

        <OrdersUnassigned onSelect={selectApi.selectOrdersUnAssigned} count={unassigned.length} />

        {/* product choice to show high prio that are a subset of the expiring */}
        <OrdersExpiring
          onSelect={selectApi.selectOrdersExpiring}
          count={unassignedHighPrio.length}
        />
      </Section>
    </Collapsible>
  )
}
