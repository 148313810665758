import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'sms4.settings.trackingPage.title' }),
    headerDescription: translate({ id: 'sms4.settings.trackingPage.description' }),
    companyName: translate({ id: 'sms4.settings.trackingPage.fields.companyName.label' }),
    companySlogan: translate({ id: 'sms4.settings.trackingPage.fields.companySlogan.label' }),
    companyPhone: translate({
      id: 'sms4.settings.trackingPage.fields.companyPhone.label',
    }),
    showContactPhone: translate({
      id: 'sms4.settings.trackingPage.fields.showContactPhone.label',
    }),
    companyEmail: translate({ id: 'sms4.settings.trackingPage.fields.companyEmail.label' }),
    showContactEmail: translate({ id: 'sms4.settings.trackingPage.fields.showContactEmail.label' }),
    useCompanyEmailAsReplyTo: translate({
      id: 'sms4.settings.trackingPage.fields.useCompanyEmailAsReplyTo.label',
    }),
    showEtaRange: translate({ id: 'sms4.settings.trackingPage.fields.showEtaRange.label' }),
    showEtaRangeHelperText: translate({
      id: 'sms4.settings.trackingPage.fields.showEtaRange.helperText',
    }),
    podsTitle: translate({ id: 'sms4.settings.trackingPage.fields.pods.title' }),
    picture: translate({ id: 'sms4.settings.trackingPage.fields.pods.showPicturePod.label' }),
    signature: translate({ id: 'sms4.settings.trackingPage.fields.pods.showSignaturePod.label' }),
    voiceMemo: translate({ id: 'sms4.settings.trackingPage.fields.pods.showVoiceMemoPod.label' }),
    driverNote: translate({ id: 'sms4.settings.trackingPage.fields.pods.showDriverNotePod.label' }),
    podsHelperText: translate({ id: 'sms4.settings.trackingPage.fields.pods.helperText' }),
    waringCardTitle: translate({ id: 'sms4.settings.trackingPage.warningCard.title' }),
    waringCardDescription: translate({ id: 'sms4.settings.trackingPage.warningCard.description' }),
    waringCardLinkText: translate({ id: 'sms4.settings.trackingPage.warningCard.link' }),
    save: translate({ id: 'sms4.settings.trackingPage.footer.action.save' }),
    preview: translate({ id: 'sms4.settings.trackingPage.footer.action.preview' }),
    cancel: translate({ id: 'sms4.settings.trackingPage.footer.action.cancel' }),
    onOff: (value: boolean) =>
      translate({ id: value ? 'sms4.settings.trackingPage.on' : 'sms4.settings.trackingPage.off' }),
    edit: translate({ id: 'sms4.settings.trackingPage.edit' }),
  }))

  return api
}
