import type { RefObject } from 'react'
import type { PaperProps } from '@mui/material'

import { useMemo } from 'react'

export function useMenuPaperProps(
  buttonRef: RefObject<HTMLButtonElement | null>,
): Partial<PaperProps> {
  const width = buttonRef.current?.offsetWidth ?? 200

  return useMemo(
    () => ({
      style: {
        width,
      },
    }),
    [width],
  )
}
