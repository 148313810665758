import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    popoutPlace: (count: number) =>
      translate({ id: 'contextualMenu.stylesOnMap.place.popOut', values: { count } }),
  }))

  return api
}
