import { useState } from 'react'

import { useIntl } from '@/intl'

export function useLocaleText() {
  const { translate } = useIntl()
  const [localeText] = useState(() => ({
    manageColumns: translate({ id: 'columnsConfigurator.manageColumns' }),
    manageColumnsDescription: translate({
      id: 'columnsConfigurator.manageColumns.description',
    }),
    columnsSequence: translate({ id: 'columnsConfigurator.columnsSequence' }),
    columnsSequenceDescription: translate({
      id: 'columnsConfigurator.columnsSequence.description',
    }),

    selectAllColumns: translate({ id: 'columnsConfigurator.selectAllColumns' }),
    selectAllColumnsFiltered: translate({ id: 'columnsConfigurator.selectAllColumnsFiltered' }),

    filterPlaceholder: translate({ id: 'columnsConfigurator.filterPlaceholder' }),

    noColumns: translate({ id: 'columnsConfigurator.noColumns' }),
    noColumnsDescription: translate({
      id: 'columnsConfigurator.noColumns.description',
    }),
    noResults: translate({ id: 'columnsConfigurator.noResults' }),
    noResultsDescription: translate({
      id: 'columnsConfigurator.noResults.description',
    }),
  }))

  return localeText
}
