import { type ReactNode } from 'react'
import { MenuItem, Typography, styled } from '@mui/material'

type Props = {
  children: ReactNode
}

const Container = styled(MenuItem, { name: 'ListHeaderFilterMenuTitle' })(() => ({
  '&.Mui-disabled': {
    opacity: 1,
  },
}))

export function ListHeaderFilterMenuTitle(props: Props) {
  const { children } = props

  return (
    <Container dense disabled>
      <Typography fontSize={14} color="text.secondary">
        {children}
      </Typography>
    </Container>
  )
}
