import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'
import { Body } from './Body'
import { Header } from './Header'
import { Footer } from './Footer'

export function Content() {
  const { ready } = useConfigureController()

  if (!ready) return null

  return (
    <ModalContent header={<Header />} footer={<Footer />} maxWidth={644}>
      <Body />
    </ModalContent>
  )
}
