import { Box, Stack, CircularProgress, Typography } from '@mui/material'

import { RouteManager } from '@workwave-tidal/icons/logos'

import { FullscreenContainer } from './elements/FullscreenContainer'

interface Props {
  message?: string
}

const sx = { transform: `translateX(-50%)` }
const sxLogo = { float: `left` }

export function LoadingScreen(props: Props) {
  const { message } = props

  return (
    <FullscreenContainer blocking data-testid="loading-overlay__background">
      <Stack
        sx={sx}
        top="45vh"
        left="50vw"
        spacing={2}
        position="absolute"
        direction="column"
        alignItems="center"
      >
        <CircularProgress />

        {message && (
          <Typography variant="h6" maxWidth="60vw" textAlign="center">
            {message}
          </Typography>
        )}
      </Stack>

      <Box position="absolute" left="50vw" bottom={71} sx={sx}>
        <RouteManager width={334} sx={sxLogo} />
      </Box>
    </FullscreenContainer>
  )
}
