import { Dispatch, SetStateAction } from 'react'
import { useOnSelectUser } from './useOnSelectUser'
import { useOnUserEdit } from './useOnUserEdit'
import { useOnSearchChange } from './useOnSearchChange'
import { useOnSearchClear } from './useOnSearchClear'
import { useOnAllUsersToggle } from './useOnAllUsersToggle'
import { useToggleSortBy } from './useToggleSortBy'
import { useCreateNewUser } from './useCreateNewUser'

type Props = {
  setQuery: Dispatch<SetStateAction<string>>
}

export function useActions(props: Props) {
  const { setQuery } = props
  const [, toggleSortBy] = useToggleSortBy()

  return {
    onUserSelect: useOnSelectUser(),
    onUserEdit: useOnUserEdit(),
    handleSearchChange: useOnSearchChange({ setQuery }),
    onSearchClear: useOnSearchClear({ setQuery }),
    toggleAllUsers: useOnAllUsersToggle(),
    createNewUser: useCreateNewUser({ setQuery }),
    toggleSortBy,
  }
}
