import type { MutableRefObject } from 'react'
import { useCallback, useReducer, useRef } from 'react'

type DropDownState = {
  open: boolean
  loading: boolean
}
type DropDownApi = DropDownState & {
  setDropDownState: (nextState: Partial<DropDownState>) => void
}
export type MutableDropDownState = MutableRefObject<DropDownApi>

export function useDropDownStateRef(): MutableDropDownState {
  const [, forceRender] = useReducer(s => s + 1, 0)

  const setDropDownState = useCallback((nextState: Partial<DropDownState>) => {
    if (Object(nextState).keys === 0) return

    Object.assign(api.current, nextState)

    forceRender()
  }, [])

  const api = useRef({ loading: false, open: false, setDropDownState })

  return api
}
