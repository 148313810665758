import type { RouteStep } from '../../typings'

import { forceStartRefId } from '../../constants'

export const forceStartPlaceholderRouteStep: RouteStep = {
  ref: forceStartRefId,
  type: 'delivery',
  displayLabel: '',
  perStopTimeSec: -1,
  idleTimeSec: -1,
  arrivalSec: -1,
  startSec: -1,
  endSec: -1,
  driveToNextSec: -1,
  distanceToNextMt: -1,
  stopIdx: -1,
}
