import { useEffect, useMemo } from 'react'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { TextField } from '@workwave-tidal/tidal/components'

import { type FormErrors, type FormFields } from '../../../../../formFields'
import { useTexts } from '../../../../../useTexts'

export function ApiKey() {
  const {
    fieldApi,
    formApi,
    errors,
    field: { value },
  } = useFormField<'credentials', FormFields, FormErrors>('credentials')

  const texts = useTexts()

  const { helperText, error } = useMemo(() => {
    if (errors.length === 0) {
      return {
        helperText: texts.linxup.apiKeyHelperText,
        error: false,
      }
    }

    return {
      helperText: errors[0].message,
      error: true,
    }
  }, [texts, errors])

  useEffect(() => {
    const formStatus = formApi.getMeta().status

    if (formStatus !== 'pristine') {
      fieldApi.validate()
    }
  }, [fieldApi, formApi])

  return (
    <TextField
      name="apiKey"
      size="medium"
      label={texts.linxup.apiKeyLabel}
      value={value.find(field => field.fieldName === 'apiKey')?.value ?? ''}
      error={error}
      onChange={e => {
        fieldApi.change([{ fieldName: 'apiKey', value: e.target.value }])
      }}
      helperText={helperText}
    />
  )
}
