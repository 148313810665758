import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function RouteEnd(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M19.7191 6.6001L20.3595 7.36808L19.7191 6.6001C21.0216 5.51385 23 6.44005 23 8.13607L23 23.866C23 25.5613 21.023 26.4877 19.7202 25.4029L10.2822 17.5439L10.9222 16.7754L10.2822 17.5439C9.32245 16.7446 9.3219 15.2709 10.2811 14.471L19.7191 6.6001Z" />
        <path d="M19.7191 6.6001L20.3595 7.36808L19.7191 6.6001C21.0216 5.51385 23 6.44005 23 8.13607L23 23.866C23 25.5613 21.023 26.4877 19.7202 25.4029L10.2822 17.5439L10.9222 16.7754L10.2822 17.5439C9.32245 16.7446 9.3219 15.2709 10.2811 14.471L19.7191 6.6001Z" />
        <path d="M19.7191 6.6001L20.3595 7.36808L19.7191 6.6001C21.0216 5.51385 23 6.44005 23 8.13607L23 23.866C23 25.5613 21.023 26.4877 19.7202 25.4029L10.2822 17.5439L10.9222 16.7754L10.2822 17.5439C9.32245 16.7446 9.3219 15.2709 10.2811 14.471L19.7191 6.6001Z" />
        <path
          d="M19.7191 6.6001L20.3595 7.36808L19.7191 6.6001C21.0216 5.51385 23 6.44005 23 8.13607L23 23.866C23 25.5613 21.023 26.4877 19.7202 25.4029L10.2822 17.5439L10.9222 16.7754L10.2822 17.5439C9.32245 16.7446 9.3219 15.2709 10.2811 14.471L19.7191 6.6001Z"
          fillOpacity="0.7"
        />
        <path
          d="M19.7191 6.6001L20.3595 7.36808L19.7191 6.6001C21.0216 5.51385 23 6.44005 23 8.13607L23 23.866C23 25.5613 21.023 26.4877 19.7202 25.4029L10.2822 17.5439L10.9222 16.7754L10.2822 17.5439C9.32245 16.7446 9.3219 15.2709 10.2811 14.471L19.7191 6.6001Z"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    </IconContainer>
  )
}
