import type { PlaceFormValues } from './types'

import { useState } from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { useEditingStateWithCtx, useEnvironmentQa, useTransactionState } from '@/atoms'
import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'

import { useStopEditingOnUnmount } from '@/hooks'
import { usePendingFitMapOnMount } from '@/map'

import { FormLoading } from '@/components/FormLoading'
import { useComposeFieldsWithError } from './hooks/useComposeFieldsWithError'
import { computeFormInitialValues } from './utils/computeFormInitialValues'
import { useValidateForm } from './hooks/useValidateForm'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'
import { Form } from './components/Form'
import { Footer } from './components/Footer'

interface Props {
  place: uui.domain.client.gps.wwgps.Place
  creating?: boolean
  loading: boolean
}

export function SingleEdit(props: Props) {
  const { place, creating } = props
  const { onCreate, onUpdate, submitting } = useOnSubmit()
  const { resetEditing } = useEditingStateWithCtx('place')

  const [initialValues] = useState<PlaceFormValues>(() => computeFormInitialValues(place))

  const validateForm = useValidateForm()
  const composeFieldsWithError = useComposeFieldsWithError()

  const { mode } = useTransactionState()
  const texts = useTexts()

  const qa = useEnvironmentQa()

  // When the Edit form mounts
  // try to apply pending fitMap requests
  usePendingFitMapOnMount(true)

  useStopEditingOnUnmount()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <FinalForm<PlaceFormValues>
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={creating ? onCreate : onUpdate}
        render={(formRenderProps: FormRenderProps<PlaceFormValues>) => {
          const transactionOpen = !props.loading
          const { handleSubmit, form } = formRenderProps

          const { valid, validating, pristine: rawPristine, errors = {} } = form.getState()

          const pristine = creating ? false : rawPristine

          const planLocked = mode === 'external'

          const saveDisabled = !valid || validating || planLocked || submitting || pristine
          const fieldsWithError = composeFieldsWithError(errors)

          return (
            <>
              <SecondaryColumnHeader
                title={texts.headerTitle}
                description={texts.headerDescription}
                avatar={null}
              />
              <FormContent
                qa={qa}
                testId="placeSingleEdit"
                footer={
                  transactionOpen ? (
                    <Footer
                      fieldsWithError={fieldsWithError}
                      saveDisabled={saveDisabled}
                      planLocked={planLocked}
                      onSubmit={handleSubmit}
                      onCancel={resetEditing}
                      submitting={submitting}
                      creating={creating}
                      pristine={pristine}
                      errors={errors}
                      valid={valid}
                    />
                  ) : undefined
                }
              >
                {transactionOpen ? <Form /> : <FormLoading />}
              </FormContent>
            </>
          )
        }}
      />
    </FormColumn>
  )
}
