import { isDark, getColorValue, theme } from '@/styles'

import { useStyles } from './hooks/useStyles'
import { useShowText } from './hooks/useShowText'
import { DeviceIcon } from './DeviceIcon'

interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
  size: number
  marginRight?: number
  icon?: string
}

export function DeviceAvatar(props: Props) {
  const { device, size, marginRight = 0 } = props

  const showText = useShowText(device)

  const backgroundColor = getColorValue(device.iconColor1) ?? theme.colors.$pureWhite

  const color = isDark(backgroundColor) ? theme.colors.$pureWhite : theme.colors.$nightRider
  const label = device.label
  const iconId = device.iconId

  const [classes, styles] = useStyles({ size, backgroundColor, color, marginRight })

  return (
    <div className={classes.container} style={styles.container}>
      {showText ? label : <DeviceIcon iconId={iconId} />}
    </div>
  )
}
