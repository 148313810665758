import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({
      id: 'settings.messagingPlan.requestChangePlan.header.title',
    }),

    subtitle: translate({
      id: 'settings.messagingPlan.requestChangePlan.header.subtitle',
    }),

    moreInfo: translate({
      id: 'settings.messagingPlan.requestChangePlan.controls.moreInfo',
    }),

    requestPlan: translate({
      id: 'settings.messagingPlan.requestChangePlan.controls.requestChange',
    }),

    cancel: translate({
      id: 'settings.messagingPlan.requestChangePlan.controls.cancel',
    }),

    requestChangePlanTitle: translate({
      id: 'settings.messagingPlan.requestChangePlan.body.title',
    }),

    requestChangePlanDescription: translate({
      id: 'settings.messagingPlan.requestChangePlan.body.subtitle',
    }),

    requestChangePlanSuccess: translate({
      id: 'settings.messagingPlan.requestChangePlan.result.success',
    }),

    requestChangePlanError: translate({
      id: 'settings.messagingPlan.requestChangePlan.result.error',
    }),
  }))

  return api
}
