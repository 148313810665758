import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectLivePositions } from '@/features/domain/device'
import { gpsReverseGeocode, selectCalendarRange } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'
import { useIsUnmounted } from '@/hooks'
import { useGetMapInfo } from '@/map'
import { getDeviceId } from '@/server-data'
import { selectDrivers } from '@/features/domain/driver'

export function useLastPosition(vehicle: uui.domain.client.UnifiedVehicle) {
  const livePositions = useSelector(selectLivePositions)
  const calendarRange = useSelector(selectCalendarRange)
  const drivers = useSelector(selectDrivers)
  const isUnmounted = useIsUnmounted()
  const { bounds } = useGetMapInfo()
  const dispatch = useAppDispatch()

  const deviceId = getDeviceId(drivers, vehicle, calendarRange.minDate)
  const lastPosition = deviceId ? livePositions[deviceId] : undefined

  const [location, setLocation] = useState<
    | uui.domain.client.gps.wwgps.GpsInfo
    | uui.domain.client.gps.telematics.CurrentPositionGpsInfo
    | 'loading'
    | undefined
  >(vehicle.hasRoutingLicense ? 'loading' : undefined)

  const boundsRef = useRef<uui.domain.LatLngBounds>(bounds)
  useEffect(() => {
    boundsRef.current = bounds
  }, [bounds])

  useEffect(() => {
    async function reverseGeocodeLastPositions(
      lastPosition:
        | uui.domain.client.gps.wwgps.GpsInfo
        | uui.domain.client.gps.telematics.CurrentPositionGpsInfo,
    ) {
      setLocation('loading')

      const response = await dispatch(
        gpsReverseGeocode({ latLng: lastPosition.latLng, bounds: boundsRef.current }),
      )

      if (gpsReverseGeocode.rejected.match(response)) {
        throw new Error('Request failed')
      }

      if (isUnmounted()) return

      setLocation({ ...lastPosition, address: response.payload.geoAddress })
    }

    if (lastPosition) {
      if (lastPosition.address) {
        setLocation(lastPosition)
        return
      }

      reverseGeocodeLastPositions(lastPosition)
    } else {
      setLocation(undefined)
    }
  }, [dispatch, isUnmounted, lastPosition])

  return location
}
