import { type PropsWithChildren } from 'react'
import { Stack, type StackProps } from '@mui/material'

type Props = StackProps & {
  testId?: string
}

export function CenteredLayout(props: PropsWithChildren<Props>) {
  const { testId, children, ...rest } = props

  return (
    <Stack
      width="100%"
      {...rest}
      gap={3}
      alignItems="center"
      justifyContent="center"
      height="inherit"
      max-height="100%"
      data-testid={testId}
      data-trackid={testId}
      margin="0 auto"
      paddingY={10}
    >
      {children}
    </Stack>
  )
}
