import styled from 'styled-components'
import DatePicker from './DatePicker'

const StyledDatePicker = styled(DatePicker)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  & > * + * {
    margin-top: 4px;
  }

  .o-date-picker__cancel {
    width: 100%;
  }
`

StyledDatePicker.displayName = 'StyledDatePicker'

export default StyledDatePicker
