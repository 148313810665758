import { getMap } from '../../atoms/map/utils/getMap'
import { findLayer } from '../utils/findLayer'

import { changelogRemoveRoadSegmentFeatures } from './utils/changelogRemoveRoadSegmentFeatures'
import { changelogAddRoadSegmentFeatures } from './utils/changelogAddRoadSegmentFeatures'
import { changelogUpdateRoadSegmentFeatures } from './utils/changelogUpdateRoadSegmentFeatures'

export function createUpdateRoadSegmentFeaturesFromChangelog(
  routingProfiles: uui.domain.ui.map.markers.MapMarkers['roadSegment'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['roadSegment'],
  selection: Set<string>,
  changelog: uui.domain.api.Changelog,
) {
  // ATTENTION: Currently all changelog are applied as soon as they arrive, also when the map is not visible
  // `ignoreSelection` it will be used to avoid to apply any selection information to the map when applying cached updater functions.
  // after all cached updates will be applied a refresh selection action will be required
  return async (_ignoreSelection: boolean = false) => {
    const map = getMap()
    const layer = findLayer(map, 'roadSegment')

    // remove
    await changelogRemoveRoadSegmentFeatures(layer, selection, changelog.remove.values())

    // add
    await changelogAddRoadSegmentFeatures(
      layer,
      routingProfiles,
      mapStyles,
      selection,
      changelog.add.values(),
    )

    // update
    await changelogUpdateRoadSegmentFeatures(
      layer,
      routingProfiles,
      mapStyles,
      selection,
      changelog.update.values(),
    )
  }
}
