import { Alert, Box } from '@mui/material'
import { Form, FormHeader } from '@workwave-tidal/tidal/form-ui/layout'

import { useConnectToAutomatedMessageEnabled } from './components/fields/hooks/useConnectToAutomatedMessageEnabled'
import { useConnectedToBackOfficePhone } from './components/fields/hooks/useConnectedToBackOfficePhone'
import { AutomatedMessageEnabledField } from './components/fields/AutomatedMessageEnabledField'
import { AutomatedMessageVoiceField } from './components/fields/AutomatedMessageVoiceField'
import { useConnectedToStatusFields } from './components/fields/hooks/useConnectedToStatusFields'
import { CallbackConfigStatusField } from './components/fields/CallbackConfigStatusField'
import { AutomatedMessageField } from './components/fields/AutomatedMessageField'
import { RestrictionsField } from './components/fields/RestrictionsField'
import { VoiceFormFooter } from './components/VoiceFormFooter/VoiceFormFooter'
import { StatusField } from './components/fields/StatusField'
import { VoiceAlert } from './components/VoiceAlert/VoiceAlert'
import { PhoneField } from './components/fields/PhoneField'

import { useCanShowAdvancedForm } from './hooks/useCanShowAdvancedForm'
import { useShowPhoneAlert } from './hooks/useShowPhoneAlert'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'

type Props = {
  onDiscard: () => void
}

export function VoiceForm(props: Props) {
  const { onDiscard } = props

  const texts = useTexts()
  const onSubmit = useOnSubmit(onDiscard)
  const showPhoneAlert = useShowPhoneAlert()

  useConnectedToStatusFields()
  useConnectedToBackOfficePhone()
  useConnectToAutomatedMessageEnabled()

  // The following hook is used to control the whole visibility of the fields
  // since it is simpler to remove them totally instead of control the visibility of
  // every field. Adding that control to the existent checks will just create mess.
  const canShowAdvancedForm = useCanShowAdvancedForm()

  return (
    <Box width={360} height="100%" data-testid="form__column-secondary" display="flex" paddingY={2}>
      <Form
        disableScroll
        fieldsSpacing={4}
        fieldsPadding="0 32px"
        testId="voice-form"
        onEnter={onSubmit}
        header={<FormHeader title={texts.header.title} subtitle={texts.header.subtitle} />}
        footer={<VoiceFormFooter onSubmit={onSubmit} onDiscard={onDiscard} />}
      >
        <StatusField />

        <VoiceAlert />

        {canShowAdvancedForm ? (
          <>
            <RestrictionsField />

            <CallbackConfigStatusField />

            <PhoneField />

            <AutomatedMessageEnabledField />

            <AutomatedMessageField />

            {showPhoneAlert && <Alert severity="info">{texts.alert}</Alert>}

            <AutomatedMessageVoiceField />
          </>
        ) : (
          <></>
        )}
      </Form>
    </Box>
  )
}
