import { useCallback, useEffect, useRef, useState } from 'react'

export const useTooltip = (delayMs: number) => {
  const [visible, setVisible] = useState(false)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  const delayRef = useRef(delayMs)
  useEffect(() => {
    delayRef.current = delayMs
  }, [delayMs])

  const show = useCallback(() => {
    timer.current = setTimeout(() => setVisible(true), delayRef.current)
  }, [])

  const hide = useCallback(() => {
    timer.current && clearTimeout(timer.current)
    setVisible(false)
  }, [])

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current)
    }
  }, [])

  return {
    show,
    hide,
    visible,
  }
}
