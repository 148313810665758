import { isWithinInterval, parse } from 'date-fns/esm'

export const toString = (eligibility: uui.domain.client.rm.Eligibility): string => {
  switch (eligibility.type) {
    case 'on':
      return eligibility.dates.length > 0 ? eligibility.dates.join(', ') : ''

    case 'by':
      return `b${eligibility.date}`

    default:
    case 'any':
      return 'any'
  }
}

export const isEligible = (
  eligibility: uui.domain.client.rm.Eligibility,
  date: string,
): boolean => {
  switch (eligibility.type) {
    case 'on':
      return eligibility.dates.indexOf(date) >= 0

    case 'by':
      return date <= eligibility.date

    case 'any':
      return true

    default:
      return false
  }
}

export const getLastEligibleDate = (
  eligibility: uui.domain.client.rm.Eligibility,
): string | undefined => {
  switch (eligibility.type) {
    case 'on':
      if (eligibility.dates.length === 0) {
        // JOURNAL: should we log it? or is it okay to faiul silently?
        return
      }
      return eligibility.dates[eligibility.dates.length - 1]

    case 'by':
      return eligibility.date

    default:
    case 'any':
      return
  }
}

export const isEligibleInRange = (
  eligibility: uui.domain.client.rm.Eligibility,
  start: string,
  end: string,
): boolean => {
  switch (eligibility.type) {
    case 'on':
      if (eligibility.dates.length === 0) {
        // JOURNAL: should we log it? or is it okay to faiul silently?
        return false
      }

      const last = eligibility.dates[eligibility.dates.length - 1]

      if (last < start) return false
      if (last <= end) return true
      if (eligibility.dates.length === 1) return false

      const first = eligibility.dates[0]
      if (first > end) return false
      if (first >= start) return true

      const now = new Date()
      const interval = {
        start: parse(start, 'yyyyMMdd', now),
        end: parse(end, 'yyyyMMdd', now),
      }

      for (const dateAsString of eligibility.dates) {
        const date = parse(dateAsString, 'yyyyMMdd', now)
        if (isWithinInterval(date, interval)) {
          return true
        }
      }

      return false

    case 'by':
      return eligibility.date >= start

    case 'any':
      return true

    default:
      return false
  }
}
