import { useCallback } from 'react'
import { useCrudSelection } from '@/atoms'

export function useOnCloseUserDetails() {
  const [, setSelection] = useCrudSelection('users')

  return useCallback(() => {
    setSelection([])
  }, [setSelection])
}
