import { generateUsersOptions } from './defaultOptions/generateUsersOptions'
import { generateDepotsOptions } from './defaultOptions/generateDepotsOptions'
import { generateDefaultOptions } from './defaultOptions/generateDefaultOptions'
import { generateDriversOptions } from './defaultOptions/generateDriversOptions'
import { generateDevicesOptions } from './defaultOptions/generateDevicesOptions'
import { generateVehiclesOptions } from './defaultOptions/generateVehiclesOptions'
import { generateTrackingOptions } from './defaultOptions/generateTrackingOptions'
import { generateOrderStepsOptions } from './defaultOptions/generateOrderStepsOptions'
import { generateOrdersGridOptions } from './defaultOptions/generateOrdersGridOptions'
import { generateEventInfosOptions } from './defaultOptions/generateEventInfosOptions'
import { generateSimulationsOptions } from './defaultOptions/generateSimulationsOptions'
import { generateRoadSegmentsOptions } from './defaultOptions/generateRoadSegmentsOptions'
import { generateTrafficAreasOptions } from './defaultOptions/generateTrafficAreasOptions'
import { generateTrafficProfilesOptions } from './defaultOptions/generateTrafficProfilesOptions'
import { generateDriverAssignmentsOptions } from './defaultOptions/generateDriverAssignmentsOptions'

// this prevent the developer from forgetting to enable a new list
const activeCategories: Record<uui.domain.ui.list.Category, true> = {
  users: true,
  depots: true,
  places: true,
  devices: true,
  drivers: true,
  regions: true,
  tracking: true,
  geofences: true,
  ordersGrid: true,
  orderSteps: true,
  eventInfos: true,
  eventAlerts: true,
  simulations: true,
  roadSegments: true,
  trafficAreas: true,
  trafficProfiles: true,
  unifiedVehicles: true,
  driverAssignments: true,
}

export const listCategories = Object.keys(activeCategories) as uui.domain.ui.list.Category[]

export const generateLists = (
  storedListOptions: uui.domain.StoredListOptions = {},
): uui.domain.ui.list.Lists => {
  return {
    users: generateList('users', storedListOptions),
    depots: generateList('depots', storedListOptions),
    places: generateList('places', storedListOptions),
    drivers: generateList('drivers', storedListOptions),
    regions: generateList('regions', storedListOptions),
    devices: generateList('devices', storedListOptions),
    tracking: generateList('tracking', storedListOptions),
    geofences: generateList('geofences', storedListOptions),
    eventInfos: generateList('eventInfos', storedListOptions),
    orderSteps: generateList('orderSteps', storedListOptions),
    ordersGrid: generateList('ordersGrid', storedListOptions),
    eventAlerts: generateList('eventAlerts', storedListOptions),
    simulations: generateList('simulations', storedListOptions),
    roadSegments: generateList('roadSegments', storedListOptions),
    trafficAreas: generateList('trafficAreas', storedListOptions),
    unifiedVehicles: generateList('unifiedVehicles', storedListOptions),
    trafficProfiles: generateList('trafficProfiles', storedListOptions),
    driverAssignments: generateList('driverAssignments', storedListOptions),
  }
}

export const generateList = (
  category: uui.domain.ui.list.Category,
  storedListOptions: uui.domain.StoredListOptions,
  revision = 0,
): uui.domain.ui.list.List => {
  return {
    options: generateListOptions(category, storedListOptions),
    structure: {
      revision,
      list: [],
      totalItems: 0,
      shownItems: 0,
      totalGroupHeaders: 0,
    },
  }
}

export const generateListChangelog = (): uui.domain.ui.list.Changelog => {
  return {
    add: new Set(),
    update: {},
    remove: new Set(),
    updateRevision: false,
    fullReplace: false,
  }
}

export const generateListReplaceChangelog = (): uui.domain.ui.list.Changelog => {
  const changelog = generateListChangelog()
  changelog.fullReplace = true
  return changelog
}

export const generateListUpdateChangelog = (): uui.domain.ui.list.UpdateChangelog => {
  return {
    prev: {},
    next: {},
  }
}

/**
 * Get the default list options for every category.
 */
export const generateListOptions = (
  category: uui.domain.ui.list.Category,
  storedListOptions: uui.domain.StoredListOptions,
): uui.domain.ui.list.ListOptions => {
  switch (category) {
    case 'users':
      return { ...generateUsersOptions(), ...storedListOptions.users }

    case 'unifiedVehicles':
      return { ...generateVehiclesOptions(), ...storedListOptions.unifiedVehicles }

    case 'drivers':
      return { ...generateDriversOptions(), ...storedListOptions.drivers }

    case 'orderSteps':
      return { ...generateOrderStepsOptions(), ...storedListOptions.orderSteps }

    case 'ordersGrid':
      return { ...generateOrdersGridOptions(), ...storedListOptions.ordersGrid }

    case 'depots':
      return { ...generateDepotsOptions(), ...storedListOptions.depots }

    case 'driverAssignments':
      return { ...generateDriverAssignmentsOptions(), ...storedListOptions.driverAssignments }

    case 'trafficProfiles':
      return { ...generateTrafficProfilesOptions(), ...storedListOptions.trafficProfiles }

    case 'trafficAreas':
      return { ...generateTrafficAreasOptions(), ...storedListOptions.trafficAreas }

    case 'devices':
      return { ...generateDevicesOptions(), ...storedListOptions.devices }

    case 'tracking':
      return { ...generateTrackingOptions(), ...storedListOptions.tracking }

    case 'eventInfos':
      return { ...generateEventInfosOptions(), ...storedListOptions.eventInfos }

    case 'simulations':
      return { ...generateSimulationsOptions(), ...storedListOptions.simulations }

    case 'roadSegments':
      return { ...generateRoadSegmentsOptions(), ...storedListOptions.roadSegments }

    case 'places':
      return { ...generateDefaultOptions(), ...storedListOptions.places }

    case 'regions':
      return { ...generateDefaultOptions(), ...storedListOptions.regions }

    case 'geofences':
      return { ...generateDefaultOptions(), ...storedListOptions.geofences }

    case 'eventAlerts':
      return { ...generateDefaultOptions(), ...storedListOptions.eventAlerts }
  }
}
