import styled from 'styled-components'

const OrderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0;
`

OrderContainer.displayName = 'OrderContainer'
export default OrderContainer
