import styled from 'styled-components'

import { HorizontalLayout } from '@/components/layout'

export function TabsContainer(props) {
  return (
    <Container>
      <HorizontalLayout>{props.children}</HorizontalLayout>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  background: ${p => p.theme.colors.$silver};
  border-radius: 5px;
  padding: 2px;
  height: 24px;
`

Container.displayName = 'Container'
