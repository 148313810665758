import { useMemo } from 'react'

import { useIntl } from '@/intl'
import { durationUtils } from '@/server-data'

export const useFormatSeconds = (sec: number, fmt: durationUtils.TimeDurationFormats = 'LONG') => {
  const { translate } = useIntl()
  return useMemo(() => formatSeconds(sec, fmt, translate), [translate, fmt, sec])
}

export function formatSeconds(
  sec: number,
  fmt: durationUtils.TimeDurationFormats = 'LONG',
  translate: uui.domain.intl.Translate,
) {
  return durationUtils.formatSeconds(translate)(sec, fmt)
}
