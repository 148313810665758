import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { ArrowRoundedUpEdge, Trash } from '@/icons'
import {
  SecondaryColumnHeaderActionsRow,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsSeparator,
} from '@/forms-legacy'

import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'

interface Props {
  roadSegments: uui.domain.client.rm.SegmentException[]
}

export function Actions(props: Props) {
  const { roadSegments } = props
  const actions = useActions(roadSegments)
  const texts = useTexts()

  return (
    <SecondaryColumnHeaderActionsRow>
      <SecondaryColumnHeaderActionButton
        label={texts.exportKML}
        icon={<ArrowRoundedUpEdge />}
        onClick={actions.onExport}
        testid="roadSegment-exportKML"
      />

      <SecondaryColumnHeaderActionsSeparator />

      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={actions.onDelete}
            disabled={preventEditing}
            testid="roadSegment-delete"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
