import { Component } from 'react'

import {
  ExecutionTimeField,
  FlexRow,
  ReadonlyBadge,
  ReadonlyLabel,
  ReadonlyBlock,
  FieldMessage,
} from '@/forms-legacy'

import { theme } from '@/local/components'

import * as OrderTexts from '../../../intl'

import { Props } from '../typings'

export default class ReportedExecutionDetails extends Component<Props> {
  render() {
    const {
      values: { timeInSec, timeOutSec },
      formRenderProps: { errors },
      accountPreferences,
    } = this.props

    const h24 = accountPreferences.timeFormat === 'HH:mm'

    const timeInLabel = (
      <FlexRow useAutoWidth>
        <ReadonlyBadge background={theme.colors.$darkOrange} disabled={timeInSec === -1}>
          1
        </ReadonlyBadge>
        <ReadonlyLabel>{OrderTexts.getReportedTimeInLabel()}</ReadonlyLabel>
      </FlexRow>
    )

    const timeOutLabel = (
      <FlexRow useAutoWidth>
        <ReadonlyBadge background={theme.colors.$darkOrange} disabled={timeOutSec === -1}>
          2
        </ReadonlyBadge>
        <ReadonlyLabel>{OrderTexts.getReportedTimeOutLabel()}</ReadonlyLabel>
      </FlexRow>
    )

    return (
      <>
        <FlexRow justifyContent="space-between">
          <ReadonlyBlock half style={{ marginBottom: '5px' }}>
            <ExecutionTimeField
              name="timeInSec"
              testId="timeInSec"
              label={timeInLabel}
              defaultValue={0}
              nullValue={-1}
              editLabel={OrderTexts.getEditText()}
              removeLabel={OrderTexts.getRemoveText()}
              nullLabel={OrderTexts.getNoneText()}
              wrappedInputProps={{ h24 }}
            />
          </ReadonlyBlock>
          <ReadonlyBlock half style={{ marginBottom: '5px' }}>
            <ExecutionTimeField
              name="timeOutSec"
              testId="timeOutSec"
              label={timeOutLabel}
              defaultValue={0}
              nullValue={-1}
              editLabel={OrderTexts.getEditText()}
              removeLabel={OrderTexts.getRemoveText()}
              nullLabel={OrderTexts.getNoneText()}
              wrappedInputProps={{ h24 }}
            />
          </ReadonlyBlock>
        </FlexRow>
        {!!errors && errors.timeInOut && <FieldMessage error>{errors.timeInOut}</FieldMessage>}
      </>
    )
  }
}
