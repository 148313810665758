import type { FormConfig } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'

import { store } from '@/store'

import { useTexts } from '../useTexts'

export function createFormValidation(
  texts: ReturnType<typeof useTexts>,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'labelValidator',
      fields: 'label',
      validator: formApi => {
        const state = store.getState()

        const { tenantSources } = state.domain.publicData.domain.gps.telematics
        const { value: tenantSourceId } = formApi.getField('tenantSourceId')
        const { value } = formApi.getField('label')

        const labelRequiredError: FormErrors = {
          id: 'labelRequiredError',
          field: 'label',
          message: texts.errors.labelRequired,
        }

        const empty = value.trim() === ''

        const labelUniqueError: FormErrors = {
          id: 'uniqueLabelError',
          field: 'label',
          message: texts.errors.labelUnique,
        }

        const unique = Object.values(tenantSources)
          .filter(tenantSource => tenantSource.id !== tenantSourceId)
          .every(source => source.label !== value)

        return {
          [labelRequiredError.id]: empty ? labelRequiredError : null,
          [labelUniqueError.id]: !unique ? labelUniqueError : null,
        }
      },
    },
  ]
}
