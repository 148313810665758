import styled from 'styled-components'

const styledComponent = styled.div`
  position: relative;
  flex: 0 0 auto;
  color: ${p => p.theme.colors.$nightRider};
  font-weight: ${p => p.theme.weights.$semiBold};
  font-size: ${p => p.theme.fonts.$p3};
  user-select: none;
`

styledComponent.displayName = 'ColumnFooterText'
export default styledComponent
