import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

type Payload = { category: uui.domain.ui.list.Category; options: uui.domain.ui.list.ListOptions }

/**
 * Set the list options.
 *
 * @private
 */
export const setListOptions = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('ui/listOptions/set', async (payload, thunkApi) => {
  const { category, options } = payload
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/ui/setListOptions', {
      category: 'rpc',
      type: 'rpc/ui/setListOptions',
      params: { category, options },
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {setListOptions}`,
      { tags: ['rpc', 'ui', 'listOptions'], info: { category, options } },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Set list options failed`,
      error,
    })
  }
})
