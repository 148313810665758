import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Content } from './Content'

interface Props {
  startRevision: number
  numberOfImportedOrdersWithLowAccuracy: number
  numberOfImportedOrders: number
}

export function Root(props: Props) {
  return (
    <Modal modalId={modalId}>
      <Content
        startRevision={props.startRevision}
        numberOfImportedOrders={props.numberOfImportedOrders}
        numberOfImportedOrdersWithLowAccuracy={props.numberOfImportedOrdersWithLowAccuracy}
      />
    </Modal>
  )
}
