import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function RouteDetailsOff(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 17 16">
        <path d="M7.83301 4.66634H9.16634V5.99967H7.83301V4.66634ZM7.83301 7.33301H9.16634V11.333H7.83301V7.33301ZM8.49967 1.33301C4.81967 1.33301 1.83301 4.31967 1.83301 7.99967C1.83301 11.6797 4.81967 14.6663 8.49967 14.6663C12.1797 14.6663 15.1663 11.6797 15.1663 7.99967C15.1663 4.31967 12.1797 1.33301 8.49967 1.33301ZM8.49967 13.333C5.55967 13.333 3.16634 10.9397 3.16634 7.99967C3.16634 5.05967 5.55967 2.66634 8.49967 2.66634C11.4397 2.66634 13.833 5.05967 13.833 7.99967C13.833 10.9397 11.4397 13.333 8.49967 13.333Z" />
      </svg>
    </IconContainer>
  )
}
