import { useRef } from 'react'

import { RenderFrom } from '@/components/Navigo/components/RenderFrom'
import { HorizontalLayout } from '@/components/layout'
import { useComponentSize } from '@/local/hooks'

import { WorkingHoursBlock } from './components/WorkingHoursBlock'
import { BreaksBlock } from './components/BreaksBlock'

import { useVehicleSettings } from './hooks/useVehicleSettings'

interface Props {
  routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable
}

export function VehicleStats(props: Props) {
  const { routeInfo } = props
  const settings = useVehicleSettings(routeInfo.vehicleId, routeInfo.date)
  const { breaks, timeWindow } = settings

  const ref = useRef<HTMLDivElement | null>(null)
  const [{ width }] = useComponentSize(ref)

  return (
    <HorizontalLayout
      data-trackid="navigo-routes-overview-details-single-vehicleStats"
      data-testid="navigo-routes-overview-details-single-vehicleStats"
      ref={ref}
    >
      <RenderFrom width={width} renderFrom={140}>
        <WorkingHoursBlock timeWindow={timeWindow} />
      </RenderFrom>

      {breaks.length > 0 && (
        <RenderFrom width={width} renderFrom={140}>
          <BreaksBlock breaks={breaks} />
        </RenderFrom>
      )}
    </HorizontalLayout>
  )
}
