import { Component } from 'react'

import { Props } from './typings'

import LoadContainer from './elements/LoadContainer'
import LoadMixedField from './elements/LoadMixedField'
import LoadLabel from './elements/LoadLabel'
import LoadInputField from './elements/LoadInputField'

export default class Load extends Component<Props> {
  private handleOnChange = (val: number) => {
    const { label, onChange } = this.props
    onChange(label, val)
  }

  private handleOnFocusMixedField = () => {
    const { label, onChange } = this.props
    onChange(label, 0)
  }

  render() {
    const { label, value, mixedNumericValue, mixedText, useMetric } = this.props

    const showMixedField: boolean = mixedNumericValue !== undefined && value === mixedNumericValue

    return (
      <LoadContainer>
        <LoadLabel>{label}</LoadLabel>
        {!showMixedField && (
          <LoadInputField
            value={value}
            onChange={this.handleOnChange}
            name="load-input-field"
            useMetric={useMetric}
          />
        )}
        {showMixedField && (
          <LoadMixedField
            type="text"
            value={mixedText}
            onChange={() => undefined}
            onFocus={this.handleOnFocusMixedField}
            name="load-mixed-input-field"
          />
        )}
      </LoadContainer>
    )
  }
}
