import type { DrivingTimeStep } from '@/forms-legacy'

function getBadgeText(value: number): string {
  return `${parseFloat(((Math.abs(value) + 50) / 100).toFixed(2))}x`
}

const steps: DrivingTimeStep[] = [
  {
    label: '0.5x',
    value: 0,
  },
  {
    label: '1x',
    value: -50,
  },
  {
    label: '2x',
    value: -150,
  },
  {
    label: '3x',
    value: -250,
  },
  {
    label: '4x',
    value: -350,
  },
  {
    label: '5x',
    value: -450,
  },
]

export const drivingTimeSliderFieldWrappedProps = {
  min: -450,
  max: 0,
  steps,
  scaleMax: 450,
  getBadgeText,
}
