import type { KeyboardEvent, MouseEvent } from 'react'
import { useCallback, useState, useEffect } from 'react'

import { FlexRow } from '@/forms-legacy'

// import TextInputState, { ChangeEventHandler } from '../../enhancers/TextInputState'

import HourPickerButton from './elements/HourPickerButton'
import HourPickerControls from './elements/HourPickerControls'
import StepControls from './elements/StepControls'
import HourPickerInputText from './elements/HourPickerInputText'

import { format, parse } from './utils'
import { Props } from './typings'
import { fullDayInSecs, defaultStepSecs, halfDayInSecs } from './constants'
import * as Texts from './intl'

export function HourPicker(props: Props) {
  const { value, h24 = false, onChange, readonly, stepSecs = defaultStepSecs, name } = props

  const isAm = value < halfDayInSecs
  const [displayText, setDisplayText] = useState(() => format(value, h24))

  useEffect(() => {
    setDisplayText(format(value, h24))
  }, [value, h24])

  const handleOnKeyUp = useCallback(
    (event: KeyboardEvent<HTMLElement>): void => {
      const { key } = event

      switch (key) {
        case 'ArrowDown': {
          const nextValue = (value - stepSecs) % fullDayInSecs
          onChange(nextValue)
          break
        }
        case 'ArrowUp': {
          const nextValue = (value + stepSecs) % fullDayInSecs
          onChange(nextValue)
          break
        }
      }
    },
    [stepSecs, onChange, value],
  )

  const handleOnChangeAm = useCallback(() => {
    const nextIsAm = !isAm
    onChange(nextIsAm ? value - halfDayInSecs : value + halfDayInSecs)
  }, [isAm, onChange, value])

  const handleOnStepDown = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const valueAsString = event.currentTarget.dataset.value

      if (valueAsString) {
        const currentValue = parseInt(valueAsString, 10)
        onChange(Math.max(0, currentValue - stepSecs))
      }
    },
    [onChange, stepSecs],
  )

  const handleOnStepUp = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const valueAsString = event.currentTarget.dataset.value

      if (valueAsString) {
        const currentValue = parseInt(valueAsString, 10)
        onChange((currentValue + stepSecs) % fullDayInSecs)
      }
    },
    [onChange, stepSecs],
  )

  const handleOnBlur = useCallback(() => {
    const numericValue = parse(displayText, isAm, h24)
    setDisplayText(format(numericValue, h24))
    onChange(numericValue)
  }, [onChange, isAm, h24, displayText, setDisplayText])

  return (
    <FlexRow>
      <HourPickerInputText
        value={displayText}
        name={name}
        onChange={setDisplayText}
        readonly={readonly}
        maxLength={5}
        onKeyUp={handleOnKeyUp}
        onBlur={handleOnBlur}
        renderExtraControls={() => (
          <StepControls value={value} onStepUp={handleOnStepUp} onStepDown={handleOnStepDown} />
        )}
      />

      {!h24 && (
        <HourPickerControls data-testid="hour-picker-mode">
          <HourPickerButton
            active={isAm}
            disabled={readonly || isAm}
            onClick={handleOnChangeAm}
            data-testid="hour-picker-mode__control"
          >
            {Texts.getAmText()}
          </HourPickerButton>
          <HourPickerButton
            active={!isAm}
            disabled={readonly || !isAm}
            onClick={handleOnChangeAm}
            data-testid="hour-picker-mode__control"
          >
            {Texts.getPmText()}
          </HourPickerButton>
        </HourPickerControls>
      )}
    </FlexRow>
  )
}
