import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

/**
 * Update a single place
 *
 * @private
 */
export const updatePlace = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  uui.domain.client.gps.wwgps.Place,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/place/update', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/place/update', {
      category: 'rpc',
      type: 'rpc/place/update',
      payload,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {updatePlace}`,
      { tags: ['rpc', 'place'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Update place failed`,
      error,
    })
  }
})
