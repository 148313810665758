import { ActionBarItem } from '@workwave-tidal/components/ActionBar'
import { Close } from '@workwave-tidal/icons'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../../hooks/useController'
import { useTexts } from './useTexts'

export function Header() {
  const texts = useTexts()
  const {
    close,
    data: { userIds },
  } = useController()

  return (
    <ModalHeader
      title={texts.title(userIds.length)}
      subtitle={texts.subtitle(userIds.length)}
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
    />
  )
}
