import { styled } from '@mui/material'

interface Props {
  textColor: string
  hasViolations: boolean
  backgroundColor: string
}

export const RouteBadge = styled('div', {
  name: 'RouteBadge',
})<Props>(({ theme, textColor, hasViolations, backgroundColor }) => ({
  color: textColor,
  width: 56,
  height: 56,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  borderRadius: 28,
  justifyContent: 'center',
  backgroundColor,

  '&:after': hasViolations
    ? {
        left: '50%',
        width: 46,
        height: 2,
        bottom: -8,
        content: '""',
        position: 'absolute',
        borderRadius: 2,
        transform: 'translateX(-50%)',
        backgroundColor: theme.palette.warning.main,
      }
    : undefined,
}))
