import { ResourceModel } from '@bryntum/schedulerpro'
import { schedulerPreferencesAtom } from '../../schedulerPreferences'

export function totalTimeSorter(item1: ResourceModel, item2: ResourceModel) {
  const totalTime1 = item1.getData(
    'totalTime',
  ) as uui.domain.client.rm.SchedulerResource['totalTime']

  const totalTime2 = item2.getData(
    'totalTime',
  ) as uui.domain.client.rm.SchedulerResource['totalTime']

  if (schedulerPreferencesAtom.preferences.showAbsoluteValues) {
    return totalTime1.absolute - totalTime2.absolute
  }

  return totalTime1.percentage - totalTime2.percentage
}
