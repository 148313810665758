import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    lasso: translate({ id: 'mapcontrols.lasso' }),
    newSelection: translate({ id: 'map.controls.lasso.newSelection' }),
    addToSelection: translate({ id: 'map.controls.lasso.addToSelection' }),
    removeFromSelection: translate({ id: 'map.controls.lasso.removeFromSelection' }),
    quitLasso: translate({ id: 'map.controls.lasso.quitLasso' }),
  }))

  return api
}
