import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    each: translate({ id: 'global.each' }),
    setupCost: translate({ id: 'rm.contextual.depot.setupCost' }),
    setupTime: translate({ id: 'rm.contextual.depot.setupTime' }),
  }))

  return api
}
