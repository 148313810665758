import type Feature from 'ol/Feature'
import { Fill, Style } from 'ol/style'

import Polygon from 'ol/geom/Polygon'
import Geometry from 'ol/geom/Geometry'

type Color = [number, number, number, number]

export function createMainFill(mainColor: Color) {
  return new Style({
    fill: new Fill({
      color: mainColor,
    }),
    geometry: (feature: Feature<Geometry>) => {
      // return the coordinates of the first ring of the polygon
      const geometry = feature?.getGeometry()
      if (geometry instanceof Geometry && geometry.getType() === 'Polygon') {
        const poly = geometry as Polygon

        const coordinates = [...(poly.getCoordinates().at(0) ?? [])]
        if (coordinates.length < 2) return

        coordinates.splice(coordinates.length - 2, 1)
        return new Polygon([coordinates])
      }
    },
  })
}
