import styled from 'styled-components'

const PairedOrderPanelButtonContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

PairedOrderPanelButtonContainer.displayName = 'PairedOrderPanelButtonContainer'
export default PairedOrderPanelButtonContainer
