import type { ExceptionReadonlyViews } from './types'

import { ViewManager } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'

import { useViewDetailsData } from './hooks/useViewDetailsData'
import { ExceptionsViewManager } from './ExceptionsViewManager'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  vehicleSettings: uui.domain.server.rm.VehicleSettings
}

export function Exceptions(props: Props) {
  const { vehicleSettings, unifiedVehicle } = props

  const viewDetailsData = useViewDetailsData(vehicleSettings)

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <ViewManager<ExceptionReadonlyViews>
        view="default"
        render={(view, changeView) => (
          <ExceptionsViewManager
            view={view}
            changeView={changeView}
            viewDetailsData={viewDetailsData}
            unifiedVehicle={unifiedVehicle}
          />
        )}
      />
    </FormColumn>
  )
}
