import { useEffect } from 'react'
import { resetEditingState } from '@/atoms'

export function useResetEditStateOnUnmount() {
  useEffect(() => {
    // Resets the Navigo mode and the paginator on unmount
    return () => {
      resetEditingState()
    }
  }, [])
}
