import type { FormEventHandler } from 'react'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  Radio,
  Button,
  Checkbox,
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@mui/material'

import { selectUserProfile } from '@/features/domain/user'

import { setClientVersionService } from '@/services'
import { useEnvironmentURLs } from '@/atoms'

export function RouteManagerVersionTester() {
  const {
    clientVersion: releasedVersion,
    clientReloadPriority: reloadPriority,
    serverVersion,
    serverInstance,
    authtoken,
  } = useSelector(selectUserProfile)
  const { serverUrl } = useEnvironmentURLs()

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    e => {
      e.preventDefault()
      //@ts-expect-error
      const formData = new FormData(e.target)
      const formProps = Object.fromEntries(formData)

      setClientVersionService({
        debug: formProps.debug === 'on',
        client: formProps.version.toString(),
        //@ts-expect-error
        priority: formProps.priority.toString(),
        authtoken,
        serverUrl,
      })
    },
    [authtoken, serverUrl],
  )

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td width={200}>Client version</td>
            <td>{releasedVersion}</td>
          </tr>
          <tr>
            <td width={200}>Reload priority</td>
            <td>{reloadPriority}</td>
          </tr>
        </tbody>
      </table>

      <hr />

      <table>
        <tbody>
          <tr>
            <td width={200}>Server version</td>
            <td>{serverVersion}</td>
          </tr>
          <tr>
            <td width={200}>Server instance</td>
            <td>{serverInstance}</td>
          </tr>
        </tbody>
      </table>

      <hr />

      <form onSubmit={onSubmit}>
        <TextField name="version" defaultValue={releasedVersion} />

        <h4 style={{ marginTop: 20 }}>
          WARNING: debug = `false` will cause an update of server version and all the clients will
          be notified
        </h4>

        <FormControlLabel control={<Checkbox defaultChecked name="debug" />} label="debug" />

        <FormControl sx={{ marginTop: 1 }}>
          <RadioGroup row defaultValue="normal" name="priority">
            <FormControlLabel value="normal" control={<Radio />} label="Normal" />
            <FormControlLabel value="high" control={<Radio />} label="High" />
            <FormControlLabel value="silent" control={<Radio />} label="Silent" />
          </RadioGroup>
        </FormControl>

        <Button type="submit" variant="contained" sx={{ marginTop: 4 }}>
          Send
        </Button>
      </form>
    </>
  )
}
