import { useSelector } from 'react-redux'

import { Text } from '@/local/components'
import { Tooltip } from '@/components/primitives/Tooltip'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'
import { VerticalLayout, HorizontalLayout, Grow } from '@/components/layout'

import { useTexts } from '../../useTexts'

import { LastPosition } from './components/LastPosition'

interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
}

const growStyle = { padding: '2px 0', maxWidth: 260, minWidth: 260 }

const ellipsisContainerStyle = { maxWidth: '100%', overflow: 'hidden' }

export function Single(props: Props) {
  const { device } = props

  const calendarRange = useSelector(selectCalendarRange)
  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const isToday =
    calendarRange.minDate === calendarRange.maxDate && calendarRange.minDate === userConfig.today

  const name = device.label

  return (
    <Grow
      data-trackid="navigo-device-overview-details-single"
      data-testid="navigo-device-overview-details-single"
      style={growStyle}
      xAxis
    >
      <VerticalLayout justifyContent="space-between">
        <HorizontalLayout alignItems="center">
          <div style={ellipsisContainerStyle}>
            <Tooltip placement="top" title={name}>
              <Text
                data-trackid="navigo-device-overview-details-single-name"
                data-testid="navigo-device-overview-details-single-name"
                size="$xl"
                weight="$semiBold"
                ellipsis
              >
                {name}
              </Text>
            </Tooltip>
          </div>
        </HorizontalLayout>

        {isToday ? (
          <LastPosition device={device} />
        ) : (
          <div style={ellipsisContainerStyle}>
            <Tooltip placement="top" title={texts.livePositionNotAvailable}>
              <Text
                data-trackid="navigo-device-overview-details-single-lastPosition"
                data-testid="navigo-device-overview-details-single-lastPosition"
                size="$h6"
                ellipsis
                italic
              >
                {texts.livePositionNotAvailable}
              </Text>
            </Tooltip>
          </div>
        )}
      </VerticalLayout>
    </Grow>
  )
}
