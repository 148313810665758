import { useRef, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { searchOnMap as searchOnMapAction } from '@/features/domain/ui/actions'
import { useAppDispatch } from '@/store'
import { useGetMapInfo } from '@/map'
import { useIsUnmounted } from '@/hooks'
import { selectTerritoryId } from '@/features/domain/territory'
import { useSearchAssetsOnMap } from './useSearchAssetsOnMap'

interface Options {
  delay?: number
  minLength?: number

  // searchOnMap specific options
  snapToRoad?: boolean
  ignoreMapBounds?: boolean
  assetTypes?: ('depot' | 'place')[]
  excludeOutOfTerritoryItems?: boolean
}

export interface SearchOnMapResult {
  // the entities specified `assetTypes` are searched locally
  items: uui.domain.client.gps.SearchOnMapItem[]
  // then, the local search could be merged with the remote one
  requestStatus: 'idle' | 'pending' | 'success' | 'failure'
}

/**
 * Get a function that searches in the local entities and asks the backend to geocode the address too.
 * While performing a search, the consumer must pass a callback that will be invoked synchronously
 * - with the local search (if enabled by `assetTypes`)
 * - with the cached results
 * and/or asynchronously
 * - with the result of the remote search (merged with the local search)
 *
 * Please note: in the V2 the cache wasn't managed, caching the results has a better UX but could
 * lead to unexpected behaviors, that's why the cache is wiped when the effect is cleaned up.
 */
export const useSearchOnMap = (options: Options) => {
  const {
    delay = 3000,
    minLength = 3,
    assetTypes = [],
    snapToRoad = false,
    ignoreMapBounds = false,
    excludeOutOfTerritoryItems = true,
  } = options
  const timeoutId = useRef<ReturnType<typeof setTimeout> | undefined>()
  const dispatch = useAppDispatch()
  const territoryId = useSelector(selectTerritoryId)
  const searchAssetsOnMap = useSearchAssetsOnMap({ assetTypes })
  const { bounds: mapLatLngBounds } = useGetMapInfo()
  const isUnmounted = useIsUnmounted()

  // only the data that could change often is stored inside `api`
  const api = useRef({ assetTypes, requestIndex: 0 })
  useEffect(() => {
    api.current = { ...api.current, assetTypes }
  }, [assetTypes])

  useEffect(() => {
    return () => {
      clearCache()
    }
  }, [])

  return useCallback(
    (address: string, callback: (result: SearchOnMapResult) => any) => {
      // the previous search must be cancelled, no matter what
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }

      if (address.length < minLength) {
        // happens when the user clear the input field starting from a previous search, the local asset should be shown
        callback({ requestStatus: 'idle', items: searchAssetsOnMap(address) })
        return
      }

      const bounds = ignoreMapBounds ? undefined : mapLatLngBounds

      const cacheId = createCacheId(
        address,
        snapToRoad,
        territoryId,
        excludeOutOfTerritoryItems,
        bounds,
      )
      const cachedItems = getCacheEntry(cacheId)
      if (!!cache[cacheId]) {
        // get the cached results immediately
        callback({ requestStatus: 'idle', items: cachedItems })
        return
      }

      callback({ requestStatus: 'pending', items: searchAssetsOnMap(address) })

      const performSearch = async () => {
        // block old request (if any)
        if (currentSearchIndex !== api.current.requestIndex || isUnmounted()) {
          return
        }

        // search both locally and remotely
        const { assetTypes } = api.current
        const searchRequest = await dispatch(
          searchOnMapAction({
            bounds,
            address,
            snapToRoad,
            assetTypes,
            excludeOutOfTerritoryItems,
          }),
        )

        if (isUnmounted()) {
          return
        }

        if (searchOnMapAction.fulfilled.match(searchRequest)) {
          const items = unwrapResult(searchRequest)
          setCacheEntry(cacheId, items)
          callback({ requestStatus: 'success', items })
        } else {
          // fallback to the local search
          callback({ requestStatus: 'failure', items: searchAssetsOnMap(address) })
        }
      }

      // ticket management
      api.current.requestIndex++
      const currentSearchIndex = api.current.requestIndex
      timeoutId.current = setTimeout(performSearch, delay)
      // allow the consumer to perform custom heuristics on the searching ticket
      return timeoutId.current
    },
    // based on how the `searchOnMap` is consumed, the following data is not likely to change during
    // the lifecycle of the consumers
    [
      delay,
      dispatch,
      minLength,
      snapToRoad,
      isUnmounted,
      territoryId,
      ignoreMapBounds,
      mapLatLngBounds,
      searchAssetsOnMap,
      excludeOutOfTerritoryItems,
    ],
  )
}

// the items could be cached in an IndexedDb clearing the older than 30 days
let cache: Record<string, uui.domain.client.gps.SearchOnMapItem[]> = {}
const clearCache = () => (cache = {})
const getCacheEntry = (id: string) => cache[id]
const setCacheEntry = (id: string, items: uui.domain.client.gps.SearchOnMapItem[]) =>
  (cache[id] = items)
const createCacheId = (
  address: string,
  snapToRoad: boolean,
  territoryId: string,
  excludeOutOfTerritoryItems: boolean,
  bounds?: uui.domain.LatLngBounds,
) =>
  bounds
    ? `${address}|${territoryId}|${bounds.north},${bounds.south},${bounds.west},${
        bounds.east
      }|$snapToRoad:${!!snapToRoad},excludeOutOfTerritoryItems:${excludeOutOfTerritoryItems}`
    : `${address}|${territoryId}|$snapToRoad:${!!snapToRoad},excludeOutOfTerritoryItems:${excludeOutOfTerritoryItems}`
