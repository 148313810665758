import { DropDownField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'
import { useDeviceDropdownValues } from '../hooks/useDeviceDropdownValues'

const wrappedInputProps = {
  defaultToFirstItem: true,
}

export function DeviceId() {
  const texts = useTexts()
  const values = useDeviceDropdownValues()

  return (
    <DropDownField
      name="rm.deviceId"
      testid="rm.deviceId"
      label={texts.dropdownLabel}
      values={values}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
