import { useSelector } from 'react-redux'

import { selectUserProfile } from '@/features/domain/user'
import { WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useTexts } from './useTexts'

interface Props {
  trial: boolean
  allowSmsOverage: boolean
}

export function PlanDetailsWarningCard(props: Props) {
  const { trial, allowSmsOverage } = props

  const userProfile = useSelector(selectUserProfile)
  const texts = useTexts()

  if (trial) {
    return (
      <WarningCard
        preset="warning"
        title={texts.trialTitle}
        description={texts.trialSubtitle}
        Icon={<WarningTriangle size={16} color="$pureWhite" background="$darkOrange" />}
      />
    )
  }

  if (allowSmsOverage) {
    return (
      <WarningCard
        preset="warning"
        title={texts.allowOverageTitle}
        description={texts.allowOverageSubtitle(userProfile.account.details.email)}
        Icon={<WarningTriangle size={16} color="$pureWhite" background="$darkOrange" />}
      />
    )
  }

  return (
    <WarningCard
      preset="warning"
      title={texts.dontAllowOverageTitle}
      description={texts.dontAllowOverageSubtitle(userProfile.account.details.email)}
      Icon={<WarningTriangle size={16} color="$pureWhite" background="$darkOrange" />}
    />
  )
}
