import { Entry } from '../Entry'

export function Entry20210305() {
  return (
    <Entry title="03/05/2021 - v3.0.0-alpha-7">
      <ul>
        <li>The map tooltips of the orders are available</li>
        <li>The Routing Layout is available and persisted locally</li>
        <li>The map markers of the Depots are available and have labels</li>
        <li>The Depot form works</li>
        <li>The Orders form allows creating and deleting orders</li>
        <li>The Inspect Pin is visible on the map</li>
        <li>The map stats in the testers (click on the T-rex icon)</li>
      </ul>
    </Entry>
  )
}
