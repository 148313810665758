import { subscribe } from 'valtio'

import { getMainSelection, getCrudSelection, emptyCrudSelection, emptyMainSelection } from '@/atoms'

import { connectToRenderingQueue } from '../../renderingQueue'
import { getDomainMarkerData } from '../markerChangelog/domain/getDomainMarkerData'
import { getMapSelectionContext } from './read/getMapSelectionContext'
import { mapSelectionAtom } from './mapSelection'
import { updateMapSelection } from './core/updateMapSelection'

const [getTicketAndRunAction] = connectToRenderingQueue()

export const subscribeMapToSelectionContext = () => {
  return subscribe(mapSelectionAtom.context, () => {
    const mapSelectionContext = getMapSelectionContext()
    const { mapMarkers, mapStyles, extendedOrderSteps, breadcrumbTimeRange } = getDomainMarkerData()

    switch (mapSelectionContext.type) {
      case 'main': {
        const nextMainSelection = getMainSelection(true)

        const action = updateMapSelection(
          mapMarkers,
          mapStyles,
          mapSelectionContext,
          nextMainSelection,
          emptyCrudSelection,
          extendedOrderSteps,
          breadcrumbTimeRange,
        )

        if (action) {
          getTicketAndRunAction(action)
        }

        break
      }

      case 'none': {
        // clear all map selections
        const action = updateMapSelection(
          mapMarkers,
          mapStyles,
          mapSelectionContext,
          emptyMainSelection,
          emptyCrudSelection,
          extendedOrderSteps,
          breadcrumbTimeRange,
        )

        if (action) {
          getTicketAndRunAction(action)
        }

        break
      }

      case 'crud': {
        const allCrudSelection = getCrudSelection(true)

        const action = updateMapSelection(
          mapMarkers,
          mapStyles,
          mapSelectionContext,
          emptyMainSelection,
          allCrudSelection,
          extendedOrderSteps,
          breadcrumbTimeRange,
        )

        if (action) {
          getTicketAndRunAction(action)
        }

        break
      }
    }
  })
}
