export function canRenderGpsDataToggler(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  edit?: boolean,
) {
  if (!unifiedVehicle.isPhysicalDevice || unifiedVehicle.trackingProvider !== 'WWGPS') return false
  if (unifiedVehicle.device.virtual) return false

  const capabilities = unifiedVehicle.device.capabilities

  const hasDriverBehaviorCapability = capabilities.includes('drb-threshold')
  const hasIdleThresholdCapability = capabilities.includes('idle-threshold')
  const hasSpeedMonitor = !!unifiedVehicle.speedMonitor

  const gpsTags = unifiedVehicle.allowGps ? unifiedVehicle.gpsTags : []

  return hasDriverBehaviorCapability || hasIdleThresholdCapability || edit || hasSpeedMonitor
    ? true
    : gpsTags.length > 0
}
