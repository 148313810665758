import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function ArrowRoundedUpEdge(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M24.96875 1.03125c-.51172 0-1.01563.17188-1.40625.5625l-7 7c-.78125.78125-.78125 2.0625 0 2.84375s2.0625.78125 2.84375 0l3.5625-3.59375v17.125c0 1.10547.89453 2 2 2s2-.89453 2-2v-17.125l3.59375 3.59375c.78125.78125 2.0625.78125 2.84375 0 .39063-.39063.5625-.89453.5625-1.40625s-.17188-1.04688-.5625-1.4375l-7-7c-.39063-.39063-.92578-.5625-1.4375-.5625zM10.125 6.96875C9.61328 6.94922 9.09375 7.125 8.6875 7.5 3.78906 12.02344.96875 18.44922.96875 25.09375c0 13.17578 10.76563 23.875 24 23.875 13.23438 0 24-10.69922 24-23.875 0-6.70313-2.69922-12.91406-7.625-17.5-.8086-.7539-2.0586-.71484-2.8125.09375-.75.8086-.71484 2.0586.09375 2.8125 4.10156 3.8203 6.34375 9.01172 6.34375 14.59375 0 10.97266-8.97266 19.875-20 19.875s-20-8.90234-20-19.875c0-5.61328 2.29297-10.83203 6.4375-14.65625.8125-.75.875-2 .125-2.8125-.375-.40625-.89453-.63672-1.40625-.65625z" />
      </svg>
    </IconContainer>
  )
}
