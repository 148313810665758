import type { MainSelection } from '@/atoms'

import { subscribeAllMainSelection, emptyCrudSelection } from '@/atoms'
import { connectToRenderingQueue } from '../../renderingQueue'
import { getDomainMarkerData } from '../../atoms/markerChangelog/domain/getDomainMarkerData'

import { getMapSelectionContext } from './read/getMapSelectionContext'
import { updateMapSelection } from './core/updateMapSelection'

const [getTicketAndRunAction] = connectToRenderingQueue()

export function subscribeToMainMapSelection() {
  return subscribeAllMainSelection((mainSelection: MainSelection) => {
    const mapSelectionContext = getMapSelectionContext()

    if (mapSelectionContext.type !== 'main') return

    const { mapMarkers, mapStyles, extendedOrderSteps, breadcrumbTimeRange } = getDomainMarkerData()

    const action = updateMapSelection(
      mapMarkers,
      mapStyles,
      mapSelectionContext,
      mainSelection,
      emptyCrudSelection,
      extendedOrderSteps,
      breadcrumbTimeRange,
    )

    if (action) {
      getTicketAndRunAction(action)
    }
  })
}
