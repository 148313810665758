import { useMemo } from 'react'
import { Divider, ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material'
import { Check } from '@mui/icons-material'

import { SortAscending, SortDescending } from '@/icons'
import { ListHeaderSorter } from '@/components/List'
import { useIsSimulation } from '@/hooks'

import { useTexts } from './hooks/useTexts'
import { useSorter } from './hooks/useSorter'

const fields = ['displayName', 'ui.sort.idx'] as const
const directions = ['asc', 'desc'] as const

export function Sorter() {
  const { field, setField, direction, editing, setDirection, noEntries } = useSorter()
  const isSimulation = useIsSimulation()
  const texts = useTexts()

  const triggerText = useMemo(() => {
    const useDirection = isSimulation
    const text = useDirection
      ? direction === 'asc'
        ? texts.sortByDirection(texts.sortAscending)
        : texts.sortByDirection(texts.sortDescending)
      : texts.sortByField(texts.sortBy(field))

    return text
  }, [direction, isSimulation, texts, field])

  const canShowSortFields = !isSimulation

  if (noEntries) return null

  return (
    <ListHeaderSorter triggerText={triggerText} disabled={editing}>
      {canShowSortFields &&
        fields.map(loopField => {
          const onClick = () => setField(loopField)

          const testId = `list-header-sorter-sortBy-${loopField}`
          const active = field === loopField

          return (
            <MenuItem key={loopField} onClick={onClick} data-testid={testId} data-trackid={testId}>
              {active && (
                <ListItemIcon>
                  <Check color="primary" />
                </ListItemIcon>
              )}
              <ListItemText inset={!active} primary={texts.sortBy(loopField)} />
            </MenuItem>
          )
        })}

      {canShowSortFields && <Divider />}

      {directions.map(loopDirection => {
        const onClick = () => setDirection(loopDirection)
        const testId = `list-header-sorter-direction-${loopDirection}`
        const active = direction === loopDirection
        const label = loopDirection === 'asc' ? texts.sortAscending : texts.sortDescending
        const icon =
          loopDirection === 'asc' ? <SortAscending size={20} /> : <SortDescending size={20} />

        return (
          <MenuItem
            key={loopDirection}
            onClick={onClick}
            data-testid={testId}
            data-trackid={testId}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              align-items="center"
              width="100%"
              gap={2}
            >
              <Stack direction="row">
                {active && (
                  <ListItemIcon>
                    <Check color="primary" />
                  </ListItemIcon>
                )}

                <ListItemText inset={!active} primary={label} />
              </Stack>

              <Stack justifyContent="center" color="action.active">
                {icon}
              </Stack>
            </Stack>
          </MenuItem>
        )
      })}
    </ListHeaderSorter>
  )
}
