import { useExportPlacesModal } from '@/components/modals/ExportPlacesModal'

export function useModalActions(placeIds: string[]) {
  const { show: showExportPlacesModal, Modal: ExportPlacesModal } = useExportPlacesModal()

  return {
    modalActions: {
      showExportPlacesModal,
    },
    modals: <ExportPlacesModal placeIds={placeIds} />,
  }
}
