import type { Props } from './typings'

import { ReadonlyLabel } from '@/forms-legacy'
import { useToggle } from '@/hooks'

import { LoadListItem } from '../LoadListItem'
import SmallLabel from '../SmallLabel'
import SuggestionsListContainer from './SuggestionsListContainer'

const LIMIT = 7

export function SuggestionsList(props: Props) {
  const [useLimit, toggleUseLimit] = useToggle(true)

  const { list, onAddLoad, renderText, filter, filterIsAlreadyUsed } = props

  const moreCount: number = list.length - (LIMIT + 1)
  const hasListItems: boolean = list.length > 0

  const showLoadMore: boolean = useLimit && moreCount > 0

  return (
    <>
      {hasListItems && (
        <>
          <ReadonlyLabel style={{ marginTop: 10 }}>
            {renderText('availableLoads', list.length)}
          </ReadonlyLabel>
          <SuggestionsListContainer>
            {list.map((l: string, idx: number) => {
              if (useLimit && idx > LIMIT) {
                return null
              }
              return (
                <LoadListItem key={l} onClick={() => onAddLoad(l)}>
                  {l}
                </LoadListItem>
              )
            })}
            {showLoadMore && (
              <LoadListItem onClick={toggleUseLimit}>
                {renderText('loadMore', moreCount)}
              </LoadListItem>
            )}
          </SuggestionsListContainer>
          <SmallLabel>{renderText('suggestionsBottomMessage')}</SmallLabel>
        </>
      )}
      {!hasListItems && (
        <SmallLabel>
          {renderText(filterIsAlreadyUsed ? 'alreadyUsed' : 'noResult', undefined, filter)}
        </SmallLabel>
      )}
    </>
  )
}
