import type { MapSelectionContext } from '../mapSelection'
import type { CrudSelectionCategory, MainSelectionCategory } from '@/atoms'

import { useEffect } from 'react'

import { setMapSelectionContext } from '../write/setMapSelectionContext'

export function useSyncMapSelectionContext(
  type: MapSelectionContext['type'],
  category?: CrudSelectionCategory | MainSelectionCategory,
): void {
  useEffect(() => {
    switch (type) {
      case 'none':
        setMapSelectionContext('none')
        break

      case 'main':
        setMapSelectionContext('main', category as MainSelectionCategory)
        break

      case 'crud':
        if (category) {
          setMapSelectionContext('crud', category as CrudSelectionCategory)
        }
        break
    }
  }, [type, category])
}
