import { ResourceModel } from '@bryntum/schedulerpro'

export function costSorter(item1: ResourceModel, item2: ResourceModel) {
  const routeCost1 = item1.getData(
    'routeCost',
  ) as uui.domain.client.rm.SchedulerResource['routeCost']

  const routeCost2 = item2.getData(
    'routeCost',
  ) as uui.domain.client.rm.SchedulerResource['routeCost']

  return routeCost1.current - routeCost2.current
}
