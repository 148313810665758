import AzugaLogoBig from '@/resources/integrations/azuga-logo-big.jpg'
import LinxupLogoBig from '@/resources/integrations/linxup-logo-big.jpg'
import VerizonLogoBig from '@/resources/integrations/verizon-logo-big.jpg'
import AzugaLogoSmall from '@/resources/integrations/azuga-logo-small.png'
import LinxupLogoSmall from '@/resources/integrations/linxup-logo-small.png'
import VerizonLogoSmall from '@/resources/integrations/verizon-logo-small.png'
import GeotabLogoSmall from '@/resources/integrations/geotab-logo-small.png'
import GeotabLogoBig from '@/resources/integrations/geotab-logo-big.jpg'

export const SOURCE_DETAILS = {
  Azuga: {
    logoBigUrl: AzugaLogoBig,
    logoSmallUrl: AzugaLogoSmall,
    descriptionId: 'settings.integrations.view.telematics.azuga.description',
  },
  Linxup: {
    logoBigUrl: LinxupLogoBig,
    logoSmallUrl: LinxupLogoSmall,
    descriptionId: 'settings.integrations.view.telematics.linxup.description',
  },
  'Verizon Connect': {
    logoBigUrl: VerizonLogoBig,
    logoSmallUrl: VerizonLogoSmall,
    descriptionId: 'settings.integrations.view.telematics.verizon.description',
  },
  'Verizon Connect (US)': {
    logoBigUrl: VerizonLogoBig,
    logoSmallUrl: VerizonLogoSmall,
    descriptionId: 'settings.integrations.view.telematics.verizon.description',
  },
  'Verizon Connect (EU)': {
    logoBigUrl: VerizonLogoBig,
    logoSmallUrl: VerizonLogoSmall,
    descriptionId: 'settings.integrations.view.telematics.verizon.description',
  },
  Geotab: {
    logoBigUrl: GeotabLogoBig,
    logoSmallUrl: GeotabLogoSmall,
    descriptionId: 'settings.integrations.view.telematics.geotab.description',
  },
}

export function getSourceDetails(sourceLabel: string) {
  return SOURCE_DETAILS[sourceLabel]
}
