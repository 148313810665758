import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    orders: translate({ id: 'orders.grid.header.orders' }),
    deselectAll: (numberOfOrders: number) =>
      translate({
        id: 'orders.grid.header.contextualMenu.deselectAllOrders',
        values: { numberOfOrders },
      }),
    selectAll: (numberOfOrders: number) =>
      translate({
        id: 'orders.grid.header.contextualMenu.selectAllOrders',
        values: { numberOfOrders },
      }),
    editColumns: translate({
      id: 'orders.grid.header.contextualMenu.editColumns',
    }),
  }))

  return api
}
