import { MapControls } from '@/components/MapControls'
import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'

import { SimulationsRoot } from './form/SimulationsRoot'
import { Map } from './components/Map'

export function Simulations() {
  return (
    <HorizontalLayout data-testid="simulations-root">
      <SimulationsRoot />

      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />

          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
