import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    placeTitle: translate({ id: 'contextualMenu.stylesOnMap.place.title' }),
    placeSubtitle: translate({ id: 'contextualMenu.stylesOnMap.place.subTitle' }),
    placeShow: translate({ id: 'contextualMenu.stylesOnMap.place.show' }),
    placeHide: translate({ id: 'contextualMenu.stylesOnMap.place.hide' }),

    disabledForBeta: translate({ id: 'global.disabledForBeta' }),
  }))

  return api
}
