import styled from 'styled-components'

export const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${p => p.theme.colors.$outrageousRed};
  color: ${p => p.theme.colors.$pureWhite};

  border-width: 0;
  padding: 0;
  margin: 0;
  outline: 0;

  cursor: pointer;

  > * {
    width: 6px;
    height: 6px;
  }
`

RemoveButton.displayName = 'RemoveButton'
