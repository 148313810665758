import { useCallback } from 'react'

import { Stack, FlexBox } from '@/local/components'

import { Kind } from './components/Kind'
import { Status } from './components/Status'
import { SentBy } from './components/SentBy'
import { EmailBody } from './components/EmailBody'
import { Button } from './components/Button'

interface Props {
  item: uui.domain.client.rm.OrderStepEmailNotification
  showModal: (data: uui.domain.client.rm.OrderStepEmailNotification) => void
}

export function EmailNotificationItem(props: Props) {
  const { item, showModal } = props

  const handleOnShowModal = useCallback(() => {
    showModal(item)
  }, [item, showModal])

  return (
    <Stack space="m">
      <Stack space="s">
        <FlexBox vAlignContent="center" w="100%" style={{ justifyContent: 'space-between' }}>
          <Kind kind={item.kind} />
          <Status status={item.status} errorCode={null} />
        </FlexBox>
        <SentBy sentTs={item.sentTs} />
        <EmailBody body={item.bodyPlainText} subject={item.subject} />
      </Stack>
      <Button onClick={handleOnShowModal} />
    </Stack>
  )
}
