import { useCallback } from 'react'

import { Edit } from '@/icons'
import { useEditingStateWithCtx } from '@/atoms'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
}

export function Actions(props: Props) {
  const {
    device: { deviceId },
  } = props

  const { setEditing } = useEditingStateWithCtx('device')
  const startEditing = useCallback(() => setEditing([deviceId]), [setEditing, deviceId])

  const texts = useTexts()

  return (
    <SecondaryColumnHeaderActionsRow>
      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.edit}
            icon={<Edit />}
            onClick={startEditing}
            disabled={preventEditing}
            testid="device-edit"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
