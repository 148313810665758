import { VerticalLayout } from '@/components/layout'
import { WarningCard } from '@/local/components'
import { Check } from '@/icons'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function SuccessBody() {
  const texts = useTexts()
  const { data } = useController()

  const address = data.messageType === 'email' ? data.email : data.phone
  if (!address) return null

  return (
    <VerticalLayout marginTop={32}>
      <WarningCard
        preset="success"
        title={texts.resultSuccessTitle}
        style={{ wordBreak: 'break-word' }}
        Icon={<Check size={14} color="$pigmentGreen" />}
        description={texts.resultSuccessDescription(address)}
      />
    </VerticalLayout>
  )
}
