import { Box } from '@mui/material'

import { HorizontalLayout, VerticalLayout, Grow } from '@/components/layout'
import { Text } from '@/local/components'
import { useFormatSeconds, useFormatDate, useFormatTime, useConvertSpeed } from '@/hooks'

import { useTexts } from './useTexts'

interface Props {
  point: uui.domain.client.gps.wwgps.GpsInfo
}

export function SpeedStop(props: Props) {
  const {
    point: { status, ts: timestamp, stoppedTime, speed: pointSpeed },
  } = props

  const pointDate = new Date(timestamp)

  const duration = useFormatSeconds(stoppedTime)
  const speed = useConvertSpeed(pointSpeed)
  const time = useFormatTime(pointDate, true)
  const date = useFormatDate(pointDate)

  const texts = useTexts()

  return (
    <Grow xAxis>
      <Box minWidth={260} height={46}>
        <VerticalLayout justifyContent="space-between">
          <HorizontalLayout alignItems="center">
            <Text size="$p3">{status === 'moving' ? texts.speed : texts.duration}:&nbsp;</Text>
            <Text size="$p3" weight="$semiBold">
              {status === 'moving' ? speed : duration}
            </Text>
          </HorizontalLayout>

          <HorizontalLayout alignItems="center">
            <Text size="$p3">{status === 'moving' ? texts.date : texts.startedAt}:&nbsp;</Text>
            <Text size="$p3" weight="$semiBold">
              {`${time} • ${date}`}
            </Text>
          </HorizontalLayout>
        </VerticalLayout>
      </Box>
    </Grow>
  )
}
