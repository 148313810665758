import { useTexts } from '../../useTexts'

import { createExecutedCustomFieldColumn } from './createExecutedCustomFieldColumn'
import { createExecutedLoadColumn } from './createExecutedLoadColumn'
import { createCustomFieldColumn } from './createCustomFieldColumn'
import { createLoadColumn } from './createLoadColumn'
import { getValue } from './getValue'

export function createGenericColumn(
  colDef: { field: string; hide: boolean; width: number },
  texts: ReturnType<typeof useTexts>,
  enableDynamicLoads: boolean,
  enableDynamicCustomFields: boolean,
) {
  // If is a load
  if (colDef.field.startsWith('formattedData.loads.'))
    return createLoadColumn(colDef, texts, enableDynamicLoads)

  if (colDef.field.startsWith('formattedData.executedLoads.'))
    return createExecutedLoadColumn(colDef, texts)

  // If is a custom field
  if (colDef.field.startsWith('formattedData.customFields.'))
    return createCustomFieldColumn(colDef, texts, enableDynamicCustomFields)

  if (colDef.field.startsWith('formattedData.executedCustomFields.'))
    return createExecutedCustomFieldColumn(colDef, texts)

  return {
    type: 'string',
    hide: colDef.hide,
    filterable: false,
    field: colDef.field,
    valueGetter: getValue,
    headerName: colDef.field,
    width: colDef.width || 200,
  }
}
