import styled from 'styled-components'
import { BUTTON_SIZE, LEFT_PAD } from '../internals/BaseInputWrapper'
import InputFieldText, { Props } from './InputFieldText'

const getResetRight = ({ multi, showCounter }: Props) =>
  multi ? '-3px' : showCounter ? '12px' : '0px'
const getResetBottom = ({ multi }: Props) => (multi ? '8%' : 'auto')
const getResetButtonTop = ({ multi }: Props) => (multi ? '18%' : '50%')

const input = styled(InputFieldText)<Props>``
const StyledInputFieldText = styled(input).attrs<Props>(() => ({
  className: 'o-input',
}))`
  position: relative;
  width: 100%;

  .o-input-field-text__reset-button {
    position: absolute;
    top: ${getResetButtonTop};
    right: ${LEFT_PAD}px;
    transform: translateY(${-BUTTON_SIZE / 2}px);
  }

  .o-input-field-text__reset-button.is-empty {
    opacity: 1;
    cursor: pointer;
  }

  .o-input-field-text__input-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .o-input-field-text__suggested-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    font-size: ${p => p.theme.fonts.$p3};
    font-weight: ${p => p.theme.weights.$semiBold};

    /* FIXME: shitty hack to align the two texts */
    transform: translateY(1px);
    user-select: none;
    pointer-events: none;
  }

  .o-input-field-text__focus-hint {
    opacity: 0.8;
    font-size: ${p => p.theme.fonts.$p3};
    font-style: italic;
    line-height: 1em;
    padding-left: 4px;
  }

  .o-input-field-text__extra-icon {
    width: 12px;
    opacity: 0.2;
    display: flex;
    margin-right: 6px;
    flex-direction: vertical;
    justify-content: center;
  }

  .o-input-field-text__counter {
    font-size: ${p => p.theme.fonts.$p3};
    font-weight: ${p => p.theme.weights.$semiBold};
    position: absolute;
    right: ${getResetRight};
    bottom: ${getResetBottom};
    margin: auto 1rem;
    font-size: ${p => p.theme.fonts.$xs};
    color: ${p => p.theme.colors.$silver};
  }

  .o-input-field-text__extra-label,
  .o-input-field-text__extra-label-as-link {
    user-select: none;
  }

  .o-input-field-text__extra-label-as-link {
    font-size: ${p => p.theme.fonts.$xs};
    color: ${p => p.theme.colors.$dodgerBlue};
    cursor: pointer;
  }
  .o-input-field-text__extra-label-as-link:hover {
    text-decoration: underline;
  }
`

StyledInputFieldText.displayName = 'StyledInputFieldText'

export default StyledInputFieldText
