import { PureComponent } from 'react'

import { Block, Label, FieldMessage, RestoreFieldButton } from '@/forms-legacy'
import { EligibilityPicker } from '@/components/EligibilityPicker'

import { Props } from './typings'

export default class EligibilityPickerField extends PureComponent<Props> {
  render() {
    const {
      label,
      name,
      info,
      testid,
      formProps: {
        input: { value, onChange },
        meta: { error, dirty, initial },
      },
    } = this.props

    return (
      <Block error={!!error} dirty={!!dirty} data-testid={testid}>
        <Label htmlFor={name}>
          {label}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <EligibilityPicker
          onChange={onChange}
          value={value}
          messages={
            <>
              {!!error && <FieldMessage error>{error}</FieldMessage>}
              {!error && !!info && <FieldMessage>{info}</FieldMessage>}
            </>
          }
        />
      </Block>
    )
  }
}
