import { Box } from '@mui/material'

import { WarningCard } from '@local/components'
import { Cross, WarningTriangle } from '@/icons'

import { useLiveEtaStatus } from '../../../../../../hooks/useLiveEtaStatus'
import { useTexts } from './useTexts'

export function NotificationLimitations() {
  const liveEtaStatus = useLiveEtaStatus()
  const texts = useTexts()

  switch (liveEtaStatus) {
    case 'notAvailableForTheUser':
      return (
        <Box paddingBottom={4}>
          <WarningCard
            description={texts.liveEtaNotAvailableForUserDescription}
            title={texts.liveEtaNotAvailableForUserTitle}
            Icon={<WarningTriangle size={16} color="$pureWhite" background="$darkOrange" />}
            preset="warning"
          />
        </Box>
      )

    case 'notActive':
      return (
        <Box paddingBottom={4}>
          <WarningCard
            description={texts.liveEtaRequiredDescription}
            title={texts.liveEtaRequiredTitle}
            Icon={<Cross size={16} />}
            preset="alert"
          />
        </Box>
      )
    case 'notActiveOnAllVehicles':
      return (
        <Box paddingBottom={4}>
          <WarningCard
            Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
            description={texts.liveEtaRequiredOnAllVehiclesDescription}
            title={texts.liveEtaRequiredOnAllVehiclesTitle}
            preset="warning"
          />
        </Box>
      )
    default:
      return null
  }
}
