import styled from 'styled-components'

export const ConfirmButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 12px;
  padding: 0;
  border-width: 0;
  outline: 0;

  line-height: 1;

  width: 90px;
  height: 18px;

  font-size: ${p => p.theme.fonts.$s};
  font-weight: ${p => p.theme.weights.$semiBold};
  color: ${p => p.theme.colors.$pureWhite};
  background: ${p => p.theme.colors.$scienceBlue};

  border-radius: 4px;

  opacity: ${p => (p.disabled ? 0.3 : 1)};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
`

ConfirmButton.displayName = 'ConfirmButton'
