import { IconButton, Stack } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { Tooltip } from '@/components/primitives/Tooltip'
import { ViewUsersMenu } from '../ViewUsersMenu'
import { useTexts } from '../hooks/useTexts'
import { useActions } from './hooks/useActions'

export function ViewUsersActions() {
  const { onDeleteUsers } = useActions()
  const texts = useTexts()

  return (
    <Stack direction="row" justifyContent="end">
      <Tooltip placement="bottom" title={texts.tooltips.delete}>
        <IconButton onClick={onDeleteUsers}>
          <Delete />
        </IconButton>
      </Tooltip>
      <ViewUsersMenu />
    </Stack>
  )
}
