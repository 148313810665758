import { Text } from '@/local/components'

interface Props {
  data: uui.domain.client.rm.OrderStepEmailNotification
}

export function NotificationSubject(props: Props) {
  const { data } = props

  return (
    <Text size="$p2" weight="$semiBold">
      {data.subject}
    </Text>
  )
}
