export function isPointOutOfTimeRange(
  event: uui.domain.ui.map.markers.DeviceEvent,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  if (breadcrumbTimeRange === 'live') return false

  if (breadcrumbTimeRange.start === -1 || breadcrumbTimeRange.end === -1) return false

  return event.timestamp < breadcrumbTimeRange.start || event.timestamp > breadcrumbTimeRange.end
}
