import { type ReactNode } from 'react'

import { HorizontalDivider } from './HorizontalDivider'

interface Props {
  firstRow: ReactNode
  secondRow?: ReactNode
}

export function DoubleColumnContainer(props: Props) {
  const { firstRow, secondRow } = props

  return (
    <div className="o-scheduler-double-column">
      <div className="o-scheduler-double-column--first-row">{firstRow}</div>
      {secondRow && (
        <>
          <HorizontalDivider />
          <div className="o-scheduler-double-column--second-row">{secondRow}</div>
        </>
      )}
    </div>
  )
}
