import { useMemo } from 'react'
import { HorizontalRadioGroupField } from '@/forms-legacy'

import { useTexts } from './useTexts'
import { useRadioGroupValues } from './useRadioGroupValues'

export function MaintenanceMileage() {
  const texts = useTexts()
  const radioGroupValues = useRadioGroupValues()
  const wrappedInputProps = useMemo(() => {
    return {
      values: radioGroupValues,
    }
  }, [radioGroupValues])

  return (
    <HorizontalRadioGroupField
      name="device.maintenanceMileage"
      testid="device.maintenanceMileage"
      label={texts.label}
      info={texts.info}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
