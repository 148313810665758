// ATTENTION: if you are about to enrich the following Modes, please take a look at the
// Simulations-related RPCs that have some custom exceptions.
type Modes = 'readonly'

const permissions: Record<uui.domain.actions.rpc.RpcAction['type'], Modes[]> = {
  //rpc-account
  'rpc/account/resetApiKey': [],
  'rpc/account/fetchApiKey': ['readonly'],
  'rpc/account/requestSmsSubscriptionChange': [],
  'rpc/account/resetNotificationEmailCounter': [],
  'rpc/account/fetchSmsPhoneNumbers': ['readonly'],
  'rpc/account/updateGpsTimezone': [],

  //rpc-demoTools
  'rpc/demoTools/timeShift': [],
  'rpc/demoTools/requestMaker': ['readonly'],
  'rpc/demoTools/importNewTerritory': [],
  'rpc/demoTools/loadDemoTerritories': [],
  'rpc/demoTools/importDemoTerritory': [],
  'rpc/demoTools/setTenantSourceState': ['readonly'],
  'rpc/demoTools/setTelematicsDeviceStatus': ['readonly'],
  'rpc/demoTools/requestLog/clearRequestLog': ['readonly'],
  'rpc/demoTools/requestLog/setEnabledStatus': ['readonly'],
  'rpc/demoTools/requestLog/enableNotifications': ['readonly'],
  'rpc/demoTools/telematics/debugSendTestDataToTelematics': ['readonly'],
  'rpc/demoTools/telematics/debugSendPeriodicTestDataToTelematics': ['readonly'],

  //rpc-depot
  'rpc/depot/create': [],
  'rpc/depot/update': [],
  'rpc/depot/delete': [],

  //rpc-company
  'rpc/company/create': [],
  'rpc/company/update': [],
  'rpc/company/delete': [],
  'rpc/company/refresh': ['readonly'],

  //rpc-traffic
  'rpc/traffic/updateTimeWindows': [],
  'rpc/traffic/addTrafficProfile': [],
  'rpc/traffic/updateTrafficProfile': [],
  'rpc/traffic/deleteTrafficProfiles': [],
  'rpc/traffic/deleteTrafficAreas': [],
  'rpc/traffic/importTrafficAreasFromKML': [],
  'rpc/traffic/addTrafficArea': [],
  'rpc/traffic/updateTrafficArea': [],

  //rpc-driver
  'rpc/driver/create': [],
  'rpc/driver/update': [],
  'rpc/driver/bulkUpdate': [],
  'rpc/driver/updatePassword': [],
  'rpc/driver/delete': [],
  'rpc/driver/sendMessage': [],
  'rpc/driver/isDriverEmailAvailable': [],
  'rpc/driver/validateDelete': [],

  //rpc-driverAssignments
  'rpc/driverAssignment/delete': [],
  'rpc/driverAssignment/update': [],
  'rpc/driverAssignment/createLocal': [],
  'rpc/driverAssignment/cancelCreateLocal': [],

  //rpc-breadcrumb
  'rpc/breadcrumb/setBreadcrumbTimeRange': ['readonly'],
  'rpc/breadcrumb/reverseGeocodePoint': ['readonly'],
  'rpc/breadcrumb/getBreadcrumbData': ['readonly'],

  //rpc-gps
  'rpc/gps/telematics/editTenantSourceCredentials': ['readonly'],
  'rpc/gps/telematics/validateUpdateTenantSource': ['readonly'],
  'rpc/gps/telematics/getDevicesByTenantSource': ['readonly'],
  'rpc/gps/telematics/validateAddTenantSource': ['readonly'],
  'rpc/gps/telematics/debugCreateDemoDevice': [],
  'rpc/gps/telematics/editTenantSourceLabel': ['readonly'],
  'rpc/gps/telematics/deleteTenantSource': ['readonly'],
  'rpc/gps/telematics/addTenantSource': ['readonly'],
  'rpc/gps/refreshData': ['readonly'],
  'rpc/gps/resumePoll': ['readonly'],
  'rpc/gps/pausePoll': ['readonly'],

  //rpc-geofence
  'rpc/geofence/delete': [],
  'rpc/geofence/create': [],
  'rpc/geofence/update': [],
  'rpc/geofence/refreshLocations': ['readonly'],

  //rpc-alert
  'rpc/eventInfo/invalidate': [],

  //rpc-alert
  'rpc/alert/delete': [],
  'rpc/alert/create': [],
  'rpc/alert/update': [],

  //rpc-place
  'rpc/place/delete': [],
  'rpc/place/create': [],
  'rpc/place/update': [],
  'rpc/place/export': ['readonly'],
  'rpc/place/refreshLocations': ['readonly'],

  //rpc-order
  'rpc/order/move': [],
  'rpc/order/clone': [],
  'rpc/order/fitIn': [],
  'rpc/order/create': [],
  'rpc/order/delete': [],
  'rpc/order/update': [],
  'rpc/order/bulkUpdate': [],
  'rpc/order/unassign': [],
  'rpc/order/deleteSteps': [],
  'rpc/order/swapOrderSteps': [],
  'rpc/order/export': ['readonly'],
  'rpc/order/invertOrderSteps': [],
  'rpc/order/validatePhoneNumbers': [],
  'rpc/order/getExportOrderColumns': ['readonly'],
  'rpc/order/updateExecutionEvents': [],
  'rpc/order/loadOrderStepNotifications': ['readonly'],
  'rpc/order/loadOrderStepUnsubscribeData': ['readonly'],
  'rpc/order/loadOrderStepTrackingLink': ['readonly'],
  'rpc/order/exportArchivedToOperations': ['readonly'],

  //rpc-route
  'rpc/route/build': [],
  'rpc/route/revoke': [],
  'rpc/route/approve': [],
  'rpc/route/build/abort': [],
  'rpc/route/export': ['readonly'],
  'rpc/route/exportByDates': ['readonly'],
  'rpc/route/fetchPolylines': ['readonly'],
  'rpc/route/bulkExportArchived': ['readonly'],
  'rpc/route/exportFromCalendar': ['readonly'],
  'rpc/route/clearPolylinesQueue': ['readonly'],
  'rpc/route/getTravelledDistance': ['readonly'],
  'rpc/route/updateRouteExecutionEvents': [],
  'rpc/route/getExportRouteColumns': ['readonly'],

  //rpc-routeplan
  'rpc/routeplan/orderCounts/update': ['readonly'],

  //rpc-simulations
  // ATTENTION: Simulations can be edited when somebody else is changing the plan but they can't be
  // edited from the same user that is changing the plan.
  'rpc/simulation/create': ['readonly'],
  'rpc/simulation/delete': ['readonly'],
  'rpc/simulation/update': ['readonly'],
  'rpc/simulation/duplicate': ['readonly'],

  'rpc/simulation/loadList': ['readonly'],
  'rpc/simulation/importAllOrdersFromSimulation': [],
  'rpc/simulation/importSomeOrdersFromSimulation': [],

  //rpc-territory
  'rpc/territory/deleteTerritory': [],
  'rpc/territory/renameTerritory': [],
  'rpc/territory/loadSupportedLanguages': [],
  'rpc/territory/configureTelematicsDemo': [],
  'rpc/territory/updateNotificationConfig': [],
  'rpc/territory/copyMobileAppInteraction': [],
  'rpc/territory/updateMobileAppInteraction': [],
  'rpc/territory/updateEarlyNotificationConfig': [],
  'rpc/territory/updateTodayNotificationConfig': [],
  'rpc/territory/updateDelayedNotificationConfig': [],
  'rpc/territory/updateTwoDaysNotificationConfig': [],
  'rpc/territory/copyDynamicLoadsCf': [],
  'rpc/territory/copyReasonsForNotCompletedOrders': [],
  'rpc/territory/updateCanceledNotificationConfig': [],
  'rpc/territory/updateTomorrowNotificationConfig': [],
  'rpc/territory/updateCompletedNotificationConfig': [],
  'rpc/territory/updateAlmostThereNotificationConfig': [],
  'rpc/territory/updateNotCompletedNotificationConfig': [],
  'rpc/territory/updateTerritorySettings': [],
  'rpc/territory/copyStartEndRouteConfiguration': [],
  'rpc/territory/change': ['readonly'],
  'rpc/territory/loadList': ['readonly'],
  'rpc/territory/copyVoiceConfig': ['readonly'],
  'rpc/territory/updateVoiceConfig': ['readonly'],
  'rpc/territory/refreshVoiceConfig': ['readonly'],
  'rpc/territory/testNotificationMessage': ['readonly'],
  'rpc/territory/refreshNotificationConfig': ['readonly'],
  'rpc/territory/fetchCommunicationPlanData': ['readonly'],
  'rpc/territory/buildTrackingPagePreviewUrl': ['readonly'],
  'rpc/territory/refreshMobileAppInteractionSettings': ['readonly'],
  'rpc/territory/refreshReasonsForNotCompletedOrdersSettings': ['readonly'],
  'rpc/territory/fetchTerritoriesDriversAndVehicles': ['readonly'],
  'rpc/territory/getOpsDataFiltered': ['readonly'],

  //rpc-transaction
  'rpc/transaction/goto': [],
  'rpc/transaction/commit': [],
  'rpc/transaction/tryOpen': [],
  'rpc/transaction/rollback': [],
  'rpc/transaction/gotoPrevRevision': [],
  'rpc/transaction/gotoNextRevision': [],
  'rpc/transaction/startImportWizard': [],
  'rpc/transaction/tryClose': ['readonly'],

  //rpc-ui
  'rpc/ui/getPlace': ['readonly'],
  'rpc/ui/searchOnMap': ['readonly'],
  'rpc/ui/reverseGeocode': ['readonly'],
  'rpc/ui/setListOptions': ['readonly'],
  'rpc/ui/resetListOptions': ['readonly'],
  'rpc/ui/gpsReverseGeocode': ['readonly'],
  'rpc/ui/changeCalendarRange': ['readonly'],
  'rpc/ui/changeDeviceIdsForEvents': ['readonly'],
  'rpc/ui/setOrdersFilter': ['readonly'],
  'rpc/ui/updateUserUiSettings': ['readonly'],
  'rpc/ui/mapStyles/resetMapStyles': ['readonly'],
  'rpc/ui/mapStyles/updateMapStyle': ['readonly'],
  'rpc/ui/mapStyles/setCustomMapStyle': ['readonly'],
  'rpc/ui/mapStyles/setGlobalRouteStyle': ['readonly'],
  'rpc/ui/mapStyles/setCustomRouteStyle': ['readonly'],
  'rpc/ui/reverseGeocodeLocationEditorPin': ['readonly'],

  //rpc-user
  'rpc/user/updateSessionUserConfiguration': ['readonly'],
  'rpc/user/updateUserPersonalization': ['readonly'],
  'rpc/user/isUsernameAvailable': ['readonly'],
  'rpc/user/refreshSessionUser': ['readonly'],
  'rpc/user/updateSessionUser': ['readonly'],
  'rpc/user/changeLanguage': ['readonly'],
  'rpc/user/changePassword': ['readonly'],
  // GPS-only users aren't affected by read-only modes. Thus, their user-related actions don't need
  // to be allowed in 'readonly' mode
  'rpc/user/updateGpsUserPersonalization': [],
  'rpc/user/updateGpsUserConfiguration': [],
  'rpc/user/fetchGpsAccountData': [],
  'rpc/user/fetchGpsUserData': [],

  //rpc-users
  'rpc/user/fetchUsersList': ['readonly'],
  'rpc/user/updateUser': ['readonly'],
  'rpc/user/createUser': ['readonly'],
  'rpc/user/enableUser': ['readonly'],
  'rpc/user/deleteUsers': ['readonly'],

  //rpc-vehicle
  'rpc/vehicle/clone': [],
  'rpc/vehicle/delete': [],
  'rpc/vehicle/update': [],
  'rpc/vehicle/create': [],
  'rpc/vehicle/setVehiclesLock': [],
  'rpc/vehicle/deleteException': [],
  'rpc/vehicle/createException': [],
  'rpc/vehicle/toggleVehicleLock': [],
  'rpc/vehicle/cloneMultipleCopies': [],
  'rpc/vehicle/cancelCreateException': [],
  'rpc/vehicle/updateVehiclesSettings': [],
  'rpc/vehicle/updateVehicleTimeWindow': [],

  //rpc-region
  'rpc/region/create': [],
  'rpc/region/update': [],
  'rpc/region/delete': [],
  'rpc/region/importRegionsFromKML': [],

  //rpc-roadSegment
  'rpc/roadSegment/delete': [],
  'rpc/roadSegment/create': [],
  'rpc/roadSegment/update': [],
  'rpc/roadSegment/importRoadSegmentsFromKML': [],

  //rpc (generic)
  'rpc/domainUpdated': ['readonly'],
  'rpc/loadInitialData': ['readonly'],
  'rpc/networkConnectionStatus/update': ['readonly'],
  'rpc/generic/validateEmail': [],

  //rpc support
  'rpc/support/openSupportTicket': ['readonly'],
}

/**
 * Verify that a particular RPC action type can be used while the app is in readOnly mode
 */
export function isActionAllowedInReadonly(type: uui.domain.actions.rpc.RpcAction['type']) {
  return permissions[type].includes('readonly')
}
