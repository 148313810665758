import type { DropDownOption } from '@/components/Dropdown'

import { useMemo } from 'react'

export function useTerritoryDropdownItems(
  territories: Record<string, uui.domain.client.rm.Territory>,
  territoryId: string,
) {
  return useMemo<DropDownOption[]>(
    () =>
      Object.values(territories).map(
        (territory): DropDownOption => ({
          kind: 'item',
          id: territory.id,
          label: territory.name,
          selected: territoryId === territory.id,
        }),
      ),
    [territories, territoryId],
  )
}
