import type { RenderItemProps } from '@/components/StructuredVirtualList'
import { useCallback, useMemo } from 'react'
import { Tag, Timeline } from '@mui/icons-material'

import {
  ListItem,
  ListItemContent,
  ListItemRow,
  ListItemTitle,
  ListItemVisibilityToggler,
  ListItemTag,
} from '@/components/List'

import { useVehicleCalendarizedInfo } from '../hooks/useVehicleCalendarizedInfo'
import { useVisibility } from '../hooks/useVisibility'
import { useLastUpdate } from '../hooks/useLastUpdate'
import { useBadgesInfo } from '../hooks/useBadgesInfo'
import { useTexts } from '../hooks/useTexts'

import { Avatar } from './Avatar'

type Props = Extract<RenderItemProps<'tracking'>, { type: 'item' }>

// const aMinute = 60
// const anHour = 3600
// const aDay = 86400

export function TrackingListItem(props: Props) {
  const { selected, item, onItemClick, onItemDoubleClick } = props

  const texts = useTexts()
  const { label, deviceId } = useVehicleCalendarizedInfo(item)

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])
  const badgesInfo = useBadgesInfo(item)

  const [visible, toggleVisibility] = useVisibility(item.unifiedId)

  const lastUpdate = useLastUpdate(deviceId)

  const lastUpdateLabel = useMemo(() => {
    if (!lastUpdate) return '-'

    // NOTE: That section has been commented out, but it's still here for reference purposes

    // const deltaSeconds = lastUpdate.deltaSeconds

    // if (deltaSeconds < 0) return '-'

    // if (deltaSeconds < aMinute) {
    //   return texts.updatedJustNow
    // }

    // if (deltaSeconds >= aMinute && deltaSeconds < anHour) {
    //   return texts.updatedSomeMinutesAgo(Math.round(deltaSeconds / aMinute))
    // }

    // if (deltaSeconds > anHour && deltaSeconds <= aDay) {
    //   return texts.updatedSomeHoursAgo(Math.round(deltaSeconds / anHour))
    // }

    return texts.lastUpdate(lastUpdate.formattedDate)
  }, [lastUpdate, texts])

  const { hasRoutingLicense } = item
  const showTag = !hasRoutingLicense && badgesInfo.showBadges

  return (
    <ListItem
      onClick={onClick}
      selected={selected}
      onDoubleClick={onDoubleClick}
      invisibleOnMap={!visible}
      avatar={<Avatar vehicle={item} />}
      right={<ListItemVisibilityToggler toggleVisibility={toggleVisibility} visible={visible} />}
    >
      <ListItemTitle
        rightAdornment={showTag ? <ListItemTag label={texts.badgeDevice} /> : undefined}
      >
        {label}
      </ListItemTitle>

      <ListItemRow>
        <ListItemContent icon={<Timeline />} empty={lastUpdate?.deltaSeconds === undefined}>
          {lastUpdateLabel}
        </ListItemContent>
      </ListItemRow>

      <ListItemRow>
        <ListItemContent icon={<Tag />} empty={badgesInfo.hasNoBadges}>
          {badgesInfo.text}
        </ListItemContent>
      </ListItemRow>
    </ListItem>
  )
}

TrackingListItem.displayName = 'TrackingListItem'
