import { useCallback } from 'react'

import { useAppLanguage } from '@/atoms'
import { createIntl } from '@/local/server-data/utils/intl'
import { useIntl } from '@/intl'

import { type Column } from './useController'

export function useCreateColumns() {
  const { translate: enTranslate } = useIntl()
  const [{ language }] = useAppLanguage()

  return useCallback(
    async (
      defs: uui.domain.actions.rpc.order.ExportOrderColumnsDef,
      keepEnglishColumns: boolean,
    ) => {
      try {
        const columns: Column[] = []

        const translate = await createLocalTranslate(keepEnglishColumns, language, enTranslate)
        if (!translate) {
          throw new Error('cannot compte translate function')
        }

        for (const c of defs.columns) {
          columns.push({
            id: c,
            // @ts-expect-error
            label: translate({ id: `modals.exportOrders.column.${c}` }),
          })
        }

        for (const c of defs.cfs) {
          const [value, prefix] = removeCustomFieldPrefix(c)

          columns.push({
            id: c,
            label: translate({
              id: getTranslateIdForCf(prefix),
              values: { value },
            }),
          })
        }

        for (const c of defs.loads) {
          columns.push({
            id: c,
            label: translate({
              id: `modals.exportOrders.column.loads`,
              values: { value: removeLoadPrefix(c) },
            }),
          })
        }

        columns.sort((a, b) => a.label.localeCompare(b.label))

        return columns
      } catch (e) {
        console.error(e)
        return [] as Column[]
      }
    },
    [language, enTranslate],
  )
}

async function createLocalTranslate(
  keepEnglishColumns: boolean,
  language: uui.domain.Language,
  translate: uui.domain.intl.Translate,
) {
  try {
    // user is not interested into having different lang
    if (!keepEnglishColumns) return translate

    // current lang is already english
    if (language === 'en') return translate

    // lang is not en and user want to keep english columns
    const intl = await createIntl('en')

    await intl.ready

    return intl.translate
  } catch (e) {
    console.error(e)
  }
}

function removeCustomFieldPrefix(value: string, prefixes: string[] = ['CF_D_', 'CF_P_', 'CF_S_']) {
  for (const prefix of prefixes) {
    if (value.startsWith(prefix)) {
      return [value.slice(prefix.length), prefix]
    }
  }

  throw new Error('cannot find prefix')
}

function removeLoadPrefix(value: string, prefix: string = 'L_') {
  if (value.startsWith(prefix)) {
    return value.slice(prefix.length)
  }

  return value
}

function getTranslateIdForCf(prefix: string) {
  switch (prefix) {
    case 'CF_D_':
      return 'modals.exportOrders.column.customFields.delivery'

    case 'CF_P_':
      return 'modals.exportOrders.column.customFields.pickup'

    case 'CF_S_':
      return 'modals.exportOrders.column.customFields.service'

    default:
      throw new Error('unrecognized prefix')
  }
}
