import type { AsyncThunkApiConfig } from '@/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

export const isUsernameAvailable = createAsyncThunk<
  // Return type of the payload creator
  boolean,
  // First argument to the payload creator
  string,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/user/isUsernameAvailable', async (username, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/user/isUsernameAvailable', {
      type: 'rpc/user/isUsernameAvailable',
      category: 'rpc',
      username,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        error: response,
        message: response.errorMessage,
      })
    }

    return response.result
  } catch (error) {
    return thunkApi.rejectWithValue({
      error,
      type: 'unknown',
      message: 'Failed to update the User Account',
    })
  }
})
