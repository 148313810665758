import { Stack, Typography } from '@mui/material'

import { useTexts } from '../../hooks/useTexts'

export function Body() {
  const texts = useTexts()

  return (
    <Stack marginTop={4} marginBottom={3}>
      <Typography variant="body2">{texts.doYouConfirm}</Typography>
    </Stack>
  )
}
