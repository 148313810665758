import { Star, House, SquaredDepot, Pin } from '@/icons'

import { AssetListItemProps as Props } from '../../../../typings'

import IconWrapper from './IconWrapper'

const getIconPreset = (asset: uui.domain.client.gps.SearchOnMapItem) => {
  switch (asset.type) {
    case 'gpsPlace':
      return <Star />
    case 'place':
      return <House />
    case 'depot':
      return <SquaredDepot />
    case 'address':
      return <Pin />
  }
}

const AssetListIcon = (props: Props) => {
  const { asset } = props
  return <IconWrapper>{getIconPreset(asset)}</IconWrapper>
}

export default AssetListIcon
