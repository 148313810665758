import styled from 'styled-components'

const ItemRendererAsideLabel = styled.p`
  color: ${p => p.theme.colors.$scienceBlue};
  font-weight: ${p => p.theme.weights.$semiBold};
  font-size: ${p => p.theme.fonts.$p3};
  margin-top: 0;
  margin-bottom: 0;
  user-select: none;
`

ItemRendererAsideLabel.displayName = 'ItemRendererAsideLabel'
export default ItemRendererAsideLabel
