import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    emailLabel: translate({
      id: 'sms4.settings.communication.notifications.testModal.body.label.email',
    }),

    phoneLabel: translate({
      id: 'sms4.settings.communication.notifications.testModal.body.label.phone',
    }),

    phoneHelper: translate({
      id: 'sms4.settings.communication.notifications.testModal.body.helper.phone',
    }),

    send: translate({
      id: 'sms4.settings.communication.notifications.testModal.send',
    }),

    cancel: translate({
      id: 'sms4.settings.communication.notifications.testModal.cancel',
    }),

    ok: translate({
      id: 'sms4.settings.communication.notifications.testModal.ok',
    }),

    close: translate({
      id: 'sms4.settings.communication.notifications.testModal.close',
    }),

    tryAgain: translate({
      id: 'sms4.settings.communication.notifications.testModal.tryAgain',
    }),

    getSuccessTitle: (notificationType: uui.domain.client.rm.NotificationWay) => {
      return translate({
        id:
          notificationType === 'PHONE'
            ? 'sms4.settings.communication.notifications.testModal.phone.success.title'
            : 'sms4.settings.communication.notifications.testModal.email.success.title',
      })
    },

    getSuccessDescription: (notificationType: uui.domain.client.rm.NotificationWay) => {
      return translate({
        id:
          notificationType === 'PHONE'
            ? 'sms4.settings.communication.notifications.testModal.phone.success.description'
            : 'sms4.settings.communication.notifications.testModal.email.success.description',
      })
    },

    getErrorTitle: (notificationType: uui.domain.client.rm.NotificationWay) => {
      return translate({
        id:
          notificationType === 'PHONE'
            ? 'sms4.settings.communication.notifications.testModal.phone.error.title'
            : 'sms4.settings.communication.notifications.testModal.email.error.title',
      })
    },

    getErrorDescription: (notificationType: uui.domain.client.rm.NotificationWay) => {
      return translate({
        id:
          notificationType === 'PHONE'
            ? 'sms4.settings.communication.notifications.testModal.phone.error.description'
            : 'sms4.settings.communication.notifications.testModal.email.error.description',
      })
    },
  }))

  return api
}
