import { useSelector } from 'react-redux'
import { proxy, subscribe, useSnapshot } from 'valtio'

import { selectUserProfileUiData } from '@/features/domain/user'

export type ExportOrdersPreferences = {
  format: 'XLSX' | 'CSV'
  includeBarcodes: boolean
  customizeColumns: boolean // false
  keepEnglishColumns: boolean // false
}

type ExportOrdersPreferencesAtom = {
  preferences: ExportOrdersPreferences
  syncWithUserProfile: boolean
}

// ---------------------------------------------------------------------------
// Default values
// ---------------------------------------------------------------------------
const defaultPreferences: ExportOrdersPreferences = {
  format: 'XLSX',
  includeBarcodes: true,
  customizeColumns: false,
  keepEnglishColumns: false,
}

// ---------------------------------------------------------------------------
// Restore from Local Storage
// ---------------------------------------------------------------------------

const localStorageId = 'routemanager/v1/atoms/exportOrdersPreferences'

const localStorageValue = localStorage.getItem(localStorageId)

const initialSettings = (
  localStorageValue
    ? { ...defaultPreferences, ...JSON.parse(localStorageValue) }
    : defaultPreferences
) as ExportOrdersPreferences

const createDefaultExportOrdersPreferences = (): ExportOrdersPreferencesAtom => ({
  preferences: initialSettings,
  syncWithUserProfile: false,
})

export const exportOrdersPreferencesAtom = proxy<ExportOrdersPreferencesAtom>(
  createDefaultExportOrdersPreferences(),
)

// ---------------------------------------------------------------------------
// Write functions
// ---------------------------------------------------------------------------

type SetExportOrdersPreferences = (prev: ExportOrdersPreferences) => ExportOrdersPreferences
type SetExportOrdersPreferencesParam =
  | SetExportOrdersPreferences
  | Partial<ExportOrdersPreferences>
  | 'reset'

export function resetExportOrdersPreferences() {
  exportOrdersPreferencesAtom.preferences = defaultPreferences
}

export const setExportOrdersPreferences = (valueOrFunc: SetExportOrdersPreferencesParam) => {
  if (valueOrFunc === 'reset') return resetExportOrdersPreferences()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(
      exportOrdersPreferencesAtom.preferences,
      valueOrFunc(exportOrdersPreferencesAtom.preferences),
    )
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      exportOrdersPreferencesAtom.preferences[field] = valueOrFunc[field]
    }
  }

  return exportOrdersPreferencesAtom
}

// ---------------------------------------------------------------------------
// Write to Local Storage
// ---------------------------------------------------------------------------

subscribe(exportOrdersPreferencesAtom.preferences, () => {
  localStorage.setItem(localStorageId, JSON.stringify(exportOrdersPreferencesAtom.preferences))
})

// ---------------------------------------------------------------------------
// React Hooks
// ---------------------------------------------------------------------------

export function useExportOrdersPreferences() {
  const snapshot = useSnapshot(exportOrdersPreferencesAtom)
  const uiData = useSelector(selectUserProfileUiData)

  if (!snapshot.syncWithUserProfile && uiData) {
    syncSnapshotToUserProfileData(uiData)
    exportOrdersPreferencesAtom.syncWithUserProfile = true
  }

  return [snapshot.preferences, setExportOrdersPreferences] as const
}

function syncSnapshotToUserProfileData(uiData: uui.domain.server.rm.UserUISettings) {
  const {
    exportOrders: { format, includeBarcodes },
  } = uiData

  setExportOrdersPreferences({ format, includeBarcodes })
}
