import type { AxiosResponse } from 'axios'

import axios from 'axios'
import { httpDefaultResponse } from './httpDefaultResponse'

type Params = {
  loginnonce?: string
  loginasnonce?: string
  authtoken?: string
}

type LoginRequest = { serverUrl: string } & (
  | {
      mode: 'token'
      token: string
    }
  | {
      mode: 'fromGPS'
      gpsnonce: string
    }
  | {
      mode: 'fromRM'
      loginnonce: string
    }
  | {
      mode: 'loginas'
      loginasnonce: string
    }
  | {
      mode: 'authtoken'
      authtoken: string
    }
)

export async function autoLogin(request: LoginRequest) {
  const params: Params = {}

  switch (request.mode) {
    case 'authtoken':
      params.authtoken = request.authtoken
      break

    case 'token':
      params.authtoken = request.token
      break

    case 'loginas':
      params.loginasnonce = request.loginasnonce
      break

    case 'fromRM':
      params.loginnonce = request.loginnonce
      break

    case 'fromGPS':
      params.loginnonce = request.gpsnonce
      break
  }

  const response: AxiosResponse<uui.domain.server.UserProfile> = await axios.get(
    `${request.serverUrl}/loginuui`,
    {
      params,
    },
  )

  return httpDefaultResponse<uui.domain.server.UserProfile>(response)
}
