import { Button, Link, Stack, Typography } from '@mui/material'
import { Launch } from '@mui/icons-material'

import { NoEntries as Illustration } from '../illustration/NoEntries'
import { useTexts } from '../hooks/useTexts'

import { CenteredLayout } from './CenteredLayout'
import { IllustrationContainer } from './IllustrationContainer'

interface Props {
  onShowModal: () => void
}

export function NoEntries(props: Props) {
  const { onShowModal } = props
  const texts = useTexts()

  return (
    <Stack
      sx={theme => ({
        backgroundColor: theme.palette.background.default,
        flex: '1 1 auto',
        height: 'inherit',
      })}
    >
      <CenteredLayout width={360} testId="companies-hero">
        <IllustrationContainer maxHeight={260} minHeight={100}>
          <Illustration />
        </IllustrationContainer>

        <Stack gap={1} flexShrink={0} alignItems="center" textAlign="center">
          <Typography variant="h6">{texts.hero.title}</Typography>
          <Typography variant="body2">{texts.hero.description}</Typography>
          <Link
            underline="hover"
            href="https://workwave.my.site.com/routemanager/s/article/Create-Companies-in-Route-Manager"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {texts.hero.externalLink}
            <Launch sx={{ ml: 1, fontSize: 'inherit' }} />
          </Link>
        </Stack>
        <Stack flexShrink={0}>
          <Button
            data-testid="companies-no-entries-add-company"
            data-trackid="companies-no-entries-add-company"
            variant="outlined"
            onClick={onShowModal}
          >
            {texts.add}
          </Button>
        </Stack>
      </CenteredLayout>
    </Stack>
  )
}
