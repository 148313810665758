import styled, { css } from 'styled-components'

import { darken } from '@/styles'

import Button from './Button'
import { Props } from './typings'

const BASE_PAD = 6
const ICON_PAD = BASE_PAD
const ICON_SIZE = 14
const ICON_SPACER = 2

export const getPadding = ({ icon }: Props) => {
  if (!icon) return '0'
  return `${ICON_SIZE + ICON_PAD}px`
}

export const getIconPosition = ({ iconPosition }: Props) => iconPosition || 'left'

export const getIconPositionStyles: any = ({ iconPosition }: Props) => {
  switch (iconPosition) {
    case 'top': {
      return css`
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      `
    }
    case 'right': {
      return css`
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      `
    }
    case 'bottom': {
      return css`
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      `
    }
    case 'left':
    default: {
      return css`
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      `
    }
  }
}
const getButtonPadding = (props: Props) => `padding-${getIconPosition(props)}: ${getPadding(props)}`

const StyledButton = styled(Button)`
  display: inline-block;
  padding: ${BASE_PAD}px ${BASE_PAD * 2}px;
  color: ${p => p.theme.colors.$pureWhite};
  background: ${p => p.theme.colors.$pigmentGreen};
  font-size: ${p => p.theme.fonts.$m};
  border-width: 0;
  border-radius: ${BASE_PAD}px;
  line-height: 1;
  transition: background 0.3s ease;
  cursor: pointer;
  outline: 0;

  &:hover {
    background: ${p => darken(p.theme.colors.$pigmentGreen, 20)};
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:disabled:hover {
    background: ${p => p.theme.colors.$pigmentGreen};
  }

  .o-button__inner {
    display: block;
    position: relative;
    ${getButtonPadding};
  }

  .o-button__ico {
    position: absolute;
    width: ${ICON_SIZE + ICON_SPACER}px;
    height: ${ICON_SIZE + ICON_SPACER}px;
    padding: ${ICON_SPACER / 2}px;
    ${props => getIconPositionStyles(props)};
  }
`

StyledButton.displayName = 'StyledButton'

export default StyledButton
