import styled from 'styled-components'

import { applyAlphaToColor } from '@/local/components'

export const ClickableContainer = styled.button`
  position: relative;
  background: transparent;
  padding: 0 4px;
  outline: 0;
  margin: 0;
  border-width: 0;
  border-radius: 4px;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  width: 100%;
  height: auto;
  text-align: left;

  transition: color 0.3s ease, background 0.25s ease-out;
  will-change: color, background;

  &:disabled {
    color: currentColor;
  }

  &:disabled:hover {
    background: ${p => applyAlphaToColor(p.theme.colors.$nightRider, 0.1)};
  }

  &:not(:disabled):hover {
    background: ${p => applyAlphaToColor(p.theme.colors.$scienceBlue, 0.1)};
    color: ${p => p.theme.colors.$scienceBlue};
  }
`

ClickableContainer.displayName = 'ClickableContainer'
