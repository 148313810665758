import tinycolor from 'tinycolor2'

import { Style } from 'ol/style'
import { FeatureLike } from 'ol/Feature'

import { drawFeatureMetadata } from '../utils/drawFeatureMetadata'
import { createMainOutline } from './utils/createMainOutline'
import { createMainFill } from './utils/createMainFill'
import { normalPoint } from './utils/normalPoint'
import { selectedPoint } from './utils/selectedPoint'
import { validSketchPoint } from './utils/validSketchPoint'
import { invalidTemporarySegment } from './utils/invalidTemporarySegment'
import { invalidSketchPoint } from './utils/invalidSketchPoint'

// ATTENTION: remember that the last point of a POLYGON is always a duplicate of the first added to close the shape

type Color = [number, number, number, number]

export const createModifyStyle = (rawMainColor: string) => {
  const baseColor = tinycolor(rawMainColor).toRgb()

  function getMainColor(alpha: number = 1): Color {
    const { r, g, b } = baseColor
    return [r, g, b, alpha]
  }

  const mainOutline = createMainOutline(getMainColor(1))
  const mainFill = createMainFill(getMainColor(0.4))

  const validStyle = [mainFill, mainOutline, normalPoint, selectedPoint, validSketchPoint]

  const invalidStyle = [
    mainFill,
    mainOutline,
    invalidTemporarySegment,
    normalPoint,
    selectedPoint,
    invalidSketchPoint,
  ]

  /**
   * Open Layers Style function used to apply the correct visual stylers to the layer
   */
  return (feature: FeatureLike, _resolution: number): Style[] => {
    // try to access the `invalid` custom field to determine which style must be used
    const invalid = drawFeatureMetadata.isInvalid(feature)

    return invalid ? invalidStyle : validStyle
  }
}
