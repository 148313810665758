import type {
  ExecutionEventMarker,
  PODMarker,
} from '../../../../atoms/executionEventMarkers/executionEventMarkers'

import { Icon, Style } from 'ol/style'

import spriteSheet from '@/resources/map-markers/execution-events.png'

import { getExecutionEventGridPlacement } from '../../coordinates/getExecutionEventGridPlacement'

const mapStylesCache: Map<string, Style> = new Map()

export function getExecutionEventFeatureStyle(
  marker: workwave.DeepReadonly<ExecutionEventMarker | PODMarker>,
) {
  const { type } = marker

  const coordinates = getExecutionEventGridPlacement(type)

  const spriteSheetUrl = spriteSheet
  const cellSize = coordinates[2]
  const x = coordinates[0]
  const y = coordinates[1]

  const cacheId = type

  if (mapStylesCache.has(cacheId)) {
    return mapStylesCache.get(cacheId)
  }

  const style = new Style({
    image: new Icon({
      src: spriteSheetUrl,
      crossOrigin: 'anonymous',

      scale: 0.5,
      opacity: 1.0,
      rotation: 0.0,
      rotateWithView: true,

      offset: [x, y],
      size: [cellSize, cellSize],

      anchor: [0.5, 1],
      anchorOrigin: 'top-left',
      anchorYUnits: 'fraction',
      anchorXUnits: 'fraction',
    }),
  })

  mapStylesCache.set(cacheId, style)

  return style
}
