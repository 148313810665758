import { renderToStaticMarkup } from 'react-dom/server'
import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { selectUserConfiguration } from '@/features/domain/user'
import { store } from '@/store'
import { intl } from '@/intl'

import { StopsCell } from '../../../components/cells/StopsCell'
import { stopsSorter } from '../sorters/stopsSorter'

export function createStopsColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    text: intl.translate({ id: 'rm.scheduler.column.stops' }),
    type: 'template',
    field: 'stopsCount',
    width,
    editor: false,
    hidden,
    region: 'cols',

    template: ({ record, field, value }) => {
      const state = store.getState()
      const { language } = selectUserConfiguration(state)

      return renderToStaticMarkup(
        <StopsCell
          record={record as ResourceModel}
          field={field}
          value={value}
          language={language}
        />,
      )
    },

    sortable: stopsSorter,
    resizable: true,
    groupable: false,
  }
}
