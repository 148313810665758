import { Navigate as RouterNavigate, NavigateProps } from 'react-router'

type Props<ACCEPTED_ROUTES> = NavigateProps & {
  to: ACCEPTED_ROUTES
}

/**
 * Adds type safety to the `Navigate` component.
 */
export function Navigate<ACCEPTED_ROUTES extends string>(
  props: Props<ACCEPTED_ROUTES>,
): React.ReactElement<Props<ACCEPTED_ROUTES>> {
  return <RouterNavigate {...props}></RouterNavigate>
}
