import { useDelayedNotificationValues } from './hooks/useDelayedNotificationValues'

export function convertValues(formValues: ReturnType<typeof useDelayedNotificationValues>) {
  // Convert toleranceMins from string to number
  const toleranceMins = parseInt(formValues.toleranceMins)

  return {
    ...formValues,
    toleranceMins,
  }
}
