import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const actions = useControllerActions()
  const { invalid, close, status } = useController()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          disabled={status !== 'ready' || invalid}
          onClick={actions.onConfirm}
          variant="contained"
        >
          {texts.commandButtonTitle}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton onClick={close}>{texts.cancelButtonTitle}</ModalFooterButton>
      }
    />
  )
}
