import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Archived(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M2.83333 1C2.59667 1 2.37715 1.12606 2.25781 1.33073L1.09115 3.33073C1.03181 3.43273 1 3.54867 1 3.66667V11.6667C1 12.402 1.598 13 2.33333 13H8.33333V11.6667C8.33333 11.0453 8.58921 10.5085 9.00521 10.1471C9.09921 8.3678 10.5267 7 12.3333 7C12.5627 7 12.7853 7.0231 13 7.0651V3.66667C13 3.54867 12.9682 3.43273 12.9089 3.33073L11.7422 1.33073C11.6229 1.12606 11.4033 1 11.1667 1H2.83333ZM3.21615 2.33333H10.7839L11.5612 3.66667H2.4388L3.21615 2.33333ZM5.66667 5H8.33333C8.70133 5 9 5.29867 9 5.66667C9 6.03467 8.70133 6.33333 8.33333 6.33333H5.66667C5.29867 6.33333 5 6.03467 5 5.66667C5 5.29867 5.29867 5 5.66667 5ZM12.3333 8.33333C11.2 8.33333 10.3333 9.2 10.3333 10.3333V11C9.93333 11 9.66667 11.2667 9.66667 11.6667V14.3333C9.66667 14.7333 9.93333 15 10.3333 15H14.3333C14.7333 15 15 14.7333 15 14.3333V11.6667C15 11.2667 14.7333 11 14.3333 11V10.3333C14.3333 9.2 13.4667 8.33333 12.3333 8.33333ZM12.3333 9.33333C12.8667 9.33333 13.3333 9.8 13.3333 10.3333V11H11.3333V10.3333C11.3333 9.8 11.8 9.33333 12.3333 9.33333Z" />
      </svg>
    </IconContainer>
  )
}
