import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    notAvailableInThePast: translate({ id: 'rm.contextual.devices.notAvailableInThePast' }),
    disabledNoMarker: translate({ id: 'rm.contextual.vehicles.noMarker' }),
    disabledForBeta: translate({ id: 'global.disabledForBeta' }),
    centerOnMap: translate({ id: 'rm.contextual.centerOnMap' }),
    runReport: translate({ id: 'gps.reports.runReport' }),
    sendMessage: translate({ id: 'global.sendMessage' }),
    breadcrumbs: translate({ id: 'global.breadcrumbs' }),
    events: translate({ id: 'global.events' }),
    edit: translate({ id: 'global.edit' }),
    disabledTooltipSubtitle: translate({
      id: 'global.disabledTooltip.transactionInProgress.subtitle',
    }),
  }))

  return api
}
