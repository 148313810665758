import { useCallback } from 'react'
import { useCrudSelection } from '@/atoms'
import { useSettingsEditing } from '../../../../hooks/useSettingsEditing'

export function useOnUserEdit() {
  const [, setSelection] = useCrudSelection('users')
  const { onEdit } = useSettingsEditing('user-management', 'none')

  return useCallback(
    (user: uui.domain.client.rm.RouteManagerUser) => {
      setSelection([user.id])
      onEdit()
    },
    [setSelection, onEdit],
  )
}
