import styled from 'styled-components'

const styledComponent = styled.div`
  color: ${p => p.theme.colors.$nightRider};
  font-size: ${p => p.theme.fonts.$p2};
  font-weight: ${p => p.theme.weights.$regular};
  font-style: italic;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

styledComponent.displayName = 'ReadonlyFieldTime'
export default styledComponent
