import type { ReactNode } from 'react'

import type { CrudSelectionCategory, MainSelectionCategory } from '@/atoms'

import { useRef, useEffect } from 'react'

import { gis } from '@/server-data'

import { useMountMap } from '../atoms/map/hooks/useMountMap'
import { useSyncMapScaleControl } from '../atoms/map/hooks/useSyncMapScaleControl'
import { useSyncMapSelectionContext } from '../atoms/selection/hooks/useSyncMapSelectionContext'
import { resetMapTooltip } from '../atoms/mapTooltip'

import { MapTooltipController } from './MapTooltipController'
import { MapContainer } from './MapContainer'
import { RenderingQueueIndicator } from './RenderingQueueIndicator'
import { MapViewport } from './MapVieport'

type Props = {
  mapId?: string
  bottomContentPadding?: number
  center?: uui.domain.LatLng
  children: ReactNode
} & (
  | {
      selectionContext: 'none'
      selectionCategory?: never
    }
  | {
      selectionContext: 'main'
      selectionCategory?: MainSelectionCategory
    }
  | {
      selectionContext: 'crud'
      selectionCategory: CrudSelectionCategory
    }
)

const placeholderContainer = document.createElement('div')

export function Map(props: Props) {
  const {
    mapId = 'map-div',
    center,
    selectionContext,
    selectionCategory,
    bottomContentPadding,
    children,
  } = props

  // The placeholder container helps TS to avoid a merge conflict between
  // React.MutableRefObject and React.LegacyRef down in MapContainer
  const targetRef = useRef(placeholderContainer)

  // Keep scale control synched with the user preferences
  useSyncMapScaleControl()

  // Keep map selection context update
  useSyncMapSelectionContext(selectionContext, selectionCategory)

  // access the map and mount it to the HTML element whose id is set to `mapId`
  const map = useMountMap(targetRef)

  useEffect(() => {
    if (center) {
      map.getView().setCenter(gis.fromLatLngToCoordinate(center))
    }
  }, [map, center])

  // reset tooltips when the map unmounts
  useEffect(() => () => void resetMapTooltip(), [])

  return (
    <MapViewport id="map-viewport">
      <MapContainer
        mapId={mapId}
        targetRef={targetRef}
        bottomContentPadding={bottomContentPadding}
      />

      <RenderingQueueIndicator />

      <MapTooltipController />

      {children}
    </MapViewport>
  )
}
