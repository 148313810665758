import styled from 'styled-components'

interface Props {
  padding?: string
  spaceChildren?: boolean
}

const styledComponent = styled.div<Props>`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 70px;
  background: ${p => p.theme.colors.$whiteSmoke};
  padding: ${p => p.padding ?? '4px 16px'};
  margin-bottom: 8px;
  justify-content: center;

  > * + * {
    margin-top: ${p => (p.spaceChildren ? '20px' : '0')};
  }
`

styledComponent.displayName = 'ColumnHeader'
export default styledComponent
