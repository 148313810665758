import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    errorName: translate({ id: 'setup.depots.form.errors.name' }),
    errorSetupCost: translate({ id: 'setup.depots.form.errors.setupCost' }),
    errorSetupTime: translate({ id: 'setup.depots.form.errors.setupTime' }),
    errorTitle: translate({ id: 'setup.depots.form.formErrorsTitle' }),
    errorLocation: translate({ id: 'setup.depots.form.errors.location' }),
    labelName: translate({ id: 'setup.depots.fields.depotName' }),
    labelNameInfo: translate({ id: 'setup.depots.fields.depotNameInfo' }),
    labelSetupCost: translate({ id: 'setup.depots.fields.setupCost' }),
    labelSetupTime: translate({ id: 'setup.depots.fields.setupTime' }),
    labelLocation: translate({ id: 'setup.depots.fields.location' }),
    labelColor: translate({ id: 'setup.depots.fields.color.title' }),
    labelColorInfo: translate({ id: 'setup.depots.fields.color.info' }),
    headerTitle: translate({ id: 'setup.depots.edit.title' }),
    headerDescription: translate({ id: 'setup.depots.edit.subtitle' }),
    footerTitle: translate({ id: 'setup.routing.edit.formErrorsTitle' }),
    submit: (id: string) =>
      id.length === 0
        ? translate({ id: 'setup.depots.edit.createDepot' })
        : translate({ id: 'setup.depots.edit.saveDepot' }),
    submitTitle: (valid: boolean, planLocked: boolean, pristine: boolean) => {
      if (!valid) {
        return translate({
          id: 'depots.form.submitButton.disabled.title.invalid',
        })
      }
      if (planLocked) {
        return translate({
          id: 'depots.form.submitButton.disabled.title.planLocked',
        })
      }

      if (pristine) {
        return translate({
          id: 'depots.form.submitButton.disabled.title.pristine',
        })
      }
      return ''
    },
    cancel: translate({ id: 'global.cancel' }),
    change: translate({ id: 'global.change' }),
  }))

  return api
}
