import type { LocationEditorFieldWrappedInputProps } from '@/forms-legacy'

import { LocationEditorField } from '@/forms-legacy'

import { useTexts } from './useTexts'

const wrappedInputProps: LocationEditorFieldWrappedInputProps = {
  modes: ['copyCoordinates'],
  height: 150,
}

export function Address() {
  const texts = useTexts()

  return (
    <LocationEditorField
      name="location"
      pinType="place"
      snapToRoad={false}
      useMapBounds={true}
      inactive={false}
      pinId="placeFormPin"
      ignoreMapBounds={false}
      label={texts.label}
      wrappedInputProps={wrappedInputProps}
      testid="depot-address"
    />
  )
}
