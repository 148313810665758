import { useState } from 'react'

import { useConfigureModalController, useModalController, useCloseModal } from '@/atoms'

type ModalState = 'ready'
type ModalData = {
  targetLayout: 'split' | 'map'
}

export const modalId = 'extractedMapWarningModal'

export const useController = () => useModalController<ModalData, ModalState>(modalId)

export const useConfigureController = (targetLayout: 'split' | 'map') => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        targetLayout,
      },
    }),
  }))

  return useConfigureModalController<ModalData, ModalState>(modalId, options)
}
