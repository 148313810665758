import type { RecurrencesMap } from '../../typings'
import type { DateCalendarPublicProps } from '@/components/Calendar'

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  startOfMonth,
  eachDayOfInterval,
  parse,
  subDays,
  min,
  format,
  isSameDay,
  addYears,
} from 'date-fns/esm'
import { selectUserConfiguration } from '@/features/domain/user'

const now = new Date()

export function useDateCalendarProps(
  recurrenceMap: RecurrencesMap,
  onChangeCallback: (date: Date) => void,
  date: Date | null,
): DateCalendarPublicProps {
  const { today, startFromDate, planType } = useSelector(selectUserConfiguration)

  const refDate = new Date()
  const isSimulation = planType === 'simulation'
  const firstNonArchivedDay = isSimulation ? undefined : parse(startFromDate, 'yyyyMMdd', refDate)
  const todayDate = parse(today, 'yyyyMMdd', now)

  const selection = useMemo<Date>(() => {
    return date ?? todayDate
  }, [date, todayDate])

  const onChange = useCallback(
    (newSelection: Date, currentSelection?: Date) => {
      if (currentSelection && isSameDay(currentSelection, newSelection)) {
        return
      }
      onChangeCallback(newSelection)
    },
    [onChangeCallback],
  )

  return useMemo(() => {
    const firstDayDisabled = startOfMonth(firstNonArchivedDay ?? todayDate)
    const lastDayDisabled = min([firstNonArchivedDay ?? firstDayDisabled, subDays(todayDate, 1)])
    const disableInterval = isSameDay(todayDate, firstDayDisabled)
      ? []
      : eachDayOfInterval({
          start: firstDayDisabled,
          end: lastDayDisabled,
        })

    const disabledDays = disableInterval.reduce((acc, date) => {
      const key = format(date, 'yyyyMMdd')
      acc[key] = 1
      return acc
    }, {})

    const minDate = format(firstDayDisabled, 'yyyyMMdd')
    const maxDate = format(addYears(todayDate, 2), 'yyyyMMdd')

    const daysWithRecurrences =
      !!recurrenceMap && recurrenceMap['DATE']
        ? recurrenceMap['DATE'].reduce((acc, curr) => {
            if (curr.value) {
              const key = format(curr.value, 'yyyyMMdd')
              acc[key] = 1
            }
            return acc
          }, {})
        : undefined

    return {
      today,
      layout: 'date',
      selection,
      onChange,
      disabledDays,
      highlightedDays: daysWithRecurrences,
      minDate,
      maxDate,
      months: 1,
    }
  }, [onChange, recurrenceMap, today, selection, todayDate, firstNonArchivedDay])
}
