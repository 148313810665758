import type { MonthData, Days, DayProps, YYYYMMDD, SelectionAsRecord } from '../../typings'

import { useMemo } from 'react'
import { eachDayOfInterval, format, getDay, isSameDay, subDays } from 'date-fns/esm'

import { useDateFnsOptions } from '@/hooks'
import { parseDate } from '../../utils/parseDate'

import { matchHighlightProducer } from './matchHighlightProducer'

const emptyDay = {
  type: 'empty',
} as const

type Props = {
  selection: SelectionAsRecord
  today: YYYYMMDD
  firstNonArchivedDay?: YYYYMMDD
  highlightedDays?: SelectionAsRecord
  disabledDays?: SelectionAsRecord
  months: MonthData[]
}

type DaysByMonth = Record<string, Days>

export function useDatesDays(props: Props): DaysByMonth {
  const options = useDateFnsOptions()
  const {
    today,
    firstNonArchivedDay,
    highlightedDays = {},
    disabledDays,
    selection,
    months,
  } = props

  return useMemo<DaysByMonth>(() => {
    const todayDate = parseDate(today)

    const firstNonArchivedDayDate = !!firstNonArchivedDay
      ? parseDate(firstNonArchivedDay)
      : undefined

    const daysByMonth: DaysByMonth = {}

    for (const month of months) {
      const { offset, start, end, id: monthId } = month

      const dates = eachDayOfInterval({
        start,
        end,
      })

      const days = dates.map<DayProps>(date => {
        const label = format(date, 'd', options)
        const dateAsString = format(date, 'yyyyMMdd')

        const selected = selection.hasOwnProperty(dateAsString)
        const disabled = !!disabledDays && !!disabledDays[dateAsString]

        const cosmetic = disabled ? 'grayed-out' : selected ? 'edge-of-selection' : 'normal'

        const dayNumber = getDay(date)
        const firstOfRow = dayNumber === options.weekStartsOn

        const isToday = isSameDay(todayDate, date)

        return {
          leftSeparator: firstNonArchivedDayDate && isSameDay(firstNonArchivedDayDate, date),
          dot: matchHighlightProducer(highlightedDays)(dateAsString),
          dotColor: '$scienceBlue',
          underline: isToday,
          id: dateAsString,
          bold: isToday,
          type: 'day',
          firstOfRow,
          disabled,
          cosmetic,
          isToday,
          label,
          date,
        }
      })

      const firstDay = days[0]
      const lastDay = days[days.length - 1]
      daysByMonth[monthId] = []

      // Let's calculate empty days before the first day paying attention if is the first of the row
      for (let i = offset[0]; i > 0; i--) {
        daysByMonth[monthId].unshift({
          ...emptyDay,
          firstOfRow: getDay(subDays(firstDay.date, i)) === options.weekStartsOn,
        })
      }

      daysByMonth[monthId].push(...days)

      // Let's calculate empty days after the last day paying attention if is the first of the row
      for (let i = 0; i < offset[1]; i++) {
        daysByMonth[monthId].push({
          ...emptyDay,
          firstOfRow: getDay(subDays(lastDay.date, i)) === options.weekStartsOn,
        })
      }
    }

    return daysByMonth
  }, [months, options, today, firstNonArchivedDay, highlightedDays, disabledDays, selection])
}
