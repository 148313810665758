import type { PairedOrder } from './useSelectedOrders'

import { useCallback, useEffect, useState } from 'react'

import { useReadOnly } from '@/hooks'
import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

import { useSelectedOrders } from './useSelectedOrders'

export type ModalMode = 'empty' | 'single' | 'bulk'
type ModalState = 'ready' | 'pending' | 'invalidForEmptyOrders'
interface ModalData {
  mode: ModalMode
  deleteAlsoPaired: boolean
  pairedOrders?: PairedOrder[]
  selectedOrders: uui.domain.client.rm.ExtendedOrderStep[]
}

export const modalId = 'deleteOrder'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = () => {
  const close = useCloseModal()
  const { readonly } = useReadOnly()
  const { selectedOrders, pairedOrders } = useSelectedOrders()

  const getModalMode = useCallback((): ModalMode => {
    if (selectedOrders.length > 1) return 'bulk'
    if (selectedOrders.length > 0) return 'single'
    return 'empty'
  }, [selectedOrders.length])

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        pairedOrders,
        selectedOrders,
        mode: getModalMode(),
        deleteAlsoPaired: true,
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const {
    data: { mode },
    status,
    update,
    updateData,
  } = ctrl

  // EFFECTS

  // External transaction
  useEffect(() => {
    switch (readonly) {
      case true:
        update({ status: 'pending' })
        break

      case false:
        if (status !== 'pending') return

        update({ status: 'ready' })
        break
    }
  }, [readonly, status, update])

  // Invalid state management
  useEffect(() => {
    updateData({ mode: getModalMode() })
  }, [getModalMode, updateData])

  // Invalid state management
  useEffect(() => {
    if (mode === 'empty') {
      update({ invalid: true, status: 'invalidForEmptyOrders' })
      return
    }

    update({ invalid: false, status: 'ready' })
  }, [mode, update])

  return ctrl
}
