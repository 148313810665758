import { PureComponent } from 'react'

import { intl } from '@/intl'

import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'

import { ReadonlyField } from './ReadonlyField'
import { ReadonlyFieldRow } from './ReadonlyFieldRow'
import { ReadonlyFieldLabel } from './ReadonlyFieldLabel'
import { ReadonlyFieldSmallLabel } from './ReadonlyFieldSmallLabel'
import { ReadonlyTimeWindowContainer } from './ReadonlyTimeWindowContainer'
import { ReadonlyTimeWindowGroup } from './ReadonlyTimeWindowGroup'

const getFromText = (): string => intl.translate({ id: 'global.from' })

const getToText = (): string => intl.translate({ id: 'global.to' })

export interface Props {
  timeWindow: uui.domain.rm.TimeWindow
}

export class ReadonlyTimeWindow extends PureComponent<Props> {
  render() {
    const {
      timeWindow: { startSec, endSec },
    } = this.props

    return (
      <ReadonlyTimeWindowContainer data-testid="time-window">
        <ReadonlyField>
          <ReadonlyFieldRow style={{ justifyContent: 'space-between' }}>
            <ReadonlyTimeWindowGroup>
              <ReadonlyFieldSmallLabel>{getFromText()}</ReadonlyFieldSmallLabel>
              <ReadonlyFieldLabel data-testid="time-window__start">
                <ConvertTime seconds={startSec} showSeconds={false}></ConvertTime>
              </ReadonlyFieldLabel>
            </ReadonlyTimeWindowGroup>

            <ReadonlyTimeWindowGroup>
              <ReadonlyFieldSmallLabel>{getToText()}</ReadonlyFieldSmallLabel>
              <ReadonlyFieldLabel data-testid="time-window__end">
                <ConvertTime seconds={endSec} showSeconds={false}></ConvertTime>
              </ReadonlyFieldLabel>
            </ReadonlyTimeWindowGroup>
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyTimeWindowContainer>
    )
  }
}
