import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'

import { isUserAuthenticated } from '@/features/domain/user'
import { useNavigate } from '@/routing'
import { WarningCircle } from '@/icons'
import { theme } from '@/styles'

import { useTexts } from './useTexts'

const useStyles = makeStyles(() => ({
  root: {
    top: 0,
    left: 0,
    zIndex: 9999,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.colors.$pureWhite,
  },
  title: {
    marginTop: 20,
    marginBottom: 10,
    textAlign: 'center',
    fontSize: theme.fonts.$h1,
    fontWeight: theme.weights.$semiBold,
  },
  subtitle: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: theme.fonts.$h2,
  },
}))

export function TooManySessions() {
  const authenticated = useSelector(isUserAuthenticated)
  const navigate = useNavigate(true)
  const classes = useStyles()
  const texts = useTexts()

  // Redirects the user to the home if is authenticated
  useEffect(() => {
    if (authenticated) {
      navigate('/')
    }
  }, [navigate, authenticated])

  return (
    <div className={classes.root}>
      <WarningCircle size={40} color="$outrageousRed" />

      <div className={classes.title}>{texts.tooManyOpenTabsTitle}</div>
      <div className={classes.subtitle}>{texts.tooManyOpenTabsSubtitle}</div>
    </div>
  )
}
