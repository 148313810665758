import type { MutableRefObject, ChangeEvent } from 'react'

import { useEffect, useRef } from 'react'

import { TextField } from '@mui/material'

import { Adornment } from '../Adornment'
import { useInteractions } from './useInteractions'

interface Props {
  inputRef: MutableRefObject<HTMLInputElement | undefined>
  name: string
  value: string
  label: string
  testId?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onClear: () => void
  helperText?: string
  placeholder?: string
  error?: boolean
  errorText?: string
  multiline?: boolean
  disabled?: boolean
  required?: boolean
  validateOnBlur: () => void
  validateOnFocus: () => void
  autoFocus?: boolean
}

export function ExactField(props: Props) {
  const {
    placeholder,
    helperText,
    label,
    name,
    onChange,
    onClear,
    inputRef,
    required,
    error,
    errorText,
    disabled,
    validateOnBlur,
    validateOnFocus,
    value,
    multiline,
    autoFocus,
    testId,
  } = props

  const { onFocus, onBlur, adornmentHidden, onMouseEnter, onMouseLeave } = useInteractions(
    value,
    validateOnFocus,
    validateOnBlur,
  )

  // HACK: if multiLine flag is true the autofocus will not behave correctly
  // this mechanism ensure that the input has the focus and the text caret is at the end of the line
  // issue: https://codesandbox.io/s/goofy-boyd-uhvhz
  const rValueLength = useRef(value.length)
  useEffect(() => {
    if (!autoFocus) return
    const range = rValueLength.current
    inputRef.current?.focus()
    inputRef.current?.setSelectionRange(range, range)
  }, [inputRef, autoFocus])

  return (
    <TextField
      inputRef={inputRef}
      name={name}
      data-testid={testId}
      label={label}
      required={required}
      helperText={error ? errorText : helperText}
      error={error}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      multiline={multiline}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      InputProps={{
        endAdornment: <Adornment onClick={onClear} hidden={adornmentHidden} />,
      }}
    />
  )
}
