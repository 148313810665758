import { ConfirmPanel as ConfirmPanelComp } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'
import { useActions } from './hooks/useActions'

interface Props {
  event: uui.domain.client.gps.wwgps.EventInfo
  view: 'default' | 'delete'
  setView: (view: 'default' | 'delete') => void
}

export function ConfirmPanel(props: Props) {
  const { setView, event } = props

  const actions = useActions(event, setView)
  const texts = useTexts()

  return (
    <ConfirmPanelComp
      title={texts.title}
      description={texts.description}
      confirmationText={texts.confirmation}
      onCancel={actions.onCancel}
      onConfirm={actions.onConfirm}
      testid="gps-events-invalidate-event"
    />
  )
}
