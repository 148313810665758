import type { SetMainSelectionParam } from '../write/setMainSelection'
import type { MainSelection } from '../typings'

import { useSnapshot } from 'valtio'
import { useCallback } from 'react'

import { mainAtom } from '../main'
import { setMainSelection } from '../write/setMainSelection'

export const useMainSelection = <Category extends keyof MainSelection = keyof MainSelection>(
  category: Category,
) => {
  return [
    useSnapshot(mainAtom).selection[category],
    useCallback(
      (params: SetMainSelectionParam<Category>) => setMainSelection(category, params),
      [category],
    ),
  ] as const
}
