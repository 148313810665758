import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRoutesInfo } from '@/features/domain/scheduler'

export function useCanUnassign(orders: uui.domain.client.rm.ExtendedOrderStep[]) {
  const routesInfo = useSelector(selectRoutesInfo)

  return useMemo(() => {
    return orders.every(o => {
      if (o.isUnassigned) return true

      // if route is locked, the order cannot be unassigned
      return routesInfo[o.routeId]?.locked ? false : true
    })
  }, [routesInfo, orders])
}
