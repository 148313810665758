import type OlMap from 'ol/Map'

import { createNonBlockingRenderByTime } from '../../renderingQueue'

import { setLayerMetadata, getLayerMetadata } from '../layerMetadata'
import { findLayer } from '../utils/findLayer'
import { findFeature } from '../findFeature'

import { findOrderMarker } from './utils/findOrderMarker'
import { getOrderFeatureStyle } from './utils/getOrderFeatureStyle'
import { getOrderFeatureMetadata } from './orderFeatureMetadata'
import { getOrderLayerMetadata } from './orderLayerMetadata'

const nonblockingRender = createNonBlockingRenderByTime()

export const resetOrderFeaturesEditingState = async (
  map: OlMap,
  mapMarkers: uui.domain.ui.map.markers.MapMarkers['order'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['order'],
) => {
  const layer = findLayer(map, 'order')

  // retrieve features hidden because of an editing state
  const orderMarkerIds = getLayerMetadata(layer, 'editingEntities') as Set<string>

  // retrieve selected features
  const layerSelection = getLayerMetadata(layer, 'selection') as Set<string>
  const selectedOrderIds = getOrderLayerMetadata(layer, 'selectedOrderIds') as Set<string>

  if (!orderMarkerIds || orderMarkerIds.size === 0) return

  nonblockingRender.reset()

  for (const markerId of orderMarkerIds) {
    await nonblockingRender.next()

    const marker = findOrderMarker(mapMarkers, markerId)
    if (!marker) {
      console.warn(
        `Trying to select Order marker with lat-lng: ${markerId} for ID: ${markerId} but no feature exist on the map`,
      )
      continue
    }

    const feature = findFeature(layer, markerId)

    if (!feature) {
      throw new Error(
        `Trying to select Order marker with lat-lng: ${markerId} for ID: ${markerId} but no feature exist on the map`,
      )
    }

    const mode: uui.domain.ui.map.markers.MapStyles['order']['mode'] =
      getOrderFeatureMetadata(feature, 'mode') ?? mapStyles.mode

    const selected = layerSelection?.has(markerId)

    // retrieve the right icon
    const style = getOrderFeatureStyle(marker, mode, selectedOrderIds, selected)
    feature.setStyle(style)
  }

  // reset stored data
  setLayerMetadata(layer, 'editingEntities', new Set())
}
