import type { AggregateCf } from '../types'

import { Stack } from '@mui/material'
import { ReadonlyLabel } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'
import { AggregateCustomField as AggregateCustomFieldUi } from './AggregateCustomField'

interface Props {
  aggregateCustomFields: AggregateCf[]
}

export function DynamicCustomFields(props: Props) {
  const { aggregateCustomFields } = props
  const texts = useTexts()

  const testId = 'orders-view-custom-fields'

  return (
    <Stack data-testid={testId}>
      <ReadonlyLabel primary>{texts.customFields.title}</ReadonlyLabel>

      {aggregateCustomFields.map(aggregateCustomField => (
        <AggregateCustomFieldUi
          key={aggregateCustomField.label}
          aggregateCustomField={aggregateCustomField}
        />
      ))}
    </Stack>
  )
}
