import type { PublicProps as Props } from './typings'
import { PureComponent } from 'react'

import TagsList from './TagsList'
import defaultRenderText from './TagsListDefaultTexts'

interface State {
  suggestions: string[]
  value: string[]
  filter: string
}

const computeSuggestions = (
  allSuggestions: string[],
  currentValues: string[],
  fil: string,
  caseSensitive: boolean = false,
): string[] => {
  return allSuggestions.reduce((acc: string[], s: string) => {
    const suggestion: string = caseSensitive ? s : s.toLowerCase()
    const filter: string = caseSensitive ? fil : fil.toLowerCase()

    const isAlreadyUsed: boolean = currentValues.includes(suggestion)
    const isIncludedInFilter: boolean = suggestion.startsWith(filter)

    if (!isAlreadyUsed && isIncludedInFilter) {
      acc.push(s)
    }

    return acc
  }, [])
}

const computeList = (list: string[], filter: string): string[] =>
  list.filter((listItem: string) => listItem.startsWith(filter))

const setFilter = (filter: string) => () => ({
  filter,
})

const setSuggestions = (suggestions: string[]) => () => ({
  suggestions,
})
const setValue = (value: string[]) => () => ({
  value,
})

export default class TagsListContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    const { value, suggestions } = props

    const filter: string = ''
    this.state = {
      suggestions: computeList(suggestions, filter),
      value: computeList(value, filter),
      filter,
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { value: prevValue, suggestions: prevSuggestions } = prevProps
    const { value, suggestions, caseSensitive } = this.props
    const { filter: prevFilter } = prevState
    const { filter } = this.state

    if (prevValue !== value || prevSuggestions !== suggestions || filter !== prevFilter) {
      const valueWithCaseSensitive: string[] = caseSensitive
        ? value
        : value.map(s => s.toLowerCase())

      this.setState(
        setSuggestions(
          computeSuggestions(suggestions, valueWithCaseSensitive, filter, caseSensitive),
        ),
      )
      this.setState(setValue(computeList(value, filter)))
    }
  }

  private handleOnAddTag = (tag: string): void => {
    const { onChange, value } = this.props
    onChange([...value, tag])
  }

  private handleOnRemoveTag = (tag: string): void => {
    const { onChange, value } = this.props
    const updatedValue: string[] = value.filter((val: string) => val !== tag)
    onChange(updatedValue, [tag])
  }

  private handleOnChangeFilter = (filter: string) => {
    this.setState(setFilter(filter))
  }

  render() {
    const { filter, value: stateValue, suggestions: stateSuggestions } = this.state
    const { suggestions, value, renderText = defaultRenderText, maxCount, ...props } = this.props

    return (
      <TagsList
        {...props}
        value={stateValue}
        filter={filter}
        maxCount={maxCount}
        onAddTag={this.handleOnAddTag}
        renderText={renderText}
        onRemoveTag={this.handleOnRemoveTag}
        suggestions={stateSuggestions}
        onChangeFilter={this.handleOnChangeFilter}
      />
    )
  }
}
