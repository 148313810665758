import { Entry } from '../Entry'

export function Entry20230614() {
  return (
    <Entry title="Jun 14 2023 - v3.4.3">
      Link to the{' '}
      <a
        href="https://workwave.atlassian.net/projects/RM/versions/13793/tab/release-report-all-issues"
        target="_blank"
        rel="noreferrer"
      >
        Jira Release
      </a>
    </Entry>
  )
}
