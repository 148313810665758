import tinycolor from 'tinycolor2'

import { ValidColor } from './typings'
import { themeColor } from './theme'

export const getColorWithAlpha = (preset: ValidColor, rawAlpha: number): string => {
  const color = themeColor[preset]

  if (!color) {
    throw new Error(`[Theme Error]: no color found for preset, ${preset}`)
  }

  const alpha = Math.min(Math.max(rawAlpha, 0), 1)

  if (alpha !== rawAlpha) {
    console.warn(`[Theme]: ${rawAlpha} is not in [0, 1]`)
  }

  return tinycolor(color).setAlpha(alpha).toString()
}

export const applyAlphaToColor = (color: string, rawAlpha: number): string => {
  if (!color.startsWith('#')) {
    throw new Error(
      `[Theme Error]: applyAlphaToColor expects an hexadecimal color string, received: ${color}`,
    )
  }

  const alpha = Math.min(Math.max(rawAlpha, 0), 1)

  if (alpha !== rawAlpha) {
    console.warn(`[Theme]: ${rawAlpha} is not in [0, 1]`)
  }

  return tinycolor(color).setAlpha(alpha).toString()
}

export const normalizeCssValue = (value: string | number): string => {
  if (typeof value === 'number' || `${parseInt(value)}` === value) {
    return `${value}px`
  }
  return value
}

export const roundToEven = (rawValue: number) => {
  const value = Math.round(rawValue)
  return value % 2 === 0 ? value : value + 1
}
