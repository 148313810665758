import styled from 'styled-components'

interface Props {
  disabled?: boolean
}

export const SectionHeaderContainer = styled.div<Props>`
  margin: 30px 0;

  > * {
    color: ${p => (p.disabled ? p.theme.colors.$silver : p.theme.colors.$nightRider)};
  }
`

SectionHeaderContainer.displayName = 'SectionHeaderContainer'
