import { useMemo } from 'react'

import { ReadonlyField, ReadonlyFieldRow, ReadonlyLabel } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'

interface Props {
  notificationAddress: string
}

export function Address(props: Props) {
  const { notificationAddress } = props
  const texts = useTexts()

  const { type, address } = useAddressInfo(notificationAddress)

  return (
    <ReadonlyField>
      <ReadonlyFieldRow style={{ justifyContent: 'space-between' }}>
        <ReadonlyLabel>{address}</ReadonlyLabel>
        <ReadonlyLabel>{type === 'email' ? texts.email : texts.sms}</ReadonlyLabel>
      </ReadonlyFieldRow>
    </ReadonlyField>
  )
}

function useAddressInfo(notificationAddress: string) {
  return useMemo(() => {
    const separatorIndex = notificationAddress.indexOf(':')

    const prefix = notificationAddress.slice(0, separatorIndex)
    const address = notificationAddress.slice(separatorIndex + 1)

    return {
      type: prefix === 'mailto' ? 'email' : 'sms',
      address,
    }
  }, [notificationAddress])
}
