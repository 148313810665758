import { useSelector } from 'react-redux'

import { selectTerritoryLicensingLimits } from '@/features/domain/territory'
import { selectOrders } from '@/features/domain/order'
import { useIsSimulation } from './useIsSimulation'

export function useNumberOfAddableOrders() {
  const { ordersOps, ordersSim } = useSelector(selectTerritoryLicensingLimits)
  const orders = useSelector(selectOrders)
  const isSimulation = useIsSimulation()

  const numberOfOrders = Object.keys(orders).length

  if (isSimulation) return ordersSim - numberOfOrders

  return ordersOps - numberOfOrders
}
