import { useSelector } from 'react-redux'
import { differenceInHours } from 'date-fns/esm'

import { VerticalLayout, Grow, HorizontalLayout } from '@/components/layout'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectLivePositions } from '@/features/domain/device'
import { ConvertDistance } from '@/components/smartUtils/conversion/ConvertDistance'
import { Text } from '@/local/components'

import { timeUtils, conversionUtils, geo } from '@/server-data'

import { TrackingStatus } from './components/TrackingStatus'
import { useTexts } from './useTexts'

interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
}

const growStyle = { height: 46 }
const HISTORICAL_GPS_POSITION_THRESHOLD_HOURS = 48

export function DeviceInfo(props: Props) {
  const { device } = props

  const userConfig = useSelector(selectUserConfiguration)
  const livePositions = useSelector(selectLivePositions) as Record<
    string,
    uui.domain.client.gps.wwgps.GpsInfo
  >

  const lastPosition = livePositions[device.deviceId]

  const texts = useTexts()

  if (!lastPosition) return null
  const lastReport = new Date(lastPosition.ts)
  const time = timeUtils.formatTime(userConfig)(lastReport, true)
  const date = timeUtils.formatDate(userConfig)(lastReport)

  const isHistorical =
    differenceInHours(new Date(), new Date(lastPosition.ts)) >
    HISTORICAL_GPS_POSITION_THRESHOLD_HOURS

  const showExtraStatus = !isHistorical

  let extraStatus = ''

  if (showExtraStatus) {
    switch (lastPosition.status) {
      case 'moving':
        extraStatus = `(${geo.computeCardinalDirection(
          lastPosition.heading,
        )} • ${conversionUtils.convertGpsSpeed(lastPosition.speed, userConfig)})`

        break

      case 'stopped':
      case 'stalling':
        if (lastPosition.stopTimestamp) {
          extraStatus = `(${texts.since} ${timeUtils.formatDate(userConfig)(lastReport)})`
        }

        break
    }
  }

  return (
    <Grow
      data-trackid="navigo-device-overview-info"
      data-testid="navigo-device-overview-info"
      style={growStyle}
      xAxis
    >
      <HorizontalLayout>
        <VerticalLayout justifyContent="space-between" width="auto">
          <HorizontalLayout
            alignItems="center"
            data-trackid="navigo-device-overview-info-deviceMovement"
            data-testid="navigo-device-overview-info-deviceMovement"
          >
            <TrackingStatus lastPosition={lastPosition} />

            <Text size="$p3" weight="$semiBold">
              {texts.status(lastPosition, isHistorical)}&nbsp;
            </Text>

            <Text size="$p3">{extraStatus}</Text>
          </HorizontalLayout>

          <HorizontalLayout
            alignItems="center"
            data-trackid="navigo-device-overview-info-lastPosition"
            data-testid="navigo-device-overview-info-lastPosition"
          >
            <Text size="$p3">{texts.lastReport}:&nbsp;</Text>

            <Text size="$p3" weight="$semiBold">
              {`${time} • ${date}`}
            </Text>
          </HorizontalLayout>
        </VerticalLayout>

        {!isNaN(lastPosition.odometer) && (
          <VerticalLayout justifyContent="space-between" width="auto" marginLeft={24}>
            <HorizontalLayout
              alignItems="center"
              height="50%"
              data-trackid="navigo-vehicle-overview-info-odometer"
              data-testid="navigo-vehicle-overview-info-odometer"
            >
              <Text size="$p3">{texts.odometer}:&nbsp;</Text>

              <Text size="$p3" weight="$semiBold">
                <ConvertDistance meters={lastPosition.odometer} />
              </Text>
            </HorizontalLayout>
          </VerticalLayout>
        )}
      </HorizontalLayout>
    </Grow>
  )
}
