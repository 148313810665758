import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'settings.rmAccount.view.headerTitle' }),
    headerDescription: translate({ id: 'settings.rmAccount.view.headerDescription' }),

    name: translate({ id: 'settings.rmAccount.view.name' }),
    surname: translate({ id: 'settings.rmAccount.view.surname' }),
    username: translate({ id: 'settings.rmAccount.view.username' }),
    email: translate({ id: 'settings.rmAccount.view.email' }),
    apiKey: translate({ id: 'settings.rmAccount.view.apiKey' }),

    missingApiKey: translate({ id: 'settings.rmAccount.view.missingApiKey' }),
  }))

  return api
}
