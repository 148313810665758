import { intl } from '@/intl'

export const getAmText = (): string =>
  intl.translate({
    id: 'global.am',
  })

export const getPmText = (): string =>
  intl.translate({
    id: 'global.pm',
  })
