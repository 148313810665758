import { type ReactNode } from 'react'
import { Stack, styled } from '@mui/material'

const Root = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  width: '100%',
}))

export type Props = {
  title: ReactNode
  filter?: ReactNode
  sorter?: ReactNode
  actions?: ReactNode
}

export function ListHeader(props: Props) {
  const { title, actions, filter, sorter } = props

  return (
    <Root gap={0.5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={0.5}>
        {title}
        {actions}
      </Stack>
      {filter}
      {sorter}
    </Root>
  )
}
