import styled from 'styled-components'

import { applyAlphaToColor } from '@/local/components'

type Props = {
  bottomSpace?: boolean
  bigBottomSpace?: boolean
}

const SecondaryColumnContentHorizontalRuler = styled.div<Props>`
  flex: 0 0 auto;
  height: 1px;
  min-height: 0;
  background: ${p => applyAlphaToColor(p.theme.colors.$pureBlack, 0.12)};

  margin-bottom: ${p => (p.bigBottomSpace ? 20 : p.bottomSpace ? 10 : 0)}px;
`

SecondaryColumnContentHorizontalRuler.displayName = 'SecondaryColumnContentHorizontalRuler'
export default SecondaryColumnContentHorizontalRuler
