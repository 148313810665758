import { type SVGProps } from 'react'
import { clsx } from '@/utils'

type Props = SVGProps<SVGSVGElement>

export function VisibilityTwoTone(props: Props) {
  const { className, ...rest } = props

  return (
    <svg
      className={clsx(className, 'o-scheduler-svg-icon')}
      focusable="false"
      aria-hidden="true"
      {...rest}
      viewBox="0 0 24 24"
      data-testid="visibility-two-tones-icon"
    >
      <path
        d="M12 4C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 13c-3.79 0-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6s7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17zm0-10c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7zm0 7c-1.38 0-2.5-1.12-2.5-2.5S10.62 9 12 9s2.5 1.12 2.5 2.5S13.38 14 12 14z
      "
      />
    </svg>
  )
}
