import { type ResourceModel } from '@bryntum/schedulerpro'
import { timeUtils } from '@/server-data'

import { schedulerPreferencesAtom } from '../../../atoms/schedulerPreferences'
import { DoubleColumnContainer } from '../../Scheduler/components/DoubleColumnContainer'
import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../Scheduler/components/Typography'
import { useTexts } from './useTexts'

interface Props {
  userConfig: uui.domain.UserConfiguration
  record: ResourceModel
  value: uui.domain.client.rm.SchedulerResource['routeStart']
}

export function RouteStart(props: Props) {
  const { value, userConfig } = props
  const texts = useTexts()

  const { showApprovedRoutes } = schedulerPreferencesAtom.preferences

  if (!value) return '-'

  const formatTime = timeUtils.formatTime(userConfig)

  const start = value.current ? formatTime(new Date(value.current.value)) : '-'

  const startSuffix =
    value.current && value.current.extraDays >= 1
      ? `(+${value.current.extraDays} ${value.current.extraDays > 1 ? texts.days : texts.day})`
      : ''

  const startTimeString = `${start} ${startSuffix}`.trim()

  if (showApprovedRoutes && value.approved) {
    const approvedStart = value.approved ? formatTime(new Date(value.approved.value)) : '-'

    const approvedStartSuffix =
      value.approved && value.approved.extraDays >= 1
        ? `(+${value.approved.extraDays} ${value.approved.extraDays > 1 ? texts.days : texts.day})`
        : ''

    const approvedStartString = `${approvedStart} ${approvedStartSuffix}`.trim()
    return (
      <DoubleColumnContainer
        firstRow={<Typography fontSize={12}>{startTimeString}</Typography>}
        secondRow={<Typography fontSize={10}>{approvedStartString}</Typography>}
      />
    )
  }

  return (
    <SingleColumnContainer>
      <Typography fontSize={12}>{startTimeString}</Typography>
    </SingleColumnContainer>
  )
}
