import { useMemo } from 'react'

import { NumericInputField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  metric: boolean
  currency: string
}

export function ServiceTimeCost(props: Props) {
  const { metric, currency } = props
  const texts = useTexts()

  const wrappedInputProps = useMemo(() => {
    return {
      step: 10,
      min: 0,
      useMetric: metric,
    }
  }, [metric])

  return (
    <NumericInputField
      name="serviceTimeCost"
      testid="serviceTimeCost"
      half
      label={texts.serviceTimeCost(`(${currency}/hr)`)}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
