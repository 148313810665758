import {
  composeOrderIconOffsetRequest,
  getOrderLabelIconOffset,
  getOrderIconOffset,
} from '@/local/server-data/utils/map'
import { parseOrderStepId } from '@/local/server-data/utils/orders'
import * as orderHelpers from '@/local/server-data/domain/helpers/order'
import { isDarkColor } from '@/local/server-data/utils/colors'
import * as gis from '@/local/server-data/utils/gis'

import { getOrdersInList } from '../../../helpers/changesets/markers/order/getOrdersInList'
import { getOrderMarkerMode } from '../../../helpers/order'
import { getOrderBadgesIconOffset } from '../../../../utils/map/orderBadges'
import { BaseOrderIconOffsetRequest } from '../../../../utils/map/orders/getOrderIconOffset'

type OrderMarkers = Record<string, uui.domain.ui.map.markers.Order>

interface GetMarkerIdsOutput {
  ids: Record<string, uui.domain.ui.map.markers.OrderStepIdMarkerVisibility>
  visibleOrderSteps: string[]
}

export const createOrderMapMarkers = (
  orders: uui.domain.PublicDomain['rm']['orders'],
  orderMapStyle: uui.domain.ui.map.markers.MapStyles['order'],
  orderStepsByLatLng: Record<string, string[]>,
  orderStepsIdInRange: Record<string, true>,
  orderStepsListStructure: uui.domain.ui.list.ListStructure,
): OrderMarkers => {
  const markers: OrderMarkers = {}

  const ordersInList = getOrdersInList(orderStepsListStructure)

  for (const [orderStepLatLng, orderStepIds] of Object.entries(orderStepsByLatLng)) {
    /**
     * Remove orderStepIds:
     * - out of the calendar range
     * - with an invalid latLng
     */
    const unfilteredOrderStepIds = orderStepIds.filter(id => {
      const { orderId } = parseOrderStepId(id)
      const order = orders[orderId]
      return orderStepsIdInRange[id] && hasValidLatLng(id, order)
    })

    // skip it if no orders is in range
    if (unfilteredOrderStepIds.length === 0) continue

    if (orderStepLatLng === gis.nullIslandLatLngString) continue

    // create the marker
    markers[orderStepLatLng] = createOrderMapMarker(
      orders,
      orderMapStyle,
      orderStepLatLng,
      unfilteredOrderStepIds,
      ordersInList,
    )
  }

  return markers
}

function hasValidLatLng(orderStepId: string, order?: uui.domain.client.rm.ExtendedOrder) {
  if (!order) return false

  const orderStep = orderHelpers.getTransportOrderStep(orderStepId, order)
  const latLng = orderStep.atDepot ? orderStep.depot.location.latLng : orderStep.location.latLng
  return !gis.isNullIslandLatLng(latLng)
}

export function isVisibleOrder(
  visibilityData: uui.domain.ui.map.markers.OrderStepIdMarkerVisibility,
  orderMapStyle: uui.domain.ui.map.markers.MapStyles['order'],
) {
  const mapStyle = visibilityData.mapStyle ?? orderMapStyle.mode
  return mapStyle !== 'off' && !visibilityData.filtered
}

export function getMarkerIdsMap(
  orderStepIds: string[],
  ordersInList: Record<string, true>,
  orderMapStyle: uui.domain.ui.map.markers.MapStyles['order'],
) {
  return orderStepIds.reduce<GetMarkerIdsOutput>(
    (acc, orderStepId) => {
      // Let's calculate visibility map for the orderStepId
      acc.ids[orderStepId] = {
        filtered: !ordersInList[orderStepId],
        mapStyle: orderMapStyle.custom[orderStepId],
      }

      // If the order is not filtered or with an `off` mapStyle let's add it to the visible orders
      if (isVisibleOrder(acc.ids[orderStepId], orderMapStyle)) {
        acc.visibleOrderSteps.push(orderStepId)
      }

      return acc
    },
    { ids: {}, visibleOrderSteps: [] },
  )
}

export function computeOrderBadge(
  request: BaseOrderIconOffsetRequest,
): uui.spriteSheet.BadgeCells | undefined {
  switch (request.state) {
    case 'normal':
      return

    case 'withViolations':
      return 'violations'

    case 'complete':
      return request.violations ? 'completeViolations' : 'complete'

    case 'notComplete':
      return request.violations ? 'notCompleteViolations' : 'notComplete'

    case 'withUnassigned':
      return request.violations ? 'unassignedViolations' : 'unassigned'
  }
}

export function computeOrderMarkerAspect(
  ids: Record<string, uui.domain.ui.map.markers.OrderStepIdMarkerVisibility>,
  visibleOrderSteps: string[],
  orders: uui.domain.PublicDomain['rm']['orders'],
) {
  const params = composeOrderIconOffsetRequest(visibleOrderSteps, orders)
  const label = computeOrderMarkerLabel(visibleOrderSteps, orders, params.color)
  const badge = computeOrderBadge(params)

  return {
    ids,
    label,
    mode: visibleOrderSteps.length === 0 ? 'off' : getOrderMarkerMode(ids),
    color: params.color,
    minimized: getOrderIconOffset(true),
    maximized: getOrderIconOffset(false),
    badgeMaximized: badge ? getOrderBadgesIconOffset(badge, 'normal') : undefined,
    badgeMinimized: badge ? getOrderBadgesIconOffset(badge, 'small') : undefined,
  }
}

function computeOrderMarkerLabel(
  orderStepIds: string[],
  orders: uui.domain.PublicDomain['rm']['orders'],
  markerColor: string,
): uui.domain.ui.map.markers.OrderLabel | undefined {
  /**
   * If there are no orderSteps or got just one orderStep
   * and the visibility is `off` we can bail out.
   *
   * NOTE: Markers with more than one orderStep should be computed
   * also if the visibility is `off` (stacked markers with plus symbol)
   */
  if (orderStepIds.length === 0) return

  const color = isDarkColor(markerColor) ? ('white' as const) : ('black' as const)

  if (orderStepIds.length > 99) {
    return { color, mainText: getOrderLabelIconOffset({ label: '99+', labelSize: 'normal' }) }
  }

  if (orderStepIds.length > 1) {
    return {
      color,
      mainText: getOrderLabelIconOffset({
        label: `x${orderStepIds.length}`,
        labelSize: 'normal',
      }),
      minimizedText: getOrderLabelIconOffset({
        label: `+`,
        labelSize: 'normal',
      }),
    }
  }

  const { orderId, orderStepType } = parseOrderStepId(orderStepIds[0])
  const extOrder = orders[orderId]

  if (!extOrder) {
    throw new Error('Cannot compute label for an unexistent order')
  }

  switch (orderStepType) {
    case 'p':
      if (extOrder.type !== 'p' && extOrder.type !== 'pd') {
        throw new Error('Error during the computation of the orderStep label')
      }

      if (extOrder.extendedPickup.isUnassigned) return

      const pickupLabel = extOrder.extendedPickup.routeStepLabel.split('.')

      if (pickupLabel.length !== 1 && pickupLabel.length !== 2) {
        throw new Error('Erorr during label computation')
      }

      switch (pickupLabel.length) {
        case 1:
          return {
            color,
            mainText: getOrderLabelIconOffset({
              label: pickupLabel[0],
              labelSize: 'normal',
            }),
          }

        case 2:
          return {
            color,
            mainText: getOrderLabelIconOffset({
              label: pickupLabel[1],
              labelSize: 'normal',
            }),
            secondaryText: getOrderLabelIconOffset({
              label: pickupLabel[0],
              labelSize: 'small',
            }),
          }
      }

    case 'd':
      if (extOrder.type !== 'd' && extOrder.type !== 's' && extOrder.type !== 'pd') {
        throw new Error('Error during the computation of the orderStep label')
      }

      if (extOrder.extendedDelivery.isUnassigned) return

      const deliveryLabel = extOrder.extendedDelivery.routeStepLabel.split('.')

      if (deliveryLabel.length !== 1 && deliveryLabel.length !== 2) {
        throw new Error('Error during label computation')
      }

      switch (deliveryLabel.length) {
        case 1:
          return {
            color,
            mainText: getOrderLabelIconOffset({
              label: deliveryLabel[0],
              labelSize: 'normal',
            }),
          }
        case 2:
          return {
            color,
            mainText: getOrderLabelIconOffset({
              label: deliveryLabel[1],
              labelSize: 'normal',
            }),
            secondaryText: getOrderLabelIconOffset({
              label: deliveryLabel[0],
              labelSize: 'small',
            }),
          }
      }

    default:
      return
  }
}

export function createOrderMapMarker(
  orders: uui.domain.PublicDomain['rm']['orders'],
  orderMapStyle: uui.domain.ui.map.markers.MapStyles['order'],
  orderStepLatLng: string,
  orderStepIds: string[],
  ordersInList: Record<string, true>,
): uui.domain.ui.map.markers.Order {
  if (orderStepIds.length === 0) {
    throw new Error('Unable to create an order marker without any orderStepId')
  }

  const latLng = gis.stringToLatLng(orderStepLatLng)
  const lonLat = gis.fromLatLngToCoordinate(latLng)

  const { ids, visibleOrderSteps } = getMarkerIdsMap(orderStepIds, ordersInList, orderMapStyle)

  const { color, ...aspect } = computeOrderMarkerAspect(ids, visibleOrderSteps, orders)

  const marker: uui.domain.ui.map.markers.Order = {
    id: orderStepLatLng,
    color,
    lonLat,
    ...aspect,
  }

  return marker
}
