import styled from 'styled-components'

const styledComponent = styled.div`
  display: flex;
  justify-content: flex-start;
  color: ${p => p.theme.colors.$pureBlack};
  background: transparent;
  font-weight: ${p => p.theme.weights.$semiBold};
  font-size: ${p => p.theme.fonts.$m};
`

styledComponent.displayName = 'FormColumnHeaderPrimaryText'
export default styledComponent
