import { Box } from '@mui/material'

import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'

import { Footer } from './Footer'
import { Header } from './Header'
import { Body } from './Body/Body'

type Props = {
  targetLayout: 'split' | 'map'
}

export function Content(props: Props) {
  const { ready } = useConfigureController(props.targetLayout)

  if (!ready) return null

  return (
    <ModalContent header={<Header />} footer={<Footer />}>
      <Box paddingTop={2} maxWidth={400}>
        <Body />
      </Box>
    </ModalContent>
  )
}
