import { createGlobalStyle } from 'styled-components'

export const CssNormalize = createGlobalStyle`
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    * {
      font-family: "Roboto","Helvetica","Arial",sans-serif
    }

    html,
    body {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.008em;
    }

    svg {
      display: block;
      color: inherit;
      line-height: 0;
      font-size: 0;
    }


    input[type="button" i],
    input[type="submit" i],
    input[type="reset" i],
    input[type="file" i]::-webkit-file-upload-button,
    button {
      letter-spacing: inherit;
    }

    /* For Firefox */
    input[type='number'] {
        -moz-appearance:textfield;
    }

    /* Webkit browsers like Safari and Chrome */
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
  `
