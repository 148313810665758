import type { RegionValidation, RegionFormValues } from '../typings'

import { useTexts } from './useTexts'

const validateForm =
  (texts: ReturnType<typeof useTexts>) =>
  (formValues: RegionFormValues): RegionValidation => {
    const { name, enterCost, enterTimeSec, points } = formValues

    const validation: RegionValidation = {}

    if (!name) {
      validation.name = texts.errorName
    }

    if (enterCost < 0) {
      validation.enterCost = texts.errorCost
    }

    if (enterTimeSec < 0) {
      validation.enterTimeSec = texts.errorTime
    }

    if (points.length < 3) {
      validation.points = texts.errorPoints
    }

    return validation
  }

export const useValidateForm = () => {
  const texts = useTexts()
  return validateForm(texts)
}
