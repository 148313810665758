import { useCallback } from 'react'

import { Trash, Edit } from '@/icons'
import { useEditingStateWithCtx } from '@/atoms'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'

import { useIsDepotLinkedToOrderSteps } from '../../../hooks/useIsDepotLinkedToOrderSteps'
import { useTexts } from '../hooks/useTexts'

interface Props {
  depot: uui.domain.client.rm.Depot
  view: 'default' | 'delete'
  setView: (view: 'default' | 'delete') => void
}

export function Actions(props: Props) {
  const { depot, setView } = props
  const linked = useIsDepotLinkedToOrderSteps(depot.id)
  const texts = useTexts()

  const { setEditing } = useEditingStateWithCtx('depot')

  const onDelete = useCallback(() => {
    setView('delete')
  }, [setView])

  const handleStartEditing = useCallback(() => setEditing([depot.id]), [setEditing, depot])

  return (
    <SecondaryColumnHeaderActionsRow centered>
      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={onDelete}
            disabled={linked || preventEditing}
            className="pendo-setup-depot__form-delete"
            testid="setup-depot__delete-button"
          />
        )}
      />
      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.edit}
            icon={<Edit />}
            onClick={handleStartEditing}
            disabled={preventEditing}
            className="pendo-setup-depot__form-edit"
            testid="setup-depot__edit-button"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
