import styled from 'styled-components'
import TimeWindowException from './TimeWindowException'

const VERTICAL_GUTTER = 10

const styledComponent = styled(TimeWindowException)`
  position: relative;
  width: 100%;

  > * + * {
    margin-top: ${VERTICAL_GUTTER}px;
  }
`
export default styledComponent
