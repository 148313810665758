import type { ReactElement } from 'react'
import type { SelectChangeEvent } from '@mui/material'
import type { ValidateOn } from '@/formUi'
import type {
  FormError,
  FormField,
  FormSingleField,
  UseFormFieldOptions,
  NarrowFieldsByValueType,
} from '@workwave-tidal/tidal/form-fairy'

import { useCallback, useMemo } from 'react'

import {
  Select as MuiSelect,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { useValidateOn } from '@/formUi'

type BulkFieldValue<T> =
  | {
      status: 'mixed'
    }
  | {
      status: 'exact'
      value: T
    }

type RequiredFormField = BulkFieldValue<string>

const mixedValue = 'mixed'

export type Option<VALUE extends string> = {
  value: VALUE
  label: string
  disabled?: boolean
} & (
  | { preset?: never }
  | { preset: 'description'; description: string }
  | { preset: 'descriptionAndIcon'; description: string; Icon: ReactElement }
)

type Props<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELD_VALUE extends string,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
> = {
  label: string
  name: FIELD_NAME
  options: Option<FIELD_VALUE>[]
  validateOn?: ValidateOn
  mixedLabel: string
  helperText?: string
  testId?: string
  placeholder?: string
} & UseFormFieldOptions<FIELDS, ERROR>

export function BulkSelect<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELD_VALUE extends string,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
>(props: Props<FIELD_NAME, FIELD_VALUE, FIELDS, ERROR>) {
  const {
    placeholder,
    helperText,
    validateOn,
    label,
    name,
    testId,
    mixedLabel,
    options: optionsWithoutMixedValue,
    ...formFieldOptions
  } = props

  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const {
    field,
    errors,
    fieldApi: { change: apiChange, validate },
    formApi,
  } = useFormField<FIELD_NAME, PartialForm, FormError<FIELD_NAME>>(
    name,
    formFieldOptions as UseFormFieldOptions<PartialForm, FormError<FIELD_NAME>>,
  )

  const { required, status, disabled, visible, value } = field

  const { validateOnBlur, validateOnChange, validateOnFocus } = useValidateOn(validate, validateOn)

  const optionsWithMixedValue = useMemo(() => {
    const mixedOption = {
      value: mixedValue,
      label: mixedLabel,
    }

    return [mixedOption, ...optionsWithoutMixedValue]
  }, [optionsWithoutMixedValue, mixedLabel])

  const onChange = useCallback(
    (event: SelectChangeEvent<FIELD_VALUE>) => {
      const value = event.target.value as FIELD_VALUE
      apiChange({ status: 'exact', value })
      validateOnChange()
    },
    [apiChange, validateOnChange],
  )

  const onBlur = useCallback(() => {
    validateOnBlur()
  }, [validateOnBlur])

  const onFocus = useCallback(() => {
    validateOnFocus()
  }, [validateOnFocus])

  // Resolve the field meta states to improve code readability
  const fieldInvalid = status === 'invalid'
  const fieldIndeterminate = status === 'indeterminate'
  const fieldHasError = fieldInvalid || (fieldIndeterminate && errors.length > 0)

  const errorText = fieldHasError ? errors[0]?.message ?? 'Unknown Error' : undefined

  // Disable the field also while the form is submitting
  const fieldDisabled = disabled || formApi.getMeta().submitting || formApi.getMeta().disabled

  if (!visible) return null

  const options = value.status === 'mixed' ? optionsWithMixedValue : optionsWithoutMixedValue

  return (
    <FormControl error={fieldHasError} size="small" data-testid={testId ?? 'bulk-select_root'}>
      <InputLabel id={name} required={required}>
        {label}
      </InputLabel>
      <MuiSelect
        labelId={name}
        label={label}
        required={required}
        value={value.status === 'mixed' ? mixedValue : value.value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={fieldDisabled}
      >
        {options.map(renderOptions)}
      </MuiSelect>
      <FormHelperText error={fieldHasError}>
        {fieldHasError ? errorText : helperText}
      </FormHelperText>
    </FormControl>
  )
}

function renderOptions<VALUE extends string>(option: Option<VALUE>) {
  switch (option.preset) {
    case 'description':
    case 'descriptionAndIcon':
      return <div>Coming Soon</div>
    default:
      return (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
          {option.label}
        </MenuItem>
      )
  }
}
