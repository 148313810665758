import type { LayerUID } from '../../../typings'

import { useEffect } from 'react'

import { getMap } from '../../../../atoms/map/utils/getMap'
import { findLayer } from '../../../utils/findLayer'

export const useMapLayerOpacity = (uid: LayerUID, opacity: number) => {
  useEffect(() => {
    try {
      findLayer(getMap(), uid).setOpacity(opacity)
    } catch (e) {}
  }, [uid, opacity])
}
