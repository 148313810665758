import type { Props } from './typings'

import { ReactElement } from 'react'

import { Left, Right } from '@/icons'

import { ArrowButtonPosition } from './typings'
import IconContainer from './IconContainer'
import BaseButton from './BaseButton'

const ArrowButton = (props: Props): ReactElement => {
  const { ref, ...buttonProps } = props

  return (
    <BaseButton {...buttonProps}>
      <IconContainer>
        {props.buttonPosition === ArrowButtonPosition.left ? <Left /> : <Right />}
      </IconContainer>
    </BaseButton>
  )
}

export default ArrowButton
