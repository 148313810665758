import OlMap from 'ol/Map'

import { setMapSelectionMode } from '../../../atoms/map/utils/setMapSelectionMode'
import { getMapSelectionMode } from '../../../atoms/map/utils/getMapSelectionMode'

import {
  getInteraction,
  registerInteraction,
  setInteractionMetadata,
} from '../../interactionMetadata'
import { createLassoSelection } from '../../reactions/lasso/createLassoSelection'
import { LassoInteraction } from '../../LassoInteraction'

import { createLassoStyle } from './createLassoStyle'

export const registerLassoInteraction = (map: OlMap) => {
  const uid = 'rm-lasso-interaction'

  if (getInteraction(uid)) return

  // -------------------------------------------
  // Initialize the Interaction

  const lasso = new LassoInteraction({
    freehand: true,
    type: 'Polygon',
    // finishCondition: finishLassoCondition,
    style: createLassoStyle(),
  })

  // by default the Interaction is disabled
  lasso.setActive(false)

  // -------------------------------------------
  // Register Interaction's event listeners

  // lasso.on('drawstart', _event => {})

  lasso.on('drawend', event => {
    event.stopPropagation()

    // access currently active selectionMode
    const mode = getMapSelectionMode()

    // end the lasso selection mode
    setMapSelectionMode('normal')

    // apply lasso selection
    createLassoSelection(map, event.feature, mode)
  })

  lasso.on('drawabort', _event => {
    // end the lasso selection mode
    setMapSelectionMode('normal')
  })

  // -------------------------------------------
  // set Interaction Metadata

  setInteractionMetadata(lasso, 'rmInteraction', true)
  setInteractionMetadata(lasso, 'uid', uid)
  registerInteraction(uid, lasso)

  // -------------------------------------------
  // Add the interaction to the map

  map.addInteraction(lasso)
}
