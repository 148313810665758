import { type ResourceModel } from '@bryntum/schedulerpro'
import { useMemo } from 'react'

import { useIntl } from '@/intl'
import { durationUtils } from '@/server-data'

import { useTexts } from '../useTexts'

export function useAggregateData(record: ResourceModel) {
  const texts = useTexts()
  const { translate } = useIntl()
  const formatDuration = durationUtils.formatSeconds(translate)

  return useMemo(() => {
    const routeDrivingTime = record.getData('routeDrivingTime')
    const routeWorkingTime = record.getData('routeWorkingTime')
    const maxVehicleDrivingTime = record.getData('maxVehicleDrivingTime')
    const maxVehicleWorkingTime = record.getData('maxVehicleWorkingTime')
    const routeDrivingSaturation = record.getData('routeDrivingSaturation')
    const routeWorkingSaturation = record.getData('routeWorkingSaturation')
    const vehicleWorkingTimeWindow = record.getData('vehicleWorkingTimeWindow')

    const workingTimeTotal =
      maxVehicleWorkingTime > 0 ? maxVehicleWorkingTime : vehicleWorkingTimeWindow

    return {
      workingTime: {
        value: formatDuration(routeWorkingTime, 'DURATION_HH_MM'),
        total:
          workingTimeTotal > 0
            ? formatDuration(workingTimeTotal, 'DURATION_HH_MM')
            : texts.unlimited,
        saturation: parseFloat(routeWorkingSaturation.toFixed(2)),
      },
      drivingTime: {
        value: formatDuration(routeDrivingTime, 'DURATION_HH_MM'),
        total:
          maxVehicleDrivingTime > 0
            ? formatDuration(maxVehicleDrivingTime, 'DURATION_HH_MM')
            : texts.unlimited,
        saturation: parseFloat(routeDrivingSaturation.toFixed(2)),
      },
    }
  }, [record, formatDuration, texts])
}
