import { ResourceModel } from '@bryntum/schedulerpro'

export function routeEndSorter(item1: ResourceModel, item2: ResourceModel) {
  const routeEnd1 = item1.getData('routeEnd') as uui.domain.client.rm.SchedulerResource['routeEnd']
  const routeEnd2 = item2.getData('routeEnd') as uui.domain.client.rm.SchedulerResource['routeEnd']

  if (!routeEnd1?.current?.value && !routeEnd2?.current?.value) return 0
  if (!routeEnd1?.current?.value) return 1
  if (!routeEnd2?.current?.value) return -1

  return routeEnd1.current.value > routeEnd2.current.value ? 1 : -1
}
