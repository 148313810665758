import type { FormErrors, FormFields } from '../../../../formFields'
import type { Notifications, NotificationFormType } from '@/notificationForms'

import { useMemo } from 'react'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'

export function useMessageSettings(notification: NotificationFormType) {
  // includeMessageUnsubscriptionInstructions
  const {
    field: { value: includeMessageUnsubscriptionInstructions },
  } = useFormField<'includeMessageUnsubscriptionInstructions', FormFields, FormErrors>(
    'includeMessageUnsubscriptionInstructions',
    {
      fieldsOfInterest: ['value'],
    },
  )

  // includeMessageTrackingLink
  const {
    field: { value: includeMessageTrackingLink },
  } = useFormField<'includeMessageTrackingLink', FormFields, FormErrors>(
    'includeMessageTrackingLink',
    { fieldsOfInterest: ['value'] },
  )

  // templateDefaultLimits
  const {
    field: { value: templateDefaultLimits },
  } = useFormField<'templateDefaultLimits', FormFields, FormErrors>('templateDefaultLimits', {
    fieldsOfInterest: ['value'],
  })

  // templateCustomLimits
  const {
    field: { value: templateCustomLimits },
  } = useFormField<'templateCustomLimits', FormFields, FormErrors>('templateCustomLimits', {
    fieldsOfInterest: ['value'],
  })

  const notificationType = useMemo(() => `${notification}Settings` as Notifications, [notification])
  const notificationLimits = useMemo(() => {
    return {
      ...templateDefaultLimits,
      ...templateCustomLimits,
    } as uui.domain.client.rm.NotificationTemplateLimits
  }, [templateDefaultLimits, templateCustomLimits])

  return {
    notificationType,
    notificationLimits,
    includeMessageTrackingLink,
    includeMessageUnsubscriptionInstructions,
  }
}
