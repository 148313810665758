import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useOnSubmit } from '../../../../hooks/useOnSubmit'
import { useController } from '../../../../hooks/useController'
import { useTexts } from '../../useTexts'

export function ReadyFooter() {
  const {
    status,
    close,
    data: { notification, values },
  } = useController()
  const submit = useOnSubmit(notification, values)
  const texts = useTexts()

  const submitting = status === 'submitting'

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          className={`pendo-${notification}-test-email-modal-send`}
          testid={`${notification}-test-email-modal-send`}
          disabled={submitting}
          loading={submitting}
          variant="contained"
          onClick={submit}
          maxWidth="50%"
        >
          {texts.send}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          className={`pendo-${notification}-test-email-modal-cancel`}
          testid={`${notification}-test-email-modal-cancel`}
          disabled={submitting}
          onClick={close}
        >
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}
