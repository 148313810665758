import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { produce } from 'immer'

import { useAppDispatch } from '@/store'
import { setListOptions } from '@/features/domain/lists/actions'
import { selectUsersListOptions } from '@/features/domain/lists'

export function useToggleSortBy() {
  const dispatch = useAppDispatch()
  const listOptions = useSelector(selectUsersListOptions)

  // Take for granted there is just one sorting option, tha one for the "fullName" field
  const sortBy = listOptions.sort[0].direction ?? 'desc'

  const setSortBy = useCallback(
    async (direction: 'asc' | 'desc') => {
      const nextOptions = produce(listOptions, draft => {
        // Take for granted there is just one sorting option, tha one for the "fullName" field
        draft.sort[0].direction = direction
      })

      // avoid triggering any calculations in case the sort options didn't change
      if (nextOptions === listOptions) return

      const request = await dispatch(setListOptions({ category: 'users', options: nextOptions }))
      return setListOptions.fulfilled.match(request)
    },
    [dispatch, listOptions],
  )

  const toggleSortBy = useCallback(() => {
    setSortBy(sortBy === 'asc' ? 'desc' : 'asc')
  }, [setSortBy, sortBy])

  return [sortBy, toggleSortBy] as const
}
