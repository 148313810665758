import { ResourceModel } from '@bryntum/schedulerpro'
import { schedulerPreferencesAtom } from '../../schedulerPreferences'

export function ordersUndeclaredSorter(item1: ResourceModel, item2: ResourceModel) {
  const ordersExecutedUndeclared1 = item1.getData(
    'ordersExecutedUndeclared',
  ) as uui.domain.client.rm.SchedulerResource['ordersExecutedUndeclared']

  const ordersExecutedUndeclared2 = item2.getData(
    'ordersExecutedUndeclared',
  ) as uui.domain.client.rm.SchedulerResource['ordersExecutedUndeclared']

  if (schedulerPreferencesAtom.preferences.showAbsoluteValues) {
    return ordersExecutedUndeclared1.absolute - ordersExecutedUndeclared2.absolute
  }

  return ordersExecutedUndeclared1.percentage - ordersExecutedUndeclared2.percentage
}
