import { useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

type ModalState = 'ready' | 'pending'

export const modalId = 'logout'

export const useController = () => useModalController<undefined, ModalState>(modalId)
export const useConfigureController = () => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: undefined,
    }),
  }))

  const ctrl = useConfigureModalController<undefined, ModalState>(modalId, options)

  return ctrl
}
