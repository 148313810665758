import { Typography } from '@mui/material'
import { useTexts } from './hooks/useTexts'
import { MaskLayout } from './components/MaskLayout'
import { useMainWindowVisible } from './hooks/useMainWindowVisible'

/**
 * Show an overlay over the map when the main window is visible.
 * see: https://github.com/openlayers/openlayers/issues/13567
 */
export function UnusableMapMask() {
  const mainWindowVisible = useMainWindowVisible()
  const texts = useTexts()

  return (
    <MaskLayout hide={mainWindowVisible}>
      <>
        <Typography variant="h3" align="center">
          {texts.warningCardTitle}
        </Typography>
        <Typography variant="h5" align="center">
          {texts.warningCardDescription}
        </Typography>
      </>
    </MaskLayout>
  )
}
