import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (count: number) =>
      translate({
        id: 'gps.eventslist.view.bulk.title',
        values: { count },
      }),
    description: translate({ id: 'gps.eventslist.view.bulk.description' }),
    findOnMap: translate({ id: 'gps.eventslist.view.actions.findOnMap' }),

    noLocationButtonTitle: translate({ id: 'gps.eventslist.view.noLocationButtonTitle' }),
  }))

  return api
}
