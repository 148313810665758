import type { CrudSelection } from '../typings'

import { produce } from 'immer'

import { crudAtom } from '../crud'
import { setAllCrudSelection } from './setAllCrudSelection'

type Params =
  | { mode: 'replace'; publicData: uui.domain.PublicData }
  | {
      mode: 'update'
      idsChangelog: uui.domain.api.IdsChangelog
      listChangelog: uui.domain.ui.list.FilterChangelog
    }

/**
 * Generates a new selection array filtering out stale items
 */
function replaceSelection(items: Record<string, unknown>, selection: string[]): string[] {
  // short-circuit long loops
  if (selection.length > 1000) return []

  return selection.filter(id => !!items[id])
}

/**
 * Replace all selections with an updated version (w/out stale items)
 */
function updateAndReplaceAllSelections(publicData: uui.domain.PublicData) {
  let isSelectionChanged = false

  setAllCrudSelection(prevCrudSelection => {
    // Collect all the selection sizes before the update
    const sizes = {
      depots: prevCrudSelection.depots.length,
      places: prevCrudSelection.places.length,
      drivers: prevCrudSelection.drivers.length,
      regions: prevCrudSelection.regions.length,
      trafficProfile: prevCrudSelection.trafficProfile.length,
      alerts: prevCrudSelection.alerts.length,
      driverAssignments: prevCrudSelection.driverAssignments.length,
      unifiedVehicles: prevCrudSelection.unifiedVehicles.length,
      simulations: prevCrudSelection.simulations.length,
      geofences: prevCrudSelection.geofences.length,
      routingSegments: prevCrudSelection.routingSegments.length,
    }

    const newCrudSelection = produce(prevCrudSelection, draft => {
      // TODO: reports

      draft.users = replaceSelection(publicData.domain.rm.users, draft.users)
      draft.depots = replaceSelection(publicData.domain.rm.depots, draft.depots)
      draft.places = replaceSelection(publicData.domain.gps.wwgps.places, draft.places)
      draft.drivers = replaceSelection(publicData.domain.rm.drivers, draft.drivers)
      draft.regions = replaceSelection(publicData.domain.rm.regions, draft.regions)
      draft.trafficProfile = replaceSelection(publicData.domain.rm.traffic, draft.trafficProfile)
      draft.trafficArea = []
      draft.alerts = replaceSelection(publicData.domain.gps.wwgps.eventAlerts, draft.alerts)

      draft.driverAssignments = replaceSelection(
        publicData.domain.rm.driverAssignments,
        draft.driverAssignments,
      )
      draft.unifiedVehicles = replaceSelection(publicData.domain.vehicles, draft.unifiedVehicles)
      draft.simulations = replaceSelection(publicData.domain.rm.simulations, draft.simulations)
      draft.geofences = replaceSelection(publicData.domain.gps.wwgps.geofences, draft.geofences)
      draft.routingSegments = replaceSelection(publicData.domain.rm.routing, draft.routingSegments)
    })

    // If at least on size is changed means that the selection is changed
    for (const key in sizes) {
      if (newCrudSelection[key].length !== sizes[key]) {
        isSelectionChanged = true

        break
      }
    }

    return newCrudSelection as CrudSelection
  })

  return isSelectionChanged ? 'removed' : 'unchanged'
}

/**
 * Update the selection for the items whose id is changed.
 */
function updateStaleItems(idsChangelog: uui.domain.api.IdsChangelog) {
  let selectionUpdated = false

  for (const [category, idsToUpdate] of Object.entries(idsChangelog.update)) {
    // short-circuit the categories that don't belong to the selection
    if (!crudAtom.selection[category] || !idsToUpdate) continue

    for (const { prev, next } of idsToUpdate) {
      crudAtom.selection[category] = crudAtom.selection[category].map(id =>
        id === prev ? next : id,
      )

      selectionUpdated = true
    }
  }

  return selectionUpdated
}

/**
 * Generates a new selection array filtering out stale items
 */
function removeStaleItems(
  idsChangelog: uui.domain.api.IdsChangelog,
  listChangelog: uui.domain.ui.list.FilterChangelog,
) {
  let isSelectionChanged = false
  for (const [category, idsToRemove] of Object.entries(idsChangelog.remove)) {
    // short-circuit the categories that don't belong to the selection
    if (!crudAtom.selection[category] || !idsToRemove) continue

    // Use a for-loop instead of a .filter() to preserve array reference
    for (let index = crudAtom.selection[category].length - 1; index >= 0; index--) {
      if (idsToRemove.includes(crudAtom.selection[category][index])) {
        crudAtom.selection[category].splice(index, 1)

        isSelectionChanged = true
      }
    }
  }

  for (const [category, idsToRemove] of Object.entries(listChangelog.remove)) {
    // short-circuit the categories that don't belong to the selection
    if (!crudAtom.selection[category]) continue

    // Use a for-loop instead of a .filter() to preserve array reference
    for (let index = crudAtom.selection[category].length - 1; index >= 0; index--) {
      if (idsToRemove.has(crudAtom.selection[category][index])) {
        crudAtom.selection[category].splice(index, 1)

        isSelectionChanged = true
      }
    }
  }

  return isSelectionChanged
}

/**
 * Remove and update the stale selections and returns a value that indicates what happened to selection items
 * The return value can be:
 * - unchanged (no changes)
 * - removed (some items have been removed)
 * - updated (some items have been updated)
 * - removedAndUpdated (some items have been removed and some updated)
 */
export function cleanUpCrudSelection(params: Params) {
  switch (params.mode) {
    case 'replace':
      return updateAndReplaceAllSelections(params.publicData)

    case 'update':
      const itemsRemoved = removeStaleItems(params.idsChangelog, params.listChangelog)
      const itemsUpdated = updateStaleItems(params.idsChangelog)

      if (itemsRemoved && itemsUpdated) return 'removedAndUpdated'
      if (itemsRemoved) return 'removed'
      if (itemsUpdated) return 'updated'

      return 'unchanged'
  }
}
