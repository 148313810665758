import type {
  FormField,
  FormError,
  FormValues as FormValuesType,
} from '@workwave-tidal/tidal/form-fairy'
import type { BulkFieldValue, BulkLoads, BulkTags } from './types'
import type { BulkLoadsError } from './components/primitives/BulkLoads/types'
import type { BulkTimeWindowsError } from './components/primitives/BulkTimeWindows/types'

const eligibilityField: FormField<BulkFieldValue<uui.domain.client.rm.Eligibility>> = {
  value: { status: 'exact', value: { type: 'any' } },
  required: false,
}

export type Priority = '-20' | '-10' | '0' | '10' | '20'
const priorityField: FormField<BulkFieldValue<Priority>> = {
  value: { status: 'exact', value: '0' },
  required: false,
}

const serviceTimeSecField: FormField<BulkFieldValue<string>> = {
  value: { status: 'exact', value: '' },
  required: false,
}

const timeWindowsField: FormField<
  BulkFieldValue<[uui.domain.rm.TimeWindow?, uui.domain.rm.TimeWindow?]>
> = {
  value: { status: 'exact', value: [] },
  required: false,
}

const locationField: FormField<BulkFieldValue<uui.domain.client.Location>> = {
  value: { status: 'mixed' },
  required: false,
}

const typeField: FormField<BulkFieldValue<'pickup' | 'dropoff' | 'service'>> = {
  value: { status: 'mixed' },
  required: false,
}

const tagsField: FormField<BulkTags> = {
  value: { tags: {}, remaining: 0, affectedOrdersCount: 0 },
  required: false,
}

const loadsField: FormField<BulkLoads> = {
  value: { loads: {}, affectedOrdersCount: 0, loadsDistribution: {} },
  required: false,
}

const forceVehicleIdField: FormField<BulkFieldValue<string>> = {
  value: {
    status: 'exact',
    value: '',
  },
}

const emailField: FormField<BulkFieldValue<string>> = {
  value: {
    status: 'exact',
    value: '',
  },
}

const companyIdField: FormField<BulkFieldValue<string>> = {
  value: {
    status: 'exact',
    value: '',
  },
}

const phoneField: FormField<BulkFieldValue<string>> = {
  value: {
    status: 'exact',
    value: '',
  },
}

const notesField: FormField<BulkFieldValue<string>> = {
  value: {
    status: 'exact',
    value: '',
  },
}

export const formFields = {
  eligibility: eligibilityField,
  companyId: companyIdField,
  forceVehicleId: forceVehicleIdField,
  priority: priorityField,
  serviceTimeSec: serviceTimeSecField,
  timeWindows: timeWindowsField,
  email: emailField,
  phone: phoneField,
  notes: notesField,
  location: locationField,
  type: typeField,
  tagsIn: tagsField,
  tagsOut: tagsField,
  loads: loadsField,
}

export type FormFields = typeof formFields
export type FormValues = FormValuesType<FormFields>
export type FormErrors = FormError<keyof FormFields> | BulkLoadsError | BulkTimeWindowsError
