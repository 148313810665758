import { Skeleton, Stack } from '@mui/material'
import { ViewContainer } from '@/formUi'

export function Loading() {
  return (
    <ViewContainer width="100%" testid="integrations-view">
      <Stack direction="row" width="100%" justifyContent="center" paddingX={4}>
        <Stack spacing={5} width="100%" maxWidth={1016}>
          <Stack spacing={5}>
            <Stack spacing={2}>
              <Skeleton variant="text" width={120} />
              <Skeleton variant="text" width={161} />
            </Stack>

            <Stack direction="row" spacing={2}>
              <Stack spacing={2} width="33%">
                <Skeleton variant="rounded" width={328} height={200} />
                <Skeleton variant="text" width={104} />
                <Skeleton variant="text" width={288} />
              </Stack>
              <Stack spacing={2} width="33%">
                <Skeleton variant="rounded" width={328} height={200} />
                <Skeleton variant="text" width={104} />
                <Skeleton variant="text" width={288} />
              </Stack>
              <Stack spacing={2} width="33%">
                <Skeleton variant="rounded" width={328} height={200} />
                <Skeleton variant="text" width={104} />
                <Skeleton variant="text" width={288} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ViewContainer>
  )
}
