import styled from 'styled-components'

import { TextInput as TextInputBase } from '@/forms-legacy'

export const TextInput = styled(TextInputBase)`
  border-width: 0;
  background-color: transparent;

  padding-left: 0;

  .o-input-field-text__extra-icon {
    flex: 0 0 auto;
  }
`

TextInput.displayName = 'TextInput'
