import { ordersNotCompletedSorter } from '../sorters/ordersNotCompletedSorter'
import { createDynamicLoadSorter } from '../sorters/dynamicLoadSorter'
import { approvedDistanceSorter } from '../sorters/approvedDistanceSorter'
import { ordersUndeclaredSorter } from '../sorters/ordersUndeclaredSorter'
import { ordersExecutedSorter } from '../sorters/ordersExecutedSorter'
import { approvedTimeSorter } from '../sorters/approvedTimeSorter'
import { ordersDoneSorter } from '../sorters/ordersDoneSorter'
import { routeStartSorter } from '../sorters/routeStartSorter'
import { ordersToDoSorter } from '../sorters/ordersToDoSorter'
import { totalTimeSorter } from '../sorters/totalTimeSorter'
import { routeEndSorter } from '../sorters/routeEndSorter'
import { vehicleSorter } from '../sorters/vehicleSorter'
import { driverSorter } from '../sorters/driverSorter'
import { ordersSorter } from '../sorters/ordersSorter'
import { stopsSorter } from '../sorters/stopsSorter'
import { costSorter } from '../sorters/costSorter'

export function getSorterByField(field: string) {
  switch (field) {
    case 'ordersExecutedReschedule':
      return ordersNotCompletedSorter

    case 'ordersExecutedUndeclared':
      return ordersUndeclaredSorter

    case 'ordersExecutedDone':
      return ordersDoneSorter

    case 'ordersExecuted':
      return ordersExecutedSorter

    case 'approvedMileage':
      return approvedDistanceSorter

    case 'approvedTime':
      return approvedTimeSorter

    case 'vehicleName':
      return vehicleSorter

    case 'driverName':
      return driverSorter

    case 'ordersToDo':
      return ordersToDoSorter

    case 'stopsCount':
      return stopsSorter

    case 'orders':
      return ordersSorter

    case 'routeCost':
      return costSorter

    case 'routeStart':
      return routeStartSorter

    case 'routeEnd':
      return routeEndSorter

    case 'totalTime':
      return totalTimeSorter

    default:
      if (field.startsWith('loads.')) {
        return createDynamicLoadSorter(field.replace('loads.', ''))
      }

      console.warn('No sorter found for field: ', field)
  }
}
