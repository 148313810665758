import { Sections } from '@/sections'
import { usePendoTrackOffline, ExternalConfigLoader } from '@/atoms'
import { useExposeRouterNavigator } from '@/routing'

import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { LoadingState } from '@/components/LoadingState'

import { AppContent, AppBody, AppFooter, AppContainer, AppHeader } from '@/components/layout'

import { ResidentModals } from '@/components/modals/ResidentModals'

import { PopupRoot } from '../atoms/popup/context/PopupRoot'
import { Bootstrap } from './components/Bootstrap'
import { ToastArea } from './components/ToastArea'
import { LanguageLoading } from './components/LanguageLoading'
import { TestActions } from './components/TestActions/TestActions'
import { GlobalKeybindings } from './components/GlobalKeybindings'
import { DomainSubscriptions } from './components/DomainSubscriptions'

import { useOfflineMonitor } from './hooks/useOfflineMonitor'
import { useInstanceLicenses } from './hooks/useInstanceLicenses'
import { useUpdateLangAttributeOnHtml } from './hooks/useUpdateLangAttributeOnHtml'
import { PopupFallback } from './components/PopupFallback'
import { useSetupBryntumSchedulerPresets } from './hooks/useSetupBryntumSchedulerPresets'

export function App() {
  // instances Material UI X license
  useInstanceLicenses()

  // Setup Bryntum Scheduler presets
  useSetupBryntumSchedulerPresets()

  // communicates to the worker when window has gone offline
  useOfflineMonitor()

  // track online/offline event to pendo with offline duration
  usePendoTrackOffline()

  // Expose a static pointer to react-router `navigate()`
  useExposeRouterNavigator()

  // update lang attribute on html
  useUpdateLangAttributeOnHtml()

  return (
    <>
      <GlobalKeybindings />
      <DomainSubscriptions />
      <TestActions />
      <ExternalConfigLoader />

      <Bootstrap>
        <AppContainer>
          <AppContent>
            <PopupRoot>
              <LanguageLoading>
                <ResidentModals>
                  <PopupFallback />
                  <AppHeader>
                    {/* Application Header, it lives outside the URL router */}
                    <Header />
                  </AppHeader>

                  {/* Application content, it's driven by the URL router */}
                  <AppBody>
                    <Sections />
                  </AppBody>

                  {/* Application Footer, it lives outside the URL router */}
                  <AppFooter>
                    <Footer />
                  </AppFooter>
                </ResidentModals>
              </LanguageLoading>
            </PopupRoot>
          </AppContent>
        </AppContainer>
      </Bootstrap>

      <LoadingState />
      <ToastArea />
    </>
  )
}
