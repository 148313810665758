import type { RecurrencesMap } from '../../typings'
import type { AnnualCalendarPublicProps } from '@/components/Calendar'

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { parse, format, isSameDay, lastDayOfYear, startOfYear } from 'date-fns/esm'

import { selectUserConfiguration } from '@/features/domain/user'

const now = new Date()

export function useAnnualCalendarProps(
  recurrenceMap: RecurrencesMap,
  onChangeCallback: (date: Date) => void,
  date: Date | null,
): AnnualCalendarPublicProps {
  const { today } = useSelector(selectUserConfiguration)
  const todayDate = parse(today, 'yyyyMMdd', now)

  const selection = useMemo<Date>(() => date || todayDate, [date, todayDate])

  const onChange = useCallback(
    (newSelection: Date, currentSelection?: Date) => {
      if (currentSelection && isSameDay(currentSelection, newSelection)) {
        return
      }
      onChangeCallback(newSelection)
    },
    [onChangeCallback],
  )

  return useMemo(() => {
    const minDate = format(startOfYear(todayDate), 'yyyyMMdd')
    const maxDate = format(lastDayOfYear(todayDate), 'yyyyMMdd')

    const daysWithRecurrences =
      !!recurrenceMap && recurrenceMap['ANNUAL']
        ? recurrenceMap['ANNUAL'].reduce((acc, curr) => {
            if (curr.value) {
              const key = format(curr.value, 'yyyyMMdd')
              acc[key] = 1
            }
            return acc
          }, {})
        : undefined

    return {
      today,
      layout: 'annual',
      selection,
      onChange,
      highlightedDays: daysWithRecurrences,
      minDate,
      maxDate,
    }
  }, [onChange, recurrenceMap, today, selection, todayDate])
}
