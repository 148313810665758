import styled from 'styled-components'

import { WithTheme, getColorWithAlpha, normalizeCssValue } from '../../../theme'

import { CosmeticProps as Props } from '../typings'

type ThemedProps = WithTheme<Props>

const getColor = ({ color: color = '$nightRider', theme: { colors } }: ThemedProps) => colors[color]

const getBackground =
  () =>
  ({ backgroundAlpha = 0.2, backgroundTint = '$darkOrange' }: ThemedProps) =>
    getColorWithAlpha(backgroundTint, backgroundAlpha)

const getWidth = ({ w: width = 'auto' }: Props) => normalizeCssValue(width)

const getPadding = ({ horizontalPad = 38, verticalPad = 18 }: Props) =>
  `${normalizeCssValue(verticalPad)} ${normalizeCssValue(horizontalPad)}`

const div = styled.div<Props>``
export const ConfirmPanelContainer = styled(div).attrs<Props>(() => ({
  className: 'o-molecules-confirm-panel-container',
}))`
  position: relative;
  width: ${getWidth};
  color: ${getColor};
  background: ${getBackground};
  padding: ${getPadding};
`

ConfirmPanelContainer.displayName = 'ConfirmPanelContainer'
