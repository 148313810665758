import { useMemo, useState } from 'react'

import { getParserByType } from '../utils/getParserByType'
import { parseArguments } from '../utils/parseArguments'

export function useQueryStateParams<T>(
  typeOrDefaultValueOrOptions?: any,
  defaultValueOrOptions?: any,
  opts?: any,
) {
  const { initialValue, parse, isValid, serialize, clearOnUnmount } = opts ?? {}

  const { type, defaultValue, options } = useMemo(() => {
    const parsedOption =
      parse !== undefined ||
      isValid !== undefined ||
      serialize !== undefined ||
      initialValue !== undefined ||
      clearOnUnmount !== undefined
        ? {
            parse,
            isValid,
            serialize,
            initialValue,
            clearOnUnmount,
          }
        : undefined

    return parseArguments<T>(typeOrDefaultValueOrOptions, defaultValueOrOptions, parsedOption)
  }, [
    typeOrDefaultValueOrOptions,
    defaultValueOrOptions,
    parse,
    isValid,
    serialize,
    initialValue,
    clearOnUnmount,
  ])

  const [value, setValue] = useState<T | undefined>(defaultValue)

  return {
    value,
    setValue,

    info: {
      value,
      options,
      defaultValue,
      transformer: type === 'custom' ? getParserByType<T>(type, options) : getParserByType<T>(type),
    },
  }
}
