import { Component } from 'react'

import { Equalizer, LeftArrow, Search } from '@/icons'
import { getColorValue } from '@/styles'

import TextButton from '../TextButton'

import ColumnHeader from './elements/ColumnHeader'
import ColumnHeaderRow from './elements/ColumnHeaderRow'
import ColumnHeaderInput from './elements/ColumnHeaderInput'

import HeaderText from './elements/HeaderText'
import { Props } from './typings'

interface State {
  text: string
}

const setText = (text: string) => () => ({ text })

const noop = () => undefined

export default class FormColumnHeader extends Component<Props, State> {
  private filterTimer: ReturnType<typeof setTimeout> | null = null

  constructor(props: Props) {
    super(props)

    const {
      filter: { initialText = '' },
    } = props

    this.state = {
      text: initialText,
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      filter: { initialText: prevText },
    } = prevProps
    const {
      filter: { initialText = '' },
    } = this.props
    const { text } = this.state

    if (prevText !== initialText && text !== initialText) {
      this.setState(setText(initialText))
    }
  }

  componentWillUnmount() {
    this.resetTimer()
  }

  private resetTimer() {
    if (this.filterTimer) clearTimeout(this.filterTimer)
  }

  private handleOnResetChange = (value: string) => {
    const {
      filter: { onChange = noop },
    } = this.props

    this.setState(setText(value))
    this.resetTimer()
    onChange(value)
  }

  private handleOnFilterChange = (value: string) => {
    const {
      filter: { onChange = noop, throttle = 0 },
    } = this.props

    this.setState(setText(value))

    this.resetTimer()
    this.filterTimer = setTimeout(() => {
      onChange(value)
    }, throttle)
  }

  render() {
    const {
      filter: {
        // text: filterText,
        placeholder: filterPlaceholder = '',
        iconHidden: filterIconHidden,
        Icon: FilterIcon,
        disabled: filterDisabled = false,
      },
      primaryAction: {
        text: primaryText = 'Back',
        iconHidden: primaryIconHidden = false,
        Icon: PrimaryIcon,
        hidden: primaryHidden = false,
        disabled: primaryDisabled = false,
        onClick: primaryOnClick = noop,
        className: primaryClassName = '',
        disabledInteraction: primaryActionDisabledInteraction = false,
      },
      secondaryAction,
      secondaryAction: {
        text: secondaryText = 'Back',
        iconHidden: secondaryIconHidden = true,
        Icon: SecondaryIcon,
        hidden: secondaryHidden = false,
        disabled: secondaryDisabled = false,
        onClick: secondaryOnClick = noop,
        className: secondaryClassName = '',
        disabledInteraction: secondaryActionDisabledInteraction = false,
      } = {},
      options: {
        Icon: OptionsIcon,
        hidden: optionsHidden = true, // default value until we'll implement the UX for this "options" tool
        disabled: optionsDisabled = false,
        onClick: optionsOnClick = noop,
        iconColor = '9b9b9b',
        iconClassName = '',
        title,
        extra: optionsExtra,
      },
      style,
      extra,
    } = this.props

    const { text: filterText } = this.state

    const primaryButton = !!primaryActionDisabledInteraction ? (
      <HeaderText>{primaryText}</HeaderText>
    ) : (
      <TextButton onClick={primaryOnClick} disabled={primaryDisabled} className={primaryClassName}>
        {!primaryIconHidden && (
          <div style={{ width: 8, display: 'inline-block' }}>
            {!!PrimaryIcon ? PrimaryIcon : <LeftArrow size={8} />}
          </div>
        )}
        {primaryIconHidden ? '' : ' '}
        {primaryText}
      </TextButton>
    )

    const secondaryButton = !!secondaryActionDisabledInteraction ? (
      <HeaderText>{primaryText}</HeaderText>
    ) : (
      <TextButton
        onClick={secondaryOnClick}
        disabled={secondaryDisabled}
        className={secondaryClassName}
      >
        {!secondaryIconHidden && SecondaryIcon && (
          <div style={{ width: 8, display: 'inline-block' }}>
            {SecondaryIcon}
            {/* <Icon size={8} preset={secondaryIcon} /> */}
          </div>
        )}

        {secondaryIconHidden || !SecondaryIcon ? '' : ' '}
        {secondaryText}
      </TextButton>
    )

    return (
      <ColumnHeader style={style}>
        <ColumnHeaderRow>
          {!primaryHidden && primaryButton}
          {secondaryAction && !secondaryHidden && secondaryButton}
        </ColumnHeaderRow>

        {extra && <ColumnHeaderRow>{extra}</ColumnHeaderRow>}

        <ColumnHeaderRow data-testid="form-header-filter">
          <ColumnHeaderInput
            name="o-form-header__input-filter"
            value={filterText}
            placeholder={filterPlaceholder}
            onChange={this.handleOnFilterChange}
            onReset={this.handleOnResetChange}
            extraIcon={filterIconHidden ? undefined : FilterIcon ? FilterIcon : <Search size={8} />}
            type="text"
            showResetIcon={filterText.length > 0}
            useBigField={false}
            readonly={filterDisabled}
          />
          {!optionsHidden && (
            <>
              <TextButton
                title={title}
                style={{
                  width: 18,
                  color: getColorValue(iconColor),
                  marginLeft: 20,
                  flexShrink: 0,
                }}
                onClick={optionsOnClick}
                disabled={optionsDisabled}
                className={iconClassName}
              >
                {!!OptionsIcon ? OptionsIcon : <Equalizer size={18} />}
              </TextButton>
              {optionsExtra?.()}
            </>
          )}
        </ColumnHeaderRow>
      </ColumnHeader>
    )
  }
}
