import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    multipleRegions: translate({ id: 'setup.regions.multipleRegionsSelected' }),
    duplicate: translate({ id: 'global.duplicateAll' }),
    delete: translate({ id: 'global.deleteAll' }),
    edit: translate({ id: 'global.editAll' }),
    exportKml: translate({ id: 'global.exportKML' }),
    regions: (count: number) => `${count} ${translate({ id: 'global.regions' })}`,
    confirmPanelTitle: (count: number) =>
      translate({ id: 'setup.regions.delete.title', values: { count } }),
    confirmPanelSubtitle: translate({ id: 'setup.regions.delete.subtitle' }),
  }))

  return api
}
