import { FlexBox, WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useTexts } from './useTexts'

export function UnsubscribeDataFailed() {
  const texts = useTexts()

  return (
    <FlexBox column vAlignContent="top" padding="8px 32px" style={{ flexShrink: 0 }}>
      <WarningCard
        preset="alert"
        title={texts.unsubscribeDataFailureTitle}
        Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
        description={texts.unsubscribeDataFailureDescription}
      />
    </FlexBox>
  )
}
