import { useMemo } from 'react'

import { useCreateNotificationEditorRenderText } from './useCreateNotificationEditorRenderText'

export function useWrappedInputProps(defaultEmailAddress?: string) {
  const notificationEditorRenderText = useCreateNotificationEditorRenderText()

  return useMemo(() => {
    return {
      defaultEmailAddress,
      renderText: notificationEditorRenderText,
    }
  }, [notificationEditorRenderText, defaultEmailAddress])
}
