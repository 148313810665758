import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

export const timeShift = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  void,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/demoTools/timeShift', async (_params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/demoTools/timeShift', {
      category: 'rpc',
      type: 'rpc/demoTools/timeShift',
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Time shift failed',
      error,
    })
  }
})
