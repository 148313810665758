import type { FormErrors, FormFields } from '../../formFields'
import { useCallback } from 'react'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'
import { useIsUnmounted } from '@/hooks'

import { useUpdateVoiceConfig } from '../useUpdateVoiceConfig'
import { getFormValues } from './getFormValues'

export function useOnSubmit(clearEditingState: () => void) {
  const isUnmounted = useIsUnmounted()
  const [onSubmit] = useUpdateVoiceConfig()
  const formApi = useFormApi<FormFields, FormErrors>()

  return useCallback(async () => {
    try {
      // Set FormState into submitting mode
      formApi.setSubmitting(true)

      // verify if the form is already valid
      const formIsValidBeforeSubmitting = formApi.getMeta().status === 'valid'

      // Validate the form if necessary, and wait for the validation result
      const formIsValid = formIsValidBeforeSubmitting || (await formApi.validate())

      // if the component has been unmounted during the async operation stop here
      if (isUnmounted()) return

      if (formIsValid) {
        await onSubmit(getFormValues(formApi))
        // Clear FormState submitting mode
        formApi.setSubmitting(false)
        // if the component has been unmounted during the async operation stop here
        if (isUnmounted()) return

        clearEditingState()
      }

      // Clear FormState submitting mode
      formApi.setSubmitting(false)
    } catch (error) {
      formApi.setSubmitting(false)
    }
  }, [clearEditingState, formApi, isUnmounted, onSubmit])
}
