import { produce } from 'immer'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectTrackingListOptions } from '@/features/domain/lists'
import { setListOptions } from '@/features/domain/lists/actions'
import { useAppDispatch } from '@/store'

import { type Tag, useController } from './useController'

export function useControllerActions() {
  const dispatch = useAppDispatch()
  const listOptions = useSelector(selectTrackingListOptions)

  const { close, updateData, data } = useController()

  const onChangeFilter = useCallback(
    (badges: Tag[]) => {
      updateData({
        badgeFilter: badges,
        filterPristine: badges.length === 0,
        allBadgesChecked: badges.length === data.badges.length,
      })
    },
    [updateData, data],
  )

  const onToggleAll = useCallback(() => {
    const badgeFilter = data.allBadgesChecked ? [] : data.badges
    updateData({
      badgeFilter,
      allBadgesChecked: !data.allBadgesChecked,
      filterPristine: badgeFilter.length === 0,
    })
  }, [updateData, data])

  const onChangeIncludeDevicesWithNoBadges = useCallback(
    (_, includeDevicesWithNoBadges: boolean) => updateData({ includeDevicesWithNoBadges }),
    [updateData],
  )

  const onChangeFilterOperator = useCallback(
    (_, checked: boolean) => updateData({ filterOperator: checked ? 'and' : 'or' }),
    [updateData],
  )

  const onApplyFilters = useCallback(async () => {
    const nextOptions = produce(listOptions, draft => {
      draft.filter = [
        {
          field: 'filter.badges',
          values: data.badgeFilter.map(b => b.id),
          operator: data.filterOperator,
        },
        {
          field: 'filter.includeDevicesWithNoBadges',
          operator: 'or',
          values: [data.includeDevicesWithNoBadges],
        },
      ]
    })

    const request = await dispatch(setListOptions({ category: 'tracking', options: nextOptions }))

    close?.()
    return setListOptions.fulfilled.match(request)
  }, [dispatch, listOptions, data, close])

  const onClearFilters = useCallback(async () => {
    const nextOptions = produce(listOptions, draft => {
      draft.filter = [
        {
          field: 'filter.badges',
          operator: 'or',
          values: [],
        },
        {
          field: 'filter.includeDevicesWithNoBadges',
          operator: 'or',
          values: [],
        },
      ]
    })

    // avoid triggering any calculations in case the group didn't change
    if (nextOptions === listOptions) return

    const request = await dispatch(setListOptions({ category: 'tracking', options: nextOptions }))

    close?.()
    return setListOptions.fulfilled.match(request)
  }, [dispatch, listOptions, close])

  return {
    onToggleAll,
    onApplyFilters,
    onChangeFilter,
    onClearFilters,
    onChangeFilterOperator,
    onChangeIncludeDevicesWithNoBadges,
  }
}
