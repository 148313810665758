import { FormColumnHeader } from '@/forms-legacy'
import { Search, Left } from '@/icons'

import { useTexts } from './useTexts'

interface Props {
  recurrenceText: string
  filter: string
  setFilter: (filter: string) => void
  switchToDefaultView: () => void
}

export function ExceptionsListHeader(props: Props) {
  const { filter, setFilter, switchToDefaultView, recurrenceText } = props
  const texts = useTexts()

  return (
    <FormColumnHeader
      filter={{
        initialText: filter,
        placeholder: texts.placeholder,
        iconHidden: false,
        Icon: <Search />,
        onChange: setFilter,
        throttle: 350,
      }}
      primaryAction={{
        text: recurrenceText,
        Icon: <Left />,
        onClick: switchToDefaultView,
      }}
      options={{
        disabled: true,
        hidden: true,
      }}
    />
  )
}
