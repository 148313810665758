import type { PartialStoreState } from '../typings'

import { createSelector } from '@reduxjs/toolkit'

export const selectSimulations = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.simulations

export const selectSimulationsList = createSelector(selectSimulations, simulations => {
  return Object.values(simulations)
})
