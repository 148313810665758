import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Acceleration(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 58 35">
        <path d="M14.318.74c-.403.23-.7.632-.824 1.196-.05.213-.005.414.034.81.238 2.335.22 5.61-2.603 7.24-1.847 1.067-4.21 1.312-6.292 1.54-1.66.182-2.97.343-3.87.863-.32.184-.508.523-.49.896.1 2.387 1.476 4.917 2.818 7.07 2.047 3.28 3.864 4.76 3.96 4.858.32.32.822.392 1.214.165L15 21.42l1.232-.677c3.516-1.958 5.496-1.896 6.81-2.02.74-.07 1.388-.11 2.007-.148 3.368-.203 5.596-.344 12.56-4.366 11.926-6.885 12.603-8.174 11.492-10.1C45.648-1.87 39.257.02 34.59 1.413c-1.65.493-3.233.964-4.42 1.035-4.336.262-13.237-1.5-14.47-1.75-.377-.178-.895-.24-1.382.042zM8.305 27.45c.33-.06.658-.165.96-.34l6.75-3.932 1.19-.688c1-.558 1.855-.912 2.577-1.162l.875 1.515c.277.48.11 1.09-.366 1.366l-9.526 5.5c-.477.275-1.088.114-1.366-.367L8.305 27.45zm45.217-16.773c.78.11 1.006 1.092 1.703 2.26 1.075 1.802 2.443 4.166 2.274 5.368-.134.95-3.29 2.407-6.638 4.116-3.627 1.852-7.543 3.95-9.747 4.69-4.24 1.424-9.052-.262-8.774-2.242.095-.666 8.34-6.354 11.296-8.51 5.836-4.26 8.572-5.865 9.886-5.68zM44.075 28.18l5.37-2.275c-.28 1.98 1.562 3.25 5.523 3.806l-.696 4.952c-2.712-.533-4.646-1.14-5.802-1.825-1.156-.684-2.62-2.237-4.395-4.657z" />
      </svg>
    </IconContainer>
  )
}
