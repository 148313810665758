import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  actionsWrapper: {
    '& > * + *': {
      marginLeft: 8,
    },
  },
  separator: {
    marginLeft: 14,
  },
})
