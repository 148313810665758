import type OlMap from 'ol/Map'
import type { PopupPlugin } from '../types'

import { journal } from '@/server-data'

type Params = {
  map: OlMap

  /**
   * The container that must always be available in order to allow the map rendered in the popup to
   * immediately switch back to the main window when the popup close. Theoretically, this is not
   * necessary since OL already answered to this issue https://github.com/openlayers/openlayers/issues/13525
   * but the mentioned solution sometimes does not work for the markers (but always works for the
   * other layers). The bug happens in our own code since in this CodeSandbox
   * https://codesandbox.io/s/external-map-bug-markers-jew6cd?file=/main.js:2021-2043
   * you can see that both the standard layers and the marker layers always work.
   */
  mainWindowTemporaryContainerId: string
}

/**
 * Create a new popup ready to host a Map.
 */
export function createMapPopupPlugin(params: Params) {
  const { map, mainWindowTemporaryContainerId } = params

  const plugin: PopupPlugin = {
    // --------------------------------------------------
    // POPUP CLOSE
    // --------------------------------------------------
    onClose: () => {
      const currentTarget = map.getTarget()

      // The map is not mounted yet
      if (!currentTarget) return

      // After the introduction of the Extracted Map, the map's target is not a string anymore.
      if (typeof currentTarget === 'string') return

      const mainWindowTemporaryContainer = getMainWindowTemporaryContainer(
        mainWindowTemporaryContainerId,
      )

      const mapWasInPopup = currentTarget.ownerDocument.defaultView !== window

      // Immediately set the map target to the main window temporary container.
      if (mapWasInPopup) {
        map.setTarget(mainWindowTemporaryContainer)
      }
    },
  }

  return plugin
}

function getMainWindowTemporaryContainer(id: string) {
  const mainWindowTemporaryContainer = document.getElementById(id)

  // Prompt the developer in case of missing temporary container
  if (mainWindowTemporaryContainer === null) {
    journal.main('No temporary map container available', { info: { id } }, 'error')

    throw new Error('No temporary map container available')
  }

  return mainWindowTemporaryContainer
}
