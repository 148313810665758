import { DurationStepField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

const wrappedInputProps = {
  step: 10,
  min: 0,
}

function secToMin(seconds: number) {
  return Math.round(seconds / 60)
}

function minToSec(minutes: number) {
  return minutes * 60
}

export function MaxWorkingTimeSec() {
  const texts = useTexts()

  return (
    <DurationStepField
      name="maxWorkingTimeSec"
      testid="maxWorkingTimeSec"
      half
      label={texts.maxWorkingTimeSec('(hh:mm)')}
      parse={minToSec}
      format={secToMin}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
