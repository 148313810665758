export const marks = [
  {
    label: '0.5x',
    value: -50,
  },
  {
    label: '1x',
    value: -100,
  },
  {
    label: '2x',
    value: -200,
  },
  {
    label: '3x',
    value: -300,
  },
  {
    label: '4x',
    value: -400,
  },
  {
    label: '5x',
    value: -500,
  },
]

type Mark = (typeof marks)[number]

export function createSnapToMarks(marks: Mark[]) {
  const _marks = marks.concat()

  return function snapTo(value: number, direction: 'prev' | 'next') {
    // filter values valid for the given direction
    const marks = _marks.filter(m => (direction === 'next' ? m.value > value : m.value < value))

    // if no marks satisfies the filter return the value
    if (marks.length === 0) return value

    // Sort marks array based on the absolute difference between value and mark's value
    marks.sort((a, b) => Math.abs(value - a.value) - Math.abs(value - b.value))

    return marks[0].value
  }
}

const snapTo = createSnapToMarks(marks)

export function snapToNext(value: number) {
  return snapTo(value, 'next')
}

export function snapToPrev(value: number) {
  return snapTo(value, 'prev')
}
