import type { PopupPlugin } from '../../types'

import { journal } from '@/server-data'

import { copyExternalStylesheets } from './core/copyExternalStylesheets'
import { keepDocumentStylesheetsInSync } from './core/keepDocumentStylesheetsInSync'

/**
 * Create a new popup which styles are kept in sync with the main window ones.
 */
export function createStyledPopupPlugin() {
  let externalStylesheetsCache = new WeakMap<Node, Node>()
  let stopSyncingDocumentStylesheets: () => void

  const plugin: PopupPlugin = {
    // --------------------------------------------------
    // POPUP LOAD
    // --------------------------------------------------
    onLoad: popupData => {
      const { popupWindow, id } = popupData

      const popupDoc = popupWindow.document
      const mainWindowDoc = window.document

      // Create the CSS container
      const documentStyleSheetsContainer = document.createElement('style') as HTMLStyleElement
      popupDoc.head.appendChild(documentStyleSheetsContainer)

      // Copy the external CSS links
      copyExternalStylesheets({
        popupDoc,
        mainWindowDoc,
        measurePerformance: false,
        mutableCache: externalStylesheetsCache,
      })

      // Copy the internal CSS rules and keep them in sync
      stopSyncingDocumentStylesheets = keepDocumentStylesheetsInSync({
        mainWindowDoc,
        mutableContainer: documentStyleSheetsContainer,
        initialSync: true,
        measurePerformance: false,
      })

      journal.main('Initial popup styles copied', { info: { id } })
    },

    // --------------------------------------------------
    // POPUP CLOSE
    // --------------------------------------------------
    onClose: popupData => {
      const { id } = popupData

      stopSyncingDocumentStylesheets()
      externalStylesheetsCache = new WeakMap<Node, Node>()

      journal.main('Popup styles cleaned up', { info: { id } })
    },
  }

  return plugin
}
