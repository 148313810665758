import type { BulkTimeWindowsError } from '../../types'

import { Stack } from '@mui/material'

import { EditTimeWindow } from './components/EditTimeWindow'
import { useActions } from './useActions'

interface Props {
  onUpdateTimeWindow: (timeWindow: uui.domain.rm.TimeWindow, timeWindowIndex: 0 | 1) => void
  onRemoveTimeWindow: (timeWindowIndex: 0 | 1) => void
  validate: (force?: boolean) => void
  value: [uui.domain.rm.TimeWindow?, uui.domain.rm.TimeWindow?]
  errors?: Readonly<BulkTimeWindowsError[]>
  disabled?: boolean
}

export function EditTimeWindows(props: Props) {
  const { value, onRemoveTimeWindow, validate, disabled, onUpdateTimeWindow, errors = [] } = props
  const [tw1, tw2] = value

  const actions = useActions(onUpdateTimeWindow, onRemoveTimeWindow)

  const invalidTimeWindowsError = errors.find(e => e.id === 'invalidTimeWindowsError')
  const invalidTimeWindowIndexes = invalidTimeWindowsError?.invalidTimeWindowIndexes ?? []

  const tw1ErrorMessage = invalidTimeWindowIndexes.includes(0)
    ? invalidTimeWindowsError?.message ?? 'Unknown error'
    : undefined

  const tw2ErrorMessage = invalidTimeWindowIndexes.includes(1)
    ? invalidTimeWindowsError?.message ?? 'Unknown error'
    : undefined

  return (
    <Stack spacing={2}>
      {!!tw1 && (
        <EditTimeWindow
          value={tw1}
          onRemoveTimeWindow={actions.onRemoveTimeWindow1}
          onUpdateTimeWindow={actions.onUpdateTimeWindow1}
          validate={validate}
          disabled={disabled}
          errorMessage={tw1ErrorMessage}
        />
      )}
      {!!tw2 && (
        <EditTimeWindow
          value={tw2}
          onRemoveTimeWindow={actions.onRemoveTimeWindow2}
          onUpdateTimeWindow={actions.onUpdateTimeWindow2}
          validate={validate}
          disabled={disabled}
          errorMessage={tw2ErrorMessage}
        />
      )}
    </Stack>
  )
}
