import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    rmAlertInfoBox: translate({
      id: 'rm.sidebar.routeDetails.header.rmonly.infobox',
    }),
  }))

  return api
}
