import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'

import { useTexts } from './useTexts'

type Value = 'not-restricted' | 'restricted'

interface Props {
  id: string
  title: string
  value: Value
  onChange: (_, value: Value) => void
}

export function RadioButtons(props: Props) {
  const { id, value, onChange, title } = props
  const texts = useTexts()

  return (
    <FormControl>
      <FormLabel id={id}>{title}</FormLabel>
      <RadioGroup aria-labelledby={id} name="radio-buttons-group" value={value} onChange={onChange}>
        <FormControlLabel value="not-restricted" control={<Radio />} label={texts.notRestricted} />
        <FormControlLabel value="restricted" control={<Radio />} label={texts.restricted} />
      </RadioGroup>
    </FormControl>
  )
}
