import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    listTitle: translate({ id: 'order.form.bulk.tags.partialList.heading' }),
    warningTooltip: (bannedTagCategory: 'tagsIn' | 'tagsOut') =>
      translate({
        id:
          bannedTagCategory === 'tagsIn'
            ? 'order.form.bulk.tags.warningTooltip.tagsIn'
            : 'order.form.bulk.tags.warningTooltip.tagsOut',
      }),
  }))

  return api
}
