import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'
import type Point from 'ol/geom/Point'
import { gis } from '@/server-data'

import { getInspectPinFeatureStyle } from './getInspectPinFeatureStyle'

export function updateInspectPinFeature(
  inspectPin: uui.domain.ui.map.InspectPin,
  feature: Feature<Geometry>,
) {
  const { latLng } = inspectPin

  const lonLat = gis.fromLatLngToCoordinate(latLng)

  // ------------------------
  // Feature Map position
  // ------------------------

  const point = feature.getGeometry() as Point

  if (!point) {
    throw new Error(`Trying to update the InspectPin without a valid geometry.`)
  }

  point.setCoordinates(lonLat)

  // ------------------------
  // Feature Style

  const style = getInspectPinFeatureStyle(inspectPin)
  feature.setStyle(style)

  return feature
}
