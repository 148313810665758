import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

/**
 * Update a new Order.
 *
 * @private
 */
export const updateOrder = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  uui.domain.ui.forms.OrderUpdate,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/order/update', async (order, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/order/update', {
      category: 'rpc',
      type: 'rpc/order/update',
      update: order,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {updateOrder}`,
      { tags: ['rpc', 'order'], info: order },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Update order Failed`,
      error,
    })
  }
})
