import type { FormFields, FormErrors } from '../formFields'

import { Box } from '@mui/material'
import { TextField } from '@workwave-tidal/tidal/form-ui'

import { useTexts } from '../../hooks/useTexts'

export function CompanyName() {
  const texts = useTexts()

  return (
    <Box paddingTop={1}>
      {/* Please remove the above <Box /> when https://workwave.atlassian.net/browse/CUI-419 will be fixed */}
      <TextField<'companyName', FormFields, FormErrors>
        name="companyName"
        label={texts.companyName}
        testId="companyName"
        validateOn="blur focus change"
      />
    </Box>
  )
}
