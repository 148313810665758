import type { FormApi, FormError, FormField, FormValues } from '@workwave-tidal/tidal/form-fairy'

import { useCallback } from 'react'
import { useIsUnmounted } from '../../../../../../../../hooks'

const delay = (duration: number) => new Promise(resolve => window.setTimeout(resolve, duration))

export function useFooterActions<
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS>,
>(
  formApi: Readonly<FormApi<FIELDS, ERROR>>,
  submit: (formValues: Readonly<FormValues<FIELDS>>) => Promise<boolean>,
  cancel: () => void,
) {
  // track the mounted state of the parent component
  const isUnmounted = useIsUnmounted()

  return {
    // Reset the form to its original values
    onReset: useCallback(() => formApi.reset('force'), [formApi]),

    // Try to submit the form
    // It will validate the Form if necessary
    // After the form is marked as valid it will emulate an async action with a 5 seconds timer
    onSubmit: useCallback(async () => {
      // Set FormState into submitting mode
      formApi.setSubmitting(true)

      // Let's add a fake delay to let the user understand that a validation is in progress
      await delay(300)

      // verify if the form is already valid
      const formIsValidBeforeSubmitting = formApi.getMeta().status === 'valid'

      // Validate the form if necessary, and wait for the validation result
      const formIsValid = formIsValidBeforeSubmitting || (await formApi.validate())

      if (!formIsValid) {
        // Clear FormState submitting mode
        formApi.setSubmitting(false)
        return
      }

      const success = await submit(formApi.getValues())

      // Clear FormState submitting mode
      formApi.setSubmitting(false)

      // if the component has been unmounted during the async operation stop here
      if (isUnmounted()) return

      // if the update has been completed successfully let's close the form
      if (success) cancel()
    }, [formApi, isUnmounted, submit, cancel]),
  } as const
}
