import type {
  SimulationDateType,
  CalendarRange,
  SimulationDate,
} from '../primitives/SimulationDatesPicker/typings'

import { PureComponent } from 'react'

import { subDays } from 'date-fns/esm'

import Block from '../edit/Block'
import Label from '../edit/Label'
import FieldMessage from '../edit/FieldMessage'

import RestoreFieldButton from '../primitives/RestoreFieldButton'
import { SimulationDatesPicker } from '../primitives/SimulationDatesPicker'

import { Props } from './typings'

export default class SimulationDatesPickerField extends PureComponent<Props> {
  private handleOnChange = (calendarRange: CalendarRange, type: SimulationDateType) => {
    const {
      formProps: {
        input: { onChange },
      },
    } = this.props

    let simulationDate: SimulationDate = {
      type: 'fromScratch',
    }

    switch (type) {
      case 'fromScratch':
        break
      case 'fromOperations':
        simulationDate = {
          type,
          dates: calendarRange,
        }
        break
      case 'fromArchived':
        simulationDate = {
          type,
          date: calendarRange.min,
        }
    }

    onChange(simulationDate)
  }

  private handleOnChangeType = (type: SimulationDateType) => {
    const {
      formProps: {
        input: { value, onChange },
      },
      wrappedInputProps: { today, firstDayNotArchived },
    } = this.props

    if (value.type === type) {
      return null
    }

    let simulationDate: SimulationDate = {
      type: 'fromScratch',
    }

    switch (type) {
      case 'fromScratch':
        break

      case 'fromOperations':
        simulationDate = {
          type: 'fromOperations',
          dates: { min: today, max: today },
        }
        break
      case 'fromArchived':
        simulationDate = {
          type: 'fromArchived',
          date: subDays(firstDayNotArchived, 1),
        }
        break
    }

    onChange(simulationDate)
  }

  render() {
    const {
      label,
      name,
      info,
      wrappedInputProps,
      formProps: {
        input: { value, onChange },
        meta: { error, dirty, initial },
      },
    } = this.props

    return (
      <Block error={!!error} dirty={!!dirty}>
        <Label htmlFor={name}>
          {label}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <SimulationDatesPicker
          {...wrappedInputProps}
          onChangeType={this.handleOnChangeType}
          onChange={this.handleOnChange}
          value={value}
          messages={
            <>
              {!!error && <FieldMessage error>{error}</FieldMessage>}
              {!error && !!info && <FieldMessage>{info}</FieldMessage>}
            </>
          }
        />
      </Block>
    )
  }
}
