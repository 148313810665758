import styled from 'styled-components'

const IconContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;

  width: 8px;

  transform: translate(-50%, -50%);
`

IconContainer.displayName = 'IconContainer'
export default IconContainer
