import styled from 'styled-components'

type Props = {
  thin?: boolean
  avoidEllipsis?: boolean
}

const checkEllipsis = ({ avoidEllipsis }: Props): string =>
  avoidEllipsis
    ? ''
    : `
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `

const AssetListText = styled.p<Props>`
  margin: 0;
  font-weight: ${p => (p.thin ? p.theme.weights.$regular : p.theme.weights.$semiBold)};
  color: ${p => (p.thin ? p.theme.colors.$shadyLady : p.theme.colors.$nightRider)};

  font-size: ${p => p.theme.fonts.$p2};

  ${checkEllipsis}

  transition: color 0.3s ease;
`

AssetListText.displayName = 'AssetListText'
export default AssetListText
