import { useFormTransaction } from '@/hooks'

import { useNewAlertValue } from './hooks/useNewAlertValue'
import { SingleEdit } from './SingleEdit'

interface Props {
  alert?: uui.domain.client.gps.wwgps.EventAlert
}

export function SingleEditProvider(props: Props) {
  const { alert } = props

  const newValue = useNewAlertValue()

  const transactionState = useFormTransaction()
  const loading = transactionState === 'loading'

  if (!alert) {
    return <SingleEdit alert={newValue} loading={loading} creating />
  }

  return <SingleEdit alert={alert} loading={loading} />
}
