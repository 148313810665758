import { useCallback, MouseEvent } from 'react'
import { Stack } from '@mui/material'

import { Button } from '@/local/components'
import { useTexts } from './useTexts'

type Props = {
  recurrenceType: uui.domain.rm.RecurrenceTypes
  onChangeRecurrenceType: (recurrenceType: uui.domain.rm.RecurrenceTypes) => void
}

export function RecurrenceTypePicker(props: Props) {
  const { recurrenceType, onChangeRecurrenceType } = props
  const texts = useTexts()

  const handleOnChange = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const recurrence = e.currentTarget.dataset.recurrence as uui.domain.rm.RecurrenceTypes
      onChangeRecurrenceType(recurrence)
    },
    [onChangeRecurrenceType],
  )

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between">
      <Button
        data-recurrence="DATE"
        preset={recurrenceType === 'DATE' ? 'confirm' : 'cancel'}
        onClick={handleOnChange}
      >
        {texts.oneTimeTranslation}
      </Button>

      <Button
        data-recurrence="ANNUAL"
        preset={recurrenceType === 'ANNUAL' ? 'confirm' : 'cancel'}
        onClick={handleOnChange}
      >
        {texts.yearlyTranslation}
      </Button>

      <Button
        data-recurrence="MONTHLY"
        preset={recurrenceType === 'MONTHLY' ? 'confirm' : 'cancel'}
        onClick={handleOnChange}
      >
        {texts.monthlyTranslation}
      </Button>

      <Button
        data-recurrence="WEEKLY"
        preset={recurrenceType === 'WEEKLY' ? 'confirm' : 'cancel'}
        onClick={handleOnChange}
      >
        {texts.weeklyTranslation}
      </Button>
    </Stack>
  )
}
