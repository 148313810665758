import { Box, styled } from '@mui/material'

export const AppContent = styled(Box, { name: 'AppContent' })({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 10,
})
