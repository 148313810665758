import type { ReactElement } from 'react'

import { Box } from '@mui/material'

interface Props {
  children: ReactElement
}

export function FullScreenContainer(props: Props) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#fff',
        zIndex: 100,
      }}
    >
      {props.children}
    </Box>
  )
}
