import styled from 'styled-components'

import { normalizeCssValue, ValidColor } from '@/local/components'

type Props = {
  alignItems?: 'center' | 'start' | 'end' | 'stretch'
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  shrink?: 1 | 0
  width?: number | string
  height?: number | string
  padding?: string
  marginTop?: string | number
  marginBottom?: string | number
  marginLeft?: string | number
  marginRight?: string | number
  background?: ValidColor
  paddingTop?: string | number
  paddingBottom?: string | number
  paddingLeft?: string | number
  paddingRight?: string | number
}

export const VerticalLayout = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-shrink: ${p => (typeof p.shrink === 'number' ? p.shrink : 0)};
  width: ${p => normalizeCssValue(p.width || '100%')};
  height: ${p => normalizeCssValue(p.height || '100%')};
  align-items: ${p => p.alignItems ?? 'start'};
  justify-content: ${p => p.justifyContent ?? 'normal'};
  padding: ${p => p.padding || 0};
  padding-top: ${p => normalizeCssValue(p.paddingTop || 0)};
  padding-bottom: ${p => normalizeCssValue(p.paddingBottom || 0)};
  padding-left: ${p => normalizeCssValue(p.paddingLeft || 0)};
  padding-right: ${p => normalizeCssValue(p.paddingRight || 0)};

  margin-top: ${p => normalizeCssValue(p.marginTop || 0)};
  margin-bottom: ${p => normalizeCssValue(p.marginBottom || 0)};
  margin-left: ${p => normalizeCssValue(p.marginLeft || 0)};
  margin-right: ${p => normalizeCssValue(p.marginRight || 0)};
  background: ${p => (p.background ? p.theme.colors[p.background] : 'transparent')};
`

VerticalLayout.displayName = 'VerticalLayout'
