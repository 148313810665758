import type { OnSelectCallbackParams } from '@/components/StructuredVirtualList'

import { useCallback } from 'react'

import { useReadOnly } from '@/hooks'
import { useDataForList } from './useDataForList'

export function useListHandlers(selection: workwave.DeepReadonly<string[]>) {
  const { readonly } = useReadOnly()
  const { structure, setEditing, deselectAll, selectDriverAssignments } = useDataForList()

  const handleOnSelect = useCallback(
    (params: OnSelectCallbackParams<'driverAssignments'>) => {
      const depotIds: string[] = []

      for (const id of params.newSelectedIds) {
        if (id.startsWith('group')) continue
        depotIds.push(id)
      }

      selectDriverAssignments(depotIds)
    },
    [selectDriverAssignments],
  )

  const handleOnSelectAll = useCallback(() => {
    const allSelected = structure.shownItems <= selection.length

    const ids: string[] = []

    for (const listItem of structure.list) {
      if (typeof listItem === 'string') {
        ids.push(listItem)
      }
    }

    allSelected ? deselectAll() : selectDriverAssignments(ids)
  }, [selectDriverAssignments, selection, deselectAll, structure])

  const handleOnDoubleClick = useCallback(() => {
    if (readonly) return

    setEditing([...selection])
  }, [setEditing, readonly, selection])

  const handleOnClick = useCallback(() => {
    if (readonly) return

    deselectAll()
    setEditing([])
  }, [setEditing, readonly, deselectAll])

  return {
    handleOnSelect,
    handleOnClick,
    handleOnSelectAll,
    handleOnDoubleClick,
  }
}
