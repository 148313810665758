import { useEffect, useRef, useState } from 'react'

type Ticket = [ticket: symbol, ready: boolean]

export const RESET_RENDERING_QUEUE = Symbol('Reset rendering queue')
export const RENDERING_QUEUE_EMPTY = Symbol('Rendering queue empty')

type QueueSubscriber = (nextTicket: symbol) => void
type UnsubscribeFromQueue = () => void

const cache = {
  main: [] as symbol[],
}

const cacheSubscribers = {
  main: new Set<QueueSubscriber>(),
}

// -----------------------------------------------------------------
// Queue utilities
// -----------------------------------------------------------------

const createTicket = (label?: string) => Symbol(label)

const notifyQueue = () => {
  const nextTicket = cache.main[0] ?? RENDERING_QUEUE_EMPTY

  for (const subscriber of cacheSubscribers.main) {
    subscriber(nextTicket)
  }
}

// -----------------------------------------------------------------
// Queue API
// -----------------------------------------------------------------

export const getTicket = (label?: string): Ticket => {
  const ticket = createTicket(label)

  const ready = cache.main.length === 0
  cache.main.push(ticket)

  notifyQueue()

  return [ticket, ready]
}

export const closeTicket = (ticket: symbol) => {
  if (!cache.main.includes(ticket)) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`Map rendering queue - Trying to close an unknown ticket`)
    }

    return
  }

  if (cache.main[0] === ticket) {
    // remove the item from the list
    cache.main.shift()
    // move to the next item in the queue
    notifyQueue()

    return
  }

  // if is not the ticket being processed
  // simply remove it
  const index = cache.main.indexOf(ticket)
  if (index > -1) {
    cache.main.splice(index, 1)

    if (cache.main.length === 0) {
      notifyQueue()
    }
  }
}

export const subscribeToRenderingQueue = (subscriber: QueueSubscriber): UnsubscribeFromQueue => {
  cacheSubscribers.main.add(subscriber)

  return () => {
    cacheSubscribers.main.delete(subscriber)
  }
}

export const clearQueue = (removeSubscribers: boolean = false) => {
  cache.main = []

  // notify every subscriber to clear its local queue
  for (const subscriber of cacheSubscribers.main) {
    subscriber(RESET_RENDERING_QUEUE)
  }

  if (removeSubscribers) {
    cacheSubscribers.main.clear()
  }
}

// -----------------------------------------------------------------
// Helper to connect and consume the rendering queue
// -----------------------------------------------------------------

export type QueuedAction = () => Promise<void>
export type RunQueuedAction = (ticket: symbol, action: QueuedAction) => Promise<void>
export type GetTicketAndRunAction = (action: QueuedAction, label?: string) => symbol

const defaultRunAction: RunQueuedAction = async (ticket, action) => {
  try {
    await action()
  } catch (e) {
    // JOURNAL:
    console.error(`Failed to run a queued action`, e)
  } finally {
    closeTicket(ticket)
  }
}

type ConnectToRenderingQueueResult = [
  getTicket: GetTicketAndRunAction,
  run: RunQueuedAction,
  unsubscribe: UnsubscribeFromQueue,
]

export const connectToRenderingQueue = (
  runAction: RunQueuedAction = defaultRunAction,
): ConnectToRenderingQueueResult => {
  const queue = new Map<symbol, QueuedAction>()
  let activeTicket: symbol | undefined

  const unsubscribe = subscribeToRenderingQueue(ticket => {
    if (ticket === activeTicket) return

    activeTicket = undefined

    // if a special ticket is provided, clear the local queue
    if (ticket === RESET_RENDERING_QUEUE) {
      queue.clear()
      return
    }

    // the queue is empty, do nothing
    if (ticket === RENDERING_QUEUE_EMPTY) return

    // check if the ticket is known
    const queuedAction = queue.get(ticket)

    if (queuedAction) {
      activeTicket = ticket
      // run the queued action
      runAction(ticket, queuedAction)
      // remove the queued action from the queue
      queue.delete(ticket)
    }
  })

  const getTicketAndRun = (action: QueuedAction, label?: string) => {
    const [ticket, ready] = getTicket(label)

    queue.set(ticket, action)

    // this is required because `action` is not yet in the queue
    if (ready) {
      activeTicket = ticket
      runAction(ticket, action)
    }

    return ticket
  }

  return [getTicketAndRun, runAction, unsubscribe]
}

// -----------------------------------------------------------------
// React Hook
// -----------------------------------------------------------------

const createCancelableInterval = () => {
  let timeout: ReturnType<typeof setTimeout> | undefined

  let running = false
  let resolveInterval = (_: boolean | PromiseLike<boolean>) => {}

  return {
    running: () => {
      return running
    },

    run: (delay: number) => {
      running = true

      if (timeout) {
        clearTimeout(timeout)
      }

      return new Promise<boolean>(resolve => {
        resolveInterval = resolve
        timeout = setTimeout(() => resolve(true), delay)
      })
    },

    cancel: () => {
      running = false

      resolveInterval(false)

      if (timeout) {
        clearTimeout(timeout)
      }
    },
  }
}

export function useMapRenderingQueue(delay: number = 500, minDuration: number = 4000) {
  const [queueRunning, setQueueRunning] = useState(false)

  const queueRunningRef = useRef(queueRunning)
  useEffect(() => void (queueRunningRef.current = queueRunning), [queueRunning])

  useEffect(() => {
    const wait = createCancelableInterval()
    const waitSafety = createCancelableInterval()
    let effectCleared = false

    let startTime = 0

    const unsubscribe = subscribeToRenderingQueue(async nextTicket => {
      const running = nextTicket !== RESET_RENDERING_QUEUE && nextTicket !== RENDERING_QUEUE_EMPTY

      if (effectCleared) return

      if (running) {
        // if the queue is already running do nothing
        if (queueRunningRef.current) return

        const completed = await wait.run(delay)

        if (completed) {
          if (effectCleared) return

          startTime = performance.now()

          setQueueRunning(true)

          const killIt = await waitSafety.run(5000)
          if (killIt) {
            if (effectCleared) return
            setQueueRunning(false)
          }
        } else {
          if (effectCleared) return
          setQueueRunning(false)
        }
      } else {
        if (waitSafety.running()) {
          waitSafety.cancel()
        }

        if (wait.running()) {
          wait.cancel()
        }

        // ATTENTION: this is commented because in some situation a race condition prevent the timer on stopping as expected
        // if the queue is already stopped do nothing
        // if (!queueRunningRef.current) return

        const timeElapsed = performance.now() - startTime

        if (queueRunningRef.current && timeElapsed < minDuration) {
          await wait.run(minDuration - timeElapsed + delay)

          if (effectCleared) return
        }

        setQueueRunning(false)
      }
    })

    return () => {
      effectCleared = true
      wait.cancel()
      waitSafety.cancel()
      unsubscribe()
    }
  }, [delay, minDuration])

  return queueRunning
}

// -----------------------------------------------------------------
// -----------------------------------------------------------------
// Helper to split rendering into multiple frames to prevent
// unintended UI freezing
// -----------------------------------------------------------------
// -----------------------------------------------------------------

export const waitFrame = () => new Promise(resolve => window.requestAnimationFrame(resolve))

export const createNonBlockingRenderByTime = (milliseconds: number = 16) => {
  let startTime = 0

  const reset = () => {
    startTime = performance.now()
  }

  const next = async () => {
    const timeElapsed = performance.now() - startTime

    if (timeElapsed > milliseconds) {
      await waitFrame()
      reset()
    }
  }

  return { reset, next } as const
}
