import { useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useEffect, useRef, useState, useCallback, useMemo } from 'react'

import { useAppDispatch } from '@/store'
import { useIsUnmounted } from '@/hooks'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'
import { loadOrderStepTrackingLink } from '@/features/domain/order'

import { OrdStepId } from '../typings'
import { getOrdStepId } from '../utils'

import {
  setOrderStepTrackingLink,
  hasOrderStepTrackingLink,
} from '../../../../../orderNotificationAtom'

type LoadingStatus = 'loading' | 'failed' | 'ready'

export const useOrderStepTrackingLinkStatus = (
  extendedOrder: uui.domain.client.rm.ExtendedOrderStep,
) => {
  const {
    orderStep: { id: orderStepId },
  } = extendedOrder

  const dispatch = useAppDispatch()
  const isUnmounted = useIsUnmounted()
  const calendarRange = useSelector(selectCalendarRange)
  const { planType } = useSelector(selectUserConfiguration)

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(() => 'loading')

  const archivedDate = planType === 'archived' ? calendarRange.minDate : undefined

  const waitingForServer = useRef(false)
  const ordStepId = useMemo(() => getOrdStepId(extendedOrder), [extendedOrder])

  const fetchData = useCallback(
    async (ordStepId: OrdStepId, orderStepId: string, archivedDate?: string) => {
      waitingForServer.current = true

      const notificationsRequest = await dispatch(
        loadOrderStepTrackingLink({
          ordStepId,
          archivedDate,
        }),
      )

      if (isUnmounted()) return

      if (loadOrderStepTrackingLink.fulfilled.match(notificationsRequest)) {
        const trackingLink = unwrapResult(notificationsRequest)

        if (trackingLink) {
          setOrderStepTrackingLink(orderStepId, trackingLink)
        }

        waitingForServer.current = false

        setLoadingStatus('ready')
      } else {
        setLoadingStatus('failed')
      }
    },
    [dispatch, isUnmounted],
  )

  useEffect(() => {
    if (hasOrderStepTrackingLink(orderStepId)) {
      setLoadingStatus('ready')
    } else {
      setLoadingStatus('loading')
      fetchData(ordStepId, orderStepId, archivedDate)
    }
  }, [orderStepId, ordStepId, fetchData, archivedDate])

  return loadingStatus
}
