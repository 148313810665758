import { Component } from 'react'

import { ClearSelectionIcon, TextButton, FlexRow, FlexColumn } from '@/forms-legacy'
import { Cross } from '@/icons'

import Text from './elements/Text'
import Button from './elements/Button'
import PanelContainer from './elements/PanelContainer'
import ButtonContainer from './elements/ButtonContainer'

import { Props } from './typings'

export default class PairedOrderPanel extends Component<Props> {
  render() {
    const {
      onOnlySelected,
      onAlsoPaired,
      onCancel,
      title,
      subtitle,
      onlySelectedButtonText,
      alsoPairedButtonText,
    } = this.props

    return (
      <PanelContainer>
        <FlexRow style={{ height: '100%', padding: ' 0 0 2px' }}>
          <FlexColumn primary>
            <Text bold>{title}</Text>
            <Text>{subtitle}</Text>
          </FlexColumn>
          <FlexColumn style={{ height: 'inherit' }}>
            <FlexRow style={{ height: 'inherit' }}>
              <TextButton onClick={onCancel}>
                <ClearSelectionIcon>
                  <Cross />
                </ClearSelectionIcon>
              </TextButton>
              <ButtonContainer>
                <Button style={{ marginLeft: 14 }} onClick={onOnlySelected}>
                  {onlySelectedButtonText}
                </Button>
                <Button style={{ marginLeft: 14 }} onClick={onAlsoPaired} alsoPaired>
                  {alsoPairedButtonText}
                </Button>
              </ButtonContainer>
            </FlexRow>
          </FlexColumn>
        </FlexRow>
      </PanelContainer>
    )
  }
}
