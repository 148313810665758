import { CircularProgress } from '@mui/material'
import { NestedDropdown, DropdownMenuItem } from '@/components/DropdownMenu'
import { StrikedEye } from '@/icons'

import { ActiveIcon } from '../ActiveIcon'

import { usePlacesMapStyle } from './hooks/usePlacesMapStyle'
import { useHasPlaces } from './hooks/useHasPlaces'
import { useActions } from './hooks/useActions'
import { useTexts } from './useTexts'

interface Props {
  parentOpen: boolean
}

export function PlacesSubMenu(props: Props) {
  const { parentOpen } = props

  const {
    methodInExecution,
    actions: { showPlaces, hidePlaces },
  } = useActions()
  const activeStyle = usePlacesMapStyle()
  const hasPlaces = useHasPlaces()
  const texts = useTexts()

  const tooltipTitle = hasPlaces === true ? undefined : texts.noPlaces

  return (
    <NestedDropdown
      placement="left"
      disabled={!hasPlaces}
      parentOpen={parentOpen}
      tooltipTitle={tooltipTitle}
      triggerContent={texts.places}
      Icon={
        hasPlaces && activeStyle === 'off' ? <StrikedEye size={12} color="$silver" /> : undefined
      }
    >
      <DropdownMenuItem
        external
        onClick={showPlaces}
        Icon={
          methodInExecution === 'showPlaces' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'on'} />
          )
        }
      >
        {texts.on}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={hidePlaces}
        Icon={<ActiveIcon active={activeStyle === 'off'} />}
      >
        {texts.off}
      </DropdownMenuItem>
    </NestedDropdown>
  )
}
