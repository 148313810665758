import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

export const findFeature = (
  layer: VectorLayer<VectorSource>,
  id: string,
): Feature<Geometry> | undefined => {
  return layer.getSource()?.getFeatureById(id) ?? undefined
}
