import type { PublicProps as Props } from './typings'

import { useEffect } from 'react'

import { FixedInterval } from './Layouts/FixedInterval'
import { Interval } from './Layouts/Interval'
import { Monthly } from './Layouts/Monthly'
import { Weekly } from './Layouts/Weekly'
import { Annual } from './Layouts/Annual'
import { Dates } from './Layouts/Dates'
import { Date } from './Layouts/Date'

import { useReferenceDate } from './hooks/useReferenceDate'

export function Calendar(props: Props) {
  const { onReferenceDateChange } = props
  const [referenceDate, setReferenceDate] = useReferenceDate(props.today, props.initialVisibleDate)

  useEffect(() => {
    onReferenceDateChange?.(referenceDate)
  }, [onReferenceDateChange, referenceDate])

  switch (props.layout) {
    case 'interval':
      return (
        <Interval {...props} referenceDate={referenceDate} setReferenceDate={setReferenceDate} />
      )

    case 'fixed-interval':
      return (
        <FixedInterval
          {...props}
          referenceDate={referenceDate}
          setReferenceDate={setReferenceDate}
        />
      )

    case 'annual':
      return <Annual {...props} referenceDate={referenceDate} setReferenceDate={setReferenceDate} />

    case 'monthly':
      return (
        <Monthly {...props} referenceDate={referenceDate} setReferenceDate={setReferenceDate} />
      )

    case 'weekly':
      return <Weekly {...props} referenceDate={referenceDate} setReferenceDate={setReferenceDate} />

    case 'date':
      return <Date {...props} referenceDate={referenceDate} setReferenceDate={setReferenceDate} />

    case 'dates':
      return <Dates {...props} referenceDate={referenceDate} setReferenceDate={setReferenceDate} />

    default:
      return <div>Coming soon</div>
  }
}
