import { useCallback, useRef } from 'react'

interface ThrottleOptions {
  leading: boolean
  trailing: boolean
}

type ThrottleFunction = (...args: any[]) => void

export function useThrottle(
  fn: ThrottleFunction,
  wait: number,
  option: ThrottleOptions = { leading: true, trailing: true },
): ThrottleFunction {
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null) // track the timer
  const lastArgs = useRef<any[]>([]) // track the args

  // create a memoized debounce
  const throttle = useCallback<ThrottleFunction>(
    function (...args) {
      const { trailing, leading } = option
      // function for delayed call
      const waitFunc = () => {
        // if trailing invoke the function and start the timer again
        if (trailing && lastArgs.current.length > 0) {
          // fn.apply(this, lastArgs.current)
          fn(...lastArgs.current)
          lastArgs.current = []
          timerId.current = setTimeout(waitFunc, wait)
        } else {
          // else reset the timer
          timerId.current = null
        }
      }

      // if leading run it right away
      if (!timerId.current && leading) {
        fn(...args)
        // fn.apply(this, args)
      }
      // else store the args
      else {
        lastArgs.current = args
      }

      // run the delayed call
      if (!timerId.current) {
        timerId.current = setTimeout(waitFunc, wait)
      }
    },
    [fn, wait, option],
  )

  return throttle
}
