import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    delete: (count: number) =>
      translate({
        id: 'form.drivers.modal.delete.action.delete',
        values: { count },
      }),
    changePassword: translate({ id: 'form.drivers.view.actions.changePassword' }),
    labels: {
      email: translate({ id: 'form.drivers.view.labels.email' }),
      name: translate({ id: 'form.drivers.view.labels.name' }),
      driverTracked: translate({ id: 'form.drivers.view.labels.driverTracked' }),
      tracked: (tracked: boolean) =>
        translate({
          id: tracked ? 'form.drivers.view.labels.tracked' : 'form.drivers.view.labels.notTracked',
        }),
      mobileFutureDaysVisibility: translate({
        id: 'form.drivers.view.labels.mobileFutureDaysVisibility',
      }),
      summarizeFutureMobileRoute: translate({
        id: 'form.drivers.view.labels.summarizeFutureMobileRoute',
      }),
    },
    driverAppTitle: translate({ id: 'form.drivers.driverApp.title' }),
    routeViewPreferences: translate({ id: 'form.drivers.driverApp.routeViewPreferences' }),
    values: {
      mobileFutureDaysVisibility: (
        mobileFutureDaysVisibility: uui.domain.client.rm.MobileFutureDaysVisibility,
      ) => {
        switch (mobileFutureDaysVisibility) {
          case -1:
            return translate({
              id: 'form.drivers.view.values.mobileFutureDaysVisibility.allDays',
            })
          case 2:
            return translate({
              id: 'form.drivers.view.values.mobileFutureDaysVisibility.todayAndNextTwoDays',
            })
          default:
            return mobileFutureDaysVisibility
        }
      },
      summarizeFutureMobileRoute: (summarizeFutureMobileRoute: boolean) => {
        switch (summarizeFutureMobileRoute) {
          case true:
            return translate({ id: 'form.drivers.view.values.summarizeFutureMobileRoute.summary' })
          case false:
            return translate({
              id: 'form.drivers.view.values.summarizeFutureMobileRoute.fullDetails',
            })
        }
      },
    },
  }))

  return api
}
