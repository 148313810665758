import { useMemo } from 'react'

import { TrafficRegionAvatarContainer } from './TrafficRegionAvatarContainer'

interface Props {
  region: uui.domain.client.rm.TrafficRegion
}

const size = 40

export function TrafficRegionAvatar(props: Props) {
  const {
    region: { points },
  } = props

  const svgPoints = useMemo(() => {
    return points.reduce((pathPoints, point: uui.domain.LatLng) => {
      const lat = point.lat
      const lng = -point.lng

      let x = (lng / 360 + 0.5) * size
      let y = (1 - (lat / 180 + 0.5)) * size

      x = (parseInt(x.toFixed(2)) * 100) / 100
      y = (parseInt(y.toFixed(2)) * 100) / 100

      return `${pathPoints} ${x},${y}`
    }, '')
  }, [points])

  return (
    <TrafficRegionAvatarContainer background="#999">
      <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <polyline
          points={svgPoints}
          fill="#ffff"
          fillOpacity="0.4"
          stroke="#fff"
          strokeOpacity="1"
        />
      </svg>
    </TrafficRegionAvatarContainer>
  )
}
