import { VerticalLayout } from '@/components/layout'

import { Message } from './blocks/Message'
import { Driver } from './blocks/Driver'

export function RmBody() {
  return (
    <VerticalLayout marginTop={32}>
      <Driver />
      <Message />
    </VerticalLayout>
  )
}
