import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    english: translate({ id: 'settings.locale.values.english' }),
    german: translate({ id: 'settings.locale.values.german' }),
    spanish: translate({ id: 'settings.locale.values.spanish' }),
    italian: translate({ id: 'settings.locale.values.italian' }),
    dutch: translate({ id: 'settings.locale.values.dutch' }),
    polish: translate({ id: 'settings.locale.values.polish' }),
    portuguese: translate({ id: 'settings.locale.values.portuguese' }),

    updateGpsFormatsSuccess: translate({
      id: 'settings.languageAndFormats.edit.save.success',
    }),
    updateGpsFormatsError: translate({ id: 'settings.languageAndFormats.edit.save.error' }),
  }))

  return api
}
