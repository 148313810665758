import { useCallback } from 'react'
import { useController } from './useController'

/**
 * Manage territory name change
 */
export const useHandleOnNameChange = () => {
  const { updateData } = useController()

  return useCallback((nextName: string) => updateData({ name: nextName }), [updateData])
}
