import { useCallback } from 'react'

import { Trash } from '@/icons'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'
import { useRecurrences } from '../hooks/useRecurrences'

interface Props {
  setView: (view: 'default' | 'delete') => void
}

export function Actions(props: Props) {
  const { setView } = props

  const texts = useTexts()
  const recurrences = useRecurrences()

  const onDelete = useCallback(() => {
    setView('delete')
  }, [setView])

  // any recurrence can not be deleted
  const anyRecurrence = recurrences.some(r => r.type === 'any')

  return (
    <SecondaryColumnHeaderActionsRow centered>
      <ReadOnlyTooltip
        ignoreReasons={['courier']}
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={onDelete}
            disabled={anyRecurrence || preventEditing}
            data-trackid="setup-vehicleassignment__bulkdelete"
            testid="setup-vehicleassignment__bulkdelete"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
