import { Modal } from '@/components/Modal'

import { type EditTrafficProfileModalProps } from '../types'
import { modalId } from '../hooks/useController'
import { Content } from './Content'

export function Root(props: EditTrafficProfileModalProps) {
  return (
    <Modal modalId={modalId}>
      <Content {...props} />
    </Modal>
  )
}
