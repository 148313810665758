import { type MouseEvent, useCallback, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Card,
  Menu,
  Stack,
  Divider,
  MenuItem,
  CardMedia,
  IconButton,
  Typography,
  CardContent,
  type PopoverOrigin,
  Badge,
  ClickAwayListener,
} from '@mui/material'
import { MoreHoriz } from '@workwave-tidal/icons'

import { selectTelematicsDeviceTransientStatuses } from '@/features/domain/account'
import { selectTelematicsDevicesAsArray } from '@/features/domain/device'
import { useNotification } from '@/hooks'

import { useTexts } from '../../../../hooks/useTexts'
import { StatusChip } from './components/StatusChip'

type Props = {
  setIntegrationId: (integrationId: string | undefined) => void
  changeCredentials: (tenantSource: uui.domain.server.gps.telematics.TenantSource) => void
  deleteIntegration: (tenantSource: uui.domain.server.gps.telematics.TenantSource) => void
  changeLabel: (tenantSource: uui.domain.server.gps.telematics.TenantSource) => void
  description?: string
  integration: uui.domain.server.gps.telematics.TenantSource
  userRole: 'admin' | 'courier' | 'planner' | 'viewer' | 'guest' | 'gpsonly'
  logo: string
}

const anchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

export function ActiveIntegrationCard(props: Props) {
  const {
    changeCredentials,
    deleteIntegration,
    setIntegrationId,
    changeLabel,
    description = '',
    integration,
    userRole,
    logo,
  } = props

  const isAdmin = userRole === 'admin'

  const deviceActivationStatuses = useSelector(selectTelematicsDeviceTransientStatuses)
  const devices = useSelector(selectTelematicsDevicesAsArray)
  const toast = useNotification()
  const texts = useTexts()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback((event: globalThis.MouseEvent | globalThis.TouchEvent) => {
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const onChangeLabel = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(null)
      changeLabel(integration)
    },
    [integration, changeLabel],
  )

  const onChangeCredentials = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(null)
      changeCredentials(integration)
    },
    [integration, changeCredentials],
  )

  const onDeleteIntegration = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(null)
      deleteIntegration(integration)
    },
    [integration, deleteIntegration],
  )

  const onClick = useCallback(() => {
    if (integration.statusCode === 2000) {
      setIntegrationId(integration.id)
    } else {
      toast.error(
        <Stack>
          <Typography variant="body2">
            {texts.tenantSourceErrorMessages.toast.title[integration.statusCode]?.(
              integration.sourceLabel,
            ) ?? integration.statusDescription}
          </Typography>
          <Typography variant="body2">
            {texts.tenantSourceErrorMessages.toast.description[integration.statusCode]?.(
              integration.sourceLabel,
            ) ?? texts.errorCode(`${integration.statusCode}`)}
          </Typography>
        </Stack>,
      )
    }
  }, [texts, toast, setIntegrationId, integration])

  const sx = useMemo(() => {
    return {
      opacity: integration.active ? 1 : 0.5,
      cursor: 'pointer',
      position: 'relative',
      pointerEvents: integration.active ? 'all' : 'none',
    }
  }, [integration])

  const errorsOnDevices = useMemo(() => {
    return (
      integration.statusCode === 2000 &&
      devices.some(
        device =>
          device.tenantSourceId === integration.id &&
          deviceActivationStatuses[device.deviceId]?.status === 'ERROR',
      )
    )
  }, [integration, devices, deviceActivationStatuses])

  const titleBadgeSlotProps = useMemo(
    () => ({
      badge: {
        style: { transform: 'translate(10px, 3px)', display: errorsOnDevices ? 'block' : 'none' },
      },
    }),
    [errorsOnDevices],
  )

  return (
    <Card
      sx={sx}
      onClick={onClick}
      data-testid="active-integration-card"
      data-trackid="active-integration-card"
    >
      <Stack spacing={2}>
        <CardMedia component="img" image={logo} alt={integration.sourceLabel} />

        <StatusChip integration={integration} changeCredentials={changeCredentials} />

        <CardContent>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Badge badgeContent="" slotProps={titleBadgeSlotProps} variant="dot" color="error">
                <Typography gutterBottom variant="h6" flexGrow={1}>
                  {integration.label}
                </Typography>
              </Badge>

              {isAdmin && (
                <>
                  <IconButton onClick={handleClick}>
                    <MoreHoriz />
                  </IconButton>

                  <ClickAwayListener onClickAway={handleClose}>
                    <Menu
                      open={open}
                      onClose={handleClose}
                      anchorEl={anchorEl}
                      anchorOrigin={anchorOrigin}
                      transformOrigin={transformOrigin}
                    >
                      <MenuItem onClick={onChangeLabel}>{texts.editLabel}</MenuItem>
                      <MenuItem onClick={onChangeCredentials}>{texts.editCredentials}</MenuItem>
                      <Divider />
                      <MenuItem onClick={onDeleteIntegration} sx={{ color: 'red' }}>
                        {texts.deleteConnection}
                      </MenuItem>
                    </Menu>
                  </ClickAwayListener>
                </>
              )}
            </Stack>

            <Typography variant="body1">{description}</Typography>
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  )
}
