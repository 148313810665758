import { proxy, useSnapshot } from 'valtio'

type RoutingSidebarSettings =
  | {
      view: 'main' | 'dispatchPanel'
    }
  | { view: 'routeDetails'; routeId: string }

type RoutingSidebarAtom = { settings: RoutingSidebarSettings }

// ---------------------------------------------------------------------------
// Default values
// ---------------------------------------------------------------------------
const defaultRoutingSidebar: RoutingSidebarSettings = {
  view: 'main',
}

// ---------------------------------------------------------------------------
// Routing Sidebar atom
// ---------------------------------------------------------------------------

const createDefaultRoutingSidebar = (): RoutingSidebarAtom => ({
  settings: { ...defaultRoutingSidebar },
})

export const routingSidebarAtom = proxy<RoutingSidebarAtom>(createDefaultRoutingSidebar())

// ---------------------------------------------------------------------------
// Write functions
// ---------------------------------------------------------------------------

type SetRoutingSidebar = (prev: RoutingSidebarSettings) => RoutingSidebarSettings
type SetRoutingSidebarParam = SetRoutingSidebar | Partial<RoutingSidebarSettings> | 'reset'

export function resetRoutingSidebar() {
  routingSidebarAtom.settings = { ...defaultRoutingSidebar }
}

export const setRoutingSidebar = (valueOrFunc: SetRoutingSidebarParam) => {
  if (valueOrFunc === 'reset') return resetRoutingSidebar()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(routingSidebarAtom.settings, valueOrFunc(routingSidebarAtom.settings))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      routingSidebarAtom.settings[field] = valueOrFunc[field]
    }
  }

  return routingSidebarAtom
}

// ---------------------------------------------------------------------------
// React Hooks
// ---------------------------------------------------------------------------

export function useRoutingSidebar() {
  return [useSnapshot(routingSidebarAtom).settings, setRoutingSidebar] as const
}
