import { useMemo } from 'react'

import { computeCreateGeofencePermission } from './utils/computeCreateGeofencePermission'
import { computeShareLocationPermission } from './utils/computeShareLocationPermission'

import { usePermissionsData } from './hooks/usePermissionsData'

export function usePermissions() {
  const permissionsData = usePermissionsData()

  return useMemo(
    () => ({
      createGeofence: computeCreateGeofencePermission(permissionsData),
      shareLocation: computeShareLocationPermission(permissionsData),
    }),
    [permissionsData],
  )
}
