import type { MouseEvent } from 'react'

import { Box } from '@mui/material'
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'

interface Props {
  onClick: (e: MouseEvent) => void
}

export function Adornment(props: Props) {
  const { onClick } = props

  return (
    <Box
      sx={{ cursor: 'pointer', display: 'flex', transform: 'translateX(6px)', color: 'grey.600' }}
      onClick={onClick}
    >
      <ArrowDropDownIcon />
    </Box>
  )
}
