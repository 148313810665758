import type { GeofenceFormValues } from '../types'

import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { getMapInfo } from '@/map'
import { useAppDispatch } from '@/store'
import { useIsUnmounted, useNotification } from '@/hooks'
import { selectUserConfiguration } from '@/features/domain/user'
import { createGeofence, updateGeofence } from '@/features/domain/geofence'
import { setCrudSelection, useListApi, useResetEditingState } from '@/atoms'

import { parseGeofenceFromFormValues } from '../utils/parseGeofenceFormValues'

export const useOnSubmit = () => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { scrollTo } = useListApi('geofences')
  const stopEditing = useResetEditingState()
  const userConfig = useSelector(selectUserConfiguration)
  const isUnmounted = useIsUnmounted()
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const { bounds } = getMapInfo()

  const metric = userConfig.distanceFormat === 'METRIC'

  const onCreate = useCallback(
    async (formValues: GeofenceFormValues) => {
      try {
        setSubmitting(true)

        const geofence = parseGeofenceFromFormValues(formValues, metric)
        const thunkResult = await dispatch(createGeofence({ geofence, bounds }))

        if (!isUnmounted()) {
          setSubmitting(false)
        }

        if (createGeofence.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        if (!isUnmounted()) {
          stopEditing()
        }

        setCrudSelection('geofences', [thunkResult.payload])

        scrollTo(thunkResult.payload)
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, isUnmounted, stopEditing, scrollTo, toast, bounds, metric],
  )

  const onUpdate = useCallback(
    async (formValues: GeofenceFormValues) => {
      try {
        setSubmitting(true)

        const geofence = { id: formValues.id, ...parseGeofenceFromFormValues(formValues, metric) }
        const thunkResult = await dispatch(updateGeofence({ geofence, bounds }))

        if (!isUnmounted()) {
          setSubmitting(false)
        }

        if (updateGeofence.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        if (!isUnmounted()) {
          stopEditing()
        }

        setCrudSelection('geofences', [thunkResult.payload])

        scrollTo(thunkResult.payload)
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, isUnmounted, stopEditing, scrollTo, toast, bounds, metric],
  )

  return {
    submitting,
    onCreate,
    onUpdate,
  }
}
