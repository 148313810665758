import { useState } from 'react'

import { IconButton } from '@/components/primitives/buttons'
import { Text } from '@/local/components'
import {
  DropdownMenuItem,
  DropdownTextItem,
  MenuIconTrigger,
  DropdownMenu,
} from '@/components/DropdownMenu'

import { useStyles } from '../../hooks/useStyles'
import { TriggerIcon } from './components/TriggerIcon'

import { useShowSubmenu } from './hooks/useShowSubmenu'
import { useActions } from './hooks/useActions'
import { useTexts } from './useTexts'

export function Lasso() {
  const [open, setOpen] = useState(false)
  const showSubmenu = useShowSubmenu()
  const actions = useActions(setOpen)
  const classes = useStyles()
  const texts = useTexts()

  return showSubmenu ? (
    <DropdownMenu
      open={open}
      setOpen={setOpen}
      trigger={
        <MenuIconTrigger
          className={classes.button}
          menuOpen={open}
          Icon={<TriggerIcon />}
          testid="map-controls-lasso-menu-trigger"
        />
      }
    >
      <DropdownTextItem>
        <Text color="$paleSky" size="$h4" uppercase>
          {texts.lasso}
        </Text>
      </DropdownTextItem>

      <DropdownMenuItem onClick={actions.toggleLassoMode}>{texts.newSelection}</DropdownMenuItem>
      <DropdownMenuItem onClick={actions.toggleLassoAdditiveMode}>
        {texts.addToSelection}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={actions.toggleLassoSubtractiveMode}>
        {texts.removeFromSelection}
      </DropdownMenuItem>
    </DropdownMenu>
  ) : (
    <IconButton className={classes.button} onClick={actions.toggleLassoMode}>
      <TriggerIcon />
    </IconButton>
  )
}
