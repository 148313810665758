import styled from 'styled-components'

export const RegionListItemWrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  font-size: ${p => p.theme.fonts.$p3};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 26px;
  padding: 0 13px;

  transition: background 0.3s ease;

  &:hover {
    background: ${p => p.theme.colors.$whiteSmoke};
  }
`

RegionListItemWrapper.displayName = 'RegionListItemWrapper'
