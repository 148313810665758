import { proxy, useSnapshot } from 'valtio'
import { domainProxy } from '@/store'

const initialState: uui.domain.api.Transaction = { mode: 'none' }

// ---------------------------------------------------------------------------
// Transaction state atom
// ---------------------------------------------------------------------------

export const transactionStateAtom = proxy<{ state: uui.domain.api.Transaction }>({
  state: { ...initialState },
})

// ------------------------------------
// Write functions
// ------------------------------------

export function resetTransactionState() {
  transactionStateAtom.state = { ...initialState }
}

// ---------------------------------------------------------------------------
// REACT HOOKS
// ---------------------------------------------------------------------------

export function useTransactionState() {
  return useSnapshot(transactionStateAtom).state
}

// ---------------------------------------------------------------------------
// SUBSCRIBE METHOD
// ---------------------------------------------------------------------------

export function subscribeTransactionState() {
  return domainProxy.subscribeToNotifications(
    notification => {
      if (notification.notificationType === 'transactionState') {
        Object.assign(transactionStateAtom.state, notification.payload)

        if (transactionStateAtom.state.mode === 'external' && !transactionStateAtom.state.owner) {
          transactionStateAtom.state.owner = 'anonymous'
        }
      }
    },
    ['transactionState'],
  )
}
