import type { DriverAssignmentFormValues } from '../typings'

import { useState, useCallback } from 'react'

import { updateDriverAssignments } from '@/features/domain/driverAssignments'
import { useResetEditingState } from '@/atoms'
import { useNotification, useIsUnmounted } from '@/hooks'
import { useAppDispatch } from '@/store'

import { NOT_DISPATCHED } from '../constants'

export function useOnSubmit(recurrence: uui.domain.rm.Recurrence) {
  const stopEditing = useResetEditingState()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const [submitting, setSubmitting] = useState<boolean>(false)
  const isUnmounted = useIsUnmounted()

  const onSubmit = useCallback(
    async (formValues: DriverAssignmentFormValues) => {
      try {
        setSubmitting(true)

        const assignments = formValues.assignments

        // clear all the 'NOT_DISPATCHED' values before calling the updateDriverAssignments
        for (const [vehicleId, driverId] of Object.entries(assignments)) {
          if (driverId === NOT_DISPATCHED) {
            assignments[vehicleId] = null
          }
        }

        const thunkResult = await dispatch(updateDriverAssignments({ recurrence, assignments }))

        if (!isUnmounted()) {
          setSubmitting(false)
        }

        if (!updateDriverAssignments.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, toast, recurrence, isUnmounted],
  )

  return {
    submitting,
    onSubmit,
  }
}
