import type { ListIconPreset } from '@/forms-legacy'

interface ListIconsItem {
  id: number
  preset: ListIconPreset
}

export const DeviceIcons: ListIconsItem[] = [
  { id: 101, preset: 'car' },
  { id: 102, preset: 'van' },
  { id: 103, preset: 'truck' },
  { id: 104, preset: 'pedestrian' },
  { id: 105, preset: 'scooter' },
  { id: 106, preset: 'pickup' },
  { id: 107, preset: 'siren' },
  { id: 108, preset: 'bus' },
  { id: 109, preset: 'longtruck' },
]

export function getDeviceIcons(): Record<number, ListIconsItem> {
  return DeviceIcons.reduce((acc: Record<number, ListIconsItem>, item: ListIconsItem) => {
    acc[item.id] = item
    return acc
  }, {})
}
