import type { PublicProps } from '../typings'

import { Component } from 'react'

import { AddRounded } from '@/icons'
import { ListActionButton } from '@/forms-legacy'

import SmallLabel from './SmallLabel'

type Props = PublicProps & {
  onChangeStep: () => void
  disabled?: boolean
}

export default class Collapsed extends Component<Props> {
  render() {
    const { onChangeStep, disabled, renderText } = this.props

    return (
      <>
        <ListActionButton
          icon={<AddRounded />}
          onClick={onChangeStep}
          disabled={disabled}
          testid="tags__add-button"
        >
          {renderText('addTag', this.props)}
        </ListActionButton>
        {disabled && <SmallLabel>{renderText('maxCountReached', this.props)}</SmallLabel>}
      </>
    )
  }
}
