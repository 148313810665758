import { proxy, useSnapshot, subscribe } from 'valtio'
import { updateAtomVersion } from './updateAtomVersion'

export type OrdersGridColumnDef = {
  field: string
  hide: boolean
  width: number
}
type OrdersGrid = {
  columns: OrdersGridColumnDef[]
  columnsPanelSortBy: 'grid-order' | 'category'
}
export type OrdersGridAtom = { settings: OrdersGrid; version: number }

// ------------------------------------
// Default values
// ------------------------------------
const ATOM_VERSION_NUMBER = 5

const defaultSettings = {
  columns: [
    { field: 'formattedData.name', hide: false, width: 200 },
    { field: 'formattedData.company', hide: false, width: 200 },
    { field: 'formattedData.orderId', hide: false, width: 200 },
    { field: 'formattedData.address', hide: false, width: 200 },
    { field: 'formattedData.requiredVehicle', hide: false, width: 200 },
    { field: 'formattedData.eligibility', hide: false, width: 200 },
    { field: 'formattedData.priority', hide: false, width: 200 },
    { field: 'formattedData.orderType', hide: false, width: 200 },
    { field: 'formattedData.serviceTime', hide: false, width: 200 },
    { field: 'formattedData.status', hide: false, width: 200 },
    { field: 'formattedData.assignedTo', hide: false, width: 200 },
    { field: 'formattedData.scheduleTime', hide: false, width: 200 },
    { field: 'formattedData.pairId', hide: true, width: 200 },
    { field: 'formattedData.addressCheck', hide: true, width: 200 },
    { field: 'formattedData.requiredTags', hide: true, width: 200 },
    { field: 'formattedData.bannedTags', hide: true, width: 200 },
    { field: 'formattedData.idleTime', hide: true, width: 200 },
    { field: 'formattedData.notes', hide: true, width: 200 },
    { field: 'formattedData.stopNo', hide: true, width: 200 },
    { field: 'formattedData.timeWindow1', hide: true, width: 200 },
    { field: 'formattedData.timeWindow2', hide: true, width: 200 },
    { field: 'formattedData.phone', hide: true, width: 200 },
    { field: 'formattedData.email', hide: true, width: 200 },
    { field: 'formattedData.barcodes', hide: true, width: 200 },
    { field: 'formattedData.shopifyOrderName', hide: false, width: 200 },
    { field: 'formattedData.plannedTime', hide: true, width: 200 },
    { field: 'formattedData.communicatedEtaRanges.twoDaysNotification', hide: true, width: 200 },
    { field: 'formattedData.communicatedEtaRanges.oneDayNotification', hide: true, width: 200 },
    { field: 'formattedData.communicatedEtaRanges.sameDayNotification', hide: true, width: 200 },
  ],
  columnsPanelSortBy: 'grid-order' as const,
  version: ATOM_VERSION_NUMBER,
}

// ------------------------------------
// Restore from Local Storage
// ------------------------------------

const localStorageId = 'routemanager/v1/atoms/ordersGrid'

const storedJson = localStorage.getItem(localStorageId)

// Since the atom is versioned we should update it to adapt old versions stored in local storage
const initialSettings = storedJson
  ? updateAtomVersion(JSON.parse(storedJson) as OrdersGridAtom).settings
  : defaultSettings

// ------------------------------------
// Routing layout atom
// ------------------------------------

const createDefaultOrdersGrid = (): OrdersGridAtom => ({
  settings: initialSettings,
  version: ATOM_VERSION_NUMBER,
})

export const ordersGridAtom = proxy<OrdersGridAtom>(createDefaultOrdersGrid())

// ------------------------------------
// Write to Local Storage
// ------------------------------------

subscribe(ordersGridAtom.settings, () => {
  localStorage.setItem(localStorageId, JSON.stringify(ordersGridAtom))
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetOrdersGrid = (prev: OrdersGrid) => OrdersGrid
type SetOrdersGridParam = SetOrdersGrid | Partial<OrdersGrid> | 'reset'

export function resetOrdersGrid() {
  ordersGridAtom.settings = initialSettings
}

export function setOrdersGrid(valueOrFunc: SetOrdersGridParam) {
  if (valueOrFunc === 'reset') resetOrdersGrid()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(ordersGridAtom.settings, valueOrFunc(ordersGridAtom.settings))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      ordersGridAtom.settings[field] = valueOrFunc[field]
    }
  }

  return ordersGridAtom
}

// ------------------------------------
// React Hooks
// ------------------------------------

export function useOrdersGrid() {
  return [useSnapshot(ordersGridAtom), setOrdersGrid] as const
}
