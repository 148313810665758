import type { FormFields, FormErrors } from '../formFields'

import { Stack } from '@mui/material'
import { Checkbox, TextField } from '@workwave-tidal/tidal/form-ui'

import { useTexts } from '../../hooks/useTexts'
import { useUpdatePhoneRelatedFields } from '../hooks/useUpdatePhoneRelatedFields'

export function CompanyPhone() {
  const texts = useTexts()

  useUpdatePhoneRelatedFields()

  return (
    <Stack>
      <TextField<'companyPhone', FormFields, FormErrors>
        name="companyPhone"
        testId="companyPhone"
        label={texts.companyPhone}
        validateOn="blur focus"
      />

      <Checkbox<'showContactPhone', FormFields, FormErrors>
        name="showContactPhone"
        testId="showContactPhone"
        label={texts.showContactPhone}
        validateOn="blur focus"
      />
    </Stack>
  )
}
