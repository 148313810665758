import type { MutableRefObject } from 'react'

import { TextField } from '@mui/material'

import { Adornment } from '../Adornment'

import { useInteractions } from './hooks/useInteractions'

interface Props {
  label: string
  onChange: (value: string) => void
  value: string
  testId?: string
  inputRef: MutableRefObject<HTMLElement | undefined>
}

export function MixedField(props: Props) {
  const { label, inputRef, value, testId } = props

  const { onClear, onChange, onBlur, onFocus, onMouseEnter, onMouseLeave, adornmentHidden } =
    useInteractions(value, props.onChange)

  return (
    <TextField
      inputRef={inputRef}
      label={label}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={value}
      InputLabelProps={{
        shrink: true,
      }}
      value=""
      onChange={onChange}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={testId}
      InputProps={{
        endAdornment: <Adornment onClick={onClear} hidden={adornmentHidden} />,
      }}
    />
  )
}
