import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    warningTooltip: (category: 'tagsIn' | 'tagsOut') =>
      translate({
        id:
          category === 'tagsIn'
            ? 'order.form.bulk.tags.warningTooltip.tagsIn'
            : 'order.form.bulk.tags.warningTooltip.tagsOut',
      }),
  }))

  return api
}
