import { Text } from '@/local/components'
import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'

import { useDepotsNavigator } from '../../../../hooks/useDepotsNavigator'
import { useStats } from './hooks/useStats'
import { useTexts } from './useTexts'

const growStyle = { height: 42 }

export function Stats() {
  const texts = useTexts()
  const { currentDepots } = useDepotsNavigator()
  const { setupCost, setupTime } = useStats(currentDepots)

  if (currentDepots.length === 0) return null

  return (
    <Grow xAxis style={growStyle}>
      <VerticalLayout width="auto" justifyContent="space-between">
        <HorizontalLayout height="auto">
          <Text size="$p3">{texts.setupCost}:&nbsp;</Text>

          <Text size="$p3" weight="$semiBold">
            {setupCost}
          </Text>
        </HorizontalLayout>

        <HorizontalLayout height="auto">
          <Text size="$p3">{texts.setupTime}:&nbsp;</Text>

          <Text size="$p3" weight="$semiBold">
            {setupTime}
          </Text>
        </HorizontalLayout>
      </VerticalLayout>
    </Grow>
  )
}
