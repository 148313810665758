import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../../../hooks/useController'
import { useDeleteDrivers } from '../../../hooks/useDeleteDrivers'
import { useTexts } from '../../../hooks/useTexts'

export function MultipleDrivers() {
  const {
    close,
    data: { deletableDriverIds },
  } = useController()

  const deleteDrivers = useDeleteDrivers(deletableDriverIds)
  const texts = useTexts()

  const gotDeletableDrivers = deletableDriverIds.length > 0

  return (
    <ModalFooter
      primaryAction={
        gotDeletableDrivers && (
          <ModalFooterButton
            color="error"
            testid="modal-delete-drivers-delete"
            onClick={deleteDrivers}
            variant="contained"
          >
            {texts.deleteRemainingDrivers(deletableDriverIds.length)}
          </ModalFooterButton>
        )
      }
      secondaryAction={
        <ModalFooterButton
          testid="modal-delete-drivers-cancel"
          onClick={close}
          variant={gotDeletableDrivers ? 'text' : 'contained'}
        >
          {gotDeletableDrivers ? texts.cancel : texts.comeBackLater}
        </ModalFooterButton>
      }
    />
  )
}
