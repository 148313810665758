import type { RegionFormValues } from './typings'

import { geo } from '@/server-data'
import { intl } from '@/intl'

const emptyRegion: RegionFormValues = {
  id: '',
  name: '',
  color: '23114F',
  enterCost: 0,
  enterTimeSec: 0,
  points: [],
}

export const computeFormInitialValues = (
  regions: Record<string, uui.domain.client.rm.Region>,
  source?: uui.domain.client.rm.Region,
): RegionFormValues => {
  if (!source) {
    const newRegionText: string = intl.translate({
      id: 'regions.form.edit.newRegionName',
    })

    const regionNumber = getNextRegionNumber(newRegionText, regions)

    return { ...emptyRegion, name: `${newRegionText} ${regionNumber}` }
  }

  return {
    id: source.id,
    name: source.name,
    color: source.color,
    enterCost: source.enterCost,
    enterTimeSec: source.enterTimeSec,
    points: source.points,
  }
}

export const parseRegionFromFormValues = (
  formValues: RegionFormValues,
): uui.domain.client.rm.Region => ({
  id: formValues.id,
  name: formValues.name,
  color: formValues.color,
  enterCost: formValues.enterCost > 0 ? formValues.enterCost * 100 : 0,
  enterTimeSec: formValues.enterTimeSec > 0 ? formValues.enterTimeSec : 0,
  points: formValues.points,
  poly: formValues.points.map(latLng => geo.latLngToJSON(latLng)),
})

const getNextRegionNumber = (
  prefix: string,
  regions: Record<string, uui.domain.client.rm.Region>,
): number => {
  let nextRegionNumber = 1

  for (const id in regions) {
    const { name } = regions[id]

    if (!name.startsWith(prefix)) continue

    const lastRegionNumber = parseInt(name.replace(prefix, '').trim())

    if (!isNaN(lastRegionNumber) && lastRegionNumber >= nextRegionNumber) {
      nextRegionNumber = lastRegionNumber + 1
    }
  }

  return nextRegionNumber
}
