import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectGpsTrackingProvider } from '@/features/domain/user'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectDrivers } from '@/features/domain/driver'
import * as uvHelpers from '@/local/server-data/domain/helpers/unifiedVehicle'

import { getColorValue } from '@/styles'

export function useDeviceInfo(vehicle: uui.domain.client.UnifiedVehicle) {
  const drivers = useSelector(selectDrivers)
  const calendarRange = useSelector(selectCalendarRange)
  const trackingProvider = useSelector(selectGpsTrackingProvider)

  return useMemo(() => {
    // when this hooks is called the calendar range will be guaranteed to be on a single day

    const color = getColorValue(
      uvHelpers.getPrimaryColor(drivers, vehicle, trackingProvider, calendarRange.minDate),
    )
    const label = uvHelpers.getLabel(drivers, vehicle, calendarRange.minDate)

    const deviceLabel =
      vehicle.hasRoutingLicense && vehicle.isPhysicalDevice && vehicle.trackingProvider === 'WWGPS'
        ? vehicle.device.label
        : ''

    return {
      color,
      label,
      deviceLabel,
    }
  }, [vehicle, drivers, trackingProvider, calendarRange])
}
