import { useCallback } from 'react'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { clsx } from '@/utils'
import { Link, useGpsReportsLink } from '@/routing'

import { getSelectedDeviceIds } from '../../../utils/getSelectedDeviceIds'
import { useTexts } from '../../../useTexts'
import { HeaderLink } from './HeaderLink'

type MainSections = uui.routing.MainSections

const useStyles = makeStyles(muiTheme => ({
  fullHeight: {
    height: '100%',
  },
  regularFontWeight: {
    fontWeight: muiTheme.typography.fontWeightRegular,
  },
}))

export function GpsOnlyLinks() {
  const texts = useTexts()
  const classes = useStyles()
  const { gpsReportsLink, createGpsReportsCookie } = useGpsReportsLink()

  const onGpsReportClick = useCallback(() => {
    createGpsReportsCookie(getSelectedDeviceIds())
  }, [createGpsReportsCookie])

  return (
    <>
      <Link<MainSections>
        to="tracking"
        data-testid="header__nav-tracking-button-link"
        className={clsx(classes.fullHeight, 'pendo-bluebar__tracking-list-menu')}
      >
        <HeaderLink
          data-testid="header__nav-tracking-button-content"
          data-trackid="gpsonly-header__nav-tracking-button-content"
        >
          {texts.tracking}
        </HeaderLink>
      </Link>

      <Button
        href={gpsReportsLink}
        target="_blank"
        onClick={onGpsReportClick}
        data-testid="header__nav-reports-button-link"
        className={clsx(
          classes.fullHeight,
          classes.regularFontWeight,
          `pendo-bluebar__GPS-reports-menu`,
        )}
      >
        <HeaderLink
          data-testid="header__nav-reports-button-content"
          data-trackid="gpsonly-header__nav-reports-button-content"
        >
          {texts.reports}
        </HeaderLink>
      </Button>
    </>
  )
}
