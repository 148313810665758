import type { KeyboardEvent } from 'react'
import type { DropDownItem, WithIndexReference } from '../../typings'
import type { Props } from './typings'

import { getNearestValidItemIndex, isValidOption } from '../../utils'
import { letters } from './letters'

function activeAndScrollToIndex(
  { options, setActiveIndex }: Props,
  scrollToElement: (el: HTMLElement) => void,
  index: number,
) {
  if (index !== -1) {
    const targetId = options[index] ? options[index].id : ''
    const targetElem = document.getElementById(targetId)

    if (targetElem) {
      scrollToElement(targetElem)
    }

    setActiveIndex(index)
  }
}

function getDistance(item: DropDownItem, query: string): number {
  const { label } = item

  const distance = label.toLowerCase().charCodeAt(0) - query.toLowerCase().charCodeAt(0)

  if (isNaN(distance)) {
    return Number.MAX_SAFE_INTEGER
  }

  return distance
}

type WithDistance<T> = T & {
  distance: number
}

type WithDistanceDropDownItem = WithDistance<WithIndexReference<DropDownItem>>

interface Nearest {
  up: WithDistanceDropDownItem | undefined
  down: WithDistanceDropDownItem | undefined
}

export function getNearest(distances: WithDistanceDropDownItem[]) {
  let nearest: Nearest = {
    up: undefined,
    down: undefined,
  }

  for (let i: number = 0; i < distances.length; i++) {
    const item = distances[i]

    const perfectMatch = item.distance === 0
    if (perfectMatch) {
      nearest = {
        up: undefined,
        down: item,
      }
      break
    }

    if (item.distance < 0) {
      if (!nearest.up || Math.abs(item.distance) < Math.abs(nearest.up.distance)) {
        nearest.up = item
      }
    } else {
      if (!nearest.down || item.distance < nearest.down.distance) {
        nearest.down = item
      }
    }
  }

  return nearest
}

function getBestItem({ up, down }: Nearest): WithDistanceDropDownItem | undefined {
  if (!up && !down) return

  if (up && down) {
    if (up.distance > 0 && up.distance < down.distance) return up
    return down
  }

  if (up) return up

  return down
}

export const getOnHandleOnKeyDown =
  (getProps: () => Props, scrollToElement: (el: HTMLElement) => void) => (event: KeyboardEvent) => {
    // getProps return the props always updated
    const props = getProps()
    const { closeList, activeIndex, options, onChange, sortedList } = props

    let hasToPreventDefault: boolean = false

    // calculate the distance between the first char of the label and the event.key
    // ideal result is distance = 0
    // up represent the item with lower distance before the ideal result
    // down represent the item with lower distance after the ideal result

    if (
      letters.includes(event.key) &&
      // the keyboard (ex. CTRL+R) combinations must not be intercepted as standard letters. Otherwise,
      // the default behavior of the browser (ex. reloading the page in case of CTRL+R) would be prevented.
      !event.metaKey &&
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey
    ) {
      hasToPreventDefault = true
      const distances = sortedList.map(i => {
        return {
          ...i,
          distance: getDistance(i, event.key),
        }
      })

      const item = getBestItem(getNearest(distances))

      if (item) {
        activeAndScrollToIndex(props, scrollToElement, item.indexRef)
      }
    }

    switch (event.key) {
      case 'Escape': {
        hasToPreventDefault = true
        closeList()
        break
      }

      case 'ArrowDown':
      case 'ArrowUp': {
        hasToPreventDefault = true
        const forward = event.key === 'ArrowDown'
        const nextIndex = getNearestValidItemIndex(options, activeIndex, forward)

        activeAndScrollToIndex(props, scrollToElement, nextIndex)
        break
      }

      case 'Enter': {
        hasToPreventDefault = true
        const option = options[activeIndex]
        if (option && isValidOption(option)) {
          const preventClose = onChange(option as DropDownItem, activeIndex, options, event)
          if (!preventClose) {
            closeList()
          }
        }
        break
      }
    }

    if (hasToPreventDefault) {
      event.preventDefault()
    }
  }
