import styled from 'styled-components'

interface Props extends workwave.Div {
  size?: number
}

const getSize =
  () =>
  ({ size = 16 }: Props) =>
    `${size}px`

const div = styled.div<Props>``
const styledComponent = styled(div).attrs<Props>(() => ({
  className: 'o-molecule-checkbox-container',
}))`
  position: relative;
  width: ${getSize};
  height: ${getSize};
`

styledComponent.displayName = 'CheckboxContainer'
export default styledComponent
