import type { CoreItem, CoreItemRendererProps, LayoutRendererProps, Modes } from './typings'
import type { FallbackProps } from '@/components/ErrorBoundary'

import { ComponentType, Component } from 'react'

import { clsx } from '@/utils'
import { ErrorBoundary } from '@/components/ErrorBoundary'

import NavContainer from './components/NavContainer'

export interface Props<T extends CoreItem, E, K> {
  items: T[]
  ItemRenderer: ComponentType<CoreItemRendererProps<T, K>>
  selectedIndex?: number
  onChangeIndex: (nextIndex: number) => void
  onItemClick?: (index: number) => void
  mode?: Modes
  LayoutRenderer: ComponentType<LayoutRendererProps<T, E, K>>
  extraItemRendererProps: K
  extraLayoutRendererProps: E
  disabled?: boolean
  className?: string
}

const DEFAULT_SELECTED_INDEX: number = -1

const ErrorFallback = (props: FallbackProps) => (
  <div style={{ color: 'red' }}>
    OPS!
    <button onClick={props.resetErrorBoundary}>Re try</button>
  </div>
)

export class Navigator<T extends CoreItem, E, K> extends Component<Props<T, E, K>> {
  render() {
    const {
      items,
      selectedIndex = DEFAULT_SELECTED_INDEX,
      ItemRenderer,
      mode = 'collapsed',
      LayoutRenderer,
      disabled = false,
      className = '',
      onChangeIndex,
      extraLayoutRendererProps,
      extraItemRendererProps,
    } = this.props

    const rootClassName = clsx({
      [className]: true,
      'o-navigator-root': true,
      'is-disabled': disabled,
    })

    const rendererProps: LayoutRendererProps<T, E, K> = {
      items,
      ItemRenderer,
      selectedIndex,
      onChangeIndex,
      mode,
      extraLayoutRendererProps,
      extraItemRendererProps,
    }

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <NavContainer className={rootClassName}>
          <LayoutRenderer {...rendererProps} />
        </NavContainer>
      </ErrorBoundary>
    )
  }
}
