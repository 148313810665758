import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import type Geometry from 'ol/geom/Geometry'

import Modify, { ModifyEvent } from 'ol/interaction/Modify'
import Feature from 'ol/Feature'
import { ObjectEvent } from 'ol/Object'
import MapBrowserEvent from 'ol/MapBrowserEvent'

import Polygon from 'ol/geom/Polygon'
import { Coordinate } from 'ol/coordinate'

import { gis } from '@/server-data'

import { computePosition, computePositions } from './utils/computePositions'
import { areSegmentsValid } from './utils/areSegmentsValid'
import { getFeatureCoordinates } from './utils/getFeatureCoordinates'
import { createModifyStyle } from './ModifyStyle/createModifyStyle'
import { createModifyPointStyle } from './ModifyStyle/createModifyPointStyle'
import { createModifyCondition } from './utils/createModifyCondition'
import { linesIntersect } from './utils/linesIntersect'
import { drawFeatureMetadata } from './utils/drawFeatureMetadata'

type GeometryStatus =
  // The Area is valid and can be saved/closed
  | 'valid'
  // The Area is invalid and cannot be saved/closed
  | 'invalid'

// Area Change unsubscribe function
type Unsubscriber = () => void

// Area Change subscribe function
type OnAreaChangeSubscriber = (
  points: uui.domain.LatLng[],
  feature: Feature<Geometry>,
  lastChanged: number,
) => void

interface Options {
  color: string
}

/**
 * TODO: Main description
 */
export class ModifyEditableAreaInteraction extends Modify {
  constructor(protected drawLayer: VectorLayer<VectorSource>, { color }: Options) {
    super({
      style: createModifyPointStyle(),
      // inherit the Vector Source from the provided draw layer
      source: drawLayer.getSource() ?? undefined,
      // the create condition is also used to determine if there's a selected vertex or not
      condition: createModifyCondition(() => this),
    })

    // set the drawing layer main color
    this.updateColor(color)

    // listen to change toi the Active state
    this.on('change:active', this.handleChangeActiveState)
  }

  // ---------------------------------------------------------------
  // ADDITIONAL API
  // ---------------------------------------------------------------

  /**
   * Change the color of the Area.
   *
   * @param color Hexadecimal or RGB string of the color the Shape must have.
   */
  public updateColor(color: string) {
    this.drawLayer.setStyle(createModifyStyle(color))
  }

  /**
   * Subscribe to the Changes to an Area.
   *
   * @param subscriber The function that will be invoked when an Area is changedd
   * @returns The function to unsubscribe
   */
  public onAreaChange(subscriber: OnAreaChangeSubscriber): Unsubscriber {
    this.onAreaChangeSubscribers.add(subscriber)

    return () => {
      this.onAreaChangeSubscribers.delete(subscriber)
    }
  }

  // ---------------------------------------------------------------
  // API exposed only for internal usage
  // ---------------------------------------------------------------

  /**
   * Receive coordinates of a vertex and reference to a Feature.
   * It allow the Interaction to be aware of the user clicking a vertex or a segment.
   *
   * @param coords Pointer coordinates using the Open Layers coordinate system
   * @param targetFeature Feature under the mouse
   */
  public internal_setActiveVertex(coords?: Coordinate, targetFeature?: Feature<Geometry>) {
    if (
      this.activeSelectedCoords?.at(0) === coords?.at(0) &&
      this.activeSelectedCoords?.at(1) === coords?.at(1)
    ) {
      return
    }

    // store the active coordinates or clear them if no coordinates are provided
    this.setActiveSelectedCoords(coords, undefined)

    if (!targetFeature) return

    if (!coords) {
      // if a Feature is provided without coordinates remove any custom field named 'activeIndex'
      drawFeatureMetadata.removeActiveIndex(targetFeature)
      return
    }

    const geometry = targetFeature.getGeometry()

    // set or unset the selected vertex
    // the addition or removal of points is managed by `refreshGeometry`
    if (geometry?.getType() === 'Polygon') {
      const poly = geometry as Polygon

      // search the coordinates in the polygon
      const coordsIndex =
        poly
          .getCoordinates()
          .at(0)
          ?.findIndex(([x, y]) => {
            return x === coords.at(0) && y === coords.at(1)
          }) ?? -1

      if (coordsIndex > -1) {
        // the user is clicking a vertex
        drawFeatureMetadata.setActiveIndex(targetFeature, coordsIndex)
        this.setActiveSelectedCoords(coords, coordsIndex)
      } else {
        // the user is clicking a segment
        drawFeatureMetadata.removeActiveIndex(targetFeature)
      }
    }
  }

  // ---------------------------------------------------------------
  // Internal Properties
  // ---------------------------------------------------------------

  /**
   * Reference to the Feature actively used by the Modify Interaction
   */
  protected activeFeature: Feature<Geometry> | undefined

  /**
   * Last valid Geometry of the modified Area.
   * It well be used to restore the Area if the changes produce an invalid Geometry.
   */
  protected activeFeaturePrevGeometry: Geometry | undefined

  /**
   * Array of LatLng points matching the active Feature coordinates.
   */
  protected activeGeometryPoints: uui.domain.Point[] = []

  /**
   * Validation status for the Geometry being drawn
   */
  protected activeGeometryStatus: GeometryStatus = 'valid'

  /**
   * Reference to the active vertex coordinates (both for moved or added points)
   */
  protected activeSelectedCoords: Coordinate | undefined

  /**
   * Reference to the active vertex index (both for moved or added points)
   */
  protected activeSelectedIndex: number | undefined

  /**
   * Flag used to track when the interaction is in REMOVE mode for the active vertex
   */
  protected removeModifier: boolean = false

  /**
   * Collection of subscribers for the `Area Change` Event.
   */
  protected onAreaChangeSubscribers = new Set<OnAreaChangeSubscriber>()

  // ---------------------------------------------------------------
  // INTERNAL EVENT HANDLERS
  // ---------------------------------------------------------------

  /**
   * Event handler reacting to the `active` status of the Interaction.
   */
  protected handleChangeActiveState = () => {
    if (this.getActive()) {
      this.enableKeyboardEvents()

      this.on('modifystart', this.handleModifyStart)
      this.on('modifyend', this.handleModifyEnd)

      this.getMap()?.on('pointermove', this.handlePointerMove)
    } else {
      this.disableKeyboardEvents()

      this.un('modifystart', this.handleModifyStart)
      this.un('modifyend', this.handleModifyEnd)
      this.getMap()?.un('pointermove', this.handlePointerMove)

      drawFeatureMetadata.removeActiveIndex(this.activeFeature)
      drawFeatureMetadata.setInvalid(this.activeFeature, false)
      this.activeFeature?.un('change', this.handleOnChangeGeometry)
      this.activeFeature = undefined
      this.activeGeometryStatus = 'valid'
      this.setActiveSelectedCoords(undefined, undefined)
    }

    this.updateCursor()
  }

  /**
   * Event handler reacting when the target Area change.
   * It's responsible to prepare the created Geometry and its target Feature before notifying
   * the subscribers to the change to the target Area.
   */
  protected handleOnAreaChange(_event: ModifyEvent, feature: Feature<Geometry>) {
    // timestamp linked to the change event
    const lastChanged = Date.now()

    // store the last changed timestamp into the feature as a custom field
    drawFeatureMetadata.setLastChanged(feature, lastChanged)

    // Open Layers Polygons keep a redundant start/end point.
    // remove duplicate final point
    const coords = getFeatureCoordinates(feature)
    const points = coords.map(gis.fromCoordinateToLatLng)
    points.pop()

    // notify subscribers the feature Geometry changed
    this.onAreaChangeSubscribers.forEach(sub => sub(points, feature, lastChanged))
  }

  /**
   * Event handler reacting to the start of an edit session.
   */
  protected handleModifyStart = (event: ModifyEvent) => {
    // disable `pointer move` subscription during an active edit session
    event.mapBrowserEvent.map.un('pointermove', this.handlePointerMove)

    // clear CSS classes not needed during an edit session
    event.mapBrowserEvent.map.getViewport()?.classList.remove('ol-pointer-modify-remove-point')
    event.mapBrowserEvent.map.getViewport()?.classList.remove('ol-pointer-modify-add-point')

    // if no features are in the event, just stop
    if (event.features.getLength() === 0) return

    // set the CSS to a MOVE vertex cursor
    event.mapBrowserEvent.map.getViewport()?.classList.add('ol-pointer-modify-move-point')

    // We know the feature will always allow to edit a SINGLE feature at the time
    // fetch the first and oly feature
    const featureLike = event.features.item(0)

    if (featureLike instanceof Feature) {
      // store reference to the Feature
      this.activeFeature = featureLike

      // store a copy of the original Feature's Geometry
      this.activeFeaturePrevGeometry = featureLike.getGeometry()?.clone()

      // subscribe to change event from the active Feature
      this.activeFeature.on('change', this.handleOnChangeGeometry)
    }
  }

  /**
   * Event handler reacting to the end of an edit session.
   */
  protected handleModifyEnd = (event: ModifyEvent) => {
    // clear CSS classes used during an edit session
    event.mapBrowserEvent.map.getViewport()?.classList.remove('ol-pointer-modify-move-point')

    if (this.activeFeature && this.activeFeaturePrevGeometry) {
      if (this.activeGeometryStatus === 'invalid') {
        // If the final Geometry is marked as invalid
        // The interaction rewrite the Feature Geometry using the last valid stored Geometry
        // No event will be emitted
        this.activeFeature.setGeometry(this.activeFeaturePrevGeometry)
      } else {
        // If the final Geometry is marked as valid
        // the interaction will notify the subscribers
        this.handleOnAreaChange(event, this.activeFeature)
      }
    }

    this.resetInteraction()

    // restore IDLE subscription to pointer move events
    event.mapBrowserEvent.map.on('pointermove', this.handlePointerMove)
  }

  /**
   * Event handler reacting to any change in the Geometry in editing
   */
  protected handleOnChangeGeometry = (event: ObjectEvent) => {
    // process the updated Feature's geometry
    this.refreshGeometry(event.target as Feature<Geometry>)
  }

  /**
   * Event handler reacting to Pointer Move events
   * This event handler is active only when the editing is on IDLE
   */
  protected handlePointerMove = (event: MapBrowserEvent<PointerEvent>) => {
    // it shouldn't be possible but do nothing if the mouse is dragging
    if (event.dragging) return

    // get all the features under the mouse coordinates on the drawing layer
    const features = event.map.getFeaturesAtPixel(event.pixel, {
      hitTolerance: 10,
      // TODO: Should we make the UID dynamic as an option?
      layerFilter: layer => layer.get('uid') === 'draw',
    })

    // search for a feature with the `editable-area` custom field
    const feature = features.find(feat =>
      feat instanceof Feature ? drawFeatureMetadata.isEditableArea(feat) : false,
    )

    if (feature) {
      // extract the target feature coordinates
      const coords = getFeatureCoordinates(feature)
      const [eventX, eventY] = event.coordinate

      // loop over the feature coordinates
      // trying to identify if the pointer is over a feature's vertex
      for (const [pX, pY] of coords) {
        const dX = Math.abs(pX - eventX)
        const dY = Math.abs(pY - eventY)

        // tolerance is based on Open Layers internal coordinates system
        const tolerance = 1300
        if (dX < tolerance && dY < tolerance) {
          // the pointer is over a vertex
          event.map.getViewport()?.classList.remove('ol-pointer-modify-add-point')

          if (!event.map.getViewport()?.classList.contains('ol-pointer-modify-move-point')) {
            event.map.getViewport()?.classList.add('ol-pointer-modify-move-point')
          }
          return
        }
      }

      // if the pointer is not over a vertex try to check if is over a segment of the feature's geometry
      const points = computePositions(event.map, coords)
      const eventPoint = computePosition(event.map, event.coordinate)

      // try searching for the cursor intersecting one of the geometry's segments
      for (let index = 0; index < points.length - 1; index++) {
        const { x: x1, y: y1 } = points[index]
        const { x: x2, y: y2 } = points[index + 1]

        // tolerance to match the intersection
        const intersectionThreshold = 22

        // to test the intersection the mouse coordinates are used to create a fake segment.
        // this approach is quite limited and it will not work well for some segments, depending on their rotation.
        const intersectPrev = linesIntersect(
          x1,
          y1,
          x2,
          y2,
          eventPoint.x - intersectionThreshold,
          eventPoint.y - intersectionThreshold,
          eventPoint.x + intersectionThreshold,
          eventPoint.y + intersectionThreshold,
        )

        const intersect = intersectPrev.intersection

        // ATTENTION: alternative method of searching for the cursor being over one of the Geometry's segments
        // scale down the polygon to allow more sensitivity when hovering the borders
        // const poly = (feature.getGeometry() as Polygon | undefined)?.clone()
        // poly?.scale(0.92)
        // const intersect = !poly?.intersectsCoordinate(event.coordinate)

        // is over a segment
        if (intersect) {
          event.map.getViewport()?.classList.remove('ol-pointer-modify-move-point')

          if (!event.map.getViewport()?.classList.contains('ol-pointer-modify-add-point')) {
            event.map.getViewport()?.classList.add('ol-pointer-modify-add-point')
          }

          if (
            this.removeModifier &&
            !event.map.getViewport()?.classList.contains('ol-pointer-modify-remove-point')
          ) {
            event.map.getViewport()?.classList.add('ol-pointer-modify-remove-point')
          }

          return
        }
      }
    }

    // if the pointer is not over any vertex or segment clear all the CSS classes changing the mouse cursor
    event.map.getViewport()?.classList.remove('ol-pointer-modify-remove-point')
    event.map.getViewport()?.classList.remove('ol-pointer-modify-add-point')
    event.map.getViewport()?.classList.remove('ol-pointer-modify-move-point')
  }

  // ---------------------------------------------------------------
  // GEOMETRY MAINTENANCE
  // ---------------------------------------------------------------

  /**
   * Reset the internal state of the interaction
   */
  protected resetInteraction = () => {
    // set the 'invalid' custom field to FALSE
    drawFeatureMetadata.setInvalid(this.activeFeature, false)

    // unregister the subscription to the Geometry changes
    this.activeFeature?.un('change', this.handleOnChangeGeometry)
    // empty the pointer to the active feature
    this.activeFeature = undefined
    // set the geometry validity to VALID
    this.activeGeometryStatus = 'valid'

    this.removeModifier = false

    this.updateCursor()
  }

  /**
   * Store the provided coordinates and index for the active vertex
   */
  protected setActiveSelectedCoords = (coords?: Coordinate, index?: number) => {
    this.activeSelectedCoords = coords
    this.activeSelectedIndex = index
  }

  /**
   * During an editing session every time the Feature's geometry change we need to
   * refresh the parsed and stored information and validate the new Geometry.
   */
  protected refreshGeometry = (feature: Feature<Geometry>) => {
    // try to extract the new geometry's coordinates
    const coords = getFeatureCoordinates(feature)
    if (coords.length === 0) return

    const map = this.getMap()
    if (!map) return

    // ------------------------------------
    // Set or unset the selected vertex when a vertex is added or removed
    if (this.activeFeaturePrevGeometry?.getType() === 'Polygon') {
      const prevCoordinates =
        (this.activeFeaturePrevGeometry as Polygon).getCoordinates().at(0) ?? []

      // ------------------------------------
      // A point has been removed
      if (prevCoordinates.length > coords.length) {
        // remove the stored `activeIndex` custom field
        drawFeatureMetadata.removeActiveIndex(feature)
        // remove the stored vertex coordinates and geometry's index
        this.setActiveSelectedCoords(undefined, undefined)
      }

      // ------------------------------------
      // A point has been added
      if (prevCoordinates.length < coords.length) {
        // search for the vertex coordinates in the geometry
        const coordsIndex =
          coords.findIndex(([x, y], idx) => {
            return x !== prevCoordinates.at(idx)?.at(0) || y !== prevCoordinates.at(idx)?.at(1)
          }) ?? -1

        if (coordsIndex > -1) {
          // if the index is valid store it in the feature as a custom field,
          // it will be used by the Style Functions
          drawFeatureMetadata.setActiveIndex(feature, coordsIndex)
          // store the vertex coordinates and geometry's index
          this.setActiveSelectedCoords(coords.at(coordsIndex), coordsIndex)
        } else {
          // remove the stored `activeIndex` custom field
          drawFeatureMetadata.removeActiveIndex(feature)
          // remove the stored vertex coordinates and geometry's index
          this.setActiveSelectedCoords(undefined, undefined)
        }
      }
    } else {
      // remove the stored `activeIndex` custom field
      drawFeatureMetadata.removeActiveIndex(feature)
      // remove the stored vertex coordinates and geometry's index
      this.setActiveSelectedCoords(undefined, undefined)
    }

    // recompute the geometry's points in the RM LatLng coordinate system
    this.activeGeometryPoints = computePositions(map, coords)

    // validate the new geometry
    this.activeGeometryStatus = this.validateGeometry(this.activeGeometryPoints)

    // store the geometry validity in the feature as custom field.
    // it will be used by the Style Functions
    drawFeatureMetadata.setInvalid(this.activeFeature, this.activeGeometryStatus === 'invalid')

    this.updateCursor()
  }

  protected validateGeometry = (points: uui.domain.Point[]): GeometryStatus => {
    if (points.length < 3) return 'invalid'
    //TODO: Add validation to ensure that the point are not overlapping

    // do not validate if there's no selected vertex
    if (this.activeSelectedIndex === undefined || this.activeSelectedIndex < 0) return 'valid'

    const prevSketchPoint =
      this.activeSelectedIndex === 0
        ? points.at(this.activeSelectedIndex - 2)!
        : points.at(this.activeSelectedIndex - 1)!
    const sketchPoint = points.at(this.activeSelectedIndex)!
    const nextSketchPoint = points.at(this.activeSelectedIndex + 1) ?? points.at(1)!
    const shapeIsValid = areSegmentsValid(sketchPoint, prevSketchPoint, nextSketchPoint, points)

    return shapeIsValid ? 'valid' : 'invalid'
  }

  // ---------------------------------------------------------------
  // Active Map Cursor
  // ---------------------------------------------------------------

  protected updateCursor() {
    const view = this.getMap()?.getViewport()
    if (!view) return

    // always reset
    view.classList.remove('ol-pointer-draw-invalid')

    if (!this.getActive()) return

    if (this.activeGeometryStatus === 'invalid') {
      view.classList.add('ol-pointer-draw-invalid')
      return
    }
  }

  // ---------------------------------------------------------------
  // KEYBOARD EVENTS
  // ---------------------------------------------------------------

  /**
   * Activate the required Keyboard event listeners
   */
  protected enableKeyboardEvents() {
    window.addEventListener('keydown', this.handleKeydown)
    window.addEventListener('keyup', this.handleKeyup)
  }

  /**
   * Remove the active Keyboard event listeners
   */
  protected disableKeyboardEvents() {
    window.removeEventListener('keydown', this.handleKeydown)
    window.removeEventListener('keyup', this.handleKeyup)
  }

  /**
   * Key-up events listener
   */
  protected handleKeyup = (_event: KeyboardEvent) => {
    // clear the optional `removeModifier` set when the ALT key modifier is pressed
    this.removeModifier = false
    this.getMap()?.getViewport()?.classList.remove('ol-pointer-modify-remove-point')
  }

  /**
   * Key-down events listener
   */
  protected handleKeydown = (event: KeyboardEvent) => {
    // store the ALT key modifier pressed status
    this.removeModifier = event.altKey

    if (
      this.removeModifier &&
      !this.getMap()?.getViewport()?.classList.contains('ol-pointer-modify-move-point') &&
      !this.getMap()?.getViewport()?.classList.contains('ol-pointer-modify-remove-point')
    ) {
      this.getMap()?.getViewport()?.classList.add('ol-pointer-modify-remove-point')
    }

    // delete last added point
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Cancel') {
      if (this.activeSelectedIndex !== undefined) {
        event.stopPropagation()
        this.removePoint()
      }
    }
  }

  // ---------------------------------------------------------------
  // OVERRIDDEN API
  // ---------------------------------------------------------------

  /**
   * Clear all the internal subscription and pointers when the Interaction is disposed.
   */
  override dispose() {
    this.disableKeyboardEvents()

    const view = this.getMap()?.getViewport()
    view?.classList.remove('ol-pointer-draw-valid')
    view?.classList.remove('ol-pointer-draw-invalid')
    view?.classList.remove('ol-pointer-draw-close-shape')

    this.un('change:active', this.handleChangeActiveState)

    this.onAreaChangeSubscribers.clear()

    this.resetInteraction()

    super.dispose()
  }
}
