import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { MapControls } from '@/components/MapControls'

import { DepotsRoot } from './components/form/DepotsRoot'
import { Map } from './components/Map'

export function Depots() {
  return (
    <HorizontalLayout data-testid="setup-depots-root">
      <DepotsRoot />

      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />
          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
