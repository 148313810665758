import type { LocationEditorProps } from '../typings'

export function useFormFieldLocation(options: LocationEditorProps) {
  const {
    formProps: {
      input: { value: formLocation },
    },
  } = options

  return formLocation
}
