import { Stack } from '@mui/material'

import { Text } from '@/local/components'
import { theme } from '@/styles'

import { Controls } from './components/Controls'
import { useTexts } from './useTexts'

interface Props {
  enableInspectPin?: boolean
  canFilterOrders?: boolean
}

const containerStyle = { backgroundColor: theme.colors.$alabaster }

export function MapControls(props: Props) {
  const texts = useTexts()

  const { enableInspectPin = false, canFilterOrders = false } = props

  return (
    <Stack
      sx={containerStyle}
      width="100%"
      paddingY={2}
      paddingX={3}
      maxHeight={60}
      direction="row"
      alignItems="center"
      data-testid="Map-controls"
      justifyContent="space-between"
    >
      <Text size="$h1" weight="$bold">
        {texts.map}
      </Text>
      <Controls disableInspectPin={!enableInspectPin} canFilterOrders={canFilterOrders} />
    </Stack>
  )
}
