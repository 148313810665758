import type { DayToDispatch } from '../../../typings'

import { format } from 'date-fns/esm'

import { Button } from '@/local/components'
import { PaperPlane } from '@/icons'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { useTexts } from '../useTexts/useTexts'
import { useActions } from '../useActions'

interface Props {
  day: DayToDispatch
}

export function PartialDispatchButton(props: Props) {
  const { canBeUpdated, date, isToday, routesCount, dispatchedRoutesCount, dispatched } = props.day

  const texts = useTexts()
  const { approveDate } = useActions()

  const title = texts.resetButtonTitle(routesCount, dispatchedRoutesCount, canBeUpdated, dispatched)
  const disabled = !canBeUpdated || !dispatched
  const className = isToday
    ? 'pendo-dispatch-routes_partial-dispatch_today-button'
    : 'pendo-dispatch-routes_partial-dispatch_not-today-button'

  const testid = isToday
    ? 'dispatch-routes_partial-dispatch_today-button'
    : 'dispatch-routes_partial-dispatch_not-today-button'

  return (
    <ReadOnlyTooltip
      canEdit={!disabled}
      title={title}
      flex="1 1 auto"
      render={preventEditing => (
        <Button
          disabled={preventEditing}
          backgroundTint="$whiteSmoke"
          preset="confirm"
          onClick={approveDate}
          title={title}
          canFocus={false}
          data-date={format(date, 'yyyyMMdd')}
          style={{ marginRight: 20 }}
          className={className}
          data-testid={testid}
          data-trackid={testid}
        >
          <PaperPlane size={12} marginRight={6} />
          {texts.dispatch}
        </Button>
      )}
    />
  )
}
