import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Lock(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 14 19">
        <path d="M6.846.6c-1.53 0-2.853.636-3.678 1.666C2.343 3.296 2 4.632 2 6.074v2.141h2.077V6.074c0-1.093.27-1.953.714-2.51.443-.557 1.046-.887 2.055-.887 1.012 0 1.612.31 2.055.865.444.555.714 1.425.714 2.532V8.83h2.077V6.074c0-1.45-.365-2.8-1.19-3.83C9.678 1.214 8.374.6 6.846.6zM2.231 7.523c-1.147 0-2.077.93-2.077 2.077v6.923c0 1.147.93 2.077 2.077 2.077h9.692c1.147 0 2.077-.93 2.077-2.077V9.6c0-1.147-.93-2.077-2.077-2.077H2.231zm4.846 3.462c.765 0 1.385.619 1.385 1.384 0 .511-.279.95-.693 1.19v1.58a.694.694 0 0 1-.692.692.694.694 0 0 1-.692-.693V13.56a1.372 1.372 0 0 1-.693-1.19c0-.765.62-1.384 1.385-1.384z" />
      </svg>
    </IconContainer>
  )
}
