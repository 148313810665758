import { PureComponent } from 'react'

import { Block, Label, FieldMessage, RestoreFieldButton } from '@/forms-legacy'

import { Props } from './typings'

export default class PodListField extends PureComponent<Props> {
  private handleOnDelete = (uuid: string) => {
    const {
      formProps: {
        input: { onChange, value },
      },
    } = this.props

    const updatedValues: uui.domain.client.rm.Pod[] = value.filter(
      ({ uuid: podId }: uui.domain.client.rm.Pod) => podId !== uuid,
    )

    onChange(updatedValues)
  }

  render() {
    const {
      label,
      name,
      info,
      renderItems,
      formProps: {
        input: { onChange, value },
        meta: { error, dirty, initial },
      },
    } = this.props

    return (
      <Block error={!!error} dirty={!!dirty}>
        <Label htmlFor={name}>
          {label}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        {renderItems(value, this.handleOnDelete)}
        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }
}
