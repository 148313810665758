import { Typography, IconButton, Stack, Tooltip } from '@mui/material'
import { Add } from '@mui/icons-material'

import { useTexts } from './useTexts'

interface Props {
  disabled?: boolean
  onOpenDialog: () => void
}

export function Header(props: Props) {
  const { onOpenDialog, disabled } = props

  const texts = useTexts()

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography noWrap variant="body1">
        {texts.title}
      </Typography>
      <Stack direction="row">
        <Tooltip title={texts.addTooltip(disabled)}>
          {/* A disabled element does not fire events. */}
          {/* Tooltip needs to listen to the child element's events to display the title. */}
          <span>
            <IconButton onClick={onOpenDialog} disabled={disabled}>
              <Add />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  )
}
