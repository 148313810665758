import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Right(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M14.98047 2.98047c-.8164 0-1.54688.4961-1.85156 1.25-.3086.7539-.125 1.6172.45705 2.1836L32.17187 25 13.58594 43.58594c-.52344.5-.73438 1.2461-.55078 1.94922.1836.69922.73047 1.2461 1.42968 1.42968.70313.1836 1.44922-.02734 1.94922-.55078l20-20c.78125-.78125.78125-2.04687 0-2.82812l-20-20c-.3789-.38672-.89453-.60547-1.4336-.60547z" />
      </svg>
    </IconContainer>
  )
}
