import type { Components } from '@mui/material'

export const MuiDialog: Components['MuiDialog'] = {
  styleOverrides: {
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: '#FFFFFF80',
      },
    },
  },
}
