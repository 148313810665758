import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    disabled: translate({ id: 'rm.scheduler.popOver.lock.archived' }),
  }))

  return api
}
