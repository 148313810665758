import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Dashboard(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 2a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-7 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.134-6.5a1 1 0 1 1 1.732 1.001A1 1 0 0 1 6.134 6.5zm1.732 11a1 1 0 1 1-1.732-1 1 1 0 0 1 1.732 1zm4.134-4a1.5 1.5 0 0 1 0-3c.027 0 .052.006.078.008L16.586 6 18 7.414l-4.508 4.508c.002.026.008.051.008.078a1.5 1.5 0 0 1-1.5 1.5zm5.866 4a1 1 0 1 1-1.732-1.001 1 1 0 0 1 1.732 1.001zM19 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
      </svg>
    </IconContainer>
  )
}
