import { PureComponent, CSSProperties } from 'react'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldParagraph,
  SecondaryColumnContentHorizontalRuler,
} from '@/forms-legacy'

import * as OrderTexts from '../../../intl'
import { Props, FieldMode } from '../typings'

const contactInformationTitleStyles: CSSProperties = {
  marginBottom: 20,
  marginTop: 10,
  paddingLeft: 0,
}

export default class ContactInformation extends PureComponent<Props> {
  render() {
    const { email, phone } = this.props

    return (
      <>
        <ReadonlyLabel style={contactInformationTitleStyles} primary>
          {OrderTexts.getContactInformationTitle()}
        </ReadonlyLabel>

        <ReadonlyBlock>
          <ReadonlyLabel primary empty={!phone || phone.length === 0}>
            {OrderTexts.getFieldLabelPhoneText()}
          </ReadonlyLabel>

          {phone && phone.length > 0 && (
            <ReadonlyField>
              <ReadonlyFieldParagraph>
                {phone === FieldMode.mixed ? OrderTexts.getMixedText() : phone}
              </ReadonlyFieldParagraph>
            </ReadonlyField>
          )}
        </ReadonlyBlock>

        <ReadonlyBlock>
          <ReadonlyLabel primary empty={!email || email.length === 0}>
            {OrderTexts.getFieldLabelEmailText()}
          </ReadonlyLabel>

          {email && email.length > 0 && (
            <ReadonlyField>
              <ReadonlyFieldParagraph>
                {email === FieldMode.mixed ? OrderTexts.getMixedText() : email}
              </ReadonlyFieldParagraph>
            </ReadonlyField>
          )}
        </ReadonlyBlock>

        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
