import type { AddTagOption as OptionProps } from '../../../types'

import { Box, Stack, Chip, Typography, Tooltip } from '@mui/material'

type Props = OptionProps & {
  selected: boolean
  warningTooltip: string
}

export function Option(props: Props) {
  const { label, selected, warning, count, ordersCount, warningTooltip } = props

  return (
    <Box
      key={label}
      paddingLeft={2}
      paddingRight={2}
      width="100%"
      sx={{ background: selected ? 'action.hover' : undefined }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Tooltip title={warning ? warningTooltip : ''} placement="right">
          <Chip
            label={label}
            variant={count > 0 ? 'outlined' : 'filled'}
            color={warning ? 'error' : undefined}
          />
        </Tooltip>
        {count > 0 && (
          <Typography variant="body2" color="text.disabled">
            {count}/{ordersCount}
          </Typography>
        )}
      </Stack>
    </Box>
  )
}
