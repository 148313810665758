import { Stack, Typography, AccordionDetails, Accordion, AccordionSummary } from '@mui/material'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'

import { useTexts } from './useTexts'
import { TerritoryDetail } from './TerritoryDetail'
import { useTerritoriesDetails } from './useTerritoriesDetails'

interface Props {
  territoryNames: Record<string, string>
  courierFilter: uui.domain.client.rm.CourierFilter
}

export function CourierConfig(props: Props) {
  const { courierFilter, territoryNames } = props

  const texts = useTexts()

  const { territoriesCount, territoriesDetails } = useTerritoriesDetails(
    courierFilter,
    territoryNames,
  )

  return (
    <Stack spacing={0} paddingBottom={4}>
      <Typography variant="caption" color="textSecondary">
        {texts.title}
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          sx={{ '& .MuiAccordionSummary-content': { alignItems: 'center' } }}
        >
          <Typography variant="body1">{texts.territoriesCount(territoriesCount)}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Stack spacing={1} mt={1}>
            {territoriesDetails.map(detail => (
              <TerritoryDetail key={detail.id} detail={detail} />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  )
}
