import type { ReactElement } from 'react'
import { styled } from '@mui/material'

import { RouteManager } from '@workwave-tidal/icons/logos'

const MaskContainer = styled('div', { name: 'MaskContainer' })({
  display: 'flex',
  flexDirection: 'column',

  // Center the children
  alignItems: 'center',
  justifyContent: 'center',

  // Fill the parent viewport
  width: '100%',
  height: '100%',

  // Cover the rest of the app
  zIndex: 99999,
  position: 'fixed',
  top: 0,
  left: 0,
  background: 'rgba(255, 255, 255, 0.85)',
})

const FirstRowContainer = styled('div', { name: 'FirstRowContainer' })({
  width: '100%',
  flex: '1 1 auto',

  // Center the children
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const LogoContainer = styled('div', { name: 'LogoContainer' })({
  width: '100%',
  flex: '0 0 200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const TextContainer = styled('div', { name: 'LogoContainer' })(({ theme }) => ({
  width: '50%',
  padding: theme.spacing(3),
}))

type Props = {
  hide: boolean
  children: ReactElement
}

export function MaskLayout(props: Props) {
  return (
    // The surrounding div allows is needed because when MaskContainer was the first rendered component
    // its styles were not copied to the popup
    <div style={{ opacity: props.hide ? 0 : 1 }}>
      <MaskContainer>
        <FirstRowContainer>
          <TextContainer>{props.children}</TextContainer>
        </FirstRowContainer>
        <LogoContainer>
          <RouteManager width={350} />
        </LogoContainer>
      </MaskContainer>
    </div>
  )
}
