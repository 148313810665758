import { useState } from 'react'

import { useConfigureModalController, useModalController, useCloseModal } from '@/atoms'

type ModalState = 'ready' | 'submitting'
type ModalData = Record<string, unknown>

export const modalId = 'changePasswordModal'

export const useController = () => useModalController<ModalData, ModalState>(modalId)

export const useConfigureController = () => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {},
    }),
  }))

  return useConfigureModalController<ModalData, ModalState>(modalId, options)
}
