import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Trash, Edit, Export } from '@/icons'

import { useModalActions } from '../../../../../../hooks/useModalActions'
import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'

interface Props {
  place: uui.domain.client.gps.wwgps.Place
  setView: (view: 'default' | 'delete') => void
}

export function Actions(props: Props) {
  const { place, setView } = props

  const { modals, modalActions } = useModalActions([place.id])
  const actions = useActions(place, setView)
  const texts = useTexts()

  return (
    <SecondaryColumnHeaderActionsRow>
      <SecondaryColumnHeaderActionButton
        label={texts.export}
        icon={<Export />}
        onClick={modalActions.showExportPlacesModal}
        testid="place-export"
      />

      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={actions.onSwitchToDeletePanel}
            disabled={preventEditing}
            testid="place-delete"
          />
        )}
      />

      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.edit}
            icon={<Edit />}
            onClick={actions.onEdit}
            disabled={preventEditing}
            testid="place-edit"
          />
        )}
      />
      {modals}
    </SecondaryColumnHeaderActionsRow>
  )
}
