import type { Coordinate } from 'ol/coordinate'

import Map from 'ol/Map'

export function computePosition(map: Map, coord: Coordinate): uui.domain.Point {
  const [x, y] = map.getPixelFromCoordinate(coord)
  return { x, y }
}

export function computePositions(map: Map, coords: Coordinate[]): uui.domain.Point[] {
  return coords.map(coord => computePosition(map, coord))
}
