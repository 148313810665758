import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderSteps } from '@/features/domain/order'

import { useDepotsNavigator } from '../../../../../hooks/useDepotsNavigator'

export const useOrderIdsForCurrentSelection = () => {
  const { currentDepots } = useDepotsNavigator()
  const orders = useSelector(selectOrderSteps)

  return useMemo(
    () =>
      Object.values(orders).reduce<string[]>((acc, order) => {
        if (order.atDepot && currentDepots.some(depot => depot.id === order.depotId))
          acc.push(order.id)

        return acc
      }, []),
    [currentDepots, orders],
  )
}
