import styled from 'styled-components'

const styledComponent = styled.div`
  position: relative;
  width: 8px;
  height: 4px;

  margin-left: 4px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4px 4px 4px;
    border-color: transparent transparent ${p => p.theme.colors.$scienceBlue} transparent;
  }
`
styledComponent.displayName = 'Triangle'
export default styledComponent
