import type { CSSProperties } from 'react'

import { PureComponent } from 'react'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyVerticalList,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  ReadonlyFieldRowRightSideLabel,
  SecondaryColumnContentHorizontalRuler,
} from '@/forms-legacy'
import { Tooltip } from '@/components/primitives/Tooltip'

import * as OrderTexts from '../../../intl'
import { Props, FieldMode } from '../typings'

const ellipsisStyle: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

export default class CustomFields extends PureComponent<Props> {
  render() {
    const { customFields } = this.props

    if (customFields === FieldMode.hidden) {
      return null
    }

    const list: [string, string][] = Object.entries(customFields)

    return (
      <>
        <ReadonlyBlock>
          <ReadonlyLabel primary empty={list.length === 0}>
            {OrderTexts.getCustomFieldText()}
          </ReadonlyLabel>

          {list.length > 0 && (
            <ReadonlyVerticalList>
              {list.map(([key, value]) => (
                <ReadonlyField key={key}>
                  <ReadonlyFieldRow>
                    <ReadonlyFieldLabel style={ellipsisStyle}>{key}</ReadonlyFieldLabel>
                    <ReadonlyFieldRowRightSideLabel
                      style={{
                        display: 'block',
                        height: 'auto',
                      }}
                    >
                      <Tooltip placement="top" title={value}>
                        <div style={ellipsisStyle}>{value}</div>
                      </Tooltip>
                    </ReadonlyFieldRowRightSideLabel>
                  </ReadonlyFieldRow>
                </ReadonlyField>
              ))}
            </ReadonlyVerticalList>
          )}
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bigBottomSpace />
      </>
    )
  }
}
