import { ReactNode, useMemo, useEffect, useRef } from 'react'
import {
  FormControlLabel,
  TextField,
  Switch,
  Paper,
  Stack,
  Popover,
  Typography,
  PopoverOrigin,
} from '@mui/material'
import { useTexts } from './useTexts'

export interface ColumnData {
  id: string
  label: any
  visible: boolean
}

export type CollectedColumns = {
  notifications: ColumnData[]
  customFields: ColumnData[]
  generic: ColumnData[]
  loads: ColumnData[]
}

type Props = {
  open: boolean
  close: () => void
  columns: ColumnData[] | CollectedColumns | null
  onColumnVisibilityChange: (id: string, visible: boolean) => void
  slots?: {
    header?: {
      leftButton?: ReactNode
      rightButton?: ReactNode
    }
    footer?: {
      leftButton?: ReactNode
      rightButton?: ReactNode
    }
  }
  position: {
    top: number
    left: number
  }
  maxHeight: number
  filter: string
  onFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const anchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' }

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

const minimumScrollBarHeight = 100

const defaultHeight = 200

const minimumPopupHeight = minimumScrollBarHeight + defaultHeight

const testIdMap = {
  root: 'columns-manager-root',
  searchInput: 'columns-manager-search-input',
  list: 'columns-manager-list',
}

export function ColumnManager(props: Props) {
  const {
    open,
    close,
    columns,
    slots,
    onColumnVisibilityChange,
    maxHeight,
    position,
    filter,
    onFilterChange,
  } = props
  const texts = useTexts()

  const anchorPosition = useMemo(
    () => ({
      top:
        maxHeight < minimumPopupHeight
          ? position.top + maxHeight - minimumPopupHeight
          : position.top,
      left: position.left,
    }),
    [maxHeight, position],
  )

  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const reference = requestAnimationFrame(() => {
      if (searchInputRef?.current) {
        searchInputRef.current.focus()
      }
    })
    return () => cancelAnimationFrame(reference)
  }, [])

  const renderColumns = () => {
    if (Array.isArray(columns)) {
      return columns.map(column => {
        const { label } = column
        if (!label) return null

        if (filter !== '') {
          const labelIncludesFilterQuery = label.toLowerCase().includes(filter.toLowerCase())
          if (!labelIncludesFilterQuery) return null
        }

        return (
          <FormControlLabel
            control={<Switch size="small" checked={column.visible} />}
            onChange={(_e, checked) => {
              onColumnVisibilityChange(column.id, checked)
            }}
            label={label}
            key={column.id}
          />
        )
      })
    } else {
      if (!columns) return

      return (
        <>
          {columns?.generic.map(column => (
            <FormControlLabel
              control={<Switch size="small" checked={column.visible} />}
              onChange={(_e, checked) => {
                onColumnVisibilityChange(column.id, checked)
              }}
              label={column.label}
              key={column.id}
            />
          ))}

          {columns?.loads.length > 0 && (
            <>
              <Typography variant="subtitle1">{texts.columnsGroup.loads}</Typography>
              {columns?.loads.map(column => (
                <FormControlLabel
                  control={<Switch size="small" checked={column.visible} />}
                  onChange={(_e, checked) => {
                    onColumnVisibilityChange(column.id, checked)
                  }}
                  label={column.label}
                  key={column.id}
                />
              ))}
            </>
          )}

          {columns?.customFields.length > 0 && (
            <>
              <Typography variant="subtitle1">{texts.columnsGroup.customFields}</Typography>
              {columns?.customFields.map(column => (
                <FormControlLabel
                  control={<Switch size="small" checked={column.visible} />}
                  onChange={(_e, checked) => {
                    onColumnVisibilityChange(column.id, checked)
                  }}
                  label={column.label}
                  key={column.id}
                />
              ))}
            </>
          )}

          {columns?.notifications.length > 0 && (
            <>
              <Typography variant="subtitle1">{texts.columnsGroup.notifications}</Typography>
              {columns?.notifications.map(column => (
                <FormControlLabel
                  control={<Switch size="small" checked={column.visible} />}
                  onChange={(_e, checked) => {
                    onColumnVisibilityChange(column.id, checked)
                  }}
                  label={column.label}
                  key={column.id}
                />
              ))}
            </>
          )}
        </>
      )
    }
  }

  return (
    <Popover
      open={open}
      onClose={close}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <Stack
        data-testid={testIdMap.root}
        data-trackid={testIdMap.root}
        width={395}
        maxHeight="100%"
      >
        <Paper elevation={3}>
          <Stack padding={1} spacing={1} alignItems="flex-start">
            <TextField
              data-testid={testIdMap.searchInput}
              data-trackid={testIdMap.searchInput}
              variant="standard"
              label="Filter"
              value={filter}
              onChange={onFilterChange}
              inputRef={searchInputRef}
            />
            <Stack direction="row" width="100%" justifyContent="space-between">
              {slots?.header?.leftButton}
              {slots?.header?.rightButton}
            </Stack>
            <Stack
              data-testid={testIdMap.list}
              data-trackid={testIdMap.list}
              maxHeight={`calc(${maxHeight}px - ${defaultHeight}px)`}
              minHeight={minimumScrollBarHeight}
              spacing={1}
              width="100%"
              overflow="auto"
              flex="1 1"
            >
              {renderColumns()}
            </Stack>
            <Stack direction="row" width="100%" justifyContent="space-between">
              {slots?.footer?.leftButton}
              {slots?.footer?.rightButton}
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Popover>
  )
}
