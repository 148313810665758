import type { PreparationCloseoutEventData } from '../../../types'

type Params = {
  driverId: string
  extRoute?: uui.domain.client.rm.ExtendedRoute
  approvedRoute?: uui.domain.client.rm.SchedulerRowApprovedData
  firstDriverId: string
  departureDepot: string
  routeTrackingData?: uui.domain.client.rm.RouteTrackingData['routeTrackingData']
}

export function computePreparationTimeEntry({
  driverId,
  extRoute,
  approvedRoute,
  firstDriverId,
  departureDepot,
  routeTrackingData,
}: Params): PreparationCloseoutEventData | undefined {
  // If there are no routeTrackingData and the driver is not the first one just bail-out
  if (!routeTrackingData && driverId !== firstDriverId) return

  // Check if got tracking data
  const gotTrackingData =
    routeTrackingData &&
    (routeTrackingData.preparationTrackingData.timeInSec !== -1 ||
      routeTrackingData.preparationTrackingData.timeOutSec !== -1)

  // If got tracking data and the driver is not the same who tracked the event just bail-out
  if (gotTrackingData && routeTrackingData.preparationTrackingData.driverId !== driverId) return

  // Compose tracking data
  const tracked = gotTrackingData
    ? {
        location: routeTrackingData.preparationTrackingData.timeInLatLng
          ? routeTrackingData.preparationTrackingData.timeInLatLng
          : undefined,
        start: routeTrackingData.preparationTrackingData.timeInSec,
        end: routeTrackingData.preparationTrackingData.timeOutSec,
      }
    : undefined

  // Search for the matching step on the approved version
  for (const extRouteStep of approvedRoute?.steps ?? []) {
    if (extRouteStep.type !== 'preparation') continue

    // Compose planned data
    const planned = {
      location: departureDepot,
      start: extRouteStep.startSec,
      end: extRouteStep.endSec,
    }

    // If there are some tracking data return the 'complete' version
    if (tracked) {
      return {
        type: 'complete',
        planned,
        tracked,
      }
    }

    return {
      type: 'planned-only',
      planned,
    }
  }

  // If we have tracked let's return it
  if (tracked) {
    return {
      type: 'tracked-only',
      tracked,
    }
  }

  // If no matching step has been found on the approved version and no tracked data are present
  // let's check if the current version got a preparation step
  for (const aggregatedStep of extRoute?.steps ?? []) {
    for (const extRouteStep of aggregatedStep.steps) {
      if (extRouteStep.step.type !== 'preparation') continue
      return {
        type: 'tracked-only',
        tracked: {
          start: -1,
          end: -1,
        },
      }
    }
  }
}
