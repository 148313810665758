import { DropDownField } from '@/forms-legacy'
import { gpsValidTimezones } from '@/local/server-data/utils/smartUtils/timezone'
import { useTexts } from './useTexts'

const values = gpsValidTimezones.map(key => ({
  id: key,
  label: key,
}))

export function Timezone() {
  const texts = useTexts()

  return <DropDownField name="timezone" label={texts.timezone} values={values} />
}
