import { useInvalidData } from '../../hooks/useInvalidData'
import { useController } from '../../../../hooks/useController'

import { MultipleDrivers } from './components/MultipleDrivers'
import { SingleDriver } from './components/SingleDriver'

export function Invalid() {
  const {
    data: { driverIds },
  } = useController()

  const invalidData = useInvalidData()

  if (!invalidData || invalidData.quantity === 0) return null

  return driverIds.length === 1 ? (
    <SingleDriver invalidDriverResult={invalidData} />
  ) : (
    <MultipleDrivers invalidDriversResult={invalidData} driverIds={driverIds} />
  )
}
