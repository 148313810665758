import styled from 'styled-components'

import { WithTheme, Theme, getColorWithAlpha } from '../../theme'
import { parseSizeValue } from '../../atoms/utils/parseSizeValue'

import { Props, ButtonPreset } from './typings'
import { defaultIconSize } from './defaults'

type ThemedProps = WithTheme<Props>

const getBackgroundColorFromPreset = (
  preset: ButtonPreset | undefined,
  theme: Theme,
  defaultValue: string,
): string => {
  switch (preset) {
    case 'cancel':
      return theme.colors.$alabaster
    case 'confirm':
      return theme.colors.$scienceBlue
    case 'alert':
      return theme.colors.$outrageousRed
    case 'textButton':
      return 'transparent'
  }
  return defaultValue
}

const getDisabledBackgroundColorFromPreset = (
  preset: ButtonPreset | undefined,
  theme: Theme,
): string => {
  switch (preset) {
    case 'cancel':
    case 'confirm':
    default:
      return theme.colors.$whiteSmoke
  }
}

const getDisabledColorFromPreset = (preset: ButtonPreset | undefined, theme: Theme): string => {
  switch (preset) {
    case 'cancel':
    case 'confirm':
    default:
      return theme.colors.$silver
  }
}

const getColorFromPreset = (
  preset: ButtonPreset | undefined,
  theme: Theme,
  defaultValue: string,
): string => {
  if (!preset) {
    return defaultValue
  }

  switch (preset) {
    case 'cancel':
      return theme.colors.$nightRider
    case 'textButton':
      return theme.colors.$scienceBlue
    case 'confirm':
    case 'alert':
      return theme.colors.$pureWhite
  }
}

const getBackgroundColor = ({
  preset,
  backgroundTint = '$alabaster',
  backgroundAlpha = 1,
  theme,
}: ThemedProps): string => {
  if (preset) {
    return getBackgroundColorFromPreset(preset, theme, theme.colors.$alabaster)
  }
  return getColorWithAlpha(backgroundTint, backgroundAlpha)
}

const getDisabledBackgroundColor = ({ preset = 'cancel', theme }: ThemedProps): string => {
  return getDisabledBackgroundColorFromPreset(preset, theme)
}

const getDisabledColor = ({ preset = 'cancel', theme }: ThemedProps): string => {
  return getDisabledColorFromPreset(preset, theme)
}

const getColor = ({ color, preset, theme }: ThemedProps): string => {
  if (!!color) {
    return theme.colors[color]
  }
  return getColorFromPreset(preset, theme, 'currentColor')
}

const getFontSize = ({ theme }: ThemedProps) => theme.fonts.$h3

const getFontWeight = ({ theme }: ThemedProps) => theme.weights.$semiBold

const getIconAbsolutePositionRight = ({ iconSize }: ThemedProps): string =>
  `${iconSize || defaultIconSize}px`

const getPaddingRightForAbsolutePlacedIcon = ({
  iconPlacement,
  iconSize,
  Icon,
}: ThemedProps): string => {
  if (iconPlacement === 'absoluteRight' && !!Icon) {
    return `padding-right: ${iconSize || defaultIconSize + 12}px;`
  }
  return ''
}

const getMarginTop = ({ marginTop }: Props) => {
  if (marginTop) {
    return parseSizeValue(marginTop)
  }

  return 0
}

const getMarginBottom = ({ marginBottom }: Props) => {
  if (marginBottom) {
    return parseSizeValue(marginBottom)
  }

  return 0
}

const getMarginLeft = ({ marginLeft }: Props) => {
  if (marginLeft) {
    return parseSizeValue(marginLeft)
  }

  return 0
}

const getMarginRight = ({ marginRight }: Props) => {
  if (marginRight) {
    return parseSizeValue(marginRight)
  }

  return 0
}

const getEllipsis = ({ ellipsis }: ThemedProps): string => {
  return !!ellipsis
    ? `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  `
    : ''
}

const getWidth = ({ autoWidth }: ThemedProps): string => (autoWidth ? 'auto' : '100%')

const getTextAlign = ({ textAlign = 'center' }: ThemedProps): string => textAlign

const getPadding = ({ padding = '0 10px' }: ThemedProps): string => parseSizeValue(padding)

const getOutline = ({ canFocus }: Props): string => (canFocus ? 'auto' : '0')

const height = 30

const btn = styled.button<Props>``
export const ButtonPrimitive: any = styled(btn).attrs<Props>(() => ({
  className: 'o-molecule-button',
}))`
  position: relative;
  display: block;
  width: ${getWidth};
  color: ${getColor};
  background-color: ${getBackgroundColor};
  ${getEllipsis};
  height: ${height}px;
  border-radius: 4px;
  margin-top: ${getMarginTop};
  margin-bottom: ${getMarginBottom};
  margin-left: ${getMarginLeft};
  margin-right: ${getMarginRight};
  text-align: ${getTextAlign};
  outline: ${getOutline};

  padding: ${getPadding};
  ${getPaddingRightForAbsolutePlacedIcon};
  font-size: ${getFontSize};
  font-weight: ${getFontWeight};
  cursor: pointer;
  border-width: 0;

  transition: color 0.2s ease, background 0.3s ease;

  .o-molecule-button__icon {
    position: absolute;
    top: 50%;
    right: ${getIconAbsolutePositionRight};
    width: ${getIconAbsolutePositionRight};
    transform: translateY(-50%);
  }

  &:disabled {
    color: ${getDisabledColor};
    background-color: ${getDisabledBackgroundColor};
    cursor: not-allowed;
  }
`

ButtonPrimitive.displayName = 'ButtonPrimitive'
