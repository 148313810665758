import type { EditingType } from '../types'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { useLogoutModal } from '@/components/modals/LogoutModal'
import { selectFullname, selectUserProfile, isGpsOnly } from '@/features/domain/user'
import { useTransactionState, useEditingState } from '@/atoms'
import { useNavigate } from '@/routing'

export function useInitializeSettingsMenu() {
  const [open, setOpen] = useState(false)

  const navigate = useNavigate(true)
  const fullName = useSelector(selectFullname)
  const { mode: transactionMode } = useTransactionState()
  const { show: showLogout, Modal: LogoutModal } = useLogoutModal()
  const {
    user: { email },
  } = useSelector(selectUserProfile)
  const [editingState] = useEditingState()
  const gpsOnly = useSelector(isGpsOnly)

  // check editing to cover the gpsonly users that have no transaction
  const canLogout = !editingState.editing && transactionMode !== 'controlled'

  const editingType: EditingType = editingState.editing
    ? transactionMode === 'controlled'
      ? 'transaction'
      : 'no-transaction'
    : 'none'

  const goToSettings = useCallback(() => {
    setOpen(false)
    navigate('settings')
  }, [navigate])

  const openLogoutModal = useCallback(() => {
    showLogout()
    setOpen(false)
  }, [showLogout])

  return {
    open,
    email,
    gpsOnly,
    setOpen,
    navigate,
    fullName,
    canLogout,
    editingType,
    LogoutModal,
    goToSettings,
    openLogoutModal,
  }
}
