import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    vehicleLabelTitle: (label: string, deviceId?: string) => {
      if (!deviceId) return `${label} `

      const deviceIdText = translate({
        id: 'gps.trackinglist.list.tooltip.deviceId',
        values: { deviceId },
      })

      return `${label} - ${deviceIdText}`
    },
    device: (allowGps: boolean, virtual: boolean, hasVehicle: boolean, isSimulation: boolean) => {
      if (isSimulation || !allowGps) return translate({ id: 'vehicles.form.tracking.none' })
      if (hasVehicle && virtual) return translate({ id: 'vehicles.form.tracking.mobileApp' })
      if (hasVehicle && !virtual) return translate({ id: 'vehicles.form.tracking.physical' })
      if (virtual) return translate({ id: 'vehicles.form.tracking.onlyDevice.virtual' })

      return translate({ id: 'vehicles.form.tracking.onlyDevice.physical' })
    },
    badges: (tags: uui.domain.client.gps.wwgps.Tag[]) => {
      const base = '-'

      return tags.reduce(
        (acc: string, tag, index: number): string =>
          index === 0 ? tag.value : `${acc}, ${tag.value}`,
        base,
      )
    },
    deviceBadge: translate({ id: 'devices.form.list.badge.device' }),
  }))

  return api
}
