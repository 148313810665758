import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    addTimeWindowTooltip: translate({ id: 'order.form.bulk.timeWindows.addTimeWindowTooltip' }),

    addTimeWindowDisabledTooltip: translate({
      id: 'order.form.bulk.timeWindows.addTimeWindowDisabledTooltip',
    }),
  }))

  return api
}
