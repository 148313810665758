import type { MouseEvent, ReactNode, SyntheticEvent, ChangeEvent } from 'react'

import { useCallback, useState, useEffect } from 'react'

import { intl } from '@/intl'
import { clsx } from '@/utils'
import { Up, Down } from '@/icons'
import { durationUtils } from '@/server-data'

// import TextInputState, { ChangeEventHandler } from '../../enhancers/TextInputState'
import { BaseFieldProps } from '../../typings'
import { BaseInput, BaseInputWrapper, StepButton, StepButtonWrapper } from '../../Internals'

import { snapToStep } from './utils'

export interface ExoticValue {
  value: number
  text: string
}

export type Props = BaseFieldProps & {
  step: number
  value: number
  defaultValue?: number
  onChange: (value: any, e?: SyntheticEvent<HTMLElement>) => void
  min?: number
  max?: number
  useSnap?: boolean
  canBeNaN?: boolean
  disabled?: boolean
  placeholder?: string
  hasError?: boolean
  exotic?: ExoticValue
  children?: ReactNode
}

const computeValue = (
  value: number,
  defaultValue: number = 0,
  canBeNaN: boolean = false,
): number => {
  if (canBeNaN) {
    return isNaN(value) ? NaN : value
  }

  return isNaN(value) ? defaultValue : value
}

const getMinutesFromText = (
  value: string,
  defaultValue: number = 0,
  canBeNaN: boolean = false,
): number => {
  const pieces: number[] = value.split(':').map((p: string) => parseInt(p))

  if (pieces.length === 1) {
    return computeValue(pieces[0], defaultValue, canBeNaN)
  }

  if (pieces.length === 2) {
    const hours: number = pieces[0] * 60
    const minutes: number = pieces[1]

    return computeValue(hours + minutes, defaultValue, canBeNaN)
  }

  return canBeNaN ? NaN : defaultValue
}

const computeDisplayText = (
  value: number,
  exotic?: ExoticValue,
  defaultValue: number = 0,
  canBeNaN: boolean = false,
): string => {
  if (exotic && value === exotic.value) {
    return exotic.text
  }
  return durationUtils.formatSeconds(intl.translate)(
    (canBeNaN && isNaN(value)) || value < 0 ? defaultValue * 60 : value * 60,
    'DURATION_HH_MM',
  )
}

export function DurationStep(props: Props) {
  const {
    name,
    value,
    className = '',
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    readonly,
    placeholder = '',
    canBeNaN,
    disabled,
    hasError,
    children = null,
    exotic,
    defaultValue = 0,
    useSnap,
    onChange,
    step,
  } = props

  const [displayText, setDisplayText] = useState<string>(() =>
    computeDisplayText(value, exotic, defaultValue, canBeNaN),
  )

  useEffect(() => {
    setDisplayText(computeDisplayText(value, exotic, defaultValue, canBeNaN))
  }, [value, exotic, defaultValue, canBeNaN])

  // const displayText = useMemo(
  //   () => computeDisplayText(value, exotic, defaultValue, canBeNaN),
  //   [value, exotic, defaultValue, canBeNaN],
  // )

  const rootClassName = clsx({
    [className]: true,
    'o-duration-step': true,
    'is-readonly': readonly,
    'has-error': hasError,
  })

  const disabledUpButton = value >= max || readonly
  const disableDownButton = (value <= min && !canBeNaN) || readonly || (canBeNaN && isNaN(value))

  const handleIncrement = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      if (isNaN(value)) {
        return onChange(0, e)
      }
      let result = snapToStep(value, step, true, useSnap)
      if (max !== undefined && result > max) {
        result = max
      }
      onChange(result, e)
    },
    [onChange, value, step, max, useSnap],
  )

  const handleDecrement = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      if (isNaN(value)) {
        return onChange(0, e)
      }

      if (value === min && canBeNaN) {
        return onChange(null, e)
      }

      let result = snapToStep(value, step, false, useSnap)
      if (min !== undefined && result < min) {
        result = min
      }
      onChange(result, e)
    },
    [onChange, value, step, min, useSnap, canBeNaN],
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDisplayText(e.target.value)
    },
    [setDisplayText],
  )

  const handleBlur = useCallback(() => {
    const val = getMinutesFromText(displayText, defaultValue, canBeNaN)
    const normalizedValue = isNaN(val) || val > max || val < min ? value : val
    setDisplayText(computeDisplayText(normalizedValue, exotic, defaultValue, canBeNaN))
    onChange(normalizedValue)
  }, [onChange, displayText, defaultValue, canBeNaN, exotic, min, max, value])

  return (
    <BaseInputWrapper className={rootClassName}>
      <BaseInput
        name={name}
        type="text"
        disabled={disabled}
        readOnly={readonly}
        value={displayText}
        onBlur={handleBlur}
        onChange={handleChange}
        data-testid="input-text"
        placeholder={placeholder}
      />

      {!!children && children}
      <StepButtonWrapper className="o-duration-step__controls">
        <StepButton
          onClick={handleIncrement}
          top
          disabled={disabledUpButton}
          testid="input-step-up"
        >
          <Up />
        </StepButton>
        <StepButton
          onClick={handleDecrement}
          bottom
          disabled={disableDownButton}
          testid="input-step-down"
        >
          <Down />
        </StepButton>
      </StepButtonWrapper>
    </BaseInputWrapper>
  )
}
