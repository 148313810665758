import { useSelector } from 'react-redux'

import { selectDevicesAggregateInfo } from '@/features/domain/device'
import { selectUserConfiguration } from '@/features/domain/user'

import { canRenderGpsDataToggler } from '../../../../../../utils/canRenderGpsDataToggler'
import { GpsDataViewToggler } from '../../../../../../components/GpsDataViewToggler'
import { getDeviceId } from '../../../../../../utils/getDeviceId'

import { TrackingOptions } from '../../../components/TrackingOptions'
import { GpsData } from '../../../components/GpsData'

import { useGpsDataCollapsed } from '../../hooks/useGpsDataCollapsed'
import { useTexts } from '../../hooks/useTexts'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  values: uui.domain.ui.forms.Rm360VehicleFormValues
}

export function WwgpsTracking(props: Props) {
  const { unifiedVehicle, values } = props

  const deviceAggregateInfos = useSelector(selectDevicesAggregateInfo)
  const { collapsed, toggleCollapsed } = useGpsDataCollapsed()
  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const canRenderToggler = canRenderGpsDataToggler(unifiedVehicle, deviceAggregateInfos, true)
  const simulation = userConfig.planType === 'simulation'
  const deviceId = getDeviceId(unifiedVehicle) ?? ''

  return (
    <>
      {!simulation && <TrackingOptions formValues={values} />}

      {canRenderToggler && (
        <GpsDataViewToggler
          title={texts.expanding}
          description={texts.deviceSettings(deviceId)}
          collapsed={collapsed}
          onToggle={toggleCollapsed}
        />
      )}

      {!collapsed && <GpsData values={values} />}
    </>
  )
}
