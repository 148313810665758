import type { FormProps } from '../types'

import { NotificationForm } from '../../../../../../notificationForms/sections/notifications/components/NotificationForm'
import { NotificationView } from '../../../../../../notificationForms/sections/notifications/components/NotificationView'
import { useFormStateController } from '../../hooks/useFormStateController'
import { CanceledNotificationView } from './components/CanceledNotificationView'

import { useCanceledNotificationSubmit } from './hooks/useCanceledNotificationSubmit'
import { useCanceledNotificationConfig } from './hooks/useCanceledNotificationConfig'
import { useCanceledNotificationValues } from './hooks/useCanceledNotificationValues'
import { useCanceledNotificationToggle } from './hooks/useCanceledNotificationToggle'
import { useTexts } from './useTexts'

const noop = () => undefined

/**
 * Renders the Canceled notification form providing all the domain data and apis.
 */
export function CanceledNotification(props: FormProps) {
  const { inEditing, setEditingState } = props

  const values = useCanceledNotificationValues()

  const { toggleDescription, onToggle } = useCanceledNotificationToggle(values)
  const { edit, cancel } = useFormStateController(setEditingState)
  const onSubmit = useCanceledNotificationSubmit()
  const config = useCanceledNotificationConfig()
  const texts = useTexts()

  // Render the Form inside a FormRoot component that will expose the FormState API through a React.Context
  if (inEditing) {
    return (
      <NotificationForm
        notification={'canceled'}
        subtitle={texts.description}
        onSubmit={onSubmit}
        title={texts.title}
        cancel={cancel}
        values={values}
        config={config}
        initHook={noop}
      />
    )
  }

  return (
    <NotificationView
      Form={<CanceledNotificationView values={values} />}
      notification={'canceled'}
      toggleSubtitle={toggleDescription}
      subtitle={texts.description}
      onToggle={onToggle}
      title={texts.title}
      values={values}
      onEdit={edit}
      available
    />
  )
}
