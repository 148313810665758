import {
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldParagraph,
} from '@/forms-legacy'

import { useTexts } from './useTexts'

interface Props {
  integrationData: uui.domain.client.rm.ShopifyIntegrationData
}

export function Shopify(props: Props) {
  const {
    integrationData: { shopifyName },
  } = props

  const texts = useTexts()

  return (
    <>
      <ReadonlyBlock>
        <ReadonlyLabel primary>{texts.orderName}</ReadonlyLabel>

        <ReadonlyField>
          <ReadonlyFieldParagraph>{shopifyName}</ReadonlyFieldParagraph>
        </ReadonlyField>
      </ReadonlyBlock>
      <SecondaryColumnContentHorizontalRuler bottomSpace />
    </>
  )
}
