import type { MouseEvent } from 'react'

import { useCallback } from 'react'

import { approveRoutes, revokeRoutes } from '@/features/domain/route'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useActions() {
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const approveDate = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      try {
        const result = await dispatch(
          approveRoutes({
            dates: [e.currentTarget.dataset.date as string],
            mode: 'byDates',
          }),
        )

        if (approveRoutes.rejected.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, toast],
  )

  const revokeDate = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      try {
        const result = await dispatch(
          revokeRoutes({
            dates: [e.currentTarget.dataset.date as string],
            mode: 'byDates',
          }),
        )

        if (revokeRoutes.rejected.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, toast],
  )

  return {
    approveDate,
    revokeDate,
  }
}
