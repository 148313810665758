import { CircularProgress } from '@mui/material'
import { NestedDropdown, DropdownMenuItem } from '@/components/DropdownMenu'
import { StrikedEye } from '@/icons'

import { ActiveIcon } from '../ActiveIcon'
import { useTexts } from './useTexts'

import { useRoutePathsMapStyle } from './hooks/useRoutePathsMapStyle'
import { useHasRoutePaths } from './hooks/useHasRoutePaths'
import { useActions } from './hooks/useActions'
interface Props {
  parentOpen: boolean
}
export function RoutePathsSubMenu(props: Props) {
  const { parentOpen } = props

  const {
    methodInExecution,
    actions: { showRoutePaths, hideRoutePaths },
  } = useActions()
  const activeStyle = useRoutePathsMapStyle()

  const hasRoutePaths = useHasRoutePaths()
  const texts = useTexts()

  // TODO: Disabled for v3-beta release
  // const tooltipTitle = hasRoutePaths === true ? undefined : texts.noRoutePaths

  return (
    <NestedDropdown
      placement="left"
      parentOpen={parentOpen}
      disabled={!hasRoutePaths}
      triggerContent={texts.routePaths}
      Icon={
        hasRoutePaths && activeStyle === 'off' ? (
          <StrikedEye size={12} color="$silver" />
        ) : undefined
      }
    >
      <DropdownMenuItem
        external
        onClick={showRoutePaths}
        Icon={
          methodInExecution === 'showRoutePaths' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'on'} />
          )
        }
      >
        {texts.on}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={hideRoutePaths}
        Icon={
          methodInExecution === 'hideRoutePaths' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'off'} />
          )
        }
      >
        {texts.off}
      </DropdownMenuItem>
    </NestedDropdown>
  )
}
