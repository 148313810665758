import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { Report } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  disabled: boolean
}

export function ReportButton(props: Props) {
  const { disabled, onClick } = props
  const texts = useTexts()

  const iconColor = disabled ? '$silver' : '$nightRider'

  return (
    <NavigoIconButton
      onClick={onClick}
      disabled={disabled}
      text={texts.runReport}
      Icon={<Report size={16} color={iconColor} />}
      testid="navigo-device-overview-actions-reports"
    />
  )
}
