import { createSlice } from '@reduxjs/toolkit'

import { extractOrderStepsFromOrders, extractOrdersGridRows } from '@/server-data'
import { logout } from '@/features/actions/logout'

import { initialState } from './initialState'

import { updateDomainData, replaceDomainData } from './core'

export const slice = createSlice({
  name: 'domain',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(logout.fulfilled, () => initialState)

    builder
      .addCase(replaceDomainData, (mutableState, action) => {
        mutableState.publicData = action.payload

        mutableState.extendedOrderSteps = extractOrderStepsFromOrders(
          action.payload.domain.rm.orders,
        )

        mutableState.ordersGridRows = extractOrdersGridRows(
          mutableState.extendedOrderSteps,
          mutableState.publicData.domain.lists.ordersGrid.structure,
        )
      })
      .addCase(updateDomainData.fulfilled, (mutableState, action) => {
        mutableState.publicData = action.payload.publicData
        mutableState.extendedOrderSteps = action.payload.extendedOrderSteps
        mutableState.ordersGridRows = action.payload.ordersGridRows
      })
  },
})
