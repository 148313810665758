export function aggregateRoutesByDate(routes: Record<string, uui.domain.client.rm.ExtendedRoute>) {
  return Object.values(routes).reduce<Record<string, uui.domain.client.rm.ExtendedRoute[]>>(
    (acc, route) => {
      const { date } = route.route
      acc[date] ??= []
      acc[date].push(route)

      return acc
    },
    {},
  )
}
