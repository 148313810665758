import { useDepotsLinkedToOrderSteps } from './useDepotsLinkedToOrderSteps'

export const useIsDepotLinkedToOrderSteps = (ids: string | string[]) => {
  const linkedIds = useDepotsLinkedToOrderSteps()

  if (Array.isArray(ids)) {
    return ids.some(id => linkedIds.has(id))
  }

  return linkedIds.has(ids)
}
