import styled from 'styled-components'

const TagPlaceholder = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  width: 165px;
  height: 28px;
  border-radius: 6px;

  margin-right: 10px;

  background: ${p => p.theme.colors.$pureWhite};
  border: 2px dashed ${p => p.theme.colors.$silver};

  padding: 0 10px;

  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
  color: ${p => p.theme.colors.$nightRider};
`
TagPlaceholder.displayName = 'TagPlaceholder'
export default TagPlaceholder
