import { ConnectMapToList, InspectPinMode, Map as MainMap, MapLayer } from '@/map'

export function Map() {
  return (
    <MainMap selectionContext="crud" selectionCategory="geofences" mapId="ww-map">
      <InspectPinMode mode="none" />

      <MapLayer uid="order" interactive={false} visible />
      <MapLayer uid="region" interactive={false} visible />
      <MapLayer uid="roadSegment" interactive={false} visible />
      <MapLayer uid="geofence" interactive visible />
      <MapLayer uid="routePolyline" visible />
      <MapLayer uid="place" visible />

      <ConnectMapToList category="geofences" />

      {/* TODO: Add remaining layers once they are implemented */}
    </MainMap>
  )
}
