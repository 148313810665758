import { useCallback } from 'react'

import { setCrudSelection, useListApi, useResetEditingState } from '@/atoms'
import { updateVehicle, createVehicle } from '@/features/domain/vehicle'
import { useIsUnmounted, useNotification } from '@/hooks'
import { store, useAppDispatch } from '@/store'

import { patchRmData } from '../../utils/patchRmData'

export function useOnSubmit(uv: uui.domain.client.UnifiedVehicle, creating?: boolean) {
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const isUnmounted = useIsUnmounted()
  const stopEditing = useResetEditingState()
  const { scrollTo } = useListApi('unifiedVehicles')

  return useCallback(
    async (formValues: uui.domain.ui.forms.RmVehicleFormValues) => {
      const patchedVehicleAndSummary = patchRmData(uv, formValues)
      try {
        if (creating) {
          const result = await dispatch(createVehicle(patchedVehicleAndSummary.unifiedVehicle))

          if (!createVehicle.fulfilled.match(result)) {
            throw new Error(result.payload?.message ?? 'Internal error')
          }

          if (isUnmounted()) return

          // Since the store is modified directly by the call we must use getState to retrieve updated data
          const { vehiclesByVehicleId } = store.getState().domain.publicData.domain
          const vehicleIds = result.payload.map(vehicleId => vehiclesByVehicleId[vehicleId])

          setCrudSelection('unifiedVehicles', vehicleIds)

          scrollTo(result.payload[0])
        } else {
          const result = await dispatch(updateVehicle(patchedVehicleAndSummary))

          if (!updateVehicle.fulfilled.match(result)) {
            throw new Error(result.payload?.message ?? 'Internal error')
          }
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [uv, dispatch, scrollTo, stopEditing, toast, creating, isUnmounted],
  )
}
