import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    reset: translate({ id: 'global.reset' }),
    showAll: translate({ id: 'global.showAll' }),
    hideAll: translate({ id: 'global.hideAll' }),
    done: translate({ id: 'global.done' }),
    columnsGroup: {
      loads: translate({ id: 'ordersGrid.columnsPanel.columnsGroup.loads' }),
      customFields: translate({ id: 'ordersGrid.columnsPanel.columnsGroup.customFields' }),
      notifications: translate({ id: 'ordersGrid.columnsPanel.columnsGroup.notifications' }),
    },
  }))

  return api
}
