import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    corneringIntensity: translate({ id: 'gps.eventslist.view.corneringIntensity' }),
    breakingIntensity: translate({ id: 'gps.eventslist.view.breakingIntensity' }),
    accelerationIntensity: translate({ id: 'gps.eventslist.view.accelerationIntensity' }),
    maxSpeedLimit: translate({ id: 'gps.eventslist.view.maxSpeedLimit' }),
    postedSpeedLimit: translate({ id: 'gps.eventslist.view.postedSpeedLimit' }),
    gals: (value: number) => `${value} gals`,
  }))

  return api
}
