import { Text } from '@/local/components'
import { VerticalLayout } from '@/components/layout'

interface Props {
  depot: uui.domain.client.rm.Depot
}

export function Single(props: Props) {
  const { depot } = props

  return (
    <VerticalLayout justifyContent="space-between">
      <Text size="$p2" weight="$semiBold">
        {depot.name}
      </Text>
      <Text size="$p3">{depot.location.address}</Text>
    </VerticalLayout>
  )
}
