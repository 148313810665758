import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    openSupportTicket: translate({ id: 'support.ticket.title' }),
    supportCenter: translate({ id: 'app.header.supportCenter' }),
    giveFeedback: translate({ id: 'app.header.feedback' }),
    help: translate({ id: 'app.header.help' }),
    disabledForBeta: translate({
      id: 'global.disabledForBeta',
    }),
  }))

  return api
}
