import type { AsyncThunkApiConfig } from '@/store'
import type { RejectError } from '../../typings'

import { createAsyncThunk } from '@reduxjs/toolkit'

/**
 * Cancel the creation of a "local" driver assignment exception
 */
export const cancelCreateLocalDriverAssignment = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  string,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/driverAssignment/cancelCreateLocal', async (recurrence, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const result = await rpc('rpc/driverAssignment/cancelCreateLocal', {
      category: 'rpc',
      type: 'rpc/driverAssignment/cancelCreateLocal',
      recurrence: recurrence,
    })
    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {cancelCreateLocalDriverAssignment}`,
      { tags: ['rpc', 'driverAssignments'], info: recurrence },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Cancel creation of driverAssignments failed`,
      error,
    })
  }
})
