import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    reasonsTitle: translate({ id: 'setup.reasonsForNotCompletedOrders.view.reasonsTitle' }),
    reasonsSubtitle: (count: number) =>
      count > 1
        ? translate({
            id: 'setup.reasonsForNotCompletedOrders.view.reasonsSubtitle',
            values: { count },
          })
        : count === 1
        ? translate({ id: 'setup.reasonsForNotCompletedOrders.view.reasonSubtitle' })
        : translate({ id: 'setup.reasonsForNotCompletedOrders.view.noReasons' }),
  }))

  return api
}
