import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Text } from '@/local/components'
import { selectApprovedVersionRoutes } from '@/features/domain/scheduler'
import { VerticalLayout, HorizontalLayout } from '@/components/layout'
import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'

import { Delta } from './Delta'

import { useTexts } from './useTexts'

interface Props {
  routeId: string
  orderStepId: string
  stepStartSec: number
}

export function PlannedEtaBlock(props: Props) {
  const { routeId, orderStepId, stepStartSec } = props

  const texts = useTexts()
  const routes = useSelector(selectApprovedVersionRoutes)

  const plannedArrival = useMemo(() => {
    return routes[routeId]?.steps.find(s => s.id === orderStepId)?.startSec
  }, [routeId, orderStepId, routes])

  return (
    <VerticalLayout width="auto" justifyContent="space-between" data-testid="PlannedEtaBlock">
      <HorizontalLayout height="auto">
        <Text size="$p3">{`${texts.plannedTime}:`}&nbsp;</Text>

        <Text size="$p3" weight="$semiBold">
          {plannedArrival ? (
            <ConvertTime seconds={plannedArrival} showSeconds={false} />
          ) : (
            texts.none
          )}
        </Text>
      </HorizontalLayout>

      <HorizontalLayout height="auto">
        <Text size="$p3">{`${texts.eta}:`}&nbsp;</Text>

        <Text size="$p3" weight="$semiBold">
          <ConvertTime seconds={stepStartSec} showSeconds={false} />
          &nbsp;
        </Text>

        <Delta planned={plannedArrival || stepStartSec} estimated={stepStartSec} />
      </HorizontalLayout>
    </VerticalLayout>
  )
}
