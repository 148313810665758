import styled from 'styled-components'

export const ReadonlyFieldArrow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 100%;
  padding-left: 12px;
  padding-right: 12px;
  background: ${p => p.theme.colors.$alabaster};
  border-radius: 6px 0px 0px 6px;

  &:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-left-color: ${p => p.theme.colors.$alabaster};
    border-width: 13px;
    margin-top: -13px;
  }
`

ReadonlyFieldArrow.displayName = 'ReadonlyFieldArrow'
