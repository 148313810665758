import { useRef, type ReactNode } from 'react'
import { Paper, IconButton, Tabs, Tab as MuiTab } from '@mui/material'

import { Scrollbar } from '@workwave-tidal/tidal/components'

import { Text } from '@/local/components'
import { DraggableLayer } from '@/components/DraggableLayer'
import { useToggleTesterModal } from '@/hooks'
import { Cross } from '@/icons'

import { useStyles } from '../hooks/useStyles'
import { type Tab } from '../TesterModal'

interface Props {
  options: {
    id: string
    label: string
    selected: boolean
    disabled: boolean
  }[]
  tab: Tab
  setTab: (tab: Tab) => void
  children?: ReactNode
}

export function Body(props: Props) {
  const { children, options, setTab, tab } = props

  const ref = useRef<null | HTMLDivElement>(null)
  const classes = useStyles()
  const { isTesterModalOpen, toggleTesterModal } = useToggleTesterModal()

  return (
    <div className={classes.wrapper} ref={ref}>
      {isTesterModalOpen ? (
        <DraggableLayer
          container={ref.current}
          render={onMouseDown => (
            <Paper className={classes.container} elevation={10}>
              <div className={classes.header} onMouseDown={onMouseDown}>
                <Text size="$h1" color="$pureWhite" weight="$semiBold">
                  Testers
                </Text>
                <IconButton onClick={toggleTesterModal}>
                  <Cross size={14} color="$pureWhite" />
                </IconButton>
              </div>

              <div className={classes.content}>
                <Tabs
                  value={tab}
                  onChange={(_e, value) => setTab(value as Tab)}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {options.map(option => (
                    <MuiTab key={option.id} label={option.label} value={option.id} />
                  ))}
                </Tabs>

                <div style={{ width: '100%', height: '33vh' }}>
                  <Scrollbar>{children}</Scrollbar>
                </div>
              </div>
            </Paper>
          )}
        />
      ) : null}
    </div>
  )
}
