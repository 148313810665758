import { Box, styled } from '@mui/material'

import { theme } from '@/styles'

import { applyAlphaToColor } from '@/local/components'

export const ListOverlayEditCover = styled(Box, { name: 'ListOverlayEditCover' })({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 10,
  background: applyAlphaToColor(theme.colors.$pureWhite, 0.85),
})
