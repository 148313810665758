import type { FormFields, FormErrors } from '../../../hooks/formFields'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useFormApi, useFormField } from '@workwave-tidal/tidal/form-fairy'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'

/**
 * Fixes form's fields visibility based on the actual data
 */
export function useFormEffects() {
  const { minDate } = useSelector(selectCalendarRange)
  const { today } = useSelector(selectUserConfiguration)

  const api = useFormApi<FormFields, FormErrors>()
  const {
    field: { value: trackingType },
  } = useFormField<'type', FormFields, FormErrors>('type')

  useEffect(() => {
    const live = trackingType === 'live'

    // type
    api.setFieldDisabledState('type', minDate !== today)
    api.setFieldVisibleState('type', minDate === today)

    // timeRangeStart
    api.setFieldDisabledState('timeRangeStart', live)
    api.setFieldVisibleState('timeRangeStart', !live)

    // timeRangeEnd
    api.setFieldDisabledState('timeRangeEnd', live)
    api.setFieldVisibleState('timeRangeEnd', !live)
  }, [api, minDate, today, trackingType])
}
