import { getMap } from '../../atoms/map/utils/getMap'
import { getInteractionMetadata } from '../interactionMetadata'

export function setEditingInteractionMode(status: 'enabled' | 'disabled') {
  const active = status === 'enabled'

  getMap()
    .getInteractions()
    .forEach(int => {
      // affect only custom RM controlled interactions
      if (getInteractionMetadata(int, 'rmInteraction')) {
        // affect only relevant interactions
        if (
          getInteractionMetadata(int, 'uid') === 'rm-mouse-single-click-interaction' ||
          getInteractionMetadata(int, 'uid') === 'rm-mouse-double-click-interaction' ||
          getInteractionMetadata(int, 'uid') === 'rm-mouse-move-interaction'
        ) {
          // update active state
          int.setActive(active)
        }
      }
    })
}
