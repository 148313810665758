import type { CSSProperties } from 'react'

import { Box } from '@mui/material'

import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { H4, WarningCard } from '@/local/components'
import { Dropdown } from '@/components/Dropdown'
import { WarningTriangle } from '@/icons'

import { useTerritoryDropdownItems } from '../hooks/useTerritoryDropdownItems'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

const layoutStyle: CSSProperties = { minHeight: 200, width: '100%' }

export function Body() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const {
    invalid,
    data: { territories, id },
  } = useController()

  const dropdownItems = useTerritoryDropdownItems(territories, id)

  return (
    <VerticalLayout marginTop={32} style={layoutStyle}>
      <Box mb={2} width="100%">
        <H4 color="$paleSky" uppercase>
          {texts.territoryDropdownTitle}
        </H4>
      </Box>

      <Box mb={4} width="100%">
        <HorizontalLayout alignItems="center">
          <Grow xAxis>
            <Dropdown
              options={dropdownItems}
              onChange={handlers.onTerritoryChange}
              disabled={!dropdownItems || invalid}
              maxListHeight={180}
            />
          </Grow>
        </HorizontalLayout>
      </Box>

      <Box width="100%">
        <WarningCard
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
          description={texts.replaceWarningCardDescription}
          title={texts.replaceWarningCardTitle}
          preset="warning"
        />
      </Box>
    </VerticalLayout>
  )
}
