import { ContextProvider } from './ContextProvider'
import { Companies } from './Companies'

export function Root() {
  return (
    <ContextProvider>
      <Companies />
    </ContextProvider>
  )
}
