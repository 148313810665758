import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Location } from 'history'

import { RedirectToHome } from '@/routing'
import { isUserAuthenticated } from '@/features/domain/user'

type Props = {
  to: Location | null
}

/**
 * @private
 */
export function RedirectOnceLogged(props: Props) {
  const { to } = props
  const authenticated = useSelector(isUserAuthenticated)

  if (!authenticated) return null

  return to ? <Navigate to={to.pathname} /> : <RedirectToHome />
}
