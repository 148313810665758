import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useController } from '../../../../../hooks/useController'
import { useControllerActions } from '../../../../../hooks/useControllerActions'
import { useTexts } from '../../../../../useTexts'

export function FormatPicker() {
  const {
    data: { format },
  } = useController()

  const handlers = useControllerActions()

  const texts = useTexts()

  return (
    <FormControl>
      <FormLabel id="format-picker">{texts.format}</FormLabel>
      <RadioGroup
        aria-labelledby="format-picker"
        value={format}
        onChange={handlers.onChangeFormat}
        data-testid="export-route-format-picker"
      >
        <FormControlLabel
          value="CSV"
          control={<Radio data-testid="export-route-format-picker-csv" />}
          label={texts.formatCSV}
        />
        <FormControlLabel
          value="XLSX"
          control={<Radio data-testid="export-route-format-picker-xlsx" />}
          label={texts.formatXLSX}
        />
      </RadioGroup>
    </FormControl>
  )
}
