import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDevicesAggregateInfo } from '@/features/domain/device'

import { useDriversByEmail } from './useDriversByEmail'

interface DeviceInfo {
  id: string
  driverName?: string
  territoryName?: string
  accountUsername?: string
}

export function useDevicesInfo() {
  const devicesAggregateInfo = useSelector(selectDevicesAggregateInfo)
  const driversByEmail = useDriversByEmail()

  return useMemo(() => {
    return Object.values(devicesAggregateInfo).reduce<DeviceInfo[]>((acc, curr) => {
      const device = curr.device
      if (!device.virtual) return acc

      acc.push({
        id: curr.device.deviceId,
        driverName: driversByEmail[device.email ?? '']?.driver.name,
        accountUsername: device.devOnly?.accountUsername ?? undefined,
        territoryName: device.devOnly?.territoryName ?? undefined,
      })
      return acc
    }, [])
  }, [devicesAggregateInfo, driversByEmail])
}
