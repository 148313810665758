import { timeWindowUtils } from '@/utils'

export function parseBreaks(
  breaks: uui.domain.rm.Break[],
  workingDayStartSec: number,
): uui.domain.rm.Break[] {
  return breaks.map(b => {
    const { durationSec } = b

    const timeWindow = timeWindowUtils.normalizeTimeWindow(
      {
        startSec: b.startSec,
        endSec: b.endSec,
      } as const,
      workingDayStartSec,
    )

    const { startSec, endSec } = timeWindowUtils.formatTimeWindowForRPC(
      timeWindow,
      workingDayStartSec,
    )

    return {
      startSec,
      endSec,
      durationSec: durationSec * 60,
    }
  })
}
