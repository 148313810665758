import { Map as MainMap, MapLayer, InspectPinMode, ConnectMapToList } from '@/map'
import { useEditingStateWithCtx } from '@/atoms'

export function Map() {
  const { editing } = useEditingStateWithCtx('order')
  const opacity = editing ? 0.5 : 1

  return (
    <MainMap selectionContext="main" selectionCategory="orderSteps" mapId="ww-map">
      <InspectPinMode mode="converted" />

      <MapLayer uid="order" interactive visible opacity={opacity} />
      <MapLayer uid="depot" interactiveOnUnmount visible opacity={opacity} />
      <MapLayer uid="region" visible opacity={opacity} />
      <MapLayer uid="roadSegment" visible opacity={opacity} />
      <MapLayer uid="geofence" visible={false} />
      <MapLayer uid="routePolyline" visible opacity={opacity} />
      <MapLayer uid="place" visible opacity={opacity} />

      <ConnectMapToList category="orderSteps" />

      {/* TODO: Add remaining layers once they are implemented */}
    </MainMap>
  )
}
