import { type FormRenderProps, Form as FinalForm } from 'react-final-form'

// import { Decorator } from 'final-form'
import { ReadonlyLabel, SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { useResetEditingState, useEnvironmentQa } from '@/atoms'
import { useReadOnly, useStopEditingOnUnmount } from '@/hooks'
import { FormLoading } from '@/components/FormLoading'
import { FormColumn } from '@/components/layout'

import { useCountRemainingTrackedVehicle } from '../../../../hooks/useCountRemainingTrackedVehicle'
import { useFormViewType } from '../../../../hooks/useFormViewType'
import { Tabs } from '../../../../components/Tabs'

import { useLockedNames } from '../hooks/useLockedNames'
import { Footer } from '../components/Footer'

import { useComposeFieldsWithError } from './hooks/useComposeFieldsWithError'
import { useFormInitialValue } from './hooks/useFormInitialValue'
import { useDecorators } from './hooks/useDecorators'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'

import { createValidate } from './createValidate'
import { Form } from './Form'

interface Props {
  trackingProvider: uui.domain.server.gps.TrackingProvider
  transactionOpen: boolean
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  creating?: boolean
}

const labelStyle = { padding: '5px 36px' }

export function SingleEditRm360(props: Props) {
  const { unifiedVehicle, transactionOpen, trackingProvider, creating } = props

  const [initialValues, setInitialValues] = useFormInitialValue(unifiedVehicle)
  const hasReachedTrackingLimit = useCountRemainingTrackedVehicle() < 1
  const composeFieldsWithError = useComposeFieldsWithError()
  const formViewType = useFormViewType(unifiedVehicle)
  const { readonly } = useReadOnly()
  const lockedNames = useLockedNames(unifiedVehicle.unifiedId)

  const decorators = useDecorators(
    unifiedVehicle,
    trackingProvider,
    initialValues,
    setInitialValues,
  )
  const onSubmit = useOnSubmit(unifiedVehicle, creating)
  const onCancel = useResetEditingState()
  const texts = useTexts()
  const qa = useEnvironmentQa()

  useStopEditingOnUnmount()

  const validate = createValidate(lockedNames)

  return (
    <FinalForm<uui.domain.ui.forms.Rm360VehicleFormValues>
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      decorators={decorators}
      keepDirtyOnReinitialize
      render={(formRenderProps: FormRenderProps<uui.domain.ui.forms.Rm360VehicleFormValues>) => {
        const { handleSubmit, submitting } = formRenderProps

        const formState = formRenderProps.form.getState()
        const { valid, pristine, errors, values } = formState
        const disableTrackingCheckbox = hasReachedTrackingLimit && !initialValues.rm.eventTracking

        const saveDisabled = readonly || !valid || (creating ? false : pristine)

        const fieldsWithError = composeFieldsWithError(
          errors as uui.domain.ui.forms.Rm360VehicleFormValidationValues,
        )

        return (
          <FormColumn width={484} testid="form__column-secondary">
            <SecondaryColumnHeader
              title={texts.headerTitle}
              description={texts.headerDescription}
              avatar={null}
            >
              <Tabs unifiedVehicle={unifiedVehicle} formViewType={formViewType} disabled />
            </SecondaryColumnHeader>
            <FormContent
              qa={qa}
              testId="vehicleGeneralSettingsRm360SingleEdit"
              footer={
                transactionOpen ? (
                  <Footer
                    fieldsWithError={fieldsWithError}
                    handleSubmit={handleSubmit}
                    saveDisabled={saveDisabled}
                    submitting={submitting}
                    readonly={readonly}
                    creating={!!creating}
                    onCancel={onCancel}
                    pristine={pristine}
                    valid={valid}
                    texts={texts}
                    submitBtnClassName={
                      creating
                        ? 'o-pendo__vehicle-form__create-vehicle'
                        : 'o-pendo__vehicle-form__update-vehicle'
                    }
                  />
                ) : undefined
              }
            >
              {transactionOpen ? (
                <>
                  <Form
                    disableTrackingCheckbox={disableTrackingCheckbox}
                    trackingProvider={trackingProvider}
                    unifiedVehicle={unifiedVehicle}
                    initialValues={initialValues as uui.domain.ui.forms.Rm360VehicleFormValues}
                    values={values as uui.domain.ui.forms.Rm360VehicleFormValues}
                  />
                  {creating && trackingProvider === 'WWGPS' && (
                    <ReadonlyLabel primary style={labelStyle}>
                      {texts.create}
                    </ReadonlyLabel>
                  )}
                </>
              ) : (
                <FormLoading />
              )}
            </FormContent>
          </FormColumn>
        )
      }}
    />
  )
}
