import { Icon, Style } from 'ol/style'
import spriteSheetUrl_1_9 from '@/resources/map-markers/orderLabels_1_9.png'
import spriteSheetUrl_10_99 from '@/resources/map-markers/orderLabels_10_99.png'
import spriteSheetUrl_100_249 from '@/resources/map-markers/orderLabels_100_249.png'
import spriteSheetUrl_250_399 from '@/resources/map-markers/orderLabels_250_399.png'
import spriteSheetUrl_400_549 from '@/resources/map-markers/orderLabels_400_549.png'
import spriteSheetUrl_550_699 from '@/resources/map-markers/orderLabels_550_699.png'
import spriteSheetUrl_700_849 from '@/resources/map-markers/orderLabels_700_849.png'
import spriteSheetUrl_850_999 from '@/resources/map-markers/orderLabels_850_999.png'
import spriteSheetUrl_1000_1149 from '@/resources/map-markers/orderLabels_1000_1149.png'
import spriteSheetUrl_1150_1299 from '@/resources/map-markers/orderLabels_1150_1299.png'
import spriteSheetUrl_1300_1449 from '@/resources/map-markers/orderLabels_1300_1449.png'
import spriteSheetUrl_1450_1599 from '@/resources/map-markers/orderLabels_1450_1599.png'
import spriteSheetUrl_special from '@/resources/map-markers/orderLabels_special.png'
import { genericStyles } from '../../../genericStyles'

const orderLabelStylesCache: Map<string, Style> = new Map()

function computeSpriteSheetUrl(sprite) {
  switch (sprite) {
    case '1_9':
      return spriteSheetUrl_1_9
    case '10_99':
      return spriteSheetUrl_10_99
    case '100_249':
      return spriteSheetUrl_100_249
    case '250_399':
      return spriteSheetUrl_250_399
    case '400_549':
      return spriteSheetUrl_400_549
    case '550_699':
      return spriteSheetUrl_550_699
    case '700_849':
      return spriteSheetUrl_700_849
    case '850_999':
      return spriteSheetUrl_850_999
    case '1000_1149':
      return spriteSheetUrl_1000_1149
    case '1150_1299':
      return spriteSheetUrl_1150_1299
    case '1300_1449':
      return spriteSheetUrl_1300_1449
    case '1450_1599':
      return spriteSheetUrl_1450_1599
    case 'special':
      return spriteSheetUrl_special
    default:
      throw new Error(`Unknown sprite: ${sprite}`)
  }
}

export function getMinimizedLabelStyle(
  mainGridPlacement: uui.domain.ui.map.markers.MultiSpriteGridPlacement,
  mode: uui.domain.ui.map.markers.MapStyles['order']['mode'],
  color: 'white' | 'black',
  selected: boolean,
) {
  if (mode !== 'on') return genericStyles.hidden
  const [x, y, cellSize, sprite] = mainGridPlacement

  const cacheId = `${x}_${y}_${sprite}_${selected ? 'selected' : 'not-selected'}_${color}`

  if (orderLabelStylesCache.has(cacheId)) {
    return orderLabelStylesCache.get(cacheId)
  }

  const spriteSheetUrl = computeSpriteSheetUrl(sprite)

  const style = new Style({
    zIndex: selected ? 2 : undefined,
    image: new Icon({
      src: spriteSheetUrl,
      color: color === 'black' ? 'rgba(0, 0, 0, 255)' : undefined,
      crossOrigin: 'anonymous',

      scale: 0.3,
      opacity: 1.0,
      rotation: 0.0,
      rotateWithView: true,

      size: [cellSize, cellSize],
      offset: [x, y],

      anchor: [0.5, 0.5],
      displacement: [0.2, 0],

      anchorOrigin: 'top-left',
      anchorYUnits: 'fraction',
      anchorXUnits: 'fraction',
    }),
  })

  orderLabelStylesCache.set(cacheId, style)

  return style
}
