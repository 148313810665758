import { useCallback, useMemo } from 'react'
import { Divider, ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material'
import { Check } from '@mui/icons-material'

import { SortAscending, SortDescending } from '@/icons'
import { ListHeaderSorter } from '@/components/List'

import { useTexts } from '../../useTexts'
import { useSorter } from './hooks/useSorter'

export function Sorter() {
  const { field, setField, direction, editing, setDirection, noEntries } = useSorter()
  const texts = useTexts()

  const triggerText = useMemo(
    () => `${texts.sortBy(field === 'name' ? texts.sortByName : texts.sortByRunningDevices)}`,
    [texts, field],
  )

  const onSortByName = useCallback(() => {
    setField('name')
  }, [setField])

  const onSortByRunningDevices = useCallback(() => {
    setField('ui.sort.runningDevices')
  }, [setField])

  const onSortAscending = useCallback(() => {
    setDirection('asc')
  }, [setDirection])

  const onSortDescending = useCallback(() => {
    setDirection('desc')
  }, [setDirection])

  if (noEntries) return null

  return (
    <ListHeaderSorter triggerText={triggerText} disabled={editing}>
      <MenuItem
        onClick={onSortByName}
        data-testid="list-header-sorter-sortby-name"
        data-trackid="list-header-sorter-sortby-name"
      >
        {field === 'name' && (
          <ListItemIcon>
            <Check color="primary" />
          </ListItemIcon>
        )}
        <ListItemText inset={field !== 'name'} primary={texts.sortByName} />
      </MenuItem>

      <MenuItem
        onClick={onSortByRunningDevices}
        data-testid="list-header-sorter-sortby-runningDevices"
        data-trackid="list-header-sorter-sortby-runningDevices"
      >
        {field === 'ui.sort.runningDevices' && (
          <ListItemIcon>
            <Check color="primary" />
          </ListItemIcon>
        )}
        <ListItemText
          inset={field !== 'ui.sort.runningDevices'}
          primary={texts.sortByRunningDevices}
        />
      </MenuItem>

      <Divider />

      <MenuItem
        onClick={onSortAscending}
        data-testid="list-header-sorter-sortDirection-ascending"
        data-trackid="list-header-sorter-sortDirection-ascending"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          align-items="center"
          width="100%"
          gap={2}
        >
          <Stack direction="row">
            {direction === 'asc' && (
              <ListItemIcon>
                <Check color="primary" />
              </ListItemIcon>
            )}

            <ListItemText inset={direction !== 'asc'} primary={texts.sortOrderAscending} />
          </Stack>

          <Stack justifyContent="center" color="action.active">
            <SortAscending size={20} />
          </Stack>
        </Stack>
      </MenuItem>

      <MenuItem
        onClick={onSortDescending}
        data-testid="list-header-sorter-sortDirection-descending"
        data-trackid="list-header-sorter-sortDirection-descending"
      >
        <Stack
          justifyContent="space-between"
          align-items="center"
          direction="row"
          width="100%"
          gap={2}
        >
          <Stack direction="row" color="light">
            {direction === 'desc' && (
              <ListItemIcon>
                <Check color="primary" />
              </ListItemIcon>
            )}

            <ListItemText inset={direction !== 'desc'} primary={texts.sortOrderDescending} />
          </Stack>

          <Stack justifyContent="center" color="action.active">
            <SortDescending size={20} />
          </Stack>
        </Stack>
      </MenuItem>
    </ListHeaderSorter>
  )
}
