import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { FormWrapper } from './FormWrapper'

export function Root() {
  return (
    <Modal modalId={modalId}>
      <FormWrapper />
    </Modal>
  )
}
