import type { VoiceEventMarker } from './voiceEventMarker'

import { snapshot, subscribe } from 'valtio'

import { isDeepEqual } from '@/server-data'
import { addVoiceEventFeature } from '../../layers/voiceEvents/features/voiceEvent/addVoiceEventFeature'
import { updateVoiceEventFeature } from '../../layers/voiceEvents/features/voiceEvent/updateVoiceEventFeature'
import { removeVoiceEventFeature } from '../../layers/voiceEvents/features/voiceEvent/removeVoiceEventFeature'

import { voiceEventMarker } from './voiceEventMarker'

export function connectMapToVoiceEvents() {
  // ---------------------------------------------------------------------
  // Voice Events
  // ---------------------------------------------------------------------

  let prevVoiceEvent: workwave.DeepReadonly<VoiceEventMarker | null> = null
  const unsubscribeFromVoiceEvents = subscribe(voiceEventMarker, () => {
    const { event } = snapshot(voiceEventMarker)

    if (isDeepEqual(event, prevVoiceEvent)) return

    if (!prevVoiceEvent && event) {
      // add
      addVoiceEventFeature(event)
    }

    if (prevVoiceEvent && event) {
      // update
      updateVoiceEventFeature(event)
    }

    if (prevVoiceEvent && !event) {
      // remove
      removeVoiceEventFeature(prevVoiceEvent.id)
    }

    prevVoiceEvent = event
  })

  return () => {
    unsubscribeFromVoiceEvents()
  }
}
