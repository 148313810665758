import type Map from 'ol/Map'
import type { FitOptions } from 'ol/View'
import type { Extent } from 'ol/extent'
import type Geometry from 'ol/geom/Geometry'

import Circle from 'ol/geom/Circle'
import VectorLayer from 'ol/layer/Vector'
import VectorImageLayer from 'ol/layer/VectorImage'

import * as olExtent from 'ol/extent'
import { easeOut } from 'ol/easing'
import Feature from 'ol/Feature'

import { gis } from '@/server-data'
import { isMapLocked, getMap } from '@/map'
import {
  getCrudSelection,
  getMainSelection,
  MainSelection,
  MainSelectionCategory,
  CrudSelection,
  CrudSelectionCategory,
} from '@/atoms'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { getMapSelectionContext } from '../../selection/read/getMapSelectionContext'
import { getDomainMarkerData } from '../../markerChangelog/domain/getDomainMarkerData'
import { getFeatureMetadata } from '../../../layers/featureMetadata'
import { getOrderFeatureMetadata } from '../../../layers/order/orderFeatureMetadata'
import { resetPendingFitMap, setPendingFitMap } from '../../pendingFitMap'

export interface FitMapOptions extends FitOptions {
  preventIfVisible?: boolean
  forceFit?: boolean
}

export type FitMapToSelection = (preventIfVisible?: boolean) => void
export type PendingFitMapMode = 'none' | 'add' | 'clear'

const nonblockingRender = createNonBlockingRenderByTime()

function canAnimate(
  map: Map,
  ext1: olExtent.Extent,
  ext2: olExtent.Extent,
  thresholdDistance: number = 1500,
) {
  const prevCenter = olExtent.getCenter(ext1)
  const nextCenter = olExtent.getCenter(ext2)

  if (!prevCenter || !nextCenter || prevCenter.length !== 2 || nextCenter.length !== 2) {
    throw new Error('not valid extent')
  }

  const p1 = gis.fromLatLngToMapPixel(map, gis.fromCoordinateToLatLng(prevCenter))
  const p2 = gis.fromLatLngToMapPixel(map, gis.fromCoordinateToLatLng(nextCenter))

  const distanceInPixels = Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2))

  return distanceInPixels < thresholdDistance
}

export const fitMapToGeometry = (
  extent: Extent,
  options?: FitMapOptions,
  pendingFitMap: PendingFitMapMode = 'clear',
) => {
  switch (pendingFitMap) {
    case 'add':
      setPendingFitMap(olExtent.clone(extent))
      break

    case 'clear':
      resetPendingFitMap()
      break
  }

  const { preventIfVisible, forceFit, ...opts } = options ?? {}

  // unless forced avoid to change the view
  const map = getMap()
  if (
    preventIfVisible &&
    olExtent.containsExtent(map.getView()?.calculateExtent(map.getSize()), extent)
  ) {
    return
  }

  if (!olExtent.isEmpty(extent)) {
    // if not specified apply a minimum padding of 50px
    const padding = opts.padding ?? [50, 50, 50, 50]

    // if not specified apply a minimum resolution of
    const minResolution = opts.minResolution ?? 8

    const view = map.getView()
    const viewportExtent = view.calculateExtent(map.getSize())

    // If the map can contain all the selected feature with the actual zoom, we can just set the
    // center without doing a fit.
    const [viewportWidth, viewportHeight] = olExtent.getSize(viewportExtent)
    const [extendWidth, extentHeight] = olExtent.getSize(extent)

    if (!forceFit && viewportWidth >= extendWidth && viewportHeight >= extentHeight) {
      if (canAnimate(map, viewportExtent, extent)) {
        view.animate({
          center: olExtent.getCenter(extent),
          duration: 450,
          easing: easeOut,
        })
      } else {
        view.setCenter(olExtent.getCenter(extent))
      }
    } else {
      // fit the map
      view.fit(extent, { ...opts, padding, minResolution })
    }
  }
}

export const fitMap = async (
  latLngArray: uui.domain.LatLng[],
  options?: FitMapOptions,
  pendingFitMap: PendingFitMapMode = 'clear',
) => {
  if (isMapLocked()) return

  switch (pendingFitMap) {
    case 'add':
      setPendingFitMap(latLngArray)
      break

    case 'clear':
      resetPendingFitMap()
      break
  }

  const { preventIfVisible, ...opts } = options ?? {}
  const extent = olExtent.createEmpty()

  const coordinates = latLngArray.map(latLng => gis.fromLatLngToCoordinate(latLng))
  olExtent.extend(extent, olExtent.boundingExtent(coordinates))

  // unless forced avoid to change the view
  const map = getMap()
  if (
    preventIfVisible &&
    olExtent.containsExtent(map.getView()?.calculateExtent(map.getSize()), extent)
  ) {
    return
  }

  if (!olExtent.isEmpty(extent)) {
    // if not specified apply a minimum padding of 50px
    const padding = opts.padding ?? [50, 50, 50, 50]

    // if not specified apply a minimum resolution of
    const minResolution = opts.minResolution ?? 8

    // fit the map
    getMap()
      .getView()
      ?.fit(extent, { ...opts, padding, minResolution })
  }
}

export const fitMapToSelection = async (
  options?: FitMapOptions,
  pendingFitMap: PendingFitMapMode = 'clear',
) => {
  if (isMapLocked()) return

  const mapSelectionContext = getMapSelectionContext()

  if (mapSelectionContext.type === 'none') return

  const { preventIfVisible, forceFit = false, ...opts } = options ?? {}

  const selectionExtent = olExtent.createEmpty()
  const { mapMarkers, mapStyles, extendedOrderSteps } = getDomainMarkerData()

  let selectionEmpty = true

  if (mapSelectionContext.type === 'main') {
    const selection = getMainSelection()

    for (const category of Object.keys(selection)) {
      if (selection[category].length !== 0) {
        selectionEmpty = false
      }

      await nonblockingRender.next()

      const categoryExtent = await getMainSelectionExtent(
        category as MainSelectionCategory,
        selection,
        mapMarkers,
        mapStyles,
        extendedOrderSteps,
      )

      olExtent.extend(selectionExtent, categoryExtent)
    }
  }

  if (mapSelectionContext.type === 'crud') {
    const selection = getCrudSelection(true)

    if (selection[mapSelectionContext.category].length !== 0) {
      selectionEmpty = false
    }

    await nonblockingRender.next()

    const categoryExtent = await getCrudSelectionExtent(
      mapSelectionContext.category,
      selection,
      mapMarkers,
      mapStyles,
    )

    olExtent.extend(selectionExtent, categoryExtent)
  }

  // if nothing is selected fit to all visible items
  if (selectionEmpty) {
    const layers = getMap().getLayers().getArray()

    const crudSelection = getCrudSelection(true)

    for (const layer of layers) {
      if (!(layer instanceof VectorLayer) && !(layer instanceof VectorImageLayer)) continue

      nonblockingRender.reset()

      const layerFeatures = layer.getSource()?.getFeatures()

      for (const feature of layerFeatures) {
        await nonblockingRender.next()

        const featureExtent = getFeatureExtent(
          mapMarkers,
          mapStyles,
          extendedOrderSteps,
          feature,
          crudSelection,
        )

        if (featureExtent) {
          olExtent.extend(selectionExtent, featureExtent)
        }
      }
    }
  } else {
    // If selection is not empty but the extent is empty
    if (!olExtent.isEmpty(selectionExtent)) {
      if (pendingFitMap === 'add') {
        // add to pendingFitMap only explicit selections
        setPendingFitMap(olExtent.clone(selectionExtent))
      }
    }
  }

  if (pendingFitMap === 'clear') {
    resetPendingFitMap()
  }

  // unless forced avoid to change the view
  const map = getMap()
  const view = map.getView()
  if (
    preventIfVisible &&
    olExtent.containsExtent(view.calculateExtent(map.getSize()), selectionExtent)
  ) {
    return
  }

  if (!olExtent.isEmpty(selectionExtent)) {
    // if not specified apply a minimum padding of 50px
    const padding = opts.padding ?? [50, 50, 50, 50]

    // if not specified apply a minimum resolution of
    const minResolution = opts.minResolution ?? 8

    const viewportExtent = view.calculateExtent(map.getSize())

    // If the map can contain all the selected feature with the actual zoom, we can just set the
    // center without doing a fit.
    const [viewportWidth, viewportHeight] = olExtent.getSize(viewportExtent)
    const [extendWidth, extentHeight] = olExtent.getSize(selectionExtent)

    if (!forceFit && viewportWidth >= extendWidth && viewportHeight >= extentHeight) {
      if (canAnimate(map, viewportExtent, selectionExtent)) {
        view.animate({
          center: olExtent.getCenter(selectionExtent),
          duration: 450,
          easing: easeOut,
        })
      } else {
        view.setCenter(olExtent.getCenter(selectionExtent))
      }
    } else {
      // fit the map
      view.fit(selectionExtent, { ...opts, padding, minResolution })
    }
  }
}

export const fitMapToOrdersAndDepots = async (
  territoryCenter: uui.domain.LatLng,
  options?: FitMapOptions,
) => {
  if (isMapLocked()) return

  const { preventIfVisible, ...opts } = options ?? {}
  const { mapMarkers, mapStyles, extendedOrderSteps } = getDomainMarkerData()
  const extent = olExtent.createEmpty()

  olExtent.extend(
    extent,
    await getOrderMarkerExtent(
      mapMarkers.order,
      mapStyles.order,
      extendedOrderSteps,
      Object.keys(extendedOrderSteps),
    ),
  )

  olExtent.extend(
    extent,
    await getDepotMarkerExtent(mapMarkers.depot, mapStyles.depot, Object.keys(mapMarkers.depot)),
  )

  // unless forced avoid to change the view
  const map = getMap()

  if (olExtent.isEmpty(extent)) {
    map.getView().setCenter(gis.fromLatLngToCoordinate(territoryCenter))
    return
  }

  if (
    preventIfVisible &&
    olExtent.containsExtent(map.getView()?.calculateExtent(map.getSize()), extent)
  ) {
    return
  }

  if (!olExtent.isEmpty(extent)) {
    // if not specified apply a minimum padding of 50px
    const padding = opts.padding ?? [50, 50, 50, 50]

    // if not specified apply a minimum resolution of
    const minResolution = opts.minResolution ?? 8

    // fit the map
    getMap()
      .getView()
      ?.fit(extent, { ...opts, padding, minResolution })
  }
}

// ----------------------------------------------------------------------
// get extent by marker
// ----------------------------------------------------------------------

const mainSelectionNonblockingRender = createNonBlockingRenderByTime()

const getMainSelectionExtent = async (
  category: MainSelectionCategory,
  selection: workwave.DeepReadonly<MainSelection>,
  mapMarkers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
) => {
  const mainSelectionExtent = olExtent.createEmpty()

  switch (category) {
    case 'orderSteps': {
      const categoryExtents = await getOrderMarkerExtent(
        mapMarkers.order,
        mapStyles.order,
        extendedOrderSteps,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'depots': {
      const categoryExtents = await getDepotMarkerExtent(
        mapMarkers.depot,
        mapStyles.depot,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'places': {
      const categoryExtents = await getPlaceMarkerExtent(
        mapMarkers.place,
        mapStyles.place,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'devices': {
      const categoryExtents = await getDeviceMarkerExtent(
        mapMarkers.device,
        mapStyles.device,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'unifiedVehicles': {
      const categoryExtents = await getVehicleMarkerExtent(
        mapMarkers.vehicle,
        mapStyles.vehicle,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    // TODO: add remaining categories once they are available
  }

  return mainSelectionExtent
}

const getCrudSelectionExtent = async (
  category: CrudSelectionCategory,
  selection: workwave.DeepReadonly<CrudSelection>,
  mapMarkers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
) => {
  const mainSelectionExtent = olExtent.createEmpty()

  switch (category) {
    case 'depots': {
      const categoryExtents = await getDepotMarkerExtent(
        mapMarkers.depot,
        mapStyles.depot,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'regions': {
      const categoryExtents = await getRegionsMarkerExtent(
        mapMarkers.region,
        mapStyles.region,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'trafficArea': {
      const categoryExtents = await getTrafficAreaMarkerExtent(
        mapMarkers.trafficProfile,
        mapStyles.trafficProfile,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'routingSegments': {
      const categoryExtents = await getRoutingSegmentsMarkerExtent(
        mapMarkers.roadSegment,
        mapStyles.roadSegment,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'geofences': {
      const categoryExtents = await getGeofencesMarkerExtent(
        mapMarkers.geofence,
        mapStyles.geofence,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'places': {
      const categoryExtents = await getPlaceMarkerExtent(
        mapMarkers.place,
        mapStyles.place,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'devices': {
      const categoryExtents = await getDeviceMarkerExtent(
        mapMarkers.device,
        mapStyles.device,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'unifiedVehicles': {
      const categoryExtents = await getVehicleMarkerExtent(
        mapMarkers.vehicle,
        mapStyles.vehicle,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }

    case 'deviceEvents': {
      const categoryExtents = await getDeviceEventMarkerExtent(
        mapMarkers.deviceEvent,
        selection[category],
      )

      olExtent.extend(mainSelectionExtent, categoryExtents)
      break
    }
  }

  return mainSelectionExtent
}

const getOrderMarkerExtent = async (
  mapOrderMarkers: uui.domain.ui.map.markers.MapMarkers['order'],
  mapOrderStyles: uui.domain.ui.map.markers.MapStyles['order'],
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
  orderStepIds: workwave.DeepReadonly<string[]>,
) => {
  const categoryExtent = olExtent.createEmpty()

  const mainStyleOn = mapOrderStyles.mode !== 'off'

  mainSelectionNonblockingRender.reset()

  for (const orderId of orderStepIds) {
    await mainSelectionNonblockingRender.next()

    const extOrder = extendedOrderSteps[orderId]

    if (extOrder && !extOrder.atDepot) {
      const marker = mapOrderMarkers[extOrder.location.rawLatLngAsString]

      // skip hidden markers
      if (marker && marker.mode !== 'off' && mainStyleOn) {
        // add the coordinate to the extent
        olExtent.extend(categoryExtent, olExtent.boundingExtent([marker.lonLat]))
      }
    }
  }

  return categoryExtent
}

const getDepotMarkerExtent = async (
  mapDepotMarkers: uui.domain.ui.map.markers.MapMarkers['depot'],
  mapDepotStyles: uui.domain.ui.map.markers.MapStyles['depot'],
  depotIds: workwave.DeepReadonly<string[]>,
) => {
  const categoryExtent = olExtent.createEmpty()

  const mainStyleOn = mapDepotStyles.mode !== 'off'

  mainSelectionNonblockingRender.reset()

  for (const depotId of depotIds) {
    await mainSelectionNonblockingRender.next()

    const marker = mapDepotMarkers[depotId]

    // skip hidden markers
    if (marker && marker.mode !== 'off' && mainStyleOn) {
      // add the coordinate to the extent
      olExtent.extend(categoryExtent, olExtent.boundingExtent([marker.lonLat]))
    }
  }

  return categoryExtent
}

const getRegionsMarkerExtent = async (
  mapRegionMarkers: uui.domain.ui.map.markers.MapMarkers['region'],
  mapRegionStyles: uui.domain.ui.map.markers.MapStyles['region'],
  regionIds: workwave.DeepReadonly<string[]>,
) => {
  const categoryExtent = olExtent.createEmpty()

  const mainStyleOn = mapRegionStyles.mode !== 'off'

  mainSelectionNonblockingRender.reset()

  for (const regionId of regionIds) {
    await mainSelectionNonblockingRender.next()

    const marker = mapRegionMarkers[regionId]

    // skip hidden markers
    if (marker && marker.mode !== 'off' && mainStyleOn) {
      // add the coordinate to the extent
      olExtent.extend(categoryExtent, olExtent.boundingExtent(marker.points))
    }
  }

  return categoryExtent
}

const getTrafficAreaMarkerExtent = async (
  mapTrafficAreaMarkers: uui.domain.ui.map.markers.MapMarkers['trafficProfile'],
  _: uui.domain.ui.map.markers.MapStyles['trafficProfile'],
  areaIds: workwave.DeepReadonly<string[]>,
) => {
  const categoryExtent = olExtent.createEmpty()

  mainSelectionNonblockingRender.reset()

  for (const trafficProfileId in mapTrafficAreaMarkers) {
    for (const areaId of areaIds) {
      if (!mapTrafficAreaMarkers[trafficProfileId][areaId]) continue

      await mainSelectionNonblockingRender.next()

      const marker = mapTrafficAreaMarkers[trafficProfileId][areaId]

      // main style will be always off for traffic
      // we have to check only the custom
      if (marker && marker.mode === 'on') {
        // add the coordinate to the extent
        olExtent.extend(categoryExtent, olExtent.boundingExtent(marker.points))
      }
    }
  }

  return categoryExtent
}

const getRoutingSegmentsMarkerExtent = async (
  mapRoutingSegmentMarkers: uui.domain.ui.map.markers.MapMarkers['roadSegment'],
  mapRoutingSegmentStyles: uui.domain.ui.map.markers.MapStyles['roadSegment'],
  routingSegmentIds: workwave.DeepReadonly<string[]>,
) => {
  const categoryExtent = olExtent.createEmpty()

  const mainStyleOn = mapRoutingSegmentStyles.mode !== 'off'

  mainSelectionNonblockingRender.reset()

  for (const routingProfileId in mapRoutingSegmentMarkers) {
    for (const routingSegmentId of routingSegmentIds) {
      if (!mapRoutingSegmentMarkers[routingProfileId][routingSegmentId]) continue

      await mainSelectionNonblockingRender.next()

      const marker = mapRoutingSegmentMarkers[routingProfileId][routingSegmentId]

      // skip hidden markers
      if (marker && marker.mode !== 'off' && mainStyleOn) {
        // add the coordinate to the extent
        olExtent.extend(categoryExtent, olExtent.boundingExtent([marker.start, marker.end]))
      }
    }
  }

  return categoryExtent
}

const getGeofencesMarkerExtent = async (
  mapGeofenceMarkers: uui.domain.ui.map.markers.MapMarkers['geofence'],
  mapGeofenceStyles: uui.domain.ui.map.markers.MapStyles['geofence'],
  geofenceIds: workwave.DeepReadonly<string[]>,
) => {
  const categoryExtent = olExtent.createEmpty()

  const mainStyleOn = mapGeofenceStyles.mode !== 'off'

  mainSelectionNonblockingRender.reset()

  for (const geofenceId of geofenceIds) {
    await mainSelectionNonblockingRender.next()

    const marker = mapGeofenceMarkers[geofenceId]

    // skip hidden markers
    if (marker && marker.mode !== 'off' && mainStyleOn) {
      // add the coordinate to the extent

      olExtent.extend(categoryExtent, new Circle(marker.lonLat, marker.radius).getExtent())
    }
  }

  return categoryExtent
}

const getPlaceMarkerExtent = async (
  mapPlaceMarkers: uui.domain.ui.map.markers.MapMarkers['place'],
  mapPlaceStyles: uui.domain.ui.map.markers.MapStyles['place'],
  placeIds: workwave.DeepReadonly<string[]>,
) => {
  const categoryExtent = olExtent.createEmpty()

  mainSelectionNonblockingRender.reset()

  for (const placeId of placeIds) {
    await mainSelectionNonblockingRender.next()

    const marker = mapPlaceMarkers[placeId]

    if (!marker) continue

    const customOn = marker.mode === 'on' && mapPlaceStyles.mode === 'off'
    const mainOn = marker.mode !== 'off' && mapPlaceStyles.mode === 'on'

    const markerVisible = customOn || mainOn

    // skip hidden markers
    if (markerVisible) {
      // add the coordinate to the extent
      olExtent.extend(categoryExtent, olExtent.boundingExtent([marker.lonLat]))
    }
  }

  return categoryExtent
}

const getDeviceMarkerExtent = async (
  mapDeviceMarkers: uui.domain.ui.map.markers.MapMarkers['device'],
  mapDeviceStyles: uui.domain.ui.map.markers.MapStyles['device'],
  deviceIds: workwave.DeepReadonly<string[]>,
) => {
  const categoryExtent = olExtent.createEmpty()

  mainSelectionNonblockingRender.reset()

  for (const deviceId of deviceIds) {
    await mainSelectionNonblockingRender.next()

    const marker = mapDeviceMarkers[deviceId]

    if (!marker) continue

    const customOn =
      (marker.mode === 'on' || marker.mode === 'label') && mapDeviceStyles.mode === 'off'
    const mainOn =
      marker.mode !== 'off' && (mapDeviceStyles.mode === 'on' || mapDeviceStyles.mode === 'label')

    const markerVisible = customOn || mainOn

    // skip hidden markers
    if (markerVisible) {
      // add the coordinate to the extent
      olExtent.extend(categoryExtent, olExtent.boundingExtent([marker.lonLat]))
    }
  }

  return categoryExtent
}

const getVehicleMarkerExtent = async (
  mapVehicleMarkers: uui.domain.ui.map.markers.MapMarkers['vehicle'],
  mapVehicleStyles: uui.domain.ui.map.markers.MapStyles['vehicle'],
  vehicleIds: workwave.DeepReadonly<string[]>,
) => {
  const categoryExtent = olExtent.createEmpty()

  mainSelectionNonblockingRender.reset()

  for (const vehicleId of vehicleIds) {
    await mainSelectionNonblockingRender.next()

    const marker = mapVehicleMarkers[vehicleId]

    if (!marker) continue

    const customOn =
      (marker.mode === 'on' || marker.mode === 'label') && mapVehicleStyles.mode === 'off'
    const mainOn =
      marker.mode !== 'off' && (mapVehicleStyles.mode === 'on' || mapVehicleStyles.mode === 'label')

    const markerVisible = customOn || mainOn

    // skip hidden markers
    if (markerVisible) {
      // add the coordinate to the extent
      olExtent.extend(categoryExtent, olExtent.boundingExtent([marker.lonLat]))
    }
  }

  return categoryExtent
}

const getDeviceEventMarkerExtent = async (
  mapDeviceEventMarkers: uui.domain.ui.map.markers.MapMarkers['deviceEvent'],
  deviceEventIds: workwave.DeepReadonly<string[]>,
) => {
  const categoryExtent = olExtent.createEmpty()

  mainSelectionNonblockingRender.reset()

  for (const deviceEventId of deviceEventIds) {
    await mainSelectionNonblockingRender.next()

    const markerId = deviceEventId.split('@')[0]
    const marker = mapDeviceEventMarkers[markerId]

    if (!marker) continue

    const event = marker.events[deviceEventId]
    if (!event) continue

    // add the coordinate to the extent
    olExtent.extend(categoryExtent, olExtent.boundingExtent([event.lonLat]))
  }

  return categoryExtent
}

// ----------------------------------------------------------------------
// get extent by feature
// ----------------------------------------------------------------------

const getFeatureExtent = (
  mapMarkers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
  feature: Feature<Geometry>,
  crudSelection?: workwave.DeepReadonly<CrudSelection>,
) => {
  const featureType = getFeatureMetadata(feature, 'type')

  switch (featureType) {
    case 'order':
      return getOrderFeatureExtent(mapMarkers.order, mapStyles.order, extendedOrderSteps, feature)

    case 'depot':
      return getDepotFeatureExtent(mapMarkers.depot, mapStyles.depot, feature)

    case 'vehicle':
      return getVehicleFeatureExtent(mapMarkers.vehicle, mapStyles.vehicle, feature)

    case 'routePolyline':
      return getRoutePolylineFeatureExtent(
        mapMarkers.routePolyline,
        mapStyles.routePolyline,
        feature,
      )

    case 'region':
      return getRegionFeatureExtent(mapMarkers.region, mapStyles.region, feature)

    case 'breadcrumbPath':
    case 'breadcrumbStops':
    case 'breadcrumbPoints':
      return getBreadcrumbFeatureExtent(mapMarkers.breadcrumb, mapStyles.breadcrumb, feature)

    case 'device':
      return getDeviceFeatureExtent(mapMarkers.device, mapStyles.device, feature)

    case 'place':
      return getPlaceFeatureExtent(mapMarkers.place, mapStyles.place, feature)

    case 'deviceEvent':
      return getDeviceEventFeatureExtent(
        mapMarkers.deviceEvent,
        mapStyles.deviceEvent,
        feature,
        crudSelection,
      )

    case 'trafficProfile':
      return getTrafficProfileFeatureExtent(
        mapMarkers.trafficProfile,
        mapStyles.trafficProfile,
        feature,
      )

    case 'roadSegment':
      return getRoadSegmentFeatureExtent(mapMarkers.roadSegment, mapStyles.roadSegment, feature)

    case 'geofence':
      return getGeofenceFeatureExtent(mapMarkers.geofence, mapStyles.geofence, feature)
  }
}

const getOrderFeatureExtent = (
  mapOrderMarkers: uui.domain.ui.map.markers.MapMarkers['order'],
  mapOrderStyles: uui.domain.ui.map.markers.MapStyles['order'],
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
  feature: Feature<Geometry>,
) => {
  const orderStepIds = getOrderFeatureMetadata(feature, 'ids')

  if (orderStepIds) {
    for (const orderId of Object.keys(orderStepIds)) {
      const extOrder = extendedOrderSteps[orderId]

      if (extOrder && !extOrder.atDepot) {
        const marker = mapOrderMarkers[extOrder.location.rawLatLngAsString]

        if (!marker) continue

        const customOn =
          (marker.mode === 'on' || marker.mode === 'maximized') && mapOrderStyles.mode === 'off'

        const mainOn =
          marker.mode !== 'off' &&
          (mapOrderStyles.mode === 'on' || mapOrderStyles.mode === 'maximized')

        const markerVisible = customOn || mainOn

        // skip hidden markers
        if (markerVisible) {
          return feature.getGeometry()?.getExtent()
          // return olExtent.boundingExtent([marker.lonLat])
        }
      }
    }
  }
}

const getDepotFeatureExtent = (
  mapDepotMarkers: uui.domain.ui.map.markers.MapMarkers['depot'],
  mapDepotStyles: uui.domain.ui.map.markers.MapStyles['depot'],
  feature: Feature<Geometry>,
) => {
  const depotId = feature.getId()

  if (depotId) {
    const marker = mapDepotMarkers[depotId]

    if (!marker) return

    const customOn =
      (marker.mode === 'on' || marker.mode === 'label') && mapDepotStyles.mode === 'off'
    const mainOn =
      marker.mode !== 'off' && (mapDepotStyles.mode === 'on' || mapDepotStyles.mode === 'label')

    const markerVisible = customOn || mainOn

    // skip hidden markers
    if (markerVisible) {
      return feature.getGeometry()?.getExtent()
    }
  }
}

const getVehicleFeatureExtent = (
  mapVehicleMarkers: uui.domain.ui.map.markers.MapMarkers['vehicle'],
  mapVehicleStyles: uui.domain.ui.map.markers.MapStyles['vehicle'],
  feature: Feature<Geometry>,
) => {
  const vehicleId = feature.getId()

  if (vehicleId) {
    const marker = mapVehicleMarkers[vehicleId]

    if (!marker) return

    const customOn =
      (marker.mode === 'on' || marker.mode === 'label') && mapVehicleStyles.mode === 'off'
    const mainOn =
      marker.mode !== 'off' && (mapVehicleStyles.mode === 'on' || mapVehicleStyles.mode === 'label')

    const markerVisible = customOn || mainOn

    // skip hidden markers
    if (markerVisible) {
      return feature.getGeometry()?.getExtent()
    }
  }
}

const getRoutePolylineFeatureExtent = (
  mapRoutePolylineMarkers: uui.domain.ui.map.markers.MapMarkers['routePolyline'],
  mapRoutePolylineStyles: uui.domain.ui.map.markers.MapStyles['routePolyline'],
  feature: Feature<Geometry>,
) => {
  const routePolylineId = feature.getId()

  if (routePolylineId) {
    const marker = mapRoutePolylineMarkers[routePolylineId]

    if (!marker) return

    const customOn = marker.mode === 'on' && mapRoutePolylineStyles.mode === 'off'
    const mainOn = marker.mode !== 'off' && mapRoutePolylineStyles.mode === 'on'

    const markerVisible = customOn || mainOn

    // skip hidden markers
    if (markerVisible) {
      return feature.getGeometry()?.getExtent()
    }
  }
}

const getRegionFeatureExtent = (
  mapRegionMarkers: uui.domain.ui.map.markers.MapMarkers['region'],
  mapRegionStyles: uui.domain.ui.map.markers.MapStyles['region'],
  feature: Feature<Geometry>,
) => {
  const regionId = feature.getId()

  if (regionId) {
    const marker = mapRegionMarkers[regionId]

    if (!marker) return

    const customOn = marker.mode === 'on' && mapRegionStyles.mode === 'off'
    const mainOn = marker.mode !== 'off' && mapRegionStyles.mode === 'on'

    const markerVisible = customOn || mainOn

    // skip hidden markers
    if (markerVisible) {
      return feature.getGeometry()?.getExtent()
    }
  }
}

const getBreadcrumbFeatureExtent = (
  mapBreadcrumbMarkers: uui.domain.ui.map.markers.MapMarkers['breadcrumb'],
  mapBreadcrumbStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
  feature: Feature<Geometry>,
) => {
  const breadcrumbId = feature.getId()

  if (breadcrumbId) {
    const marker = mapBreadcrumbMarkers[breadcrumbId]

    if (!marker) return

    const customOn = marker.mode === 'on' && mapBreadcrumbStyles.mode === 'off'
    const mainOn = marker.mode !== 'off' && mapBreadcrumbStyles.mode === 'on'

    const markerVisible = customOn || mainOn

    // skip hidden markers
    if (markerVisible) {
      return feature.getGeometry()?.getExtent()
    }
  }
}

const getDeviceFeatureExtent = (
  mapDeviceMarkers: uui.domain.ui.map.markers.MapMarkers['device'],
  mapDeviceStyles: uui.domain.ui.map.markers.MapStyles['device'],
  feature: Feature<Geometry>,
) => {
  const deviceId = feature.getId()

  if (deviceId) {
    const marker = mapDeviceMarkers[deviceId]

    if (!marker) return

    const customOn =
      (marker.mode === 'on' || marker.mode === 'label') && mapDeviceStyles.mode === 'off'
    const mainOn =
      marker.mode !== 'off' && (mapDeviceStyles.mode === 'on' || mapDeviceStyles.mode === 'label')

    const markerVisible = customOn || mainOn

    // skip hidden markers
    if (markerVisible) {
      return feature.getGeometry()?.getExtent()
    }
  }
}

const getPlaceFeatureExtent = (
  mapPlaceMarkers: uui.domain.ui.map.markers.MapMarkers['place'],
  mapPlaceStyles: uui.domain.ui.map.markers.MapStyles['place'],
  feature: Feature<Geometry>,
) => {
  const placeId = feature.getId()

  if (placeId) {
    const marker = mapPlaceMarkers[placeId]

    if (!marker) return

    const customOn = marker.mode === 'on' && mapPlaceStyles.mode === 'off'
    const mainOn = marker.mode !== 'off' && mapPlaceStyles.mode === 'on'

    const markerVisible = customOn || mainOn

    // skip hidden markers
    if (markerVisible) {
      return feature.getGeometry()?.getExtent()
    }
  }
}

const getDeviceEventFeatureExtent = (
  mapDeviceEventMarkers: uui.domain.ui.map.markers.MapMarkers['deviceEvent'],
  mapDeviceEventStyles: uui.domain.ui.map.markers.MapStyles['deviceEvent'],
  feature: Feature<Geometry>,
  crudSelection?: workwave.DeepReadonly<CrudSelection>,
) => {
  const deviceEventId = feature.getId()

  if (deviceEventId) {
    if (typeof deviceEventId === 'number') {
      throw new Error('deviceEventId should be a string')
    }

    const markerId = deviceEventId.split('@')[0]
    const marker = mapDeviceEventMarkers[markerId]

    if (!marker) return

    const customOn = marker.mode === 'on' && mapDeviceEventStyles.mode === 'off'
    const mainOn = marker.mode !== 'off' && mapDeviceEventStyles.mode === 'on'

    const selected = crudSelection?.deviceEvents.includes(markerId)

    const markerVisible = selected && (customOn || mainOn)

    // skip hidden markers
    if (markerVisible) {
      return feature.getGeometry()?.getExtent()
    }
  }
}

const getTrafficProfileFeatureExtent = (
  mapTrafficProfileMarkers: uui.domain.ui.map.markers.MapMarkers['trafficProfile'],
  mapTrafficProfileStyles: uui.domain.ui.map.markers.MapStyles['trafficProfile'],
  feature: Feature<Geometry>,
) => {
  const trafficProfileId = feature.getId()

  if (trafficProfileId) {
    for (const recurrence in mapTrafficProfileMarkers) {
      const marker = mapTrafficProfileMarkers[recurrence][trafficProfileId]

      if (!marker) continue

      const customOn = marker.mode === 'on' && mapTrafficProfileStyles.mode === 'off'
      const mainOn = marker.mode !== 'off' && mapTrafficProfileStyles.mode === 'on'

      const markerVisible = customOn || mainOn

      // skip hidden markers
      if (markerVisible) {
        return feature.getGeometry()?.getExtent()
      }
    }
  }
}

const getRoadSegmentFeatureExtent = (
  mapRoadSegmentMarkers: uui.domain.ui.map.markers.MapMarkers['roadSegment'],
  mapRoadSegmentStyles: uui.domain.ui.map.markers.MapStyles['roadSegment'],
  feature: Feature<Geometry>,
) => {
  const roadSegmentId = feature.getId()

  if (roadSegmentId && !!mapRoadSegmentMarkers.default) {
    const markers = mapRoadSegmentMarkers.default[roadSegmentId]

    for (const markerId in markers) {
      const marker = markers[markerId]

      if (!marker) continue

      const customOn = marker.mode === 'on' && mapRoadSegmentStyles.mode === 'off'
      const mainOn = marker.mode !== 'off' && mapRoadSegmentStyles.mode === 'on'

      const markerVisible = customOn || mainOn

      // skip hidden markers
      if (markerVisible) {
        return feature.getGeometry()?.getExtent()
      }
    }
  }
}

const getGeofenceFeatureExtent = (
  mapGeofenceMarkers: uui.domain.ui.map.markers.MapMarkers['geofence'],
  mapGeofenceStyles: uui.domain.ui.map.markers.MapStyles['geofence'],
  feature: Feature<Geometry>,
) => {
  const geofenceId = feature.getId()

  if (geofenceId) {
    const marker = mapGeofenceMarkers[geofenceId]

    if (!marker) return

    const customOn = marker.mode === 'on' && mapGeofenceStyles.mode === 'off'
    const mainOn = marker.mode !== 'off' && mapGeofenceStyles.mode === 'on'

    const markerVisible = customOn || mainOn

    // skip hidden markers
    if (markerVisible) {
      return feature.getGeometry()?.getExtent()
    }
  }
}
