import { useCallback } from 'react'
import { addSeconds } from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { selectDayRangeInfo } from '@/features/domain/scheduler'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { schedulerInstance } from '../../../../../atoms/project/project'

type Options = {
  leftMarginSeconds?: number
  rightMarginSeconds?: number
}

export function useOnZoomRoutes(options?: Options) {
  const calendarRangeInterval = useSelector(selectCalendarRangeInterval)
  const dayRange = useSelector(selectDayRangeInfo)

  return useCallback(() => {
    if (!schedulerInstance.instance) return

    // Compute new visible time span
    const { start } = calendarRangeInterval
    const startDate = addSeconds(
      start,
      dayRange.dayRange.startSec + (options?.leftMarginSeconds ?? 0),
    )
    const endDate = addSeconds(start, dayRange.dayRange.endSec + (options?.rightMarginSeconds ?? 0))

    // Since zoomToSpan does not work well when the zoom level is 0, we need to zoom in first
    schedulerInstance.instance.zoomInFull()

    // Zoom to the new time span
    schedulerInstance.instance.zoomToSpan({
      startDate,
      endDate,
      leftMargin: 0,
      rightMargin: 0,
    })
  }, [calendarRangeInterval, dayRange, options])
}
