import { useCallback } from 'react'

import { useRoutingLayout } from '@/atoms'

// there are 2 ways to show scheduler
// in split layout a flag has to be changed
// in dedicated layout "scheduler" | "orders-grid" we just simply change the layout

export function useShowHideScheduler(showSchedulerInSplitLayout: (show: boolean) => void) {
  const [layout, setLayout] = useRoutingLayout()

  const onRadioChange = useCallback(
    (_, value: 'scheduler' | 'orders-grid') => {
      switch (layout.layout.mode) {
        case 'split':
          showSchedulerInSplitLayout(value === 'scheduler')
          return

        case 'orders-grid':
          setLayout({ mode: 'scheduler' })
          return

        case 'scheduler':
          setLayout({ mode: 'orders-grid' })
          return
      }
    },
    [showSchedulerInSplitLayout, layout, setLayout],
  )

  const radioValue =
    layout.layout.mode === 'split' ? layout.layout.bottomElement : layout.layout.mode

  return {
    onRadioChange,
    radioValue,
  }
}
