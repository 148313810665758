import styled from 'styled-components'

type Props = {
  width?: number
  gutter?: number
  paddingX?: number
}

export const HeaderSlice = styled.span<Props>`
  display: flex;
  flex: 0 0 auto;
  height: inherit;
  position: relative;
  align-items: center;
  padding: 0 ${p => p.paddingX ?? 0}px;
  width: ${p => (typeof p.width === 'number' ? `${p.width}px` : '100%')};

  > * + * {
    margin-left: ${p => p.gutter ?? 0}px;
  }
`

HeaderSlice.displayName = 'HeaderSlice'
