import { useEffect, useRef, useCallback } from 'react'

type Callback = () => void

export function useDelayedCallback(callback: Callback, delay: number) {
  const delayRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const stableCallback = useRef<Callback>()

  // stabilize callback
  useEffect(() => {
    stableCallback.current = callback
  }, [callback])

  // clear timeout on unmount
  useEffect(() => {
    return () => {
      if (delayRef.current) {
        clearTimeout(delayRef.current)
      }
    }
  }, [])

  const clearTimer = useCallback(() => {
    if (delayRef.current) {
      clearTimeout(delayRef.current)
    }
  }, [])

  const startDelayedCallback = useCallback(() => {
    if (delayRef.current) {
      clearTimeout(delayRef.current)
    }

    delayRef.current = setTimeout(() => {
      stableCallback.current?.()
    }, delay)
  }, [delay])

  return [startDelayedCallback, clearTimer]
}
