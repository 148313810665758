import { ConfirmPanel as ConfirmPanelComp } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'
import { useActions } from '../hooks/useActions'

interface Props {
  onCancel: () => void
}

export function ConfirmPanel(props: Props) {
  const { onCancel } = props
  const actions = useActions()
  const texts = useTexts()

  return (
    <ConfirmPanelComp
      title={texts.confirmPanelTitle}
      confirmationText={texts.delete}
      onCancel={onCancel}
      onConfirm={actions.onDelete}
      testid="setup-traffic-bulk-delete-confirm-panel"
    />
  )
}
