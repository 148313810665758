import { Stack } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useControllerActions } from '../../../hooks/useControllerActions'
import { useTexts } from '../../../hooks/useTexts'
import { Footer } from './Footer'

export function ConfigureColumnsFooter() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const { close, status, invalid } = useController()

  return (
    <Footer>
      <ModalFooterButton onClick={handlers.backToConfigureFormat} testid="export-route-prev-button">
        <KeyboardArrowLeft />
        {texts.prevStepButton}
      </ModalFooterButton>
      <Stack gap={1} direction="row">
        <ModalFooterButton onClick={close} testid="export-route-cancel-button">
          {texts.cancelButton}
        </ModalFooterButton>
        <ModalFooterButton
          disabled={invalid || status !== 'ready'}
          onClick={handlers.onExport}
          variant="contained"
          loading={handlers.isLoading}
          testid="export-routes-export-button"
        >
          {texts.exportButton}
        </ModalFooterButton>
      </Stack>
    </Footer>
  )
}
