import { ActionBarItem } from '@workwave-tidal/components/ActionBar'
import { Close } from '@workwave-tidal/icons'
import { ModalHeader } from '@/components/Modal'

import { useControllerById } from '../../../../../../hooks/useController'
import { useTexts } from './useTexts'

interface Props {
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery'
}

export function Header(props: Props) {
  const { pinId } = props

  const { close } = useControllerById(pinId)
  const texts = useTexts()

  const headerTitle = pinId === 'depotFormPin' ? texts.depotHeaderTitle : texts.orderHeaderTitle
  const headerSubtitle =
    pinId === 'depotFormPin' ? texts.depotHeaderSubtitle : texts.orderHeaderSubtitle

  return (
    <ModalHeader
      subtitle={headerSubtitle}
      title={headerTitle}
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
    />
  )
}
