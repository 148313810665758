import type { PublicProps as Props } from './typings'
import type { FieldRenderProps } from 'react-final-form'

import { Field as FinalFormField } from 'react-final-form'

import { isDeepEqual } from '@/server-data'
import { NumericRangeField } from './NumericRangeField'

export function NumericRangeFieldWrapper(props: Props) {
  const { name, ...rest } = props

  return (
    <FinalFormField
      {...rest}
      name={name}
      isEqual={isDeepEqual}
      render={(formProps: FieldRenderProps<any>) => {
        return <NumericRangeField {...props} formProps={formProps} />
      }}
    />
  )
}
