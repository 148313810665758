import { PureComponent } from 'react'

import { FormatDate } from '@/components/smartUtils/time/FormatDate'

import { ReadonlyField } from './ReadonlyField'
import { ReadonlyFieldRow } from './ReadonlyFieldRow'
import { ReadonlyFieldLabel } from './ReadonlyFieldLabel'
import { ReadonlyTimeWindow } from './ReadonlyTimeWindow'

import { ReadonlyTimeWindowExceptionsContainer } from './ReadonlyTimeWindowExceptionsContainer'
import { ReadonlyTimeWindowExceptionsRow } from './ReadonlyTimeWindowExceptionsRow'
import { ReadonlyTimeWindowExceptionsColumn } from './ReadonlyTimeWindowExceptionsColumn'

export interface Props {
  timeWindowExceptions: Record<string, uui.domain.rm.TimeWindow[]>
}

export class ReadonlyTimeWindowExceptions extends PureComponent<Props> {
  render() {
    const { timeWindowExceptions } = this.props

    return (
      <ReadonlyTimeWindowExceptionsContainer>
        {Object.entries(timeWindowExceptions).map(([dateAsString, timeWindows]) => {
          // const label: string = format(dateAsString, 'MMMM D, YYYY')

          if (timeWindows.length === 0) {
            return null
          }

          return (
            <ReadonlyTimeWindowExceptionsRow key={dateAsString}>
              <ReadonlyField style={{ width: '44%', marginRight: 6 }}>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>
                    <FormatDate source={dateAsString}></FormatDate>
                  </ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>

              <ReadonlyTimeWindowExceptionsColumn>
                {timeWindows[0] && <ReadonlyTimeWindow timeWindow={timeWindows[0]} />}

                {timeWindows[1] && <ReadonlyTimeWindow timeWindow={timeWindows[1]} />}
              </ReadonlyTimeWindowExceptionsColumn>
            </ReadonlyTimeWindowExceptionsRow>
          )
        })}
      </ReadonlyTimeWindowExceptionsContainer>
    )
  }
}
