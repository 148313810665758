import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useNotification, useIsUnmounted } from '@/hooks'
import { importDemoTerritory } from '@/features/domain/demoTools'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export function useControllerActions() {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const {
    close,
    updateData,
    data: { id },
  } = useController()
  const isUnmounted = useIsUnmounted()

  const onTerritoryChange = useCallback(item => updateData({ id: item.id }), [updateData])

  const onConfirmTerritoryChange = useCallback(async () => {
    if (!id) return

    try {
      await dispatch(importDemoTerritory(id))
      if (isUnmounted()) return

      close?.()
    } catch (e) {
      toast.error(texts.genericError)
    }
  }, [id, dispatch, texts, toast, close, isUnmounted])

  return {
    onTerritoryChange,
    onConfirmTerritoryChange,
  }
}
