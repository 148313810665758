import { styled } from '@mui/material'
import { theme } from '@/styles'

interface Props {
  disabled: boolean
}

export const IconContainer = styled('div', {
  name: 'IconContainer',
  shouldForwardProp: prop => prop !== 'disabled',
})<Props>(({ disabled }) => ({
  minWidth: 20,
  marginRight: '18px',

  color: disabled ? theme.colors.$silver : theme.colors.$nightRider,

  '.active &': {
    color: theme.colors.$dodgerBlue,
  },
}))
