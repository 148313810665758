import type { ListIconPreset } from '../../typings'

import { Icon } from '../Icon'

import { DeviceIconButtonContainer } from './DeviceIconButtonContainer'
import { DeviceIconButtonInner } from './DeviceIconButtonInner'
import { DeviceIconButtonIcon } from './DeviceIconButtonIcon'

export interface Props {
  preset: ListIconPreset
  label: string
  onClick: () => void
}

export function DeviceIconButton(props: Props) {
  const { label, onClick, preset } = props

  return (
    <DeviceIconButtonContainer>
      <DeviceIconButtonIcon data-icon={preset}>
        <Icon preset={preset} />
      </DeviceIconButtonIcon>
      <DeviceIconButtonInner data-testid="deviceIconButton" onClick={onClick}>
        {label}
      </DeviceIconButtonInner>
    </DeviceIconButtonContainer>
  )
}
