import VectorSource from 'ol/source/Vector'
import { Geometry } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import { Modify } from 'ol/interaction'
import { Style } from 'ol/style'
import OlMap from 'ol/Map'
import { gis } from '@/server-data'

export function createLocationEditorPinInteraction(
  map: OlMap,
  source: VectorSource<Geometry>,
  layer: VectorLayer<VectorSource<Geometry>>,
  updateData: (maybeLazyValue: uui.domain.LatLng) => void,
) {
  // create the modification interaction
  const pinInteraction = new Modify({
    hitDetection: layer,
    source,
    style: new Style(), // Hide the blue circle on the pin when dragging it,
  })

  // listens to pointer move events to change cursor style
  map.on(['pointermove'], (evt: any) => {
    const target = map.getTarget() as string
    const targetElement = document.getElementById(target)

    if (!targetElement || !evt.pixel || targetElement.style.cursor === 'grabbing') return

    const hit = map.forEachFeatureAtPixel(evt.pixel, (_feature, _layer) => true)

    targetElement.style.cursor = hit ? 'grab' : 'default'
  })

  // listens to modifystart and modifyend events to change cursor style
  pinInteraction.on(['modifystart', 'modifyend'], evt => {
    const target = map.getTarget() as string
    const targetElement = document.getElementById(target)

    if (!targetElement) return

    targetElement.style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'default'
  })

  // listens to modifyend event to update data
  pinInteraction.on(['modifyend'], async (evt: any) => {
    const coords = evt.features.item(0).getGeometry().getCoordinates()
    if (!coords) return

    updateData(gis.fromCoordinateToLatLng(coords))
  })

  return pinInteraction
}
