import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    currencyRequired: translate({
      id: 'settings.languageAndFormats.edit.errors.currency.required',
    }),
    currencyInvalid: translate({ id: 'settings.languageAndFormats.edit.errors.currency.valid' }),
  }))

  return api
}

export type ValidationTexts = ReturnType<typeof useTexts>
