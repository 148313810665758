import type { CurrencyOption } from '../LanguageAndFormatsForm/typings'

import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { intl } from '@/intl'
import { selectUserConfiguration, updateSessionUserConfiguration } from '@/features/domain/user'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

type LanguageAndPreferencesData = {
  distanceFormat: uui.domain.DistanceFormat
  dateFormat: uui.domain.DateFormat
  timeFormat: uui.domain.client.TimeFormat
  language: uui.domain.Language
  currency: CurrencyOption
}

export function useLanguageAndFormatsData() {
  const userConfiguration = useSelector(selectUserConfiguration)
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const languageAndPreferences = useMemo<LanguageAndPreferencesData>(() => {
    return {
      distanceFormat: userConfiguration.distanceFormat,
      dateFormat: userConfiguration.dateFormat,
      timeFormat: userConfiguration.timeFormat,
      currency: { value: userConfiguration.currency, label: userConfiguration.currency },
      language: userConfiguration.language,
    }
  }, [userConfiguration])

  const updateLanguageAndFormats = useCallback(
    async (data: LanguageAndPreferencesData) => {
      let success: boolean = true
      try {
        const currency = data.currency.value
        const thunkResult = await dispatch(updateSessionUserConfiguration({ ...data, currency }))

        if (!updateSessionUserConfiguration.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        success = false
      }

      setTimeout(() => {
        showNotificationToast(success, toast)
      }, 1000)

      return success
    },
    [dispatch, toast],
  )

  return [languageAndPreferences, updateLanguageAndFormats] as const
}

/**
 * this function has to access the intl object directly
 * otherwise using the standard useTexts hooks
 * will raise the issue of having the text translated into
 * the previous language
 */
function showNotificationToast(success: boolean, toast: ReturnType<typeof useNotification>) {
  const toastOptions = {
    autoClose: 5000,
  }

  if (success) {
    toast.success(
      intl.translate({
        id: 'settings.languageAndFormats.edit.save.success',
      }),
      toastOptions,
    )
    return
  }

  toast.error(
    intl.translate({
      id: 'settings.languageAndFormats.edit.save.error',
    }),
    toastOptions,
  )
}
