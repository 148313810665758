import { TransactionInProgressTooltip } from '@/components/smartUtils/plan/TransactionInProgressTooltip'
import { TerritoryButtons } from './components/TerritoryButtons/TerritoryButtons'
import { ModalTrigger } from './components/ModalTrigger'

import { useAvailableTerritories } from './hooks/useAvailableTerritories'

interface Props {
  setOpen: (open: boolean) => void
  hasTerritoryMenuOptions: boolean
}

export function ChangeTerritoryMenuItem(props: Props) {
  const { setOpen, hasTerritoryMenuOptions } = props

  const territories = useAvailableTerritories()

  // If there is just the active territory will show nothing
  if (territories.length === 0) return null

  // If there are more than 6 territories (beyond the active one) will show the modal trigger
  if (territories.length > 6)
    return (
      <TransactionInProgressTooltip
        render={preventEditing => <ModalTrigger setOpen={setOpen} disabled={preventEditing} />}
        placement="left"
      />
    )

  // If there are 6 or less other territories (beyond the active one) will show territory triggers
  return (
    <TerritoryButtons
      setOpen={setOpen}
      territories={territories}
      hasTerritoryMenuOptions={hasTerritoryMenuOptions}
    />
  )
}
