import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { conversionUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export const useConvertDistance = (meters: number, decimals?: number, printLabel?: boolean) => {
  const userConfig = useSelector(selectUserConfiguration)

  return useMemo(
    () => conversionUtils.convertDistance(userConfig)(meters, decimals, printLabel),
    [userConfig, meters, decimals, printLabel],
  )
}
