import { useCallback } from 'react'
import { useController } from './useController'

export function useControllerActions() {
  const {
    close,
    data: { previewUrl },
  } = useController()

  const openPreview = useCallback(() => {
    globalThis.open(previewUrl, '_blank')
    close?.()
  }, [close, previewUrl])

  return {
    openPreview,
  }
}
