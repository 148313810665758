import type { RecurrencesMap } from './typings'

import { useCallback, useMemo } from 'react'
import { parse } from 'date-fns/esm'
import { useSelector } from 'react-redux'
import { Stack, Box } from '@mui/material'

import { selectUserConfiguration } from '@/features/domain/user'
import { composeRecurrenceId, parseRecurrence } from '@/server-data'

import { RecurrenceTypePicker } from './components/RecurrenceTypePicker'
import { CalendarPicker } from './components/CalendarPicker'

type Props = {
  value?: uui.domain.rm.Recurrence
  onChange: (r: uui.domain.rm.Recurrence) => void
  recurrenceMap: RecurrencesMap
}

const now = new Date()

export function RecurrencePicker(props: Props) {
  const { recurrenceMap, value, onChange } = props

  const { today } = useSelector(selectUserConfiguration)
  const todayDate = parse(today, 'yyyyMMdd', now)

  const recurrenceType = useMemo<uui.domain.rm.RecurrenceTypes>(
    () => value?.type || 'DATE',
    [value],
  )

  const handleOnChangeRecurrenceType = useCallback(
    (type: uui.domain.rm.RecurrenceTypes) => {
      onChange(parseRecurrence(composeRecurrenceId(type, todayDate))!)
    },
    [onChange, todayDate],
  )

  const handleOnChangeDate = useCallback(
    (type: uui.domain.rm.RecurrenceTypes, date: Date) => {
      onChange(parseRecurrence(composeRecurrenceId(type, date))!)
    },
    [onChange],
  )

  return (
    <Box padding={1}>
      <Stack spacing={3}>
        <RecurrenceTypePicker
          recurrenceType={recurrenceType}
          onChangeRecurrenceType={handleOnChangeRecurrenceType}
        />
        <CalendarPicker
          recurrenceType={recurrenceType}
          recurrenceMap={recurrenceMap}
          onChange={handleOnChangeDate}
          date={value?.value || null}
        />
      </Stack>
    </Box>
  )
}
