import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'

const nonblockingRender = createNonBlockingRenderByTime()

export async function changelogRemoveDeviceEventFeatures(
  layer: VectorLayer<VectorSource>,
  selection: Set<string>,
  markerIds: IterableIterator<string>,
) {
  nonblockingRender.reset()

  for (const markerId of markerIds) {
    // Remove points features
    const pointsFeatures = layer.getSource()?.getFeatures()

    if (!pointsFeatures) {
      throw new Error('Features not found (source is undefined)')
    }

    for (const feature of pointsFeatures) {
      const featureId = feature.getId()
      if (typeof featureId === 'string' && featureId.startsWith(`${markerId}@`)) {
        await nonblockingRender.next()

        layer.getSource()?.removeFeature(feature)
      }
    }
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
