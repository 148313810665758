import type { CSSProperties } from 'react'
import type { SegmentType } from '../../../types'

import { useMemo } from 'react'

import { theme } from '@/styles'

export function useStyles(type: SegmentType, size: number) {
  return useMemo(() => {
    const background = getBackground(type)
    const color = getColor(type)

    return {
      container: {
        '--size': `${size}px`,
        '--background': background,
        '--color': color,
        '--borderColor': theme.colors.$pureWhite,
      } as CSSProperties,
    }
  }, [type, size])
}

function getBackground(type: SegmentType) {
  switch (type.type) {
    case 'blocked':
    case 'delayed':
      return '#fff'
    case 'disabled':
      return '#c3c3c3'
  }
}

function getColor(type: SegmentType) {
  switch (type.type) {
    case 'blocked':
      return '#fa603f'
    case 'delayed':
      return '#ff9500'
    case 'disabled':
      return '#c3c3c3'
  }
}
