import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  link: {
    padding: 0,
    width: '100%',
    '&.active $tick': {
      opacity: 1,
    },
  },
  tick: {
    opacity: 0,
  },
})
