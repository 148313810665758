import type { BaseProps as Props } from './typings'

import styled from 'styled-components'

import { InvisibleButton } from '@/local/components'

export const StatusIndicatorContainer = styled(InvisibleButton)<Props>`
  position: relative;
  display: block;
  color: ${p => (p.disabled ? p.theme.colors.$whiteSmoke : p.theme.colors.$scienceBlue)};
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  background: transparent;
  border: 2px solid;
  border-color: transparent;
  transition: background 0.2s ease, border 0.2s ease;
  border-radius: 2px;

  &:hover {
    background: ${p => (p.disabled ? 'transparent' : p.theme.colors.$alabaster)};
  }

  &:focus {
    border-color: ${p => (p.disabled ? 'transparent' : p.theme.colors.$scienceBlue)};
  }
`

StatusIndicatorContainer.displayName = 'StatusIndicatorContainer'
