import { useCallback, useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { generateRelativePath } from '@/routing'
import { usePopupApi, useRoutingLayout } from '@/atoms'
import { useExtractedMapWarningModal } from '../modals/ExtractedMapWarningModal'
import { useDropdownOpen, useTargetLayout } from '../atoms/routingLayoutDropdownState'

export const useRoutingLayoutMenu = () => {
  const [{ layout }, setLayout] = useRoutingLayout()
  const [, setModalTargetLayout] = useTargetLayout()
  const [dropdownOpen, setDropdownOpen] = useDropdownOpen()
  const { show: showExtractedMapWarningModal } = useExtractedMapWarningModal()

  const location = useLocation()
  const {
    open: openPopup,
    focus: focusPopup,
    close: closePopup,
    status: popupStatus,
  } = usePopupApi()

  const extractedMapOpen = popupStatus === 'opening' || popupStatus === 'open'

  // ---------------------------------------------------------------------------------
  // API
  // ---------------------------------------------------------------------------------
  const api = useRef({
    setLayout,
    openPopup,
    focusPopup,
    closePopup,
    setDropdownOpen,
    extractedMapOpen,
    setModalTargetLayout,
    layoutMode: layout.mode,
    showExtractedMapWarningModal,
    layoutBottomElement: layout.bottomElement,
  })
  useLayoutEffect(() => {
    api.current = {
      setLayout,
      openPopup,
      focusPopup,
      closePopup,
      setDropdownOpen,
      extractedMapOpen,
      setModalTargetLayout,
      layoutMode: layout.mode,
      showExtractedMapWarningModal,
      layoutBottomElement: layout.bottomElement,
    }
  }, [
    setLayout,
    openPopup,
    focusPopup,
    closePopup,
    layout.mode,
    setDropdownOpen,
    extractedMapOpen,
    setModalTargetLayout,
    layout.bottomElement,
    showExtractedMapWarningModal,
  ])

  // ---------------------------------------------------------------------------------
  // FOCUS EXTRACTED MAP
  // ---------------------------------------------------------------------------------
  const focusExtractedMap = useCallback(() => {
    const { setDropdownOpen, focusPopup } = api.current

    setDropdownOpen(false)
    focusPopup()
  }, [])

  // ---------------------------------------------------------------------------------
  // CLOSE EXTRACTED MAP
  // ---------------------------------------------------------------------------------
  const closeExtractedMap = useCallback(() => {
    const { setDropdownOpen, closePopup } = api.current

    setDropdownOpen(false)
    closePopup()
  }, [])

  // ---------------------------------------------------------------------------------
  // OPEN EXTRACTED MAP
  // ---------------------------------------------------------------------------------
  const openExtractedMap = useCallback(() => {
    const { setDropdownOpen, setLayout, openPopup, layoutMode, layoutBottomElement } = api.current

    setDropdownOpen(false)

    // When the Extracted Map is open, the users are forced to a map-free layout
    if (layoutMode === 'split' || layoutMode === 'map') {
      setLayout({ mode: layoutBottomElement === 'orders-grid' ? 'orders-grid' : 'scheduler' })
    }

    openPopup()
  }, [])

  // ---------------------------------------------------------------------------------
  // SET TO SCHEDULER LAYOUT
  // ---------------------------------------------------------------------------------
  const setToScheduler = useCallback(() => {
    const { setLayout, setDropdownOpen } = api.current

    setDropdownOpen(false)
    setLayout({ mode: 'scheduler' })
  }, [])

  // ---------------------------------------------------------------------------------
  // SET TO ORDER GRID LAYOUT
  // ---------------------------------------------------------------------------------
  const setToOrderGrid = useCallback(() => {
    const { setLayout, setDropdownOpen } = api.current

    setDropdownOpen(false)
    setLayout({ mode: 'orders-grid' })
  }, [])

  // ---------------------------------------------------------------------------------
  // SET TO MAP LAYOUT
  // ---------------------------------------------------------------------------------
  const setToMap = useCallback(async () => {
    const {
      setLayout,
      setDropdownOpen,
      extractedMapOpen,
      setModalTargetLayout,
      showExtractedMapWarningModal,
    } = api.current
    setDropdownOpen(false)

    if (extractedMapOpen) {
      setModalTargetLayout('map')
      showExtractedMapWarningModal()
      return
    }

    setLayout({ mode: 'map' })
  }, [])

  // ---------------------------------------------------------------------------------
  // SET TO SPLIT LAYOUT
  // ---------------------------------------------------------------------------------
  const setToSplit = useCallback(async () => {
    const {
      setLayout,
      layoutMode,
      setDropdownOpen,
      extractedMapOpen,
      setModalTargetLayout,
      showExtractedMapWarningModal,
    } = api.current

    setDropdownOpen(false)

    if (extractedMapOpen) {
      setModalTargetLayout('split')
      showExtractedMapWarningModal()
      return
    }

    const bottomElement = layoutMode === 'scheduler' ? 'scheduler' : 'orders-grid'
    setLayout({ mode: 'split', bottomElement })
  }, [])

  // ---------------------------------------------------------------------------------
  // RETURN
  // ---------------------------------------------------------------------------------
  return {
    disabled: generateRelativePath(location.pathname) !== 'home',
    dropdownOpen,
    setDropdownOpen,

    extractedMapOpen,
    closeExtractedMap,
    focusExtractedMap,

    setToMap,
    setToSplit,
    setToScheduler,
    setToOrderGrid,
    openExtractedMap,
    layoutMode: layout.mode,
  }
}
