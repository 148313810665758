import { parse } from 'date-fns/esm'

import { memoizeOne } from '../../../utils/memoizeOne'

const getLastCalendarRangeIntervalValue = (minDate: string, maxDate: string) => {
  const now = new Date()

  return {
    end: parse(maxDate, 'yyyyMMdd', now),
    start: parse(minDate, 'yyyyMMdd', now),
  }
}

/**
 * Get a calendar interval valid for Date-fns. Cache the last interval expecting to ba called in
 * loops where the same calendar range is used over and over.
 */
export const getLastCalendarRangeInterval = memoizeOne(getLastCalendarRangeIntervalValue)
