import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

/**
 * Update Vehicle
 *
 * @private
 */
export const createVehicle = createAsyncThunk<
  // Return type of the payload creator
  string[],
  // First argument to the payload creator
  uui.domain.client.UnifiedVehicle,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/vehicle/create', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const response = await rpc('rpc/vehicle/create', {
      category: 'rpc',
      type: 'rpc/vehicle/create',
      unifiedVehicle: payload,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {createVehicleException}`,
      { tags: ['rpc', 'vehicles'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Create vehicle exception failed`,
      error,
    })
  }
})
