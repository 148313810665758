import { SvgIcon } from '@mui/material'

export function Rabbit() {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path
        d="M19.31 7.59888C18.09 7.55888 16.88 8.49888 16.5 9.99888C16 11.9989 16 11.9989 15 12.9989C13 14.9989 10 15.9989 4 16.9989C3 17.1589 2.5 17.4989 2 17.9989C4 17.9989 6 17.9989 4.5 19.4989L3 20.9989H6L8 18.9989C10 19.9989 11.33 19.9989 13.33 18.9989L14 20.9989H17L16 17.9989C16 17.9989 17 13.9989 18 12.9989C19 11.9989 19 12.9989 20 12.9989C21 12.9989 22 11.9989 22 10.4989C22 9.99888 22 8.99888 20.5 7.99888C20.15 7.75888 19.74 7.61888 19.31 7.59888ZM9 7.99888C7.4087 7.99888 5.88258 8.63103 4.75736 9.75624C3.63214 10.8815 3 12.4076 3 13.9989C3 14.5989 3.13 15.0789 3.23 15.5989C9.15 14.6189 12.29 13.5889 13.93 11.9389L14.43 11.4389C13.9437 10.41 13.1752 9.54049 12.2138 8.93148C11.2525 8.32247 10.138 7.99905 9 7.99888Z"
        fill="black"
        fillOpacity="0.56"
      />
    </SvgIcon>
  )
}
