import { useMemo } from 'react'

import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { PaperPlane } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  permission:
    | 'hidden'
    | 'enabled'
    | 'disabledBecauseInThePast'
    | 'disabledForNoDispatchSlots'
    | 'disabledOutOfOptimizationRange'
  onClick: () => void
}

export function DispatchButton(props: Props) {
  const { onClick, permission } = props
  const texts = useTexts()

  const tooltipTitle = useMemo(() => {
    switch (permission) {
      case 'disabledForNoDispatchSlots':
        return texts.reachMaxDispatchedDays

      case 'disabledBecauseInThePast':
        return texts.disabledDispatchRoutesPast

      case 'disabledOutOfOptimizationRange':
        return texts.disabledOutOfOptimizationRange

      default:
        return ''
    }
  }, [texts, permission])

  const disabled = permission.startsWith('disabled')
  if (permission === 'hidden') return null

  return (
    <ReadOnlyTooltip
      canEdit={!disabled}
      title={tooltipTitle}
      render={preventEditing => (
        <NavigoIconButton
          onClick={onClick}
          text={texts.dispatch}
          disabled={preventEditing || disabled}
          Icon={
            <PaperPlane size={17} color={preventEditing || disabled ? '$silver' : '$nightRider'} />
          }
          testid="navigo-routes-overview-actions-dispatch"
        />
      )}
    />
  )
}
