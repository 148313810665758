import { useEffect, useState } from 'react'
import { domainProxy } from '@/store'

/**
 * That hooks listens for `buildProgress` notifications from the domainProxy and returns the result
 *
 */
export function useBuildProgress() {
  const [progress, setProgress] = useState<number | 'completed'>(0)

  useEffect(() => {
    const unsubscribe = domainProxy.subscribeToNotifications(
      notification => {
        if (notification.notificationType === 'buildProgress') {
          setProgress(notification.payload)
        }
      },
      ['buildProgress'],
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return progress
}
