import { makeStyles } from '@mui/styles'
import { Box } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { Text } from '@/local/components'
import { theme } from '@/styles'
import { User } from '@/icons'

interface Props {
  onClick?: () => void
  children?: string
}

const useStyles = makeStyles({
  driverTagButton: {
    outline: 0,
    borderWidth: 0,
    borderRadius: 4,
    overflow: 'hidden',

    backgroundColor: theme.colors.$shadyLady,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre',
  },
})

export function DriverTag(props: Props) {
  const { children = '', onClick } = props
  const classes = useStyles()
  return (
    <button
      data-trackid="navigo-routes-overview-details-single-driver"
      data-testid="navigo-routes-overview-details-single-driver"
      className={classes.driverTagButton}
      onClick={onClick}
    >
      <Box flexShrink={0} display="flex">
        <User size={10} marginRight={4} color="$pureWhite" />
      </Box>

      <div style={{ overflow: 'hidden' }}>
        <Tooltip placement="top" title={children} disabled={!children}>
          <Text size="$p3" color="$pureWhite" ellipsis>
            {children}
          </Text>
        </Tooltip>
      </div>
    </button>
  )
}
