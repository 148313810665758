import { Tooltip } from '@/components/primitives/Tooltip'
import { DropdownMenu, MenuIconTrigger, DropdownListSeparator } from '@/components/DropdownMenu'
import { SingleLayout, DoubleLayout } from '@/icons'

import { useTexts } from './useTexts'
import { useRoutingLayoutMenu } from './hooks/useRoutingLayoutMenu'

import { MapLayoutItem } from './components/MapLayoutItem'
import { SplitLayoutItem } from './components/SplitLayoutItem'
import { ExtractMapItem } from './components/OpenExtractedMapItem'
import { SchedulerLayoutItem } from './components/SchedulerLayoutItem'
import { OrderGridLayoutItem } from './components/OrderGridLayoutItem'
import { ExtractedMapSubMenu } from './components/ExtractedMapSubMenu'

function ActiveIcon(props: { activeLayout: string }) {
  return props.activeLayout === 'split' ? (
    <DoubleLayout size={16} color="$pureWhite" />
  ) : (
    <SingleLayout size={16} color="$pureWhite" />
  )
}

export function RoutingLayoutDropdownMenu() {
  const texts = useTexts()
  const { dropdownOpen, setDropdownOpen, disabled, layoutMode, extractedMapOpen } =
    useRoutingLayoutMenu()

  return (
    <DropdownMenu
      open={dropdownOpen}
      setOpen={setDropdownOpen}
      disabled={disabled}
      trigger={
        <Tooltip placement="bottom" title={disabled ? texts.layoutDisabled : texts.layout}>
          <MenuIconTrigger
            menuOpen={dropdownOpen}
            Icon={<ActiveIcon activeLayout={layoutMode} />}
            testid="header__layout-menu-trigger"
          />
        </Tooltip>
      }
    >
      <div data-testid="header__layout-menu-container">
        <MapLayoutItem />
        <SchedulerLayoutItem />
        <OrderGridLayoutItem />
        <SplitLayoutItem />

        <DropdownListSeparator />

        {extractedMapOpen ? <ExtractedMapSubMenu /> : <ExtractMapItem />}
      </div>
    </DropdownMenu>
  )
}
