import { Stack } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'
import { ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useControllerActions } from '../../../hooks/useControllerActions'
import { useTexts } from '../../../hooks/useTexts'
import { Footer } from './Footer'

export function ConfigureFormatFooter() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const {
    close,
    status,
    invalid,
    data: { columnsState, customizeColumns, approvedRoutesAmount, filter, selectedRoutes },
  } = useController()

  const amount = filter === 'selected' ? selectedRoutes.length : approvedRoutesAmount

  return (
    <Footer>
      {/* the empty div will let the stack to align to the right for the justify-content of the Footer */}
      <div />
      <Stack gap={1} direction="row">
        <ModalFooterButton testid="export-route-cancel-button" onClick={close}>
          {texts.cancelButton}
        </ModalFooterButton>
        {customizeColumns ? (
          <ModalFooterButton
            variant="contained"
            testid="export-route-go-to-column-selection"
            onClick={handlers.onNextButtonClick}
            loading={columnsState.status === 'loading'}
          >
            {texts.nextStepButton}
            <KeyboardArrowRight />
          </ModalFooterButton>
        ) : (
          <ModalFooterButton
            disabled={invalid || status !== 'ready'}
            onClick={handlers.onLegacyExport}
            variant="contained"
            loading={handlers.isLoading}
            testid="export-routes-export-button"
          >
            {texts.exportButton(amount)}
          </ModalFooterButton>
        )}
      </Stack>
    </Footer>
  )
}
