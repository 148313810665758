import type { PublicProps as Props } from './types'
import { Field as FinalFormField, FieldRenderProps } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import { DrawAreaField } from './DrawAreaField'

export function DrawAreaFieldWrapper(props: Props) {
  const { name } = props

  return (
    <FinalFormField
      {...props}
      name={name}
      isEqual={isDeepEqual}
      render={(formProps: FieldRenderProps<uui.domain.LatLng[]>) => {
        return <DrawAreaField {...props} formProps={formProps} />
      }}
    />
  )
}
