import { NavLink as RouterLink, NavLinkProps } from 'react-router-dom'
import { generateAbsolutePath } from '../utils/generateAbsolutePath'

type Props<ACCEPTED_ROUTES> = NavLinkProps & {
  to: ACCEPTED_ROUTES
  absolute?: boolean
  disabled?: boolean
}

/**
 * Adds type safety to the `Link` component.
 */
export function Link<ACCEPTED_ROUTES extends string>(
  props: Props<ACCEPTED_ROUTES>,
): React.ReactElement<Props<ACCEPTED_ROUTES>> {
  const { children, absolute, disabled, ...rest } = props

  if (disabled) return <>{children}</>

  if (absolute && typeof props.to === 'string') {
    return (
      <RouterLink {...rest} to={generateAbsolutePath(props.to)}>
        {children}
      </RouterLink>
    )
  }

  return <RouterLink {...rest}>{children}</RouterLink>
}
