import { useMemo } from 'react'

import { NumericInputField } from '@/forms-legacy'

import { useGetDistanceUnitOfMeasure } from '@/hooks'

import { useTexts } from '../hooks/useTexts'

interface Props {
  metric: boolean
}

export function MaxDistance(props: Props) {
  const { metric } = props
  const texts = useTexts()

  const unitOfMeasure = useGetDistanceUnitOfMeasure()

  const wrappedInputProps = useMemo(() => {
    return {
      step: 10,
      useMetric: metric,
    }
  }, [metric])

  return (
    <NumericInputField
      name="maxDistanceMt"
      testid="maxDistanceMt"
      half
      label={texts.maxDistance(unitOfMeasure)}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
