import { useCallback } from 'react'

import { useChangeTerritoryModal } from '@/components/modals/ChangeTerritoryModal'
import { DropdownMenuItem } from '@/components/DropdownMenu'

import { useTexts } from '../useTexts'

interface Props {
  disabled?: boolean
  setOpen: (open: boolean) => void
}

export function ModalTrigger(props: Props) {
  const { setOpen, disabled = false } = props
  const { show } = useChangeTerritoryModal()
  const texts = useTexts()

  const showModal = useCallback(() => {
    show()
    setOpen(false)
  }, [show, setOpen])

  return (
    <DropdownMenuItem
      disabled={disabled}
      onClick={showModal}
      className="pendo-bluebar__territory-switch-territory-button"
      testid="territoryDropdown__modal-trigger"
    >
      {texts.switchTerritory}
    </DropdownMenuItem>
  )
}
