import { PureComponent } from 'react'

import FormBulkAvatarContainer from './FormBulkAvatarContainer'
import { Props } from './typings'

export class FormBulkAvatar extends PureComponent<Props> {
  render() {
    const { size, color } = this.props

    return <FormBulkAvatarContainer color={color}>{`x${size}`}</FormBulkAvatarContainer>
  }
}
