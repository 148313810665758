import type { ListIconPreset, ListIconItem } from './typings'

import { useCallback } from 'react'
import { ReadonlyLabel } from '@/forms-legacy'

import { DeviceIconButton } from './elements/DeviceIconButton'
import { IconsList } from './elements/IconsList'

interface Props {
  value: number
  openText: string
  description: string
  activePreset: ListIconPreset
  onChange: (value: number) => void
  icons: Record<number, ListIconItem>
  setMode: (mode: 'open' | 'closed') => void
}

export function OpenList(props: Props) {
  const { setMode, activePreset, description, openText, icons, onChange, value } = props

  const onClick = useCallback(() => {
    setMode('closed')
  }, [setMode])

  return (
    <>
      <DeviceIconButton onClick={onClick} label={openText} preset={activePreset} />
      {description && (
        <ReadonlyLabel style={{ marginTop: 10 }} small>
          {description}
        </ReadonlyLabel>
      )}
      <IconsList icons={icons} onChange={onChange} setMode={setMode} value={value} />
    </>
  )
}
