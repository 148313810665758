import 'ol/ol.css'
import 'react-toastify/dist/ReactToastify.css'
import 'intersection-observer'

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { Root } from '@/app'
import { journal } from '@/server-data'

import { version } from '@/utils'

// UI Journal: Initialize Journal and configure its prefix
journal.core.setJournalPrefix('[UI]: ')

/**
 * RouteManager entry point
 */
function renderApp() {
  const container = document.getElementById('root')

  if (!container) {
    throw new Error(`Unknown Root node named: 'root'`)
  }

  const root = createRoot(container)

  if (enableStrictMode()) {
    console.log('React StrictMode enabled')
    root.render(
      <StrictMode>
        <Root />
      </StrictMode>,
    )
  } else {
    console.log('React StrictMode disabled')
    root.render(<Root />)
  }
}

renderApp()

// Log current RouteManager Version info in the browser console
version.logVersionInfo()

function enableStrictMode() {
  return process.env.PUBLIC_REACT_STRICT_MODE === 'enable'
}
