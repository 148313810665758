import { useSelector } from 'react-redux'

import { useCrudSelection } from '@/atoms'
import { selectTrafficProfiles } from '@/features/domain/traffic'

export const useTrafficProfile = () => {
  const [trafficProfileSelection] = useCrudSelection('trafficProfile')
  const trafficProfiles = useSelector(selectTrafficProfiles)

  return trafficProfiles[trafficProfileSelection[0]]
}
