import { FormControl, FormHelperText, TextField } from '@mui/material'

import { useTexts } from '../../hooks/useTexts'
import { useControllerActions } from '../../hooks/useControllerActions'
import { useController } from '../../hooks/useController'

const MAXLENGTH = 250

export function TrafficDescription() {
  const texts = useTexts()
  const { onChangeDescription } = useControllerActions()
  const {
    data: {
      formValues: { description },
    },
  } = useController()

  const helperText = `${description.length}/${MAXLENGTH}`

  return (
    <FormControl data-testid="traffic-description-field">
      <TextField
        label={texts.form.trafficDescription}
        value={description}
        onChange={onChangeDescription}
        inputProps={{
          maxLength: MAXLENGTH,
        }}
      />
      <FormHelperText sx={{ marginX: 0 }}>{helperText}</FormHelperText>
    </FormControl>
  )
}
