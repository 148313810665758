import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const { logout } = useControllerActions()
  const { status, invalid, close } = useController()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          disabled={invalid || status !== 'ready'}
          variant="contained"
          onClick={logout}
        >
          {texts.logout}
        </ModalFooterButton>
      }
      secondaryAction={<ModalFooterButton onClick={close}>{texts.cancel}</ModalFooterButton>}
    />
  )
}
