import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (count: number) => translate({ id: 'gps.geofences.bulk.title', values: { count } }),
    confirmPanelSubtitle: translate({ id: 'gps.geofences.view.delete.subtitle' }),
    description: translate({ id: 'gps.geofences.bulk.description' }),
    delete: translate({ id: 'gps.geofences.bulk.deleteAllAction' }),
    confirmPanelTitle: translate({
      id: 'gps.geofences.view.delete.title',
      values: { selected: 2 },
    }),
  }))

  return api
}
