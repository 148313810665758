import { InvisibleButton } from '../../atoms'

import { Props } from './typings'

export const IconButton = (props: Props) => {
  const { Icon, children, ref, color, ...btnProps } = props

  return <InvisibleButton {...btnProps}>{Icon}</InvisibleButton>
}

IconButton.displayName = 'IconButton'
