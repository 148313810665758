import type { MonthData, Days, DayProps, YYYYMMDD, SelectionAsRecord } from '../../typings'

import { useMemo } from 'react'
import { eachDayOfInterval, format, isSameDay, getDate, getMonth } from 'date-fns/esm'

import { useDateFnsOptions } from '@/hooks'
import { parseDate } from '../../utils/parseDate'

import { matchHighlightProducer } from './matchHighlightProducer'

type Props = {
  selection: SelectionAsRecord
  today: YYYYMMDD
  firstNonArchivedDay?: YYYYMMDD
  highlightedDays?: SelectionAsRecord
  disabledDays?: SelectionAsRecord
  months: MonthData[]
}

type DaysByMonth = Record<string, Days>

export const useAnnualDays = (props: Props): DaysByMonth => {
  const options = useDateFnsOptions()
  const {
    today,
    firstNonArchivedDay,
    highlightedDays = {},
    disabledDays,
    selection,
    months,
  } = props

  return useMemo<DaysByMonth>(() => {
    const todayDate = parseDate(today)

    const firstNonArchivedDayDate = !!firstNonArchivedDay
      ? parseDate(firstNonArchivedDay)
      : undefined

    const daysByMonth: DaysByMonth = {}

    for (const month of months) {
      const { start, end, id: monthId } = month

      const dates = eachDayOfInterval({
        start,
        end,
      })

      const days = dates.map<DayProps>((date, index) => {
        const label = format(date, 'd', options)
        const dateAsString = format(date, 'yyyyMMdd')

        const selected = Object.keys(selection).some(sel => {
          const selectionDate = parseDate(sel)
          return (
            getDate(selectionDate) === getDate(date) && getMonth(selectionDate) === getMonth(date)
          )
        })

        const disabled = !!disabledDays && !!disabledDays[dateAsString]

        const cosmetic = disabled ? 'grayed-out' : selected ? 'edge-of-selection' : 'normal'

        const firstOfRow = index % 7 === 0
        const isToday = isSameDay(todayDate, date)

        return {
          leftSeparator: firstNonArchivedDayDate && isSameDay(firstNonArchivedDayDate, date),
          dot: matchHighlightProducer(highlightedDays)(dateAsString),
          underline: isToday,
          id: dateAsString,
          bold: isToday,
          type: 'day',
          firstOfRow,
          disabled,
          cosmetic,
          isToday,
          label,
          date,
        }
      })

      daysByMonth[monthId] = days
    }

    return daysByMonth
  }, [months, options, today, firstNonArchivedDay, highlightedDays, disabledDays, selection])
}
