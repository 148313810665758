import { useCallback } from 'react'

import { resetCrudSelection, useEditingStateWithCtx } from '@/atoms'

export function useActions() {
  const { editing, setEditing } = useEditingStateWithCtx('alert')

  const onCreate = useCallback(() => {
    resetCrudSelection('alerts')
    setEditing([])
  }, [setEditing])

  return { onCreate, editing }
}
