import type { ExceptionReadonlyValues } from '../../types'

import { useSelector } from 'react-redux'

import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
  HorizontalRuler,
  DrivingTimeSlider,
  ReadonlyFieldParagraph,
} from '@/forms-legacy'
import { selectUserConfiguration } from '@/features/domain/user'

import { useSpeedSliderSteps } from '../../hooks/useSpeedSliderSteps'

import { Loads } from './components/Loads'
import { Tags } from './components/Tags'

import { useTexts } from './useTexts'
import { Constraints } from './components/Constraints'
import { Costs } from './components/Costs'
import { Regions } from './components/Regions'

interface Props {
  data: ExceptionReadonlyValues
}

function getCoordinatesStyle(val: number, max: number): React.CSSProperties {
  return {
    left: `${(Math.abs(val) / max) * 100}%`,
  }
}

export function ViewDetails(props: Props) {
  const {
    available,
    startLocation,
    endLocation,
    flexibleStart,
    workingDayStart,
    workingDayEnd,
    breaks,
    regions,
    maxOrders,
    maxMileage,
    maxDrivingHours,
    maxWorkingHours,
    minWorkingHours,
    closeOutTimeSec,
    preparationTimeSec,
    speedFactor,
    fixedCost,
    serviceTime,
    drivingTime,
    idleTime,
    breakTime,
    perMile,
    perStop,
    notes,
    loads,
    tags,
  } = props.data

  const texts = useTexts()
  const userConfig = useSelector(selectUserConfiguration)

  const metric = userConfig.distanceFormat === 'METRIC'

  const sliderSteps = useSpeedSliderSteps()

  return (
    <>
      <ReadonlyLabel primary style={{ marginBottom: 0 }}>
        {texts.vehicleAvailable(available)}
      </ReadonlyLabel>

      <HorizontalRuler />

      <ReadonlyBlock>
        <ReadonlyLabel empty={!startLocation}>{texts.startLocation}</ReadonlyLabel>
        {!!startLocation && (
          <>
            <ReadonlyBlock>
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>{startLocation}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            </ReadonlyBlock>

            {!!preparationTimeSec && (
              <ReadonlyBlock half>
                <ReadonlyLabel>{texts.preparationTimeSec}</ReadonlyLabel>
                <ReadonlyField>
                  <ReadonlyFieldRow>
                    <ReadonlyFieldLabel>{preparationTimeSec}</ReadonlyFieldLabel>
                  </ReadonlyFieldRow>
                </ReadonlyField>
              </ReadonlyBlock>
            )}
          </>
        )}
      </ReadonlyBlock>

      <ReadonlyBlock>
        <ReadonlyLabel empty={!endLocation}>{texts.endLocation}</ReadonlyLabel>
        {!!endLocation && (
          <>
            <ReadonlyBlock>
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>{endLocation}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            </ReadonlyBlock>

            {!!closeOutTimeSec && (
              <ReadonlyBlock half>
                <ReadonlyLabel>{texts.closeOutTimeSec}</ReadonlyLabel>
                <ReadonlyField>
                  <ReadonlyFieldRow>
                    <ReadonlyFieldLabel>{closeOutTimeSec}</ReadonlyFieldLabel>
                  </ReadonlyFieldRow>
                </ReadonlyField>
              </ReadonlyBlock>
            )}
          </>
        )}
      </ReadonlyBlock>

      <HorizontalRuler />

      <ReadonlyLabel primary>{texts.workingHours}</ReadonlyLabel>
      <ReadonlyBlock half>
        <ReadonlyLabel>{texts.workingDayStart(flexibleStart)}</ReadonlyLabel>
        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>{workingDayStart}</ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyBlock>
      <ReadonlyBlock half>
        <ReadonlyLabel>{texts.workingDayEnd}</ReadonlyLabel>
        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>{workingDayEnd}</ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
        <ReadonlyLabel small>{texts.workingDayEndInfo}</ReadonlyLabel>
      </ReadonlyBlock>

      <HorizontalRuler />

      <ReadonlyBlock>
        <ReadonlyLabel empty={breaks.length === 0}>{texts.breaks}</ReadonlyLabel>
        {breaks.map(b => (
          <ReadonlyField key={b.id}>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{b.text}</ReadonlyFieldLabel>
              <ReadonlyLabel small>{b.description}</ReadonlyLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        ))}
      </ReadonlyBlock>

      <HorizontalRuler />

      <Regions regions={regions} />

      <HorizontalRuler />

      <Constraints
        maxOrders={maxOrders}
        maxMileage={maxMileage}
        maxDrivingHours={maxDrivingHours}
        maxWorkingHours={maxWorkingHours}
        minWorkingHours={minWorkingHours}
      />

      <HorizontalRuler />

      <Loads loads={loads} />

      <HorizontalRuler />

      <Tags tags={tags} />

      <HorizontalRuler />

      <ReadonlyBlock>
        <ReadonlyLabel>{texts.speed}</ReadonlyLabel>
        <DrivingTimeSlider
          name="speedFactor"
          min={0}
          max={150}
          scaleMax={150}
          steps={sliderSteps}
          onChange={() => undefined}
          value={speedFactor - 50}
          disabled={true}
          getBadgeText={() => ''}
          hideBadge={true}
          getCoordinatesStyle={getCoordinatesStyle}
          outerStyle={{
            marginTop: 8,
          }}
        />
      </ReadonlyBlock>

      <HorizontalRuler />

      <Costs
        metric={metric}
        fixedCost={fixedCost}
        serviceTime={serviceTime}
        drivingTime={drivingTime}
        idleTime={idleTime}
        breakTime={breakTime}
        perMile={perMile}
        perStop={perStop}
      />

      <HorizontalRuler />

      <ReadonlyBlock>
        <ReadonlyLabel empty={!notes} primary>
          {texts.notes}
        </ReadonlyLabel>
        {!!notes && (
          <ReadonlyField>
            <ReadonlyFieldParagraph>{notes}</ReadonlyFieldParagraph>
          </ReadonlyField>
        )}
      </ReadonlyBlock>

      <HorizontalRuler style={{ height: 0, marginBottom: 60 }} />
    </>
  )
}
