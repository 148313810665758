import styled from 'styled-components'

type Props = {
  size?: number
}

const styledComponent = styled.div<Props>`
  position: relative;
  flex: 0 0 auto;
  display: block;
  width: ${p => p.size || 42}px;
  height: ${p => p.size || 42}px;
  border-radius: 8px;
  user-select: none;

  transform: translateZ(0px);
`

styledComponent.displayName = 'OrderAvatarContainer'
export default styledComponent
