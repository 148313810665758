import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    missingName: translate({ id: 'gps.places.edit.validationMissingName' }),
    invalidLocation: translate({ id: 'gps.places.edit.validationInvalidLocation' }),
    labelName: translate({ id: 'gps.places.edit.nameTitle' }),
    labelLocation: translate({ id: 'global.location' }),
    footerTitle: translate({ id: 'gps.places.edit.formErrorsTitle' }),
    headerTitle: translate({ id: 'gps.places.edit.headerTitle' }),
    headerDescription: translate({ id: 'gps.places.edit.headerDescription' }),
  }))

  return api
}
