import { intl } from '@/intl'

export const getExecutionDetailsTitle = (): string =>
  intl.translate({ id: 'orders.form.reportedExecutionDetails' })

export const getReportedTimeInLabel = (): string =>
  intl.translate({ id: 'orders.form.reportedTimeIn' })

export const getReportedTimeOutLabel = (): string =>
  intl.translate({ id: 'orders.form.reportedTimeOut' })

export const getReportedStatusWithDate = (date: string): string =>
  intl.translate({ id: 'orders.form.reportedStatusWithDate', values: { date } })

export const getReportedStatus = (): string => intl.translate({ id: 'orders.form.reportedStatus' })

export const getReportedStatusReason = (): string =>
  intl.translate({ id: 'orders.form.reportedStatusReason' })

export const getDetectedExecutionDetailsTitle = (): string =>
  intl.translate({ id: 'orders.form.detectedExecutionDetails' })

export const getPODTitle = (): string => intl.translate({ id: 'global.POD' })

export const getNoteLabel = (): string => intl.translate({ id: 'global.note' })

export const getPicturesLabel = (): string => intl.translate({ id: 'global.pictures' })
export const getPictureLabel = (): string => intl.translate({ id: 'global.picture' })
export const getVoiceMemosLabel = (): string => intl.translate({ id: 'global.voiceMemos' })
export const getVoiceMemoLabel = (): string => intl.translate({ id: 'global.voiceMemo' })
export const getSignaturesLabel = (): string => intl.translate({ id: 'global.signatures' })
export const getCustomerSignatureLabel = (): string =>
  intl.translate({ id: 'global.customerSignature' })

export const getNoneText = (): string => intl.translate({ id: 'global.none' })
export const getEditText = (): string => intl.translate({ id: 'global.edit' })
export const getRemoveText = (): string => intl.translate({ id: 'global.remove' })

export const getValidationExecutionEventsTimeOutOverlappingText = (): string =>
  intl.translate({ id: 'orders.edit.executions.validation.timeInOut.overlap' })
export const getExecutionEventsTimeInOutFieldText = (): string =>
  intl.translate({ id: 'orders.edit.executions.timeInOut' })
export const getValidationExecutionEventsTimeOutAcrossDayEndText = (): string =>
  intl.translate({ id: 'orders.edit.executions.validation.timeOut.acrossDayEnd' })

export const getBarcodesFieldText = (): string =>
  intl.translate({ id: 'orders.field.barcode.label' })

export const getInvalidLoadValueText = (): string =>
  intl.translate({ id: 'orders.form.executionEvents.dynamicLoadsCf.invalidValue' })
