import type { ChangeEvent } from 'react'

import { useCallback } from 'react'

import { SearchInput } from '@/components/primitives/SearchInput'
import { setMainSelection } from '@/atoms'

import { useVisibleOrderIds } from './hooks/useVisibleOrderIds'
import { useFilterOrders } from './hooks/useFilterOrders'
import { useTexts } from './useTexts'

export function FilterOrders() {
  const visibleOrderIds = useVisibleOrderIds()
  const [query, setQuery] = useFilterOrders()
  const texts = useTexts()

  const onChange = useCallback(
    (value: string) => {
      // HACK: This will allow to use @/formUi useDebounceText with @/form-legacy components
      setQuery({ currentTarget: { value } } as ChangeEvent<HTMLInputElement>)
    },
    [setQuery],
  )

  const onCounterClick = useCallback(() => {
    setMainSelection('orderSteps', visibleOrderIds)
  }, [visibleOrderIds])

  return (
    <SearchInput
      filter={query}
      onChange={onChange}
      placeholder={texts.placeholder}
      data-test="map-controls_filter-orders-input"
      data-trackid="map-controls_filter-orders-input"
      extraLabelOnClick={visibleOrderIds.length > 0 ? onCounterClick : undefined}
      extraLabel={query.trim().length > 0 ? texts.results(visibleOrderIds.length) : undefined}
      testId="map-controls-filter-orders"
      trackid="map-controls-filter-orders"
    />
  )
}
