import { FormControlLabel, FormControl, RadioGroup, FormLabel, Radio, Box } from '@mui/material'

import { VerticalLayout } from '@/components/layout/VerticalLayout'
import { Calendar } from '@/components/Calendar'
import { WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Body() {
  const { onModeChange, onSelectionChange } = useControllerActions()

  const texts = useTexts()
  const {
    status,
    data: {
      firstNonArchivedDay,
      daysWithOrders,
      disabledDays,
      selection,
      minDate,
      maxDate,
      today,
      mode,
    },
  } = useController()

  const submitting = status === 'submitting'

  const warningCardTitle = texts.areYouSure
  const warningCardDescription =
    mode === 'replace' ? texts.warningSimulationReplace : texts.warningSimulationAdd

  return (
    <VerticalLayout marginTop={32} width={580}>
      <Box marginBottom={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{texts.mode}</FormLabel>

          <RadioGroup
            aria-label="copyMethod"
            onChange={onModeChange}
            name="copyMethod"
            value={mode}
          >
            <FormControlLabel
              value="add"
              disabled={submitting}
              control={<Radio />}
              label={texts.add}
            />
            <FormControlLabel
              value="replace"
              disabled={submitting}
              control={<Radio />}
              label={texts.replace}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Calendar
        months={2}
        layout="date"
        today={today}
        minDate={minDate}
        maxDate={maxDate}
        selection={selection}
        disabledDays={disabledDays}
        onChange={onSelectionChange}
        highlightedDays={daysWithOrders}
        tooltipIntlText={texts.numberOfOrders}
        firstNonArchivedDay={firstNonArchivedDay}
      />

      <Box marginTop={2}>
        <WarningCard
          preset="warning"
          title={warningCardTitle}
          description={warningCardDescription}
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
        />
      </Box>
    </VerticalLayout>
  )
}
