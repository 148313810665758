import { type SchedulerEventModel } from '@bryntum/schedulerpro'

import { durationUtils, timeUtils } from '@/server-data'
import { intl } from '@/intl'

import { Typography } from '../Typography'
import { Stack } from '../Stack'

import { useTooltipData } from './hooks/useTooltipData'
import { useTexts } from './useTexts'

interface Props {
  eventRecord: SchedulerEventModel
  userConfig: uui.domain.UserConfiguration
}

function computeColor(seconds: number) {
  return seconds > 0 ? '#ef5350' : '#4caf50'
}

export function SetupTimeTooltip(props: Props) {
  const { eventRecord, userConfig } = props
  const { start, duration, end, delay, stepType } = useTooltipData(eventRecord)
  const texts = useTexts()

  return (
    <Stack spacing={0.75}>
      <Stack direction="row">
        <Typography variant="h6">
          {stepType === 'preparation' || stepType === 'closeOut'
            ? texts.setupTime.title(stepType)
            : ''}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Stack flexGrow={1}>
          <Typography variant="caption" color="#bdbdbd">
            {texts.setupTime.startAt}
          </Typography>

          <Typography variant="subtitle2">{timeUtils.formatTime(userConfig)(start)}</Typography>

          {!!delay && delay.startSeconds !== 0 && (
            <Typography variant="subtitle2" color={computeColor(delay.startSeconds)}>
              {`${
                delay.startSeconds > 0 ? '+' : delay.startSeconds < 0 ? '-' : ''
              }${durationUtils.formatMinutes(intl.translate, delay.startSeconds)}`}
            </Typography>
          )}
        </Stack>

        <Stack flexGrow={1}>
          <Typography variant="caption" color="#bdbdbd">
            {texts.setupTime.timeSpan}
          </Typography>

          <Typography variant="subtitle2">
            {durationUtils.formatMinutes(intl.translate, duration)}
          </Typography>

          {/* We won't show delays under a minute */}
          {!!delay && Math.abs(delay.durationSeconds) > 60 && (
            <Typography variant="subtitle2" color={computeColor(delay.durationSeconds)}>
              {`${delay.durationSeconds > 0 ? '+' : '-'}${durationUtils.formatMinutes(
                intl.translate,
                delay.durationSeconds,
              )}`}
            </Typography>
          )}
        </Stack>

        <Stack flexGrow={1}>
          <Typography variant="caption" color="#bdbdbd">
            {texts.setupTime.endAt}
          </Typography>

          <Typography variant="subtitle2">{timeUtils.formatTime(userConfig)(end)}</Typography>

          {!!delay && delay.endSeconds !== 0 && (
            <Typography variant="subtitle2" color={computeColor(delay.endSeconds)}>
              {`${
                delay.endSeconds > 0 ? '+' : delay.endSeconds < 0 ? '-' : ''
              }${durationUtils.formatMinutes(intl.translate, delay.endSeconds)}`}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
