import { useCallback } from 'react'

import { Trash, Edit } from '@/icons'
import { useEditingStateWithCtx } from '@/atoms'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'

import { useRecurrence } from '../../../hooks/useRecurrence'
import { useTexts } from '../hooks/useTexts'

interface Props {
  setView: (view: 'default' | 'delete') => void
}

export function Actions(props: Props) {
  const { setView } = props

  const texts = useTexts()

  const { setEditing } = useEditingStateWithCtx('driversAssignment')

  const recurrence = useRecurrence()
  const recurrenceType = recurrence?.type ?? 'any'

  const onDelete = useCallback(() => {
    setView('delete')
  }, [setView])

  const handleStartEditing = useCallback(
    () => setEditing([recurrenceType]),
    [setEditing, recurrenceType],
  )

  // any recurrence can not be deleted
  const anyRecurrence = recurrenceType === 'any'

  return (
    <SecondaryColumnHeaderActionsRow centered>
      <ReadOnlyTooltip
        ignoreReasons={['courier']}
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={onDelete}
            disabled={anyRecurrence || preventEditing}
            data-trackid="setup-vehicleassignment__delete"
            testid="setup-vehicleassignment__delete"
          />
        )}
      />
      <ReadOnlyTooltip
        ignoreReasons={['courier']}
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.edit}
            icon={<Edit />}
            onClick={handleStartEditing}
            disabled={preventEditing}
            data-trackid="setup-vehicleassignment__edit"
            testid="setup-vehicleassignment__edit"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
