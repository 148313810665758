import { Box } from '@mui/material'

import {
  Dot,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  ReadonlyFieldRowPart,
} from '@/forms-legacy'
import { colorsUtils } from '@/utils'

interface Props {
  item: uui.domain.client.gps.wwgps.DeviceInfo
}

export function ListItem(props: Props) {
  const { label, deviceId, iconColor2 } = props.item

  const color = colorsUtils.getValidHexColor(iconColor2)

  return (
    <ReadonlyField>
      <ReadonlyFieldRow style={{ justifyContent: 'space-between' }}>
        <ReadonlyFieldLabel>{label}</ReadonlyFieldLabel>
        <ReadonlyFieldRowPart>
          <ReadonlyLabel small>{deviceId}</ReadonlyLabel>
          <Box width={14} height={14} marginLeft={1}>
            <Dot innerColor={color} outerColor={color} />
          </Box>
        </ReadonlyFieldRowPart>
      </ReadonlyFieldRow>
    </ReadonlyField>
  )
}
