import { VerticalLayout } from '@/components/layout'
import { WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useTexts } from '../useTexts'

export function PendingBody() {
  const texts = useTexts()

  return (
    <VerticalLayout marginTop={22}>
      <WarningCard
        preset="warning"
        title={texts.transactionWarningCardTitle}
        description={texts.transactionWarningCardDescription}
        Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
      />
    </VerticalLayout>
  )
}
