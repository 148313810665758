import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({
      id: 'sms4.settings.communication.notifications.testModal.header.title',
    }),

    subtitle: translate({
      id: 'sms4.settings.communication.notifications.testModal.header.subtitle.phone',
    }),
  }))

  return api
}
