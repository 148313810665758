import { useCallback } from 'react'

import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { deletePlaces } from '@/features/domain/place'

export function useActions(
  places: uui.domain.client.gps.wwgps.Place[],
  setView: (view: 'default' | 'delete') => void,
) {
  const toast = useNotification()
  const isUnmounted = useIsUnmounted()
  const dispatch = useAppDispatch()

  const onSwitchToDeletePanel = useCallback(() => setView('delete'), [setView])
  const onSwitchToDefaultPanel = useCallback(() => setView('default'), [setView])

  const onDelete = useCallback(async () => {
    try {
      const ids = places.map(p => p.id)
      const result = await dispatch(deletePlaces(ids))

      if (!deletePlaces.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      if (!isUnmounted()) {
        setView('default')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [setView, dispatch, toast, places, isUnmounted])

  return {
    onSwitchToDeletePanel,
    onSwitchToDefaultPanel,
    onDelete,
  }
}
