import styled from 'styled-components'

export interface Props extends workwave.Div {
  height: number
  width: number
}

const getWidth = ({ width }: Props) => width
const getHeight = ({ height }: Props) => height

const comp = styled.button<Props>``
const styledComponent = styled(comp).attrs<Props>(() => ({
  className: 'o-warning-overlay__overlay-background',
}))`
  background: rgb(255, 255, 255, 0.65);
  color: #000;
  height: ${props => getHeight(props)}px;
  width: ${props => getWidth(props)}px;
`

export default styledComponent
