import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getColorValue, isDark, theme } from '@/styles'
import { selectGpsTrackingProvider } from '@/features/domain/user'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectDrivers } from '@/features/domain/driver'
import * as uvHelpers from '@/local/server-data/domain/helpers/unifiedVehicle'

export function useStyles(vehicle: uui.domain.client.UnifiedVehicle) {
  const drivers = useSelector(selectDrivers)
  const calendarRange = useSelector(selectCalendarRange)
  const trackingProvider = useSelector(selectGpsTrackingProvider)

  return useMemo(() => {
    // this hook will run in the tracking list
    // where only a single date could be selected
    if (calendarRange.minDate !== calendarRange.maxDate) return {}

    const vehicleColor = uvHelpers.getPrimaryColor(
      drivers,
      vehicle,
      trackingProvider,
      calendarRange.minDate,
    )

    const backgroundColor = getColorValue(vehicleColor)
    const color = isDark(backgroundColor) ? theme.colors.$pureWhite : theme.colors.$nightRider

    return {
      color,
      backgroundColor,
    }
  }, [vehicle, drivers, trackingProvider, calendarRange])
}
