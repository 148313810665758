import styled from 'styled-components'

const BaseButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  padding: 0 10px;
  border: 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 165px;
  height: 28px;
  border-radius: 6px;

  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
  line-height: 1;

  color: ${p => p.theme.colors.$pureWhite};
  background: ${p => p.theme.colors.$pigmentGreen};

  text-align: left;

  cursor: pointer;
  user-select: none;
  outline: 0;
  transition: background 0.3s ease;

  &:disabled {
    cursor: not-allowed;
    background: ${p => p.theme.colors.$whiteSmoke};
  }
`

BaseButton.displayName = 'BaseButton'
export default BaseButton
