import styled from 'styled-components'

export const ButtonPrimitive = styled.button`
  align-items: center;
  background: none;
  border: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 8px 17px;
  opacity: 1;
  outline: none;
  transition: background 0.3s ease;

  &:enabled&:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`

ButtonPrimitive.displayName = 'ButtonPrimitive'
