import { useSelector } from 'react-redux'
import { selectRegionMapStyles } from '@/features/domain/ui'

export const useRegionsMapStyle = () => {
  const { mode, custom } = useSelector(selectRegionMapStyles)

  if (Object.keys(custom).length > 0) return

  return mode
}
