import { useCallback } from 'react'

import { resetCrudSelection } from '@/atoms'

export function useActions() {
  const onClose = useCallback(() => {
    resetCrudSelection('alerts')
  }, [])

  return {
    onClose,
  }
}
