import type { RouteDetailEntry } from '../../../../types'
import { Box, Stack } from '@mui/material'

import { RoutePreparationEvent } from './components/RoutePreparationEvent'
import { RouteCloseoutEvent } from './components/RouteCloseoutEvent'
import { RouteOrdersEvent } from './components/RouteOrdersEvent'
import { StartEvent } from './components/StartEvent'
import { EndEvent } from './components/EndEvent'
import { Driver } from './components/Driver'

interface Props {
  routeColor: string
  routeDetailsData: RouteDetailEntry[]
}

export function RouteDetailsView(props: Props) {
  const { routeDetailsData, routeColor } = props
  const border = routeDetailsData.length > 1 ? '1px solid' : undefined

  return (
    <Stack spacing={1} padding={1}>
      {routeDetailsData.map((entry, index) => (
        <Box
          key={`routeDetail_${index}`}
          border={border}
          padding={1}
          borderColor="grey.300"
          data-testid="routeDriver"
          borderRadius={1}
        >
          <Stack padding={[0, 1]} spacing={3}>
            <Driver name={entry.driverName} driverId={entry.driverId} />
            {entry.start && <StartEvent entry={entry.start} routeColor={routeColor} />}

            {entry.preparation && (
              <RoutePreparationEvent data={entry.preparation} routeColor={routeColor} />
            )}

            {entry.ordersCount > 0 && <RouteOrdersEvent ordersCount={entry.ordersCount} />}

            {entry.closeout && <RouteCloseoutEvent data={entry.closeout} routeColor={routeColor} />}

            {entry.end && <EndEvent entry={entry.end} routeColor={routeColor} />}
          </Stack>
        </Box>
      ))}
    </Stack>
  )
}
