import type { ActionPermission } from '../../../../../hooks/useVehiclesNavigoMachine/types'

import { useMemo } from 'react'

import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Report } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  permission:
    | ActionPermission
    | 'disabledNoDevice'
    | 'disabledLoadingGpsData'
    | 'disabledNoPhysicalDevice'
  vehicles: uui.domain.client.UnifiedVehicle[]
  onClick: () => void
}

export function ReportButton(props: Props) {
  const { permission, vehicles, onClick } = props
  const texts = useTexts()

  const disabled = permission.startsWith('disabled')

  const iconColor = disabled ? '$silver' : '$nightRider'

  const tooltipTitle = useMemo(() => {
    switch (permission) {
      case 'disabledNoPhysicalDevice':
        return texts.disabledReport

      case 'disabledLoadingGpsData':
        return texts.disabledLoadingGpsData

      case 'disabledNoDevice':
        return vehicles.length === 1
          ? texts.disabledNoDevice(
              vehicles[0].hasRoutingLicense ? vehicles[0].vehicle.externalId : '',
            )
          : ''

      default:
        return ''
    }
  }, [texts, permission, vehicles])

  return (
    <Tooltip placement="top" title={tooltipTitle} disabled={!disabled}>
      <NavigoIconButton
        onClick={onClick}
        disabled={disabled}
        text={texts.runReport}
        Icon={<Report size={16} color={iconColor} />}
        testid="navigo-vehicle-overview-actions-reports"
      />
    </Tooltip>
  )
}
