import { useSelector } from 'react-redux'

import { conversionUtils } from '@/server-data'
import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'
import { selectUserConfiguration } from '@/features/domain/user'

import { Completed, NotCompleted, NoStatus } from '@/icons'

import * as OrderTexts from '../../../intl'

import { getStatusData } from '../utils'

import { Props, StatusData } from '../typings'

export function ReportedStatus(props: Props) {
  const { trackingData } = props
  const userConfig = useSelector(selectUserConfiguration)

  const { statusSec, status }: StatusData = getStatusData(trackingData)
  const reportedStatusLabel: string =
    statusSec > 0
      ? OrderTexts.getReportedStatusWithDate(
          conversionUtils.convertTime(userConfig)(statusSec, false),
        )
      : OrderTexts.getReportedStatus()

  const reportedStatusValue: string = OrderTexts.getOrderStatusText(status)

  const reportedStatusIcon = status ? (
    status === 'done' ? (
      <Completed background="$pigmentGreen" color="$pureWhite" size={16} />
    ) : status === 'reschedule' ? (
      <NotCompleted background="$outrageousRed" color="$pureWhite" size={16} />
    ) : (
      <NoStatus background="$silver" size={16} />
    )
  ) : undefined

  return (
    <ReadonlyBlock>
      <ReadonlyLabel>{reportedStatusLabel}</ReadonlyLabel>

      <ReadonlyField>
        <ReadonlyFieldRow>
          {reportedStatusIcon}
          <ReadonlyFieldLabel style={{ marginLeft: 8 }}>{reportedStatusValue}</ReadonlyFieldLabel>
        </ReadonlyFieldRow>
      </ReadonlyField>
    </ReadonlyBlock>
  )
}
