import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { resetCrudSelection, useCrudSelection } from '@/atoms'
import { selectUsersListStructure } from '@/features/domain/lists'

export function useOnAllUsersToggle() {
  const [selection, setSelection] = useCrudSelection('users')

  const structure = useSelector(selectUsersListStructure)

  return useCallback(() => {
    const allSelected = structure.shownItems <= selection.length

    const userIds: string[] = []

    for (const listItem of structure.list) {
      if (typeof listItem === 'string') {
        userIds.push(listItem)
      }
    }

    if (allSelected) {
      resetCrudSelection('users')
    } else {
      setSelection(userIds)
    }
  }, [setSelection, selection, structure])
}
