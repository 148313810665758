import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { conversionUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export const useGetDistanceUnitOfMeasure = () => {
  const userConfig = useSelector(selectUserConfiguration)

  return useMemo(() => conversionUtils.getDistanceUnitOfMeasure(userConfig), [userConfig])
}
