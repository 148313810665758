import { Typography } from '@mui/material'
import { useController } from '../../hooks/useController'
import { useTexts } from './useTexts'

export function Body() {
  const {
    data: { targetLayout },
  } = useController()

  const texts = useTexts()

  const bodyText = targetLayout === 'split' ? texts.targetSplitBody : texts.targetMapBody

  return <Typography>{bodyText}</Typography>
}
