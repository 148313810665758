import { type ReactNode, useCallback, useState, useRef } from 'react'
import { LinearProgress as MuiLinearProgress, Stack, styled } from '@mui/material'
import { useIsLoading } from '@/atoms'
import { useNavigate } from '@/routing'

import '../../styles/scheduler.css'

import {
  type MovePairedOrdersPayload,
  useMovePairedOrdersModal,
} from '@/components/modals/MovePairedOrdersModal'
import { SchedulerHeader } from './components/SchedulerHeader'
import { ColumnManagerPanel } from './components/ColumnManagerPanel'
import { useInitializeScheduler } from './hooks/useInitializeScheduler'
import { useColumnsPanel } from './hooks/useColumnsPanel'
import { useHandleKeyboardNavigation } from './hooks/useHandleKeyboardNavigation'

interface Props {
  ResizeHandle?: ReactNode
}

const SCHEDULER_ROOT_ID = 'scheduler-root'

const LinearProgress = styled(MuiLinearProgress)({
  position: 'absolute',
  top: '82px',
  left: 0,
  width: '100%',
})

const SchedulerRoot = styled('div')(() => ({
  width: '100%',
  height: '100%',
}))

export function Scheduler(props: Props) {
  const { ResizeHandle } = props

  const containerRef = useRef<HTMLDivElement>(null)

  useHandleKeyboardNavigation()

  const {
    openColumnsPanel,
    closeColumnsPanel,
    columnsPanelVisible,
    onHeaderMenuBeforeShow,
    columnsPanelCoordinates,
  } = useColumnsPanel(containerRef)

  const [movePairedOrdersPayload, setMovePairedOrdersPayload] = useState<
    MovePairedOrdersPayload | undefined
  >(undefined)

  const { Modal, show } = useMovePairedOrdersModal()
  const navigate = useNavigate<uui.routing.Routing | uui.routing.Setup>(true)

  const onShowMovePairedOrdersModal = useCallback(
    (payload: MovePairedOrdersPayload) => {
      setMovePairedOrdersPayload(payload)
      show()
    },
    [show],
  )

  const showLoader = useIsLoading('schedulerLoading')

  const scheduler = useInitializeScheduler(
    SCHEDULER_ROOT_ID,
    navigate,
    onShowMovePairedOrdersModal,
    onHeaderMenuBeforeShow,
  )

  return (
    <Stack width="100%" height="100%" position="relative">
      <SchedulerHeader
        scheduler={scheduler}
        editColumns={openColumnsPanel}
        ResizeHandle={ResizeHandle}
      />

      <div className="o-scheduler-container">
        <SchedulerRoot id={SCHEDULER_ROOT_ID} ref={containerRef}></SchedulerRoot>
      </div>

      {columnsPanelVisible && (
        <ColumnManagerPanel
          open={columnsPanelVisible}
          scheduler={scheduler}
          position={{ top: columnsPanelCoordinates.y, left: columnsPanelCoordinates.x }}
          close={closeColumnsPanel}
        />
      )}

      {!!movePairedOrdersPayload && <Modal payload={movePairedOrdersPayload} />}

      {showLoader && <LinearProgress />}
    </Stack>
  )
}
