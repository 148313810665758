import { styled } from '@mui/material'

import { Cross, Search } from '@/icons'

interface Props {
  open: boolean
  title?: string
  toggle: () => void
}

export const Container = styled('div', {
  name: 'Container',
})(() => ({
  marginLeft: 3,
  position: 'relative',
  opacity: 0.9,
}))

export const Toggler = styled('button', {
  name: 'Toggler',
})(() => ({
  backgroundColor: 'rgba(0,60,136,0.5)',
  textDecoration: 'none',
  borderRadius: 4,
  fontWeight: 'bold',
  lineHeight: '.4em',
  textAlign: 'center',
  fontSize: '1.14em',
  display: 'block',
  padding: 0,
  border: 'none',
  height: '32px',
  margin: 1,
  width: '32px',
  color: 'white',
}))

export function ToggleButton(props: Props) {
  const { open, title, toggle } = props

  const testId = open ? 'search-on-map-button-close' : 'search-on-map-button-open'

  return (
    <Container>
      <Toggler onClick={toggle} data-testid={testId} data-trackid={testId} title={title}>
        {open ? <Cross color="$pureWhite" size={12} /> : <Search color="$pureWhite" size={15} />}
      </Toggler>
    </Container>
  )
}
