import type { BulkLoads } from '../../../../types'
import type { AddLoadOption as Option } from '../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectLoadsFromVehicles } from '@/features/domain/vehicle'
import { selectLoadsFromOrders } from '@/features/domain/order'

export function useAddLoadsOptions(bulkLoads: BulkLoads) {
  const vehicleLoads = useSelector(selectLoadsFromVehicles)
  const orderLoads = useSelector(selectLoadsFromOrders)

  return useMemo(() => {
    const loads = Array.from(new Set([...vehicleLoads, ...orderLoads]))

    const options = loads.reduce<Option[]>((acc, label) => {
      const load = bulkLoads.loads[label]
      if (load?.status === 'exact') return acc

      const shareCount = load?.status === 'partial' ? load.value : -1
      acc.push({
        label,
        shareCount,
      })
      return acc
    }, [])

    options.sort(sortOptions)
    return options
  }, [vehicleLoads, bulkLoads, orderLoads])
}

// options has to be sorted by count descending than alphabetically
function sortOptions(a: Option, b: Option) {
  if (a.shareCount === b.shareCount) {
    return a.label.localeCompare(b.label)
  }
  return -(a.shareCount - b.shareCount)
}
