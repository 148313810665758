import { useSelector } from 'react-redux'

import { InspectPinMode, Map as MainMap, MapLayer } from '@/map'
import { selectTerritoryCenter } from '@/features/domain/territory'
import { SearchOnMap } from '@/components/SearchOnMap'

interface Props {
  bottomContentPadding?: number
  disableAutoCenter?: boolean
}

export function Map(props: Props) {
  const { disableAutoCenter, bottomContentPadding } = props

  const territoryCenter = useSelector(selectTerritoryCenter)
  const center = disableAutoCenter ? undefined : territoryCenter

  return (
    <MainMap
      selectionContext="main"
      mapId="ww-map"
      center={center}
      bottomContentPadding={bottomContentPadding}
    >
      <InspectPinMode mode="normal" />

      <MapLayer uid="order" interactive visible />
      <MapLayer uid="place" interactive visible />
      <MapLayer uid="depot" interactive visible />
      <MapLayer uid="vehicle" interactive visible />
      <MapLayer uid="region" visible />
      <MapLayer uid="roadSegment" visible />
      <MapLayer uid="geofence" visible={false} />
      <MapLayer uid="routePolyline" visible />

      <MapLayer uid="breadcrumbPath" visible />
      <MapLayer uid="breadcrumbPoints" visible interactive />

      <SearchOnMap />
      {/* TODO: Add remaining layers once they are implemented */}
    </MainMap>
  )
}
