import type { ActionPermission } from '../../../../../hooks/useVehiclesNavigoMachine/types'

import { useMemo } from 'react'

import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Email } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  permission:
    | ActionPermission
    | 'disabledNoDevice'
    | 'disabledNoDriver'
    | 'disabledLoadingGpsData'
    | 'disabledDriverNotTracked'
    | 'disabledMultipleVehicles'
  vehicles: uui.domain.client.UnifiedVehicle[]
  onClick: () => void
}

export function SendMessageButton(props: Props) {
  const { permission, onClick, vehicles } = props
  const texts = useTexts()

  const disabled = permission.startsWith('disabled')

  const tooltipTitle = useMemo(() => {
    switch (permission) {
      case 'disabledNoDevice':
        return vehicles.length === 1
          ? texts.disabledNoDevice(
              vehicles[0].hasRoutingLicense ? vehicles[0].vehicle.externalId : '',
            )
          : ''

      case 'disabledNoDriver':
        return texts.disabledNoDriver

      case 'disabledDriverNotTracked':
        return texts.disabledDriverNotTracked

      case 'disabledLoadingGpsData':
        return texts.disabledLoadingGpsData

      case 'disabledMultipleVehicles':
        return texts.disabledMultipleVehicles

      case 'disabled':
        return texts.disabledNotAvailableInThePast

      default:
        return ''
    }
  }, [texts, permission, vehicles])

  return (
    <Tooltip placement="top" disabled={!disabled} title={tooltipTitle}>
      <NavigoIconButton
        onClick={onClick}
        disabled={disabled}
        text={texts.sendMessage}
        testid="navigo-vehicle-overview-actions-sendMessage"
        Icon={<Email size={20} color={disabled ? '$silver' : '$nightRider'} />}
      />
    </Tooltip>
  )
}
