import type { ImportCompletePayload } from './types'

import { parseImportWizardCookie } from './utils/parseImportWizardCookie'
import {
  updateImportWizardCookie,
  deleteImportWizardCookie,
  createImportWizardCookie,
} from './utils/cookie'

const state = parseImportWizardCookie()

export const importWizardSession = {
  getState() {
    return state
  },

  initializeImport(base64EncodedContent: string) {
    createImportWizardCookie(base64EncodedContent)
  },

  setImportCompleted(payload: ImportCompletePayload) {
    if (!state) {
      throw Error('Cannot set as completed an undefined state')
    }

    state.step = 'importCompleted'

    if (state.step === 'importCompleted') {
      state.lowAccuracyOrders = payload.lowAccuracyOrders
      state.importedOrders = payload.importedOrders
    }

    updateImportWizardCookie(state)
  },

  setImportCanceled() {
    if (!state) {
      throw Error('Cannot set as completed an undefined state')
    }

    state.step = 'importCanceled'

    updateImportWizardCookie(state)
  },

  deleteCookie() {
    deleteImportWizardCookie()
  },
}
