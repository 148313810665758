import type { TimeFormat } from '../types'

import { useEffect, useReducer, useRef } from 'react'

import { useValidateOn } from '../../../hooks/useValidateOn'
import { convertTimeInput, formatTime } from '../utils'
import { useActions } from './useActions'

type FieldError = 'emptyField' | 'invalidFormat' | null

export interface State {
  open: boolean
  error: FieldError
  timeValue: number
  inputValue: string
  invalidInput: boolean
  selectValue: number | null
}
export type UpdateAction = Partial<State>

/**
 * Updates the component state with values from updateAction
 */
function timePickerStateReducer(state: State, updateAction: UpdateAction) {
  return { ...state, ...updateAction }
}

/**
 * Initialize the component state
 */
function timePickerStateInitializer(params: { value: number; timeFormat: TimeFormat }) {
  const { value, timeFormat } = params
  const initialInputValue = formatTime(value, timeFormat)
  const invalidFormat = convertTimeInput(initialInputValue, timeFormat) === 'invalid'
  const error: FieldError =
    initialInputValue.trim() === '' ? 'emptyField' : invalidFormat ? 'invalidFormat' : null

  return {
    error,
    open: false,
    timeValue: value,
    selectValue: null,
    invalidInput: invalidFormat,
    inputValue: initialInputValue,
  }
}

/**
 * Instances the component state and keep it up to date.
 * Returns actual state and actions
 */
export function useTimePicker(
  timeFormat: TimeFormat,
  options: number[],
  validations: ReturnType<typeof useValidateOn>,
  value: number,
  updateFormValue: (value: number) => void,
  allowUnlistedValues: boolean,
) {
  const { validateOnChange } = validations

  const [componentState, updateComponentState] = useReducer(
    // Reducer
    timePickerStateReducer,

    // Initializer arguments
    { value, timeFormat },

    // Initializer
    timePickerStateInitializer,
  )

  const actions = useActions({
    updateComponentState,
    allowUnlistedValues,
    componentState,
    validations,
    timeFormat,
    options,
  })

  const { timeValue, inputValue } = componentState

  const apiRef = useRef({ updateFormValue })
  useEffect(() => {
    apiRef.current.updateFormValue = updateFormValue
  }, [updateFormValue])

  const formValueRef = useRef(value)
  useEffect(() => {
    formValueRef.current = value
    updateComponentState({ timeValue: value })
  }, [value])

  // Update TextInput value once the field value changes
  useEffect(() => {
    updateComponentState({
      inputValue: formatTime(timeValue, timeFormat),
    })

    validateOnChange()
  }, [validateOnChange, timeFormat, timeValue])

  // Convert valid time input from the TextInput and updates the Dropdown value
  useEffect(() => {
    if (inputValue.trim() === '') {
      updateComponentState({ invalidInput: true, error: 'emptyField' })
      return
    }

    const parsedInput = convertTimeInput(inputValue, timeFormat)
    const isInvalid =
      parsedInput === 'invalid' || (!allowUnlistedValues && !options.includes(parsedInput))

    // If the value is invalid let's close the dropdown
    const updateValue: Partial<State> = isInvalid
      ? { invalidInput: true, error: 'invalidFormat' }
      : { invalidInput: false, selectValue: parsedInput, error: null }

    updateComponentState(updateValue)
  }, [allowUnlistedValues, timeFormat, options, inputValue])

  // Update form value
  useEffect(() => {
    if (timeValue !== formValueRef.current) apiRef.current.updateFormValue(timeValue)
  }, [timeValue])

  return {
    actions,
    componentState,
  }
}
