import type { IconContainerProps } from './typings'

import { ValidColor, theme } from '@/local/components'

import { IconContainer } from './elements/IconContainer'

type Props = IconContainerProps & {
  background: ValidColor
}

export function NotCompleted(props: Props) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <circle cx="8" cy="8" r="8" fill={theme.colors[props.background]} />
        <path
          fill={props.color ? theme.colors[props.color] : 'currentColor'}
          d="M6.955 3.222c.247-.266.558-.398.94-.398.383 0 .694.13.941.393.242.26.367.59.367.986 0 .216-.21 1.653-.627 4.312a.4.4 0 01-.395.338h-.549a.4.4 0 01-.394-.336c-.433-2.66-.65-4.098-.65-4.314 0-.391.125-.718.367-.98zm1.863 8.976c-.258.25-.566.377-.922.377-.356 0-.665-.127-.922-.377a1.229 1.229 0 01-.386-.914c0-.356.128-.664.386-.922.257-.258.566-.386.922-.386.356 0 .664.128.922.386.257.258.385.566.385.922 0 .359-.128.661-.385.914z"
        />
      </svg>
    </IconContainer>
  )
}
