import type { FormFields, FormErrors } from '../formFields'

import { useCallback } from 'react'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { useIsUnmounted, useNotification } from '@/hooks'
import { editTenantSourceCredentials } from '@/features/domain/gps'
import { useAppDispatch } from '@/store'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export function useOnSubmit() {
  const toast = useNotification()
  const formApi = useFormApi<FormFields, FormErrors>()
  const dispatch = useAppDispatch()
  const {
    update,
    close,
    data: { tenantSource },
  } = useController()
  const isUnmounted = useIsUnmounted()
  const texts = useTexts()

  return useCallback(async () => {
    try {
      formApi.setSubmitting(true)
      update({ status: 'submitting' })

      // Clear wrongCredentials flag
      formApi.change('wrongCredentials', false)

      await formApi.waitForValidation()

      const valid = await formApi.validate()

      // if the component has been unmounted during the async operation stop here
      if (isUnmounted()) return

      if (valid) {
        try {
          const { credentials } = formApi.getValues()
          const result = await dispatch(
            editTenantSourceCredentials({ tenantSourceId: tenantSource.id, credentials }),
          )

          if (editTenantSourceCredentials.rejected.match(result)) {
            const errorCode =
              result.payload?.type === 'rpcFailure' ? result.payload.error.errorCode : undefined

            if (errorCode === 400) {
              // if the component has been unmounted during the async operation stop here
              if (isUnmounted()) return

              formApi.change('wrongCredentials', true)

              // Clear FormState submitting mode
              formApi.setSubmitting(false)
              update({ status: 'ready' })

              // Validate the form again to show the error message
              await formApi.waitForValidation()
              await formApi.validate()

              return
            }

            throw new Error(result.payload?.message ?? 'Internal error')
          }
          // Clear FormState submitting mode
          formApi.setSubmitting(false)
          close?.()

          toast.success(texts.successToast)

          // if the component has been unmounted during the async operation stop here
          if (isUnmounted()) return

          // Clear FormState submitting mode
          formApi.setSubmitting(false)
          close?.()
        } catch (error) {
          formApi.setSubmitting(false)
        }
      } else {
        // Clear FormState submitting mode
        formApi.setSubmitting(false)
        update({ status: 'ready' })
      }
    } catch (e) {
      formApi.setSubmitting(false)
      update({ status: 'ready' })

      toast.error(e.message)
    }
  }, [formApi, toast, tenantSource, texts, update, close, dispatch, isUnmounted])
}
