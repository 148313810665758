import { Box, Stack } from '@mui/material'

import { MapControls } from '@/components/MapControls'
import { Navigo } from '@/components/Navigo'
import { useNavigoCategory } from '@/hooks'

import { Map } from './Map'

export function MapLayout() {
  const navigoCategory = useNavigoCategory()
  const navigoBottomPadding = navigoCategory ? 70 : 0

  // No Extract Map management here. The Map and the Extract Map cannot co-exist.

  return (
    <Stack width="100%" height="100%">
      <MapControls enableInspectPin canFilterOrders />
      <Map bottomContentPadding={navigoBottomPadding} />
      <Box width="100%" zIndex={2}>
        <Navigo />
      </Box>
    </Stack>
  )
}
