import { TrafficProfiles } from './components/TrafficProfiles'
import { TrafficAreas } from './components/TrafficAreas'

import { useListType } from './hooks/useListType'
import { useResetEditStateOnUnmount } from './hooks/useResetEditStateOnUnmount'

export function TrafficRoot() {
  const listType = useListType()

  useResetEditStateOnUnmount()

  return (
    <>
      {listType === 'trafficProfiles' && <TrafficProfiles />}
      {listType === 'trafficAreas' && <TrafficAreas />}
    </>
  )
}
