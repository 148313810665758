import { useCallback } from 'react'
import { proxy, useSnapshot } from 'valtio'

interface CollapseState {
  sidebar: boolean
  communication: boolean
  advanced: boolean
  driverMobileApp: boolean
  integrations: boolean
}

interface CollapseStatus {
  state: CollapseState
}

// ------------------------------------
// Default values
// ------------------------------------
const defaultState: CollapseState = {
  sidebar: false,
  communication: true,
  advanced: true,
  driverMobileApp: true,
  integrations: true,
}

const collapseStatusAtom = proxy<CollapseStatus>({ state: defaultState })

// ------------------------------------
// Write functions
// ------------------------------------

type SetCollapseStatus = (prev: CollapseState) => CollapseState
type SetCollapseStatusParam = SetCollapseStatus | Partial<CollapseState>

const setCollapsedStatus = (valueOrFunc: SetCollapseStatusParam) => {
  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(collapseStatusAtom.state, valueOrFunc(collapseStatusAtom.state))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      collapseStatusAtom.state[field] = valueOrFunc[field]
    }
  }

  return collapseStatusAtom
}

// ------------------------------------
// React Hooks
// ------------------------------------

export const useSidebarCollapsed = () => {
  const sidebarCollapsed = useSnapshot(collapseStatusAtom).state.sidebar

  const setter = useCallback((collapsed: boolean) => {
    setCollapsedStatus({ sidebar: collapsed })
  }, [])

  const toggler = useCallback(() => {
    setCollapsedStatus({ sidebar: !sidebarCollapsed })
  }, [sidebarCollapsed])

  return [sidebarCollapsed, setter, toggler] as const
}

export const useCommunicationCollapsed = () => {
  const communicationCollapsed = useSnapshot(collapseStatusAtom).state.communication

  const setter = useCallback((collapsed: boolean) => {
    setCollapsedStatus({ communication: collapsed })
  }, [])

  const toggler = useCallback(() => {
    setCollapsedStatus({ communication: !communicationCollapsed })
  }, [communicationCollapsed])

  return [communicationCollapsed, setter, toggler] as const
}

export const useAdvancedCollapsed = () => {
  const advancedCollapsed = useSnapshot(collapseStatusAtom).state.advanced

  const setter = useCallback((collapsed: boolean) => {
    setCollapsedStatus({ advanced: collapsed })
  }, [])

  const toggler = useCallback(() => {
    setCollapsedStatus({ advanced: !advancedCollapsed })
  }, [advancedCollapsed])

  return [advancedCollapsed, setter, toggler] as const
}

export const useDriverMobileAppCollapsed = () => {
  const driverMobileAppCollapsed = useSnapshot(collapseStatusAtom).state.driverMobileApp

  const setter = useCallback((collapsed: boolean) => {
    setCollapsedStatus({ driverMobileApp: collapsed })
  }, [])

  const toggler = useCallback(() => {
    setCollapsedStatus({ driverMobileApp: !driverMobileAppCollapsed })
  }, [driverMobileAppCollapsed])

  return [driverMobileAppCollapsed, setter, toggler] as const
}

export const useIntegrationsCollapsed = () => {
  const integrationsCollapsed = useSnapshot(collapseStatusAtom).state.integrations

  const setter = useCallback((collapsed: boolean) => {
    setCollapsedStatus({ integrations: collapsed })
  }, [])

  const toggler = useCallback(() => {
    setCollapsedStatus({ integrations: !integrationsCollapsed })
  }, [integrationsCollapsed])

  return [integrationsCollapsed, setter, toggler] as const
}
