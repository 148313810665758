import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useAddTestData } from './hooks/useAddTestData'
import { UploadJson } from './components/UploadJson'

export function AddTestData() {
  const {
    json,
    devices,
    loading,
    setJson,
    vehicles,
    deviceId,
    periodic,
    jsonValid,
    setDeviceId,
    setPeriodic,
    setJsonValid,
    onAddUploadedData,
    vehiclesByVehicleId,
  } = useAddTestData()

  return (
    <Stack gap={2}>
      <Typography>
        Choose a device id from the dropdown, than upload a json file, it must contains one of
        breadcrumbs or stops. The device id will be replaced into the json file, so every event will
        be attached to the selected device. <br />
        By choosing the periodic call, the events will be sent periodically (1 at the time every 20
        seconds) to the server and the time of each point will be updated to the current time.
      </Typography>
      <Typography variant="body2">
        Final note: even if the positions will be sent periodically, do not expect that events will
        come periodically. There is at most 15 seconds of delay.
      </Typography>

      <Stack direction="row" gap={2} alignItems="flex-start">
        <FormControl sx={{ width: 300 }}>
          <InputLabel>Device Id</InputLabel>
          <Select value={deviceId} label="Device Id" onChange={e => setDeviceId(e.target.value)}>
            {devices.map(device => {
              const vehicle = vehicles[vehiclesByVehicleId[device.correlationHandle]]
              const vehicleLabel = vehicle?.hasRoutingLicense ? vehicle.vehicle.externalId : ''

              return (
                <MenuItem value={device.deviceId} key={device.deviceId}>
                  {`${
                    device.deviceLabel ?? device.deviceId
                  } (Vehicle: ${vehicleLabel} - Device ID: ${device.deviceId})`}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <UploadJson
          json={json}
          setJson={setJson}
          jsonValid={jsonValid}
          setJsonValid={setJsonValid}
        />
      </Stack>
      <Stack direction="row" gap={2} alignItems="center">
        <FormControlLabel
          control={<Checkbox value={periodic} onChange={(_, checked) => setPeriodic(checked)} />}
          label="Perform periodic call"
        />

        <Stack display="block">
          <LoadingButton
            onClick={onAddUploadedData}
            disabled={!json || !jsonValid || !deviceId}
            loading={loading}
            variant="contained"
          >
            Add uploaded data
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  )
}
