import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    disableEditForArchivedDaysTitle: translate({
      id: 'global.archived.disableEdit',
    }),

    disableEditForCouriersTitle: translate({
      id: 'global.couriers.disableEdit',
    }),

    disableEditForViewersTitle: translate({
      id: 'global.viewers.disableEdit',
    }),

    disableEditForGuestsTitle: translate({
      id: 'global.guests.disableEdit',
    }),

    disableEditForOffline: translate({
      id: 'global.offline.disableEdit',
    }),

    transactionInProgressTitle: translate({
      id: 'global.disabledTooltip.transactionInProgress.title',
    }),
    transactionInProgressSubtitle: translate({
      id: 'global.disabledTooltip.transactionInProgress.subtitle',
    }),
  }))

  return api
}
