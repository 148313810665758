import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    useOdometer: translate({
      id: 'vehicles.form.edit.generalSettings.fields.maintenanceMileage.useOdometer',
    }),
    ignoreOdometer: translate({
      id: 'vehicles.form.edit.generalSettings.fields.maintenanceMileage.ignoreOdometer',
    }),
    label: translate({ id: 'vehicles.form.edit.generalSettings.fields.maintenanceMileage.label' }),
    info: translate({ id: 'vehicles.form.edit.generalSettings.fields.maintenanceMileage.info' }),
  }))

  return api
}
