import { useTexts } from '../../useTexts'

import { getShortExecutedLoadName } from './getShortExecutedLoadName'
import { getExecutedLoad } from './getExecutedLoad'

export function createExecutedLoadColumn(
  colDef: { field: string; hide?: boolean; width?: number },
  texts: ReturnType<typeof useTexts>,
) {
  return {
    type: 'number',
    filterable: false,
    valueGetter: getExecutedLoad,
    hide: colDef?.hide ?? true,
    width: colDef?.width ?? 200,
    field: colDef?.field ?? `formattedData.executedLoads.${colDef.field}`,
    headerName: `${getShortExecutedLoadName(colDef.field)} (${texts.executedLoad})`,
  }
}
