import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Equalizer(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M5.941 10.235c.455 0 .824.369.824.824v1.647a.824.824 0 01-.824.823H4.294a.824.824 0 01-.823-.823v-1.647c0-.455.368-.824.823-.824zm-3.29.823l-.004.104v1.44c0 .036.001.07.003.104H1.438A.438.438 0 011 12.268v-.772c0-.241.196-.437.438-.437l1.213-.001zm11.912 0c.241 0 .437.197.437.438v.772a.438.438 0 01-.438.438l-6.978-.001.004-.102v-1.441c0-.035 0-.07-.003-.104h6.978zm-2.857-4.94c.455 0 .823.368.823.823v1.647a.824.824 0 01-.823.824h-1.647a.824.824 0 01-.824-.824V6.941c0-.455.369-.823.824-.823zm-3.291.823l-.003.103v1.441c0 .035 0 .07.003.104H1.438A.437.437 0 011 8.15v-.772c0-.242.196-.438.438-.438h6.977zm6.147 0c.242 0 .438.196.438.438v.772a.437.437 0 01-.438.437h-1.214l.005-.103v-1.44c0-.035-.001-.07-.003-.104h1.213zM7.588 2c.455 0 .824.369.824.824V4.47a.824.824 0 01-.824.823H5.941a.824.824 0 01-.823-.823V2.824c0-.455.368-.824.823-.824zm-3.29.823l-.004.103v1.442c0 .034.001.069.003.103h-2.86A.438.438 0 011 4.033v-.772c0-.242.196-.437.438-.437l2.86-.001zm10.264 0c.242 0 .438.196.438.438v.772a.437.437 0 01-.438.438L9.232 4.47l.004-.102V2.926c0-.034 0-.068-.003-.102h5.33z" />
      </svg>
    </IconContainer>
  )
}
