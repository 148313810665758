import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { parse, isAfter } from 'date-fns/esm'

import { selectCalendarRange } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'

import { useTexts } from './useTexts'

export function useTrackingTooltip() {
  const calendarRange = useSelector(selectCalendarRange)
  const today = useSelector(selectUserConfiguration).today
  const texts = useTexts()

  return useMemo(() => {
    const now = new Date()
    const rangeSelected = calendarRange.minDate !== calendarRange.maxDate

    const todayDate = parse(today, 'yyyyMMdd', now)
    const minDateCalendar = parse(calendarRange.minDate, 'yyyyMMdd', now)
    const maxDateCalendar = parse(calendarRange.maxDate, 'yyyyMMdd', now)
    const calendarRangeInTheFuture =
      isAfter(minDateCalendar, todayDate) || isAfter(maxDateCalendar, todayDate)

    if (calendarRangeInTheFuture) {
      return texts.futureDates
    }

    if (rangeSelected) {
      return texts.multipleDates
    }

    return ''
  }, [calendarRange, today, texts])
}
