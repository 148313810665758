import styled from 'styled-components'

type Props = {
  disabled?: boolean
}

const CheckboxLabel = styled.label<Props>`
  font-size: ${p => p.theme.fonts.$p3};
  color: ${p => (p.disabled ? p.theme.colors.$silver : p.theme.colors.$nightRider)};
  margin-left: 8px;
`

CheckboxLabel.displayName = 'CheckboxLabel'
export default CheckboxLabel
