import { TextField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

const wrappedInputProps = {
  multi: true,
  showCounter: true,
  showResetIcon: true,
}

export function Notes() {
  const texts = useTexts()

  return (
    <TextField
      name="notes"
      testid="notes"
      label={texts.notes}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
