import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'vehicles.form.edit.generalSettings.title' }),
    headerDescription: translate({ id: 'vehicles.form.edit.generalSettings.subtitle' }),
    errors: translate({
      id: 'vehicles.form.edit.generalSettings.errorTitle',
    }),
    save: (create?: boolean) =>
      translate({
        id: create
          ? 'vehicles.form.edit.generalSettings.create'
          : 'vehicles.form.edit.generalSettings.update',
      }),
    saveTitle: (valid: boolean, planLocked: boolean, pristine: boolean) => {
      if (!valid) return translate({ id: 'vehicles.form.submitButton.disabled.title.invalid' })
      if (planLocked)
        return translate({ id: 'vehicles.form.submitButton.disabled.title.planLocked' })
      if (pristine) {
        return translate({ id: 'vehicles.form.submitButton.disabled.title.pristine' })
      }
      return ''
    },
    cancel: translate({ id: 'vehicles.form.edit.generalSettings.discard' }),
    create: translate({ id: 'vehicles.form.edit.generalSettings.create.info' }),
    deleting: translate({ id: 'global.deleting' }),
    expanding: translate({ id: 'vehicles.form.generalSettings.gpsExpandingTitle' }),
    deviceSettings: (deviceId: string) =>
      translate({
        id: 'vehicles.form.generalSettings.deviceSettings',
        values: {
          deviceId,
        },
      }),
    integrations: translate({ id: 'vehicles.form.generalSettings.integrations' }),
    gpsDevice: translate({ id: 'vehicles.form.generalSettings.gpsDevice' }),
    trackTheVehicle: translate({ id: 'vehicles.form.generalSettings.trackTheVehicle' }),
    selectAnIntegration: translate({ id: 'vehicles.form.generalSettings.selectAnIntegration' }),
    selectGpsDevice: translate({ id: 'vehicles.form.generalSettings.selectGpsDevice' }),
    onlyAvailableIntegrationInUse: (label: string) =>
      translate({
        id: 'vehicles.form.generalSettings.onlyAvailableIntegrationInUse',
        values: { label },
      }),
    remainingLicenses: (count: number) =>
      translate({ id: 'vehicles.form.generalSettings.remainingLicenses', values: { count } }),
    integrationDeletionInProgressChooseAnother: translate({
      id: 'vehicles.form.generalSettings.integrationDeletionInProgressChooseAnother',
    }),
    integrationDeletionInProgress: (label: string) =>
      translate({
        id: 'vehicles.form.generalSettings.integrationDeletionInProgress',
        values: { label },
      }),

    noTenantSourceAvailable: {
      alert: translate({ id: 'vehicles.form.generalSettings.noTenantSourceAvailable.alert' }),
      action: translate({
        id: 'vehicles.form.generalSettings.noTenantSourceAvailable.action',
      }),
    },
    noRemainingLicenses: {
      alert: translate({ id: 'vehicles.form.generalSettings.noRemainingLicenses.alert' }),
    },
  }))

  return api
}
