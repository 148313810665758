import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectUsersListOptions,
  setListOptions,
  selectUsersListStructure,
} from '@/features/domain/lists'
import { useAppDispatch } from '@/store'
import { useSettingsEditing } from '../../../../../../../../hooks/useSettingsEditing'

export function useSorter() {
  const listOptions = useSelector(selectUsersListOptions)
  const listStructure = useSelector(selectUsersListStructure)
  const { editing } = useSettingsEditing('user-management', 'none')
  const dispatch = useAppDispatch()

  const [direction, setDirection] = useState(() => listOptions.sort[0].direction)

  useEffect(() => {
    if (listOptions.sort[0].direction === direction) return
    if (!listOptions.sort) return

    dispatch(
      setListOptions({
        category: 'users',
        options: {
          ...listOptions,
          sort: [{ ...listOptions.sort[0], direction }],
        },
      }),
    )
  }, [dispatch, direction, listOptions])

  return { editing, direction, setDirection, noEntries: listStructure.totalItems === 0 }
}
