import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'
import { findFeature } from '../../findFeature'

import { findRegionMarker } from './findRegionMarker'
import { updateRegionFeature } from './updateRegionFeature'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Update the rendered style and position for all the Regions marked to be updated in a Changelog coming form the data-layer
 */
export async function changelogUpdateRegionFeatures(
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.Area>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['region'],
  selection: Set<string>,
  markerIds: IterableIterator<string>,
) {
  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findRegionMarker(markers, markerId)
    const feature = findFeature(layer, marker.id)

    if (!feature) {
      throw new Error(
        `Trying to update Region marker with ID: ${marker.id} but no feature exist on the map`,
      )
    }

    const selected = selection.has(markerId)
    updateRegionFeature(mapStyles, marker, feature, selected)
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
