import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import Point from 'ol/geom/Point'
import Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'
import { findFeature } from '../../findFeature'

import { setBreadcrumbFeatureMetadata } from '../breadcrumbFeatureMetadata'
import { updateBreadcrumbPointsFeature } from './breadcrumbPoints/updateBreadcrumbPointsFeature'
import { updateBreadcrumbPathFeature } from './breadcrumbPath/updateBreadcrumbPathFeature'
import { findBreadcrumbMarker } from './findBreadcrumbMarker'
import { updateBreadcrumbStopsFeature } from './breadcrumbStops/updateBreadcrumbStopsFeature'
import { getBreadcrumbStopsFeatureStyle } from './breadcrumbStops/getBreadcrumbStopsFeatureStyle'
import { getBreadcrumbPointsFeatureStyle } from './breadcrumbPoints/getBreadcrumbPointsFeatureStyle'

type BreadcrumbLayers = {
  breadcrumbPathLayer: VectorLayer<VectorSource>
  breadcrumbPointsLayer: VectorLayer<VectorSource>
}

const nonblockingRender = createNonBlockingRenderByTime()

export async function changelogUpdateBreadcrumbFeatures(
  layers: BreadcrumbLayers,
  markers: Record<string, uui.domain.ui.map.markers.Breadcrumb>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
  selection: Set<string>,
  markerIds: IterableIterator<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findBreadcrumbMarker(markers, markerId)
    if (!marker) {
      console.warn(
        `Trying to update Breadcrumb feature with deviceId: ${markerId} but no marker exist on the map`,
      )
      continue
    }

    const pathFeature = findFeature(layers.breadcrumbPathLayer, marker.id)

    if (!pathFeature) {
      throw new Error(
        `Trying to update Breadcrumb feature with id: ${marker.id} but no feature exist on the map`,
      )
    }

    // ------------------------------------------------------
    // UPDATE PATH FEATURE
    // ------------------------------------------------------

    updateBreadcrumbPathFeature(mapStyles, marker, pathFeature)

    if (marker.fetched) {
      const newPointFeatures: Feature<Point>[] = []
      const newStopFeatures: Feature<Point>[] = []

      // ------------------------------------------------------
      // UPDATE POINTS FEATURES
      // ------------------------------------------------------

      for (const pointId in marker.points) {
        const point = marker.points[pointId]
        const feature = findFeature(layers.breadcrumbPointsLayer, point.id)

        // If the point exists, update it
        if (feature) {
          updateBreadcrumbPointsFeature(
            mapStyles,
            marker,
            feature,
            point,
            selection.has(point.id),
            breadcrumbTimeRange,
          )
          continue
        }

        const mode: uui.domain.ui.map.markers.MapStyles['breadcrumb']['mode'] =
          marker.mode ?? mapStyles.mode

        //If the point does not exists, create it
        const newPointFeature = new Feature({ geometry: new Point(point.lonLat) })

        setBreadcrumbFeatureMetadata(newPointFeature, 'type', 'breadcrumbPoints')
        newPointFeature.setStyle(
          getBreadcrumbPointsFeatureStyle(point, mode, selection.has(pointId), breadcrumbTimeRange),
        )
        newPointFeature.setId(point.id)

        newPointFeatures.push(newPointFeature)
      }

      // add all new features to the layer
      layers.breadcrumbPointsLayer.getSource()?.addFeatures(newPointFeatures)

      // ------------------------------------------------------
      // UPDATE STOPS FEATURES
      // ------------------------------------------------------
      for (const stopId in marker.stops) {
        const stop = marker.stops[stopId]
        const features = [
          findFeature(layers.breadcrumbPathLayer, stop.id),
          findFeature(layers.breadcrumbPointsLayer, stop.id),
        ]

        for (const feature of features) {
          // If the stop exists, update it
          if (feature) {
            updateBreadcrumbStopsFeature(
              mapStyles,
              marker,
              feature,
              stop,
              selection.has(stop.id),
              breadcrumbTimeRange,
            )
            continue
          }

          const mode: uui.domain.ui.map.markers.MapStyles['breadcrumb']['mode'] =
            marker.mode ?? mapStyles.mode

          //If the stop does not exists, create it
          const newStopFeature = new Feature({ geometry: new Point(stop.lonLat) })

          setBreadcrumbFeatureMetadata(newStopFeature, 'type', 'breadcrumbStops')
          newStopFeature.setStyle(
            getBreadcrumbStopsFeatureStyle(stop, mode, selection.has(stopId), breadcrumbTimeRange),
          )
          newStopFeature.setId(stop.id)

          newStopFeatures.push(newStopFeature)
        }
      }

      // add all new features to the layer
      layers.breadcrumbPathLayer.getSource()?.addFeatures(newStopFeatures)
      layers.breadcrumbPointsLayer.getSource()?.addFeatures(newStopFeatures)
    } else {
      const stopFeaturesOnPointsLayer =
        layers.breadcrumbPointsLayer.getSource()?.getFeatures() ?? []
      const stopFeaturesOnPathLayer = layers.breadcrumbPathLayer.getSource()?.getFeatures() ?? []
      const pointFeatures = layers.breadcrumbPointsLayer.getSource()?.getFeatures() ?? []

      for (const feature of pointFeatures) {
        const featureId = feature.getId()
        if (typeof featureId === 'string' && featureId.startsWith(`${markerId}@`)) {
          layers.breadcrumbPointsLayer.getSource()?.removeFeature(feature)
        }
      }

      for (const feature of stopFeaturesOnPointsLayer) {
        const featureId = feature.getId()
        if (typeof featureId === 'string' && featureId.startsWith(`${markerId}@`)) {
          layers.breadcrumbPathLayer.getSource()?.removeFeature(feature)
        }
      }

      for (const feature of stopFeaturesOnPathLayer) {
        const featureId = feature.getId()
        if (typeof featureId === 'string' && featureId.startsWith(`${markerId}@`)) {
          layers.breadcrumbPathLayer.getSource()?.removeFeature(feature)
        }
      }
    }
  }

  // update the stored collection
  setLayerMetadata(layers.breadcrumbPathLayer, 'selection', selection)
  setLayerMetadata(layers.breadcrumbPathLayer, 'selection', selection)
  setLayerMetadata(layers.breadcrumbPointsLayer, 'selection', selection)
}
