import { useEffect } from 'react'
import { Paper, Stack } from '@mui/material'

import { resetRoutingSidebar } from '@/atoms'
import { Sidebar } from '@/components/Sidebar'

import { RoutingView } from './components/RoutingView'
import { RoutingSidebar } from './components/RoutingSidebar'

export function RoutingHome() {
  // Reset the Routing sidebar on unmount
  useEffect(
    () => () => {
      resetRoutingSidebar()
    },
    [],
  )

  return (
    <Stack direction="row" height="100%" width="100%" maxWidth="100%">
      <Paper data-testid="routing-sidebar" elevation={4} sx={{ height: '100%', zIndex: 10 }}>
        <Sidebar>
          <RoutingSidebar />
        </Sidebar>
      </Paper>
      <RoutingView />
    </Stack>
  )
}
