import styled from 'styled-components'
import { MouseEventHandler } from 'react'

import Button from './Button'

export interface Props {
  className?: string
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  width?: number | string
  testid?: string
}

const ListActionButton = styled(Button)<Props>`
  text-align: left;
  font-weight: ${p => p.theme.weights.$regular};
  width: ${p => (p.width ? `${p.width}px` : 'auto')};

  &:disabled {
    background: ${p => p.theme.colors.$whiteSmoke};
    color: ${p => p.theme.colors.$pureWhite};
  }
`

ListActionButton.displayName = 'ListActionButton'

export default ListActionButton
