import type { SegmentType } from '../../../types'

import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    cardTitle: translate({ id: 'setup.routing.outOfTerritory.title' }),
    cardDescription: translate({ id: 'setup.routing.outOfTerritory.description' }),
    labelName: translate({ id: 'setup.routing.edit.labels.name' }),
    labelAdditionalDrivingTimeSec: translate({
      id: 'setup.routing.edit.labels.additionalDrivingTimeSec',
    }),
    errorName: translate({ id: 'setup.routing.edit.validation.name' }),
    errorAdditionalDrivingTimeSec: translate({
      id: 'setup.routing.edit.validation.additionalDrivingTimeSec',
    }),
    footerTitle: translate({ id: 'setup.routing.edit.formErrorsTitle' }),
    footerMessage: (missingPolyline: boolean) => {
      if (!missingPolyline) return []
      return [
        translate({ id: 'setup.routing.edit.missingPolyRow1' }),
        translate({ id: 'setup.routing.edit.missingPolyRow2' }),
      ]
    },
    save: translate({ id: 'setup.routing.edit.save' }),
    create: translate({ id: 'setup.routing.edit.create' }),
    saveTitle: (valid: boolean, planLocked: boolean, pristine: boolean) => {
      if (!valid)
        return translate({ id: 'routingProfiles.form.submitButton.disabled.title.invalid' })

      if (planLocked)
        return translate({ id: 'routingProfiles.form.submitButton.disabled.title.planLocked' })

      if (pristine)
        return translate({ id: 'routingProfiles.form.submitButton.disabled.title.pristine' })

      return ''
    },
    cancel: translate({ id: 'global.cancel' }),
    edit: translate({ id: 'setup.routing.edit.title' }),
    headerTitle: (name?: string) => name ?? translate({ id: 'setup.routing.view.unnamedSegment' }),
    headerDescription: (type: SegmentType) => {
      switch (type.type) {
        case 'disabled':
          return translate({ id: 'setup.routing.view.single.subTitle.disabled' })
        case 'blocked':
          return translate({ id: 'setup.routing.view.single.subTitle.blocked' })
        case 'delayed': {
          return translate({ id: 'setup.routing.view.single.subTitle.delayed' })
        }
      }
    },

    creatingLine: [
      translate({ id: 'setup.routing.edit.missingPolyRow1' }),
      translate({ id: 'setup.routing.edit.missingPolyRow2' }),
    ],
  }))

  return api
}
