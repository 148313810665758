import type { DriverWithVehicleAndDistance } from '../typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getDeviceId } from '@/server-data'
import { useDriverAssignmentsByDate } from '@/hooks'
import { selectDrivers } from '@/features/domain/driver'
import { selectVehicles } from '@/features/domain/vehicle'
import { selectUserConfiguration } from '@/features/domain/user'

import { useDistancesByDeviceId } from './useDistancesByDeviceId'

export const useDriverAssignments = () => {
  const today = useSelector(selectUserConfiguration).today
  const vehiclesMap = useSelector(selectVehicles)
  const driversMap = useSelector(selectDrivers)
  const driverAssignments = useDriverAssignmentsByDate(today)
  const distances = useDistancesByDeviceId()

  return useMemo(
    () =>
      Object.entries(driverAssignments)
        .reduce<DriverWithVehicleAndDistance[]>((acc, [vehicleId, driverId]) => {
          const vehicle = vehiclesMap[vehicleId]

          if (!vehicle) return acc

          const deviceId = getDeviceId(driversMap, vehicle, today)

          if (vehicle.hasRoutingLicense) {
            acc.push({
              id: driverId,
              driver: driversMap[driverId].driver,
              vehicle: { id: vehicle.unifiedId, ...vehicle },
              distance: deviceId ? distances[deviceId] : undefined,
            })
          }

          return acc
        }, [])
        .sort(
          (a, b) =>
            (a.distance ?? Number.MAX_SAFE_INTEGER) - (b.distance ?? Number.MAX_SAFE_INTEGER),
        ),
    [driversMap, vehiclesMap, driverAssignments, distances, today],
  )
}
