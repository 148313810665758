import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Unlock(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 18 19">
        <path d="M4.84615.6c-1.53064 0-2.85306.63552-3.67788 1.66587C.34345 3.2962 0 4.63215 0 6.07356v2.14182h2.07692V6.07356c0-1.09255.27044-1.95253.71395-2.50962.4435-.55709 1.04657-.88702 2.05528-.88702 1.01142 0 1.61178.311 2.0553.86539.4435.55438.71393 1.42518.71393 2.53125v.7572h2.07693v-.7572c0-1.44952-.36509-2.79898-1.1899-3.82933C7.67757 1.21388 6.3741.6 4.84614.6zm1.38462 6.92308c-1.14664 0-2.07692.93029-2.07692 2.07692v6.92308c0 1.14663.93028 2.07692 2.07692 2.07692h9.6923C17.06972 18.6 18 17.66971 18 16.52308V9.6c0-1.14663-.93029-2.07692-2.07692-2.07692H6.23077zm4.84615 3.46154c.76533 0 1.38462.61929 1.38462 1.38461 0 .51112-.27855.94922-.6923 1.1899v1.57933c0 .38131-.311.6923-.69232.6923-.3813 0-.6923-.31099-.6923-.6923v-1.57933c-.41377-.24068-.69231-.67878-.69231-1.1899 0-.76532.61929-1.38461 1.38461-1.38461z" />
      </svg>
    </IconContainer>
  )
}
