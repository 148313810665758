import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectTrackingListOptions,
  setListOptions,
  selectTrackingListStructure,
} from '@/features/domain/lists'
import { useAppDispatch } from '@/store'
import { isGpsOnly } from '@/features/domain/user'

export function useSorter() {
  const canGroupItems = !useSelector(isGpsOnly)
  const listOptions = useSelector(selectTrackingListOptions)
  const listStructure = useSelector(selectTrackingListStructure)
  const dispatch = useAppDispatch()

  const [sortField, setSortField] = useState(() => listOptions.sort[0].field)
  const [direction, setDirection] = useState(() => listOptions.sort[0].direction)
  const [groupField, setGroupField] = useState<string | undefined>(() =>
    canGroupItems ? listOptions.group[0]?.field : undefined,
  )

  useEffect(() => {
    if (!listOptions.sort) return
    if (
      listOptions.sort[0].field === sortField &&
      listOptions.group[0]?.field === groupField &&
      listOptions.sort[0].direction === direction
    )
      return

    const sort =
      sortField === 'ui.sort.lastActivity'
        ? [
            { field: sortField, direction },
            { field: 'name', direction },
          ]
        : [
            { field: sortField, direction },
            { field: 'ui.sort.lastActivity', direction },
          ]

    const group: uui.domain.ui.list.GroupOption[] = groupField
      ? [{ field: groupField, direction: 'asc' }]
      : []

    dispatch(setListOptions({ category: 'tracking', options: { ...listOptions, sort, group } }))
  }, [dispatch, sortField, direction, groupField, listOptions])

  return {
    direction,
    sortField,
    groupField,
    setDirection,
    setSortField,
    canGroupItems,
    setGroupField,
    noEntries: listStructure.totalItems === 0,
  }
}
