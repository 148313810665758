import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useControllerActions } from '../hooks/useControllerActions'
import { useTexts } from '../hooks/useTexts'

export function Footer() {
  const texts = useTexts()
  const actions = useControllerActions()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          onClick={actions.onGoToIntegrations}
          testid="vehicleform-no-integrations-available-modal-confirm-button"
          color="error"
        >
          {texts.confirm}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          onClick={actions.onCancel}
          variant="contained"
          testid="vehicleform-no-integrations-available-modal-cancel-button"
        >
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}
