import type { FormFields, FormErrors } from './formFields'

import { FormProvider } from '@workwave-tidal/tidal/form-ui/layout'

import { usePersonalizationData } from '../hooks/usePersonalizationData'

import { useCreateFormConfig } from './hooks/useCreateFormConfig'
import { formFields } from './formFields'
import { GpsPersonalizationForm } from './GpsPersonalizationForm'

type Props = {
  onDiscard: () => void
}

export function GpsPersonalizationFormProvider(props: Props) {
  const { onDiscard } = props

  // access form initial values and a function update the data when the form is saved
  const [personalization, updatePersonalization] = usePersonalizationData()

  // create a memoized configuration including initial form valuers and form validation
  const createFormConfig = useCreateFormConfig(personalization)

  return (
    <FormProvider<FormFields, FormErrors>
      config={createFormConfig}
      name="personalization-form"
      fields={formFields}
      verbose={false}
    >
      <GpsPersonalizationForm onDiscard={onDiscard} updateAccount={updatePersonalization} />
    </FormProvider>
  )
}
