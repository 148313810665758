import { useController } from '../useController'
import { useDriverFromSelectedVehicle } from './useDriverFromSelectedVehicle'

/**
 * This hook will return the correct driver both if:
 * - The user cames from the Vehicle Navigo
 * - The user cames from Depot/Place/Inspect Navigo
 */
export const useDriver = () => {
  const driverFromSelectedVehicle = useDriverFromSelectedVehicle()
  const {
    data: { selectedId, drivers },
  } = useController()

  if (driverFromSelectedVehicle) return driverFromSelectedVehicle

  // If no vehicle has been selected let's try to return driver from chooseRecipient or current route
  if (!drivers) return

  return drivers.find(d => d.driver.id === selectedId)?.driver
}
