import type { DrawRoadSegmentProps as Props } from './types'

// import { Block, Label, FieldMessage } from '@/forms-legacy'
import { useConnectDrawRoadSegmentField } from './useConnectDrawRoadSegmentField'

export function DrawRoadSegmentField(props: Props) {
  const {
    formProps: {
      input: { onChange },
      meta: { initial: editableRoadSegmentInitialCoords },
    },

    editableRoadSegmentId,
    editableRoadSegmentType,
  } = props

  // connect and initialize to the EditableRoadSegment atom!
  useConnectDrawRoadSegmentField(
    onChange,
    editableRoadSegmentId,
    editableRoadSegmentType,
    editableRoadSegmentInitialCoords,
  )

  return null
}
