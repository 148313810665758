import { useEffect, useCallback, useRef } from 'react'
import { useTesterModalState } from '@/atoms'

export const useToggleTesterModal = () => {
  const [testerModalState, setTesterModalState] = useTesterModalState()
  const testerModalStateRef = useRef(testerModalState)

  useEffect(() => {
    testerModalStateRef.current = testerModalState
  }, [testerModalState])

  const handleToggleTesterModal = useCallback(() => {
    setTesterModalState(testerModalStateRef.current === 'open' ? 'closed' : 'open')
  }, [setTesterModalState])

  return {
    isTesterModalOpen: testerModalState === 'open',
    toggleTesterModal: handleToggleTesterModal,
  }
}
