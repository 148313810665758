import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDrivers } from '@/features/domain/driver'

export function useDriverName(driverId: string) {
  const drivers = useSelector(selectDrivers)

  return useMemo(() => {
    return drivers[driverId]?.driver.name ?? '[placeholder]'
  }, [drivers, driverId])
}
