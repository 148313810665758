import { Entry } from '../Entry'
import { JiraLinks } from './JiraLinks'

const ids = [
  10755, 10754, 10913, 10799, 10751, 10892, 10027, 10841, 10359, 10751, 10845, 10837, 10839, 10814,
  10824,
]

export function Entry20210531() {
  return (
    <Entry title="05/31/2021 - v3.0.0-beta-6 fixes">
      Full list of Jira issues fixed:
      <br />
      <br />
      <JiraLinks ids={ids} />
      <br />
      <br />
    </Entry>
  )
}
