import { Fragment } from 'react'

import { WarningTriangle } from '@/icons'
import { WarningCard } from '@/local/components'
import { VerticalLayout } from '@/components/layout/VerticalLayout'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

const Block = Fragment

export function Body() {
  const texts = useTexts()
  const {
    data: { name },
    status,
  } = useController()

  const title =
    status !== 'pending' ? texts.warningCardTitle(name) : texts.transactionWarningCardTitle

  const description =
    status !== 'pending' ? texts.warningCardDescription : texts.transactionWarningCardDescription

  return (
    <VerticalLayout marginTop={32}>
      <Block>
        <WarningCard
          title={title}
          description={description}
          preset="warning"
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
        />
      </Block>
    </VerticalLayout>
  )
}
