import type { LocationEditorProps } from '../typings'

import { useEffect } from 'react'

import { addLocationPin, getLocationPin } from '@/map'
import { useFormFieldLocation } from './useFormFieldLocation'

export function useInitializeFormFieldLocationPin(options: LocationEditorProps) {
  const { pinId, hidden, pinType, pinInfo, snapToRoad, pinRadius = 0, connectedPlaceId } = options
  const formLocation = useFormFieldLocation(options)

  // create the target Location Pin if not already available
  useEffect(() => {
    const pin = getLocationPin(pinId)

    if (!formLocation) {
      throw new Error(
        `Trying to connect a LocationEditor Field to the Map without a source form value`,
      )
    }

    if (!pin) {
      addLocationPin(
        pinId,
        { ...formLocation.latLng },
        {
          snapToRoad,
          radius: pinRadius,
          location: { ...formLocation },
          type: connectedPlaceId ? 'depot' : pinType,
        },
        pinInfo,
        hidden,
      )
    }
  }, [pinId, hidden, pinType, pinInfo, pinRadius, snapToRoad, formLocation, connectedPlaceId])
}
