import type { Props } from './typings'

import { useCallback } from 'react'
import {
  Block,
  Label,
  FieldMessage,
  FlexRow,
  ReadonlyBlock,
  InputStep,
  RestoreFieldButton,
} from '@/forms-legacy'

export function DriverBehaviorField(props: Props) {
  const {
    label,
    name,
    info,
    labelHard,
    labelHarsh,
    labelSevere,
    hideHarshField,
    hideSevereField,
    testid,
    formProps: {
      input: { onChange, value: inputValue },
      meta: { error, dirty, initial },
    },
  } = props

  const handleOnChange = useCallback(
    (name: string) => (value: any) => {
      const formValue = {
        ...inputValue,
        [name]: parseInt(value),
      }
      onChange(formValue)
    },
    [inputValue, onChange],
  )

  const showHarshField: boolean = !hideHarshField
  const showSevereField: boolean = !hideSevereField

  return (
    <Block error={!!error} dirty={!!dirty} data-testid={testid} data-trackid={testid}>
      <Label bold htmlFor={name}>
        {label}
        <RestoreFieldButton
          dirty={dirty}
          initial={initial}
          onChange={onChange}
          style={{ marginLeft: 12 }}
        />
      </Label>
      <FlexRow justifyContent="space-between">
        <ReadonlyBlock aThird>
          <Label>{labelHard}</Label>
          <InputStep
            name={`${name}.hard`}
            value={inputValue.hard}
            onChange={handleOnChange('hard')}
            step={1}
            canBeNaN={false}
          />
        </ReadonlyBlock>
        {showHarshField && (
          <ReadonlyBlock aThird>
            <Label>{labelHarsh}</Label>
            <InputStep
              name={`${name}.harsh`}
              value={inputValue.harsh}
              onChange={handleOnChange('harsh')}
              step={1}
              canBeNaN={false}
            />
          </ReadonlyBlock>
        )}
        {showSevereField && (
          <ReadonlyBlock aThird>
            <Label>{labelSevere}</Label>
            <InputStep
              name={`${name}.severe`}
              value={inputValue.severe}
              onChange={handleOnChange('severe')}
              step={1}
              canBeNaN={false}
            />
          </ReadonlyBlock>
        )}
      </FlexRow>

      {!!error && <FieldMessage error>{error}</FieldMessage>}
      {!error && !!info && <FieldMessage>{info}</FieldMessage>}
    </Block>
  )
}
