import { useSettingsEditing } from '../../hooks/useSettingsEditing'
import { useRefreshGpsAccountData } from './hooks/useRefreshGpsAccountData'

import { GpsAccountForm } from './GpsAccountForm'
import { GpsAccountView } from './GpsAccountView'

export function GpsAccount() {
  useRefreshGpsAccountData()

  const { editing, onEdit, onDiscard } = useSettingsEditing('gps-account', 'reset')

  return (
    <>
      {!editing && <GpsAccountView onEdit={onEdit} />}
      {editing && <GpsAccountForm onDiscard={onDiscard} />}
    </>
  )
}
