import { useResetCrudSelectionOnUnmount } from '@/atoms'

import { useEventsRoot } from './hooks/useEventsRoot'
import { EventsList as List } from './EventsList'
import { SingleView } from './Single/View'
import { BulkView } from './Bulk/View'

import { useUpdateDeviceIdForEvents } from './hooks/useUpdateDeviceIdForEvents'
import { useRedirectWhenNoSelection } from './hooks/useRedirectWhenNoSelection'

export function EventsRoot() {
  const { eventsData, deviceEvents } = useEventsRoot()

  useResetCrudSelectionOnUnmount('deviceEvents')
  useUpdateDeviceIdForEvents()
  useRedirectWhenNoSelection()

  return (
    <>
      <List deviceEvents={deviceEvents} />
      {eventsData.type === 'single' && <SingleView deviceEvent={eventsData.event} />}
      {eventsData.type === 'bulk' && <BulkView deviceEvents={eventsData.events} />}
    </>
  )
}
