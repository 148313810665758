import { useState } from 'react'
import { useSelector } from 'react-redux'

import { useIntl } from '@/intl'
import { conversionUtils, durationUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export function useTexts() {
  const { translate } = useIntl()
  const userConfig = useSelector(selectUserConfiguration)

  const [api] = useState(() => ({
    workingTime: translate({ id: 'rm.summary.workingTime' }),
    workingTimeTooltip: translate({ id: 'rm.summary.workingTime.title' }),
    drivingTime: translate({ id: 'rm.summary.drivingTime' }),
    drivingTimeTooltip: translate({ id: 'rm.summary.drivingTime.title' }),
    mileageTooltip: translate({ id: 'rm.summary.mileage.title' }),
    distance: translate({ id: 'rm.summary.distance' }),
    distanceValue: (meters: number) => {
      const amount = conversionUtils.convertDistance(userConfig)(meters, 1, true)
      return amount
    },
    timeValue: (seconds: number) => {
      if (seconds > 0) {
        return durationUtils.formatSeconds(translate)(seconds, 'EXTRA_SHORT')
      }
      return '0h 0m'
    },
  }))

  return api
}
