import { makeStyles } from '@mui/styles'

import { Link } from '@/routing'
import { clsx } from '@/utils'

import { useTexts } from '../../../../useTexts'
import { HeaderLink } from '../HeaderLink'
import { useRmLinks } from './hooks/useRmLinks'

type RoutingRoutes = uui.routing.Routing

const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
  },
})

export function RmLinks() {
  const texts = useTexts()
  const classes = useStyles()
  const { canShow } = useRmLinks()

  return (
    <>
      <Link<RoutingRoutes>
        absolute
        to="home"
        data-testid="header__nav-home-button-link"
        className={clsx(classes.fullHeight, 'pendo-bluebar__home-menu')}
      >
        <HeaderLink
          data-testid="header__nav-home-button-content"
          data-trackid="rm-header__nav-home-button-content"
        >
          {texts.home}
        </HeaderLink>
      </Link>

      <Link<RoutingRoutes>
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        className={clsx(classes.fullHeight, 'pendo-bluebar__orders-menu')}
      >
        <HeaderLink
          data-testid="header__nav-orders-button-content"
          data-trackid="rm-header__nav-orders-button-content"
        >
          {texts.orders}
        </HeaderLink>
      </Link>

      {canShow.simulations && (
        <Link<RoutingRoutes>
          absolute
          to="simulations"
          data-testid="header__nav-simulations-button-link"
          className={clsx(classes.fullHeight, 'pendo-bluebar__simulations-list-menu')}
        >
          <HeaderLink
            data-testid="header__nav-simulations-button-content"
            data-trackid="rm-header__nav-simulations-button-content"
          >
            {texts.simulations}
          </HeaderLink>
        </Link>
      )}
    </>
  )
}
