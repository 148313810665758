import type { Theme } from '@mui/material'

import { makeStyles } from '@mui/styles'

import { normalizeCssValue, ValidColor, theme } from '@/local/components'

type Props = {
  alignItems?: 'center' | 'start' | 'end' | 'stretch'
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  shrink?: 1 | 0
  width?: number | string
  height?: number | string
  padding?: string
  marginTop?: string | number
  marginBottom?: string | number
  marginLeft?: string | number
  marginRight?: string | number
  background?: ValidColor
  paddingTop?: string | number
  paddingBottom?: string | number
  paddingLeft?: string | number
  paddingRight?: string | number
}

export const useMonthStyle = makeStyles<Theme, Props>({
  root: p => ({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flexWrap: 'nowrap',

    flexShrink: typeof p.shrink === 'number' ? p.shrink : 0,
    width: normalizeCssValue(p.width || '100%'),
    height: normalizeCssValue(p.height || '100%'),
    alignItems: p.alignItems ?? 'start',
    justifyContent: p.justifyContent ?? 'normal',
    padding: p.padding || 0,
    paddingTop: normalizeCssValue(p.paddingTop || 0),
    paddingBottom: normalizeCssValue(p.paddingBottom || 0),
    paddingLeft: normalizeCssValue(p.paddingLeft || 0),
    paddingRight: normalizeCssValue(p.paddingRight || 0),
    marginTop: normalizeCssValue(p.marginTop || 0),
    marginBottom: normalizeCssValue(p.marginBottom || 0),
    marginLeft: normalizeCssValue(p.marginLeft || 0),
    marginRight: normalizeCssValue(p.marginRight || 0),
    background: p.background ? theme.colors[p.background] : 'transparent',
  }),
})
