import styled from 'styled-components'

export const CategoryButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: horizontal;
  width: 100%;

  & > * + * {
    margin-left: 24px;
  }
`
CategoryButtonContainer.displayName = 'CategoryButtonContainer'
