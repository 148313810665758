import type { FormFields, FormErrors } from '../../../formFields'
import type { GpsTimezone } from '../../../types'

import { SimpleSelect } from '@workwave-tidal/tidal/form-ui'

import { timezoneUtils } from '@/server-data'

import { useTexts } from './useTexts'

export function Timezone() {
  const texts = useTexts()

  return (
    <SimpleSelect<'timezone', GpsTimezone, FormFields, FormErrors>
      label={texts.timezone}
      name="timezone"
      testId="timezone"
      options={options}
      validateOn="blur focus"
    />
  )
}

const options = timezoneUtils.gpsValidTimezones.map(timezone => ({
  label: timezone,
  value: timezone,
  type: 'option',
}))
