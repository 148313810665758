import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { addDays, parse, parseISO } from 'date-fns/esm'

import { Gear } from '@/icons'
import { timeUtils } from '@/server-data'
import { selectTerritory } from '@/features/domain/territory'
import { selectUserConfiguration } from '@/features/domain/user'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'

import { type ActionPermissions } from '../hooks/usePermissions/types'
import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  permission: ActionPermissions['build']
}

export function BuildButton(props: Props) {
  const { permission, onClick } = props

  const userConfiguration = useSelector(selectUserConfiguration)
  const {
    licensingLimits: { horizonOpt },
  } = useSelector(selectTerritory)
  const texts = useTexts()

  const disabled = permission.startsWith('disabled')

  const { title, subtitle, forceState } = useMemo(() => {
    switch (permission) {
      case 'disabledAllRoutesLocked':
        return {
          title: texts.disabledAllRoutesLocked,
          forceState: undefined,
        }
      case 'disabledBecauseInThePast':
        return {
          title: texts.disabledBecauseInThePastTitle,
          subtitle: texts.disabledBecauseInThePastSubtitle,
          forceState: undefined,
        }
      case 'someRouteInThePast':
        return {
          title: texts.someRouteInThePastTitle,
          subtitle: texts.someRouteInThePastSubtitle,
          forceState: 'enabled' as const,
        }

      case 'disabledBiggerThanOptimizationHorizon':
        return {
          title: texts.disabledBuildTooltipTitle,
          subtitle: texts.disabledExceedMaxOptDaysBuildTooltipSubtitle(horizonOpt),
          forceState: undefined,
        }

      case 'disabledOutOfOptimizationRange':
        const optIntervalStart = parse(userConfiguration.startFromDate, 'yyyyMMdd', new Date())
        const optIntervalEnd = addDays(parseISO(userConfiguration.startOfToday), horizonOpt)

        return {
          title: texts.disabledBuildTooltipTitle,
          subtitle: texts.disabledOutOfOptHorizonBuildTooltipSubtitle(
            timeUtils.formatDate(userConfiguration)(optIntervalStart),
            timeUtils.formatDate(userConfiguration)(optIntervalEnd),
          ),
          forceState: undefined,
        }

      default:
        return {
          title: '',
          subtitle: '',
          forceState: undefined,
        }
    }
  }, [userConfiguration, horizonOpt, texts, permission])

  return (
    <ReadOnlyTooltip
      title={title}
      canEdit={!disabled}
      subtitle={subtitle}
      forceState={forceState}
      render={preventEditing => (
        <NavigoIconButton
          onClick={onClick}
          text={texts.build}
          disabled={preventEditing}
          Icon={<Gear color={preventEditing ? '$silver' : '$nightRider'} size={17} />}
          testid="navigo-routes-overview-actions-build"
        />
      )}
    />
  )
}
