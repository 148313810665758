import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  HorizontalRuler,
} from '@/forms-legacy'
import { theme } from '@/styles'

import { useDriverBehaviorData } from './hooks/useDriverBehaviorData'

interface Props {
  event: uui.domain.client.gps.wwgps.EventInfo
}

const rowStyle = { justifyContent: 'space-between' }

export function DriverBehavior(props: Props) {
  const { event } = props

  const data = useDriverBehaviorData(event)

  if (!data) return null

  return (
    <>
      <HorizontalRuler />
      <ReadonlyBlock half>
        <ReadonlyLabel>{data.title}</ReadonlyLabel>
        <ReadonlyField>
          <ReadonlyFieldRow style={rowStyle}>
            <ReadonlyFieldLabel>{data.value}</ReadonlyFieldLabel>
            {data.delta && data.delta > 0 && (
              <ReadonlyFieldLabel style={{ color: theme.colors.$outrageousRed }}>
                + {data.delta}
              </ReadonlyFieldLabel>
            )}
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyBlock>
    </>
  )
}
