import styled from 'styled-components'

const LocationEditorTextInput = styled.input`
  width: 100%;
  font-size: ${p => p.theme.fonts.$p2};
  font-weight: ${p => p.theme.weights.$semiBold};
  color: ${p => p.theme.colors.$nightRider};
  background: transparent;
  border: none;
  outline: 0;
`

LocationEditorTextInput.displayName = 'LocationEditorTextInput'
export default LocationEditorTextInput
