import { Fragment } from 'react'

import { Text } from '@/local/components'

import ClampText from './ClampText'

interface Props {
  rows: string[]
}

export function NotificationBody(props: Props) {
  const { rows } = props

  return (
    <ClampText>
      <Text size="$p3">
        {rows.map((item, key) => (
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        ))}
      </Text>
    </ClampText>
  )
}
