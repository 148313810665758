import { useCallback, useState, useEffect } from 'react'

import { resetCrudSelection } from '@/atoms'

import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { usePendingFitMapOnMount } from '@/map'

import { DepotAvatar } from '../../components/DepotAvatar'
import { ViewDetails } from '../../components/ViewDetails'
import { ActionsManager } from './components/ActionsManager'

interface Props {
  depot: uui.domain.client.rm.Depot
}

export function SingleView(props: Props) {
  const { depot } = props

  usePendingFitMapOnMount()

  const [view, setView] = useState<'default' | 'delete'>(() => 'default')

  const onReset = useCallback(() => {
    resetCrudSelection('depots')
  }, [])

  useEffect(() => {
    setView('default')
  }, [depot])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={depot.name}
        description={depot.location.address ?? depot.location.geoAddress}
        action={onReset}
        avatar={<DepotAvatar depots={[depot]} size={44} />}
      />
      <ActionsManager view={view} setView={setView} depot={depot} />
      <FormContent footer={<div />}>
        <ViewDetails setupCost={depot.setupCost} setupTimeSec={depot.setupTimeSec} />
      </FormContent>
    </FormColumn>
  )
}
