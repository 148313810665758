import type { FormFields, FormErrors } from '../formFields'

import { useCallback } from 'react'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { useIsUnmounted, useNotification } from '@/hooks'
import { deleteTenantSource } from '@/features/domain/gps'
import { useAppDispatch } from '@/store'

import { useController } from './useController'

export function useOnSubmit(setFirstSubmitDone: (value: boolean) => void) {
  const toast = useNotification()
  const formApi = useFormApi<FormFields, FormErrors>()
  const dispatch = useAppDispatch()
  const {
    update,
    close,
    data: { tenantSource },
  } = useController()
  const isUnmounted = useIsUnmounted()

  return useCallback(async () => {
    try {
      setFirstSubmitDone(true)
      formApi.setSubmitting(true)
      update({ status: 'submitting' })

      await formApi.waitForValidation()

      const valid =
        formApi.getMeta().status === 'indeterminate'
          ? await formApi.validate()
          : formApi.getMeta().status === 'valid'

      // if the component has been unmounted during the async operation stop here
      if (isUnmounted()) return

      if (valid) {
        try {
          const thunkResult = await dispatch(deleteTenantSource(tenantSource.id))

          if (!deleteTenantSource.fulfilled.match(thunkResult)) {
            throw new Error(thunkResult.payload?.message ?? 'Internal error')
          }

          // Clear FormState submitting mode
          formApi.setSubmitting(false)
          close?.()
        } catch (error) {
          formApi.setSubmitting(false)
        }
      } else {
        // Clear FormState submitting mode
        formApi.setSubmitting(false)
        update({ status: 'ready' })
      }
    } catch (e) {
      formApi.setSubmitting(false)
      update({ status: 'ready' })

      toast.error(e.message)
    }
  }, [formApi, toast, update, close, dispatch, isUnmounted, setFirstSubmitDone, tenantSource])
}
