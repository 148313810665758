import { useMemo } from 'react'
import { type ResourceModel } from '@bryntum/schedulerpro'

import { conversionUtils } from '@/server-data'

import { useTexts } from '../useTexts'

export function useAggregateData(
  record: ResourceModel,
  userConfig: uui.domain.UserConfiguration,
  formatNumber: (source: number) => string,
) {
  const storedApprovedMaxMileage = record.getData(
    'approvedMaxMileage',
  ) as uui.domain.client.rm.SchedulerResource['approvedMaxMileage']
  const storedApprovedMileage = record.getData(
    'approvedMileage',
  ) as uui.domain.client.rm.SchedulerResource['approvedMileage']

  const approvedMaxMileage =
    conversionUtils.convertMetersToMilesOrKilometers(userConfig)(storedApprovedMaxMileage)

  const approvedMileage = conversionUtils.convertMetersToMilesOrKilometers(userConfig)(
    storedApprovedMileage?.absolute ?? -1,
  )

  const texts = useTexts()

  return useMemo(() => {
    if (approvedMileage === -1) return

    return {
      value: `${formatNumber(approvedMileage)}`,
      total: `${approvedMaxMileage > 0 ? formatNumber(approvedMaxMileage) : texts.unlimited}`,
      saturation:
        approvedMaxMileage > 0
          ? formatNumber(parseFloat(((approvedMileage / approvedMaxMileage) * 100).toFixed(2)))
          : formatNumber(0),
    }
  }, [approvedMileage, approvedMaxMileage, formatNumber, texts])
}
