import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehiclesByDeviceId, selectVehicles } from '@/features/domain/vehicle'

export function useUnifiedVehicle(deviceId: string) {
  const vehicles = useSelector(selectVehicles)
  const vehiclesByDeviceId = useSelector(selectVehiclesByDeviceId)

  return useMemo(() => {
    const uvId = vehiclesByDeviceId[deviceId]

    if (!uvId) {
      throw new Error(`cannot find vehicle for device id: ${deviceId}`)
    }

    return vehicles[uvId]
  }, [vehiclesByDeviceId, deviceId, vehicles])
}
