import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Swap(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 23 18">
        <path d="M3.49 0a.993.993 0 0 0-.78.395L.307 2.8a.996.996 0 0 0 .438 1.684.996.996 0 0 0 .972-.275l.79-.79v8.56c0 2.739 2.248 4.986 4.986 4.986 2.738 0 4.985-2.247 4.985-4.985V5.996a2.994 2.994 0 0 1 2.992-2.991 2.994 2.994 0 0 1 2.99 2.991v8.573l-.784-.799a1.005 1.005 0 0 0-.713-.306 1 1 0 0 0-.709 1.708l2.493 2.53a.996.996 0 0 0 1.422 0l2.492-2.53a1 1 0 0 0-1.421-1.402l-.785.799V5.996c0-2.738-2.247-4.985-4.985-4.985a1 1 0 0 0-.36.074c-2.563.195-4.626 2.302-4.626 4.911v5.983a2.994 2.994 0 0 1-2.991 2.99 2.994 2.994 0 0 1-2.991-2.99V3.418l.79.79a.996.996 0 0 0 1.685-.438.996.996 0 0 0-.275-.971L4.295.392A.996.996 0 0 0 3.49 0z" />
      </svg>
    </IconContainer>
  )
}
