import { useCallback, useMemo } from 'react'

export function useCreateFormatLanguage(supportedLanguages: Record<string, string>) {
  // the backend will provide a Record of the supported languages
  // the issue is that the record has as key the language label
  // and as value the language "id"
  // this will switch key/value of that record
  const supportedLanguageByLanguageId = useMemo(() => {
    const data: Record<string, string> = {}
    const entries = Object.entries(supportedLanguages)
    for (const [label, key] of entries) {
      data[key] = label
    }
    return data
  }, [supportedLanguages])

  return useCallback(
    (langId: string) => {
      return supportedLanguageByLanguageId[langId] ?? langId
    },
    [supportedLanguageByLanguageId],
  )
}
