import { ColumnHeaderRow, TextButton } from '@/forms-legacy'
import { RecurrenceSelector } from '@/components/RecurrenceSelector'
import { FormColumn } from '@/components/layout'
import { Left } from '@/icons'
import { useFormTransaction } from '@/hooks'

import { AddTrafficProfileHeader } from './components/AddTrafficProfileHeader'
import { useTexts } from './hooks/useTexts'
import { useActions } from './hooks/useActions'
import { useRecurrencesMap } from './hooks/useRecurrencesMap'

export function CreateTrafficProfile() {
  const texts = useTexts()
  const { onCancel, onCreateRecurrence, onOpenExistingRecurrence } = useActions()
  const recurrencesMap = useRecurrencesMap()

  const transactionState = useFormTransaction()
  const transactionOpen = transactionState === 'ready'

  return (
    <FormColumn width={320} testid="form__column-primary">
      <AddTrafficProfileHeader>
        <ColumnHeaderRow>
          <TextButton onClick={onCancel}>
            <Left size={8} as="span" />
            {texts.back}
          </TextButton>
        </ColumnHeaderRow>
      </AddTrafficProfileHeader>
      <RecurrenceSelector
        onCreate={onCreateRecurrence}
        onOpenExisting={onOpenExistingRecurrence}
        onCancel={onCancel}
        recurrenceMap={recurrencesMap}
        disabled={!transactionOpen}
      />
    </FormColumn>
  )
}
