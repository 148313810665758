import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    generalSettings: translate({ id: 'vehicles.form.tabs.generalSettings' }),
    exception: translate({ id: 'vehicles.form.tabs.exceptionSettings' }),
  }))

  return api
}
