import { useState, useEffect } from 'react'
import { proxy, useSnapshot } from 'valtio'

type RegisteredModal = Record<string, string>
type RegisteredModalAtom = { modals: Record<string, string> }

// --------------------------------------------------------------------
// --------------------------------------------------------------------
// MODAL INSTANCES MANAGEMENT
// --------------------------------------------------------------------
// --------------------------------------------------------------------

/**
 * The application supports multiple instances of the same modal to be present at the
 * same time into the DOM.
 * Only one modal of each kind can be opened thou. To gracefully manage a variable number
 * of instances of the same modal window there's a precedence-based subscription system.
 * Every modal register itself through its unique ID, the system internally create unique
 * identifiers for every modal instance and only the last registered will be considered active.
 * All the other instances will be kept disabled, ready to became active if the last in the queue
 * is removed from the DOM.
 */

// ------------------------------------
// Registered modal atom
// ------------------------------------

const registeredModalAtom = proxy<RegisteredModalAtom>({
  modals: {},
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetRegisteredModal = (prev: RegisteredModal) => RegisteredModal
type SetRegisteredModalParam = SetRegisteredModal | Partial<RegisteredModal> | 'reset'

export function resetRegisteredModal() {
  registeredModalAtom.modals = {}
}

export function setRegisteredModal(valueOrFunc: SetRegisteredModalParam) {
  if (valueOrFunc === 'reset') resetRegisteredModal()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(registeredModalAtom.modals, valueOrFunc(registeredModalAtom.modals))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      registeredModalAtom.modals[field] = valueOrFunc[field]
    }
  }

  return registeredModalAtom
}

// ------------------------------------
// React Hooks
// ------------------------------------

export const useRegisterModal = (id: string): boolean => {
  const [uid] = useState(() => `${Math.random()}_${id}`)
  const cache = useSnapshot(registeredModalAtom.modals)[id]

  useEffect(() => {
    if (!cache) {
      setRegisteredModal({ [id]: uid })
    }

    return () => {
      if (cache === uid) {
        setRegisteredModal({ [id]: undefined })
      }
    }
  }, [cache, uid, id])

  return cache === uid
}
