import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    gals: translate({ id: 'global.unitOfMeasure.gals' }),
    notSet: translate({ id: 'global.notSet' }),
    acceleration: translate({ id: 'vehicles.form.driverBehaviors.acceleration' }),
    braking: translate({ id: 'vehicles.form.driverBehaviors.braking' }),
    cornering: translate({ id: 'vehicles.form.driverBehaviors.cornering' }),
    hard: translate({ id: 'vehicles.form.driverBehaviors.hard' }),
    harsh: translate({ id: 'vehicles.form.driverBehaviors.harsh' }),
    severe: translate({ id: 'vehicles.form.driverBehaviors.severe' }),
  }))

  return api
}
