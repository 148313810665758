import type { DropDownItemDate } from '../typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { addDays, subDays, parseISO, isEqual, format } from 'date-fns/esm'

import { fileUtils } from '@/utils'
import { selectUserConfiguration } from '@/features/domain/user'
import { timeUtils, getCalendarizedSettings } from '@/server-data'

import { useTexts } from '../useTexts'
import { isBestOption } from '../typings'
import { useController } from './useController'

export const useDateOptions = (): DropDownItemDate[] => {
  const {
    data: { activeDate, activeVehicle, closedRouteIds, today, vehicles, dates },
  } = useController()

  const texts = useTexts()
  const userConfig = useSelector(selectUserConfiguration)
  const todayDate = parseISO(today)

  const bestItem: DropDownItemDate = {
    id: 'best-date-dropdown-item',
    selected: isBestOption(activeDate),
    disabled: false,
    label: texts.bestDate,
    date: 'best',
    kind: 'item',
  }

  const isAtLeastOneVehicleAvailableForDate = useMemo(
    (date?: fileUtils.DateOrDateString) =>
      vehicles.some(vehicle => getCalendarizedSettings(vehicle, date)?.available),
    [vehicles],
  )

  const options = dates.reduce(
    (acc: DropDownItemDate[], date: Date) => {
      const dateString = timeUtils.formatDate(userConfig)(date)
      const isToday = isEqual(date, todayDate)
      const isTomorrow = isEqual(date, addDays(todayDate, 1))
      const isYesterday = isEqual(date, subDays(todayDate, 1))

      const dateDayAsString = texts.getDayLabelFromDayIndex(date.getDay())

      const suffix = isToday
        ? `- ${texts.today}`
        : isTomorrow
        ? `- ${texts.tomorrow}`
        : isYesterday
        ? `- ${texts.yesterday}`
        : `- ${dateDayAsString}`

      const dateAsString = format(date, 'yyyyMMdd')
      const routeId = activeVehicle === 'best' ? '' : `${activeVehicle.id}-${dateAsString}`

      const locked =
        activeVehicle === 'best' ? false : activeVehicle.lockedDates.includes(dateAsString)
      const closed = closedRouteIds.has(routeId)

      const disabled = !isAtLeastOneVehicleAvailableForDate || locked || closed
      const selected = !isBestOption(activeDate) && activeDate === date
      const disabledReason = !isAtLeastOneVehicleAvailableForDate
        ? `(${texts.noAvailableVehicles})`
        : locked
        ? `(${texts.routeLocked})`
        : closed
        ? `(${texts.routeClosed})`
        : ''
      const label = `${dateString} ${suffix} ${disabledReason}`

      acc.push({
        id: dateString,
        date,
        label,
        disabled,
        selected,
        kind: 'item',
      })

      return acc
    },
    [bestItem],
  )

  return options
}
