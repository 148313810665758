import { useCallback } from 'react'

import { setMainSelection } from '@/atoms'
import { colorsUtils } from '@/utils'
import { isDark } from '@/styles'
import { Truck } from '@/icons'

import { TagButton } from './components/TagButton'

interface Props {
  vehicle: uui.domain.client.UnifiedVehicle
}

export function VehicleTag(props: Props) {
  const { vehicle } = props

  const onClick = useCallback(() => {
    if (!vehicle.hasRoutingLicense) return
    setMainSelection('unifiedVehicles', [vehicle.unifiedId])
  }, [vehicle])

  if (!vehicle.hasRoutingLicense) return null

  const backgroundColor = colorsUtils.getValidHexColor(vehicle.vehicle.color)

  return (
    <TagButton
      data-trackid="navigo-breadcrumbs-overview-details-single-vehicle"
      data-testid="navigo-breadcrumbs-overview-details-single-vehicle"
      color={backgroundColor}
      onClick={onClick}
    >
      <Truck size={14} color={isDark(backgroundColor) ? '$pureWhite' : '$nightRider'} />
    </TagButton>
  )
}
