import { computeDeviceData, createEmptyDeviceData } from '../../../../hooks/useDeviceData'
import { computeRmData } from '../utils/computeRmData'

export function computeWwGpsFormInitialValue(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  userConfig: uui.domain.UserConfiguration,
  allGpsTags: Record<string, uui.domain.client.gps.wwgps.Tag>,
): uui.domain.ui.forms.Rm360VehicleFormValues {
  const metric = userConfig.distanceFormat === 'METRIC'

  if (unifiedVehicle.isPhysicalDevice && unifiedVehicle.trackingProvider !== 'WWGPS') {
    throw new Error('Invalid tracking provider')
  }

  const rm = computeRmData(unifiedVehicle)
  const device: uui.domain.ui.forms.DeviceData = unifiedVehicle.isPhysicalDevice
    ? computeDeviceData({
        deviceConfiguration: unifiedVehicle.deviceConfiguration,
        idleStartThreshold: unifiedVehicle.idleStartThreshold,
        driverBehaviors: unifiedVehicle.driverBehaviors,
        speedMonitor: unifiedVehicle.speedMonitor,
        allGpsTags,
        userConfig,
        gpsTags: unifiedVehicle.allowGps ? unifiedVehicle.gpsTags : [],
        device: unifiedVehicle.device,
      })
    : createEmptyDeviceData(metric)

  return {
    rm,
    device,
    trackingOption: unifiedVehicle.isPhysicalDevice ? 'physicalDevice' : 'mobileApp',
  }
}
