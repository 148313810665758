import styled from 'styled-components'

type Props = {
  checked?: boolean
}

const checkboxSize: number = 14

const CheckboxIcon = styled.span<Props>`
  display: flex;
  margin: 0;
  padding: 0;
  outline: 0;
  border-style: solid;
  border-radius: 20%;
  border-color: ${p => (p.checked ? 'transparent' : p.theme.colors.$silver)};
  border-width: 2px;
  cursor: pointer;
  background: ${p => (p.checked ? p.theme.colors.$scienceBlue : p.theme.colors.$pureWhite)};
  color: ${p => (p.checked ? p.theme.colors.$pureWhite : 'transparent')};
  height: ${checkboxSize}px;
  width: ${checkboxSize}px;
  transition: 0.3s ease;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  > * {
    display: block;
    margin-top: 1px;
    width: 100%;
  }
`

CheckboxIcon.displayName = 'CheckboxIcon'
export default CheckboxIcon
