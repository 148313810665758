import { type HTMLAttributes, type DetailedHTMLProps, CSSProperties } from 'react'
import { clsx } from '@/utils'

type BaseDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type Props = BaseDivProps &
  Partial<{
    direction: CSSProperties['flexDirection']
    gap: number
    alignItems: CSSProperties['alignItems']
    justifyContent: CSSProperties['justifyContent']
  }>

export function Box(props: Props) {
  const { children, className, direction, gap, alignItems, justifyContent, style, ...rest } = props

  const styles = {
    ...style,
    ...(direction ? { '--direction': direction } : {}),
    ...(alignItems ? { '--align-items': alignItems } : {}),
    ...(justifyContent ? { '--justify-content': justifyContent } : {}),
    ...(typeof gap === 'number' ? { '--gap': `${gap}px` } : {}),
  } as CSSProperties

  return (
    <div className={clsx(className, 'o-scheduler-box')} style={styles} {...rest}>
      {children}
    </div>
  )
}
