import type { InspectPin } from '../inspectPin'

import { snapshot } from 'valtio'

import { inspectPin } from '../inspectPin'
import { removeInspectPin } from './removeInspectPin'

export type SetInspectPin = (prev: InspectPin) => InspectPin

export type SetInspectPinParam = SetInspectPin | Partial<InspectPin> | 'reset'

export function setInspectPin(valueOrFunc: SetInspectPinParam) {
  // reset
  if (valueOrFunc === 'reset') return removeInspectPin()
  if (valueOrFunc === undefined) return removeInspectPin()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    inspectPin.pin = valueOrFunc(inspectPin.pin ? snapshot(inspectPin.pin) : inspectPin.pin)
  } else if (inspectPin.pin && valueOrFunc) {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      inspectPin.pin[field] = valueOrFunc[field]
    }
  } else {
    Object.assign(inspectPin, { pin: valueOrFunc })
  }

  return inspectPin.pin ? snapshot(inspectPin.pin) : undefined
}
