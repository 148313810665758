import { useMemo } from 'react'

import { useCreateAlertLabel } from '../../../hooks/useCreateAlertLabel'

import { useTexts } from './useTexts'

export function useHeaderDescription(alerts: uui.domain.client.gps.wwgps.EventAlert[]) {
  const texts = useTexts()
  const firstEventLabel = useCreateAlertLabel()(alerts[0].eventTypeId)

  return useMemo(() => {
    const firstAlertEventType = alerts[0].eventTypeId
    const allAlertsShareSameEventType = alerts.every(a => a.eventTypeId === firstAlertEventType)

    return allAlertsShareSameEventType ? firstEventLabel : texts.genericDescription
  }, [alerts, firstEventLabel, texts])
}
