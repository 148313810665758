import type { CheckboxProps as Props } from '@mui/material'

import { withStyles } from '@mui/styles'
import { Checkbox as MUICheckbox } from '@mui/material'
import { Check } from '@/icons'

import { CheckboxIcon } from './CheckboxIcon'

const StyledCheckbox = withStyles({ root: { padding: '0' } })(MUICheckbox)

export function Checkbox(props: Props) {
  const { disabled } = props

  return (
    <StyledCheckbox
      {...props}
      disableRipple
      icon={<CheckboxIcon disabled={disabled} />}
      checkedIcon={
        <CheckboxIcon disabled={disabled} checked>
          <Check as="div" color="$pureWhite" size={9} style={{ marginTop: 2, marginLeft: 2 }} />
        </CheckboxIcon>
      }
    />
  )
}
