import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Picture(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M24.375 4c-1.79688 0-3.67578 1.42188-4.4375 3.375L19.3125 9H14V8c0-.5547-.4453-1-1-1H6c-.5547 0-1 .4453-1 1v1.28125C2.0586 10.14845 0 12.86328 0 16v21c0 3.85938 3.14063 7 7 7h36c3.85938 0 7-3.14063 7-7V16c0-3.85938-3.14063-7-7-7h-2.3125l-.625-1.625C39.30078 5.42187 37.42187 4 35.625 4zM30 13c7.16797 0 13 5.83203 13 13s-5.83203 13-13 13-13-5.83203-13-13 5.83203-13 13-13zM7 14c1.10547 0 2 .89453 2 2s-.89453 2-2 2-2-.89453-2-2 .89453-2 2-2zm23 1c-6.0664 0-11 4.9336-11 11s4.9336 11 11 11 11-4.9336 11-11-4.9336-11-11-11z" />
      </svg>
    </IconContainer>
  )
}
