import tinycolor from 'tinycolor2'

import { Style } from 'ol/style'
import { FeatureLike } from 'ol/Feature'

import { drawFeatureMetadata } from '../utils/drawFeatureMetadata'

import { firstPoint } from './utils/firstPoint'
import { normalPoint } from './utils/normalPoint'
import { createMainFill } from './utils/createMainFill'
import { lastAddedPoint } from './utils/lastAddedPoint'
import { validSketchPoint } from './utils/validSketchPoint'
import { createMainOutline } from './utils/createMainOutline'
import { invalidSketchPoint } from './utils/invalidSketchPoint'
import { invalidTemporarySegment } from './utils/invalidTemporarySegment'
import { createValidTemporarySegment } from './utils/createValidTemporarySegment'

type Color = [number, number, number, number]

export const createDrawStyle = (rawMainColor: string) => {
  const baseColor = tinycolor(rawMainColor).toRgb()

  function getMainColor(alpha: number = 1): Color {
    const { r, g, b } = baseColor
    return [r, g, b, alpha]
  }

  const mainFill = createMainFill(getMainColor(0.4))
  const mainOutline = createMainOutline(getMainColor(1))
  const validTemporarySegment = createValidTemporarySegment(getMainColor(1))

  const validStyle = [
    mainFill,
    mainOutline,
    validTemporarySegment,
    normalPoint,
    firstPoint,
    lastAddedPoint,
    validSketchPoint,
  ]

  const invalidStyle = [
    mainFill,
    mainOutline,
    invalidTemporarySegment,
    normalPoint,
    firstPoint,
    lastAddedPoint,
    invalidSketchPoint,
  ]

  /**
   * Open Layers Style function used to apply the correct visual stylers to the layer
   */
  return (feature: FeatureLike, _resolution: number): Style[] => {
    // try to access the `valid` custom field to determine which style must be used
    const valid = drawFeatureMetadata.isValid(feature)
    return valid ? validStyle : invalidStyle
  }
}
