import { useEffect } from 'react'

import { setEditingState } from '@/atoms'

export function useStopEditingOnUnmount() {
  useEffect(() => {
    setEditingState({ editing: true })

    return () => {
      setEditingState({ editing: false })
    }
  }, [])
}
