import type { Props } from './typings'

import { FlexRow, FlexColumn, CircularButton } from '@/forms-legacy'
import { Cross } from '@/icons'

import ErrorLabel from './ErrorLabel'
import UiVehicleLoad from './VehicleLoad'

function VehicleLoads(props: Props) {
  const { loads, onDeleteLoad, onChangeLoad, renderText, useMetric } = props

  return (
    <>
      {Object.entries(loads).map(([key, load]: [string, uui.domain.ui.forms.VehicleLoad]) => {
        const { value, min } = load

        const validValue: boolean = value > 0
        const validMin: boolean = min < value

        return (
          <FlexColumn primary key={key}>
            <FlexRow style={{ marginTop: 10 }}>
              <CircularButton
                style={{ marginRight: 10 }}
                onClick={() => onDeleteLoad(key)}
                Icon={<Cross />}
              />
              <UiVehicleLoad
                label={key}
                value={load}
                onChange={onChangeLoad}
                useMetric={useMetric}
              />
            </FlexRow>
            {!validValue && (
              <ErrorLabel style={{ marginLeft: 28 }}>{renderText('notValidValue')}</ErrorLabel>
            )}
            {validValue && !validMin && (
              <ErrorLabel style={{ marginLeft: 28 }}>{renderText('notValidMinValue')}</ErrorLabel>
            )}
          </FlexColumn>
        )
      })}
    </>
  )
}

export default VehicleLoads
