import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const actions = useControllerActions()
  const { close, status, invalid } = useController()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          disabled={invalid || status !== 'ready'}
          onClick={actions.onConfirmTimeShiftPlan}
          variant="contained"
        >
          {texts.commandButtonTitle}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton onClick={close}>{texts.cancelButtonTitle}</ModalFooterButton>
      }
    />
  )
}
