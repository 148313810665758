import { Separator } from '@/components/Separator'
import { MapStyle } from '@/icons'

import { NavigoIconButton } from '../../../../elements/NavigoIconButton'
import { CloseButton } from '../../../../elements/CloseButton'

import { usePlacesNavigator } from '../../hooks/usePlacesNavigator'
import { Details } from './components/Details'
import { Actions } from './components/Actions'

import { useTexts } from './useTexts'

interface Props {
  expanded: boolean
  goToStyle: () => void
  toggleExpanded: () => void
}

export function Overview(props: Props) {
  const { goToStyle, expanded, toggleExpanded } = props

  const { currentPlaces } = usePlacesNavigator()
  const texts = useTexts()

  return (
    <>
      <Details places={currentPlaces} />

      <Separator />

      <Actions expanded={expanded} toggleExpanded={toggleExpanded} />

      <NavigoIconButton
        text={currentPlaces.length > 1 ? texts.styleAll : texts.style}
        Icon={<MapStyle size={20} color="$nightRider" />}
        testid="navigo-places-map-style-button"
        onClick={goToStyle}
      />

      <CloseButton />
    </>
  )
}
