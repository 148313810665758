import { Component, CSSProperties } from 'react'

import { Cross } from '@/icons'

import RoundBtn from './elements/RoundBtn'

export interface Props {
  onClick: () => void
  style?: CSSProperties
}

export default class DeleteBtn extends Component<Props> {
  render() {
    const { onClick, style } = this.props
    return (
      <RoundBtn type="button" onClick={onClick} style={style}>
        <Cross size={8} as="div" />
      </RoundBtn>
    )
  }
}
