import { useCallback } from 'react'

import { setMapSelectionMode, useInspectPin, useToggleInspectPin } from '@/map'

export const useInspectPinToggle = () => {
  const toggleInspectPin = useToggleInspectPin()
  const [inspectPin] = useInspectPin()

  const toggle = useCallback(() => {
    // lasso and inspect pin are mutually exclusive
    setMapSelectionMode('normal')

    toggleInspectPin()
  }, [toggleInspectPin])

  return { inspectPin, toggleInspectPin: toggle }
}
