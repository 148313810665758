import { Entry } from '../Entry'

export function Entry20220502_2() {
  return (
    <Entry title="02/05/2022 - v3.0.3">
      Link to the{' '}
      <a
        href="https://workwave.atlassian.net/projects/RM/versions/12595/tab/release-report-all-issues"
        target="_blank"
        rel="noreferrer"
      >
        Jira Release
      </a>
    </Entry>
  )
}
