import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { Tooltip } from '@/components/primitives/Tooltip'
import { VehicleAssignment } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  disabled: boolean
}

export function VehicleAssignmentButton(props: Props) {
  const { disabled, onClick } = props
  const texts = useTexts()

  const iconColor = disabled ? '$silver' : '$nightRider'

  return (
    <Tooltip placement="top" disabled={!disabled} title={texts.disabledForBeta}>
      <NavigoIconButton
        onClick={onClick}
        disabled={disabled}
        text={texts.vehicleAssignment}
        Icon={<VehicleAssignment size={18} color={iconColor} />}
        testid="navigo-drivers-actions-vehicleAssignmentButton"
      />
    </Tooltip>
  )
}
