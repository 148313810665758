import { mapAtom } from '../../atoms/map/map'
import { findLayer } from '../utils/findLayer'

import { mapStylesUpdateDeviceFeatures } from './utils/mapStylesUpdateDeviceFeatures'

export const createUpdateDeviceFeaturesMapStyleFromChangelog = (
  markers: uui.domain.ui.map.markers.MapMarkers['device'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['device'],
  selection: Set<string>,
) => {
  // ATTENTION: Application of Map Style changes will not be cached if a delayed rendering mechanism will be implemented
  // After the whole set of delayed marker changelogs is applied a full refresh of Map Styles will be required

  return async () => {
    const map = mapAtom.map
    const layer = findLayer(map, 'device')

    // only the `update` field is populated in a mapStyles changelog
    await mapStylesUpdateDeviceFeatures(layer, markers, mapStyles, selection)
  }
}
