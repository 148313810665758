import type { LocationEditorProps } from '../typings'

import { useEffect, useRef } from 'react'
import { useForm } from 'react-final-form'

import { gis } from '@/server-data'
import { getViaDotNotation } from '@/utils'
import { subscribeToLocationPin } from '@/map'

import { useFormFieldLocation } from './useFormFieldLocation'
import { useFormFieldOnChange } from './useFormFieldOnChange'

export function useConnectLocationPinToFormField(options: LocationEditorProps) {
  const { pinId, onRadiusChanged, pinRadius } = options
  const formApi = useForm()

  const formLocation = useFormFieldLocation(options)
  const onChange = useFormFieldOnChange(options)

  const api = useRef({ formLocation, onChange, onRadiusChanged, pinRadius })
  useEffect(() => {
    api.current = { formLocation, onChange, onRadiusChanged, pinRadius }
  }, [formLocation, onChange, onRadiusChanged, pinRadius])

  // listen to pin changes
  useEffect(() => {
    let effectCleared = false

    const unsubscribe = subscribeToLocationPin(pinId, pin => {
      if (effectCleared) return
      if (!pin) return

      const { formLocation, onChange } = api.current
      const location = pin.pin.location

      if (!location) return
      if (!formLocation) return

      // When the location editor starts from a manual location (creation of new entity, so not reverse geocoded)
      // we have to update it to correctly support the Restore button
      const isGeocodedVersionOfInitialLocation =
        formLocation.geoAddress.length === 0 && location.geoAddress

      if (isGeocodedVersionOfInitialLocation) {
        const { values, initialValues, dirtyFields } = formApi.getState()

        const dirtyFieldNames = Object.keys(dirtyFields)

        switch (pin.id) {
          case 'orderForm_pickup':
            formApi.initialize({ ...values, pickup: { ...values.pickup, location } })

            for (const dirtyFieldName of dirtyFieldNames) {
              formApi.mutators.setInitialValue(
                dirtyFieldName,
                getViaDotNotation(initialValues, dirtyFieldName),
              )
            }

            break

          case 'orderForm_service':
          case 'orderForm_delivery':
            formApi.initialize({ ...values, delivery: { ...values.delivery, location } })

            for (const dirtyFieldName of dirtyFieldNames) {
              formApi.mutators.setInitialValue(
                dirtyFieldName,
                getViaDotNotation(initialValues, dirtyFieldName),
              )
            }
            break

          default:
            formApi.initialize({ ...values, location })
        }
      }

      // Update Form Location if the pin has been moved
      if (
        !gis.latLngEquals(formLocation.latLng, location.latLng) ||
        formLocation.address !== location.address ||
        formLocation.geoAddress !== location.geoAddress ||
        formLocation.status !== location.status
      ) {
        // update form location
        onChange({ ...location })
      }

      const { onRadiusChanged, pinRadius } = api.current

      // Update Form radius if the pin radius has been changed
      if (onRadiusChanged && typeof pinRadius === 'number' && pinRadius !== pin.pin.radius) {
        onRadiusChanged(pin.pin.radius, pinRadius)
      }
    })

    return () => {
      effectCleared = true
      unsubscribe()
    }
  }, [formApi, pinId])
}
