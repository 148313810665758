import { CSSProperties, PureComponent } from 'react'

import { LoadsListField as LoadsList } from '@/forms-legacy'

import * as OrderTexts from '../../../../../../intl'
import { Props } from '../typings'

export default class LoadsListField extends PureComponent<Props> {
  render() {
    const { loads, type, useMetric } = this.props
    const containerStyle: CSSProperties = {
      display: type === 's' ? 'none' : 'block',
    }

    return (
      <div style={containerStyle}>
        <LoadsList
          name="order.loads"
          label={OrderTexts.getFieldLabelLoadsText()}
          wrappedInputProps={{
            suggestions: loads,
            maxCount: 5,
            notInSuggestionListLabel: OrderTexts.getUndefinedLoadText(),
            renderText: OrderTexts.getLoadsRenderText,
            useMetric,
          }}
          testid="loads"
        />
      </div>
    )
  }
}
