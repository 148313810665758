import { DropdownMenuItem } from '@/components/DropdownMenu'
import { Link } from '@/routing'
import { clsx } from '@/utils'

import { useTexts } from '../../../useTexts'
import { ActiveIcon } from './ActiveIcon'

import { useStyles } from './hooks/useStyles'

type RoutingRoutes = uui.routing.Routing

export function RmGuestLinks() {
  const classes = useStyles()
  const texts = useTexts()

  return (
    <>
      <Link<RoutingRoutes>
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        className={clsx(classes.link, 'pendo-bluebar__orders-menu')}
      >
        <DropdownMenuItem
          data-testid="header__nav-orders-button-link"
          data-trackid="rm-guest-header__nav-orders-button-link"
        >
          <ActiveIcon />
          {texts.orders}
        </DropdownMenuItem>
      </Link>
    </>
  )
}
