import type { ReactElement } from 'react'

import { VerticalLayout } from '@/components/layout'
import { Stack } from '@/local/components'

interface Props {
  indentation?: 0 | 1
  children: ReactElement | ReactElement[]
}

export function Section(props: Props) {
  return (
    <VerticalLayout paddingLeft={props.indentation === 1 ? 22 : 0} alignItems="stretch">
      <Stack space={props.indentation === 1 ? 's' : 'm'}>{props.children}</Stack>
    </VerticalLayout>
  )
}
