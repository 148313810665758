import styled from 'styled-components'

const SectionTitle = styled.h3`
  position: relative;
  width: 100%;
  margin: 10px 0 5px;
  color: ${p => p.theme.colors.$nightRider};
  font-size: ${p => p.theme.fonts.$p2};
  font-weight: ${p => p.theme.weights.$semiBold};
  padding-left: 4px;
`
SectionTitle.displayName = 'SectionTitle'
export default SectionTitle
