import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Car(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M10.2157 6C8.38713 6 6.75302 7.15956 6.14994 8.88371L3.93299 15.2169C2.74945 15.9956 2 17.3403 2 18.82V26.4615C2 27.6515 2.96385 28.6154 4.15385 28.6154C5.34385 28.6154 6.30769 27.6515 6.30769 26.4615V25.1028C6.30769 25.1028 11.9949 25.3846 16 25.3846C20.0051 25.3846 25.6923 25.1028 25.6923 25.1028V26.4615C25.6923 27.6515 26.6562 28.6154 27.8462 28.6154C29.0362 28.6154 30 27.6515 30 26.4615V18.82C30 17.3403 29.2484 15.9945 28.067 15.2169L25.8522 8.88582C25.248 7.16059 23.6129 6 21.7843 6H10.2157ZM10.2157 8.15385H21.7843C22.6996 8.15385 23.5156 8.73306 23.8182 9.59676L25.0508 13.122C25.2446 13.6766 24.7836 14.2358 24.2031 14.1421C22.0913 13.8007 19.3762 13.5385 16 13.5385C12.6238 13.5385 9.90872 13.8007 7.79688 14.1421C7.21641 14.2358 6.75537 13.6766 6.94922 13.122L8.18179 9.59676C8.48441 8.73306 9.30036 8.15385 10.2157 8.15385ZM8.46154 19.4615C8.46154 18.5698 7.73785 17.8462 6.84615 17.8462C5.95446 17.8462 5.23077 18.5698 5.23077 19.4615C5.23077 20.3532 5.95446 21.0769 6.84615 21.0769C7.73785 21.0769 8.46154 20.3532 8.46154 19.4615ZM19.2308 17.8462C19.8252 17.8462 20.3077 18.3286 20.3077 18.9231C20.3077 19.5175 19.8252 20 19.2308 20H12.7692C12.1748 20 11.6923 19.5175 11.6923 18.9231C11.6923 18.3286 12.1748 17.8462 12.7692 17.8462H19.2308ZM26.7692 19.4615C26.7692 18.5698 26.0455 17.8462 25.1538 17.8462C24.2622 17.8462 23.5385 18.5698 23.5385 19.4615C23.5385 20.3532 24.2622 21.0769 25.1538 21.0769C26.0455 21.0769 26.7692 20.3532 26.7692 19.4615Z" />
      </svg>
    </IconContainer>
  )
}
