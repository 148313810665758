import type {
  FormError,
  FormField,
  FormValues as FormValuesType,
} from '@workwave-tidal/tidal/form-fairy'

const messageField: FormField<string> = {
  required: true,
  value: '',
}

export const formFields = {
  message: messageField,
}

export type FormFields = typeof formFields
export type FormErrors = FormError<keyof FormFields>
export type FormValues = FormValuesType<FormFields>
