import { type SchedulerPro } from '@bryntum/schedulerpro'
import { subscribe } from 'valtio/vanilla'
import { applicationModeAtom, loadingStateAtom } from '@/atoms'

export function startSchedulerReadonlySync(scheduler: SchedulerPro) {
  return subscribe(applicationModeAtom, () => {
    // eslint-disable-next-line no-param-reassign
    scheduler.readOnly = applicationModeAtom.mode === 'readonly' || loadingStateAtom.isRescheduling
  })
}
