import styled from 'styled-components'

const FooterTitle = styled.h3`
  position: relative;
  color: ${p => p.theme.colors.$nightRider};
  font-size: ${p => p.theme.fonts.$s};
  font-weight: ${p => p.theme.weights.$semiBold};
`
FooterTitle.displayName = 'FooterTitle'
export default FooterTitle
