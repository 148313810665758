import type OlMap from 'ol/Map'

import VectorImageLayer from 'ol/layer/VectorImage'
import VectorSource from 'ol/source/Vector'

import { setLayerMetadata } from '../layerMetadata'

export const createOrderLayer = (map: OlMap) => {
  const source = new VectorSource()
  const layer = new VectorImageLayer({ source })

  setLayerMetadata(layer, 'uid', 'order')

  map.addLayer(layer)
}
