import { useEffect } from 'react'

import { useAppDispatch } from '@/store'
import { loadDemoTerritories } from '@/features/domain/demoTools'

import { useController } from './useController'

export const useFetchDemoTerritories = () => {
  const { update } = useController()
  const dispatch = useAppDispatch()

  useEffect(() => {
    let effectCleaned = false

    const fetchDemoTerritories = async () => {
      try {
        if (effectCleaned) return

        const response = await dispatch(loadDemoTerritories())

        if (loadDemoTerritories.fulfilled.match(response))
          return update({
            status: 'ready',
            invalid: false,
            data: { territories: response.payload },
          })

        update({ status: 'failed', invalid: true, data: { territories: [] } })
      } catch (e) {
        if (effectCleaned) return

        update({ status: 'failed', invalid: true, data: { territories: [] } })
      }
    }

    fetchDemoTerritories()

    return () => {
      effectCleaned = true
    }
  }, [dispatch, update])
}
