import { Fragment } from 'react'
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'

import { useController } from '../../../../../hooks/useController'
import { useControllerActions } from '../../../../../hooks/useControllerActions'
import { useTexts } from '../../../../../hooks/useTexts'
import { useFilterOptions } from '../../../../../hooks/useFilterOptions'

import { NotAllRoutesDispatchedWarning } from './NotAllRoutesDispatchedWarning'
import { NoApprovedRoutes } from './NoApprovedRoutes'
import { IncludePod } from './IncludePod'

const routesFilterTestId = 'export-route-routes-filter'

export function FilterPicker() {
  const {
    data: { filter, approvedRoutesAmount, selectedRoutes },
  } = useController()

  const handlers = useControllerActions()
  const filterOptions = useFilterOptions()

  const selectedRoutesAmount = selectedRoutes.length
  const noApprovedRoutes = approvedRoutesAmount === 0

  const texts = useTexts()

  if (noApprovedRoutes) {
    return <NoApprovedRoutes />
  }

  return (
    <Box>
      <FormLabel id="filter-picker">{texts.filterTitle}</FormLabel>
      <RadioGroup
        aria-labelledby="filter-picker"
        value={filter}
        onChange={handlers.onChangeFilter}
        data-testid={routesFilterTestId}
        data-trackid={routesFilterTestId}
      >
        {filterOptions.map(opt => {
          const showNotAllRoutesDispatchedWarning =
            opt.value === 'dispatched' &&
            filter === 'dispatched' &&
            approvedRoutesAmount < selectedRoutesAmount

          const showIncludePod = opt.value === 'selected' && filter === 'selected'

          return (
            <Fragment key={opt.value}>
              <Tooltip placement="bottom" title={opt.tooltip} disabled={!opt.disabled}>
                <span>
                  <FormControlLabel
                    value={opt.value}
                    control={<Radio data-testid={`export-route-filter-${opt.value}`} />}
                    label={opt.label}
                    disabled={opt.disabled}
                  />
                  <Stack gap={1} pl={4}>
                    <Typography
                      variant="caption"
                      sx={theme => ({
                        color: opt.disabled ? theme.vars.palette.action.disabled : undefined,
                      })}
                    >
                      {opt.description}
                    </Typography>
                    {showIncludePod && <IncludePod />}
                    {showNotAllRoutesDispatchedWarning && (
                      <NotAllRoutesDispatchedWarning
                        approvedRoutesAmount={approvedRoutesAmount}
                        selectedRoutesAmount={selectedRoutesAmount}
                      />
                    )}
                  </Stack>
                </span>
              </Tooltip>
            </Fragment>
          )
        })}
      </RadioGroup>
    </Box>
  )
}
