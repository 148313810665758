import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import Polygon from 'ol/geom/Polygon'

import { setTrafficAreaFeatureMetadata } from '../trafficAreaFeatureMetadata'
import { getTrafficAreaFeatureStyle } from './getTrafficAreaFeatureStyle'

/**
 * Update a Traffic area feature style and position on the map
 */
export function updateTrafficAreaFeature(
  mapStyles: uui.domain.ui.map.markers.MapStyles['trafficProfile'],
  marker: uui.domain.ui.map.markers.Area,
  feature: Feature<Geometry>,
  selected: boolean = false,
): Feature<Geometry> {
  // ------------------------
  // Feature Geometry
  // ------------------------

  feature.setGeometry(new Polygon([marker.points]))

  // ------------------------
  // Feature Metadata
  // ------------------------

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['trafficProfile']['mode'] =
    marker.mode ?? mapStyles.mode
  setTrafficAreaFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getTrafficAreaFeatureStyle(marker, mode, selected))

  return feature
}
