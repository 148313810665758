import styled from 'styled-components'
import InputFieldText from './InputFieldText'

export type { Props } from './InputFieldText'

const InputFieldTextBig = styled(InputFieldText)`
  font-size: ${p => p.theme.fonts.$h3};
  background: ${p => p.theme.colors.$pureWhite};
  height: 32px;

  .o-input-field-text__base-input {
    font-size: inherit;
  }
`

InputFieldTextBig.displayName = 'InputFieldTextBig'

export default InputFieldTextBig
