import {
  ItemRendererLabel,
  ItemRendererTitle,
  ClearSelectionIcon,
  TextButton,
  FlexRow,
  FlexColumn,
} from '@/forms-legacy'
import { Cross } from '@/icons'

import ConfirmButton from './elements/ConfirmButton'
import { Props } from './typings'

const style = { padding: '0 30px' } as const

const ConfirmPanel = (props: Props) => {
  const { title, description, onCancel, onConfirm, confirmationText, testid } = props

  return (
    <FlexRow height={60} justifyContent="space-between" style={style} data-testid={testid}>
      <FlexColumn primary hideOverflow>
        <ItemRendererTitle>{title}</ItemRendererTitle>
        {description && <ItemRendererLabel>{description}</ItemRendererLabel>}
      </FlexColumn>

      <FlexColumn hideOverflow>
        <FlexRow>
          <TextButton
            onClick={onCancel}
            data-testid="confirm-panel__cancel-button"
            data-trackid="confirm-panel__cancel-button"
          >
            <ClearSelectionIcon>
              <Cross size={9} />
            </ClearSelectionIcon>
          </TextButton>

          <ConfirmButton
            style={{ marginLeft: 14 }}
            onClick={onConfirm}
            data-testid="confirm-panel__confirm-button"
            data-trackid="confirm-panel__confirm-button"
          >
            {confirmationText}
          </ConfirmButton>
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  )
}

export default ConfirmPanel
