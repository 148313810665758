import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Polyline(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 20 17">
        <path d="M2.628 12.842l1.338-2.317A1.688 1.688 0 0 1 5.54 7.798l.004-.007.02.01c.05.01.101.02.152.034.598.16 1.034.623 1.19 1.179l3.189.678a1.69 1.69 0 0 1 1.207-.957l-.092-5.252a1.688 1.688 0 1 1 1.964-1.054l3.237 2.351a1.688 1.688 0 1 1-.617.943l-3.344-2.43a1.698 1.698 0 0 1-.117.063l.097 5.548a1.688 1.688 0 1 1-2.431 1.917l-3.176-.675a1.688 1.688 0 0 1-1.899.97L3.49 13.598a1.688 1.688 0 1 1-.862-.757z"></path>
      </svg>
    </IconContainer>
  )
}
