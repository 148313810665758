import { Separator } from '@/components/Separator'

import { NavigoContainer } from '../../elements/NavigoContainer'
import { CloseButton } from '../../elements/CloseButton'

import { Position } from './components/Position'
import { Actions } from './components/Actions'
import { Info } from './components/Info'

import { useInspectPinNavigoActions } from './hooks/useInspectPinNavigoActions'

export function InspectPin() {
  const { close } = useInspectPinNavigoActions()

  return (
    <NavigoContainer data-testid="navigo-inspect-pin" data-trackid="navigo-inspect-pin">
      <Info />

      <Separator />

      <Position />

      <Actions />

      <CloseButton onClick={close} />
    </NavigoContainer>
  )
}
