import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { type GridValueGetterParams } from '@mui/x-data-grid-pro'

import { selectCompanies } from '@/features/domain/company'
import { selectUserConfiguration } from '@/features/domain/user'

export function useGetCompanyValue() {
  const companies = useSelector(selectCompanies)
  const { planType } = useSelector(selectUserConfiguration)

  const archived = planType === 'archived'

  return useCallback(
    (params: GridValueGetterParams) => {
      const fieldName = params.field.replace('formattedData.', '')
      const companyId = params.row[fieldName]

      if (archived) return companyId

      if (companyId === null) return '-'

      return companies[companyId]?.name || '-'
    },
    [companies, archived],
  )
}
