import type { ReactElement } from 'react'

import { makeStyles } from '@mui/styles'

import { IconButton } from '@/components/primitives/buttons/IconButton'
import { Tooltip } from '@/components/primitives/Tooltip'
import { theme } from '@/styles'
import { Link } from '@/routing'
import { clsx } from '@/utils'

interface Props {
  tooltipTitle: string
  tooltipSubtitle?: string
  className?: string
  Icon: ReactElement
  to: string
  testid?: string
}

const useStyles = makeStyles({
  iconButton: { height: 36, padding: '8px !important' },
  link: { height: '100%' },
  wrapper: {
    height: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',

    '&::after': {
      left: 0,
      bottom: 0,
      content: '" "',
      width: '100%',
      height: '5px',
      display: 'block',
      position: 'absolute',
      transform: 'scale(0, 1)',
      transformOrigin: 'left center',
      transition: 'transform 0.3s ease',
      backgroundColor: theme.colors.$pureWhite,
    },

    '.active &::after': {
      transform: 'scale(1, 1)',
    },
  },
})

export function LinkIcon(props: Props) {
  const { to, className, tooltipTitle, tooltipSubtitle, Icon, testid } = props
  const classes = useStyles()

  return (
    <Link to={to} className={clsx(classes.link, className)}>
      <div className={classes.wrapper}>
        <Tooltip placement="bottom" title={tooltipTitle} subtitle={tooltipSubtitle}>
          <IconButton className={classes.iconButton} data-testid={testid} data-trackid={testid}>
            {Icon}
          </IconButton>
        </Tooltip>
      </div>
    </Link>
  )
}
