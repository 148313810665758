import type { BulkFieldValue } from '../../../types'

export function computeType(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
): BulkFieldValue<'pickup' | 'dropoff' | 'service'> {
  const type = orders[0].orderStep.type
  const exact = orders.every(o => o.orderStep.type === type)

  if (exact) {
    const value = type === 'd' ? 'dropoff' : type === 'p' ? 'pickup' : 'service'

    return {
      status: 'exact',
      value,
    }
  }

  return {
    status: 'mixed',
  }
}
