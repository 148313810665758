import styled from 'styled-components'

type Props = {
  showBackground?: boolean
  centerItems?: boolean
  hideBottomSpacing?: boolean
  fullPage?: boolean
}

const FooterRow = styled.footer<Props>`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  width: ${p => (p.fullPage ? '484px' : '100%')};
  background: ${p => (p.showBackground ? p.theme.colors.$alabaster : 'transparent')};
  align-items: center;
  justify-content: ${p => (p.centerItems ? 'center' : 'flex-start')};
  padding: 18px 0;
  padding-bottom: ${p => (p.hideBottomSpacing ? 0 : 18)}px;
  ${p => (p.fullPage ? 'margin: 0 auto' : '')};
`

FooterRow.displayName = 'FooterRow'
export default FooterRow
