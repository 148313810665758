import { useCallback, useState } from 'react'
import { Skeleton } from '@mui/material'

import { resetCrudSelection } from '@/atoms'

import {
  FormContent,
  SecondaryColumnHeader,
  SecondaryColumnContentContainer,
  SecondaryColumnContentHorizontalRuler,
} from '@/forms-legacy'
import { FormColumn } from '@/components/layout'

import { Avatar } from '../../components/Avatar'

import { ActionsManager } from './components/ActionsManager'
import { DeviceInfo } from './components/DeviceInfo'
import { EventTime } from './components/EventTime'
import { Speed } from './components/Speed'
import { Heading } from './components/Heading'
import { DriverBehavior } from './components/DriverBehavior'
import { useEventTitle } from './hooks/useEventTitle'
import { useDescription } from './hooks/useDescription'
import { useUnifiedVehicle } from './hooks/useUnifiedVehicle'

interface Props {
  deviceEvent: uui.domain.client.gps.wwgps.EventInfo
}

const avatarStyle = { marginRight: 16, border: 'none' }

export function SingleView(props: Props) {
  const { deviceEvent } = props

  const [view, setView] = useState<'default' | 'delete'>(() => 'default')
  const eventTitle = useEventTitle(deviceEvent)
  const description = useDescription(deviceEvent)

  const unifiedVehicle = useUnifiedVehicle(deviceEvent.deviceId)

  const onReset = useCallback(() => {
    resetCrudSelection('deviceEvents')
  }, [])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={eventTitle}
        description={description ? description : <Skeleton width={200} animation="wave" />}
        action={onReset}
        avatar={<Avatar size={44} style={avatarStyle} event={deviceEvent} />}
      />
      <ActionsManager view={view} setView={setView} event={deviceEvent} />
      <FormContent footer={<div />}>
        <SecondaryColumnContentContainer>
          <SecondaryColumnContentHorizontalRuler bottomSpace />
          <DeviceInfo unifiedVehicle={unifiedVehicle} />
          <EventTime timestamp={deviceEvent.ts} />
          <Speed speed={deviceEvent.speed} />
          <Heading heading={deviceEvent.heading} />
          <DriverBehavior event={deviceEvent} />
        </SecondaryColumnContentContainer>
      </FormContent>
    </FormColumn>
  )
}
