import { useMemo } from 'react'

import { NumericInputField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  metric: boolean
  currency: string
}

export function PerStopCost(props: Props) {
  const { metric, currency } = props
  const texts = useTexts()

  const wrappedInputProps = useMemo(() => {
    return {
      step: 10,
      useMetric: metric,
    }
  }, [metric])

  return (
    <NumericInputField
      name="perStopCost"
      testid="perStopCost"
      half
      label={texts.perStopCost(`(${currency}/stop)`)}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
