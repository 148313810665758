import { intl } from '@/intl'

export function validateDriverBehaviorsData(
  threshold: number,
  data: uui.domain.ui.forms.BaseBehaviorsData,
): string | undefined {
  const { min, max } = data

  const valid = !!threshold && isFinite(threshold) && min <= threshold && max >= threshold

  if (valid) return

  return intl.translate({
    id: 'vehicles.form.edit.generalSettings.validation.driverBehaviors.range',
    values: {
      min,
      max,
    },
  })
}
