import { useCallback, useState } from 'react'

import { resetCrudSelection } from '@/atoms'
import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'

import { ViewDetails } from '../../components/ViewDetails'

import { useBulkData } from './hooks/useBulkData'
import { useTexts } from './hooks/useTexts'
import { FormBulkAvatar } from './components/FormBulkAvatar'
import { ActionsManager } from './components/ActionsManager'

interface Props {
  regions: uui.domain.client.rm.Region[]
}

export function BulkView(props: Props) {
  const { regions } = props

  const texts = useTexts()

  const [view, setView] = useState<'default' | 'delete'>(() => 'default')

  const { enterCost, costMixed, enterTimeSec, timeMixed } = useBulkData(regions)

  const onReset = useCallback(() => {
    resetCrudSelection('regions')
  }, [])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.regions(regions.length)}
        description={texts.multipleRegions}
        action={onReset}
        avatar={<FormBulkAvatar size={regions.length} />}
      />
      <ActionsManager view={view} setView={setView} regions={regions} />
      <FormContent footer={<></>}>
        <ViewDetails
          enterCost={enterCost}
          enterCostMixed={costMixed}
          enterTimeSec={enterTimeSec}
          enterTimeMixed={timeMixed}
        />
      </FormContent>
    </FormColumn>
  )
}
