import { mapAtom } from '../../atoms/map/map'
import { findLayer } from '../utils/findLayer'

import { mapStylesUpdateBreadcrumbPointsFeatures } from './utils/breadcrumbPoints/mapStylesUpdateBreadcrumbPointsFeatures'

export const createUpdateBreadcrumbFeaturesMapStyleFromChangelog = (
  markers: uui.domain.ui.map.markers.MapMarkers['breadcrumb'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
  selection: Set<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) => {
  // ATTENTION: Application of Map Style changes will not be cached if a delayed rendering mechanism will be implemented
  // After the whole set of delayed marker changelogs is applied a full refresh of Map Styles will be required

  return async () => {
    const map = mapAtom.map

    // only the `update` field is populated in a mapStyles changelog
    await mapStylesUpdateBreadcrumbPointsFeatures(
      findLayer(map, 'breadcrumbPoints'),
      markers,
      mapStyles,
      selection,
      breadcrumbTimeRange,
    )
  }
}
