import styled from 'styled-components'

type Props = {
  size?: number
}

const BaseButton = styled.button<Props>`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 50%;
  height: ${p => p.size || 14}px;
  width: ${p => p.size || 14}px;

  background: ${p => p.theme.colors.$outrageousRed};
  color: ${p => p.theme.colors.$pureWhite};

  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
  outline: 0;
  transition: 0.3s ease;
  transition-property: background, color;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
    background: ${p => p.theme.colors.$silver};
    color: ${p => p.theme.colors.$pureWhite};
  }
`

BaseButton.displayName = 'BaseButton'
export default BaseButton
