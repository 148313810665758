import { useMemo } from 'react'

import { BreaksField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  timeFormat: uui.domain.client.TimeFormat
}

export function Breaks(props: Props) {
  const { timeFormat } = props

  const texts = useTexts()

  const wrappedInputProps = useMemo(() => {
    return {
      labelDuration: texts.breaksDuration,
      labelStart: texts.breaksStart,
      labelEnd: texts.breaksEnd,
      labelButton: texts.breaksAdd,
      timeFormat,
      max: 3,
    }
  }, [texts, timeFormat])

  return (
    <BreaksField
      name="breaks"
      testid="breaks"
      label={texts.breaks}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
