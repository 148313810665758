import { getDay } from 'date-fns/esm'

interface Props {
  index: number
  values: Date[]
  ghosted: boolean
  selected: boolean
  dayNumber: ReturnType<typeof getDay>
  dateFnsOptions: uui.domain.DateFnsOptions
  firstOfGhosted: boolean
  lastOfGhosted: boolean
}

/**
 * Computes the value of the rounded border for a single day on calendar
 */
export function getRoundedBorder(props: Props): 'left' | 'right' | 'none' {
  const {
    index,
    values,
    selected,
    ghosted,
    dayNumber,
    dateFnsOptions,
    firstOfGhosted,
    lastOfGhosted,
  } = props

  const lastOfRow = (dateFnsOptions.weekStartsOn + 6) % 7 === dayNumber
  const firstOfRow = dayNumber === dateFnsOptions.weekStartsOn
  const lastDayToRender = index === values.length - 1
  const firstDayToRender = index === 0

  return (selected || ghosted) && (firstOfRow || firstDayToRender || firstOfGhosted)
    ? 'left'
    : (selected || ghosted) && (lastOfRow || lastDayToRender || lastOfGhosted)
    ? 'right'
    : 'none'
}
