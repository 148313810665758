import { Box, Stack } from '@mui/material'
import { ModalFooterProps } from './types'
import { Root } from './components/Root'

export function ModalFooter(props: ModalFooterProps) {
  const {
    testId = 'ModalFooter',
    children = <div />,
    primaryAction,
    secondaryAction,
    tertiaryAction,
  } = props
  const trackId = props.trackId ?? testId

  return (
    <Root data-testid={testId} data-trackid={trackId}>
      <Box flex={1}>{children}</Box>
      <Stack flexShrink={0} gap={1} direction="row">
        <Box order={3}>{primaryAction}</Box>
        {Boolean(secondaryAction) && <Box order={1}>{secondaryAction}</Box>}
        {Boolean(tertiaryAction) && <Box order={2}>{tertiaryAction}</Box>}
      </Stack>
    </Root>
  )
}
