import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../renderingQueue'
import { getLayerMetadata, setLayerMetadata } from '../layerMetadata'

import { findFeature } from '../findFeature'

import { updateVehicleFeature } from './utils/updateVehicleFeature'
import { findVehicleMarker } from './utils/findVehicleMarker'

const nonblockingRender = createNonBlockingRenderByTime()

export const updateVehiclesMapSelection = async (
  layer: VectorLayer<VectorSource>,
  mapMarkers: uui.domain.ui.map.markers.MapMarkers['vehicle'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['vehicle'],
  selection: Set<string>,
) => {
  // retrieve selected items in the current layer
  const layerSelection = (getLayerMetadata(layer, 'selection') ?? new Set()) as Set<string>

  // if prev and next are empty do nothing
  if (selection.size !== 0 || layerSelection.size !== 0) {
    const vehiclesToDeselect = new Set(layerSelection)

    nonblockingRender.reset()

    for (const markerId of selection) {
      await nonblockingRender.next()

      // remove it from vehicleId to be deselected
      vehiclesToDeselect.delete(markerId)

      if (layerSelection.has(markerId)) {
        // vehicleId was previously selected and it's still selected
        // do nothing
        continue
      }

      const marker = findVehicleMarker(mapMarkers, markerId)

      // It is possible that a marker doesn't exists on the map since it can be possible that no live position is available
      if (!marker) continue

      const feature = findFeature(layer, marker.id)

      if (!feature) {
        throw new Error(
          `Trying to select Vehicle marker with id: ${markerId} but no feature exist on the map`,
        )
      }

      // the vehicleId is a new selection
      updateVehicleFeature(mapStyles, marker, feature, true)
    }

    nonblockingRender.reset()

    for (const markerId of vehiclesToDeselect) {
      await nonblockingRender.next()

      const marker = findVehicleMarker(mapMarkers, markerId)

      // It is possible that a marker doesn't exists on the map since it can be possible that no live position is available
      if (!marker) continue

      const feature = findFeature(layer, marker.id)

      if (!feature) {
        // fail silently in production when deselecting unknown items
        if (process.env.NODE_ENV === 'development') {
          console.warn(
            `Trying to deselect Vehicle marker with id: ${markerId} but no feature exist on the map`,
          )
        }

        continue
      }

      // deselect the vehicle
      updateVehicleFeature(mapStyles, marker, feature, false)
    }
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
