import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectRegions } from '@/features/domain/region'

export const useRegionsRoot = () => {
  const { editing } = useEditingStateWithCtx('region')
  const [selection] = useCrudSelection('regions')
  const regions = useSelector(selectRegions)
  const selectedRegions = useMemo(
    () => getSelectedRegions(selection, regions),
    [selection, regions],
  )

  return {
    regions,
    selectedRegions,
    editing,
    selection,
  }
}

const getSelectedRegions = (
  selection: workwave.DeepReadonly<string[]>,
  regionsMap: Record<string, uui.domain.client.rm.Region>,
): uui.domain.client.rm.Region[] => {
  // selection could hold some ids that are not in the regionsMap due to server-data computation
  // regionsMap holds the "truth"
  return selection.reduce<uui.domain.client.rm.Region[]>((acc, id) => {
    if (regionsMap[id]) {
      acc.push(regionsMap[id])
    }
    return acc
  }, [])
}
