import { useState, useEffect } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

interface ImportedTerritory {
  archived: uui.domain.server.rm.RoutePlan[]
  simulations: uui.domain.server.rm.RoutePlan[]
  operations: uui.domain.server.rm.RoutePlan

  territory: uui.domain.server.rm.BaseTerritory
}

type ModalStatus = 'ready' | 'failed'
interface ModalData {
  name: string
  fileName?: string
  lastModifiedDate?: string
  importedTerritory?: ImportedTerritory
}

export const modalId = 'importNewTerritory'
export const useController = () => useModalController<ModalData, ModalStatus>(modalId)

export const useConfigureController = () => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      invalid: true,
      data: { name: '' },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalStatus>(modalId, options)
  const {
    update,
    data: { name, importedTerritory },
  } = ctrl

  // EFFECTS

  // Invalid state
  useEffect(() => {
    update({ invalid: !name?.trim() || !importedTerritory })
  }, [name, importedTerritory, update])

  return ctrl
}
