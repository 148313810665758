import type { ChangeEvent } from 'react'

import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { deleteOrderSteps } from '@/features/domain/order'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export interface ModalHandlers {
  onCancel: () => void
  onDeleteOrder: () => void
}

export function useControllerActions() {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const {
    close,
    updateData,
    data: { selectedOrders, pairedOrders, deleteAlsoPaired },
  } = useController()

  const onDeleteOrder = useCallback(async () => {
    try {
      close?.()
      const idsToDelete = selectedOrders.map(order => order.orderStep.id)

      if (deleteAlsoPaired && !!pairedOrders && pairedOrders.length > 0)
        idsToDelete.push(...pairedOrders.map(order => order.id))

      await dispatch(deleteOrderSteps(idsToDelete))
    } catch (e) {
      toast.error(texts.genericDeleteOrderError)
    }
  }, [pairedOrders, selectedOrders, deleteAlsoPaired, texts, toast, close, dispatch])

  const onDeleteAlsoPairedChange = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) =>
      updateData({ deleteAlsoPaired: checked }),
    [updateData],
  )

  return { onDeleteOrder, onDeleteAlsoPairedChange }
}
