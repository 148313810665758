import type { CourierFilterError, FormFields } from './types'

import { CourierFilterField } from './components/CourierFilterField'

interface Props {
  territoriesData: uui.domain.client.rm.TerritoriesDriversAndVehicles
  territoryNames: Record<string, string>
}

export function CourierFilterFieldRoot(props: Props) {
  const { territoriesData, territoryNames } = props

  return (
    <CourierFilterField<'courierFilter', FormFields, CourierFilterError>
      name="courierFilter"
      territoriesConfig={territoriesData}
      territoryNames={territoryNames}
    />
  )
}
