import { useEffect } from 'react'

import { useFormTransaction } from '@/hooks'
import { fitMapToSelection } from '@/map'
import { useNewPlaceValue } from './hooks/useNewPlaceValue'
import { SingleEdit } from './SingleEdit'

interface Props {
  place?: uui.domain.client.gps.wwgps.Place
}

export function SingleEditProvider(props: Props) {
  const { place } = props

  const newValue = useNewPlaceValue()

  const transactionState = useFormTransaction()
  const loading = transactionState === 'loading'

  useEffect(() => {
    // try to select it right away and
    // queue a pending fitMap request
    // it will be applied by the destination view when it opens
    fitMapToSelection({ preventIfVisible: true }, 'add')
  }, [])

  if (!place) {
    return <SingleEdit place={newValue} loading={loading} creating />
  }

  return <SingleEdit place={place} loading={loading} />
}
