import { PureComponent } from 'react'

import { BarcodesField } from '@/forms-legacy'
import * as OrderTexts from '../../../../../../intl'

import { Props } from '../typings'

export default class Barcodes extends PureComponent<Props> {
  render() {
    const { orderStepType, orderStep, maxBarcodes } = this.props

    const podsBarcodes = orderStep.trackingData?.pods?.barcodes || []

    return (
      <BarcodesField
        name={`${orderStepType}.barcodes`}
        label={OrderTexts.getBarcodesFieldText()}
        wrappedInputProps={{
          maxBarcodes,
          labelButton: OrderTexts.getBarcodesAddNewText(),
          podsBarcodes,
          readonlyReason: OrderTexts.getReadonlyReasonText(),
          addButtonClassName: `pendo-order__${orderStepType}-add-barcodes-button`,
          addButtonDisabledTitle: OrderTexts.getAddBarcodesButtonDisabledText(),
        }}
        testid="barcodes"
      />
    )
  }
}
