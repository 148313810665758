import type { TwoDaysNotificationValues } from './hooks/useTwoDaysNotificationValues'

export function convertValues(formValues: TwoDaysNotificationValues) {
  // Convert windowOfCommitmentToleranceMins from string to number
  const windowOfCommitmentToleranceMins = parseInt(formValues.windowOfCommitmentToleranceMins)

  return {
    ...formValues,
    windowOfCommitmentToleranceMins,
  }
}
