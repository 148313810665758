import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { setMainSelection } from '@/atoms'
import { selectVehiclesByVehicleId } from '@/features/domain/vehicle'

export function useSingleActions(vehicleId: string, driverId?: string) {
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)

  const selectVehicle = useCallback(() => {
    setMainSelection('unifiedVehicles', [vehiclesByVehicleId[vehicleId]])
  }, [vehiclesByVehicleId, vehicleId])

  const selectDriver = useCallback(() => {
    if (!driverId) return

    setMainSelection('drivers', [driverId])
  }, [driverId])

  return { selectVehicle, selectDriver }
}
