import type { RenderItemProps } from '@/components/StructuredVirtualList'

import { memo, useCallback } from 'react'
import { DirectionsCar, Notifications } from '@mui/icons-material'

import {
  ListItem,
  ListItemAvatarRounded,
  ListItemContent,
  ListItemRow,
  ListItemTitle,
} from '@/components/List'

import { useCreateAlertLabel } from '../../hooks/useCreateAlertLabel'

import { useTexts } from './hooks/useTexts'
import { Avatar } from './components/Avatar'

type Props = Extract<RenderItemProps<'eventAlerts'>, { type: 'item' }>

export const AlertItem = memo((props: Props) => {
  const { selected, item, onItemClick, onItemDoubleClick } = props

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])
  const createAlertLabel = useCreateAlertLabel()
  const texts = useTexts()

  const { eventTypeId, name, deviceIds } = item

  return (
    <ListItem
      onDoubleClick={onDoubleClick}
      selected={selected}
      onClick={onClick}
      testId="alert-list-item"
      avatar={
        <ListItemAvatarRounded color="transparent">
          <Avatar eventTypeId={eventTypeId} size={29} />
        </ListItemAvatarRounded>
      }
    >
      <ListItemTitle>{name}</ListItemTitle>

      <ListItemRow testId="alert-type-row">
        <ListItemContent icon={<Notifications />}>
          {createAlertLabel(item.eventTypeId)}
        </ListItemContent>
      </ListItemRow>

      <ListItemRow testId="alert-running-vehicles-row">
        <ListItemContent icon={<DirectionsCar />}>
          {texts.runningOn(deviceIds.length)}
        </ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})
AlertItem.displayName = 'AlertItem'
