import type { ReactNode, ButtonHTMLAttributes, DetailedHTMLProps, ReactElement } from 'react'

import { LoadingButton } from '@mui/lab'

type Button = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

type Props = {
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  variant?: 'text' | 'outlined' | 'contained'
  minWidth?: string | number
  maxWidth?: string | number
  type?: Button['type']
  onClick?: () => void
  children?: ReactNode
  Icon?: ReactElement
  disabled?: boolean
  className?: string
  iconSize?: number
  loading?: boolean
  testid?: string
  trackid?: string
  title?: string
}

export function ModalFooterButton(props: Props) {
  const {
    color = 'primary',
    loading = false,
    className,
    disabled,
    children,
    onClick,
    variant,
    testid,
    trackid,
    title,
  } = props

  if (!onClick && process.env.NODE_ENV === 'development')
    console.warn('ModalFooterButton without an onClick callback')

  return (
    <LoadingButton
      className={className}
      data-trackid={trackid ?? testid}
      data-testid={testid}
      disabled={disabled}
      loading={loading}
      variant={variant}
      onClick={onClick}
      color={color}
      title={title}
    >
      {children}
    </LoadingButton>
  )
}
