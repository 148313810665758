import type { GridApi, GridColDef } from '@mui/x-data-grid-pro'
import { useMemo, useState, useCallback, MutableRefObject } from 'react'
import {
  Paper,
  Button,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Check } from '@mui/icons-material'
import { GridPreferencePanelsValue } from '@mui/x-data-grid-pro'
import { type ColumnData, type CollectedColumns, ColumnManager } from '@/components/ColumnManager'
import { naturalSort } from '@/server-data'
import { useTexts } from '../hooks/useTexts'

interface ColumnManagerPanelProps {
  data: {
    currentColumns: GridColDef<any, any, any>[]
    sortMethod: 'grid-order' | 'category'
    searchValue: string
  }
  actions: {
    toggleColumn: (id: string, visibility: boolean) => void
    setSortMethod: (event: 'category' | 'grid-order') => void
    hideAllColumns: () => void
    showAllColumns: () => void
    resetColumns: () => void
    handleSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  }
  apiRef: MutableRefObject<GridApi>
}

function getDefaultCollectedColumns(): CollectedColumns {
  return {
    notifications: [],
    customFields: [],
    generic: [],
    loads: [],
  }
}

export function ColumnManagerPanel(props: ColumnManagerPanelProps) {
  const { data, actions, apiRef } = props
  const [sortByMenuAnchorEl, setSortByMenuAnchorEl] = useState<null | HTMLElement>(null)
  const texts = useTexts()
  const cordinates = apiRef?.current?.rootElementRef?.current?.getBoundingClientRect()
  const isColumnManagerOpen = useMemo(() => {
    const preferencePanel = apiRef?.current?.state?.preferencePanel
    if (!preferencePanel) return false

    return (
      preferencePanel.open && preferencePanel.openedPanelValue === GridPreferencePanelsValue.columns
    )
  }, [apiRef])
  const isSortByModalOpen = Boolean(sortByMenuAnchorEl)
  const allColumnsVisible = apiRef?.current?.getAllColumns().every(column => !column.hide)

  const getCategorizedColumns = useCallback(() => {
    const collectedColumns = data.currentColumns.reduce<CollectedColumns>((acc, column) => {
      const { field } = column
      const record = {
        id: column.field,
        label: column.headerName,
        visible: !column.hide,
      }

      if (
        field.startsWith('formattedData.loads') ||
        field.startsWith('formattedData.executedLoads')
      ) {
        acc.loads.push(record)
        return acc
      }

      if (
        field.startsWith('formattedData.customFields') ||
        field.startsWith('formattedData.executedCustomFields')
      ) {
        acc.customFields.push(record)
        return acc
      }

      if (field.startsWith('formattedData.communicatedEtaRanges')) {
        acc.notifications.push(record)
        return acc
      }

      acc.generic.push(record)
      return acc
    }, getDefaultCollectedColumns())

    collectedColumns.customFields = collectedColumns.customFields.sort((a, b) =>
      naturalSort(a.label ?? '', b.label ?? ''),
    )

    collectedColumns.generic = collectedColumns.generic.sort((a, b) =>
      naturalSort(a.label ?? '', b.label ?? ''),
    )

    collectedColumns.loads = collectedColumns.loads.sort((a, b) =>
      naturalSort(a.label ?? '', b.label ?? ''),
    )

    collectedColumns.notifications = collectedColumns.notifications.sort((a, b) =>
      naturalSort(a.label ?? '', b.label ?? ''),
    )

    return collectedColumns
  }, [data.currentColumns])

  const columns = useMemo((): ColumnData[] | CollectedColumns | null => {
    if (!data?.currentColumns || !data?.sortMethod) return null

    if (data.sortMethod === 'category') {
      const categorizedColumns = getCategorizedColumns()
      return categorizedColumns
    } else {
      const currentColumns: GridColDef<any, any, any>[] = data.currentColumns
      const managedColumns = currentColumns.reduce((columnsList: ColumnData[], column) => {
        const record: ColumnData = {
          id: column.field,
          label: column.headerName,
          visible: !column.hide,
        }
        columnsList.push(record)
        return columnsList
      }, [])

      return managedColumns
    }
  }, [data, getCategorizedColumns])

  const hideSortByMenu = useCallback(() => setSortByMenuAnchorEl(null), [])

  const showSortByMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSortByMenuAnchorEl(event.currentTarget)
  }

  const setColumnsSortByGridOrder = useCallback(() => {
    actions.setSortMethod('grid-order')
  }, [actions])

  const setColumnsSortByCategory = useCallback(() => {
    actions.setSortMethod('category')
  }, [actions])

  const closeColumnsPanel = useCallback(() => {
    apiRef?.current?.hidePreferences()
  }, [apiRef])

  const onColumnVisibilityChange = useCallback(
    (id, visibility) => {
      actions.toggleColumn(id, visibility)
    },
    [actions],
  )

  const hideAllColumns = useCallback(() => {
    actions.hideAllColumns()
  }, [actions])

  const showAllColumns = useCallback(() => {
    actions.showAllColumns()
  }, [actions])

  const resetColumns = useCallback(() => {
    actions.resetColumns()
  }, [actions])

  const handleSearchValueChange = useCallback(
    event => {
      actions.handleSearchValueChange(event)
    },
    [actions],
  )

  const slots = {
    header: {
      leftButton: allColumnsVisible ? (
        <Button
          onClick={hideAllColumns}
          color="primary"
          data-testid="orders-grid-columns-panel-hide-button"
          data-trackid="orders-grid-columns-panel-hide-button"
        >
          {apiRef.current.getLocaleText('columnsPanelHideAllButton')}
        </Button>
      ) : (
        <Button
          onClick={showAllColumns}
          color="primary"
          data-testid="orders-grid-columns-panel-show-button"
          data-trackid="orders-grid-columns-panel-show-button"
        >
          {apiRef.current.getLocaleText('columnsPanelShowAllButton')}
        </Button>
      ),
      rightButton: (
        <Button
          color="primary"
          onClick={showSortByMenu}
          data-testid="orders-grid-columns-panel-show-button"
          data-trackid="orders-grid-columns-panel-show-button"
        >
          {texts.sortBy}
        </Button>
      ),
    },
    footer: {
      leftButton: (
        <Button
          onClick={resetColumns}
          color="primary"
          data-testid="orders-grid-columns-panel-reset-button"
          data-trackid="orders-grid-columns-panel-reset-button"
        >
          {texts.reset}
        </Button>
      ),
      rightButton: (
        <Button
          onClick={closeColumnsPanel}
          color="primary"
          data-testid="orders-grid-columns-panel-close-button"
          data-trackid="orders-grid-columns-panel-close-button"
        >
          {texts.done}
        </Button>
      ),
    },
  }

  return (
    <>
      <ColumnManager
        open={isColumnManagerOpen}
        close={closeColumnsPanel}
        columns={columns}
        slots={slots}
        onColumnVisibilityChange={onColumnVisibilityChange}
        position={{
          top: cordinates?.y ? cordinates.y + 32 : 0,
          left: cordinates?.x ? cordinates.x + 32 : 0,
        }}
        maxHeight={apiRef?.current?.rootElementRef?.current?.offsetHeight || 500}
        filter={data.searchValue}
        onFilterChange={handleSearchValueChange}
      />
      <Popover
        open={isSortByModalOpen}
        onClose={hideSortByMenu}
        anchorEl={sortByMenuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper elevation={3}>
          <MenuList>
            <MenuItem onClick={setColumnsSortByCategory}>
              <ListItemIcon sx={{ opacity: data.sortMethod === 'category' ? 1 : 0 }}>
                <Check fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>{texts.sortByCategory}</ListItemText>
            </MenuItem>
            <MenuItem onClick={setColumnsSortByGridOrder}>
              <ListItemIcon sx={{ opacity: data.sortMethod === 'grid-order' ? 1 : 0 }}>
                <Check fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>{texts.sortByGridOrder}</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </>
  )
}
