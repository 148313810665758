import type { FormValidator } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../../formFields'
import type { Texts } from '../useTexts'

export const createValidateTerritoryFilter =
  (texts: Texts): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const field = formApi.getField('territoryFilter')
    const type = formApi.getField('type')
    const limitedAccess = formApi.getField('limitedAccess')

    const hasError = limitedAccess.value && field.value.length === 0 && type.value !== 'courier'

    const territoryFilterRequiredError: FormErrors = {
      id: 'territoryFilterRequiredError',
      message: texts.errors.territoryFilter,
      field: 'territoryFilter' as const,
      priority: 10,
    }

    return {
      [territoryFilterRequiredError.id]: hasError ? territoryFilterRequiredError : null,
    }
  }
