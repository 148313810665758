import { Entry } from '../Entry'

export function Entry20210312() {
  return (
    <Entry title="03/12/2021 - v3.0.0-alpha-9">
      <ul>
        <li>The Scheduler updates when the app receives a changeset</li>
        <li>Changing the calendar range faster (up to 80%)</li>
        <li>New marker stats are available in the testers panel</li>
        <li>InspectPin and Lasso are mutually exclusive</li>
        <li>The execution events form works</li>
        <li>Exporting orders works</li>
        <li>Unassigning orders works</li>
        <li>Text searching on lists with big volumes works</li>
        <li>Map internal controls are not hidden by the Navigo anymore</li>
        <li>The sidebar menu toggle is disabled when the sidebar is not available</li>
      </ul>
    </Entry>
  )
}
