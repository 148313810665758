import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    name: translate({ id: 'settings.rmAccount.edit.fields.name.label' }),
    email: translate({ id: 'settings.rmAccount.edit.fields.email.label' }),
    surname: translate({ id: 'settings.rmAccount.edit.fields.surname.label' }),
    username: translate({ id: 'settings.rmAccount.edit.fields.username.label' }),
  }))

  return api
}
