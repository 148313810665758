import type { ValidColor } from '@/local/components'
import { MouseEvent, Component, ReactNode, ReactElement } from 'react'
import classnames from 'classnames'

import Badge, { Props as BadgeProps } from '../Badge'

export interface Props {
  name: string
  callback: () => void
  Icon: ReactElement
  label: string
  className?: string
  disabled?: boolean
  iconColor?: ValidColor
  useBigIcon?: boolean
  badge?: BadgeProps
  selected?: boolean
  renderAsDownloadableLink?: boolean
  href?: string
}

export default class ActionButton extends Component<Props> {
  handleOnClick = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    const { callback } = this.props
    callback()
  }

  renderBadge = (): ReactNode => {
    const { badge } = this.props
    if (!badge) return null
    const { text, className } = badge

    return (
      <div className="o-action-button__badge">
        <Badge className={className} text={text} tiny />
      </div>
    )
  }

  renderInner = (): ReactNode => {
    const { Icon, label, useBigIcon = false } = this.props

    const iconContainerClassName = classnames({
      'o-action-button__ico': true,
      'o-action-button__ico--big': useBigIcon,
    })

    return (
      <>
        <span className={iconContainerClassName}>{Icon}</span>
        <span className="o-action-button__label">{label}</span>
      </>
    )
  }

  renderAsButton = (): ReactNode => {
    const { disabled = false } = this.props

    return (
      <button className="o-action-button__button" onClick={this.handleOnClick} disabled={disabled}>
        {this.renderInner()}
      </button>
    )
  }

  renderAsLink = (): ReactNode => {
    const { href = '' } = this.props

    return (
      <a
        className="o-action-button__button"
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        download
      >
        {this.renderInner()}
      </a>
    )
  }

  render() {
    const {
      className = '',
      selected = false,
      renderAsDownloadableLink = false,
      href = '',
    } = this.props

    const rootClassName = classnames({
      [className]: true,
      'o-action-button': true,
      'is-selected': selected,
    })

    const renderAsLink = renderAsDownloadableLink && href.length > 0
    const renderer: () => ReactNode = renderAsLink ? this.renderAsLink : this.renderAsButton

    return (
      <div className={rootClassName}>
        {renderer()}
        {this.renderBadge()}
      </div>
    )
  }
}
