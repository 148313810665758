import { Divider, Stack } from '@mui/material'

import { useAppLanguage } from '@/atoms'

import { useController } from '../../../../hooks/useController'

import { ExportFilePicker } from './components/ExportFilePicker'
import { CustomizeColumns } from './components/ConfigureColumns'
import { FormatPicker } from './components/FormatPicker'
import { FilterPicker } from './components/FilterPicker'

import { Advanced } from './components/Advanced'

export function Body() {
  const {
    data: { isSimulation, territoryIds },
  } = useController()
  const [{ language }] = useAppLanguage()

  const isMultiTerritoryExport = territoryIds.length > 1

  return (
    <Stack height="100%" overflow="auto" padding={4} gap={4}>
      {isSimulation ? null : (
        <>
          <FilterPicker />
          <Divider />
        </>
      )}
      <FormatPicker />
      {isMultiTerritoryExport && <ExportFilePicker />}
      <Divider />
      <Stack gap={2}>
        <CustomizeColumns />
        {language !== 'en' && <Advanced />}
      </Stack>
    </Stack>
  )
}
