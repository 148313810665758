import { useMemo } from 'react'

export function useApprovedRoutesCount(
  routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[],
) {
  return useMemo(
    () => routeInfos.filter(routeInfo => !!routeInfo.approvedVersion).length,
    [routeInfos],
  )
}
