import { useCallback } from 'react'
import { FormHeader } from '@workwave-tidal/tidal/form-ui/layout'
import {
  ViewActionButton,
  ViewActions,
  ViewContainer,
  ViewContent,
  ViewField,
  ViewFieldsLayout,
} from '@/formUi'
import { useVoiceData } from '../../hooks/useVoiceData'
import { useCopyVoiceConfigModal } from '../../modals/CopyVoiceConfigModal'
import { useTexts } from './hooks/useTexts'
import { AdvancedProperties } from './AdvancedProperties'

interface Props {
  onEdit: () => void
}

export function VoiceView(props: Props) {
  const { onEdit } = props
  const [voice] = useVoiceData()
  const texts = useTexts()
  const { show: showCopyVoiceConfigModal, Modal: CopyVoiceConfigModal } = useCopyVoiceConfigModal()
  const isAdvanced = voice?.status === 'ADVANCED'
  const status = texts.value.status[voice?.status?.toLowerCase()] || 'OFF'

  const handleCopyClick = useCallback(() => {
    showCopyVoiceConfigModal()
  }, [showCopyVoiceConfigModal])

  return (
    <ViewContainer>
      <ViewContent>
        <FormHeader title={texts.title} subtitle={texts.subtitle} />

        <ViewFieldsLayout>
          <ViewActions>
            <ViewActionButton label={texts.actions.edit} onClick={onEdit} />

            <ViewActionButton onClick={handleCopyClick} label={texts.actions.copy} />
          </ViewActions>

          <ViewField label={texts.labels.status}>{status}</ViewField>
        </ViewFieldsLayout>

        {isAdvanced && <AdvancedProperties voice={voice} />}

        <CopyVoiceConfigModal />
      </ViewContent>
    </ViewContainer>
  )
}
