import { useCallback } from 'react'
import { useChangePasswordModal } from '../../modals/ChangePasswordModal'

export function useOnChangePassword() {
  const { show: showChangePasswordModal } = useChangePasswordModal()

  return useCallback(() => {
    showChangePasswordModal()
  }, [showChangePasswordModal])
}
