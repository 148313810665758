import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import { Scrollbar } from '@workwave-tidal/tidal/components'

import { isGpsOnly } from '@/features/domain/user'
import { Sidebar } from '@/components/Sidebar'
import { H1 } from '@/local/components'

import { GpsSidebar } from './components/GpsSidebar'
import { Container } from './components/Container'
import { RmSidebar } from './components/RmSidebar'

import { useTexts } from './useTexts'

export function SettingsSidebar() {
  const gpsOnly = useSelector(isGpsOnly)
  const texts = useTexts()

  return (
    <Container elevation={4} data-testid="settings__sidebar">
      <Sidebar>
        <Scrollbar>
          <Stack padding="0 16px 24px" spacing={2}>
            <H1 style={{ margin: '8px 0' }}>{texts.settingsTitle}</H1>

            {gpsOnly ? <GpsSidebar /> : <RmSidebar />}
          </Stack>
        </Scrollbar>
      </Sidebar>
    </Container>
  )
}
