import { useFormatDateTime } from '@/hooks'

interface Props {
  source: Date
  excludeTime?: boolean
}

export function FormatDateTime(props: Props) {
  const { source, excludeTime } = props
  const value = useFormatDateTime(source, excludeTime)

  return <>{value}</>
}
