import { useSelector } from 'react-redux'
import { Paper, Stack, styled } from '@mui/material'
import { Scrollbar } from '@workwave-tidal/tidal/components'

import { H1 } from '@/local/components'
import { Sidebar } from '@/components/Sidebar'
import { selectUserConfiguration } from '@/features/domain/user'

import { useTexts } from './useTexts'
import { GpsSetupSidebar } from './components/GpsSetupSidebar'
import { RmSetupSidebar } from './components/RmSetupSidebar'

const SidebarContainer = styled(Paper, { name: 'SidebarContainer' })({
  height: '100%',
  zIndex: 10,
})

export function SetupSidebar() {
  const { userType } = useSelector(selectUserConfiguration)
  const texts = useTexts()

  return (
    <SidebarContainer elevation={4} data-testid="setup__sidebar">
      <Sidebar>
        <Scrollbar>
          <Stack padding="0 16px 24px" spacing={2}>
            <H1 style={{ margin: '8px 0' }}>{texts.setup}</H1>

            {userType === 'gpsOnly' ? <GpsSetupSidebar /> : <RmSetupSidebar />}
          </Stack>
        </Scrollbar>
      </Sidebar>
    </SidebarContainer>
  )
}
