import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectCalendarRange, setCustomMapStyle } from '@/features/domain/ui'
import { selectLivePositions } from '@/features/domain/device'
import { selectDrivers } from '@/features/domain/driver'

import { setCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { useGpsReportsLink, useNavigate } from '@/routing'
import { fitMapToSelection, fitMap } from '@/map'
import { getDeviceId } from '@/server-data'
import { useAppDispatch } from '@/store'

export function useActions(
  vehicles: uui.domain.client.UnifiedVehicle[],
  breadcrumbMapMode: uui.domain.ui.map.markers.MapMode,
  eventsCount: number,
) {
  const navigateMainSections = useNavigate<uui.routing.MainSections>(true)
  const { gpsReportsLink, createGpsReportsCookie } = useGpsReportsLink()
  const { setEditing } = useEditingStateWithCtx('vehicle')
  const navigateSetup = useNavigate<uui.routing.Setup>(true)
  const livePositions = useSelector(selectLivePositions)
  const calendarRange = useSelector(selectCalendarRange)
  const dispatch = useAppDispatch()
  const drivers = useSelector(selectDrivers)

  // -------------------------------------------------------
  // Events
  // -------------------------------------------------------
  const goToEvents = useCallback(() => {
    const ids = vehicles.reduce<string[]>((acc, vehicle) => {
      const deviceId = getDeviceId(drivers, vehicle, calendarRange.minDate)
      if (deviceId) {
        acc.push(deviceId)
      }
      return acc
    }, [])

    // if eventsCount is 0 the marker does not exist
    // we prevent an error
    if (eventsCount > 0) {
      dispatch(
        setCustomMapStyle({
          customStyle: {
            ids,
            type: 'deviceEvent',
            mode: 'on',
          },
        }),
      )
    }
    // Navigate to the correct route
    navigateMainSections('events')
  }, [navigateMainSections, calendarRange, drivers, dispatch, vehicles, eventsCount])

  // -------------------------------------------------------
  // Toggle breadcrumbs
  // -------------------------------------------------------
  const toggleBreadcrumbs = useCallback(() => {
    if (calendarRange.minDate !== calendarRange.maxDate) {
      throw new Error(
        'It is not possible to toggle breadcrumbs for multiple days. THIS SHOULD NOT HAPPEN',
      )
    }

    // If there are (0 || > 1) selected vehicles we cannot toggle breadcrumbs
    if (vehicles.length !== 1) return

    const vehicle = vehicles[0]
    const deviceId = getDeviceId(drivers, vehicle, calendarRange.minDate)

    if (!deviceId) return

    dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: {
          type: 'breadcrumb',
          mode: breadcrumbMapMode === 'on' ? 'off' : 'on',
          ids: [deviceId],
        },
      }),
    )
  }, [dispatch, calendarRange, breadcrumbMapMode, vehicles, drivers])

  // -------------------------------------------------------
  // Center on Map
  // -------------------------------------------------------
  const centerOnMap = useCallback(() => {
    const latLngCoordinates = vehicles.reduce<uui.domain.LatLng[]>((acc, vehicle) => {
      const deviceId = getDeviceId(drivers, vehicle, calendarRange.minDate)

      if (!deviceId) return acc

      const lastPosition = livePositions[deviceId]

      if (!lastPosition) return acc

      acc.push(lastPosition.latLng)

      return acc
    }, [])

    fitMap(latLngCoordinates)
  }, [calendarRange.minDate, drivers, vehicles, livePositions])

  // -------------------------------------------------------
  // Edit
  // -------------------------------------------------------
  const goToEdit = useCallback(() => {
    const currentVehicleIds = vehicles.map(vehicle => vehicle.unifiedId)
    setCrudSelection('unifiedVehicles', currentVehicleIds)

    fitMapToSelection({ preventIfVisible: false }, 'add')

    setEditing(currentVehicleIds)

    navigateSetup('vehicles')
  }, [navigateSetup, setEditing, vehicles])

  // -------------------------------------------------------
  // Reports
  // -------------------------------------------------------
  const runReports = useCallback(() => {
    const deviceIds = vehicles.reduce<number[]>((acc, uv) => {
      if (!uv.isPhysicalDevice) return acc

      acc.push(parseInt(uv.device.deviceId))

      return acc
    }, [])

    createGpsReportsCookie(deviceIds)

    globalThis.open(gpsReportsLink, '_blank')
  }, [gpsReportsLink, createGpsReportsCookie, vehicles])

  return { toggleBreadcrumbs, goToEvents, centerOnMap, goToEdit, runReports }
}
