/**
 * Retrieve a RoutePolyline Marker by its ID or throws an Error if it cannot be found
 */
export function findRoutePolylineMarker(
  markers: Record<string, uui.domain.ui.map.markers.RoutePolyline>,
  routePolylineId: string,
) {
  const marker = markers[routePolylineId]

  if (!marker) {
    throw new Error(`Unknown RoutePolyline Map Marker with ID: ${routePolylineId}`)
  }

  return marker
}
