import type { RouteDetailEntryStart } from '../../../types'

interface Params {
  driverId: string
  routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable
  driverName: string
  shiftStart?: uui.domain.server.rm.TrackingDataFix
  firstDriverId: string
  departureDepot: string
  routeTrackingData: uui.domain.server.rm.RouteTrackingDataRegistryEntry['routeTrackingData']
  mobileAppsTrackingType: 'ONLY_ORDERSTEPS' | 'ORDERSTEPS_AND_BOUND'
  startsBeforeWorkingDayStart: boolean
}

function getEntryType(
  mobileAppsTrackingType: 'ONLY_ORDERSTEPS' | 'ORDERSTEPS_AND_BOUND',
  driverId: string,
  firstDriverId: string,
  routeTrackingData: uui.domain.server.rm.RouteTrackingDataRegistryEntry['routeTrackingData'],
  shiftStart?: uui.domain.server.rm.TrackingDataFix,
) {
  if (mobileAppsTrackingType === 'ONLY_ORDERSTEPS') {
    if (driverId === firstDriverId) {
      return 'routeStart'
    }

    return 'driverStart'
  }

  if (!routeTrackingData || !shiftStart) {
    return firstDriverId === driverId ? 'routeStart' : 'driverStart'
  }

  return routeTrackingData.startData.sec === shiftStart.sec ? 'routeStart' : 'driverStart'
}

export function computeEntryStartData({
  driverId,
  routeInfo,
  driverName,
  shiftStart,
  firstDriverId,
  departureDepot,
  routeTrackingData,
  mobileAppsTrackingType,
  startsBeforeWorkingDayStart,
}: Params): RouteDetailEntryStart | undefined {
  // Compute start type
  const type = getEntryType(
    mobileAppsTrackingType,
    driverId,
    firstDriverId,
    routeTrackingData,
    shiftStart,
  )

  // Compute planned time
  const planned = {
    sec: routeInfo.approvedVersion?.routeDepartureSec ?? -1,
    location: departureDepot,
  }

  // Compute tracked time
  const tracked = shiftStart ? { sec: shiftStart.sec, location: shiftStart.latLng } : { sec: -1 }

  switch (type) {
    case 'driverStart':
      if (tracked.sec === -1 && driverName === '-') {
        return
      }

      return { type, data: tracked }

    case 'routeStart':
      if (planned.sec === -1 && tracked.sec === -1 && driverName === '-') {
        return
      }
      return { type, data: { planned, tracked, startsBeforeWorkingDayStart } }
  }
}
