import type { MutableRefObject } from 'react'
import type { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { OrdersGridDropdown } from './components/OrdersGridDropdown'
import { TopBarContainer } from './components/TopBarContainer'
import SearchOrdersBar from './components/SearchOrdersBar'
interface Props {
  orderIds: string[]
  apiRef: MutableRefObject<GridApiPro>
}

export function TopBar(props: Props) {
  const { orderIds, apiRef } = props

  return (
    <TopBarContainer data-testid="OrdersGrid-header">
      <OrdersGridDropdown orderIds={orderIds} apiRef={apiRef} />
      <SearchOrdersBar />
    </TopBarContainer>
  )
}
