import type { SelectionOptions } from '../../utils/applySelection'
import type { CrudSelection } from '../typings'

import { snapshot } from 'valtio'

import { applySelection } from '../../utils/applySelection'
import { crudAtom } from '../crud'
import { resetCrudSelection } from './resetCrudSelection'

type SetCrudSelection<Category extends keyof CrudSelection> = (
  prev: workwave.DeepReadonly<CrudSelection[Category]>,
) => CrudSelection[Category]

export type SetCrudSelectionParam<Category extends keyof CrudSelection> =
  | SetCrudSelection<Category>
  | CrudSelection[Category]
  | 'reset'

export const setCrudSelection = <Category extends keyof CrudSelection = keyof CrudSelection>(
  category: Category,
  valueOrFunc: SetCrudSelectionParam<Category>,
  options?: SelectionOptions,
) => {
  // reset
  if (valueOrFunc === 'reset') return resetCrudSelection(category)

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    crudAtom.selection[category] = valueOrFunc(snapshot(crudAtom.selection[category]))
  } else {
    const ids = valueOrFunc

    // TODO: drop this behavior favoring only 'reset'? Or do we want to keep both possibilities?
    // RESET the target Selection's atom and exit!
    if (ids.length === 0) {
      return resetCrudSelection(category)
    }

    const prevSelection = crudAtom.selection[category]

    // ATTENTION: CRUD only
    // Reports currently support only Single Selection
    const nextSelection = category === 'reports' ? ids.slice(0, 1) : ids

    crudAtom.selection[category] = applySelection(
      prevSelection,
      nextSelection,
      options,
    ) as CrudSelection[Category]
  }

  return crudAtom.selection[category]
}
