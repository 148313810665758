import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    cancelButtonTitle: translate({ id: 'demoTools.timeShiftPlan.modal.cancelButton.title' }),
    commandButtonTitle: translate({ id: 'demoTools.timeShiftPlan.modal.commandButton.title' }),
    genericError: translate({ id: 'demoTools.timeShiftPlan.error.generic' }),
    modalTitle: translate({ id: 'demoTools.timeShiftPlan.modal.title' }),
    modalSubTitle: translate({ id: 'demoTools.timeShiftPlan.modal.subtitle' }),
    warningCardTitle: translate({ id: 'demoTools.timeShiftPlan.modal.warningCard.title' }),
    warningCardDescription: translate({
      id: 'demoTools.timeShiftPlan.modal.warningCard.description',
    }),
    transactionWarningCardTitle: translate({
      id: 'demoTools.timeShiftPlan.modal.transaction.warningCard.title',
    }),
    transactionWarningCardDescription: translate({
      id: 'demoTools.timeShiftPlan.modal.transaction.warningCard.description',
    }),
  }))

  return api
}
