import { MapStyle } from '@/icons'

import { Stats } from '../../components/Stats'
import { CloseButton } from '../../../../elements/CloseButton'
import { NavigoIconButton } from '../../../../elements/NavigoIconButton'

import { useNavigoView } from '../../hooks/useNavigoView'
import { useOrderStepsNavigator } from '../../hooks/useOrderStepsNavigator'
import { useTexts } from './useTexts'
import { Actions } from './components/Actions'

export function Overview() {
  const texts = useTexts()
  const { currentOrders } = useOrderStepsNavigator()
  const {
    navigate: { goToMapStyle },
  } = useNavigoView()

  return (
    <>
      <Stats />
      <Actions />
      <NavigoIconButton
        text={currentOrders.length > 1 ? texts.styleAll : texts.style}
        Icon={<MapStyle size={20} color="$nightRider" />}
        onClick={goToMapStyle}
        testid="navigo-order-map-style-button"
      />
      <CloseButton />
    </>
  )
}
