import { Typography, TextField, Stack } from '@mui/material'

interface Props {
  value: Record<string, number>
  onChange: (key: string, value: number) => void
}

export function Loads(props: Props) {
  const { value, onChange } = props

  if (Object.keys(value).length === 0) return null

  return (
    <div>
      <Typography variant="h6">Loads</Typography>
      <Stack spacing={0.5}>
        {Object.entries(value).map(([key, value]) => (
          <div key={key}>
            <label>
              {key}
              <TextField
                type="number"
                value={value}
                onChange={e => onChange(key, Number(e.target.value))}
              />
            </label>
          </div>
        ))}
      </Stack>
    </div>
  )
}
