import type { KeyboardEvent } from 'react'
import type { State, UpdateAction } from '../useTimePicker'
import type { TimeFormat } from '../../types'

import { useCallback, useEffect, useRef } from 'react'

import { useValidateOn } from '../../../../hooks/useValidateOn'
import { convertTimeInput, findIndexOfNearestOption, formatTime } from '../../utils'

interface Params {
  updateComponentState: React.Dispatch<UpdateAction>
  validations: ReturnType<typeof useValidateOn>
  allowUnlistedValues: boolean
  timeFormat: TimeFormat
  componentState: State
  options: number[]
  onBlur: () => void
}

export function useKeyboardActions(params: Params) {
  const { updateComponentState, timeFormat, options, onBlur, componentState } = params

  const componentStateRef = useRef(componentState)
  useEffect(() => {
    componentStateRef.current = componentState
  }, [componentState])

  // Called when a keyDown event on the TextInput is triggered
  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      const { selectValue } = componentStateRef.current
      const validCodes = ['ArrowDown', 'ArrowUp', 'Enter', 'Escape', 'Tab']

      if (!validCodes.includes(e.code)) return

      let selectedIndex = options.findIndex(item => item === selectValue)
      const inputElement = e.target as HTMLInputElement

      switch (e.code) {
        case 'Tab':
          updateComponentState({
            open: false,
          })
          break
        case 'Escape':
          e.preventDefault()
          updateComponentState({
            open: false,
            inputValue: formatTime(componentStateRef.current.timeValue, timeFormat),
          })
          break

        case 'Enter':
          e.preventDefault()
          if (selectedIndex !== -1) {
            inputElement.select()
            updateComponentState({
              open: false,
              timeValue: options[selectedIndex],
              selectValue: options[selectedIndex],
              inputValue: formatTime(options[selectedIndex], timeFormat),
            })
          }
          // If there's no selected item, pushing Enter will act as a blur
          else {
            onBlur()
          }

          break

        case 'ArrowUp':
          e.preventDefault()

          if (selectedIndex < 0) {
            const convertedInputValue = convertTimeInput(
              componentStateRef.current.inputValue,
              timeFormat,
            )

            if (convertedInputValue !== 'invalid') {
              const nearestIndex = findIndexOfNearestOption(convertedInputValue, options)

              if (nearestIndex) {
                selectedIndex = nearestIndex
              }
            }
          }

          if (selectedIndex <= 0) {
            updateComponentState({ open: true })
          } else {
            updateComponentState({
              open: true,
              selectValue: options[selectedIndex - 1],
              inputValue: formatTime(options[selectedIndex - 1], timeFormat),
            })
          }
          break

        case 'ArrowDown':
          e.preventDefault()

          if (selectedIndex < 0) {
            const convertedInputValue = convertTimeInput(
              componentStateRef.current.inputValue,
              timeFormat,
            )

            if (convertedInputValue !== 'invalid') {
              const nearestIndex = findIndexOfNearestOption(convertedInputValue, options)

              if (nearestIndex) {
                selectedIndex = nearestIndex
              }
            }
          }

          if (selectedIndex >= options.length - 1) {
            updateComponentState({ open: true })
          } else {
            updateComponentState({
              open: true,
              selectValue: options[selectedIndex + 1],
              inputValue: formatTime(options[selectedIndex + 1], timeFormat),
            })
          }

          break
      }
    },
    [updateComponentState, options, timeFormat, onBlur, componentStateRef],
  )

  return { onKeyDown }
}
