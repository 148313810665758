import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../../typings'

type Params = uui.domain.actions.rpc.ui.UpdateMapStyleAction['mapStyle']
/**
 * Create a new Driver.
 *
 * @private
 */
export const updateMapStyle = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Params,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('ui/mapStyles/updateMapStyle', async (mapStyle, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/ui/mapStyles/updateMapStyle', {
      mapStyle,
      category: 'rpc',
      type: 'rpc/ui/mapStyles/updateMapStyle',
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        error: result,
        type: 'rpcFailure',
        message: result.errorMessage,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {updateMapStyle}`,
      { tags: ['rpc', 'ui', 'map', 'mapStyles'], info: mapStyle },
      'error',
    )

    return thunkApi.rejectWithValue({
      error,
      type: 'unknown',
      message: `Failed to update a Map Style`,
    })
  }
})
