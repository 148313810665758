import { getMap } from '../../atoms/map/utils/getMap'
import { findLayer } from '../utils/findLayer'

import { changelogRemoveRegionFeatures } from './utils/changelogRemoveRegionFeatures'
import { changelogAddRegionFeatures } from './utils/changelogAddRegionFeatures'
import { changelogUpdateRegionFeatures } from './utils/changelogUpdateRegionFeatures'

export function createUpdateRegionFeaturesFromChangelog(
  markers: uui.domain.ui.map.markers.MapMarkers['region'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['region'],
  selection: Set<string>,
  changelog: uui.domain.api.Changelog,
) {
  // ATTENTION: Currently all changelog are applied as soon as they arrive, also when the map is not visible
  // `ignoreSelection` it will be used to avoid to apply any selection information to the map when applying cached updater functions.
  // after all cached updates will be applied a refresh selection action will be required
  return async (_ignoreSelection: boolean = false) => {
    const map = getMap()
    const layer = findLayer(map, 'region')

    // remove
    await changelogRemoveRegionFeatures(layer, selection, changelog.remove.values())

    // add
    await changelogAddRegionFeatures(layer, markers, mapStyles, selection, changelog.add.values())

    // update
    await changelogUpdateRegionFeatures(
      layer,
      markers,
      mapStyles,
      selection,
      changelog.update.values(),
    )
  }
}
