export function canRenderGpsDataToggler(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  deviceAggregateInfos: Record<string, uui.domain.client.gps.wwgps.DeviceAggregateInfo>,
  edit?: boolean,
) {
  if (!unifiedVehicle.isPhysicalDevice || unifiedVehicle.trackingProvider !== 'WWGPS') return false

  const device = unifiedVehicle.device as uui.domain.client.gps.wwgps.DeviceInfo
  const deviceAggregateInfo = deviceAggregateInfos[device.deviceId]
  const { capabilities } = device

  const hasDriverBehaviorCapability = capabilities.includes('drb-threshold')
  const hasIdleThresholdCapability = capabilities.includes('idle-threshold')
  const hasSpeedMonitor = !!deviceAggregateInfo.speedMonitor

  const gpsTags = unifiedVehicle.allowGps ? deviceAggregateInfo?.gpsTags ?? [] : []

  return hasDriverBehaviorCapability || hasIdleThresholdCapability || edit || hasSpeedMonitor
    ? true
    : gpsTags.length > 0
}
