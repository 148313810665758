import type { UpdateDriverActivity } from '../types'
import type { FormFields, FormErrors } from '../formFields'

import { useCallback } from 'react'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { useIsUnmounted } from '@/hooks'

export function useOnSubmit(
  updateDriverActivity: UpdateDriverActivity,
  clearEditingState: () => void,
) {
  const formApi = useFormApi<FormFields, FormErrors>()

  // track the mounted state of the parent component
  const isUnmounted = useIsUnmounted()

  return useCallback(async () => {
    try {
      formApi.setSubmitting(true)

      await formApi.waitForValidation()

      const valid =
        formApi.getMeta().status === 'indeterminate'
          ? await formApi.validate()
          : formApi.getMeta().status === 'valid'

      // handle pristine form submission
      if (formApi.getMeta().status === 'pristine') {
        formApi.setSubmitting(false)
        clearEditingState()
        return
      }

      if (!valid) {
        formApi.setSubmitting(false)
        return
      }

      const formValues = formApi.getValues()
      const nonEmptyReasons = formValues.reasonsForNotCompletedOrders.filter(Boolean)

      const collectStartEndRoute = formValues.collectStartEndRoute === 'yes'

      const result = await updateDriverActivity(
        collectStartEndRoute,
        nonEmptyReasons,
        formValues.enableDynamicLoads,
        formValues.enableDynamicCustomFields,
      )

      // Clear FormState submitting mode
      formApi.setSubmitting(false)

      // if the component has been unmounted during the async operation stop here
      if (isUnmounted()) return

      // if the update has been completed successfully let's close the form
      if (result) clearEditingState()
    } catch (e) {
      formApi.setSubmitting(false)
    }
  }, [formApi, updateDriverActivity, isUnmounted, clearEditingState])
}
