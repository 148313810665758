import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useNavigate } from '@/routing'
import { isGpsOnly } from '@/features/domain/user'
import { useSupportTicketModal } from '@/components/modals/SupportTicketModal'

export function useActions() {
  const navigate = useNavigate<uui.routing.MainSections>(true)
  const { show } = useSupportTicketModal()

  const gpsOnly = useSelector(isGpsOnly)

  const onNavigateHome = useCallback(() => {
    navigate(gpsOnly ? 'tracking' : 'routing')
  }, [navigate, gpsOnly])

  return {
    onNavigateHome,
    onOpenSupportTicket: show,
  }
}
