// ----- Length conversion values
// const KM_PER_MILE: number = 1.609344

import { addSeconds, format } from 'date-fns/esm'
import { cmsToMph, cmsToKmh } from '../conversion'

const MILE_PER_KM: number = 0.621371
const MILES_PER_METER: number = 0.000621371
const KM_PER_METER: number = 0.001
const KM_PER_MILE: number = 1.609344
const FEETS_PER_METER: number = 3.28084
// const KM_UM: string = 'km'
// const MILE_UM: string = 'mi'

// ----- Speed conversion values
const KMH_PER_MS: number = 3.6
const MPH_PER_MS: number = 2.23694
const KMH_UM: string = 'km/h'
const MPH_UM: string = 'mph'

export const intlFormat: Record<uui.domain.Language, string> = {
  en: 'us-US',
  es: 'es-ES',
  it: 'it-IT',
  pl: 'pl-PL',
  pt: 'pt-PT',
  nl: 'nl-NL',
  de: 'de-DE',
  fr: 'fr-FR',
}

export const convertSpeed =
  (userConfig: uui.domain.UserConfiguration) =>
  (metersPerSeconds: number, decimals: number = 0, printLabel: boolean = true) => {
    const { distanceFormat } = userConfig

    const label = printLabel ? (distanceFormat === 'IMPERIAL' ? ' mph' : ' km/h') : ''
    const factor = distanceFormat === 'IMPERIAL' ? MPH_PER_MS : KMH_PER_MS
    const value = metersPerSeconds * factor

    return `${value.toFixed(decimals)}${label}`
  }

export const formatPostedSpeed = (userConfig: uui.domain.UserConfiguration) => (speed: string) =>
  convertSpeed(userConfig)(parseInt(speed) / MPH_PER_MS)

export const convertMetersToMilesOrKilometers =
  (userConfig: uui.domain.UserConfiguration) =>
  (meters: number, decimals: number = 1) => {
    const { distanceFormat } = userConfig
    return distanceFormat === 'METRIC'
      ? parseFloat((meters * KM_PER_METER).toFixed(decimals))
      : parseFloat((meters * MILES_PER_METER).toFixed(decimals))
  }

export const convertDistance =
  (userConfig: uui.domain.UserConfiguration) =>
  (meters: number, maximumFractionDigits: number = 1, printLabel: boolean = true): string => {
    const { distanceFormat, language } = userConfig

    const label = printLabel ? (distanceFormat === 'IMPERIAL' ? ' mi' : ' km') : ''
    const factor = distanceFormat === 'IMPERIAL' ? MILES_PER_METER : KM_PER_METER
    const value = meters * factor

    const format = intlFormat[language]
    const formatNumber = new Intl.NumberFormat(format, { maximumFractionDigits }).format

    return `${formatNumber(value)}${label}`
  }

export const convertDistanceToMeters =
  (userConfig: uui.domain.UserConfiguration) =>
  (source: number, decimals: number = 2): number => {
    const { distanceFormat } = userConfig

    const value = distanceFormat === 'IMPERIAL' ? source / MILES_PER_METER : source * 1000

    return parseFloat(value.toFixed(decimals))
  }

export const convertTime =
  (userConfig: uui.domain.UserConfiguration) =>
  (seconds: number, showSeconds: boolean = true): string => {
    const { timeFormat } = userConfig

    const formatString = showSeconds ? getTimeFormatWithSeconds(timeFormat) : timeFormat
    const helperDate = addSeconds(new Date().setHours(0, 0, 0, 0), seconds)

    return format(helperDate, formatString)
  }

export const convertTimeFromDate =
  (userConfig: uui.domain.UserConfiguration) =>
  (date: number | Date, showSeconds: boolean = true): string => {
    const { timeFormat } = userConfig

    const formatString = showSeconds ? getTimeFormatWithSeconds(timeFormat) : timeFormat
    return format(date, formatString)
  }

export const getTimeFormatWithSeconds = (timeFormat: uui.domain.client.TimeFormat): string => {
  switch (timeFormat) {
    case 'hh:mm aa':
      return 'hh:mm:ss aa'

    case 'HH:mm':
      return 'HH:mm:ss'
  }
}

export const getDistanceUnitOfMeasure = (userConfig: uui.domain.UserConfiguration) => {
  const { distanceFormat } = userConfig
  return distanceFormat === 'IMPERIAL' ? 'mi' : 'km'
}

export const convertMaxSpeedValue =
  (userConfig: uui.domain.UserConfiguration) =>
  (valueInCms: number): number => {
    const { distanceFormat } = userConfig
    const val: number = distanceFormat === 'IMPERIAL' ? cmsToMph(valueInCms) : cmsToKmh(valueInCms)
    return Math.round(val)
  }

export function convertCostPerKmToCostPerMi(kmCost: number) {
  return kmCost / MILE_PER_KM
}

export function convertCostPerMilesToCostPerKilometers(miles: number) {
  return miles / KM_PER_MILE
}

export function convertMilesToKm(miles: number) {
  return miles * KM_PER_MILE
}

export function convertKmToMiles(km: number) {
  return km * MILE_PER_KM
}

export function convertMetersToFeet(m: number) {
  return m * FEETS_PER_METER
}

// gps speed values are currently in meters per second
export const convertGpsSpeed = (
  metersPerSeconds: number,
  userConfig: uui.domain.UserConfiguration,
  decimal: number = 0,
  printLabel: boolean = true,
): string => {
  const { distanceFormat } = userConfig

  const mph: number = metersPerSeconds * MPH_PER_MS

  if (distanceFormat === 'IMPERIAL') {
    return `${mph.toFixed(decimal)} ${printLabel ? MPH_UM : ''}`
  }

  const km: number = mph * KM_PER_MILE
  return `${km.toFixed(decimal)} ${printLabel ? KMH_UM : ''}`
}
