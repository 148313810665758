import { Stack } from '@mui/material'

import { Scheduler } from '@/scheduler'
import { Navigo } from '@/components/Navigo'

import { Popup } from './Popup'

export function SchedulerLayout() {
  return (
    <Stack width="100%" height="100%" overflow="hidden">
      <Scheduler />
      <Navigo />
      <Popup />
    </Stack>
  )
}
