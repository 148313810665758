import { useCallback, useState } from 'react'

import { setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'popoutPlaces'
export function useStylerActions(placeIds: string[]) {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const popoutPlaces = useCallback(async () => {
    setMethodInExecution('popoutPlaces')

    await dispatch(
      setCustomMapStyle({
        mapMode: 'off',
        customStyle: { type: 'place', mode: 'on', ids: placeIds },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, placeIds])

  return { methodInExecution, actions: { popoutPlaces } }
}
