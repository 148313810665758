import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    date: translate({ id: 'global.date' }),
    speed: translate({ id: 'global.speed' }),
    duration: translate({ id: 'global.duration' }),
    startedAt: translate({ id: 'rm.contextual.breadcrumbs.navbar.stopStartedAt' }),
  }))

  return api
}
