import { toggleVehicleLock } from '@/features/domain/vehicle'
import { store } from '@/store'
import { setRouteLoadingState } from '../../../../atoms/routeLoadingState'

export async function toggleRouteLock(date: string, vehicleId: string) {
  const payload = {
    date,
    vehicleId,
  }
  const routeId = `${payload.vehicleId}-${payload.date}`

  setRouteLoadingState(mutableState => {
    mutableState.lockStatus[routeId] = true
    return mutableState
  })

  const result = await store.dispatch(toggleVehicleLock(payload))

  if (!toggleVehicleLock.fulfilled.match(result)) {
    throw new Error(result.payload?.message ?? 'Internal error')
  }

  setRouteLoadingState(mutableState => {
    delete mutableState.lockStatus[routeId]
    return mutableState
  })
}
