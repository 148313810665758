import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    branding: translate({ id: 'global.branding' }),
    expandSidebar: translate({ id: 'app.header.sidebar.expand' }),
    collapseSidebar: translate({ id: 'app.header.sidebar.collapse' }),
    cannotCollapseSidebar: translate({ id: 'app.header.sidebar.cannotCollapse' }),
  }))

  return api
}
