import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    doYouConfirm: translate({ id: 'goToIntegrationsModal.doYouConfirm' }),
    headerTitle: translate({ id: 'goToIntegrationsModal.headerTitle' }),
    confirm: translate({ id: 'goToIntegrationsModal.confirm' }),
    cancel: translate({ id: 'goToIntegrationsModal.cancel' }),
  }))

  return api
}
