import { useSelector } from 'react-redux'

import { selectTelematicsSources } from '@/features/domain/account'
import { useController } from '../../hooks/useController'

import { VerizonConnect } from './components/VerizonConnect'
import { Linxup } from './components/Linxup'
import { Azuga } from './components/Azuga'

interface Props {
  tenantSource: uui.domain.server.gps.telematics.TenantSource
}

export function Header(props: Props) {
  const { tenantSource } = props
  const { close } = useController()
  const sources = useSelector(selectTelematicsSources)

  const source = sources[tenantSource.sourceId]

  if (!source) return null

  switch (source.label) {
    case 'Azuga':
      return <Azuga source={source} close={close} />

    case 'Linxup':
      return <Linxup source={source} close={close} />

    case 'Verizon Connect':
      return <VerizonConnect source={source} close={close} />

    default:
      return null
  }
}
