import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'

import { ViewActions } from '@/formUi'
import { WarningCard } from '@/local/components'

import { SentEmailsCounter } from '../SentEmailsCounter'
import { ChangePlanButton } from '../ChangePlanButton'
import { useTexts } from './useTexts'

interface Props {
  counters: uui.domain.server.CountryNotificationCounters
}

export function EmailPlan(props: Props) {
  const { counters } = props
  const texts = useTexts()

  const startDate = new Date(counters.emailCountStartTs)

  return (
    <>
      <ViewActions>
        <ChangePlanButton />
      </ViewActions>

      <SentEmailsCounter startDate={startDate} emailCount={counters.emailCount} />

      <WarningCard
        preset="info"
        title={texts.warningTitle}
        description={texts.warningDescription}
        Icon={<InfoOutlinedIcon color="primary" />}
      />
    </>
  )
}
