import { useState, useEffect } from 'react'

import {
  useCloseModal,
  useModalController,
  useConfigureModalController,
  useExportOrdersPreferences,
} from '@/atoms'

import { useCurrentOrders } from './useCurrentOrders'

type ModalState = 'ready' | 'pending' | 'invalidForEmptyOrders'

export type Column = {
  id: string
  label: string
}

type ColumnsState =
  | {
      status: 'not-requested'
    }
  | {
      status: 'loading'
    }
  | {
      status: 'complete'
      columns: Column[]
    }
  | {
      status: 'error'
    }

export interface ModalData {
  wizardStep: 'configure-format' | 'configure-columns'
  initialSelection: uui.domain.client.rm.ExtendedOrderStep[]
  selectedOrders: uui.domain.client.rm.ExtendedOrderStep[]
  allowBarcodes: boolean
  customizeColumns: boolean
  includeBarcodes: boolean
  format: 'XLSX' | 'CSV'
  keepEnglishColumns: boolean
  columnsState: ColumnsState
  columnSelection: string[]
  showEmptySelectionWarning: boolean
}

export const modalId = 'exportOrder'
export const useController = () => useModalController<ModalData>(modalId)

export const useConfigureController = () => {
  const [exportOrdersPreferences] = useExportOrdersPreferences()
  const selectedOrders = useCurrentOrders()
  const close = useCloseModal()

  const { format, includeBarcodes, customizeColumns, keepEnglishColumns } = exportOrdersPreferences

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      invalid: false,
      data: {
        wizardStep: 'configure-format' as const,
        format,
        initialSelection: selectedOrders,
        selectedOrders,
        includeBarcodes,
        allowBarcodes: true,
        keepEnglishColumns,
        columnsState: {
          status: 'not-requested' as const,
        },
        columnSelection: [],
        customizeColumns,
        showEmptySelectionWarning: false,
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const { update, updateData } = ctrl

  // EFFECTS

  // Invalid state management
  useEffect(() => {
    updateData({ selectedOrders })

    if (selectedOrders.length === 0) {
      update({ invalid: true, status: 'invalidForEmptyOrders' })
      return
    }

    update({ invalid: false, status: 'ready' })
  }, [selectedOrders, update, updateData])

  return ctrl
}
