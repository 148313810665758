import type { PartialStoreState } from '../typings'
import { createSelector } from '@reduxjs/toolkit'

export const selectCompanies = (state: PartialStoreState) =>
  state.domain.publicData.profile.account.accountData.companies

export const selectCompanyByNameLowercase = createSelector(selectCompanies, companies => {
  const byName: Record<string, uui.domain.client.rm.Company> = {}

  for (const company of Object.values(companies)) {
    byName[company.name.toLowerCase()] = company
  }

  return byName
})

export const selectCompaniesAsArray = createSelector(selectCompanies, companies => {
  return Object.values(companies).sort(sortCompany)
})

function sortCompany(a: uui.domain.client.rm.Company, b: uui.domain.client.rm.Company) {
  if (a.enabled && !b.enabled) {
    return -1
  }

  if (!a.enabled && b.enabled) {
    return 1
  }

  return a.name.localeCompare(b.name)
}
