export function getViaDotNotation(t: object, path: string) {
  return path.split('.').reduce((r, k) => r?.[k], t)
}

export function setViaDotNotation(target: object, path: string, value: any) {
  if (!path || !(typeof target === 'object')) return target

  const keys = path.split('.')
  let obj = target

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const next = keys[i + 1]

    if (next === undefined) {
      obj[key] = value
      break
    }

    if (typeof next === 'number' && !Array.isArray(obj[key])) {
      obj = obj[key] = []
      continue
    }

    if (!(typeof obj[key] === 'object')) {
      obj[key] = {}
    }

    obj = obj[key]
  }

  return target
}
