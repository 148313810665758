import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Keyboard(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 28 18">
        <path d="M26 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-6.6 4h1.2c.22 0 .4.18.4.4v1.2a.4.4 0 0 1-.4.4h-1.2a.4.4 0 0 1-.4-.4V4.4c0-.22.18-.4.4-.4zm-4 0h1.2c.22 0 .4.18.4.4v1.2a.4.4 0 0 1-.4.4h-1.2a.4.4 0 0 1-.4-.4V4.4c0-.22.18-.4.4-.4zM19 8.4v1.2a.4.4 0 0 1-.4.4h-1.2a.4.4 0 0 1-.4-.4V8.4c0-.22.18-.4.4-.4h1.2c.22 0 .4.18.4.4zM11.4 4h1.2c.22 0 .4.18.4.4v1.2a.4.4 0 0 1-.4.4h-1.2a.4.4 0 0 1-.4-.4V4.4c0-.22.18-.4.4-.4zM15 8.4v1.2a.4.4 0 0 1-.4.4h-1.2a.4.4 0 0 1-.4-.4V8.4c0-.22.18-.4.4-.4h1.2c.22 0 .4.18.4.4zM7.4 4h1.2c.22 0 .4.18.4.4v1.2a.4.4 0 0 1-.4.4H7.4a.4.4 0 0 1-.4-.4V4.4c0-.22.18-.4.4-.4zM11 8.4v1.2a.4.4 0 0 1-.4.4H9.4a.4.4 0 0 1-.4-.4V8.4c0-.22.18-.4.4-.4h1.2c.22 0 .4.18.4.4zM3.4 4h1.2c.22 0 .4.18.4.4v1.2a.4.4 0 0 1-.4.4H3.4a.4.4 0 0 1-.4-.4V4.4c0-.22.18-.4.4-.4zm0 4h3.2c.22 0 .4.18.4.4v1.2a.4.4 0 0 1-.4.4H3.4a.4.4 0 0 1-.4-.4V8.4c0-.22.18-.4.4-.4zm1.2 6H3.4a.4.4 0 0 1-.4-.4v-1.2c0-.22.18-.4.4-.4h1.2c.22 0 .4.18.4.4v1.2a.4.4 0 0 1-.4.4zm16 0H7.4a.4.4 0 0 1-.4-.4v-1.2c0-.22.18-.4.4-.4h13.2c.22 0 .4.18.4.4v1.2a.4.4 0 0 1-.4.4zm4 0h-1.2a.4.4 0 0 1-.4-.4v-1.2c0-.22.18-.4.4-.4h1.2c.22 0 .4.18.4.4v1.2a.4.4 0 0 1-.4.4zm0-4h-3.2a.4.4 0 0 1-.4-.4V8.4c0-.22.18-.4.4-.4h3.2c.22 0 .4.18.4.4v1.2a.4.4 0 0 1-.4.4zm0-4h-1.2a.4.4 0 0 1-.4-.4V4.4c0-.22.18-.4.4-.4h1.2c.22 0 .4.18.4.4v1.2a.4.4 0 0 1-.4.4z" />
      </svg>
    </IconContainer>
  )
}
