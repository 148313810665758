import type { ActionPermission } from '../../types'

export function computeSendMessagePermission(
  devicesCount: number,
  isToday: boolean,
  isGpsOnly: boolean,
): ActionPermission {
  // Hide the button if the user is not a gps only user
  if (!isGpsOnly) return 'hidden'

  // Disable the button if got multiple vehicles
  if (devicesCount !== 1 || !isToday) return 'disabled'

  return 'enabled'
}
