import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../../typings'

/**
 * Create a new Driver.
 *
 * @private
 */
export const resetMapStyles = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  void,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('ui/mapStyles/resetMapStyles', async (_, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/ui/mapStyles/resetMapStyles', {
      category: 'rpc',
      type: 'rpc/ui/mapStyles/resetMapStyles',
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        error: result,
        type: 'rpcFailure',
        message: result.errorMessage,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {resetMapStyles}`,
      { tags: ['rpc', 'ui', 'map', 'mapStyles'] },
      'error',
    )

    return thunkApi.rejectWithValue({
      error,
      type: 'unknown',
      message: `Failed to rest all Map Styles`,
    })
  }
})
