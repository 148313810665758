import type { MenuOption } from './types'

import { Box } from '@mui/material'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { Title } from './components/Title'
import { PopMenu } from './components/PopMenu'
import { Subtitle } from './components/Subtitle'
import { HeaderRow } from './components/HeaderRow'
import { EditButton } from './components/EditButton'
import { TitleLayout } from './components/TitleLayout'
import { CloseButton } from './components/CloseButton'
import { HeaderLayout } from './components/HeaderLayout'
import { DeleteButton } from './components/DeleteButton'

import { useTexts } from './hooks/useTexts'

interface Props {
  moreActions?: MenuOption[]

  onEdit?: () => void
  forceDisableEdit?: boolean

  onDelete?: () => void
  forceDisableDelete?: boolean

  onClose: () => void

  texts: {
    title: string
    subtitle?: string
  }

  validatingDelete: boolean

  testid?: string
}

export function ViewHeader(props: Props) {
  const {
    texts,
    testid,
    onEdit,
    onClose,
    onDelete,
    moreActions,
    forceDisableEdit,
    validatingDelete,
    forceDisableDelete,
  } = props

  const localTexts = useTexts()

  return (
    <HeaderLayout data-testid={testid}>
      <HeaderRow>
        <TitleLayout>
          <Title>{texts.title}</Title>
          {texts.subtitle && <Subtitle>{texts.subtitle}</Subtitle>}
        </TitleLayout>
        <CloseButton onClose={onClose} title={localTexts.close} />
      </HeaderRow>
      <HeaderRow sx={{ justifyContent: 'flex-end' }}>
        <Box sx={{ marginLeft: 'auto' }} />
        {onEdit && (
          <ReadOnlyTooltip
            canEdit={!forceDisableEdit}
            forceState="enabled"
            title={localTexts.edit}
            render={preventEditing => (
              <EditButton onEdit={onEdit} disabled={preventEditing} testId="edit-button" />
            )}
          />
        )}
        {onDelete && (
          <ReadOnlyTooltip
            canEdit={!forceDisableDelete}
            forceState="enabled"
            title={localTexts.delete}
            render={preventEditing => (
              <DeleteButton
                onDelete={onDelete}
                disabled={preventEditing}
                validating={validatingDelete}
              />
            )}
          />
        )}
        {moreActions && <PopMenu moreActions={moreActions} />}
      </HeaderRow>
    </HeaderLayout>
  )
}
