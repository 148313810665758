import { useCallback, useState } from 'react'

import { setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Actions = 'popoutOrders' | 'maximizeOrders' | 'showOrders' | 'hideOrders'

export const useActions = (ids: string[]) => {
  const dispatch = useAppDispatch()

  const [methodInExecution, setMethodInExecution] = useState<Actions | null>(null)

  const popoutOrders = useCallback(async () => {
    setMethodInExecution('popoutOrders')

    await dispatch(
      setCustomMapStyle({
        mapMode: 'off',
        customStyle: { type: 'order', mode: 'maximized', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const maximizeOrders = useCallback(async () => {
    setMethodInExecution('maximizeOrders')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'order', mode: 'maximized', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const showOrders = useCallback(async () => {
    setMethodInExecution('showOrders')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'order', mode: 'on', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const hideOrders = useCallback(async () => {
    setMethodInExecution('hideOrders')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'order', mode: 'off', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  return { methodInExecution, actions: { popoutOrders, maximizeOrders, showOrders, hideOrders } }
}
