import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { journal } from '@/server-data'
import { useAppDispatch } from '@/store'
import { selectLivePositions } from '@/features/domain/device'
import { fitMap, fitMapToSelection } from '@/map'
import { useGpsReportsLink, useNavigate } from '@/routing'
import { selectCalendarRange, setCustomMapStyle } from '@/features/domain/ui'
import { setCrudSelection, useEditingStateWithCtx } from '@/atoms'

export function useActions(
  devices: uui.domain.client.gps.wwgps.DeviceInfo[],
  breadcrumbMapMode: uui.domain.ui.map.markers.MapMode,
  eventsCount: number,
) {
  const dispatch = useAppDispatch()
  const calendarRange = useSelector(selectCalendarRange)
  const livePositions = useSelector(selectLivePositions)
  const { setEditing } = useEditingStateWithCtx('device')
  const navigateSetup = useNavigate<uui.routing.Setup>(true)
  const { gpsReportsLink, createGpsReportsCookie } = useGpsReportsLink()
  const navigateMain = useNavigate<uui.routing.MainSections>(true)

  // --------------------------------------------------
  // Center on Map
  // --------------------------------------------------
  const centerOnMap = useCallback(() => {
    const latLngCoordinates = devices.reduce<uui.domain.LatLng[]>((acc, device) => {
      const lastPosition = livePositions[device.deviceId]

      if (!lastPosition) return acc

      acc.push(lastPosition.latLng)

      return acc
    }, [])

    fitMap(latLngCoordinates)
  }, [devices, livePositions])

  // --------------------------------------------------
  // Toggle breadcrumbs
  // --------------------------------------------------
  const toggleBreadcrumbs = useCallback(() => {
    // If there are (0 || > 1) selected devices we cannot toggle breadcrumbs
    if (devices.length !== 1) return

    if (calendarRange.minDate !== calendarRange.maxDate) {
      journal.main(
        'It is not possible to toggle breadcrumbs for multiple days. THIS SHOULD NOT HAPPEN',
        {
          info: {
            maxDate: calendarRange.maxDate,
            minDate: calendarRange.minDate,
          },
        },
        'error',
      )
      return
    }

    dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: {
          type: 'breadcrumb',
          mode: breadcrumbMapMode === 'on' ? 'off' : 'on',
          ids: [devices[0].deviceId],
        },
      }),
    )
  }, [dispatch, calendarRange, breadcrumbMapMode, devices])

  // --------------------------------------------------
  // Go to Edit
  // --------------------------------------------------
  const goToEdit = useCallback(() => {
    const currentDeviceIds = devices.map(device => device.deviceId)
    setCrudSelection('devices', currentDeviceIds)

    setEditing(currentDeviceIds)

    fitMapToSelection({ preventIfVisible: false }, 'add')

    navigateSetup('devices')
  }, [navigateSetup, setEditing, devices])

  const goToEvents = useCallback(() => {
    const ids = devices.map(d => d.deviceId)

    // if eventsCount is 0 the marker does not exist
    // we prevent an error
    if (eventsCount > 0) {
      dispatch(
        setCustomMapStyle({
          customStyle: {
            ids,
            type: 'deviceEvent',
            mode: 'on',
          },
        }),
      )
    }

    // Navigate to the correct route
    navigateMain('events')
  }, [dispatch, devices, navigateMain, eventsCount])

  // -------------------------------------------------------
  // Reports
  // -------------------------------------------------------
  const runReports = useCallback(() => {
    const deviceIds = devices.reduce<number[]>((acc, device) => {
      acc.push(parseInt(device.deviceId))

      return acc
    }, [])

    createGpsReportsCookie(deviceIds)

    globalThis.open(gpsReportsLink, '_blank')
  }, [gpsReportsLink, createGpsReportsCookie, devices])

  return { goToEdit, goToEvents, toggleBreadcrumbs, centerOnMap, runReports }
}
