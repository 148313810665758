import type { FormValidator } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../../../formFields'
import type { ValidationTexts } from '../useTexts'

const maxLoadValue = 5000000

export const createValidateLoads =
  (texts: ValidationTexts): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const bulkLoads = formApi.getField('loads').value

    const invalidLoads = Object.values(bulkLoads.loads).reduce<Record<string, string[]>>(
      (acc, curr) => {
        const errors: string[] = []
        if (curr.status !== 'exact') return acc

        const numericValue = parseFloat(curr.value)

        if (curr.value === '') {
          errors.push(texts.invalidLoadEmpty)
        } else if (isNaN(numericValue) || `${numericValue}` !== curr.value) {
          errors.push(texts.invalidLoadInvalidFormat)
        } else if (numericValue <= 0) {
          errors.push(texts.invalidLoadLessThanZero)
        } else if (numericValue > maxLoadValue) {
          errors.push(texts.invalidLoadExceedMaxValue)
        }

        if (curr.name.length > 30) {
          errors.push(texts.invalidLoadExceedMaxCharacters)
        }

        if (errors.length > 0) {
          acc[curr.name] = errors
        }
        return acc
      },
      {},
    )

    const invalidLoadsError: FormErrors = {
      id: 'invalidLoadsError',
      field: 'loads',
      message: '',
      priority: 3,
      invalidLoads: invalidLoads,
    }

    const hasInvalidLoads = Object.keys(invalidLoads).length > 0

    return {
      [invalidLoadsError.id]: hasInvalidLoads ? invalidLoadsError : null,
    }
  }
