import { Alert, Checkbox, FormControlLabel } from '@mui/material'

import { useController } from '../../../../../hooks/useController'
import { useControllerActions } from '../../../../../hooks/useControllerActions'
import { useTexts } from '../../../../../hooks/useTexts'

const includePodTestId = 'export-route-include-pod'

export function IncludePod() {
  const {
    data: { includePod, canExportPod },
  } = useController()

  const handlers = useControllerActions()

  const texts = useTexts()

  if (!canExportPod) {
    return <Alert severity="info">{texts.cannotExportPodForCalendarRange}</Alert>
  }

  return (
    <FormControlLabel
      data-testid={includePodTestId}
      data-trackid={includePodTestId}
      control={
        <Checkbox size="small" checked={includePod} onChange={handlers.onChangeIncludePod} />
      }
      label={texts.includePod}
    />
  )
}
