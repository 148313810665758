import { validateGpsData } from './validations/validateGpsData'
import { validateDeviceData } from './validations/validateDeviceData'

export function validate(values: uui.domain.ui.forms.GpsVehicleFormValues) {
  const gps = validateGpsData(values.gps, [])
  const device = validateDeviceData(values.device)

  return {
    gps,
    device,
  }
}
