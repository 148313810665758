import { AccessTimeFilled, RemoveCircle } from '@mui/icons-material'
import { useSegmentType } from '../../../../hooks/useSegmentType'
import { IconBackground } from './IconBackground'
import { DisabledBadge } from './DisabledBadge'

interface Props {
  segment: uui.domain.client.rm.SegmentException
}

export function RoutingSegmentAvatar(props: Props) {
  const { segment } = props

  const segmentType = useSegmentType(segment)

  switch (segmentType.type) {
    case 'delayed':
      return (
        <IconBackground>
          <AccessTimeFilled fontSize="large" color="warning" />
        </IconBackground>
      )

    case 'blocked':
      return (
        <IconBackground>
          <RemoveCircle fontSize="large" color="error" />
        </IconBackground>
      )

    case 'disabled':
      return <DisabledBadge />
  }
}
