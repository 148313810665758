import { type ResourceModel } from '@bryntum/schedulerpro'
import { type CSSProperties } from 'react'
import { getDay, parse } from 'date-fns/esm'

import { getDayLabelFromIndex } from '@/intl'
import { useIsLoading } from '@/atoms'
import { formatDate } from '@/hooks'

import { getViolationsColor } from '../../../styles/colors'

import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../Scheduler/components/Typography'
import { Spinner } from '../../Scheduler/components/Spinner'
import { Link } from '../../Scheduler/components/Link'
import { Box } from '../../Scheduler/components/Box'

import { EventBusy } from '../icons/EventBusy'
import { Flag } from '../icons/Flag'

interface Props {
  record: ResourceModel
  field: string
  value: string
  userConfig: uui.domain.UserConfiguration
}

export function DateCell(props: Props) {
  const { value, record, userConfig } = props
  const available = record.getData('available') as boolean | undefined
  const violations = record.getData('violations') as uui.domain.rm.RouteViolations | undefined

  const gpsDataLoading = useIsLoading('fetchGpsData')

  const unavailable = !available
  const hasVehicleViolations = (violations?.vehicle?.length ?? 0) > 0

  const iconBaseStyle = {
    '--font-size': '14px',
  } as CSSProperties

  const errorStyle = hasVehicleViolations
    ? ({
        color: getViolationsColor(),
        '--font-size': '14px',
      } as CSSProperties)
    : {}

  const iconStyle = { ...iconBaseStyle, ...errorStyle }

  const date = parse(value, 'yyyyMMdd', new Date())

  // If GPS data are still loading we cannot select/edit driver assignments
  if (gpsDataLoading) {
    return (
      <SingleColumnContainer>
        <Spinner size={14} />
      </SingleColumnContainer>
    )
  }

  const formattedDate = formatDate(date, false, userConfig)

  return (
    <SingleColumnContainer>
      <Link>
        <Box direction="row" gap={8} alignItems="center" justifyContent="start">
          <Typography fontSize={12}>
            {getDayLabelFromIndex(getDay(date), 'short')}
            {' • '}
            {formattedDate}
          </Typography>

          {unavailable && <EventBusy style={iconStyle} />}
          <div style={{ marginRight: 4 }}>{hasVehicleViolations && <Flag style={iconStyle} />}</div>
        </Box>
      </Link>
    </SingleColumnContainer>
  )
}
