import type Geometry from 'ol/geom/Geometry'
import Feature from 'ol/Feature'

import { updateEditableArea } from '../../../atoms/editableArea/core/updateEditableArea'

export function createOnAreaChange() {
  /**
   * Update the active Editable Area with the latest Geometry points
   */
  return (points: uui.domain.LatLng[], _feature: Feature<Geometry>, _lastChanged: number) => {
    updateEditableArea(prev => {
      return { ...prev, points }
    })
  }
}
