import { timeWindowUtils } from '@/utils'

export function formatWorkingDayInfo(
  timeWindow: uui.domain.rm.TimeWindow,
  earlyDeparture: boolean,
  workingDayStartSec: number,
): uui.domain.ui.forms.ExceptionFormValues['workingDayInfo'] {
  const { startSec, endSec } = timeWindowUtils.formatTimeWindow(timeWindow, workingDayStartSec)

  return {
    workingDayStart: startSec,
    workingDayEnd: endSec,
    flexibleStart: !earlyDeparture,
  }
}
