import { useCallback, useState } from 'react'

import { setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'popoutVehicles' | 'showVehicleLabels' | 'showVehicles' | 'hideVehicles'

export const useActions = (ids: string[]) => {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const popoutVehicles = useCallback(async () => {
    setMethodInExecution('popoutVehicles')

    await dispatch(
      setCustomMapStyle({
        mapMode: 'off',
        customStyle: { type: 'vehicle', mode: 'label', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const showVehicleLabels = useCallback(async () => {
    setMethodInExecution('showVehicleLabels')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'vehicle', mode: 'label', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const showVehicles = useCallback(async () => {
    setMethodInExecution('showVehicles')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'vehicle', mode: 'on', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const hideVehicles = useCallback(async () => {
    setMethodInExecution('hideVehicles')

    await dispatch(
      setCustomMapStyle({
        mapMode: undefined,
        customStyle: { type: 'vehicle', mode: 'off', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  return {
    methodInExecution,
    actions: { popoutVehicles, showVehicleLabels, showVehicles, hideVehicles },
  }
}
