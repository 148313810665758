import type { FormRenderProps } from 'react-final-form'
import type { TrafficTimeWindowsFormValues } from '../../../types'

import { useCallback } from 'react'

export function useManipulateTimeWindows(
  formRenderProps: FormRenderProps<TrafficTimeWindowsFormValues>,
) {
  const formState = formRenderProps.form.getState()
  const changeFormValues = formRenderProps.form.change
  const formValues = formState.values

  const visibleTimeWindowsCount = computeVisibleTimeWindowsCount(
    formValues.timeWindow1Visible,
    formValues.timeWindow2Visible,
  )

  const onAddTimeWindow = useCallback(() => {
    if (visibleTimeWindowsCount === 2) return

    switch (visibleTimeWindowsCount) {
      case 0:
        changeFormValues('timeWindow1Visible', true)
        break
      case 1:
        changeFormValues('timeWindow2Visible', true)
        break
    }
  }, [changeFormValues, visibleTimeWindowsCount])

  const onHideTimeWindow1 = useCallback(() => {
    changeFormValues('timeWindow1Visible', false)
  }, [changeFormValues])

  const onHideTimeWindow2 = useCallback(() => {
    changeFormValues('timeWindow2Visible', false)
  }, [changeFormValues])

  return {
    onAddTimeWindow,
    onHideTimeWindow1,
    onHideTimeWindow2,
    reachLimitOfTimeWindows: visibleTimeWindowsCount === 2,
  }
}

function computeVisibleTimeWindowsCount(timeWindow1Visible: boolean, timeWindow2Visible: boolean) {
  if (!timeWindow1Visible && !timeWindow2Visible) {
    return 0
  }
  if (timeWindow1Visible && timeWindow2Visible) {
    return 2
  }
  return 1
}
