import { useMemo } from 'react'
import { format, addDays } from 'date-fns/esm'

import { useDateFnsOptions } from '@/hooks'

const sunday = new Date(1970, 1, 1)

export const useDaysOfWeek = () => {
  const options = useDateFnsOptions()

  const daysOfWeek = useMemo(() => {
    const map: Record<number, string> = {}
    for (let i = 0; i < 7; i++) {
      map[i] = format(addDays(sunday, i), 'EEEEEE', options)
    }

    const result: string[] = []
    for (let i = 0; i < 7; i++) {
      result.push(map[(i + options.weekStartsOn) % 7])
    }

    return result
  }, [options])

  return daysOfWeek
}
