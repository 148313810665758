import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import {
  useToggleTesterModal,
  useToggleSidebar,
  useTimeShiftPlan,
  useHasPrivileges,
  useMouseTrap,
} from '@/hooks'

export function GlobalKeybindings() {
  const { authenticated } = useSelector(selectUserConfiguration)

  const timeShiftPlan = useTimeShiftPlan()
  const { toggleSidebar } = useToggleSidebar()
  const { toggleTesterModal } = useToggleTesterModal()
  const isDemoOrDev = useHasPrivileges(['demo', 'dev'], false)

  useMouseTrap('mod+b', toggleSidebar, true, !authenticated)
  useMouseTrap('ctrl+shift+\\', toggleTesterModal, true, !authenticated)
  useMouseTrap('ctrl+shift+d', timeShiftPlan, false, !isDemoOrDev)

  return null
}
