import type { ExceptionReadonlyValues } from '../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectTerritory } from '@/features/domain/territory'
import { selectUserConfiguration } from '@/features/domain/user'

import { computeReadonlyValues } from '../utils/computeReadonlyValues'

import { useParseRegionData } from './useParseRegionData'
import { useParseBreaksData } from './useParseBreaksData'
import { useParseDepotData } from './useParseDepotData'
import { useParseLoadsData } from './useParseLoadsData'

export function useViewDetailsData(
  vs: uui.domain.server.rm.VehicleSettings,
): ExceptionReadonlyValues {
  const { workingDayStartSec } = useSelector(selectTerritory)

  const userConfig = useSelector(selectUserConfiguration)
  const regionData = useParseRegionData(vs.regionIds)
  const breaksData = useParseBreaksData(vs.breaks, workingDayStartSec)
  const departureDepotData = useParseDepotData(vs.departureDepotId)
  const arrivalDepotData = useParseDepotData(vs.arrivalDepotId)
  const loadsData = useParseLoadsData(vs.loadCapacities, vs.minLoadCapacities)

  return useMemo(() => {
    return computeReadonlyValues({
      vs,
      regionData,
      breaksData,
      loadsData,
      departureDepotData,
      arrivalDepotData,
      workingDayStartSec,
      userConfig,
    })
  }, [
    vs,
    regionData,
    breaksData,
    loadsData,
    departureDepotData,
    arrivalDepotData,
    workingDayStartSec,
    userConfig,
  ])
}
