import { PresetManager } from '@bryntum/schedulerpro'
import { useCreateFormatTime } from '@/hooks'

type HeaderConfig = {
  align: string
  coord: number
  start: Date
  end: Date
  index: number
  headerCellCls: string
  width: number
}

function createBasePresetConfig(formatTime: ReturnType<typeof useCreateFormatTime>) {
  return {
    displayDateFormat: 'HH:mm', // Controls how dates will be displayed in tooltips etc

    shiftIncrement: 1, // Controls how much time to skip when calling shiftNext and shiftPrevious.
    shiftUnit: 'hour' as const, // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
    defaultSpan: 24, // By default, if no end date is supplied to a view it will show 12 hours

    timeResolution: {
      // Dates will be snapped to this resolution
      unit: 'minute', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
      increment: 1,
    },

    headers: [
      // This defines your header rows from top to bottom
      {
        unit: 'hour',
        align: 'start',
        headerCellCls: 'ww-timeaxis-hour',
        renderer: function (start: Date, _end: Date, headerConfig: HeaderConfig, _index: number) {
          const formattedDate = formatTime(start, false)
          return `${computeDateText(formattedDate, headerConfig)}
          ${computeMinuteSubdivisions(headerConfig)}`
        },
      },
    ],

    //columnLinesFor: 1, // Defines header level column lines will be drawn for. Defaults to the last level.
  }
}

export function useSetupBryntumSchedulerPresets() {
  PresetManager.removeAll()

  const formatDate = useCreateFormatTime()
  const basePresetConfig = createBasePresetConfig(formatDate)

  for (let a = 0; a < 20; a++) {
    PresetManager.registerPreset(`preset-level-${a}`, {
      ...basePresetConfig,
      id: `preset-level-${a}`,
      name: `preset-level-${a}`,
      tickWidth: (a + 1) * 50,
    })
  }
}

function computeDateText(textToDisplay: string, headerConfig: HeaderConfig) {
  let className = 'ww-timeaxis-hour__label'
  if (headerConfig.width < 100) {
    className += ' ww-timeaxis-hour__label--smallest'
  }
  if (headerConfig.width < 150) {
    className += ' ww-timeaxis-hour__label--small'
  }

  return `<span class="${className}">${textToDisplay}</span>`
}

/**
 * @return html string representing minutes separator based on the available width
 * @param headerConfig HeaderConfig
 */

function computeMinuteSubdivisions(headerConfig: HeaderConfig) {
  const { width } = headerConfig

  const subdivision = getSubdivisionByWidth(width)

  if (subdivision === 0) return ''

  let html = '<span class="ww-timeaxis-hour">'

  for (let i = 0; i < subdivision; i++) {
    const cellWidth = 100 / subdivision
    const highlight = i === 0

    html += `<span class="ww-timeaxis-hour__subdivision ${
      highlight ? 'ww-timeaxis-hour__subdivision-highlight' : ''
    }" style="width: ${cellWidth}%"></span>`
  }

  html += '</span>'

  return html
}

function getSubdivisionByWidth(width: number) {
  if (width < 50) {
    return 0
  }

  if (width < 100) {
    return 2
  }

  if (width < 150) {
    return 3
  }

  return 5
}
