/**
 * Determine the intersection point of two line segments, but considering also
 * colinear segments as intersection
 * @see {@link http://paulbourke.net/geometry/pointlineplane/}
 */
export function linesIntersect(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number,
): { x: number; y: number; colinear: boolean; intersection: boolean } {
  const notIntersection = { x: 0, y: 0, colinear: false, intersection: false }
  const colinear = { x: 0, y: 0, colinear: true, intersection: true }

  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return { x: 0, y: 0, colinear: false, intersection: false }
  }

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1)
  const numberA = (x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)
  const numberB = (x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)

  // Lines are parallel
  if (denominator === 0) {
    const isColinear = numberA === 0 && numberB === 0

    return isColinear ? colinear : notIntersection
  }

  const ua = numberA / denominator
  const ub = numberB / denominator

  // is the intersection along the segments
  if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
    // Intersection point
    const x = x1 + ua * (x2 - x1)
    const y = y1 + ua * (y2 - y1)

    return { x, y, colinear: false, intersection: true }
  }

  return notIntersection
}
