import type { FormValidator } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../../../formFields'
import type { ValidationTexts } from '../useTexts'

import { useAppDispatch } from '@/store'
import { validatePhoneNumbers as validatePhoneNumbersAction } from '@/features/domain/order/actions/validatePhoneNumbers'

type Dispatch = ReturnType<typeof useAppDispatch>

export const createValidatePhone =
  (dispatch: Dispatch, texts: ValidationTexts): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const { value, status } = formApi.getField('phone')

    const invalidPhoneError: FormErrors = {
      id: 'invalidPhoneError',
      field: 'phone',
      message: texts.invalidPhone,
      priority: 3,
    }

    const hasToValidate = status !== 'pristine' && value.status === 'exact'
    const phoneToValidate = value.status === 'exact' ? value.value : ''

    if (!hasToValidate) {
      return {
        [invalidPhoneError.id]: null,
      }
    }

    const valid = await validatePhoneNumber(phoneToValidate, dispatch)

    return {
      [invalidPhoneError.id]: !valid ? invalidPhoneError : null,
    }
  }

async function validatePhoneNumber(phone: string, dispatch: Dispatch) {
  const thunkResult = await dispatch(validatePhoneNumbersAction([phone]))

  if (validatePhoneNumbersAction.fulfilled.match(thunkResult)) {
    return thunkResult.payload[phone]
  }

  return false
}
