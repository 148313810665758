import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectPhysicalDevices } from '@/features/domain/device'

export function useDevices() {
  const devices = useSelector(selectPhysicalDevices)

  return useMemo(() => {
    return Object.values(devices).map(device => {
      return {
        id: device.deviceId,
        name: device.label,
        color: device.iconColor1,
      }
    })
  }, [devices])
}
