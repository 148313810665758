import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRoutesInfo } from '@/features/domain/scheduler'

export function useCanUnassign(order: uui.domain.client.rm.ExtendedOrderStep) {
  const routesInfo = useSelector(selectRoutesInfo)

  return useMemo(() => {
    if (order.isUnassigned) return true

    // if route is locked, the order cannot be unassigned
    return routesInfo[order.routeId]?.locked ? false : true
  }, [routesInfo, order])
}
