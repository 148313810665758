import styled from 'styled-components'

const AssetListTitle = styled.h3`
  margin: 0;
  font-weight: ${p => p.theme.weights.$semiBold};
  font-size: ${p => p.theme.fonts.$p3};
  color: ${p => p.theme.colors.$paleSky};
  text-transform: uppercase;
`

AssetListTitle.displayName = 'AssetListTitle'
export default AssetListTitle
