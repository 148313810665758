import { Outlet } from 'react-router'

import { Grow, HorizontalLayout } from '@/components/layout'
import { useResetOrdersFilterOnMount } from '@/hooks'

import { Popup } from './components/Popup'
import { SettingsSidebar } from './components/SettingsSidebar'

export function Settings() {
  useResetOrdersFilterOnMount()

  return (
    <>
      <HorizontalLayout>
        <SettingsSidebar />

        <Grow xAxis yAxis>
          <Outlet />
        </Grow>
      </HorizontalLayout>

      <Popup />
    </>
  )
}
