import type { FitMapOptions, PendingFitMapMode } from '../utils/fitMap'

import { useCallback, useRef, useEffect } from 'react'

import { fitMap } from '../utils/fitMap'

export const useFitMap = (options?: FitMapOptions) => {
  const optionsRef = useRef(options)
  useEffect(() => void (optionsRef.current = options), [options])

  return useCallback(
    (
      latLngArray: uui.domain.LatLng[],
      preventIfVisible?: boolean,
      pendingFitMap?: PendingFitMapMode,
    ) => {
      fitMap(
        latLngArray,
        {
          ...optionsRef.current,
          preventIfVisible: preventIfVisible ?? optionsRef.current?.preventIfVisible,
        },
        pendingFitMap,
      )
    },
    [],
  )
}
