import {
  useState,
  useEffect,
  forwardRef,
  useCallback,
  type ReactNode,
  type MouseEvent,
  type MutableRefObject,
} from 'react'
import { FilterList } from '@mui/icons-material'
import { Badge, IconButton, Menu, PopoverOrigin } from '@mui/material'
import { Tooltip } from '../../primitives/Tooltip'
import { useTexts } from './hooks/useTexts'

type Props = {
  filterActive?: boolean
  disabled?: boolean
  children: ReactNode
}

export type FilterMenuRef = {
  close: () => void
}

const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }

export const ListHeaderFilterMenu = forwardRef(
  (props: Props, ref: MutableRefObject<FilterMenuRef>) => {
    const { children, disabled = false, filterActive = false } = props

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const texts = useTexts()

    const onToggleClick = useCallback((event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }, [])

    const onSecondaryActionsMenuClose = useCallback(() => {
      setAnchorEl(null)
    }, [])

    useEffect(() => {
      // eslint-disable-next-line no-param-reassign
      ref.current = { close: onSecondaryActionsMenuClose }
    }, [ref, onSecondaryActionsMenuClose])

    const open = Boolean(anchorEl)

    return (
      <Badge
        color="primary"
        overlap="circular"
        variant="dot"
        invisible={!filterActive}
        data-testid="list-header-filter-menu-badge"
        data-trackid="list-header-filter-menu-badge"
      >
        <Tooltip title={texts.filter} placement="bottom" disabled={disabled}>
          <IconButton
            onClick={onToggleClick}
            disabled={disabled}
            data-testid={'list-header-filter-menu-button'}
            data-trackid={'list-header-filter-menu-button'}
          >
            <FilterList />
          </IconButton>
        </Tooltip>

        <Menu
          open={open}
          onClose={onSecondaryActionsMenuClose}
          anchorEl={anchorEl}
          data-testid={'list-header-filter-menu'}
          anchorOrigin={anchorOrigin}
          data-trackid={'list-header-filter-menu'}
        >
          {children}
        </Menu>
      </Badge>
    )
  },
)
