import { Stack, Chip } from '@mui/material'

import { FormatDate } from '@/components/smartUtils/time/FormatDate'

import { useChips } from './useChips'

interface Props {
  dates: string[]
  onChange: (dates: Date[]) => void
}

// set the width of the Chips container to the same as the calendar to align them
const calendarWidth = 272

export function Chips(props: Props) {
  const { dates: sourceDates, onChange } = props

  const { dates, onRemoveChip } = useChips(sourceDates, onChange)

  return (
    // this div is necessary to let the Stack container to not override the margin of his children
    <div>
      <Stack spacing={1} alignItems="flex-start" width={calendarWidth} sx={{ margin: '0 auto' }}>
        {dates.map(date => (
          <Chip
            key={date}
            label={<FormatDate source={date} />}
            onDelete={() => onRemoveChip(date)}
          />
        ))}
      </Stack>
    </div>
  )
}
