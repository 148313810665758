import { Stack } from '@mui/material'
import { TextField } from '@workwave-tidal/tidal/form-ui'

import { type FormFields, type FormErrors } from '../formFields'
import { useTexts } from '../hooks/useTexts'

export function Body() {
  const texts = useTexts()

  return (
    <Stack mt={3}>
      <TextField<'name', FormFields, FormErrors>
        label={texts.form.name.label}
        validateOn="blur focus change"
        name="name"
        testId="name"
      />
    </Stack>
  )
}
