import { useSelector } from 'react-redux'
import { proxy, subscribe, useSnapshot } from 'valtio'

import { selectUserProfileUiData } from '@/features/domain/user'

export type ExportRoutesPreferences = {
  keepEnglishColumns: boolean
  customizeColumns: boolean
  includeBarcodes: boolean
  currentRoutes: boolean
  customFields: Record<string, boolean>
  includePod: boolean
  singleFile: boolean
  format: 'XLSX' | 'CSV'
  loads: Record<string, boolean>
}

type ExportRoutesPreferencesAtom = {
  preferences: ExportRoutesPreferences
  syncWithUserProfile: boolean
}

// ---------------------------------------------------------------------------
// Default values
// ---------------------------------------------------------------------------
const defaultPreferences: ExportRoutesPreferences = {
  keepEnglishColumns: false,
  customizeColumns: false,
  includeBarcodes: true,
  currentRoutes: true,
  customFields: {},
  singleFile: true,
  includePod: true,
  format: 'XLSX',
  loads: {},
}

// ---------------------------------------------------------------------------
// Restore from Local Storage
// ---------------------------------------------------------------------------

const localStorageId = 'routemanager/v1/atoms/exportRoutesPreferences'

const localStorageValue = localStorage.getItem(localStorageId)

const initialSettings = (
  localStorageValue
    ? { ...defaultPreferences, ...JSON.parse(localStorageValue) }
    : defaultPreferences
) as ExportRoutesPreferences

const createDefaultExportRoutesPreferences = (): ExportRoutesPreferencesAtom => ({
  preferences: initialSettings,
  syncWithUserProfile: false,
})

export const exportRoutesPreferencesAtom = proxy<ExportRoutesPreferencesAtom>(
  createDefaultExportRoutesPreferences(),
)

// ---------------------------------------------------------------------------
// Write functions
// ---------------------------------------------------------------------------

type SetExportRoutesPreferences = (prev: ExportRoutesPreferences) => ExportRoutesPreferences
type SetExportRoutesPreferencesParam =
  | SetExportRoutesPreferences
  | Partial<ExportRoutesPreferences>
  | 'reset'

export function resetExportRoutesPreferences() {
  exportRoutesPreferencesAtom.preferences = defaultPreferences
}

export const setExportRoutesPreferences = (valueOrFunc: SetExportRoutesPreferencesParam) => {
  if (valueOrFunc === 'reset') return resetExportRoutesPreferences()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(
      exportRoutesPreferencesAtom.preferences,
      valueOrFunc(exportRoutesPreferencesAtom.preferences),
    )
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      exportRoutesPreferencesAtom.preferences[field] = valueOrFunc[field]
    }
  }

  return exportRoutesPreferencesAtom
}

// ---------------------------------------------------------------------------
// Write to Local Storage
// ---------------------------------------------------------------------------

subscribe(exportRoutesPreferencesAtom.preferences, () => {
  localStorage.setItem(localStorageId, JSON.stringify(exportRoutesPreferencesAtom.preferences))
})

// ---------------------------------------------------------------------------
// React Hooks
// ---------------------------------------------------------------------------

export function useExportRoutesPreferences() {
  const snapshot = useSnapshot(exportRoutesPreferencesAtom)
  const uiData = useSelector(selectUserProfileUiData)

  if (!snapshot.syncWithUserProfile && uiData) {
    syncSnapshotToUserProfileData(uiData)
    exportRoutesPreferencesAtom.syncWithUserProfile = true
  }

  return [snapshot.preferences, setExportRoutesPreferences] as const
}

function syncSnapshotToUserProfileData(uiData: uui.domain.server.rm.UserUISettings) {
  const {
    exportRoutes: { format, includeBarcodes, includePod },
  } = uiData

  setExportRoutesPreferences({ format, includeBarcodes, includePod })
}
