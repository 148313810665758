import styled from 'styled-components'

import { Props, ArrowButtonPosition } from './typings'

const getSize = ({ size = 28 }: Props) => size

const getBorderRadius = ({ buttonPosition }: Props) => {
  if (buttonPosition === ArrowButtonPosition.left) {
    return '6px 0 0 6px'
  }

  return '0 6px 6px 0'
}

const getMargins = ({ buttonPosition }: Props) => {
  if (buttonPosition === ArrowButtonPosition.left) {
    return '0 1px 0 0'
  }

  return '0 0 0 1px'
}

const styledComponent = styled.button.attrs<workwave.Button>(() => ({
  className: 'o-compact-date-selector__base-button',
  type: 'button',
}))`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  padding: 0;
  border: 0;
  margin: ${props => getMargins(props)};
  border-radius: ${props => getBorderRadius(props)};
  height: ${getSize}px;
  width: ${getSize}px;

  background: ${p => p.theme.colors.$silver};
  color: ${p => p.theme.colors.$pureWhite};

  font-size: ${p => p.theme.fonts.$m};
  font-weight: ${p => p.theme.weights.$bold};
  outline: 0;
  transition: 0.3s ease;
  transition-property: background, color;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
    background: ${p => p.theme.colors.$whiteSmoke};
    color: ${p => p.theme.colors.$pureWhite};
  }

  &:hover {
    background: ${p => p.theme.colors.$shadyLady};
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:disabled:hover {
    background: ${p => p.theme.colors.$silver};
  }
`

styledComponent.displayName = 'CompactDateSelectorArrowBaseButton'
export default styledComponent
