import styled from 'styled-components'

interface Props {
  size?: number
}

const defaultSize = 32

const AvatarContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${p => p.theme.colors.$pureWhite};
  overflow: hidden;
  height: ${p => p.size ?? defaultSize}px;
  width: ${p => p.size ?? defaultSize}px;
  font-size: ${p => p.theme.fonts.$p2};
  font-weight: ${p => p.theme.weights.$semiBold};
  border: 1px solid ${p => p.theme.colors.$pureWhite};
`

AvatarContainer.displayName = 'AvatarContainer'
export default AvatarContainer
