import { Component } from 'react'

import { ReadonlyLabel } from '@/forms-legacy'

import { TagListItem } from '../TagListItem'
import SmallLabel from '../SmallLabel'

import SuggestionsListContainer from './SuggestionsListContainer'

import { Props } from './typings'

interface State {
  useLimit: boolean
}

const setUseLimit = (useLimit: boolean) => () => ({
  useLimit,
})

export default class SuggestionsList extends Component<Props, State> {
  readonly limit: number = 7

  state = {
    useLimit: true,
  }

  handleOnToggleUseLimit = () => {
    const { useLimit } = this.state
    this.setState(setUseLimit(!useLimit))
  }

  render() {
    const { useLimit } = this.state
    const { list, onAddTag, renderText, filter } = this.props

    const moreCount: number = list.length - (this.limit + 1)
    const hasListItems: boolean = list.length > 0

    const showMore: boolean = useLimit && moreCount > 0

    return (
      <>
        {hasListItems && (
          <>
            <ReadonlyLabel style={{ marginTop: 10, marginBottom: 10 }}>
              {renderText('suggestedListTitle', this.props)}
            </ReadonlyLabel>
            <SuggestionsListContainer data-testid="tags__suggestions-list-container">
              {list.map((l: string, idx: number) => {
                if (useLimit && idx > this.limit) {
                  return null
                }
                return (
                  <TagListItem key={l} onClick={() => onAddTag(l)}>
                    {l}
                  </TagListItem>
                )
              })}
              {showMore && (
                <TagListItem onClick={this.handleOnToggleUseLimit}>
                  {renderText('loadMoreTag', this.props, undefined, moreCount)}
                </TagListItem>
              )}
            </SuggestionsListContainer>
            <SmallLabel>{renderText('suggestionsListMessage', this.props)}</SmallLabel>
          </>
        )}
        {!hasListItems && (
          <SmallLabel>
            {renderText('noResult', this.props, undefined, undefined, filter)}
          </SmallLabel>
        )}
      </>
    )
  }
}
