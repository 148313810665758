import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectGpsTags } from '@/features/domain/tags'
import { selectUserConfiguration } from '@/features/domain/user'

import { computeDeviceData } from './utils/computeDeviceData'
import { createEmptyDeviceData } from './utils/createEmptyDeviceData'

export function useDeviceData(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  allowEmptyDeviceData?: boolean,
): uui.domain.ui.forms.DeviceData {
  const allGpsTags = useSelector(selectGpsTags)
  const userConfig = useSelector(selectUserConfiguration)

  return useMemo<uui.domain.ui.forms.DeviceData>(() => {
    const metric = userConfig.distanceFormat === 'METRIC'

    if (!unifiedVehicle.isPhysicalDevice || unifiedVehicle.trackingProvider !== 'WWGPS') {
      if (allowEmptyDeviceData) return createEmptyDeviceData(metric)
      throw new Error('physical device not found')
    }

    const { device, driverBehaviors, speedMonitor, deviceConfiguration, idleStartThreshold } =
      unifiedVehicle

    const gpsTags = unifiedVehicle.allowGps ? unifiedVehicle.gpsTags : []

    return computeDeviceData({
      allGpsTags,
      device,
      userConfig,
      deviceConfiguration,
      driverBehaviors,
      gpsTags,
      idleStartThreshold,
      speedMonitor,
    })
  }, [allGpsTags, unifiedVehicle, userConfig, allowEmptyDeviceData])
}
