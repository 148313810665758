import styled from 'styled-components'

type Props = {
  alsoPaired?: boolean
}

const PairedOrderPanelButton = styled.button.attrs<Props>(() => ({
  className: 'o-paired-order-panel__button',
  type: 'button',
}))<Props>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 140px;
  height: 18px;

  font-size: ${p => p.theme.fonts.$s};
  font-weight: ${p => p.theme.weights.$semiBold};

  border-width: 0;
  border-radius: 4px;

  color: ${p => (p.alsoPaired ? p.theme.colors.$outrageousRed : p.theme.colors.$silver)};
  background: ${p => (p.alsoPaired ? p.theme.colors.$pureWhite : p.theme.colors.$nightRider)};

  outline: 0;
  cursor: pointer;
`
PairedOrderPanelButton.displayName = 'PairedOrderPanelButton'

export default PairedOrderPanelButton
