import { useCallback } from 'react'

import { fileUtils, trafficAreasUtils } from '@/utils'
import { useAppDispatch } from '@/store'
import { useCrudSelection } from '@/atoms'
import { importTrafficAreasFromKML } from '@/features/domain/traffic'
import { useNotification, useIsUnmounted } from '@/hooks'

import { useTexts } from './useTexts'

export function useOnImportKML(recurrence: string) {
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()
  const isUnmounted = useIsUnmounted()
  const [, setTrafficAreaSelection] = useCrudSelection('trafficArea')

  return useCallback(async () => {
    const file = await fileUtils.openFile()
    if (!file) return

    const readFileResult = await fileUtils.readFile(file, { asText: true })
    if (isUnmounted()) return

    if (readFileResult.type === 'withError') {
      toast.error(texts.importKMLFailed)
      return
    }

    try {
      const areas = trafficAreasUtils.KMLToTrafficRegions(readFileResult.file.content)
      const thunkResult = await dispatch(importTrafficAreasFromKML({ areas, recurrence }))

      if (importTrafficAreasFromKML.rejected.match(thunkResult)) {
        throw new Error(thunkResult.payload?.message ?? 'Internal error')
      }

      setTrafficAreaSelection(thunkResult.payload)
    } catch (e) {
      toast.error(texts.importKMLFailed)
    }
  }, [dispatch, toast, texts, recurrence, isUnmounted, setTrafficAreaSelection])
}
