import type { MapSelectionContext } from '../mapSelection'
import type { CrudSelection } from '@/atoms'

type CrudMapSelection = {
  depots: Set<string>
  devices: Set<string>
  driverAssignments: Set<string>
  drivers: Set<string>
  unifiedVehicles: Set<string>
  regions: Set<string>
  trafficAreas: Set<string>
  routingSegments: Set<string>
  simulations: Set<string>
  users: Set<string>
  // importOrders: Set<string>
  alerts: Set<string>
  geofences: Set<string>
  places: Set<string>
  reports: Set<string>
  deviceEvents: Set<string>
}

export function getMapCrudSelection(
  mapSelectionContext: MapSelectionContext,
  crudSelection: workwave.DeepReadonly<CrudSelection>,
): CrudMapSelection {
  const selection: CrudMapSelection = {
    depots: new Set(),
    devices: new Set(),
    driverAssignments: new Set(),
    drivers: new Set(),
    unifiedVehicles: new Set(),
    regions: new Set(),
    trafficAreas: new Set(),
    routingSegments: new Set(),
    simulations: new Set(),
    users: new Set(),
    alerts: new Set(),
    geofences: new Set(),
    places: new Set(),
    reports: new Set(),
    deviceEvents: new Set(),
  }

  if (mapSelectionContext.type !== 'crud') {
    return selection
  }

  switch (mapSelectionContext.category) {
    case 'depots':
    case 'places':
    case 'devices':
    case 'regions':
    case 'trafficArea':
    case 'deviceEvents':
    case 'trafficProfile':
    case 'unifiedVehicles':
    case 'routingSegments':
      // string[]
      selection[mapSelectionContext.category] = new Set(crudSelection[mapSelectionContext.category])
      break

    case 'reports':
    case 'geofences':
      // string[]
      selection[mapSelectionContext.category] = new Set(crudSelection[mapSelectionContext.category])
      break

    default:
    case 'alerts':
    case 'drivers':
    case 'simulations':
    case 'driverAssignments':
      break
  }

  return selection
}
