import { useCallback, useState } from 'react'

import { setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'maximizeRoutePaths' | 'showRoutePaths' | 'hideRoutePaths'

export const useRouteActions = (ids: string[]) => {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const maximizeRoutePaths = useCallback(async () => {
    setMethodInExecution('maximizeRoutePaths')

    await dispatch(
      setCustomMapStyle({
        customStyle: { type: 'order', mode: 'maximized', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const showRoutePaths = useCallback(async () => {
    setMethodInExecution('showRoutePaths')

    await dispatch(
      setCustomMapStyle({
        customStyle: { type: 'order', mode: 'on', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const hideRoutePaths = useCallback(async () => {
    setMethodInExecution('hideRoutePaths')

    await dispatch(
      setCustomMapStyle({
        customStyle: { type: 'order', mode: 'off', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  return { methodInExecution, actions: { maximizeRoutePaths, showRoutePaths, hideRoutePaths } }
}
