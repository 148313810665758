import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useResetEditingState, useDriverAssignmentEditMode } from '@/atoms'
import { useNotification } from '@/hooks'
import { cancelCreateLocalDriverAssignment } from '@/features/domain/driverAssignments'

export function useOnCancel(recurrence: string) {
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const [editMode, setEditMode] = useDriverAssignmentEditMode()
  const stopEditing = useResetEditingState()

  return useCallback(async () => {
    try {
      if (editMode === 'create') {
        const result = await dispatch(cancelCreateLocalDriverAssignment(recurrence))

        if (!cancelCreateLocalDriverAssignment.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      }

      setEditMode('reset')
      stopEditing()
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, toast, stopEditing, recurrence, editMode, setEditMode])
}
