import type { FormProps } from '../types'

import { Preferences } from '@/notificationForms'
import { usePreferencesProps } from './usePreferencesProps'

/**
 * Render the Preferences form form providing all the domain data and apis.
 */
export function PreferencesProvider(props: FormProps) {
  const { inEditing, setEditingState } = props
  const preferencesProps = usePreferencesProps(inEditing, setEditingState)

  return <Preferences {...preferencesProps} />
}
