import { useSelector } from 'react-redux'
import { type ExtendedPrivilege, useHasPrivileges } from '@/hooks'
import { selectTerritories } from '@/features/domain/territory'

export function useCanSeeTerritoryMenu(accountPrivileges: ExtendedPrivilege[]) {
  const territories = useSelector(selectTerritories)
  const hasMultipleTerritories = Object.keys(territories).length > 1

  const gotPrivileges = useHasPrivileges(accountPrivileges ?? [], false)

  if (gotPrivileges && hasMultipleTerritories) {
    return true
  }

  return false
}
