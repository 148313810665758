import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    newException: translate({ id: 'vehicles.form.exceptions.calendar.newException' }),
    current: translate({ id: 'vehicles.form.exceptions.opened' }),
    select: translate({ id: 'vehicles.form.exceptions.select' }),
  }))

  return api
}
