import { useCallback } from 'react'

import { ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useControllerActions } from '../../../hooks/useControllerActions'
import { useModalData } from '../../../hooks/useModalData'
import { useTexts } from '../../../useTexts'

import { useButtonClassName } from './hooks/useButtonClassName'

export function ButtonMove() {
  const ctrl = useController()
  const { move } = useControllerActions()
  const data = useModalData()
  const texts = useTexts()

  const { type, suffix } = useButtonClassName(data)

  const className = `pendo-moving-${type}_move-${type}${suffix}`
  const testid = `moving-${type}_move-${type}${suffix}`

  const handleOnClick = useCallback(() => {
    move(true)
  }, [move])

  return (
    <ModalFooterButton
      disabled={ctrl.status === 'pending'}
      onClick={handleOnClick}
      className={className}
      testid={testid}
      variant="contained"
    >
      {texts.btnMove(data.orderStep.type, data.pairedOrderStepUnAssigned)}
    </ModalFooterButton>
  )
}
