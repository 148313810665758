import { useSingleEditSimulationActions } from './hooks/useSingleEditSimulationActions'
import { useSingleEditSimulationData } from './hooks/useSingleEditSimulationData'
import { SingleEdit } from './SingleEdit'

type Props = {
  simulation?: uui.domain.client.rm.SimulationInfo
}

// REACT ERROR WHEN CREATING A ASIMULATION
const servedOrders: Map<string, string> = new Map()

export function SingleEditProvider(props: Props) {
  const { simulation } = props

  const data = useSingleEditSimulationData(simulation)
  const { actions, submitting } = useSingleEditSimulationActions()

  return (
    <SingleEdit
      today={data.today}
      simulation={simulation}
      submitting={submitting}
      servedOrders={servedOrders}
      readonly={data.readonly}
      stopEditing={actions.stopEditing}
      simulationsNames={data.simulationNames}
      calendarSelection={data.calendarSelection}
      updateSimulation={actions.onUpdateSimulation}
      createSimulation={actions.onCreateSimulation}
      firstDayNotArchived={data.firstDayNotArchived}
      maxIntervalLength={data.licensingLimits.horizonView}
      isSelectingArchivedDate={data.isSelectingArchivedDate}
    />
  )
}
