import { type ResourceModel } from '@bryntum/schedulerpro'
import { useMemo } from 'react'

import { durationUtils } from '@/server-data'
import { useIntl } from '@/intl'

import { useTexts } from '../useTexts'

export function useAggregateData(record: ResourceModel) {
  const { translate } = useIntl()
  const texts = useTexts()

  const formatDuration = durationUtils.formatSeconds(translate)

  const routeRemainingDrivingTime = record.get(
    'routeRemainingDrivingTime',
  ) as uui.domain.client.rm.SchedulerResource['routeRemainingDrivingTime']

  const routeRemainingWorkingTime = record.get(
    'routeRemainingWorkingTime',
  ) as uui.domain.client.rm.SchedulerResource['routeRemainingWorkingTime']

  const routeTotalWorkingTime = record.get(
    'routeTotalWorkingTime',
  ) as uui.domain.client.rm.SchedulerResource['routeTotalWorkingTime']

  const maxVehicleDrivingTime = record.get(
    'maxVehicleDrivingTime',
  ) as uui.domain.client.rm.SchedulerResource['maxVehicleDrivingTime']

  return useMemo(() => {
    return {
      workingTime: {
        value: formatDuration(routeRemainingWorkingTime, 'DURATION_HH_MM'),
        total:
          routeTotalWorkingTime > 0
            ? formatDuration(routeTotalWorkingTime, 'DURATION_HH_MM')
            : texts.unlimited,
        saturation:
          routeTotalWorkingTime > 0
            ? parseFloat(((routeRemainingWorkingTime / routeTotalWorkingTime) * 100).toFixed(2))
            : 0,
      },
      drivingTime: {
        value: formatDuration(routeRemainingDrivingTime, 'DURATION_HH_MM'),
        total:
          maxVehicleDrivingTime > 0
            ? formatDuration(maxVehicleDrivingTime, 'DURATION_HH_MM')
            : texts.unlimited,
        saturation:
          maxVehicleDrivingTime > 0
            ? parseFloat(((routeRemainingDrivingTime / maxVehicleDrivingTime) * 100).toFixed(2))
            : 0,
      },
    }
  }, [
    routeRemainingDrivingTime,
    routeRemainingWorkingTime,
    routeTotalWorkingTime,
    maxVehicleDrivingTime,
    formatDuration,
    texts,
  ])
}
