import { useCallback } from 'react'
import { setInspectPin, useMapSelectionMode } from '@/map'

export const useActions = (setOpen: (open: boolean) => void) => {
  const [mode, setMode] = useMapSelectionMode()

  const toggleLassoMode = useCallback(() => {
    // lasso and inspect pin are mutually exclusive
    setInspectPin('reset')

    setMode(mode !== 'normal' ? 'normal' : 'lasso')
    setOpen(false)
  }, [mode, setMode, setOpen])

  const toggleLassoAdditiveMode = useCallback(() => {
    // lasso and inspect pin are mutually exclusive
    setInspectPin('reset')

    setMode(mode !== 'normal' ? 'normal' : 'lassoAdditive')
    setOpen(false)
  }, [mode, setMode, setOpen])

  const toggleLassoSubtractiveMode = useCallback(() => {
    // lasso and inspect pin are mutually exclusive
    setInspectPin('reset')

    setMode(mode !== 'normal' ? 'normal' : 'lassoSubtractive')
    setOpen(false)
  }, [mode, setMode, setOpen])

  return {
    toggleLassoMode,
    toggleLassoAdditiveMode,
    toggleLassoSubtractiveMode,
  }
}
