import { CircularProgress } from '@mui/material'

import { NestedDropdown, DropdownMenuItem } from '@/components/DropdownMenu'
import { StrikedEye } from '@/icons'

import { ActiveIcon } from '../ActiveIcon'

import { useOrdersMapStyle } from './hooks/useOrdersMapStyle'
import { useHasOrders } from './hooks/useHasOrders'
import { useActions } from './hooks/useActions'
import { useTexts } from './useTexts'
interface Props {
  parentOpen: boolean
}

export function OrdersSubMenu(props: Props) {
  const { parentOpen } = props

  const {
    methodInExecution,
    actions: { maximizeOrders, showOrders, hideOrders },
  } = useActions()
  const activeStyle = useOrdersMapStyle()
  const hasOrders = useHasOrders()
  const texts = useTexts()
  const tooltipTitle = hasOrders === true ? undefined : texts.noOrders

  return (
    <NestedDropdown
      placement="left"
      disabled={!hasOrders}
      parentOpen={parentOpen}
      tooltipTitle={tooltipTitle}
      triggerContent={texts.orders}
      Icon={activeStyle === 'off' ? <StrikedEye size={12} color="$silver" /> : undefined}
    >
      <DropdownMenuItem
        external
        onClick={maximizeOrders}
        Icon={
          methodInExecution === 'maximizeOrders' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'maximized'} />
          )
        }
      >
        {texts.maximized}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={showOrders}
        Icon={
          methodInExecution === 'showOrders' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'on'} />
          )
        }
      >
        {texts.on}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={hideOrders}
        Icon={
          methodInExecution === 'hideOrders' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'off'} />
          )
        }
      >
        {texts.off}
      </DropdownMenuItem>
    </NestedDropdown>
  )
}
