import { useNavigate as useRouterNavigate } from 'react-router'
import { useCallback } from 'react'

import { generateAbsolutePath } from '../utils/generateAbsolutePath'

export type ControlledNavigateFunction<ACCEPTED_ROUTES> = (
  to: ACCEPTED_ROUTES,
  options?: {
    replace?: boolean
    state?: Record<string, any> | null
  },
) => void

/**
 * Adds type safety to the `useNavigate` hook.
 */
export const useNavigate = <ACCEPTED_ROUTES extends string = string>(
  absolute?: boolean,
): ControlledNavigateFunction<ACCEPTED_ROUTES> => {
  const navigate = useRouterNavigate()
  const navigateCallback = useCallback<ControlledNavigateFunction<ACCEPTED_ROUTES>>(
    (to, options) => {
      navigate(absolute ? generateAbsolutePath(to) : to, options)
    },
    [navigate, absolute],
  )
  return navigateCallback
}
