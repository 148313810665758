import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../renderingQueue'
import { mapAtom } from '../../atoms/map/map'
import { setLayerMetadata } from '../layerMetadata'
import { findLayer } from '../utils/findLayer'

import { createGeofenceFeature } from './utils/createGeofenceFeature'

const nonblockingRender = createNonBlockingRenderByTime()

export async function initializeGeofenceFeatures(
  markers: uui.domain.ui.map.markers.MapMarkers['geofence'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['geofence'],
  selection: Set<string>,
) {
  const map = mapAtom.map
  const layer = findLayer(map, 'geofence')

  const features: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const marker of Object.values(markers)) {
    await nonblockingRender.next()

    const selected = selection.has(marker.id)
    features.push(createGeofenceFeature(marker, mapStyles, selected))
  }

  // add all new features to the layer
  layer.getSource()?.addFeatures(features)

  // keep track of selected items in the current layer
  setLayerMetadata(layer, 'selection', new Set(selection))
}
