import { useSelector } from 'react-redux'

import { selectCustomerNotificationsSettings } from '@/features/domain/territory'

export type CompletedNotificationValues = ReturnType<typeof useCompletedNotificationValues>

/**
 * That hook grabs completedNotification values from the domain and returns them
 */
export function useCompletedNotificationValues() {
  const notificationSettings = useSelector(selectCustomerNotificationsSettings)

  return notificationSettings['completedSettings']
}
