import { Grow } from '@/components/layout'

import { useOrderStepsNavigator } from '../../hooks/useOrderStepsNavigator'
import { Bulk } from './Bulk'
import { Single } from './Single'

const growStyle = { height: 42 }

export function Stats() {
  const { currentOrders } = useOrderStepsNavigator()
  if (currentOrders.length === 0) return null

  return (
    <Grow
      xAxis
      style={growStyle}
      data-trackid="navigo-order-overview-stats"
      data-testid="navigo-order-overview-stats"
    >
      {currentOrders.length > 1 ? (
        <Bulk extOrders={currentOrders} />
      ) : (
        <Single extOrder={currentOrders[0]} />
      )}
    </Grow>
  )
}
