import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    blocked: translate({ id: 'setup.routing.edit.type.options.blocked' }),
    delayed: translate({ id: 'setup.routing.edit.type.options.delayed' }),
    disabled: translate({ id: 'setup.routing.edit.type.options.disabled' }),
    label: translate({ id: 'setup.routing.edit.labels.type' }),
    min: translate({ id: 'setup.routing.edit.labels.min' }),
  }))

  return api
}
