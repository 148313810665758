import {
  parse,
  format,
  getHours,
  addMinutes,
  getMinutes,
  addSeconds,
  getSeconds,
  startOfToday,
} from 'date-fns/esm'

import { isValidStringDate } from '../time'

import { createDateFnsOptions } from './dateFnsOptions'
import { getTimeFormatWithSeconds } from './conversion'
/**
 * @param date  Can be a DateString 'yyyyMMdd' or a javascript date
 * @param short Boolean, if set to true will output a short notation of the string
 */
export const formatDate =
  (userConfig: uui.domain.UserConfiguration) =>
  (source: string | Date, shortFormat: boolean = false): string => {
    const { dateFormatLong, dateFormatShort } = userConfig
    const options = createDateFnsOptions(userConfig)

    let date: Date

    if (typeof source === 'string') {
      if (!isValidStringDate(source)) {
        if (process.env.NODE_ENV === 'development') {
          // JOURNAL:
          console.error(`[formatDate] failed, ${source}`)
        }

        return ''
      }

      date = parse(source, 'yyyyMMdd', new Date())
    } else {
      date = source
    }

    return format(date, shortFormat ? dateFormatShort : dateFormatLong, options)
  }

export const formatDateTime =
  (userConfig: uui.domain.UserConfiguration) =>
  (source: Date, excludeTime: boolean = false, showSeconds = false): string => {
    const { dateFormatLong, timeFormat } = userConfig

    const options = createDateFnsOptions(userConfig)

    const tf = showSeconds ? getTimeFormatWithSeconds(timeFormat) : timeFormat

    return format(source, excludeTime ? dateFormatLong : `${dateFormatLong} ${tf}`, options)
  }

export function formatTime(userConfig: uui.domain.UserConfiguration) {
  return (source: Date, showSeconds = false): string => {
    const { timeFormat } = userConfig

    const options = createDateFnsOptions(userConfig)

    const tf = showSeconds ? getTimeFormatWithSeconds(timeFormat) : timeFormat

    return format(source, tf, options)
  }
}

export function formatHour(date: Date, timeFormat: uui.domain.client.TimeFormat): string {
  return format(date, timeFormat)
}

export function formatMinutesFromMidnight(
  minutes: number,
  timeFormat: uui.domain.client.TimeFormat,
) {
  return formatHour(addMinutes(startOfToday(), minutes), timeFormat)
}

export function formatSecondsFromMidnight(
  seconds: number,
  timeFormat: uui.domain.client.TimeFormat,
) {
  return formatHour(addSeconds(startOfToday(), seconds), timeFormat)
}

export interface ExplodedTime {
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

export const explodeSeconds = (seconds: number): ExplodedTime => explodeTime(seconds * 1000)

export const explodeTime = (duration: number): ExplodedTime => {
  let milliseconds: number = duration
  const hours: number = Math.floor(milliseconds / (60 * 60 * 1000))
  milliseconds %= 60 * 60 * 1000
  const minutes: number = Math.floor(milliseconds / (60 * 1000))
  milliseconds %= 60 * 1000
  const seconds: number = Math.floor(milliseconds / 1000)
  milliseconds %= 1000
  return { hours, minutes, seconds, milliseconds }
}

export const getTimeAsSecondsFromDate = (date: Date): number =>
  getHours(date) * 3600 + getMinutes(date) * 60 + getSeconds(date)
