import type Geometry from 'ol/geom/Geometry'

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { setPlaceFeatureMetadata } from '../placeFeatureMetadata'
import { getPlaceFeatureStyle } from './getPlaceFeatureStyle'
import { getPlaceFeatureLabelStyle } from './getPlaceFeatureLabelStyle'

export const createPlaceFeature = (
  marker: uui.domain.ui.map.markers.Place,
  mapStyles: uui.domain.ui.map.markers.MapStyles['place'],
  selected: boolean = false,
): Feature<Geometry> => {
  const { lonLat, id } = marker

  const feature = new Feature({ geometry: new Point(lonLat), label: new Point(lonLat) })

  // use marker ID (placeId) as unique ID
  feature.setId(id)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setPlaceFeatureMetadata(feature, 'type', 'place')

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['place']['mode'] = marker.mode ?? mapStyles.mode
  setPlaceFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle([
    getPlaceFeatureStyle(marker, mode, selected),
    getPlaceFeatureLabelStyle(marker, mode),
  ])

  return feature
}
