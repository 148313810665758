import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    cancelButtonTitle: translate({
      id: 'settings.integrations.editTenantSourceLabel.cancelButtonTitle',
    }),
    saveButtonTitle: translate({
      id: 'settings.integrations.editTenantSourceLabel.saveButtonTitle',
    }),
    errors: {
      labelRequired: translate({
        id: 'settings.integrations.editTenantSourceLabel.errors.labelRequired',
      }),
      labelUnique: translate({
        id: 'settings.integrations.editTenantSourceLabel.errors.labelUnique',
      }),
    },
    connectionNameLabel: translate({
      id: 'settings.integrations.editTenantSourceLabel.connectionNameLabel',
    }),
    headerTitle: translate({
      id: 'settings.integrations.editTenantSourceLabel.headerTitle',
    }),
    successToast: translate({
      id: 'settings.integrations.editTenantSourceLabel.successToast',
    }),
  }))

  return api
}
