import type { CSSProperties } from 'react'

import { DrivingTimeSliderField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

export function SpeedFactor() {
  const texts = useTexts()

  return (
    <DrivingTimeSliderField
      name="speedFactor"
      testid="speedFactor"
      label={texts.speedFactor}
      wrappedInputProps={wrappedInputProps}
    />
  )
}

const steps = [
  {
    label: '0.5x',
    value: 0,
  },
  {
    label: '1x',
    value: 50,
  },
  {
    label: '2x',
    value: 150,
  },
]

function getCoordinatesStyle(val: number, max: number): CSSProperties {
  return {
    left: `${(Math.abs(val) / max) * 100}%`,
  }
}

function getBadgeText(val: number) {
  return `${(val + 50) / 100}x`
}

const wrappedInputProps = {
  min: 0,
  max: 150,
  steps,
  scaleMax: 150,
  getBadgeText,
  getCoordinatesStyle,
}
