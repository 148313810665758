import type { RouteStep, Route } from '../../typings'

export const isInProgress = (step: RouteStep, route: Route): boolean => {
  const { edge } = route
  if (!edge) return false

  const { timeSec } = edge
  const { startSec, endSec } = step

  return startSec <= timeSec && timeSec < endSec
}
