import { useController } from '../../hooks/useController'
import { Azuga } from './components/Azuga'
import { Geotab } from './components/Geotab'
import { Linxup } from './components/Linxup'
import { VerizonConnect } from './components/VerizonConnect'

interface Props {
  source: uui.domain.server.gps.telematics.Source
}

export function Header(props: Props) {
  const { source } = props
  const { close } = useController()

  switch (source.label) {
    case 'Azuga':
      return <Azuga source={source} close={close} />

    case 'Linxup':
      return <Linxup source={source} close={close} />

    case 'Verizon Connect':
    case 'Verizon Connect (EU)':
    case 'Verizon Connect (US)':
      return <VerizonConnect source={source} close={close} />

    case 'Geotab':
      return <Geotab source={source} close={close} />

    default:
      return null
  }
}
