import { FooterMessage, FooterText, HorizontalRuler } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'
import { Badges } from './fields/Badges'
import { Braking } from './fields/Braking'
import { Acceleration } from './fields/Acceleration'
import { Cornering } from './fields/Cornering'
import { IdleStart } from './fields/IdleStart'
import { SpeedLimit } from './fields/SpeedLimit'
import { MaxSpeedLimit } from './fields/MaxSpeedLimit'
import { MaintenanceMileage } from './fields/MaintenanceMileage'

interface Props {
  values: uui.domain.ui.forms.FormWithDeviceData
}

export function GpsData(props: Props) {
  const { values } = props

  const texts = useTexts()

  const showSpeedLimitField = values.device.speedLimit !== null
  const hasIdleThresholdCapability = values.device.idleStart.enabled
  const hasDriverBehaviorCapability = values.device.hasDriverBehaviorCapability
  const showMaintenanceMileageField = values.device.maintenanceMileage !== null

  return (
    <>
      <Badges />
      {hasDriverBehaviorCapability && (
        <>
          <FooterMessage quote alignedWithForm>
            <FooterText>{texts.warning}</FooterText>
          </FooterMessage>

          <Braking />
          <Acceleration />
          <Cornering />

          <HorizontalRuler />
        </>
      )}

      {hasIdleThresholdCapability && (
        <>
          <IdleStart showCustomField={values.device.idleStart.preset === 'custom'} />
          <HorizontalRuler />
        </>
      )}

      {showSpeedLimitField && (
        <SpeedLimit showToleranceField={values.device.speedLimit?.enabled === 'enabled'} />
      )}

      {hasDriverBehaviorCapability && (
        <>
          <MaxSpeedLimit defaultValue={values.device.maxSpeedLimit.max} />
          <HorizontalRuler />
        </>
      )}
      {showMaintenanceMileageField && (
        <>
          <MaintenanceMileage />
          <HorizontalRuler />
        </>
      )}
    </>
  )
}
