import { fileUtils } from '@/utils'
import { journal } from '@/server-data'

export interface ImportedTerritory {
  archived: uui.domain.server.rm.RoutePlan[]
  simulations: uui.domain.server.rm.RoutePlan[]
  operations: uui.domain.server.rm.RoutePlan & { lastUpdateDate?: string }
  territory: uui.domain.server.rm.BaseTerritory
}

interface ImportedTerritories {
  territories: ImportedTerritory[]
}

/**
 * Extract the territory from the passed file.
 */
export const parseFileContent = (file: fileUtils.ParsedFile) => {
  try {
    const territories: ImportedTerritories = JSON.parse(file.content)

    if (territories.territories.length > 0) {
      const territory = territories.territories[0]
      territory.archived.forEach(mutablePlan => (mutablePlan.type = 'archived'))
      territory.simulations.forEach(mutablePlan => (mutablePlan.type = 'simulation'))
      territory.operations.type = 'operations'

      return territory
    }
  } catch (error) {
    journal.main(`Parse file failed`, { tags: ['importTerritory'], info: error }, 'error')
  }
}
