import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function MobileGear(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M4.558 1C3.698 1 3 1.698 3 2.558v10.598c0 .86.698 1.558 1.558 1.558h5.61c.861 0 1.56-.698 1.56-1.558v-1.559H4.246V2.87h7.48v-.312c0-.86-.698-1.558-1.558-1.558h-5.61zm6.067 3.117c-.08 0-.147.06-.157.14l-.073.63a2.473 2.473 0 00-.81.47L9 5.102a.158.158 0 00-.2.067l-.584 1.01a.158.158 0 00.043.206l.503.373c-.03.155-.048.313-.048.475 0 .162.018.32.048.473l-.502.374a.158.158 0 00-.043.206l.584 1.01c.04.07.126.098.2.066l.582-.252c.237.205.51.364.811.469l.073.63c.01.081.077.14.157.14h1.167c.08 0 .148-.06.157-.14l.073-.63c.302-.105.574-.264.81-.469l.585.253a.157.157 0 00.199-.067l.584-1.01a.158.158 0 00-.043-.206l-.502-.374c.03-.154.047-.311.047-.473 0-.162-.017-.32-.047-.474l.501-.374a.158.158 0 00.043-.205l-.583-1.011a.158.158 0 00-.2-.066l-.583.252a2.477 2.477 0 00-.811-.468l-.073-.631a.157.157 0 00-.157-.14h-1.167zm.584 2.026a1.09 1.09 0 110 2.182 1.09 1.09 0 010-2.182zm-3.845 6a.7.7 0 110 1.401.7.7 0 010-1.401z" />
      </svg>
    </IconContainer>
  )
}
