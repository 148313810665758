import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Offline(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M2.47119 1.52869L1.52848 2.47139L3.479 4.42192C2.534 4.90214 1.67079 5.51973 0.928223 6.26176L2.2238 7.55733C3.0174 6.84521 3.94585 6.28644 4.95947 5.90238L6.01025 6.95317C4.94538 7.25539 3.97538 7.78625 3.1652 8.49874L4.57926 9.9128C5.443 9.1894 6.53853 8.73767 7.73551 8.67843L9.27067 10.2136C8.871 10.0782 8.44492 10 7.99984 10C7.0665 10 6.20982 10.3247 5.52848 10.862L7.99984 13.3334L10.1951 11.1381L13.5285 14.4714L14.4712 13.5287L2.47119 1.52869ZM7.99984 3.33337C7.43117 3.33337 6.87781 3.39178 6.33447 3.48311L8.19254 5.34249C10.3359 5.39049 12.2859 6.22 13.7759 7.55733L15.0701 6.26306C13.2608 4.45306 10.7612 3.33337 7.99984 3.33337ZM9.73421 6.88285L12.0923 9.24093L12.8332 8.50004C11.9585 7.73004 10.9002 7.16885 9.73421 6.88285Z" />
      </svg>
    </IconContainer>
  )
}
