import { proxy, useSnapshot } from 'valtio'

type State = Record<string, boolean>

const initialValue = {} as const
const collapsedStateAtom = proxy<State>(initialValue)

export function setCollapsedState(family: string, collapsed: boolean) {
  collapsedStateAtom[family] = collapsed
}

export function resetCollapsedState(family: string) {
  collapsedStateAtom[family] = true
}

export const useCollapsedState = (family: string) => {
  return useSnapshot(collapsedStateAtom)[family] ?? true
}
