import type { Option } from './types'
import type { SelectChangeEvent } from '@mui/material'

import { useCallback } from 'react'
import { Select as MuiSelect, FormControl, InputLabel, MenuItem } from '@mui/material'

import { useOptions } from './hooks/useOptions'

type EligibilityType = uui.domain.client.rm.Eligibility['type']

interface Props {
  label: string
  value: EligibilityType | 'mixed'
  onChange: (value: EligibilityType) => void
  disabled?: boolean
  error?: boolean
}

const name = 'eligibilityTypePicker'

export function EligibilityTypePicker(props: Props) {
  const { value, label, onChange: apiChange, disabled, error } = props

  const options = useOptions(value === 'mixed')

  const onChange = useCallback(
    (event: SelectChangeEvent<EligibilityType>) => {
      const value = event.target.value as EligibilityType
      apiChange(value)
    },
    [apiChange],
  )

  return (
    <FormControl size="small" error={error} data-testid="eligibility-type-picker_root">
      <InputLabel id={name}>{label}</InputLabel>
      <MuiSelect labelId={name} label={label} value={value} onChange={onChange} disabled={disabled}>
        {options.map(renderOptions)}
      </MuiSelect>
    </FormControl>
  )
}

function renderOptions(option: Option) {
  return (
    <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
      {option.label}
    </MenuItem>
  )
}
