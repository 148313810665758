import { Fill, Style, Text } from 'ol/style'

// -----------------------------------
// Label attributes
const size = '14px'
const height = 1
const weight = '400'
const fill = new Fill({ color: '#000000' })
const backgroundFill = new Fill({ color: '#ffffff' })

export const genericStyles = {
  hidden: new Style(),

  label: new Style({
    geometry: 'label',
    text: new Text({
      fill,
      offsetX: 0,
      rotation: 0,
      backgroundFill,
      overflow: false,
      placement: 'point',
      textAlign: 'center',
      padding: [3, 5, 1, 8],
      textBaseline: 'middle',
      font: `${weight} ${size}/${height} "Roboto"`,
    }),
  }),

  idx: new Style({
    text: new Text({
      offsetX: 0,
      offsetY: 1,
      rotation: 0,
      overflow: false,
      placement: 'point',
      textAlign: 'center',
      padding: [3, 5, 1, 8],
      textBaseline: 'middle',
      font: `700 13px/1 "Roboto"`,
      backgroundFill: new Fill({ color: 'transparent' }),
    }),
  }),
}
