import type { PartialStoreState } from '../typings'
import { createSelector } from '@reduxjs/toolkit'

import { selectTelematicsDevices, selectTelematicsDevicesAsArray } from '../device'

export const selectTelematicsSources = (state: PartialStoreState) => {
  return state.domain.publicData.domain.gps.telematics.sources
}

export const selectTelematicsTenantSources = (state: PartialStoreState) => {
  return state.domain.publicData.domain.gps.telematics.tenantSources
}

export const selectTelematicsSourcesAsArray = createSelector(selectTelematicsSources, sources =>
  Object.values(sources),
)

export const selectTelematicsTenantSourcesAsArray = createSelector(
  selectTelematicsTenantSources,
  tenantSources => Object.values(tenantSources),
)

export const selectTelematicsDeviceTransientStatuses = (state: PartialStoreState) => {
  return state.domain.publicData.domain.gps.telematics.deviceTransientStatuses
}

export const selectTotalTelematicsLicenses = (state: PartialStoreState) => {
  return state.domain.publicData.profile.account.licensingLimits.telematicsLicensesCount ?? 0
}

export const selectTotalTelematicsDemoEnable = (state: PartialStoreState) => {
  return state.domain.publicData.profile.account.licensingLimits.telematicsDemoEnable ?? false
}

export const selectUsedTelematicsLicenses = createSelector(
  selectTelematicsDevicesAsArray,
  selectTelematicsDeviceTransientStatuses,
  (telematicsDevices, deviceTransientStatuses) => {
    const activeOrPendingDevices = new Set<string>()

    // Add active devices to the list
    for (const device of telematicsDevices) {
      if (device.active) {
        activeOrPendingDevices.add(device.deviceId)
      }
    }

    // Add devices with pending status to the list
    for (const [deviceId, { status }] of Object.entries(deviceTransientStatuses)) {
      switch (status) {
        case 'PENDING-ACTIVATION':
          activeOrPendingDevices.add(deviceId)
          break
        case 'PENDING-DEACTIVATION':
          activeOrPendingDevices.delete(deviceId)
          break
      }
    }

    return activeOrPendingDevices.size
  },
)

export const selectUsedTelematicsLicensesByTenantSource = createSelector(
  selectTelematicsTenantSourcesAsArray,
  selectTelematicsDevices,
  (tenantSources, telematicsDevices) => {
    return tenantSources.reduce((acc, tenantSource) => {
      const activeDevices = Object.values(telematicsDevices).filter(
        device => device.active && device.tenantSourceId === tenantSource.id,
      )

      acc[tenantSource.id] = activeDevices.length

      return acc
    }, {} as Record<string, number>)
  },
)

export const selectRemainingTelematicsLicenses = createSelector(
  selectTotalTelematicsLicenses,
  selectUsedTelematicsLicenses,
  (total, used) => {
    return total - used
  },
)
