import type { CalendarClickType, DateListCalendarProps as Props, YYYYMMDD } from '../../typings'

import { useCallback } from 'react'

import { HorizontalLayout, VerticalLayout } from '@/components/layout'

import { Month } from '../../components/Month'
import { MonthNavigator } from '../../components/MonthNavigator'
import { useMonths } from '../../hooks/useMonths'
import { useMonthNavigatorProps } from '../../hooks/useMonthNavigatorProps'
import { parseDate } from '../../utils/parseDate'

import { useSelectionData } from './useSelectionData'
import { useDatesDays } from './useDatesDays'

export function Dates(props: Props) {
  const {
    today,
    minDate,
    maxDate,
    dotColor,
    onChange,
    selection,
    disabledDays,
    referenceDate,
    highlightedDays,
    setReferenceDate,
    firstNonArchivedDay,
    showNeighboringMonth,
  } = props

  const months = useMonths(referenceDate, showNeighboringMonth, 1, minDate, maxDate)

  const selectionData = useSelectionData(selection)

  const daysByMonth = useDatesDays({
    selection: selectionData,
    today,
    firstNonArchivedDay,
    highlightedDays,
    disabledDays,
    months,
  })

  const monthNavigatorProps = useMonthNavigatorProps({
    referenceDate,
    setReferenceDate,
    months: 1,
    minDateAsString: minDate,
    maxDateAsString: maxDate,
    labelFormat: 'month-year',
  })

  const handleOnClick = useCallback(
    (day?: YYYYMMDD, type: CalendarClickType = 'click') => {
      if (day) {
        const dayDate = parseDate(day)
        onChange(dayDate, selection, type)
      }
    },
    [onChange, selection],
  )

  return (
    <VerticalLayout height="auto" alignItems="center">
      <MonthNavigator {...monthNavigatorProps} />
      <HorizontalLayout width="auto">
        {months.map(
          (month, index) =>
            daysByMonth[month.id] && (
              <Month
                index={index}
                key={month.id}
                showDaysOfWeek
                dotColor={dotColor}
                onClick={handleOnClick}
                days={daysByMonth[month.id]}
              />
            ),
        )}
      </HorizontalLayout>
    </VerticalLayout>
  )
}
