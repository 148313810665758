import { useNavigator as useCoreNavigator } from '../../components/Navigator/hooks/useNavigator'
import { useBreadcrumbsData } from './hooks/useBreadcrumbsData'

import { BreadcrumbsLoading } from './BreadcrumbsLoading'
import { Breadcrumbs } from './Breadcrumbs'

export function BreadcrumbsProvider() {
  const breadcrumbData = useBreadcrumbsData()
  const { ids } = useCoreNavigator()

  if (ids.length !== 1) return null

  // If data are not yet ready return a skeleton
  if (!breadcrumbData) return <BreadcrumbsLoading />

  return <Breadcrumbs breadcrumbData={breadcrumbData} id={ids[0]} />
}
