import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function User(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M30.9336 32.52734c-.14844-1.61328-.08985-2.73828-.08985-4.21093.73047-.3828 2.03906-2.8242 2.2578-4.8867.57423-.0469 1.48048-.60545 1.7461-2.8164.14063-1.1875-.42578-1.85546-.77343-2.0664.9336-2.8086 2.875-11.5-3.58594-12.39843-.66797-1.16797-2.3711-1.7578-4.58203-1.7578-8.85547.16405-9.92188 6.68748-7.98047 14.15623-.34765.21093-.91406.8789-.77344 2.0664.26954 2.21094 1.17188 2.76953 1.7461 2.8164.21875 2.0625 1.57812 4.5039 2.3125 4.8867 0 1.47267.05468 2.59767-.09375 4.21095C19.3672 37.23828 7.54683 35.91405 7 45h38c-.54688-9.08594-12.3164-7.76172-14.0664-12.47266z" />
      </svg>
    </IconContainer>
  )
}
