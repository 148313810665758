interface TileInfo {
  base: string
  type: string
  scheme: string
  format: string
  ppi: number
  tileSize: number
  lng: string
}

const commonTileInfo: TileInfo = {
  base: '',
  type: 'maptile',
  scheme: '',
  format: 'png8',
  ppi: 400, // 100 / 400
  tileSize: 512, // set it douible of the exposed size for OpenLayers
  lng: 'en',
}

const composeUrl = (i: TileInfo) => {
  return `https://${i.base}/v3/base/mc/{z}/{x}/{y}/${i.format}?size=${i.tileSize}&ppi=${i.ppi}&lang=${i.lng}&style=${i.scheme}&features=pois:disabled`
}

const getNormalTile = (lng: string = 'en') => {
  return composeUrl({
    ...commonTileInfo,
    lng,
    base: 'maps.hereapi.com',
    scheme: 'explore.day',
    format: 'png',
  })
}

const getHybridTile = (lng: string = 'en') => {
  return composeUrl({
    ...commonTileInfo,
    lng,
    base: 'maps.hereapi.com',
    scheme: 'explore.satellite.day',
    format: 'png',
  })
}

type Params = {
  tileType: 'normal' | 'hybrid'
  lng?: string
}

export const getTileUrl = (params: Params) => {
  const { tileType, lng = 'en' } = params

  switch (tileType) {
    case 'hybrid':
      return getHybridTile(lng)

    case 'normal':
    default:
      return getNormalTile(lng)
  }
}

export function getLiveTrafficTileUrl() {
  return `https://traffic.vector.hereapi.com/v2/traffictiles/flow/mc/{z}/{x}/{y}/omv?`
}
