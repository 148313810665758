import { convertKilometersToMiles } from '@/local/server-data/utils/conversion'

export function patchSpeedMonitor(
  mutableDraft: uui.domain.client.gps.wwgps.SpeedMonitor | undefined,
  formValues: uui.domain.ui.forms.DeviceData,
  deviceId: string,
) {
  const { speedLimit } = formValues

  if (!mutableDraft && !!speedLimit) {
    return computeSpeedLimitFromFormValues(speedLimit, deviceId)
  }

  if (!speedLimit || !mutableDraft) return

  const enabled = speedLimit.enabled === 'enabled'

  mutableDraft.relativeThreshold = computeRelativeThreshold(speedLimit)

  mutableDraft.disabled = !enabled
  mutableDraft.units = 'miles_per_hour'

  return mutableDraft
}

function computeSpeedLimitFromFormValues(
  speedLimit: uui.domain.ui.forms.SpeedLimitFormData,
  deviceId: string,
): uui.domain.client.gps.wwgps.SpeedMonitor {
  return {
    deviceId,
    disabled: speedLimit.enabled === 'disabled',
    relativeThreshold: computeRelativeThreshold(speedLimit),
    units: 'miles_per_hour',
    absoluteThreshold: null,
  }
}

function computeRelativeThreshold(speedLimit: uui.domain.ui.forms.SpeedLimitFormData) {
  const enabled = speedLimit.enabled === 'enabled'

  return enabled
    ? speedLimit.metric
      ? parseFloat(convertKilometersToMiles(speedLimit.threshold).toFixed(2))
      : speedLimit.threshold
    : null
}
