import type { FormFields } from '../formFields'
import { TextField } from '@workwave-tidal/tidal/form-ui'
import { useTexts } from '../hooks/useTexts'

export function Email() {
  const texts = useTexts()

  return (
    <TextField<'email', FormFields>
      name="email"
      testId="email"
      label={texts.labels.email}
      placeholder={texts.placeholders.email}
      validateOn="change blur"
      required
    />
  )
}
