export function hasTrackedEvents(
  routeTrackingData: uui.domain.client.rm.RouteTrackingData['routeTrackingData'],
  driverTrackingData: Record<string, uui.domain.server.rm.DriverTrackingData>,
) {
  if (
    (routeTrackingData?.preparationTrackingData.timeInSec ?? -1) !== -1 ||
    (routeTrackingData?.closeOutTrackingData.timeOutSec ?? -1) !== -1
  )
    return true

  for (const driverId in driverTrackingData) {
    const { shiftStart, shiftEnd } = driverTrackingData[driverId]

    if (shiftStart.sec !== -1 || shiftEnd.sec !== -1) return true
  }

  return false
}
