import { useCallback, useMemo } from 'react'

import { resetCrudSelection } from '@/atoms'
import { FindOnMap } from '@/icons'
import {
  SecondaryColumnHeader,
  FormBulkAvatar,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsRow,
} from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import * as gis from '@/local/server-data/utils/gis'

import { useTexts } from './hooks/useTexts'
import { useActions } from './hooks/useActions'

interface Props {
  deviceEvents: uui.domain.client.gps.wwgps.EventInfo[]
}

export function BulkView(props: Props) {
  const { deviceEvents } = props

  const eventsCount = deviceEvents.length

  const texts = useTexts()
  const actions = useActions()
  const allEventsWithInvalidLatLng = useMemo(
    () => deviceEvents.every(e => gis.isNullIslandLatLng(e.latLng)),
    [deviceEvents],
  )

  const onReset = useCallback(() => {
    resetCrudSelection('deviceEvents')
  }, [])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.title(eventsCount)}
        description={texts.description}
        action={onReset}
        avatar={<FormBulkAvatar size={eventsCount} />}
      />
      <SecondaryColumnHeaderActionsRow centered>
        <SecondaryColumnHeaderActionButton
          label={texts.findOnMap}
          icon={<FindOnMap />}
          onClick={actions.onFitMap}
          testid="events-list__find-on-map"
          disabled={allEventsWithInvalidLatLng}
          title={allEventsWithInvalidLatLng ? texts.noLocationButtonTitle : ''}
        />
      </SecondaryColumnHeaderActionsRow>
    </FormColumn>
  )
}
