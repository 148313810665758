import { CategoryButton } from '@/components/Navigo/components/CategoryButton'
import { FormatDate } from '@/components/smartUtils/time/FormatDate'
import { VerticalLayout } from '@/components/layout'
import { Text } from '@/local/components'

import { Route } from '@/icons'

import { useVehiclesData } from '../../hooks/useVehiclesData'
import { useTexts } from '../../useTexts'
import { useSelectRoutes } from './hooks/useSelectRoutes'

interface Props {
  vehicleIds: string[]
}

export function RoutesButton(props: Props) {
  const { vehicleIds } = props

  const {
    totalViolations,
    backgroundColor,
    foregroundColor,
    activeRange,
    borderColor,
    ordersCount,
    routeIds,
  } = useVehiclesData(vehicleIds)

  const selectRoutes = useSelectRoutes(routeIds)
  const texts = useTexts()

  const badge =
    routeIds.length === 0
      ? undefined
      : ordersCount === 0
      ? texts.empty
      : totalViolations > 0
      ? totalViolations
      : undefined

  const title = texts.routesCounter(routeIds.length)
  const disabled = routeIds.length === 0

  return (
    <CategoryButton
      fullOpacityIfDisabled
      data-trackid="navigo-vehicle-go-to-routes-button"
      data-testid="navigo-vehicle-go-to-routes-button"
      foregroundColor={foregroundColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      disabled={disabled}
      autoWidth
      onClick={routeIds.length > 0 ? selectRoutes : undefined}
      badge={badge}
      Icon={<Route size={20} />}
    >
      <VerticalLayout width="auto" justifyContent="center">
        {title}
        {activeRange && (
          <Text size="$p3" weight="$regular">
            {texts.on} <FormatDate source={activeRange.startDate} shortFormat />
            {activeRange.endDate && ' - '}
            {activeRange.endDate && <FormatDate source={activeRange.endDate} shortFormat />}
          </Text>
        )}
      </VerticalLayout>
    </CategoryButton>
  )
}
