import { Entry } from '../Entry'
import { JiraLinks } from './JiraLinks'

const ids = [
  10217, 10276, 10282, 10283, 10313, 10317, 10319, 10334, 10392, 10438, 10509, 10510, 10514, 10515,
  10529, 10538, 10512,
]

export function Entry20210330() {
  return (
    <Entry title="03/30/2021 - v3.0.0-beta-1">
      <b>V3 beta Phase 1</b>: the first public beta
      <br />
      <br />
      Full list of Jira issues fixed:
      <br />
      <br />
      <JiraLinks ids={ids} />
      <br />
      <br />
    </Entry>
  )
}
