import { type ReactNode } from 'react'
import { useTexts } from '../useTexts'
import { DropdownStatus } from '../../Dropdown'

export interface Props {
  isSearching?: boolean
  status: DropdownStatus
  count: number
  secondaryText?: ReactNode
}

function Footer(props: Props) {
  const { isSearching, status, count, secondaryText } = props
  const texts = useTexts()

  let text: string = texts.genericDropdownFooterNoItemsText

  if (count > 0) {
    text = isSearching
      ? texts.genericDropdownFooterWithSearchText(count)
      : texts.genericDropdownFooterText(count)
  }

  if (status === 'closed') return null
  return (
    <div className="o-dropdown-wrapper-footer">
      {text}
      {secondaryText}
    </div>
  )
}

export default Footer
