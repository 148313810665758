import type { MouseEventHandler } from 'react'

import styled from 'styled-components'

import { BUTTON_SIZE } from './BaseInputWrapper'

const BUTTON_PAD = 4

export interface Props {
  className?: string
  type?: string
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const ButtonReset = styled.button`
  flex: 0 0 auto;
  background: ${p => p.theme.colors.$paleSky};
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  padding: ${BUTTON_PAD}px;
  border-radius: 50%;

  outline: 0;
  border-width: 0;
  color: ${p => p.theme.colors.$pureWhite};

  transition: 0.3s ease;
  transition-property: background, opacity, color;
  cursor: pointer;

  &:hover {
    background: ${p => p.theme.colors.$shadyLady};
  }

  & > * {
    display: block;
    width: 100%;
  }

  &:disabled {
    opacity: 0;
    cursor: auto;
  }

  &.is-empty {
    opacity: 0;
    cursor: auto;
  }
`

ButtonReset.displayName = 'ButtonReset'

export default ButtonReset
