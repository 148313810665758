import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    copy: translate({ id: 'settings.rmAccount.view.copy' }),
    reset: translate({ id: 'settings.rmAccount.view.reset' }),

    copyApiKeySuccess: translate({ id: 'settings.rmAccount.view.copyApiKeySuccess' }),
    copyApiKeyError: translate({ id: 'settings.rmAccount.view.copyApiKeyError' }),
  }))

  return api
}
