import { useEffect, useState } from 'react'

import {
  useConfigureModalController,
  useModalController,
  setEditingState,
  useCloseModal,
} from '@/atoms'
import { importWizardSession } from '@/utils'
import { useData } from './useData'

type ModalState = 'ready'
interface ModalData {
  importedOrderStepIds: string[]
  lowAccuracyOrderStepIds: string[]
  numberOfImportedOrders: number
  numberOfImportedOrdersWithLowAccuracy: number
}

export const modalId = 'importWizardResultModal'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = (
  startRevision: number,
  numberOfImportedOrders: number,
  numberOfImportedOrdersWithLowAccuracy: number,
) => {
  const close = useCloseModal()
  const { importedOrderStepIds, lowAccuracyOrderStepIds } = useData(startRevision)

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        numberOfImportedOrders,
        numberOfImportedOrdersWithLowAccuracy,

        importedOrderStepIds,
        lowAccuracyOrderStepIds,
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)

  // Pause transaction on Open + Resume transaction on Close
  useEffect(() => {
    setEditingState({ editing: true })

    return () => {
      setEditingState({ editing: false })

      // Delete the cookie once the modal closes
      importWizardSession.deleteCookie()
    }
  }, [])

  return ctrl
}
