import type { FormFields, FormErrors } from '../../../formFields'

import { BulkTextField } from '../../primitives/BulkTextField'
import { useTexts } from './useTexts'

export function Notes() {
  const texts = useTexts()

  return (
    <BulkTextField<'notes', FormFields, FormErrors>
      name="notes"
      testId="notes"
      mixedLabel={texts.mixed}
      label={texts.notes}
      multiline
    />
  )
}
