import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    cancelButtonTitle: translate({ id: 'demoTools.deleteTerritory.modal.cancelButton.title' }),
    commandButtonTitle: translate({ id: 'demoTools.deleteTerritory.modal.commandButton.title' }),
    genericError: translate({ id: 'demoTools.deleteTerritory.error.generic' }),
    modalTitle: (territoryName: string) =>
      translate({ id: 'demoTools.deleteTerritory.modal.title', values: { territoryName } }),
    modalSubTitle: translate({ id: 'demoTools.deleteTerritory.modal.subtitle' }),
    transactionWarningCardTitle: translate({
      id: 'demoTools.deleteTerritory.modal.transaction.warningCard.title',
    }),
    transactionWarningCardDescription: translate({
      id: 'demoTools.deleteTerritory.modal.transaction.warningCard.description',
    }),
    warningCardTitle: (territoryName: string) =>
      translate({
        id: 'demoTools.deleteTerritory.modal.warningCard.title',
        values: { territoryName },
      }),
    warningCardDescription: translate({
      id: 'demoTools.deleteTerritory.modal.warningCard.description',
    }),
  }))

  return api
}
