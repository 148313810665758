import { PureComponent } from 'react'

import {
  HorizontalRadioGroupField,
  HorizontalRadioGroupColors,
  RadioGroupValues,
} from '@/forms-legacy'
import { theme } from '@/local/components'

import * as OrderTexts from '../../../../../../intl'
import { Props } from '../typings'

const orderTypeColors: HorizontalRadioGroupColors = {
  background: theme.colors.$silver,
  backgroundSelected: theme.colors.$nightRider,
  color: theme.colors.$nightRider,
  colorSelected: theme.colors.$pureWhite,
  border: theme.colors.$pureWhite,
  borderSize: 3,
}

const getOrderTypeValues = (
  initialValue: uui.domain.client.rm.OrderIdentifier,
): RadioGroupValues => [
  {
    label: OrderTexts.getOrderTypePickupText(),
    value: 'p',
    disabled: initialValue === 'pd',
  },
  {
    label: OrderTexts.getOrderTypeDeliveryText(),
    value: 'd',
    disabled: initialValue === 'pd',
  },
  {
    label: OrderTexts.getOrderTypeServiceText(),
    value: 's',
    disabled: initialValue === 'pd',
  },
  {
    label: OrderTexts.getOrderTypePickupDeliveryText(),
    value: 'pd',
  },
]

export default class OrderTypeField extends PureComponent<Props> {
  private readonly orderTypeValues: RadioGroupValues

  constructor(props: Props) {
    super(props)
    this.orderTypeValues = getOrderTypeValues(props.initialValues.type)
  }

  render() {
    const {
      disabledFields = [],
      initialValues: { type },
    } = this.props

    const disabled = disabledFields.includes('type')

    const info = type === 'pd' ? OrderTexts.getDeletePairedFirstMessage() : undefined

    return (
      <HorizontalRadioGroupField
        name="type"
        label={OrderTexts.geFieldLabelOrderTypeText()}
        info={info}
        wrappedInputProps={{
          hideOuterBorder: true,
          hideSelectionUnderline: true,
          colors: orderTypeColors,
          values: this.orderTypeValues,
          disabled,
        }}
        testid="type"
      />
    )
  }
}
