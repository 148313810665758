import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'setup.vehicleAssignments.form.title.recurrence' }),
    headerDescription: translate({ id: 'setup.vehicleAssignments.form.description.recurrence' }),
  }))

  return api
}
