import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import { selectDevicesAggregateInfo } from '@/features/domain/device'
import { cmsToKmh, cmsToMph } from '@/local/server-data/utils/conversion'

import { conversionUtils } from '@/server-data'

import { useTexts } from './useTexts'

interface Data {
  title: string
  value: string
  delta?: number
}

export function useDriverBehaviorData(event: uui.domain.client.gps.wwgps.EventInfo) {
  const texts = useTexts()
  const userConfig = useSelector(selectUserConfiguration)

  const deviceAggregateInfo = useSelector(selectDevicesAggregateInfo)

  return useMemo<Data | undefined>(() => {
    const { data, type } = event
    const { gal, known: postedSpeedLimit } = data || {}

    const metric = userConfig.distanceFormat === 'METRIC'

    const driverBehavior = deviceAggregateInfo[event.deviceId]?.driverBehaviors

    switch (type) {
      case 'hard-cornering':
        return {
          title: texts.corneringIntensity,
          value: texts.gals(gal),
          delta: driverBehavior ? getDeltaGals(driverBehavior, type, gal) : undefined,
        }

      case 'max-speed-threshold': {
        const speedThreshold = driverBehavior?.speedThreshold.threshold

        return {
          title: texts.maxSpeedLimit,
          value: speedThreshold ? `${getMaxSpeedValue(speedThreshold, metric)}` : '',
        }
      }

      case 'device-speed-alert':
        return {
          title: texts.postedSpeedLimit,
          value: postedSpeedLimit
            ? conversionUtils.formatPostedSpeed(userConfig)(postedSpeedLimit)
            : '',
        }

      case 'hard-braking':
      case 'harsh-braking':
      case 'severe-braking':
        return {
          title: texts.breakingIntensity,
          value: texts.gals(gal),
          delta: driverBehavior ? getDeltaGals(driverBehavior, type, gal) : undefined,
        }

      case 'hard-acceleration':
      case 'harsh-acceleration':
      case 'severe-acceleration':
        return {
          title: texts.accelerationIntensity,
          value: texts.gals(gal),
          delta: driverBehavior ? getDeltaGals(driverBehavior, type, gal) : undefined,
        }
    }
  }, [event, texts, deviceAggregateInfo, userConfig])
}

function getDeltaGals(
  driverBehavior: uui.domain.client.gps.wwgps.DriverBehaviorSettings,
  type: uui.domain.client.gps.wwgps.EventInfoType,
  gal?: number,
): number {
  let threshold: number | undefined | null

  switch (type) {
    case 'hard-braking':
      threshold = driverBehavior.hardBraking.threshold
      break
    case 'harsh-braking':
      threshold = driverBehavior.harshBraking.threshold
      break
    case 'severe-braking':
      threshold = driverBehavior.severeBraking.threshold
      break

    case 'hard-acceleration':
      threshold = driverBehavior.hardAcceleration.threshold
      break
    case 'harsh-acceleration':
      threshold = driverBehavior.harshAcceleration.threshold
      break
    case 'severe-acceleration':
      threshold = driverBehavior.severeAcceleration.threshold
      break

    case 'hard-cornering':
      threshold = driverBehavior.hardCornering.threshold
      break
  }

  return threshold && gal && gal > threshold ? gal - threshold : 0
}

function getMaxSpeedValue(valueInCms: number, metric: boolean) {
  const val = metric ? cmsToKmh(valueInCms) : cmsToMph(valueInCms)
  return Math.round(val)
}
