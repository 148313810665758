import { ViewField, ViewFieldsLayout } from '@/formUi'
import { useTexts } from './hooks/useTexts'
//import { Restrictions } from './Restrictions'

type Props = {
  voice: uui.domain.server.rm.VoiceConfig
}

export function OffAdvancedProperties(props: Props) {
  const { voice } = props
  const texts = useTexts()

  if (voice.status !== 'ADVANCED' || voice.callbackConfig.status !== 'OFF') return null

  const messageOnCallbackTitle = voice.callbackConfig.automatedMessageEnabled
    ? texts.value.messageOnCallback.on
    : texts.value.messageOnCallback.off
  const voicePerformance =
    voice.callbackConfig.automatedMessageVoice &&
    `${voice.callbackConfig.automatedMessageVoice[0]}${voice.callbackConfig.automatedMessageVoice
      .substring(1)
      .toLowerCase()}`

  return (
    <ViewFieldsLayout>
      {/*
      //TODO: Re-enable that field once it will be available

      <ViewField label={texts.labels.restrictions}>
        <Restrictions voice={voice} />
      </ViewField>
      */}
      <ViewField label={texts.labels.preference}>{texts.value.preference.off}</ViewField>

      <ViewField label={texts.labels.messageOnCallback}>{messageOnCallbackTitle}</ViewField>
      {voice.callbackConfig.automatedMessageEnabled ? (
        <>
          <ViewField label={texts.labels.message}>
            {voice.callbackConfig.automatedMessage}
          </ViewField>
          <ViewField label={texts.labels.voicePreference}>{voicePerformance}</ViewField>
        </>
      ) : null}
    </ViewFieldsLayout>
  )
}
