import type { AlertFormValidation, AlertFormValues } from '../types'

import { useTexts } from './useTexts'

const validateForm =
  (texts: ReturnType<typeof useTexts>) =>
  (formValues: AlertFormValues): AlertFormValidation => {
    const validation: AlertFormValidation = {}

    const { name, deviceIds, notificationAddresses } = formValues

    if (name.length < 1) {
      validation.name = texts.validationMissingName
    }

    if (deviceIds.length < 1) {
      validation.deviceIds = texts.validationDeviceRequired
    }

    if (notificationAddresses.length < 1) {
      validation.notificationAddresses = texts.validateNotificationAddress
    }

    return validation
  }

export const useValidateForm = () => {
  const texts = useTexts()
  return validateForm(texts)
}
