import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

import { selectData } from '../cursors/selectData'

type ModalState = 'ready' | 'pending'
interface ModalData {
  newTerritoryId?: string
  territory?: uui.domain.client.rm.Territory
  territories: uui.domain.client.rm.Territory[]
}

export const modalId = 'changeTerritory'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = () => {
  const close = useCloseModal()
  const { territories, territory } = useSelector(selectData)

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: { territories, territory },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const { updateData } = ctrl

  // EFFECTS

  // Data update
  useEffect(() => {
    updateData({ territories, territory })
  }, [territories, territory, updateData])

  return ctrl
}
