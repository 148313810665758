import { useSelector } from 'react-redux'

import { timeUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

import { useTexts } from '../../../useTexts'

export function useOpenButtonText(
  selectedDaysCount: number,
  selectionStart?: Date,
  selectionEnd?: Date,
) {
  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  if (!selectionStart) return texts.open

  if (!selectionEnd) return texts.openDay(timeUtils.formatDate(userConfig)(selectionStart, true))

  if (selectionStart.getTime() === selectionEnd.getTime())
    return texts.openDay(timeUtils.formatDate(userConfig)(selectionStart, true))

  return texts.openDays(selectedDaysCount)
}
