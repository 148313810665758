import type { SegmentType } from '../../../../types'

import { useState } from 'react'

import { useIntl } from '@/intl'
import { durationUtils } from '@/server-data'

export function useTexts() {
  const { translate } = useIntl()
  const formatDurationSec = durationUtils.formatSeconds(translate)

  const [api] = useState(() => ({
    segmentDescription: (type: SegmentType) => {
      switch (type.type) {
        case 'disabled':
          return translate({ id: 'setup.routing.list.segments.disabledDescription' })
        case 'blocked':
          return translate({ id: 'setup.routing.list.segments.blockedDescription' })
        case 'delayed': {
          return translate({
            id: 'setup.routing.list.segments.delayedDescription',
            values: { time: formatDurationSec(type.additionalDrivingTimeSec, 'SHORT') },
          })
        }
      }
    },
  }))

  return api
}
