import { proxy, subscribe, useSnapshot } from 'valtio'

// ---------------------------------------------------------------------------
// Restore from Local Storage
// ---------------------------------------------------------------------------

const localStorageId = 'routemanager/v1/atoms/warningOverlayShown'

const localStorageValue = localStorage.getItem(localStorageId)
const storedValue = localStorageValue ? JSON.parse(localStorageValue) : { visible: false }

// ---------------------------------------------------------------------------
// Retro compatibility check
// ---------------------------------------------------------------------------

// in old version was boolean
const initialSettings = typeof storedValue === 'boolean' ? { visible: storedValue } : storedValue

// If stored value was boolean let's update it
if (typeof storedValue === 'boolean') {
  localStorage.setItem(localStorageId, JSON.stringify(initialSettings))
}

// ---------------------------------------------------------------------------
// Warning Overlay show atom
// ---------------------------------------------------------------------------
export const warningOverlayShownAtom = proxy<{ visible: boolean }>(initialSettings)

export function setWarningOverlayShown(value: boolean) {
  warningOverlayShownAtom.visible = value
}

// ---------------------------------------------------------------------------
// Write to Local Storage
// ---------------------------------------------------------------------------

subscribe(warningOverlayShownAtom, () => {
  localStorage.setItem(localStorageId, JSON.stringify(warningOverlayShownAtom))
})

export const useWarningOverlayShown = () =>
  [useSnapshot(warningOverlayShownAtom).visible, setWarningOverlayShown] as const
