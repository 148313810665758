import { useState } from 'react'
import { useIntl } from '@/intl'

import { useTitle } from './useTitle'
import { useDescription } from './useDescription'
import { useRevokeButtonTitle } from './useRevokeButtonTitle'
import { useResetButtonTitle } from './useResetButtonTitle'

export function useTexts() {
  const { translate } = useIntl()

  const title = useTitle()
  const description = useDescription()
  const revokeButtonTitle = useRevokeButtonTitle()
  const resetButtonTitle = useResetButtonTitle()

  const [api] = useState(() => ({
    title,
    description,
    revokeButtonTitle,
    resetButtonTitle,
    dispatchButtonTitle: (dispatchButtonDisabled?: boolean) =>
      translate({
        id: dispatchButtonDisabled
          ? 'dispatchPanel.tooltips.dispatch.disabled'
          : 'dispatchPanel.tooltips.dispatch.enabled',
      }),
    dispatch: translate({ id: 'dispatchPanel.action.dispatch' }),
    revoke: translate({ id: 'dispatchPanel.action.revoke' }),
  }))

  return api
}
