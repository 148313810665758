import { PureComponent } from 'react'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'

import * as OrderTexts from '../../../intl'

import { getCalculatedServiceTime } from '../utils'
import { Props, CalculatedServiceTime } from '../typings'
import { formatMinutes } from '../intl'

export default class DetectedServiceTime extends PureComponent<Props> {
  render() {
    const { trackingData, rescheduleBehavior } = this.props

    const { time, locID, text }: CalculatedServiceTime = getCalculatedServiceTime(
      trackingData,
      rescheduleBehavior,
    )
    const detectedTimeText: string =
      time > 0
        ? `${formatMinutes(Math.floor(Math.abs(time) / 60))} •`
        : OrderTexts.getUnableToCalculateText()

    const detectedTimeDescription: string | undefined = locID ? text : undefined

    return (
      <ReadonlyBlock data-testid="DetectedServiceTime-root">
        <ReadonlyLabel>{OrderTexts.getCalculatedServiceTimeText()}</ReadonlyLabel>

        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>
              {detectedTimeText}
              <span style={{ fontWeight: 'normal', marginLeft: 3 }}>{detectedTimeDescription}</span>
            </ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyBlock>
    )
  }
}
