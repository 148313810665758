import styled from 'styled-components'

type Props = {
  empty?: boolean
  light?: boolean
}

export const ReadonlyFieldLabel = styled.div<Props>`
  color: ${p => (p.empty ? p.theme.colors.$paleSky : p.theme.colors.$nightRider)};
  font-size: ${p => (p.light ? p.theme.fonts.$p3 : p.theme.fonts.$p2)};
  font-weight: ${p => (p.light ? p.theme.weights.$regular : p.theme.weights.$semiBold)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

ReadonlyFieldLabel.displayName = 'ReadonlyFieldLabel'
