import type { PreparationCloseoutEventData } from '../../../../../types'

import { useSelector } from 'react-redux'
import { Grid, Skeleton, Stack, Typography } from '@mui/material'
import { HistoryToggleOff, AccessTime, ArrowRightAlt } from '@mui/icons-material'

import { selectUserAccountPreferences, selectUserConfiguration } from '@/features/domain/user'
import { selectTerritory } from '@/features/domain/territory'
import { CloseoutTime } from '@/icons'
import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'
import { timeUtils } from '@/server-data'
import { Tooltip } from '@/components/primitives/Tooltip'
import { theme } from '@/styles'

import { useComputedLocation } from '../hooks/useComputedLocation'
import { useTexts } from '../hooks/useTexts'

interface Props {
  data: PreparationCloseoutEventData
  routeColor: string
}

export function RouteCloseoutEvent(props: Props) {
  const { data, routeColor } = props

  const planned =
    data.type === 'complete' || data.type === 'planned-only' ? data.planned : undefined
  const tracked =
    data.type === 'complete' || data.type === 'tracked-only' ? data.tracked : undefined

  const { mobileAppsTrackingType } = useSelector(selectTerritory)
  const accountPreferences = useSelector(selectUserAccountPreferences)
  const computedLocation = useComputedLocation(tracked?.location ?? planned?.location)
  const { userType } = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const plannedStartTime = planned
    ? timeUtils.formatSecondsFromMidnight(planned.start, accountPreferences.timeFormat)
    : undefined
  const plannedEndTime = planned
    ? timeUtils.formatSecondsFromMidnight(planned.end, accountPreferences.timeFormat)
    : undefined

  const trackedStartTime =
    tracked && tracked.start !== -1
      ? timeUtils.formatSecondsFromMidnight(tracked.start, accountPreferences.timeFormat)
      : '-'

  const trackedEndTime =
    tracked && tracked.end !== -1
      ? timeUtils.formatSecondsFromMidnight(tracked.end, accountPreferences.timeFormat)
      : '-'

  const delta = planned && tracked && tracked.end !== -1 ? tracked.end - planned.end : 0
  const inLate = delta > 0

  const iconColor =
    mobileAppsTrackingType === 'ONLY_ORDERSTEPS'
      ? theme.colors.$pureBlack
      : tracked?.start !== undefined &&
        tracked?.start !== -1 &&
        tracked?.end !== undefined &&
        tracked?.end !== -1
      ? theme.colors.$shadyLady
      : routeColor

  const deltaColor = delta > 0 ? '#EC6040' : delta < 0 ? '#399C4F' : 'text.secondary'

  const plannedTimeTooltip = planned
    ? texts.closeoutTimeTooltipPlanned
    : texts.closeoutTimeTooltipNotDispatched

  const trackedTimeTooltip =
    trackedStartTime !== '-' || trackedEndTime !== '-'
      ? texts.closeoutTimeTooltipExecuted
      : userType === 'rmOnly'
      ? texts.closeoutTimeWontBeCollectedRMOnlyTooltip
      : texts.closeoutTimeTooltipNotCollected

  return (
    <Grid maxWidth="100%" container spacing={1} data-testid="routeCloseoutEvent">
      <Grid item xs={2} sx={{ color: iconColor }}>
        <CloseoutTime size={32} />
      </Grid>
      <Grid item xs={10}>
        <Stack>
          <Typography variant="h6" data-testid="eventTitle">
            {texts.closeoutTime}
          </Typography>

          {computedLocation ? (
            <Typography variant="caption" color="text.secondary" data-testid="eventLocation">
              {computedLocation}
            </Typography>
          ) : (
            <Skeleton width={100} height={20} animation="wave" data-testid="eventLocationLoader" />
          )}

          <Stack spacing={1} paddingTop={1}>
            <Tooltip placement="top" title={plannedTimeTooltip}>
              <Stack direction="row" spacing={1}>
                <HistoryToggleOff fontSize="small" color={planned ? 'inherit' : 'disabled'} />
                {planned ? (
                  <>
                    <Typography fontSize={13} data-testid="plannedStartTime" whiteSpace="nowrap">
                      {plannedStartTime}
                    </Typography>
                    <ArrowRightAlt fontSize="small" />
                    <Typography fontSize={13} data-testid="plannedEndTime" whiteSpace="nowrap">
                      {plannedEndTime}
                    </Typography>
                  </>
                ) : (
                  <Typography fontSize={13} data-testid="plannedTimeNotAvailable" color="grey.600">
                    -
                  </Typography>
                )}
              </Stack>
            </Tooltip>

            <Tooltip placement="top" title={trackedTimeTooltip}>
              <Stack direction="row" spacing={1} alignItems="flex-end">
                <AccessTime
                  fontSize="small"
                  color={
                    trackedStartTime === '-' && trackedEndTime === '-' ? 'disabled' : 'inherit'
                  }
                />
                {tracked ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      {trackedStartTime === '-' && trackedEndTime === '-' ? (
                        <Typography fontSize={13} data-testid="trackedTimeNotAvailable">
                          -
                        </Typography>
                      ) : (
                        <>
                          <Typography
                            fontSize={13}
                            data-testid="trackedStartTime"
                            whiteSpace="nowrap"
                          >
                            {trackedStartTime}
                          </Typography>
                          <ArrowRightAlt fontSize="small" />
                          <Typography
                            fontSize={13}
                            data-testid="trackedEndTime"
                            whiteSpace="nowrap"
                          >
                            {trackedEndTime}
                          </Typography>
                        </>
                      )}
                    </Stack>
                    {delta !== 0 ? (
                      <Typography
                        color={deltaColor}
                        fontSize={12}
                        whiteSpace="nowrap"
                        data-testid="trackedTimeDifference"
                      >
                        {inLate ? '+' : '-'}
                        <FormatSeconds seconds={delta} format="MINUTES_ONLY" />
                      </Typography>
                    ) : null}
                  </Stack>
                ) : (
                  <Typography fontSize={13} data-testid="eventTrackedTimeNotCollected">
                    -
                  </Typography>
                )}
              </Stack>
            </Tooltip>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}
