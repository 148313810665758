import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    notRestricted: translate({ id: 'userManagement.3pc.notRestricted' }),
    restricted: translate({ id: 'userManagement.3pc.restricted' }),
  }))

  return api
}
