import { type ReactElement } from 'react'
import { Badge } from '@mui/material'
import { type ValidFontSize } from '@/local/components'

import { Text } from '@/local/components'

import { ActionButtonComponent } from './components/ActionButtonComponent'
import { IconContainer } from './components/IconContainer'
import { HoverArea } from './components/HoverArea'

interface Props {
  text: string

  Icon?: ReactElement
  error?: boolean
  testid?: string
  onClick?: () => void
  fontSize?: ValidFontSize
  disabled?: boolean
  className?: string
  secondaryButton?: ReactElement
}

export function ActionButton(props: Props) {
  const {
    disabled = false,
    secondaryButton,
    className,
    fontSize,
    onClick,
    testid,
    error = false,
    Icon,
    text,
  } = props

  // NOTE: The display hack in the badge has been made because the invisible prop looks like is not working here
  const slotProps = {
    badge: {
      style: { transform: 'translate(15px, -5px)', display: error ? 'block' : 'none' },
    },
  }

  return (
    <ActionButtonComponent
      onClick={disabled ? undefined : onClick}
      className={className}
      disabled={disabled}
      data-trackid={testid}
      data-testid={testid}
    >
      <HoverArea>
        <IconContainer disabled={disabled}>{Icon}</IconContainer>
        <Badge badgeContent="" color="error" variant="dot" slotProps={slotProps}>
          <Text
            size={fontSize ?? '$h2'}
            color="$nightRider"
            weight="$regular"
            textAlign="left"
            ellipsis
          >
            {text}
          </Text>
        </Badge>

        <span className="secondaryButton">{secondaryButton}</span>
      </HoverArea>
    </ActionButtonComponent>
  )
}
