import { NONE_OPTION } from '../../../../../constants'
import { type BulkFieldValue } from '../../../types'

export function computeCompanyId(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
  companies: Record<string, uui.domain.client.rm.Company>,
): BulkFieldValue<string> {
  const companyId = orders[0].order.companyId
  const exact = orders.every(o => o.order.companyId === companyId)

  if (exact) {
    const hasZombieCompany = companyId !== null && !companies[companyId]

    return {
      status: 'exact',
      value: companyId === null || hasZombieCompany ? NONE_OPTION : companyId,
    }
  }

  return {
    status: 'mixed',
  }
}
