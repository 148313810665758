import { useCallback } from 'react'
import { proxy, useSnapshot } from 'valtio'

interface CollapsibleState {
  regions: boolean
  constraints: boolean
  costs: boolean
}

interface CollapsibleStateAtom {
  state: CollapsibleState
}

const initialState: CollapsibleState = {
  regions: true,
  constraints: true,
  costs: true,
}

export const collapsibleStateAtom = proxy<CollapsibleStateAtom>({
  state: initialState,
})

// ------------------------------------
// React Hooks
// ------------------------------------

type Category = keyof CollapsibleState

export function useCollapsibleState(category: Category) {
  const state = useSnapshot(collapsibleStateAtom).state
  const value = state[category]

  const toggleValue = useCallback(() => {
    collapsibleStateAtom.state[category] = !value
  }, [value, category])

  return [value, toggleValue] as const
}
