import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    invalidLoads: (invalidLoads: string) =>
      translate({ id: 'order.form.bulk.errors.load.invalid', values: { invalidLoads } }),
    exceededMaxLoads: (max: number) =>
      translate({ id: 'order.form.bulk.errors.load.exceededMaxCount', values: { max } }),

    invalidTimeWindows: translate({ id: 'orders.edit.validation.timeWindow.startEndError' }),
    invalidServiceTimeFormat: translate({
      id: 'order.form.bulk.field.serviceTimeSec.invalidFormat',
    }),
    invalidServiceTimeMaxValue: translate({
      id: 'order.form.bulk.field.serviceTimeSec.invalidMaxValue',
    }),
    invalidLoadInvalidFormat: translate({ id: 'order.form.bulk.errors.load.invalid.notANumber' }),
    invalidLoadLessThanZero: translate({ id: 'order.form.bulk.errors.load.invalid.lessThanZero' }),
    invalidLoadEmpty: translate({ id: 'order.form.bulk.errors.load.invalid.empty' }),
    invalidLoadExceedMaxValue: translate({
      id: 'order.form.bulk.errors.load.invalid.exceedMaxValue',
      values: { max: '5 000 000' },
    }),
    invalidLoadExceedMaxCharacters: translate({
      id: 'order.form.bulk.errors.load.invalid.exceedMaxCharacters',
    }),

    invalidEligibilityNoDates: translate({
      id: 'order.form.bulk.errors.eligibility.invalid.noDates',
    }),
    invalidPhone: translate({
      id: 'orders.edit.validation.phone.invalid',
    }),
    invalidEmail: translate({
      id: 'orders.edit.validation.email.invalid',
    }),
  }))

  return api
}

export type ValidationTexts = ReturnType<typeof useTexts>
