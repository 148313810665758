import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
} from '@mui/material'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { useEffect, useState } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'

import { useControllerActions } from '../../../../../hooks/useControllerActions'
import { useController } from '../../../../../hooks/useController'
import { useTexts } from '../../../../../hooks/useTexts'

export function Advanced() {
  const {
    data: { keepEnglishColumns, customizeColumns },
  } = useController()

  const [expanded, setExpanded] = useState(customizeColumns)
  const texts = useTexts()

  const { onChangeKeepEnglishColumns } = useControllerActions()

  useEffect(() => {
    // if customizeColumns is true, there's no need to collapse the accordion
    if (customizeColumns) return

    setExpanded(customizeColumns)
  }, [customizeColumns])

  const ArrowIcon = expanded ? KeyboardArrowUp : KeyboardArrowDown
  const advancedButtonDisabled = !customizeColumns
  const advancedButtonTooltipDisabled = !advancedButtonDisabled
  return (
    <Stack alignItems="flex-start" pl={4}>
      <Tooltip
        placement="bottom"
        title={texts.advancedButtonDisabled}
        disabled={advancedButtonTooltipDisabled}
      >
        <Button
          disabled={advancedButtonDisabled}
          variant="text"
          onClick={() => setExpanded(p => !p)}
          sx={{ justifyContent: 'flex-start', width: '100%' }}
          data-testid="export-route-territory-export-advanced-submenu"
          data-trackid="export-advanced-submenu"
        >
          <>
            {texts.advanced.title}
            <ArrowIcon sx={{ mr: 1 }} />
          </>
        </Button>
      </Tooltip>

      <Accordion expanded={expanded}>
        <AccordionSummary sx={{ display: 'none' }}></AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={keepEnglishColumns}
                  onChange={(_, checked) => onChangeKeepEnglishColumns(checked)}
                  data-testid="export-route-territory-export-keep-english-columns"
                />
              }
              label={texts.advanced.keepEnglishColumns}
            />
            <FormHelperText sx={{ ml: 4, mb: 0, mt: -1 }}>
              {texts.advanced.keepEnglishColumnsDescription}
            </FormHelperText>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </Stack>
  )
}
