import { PureComponent } from 'react'

import {
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'
import * as OrderTexts from '../../../intl'

import { Props, FieldMode } from '../typings'
import ViolationIcon from './ViolationIcon'

export default class Vehicle extends PureComponent<Props> {
  render() {
    const { vehicle, violations } = this.props

    const text = vehicle === FieldMode.mixed ? OrderTexts.getMixedText() : vehicle

    const vehicleViolation = violations.includes('VE')

    return (
      <>
        <ReadonlyBlock>
          <ReadonlyLabel primary>
            {OrderTexts.getFieldLabelVehicleAssignmentText()}
            {vehicleViolation && <ViolationIcon />}
          </ReadonlyLabel>

          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{text}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
