import type { Location } from 'history'
import type { CustomOptions, Options } from './types'

import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import history from 'history/browser'

import { deleteDefaultValue, getDefaultValue, setDefaultValue } from './utils/defaultValuesCache'
import { subscribeToDefaultValues } from './utils/defaultValueSubscribers'
import { useQueryStateParams } from './hooks/useQueryStateParams'

type ClearFunction = () => void
type UpdateFunction<T> = (value: T) => void

//#region useQueryState overloads
export function useQueryState<T extends string = string>(
  key: string,
  opts?: Options<T>,
): [T | undefined, UpdateFunction<T | undefined>, ClearFunction]
export function useQueryState<T extends string = string>(
  key: string,
  defaultValue: T,
  opts?: Options<T>,
): [T, UpdateFunction<T>, ClearFunction]

export function useQueryState<T extends string = string>(
  key: string,
  type: 'string',
  opts?: Options<T>,
): [T | undefined, UpdateFunction<T | undefined>, ClearFunction]
export function useQueryState<T extends string = string>(
  key: string,
  type: 'string',
  defaultValue: T,
  opts?: Options<T>,
): [T, UpdateFunction<T>, ClearFunction]

export function useQueryState(
  key: string,
  type: 'boolean',
  opts?: Options<boolean>,
): [string | undefined, UpdateFunction<string | undefined>, ClearFunction]
export function useQueryState(
  key: string,
  type: 'boolean',
  defaultValue: boolean,
  opts?: Options<boolean>,
): [string, UpdateFunction<string>, ClearFunction]

export function useQueryState(
  key: string,
  type: 'integer',
  opts?: Options<number>,
): [number | undefined, UpdateFunction<number | undefined>, ClearFunction]
export function useQueryState(
  key: string,
  type: 'integer',
  defaultValue: number,
  opts?: Options<number>,
): [number, UpdateFunction<number>, ClearFunction]

export function useQueryState(
  key: string,
  type: 'float',
  opts?: Options<number>,
): [number | undefined, UpdateFunction<number | undefined>, ClearFunction]
export function useQueryState(
  key: string,
  type: 'float',
  defaultValue: number,
  opts?: Options<number>,
): [number, UpdateFunction<number>, ClearFunction]

export function useQueryState(
  key: string,
  type: 'timestamp',
  opts?: Options<Date>,
): [Date | undefined, UpdateFunction<Date | undefined>, ClearFunction]
export function useQueryState(
  key: string,
  type: 'timestamp',
  defaultValue: Date,
  opts?: Options<Date>,
): [Date, UpdateFunction<Date>, ClearFunction]

export function useQueryState<T>(
  key: string,
  type: 'json',
  opts?: T,
): [T | undefined, UpdateFunction<T | undefined>, ClearFunction]
export function useQueryState<T>(
  key: string,
  type: 'json',
  defaultValue: T,
  opts?: Options<T>,
): [T, UpdateFunction<T>, ClearFunction]

export function useQueryState(
  key: string,
  type: 'isoDateTime',
  opts?: Options<Date>,
): [Date | undefined, UpdateFunction<Date | undefined>, ClearFunction]
export function useQueryState(
  key: string,
  type: 'isoDateTime',
  defaultValue: Date,
  opts?: Options<Date>,
): [Date, UpdateFunction<Date>, ClearFunction]

export function useQueryState<T>(
  key: string,
  type: 'custom',
  opts: CustomOptions<T>,
): [T | undefined, UpdateFunction<T | undefined>, ClearFunction]
export function useQueryState<T>(
  key: string,
  type: 'custom',
  defaultValue: T,
  opts: CustomOptions<T>,
): [T, UpdateFunction<T>, ClearFunction]
//#endregion

export function useQueryState<T>(
  key: string,
  typeOrDefaultValueOrOptions?: any,
  defaultValueOrOptions?: any,
  opts?: any,
): any {
  // ------------------------------------------------------
  // ------------------------------------------------------

  const rValueAsString = useRef<string | null>(null)

  const { value, setValue, info } = useQueryStateParams<T>(
    typeOrDefaultValueOrOptions,
    defaultValueOrOptions,
    opts,
  )

  const rInfo = useRef(info)
  useLayoutEffect(() => void (rInfo.current = info), [info])

  // ------------------------------------------------------
  // ------------------------------------------------------

  const handleHistoryChange = useCallback(
    (location: Location) => {
      const params = new URLSearchParams(location.search)

      const paramValue = params.get(key)

      if (paramValue !== undefined && paramValue !== null) {
        const decodedParamValue = window.decodeURI(paramValue)

        if (rValueAsString.current !== decodedParamValue) {
          // parse and update the value
          setValue(rInfo.current.transformer.parse(decodedParamValue))

          // store the value-as-string
          rValueAsString.current = decodedParamValue
        }
      } else {
        // clear the value-as-string
        rValueAsString.current = paramValue

        const defaultValue = getDefaultValue<T>(key)

        if (rInfo.current.value !== defaultValue) {
          console.log('Force default value')
          setValue(defaultValue)
        }
      }
    },
    [key, setValue],
  )

  // ------------------------------------------------------
  // ------------------------------------------------------

  const clearUrlValue = useCallback(() => {
    const params = new URLSearchParams(history.location.search)
    params.delete(key)

    history.push(
      {
        search: params.keys.length > 0 ? `?${params.toString()}` : undefined,
        pathname: window.location.pathname,
      },
      history.location.state,
    )
  }, [key])

  // ------------------------------------------------------
  // ------------------------------------------------------

  const updateUrlValue = useCallback(
    (value: T | undefined) => {
      if (value === undefined) {
        return clearUrlValue()
      }

      const params = new URLSearchParams(history.location.search)
      params.set(key, window.encodeURI(rInfo.current.transformer.serialize(value)))

      history.push(
        {
          hash: history.location.hash,
          search: `?${params.toString()}`,
          pathname: window.location.pathname,
        },
        history.location.state,
      )
    },
    [clearUrlValue, key],
  )

  // ------------------------------------------------------
  // ------------------------------------------------------

  useEffect(() => {
    const uid = ''

    const unsubscribeFromDefaultValues = subscribeToDefaultValues<T>({
      key,
      subscriber: () => {
        // recompute data using the new default value
        handleHistoryChange(history.location)
      },
    })

    // listen for URL changes
    const unsubscribeFromHistory = history.listen(({ location }) => handleHistoryChange(location))

    // try to register default value
    if (rInfo.current.defaultValue !== null && rInfo.current.defaultValue !== undefined) {
      setDefaultValue<T>(key, uid, rInfo.current.defaultValue)
    }

    // initialize data
    if (
      rInfo.current.options.initialValue !== undefined &&
      rInfo.current.options.initialValue !== null
    ) {
      updateUrlValue(rInfo.current.options.initialValue)
    } else {
      handleHistoryChange(history.location)
    }

    return () => {
      unsubscribeFromHistory()
      unsubscribeFromDefaultValues()

      // clear default value
      deleteDefaultValue<T>(key, uid)

      // clear data
      if (rInfo.current.options.clearOnUnmount) {
        clearUrlValue()
      }
    }
  }, [handleHistoryChange, updateUrlValue, clearUrlValue, key])

  // ------------------------------------------------------
  // ------------------------------------------------------

  return [value, updateUrlValue, clearUrlValue]
}
