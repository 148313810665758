import { useMemo } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'
import { setMainSelection } from '@/atoms'

import { Collapsible } from '../Collapsible'
import { Section } from '../Section'
import { Header } from '../Header'
import { Row } from '../Row'

import { useCollapsedState, setCollapsedState } from '../../hooks'
import { useTexts } from './useTexts'

interface Props {
  snapshot: uui.domain.client.rm.PlanSummary
}

function setVehicleCollapsedState(collapsed: boolean) {
  setCollapsedState('vehicle', collapsed)
}

export function VehiclePlanSummary(props: Props) {
  const { snapshot } = props
  const texts = useTexts()

  const {
    vehicle: { vehicles, violated },
  } = snapshot

  const collapsed = useCollapsedState('vehicle')

  const selectApi = useMemo(
    () => ({
      selectAllVehicles: () => setMainSelection('unifiedVehicles', snapshot.vehicle.vehicles),
      selectVehiclesWithViolations: () =>
        setMainSelection('unifiedVehicles', snapshot.vehicle.violated),
    }),
    [snapshot],
  )

  return (
    <Collapsible
      collapsed={collapsed}
      setCollapsed={setVehicleCollapsedState}
      trigger={
        <Header
          testId="plan-summary-vehicles"
          text={texts.vehicles(vehicles.length)}
          value={collapsed ? vehicles.length : ''}
        />
      }
    >
      <Section indentation={1}>
        <Tooltip
          textAlign="left"
          placement="right"
          title={texts.vehicles(vehicles.length)}
          subtitle={texts.vehiclesTooltip}
        >
          <Row
            onClick={selectApi.selectAllVehicles}
            text={texts.all}
            value={vehicles.length}
            disabled={vehicles.length === 0}
            testId="plan-summary-all-vehicles"
          />
        </Tooltip>

        <Tooltip
          textAlign="left"
          placement="right"
          title={texts.violated}
          subtitle={texts.violatedTooltip}
        >
          <Row
            onClick={selectApi.selectVehiclesWithViolations}
            text={texts.violated}
            value={violated.length}
            disabled={violated.length === 0}
            testId="plan-summary-vehicles-with-violations"
          />
        </Tooltip>
      </Section>
    </Collapsible>
  )
}
