import { Component } from 'react'

import styled from 'styled-components'
import { clsx } from '@/utils'
import { buttonReset } from '@/styles'
import { Rewind as RewindIcon } from '@/icons'

const WIDTH = 8

export interface Props {
  onClick: () => void
  className?: string
  disabled?: boolean
}

class Rewind extends Component<Props> {
  render() {
    const { className = '', onClick, disabled = false } = this.props
    const rootClassName = clsx({
      [className]: true,
      'o-rewind': true,
    })
    return (
      <button className={rootClassName} onClick={onClick} disabled={disabled}>
        <span className="o-ico">
          <RewindIcon />
        </span>
      </button>
    )
  }
}

const StyledRewind = styled(Rewind)`
  ${buttonReset}
  display: block;
  width: ${WIDTH}px;
  transition: 0.3s ease;
  transition-property: transform, opacity;
  opacity: 1;
  cursor: pointer;

  &:disabled {
    opacity: 0;
    cursor: auto;
  }

  &:hover {
    transform: translateX(-15%);
  }

  .o-ico {
    display: block;
    width: 100%;
    transition: color 0.3s ease;
  }

  &:hover .o-ico {
    color: ${p => p.theme.colors.$dodgerBlue};
  }
`

StyledRewind.displayName = 'StyledRewind'

export default StyledRewind
