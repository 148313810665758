import { DropdownMenuItem } from '@/components/DropdownMenu'
import { Link } from '@/routing'
import { clsx } from '@/utils'

import { useTexts } from '../../../../useTexts'
import { ActiveIcon } from '../ActiveIcon'
import { useStyles } from '../hooks/useStyles'
import { useRmLinks } from './hooks/useRmLinks'

type RoutingRoutes = uui.routing.Routing

export function RmLinks() {
  const texts = useTexts()
  const classes = useStyles()
  const { canShow } = useRmLinks()

  return (
    <>
      <Link<RoutingRoutes>
        absolute
        to="home"
        data-testid="header__nav-home-button-link"
        className={clsx(classes.link, 'pendo-bluebar__home-menu')}
      >
        <DropdownMenuItem
          data-testid="header__nav-home-button-content"
          data-trackid="rm-header__nav-home-button-content"
        >
          <ActiveIcon />
          {texts.home}
        </DropdownMenuItem>
      </Link>

      <Link<RoutingRoutes>
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        className={clsx(classes.link, 'pendo-bluebar__orders-menu')}
      >
        <DropdownMenuItem
          data-testid="header__nav-orders-button-content"
          data-trackid="rm-header__nav-orders-button-content"
        >
          <ActiveIcon />
          {texts.orders}
        </DropdownMenuItem>
      </Link>

      {canShow.simulations && (
        <Link<RoutingRoutes>
          absolute
          to="simulations"
          data-testid="header__nav-simulations-button-link"
          className={clsx(classes.link, 'pendo-bluebar__simulations-list-menu')}
        >
          <DropdownMenuItem
            data-testid="header__nav-simulations-button-content"
            data-trackid="rm-header__nav-simulations-button-content"
          >
            <ActiveIcon />
            {texts.simulations}
          </DropdownMenuItem>
        </Link>
      )}
    </>
  )
}
