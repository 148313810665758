import {
  SecondaryColumnHeaderActionsRow,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsSeparator,
} from '@/forms-legacy'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { ArrowRoundedUpEdge, Edit, Trash } from '@/icons'

import { useEditing } from '../../../../../../hooks/useEditing'

import { useActions } from '../hooks/useActions'
import { useTexts } from '../hooks/useTexts'
interface Props {
  trafficArea: uui.domain.client.rm.TrafficRegion
  onDelete: () => void
}

export function Actions(props: Props) {
  const { onDelete, trafficArea } = props
  const actions = useActions(trafficArea)
  const texts = useTexts()

  const { editingEntity } = useEditing('trafficArea')
  const inEdit = editingEntity !== 'none'

  return (
    <SecondaryColumnHeaderActionsRow>
      <SecondaryColumnHeaderActionButton
        label={texts.exportKML}
        icon={<ArrowRoundedUpEdge />}
        onClick={actions.onExport}
        disabled={inEdit}
        testid="setup-traffic__exportkml"
      />

      <SecondaryColumnHeaderActionsSeparator />

      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.edit}
            icon={<Edit />}
            onClick={actions.onEdit}
            disabled={preventEditing || inEdit}
            testid="setup-traffic__edit"
          />
        )}
      />
      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={onDelete}
            disabled={preventEditing || inEdit}
            testid="setup-traffic__delete"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
