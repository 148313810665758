import styled from 'styled-components'

export const IconsListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 164px;
  padding: 5px;

  border: 2px solid ${p => p.theme.colors.$nightRider};
  border-radius: 6px;
`

IconsListContainer.displayName = 'IconsListContainer'
