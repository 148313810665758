import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { isGpsOnly } from '@/features/domain/user'
import { useMainSelection } from '@/atoms'

import { useDriverAssignments } from './useDriverAssignments'
import { useController } from './useController'
import { useDevices } from './useDevices'
import { useDriver } from './useDriver'

export const useControllerData = () => {
  const [selection] = useMainSelection('drivers')
  const gpsOnly = useSelector(isGpsOnly)
  const drivers = useDriverAssignments()
  const { updateData } = useController()
  const devices = useDevices()
  const driver = useDriver()

  const driverName = driver?.name
  const driverEmail = driver?.deployment.email

  useEffect(() => {
    if (gpsOnly) return

    // Force the message type to 'E-mail' and set the correct driver e-mail
    updateData({ messageType: 'email', email: driverEmail, driverName })
  }, [updateData, gpsOnly, driverEmail, driverName])

  return {
    gpsOnly,
    drivers,
    devices,
    driverName,
    driverEmail,
    selectedId: selection.length > 0 ? selection[0] : undefined,
  }
}
