import type { Props } from './OnCalendar'
import type { DateListCalendarPublicProps as CalendarProps } from '@/components/Calendar'

import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  max,
  parse,
  format,
  subDays,
  addDays,
  addYears,
  isSameDay,
  startOfMonth,
  eachDayOfInterval,
  subYears,
} from 'date-fns/esm'

import { selectTerritoryLicensingLimits } from '@/features/domain/territory'
import { selectUserConfiguration } from '@/features/domain/user'

const now = new Date()

export const useOnCalendarProps = ({ dates, onChange }: Props): CalendarProps => {
  const { planType, today } = useSelector(selectUserConfiguration)
  const todayDate = parse(today, 'yyyyMMdd', now)
  const { horizonView } = useSelector(selectTerritoryLicensingLimits)
  const selection = useMemo<Date[]>(() => dates.map(d => parse(d, 'yyyyMMdd', now)), [dates])

  const handleOnChange = useCallback(
    (newSelection: Date, currentSelection?: Date[]) => {
      if (!currentSelection) {
        onChange([newSelection])
        return
      }

      const duplicateMatchIndex = currentSelection.findIndex(date => isSameDay(date, newSelection))

      if (duplicateMatchIndex !== -1) {
        onChange(removeFromArray(currentSelection, duplicateMatchIndex))
        return
      }

      onChange([...currentSelection, newSelection])
    },
    [onChange],
  )

  const isSimulation = planType === 'simulation'

  return useMemo(() => {
    const firstDayDisabled = isSimulation ? subYears(todayDate, 10) : startOfMonth(todayDate)
    const lastVisibleDay = isSimulation ? addYears(todayDate, 10) : addYears(todayDate, 2)
    const minDate = format(firstDayDisabled, 'yyyyMMdd')
    const maxDate = format(lastVisibleDay, 'yyyyMMdd')

    const lastDayDisabled = max([firstDayDisabled, subDays(todayDate, 1)])
    const disableIntervalBeforeToday =
      isSimulation || isSameDay(todayDate, firstDayDisabled)
        ? []
        : eachDayOfInterval({
            start: firstDayDisabled,
            end: lastDayDisabled,
          })

    const disableIntervalAfterToday = isSimulation
      ? []
      : eachDayOfInterval({
          start: addDays(todayDate, horizonView),
          end: lastVisibleDay,
        })

    const disabledDays = [...disableIntervalBeforeToday, ...disableIntervalAfterToday].reduce(
      (acc, date) => {
        const key = format(date, 'yyyyMMdd')
        acc[key] = 1
        return acc
      },
      {},
    )

    return {
      today,
      layout: 'dates',
      selection,
      onChange: handleOnChange,
      disabledDays,
      minDate,
      maxDate,
      maxIntervalLength: horizonView,
    }
  }, [handleOnChange, horizonView, today, selection, isSimulation, todayDate])
}

const removeFromArray = (dates: Date[], index: number, count: number = 1): Date[] => {
  const clone = [...dates]
  clone.splice(index, count)
  return clone
}
