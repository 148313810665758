import { useCallback } from 'react'

import { useFitMapToSelection } from '@/map'

export function useActions() {
  const fitMapToSelection = useFitMapToSelection()

  const onFitMap = useCallback(() => {
    fitMapToSelection()
  }, [fitMapToSelection])

  return {
    onFitMap,
  }
}
