import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { parse } from 'date-fns/esm'
import { selectApprovedVersionRoutes } from '@/features/domain/scheduler'
import { selectRoutes } from '@/features/domain/route'

export function useRouteData(orderStepId: string, routeId: string) {
  const approvedRoutes = useSelector(selectApprovedVersionRoutes)
  const extRoutes = useSelector(selectRoutes)

  return useMemo(() => {
    const approvedRoute = approvedRoutes[routeId]
    const extRoute = extRoutes[routeId]

    return {
      plannedArrival: approvedRoute?.steps.find(s => s.id === orderStepId)?.startSec,
      date: parse(extRoute.route.date, 'yyyyMMdd', new Date()),
    }
  }, [routeId, extRoutes, orderStepId, approvedRoutes])
}
