import { PureComponent } from 'react'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  ReadonlyBadge,
  FlexRow,
} from '@/forms-legacy'

import * as OrderTexts from '../../../intl'

import { parseSecondToText } from '../utils'
import { Props } from '../typings'

export default class DetectedTime extends PureComponent<Props> {
  render() {
    const {
      trackingData: { autoTimeInSec, autoTimeOutSec },
    } = this.props

    const autoTimeInDisabled: boolean = autoTimeInSec === -1
    const autoTimeOutDisabled: boolean = autoTimeOutSec === -1

    const autoTimeInText = parseSecondToText(autoTimeInSec)
    const autoTimeOutText = parseSecondToText(autoTimeOutSec)

    return (
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <ReadonlyBlock half>
          <FlexRow>
            <ReadonlyBadge
              style={{ marginLeft: 6 }}
              background="#9ce0ff"
              disabled={autoTimeInDisabled}
            >
              3
            </ReadonlyBadge>
            <ReadonlyLabel>{OrderTexts.getDetectedTimeInLabel()}</ReadonlyLabel>
          </FlexRow>

          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{autoTimeInText}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>

        <ReadonlyBlock half>
          <FlexRow>
            <ReadonlyBadge
              style={{ marginLeft: 6 }}
              background="#9ce0ff"
              disabled={autoTimeOutDisabled}
            >
              4
            </ReadonlyBadge>
            <ReadonlyLabel>{OrderTexts.getDetectedTimeOutLabel()}</ReadonlyLabel>
          </FlexRow>

          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{autoTimeOutText}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      </FlexRow>
    )
  }
}
