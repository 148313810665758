import styled from 'styled-components'

const styledComponent = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  margin: 15px 0;
  background: ${p => p.theme.colors.$whiteSmoke};
`
styledComponent.displayName = 'HorizontalRuler'
export default styledComponent
