import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    cancelButtonTitle: translate({ id: 'duplicateOrderModal.cancelButton.title' }),
    duplicateButtonTitle: translate({ id: 'duplicateOrderModal.duplicateButton.title' }),
    duplicateAlso: (type: string) =>
      translate({
        id:
          type === 'p'
            ? 'duplicateOrderModal.duplicateAlso.dropoff'
            : 'duplicateOrderModal.duplicateAlso.pickup',
      }),
    duplicateAlsoBulk: translate({ id: 'duplicateOrderModal.duplicateAlso.bulk' }),
    emptyOrdersTitle: translate({ id: 'duplicateOrderModal.title.emptyOrders' }),
    warningCardEmptyOrdersTitle: translate({ id: 'duplicateOrderModal.invalid.emptyOrders.title' }),
    warningCardEmptyOrdersDesc: translate({
      id: 'duplicateOrderModal.invalid.emptyOrders.description',
    }),
    title: (type: string) =>
      translate({
        id: type === 'p' ? 'duplicateOrderModal.title.pickup' : 'duplicateOrderModal.title.dropoff',
      }),
    titleBulk: translate({ id: 'duplicateOrderModal.title.bulk' }),
    subtitle: ({ name, serviceTime }: { name: string; serviceTime: string | null }) =>
      !!serviceTime
        ? translate({
            id: 'duplicateOrderModal.subtitle.serviceTime',
            values: { name, serviceTime },
          })
        : translate({
            id: 'duplicateOrderModal.subtitle',
            values: { name },
          }),
    subtitleBulk: (ordersAmount: number) =>
      translate({
        id:
          ordersAmount > 1
            ? 'duplicateOrderModal.subtitle.bulk.plural'
            : 'duplicateOrderModal.subtitle.bulk.singular',
        values: { ordersAmount },
      }),
    transactionWarningCardTitle: translate({
      id: 'duplicateOrderModal.transaction.warningCard.title',
    }),
    transactionWarningCardDescription: translate({
      id: 'duplicateOrderModal.transaction.warningCard.description',
    }),
    warningCardTitle: (ordersAmount: number) =>
      translate({ id: 'duplicateOrderModal.warningCard.title', values: { ordersAmount } }),
    warningCardDescription: (ordersAmount: number) =>
      translate({ id: 'duplicateOrderModal.warningCard.description', values: { ordersAmount } }),
  }))

  return api
}
