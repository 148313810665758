import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    phoneTitle: translate({ id: 'sendMessageToDriverModal.phone.title' }),
    phoneErrorEmpty: translate({ id: 'sendMessageToDriverModal.phone.errors.empty' }),
    phoneErrorFormat: translate({ id: 'sendMessageToDriverModal.phone.errors.format' }),
  }))

  return api
}
