import { renderToStaticMarkup } from 'react-dom/server'
import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'

import { intl } from '@/intl'

import {
  ViolationsCell,
  ViolationsHeader,
  ViolationsTooltip,
} from '../../../components/cells/ViolationsCell'

export function createViolationsColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    text: intl.translate({ id: 'rm.scheduler.column.warnings' }),
    type: 'template',
    field: 'warnings',
    width,
    hidden,
    align: 'center',
    editor: false,
    region: 'cols',

    template: ({ record, field, value }) =>
      renderToStaticMarkup(
        <ViolationsCell record={record as ResourceModel} field={field} value={value} />,
      ),

    // renderToStaticMarkup is necessary to render React headers until it will support React components
    // https://github.com/bryntum/support/issues/5808
    resizable: true,
    groupable: false,
    headerRenderer: () => renderToStaticMarkup(<ViolationsHeader />),
    tooltipRenderer: ({ record }) => {
      const hasViolations = record.getData(
        'hasViolations',
      ) as uui.domain.client.rm.SchedulerResource['hasViolations']

      const available = record.getData(
        'available',
      ) as uui.domain.client.rm.SchedulerResource['available']

      if (!available || !hasViolations) return ''

      return renderToStaticMarkup(<ViolationsTooltip record={record as ResourceModel} />)
    },
  }
}
