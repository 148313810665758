import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    body: (count: number) =>
      translate({
        id: 'form.drivers.modal.delete.header.body',
        values: { count },
      }),

    warningTitle: translate({ id: 'form.drivers.modal.delete.body.invalid.warning.title' }),
    warningSubtitleAll: translate({
      id: 'form.drivers.modal.delete.body.invalid.warning.subtitle.all',
    }),
    warningSubtitlePartial: 'form.drivers.modal.delete.body.invalid.warning.subtitle.partial',
    today: translate({ id: 'global.today' }),
    tomorrow: translate({ id: 'global.tomorrow' }),
    yesterday: translate({ id: 'global.yesterday' }),
    day: translate({ id: 'global.day' }),
    days: translate({ id: 'global.days' }),
    canBeDeletedFrom: 'form.drivers.modal.delete.body.invalid.multiple.canBeDeletedFrom',
    driversThatCantBeDeleted: translate({
      id: 'form.drivers.modal.delete.body.invalid.multiple.driversThatCantBeDeleted',
    }),

    doNotAssignDriverForTheNext: translate({
      id: 'form.drivers.modal.delete.body.invalid.single.doNotAssignDriverForTheNext',
    }),
    theDriverCanBeDeletedFrom: translate({
      id: 'form.drivers.modal.delete.body.invalid.single.theDriverCanBeDeletedFrom',
    }),
    onlySomeDriversCanBeDeleted:
      'form.drivers.modal.delete.body.invalid.multiple.onlySomeDriversCanBeDeleted',

    lastActivity: (lastActivityText: string) =>
      translate({
        id: 'form.drivers.modal.delete.body.invalid.single.lastActivity',
        values: { lastActivityText },
      }),
    comeBackInDays: (deletionDateText: string) =>
      translate({
        id: 'form.drivers.modal.delete.body.invalid.single.comeBackInDays',
        values: { deletionDateText },
      }),
  }))

  return api
}
