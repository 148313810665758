import type { FormFields, FormErrors } from '../../../formFields'

import { Password as PasswordField } from '@workwave-tidal/tidal/form-ui/fields'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { ValidationSummary } from '@workwave-tidal/tidal/form-ui/utils/ValidationSummary'

import { useStandardPasswordValidations } from '@/hooks'

import { useTexts } from '../hooks/useTexts'

interface Props {
  firstSubmitDone?: boolean
}

const textfieldProps = {
  autoComplete: 'new-password',
}

export function Password(props: Props) {
  const { firstSubmitDone } = props
  const texts = useTexts()

  const { validationSummaryItems } = useStandardPasswordValidations<FormFields, FormErrors>(
    'new-password',
  )

  const {
    field: { visible },
  } = useFormField<'new-password', FormFields, FormErrors>('new-password')

  if (!visible) return null

  return (
    <>
      <PasswordField<'new-password', FormFields, FormErrors>
        name="new-password"
        testId="password"
        label={texts.labels.password}
        placeholder={texts.labels.password}
        validateOn="change blur"
        errorVisibility={firstSubmitDone ? 'accent-only' : 'none'}
        textfieldProps={textfieldProps}
      />
      <ValidationSummary<FormFields>
        items={validationSummaryItems}
        mode={firstSubmitDone ? 'show-errors' : 'silent-errors'}
      />
    </>
  )
}
