import { useState, useCallback } from 'react'

import { ImportOrders } from '@/icons'
import { copyToClipboard } from '@/utils'
import { Tooltip } from '@/components/primitives/Tooltip'
import { ReadonlyField, ReadonlyFieldRow, ReadonlyFieldLink } from '@/forms-legacy'

import { useTexts } from './useTexts'

import { IconButton } from './components/IconButton'

interface Props {
  link: string
  text: string
}

const rowStyle = { justifyContent: 'space-between', height: 40 }

const testidLink = 'trackingLink-link'
const testidCopyButton = 'trackingLink-copy-link'

export function TrackingLink(props: Props) {
  const { link, text } = props
  const texts = useTexts()

  const [status, setStatus] = useState<'ready' | 'copied'>('ready')

  const onCopyToClipboard = useCallback(async () => {
    const copied = await copyToClipboard(link)
    if (!copied) return
    setStatus('copied')

    setTimeout(() => {
      setStatus('ready')
    }, 2000)

    return () => {
      setStatus('ready')
    }
  }, [link])

  return (
    <ReadonlyField>
      <ReadonlyFieldRow style={rowStyle}>
        <Tooltip placement="top" title={texts.open}>
          <ReadonlyFieldLink
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
            data-testid={testidLink}
            data-trackid={testidLink}
          >
            {text}
          </ReadonlyFieldLink>
        </Tooltip>

        <Tooltip placement="top" title={status === 'ready' ? texts.copy : texts.copied}>
          <IconButton
            className="secondaryButton"
            onClick={onCopyToClipboard}
            data-testid={testidCopyButton}
            data-trackid={testidCopyButton}
          >
            <ImportOrders size={14} color={'$nightRider'} />
          </IconButton>
        </Tooltip>
      </ReadonlyFieldRow>
    </ReadonlyField>
  )
}
