import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    label: translate({ id: 'gps.places.edit.nameTitle' }),
    info: translate({ id: 'gps.places.edit.nameDescription' }),
  }))

  return api
}
