import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
} from '@/forms-legacy'

import { useTexts } from './useTexts'

interface Props {
  speedMonitor: uui.domain.client.gps.wwgps.SpeedMonitor
}

function getSpeedLimitThresholdValue(relativeThreshold: number | null, useMetric: boolean) {
  if (!relativeThreshold) {
    return ''
  }

  const threshold: number = useMetric ? milesToKilometers(relativeThreshold) : relativeThreshold
  return `${Math.round(threshold)}`
}

function milesToKilometers(miles: number) {
  return miles * 1.609344
}

export function SpeedLimit(props: Props) {
  const { speedMonitor } = props
  const texts = useTexts()
  const userConfig = useSelector(selectUserConfiguration)

  const enabled = speedMonitor.relativeThreshold !== null && !speedMonitor.disabled
  const metricFormat = userConfig.distanceFormat === 'METRIC'

  const fieldValue: string = `${getSpeedLimitThresholdValue(
    speedMonitor.relativeThreshold,
    metricFormat,
  )} ${texts.speedMeasure(metricFormat)}`

  return (
    <ReadonlyBlock>
      <ReadonlyLabel empty={!enabled} primary>
        {texts.postedSpeedLimit(enabled)}
      </ReadonlyLabel>
      {enabled && (
        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>{fieldValue}</ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      )}
    </ReadonlyBlock>
  )
}
