import { Text } from './Text'
import { Props } from './typings'

export function P2(props: Props) {
  const { children = '', as, className = '', style, size, weight, ...p } = props

  return (
    <Text size="$p2" weight="$regular" as={'p'} style={style} className={className} {...p}>
      {children}
    </Text>
  )
}
