import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    overlappingTags: translate({ id: 'order.form.bulk.errors.tag.overlapping' }),
    loads: translate({ id: 'orders.edit.field.loads' }),
    addLoad: translate({ id: 'order.form.bulk.loads.addLoadsModalTitle' }),
    waringCardTitle: translate({ id: 'order.form.bulk.loads.warningCard.title' }),
    waringCardText: translate({ id: 'order.form.bulk.loads.warningCard.text' }),
  }))

  return api
}
