import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function CalendarAlt(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M4,1 L4,2.3 L3.33333333,2.3 C2.6,2.3 2,2.885 2,3.6 L2,12.7 C2,13.415 2.6,14 3.33333333,14 L12.6666667,14 C13.4,14 14,13.415 14,12.7 L14,3.6 C14,2.885 13.4,2.3 12.6666667,2.3 L12,2.3 L12,1 L10.6666667,1 L10.6666667,2.3 L5.33333333,2.3 L5.33333333,1 L4,1 Z M3.33333333,5.55 L12.6666667,5.55 L12.6666667,12.7 L3.33333333,12.7 L3.33333333,5.55 Z" />
      </svg>
    </IconContainer>
  )
}
