import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function PaperPlaneWithStrikeThrough(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 8 8">
        <path d="M2.088 3.017l1.301 1.156-1.012.82-.155 1.578 1.059-1.195.571-.791 1.957 1.739-.287 1.147a.144.144 0 01-.055.082l-.045.021a.145.145 0 01-.14-.036L3.395 5.68 2.144 7.095a.144.144 0 01-.252-.11l.193-1.966-1.986-.648a.143.143 0 01-.098-.124.143.143 0 01.074-.14l2.013-1.09zM7.06.324a.143.143 0 01.157.013.144.144 0 01.051.15l-.862 3.449-1.271-1.13.08-.11-.107.086-.98-.87zM.811.422a.364.364 0 01.46-.07l.054.04L7.87 6.21a.364.364 0 01-.43.583l-.053-.04L.84.936a.364.364 0 01-.03-.513z" />
      </svg>
    </IconContainer>
  )
}
