import type { MouseInteractionProps as Props } from '../typings'

import { useRef } from 'react'

const containerStyle = { width: '100%' }

export function MouseInteraction(props: Props) {
  const { render, children, testId } = props
  const containerRef = useRef<HTMLDivElement | null>(null)

  const content = children ? children : render ? render() : null

  return (
    <div style={containerStyle} ref={containerRef} data-testid={testId} data-trackid={testId}>
      {content}
    </div>
  )
}
