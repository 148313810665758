import { useCallback } from 'react'
import { format } from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { version } from '@/utils'
import { getDateInTimezone } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export function useAttachAppInfoToMessage() {
  const { transitions } = useSelector(selectUserConfiguration)

  return useCallback(
    (message: string) => {
      const now = getDateInTimezone(new Date(), transitions)
      const suffix = `
\n\n
==========================================
  Generated: ${format(now, 'yyyy-MM-dd HH:mm')} - UUI@${version.getVersion()}
`
      return `${message}${suffix}`
    },
    [transitions],
  )
}
