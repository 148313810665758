import type { AxiosError } from 'axios'

import axios from 'axios'

type Request = {
  serverUrl: string
  password: string
  token: string
}

// possible errors code from API are:
// 200: RESET_PW_ERROR_USERNAME_MISSING
// 210: RESET_PW_ERROR_TOKEN_MISSING
// 211: RESET_PW_ERROR_TOKEN_MALFORMED
// 212: RESET_PW_ERROR_TOKEN_EXPIRED
// 220: RESET_PW_ERROR_PASSWORD_INVALID
// 221: RESET_PW_ERROR_USER_MISSING
// 222: RESET_PW_ERROR_USER_EMAIL_MISSING
// 299: RESET_PW_ERROR_INTERNAL

type ChangePasswordResponse = 'success' | 'invalidToken' | 'genericError'

type ApiFailure = {
  errorCode: number
  errorMessage: string
}

export async function changePassword({
  serverUrl,
  password,
  token,
}: Request): Promise<ChangePasswordResponse> {
  try {
    await axios.get(`${serverUrl}/resetpwuui`, {
      params: { password: encodeURI(password), token },
    })

    return 'success'
  } catch (e) {
    if (e.isAxiosError) {
      const { response } = e as AxiosError<ApiFailure>
      if (response) {
        const {
          data: { errorCode },
        } = response

        if (errorCode >= 210 && errorCode <= 220) {
          return 'invalidToken'
        }
      }
    }

    return 'genericError'
  }
}
