import { Card } from './Card'
import { CardContainer } from './CardContainer'

type Props = {
  firstOfRow?: boolean
}

export function EmptyDay(props: Props) {
  return (
    <CardContainer className="o-calendar__card" firstOfRow={props.firstOfRow}>
      <Card disabled className="o-calendar__card" />
    </CardContainer>
  )
}
