import type { Notifications } from '@/notificationForms'

import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { addMinutes, startOfToday } from 'date-fns'

import { selectUserAccountPreferences } from '@/features/domain/user'
import { durationUtils } from '@/server-data'

import { intl } from '@/intl'
import { useTexts } from '../useTexts'

type NotificationDetails = Record<
  Notifications,
  { subtitle: string; status?: string; enabled: boolean }
>

/**
 * That hook composes status messages for the notification list
 */
export function useStatusMessages(
  notificationStatus: uui.domain.client.rm.NotificationConfig,
  liveEtaStatus: 'active' | 'notActiveOnAllVehicles' | 'notActive' | 'notAvailableForTheUser',
) {
  const accountPreferences = useSelector(selectUserAccountPreferences)
  const texts = useTexts()

  return useMemo(() => {
    const {
      notCompletedSettings,
      delayedSettings,
      canceledSettings,
      completedSettings,
      earlySettings,
      twoDaysSettings,
      tomorrowSettings,
      almostThereSettings,
      todaySettings,
    } = notificationStatus

    const today = startOfToday()

    const liveEtaGloballyEnabled =
      liveEtaStatus !== 'notActive' && liveEtaStatus !== 'notAvailableForTheUser'
    const statuses: Partial<NotificationDetails> = {}

    // Delayed Notification
    if (delayedSettings.enabled && liveEtaGloballyEnabled) {
      const time = delayedSettings.toleranceMins
        ? durationUtils.formatMinutes(intl.translate, delayedSettings.toleranceMins * 60)
        : undefined

      statuses.delayedSettings = {
        subtitle: texts.notificationWay(delayedSettings.notificationWay),
        status: texts.delayedNotificationStatus(time),
        enabled: true,
      }
    } else {
      statuses.delayedSettings = {
        subtitle: liveEtaGloballyEnabled ? texts.disabled : texts.notAvailable,
        enabled: liveEtaGloballyEnabled && delayedSettings.enabled,
      }
    }

    // Early Notification
    if (earlySettings.enabled && liveEtaGloballyEnabled) {
      const time = earlySettings.toleranceMins
        ? durationUtils.formatMinutes(intl.translate, earlySettings.toleranceMins * 60)
        : undefined

      statuses.earlySettings = {
        subtitle: texts.notificationWay(earlySettings.notificationWay),
        status: texts.earlyNotificationStatus(time),
        enabled: true,
      }
    } else {
      statuses.earlySettings = {
        subtitle: liveEtaGloballyEnabled ? texts.disabled : texts.notAvailable,
        enabled: liveEtaGloballyEnabled && earlySettings.enabled,
      }
    }

    // Almost there Notification
    if (almostThereSettings.enabled && liveEtaGloballyEnabled) {
      const time = durationUtils.formatMinutes(
        intl.translate,
        almostThereSettings.proximityThresholdMins * 60,
      )

      statuses.almostThereSettings = {
        subtitle: texts.notificationWay(almostThereSettings.notificationWay),
        status: texts.almostThereNotificationStatus(time),
        enabled: almostThereSettings.enabled,
      }
    } else {
      statuses.almostThereSettings = {
        subtitle: liveEtaGloballyEnabled ? texts.disabled : texts.notAvailable,
        enabled: liveEtaGloballyEnabled && almostThereSettings.enabled,
      }
    }

    // Tomorrow Notification
    if (tomorrowSettings.enabled) {
      const time = durationUtils.formatHour(
        addMinutes(today, tomorrowSettings.notificationCutoffMins),
        accountPreferences,
      )

      statuses.tomorrowSettings = {
        subtitle: texts.notificationWay(tomorrowSettings.notificationWay),
        status: texts.tomorrowNotificationStatus(time),
        enabled: tomorrowSettings.enabled,
      }
    } else {
      statuses.tomorrowSettings = {
        subtitle: texts.disabled,
        enabled: tomorrowSettings.enabled,
      }
    }

    // Two days Notification
    if (twoDaysSettings.enabled) {
      const time = durationUtils.formatHour(
        addMinutes(today, twoDaysSettings.notificationCutoffMins),
        accountPreferences,
      )

      statuses.twoDaysSettings = {
        subtitle: texts.notificationWay(twoDaysSettings.notificationWay),
        status: texts.twoDaysNotificationStatus(time),
        enabled: twoDaysSettings.enabled,
      }
    } else {
      statuses.twoDaysSettings = {
        subtitle: texts.disabled,
        enabled: twoDaysSettings.enabled,
      }
    }

    // Today Notification
    if (todaySettings.enabled) {
      const time = durationUtils.formatHour(
        addMinutes(today, todaySettings.notificationCutoffMins),
        accountPreferences,
      )

      statuses.todaySettings = {
        subtitle: texts.notificationWay(todaySettings.notificationWay),
        status: texts.todayNotificationStatus(time),
        enabled: todaySettings.enabled,
      }
    } else {
      statuses.todaySettings = {
        subtitle: texts.disabled,
        enabled: todaySettings.enabled,
      }
    }

    // Status Canceled Notification
    statuses.canceledSettings = {
      subtitle: canceledSettings.enabled
        ? texts.notificationWay(canceledSettings.notificationWay)
        : texts.disabled,
      status: canceledSettings.enabled ? texts.canceledNotificationStatus : undefined,
      enabled: canceledSettings.enabled,
    }

    // Status Not Completed Notification
    statuses.notCompletedSettings = {
      subtitle: notCompletedSettings.enabled
        ? texts.notificationWay(notCompletedSettings.notificationWay)
        : texts.disabled,
      status: notCompletedSettings.enabled ? texts.notCompletedNotificationStatus : undefined,
      enabled: notCompletedSettings.enabled,
    }

    // Status Completed Notification
    statuses.completedSettings = {
      subtitle: completedSettings.enabled
        ? texts.notificationWay(completedSettings.notificationWay)
        : texts.disabled,
      status: completedSettings.enabled ? texts.completedNotificationStatus : undefined,
      enabled: completedSettings.enabled,
    }

    return statuses as NotificationDetails
  }, [texts, accountPreferences, liveEtaStatus, notificationStatus])
}
