import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'
import { VerticalLayout } from '@/components/layout'
import { Text } from '@/local/components'

import { useTexts } from '../useTexts'

interface Props {
  timeWindows: uui.domain.rm.TimeWindow[]
  violations?: uui.domain.rm.RouteStepViolationsType[]
}

export function OrderTimeWindow(props: Props) {
  const { timeWindows, violations } = props
  const texts = useTexts()

  const color = (violations || []).includes('TW') ? '$darkOrange' : '$nightRider'

  return (
    <VerticalLayout marginTop={8}>
      <Text size="$s" weight="$semiBold" color="$shadyLady" uppercase>
        {texts.timeWindows}
      </Text>

      {timeWindows.map((timeWindow, index) => (
        <Text key={index} size="$m" color={color}>
          <ConvertTime seconds={timeWindow.startSec} showSeconds={false} />
          {` - `}
          <ConvertTime seconds={timeWindow.endSec} showSeconds={false} />
        </Text>
      ))}
    </VerticalLayout>
  )
}
