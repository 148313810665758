import { type ReactNode, type MouseEvent, useState, useCallback } from 'react'
import { Box, Menu, type PopoverOrigin } from '@mui/material'
import { ListHeaderSorterTrigger } from './ListHeaderSorterTrigger'

type Props = {
  triggerText: ReactNode
  disabled?: boolean
  children: ReactNode
}

const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }

export function ListHeaderSorter(props: Props) {
  const { children, disabled = false, triggerText } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)

  const onToggleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }, [])

  const onClose = useCallback(() => {
    setAnchorEl(null)
    setOpen(false)
  }, [])

  return (
    <Box>
      <ListHeaderSorterTrigger
        onToggleClick={onToggleClick}
        disabled={disabled}
        testId="list-header-sorter-trigger"
        open={open}
      >
        {triggerText}
      </ListHeaderSorterTrigger>
      <Menu
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        data-testid={'list-header-sorter-menu'}
        anchorOrigin={anchorOrigin}
        data-trackid={'list-header-sorter-menu'}
      >
        {children}
      </Menu>
    </Box>
  )
}
