import { useMemo } from 'react'

import { VehicleLoadsListField } from '@/forms-legacy'

import { useTexts } from '../../hooks/useTexts'

import { loadsRenderTexts } from './loadsRenderTexts'

interface Props {
  loadsSuggestions: string[]
  metric: boolean
}

export function Loads(props: Props) {
  const { metric, loadsSuggestions } = props
  const texts = useTexts()

  const wrappedInputProps = useMemo(() => {
    return {
      suggestions: loadsSuggestions,
      renderText: loadsRenderTexts,
      maxCount: 5,
      useMetric: metric,
    }
  }, [metric, loadsSuggestions])

  return (
    <VehicleLoadsListField
      name="loads"
      testid="loads"
      label={texts.loads}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
