import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { copyMobileAppInteraction } from '@/features/domain/territory'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export function useControllerActions() {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const {
    close,
    updateData,
    data: { id },
  } = useController()

  const onTerritoryChange = useCallback(item => updateData({ id: item.id }), [updateData])

  const onConfirm = useCallback(async () => {
    if (!id) return

    try {
      const result = await dispatch(copyMobileAppInteraction(id))

      if (copyMobileAppInteraction.rejected.match(result)) {
        throw new Error(result.error.message)
      }

      toast.success(texts.success)

      close?.()
    } catch (e) {
      toast.error(texts.error)
    }
  }, [dispatch, toast, close, texts, id])

  return {
    onTerritoryChange,
    onConfirm,
  }
}
