import styled from 'styled-components'

type Props = {
  selected?: boolean
  disabled?: boolean
}

const ItemRendererLabel = styled.p<Props>`
  color: ${p =>
    p.selected
      ? p.theme.colors.$pureWhite
      : p.disabled
      ? p.theme.colors.$shadyLady
      : p.theme.colors.$nightRider};
  font-size: ${p => p.theme.fonts.$p3};
  margin-top: 0;
  margin-bottom: 0;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

ItemRendererLabel.displayName = 'ItemRendererLabel'
export default ItemRendererLabel
