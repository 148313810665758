import {
  ItemRendererTitle,
  ItemRendererLabel,
  FlexRow,
  FlexColumn,
  TextButton,
} from '@/forms-legacy'
import { intl } from '@/intl'
import { recurrenceToString } from '@/server-data'
import { useVehicleFormException } from '@/atoms'

import { getRecurrences } from '../../../../../../utils/getRecurrences'

import { ExceptionSliderControls } from './components/ExceptionSliderControls'
import { useTexts } from './useTexts'

interface Props {
  switchToListExceptions: () => void
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function ExceptionSlider(props: Props) {
  const { switchToListExceptions, unifiedVehicle } = props
  const [{ exception }] = useVehicleFormException()
  const texts = useTexts()

  const recurrences = getRecurrences(unifiedVehicle)
  const currentRecurrence = recurrences.find(rec => rec.source === exception)

  if (!currentRecurrence) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('current recurrence can not be find')
    }
    return null
  }

  const recurrence = recurrenceToString(intl.translate)(currentRecurrence)

  return (
    <FlexRow useAutoWidth>
      <ExceptionSliderControls recurrences={recurrences} />
      <FlexColumn style={{ marginLeft: 14 }}>
        <ItemRendererTitle>{texts.title(recurrence)}</ItemRendererTitle>
        <FlexRow>
          <ItemRendererLabel>{texts.subtitle(recurrences.length - 1)}</ItemRendererLabel>
          {recurrences.length > 1 && (
            <TextButton style={{ marginLeft: 8 }} onClick={switchToListExceptions}>
              {texts.showAll}
            </TextButton>
          )}
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  )
}
