import styled from 'styled-components'

const styledComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding-left: 8px;

  > * + * {
    margin-top: 2px;
  }
`

styledComponent.displayName = 'RadioGroupContainer'
export default styledComponent
