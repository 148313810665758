import { useSelector } from 'react-redux'

import { selectTerritoryLicensingLimits } from '@/features/domain/territory'
import { selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { selectUserConfiguration } from '@/features/domain/user'

// return how many free RM vehicle license a user can add
export function useCountRemainingVehicle() {
  const { planType } = useSelector(selectUserConfiguration)
  const isSimulation = planType === 'simulation'

  const { vehiclesOps, vehiclesSim } = useSelector(selectTerritoryLicensingLimits)

  const vehicles = useSelector(selectVehiclesByVehicleId)
  const vehiclesCount = Object.keys(vehicles).length

  const limit = isSimulation ? vehiclesSim : vehiclesOps
  return limit - vehiclesCount
}
