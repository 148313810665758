import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    confirm: goingToEnable =>
      translate({
        id: goingToEnable
          ? 'sms4.settings.communication.notifications.confirmToggleModal.footer.confirm.enable'
          : 'sms4.settings.communication.notifications.confirmToggleModal.footer.confirm.disable',
      }),

    cancel: translate({
      id: 'global.cancel',
    }),
  }))

  return api
}
