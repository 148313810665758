import { Stack, Typography } from '@mui/material'

import { useTexts } from '../../../../useTexts'

import { Username } from './components/Username'
import { Password } from './components/Password'
import { Database } from './components/Database'

export function Geotab() {
  const texts = useTexts()

  return (
    <Stack spacing={2} width="100%">
      <Typography variant="caption">{texts.geotab.instructions}</Typography>

      <Username />
      <Password />
      <Database />
    </Stack>
  )
}
