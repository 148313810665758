import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Letter(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M4.2 4.8a2.704 2.704 0 00-2.691 2.475L10.5 12.13l8.991-4.855A2.704 2.704 0 0016.8 4.8H4.2zm-2.7 4v7.7c0 1.49 1.21 2.7 2.7 2.7h12.6c1.49 0 2.7-1.21 2.7-2.7V8.8l-8.68 4.695a.675.675 0 01-.64.001L1.5 8.8z" />
      </svg>
    </IconContainer>
  )
}
