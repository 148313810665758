import type { CSSProperties } from 'react'
import type { Props } from './FormValidationIndicator'

import { useMemo } from 'react'
import { useTheme } from '@mui/material'

import classes from './styles.module.css'

export function SuperCarIndicator(props: Props) {
  const { size, duration } = props
  const theme = useTheme()

  const primaryMainColor = theme.palette.primary.main

  const styles = useMemo(
    () => ({
      root: {
        '--duration': duration ? `${duration}s` : undefined,
        '--startPosition': `-${size}px`,
        '--color': primaryMainColor,
        '--size': `${size}px`,
      } as CSSProperties,
    }),
    [primaryMainColor, duration, size],
  )

  return <div className={classes.superCar} style={styles.root} />
}
