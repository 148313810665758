import { useSelector } from 'react-redux'

import { selectCustomerNotificationsSettings } from '@/features/domain/territory'

export type CanceledNotificationValues = ReturnType<typeof useCanceledNotificationValues>

/**
 * That hook grabs canceledNotification values from the domain and returns them
 */
export function useCanceledNotificationValues() {
  const notificationSettings = useSelector(selectCustomerNotificationsSettings)

  return notificationSettings['canceledSettings']
}
