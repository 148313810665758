import {
  SecondaryColumnContentHorizontalRuler,
  SecondaryColumnContentContainer,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'
import { isMultipleValues, getDrivingTimeFactorLabel } from './utils'
import { useComputeTimeWindowsValues } from './hooks/useComputeTimeWindowsValues'

interface Props {
  levels: number[]
  timeWindows: [uui.domain.rm.TimeWindow?, uui.domain.rm.TimeWindow?]
  timeWindowsLevels: number[][]
}

export function ViewDetails(props: Props) {
  const { timeWindows, timeWindowsLevels, levels } = props
  const texts = useTexts()
  const timeWindowValues = useComputeTimeWindowsValues(timeWindows, timeWindowsLevels)

  if (levels.length === 0) {
    return null
  }

  return (
    <SecondaryColumnContentContainer>
      <SecondaryColumnContentHorizontalRuler bottomSpace />

      <ReadonlyBlock half>
        <ReadonlyLabel primary>{texts.defaultDrivingTime}</ReadonlyLabel>

        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>
              {isMultipleValues(levels)
                ? texts.multipleValues
                : getDrivingTimeFactorLabel(levels[0])}
            </ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyBlock>

      <SecondaryColumnContentHorizontalRuler bottomSpace />

      <ReadonlyLabel primary>{texts.TimeWindowsDrivingTime}</ReadonlyLabel>

      {timeWindowValues.map(val => (
        <ReadonlyBlock half key={val.key}>
          <ReadonlyLabel>{val.key}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{val.value}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      ))}
    </SecondaryColumnContentContainer>
  )
}
