import { getReduxStore } from '@/store'

type DomainMarkersData = Readonly<{
  mapMarkers: uui.domain.ui.map.markers.MapMarkers
  mapStyles: uui.domain.ui.map.markers.MapStyles
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange
  licensingLimits: uui.domain.server.rm.LicensingLimits
  gpsRole?: string
}>

export function getDomainMarkerData(): DomainMarkersData {
  const reduxStore = getReduxStore()

  const {
    domain: {
      publicData: {
        domain: { mapMarkers, mapStyles },
        breadcrumbTimeRange,
        profile: {
          account: { licensingLimits },
          gpsUser,
        },
      },
      extendedOrderSteps,
    },
  } = reduxStore.getState()

  return {
    mapMarkers,
    mapStyles,
    extendedOrderSteps,
    breadcrumbTimeRange,
    licensingLimits,
    gpsRole: gpsUser?.role,
  }
}
