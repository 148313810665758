import type { useEarlyNotificationValues } from './hooks/useEarlyNotificationValues'

export function convertValues(formValues: ReturnType<typeof useEarlyNotificationValues>) {
  // Convert toleranceMins from string to number
  const toleranceMins = parseInt(formValues.toleranceMins)

  return {
    ...formValues,
    toleranceMins,
  }
}
