import type { DropdownItem } from '@/forms-legacy'

import { useMemo } from 'react'

import { DropDownField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'
import { NotSet } from '../components/NotSet'

interface Props {
  depots: uui.domain.client.rm.Depot[]
}

export function DepartureDepotId(props: Props) {
  const { depots } = props

  const texts = useTexts()

  const values = useMemo<DropdownItem[]>(() => {
    return depots.map(depot => {
      return { id: depot.id, label: depot.name }
    })
  }, [depots])

  return (
    <DropDownField
      values={values}
      name="departureDepotId"
      testid="departureDepotId"
      label={texts.startLocation}
      disabledValue="disabledDepotValue"
      DisabledState={() => <NotSet text={texts.notSet} />}
      className="o-pendo__vehicle-form__start-location"
    />
  )
}
