import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function TriangleDown(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M7.52 11.027l-2.8-3.734a.6.6 0 01.48-.96h5.6a.6.6 0 01.48.96l-2.8 3.734a.6.6 0 01-.96 0z" />
      </svg>
    </IconContainer>
  )
}
