import type { FormErrors, FormFields } from './formFields'
import type { NotificationFormValues, NotificationFormConfig } from './types'
import type { NotificationFormType } from '@/notificationForms'

import { useState } from 'react'

import { FormProvider } from '@workwave-tidal/tidal/form-ui/layout'

import { NotificationForm } from './NotificationForm'

import { getFormFields } from './formFields'

type Props = {
  onSubmit: (formValues: Readonly<NotificationFormValues>) => Promise<boolean>
  values: Partial<NotificationFormValues>
  notification: NotificationFormType
  config?: NotificationFormConfig
  initHook: () => void
  cancel: () => void
  subtitle: string
  title: string
}

export function NotificationFormProvider(props: Props) {
  const { title, subtitle, config, values, initHook, cancel, onSubmit, notification } = props

  // Updates config with generic validations
  const [formFields] = useState(() => getFormFields(values, notification))

  // Render the Form inside a FormRoot component that will expose the FormState API through a React.Context
  return (
    <FormProvider<FormFields, FormErrors> name="voice-form" fields={formFields} config={config}>
      <NotificationForm
        notification={notification}
        subtitle={subtitle}
        initHook={initHook}
        onSubmit={onSubmit}
        cancel={cancel}
        title={title}
      />
    </FormProvider>
  )
}
