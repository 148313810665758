import { makeStyles } from '@mui/styles'
import { theme } from '@/styles'

export const useStyles = makeStyles({
  wrapper: {
    bottom: 30,
    right: 30,
    zIndex: 9999,
    position: 'fixed',
  },
  container: {
    zIndex: 9999,
    width: '40vw',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    backgroundColor: '#FFFFFFEB',
  },
  content: { padding: 30, overflow: 'hidden' },
  scrollbar: { marginTop: 20 },
  header: {
    padding: '10px 30px',
    width: '100%',
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.$paleSky,
  },
})
