import { useCallback, useState } from 'react'
import { updateMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'showPlaces' | 'hidePlaces'
export function useActions() {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const showPlaces = useCallback(async () => {
    setMethodInExecution('showPlaces')

    await dispatch(updateMapStyle({ type: 'place', mode: 'on' }))

    setMethodInExecution(null)
  }, [dispatch])

  const hidePlaces = useCallback(async () => {
    setMethodInExecution('hidePlaces')

    await dispatch(updateMapStyle({ type: 'place', mode: 'off' }))

    setMethodInExecution(null)
  }, [dispatch])

  return { methodInExecution, actions: { showPlaces, hidePlaces } }
}
