import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'settings.gpsAccount.edit.header.title' }),
    headerDescription: translate({ id: 'settings.gpsAccount.edit.header.description' }),
    username: translate({ id: 'settings.gpsAccount.view.username' }),
    email: translate({ id: 'settings.gpsAccount.view.email' }),
  }))

  return api
}
