import type { Options as IconOptions } from 'ol/style/Icon'
import { Icon, Style } from 'ol/style'

import ordersSpriteSheet from '@/resources/map-markers/orders.png'
import ordersMinSpriteSheet from '@/resources/map-markers/orders_min.png'

import { genericStyles } from '../../../genericStyles'

const orderStylesCache: Map<string, Style> = new Map()

export function getMarkerStyle(
  marker: uui.domain.ui.map.markers.Order,
  mode: uui.domain.ui.map.markers.MapStyles['order']['mode'],
  color: string,
  selected: boolean = false,
) {
  if (mode === 'off') return genericStyles.hidden

  const spriteSheetUrl = mode === 'on' ? ordersMinSpriteSheet : ordersSpriteSheet
  const [x, y, cellSize] = mode === 'on' ? marker.minimized : marker.maximized

  const cacheId = `${mode}_${x}_${y}_${selected ? 'selected' : 'not-selected'}_${color}`

  if (orderStylesCache.has(cacheId)) {
    const cachedStyle = orderStylesCache.get(cacheId)

    if (!cachedStyle) {
      throw new Error(`Cannot find cached style with key ${cacheId}`)
    }

    return cachedStyle
  }

  const opts: IconOptions = {
    anchor: [0.5, 0.5],
    anchorOrigin: 'top-left',
    anchorYUnits: 'fraction',
    anchorXUnits: 'fraction',
  }

  const style = new Style({
    zIndex: selected ? 2 : mode === 'maximized' ? 1 : undefined,
    image: new Icon({
      src: spriteSheetUrl,
      color,
      crossOrigin: 'anonymous',

      size: [cellSize, cellSize],
      scale: 0.5,
      offset: [x, y],
      opacity: 1.0,
      rotation: 0.0,
      rotateWithView: true,

      ...opts,
    }),
  })

  orderStylesCache.set(cacheId, style)

  return style
}
