import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDrivers } from '@/features/domain/driver'
import { selectCalendarRange } from '@/features/domain/ui'
import * as uvHelpers from '@/local/server-data/domain/helpers/unifiedVehicle'

export function useVehicleCalendarizedInfo(vehicle: uui.domain.client.UnifiedVehicle) {
  const drivers = useSelector(selectDrivers)
  const calendarRange = useSelector(selectCalendarRange)

  return useMemo(() => {
    // this hook will run in the tracking list
    // where only a single date could be selected
    if (calendarRange.minDate !== calendarRange.maxDate) {
      return {
        avatarLabel: '',
        label: '',
        deviceId: '',
      }
    }

    const label = uvHelpers.getLabel(drivers, vehicle, calendarRange.minDate)

    const avatarLabel = vehicle.hasRoutingLicense ? `${vehicle.vehicle.idx}` : label

    const deviceId = uvHelpers.getDeviceId(drivers, vehicle, calendarRange.minDate)

    return {
      label,
      avatarLabel,
      deviceId: deviceId ?? '',
    }
  }, [vehicle, drivers, calendarRange])
}
