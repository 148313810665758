import { useCallback, useState, useEffect, useRef } from 'react'
import { type Column, type Selection } from '../types'

export function useColumns(
  allColumns: Column[],
  selection: Selection['selection'],
  onSelectionChange: Selection['onSelectionChange'],
) {
  const allColumnsById = useRef<Record<string, Column>>(computeColumnsById(allColumns))
  const allColumnsRef = useRef<Column[]>(allColumns)
  useEffect(() => {
    allColumnsById.current = computeColumnsById(allColumns)
    allColumnsRef.current = allColumns
  }, [allColumns])

  const [filter, setFilter] = useState(() => '')

  const [columns, setColumns] = useState<Column[]>(() => [])

  const [selectedColumns, setSelectedColumns] = useState<Column[]>(() =>
    // filter(Boolean) will grant that any previously exported
    // column that may not be available in this export will be ignored
    selection.map(s => allColumnsById.current[s]).filter(Boolean),
  )

  const onChangeFilter = useCallback((value: string) => {
    setFilter(value)
  }, [])

  const onRemoveColumn = (id: string) => {
    onSelectionChange(selection.filter(c => c !== id))
  }

  useEffect(() => {
    setColumns(allColumnsRef.current.filter(c => applyFilter(c, filter)).sort(sortColumnsByLabel))
  }, [filter])

  useEffect(() => {
    // filter(Boolean) will grant that any previously exported
    // column that may not be available in this export will be ignored
    setSelectedColumns(selection.map(s => allColumnsById.current[s]).filter(Boolean))
  }, [selection])

  return {
    columns,
    onRemoveColumn,
    filter,
    onChangeFilter,
    selectedColumns,
  }
}

function applyFilter(column: Column, filter: string) {
  return column.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
}

function sortColumnsByLabel(a: Column, b: Column) {
  return a.label.localeCompare(b.label)
}

function computeColumnsById(allColumns: Column[]) {
  return allColumns.reduce((acc, curr) => {
    acc[curr.id] = curr
    return acc
  }, {} as Record<string, Column>)
}
