import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    label: (shared: number, count: number) =>
      translate({
        id: 'order.form.bulk.loads.partial.sharedLoad',
        values: { shared, count },
      }),
    addTooltip: translate({ id: 'order.form.bulk.loads.partial.addTooltip' }),
    fieldDisabled: translate({ id: 'order.form.bulk.loads.fieldDisabled.tooltip' }),
    addTooltipDisabled: (maxLoads: number) =>
      translate({ id: 'order.form.bulk.loads.addLoad.disabled.tooltip', values: { maxLoads } }),
    removeTooltip: translate({ id: 'order.form.bulk.loads.partial.removeTooltip' }),
  }))

  return api
}
