import { format, addSeconds, startOfToday } from 'date-fns/esm'

export function secToTimeHHmm(
  sec: number,
  openLabel: string = '',
  timeFormat: uui.domain.client.TimeFormat = 'hh:mm aa',
): string {
  if (!Number.isFinite(sec)) return ''
  if (sec === -1) return openLabel

  const time = addSeconds(startOfToday(), sec)
  return format(time, timeFormat)
}
