import { useEffect } from 'react'

import { updateNetworkConnectionStatus as updateNetworkConnectionStatusAction } from '@/features/actions/updateNetworkConnectionStatus'
import { useAppDispatch } from '@/store'

export function useOfflineMonitor() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const updateNetworkConnectionStatus = (e: Event) => {
      dispatch(updateNetworkConnectionStatusAction(e.type === 'online'))
    }

    window.addEventListener('online', updateNetworkConnectionStatus)
    window.addEventListener('offline', updateNetworkConnectionStatus)

    return () => {
      window.removeEventListener('online', updateNetworkConnectionStatus)
      window.removeEventListener('offline', updateNetworkConnectionStatus)
    }
  }, [dispatch])
}
