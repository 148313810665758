import { AccelerationList } from './components/AccelerationList'
import { CorneringList } from './components/CorneringList'
import { BrakingList } from './components/BrakingList'

interface Props {
  driverBehaviors: uui.domain.client.gps.wwgps.DriverBehaviorSettings
}

export function DriverBehaviors(props: Props) {
  const { driverBehaviors } = props

  return (
    <>
      <BrakingList driverBehaviors={driverBehaviors} />
      <AccelerationList driverBehaviors={driverBehaviors} />
      <CorneringList driverBehaviors={driverBehaviors} />
    </>
  )
}
