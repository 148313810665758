import type { PopoverOrigin } from '@mui/material'

import { useMemo } from 'react'

type placement = 'top' | 'bottom' | 'left' | 'right'

const offset = 8

export const useTooltipPositioning = (placement: placement) => {
  const anchorOrigin: PopoverOrigin = useMemo(() => {
    switch (placement) {
      case 'top':
        return { vertical: -offset, horizontal: 'center' }
      case 'bottom':
        return { vertical: 'bottom', horizontal: 'center' }
      case 'left':
        return { vertical: 'center', horizontal: -offset }
      case 'right':
        return { vertical: 'center', horizontal: 'right' }
    }
  }, [placement])

  const transformOrigin: PopoverOrigin = useMemo(() => {
    switch (placement) {
      case 'top':
        return { vertical: 'bottom', horizontal: 'center' }
      case 'bottom':
        return { vertical: -offset, horizontal: 'center' }
      case 'left':
        return { vertical: 'center', horizontal: 'right' }
      case 'right':
        return { vertical: 'center', horizontal: -offset }
    }
  }, [placement])

  return { anchorOrigin, transformOrigin }
}
