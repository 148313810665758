import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    edit: translate({ id: 'setup.driverAssistance.view.edit' }),
    copy: translate({ id: 'setup.driverAssistance.view.copy' }),
    oneTerritory: translate({ id: 'setup.driverAssistance.view.oneTerritory' }),
    modeHeader: translate({ id: 'setup.driverAssistance.view.modeHeader' }),
    title: translate({ id: 'setup.DriverAssistance.view.title' }),
    description: translate({ id: 'setup.driverAssistance.view.description' }),
  }))

  return api
}
