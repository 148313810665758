import { VerticalLayout } from '@/components/layout'
import { WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function FailureBody() {
  const texts = useTexts()
  const { data } = useController()

  const address = data.messageType === 'email' ? data.email : data.phone
  if (!address) return null

  return (
    <VerticalLayout marginTop={32}>
      <WarningCard
        preset="warning"
        title={texts.resultErrorTitle}
        style={{ wordBreak: 'break-word' }}
        description={texts.resultErrorDescription(address)}
        Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
      />
    </VerticalLayout>
  )
}
