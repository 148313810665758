import type { TrafficAreaFormValuesValidation, TrafficAreaFormValues } from '../types'

import { useCallback } from 'react'

import { useTexts } from './useTexts'

export function useFormValidation() {
  const texts = useTexts()

  return useCallback(
    (formValues: TrafficAreaFormValues) => {
      const { name, points } = formValues
      const validation: TrafficAreaFormValuesValidation = {}

      if (!name) {
        validation.name = texts.errorName
      }

      // || !closed
      if (points.length < 3) {
        validation.points = texts.errorPoints
      }

      return validation
    },
    [texts],
  )
}
