import type { NotificationFormValues } from './types'
import type { NotificationFormType } from '@/notificationForms'
import type { FormError, FormField } from '@workwave-tidal/tidal/form-fairy'

import { produce } from 'immer'

// ---------------------------------------------
// GENERIC FIELDS
// ---------------------------------------------
const notificationWayField: FormField<uui.domain.client.rm.NotificationWay> = {
  value: 'EMAIL',
}

const enabledField: FormField<boolean> = {
  value: true,
  required: true,
}

const subjectCustomField: FormField<string> = {
  required: true,
  visible: true,
  value: '',
}

const templateCustomField: FormField<string> = {
  required: true,
  value: '',
}

const includeMessageUnsubscriptionInstructionsField: FormField<boolean> = {
  value: false,
}

const includeMessageTrackingLinkField: FormField<boolean> = {
  value: false,
}

const backupNotificationField: FormField<boolean> = {
  required: false,
  visible: false,
  value: false,
}

const templateDefaultField: FormField<string> = {
  required: true,
  visible: false,
  value: '',
}

const subjectDefaultField: FormField<string> = {
  required: true,
  visible: false,
  value: '',
}

const backupSubjectCustomField: FormField<string> = {
  required: false,
  visible: false,
  value: '',
}

const templateDefaultLimitsField: FormField<uui.domain.client.rm.NotificationTemplateLimits> = {
  required: true,
  visible: false,
  value: {},
}

const templateCustomLimitsField: FormField<uui.domain.client.rm.NotificationTemplateLimits> = {
  required: true,
  visible: false,
  value: {},
}

const templatePreviewField: FormField<string> = {
  required: false,
  visible: false,
  value: '',
}

// ---------------------------------------------
// FUTURE (TOMORROW, TWO DAYS) + TODAY NOTIFICATION FIELDS
// ---------------------------------------------

const notificationCutoffMinsField: FormField<number> = {
  disabled: true,
  visible: false,
  value: 0,
}

// ---------------------------------------------
// FUTURE (TOMORROW, TWO DAYS) NOTIFICATION FIELDS
// ---------------------------------------------

const alwaysNotifyAfterCutoffField: FormField<boolean> = {
  visible: false,
  value: false,
}

// ---------------------------------------------
// ALMOST THERE NOTIFICATION FIELDS
// ---------------------------------------------

const proximityThresholdMinsField: FormField<string> = {
  visible: false,
  value: '10',
}

// ---------------------------------------------
// TOLERANCE (EARLY, DELAYED) NOTIFICATION FIELDS
// ---------------------------------------------

const toleranceMinsField: FormField<string> = {
  visible: false,
  value: 'No',
}

// ---------------------------------------------
// TODAY NOTIFICATION FIELDS
// ---------------------------------------------

const windowOfCommitmentSnapToMinsField: FormField<number> = {
  required: true,
  visible: false,
  value: 0,
}

const windowOfCommitmentToleranceMinsField: FormField<string> = {
  visible: false,
  value: '30',
}

const formFields = {
  includeMessageUnsubscriptionInstructions: includeMessageUnsubscriptionInstructionsField,
  windowOfCommitmentToleranceMins: windowOfCommitmentToleranceMinsField,
  windowOfCommitmentSnapToMins: windowOfCommitmentSnapToMinsField,
  includeMessageTrackingLink: includeMessageTrackingLinkField,
  alwaysNotifyAfterCutoff: alwaysNotifyAfterCutoffField,
  notificationCutoffMins: notificationCutoffMinsField,
  proximityThresholdMins: proximityThresholdMinsField,
  templateDefaultLimits: templateDefaultLimitsField,
  templateCustomLimits: templateCustomLimitsField,
  backupSubjectCustom: backupSubjectCustomField,
  backupNotification: backupNotificationField,
  notificationWay: notificationWayField,
  templatePreview: templatePreviewField,
  templateDefault: templateDefaultField,
  subjectDefault: subjectDefaultField,
  templateCustom: templateCustomField,
  toleranceMins: toleranceMinsField,
  subjectCustom: subjectCustomField,
  enabled: enabledField,
}

export type FormFields = typeof formFields
export type FormErrors = FormError<keyof FormFields>

/**
 * This method changes visibility/mandatory state of the fields based on the initial values
 * NOTE: We're setting up mandatory state before the initialization because, otherwise, the form will change his state to `indeterminate`
 */
export function getFormFields(
  values: Partial<NotificationFormValues>,
  notification: NotificationFormType,
) {
  if (!values) return formFields

  return produce(formFields, draft => {
    for (const key in values) {
      draft[key].value = values[key]
    }

    // Set notificationCutoffMins for forms that requires it
    const formsWithNotificationTime = ['today', 'tomorrow', 'twoDays']

    if (formsWithNotificationTime.includes(notification)) {
      draft.notificationCutoffMins.disabled = false
      draft.notificationCutoffMins.required = true
      draft.notificationCutoffMins.visible = true
    }

    if (draft.notificationWay.value === 'PHONE') {
      // Changes based on the Notification Type
      // Disable subjectCustom field if the notification is an SMS
      draft.backupNotification.visible = true
      draft.subjectCustom.required = false
      draft.subjectCustom.visible = false

      // Enable backupSubjectCustom field if the backupNotification is active
      if (draft.backupNotification.value) {
        draft.backupSubjectCustom.required = true
        draft.backupSubjectCustom.visible = true
      }
    }

    // If the hotword @@ETARange@@ is present in templateCustom's value
    // the windowOfCommitmentToleranceMins will be required
    if (draft.templateCustom.value.indexOf('@@ETARange@@') !== -1) {
      draft.windowOfCommitmentToleranceMins.required = false
      draft.windowOfCommitmentToleranceMins.visible = true
    }
  })
}
