import type { SyntheticEvent, CSSProperties } from 'react'
import type { ValidationSummaryItem } from '@workwave-tidal/tidal/form-ui/utils/ValidationSummary'
import type { FormFields } from '../../../formFields'

import { useCallback, useState } from 'react'
import { Stack, Alert } from '@mui/material'
import { ValidationSummary } from '@workwave-tidal/tidal/form-ui/utils/ValidationSummary'
import { FormContainer } from '@workwave-tidal/tidal/form-ui/core'

import { ModalContent } from '@/components/Modal'

import { useOnSubmit } from '../../hooks/useOnSubmit'
import { useTexts } from '../../hooks/useTexts'

import { Footer } from '../Footer'
import { Header } from '../Header'

import { NewPassword } from './components/fields/NewPassword'

interface Props {
  validationSummaryItems?: ValidationSummaryItem<FormFields>[]
}

const formStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'block',
  position: 'relative',
}

export function Form(props: Props) {
  // Destructure the `validationSummaryItems` prop and set a default value
  const { validationSummaryItems = [] } = props

  // State for checking if first submit is done
  const [firstSubmitDone, setFirstSubmitDone] = useState(false)

  // Callback for handling submit
  const onSubmit = useOnSubmit(setFirstSubmitDone)

  // Texts for the form
  const texts = useTexts()

  // Form submit handler
  const onFormSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault()
      onSubmit()
    },
    [onSubmit],
  )

  // Mode for `ValidationSummary` component
  const validationSummaryMode = firstSubmitDone ? 'show-errors' : 'silent-errors'

  return (
    <ModalContent header={<Header />} footer={<Footer onSubmit={onSubmit} />} maxWidth={600}>
      <form style={formStyle} onSubmit={onFormSubmit}>
        <Stack paddingTop={2} spacing={2} width={568} maxWidth="100%">
          <FormContainer testId="rm-user-change-password-form" width={300}>
            <Stack spacing={2} width="100%">
              <NewPassword firstSubmitDone={firstSubmitDone} />

              {/* Pass down the `validationSummaryItems` and `validationSummaryMode` to `ValidationSummary` component */}
              <ValidationSummary<FormFields>
                items={validationSummaryItems}
                mode={validationSummaryMode}
              />
            </Stack>
          </FormContainer>
          <Alert severity="warning">{texts.warning}</Alert>
        </Stack>
      </form>
    </ModalContent>
  )
}
