import { Entry } from '../Entry'

export function Entry20231207() {
  return (
    <Entry title="Dec 07 2023 - v3.5.8">
      Link to the{' '}
      <a
        href="https://workwave.atlassian.net/projects/RM/versions/15608/tab/release-report-all-issues"
        target="_blank"
        rel="noreferrer"
      >
        Jira Release
      </a>
    </Entry>
  )
}
