import type Feature from 'ol/Feature'

import { Stroke, Style } from 'ol/style'

import Polygon from 'ol/geom/Polygon'
import Geometry from 'ol/geom/Geometry'
import LineString from 'ol/geom/LineString'

import { drawStyleCommon } from './common'

const common = drawStyleCommon()

export const invalidTemporarySegment = new Style({
  stroke: new Stroke({
    color: common.red(1),
    width: common.lineWidth,
    lineDash: [4, 8],
    lineDashOffset: 6,
  }),
  geometry: (feature: Feature<Geometry>) => {
    // return the coordinates of the first ring of the polygon
    const geometry = feature?.getGeometry()
    if (geometry instanceof Geometry && geometry.getType() === 'Polygon') {
      const poly = geometry as Polygon

      const coords = [...(poly.getCoordinates().at(0) ?? [])]
      if (coords.length > 2) {
        const vertexCount = coords.length > 3 ? -3 : -2
        const coordinates = [...coords].slice(vertexCount)
        return new LineString(coordinates)
      }
    }
  },
})
