import { getCrudSelection, getMainSelection } from '@/atoms'
import { getMapSelectionContext } from '@/map'

function getDeviceIdsFromVehicleIds(vehicleIds: workwave.DeepReadonly<string[]>) {
  return vehicleIds.reduce<string[]>((acc, uvId) => {
    const deviceId = uvId.split('@@')[0]

    if (deviceId === '[DEVICE-ID]') return acc

    acc.push(deviceId)
    return acc
  }, [])
}

export function getSelectedDeviceIds() {
  const mainVehicleSelection = getMainSelection().unifiedVehicles
  const crudVehicleSelection = getCrudSelection().unifiedVehicles

  const mainDeviceSelection = getMainSelection().devices
  const crudDeviceSelection = getCrudSelection().devices
  const mapSelectionCtx = getMapSelectionContext()

  switch (mapSelectionCtx.type) {
    case 'main':
      return Array.from(
        new Set<string>([
          ...mainDeviceSelection,
          ...getDeviceIdsFromVehicleIds(mainVehicleSelection),
        ]),
      ).map(id => parseInt(id))

    case 'crud':
      return Array.from(
        new Set<string>([
          ...crudDeviceSelection,
          ...getDeviceIdsFromVehicleIds(crudVehicleSelection),
        ]),
      ).map(id => parseInt(id))

    case 'none':
    default:
      return []
  }
}
