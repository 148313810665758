import { Text } from './Text'
import { Props } from './typings'

export function H4(props: Props) {
  const { children = '', as, className = '', style, size, weight, ...p } = props

  return (
    <Text size="$h4" weight="$bold" as={'h4'} style={style} className={className} {...p}>
      {children}
    </Text>
  )
}
