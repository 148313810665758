import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    header: {
      title: translate({ id: 'setup.startEndRoute.header.title' }),
      description: translate({ id: 'setup.startEndRoute.header.description' }),
    },
    copy: translate({ id: 'setup.startEndRoute.btn.copy' }),
    collect: translate({ id: 'setup.startEndRoute.collect.title' }),
    yes: translate({ id: 'setup.startEndRoute.collect.yes' }),
    no: translate({ id: 'setup.startEndRoute.collect.no' }),
    copyTooltip: {
      title: translate({ id: 'setup.startEndRoute.copyTooltip.title' }),
    },
  }))

  return api
}
