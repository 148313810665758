import { NavigoContainer } from '../../elements/NavigoContainer'
import { DevicesNavigator } from './components/DevicesNavigator'

import { Overview } from './views/Overview'
import { MapStyle } from './views/MapStyle'

import { useDevicesNavigoMachine } from './hooks/useDevicesNavigoMachine'

export function Devices() {
  const { currentState, data, actions } = useDevicesNavigoMachine()

  switch (currentState) {
    case 'mapStyle':
      return (
        <NavigoContainer data-trackid="navigo-devices" data-testid="navigo-devices">
          <DevicesNavigator />

          <MapStyle reset={actions.reset} />
        </NavigoContainer>
      )

    case 'overview':
    default:
      return (
        <NavigoContainer data-trackid="navigo-devices" data-testid="navigo-devices">
          <DevicesNavigator />

          <Overview
            expanded={data.expanded}
            permissions={data.permissions}
            goToStyle={actions.goToMapStyle}
            toggleExpanded={actions.toggleExpanded}
          />
        </NavigoContainer>
      )
  }
}
