import { Component } from 'react'

import { ReadonlyLabel, ReadonlyBlock, HorizontalRuler } from '@/forms-legacy'

import * as Texts from '../../intl'

import { PodPictures } from './components/PodPictures'
import PodNotes from './components/PodNotes'
import { PodAudios } from './components/PodAudios'
import { PodSignatures } from './components/PodSignatures'
import { PodBarcodes } from './components/PodBarcodes'

import { Props } from './typings'

export default class Pods extends Component<Props> {
  render() {
    const { allowBarcodes } = this.props

    return (
      <ReadonlyBlock>
        <HorizontalRuler />
        <ReadonlyBlock>
          <ReadonlyLabel primary>{Texts.getPODTitle()}</ReadonlyLabel>
        </ReadonlyBlock>
        <ReadonlyBlock>
          <PodNotes {...this.props} />
        </ReadonlyBlock>
        <ReadonlyBlock>
          <PodPictures {...this.props} />
        </ReadonlyBlock>
        <ReadonlyBlock>
          <PodSignatures {...this.props} />
        </ReadonlyBlock>
        <ReadonlyBlock>
          <PodAudios {...this.props} />
        </ReadonlyBlock>
        {allowBarcodes && (
          <ReadonlyBlock>
            <PodBarcodes {...this.props} />
          </ReadonlyBlock>
        )}
      </ReadonlyBlock>
    )
  }
}
