import { useState } from 'react'
import { useIntl } from '@/intl'

import { durationUtils } from '@/server-data'

const formatSetupCost = (setupCost: number): string => `${(setupCost / 100).toFixed(2)}`

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    multipleValues: translate({ id: 'global.multipleValues' }),
    setupCost: (cost: number, currency: string) => {
      return cost > -1
        ? translate({
            id: 'setup.depots.view.setupCost',
            values: { cost: formatSetupCost(cost), currency },
          })
        : translate({ id: 'setup.depots.list.noSetupCost' })
    },
    setupTime: (seconds: number) => {
      return seconds > -1
        ? durationUtils.formatSeconds(translate)(seconds, 'SHORT')
        : translate({ id: 'setup.depots.list.noSetupTime' })
    },
    setupCostLabel: translate({ id: 'setup.depots.setupCost' }),
    setupTimeLabel: translate({ id: 'setup.depots.setupTime' }),
  }))

  return api
}
