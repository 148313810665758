import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

interface Payload {
  area: uui.domain.server.rm.TrafficRegion
  id: string
  recurrence: string
}

/**
 * Update/Insert a traffic area
 *
 * @private
 */
export const updateTrafficArea = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/traffic/updateTrafficArea', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const response = await rpc('rpc/traffic/updateTrafficArea', {
      category: 'rpc',
      type: 'rpc/traffic/updateTrafficArea',
      area: payload.area,
      id: payload.id,
      recurrence: payload.recurrence,
    })
    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {updateTrafficArea}`,
      { tags: ['rpc', 'traffic'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Update traffic area failed`,
      error,
    })
  }
})
