import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { mapAtom } from '../../../atoms/map/map'
import { findLayer } from '../../utils/findLayer'

import { createInspectPinFeature } from './utils/createInspectPinFeature'
import { updateInspectPinFeature } from './utils/updateInspectPinFeature'

export function addOrUpdateInspectPinFeature(
  inspectPin: uui.domain.ui.map.InspectPin,
): Feature<Geometry> {
  const map = mapAtom.map
  const layer = findLayer(map, 'pin')
  let feature = layer.getSource()?.getFeatureById('inspectPin')

  if (feature) {
    // update the pin
    updateInspectPinFeature(inspectPin, feature)
  } else {
    // create the pin
    feature = createInspectPinFeature(inspectPin)
    layer.getSource()?.addFeature(feature)
  }

  return feature
}
