import styled from 'styled-components'

import { TriangleDown } from '@/icons'

interface Props {
  collapsed?: boolean
}

const IconPlacer = styled.span<Props>`
  transform: rotate(${p => (p.collapsed ? -90 : 0)}deg);
  will-change: transform;
`

IconPlacer.displayName = 'IconPlacer'

const Spacer = styled.div`
  width: 8px;
`

Spacer.displayName = 'Spacer'

export function Indicator(props: Props) {
  return (
    <>
      <IconPlacer collapsed={props.collapsed}>
        <TriangleDown size={14} />
      </IconPlacer>
      <Spacer />
    </>
  )
}
