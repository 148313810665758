import { theme } from '@/styles'

function getWidth(label: string, pixelPerCharacter: number, pad: number, maxWidth: number) {
  const res = Math.ceil(label.length * pixelPerCharacter) + 2 * pad
  return Math.min(res % 2 === 0 ? res : res + 1, maxWidth)
}

export function getLocationPinSvgText(label: string, pad: number = 16, maxWidth: number = 200) {
  // arbitrary value based on browser render
  // this has to change if we change font or font weight or font size
  const pixelPerCharacter: number = 6.4

  const w: number = getWidth(label, pixelPerCharacter, pad, maxWidth)
  const h: number = 24

  const maxChars: number = Math.ceil((w - pad * 2) / pixelPerCharacter)

  const renderedLabel = label.length > maxChars ? `${label.substring(0, maxChars - 5)}...` : label

  const bgColor = theme.colors.$pureWhite

  const color = '#00000099'

  const svg = `
<svg width="${w}" height="${h + 2}" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <filter id="shadow">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2476_4326"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="0.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_2476_4326" result="effect2_dropShadow_2476_4326"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_dropShadow_2476_4326"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="0.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
      <feBlend mode="normal" in2="effect2_dropShadow_2476_4326" result="effect3_dropShadow_2476_4326"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2476_4326" result="shape"/>
    </filter>
  </defs>
  
  <rect width="${w}" height="${h}" rx="3" fill="${bgColor}" filter="url(#shadow)" />
  <text x="${w / 2}" y="${
    h / 2
  }" font-family="Roboto, sans-serif" font-size="16" font-weight="400" fill="${color}" text-anchor="middle" alignment-baseline="central">
    ${renderedLabel}
  </text>
  
</svg>
  `

  return svg
}
