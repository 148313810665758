import { useEffect } from 'react'

import { setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

export function useHidePlaceWhileEditing(editing: boolean, placeId?: string) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!placeId || !editing) return

    // hide the feature
    dispatch(
      setCustomMapStyle({
        customStyle: {
          type: 'place',
          mode: 'off',
          ids: [placeId],
        },
      }),
    )

    return () => {
      dispatch(
        setCustomMapStyle({
          customStyle: {
            type: 'place',
            mode: 'on',
            ids: [placeId],
          },
        }),
      )
    }
  }, [dispatch, editing, placeId])
}
