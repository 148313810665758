import type { Props } from '../typings'
import { parse, isValid, isAfter } from 'date-fns/esm'
import { useCallback, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { selectTerritory, selectTerritoryLicensingLimits } from '@/features/domain/territory'
import { deleteOrderSteps, unassignOrderSteps } from '@/features/domain/order'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectRmVehicles } from '@/features/domain/vehicle'
import { selectCompanies } from '@/features/domain/company'
import { selectRoutes } from '@/features/domain/route'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { setMainSelection, resetMainSelection, useEditingStateWithCtx, useListApi } from '@/atoms'

export function useSingleViewActions(props: Props) {
  const { order, selection } = props

  const nowRef = useRef(new Date())

  const toast = useNotification()
  const dispatch = useAppDispatch()
  const routes = useSelector(selectRoutes)
  const territory = useSelector(selectTerritory)
  const vehiclesMap = useSelector(selectRmVehicles)
  const companiesMap = useSelector(selectCompanies)
  const { scrollTo } = useListApi('orderSteps')
  const { setEditing } = useEditingStateWithCtx('order')
  const userConfiguration = useSelector(selectUserConfiguration)
  const licensingLimits = useSelector(selectTerritoryLicensingLimits)

  // ------------------------------
  // Callbacks
  // ------------------------------

  const onCloseSingleView = useCallback(() => resetMainSelection('orderSteps'), [])

  const onSelectPaired = useCallback(() => {
    if (order.type === 'pd') {
      setMainSelection('orderSteps', [order.pairedId])
      scrollTo(order.pairedId)
    }
  }, [scrollTo, order])

  const allowBarcodes = useMemo(() => licensingLimits.trackedVehicles > 0, [licensingLimits])

  const onDeleteOrderSteps = useCallback(
    async (ids: string[]) => {
      try {
        const thunkResult = await dispatch(deleteOrderSteps(ids))

        if (!deleteOrderSteps.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, toast],
  )

  const onUnassignOrderSteps = useCallback(
    async (ids: string[]) => {
      try {
        const result = await dispatch(unassignOrderSteps(ids))

        if (!unassignOrderSteps.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, toast],
  )

  const onStartEditing = useCallback(
    () => setEditing(Array.from(selection)),
    [setEditing, selection],
  )

  // ------------------------------
  // Values
  // ------------------------------

  const isOrderAssignedToFutureRoute = useMemo(() => {
    const routeHolderOfOrder = Object.values(routes).find(r => {
      const isInThisRoute = r.route.steps.find(step => step.ref === order.order.id)
      return isInThisRoute
    })
    if (!routeHolderOfOrder) {
      return false
    }
    const routeDate = parse(routeHolderOfOrder.id.slice(-8), 'yyyyMMdd', nowRef.current)
    const todayDate = parse(userConfiguration.today, 'yyyyMMdd', nowRef.current)

    if (isValid(routeDate)) {
      return isAfter(routeDate, todayDate)
    }

    return false
  }, [routes, userConfiguration, order])

  return {
    territory,
    vehiclesMap,
    companiesMap,
    allowBarcodes,
    onStartEditing,
    onSelectPaired,
    userConfiguration,
    onCloseSingleView,
    onDeleteOrderSteps,
    onUnassignOrderSteps,
    isOrderAssignedToFutureRoute,
  }
}
