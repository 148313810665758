import type { IconContainerProps } from '@/icons'

import {
  Gps,
  Unplug,
  TruckIdle,
  Speed,
  TimeInterval,
  Cornering,
  TractionControl,
  Acceleration,
  Attention,
} from '@/icons'

interface Props {
  eventTypeId: uui.domain.client.gps.wwgps.EventAlert['eventTypeId']
  iconProps?: Partial<IconContainerProps>
}

export function EventAlertIcon(props: Props) {
  const { eventTypeId, iconProps } = props

  switch (eventTypeId) {
    case 520:
      return <Gps {...iconProps} />

    case 130:
      return <Unplug {...iconProps} />

    case 30:
    case 40:
    case 50:
      return <TruckIdle {...iconProps} />

    case 500:
    case 505:
      return <Speed {...iconProps} />

    case 510:
      return <TimeInterval {...iconProps} />

    case 460:
      return <Cornering {...iconProps} />

    case 400:
    case 410:
    case 420:
      return <TractionControl {...iconProps} />

    case 430:
    case 440:
    case 450:
      return <Acceleration {...iconProps} />

    default:
      return <Attention {...iconProps} />
  }
}
