import type { ChipShape, CoreItem, Modes, RouteChip } from '../../../components/Navigator/typings'

import { useSelector } from 'react-redux'
import { clsx } from '@/utils'

import { isDark, getColorValue } from '@/styles'
import { theme } from '@/local/components'

import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { Chip } from '../../../components/Navigator/components/Chip'

export interface Props<T extends CoreItem, K> {
  item: T
  mode: Modes
  selected?: boolean
  onClick: () => void
  className?: string
  extraItemRendererProps: K
  loopIndex: number
}

export function RouteItemRenderer(props: Props<RouteChip, Record<string, any>>) {
  const {
    item: { source },
    onClick,
    className: cls = '',
  } = props

  const className = clsx(cls, 'o-chip--circle')

  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const vehicles = useSelector(selectVehicles)

  const uv = vehicles[vehiclesByVehicleId[source.vehicleId]]

  const background = getColorValue(
    (source.type === 'route' || source.type === 'routeUnavailable') && source.ordersCount === 0
      ? theme.colors.$pureWhite
      : source.avatar.color,
  )
  const textColor = isDark(background) ? theme.colors.$pureWhite : theme.colors.$nightRider

  const row = uv.hasRoutingLicense && uv.vehicle.idx !== -1 ? uv.vehicle.idx.toString() : undefined
  const chipProps = {
    row,
    onClick,
    className,
    background,
    color: textColor,
    borderColor: getColorValue(source.avatar.color),
    shape: 'rounded' as ChipShape,
    hasViolation: source.violationsCount > 0,
  }

  return <Chip {...chipProps} />
}
