import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { setGeofenceFeatureMetadata } from '../geofenceFeatureMetadata'
import { getGeofenceFeatureStyle } from './getGeofenceFeatureStyle'

export const updateGeofenceFeature = (
  mapStyles: uui.domain.ui.map.markers.MapStyles['geofence'],
  marker: uui.domain.ui.map.markers.Geofence,
  feature: Feature<Geometry>,
  selected: boolean = false,
): Feature<Geometry> => {
  // ------------------------
  // Feature Metadata
  // ------------------------

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['geofence']['mode'] =
    marker.mode ?? mapStyles.mode
  setGeofenceFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getGeofenceFeatureStyle(marker, mode, selected))

  return feature
}
