import { useState } from 'react'

import { useConfigureModalController, useModalController, useCloseModal } from '@/atoms'
import { useFetchPhoneNumbers } from './useFetchPhoneNumbers'

type ModalState = 'ready' | 'pending'
type ModalData = {
  phoneNumbers?: string[]
}

export const modalId = 'phoneNumbersModal'

export const useController = () => useModalController<ModalData, ModalState>(modalId)

export const useConfigureController = () => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      data: {},
      close,
    }),
  }))

  useFetchPhoneNumbers()

  return useConfigureModalController<ModalData, ModalState>(modalId, options)
}
