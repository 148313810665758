import styled from 'styled-components'

export interface Props {
  className?: string
}

const NavContainer = styled.div.attrs(({ className }) => {
  return { className }
})<Props>`
  display: block;
  flex: 0 0 auto;
`

NavContainer.displayName = 'NavContainer'

export default NavContainer
