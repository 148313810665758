import type Geometry from 'ol/geom/Geometry'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { setBreadcrumbFeatureMetadata } from '../../breadcrumbFeatureMetadata'
import { getBreadcrumbStopsFeatureStyle } from './getBreadcrumbStopsFeatureStyle'

/**
 * Breadcrumbs are composed by multiple features on the map:
 * - One feature that represent the full  ()
 * - <number_of_stops> features that represents the single breadcrumbs on the map
 * - <number_of_stalls> features that represents the single stalls on the map
 * - <number_of_> features that represents the single  on the map
 */
export function createBreadcrumbStopsFeatures(
  marker: uui.domain.ui.map.markers.Breadcrumb,
  mapStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
  selection: Set<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
): Feature<Geometry>[] {
  const mode: uui.domain.ui.map.markers.MapStyles['breadcrumb']['mode'] =
    marker.mode ?? mapStyles.mode

  const features: Feature<Geometry>[] = []

  //  feature
  if (marker.fetched) {
    for (const stopId in marker.stops) {
      const stop = marker.stops[stopId]
      const feature = new Feature({ geometry: new Point(stop.lonLat) })

      setBreadcrumbFeatureMetadata(feature, 'type', 'breadcrumbStops')

      feature.setStyle(
        getBreadcrumbStopsFeatureStyle(stop, mode, selection.has(stopId), breadcrumbTimeRange),
      )
      feature.setId(stop.id)

      features.push(feature)
    }
  }

  return features
}
