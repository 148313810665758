import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCalendarRange } from '@/features/domain/ui'
import { selectDeviceEvents } from '@/features/domain/deviceEvents'

export function useEvents(devices: uui.domain.client.gps.wwgps.DeviceInfo[]) {
  const calendarRange = useSelector(selectCalendarRange)
  const deviceEvents = useSelector(selectDeviceEvents)

  return useMemo(() => {
    if (calendarRange.minDate !== calendarRange.maxDate) return 0

    let eventsCount = 0

    for (const { deviceId } of devices) {
      eventsCount += Object.keys(deviceEvents?.[deviceId]?.[calendarRange.minDate] ?? []).length
    }

    return eventsCount
  }, [devices, calendarRange, deviceEvents])
}
