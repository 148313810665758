import { useEffect } from 'react'
import {
  resetVehicleFormException,
  resetAllModalControllers,
  resetAllListSubscribers,
  resetSchedulerDayRange,
  resetTransactionState,
  resetAllCrudSelection,
  resetAllMainSelection,
  setApplicationMode,
  resetLoadingState,
  resetAppLanguage,
  resetPaginations,
  resetNavigoView,
  setEditingState,
  resetOrdersGrid,
  resetModalState,
} from '@/atoms'
import { resetMap, resetPendingFitMap } from '@/map'

/**
 * That hooks resets all the atoms except accountsData
 *
 * Note: accountsData atom must be reset only after a successful logout
 */
export function useResetAtomsOnLogout() {
  useEffect(() => {
    resetVehicleFormException()
    setApplicationMode('reset')
    resetAllModalControllers()
    resetAllListSubscribers()
    setEditingState('reset')
    resetSchedulerDayRange()
    resetTransactionState()
    resetAllMainSelection()
    resetAllCrudSelection()
    resetPendingFitMap()
    resetLoadingState()
    resetPaginations()
    resetAppLanguage()
    resetNavigoView()
    resetOrdersGrid()
    resetModalState()
    resetMap()
  }, [])
}
