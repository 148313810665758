import type {
  BreadcrumbChip,
  ChipShape,
  CoreItem,
  Modes,
} from '../../../components/Navigator/typings'

import { Component } from 'react'
import { LocationOn } from '@mui/icons-material'

import { getColorValue, isDark } from '@/styles'
import { theme } from '@/local/components'
import { clsx } from '@/utils'

import { BreadcrumbStop, BreadcrumbParking } from '@/icons'
import { Chip } from '../../../components/Navigator/components/Chip'

export interface Props<T extends CoreItem, K> {
  item: T
  mode: Modes
  selected?: boolean
  onClick: () => void
  className?: string
  extraItemRendererProps: K
  loopIndex: number
}

export class BreadcrumbItemRenderer extends Component<Props<BreadcrumbChip, Record<string, any>>> {
  render() {
    const {
      item: { source },
      onClick,
      className: cls = '',
    } = this.props

    const { color } = source

    const className = clsx({
      [cls]: true,
      'o-breadcrumb-chip': true,
    })

    const background = getColorValue(color)
    const iconColorName = isDark(background) ? '$pureWhite' : '$nightRider'
    const iconColor = isDark(background) ? theme.colors.$pureWhite : theme.colors.$nightRider

    const rotation = source.heading === -1 ? 0 : source.heading

    const chipProps = {
      color,
      onClick,
      className,
      background,
      shape: 'rounded' as ChipShape,
      Icon:
        source.status === 'stalling' ? (
          <BreadcrumbParking size={27} color={iconColorName} />
        ) : source.status === 'stopped' ? (
          <BreadcrumbStop size={22} color={iconColorName} />
        ) : (
          <LocationOn fontSize="medium" sx={{ color: iconColor, rotate: `${rotation}deg` }} />
        ),
    }
    return <Chip {...chipProps} />
  }
}
