import type { CSSProperties } from 'react'
import { useCallback } from 'react'

import TextButton from '../../../../lib/TextButton'
import { useTexts } from './useTexts'

type Props = {
  onChange: (initial: any) => void
  initial: any
  style?: CSSProperties
  dirty?: boolean
}

export function RestoreFieldButton(props: Props) {
  const { style = {}, dirty, onChange, initial } = props
  const texts = useTexts()

  const handleOnRestore = useCallback(() => {
    onChange(initial)
  }, [onChange, initial])

  if (!dirty) return null

  return (
    <TextButton
      style={{ fontSize: 12, ...style }}
      onClick={handleOnRestore}
      data-testid="form-field__restore-button"
    >
      {texts.restoreTranslation}
    </TextButton>
  )
}
