import { Cross } from '@/icons'
import { WarningCard } from '@/local/components'

import { useTexts } from './useTexts'

export function LoadingTerritoriesDataError() {
  const texts = useTexts()

  return (
    <WarningCard
      title={texts.title}
      description={texts.description}
      Icon={<Cross />}
      preset="alert"
    />
  )
}
