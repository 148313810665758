import type {
  FormField,
  FormError,
  FormValues as FormValuesType,
} from '@workwave-tidal/tidal/form-fairy'

const checkboxField: FormField<boolean> = {
  value: false,
  required: false,
}

export const formFields = {
  c1: checkboxField,
  c2: checkboxField,
  c3: checkboxField,
  c4: checkboxField,
}

export type FormFields = typeof formFields
export type FormValues = FormValuesType<FormFields>
export type FormErrors = FormError<keyof FormFields>
