import type {
  DrawEditableAreaInteraction,
  ModifyEditableAreaInteraction,
} from '../../../interactions/EditableAreaInteraction'

/**
 * Update the Active area color when it change in the Editable Area Store
 */
export function changeAreaColor(
  drawArea: DrawEditableAreaInteraction,
  modifyArea: ModifyEditableAreaInteraction,
  color: string,
) {
  // draw area uses it's own overlay layer while drawing the shape
  drawArea?.updateColor(color)

  // modify area uses `draw` layer style
  modifyArea?.updateColor(color)
}
