import type { CanceledNotificationValues } from '../../hooks/useCanceledNotificationValues'

import { Box } from '@mui/material'
import { useComputeSegmentsCount } from '@/hooks'

import { Field } from '../../../../components/Field'
import { NotificationLimitations } from './components/NotificationLimitations'

import { useTexts } from './useTexts'

interface Props {
  values: CanceledNotificationValues
}

export function CanceledNotificationView(props: Props) {
  const { values } = props
  const texts = useTexts()

  const isEmailNotification = values.notificationWay === 'EMAIL'
  const isPhoneNotification = !isEmailNotification

  const backupNotificationText = values.backupNotification ? texts.on : texts.off
  const notificationWay = values.notificationWay === 'PHONE' ? 'SMS' : 'E-mail'

  const segmentsCount = useComputeSegmentsCount(
    { ...values.templateDefaultLimits, ...values.templateCustomLimits },
    values.templateCustom,
    values.includeMessageUnsubscriptionInstructions,
    values.includeMessageTrackingLink,
  )

  return (
    <Box paddingLeft={4} paddingRight={4}>
      {/* Notification Limitations */}
      <NotificationLimitations />

      {/* Notification Type */}
      <Field label={texts.notificationType}>{notificationWay}</Field>

      {isEmailNotification && (
        <>
          {/* E-mail subject */}
          <Field label={texts.emailSubject}>{values.subjectCustom}</Field>

          {/* E-mail body */}
          <Field label={texts.emailText}>{values.templatePreview}</Field>
        </>
      )}

      {isPhoneNotification && (
        <>
          {/* SMS body */}
          <Field label={`${texts.smsText} • ${texts.segmentsCount(segmentsCount)}`}>
            {values.templatePreview}
          </Field>

          {/* E-mail backup */}
          <Field label={texts.emailBackup}>{backupNotificationText}</Field>

          {/* E-mail backup Subject*/}
          {values.backupNotification && (
            <Field label={texts.emailBackupSubject}>{values.backupSubjectCustom}</Field>
          )}
        </>
      )}
    </Box>
  )
}
