import { type ChangeEventHandler, useCallback, useState, useMemo, useEffect } from 'react'
import { Button } from '@mui/material'
import { type Column, type ColumnStore, type SchedulerPro } from '@bryntum/schedulerpro'
import { ColumnManager, useTexts, type ColumnData } from '@/components/ColumnManager'
import { getInitialSchedulerColumn, setSchedulerColumns } from '../../../atoms/columns'
import { getColumnsFromConfig } from '../../../utils/createSchedulerConfig/columnStore/utils/getColumnsFromConfig'

interface Props {
  scheduler?: SchedulerPro
  open: boolean
  close: () => void
  position: {
    top: number
    left: number
  }
}

export function ColumnManagerPanel(props: Props) {
  const { scheduler, open, close, position } = props
  const texts = useTexts()
  const [columns, setColumns] = useState<ColumnData[]>(() => {
    if (!scheduler) return []

    const columnStore = scheduler.columns as ColumnStore
    if (!columnStore) return []

    return columnStore.allRecords.reduce((columnsList: ColumnData[], column) => {
      const text = column.getData('text')
      if (!text) {
        return columnsList
      }

      if (column.getData('region') === 'cols') {
        const record = {
          id: column.id as string,
          label: text,
          visible: !column.getData('hidden'),
        }
        columnsList.push(record)
      }

      return columnsList
    }, [])
  })
  const [filter, setFilter] = useState<string>('')

  useEffect(() => {
    if (!scheduler) return

    const columnStore = scheduler.columns as ColumnStore
    if (!columnStore) return

    columnStore.onChange = () => {
      const managedColumns = columnStore.allRecords.reduce((columnsList: ColumnData[], column) => {
        const text = column.getData('text')
        if (!text) {
          return columnsList
        }

        if (column.getData('region') === 'cols') {
          const record = {
            id: column.id as string,
            label: text,
            visible: !column.getData('hidden'),
          }
          columnsList.push(record)
        }

        return columnsList
      }, [])
      setColumns(managedColumns)
    }
  }, [scheduler])

  const allColumnsVisible = useMemo(() => {
    return columns.every(column => column.visible)
  }, [columns])

  const showAll = useCallback(() => {
    if (!scheduler) return

    const columnStore = scheduler.columns as ColumnStore
    if (!columnStore) return

    for (const column of columnStore.allRecords as Column[]) {
      if (column.getData('region') === 'cols') {
        column.show()
      }
    }
  }, [scheduler])

  const hideAll = useCallback(() => {
    if (!scheduler) return

    const columnStore = scheduler.columns as ColumnStore
    if (!columnStore) return

    for (const column of columnStore.allRecords as Column[]) {
      if (column.getData('region') === 'cols') {
        column.hide()
      }
    }
  }, [scheduler])

  const resetDefaultColumns = useCallback(() => {
    if (!scheduler) return

    const columnStore = scheduler.columns as ColumnStore
    if (!columnStore) return

    columnStore.removeAll()
    setSchedulerColumns('reset')
    const defaultColumns = getColumnsFromConfig(getInitialSchedulerColumn())
    columnStore.add(defaultColumns)
  }, [scheduler])

  const onColumnVisibilityChange = useCallback(
    (id, checked) => {
      if (!scheduler) return

      const columnStore = scheduler.columns as ColumnStore
      if (!columnStore) return

      const column = columnStore.getById(id) as Column
      if (column) {
        column.hidden = !checked
      }
    },
    [scheduler],
  )

  const onFilterInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    setFilter(e.target.value)
  }, [])

  const slots = {
    header: {
      leftButton: (
        <Button onClick={allColumnsVisible ? hideAll : showAll}>
          {allColumnsVisible ? texts.hideAll : texts.showAll}
        </Button>
      ),
    },
    footer: {
      leftButton: <Button onClick={resetDefaultColumns}>{texts.reset}</Button>,
      rightButton: <Button onClick={close}>{texts.done}</Button>,
    },
  }

  return (
    <ColumnManager
      open={open}
      close={close}
      columns={columns}
      slots={slots}
      onColumnVisibilityChange={onColumnVisibilityChange}
      position={{ top: position.top, left: position.left }}
      maxHeight={(scheduler?.height as number) || 500}
      filter={filter}
      onFilterChange={onFilterInputChange}
    />
  )
}
