import { useMemo } from 'react'

import { VerticalLayout } from '@/components/layout'
import { H4 } from '@/local/components'
import { Block, InputFieldText } from '@/forms-legacy'

import { useControllerActions } from '../../../../../../hooks/useControllerActions'
import { useValidations } from '../../../../../../hooks/useValidations'
import { useController } from '../../../../../../hooks/useController'
import { ErrorMessage } from '../ErrorMessage'

import { useTexts } from './useTexts'

export function Email() {
  const texts = useTexts()
  const { data } = useController()
  const { onEmailChange } = useControllerActions()
  const { email: validation } = useValidations()

  const errorMessage = useMemo(() => {
    switch (validation) {
      case 'empty':
        return texts.emailErrorEmpty

      case 'invalidFormat':
        return texts.emailErrorFormat

      case 'valid':
      case 'pristine':
      default:
        return ''
    }
  }, [texts, validation])

  if (data.messageType !== 'email') return null
  const email = data?.email ?? ''

  return (
    <VerticalLayout marginBottom={5} data-testid="send-message-to-driver-email-type-panel">
      <Block>
        <H4 color="$paleSky" uppercase>
          {texts.emailTitle}
        </H4>
      </Block>

      <Block>
        <InputFieldText
          name="email"
          tabIndex={1}
          value={email}
          onChange={onEmailChange}
          placeholder={texts.emailPlaceholder}
        />
      </Block>

      <ErrorMessage>{errorMessage}</ErrorMessage>
    </VerticalLayout>
  )
}
