import { Entry } from '../Entry'
import { JiraLinks } from './JiraLinks'

const ids = [
  10532, 10554, 10520, 10518, 10468, 10324, 10499, 10557, 10385, 10498, 10498, 10366, 10532, 10571,
  10548, 10385, 10549,
]

export function Entry20210409() {
  return (
    <Entry title="04/09/2021 - v3.0.0-beta-1 fixes">
      Full list of Jira issues fixed:
      <br />
      <br />
      <JiraLinks ids={ids} />
      <br />
      <br />
    </Entry>
  )
}
