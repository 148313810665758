import tinycolor from 'tinycolor2'
import { Fill, Stroke, Style } from 'ol/style'

import { genericStyles } from '../../genericStyles'

const regionMarkerStylesCache: Map<string, Style> = new Map()

/**
 * Return the right style to be used by a Region feature
 */
export function getRegionFeatureStyle(
  marker: uui.domain.ui.map.markers.Area,
  mode: uui.domain.ui.map.markers.MapStyles['region']['mode'],
  selected: boolean = false,
) {
  if (mode === 'off') return genericStyles.hidden

  const cacheId = `${marker.color}_${selected ? 1 : 0}`

  if (regionMarkerStylesCache.has(cacheId)) {
    const cachedStyle = regionMarkerStylesCache.get(cacheId)
    if (cachedStyle) return cachedStyle
  }

  const { r, g, b } = tinycolor(marker.color).toRgb()

  const style = new Style({
    stroke: new Stroke({
      width: 2,
      color: selected ? '#6598f0' : [r, g, b, 1],
    }),

    fill: new Fill({
      color: [r, g, b, 0.4],
    }),
  })

  regionMarkerStylesCache.set(cacheId, style)

  return style
}
