import type { AsyncThunkApiConfig } from '@/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type UserPersonalization = uui.domain.actions.rpc.user.UpdateUserPersonalizationAction['data']

export const updateUserPersonalization = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  UserPersonalization,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/territory/change', async (data, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/user/updateUserPersonalization', {
      type: 'rpc/user/updateUserPersonalization',
      category: 'rpc',
      data,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        error: result,
        message: result.errorMessage,
      })
    }
  } catch (error) {
    return thunkApi.rejectWithValue({
      error,
      type: 'unknown',
      message: 'Failed to update the User Account personalization',
    })
  }
})
