import type { Props } from './typings'

import { useCallback } from 'react'

import { FlexRow, FlexColumn } from '@/forms-legacy'

import LoadContainer from '../../../LoadsList/components/Loads/components/Load/elements/LoadContainer'
import LoadInputField from '../../../LoadsList/components/Loads/components/Load/elements/LoadInputField'
import LoadLabel from '../../../LoadsList/components/Loads/components/Load/elements/LoadLabel'

function VehicleLoad(props: Props) {
  const { label, value: load, onChange, useMetric } = props
  const { value: loadValue, min: loadMin } = load

  const handleOnChangeValue = useCallback(
    (value: number) => {
      onChange(label, {
        ...load,
        value: isNaN(value) ? 0 : value * 100,
      })
    },
    [onChange, load, label],
  )

  const handleOnChangeMin = useCallback(
    (value: number) => {
      onChange(label, {
        ...load,
        min: isNaN(value) ? 0 : value * 100,
      })
    },
    [onChange, load, label],
  )

  return (
    <LoadContainer large>
      <FlexColumn primary>
        <LoadLabel>{label}</LoadLabel>
      </FlexColumn>
      <FlexColumn>
        <FlexRow>
          <LoadInputField
            value={loadValue / 100}
            onChange={handleOnChangeValue}
            name="load-vehicle--value"
            noRightRadius
            useSpace
            useMetric={useMetric}
          />
          <LoadInputField
            value={loadMin / 100}
            onChange={handleOnChangeMin}
            name="load-vehicle--min"
            renderExtraControls={() => 'min'}
            useMetric={useMetric}
          />
        </FlexRow>
      </FlexColumn>
    </LoadContainer>
  )
}

export default VehicleLoad
