import { ReactElement, MouseEventHandler } from 'react'

import { useMouseDrag } from '@/local/hooks'

interface Props {
  container: null | HTMLDivElement
  draggable?: boolean
  render: (mouseDownHandler: MouseEventHandler, dragging: boolean) => ReactElement
}

export const DraggableLayer = (props: Props) => {
  const { render, container } = props
  const [dragInfo, dragging, onMouseDown] = useMouseDrag(true)

  const { offsetX, offsetY } = dragInfo

  if (container) {
    container.style.cssText = `
    transform: translate(${offsetX}px, ${offsetY}px);
    willChange: 'transform';
  `
  }

  return render(onMouseDown, dragging)
}
