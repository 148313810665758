import { getRouterNavigator } from '@/routing'
import { setCrudSelection, setEditingState } from '@/atoms'
import { gis } from '@/server-data'

import { getDomainMarkerData } from '../../../../atoms/markerChangelog/domain/getDomainMarkerData'
import { fitMap } from '../../../../atoms/map/utils/fitMap'

export function doubleClickTrafficAreas(areaId?: string) {
  if (!areaId) return

  const { mapMarkers } = getDomainMarkerData()

  const profileId = areaId.substring(0, areaId.lastIndexOf('-'))
  const trafficArea = mapMarkers.trafficProfile[profileId][areaId]

  if (!trafficArea) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`Trying to double click a non-existent TrafficArea with ID: ${areaId}`)
    }

    return
  }

  // Set the new selection for both selectionContexts
  setCrudSelection('trafficArea', [areaId])

  const latLngCoordinates = trafficArea.points.map(gis.fromCoordinateToLatLng)

  // queue a pending fitMap request
  // it will be applied by the destination view when it opens
  fitMap(latLngCoordinates, { preventIfVisible: true }, 'add')

  // Navigate to the target page
  setEditingState({ editing: true, ctx: 'trafficArea', editingEntities: [areaId] })

  const navigate = getRouterNavigator()
  navigate('/setup/traffic-area')
}
