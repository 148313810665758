import { type EventModel, type SchedulerPro } from '@bryntum/schedulerpro'

type EventPartialResizeParams = {
  eventRecord: EventModel
  startDate: Date
  endDate: Date
  element: HTMLElement
  source: SchedulerPro
}

export function onEventPartialResize(params: EventPartialResizeParams) {
  const { eventRecord, element, source } = params

  const id = (eventRecord.id as string).replace('-handles', '')
  const originalEvent = source.eventStore.getById(id) as EventModel

  if (!originalEvent) return

  //originalEvent.setStartEndDate(eventRecord.startDate as Date, eventRecord.endDate as Date)
  const originalElement = document.querySelector(
    // @ts-expect-error
    `[data-event-id="${eventRecord.domId.replace('-handles', '')}"]`,
  )

  if (!originalElement) return
  // @ts-expect-error
  originalElement.style.left = element.style.left
  // @ts-expect-error
  originalElement.style.width = element.style.width
}
