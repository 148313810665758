import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectGpsTrackingProvider, selectUserConfiguration } from '@/features/domain/user'
import { useResetEditingState, useListApi, setCrudSelection } from '@/atoms'
import { updateVehicle, createVehicle } from '@/features/domain/vehicle'
import { selectDevicesAggregateInfo } from '@/features/domain/device'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch, store } from '@/store'

import { patchRm360Data } from '../../utils/patchRm360Data'

export function useOnSubmit(uv: uui.domain.client.UnifiedVehicle, creating?: boolean) {
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const userConfig = useSelector(selectUserConfiguration)
  const isUnmounted = useIsUnmounted()
  const stopEditing = useResetEditingState()
  const { scrollTo } = useListApi('unifiedVehicles')
  const trackingProvider = useSelector(selectGpsTrackingProvider)

  const deviceAggregateInfo = useSelector(selectDevicesAggregateInfo)

  const metric = userConfig.distanceFormat === 'METRIC'

  return useCallback(
    async (formValues: uui.domain.ui.forms.Rm360VehicleFormValues) => {
      const patchedVehicleAndSummary = patchRm360Data(
        trackingProvider,
        uv,
        formValues,
        deviceAggregateInfo,
        metric,
      )

      try {
        if (creating) {
          const result = await dispatch(createVehicle(patchedVehicleAndSummary.unifiedVehicle))

          if (createVehicle.rejected.match(result)) {
            throw new Error(result.payload?.message ?? 'Internal error')
          }

          if (isUnmounted()) return

          // Since the store is modified directly by the call we must use getState to retrieve updated data
          const { vehiclesByVehicleId } = store.getState().domain.publicData.domain
          const vehicleIds = result.payload.map(vehicleId => vehiclesByVehicleId[vehicleId])

          setCrudSelection('unifiedVehicles', vehicleIds)

          scrollTo(vehicleIds[0])
        } else {
          const result = await dispatch(updateVehicle(patchedVehicleAndSummary))

          if (updateVehicle.rejected.match(result)) {
            throw new Error(result.payload?.message ?? 'Internal error')
          }
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [
      uv,
      metric,
      toast,
      creating,
      dispatch,
      scrollTo,
      isUnmounted,
      stopEditing,
      trackingProvider,
      deviceAggregateInfo,
    ],
  )
}
