import type Map from 'ol/Map'
import type { Coordinate } from 'ol/coordinate'

// import { LatLonSpherical as GeodesyLatLon } from 'geodesy'
// import GeodesyLatLon from 'geodesy/latlon-spherical'
import GeodesyLatLon from 'geodesy/latlon-ellipsoidal-vincenty'

import { fromLonLat, toLonLat } from 'ol/proj'
import LineString from 'ol/geom/LineString'
import Polyline from 'ol/format/Polyline'

import * as ext from 'ol/extent'

import { encodeFloat } from './geo'

// ------------------------------------------------------
// SearchOnMap-related utils

export function getMapBoundingBox(
  bounds: uui.domain.LatLngBounds,
): uui.domain.client.gps.GeocodingBoundingBox {
  const northWest: uui.domain.server.LatLngTuple = [
    encodeFloat(bounds.north),
    encodeFloat(bounds.west),
  ]
  const southEast: uui.domain.server.LatLngTuple = [
    encodeFloat(bounds.south),
    encodeFloat(bounds.east),
  ]
  return { northWest, southEast }
}

// ------------------------------------------------------
// ------------------------------------------------------

const COORDINATES_EQUALITY_THRESHOLD: number = 1e-9

// see: https://stackoverflow.com/questions/22521982/check-if-point-inside-a-polygon
// Typescript adaption of: https://github.com/substack/point-in-polygon. It doesnt work well for point on corners or edges of the polygon.
// To have a more reliable implementation see: https://github.com/mikolalysenko/robust-point-in-polygon
export function pointInPoly(poly: uui.domain.LatLng[], latLng: uui.domain.LatLng): boolean {
  const { lat, lng } = latLng

  let inside = false

  const polyLength = poly.length
  for (let i = 0, j = polyLength - 1; i < polyLength; j = i++) {
    const { lat: ILat, lng: iLng } = poly[i]
    const { lat: jLat, lng: jLng } = poly[j]

    const intersect =
      iLng > lng != jLng > lng && lat < ((jLat - ILat) * (lng - iLng)) / (jLng - iLng) + ILat

    if (intersect) {
      inside = !inside
    }
  }

  return inside
}

export function createLatLng(lat: number, lng: number): uui.domain.LatLng {
  return { lat, lng }
}

const isSimpleLatLng = (source: any) =>
  typeof source === 'object' &&
  Object.keys(source).length === 2 &&
  source.hasOwnProperty('lat') &&
  source.hasOwnProperty('lng')

const isFleetLatLng = (source: any) =>
  typeof source === 'object' &&
  Object.keys(source).length === 3 &&
  source.hasOwnProperty('lat') &&
  source.hasOwnProperty('lng') &&
  source.hasOwnProperty('__fleetOriginal')

export function isLatLng(source: any): boolean {
  try {
    if (isSimpleLatLng(source) || isFleetLatLng(source)) {
      return true
    }
  } catch (e) {}
  return false
}

export function isLatLngBounds(source: any): boolean {
  try {
    const keys = Object.keys(source)
    return (
      keys.length === 4 &&
      keys.includes('east') &&
      keys.includes('west') &&
      keys.includes('north') &&
      keys.includes('south')
    )
  } catch (e) {}
  return false
}

export function createPoint(x: number, y: number): uui.domain.Point {
  return { x, y }
}

export function createEmptyLatLngBounds(): uui.domain.LatLngBounds {
  return {
    east: -180,
    north: -1,
    south: 1,
    west: 180,
  }
}

// From the rest api an order can be created (under a flag) by ignoring any geocoding errors.
// The result leads to a Location with an address but no latLng
// To keep the type safe we overwrite the latlng with the `nullIslandLatLng`

export const nullIslandLatLng: uui.domain.LatLng = {
  lat: Number.MAX_SAFE_INTEGER,
  lng: Number.MAX_SAFE_INTEGER,
}

export function createNullIslandLatLng() {
  return { ...nullIslandLatLng }
}

export function isNullIslandLatLng(latLng: uui.domain.LatLng) {
  return latLng.lat === nullIslandLatLng.lat && latLng.lng === nullIslandLatLng.lng
}

export const nullIslandLatLngString = latLngToString(nullIslandLatLng)

export function createLatLngBounds(
  east: number,
  north: number,
  south: number,
  west: number,
): uui.domain.LatLngBounds {
  return {
    east,
    north,
    south,
    west,
  }
}

export function getBoundsNorthEast(bounds: uui.domain.LatLngBounds): uui.domain.LatLng {
  return {
    lat: bounds.north,
    lng: bounds.east,
  }
}

export function getBoundsSouthWest(bounds: uui.domain.LatLngBounds): uui.domain.LatLng {
  return {
    lat: bounds.south,
    lng: bounds.west,
  }
}

export function boundsToPoints(
  bounds: uui.domain.LatLngBounds,
): [uui.domain.LatLng, uui.domain.LatLng, uui.domain.LatLng, uui.domain.LatLng] {
  const ne = getBoundsNorthEast(bounds)
  const sw = getBoundsSouthWest(bounds)
  const se = createLatLng(sw.lat, ne.lng)
  const nw = createLatLng(ne.lat, sw.lng)

  return [ne, se, sw, nw]
}

function equalsCoord(a: number, b: number, c: number = COORDINATES_EQUALITY_THRESHOLD): boolean {
  return Math.abs(a - b) <= c
}

export function latLngEquals(
  a: uui.domain.LatLng,
  b: uui.domain.LatLng,
  c: number = COORDINATES_EQUALITY_THRESHOLD,
): boolean {
  return equalsCoord(a.lat, b.lat, c) && equalsCoord(a.lng, b.lng, c)
}

export function latLngBoundsEquals(
  a: uui.domain.LatLngBounds,
  b: uui.domain.LatLngBounds,
  c: number = COORDINATES_EQUALITY_THRESHOLD,
): boolean {
  const aNE = getBoundsNorthEast(a)
  const bNE = getBoundsNorthEast(b)

  if (!latLngEquals(aNE, bNE, c)) {
    return false
  }

  const aSW = getBoundsSouthWest(a)
  const bSW = getBoundsSouthWest(b)
  return latLngEquals(aSW, bSW, c)
}

function roundCoordinate(coord: number, precision: number) {
  const pow = Math.pow(10, precision)
  return Math.round(coord * pow) / pow
}

export function latLngToUrlValue(latLng: uui.domain.LatLng, precision: number = 6): string {
  const lat = roundCoordinate(latLng.lat, precision)
  const lng = roundCoordinate(latLng.lng, precision)

  return `${lat},${lng}`
}

export function latLngToString(latLng: uui.domain.LatLng): string {
  return `(${latLng.lat}, ${latLng.lng})`
}

export function stringToLatLng(source: string): uui.domain.LatLng {
  const [lat, lng] = source
    .slice(1, -1) //          remove parenthesis
    .split(',') //            separate by comma
    .map(v => v.trim()) //    remove white-spaces
  return { lat: parseFloat(lat), lng: parseFloat(lng) }
}

export function getBoundsCenter(bounds: uui.domain.LatLngBounds): uui.domain.LatLng {
  const ne = getBoundsNorthEast(bounds)
  const sw = getBoundsSouthWest(bounds)

  return getCenter(ne, sw)
}

export function getCenter(a: uui.domain.LatLng, b: uui.domain.LatLng): uui.domain.LatLng {
  return createLatLng((a.lat + b.lat) / 2, (a.lng + b.lng) / 2)
}

export function boundsContains(bounds: uui.domain.LatLngBounds, latLng: uui.domain.LatLng) {
  const { east, north, south, west } = bounds
  const { lat, lng } = latLng

  const containsLat = lat >= south && lat <= north
  const containsLng = lng >= west && lng <= east

  return containsLat && containsLng
}

export function contains(poly: uui.domain.LatLng[], latLng: uui.domain.LatLng): boolean {
  return pointInPoly(poly, latLng)
}

export function distanceBetween(from: uui.domain.LatLng, to: uui.domain.LatLng): number {
  const p1 = new GeodesyLatLon(from.lat, from.lng)
  const p2 = new GeodesyLatLon(to.lat, to.lng)
  return p1.distanceTo(p2)
}

export function computeOffset(
  from: uui.domain.LatLng,
  distanceInMeters: number,
  heading: number,
): uui.domain.LatLng {
  const p1 = new GeodesyLatLon(from.lat, from.lng)
  const p2 = p1.destinationPoint(distanceInMeters, heading)
  return createLatLng(p2.lat, p2.lon)
}

export function insideCircle(
  latLng: uui.domain.LatLng,
  center: uui.domain.LatLng,
  radius: number = 0,
  onEdge: boolean = false,
): boolean {
  if (radius <= 0) {
    return false
  }

  const distance: number = distanceBetween(center, latLng)

  return onEdge ? distance <= radius : distance < radius
}

export function validLatLng(lat: number, lng: number): boolean {
  try {
    GeodesyLatLon.parse(lat, lng)
    return true
  } catch (e) {}

  return false
}

// --------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------
// OpenLayers related API

export function fromLatLngToCoordinate({ lng, lat }: uui.domain.LatLng) {
  return fromLonLat([lng, lat]) as [lon: number, lat: number]
}

export function fromCoordinateToLatLng(coord: Coordinate): uui.domain.LatLng {
  const [lng, lat] = toLonLat(coord)
  return createLatLng(lat, lng)
}

export function fromLatLngToMapPixel(map: Map, latLng: uui.domain.LatLng): uui.domain.Point {
  const [x, y] = map.getPixelFromCoordinate(fromLatLngToCoordinate(latLng))

  return createPoint(x, y)
}

export function fromMapPixelToLatLng(map: Map, x: number, y: number): uui.domain.LatLng {
  const tmpPointInPixel = createPoint(x, y)
  return fromCoordinateToLatLng(map.getCoordinateFromPixel([tmpPointInPixel.x, tmpPointInPixel.y]))
}

export function getMapBounds(map: Map): uui.domain.LatLngBounds {
  const view = map.getView()
  const viewExtent = view.calculateExtent(map.getSize())

  const { lat: north, lng: west } = fromCoordinateToLatLng(ext.getTopLeft(viewExtent))
  const { lat: south, lng: east } = fromCoordinateToLatLng(ext.getBottomRight(viewExtent))

  return createLatLngBounds(east, north, south, west)
}

export function computePolyBoundaries(points: uui.domain.LatLng[] = []): uui.domain.LatLngBounds {
  const coordinates: Coordinate[] = []
  for (const point of points) {
    coordinates.push(fromLatLngToCoordinate(point))
  }
  const polyExtent = ext.boundingExtent(coordinates)

  const { lat: north, lng: west } = fromCoordinateToLatLng(ext.getTopLeft(polyExtent))
  const { lat: south, lng: east } = fromCoordinateToLatLng(ext.getBottomRight(polyExtent))

  return createLatLngBounds(east, north, south, west)
}

export function getOpenLayerCoordinatesFromEncodedPoly(poly: string) {
  const polylineParser = new Polyline()

  const geometry = polylineParser.readGeometry(poly, {
    featureProjection: 'EPSG:3857',
  }) as LineString

  return geometry.getCoordinates()
}
