import { Checkbox, FormControlLabel } from '@mui/material'
import { type Column as ColumnData } from '../../../types'

type ColumnProps = ColumnData & {
  onChange: (columnId: string) => void
  checked: boolean
}

export function Column(props: ColumnProps) {
  const { checked, onChange, label, id } = props

  return (
    <FormControlLabel
      data-testid="columns-picker-column"
      control={<Checkbox checked={checked} onChange={() => onChange(id)} />}
      label={label}
    />
  )
}
