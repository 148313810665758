import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    switchTerritory: translate({ id: 'app.header.territoryMenu.switch' }),
    renameTerritory: translate({ id: 'app.header.territoryMenu.rename' }),
    deleteTerritory: translate({ id: 'app.header.territoryMenu.delete' }),
    timeShiftPlan: translate({ id: 'app.header.territoryMenu.timeShift' }),
    importNewTerritory: translate({ id: 'app.header.territoryMenu.import' }),
    territorySettings: translate({ id: 'app.header.territoryMenu.settings' }),
    importDemoTerritory: translate({ id: 'app.header.territoryMenu.importDemo' }),
    editingInProgressTitle: translate({ id: 'app.header.territoryMenu.editingInProgress.title' }),
    editingInProgressSubtitle: translate({
      id: 'app.header.territoryMenu.editingInProgress.subtitle',
    }),
    tooltipGpsOnly: (org: string) =>
      translate({ id: 'app.header.territoryMenu.tooltip.gpsOnlyUser', values: { org } }),
  }))

  return api
}
