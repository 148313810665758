import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function ShowDetails(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 18 18">
        <path d="M2.29 0A2.09 2.09 0 0 0 .2 2.09v11.143a2.09 2.09 0 0 0 2.09 2.09h3.482V13.93H2.289a.698.698 0 0 1-.696-.697V4.875h15.323v8.358a.698.698 0 0 1-.697.697H12.04v1.393h4.18a2.09 2.09 0 0 0 2.089-2.09V2.09A2.09 2.09 0 0 0 16.219 0H2.29zm-.24 2.046h.5a.5.5 0 0 1 .501.5v.48H3.03a.5.5 0 0 1-.501.5H2.05a.5.5 0 0 1-.5-.5v-.48a.5.5 0 0 1 .5-.5zm2.09 0h.5a.5.5 0 0 1 .5.5v.48H5.12a.5.5 0 0 1-.5.5h-.48a.5.5 0 0 1-.5-.5v-.48a.5.5 0 0 1 .5-.5zm1.632.043h11.144v1.393H5.772V2.09zm3.504 6.312a.331.331 0 0 0-.24.088L5.904 11.97a.362.362 0 0 0-.11.24c0 .043.006.09.022.13.052.122.194.196.327.196h1.72c0 2.916-.51 4.5-3.831 5.463 5.56 0 6.66-3.216 6.66-5.463h1.698c.133 0 .23-.074.283-.196a.343.343 0 0 0-.066-.37L9.494 8.489a.283.283 0 0 0-.218-.088z" />
      </svg>
    </IconContainer>
  )
}
