import type { DayToDispatch } from '../../typings'

import { FlexBox, H3, P3 } from '@/local/components'

import { PartialDispatchButton } from './components/PartialDispatchButton'
import { RevokeButton } from './components/RevokeButton'
import { DispatchButton } from './components/DispatchButton'
import { Icon } from './components/Icon'
import { useTexts } from './useTexts/useTexts'

interface Props {
  day: DayToDispatch
}

export const DayCard = (props: Props) => {
  const { day } = props
  const {
    id,
    date,
    isToday,
    isTomorrow,
    inProgress,
    routesCount,
    dispatchedRoutesCount,
    ordersCount,
    dispatched,
  } = day

  const texts = useTexts()

  const noRoutes = routesCount === 0 && dispatchedRoutesCount === 0
  const isPartial = routesCount > dispatchedRoutesCount
  const hasRoutes = !noRoutes

  const title = texts.title(date, isToday, isTomorrow, inProgress)
  const description = texts.description(routesCount, ordersCount, dispatchedRoutesCount)

  return (
    <FlexBox
      column
      key={id}
      tint="$alabaster"
      color={noRoutes ? '$silver' : '$nightRider'}
      padding={10}
      w="100%"
      marginBottom={10}
      style={{ borderRadius: 8 }}
    >
      <FlexBox marginBottom={4}>
        <H3>{title}</H3>
      </FlexBox>
      <FlexBox vAlignContent="center" marginBottom={hasRoutes ? 14 : 0}>
        <Icon
          routesCount={routesCount}
          dispatched={dispatched}
          dispatchedRoutesCount={dispatchedRoutesCount}
        />
        <P3>{description}</P3>
      </FlexBox>
      {hasRoutes && dispatched && (
        <FlexBox gutter={'auto'} w="100%">
          {isPartial && <PartialDispatchButton day={day} />}
          <RevokeButton day={day} />
        </FlexBox>
      )}
      {hasRoutes && !dispatched && (
        <FlexBox gutter={'auto'} w="100%">
          <DispatchButton day={day} />
        </FlexBox>
      )}
    </FlexBox>
  )
}
