import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getVersion } from '../../utils/version'

type RejectError = {
  message: string
  error: Error
}
export const intializePendo = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  void,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('lifecycle/initializePendo', async (_, thunkApi) => {
  try {
    if (!thunkApi.extra.pendo) {
      if (!window.location.hostname.includes('localhost')) {
        thunkApi.extra.journal.main(
          `No Pendo available, if you aren't in DEV mode something went wrong`,
          { tags: ['pendo'] },
          'warn',
        )
      }

      return
    }

    const account = thunkApi.getState().domain.publicData.profile.account
    const user = thunkApi.getState().domain.publicData.profile.user
    const gpsUser = thunkApi.getState().domain.publicData.profile.gpsUser
    const territory = thunkApi.getState().domain.publicData.territory

    const {
      licensingLimits,
      details: { companyName, country, timeZoneCode, resellerEmail },
      credentials: { username, salesforceId, salesrep },
    } = account

    const userType = !!account.licensingLimits.gpsOnly
      ? 'gps'
      : licensingLimits.allowGps
      ? '360'
      : 'rm'

    const uiVersion = getVersion()

    thunkApi.extra.pendo.initialize({
      visitor: {
        id: username, // Required if user is logged in
        // Custom Visitor data

        userType,

        // from RM account
        rmUsername: username,

        // from RM user profile
        rmUserRole: user.type,
        name: user.name,
        surname: user.surname,
        email: user.email,
        uiLanguage: user.settings.language,
        timeFormat: user.settings.timeFormat,
        distanceFormat: user.settings.distanceFormat,
        dateFormat: user.settings.dateFormat,
        currency: user.settings.currency,

        // from GPS user profile
        gpsId: gpsUser ? gpsUser.userId : undefined,
        gpsLogin: gpsUser ? gpsUser.loginName : undefined,
        gpsTimezone: gpsUser ? gpsUser.timezone : undefined,
        gpsRole: gpsUser ? gpsUser.role : undefined,

        uiVersion,

        // DTODO: verifuy if this is still relevant or it can be dopped
        // ...(redirectFromLegacyValue ? { redirectFromLegacy: redirectFromLegacyValue } : {}),
      },

      account: {
        id: account.id,

        // ATTENTION: You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names:
        // name, planLevel, planPrice, creationDate
        rmCompany: companyName,
        rmCountry: country,
        rmPrivileges: licensingLimits.privileges,
        rmSalesforceId: salesforceId,
        rmSalesrepName: salesrep,
        rmTimezone: timeZoneCode,
        rmAllowAPIflag: licensingLimits.allowApi,
        rmAllowProposalAPIflag: licensingLimits.allowProposals,
        rmVehicles: licensingLimits.vehicles,

        rm360flag: licensingLimits.allowTracking,
        rmAllowVehicleCreationDeletionAPIflag: licensingLimits.allowApiVehicles,
        rmMaxRoadSegments: licensingLimits.maxSegmentExceptions,
        rmMaxBarcodes: licensingLimits.maxBarcodes,
        rmMaxCustomFieldsPerOrder: licensingLimits.maxCustomFieldsPerOrder,

        rmHorizonOptDays: territory.licensingLimits.horizonOpt,
        rmHorizonViewDays: territory.licensingLimits.horizonView,
        rmOrderRadius: territory.licensingLimits.orderRadiusMt,
        rmOperationOrders: territory.licensingLimits.ordersOps,
        rmSimulationOrders: territory.licensingLimits.ordersSim,
        rmTrackedVehicles: territory.licensingLimits.trackedVehicles,
        rmVehicleRadius: territory.licensingLimits.vehicleRadiusMt,
        rmMaxStatusReasonsCount: territory.licensingLimits.maxStatusReasonsCount,

        rmResellerEmail: resellerEmail,
        rmSuspended: licensingLimits.suspended,
        rmExpiryDate: licensingLimits.expiryDate,

        restrictToV3: licensingLimits.forceUUI,
      },
    })

    const checkReadyStatus = () => thunkApi.extra.pendo!.isReady()
    const initialized = await checkStatus(checkReadyStatus)

    if (!initialized) {
      // TODO: add a proper localized message
      // JOURNAL: trigger the journal
      return thunkApi.rejectWithValue({
        message: 'Impossible to initialize Pendo',
        error: new Error('Impossible to initialize Pendo'),
      })
    }

    thunkApi.extra.pendo.loadGuides()
    thunkApi.extra.pendo.startGuides()
  } catch (error) {
    // TODO: add a proper localized message
    // JOURNAL: trigger the journal
    return thunkApi.rejectWithValue({
      message: 'Failed initializing Pendo',
      error,
    })
  }
})

const checkStatus = (action: () => boolean, timeout = 30000) => {
  const interval = 500

  return new Promise<boolean>(resolve => {
    let elapsed = 0

    const execute = () => {
      setTimeout(() => {
        elapsed += interval

        if (action()) {
          resolve(true)
        } else if (elapsed <= timeout) {
          execute()
        } else {
          resolve(false)
        }
      }, interval)
    }

    execute()
  })
}
