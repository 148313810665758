import { useCallback } from 'react'

import { format } from 'date-fns/esm'

import { changeCalendarRange as changeCalendarRangeAction } from '@/features/domain/ui'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { useNavigate } from '@/routing'

export const useActions = () => {
  const navigate = useNavigate<uui.routing.MainSections>()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const changeCalendarRange = useCallback(
    async (start?: Date, end?: Date) => {
      try {
        const startDate = start ?? Date.now()
        const minDate = format(startDate, 'yyyyMMdd')
        const maxDate = format(end ?? startDate, 'yyyyMMdd')

        const result = await dispatch(changeCalendarRangeAction({ minDate, maxDate }))

        if (!changeCalendarRangeAction.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e)
      }
    },
    [dispatch, toast],
  )

  const navigateToHome = useCallback(() => navigate('/'), [navigate])

  return { navigateToHome, changeCalendarRange }
}
