import type { SearchableListTypes } from '@/forms-legacy'

import { intl } from '@/intl'

export function regionsRenderText(
  key: SearchableListTypes.SearchableListText,
  props: SearchableListTypes.Props<uui.domain.client.rm.Region>,
  count: number = 0,
): string {
  const { values } = props
  const total: number = values.length || 0

  switch (key) {
    case 'filterPlaceholder':
      return intl.translate({ id: 'vehicles.form.regions.filterPlaceholder' })
    case 'checkAll':
      return intl.translate({
        id: 'vehicles.form.regions.checkAll',
        values: {
          count: total,
        },
      })

    case 'unCheckAll':
      return intl.translate({
        id: 'vehicles.form.regions.uncheckAll',
        values: {
          count: total,
        },
      })

    case 'footerDescription':
      return intl.translate({
        id: 'vehicles.form.regions.footerDescription',
        values: {
          total,
          checked: count,
        },
      })

    default:
      return key
  }
}
