import { PureComponent } from 'react'
import { Field as FinalFormField, FieldRenderProps } from 'react-final-form'

import NotificationEditorField from './NotificationEditorField'
import { PublicProps as Props } from './typings'

export default class NotificationEditorFieldWrapper extends PureComponent<Props> {
  render() {
    const { name, ...props } = this.props

    return (
      <FinalFormField
        {...props}
        name={name}
        render={(formProps: FieldRenderProps<any>) => {
          return <NotificationEditorField {...this.props} formProps={formProps} />
        }}
      />
    )
  }
}
