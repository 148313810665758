import { useMemo } from 'react'

import { RadioGroupField } from '@/forms-legacy'

import { useTexts } from './useTexts'

export function Type() {
  const texts = useTexts()

  const wrappedInputProps = useMemo(() => {
    return {
      values: [
        {
          label: texts.disabled,
          value: 'disabled',
        },
        {
          label: texts.blocked,
          value: 'blocked',
        },
        {
          label: texts.delayed,
          extraLabel: texts.min,
          value: 'delayed',
        },
      ],
    }
  }, [texts])

  return <RadioGroupField name="type" label={texts.label} wrappedInputProps={wrappedInputProps} />
}
