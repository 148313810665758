import { Box } from '@mui/material'

import { WarningCard } from '@local/components'
import { Cross, WarningTriangle } from '@/icons'

import { useLiveEtaStatus } from '../../../../../../hooks/useLiveEtaStatus'
import { useTexts } from './useTexts'

export function NotificationLimitations() {
  const liveEtaStatus = useLiveEtaStatus()
  const texts = useTexts()

  // If the user is 360Legacy (no Live ETA)
  if (liveEtaStatus === 'notAvailableForTheUser')
    return (
      <Box paddingBottom={4}>
        <WarningCard
          description={texts.liveEtaNotAvailableForUserDescription}
          title={texts.liveEtaNotAvailableForUserTitle}
          Icon={<WarningTriangle size={16} color="$pureWhite" background="$darkOrange" />}
          preset="warning"
        />
      </Box>
    )

  // If Live ETA is not enabled on the Territory let's return just the alert
  if (liveEtaStatus === 'notActive')
    return (
      <Box paddingBottom={4}>
        <WarningCard
          description={texts.liveEtaRequiredDescription}
          title={texts.liveEtaRequiredTitle}
          Icon={<Cross size={16} />}
          preset="alert"
        />
      </Box>
    )

  return (
    <>
      <Box paddingBottom={4}>
        <WarningCard
          description={texts.notificationLimitationDescription}
          title={texts.notificationLimitationTitle}
          Icon={<WarningTriangle size={16} color="$pureWhite" background="$darkOrange" />}
          preset="warning"
        />
      </Box>

      {liveEtaStatus === 'notActiveOnAllVehicles' && (
        <Box paddingBottom={4}>
          <WarningCard
            description={texts.liveEtaRequiredOnAllVehiclesDescription}
            title={texts.liveEtaRequiredOnAllVehiclesTitle}
            Icon={<WarningTriangle size={16} color="$pureWhite" background="$darkOrange" />}
            preset="warning"
          />
        </Box>
      )}
    </>
  )
}
