import { Box, Stack } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { Text } from '@/local/components'

import { MoreTag } from './MoreTag'

import { useTexts } from './useTexts'

interface Props {
  trackingData?: uui.domain.client.rm.OrderStepTrackingData

  showDynamicLoads: boolean
  gotMore: boolean
  loads: Record<string, number>
}

const multiColumnContainerStyle = {
  height: 44,
  columnGap: 0,
  columnFill: 'auto',
  columnCount: 2,
  columnWidth: 100,
}

function getLoadValue(number: number) {
  // Round the number to hundreds
  const roundedValue = Math.round(number * 100) / 100

  // Convert to correct load value
  return roundedValue / 100
}

export function LoadsBlock(props: Props) {
  const { loads, gotMore, trackingData, showDynamicLoads } = props

  const texts = useTexts()
  const keys = Object.keys(loads)

  const hasExecutionEvents = trackingData && !trackingData.isEmpty

  return (
    <Box
      data-trackid="navigo-orders-overview-details-single-orderStats-loadsBlock"
      data-testid="navigo-orders-overview-details-single-orderStats-loadsBlock"
      sx={multiColumnContainerStyle}
    >
      {keys.map((name, index) => {
        const configuredLoad = getLoadValue(loads[name])
        const executedLoad = trackingData?.loadTrackedMap?.[name]
          ? getLoadValue(trackingData.loadTrackedMap[name].value)
          : '-'
        const tooltipText =
          showDynamicLoads && hasExecutionEvents
            ? `${executedLoad} / ${configuredLoad} ${name}`
            : `${configuredLoad} ${name}`

        return (
          <Tooltip placement="top" title={tooltipText} key={`column_${index}`}>
            <Stack
              direction="row"
              flexWrap="nowrap"
              data-testid="navigo-orders-overview-details-single-orderStats-loadsBlock-load"
              data-trackid="navigo-orders-overview-details-single-orderStats-loadsBlock-load"
            >
              <Text
                size="$p3"
                style={{ opacity: index === 0 ? 1 : 0, whiteSpace: 'nowrap' }}
                testid="navigo-orders-overview-details-single-orderStats-loadsBlock-load-name"
              >
                {`${texts.loads}:`}&nbsp;
              </Text>

              <Text
                size="$p3"
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                weight="$semiBold"
                testid="navigo-orders-overview-details-single-orderStats-loadsBlock-load-value"
              >
                {showDynamicLoads && hasExecutionEvents && `${executedLoad} /`}
                {`${configuredLoad} ${name}`}
              </Text>

              {index + 1 === keys.length && gotMore ? <MoreTag /> : null}
            </Stack>
          </Tooltip>
        )
      })}
    </Box>
  )
}
