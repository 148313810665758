import { Scrollbar } from '@workwave-tidal/tidal/components'

import { FlexBox } from '@/local/components'
import { resetRoutingSidebar } from '@/atoms'
import { PaperPlane } from '@/icons'

import { PanelHeader } from './components/PanelHeader'
import { DayCard } from './components/DayCard'

import { useDispatchDays } from './hooks/useDispatchDays'
import { useTexts } from './hooks/useTexts'

export function DispatchPanel() {
  const { days, dispatchedDaysCount } = useDispatchDays()

  const texts = useTexts()

  return (
    <FlexBox column h="100%" tint="$pureWhite">
      <PanelHeader
        title={texts.headerTitle}
        subTitle={texts.subtitle(dispatchedDaysCount)}
        Icon={<PaperPlane size={18} />}
        onClose={resetRoutingSidebar}
        closeButtonClassName="pendo-dispatch-panel__btn-close"
      />

      <Scrollbar hideOverflow="x">
        {days.map(day => (
          <DayCard key={day.id} day={day} />
        ))}
      </Scrollbar>
    </FlexBox>
  )
}
