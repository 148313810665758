import { PureComponent } from 'react'
import {
  Field as FinalFormField,
  FieldProps as FFieldProps,
  FieldRenderProps as FFieldRenderProps,
} from 'react-final-form'

import { isDeepEqual } from '@/server-data'
import {
  HorizontalRadioGroup,
  Block,
  Label,
  FieldMessage,
  RestoreFieldButton,
  HorizontalRadioGroupProps,
} from '@/forms-legacy'

export type FieldProps = FFieldProps<string | number, any>
export type FieldRenderProps = FFieldRenderProps<string | number>

export interface Props extends FieldProps {
  name: string
  label: string
  info?: string
  half?: boolean
  labelClassName?: string
  wrappedInputProps: Omit<HorizontalRadioGroupProps, 'onChange' | 'value' | 'name'>
  testid?: string
}

export default class HorizontalRadioGroupField extends PureComponent<Props> {
  private renderField = (formProps: FieldRenderProps) => {
    const {
      label,
      name,
      info,
      half = false,
      wrappedInputProps,
      labelClassName,
      testid,
    } = this.props
    const {
      input: { value, onChange },
      meta: { error, dirty, initial },
    } = formProps

    return (
      <Block half={half} error={!!error} dirty={!!dirty} data-testid={testid} data-trackid={testid}>
        <Label htmlFor={name}>
          <span className={labelClassName}>{label}</span>
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <HorizontalRadioGroup
          {...wrappedInputProps}
          name={name}
          onChange={onChange}
          value={value}
        />
        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }

  render() {
    const { name, ...props } = this.props
    return <FinalFormField {...props} isEqual={isDeepEqual} name={name} render={this.renderField} />
  }
}
