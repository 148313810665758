import type { ActionPermissions } from '../../../../hooks/useVehiclesNavigoMachine/types'

import { HorizontalLayout } from '@/components/layout'
import { Separator } from '@/components/Separator'

import { CenterOnMapButton } from './components/CenterOnMapButton'
import { SendMessageButton } from './components/SendMessageButton'
import { BreadcrumbsButton } from './components/BreadcrumbsButton'
import { ReportButton } from './components/ReportButton'
import { EventsButton } from './components/EventsButton'
import { ExpandButton } from './components/ExpandButton'
import { EditButton } from './components/EditButton'

import { useFitMapOnVehiclesForBreadcrumbFetch } from './hooks/useFitMapOnVehiclesForBreadcrumbFetch'
import { useBreadcrumbsStatus } from './hooks/useBreadcrumbsStatus'
import { useModalActions } from './hooks/useModalActions'
import { useActions } from './hooks/useActions'
import { useEvents } from './hooks/useEvents'
import { useStyles } from './hooks/useStyles'

import { showByPermission } from './utils'

interface Props {
  expanded: boolean
  toggleExpanded: () => void
  permissions: ActionPermissions
  vehicles: uui.domain.client.UnifiedVehicle[]
}

export function Actions(props: Props) {
  const { expanded, permissions, vehicles, toggleExpanded } = props

  const breadcrumbStatus = useBreadcrumbsStatus(vehicles)
  const { modals, modalActions } = useModalActions()
  const eventsCount = useEvents(vehicles)
  const { toggleBreadcrumbs, goToEvents, centerOnMap, goToEdit, runReports } = useActions(
    vehicles,
    breadcrumbStatus,
    eventsCount,
  )

  const classes = useStyles()
  useFitMapOnVehiclesForBreadcrumbFetch(vehicles)

  return (
    <HorizontalLayout
      data-trackid="navigo-vehicle-overview-actions"
      data-testid="navigo-vehicle-overview-actions"
      alignItems="center"
      width="auto"
    >
      <HorizontalLayout width="auto" className={classes.actionsWrapper} alignItems="center">
        {expanded && (
          <>
            {showByPermission(permissions.events) && (
              <EventsButton
                permission={permissions.events}
                vehicles={vehicles}
                onClick={goToEvents}
                count={eventsCount}
              />
            )}

            {showByPermission(permissions.breadcrumbs) && (
              <BreadcrumbsButton
                permission={permissions.breadcrumbs}
                onClick={toggleBreadcrumbs}
                status={breadcrumbStatus}
                vehicles={vehicles}
              />
            )}

            <Separator className={classes.separator} />

            {showByPermission(permissions.report) && (
              <ReportButton
                permission={permissions.report}
                vehicles={vehicles}
                onClick={runReports}
              />
            )}

            {showByPermission(permissions.sendMessage) && (
              <SendMessageButton
                onClick={modalActions.showSendMessageToDriverModal}
                permission={permissions.sendMessage}
                vehicles={vehicles}
              />
            )}

            <CenterOnMapButton
              permission={permissions.centerOnMap}
              vehicles={vehicles}
              onClick={centerOnMap}
            />
          </>
        )}

        <ExpandButton expanded={expanded} onClick={toggleExpanded} />

        <EditButton onClick={goToEdit} permission={permissions.edit} />
      </HorizontalLayout>

      {modals}
    </HorizontalLayout>
  )
}
