import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { timeUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export const useFormatDateTime = (source: Date, excludeTime: boolean = false) => {
  const userConfig = useSelector(selectUserConfiguration)

  return useMemo(
    () => timeUtils.formatDateTime(userConfig)(source, excludeTime),
    [userConfig, source, excludeTime],
  )
}
