import { useMemo } from 'react'
import { useTheme } from '@mui/material'
import { useTexts } from './useTexts'
import { useOnDeleteUsers } from './useOnDeleteUsers'

export type MenuOption = {
  id: 'toggleUser' | 'changePassword' | 'delete'
  title: string
  action?: () => void
  color?: string
}

export function useMenuOptions(): MenuOption[] {
  const texts = useTexts()
  const onDeleteUsers = useOnDeleteUsers()
  const theme = useTheme()

  return useMemo(
    () => [
      {
        id: 'delete',
        title: texts.menu.delete,
        action: onDeleteUsers,
        color: theme.palette.error.main,
      },
    ],
    [theme, onDeleteUsers, texts],
  )
}
