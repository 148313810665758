import { useSelector } from 'react-redux'

import { selectUserType } from '@/features/domain/user'

import { FooterCommit } from './components/FooterCommit'
import { FooterLocked } from './components/FooterLocked'
import { FooterOffline } from './components/FooterOffline'
import { FooterContainer } from './elements/FooterContainer'
import { FooterArchived } from './components/FooterArchived'
import { FooterRollback } from './components/FooterRollback'
import { FooterSimulation } from './components/FooterSimulation'
import { FooterTransaction } from './components/FooterTransaction'

import { useFooterState } from './hooks/useFooterState'

const acceptedUserTypes: uui.domain.rm.RouteManagerUserType[] = ['admin', 'planner', 'courier']

export function Footer() {
  const { mode, offline, transaction } = useFooterState()
  const userType = useSelector(selectUserType)

  switch (mode) {
    case 'archived':
      return (
        <FooterContainer>
          <FooterArchived userType={userType} />
        </FooterContainer>
      )

    case 'simulation':
      return (
        <FooterContainer>
          <FooterSimulation offline={offline} />
        </FooterContainer>
      )

    case 'transaction':
      return (
        <FooterContainer>
          <FooterTransaction offline={offline} />
        </FooterContainer>
      )

    case 'rollback':
      return (
        <FooterContainer>
          <FooterRollback offline={offline} />
        </FooterContainer>
      )

    case 'commit':
      return (
        <FooterContainer>
          <FooterCommit offline={offline} />
        </FooterContainer>
      )

    case 'locked':
      return acceptedUserTypes.includes(userType) ? (
        <FooterContainer>
          <FooterLocked transaction={transaction} offline={offline} />
        </FooterContainer>
      ) : null

    case 'offline':
      return (
        <FooterContainer>
          <FooterOffline />
        </FooterContainer>
      )

    default:
    case 'hidden':
      return null
  }
}
