import type { GridApiRef, GridSelectionModel } from '@mui/x-data-grid-pro'
import { GridEvents } from '@mui/x-data-grid-pro'

import { useEffect } from 'react'
import { getScrollPosition, setOrdersGridScroll } from '@/atoms'
/**
 * Updates scroll position on selection change
 *
 * @param apiRef
 * @param selection
 */
//
export function useAutoScroll(apiRef: GridApiRef, selection: GridSelectionModel | undefined) {
  const { current } = apiRef

  useEffect(() => {
    setTimeout(() => {
      const isReady = !!current.getRowIndex

      if (!isReady) return

      //If there's a selection scroll to the correct index and bail-out
      if (selection?.length) {
        // if multiple selection do no perform any scroll
        if (selection.length > 1) return

        const rowIndex = current.getRowIndex(selection[0])

        current.scrollToIndexes({ rowIndex })
        return
      }

      // Otherwise go to the last stored position
      current.scroll(getScrollPosition().ordersGrid)
    }, 0)
  }, [current, selection])

  useEffect(() => {
    let unsubscribe: () => void | undefined

    setTimeout(() => {
      const isReady = !!current.getRowIndex

      if (!isReady) return

      // Store the current position in the atom once it changes
      unsubscribe = current.subscribeEvent(GridEvents.rowsScroll, params => {
        const { top, left } = params
        setOrdersGridScroll({ top, left })
      })
    }, 0)

    return () => {
      unsubscribe?.()
    }
  }, [current])
}
