import type { ChangeEvent } from 'react'
import type { BulkLoadExact } from '../../../../../types'

import { useCallback, useRef, useEffect } from 'react'
import { Stack, IconButton, TextField, Tooltip } from '@mui/material'
import { RemoveCircleOutline } from '@mui/icons-material'

import { useTexts } from './hooks/useTexts'

interface Props {
  load: BulkLoadExact
  onDeleteLoad: (name: string) => void
  onUpdateLoad: (name: string, value: string) => void
  errors?: string[]
  autoFocus?: boolean
  ordersCount: number
}

export function ExactLoad(props: Props) {
  const { load, onDeleteLoad, errors, onUpdateLoad, autoFocus, ordersCount } = props
  const { name, mixed, value } = load
  const error = !!errors

  const inputRef = useRef<HTMLInputElement | undefined>()

  const texts = useTexts()

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onUpdateLoad(name, event.target.value)
    },
    [onUpdateLoad, name],
  )

  useEffect(() => {
    autoFocus && inputRef.current?.focus()
  }, [autoFocus])

  const warning = load.warning && !error
  const color = warning ? 'warning' : undefined

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
      <TextField
        inputRef={inputRef}
        color={color}
        error={error}
        value={mixed ? '' : value}
        data-testid="bulkLoad-text"
        placeholder={mixed ? texts.mixed : undefined}
        InputLabelProps={{
          color,
          shrink: mixed || error ? true : undefined,
          focused: warning,
        }}
        focused={warning}
        label={name}
        onChange={onChange}
        helperText={error ? errors?.[0] : warning ? texts.undefinedLoad : undefined}
        FormHelperTextProps={{
          sx: { color: warning ? 'warning.main' : undefined },
        }}
        sx={{ margin: 0 }}
      />
      <Tooltip title={texts.removeTooltip(ordersCount)}>
        <IconButton color="error" onClick={() => onDeleteLoad(load.name)}>
          <RemoveCircleOutline />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}
