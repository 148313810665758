import type { InvalidDriversResult } from '../../../hooks/useInvalidData'

import { useMemo } from 'react'

import { addDays, format, differenceInCalendarDays } from 'date-fns/esm'
import { Box, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import { WarningCard } from '@local/components'
import { timeUtils } from '@/server-data'

import { selectUserConfiguration } from '@/features/domain/user'
import { WarningTriangle } from '@/icons'
import { dateUtils } from '@/utils'

import { useTexts } from '../../../useTexts'

type Props = {
  invalidDriverResult: InvalidDriversResult
}

export function SingleDriver(props: Props) {
  const { invalidDriverResult } = props
  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  if (Object.keys(invalidDriverResult.details).length !== 1) {
    throw new Error(
      'Is not possible to have zero or multiple driver deletion date on a single driver',
    )
  }

  const { deletionDateText, daysToDeletionDate, deletionDateWeekDay } = useMemo(() => {
    const deletionDateAsString = Object.keys(invalidDriverResult.details)[0]
    const { today } = userConfig

    const todayDate = dateUtils.parseDate(today)
    const deletionDate = dateUtils.parseDate(deletionDateAsString)
    const tomorrow = format(addDays(todayDate, 1), 'yyyyMMdd')

    let deletionDateText = ''

    switch (deletionDateAsString) {
      case today:
        deletionDateText = texts.today
        break

      case tomorrow:
        deletionDateText = texts.tomorrow
        break

      default:
        deletionDateText = timeUtils.formatDate(userConfig)(deletionDate)
    }

    return {
      deletionDateText,
      daysToDeletionDate: differenceInCalendarDays(deletionDate, todayDate),
      deletionDateWeekDay: format(deletionDate, 'EEEE'),
    }
  }, [invalidDriverResult, userConfig, texts])

  return (
    <Stack width="100%" paddingX={1}>
      <WarningCard
        Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
        preset="warning"
        description={texts.warningTitle}
      />

      <Stack mt={2}>
        <Box>
          {`${texts.doNotAssignDriverForTheNext} `}
          <Typography fontWeight={600} display="inline">
            {daysToDeletionDate}
          </Typography>
          {` ${texts.days.toLocaleLowerCase()}.`}
        </Box>
        <Box>
          {`${texts.theDriverCanBeDeletedFrom} `}
          <Typography fontWeight={600} display="inline">
            {deletionDateText}
          </Typography>
          {` (${deletionDateWeekDay}).`}
        </Box>
      </Stack>
    </Stack>
  )
}
