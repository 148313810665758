import { mapAtom } from '../../atoms/map/map'
import { findLayer } from '../utils/findLayer'
import { changelogAddDeviceEventFeatures } from './utils/changelogAddDeviceEventFeatures'
import { changelogRemoveDeviceEventFeatures } from './utils/changelogRemoveDeviceEventFeatures'
import { changelogUpdateDeviceEventFeatures } from './utils/changelogUpdateDeviceEventFeatures'

export function createUpdateDeviceEventFeaturesFromChangelog(
  markers: uui.domain.ui.map.markers.MapMarkers['deviceEvent'],
  selection: Set<string>,
  changelog: uui.domain.api.Changelog,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  // ATTENTION: Currently all changelog are applied as soon as they arrive, also when the map is not visible
  // `ignoreSelection` it will be used to avoid to apply any selection information to the map when applying cached updater functions.
  // after all cached updates will be applied a refresh selection action will be required
  return async (_ignoreSelection: boolean = false) => {
    const map = mapAtom.map
    const layer = findLayer(map, 'deviceEvent')

    // remove
    await changelogRemoveDeviceEventFeatures(layer, selection, changelog.remove.values())

    // add
    await changelogAddDeviceEventFeatures(
      layer,
      markers,
      selection,
      changelog.add.values(),
      breadcrumbTimeRange,
    )

    // update
    await changelogUpdateDeviceEventFeatures(
      layer,
      markers,
      selection,
      changelog.update.values(),
      breadcrumbTimeRange,
    )
  }
}
