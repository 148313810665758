import { PlanSummary } from '@/components/PlanSummary'
import { VerticalLayout } from '@/components/layout'
import { useRoutingSidebar } from '@/atoms'

import { RoutingNav } from './components/RoutingNav'
import { RouteDetails } from './components/RouteDetails'
import { DispatchPanel } from './components/DispatchPanel'

export function RoutingSidebar() {
  const [settings] = useRoutingSidebar()

  switch (settings.view) {
    case 'main':
      return (
        <VerticalLayout justifyContent="space-between">
          <RoutingNav />
          <PlanSummary />
        </VerticalLayout>
      )

    case 'dispatchPanel':
      return <DispatchPanel />

    case 'routeDetails':
      return <RouteDetails routeId={settings.routeId} />

    default:
      return null
  }
}
