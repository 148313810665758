import { useState, useCallback, MouseEvent } from 'react'

import { IconButton, Tooltip } from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'

import { DropdownMenu, DropdownMenuItem } from '@/components/DropdownMenu'

import { useTexts } from './useTexts'

interface Props {
  onDeleteAllLoads: () => void
  deleteAllLoadsDisabled?: boolean
  disabled?: boolean
}

const noop = (e: MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault()
}

export function Menu(props: Props) {
  const { onDeleteAllLoads, deleteAllLoadsDisabled, disabled } = props

  const [open, setOpen] = useState(false)
  const texts = useTexts()

  const onRemoveAllAndCloseMenu = useCallback(() => {
    onDeleteAllLoads()
    setOpen(false)
  }, [onDeleteAllLoads])

  return (
    <DropdownMenu
      setOpen={setOpen}
      open={open}
      autoWidth
      disabled={disabled}
      trigger={
        // href="#" onClick={noop} ==> Hack to have an <a> component and not a button
        <Tooltip title={disabled ? texts.fieldDisabled : texts.moreTooltip}>
          <IconButton href="#" onClick={noop} data-testid="bulk-loads_menu-btn">
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <Tooltip title={deleteAllLoadsDisabled ? texts.deleteAllLoadsDisabledTooltip : ''}>
        <span>
          <DropdownMenuItem
            size="$l"
            weight="$regular"
            onClick={onRemoveAllAndCloseMenu}
            testid="bulk-loads-remove-all"
            warn
            disabled={deleteAllLoadsDisabled}
          >
            {texts.removeAll}
          </DropdownMenuItem>
        </span>
      </Tooltip>
    </DropdownMenu>
  )
}
