import { useCallback, useState } from 'react'
import { updateMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'showDepotsWithLabels' | 'showDepots' | 'hideDepots'
export function useActions() {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const showDepots = useCallback(async () => {
    setMethodInExecution('showDepots')

    await dispatch(updateMapStyle({ type: 'depot', mode: 'on' }))

    setMethodInExecution(null)
  }, [dispatch])

  const showDepotsWithLabels = useCallback(async () => {
    setMethodInExecution('showDepotsWithLabels')

    await dispatch(updateMapStyle({ type: 'depot', mode: 'label' }))

    setMethodInExecution(null)
  }, [dispatch])

  const hideDepots = useCallback(async () => {
    setMethodInExecution('hideDepots')

    await dispatch(updateMapStyle({ type: 'depot', mode: 'off' }))

    setMethodInExecution(null)
  }, [dispatch])

  return { methodInExecution, actions: { showDepots, showDepotsWithLabels, hideDepots } }
}
