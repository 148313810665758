import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    errors: {
      required: translate({ id: 'form.drivers.edit.errors.required' }),
      email: {
        inUse: translate({ id: 'form.drivers.edit.errors.email.inUse' }),
        invalid: translate({ id: 'form.drivers.edit.errors.email.invalid' }),
      },
    },
    otherAccount: translate({ id: 'form.drivers.edit.gpsDeviceId.otherAccount' }),
  }))

  return api
}
