import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    shareLocation: translate({ id: 'sendMessageToDriverModal.shareLocation.title' }),
    prefilled: (url: string) =>
      translate({ id: 'sendMessageToDriverModal.message.prefilled', values: { url } }),
  }))

  return api
}
