import { proxy, useSnapshot, subscribe } from 'valtio'

type LayoutMode = 'split' | 'map' | 'orders-grid' | 'scheduler'
type RoutingLayout = {
  mode: LayoutMode
  schedulerHeight: number
  bottomElement: 'scheduler' | 'orders-grid'
}
type RoutingLayoutAtom = { layout: RoutingLayout }

// ------------------------------------
// Default values
// ------------------------------------
const defaultRoutingLayout = {
  mode: 'split',
  schedulerHeight: 400,
  bottomElement: 'scheduler',
}

// ------------------------------------
// Restore from Local Storage
// ------------------------------------

const localStorageId = 'routemanager/v1/atoms/routingLayout'

const { layout: initialLayout = defaultRoutingLayout as RoutingLayout } = JSON.parse(
  localStorage.getItem(localStorageId) ?? '{}',
)

// ------------------------------------
// Routing layout atom
// ------------------------------------

const createDefaultRoutingLayout = (): RoutingLayoutAtom => ({
  layout: initialLayout,
})

export const routingLayoutAtom = proxy<RoutingLayoutAtom>(createDefaultRoutingLayout())

// ------------------------------------
// Write to Local Storage
// ------------------------------------

subscribe(routingLayoutAtom.layout, () => {
  localStorage.setItem(localStorageId, JSON.stringify(routingLayoutAtom))
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetRoutingLayout = (prev: RoutingLayout) => RoutingLayout
type SetRoutingLayoutParam = SetRoutingLayout | Partial<RoutingLayout>

export const setRoutingLayout = (valueOrFunc: SetRoutingLayoutParam) => {
  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(routingLayoutAtom.layout, valueOrFunc(routingLayoutAtom.layout))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      routingLayoutAtom.layout[field] = valueOrFunc[field]
    }
  }

  return routingLayoutAtom
}

// ------------------------------------
// React Hooks
// ------------------------------------

export const useRoutingLayout = () => {
  return [useSnapshot(routingLayoutAtom), setRoutingLayout] as const
}
