import { NotificationFormConfig } from '../../../../../notificationForms/sections/notifications/components/NotificationForm'

import { useBackupSubjectCustomValidator } from './validations/useBackupSubjectCustomValidator'
import { useTemplateCustomValidator } from './validations/useTemplateCustomValidator'
import { useSubjectCustomValidator } from './validations/useSubjectCustomValidator'

export function useCommonValidations() {
  const backupSubjectCustomValidator = useBackupSubjectCustomValidator()
  const templateCustomValidator = useTemplateCustomValidator()
  const subjectCustomValidator = useSubjectCustomValidator()

  return [
    ...subjectCustomValidator,
    ...templateCustomValidator,
    ...backupSubjectCustomValidator,
  ] as NotificationFormConfig['validations']
}
