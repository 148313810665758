import styled from 'styled-components'

const SmallLabel = styled.span`
  color: ${p => p.theme.colors.$silver};
  font-size: ${p => p.theme.fonts.$s};

  margin-top: 5px;
`

SmallLabel.displayName = 'SmallLabel'
export default SmallLabel
