import styled from 'styled-components'
import ActionButton from './ActionButtonSmall'

const OUTER_WIDTH = 50
const VERTICAL_SPACE = 0
const RADIUS = 7

const ICO_HEIGHT = 18
const ICO_HEIGHT__BIG = 24
const LABEL_HEIGHT = 18

const styledComponent = styled(ActionButton)`
  position: relative;
  z-index: 1;
  display: block;
  width: ${OUTER_WIDTH}px;
  height: 100%;
  padding: ${VERTICAL_SPACE}px 0;
  border-radius: ${RADIUS}px;

  &:disabled {
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: ${p => p.theme.colors.$paleSky};
    transform: scale(0);
    opacity: 0;
  }

  .o-action-button__button {
    border-width: 0;
    border-radius: 0;
    background: transparent;
    padding: 0;
    outline: 0;
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: ${p => p.theme.colors.$shadyLady};
    height: 100%;
    cursor: pointer;
    transition: opacity 0.3s ease;
    text-decoration: none;
  }

  .o-action-button__button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .o-action-button__button > * {
    color: inherit;
  }

  .o-action-button__ico {
    display: block;
    width: auto;
    height: ${ICO_HEIGHT}px;
    color: ${p => (p.iconColor ? p.theme.colors[p.iconColor] || 'inherit' : 'inherit')};
  }

  .o-action-button__ico--big {
    height: ${ICO_HEIGHT__BIG}px;
  }

  .o-action-button__label {
    display: flex;
    width: 100%;
    height: ${LABEL_HEIGHT}px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: ${p => p.theme.fonts.$xs};
  }

  .o-action-button__badge {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(10%, -35%);
    z-index: 6;
  }

  .o-action-button__badge .o-badge {
    color: ${p => p.theme.colors.$pureWhite};
    background: ${p => p.theme.colors.$scienceBlue};
  }
`

export default styledComponent
