import type TileSource from 'ol/source/Tile'
import type { MapTile } from '../map'

import { createXYZ } from 'ol/tilegrid'
import TileLayer from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'

import { authenticatedRasterTileLoadFunction } from './authenticatedRasterTileLoadFunction'
import { getTileUrl } from './tiles'

type Params = {
  lng?: string
  tileType: MapTile
  visible?: boolean
  tileSize?: number
}
export const createTileLayer = ({
  tileType,
  visible,
  lng = 'en',
  tileSize = 256,
}: Params): TileLayer<TileSource> => {
  const tileLayerSource = new XYZ({
    url: getTileUrl({ tileType, lng }),
    tileLoadFunction: authenticatedRasterTileLoadFunction,
    tileGrid: createXYZ({
      // extent: Base_bounds,
      minZoom: 3,
      maxZoom: 19,
      tileSize,
    }),
    crossOrigin: 'Anonymous',
    attributionsCollapsible: false,
    attributions:
      'Map Tiles &copy; ' +
      new Date().getFullYear() +
      ' ' +
      '<a href="http://developer.here.com">HERE</a>',
  })

  const tileLayer = new TileLayer({
    visible,
    source: tileLayerSource,
    preload: 0, // Infinity,
    properties: { id: 'tileLayer' },
  })

  return tileLayer
}
