import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    cancel: translate({ id: 'global.cancel' }),
    save: translate({ id: 'global.save' }),
    saveTitle: (valid: boolean, planLocked: boolean, pristine: boolean) => {
      if (!valid) return translate({ id: 'traffic.form.submitButton.disabled.title.invalid' })
      if (planLocked)
        return translate({ id: 'traffic.form.submitButton.disabled.title.planLocked' })
      if (pristine) return translate({ id: 'traffic.form.submitButton.disabled.title.pristine' })
      return ''
    },
    invalidTimeWindow: translate({ id: 'setup.traffic.timeWindow.startEndError' }),
    overlappingTimeWindow: translate({ id: 'setup.traffic.timeWindows.validation.overlapping' }),
  }))

  return api
}
