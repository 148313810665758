import {
  FlexRow,
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
} from '@/forms-legacy'

import { useTexts } from '../useTexts'

interface Props {
  driverBehaviors: uui.domain.client.gps.wwgps.DriverBehaviorSettings
}

export function CorneringList(props: Props) {
  const {
    driverBehaviors: { hardCornering },
  } = props

  const texts = useTexts()

  const galsText = texts.gals
  const notSetText = texts.notSet

  const hardValue: string = hardCornering.threshold
    ? `${hardCornering.threshold || ''} ${galsText}`
    : notSetText

  return (
    <>
      <ReadonlyLabel primary>{texts.cornering}</ReadonlyLabel>
      <FlexRow>
        <ReadonlyBlock aThird>
          <ReadonlyLabel>{texts.hard}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{hardValue}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      </FlexRow>
    </>
  )
}
