import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'
import { geo } from '@/server-data'

import { useTexts } from './hooks/useTexts'

interface Props {
  heading: number
}

export function Heading(props: Props) {
  const { heading } = props

  const texts = useTexts()

  return (
    <ReadonlyBlock half>
      <ReadonlyLabel>{texts.heading}</ReadonlyLabel>
      <ReadonlyField>
        <ReadonlyFieldRow>
          <ReadonlyFieldLabel>
            {heading}° {geo.computeCardinalDirection(heading)}
          </ReadonlyFieldLabel>
        </ReadonlyFieldRow>
      </ReadonlyField>
    </ReadonlyBlock>
  )
}
