import { useCallback } from 'react'

import { useNavigate } from '@/routing'
import { usePagination, setMainSelection } from '@/atoms'
import { useOrderStepsNavigator } from '../../../../../../hooks/useOrderStepsNavigator'

export const useGoToPods = () => {
  const navigate = useNavigate(true)
  const { currentOrderIds } = useOrderStepsNavigator()
  const { setPage: setOrderFormTab } = usePagination('orderTab')

  return useCallback(() => {
    setMainSelection('orderSteps', currentOrderIds)
    setOrderFormTab(1)
    navigate('orders')
  }, [setOrderFormTab, navigate, currentOrderIds])
}
