import { useConvertSpeed } from '@/hooks'

interface Props {
  metersPerSeconds: number
  decimals?: number
  printLabel?: boolean
}

export function ConvertSpeed(props: Props) {
  const { metersPerSeconds, decimals, printLabel } = props
  const value = useConvertSpeed(metersPerSeconds, decimals, printLabel)

  return <>{value}</>
}
