import { Grid } from '@mui/material'

import { intl } from '@/intl'

import { getSourceDetails } from '../../../utils/getSourceDetails'

import { IntegrationCard } from './IntegrationCard'

type Props = {
  availableIntegrations: uui.domain.server.gps.telematics.Source[]
  onIntegrationClick: () => void
  onSetSource: (source: uui.domain.server.gps.telematics.Source) => void
  disabled?: boolean
  userRole: 'admin' | 'courier' | 'planner' | 'viewer' | 'guest' | 'gpsonly'
}

export function AvailableIntegrationsGrid(props: Props) {
  const { availableIntegrations, disabled, userRole, onIntegrationClick, onSetSource } = props

  return (
    <Grid container spacing={2} rowSpacing={2} width="100%" maxWidth={1152}>
      {availableIntegrations.map(integration => {
        const { logoBigUrl, descriptionId } = getSourceDetails(integration.label)
        const onClick =
          userRole === 'admin'
            ? () => {
                onSetSource(integration)
                onIntegrationClick()
              }
            : undefined

        return (
          <Grid item md={6} lg={4} key={integration.id}>
            <IntegrationCard
              disabled={disabled}
              logo={logoBigUrl}
              onClick={onClick}
              integration={integration}
              description={intl.translate({ id: descriptionId })}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
