import { mapAtom } from '../../atoms/map/map'
import { findLayer } from '../utils/findLayer'

import { changelogRemoveOrderFeatures } from './utils/changelogRemoveOrderFeatures'
import { changelogAddOrderFeatures } from './utils/changelogAddOrderFeatures'
import { changelogUpdateOrderFeatures } from './utils/changelogUpdateOrderFeatures'

export const createUpdateOrderFeaturesFromChangelog = (
  markers: uui.domain.ui.map.markers.MapMarkers['order'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['order'],
  selection: Set<string>,
  selectedOrderIds: Set<string>,
  changelog: uui.domain.api.Changelog,
) => {
  // ATTENTION: Currently all changelog are applied as soon as they arrive, also when the map is not visible
  // `ignoreSelection` it will be used to avoid to apply any selection information to the map when applying cached updater functions.
  // after all cached updates will be applied a refresh selection action will be required
  return async (_ignoreSelection: boolean = false) => {
    const map = mapAtom.map
    const layer = findLayer(map, 'order')

    // remove
    await changelogRemoveOrderFeatures(layer, selection, changelog.remove.values())

    // add
    await changelogAddOrderFeatures(
      layer,
      markers,
      mapStyles,
      selection,
      selectedOrderIds,
      changelog.add.values(),
    )

    // update
    await changelogUpdateOrderFeatures(
      layer,
      markers,
      mapStyles,
      selection,
      selectedOrderIds,
      changelog.update.values(),
    )
  }
}
