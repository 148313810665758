import { useSelector } from 'react-redux'
import { parseISO, isEqual, addSeconds, format } from 'date-fns/esm'

import { useMemo } from 'react'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectTerritory } from '@/features/domain/territory'
import { selectStartOfToday } from '@/features/domain/user'
import { selectVehicles } from '@/features/domain/vehicle'
import { getMatchingRecurrenceByDate } from '@/server-data'

export const useIsEnabled = (): 'noVehicles' | 'notToday' | true => {
  const { start, end } = useSelector(selectCalendarRangeInterval)
  const { workingDayStartSec } = useSelector(selectTerritory)
  const startOfToday = useSelector(selectStartOfToday)
  const vehicles = useSelector(selectVehicles)

  return useMemo(() => {
    const isSingleDateSelected = isEqual(start, end)

    if (
      // Is not a range
      !isSingleDateSelected ||
      // Is not today
      !isEqual(addSeconds(start, workingDayStartSec), parseISO(startOfToday))
    )
      return 'notToday'

    const startAsString = format(start, 'yyyyMMdd')

    // Filter out virtual devices and associated devices
    const atLeastOneTrackedVehicle = Object.values(vehicles).some(vehicle => {
      if (vehicle.hasRoutingLicense) {
        // Vehicles associated to a physical device
        if (vehicle.isPhysicalDevice) return true

        if (vehicle.hasRoutingLicense && isSingleDateSelected) {
          const matchingRecurrence = getMatchingRecurrenceByDate(
            startAsString,
            Object.keys(vehicle.driversByRecurrence),
          )

          const driverAndDevice = vehicle.driversByRecurrence[matchingRecurrence]

          // check if associated to an deviceId
          return driverAndDevice?.associated && driverAndDevice?.deviceId
        }
      }

      return false
    })

    if (!atLeastOneTrackedVehicle) return 'noVehicles'

    return true
  }, [startOfToday, workingDayStartSec, start, end, vehicles])
}
