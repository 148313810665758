import type { CSSProperties } from 'react'

import { List } from '@/forms-legacy'

const style: CSSProperties = {
  width: '100%',
}

const listWrapperStyle: CSSProperties = {
  overflow: 'visible',
}

export function ScrollableList<T, E>(props: List.Props<T, string, E>) {
  const finalStyle = { ...(props.style || {}), ...style }
  const finalListWrapperStyle = {
    ...(props.listWrapperStyle || {}),
    ...listWrapperStyle,
  }

  return (
    <List.List<T, string, E>
      {...props}
      style={finalStyle}
      listWrapperStyle={finalListWrapperStyle}
    />
  )
}
