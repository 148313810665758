import type { LoadsListText } from '@/forms-legacy'
import { intl } from '@/intl'

export function loadsRenderTexts(
  field: LoadsListText,
  count: number = 0,
  filterText: string = '',
): string {
  switch (field) {
    case 'addLoad':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.collapsed.addLoad',
      })

    case 'maxCountReached':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.maxCountReached',
      })

    case 'searchPlaceholder':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.search.placeholder',
      })

    case 'loadMore':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.suggestionsList.loadMore',
        values: { count },
      })

    case 'availableLoads':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.suggestionsList.availableLoads',
        values: { count },
      })

    case 'suggestionsBottomMessage':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.suggestionsList.bottomMessage',
      })

    case 'noResult':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.suggestionsList.noResults',
        values: { filter: filterText },
      })

    case 'alreadyUsed':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.suggestionsList.alreadyUsed',
        values: { filter: filterText },
      })

    case 'notValidValue':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.loads.noValidValue',
      })

    case 'notValidMinValue':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.loads.noValidMin',
      })

    case 'removeLoads':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.removeWithCount',
        values: { count },
      })

    case 'addToAll':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.addToAll',
      })

    case 'noLoads':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.noLoads',
      })

    case 'sharedLoad':
      return intl.translate({
        id: 'vehicles.form.edit.generalSettings.loadList.loads.title',
      })

    case 'mixedValue':
      return intl.translate({
        id: 'global.mixed',
      })

    case 'toggleButton':
      const collapsed = !!count
      return intl.translate({
        id: collapsed ? 'global.show' : 'global.hide',
      })

    default:
      return field
  }
}
