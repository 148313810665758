import type { RoadSegmentFormValues } from '../types'

import { FormRenderProps } from 'react-final-form'

import { DrawRoadSegmentField, FormLayout, SectionTitle, TextField } from '@/forms-legacy'
import { WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useTexts } from '../hooks/useTexts'

import { Type } from './fields/Type'
import { AdditionalDrivingTimeSec } from './fields/AdditionalDrivingTimeSec'

interface Props {
  formRenderProps: FormRenderProps<RoadSegmentFormValues>
  outOfTerritory: boolean
}

const warningCardStyle = { marginTop: 16, marginBottom: 16 }

export function Form(props: Props) {
  const {
    formRenderProps: {
      values: { type },
    },
    outOfTerritory,
  } = props

  const texts = useTexts()

  return (
    <FormLayout>
      <SectionTitle>{texts.edit}</SectionTitle>

      <TextField name="name" label={texts.labelName} half />

      <Type />

      <AdditionalDrivingTimeSec disabled={type !== 'delayed'} />

      <DrawRoadSegmentField
        editableRoadSegmentId="roadSegment"
        editableRoadSegmentType={type}
        name="editableSegment"
        label={'Shape'}
      />

      {outOfTerritory && (
        <WarningCard
          preset="alert"
          description={texts.cardDescription}
          title={texts.cardTitle}
          style={warningCardStyle}
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
        />
      )}
    </FormLayout>
  )
}
