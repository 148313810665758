import { useEffect, useState } from 'react'

import { useFormFieldApi } from '@workwave-tidal/tidal/form-fairy'
import { TextField } from '@workwave-tidal/tidal/components'

import { type FormErrors, type FormFields } from '../../../../../formFields'
import { useTexts } from '../../../../../useTexts'

export function Username() {
  const [username, setUsername] = useState<string>('')
  const fieldApi = useFormFieldApi<'credentials', FormFields, FormErrors>('credentials')
  const texts = useTexts()

  useEffect(() => {
    const passwordCredential = fieldApi
      .getField()
      .value.find(credential => credential.fieldName === 'password') ?? {
      fieldName: 'password',
      value: '',
    }

    fieldApi.change([{ fieldName: 'username', value: username }, passwordCredential])
  }, [username, fieldApi])

  return (
    <TextField
      name="username"
      size="medium"
      label={texts.verizonConnect.usernameLabel}
      value={username}
      onChange={e => setUsername(e.target.value)}
    />
  )
}
