import type { ImageLayout } from '../typings'
import styled from 'styled-components'

export interface Props extends workwave.Div {
  layout?: ImageLayout
}

const ReadonlyImageTextContainer = styled.div<Props>`
  display: flex;
  height: 48px;
  flex-direction: ${p => (p.layout === 'detail' ? 'row' : 'column')};
  justify-content: ${p => (p.layout === 'detail' ? 'flex-start' : 'center')};
  align-items: ${p => (p.layout === 'detail' ? 'baseline' : 'flex-start')};
  flex: 1 1 auto;
  padding: 0 10px;
  color: ${p => p.theme.colors.$nightRider};
  overflow: hidden;

  & > * {
    margin: 0;
    margin-top: ${p => (p.layout === 'detail' ? '8px' : '4px')};
    display: block;
    width: ${p => (p.layout === 'detail' ? 'auto' : 'inherit')};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & > * + * {
    margin-left: ${p => (p.layout === 'detail' ? '14px' : '0')};
  }
`

ReadonlyImageTextContainer.displayName = 'ReadonlyImageTextContainer'
export default ReadonlyImageTextContainer
