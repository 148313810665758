import { createGlobalStyle } from 'styled-components'
import { scopedCssBaselineClasses } from '@mui/material'

export const CustomStyles = createGlobalStyle`

    html,
    body {
      user-select: none;
      background: white;
    }

    body {
      overflow: hidden;
    }

    #root {
      position:relative;
      z-index: 10;
    }

    .${scopedCssBaselineClasses.root} {
      background-color: white;
    }

    #popups {
      position: absolute;
      z-index: 20;
      pointer-events: none;
      top: 0;
      left: 0;
    }

    #dev-tools-container {
      position: fixed;
      z-index: 30;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
    }

    .hidden {
      display: none
    }

    a {
      text-decoration: none;
    }

    button {
      cursor: pointer;
    }
  `
