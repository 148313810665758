import type Feature from 'ol/Feature'

import { Geometry, Point } from 'ol/geom'
import { Icon, Style } from 'ol/style'

import { getLocationPinSvgText } from '../../../../../utils/getLocationPinSvgText'
import { genericStyles } from '../../../../genericStyles'
import { getTexts } from '../../../../../controls/registerMapControls/texts'
import { mapAtom } from '../../../../../atoms/map/map'

const mapStylesCache: Map<string, Style> = new Map()

function getLabelToRender(id: uui.domain.ui.map.LocationPin['id']) {
  const texts = getTexts()

  switch (id) {
    case 'orderForm_delivery':
      return texts.delivery
    case 'orderForm_pickup':
      return texts.pickup
    case 'orderForm_service':
      return texts.service
  }
}

export function getLocationPinLabelStyle(id: uui.domain.ui.map.LocationPin['id']) {
  if (id !== 'orderForm_delivery' && id !== 'orderForm_pickup' && id !== 'orderForm_service')
    return genericStyles.hidden

  const cacheId = `locationPin_${id}`

  if (mapStylesCache.has(cacheId)) {
    const cached = mapStylesCache.get(cacheId)

    if (cached) return cached
  }

  const labelToRender = getLabelToRender(id)

  if (!labelToRender) return genericStyles.hidden

  const style = new Style({
    image: new Icon({
      opacity: 1,
      scale: 1,
      src:
        'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(getLocationPinSvgText(labelToRender)),
    }),
    geometry: (feature: Feature<Geometry>) => {
      if (!feature) return

      const geometry = feature.getGeometry()

      if (geometry instanceof Geometry && geometry.getType() === 'Point') {
        const center = geometry as Point
        const centerCoordinate = center.getCoordinates()

        let topPoint = new Point(centerCoordinate)
        const topPixels = mapAtom.map.getPixelFromCoordinate(centerCoordinate)

        const [x, y] = topPixels
        const shiftedTopCoord = mapAtom.map.getCoordinateFromPixel([x, y - 70])
        topPoint = new Point(shiftedTopCoord)

        return topPoint
      }
    },
  })

  mapStylesCache.set(cacheId, style)

  return style
}
