import { parse, isBefore, isAfter, isSameMonth, isSameYear } from 'date-fns/esm'

export function computeReferenceDate(eligibility: uui.domain.client.rm.Eligibility, today: string) {
  switch (eligibility.type) {
    case 'any':
      return today

    case 'by':
      return eligibility.date

    case 'on': {
      const dates = [...eligibility.dates]

      if (dates.length === 1) return dates[0]

      dates.sort(sortDates)

      // if all dates are in the same month take the first one
      if (allDatesInTheSameMonth(dates)) {
        return dates[0]
      }

      // check if any date contains the month of today, open the today month
      if (datesShareSameMonthAsToday(dates, today)) {
        return today
      }

      // pick the smallest date
      return dates[0]
    }
  }
}

const now = new Date()
function sortDates(a: string, b: string) {
  const dateA = parse(a, 'yyyyMMdd', now)
  const dateB = parse(b, 'yyyyMMdd', now)

  if (isBefore(dateA, dateB)) return -1
  if (isAfter(dateA, dateB)) return 1
  return 0
}

function allDatesInTheSameMonth(dates: string[]) {
  if (dates.length < 2) return true
  const [first, ...rest] = dates
  const firstDate = parse(first, 'yyyyMMdd', now)
  return rest.every(d => {
    const date = parse(d, 'yyyyMMdd', now)

    return isSameYear(date, firstDate) && isSameMonth(date, firstDate)
  })
}

function datesShareSameMonthAsToday(dates: string[], today: string) {
  const todayDate = parse(today, 'yyyyMMdd', now)

  return dates.some(d => {
    const date = parse(d, 'yyyyMMdd', now)
    return isSameYear(date, todayDate) && isSameMonth(date, todayDate)
  })
}
