import styled from 'styled-components'

type Props = {
  disabled?: boolean
}

const styledComponent = styled.label<Props>`
  position: relative;
  display: flex;
  align-items: center;
  background: transparent;
  width: 100%;
  color: ${p => p.theme.colors.$nightRider};
  font-size: ${p => p.theme.fonts.$p2};
  font-weight: ${p => p.theme.weights.$semiBold};
  padding-left: 4px;
  cursor: pointer;

  opacity: ${p => (p.disabled ? 0.3 : 1)};
  pointer-events: ${p => (p.disabled ? 'none' : 'unset')};
`

styledComponent.displayName = 'RadioLabel'
export default styledComponent
