import type { ReactElement } from 'react'
import type { Props } from './typings'

import { useState, useCallback, useEffect } from 'react'

import LoadsListWrapper from '../LoadsList/components/LoadsListWrapper'
import { Collapsed } from '../LoadsList/components/Collapsed'
import Search from '../LoadsList/components/Search'
import { SuggestionsList } from '../LoadsList/components/SuggestionsList'

import UiVehicleLoads from './VehicleLoads'
import { updateLoads, deleteLoad } from './utils'

const defaultMax = 20

const VehicleLoadsList = (props: Props): ReactElement => {
  const {
    value,
    maxCount = defaultMax,
    onChangeFilter,
    filter,
    onChange,
    suggestions,
    renderText,
    useMetric,
  } = props

  const [step, onChangeStep] = useState<'collapsed' | 'search'>('collapsed')
  const [filterIsAlreadyUsed, setFilterIsAlreadyUsed] = useState(false)

  useEffect(() => {
    setFilterIsAlreadyUsed(!!Object.keys(value).find(v => v === filter))
  }, [value, filter])

  const hasReachedMax: boolean = Object.keys(value).length >= maxCount

  const handleOnAddLoad = useCallback(
    (loadName: string) => {
      const loadIsAlreadyUsed = !!Object.keys(value).find(v => v === loadName)

      if (!loadIsAlreadyUsed) {
        updateLoads(loadName, { value: 0, min: 0 }, value, onChange)
        onChangeFilter('')
      }
    },
    [onChange, onChangeFilter, value],
  )

  const handleOnUpdateLoad = useCallback(
    (loadName: string, updatedValue: uui.domain.ui.forms.VehicleLoad) => {
      updateLoads(loadName, updatedValue, value, onChange)
    },
    [onChange, value],
  )

  const handleOnDeleteLoad = useCallback(
    (loadName: string) => {
      deleteLoad(loadName, value, onChange)
    },
    [onChange, value],
  )

  return (
    <LoadsListWrapper>
      {(step === 'collapsed' || hasReachedMax) && (
        <Collapsed
          onChangeStep={() => onChangeStep('search')}
          disabled={hasReachedMax}
          buttonText={renderText('addLoad')}
          labelText={renderText('maxCountReached')}
        />
      )}
      {step === 'search' && !hasReachedMax && (
        <>
          <Search
            onChange={onChangeFilter}
            filter={filter}
            onAddLoad={handleOnAddLoad}
            placeholderText={renderText('searchPlaceholder')}
          />
          <SuggestionsList
            list={suggestions}
            onAddLoad={handleOnAddLoad}
            renderText={renderText}
            filter={filter}
            filterIsAlreadyUsed={filterIsAlreadyUsed}
          />
        </>
      )}
      <UiVehicleLoads
        loads={value}
        onChangeLoad={handleOnUpdateLoad}
        onDeleteLoad={handleOnDeleteLoad}
        renderText={renderText}
        useMetric={useMetric}
      />
    </LoadsListWrapper>
  )
}

export default VehicleLoadsList
