import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'
import type { MainSelection } from '@/atoms'
import type { MapSelectionMode } from '../../../atoms/map/map'

import OlMap from 'ol/Map'

import { setAllMainSelection } from '@/atoms'

import { selectVehicleFeatureInExtent } from './utils/selectVehicleFeatureInExtent'
import { selectDeviceFeatureInExtent } from './utils/selectDeviceFeatureInExtent'
import { selectDepotFeatureInExtent } from './utils/selectDepotFeatureInExtent'
import { selectOrderFeatureInExtent } from './utils/selectOrderFeatureInExtent'
import { selectPlaceFeatureInExtent } from './utils/selectPlaceFeatureInExtent'

export const createLassoSelection = async (
  map: OlMap,
  lassoFeature: Feature<Geometry>,
  mode: MapSelectionMode,
) => {
  const lassoPolygon = lassoFeature.getGeometry()
  const lassoExtent = lassoPolygon?.getExtent()

  if (lassoPolygon && lassoExtent) {
    const selection: MainSelection = {
      breadcrumbs: [],
      devices: [],
      depots: [],
      drivers: [],
      orderSteps: [],
      places: [],
      routes: [],
      unifiedVehicles: [],
    }

    selection.unifiedVehicles = await selectVehicleFeatureInExtent(map, lassoPolygon, lassoExtent)
    selection.orderSteps = await selectOrderFeatureInExtent(map, lassoPolygon, lassoExtent)
    selection.devices = await selectDeviceFeatureInExtent(map, lassoPolygon, lassoExtent)
    selection.depots = await selectDepotFeatureInExtent(map, lassoPolygon, lassoExtent)
    selection.places = await selectPlaceFeatureInExtent(map, lassoPolygon, lassoExtent)

    switch (mode) {
      case 'lasso':
        setAllMainSelection(selection, { force: true })
        break

      case 'lassoAdditive':
        setAllMainSelection(selection, { modifiers: { metaKey: true } })
        break

      case 'lassoSubtractive':
        setAllMainSelection(selection, { modifiers: { altKey: true } })
        break
    }
  }
}
