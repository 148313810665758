import { Icon, Style } from 'ol/style'

import deviceEventsSpriteSheet from '@/resources/map-markers/device-events.png'
import { genericStyles } from '../../../genericStyles'
import { isPointOutOfTimeRange } from '../isPointOutOfTimeRange'

const deviceEventMarkerStylesCache: Map<string, Style[]> = new Map()

/**
 * Return the right style to be used by a DeviceEvent  feature
 */
export function getDeviceEventPointsFeatureStyle(
  event: uui.domain.ui.map.markers.DeviceEvent,
  selected: boolean,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  if (!selected || isPointOutOfTimeRange(event, breadcrumbTimeRange)) return genericStyles.hidden

  const coordinates: uui.domain.ui.map.markers.GridPlacement = event.grid

  const x = coordinates[0]
  const y = coordinates[1]
  const cellSize = coordinates[2]

  const cacheId = `${x}_${y}`

  if (deviceEventMarkerStylesCache.has(cacheId)) {
    const cachedStyle = deviceEventMarkerStylesCache.get(cacheId)
    if (cachedStyle) return cachedStyle
  }

  const styles = [
    // Full stroke
    new Style({
      image: new Icon({
        src: deviceEventsSpriteSheet,
        crossOrigin: 'anonymous',

        scale: 0.5,
        opacity: 1.0,
        rotation: 0.0,
        rotateWithView: true,

        size: [cellSize, cellSize],
        offset: [x, y],

        anchor: [0.5, 1],
        anchorOrigin: 'top-left',
        anchorYUnits: 'fraction',
        anchorXUnits: 'fraction',
      }),
    }),
  ]

  deviceEventMarkerStylesCache.set(cacheId, styles)

  return styles
}
