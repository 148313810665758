import { type ResourceModel } from '@bryntum/schedulerpro'
import { type CSSProperties } from 'react'
import { conversionUtils } from '@/server-data'
import { useTexts } from './useTexts'

interface Props {
  language: uui.domain.Language
  record: ResourceModel
  loadName: string
}

const textStyle: CSSProperties = {
  letterSpacing: '0.00714em',
  marginBottom: '0.35rem',
  fontWeight: 500,
  fontSize: '0.875rem',
}

export function DynamicLoadTooltip(props: Props) {
  const { record, language, loadName } = props

  const formatNumber = Intl.NumberFormat(conversionUtils.intlFormat[language]).format
  const texts = useTexts()

  const loads = record.getData('loads') as uui.domain.client.rm.SchedulerResource['loads']
  const loadData = loads[loadName]

  if (!loadData?.current) return <div style={textStyle}>{texts.noLoad}</div>

  const content = isNaN(loadData.current.percentage)
    ? texts.loadNotDefinedOnVehicle
    : `${formatNumber(loadData.current.absolute)}/${formatNumber(
        loadData.current.capacity,
      )} (${formatNumber(loadData.current.percentage)}%)`

  return <div style={textStyle}>{content}</div>
}
