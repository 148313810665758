import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { renderToStaticMarkup } from 'react-dom/server'

import { intl } from '@/intl'

import { LiveEtaCell } from '../../../components/cells/LiveEtaCell'

export function createLiveEtaColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    text: intl.translate({ id: 'rm.scheduler.column.liveEta' }),
    type: 'template',
    field: 'liveEta',
    width,
    hidden,
    editor: false,
    region: 'cols',
    template: ({ record, field, value }) =>
      renderToStaticMarkup(
        <LiveEtaCell record={record as ResourceModel} field={field} value={value} />,
      ),
    resizable: true,
    groupable: false,
  }
}
