import type { RouteDetailEntryEnd } from '../../../../../types'

import { useSelector } from 'react-redux'
import { Grid, Stack, Typography, Skeleton } from '@mui/material'
import { HistoryToggleOff, AccessTime } from '@mui/icons-material'

import { selectUserConfiguration } from '@/features/domain/user'
import { selectTerritory } from '@/features/domain/territory'
import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'
import { RouteEnd } from '@/icons'
import { Tooltip } from '@/components/primitives/Tooltip'
import { theme } from '@/styles'

import { useComputedLocation } from '../hooks/useComputedLocation'
import { useEventTimings } from '../hooks/useEventTimings'
import { useTexts } from '../hooks/useTexts'

interface Props {
  entry: RouteDetailEntryEnd
  routeColor: string
}

export function EndEvent(props: Props) {
  const { entry, routeColor } = props
  const planned = entry.type === 'routeEnd' ? entry.data.planned : undefined
  const tracked = entry.type === 'routeEnd' ? entry.data.tracked : entry.data
  const endsAfterWorkingDayEnd =
    entry.type === 'routeEnd' ? entry.data.endsAfterWorkingDayEnd : false

  const { plannedTime, trackedTime, delta, inLate } = useEventTimings({
    planned: planned?.sec,
    tracked: tracked.sec,
  })

  const { mobileAppsTrackingType } = useSelector(selectTerritory)
  const computedLocation = useComputedLocation(tracked.location ?? planned?.location ?? undefined)
  const { userType } = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const plannedTooltipTitle = texts.routeEndPlannedTooltip
  const trackedTooltipTitle =
    trackedTime === '-'
      ? userType === 'rmOnly'
        ? texts.routeEndWontBeCollectedRMOnlyTooltip
        : mobileAppsTrackingType === 'ONLY_ORDERSTEPS'
        ? texts.routeEndWontBeCollectedTooltip
        : texts.routeEndNotCollectedTooltip
      : texts.routeEndCollectedTooltip

  const trackedTimeColor = endsAfterWorkingDayEnd ? theme.colors.$outrageousRed : undefined

  const iconColor =
    mobileAppsTrackingType === 'ONLY_ORDERSTEPS'
      ? theme.colors.$pureBlack
      : trackedTime !== '-'
      ? theme.colors.$shadyLady
      : routeColor

  const deltaColor = delta > 0 ? '#EC6040' : delta < 0 ? '#399C4F' : 'text.secondary'
  const title = entry.type === 'routeEnd' ? texts.routeEnd : texts.driverEnd

  return (
    <Grid
      maxWidth="100%"
      container
      spacing={1}
      data-testid={entry.type === 'routeEnd' ? 'routeEndEvent' : 'routeDriverEndEvent'}
    >
      <Grid item xs={2} sx={{ color: iconColor }}>
        <RouteEnd size={32} />
      </Grid>

      <Grid item xs={10}>
        <Stack>
          <Typography variant="h6" data-testid="eventTitle">
            {title}
          </Typography>

          {computedLocation ? (
            <Typography
              data-testid="eventLocation"
              variant="caption"
              color={computedLocation === '-' ? 'grey.600' : 'text.secondary'}
            >
              {computedLocation}
            </Typography>
          ) : (
            <Skeleton width={100} height={20} animation="wave" data-testid="eventLocationLoader" />
          )}

          <Stack spacing={1} alignItems="flex-start" paddingTop={1}>
            {entry.type === 'routeEnd' && (
              <Tooltip placement="top" title={plannedTooltipTitle}>
                <Stack direction="row" spacing={1}>
                  <HistoryToggleOff
                    fontSize="small"
                    color={plannedTime === '-' ? 'disabled' : 'inherit'}
                  />
                  <Typography
                    fontSize={13}
                    color={plannedTime === '-' ? 'grey.600' : 'text'}
                    data-testid="eventPlannedTime"
                  >
                    {plannedTime}
                  </Typography>
                </Stack>
              </Tooltip>
            )}

            <Tooltip placement="top" title={trackedTooltipTitle}>
              <Stack direction="row" spacing={1} alignItems="flex-end">
                <AccessTime fontSize="small" color={trackedTime === '-' ? 'disabled' : 'inherit'} />
                <Typography
                  color={
                    trackedTime === '-' ? 'grey.600' : trackedTimeColor ? trackedTimeColor : 'text'
                  }
                  fontSize={13}
                  data-testid="eventTrackedTime"
                >
                  {trackedTime}
                </Typography>
                {delta !== 0 ? (
                  <Typography color={deltaColor} fontSize={12} data-testid="trackedTimeDifference">
                    {inLate ? '+' : '-'}
                    <FormatSeconds seconds={delta} format="MINUTES_ONLY" />
                  </Typography>
                ) : null}
              </Stack>
            </Tooltip>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}
