import { useSelector } from 'react-redux'
import { selectSimulationsListStructure } from '@/features/domain/lists'
import { useEditingStateWithCtx } from '@/atoms'
import { useSearchList } from './useSearchList'

export function useFilter() {
  const { editing } = useEditingStateWithCtx('simulation')
  const { query, setQuery } = useSearchList()
  const listStructure = useSelector(selectSimulationsListStructure)

  return {
    query,
    editing,
    setQuery,
    noEntries: listStructure.totalItems === 0,
  }
}
