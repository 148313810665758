type Direction = 'asc' | 'desc'

export function naturalSort(s1: string, s2: string, direction: Direction = 'asc'): number {
  const directionModifier: number = direction === 'asc' ? 1 : -1

  if (s1.length === 0 || s2.length === 0) {
    return (s1.length - s2.length) * directionModifier
  }

  let indexA: number = 0
  let indexB: number = 0
  let numberA: number
  let numberB: number
  let charA: string = ''
  let charB: string = ''
  let difference: number

  do {
    numberA = 0
    while (indexA < s1.length) {
      charA = s1[indexA]
      indexA++
      if (!(charA >= '0' && charA <= '9')) break

      numberA = numberA * 10 + parseInt(charA)
    }

    numberB = 0
    while (indexB < s2.length) {
      charB = s2[indexB]
      indexB++
      if (!(charB >= '0' && charB <= '9')) break

      numberB = numberB * 10 + parseInt(charB)
    }

    if (charA === ' ' && charB === ' ') {
      while (indexA < s1.length && s1[indexA] === ' ') {
        indexA++
      }
      while (indexB < s2.length && s2[indexB] === ' ') {
        indexB++
      }
    }

    difference = (numberA - numberB) * directionModifier
    if (difference !== 0) return difference
    if (charA !== charB) {
      if (charA === '.') return -1 * directionModifier
      if (charB === '.') return 1 * directionModifier
      return (
        (charA.toUpperCase().charCodeAt(0) - charB.toUpperCase().charCodeAt(0)) * directionModifier
      )
    }
  } while (indexA < s1.length && indexB < s2.length)

  return (indexB - s2.length - (indexA - s1.length)) * directionModifier
}
