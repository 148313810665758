import type { ReactNode } from 'react'

import { useState, useEffect } from 'react'

import { VerticalLayout, Grow } from '@/components/layout'

import { useToggleSidebar } from '@/hooks'

import { ToggleSidebarButton, HoverSensor } from './components/ToggleSidebarButton'

const sidebarWidth = 320
const collapsedSidebarWidth = 18

interface Props {
  children?: ReactNode
}

export function Sidebar(props: Props) {
  const { isSidebarOpen, toggleSidebar } = useToggleSidebar()

  const [width, setWidth] = useState(sidebarWidth)

  useEffect(() => {
    setWidth(isSidebarOpen ? sidebarWidth : collapsedSidebarWidth)
  }, [isSidebarOpen])

  return (
    <Grow yAxis>
      <VerticalLayout width={width} background="$alabaster">
        <HoverSensor collapsed={!isSidebarOpen}>
          {isSidebarOpen && props.children}
          <ToggleSidebarButton toggle={toggleSidebar} collapsed={!isSidebarOpen} />
        </HoverSensor>
      </VerticalLayout>
    </Grow>
  )
}
