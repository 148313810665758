import type { FormFields, FormErrors } from '../../../../../hooks/formFields'
import type { FormValidator } from '@workwave-tidal/tidal/form-fairy'

import { useCallback } from 'react'
import { useTexts } from '../useTexts'

export function usePhoneNumberRequired(): FormValidator<FormFields, FormErrors> {
  const texts = useTexts()

  return useCallback(
    formApi => {
      // access the current name form-field
      const field = formApi.getField('phoneNumber')

      return {
        phoneNumberRequiredError:
          field.required && field.value.trim().length === 0
            ? {
                id: 'phoneNumberRequiredError',
                message: texts.phoneRequired,
                field: 'phoneNumber',
                priority: 10,
              }
            : null,
      }
    },
    [texts],
  )
}
