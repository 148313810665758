import { PureComponent } from 'react'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyList,
  ReadonlyFieldArrow,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  SecondaryColumnContentHorizontalRuler,
} from '@/forms-legacy'
import * as Texts from '../intl'

import { Props } from '../typings'
import ViolationIcon from './ViolationIcon'

export default class BannedTags extends PureComponent<Props> {
  render() {
    const { tagsOut = [], violations } = this.props

    const bannedTagsViolation = violations.includes('TGO')

    return (
      <>
        <ReadonlyBlock>
          <ReadonlyLabel primary empty={tagsOut.length === 0}>
            {Texts.getBannedTagsText()}
            {bannedTagsViolation && <ViolationIcon />}
          </ReadonlyLabel>

          {tagsOut.length > 0 && (
            <ReadonlyList>
              {tagsOut.map(key => (
                <ReadonlyFieldArrow key={key}>
                  <ReadonlyFieldRow>
                    <ReadonlyFieldLabel>{key}</ReadonlyFieldLabel>
                  </ReadonlyFieldRow>
                </ReadonlyFieldArrow>
              ))}
            </ReadonlyList>
          )}
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
