import { Outlet } from 'react-router'

import { HorizontalLayout } from '@/components/layout'

export function Routing() {
  return (
    <HorizontalLayout>
      <Outlet />
    </HorizontalLayout>
  )
}
