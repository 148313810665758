import type { BulkLoads, BulkLoadPartial, BulkLoadExact } from '../../../../types'

import { useMemo } from 'react'

interface SplittedLoads {
  exact: BulkLoadExact[]
  partial: BulkLoadPartial[]
}

export function useSplitAndSortLoadsByType(bulkLoads: BulkLoads) {
  return useMemo(() => {
    const splitted = Object.values(bulkLoads.loads).reduce<SplittedLoads>(
      (acc, curr) => {
        switch (curr.status) {
          case 'exact':
            acc.exact.push(curr)
            break
          case 'partial':
            acc.partial.push(curr)
            break
        }

        return acc
      },
      { exact: [], partial: [] },
    )

    splitted.exact.sort(sortExact)
    splitted.partial.sort(sortPartial)

    return splitted
  }, [bulkLoads])
}

function sortExact(a: BulkLoadExact, b: BulkLoadExact) {
  if (a.addedTimestamp === -1 && b.addedTimestamp !== -1) {
    return -1
  }
  if (a.addedTimestamp !== -1 && b.addedTimestamp === -1) {
    return 1
  }
  if (a.addedTimestamp === -1 && b.addedTimestamp === -1) {
    return a.name.localeCompare(b.name)
  }

  // sort by timestamp descending
  return -(a.addedTimestamp - b.addedTimestamp)
}

function sortPartial(a: BulkLoadPartial, b: BulkLoadPartial) {
  const countDiff = a.value - b.value
  if (countDiff !== 0) return -countDiff

  return a.name.localeCompare(b.name)
}
