import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useEditingStateWithCtx, useCrudSelection, resetCrudSelection, useIsLoading } from '@/atoms'

import { selectDevicesListStructure } from '@/features/domain/lists'

export const useDataForList = () => {
  const { setEditing } = useEditingStateWithCtx('device')
  const structure = useSelector(selectDevicesListStructure)
  const [selection, setSelection] = useCrudSelection('devices')
  const isLoading = useIsLoading('fetchGpsData')

  const lastSelectedDevice = selection.length > 0 ? selection[selection.length - 1] : undefined

  const setCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  const deselectAll = () => resetCrudSelection('devices')

  return {
    structure,
    isLoading,
    setCreate,
    setEditing,
    lastSelectedDevice,
    setSelection,
    deselectAll,
  }
}
