import { addMilliseconds, addSeconds, parseISO } from 'date-fns/esm'
import { type TimeSpanConfig } from '@bryntum/schedulerpro'

import { selectStartOfToday, selectUserConfiguration } from '@/features/domain/user'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectApplicationClock } from '@/features/domain/applicationClock'
import { selectDayRangeInfo } from '@/features/domain/scheduler'
import { timeUtils } from '@/server-data'
import { store } from '@/store'

import { getProject } from '../project'

export function computeTimeRanges(): Partial<TimeSpanConfig>[] {
  const state = store.getState()

  const applicationClock = selectApplicationClock(state)
  const startOfToday = selectStartOfToday(state)
  const userConfig = selectUserConfiguration(state)
  const { start } = selectCalendarRangeInterval(state)
  const dayRange = selectDayRangeInfo(state)

  const millisecondsSinceMidnight = applicationClock - parseISO(startOfToday).getTime()

  const clock = addSeconds(
    addMilliseconds(start, millisecondsSinceMidnight),
    dayRange.dayStartOffset,
  )

  const name = timeUtils.formatTime(userConfig)(clock)

  return [
    {
      id: 1,
      name,
      startDate: clock,
    },
  ]
}

export function syncProjectTimeRanges() {
  const project = getProject()

  project.timeRanges = computeTimeRanges()
}
