import { useAutoScroll, useIsLoading } from '@/atoms'
import { ListFooter, LayoutComposer, NoEntries, NoResults } from '@/components/List'
import { StructuredVirtualList } from '@/components/StructuredVirtualList'
import { Grow, FormColumn, ComponentSize } from '@/components/layout'
import { ListLoading } from '@/components/ListLoading'

import { Item } from './Item'

import { useListHandlers } from './hooks/useListHandlers'
import { useDataForList } from './hooks/useDataForList'
import { useVehicles } from './hooks/useVehicles'
import { useTexts } from './hooks/useTexts'
import { Header } from './Header'

const getItemHeight = () => 79
const getItemId = (item: uui.domain.client.UnifiedVehicle) => item.unifiedId

export function TrackingList() {
  const { handleOnSelect, handleOnSelectAll } = useListHandlers()
  const { structure, lastSelectedVehicle, selection } = useDataForList()
  const texts = useTexts()
  const vehicles = useVehicles()

  const isLoading = useIsLoading('fetchGpsData')

  useAutoScroll('tracking', lastSelectedVehicle)

  const allSelected = selection.length >= structure.shownItems
  const multiSelect = selection.length > 1

  return (
    <>
      <FormColumn width={320} testid="form__column-primary">
        <Header />

        <Grow yAxis xAxis position="relative">
          <LayoutComposer
            totalItems={structure.totalItems}
            shownItems={structure.shownItems}
            noResults={
              <NoResults title={texts.noResults.title} description={texts.noResults.description} />
            }
            noEntries={
              <NoEntries title={texts.noEntries.title} description={texts.noEntries.description} />
            }
            list={
              <ComponentSize
                render={size => {
                  // TODO: reset the scroll when the list options change
                  return isLoading ? (
                    <ListLoading />
                  ) : (
                    <StructuredVirtualList
                      itemsById={vehicles}
                      structure={structure}
                      listApiCategory="tracking"
                      getItemHeights={getItemHeight}
                      RenderItem={Item}
                      listHeight={size.height}
                      getItemId={getItemId}
                      selectedItemIds={selection}
                      onSelect={handleOnSelect}
                      buffer={50}
                    />
                  )
                }}
              />
            }
          />
        </Grow>

        {multiSelect && (
          <ListFooter
            label={texts.selectBtn(allSelected)}
            onToggleSelection={handleOnSelectAll}
            checked={allSelected}
          />
        )}
      </FormColumn>
    </>
  )
}
