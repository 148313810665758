import {
  ChangeEvent,
  Component,
  MouseEvent,
  SyntheticEvent,
  ReactNode,
  FormEvent,
  FocusEvent,
} from 'react'

import { clsx } from '@/utils'
import { Up, Down, Cross } from '@/icons'

import {
  BaseInput,
  BaseInputWrapper,
  StepButton,
  StepButtonWrapper,
  ButtonReset,
} from '../Internals'

import { snapToStep } from './utils'

export type Props = {
  name: string
  placeholder?: string
  className?: string
  size?: number
  readonly?: boolean
  step: number
  value: number
  onChange: (value: any, e?: SyntheticEvent<HTMLElement>) => void
  min?: number
  max?: number
  useSnap?: boolean
  canBeNaN?: boolean
  allowDigits?: boolean
  unit?: ReactNode
  showResetIcon?: boolean
  defaultValue?: number
  onFocus?: (event: FormEvent<HTMLElement>) => void
  onBlur?: (event: FormEvent<HTMLElement>, value: number) => void
}

export interface State {
  value: number
}

const parseValue = (value: string, allowDigits: boolean, defaultValue: number): number => {
  const parseFunction = allowDigits ? parseFloat : parseInt
  const parsedValue: number = parseFunction(value)
  if (isNaN(parsedValue)) {
    return defaultValue
  }
  if (allowDigits) {
    return parseFloat(parsedValue.toFixed(2))
  }
  return parsedValue
}

export default class InputStep extends Component<Props> {
  static defaultProps: Partial<Props> = {
    useSnap: true,
  }

  readonly state: State = {
    value: 0,
  }

  componentDidMount() {
    const { value } = this.props
    this.updateValue(value)
  }

  componentDidUpdate(prevProps: Props) {
    const { value: prevValue } = prevProps
    const { value } = this.props

    if (!isNaN(value) && value !== prevValue) {
      this.updateValue(value)
    }
  }

  private updateValue(value: number) {
    // Workaround for:
    // https://github.com/facebook/react/issues/9402
    // borrowed from:
    // https://github.com/hyperdexapp/hyperdex/pull/226
    // We force React to update
    this.setState({ value: 0 }, () => {
      this.setState({ value })
    })
  }

  private handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
    const { onFocus } = this.props
    if (onFocus) {
      onFocus(e)
    }
  }

  private handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
    const { onBlur, value, onChange, min, max } = this.props

    if (onBlur) onBlur(e, value)

    let result = value

    if (typeof min === 'number') {
      result = Math.max(min, result)
    }

    if (typeof max === 'number') {
      result = Math.min(max, result)
    }
    onChange(result, e)
  }

  private handleIncrement = (e: MouseEvent<HTMLElement>) => {
    const { onChange, value, step, max, useSnap } = this.props
    e.preventDefault()
    if (isNaN(value)) {
      return onChange(0, e)
    }
    let result: number = snapToStep(value, step, true, useSnap)
    if (max !== undefined && result > max) {
      result = max
    }
    onChange(result, e)
  }

  private handleDecrement = (e: MouseEvent<HTMLElement>) => {
    const { onChange, value, step, min, useSnap, canBeNaN } = this.props
    e.preventDefault()
    if (isNaN(value)) {
      return onChange(0, e)
    }

    if (value === min && canBeNaN) {
      return onChange(null, e)
    }

    let result: number = snapToStep(value, step, false, useSnap)
    if (min !== undefined && result < min) {
      result = min
    }
    onChange(result, e)
  }

  private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange, canBeNaN = false, allowDigits = false, min, max } = this.props
    const {
      target: { value },
    } = e
    const defaultValue = canBeNaN ? NaN : 0
    let result: number = parseValue(value, allowDigits, defaultValue)

    if (typeof min === 'number') {
      result = Math.max(min, result)
    }

    if (typeof max === 'number') {
      result = Math.min(max, result)
    }

    onChange(result, e)
  }

  private handleResetField = (e: MouseEvent<HTMLElement>): void => {
    const { onChange, defaultValue = 0 } = this.props
    e.preventDefault()
    onChange(defaultValue, e)
  }

  private renderResetButton = (): ReactNode => {
    const { showResetIcon, defaultValue, readonly, name } = this.props

    if (!showResetIcon) return null
    if (showResetIcon && typeof defaultValue !== 'number') {
      throw new Error(`InputStep requires a defaultValue to enable the reset button`)
    }

    return (
      <ButtonReset type="button" key={name} onClick={this.handleResetField} disabled={readonly}>
        <Cross />
      </ButtonReset>
    )
  }

  render() {
    const {
      name,
      unit,
      className = '',
      min = Number.MIN_SAFE_INTEGER,
      max = Number.MAX_SAFE_INTEGER,
      readonly = false,
      placeholder = '',
      canBeNaN = false,
    } = this.props
    const { value } = this.state

    const rootClassName = clsx({
      [className]: true,
      'o-input-step': true,
      'is-readonly': readonly,
    })

    return (
      <BaseInputWrapper className={rootClassName}>
        <BaseInput
          type="number"
          name={name}
          value={canBeNaN && isNaN(value) ? '' : value}
          onChange={this.handleChange}
          placeholder={placeholder}
          readOnly={readonly}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          data-testid="input-text"
        />
        {this.renderResetButton()}
        {!!unit && unit}
        <StepButtonWrapper>
          <StepButton
            onClick={this.handleIncrement}
            top
            disabled={value >= max || readonly}
            testid="input-step-up"
          >
            <Up />
          </StepButton>
          <StepButton
            onClick={this.handleDecrement}
            bottom
            disabled={(value <= min && !canBeNaN) || readonly || (canBeNaN && isNaN(value))}
            testid="input-step-down"
          >
            <Down />
          </StepButton>
        </StepButtonWrapper>
      </BaseInputWrapper>
    )
  }
}
