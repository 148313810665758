import { proxy, useSnapshot } from 'valtio'

type BaseNavigoCategory =
  | 'breadcrumbs'
  | 'depots'
  | 'devices'
  | 'drivers'
  | 'places'
  | 'routes'
  | 'unifiedVehicles'
  | 'multiCategory'

export type NavigoCategory = BaseNavigoCategory | 'orderSteps'

type BaseNavigoView = 'overview' | 'mapStyle'

type NavigoView =
  | { category: 'none' }
  | {
      category: BaseNavigoCategory
      view: BaseNavigoView
    }
  | {
      category: 'orderSteps'
      view: BaseNavigoView | 'executionDetails'
    }

type NavigoViewsAtom = { navigoView: NavigoView }

// ------------------------------------
// Default values
// ------------------------------------

function createInitialNavigoView(): NavigoView {
  return { category: 'none' }
}

// ------------------------------------
// Navigo view atom
// ------------------------------------

export const navigoViewAtom = proxy<NavigoViewsAtom>({
  navigoView: createInitialNavigoView(),
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetNavigoView = (prev: NavigoView) => NavigoView
type SetNavigoViewParam = SetNavigoView | Partial<NavigoView> | 'reset'

export function resetNavigoView() {
  navigoViewAtom.navigoView = createInitialNavigoView()
}

export function setNavigoView(valueOrFunc: SetNavigoViewParam) {
  if (valueOrFunc === 'reset') return resetNavigoView()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(navigoViewAtom.navigoView, valueOrFunc(navigoViewAtom.navigoView))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      navigoViewAtom.navigoView[field] = valueOrFunc[field]
    }
  }

  return navigoViewAtom
}

// ------------------------------------
// React Hooks
// ------------------------------------

export function useNavigoView(category: NavigoCategory | 'orderSteps') {
  if (navigoViewAtom.navigoView.category !== category) {
    setNavigoView({ category, view: 'overview' })
  }

  return [useSnapshot(navigoViewAtom).navigoView, setNavigoView] as const
}
