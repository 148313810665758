import { NavigoContainer } from '../../elements/NavigoContainer'
import { CloseButton } from '../../elements/CloseButton'
import { DriversNavigator } from './components/DriversNavigator'
import { Overview } from './views/Overview'

import { useDriversNavigoMachine } from './hooks/useDriversNavigoMachine'

export function Drivers() {
  const { data, actions } = useDriversNavigoMachine()

  return (
    <NavigoContainer data-testid="navigo-drivers" data-trackid="navigo-drivers">
      <DriversNavigator />
      <Overview
        expanded={data.expanded}
        permissions={data.permissions}
        toggleExpanded={actions.toggleExpanded}
      />
      <CloseButton />
    </NavigoContainer>
  )
}
