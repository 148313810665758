import type { TomorrowNotificationValues } from './hooks/useTomorrowNotificationValues'

export function convertValues(formValues: TomorrowNotificationValues) {
  // Convert windowOfCommitmentToleranceMins from string to number
  const windowOfCommitmentToleranceMins = parseInt(formValues.windowOfCommitmentToleranceMins)

  // convert it back to minutes
  const notificationCutoffMins = formValues.notificationCutoffMins

  return {
    ...formValues,
    notificationCutoffMins,
    windowOfCommitmentToleranceMins,
  }
}
