import styled from 'styled-components'

import IconContainerBase from '../IconContainer'

import { UiStyle } from './typings'

type Props = {
  disabled?: boolean
  uiStyle?: UiStyle
}

const IconContainer = styled(IconContainerBase)<Props>`
  > * {
    color: ${p =>
      p.disabled
        ? p.theme.colors.$whiteSmoke
        : p.uiStyle === 'default'
        ? p.theme.colors.$pigmentGreen
        : p.theme.colors.$outrageousRed};
  }
`
IconContainer.displayName = 'IconContainer'
export default IconContainer
