import { ListActionButton } from '@/forms-legacy'
import { AddRounded } from '@/icons'

import * as Texts from '../intl'

import SmallLabel from './SmallLabel'

interface Props {
  onChangeStep: () => void
  disabled?: boolean
}

export function Collapsed(props: Props) {
  const { onChangeStep, disabled } = props

  return (
    <>
      <ListActionButton
        width={165}
        disabled={disabled}
        icon={<AddRounded />}
        onClick={onChangeStep}
        testid="custom-field__add-btn"
      >
        {Texts.getAddCustomFieldText()}
      </ListActionButton>
      {disabled && <SmallLabel>{Texts.getMaxCountReachedText()}</SmallLabel>}
    </>
  )
}
