import styled from 'styled-components'

type Props = {
  selected?: boolean
}

const styledComponent = styled.span<Props>`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: -1px;
    right: -1px;
    height: 1px;
    background: ${p => p.theme.colors.$silver};
    opacity: ${p => (p.selected ? 1 : 0)};
    transition: 0.3s ease;
    transition-property: background;
  }
`
styledComponent.displayName = 'RadioLabelText'
export default styledComponent
