import { Stack, styled } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { Text } from '@/local/components'
import { Flag } from '@/icons'

import { useTexts } from '../useTexts'
interface Props {
  collapsed?: boolean
  noTooltip?: boolean
  onClick?: () => void
  violations: uui.domain.rm.RouteStepViolationsType[]
}

const ViolationButton = styled('button', { name: 'ViolationButton' })(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  padding: '2px 6px',
  cursor: 'pointer',
  borderRadius: 4,
  borderWidth: 0,
  marginRight: 6,
  outline: 0,
}))

export function ViolationTag(props: Props) {
  const texts = useTexts()
  const { violations, onClick, noTooltip = false, collapsed = true } = props

  const violationsTexts = violations.map(violationCode => texts.violation(violationCode))
  const noViolations = violationsTexts.length === 0

  return (
    <Tooltip
      disabled={noTooltip || noViolations}
      placement="top"
      textAlign="left"
      title={violationsTexts}
    >
      <ViolationButton onClick={onClick}>
        <Stack direction="row" alignItems="center" height={18}>
          <Flag color="$nightRider" size={15} marginRight={4} />

          {collapsed ? (
            <Text size="$p3" color="$nightRider" weight="$bold">
              {violations.length}
            </Text>
          ) : (
            <>
              <Text size="$p3" color="$nightRider" weight="$bold">
                {`${texts.summaryViolations(violations.length)} •`}
              </Text>
              &nbsp;
              <Text size="$p3" color="$nightRider">
                {texts.summaryConstraints([...new Set(violations)].length)}
              </Text>
            </>
          )}
        </Stack>
      </ViolationButton>
    </Tooltip>
  )
}
