import type { Props } from './typings'

import { FlexBox, Text } from '@/local/components'

import { useSkin } from './hooks/useSkin'

export function Alert(props: Props) {
  const { background, color } = useSkin(props)

  const { Icon, text, w = '100%', style = {}, className = '' } = props

  return (
    <FlexBox
      w={w}
      color={color}
      className={className}
      padding="14px 22px"
      style={{
        background,
        borderRadius: 3,
        ...style,
      }}
    >
      {!!Icon && (
        <FlexBox column shrink={0} marginRight={18} marginTop={3}>
          {Icon}
        </FlexBox>
      )}

      <FlexBox column grow={1}>
        <Text size="$p2">{text}</Text>
      </FlexBox>
    </FlexBox>
  )
}
