import { Entry } from '../Entry'

export function Entry20210226() {
  return (
    <Entry title="02/26/2021 - v3.0.0-alpha-6">
      <ul>
        <li>The MapControls are available on every map</li>
        <li>SMS Phase 3 views are available (not connected to the back-end yet)</li>
        <li>The Depots form is available (not connected to the back-end yet)</li>
        <li>The Map does not freeze the UI anymore</li>
        <li>The app does not freeze on large selections anymore</li>
        <li>
          Please note: selecting all the items on the map or in the list do not work properly yet
        </li>
      </ul>
    </Entry>
  )
}
