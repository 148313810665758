import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    start: translate({ id: 'global.start' }),
    end: translate({ id: 'global.end' }),
  }))

  return api
}
