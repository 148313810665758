import { type SchedulerProConfig } from '@bryntum/schedulerpro'

import { setMainSelection, setPagination } from '@/atoms'
import { ControlledNavigateFunction } from '@/routing'
import { selectOrderSteps } from '@/features/domain/order'
import { store } from '@/store'

export function createOnEventDblClick(
  navigate: ControlledNavigateFunction<uui.routing.Routing | uui.routing.Setup>,
): SchedulerProConfig['onEventDblClick'] {
  return clickEvent => {
    const { eventRecord } = clickEvent
    const steps = eventRecord.getData('steps') as uui.domain.client.rm.ExtendedOrderStep[]
    const type = eventRecord.getData('type') as uui.domain.client.rm.SchedulerEvent['type']

    if (type === 'idle-time' || type === 'brk') return

    const ids = steps.map(step => step.id)
    const extOrderStep = selectOrderSteps(store.getState())[ids[0]]

    if (!extOrderStep) {
      throw new Error(`Cannot find order step with id: ${ids[0]}`)
    }

    // Set the new main selection
    setMainSelection('orderSteps', ids)

    setPagination(draft => {
      draft.orderTab.current = 0
      return draft
    })

    // Navigate to the correct route
    navigate('orders')
  }
}
