import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()

  const { close } = useController()
  const { onSubmit } = useControllerActions()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          onClick={onSubmit}
          testid="modal__button-delete"
          variant="text"
          color="error"
        >
          {texts.footer.delete}
        </ModalFooterButton>
      }
      secondaryAction={<ModalFooterButton onClick={close}>{texts.footer.cancel}</ModalFooterButton>}
    />
  )
}
