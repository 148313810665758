import { memo, MouseEventHandler, KeyboardEvent, useCallback } from 'react'
import styled from 'styled-components'

import {
  TextInputState,
  TextInputStateChangeEventHandler as ChangeEventHandler,
  InputFieldText,
} from '@/forms-legacy'
import { Search } from '@/icons'

export interface Props {
  onChange: (value: string) => void
  filter: string | undefined
  className?: string
  name?: string
  placeholder?: string
  extraLabel?: string
  extraLabelOnClick?: MouseEventHandler
  extraLabelClassName?: string
  testId?: string
  trackid?: string
}

export function SearchInput(props: Props) {
  const {
    name = 'o-order-search-bar_input',
    className = 'pendo-order__search',
    filter,
    onChange,
    placeholder = '',
    extraLabel = '',
    extraLabelOnClick,
    extraLabelClassName,
    trackid,
    testId,
  } = props

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Escape') {
        onChange('')
      }
    },
    [onChange],
  )

  return (
    <InputWrapper className={className} data-testid={testId}>
      <TextInputState
        value={filter ? filter : ''}
        delay={300}
        onChange={onChange}
        render={(text: string, wrappedOnChange: ChangeEventHandler) => (
          <InputFieldText
            extraIcon={<Search />}
            name={name}
            placeholder={placeholder}
            extraLabel={extraLabel}
            extraLabelOnClick={extraLabelOnClick}
            extraLabelClassName={extraLabelClassName}
            onChange={wrappedOnChange}
            value={text}
            onKeyDown={handleOnKeyDown}
            trackid={trackid}
          />
        )}
      />
    </InputWrapper>
  )
}

const ICON_SIZE: number = 16
const InputWrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 272px;
  margin-left: 20px;

  .o-input-field-text__input-wrapper {
    width: auto;
  }

  .o-input-field-text__base-input {
    max-width: 160px;
  }

  .o-input-field-text__extra-label,
  .o-input-field-text__extra-label-as-link {
    font-size: ${p => p.theme.fonts.$s};
    text-decoration: none;
  }

  .o-input-field-text__extra-label-as-link:hover {
    text-decoration: none;
  }

  .o-input-field-text__extra-label {
    position: absolute;
    right: 30px;
  }
  .o-input-field-text {
    height: 22px;
    line-height: 20px;
    background: ${p => p.theme.colors.$pureWhite};
    border-color: transparent;
    color: ${p => p.theme.colors.$nightRider};
  }

  .o-input-field-text__extra-icon {
    width: ${ICON_SIZE}px;
    margin-right: ${ICON_SIZE / 4}px;
    color: inherit;
  }
`

InputWrapper.displayName = 'InputWrapper'

export default memo(SearchInput)
