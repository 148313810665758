import styled from 'styled-components'

const styledComponent = styled.span`
  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
  line-height: 0.88;

  display: block;
  color: inherit;
  text-transform: uppercase;
`

styledComponent.displayName = 'OrderAvatarText'
export default styledComponent
