import type { EditingStateContext } from '@/atoms'

import { resetOrderFeaturesEditingState } from '../../layers/order/resetOrderFeaturesEditingState'
import { getDomainMarkerData } from '../../atoms/markerChangelog/domain/getDomainMarkerData'
import { getMap } from '../../atoms/map/utils/getMap'

export function resetMapEditingState(ctx: EditingStateContext) {
  const map = getMap()
  const { mapMarkers, mapStyles } = getDomainMarkerData()

  // hide selected entities when required
  switch (ctx) {
    case 'order': {
      resetOrderFeaturesEditingState(map, mapMarkers.order, mapStyles.order)
      break
    }

    case 'region':
    case 'place':
    case 'geofence':
    case 'segment': {
      // TODO: add logic when the map layers is available
      // TODO: add logic when the map layers is available
      break
    }

    // all other entities don't require to hide anything when selected
    default:
      break
  }
}
