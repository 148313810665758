import { Outlet } from 'react-router'

import { Grow, HorizontalLayout } from '@/components/layout'
import { useResetOrdersFilterOnMount } from '@/hooks'

import { SetupSidebar } from './components/SetupSidebar'

export function Setup() {
  useResetOrdersFilterOnMount()

  return (
    <HorizontalLayout>
      <SetupSidebar />

      <Grow xAxis yAxis>
        <Outlet />
      </Grow>
    </HorizontalLayout>
  )
}
