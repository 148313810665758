import { usePagination } from '@/atoms'

import { useNavigoCategorySelection } from '../../../hooks/useNavigoCategorySelection'

export const useNavigator = () => {
  const pagination = usePagination('navigo')
  const { ids } = useNavigoCategorySelection()

  return {
    ids,
    pagination,
  }
}
