import type { FormErrors, FormFields } from '../../formFields'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { TextField } from '@workwave-tidal/tidal/form-ui'
import { useTexts } from './hooks/useTexts'

export function PhoneField() {
  const texts = useTexts()
  const { field } = useFormField<'backOfficePhone', FormFields, FormErrors>('backOfficePhone')
  const helperText =
    field.status === 'invalid' ? texts.backOfficePhone.hint : texts.backOfficePhone.caption

  return (
    <TextField<'backOfficePhone', FormFields, FormErrors>
      name="backOfficePhone"
      label={texts.backOfficePhone.label}
      validateOn="blur change"
      helperText={helperText}
    />
  )
}
