import { ListActionButton } from '@/forms-legacy'
import { AddRounded } from '@/icons'

import SmallLabel from './SmallLabel'

interface Props {
  onChangeStep: () => void
  buttonText: string
  labelText: string
  disabled?: boolean
}

export function Collapsed(props: Props) {
  const { onChangeStep, disabled, buttonText, labelText } = props

  return (
    <>
      <ListActionButton
        width={165}
        disabled={disabled}
        icon={<AddRounded />}
        onClick={onChangeStep}
        testid="load-add__button"
      >
        {buttonText}
      </ListActionButton>
      {disabled && <SmallLabel>{labelText}</SmallLabel>}
    </>
  )
}
