import { useMemo } from 'react'
import { format, addDays, isAfter } from 'date-fns/esm'

export function useIsExportDisabled(
  rangeWithArchivedDays: boolean,
  selectedDaysCount: number,
  daysWithOrders: Record<string, number>,
  start?: Date,
  end?: Date,
) {
  return useMemo(() => {
    // Cannot export more than 31 archived days
    if (rangeWithArchivedDays && selectedDaysCount > 31) return 'moreThan31ArchivedDays'

    // No start range
    if (!start) return 'noOrders'

    // One-day range
    if (!end || start === end) {
      const dateAsString = format(start, 'yyyyMMdd')
      return !daysWithOrders[dateAsString] || daysWithOrders[dateAsString] === 0
        ? 'noOrders'
        : false
    }

    // Multi-day range
    let day = start
    while (!isAfter(day, end)) {
      if (daysWithOrders[format(day, 'yyyyMMdd')] > 0) return false
      day = addDays(day, 1)
    }

    return 'noOrders'
  }, [rangeWithArchivedDays, selectedDaysCount, daysWithOrders, start, end])
}
