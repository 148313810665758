import { unwrapResult } from '@reduxjs/toolkit'
import { AppDispatch, getReduxStore } from '@/store'

import { gpsReverseGeocode, reverseGeocode } from '@/features/domain/ui'

export async function reverseGeocodeLocation(
  latLng: uui.domain.LatLng,
  bounds: uui.domain.LatLngBounds,
  snapToRoad: boolean = false,
): Promise<uui.domain.client.Location | void> {
  const dispatch = getReduxStore().dispatch as AppDispatch

  // Reverse geocode the provided coordinates
  const request = snapToRoad
    ? await dispatch(reverseGeocode({ latLng }))
    : await dispatch(gpsReverseGeocode({ latLng, bounds }))

  if ((snapToRoad ? reverseGeocode : gpsReverseGeocode).fulfilled.match(request)) {
    return unwrapResult(request)
  }
}
