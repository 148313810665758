import { Box } from '@mui/material'
import { WarningCard } from '@/local/components'

import { WarningTriangle } from '@/icons'
import { useTexts } from '../useTexts'

export function Body() {
  const texts = useTexts()

  return (
    <Box marginTop={2}>
      <WarningCard
        preset="warning"
        title={texts.requestChangePlanTitle}
        description={texts.requestChangePlanDescription}
        Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
      />
    </Box>
  )
}
