import { type ReactNode, type MouseEvent } from 'react'
import { Button, styled } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

type Props = {
  onToggleClick: (event: MouseEvent<HTMLElement>) => void
  disabled?: boolean
  children: ReactNode
  testId?: string
  open: boolean
}

export const ListHeaderSorterTriggerButton = styled(Button, {
  name: 'ListHeaderSorterTrigger',
})({
  textTransform: 'none',
  textAlign: 'left',
  float: 'left',
})

export function ListHeaderSorterTrigger(props: Props) {
  const {
    open,
    children,
    disabled = false,
    testId = 'list-header-sorter-trigger',
    onToggleClick,
  } = props

  const icon = open ? <KeyboardArrowUp /> : <KeyboardArrowDown />

  return (
    <ListHeaderSorterTriggerButton
      data-trackid={testId}
      data-testid={testId}
      disabled={disabled}
      endIcon={icon}
      onClick={onToggleClick}
      variant="text"
    >
      {children}
    </ListHeaderSorterTriggerButton>
  )
}
