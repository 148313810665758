import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  grid: {
    width: '100%',
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 0,
    },

    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },

    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },

    '& .MuiBadge-badge': {
      top: 5,
      left: 12,
    },
  },
  container: {
    width: '100%',
  },
  gridContainer: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
  },
})
