import { ActionPermission } from '../../types'

export function computeReportPermission(
  devicesCount: number,
  userType: uui.domain.UserType,
): ActionPermission {
  if (userType === 'courier') return 'hidden'

  return devicesCount > 0 ? 'enabled' : 'disabled'
}
