import { Add } from '@mui/icons-material'

import { ListHeaderActions, ListHeaderPrimaryActionButton } from '@/components/List'
import { TransactionInProgressTooltip } from '@/components/smartUtils/plan/TransactionInProgressTooltip'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../../../../hooks/useTexts'
import { useActions } from './hooks/useActions'

export function Actions() {
  const { editing, onCreate } = useActions()
  const texts = useTexts()

  return (
    <ListHeaderActions>
      <TransactionInProgressTooltip
        render={preventEditing => (
          <Tooltip
            title={texts.addSimulation}
            disabled={editing || preventEditing}
            placement="bottom"
          >
            <ListHeaderPrimaryActionButton
              className="pendo-simulations-new_simulation"
              disabled={editing || preventEditing}
              onClick={onCreate}
              trackId="simulations-new_simulation"
            >
              <Add />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
    </ListHeaderActions>
  )
}
