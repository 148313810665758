import { useCallback } from 'react'
import { useSnapshot } from 'valtio'

import { mapAtom } from '../map'

export const useLockMap = () => {
  return [
    useSnapshot(mapAtom).locked,

    useCallback((value: boolean) => {
      mapAtom.locked = value
    }, []),

    useCallback(() => {
      mapAtom.locked = !mapAtom.locked
    }, []),
  ] as const
}
