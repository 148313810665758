import { useSelector } from 'react-redux'

import { selectUserAccount } from '@/features/domain/user'
import { Tooltip } from '@/components/primitives/Tooltip'
import { FlexBox, Text } from '@/local/components'
import { BusinessUnit } from '@/icons'

import { useTexts } from '../useTexts'

export function GpsOnlyDropdown() {
  const texts = useTexts()
  const {
    details: { companyName },
  } = useSelector(selectUserAccount)

  return (
    <Tooltip placement="bottom" title={texts.tooltipGpsOnly(companyName)}>
      <FlexBox
        h={36}
        shrink={0}
        borderRadius={4}
        color="$pureWhite"
        position="relative"
        vAlignContent="center"
        padding="0 8px 0 12px"
      >
        <BusinessUnit size={18} marginRight={10} />

        <Text ellipsis size="$p2" marginRight={6}>
          {companyName}
        </Text>
      </FlexBox>
    </Tooltip>
  )
}
