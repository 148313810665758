import type { GeofenceFormValidation, GeofenceFormValues } from '../types'

import { isBefore, isSameDay, parseISO } from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import { locationHasError } from '@/server-data'
import { convertKmToMiles, convertMilesToKm } from '@/local/server-data/utils/smartUtils/conversion'

import { useTexts } from './useTexts'

export const minimumRadiusMeters = 50
export const maximumRadiusMeters = 100000

export const defaultGeofenceDateFormat = 'YYYY-MM-DDTHH:mm:ssZ'
export const defaultGeofenceNeverEndingDateTime = '2038-01-01T00:00:00'

const maxSpeedMaxMph = 200 // mph
const maxSpeedMinMph = 5 // mph

export const neverEndingDateTime = parseISO(defaultGeofenceNeverEndingDateTime)

const validateForm =
  (texts: ReturnType<typeof useTexts>, metric: boolean) =>
  (geofence: GeofenceFormValues): GeofenceFormValidation => {
    const validation: GeofenceFormValidation = {}

    const {
      name,
      deviceIds,
      notificationAddresses,
      startDateTime,
      endDateTime,
      radius,
      location,
      maxSpeed,
      maxSpeedEnabled,
    } = geofence

    if (name.length < 1) {
      validation.name = texts.validationMissingName
    }

    if (deviceIds.length < 1) {
      validation.deviceIds = texts.validationDeviceRequired
    }

    if (notificationAddresses.length < 1) {
      validation.notificationAddresses = texts.validateNotificationAddress
    }

    const endTimeIsSet: boolean = !!endDateTime && !isSameDay(endDateTime, neverEndingDateTime)

    if (endDateTime && endTimeIsSet && isBefore(endDateTime, startDateTime)) {
      validation.endDateTime = texts.validateEndDateTime
    }

    if (radius < minimumRadiusMeters || radius > maximumRadiusMeters) {
      validation.radius = texts.validateRadius
    }

    if (maxSpeedEnabled === 'enabled' && typeof maxSpeed === 'number') {
      const maxSpeedMph = metric ? Math.trunc(convertKmToMiles(maxSpeed)) : maxSpeed

      if (maxSpeedMph < maxSpeedMinMph || maxSpeedMph > maxSpeedMaxMph) {
        const min = metric ? Math.trunc(convertMilesToKm(maxSpeedMinMph)) : maxSpeedMinMph
        const max = metric ? Math.trunc(convertMilesToKm(maxSpeedMaxMph)) : maxSpeedMaxMph
        validation.maxSpeed = texts.validateMaxSpeed(min, max)
      }
    }

    if (!location || locationHasError(location)) {
      validation.location = 'Invalid location'
    }

    return validation
  }

export const useValidateForm = () => {
  const texts = useTexts()
  const userConfig = useSelector(selectUserConfiguration)

  return validateForm(texts, userConfig.distanceFormat === 'METRIC')
}
