import { IconButton } from '@/components/primitives/buttons'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Freeze, NoFreeze } from '@/icons'
import { useLockMap } from '@/map'

import { useStyles } from '../hooks/useStyles'
import { useTexts } from '../useTexts'

export function FreezeMapToggle() {
  const [locked, , toggleLockMap] = useLockMap()
  const classes = useStyles()
  const texts = useTexts()

  const testid = 'map-controls-freeze-map'

  return (
    <Tooltip placement="bottom" title={locked ? texts.freezeMapOff : texts.freezeMap}>
      <IconButton
        onClick={toggleLockMap}
        className={classes.button}
        data-testid={testid}
        data-trackid={testid}
      >
        {locked ? (
          <NoFreeze size={18} color="$nightRider" />
        ) : (
          <Freeze size={18} color="$nightRider" />
        )}
      </IconButton>
    </Tooltip>
  )
}
