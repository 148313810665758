import type Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Geometry from 'ol/geom/Geometry'

import { setDeviceFeatureMetadata } from '../deviceFeatureMetadata'
import { getDeviceFeatureStyles } from './getDeviceFeatureStyles'

export const updateDeviceFeature = (
  mapStyles: uui.domain.ui.map.markers.MapStyles['device'],
  marker: uui.domain.ui.map.markers.Device,
  feature: Feature<Geometry>,
  selected: boolean = false,
): Feature<Geometry> => {
  // ------------------------
  // Feature Geometry
  // ------------------------
  const newPoint = new Point(marker.lonLat)

  // Set default feature as 'label' and update geometry
  feature.setGeometryName('label')
  feature.setGeometry(newPoint)

  // Switch back to default feature and update geometry
  feature.setGeometryName('geometry')
  feature.setGeometry(newPoint)
  // ------------------------
  // Feature Metadata
  // ------------------------

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['device']['mode'] = marker.mode ?? mapStyles.mode
  setDeviceFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getDeviceFeatureStyles(marker, mode, selected))

  return feature
}
