import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

export const enableRequestLogNotifications = createAsyncThunk<
  // Return type of the payload creator
  uui.domain.SocketLogEntry[] | undefined,
  // First argument to the payload creator
  boolean,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('rpc/demoTools/requestLog/enableNotifications', async (enabled, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/demoTools/requestLog/enableNotifications', {
      category: 'rpc',
      type: 'rpc/demoTools/requestLog/enableNotifications',
      enabled,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Error while changeing enable status for request log',
      error,
    })
  }
})
