import type { Props } from './typings'

import { Block, FieldMessage, Label, RestoreFieldButton } from '@/forms-legacy'

import { NumericInput } from './NumericInput'

export function NumericInputField(props: Props) {
  const {
    info,
    label,
    half = false,
    name,
    wrappedInputProps,
    formProps: {
      input: { value, onChange },
      meta: { error, dirty, initial },
    },
  } = props

  return (
    <Block half={half} error={!!error} dirty={!!dirty}>
      <Label htmlFor={name}>
        {label}
        <RestoreFieldButton
          dirty={dirty}
          initial={initial}
          onChange={onChange}
          style={{ marginLeft: 12 }}
        />
      </Label>

      <NumericInput {...wrappedInputProps} name={name} onChange={onChange} value={value} />

      {!!error && <FieldMessage error>{error}</FieldMessage>}
      {!error && !!info && <FieldMessage>{info}</FieldMessage>}
    </Block>
  )
}
