import { createSelector } from '@reduxjs/toolkit'
import { selectTerritory, selectTerritories } from '@/features/domain/territory'

export const selectData = createSelector(
  selectTerritory,
  selectTerritories,
  (territory, territories) => {
    return {
      territory,
      territories: Object.values(territories).sort((a, b) => a.name.localeCompare(b.name)),
    }
  },
)
