import { useCallback } from 'react'

import { changePasswordService } from '@/services'
import { useEnvironmentURLs } from '@/atoms'
import { useNotification } from '@/hooks'
import { useNavigate } from '@/routing'

export function useActions(token: string | null) {
  const { serverUrl } = useEnvironmentURLs()
  const navigate = useNavigate()
  const toast = useNotification()

  const onGoToLogin = useCallback(() => {
    navigate('/login')
  }, [navigate])

  const onResetPassword = useCallback(
    async payload => {
      try {
        if (!token) {
          throw new Error('invalidToken')
        }

        const { newPassword } = payload
        const response = await changePasswordService({ password: newPassword, serverUrl, token })
        if (response !== 'success') throw new Error(response)

        toast.success('The password has been changed')
        navigate('/login')
      } catch (error) {
        if (!(error instanceof Error)) return

        if (error.message === 'invalidToken') {
          navigate('/error/invalid-reset-password-token')
        }

        toast.error('Ops...something went wrong. Please try again')
      }
    },
    [navigate, toast, token, serverUrl],
  )

  return { onResetPassword, onGoToLogin }
}
