import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { selectUserConfiguration } from '@/features/domain/user'
import { useAppLanguage, useIsOffline, useTransactionState } from '@/atoms'

import { useTransactionResolution } from './useTransactionResolution'

type FooterMode =
  | 'archived'
  | 'commit'
  | 'hidden'
  | 'locked'
  | 'offline'
  | 'rollback'
  | 'simulation'
  | 'transaction'

export const useFooterState = () => {
  // guarantees that the component is re-rendered when the language changes
  useAppLanguage()

  const offline = useIsOffline()
  const transaction = useTransactionState()
  const { mode: transactionMode } = transaction
  const { planType } = useSelector(selectUserConfiguration)
  const { transactionResolution } = useTransactionResolution()
  const [mode, setMode] = useState<FooterMode>('hidden')

  useEffect(() => {
    if (offline) {
      setMode('offline')
      return
    }

    switch (planType) {
      case 'archived':
        setMode('archived')
        break

      case 'simulation':
        setMode('simulation')
        break

      case 'operations':
        switch (transactionMode) {
          case 'external':
            setMode('locked')
            break

          case 'controlled':
            setMode('transaction')
            break

          default:
          case 'none':
            switch (transactionResolution) {
              case 'rollback':
                setMode('rollback')
                break

              case 'commit':
              case 'autoCommit':
                setMode('commit')
                break

              case 'none':
                setMode('hidden')
                break
            }
            break
        }
        break

      case 'none':
        setMode('hidden')
        break
    }
  }, [transactionResolution, transactionMode, offline, planType])

  return { mode, offline, transaction }
}
