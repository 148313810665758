import type { Theme } from '@mui/material'
import type { ReactElement } from 'react'

import { makeStyles } from '@mui/styles'

interface Props {
  children: ReactElement | ReactElement[]
  spacing?: number
}

export const useClasses = makeStyles<Theme, Props>(theme => ({
  fieldset: props => ({
    padding: 0,
    margin: 0,
    paddingBottom: theme.spacing(props.spacing ?? 4),
    borderWidth: 0,
  }),
}))

export function FieldSet(props: Props) {
  const classes = useClasses(props)

  return <fieldset className={classes.fieldset}>{props.children}</fieldset>
}
