import { useMemo } from 'react'
import { useTexts } from './useTexts'

export function useRadioGroupValues() {
  const texts = useTexts()

  return useMemo(() => {
    return [
      {
        label: texts.fiveMins,
        value: 'fiveMins',
      },
      {
        label: texts.tenMins,
        value: 'tenMins',
      },
      {
        label: texts.thirtyMins,
        value: 'thirtyMins',
      },
      {
        label: texts.custom,
        value: 'custom',
      },
    ]
  }, [texts])
}
