import { PureComponent, CSSProperties } from 'react'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyVerticalList,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  SecondaryColumnContentHorizontalRuler,
  ReadonlyField,
} from '@/forms-legacy'

import * as OrderTexts from '../../../intl'
import { Props, FieldMode } from '../typings'

const ellipsisStyle: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  direction: 'rtl',
}

export default class Barcodes extends PureComponent<Props> {
  render() {
    const { barcodes } = this.props

    if (barcodes === FieldMode.hidden) {
      return null
    }

    return (
      <>
        <ReadonlyBlock>
          <ReadonlyLabel primary empty={barcodes.length === 0}>
            {OrderTexts.getBarcodesFieldText()}
          </ReadonlyLabel>

          {barcodes.length > 0 && (
            <ReadonlyVerticalList>
              {barcodes.map((value, i) => (
                <ReadonlyField key={`${value}-${i}`}>
                  <ReadonlyFieldRow>
                    <ReadonlyFieldLabel style={ellipsisStyle}>{value}</ReadonlyFieldLabel>
                  </ReadonlyFieldRow>
                </ReadonlyField>
              ))}
            </ReadonlyVerticalList>
          )}
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bigBottomSpace />
      </>
    )
  }
}
