import type { CSSProperties } from 'react'

import { AvatarContainer } from './elements/AvatarContainer'
import { EventInfoIcon } from './elements/EventInfoIcon'

interface Props {
  event: uui.domain.client.gps.wwgps.EventInfo
  size: number
  selected?: boolean
  style?: CSSProperties
}

export function Avatar(props: Props) {
  const { event, size, style = {}, selected } = props

  return (
    <AvatarContainer style={style} size={size} selected={selected}>
      <EventInfoIcon eventType={event.type} size={28} />
    </AvatarContainer>
  )
}
