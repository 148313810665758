import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    liveTrafficOn: translate({ id: 'map.controls.liveTraffic.on' }),
    liveTrafficOff: translate({ id: 'map.controls.liveTraffic.off' }),
    freezeMap: translate({ id: 'map.controls.freezeMap.on' }),
    freezeMapOff: translate({ id: 'map.controls.freezeMap.off' }),
    inspectTool: translate({ id: 'map.controls.inspectTool.on' }),
    inspectToolOff: translate({ id: 'map.controls.inspectTool.off' }),
  }))

  return api
}
