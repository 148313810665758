import { Calendar } from '@/components/Calendar'

import { useByCalendarProps } from './useByCalendarProps'

export type Props = {
  date: string
  initialVisibleDate: string
  onChange: (dates: Date[]) => void
  onReferenceDateChange: (newReferenceDate: Date) => void
}

export function ByCalendar(props: Props) {
  const calendarProps = useByCalendarProps(props)

  return (
    <Calendar
      {...calendarProps}
      initialVisibleDate={props.initialVisibleDate}
      onReferenceDateChange={props.onReferenceDateChange}
    />
  )
}
