import { type SchedulerProConfig } from '@bryntum/schedulerpro'
import { updateVehicleTimeWindow } from '@/features/domain/vehicle'
import { store } from '@/store'
import { getResizeTimeWindowAtom } from '../../../atoms/resizeTimeWindow'

export const onEventResizeEnd: SchedulerProConfig['onEventResizeEnd'] = async params => {
  const { source, changed, eventRecord } = params

  const lastValidTimeWindow = getResizeTimeWindowAtom()

  // If the event isn't changed means that the resize has been completed, so we can return.
  if (changed || !lastValidTimeWindow) return

  const resourceRecord = source.resourceStore.getById(eventRecord.resourceId)

  const date = resourceRecord.getData('dateAsString') as string
  const vehicleId = resourceRecord.getData('vehicleId') as string

  const result = await store.dispatch(
    updateVehicleTimeWindow({ date, vehicleId, timeWindow: lastValidTimeWindow }),
  )

  if (updateVehicleTimeWindow.rejected.match(result)) {
    throw new Error(result.payload?.message ?? 'Internal error')
  }
}
