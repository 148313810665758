import type { Location } from 'history'
import { useMemo } from 'react'
import { useLocation } from 'react-router'

type LocationStateWithFrom = Location & { state: { from: Location } }

export function useLocationFrom() {
  const location = useLocation()

  return useMemo(() => {
    const locWithState = location.state as LocationStateWithFrom

    if (
      typeof locWithState?.state?.from?.pathname === 'string' &&
      typeof locWithState?.state?.from?.search === 'string' &&
      typeof locWithState?.state?.from?.hash === 'string'
    ) {
      return locWithState.state.from
    }

    return null
  }, [location])
}
