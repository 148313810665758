import { type GridCellParams } from '@mui/x-data-grid-pro'
import { Typography } from '@mui/material'

export function NameCell(params: GridCellParams) {
  return (
    <Typography variant="body2" data-testid="companies-name">
      {params.value}
    </Typography>
  )
}
