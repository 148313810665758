import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'

import { createNonBlockingRenderByTime } from '../../renderingQueue'
import { setLayerMetadata } from '../layerMetadata'
import { findLayer } from '../utils/findLayer'
import { mapAtom } from '../../atoms/map/map'

import { createBreadcrumbPointsFeatures } from './utils/breadcrumbPoints/createBreadcrumbPointsFeatures'
import { createBreadcrumbStopsFeatures } from './utils/breadcrumbStops/createBreadcrumbStopsFeatures'
import { createBreadcrumbPathFeature } from './utils/breadcrumbPath/createBreadcrumbPathFeature'

const nonblockingRender = createNonBlockingRenderByTime()

export const initializeBreadcrumbFeatures = async (
  markers: uui.domain.ui.map.markers.MapMarkers['breadcrumb'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
  selection: Set<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) => {
  const map = mapAtom.map
  const layersIds = ['breadcrumbPoints', 'breadcrumbPath'] as const

  for (const layerId of layersIds) {
    // Breadcrumb layer
    const layer = findLayer(map, layerId)

    const features: Feature<Geometry>[] = []

    nonblockingRender.reset()

    for (const marker of Object.values(markers)) {
      await nonblockingRender.next()

      const stopFeatures = createBreadcrumbStopsFeatures(
        marker,
        mapStyles,
        selection,
        breadcrumbTimeRange,
      )

      switch (layerId) {
        case 'breadcrumbPoints':
          features.push(
            ...createBreadcrumbPointsFeatures(marker, mapStyles, selection, breadcrumbTimeRange),
          )
          features.push(...stopFeatures)
          break

        case 'breadcrumbPath':
          features.push(createBreadcrumbPathFeature(marker, mapStyles))
          features.push(...stopFeatures)
          break
      }
    }

    // add all new features to the layer
    layer.getSource()?.addFeatures(features)

    // keep track of selected items in the current layer
    setLayerMetadata(layer, 'selection', new Set(selection))
  }
}
