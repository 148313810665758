import styled from 'styled-components'

const styledComponent = styled.input.attrs<workwave.Input>(() => ({
  className: 'o-molecule-checkbox-input',
  type: 'checkbox',
}))`
  opacity: 0;
  z-index: 10;
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  appearance: none;

  &:disabled {
    cursor: not-allowed;
  }
`

styledComponent.displayName = 'CheckboxInput'
export default styledComponent
