import { Box } from '@mui/material'

import { useSettingsEditing } from '../../hooks/useSettingsEditing'

import { LanguageAndFormatsView } from './LanguageAndFormatsView'
import { LanguageAndFormatsForm } from './LanguageAndFormatsForm'

export function LanguageAndFormats() {
  const { editing, onEdit, onDiscard } = useSettingsEditing('rm-account', 'reset')

  return (
    <Box display="flex" width="100%" height="100%">
      {!editing && <LanguageAndFormatsView onEdit={onEdit} />}
      {editing && <LanguageAndFormatsForm onDiscard={onDiscard} />}
    </Box>
  )
}
