import { proxy, useSnapshot, subscribe } from 'valtio'

export interface SchedulerPreferences {
  showUnavailableRoutes: boolean
  showApprovedRoutes: boolean
  showAbsoluteValues: boolean
  groupBy: 'dateAsString' | 'vehicleId'
  sorters: {
    field: string
    ascending: boolean
  }[]
}

type SchedulerPreferencesAtom = { preferences: SchedulerPreferences }

// Default values
// ------------------------------------
const defaultSorters = [
  {
    field: 'vehicleAvatar',
    ascending: true,
  },
]
const defaultSchedulerPreferences: SchedulerPreferences = {
  groupBy: 'dateAsString',
  sorters: defaultSorters,
  showAbsoluteValues: false,
  showApprovedRoutes: false,
  showUnavailableRoutes: false,
}

const localStorageId = 'routemanager/v3/atoms/local/scheduler/schedulerPreferences'

function computeInitialSchedulerPreferences() {
  const preferences = JSON.parse(
    localStorage.getItem(localStorageId) ?? JSON.stringify(defaultSchedulerPreferences),
  )

  localStorage.setItem(localStorageId, JSON.stringify(preferences))
  return preferences
}

const createDefaultSchedulerPreferences = (): SchedulerPreferencesAtom => ({
  preferences: computeInitialSchedulerPreferences(),
})

export const schedulerPreferencesAtom = proxy<SchedulerPreferencesAtom>(
  createDefaultSchedulerPreferences(),
)

subscribe(schedulerPreferencesAtom.preferences, () => {
  localStorage.setItem(localStorageId, JSON.stringify(schedulerPreferencesAtom.preferences))
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetSchedulerPreferences = (prev: SchedulerPreferences) => SchedulerPreferences
export type SchedulerPreferencesSetter = SetSchedulerPreferences | Partial<SchedulerPreferences>

export const setSchedulerPreferences = (valueOrFunc: SchedulerPreferencesSetter) => {
  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(
      schedulerPreferencesAtom.preferences,
      valueOrFunc(schedulerPreferencesAtom.preferences),
    )
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      schedulerPreferencesAtom.preferences[field] = valueOrFunc[field]
    }
  }

  return schedulerPreferencesAtom.preferences
}

// ------------------------------------
// React Hooks
// ------------------------------------

export const useSchedulerPreferencesAtom = () => {
  return [useSnapshot(schedulerPreferencesAtom).preferences, setSchedulerPreferences] as const
}
