import { useCallback, useMemo } from 'react'
import { setOrdersGrid } from '@/atoms'

export function useColumnsPanelProps(lockedColumns: string[]) {
  const onToggleAllColumns = useCallback(
    (visible: boolean) => {
      setOrdersGrid(draft => {
        for (const column of draft.columns) {
          if (lockedColumns.includes(column.field)) continue

          column.hide = visible
        }

        return draft
      })
    },
    [lockedColumns],
  )

  return useMemo(() => ({ lockedColumns, onToggleAllColumns }), [lockedColumns, onToggleAllColumns])
}
