import type { MainSelection } from './typings'

import { proxy } from 'valtio'
import { devtools } from 'valtio/utils'
import { getDevPreference } from '@/dev'

export const createDefaultMainSelection = (): MainSelection => ({
  breadcrumbs: [],
  depots: [],
  devices: [],
  drivers: [],
  orderSteps: [],
  places: [],
  routes: [],
  unifiedVehicles: [],
})

export const mainAtom = proxy<{ selection: MainSelection }>({
  selection: createDefaultMainSelection(),
})

if (getDevPreference('mainSelectionDevTools') === 'enable') {
  devtools(mainAtom, 'mainSelectionAtom')
}
