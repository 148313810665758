import { useEffect } from 'react'
import { domainProxy } from '@/store'

/**
 * That hook resets the transaction timer every time it receives a `resetTimer` notification from server-data
 */
export function useOnTimerReset(reset: () => void) {
  useEffect(() => {
    return domainProxy.subscribeToNotifications(reset, ['resetTimer'])
  }, [reset])
}
