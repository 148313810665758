import { useController } from '../../hooks/useController'
import { SuccessBody } from './components/SuccessBody'
import { ReadyBody } from './components/ReadyBody'
import { ErrorBody } from './components/ErrorBody'

export function Body() {
  const { status } = useController()

  switch (status) {
    case 'ready':
    case 'submitting':
      return <ReadyBody />

    case 'success':
      return <SuccessBody />

    case 'error':
      return <ErrorBody />

    default:
      return null
  }
}
