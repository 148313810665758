import styled from 'styled-components'

const ReadonlyAudioNativeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  width: 100%;
`

ReadonlyAudioNativeContainer.displayName = 'ReadonlyAudioNativeContainer'
export default ReadonlyAudioNativeContainer
