import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    routeStartPlannedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeStart.planned.tooltip.regular',
    }),
    routeStartWontBeCollectedRMOnlyTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeStart.tracked.tooltip.wontBeCollected.rmOnly',
    }),
    routeStartWontBeCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeStart.tracked.tooltip.wontBeCollected',
    }),
    routeStartNotCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeStart.tracked.tooltip.notCollected',
    }),
    routeStartCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeStart.tracked.tooltip.collected',
    }),
    routeEndPlannedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeEnd.planned.tooltip.regular',
    }),
    routeEndNotCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeEnd.tracked.tooltip.notCollected',
    }),
    routeEndCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeEnd.tracked.tooltip.collected',
    }),
    routeEndWontBeCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeEnd.tracked.tooltip.wontBeCollected',
    }),
    routeEndWontBeCollectedRMOnlyTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeEnd.tracked.tooltip.wontBeCollected.rmOnly',
    }),
    preparationTime: translate({ id: 'rm.sidebar.routeDetails.view.prepTime' }),
    closeoutTime: translate({ id: 'rm.sidebar.routeDetails.view.closeoutTime' }),
    driverStart: translate({ id: 'rm.sidebar.routeDetails.view.driverStart' }),
    routeStart: translate({ id: 'rm.sidebar.routeDetails.view.routeStart' }),
    driverEnd: translate({ id: 'rm.sidebar.routeDetails.view.driverEnd' }),
    routeEnd: translate({ id: 'rm.sidebar.routeDetails.view.routeEnd' }),
    orders: (count: number) =>
      translate({ id: 'rm.sidebar.routeDetails.view.orders', values: { count } }),

    trackedDriverStartTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.driverStart.tooltip',
    }),
    trackedDriverEndTooltip: translate({ id: 'rm.sidebar.routeDetails.view.driverEnd.tooltip' }),
    closeoutTimeTooltipExecuted: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.tooltip.executed',
    }),
    closeoutTimeTooltipPlanned: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.tooltip.planned',
    }),
    closeoutTimeTooltipNotCollected: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.tooltip.notCollected',
    }),
    closeoutTimeTooltipWontBeCollected: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.tooltip.wontBeCollected',
    }),
    closeoutTimeTooltipNotDispatched: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.tooltip.notDispatched',
    }),
    closeoutTimeWontBeCollectedRMOnlyTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.tracked.tooltip.wontBeCollected.rmOnly',
    }),
    preparationTimeTooltipExecuted: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.tooltip.executed',
    }),
    preparationTimeTooltipPlanned: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.tooltip.planned',
    }),
    preparationTimeTooltipNotCollected: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.tooltip.notCollected',
    }),
    preparationTimeTooltipWontBeCollected: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.tooltip.wontBeCollected',
    }),
    preparationTimeTooltipNotDispatched: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.tooltip.notDispatched',
    }),
    preparationTimeWontBeCollectedRMOnlyTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.tracked.tooltip.wontBeCollected.rmOnly',
    }),
  }))

  return api
}
