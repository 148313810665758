import { useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

import { useData } from './useData'

type ModalState = 'ready'

export type Tag = {
  id: string
  label: string
}

export interface ModalData {
  badges: Tag[]
  badgeFilter: Tag[]
  filterOperator: 'or' | 'and'
  filterPristine: boolean
  includeDevicesWithNoBadges: boolean
  allBadgesChecked: boolean
}

export const modalId = 'filterByBadge'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = () => {
  const data = useData()
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data,
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  return ctrl
}
