import { useEffect } from 'react'

import { refreshGeofenceLocations } from '@/features/domain/geofence'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { useIsLoading } from '@/atoms'

import { useTexts } from './useTexts'

export function useRefreshGeofenceLocations() {
  const isLoading = useIsLoading('fetchGpsData')
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  useEffect(() => {
    const refreshGeocodeLocations = async () => {
      try {
        const thunkResult = await dispatch(refreshGeofenceLocations())

        if (refreshGeofenceLocations.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }
      } catch (error) {
        toast.error(texts.errorGeocodingGeofences, { autoClose: 5000 })
      }
    }

    // Let's wait for the completion of GPS data fetching before refreshing locations
    if (!isLoading) {
      refreshGeocodeLocations()
    }
  }, [dispatch, isLoading, toast, texts])
}
