import styled from 'styled-components'

const styledComponent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`
styledComponent.displayName = 'HorizontalRadioContainer'
export default styledComponent
