import type { Options } from '../types'

export function isOptions<T>(source: unknown): source is Options<T> {
  if (typeof source !== 'object' || !source) return false
  if (Object.prototype.toString.call(source) !== '[object Object]') return false

  if (
    Object.keys(source).some(
      key =>
        key !== 'parse' &&
        key !== 'isValid' &&
        key !== 'serialize' &&
        key !== 'debugName' &&
        key !== 'initialValue' &&
        key !== 'clearOnUnmount',
    )
  ) {
    return false
  }

  return true
}
