import type { FormFields, FormErrors } from '../../../formFields'

import { BulkTextField } from '../../primitives/BulkTextField'
import { useTexts } from './useTexts'

export function Phone() {
  const texts = useTexts()

  return (
    <BulkTextField<'phone', FormFields, FormErrors>
      name="phone"
      mixedLabel={texts.mixed}
      label={texts.phone}
      validateOn="blur"
    />
  )
}
