import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'

import { Header } from './Header'
import { Footer } from './Footer'
import { FeedbackForm, FeedbackFormProvider } from './FeedbackForm'

export function Content() {
  const { ready } = useConfigureController()

  if (!ready) return null

  return (
    <FeedbackFormProvider>
      <ModalContent header={<Header />} footer={<Footer />}>
        <FeedbackForm />
      </ModalContent>
    </FeedbackFormProvider>
  )
}
