import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState({
    bestFitResponse100: translate({ id: 'error.rpc.bestFitResponse.100' }),
    bestFitResponse101: translate({ id: 'error.rpc.bestFitResponse.101' }),
    bestFitResponse102: translate({ id: 'error.rpc.bestFitResponse.102' }),
    bestFitResponse103: translate({ id: 'error.rpc.bestFitResponse.103' }),
    bestFitResponse104: translate({ id: 'error.rpc.bestFitResponse.104' }),
    bestFitResponse105: translate({ id: 'error.rpc.bestFitResponse.105' }),
    noAssigned: translate({ id: 'modals.fitin.results.noAssigned' }),
    someAssigned: (assignedCount: number, unassignedCount: number) =>
      translate({
        id: 'modals.fitin.results.someAssigned',
        values: { assignedCount, unassignedCount },
      }),
    allAssigned: translate({ id: 'modals.fitin.results.allAssigned' }),
    error: translate({ id: 'modals.fitin.results.error' }),
  })

  return api
}
