import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { formatNumber } from '@/hooks'
import { selectUserConfiguration } from '@/features/domain/user'

export const useFormatCurrency = (amount: number): string => {
  const { currency, language } = useSelector(selectUserConfiguration)

  return useMemo(() => formatCurrency(amount, currency, language), [currency, amount, language])
}

export function formatCurrency(amount: number, currency: string, language: string) {
  return `${formatNumber(amount, language)} ${currency.toUpperCase()}`
}
