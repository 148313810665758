import styled from 'styled-components'

export const WIDTH = 44
const VERTICAL_PAD = 1
const GUTTER = 2

const StepButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: ${VERTICAL_PAD}px;
  width: ${WIDTH}px;
  padding: ${VERTICAL_PAD}px 0;

  > * + * {
    margin-top: ${GUTTER}px;
  }
`

StepButtonWrapper.displayName = 'StepButtonWrapper'

export default StepButtonWrapper
