import { CSSProperties } from 'react'
import styled from 'styled-components'

import { getColorWithAlpha, ValidColor, WithTheme } from '../theme'
import { parseSizeValue } from './utils/parseSizeValue'

export const hAlignContent_ = ['left', 'center', 'right'] as const
type HAlignContent = (typeof hAlignContent_)[number]

export const vAlignContent_ = ['top', 'center', 'bottom'] as const
type VAlignContent = (typeof vAlignContent_)[number]

export const position_ = ['static', 'relative', 'absolute', 'sticky', 'fixed'] as const
type Position = (typeof position_)[number]

export interface Props {
  className?: string
  style?: CSSProperties
  column?: boolean
  vAlignContent?: VAlignContent
  hAlignContent?: HAlignContent
  grow?: boolean | number
  shrink?: boolean | number
  basis?: string | number
  flexWrap?: boolean
  w?: string | number
  h?: string | number
  gutter?: string | number
  position?: Position
  zIndex?: 'auto' | number
  color?: ValidColor
  tint?: ValidColor
  backgroundAlpha?: number
  padding?: string | number
  lineHeight?: string | number
  marginTop?: string | number
  marginBottom?: string | number
  marginLeft?: string | number
  marginRight?: string | number
  padY?: string | number
  padX?: string | number
  paddingTop?: string | number
  paddingBottom?: string | number
  paddingLeft?: string | number
  paddingRight?: string | number
  borderRadius?: string | number
}

type ThemedProps = WithTheme<Props>

const getFlexDirection = ({ column = false }: Props) => (column ? 'column' : 'row')

const getFlexWrap = ({ flexWrap: wrap = false }: Props) => (wrap ? 'wrap' : 'nowrap')

const getJustifyContent = ({ column, vAlignContent = 'top', hAlignContent = 'left' }: Props) =>
  alignPropToFlex(column ? vAlignContent : hAlignContent)

const getAlignItems = ({ column, vAlignContent = 'top', hAlignContent = 'left' }: Props) =>
  alignPropToFlex(column ? hAlignContent : vAlignContent)

const alignPropToFlex = (align: VAlignContent | HAlignContent) => {
  switch (align) {
    case 'top':
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'bottom':
    case 'right':
      return 'flex-end'
  }
}

const getGrow = ({ grow }: Props) => {
  if (typeof grow === 'number') {
    return grow
  } else if (grow) {
    return 1
  }

  return 0
}

const getShrink = ({ shrink, basis }: Props) => {
  if (typeof shrink === 'number') {
    return shrink
  } else if (shrink) {
    return 1
  } else if (shrink === false) {
    return 0
  }

  if (basis && basis !== 'auto') {
    return 0
  }

  return 1
}

const getBasis = ({ basis }: Props) => {
  if (basis) {
    return parseSizeValue(basis)
  }

  return 'auto'
}

const getMarginTop = ({ marginTop }: Props) => {
  if (marginTop) {
    return parseSizeValue(marginTop)
  }

  return 0
}

const getMarginBottom = ({ marginBottom }: Props) => {
  if (marginBottom) {
    return parseSizeValue(marginBottom)
  }

  return 0
}

const getMarginLeft = ({ marginLeft, gutter }: Props) => {
  if (marginLeft) {
    return parseSizeValue(marginLeft)
  }

  if (gutter) {
    return parseSizeValue(gutter)
  }

  return 0
}

const getMarginRight = ({ marginRight, gutter }: Props) => {
  if (marginRight) {
    return parseSizeValue(marginRight)
  }

  if (gutter) {
    return parseSizeValue(gutter)
  }

  return 0
}

const getFlex = (props: Props) => `${getGrow(props)} ${getShrink(props)} ${getBasis(props)}`

const getWidth = ({ w: width = 'auto' }: Props) => parseSizeValue(width)

const getBorderRadius = ({ borderRadius = 0 }: Props) => parseSizeValue(borderRadius)

const getHeight = ({ h: height = 'auto' }: Props) => parseSizeValue(height)

const getLineHeight = ({ lineHeight = '1em' }: Props) => parseSizeValue(lineHeight)

const getPosition = ({ position = 'static' }: Props) =>
  position !== 'static' ? `position: ${position}` : ''

const getZIndex = ({ zIndex = 'auto' }: Props) => (zIndex !== 'auto' ? `z-index: ${zIndex}` : '')

const getColor = ({ color, theme }: ThemedProps): string => {
  const colorValue = color ? theme.colors[color] : undefined
  return colorValue ? colorValue : 'currentColor'
}

const getTint = ({ tint, theme, backgroundAlpha = 1 }: ThemedProps): string => {
  const alpha = backgroundAlpha >= 0 && backgroundAlpha <= 1 ? backgroundAlpha : 1
  const value = tint && theme.colors[tint] ? getColorWithAlpha(tint, alpha) : undefined
  return value ? value : 'transparent'
}

const getPadding = ({
  padding,
  padX,
  padY,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
}: ThemedProps): string => {
  if (typeof padding !== 'undefined') {
    return parseSizeValue(padding)
  }

  const top = typeof paddingTop !== 'undefined' ? paddingTop : padY
  const bottom = typeof paddingBottom !== 'undefined' ? paddingBottom : padY
  const left = typeof paddingLeft !== 'undefined' ? paddingLeft : padX
  const right = typeof paddingRight !== 'undefined' ? paddingRight : padX

  return `${parseSizeValue(top || 0)} ${parseSizeValue(right || 0)} ${parseSizeValue(
    bottom || 0,
  )} ${parseSizeValue(left || 0)}`
}

const div = styled.div<Props>``
export const FlexBox = styled(div).attrs<Props>(() => ({
  className: 'o-flex-box',
}))`
  ${getPosition};
  ${getZIndex};
  display: flex;
  flex: ${getFlex};
  flex-direction: ${getFlexDirection};
  justify-content: ${getJustifyContent};
  align-items: ${getAlignItems};
  flex-wrap: ${getFlexWrap};
  width: ${getWidth};
  height: ${getHeight};
  line-height: ${getLineHeight};
  margin-top: ${getMarginTop};
  margin-bottom: ${getMarginBottom};
  margin-left: ${getMarginLeft};
  margin-right: ${getMarginRight};
  border-radius: ${getBorderRadius};

  color: ${getColor};
  background-color: ${getTint};
  padding: ${getPadding};

  min-width: 0;
  min-height: 0;
`

FlexBox.displayName = 'FlexBox'
