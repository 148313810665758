import type Feature from 'ol/Feature'

import Icon from 'ol/style/Icon'
import { Style } from 'ol/style'
import Point from 'ol/geom/Point'
import Circle from 'ol/geom/Circle'
import Geometry from 'ol/geom/Geometry'

import { gis } from '@/server-data'

import { getSvgText } from '../../../../utils/getSvgText'
import { mapAtom } from '../../../../atoms/map/map'

const labelMarkerStylesCache: Map<string, Style> = new Map()

export function getLabelStyle(type: uui.domain.ui.map.markers.Geofence['type'], label: string) {
  const labelCacheId = `${type}_${label}`

  if (labelMarkerStylesCache.has(labelCacheId)) {
    const cachedLabelStyle = labelMarkerStylesCache.get(labelCacheId)

    if (cachedLabelStyle) return cachedLabelStyle
  }

  const labelToRender = `${getGeofenceTypeIcon(type)} ${label}`

  const style = new Style({
    image: new Icon({
      opacity: 1,
      src:
        'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(getSvgText(labelToRender, true)),
      scale: 1,
    }),
    geometry: (feature: Feature<Geometry>) => {
      const geometry = feature?.getGeometry()

      if (geometry instanceof Geometry && geometry.getType() === 'Circle') {
        const circle = geometry as Circle
        const centerCoordinate = circle.getCenter()

        const bottomLatLng = gis.computeOffset(
          gis.fromCoordinateToLatLng(centerCoordinate),
          circle.getRadius(),
          180,
        )
        const bottomCoordinate = gis.fromLatLngToCoordinate(bottomLatLng)

        let bottomPoint = new Point(bottomCoordinate)
        const bottomPixels = mapAtom.map.getPixelFromCoordinate(bottomCoordinate)

        const [x, y] = bottomPixels
        const shiftedBottomCoord = mapAtom.map.getCoordinateFromPixel([x, y + 20])
        bottomPoint = new Point(shiftedBottomCoord)

        return bottomPoint
      }
    },
  })

  labelMarkerStylesCache.set(labelCacheId, style)
  return style
}

function getGeofenceTypeIcon(type: uui.domain.ui.map.markers.Geofence['type']) {
  switch (type) {
    case 'arriving':
      return '&#8592;'
    case 'departing':
      return '&#8594;'
    default:
      return '&#8644;'
  }
}
