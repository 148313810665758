import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function SingleLayout(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <rect x="5" y="1" width="10" height="14" rx="1" />
        <path d="M1 2C1 1.44772 1.44772 1 2 1C2.55228 1 3 1.44772 3 2V14C3 14.5523 2.55228 15 2 15C1.44772 15 1 14.5523 1 14V2Z" />
      </svg>
    </IconContainer>
  )
}
