import { Tooltip } from '@/components/primitives/Tooltip'

import { Row } from '../Row'
import { useTexts } from './useTexts'

interface Props {
  snapshot: uui.domain.client.rm.PlanSummary
}

export function Cost(props: Props) {
  const { snapshot } = props
  const texts = useTexts()

  const {
    route: { costs },
  } = snapshot

  return (
    <Tooltip textAlign="left" placement="right" title={texts.costs} subtitle={texts.costsTooltip}>
      <Row text={texts.costs} value={texts.costsValue(costs / 100)} />
    </Tooltip>
  )
}
