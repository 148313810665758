import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Content } from './Content'

import { FormProvider } from './FormProvider'

export function Root() {
  return (
    <Modal modalId={modalId}>
      <FormProvider>
        <Content />
      </FormProvider>
    </Modal>
  )
}
