import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    on: translate({ id: 'global.on' }),
    empty: translate({ id: 'global.empty' }),
    style: translate({ id: 'rm.contextual.styleOnMap' }),
    notAvailableInThePast: translate({ id: 'rm.contextual.vehicles.notAvailableInThePast' }),

    routesCounter: (count: number) =>
      count > 0
        ? translate({ id: 'global.counter.routes', values: { count } })
        : translate({ id: 'global.unavailable' }),

    disabledLoadingGpsData: translate({ id: 'global.gpsLoading.disableEdit' }),
    disabledNoDevice: (vehicleName: string) =>
      translate({ id: 'rm.contextual.vehicles.noLinkedDevice', values: { vehicleName } }),
  }))

  return api
}
