import { useEffect, useState } from 'react'

import { getTravelledDistance } from '@/features/domain/route'
import { store } from '@/store'

type TravelledDistance =
  | {
      status: 'ok'
      value: number
    }
  | {
      status: 'loading'
    }

async function computeTravelledDistance(
  routeId: string,
  setTravelledDistance: (value: TravelledDistance) => void,
) {
  const result = await store.dispatch(getTravelledDistance(routeId))

  if (getTravelledDistance.rejected.match(result)) {
    throw new Error(result.payload?.message ?? 'Internal error')
  }

  setTravelledDistance({ status: 'ok', value: result.payload })
}

export function useComputeTravelledDistance(routeId: string) {
  const [travelledDistance, setTravelledDistance] = useState<TravelledDistance>({
    status: 'loading',
  })

  useEffect(() => {
    computeTravelledDistance(routeId, setTravelledDistance)
  }, [routeId])

  return travelledDistance
}
