import { Page404 } from './pages/Page404'
import { Page500 } from './pages/Page500'
import { LoggedOut } from './pages/LoggedOut'
import { GenericPage } from './pages/GenericPage'
import { InvalidResetPasswordToken } from './pages/InvalidResetPasswordToken'

interface Props {
  type:
    | '404'
    | '500'
    | 'generic'
    | 'unknown'
    | 'logged-out'
    | 'unsupported'
    | 'invalid-reset-password-token'
}

export function ErrorPageContent(props: Props) {
  switch (props.type) {
    case '404':
      return <Page404 />

    case '500':
      return <Page500 />

    case 'logged-out':
      return <LoggedOut />

    case 'invalid-reset-password-token':
      return <InvalidResetPasswordToken />

    case 'generic':
    case 'unsupported':
    case 'unknown':
      return <GenericPage />

    default:
      return null
  }
}
