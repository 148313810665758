import type { MovePairedOrdersPayload } from '../typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderSteps } from '@/features/domain/order'
import { selectVehicles } from '@/features/domain/vehicle'
import { selectRoutes } from '@/features/domain/route'
import { selectRoutesInfo } from '@/features/domain/scheduler'

import { computeModalDataValues } from '../utils/computeModalDataValues'

export const useControllerData = (payload: MovePairedOrdersPayload) => {
  const ordersMap = useSelector(selectOrderSteps)
  const vehiclesMap = useSelector(selectVehicles)
  const routesMap = useSelector(selectRoutes)
  const routesInfo = useSelector(selectRoutesInfo)

  return useMemo(() => {
    const { orderStepId, pairedOrderStepId, sourceRouteId } = payload

    const orderStep = ordersMap[orderStepId]?.orderStep
    const order = ordersMap[orderStepId]?.order
    const extendedPairedOrderStep = ordersMap[pairedOrderStepId]

    const vehicleId = routesInfo[sourceRouteId]?.unifiedVehicleId
    const date = routesInfo[sourceRouteId]?.dateAsString

    const unifiedVehicle = vehiclesMap[vehicleId]
    const vehicle = unifiedVehicle?.hasRoutingLicense ? unifiedVehicle.vehicle : undefined

    const pairedRoute = extendedPairedOrderStep
      ? extendedPairedOrderStep.isUnassigned
        ? undefined
        : routesMap[extendedPairedOrderStep.routeId]
      : undefined

    return computeModalDataValues(
      payload,
      date,
      order,
      orderStep,
      extendedPairedOrderStep.orderStep,
      vehicle,
      pairedRoute?.route,
    )
  }, [payload, ordersMap, vehiclesMap, routesMap, routesInfo])
}
