import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, resetCrudSelection } from '@/atoms'
import { selectEventInfosListStructure } from '@/features/domain/lists'

export function useTitle() {
  const listStructure = useSelector(selectEventInfosListStructure)

  const [selection, select] = useCrudSelection('deviceEvents')
  const editing = false

  const onSelectAll = useCallback(() => {
    const allSelected = listStructure.shownItems <= selection.length

    if (allSelected) {
      resetCrudSelection('deviceEvents')
    } else {
      select(
        listStructure.list.reduce<string[]>((acc, item) => {
          if (typeof item === 'string') {
            acc.push(item)
          }
          return acc
        }, []),
      )
    }
  }, [select, selection, listStructure])

  const onGoBack = useCallback(() => {
    window.history.back()
  }, [])

  return {
    editing,
    selection,
    onSelectAll,
    listStructure,
    onGoBack,
  }
}
