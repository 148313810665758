import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

type OrderFeatureMetadata = {
  mode: 'on' | 'off' | 'maximized'
  type: 'order'
  ids: Record<string, true>
  selected: boolean
}

export const setOrderFeatureMetadata = <Field extends keyof OrderFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
  value: OrderFeatureMetadata[Field],
) => {
  target.set(field, value)
}

export const getOrderFeatureMetadata = <Field extends keyof OrderFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
): OrderFeatureMetadata[Field] | undefined => {
  return target.get(field)
}

export const removeOrderFeatureMetadata = <Field extends keyof OrderFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
) => {
  target.unset(field)
}
