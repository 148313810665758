import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    placeholder: translate({ id: 'orders.form.filterPlaceholder' }),
    results: (count: number) => translate({ id: 'global.counter.orders', values: { count } }),
  }))

  return api
}
