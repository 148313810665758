import { useCallback } from 'react'
import { useIntl } from '@/intl'

export function useRevokeButtonTitle() {
  const { translate } = useIntl()

  return useCallback(
    (routesCount: number, dispatchedRoutesCount: number, canBeRevoked?: boolean) => {
      const disabled = !canBeRevoked

      if (disabled) {
        return translate({ id: 'dispatchPanel.tooltips.revoke.disabled' })
      }

      if (dispatchedRoutesCount !== routesCount) {
        return translate({
          id: 'dispatchPanel.tooltips.partial.revoke',
          values: { count: dispatchedRoutesCount },
        })
      }

      return translate({ id: 'dispatchPanel.tooltips.revoke.enabled' })
    },
    [translate],
  )
}
