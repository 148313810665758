import { intl } from '@/intl'

export const getRequiredTagsText = (): string => intl.translate({ id: 'global.requiredTags' })

export const getBannedTagsText = (): string => intl.translate({ id: 'global.bannedTags' })

export const getEligibilityText = (): string => intl.translate({ id: 'global.eligibility' })

export const getTimeWindowExceptionsAndAlternatives = (): string =>
  intl.translate({ id: 'global.timeWindowExceptionsAndAlternatives' })

export const getLoadsText = (): string => intl.translate({ id: 'global.loads' })

export const getNotesText = (): string => intl.translate({ id: 'global.notes' })

export const getImportanceText = (): string => intl.translate({ id: 'global.importance' })

export const getServiceTimeText = (): string => intl.translate({ id: 'global.serviceTime' })

export const getTimeWindowsText = (): string => intl.translate({ id: 'global.timeWindows' })

export const getTypeText = (): string => intl.translate({ id: 'global.type' })

export const getPickupText = (): string => intl.translate({ id: 'global.pickup' })

export const getDeliveryText = (): string => intl.translate({ id: 'global.delivery' })

export const getPickupDeliveryText = (): string => intl.translate({ id: 'global.pickupDelivery' })

export const getServiceText = (): string => intl.translate({ id: 'global.service' })

export const getNoopText = (): string => ''

export const getCompanyText = (): string =>
  intl.translate({ id: 'orders.viewDetails.label.company' })
