import { useMemo } from 'react'
import { useTexts } from './useTexts'

export function useRadioGroupValues() {
  const texts = useTexts()

  return useMemo(() => {
    return [
      {
        label: texts.useOdometer,
        value: 'useOdometer',
      },
      {
        label: texts.ignoreOdometer,
        value: 'ignoreOdometer',
      },
    ]
  }, [texts])
}
