import { useCallback } from 'react'

import { resetCrudSelection } from '@/atoms'
import { useSettingsEditing } from '../../../../../../../../hooks/useSettingsEditing'

export function useActions() {
  const { editing, onEdit } = useSettingsEditing('user-management', 'none')

  const onCreate = useCallback(() => {
    resetCrudSelection('users')
    onEdit()
  }, [onEdit])

  return { onCreate, editing }
}
