import type { ExtraLayoutRendererProps } from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import type { DeviceChip } from '../../../components/Navigator/typings'

import { useCallback } from 'react'

import { VerticalLayout } from '@/components/layout'

import CollapsedLayoutRenderer from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import { Navigator } from '../../../components/Navigator'

import { useDevicesNavigator } from '../hooks/useDevicesNavigator'
import { DeviceItemRenderer } from './DeviceItemRenderer'

const renderProps: ExtraLayoutRendererProps = {
  chipShape: 'rounded',
}

export function DevicesNavigator() {
  const { chips, page, setPage } = useDevicesNavigator()

  const onChangeIndex = useCallback(index => setPage(index), [setPage])

  return (
    <VerticalLayout
      data-trackid="navigo-device-navigator"
      data-testid="navigo-device-navigator"
      justifyContent="center"
      width="auto"
      height={46}
    >
      <Navigator<DeviceChip, ExtraLayoutRendererProps, Record<string, any>>
        items={chips}
        selectedIndex={page}
        onChangeIndex={onChangeIndex}
        ItemRenderer={DeviceItemRenderer}
        extraItemRendererProps={renderProps}
        extraLayoutRendererProps={renderProps}
        LayoutRenderer={CollapsedLayoutRenderer}
      />
    </VerticalLayout>
  )
}
