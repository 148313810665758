import { useEditing } from '../../hooks/useEditing'

import { CreateTrafficProfile } from './components/CreateTrafficProfile'
import { EditTimeWindows } from './components/EditTimeWindows'
import { List } from './components/List'

export function TrafficProfiles() {
  const { editingEntity } = useEditing('trafficProfile')

  switch (editingEntity) {
    case 'create-traffic-profile':
      return <CreateTrafficProfile />

    case 'edit-time-windows':
      return <EditTimeWindows />

    default:
      return <List />
  }
}
