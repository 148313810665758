import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../../hooks/useController'

import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'

export function Footer() {
  const { close } = useController()

  const texts = useTexts()
  const submit = useOnSubmit()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          color="error"
          variant="contained"
          onClick={submit}
          maxWidth="50%"
          testid="settings-extract-map-warning-ok-button"
        >
          {texts.ok}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          variant="text"
          onClick={close}
          testid="settings-extract-map-warning-cancel-button"
        >
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}
