import type { ExecutionEventsFormValues } from '../../typings'

import { Stack } from '@mui/material'

import { DynamicLoadField } from './DynamicLoadField'
import { ReadonlyLoadField } from './ReadonlyLoadField'
import { useTexts } from './useTexts'

interface Props {
  value: ExecutionEventsFormValues['dynamicLoads']
  error?: Record<string, string>
  onChange: (key: string, value: number) => void
}

export function DynamicLoads(props: Props) {
  const { value, onChange, error } = props
  const texts = useTexts()

  const entries = Object.entries(value)

  return (
    <Stack spacing={1} width="100%">
      {entries.map(([label, load]) => {
        if (load.disabled) {
          return <ReadonlyLoadField key={label} label={label} value={texts.undeclared} />
        }
        return (
          <DynamicLoadField
            key={label}
            label={label}
            value={load.value}
            error={error?.[label]}
            onChange={onChange}
            testId={`dynamic-load-${label}`}
          />
        )
      })}
    </Stack>
  )
}
