import { TrafficAreasViewType } from './TrafficAreasViewType'
import { ListOfTrafficAreas } from './components/ListOfTrafficAreas'
import { useTrafficProfile } from './hooks/useTrafficProfile'
import { useEnsureTrafficRegionVisibility } from './hooks/useEnsureTrafficRegionVisibility'

export function TrafficAreas() {
  const trafficProfile = useTrafficProfile()

  useEnsureTrafficRegionVisibility()

  if (!trafficProfile) {
    return null
  }

  return (
    <>
      <ListOfTrafficAreas trafficProfile={trafficProfile} />
      <TrafficAreasViewType trafficProfile={trafficProfile} />
    </>
  )
}
