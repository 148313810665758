import { useFormState } from 'react-final-form'
import { DurationStepField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

const wrappedInputProps = {
  step: 10,
  min: 0,
  max: 23 * 60 + 59,
}

function secToMin(seconds: number) {
  return Math.round(seconds / 60)
}

function minToSec(minutes: number) {
  return minutes * 60
}

export function CloseOutTime() {
  const texts = useTexts()
  const {
    values: { arrivalDepotId },
  } = useFormState()

  if (arrivalDepotId === 'disabledDepotValue') return null

  return (
    <DurationStepField
      name="closeOutTimeSec"
      testid="closeOutTime"
      half
      info={texts.closeOutTimeInfo}
      label={texts.closeOutTime}
      parse={minToSec}
      format={secToMin}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
