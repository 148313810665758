import { useCallback, useMemo, useState } from 'react'

import { validateDriverDelete } from '@/features/domain/driver'
import { resetCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { useAppDispatch } from '@/store'

import { useDeleteDriverModal } from '../../../modals/DeleteDriver'

import { useTexts } from './useTexts'

export function useViewHeaderProps(driverIds: string[]) {
  const texts = useTexts()
  const dispatch = useAppDispatch()
  const { show: showDeleteModal } = useDeleteDriverModal()
  const { setEditing } = useEditingStateWithCtx('driver')

  const [validatingDelete, setValidatingDelete] = useState<boolean>(false)
  const [deleteValidationResult, setDeleteValidationResult] = useState<
    uui.domain.server.rm.DeleteDriverValidationResults | undefined
  >()

  const entityCount = driverIds.length

  const onEdit = useCallback(() => setEditing(driverIds), [setEditing, driverIds])

  const onClose = useCallback(() => {
    resetCrudSelection('drivers')
  }, [])

  const textsToExport = useMemo(() => {
    return {
      title: texts.title(entityCount),
    }
  }, [entityCount, texts])

  const onDelete = useCallback(async () => {
    setValidatingDelete(true)

    const result = await dispatch(validateDriverDelete(driverIds))

    if (validateDriverDelete.rejected.match(result)) {
      throw new Error(result.payload?.message ?? 'Internal error')
    }

    setDeleteValidationResult(result.payload)
    setValidatingDelete(false)
    showDeleteModal()
  }, [dispatch, driverIds, showDeleteModal])

  return {
    onDelete,
    onClose,
    onEdit,
    texts: textsToExport,
    validatingDelete,
    deleteValidationResult,
  }
}
