import styled from 'styled-components'

const StepButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: block;
  position: relative;
  margin: 0;
  border: 0;
  width: 100%;
  height: 10px;

  padding: 0;
  outline: 0;
  border-width: 0;

  line-height: 1;

  background: ${p => p.theme.colors.$whiteSmoke};
  color: ${p => p.theme.colors.$nightRider};

  transition: 0.3s ease;
  transition-property: background, color;
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
    background: ${p => p.theme.colors.$whiteSmoke};
    color: ${p => p.theme.colors.$silver};
  }
`

StepButton.displayName = 'StepButton'
export default StepButton
