import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    confirmPanelTitle: translate({
      id: 'setup.vehicleAssignments.delete.title',
      values: { count: 1 },
    }),
    confirmPanelSubtitle: translate({ id: 'setup.vehicleAssignments.delete.subtitle' }),

    delete: translate({ id: 'global.delete' }),
    edit: translate({ id: 'global.edit' }),

    recurrenceDescription: (type: string) => {
      switch (type) {
        case 'DATE':
          return translate({ id: 'setup.vehicleAssignments.list.type.date' })

        case 'WEEKLY':
          return translate({ id: 'setup.vehicleAssignments.list.type.weekly' })

        case 'MONTHLY':
          return translate({ id: 'setup.vehicleAssignments.list.type.monthly' })

        case 'ANNUAL':
          return translate({ id: 'setup.vehicleAssignments.list.type.annual' })

        case 'any':
          return translate({ id: 'setup.vehicleAssignments.list.type.default' })

        default:
          return ''
      }
    },
  }))

  return api
}
