import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    badges: translate({ id: 'vehicles.form.badges' }),
    minutes: (value: number) => translate({ id: 'global.plural.short.minutes', values: { value } }),
    idleStartLimit: translate({
      id: 'vehicles.form.driverBehaviors.idleStartLimit',
    }),
    odometer: translate({
      id: 'vehicles.form.edit.generalSettings.fields.maintenanceMileage.useOdometer',
    }),
    ignoreOdometer: translate({
      id: 'vehicles.form.edit.generalSettings.fields.maintenanceMileage.ignoreOdometer',
    }),
    maintenanceMileage: translate({
      id: 'vehicles.form.edit.generalSettings.fields.maintenanceMileage.label',
    }),
    speedMeasure: (metric?: boolean) =>
      translate({
        id: metric ? 'global.unitOfMeasure.kmh' : 'global.unitOfMeasure.mph',
      }),
    postedSpeedLimit: (enabled?: boolean) =>
      translate({
        id: enabled
          ? 'vehicles.form.driverBehaviors.postedSpeedLimit'
          : 'vehicles.form.driverBehaviors.postedSpeedLimit.disabled',
      }),
    maxSpeed: (enabled?: boolean) =>
      translate({
        id: enabled
          ? 'vehicles.form.driverBehaviors.maxSpeed'
          : 'vehicles.form.driverBehaviors.maxSpeed.disabled',
      }),
  }))

  return api
}
