import { useCallback } from 'react'
import { format } from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { useIntl } from '@/intl'
import { timeUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export function useTitle() {
  const { translate } = useIntl()

  const userConfig = useSelector(selectUserConfiguration)

  return useCallback(
    (date: Date, isToday?: boolean, isTomorrow?: boolean, inProgress?: boolean) => {
      const formatDateTime = timeUtils.formatDateTime(userConfig)
      const formattedDate = formatDateTime(date, true)

      let result = ''

      if (isToday) {
        result = `${translate({ id: 'dispatchPanel.date.today' })}, ${formattedDate}`
      } else if (isTomorrow) {
        result = `${translate({ id: 'dispatchPanel.date.tomorrow' })}, ${formattedDate}`
      } else {
        result = `${format(date, 'eee')}, ${formattedDate}`
      }

      if (inProgress) {
        result += ` (${translate({ id: 'dispatchPanel.cards.inProgress' })})`
      }

      return result
    },
    [userConfig, translate],
  )
}
