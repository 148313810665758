import styled from 'styled-components'
import Field from './Field'

const LABEL_INNER_GUTTER = 6
const LABEL_BOTTOM_SPACE = 6
export const INFO_BOTTOM = -2

export const BOTTOM_SPACE_FOR_MESSAGE = 16

const StyledField = styled(Field)`
  position: relative;
  padding-bottom: ${BOTTOM_SPACE_FOR_MESSAGE}px;
  width: 100%;

  &.label-after {
    display: flex;
  }

  &.label-after .o-field__label-wrapper {
    order: 2;
    margin-left: 5px;
  }

  .o-field__label-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${LABEL_BOTTOM_SPACE}px;
  }

  .o-field__label-wrapper > * + * {
    margin-left: ${LABEL_INNER_GUTTER}px;
  }

  .o-field__info {
    position: absolute;
    height: ${BOTTOM_SPACE_FOR_MESSAGE}px;
    bottom: ${INFO_BOTTOM}px;
    left: 0;
    width: 100%;
  }

  .o-field__info.is-error-message > * {
    color: ${p => p.theme.colors.$outrageousRed};
  }
`

StyledField.displayName = 'StyledField'

export default StyledField
