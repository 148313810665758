import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    newTrafficArea: translate({ id: 'setup.traffic.createNew' }),
    importKML: translate({ id: 'global.importKML' }),
    entitiesCount: (selected: number, count: number): string =>
      translate({
        id:
          selected > 0
            ? 'setup.trafficAreas.list.footerCountWithSelection'
            : 'setup.trafficAreas.list.footerCount',
        values: { count, selected },
      }),
    selectBtn: (allSelected: boolean): string =>
      translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' }),

    importKMLFailed: translate({ id: 'global.importKML.failed' }),
    noEntries: {
      title: translate({ id: 'trafficAreas.list.noEntries.title' }),
      description: translate({ id: 'trafficAreas.list.noEntries.description' }),
      action: translate({ id: 'trafficAreas.list.noEntries.action' }),
    },
    noResults: {
      title: translate({ id: 'trafficAreas.list.noResults.title' }),
      description: translate({ id: 'trafficAreas.list.noResults.description' }),
    },
  }))

  return api
}
