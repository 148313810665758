import { produceWithPatches } from 'immer'

import { computeTags } from '../computeTags'
import { patchSpeedMonitor } from '../patchSpeedMonitor'
import { areGpsTagsChanged } from '../areGpsTagsChanged'
import { patchDriverBehaviors } from '../patchDriverBehaviors'
import { patchDeviceConfiguration } from '../patchDeviceConfiguration'
import { computeIdleStartThreshold } from '../computeIdleStartThreshold'

export function patchRm360DataWwgps(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  formValues: uui.domain.ui.forms.Rm360VehicleFormValues,
  deviceAggregateInfo: Record<string, uui.domain.client.gps.wwgps.DeviceAggregateInfo>,
  metric: boolean,
) {
  if (!unifiedVehicle.hasRoutingLicense) {
    throw new Error('routing license is required to patch rm data')
  }

  return produceWithPatches(unifiedVehicle, draft => {
    const deviceId = formValues.rm.deviceId

    // update vehicle data
    draft.vehicle.color = formValues.rm.color.color
    draft.vehicle.externalId = formValues.rm.name
    draft.vehicle.gpsDeviceId = deviceId
    draft.vehicle.tracked = formValues.rm.eventTracking
    draft.vehicle.edgeOverride = formValues.rm.liveEta ? 'GPS' : 'ROUTESTEP'

    // note @ts-expect-error are required, ts cannot infer the type correctly
    if (deviceId) {
      // update device data
      draft.isPhysicalDevice = true

      if (!deviceAggregateInfo[deviceId]) {
        throw new Error(`Can not find device with id: ${deviceId}`)
      }
      // @ts-expect-error
      draft.device = deviceAggregateInfo[`${deviceId}`].device

      if (formValues.device.hasDriverBehaviorCapability) {
        // @ts-expect-error
        draft.driverBehaviors = patchDriverBehaviors(
          // @ts-expect-error
          draft.driverBehaviors,
          formValues.device,
          metric,
        )
      }

      // @ts-expect-error
      draft.speedMonitor = patchSpeedMonitor(draft.speedMonitor, formValues.device, deviceId)
      // @ts-expect-error
      draft.idleStartThreshold = computeIdleStartThreshold(formValues.device)
      // @ts-expect-error
      draft.deviceConfiguration = patchDeviceConfiguration(
        // @ts-expect-error
        draft.deviceConfiguration,
        formValues.device,
      )
      if (draft.allowGps) {
        const nextTags = computeTags(formValues.device)
        if (areGpsTagsChanged(draft.gpsTags, nextTags)) {
          draft.gpsTags = nextTags
        }
      }
    } else {
      // delete all the device related fields
      draft.isPhysicalDevice = false
      // @ts-expect-error
      delete draft.device
      // @ts-expect-error
      delete draft.deviceIconId
      // @ts-expect-error
      delete draft.driverBehaviors
      // @ts-expect-error
      delete draft.idleSettings
      // @ts-expect-error
      delete draft.idleStartThreshold
      // @ts-expect-error
      delete draft.speedMonitor
      // @ts-expect-error
      delete draft.deviceConfiguration
    }

    if (draft.isPhysicalDevice) {
      draft.trackingProvider = 'WWGPS'
    }
  })
}
