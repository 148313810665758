import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderSteps } from '@/features/domain/order'

export const useSelectedOrderSteps = (selectedOrderStepIds: string[]) => {
  const extOrderSteps = useSelector(selectOrderSteps)

  const selectedOrderSteps = useMemo(
    () => selectedOrderStepIds.map(orderStepId => extOrderSteps[orderStepId]),
    [extOrderSteps, selectedOrderStepIds],
  )

  // Retrieves all paired orders not already selected
  const pairedOrderSteps = useMemo(
    () =>
      selectedOrderSteps.reduce<uui.domain.client.rm.ExtendedOrderStep[]>((list, extOrderStep) => {
        if (extOrderStep.type !== 'pd') return list

        const pairedOrderStepId =
          extOrderStep.orderStepType === 'p' ? extOrderStep.delivery?.id : extOrderStep.pickup?.id

        if (!pairedOrderStepId) return list

        const pairedNotFound = !selectedOrderSteps.some(
          extOrderStep => extOrderStep.id === pairedOrderStepId,
        )

        // If the paired order is not selected is not unassigned
        if (pairedNotFound && !extOrderSteps[pairedOrderStepId].isUnassigned) {
          list.push(extOrderSteps[pairedOrderStepId])
        }

        return list
      }, []),
    [extOrderSteps, selectedOrderSteps],
  )

  return { selectedOrderSteps, pairedOrderSteps } as const
}
