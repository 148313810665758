import type { RegionFormValues } from './typings'

import { useMemo } from 'react'
import { FormApi } from 'final-form'
import { useSelector } from 'react-redux'

import {
  FormLayout,
  TextField,
  ColorPickerField,
  NumericStepperField,
  DurationStepField,
  FieldMessage,
  DrawAreaField,
} from '@/forms-legacy'
import { selectUserConfiguration } from '@/features/domain/user'
import { colorPalette } from '@/server-data'

import { useTexts } from './hooks/useTexts'

const secToMin = (seconds: number): number => Math.round(seconds / 60)
const minToSec = (minutes: number): number => minutes * 60

const durationStepFieldWrappedProps = {
  min: 0,
  step: 1,
  useSnap: false,
  children: 'hh:mm',
} as const

type Props = {
  formApi: FormApi<RegionFormValues, Partial<RegionFormValues>>
}
export function Form(props: Props) {
  const { formApi } = props
  const activeColor = formApi.getState().values.color

  const texts = useTexts()
  const { currency } = useSelector(selectUserConfiguration)

  const enterCostWrappedProps = useMemo(
    () => ({
      step: 1,
      min: 0,
      canBeNaN: false,
      unit: currency,
    }),
    [currency],
  )

  const colorPickerWrappedProps = useMemo(
    () => ({
      colors: colorPalette,
      openText: texts.cancel.toUpperCase(),
      closedText: texts.change.toUpperCase(),
    }),
    [texts],
  )

  return (
    <FormLayout>
      <FieldMessage>{texts.editTitle}</FieldMessage>
      <TextField testid="region-name" name="name" label={texts.labelName} half />
      <ColorPickerField
        name="color"
        label={texts.labelColor}
        testid="region-color"
        wrappedInputProps={colorPickerWrappedProps}
        half
      />

      <NumericStepperField
        name="enterCost"
        testid="region-enterCost"
        label={texts.labelCost}
        wrappedInputProps={enterCostWrappedProps}
        half
      />

      <DurationStepField
        name="enterTimeSec"
        testid="region-enterTimeSec"
        label={texts.labelTime}
        parse={minToSec}
        format={secToMin}
        wrappedInputProps={durationStepFieldWrappedProps}
        half
      />

      <DrawAreaField
        name="points"
        label={'Shape'}
        editableAreaId="regionArea"
        editableAreaColor={activeColor}
      />
    </FormLayout>
  )
}
