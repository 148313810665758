import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, resetCrudSelection, useEditingStateWithCtx, useIsLoading } from '@/atoms'
import { selectDriverAssignmentsListStructure } from '@/features/domain/lists'

export const useDataForList = () => {
  const { setEditing } = useEditingStateWithCtx('driversAssignment')
  const structure = useSelector(selectDriverAssignmentsListStructure)
  const [selection, setSelection] = useCrudSelection('driverAssignments')
  const isLoading = useIsLoading('fetchGpsData')

  const lastSelectedDriverAssignment =
    selection.length > 0 ? selection[selection.length - 1] : undefined

  const setCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  const deselectAll = () => resetCrudSelection('driverAssignments')

  return {
    isLoading,
    structure,
    setCreate,
    setEditing,
    lastSelectedDriverAssignment,
    selectDriverAssignments: setSelection,
    deselectAll,
  }
}
