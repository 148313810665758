import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { produce } from 'immer'

import { useAppDispatch } from '@/store'
import { isDeepEqual } from '@/server-data'
import { setListOptions } from '@/features/domain/lists/actions'
import { selectRoadSegmentsListOptions } from '@/features/domain/lists'

type RoadSegmentFilterStatus = {
  type: 'all' | 'blocked' | 'delayed' | 'disabled'
}

const filterValuesByRoadSegmentType = {
  all: ['blocked', 'delayed', 'disabled'],
  blocked: ['blocked'],
  delayed: ['delayed'],
  disabled: ['disabled'],
}

export function useFilterList() {
  const dispatch = useAppDispatch()
  const listOptions = useSelector(selectRoadSegmentsListOptions)

  const setFilter = useCallback(
    async (filter: { type: RoadSegmentFilterStatus['type'] }) => {
      const nextOptions = produce(listOptions, draft => {
        draft.filter = [
          {
            field: 'type',
            values: filterValuesByRoadSegmentType[filter.type],
            operator: 'or',
          },
        ]
      })

      // avoid triggering any calculations in case the group didn't change
      if (nextOptions === listOptions) return

      const request = await dispatch(
        setListOptions({ category: 'roadSegments', options: nextOptions }),
      )
      return setListOptions.fulfilled.match(request)
    },
    [dispatch, listOptions],
  )

  return {
    filter: listOptions.filter.reduce((acc, { values }, index) => {
      switch (index) {
        case 0:
          acc.type = getRoadSegmentStepsType(values)
          break
      }

      return acc
    }, {} as RoadSegmentFilterStatus),
    setFilter,
  }
}

function getRoadSegmentStepsType(
  filterValues: string[] | number[] | boolean[],
): RoadSegmentFilterStatus['type'] {
  const key = Object.keys(filterValuesByRoadSegmentType).find(roadSegmentsType => {
    const values = filterValuesByRoadSegmentType[roadSegmentsType]

    /*
      filterValues could [true,false] or [false,true], in both cases we need to match it with the
      values of filterValuesByRoadSegmentsType
    */
    return isDeepEqual([...filterValues].sort(), values)
  })
  if (!key && process.env.NODE_ENV === 'development') {
    throw new Error(`No driver assignment type for ${filterValues}`)
  }

  return (key ?? '') as RoadSegmentFilterStatus['type']
}
