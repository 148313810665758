import { ResourceModel } from '@bryntum/schedulerpro'
import { schedulerPreferencesAtom } from '../../schedulerPreferences'

export function ordersNotCompletedSorter(item1: ResourceModel, item2: ResourceModel) {
  const ordersExecutedReschedule1 = item1.getData(
    'ordersExecutedReschedule',
  ) as uui.domain.client.rm.SchedulerResource['ordersExecutedReschedule']

  const ordersExecutedReschedule2 = item2.getData(
    'ordersExecutedReschedule',
  ) as uui.domain.client.rm.SchedulerResource['ordersExecutedReschedule']

  if (schedulerPreferencesAtom.preferences.showAbsoluteValues) {
    return ordersExecutedReschedule1.absolute - ordersExecutedReschedule2.absolute
  }

  return ordersExecutedReschedule1.percentage - ordersExecutedReschedule2.percentage
}
