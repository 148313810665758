import type { FormFields, FormErrors } from '../hooks/formFields'
import { useFormMeta } from '@workwave-tidal/tidal/form-fairy'
import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Footer() {
  const { onConfirm } = useControllerActions()
  const { close } = useController()
  const texts = useTexts()

  // Connect the Footer to the active Form
  const { meta } = useFormMeta<FormFields, FormErrors>()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          testid="modal__button-showData"
          disabled={meta.submitting}
          loading={meta.submitting}
          onClick={onConfirm}
          variant="contained"
        >
          {texts.showData}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton onClick={close} disabled={meta.submitting}>
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}
