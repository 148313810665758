import { RadioGroupValues } from '../HorizontalRadioGroup'

import * as LocalTexts from './intl'

export const getRadioGroupTypes = (): RadioGroupValues => [
  {
    label: LocalTexts.getFromOperationsText(),
    value: 'fromOperations',
    className: 'pendo-simulation__import-from-operation',
  },
  {
    label: LocalTexts.getFromArchivedText(),
    value: 'fromArchived',
    className: 'pendo-simulation__import-from-archived',
  },
  {
    label: LocalTexts.getFromScratchText(),
    value: 'fromScratch',
    className: 'pendo-simulation__start-from-scratch',
  },
]
