import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    ordersText: translate({ id: 'global.orders' }),
    copyAllToOperations: translate({ id: 'global.copyAllToOperations' }),
    mixedAddressesText: translate({ id: 'orders.mixedAddresses' }),
    duplicateAll: translate({ id: 'global.duplicateAll' }),
    exportAll: translate({ id: 'global.exportAll' }),
    unassignAll: translate({ id: 'global.unassignAll' }),
    unassign: translate({ id: 'global.unassign' }),
    fitIn: translate({ id: 'rm.contextual.order.fitIn' }),
    fitInAll: translate({ id: 'rm.contextual.order.fitInAll' }),
    deleteAll: translate({ id: 'global.deleteAll' }),
    editAll: translate({ id: 'global.editAll' }),
  }))

  return api
}
