import { PureComponent } from 'react'
import { Field as FinalFormField, FieldRenderProps } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import DrivingTimeSliderField from './DrivingTimeSliderField'
import { PublicProps as Props } from './typings'

export default class DrivingTimeSliderFieldWrapper extends PureComponent<Props> {
  render() {
    const { name, ...props } = this.props

    return (
      <FinalFormField
        {...props}
        name={name}
        isEqual={isDeepEqual}
        render={(formProps: FieldRenderProps<any>) => {
          return (
            <DrivingTimeSliderField
              {...this.props}
              formProps={formProps}
              testid="traffic-area-driving-time-slider-field"
            />
          )
        }}
      />
    )
  }
}
