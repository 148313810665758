import { useCallback, useState } from 'react'

export function useInteractions(
  value: string,
  validateOnFocus: () => void,
  validateOnBlur: () => void,
) {
  const [mouseInside, setMouseInside] = useState(false)
  const [focus, setFocus] = useState(false)

  const onMouseEnter = useCallback(() => {
    setMouseInside(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setMouseInside(false)
  }, [])

  const onFocus = useCallback(() => {
    validateOnFocus()
    setFocus(true)
    setMouseInside(true)
  }, [validateOnFocus])

  const onBlur = useCallback(() => {
    validateOnBlur()
    setFocus(false)
    setMouseInside(false)
  }, [validateOnBlur])

  const adornmentVisible = (focus || mouseInside) && value.length > 0

  return {
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    adornmentHidden: !adornmentVisible,
  }
}
