import type { FormErrors, FormFields } from '../../../formFields'
import { useEffect } from 'react'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'

export function useConnectToAutomatedMessageEnabled() {
  const { field: statusField } = useFormField<'status', FormFields, FormErrors>('status')
  const { field: callbackConfigStatusField } = useFormField<
    'callbackConfigStatus',
    FormFields,
    FormErrors
  >('callbackConfigStatus')
  const { field, formApi } = useFormField<'automatedMessageEnabled', FormFields, FormErrors>(
    'automatedMessageEnabled',
  )

  useEffect(() => {
    const automatedMessageEnabled =
      field.value === 'ON' &&
      statusField.value === 'ADVANCED' &&
      callbackConfigStatusField.value === 'OFF'

    formApi.setFieldVisibleState('automatedMessage', automatedMessageEnabled)
    formApi.setFieldVisibleState('automatedMessageVoice', automatedMessageEnabled)
  }, [field, statusField, callbackConfigStatusField, formApi])
}
