import { useCallback } from 'react'

import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { clsx } from '@/utils'
import { Link, useGpsReportsLink } from '@/routing'

import { Tooltip } from '@/components/primitives/Tooltip'
import { DropdownMenuItem } from '@/components/DropdownMenu'

import { ActiveIcon } from '../ActiveIcon'

import { getSelectedDeviceIds } from '../../../../utils/getSelectedDeviceIds'
import { useTrackingDisabled } from '../../../../hooks/useTrackingDisabled'
import { useTrackingTooltip } from '../../../../hooks/useTrackingTooltip'
import { useTexts } from '../../../../useTexts'
import { useStyles } from '../hooks/useStyles'

import { useRm360Links } from './hooks/useRm360Links'

type MainSections = uui.routing.MainSections
type RoutingRoutes = uui.routing.Routing

const useFixStyles = makeStyles({
  fixLinkInsideTooltip: {
    '& a': {
      display: 'block',
      width: '100%',
    },
  },
})

export function Rm360Links() {
  const classes = useStyles()
  const fixStyles = useFixStyles()
  const texts = useTexts()
  const { canShow } = useRm360Links()
  const trackingTooltip = useTrackingTooltip()
  const trackingDisabled = useTrackingDisabled()
  const { gpsReportsLink, createGpsReportsCookie } = useGpsReportsLink()

  const onGpsReportClick = useCallback(() => {
    createGpsReportsCookie(getSelectedDeviceIds())
  }, [createGpsReportsCookie])

  return (
    <>
      <Link<RoutingRoutes>
        absolute
        to="home"
        data-testid="header__nav-home-button-link"
        className={clsx(classes.link, 'pendo-bluebar__home-menu')}
      >
        <DropdownMenuItem
          data-testid="header__nav-home-button-content"
          data-trackid="rm-360-header__nav-home-button-content"
        >
          <ActiveIcon />
          {texts.home}
        </DropdownMenuItem>
      </Link>

      <Link<RoutingRoutes>
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        className={clsx(classes.link, 'pendo-bluebar__orders-menu')}
      >
        <DropdownMenuItem
          data-testid="header__nav-orders-button-link"
          data-trackid="rm-360-header__nav-orders-button-link"
        >
          <ActiveIcon />
          {texts.orders}
        </DropdownMenuItem>
      </Link>

      {canShow.tracking && (
        <div className={fixStyles.fixLinkInsideTooltip}>
          <Tooltip placement="top" title={trackingTooltip} disabled={trackingTooltip.length === 0}>
            <Link<MainSections>
              to="tracking"
              disabled={trackingDisabled}
              data-testid="header__nav-tracking-button-link"
              className={clsx(classes.link, 'pendo-bluebar__tracking-list-menu')}
            >
              <DropdownMenuItem
                data-testid="header__nav-tracking-button-content"
                data-trackid="rm-360-header__nav-tracking-button-content"
                disabled={trackingDisabled}
              >
                <ActiveIcon />
                {texts.tracking}
              </DropdownMenuItem>
            </Link>
          </Tooltip>
        </div>
      )}

      {canShow.simulations && (
        <Link<RoutingRoutes>
          absolute
          to="simulations"
          data-testid="header__nav-simulations-button-link"
          className={clsx(classes.link, 'pendo-bluebar__simulations-list-menu')}
        >
          <DropdownMenuItem
            data-testid="header__nav-simulations-button-content"
            data-trackid="rm-360-header__nav-simulations-button-content"
          >
            <ActiveIcon />
            {texts.simulations}
          </DropdownMenuItem>
        </Link>
      )}

      {canShow.reports && (
        <Button
          href={gpsReportsLink}
          onClick={onGpsReportClick}
          target="_blank"
          data-testid="header__nav-reports-button-link"
          className={clsx(classes.link, 'pendo-bluebar__GPS-reports-menu')}
        >
          <DropdownMenuItem
            data-testid="header__nav-reports-button-content"
            data-trackid="rm-360-header__nav-reports-button-content"
          >
            <ActiveIcon />
            {texts.gpsReports}
          </DropdownMenuItem>
        </Button>
      )}
    </>
  )
}
