import { CircularProgress } from '@mui/material'

import { HorizontalLayout } from '@/components/layout'
import { ShowLabelVehicle, ShowVehicle, HideVehicle } from '@/icons'

import { NavigoIconButton } from '../../../../../../elements/NavigoIconButton'
import { useStylerData } from '../../../../hooks/useStylerData'

import { useActions } from '../../hooks/useActions'

import { ButtonsContainer } from './components/ButtonsContainer'
import { useTexts } from './useTexts'

interface Props {
  vehicleIds: string[]
}

export function Styler(props: Props) {
  const { vehicleIds } = props

  const { methodInExecution, actions } = useActions(vehicleIds)
  const { activeMapStyle } = useStylerData()
  const texts = useTexts()

  return (
    <HorizontalLayout
      data-trackid="navigo-vehicle-mapStyles-styler"
      data-testid="navigo-vehicle-mapStyles-styler"
      justifyContent="start"
      height={42}
    >
      <ButtonsContainer>
        <NavigoIconButton
          text={texts.show}
          onClick={actions.showVehicleLabels}
          active={activeMapStyle === 'label'}
          Icon={
            methodInExecution === 'showVehicleLabels' ? (
              <CircularProgress size={18} color="primary" />
            ) : (
              <ShowLabelVehicle size={16} />
            )
          }
          testid="navigo-vehicle-mapStyles-showVehicleLabelsButton"
        />

        <NavigoIconButton
          text={texts.reduce}
          onClick={actions.showVehicles}
          active={activeMapStyle === 'on'}
          Icon={
            methodInExecution === 'showVehicles' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <ShowVehicle size={16} />
            )
          }
          testid="navigo-vehicle-mapStyles-showVehiclesButton"
        />

        <NavigoIconButton
          text={texts.hide}
          onClick={actions.hideVehicles}
          active={activeMapStyle === 'off'}
          Icon={
            methodInExecution === 'hideVehicles' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <HideVehicle size={16} />
            )
          }
          testid="navigo-vehicle-mapStyles-hideVehiclesButton"
        />
      </ButtonsContainer>
    </HorizontalLayout>
  )
}
