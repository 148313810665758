import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
} from '@/forms-legacy'

import { cmsToKmh, cmsToMph } from '@/local/server-data/utils/conversion'

import { useTexts } from './useTexts'

interface Props {
  driverBehaviors: uui.domain.client.gps.wwgps.DriverBehaviorSettings
}

const maxSpeedDisabledValueInCms = 8986

function getMaxSpeedValue(valueInCms: number, metricFormat: boolean): number {
  const value = metricFormat ? cmsToKmh(valueInCms) : cmsToMph(valueInCms)
  return Math.round(value)
}

export function MaxSpeedLimit(props: Props) {
  const { driverBehaviors } = props
  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const threshold = driverBehaviors.speedThreshold.threshold || -1

  const disabled = threshold >= maxSpeedDisabledValueInCms
  const metricFormat = userConfig.distanceFormat === 'METRIC'

  const fieldValue =
    threshold === -1
      ? '-'
      : `${getMaxSpeedValue(threshold, metricFormat)} ${texts.speedMeasure(metricFormat)}`

  return (
    <ReadonlyBlock>
      <ReadonlyLabel empty={disabled} primary>
        {texts.maxSpeed(!disabled)}
      </ReadonlyLabel>
      {!disabled && (
        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>{fieldValue}</ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      )}
    </ReadonlyBlock>
  )
}
