import { useEffect } from 'react'

import { usePagination } from '@/atoms'

import { List } from './List'
import { BulkView } from './Bulk/View'
import { TabValues } from './typings'
import { BulkEdit } from './Bulk/Edit'
import { SingleView } from './Single/View'
import { useOrdersRoot } from './useOrdersRoot'
import EditOrderStep from './Single/Edit/OrderStep'
import EditExecutionEvents from './Single/Edit/ExecutionEvents'

export function OrdersRoot() {
  const { editing, orders, selectedOrders, selection, resetOrdersFilter } = useOrdersRoot()

  const bulk = selectedOrders.length > 1
  const single = selectedOrders.length === 1

  const { page, setPage } = usePagination('orderTab')

  const order = selectedOrders[0]

  useEffect(() => {
    return () => {
      // Set the order form to the first tab (configuration)
      setPage(0)
      resetOrdersFilter()
    }
  }, [setPage, resetOrdersFilter])

  return (
    <>
      <List editing={editing} orders={orders} selection={selection} tab={page as TabValues} />
      {single && !editing && order && (
        <SingleView order={order} tab={page as TabValues} setTab={setPage} selection={selection} />
      )}
      {bulk && !editing && <BulkView orders={selectedOrders} />}
      {editing && !bulk && (
        <>
          {page === 0 && <EditOrderStep order={order} tab={page} />}
          {page === 1 && order && <EditExecutionEvents order={order} tab={page} />}
        </>
      )}
      {editing && bulk && <BulkEdit orders={selectedOrders} />}
    </>
  )
}
