import { useMemo } from 'react'

export function useViewDetails(areas: uui.domain.client.rm.TrafficRegion[]) {
  return useMemo(() => {
    const levels = areas.map(area => area.level)
    const timeWindowsLevels = areas.map(area => area.timeWindowLevels)

    return {
      levels,
      timeWindowsLevels,
    }
  }, [areas])
}
