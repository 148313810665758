import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderStepsListOptions, setOrdersFilter } from '@/features/domain/lists'

import { useAppDispatch } from '@/store'
import { useDebounceText } from '@/formUi'

export function useFilterOrders() {
  const listOptions = useSelector(selectOrderStepsListOptions)
  const dispatch = useAppDispatch()

  const setQuery = useCallback(
    async (newValue: string) => {
      dispatch(
        setOrdersFilter({ ...listOptions, search: { ...listOptions.search, query: newValue } }),
      )
    },
    [dispatch, listOptions],
  )

  return useDebounceText(listOptions.search.query, setQuery, 300)
}
