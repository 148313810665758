import { useCallback, useEffect } from 'react'
import { useMachine } from '@xstate/react'

import { navigoVehiclesMachine } from './stateMachine'
import { usePermissions } from './usePermissions'

export function useVehiclesNavigoMachine() {
  const permissions = usePermissions()

  // ----------------------------------
  // INITIALIZATION
  // ----------------------------------
  const [stateMachine, send] = useMachine(navigoVehiclesMachine, {
    context: { permissions },
  })

  // ----------------------------------
  // ACTIONS
  // ----------------------------------
  const actions = {
    toggleExpanded: useCallback(() => send('TOGGLE_EXPANDED'), [send]),
    goToMapStyle: useCallback(() => send('GO_TO_MAP_STYLE'), [send]),
    reset: useCallback(() => send('RESET'), [send]),
  }

  // ----------------------------------
  // CONTEXT UPDATES
  // ----------------------------------
  useEffect(() => {
    send({ type: 'UPDATE_ACTION_PERMISSIONS', permissions })
  }, [send, permissions])

  // ----------------------------------
  // RETURN
  // ----------------------------------
  return {
    actions,
    currentState: stateMachine.value,
    data: {
      expanded: stateMachine.context.expanded,
      permissions: stateMachine.context.permissions,
    },
  }
}
