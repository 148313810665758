import type { Theme } from '@mui/material'

import { components } from './components'

//TODO: Should be removed once we will stop using makeStyles
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const MuiTheme = {
  components,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1630,
      xl: 1920,
    },
  },
  colorSchemes: {
    light: {
      palette: {
        action: {
          selectedOpacity: 0.16,
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none' as const,
    },
  },
}
