import { useState, CSSProperties, useCallback, useMemo } from 'react'

import { ActionButtonSmall } from '@/forms-legacy'
import { Download, TargetWithArrow } from '@/icons'

import * as OrderTexts from '../../../../intl'

import ReadonlyImageContainer from './elements/ReadonlyImageContainer'
import ReadonlyImageWrapper from './elements/ReadonlyImageWrapper'
import ReadonlyImageButton from './elements/ReadonlyImageButton'
import ReadonlyImageTextContainer from './elements/ReadonlyImageTextContainer'
import ReadonlyImageTextTime from './elements/ReadonlyImageTextTime'
import ReadonlyImageTextTitle from './elements/ReadonlyImageTextTitle'
import ReadonlyImageTextInfo from './elements/ReadonlyImageTextInfo'
import ReadonlyImageButtonsContainer from './elements/ReadonlyImageButtonsContainer'
import { ImageLayout, Props } from './typings'

export interface State {
  layout: ImageLayout
}

export function ReadonlyImage(props: Props) {
  const {
    toggleLayoutDisabled,
    src,
    title,
    time,
    text,
    backgroundSize,
    fitImageToContainer,
    selected,
    onToggle,
    latLng,
    uuid,
  } = props

  const [layout, setLayout] = useState<ImageLayout>(() => props.layout)

  const toggleLayout = useCallback(() => {
    setLayout(layout === 'detail' ? 'thumbnail' : 'detail')
  }, [layout])

  const handleToggle = useCallback(() => {
    onToggle(uuid, latLng)
  }, [onToggle, latLng, uuid])

  const collapsed = layout === 'thumbnail'

  const imageStyle = useMemo<CSSProperties>(
    () => ({
      backgroundImage: `url(${src})`,
      backgroundSize: backgroundSize ? backgroundSize : fitImageToContainer ? 'cover' : 'contain',
    }),
    [src, backgroundSize, fitImageToContainer],
  )

  return (
    <ReadonlyImageContainer layout={layout}>
      <ReadonlyImageWrapper layout={layout}>
        <ReadonlyImageButton
          layout={layout}
          style={imageStyle}
          onClick={toggleLayout}
          disabled={toggleLayoutDisabled}
        />
      </ReadonlyImageWrapper>

      <ReadonlyImageTextContainer layout={layout}>
        <ReadonlyImageTextTitle>{title}</ReadonlyImageTextTitle>
        <ReadonlyImageTextTime>{time}</ReadonlyImageTextTime>
        {collapsed && <ReadonlyImageTextInfo>{text}</ReadonlyImageTextInfo>}
      </ReadonlyImageTextContainer>

      <ReadonlyImageButtonsContainer>
        <ActionButtonSmall
          name="ShowOnMapAction"
          label={OrderTexts.getPodShowOnMapText(!!selected)}
          Icon={<TargetWithArrow size={18} color="$shadyLady" />}
          callback={handleToggle}
          disabled={!latLng}
          href={src}
        />
        <ActionButtonSmall
          name="DownloadAction"
          label={OrderTexts.getDownloadText()}
          Icon={<Download size={26} color="$shadyLady" />}
          callback={() => undefined}
          href={src}
          renderAsDownloadableLink
        />
      </ReadonlyImageButtonsContainer>

      {!collapsed && (
        <ReadonlyImageTextInfo style={{ padding: collapsed ? undefined : '0 10px' }}>
          {text}
        </ReadonlyImageTextInfo>
      )}
    </ReadonlyImageContainer>
  )
}
