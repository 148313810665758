import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { resetCrudSelection } from '@/atoms'
import { deleteSimulations } from '@/features/domain/simulation'

import { BulkView } from './BulkView'

type Props = {
  selection: workwave.DeepReadonly<string[]>
  simulations: uui.domain.client.rm.SimulationInfo[]
}

export function BulkViewProvider(props: Props) {
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const handleOnResetSelection = useCallback(() => {
    resetCrudSelection('simulations')
  }, [])

  const handleOnDeleteSimulations = useCallback(
    async (simulationIds: string[]) => {
      try {
        const result = await dispatch(deleteSimulations({ simulationIds }))

        if (!deleteSimulations.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, toast],
  )

  return (
    <BulkView
      simulations={props.simulations}
      resetSelection={handleOnResetSelection}
      deleteSimulations={handleOnDeleteSimulations}
    />
  )
}
