import { ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material'
import { Check } from '@mui/icons-material'

import { SortAscending, SortDescending } from '@/icons'
import { ListHeaderSorter } from '@/components/List'

import { useSorter } from './hooks/useSorter'
import { useTexts } from './hooks/useTexts'

const directions = ['asc', 'desc'] as const

export function Sorter() {
  const { direction, editing, setDirection } = useSorter()
  const texts = useTexts()

  const triggerText = direction === 'asc' ? texts.sortAscending : texts.sortDescending

  return (
    <ListHeaderSorter triggerText={triggerText} disabled={editing}>
      {directions.map(loopDirection => {
        const onClick = () => setDirection(loopDirection)
        const testId = `list-header-sorter-direction-${loopDirection}`
        const active = direction === loopDirection
        const label = loopDirection === 'asc' ? texts.sortAscending : texts.sortDescending
        const icon =
          loopDirection === 'asc' ? <SortAscending size={20} /> : <SortDescending size={20} />

        return (
          <MenuItem
            key={loopDirection}
            onClick={onClick}
            data-testid={testId}
            data-trackid={testId}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              align-items="center"
              width="100%"
              gap={2}
            >
              <Stack direction="row">
                {active && (
                  <ListItemIcon>
                    <Check color="primary" />
                  </ListItemIcon>
                )}

                <ListItemText inset={!active} primary={label} />
              </Stack>

              <Stack justifyContent="center" color="action.active">
                {icon}
              </Stack>
            </Stack>
          </MenuItem>
        )
      })}
    </ListHeaderSorter>
  )
}
