import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    disablePrev: translate({ id: 'rm.contextual.breadcrumbs.navbar.disabledPrev.title' }),
    disableNext: translate({ id: 'rm.contextual.breadcrumbs.navbar.disabledNext.title' }),
  }))

  return api
}
