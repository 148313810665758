import type { RoadSegmentFormValidation, RoadSegmentFormValues } from '../types'

import { useTexts } from './useTexts'

const validateForm =
  (texts: ReturnType<typeof useTexts>) =>
  (formValues: RoadSegmentFormValues): RoadSegmentFormValidation => {
    const { name, additionalDrivingTimeSec, type, editableSegment } = formValues

    const validation: RoadSegmentFormValidation = {}

    if (!name) {
      validation.name = texts.errorName
    }

    if (type === 'delayed' && additionalDrivingTimeSec <= 0) {
      validation.additionalDrivingTimeSec = texts.errorAdditionalDrivingTimeSec
    }

    if (!editableSegment) {
      validation.editableSegment = 'Invalid Segment'
    }

    return validation
  }

export const useValidateForm = () => {
  const texts = useTexts()
  return validateForm(texts)
}
