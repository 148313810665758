import type { MouseEvent } from 'react'
import type { GridFilterItemProps, GridSortDirection } from '@mui/x-data-grid-pro'

import { useMemo, useCallback } from 'react'
import { MenuItem } from '@mui/material'
import { useGridApiContext, useGridSelector, gridSortModelSelector } from '@mui/x-data-grid-pro'

export function SortGridMenuItems(props: GridFilterItemProps) {
  const { column, onClick } = props
  const apiRef = useGridApiContext()
  const sortModel = useGridSelector(apiRef, gridSortModelSelector)

  const sortDirection = useMemo(() => {
    if (!column) {
      return null
    }
    const sortItem = sortModel.find(item => item.field === column.field)
    return sortItem?.sort
  }, [column, sortModel])

  const onSortMenuItemClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      onClick(event)
      const direction = event.currentTarget.getAttribute('data-value') || null
      apiRef.current.sortColumn(column!, direction as GridSortDirection)
    },
    [apiRef, column, onClick],
  )

  if (!column?.sortable) {
    return null
  }

  return (
    <>
      <MenuItem onClick={onSortMenuItemClick} data-value="asc" disabled={sortDirection === 'asc'}>
        {apiRef.current.getLocaleText('columnMenuSortAsc')}
      </MenuItem>
      <MenuItem onClick={onSortMenuItemClick} data-value="desc" disabled={sortDirection === 'desc'}>
        {apiRef.current.getLocaleText('columnMenuSortDesc')}
      </MenuItem>
    </>
  )
}
