import { useAppLanguage, useLoadingState } from '@/atoms'
import { LoadingScreen } from '@/components/LoadingScreen'

/**
 * Removes all the App children while a language is loading. It ensures that every component is
 * re-rendered when the new language is ready.
 */
export function LanguageLoading(props) {
  const [{ nextLanguage }] = useAppLanguage()
  const { type } = useLoadingState()

  // We don't have to show the loader if any loading state is active
  return nextLanguage && type === 'none' ? (
    // The message is hardcoded in english since it can be that there's no translations available
    <LoadingScreen message="Loading language" />
  ) : (
    <>{props.children}</>
  )
}
