import { Box, Typography, Stack } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { ViewActionButton } from '@/formUi'

import { ReasonsForNotCompletedOrdersCount } from '../../ReasonsForNotCompletedOrdersCount'
import { useTexts } from '../hooks/useTexts'
import { useCopyReasonsForNotCompletedOrdersModal } from '../../../modals/CopyReasonsForNotCompletedOrdersModal'

interface Props {
  reasonsForNotCompletedOrders: string[]
  territories: Record<string, uui.domain.client.rm.Territory>
}

export function ReasonsForNotCompletedOrders(props: Props) {
  const { reasonsForNotCompletedOrders, territories } = props
  const texts = useTexts()

  const noReasons = reasonsForNotCompletedOrders.length === 0
  const singleTerritory = Object.keys(territories).length === 1

  const cannotCopyReasons = noReasons || singleTerritory
  const tooltipTitle = noReasons ? texts.noReasons : singleTerritory ? texts.oneTerritory : ''

  const {
    show: showCopyReasonsForNotCompletedOrdersModal,
    Modal: CopyReasonsForNotCompletedOrdersModal,
  } = useCopyReasonsForNotCompletedOrdersModal()

  return (
    <>
      <Stack spacing={2} data-testid="driver-activity-reasons-not-completed-orders">
        <ReasonsForNotCompletedOrdersCount reasonsCount={reasonsForNotCompletedOrders.length} />
        <Tooltip placement="top" title={tooltipTitle} disabled={!cannotCopyReasons}>
          <ViewActionButton
            disabled={cannotCopyReasons}
            onClick={showCopyReasonsForNotCompletedOrdersModal}
            label={texts.copy}
            testId="driver-activity-reasons-not-completed-orders-copy-btn"
          />
        </Tooltip>

        <Box>
          {reasonsForNotCompletedOrders.map((reason, index) => (
            <Typography key={index} variant="body2" marginTop={1}>
              {reason}
            </Typography>
          ))}
        </Box>
      </Stack>

      <CopyReasonsForNotCompletedOrdersModal />
    </>
  )
}
