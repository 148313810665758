import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    genericDropdownFooterText: (count: number) =>
      translate({ id: 'ui.genericDropdown.footer', values: { count } }),
    genericDropdownFooterWithSearchText: (count: number) =>
      translate({ id: 'ui.genericDropdown.footerWithSearch', values: { count } }),
    genericDropdownFooterNoItemsText: translate({ id: 'ui.genericDropdown.footerNoItems' }),
  }))

  return api
}
