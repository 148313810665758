import { type ColumnStoreConfig } from '@bryntum/schedulerpro'
import { type SchedulerColumn, getSchedulerColumnAtom } from '../../../atoms/columns'

import { getColumnsFromConfig } from './utils/getColumnsFromConfig'
import { onUpdate } from './events/onUpdate'

export function createColumnStore(): Partial<ColumnStoreConfig> {
  const storedColumns = getSchedulerColumnAtom(true).columns
  const data = getColumnsFromConfig(storedColumns as SchedulerColumn[])

  return { data, onUpdate }
}
