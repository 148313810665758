import styled from 'styled-components'

const styledComponent = styled.div.attrs({
  className: 'o-warning-overlay__dialog-container',
})`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default styledComponent
