import { Map as MainMap, MapLayer } from '@/map'

export function Map() {
  return (
    <MainMap selectionContext="crud" selectionCategory="depots" mapId="ww-depot-map">
      <MapLayer uid="order" interactiveOnUnmount visible />
      <MapLayer uid="depot" interactive interactiveOnUnmount visible />
      <MapLayer uid="region" visible />
      <MapLayer uid="roadSegment" visible />
      <MapLayer uid="geofence" visible={false} />
      <MapLayer uid="routePolyline" visible />
      <MapLayer uid="place" visible />

      {/* TODO: Add remaining layers once they are implemented */}
    </MainMap>
  )
}
