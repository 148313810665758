import { useEffect } from 'react'

import { WarningTriangle } from '@/icons'
import { WarningCard, H4 } from '@/local/components'
import { Dropdown } from '@/components/Dropdown'
import { VerticalLayout } from '@/components/layout/VerticalLayout'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'
import { useStepOptions } from '../hooks/useStepOptions'
import { useDateOptions } from '../hooks/useDateOptions'
import { useVehicleOptions } from '../hooks/useVehicleOptions'
import { useWarningCardTexts } from '../hooks/useWarningCardTexts'

const labelSpace = 8
const dropdownMaxHeight = 120
const dropdownBlockSpace = 22

const warningCardStyle = { marginTop: dropdownBlockSpace }

export function Body() {
  const texts = useTexts()
  const dateOptions = useDateOptions()
  const stepOptions = useStepOptions()
  const vehicleOptions = useVehicleOptions()
  const actions = useControllerActions()
  const {
    updateData,
    status,
    data: { isForceFitting, areDateAndVehicleSet, orderSteps },
  } = useController()

  const warningCardTexts = useWarningCardTexts(orderSteps.length > 1, isForceFitting)

  // If there is no selected step, select the first one not disabled
  useEffect(() => {
    const selectedStep = stepOptions.find(o => o.selected)

    if (selectedStep) return

    const option = stepOptions.find(s => !s.disabled)
    updateData({ activeStep: option?.routeStep })
  }, [updateData, stepOptions])

  return (
    <VerticalLayout>
      <VerticalLayout marginTop={dropdownBlockSpace}>
        <H4 color="$paleSky" marginBottom={labelSpace} uppercase>
          {texts.date}
        </H4>
        <Dropdown
          disabled={status === 'submitting'}
          onChange={actions.onChangeDateOption}
          maxListHeight={dropdownMaxHeight}
          options={dateOptions}
          testId="date-dropdown"
        />
      </VerticalLayout>

      <VerticalLayout marginTop={dropdownBlockSpace}>
        <H4 color="$paleSky" marginBottom={labelSpace} uppercase>
          {texts.vehicle}
        </H4>
        <Dropdown
          onChange={actions.onChangeVehicleOption}
          disabled={status === 'submitting'}
          maxListHeight={dropdownMaxHeight}
          options={vehicleOptions}
          testId="vehicle-dropdown"
        />
      </VerticalLayout>

      <VerticalLayout marginTop={dropdownBlockSpace}>
        <H4 color="$paleSky" marginBottom={labelSpace} uppercase>
          {texts.insertAfter}
        </H4>
        <Dropdown
          options={stepOptions}
          disabled={status === 'submitting' || !areDateAndVehicleSet}
          maxListHeight={dropdownMaxHeight}
          onChange={actions.onChangeStepOption}
          testId="step-dropdown"
        />
      </VerticalLayout>

      <WarningCard
        preset={isForceFitting ? 'warning' : 'info'}
        description={warningCardTexts.description}
        style={warningCardStyle}
        Icon={
          <WarningTriangle
            size={16}
            color="$pureWhite"
            background={isForceFitting ? '$darkOrange' : '$scienceBlue'}
          />
        }
      />
    </VerticalLayout>
  )
}
