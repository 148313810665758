import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState({
    multipleDrivers: translate({ id: 'map.tooltip.order.drivers.multiple' }),
  })

  return api
}
