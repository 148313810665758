import type Geometry from 'ol/geom/Geometry'

import MultiLineString from 'ol/geom/MultiLineString'
import LineString from 'ol/geom/LineString'
import Feature from 'ol/Feature'

import { setRoutePolylineFeatureMetadata } from '../routePolylineFeatureMetadata'
import { getRoutePolylineFeatureStyle } from './getRoutePolylineFeatureStyle'

/**
 * Update a RoutePolyline feature style and position on the map
 */
export function updateRoutePolylineFeature(
  mapStyles: uui.domain.ui.map.markers.MapStyles['routePolyline'],
  marker: uui.domain.ui.map.markers.RoutePolyline,
  feature: Feature<Geometry>,
): Feature<Geometry> {
  // ------------------------
  // Feature Geometry
  // ------------------------

  /**
   * Let's update geometries
   * - One for full stroke legs (ok and straightLine)
   * - One for dashed stroke legs (pedestrian)
   */
  const regularLegs: LineString[] = []
  const pedestrianLegs: LineString[] = []

  if (marker.fetched) {
    for (const leg of marker.legs) {
      switch (leg.status) {
        case 'pedestrian':
          pedestrianLegs.push(new LineString(leg.coordinates))
          break

        case 'ok':
        case 'straightLine':
        default:
          regularLegs.push(new LineString(leg.coordinates))
      }
    }
  }
  // Full stroke legs (ok and straightLine)
  feature.setGeometry(new MultiLineString(regularLegs))

  // Dashed stroke legs (pedestrian)
  feature.set('pedestrianLegs', new MultiLineString(pedestrianLegs))
  // ------------------------
  // Feature Metadata
  // ------------------------

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['routePolyline']['mode'] =
    marker.mode ?? mapStyles.mode
  setRoutePolylineFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getRoutePolylineFeatureStyle(marker, mode))

  return feature
}
