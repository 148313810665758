import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    labels: {
      name: translate({ id: 'userManagement.viewUser.labels.name' }),
      surname: translate({ id: 'userManagement.viewUser.labels.surname' }),
      email: translate({ id: 'userManagement.viewUser.labels.email' }),
      username: translate({ id: 'userManagement.viewUser.labels.username' }),
      role: translate({ id: 'userManagement.viewUser.labels.role' }),
      access: translate({ id: 'userManagement.viewUser.labels.access' }),
      companies: translate({ id: 'userManagement.viewUser.labels.companies' }),
      territories: translate({ id: 'userManagement.viewUser.labels.territories' }),
    },
    territories: {
      all: translate({ id: 'userManagement.viewUser.territories.all' }),
      limited: (count: number) =>
        translate({ id: 'userManagement.viewUser.territories.limited', values: { count } }),
    },
    menu: {
      enabled: translate({ id: 'userManagement.viewUser.menu.enable' }),
      disabled: translate({ id: 'userManagement.viewUser.menu.disable' }),
      changePassword: translate({ id: 'userManagement.viewUser.menu.changePassword' }),
      delete: translate({ id: 'userManagement.viewUser.menu.delete' }),
    },
    disabled: translate({ id: 'userManagement.viewUser.menu.disabled' }),
    enableUserError: translate({ id: 'userManagement.viewUser.enableUserError' }),
    tooltips: {
      close: translate({ id: 'userManagement.viewUser.tooltips.close' }),
      edit: translate({ id: 'userManagement.viewUser.tooltips.edit' }),
      delete: translate({ id: 'userManagement.viewUser.tooltips.delete' }),
      more: translate({ id: 'userManagement.viewUser.tooltips.more' }),
      expand: translate({ id: 'userManagement.viewUser.tooltips.expand' }),
      collapse: translate({ id: 'userManagement.viewUser.tooltips.collapse' }),
    },
    errors: {
      companyMissing: translate({ id: 'userManagement.viewUser.errors.companyMissing' }),
    },
  }))

  return api
}
