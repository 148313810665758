import type { FormFields, FormErrors } from '../../formFields'

import { Stack, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useDisableSubmit } from '@/components/formUi/hooks/useDisableSubmit'
import { useTexts } from './hooks/useTexts'

interface Props {
  submitting: boolean
  onDiscard: () => void
  onSubmit: () => void
}

export function FormFooter(props: Props) {
  const { onSubmit } = props
  const texts = useTexts()
  const { disableSubmit, submitting } = useDisableSubmit<FormFields, FormErrors>()

  return (
    <Stack
      spacing={2}
      direction="row"
      component="footer"
      alignItems="center"
      paddingTop={4}
      paddingLeft={4}
      paddingRight={4}
    >
      <LoadingButton
        size="medium"
        type="submit"
        color="primary"
        variant="contained"
        onClick={onSubmit}
        loading={submitting}
        disabled={disableSubmit}
        data-testid="settings-personalization-edit-save-button"
        data-trackid="settings-personalization-edit-save-button"
      >
        {texts.save}
      </LoadingButton>

      <Button
        type="button"
        size="medium"
        color="primary"
        onClick={props.onDiscard}
        variant="text"
        data-testid="settings-personalization-edit-cancel-button"
        data-trackid="settings-personalization-edit-cancel-button"
      >
        {texts.cancel}
      </Button>
    </Stack>
  )
}
