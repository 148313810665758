import type { ChipShape, CoreItem, Modes, DeviceChip } from '../../../components/Navigator/typings'

import { clsx } from '@/utils'
import { theme } from '@/local/components'
import { getColorValue, isDark } from '@/styles'

import { Chip } from '../../../components/Navigator/components/Chip'
import { DeviceIcon } from './DeviceIcon'

export interface Props<T extends CoreItem, K> {
  item: T
  mode: Modes
  selected?: boolean
  onClick: () => void
  className?: string
  extraItemRendererProps: K
  loopIndex: number
}

export function DeviceItemRenderer(props: Props<DeviceChip, Record<string, any>>) {
  const {
    item: { source },
    onClick,
    className: cls = '',
  } = props

  const className = clsx(cls, 'o-chip--circle')
  const background: string = getColorValue(source.iconColor1)

  const textColor = isDark(background) ? theme.colors.$pureWhite : theme.colors.$nightRider

  const chipProps = {
    onClick,
    className,
    background,
    color: textColor,
    borderColor: background,
    shape: 'rounded' as ChipShape,
    Icon: <DeviceIcon iconId={source.iconId} />,
  }

  return <Chip {...chipProps} />
}
