import { useEffect } from 'react'
import { Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'
import { Checkbox, MultiSelect } from '@workwave-tidal/tidal/form-ui'

import { selectCompaniesAsArray } from '@/features/domain/company'
import { Tooltip } from '@/components/primitives/Tooltip'

import { type FormErrors, type FormFields } from '../../formFields'
import { useTexts } from '../../hooks/useTexts'
import { useCompanies } from './hooks/useCompanies'

export function Companies() {
  const allCompaniesCount = useSelector(selectCompaniesAsArray).length
  const formApi = useFormApi<FormFields, FormErrors>()
  const { companies: options } = useCompanies()

  const isCompanyFilteredField = formApi.getField('isCompanyFiltered')
  const showList = isCompanyFilteredField.value
  const disabled = isCompanyFilteredField.disabled

  const texts = useTexts()

  useEffect(() => {
    formApi.setFieldDisabledState('isCompanyFiltered', allCompaniesCount === 0)
  }, [formApi, allCompaniesCount])

  useEffect(() => {
    const unsubscribe = formApi.subscribeToField('isCompanyFiltered', field => {
      // toggle the required state of the companies field
      // changing the required state will invalidate the field
      formApi.setFieldRequiredState('companies', field.value)

      if (!field.value) {
        formApi.clearError('companies')
        formApi.change('companies', [])
      }
    })

    return () => {
      unsubscribe()
    }
  }, [formApi])

  if (disabled) {
    return (
      <Tooltip placement="bottom" title={texts.tooltip.isCompanyFiltered.disabled}>
        <Checkbox<'isCompanyFiltered', FormFields, FormErrors>
          label={texts.labels.isCompanyFiltered}
          validateOn="blur focus change"
          name="isCompanyFiltered"
          testId="isCompanyFiltered"
        />
      </Tooltip>
    )
  }

  return (
    <Stack gap={2}>
      <Checkbox<'isCompanyFiltered', FormFields, FormErrors>
        label={texts.labels.isCompanyFiltered}
        validateOn="blur focus change"
        name="isCompanyFiltered"
        testId="isCompanyFiltered"
        helperText={texts.helperText.isCompanyFiltered}
      />
      {showList && (
        <MultiSelect<'companies', FormFields, FormErrors, uui.domain.client.rm.Company>
          name="companies"
          testId="companies"
          validateOn="blur change"
          multiSelectProps={{
            label: texts.labels.companies,
            items: options,
            getLabel: c => c.name,
            getOptionDisabled: c => !c.enabled,
            inputMode: 'normal',
            optionMode: 'normal',
            texts: texts.multiSelect.companies,
          }}
        />
      )}
    </Stack>
  )
}
