import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { Alert, Button, Stack, Typography, useTheme } from '@mui/material'

import { selectTotalTelematicsLicenses } from '@/features/domain/account'
import { useNavigate } from '@/routing'

import { useTexts } from '../../../../hooks/useTexts'

import { NoIntegrationsIllustration } from './components/NoIntegrationsIllustration'

export function NoIntegrations() {
  const totalLicenses = useSelector(selectTotalTelematicsLicenses)
  const theme = useTheme()
  const texts = useTexts()
  const navigate = useNavigate<uui.routing.Setup>(true)

  const onAssign = useCallback(() => {
    navigate('vehicles')
  }, [navigate])

  return (
    <Stack bgcolor={theme.palette.background.default} alignItems="center" padding={4} spacing={3}>
      <Stack spacing={3} alignItems="center" width={360}>
        <NoIntegrationsIllustration />

        <Stack spacing={1} textAlign="center">
          <Typography variant="h6" fontWeight={500}>
            {texts.noIntegrations.title}
          </Typography>
          <Typography variant="body2">{texts.noIntegrations.description}</Typography>
        </Stack>

        {totalLicenses > 0 ? (
          <Button
            variant="outlined"
            onClick={onAssign}
            data-testid="integration-page-assign-button"
          >
            {texts.noIntegrations.action}
          </Button>
        ) : (
          <Alert severity="info">
            <Typography>{texts.noIntegrations.noLicenses.alert}</Typography>
          </Alert>
        )}
      </Stack>
    </Stack>
  )
}
