import { Text } from '@/local/components'
import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'

interface Props {
  timeWindow: uui.domain.rm.TimeWindow
  gotViolations?: boolean
}

export function TimeWindow(props: Props) {
  const {
    gotViolations = false,
    timeWindow: { startSec, endSec },
  } = props

  return (
    <Text size="$p3" color={gotViolations ? '$outrageousRed' : '$nightRider'}>
      <ConvertTime seconds={startSec} showSeconds={false} />
      {` - `}
      <ConvertTime seconds={endSec} showSeconds={false} />
    </Text>
  )
}
