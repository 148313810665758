import { useSelector } from 'react-redux'

import { selectCalendarRangeInterval } from '@/features/domain/ui'
import {
  selectGpsTrackingProvider,
  selectUserConfiguration,
  selectUserProfile,
  isGpsOnly,
} from '@/features/domain/user'

import { selectPhysicalDevices } from '@/features/domain/device'

export const useRm360Links = () => {
  const { planType, userType } = useSelector(selectUserConfiguration)
  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const physicalDevices = useSelector(selectPhysicalDevices)
  const calendarRange = useSelector(selectCalendarRangeInterval)
  const userProfile = useSelector(selectUserProfile)
  const gpsOnly = useSelector(isGpsOnly)

  const canShow = {
    simulations: !userProfile.isViewOnly && userType !== 'rmViewer' && userType !== 'courier',
    tracking: planType !== 'simulation' && trackingProvider !== 'none',
    reports:
      physicalDevices.length > 0 &&
      planType !== 'simulation' &&
      (gpsOnly || trackingProvider === 'WWGPS'),
  }

  return {
    canShow,
    calendarRange,
  }
}
