import type { SegmentType } from '../utils/color'

import tinycolor from 'tinycolor2'

import { getTypeColor } from '../utils/color'

import { createValidSegment } from './utils/createValidSegment'
import { createCircle } from './utils/createCircle'
import { fullSegment } from './utils/fullSegment'
import { createLabel } from './utils/createLabel'
import { points } from './utils/points'

type Color = [number, number, number, number]

export const createStyle = (type: SegmentType) => {
  const baseColor = tinycolor(getTypeColor(type)).toRgb()

  function getMainColor(alpha: number = 1): Color {
    const { r, g, b } = baseColor
    return [r, g, b, alpha]
  }

  const validSegment = createValidSegment(getMainColor(1))
  const circle = createCircle()
  const label = createLabel()

  return [fullSegment, validSegment, points, circle, label]
}
