import type { FormErrors, FormFields } from './formFields'
import { Box, Stack } from '@mui/material'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { ReasonField } from './ReasonField'

export function ReasonFields() {
  const { formApi } = useFormField<'reasonsForNotCompletedOrders', FormFields, FormErrors>(
    'reasonsForNotCompletedOrders',
  )
  const errors = formApi.getErrors().reasonsError

  return (
    <Stack marginTop={2}>
      {formApi.getField('reasonsForNotCompletedOrders').value.map((reason, index) => (
        <Box key={`Reason${index}`}>
          <ReasonField
            value={reason}
            index={index}
            errorMessage={errors?.invalidReasonsIndexes?.includes(index) ? errors.message : null}
          />
        </Box>
      ))}
    </Stack>
  )
}
