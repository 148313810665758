import { useNewRoadSegmentValue } from './hooks/useNewRoadSegmentValue'
import { SingleEdit } from './SingleEdit'

interface Props {
  roadSegment?: uui.domain.client.rm.SegmentException
}

export function SingleEditProvider(props: Props) {
  const { roadSegment } = props
  const newValue = useNewRoadSegmentValue()

  if (!roadSegment) {
    return <SingleEdit roadSegment={newValue} creating />
  }

  return <SingleEdit roadSegment={roadSegment} />
}
