import styled from 'styled-components'

type Props = {
  disabled?: boolean
}

const IconContainer = styled.span<Props>`
  position: relative;
  display: block;

  width: 12px;
  height: 12px;
  border-radius: 50%;

  margin-right: 10px;

  background: ${p => p.theme.colors.$pureWhite};

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 8px;
    color: ${p => (p.disabled ? p.theme.colors.$silver : p.theme.colors.$pigmentGreen)};
  }
`
IconContainer.displayName = 'IconContainer'
export default IconContainer
