import type { ReactElement } from 'react'
import { useHasPrivileges, useIsUserType, type ExtendedPrivilege } from '@/hooks'

type ComponentProps = {
  exactPrivileges?: boolean
  children: ReactElement
  userTypes?: uui.domain.rm.RouteManagerUserType[]
  accountPrivileges?: ExtendedPrivilege[]
}

export function HasPrivileges(props: ComponentProps) {
  const { exactPrivileges, children, userTypes, accountPrivileges } = props

  const gotPrivileges = useHasPrivileges(accountPrivileges ?? [], exactPrivileges ?? false)

  const hasUserType = useIsUserType(userTypes ?? [])

  return gotPrivileges && hasUserType ? children : null
}
