import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { timeUtils, getDateInTimezone } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectLivePositions } from '@/features/domain/device'

interface LastUpdate {
  formattedDate: string
  deltaSeconds: number
}

export function useLastUpdate(deviceId: string) {
  const livePositions = useSelector(selectLivePositions)
  const userConfig = useSelector(selectUserConfiguration)
  const [now, setNow] = useState(() => {
    return getDateInTimezone(new Date(), userConfig.transitions)
  })

  useEffect(() => {
    const timer = setInterval(
      () => setNow(getDateInTimezone(new Date(), userConfig.transitions)),
      1000,
    )

    return () => clearInterval(timer)
  }, [userConfig])

  return useMemo<LastUpdate | undefined>(() => {
    const livePosition = livePositions[deviceId]

    if (!livePosition?.$ISODate) return

    const nowTs = now.getTime()
    const date = new Date(livePosition.ts)
    const deltaSeconds = Math.trunc((nowTs - livePosition.ts) / 1000)

    const timeString = timeUtils.formatTime(userConfig)(date, true)
    const dateString = timeUtils.formatDate(userConfig)(date)

    const formattedDate = `${timeString} • ${dateString}`

    return {
      formattedDate,
      deltaSeconds,
    }
  }, [livePositions, deviceId, now, userConfig])
}
