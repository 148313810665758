import { useMemo } from 'react'

import { parseRecurrence } from '@/server-data'

type Result = Record<uui.domain.rm.RecurrenceTypes, uui.domain.rm.Recurrence[]>

export function useRecurrencesMap(
  driverAssignments: Record<string, uui.domain.client.rm.DriverAssignmentsWithId>,
): Result | undefined {
  return useMemo(() => {
    const map = Object.keys(driverAssignments).reduce((acc, id) => {
      const rec = parseRecurrence(id)

      if (!rec) return acc

      const { type } = rec
      const arr = acc[type] || []
      acc[type] = [...arr, rec]

      return acc
    }, {})

    return Object.keys(map).length === 0 ? undefined : (map as Result)
  }, [driverAssignments])
}
