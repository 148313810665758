import type { PartialStoreState } from '../typings'
import { createSelector } from '@reduxjs/toolkit'

export const selectDepots = (state: PartialStoreState) => state.domain.publicData.domain.rm.depots

export const selectAggregateDepotsByLatLng = createSelector(selectDepots, depots => {
  const byLatLng: Record<string, uui.domain.client.rm.Depot[]> = {}

  for (const depot of Object.values(depots)) {
    const {
      location: { rawLatLngAsString },
    } = depot

    byLatLng[rawLatLngAsString] ??= []
    byLatLng[rawLatLngAsString].push(depot)
  }

  return byLatLng
})
