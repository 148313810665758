import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    addTrafficArea: translate({ id: 'traffic.trafficArea.list.actions.addTrafficArea' }),
    profileSettings: translate({ id: 'traffic.trafficArea.list.actions.profileSettings' }),
    deleteProfile: translate({ id: 'traffic.trafficArea.list.actions.deleteProfile' }),
    importKML: translate({ id: 'global.importKML' }),
    importKMLFailed: translate({ id: 'global.importKML.failed' }),
    goBack: (profileName: string) =>
      translate({ id: 'traffic.trafficArea.list.actions.goBack', values: { profileName } }),
  }))

  return api
}
