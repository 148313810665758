import type { GridCellParams } from '@mui/x-data-grid-pro'

import { Tooltip } from '@/components/primitives/Tooltip'
import { useTooltipText } from './hooks/useTooltipText'

interface Props {
  params: GridCellParams
}

function AddressCellRenderer(props: Props) {
  const {
    params: { value, row },
  } = props

  const orderStepId = row.id as string
  const tooltipText = useTooltipText(orderStepId)

  if (!tooltipText) return <span className="table-cell-truncate">{value}</span>

  return (
    <Tooltip placement="top" title={tooltipText}>
      <span className="table-cell-truncate">{value}</span>
    </Tooltip>
  )
}

export function AddressCell(params: GridCellParams) {
  return <AddressCellRenderer params={params} />
}
