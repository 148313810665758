import { type CSSProperties } from 'react'
import { useTexts } from './useTexts'

const textStyle: CSSProperties = {
  letterSpacing: '0.00714em',
  marginBottom: '0.35rem',
  fontWeight: 500,
  fontSize: '0.875rem',
}

export function LockedCellTooltip() {
  const texts = useTexts()

  return <div style={textStyle}>{texts.disabled}</div>
}
