/**
 * Imperatively spawn a new popup.
 */
export function spawnPopup(
  target: string,
  windowFeatures:
    | {
        width: number
        height: number
        screenX: number
        screenY: number
      }
    | undefined,
) {
  let features = ``

  if (windowFeatures) {
    features += `width=${windowFeatures.width},height=${windowFeatures.height},left=${windowFeatures?.screenX},top=${windowFeatures?.screenY}`
  }

  // Open the popup
  // see: https://developer.mozilla.org/en-US/docs/Web/API/Window/open
  return window.open(
    // An empty URL means an empty popup
    '',

    // The browsing context. Can be used as the target attribute of <a> or <form> elements.
    // see: https://developer.mozilla.org/en-US/docs/Web/API/Window/open
    target,

    features,
  )
}
