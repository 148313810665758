import type { Props, AggregateLoad, AggregateCf } from '../types'

import { useMemo } from 'react'

export function useAggregateLoadsCf(props: Props) {
  const { customFields, trackedCustomFields, loads, trackedLoads } = props

  const aggLoads = useMemo(() => aggregateLoads(loads, trackedLoads), [loads, trackedLoads])
  const aggCf = useMemo(
    () => aggregateCf(customFields, trackedCustomFields),
    [customFields, trackedCustomFields],
  )

  return {
    aggregateLoads: aggLoads,
    aggregateCustomFields: aggCf,
  }
}

function aggregateLoads(
  loads: uui.domain.client.rm.Order['loads'] = {},
  trackedLoads: uui.domain.client.rm.OrderStepTrackingData['loadTrackedMap'] = {},
) {
  return Object.keys(loads).reduce<AggregateLoad[]>((acc, key) => {
    const load = loads[key]
    const trackedLoad = trackedLoads[key]

    if (typeof load !== 'number') {
      throw new Error(`Cannot aggregate loads. Load of key '${key}' is not a number (${load})`)
    }

    if (trackedLoad) {
      const normalizedLoad = load / 100
      const normalizedTrackedLoad = trackedLoad.value / 100

      acc.push({
        label: key,
        value: normalizedLoad,
        trackedValue: normalizedTrackedLoad,
        delta: normalizedTrackedLoad - normalizedLoad,
      })
      return acc
    }

    acc.push({
      label: key,
      value: 'undeclared',
    })

    return acc
  }, [])
}

function aggregateCf(
  customFields: uui.domain.client.rm.OrderStep['customFields'] = {},
  trackedCustomFields: uui.domain.client.rm.OrderStepTrackingData['customFieldTrackedMap'] = {},
) {
  return Object.keys(customFields).reduce<AggregateCf[]>((acc, key) => {
    const cf = customFields[key]
    const trackedCf = trackedCustomFields[key]

    if (typeof cf !== 'string') {
      throw new Error(
        `Cannot aggregate custom fields. Custom field of key '${key}' is not a string (${cf})`,
      )
    }

    if (trackedCf) {
      acc.push({
        label: key,
        type: 'reported',
        value: cf,
        trackedValue: trackedCf.value,
      })
      return acc
    }

    acc.push({
      label: key,
      type: 'undeclared',
    })

    return acc
  }, [])
}
