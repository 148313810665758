import type { CollapsibleListExtraProps } from '@/forms-legacy'

import { ReadonlyBlock, CollapsibleList } from '@/forms-legacy'

import { useDevicesInfo } from './hooks/useDevicesInfo'

import { ListItem } from './ListItem'
import { Header } from './Header'
interface Props {
  deviceIds: string[]
}

const renderLoadMore = () => null
const renderListItem = (item: uui.domain.client.gps.wwgps.DeviceInfo) => (
  <ListItem key={item.deviceId} item={item} />
)
const renderHeader = (headerProps: CollapsibleListExtraProps) => <Header {...headerProps} />

export function DevicesList(props: Props) {
  const { deviceIds } = props

  const deviceInfos = useDevicesInfo(deviceIds)

  return (
    <ReadonlyBlock>
      <CollapsibleList<uui.domain.client.gps.wwgps.DeviceInfo>
        list={deviceInfos}
        itemsThreshold={0}
        renderHeader={renderHeader}
        renderLoadMore={renderLoadMore}
        renderListItem={renderListItem}
      />
    </ReadonlyBlock>
  )
}
