import { useCallback, useState } from 'react'
import { updateMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'showRegions' | 'hideRegions'
export function useActions() {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const showRegions = useCallback(async () => {
    setMethodInExecution('showRegions')

    dispatch(updateMapStyle({ type: 'region', mode: 'on' }))

    setMethodInExecution(null)
  }, [dispatch])

  const hideRegions = useCallback(async () => {
    setMethodInExecution('hideRegions')

    dispatch(updateMapStyle({ type: 'region', mode: 'off' }))

    setMethodInExecution(null)
  }, [dispatch])

  return { methodInExecution, actions: { showRegions, hideRegions } }
}
