import { ShowDetails } from '@/icons'

import { Stats } from '../../components/Stats'
import { BackButton } from '../../../../elements/BackButton'
import { NavigoIconButton } from '../../../../elements/NavigoIconButton'

import { useTexts } from './useTexts'
import { useExecutionDetailsNavigation } from './hooks/useExecutionDetailsNavigation'

export function ExecutionDetails() {
  const texts = useTexts()
  const { goToDetail, goToOverview } = useExecutionDetailsNavigation()

  return (
    <>
      <Stats />
      <NavigoIconButton
        text={texts.showDetail}
        Icon={<ShowDetails size={18} color="$nightRider" />}
        onClick={goToDetail}
        className="pendo-navigoorder-execution-details_showDetails-button"
        testid="navigoorder-execution-details_showDetails-button"
      />
      <BackButton onClick={goToOverview} />
    </>
  )
}
