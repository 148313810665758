import type { ReactElement } from 'react'
import type { NotificationFormValues } from '../NotificationForm'

import type { NotificationFormType } from '@/notificationForms'
import { FormContainer, FormContent } from '@/formUi'

import { useTestPhoneNotificationModal } from '@/components/modals/TestPhoneNotificationModal'
import { useTestEmailNotificationModal } from '@/components/modals/TestEmailNotificationModal'
import { ActionsView } from './components/ActionsView'
import { HeaderView } from './components/HeaderView'

type Props = {
  values: Partial<NotificationFormValues>
  notification: NotificationFormType
  toggleSubtitle: string
  available?: boolean
  onToggle: () => void
  onEdit: () => void
  Form: ReactElement
  subtitle: string
  title: string
}

export function NotificationView(props: Props) {
  const {
    available = true,
    toggleSubtitle,
    notification,
    onToggle,
    subtitle,
    values,
    onEdit,
    title,
    Form,
  } = props

  const { Modal: PhoneModal, show: showPhoneTestNotificationModal } =
    useTestPhoneNotificationModal()
  const { Modal: EmailModal, show: showEmailTestNotificationModal } =
    useTestEmailNotificationModal()

  const show =
    values.notificationWay === 'PHONE'
      ? showPhoneTestNotificationModal
      : showEmailTestNotificationModal

  const Modal = values.notificationWay === 'PHONE' ? PhoneModal : EmailModal

  return (
    <FormContainer testid={`${notification}-view`}>
      <FormContent footer={<></>}>
        <HeaderView
          toggleSubtitle={toggleSubtitle}
          notification={notification}
          available={available}
          onToggle={onToggle}
          subtitle={subtitle}
          values={values}
          title={title}
        />
        {available && <ActionsView onEdit={onEdit} test={show} notification={notification} />}

        {Form}

        <Modal notification={notification} values={values} />
      </FormContent>
    </FormContainer>
  )
}
