import type { GridSlotsComponent, GridSlotsComponentsProps } from '@mui/x-data-grid-pro'
import type { Props } from '../types'

import { useMemo } from 'react'

import { ColumnsPanel } from '../components/ColumnsPanel'
import { ColumnMenu } from '../components/ColumnMenu'
import { Panel } from '../components/Panel'

export function useSlots(props: Props) {
  const {
    ColumnsPanel: providedColumnsPanel,
    ColumnMenu: providedColumnMenu,
    Panel: providedPanel,
    columnsPanelProps,
    columnMenuProps,
    panelProps,
  } = props

  const components: Partial<GridSlotsComponent> = useMemo(
    () => ({
      // GridColumns panel component rendered when clicking the columns button.
      ColumnsPanel: providedColumnsPanel || ColumnsPanel,

      // Panel component wrapping the filters and columns panels.
      Panel: providedPanel || Panel,

      ColumnMenu: providedColumnMenu || ColumnMenu,
    }),
    [providedColumnsPanel, providedColumnMenu, providedPanel],
  )

  const componentsProps: Partial<GridSlotsComponentsProps> = useMemo(
    () => ({
      columnsPanel: columnsPanelProps,
      columnMenu: columnMenuProps,
      panel: panelProps,
    }),
    [columnsPanelProps, columnMenuProps, panelProps],
  )

  return {
    components,
    componentsProps,
  }
}
