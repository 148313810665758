import styled from 'styled-components'

const EmptyStateContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  background: ${p => p.theme.colors.$pureWhite};
  color: ${p => p.theme.colors.$silver};
`

EmptyStateContainer.displayName = 'EmptyStateContainer'
export default EmptyStateContainer
