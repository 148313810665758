import { Text } from '@/local/components'
import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'

interface Props {
  planned: number
  estimated: number
}

export function Delta(props: Props) {
  const { planned, estimated } = props
  const delta = estimated - planned
  if (delta === 0) return null

  const inLate = delta > 0

  return (
    <Text size="$p3" color={inLate ? '$outrageousRed' : '$pigmentGreen'}>
      {inLate ? '+' : '-'}
      <FormatSeconds seconds={delta} format="MINUTES_ONLY" />
    </Text>
  )
}
