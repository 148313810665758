import { useState, useEffect } from 'react'

import { addSeconds } from 'date-fns/esm'
import { dateUtils } from '@/utils'

import { FormatTime } from '@/components/smartUtils/time/FormatTime'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyFieldRow,
  ReadonlyFieldRowPart,
  ReadonlyFieldLabel,
  SecondaryColumnContentHorizontalRuler,
} from '@/forms-legacy'

import { useFormatDateTime } from '@/hooks'
import {
  getBarcodesByStatus,
  fromPodBarcodeToPodBarcodeOrUnscanned,
  BarcodesByStatus,
} from '../../../utils'

import * as OrderTexts from '../../../intl'

import { Props } from '../typings'

type RowPartProps = {
  date: Date
}

function RowPart(props: RowPartProps) {
  const { date } = props

  const title = useFormatDateTime(date)

  return (
    <ReadonlyFieldRowPart>
      <ReadonlyLabel small title={title}>
        <FormatTime source={date} />
      </ReadonlyLabel>
    </ReadonlyFieldRowPart>
  )
}

const PodBarcodes = (props: Props) => {
  const {
    pods: { barcodes },
    orderStep,
  } = props

  const [barcodesByStatus, setBarcodesByStatus] = useState<BarcodesByStatus>(() =>
    getBarcodesByStatus(fromPodBarcodeToPodBarcodeOrUnscanned(barcodes || [], orderStep)),
  )

  useEffect(() => {
    setBarcodesByStatus(
      getBarcodesByStatus(fromPodBarcodeToPodBarcodeOrUnscanned(barcodes || [], orderStep)),
    )
  }, [barcodes, orderStep])

  const entries = Object.entries(barcodesByStatus) as [
    uui.domain.client.rm.BarcodePod['barcodeStatus'],
    uui.domain.client.rm.BarcodePod[],
  ][]

  const allBarcodesPodsCount = entries.reduce(
    (acc, [_, podByStatus]) => acc + podByStatus.length,
    0,
  )

  return (
    <ReadonlyBlock>
      <SecondaryColumnContentHorizontalRuler />
      <ReadonlyFieldLabel empty={allBarcodesPodsCount === 0}>
        {OrderTexts.getBarcodesFieldText()}
      </ReadonlyFieldLabel>

      {entries.map(
        (
          keyVal: [
            uui.domain.client.rm.BarcodePod['barcodeStatus'],
            uui.domain.client.rm.BarcodePod[],
          ],
        ) => {
          const [barcodeStatus, podByStatus] = keyVal

          const countPrefix = podByStatus.length > 0 ? ` (${podByStatus.length})` : ''

          return (
            <div key={barcodeStatus}>
              <ReadonlyFieldLabel empty={podByStatus.length === 0} style={{ marginTop: 14 }}>
                {OrderTexts.getBarcodesStatusText(barcodeStatus)}
                {countPrefix}
              </ReadonlyFieldLabel>
              {podByStatus.map((pod, index) => {
                const podDate = addSeconds(dateUtils.getDateAtMidnight(), pod.sec)

                return (
                  <ReadonlyFieldRow style={{ justifyContent: 'space-between' }} key={index}>
                    <ReadonlyFieldRowPart primary>
                      <ReadonlyLabel ellipsis truncateFromLeft>
                        {pod.barcode}
                      </ReadonlyLabel>
                    </ReadonlyFieldRowPart>
                    {pod.sec > 0 && <RowPart date={podDate} />}
                  </ReadonlyFieldRow>
                )
              })}
            </div>
          )
        },
      )}
    </ReadonlyBlock>
  )
}

export default PodBarcodes
