import { useFormatDate } from '@/hooks'

interface Props {
  source: string | Date
  shortFormat?: boolean
}

export function FormatDate(props: Props) {
  const { source, shortFormat } = props
  const value = useFormatDate(source, shortFormat)

  return <>{value}</>
}
