import { useCloseModal, useShowModal } from '@/atoms'

import { Root } from './components/Root'
import { modalId } from './hooks/useController'

export const useImportDemoTerritoryModal = () => ({
  show: useShowModal(modalId),
  close: useCloseModal(),
  Modal: Root,
})
