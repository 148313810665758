import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

/**
 * accepts both routes: uui.domain.client.rm.RouteIdentifier[] and dates: string[]
 * if you need to approve an entire day use mode "byDates"
 * otherwise if you just need to approve a collection of routes use mode "byRoutes"
 */
export const approveRoutes = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  uui.domain.actions.rpc.route.ApproveRoutesAction['payload'],
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/route/approve', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/route/approve', {
      category: 'rpc',
      type: 'rpc/route/approve',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {approveRoutes}`,
      { tags: ['rpc', 'order'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Approve routes failed`,
      error,
    })
  }
})
