import { Box } from '@mui/material'
import { differenceInHours } from 'date-fns/esm'

import { theme } from '@/styles'
import { Dot } from '@/forms-legacy'

export interface Props {
  lastPosition:
    | uui.domain.client.gps.wwgps.GpsInfo
    | uui.domain.client.gps.telematics.CurrentPositionGpsInfo
}

const HISTORICAL_GPS_POSITION_THRESHOLD_HOURS = 48

export function TrackingStatus(props: Props) {
  const { lastPosition } = props

  const isHistorical =
    differenceInHours(new Date(), new Date(lastPosition.ts)) >
    HISTORICAL_GPS_POSITION_THRESHOLD_HOURS

  let innerColor: string = ''
  let outerColor: string | undefined

  const status = isHistorical ? 'notReporting' : lastPosition.status

  switch (status) {
    case 'moving':
      innerColor = theme.colors.$pigmentGreen
      break
    case 'stopped':
      innerColor = theme.colors.$outrageousRed
      break
    case 'stalling':
      innerColor = theme.colors.$shadyLady
      break
    default:
      innerColor = theme.colors.$pureWhite
      outerColor = theme.colors.$nightRider
  }

  return (
    <Box width={10} height={10} marginRight={1}>
      <Dot innerColor={innerColor} outerColor={outerColor} />
    </Box>
  )
}
