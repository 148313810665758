import { styled } from '@mui/material'

import { cardGutter } from '../../constants'

export const LeftSeparator = styled('span')<{ inSelection: boolean }>`
  position: absolute;
  top: 50%;
  left: ${-cardGutter / 2}px;

  width: 1px;
  height: 14px;

  background: ${p => (p.inSelection ? p.theme.palette.grey[800] : p.theme.palette.grey[400])};

  transform: translate(0, -50%);

  z-index: 5;
`

LeftSeparator.displayName = 'LeftSeparator'
