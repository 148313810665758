import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    viewSettings: translate({
      id: 'orders.smsNotifications.notification.cta',
    }),

    sms: translate({
      id: 'orders.smsNotifications.notification.kind.sms',
    }),

    email: translate({
      id: 'orders.smsNotifications.notification.kind.email',
    }),

    senderText: translate({
      id: 'orders.smsNotifications.notification.sentBy.sender',
    }),

    label: (status: uui.domain.client.rm.OrderStepEmailOrSMSNotification['status']) =>
      translate({
        id: `orders.smsNotifications.notification.status.${status.toLowerCase()}` as uui.domain.intl.LocalizationIdentifier,
      }),

    errorCodeLabel: (errorCode: uui.domain.client.rm.SmsErrorCode) =>
      translate({
        id: `orders.smsNotifications.notification.errorCode.${errorCode}` as const,
      }),
  }))

  return api
}
