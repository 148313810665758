import { useCallback } from 'react'

import { useIsUnmounted } from '@/hooks'

import { useController } from '../../../hooks/useController'
import { useSetLayout } from './useSetLayout'

export function useOnSubmit() {
  const {
    close,
    data: { targetLayout },
  } = useController()

  const isUnmounted = useIsUnmounted()

  const { setToMap, setToSplit } = useSetLayout()

  return useCallback(() => {
    // if the component has been unmounted during the async operation stop here
    if (isUnmounted()) return

    targetLayout === 'map' ? setToMap() : setToSplit()

    close?.()
  }, [isUnmounted, close, setToMap, setToSplit, targetLayout])
}
