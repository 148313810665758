import { type ResourceModel } from '@bryntum/schedulerpro'
import { formatNumber } from '@/hooks'
import { conversionUtils } from '@/server-data'

import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../Scheduler/components/Typography'
import { LinearProgress } from '../../Scheduler/components/LinearProgress'
import { getPercentageColor } from '../../../styles/colors'

interface Props {
  record: ResourceModel
  field: string
  value: { absolute: number; percentage: number }
  userConfig: uui.domain.UserConfiguration
  showAbsoluteValues: boolean
}

export function ApprovedDistanceCell(props: Props) {
  const { value, userConfig, showAbsoluteValues } = props
  const { language } = userConfig

  if (!value) {
    return (
      <SingleColumnContainer>
        {showAbsoluteValues ? <Typography fontSize={12}>-</Typography> : <></>}
      </SingleColumnContainer>
    )
  }

  const normalizedValue =
    value.percentage > 100 ? 100 : value.percentage <= 0 ? 0 : (value.percentage * 100) / 100

  const formattedValue = formatNumber(Math.round(value.percentage * 100) / 100, language)

  return (
    <SingleColumnContainer>
      {showAbsoluteValues ? (
        <Typography fontSize={12}>
          {conversionUtils.convertDistance(userConfig)(value.absolute, 2)}
        </Typography>
      ) : (
        <>
          <LinearProgress color={getPercentageColor(value.percentage)} value={normalizedValue} />
          <Typography fontSize={10}>
            {value.percentage <= 0 ? '-' : `${formattedValue}%`}
          </Typography>
        </>
      )}
    </SingleColumnContainer>
  )
}
