import { proxy, snapshot, useSnapshot } from 'valtio'

export type MapTooltipState =
  | { type: 'none' }
  | {
      type: 'order'
      ids: string[]
      coordinates: number[]
    }

type SetMapTooltip = (prev: workwave.DeepReadonly<MapTooltipState>) => MapTooltipState
type SetMapTooltipParam = SetMapTooltip | Partial<MapTooltipState> | 'reset'

export const mapTooltipAtom = proxy<MapTooltipState>({ type: 'none' })

// ------------------------------------
// Write functions
// ------------------------------------

export const setMapTooltip = (valueOrFunc: SetMapTooltipParam) => {
  // reset
  if (valueOrFunc === 'reset') return resetMapTooltip()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    const updates = valueOrFunc(snapshot(mapTooltipAtom))

    for (const field of Object.keys(updates)) {
      mapTooltipAtom[field] = updates[field]
    }
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      mapTooltipAtom[field] = valueOrFunc[field]
    }
  }

  return mapTooltipAtom
}

export const resetMapTooltip = () => {
  mapTooltipAtom.type = 'none'

  return mapTooltipAtom
}

// ------------------------------------
// React Hooks
// ------------------------------------

export const useMapTooltip = () => {
  return [useSnapshot(mapTooltipAtom), setMapTooltip] as const
}
