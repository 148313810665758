import styled from 'styled-components'

import { RoundedButton } from './elements/RoundedButton'

type Props = {
  collapsed: boolean
}

export const HoverSensor = styled.div<Props>`
  position: absolute;
  pointer-events: all;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  &::after {
    content: '';
    position: absolute;
    top: 70px;
    left: 100%;
    width: 22px;
    height: 22px;
  }

  ${RoundedButton} {
    opacity: ${p => (p.collapsed ? 1 : 0)};
  }

  &:hover {
    ${RoundedButton} {
      opacity: 1;
    }
  }
`
HoverSensor.displayName = 'HoverSensor'
