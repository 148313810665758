import { useMemo } from 'react'
import { alpha, Stack, Typography, useTheme } from '@mui/material'

import { type SimpleSelectOption } from '@workwave-tidal/tidal/components'
import { BulkSelect } from '@/components/BulkSelect'

import { type FormFields, type FormErrors } from '../../../formFields'
import { useTexts } from '../hooks/useTexts'

export function MobileFutureDaysVisibility() {
  const options = useMobileFutureDaysVisibilityOptions()
  const theme = useTheme()
  const texts = useTexts()

  return (
    <Stack spacing={3}>
      <Typography variant="subtitle1" color={alpha(theme.palette.secondary.contrastText, 0.7)}>
        {texts.routeViewPreferences}
      </Typography>
      <BulkSelect<'mobileFutureDaysVisibility', string, FormFields, FormErrors>
        name="mobileFutureDaysVisibility"
        testId="mobileFutureDaysVisibility"
        label={texts.labels.mobileFutureDaysVisibility}
        mixedLabel={texts.labels.mixed}
        validateOn="change blur"
        options={options}
      />
    </Stack>
  )
}

function useMobileFutureDaysVisibilityOptions() {
  const texts = useTexts()

  return useMemo<SimpleSelectOption<string>[]>(() => {
    return [
      {
        value: '-1',
        label: texts.values.mobileFutureDaysVisibility(-1),
      },
      {
        value: '2',
        label: texts.values.mobileFutureDaysVisibility(2),
      },
    ]
  }, [texts])
}
