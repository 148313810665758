import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Content } from './Content'

export function Root() {
  return (
    <Modal modalId={modalId}>
      <Content />
    </Modal>
  )
}
