import type { Props } from './typings'

import { Block, FieldMessage, FlexRow, RestoreFieldButton } from '@/forms-legacy'

import Checkbox from '../primitives/Checkbox'

export function CheckboxField(props: Props) {
  const {
    info,
    label,
    half = false,
    wrappedInputProps,
    formProps: {
      input: { value, onChange },
      meta: { error, dirty, initial },
    },
    className,
    testid,
  } = props

  return (
    <Block
      className={className}
      half={half}
      error={!!error}
      dirty={!!dirty}
      data-testid={testid}
      data-trackid={testid}
    >
      <FlexRow justifyContent="space-between">
        <FlexRow useAutoWidth>
          <Checkbox {...wrappedInputProps} checked={!!value} onChange={onChange} />
          {label}
        </FlexRow>
        <RestoreFieldButton
          dirty={dirty}
          initial={initial}
          onChange={onChange}
          style={{ marginLeft: 12 }}
        />
      </FlexRow>

      {!!error && <FieldMessage error>{error}</FieldMessage>}
      {!error && !!info && <FieldMessage>{info}</FieldMessage>}
    </Block>
  )
}
