import styled from 'styled-components'

const ReadonlyAudioButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0px 10px;
  margin: 10px 0px;
`

ReadonlyAudioButtonsContainer.displayName = 'ReadonlyAudioButtonsContainer'
export default ReadonlyAudioButtonsContainer
