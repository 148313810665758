import type Geometry from 'ol/geom/Geometry'

import type {
  ExecutionEventMarker,
  PODMarker,
} from '../../../../atoms/executionEventMarkers/executionEventMarkers'

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { getExecutionEventFeatureStyle } from './getExecutionEventFeatureStyle'

export function refreshExecutionEventFeature(
  marker: workwave.DeepReadonly<ExecutionEventMarker | PODMarker>,
  feature: Feature<Geometry>,
) {
  const { lonLat, type } = marker

  // ------------------------
  // Feature Map position
  // ------------------------

  const point = feature.getGeometry() as Point

  if (!point) {
    throw new Error(
      `Trying to update Order Execution Event Marker [${type}] without a valid geometry.`,
    )
  }

  point.setCoordinates(Array.from(lonLat))

  // ------------------------
  // Feature Style

  const style = getExecutionEventFeatureStyle(marker)
  feature.setStyle(style)

  return feature
}
