import { SvgIcon } from '@mui/material'

export function Tortoise() {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path
        d="M18.0501 21L15.3201 16.26C15.3201 14.53 14.2501 13.42 12.9501 13.42C12.0501 13.42 11.2701 13.92 10.8701 14.66C11.2001 14.47 11.5901 14.37 12.0001 14.37C13.3001 14.37 14.3601 15.43 14.3601 16.73C14.3601 18.04 13.3101 19.11 12.0001 19.11H15.3001V21H6.79012C6.55012 21 6.30012 20.91 6.12012 20.72C5.94254 20.5422 5.84279 20.3013 5.84279 20.05C5.84279 19.7987 5.94254 19.5578 6.12012 19.38L6.62012 18.88C6.28012 18.73 6.00012 18.5 5.72012 18.26C5.50012 18.76 5.00012 19.11 4.42012 19.11C4.04218 19.11 3.67973 18.9599 3.41249 18.6926C3.14525 18.4254 2.99512 18.0629 2.99512 17.685C2.99512 17.3071 3.14525 16.9446 3.41249 16.6774C3.67973 16.4101 4.04218 16.26 4.42012 16.26L4.89012 16.34V14.37C4.8888 13.7472 5.0105 13.1302 5.24824 12.5545C5.48598 11.9788 5.83508 11.4558 6.27549 11.0154C6.7159 10.575 7.23896 10.2259 7.81464 9.98813C8.39032 9.75039 9.00728 9.62868 9.63012 9.63H9.65012C11.7701 9.64 13.4201 10.47 13.4201 9.16C13.4201 8.23 13.6201 7.86 13.9601 7.34C13.2301 7 12.4001 6.79 11.5301 6.79C11.0001 6.79 10.5801 6.37 10.5801 5.84C10.5801 5.41 10.8601 5.05 11.2501 4.93L10.5801 4.89C10.0601 4.89 9.63012 4.47 9.63012 3.95C9.63012 3.42 10.0601 3 10.5801 3H11.5301C13.6301 3 15.4701 4.15 16.4601 5.85L16.7401 5.84C17.4501 5.84 18.1101 6.07 18.6501 6.45L19.1001 6.83C21.2701 8.78 21.0001 10.1 21.0001 10.11C21.0001 11.39 19.9401 12.44 18.6501 12.44L18.1601 12.39V12.47C18.1601 13.58 17.6801 14.57 16.9301 15.27L20.2401 21H18.0501ZM18.1601 7.74C17.6301 7.74 17.2101 8.16 17.2101 8.68C17.2101 9.21 17.6301 9.63 18.1601 9.63C18.6801 9.63 19.1101 9.21 19.1101 8.68C19.1101 8.16 18.6801 7.74 18.1601 7.74Z"
        fill="black"
        fillOpacity="0.56"
      />
    </SvgIcon>
  )
}
