import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Download(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 21 14">
        <path d="M17.83525 6.30197c1.43018.48306 2.43748 1.87522 2.43748 3.42894 0 2.01302-1.63607 3.64909-3.6491 3.64909H4.46C2.00035 13.38 0 11.37965 0 8.92c0-2.01619 1.34782-3.77263 3.24997-4.29528C3.3751 2.05262 5.5069 0 8.1091 0c1.62974 0 3.16128.83308 4.0688 2.19199.39278-.11087.78874-.16472 1.20211-.16472 2.3963 0 4.35864 1.90057 4.45525 4.2747zM12.5421 8.42308c.06058-.12116.09146-.2435.03088-.33379-.06177-.12235-.15204-.18293-.2744-.18293h-1.1249s-.18292-2.64535-.2732-2.7677c-.12235-.15204-.45613-.2732-.79111-.2732s-.69965.12116-.8208.2732c-.09147.12235-.24352 2.7677-.24352 2.7677h-1.1249c-.12235 0-.21262.06058-.30409.18293-.03088.12116-.03088.24233.0297.3338.85169 1.24724 2.1904 2.43272 2.25098 2.4636.06058.0297.15205.06058.21263.06058.06058 0 .15205-.03088.18293-.06058.06058-.03088 1.3981-1.18666 2.2498-2.4636z" />
      </svg>
    </IconContainer>
  )
}
