import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const { invalid, status, close } = useController()
  const { handleOnConfirm, handleOnChangeFile } = useControllerActions()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          disabled={invalid || status !== 'ready'}
          onClick={handleOnConfirm}
          variant="contained"
        >
          {texts.commandButtonTitle}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton onClick={close}>{texts.cancelButtonTitle}</ModalFooterButton>
      }
      tertiaryAction={
        <ModalFooterButton onClick={handleOnChangeFile}>
          {texts.changeFileButtonTitle}
        </ModalFooterButton>
      }
    />
  )
}
