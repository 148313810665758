import styled from 'styled-components'
import { normalizeCssValue } from '../../theme'

interface Props {
  primary?: boolean
  gutter?: number | string
  minWidth?: number | string
  maxWidth?: number | string
}

const getMaxWidth = ({ maxWidth = 'none' }: Props) => normalizeCssValue(maxWidth)

const getMinWidth = ({ minWidth = 'none' }: Props) => normalizeCssValue(minWidth)

const getGutter = ({ gutter = 0 }: Props) => normalizeCssValue(gutter)

const getGrow = ({ primary }: Props) => (!!primary ? 1 : 0)

const item = styled.div<Props>``
export const ButtonToolbarItem = styled(item).attrs<Props>(() => ({
  className: 'o-button-toolbar__item',
}))`
  display: flex;
  flex-grow: ${getGrow};
  max-width: ${getMaxWidth};
  min-width: ${getMinWidth};
  margin: 0 ${getGutter};
`

ButtonToolbarItem.displayName = 'ButtonToolbarItem'
