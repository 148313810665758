import type { ReactElement } from 'react'

import { useRegisterModal, useCurrentModal } from '@/atoms'

type Props = {
  modalId: string
  children: ReactElement
}

/**
 * if modalId is the "active" one it renders
 */
export function Modal(props: Props) {
  const { modalId, children } = props

  const { modalId: currentModalId } = useCurrentModal()
  const registered = useRegisterModal(modalId)

  if (!registered) {
    return null
  }

  if (modalId === currentModalId) {
    return children
  }

  return null
}
