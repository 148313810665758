import { useMemo } from 'react'

import { useEventName } from '../../../hooks/useEventName'
import { useTexts } from './useTexts'

export function useEventTitle(event: uui.domain.client.gps.wwgps.EventInfo) {
  const { type, data } = event

  const eventName = useEventName(type)
  const texts = useTexts()

  return useMemo(() => {
    if (type === 'geofence-trigger' && !!data) {
      const { name: geofenceName, type: geofenceType } = data

      const geofenceTriggerType =
        geofenceType === 'arrived' ? texts.geofenceEntering : texts.geofenceExiting

      return `${geofenceTriggerType} ${geofenceName}`
    }

    return eventName
  }, [eventName, type, data, texts])
}
