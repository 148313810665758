import { useTexts } from './useTexts'

export function useComposeFieldsWithError() {
  const texts = useTexts()

  return (errors: uui.domain.ui.forms.Rm360VehicleFormValidationValues) => {
    const { rm = {}, device = {} } = errors

    const entries = Object.entries(rm)
    const rmErrors: string[] = []
    // this will filter the keys for which value is undefined
    for (const [key, value] of entries) {
      if (value) {
        rmErrors.push(
          texts.rmField(key as keyof uui.domain.ui.forms.RouteManagerVehicleDataValidation),
        )
      }
    }

    const deviceEntries = Object.entries(device)
    const deviceFieldsWithError: string[] = []
    // this will filter the keys for which value is undefined
    for (const [key, value] of deviceEntries) {
      if (value) {
        deviceFieldsWithError.push(
          texts.deviceField(key as keyof uui.domain.ui.forms.DeviceDataValidation),
        )
      }
    }

    return rmErrors
  }
}
