import type { RenderItemProps } from '@/components/StructuredVirtualList'

import { memo, useCallback } from 'react'
import { NearMeDisabled } from '@mui/icons-material'

import { Tooltip } from '@/components/primitives/Tooltip'

import { ListItem, ListItemContent, ListItemRow, ListItemTitle } from '@/components/List'

import { useTexts } from './useTexts'

type Props = Extract<RenderItemProps<'drivers'>, { type: 'item' }>

export const DriverItem = memo((props: Props) => {
  const { selected, item, onItemClick, onItemDoubleClick } = props

  const texts = useTexts()

  const email = props.item.driver.deployment.email
  const name = props.item.driver.name
  const untracked = props.item.type === 'free'

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])

  return (
    <ListItem
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      selected={selected}
      testId="drivers-list-item"
    >
      <ListItemTitle
        rightAdornment={
          untracked ? (
            <Tooltip placement="top" title={texts.notTracked}>
              <NearMeDisabled
                sx={{ fontSize: '1rem', transform: 'translateY(2px)' }}
                color="warning"
              />
            </Tooltip>
          ) : undefined
        }
      >
        {name}
      </ListItemTitle>

      <ListItemRow testId="driver-email-row">
        <ListItemContent showTooltip>{email}</ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})

DriverItem.displayName = 'DriverItem'
