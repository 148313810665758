import styled from 'styled-components'

interface ButtonContainerProps {
  active?: boolean
  disabled?: boolean
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: block;
  border-width: 0;
  border-radius: 0;
  background: transparent;
  padding: 0;
  position: relative;
  outline: 0;
  height: 42px;

  &:disabled {
    cursor: default;
  }

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -2px;
    left: 0px;
    width: 100%;
    opacity: ${p => (p.active ? 1 : 0)};
    background: ${p => p.theme.colors.$whiteSmoke};
    border-radius: 7px;
    transition: transform 0.3s ease 0s, opacity;
    transform: scale(${p => (p.active ? 1 : 0)});
  }
`

ButtonContainer.displayName = 'ButtonContainer'
