import { MapControls } from '@/components/MapControls'
import { HorizontalLayout, VerticalLayout, Grow } from '@/components/layout'

import { Map } from './components/Map'
import { EventsRoot } from './components/form/EventsRoot'

export function Events() {
  return (
    <HorizontalLayout data-testid="events-root">
      <EventsRoot />
      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />
          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
