import { useState } from 'react'
import { useIntl } from '@/intl'

export const useErrorLabels = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    emptyField: translate({
      id: 'sms4.validations.requiredField',
    }),
    invalidFormat: translate({
      id: 'sms4.validations.invalidTime',
    }),
  }))

  return api
}
