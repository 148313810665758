import styled from 'styled-components'

interface Props {
  background: string
}

export const TrafficRegionAvatarContainer = styled.div<Props>`
  width: 40px;
  height: 40px;
  background: ${p => p.background};
  border: 1px solid ${p => p.theme.colors.$pureWhite};
  border-radius: 50%;
  margin-right: 16px;
`

TrafficRegionAvatarContainer.displayName = 'TrafficRegionAvatarContainer'
