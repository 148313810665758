import styled from 'styled-components'

const styledComponent = styled.button.attrs<workwave.Button>(() => ({
  className: 'o-transparent-button',
  type: 'button',
}))`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 0;
  outline: 0;
  margin: 0;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
`

styledComponent.displayName = 'TransparentButton'
export default styledComponent
