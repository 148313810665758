import styled from 'styled-components'

const styledComponent = styled.div.attrs({
  className: 'o-store-links__container',
})`
  display: flex;
  flex-direction: column;
`

export default styledComponent
