import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectTerritories } from '@/features/domain/territory'

export function useTerritoryNames() {
  const territories = useSelector(selectTerritories)

  return useMemo(() => {
    return Object.values(territories).reduce<Record<string, string>>((acc, territory) => {
      acc[territory.id] = territory.name
      return acc
    }, {})
  }, [territories])
}
