import { useSelector } from 'react-redux'
import { selectRoutePolylineMapStyles } from '@/features/domain/ui'

export const useRoutePathsMapStyle = () => {
  const { mode, custom } = useSelector(selectRoutePolylineMapStyles)

  if (Object.keys(custom).length > 0) return

  return mode
}
