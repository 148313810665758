import type { RenderItemProps } from '@/components/StructuredVirtualList'

import { memo, useCallback } from 'react'
import { Timelapse } from '@mui/icons-material'

import { ListItem, ListItemContent, ListItemRow, ListItemTitle } from '@/components/List'
import { getRecurrenceLabel } from '@/server-data'
import { intl } from '@/intl'

import { useTexts } from './hooks/useTexts'

import { getDrivingTimeFactorFromBaseFactor } from './utils'

type Props = Extract<RenderItemProps<'trafficProfiles'>, { type: 'item' }>

export const TrafficProfileItem = memo((props: Props) => {
  const { selected, item, onItemClick } = props
  const { recurrence, baseTrafficFactor } = item

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const texts = useTexts()

  const drivingTimeBaseFactor = getDrivingTimeFactorFromBaseFactor(baseTrafficFactor)
  const drivingTimeBaseFactorString = texts.drivingTime(
    drivingTimeBaseFactor % 1 ? drivingTimeBaseFactor.toFixed(2) : `${drivingTimeBaseFactor}`,
  )

  return (
    <ListItem selected={selected} onClick={onClick} testId="traffic-profile-list-item">
      <ListItemTitle>
        {getRecurrenceLabel(recurrence.type, recurrence.value, intl.translate)}
      </ListItemTitle>

      <ListItemRow testId="traffic-profile-overall-driving-time-row">
        <ListItemContent icon={<Timelapse />}>{drivingTimeBaseFactorString}</ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})
TrafficProfileItem.displayName = 'TrafficProfileItem'
