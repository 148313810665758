import { IconButton, Paper, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Column } from '../../../types'
import { DragIcon } from './DragIcon'

type ColumnCardProps = Column & {
  onRemoveColumn: (id: string) => void
}

export function ColumnCard(props: ColumnCardProps) {
  const { id, label, onRemoveColumn } = props

  return (
    <Paper
      key={id}
      elevation={2}
      sx={theme => ({
        border: `1px solid ${theme.palette.divider}`,
      })}
    >
      <Stack flex={1} justifyContent="space-between" direction="row" maxWidth="100%">
        <Stack direction="row" gap={1} alignItems="center" flex="1 1 auto" minWidth={0}>
          <IconButton>
            <DragIcon />
          </IconButton>

          <Typography variant="body1" noWrap>
            {label}
          </Typography>
        </Stack>

        <Stack direction="row" gap={1} alignItems="center" flex="0 1 auto">
          <IconButton onClick={() => onRemoveColumn(id)}>
            <Close />
          </IconButton>
        </Stack>
      </Stack>
    </Paper>
  )
}
