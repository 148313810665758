import type { AlertFormValues } from '../../types'

import { FormLayout, HorizontalRuler } from '@/forms-legacy'

import { Name } from './fields/Name'
import { EventTypesList } from './fields/EventTypesList'
import { DevicesList } from './fields/DevicesList'
import { NotificationAddresses } from './fields/NotificationAddresses'

interface Props {
  initialValues: AlertFormValues
}

export function Form(props: Props) {
  const {
    initialValues: { defaultEmailAddress },
  } = props

  return (
    <FormLayout>
      <Name />
      <HorizontalRuler />
      <EventTypesList />
      <HorizontalRuler />
      <DevicesList />
      <HorizontalRuler />
      <NotificationAddresses defaultEmailAddress={defaultEmailAddress} />
    </FormLayout>
  )
}
