import type { Props, OrderFormValues } from './typings'

import { useState, useEffect, useCallback } from 'react'
import { FormApi } from 'final-form'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { FormLoading } from '@/components/FormLoading'
import { FormColumn } from '@/components/layout'

import { Footer, FormContent, SecondaryColumnHeader } from '@/forms-legacy'
import { useStopEditingOnUnmount } from '@/hooks'
import { setInitialValue } from '@/utils'
import { gis } from '@/server-data'

import * as OrderTexts from '../../../intl'

import { Tabs } from '../../../components/Tabs'
import { OrderAvatar } from '../../../components/OrderAvatar'

import { validateForm, getFieldsWithError } from './formValidation'
import {
  getCreateOrderFromFormValues,
  getOrderStepUpdateChanges,
  connectedDepotDecorator,
  getFormInitialValues,
  latLngDecorators,
} from './utils'

import Form from './Form'

const noop = () => undefined
const mutators = { setInitialValue }

export function SingleEdit(props: Props) {
  const {
    qa,
    tab,
    order,
    orders,
    depots,
    loading,
    mapCenter,
    companies,
    planLocked,
    submitting,
    stopEditing,
    maxBarcodes,
    createOrder,
    maxTagsCount,
    updateOrders,
    validateEmail,
    removeInspectPin,
    workingDayStartSec,
    depotsAsMapByLatLng,
    notificationBehavior,
    allowEmptyCustomFields,
    initialLatLngFromInspectPin,
  } = props

  const [initialValues] = useState(() =>
    getFormInitialValues(
      mapCenter,
      orders,
      workingDayStartSec,
      depots,
      companies,
      order,
      initialLatLngFromInspectPin,
    ),
  )

  const [decorators] = useState([
    latLngDecorators('p', initialValues),
    latLngDecorators('d', initialValues),
    connectedDepotDecorator('p', depotsAsMapByLatLng),
    connectedDepotDecorator('d', depotsAsMapByLatLng),
  ])

  // Callback to handle form submit
  const handleOnSubmit = useCallback(
    (updatedOrder: OrderFormValues, formApi: FormApi<Record<string, any>, Record<string, any>>) => {
      const { dirtyFields, pristine } = formApi.getState()

      order
        ? updateOrders(
            getOrderStepUpdateChanges(
              updatedOrder,
              Object.keys(dirtyFields),
              initialValues,
              workingDayStartSec,
            ),
            pristine,
          )
        : createOrder(getCreateOrderFromFormValues(updatedOrder, workingDayStartSec))
    },
    [initialValues, order, createOrder, workingDayStartSec, updateOrders],
  )

  // Callback to handle form cancel
  const handleOnCancel = useCallback(() => {
    stopEditing()
  }, [stopEditing])

  // Remove inspect pin if it was used to set initial lat lng
  useEffect(() => {
    if (initialLatLngFromInspectPin) {
      removeInspectPin()
    }
  }, [initialLatLngFromInspectPin, removeInspectPin, stopEditing])

  // Stop editing when component unmounts
  useStopEditingOnUnmount()

  const pickupAndDeliveryFromStart: boolean = initialValues.type === 'pd'

  return (
    <FinalForm
      initialValues={initialValues}
      validate={validateForm(
        workingDayStartSec,
        notificationBehavior,
        pickupAndDeliveryFromStart,
        maxBarcodes,
        allowEmptyCustomFields,
        initialValues,
        validateEmail,
      )}
      keepDirtyOnReinitialize
      mutators={mutators}
      onSubmit={handleOnSubmit}
      decorators={decorators}
      render={(formRenderProps: FormRenderProps) => {
        const { handleSubmit, form } = formRenderProps

        // If transaction is not open, show loading indicator
        const transactionOpen = !loading

        // Get form state
        const { values, valid, pristine, errors = {} } = form.getState()
        const { type } = values as OrderFormValues

        // Disable save button if form is invalid or plan is locked
        const saveDisabled = !valid || planLocked

        // Get fields with errors
        const fieldsWithError = getFieldsWithError(errors, type, pickupAndDeliveryFromStart)

        return (
          <FormColumn width={484}>
            <SecondaryColumnHeader
              title={initialValues.order.name}
              description={initialValues.orderStep.location?.address || ''}
              avatar={
                order ? (
                  <OrderAvatar extendedOrderStep={order} style={{ marginRight: 16 }} size={42} />
                ) : null
              }
              errors={{
                description:
                  !order || (!order.atDepot && gis.isNullIslandLatLng(order.location.latLng)),
              }}
            >
              <Tabs disabled setTab={noop} tab={tab} />
            </SecondaryColumnHeader>
            <FormContent
              qa={qa}
              testId="orderSingleEdit"
              footer={
                transactionOpen ? (
                  <Footer
                    errorTitle={OrderTexts.getFormErrorsTitleText()}
                    errors={fieldsWithError}
                    submitting={submitting}
                    primary={{
                      text: OrderTexts.getFormSaveText(),
                      title: OrderTexts.getSubmitButtonTitle(planLocked, valid, pristine),
                      disabled: saveDisabled,
                      onClick: handleSubmit,
                    }}
                    secondary={{
                      text: OrderTexts.getCancelText(),
                      onClick: handleOnCancel,
                      disabled: submitting,
                    }}
                  />
                ) : undefined
              }
            >
              {transactionOpen ? (
                <Form
                  {...props}
                  formRenderProps={formRenderProps}
                  initialValues={initialValues}
                  maxTagsCount={maxTagsCount}
                />
              ) : (
                <FormLoading />
              )}
            </FormContent>
          </FormColumn>
        )
      }}
    />
  )
}
