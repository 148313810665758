import { Typography } from '@mui/material'

import { durationUtils } from '@/server-data'
import { intl } from '@/intl'

interface Props {
  durationSec: number
}

export function Duration(props: Props) {
  const { durationSec } = props
  const formatSeconds = durationUtils.formatSeconds(intl.translate)

  return (
    <Typography
      sx={{ paddingX: 1, paddingY: 0.25, backgroundColor: 'action.hover', borderRadius: '4px' }}
      variant="body2"
    >
      {formatSeconds(durationSec, 'SHORT')}
    </Typography>
  )
}
