import { useState, useCallback, useMemo } from 'react'

import { SearchableListField } from '@/forms-legacy'

import { useCollapsibleState } from '../../../../../atoms/collapsibleState'
import { CollapseButton } from '../../../../../components/CollapseButton'

import { useTexts } from '../../hooks/useTexts'

import { RegionListItem } from './RegionListItem'
import { regionsRenderText } from './regionsRenderText'

interface Props {
  regions: uui.domain.client.rm.Region[]
}

export function RegionIds(props: Props) {
  const { regions } = props
  const regionsCount = regions.length

  const texts = useTexts()
  const [collapsed, toggleCollapsed] = useCollapsibleState('regions')

  const [visibleRegions, setVisibleRegions] = useState(regions)
  const onFilter = useCallback(
    (txt: string) => {
      const filter = txt.toLocaleLowerCase().trim()

      const value = regions.filter(region => {
        return region.name.toLocaleLowerCase().trim().includes(filter)
      })

      setVisibleRegions(value)
    },
    [regions],
  )

  const wrappedInputProps = useMemo(() => {
    return {
      ListItem: RegionListItem,
      visibleRows: Math.min(8, regionsCount),
      rowHeight: 26,
      renderText: regionsRenderText,
      onFilter: onFilter,
      values: visibleRegions,
      selectionLimit: -1,
    }
  }, [onFilter, visibleRegions, regionsCount])

  return (
    <>
      <CollapseButton toggleCollapsed={toggleCollapsed} collapsed={collapsed}>
        {texts.regionsList}
      </CollapseButton>
      <div hidden={collapsed}>
        <SearchableListField<uui.domain.client.rm.Region>
          name="regionIds"
          testid="regionIds"
          label={texts.regionsList}
          wrappedInputProps={wrappedInputProps}
        />
      </div>
    </>
  )
}
