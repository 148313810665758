import { useMemo } from 'react'
import { proxyWithComputed } from 'valtio/utils'
import { useSnapshot } from 'valtio'

import { domainProxy } from '@/store'

interface ComputedData {
  isFetchingGpsData: boolean
  isRescheduling: boolean
}

export const loadingStateAtom = proxyWithComputed<uui.domain.api.LoadingState, ComputedData>(
  {
    type: 'none',
    details: [],
  },
  {
    isRescheduling: snap => Boolean(snap.details.find(detail => detail.id === 'schedulerLoading')),
    isFetchingGpsData: snap => Boolean(snap.details.find(detail => detail.id === 'fetchGpsData')),
  },
)

export function resetLoadingState() {
  loadingStateAtom.type = 'none'
  loadingStateAtom.details = []
}

// ---------------------------------------------------------------------------
// REACT HOOKS
// ---------------------------------------------------------------------------

export function useLoadingState() {
  return useSnapshot(loadingStateAtom)
}

export function useIsLoading(
  ...loadingStateTypes: uui.domain.actions.notification.LoadingStateIdentifier[]
) {
  const { details, type } = useSnapshot(loadingStateAtom)

  const isLoading = useMemo(() => {
    // eslint-disable-next-line valtio/state-snapshot-rule
    return !!details.find(detail => loadingStateTypes.includes(detail.id))
  }, [details, loadingStateTypes])

  return loadingStateTypes.length > 0 ? isLoading : type !== 'none'
}

// ---------------------------------------------------------------------------
// SUBSCRIBE METHOD
// ---------------------------------------------------------------------------

export function subscribeLoadingState() {
  return domainProxy.subscribeToNotifications(
    notification => {
      if (notification.notificationType === 'loadingState') {
        loadingStateAtom.type = notification.payload.type
        loadingStateAtom.details = notification.payload.details
        loadingStateAtom.message = notification.payload.message
      }
    },
    ['loadingState'],
  )
}
