import type { LazyCreateFormConfig } from '@workwave-tidal/tidal/form-fairy'

import type { FormErrors, FormFields } from './formFields'

import { useState } from 'react'
import { parse } from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { getDateInTimezone } from '@/server-data'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectBreadcrumbTimeRange } from '@/features/domain/breadcrumb'

import { useTexts } from '../useTexts'

import { createValidations } from './validations/createValidations'

export function useFormConfig(): LazyCreateFormConfig<FormFields, FormErrors> {
  const breadcrumbTimeRange = useSelector(selectBreadcrumbTimeRange)
  const { minDate, maxDate } = useSelector(selectCalendarRange)
  const { today, transitions } = useSelector(selectUserConfiguration)
  const texts = useTexts()

  if (minDate !== maxDate) {
    throw new Error('This should never happen')
  }

  const [formConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    const currentDayMidnightTimeStamp = parse(minDate, 'yyyyMMdd', new Date()).getTime()

    return {
      validations: createValidations(minDate === today, transitions, texts),
      overrideValues:
        breadcrumbTimeRange === 'live'
          ? {
              type: 'live',
              timeRangeStart: 0,
              timeRangeEnd:
                (getDateInTimezone(new Date().getTime(), transitions).getTime() -
                  currentDayMidnightTimeStamp) /
                1000,
            }
          : {
              type: 'custom',
              timeRangeEnd: (breadcrumbTimeRange.end - currentDayMidnightTimeStamp) / 1000 / 60, // minutes from midnight
              timeRangeStart: (breadcrumbTimeRange.start - currentDayMidnightTimeStamp) / 1000 / 60, // minutes from midnight
            },
    }
  })

  return formConfig
}
