import type { FormFields, FormErrors } from '../formFields'

import { useState, useCallback } from 'react'

import { useFormApi } from '@workwave-tidal/tidal/form-fairy'
import { buildTrackingPagePreviewUrl } from '@/features/domain/territory'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useGeneratePreviewUrl(onOpen: () => void) {
  const [generatingPreviewUrl, setGeneratingPreviewUrl] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined)
  const isUnmounted = useIsUnmounted()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const formApi = useFormApi<FormFields, FormErrors>()

  const generatePreviewUrl = useCallback(async () => {
    if (formApi.getMeta().status === 'invalid') return

    const formIsValid = await formApi.validate()
    if (isUnmounted() || !formIsValid) return

    setGeneratingPreviewUrl(true)

    const formValues = formApi.getValues()

    try {
      const response = await dispatch(buildTrackingPagePreviewUrl(formValues))
      if (isUnmounted()) return

      if (!buildTrackingPagePreviewUrl.fulfilled.match(response)) {
        throw new Error(response.payload?.message ?? 'Internal error')
      }

      setPreviewUrl(response.payload)
      onOpen()
    } catch (error) {
      toast.error(error.message)
      setPreviewUrl(undefined)
    }

    setGeneratingPreviewUrl(false)
  }, [dispatch, formApi, toast, isUnmounted, onOpen])

  return {
    generatingPreviewUrl,
    generatePreviewUrl,
    previewUrl,
  }
}
