import type { LocationEditorProps } from '../typings'

import { produce } from 'immer'
import { useCallback, useEffect, useRef } from 'react'

import { getLocationPin, updateLocationPin } from '@/map'
import { useFormFieldOnChange } from './useFormFieldOnChange'

export function useConnectRestoreButtonToLocationPin(options: LocationEditorProps) {
  const { pinId, onConnectedPlaceChanged, connectedPlaceId } = options
  const onChange = useFormFieldOnChange(options)

  const initialConnectedPlaceIdRef = useRef(connectedPlaceId)
  const api = useRef({ onChange, onConnectedPlaceChanged })
  useEffect(() => {
    api.current = { onChange, onConnectedPlaceChanged }
  }, [onChange, onConnectedPlaceChanged])

  return useCallback(
    (location: uui.domain.client.Location) => {
      const pin = getLocationPin(pinId, true)

      const { onChange, onConnectedPlaceChanged } = api.current
      const initialConnectedPlaceId = initialConnectedPlaceIdRef.current

      if (pin) {
        // reset the pin marker.
        // Required because the pin marker lat-lng is never updated as a side-effect
        // from form changes
        updateLocationPin(pinId, prevPin =>
          produce(prevPin, draft => {
            draft.pin.location = location
            draft.pin.latLng = location.latLng
          }),
        )
      }

      // reset the form
      onChange(location)

      if (onConnectedPlaceChanged) {
        onConnectedPlaceChanged(initialConnectedPlaceId)
      }
    },
    [pinId],
  )
}
