import { Stroke, Style } from 'ol/style'
import tinycolor from 'tinycolor2'

import { genericStyles } from '../../../genericStyles'

const breadcrumbMarkerStylesCache: Map<string, Style[]> = new Map()

/**
 * Return the right style to be used by a Breadcrumb path feature
 */
export function getBreadcrumbPathFeatureStyle(
  marker: uui.domain.ui.map.markers.Breadcrumb,
  mode: uui.domain.ui.map.markers.MapStyles['breadcrumb']['mode'],
) {
  if (mode === 'off' || !marker.fetched) return genericStyles.hidden

  const cacheId = marker.color

  if (breadcrumbMarkerStylesCache.has(cacheId)) {
    const cachedStyle = breadcrumbMarkerStylesCache.get(cacheId)
    if (cachedStyle) return cachedStyle
  }

  const { r, g, b } = tinycolor(marker.color).toRgb()

  const styles = [
    // Full stroke
    new Style({
      stroke: new Stroke({
        color: [r, g, b, 1],
        width: 8,
      }),
    }),
  ]

  breadcrumbMarkerStylesCache.set(cacheId, styles)

  return styles
}
