import { useResetCrudSelectionOnUnmount } from '@/atoms'

import { useRegionsRoot } from './hooks/useRegionsRoot'
import { useHideRegionWhileEditing } from './hooks/useHideRegionWhileEditing'

import { List } from './List'
import { SingleView } from './Single/View'
import { BulkView } from './Bulk/View'
import { SingleEdit } from './Single/Edit'

export function RegionsRoot() {
  const { editing, regions, selection, selectedRegions } = useRegionsRoot()

  const bulk = selectedRegions.length > 1
  const single = selectedRegions.length === 1

  const region = selectedRegions[0]

  // add or show the selected feature when entering in edit mode
  useHideRegionWhileEditing(editing, selection[0])

  useResetCrudSelectionOnUnmount('regions')

  return (
    <>
      <List editing={editing} regions={regions} selection={selection} />
      {single && !editing && region && <SingleView region={region} />}
      {bulk && <BulkView regions={selectedRegions} />}
      {editing && !bulk && <SingleEdit region={region} regions={regions} />}
    </>
  )
}
