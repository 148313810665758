import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Reverse } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  permission: 'enabled' | 'disabledForInvalidRoute'
}

export function ReverseButton(props: Props) {
  const { permission, onClick } = props
  const texts = useTexts()

  const disabled = permission !== 'enabled'

  return (
    <ReadOnlyTooltip
      render={preventEditing => (
        <NavigoIconButton
          onClick={onClick}
          disabled={preventEditing || disabled}
          text={texts.reverse}
          Icon={
            <Reverse size={17} color={preventEditing || disabled ? '$silver' : '$nightRider'} />
          }
          testid="navigo-routes-overview-actions-reverse"
        />
      )}
    />
  )
}
