import type { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { theme } from '@/styles'

interface Props {
  size: number
  duration?: number
}

const useStyles = makeStyles<Theme, Props>({
  root: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 10,
    width: ({ size }) => `${size}px`,
    height: '4px',
    background: theme.colors.$dodgerBlue,

    animation: `$loadAnimation infinite`,
    animationDuration: ({ duration = 3 }) => `${duration}s`,
  },

  '@keyframes loadAnimation': {
    from: {
      left: ({ size }) => `-${size}px`,
    },
    '65%': {
      left: '100%',
    },
    '100%': {
      left: ({ size }) => `-${size}px`,
    },
  },
})

export function SuperCarIndicator(props: Props) {
  const classes = useStyles(props)

  return <div className={classes.root} />
}
