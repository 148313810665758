type Load = Record<string, number>

interface ExplodedLoads {
  loadCapacities: Load
  minLoadCapacities: Load
}

export function explodeLoads(
  vehicleLoads: Record<string, uui.domain.ui.forms.VehicleLoad>,
): ExplodedLoads {
  return Object.entries(vehicleLoads).reduce<ExplodedLoads>(
    (acc: ExplodedLoads, [key, load]) => {
      acc.loadCapacities[key] = load.value
      acc.minLoadCapacities[key] = load.min
      return acc
    },
    {
      loadCapacities: {},
      minLoadCapacities: {},
    },
  )
}
