import { WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useModalData } from '../../../hooks/useModalData'
import { useTexts } from '../../../useTexts'

export function InfoCard() {
  const texts = useTexts()
  const data = useModalData()

  return (
    <WarningCard
      preset="warning"
      Icon={<WarningTriangle size={14} />}
      title={texts.cardTitle(data?.orderStep.type, data.pairedOrderStepUnAssigned)}
      description={texts.cardText}
    />
  )
}
