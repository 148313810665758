import { PureComponent, CSSProperties } from 'react'

// import * as Texts from '@root/intl'
import {
  HorizontalRuler,
  DurationStepField,
  TagsListField,
  TimeWindowListField,
  TimeWindowExceptionListField,
  TextField,
} from '@/forms-legacy'

import * as OrderTexts from '../../../../../intl'

import OrderStepContainer from './OrderStepContainer'
import Address from './components/Address'
import ContactInformation from './components/ContactInformation'
import CustomFields from './components/CustomFields'
import Barcodes from './components/Barcodes'

import { Props } from './typings'
const defaultTagsCount = 20
const fullDayInSeconds = 86340 // 23 hours and 59 minutes in seconds
const secToMin = (seconds: number): number => Math.round(seconds / 60)
const minToSec = (minutes: number): number => {
  const result = minutes * 60

  return result > fullDayInSeconds ? fullDayInSeconds : result
}

export default class OrderStep extends PureComponent<Props> {
  render() {
    const {
      orderStepType,
      tags,
      showLastRuler,
      disabledFields = [],
      allowBarcodes,
      maxTagsCount = defaultTagsCount,
      h24,
    } = this.props

    const lastRulerStyle: CSSProperties = showLastRuler
      ? {
          marginBottom: 20,
        }
      : {
          marginBottom: 120,
          opacity: 0,
        }

    const disabledTWE: boolean = disabledFields.includes('timeWindowExceptions')

    return (
      <OrderStepContainer data-testid={orderStepType}>
        <Address {...this.props} />
        <HorizontalRuler />

        <ContactInformation {...this.props} />

        <DurationStepField
          name={`${orderStepType}.serviceTimeSec`}
          label={OrderTexts.geFieldLabelServiceTimeText()}
          format={secToMin}
          parse={minToSec}
          wrappedInputProps={{
            min: 0,
            step: 10,
            useSnap: false,
            children: 'hh:mm',
          }}
          half
          testid="serviceTime"
        />
        <HorizontalRuler />

        <TimeWindowListField
          name={`${orderStepType}.timeWindows`}
          label={OrderTexts.getTimeWindowListText()}
          wrappedInputProps={{ h24 }}
          testid="timeWindows"
        />
        <TimeWindowExceptionListField
          name={`${orderStepType}.timeWindowExceptions`}
          label={OrderTexts.getTimeWindowExceptionListText()}
          wrappedInputProps={{ disabled: disabledTWE, h24 }}
          testid="timeWindowExceptions"
        />
        <HorizontalRuler />

        <TagsListField
          wrappedInputProps={{ suggestions: tags, renderText: OrderTexts.getTagsRenderText }}
          maxCount={maxTagsCount}
          testid="tagsIn"
          label={OrderTexts.getTagsInLabelText()}
          name={`${orderStepType}.tagsIn`}
        />

        <TagsListField
          wrappedInputProps={{
            suggestions: tags,
            isBanList: true,
            renderText: OrderTexts.getTagsRenderText,
          }}
          maxCount={maxTagsCount}
          testid="tagsOut"
          label={OrderTexts.getTagsOutLabelText()}
          name={`${orderStepType}.tagsOut`}
        />

        <HorizontalRuler />

        <TextField
          name={`${orderStepType}.notes`}
          label={OrderTexts.geFieldLabelNotesText()}
          wrappedInputProps={{
            multi: true,
            showCounter: true,
            showResetIcon: true,
          }}
          testid="notes"
        />
        <HorizontalRuler />

        <CustomFields {...this.props} />

        <HorizontalRuler />

        {allowBarcodes && <Barcodes {...this.props} />}

        <HorizontalRuler style={lastRulerStyle} />
      </OrderStepContainer>
    )
  }
}
