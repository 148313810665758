export type { Theme } from './theme'
export { getColorValue, getSize, theme } from './theme'
export { MuiTheme } from './MuiTheme'

export * from './cssMixins'
export * from './colorUtils'
export * from './customStyles'
export * from './layoutSizes'
export * from './ww-normalize'
export * from './utils'

/**
brandColor is an exception were a color is outside of the theme
it is used to fill the background of the header
but it may be used in the future
for this reason it has moved into the `@/styles` folder
this could change after we move every color into css variables
*/
export const brandColor = '#002D5C'
