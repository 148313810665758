import { useMemo } from 'react'
import { useTheme } from '@mui/material'
import { useTexts } from './useTexts'
import { useOnDeleteUser } from './useOnDeleteUser'
import { useOnChangePassword } from './useOnChangePassword'
import { useOnToggleUser } from './useOnToggleUser'

export type MenuOption = {
  id: 'toggleUser' | 'changePassword' | 'delete'
  title: string
  action?: () => void
  color?: string
}

type Props = {
  selectedUsers: uui.domain.client.rm.RouteManagerUser[]
}

export function useMenuOptions(props: Props): MenuOption[] {
  const onChangePassword = useOnChangePassword()
  const onDeleteUser = useOnDeleteUser()
  const onToggleUser = useOnToggleUser()
  const { selectedUsers } = props
  const { enabled, id } = selectedUsers[0]
  const texts = useTexts()
  const theme = useTheme()

  const toggleUserTitle = enabled ? texts.menu.disabled : texts.menu.enabled

  return useMemo(
    () => [
      { id: 'toggleUser', title: toggleUserTitle, action: () => onToggleUser(enabled, id) },
      { id: 'changePassword', title: texts.menu.changePassword, action: onChangePassword },
      {
        id: 'delete',
        title: texts.menu.delete,
        action: onDeleteUser,
        color: theme.palette.error.main,
      },
    ],
    [enabled, id, onChangePassword, onDeleteUser, onToggleUser, texts, theme, toggleUserTitle],
  )
}
