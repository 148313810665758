import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'

import { selectTelematicsTenantSources } from '@/features/domain/account'

import { ViewContainer } from '@/formUi'

import { useIntegrationData } from './hooks/useIntegrationData'
import { StatsAndActions } from './components/StatsAndActions'
import { LoadingSkeleton } from './components/LoadingSkeleton'
import { NoIntegrations } from './components/NoIntegrations'
import { DeviceTable } from './components/DeviceTable'
import { TitleBar } from './components/TitleBar'

type Props = {
  setIntegrationId: (integrationId: string | undefined) => void
  integrationId: string
}

export function IntegrationPage(props: Props) {
  const { integrationId, setIntegrationId } = props

  const telematicsTenantSources = useSelector(selectTelematicsTenantSources)
  const { loading, data, filter, onChangeFilter, unfilteredDataSize } =
    useIntegrationData(integrationId)

  const tenantSource = telematicsTenantSources[integrationId]

  useEffect(() => {
    if (integrationId && !tenantSource) {
      setIntegrationId(undefined)
    }
  }, [integrationId, tenantSource, setIntegrationId])

  // If the integrationId is not valid, redirect to the integrations page
  if (!tenantSource?.active) {
    setIntegrationId(undefined)
  }

  return (
    <ViewContainer width="100%" testid="integration-page-view">
      <Stack direction="row" width="100%" justifyContent="center" paddingX={4}>
        <Stack spacing={5} width="100%" maxWidth={1152}>
          <Stack spacing={5}>
            {loading || !data ? (
              <LoadingSkeleton />
            ) : (
              <>
                <TitleBar tenantSource={tenantSource} setIntegrationId={setIntegrationId} />
                <StatsAndActions tenantSource={tenantSource} />
                {unfilteredDataSize === 0 ? (
                  <NoIntegrations />
                ) : (
                  <>
                    <DeviceTable
                      tenantSource={tenantSource}
                      data={data}
                      filter={filter}
                      onChangeFilter={onChangeFilter}
                    />
                  </>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ViewContainer>
  )
}
