import { type Column, type Grid, type Menu, type MenuItemConfig } from '@bryntum/schedulerpro'
import { type RefObject, useCallback, useState } from 'react'

export function useColumnsPanel(containerRef: RefObject<HTMLDivElement>) {
  const [columnsPanelCoordinates, setColumnsPanelCoordinates] = useState({ x: 0, y: 0 })
  const [columnsPanelVisible, setColumnsPanelVisible] = useState(false)

  const openColumnsPanel = useCallback(() => {
    if (!containerRef.current) return

    const boundingClientRect = containerRef.current.getBoundingClientRect()

    setColumnsPanelCoordinates({ x: boundingClientRect.left + 26, y: boundingClientRect.top + 26 })
    setColumnsPanelVisible(true)
  }, [containerRef])

  const closeColumnsPanel = useCallback(() => {
    setColumnsPanelVisible(false)
  }, [])

  const onHeaderMenuBeforeShow = useCallback(
    (event: {
      source: Grid
      menu: Menu
      items: Record<string, Partial<MenuItemConfig>>
      column: Column
      // The point attribute is not present in the type definition, but it is present in the payload. Can't find a way to retrieve coordinates without it.
      point: [number, number]
    }) => {
      setColumnsPanelCoordinates({ x: event.point[0], y: event.point[1] })
      setColumnsPanelVisible(true)

      return false
    },
    [],
  )

  return {
    columnsPanelCoordinates,
    onHeaderMenuBeforeShow,
    columnsPanelVisible,
    closeColumnsPanel,
    openColumnsPanel,
  }
}
