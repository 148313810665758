import type { DayToDispatch } from '../../../typings'

import { format } from 'date-fns/esm'

import { Button } from '@/local/components'
import { PaperPlane } from '@/icons'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { useTexts } from '../useTexts/useTexts'
import { useActions } from '../useActions'

interface Props {
  day: DayToDispatch
}

export function DispatchButton(props: Props) {
  const { canBeDispatched, date, isToday, isTomorrow } = props.day

  const texts = useTexts()
  const { approveDate } = useActions()

  const title = texts.dispatchButtonTitle(!canBeDispatched)

  const className = isToday
    ? 'pendo-dispatch-routes_dispatch_today-button'
    : 'pendo-dispatch-routes_dispatch_not-today-button'

  const testid = isToday
    ? 'dispatch-routes_dispatch_today-button'
    : 'dispatch-routes_dispatch_not-today-button'

  const testLabel = isToday ? 'today' : isTomorrow ? 'tomorrow' : undefined

  return (
    <ReadOnlyTooltip
      canEdit={canBeDispatched}
      title={title}
      flex="1 1 auto"
      render={preventEditing => (
        <Button
          disabled={preventEditing}
          preset="confirm"
          onClick={approveDate}
          canFocus={false}
          data-date={format(date, 'yyyyMMdd')}
          className={className}
          data-testlabel={testLabel}
          data-testid={testid}
          data-trackid={testid}
        >
          <PaperPlane as="span" size={12} marginRight={6} />
          {texts.dispatch}
        </Button>
      )}
    />
  )
}
