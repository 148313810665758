import type { TrackingAppFormActions as Props } from '../types'

import { Box } from '@mui/material'
import { FormHeader, Form } from '@workwave-tidal/form-ui/layout'

import { useTrackingPagePreviewModal } from '@/components/modals/TrackingPagePreviewModal'

import { useTexts } from '../hooks/useTexts'

import { useGeneratePreviewUrl } from './hooks/useGeneratePreviewUrl'
import { useFormValidation } from './hooks/useFormValidation'
import { useOnSubmit } from './hooks/useOnSubmit'

import { CompanySlogan } from './fields/CompanySlogan'
import { CompanyPhone } from './fields/CompanyPhone'
import { CompanyEmail } from './fields/CompanyEmail'
import { ShowETARange } from './fields/ShowETARange'
import { CompanyName } from './fields/CompanyName'
import { Pods } from './fields/Pods'

import { FormFooter } from './FormFooter'

export function TrackingPageForm(props: Props) {
  const { onCancel, updateTrackingAppData } = props

  const texts = useTexts()
  useFormValidation()
  const onSubmit = useOnSubmit(updateTrackingAppData, onCancel)
  const { Modal, show } = useTrackingPagePreviewModal()
  const { previewUrl, generatePreviewUrl, generatingPreviewUrl } = useGeneratePreviewUrl(show)

  return (
    <Box width={360} height="100%" data-testid="form__column-secondary" display="flex" paddingY={2}>
      <Form
        fieldsSpacing={4}
        fieldsPadding="0 32px"
        testId="voice-form"
        onEnter={onSubmit}
        header={<FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />}
        footer={
          <FormFooter
            onSubmit={onSubmit}
            onCancel={onCancel}
            generatePreviewUrl={generatePreviewUrl}
            generatingPreviewUrl={generatingPreviewUrl}
          />
        }
      >
        <CompanyName />
        <CompanySlogan />
        <CompanyPhone />
        <CompanyEmail />
        <ShowETARange />
        <Pods />
      </Form>
      {previewUrl && <Modal previewUrl={previewUrl} />}
    </Box>
  )
}
