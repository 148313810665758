import { CircularProgress } from '@mui/material'
import { LightBulb } from '@/icons'

import { BackButton } from '../../../../elements/BackButton'
import { NavigoIconButton } from '../../../../elements/NavigoIconButton'
import { Styler } from './components/Styler'

import { useActions } from './hooks/useActions'
import { useData } from './hooks/useData'
import { useTexts } from './useTexts'

export function MapStyle() {
  const texts = useTexts()
  const { goToOverview, currentOrderIds, activeMapStyle } = useData()
  const {
    methodInExecution,
    actions: { maximizeOrders, popoutOrders, showOrders, hideOrders },
  } = useActions(currentOrderIds)

  return (
    <>
      <Styler
        ids={currentOrderIds}
        hideOrders={hideOrders}
        showOrders={showOrders}
        activeMapStyle={activeMapStyle}
        maximizeOrders={maximizeOrders}
        methodInExecution={methodInExecution}
      />

      <NavigoIconButton
        text={texts.popOutOrder(currentOrderIds.length)}
        Icon={
          methodInExecution === 'popoutOrders' ? (
            <CircularProgress size={16} color="primary" />
          ) : (
            <LightBulb size={12} color="$nightRider" />
          )
        }
        onClick={popoutOrders}
        testid="navigo-order-popout-orders"
      />

      <BackButton onClick={goToOverview} />
    </>
  )
}
