import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Ask(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 20 20">
        <path d="M10 1.667c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333Zm0 1.25A7.074 7.074 0 0 1 17.083 10 7.074 7.074 0 0 1 10 17.083 7.074 7.074 0 0 1 2.917 10 7.074 7.074 0 0 1 10 2.917Zm0 2.5a2.51 2.51 0 0 0-2.5 2.5v.208a.625.625 0 1 0 1.25 0v-.208c0-.698.552-1.25 1.25-1.25.697 0 1.25.552 1.25 1.25 0 .975-.254 1.097-.698 1.447-.223.174-.514.376-.763.715-.249.34-.414.812-.414 1.38a.625.625 0 1 0 1.25 0c0-.356.07-.5.172-.64.102-.14.28-.279.526-.472.493-.388 1.177-1.114 1.177-2.43a2.51 2.51 0 0 0-2.5-2.5Zm0 7.916A.833.833 0 1 0 10 15a.833.833 0 0 0 0-1.667Z" />
      </svg>
    </IconContainer>
  )
}
