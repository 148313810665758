import { styled } from '@mui/material'

export const SwitchSection = styled('div', { name: 'SwitchSection' })(({ theme }) => ({
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  minWidth: 286,
  fontSize: 15,
}))
