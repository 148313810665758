import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, resetCrudSelection } from '@/atoms'

import { selectTrafficAreasListStructure } from '@/features/domain/lists'

import { useEditing } from '../../../../../../../hooks/useEditing'

export const useDataForList = () => {
  const structure = useSelector(selectTrafficAreasListStructure)
  const [trafficAreaSelection, setSelection] = useCrudSelection('trafficArea')
  const { setEditCreateTrafficArea } = useEditing('trafficArea')

  const deselectAll = () => resetCrudSelection('trafficArea')

  const onCreateTrafficArea = useCallback(() => {
    setSelection([])
    setEditCreateTrafficArea()
  }, [setEditCreateTrafficArea, setSelection])

  return {
    structure,
    trafficAreaSelection,
    selectTrafficAreas: setSelection,
    deselectAll,
    onCreateTrafficArea,
  }
}
