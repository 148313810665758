import { LeftArrow } from '@/icons'

import TextButton from '../lib/TextButton'
import ColumnHeader from './elements/ColumnHeader'
import ItemRendererColumn from './elements/ItemRendererColumn'

import ClearSelectionIcon from './elements/ClearSelectionIcon'
import SecondaryColumnHeaderRow from './elements/SecondaryColumnHeaderRow'
import SecondaryColumnTitle from './elements/SecondaryColumnTitle'
import SecondaryColumnDescription from './elements/SecondaryColumnDescription'

import { Props } from './typings'

const closeBtnTestid = 'secondary-column__close-btn'

export function SecondaryColumnHeader(props: Props) {
  const { avatar, title, description, action, customDescription, actionButtons, errors, children } =
    props

  return (
    <ColumnHeader padding={!!children ? '14px 16px' : undefined}>
      <SecondaryColumnHeaderRow>
        <ItemRendererColumn>{avatar}</ItemRendererColumn>

        <ItemRendererColumn primary>
          <SecondaryColumnTitle error={errors?.title}>{title}</SecondaryColumnTitle>
          {!!customDescription && customDescription}
          {!customDescription && (
            <SecondaryColumnDescription error={errors?.description}>
              {description}
            </SecondaryColumnDescription>
          )}
        </ItemRendererColumn>
        {!!actionButtons && actionButtons}
        {!!action && (
          <TextButton onClick={action} data-testid={closeBtnTestid} data-trackid={closeBtnTestid}>
            <ClearSelectionIcon>
              <LeftArrow size={9} />
            </ClearSelectionIcon>
          </TextButton>
        )}
      </SecondaryColumnHeaderRow>

      {!!children && children}
    </ColumnHeader>
  )
}

export default SecondaryColumnHeader
