import { Fragment } from 'react'

import { P2 } from '@/local/components'

interface Props {
  rows: string[]
}

export function NotificationContent(props: Props) {
  const { rows } = props

  return (
    <P2>
      {rows.map((item, key) => (
        <Fragment key={key}>
          {item}
          <br />
        </Fragment>
      ))}
    </P2>
  )
}
