import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { selectUserConfiguration } from '@/features/domain/user'

import { generateAbsolutePath } from '../utils/generateAbsolutePath'
import { getUserHome } from '../utils/getUserHome'

/**
 * @private
 */
export function RedirectToHome() {
  const { userType } = useSelector(selectUserConfiguration)
  const route = getUserHome(userType)

  return <Navigate to={generateAbsolutePath(route)} replace />
}
