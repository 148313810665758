import { PureComponent } from 'react'
import { Alert, Box, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { Block, Label, FieldMessage, LoadsList, RestoreFieldButton } from '@/forms-legacy'

import { Props } from './typings'

export default class LoadsListField extends PureComponent<Props> {
  render() {
    const { label, name, info, half = false, wrappedInputProps, formProps, testid } = this.props
    const {
      input: { value, onChange },
      meta: { error, dirty, initial },
    } = formProps

    const fieldHasSomeUndefinedLoad = Object.keys(value).some(
      load => !wrappedInputProps.suggestions.includes(load),
    )

    return (
      <Block half={half} error={!!error} dirty={!!dirty} data-testid={testid}>
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <LoadsList {...wrappedInputProps} onChange={onChange} value={value} type="single" />
        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
        {fieldHasSomeUndefinedLoad && (
          <Box paddingTop={1.5}>
            <Alert
              severity="warning"
              sx={{
                '& .MuiAlert-icon': {
                  alignItems: 'center',
                },
              }}
            >
              <FormattedMessage
                id="form.order.loads.alert.undefinedLoad"
                values={{
                  b: chunks => (
                    <Typography fontWeight={600} display="inline">
                      {chunks}
                    </Typography>
                  ),
                }}
              />
            </Alert>
          </Box>
        )}
      </Block>
    )
  }
}
