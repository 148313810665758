import type { FormRenderProps } from 'react-final-form'

import { Form as FinalForm } from 'react-final-form'

import { useReadOnly, useStopEditingOnUnmount } from '@/hooks'
import { FormColumn } from '@/components/layout'
import { useResetEditingState, useEnvironmentQa } from '@/atoms'
import { ReadonlyLabel, SecondaryColumnHeader, FormContent } from '@/forms-legacy'

import { Tabs } from '../../../../components/Tabs'
import { useFormViewType } from '../../../../hooks/useFormViewType'
import { useCountRemainingTrackedVehicle } from '../../../../hooks/useCountRemainingTrackedVehicle'

import { Footer } from '../components/Footer'
import { useLockedNames } from '../hooks/useLockedNames'

import { useTexts } from './hooks/useTexts'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useFormInitialValue } from './hooks/useFormInitialValue'

import { useComposeFieldsWithError } from './hooks/useComposeFieldsWithError'

import { createValidate } from './createValidate'
import { Form } from './Form'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  transactionOpen: boolean
  creating?: boolean
}

const labelStyle = { padding: '5px 36px' }

export function SingleEditRm(props: Props) {
  const { unifiedVehicle, creating } = props

  const initialValues = useFormInitialValue(unifiedVehicle)
  const formViewType = useFormViewType(unifiedVehicle)
  const lockedNames = useLockedNames(unifiedVehicle.unifiedId)
  const { readonly } = useReadOnly()
  const onSubmit = useOnSubmit(unifiedVehicle, creating)
  const onCancel = useResetEditingState()
  const hasReachedTrackingLimit = useCountRemainingTrackedVehicle() < 1
  const qa = useEnvironmentQa()
  const composeFieldsWithError = useComposeFieldsWithError()
  const texts = useTexts()

  useStopEditingOnUnmount()

  const validate = createValidate(lockedNames)

  return (
    <FinalForm<uui.domain.ui.forms.RmVehicleFormValues>
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      render={(formRenderProps: FormRenderProps<uui.domain.ui.forms.RmVehicleFormValues>) => {
        const { handleSubmit, submitting } = formRenderProps

        const formState = formRenderProps.form.getState()
        const { valid, pristine, errors } = formState
        const disableTrackingCheckbox = hasReachedTrackingLimit && !initialValues.rm.eventTracking

        const saveDisabled = readonly || !valid || (creating ? false : pristine)

        const fieldsWithError = composeFieldsWithError(
          errors as uui.domain.ui.forms.RmVehicleFormValidationValues,
        )

        return (
          <FormColumn width={484} testid="form__column-secondary">
            <SecondaryColumnHeader
              title={texts.headerTitle}
              description={texts.headerDescription}
              avatar={null}
            >
              <Tabs unifiedVehicle={unifiedVehicle} formViewType={formViewType} disabled />
            </SecondaryColumnHeader>
            <FormContent
              qa={qa}
              testId="vehicleGeneralSettingsRmOnlySingleEdit"
              footer={
                <Footer
                  fieldsWithError={fieldsWithError}
                  handleSubmit={handleSubmit}
                  saveDisabled={saveDisabled}
                  readonly={readonly}
                  submitting={submitting}
                  creating={!!creating}
                  onCancel={onCancel}
                  pristine={pristine}
                  valid={valid}
                  texts={texts}
                  submitBtnClassName={
                    creating
                      ? 'o-pendo__vehicle-form__create-vehicle'
                      : 'o-pendo__vehicle-form__update-vehicle'
                  }
                />
              }
            >
              <Form disableTrackingCheckbox={disableTrackingCheckbox} />
              {creating && (
                <ReadonlyLabel primary style={labelStyle}>
                  {texts.create}
                </ReadonlyLabel>
              )}
            </FormContent>
          </FormColumn>
        )
      }}
    />
  )
}
