import { useFormViewType } from '../../../hooks/useFormViewType'

import { SingleViewRm } from './SingleViewRm'
import { SingleViewGps } from './SingleViewGps'
interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function GeneralSettings(props: Props) {
  const { unifiedVehicle } = props

  const formViewType = useFormViewType(unifiedVehicle)

  switch (formViewType) {
    case 'gspOnly':
      return <SingleViewGps unifiedVehicle={unifiedVehicle} />

    case 'rm360':
    case 'rmOnly':
    default:
      return <SingleViewRm unifiedVehicle={unifiedVehicle} />
  }
}
