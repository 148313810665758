import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'setup.driverActivity.dynamicLoadsCf.title' }),
    description: translate({ id: 'setup.driverActivity.dynamicLoadsCf.description' }),
    copy: translate({ id: 'setup.driverActivity.dynamicLoadsCf.copy' }),
    loads: translate({ id: 'setup.driverActivity.dynamicLoadsCf.loads' }),
    customFields: translate({ id: 'setup.driverActivity.dynamicLoadsCf.customFields' }),
    copyTooltip: translate({ id: 'setup.driverActivity.dynamicLoadsCf.copyTooltip' }),
  }))

  return api
}
