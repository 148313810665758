import { memo, useCallback } from 'react'
import { Payments, Timelapse } from '@mui/icons-material'

import { FormatCurrency } from '@/components/smartUtils/currency/FormatCurrency'
import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'
import { Depot as DepotIcon } from '@/icons'
import { type RenderItemProps } from '@/components/StructuredVirtualList'
import {
  ListItem,
  ListItemAvatarRounded,
  ListItemContent,
  ListItemRow,
  ListItemTitle,
  ListItemVisibilityToggler,
} from '@/components/List'

import { useVisibility } from '../hooks/useVisibility'

type Props = Extract<RenderItemProps<'depots'>, { type: 'item' }>

export const DepotItem = memo((props: Props) => {
  const { selected, item, onItemClick, onItemDoubleClick } = props

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])

  const [visible, toggleVisibility] = useVisibility(item.id)

  const emptyCost = item.setupCost === -1
  const emptyTime = item.setupTimeSec === -1

  return (
    <ListItem
      onClick={onClick}
      selected={selected}
      onDoubleClick={onDoubleClick}
      invisibleOnMap={!visible}
      avatar={
        <ListItemAvatarRounded color={`#${item.color}`}>
          <DepotIcon size={20} />
        </ListItemAvatarRounded>
      }
      right={<ListItemVisibilityToggler toggleVisibility={toggleVisibility} visible={visible} />}
    >
      <ListItemTitle>{item.name}</ListItemTitle>

      <ListItemRow testId="depot-setup-cost-row">
        <ListItemContent empty={emptyCost} icon={<Payments />}>
          {emptyCost ? '-' : <FormatCurrency amount={item.setupCost / 100} />}
        </ListItemContent>
      </ListItemRow>

      <ListItemRow testId="depot-setup-time-row">
        <ListItemContent empty={emptyTime} icon={<Timelapse />}>
          {emptyTime ? '-' : <FormatSeconds seconds={item.setupTimeSec} />}
        </ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})
DepotItem.displayName = 'DepotItem'
