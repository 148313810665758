import type {
  SelectionAsRecord,
  WeeklyValidSelection,
  WeeklySelectionAsRecord,
} from '../../typings'

import { getDay } from 'date-fns/esm'

import { parseDate } from '../../utils/parseDate'

export const matchHighlightProducer = (selectionAsRecord: SelectionAsRecord) => {
  const keys = Object.keys(selectionAsRecord)
  const weeklySelection =
    keys.length > 0
      ? keys.reduce<WeeklySelectionAsRecord>((acc, curr) => {
          const date = parseDate(curr)
          const dateDay = getDay(date) as WeeklyValidSelection
          acc[dateDay] = 1
          return acc
        }, {} as WeeklySelectionAsRecord)
      : undefined

  return (date: WeeklyValidSelection): boolean => !!weeklySelection && !!weeklySelection[date]
}
