import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    edit: translate({ id: 'global.edit' }),
    lock: translate({ id: 'global.lock' }),
    swap: translate({ id: 'global.swap' }),
    build: translate({ id: 'rm.sidebar.build' }),
    unlock: translate({ id: 'global.unlock' }),
    export: translate({ id: 'global.export' }),
    revoke: translate({ id: 'rm.contextual.route.revokeRoute' }),
    editAll: translate({ id: 'global.editAll' }),
    lockAll: translate({ id: 'global.lockAll' }),
    reverse: translate({ id: 'global.reverse' }),
    unassign: translate({ id: 'global.unassign' }),
    buildAll: translate({ id: 'rm.contextual.route.buildAll' }),
    dispatch: translate({ id: 'rm.contextual.route.dispatch' }),
    exportAll: translate({ id: 'global.exportAll' }),
    revokeAll: translate({ id: 'rm.contextual.route.revokeAllRoutes' }),
    unlockAll: translate({ id: 'global.unlockAll' }),
    reverseAll: translate({ id: 'global.reverseAll' }),
    emailExport: translate({ id: 'rm.contextual.route.mailToDriver' }),
    unassignAll: translate({ id: 'rm.contextual.route.unassignAllRoutes' }),
    routeDetails: translate({ id: 'rm.contextual.route.routeDetails' }),
    disabledForBeta: translate({ id: 'global.disabledForBeta' }),
    reachMaxDispatchedDays: translate({ id: 'dispatchPanel.tooltips.dispatch.disabled' }),

    disabledExportTooltip: translate({
      id: 'rm.contextual.route.export.disabled.title',
    }),

    disabledBuildTooltipTitle: translate({
      id: 'rm.contextual.route.build.disabledOutOfOptimizationRangeTitle.title',
    }),

    disabledBecauseInThePastTitle: translate({
      id: 'rm.contextual.route.build.disabledBecauseInThePast.title',
    }),

    disabledBecauseInThePastSubtitle: translate({
      id: 'rm.contextual.route.build.disabledBecauseInThePast.subtitle',
    }),

    someRouteInThePastTitle: translate({
      id: 'rm.contextual.route.build.someRouteInThePast.title',
    }),

    someRouteInThePastSubtitle: translate({
      id: 'rm.contextual.route.build.someRouteInThePast.subtitle',
    }),

    disabledAllRoutesLocked: translate({
      id: 'rm.contextual.route.build.disabledAllRoutesLocked.title',
    }),

    disabledOutOfOptHorizonBuildTooltipSubtitle: (startDate: string, endDate: string) =>
      translate({
        id: 'rm.contextual.route.build.disabledOutOfOptimizationRangeTitle.subtitle',
        values: { startDate, endDate },
      }),

    disabledExceedMaxOptDaysBuildTooltipSubtitle: (maxOptimizableDays: number) =>
      translate({
        id: 'rm.contextual.route.build.disabledBiggerThanOptimizationHorizon.subtitle',
        values: { maxOptimizableDays },
      }),

    disabledMailToDriversTooltip: translate({
      id: 'rm.contextual.route.export.email.disabled.title',
    }),

    disabledDispatchRoutesPast: translate({
      id: 'rm.contextual.route.dispatch.disabledBecauseInThePast.title',
    }),

    disabledRevokeRoutesPast: translate({
      id: 'rm.contextual.route.revoke.disabledBecauseInThePast.title',
    }),

    disabledBecauseGotExecutionEvents: translate({
      id: 'rm.contextual.route.revoke.disabledBecauseGotExecutionEvents.title',
    }),

    disabledOutOfOptimizationRange: translate({
      id: 'rm.contextual.route.dispatch.disabledOutOfOptimizationRange.title',
    }),

    exportRoute: (multiple: boolean) =>
      translate({ id: 'rm.contextual.route.export.route', values: { count: multiple ? 2 : 1 } }),
    exportManifest: (multiple: boolean) =>
      translate({ id: 'rm.contextual.route.export.manifest', values: { count: multiple ? 2 : 1 } }),
    exportDispatchedManifest: (multiple: boolean) =>
      translate({
        id: 'rm.contextual.route.export.dispatchedManifest',
        values: { count: multiple ? 2 : 1 },
      }),

    cannotExportInSimulation: translate({
      id: 'rm.contextual.route.export.cannotExportInSimulation',
    }),
    routesEmpty: (multiple: boolean) =>
      translate({
        id: 'rm.contextual.route.export.routesEmpty',
        values: { count: multiple ? 2 : 1 },
      }),
    lockDisabled: translate({ id: 'rm.contextual.route.lock.disabled' }),
  }))

  return api
}
