import { useCallback, useContext } from 'react'

import { journal } from '@/server-data'

import { PopupContext } from '../context/PopupContext'

/**
 * Expose the popup API TODO: write better docs
 */
export function usePopupApi() {
  const popup = useContext(PopupContext)

  const open = useCallback(() => {
    switch (popup.status) {
      case 'close':
      case 'error':
        return popup.open()

      case 'opening':
      case 'open':
        journal.main('The Popup is already open', {}, 'warn')
        return Promise.reject(new Error('The Popup is already open'))

      default:
        // This is a TS protection that forces the developer to properly manage all the cases.
        // It throws when the developer adds new app statuses without adding a corresponding `case` here.
        // @ts-expect-error
        throw new Error(`Unknown status: ${popup.status}`)
    }
  }, [popup])

  const close = useCallback(() => {
    switch (popup.status) {
      case 'close':
        journal.main('The Popup is not open', {})
        return Promise.resolve()

      case 'opening':
      case 'open':
        return popup.close()

      case 'error':
        journal.main('The Popup encountered an error and did not open', {}, 'warn')
        return Promise.resolve()

      default:
        // This is a TS protection that forces the developer to properly manage all the cases.
        // It throws when the developer adds new app statuses without adding a corresponding `case` here.
        // @ts-expect-error
        throw new Error(`Unknown status: ${popup.status}`)
    }
  }, [popup])

  const focus = useCallback(() => {
    switch (popup.status) {
      case 'close':
        journal.main('The Popup is not open', {}, 'warn')
        return

      case 'opening':
        journal.main(
          'The Popup is currently opening, it will be automatically focused once opened',
          {},
          'warn',
        )
        break

      case 'open':
        return popup.focus()

      case 'error':
        journal.main('The Popup encountered an error and did not open', {}, 'warn')
        break

      default:
        // This is a TS protection that forces the developer to properly manage all the cases.
        // It throws when the developer adds new app statuses without adding a corresponding `case` here.
        // @ts-expect-error
        throw new Error(`Unknown status: ${popup.status}`)
    }
  }, [popup])

  return {
    close,
    focus,
    open,
    status: popup.status,
  }
}
