import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function TimeInterval(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M25 2C18.4375 2 12.5078 4.76172 8.3125 9.1875c-.8711.91797-1.66406 1.91797-2.375 2.96875-.0547.08203-.10156.16797-.15625.25-.02734.04297-.0664.08203-.09375.125-.1211.1836-.23047.375-.34375.5625-.14453.23828-.30078.47266-.4375.71875-.0586.10547-.1289.20313-.1875.3125-.04297.08203-.08203.16797-.125.25-.11328.21484-.20703.4375-.3125.65625-.05078.10547-.10547.20703-.15625.3125-.10938.23438-.21094.48047-.3125.71875-.03906.09375-.08594.1875-.125.28125-.10156.25-.1875.4961-.28125.75-.0547.14453-.10547.29297-.15625.4375-.0703.20703-.15234.41406-.21875.625-.02344.07813-.03906.16797-.0625.25-.08203.28125-.17578.5586-.25.84375-.01563.0625-.01563.125-.03125.1875-.08594.34766-.1797.70703-.25 1.0625-.0039.01953.0039.04297 0 .0625-.0703.35156-.1328.70313-.1875 1.0625-.05078.33984-.08984.6875-.125 1.03125-.0039.01953.0039.04297 0 .0625-.03516.3672-.07422.72266-.09375 1.09375v.03125C2.01172 24.22655 2 24.61328 2 25c0 12.6836 10.3164 23 23 23s23-10.3164 23-23S37.6836 2 25 2zm0 2v17.3125L9.96875 10.375C13.78515 6.45312 19.10547 4 25 4zM13.09375 15.09375L24.1875 23.1875C24.4336 23.07812 24.71094 23 25 23c.5078 0 .96094.1875 1.3125.5l9.46875-3.15625.625 1.90625-9.4375 3.15625C26.78125 26.3164 25.96875 27 25 27c-1.10547 0-2-.89453-2-2 0-.0703.02344-.15234.03125-.21875l-11.125-8.0625z" />
      </svg>
    </IconContainer>
  )
}
