import { useCallback } from 'react'
import { proxy, useSnapshot, snapshot } from 'valtio'

type Category = 'orderTab' | 'vehicleTab' | 'navigo'

type State = {
  current: number
  min: number
  max: number
}

type PaginationCategories = Record<Category, State>
type PaginationAtom = { categories: PaginationCategories }

const initialPaginationCategories = {
  // 0: Order information tab
  // 1: Execution events tab
  // 2: Notifications tab
  orderTab: { current: 0, min: 0, max: 2 },

  vehicleTab: { current: 0, min: 0, max: 1 },

  // The -1 value allows showing the "all values" chip, used by the navigator to show a summary
  // of all the selected entities (ex. three selected orders get the navigator shows four chips:
  // the "all orders" chip and one chip for every order).
  // The -1 value allows also the modals to recognize the fact that they have to filter (or not)
  // the main selection.
  navigo: { current: -1, min: -1, max: Number.MAX_SAFE_INTEGER },
}

export const paginationAtom = proxy<PaginationAtom>({
  categories: { ...initialPaginationCategories },
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetRoutingLayout = (prev: PaginationCategories) => PaginationCategories
type SetPaginationParam = SetRoutingLayout | Partial<PaginationCategories> | 'reset'

export function resetPaginations() {
  paginationAtom.categories = { ...initialPaginationCategories }
}

export const setPagination = (valueOrFunc: SetPaginationParam) => {
  if (valueOrFunc === 'reset') return resetPaginations()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(paginationAtom.categories, valueOrFunc(paginationAtom.categories))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      paginationAtom.categories[field] = valueOrFunc[field]
    }
  }

  return paginationAtom
}

export function usePagination(category: Category) {
  const { min, max, current: page } = useSnapshot(paginationAtom).categories[category]

  const setPage = useCallback(
    (current: number) => {
      setPagination({ [category]: { ...paginationAtom.categories[category], current } })
    },
    [category],
  )

  const reset = useCallback(() => {
    setPagination({ [category]: initialPaginationCategories[category] })
  }, [category])

  return {
    page,
    min,
    max,
    reset,
    setPage,
  } as const
}

// ------------------------------------
// Read functions
// ------------------------------------

export function getPagination(immutable: boolean = true) {
  return immutable ? snapshot(paginationAtom.categories) : paginationAtom.categories
}
