import { NavigoContainer } from '../../elements/NavigoContainer'

import { usePlaceNavigoMachine } from './hooks/usePlaceNavigoMachine'

import { PlacesNavigator } from './components/PlacesNavigator'
import { Overview } from './views/Overview'
import { MapStyle } from './views/MapStyle'

export function Places() {
  const { currentState, data, actions } = usePlaceNavigoMachine()

  switch (currentState) {
    case 'mapStyle':
      return (
        <NavigoContainer data-testid="navigo-place" data-trackid="navigo-place">
          <PlacesNavigator />

          <MapStyle reset={actions.reset} />
        </NavigoContainer>
      )

    case 'overview':
    default:
      return (
        <NavigoContainer data-testid="navigo-place" data-trackid="navigo-place">
          <PlacesNavigator />

          <Overview
            expanded={data.expanded}
            goToStyle={actions.goToMapStyle}
            toggleExpanded={actions.toggleExpanded}
          />
        </NavigoContainer>
      )
  }
}
