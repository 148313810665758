import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({
      id: 'sms4.trackingPagePreviewModal.title',
    }),
    subtitle: translate({ id: 'sms4.trackingPagePreviewModal.subtitle' }),
  }))

  return api
}
