import type OlMap from 'ol/Map'

import VectorImageLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { setLayerMetadata } from '../layerMetadata'

export const createBreadcrumbLayers = (map: OlMap) => {
  // Path layer
  const pathLayer = new VectorImageLayer<VectorSource>({ source: new VectorSource(), maxZoom: 14 })

  setLayerMetadata(pathLayer, 'uid', 'breadcrumbPath')

  map.addLayer(pathLayer)

  // Breadcrumb layer
  const pointsLayer = new VectorImageLayer<VectorSource>({
    source: new VectorSource(),
    minZoom: 14,
  })

  setLayerMetadata(pointsLayer, 'uid', 'breadcrumbPoints')

  map.addLayer(pointsLayer)
}
