import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '@/store'

import {
  setCustomMapStyle,
  selectDevicesMapStyles,
  selectVehiclesMapStyles,
} from '@/features/domain/ui'

import { getDeviceIdFromUnifiedVehicleId, isUnassociatedDevice } from '@/server-data'

export const useVisibility = (id: string): [boolean, React.MouseEventHandler<HTMLDivElement>] => {
  const vehicleMapStyles = useSelector(selectVehiclesMapStyles)
  const deviceMapStyles = useSelector(selectDevicesMapStyles)
  const dispatch = useAppDispatch()

  const isDevice = isUnassociatedDevice(id)
  const normalizedId = isDevice ? getDeviceIdFromUnifiedVehicleId(id) : id

  const globalStyle = isDevice ? deviceMapStyles.mode : vehicleMapStyles.mode
  const customStyle = isDevice
    ? deviceMapStyles.custom[normalizedId]
    : vehicleMapStyles.custom[normalizedId]

  const mode = customStyle || globalStyle

  return [
    mode !== 'off',
    useCallback(
      e => {
        e.stopPropagation()

        dispatch(
          setCustomMapStyle({
            customStyle: {
              type: isDevice ? 'device' : 'vehicle',
              mode: mode !== 'off' ? 'off' : 'on',
              ids: [normalizedId],
            },
          }),
        )
      },
      [mode, dispatch, isDevice, normalizedId],
    ),
  ]
}
