import type { OnSelectCallbackParams } from '@/components/StructuredVirtualList'

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, resetCrudSelection } from '@/atoms'
import { selectSimulationsListStructure } from '@/features/domain/lists'

type OnSelectParams = OnSelectCallbackParams<'simulations'>

export const useListSelection = () => {
  const [selection, selectSimulation] = useCrudSelection('simulations')
  const structure = useSelector(selectSimulationsListStructure)

  // TS can't infer string[] with a simple `filter`
  const simIds = useMemo(() => {
    return structure.list.reduce<string[]>((acc, item) => {
      if (typeof item === 'string') {
        acc.push(item)
      }
      return acc
    }, [])
  }, [structure.list])

  const allSelected = simIds.length === selection.length
  const lastSelectedSimulation = selection.length > 0 ? selection[selection.length - 1] : undefined

  const onSelect = useCallback(
    (params: OnSelectParams) => selectSimulation(params.newSelectedIds),
    [selectSimulation],
  )
  const onSelectAll = useCallback(() => {
    if (allSelected) {
      resetCrudSelection('simulations')
    } else {
      selectSimulation(simIds)
    }
  }, [selectSimulation, allSelected, simIds])

  return {
    onSelect,
    allSelected,
    onSelectAll,
    lastSelectedSimulation,
  }
}
