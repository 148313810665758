import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'
import type { OptionalSelectionModifiers } from '@/atoms'

import { setCrudSelection } from '@/atoms'

import { getConnectedList } from '../../../../atoms/selection/read/getConnectedList'
import { getMapSelectionContext } from '../../../../atoms/selection/read/getMapSelectionContext'

export function clickRegion(feature: Feature<Geometry>, modifiers: OptionalSelectionModifiers) {
  const connectedList = getConnectedList()
  const mapSelectionContext = getMapSelectionContext()

  const regionId = feature.getId()?.toString()

  if (!regionId) return

  const selection = [regionId]

  // select
  if (mapSelectionContext.type === 'crud') {
    if (mapSelectionContext.category === 'regions') {
      setCrudSelection('regions', selection, { modifiers })

      // try to scroll to
      if (
        connectedList.status === 'connected' &&
        connectedList.category === 'regions' &&
        !connectedList.disabled
      ) {
        connectedList.scrollTo(regionId)
      }
    }
  }
}
