import { CircularProgress } from '@mui/material'

import { OrdersVisible, OrdersVisibleMinimized } from '@/icons'
import { VerticalLayout } from '@/components/layout'
import { Text } from '@/local/components'

import { NavigoIconButton } from '../../../../../../../../elements/NavigoIconButton'

import { ButtonsContainer } from '../ButtonsContainer'
import { useTexts } from '../useTexts'
import { useActiveRouteMapStyle } from './hooks/useActiveRouteMapStyle'
import { useRouteActions } from './hooks/useRouteActions'

interface Props {
  orderStepIds: string[]
}

export function RouteStyler(props: Props) {
  const { orderStepIds } = props

  const activeRouteMapStyle = useActiveRouteMapStyle(orderStepIds)
  const { methodInExecution, actions } = useRouteActions(orderStepIds)

  const texts = useTexts()

  return (
    <>
      <VerticalLayout justifyContent="space-between" width="auto" marginRight={24}>
        <Text size="$p3" weight="$semiBold">
          {texts.routeTitle}
        </Text>

        <Text size="$p3">{texts.routeSubtitle}</Text>
      </VerticalLayout>

      <ButtonsContainer>
        <NavigoIconButton
          Icon={
            methodInExecution === 'maximizeRoutePaths' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <OrdersVisible size={16} color="$nightRider" />
            )
          }
          testid="navigo-routes-mapStyles-ordersStyle-maximize"
          active={activeRouteMapStyle === 'maximized'}
          onClick={actions.maximizeRoutePaths}
          text={texts.routeShow}
        />

        <NavigoIconButton
          Icon={
            methodInExecution === 'showRoutePaths' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <OrdersVisibleMinimized size={16} color="$nightRider" />
            )
          }
          testid="navigo-routes-mapStyles-ordersStyle-on"
          active={activeRouteMapStyle === 'on'}
          onClick={actions.showRoutePaths}
          text={texts.routeReduce}
        />

        <NavigoIconButton
          Icon={
            methodInExecution === 'hideRoutePaths' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <OrdersVisibleMinimized size={16} color="$silver" />
            )
          }
          testid="navigo-routes-mapStyles-ordersStyle-hide"
          active={activeRouteMapStyle === 'off'}
          onClick={actions.hideRoutePaths}
          text={texts.routeHide}
        />
      </ButtonsContainer>
    </>
  )
}
