import { ColumnStoreConfig, type TemplateColumn } from '@bryntum/schedulerpro'
import {
  setSchedulerColumns,
  type SchedulerColumn,
  updateSchedulerColumnsSectionWidth,
} from '../../../../atoms/columns'
import { type SchedulerColumnId } from '../../../../atoms/columns/types'

export const onUpdate: ColumnStoreConfig['onUpdate'] = ({ source }) => {
  //@ts-expect-error
  const grid = source.grid as Grid

  if (grid?.subGrids?.cols?.width) {
    updateSchedulerColumnsSectionWidth(grid.subGrids.cols.width)
  }

  const updatedColumns = source.records.reduce<SchedulerColumn[]>((acc, column) => {
    const data = column as TemplateColumn

    // Ignore internal columns
    if (data.field.startsWith('_')) return acc

    const id = data.field.startsWith('loads.')
      ? (data.field.replace('loads.', 'dynamic-load-') as SchedulerColumnId)
      : (data.field as SchedulerColumnId)

    acc.push({
      id,
      width: data.width,
      active: !data.hidden,
      hidden: data.hidden,
      static: false,
    })

    return acc
  }, [])

  setSchedulerColumns(updatedColumns)
}
