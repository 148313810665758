import { useCallback } from 'react'

import { SecondaryColumnHeader } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { usePendingFitMapOnMount } from '@/map'
import { resetCrudSelection } from '@/atoms'

import { PlaceAvatar } from '../../components/PlaceAvatar'

import { ActionsManager } from './components/ActionsManager'

interface Props {
  place: uui.domain.client.gps.wwgps.Place
}

const avatarStyle = { marginRight: 16, border: 'none' }

export function SingleView(props: Props) {
  const { place } = props
  const { name, address = '' } = place

  usePendingFitMapOnMount(true)

  const onReset = useCallback(() => {
    resetCrudSelection('places')
  }, [])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={name}
        description={address}
        action={onReset}
        avatar={<PlaceAvatar size={44} style={avatarStyle} />}
        data-testid="places-readonly-header"
      />
      <ActionsManager place={place} />
    </FormColumn>
  )
}
