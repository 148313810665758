import type { DeviceInfoItem } from './types'

import { SearchableListField } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'
import { useWrappedInputProps } from './hooks/useWrappedInputProps'

export function DevicesList() {
  const texts = useTexts()
  const wrappedInputProps = useWrappedInputProps()

  return (
    <SearchableListField<DeviceInfoItem>
      name="deviceIds"
      label={texts.label}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
