import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectTrackingListOptions } from '@/features/domain/lists'

export function useIsFilterByBadgeActive() {
  const listOptions = useSelector(selectTrackingListOptions)

  return useMemo(() => {
    const badgesFilter = listOptions.filter.find(f => f.field === 'filter.badges')
    const isFilteringByBadge = (badgesFilter?.values ?? []).length > 0

    const deviceWithNoBadgesFilter = listOptions.filter.find(
      f => f.field === 'filter.includeDevicesWithNoBadges',
    )
    const deviceWithNoBadgesFilterActive =
      deviceWithNoBadgesFilter?.values.length === 1 && Boolean(deviceWithNoBadgesFilter.values[0])

    return isFilteringByBadge || deviceWithNoBadgesFilterActive
  }, [listOptions])
}
