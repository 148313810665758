import { Box } from '@mui/material'

import { useSetupEditing } from '../../hooks/useSetupEditing'
import { useRefreshVoice } from './hooks/useRefreshVoice'
import { VoiceFormLayout } from './components/VoiceForm'
import { VoiceView } from './components/VoiceView'

export function Voice() {
  const { editing, onEdit, onDiscard } = useSetupEditing('voice', 'reset')

  // Refresh Voice from the BE to avoid the presence of old data
  useRefreshVoice()

  return (
    <Box display="flex" width="100%" height="100%">
      {!editing && <VoiceView onEdit={onEdit} />}
      {editing && <VoiceFormLayout onDiscard={onDiscard} />}
    </Box>
  )
}
