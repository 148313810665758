import { Alert, Divider, Stack, Typography } from '@mui/material'
import { TipsAndUpdates } from '@mui/icons-material'

import { useTexts } from '../../../../useTexts'
import { ApiKey } from './components/ApiKey'
import { Label } from './components/Label'

export function Azuga() {
  const texts = useTexts()

  return (
    <Stack spacing={2} width="100%">
      <Label />

      <Divider />

      <Typography variant="caption">{texts.azuga.instructions}</Typography>

      <ApiKey />

      <Alert icon={<TipsAndUpdates />} severity="info">
        {texts.azuga.suggestion}
      </Alert>
    </Stack>
  )
}
