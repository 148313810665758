import { useEffect, useRef } from 'react'
import { getInspectPin } from '../../../../atoms/inspectPin/core/getInspectPin'
import { removeInspectPin } from '../../../../atoms/inspectPin/core/removeInspectPin'

type Props = {
  mode: 'converted' | 'normal' | 'none'
}

export function InspectPinMode(props: Props) {
  const { mode } = props

  const modeRef = useRef(mode)

  useEffect(() => {
    modeRef.current = mode
  }, [mode])

  useEffect(() => {
    const pin = getInspectPin()

    if (pin) {
      if (modeRef.current === 'none') {
        removeInspectPin()
      } else if (modeRef.current === 'converted' && pin.mode === 'normal') {
        removeInspectPin()
      }
    }
  }, [])

  return null
}
