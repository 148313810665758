import type { ExecutionEventsFormValues } from '../../typings'

import { Stack } from '@mui/material'

import { CustomFieldField } from './CustomFieldField'
import { ReadonlyCustomFieldField } from './ReadonlyCustomFieldField'
import { useTexts } from './useTexts'

interface Props {
  value: ExecutionEventsFormValues['customFields']
  error?: Record<string, string>
  onChange: (key: string, value: string) => void
}

export function CustomFields(props: Props) {
  const { value, onChange, error } = props
  const texts = useTexts()

  const entries = Object.entries(value)

  return (
    <Stack spacing={1} width="100%">
      {entries.map(([label, customField]) => {
        if (customField.disabled) {
          return <ReadonlyCustomFieldField key={label} label={label} value={texts.undeclared} />
        }
        return (
          <CustomFieldField
            key={label}
            label={label}
            value={customField.value}
            error={error?.[label]}
            onChange={onChange}
            testId={`dynamic-customField-${label}`}
          />
        )
      })}
    </Stack>
  )
}
