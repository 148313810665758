import { validatePhoneNumbers as validatePhoneNumbersAction } from '@/features/domain/order/actions/validatePhoneNumbers'
import { locationHasError } from '@/server-data'
import { store } from '@/store'

import * as Texts from '../../../../intl'
import {
  FormOrderStep,
  OrderStepValidation,
  TimeWindowExceptionValidation,
  OrderFormValues,
} from '../typings'
import validateTimeWindow from './validateTimeWindow'
import validateTimeWindowExceptionDate from './validateTimeWindowExceptionDate'
import isTagsColliding from './isTagsColliding'

import {
  validateBarcodes,
  areValidBarcodes,
  barcodeMaxLength,
  BarcodesValidationResult,
  getBarcodesInvalidValues,
} from './validateBarcodes'

async function validatePhoneNumbers(phoneNumbers: string[]) {
  const thunkResult = await store.dispatch(validatePhoneNumbersAction(phoneNumbers))

  if (validatePhoneNumbersAction.fulfilled.match(thunkResult)) {
    return thunkResult.payload
  }

  return {}
}

export default async function validateOrderStep(
  orderStep: FormOrderStep,
  workingDayStartSec: number,
  eligibility: uui.domain.client.rm.Eligibility,
  notificationBehavior: uui.domain.client.rm.Territory['notificationBehavior'],
  maxBarcodes: number,
  allowEmptyCustomFields: boolean,
  formInitialValues: OrderFormValues,
  validateEmail: (email: string) => Promise<boolean>,
  validatePristineContactInfo?: boolean,
): Promise<OrderStepValidation> {
  const {
    tagsOut = [],
    tagsIn = [],
    timeWindows,
    timeWindowExceptions,
    customFields,
    phone,
    email,
    location,
    barcodes,
    serviceTimeSec,
  } = orderStep

  const orderStepValidation: OrderStepValidation = {}

  // ------------------------------------------
  // tags

  if (isTagsColliding(tagsIn, tagsOut)) {
    orderStepValidation.tagsIn = Texts.getValidationErrorTagsCollisionText()
    orderStepValidation.tagsOut = Texts.getValidationErrorTagsCollisionText()
  }

  // ------------------------------------------
  // time-windows

  const timeWindowsErrors = timeWindows.map(tw => {
    return validateTimeWindow(tw, workingDayStartSec)
      ? ''
      : Texts.getValidationErrorTimeWindowsValuesText()
  }, [])

  if (timeWindowsErrors.filter(twe => twe !== '').length > 0) {
    orderStepValidation.timeWindows = timeWindowsErrors
  }

  // ------------------------------------------
  // time-windows exceptions

  if (timeWindowExceptions) {
    const tweErrors = Object.entries(timeWindowExceptions).reduce(
      (acc: Record<string, TimeWindowExceptionValidation>, [dateAsString, twe]) => {
        const dateError: boolean = !validateTimeWindowExceptionDate(dateAsString, eligibility)

        const twErrors: string[] = twe.map((tw: uui.domain.rm.TimeWindow) => {
          const valid: boolean = validateTimeWindow(tw, workingDayStartSec)
          return valid ? '' : Texts.getValidationErrorTimeWindowsValuesText()
        })

        const showTwErrors: boolean = twErrors.some((value: string) => value.length > 0)

        if (dateError || showTwErrors) {
          acc[dateAsString] = {}

          if (dateError) {
            acc[dateAsString].date = Texts.getValidationErrorTimeWindowExceptionInvalidDateText()
          }

          if (showTwErrors) {
            acc[dateAsString].timeWindows = twErrors
          }
        }

        return acc
      },
      {},
    )

    if (Object.keys(tweErrors).length > 0) {
      orderStepValidation.timeWindowExceptions = tweErrors
    }
  }

  // ------------------------------------------
  // customFields

  if (customFields) {
    // allowEmptyCustomFields means custom field cannot be invalid
    const customFieldsErrors: Record<string, string> = allowEmptyCustomFields
      ? {}
      : Object.entries(customFields).reduce((acc: Record<string, string>, [key, value]) => {
          if (!value || value.trim().length === 0) {
            acc[key] = Texts.getValidationErrorCustomFieldText()
          }

          return acc
        }, {})

    if (Object.keys(customFieldsErrors).length > 0) {
      orderStepValidation.customFields = customFieldsErrors
    }
  }

  // ------------------------------------------
  // contact information

  // after https://workwave.atlassian.net/browse/RM-9714 both phone and email field should not be validated
  // if the field is pristine, because the api allow them to be incorrect
  if (notificationBehavior !== 'NO_NOTIFICATIONS') {
    if (notificationBehavior === 'EMAIL_AND_PHONE') {
      if (phone && phone.length > 0) {
        const phoneFieldPristine = phone === formInitialValues.orderStep.phone
        const skipPhoneValidation = validatePristineContactInfo ? false : phoneFieldPristine

        let phoneIsValid = false
        try {
          // Invalid emails and phone numbers can be inserted via APIs and they must not be validated (until the user updates them).
          // Instead, new orders can't be imported with invalid contact info.

          if (skipPhoneValidation) {
            phoneIsValid = true
          } else {
            const res = await validatePhoneNumbers([phone])
            phoneIsValid = res[phone]
          }
        } catch (e) {
          console.warn(`Error on [validatePhoneNumbers] method: [${e}]`)
        }

        if (!phoneIsValid) {
          orderStepValidation.phone = Texts.getValidationErrorPhoneText()
        }
      }
    }

    const emailFieldPristine = email === formInitialValues.orderStep.email
    const skipEmailValidation = validatePristineContactInfo ? false : emailFieldPristine

    const emailValid = skipEmailValidation || !email ? true : await validateEmail(email)

    if (!emailValid) {
      orderStepValidation.email = Texts.getValidationErrorEmailText()
    }
  }

  // ------------------------------------------
  // Location

  if (!location || locationHasError(location)) {
    orderStepValidation.location = 'Invalid location'
  }

  // ------------------------------------------
  // Service time

  if (isNaN(serviceTimeSec)) {
    orderStepValidation.serviceTimeSec = Texts.getValidationErrorServiceTimeText()
  }

  // ------------------------------------------
  // Barcodes

  const barcodeValidation: BarcodesValidationResult = validateBarcodes(barcodes, maxBarcodes)

  if (!areValidBarcodes(barcodeValidation)) {
    const invalidText = Texts.getBarcodesValidationText(
      barcodeValidation,
      maxBarcodes,
      barcodeMaxLength,
    )

    orderStepValidation.barcodes = {
      text: invalidText,
      invalidValues: getBarcodesInvalidValues(barcodeValidation),
    }
  }

  return orderStepValidation
}
