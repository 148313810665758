import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function NoFreeze(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 17">
        <path
          fillRule="evenodd"
          d="M8.334 5.772L6.09 3.528l-.447-.447c-.109-.11-.257-.18-.422-.19-.054-.002-.112.002-.17.014-.232.049-.425.236-.482.466-.057.239.02.462.17.611l.33.328 2.364 2.362.9-.9zM6.983 7.123L3.249 3.395c-.15-.15-.374-.224-.61-.168-.224.055-.407.238-.462.461-.058.24.018.463.17.612l3.729 3.73.907-.907zM5.625 8.481L2.407 5.264c-.15-.147-.371-.22-.607-.162-.222.055-.404.235-.458.457-.058.238.018.462.167.612l3.21 3.215.906-.905zM4.268 9.838L2.42 7.987l-.324-.326c-.148-.15-.373-.228-.61-.17-.233.054-.42.25-.467.482-.049.232.029.449.177.593l2.171 2.171.9-.9z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M2.653 3.223l-.015.004c-.223.055-.406.238-.461.461l-.004.015.48-.48zm3.23 10.028l.965.965c.56.56 1.295.798 2.031.798.736 0 1.486-.292 2.046-.853l1.347-1.347c.327-.326.574-.71.735-1.143l.714-2.26c.13-.346.327-.654.588-.916 0 0 .656-.653.873-.872l.22-.227c.173-.174.279-.413.279-.676 0-.529-.429-.957-.958-.957-.266 0-.507.108-.68.284l-2.041 2.04c-.294.292-.762.347-1.121.166l-4.998 4.998zM13.671 1.691c.196-.195.512-.195.708 0l.55.55c.195.196.195.512 0 .707L2.435 15.441c-.195.195-.512.195-.707 0l-.55-.55c-.195-.196-.195-.512 0-.707L13.671 1.69z"
          clipRule="evenodd"
        />
      </svg>
    </IconContainer>
  )
}
