import { useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

type ModalState = 'ready'

export const modalId = 'changelog'

export const useController = () => useModalController<undefined, ModalState>(modalId)
export const useConfigureController = () => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: undefined,
    }),
  }))

  return useConfigureModalController<undefined, ModalState>(modalId, options)
}
