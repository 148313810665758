import { DriverBehaviorField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

export function Cornering() {
  const texts = useTexts()

  return (
    <DriverBehaviorField
      name="device.cornering"
      testid="device.cornering"
      label={texts.cornering}
      labelHard={texts.gals(texts.hard)}
      labelHarsh=""
      labelSevere=""
      hideHarshField
      hideSevereField
    />
  )
}
