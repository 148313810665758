import { useCallback } from 'react'
import { type ColumnsPickerProps } from '../types'

export function useColumnsPicker(props: ColumnsPickerProps) {
  const { columns, onSelectionChange, selection, filter } = props

  const onToggle = useCallback(() => {
    const allSelected = columns.every(c => selection.includes(c.id))
    onSelectionChange(allSelected ? [] : columns.map(c => c.id))
  }, [columns, onSelectionChange, selection])

  const allChecked = columns.every(c => selection.includes(c.id))
  const allUnchecked = columns.every(c => !selection.includes(c.id))

  const onChangeColumn = (columnId: string) => {
    const nextSelection = [...selection]
    const idx = nextSelection.findIndex(c => c === columnId)
    if (idx === -1) {
      nextSelection.push(columnId)
    } else {
      nextSelection.splice(idx, 1)
    }

    onSelectionChange(nextSelection)
  }

  return {
    toggleAllProps: {
      onToggle,
      indeterminate: !allChecked && !allUnchecked,
      checked: allChecked,
      filter,
    },
    onChangeColumn,
  }
}
