import styled from 'styled-components'

import { darken } from '@/styles'

const HEIGHT = 32
const PAD = 24

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${HEIGHT}px;
  background: ${p => p.theme.colors.$pigmentGreen};
  color: ${p => p.theme.colors.$pureWhite};
  padding: 0 ${PAD}px;
  border-width: 0;
  border-radius: 5px;
  outline: 0;
  font-size: ${p => p.theme.fonts.$m};
  transition: 0.3s ease;
  transition-property: opacity, background;
  cursor: pointer;

  &:not(:disabled):hover {
    background: ${p => darken(p.theme.colors.$pigmentGreen, 10)};
  }
`
