import { intl } from '@/intl'

export type MapControlsTexts = {
  switchToMap: string
  zoomInToolTip: string
  zoomOutToolTip: string
  switchToSatellite: string
  toggleMapTilesActive: string
  toggleMapTilesInactive: string
  toggleMapTilesToolTip: string
}

export const getTexts = () => {
  const { translate } = intl

  // try to get the real texts, if @intl is not ready it'll throws
  try {
    return {
      orders: (count: number) => translate({ id: 'map.depot.label.orders', values: { count } }),
      pickup: translate({ id: 'map.locationPin.label.pickup' }),
      service: translate({ id: 'map.locationPin.label.service' }),
      delivery: translate({ id: 'map.locationPin.label.delivery' }),
      zoomInToolTip: translate({ id: 'map.controls.zoom.in.tooltip' }),
      zoomOutToolTip: translate({ id: 'map.controls.zoom.out.tooltip' }),
      zoomInDisabledToolTip: translate({ id: 'map.controls.zoom.in.tooltip.disabled' }),
      zoomOutDisabledToolTip: translate({ id: 'map.controls.zoom.out.tooltip.disabled' }),

      switchToMap: translate({ id: 'lib.locationEditor.modal.switchToMap' }),
      switchToSatellite: translate({ id: 'lib.locationEditor.modal.switchToSatellite' }),
      toggleMapTilesActive: translate({ id: 'map.controls.map-tiles.active' }),
      toggleMapTilesInactive: translate({ id: 'map.controls.map-tiles.inactive' }),
      toggleMapTilesToolTip: translate({ id: 'map.controls.map-tiles.tooltip' }),
    }
  } catch (e) {
    // fallback to placeholder values
    return {
      orders: (count: number) => (count > 1 ? `${count} Orders` : `${count} Order`),
      zoomInToolTip: 'Zoom In',
      zoomOutToolTip: 'Zoom Out',

      switchToMap: 'Switch to Map',
      switchToSatellite: 'Switch to Satellite',
      toggleMapTilesActive: 'S',
      toggleMapTilesInactive: 'N',
      toggleMapTilesToolTip: 'Change Map Mode',
    }
  }
}
