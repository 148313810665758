import { type GridCellParams } from '@mui/x-data-grid-pro'
import { OpenInNew } from '@mui/icons-material'
import { useSelector } from 'react-redux'

import { selectTelematicsDeviceTransientStatuses } from '@/features/domain/account'

import { type TableRow } from '../../../types'
import { LinkWithIcon } from './LinkWithIcon'

// This function return an URL of the current host with the territoryId as a query parameter
function composeVehicleUrl(territoryId: string, vehicleId: string) {
  return `${window.location.origin}/setup/vehicles?territoryId=${territoryId}&vehicleId=${vehicleId}`
}

export function VehicleCell(params: GridCellParams<{ id: string; name: string }, TableRow>) {
  const { id, name } = params.row.vehicle

  const transientStatuses = useSelector(selectTelematicsDeviceTransientStatuses)

  const href = composeVehicleUrl(params.row.territory.id, id)

  const error = transientStatuses[params.row.id]?.status === 'ERROR'

  return (
    <LinkWithIcon href={href} icon={<OpenInNew />} error={error}>
      {name}
    </LinkWithIcon>
  )
}
