import type { MapSelectionContext } from '../../selection/mapSelection'
import type { CrudSelection, MainSelection } from '@/atoms'

import { parseOrderSelection } from '../../selection/read/parseOrderSelection'
import { initializeOrderFeatures } from '../../../layers/order/initializeOrderFeatures'
import { initializeDepotFeatures } from '../../../layers/depot/initializeDepotFeatures'
import { initializePlaceFeatures } from '../../../layers/place/initializePlaceFeatures'
import { initializeDeviceFeatures } from '../../../layers/device/initializeDeviceFeatures'
import { initializeRegionFeatures } from '../../../layers/region/initializeRegionFeatures'
import { initializeVehicleFeatures } from '../../../layers/vehicle/initializeVehicleFeatures'
import { initializeGeofenceFeatures } from '../../../layers/geofence/initializeGeofenceFeatures'
import { initializeBreadcrumbFeatures } from '../../../layers/breadcrumb/initializeBreadcrumbFeatures'
import { initializeRoadSegmentFeatures } from '../../../layers/roadSegment/initializeRoadSegmentFeatures'
import { initializeTrafficAreaFeatures } from '../../../layers/trafficArea/initializeTrafficAreaFeatures'
import { initializeDeviceEventFeatures } from '../../../layers/deviceEvent/initializeDeviceEventFeatures'
import { initializeRoutePolylineFeatures } from '../../../layers/routePolyline/initializeRoutePolylineFeatures'

export const initializeMarkerLayers = async (
  markers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
  mapSelectionContext: MapSelectionContext,
  mainSelection: workwave.DeepReadonly<MainSelection>,
  crudSelection: workwave.DeepReadonly<CrudSelection>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) => {
  // ATTENTION: all initialization steps are executed only once per territory and don't require to be added to the mapRenderingQueue

  await initializeOrderFeatures(
    markers.order,
    mapStyles.order,
    parseOrderSelection(mapSelectionContext, mainSelection.orderSteps, extendedOrderSteps),
    new Set(mainSelection.orderSteps),
  )

  await initializeDepotFeatures(markers.depot, mapStyles.depot, new Set(mainSelection.depots))

  await initializeRegionFeatures(markers.region, mapStyles.region, new Set(crudSelection.regions))

  await initializeRoadSegmentFeatures(
    markers.roadSegment,
    mapStyles.roadSegment,
    new Set(crudSelection.routingSegments),
  )

  await initializeGeofenceFeatures(
    markers.geofence,
    mapStyles.geofence,
    new Set(crudSelection.geofences),
  )

  await initializeTrafficAreaFeatures(
    markers.trafficProfile,
    mapStyles.trafficProfile,
    new Set(crudSelection.trafficArea),
  )

  await initializeRoutePolylineFeatures(markers.routePolyline, mapStyles.routePolyline)

  await initializePlaceFeatures(markers.place, mapStyles.place, new Set(mainSelection.places))

  await initializeBreadcrumbFeatures(
    markers.breadcrumb,
    mapStyles.breadcrumb,
    new Set(mainSelection.breadcrumbs),
    breadcrumbTimeRange,
  )

  await initializeDeviceFeatures(markers.device, mapStyles.device, new Set(mainSelection.devices))

  await initializeVehicleFeatures(
    markers.vehicle,
    mapStyles.vehicle,
    new Set(mainSelection.unifiedVehicles),
  )

  await initializeDeviceEventFeatures(
    markers.deviceEvent,
    new Set(crudSelection.deviceEvents),
    breadcrumbTimeRange,
  )
}
