import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    apply: translate({
      id: 'modals.filterByBadge.btn.apply',
    }),
    clear: translate({
      id: 'modals.filterByBadge.btn.resetDefault',
    }),
    cancel: translate({
      id: 'modals.filterByBadge.btn.cancel',
    }),
  }))

  return api
}
