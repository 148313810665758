import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    routeStartIncoherenceError: translate({
      id: 'rm.sidebar.routeDetails.form.routeStart.error.coherence',
    }),
    routeEndIncoherenceError: translate({
      id: 'rm.sidebar.routeDetails.form.routeEnd.error.coherence',
    }),
    prepTimeIncoherenceError: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.errors.coherence',
    }),
    closeoutTimeIncoherenceError: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.errors.coherence',
    }),
  }))

  return api
}
