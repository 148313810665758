import { useState } from 'react'

import { Actions } from './Actions'
import { ConfirmPanel } from './ConfirmPanel'

interface Props {
  place: uui.domain.client.gps.wwgps.Place
}

export function ActionsManager(props: Props) {
  const { place } = props
  const [view, setView] = useState<'default' | 'delete'>(() => 'default')

  switch (view) {
    case 'delete':
      return <ConfirmPanel place={place} setView={setView} />
    case 'default':
      return <Actions place={place} setView={setView} />
  }
}
