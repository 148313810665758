import { theme } from '@/styles'

export const getGeofencePinHandle = (
  label: string,
): string => `<svg width="77" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path width="77" height="24" d="M8 0h65c2.2091 0 4 1.7909 4 4v16c0 2.2091-1.7909 4-4 4H8c-2.2091 0-4-1.7909-4-4v-3c0-1.2823-4-4.005-4-5.0034C0 10.998 4 8.2824 4 7V4c0-2.2091 1.7909-4 4-4z" fill-opacity=".8" fill="#000" />
      <text x="38" y="12" font-family="Roboto, sans-serif" font-size="12" font-weight="600" fill="${theme.colors.$pureWhite}" text-anchor="middle" alignment-baseline="central">
        ${label}
      </text>
    </svg>
  `
