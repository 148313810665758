import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'

import { createRoadSegmentFeature } from './createRoadSegmentFeature'
import { findRoadSegmentMarker } from './findRoadSegmentMarker'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Add new Features for all the RoadSegment marked for creation in a Changelog coming form the data-layer
 */
export async function changelogAddRoadSegmentFeatures(
  layer: VectorLayer<VectorSource>,
  routingProfiles: uui.domain.ui.map.markers.MapMarkers['roadSegment'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['roadSegment'],
  selection: Set<string>,
  routingProfileIds: IterableIterator<string>,
) {
  const features: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const profileId of routingProfileIds) {
    for (const markerId in routingProfiles[profileId]) {
      await nonblockingRender.next()
      const marker = findRoadSegmentMarker(routingProfiles[profileId], markerId)
      const selected = selection.has(profileId)

      features.push(createRoadSegmentFeature(marker, mapStyles, selected))
    }
  }

  // add all new features to the layer
  layer.getSource()?.addFeatures(features)

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
