import { TidalForm } from '@workwave-tidal/tidal/form-ui/layout'

import { useConfigureController } from '../hooks/useController'

import { type FormFields, type FormErrors, formFields } from '../formFields'
import { useFormConfig } from '../hooks/useFormConfig'

import { Form } from './Form'

type Props = {
  tenantSource: uui.domain.server.gps.telematics.TenantSource
}

export function Content(props: Props) {
  const { tenantSource } = props
  const { ready } = useConfigureController(tenantSource)
  const formConfig = useFormConfig()

  if (!ready) return null

  return (
    <TidalForm<FormFields, FormErrors>
      fieldsSpacing={2}
      disableScroll
      config={formConfig}
      fields={formFields}
      name="delete-telematics-tenant-source-form"
    >
      <Form />
    </TidalForm>
  )
}
