import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

export const setTelematicsDeviceStatus = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  uui.domain.actions.rpc.demoTools.SetTelematicsDeviceStatusAction['payload'],
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/demoTools/setTelematicsDeviceStatus', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/demoTools/setTelematicsDeviceStatus', {
      category: 'rpc',
      type: 'rpc/demoTools/setTelematicsDeviceStatus',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Set tenantSource error failed',
      error,
    })
  }
})
