import { ReadonlyBlock, ReadonlyLabel } from '@/forms-legacy'

import { Address } from './Address'
import { useTexts } from './hooks/useTexts'

interface Props {
  notificationAddresses: string[]
}

export function NotificationAddresses(props: Props) {
  const { notificationAddresses } = props
  const texts = useTexts()

  return (
    <ReadonlyBlock>
      <ReadonlyLabel>{texts.contacts}</ReadonlyLabel>
      {notificationAddresses.map(address => (
        <Address key={address} notificationAddress={address} />
      ))}
    </ReadonlyBlock>
  )
}
