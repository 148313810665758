import type { MutableState } from 'final-form'
import { setViaDotNotation } from '../../object'

type FieldName = string
type FieldValue = any

export function setInitialValue(args: [FieldName, FieldValue], state: MutableState<any, any>) {
  const [path, value] = args
  if (!state.formState.initialValues) return
  setViaDotNotation(state.formState.initialValues, path, value)
}
