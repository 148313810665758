import type { FormValidator, FormField, FormError } from '@workwave-tidal/tidal/form-fairy'

interface Validation<
  FORM_FIELDS extends Record<string, FormField>,
  FORM_ERRORS extends FormError<keyof FORM_FIELDS>,
> {
  id: string
  validator: FormValidator<FORM_FIELDS, FORM_ERRORS>
  fields?: keyof FORM_FIELDS
}

export function getStandardPasswordValidations<
  FORM_FIELDS extends Record<string, FormField>,
  FORM_ERRORS extends FormError<keyof FORM_FIELDS>,
>(field: keyof FORM_FIELDS, specialCharacters: string[]) {
  const validatePassword: Validation<FORM_FIELDS, FORM_ERRORS> = {
    id: `${field as string}_validateStandardPassword`,
    validator: formApi => {
      const { value } = formApi.getField(field)

      if (typeof value !== 'string')
        throw new Error('The provided password field should be a string')

      const tooShortID = `${field as string}_too_short`
      const tooShort = value.trim().length < 8

      const tooShortError = {
        id: tooShortID,
        field,
        message: 'Password must be at least 8 characters.',
        priority: 30,
      }

      const noNumbersID = `${field as string}_got_number`
      const noNumbers = !/\d/.test(value)

      const noNumbersError = {
        id: noNumbersID,
        field,
        message: 'Should contain at least one number.',
        priority: 25,
      }

      const noUppercaseLetterID = `${field as string}_got_uppercase_letter`
      const noUppercaseLetter = !/[A-Z]/.test(value)

      const noUppercaseLetterError = {
        id: noUppercaseLetterID,
        field,
        message: 'Should contain at least one uppercase letter.',
        priority: 20,
      }

      const noLowercaseLetterID = `${field as string}_got_lowercase_letter`
      const noLowercaseLetter = !/[a-z]/.test(value)

      const noLowercaseLetterError = {
        id: noLowercaseLetterID,
        field,
        message: 'Should contain at least one lowercase letter.',
        priority: 15,
      }

      const noSpecialCharacterID = `${field as string}_got_special_character`
      const noSpecialCharacter = !new RegExp(`[\\${specialCharacters.join('\\')}]`).test(value)

      const noSpecialCharacterError = {
        id: noSpecialCharacterID,
        field,
        message: 'Should contain at least one special character.',
        priority: 10,
      }

      return {
        [tooShortID]: tooShort ? tooShortError : null,
        [noNumbersID]: noNumbers ? noNumbersError : null,
        [noUppercaseLetterID]: noUppercaseLetter ? noUppercaseLetterError : null,
        [noLowercaseLetterID]: noLowercaseLetter ? noLowercaseLetterError : null,
        [noSpecialCharacterID]: noSpecialCharacter ? noSpecialCharacterError : null,
      }
    },
    fields: field,
  }

  return [validatePassword]
}
