import { memo } from 'react'
import { Cross, Plus } from '@/icons'
import { ReadonlyFieldArrow } from '@/forms-legacy'

import IconContainer from './IconContainer'
import TextContainer from './TextContainer'
import BaseBtn from './BaseBtn'
import { Props } from './typings'

export function TagListItemComponent(props: Props) {
  const { children, onClick, disabled, uiStyle } = props

  return (
    <BaseBtn
      onClick={onClick}
      disabled={disabled}
      style={{ padding: 0 }}
      data-testid="tags__base-button"
    >
      <ReadonlyFieldArrow style={{ width: '98%', flexWrap: 'nowrap' }}>
        <IconContainer uiStyle={uiStyle} style={{ flex: '0 0 auto' }}>
          {uiStyle === 'remove' ? <Cross color="$outrageousRed" /> : <Plus color="$pigmentGreen" />}
        </IconContainer>
        <TextContainer>{children}</TextContainer>
      </ReadonlyFieldArrow>
    </BaseBtn>
  )
}

export const TagListItem = memo(TagListItemComponent)
