import { useMemo } from 'react'

import { FormColumn } from '@/components/layout'
import { ViewHeader } from '@/components/formUi'
import { ViewContent, ViewFieldsLayout } from '@/formUi'

import { useDeleteDriverModal } from '../../../modals/DeleteDriver'

import { useViewHeaderProps } from '../hooks/useViewHeaderProps'
import { useDriversValues } from '../hooks/useDriversValues'

import { DriverApp } from './components/DriverApp'

interface Props {
  drivers: uui.domain.client.rm.ExtendedDriver[]
}

export function BulkView(props: Props) {
  const { drivers } = props
  const driverIds = useMemo(() => drivers.map(extDriver => extDriver.driver.id), [drivers])
  const driversValues = useDriversValues(drivers)

  const viewHeaderProps = useViewHeaderProps(driverIds)

  const { Modal: DeleteDriverModal } = useDeleteDriverModal()

  return (
    <FormColumn width={360} testid="form__column-secondary">
      <ViewHeader {...viewHeaderProps} testid="driver-bulk-view-header" />

      <ViewContent>
        <ViewFieldsLayout paddingTop={2}>
          <DriverApp {...driversValues} />
        </ViewFieldsLayout>
      </ViewContent>

      <DeleteDriverModal
        driverIds={driverIds}
        validationResult={viewHeaderProps.deleteValidationResult}
      />
    </FormColumn>
  )
}
