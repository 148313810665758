import { makeStyles } from '@mui/styles'
import { theme } from '@/styles'

const useStyle = makeStyles({
  separator: {
    border: 0,
    height: 1,
    width: '100%',
    background: theme.colors.$whiteSmoke,
  },
})

export function DropdownListSeparator() {
  const classes = useStyle()
  return <hr className={classes.separator} />
}
