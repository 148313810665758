import { useSelector } from 'react-redux'
import { selectTerritoryNotificationBehavior } from '@/features/domain/territory'
import { useIsForeignUser } from './useIsForeignUser'

export function useCanShowAdvancedForm() {
  const advancedManagementDisabled = useSelector(selectTerritoryNotificationBehavior)
  const isForeignUser = useIsForeignUser()

  return advancedManagementDisabled === 'EMAIL_AND_PHONE' && !isForeignUser
}
