import VectorTileSource from 'ol/source/VectorTile'

import { mapAtom } from '../map'
import { getLiveTrafficTileUrl } from './tiles'
import { getLiveTrafficLayer } from './getLiveTrafficLayer'

export function showMapTrafficTiles(showTraffic: boolean) {
  const liveTrafficLayer = getLiveTrafficLayer()

  if (!liveTrafficLayer || !mapAtom.authenticationToken) {
    throw new Error(`Trying to change map tiles before the map is ready.`)
  }

  const liveTrafficSource = liveTrafficLayer.getSource()

  if (!(liveTrafficSource instanceof VectorTileSource)) {
    throw new Error(`The Map Tile Source is not valid.`)
  }

  // update the atom
  mapAtom.showTraffic = showTraffic

  // change source
  liveTrafficSource.setUrl(getLiveTrafficTileUrl())
}
