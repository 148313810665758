import { VerticalLayout } from '@/components/layout'

import { NameAndRoute } from './NameAndRoute'
import { AddressAndViolations } from './AddressAndViolations'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

export function Single(props: Props) {
  return (
    <VerticalLayout data-testid="navigo-order-detail" justifyContent="space-between">
      <NameAndRoute extOrder={props.extOrder} />
      <AddressAndViolations extOrder={props.extOrder} />
    </VerticalLayout>
  )
}
