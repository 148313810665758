import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    sortBy: (field: string) => {
      switch (field) {
        case 'name':
          return translate({ id: 'gps.geofences.list.sorter.sortBy.name' })
        case 'ui.sort.devicesCount':
          return translate({ id: 'gps.geofences.list.sorter.sortBy.runningVehicles' })
        default:
          console.warn(`Unknown geofence field: ${field}`)
          return ''
      }
    },

    sortByField: (field: string) =>
      translate({ id: 'gps.geofences.list.sorter.sortBy', values: { field } }),

    sortByName: translate({ id: 'gps.geofences.list.sorter.sortBy.name' }),
    sortByRunningVehicles: translate({ id: 'gps.geofences.list.sorter.sortBy.runningVehicles' }),
    sortAscending: translate({ id: 'gps.geofences.list.sorter.sortBy.direction.ascending' }),
    sortDescending: translate({ id: 'gps.geofences.list.sorter.sortBy.direction.descending' }),
  }))

  return api
}
