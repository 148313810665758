export function toggleIds<T = string>(ids: T[], selectedIds: T[]): T[] {
  return ids.reduce((acc, id) => {
    return toggleId<T>(acc, id)
  }, selectedIds)
}

function toggleId<T = string>(ids: T[], id: T): T[] {
  const index: number = ids.findIndex(el => el === id)

  if (index === -1) {
    return [...ids, id]
  }

  return [...ids.slice(0, index), ...ids.slice(index + 1)]
}
