import type { TerritoryConfig } from '../../../../../types'

import { useMemo } from 'react'

interface Territory {
  territoryName: string
  territoryId: string
  config: TerritoryConfig
}

export function useTerritories(
  fieldValue: uui.domain.client.rm.CourierFilter,
  territoryNames: Record<string, string>,
) {
  return useMemo(() => {
    return Object.entries(fieldValue).reduce<Territory[]>((acc, [territoryId, config]) => {
      let territoryName = ''

      if (!territoryNames[territoryId]) {
        if (process.env.NODE_ENV === 'development') {
          throw new Error(`cannot find territory with id: ${territoryId} in domain territories`)
        }
      } else {
        territoryName = territoryNames[territoryId]
      }

      acc.push({
        territoryId,
        config,
        territoryName,
      })

      return acc
    }, [])
  }, [fieldValue, territoryNames])
}
