import { type ReactNode } from 'react'
import { Box, Link, Stack, Typography, styled } from '@mui/material'
import { Warning } from '@mui/icons-material'

type LinkWithIconProps = {
  href: string
  icon: ReactNode
  error?: boolean
  children: ReactNode
}

const LinkComponent = styled(Link, {
  name: 'LinkComponent',
})(() => ({
  '.external-link-icon-container': {
    display: 'none',
  },

  '&:hover': {
    '.external-link-icon-container': {
      display: 'inline-flex',
    },
  },
}))

export function LinkWithIcon(props: LinkWithIconProps) {
  const { href, icon, error = false, children } = props

  return (
    <LinkComponent href={href} target="_blank" color={error ? 'error' : undefined}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="body1">{children}</Typography>
        <Box display="inline-flex" className="external-link-icon-container">
          {icon}
        </Box>
        {error && (
          <Box display="inline-flex" className="icon-container">
            <Warning />
          </Box>
        )}
      </Stack>
    </LinkComponent>
  )
}
