import type { ErrorInfo, ComponentType, ReactFragment, ReactElement } from 'react'

import { Component } from 'react'

export type FallbackProps = {
  error: Error
  resetErrorBoundary: () => void
}

export type Props = {
  children: ReactFragment | ReactElement
  FallbackComponent: ComponentType<FallbackProps>
  onReset?: (...args: any[]) => void
  onError?: (error: Error, info: ErrorInfo) => void
}

type State = {
  error: Error | null
}

const initialState: State = { error: null }

export class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  state = initialState
  updatedWithError = false
  resetErrorBoundary = (...args) => {
    this.props.onReset?.(...args)
    this.reset()
  }

  reset() {
    this.updatedWithError = false
    this.setState(initialState)
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.props.onError?.(error, info)
  }

  componentDidUpdate() {
    const { error } = this.state

    if (error !== null && !this.updatedWithError) {
      this.updatedWithError = true
      return
    }

    if (error !== null) {
      this.reset()
    }
  }

  render() {
    const { error } = this.state
    const { FallbackComponent } = this.props

    if (error !== null) {
      const props = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      }

      if (!FallbackComponent) {
        throw new Error('ErrorBoundary requires a FallbackComponent prop')
      }
      return <FallbackComponent {...props} />
    }

    return this.props.children
  }
}
