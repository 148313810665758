import { useState } from 'react'
import { differenceInHours } from 'date-fns/esm'
import { useIntl } from '@/intl'

const HISTORICAL_GPS_POSITION_THRESHOLD_HOURS = 48
export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    status: (
      lastPosition:
        | uui.domain.client.gps.wwgps.GpsInfo
        | uui.domain.client.gps.telematics.CurrentPositionGpsInfo,
    ) => {
      const isHistorical =
        differenceInHours(new Date(), new Date(lastPosition.ts)) >
        HISTORICAL_GPS_POSITION_THRESHOLD_HOURS

      if (isHistorical) return translate({ id: 'rm.contextual.breadcrumbs.notReportingVehicle' })

      switch (lastPosition.status) {
        case 'moving':
          return translate({ id: 'rm.contextual.breadcrumbs.movingVehicle' })
        case 'stalling':
          return translate({ id: 'rm.contextual.breadcrumbs.stallingVehicle' })
        case 'stopped':
          return translate({ id: 'rm.contextual.breadcrumbs.stoppedVehicle' })
      }
    },

    lastReport: translate({ id: 'rm.contextual.vehicle.lastReport' }),
    odometer: translate({ id: 'global.odometer' }),
    heading: translate({ id: 'global.heading' }),
    since: translate({ id: 'global.since' }),
  }))

  return api
}
