import { Typography } from '@mui/material'

interface Props {
  children: string
}

export function Title(props: Props) {
  const { children } = props

  return (
    <Typography component="h1" variant="h6" marginRight={1} fontWeight={600} noWrap>
      {children}
    </Typography>
  )
}
