import { useMemo } from 'react'

import { useTexts } from '../../../useTexts'

export const useBtnTexts = (plural: boolean) => {
  const texts = useTexts()

  const btnTexts = useMemo(() => {
    if (plural)
      return {
        duplicate: texts.duplicateAll,
        export: texts.exportAll,
        delete: texts.deleteAll,
        edit: texts.editAll,
      }

    return {
      duplicate: texts.duplicate,
      delete: texts.delete,
      export: texts.export,
      edit: texts.edit,
    }
  }, [plural, texts])

  return btnTexts
}
