import { Add } from '@mui/icons-material'

import { ListHeaderActions, ListHeaderPrimaryActionButton } from '@/components/List'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../../../../hooks/useTexts'
import { useActions } from './hooks/useActions'

export function Actions() {
  const { editing, onCreate } = useActions()
  const texts = useTexts()

  return (
    <ListHeaderActions>
      <ReadOnlyTooltip
        placement="bottom"
        render={preventEditing => (
          <Tooltip title={texts.addDriver} placement="bottom">
            <ListHeaderPrimaryActionButton
              disabled={preventEditing || editing}
              onClick={onCreate}
              trackId="create-driver_icon-button"
            >
              <Add />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
    </ListHeaderActions>
  )
}
