import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { produce } from 'immer'

import { useAppDispatch } from '@/store'
import { isDeepEqual } from '@/server-data'
import { setListOptions } from '@/features/domain/lists/actions'
import { selectDriverAssignmentsListOptions } from '@/features/domain/lists'

type DriverAssignmentFilterStatus = {
  type: 'all' | 'annual' | 'any' | 'monthly' | 'weekly' | 'oneTime'
}

const filterValuesByDriverAssignmentType = {
  all: ['ANNUAL', 'BEFORE', 'DATE', 'MONTHLY', 'TODAY', 'WEEKLY', 'any'],
  annual: ['ANNUAL'],
  any: ['ANY'],
  monthly: ['MONTHLY'],
  weekly: ['WEEKLY'],
  oneTime: ['DATE'],
}

export function useFilterList() {
  const dispatch = useAppDispatch()
  const listOptions = useSelector(selectDriverAssignmentsListOptions)

  const setFilter = useCallback(
    async (filter: { type: DriverAssignmentFilterStatus['type'] }) => {
      const nextOptions = produce(listOptions, draft => {
        draft.filter = [
          {
            field: 'ui.filter.recurrenceType',
            values: filterValuesByDriverAssignmentType[filter.type],
            operator: 'or',
          },
        ]
      })

      // avoid triggering any calculations in case the group didn't change
      if (nextOptions === listOptions) return

      const request = await dispatch(
        setListOptions({ category: 'driverAssignments', options: nextOptions }),
      )
      return setListOptions.fulfilled.match(request)
    },
    [dispatch, listOptions],
  )

  return {
    filter: listOptions.filter.reduce((acc, { values }, index) => {
      switch (index) {
        case 0:
          acc.type = getDriverAssignmentStepsType(values)
          break
      }

      return acc
    }, {} as DriverAssignmentFilterStatus),
    setFilter,
  }
}

function getDriverAssignmentStepsType(
  filterValues: string[] | number[] | boolean[],
): DriverAssignmentFilterStatus['type'] {
  const key = Object.keys(filterValuesByDriverAssignmentType).find(driverAssignmentsType => {
    const values = filterValuesByDriverAssignmentType[driverAssignmentsType]

    /*
      filterValues could [true,false] or [false,true], in both cases we need to match it with the
      values of filterValuesByDriverAssignmentsType
    */
    return isDeepEqual([...filterValues].sort(), values)
  })
  if (!key && process.env.NODE_ENV === 'development') {
    throw new Error(`No driver assignment type for ${filterValues}`)
  }

  return (key ?? '') as DriverAssignmentFilterStatus['type']
}
