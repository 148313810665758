import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Unlock } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  multiple: boolean
  disabled: boolean
}

export function UnlockButton(props: Props) {
  const { multiple, disabled, onClick } = props
  const texts = useTexts()

  return (
    <ReadOnlyTooltip
      render={preventEditing => (
        <NavigoIconButton
          onClick={onClick}
          disabled={preventEditing}
          testid="navigo-routes-overview-actions-unlock"
          Icon={<Unlock size={14} color={disabled || preventEditing ? '$silver' : '$nightRider'} />}
          text={multiple ? texts.unlockAll : texts.unlock}
        />
      )}
    />
  )
}
