import { type ResourceModel } from '@bryntum/schedulerpro'
import { type CSSProperties } from 'react'
import { useTexts } from './useTexts'
import { useAggregateData } from './hooks/useAggregateData'

interface Props {
  record: ResourceModel
}

const containerStyle: CSSProperties = { display: 'flex', flexDirection: 'column' }
const titleStyle: CSSProperties = {
  letterSpacing: '0.0075em',
  marginBottom: '0.35rem',
  fontWeight: 500,
  fontSize: '1.25rem',
}
const textStyle: CSSProperties = {
  letterSpacing: '0.00714em',
  marginBottom: '0.35rem',
  fontWeight: 500,
  fontSize: '0.875rem',
}

export function ApprovedTimeTooltip(props: Props) {
  const { record } = props

  const { workingTime, drivingTime } = useAggregateData(record)
  const texts = useTexts()

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>{texts.approvedTime}</div>

      <div style={textStyle}>
        {texts.workingTime(workingTime.value, workingTime.total, workingTime.saturation)}
      </div>

      <div style={textStyle}>
        {texts.drivingTime(drivingTime.value, drivingTime.total, drivingTime.saturation)}
      </div>
    </div>
  )
}
