import type { ModalData, MovePairedOrdersPayload } from '../typings'

import { useState, useEffect } from 'react'

import { useReadOnly } from '@/hooks'
import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

import { useControllerData } from './useControllerData'
type ModalState = 'ready' | 'pending'

export const modalId = 'movePairedOrderStep'

export const useController = () => useModalController<ModalData, ModalState>(modalId)

export const useConfigureController = (payload: MovePairedOrdersPayload) => {
  const { readonly } = useReadOnly()
  const close = useCloseModal()
  const data = useControllerData(payload)

  // INITIALIZATION
  const [options] = useState(() => {
    return {
      createInitialState: () => ({
        close,
        data,
      }),
    }
  })

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const { update, updateData, status: ctrlStatus } = ctrl

  useEffect(() => {
    updateData(data)
  }, [data, updateData])

  // External transaction
  useEffect(() => {
    switch (readonly) {
      case true:
        update({ status: 'pending' })
        break

      case false:
        if (ctrlStatus !== 'pending') return

        update({ status: 'ready' })
        break
    }
  }, [readonly, ctrlStatus, update])

  return ctrl
}
