import type { QueryParameters } from '@/hooks'

import { useState, useEffect, useRef } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

import { autoLogin } from '@/features/actions/autoLogin'
import { useNavigate } from '@/routing'
import { resumeImport } from '@/utils'
import { useAppDispatch, useJournal } from '@/store'
import { useEnvironmentURLs, clearTokens, updateAccountDataToken } from '@/atoms'

type LoginRequest = Parameters<typeof autoLogin>[0]

type Props = {
  onComplete: (success: boolean) => void
  onLoadInitialDataComplete: () => void
  query: QueryParameters
}

export function AutoLoginFromUrl(props: Props) {
  const { onComplete, onLoadInitialDataComplete, query } = props

  const journal = useJournal()
  const dispatch = useAppDispatch()
  const { serverUrl } = useEnvironmentURLs()
  const navigate = useNavigate()

  const [request, setRequest] = useState<LoginRequest | undefined>()

  const api = useRef({
    journal,
    dispatch,
    navigate,
    serverUrl,
    onComplete,
    clearTokens,
    updateAccountDataToken,
    onLoadInitialDataComplete,
  })

  useEffect(() => {
    api.current = {
      journal,
      dispatch,
      navigate,
      serverUrl,
      onComplete,
      clearTokens,
      updateAccountDataToken,
      onLoadInitialDataComplete,
    }
  })

  useEffect(() => {
    const { journal, onComplete, serverUrl } = api.current

    // Resume pending imports if present
    const importData = resumeImport()

    let simulationId: string | undefined
    let territoryId: string | undefined

    if (importData?.type === 'importWizard') {
      territoryId = importData.territoryId
      simulationId = importData.simulationId
    }

    if (query.loginnonce) {
      journal.main(`Try to login with a URL token`, { tags: ['bootstrap'] })

      setRequest({
        serverUrl,
        mode: 'fromRM',
        username: 'anonymous',
        loginnonce: query.loginnonce,
        importData,
        territoryId,
        simulationId,
      })
    } else if (query.gpsnonce) {
      journal.main(`Try to login with a URL token`, { tags: ['bootstrap'] })

      setRequest({
        serverUrl,
        mode: 'fromGPS',
        username: 'anonymous',
        gpsnonce: query.gpsnonce,
        importData,
        territoryId,
        simulationId,
      })
    } else if (query.authtoken) {
      journal.main(`Try to login with a URL token`, { tags: ['bootstrap'] })

      setRequest({
        serverUrl,
        mode: 'authtoken',
        username: 'anonymous',
        authtoken: query.authtoken,
        importData,
        territoryId,
        simulationId,
      })
    } else if (query.loginasnonce) {
      journal.main(`Try to login impersonating another User`, { tags: ['bootstrap'] })

      setRequest({
        serverUrl,
        mode: 'loginas',
        username: 'anonymous',
        importData,
        territoryId,
        simulationId,
        loginasnonce: query.loginasnonce,
      })
    } else {
      journal.main('Cannot autologin from url ', {
        tags: ['bootstrap'],
        info: {
          query,
        },
      })

      // no query parameters to access, try LocalStorage
      onComplete(false)
    }
  }, [query])

  useEffect(() => {
    const { dispatch, navigate, clearTokens, updateAccountDataToken, onComplete } = api.current

    if (!request) return

    const execute = async () => {
      try {
        const loginRequest = await dispatch(autoLogin(request))

        if (autoLogin.fulfilled.match(loginRequest)) {
          // success
          const { clientUserProfile: profile, loadInitialDataPromise } = unwrapResult(loginRequest)
          loadInitialDataPromise.then(api.current.onLoadInitialDataComplete)

          // login successful, update account data atom (rememberMe = true for autologin)
          updateAccountDataToken(profile.user.username, profile.authtoken, true)

          onComplete(true)
        } else {
          // managed error

          if (loginRequest.payload?.type === 'too-many-sessions') {
            navigate('error/too-many-sessions')
          } else {
            clearTokens()
          }

          // login Failed
          onComplete(false)
        }
      } catch (e) {
        clearTokens()
        navigate('error')
      }
    }

    execute()
  }, [request])

  return null
}
