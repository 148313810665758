import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    selectBtn: (allSelected: boolean): string =>
      translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' }),
    noEntries: {
      title: translate({ id: 'orderSteps.list.noEntries.title' }),
      description: translate({ id: 'orderSteps.list.noEntries.description' }),
      action: translate({ id: 'orderSteps.list.noEntries.action' }),
    },
    noResults: {
      title: translate({ id: 'orderSteps.list.noResults.title' }),
      description: translate({ id: 'orderSteps.list.noResults.description' }),
    },
  }))

  return api
}
