import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    done: translate({ id: 'ordersGrid.columnsPanel.done' }),
    reset: translate({ id: 'ordersGrid.columnsPanel.reset' }),
    sortBy: translate({ id: 'ordersGrid.columnsPanel.sortBy' }),
    sortByCategory: translate({ id: 'ordersGrid.columnsPanel.sortBy.category' }),
    sortByGridOrder: translate({ id: 'ordersGrid.columnsPanel.sortBy.gridOrder' }),
    columnsGroup: {
      loads: translate({ id: 'ordersGrid.columnsPanel.columnsGroup.loads' }),
      customFields: translate({ id: 'ordersGrid.columnsPanel.columnsGroup.customFields' }),
      notifications: translate({ id: 'ordersGrid.columnsPanel.columnsGroup.notifications' }),
    },
  }))

  return api
}
