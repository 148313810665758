import type { Validation } from '../types'

import { useTexts } from '../useTexts'

const hotwordsRegexp = new RegExp(/@@(.*?)@@/gm)

export function useTemplateCustomValidator(): Validation[] {
  const texts = useTexts()

  return [
    {
      id: 'templateCustomRequired',
      fields: 'templateCustom',
      validator: formApi => {
        // access the current name form-field
        const field = formApi.getField('templateCustom')

        return {
          templateCustomRequiredError:
            field.required && field.value.trim().length === 0
              ? {
                  id: 'templateCustomRequiredError',
                  field: 'templateCustom',
                  message: texts.requiredField,
                  priority: 10,
                }
              : null,
        }
      },
    },
    {
      id: 'templateCustomMaxLength',
      fields: 'templateCustom',
      validator: formApi => {
        let messageLength = 0

        // access the current name form-field
        const includeMessageTrackingLink = formApi.getField('includeMessageTrackingLink').value
        const notificationWay = formApi.getField('notificationWay').value
        const includeMessageUnsubscriptionInstructions = formApi.getField(
          'includeMessageUnsubscriptionInstructions',
        ).value
        const field = formApi.getField('templateCustom')

        const message = field.value

        // Calculate max message length based on the notification type
        const maxLength = notificationWay === 'PHONE' ? 500 : 2500

        // Retrieve current character limits
        const defaultLimits = formApi.getField('templateDefaultLimits').value
        const customLimits = formApi.getField('templateCustomLimits').value
        const limits = { ...defaultLimits, ...customLimits }

        // Retrieve tokens in text
        const hotwords = message.match(hotwordsRegexp)?.map(hotword => hotword.replace(/@@/g, ''))

        // Adds length of the message without hotwords/links/instructions
        messageLength += message.replace(hotwordsRegexp, '').length

        // If hotwords are present add the correct length from each one
        for (const hotword of hotwords || []) {
          if (!limits[hotword]) continue

          messageLength += limits[hotword]
        }

        // If we need to include the tracking link, add the Tracking Link length
        if (includeMessageTrackingLink) {
          messageLength += limits.TrackingLink ?? 0
        }

        // If we need to include the tracking link, add the Unsubscribe length
        if (includeMessageUnsubscriptionInstructions) {
          messageLength += limits.Unsubscribe ?? 0
        }

        return {
          templateCustomMaxLengthError:
            messageLength > maxLength
              ? {
                  id: 'templateCustomMaxLengthError',
                  field: 'templateCustom',
                  message: texts.messageTooLong(maxLength),
                  priority: 9,
                }
              : null,
        }
      },
    },
  ]
}
