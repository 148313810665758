import type { LazyCreateFormConfig, FormValues } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'

import { useState } from 'react'

export function useCreateFormConfig(
  overrideValues: FormValues<FormFields>,
): LazyCreateFormConfig<FormFields, FormErrors> {
  const [createFormConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    return () => ({
      overrideValues,
    })
  })

  return createFormConfig
}
