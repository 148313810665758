import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'setup.driverActivity.view.title' }),
    headerDescription: translate({ id: 'setup.reasonsForNotCompletedOrders.view.description' }),

    save: translate({ id: 'setup.reasonsForNotCompletedOrders.form.save' }),
    cancel: translate({ id: 'setup.reasonsForNotCompletedOrders.form.cancel' }),
    add: translate({ id: 'setup.reasonsForNotCompletedOrders.form.addReason' }),
    maxReasonsReached: (count: number) =>
      translate({
        id: 'setup.reasonsForNotCompletedOrders.form.maxReasonsReached',
        values: { count },
      }),
    more: translate({ id: 'setup.reasonsForNotCompletedOrders.form.more' }),
    asc: translate({ id: 'setup.reasonsForNotCompletedOrders.form.ASC' }),
    desc: translate({ id: 'setup.reasonsForNotCompletedOrders.form.DESC' }),
    removeAll: translate({ id: 'setup.reasonsForNotCompletedOrders.form.removeAll' }),
    noReasons: translate({ id: 'setup.reasonsForNotCompletedOrders.form.noReasons' }),
    reasonsError: translate({ id: 'setup.reasonsForNotCompletedOrders.form.error' }),
    resultOk: translate({ id: 'setup.reasonsForNotCompletedOrders.form.result.ok' }),
  }))

  return api
}
