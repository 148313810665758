import { snapshot } from 'valtio'

import { locationPin } from '../locationPin'

import { removeLocationPin } from './removeLocationPin'

export type UpdateLocationPin = (
  prev: uui.domain.ui.map.LocationPin,
) => uui.domain.ui.map.LocationPin

export type UpdateLocationPinParam =
  | UpdateLocationPin
  | Partial<uui.domain.ui.map.LocationPin>
  | 'reset'

export function updateLocationPin(
  id: uui.domain.ui.map.LocationPinId,
  valueOrFunc: UpdateLocationPinParam,
): uui.domain.ui.map.LocationPin | void {
  // reset
  if (valueOrFunc === 'reset') return removeLocationPin(id)
  if (valueOrFunc === undefined) return removeLocationPin(id)

  const mutableTargetPin = locationPin.pins[id]

  if (!mutableTargetPin) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`Trying to update a non-existent LocationPin with ID: ${id}`)
    }
    return
  }

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    locationPin.pins[id] = valueOrFunc(snapshot(mutableTargetPin))
  } else if (mutableTargetPin && valueOrFunc) {
    Object.assign(locationPin.pins[id] ?? {}, valueOrFunc)
  }

  return snapshot(mutableTargetPin)
}
