import { useSnapshot } from 'valtio'
import { environment } from '../environment'

/**
 * Get the environment QA variable, avoiding the consumer to address when the config.json file
 * isn't available yet.
 */
export function useEnvironmentQa() {
  const { env } = useSnapshot(environment)
  return env.status === 'ready' ? env.core.qa : 'disable'
}
