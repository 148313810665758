import type { Filter, Format } from '../typings'

import { useEffect, useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

import { useData } from './useData'

export type Column = {
  id: string
  label: string
}

type ColumnsState =
  | {
      status: 'not-requested'
    }
  | {
      status: 'loading'
    }
  | {
      status: 'complete'
      columns: Column[]
    }
  | {
      status: 'error'
    }

type ModalState = 'ready' | 'pending' | 'invalidForEmptyRoutes' | 'invalidForSimulationEnvironment'
export interface ModalData {
  wizardStep: 'configure-format' | 'configure-columns'
  filter: Filter
  format: Format
  includePod: boolean
  isSimulation: boolean
  includeBarcodes: boolean
  keepEnglishColumns: boolean
  canExportPod: boolean
  approvedRoutesAmount: number
  selectedRoutes: uui.domain.client.rm.ExtendedRoute[]
  initiallySelectedRoutes: uui.domain.client.rm.ExtendedRoute[]
  columnsState: ColumnsState
  columnSelection: string[]
  showEmptySelectionWarning: boolean
  selectedRoutesIds: readonly string[]
  customizeColumns: boolean
}

export const modalId = 'exportRoutes'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = () => {
  const {
    isSimulation,
    currentRoutes,
    selectedRoutes,
    approvedRoutesAmount,
    canExportInSimulation,
    format,
    includeBarcodes,
    includePod,
    selectedRoutesIds,
    canExportPod,
    customizeColumns,
  } = useData()

  const close = useCloseModal()

  // INITIALIZATION

  const filter =
    selectedRoutes.length === 0 || !currentRoutes ? ('dispatched' as const) : ('selected' as const)

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        format,
        canExportPod,
        includePod: currentRoutes ? includePod : false,
        keepEnglishColumns: false,
        isSimulation,
        wizardStep: 'configure-format' as const,
        initiallySelectedRoutes: selectedRoutes,
        selectedRoutes,
        includeBarcodes,
        approvedRoutesAmount,
        canExportInSimulation,
        showEmptySelectionWarning: false,
        columnsState: {
          status: 'not-requested' as const,
        },
        columnSelection: [],
        filter,
        selectedRoutesIds,
        customizeColumns,
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const { update, updateData } = ctrl

  // EFFECTS
  useEffect(() => {
    updateData({ selectedRoutes })
  }, [selectedRoutes, updateData])

  // // EFFECTS
  useEffect(() => {
    updateData({ approvedRoutesAmount })
  }, [approvedRoutesAmount, updateData])

  // Invalid state management
  useEffect(() => {
    if (isSimulation && !canExportInSimulation) {
      update({ invalid: true, status: 'invalidForSimulationEnvironment' })
      return
    }

    if (selectedRoutes.length === 0 && approvedRoutesAmount === 0) {
      update({ invalid: true, status: 'invalidForEmptyRoutes' })
      return
    }

    update({ invalid: false, status: 'ready' })
  }, [selectedRoutes, isSimulation, canExportInSimulation, update, approvedRoutesAmount])

  return ctrl
}
