import { useCallback } from 'react'

import { useNavigate } from '@/routing'

export function useActions() {
  const navigateSetup = useNavigate<uui.routing.Setup>(true)

  const navigateToTerritorySettings = useCallback(() => {
    navigateSetup('territory')
  }, [navigateSetup])

  return {
    navigateToTerritorySettings,
  }
}
