import { useSelector } from 'react-redux'

import { useEditingStateWithCtx } from '@/atoms'
import { selectOrderStepsListStructure } from '@/features/domain/lists'

import { useFilterList } from './useFilterList'
import { useSearchList } from './useSearchList'

export function useFilter() {
  const { editing } = useEditingStateWithCtx('order')
  const listStructure = useSelector(selectOrderStepsListStructure)
  const { query, setQuery } = useSearchList()
  const { filter, setFilter } = useFilterList()

  return {
    query,
    filter,
    editing,
    setQuery,
    setFilter,
    noEntries: listStructure.totalItems === 0,
  }
}
