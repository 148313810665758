import { VerticalLayout } from '@/components/layout/VerticalLayout'
import { WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useTexts } from '../useTexts'

export function Body() {
  const texts = useTexts()

  return (
    <VerticalLayout marginTop={32}>
      <WarningCard
        preset="warning"
        title={texts.cardTitle}
        style={{ wordBreak: 'break-word' }}
        Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
        description={texts.cardDescription}
      />
    </VerticalLayout>
  )
}
