// import { useCallback } from 'react'

import { ConfirmPanel as ConfirmPanelComp } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'
import { useActions } from '../hooks/useActions'

interface Props {
  trafficArea: uui.domain.client.rm.TrafficRegion
  onCancel: () => void
}

export function ConfirmPanel(props: Props) {
  const { trafficArea, onCancel } = props
  const actions = useActions(trafficArea)
  const texts = useTexts()

  return (
    <ConfirmPanelComp
      title={texts.confirmPanelTitle}
      confirmationText={texts.delete}
      onCancel={onCancel}
      onConfirm={actions.onDelete}
      testid="setup-traffic-delete-confirm-panel"
    />
  )
}
