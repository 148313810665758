import { type SchedulerPro } from '@bryntum/schedulerpro'
import { subscribe } from 'valtio/vanilla'

import { schedulerPreferencesAtom } from '../atoms/schedulerPreferences'

export function startShowAbsoluteValuesSync(scheduler: SchedulerPro) {
  // Subscribe to the preferences atom to update the filters
  let currentShowAbsoluteValues = schedulerPreferencesAtom.preferences.showAbsoluteValues

  return subscribe(schedulerPreferencesAtom, () => {
    // Refresh the scheduler if showAbsoluteValues changed
    if (currentShowAbsoluteValues === schedulerPreferencesAtom.preferences.showAbsoluteValues)
      return

    // Update the showAbsoluteValues value
    currentShowAbsoluteValues = schedulerPreferencesAtom.preferences.showAbsoluteValues

    scheduler.refreshRows()
  })
}
