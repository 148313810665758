import { addSeconds, differenceInSeconds, max, min } from 'date-fns/esm'
import { type EventResizeConfig } from '@bryntum/schedulerpro'

import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectDayRangeInfo } from '@/features/domain/scheduler'
import { store } from '@/store'

import { setResizeTimeWindowAtom } from '../../../../atoms/resizeTimeWindow'

export const validatorFn: EventResizeConfig['validatorFn'] = params => {
  {
    const { startDate, endDate } = params

    const state = store.getState()

    const { start: calendarStart } = selectCalendarRangeInterval(state)
    const { dayStartOffset } = selectDayRangeInfo(state)
    calendarStart.setHours(0, 0, 0)

    const updatedStartDate = startDate
    const updatedEndDate = endDate
    const startBoundary = addSeconds(calendarStart, dayStartOffset)
    const endBoundary = addSeconds(calendarStart, dayStartOffset + 24 * 60 * 60)
    const valid = updatedStartDate >= startBoundary && updatedEndDate <= endBoundary

    // If is not valid we need to store the last valid time window, so we can save it later (via onEventResizeEnd).
    // This is required because the time window received by onEventResizeEnd is the real position of the mouse, that can be outside the valid range.
    if (!valid) {
      const start = max([updatedStartDate, startBoundary])
      const end = min([updatedEndDate, endBoundary])

      const day = new Date(startDate)
      day.setHours(0, 0, 0)

      setResizeTimeWindowAtom({
        startSec: differenceInSeconds(start, day),
        endSec: differenceInSeconds(end, day),
      })
    }

    return valid
  }
}
