import { Box } from '@mui/material'

import { WarningCard } from '@/local/components'
import { Thick } from '@/icons'

import { useTexts } from '../useTexts'

export function Body() {
  const texts = useTexts()

  return (
    <Box paddingTop={2}>
      <WarningCard
        Icon={<Thick size={14} color="$pigmentGreen" />}
        description={texts.cardText}
        preset="success"
        title=""
      />
    </Box>
  )
}
