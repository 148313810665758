import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { MapControls } from '@/components/MapControls'

import { RoadsRoot } from './components/form'
import { Map } from './components/Map'

import { useEnsureRoadSegmentVisibility } from './hooks/useEnsureRoadSegmentVisibility'

export function Roads() {
  useEnsureRoadSegmentVisibility()

  return (
    <HorizontalLayout data-testid="setup-road-editor-root">
      <RoadsRoot />

      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />
          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
