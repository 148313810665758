import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    plannedTimeTooltip: translate({
      id: 'rm.sidebar.routeDetails.header.plannedTime.tooltip.planned',
    }),
    trackedTimeTooltip: translate({
      id: 'rm.sidebar.routeDetails.header.trackedTime.tooltip.executed',
    }),
    trackedTimeTooltipNotYetCollected: translate({
      id: 'rm.sidebar.routeDetails.header.trackedTime.tooltip.disabled.notYetCollected',
    }),
    trackedTimeTooltipWontBeCollected: translate({
      id: 'rm.sidebar.routeDetails.header.trackedTime.tooltip.disabled.wontBeCollected',
    }),
    trackedTimeTooltipRmOnly: translate({
      id: 'rm.sidebar.routeDetails.header.trackedTime.tooltip.disabled.rmOnly',
    }),
  }))

  return api
}
