import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

interface ImportedTerritory {
  archived: uui.domain.server.rm.RoutePlan[]
  simulations: uui.domain.server.rm.RoutePlan[]
  operations: uui.domain.server.rm.RoutePlan

  territory: uui.domain.server.rm.BaseTerritory
}

type ImportNewTerritoryArgs = {
  name: string
  importedTerritory: ImportedTerritory
}

export const importNewTerritory = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  ImportNewTerritoryArgs,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/demoTools/importDemoTerritory', async (params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/demoTools/importNewTerritory', {
      category: 'rpc',
      type: 'rpc/demoTools/importNewTerritory',
      params: { ...params, open: true },
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Time shift plan Failed',
      error,
    })
  }
})
