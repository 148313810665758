import { getMap } from '../../atoms/map/utils/getMap'
import { findLayer } from '../utils/findLayer'

/**
 * Remove all Regions Features from the Map
 */
export function clearRegionFeatures() {
  const map = getMap()
  const layer = findLayer(map, 'region')

  layer.getSource()?.clear()
}
