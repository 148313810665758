import type { ListIconPreset } from '../typings'

import { Bus, Car, Van, Truck, Pedestrian, Scooter, Siren, LongTruck, Pickup } from '@/icons'

interface Props {
  preset: ListIconPreset
}

export function Icon(props: Props) {
  switch (props.preset) {
    case 'bus':
      return <Bus />

    case 'car':
      return <Car />

    case 'van':
      return <Van />

    case 'truck':
      return <Truck />

    case 'pedestrian':
      return <Pedestrian />

    case 'scooter':
      return <Scooter />

    case 'pickup':
      return <Pickup />

    case 'siren':
      return <Siren />

    case 'longtruck':
      return <LongTruck />

    default:
      return null
  }
}
