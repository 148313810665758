import { useSelector } from 'react-redux'
import { selectUsersListStructure } from '@/features/domain/lists'

import { useSettingsEditing } from '../../../../../../../../hooks/useSettingsEditing'
import { useFilterList } from './useFilterList'
import { useSearchList } from './useSearchList'

export function useFilter() {
  const { editing } = useSettingsEditing('user-management', 'none')
  const { query, setQuery } = useSearchList()
  const { filter, setFilter } = useFilterList()
  const listStructure = useSelector(selectUsersListStructure)

  return {
    query,
    filter,
    editing,
    setQuery,
    setFilter,
    noEntries: listStructure.totalItems === 0,
  }
}
