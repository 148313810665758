import { isRenderGroupHeader } from './isRenderGroupHeader'

export const getItemOrGroupHeader = <T>(
  itemIdOrGroupHeader: uui.domain.ui.list.RenderItem,
  itemsById,
): T | uui.domain.ui.list.RenderGroupHeader =>
  isRenderGroupHeader(itemIdOrGroupHeader) ? itemIdOrGroupHeader : itemsById[itemIdOrGroupHeader]

export const getItemIdOrGroupHeaderId = <T>(
  itemIdOrGroupHeader: T | uui.domain.ui.list.RenderGroupHeader,
  getItemId: (item: T) => string,
): string =>
  isRenderGroupHeader(itemIdOrGroupHeader)
    ? itemIdOrGroupHeader.groupId
    : getItemId(itemIdOrGroupHeader)
