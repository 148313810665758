import type { BulkFieldValue } from '../../../types'

type TimeWindowCouple = [uui.domain.rm.TimeWindow?, uui.domain.rm.TimeWindow?]

export function computeTimeWindows(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
): BulkFieldValue<TimeWindowCouple> {
  const timeWindows = orders[0].orderStep.timeWindows as TimeWindowCouple
  const exact = orders.every(o => isEqual(o.orderStep.timeWindows as TimeWindowCouple, timeWindows))

  if (exact) {
    return {
      status: 'exact',
      value: timeWindows,
    }
  }

  return {
    status: 'mixed',
  }
}

function isEqual(a: TimeWindowCouple, b: TimeWindowCouple) {
  if (a.length !== b.length) return false

  // max 2 time window are allowed, stringify makes the code easy to read
  // and do not impact on performance since the object are very small
  return JSON.stringify(a) === JSON.stringify(b)
}
