import { Stack, Typography } from '@mui/material'
import { NoResult2 } from '@workwave-tidal/icons/illustrations'

import { type LocaleText } from '../../../types'

interface Props {
  localeText: LocaleText
}

export function NoColumns(props: Props) {
  const { localeText } = props

  return (
    <Stack
      gap={3}
      paddingX={3}
      paddingY={4}
      sx={theme => ({
        background: theme.palette.background.default3,
      })}
    >
      <Stack>
        <Typography variant="body1">{localeText.columnsSequence}</Typography>
        <Typography variant="caption">&nbsp;</Typography>
      </Stack>

      <Stack gap={2} justifyContent="center" alignItems="center">
        <NoResult2 size={40} />
        <Stack gap={0.5} justifyContent="center" alignItems="center">
          <Typography variant="subtitle2">{localeText.noColumns}</Typography>
          <Typography variant="caption">{localeText.noColumnsDescription}</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
