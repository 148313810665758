import { FlexBox, WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useTexts } from '../useTexts'

export function NotificationsListFailed() {
  const texts = useTexts()

  return (
    <FlexBox column vAlignContent="top" padding="34px 32px">
      <WarningCard
        preset="alert"
        title={texts.notificationsListFailureTitle}
        Icon={<WarningTriangle />}
        description={texts.notificationsListFailureDescription}
      />
    </FlexBox>
  )
}
