import type {
  RenderItemProps,
  ItemWithPosition,
  OnSelectCallback,
  OnDoubleClickCallback,
  OnItemClickHandlerParams,
} from './typings'

import { useCallback, useMemo } from 'react'

import { RenderItemWrapper } from './RenderItemWrapper'
import { getUpdatedSelection } from './core/getUpdatedSelection'
import { isRenderGroupHeader } from './core/isRenderGroupHeader'

type ItemsRendererProps<
  Category extends uui.domain.ui.list.Category,
  T = uui.domain.ui.list.EntityForListCategory[Category],
> = {
  selectedItemIds: workwave.DeepReadonly<string[]>
  onSelect: OnSelectCallback<Category, T>
  onDoubleClick?: OnDoubleClickCallback<Category, T>
  getItemId: (item: T) => string
  visibleItems: ItemWithPosition<T>[]
  itemsWithPosition: ItemWithPosition<T>[]
  RenderItem: React.ComponentType<RenderItemProps<Category, T>>
}

export function ItemsRenderer<
  Category extends uui.domain.ui.list.Category,
  T = uui.domain.ui.list.EntityForListCategory[Category],
>(props: ItemsRendererProps<Category, T>) {
  const {
    onSelect,
    getItemId,
    onDoubleClick,
    RenderItem,
    visibleItems,
    selectedItemIds,
    itemsWithPosition,
  } = props

  // The component updates the selection and the modifiers, but give its consumer all the necessary
  // data to manage the selection itself
  type HandleClick = (params: OnItemClickHandlerParams<Category, T>) => any
  const handleClick = useCallback<HandleClick>(
    params => {
      const { event: modifiers } = params
      const item = params.item
      const clickedItemId = isRenderGroupHeader(item) ? item.groupId : getItemId(item)

      onSelect({
        item,
        modifiers,
        newSelectedIds: getUpdatedSelection({
          getItemId,
          modifiers,
          clickedItemId,
          items: itemsWithPosition,
          prevSelectedIds: selectedItemIds,
        }),
      })
    },
    [getItemId, itemsWithPosition, onSelect, selectedItemIds],
  )

  const handleDoubleClick = useCallback(
    (item: T) => {
      onDoubleClick?.(item)
    },
    [onDoubleClick],
  )

  const children = useMemo(
    () =>
      visibleItems.map(({ item, y }) => {
        if (isRenderGroupHeader(item)) {
          return (
            <RenderItemWrapper key={item.groupId} style={{ top: y }}>
              <RenderItem type="renderGroupHeader" renderGroupHeader={item} />
            </RenderItemWrapper>
          )
        } else {
          const itemId = getItemId(item)
          return (
            <RenderItemWrapper key={itemId} style={{ top: y }}>
              <RenderItem
                type="item"
                item={item}
                onItemClick={handleClick}
                onItemDoubleClick={handleDoubleClick}
                selected={selectedItemIds.includes(itemId)}
              />
            </RenderItemWrapper>
          )
        }
      }),
    [visibleItems, RenderItem, handleClick, handleDoubleClick, selectedItemIds, getItemId],
  )

  return <>{children}</>
}
