import { useEffect } from 'react'

import {
  InspectPinMode,
  Map as MainMap,
  MapLayer,
  DrawArea,
  removeEditableArea,
  ConnectMapToList,
} from '@/map'

export function Map() {
  useEffect(
    () => () => {
      removeEditableArea()
    },
    [],
  )

  return (
    <MainMap selectionContext="crud" selectionCategory="regions" mapId="ww-map">
      <InspectPinMode mode="none" />

      <MapLayer uid="order" visible />
      <MapLayer uid="roadSegment" visible />
      <MapLayer uid="region" interactive visible />
      <MapLayer uid="geofence" visible={false} />
      <MapLayer uid="routePolyline" visible />
      <MapLayer uid="place" visible />

      <ConnectMapToList category="regions" />

      <DrawArea />

      {/* TODO: Add remaining layers once they are implemented */}
    </MainMap>
  )
}
