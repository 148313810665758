import { FormLayout } from '@/forms-legacy'

import { GpsDataViewToggler } from '../../components/GpsDataViewToggler'

import { GpsData } from './components/GpsData'
import { useGpsDataCollapsed } from './hooks/useGpsDataCollapsed'
import { canRenderGpsDataToggler } from './utils/canRenderGpsDataToggler'
import { useTexts } from './hooks/useTexts'
import { Name } from './fields/Name'
import { Color } from './fields/Color'
import { Icon } from './fields/Icon'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  values: uui.domain.ui.forms.FormWithDeviceData
}

export function Form(props: Props) {
  const { unifiedVehicle, values } = props

  const texts = useTexts()
  const { collapsed, toggleCollapsed } = useGpsDataCollapsed()

  const canRenderToggler = canRenderGpsDataToggler(unifiedVehicle, true)
  const deviceId = unifiedVehicle.isPhysicalDevice ? unifiedVehicle.device.deviceId : ''

  return (
    <FormLayout>
      <Name />
      <Color />
      <Icon />
      {canRenderToggler && (
        <GpsDataViewToggler
          title={texts.expanding}
          description={texts.deviceSettings(deviceId)}
          collapsed={collapsed}
          onToggle={toggleCollapsed}
        />
      )}
      {canRenderToggler && !collapsed && <GpsData values={values} />}
    </FormLayout>
  )
}
