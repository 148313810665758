import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useEditingStateWithCtx, useCrudSelection, resetCrudSelection, useIsLoading } from '@/atoms'

import { selectPlacesListStructure } from '@/features/domain/lists'

export const useDataForList = () => {
  const { setEditing } = useEditingStateWithCtx('place')
  const isLoading = useIsLoading('fetchGpsData')

  const structure = useSelector(selectPlacesListStructure)
  const [selection, setSelection] = useCrudSelection('places')

  const lastSelectedPlace = selection.length > 0 ? selection[selection.length - 1] : undefined

  const setCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  const deselectAll = () => resetCrudSelection('places')

  return {
    lastSelectedPlace,
    setSelection,
    deselectAll,
    setEditing,
    structure,
    setCreate,
    isLoading,
  }
}
