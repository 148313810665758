import { useMemo } from 'react'
import { useTexts } from './useTexts'

export function useRadioGroupValues() {
  const texts = useTexts()

  return useMemo(() => {
    return [
      {
        label: texts.enabled,
        value: 'enabled',
      },
      {
        label: texts.disabled,
        value: 'disabled',
      },
    ]
  }, [texts])
}
