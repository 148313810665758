import { useEffect } from 'react'
import { Box, Checkbox, FormControl, FormControlLabel, Stack, Typography } from '@mui/material'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useControllerActions } from '../../../../../hooks/useControllerActions'
import { useController } from '../../../../../hooks/useController'
import { useTexts } from '../../../../../hooks/useTexts'

interface Props {
  testId?: string
  trackId?: string
}

export function CustomizeColumns(props: Props) {
  const { testId = 'export-customize-columns-flag' } = props
  const trackId = props.trackId ?? testId

  const {
    data: { customizeColumns, singleFile, multiTerritory },
  } = useController()

  const { onChangeCustomizeColumns } = useControllerActions()
  const texts = useTexts()

  useEffect(() => {
    if (singleFile && multiTerritory) {
      onChangeCustomizeColumns(true)
    }
  }, [singleFile, multiTerritory, onChangeCustomizeColumns])

  const disabled = singleFile && multiTerritory

  return (
    <FormControl>
      <Stack data-testid={testId} data-trackid={trackId}>
        <Tooltip placement="bottom" title={texts.customizeColumnsDisabled} disabled={!disabled}>
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                onChange={(_, checked) => onChangeCustomizeColumns(checked)}
                checked={customizeColumns}
              />
            }
            label={texts.customizeColumns}
          />
        </Tooltip>

        <Box ml={4}>
          <Typography variant="caption">{texts.customizeColumnsDescription}</Typography>
        </Box>
      </Stack>
    </FormControl>
  )
}
