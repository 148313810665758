import { useCallback, useMemo } from 'react'
import { Divider, ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material'
import { Check } from '@mui/icons-material'

import { ListHeaderFilterMenuTitle, ListHeaderSorter } from '@/components/List'
import { SortAscending, SortDescending } from '@/icons'

import { useTexts } from '../../useTexts'
import { useSorter } from './hooks/useSorter'

export function Sorter() {
  const {
    canGroupItems,
    sortField,
    setSortField,
    direction,
    setDirection,
    groupField,
    setGroupField,
    noEntries,
  } = useSorter()
  const texts = useTexts()

  const triggerText = useMemo(
    () => `${texts.sortBy(sortField === 'name' ? texts.sortByName : texts.sortByLastActivity)}`,
    [texts, sortField],
  )

  const onSortByName = useCallback(() => {
    setSortField('name')
  }, [setSortField])

  const onSortByEta = useCallback(() => {
    setSortField('ui.sort.lastActivity')
  }, [setSortField])

  const onSortAscending = useCallback(() => {
    setDirection('asc')
  }, [setDirection])

  const onSortDescending = useCallback(() => {
    setDirection('desc')
  }, [setDirection])

  const onGroupByNone = useCallback(() => {
    setGroupField(undefined)
  }, [setGroupField])

  const onGroupByType = useCallback(() => {
    setGroupField('ui.group.rm360')
  }, [setGroupField])

  if (noEntries) return null

  return (
    <ListHeaderSorter triggerText={triggerText}>
      <MenuItem
        onClick={onSortByEta}
        data-testid="list-header-sorter-sortby-eta"
        data-trackid="list-header-sorter-sortby-eta"
      >
        {sortField === 'ui.sort.lastActivity' && (
          <ListItemIcon>
            <Check color="primary" />
          </ListItemIcon>
        )}
        <ListItemText
          inset={sortField !== 'ui.sort.lastActivity'}
          primary={texts.sortByLastActivity}
        />
      </MenuItem>

      <MenuItem
        onClick={onSortByName}
        data-testid="list-header-sorter-sortby-name"
        data-trackid="list-header-sorter-sortby-name"
      >
        {sortField === 'name' && (
          <ListItemIcon>
            <Check color="primary" />
          </ListItemIcon>
        )}
        <ListItemText inset={sortField !== 'name'} primary={texts.sortByName} />
      </MenuItem>

      <Divider />

      <MenuItem
        onClick={onSortAscending}
        data-testid="list-header-sorter-sortDirection-ascending"
        data-trackid="list-header-sorter-sortDirection-ascending"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          align-items="center"
          width="100%"
          gap={2}
        >
          <Stack direction="row">
            {direction === 'asc' && (
              <ListItemIcon>
                <Check color="primary" />
              </ListItemIcon>
            )}

            <ListItemText inset={direction !== 'asc'} primary={texts.sortOrderAscending} />
          </Stack>

          <Stack justifyContent="center" color="action.active">
            <SortAscending size={20} />
          </Stack>
        </Stack>
      </MenuItem>

      <MenuItem
        onClick={onSortDescending}
        data-testid="list-header-sorter-sortDirection-descending"
        data-trackid="list-header-sorter-sortDirection-descending"
      >
        <Stack
          justifyContent="space-between"
          align-items="center"
          direction="row"
          width="100%"
          gap={2}
        >
          <Stack direction="row" color="light">
            {direction === 'desc' && (
              <ListItemIcon>
                <Check color="primary" />
              </ListItemIcon>
            )}

            <ListItemText inset={direction !== 'desc'} primary={texts.sortOrderDescending} />
          </Stack>

          <Stack justifyContent="center" color="action.active">
            <SortDescending size={20} />
          </Stack>
        </Stack>
      </MenuItem>
      {canGroupItems && <Divider />}

      {canGroupItems && <ListHeaderFilterMenuTitle>{texts.groupBy}</ListHeaderFilterMenuTitle>}

      {canGroupItems && (
        <MenuItem
          onClick={onGroupByNone}
          data-testid="list-header-sorter-groupBy-none"
          data-trackid="list-header-sorter-groupBy-none"
        >
          <Stack
            justifyContent="space-between"
            align-items="center"
            direction="row"
            width="100%"
            gap={2}
          >
            <Stack direction="row" color="light">
              {!groupField && (
                <ListItemIcon>
                  <Check color="primary" />
                </ListItemIcon>
              )}

              <ListItemText inset={!!groupField} primary={texts.groupByNone} />
            </Stack>
          </Stack>
        </MenuItem>
      )}

      {canGroupItems && (
        <MenuItem
          onClick={onGroupByType}
          data-testid="list-header-sorter-groupBy-type"
          data-trackid="list-header-sorter-groupBy-type"
        >
          <Stack
            justifyContent="space-between"
            align-items="center"
            direction="row"
            width="100%"
            gap={2}
          >
            <Stack direction="row" color="light">
              {groupField === 'ui.group.rm360' && (
                <ListItemIcon>
                  <Check color="primary" />
                </ListItemIcon>
              )}

              <ListItemText inset={groupField !== 'ui.group.rm360'} primary={texts.groupByType} />
            </Stack>
          </Stack>
        </MenuItem>
      )}
    </ListHeaderSorter>
  )
}
