import type OlMap from 'ol/Map'
import type { PopupPlugin } from '../types'

import { createMapPopupPlugin } from '../plugins/createMapPopupPlugin'
import { createStyledPopupPlugin } from '../plugins/createStyledPopupPlugin'
import { createPopupLoggerPlugin } from '../plugins/createPopupLoggerPlugin'
import { createReactRootPopupPlugin } from '../plugins/createReactRootPopupPlugin'
import { createShowToastOnClosePlugin } from '../plugins/createShowToastOnClosePlugin'
import { createEmptyPopup } from './createEmptyPopup'

import { getMapScreenCoordinates } from './getMapScreenCoordinates'

type Params = {
  map: OlMap

  // The if of the container that must always be available in the main window.
  mainWindowTemporaryContainerId: string

  // A list of popup plugins that come *after* the createRouteManagerPopup ones.
  plugins: PopupPlugin[]

  // The popup id
  id?: string

  // The page title
  title?: string
}

/**
 * Create a new popup with all the necessary plugins to render a RouteManager portion and/or the map.
 */
export function createRouteManagerPopup(params: Params) {
  const {
    map,
    mainWindowTemporaryContainerId,
    plugins,

    // Optional params
    id = 'routeManagerPopup',
    title = 'RouteManager popup',
  } = params

  return createEmptyPopup({
    id,

    plugins: [
      createPopupLoggerPlugin(),
      createMapPopupPlugin({ mainWindowTemporaryContainerId, map }),
      createReactRootPopupPlugin(),
      createStyledPopupPlugin(),
      createShowToastOnClosePlugin(),
      ...plugins,
    ],

    windowConfig: () => ({
      title,
      windowFeatures: getMapScreenCoordinates(map),
    }),
  })
}
