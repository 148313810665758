import { useState, useCallback } from 'react'

import { Box } from '@mui/material'
import { Cross } from '@/icons'
import {
  InputStep,
  ItemRendererTitle,
  ClearSelectionIcon,
  TextButton,
  FlexRow,
  FlexColumn,
} from '@/forms-legacy'

import { ConfirmButton } from './components/ConfirmButton'

interface Props {
  title: string
  buttonText: string
  maxCopies: number
  onCancel: () => void
  onClone: (copies: number) => void
  vehicle: uui.domain.client.UnifiedVehicle
}

export function ClonePanel(props: Props) {
  const { title, onCancel, onClone, buttonText, maxCopies } = props

  const [copies, setCopies] = useState(1)
  const handleOnClone = useCallback(() => onClone(copies), [onClone, copies])

  return (
    <>
      <FlexRow height={60} justifyContent="space-between" style={{ padding: '0 30px' }}>
        <FlexColumn primary hideOverflow>
          <ItemRendererTitle>{title}</ItemRendererTitle>
          <Box width={100} paddingTop={1}>
            <InputStep
              name="cloneCopies"
              step={1}
              value={copies}
              onChange={setCopies}
              min={1}
              max={maxCopies}
            />
          </Box>
        </FlexColumn>
        <FlexColumn hideOverflow>
          <FlexRow>
            <TextButton onClick={onCancel}>
              <ClearSelectionIcon>
                <Cross />
              </ClearSelectionIcon>
            </TextButton>
            <ConfirmButton onClick={handleOnClone}>{buttonText}</ConfirmButton>
          </FlexRow>
        </FlexColumn>
      </FlexRow>
    </>
  )
}
