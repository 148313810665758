import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDevicesAggregateInfo } from '@/features/domain/device'

export function useBadges(deviceId: string) {
  const devicesAggregateInfo = useSelector(selectDevicesAggregateInfo)

  return useMemo(() => {
    const deviceInfo = devicesAggregateInfo[deviceId]

    if (!deviceInfo) {
      throw new Error('Cannot find device')
    }

    return deviceInfo.gpsTags ?? []
  }, [deviceId, devicesAggregateInfo])
}
