import { StyledComponent } from '@emotion/styled'
import { styled, Select, type SelectProps } from '@mui/material'

/**
 * ATTENTION: Until form-ui will fully support MUI theming this allow
 * us to change form fields without affecting all usages of MUI TextField
 */
export const FormUISelect: StyledComponent<SelectProps> = styled(Select, { name: 'FormUISelect' })({
  // The margin top has been added to prevent the label to going outside of the component area
  marginTop: 5,
})
