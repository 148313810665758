import type { FormProps } from '../types'

import { NotificationForm } from '../../../../../../notificationForms/sections/notifications/components/NotificationForm'
import { NotificationView } from '../../../../../../notificationForms/sections/notifications/components/NotificationView'

import { useFormStateController } from '../../hooks/useFormStateController'
import { NotCompletedNotificationView } from './components/NotCompletedNotificationView'

import { useNotCompletedNotificationSubmit } from './hooks/useNotCompletedNotificationSubmit'
import { useNotCompletedNotificationConfig } from './hooks/useNotCompletedNotificationConfig'
import { useNotCompletedNotificationValues } from './hooks/useNotCompletedNotificationValues'
import { useNotCompletedNotificationToggle } from './hooks/useNotCompletedNotificationToggle'
import { useTexts } from './useTexts'

const noop = () => undefined

/**
 * Renders the NotCompleted notification form providing all the domain data and apis.
 */
export function NotCompletedNotification(props: FormProps) {
  const { inEditing, setEditingState } = props

  const values = useNotCompletedNotificationValues()

  const { toggleDescription, onToggle } = useNotCompletedNotificationToggle(values)
  const { edit, cancel } = useFormStateController(setEditingState)
  const onSubmit = useNotCompletedNotificationSubmit()
  const config = useNotCompletedNotificationConfig()
  const texts = useTexts()

  // Render the Form inside a FormRoot component that will expose the FormState API through a React.Context
  if (inEditing) {
    return (
      <NotificationForm
        notification={'notCompleted'}
        subtitle={texts.description}
        onSubmit={onSubmit}
        title={texts.title}
        cancel={cancel}
        values={values}
        config={config}
        initHook={noop}
      />
    )
  }

  return (
    <NotificationView
      Form={<NotCompletedNotificationView values={values} />}
      toggleSubtitle={toggleDescription}
      notification={'notCompleted'}
      subtitle={texts.description}
      onToggle={onToggle}
      title={texts.title}
      values={values}
      onEdit={edit}
      available
    />
  )
}
