import type { TrackingAppFormProps as Props } from '../types'
import type { FormFields, FormErrors } from './formFields'
import { FormProvider } from '@workwave-tidal/tidal/form-ui/layout'
import { formFields } from './formFields'
import { useFormConfig } from './hooks/useFormConfig'
import { TrackingPageForm } from './TrackingPageForm'

export function TrackingPageFormProvider(props: Props) {
  const { formValues, ...rest } = props
  const formConfig = useFormConfig(formValues)

  return (
    <FormProvider<FormFields, FormErrors>
      fields={formFields}
      config={formConfig}
      name="trackingPageForm"
    >
      <TrackingPageForm {...rest} />
    </FormProvider>
  )
}
