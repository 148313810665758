import { type SchedulerEventModel } from '@bryntum/schedulerpro'
import { useMemo } from 'react'
import { differenceInSeconds } from 'date-fns/esm'
import { useTexts } from '../useTexts'

export function useTooltipData(eventRecord: SchedulerEventModel) {
  const texts = useTexts()

  return useMemo(() => {
    const startDate = eventRecord.startDate as Date
    const endDate = eventRecord.endDate as Date

    const name = eventRecord.getData('name') as string | undefined
    const label = eventRecord.getData('label') as string | undefined
    const status = eventRecord.getData('status') as
      | uui.domain.client.rm.OrderStepTrackingData['status']
      | undefined

    const serviceTime = eventRecord.getData('serviceTime') as number | undefined

    const approvedVersion = eventRecord.getData('approvedVersion') as
      | uui.domain.client.rm.SchedulerEvent['approvedVersion']
      | undefined

    // max 3 violations show in tooltip
    const rawViolations =
      (eventRecord.getData('violations') as uui.domain.rm.RouteStepViolationsType[] | undefined) ??
      []

    const violations =
      rawViolations.length > 0 ? rawViolations.slice(0, 3).map(v => texts.violation(v)) : undefined

    const arrival = eventRecord.getData('arrivalDate') as Date | undefined
    const approvedArrival = approvedVersion?.arrivalDate ?? undefined
    const approvedEnd = approvedVersion ? (approvedVersion.endDate as Date) : undefined

    const arrivalDelaySeconds =
      arrival && approvedArrival ? differenceInSeconds(arrival, approvedArrival) : 0

    const departureDelaySeconds = approvedEnd
      ? differenceInSeconds(endDate as Date, approvedEnd)
      : 0

    return {
      end: endDate,
      type: 'routeStep',
      name: name ?? '',
      label: label ?? '',
      start: startDate,
      status,
      violations,
      serviceTime,
      delay: approvedVersion
        ? {
            arrivalDelaySeconds,
            departureDelaySeconds,
          }
        : undefined,
    }
  }, [texts, eventRecord])
}
