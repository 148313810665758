import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function ContactCard(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 12">
        <path d="M14 3.33333V2.66667L12 4L10 2.66667V3.33333L12 4.66667L14 3.33333ZM14.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H14.6667C15.4 12 15.9933 11.4 15.9933 10.6667L16 1.33333C16 0.6 15.4 0 14.6667 0ZM5.33333 2C6.44 2 7.33333 2.89333 7.33333 4C7.33333 5.10667 6.44 6 5.33333 6C4.22667 6 3.33333 5.10667 3.33333 4C3.33333 2.89333 4.22667 2 5.33333 2ZM9.33333 10H1.33333V9.33333C1.33333 8 4 7.26667 5.33333 7.26667C6.66667 7.26667 9.33333 8 9.33333 9.33333V10ZM14.6667 6H9.33333V2H14.6667V6Z" />
      </svg>
    </IconContainer>
  )
}
