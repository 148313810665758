import type { OrdersGridAtom } from './ordersGrid'

function migrateFromNoVersionToV2(ordersGridAtom: OrdersGridAtom) {
  ordersGridAtom.settings.columns.push({ field: 'formattedData.stopNo', hide: true, width: 200 })
}

function migrateFromV2ToV3(ordersGridAtom: OrdersGridAtom) {
  ordersGridAtom.settings.columns.push({ field: 'formattedData.priority', hide: false, width: 200 })
  ordersGridAtom.settings.columns.push({
    field: 'formattedData.scheduleTime',
    hide: false,
    width: 200,
  })
  ordersGridAtom.settings.columns.push({ field: 'formattedData.pairId', hide: true, width: 200 })
}

function migrateFromV3ToV4(ordersGridAtom: OrdersGridAtom) {
  ordersGridAtom.settings.columns.push({
    field: 'formattedData.company',
    hide: false,
    width: 200,
  })
}

function migrateFromV4ToV5(mutableOrdersGridAtom: OrdersGridAtom) {
  mutableOrdersGridAtom.settings.columns.push({
    field: 'formattedData.plannedTime',
    hide: true,
    width: 200,
  })
  mutableOrdersGridAtom.settings.columns.push({
    field: 'formattedData.communicatedEtaRanges.twoDaysNotification',
    hide: true,
    width: 200,
  })
  mutableOrdersGridAtom.settings.columns.push({
    field: 'formattedData.communicatedEtaRanges.oneDayNotification',
    hide: true,
    width: 200,
  })
  mutableOrdersGridAtom.settings.columns.push({
    field: 'formattedData.communicatedEtaRanges.sameDayNotification',
    hide: true,
    width: 200,
  })

  mutableOrdersGridAtom.settings.columnsPanelSortBy = 'grid-order'
}

/**
 * This method updates (if required) the ordersGrid atom value stored in local storage
 * to the most current version.
 *
 * Please update it when the atom structure changes
 *
 * @param localStorageValue
 * @returns update
 */
export function updateAtomVersion(localStorageValue: OrdersGridAtom): OrdersGridAtom {
  switch (localStorageValue.version) {
    case undefined:
      // FROM no_version TO version 2
      migrateFromNoVersionToV2(localStorageValue)

      // FROM version 2 TO version 3
      migrateFromV2ToV3(localStorageValue)
      break

    case 2:
      // FROM version 2 TO version 3
      migrateFromV2ToV3(localStorageValue)
      break

    case 3:
      // FROM version 3 TO version 4
      migrateFromV3ToV4(localStorageValue)
      break

    case 4:
      // FROM version 4 TO version 5
      migrateFromV4ToV5(localStorageValue)
      break

    case 5:
      break
  }

  return localStorageValue
}
