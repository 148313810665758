import type { CSSProperties } from 'react'
import type { ValidColor } from '@/local/components'

import styled from 'styled-components'

import { theme } from '@/local/components'

type Props = {
  size?: number
  className?: string
  title?: string
  color?: ValidColor
  style?: CSSProperties
  as?: 'span' | 'div'
  animation?: 'spin'
  marginLeft?: number
  marginRight?: number
}

const IconContainer = styled.span<Props>`
  display: ${p => (p.as === 'div' ? 'block' : 'inline-block')};
  width: ${p => (p.size ? `${p.size}px` : '100%')};
  margin-left: ${p => p.marginLeft ?? 0};
  margin-right: ${p => p.marginRight ?? 0};

  animation: none;
`

export function UpIcon(props: Props) {
  const { color } = props

  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path
          style={{ fill: color ? theme.colors[color] : 'currentcolor' }}
          d="M24.96875 13c-.51953.0078-1.01563.21875-1.3828.58594l-20 20c-.52345.5-.7344 1.2461-.5508 1.94922.1836.69922.73048 1.2461 1.4297 1.42968.70312.1836 1.4492-.02734 1.9492-.55078L25 17.82813l18.58594 18.58593c.5.52344 1.2461.73438 1.94922.55078.69922-.1836 1.2461-.73047 1.42968-1.42968.1836-.70313-.02734-1.44922-.55078-1.94922l-20-20c-.3828-.38282-.90234-.59375-1.4453-.58594z"
        />
      </svg>
    </IconContainer>
  )
}
