import styled from 'styled-components'

type Props = {
  error?: boolean
}

const SecondaryColumnDescription = styled.div<Props>`
  color: ${p => (p.error ? p.theme.colors.$outrageousRed : p.theme.colors.$nightRider)};
  font-weight: ${p => p.theme.weights.$semiBold};
  font-size: ${p => p.theme.fonts.$s};
  line-height: 1.2;
  font-style: italic;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  pointer-events: none;
`

SecondaryColumnDescription.displayName = 'SecondaryColumnDescription'
export default SecondaryColumnDescription
