import type { SelectionAsRecord } from '@/components/Calendar/typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  parse,
  format,
  subDays,
  subYears,
  subMonths,
  addMonths,
  eachDayOfInterval,
} from 'date-fns/esm'

import { selectTerritoryLicensingLimits } from '@/features/domain/territory'
import { isGpsOnly, selectUserConfiguration } from '@/features/domain/user'
import { timezoneUtils } from '@/server-data'

export const useData = () => {
  const gpsOnly = useSelector(isGpsOnly)
  const userConfiguration = useSelector(selectUserConfiguration)

  const { horizonView } = useSelector(selectTerritoryLicensingLimits)

  return useMemo(() => {
    const refDate = new Date()
    const { startFromDate, planType, userType } = userConfiguration
    const isSimulation = planType === 'simulation'

    // The value for TODAY on Territory Calendar is the real value of current day calculated
    // like if the user is always in the timezone of the actual territory

    const todayDate = timezoneUtils.createDate(userConfiguration)(refDate)
    const todayAsString = format(todayDate, 'yyyyMMdd')

    const numberOfMonthsToShow: 1 | 2 = gpsOnly ? 1 : 2

    const firstNonArchivedDay = isSimulation ? undefined : parse(startFromDate, 'yyyyMMdd', refDate)
    const firstNonArchivedDayAsString = isSimulation ? undefined : startFromDate

    const minDate = isSimulation ? subYears(todayDate, 15) : subMonths(todayDate, 13)
    const maxDate = addMonths(todayDate, 24)

    const disabledDays =
      userType === 'courier' && firstNonArchivedDay
        ? eachDayOfInterval({
            start: minDate,
            end: subDays(firstNonArchivedDay, 1),
          }).reduce<SelectionAsRecord>((acc, day) => {
            acc[format(day, 'yyyyMMdd')] = 1

            return acc
          }, {})
        : undefined

    return {
      today: todayDate,
      todayAsString,
      gpsOnly,
      disabledDays,
      numberOfMonthsToShow,
      maxIntervalLength: horizonView - 1,
      minDate,
      maxDate,
      minDateAsString: format(minDate, 'yyyyMMdd'),
      maxDateAsString: format(maxDate, 'yyyyMMdd'),
      firstNonArchivedDay: firstNonArchivedDayAsString,
    }
  }, [userConfiguration, gpsOnly, horizonView])
}
