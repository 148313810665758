import { Stack } from '@mui/material'

import { TrafficDescription } from './fields/TrafficDescription'
import { DrivingTime } from './fields/DrivingTime'

export function Body() {
  return (
    <Stack gap={2} width="100%" mt={4} data-testid="edit-traffic-modal-body">
      <DrivingTime />
      <TrafficDescription />
    </Stack>
  )
}
