import { intl } from '@/intl'

export const getBackText = (): string => intl.translate({ id: 'global.back' })

export const getSimulationsListBackText = (): string =>
  intl.translate({ id: 'simulations.list.back' })

export const getFilterTerritoriesPlaceholder = (): string =>
  intl.translate({
    id: 'territories.filter.placeholder',
  })

export const getTerritoryBadgeText = (): string =>
  intl.translate({
    id: 'territories.badge',
  })

export const getTerritorySelectIndicatorText = (isViewOnly: boolean): string =>
  isViewOnly
    ? intl.translate({
        id: 'territories.open',
      })
    : intl.translate({
        id: 'territories.select',
      })

export const getFilteredTerritoriesCountText = (count: number): string =>
  intl.translate({
    id: 'territories.filter.count',
    values: {
      count,
    },
  })

export const getVehiclesCountText = (count: number): string =>
  intl.translate({
    id: 'global.counter.vehicles',
    values: {
      count,
    },
  })

export const getSimulationsCountText = (count: number): string =>
  intl.translate({
    id: 'global.counter.simulations',
    values: {
      count,
    },
  })

export const getSimulationOrdersCountText = (count: number): string =>
  intl.translate({
    id: 'global.counter.orders',
    values: {
      count,
    },
  })

export const getSimulationVehiclesCountText = (count: number): string =>
  intl.translate({
    id: 'global.counter.vehicles',
    values: {
      count,
    },
  })

// ----------------------------------------
// ----------------------------------------

export const getFilterSimulationsPlaceholder = (): string =>
  intl.translate({
    id: 'simulations.filter.placeholder',
  })

export const getFilteredSimulationsCountText = (count: number, selected: number): string =>
  intl.translate({
    id:
      selected > 0 ? 'setup.simulations.list.footerCountWithSelection' : 'simulations.filter.count',
    values: { count, selected },
  })

export const getSelectButtonText = (allSelected: boolean): string =>
  intl.translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' })

export const getSimulationsBadgeText = (): string =>
  intl.translate({
    id: 'simulations.badge',
  })

export const getOpenOperationsText = (): string =>
  intl.translate({
    id: 'simulations.openOperations',
  })

export const getSimulationCreationText = (date: string): string =>
  intl.translate({
    id: 'simulations.created',
    values: {
      date,
    },
  })

export const getSimulationUpdateText = (date: string): string =>
  intl.translate({
    id: 'simulations.updated',
    values: {
      date,
    },
  })

export const getNewSimulationsText = (): string =>
  intl.translate({
    id: 'simulations.new',
  })

export const getDuplicateSimulationText = (name: string): string =>
  intl.translate({
    id: 'simulations.duplicateName',
    values: {
      name,
    },
  })

export const getSimulationDetailsText = (): string =>
  intl.translate({
    id: 'simulations.readonly.title',
  })

export const getSimulationNameFieldText = (): string =>
  intl.translate({
    id: 'simulations.fields.name.label',
  })

export const getSimulationLabelFieldText = (): string =>
  intl.translate({
    id: 'simulations.fields.label.label',
  })

export const getSimulationsDeleteConfirmTitle = (count: number): string =>
  intl.translate({
    id: 'simulations.confirmPanel.title',
    values: {
      count,
    },
  })

export const getSimulationsDeleteConfirmDescription = (): string =>
  intl.translate({
    id: 'simulations.confirmPanel.description',
  })

export const getSimulationsDeleteConfirmButtonText = (): string =>
  intl.translate({
    id: 'simulations.confirmPanel.confirmText',
  })

export const getSimulationValidationEmptyName = (): string =>
  intl.translate({
    id: 'simulations.validation.name.missing',
  })

export const getSimulationValidationUniqueName = (): string =>
  intl.translate({
    id: 'simulations.validation.name.unique',
  })

export const getSimulationValidationNotesMaxLength = (): string =>
  intl.translate({
    id: 'simulations.validation.label.maxlength',
  })

export const getSimulationValidationDateNotSet = (): string =>
  intl.translate({
    id: 'simulations.validation.date.notset',
  })

export const getNewSimulationsDefaultName = (): string =>
  intl.translate({
    id: 'simulations.edit.name.new',
  })

export const getFormErrorsTitleText = (): string =>
  intl.translate({ id: 'simulations.edit.formErrorsTitle' })

export const getFormSaveText = (isCreating: boolean): string =>
  isCreating
    ? intl.translate({ id: 'simulations.edit.create' })
    : intl.translate({ id: 'simulations.edit.save' })

export const getCancelText = (): string => intl.translate({ id: 'simulations.edit.cancel' })

export const getFormTitleText = (): string => intl.translate({ id: 'simulations.edit.title' })

export const getFormCalendarTitleText = (): string =>
  intl.translate({ id: 'simulations.edit.calendar.title' })

export const getFormFooterMessageText = (): string =>
  intl.translate({ id: 'simulations.footer.message' })

export const getDeleteTerritoryText = (): string =>
  intl.translate({ id: 'territories.deleteTerritory' })

export const getSubmitButtonTitle = (
  valid: boolean,
  readonly: boolean,
  pristine: boolean,
): string => {
  if (!valid) {
    return intl.translate({
      id: 'territories.form.submitButton.disabled.title.invalid',
    })
  }

  if (readonly) {
    return intl.translate({
      id: 'territories.form.submitButton.disabled.title.planLocked',
    })
  }

  if (pristine) {
    return intl.translate({
      id: 'territories.form.submitButton.disabled.title.pristine',
    })
  }

  return ''
}

export const getYourSimulationsText = (): string =>
  intl.translate({ id: 'forms.simulations.yourSimulations' })

export const getOpenText = (): string => intl.translate({ id: 'global.open' })

export const getDuplicateText = (): string => intl.translate({ id: 'global.duplicate' })

export const getDeleteText = (): string => intl.translate({ id: 'global.delete' })

export const getEditText = (): string => intl.translate({ id: 'global.edit' })

export const getTransactionInProgressSubtitle = (): string =>
  intl.translate({ id: 'simulations.new.editingInProgress.subtitle' })
