import type { ReactChild } from 'react'

import { Box } from '@mui/material'

import { theme } from '@/styles'

interface Props {
  children: ReactChild
}

export function EditTimeWindowsHeader(props: Props) {
  return (
    <Box padding="4px 16px" width="100%" sx={{ background: theme.colors.$whiteSmoke }}>
      {props.children}
    </Box>
  )
}
