import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { isGpsOnly } from '@/features/domain/user'
import { useJournal } from '@/store'
import { gis } from '@/server-data'

export function useGetCurrentLocation() {
  const gpsOnly = useSelector(isGpsOnly)
  const journal = useJournal()

  const [center, setCenter] = useState<uui.domain.LatLng>()

  useEffect(() => {
    async function executor() {
      if (!('geolocation' in navigator)) return

      try {
        const currentLocation = await geCurrentLocation()
        setCenter(currentLocation)
      } catch (error) {
        if (!error) {
          journal.main('Retrieving Geolocation failed: unknown error', { info: { error } }, 'error')
        }

        const { code, message } = error

        // Error code 1 = PERMISSION_DENIED --> no need to trigger an error
        const severity = code === 1 ? 'warn' : 'error'

        journal.main(
          `Geocoding position error: [${code}] - ${message}`,
          { info: { error } },
          severity,
        )
      }
    }

    // If the user is a gps-only we require the access to the browser location
    if (gpsOnly) {
      executor()
    }
  }, [journal, gpsOnly])

  return center
}

// Brought directly from V2
const geCurrentLocation = (): Promise<uui.domain.LatLng> =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const {
          coords: { latitude, longitude },
        } = position
        resolve(gis.createLatLng(latitude, longitude))
      },
      error => reject(error),
    )
  })
