import { ModalFooterButton } from '@/components/Modal'

import { useControllerActions } from '../../../hooks/useControllerActions'
import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function ChooseRecipientFooter() {
  const texts = useTexts()
  const {
    close,
    data: { selectedId },
  } = useController()
  const { confirmRecipientChoice } = useControllerActions()

  return (
    <>
      <ModalFooterButton
        onClick={confirmRecipientChoice}
        disabled={!selectedId}
        variant="contained"
        testid="send-message-to-driver-modal-chosen-recipient-button"
      >
        {texts.okButtonTitle}
      </ModalFooterButton>

      <ModalFooterButton
        onClick={close}
        testid="send-message-to-driver-modal-chosen-recipient-cancel-button"
      >
        {texts.cancelButtonTitle}
      </ModalFooterButton>
    </>
  )
}
