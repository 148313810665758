import type { Props } from './typings'
import type { RadioGroupValues } from '@/forms-legacy'

import { useMemo, useCallback } from 'react'
import {
  Block,
  FieldMessage,
  Label,
  FlexRow,
  RestoreFieldButton,
  InputStep,
  HorizontalRadioGroup,
} from '@/forms-legacy'

const horizontalRadioGroupDefaultValue = 100

export function NumericRangeField(props: Props) {
  const {
    info,
    disableValue,
    defaultValue = 0,
    label,
    wrappedInputProps,
    stepLabel,
    renderText,
    testid,
    formProps: {
      input: { value, onChange },
      meta: { error, dirty, initial },
    },
  } = props

  const horizontalRadioGroupValues = useMemo<RadioGroupValues>(
    () => computeRadioGroupValues(renderText, disableValue),
    [renderText, disableValue],
  )

  const handleOnChangeRadioGroup = useCallback(
    (valueAsString: string) => {
      onChange(parseInt(valueAsString) === disableValue ? disableValue : defaultValue)
    },
    [disableValue, defaultValue, onChange],
  )

  const renderRadioGroup = typeof disableValue === 'number'
  const radioGroupValue = value === disableValue ? value : horizontalRadioGroupDefaultValue

  const renderInput = renderRadioGroup ? radioGroupValue !== disableValue : true

  return (
    <Block error={!!error} dirty={!!dirty} data-testid={testid} data-trackid={testid}>
      <FlexRow justifyContent="space-between">
        <Label htmlFor="field-horizontal-radio-group">
          {label}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
      </FlexRow>

      {renderRadioGroup && (
        <HorizontalRadioGroup
          values={horizontalRadioGroupValues}
          onChange={handleOnChangeRadioGroup}
          value={radioGroupValue}
          name="field-horizontal-radio-group"
        />
      )}

      {renderInput && (
        <>
          {stepLabel && <Label>{stepLabel}</Label>}
          <InputStep
            {...wrappedInputProps}
            onChange={onChange}
            value={value}
            name="field-input-step"
          />
        </>
      )}

      {!!error && <FieldMessage error>{error}</FieldMessage>}
      {!error && !!info && <FieldMessage>{info}</FieldMessage>}
    </Block>
  )
}

function computeRadioGroupValues(renderText: Props['renderText'], disableValue: number = -1) {
  return [
    {
      value: horizontalRadioGroupDefaultValue,
      label: renderText('enabled'),
    },
    {
      value: disableValue,
      label: renderText('disabled'),
    },
  ]
}
