import type { View } from '../types'

import { useCallback } from 'react'

import { cloneVehicles, deleteVehicles } from '@/features/domain/vehicle'
import { useNotification, useIsUnmounted } from '@/hooks'
import { useAppDispatch } from '@/store'

type SetView = (view: View) => void

export function useActions(unifiedVehicles: uui.domain.client.UnifiedVehicle[], setView: SetView) {
  const unmounted = useIsUnmounted()

  // const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const onDelete = useCallback(async () => {
    try {
      const vehicleIds = unifiedVehicles.map(uv => {
        if (!uv.hasRoutingLicense) {
          throw new Error('routing license missing')
        }
        return uv.vehicleId
      })

      const result = await dispatch(deleteVehicles(vehicleIds))

      if (!deleteVehicles.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      if (!unmounted) {
        setView('default')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [setView, dispatch, toast, unifiedVehicles, unmounted])

  const onClone = useCallback(async () => {
    try {
      const vehicleIds = unifiedVehicles.map(uv => {
        if (!uv.hasRoutingLicense) {
          throw new Error('routing license missing')
        }
        return uv.vehicleId
      })

      const result = await dispatch(cloneVehicles({ vehicleIds }))

      if (!cloneVehicles.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      if (!unmounted) {
        setView('default')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [setView, dispatch, toast, unifiedVehicles, unmounted])

  return {
    onDelete,
    onClone,
  }
}
