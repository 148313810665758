import { useSelector } from 'react-redux'
import { selectDevicesListStructure } from '@/features/domain/lists'
import { useEditingStateWithCtx } from '@/atoms'
import { useSearchList } from './useSearchList'

export function useFilter() {
  const listStructure = useSelector(selectDevicesListStructure)
  const { editing } = useEditingStateWithCtx('device')
  const { query, setQuery } = useSearchList()

  return {
    query,
    editing,
    setQuery,
    noEntries: listStructure.totalItems === 0,
  }
}
