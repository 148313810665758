import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    searchPlaceholder: translate({ id: 'setup.traffic.filterPlaceholder' }),
    editTimeWindows: translate({ id: 'setup.traffic.editTimeWindows' }),
    yourTrafficProfiles: translate({ id: 'setup.traffic.trafficProfile.list.yourTrafficProfiles' }),
    newTrafficProfile: translate({ id: 'setup.traffic.addTrafficProfile' }),
    noResults: {
      title: translate({ id: 'trafficProfiles.list.noResults.title' }),
      description: translate({ id: 'trafficProfiles.list.noResults.description' }),
    },
  }))

  return api
}
