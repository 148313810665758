import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { proxy, useSnapshot } from 'valtio'
import { selectDayRangeInfo } from '@/features/domain/scheduler'

type SchedulerDayRange = uui.domain.client.rm.SchedulerDayRangeInfo

type SchedulerDayRangeAtom = { schedulerDayRange: SchedulerDayRange }

function createInitialSchedulerDayRange(): SchedulerDayRange {
  return {
    dayLength: -1,
    dayRange: { startSec: -1, endSec: -1 },
    dayStartOffset: 0,
    emptyDay: false,
  }
}

const schedulerDayRangeAtom = proxy<SchedulerDayRangeAtom>({
  schedulerDayRange: createInitialSchedulerDayRange(),
})

export function resetSchedulerDayRange() {
  schedulerDayRangeAtom.schedulerDayRange = createInitialSchedulerDayRange()
}

type SetSchedulerDayRange = (prev: SchedulerDayRange) => SchedulerDayRange
type SetSchedulerDayRangeParam = SetSchedulerDayRange | Partial<SchedulerDayRange> | 'reset'

export function setSchedulerDayRange(valueOrFunc: SetSchedulerDayRangeParam) {
  if (valueOrFunc === 'reset') return resetSchedulerDayRange()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(
      schedulerDayRangeAtom.schedulerDayRange,
      valueOrFunc(schedulerDayRangeAtom.schedulerDayRange),
    )
  } else {
    // atomic update
    schedulerDayRangeAtom.schedulerDayRange = {
      ...schedulerDayRangeAtom.schedulerDayRange,
      ...valueOrFunc,
    }
  }

  return schedulerDayRangeAtom
}

// ------------------------------------
// React Hooks
// ------------------------------------

export function useSchedulerDayRange() {
  const state = useSnapshot(schedulerDayRangeAtom)

  return [state.schedulerDayRange, setSchedulerDayRange] as const
}

export function useSyncSchedulerDayRange() {
  const dayRange = useSelector(selectDayRangeInfo)

  useEffect(() => {
    const prevDayRange = schedulerDayRangeAtom.schedulerDayRange

    const noUpdate = JSON.stringify(prevDayRange) === JSON.stringify(dayRange)
    if (noUpdate) return

    setSchedulerDayRange(dayRange)
  }, [dayRange])
}
