import type { ActionPermissions } from '../types'

import { useMemo } from 'react'

import { computeVehicleAssignmentPermission } from './utils/computeVehicleAssignmentPermission'
import { computeSendMessagePermission } from './utils/computeSendMessagePermission'

export function usePermissions(): ActionPermissions {
  return useMemo(
    () => ({
      vehicleAssignment: computeVehicleAssignmentPermission(),
      sendMessage: computeSendMessagePermission(),
    }),
    [],
  )
}
