import { Outlet } from 'react-router'

import { ErrorPageFooter } from './ErrorPageFooter'
import { ErrorPageLayout } from './ErrorPageLayout'
import { ErrorImage } from './ErrorImage'

export function Error() {
  return (
    <ErrorPageLayout picture={<ErrorImage />} footer={<ErrorPageFooter />}>
      <Outlet />
    </ErrorPageLayout>
  )
}
