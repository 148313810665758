import { PureComponent } from 'react'
import {
  Field as FinalFormField,
  FieldRenderProps as FFieldRenderProps,
  FieldProps as FFieldProps,
} from 'react-final-form'

import { Block, Label, FieldMessage, RestoreFieldButton } from '@/forms-legacy'
import { isDeepEqual } from '@/server-data'

import { ColorPicker, Props as ColorPickerFieldProps } from './primitives/ColorPicker'

export type FieldProps = FFieldProps<string, any>
export type FieldRenderProps = FFieldRenderProps<string>

export interface Props extends FieldProps {
  name: string
  label: string
  info?: string
  half?: boolean
  wrappedInputProps: Omit<ColorPickerFieldProps, 'onChange' | 'value'>
  className?: string
  testid?: string
}

export class ColorPickerField extends PureComponent<Props> {
  private renderField = (formProps: FieldRenderProps) => {
    const { label, name, info, half = false, wrappedInputProps, className, testid } = this.props
    const {
      input: { value, onChange },
      meta: { error, dirty, initial },
    } = formProps

    return (
      <Block
        half={half}
        error={!!error}
        dirty={!!dirty}
        className={className}
        data-testid={testid}
        data-trackid={testid}
      >
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <ColorPicker {...wrappedInputProps} onChange={onChange} value={value} />
        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }

  render() {
    const { name, ...props } = this.props
    return <FinalFormField {...props} isEqual={isDeepEqual} name={name} render={this.renderField} />
  }
}
