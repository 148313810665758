import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, resetCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { fitMapToSelection } from '@/map'
import { selectRegionsListStructure } from '@/features/domain/lists'

export function useTitle() {
  const listStructure = useSelector(selectRegionsListStructure)
  const { editing } = useEditingStateWithCtx('region')

  const [selection, select] = useCrudSelection('regions')

  const onSelectAll = useCallback(() => {
    const allSelected = listStructure.shownItems <= selection.length

    if (allSelected) {
      resetCrudSelection('regions')
    } else {
      select(
        listStructure.list.reduce<string[]>((acc, item) => {
          if (typeof item === 'string') {
            acc.push(item)
          }
          return acc
        }, []),
      )

      fitMapToSelection({ preventIfVisible: true }, 'add')
    }
  }, [select, selection, listStructure])

  return {
    editing,
    selection,
    onSelectAll,
    listStructure,
    noEntries: listStructure.totalItems === 0,
  }
}
