import type { Decorator } from 'final-form'

import { useRef } from 'react'
import { useSelector } from 'react-redux'
import createDecorator from 'final-form-calculate'

import { selectTelematicsTenantSourcesAsArray } from '@/features/domain/account'
import { selectDevicesAggregateInfo } from '@/features/domain/device'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectGpsTags } from '@/features/domain/tags'

import { getDeviceId } from '../../../../../../utils/getDeviceId'

import { useAvailableDevices } from '../useAvailableDevices'

import { createTelematicsDecorators } from './telematicsDecorators'
import { createWwgpsDecorators } from './wwgpsDecorators'

export function useDecorators(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  trackingProvider: uui.domain.server.gps.TrackingProvider,
  initialValues:
    | uui.domain.ui.forms.Rm360VehicleFormValues
    | uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics,
  updateInitialValues: (values: uui.domain.ui.forms.Rm360VehicleFormValues) => void,
) {
  const devicesAggregateInfo = useSelector(selectDevicesAggregateInfo)
  const availableDevices = useAvailableDevices()
  const tenantSources = useSelector(selectTelematicsTenantSourcesAsArray)
  const userConfig = useSelector(selectUserConfiguration)
  const allGpsTags = useSelector(selectGpsTags)

  const metric = userConfig.distanceFormat === 'METRIC'

  const devId = getDeviceId(unifiedVehicle)
  const rDeviceId = useRef(devId ? parseInt(devId) : undefined)

  const decorators =
    trackingProvider === 'WWGPS'
      ? createWwgpsDecorators(
          availableDevices,
          metric,
          rDeviceId,
          devicesAggregateInfo,
          allGpsTags,
          userConfig,
          updateInitialValues,
          initialValues as uui.domain.ui.forms.Rm360VehicleFormValues,
        )
      : createTelematicsDecorators(tenantSources)

  const createDecorators = createDecorator(...decorators) as Decorator<
    uui.domain.ui.forms.Rm360VehicleFormValues,
    uui.domain.ui.forms.Rm360VehicleFormValues
  > // as Decorator<> used because createDecorator function do not accept a generic

  // useRef is used here since in the v2 this computation is done inside the constructor function
  return useRef([createDecorators]).current
}
