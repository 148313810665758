import { styled } from '@mui/material'
import { setAlpha, theme } from '@/styles'

export const HoverArea = styled('div', {
  name: 'HoverArea',
})(() => ({
  borderRadius: 3,
  alignItems: 'center',
  position: 'relative',
  padding: '11px 13px',
  display: 'flex',

  '.secondaryButton': {
    position: 'absolute',
    display: 'none',
    right: 5,
    top: 5,
  },

  '.active &': {
    background: setAlpha(theme.colors.$dodgerBlue, 0.05),
    '&:hover': {
      background: setAlpha(theme.colors.$dodgerBlue, 0.05),
    },
  },

  '&:hover': {
    backgroundColor: theme.colors.$whiteSmoke,
  },

  '&:hover .secondaryButton': {
    display: 'block',
  },
}))
