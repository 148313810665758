import type { ChangeEvent } from 'react'
import type { IntervalSelection, OnChangeIntervalSelection } from '@/components/Calendar/typings'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns/esm'

import { importAllOrdersFromSimulation } from '@/features/domain/simulation'
import { exportArchivedToOperations } from '@/features/domain/order'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectCalendarRange } from '@/features/domain/ui'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useController } from './useController'

export function useControllerActions() {
  const { minDate: date } = useSelector(selectCalendarRange)
  const userConfiguration = useSelector(selectUserConfiguration)
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const {
    data: { mode, selectionStart, intervalLength },
    updateData,
    update,
    close,
  } = useController()

  const onModeChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, mode: 'replace' | 'add') => {
      updateData({ mode })
    },
    [updateData],
  )

  const onSelectionChange = useCallback<OnChangeIntervalSelection>(
    (_newSelection: Date | null, interval: IntervalSelection) => {
      updateData({ selectionStart: interval[0], selectionEnd: interval[1] })
    },
    [updateData],
  )

  const onConfirm = useCallback(async () => {
    try {
      update({ status: 'submitting' })

      // Since the actions require moving to Operations we need to close the modal,
      // otherwise the modal will disappear and appear again
      close?.()

      // Simulation flow
      if (userConfiguration.planType === 'simulation') {
        const result = await dispatch(
          importAllOrdersFromSimulation({
            opsStartDate: format(selectionStart, 'yyyyMMdd'),
            simulationId: userConfiguration.planId,
            lengthDays: intervalLength,
            simStartDate: date,
            mode,
          }),
        )

        if (!importAllOrdersFromSimulation.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      }

      // Archived day flow
      else {
        const result = await dispatch(
          exportArchivedToOperations({
            opsStartDate: format(selectionStart, 'yyyyMMdd'),
            date,
            mode,
          }),
        )

        if (!exportArchivedToOperations.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [
    userConfiguration,
    selectionStart,
    intervalLength,
    dispatch,
    update,
    close,
    toast,
    date,
    mode,
  ])

  return { onModeChange, onSelectionChange, onConfirm }
}
