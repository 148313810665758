import { useCallback } from 'react'

import { Cross } from '@/icons'

import { useNavigoCategorySelection } from '../hooks/useNavigoCategorySelection'
import { CircleIconButton } from './CircleIconButton'

interface Props {
  onClick?: () => void
}

export function CloseButton(props: Props) {
  const { onClick } = props

  const { resetSelection } = useNavigoCategorySelection()
  const handleClose = useCallback(() => resetSelection(), [resetSelection])

  return (
    <CircleIconButton
      Icon={<Cross size={10} color="$pureWhite" />}
      data-trackid="navigo-closeButton"
      data-testid="navigo-closeButton"
      onClick={onClick || handleClose}
    />
  )
}
