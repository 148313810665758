import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    english: translate({ id: 'settings.locale.values.english' }),
    german: translate({ id: 'settings.locale.values.german' }),
    spanish: translate({ id: 'settings.locale.values.spanish' }),
    italian: translate({ id: 'settings.locale.values.italian' }),
    dutch: translate({ id: 'settings.locale.values.dutch' }),
    french: translate({ id: 'settings.locale.values.french' }),
    portuguese: translate({ id: 'settings.locale.values.portuguese' }),
  }))

  return api
}
