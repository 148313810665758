import type { FormErrors, FormFields } from './formFields'

import { FormProvider } from '@workwave-tidal/tidal/form-ui/layout/FormProvider'
import { useDriverAssistanceData } from '../../hooks/useDriverAssistanceData'
import { formFields } from './formFields'
import { useCreateFormConfig } from './hooks/useCreateFormConfig'
import { DriverAssistanceForm } from './DriverAssistanceForm'

interface Props {
  onDiscard: () => void
}

export function DriverAssistanceFormLayout(props: Props) {
  const { onDiscard } = props

  // Get reasons for orders from the domain state and the update method
  const [driverAssistance, updateDriverAssistance] = useDriverAssistanceData()
  const createFormConfig = useCreateFormConfig(driverAssistance)

  return (
    <FormProvider<FormFields, FormErrors>
      name="assistance-form"
      fields={formFields}
      verbose={false}
      config={createFormConfig}
    >
      <DriverAssistanceForm onDiscard={onDiscard} updateDriverAssistance={updateDriverAssistance} />
    </FormProvider>
  )
}
