import type { DriverChip } from '../../../components/Navigator/typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDrivers } from '@/features/domain/driver'

import { useNavigator as useCoreNavigator } from '../../../components/Navigator/hooks/useNavigator'

export function useDriversNavigator() {
  const {
    ids,
    pagination: { page, setPage },
  } = useCoreNavigator()
  const drivers = useSelector(selectDrivers)

  const [selectedIds, chips] = useMemo(() => {
    const selectedDrivers = ids.map(id => drivers[id]).filter(Boolean)

    const chips: DriverChip[] = selectedDrivers.map(driver => ({
      id: driver.id,
      source: driver,
      type: 'driver',
    }))

    return [selectedDrivers, chips] as const
  }, [drivers, ids])

  const currentDrivers = page === -1 || !selectedIds?.[page] ? selectedIds : [selectedIds[page]]
  const currentDriverIds = page === -1 || !ids?.[page] ? ids : [ids[page]]

  return {
    page,
    chips,
    setPage,
    currentDrivers,
    currentDriverIds,
  } as const
}
