import { useMemo } from 'react'

import { LoadsBlock } from './LoadsBlock'

interface Props {
  loads: Record<string, number>
  showDynamicLoads: boolean
  trackingData?: uui.domain.client.rm.OrderStepTrackingData
  loadsMax?: number
}

export function LoadsBlockProvider(props: Props) {
  const { loads, trackingData, showDynamicLoads, loadsMax = 10 } = props

  const keys = Object.keys(loads)
  const gotMore = keys.length > loadsMax

  const firstLoads = useMemo(
    () =>
      keys.slice(0, loadsMax).reduce((acc, key) => {
        acc[key] = loads[key]
        return acc
      }, {}),
    [keys, loads, loadsMax],
  )

  return (
    <LoadsBlock
      loads={firstLoads}
      gotMore={gotMore}
      trackingData={trackingData}
      showDynamicLoads={showDynamicLoads}
    />
  )
}
