import type { EntitiesToShow } from '../types'

import { useEffect, useState } from 'react'
import { useMap } from '@/map'

function getEntitiesToShowByZoom(zoom?: number) {
  if (!zoom) return 'stops'

  return zoom >= 14 ? 'all' : 'stops'
}

/**
 * Monitor the zoom level on the map and returns which entities to render on the Navigo (all or just stops)
 */
export function useEntitiesToShowByZoom() {
  const [{ map }] = useMap()
  const [entitiesToShow, setEntitiesToShow] = useState<EntitiesToShow>(
    getEntitiesToShowByZoom(map.getView().getZoom()),
  )

  useEffect(() => {
    const updateEntitiesToShow = () =>
      setEntitiesToShow(getEntitiesToShowByZoom(map.getView().getZoom()))

    map.addEventListener('moveend', updateEntitiesToShow)

    return () => {
      map.removeEventListener('on', updateEntitiesToShow)
    }
  }, [map])

  return entitiesToShow
}
