import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useMainSelection } from '@/atoms'
import { selectOrderSteps } from '@/features/domain/order'

export function useSelectedOrders() {
  const [selection] = useMainSelection('orderSteps')
  const orders = useSelector(selectOrderSteps)
  const selectedOrders = useMemo(() => getSelectedOrders(selection, orders), [selection, orders])

  return { selectedOrders }
}

const getSelectedOrders = (
  selection: workwave.DeepReadonly<string[]>,
  ordersMap: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
): uui.domain.client.rm.ExtendedOrderStep[] => {
  // selection could hold some ids that are not in the ordersMap due to server-data computation
  // ordersMap holds the "truth"
  return selection.reduce<uui.domain.client.rm.ExtendedOrderStep[]>((acc, id) => {
    if (ordersMap[id]) {
      acc.push(ordersMap[id])
    }
    return acc
  }, [])
}
