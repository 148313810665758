import type {
  GridSortItem,
  GridSortModel,
  GridCallbackDetails,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { produce } from 'immer'

import { isDeepEqual } from '@/server-data'
import { fitMapToSelection } from '@/map'
import { useNotification, useReadOnly, useIsUnmounted } from '@/hooks'
import { useAppDispatch } from '@/store'
import { useNavigate } from '@/routing'
import {
  selectOrdersGridListOptions,
  resetListOptions,
  setListOptions,
} from '@/features/domain/lists'
import {
  useEditingStateWithCtx,
  setAllMainSelection,
  setMainSelection,
  useOrdersGrid,
} from '@/atoms'

export function useOrdersGridActions(sortModel: GridSortItem[]) {
  const listOptions = useSelector(selectOrdersGridListOptions)
  const { setEditing } = useEditingStateWithCtx('order')
  const [, setOrdersGrid] = useOrdersGrid()
  const { readonly } = useReadOnly()
  const navigate = useNavigate(true)
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const isUnmounted = useIsUnmounted()

  /**
   * Updates list options once a change in sorting order has been required
   */
  const onSortModelChange = useCallback(
    async (model: GridSortModel) => {
      try {
        if (isDeepEqual(model, sortModel)) return

        const sortReset = model.length === 0

        // If is a reset just ask for a listOptions reset and get out
        if (sortReset) {
          const result = await dispatch(
            resetListOptions({ category: 'ordersGrid', options: ['sort'] }),
          )

          if (isUnmounted()) return

          if (!resetListOptions.fulfilled.match(result)) {
            throw new Error(result.payload?.message ?? 'Internal error')
          }

          return
        }

        // Else produce a sort options array and call a setListOptions
        const result = await dispatch(
          setListOptions({
            category: 'ordersGrid',
            options: produce(listOptions, draft => {
              draft.sort = model.map(m => ({ direction: m.sort || 'asc', field: m.field }))
            }),
          }),
        )

        if (isUnmounted()) return

        if (!setListOptions.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e)
      }
    },
    [dispatch, toast, sortModel, listOptions, isUnmounted],
  )

  /**
   * Syncs orders grid selection with the main selection
   */
  const onSelectionModelChange = useCallback((orderStepIds: string[]) => {
    setAllMainSelection({ orderSteps: orderStepIds })
    fitMapToSelection({ preventIfVisible: true })
  }, [])

  /**
   * Brings the user to the order form (in edit mode) on double click
   */
  const onRowDoubleClick = useCallback(
    (orderStepId: string) => {
      if (readonly) return

      // Set the new main selection
      setMainSelection('orderSteps', [orderStepId])

      // Set the order form to editing mode
      setEditing([orderStepId])

      // Navigate to the correct route
      navigate('orders')
    },
    [readonly, setEditing, navigate],
  )

  /**
   * Stores in ordersGrid atom all column's visibility changes
   */
  const onColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel, _details: GridCallbackDetails) => {
      setOrdersGrid(draft => {
        for (const field in model) {
          const column = draft.columns.find(col => col.field === field)

          if (column) {
            column.hide = !model[field]
          } else {
            draft.columns.push({ field, hide: !model[field], width: 200 })
          }
        }

        return draft
      })
    },
    [setOrdersGrid],
  )

  /**
   * Stores in ordersGrid atom all column's visibility changes
   */
  const onColumnWidthChange = useCallback(
    (field: string, width: number) => {
      setOrdersGrid(draft => {
        const column = draft.columns.find(col => col.field === field)

        if (column) {
          column.width = width
        }

        return draft
      })
    },
    [setOrdersGrid],
  )

  /**
   * Stores in ordersGrid atom all column's order changes
   */
  const onColumnOrderChange = useCallback(
    (oldIndex: number, targetIndex: number) =>
      setOrdersGrid(draft => {
        // Move the column to the new position
        draft.columns.splice(targetIndex, 0, draft.columns.splice(oldIndex, 1)[0])

        return draft
      }),
    [setOrdersGrid],
  )

  return {
    onColumnVisibilityModelChange,
    onSelectionModelChange,
    onColumnOrderChange,
    onColumnWidthChange,
    onSortModelChange,
    onRowDoubleClick,
  }
}
