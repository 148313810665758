import type { FormApi } from 'final-form'
import type { Props, ExecutionEventsFormValues } from './typings'

import { useCallback, useRef } from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'
import createDecorator from 'final-form-calculate'

import { Footer, FormContent, SecondaryColumnHeader } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { gis } from '@/server-data'

import { OrderAvatar } from '../../../components/OrderAvatar'
import * as OrderTexts from '../../../intl'
import { Tabs } from '../../../components/Tabs'

import { getFormInitialValues, getExecutionEventsChanges } from './utils'
import { validateForm, getFieldsWithError } from './formValidation'
import { Form } from './Form'

const noop = () => undefined

function createStatusDecorator(statusReasons: string[]) {
  return createDecorator({
    field: 'status',
    isEqual: (
      status: uui.domain.client.rm.OrderStepTrackingData['status'] | null | undefined,
      prevStatus: uui.domain.client.rm.OrderStepTrackingData['status'] | null | undefined,
    ) => {
      if (!prevStatus || !status) {
        return true
      }

      return prevStatus === status
    },
    updates: {
      ['statusReason']: (
        status: uui.domain.client.rm.OrderStepTrackingData['status'] | null | undefined,
        formValues: ExecutionEventsFormValues,
      ): string => {
        const { statusReason = '' } = formValues

        if (status === 'reschedule') {
          return statusReasons[0] || ''
        }

        return statusReason
      },
    },
  })
}

export function EditExecutionEvents(props: Props) {
  const {
    qa,
    tab,
    order,
    onCancel,
    territory,
    planLocked,
    submitting,
    workingDayStartSec,
    updateExecutionEvent,
    updateExecutionEventPodMarkers,
  } = props

  const { id: extOrderId } = order
  const initialValues = useRef(getFormInitialValues(order))
  const decorators = useRef([createStatusDecorator(territory.statusReasons)])

  const handleOnSubmit = useCallback(
    (
      values: ExecutionEventsFormValues,
      formApi: FormApi<Record<string, any>, Record<string, any>>,
    ) => {
      const { dirtyFields, pristine } = formApi.getState()

      const changes = getExecutionEventsChanges(
        extOrderId,
        values,
        initialValues.current,
        Object.keys(dirtyFields),
        workingDayStartSec,
      )

      updateExecutionEvent(changes, pristine)
    },
    [updateExecutionEvent, workingDayStartSec, extOrderId],
  )

  return (
    <FinalForm
      initialValues={initialValues.current}
      validate={validateForm(workingDayStartSec)}
      onSubmit={handleOnSubmit}
      decorators={decorators.current}
      render={(formRenderProps: FormRenderProps) => {
        const { handleSubmit, form } = formRenderProps
        const { valid, pristine, errors = {} } = form.getState()

        const saveDisabled = !valid || planLocked
        const fieldsWithError = getFieldsWithError(errors)

        return (
          <FormColumn width={484}>
            <SecondaryColumnHeader
              title={order?.order.name || ''}
              description={
                !order ? '' : order.orderStep.atDepot ? '' : order.orderStep.location.address || ''
              }
              avatar={
                order ? (
                  <OrderAvatar extendedOrderStep={order} style={{ marginRight: 16 }} size={42} />
                ) : null
              }
              errors={{
                description:
                  !order || (!order.atDepot && gis.isNullIslandLatLng(order.location.latLng)),
              }}
            >
              <Tabs disabled setTab={noop} tab={tab} />
            </SecondaryColumnHeader>
            <FormContent
              qa={qa}
              testId="orderEditExecutionEvents"
              footer={
                <Footer
                  errorTitle={OrderTexts.getFormErrorsTitleText()}
                  errors={fieldsWithError}
                  submitting={submitting}
                  primary={{
                    text: OrderTexts.getFormSaveText(),
                    title: OrderTexts.getSubmitButtonTitle(planLocked, valid, pristine),
                    disabled: saveDisabled,
                    onClick: handleSubmit,
                  }}
                  secondary={{
                    text: OrderTexts.getCancelText(),
                    disabled: submitting,
                    onClick: onCancel,
                  }}
                />
              }
            >
              <Form
                {...props}
                initialValues={initialValues.current}
                formRenderProps={formRenderProps}
                onTogglePod={updateExecutionEventPodMarkers}
              />
            </FormContent>
          </FormColumn>
        )
      }}
    />
  )
}
