import { useCallback } from 'react'

import { Box, Button, useTheme } from '@mui/material'

import { useTexts } from '../../hooks/useTexts'

interface Props {
  onEdit: () => void
  previewUrl: string
}

export function Actions(props: Props) {
  const { onEdit, previewUrl } = props

  const onPreview = useCallback(() => {
    globalThis.open(previewUrl, '_blank')
  }, [previewUrl])

  const texts = useTexts()
  const theme = useTheme()

  return (
    <Box display="flex" paddingBottom={4} gap={theme.spacing(2)}>
      <Button
        onClick={onEdit}
        variant="text"
        color="primary"
        size="small"
        data-testid="edit-button"
        data-trackid="edit-button"
        className="pendo-notification-trackingApp-edit-button"
      >
        {texts.edit}
      </Button>
      <Button
        onClick={onPreview}
        type="button"
        variant="text"
        color="primary"
        size="medium"
        data-testid="preview-button"
        data-trackid="preview-button"
        className="pendo-trackingApp-form-preview-button"
      >
        {texts.preview}
      </Button>
    </Box>
  )
}
