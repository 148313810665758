import { NotificationEditorField } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'
import { useWrappedInputProps } from './hooks/useWrappedInputProps'

interface Props {
  defaultEmailAddress?: string
}

export function NotificationAddresses(props: Props) {
  const { defaultEmailAddress } = props
  const texts = useTexts()
  const wrappedInputProps = useWrappedInputProps(defaultEmailAddress)

  return (
    <NotificationEditorField
      name="notificationAddresses"
      label={texts.label}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
