import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    description: translate({ id: 'setup.routing.view.bulk.subTitle' }),
    exportKML: translate({ id: 'global.exportKML' }),
    delete: translate({ id: 'global.delete' }),
    segments: translate({ id: 'global.segments' }),
  }))

  return api
}
