import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import Polygon from 'ol/geom/Polygon'

import { setRegionFeatureMetadata } from '../regionFeatureMetadata'
import { getRegionFeatureStyle } from './getRegionFeatureStyle'

/**
 * Update a Region feature style and position on the map
 */
export function updateRegionFeature(
  mapStyles: uui.domain.ui.map.markers.MapStyles['region'],
  marker: uui.domain.ui.map.markers.Area,
  feature: Feature<Geometry>,
  selected: boolean = false,
): Feature<Geometry> {
  // ------------------------
  // Feature Geometry
  // ------------------------

  feature.setGeometry(new Polygon([marker.points]))

  // ------------------------
  // Feature Metadata
  // ------------------------

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['region']['mode'] = marker.mode ?? mapStyles.mode
  setRegionFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getRegionFeatureStyle(marker, mode, selected))

  return feature
}
