import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useCrudSelection } from '@/atoms'
import { selectUsers } from '@/features/domain/user'

type Result = {
  selection: string[]
  selectedUsers: uui.domain.client.rm.RouteManagerUser[]
}

export function useSelectedUsers() {
  const users = useSelector(selectUsers)
  const [selection] = useCrudSelection('users')

  return useMemo(() => getSelectedUsers(selection, users), [selection, users])
}

/**
 * The selection could contain unexisting ids.
 */
function getSelectedUsers(
  selection: workwave.DeepReadonly<string[]>,
  users: Record<string, uui.domain.client.rm.RouteManagerUser>,
): Result {
  return selection.reduce<Result>(
    (acc, id) => {
      if (users[id]) {
        acc.selection.push(id)
        acc.selectedUsers.push(users[id])
      }

      return acc
    },
    {
      selection: [],
      selectedUsers: [],
    },
  )
}
