import styled from 'styled-components'
import { InputFieldText } from '@/forms-legacy'

const CustomFieldsField = styled(InputFieldText).attrs({
  className: 'o-custom-fields-field',
})`
  flex: 0 1 auto;
  width: 100%;

  border-width: 0;
  color: ${p => p.theme.colors.$nightRider};
  background: ${p => p.theme.colors.$pureWhite};

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 24px;
`
CustomFieldsField.displayName = 'CustomFieldsField'
export default CustomFieldsField
