import type { FormErrors, FormFields } from '../formFields'
import { Checkbox } from '@workwave-tidal/tidal/form-ui'
import { useTexts } from '../hooks/useTexts'

export function StatusField() {
  const texts = useTexts()

  return (
    <Checkbox<'includeStatusChecked', FormFields, FormErrors>
      name="includeStatusChecked"
      label={texts.status}
      testId="driver-assistance-form-status-field"
      disabled={true}
      validateOn="blur change"
    />
  )
}
