import { useCallback, useState } from 'react'

import { setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'showPlacePaths' | 'hidePlacePaths'
export const usePlaceActions = (ids: string[]) => {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const showPlacePaths = useCallback(async () => {
    setMethodInExecution('showPlacePaths')
    await dispatch(
      setCustomMapStyle({
        customStyle: { type: 'place', mode: 'on', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  const hidePlacePaths = useCallback(async () => {
    setMethodInExecution('hidePlacePaths')
    await dispatch(
      setCustomMapStyle({
        customStyle: { type: 'place', mode: 'off', ids },
      }),
    )

    setMethodInExecution(null)
  }, [dispatch, ids])

  return { methodInExecution, actions: { showPlacePaths, hidePlacePaths } }
}
