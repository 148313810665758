import { useEffect, useState } from 'react'

//import { modalUtils } from '@/utils'
import {
  useConfigureModalController,
  useTransactionState,
  useModalController,
  useCloseModal,
} from '@/atoms'

import { useControllerData } from './useControllerData'

type ModalState = 'ready' | 'submitting'
interface ModalData {
  planType: 'none' | 'operations' | 'archived' | 'simulation'
  daysWithOrders: Record<string, number>
  disabledDays: Record<string, number>
  firstNonArchivedDay: string
  mode: 'replace' | 'add'
  lastSelectableDate: Date
  intervalLength: number
  orderStepIds: string[]
  selection: Date
  minDate: string
  maxDate: string
  dates: string[]
  today: string
}

export const modalId = 'copySelectedOrders'

export const useController = () => useModalController<ModalData, ModalState>(modalId)

export const useConfigureController = (orderStepIds: string[]) => {
  const transaction = useTransactionState()
  const close = useCloseModal()

  const {
    startFromDate: firstNonArchivedDay,
    minDateAsString: minDate,
    maxDateAsString: maxDate,
    lastSelectableDate,
    intervalLength,
    daysWithOrders,
    disabledDays,
    selection,
    planType,
    today,
  } = useControllerData()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        mode: 'add' as const,
        firstNonArchivedDay,
        lastSelectableDate,
        intervalLength,
        daysWithOrders,
        orderStepIds,
        disabledDays,
        selection,
        dates: [],
        planType,
        minDate,
        maxDate,
        today,
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const { status, update } = ctrl

  // EFFECTS

  // External transaction
  useEffect(() => {
    //modalUtils.disableOnExternalTransaction(transaction, status, update)
  }, [transaction, status, update])

  return ctrl
}
