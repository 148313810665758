import { Field as FinalFormField, FieldRenderProps } from 'react-final-form'

import DateTimeSelectorField from './DateTimeSelectorField'
import { PublicProps as Props } from './typings'

function DateTimeSelectorFieldWrapper(props: Props) {
  const { name, ...rest } = props
  return (
    <FinalFormField
      {...rest}
      name={name}
      render={(formProps: FieldRenderProps<any>) => {
        return <DateTimeSelectorField {...props} formProps={formProps} />
      }}
    />
  )
}

export default DateTimeSelectorFieldWrapper
