import { useViewType } from './hooks/useViewType'

import { SingleView } from './components/Single/View'
import { SingleEdit } from './components/Single/Edit'
import { BulkView } from './components/Bulk/View'

interface Props {
  trafficProfile: uui.domain.client.rm.TrafficProfile
}

export function TrafficAreasViewType(props: Props) {
  const { trafficProfile } = props
  const viewType = useViewType()

  switch (viewType) {
    case 'single-view':
      return <SingleView />

    case 'single-edit':
      return <SingleEdit trafficProfile={trafficProfile} />

    case 'create-traffic-area':
      return <SingleEdit trafficProfile={trafficProfile} creating />

    case 'bulk-view':
      return <BulkView />

    case 'none':
      return null
  }
}
