import { getIdleStartValue } from './getIdleStartValue'
import { isSpeedMonitorDisabled } from './isSpeedMonitorDisabled'
import { getMaxSpeedValue } from './getMaxSpeedValue'
import { convertSpeed } from './convertSpeed'

interface Params {
  userConfig: uui.domain.UserConfiguration
  device: uui.domain.client.gps.wwgps.DeviceInfo
  driverBehaviors?: uui.domain.client.gps.wwgps.DriverBehaviorSettings
  speedMonitor?: uui.domain.client.gps.wwgps.SpeedMonitor
  deviceConfiguration?: uui.domain.client.gps.wwgps.DeviceConfiguration
  idleStartThreshold?: number
  gpsTags?: uui.domain.client.gps.wwgps.Tag[]
  allGpsTags: Record<string, uui.domain.client.gps.wwgps.Tag>
}

const maxSpeedDisabledValue = -1
const driverBehaviorsMinValue = 100
const driverBehaviorsMaxValue = 600
const maxSpeedDisabledValueInCms = 8986

export function computeDeviceData(params: Params): uui.domain.ui.forms.DeviceData {
  const {
    device,
    driverBehaviors,
    speedMonitor,
    deviceConfiguration,
    idleStartThreshold,
    gpsTags,
    allGpsTags: allGpsTagsRecord,
    userConfig,
  } = params

  const metric = userConfig.distanceFormat === 'METRIC'

  const relativeThreshold =
    typeof speedMonitor?.relativeThreshold === 'number' ? speedMonitor.relativeThreshold : 10

  const allGpsTags = Object.values(allGpsTagsRecord)

  const hasDriverBehaviorCapability = device.capabilities.includes('drb-threshold')
  const hasIdleThresholdCapability = device.capabilities.includes('idle-threshold')

  const hasSpeedMonitor = !!speedMonitor || (!!device && hasDriverBehaviorCapability)

  const badges = (gpsTags ?? []).map(tag => tag.value)

  const hardBraking = driverBehaviors?.hardBraking.threshold ?? -1
  const harshBraking = driverBehaviors?.harshBraking.threshold ?? -1
  const severeBraking = driverBehaviors?.severeBraking.threshold ?? -1
  const hardAcceleration = driverBehaviors?.hardAcceleration.threshold ?? -1
  const harshAcceleration = driverBehaviors?.harshAcceleration.threshold ?? -1
  const severeAcceleration = driverBehaviors?.severeAcceleration.threshold ?? -1
  const hardCornering = driverBehaviors?.hardCornering.threshold ?? -1

  const maxSpeedLimitValue = driverBehaviors?.speedThreshold.threshold || maxSpeedDisabledValue

  const maintenanceMileage = !!deviceConfiguration?.maintenanceMileageMethod
    ? deviceConfiguration?.maintenanceMileageMethod === 'preferOdometer'
      ? 'useOdometer'
      : 'ignoreOdometer'
    : 'useOdometer'

  return {
    hasDriverBehaviorCapability,

    braking: {
      hard: hardBraking,
      harsh: harshBraking,
      severe: severeBraking,
      min: driverBehaviorsMinValue,
      max: driverBehaviorsMaxValue,
    },

    acceleration: {
      hard: hardAcceleration,
      harsh: harshAcceleration,
      severe: severeAcceleration,
      min: driverBehaviorsMinValue,
      max: driverBehaviorsMaxValue,
    },

    cornering: {
      hard: hardCornering,
      min: driverBehaviorsMinValue,
      max: driverBehaviorsMaxValue,
    },

    badges: {
      badges,
      originalTags: allGpsTags,
    },

    speedLimit: hasSpeedMonitor
      ? {
          threshold: convertSpeed(relativeThreshold, metric),
          enabled: isSpeedMonitorDisabled(speedMonitor) ? 'disabled' : 'enabled',
          min: metric ? 9 : 5,
          max: metric ? 32 : 20,
          metric,
        }
      : null,

    idleStart: getIdleStartValue(hasIdleThresholdCapability, idleStartThreshold),
    maxSpeedLimit: {
      threshold:
        maxSpeedLimitValue >= maxSpeedDisabledValueInCms
          ? maxSpeedDisabledValue
          : getMaxSpeedValue(maxSpeedLimitValue, metric),
      min: metric ? 20 : 12,
      max: metric ? 320 : 200,
    },

    maintenanceMileage,
  }
}
