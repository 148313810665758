import type { NotificationFormType } from '@/notificationForms'
import type { FormErrors, FormFields } from '../hooks/formFields'
import type { TestValues } from '../types'

import { Box } from '@mui/material'
import { FormProvider } from '@workwave-tidal/tidal/form-ui/layout'

import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'
import { formFields } from '../hooks/formFields'

import { Footer } from './Footer'
import { Header } from './Header'
import { Body } from './Body'

interface Props {
  notification: NotificationFormType
  values: TestValues
}

export function Content(props: Props) {
  const { notification, values } = props
  const { ready } = useConfigureController(notification, values)

  if (!ready) return null

  return (
    <FormProvider<FormFields, FormErrors> name="test-notification-email-form" fields={formFields}>
      <ModalContent header={<Header />} footer={<Footer />}>
        <Box paddingTop={2}>
          <Body />
        </Box>
      </ModalContent>
    </FormProvider>
  )
}
