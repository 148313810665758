import styled from 'styled-components'

type Props = {
  primary?: boolean
  hideOverflow?: boolean
}

const FlexColumn = styled.div<Props>`
  display: flex;
  flex: ${p => (p.primary ? '0 1 auto' : '0 0 auto')};
  width: ${p => (p.primary ? '100%' : 'auto')};
  flex-direction: column;
  overflow: ${p => (p.hideOverflow ? 'hidden' : 'visible')};
`

FlexColumn.displayName = 'FlexColumn'
export default FlexColumn
