import styled from 'styled-components'
import { getColorWithAlpha } from '@/local/components'

import ActionButton from './ActionButton'

const ROUNDED_SIZE = 22
const NO_ROUNDED_ICON_SIZE = 30

const StyledActionButton = styled(ActionButton)`
  background: transparent;
  color: ${p => p.theme.colors.$paleSky};
  position: relative;

  &:disabled {
    opacity: 0.4;
  }

  &.is-rounded {
    width: ${ROUNDED_SIZE}px;
    height: ${ROUNDED_SIZE}px;
    padding: 0;
    background: ${p => p.theme.colors.$paleSky};
    color: ${p => p.theme.colors.$pureWhite};
    border-radius: 50%;
    transition: background 0.3s ease;
  }

  .o-button__inner {
    padding-top: ${NO_ROUNDED_ICON_SIZE}px;
  }

  &.is-rounded .o-button__inner {
    padding-top: 0;
    width: 100%;
    height: 100%;
  }

  &.is-rounded .o-button__ico {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) scale(0.85);
  }

  &.is-rounded .o-button__ico > * {
    width: 70%;
    height: 70%;
  }

  .o-button__ico {
    transition: 0.3s ease;
    transition-property: transform, color;
  }

  &:not(.is-rounded) .o-button__ico {
    width: ${NO_ROUNDED_ICON_SIZE}px;
    height: ${NO_ROUNDED_ICON_SIZE}px;
  }

  .o-button__text,
  .o-button__inner > span + span {
    transition: 0.3s ease;
    transition-property: color;
    font-size: 11px;
    min-width: ${NO_ROUNDED_ICON_SIZE}px;
  }

  &:hover:not(.is-rounded) {
    background: transparent;
  }

  &.is-active,
  &:hover:not(:disabled) {
    color: ${getColorWithAlpha('$nightRider', 0.8)};
  }

  &:hover.is-rounded {
    color: ${p => p.theme.colors.$pureWhite};
    background: ${p => p.theme.colors.$silver};
  }

  &:hover:not(:disabled) .o-button__ico,
  &.is-active .o-button__ico {
    transform: translateX(-50%) scale(1.2);
  }

  &:hover.is-rounded .o-button__ico {
    transform: translate(-50%, -50%) scale(1);
  }

  .o-action-button__dot {
    position: absolute;
    width: 6px;
    left: 0;
    top: 0;
  }
`

StyledActionButton.displayName = 'StyledActionButton'

export default StyledActionButton
