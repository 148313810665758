import { Dispatch, SetStateAction, useCallback } from 'react'

type Props = {
  setQuery: Dispatch<SetStateAction<string>>
}

export function useOnSearchClear(props: Props) {
  const { setQuery } = props

  return useCallback(() => {
    setQuery('')
  }, [setQuery])
}
