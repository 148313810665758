import { FormatDate } from '@/components/smartUtils/time/FormatDate'
import { HorizontalLayout } from '@/components/layout'
import { Text } from '@/local/components'

import { useTexts } from '../useTexts'

interface Props {
  idx: string | number
  date: Date
}

export function NameAndDate(props: Props) {
  const { idx, date } = props
  const texts = useTexts()

  const title = `${texts.route} ${idx} •`

  return (
    <HorizontalLayout alignItems="center" height="auto">
      <Text
        data-trackid="navigo-routes-overview-details-name"
        data-testid="navigo-routes-overview-details-name"
        weight="$semiBold"
        marginRight={3}
        size="$l"
      >
        {title}
      </Text>

      <Text size="$s">
        <FormatDate
          data-trackid="navigo-routes-overview-details-date"
          data-testid="navigo-routes-overview-details-date"
          source={date}
          shortFormat
        />
      </Text>
    </HorizontalLayout>
  )
}
