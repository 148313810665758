import type { GridValueGetterParams } from '@mui/x-data-grid-pro'

export function getExecutedCustomField(
  params: GridValueGetterParams<uui.domain.client.rm.FormattedData>,
) {
  const executedCustomFields = params.row.executedCustomFields
  const customFieldName = params.field.replace('formattedData.executedCustomFields.', '')

  return executedCustomFields[customFieldName] ?? '-'
}
