import type { ReactNode, CSSProperties } from 'react'

import { ValidColor, FlexBox, Text } from '@/local/components'

type Props = {
  backgroundColor?: ValidColor
  color?: ValidColor
  style?: CSSProperties
  children?: ReactNode
}

export function Tag(props: Props) {
  const { backgroundColor, color, style } = props

  return (
    <FlexBox padX={6} tint={backgroundColor} borderRadius="4" h={16} style={style}>
      <Text size="$m" color={color} weight="$bold" uppercase>
        {props.children}
      </Text>
    </FlexBox>
  )
}
