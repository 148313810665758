import { useMemo } from 'react'

import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Trash, Export } from '@/icons'

import { useModalActions } from '../../../../../../hooks/useModalActions'
import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'

interface Props {
  places: uui.domain.client.gps.wwgps.Place[]
  setView: (view: 'default' | 'delete') => void
}

export function Actions(props: Props) {
  const { places, setView } = props

  const placeIds = useMemo(() => places.map(place => place.id), [places])

  const { modals, modalActions } = useModalActions(placeIds)
  const actions = useActions(places, setView)
  const texts = useTexts()

  return (
    <SecondaryColumnHeaderActionsRow>
      <SecondaryColumnHeaderActionButton
        label={texts.export}
        icon={<Export />}
        onClick={modalActions.showExportPlacesModal}
        testid="place-export"
      />

      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={actions.onSwitchToDeletePanel}
            disabled={preventEditing}
            testid="place-delete"
          />
        )}
      />
      {modals}
    </SecondaryColumnHeaderActionsRow>
  )
}
