import { useMemo } from 'react'
import { SegmentType } from '../types'

export function useSegmentType(item: uui.domain.client.rm.SegmentException): SegmentType {
  return useMemo(() => {
    const { malus, additionalDrivingTimeSec } = item

    if (additionalDrivingTimeSec) {
      return { type: 'delayed', additionalDrivingTimeSec }
    }

    if (malus === 10) return { type: 'blocked' }
    if (malus === 0) return { type: 'disabled' }

    return { type: 'delayed', additionalDrivingTimeSec: -1 }
  }, [item])
}
