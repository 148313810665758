import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    confirmPanelTitle: translate({
      id: 'gps.places.view.delete.title',
      values: { selected: 1 },
    }),
    confirmPanelSubtitle: translate({ id: 'gps.places.view.delete.subtitle' }),
    delete: translate({ id: 'global.delete' }),
    edit: translate({ id: 'global.edit' }),
    export: translate({ id: 'global.export' }),
  }))

  return api
}
