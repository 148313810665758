import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'
import { useOnConfirm } from '../hooks/useOnConfirm'

export function Footer() {
  const {
    data: { notification, goingToEnable, onToggle },
  } = useController()

  const { close } = useController()

  const onConfirm = useOnConfirm(onToggle, close)
  const texts = useTexts()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          className={`pendo-notification-${notification}-toggle-modal-confirm`}
          onClick={onConfirm}
          variant="contained"
          maxWidth="50%"
        >
          {texts.confirm(goingToEnable)}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          className={`pendo-notification-${notification}-toggle-modal-cancel`}
          onClick={close}
        >
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}
