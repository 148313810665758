import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'

import * as OrderTexts from '../../../intl'
import { Props } from '../typings'

export function ReportedStatusReason(props: Props) {
  const {
    trackingData: { status, statusReason },
  } = props

  if (status !== 'reschedule' || !statusReason) {
    return null
  }

  return (
    <ReadonlyBlock>
      <ReadonlyLabel>{OrderTexts.getReportedStatusReason()}</ReadonlyLabel>

      <ReadonlyField>
        <ReadonlyFieldRow>
          <ReadonlyFieldLabel>{statusReason}</ReadonlyFieldLabel>
        </ReadonlyFieldRow>
      </ReadonlyField>
    </ReadonlyBlock>
  )
}
