import { styled } from '@mui/material'
import { theme } from '@/styles'

interface Props {
  disabled: boolean
}

export const ActionButtonComponent = styled('button', {
  name: 'ActionButtonComponent',
})<Props>(({ disabled }) => ({
  outline: 0,
  padding: 0,
  width: '100%',
  maxHeight: 44,
  borderWidth: 0,
  cursor: 'pointer',
  position: 'relative',
  background: 'inherit',
  pointerEvents: disabled ? 'none' : 'all',

  '& p': {
    color: disabled ? theme.colors.$silver : theme.colors.$nightRider,
  },

  '.active &': {
    cursor: 'default',

    '& p': {
      color: theme.colors.$dodgerBlue,
    },
  },
}))
