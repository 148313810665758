import { DrivingTimeStep } from './typings'

function sortFunction(a: number, b: number) {
  return a - b
}

function getSnapArray(steps: DrivingTimeStep[]): number[] {
  const snapped = steps.map(({ value }: DrivingTimeStep) => value)
  return snapped.sort(sortFunction)
}

function getMinIndex(value: number, snapArray: number[]): number {
  const deltaArray = snapArray.map((snap: number) => Math.abs(value - snap))
  const deltaArrayMin = Math.min(...deltaArray)
  return deltaArray.findIndex(v => v === deltaArrayMin)
}

export function getNextSnappedValue(value: number, steps: DrivingTimeStep[]) {
  const stepsCount = steps.length - 1
  const snapArray = getSnapArray(steps)
  const minIndex = getMinIndex(value, snapArray)

  return minIndex === stepsCount ? snapArray[stepsCount] : snapArray[minIndex + 1]
}

export function getPrevSnappedValue(value: number, steps: DrivingTimeStep[]): number {
  const snapArray = getSnapArray(steps)
  const minIndex = getMinIndex(value, snapArray)

  return minIndex === 0 ? snapArray[0] : snapArray[minIndex - 1]
}
