import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    openDay: (day: string) =>
      translate({ id: 'territoryCalendar.button.openDay', values: { day } }),
    openDays: (count: number) =>
      translate({ id: 'territoryCalendar.button.openDays', values: { count } }),
    open: translate({ id: 'territoryCalendar.button.open' }),
    export: translate({ id: 'territoryCalendar.button.export' }),
    exportMultipleTerritories: translate({
      id: 'territoryCalendar.button.exportMultipleTerritories',
    }),
    cancel: translate({ id: 'territoryCalendar.button.cancel' }),
    today: translate({ id: 'territoryCalendar.button.today' }),
    disabledForBeta: translate({
      id: 'global.disabledForBeta',
    }),
    cannotOpenArchivedRange: translate({
      id: 'territoryCalendar.button.open.error.archivedRange.title',
    }),
    cannotOpenRangeOfArchivedAndNotArchived: translate({
      id: 'territoryCalendar.button.open.error.rangeOfArchivedAndNotArchived.title',
    }),
    noOrdersToExportTitle: translate({
      id: 'territoryCalendar.button.export.disabled.tooltip.noOrders.title',
    }),
    noOrdersToExportSubtitle: translate({
      id: 'territoryCalendar.button.export.disabled.tooltip.noOrders.subtitle',
    }),
    moreThan31ArchivedDaysTitle: translate({
      id: 'territoryCalendar.button.export.disabled.tooltip.31ArchivedDays.title',
    }),
    ordersTooltip: (count: number) =>
      translate({ id: 'territoryCalendar.ordersTooltip', values: { count } }),

    cannotArchivedDayDuringTransactionTitle: translate({
      id: 'territoryCalendar.button.open.error.archivedDayOnTransaction.title',
    }),

    cannotArchivedDayDuringTransactionSubtitle: translate({
      id: 'territoryCalendar.button.open.error.archivedDayOnTransaction.subtitle',
    }),
  }))

  return api
}
