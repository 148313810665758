import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Unplug(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M43.6875 2l-5.03125 5.0625-2.3125-2.3125c-2.34375-2.339844-6.15625-2.339844-8.5 0l-4.8125 4.84375-1.3125-1.3125c-.242187-.25-.597656-.35547-.9375-.28125-.375.066406-.67578.339844-.78125.703125-.10547.367188.003906.757813.28125 1.015625L25.0625 14.5l-6.125 6.15625 1.40625 1.40625L26.5 15.9375 34.0625 23.5l-6.125 6.15625 1.40625 1.40625L35.5 24.9375l4.78125 4.78125c.398438.398438 1.039063.398438 1.4375 0 .398438-.398437.398438-1.039062 0-1.4375l-1.3125-1.3125 4.84375-4.8125c2.34375-2.34375 2.34375-6.125 0-8.46875v-.03125l-2.3125-2.3125L48 6.3125zM8.90625 19.96875c-.04297.007813-.085937.01953-.125.03125-.375.066406-.67578.339844-.78125.703125-.10547.367188.003906.757813.28125 1.015625l1.3125 1.3125L4.75 27.84375c-2.34375 2.34375-2.34375 6.15625 0 8.5L7.0625 38.625 2 43.6875 6.3125 48l5.0625-5.0625 2.28125 2.3125c2.34375 2.339844 6.15625 2.339844 8.5 0l4.8125-4.84375 1.3125 1.3125c.398438.398438 1.039063.398438 1.4375 0 .398438-.398437.398438-1.039062 0-1.4375l-20-20c-.20703-.222656-.507812-.335937-.8125-.3125z" />
      </svg>
    </IconContainer>
  )
}
