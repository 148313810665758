import { useSelector } from 'react-redux'
import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'

export function useRowNumber(extOrder: uui.domain.client.rm.ExtendedOrderStep) {
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const vehicles = useSelector(selectVehicles)

  if (!extOrder.hasVehicle) {
    throw new Error('Cannot provide a row number of an order without a vehicle')
  }

  const unifiedVehicle = vehicles[vehiclesByVehicleId[extOrder.vehicleId]]
  if (!unifiedVehicle.hasRoutingLicense) {
    throw new Error(
      'Cannot provide a row number of an order assigned to a vehicle without a routing license',
    )
  }

  return unifiedVehicle.vehicle.idx
}
