import styled from 'styled-components'

type Props = {
  xAxis?: boolean
  yAxis?: boolean
  position?: 'relative' | 'static' | 'absolute'
}

export const Grow = styled.div<Props>`
  display: block;
  position: ${p => p.position ?? 'static'};
  flex: 0 1 auto;
  height: ${props => (props.yAxis ? '100%' : 'auto')};
  width: ${props => (props.xAxis ? '100%' : 'auto')};
`

Grow.displayName = 'Grow'
