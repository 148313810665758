import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectTelematicsDevicesAsArrayWithActiveStatus } from '@/features/domain/device'
import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { useNotification } from '@/hooks'

export function useAddTestData() {
  const devices = useSelector(selectTelematicsDevicesAsArrayWithActiveStatus).sort((a, b) =>
    (a.deviceLabel ?? a.deviceId).localeCompare(b.deviceLabel ?? b.deviceId),
  )
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const vehicles = useSelector(selectVehicles)
  const toast = useNotification()

  const [deviceIdFromSelect, setDeviceId] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [json, setJson] = useState<unknown | null>(null)

  const [jsonValid, setJsonValid] = useState(false)
  const [periodic, setPeriodic] = useState(false)

  const onAddTestData = useCallback(
    async (
      deviceId: string,
      breadcrumbs: uui.domain.server.gps.telematics.BreadcrumbGpsInfo[] = [],
      stops: uui.domain.server.gps.telematics.StopInfo[] = [],
      periodic: boolean,
    ) => {
      try {
        if (breadcrumbs.length === 0 && stops.length === 0) {
          throw new Error('Please add breadcrumbs or stops')
        }

        setLoading(true)

        periodic
          ? await globalThis.testActions.telematics.sendTestDataToTelematicsPeriodically({
              intervalDurationSeconds: 20,
              breadcrumbs,
              deviceId,
              stops,
            })
          : await globalThis.testActions.telematics.sendTestDataToTelematics({
              breadcrumbs,
              deviceId,
              stops,
            })

        toast.success('Test data sent successfully')
      } catch (e) {
        toast.error(e.message)
      } finally {
        setLoading(false)
      }
    },
    [toast],
  )

  const onAddUploadedData = () => {
    if (!deviceIdFromSelect) {
      toast.error('Please select a device')
      return
    }

    if (!jsonValid || !json) {
      toast.error('Please upload a valid json file')
      return
    }

    // @ts-expect-error
    const breadcrumbs = json?.breadcrumbs ?? []

    for (const breadcrumb of breadcrumbs) {
      if (!breadcrumb.deviceId) {
        breadcrumb.deviceId = deviceIdFromSelect
      }
      breadcrumb.deviceId = deviceIdFromSelect
    }

    // @ts-expect-error
    const stops = json?.stops ?? []

    for (const stop of stops) {
      if (!stop.deviceId) {
        stop.deviceId = deviceIdFromSelect
      }
      stop.deviceId = deviceIdFromSelect
    }

    onAddTestData(deviceIdFromSelect, breadcrumbs, stops, periodic)
  }

  return {
    deviceId: deviceIdFromSelect,
    vehiclesByVehicleId,
    onAddUploadedData,
    onAddTestData,
    setJsonValid,
    setDeviceId,
    setPeriodic,
    jsonValid,
    periodic,
    vehicles,
    devices,
    loading,
    setJson,
    json,
  }
}
