import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCompaniesAsArray } from '@/features/domain/company'

export function useCompanies() {
  const allCompanies = useSelector(selectCompaniesAsArray)
  const [companiesFilter, onChangeCompaniesFilter] = useState(() => '')

  const companies = useMemo(() => {
    return allCompanies.filter(c =>
      c.name.toLocaleLowerCase().includes(companiesFilter.toLocaleLowerCase()),
    )
  }, [allCompanies, companiesFilter])

  return {
    companies,
    companiesFilter,
    onChangeCompaniesFilter,
    noEntries: allCompanies.length === 0,
  }
}
