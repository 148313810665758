import type { FormFields, FormErrors } from '../../../formFields'
import type { FormValidator, FormError } from '@workwave-tidal/tidal/form-fairy'

import { useValidateEmail } from '@/hooks'

import { useTexts } from '../useTexts'

export const createValidateCompanyEmail =
  (
    validateEmail: ReturnType<typeof useValidateEmail>,
    texts: ReturnType<typeof useTexts>,
  ): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const field = formApi.getField('companyEmail')
    const email = field.value.trim()

    const valid = await validateEmail(email)

    const error: FormError<keyof FormFields> = {
      id: 'companyEmail-invalid',
      field: 'companyEmail',
      message: texts.companyEmailInvalidFormat,
    }

    return {
      'companyEmail-invalid': valid ? null : error,
    }
  }
