import { type ReactNode } from 'react'
import { Stack } from '@mui/material'

type Props = {
  testId?: string
  children: ReactNode
}

export function ListHeaderFilterContainer(props: Props) {
  const { children, testId = 'list-header-filter-section' } = props

  return (
    <Stack
      justifyContent="space-between"
      data-trackid={testId}
      data-testid={testId}
      alignItems="center"
      direction="row"
      width="100%"
      gap={1}
    >
      {children}
    </Stack>
  )
}
