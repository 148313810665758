import { useCallback, useEffect } from 'react'
import { format } from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { useMainSelection } from '@/atoms'
import { selectTerritoryId } from '@/features/domain/territory'
import { selectCalendarRangeInterval } from '@/features/domain/ui'

/**
 * Expose a test action that generates the tracking token for
 * - the current territory
 * - the first selected order
 * - the start date of the calendar range
 */
export function useExposeGetTrackingToken(qaEnabled: boolean) {
  const territoryId = useSelector(selectTerritoryId)
  const { start: startDate } = useSelector(selectCalendarRangeInterval)
  const [selection] = useMainSelection('orderSteps')

  const getTrackingToken = useCallback(() => {
    if (selection.length === 0) {
      console.warn(`Please, select an order`)
      return
    }

    const firstSelectedOrderStep = selection[0]
    const dateAsString = format(startDate, 'yyyyMMdd')

    console.log('Generating tracking token for')
    console.table({ territoryId, date: dateAsString, orderStep: firstSelectedOrderStep })

    console.log('The token is')
    console.warn(
      encodeURIComponent(generateToken(territoryId, firstSelectedOrderStep, dateAsString)),
    )
  }, [selection, startDate, territoryId])

  // register/unregister the test action
  useEffect(() => {
    if (!qaEnabled) return

    globalThis.testActions ??= {}
    globalThis.testActions.getTrackingToken = getTrackingToken

    return () => void delete globalThis.testActions?.testAction
  }, [qaEnabled, getTrackingToken])
}

// see: https://github.com/WorkWave/route-manager/blob/master/apps/tracking-app/README.md
type YYYYMMDD = string
function generateToken(territoryId: string, orderStepId: string, date: YYYYMMDD) {
  const orderStepType = orderStepId.endsWith('-p') ? 'pickup' : 'delivery'

  // takes for granted that the order id doesn't contain `-p` or `-d`
  const orderId = orderStepId.replace(/(-p|-d)/, '')

  return btoa(`${territoryId};${orderStepType}-${orderId};${date}`)
}
