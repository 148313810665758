import styled from 'styled-components'
import Chip from './Chip'

const RADIUS = 6
const ICON_WIDTH = 26

const BORDER_SIZE = 2

const SLICE_WIDTH = 14
const SLICE_HEIGHT = 16

const VIOLATIONS_OFFSET = 6
const VIOLATIONS_HEIGHT = 2

const CHIP_BORDER = 2
const CHIP_WIDTH = 42
const CHIP_HEIGHT = 36

const StyledChip = styled(Chip)`
  position: relative;
  flex: 0 0 auto;
  display: block;
  width: ${CHIP_WIDTH}px;
  height: ${CHIP_HEIGHT}px;
  border-radius: ${RADIUS}px;
  box-shadow: 0 0 0 0 transparent;
  transition: 0.3s ease;
  transition-property: transform, box-shadow;

  &.o-chip .o-chip__violations {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &.o-chip.is-active .o-chip__violations {
    opacity: 1;
  }

  &.o-order-chip.use-border {
    border: ${CHIP_BORDER}px solid ${p => p.theme.colors.$pureWhite};
    border-radius: ${RADIUS + CHIP_BORDER}px;
    will-change: transform;
  }

  &.o-chip--circle {
    width: ${CHIP_HEIGHT}px;
    border-radius: 50%;
  }

  .o-chip__inner {
    border-width: 0;
    border-radius: 0;
    background: transparent;
    padding: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: ${BORDER_SIZE}px solid transparent;
    border-radius: ${RADIUS}px;
    color: ${p => p.theme.colors.$pureWhite};
    background: ${p => p.theme.colors.$nightRider};
  }

  &.o-chip--circle .o-chip__inner {
    border-radius: inherit;
  }

  .o-chip__text {
    font-size: ${p => p.theme.fonts.$h3};
    font-weight: ${p => p.theme.weights.$bold};
    line-height: 0.88;
    display: block;
    color: inherit;
    text-transform: uppercase;
  }

  .o-chip__row {
    display: block;
  }

  .o-chip__row--small {
    font-size: ${p => p.theme.fonts.$xs};
  }

  .o-chip__icon {
    width: ${ICON_WIDTH}px;
    display: flex;
    justify-content: center;
  }

  .o-chip-button__badge {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translate(-50%, -50%);
  }

  .o-chip__violations {
    position: absolute;
    bottom: -${VIOLATIONS_OFFSET}px;
    left: 50%;
    width: calc(100% - ${RADIUS}px);
    height: ${VIOLATIONS_HEIGHT}px;
    border-radius: ${VIOLATIONS_HEIGHT}px;
    background: #f9ba1b;
    transform: translateX(-50%);
  }

  .o-chip__slice {
    position: absolute;
    bottom: 0;
    right: 0;
    width: ${SLICE_WIDTH}px;
    height: ${SLICE_HEIGHT}px;
    overflow: hidden;
    border-bottom-right-radius: inherit;
    transform: translateZ(0);
  }

  .o-chip__slice::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: currentColor;
    transform: translate(42%, 44%) rotate(45deg);
    border-radius: inherit;
  }

  .o-chip__lock {
    position: absolute;
    background: ${p => p.theme.colors.$nightRider};
    border-radius: inherit;
    width: calc(100% - ${BORDER_SIZE * 2}px);
    height: calc(100% - ${BORDER_SIZE * 2}px);
  }

  .o-chip__lock .o-chip__lock-icon {
    position: absolute;
    color: ${p => p.theme.colors.$pureWhite};
    width: 6px;
    left: 50%;
    top: ${BORDER_SIZE * 2}px;
    transform: translate(-50%, 0);
  }

  &.o-deselect-chip .o-chip__inner {
    transition: opacity 0.3s ease;
    opacity: 1;
  }

  &.o-deselect-chip.has-processed .o-chip__inner {
    opacity: 0.3;
  }

  &.o-order-chip .o-chip__violations {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &.o-order-chip.is-active .o-chip__violations {
    opacity: 1;
  }

  &.o-unassign-chip.has-processed,
  &.o-unassign-chip.has-processed .o-chip__inner {
    transition: 0.3s ease;
    transition-property: background, color;
  }

  &.o-date-chip {
    cursor: auto;
  }

  &.o-date-chip .o-chip__row {
    font-size: ${p => p.theme.fonts.$h3};
  }

  &.o-date-chip .o-chip__row + .o-chip__row {
    font-size: ${p => p.theme.fonts.$h3};
  }

  &.has-underline .o-chip__inner {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &.has-underline .o-chip__underline {
    display: inline-block;
    background-color: ${p => p.theme.colors.$shadyLady};
    border: 1px solid ${p => p.theme.colors.$shadyLady};
    border-radius: 4px;
    height: 1px;
    margin: 1px 8px 0;
  }

  &.o-fitin-date-chip.best-fit .o-chip__text,
  &.o-fitin-vehicle-chip.best-fit .o-chip__text,
  &.o-fitin-step-chip.best-fit .o-chip__text {
    text-transform: capitalize;
  }

  &.is-combo .o-chip__inner {
    flex-direction: column;
    justify-content: space-around;
  }

  &.is-combo.o-chip--circle .o-chip__text {
    padding-bottom: 2px;
  }

  &.o-place-chip .o-chip__icon {
    width: 16px;
  }
`

StyledChip.displayName = 'StyledChip'

export default StyledChip
