import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'vehicles.form.exceptions.edit.title' }),
    save: (exception: string, create: boolean) =>
      translate({
        id: create
          ? 'vehicles.form.exceptions.edit.submit.create'
          : 'vehicles.form.exceptions.edit.submit',
        values: { exception },
      }),
    saveTitle: (valid: boolean, readonly: boolean, pristine: boolean) => {
      if (!valid) return translate({ id: 'vehicles.form.submitButton.disabled.title.invalid' })
      if (readonly) return translate({ id: 'vehicles.form.submitButton.disabled.title.planLocked' })
      if (pristine) return translate({ id: 'vehicles.form.submitButton.disabled.title.pristine' })
      return ''
    },
    cancel: translate({ id: 'vehicles.form.exceptions.edit.cancel' }),
    errorTitle: translate({ id: 'vehicles.form.edit.generalSettings.errorTitle' }),
    validationTWInvalidBounds: translate({
      id: 'vehicles.form.exceptions.vehicle.validationTWinvalidBounds',
    }),
    validationTWEndLessThanStart: translate({
      id: 'vehicles.form.exceptions.vehicle.validationTWEndLessThanStart',
    }),
    validationTWInvalidRange: translate({
      id: 'vehicles.form.exceptions.vehicle.validationTWInvalidRange',
    }),
    validationBreaksInvalidDuration: translate({
      id: 'vehicles.form.exceptions.vehicle.validationBreaksInvalidDuration',
    }),
    validationBreaksOverlapping: translate({
      id: 'vehicles.form.exceptions.vehicle.validationBreaksOverlapping',
    }),
    validationLoadsInvalid: translate({
      id: 'vehicles.form.exceptions.vehicle.validationLoadsInvalid',
    }),
    validationTWEndGreaterThanWorkingDayStart: translate({
      id: 'vehicles.form.exceptions.vehicle.validationTWEndGreaterThanWorkingDayStart',
    }),
  }))

  return api
}
