import { useCallback, useLayoutEffect, useRef } from 'react'

/**
 * Helper hook tracking the mounted state of the host React Component.
 *
 * This utility shouldn't be used inside `effects` only into `callbacks`.
 *
 * @example
 * import { useCallback, useState } from 'react'
 * import { useIsUnmounted } from '@/utils'
 *
 * const MyComp() {
 *    const isUnmounted = useIsUnmounted()
 *    const [color, setColor] = useState('#ff0000')
 *
 *    // some async callback
 *    const handleClick = useCallback(async () => {
 *      const newColor = await doSomeAsyncStuff()
 *
 *      // check if the component is still mounted
 *      if (isUnmounted()) return
 *
 *      // then do component stuff
 *      setColor(newColor)
 *    }, [isUnmounted])
 * }
 *
 * @returns A stable function returning `true` when the component is mounting or has been unmounted.
 */
export function useIsUnmounted() {
  const rIsUnmounted = useRef<'mounting' | 'mounted' | 'unmounted'>('mounting')

  useLayoutEffect(() => {
    rIsUnmounted.current = 'mounted'
    return () => void (rIsUnmounted.current = 'unmounted')
  }, [])

  return useCallback(() => rIsUnmounted.current !== 'mounted', [])
}
