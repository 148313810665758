import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFitMapOnBreadcrumbFetch } from '@/components/Navigo/hooks/useFitMapOnBreadcrumbFetch'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectDevices } from '@/features/domain/device'
import { selectDrivers } from '@/features/domain/driver'
import { getDeviceId } from '@/server-data'

export function useFitMapOnVehiclesForBreadcrumbFetch(
  vehicles: uui.domain.client.UnifiedVehicle[],
) {
  const calendarRange = useSelector(selectCalendarRange)
  const devices = useSelector(selectDevices)
  const drivers = useSelector(selectDrivers)

  const vehicleDevices = useMemo(
    () =>
      vehicles.reduce<
        (uui.domain.client.gps.wwgps.DeviceInfo | uui.domain.client.gps.telematics.DeviceInfo)[]
      >((acc, vehicle) => {
        const deviceId = getDeviceId(drivers, vehicle, calendarRange.minDate)

        if (!deviceId) return acc

        acc.push(devices[deviceId])

        return acc
      }, []),
    [calendarRange, devices, drivers, vehicles],
  )

  useFitMapOnBreadcrumbFetch(vehicleDevices)
}
