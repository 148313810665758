import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    disabledForLoadingTooltip: translate({ id: 'global.gpsLoading.disableEdit' }),
    filterPlaceholder: translate({ id: 'setup.driverAssignment.form.list.filter.placeholder' }),
    driverAssignments: translate({ id: 'setup.driverAssignment.form.list.yourAssignments' }),
    filterTypeAll: translate({ id: 'setup.driverAssignment.form.list.filter.filterType.any' }),
    filterTypeDefault: translate({
      id: 'setup.driverAssignment.form.list.filter.filterType.default',
    }),
    filterTypeAnnual: translate({
      id: 'setup.driverAssignment.form.list.filter.filterType.annual',
    }),
    filterTypeMonthly: translate({
      id: 'setup.driverAssignment.form.list.filter.filterType.monthly',
    }),
    filterTypeWeekly: translate({
      id: 'setup.driverAssignment.form.list.filter.filterType.weekly',
    }),
    filterTypeOneTime: translate({
      id: 'setup.driverAssignment.form.list.filter.filterType.oneTime',
    }),
    clearAll: translate({ id: 'setup.driverAssignment.form.list.filter.clearAll' }),
    add: translate({ id: 'global.add' }),
    addDriverAssignment: translate({ id: 'setup.driverAssignment.addDriverAssignment' }),

    sortByAscending: translate({ id: 'setup.driverAssignment.form.list.sorter.sortBy.ascending' }),
    sortByDescending: translate({
      id: 'setup.driverAssignment.form.list.sorter.sortBy.descending',
    }),
    sortOrderAscending: translate({
      id: 'setup.driverAssignment.form.list.sorter.sortOrder.ascending',
    }),
    sortOrderDescending: translate({
      id: 'setup.driverAssignment.form.list.sorter.sortOrder.descending',
    }),
  }))

  return api
}
