import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (count: number) =>
      translate({
        id: 'gps.alerts.view.bulk.title',
        values: { count },
      }),
    description: translate({
      id: 'gps.alerts.view.bulk.subtitle',
    }),
    genericDescription: translate({
      id: 'gps.alerts.view.bulk.subtitle',
    }),
  }))

  return api
}
