import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

type TrafficAreaFeatureMetadata = {
  mode: 'on' | 'off'
  type: 'trafficProfile'
}

export function setTrafficAreaFeatureMetadata<Field extends keyof TrafficAreaFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
  value: TrafficAreaFeatureMetadata[Field],
) {
  target.set(field, value)
}

export function getTrafficAreaFeatureMetadata<Field extends keyof TrafficAreaFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
): TrafficAreaFeatureMetadata[Field] | undefined {
  return target.get(field)
}

export function removeTrafficAreaFeatureMetadata<Field extends keyof TrafficAreaFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
) {
  target.unset(field)
}
