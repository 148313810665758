import type { BulkFieldValue } from '../../../types'

export function computeForceVehicleId(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
): BulkFieldValue<string> {
  const vehicleId = orders[0].order.forceVehicleId
  const exact = orders.every(o => o.order.forceVehicleId === vehicleId)

  if (exact) {
    return {
      status: 'exact',
      value: vehicleId ?? '',
    }
  }

  return {
    status: 'mixed',
  }
}
