import type Geometry from 'ol/geom/Geometry'

import Feature from 'ol/Feature'
import LineString from 'ol/geom/LineString'

import { setRoadSegmentFeatureMetadata } from '../roadSegmentFeatureMetadata'
import { getRoadSegmentFeatureStyle } from './getRoadSegmentFeatureStyle'

/**
 * Create a new RoadSegment Feature
 */
export function createRoadSegmentFeature(
  marker: uui.domain.ui.map.markers.RoadSegment,
  mapStyles: uui.domain.ui.map.markers.MapStyles['roadSegment'],
  selected: boolean = false,
): Feature<Geometry> {
  const { id, start, end } = marker

  const feature = new Feature({ geometry: new LineString([start, end]) })

  // use marker ID (depotId) as unique ID
  feature.setId(id)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setRoadSegmentFeatureMetadata(feature, 'type', 'roadSegment')

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['roadSegment']['mode'] =
    marker.mode ?? mapStyles.mode
  setRoadSegmentFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getRoadSegmentFeatureStyle(marker, mode, selected))

  return feature
}
