import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'
import { Body } from './Body'
import { Header } from './Header'
import { InvalidHeader } from './InvalidHeader'
import { Footer } from './Footer'
import { InvalidFooter } from './InvalidFooter'
import { InvalidBody } from './InvalidBody'

export function Content() {
  const { ready, invalid } = useConfigureController()

  if (!ready) return null

  if (invalid)
    return (
      <ModalContent header={<InvalidHeader />} footer={<InvalidFooter />}>
        <InvalidBody />
      </ModalContent>
    )

  return (
    <ModalContent header={<Header />} footer={<Footer />} minWidth={1030}>
      <Body />
    </ModalContent>
  )
}
