import { useCallback } from 'react'
import { useSettingsEditing } from '../../../../hooks/useSettingsEditing'

export function useOnEditUser() {
  const { onEdit } = useSettingsEditing('user-management', 'none')

  return useCallback(() => {
    onEdit()
  }, [onEdit])
}
