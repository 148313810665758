import { useCallback, useState } from 'react'
import { updateMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'showRoadEdits' | 'hideRoadEdits'
export function useActions() {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const showRoadEdits = useCallback(async () => {
    setMethodInExecution('showRoadEdits')

    await dispatch(updateMapStyle({ type: 'roadSegment', mode: 'on' }))

    setMethodInExecution(null)
  }, [dispatch])

  const hideRoadEdits = useCallback(async () => {
    setMethodInExecution('hideRoadEdits')

    await dispatch(updateMapStyle({ type: 'roadSegment', mode: 'off' }))

    setMethodInExecution(null)
  }, [dispatch])

  return { methodInExecution, actions: { showRoadEdits, hideRoadEdits } }
}
