import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'modals.filterByBadge.header.title' }),
    subtitle: translate({
      id: 'modals.filterByBadge.header.text',
    }),
  }))

  return api
}
