import { styled } from '@mui/material'

interface Props {
  children: string
  maxWidth?: number
  finalCharsToKeep?: number
}

const Container = styled('span', {
  name: 'Container',
  shouldForwardProp: prop => prop !== 'maxWidth',
})<{ maxWidth: string | number }>(({ maxWidth }) => ({
  display: 'flex',
  maxWidth,
  whiteSpace: 'pre',
}))

const Initial = styled('span', {
  name: 'Initial',
})<Props>(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const Final = styled('span', {
  name: 'Final',
})({})

export function CollapseText(props: Props) {
  const { children, maxWidth = '100%', finalCharsToKeep = 5 } = props

  const initial = children.substring(0, children.length - finalCharsToKeep)
  const final = children.substring(children.length - finalCharsToKeep, children.length)

  return (
    <Container maxWidth={maxWidth} data-testlabel={children}>
      <Initial>{initial}</Initial>
      <Final>{final}</Final>
    </Container>
  )
}
