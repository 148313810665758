import type { FormField, FormError } from '@workwave-tidal/tidal/form-fairy'

const name: FormField<string> = {
  value: '',
  required: true,
}

const email: FormField<string> = {
  value: '',
  required: true,
}

const newPassword: FormField<string> = {
  value: '',
}

const gpsDeviceId: FormField<string> = {
  value: '',
}

const summarizeFutureMobileRoute: FormField<boolean> = {
  value: false,
}

const mobileFutureDaysVisibility: FormField<string> = {
  value: '2',
}

const trackDriver: FormField<boolean> = {
  value: false,
}

export const formFields = {
  name,
  email,
  'new-password': newPassword,
  gpsDeviceId,
  trackDriver,
  summarizeFutureMobileRoute,
  mobileFutureDaysVisibility,
} as const

export type FormFields = typeof formFields
export type FormErrors = FormError<keyof FormFields>
