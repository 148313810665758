import { useSegmentType } from '../../hooks/useSegmentType'

import classes from './styles.module.css'
import { useStyles } from './hooks/useStyles'

import { RoutingSegmentAvatarIcon } from './RoutingSegmentAvatarIcon'

interface Props {
  segment: uui.domain.client.rm.SegmentException
  size: number
}

export function RoutingSegmentAvatar(props: Props) {
  const { size, segment } = props

  const segmentType = useSegmentType(segment)
  const styles = useStyles(segmentType, size)

  return (
    <div className={classes.container} style={styles.container}>
      <RoutingSegmentAvatarIcon segmentType={segmentType} size={size - 2} />
    </div>
  )
}
