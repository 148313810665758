import { ApplicationModeResult } from '@/atoms'
import { journal } from '@/server-data'

export type ReadOnlyCause =
  | 'courier'
  | 'rmViewer'
  | 'rmGuest'
  | 'archived'
  | 'externalTransaction'
  | 'offline'
  | 'unknown'

export type ReadOnlyStatus =
  | {
      readonly: true
      cause: ReadOnlyCause
    }
  | {
      readonly: false
      cause: 'editable'
    }

export function computeReadonlyStatus(
  offline: boolean,
  appMode: ApplicationModeResult,
  planType: 'archived' | 'none' | 'simulation' | 'operations',
  userType: uui.domain.UserType,
  externalTransactionExist: boolean,
): ReadOnlyStatus {
  if (appMode === 'readonly') {
    if (offline)
      return {
        readonly: true,
        cause: 'offline',
      }

    if (externalTransactionExist)
      return {
        readonly: true,
        cause: 'externalTransaction',
      }

    if (userType === 'rmViewer')
      return {
        readonly: true,
        cause: 'rmViewer',
      }

    if (userType === 'courier')
      return {
        readonly: true,
        cause: 'courier',
      }

    if (userType === 'rmGuest')
      return {
        readonly: true,
        cause: 'rmGuest',
      }

    if (planType === 'archived')
      return {
        readonly: true,
        cause: 'archived',
      }

    /**
     * Theoretically we always know the reason why the application became `readonly`.
     * That journal entry is useful to keep track of unknown motivations that could lead
     * to an unmanaged readonly state.
     *
     * In the future, the management of that hook and of ApplicationState atom should be refactored
     * to remove that chance and let TS protect from unexpected cases.
     */
    journal.ui(
      'The application reached a `readonly` state without a known cause. Please investigate that.',
      { info: { offline, appMode, planType, userType, externalTransactionExist } },
      'error',
    )

    return { readonly: true, cause: 'unknown' }
  }

  return {
    readonly: false,
    cause: 'editable',
  }
}
