import { useSelector } from 'react-redux'
import { Stack, Button, Typography } from '@mui/material'

import { selectUserProfile } from '@/features/domain/user'
import { RedirectToHome } from '@/routing'
import { version } from '@/utils'

import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'

import { NewVersionImage } from './components/NewVersionImage'
import { MainLayout } from './components/MainLayout'
import { Footer } from './components/Footer'

export function NewClientVersionAvailable() {
  const { clientReloadPriority, clientVersion } = useSelector(selectUserProfile)
  const { onUpdate } = useActions()
  const texts = useTexts()

  const appVersion = version.getVersion()

  if (clientVersion === appVersion || clientReloadPriority !== 'high') return <RedirectToHome />

  return (
    <MainLayout picture={<NewVersionImage width={400} />} footer={<Footer />}>
      <Stack spacing={4}>
        <Stack spacing={0}>
          <Typography variant="h3">{texts.title}</Typography>
          <Typography variant="h4">{texts.description}</Typography>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={onUpdate}>
            {texts.primaryAction}
          </Button>
        </Stack>
      </Stack>
    </MainLayout>
  )
}
