import { Stack, Typography, IconButton } from '@mui/material'
import { RemoveCircleOutline as RemoveCircleOutlineIcon } from '@mui/icons-material'

import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from './useTexts'

interface Props {
  title: string
  onRemoveTerritory: () => void
}

const iconButtonSx = { color: 'error.main' }

export function Header(props: Props) {
  const { title, onRemoveTerritory } = props

  const texts = useTexts()

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" component="header">
      <Typography variant="subtitle2">{title}</Typography>
      <Tooltip title={texts.tooltip} placement="top">
        <IconButton onClick={onRemoveTerritory} sx={iconButtonSx}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}
