import type { NotificationAddress, AlertFormValues } from '../types'

export function computeFormInitialValues(
  alert: uui.domain.client.gps.wwgps.EventAlert,
): AlertFormValues {
  return {
    id: parseInt(alert.id),
    name: alert.name,
    userId: alert.userId,
    organizationId: alert.organizationId,
    eventTypeId: `${alert.eventTypeId}`,
    notificationAddresses: alert.notificationAddresses.map(parseRecipients),
    deviceIds: alert.deviceIds,
    defaultEmailAddress: undefined,
  }
}

function parseRecipients(notificationAddress: string): NotificationAddress {
  const separatorIndex = notificationAddress.indexOf(':')

  const prefix = notificationAddress.slice(0, separatorIndex)
  const address = notificationAddress.slice(separatorIndex + 1)

  return {
    prefix: prefix === 'mailto' ? 'email' : 'sms',
    address,
  }
}
