import type { MouseInteractionSubscriber } from '../../MouseInteraction'

import Feature from 'ol/Feature'

import { getFeatureMetadata } from '../../../layers/featureMetadata'

import { clickDepot } from './utils/clickDepot'
import { clickOrder } from './utils/clickOrder'
import { clickPlace } from './utils/clickPlace'
import { clickDevice } from './utils/clickDevice'
import { clickRegion } from './utils/clickRegion'
import { clickVehicle } from './utils/clickVehicle'
import { clickBreadcrumb } from './utils/clickBreadcrumb'

import { clickRoadSegment } from './utils/clickRoadSegment'
import { clickTrafficArea } from './utils/clickTrafficArea'

export const subscribeToMouseSingleClick: MouseInteractionSubscriber = (
  features,
  _layersByFeature,
  modifiers,
  _mapBrowserEvent,
) => {
  if (features.length === 0) return

  // currently only single selection is supported through mouse-click interactions
  const feature = features[0]

  // narrow FeatureLike to Feature
  if (!(feature instanceof Feature)) return

  const type = getFeatureMetadata(feature, 'type')

  switch (type) {
    case 'order':
      clickOrder(feature, modifiers)
      break

    case 'depot':
      clickDepot(feature, modifiers)
      break

    case 'region':
      clickRegion(feature, modifiers)
      break

    case 'roadSegment':
      clickRoadSegment(feature, modifiers)
      break

    case 'trafficProfile':
      clickTrafficArea(feature, modifiers)
      break

    case 'place':
      clickPlace(feature, modifiers)
      break

    case 'breadcrumbPoints':
    case 'breadcrumbStops':
      clickBreadcrumb(feature, modifiers)
      break

    case 'device':
      clickDevice(feature, modifiers)
      break

    case 'vehicle':
      clickVehicle(feature, modifiers)
      break

    // TODO: add other supported selectable features
    default:
      break
  }
}
