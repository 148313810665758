import type { BulkLoadExact } from '../../../../../../types'

import { useMemo } from 'react'

//  find the load with the biggest addedTimestamp
export function useFindLoadToFocus(loads: BulkLoadExact[]) {
  return useMemo(() => {
    const sortedLoads = loads.sort((a, b) => b.addedTimestamp - a.addedTimestamp)

    // return the load name only if the timestamp is not -1 (default for loads not added manually)
    return sortedLoads[0]?.addedTimestamp !== -1 ? sortedLoads[0]?.name : undefined
  }, [loads])
}
