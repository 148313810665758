import { BackButton } from '../../../../elements/BackButton'
import { usePlacesNavigator } from '../../hooks/usePlacesNavigator'

import { Styler } from './components/Styler'

interface Props {
  reset: () => void
}

export function MapStyle(props: Props) {
  const { reset } = props
  const { currentPlaceIds } = usePlacesNavigator()

  return (
    <>
      <Styler placeIds={currentPlaceIds} />
      <BackButton testid="navigo-places-mapStyles-back" onClick={reset} />
    </>
  )
}
