import type { RootProps } from './types'

import { Forms } from './sections/notifications/FormsRoot'

/**
 * The Notification forms entry point.
 */
export function Root(props: RootProps) {
  const { path, PreferencesProvider, TrackingPageProvider, ...formsProps } = props

  switch (path) {
    case 'tracking-app':
      return TrackingPageProvider

    case 'preferences':
      return PreferencesProvider

    case 'notifications':
      return <Forms {...formsProps} />
  }
}
