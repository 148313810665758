export function parseNotificationAddress(notificationAddress: string) {
  const separatorIndex = notificationAddress.indexOf(':')

  const prefix = notificationAddress.slice(0, separatorIndex)
  const address = notificationAddress.slice(separatorIndex + 1)

  return {
    prefix: prefix === 'mailto' ? 'email' : 'sms',
    address,
  } as { prefix: 'sms' | 'email'; address: string }
}
