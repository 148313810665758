import type { ReactElement } from 'react'

import { CSSProperties, Component } from 'react'
import { clsx } from '@/utils'

import Badge, { Props as BadgeProps } from '../Badge'
import { ChipShape } from '../../typings'

export interface Props {
  row?: string
  optRow?: string
  Icon?: ReactElement

  color?: string
  background?: string
  borderColor?: string

  selected?: boolean

  badge?: BadgeProps

  shape?: ChipShape

  hasViolation?: boolean
  disabled?: boolean
  hasToBeProcessed?: boolean
  sliceColor?: string
  hasUnderline?: boolean
  hasLock?: boolean

  secondRowSmall?: boolean

  className?: string
}

export default class Chip extends Component<Props> {
  renderText = ({ row, optRow, Icon, secondRowSmall = false }: Props) => {
    const firstRowClassName = clsx({
      'o-chip__row': true,
      'o-chip__row--small': !!row && row?.length > 0 && !!Icon,
    })
    const secondRowClassName = clsx({
      'o-chip__row': true,
      'o-chip__row--small': secondRowSmall,
    })

    return (
      <>
        {!!Icon && <span className="o-chip__icon">{Icon}</span>}
        {row && (
          <span className="o-chip__text">
            <span className={firstRowClassName}>{row}</span>
            {optRow && <span className={secondRowClassName}>{optRow}</span>}
          </span>
        )}
      </>
    )
  }

  renderBadge = () => {
    const { badge } = this.props

    if (!badge) return null
    return (
      <span className="o-chip-button__badge">
        <Badge {...badge} />
      </span>
    )
  }

  render() {
    const {
      className = '',
      shape = 'rounded',
      selected = false,
      hasViolation = false,
      hasToBeProcessed = false,
      color,
      background,
      borderColor,
      sliceColor,
      hasUnderline = false,
      row,
      Icon,
      hasLock = false,
    } = this.props

    const sliceStyle: CSSProperties = {
      color: !!sliceColor ? sliceColor : 'transparent',
    }

    const rootClassName: string = clsx({
      [className]: true,
      'o-chip': true,
      'o-chip--circle': shape === 'rounded',
      'o-chip--square': shape === 'squared',
      'is-selected': selected,
      'has-violation': hasViolation,
      'has-processed': hasToBeProcessed,
      'has-underline': hasUnderline,
      'is-combo': !!row && row?.length > 0 && !!Icon,
      'has-lock': hasLock,
    })

    return (
      <div className={rootClassName}>
        <div className="o-chip__inner" style={{ color, background, borderColor }}>
          {this.renderText(this.props)}
          {hasViolation && <span className="o-chip__violations" />}
          {sliceColor && <span className="o-chip__slice" style={sliceStyle} />}
          {hasUnderline && <span className="o-chip__underline" />}
          {hasLock && (
            <span className="o-chip__lock">
              <span className="o-chip__lock-icon">{Icon}</span>
            </span>
          )}
        </div>
        {this.renderBadge()}
      </div>
    )
  }
}
