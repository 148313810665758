import { PureComponent } from 'react'

import {
  SecondaryColumnContentHorizontalRuler,
  SecondaryColumnContentContainer,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  FormContent,
  ReadonlyFieldParagraph,
} from '@/forms-legacy'

import * as Texts from '../../intl'

type Props = {
  name: string
  label: string
}

export default class ViewDetails extends PureComponent<Props> {
  render() {
    const { name, label } = this.props

    const emptyLabel: boolean = label.length === 0

    return (
      <FormContent footer={<div />}>
        <SecondaryColumnContentContainer>
          <SecondaryColumnContentHorizontalRuler bottomSpace />

          <ReadonlyBlock>
            <ReadonlyLabel primary>{Texts.getSimulationDetailsText()}</ReadonlyLabel>
          </ReadonlyBlock>

          <ReadonlyBlock>
            <ReadonlyLabel>{Texts.getSimulationNameFieldText()}</ReadonlyLabel>
            <ReadonlyField>
              <ReadonlyFieldRow>
                <ReadonlyFieldLabel>{name}</ReadonlyFieldLabel>
              </ReadonlyFieldRow>
            </ReadonlyField>
          </ReadonlyBlock>

          <ReadonlyBlock>
            <ReadonlyLabel empty={emptyLabel}>{Texts.getSimulationLabelFieldText()}</ReadonlyLabel>
            {!emptyLabel && (
              <ReadonlyField>
                <ReadonlyFieldParagraph>{label}</ReadonlyFieldParagraph>
              </ReadonlyField>
            )}
          </ReadonlyBlock>
        </SecondaryColumnContentContainer>
      </FormContent>
    )
  }
}
