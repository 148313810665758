import type { ActionStatus } from '../../../../../hooks/useDevicesNavigoMachine/types'

import { useMemo } from 'react'

import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { Tooltip } from '@/components/primitives/Tooltip'
import { CenterOnMap } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  permission: ActionStatus | 'disabledNoMarker'
  onClick: () => void
}

export function CenterOnMapButton(props: Props) {
  const { permission, onClick } = props
  const texts = useTexts()

  const disabled = permission.startsWith('disabled')

  const tooltipTitle = useMemo(() => {
    switch (permission) {
      case 'disabledNoMarker':
        return texts.disabledNoMarker

      case 'disabled':
        return texts.notAvailableInThePast

      default:
        return ''
    }
  }, [texts, permission])

  const iconColor = disabled ? '$silver' : '$nightRider'

  return (
    <Tooltip placement="top" disabled={!disabled} title={tooltipTitle}>
      <NavigoIconButton
        onClick={onClick}
        disabled={disabled}
        text={texts.centerOnMap}
        Icon={<CenterOnMap size={16} color={iconColor} />}
        testid="navigo-device-overview-actions-centerOnMap"
      />
    </Tooltip>
  )
}
