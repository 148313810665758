import type {
  YYYYMMDD,
  CalendarClickType,
  WeeklyValidSelection,
  WeekCalendarProps as Props,
} from '../../typings'

import { useCallback } from 'react'
import { getDay } from 'date-fns/esm'

import { HorizontalLayout, VerticalLayout } from '@/components/layout'

import { Month } from '../../components/Month'
import { parseDate } from '../../utils/parseDate'

import { useSelectionData } from './useSelectionData'
import { useWeeklyDays } from './useWeeklyDays'

export function Weekly(props: Props) {
  const { highlightedDays, onChange, selection, allowAutoMonthHeight, dotColor } = props

  const selectionData = useSelectionData(selection)

  const days = useWeeklyDays({
    selection: selectionData,
    highlightedDays,
  })

  const handleOnClick = useCallback(
    (day?: YYYYMMDD, type: CalendarClickType = 'click') => {
      if (day) {
        const dayDate = getDay(parseDate(day)) as WeeklyValidSelection
        onChange(dayDate, selection, type)
      }
    },
    [onChange, selection],
  )

  return (
    <VerticalLayout height="auto" alignItems="center">
      <HorizontalLayout width="auto">
        <Month
          index={0}
          days={days}
          dotColor={dotColor}
          onClick={handleOnClick}
          allowAutoMonthHeight={allowAutoMonthHeight}
        />
      </HorizontalLayout>
    </VerticalLayout>
  )
}
