import type { FormProps } from '../types'

import { NotificationForm } from '../../../../../../notificationForms/sections/notifications/components/NotificationForm'
import { NotificationView } from '../../../../../../notificationForms/sections/notifications/components/NotificationView'

import { useFormStateController } from '../../hooks/useFormStateController'
import { TodayNotificationView } from './components/TodayNotificationView'

import { useTodayNotificationInitForm } from './hooks/useTodayNotificationInitForm'
import { useTodayNotificationSubmit } from './hooks/useTodayNotificationSubmit'
import { useTodayNotificationConfig } from './hooks/useTodayNotificationConfig'
import { useTodayNotificationValues } from './hooks/useTodayNotificationValues'
import { useTodayNotificationToggle } from './hooks/useTodayNotificationToggle'
import { useTexts } from './useTexts'

/**
 * Renders the Today notification form providing all the domain data and apis.
 */
export function TodayNotification(props: FormProps) {
  const { inEditing, setEditingState } = props

  const values = useTodayNotificationValues()

  const { toggleDescription, onToggle } = useTodayNotificationToggle(values)
  const { edit, cancel } = useFormStateController(setEditingState)
  const onSubmit = useTodayNotificationSubmit()
  const config = useTodayNotificationConfig()
  const texts = useTexts()

  // Render the Form inside a FormRoot component that will expose the FormState API through a React.Context
  if (inEditing) {
    return (
      <NotificationForm
        initHook={useTodayNotificationInitForm}
        subtitle={texts.description}
        notification={'today'}
        onSubmit={onSubmit}
        title={texts.title}
        cancel={cancel}
        values={values}
        config={config}
      />
    )
  }

  return (
    <NotificationView
      Form={<TodayNotificationView values={values} />}
      toggleSubtitle={toggleDescription}
      subtitle={texts.description}
      notification={'today'}
      onToggle={onToggle}
      title={texts.title}
      values={values}
      onEdit={edit}
    />
  )
}
