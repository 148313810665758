import { WorkingDayInfoField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  h24: boolean
}

export function WorkingDayInfo(props: Props) {
  const { h24 } = props

  const texts = useTexts()

  return (
    <WorkingDayInfoField
      name="workingDayInfo"
      testid="workingDayInfo"
      label={texts.workingHours}
      labelStart={texts.workingDayStart}
      labelEnd={texts.workingDayEnd}
      labelEndInfo={texts.workingDayEndInfo}
      labelFlexibleStart={texts.flexibleStart}
      h24={h24}
    />
  )
}
