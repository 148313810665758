import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    mixed: translate({ id: 'order.form.bulk.mixed' }),
    lowest: translate({ id: 'orders.priority.lowest' }),
    lower: translate({ id: 'orders.priority.lower' }),
    normal: translate({ id: 'orders.priority.normal' }),
    higher: translate({ id: 'orders.priority.higher' }),
    highest: translate({ id: 'orders.priority.highest' }),
    priority: translate({ id: 'orders.edit.field.priority' }),
  }))

  return api
}
