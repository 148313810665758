import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDevicesAggregateInfo } from '@/features/domain/device'

export function useCanRenderGpsDataToggler(
  device: uui.domain.client.gps.wwgps.DeviceInfo,
  edit: boolean = false,
) {
  const deviceAggregateInfo = useSelector(selectDevicesAggregateInfo)

  return useMemo(() => {
    if (device.virtual) return false

    const tags = deviceAggregateInfo[device.deviceId]?.gpsTags ?? []
    const hasSpeedMonitor = !!deviceAggregateInfo[device.deviceId]?.speedMonitor

    const capabilities = device.capabilities

    const hasDriverBehaviorCapability = capabilities.includes('drb-threshold')
    const hasIdleThresholdCapability = capabilities.includes('idle-threshold')

    return hasDriverBehaviorCapability || hasIdleThresholdCapability || edit || hasSpeedMonitor
      ? true
      : tags.length > 0
  }, [device, deviceAggregateInfo, edit])
}
