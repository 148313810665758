import OlMap from 'ol/Map'
import View from 'ol/View'
import { defaults as interactionDefaults, MouseWheelZoom } from 'ol/interaction'

import { gis } from '@/server-data'

export const mouseWheelZoomInteraction = new MouseWheelZoom({
  constrainResolution: true,
  duration: 0,
  timeout: 100,
})

export const createMap = (mapId: string | undefined, center: uui.domain.LatLng) => {
  const interactions = interactionDefaults({
    altShiftDragRotate: true,
    doubleClickZoom: true,
    keyboard: true,
    mouseWheelZoom: true,
    shiftDragZoom: true,
    dragPan: true,
    pinchRotate: false,
    pinchZoom: true,
    // zoomDelta: 0,
  }).extend([mouseWheelZoomInteraction])

  return new OlMap({
    target: mapId,
    layers: [],

    interactions,

    view: new View({
      // resolutions: tttt.getSource()?.getTileGrid().getResolutions(),
      zoom: 10,
      minZoom: 3,
      maxZoom: 19,
      // constrainResolution: true,

      center: gis.fromLatLngToCoordinate(center),
    }),
  })
}
