import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function FitSelectedToMap(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 17">
        <path d="M1.438 1.571c-.242 0-.438.196-.438.438v3.903c0 .177.105.337.27.404.161.068.346.023.47-.1L3.036 4.92l1.556 1.556c.329-.534.78-.984 1.313-1.313L4.349 3.607l1.295-1.296c.082-.084.135-.189.135-.302 0-.057-.01-.114-.034-.169-.067-.162-.227-.269-.404-.269H1.437zm9.97 4.905c-.329-.534-.78-.984-1.313-1.313l1.556-1.556-1.295-1.296c-.124-.124-.168-.309-.101-.47.067-.165.227-.27.404-.27h3.903c.242 0 .438.196.438.438v3.903c0 .177-.107.337-.27.404-.054.023-.11.034-.168.034-.113 0-.218-.053-.302-.135L12.964 4.92l-1.556 1.556zm-1.313 5.503c.534-.329.984-.78 1.313-1.313l1.556 1.556 1.296-1.295c.124-.124.309-.168.47-.101.165.067.27.227.27.404v3.903c0 .242-.196.438-.438.438H10.66c-.177 0-.337-.107-.404-.27-.023-.054-.034-.11-.034-.168 0-.113.053-.218.135-.302l1.295-1.296-1.556-1.556zm-5.503-1.313c.327.531.774.98 1.304 1.308L4.35 13.535l1.295 1.296c.124.124.168.309.101.47-.067.165-.227.27-.404.27H1.437c-.24 0-.437-.196-.437-.438V11.23c0-.177.107-.337.27-.404.054-.023.11-.034.167-.034.114 0 .22.053.303.135l1.296 1.296 1.556-1.557z" />
        <circle cx="8" cy="8.571" r="2" />
      </svg>
    </IconContainer>
  )
}
