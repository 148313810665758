import styled from 'styled-components'

const EmptyStateTitle = styled.h3`
  margin: 0 0 8px 0;
  text-transform: uppercase;
  font-weight: ${p => p.theme.weights.$semiBold};
  font-size: ${p => p.theme.fonts.$m};
  color: inherit;
`

EmptyStateTitle.displayName = 'EmptyStateTitle'
export default EmptyStateTitle
