import { type CollectionFilterConfig } from '@bryntum/schedulerpro'

export const approvedRouteFilter: Partial<CollectionFilterConfig> = {
  id: 'approvedRouteFilter',
  property: 'isApproved',
  operator: 'isFalse',
}

export const unavailableRouteFilter: Partial<CollectionFilterConfig> = {
  id: 'unavailableRouteFilter',
  property: 'available',
  operator: 'isTrue',
}
