import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Plus(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 11 11">
        <path d="M6.30766 1.69256c.00005-.44614-.36155-.80775-.8077-.8077-.44595-.00014-.80774.36165-.8076.8076L4.6923 4.69233l-2.99985.00006C1.2465 4.69223.8847 5.05402.88482 5.5c0 .4461.3616.8077.8077.8077l2.99987-.00007-.00007 2.99988c0 .4461.3616.8077.80767.80768.44597.00012.80776-.36167.80763-.80763l.00008-2.99983 2.99984-.00008c.44595.00014.80774-.36165.8076-.8076.00008-.44612-.36153-.80772-.80767-.80767l-2.99985.00004.00004-2.99984z" />
      </svg>
    </IconContainer>
  )
}
