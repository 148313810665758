import { FeatureLike } from 'ol/Feature'
import LineString from 'ol/geom/LineString'
import Geometry from 'ol/geom/Geometry'

export function getFeatureCoordinates(feature: FeatureLike) {
  const geometry = feature.getGeometry()
  if (geometry instanceof Geometry && geometry.getType() === 'LineString') {
    const line = geometry as LineString
    const coordinates = line.getCoordinates()

    if (coordinates) {
      return coordinates
    }
  }

  return []
}
