import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function DriverAssignment(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M2.13 26.864v-1.495c0-1.935 2.918-3.694 6.106-4.313 0 0 .975-.526.708-1.495-.975-1.23-1.24-2.55-1.24-2.55a2.748 2.748 0 01-.62-.44c-.266-.354-.618-1.409-.528-2.2.086-.704.262-.44.352-.614-.709-1.585-.352-3.609.443-5.104 1.68-2.904 5.22-2.024 5.752-.97 3.364-.614 3.274 4.665 2.745 5.984 0 0 .263.09.263 1.32-.086 1.32-1.148 2.11-1.148 2.11 0 .354-.442 1.409-1.15 2.378-.62 1.23.618 1.495.618 1.495 3.187.615 6.105 2.375 6.105 4.31v1.495c0 1.234-4.778 2.553-9.203 2.553-4.335 0-9.203-.44-9.203-2.464z" />
        <path d="M28.826 6.078c0-2.803-2.197-5-5-5s-5 2.197-5 5 2.197 5 5 5 5-2.197 5-5zm-2.5 7.755c0-.123-.03-.25-.098-.352l-.508-.723.508-.703a.597.597 0 00.078-.215 6.349 6.349 0 01-2.48.489 6.36 6.36 0 01-2.5-.508v6.133c0 .166.058.332.175.45l1.25 1.25a.635.635 0 00.45.175h1.25a.635.635 0 00.45-.176l1.25-1.25a.635.635 0 00.175-.449v-1.055a.59.59 0 00-.176-.43l-.723-.703.723-.703a.635.635 0 00.176-.45v-.78zm-2.5-7.755a1.25 1.25 0 10.001-2.499 1.25 1.25 0 00-.001 2.499z" />
      </svg>
    </IconContainer>
  )
}
