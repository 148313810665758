export function isRouteOutdated(
  route: uui.domain.client.rm.Route,
  approvedPlanData?: uui.domain.client.rm.ApprovedPlanData,
) {
  const approvedRoute = approvedPlanData?.[route.vehicleId]
  if (!approvedRoute) {
    return true
  }

  return route.revision > approvedRoute.route.revision
}
