import type { ImageLayout } from '../typings'
import styled from 'styled-components'

export interface Props extends workwave.Div {
  layout?: ImageLayout
}

const ReadonlyImageContainer = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: row;
  height: ${p => (p.layout === 'detail' ? 'auto' : '60px')};
  background: ${p => p.theme.colors.$alabaster};
  border-radius: 6px;
  align-items: center;
  flex-wrap: ${p => (p.layout === 'detail' ? 'wrap' : 'nowrap')};
  width: 100%;
  padding-left: ${p => (p.layout === 'detail' ? '2px' : '12px')};
  padding-right: ${p => (p.layout === 'detail' ? '2px' : '12px')};
  padding-top: ${p => (p.layout === 'detail' ? '2px' : '0')};
`

ReadonlyImageContainer.displayName = 'ReadonlyImageContainer'
export default ReadonlyImageContainer
