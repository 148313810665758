import styled from 'styled-components'

const CheckboxInput = styled.input.attrs(() => ({
  className: 'o-checkbox-input',
  type: 'checkbox',
}))`
  opacity: 0;
  z-index: 10;
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 0;
`

CheckboxInput.displayName = 'CheckboxInput'
export default CheckboxInput
