import { useCallback, useState } from 'react'

export function useDialog() {
  const [open, setOpen] = useState(false)

  const onShowDialog = useCallback(() => {
    setOpen(true)
  }, [])
  const onCloseDialog = useCallback(() => {
    setOpen(false)
  }, [])

  return {
    open,
    onShowDialog,
    onCloseDialog,
  }
}
