import type { ReactElement, ReactNode } from 'react'

import { Component } from 'react'
import { clsx } from '@/utils'

export interface Props {
  text?: string
  Icon?: ReactElement
  className?: string
  tiny?: boolean
  disabled?: boolean
}

export default class Badge extends Component<Props> {
  getInner = (): ReactNode => {
    const { text = '', Icon } = this.props
    if (Icon) return <span className="o-badge__icon">{Icon}</span>

    return text
  }

  render() {
    const { className = '', Icon, tiny = false, disabled = false } = this.props
    const rootClassName = clsx({
      [className]: true,
      'o-badge': true,
      'o-badge--rounded': !!Icon,
      'o-badge--tiny': tiny,
      'is-disabled': disabled,
    })

    return <span className={rootClassName}>{this.getInner()}</span>
  }
}
