import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    overlappingTags: translate({ id: 'order.form.bulk.errors.tag.overlapping' }),
    tagsIn: translate({ id: 'orders.edit.field.tags.in' }),
    tagsOut: translate({ id: 'orders.edit.field.tags.out' }),
    addTagsInModalTitle: translate({ id: 'order.form.bulk.tags.addTagsInModalTitle' }),
    addTagsOutModalTitle: translate({ id: 'order.form.bulk.tags.addTagsOutModalTitle' }),
  }))

  return api
}
