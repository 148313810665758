import {
  TextField,
  ColorPickerField,
  CheckboxField,
  FooterMessage,
  FooterText,
} from '@/forms-legacy'

import { colorPalette } from '@/server-data'

import { useTexts } from './hooks/useTexts'

// remove readonly
const colors = [...colorPalette]

interface Props {
  disableTrackingCheckbox: boolean
  hasReachedTrackingLimit: boolean
  hasVehicleToTrack: boolean
  hasLiveEta: boolean
  simulation: boolean
}

export function RmData(props: Props) {
  const {
    hasLiveEta,
    hasReachedTrackingLimit,
    disableTrackingCheckbox,
    hasVehicleToTrack,
    simulation,
  } = props

  const texts = useTexts()

  return (
    <>
      <TextField name="rm.name" testid="rm.name" label={texts.name} />
      <ColorPickerField
        name="rm.color.color"
        testid="rm.color"
        label={texts.color}
        info={texts.colorInfo}
        wrappedInputProps={{
          openText: texts.globalCancel,
          closedText: texts.globalEdit,
          colors,
        }}
      />
      {!simulation && (
        <div className="o-pendo__vehicle-form__event-tracking">
          <CheckboxField
            name="rm.eventTracking"
            testid="rm.eventTracking"
            wrappedInputProps={{
              disabled: disableTrackingCheckbox,
              label: texts.tracked,
              id: 'rm.eventTracking',
            }}
          />
          {hasReachedTrackingLimit && hasVehicleToTrack && (
            <FooterMessage quote alignedWithForm>
              <FooterText>{texts.trackedWarning}</FooterText>
            </FooterMessage>
          )}

          {hasLiveEta && (
            <CheckboxField
              name="rm.liveEta"
              testid="rm.liveEta"
              wrappedInputProps={{
                label: texts.liveEta,
                id: 'rm.liveEta',
              }}
            />
          )}
        </div>
      )}
    </>
  )
}
