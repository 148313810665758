import styled from 'styled-components'

import { HorizontalLayout } from '@/components/layout'
import { H2 } from '@/local/components'

interface Props {
  text: string
  value: string | number
  testId?: string
}

export function Header(props: Props) {
  const { text, value, testId } = props

  return (
    <HorizontalLayout
      height={24}
      flexWrap="nowrap"
      alignItems="center"
      data-testid={testId}
      data-trackid={testId}
    >
      <H2 ellipsis>{text}</H2>
      <UnShrinkableBlock>
        <H2 style={{ paddingLeft: 6 }} textAlign="right">
          {value}
        </H2>
      </UnShrinkableBlock>
    </HorizontalLayout>
  )
}

const UnShrinkableBlock = styled.div`
  flex: 0 0 auto;
  width: auto;
`

UnShrinkableBlock.displayName = 'UnShrinkableBlock'
