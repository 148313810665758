function isHex(hex: string) {
  return /(^[0-9A-F]{6}$)|(^[0-9A-F]{3}$)/i.test(hex)
}

export function getValidHexColor(color: string): string {
  if (color.length === 6 || color.length === 3) {
    if (isHex(color)) {
      return `#${color}`
    }
  }

  if (color.startsWith('#') && isHex(color.slice(1))) {
    return color
  }

  return '#FFF'
}
