import { renderToStaticMarkup } from 'react-dom/server'
import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { intl } from '@/intl'

import { DriverCell } from '../../../components/cells/DriverCell'
import { driverSorter } from '../sorters/driverSorter'

export function createDriverColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    text: intl.translate({ id: 'rm.scheduler.column.driver' }),
    type: 'template',
    field: 'driverName',
    width,
    editor: false,
    hidden,
    region: 'cols',

    template: ({ record, field, value }) => {
      return renderToStaticMarkup(
        <DriverCell record={record as ResourceModel} field={field} value={value} />,
      )
    },

    sortable: driverSorter,
    resizable: true,
    groupable: false,
  }
}
