import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Check(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 26 26">
        <path d="M22.5664 4.73047l-1.79296-1.21875c-.4961-.33594-1.17578-.20703-1.50782.28516L10.47657 16.7578 6.4375 12.71876c-.42188-.42188-1.10938-.42188-1.53125 0L3.3711 14.2539c-.42188.42188-.42188 1.10938 0 1.53516L9.58202 22c.34766.34766.89453.61328 1.38672.61328.4922 0 .98828-.3086 1.3086-.77344L22.85546 6.23438c.33594-.4922.20703-1.16797-.28906-1.5039z" />
      </svg>
    </IconContainer>
  )
}
