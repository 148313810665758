import { useState } from 'react'

import { Root } from './components/Root'

export function useRequestLogModal() {
  const [open, setOpen] = useState(false)

  const show = () => setOpen(true)
  const close = () => setOpen(false)

  return {
    show,
    close,
    Modal: open ? Root : () => null,
  }
}
