import { Actions } from './Actions'
import { DeletePanel } from './DeletePanel'

interface Props {
  view: 'default' | 'delete'
  setView: (view: 'default' | 'delete') => void
}

export function ActionsManager(props: Props) {
  const { view, setView } = props

  switch (view) {
    case 'delete':
      return <DeletePanel setView={setView} />
    case 'default':
      return <Actions setView={setView} />
  }
}
