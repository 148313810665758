import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { setMainSelection } from '@/atoms'

import { useVehicleIdsForCurrentSelection } from './useVehicleIdsForCurrentSelection'
import { useOrderIdsForCurrentSelection } from './useOrderIdsForCurrentSelection'

export const useEntityRelations = () => {
  const interval = useSelector(selectCalendarRangeInterval)
  const vehicleIdsForCurrentSelection = useVehicleIdsForCurrentSelection(interval)
  const orderIdsForCurrentSelection = useOrderIdsForCurrentSelection()

  const setVehiclesSelection = useCallback(
    () => setMainSelection('unifiedVehicles', vehicleIdsForCurrentSelection),
    [vehicleIdsForCurrentSelection],
  )

  const setOrderStepsSelection = useCallback(
    () => setMainSelection('orderSteps', orderIdsForCurrentSelection),
    [orderIdsForCurrentSelection],
  )

  return useMemo(
    () => ({
      interval,
      orders: orderIdsForCurrentSelection,
      vehicles: vehicleIdsForCurrentSelection,
      actions: {
        setVehiclesSelection,
        setOrderStepsSelection,
      },
    }),
    [
      interval,
      setVehiclesSelection,
      setOrderStepsSelection,
      orderIdsForCurrentSelection,
      vehicleIdsForCurrentSelection,
    ],
  )
}
