import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (count: number) =>
      translate({
        id: 'settings.users.view.deleteUserConfirmationModal.header.title',
        values: { count },
      }),

    subtitle: (count: number) =>
      translate({
        id: 'settings.users.view.deleteUserConfirmationModal.header.subtitle',
        values: { count },
      }),
  }))

  return api
}
