import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'settings.personalization.view.headerTitle' }),
    headerDescription: translate({ id: 'settings.personalization.view.headerDescription' }),

    weekStartsWith: translate({ id: 'settings.personalization.view.weekStartsWith' }),
  }))

  return api
}
