import { NestedDropdown, DropdownMenuItem } from '@/components/DropdownMenu'

import { useRoutingLayoutMenu } from '../hooks/useRoutingLayoutMenu'
import { useTexts } from '../useTexts'

export function ExtractedMapSubMenu() {
  const texts = useTexts()
  const { focusExtractedMap, closeExtractedMap, extractedMapOpen } = useRoutingLayoutMenu()

  return (
    <NestedDropdown
      placement="left"
      parentOpen={extractedMapOpen}
      triggerContent={texts.extractedMap}
    >
      <DropdownMenuItem onClick={focusExtractedMap} testid="menu-list-item">
        {texts.extractedMapGotoTab}
      </DropdownMenuItem>

      <DropdownMenuItem warn onClick={closeExtractedMap} testid="menu-list-item">
        {texts.extractedMapCloseTab}
      </DropdownMenuItem>
    </NestedDropdown>
  )
}
