import { useCallback } from 'react'

import { useNavigoView as useCoreNavigoView } from '@/atoms'

export const useNavigoView = () => {
  const [state, setState] = useCoreNavigoView('depots')

  const navigate = {
    goToOverview: useCallback(() => setState({ category: 'depots', view: 'overview' }), [setState]),
    goToMapStyle: useCallback(() => setState({ category: 'depots', view: 'mapStyle' }), [setState]),
  }

  if (state.category === 'none') {
    return { navigate, view: 'none' }
  }

  return {
    view: state.view,
    navigate,
  }
}
