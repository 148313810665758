import { FormatDate } from '@/components/smartUtils/time/FormatDate'
import { getColorValue } from '@/styles'

import { RouteTag } from '../../../../../../../../../components/RouteTag'
import { useSelectRoute } from './hooks/useRouteActions'
import { useRowNumber } from './hooks/useRowNumber'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

export function RouteBadge(props: Props) {
  const { extOrder } = props
  const selectRoute = useSelectRoute(extOrder)
  const rowNumber = useRowNumber(extOrder)

  if (extOrder.isUnassigned) return null

  return (
    <RouteTag
      testId="navigo-order-route-badge"
      onClick={selectRoute}
      backgroundColor={getColorValue(extOrder.color)}
    >
      {`${rowNumber} • `}
      <FormatDate source={extOrder.dateAsString} shortFormat={true} />
    </RouteTag>
  )
}
