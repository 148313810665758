import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    disabledForLoadingTooltip: translate({ id: 'global.gpsLoading.disableEdit' }),
    searchPlaceholder: translate({ id: 'gps.alerts.list.filterPlaceholder' }),
    yourAlerts: translate({ id: 'gps.alerts.yourAlerts' }),
    createNew: translate({ id: 'gps.alerts.createNew' }),
    entitiesCount: (selected: number, count: number): string =>
      translate({
        id:
          selected > 0 ? 'gps.alerts.list.footerCountWithSelection' : 'gps.alerts.list.footerCount',
        values: { count, selected },
      }),
    selectBtn: (allSelected: boolean): string =>
      translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' }),
    noEntries: {
      title: translate({ id: 'alerts.list.noEntries.title' }),
      description: translate({ id: 'alerts.list.noEntries.description' }),
      action: translate({ id: 'alerts.list.noEntries.action' }),
    },
    noResults: {
      title: translate({ id: 'alerts.list.noResults.title' }),
      description: translate({ id: 'alerts.list.noResults.description' }),
    },
  }))

  return api
}
