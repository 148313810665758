import { useCallback } from 'react'

import { useIntl } from '@/intl'

export function useCreateAlertLabel() {
  const { translate } = useIntl()

  return useCallback(
    (type: uui.domain.server.gps.wwgps.EventAlertType) => {
      switch (type) {
        case 10:
          return translate({ id: 'gps.alerts.types.ignition-on' })

        case 20:
          return translate({ id: 'gps.alerts.types.ignition-off' })

        case 30:
          return translate({ id: 'gps.alerts.types.idle-start' })

        case 40:
          return translate({ id: 'gps.alerts.types.idle-update' })

        case 50:
          return translate({ id: 'gps.alerts.types.idle-stop' })

        case 60:
          return translate({ id: 'gps.alerts.types.rapid-acceleration' })

        case 70:
          return translate({ id: 'gps.alerts.types.rapid-deceleration' })

        case 80:
          return translate({ id: 'gps.alerts.types.engine-warning-light' })

        case 90:
          return translate({ id: 'gps.alerts.types.device-power-reset' })

        case 100:
          return translate({ id: 'gps.alerts.types.user-button-press' })

        case 110:
          return translate({ id: 'gps.alerts.types.battery-level-report' })

        case 120:
          return translate({ id: 'gps.alerts.types.obd-port-plug-in' })

        case 125:
          return translate({ id: 'gps.alerts.types.obd-error' })

        case 130:
          return translate({ id: 'gps.alerts.types.device-unplug' })

        case 140:
          return translate({ id: 'gps.alerts.types.digital-input-1-low' })

        case 150:
          return translate({ id: 'gps.alerts.types.digital-input-1-high' })

        case 160:
          return translate({ id: 'gps.alerts.types.digital-input-2-low' })

        case 170:
          return translate({ id: 'gps.alerts.types.digital-input-2-high' })

        case 180:
          return translate({ id: 'gps.alerts.types.digital-input-3-low' })

        case 190:
          return translate({ id: 'gps.alerts.types.digital-input-3-high' })

        case 200:
          return translate({ id: 'gps.alerts.types.digital-input-4-low' })

        case 210:
          return translate({ id: 'gps.alerts.types.digital-input-4-high' })

        case 220:
          return translate({ id: 'gps.alerts.types.digital-input-5-low' })

        case 230:
          return translate({ id: 'gps.alerts.types.digital-input-5-high' })

        case 300:
          return translate({ id: 'gps.alerts.types.garmin-enabled' })

        case 310:
          return translate({ id: 'gps.alerts.types.garmin-disabled' })

        case 400:
          return translate({ id: 'gps.alerts.types.hard-braking' })

        case 410:
          return translate({ id: 'gps.alerts.types.harsh-braking' })

        case 420:
          return translate({ id: 'gps.alerts.types.severe-braking' })

        case 430:
          return translate({ id: 'gps.alerts.types.hard-acceleration' })

        case 440:
          return translate({ id: 'gps.alerts.types.harsh-acceleration' })

        case 450:
          return translate({ id: 'gps.alerts.types.severe-acceleration' })

        case 460:
          return translate({ id: 'gps.alerts.types.hard-cornering' })

        case 500:
          return translate({ id: 'gps.alerts.types.device-speed-alert' })

        case 505:
          return translate({ id: 'gps.alerts.types.max-speed-threshold' })

        case 510:
          return translate({ id: 'gps.alerts.types.moving-outside-hours' })

        case 520:
          return translate({ id: 'gps.alerts.types.geofence-trigger' })

        case 530:
          return translate({ id: 'gps.alerts.types.dtc-found' })

        case 540:
          return translate({ id: 'gps.alerts.types.vehicle-battery-low' })

        default:
          if (process.env.NODE_ENV === 'development') {
            throw new Error('No intl found for eventType')
          }
          return ''
      }
    },
    [translate],
  )
}
