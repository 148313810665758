import type { LayerUID } from './typings'
import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'

type FeatureMetadata = {
  type: LayerUID | 'breadcrumbStops'
}

export const setFeatureMetadata = <Field extends keyof FeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
  value: FeatureMetadata[Field],
) => {
  target.set(field, value)
}

export const getFeatureMetadata = <Field extends keyof FeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
): FeatureMetadata[Field] | undefined => {
  return target.get(field)
}

export const removeFeatureMetadata = <Field extends keyof FeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
) => {
  target.unset(field)
}
