import styled from 'styled-components'

export const InputRangeWrapper = styled.div`
  position: relative;
  flex: 0 1 auto;
  width: 100%;
  margin: 0 15px;
`

InputRangeWrapper.displayName = 'InputRangeWrapper'
