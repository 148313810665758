import { useSelector } from 'react-redux'

import { isSameDay, parseISO } from 'date-fns'
import { selectUserConfiguration } from '@/features/domain/user'
import { timeUtils } from '@/server-data'
import {
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyLabel,
} from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'
import { neverEndingDateTime } from '../../../../../constants'

interface Props {
  startTime: string
  endTime: string
}

export function StartEndDateTime(props: Props) {
  const { startTime, endTime } = props

  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const endTimeIsSet = !!endTime && !isSameDay(parseISO(endTime), parseISO(neverEndingDateTime))
  const endTimeTitle = endTimeIsSet ? texts.dateTimeEnd : texts.noDateTimeEnd

  return (
    <>
      <ReadonlyBlock half>
        <ReadonlyLabel empty={!startTime}>{texts.dateTimeStart}</ReadonlyLabel>
        {!!startTime && (
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>
                {timeUtils.formatDateTime(userConfig)(parseISO(startTime))}
              </ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        )}
      </ReadonlyBlock>

      <ReadonlyBlock half>
        <ReadonlyLabel empty={!endTimeIsSet}>{endTimeTitle}</ReadonlyLabel>
        {endTimeIsSet && (
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>
                {timeUtils.formatDateTime(userConfig)(parseISO(endTime))}
              </ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        )}
      </ReadonlyBlock>
    </>
  )
}
