import OlMap from 'ol/Map'
import ScaleLine from 'ol/control/ScaleLine'

const uniqueId = 'rm-scale'

export const registerScaleControl = (map: OlMap, useMetricSystem: boolean) => {
  const controls = map.getControls()

  if (controls.get(uniqueId)) return

  const scaleControl = new ScaleLine({
    units: useMetricSystem ? 'metric' : 'us',
  })
  scaleControl.set('uid', uniqueId)
  map.addControl(scaleControl)

  controls.set(uniqueId, scaleControl)
}

export const refreshScaleControl = (map: OlMap, useMetricSystem: boolean) => {
  const controls = map.getControls()

  const ctrl = controls.get(uniqueId) as ScaleLine

  if (!ctrl) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('Trying to refresh Map Scale Control before creating it')
    }
    return
  }

  // try to update the unit system before leaving
  ctrl.setUnits(useMetricSystem ? 'metric' : 'us')
}
