import { snapshot, subscribe } from 'valtio'

import { editableRoadSegment } from './editableRoadSegment'

type Subscriber = (roadSegment: uui.domain.ui.map.EditableRoadSegment) => void

export function subscribeToEditableRoadSegment(subscriber: Subscriber, sync = true) {
  return subscribe(
    editableRoadSegment,
    () => subscriber(snapshot(editableRoadSegment.roadSegment)),
    sync,
  )
}
