import styled from 'styled-components'

type Props = {
  empty?: boolean
}

export const ReadonlyLabelSup = styled.sup<Props>`
  color: ${p => (p.empty ? p.theme.colors.$silver : p.theme.colors.$nightRider)};
  font-size: ${p => p.theme.fonts.$xs};
  font-weight: ${p => p.theme.weights.$semiBold};
  text-transform: uppercase;
`

ReadonlyLabelSup.displayName = 'ReadonlyLabelSup'
