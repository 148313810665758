import { useCallback } from 'react'

import {
  SecondaryColumnHeader,
  SecondaryColumnContentHorizontalRuler,
  SecondaryColumnContentContainer,
  HorizontalRuler,
  FormContent,
} from '@/forms-legacy'

import { FormColumn } from '@/components/layout'
import { usePendingFitMapOnMount } from '@/map'
import { resetCrudSelection } from '@/atoms'

import { DeviceAvatar } from '../../components/DeviceAvatar'
import { GpsDataViewToggler } from '../../components/GpsDataViewToggler'

import { GpsData } from './components/GpsData'

import { useCanRenderGpsDataToggler } from './hooks/useCanRenderGpsDataToggler'
import { useGpsDataCollapsed } from './hooks/useGpsDataCollapsed'
import { useCanEditGpsData } from './hooks/useCanEditGpsData'
import { useTexts } from './hooks/useTexts'

import { Actions } from './components/Actions'

interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
}

export function SingleView(props: Props) {
  const { device } = props

  // When the read-only view mounts
  // try to apply pending fitMap requests
  usePendingFitMapOnMount()

  const deviceId = device.deviceId
  const deviceLabel = device.label

  const resetSelection = useCallback(() => {
    resetCrudSelection('devices')
  }, [])

  const { collapsed, toggleCollapsed } = useGpsDataCollapsed(deviceId)

  const canRenderToggler = useCanRenderGpsDataToggler(device)
  const canEditGpsData = useCanEditGpsData()
  const texts = useTexts()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={deviceLabel}
        description={texts.headerDescription(deviceId)}
        action={resetSelection}
        avatar={<DeviceAvatar device={device} marginRight={16} size={42} />}
        data-testid="vehicles-readonly-header"
      />
      {canEditGpsData && <Actions device={device} />}
      <FormContent footer={<div />}>
        <SecondaryColumnContentContainer>
          {canEditGpsData && <SecondaryColumnContentHorizontalRuler bigBottomSpace />}
          {canRenderToggler && (
            <GpsDataViewToggler
              title={texts.expand}
              description={texts.deviceSettings(deviceId)}
              collapsed={collapsed}
              onToggle={toggleCollapsed}
            />
          )}
          {canRenderToggler && !collapsed && <GpsData device={device} />}

          <HorizontalRuler style={{ height: 0, marginBottom: 60 }} />
        </SecondaryColumnContentContainer>
      </FormContent>
    </FormColumn>
  )
}
