import { useCloseModal, useShowModal } from '@/atoms'

import { Root } from './components/Root'
import { modalId } from './hooks/useController'

export function useDeleteDriverModal() {
  const show = useShowModal(modalId)
  const close = useCloseModal()

  return {
    show,
    close,
    Modal: Root,
  }
}
