import type { ReactNode, RefObject, ReactElement } from 'react'

import { IconContainer } from './components/IconContainer'
import { MenuItem } from './components/MenuItem'
import { Content } from './components/Content'
import { Arrow } from './components/Arrow'

interface Props {
  open: boolean
  disabled?: boolean
  anchorElRef: RefObject<HTMLLIElement>
  Icon?: ReactElement
  children?: ReactNode
  fontSize?: number
  fontWeight?: number
}

export function TriggerContent(props: Props) {
  const {
    children,
    anchorElRef,
    Icon,
    open,
    disabled = false,
    fontWeight = 600,
    fontSize = 16,
  } = props

  return (
    <MenuItem ref={anchorElRef} disabled={disabled} open={open}>
      <Content fontSize={fontSize} fontWeight={fontWeight}>
        {children}

        <IconContainer className="iconContainer">{Icon}</IconContainer>
        <Arrow className="arrow" size={12} color="$nightRider" />
      </Content>
    </MenuItem>
  )
}
