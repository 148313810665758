import { ActionBarItem } from '@workwave-tidal/components/ActionBar'
import { Close } from '@workwave-tidal/icons'
import { useFormatSeconds } from '@/hooks'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

function BulkHeader() {
  const texts = useTexts()
  const {
    close,
    data: { selectedOrderSteps },
  } = useController()

  const title = texts.titleBulk
  const subtitle = texts.subtitleBulk(selectedOrderSteps.ids.length)

  return (
    <ModalHeader
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      title={title}
      subtitle={subtitle}
    />
  )
}

function SingleHeader() {
  const texts = useTexts()
  const {
    close,
    data: { selectedOrderSteps },
  } = useController()

  const {
    orderStep,
    orderStepType,
    order: { name },
  } = selectedOrderSteps.orderSteps[selectedOrderSteps.ids[0]]

  const title = texts.title(orderStepType)
  const serviceTime = useFormatSeconds(orderStep.serviceTimeSec)
  const subtitle = texts.subtitle({ name, serviceTime })

  return (
    <ModalHeader
      title={title}
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      subtitle={subtitle}
    />
  )
}

export function InvalidHeader() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalHeader
      title={texts.emptyOrdersTitle}
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
    />
  )
}

export function Header() {
  const {
    invalid,
    data: { mode },
  } = useController()

  if (invalid) return <InvalidHeader />

  return mode === 'bulk' ? <BulkHeader /> : <SingleHeader />
}
