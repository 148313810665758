import type { NotificationFormType } from '@/notificationForms'

import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'
import { Body } from './Body'
import { Header } from './Header'
import { Footer } from './Footer'

interface Props {
  notification: NotificationFormType
  goingToEnable: boolean
  onToggle: () => void
  subtitle: string
  title: string
}

export function Content(props: Props) {
  const { title, subtitle, notification, onToggle, goingToEnable } = props
  const { ready } = useConfigureController(title, subtitle, notification, onToggle, goingToEnable)

  if (!ready) return null

  return (
    <ModalContent header={<Header />} footer={<Footer />}>
      <Body />
    </ModalContent>
  )
}
