import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'sms4.settings.preferencesPage.title' }),
    headerDescription: translate({ id: 'sms4.settings.preferencesPage.description' }),
    cardTitle: translate({
      id: 'sms4.settings.preferencesPage.fields.notificationLanguage.card.title',
    }),
    cardDescription: translate({
      id: 'sms4.settings.preferencesPage.fields.notificationLanguage.card.description',
    }),
    notificationLanguage: translate({
      id: 'sms4.settings.preferencesPage.fields.notificationLanguage.label',
    }),
    dateFormat: translate({ id: 'sms4.settings.preferencesPage.fields.dateFormat.label' }),
    dateFormatExample: (dateFormat: uui.domain.client.rm.NotificationConfig['dateFormat']) =>
      translate({
        id:
          dateFormat === 'DMMM'
            ? 'sms4.settings.preferencesPage.fields.dateFormat.example.DMMM'
            : 'sms4.settings.preferencesPage.fields.dateFormat.example.MMMD',
      }),
    timeFormat: translate({ id: 'sms4.settings.preferencesPage.fields.timeFormat.label' }),
    timeFormatExample: (timeFormat: uui.domain.client.rm.NotificationConfig['timeFormat']) =>
      translate({
        id:
          timeFormat === 'H12'
            ? 'sms4.settings.preferencesPage.fields.timeFormat.example.H12'
            : 'sms4.settings.preferencesPage.fields.timeFormat.example.H24',
      }),
    companyFromName: translate({
      id: 'sms4.settings.preferencesPage.fields.companyFromName.label',
    }),

    edit: translate({ id: 'sms4.settings.preferencesPage.edit' }),
    save: translate({ id: 'sms4.settings.preferencesPage.save' }),
    cancel: translate({ id: 'sms4.settings.preferencesPage.cancel' }),
  }))

  return api
}
