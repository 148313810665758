import type { ImportWizardCookieData } from '@/utils'

import { importWizardSession } from '@/utils'

export function resumeImportWizardFlow(importWizardState: ImportWizardCookieData) {
  const { step } = importWizardState

  if (step !== 'importCompleted' && step !== 'importCanceled') return

  const sessionTokenFromCookie = importWizardState.webSessionId

  switch (importWizardState.mode) {
    case 'operations':
      return {
        type: 'importWizard',
        territoryId: importWizardState.territoryId,
        txnIdFromCookie: importWizardState.txnId,
        customCalendarRange: importWizardState?.calendarRange ?? undefined,
        sessionTokenFromCookie,
      } as const

    case 'simulation':
      importWizardSession.deleteCookie()

      return {
        type: 'importWizard',
        territoryId: importWizardState.territoryId,
        simulationId: importWizardState.simulationId,
        sessionTokenFromCookie,
      } as const
  }
}
