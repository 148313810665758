import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'orders.smsNotifications.voiceCall.title' }),
    byDriver: (driverName: string, phoneNumber: string) =>
      translate({
        id: 'orders.smsNotifications.voiceCall.byDriver',
        values: { driverName, phoneNumber },
      }),
    show: translate({ id: 'orders.smsNotifications.voiceCall.show' }),
    hide: translate({ id: 'orders.smsNotifications.voiceCall.hide' }),
    noGpsDataAvailable: translate({ id: 'orders.smsNotifications.voiceCall.noGps' }),
  }))

  return api
}
