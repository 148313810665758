import { gis } from '@/server-data'

export const getEmptyLocation = (
  territoryCenter: uui.domain.LatLng,
  latLngFromMapPin?: uui.domain.LatLng,
): uui.domain.client.Location => {
  const lat = latLngFromMapPin ? latLngFromMapPin.lat : territoryCenter.lat
  const lng = latLngFromMapPin ? latLngFromMapPin.lng : territoryCenter.lng
  const latLng = gis.createLatLng(lat, lng)

  return {
    latLng,
    address: '',
    status: 'OK',
    geoAddress: '',
    source: 'MANUAL',
    rawLatLngAsString: '',
  }
}
