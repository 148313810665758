import styled from 'styled-components'

const styledComponent = styled.input.attrs(() => ({
  type: 'radio',
  className: 'o-radio-input',
}))`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 10;
  user-select: none;
  cursor: pointer;
`
styledComponent.displayName = 'RadioInput'
export default styledComponent
