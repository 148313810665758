import { ReactElement, useCallback } from 'react'

import { Block, FlexRow, FlexColumn, Label, HourPicker } from '@/forms-legacy'

import DurationStep from './elements/DurationStep'

import { Props } from './typings'

const Break = (props: Props): ReactElement => {
  const { onChange, value, labelDuration, labelStart, labelEnd, timeFormat } = props

  const handleOnChangeDuration = useCallback(
    (durationSec: number) => {
      onChange({
        ...value,
        durationSec,
      })
    },
    [value, onChange],
  )

  const handleOnChangeStart = useCallback(
    (startSec: number) => {
      onChange({
        ...value,
        startSec,
      })
    },
    [value, onChange],
  )

  const handleOnChangeEnd = useCallback(
    (endSec: number) => {
      onChange({
        ...value,
        endSec,
      })
    },
    [value, onChange],
  )

  const { durationSec: duration, startSec: start, endSec: end } = value

  const h24 = timeFormat === 'HH:mm'

  return (
    <FlexRow>
      <FlexColumn primary>
        <FlexRow>
          <Block half>
            <Label>{labelDuration}</Label>
            <DurationStep
              onChange={handleOnChangeDuration}
              value={duration}
              name="duration"
              step={5}
              min={0}
            >
              hh:mm
            </DurationStep>
          </Block>
        </FlexRow>
        <FlexRow justifyContent="space-between" data-testid="break-time-window">
          <Block half>
            <Label>{labelStart}</Label>
            <HourPicker name="start" value={start} onChange={handleOnChangeStart} h24={h24} />
          </Block>
          <Block half>
            <Label>{labelEnd}</Label>
            <HourPicker name="end" value={end} onChange={handleOnChangeEnd} h24={h24} />
          </Block>
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  )
}

export default Break
