import { Component } from 'react'

import { ReadonlyLabel } from '@/forms-legacy'

import Collapsed from './components/Collapsed'
import Search from './components/Search'
import SuggestionsList from './components/SuggestionsList'
import Tags from './components/Tags'
import TagsListWrapper from './components/TagsListWrapper'
import TagPlaceholder from './components/TagPlaceholder'
import PartialTags from './components/PartialTags'

import { Props, Step, PublicPropsBulk } from './typings'

interface State {
  step: Step
}

const setStep = (step: Step) => () => ({
  step,
})

const defaultMax: number = 20

export default class TagsList extends Component<Props, State> {
  state: State = {
    step: Step.collapsed,
  }

  private handleOnChangeStep = () => {
    this.setState(setStep(Step.search))
  }

  render() {
    const { step } = this.state
    const { suggestions, value, maxCount = defaultMax, onRemoveTag, type, renderText } = this.props

    const hasReachedMax: boolean = Object.keys(value).length >= maxCount
    const hasNoTags: boolean = Object.keys(value).length === 0

    const bulkProps: PublicPropsBulk = this.props as PublicPropsBulk

    return (
      <TagsListWrapper data-testid="tags__list-wrapper">
        {(step === Step.collapsed || hasReachedMax) && (
          <Collapsed
            onChangeStep={this.handleOnChangeStep}
            disabled={hasReachedMax}
            {...this.props}
          />
        )}

        {step === Step.search && !hasReachedMax && <Search {...this.props} />}

        {type === 'bulk' && (
          <ReadonlyLabel primary style={{ marginTop: 10 }}>
            {renderText('bulkTitle', this.props)}
          </ReadonlyLabel>
        )}

        {type === 'bulk' && hasNoTags && (
          <TagPlaceholder>{renderText('noTags', this.props)}</TagPlaceholder>
        )}

        <Tags list={value} onDeleteTag={onRemoveTag} {...this.props} />

        {step === Step.search && !hasReachedMax && (
          <SuggestionsList list={suggestions} {...this.props} />
        )}

        {type === 'bulk' && <PartialTags {...bulkProps} renderText={renderText} />}
      </TagsListWrapper>
    )
  }
}
