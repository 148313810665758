import type Geometry from 'ol/geom/Geometry'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { setDeviceEventFeatureMetadata } from '../../deviceEventFeatureMetadata'
import { getDeviceEventPointsFeatureStyle } from './getDeviceEventPointsFeatureStyle'

export function createDeviceEventPointsFeatures(
  marker: uui.domain.ui.map.markers.DeviceEvents,
  selection: Set<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
): Feature<Geometry>[] {
  const features: Feature<Geometry>[] = []

  //  feature
  for (const eventId in marker.events) {
    const event = marker.events[eventId]
    const feature = new Feature({ geometry: new Point(event.lonLat) })

    setDeviceEventFeatureMetadata(feature, 'type', 'deviceEvent')

    feature.setStyle(
      getDeviceEventPointsFeatureStyle(event, selection.has(eventId), breadcrumbTimeRange),
    )
    feature.setId(event.id)

    features.push(feature)
  }

  return features
}
