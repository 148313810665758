import { Entry } from '../Entry'

export function Entry20231025() {
  return (
    <Entry title="Oct 25 2023 - v3.5.3">
      Link to the{' '}
      <a
        href="https://workwave.atlassian.net/projects/RM/versions/14519/tab/release-report-all-issues"
        target="_blank"
        rel="noreferrer"
      >
        Jira Release
      </a>
    </Entry>
  )
}
