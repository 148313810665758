import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { setDeviceEventFeatureMetadata } from '../../deviceEventFeatureMetadata'
import { getDeviceEventPointsFeatureStyle } from './getDeviceEventPointsFeatureStyle'

export function updateDeviceEventPointsFeature(
  feature: Feature<Geometry>,
  event: uui.domain.ui.map.markers.DeviceEvent,
  selected: boolean,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
): Feature<Geometry> {
  // ------------------------
  // Feature Metadata
  // ------------------------

  setDeviceEventFeatureMetadata(feature, 'type', 'deviceEvent')

  // ------------------------
  // Feature Style
  // ------------------------

  const style = getDeviceEventPointsFeatureStyle(event, selected, breadcrumbTimeRange)
  feature.setStyle(style)

  return feature
}
