import { ResourceModel } from '@bryntum/schedulerpro'
import { appLanguageAtom } from '../../../../atoms'

export function driverSorter(item1: ResourceModel, item2: ResourceModel) {
  const driverName1 = item1.getData(
    'driverName',
  ) as uui.domain.client.rm.SchedulerResource['driverName']

  const driverName2 = item2.getData(
    'driverName',
  ) as uui.domain.client.rm.SchedulerResource['driverName']

  return driverName1.current.localeCompare(driverName2.current, appLanguageAtom.settings.language, {
    numeric: true,
    ignorePunctuation: false,
  })
}
