import { useEffect } from 'react'

import { refreshGpsData } from '@/features/domain/gps'
import { useAppDispatch, useJournal } from '@/store'

type GpsEntityToRefresh = 'unifiedVehicles' | 'geofence' | 'alert' | 'places'

export function useRefreshGpsData(entity: GpsEntityToRefresh) {
  const dispatch = useAppDispatch()
  const journal = useJournal()

  useEffect(() => {
    async function requestGpsDataRefresh() {
      try {
        const gpsEntities = getForceGpsEntities(entity, journal)
        if (!gpsEntities) throw new Error('The requested entity does not exists')

        const result = await dispatch(refreshGpsData(gpsEntities))

        if (!refreshGpsData.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (error) {
        // TODO: journal log
        journal.main(`Fetching GPS data went wrong`, { info: { entity, error } }, 'warn')
      }
    }

    requestGpsDataRefresh()
  }, [dispatch, entity, journal])
}

function getForceGpsEntities(entity: GpsEntityToRefresh, journal) {
  switch (entity) {
    case 'unifiedVehicles':
      return {
        driverBehaviorSettings: true,
        deviceConfiguration: true,
        deviceInfo: true,
        eventAlert: true,
        deviceTag: true,
        eventInfo: true,
        tag: true,
      }

    case 'geofence':
      return {
        geofence: true,
      }

    case 'alert':
      return {
        eventAlert: true,
      }

    case 'places':
      return {
        places: true,
      }

    default:
      journal.main(`Unknown entity`, { info: { entity } }, 'error')
  }
}
