import { useSelector } from 'react-redux'

import { selectCurrentPlanSnapshot } from '@/features/domain/history'

import { VehiclePlanSummary } from './components/VehiclePlanSummary'
import { OrderPlanSummary } from './components/OrderPlanSummary'
import { RoutePlanSummary } from './components/RoutePlanSummary'
import { Container } from './components/Container'
import { Section } from './components/Section'
import { Cost } from './components/Cost'

export function PlanSummary() {
  const snapshot = useSelector(selectCurrentPlanSnapshot)

  if (!snapshot) return null

  return (
    <Container>
      <Section>
        <Cost snapshot={snapshot} />
        <OrderPlanSummary snapshot={snapshot} />
        <VehiclePlanSummary snapshot={snapshot} />
        <RoutePlanSummary snapshot={snapshot} />
      </Section>
    </Container>
  )
}
