import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
} from '@/forms-legacy'

import { useTexts } from './useTexts'

interface Props {
  deviceConfiguration: uui.domain.client.gps.wwgps.DeviceConfiguration
}

export function MaintenanceMileage(props: Props) {
  const { deviceConfiguration } = props

  const texts = useTexts()

  const hasDeviceConfiguration: boolean =
    deviceConfiguration && !!deviceConfiguration.maintenanceMileageMethod

  if (!hasDeviceConfiguration) {
    return null
  }

  const fieldValue =
    deviceConfiguration.maintenanceMileageMethod === 'preferOdometer'
      ? texts.odometer
      : texts.ignoreOdometer

  return (
    <ReadonlyBlock>
      <ReadonlyLabel>{texts.maintenanceMileage}</ReadonlyLabel>
      <ReadonlyField>
        <ReadonlyFieldRow>
          <ReadonlyFieldLabel>{fieldValue}</ReadonlyFieldLabel>
        </ReadonlyFieldRow>
      </ReadonlyField>
    </ReadonlyBlock>
  )
}
