import type Geometry from 'ol/geom/Geometry'
import type { VoiceEventMarker } from '../../../../atoms/voiceEventMarkers/voiceEventMarker'

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { getVoiceEventFeatureStyle } from './getVoiceEventFeatureStyle'

export function refreshVoiceEventFeature(
  marker: workwave.DeepReadonly<VoiceEventMarker>,
  feature: Feature<Geometry>,
) {
  const { id, lonLat } = marker

  // ------------------------
  // Feature Map position
  // ------------------------

  const point = feature.getGeometry() as Point

  if (!point) {
    throw new Error(`Trying to update Order Voice Event Marker [${id}] without a valid geometry.`)
  }

  point.setCoordinates(Array.from(lonLat))

  // ------------------------
  // Feature Style

  const style = getVoiceEventFeatureStyle(marker)
  feature.setStyle(style)

  return feature
}
