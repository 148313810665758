import { type Grouper } from '@bryntum/schedulerpro'

export const dateGrouper: Grouper = {
  field: 'dateAsString',
  ascending: true,
}
export const vehicleGrouper: Grouper = {
  field: 'vehicleName',
  ascending: true,
}
