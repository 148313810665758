import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Left(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M34.96094 2.98047c-.51953.01562-1.01172.23437-1.375.60547l-20 20c-.78125.78125-.78125 2.04687 0 2.82812l20 20c.5.52344 1.2461.73438 1.94922.55078.69922-.1836 1.2461-.73047 1.42968-1.42968.1836-.70313-.02734-1.44922-.55078-1.94922L17.82813 25 36.41406 6.41406c.58985-.57422.76953-1.45312.44922-2.21484-.32422-.7578-1.07812-1.2422-1.90234-1.21875z" />
      </svg>
    </IconContainer>
  )
}
