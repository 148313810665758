import type { ReactElement } from 'react'

import { Box, Typography } from '@mui/material'

interface Props {
  children: ReactElement | string
  label: string
}

export function Field(props: Props) {
  return (
    <Box paddingBottom={4} data-testid={props.label}>
      <Typography variant="caption" color="textSecondary">
        {props.label}
      </Typography>
      <Typography variant="body1">{props.children}</Typography>
    </Box>
  )
}
