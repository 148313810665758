import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'
import { findFeature } from '../../findFeature'

import { findDepotMarker } from './findDepotMarker'
import { updateDepotFeature } from './updateDepotFeature'

const nonblockingRender = createNonBlockingRenderByTime()

export const changelogUpdateDepotFeatures = async (
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.Depot>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['depot'],
  selection: Set<string>,
  markerIds: IterableIterator<string>,
) => {
  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findDepotMarker(markers, markerId)
    if (!marker) {
      throw new Error(
        `Trying to update Depot feature with lat-lng: ${markerId} but no marker exist on the map`,
      )
    }

    const feature = findFeature(layer, marker.id)

    if (!feature) {
      throw new Error(
        `Trying to update Depot feature with lat-lng: ${marker.id} but no feature exist on the map`,
      )
    }

    const selected = selection.has(markerId)
    updateDepotFeature(mapStyles, marker, feature, selected)
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
