import styled from 'styled-components'

export const RoundedButton = styled.button`
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 10;
  transform: translateX(50%);
  padding: 0;
  margin: 0;
  outline: 0;
  border-width: 0;
  width: 22px;
  height: 22px;
  background: ${p => p.theme.colors.$pureWhite};
  color: ${p => p.theme.colors.$nightRider};
  border-radius: 50%;
  box-shadow: 0 0 3px 0 ${p => p.theme.colors.$nightRider};

  transition: opacity 0.2s ease, background 0.2s ease, color 0.2s ease;

  &:hover {
    background: ${p => p.theme.colors.$scienceBlue};
    color: ${p => p.theme.colors.$pureWhite};
  }

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

RoundedButton.displayName = 'RoundedButton'
