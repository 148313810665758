import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const {
    close,
    status,
    invalid,
    data: { isForceFitting },
  } = useController()
  const { onForceFitIn, onBestFitIn } = useControllerActions()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          onClick={isForceFitting ? onForceFitIn : onBestFitIn}
          color={isForceFitting ? 'error' : 'primary'}
          disabled={invalid || status !== 'ready'}
          testid="modal__submit-primary-button"
          trackid={isForceFitting ? 'fit-in-force-assign-btn' : 'fit-in-assign-orders-btn'}
          loading={status === 'submitting'}
          variant="contained"
        >
          {isForceFitting ? texts.btnForceFitIn : texts.btnTryFitIn}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          onClick={close}
          testid="modal__cancel-button"
          disabled={invalid || status === 'submitting'}
        >
          {status === 'invalidForAlreadyAssigned' ? texts.btnBackToOrder : texts.btnCancel}
        </ModalFooterButton>
      }
    />
  )
}
