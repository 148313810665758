import type { ModalMode } from './hooks/useController'

import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    cancelButtonTitle: translate({ id: 'deleteOrderModal.cancelButton.title' }),
    deleteButtonTitle: translate({ id: 'deleteOrderModal.deleteButton.title' }),
    deleteAlso: (type: string) =>
      translate({
        id:
          type === 'p'
            ? 'deleteOrderModal.deleteAlso.dropoff'
            : 'deleteOrderModal.deleteAlso.pickup',
      }),
    deleteAlsoBulk: translate({ id: 'deleteOrderModal.deleteAlso.bulk' }),
    genericDeleteOrderError: translate({ id: 'deleteOrderModal.error.generic' }),
    emptyOrdersTitle: translate({ id: 'deleteOrderModal.title.emptyOrders' }),
    warningCardEmptyOrdersTitle: translate({ id: 'deleteOrderModal.invalid.emptyOrders.title' }),
    warningCardEmptyOrdersDesc: translate({
      id: 'deleteOrderModal.invalid.emptyOrders.description',
    }),
    title: (type: string) =>
      translate({
        id: type === 'p' ? 'deleteOrderModal.title.pickup' : 'deleteOrderModal.title.dropoff',
      }),
    titleBulk: translate({ id: 'deleteOrderModal.title.bulk' }),
    subtitle: ({ name, serviceTime }: { name: string; serviceTime: string | null }) =>
      !!serviceTime
        ? translate({
            id: 'deleteOrderModal.subtitle.serviceTime',
            values: { name, serviceTime },
          })
        : translate({
            id: 'deleteOrderModal.subtitle',
            values: { name },
          }),
    subtitleBulk: (ordersAmount: number) =>
      translate({
        id:
          ordersAmount > 1
            ? 'deleteOrderModal.subtitle.bulk.plural'
            : 'deleteOrderModal.subtitle.bulk.singular',
        values: { ordersAmount },
      }),
    transactionWarningCardTitle: translate({
      id: 'deleteOrderModal.transaction.warningCard.title',
    }),
    transactionWarningCardDescription: translate({
      id: 'deleteOrderModal.transaction.warningCard.description',
    }),
    warningCardTitle: (mode: ModalMode) =>
      translate({
        id:
          mode === 'bulk'
            ? 'deleteOrderModal.warningCard.title.plural'
            : 'deleteOrderModal.warningCard.title.singular',
      }),
    warningCardDescription: translate({ id: 'deleteOrderModal.warningCard.description' }),
  }))

  return api
}
