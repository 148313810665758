import type { Props } from '../typings'

import { Stack } from '@mui/material'

import FlexColumn from '../../lib/FlexColumn'

import { getLabelFromLocation } from '../utils'
import TransparentButton from '../elements/TransparentButton'
import FieldContainer from '../elements/FieldContainer'

import LocationEditorTextInput from './LocationEditorTextInput'
import AdditionalModes from './AdditionalModes'
import WarningCard from './WarningCard'
import Icon from './Icon'

function noop() {}

export const CollapsedState = (props: Props) => {
  const { testId, status = 'ok', location, openList, collapsed } = props

  return (
    <Stack spacing={2} alignItems="flex-start">
      <FieldContainer interactive>
        <Icon {...props} collapsed={collapsed} />

        <FlexColumn primary>
          <LocationEditorTextInput
            data-trackid={testId}
            data-testid={testId}
            readOnly={collapsed}
            onChange={noop}
            value={getLabelFromLocation(location)}
          />
        </FlexColumn>
        <TransparentButton onClick={openList} data-testid="location-editor__transparent-button" />
      </FieldContainer>

      {status !== 'ok' && <WarningCard {...props} />}

      <AdditionalModes {...props} />
    </Stack>
  )
}
