import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'scheduler.popOver.timeLeft.title' }),
    unlimited: translate({ id: 'scheduler.popOver.timeLeft.unlimited' }),
    workingTime: (value: string, total: string, saturation: number) =>
      translate({
        id: 'scheduler.popOver.timeLeft.workingTime',
        values: { value, total, saturation },
      }),
    drivingTime: (value: string, total: string, saturation: number) =>
      translate({
        id: 'scheduler.popOver.timeLeft.title.drivingTime',
        values: { value, total, saturation },
      }),
  }))

  return api
}
