import type { Theme } from '@mui/material'

import { makeStyles } from '@mui/styles'
import { theme } from '@/styles'

interface Props {
  color: string
  withPlus?: boolean
}

const useStyles = makeStyles<Theme, Props>({
  circle: props => ({
    width: 15,
    height: 15,
    backgroundColor: props.color,
    borderRadius: 8,
    position: 'relative',
    border: `2px solid ${theme.colors.$alabaster}`,

    '&::after': {
      content: '"+"',
      color: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      fontSize: 15,
      lineHeight: 14,
      transform: 'translate(-50%,-50%)',
      display: props.withPlus ? 'block' : 'none',
    },
  }),
})

export function RouteCircle(props: Props) {
  const classes = useStyles(props)

  return <div className={classes.circle}></div>
}
