import type { Props } from './typings'

import { useState, useCallback, useMemo, useRef } from 'react'
import { Menu } from '@mui/material'

import { FlexBox } from '@/local/components'

import { DropdownHead } from './elements/DropdownHead'
import { StatusIndicator } from './elements/StatusIndicator'
import { Options } from './elements/Options'
import { MouseInteraction } from './elements/MouseInteraction'
import { getDropDownItemForHead, getSortedList } from './utils'
import { useFixPopupPosition } from './hooks/useFixPopupPosition'

export const Dropdown = (props: Props) => {
  const {
    initiallyExpanded,
    itemRenderPreset = 'default',
    maxListHeight = 100,
    enableFocus,
    disabled,
    options,
    testId = 'dropdown-container',
  } = props

  const ref = useRef<HTMLButtonElement | null>(null)
  const actionsRef = useFixPopupPosition()

  const [collapsed, setCollapsed] = useState(!initiallyExpanded)
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const sortedList = useMemo(() => getSortedList(options), [options])

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed, setCollapsed])

  const closeList = useCallback(() => {
    setCollapsed(true)
  }, [setCollapsed])

  const listVisible = !collapsed && !disabled
  const headItem = getDropDownItemForHead(props)

  return (
    <MouseInteraction testId={testId}>
      <FlexBox vAlignContent="center" w="100%">
        <FlexBox as="header" column grow position={'relative'}>
          <DropdownHead
            ref={ref}
            disabled={disabled}
            Icon={headItem.Icon}
            enableFocus={enableFocus}
            onClick={toggleCollapsed}
            data-testid="dropdown-trigger"
          >
            {headItem.label}
          </DropdownHead>

          <Menu
            open={listVisible}
            id="dropdown-menu"
            action={actionsRef}
            anchorEl={ref.current}
            onClose={closeList}
            variant="selectedMenu"
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            PaperProps={{
              elevation: 2,
              style: { width: ref.current?.offsetWidth },
            }}
            MenuListProps={{
              style: { paddingTop: 0, paddingBottom: 0 },
            }}
          >
            <Options
              {...props}
              data-testid="dropdown-list-container"
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              itemRenderPreset={itemRenderPreset}
              maxListHeight={maxListHeight}
              closeList={closeList}
              sortedList={sortedList}
            />
          </Menu>
        </FlexBox>
        <FlexBox column marginLeft={2}>
          <StatusIndicator
            onClick={toggleCollapsed}
            collapsed={collapsed}
            disabled={disabled}
            enableFocus={enableFocus}
          />
        </FlexBox>
      </FlexBox>
    </MouseInteraction>
  )
}

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
} as const

const transformOrigin = {
  vertical: -6,
  horizontal: 0,
} as const
