import { useSelector } from 'react-redux'

import { useMemo } from 'react'
import { selectOrderSteps } from '@/features/domain/order'

import { getColorValue } from '@/styles'

export function useDotColor(orderStepId: string) {
  const extOrderSteps = useSelector(selectOrderSteps)
  const extOrderStep = extOrderSteps[orderStepId]

  return useMemo(() => {
    if (!extOrderStep || extOrderStep.isUnassigned) return

    return getColorValue(extOrderStep.color)
  }, [extOrderStep])
}
