import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { AccordionSummary, AccordionDetails, Accordion, Box } from '@mui/material'

import { generateRelativePath, Link } from '@/routing'
import { selectUserConfiguration } from '@/features/domain/user'
import { MobileGear, Down } from '@/icons'
import { Tooltip } from '@/components/primitives/Tooltip'

import { MainLink } from '../../../MainLink'
import { LinkList } from '../LinkList'
import { SubLink } from '../SubLink'

import { useDriverMobileAppCollapsed } from '../../hooks/useCollapseStatus'

import { useTexts } from './useTexts'

type SetupRoutes = uui.routing.Setup

export function DriverMobileApp() {
  const { pathname } = useLocation()
  const { userType } = useSelector(selectUserConfiguration)
  const path = generateRelativePath(pathname) as uui.routing.Setup
  const [collapsed, setCollapsed, toggleCollapsed] = useDriverMobileAppCollapsed()

  useEffect(() => {
    switch (path) {
      case 'driver-assistance':
      case 'driver-activity':
        setCollapsed(false)
        break
    }
  }, [setCollapsed, path])

  const texts = useTexts()

  if (userType === 'rmOnly')
    return (
      <Tooltip title={texts.disabledSectionTooltip} placement="top">
        <MainLink
          disabled
          summary
          testid="setup-driverMobileAppMenu-expand"
          Icon={<MobileGear size={20} color="$silver" />}
          text={texts.driverMobileApp}
        />
      </Tooltip>
    )

  return (
    <Box marginTop={1} marginBottom={1}>
      <Accordion
        data-trackid="setup-driverMobileAppMenu"
        data-testid="setup-driverMobileAppMenu"
        expanded={!collapsed}
      >
        <AccordionSummary
          expandIcon={<Down size={13} color="$nightRider" />}
          onClick={toggleCollapsed}
        >
          <MainLink
            Icon={<MobileGear size={20} color="$nightRider" />}
            testid="setup-driverMobileAppMenu-expand"
            text={texts.driverMobileApp}
            summary
          />
        </AccordionSummary>

        <AccordionDetails>
          {
            // @ts-expect-error
            <LinkList component="nav">
              <Link<SetupRoutes> to="driver-assistance">
                <SubLink
                  testid="setup-driverMobileAppMenu-driverAssistance"
                  text={texts.driverAssistance}
                  fontSize="$m"
                />
              </Link>

              <Link<SetupRoutes> to="driver-activity">
                <SubLink
                  testid="setup-driverMobileAppMenu-reasonsForNotCompletedOrders"
                  text={texts.reasonsForNotCompletedOrders}
                  fontSize="$m"
                />
              </Link>
            </LinkList>
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
