import { useCallback } from 'react'

export function useOnCalendarChange(onChange: (date: Date) => void) {
  return useCallback(
    (date: Date | null) => {
      if (date) {
        onChange(date)
      }
    },
    [onChange],
  )
}
