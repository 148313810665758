import type { MultipleOrderStatus, OrderStatus } from '../typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentPlanSnapshot } from '@/features/domain/history'

function createMultipleOrderStatus(): MultipleOrderStatus {
  return {
    completed: { count: 0, withViolations: 0 },
    notCompleted: { count: 0, withViolations: 0 },
    undeclared: { count: 0, withViolations: 0 },
    unassigned: { count: 0, withViolations: 0 },
    todo: { count: 0, withViolations: 0 },
    notDispatched: { count: 0, withViolations: 0 },
  }
}

function computeOrderStatus(
  extOrder: uui.domain.client.rm.ExtendedOrderStep,
  planSummary: uui.domain.client.rm.PlanSummary | undefined,
): OrderStatus {
  // Unassigned
  if (extOrder.isUnassigned) return 'unassigned'

  // Not dispatched
  if (!planSummary?.order.dispatched.includes(extOrder.id)) return 'notDispatched'

  // Undeclared
  if (extOrder.hasBeenExecuted && extOrder.orderStep.trackingData.status === 'missing')
    return 'undeclared'

  // Not completed
  if (extOrder.orderStep.trackingData.status === 'reschedule') return 'notCompleted'

  // Completed
  if (extOrder.orderStep.trackingData.status === 'done') return 'completed'

  // To do
  return 'todo'
}

export function useOrdersStatus(
  extOrders: uui.domain.client.rm.ExtendedOrderStep | uui.domain.client.rm.ExtendedOrderStep[],
): OrderStatus | MultipleOrderStatus {
  const planSummary = useSelector(selectCurrentPlanSnapshot)

  return useMemo(() => {
    if (Array.isArray(extOrders)) {
      const statuses = extOrders.reduce<Partial<MultipleOrderStatus>>((acc, extOrder) => {
        const status = computeOrderStatus(extOrder, planSummary)
        const currentStatus = acc[status] || { count: 0, withViolations: 0 }

        currentStatus.count++

        if (!extOrder.isUnassigned && extOrder.violations?.some(type => type === 'TW')) {
          currentStatus.withViolations++
        }

        acc[status] = currentStatus

        return acc
      }, {})

      const statusKeys = Object.keys(statuses) as OrderStatus[]

      return statusKeys.length === 1
        ? statusKeys[0]
        : { ...createMultipleOrderStatus(), ...statuses }
    }

    return computeOrderStatus(extOrders, planSummary)
  }, [planSummary, extOrders])
}
