import styled from 'styled-components'

export const Separator = styled.div`
  background-color: white;
  border: solid 1px white;
  border-radius: 2px;
  height: 24px;
  margin-left: 9px;
  opacity: 0.2;
`
Separator.displayName = 'Separator'
