import { useToggleTesterModal } from '@/hooks'

import { TesterModal } from './TesterModal'

export const useTesterModal = () => {
  const { toggleTesterModal } = useToggleTesterModal()

  return {
    Modal: <TesterModal />,
    toggleTesterModal,
  }
}
