import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function HideDepot(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 21 21">
        <path d="M10.5 20.5a.5.5 0 1 1 0-1c.965 0 1.91-.152 2.807-.446a.5.5 0 0 1 .311.95 9.991 9.991 0 0 1-3.118.496zm5.926-1.944a.5.5 0 1 1-.593-.805 9.055 9.055 0 0 0 1.995-2.025.5.5 0 0 1 .814.581 10.055 10.055 0 0 1-2.216 2.249zm3.622-5.077a.5.5 0 0 1-.954-.297 8.992 8.992 0 0 0 .405-2.844.5.5 0 1 1 1-.018v.217a9.995 9.995 0 0 1-.45 2.942zm-.103-6.27a.5.5 0 0 1-.944.328 8.978 8.978 0 0 0-1.349-2.501.5.5 0 0 1 .794-.608 9.978 9.978 0 0 1 1.5 2.78zm-3.831-4.986a.5.5 0 1 1-.562.827 8.957 8.957 0 0 0-2.574-1.205.5.5 0 0 1 .275-.961 9.957 9.957 0 0 1 2.86 1.34zM10.139.506a.5.5 0 0 1 .036 1 8.965 8.965 0 0 0-2.79.547.5.5 0 1 1-.345-.938 9.965 9.965 0 0 1 3.1-.609zm-5.855 2.16a.5.5 0 1 1 .622.783 9.053 9.053 0 0 0-1.92 2.096.5.5 0 1 1-.835-.552 10.053 10.053 0 0 1 2.133-2.327zM.85 7.87a.5.5 0 1 1 .965.262 9.006 9.006 0 0 0-.301 2.854.5.5 0 1 1-.999.054A10.009 10.009 0 0 1 .85 7.87zm.332 6.264a.5.5 0 0 1 .932-.363 8.992 8.992 0 0 0 1.44 2.45.5.5 0 1 1-.772.636 9.992 9.992 0 0 1-1.6-2.723zm4.017 4.845a.5.5 0 0 1 .531-.847c.807.505 1.689.88 2.617 1.108a.5.5 0 1 1-.239.97 9.946 9.946 0 0 1-2.909-1.23z" />
        <path d="M10.5 6L6.45 8.931 6 9.256v4.508c0 .13.101.236.225.236H8.7a.231.231 0 0 0 .225-.236v-2.12c0-.91.707-1.65 1.575-1.65s1.575.74 1.575 1.65v2.12c0 .13.101.236.225.236h2.475a.231.231 0 0 0 .225-.236V9.26l-.45-.325L10.5 6z" />
      </svg>
    </IconContainer>
  )
}
