import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
} from '@/forms-legacy'

import { useTexts } from './useTexts'

interface Props {
  idleStartThreshold?: number
}

export function IdleStartLimit(props: Props) {
  const { idleStartThreshold } = props

  const texts = useTexts()

  const enabled = typeof idleStartThreshold === 'number' && isFinite(idleStartThreshold)
  const fieldValue = texts.minutes(Math.round((idleStartThreshold ?? 0) / 60))

  return (
    <ReadonlyBlock>
      <ReadonlyLabel primary>{texts.idleStartLimit}</ReadonlyLabel>
      {enabled && (
        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>{fieldValue}</ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      )}
    </ReadonlyBlock>
  )
}
