import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    switchToSatellite: translate({ id: 'lib.locationEditor.modal.switchToSatellite' }),
    switchToMap: translate({ id: 'lib.locationEditor.modal.switchToMap' }),
  }))

  return api
}
