import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    sortBy: (field: string) => {
      switch (field) {
        case 'displayName':
          return translate({ id: 'vehicles.list.header.sortBy.name' })
        case 'ui.sort.idx':
          return translate({ id: 'vehicles.list.header.sortBy.id' })
        default:
          console.warn(`Unknown vehicle field: ${field}`)
          return ''
      }
    },

    sortByField: (field: string) =>
      translate({ id: 'vehicles.list.header.sortBy', values: { field } }),
    sortByDirection: (direction: string) =>
      translate({ id: 'vehicles.list.header.sortBy.direction', values: { direction } }),

    sortById: translate({ id: 'vehicles.list.header.sortBy.id' }),
    sortByName: translate({ id: 'vehicles.list.header.sortBy.name' }),
    sortAscending: translate({ id: 'vehicles.list.header.sortBy.ascending' }),
    sortDescending: translate({ id: 'vehicles.list.header.sortBy.descending' }),
  }))

  return api
}
