import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    invalidFormat: translate({ id: 'order.form.bulk.timeWindows.errors.invalidFormat' }),
    emptyField: translate({ id: 'order.form.bulk.timeWindows.errors.empty' }),
  }))

  return api
}
