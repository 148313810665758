import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { isVisibleOrder } from '@/server-data'

import { setOrderFeatureMetadata } from '../orderFeatureMetadata'
import { getOrderFeatureStyle } from './getOrderFeatureStyle'

export const updateOrderFeature = (
  mapStyles: uui.domain.ui.map.markers.MapStyles['order'],
  marker: uui.domain.ui.map.markers.Order,
  feature: Feature<Geometry>,
  selectedOrderIds: Set<string>,
  selected: boolean = false,
): Feature<Geometry> => {
  const { ids } = marker

  const visibleIds = Object.keys(ids).reduce<Record<string, true>>((acc, id) => {
    if (isVisibleOrder(ids[id], mapStyles)) {
      acc[id] = true
    }

    return acc
  }, {})

  // update all linked Orders ID
  setOrderFeatureMetadata(feature, 'ids', visibleIds)

  // update all linked Orders ID
  setOrderFeatureMetadata(feature, 'selected', selected)

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['order']['mode'] = marker.mode ?? mapStyles.mode
  setOrderFeatureMetadata(feature, 'mode', mode)

  // retrieve the right icon
  const style = getOrderFeatureStyle(marker, mode, selectedOrderIds, selected)
  feature.setStyle(style)

  return feature
}
