import { useCallback } from 'react'

export function useActions(
  driverAssignments: Record<string, uui.domain.client.rm.DriverAssignmentsWithId>,
  setDriverAssignment: (driverAssignment: uui.domain.client.rm.DriverAssignmentsWithId) => void,
) {
  const onOpenExisting = useCallback(
    (r: uui.domain.rm.Recurrence) => {
      if (!driverAssignments[r.source]) {
        throw new Error(`Cannot open existing day for ${r.source}`)
      }
      setDriverAssignment(driverAssignments[r.source])
    },
    [setDriverAssignment, driverAssignments],
  )

  const onCreate = useCallback(
    (r: uui.domain.rm.Recurrence) => {
      setDriverAssignment({ id: r.source, assignments: {} })
    },
    [setDriverAssignment],
  )

  return {
    onOpenExisting,
    onCreate,
  }
}
