import { Box } from '@mui/material'
import { type PropsWithChildren } from 'react'

export function IllustrationContainer(
  props: PropsWithChildren<{ maxHeight: string | number; minHeight?: string | number }>,
) {
  const { maxHeight, children, minHeight = 100 } = props

  // Greedy container that will take all the space available, it stops at the maxHeight
  return (
    <Box
      flexShrink={1}
      flexGrow={1}
      width="100%"
      maxHeight={maxHeight}
      minHeight={minHeight}
      position="relative"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Box>
    </Box>
  )
}
