import type { AlertFormValues } from './types'

import { useState } from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { useReadOnly, useStopEditingOnUnmount } from '@/hooks'
import { useEditingStateWithCtx, useEnvironmentQa } from '@/atoms'
import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'

import { FormColumn } from '@/components/layout'

import { FormLoading } from '@/components/FormLoading'
import { computeFormInitialValues } from './utils/computeFormInitialValues'
import { useValidateForm } from './hooks/useValidateForm'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useComposeFieldsWithError } from './hooks/useComposeFieldsWithError'
import { useTexts } from './hooks/useTexts'
import { Footer } from './components/Footer'
import { Form } from './components/Form'

interface Props {
  alert: uui.domain.client.gps.wwgps.EventAlert
  creating?: boolean
  loading: boolean
}

export function SingleEdit(props: Props) {
  const { alert, loading, creating } = props
  const { onCreate, onUpdate, submitting } = useOnSubmit()
  const { resetEditing } = useEditingStateWithCtx('alert')

  const [initialValues] = useState<AlertFormValues>(() => computeFormInitialValues(alert))

  const validateForm = useValidateForm()
  const composeFieldsWithError = useComposeFieldsWithError()

  const { readonly } = useReadOnly()
  const texts = useTexts()

  const qa = useEnvironmentQa()

  useStopEditingOnUnmount()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <FinalForm<AlertFormValues>
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={creating ? onCreate : onUpdate}
        render={(formRenderProps: FormRenderProps<AlertFormValues>) => {
          const { handleSubmit, form } = formRenderProps

          const { valid, validating, pristine, errors = {} } = form.getState()

          const saveDisabled = pristine || !valid || validating || readonly
          const fieldsWithError = composeFieldsWithError(errors)

          return (
            <>
              <SecondaryColumnHeader
                title={texts.headerTitle}
                description={texts.headerDescription}
                avatar={null}
              />
              <FormContent
                qa={qa}
                testId="alertSingleEdit"
                footer={
                  <>
                    {!loading && (
                      <Footer
                        onSubmit={handleSubmit}
                        onCancel={resetEditing}
                        disabled={saveDisabled}
                        fieldsWithError={fieldsWithError}
                        pristine={pristine}
                        readonly={readonly}
                        valid={valid}
                        submitting={submitting}
                        creating={creating}
                      />
                    )}
                  </>
                }
              >
                {!loading ? <Form initialValues={initialValues} /> : <FormLoading />}
              </FormContent>
            </>
          )
        }}
      />
    </FormColumn>
  )
}
