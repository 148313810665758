import styled from 'styled-components'

import { InputFieldText as Comp } from '@/forms-legacy'

const LoadMixedField = styled(Comp)`
  width: 120px;

  border-width: 0;
  color: ${p => p.theme.colors.$nightRider};
  background: ${p => p.theme.colors.$pureWhite};

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 24px;
`
LoadMixedField.displayName = 'LoadMixedField'
export default LoadMixedField
