import type { OptionalSelectionModifiers } from '@/atoms'
import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'

import { getCrudSelection, setCrudSelection, getMainSelection, setMainSelection } from '@/atoms'

import { getConnectedList } from '../../../../atoms/selection/read/getConnectedList'
import { getMapSelectionContext } from '../../../../atoms/selection/read/getMapSelectionContext'

export function clickDevice(feature: Feature<Geometry>, modifiers: OptionalSelectionModifiers) {
  const connectedList = getConnectedList()
  const mapSelectionContext = getMapSelectionContext()

  const deviceId = feature.getId()?.toString()

  if (deviceId) {
    const selection = [deviceId]

    // select
    if (mapSelectionContext.type === 'crud') {
      if (mapSelectionContext.category === 'devices') {
        const crudSelection = getCrudSelection()
        const withoutModifiers = Object.keys(modifiers).every(key => modifiers[key] === false)

        // If there's no modifier and the device is already selected we must deselect it
        if (withoutModifiers) {
          const index = crudSelection.devices.indexOf(selection[0])

          if (index !== -1) {
            selection.splice(index, 1)

            setCrudSelection('devices', selection, { modifiers })
            return
          }
        }

        setCrudSelection('devices', selection, { modifiers })

        // try to scroll to
        if (
          connectedList.status === 'connected' &&
          connectedList.category === 'devices' &&
          !connectedList.disabled
        ) {
          connectedList.scrollTo(deviceId)
        }
      }
    } else {
      const mainSelection = getMainSelection()
      const withoutModifiers = Object.keys(modifiers).every(key => modifiers[key] === false)

      // If there's no modifier and the device is already selected we must deselect it
      if (withoutModifiers) {
        const index = mainSelection.devices.indexOf(selection[0])

        if (index !== -1) {
          selection.splice(index, 1)

          setMainSelection('devices', selection, { modifiers })
          return
        }
      }
      setMainSelection('devices', selection, { modifiers })
    }
  }
}
