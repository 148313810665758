import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useIsSimulation } from '@/hooks'
import { selectUserConfiguration, selectUserType } from '@/features/domain/user'

export const useCanShow = () => {
  const userRole = useSelector(selectUserType)
  const isSimulation = useIsSimulation()
  const { userType } = useSelector(selectUserConfiguration)

  const noGpsOnly = userType !== 'gpsOnly'
  const admin = userRole === 'admin'
  const noGuest = userType !== 'rmGuest'

  return useMemo(
    () => ({
      depots: noGpsOnly,
      vehicles: noGpsOnly,

      // Guest users are expected not to see the setup sidebar at all
      // Communication section is not available for viewer users and when in simulation
      communication: noGpsOnly && admin && !isSimulation,

      // Not available in Simulation
      driverMobileApp: admin && !isSimulation,
      driverAssignments: noGpsOnly && !isSimulation,
      drivers: noGpsOnly && !isSimulation,
      territory: noGuest,
    }),
    [noGpsOnly, isSimulation, admin, noGuest],
  )
}
