import { Box } from '@mui/material'
import { FormProvider } from '@workwave-tidal/tidal/form-ui/layout/FormProvider'

import { FormLoading } from '@/components/FormLoading'

import { FormColumn } from '@/components/layout'
import { useFormTransaction } from '@/hooks'

import { useFormConfig } from './hooks/useFormConfig'
import { type FormErrors, type FormFields, formFields } from './formFields'
import { EditDrivers } from './components/EditDrivers/EditDrivers'

type Props = {
  drivers: uui.domain.client.rm.ExtendedDriver[]
}

export function BulkEdit(props: Props) {
  const { drivers } = props
  const { formConfig } = useFormConfig(drivers)
  const transactionOpen = useFormTransaction() === 'ready'

  switch (transactionOpen) {
    case false:
      return (
        <Box height="100%">
          <FormColumn width={360} testid="form__column-secondary">
            <FormLoading style={{ width: '100%' }} />
          </FormColumn>
        </Box>
      )
    case true:
      return (
        <Box height="100%">
          <FormColumn width={360} testid="form__column-secondary">
            <FormProvider<FormFields, FormErrors>
              name="driver-bulk-edit"
              fields={formFields}
              verbose={false}
              config={formConfig}
            >
              <EditDrivers drivers={drivers} />
            </FormProvider>
          </FormColumn>
        </Box>
      )
  }
}
