import { useMemo, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { parseISO } from 'date-fns/esm'

import { selectDeviceEvents } from '@/features/domain/deviceEvents'
import { selectBreadcrumbTimeRange } from '@/features/domain/breadcrumb'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectStartOfToday } from '@/features/domain/user'
import { useCrudSelection } from '@/atoms'

import { useComputeSelectedDeviceIds } from './useComputeSelectedDeviceIds'

export function useDeviceEvents() {
  const deviceEventsByDeviceId = useSelector(selectDeviceEvents)
  const deviceIds = useComputeSelectedDeviceIds()

  const [selection, setSelection] = useCrudSelection('deviceEvents')

  const breadcrumbsTimeRange = useSelector(selectBreadcrumbTimeRange)

  const calendarRange = useSelector(selectCalendarRange)

  const today = useSelector(selectStartOfToday)
  const todayMs = parseISO(today).getTime()

  const api = useRef({
    selectionSet: new Set([...selection]),
    setSelection,
  })

  useEffect(() => {
    api.current.selectionSet = new Set([...selection])
  }, [selection])

  const events = useMemo<Record<string, uui.domain.client.gps.wwgps.EventInfo>>(() => {
    const range = calendarRange.minDate !== calendarRange.maxDate

    if (range) return {}

    return deviceIds.reduce<Record<string, uui.domain.client.gps.wwgps.EventInfo>>(
      (acc, deviceId) => {
        const eventsByDateAndDevice =
          deviceEventsByDeviceId[deviceId]?.[calendarRange.minDate] ?? {}

        for (const eventId in eventsByDateAndDevice) {
          const event = eventsByDateAndDevice[eventId]
          const eventInTimeRange = isEventInTimeRange(event.ts, breadcrumbsTimeRange, todayMs)

          if (!eventInTimeRange) continue

          acc[eventId] = event
        }

        return acc
      },
      {},
    )
  }, [deviceIds, deviceEventsByDeviceId, breadcrumbsTimeRange, calendarRange, todayMs])

  // prune the selection by filtering only the events that are in the list
  // this is made in the UI since the current flow in the domain middleware
  // do not allow to do it there
  useEffect(() => {
    for (const id of api.current.selectionSet) {
      if (!events[id]) {
        api.current.selectionSet.delete(id)
      }
    }

    api.current.setSelection([...api.current.selectionSet])
  }, [events])

  return events
}

function isEventInTimeRange(
  eventTs: number,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
  now: number,
) {
  if (breadcrumbTimeRange === 'live') {
    return eventTs > now
  }

  return eventTs >= breadcrumbTimeRange.start && eventTs <= breadcrumbTimeRange.end
}
