import type { SelectionAsRecord } from '../../typings'

import { useMemo } from 'react'
import { format } from 'date-fns/esm'

export const useSelectionData = (selection?: Date[]) => {
  return useMemo<SelectionAsRecord>(() => {
    const selectionAsRecord: SelectionAsRecord = {}
    if (!selection) {
      return selectionAsRecord
    }
    for (const date of selection) {
      selectionAsRecord[format(date, 'yyyyMMdd')] = 1
    }

    return selectionAsRecord
  }, [selection])
}
