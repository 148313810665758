import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function FindOnMap(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 15 15">
        <path d="M7.49963 0c4.13554 0 7.49963 3.364092 7.49963 7.499629 0 .144345-.0041.28775-.01219.430105-.91565-1.031085-2.25085-1.680043-3.73763-1.680043-1.02819 0-1.9839.310368-2.77853.842529l.27866-.86206 1.07416-.800741 1.0937-.156242 1.01557.624968.42967-.78121-1.67961-.546849-1.34758.429666-.09766-1.288998.54685-.468727.66403.292954-.11718-1.249938.50779-.05859c-.96675-.610322-2.10927-.976515-3.33968-.976515-.11393 0-.22785.011935-.34033.023509l-.16746.015552 1.38665.097651.33202 1.445241-1.87491.351545-.50779-.70309-1.07416.234363.37107 1.660074-2.49987 1.191348L1.44524 6.89419l.60544 1.230408h2.3241l2.11471 1.590617c-.1557.483438-.2398.999012-.2398 1.534228 0 .304772.02727.603174.0795.8929l-.09903.376569.50388.880015c.28947.607004.69773 1.146465 1.19472 1.58831l-.18677.00816-.24236.00386C3.36409 14.999257 0 11.635167 0 7.49963 0 3.364092 3.36409 0 7.49963 0zm3.75103 8.124598c1.74929 0 3.12363 1.374932 3.12363 3.124845 0 .62497-.18798 1.250426-.50046 1.750402l.94477.943557c.24187.241863.24187.633337 0 .8752-.24186.241864-.63334.241864-.8752 0l-.94356-.944777c-.49997.312485-1.12543.500463-1.7504.500463-1.74991 0-3.12484-1.374336-3.12484-3.123624 0-1.693666 1.4324-3.126066 3.12606-3.126066zm-9.35622.566378l-.60544-.70309c.19115 2.482624 1.8058 4.561469 4.04346 5.387528l.23367.080951-1.77726-2.187392h-.72262L1.89444 9.979975V8.690976l-.60544-.70309zm9.355.68356c-1.06244 0-1.8749.81246-1.8749 1.874907 0 1.062448.81246 1.874907 1.8749 1.874907 1.06245 0 1.87491-.81246 1.87491-1.874907 0-1.062447-.81246-1.874907-1.87491-1.874907zM5.82002 1.699135l.97652-.410136c-.40428.04492-.79137.12265-1.16627.238191l-.27897.093824.46872.07812.97652-.410135z" />
      </svg>
    </IconContainer>
  )
}
