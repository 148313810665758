import { renderToStaticMarkup } from 'react-dom/server'
import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'

import { selectUserConfiguration } from '@/features/domain/user'
import { store } from '@/store'
import { intl } from '@/intl'

import { CostCell } from '../../../components/cells/CostCell'
import { costSorter } from '../sorters/costSorter'

export function createCostColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    text: intl.translate({ id: 'rm.scheduler.column.routeCost' }),
    type: 'template',
    field: 'routeCost',
    width,
    editor: false,
    hidden,
    region: 'cols',

    template: ({ record, field, value }) => {
      const state = store.getState()
      const { language, currency } = selectUserConfiguration(state)
      return renderToStaticMarkup(
        <CostCell
          record={record as ResourceModel}
          field={field}
          value={value}
          language={language}
          currency={currency}
        />,
      )
    },

    sortable: costSorter,
    resizable: true,
    groupable: false,
  }
}
