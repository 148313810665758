import styled from 'styled-components'

const styledComponent = styled.div.attrs({
  className: 'o-warning-overlay__dialog-box',
})`
  height: 320px;
  width: 460px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: rgba(153, 153, 153, 0.7) 0px 0px 20px 10px;
  background: white;
  border-radius: 10px;
  padding: 2rem;
`

export default styledComponent
