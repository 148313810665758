import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  selectLivePositions,
  selectTelematicsDevicesAsArrayWithActiveStatus,
} from '@/features/domain/device'
import { getOpsDataFiltered, selectTerritories } from '@/features/domain/territory'
import { store } from '@/store'

import { type TableRow } from '../types'

async function fetchVehiclesByTerritory(
  setVehiclesByTerritory: (
    vehiclesByTerritory: uui.domain.server.rm.TerritoriesDriversAndVehicles | undefined,
  ) => void,
) {
  const result = await store.dispatch(getOpsDataFiltered())

  if (getOpsDataFiltered.rejected.match(result)) {
    throw new Error('Failed to fetch vehicles by territory')
  }

  setVehiclesByTerritory(result.payload)
}

export function useIntegrationData(tenantSourceId: string) {
  const devices = useSelector(selectTelematicsDevicesAsArrayWithActiveStatus)
  const territories = useSelector(selectTerritories)
  const livePositions = useSelector(selectLivePositions)
  const [filter, setFilter] = useState('')

  const [vehiclesByTerritory, setVehiclesByTerritory] = useState<
    uui.domain.server.rm.TerritoriesDriversAndVehicles | undefined
  >()

  useEffect(() => {
    fetchVehiclesByTerritory(setVehiclesByTerritory)
  }, [devices, setVehiclesByTerritory])

  const onChangeFilter = useCallback((search: string) => setFilter(search), [])

  const data = useMemo(() => {
    if (!vehiclesByTerritory) return

    return devices.reduce<TableRow[]>((acc, device) => {
      if (device.tenantSourceId !== tenantSourceId) return acc

      if (!device.active) return acc

      const vehicleLabel =
        vehiclesByTerritory[device.territoryId]?.vehicles[device.correlationHandle]
      const territory = territories[device.territoryId]

      // If the territory is not found, we skip the device since it can be assigned in a territory that is not visible
      if (!territory) return acc

      const lastPosition = livePositions[device.deviceId]

      const name = device.deviceLabel ?? device.deviceId

      const isInsideFilter = isPassingFilter(filter, name, vehicleLabel, territory.name)

      if (!isInsideFilter) return acc

      acc.push({
        id: device.deviceId,
        name,
        vehicle: { id: device.correlationHandle, name: vehicleLabel },
        territory: { id: territory.id, name: territory.name },
        lastActivity: lastPosition as uui.domain.client.gps.telematics.CurrentPositionGpsInfo,
      })

      return acc
    }, [])
  }, [vehiclesByTerritory, tenantSourceId, livePositions, territories, devices, filter])

  return { data, loading: !data, filter, onChangeFilter, unfilteredDataSize: devices.length }
}

function isPassingFilter(filter: string, name: string, vehicleName: string, territoryName: string) {
  const lowerFilter = filter.toLowerCase()

  return (
    name.toLowerCase().includes(lowerFilter) ||
    vehicleName.toLowerCase().includes(lowerFilter) ||
    territoryName.toLowerCase().includes(lowerFilter)
  )
}
