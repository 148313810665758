import { Alert, Divider, Stack, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

import { useTexts } from '../../../../useTexts'
import { ApiKey } from './components/ApiKey'
import { Label } from './components/Label'

export function Linxup() {
  const texts = useTexts()

  return (
    <Stack spacing={2} width="100%">
      <Label />

      <Divider />

      <Typography variant="caption">{texts.linxup.instructions}</Typography>

      <ApiKey />

      <Alert icon={<InfoOutlined />} severity="info">
        {texts.linxup.suggestion}
      </Alert>
    </Stack>
  )
}
