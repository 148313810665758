import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    cancelTranslation: translate({ id: 'recurrenceSelector.footer.button.cancel' }),
    setRecurrenceTranslation: translate({ id: 'recurrenceSelector.footer.button.setRecurrence' }),
    openExistingTranslation: translate({ id: 'recurrenceSelector.footer.button.openExisting' }),
  }))

  return api
}
