import { useSelector } from 'react-redux'
import { format } from 'date-fns/esm'
import { useRef, useCallback, useEffect } from 'react'

import { selectUserConfiguration } from '@/features/domain/user'

import { OnCalendar } from './OnCalendar'
import { ByCalendar } from './ByCalendar'

type Props = {
  eligibility: uui.domain.client.rm.Eligibility
  onChange: (type: uui.domain.client.rm.Eligibility['type'], dates: Date[]) => void
}

export function CalendarPicker(props: Props) {
  const { onChange, eligibility } = props

  const { today } = useSelector(selectUserConfiguration)
  const referenceDate = useRef(computeReferenceDate(eligibility, today))

  const handleOnChange = useCallback(
    (type: uui.domain.client.rm.Eligibility['type']) => (date: Date[]) => {
      onChange(type, date)
    },
    [onChange],
  )

  const onChangeReferenceDate = useCallback((newReferenceDate: Date) => {
    referenceDate.current = format(newReferenceDate, 'yyyyMMdd')
  }, [])

  useEffect(() => {
    if (eligibility.type === 'any') {
      referenceDate.current = today
    }
  }, [today, eligibility.type])

  switch (eligibility.type) {
    case 'on':
      return (
        <OnCalendar
          dates={eligibility.dates}
          onChange={handleOnChange('on')}
          initialVisibleDate={referenceDate.current}
          onReferenceDateChange={onChangeReferenceDate}
        />
      )

    case 'by':
      return (
        <ByCalendar
          date={eligibility.date}
          onChange={handleOnChange('by')}
          initialVisibleDate={referenceDate.current}
          onReferenceDateChange={onChangeReferenceDate}
        />
      )

    case 'any':
    default:
      return null
  }
}

function computeReferenceDate(
  eligibility: uui.domain.client.rm.Eligibility,
  today: string,
): string {
  switch (eligibility.type) {
    case 'any':
      return today

    case 'by':
      return eligibility.date

    case 'on':
      const sortedDates = [...eligibility.dates].sort(sortDate)
      return sortedDates[0]
  }
}

function sortDate(a: string, b: string) {
  return new Date(a).getDate() - new Date(b).getDate()
}
