import type { RegionFormValues } from './typings'

import { useState } from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { useEditingStateWithCtx, useEnvironmentQa } from '@/atoms'
import { Footer, SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { FormLoading } from '@/components/FormLoading'
import { FormColumn } from '@/components/layout'
import { usePendingFitMapOnMount } from '@/map'
import { useFormTransaction, useReadOnly, useStopEditingOnUnmount } from '@/hooks'

import { useComposeFieldsWithError } from './hooks/useComposeFieldsWithError'
import { useValidateForm } from './hooks/useValidateForm'
import { computeFormInitialValues } from './utils'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'
import { Form } from './Form'

interface Props {
  region: uui.domain.client.rm.Region
  regions: Record<string, uui.domain.client.rm.Region>
}

export function SingleEdit(props: Props) {
  const { region, regions } = props
  const { onCreate, onUpdate, submitting } = useOnSubmit()
  const { resetEditing } = useEditingStateWithCtx('region')
  const { readonly } = useReadOnly()

  const [initialValues] = useState<RegionFormValues>(() =>
    computeFormInitialValues(regions, region),
  )

  const validateForm = useValidateForm()
  const composeFieldsWithError = useComposeFieldsWithError()

  const texts = useTexts()

  const qa = useEnvironmentQa()

  // When the Edit form mounts
  // try to apply pending fitMap requests
  usePendingFitMapOnMount()

  useStopEditingOnUnmount()

  const transactionState = useFormTransaction()
  const transactionOpen = transactionState === 'ready'

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <FinalForm<RegionFormValues>
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={initialValues.id ? onUpdate : onCreate}
        render={(formRenderProps: FormRenderProps<RegionFormValues>) => {
          const {
            handleSubmit,
            form,
            values: { name, points },
          } = formRenderProps

          const { valid, validating, pristine, errors = {} } = form.getState()

          const saveDisabled = pristine || !valid || validating || readonly
          const fieldsWithError = composeFieldsWithError(errors)

          const footerMessages = points.length > 0 ? texts.editingPolygon : texts.creatingPolygon

          return (
            <>
              <SecondaryColumnHeader
                title={texts.headerTitle(name)}
                description={texts.headerDescription}
                avatar={null}
              />
              <FormContent
                qa={qa}
                testId="regionSingleEdit"
                footer={
                  transactionOpen ? (
                    <Footer
                      message={footerMessages}
                      errorTitle={texts.footerTitle}
                      errors={fieldsWithError}
                      submitting={submitting}
                      primary={{
                        text: texts.submit(initialValues.id),
                        title: texts.submitTitle(valid, readonly, pristine),
                        disabled: saveDisabled,
                        onClick: handleSubmit,
                      }}
                      secondary={{
                        onClick: resetEditing,
                        disabled: submitting,
                        text: texts.cancel,
                      }}
                    />
                  ) : undefined
                }
              >
                {transactionOpen ? <Form formApi={form} /> : <FormLoading />}
              </FormContent>
            </>
          )
        }}
      />
    </FormColumn>
  )
}
