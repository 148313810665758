import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../../hooks/useController'
import { useTexts } from '../../useTexts'

import { useOnSubmit } from './hooks/useOnSubmit'

export function Footer() {
  const {
    status,
    close,
    data: { placeIds },
  } = useController()
  const submit = useOnSubmit(placeIds)
  const texts = useTexts()

  const submitting = status === 'submitting'

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          className={`pendo-export-places-modal-send`}
          testid={`export-places-modal-send`}
          disabled={submitting}
          loading={submitting}
          variant="contained"
          onClick={submit}
          maxWidth="50%"
        >
          {texts.send}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          className={`pendo-export-places-modal-cancel`}
          testid={`export-places-modal-cancel`}
          disabled={submitting}
          onClick={close}
        >
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}
