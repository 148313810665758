import { PureComponent } from 'react'

import { TagListItem } from '../TagListItem'
import SmallLabel from '../SmallLabel'

import TagsContainer from './TagsContainer'
import { Props } from './typings'

export default class Tags extends PureComponent<Props> {
  render() {
    const { list, onDeleteTag, renderText } = this.props

    return (
      <div style={{ marginTop: 10 }}>
        {list.length > 0 && (
          <>
            <TagsContainer data-testid="tags__selection-list-container">
              {list.map((l: string) => {
                return (
                  <TagListItem uiStyle={'remove'} key={l} onClick={() => onDeleteTag(l)}>
                    {l}
                  </TagListItem>
                )
              })}
            </TagsContainer>
            <SmallLabel>{renderText('tagsListMessage', this.props)}</SmallLabel>
          </>
        )}
      </div>
    )
  }
}
