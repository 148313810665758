import { Entry } from '../Entry'
import { JiraLinks } from './JiraLinks'

const ids = [10591, 10320, 10709, 10710]

export function Entry20210422() {
  return (
    <Entry title="04/22/2021 - v3.0.0-beta-1 fixes">
      Full list of Jira issues fixed:
      <br />
      <br />
      <JiraLinks ids={ids} />
      <br />
      <br />
    </Entry>
  )
}
