import styled from 'styled-components'

const styledComponent = styled.span.attrs<workwave.Span>(() => ({
  className: 'o-field-arrow-button__icon-container',
}))`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  transform: translate(-50%, -50%);
`

styledComponent.displayName = 'ArrowButtonIconContainer'
export default styledComponent
