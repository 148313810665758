import { useSelector } from 'react-redux'

import {
  SecondaryColumnContentContainer,
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyFieldLabel,
  ReadonlyField,
  ReadonlyFieldRow,
} from '@/forms-legacy'
import { selectUserConfiguration } from '@/features/domain/user'

import { durationUtils } from '@/server-data'

import { intl } from '@/intl'
import { useTexts } from './hooks/useTexts'

interface Props {
  enterTimeSec: number
  enterCost: number
  enterCostMixed?: boolean
  enterTimeMixed?: boolean
}

export function ViewDetails(props: Props) {
  const { enterTimeSec, enterCost, enterCostMixed, enterTimeMixed } = props

  const texts = useTexts()
  const { currency } = useSelector(selectUserConfiguration)

  const time = enterTimeMixed
    ? texts.multipleValues
    : enterTimeSec > 0
    ? durationUtils.formatSeconds(intl.translate)(enterTimeSec, 'SHORT')
    : texts.none

  const cost = enterCostMixed
    ? texts.multipleValues
    : enterCost > 0
    ? `${enterCost} ${currency.toUpperCase()}`
    : texts.none

  return (
    <SecondaryColumnContentContainer>
      <SecondaryColumnContentHorizontalRuler bottomSpace />

      <ReadonlyBlock half>
        <ReadonlyLabel primary>{texts.title}</ReadonlyLabel>

        <ReadonlyLabel>{texts.cost}</ReadonlyLabel>

        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>{cost}</ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyBlock>

      <ReadonlyBlock half>
        <ReadonlyLabel>{texts.time}</ReadonlyLabel>

        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>{time}</ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyBlock>
    </SecondaryColumnContentContainer>
  )
}
