import { intl } from '@/intl'

export function validateSpeedLimit(
  speedLimit?: uui.domain.ui.forms.GpsVehicleFormValues['device']['speedLimit'],
) {
  if (!speedLimit) return

  const { enabled, min, max, threshold } = speedLimit
  if (enabled === 'disabled') return

  if (typeof threshold !== 'number') {
    return intl.translate({
      id: 'vehicles.form.edit.generalSettings.validation.speedLimit.notNumeric',
    })
  }

  if (threshold < min || threshold > max) {
    return intl.translate({
      id: 'vehicles.form.edit.generalSettings.validation.speedLimit.range',
      values: { min, max },
    })
  }
}
