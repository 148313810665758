import { useState, useEffect, useCallback } from 'react'

import { useQueryParameters } from '@/hooks'

import { AutoLoginFromLocalStorage } from './AutoLoginFromLocalStorage'
import { AutoLoginFromUrl } from './AutoLoginFromUrl'

type Step = 'query' | 'account' | 'done'

type Props = {
  onComplete: (success: boolean) => void
  onLoadInitialDataComplete: () => void
}

export function Initialize(props: Props) {
  const { onComplete, onLoadInitialDataComplete } = props

  // const dispatch = useAppDispatch()
  const query = useQueryParameters()
  const { noorders, stylesOverride } = query

  const [step, setStep] = useState<Step>('query')

  useEffect(() => {
    // TODO: implement support for noorders

    if (stylesOverride) {
      // dispatch(changeGlobalStyles(stylesOverride))
      // TODO: update domain store data for Map Styles
    }
    console.info('WIP: Styles override form URL to be implemented yet', noorders, stylesOverride)
  }, [noorders, stylesOverride])

  const handleAutoLoginFromUrl = useCallback(
    (done: boolean) => (done ? onComplete(true) : setStep('account')),
    [onComplete],
  )

  const handleAutoLoginFromLocalStorage = useCallback(
    (done: boolean) => {
      setStep('done')
      onComplete(done)
    },
    [onComplete],
  )

  switch (step) {
    case 'query':
      return (
        <AutoLoginFromUrl
          query={query}
          onComplete={handleAutoLoginFromUrl}
          onLoadInitialDataComplete={onLoadInitialDataComplete}
        />
      )

    case 'account':
      return (
        <AutoLoginFromLocalStorage
          query={query}
          onComplete={handleAutoLoginFromLocalStorage}
          onLoadInitialDataComplete={onLoadInitialDataComplete}
        />
      )

    default:
    case 'done':
      return null
  }
}
