import styled from 'styled-components'

const CustomFieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 28px;

  background: ${p => p.theme.colors.$whiteSmoke};
  border-radius: 6px;

  padding: 0 0 0 10px;
  border: 2px solid ${p => p.theme.colors.$whiteSmoke};
`
CustomFieldContainer.displayName = 'CustomFieldContainer'
export default CustomFieldContainer
