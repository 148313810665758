import type { AlertFormValidation } from '../types'

import { useTexts } from './useTexts'

export const useComposeFieldsWithError = () => {
  const texts = useTexts()

  return (errors: AlertFormValidation) =>
    Object.keys(errors).map(field => {
      switch (field as keyof AlertFormValidation) {
        case 'name':
          return texts.labelName
        case 'deviceIds':
          return texts.labelDeviceIds
        case 'notificationAddresses':
          return texts.labelNotificationAddresses

        default:
          return ''
      }
    })
}
