import { styled } from '@mui/material'
import { theme } from '@/styles'

import { IconContainerProps as Props } from '../typings'

function shouldForwardProp(prop: string) {
  return (
    prop !== 'marginBottom' &&
    prop !== 'marginLeft' &&
    prop !== 'marginRight' &&
    prop !== 'marginTop' &&
    prop !== 'size' &&
    prop !== 'as'
  )
}

export const IconContainer = styled('span', {
  name: 'IconContainer',
  shouldForwardProp,
})<Props>(({ size, as, marginLeft, marginRight, marginTop, marginBottom, animation, color }) => ({
  marginBottom: `${marginBottom ?? 0}px`,
  marginRight: `${marginRight ?? 0}px`,
  marginLeft: `${marginLeft ?? 0}px`,
  marginTop: `${marginTop ?? 0}px`,
  display: as === 'div' ? 'block' : 'inline-block',
  width: size ? `${size}px` : '100%',
  fill: color ? theme.colors[color] : 'currentColor',
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },

    to: {
      transform: 'rotate(360deg)',
    },
  },
  animation: animation === 'spin' ? 'spin 2s infinite linear' : 'none',
}))
