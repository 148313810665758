import { CircularProgress } from '@mui/material'
import { NestedDropdown, DropdownMenuItem } from '@/components/DropdownMenu'
import { StrikedEye } from '@/icons'

import { ActiveIcon } from '../ActiveIcon'

import { useRoadEditsMapStyle } from './hooks/useRoadEditsMapStyle'
import { useHasRoadEdits } from './hooks/useHasRoadEdits'
import { useActions } from './hooks/useActions'
import { useTexts } from './useTexts'

interface Props {
  parentOpen: boolean
}

export function RoadEditsSubMenu(props: Props) {
  const { parentOpen } = props

  const {
    methodInExecution,
    actions: { showRoadEdits, hideRoadEdits },
  } = useActions()
  const activeStyle = useRoadEditsMapStyle()
  const hasRoutePaths = useHasRoadEdits()
  const texts = useTexts()

  const tooltipTitle = hasRoutePaths === true ? undefined : texts.noRoadEdits

  return (
    <NestedDropdown
      placement="left"
      parentOpen={parentOpen}
      disabled={!hasRoutePaths}
      tooltipTitle={tooltipTitle}
      triggerContent={texts.roadEdits}
      Icon={
        hasRoutePaths && activeStyle === 'off' ? (
          <StrikedEye size={12} color="$silver" />
        ) : undefined
      }
    >
      <DropdownMenuItem
        external
        onClick={showRoadEdits}
        Icon={
          methodInExecution === 'showRoadEdits' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'on'} />
          )
        }
      >
        {texts.on}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={hideRoadEdits}
        Icon={
          methodInExecution === 'hideRoadEdits' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'off'} />
          )
        }
      >
        {texts.off}
      </DropdownMenuItem>
    </NestedDropdown>
  )
}
