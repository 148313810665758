import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function NoBreadcrumbs(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path
          d="M6.169 3.22c.554-.403 1.316.005 1.316.705V5.61L3.437 9.659 1.353 8.14c-.44-.32-.469-.97-.088-1.335l.088-.073zM14.782 3.342c.134.15.218.35.218.583v7.022c0 .525-.43.885-.877.863-.15-.008-.3-.058-.44-.159l-4.17-3.04zM7.484 10.64l.001.308c0 .525-.43.885-.877.863-.081-.004-.162-.02-.242-.051l1.118-1.12z"
          transform="translate(-1481 -179) translate(143 76) translate(1338 103) translate(0 .44)"
        />
        <path
          d="M7.665-1.778h.778c.276 0 .5.224.5.5V16.39c0 .276-.224.5-.5.5h-.778c-.276 0-.5-.224-.5-.5V-1.278c0-.276.224-.5.5-.5z"
          transform="translate(-1481 -179) translate(143 76) translate(1338 103) translate(0 .44) rotate(45 8.054 7.556)"
        />
      </svg>
    </IconContainer>
  )
}
