import styled from 'styled-components'

const ColorPickerButton = styled.button`
  height: 26px;
  background-color: transparent;
  outline: 0;
  text-align: center;
  cursor: pointer;
  margin-left: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  border: 2px solid ${p => p.theme.colors.$nightRider};
  border-radius: 6px;
  user-select: none;
  align-items: center;

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${p => p.theme.colors.$silver};
    color: ${p => p.theme.colors.$pureWhite};
  }
`

ColorPickerButton.displayName = 'ColorPickerButton'
export default ColorPickerButton
