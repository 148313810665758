import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { eachDayOfInterval } from 'date-fns/esm'

import { selectVehicles } from '@/features/domain/vehicle'
import { getMatchingRecurrenceByDate } from '@/server-data'

import { useDepotsNavigator } from '../../../../../hooks/useDepotsNavigator'

export const useVehicleIdsForCurrentSelection = (interval: { start: Date; end: Date }) => {
  const { currentDepots } = useDepotsNavigator()
  const vehicles = useSelector(selectVehicles)
  const days = eachDayOfInterval(interval)

  return useMemo(
    () =>
      Object.values(vehicles).reduce<string[]>((acc, unifiedVehicle) => {
        if (!unifiedVehicle.hasRoutingLicense) return acc

        const { unifiedId, vehicle } = unifiedVehicle
        const calendarizedSettings = Object.keys(vehicle.settings)

        const isVehiclePresent = days.some(day => {
          const match = getMatchingRecurrenceByDate(day.toISOString(), calendarizedSettings)
          const { departureDepotId, arrivalDepotId } = vehicle.settings[match]

          return (
            (arrivalDepotId && currentDepots.some(depot => depot.id === arrivalDepotId)) ||
            (departureDepotId && currentDepots.some(depot => depot.id === departureDepotId))
          )
        })

        if (isVehiclePresent) acc.push(unifiedId)

        return acc
      }, []),
    [currentDepots, vehicles, days],
  )
}
