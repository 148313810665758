import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    mobileApp: translate({
      id: 'vehicles.form.edit.generalSettings.fields.trackingOptions.mobileApp',
    }),
    physicalDevice: translate({
      id: 'vehicles.form.edit.generalSettings.fields.trackingOptions.physicalDevice',
    }),
    physicalDeviceDisabled: translate({
      id: 'vehicles.form.edit.generalSettings.trackingSettings.physicalDevice.disabled.title',
    }),
    trackingOptionLabel: translate({
      id: 'vehicles.form.edit.generalSettings.fields.trackingOptions.label',
    }),
    trackingOptionInfo: translate({
      id: 'vehicles.form.edit.generalSettings.fields.trackingOptions.info',
    }),
    dropdownLabel: translate({
      id: 'vehicles.form.edit.generalSettings.fields.trackingOptions.physicalDevice.label',
    }),
  }))

  return api
}
