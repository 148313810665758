import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Pin(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M25 1C16.18 1 9 8.18 9 17c0 14.113 14.63 30.945 15.25 31.656.19.22.46.344.75.344.31-.02.56-.125.75-.344C26.37 47.934 41 30.813 41 17c0-8.82-7.18-16-16-16zm0 11c3.313 0 6 2.688 6 6 0 3.313-2.688 6-6 6-3.313 0-6-2.688-6-6 0-3.313 2.688-6 6-6z" />
      </svg>
    </IconContainer>
  )
}
