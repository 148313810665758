export const locationIsInvalid = (
  location: uui.domain.server.Location | uui.domain.client.Location,
) =>
  location.status === 'GEOCODING_ERROR' ||
  location.status === 'GEOCODING_FAILED' ||
  location.status === 'GEOCODING_TIMEOUT' ||
  location.status === 'NOT_FOUND'

export const locationIsOutsideTerritory = (
  location: uui.domain.server.Location | uui.domain.client.Location,
) => location.status === 'OUT_OF_TERRITORY_RANGE'

export const locationHasError = (loc: uui.domain.client.Location) =>
  locationIsInvalid(loc) || locationIsOutsideTerritory(loc)

export const locationHasWarning = (
  location: uui.domain.server.Location | uui.domain.client.Location,
) =>
  location.status === 'LOW_ACCURACY' ||
  location.status === 'POSTCODE_ISSUE' ||
  location.status === 'POSTCODE_LEVEL_ACCURACY'
