import { useMemo } from 'react'

import { useCountRemainingVehicle } from '../../../hooks/useCountRemainingVehicle'

export function useFlags(unifiedVehicles: uui.domain.client.UnifiedVehicle[]) {
  const countRemainingVehicle = useCountRemainingVehicle()

  const duplicateDisabled = useMemo(() => {
    if (unifiedVehicles.length > countRemainingVehicle) return 'overVehiclesLimit'
    if (unifiedVehicles.some(uv => !uv.hasRoutingLicense)) return 'vehiclesWithoutRoutingLicense'
    return false
  }, [unifiedVehicles, countRemainingVehicle])

  return {
    duplicateDisabled,
  }
}
