import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'setup.driverActivity.view.title' }),
    headerDescription: translate({ id: 'setup.reasonsForNotCompletedOrders.view.description' }),

    edit: translate({ id: 'setup.reasonsForNotCompletedOrders.view.edit' }),
    copy: translate({ id: 'setup.reasonsForNotCompletedOrders.view.copy' }),
    noReasons: translate({ id: 'setup.reasonsForNotCompletedOrders.view.copyWithNoReasons' }),
    oneTerritory: translate({ id: 'setup.reasonsForNotCompletedOrders.view.copyWithOne' }),
  }))

  return api
}
