import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectRoadSegmentsListOptions,
  setListOptions,
  selectRoadSegmentsListStructure,
} from '@/features/domain/lists'
import { useEditingStateWithCtx } from '@/atoms'
import { useAppDispatch } from '@/store'

export function useSorter() {
  const listOptions = useSelector(selectRoadSegmentsListOptions)
  const listStructure = useSelector(selectRoadSegmentsListStructure)
  const { editing } = useEditingStateWithCtx('segment')
  const dispatch = useAppDispatch()

  const [direction, setDirection] = useState(() => listOptions.sort[0].direction)

  useEffect(() => {
    if (!listOptions.sort) return
    if (listOptions.sort[0].direction === direction) return

    dispatch(
      setListOptions({
        category: 'roadSegments',
        options: {
          ...listOptions,
          sort: [
            { ...listOptions.sort[0], direction },
            {
              field: 'type',
              direction,
            },
          ],
        },
      }),
    )
  }, [dispatch, direction, listOptions])

  return { editing, direction, setDirection, noEntries: listStructure.totalItems === 0 }
}
