import styled from 'styled-components'

import Button from './StyledButton'

const SIZE_ICO = 20
const SIZE_BTN = 24

const IconButton = styled(Button)`
  width: ${SIZE_BTN}px;
  height: ${SIZE_BTN}px;
  padding: ${(SIZE_BTN - SIZE_ICO) / 2}px;
  background: transparent;

  color: ${p => p.theme.colors.$pureBlack};

  &:hover {
    background: ${p => p.theme.colors.$dodgerBlue};
    color: ${p => p.theme.colors.$pureWhite};
  }

  .o-button__inner {
    padding-left: 0;
  }

  .o-button__ico {
    width: ${SIZE_ICO}px;
    height: ${SIZE_ICO}px;
  }
`

IconButton.displayName = 'IconButton'

export default IconButton
