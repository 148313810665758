import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { resetPendingFitMap } from '@/map'
import { useAppDispatch } from '@/store'

import { updateMapStyle } from '@/features/domain/ui'
import { selectTerritoryId } from '@/features/domain/territory'

export function useEnsureGeofenceVisibility() {
  const dispatch = useAppDispatch()

  const territoryId = useSelector(selectTerritoryId)

  useEffect(() => {
    // make geofence style visible
    dispatch(
      updateMapStyle({
        type: 'geofence',
        mode: 'on',
      }),
    )

    return () => {
      resetPendingFitMap()

      // restore previously active geofence Map Styles
      dispatch(
        updateMapStyle({
          type: 'geofence',
          mode: 'off',
        }),
      )
    }
  }, [dispatch, territoryId])
}
