import { useResetCrudSelectionOnUnmount } from '@/atoms'

import { useDepotsRoot } from './hooks/useDepotsRoot'
import { List } from './List'
import { SingleView } from './Single/View'
import { BulkView } from './Bulk/View'
import { SingleEdit } from './Single/Edit'

export function DepotsRoot() {
  const { editing, depots, selectedDepots, selection } = useDepotsRoot()
  const bulk = selectedDepots.length > 1
  const single = selectedDepots.length === 1

  const depot = selectedDepots[0]

  useResetCrudSelectionOnUnmount('depots')

  return (
    <>
      <List editing={editing} depots={depots} selection={selection} />
      {single && !editing && depot && <SingleView depot={depot} />}
      {bulk && <BulkView depots={selectedDepots} selection={selection} />}
      {editing && !bulk && <SingleEdit depot={depot} />}
    </>
  )
}
