import type { AggregateCf } from '../types'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
} from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  aggregateCustomField: AggregateCf
}

const cfTestId = 'orders-view-custom-fields-cf'
const cfLabelTestId = 'orders-view-custom-fields-cf-label'
const cfValueTestId = 'orders-view-custom-fields-cf-value'

export function AggregateCustomField(props: Props) {
  const { aggregateCustomField } = props
  const { label } = aggregateCustomField
  const texts = useTexts()

  return (
    <ReadonlyBlock data-testid={cfTestId}>
      <ReadonlyLabel data-testid={cfLabelTestId}>{label}</ReadonlyLabel>

      <ReadonlyField>
        <ReadonlyFieldRow>
          {aggregateCustomField.type === 'undeclared' && (
            <ReadonlyFieldLabel data-testid={cfValueTestId} empty>
              {texts.undeclared}
            </ReadonlyFieldLabel>
          )}
          {aggregateCustomField.type === 'reported' && (
            <ReadonlyFieldLabel data-testid={cfValueTestId}>
              {aggregateCustomField.trackedValue.trim() === ''
                ? texts.emptyField
                : aggregateCustomField.trackedValue}
            </ReadonlyFieldLabel>
          )}
        </ReadonlyFieldRow>
      </ReadonlyField>
    </ReadonlyBlock>
  )
}
