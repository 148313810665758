import type { GridSortItem } from '@mui/x-data-grid-pro'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrdersGridListOptions } from '@/features/domain/lists'
import { selectOrdersGridRows } from '@/features/domain/order'
import { useMainSelection } from '@/atoms'

import { useGridColumns } from './useGridColumns'

export function useOrdersGridData() {
  const listOptions = useSelector(selectOrdersGridListOptions)
  const [selectionModel] = useMainSelection('orderSteps')
  const rows = useSelector(selectOrdersGridRows)

  const columns = useGridColumns(rows)

  const sortModel: GridSortItem[] = useMemo(() => {
    return listOptions.sort.map(o => ({
      sort: o.direction,
      field: o.field,
    }))
  }, [listOptions])

  return { rows, columns, sortModel, selectionModel }
}
