import styled from 'styled-components'

export const HeaderContainer = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 28px;
  background: ${p => p.theme.colors.$whiteSmoke};
  padding: 0 14px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

HeaderContainer.displayName = 'HeaderContainer'
