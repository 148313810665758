import type { PermissionData } from '../types'

export function computeRevokePermission(permissionData: PermissionData) {
  if (permissionData.isSimulation || !permissionData.allRoutesApproved) return 'hidden'

  if (permissionData.gotExecutionEvents) return 'disabledBecauseGotExecutionEvents'

  if (permissionData.routesInThePastCount > 0) return 'disabledBecauseInThePast'

  return 'enabled'
}
