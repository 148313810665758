import type { FormFields, FormErrors } from '../formFields'

import { useForm } from '@workwave-tidal/tidal/form-fairy'

export function useShowPodWarningCard() {
  const { values } = useForm<FormFields, FormErrors>()
  const { showAudioPod, showNotePod, showSignaturePod, showPicturePod } = values

  return showAudioPod || showNotePod || showSignaturePod || showPicturePod
}
