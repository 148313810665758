import { Box, Typography } from '@mui/material'

import { useTexts } from '../../hooks/useTexts'

interface Props {
  companyEmail: string
  showContactEmail: boolean
  useCompanyEmailAsReplyTo: boolean
}

export function CompanyEmail(props: Props) {
  const { companyEmail, showContactEmail, useCompanyEmailAsReplyTo } = props

  const texts = useTexts()

  return (
    <Box paddingBottom={4} data-testid={texts.companyEmail} display="flex" flexDirection="column">
      <Typography variant="caption" color="textSecondary">
        {texts.companyEmail}
      </Typography>
      <Typography variant="body1">{companyEmail}</Typography>
      {showContactEmail && (
        <Typography variant="caption" color="textSecondary">
          - {texts.showContactEmail}
        </Typography>
      )}
      {useCompanyEmailAsReplyTo && (
        <Typography variant="caption" color="textSecondary">
          - {texts.useCompanyEmailAsReplyTo}
        </Typography>
      )}
    </Box>
  )
}
