import { IconButton, ListItemButton, ListItemText } from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'

import { DropdownMenu } from '@/components/DropdownMenu'

import { useTexts } from '../../hooks/useTexts'

import { useMenu } from './hooks/useMenu'

interface Props {
  company: uui.domain.client.rm.Company
}

export function Menu(props: Props) {
  const { company } = props

  const { open, onRename, setOpen, onDelete, onToggleDisable } = useMenu(company)

  const texts = useTexts()

  const enableButtonTestid = company.enabled
    ? 'status-cell-disable-company'
    : 'status-cell-enable-company'

  const renameTestid = 'status-cell-rename-company'
  const deleteTestid = 'status-cell-delete-company'

  return (
    <DropdownMenu
      open={open}
      setOpen={setOpen}
      menuTestid="status-cell-menu-dropdown-menu"
      triggerTestid="status-cell-menu-trigger"
      autoWidth
      trigger={
        <IconButton>
          <MoreHoriz />
        </IconButton>
      }
    >
      <ListItemButton
        dense
        onClick={onRename}
        data-testid={renameTestid}
        data-trackid={renameTestid}
      >
        <ListItemText primary={texts.menu.rename} />
      </ListItemButton>
      <ListItemButton
        dense
        divider
        onClick={onToggleDisable}
        data-testid={enableButtonTestid}
        data-trackid={enableButtonTestid}
      >
        <ListItemText primary={texts.menu.toggleEnabled(company.enabled)} />
      </ListItemButton>
      <ListItemButton
        dense
        onClick={onDelete}
        data-testid={deleteTestid}
        data-trackid={deleteTestid}
      >
        <ListItemText
          sx={theme => ({ color: theme.palette.error.main })}
          primary={texts.menu.delete}
        />
      </ListItemButton>
    </DropdownMenu>
  )
}
