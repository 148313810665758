import { WarningTriangle } from '@/icons'
import { Block, InputFieldText } from '@/forms-legacy'
import { H3, H4, H6, WarningCard } from '@/local/components'
import { HorizontalLayout, VerticalLayout } from '@/components/layout'

import { useController } from '../hooks/useController'
import { useDragAndDrop } from '../hooks/useDragAndDrop'
import { useControllerActions } from '../hooks/useControllerActions'
import { useOpenFileDialogOnMount } from '../hooks/useOpenFileDialogOnMount'
import { useUpdateNameOnFileChange } from '../hooks/useUpdateNameOnFileChange'

import { useTexts } from '../useTexts'

export function Body() {
  const texts = useTexts()
  const {
    status,
    data: { name, fileName, lastModifiedDate },
  } = useController()
  const { handleOnDragAndDropFile, handleOnNameChange } = useControllerActions()

  const { drop } = useDragAndDrop({ onDrop: handleOnDragAndDropFile })

  useOpenFileDialogOnMount()
  useUpdateNameOnFileChange()

  return (
    <VerticalLayout marginTop={32} ref={drop}>
      {status !== 'failed' && (
        <>
          {fileName && lastModifiedDate && (
            <>
              <Block>
                <H4 color="$paleSky" uppercase>
                  {texts.territoryFileTitle}
                </H4>
              </Block>

              <Block>
                <H3 ellipsis>{fileName}</H3>
                <H6 color="$shadyLady" ellipsis>
                  {texts.lastUpdate(lastModifiedDate)}
                </H6>
              </Block>
            </>
          )}

          <Block>
            <H4 color="$paleSky" marginTop={16} uppercase>
              {texts.territoryNameTitle}
            </H4>
          </Block>
          <Block>
            <HorizontalLayout alignItems="center">
              <InputFieldText
                name="territory-name"
                value={name ?? ''}
                onChange={handleOnNameChange}
              />
            </HorizontalLayout>
          </Block>
        </>
      )}
      {status === 'failed' && (
        <Block>
          <WarningCard
            preset="warning"
            title={texts.failedDataFetchingWarningCardTitle}
            description={texts.failedDataFetchingWarningCardDescription}
            Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
          />
        </Block>
      )}
    </VerticalLayout>
  )
}
