import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    voice: translate({ id: 'setup.communication.voice' }),
    communication: translate({ id: 'app.header.setup.communication' }),
    trackingApp: translate({ id: 'app.header.setup.communication.trackingApp' }),
    preferences: translate({ id: 'app.header.setup.communication.preferences' }),
    notifications: translate({ id: 'app.header.setup.communication.notifications' }),
    disabledSectionTooltip: (rmOnly: boolean) =>
      translate({
        id: rmOnly
          ? 'app.header.customerNotifications.rmOnly.disabled.title'
          : 'app.header.customerNotifications.disabled.title',
      }),
  }))

  return api
}
