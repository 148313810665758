import { ResourceModel } from '@bryntum/schedulerpro'
import { schedulerPreferencesAtom } from '../../schedulerPreferences'

export function ordersToDoSorter(item1: ResourceModel, item2: ResourceModel) {
  const ordersToDo1 = item1.getData(
    'ordersToDo',
  ) as uui.domain.client.rm.SchedulerResource['ordersToDo']

  const ordersToDo2 = item2.getData(
    'ordersToDo',
  ) as uui.domain.client.rm.SchedulerResource['ordersToDo']

  if (schedulerPreferencesAtom.preferences.showAbsoluteValues) {
    return ordersToDo1.absolute - ordersToDo2.absolute
  }

  return ordersToDo1.percentage - ordersToDo2.percentage
}
