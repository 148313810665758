import type { FormFields } from '../../../formFields'
import type { BulkTimeWindowsError } from '../../primitives/BulkTimeWindows'

import { BulkTimeWindows } from '../../primitives/BulkTimeWindows'
import { useTexts } from './useTexts'

export function TimeWindows() {
  const texts = useTexts()

  return (
    <BulkTimeWindows<'timeWindows', FormFields, BulkTimeWindowsError>
      name="timeWindows"
      testId="timeWindows"
      label={texts.timeWindows}
    />
  )
}
