import { FlexBox, Text } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { Container } from '../../elements/Container'
import { SimulationControls } from './components/SimulationControls'
import { useSimulationName } from './hooks/useSimulationName'
import { useTexts } from './useTexts'

type Props = {
  offline: boolean
}

export function FooterSimulation(_props: Props) {
  const name = useSimulationName()
  const texts = useTexts()

  return (
    <Container color="$pureWhite" background="$darkOrange">
      <FlexBox vAlignContent="center" grow={0} shrink={0} zIndex={10}>
        <WarningTriangle size={16} color="$darkOrange" background="$pureWhite" marginRight={14} />
        <Text weight="$bold">{name}&nbsp;</Text>
        <Text weight="$semiBold">{texts.simulationAlert}</Text>
      </FlexBox>

      <FlexBox grow={1} shrink={0} zIndex={10} marginRight="auto">
        <SimulationControls />
      </FlexBox>
    </Container>
  )
}
