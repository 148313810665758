import type { DropDownItemStep } from '../../typings'

export const composeBestItemWithLabel = (
  id: string,
  label: string = '',
  selected: boolean = true,
  disabled: boolean = false,
): DropDownItemStep => {
  return {
    id,
    disabled,
    selected,
    label,
    routeStep: 'best',
    kind: 'item',
  }
}
