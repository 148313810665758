import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectSimulations } from '@/features/domain/simulation'

export const useSimulationsRoot = () => {
  const { editing } = useEditingStateWithCtx('simulation')
  const [selection] = useCrudSelection('simulations')

  const simulations = useSelector(selectSimulations)
  const selectedSimulations = useMemo(
    () => getSelectedSimulations(selection, simulations),
    [selection, simulations],
  )

  return {
    simulations,
    selectedSimulations,
    editing,
    selection,
  }
}

const getSelectedSimulations = (
  selection: workwave.DeepReadonly<string[]>,
  simulations: Record<string, uui.domain.client.rm.SimulationInfo>,
) => {
  if (Object.keys(simulations).length === 0) return []

  return selection.reduce<uui.domain.server.rm.SimulationInfo[]>((acc, id) => {
    if (!!simulations[id]) acc.push(simulations[id])
    return acc
  }, [])
}
