import type { DriverWithVehicleAndDistance, ModalVehicle, ModalDevice } from '../typings'

import { useState, useEffect } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

import { useControllerData } from './useControllerData'
import { useValidations } from './useValidations'

type ModalState = 'chooseRecipient' | 'composeMessage' | 'success' | 'failure'

type CommonFields =
  | { gpsOnly: true; message?: string }
  | { gpsOnly: false; message?: string; driverName: string }

type EmailMessage = CommonFields & {
  messageType: 'email'
  email?: string
}

type SmsMessage = CommonFields & {
  messageType: 'sms'
  phone?: string
}

interface ChooseRecipient {
  locationToShare: uui.domain.LatLng
  vehicles: ModalVehicle[]
  drivers: DriverWithVehicleAndDistance[]
  devices: ModalDevice[]
  selectedId?: string
}

export type ModalData = ChooseRecipient & (EmailMessage | SmsMessage)

export const modalId = 'sendMessageToDriver'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = (locationToShare?: uui.domain.LatLng) => {
  const { gpsOnly, drivers, devices, driverName, driverEmail, selectedId } = useControllerData()
  const close = useCloseModal()

  // INITIALIZATION
  const [options] = useState(() => ({
    createInitialState: () => ({
      status: (locationToShare ? 'chooseRecipient' : 'composeMessage') as ModalState,
      close,
      data: {
        gpsOnly,
        drivers,
        devices,
        selectedId,
        locationToShare,
        messageType: 'email',
        email: gpsOnly ? undefined : driverEmail,
        driverName: gpsOnly ? undefined : driverName,
      } as ModalData,
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const { update } = ctrl

  // EFFECTS
  const { valid: validForm } = useValidations()

  useEffect(() => {
    update({ invalid: !validForm })
  }, [validForm, update])

  return ctrl
}
