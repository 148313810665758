import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Flag(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M5 2.46875V29h2v-8.34375L27.03125 12z" />
      </svg>
    </IconContainer>
  )
}
