import styled from 'styled-components'

export const WarningIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 0;

  width: 14px;
  height: 14px;

  color: ${p => p.theme.colors.$darkOrange};

  transform: translate(26px, -50%);
`

WarningIcon.displayName = 'WarningIcon'
