import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    trialTitle: translate({ id: 'settings.messagingPlan.view.smsPlan.trialTitle' }),
    trialSubtitle: translate({ id: 'settings.messagingPlan.view.smsPlan.trialSubtitle' }),

    allowOverageTitle: translate({ id: 'settings.messagingPlan.view.smsPlan.allowOverageTitle' }),
    allowOverageSubtitle: translate({
      id: 'settings.messagingPlan.view.smsPlan.allowOverageSubtitle',
    }),

    dontAllowOverageTitle: translate({
      id: 'settings.messagingPlan.view.smsPlan.dontAllowOverageTitle',
    }),
    dontAllowOverageSubtitle: translate({
      id: 'settings.messagingPlan.view.smsPlan.dontAllowOverageSubtitle',
    }),

    expiring: (expireDate: string, daysToExpire: number) =>
      translate({
        id: 'settings.messagingPlan.view.smsPlanExpiresOn',
        values: { expireDate, daysToExpire },
      }),
    smsConsumption: (smsCount: string, percentage: string) =>
      translate({
        id: 'settings.messagingPlan.view.smsConsumption',
        values: { smsCount, percentage },
      }),
    callsConsumption: (callCredits: string, percentage: string) =>
      translate({
        id: 'settings.messagingPlan.view.callsConsumption',
        values: { callCredits, percentage },
      }),
    planUsage: (name: string) =>
      translate({ id: 'settings.messagingPlan.view.smsPlanUsage', values: { name } }),
  }))

  return api
}
