import type { ReactNode } from 'react'

import { styled } from '@mui/material'
import { Text } from '@/local/components'
import { Flag } from '@/icons'

interface Props {
  onClick?: () => void
  children?: ReactNode
}

const VehicleViolationsTagComp = styled('div', { name: 'RouteViolationTag' })(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  borderRadius: 4,
  borderWidth: 0,
  alignItems: 'center',
  outline: 0,
  display: 'flex',
  padding: '1px 6px',
  cursor: 'pointer',
}))

export function VehicleViolationsTag(props: Props) {
  const { children, onClick } = props

  return (
    <VehicleViolationsTagComp
      data-trackid="navigo-routes-overview-details-single-vehicle-violations"
      data-testid="navigo-routes-overview-details-single-vehicle-violations"
      onClick={onClick}
    >
      <Flag color="$nightRider" size={13} marginRight={4} />

      <Text size="$p3" color="$nightRider">
        {children}
      </Text>
    </VehicleViolationsTagComp>
  )
}
