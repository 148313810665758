import { useSelector } from 'react-redux'

import { selectLiveEta } from '@/features/domain/territory'
import { selectUserConfiguration } from '@/features/domain/user'

import { useCountRemainingTrackedVehicle } from '../../../../../hooks/useCountRemainingTrackedVehicle'
import { useCountRemainingVehicle } from '../../../../../hooks/useCountRemainingVehicle'

import { RmData } from './RmData'

interface Props {
  disableTrackingCheckbox: boolean
}

export function RmDataProvider(props: Props) {
  const { disableTrackingCheckbox } = props

  const userConfig = useSelector(selectUserConfiguration)
  const simulation = userConfig.planType === 'simulation'

  const hasVehicleToTrack = useCountRemainingVehicle() < 1
  const hasReachedTrackingLimit = useCountRemainingTrackedVehicle() < 1

  const hasLiveEta = useSelector(selectLiveEta)

  return (
    <RmData
      disableTrackingCheckbox={disableTrackingCheckbox}
      simulation={simulation}
      hasVehicleToTrack={hasVehicleToTrack}
      hasLiveEta={hasLiveEta}
      hasReachedTrackingLimit={hasReachedTrackingLimit}
    />
  )
}
