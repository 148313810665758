import { useCallback } from 'react'
import { eachDayOfInterval, format } from 'date-fns/esm'

export function useRmToolbarActions(
  setHighlightToday: (value: boolean) => void,
  showExportRoutesFromCalendarModal: () => void,
  setExportDates: (dates: string[]) => void,
  selectionStart: Date | undefined,
  selectionEnd: Date | undefined,
  onClose: () => void,
  setMultiterritory: (multiTerritory: boolean) => void,
) {
  const highLightToday = useCallback(() => setHighlightToday(true), [setHighlightToday])

  const unhighLightToday = useCallback(() => setHighlightToday(false), [setHighlightToday])

  const goToExport = useCallback(() => {
    if (!selectionStart) return
    showExportRoutesFromCalendarModal()
    setMultiterritory(false)
    setExportDates(
      eachDayOfInterval({ start: selectionStart, end: selectionEnd ?? selectionStart }).map(date =>
        format(date, 'yyyyMMdd'),
      ),
    )

    onClose()
  }, [
    showExportRoutesFromCalendarModal,
    setMultiterritory,
    selectionStart,
    setExportDates,
    selectionEnd,
    onClose,
  ])

  const goToMultiterritoryExport = useCallback(() => {
    if (!selectionStart) return
    showExportRoutesFromCalendarModal()

    setMultiterritory(true)
    setExportDates(
      eachDayOfInterval({ start: selectionStart, end: selectionEnd ?? selectionStart }).map(date =>
        format(date, 'yyyyMMdd'),
      ),
    )

    onClose()
  }, [
    showExportRoutesFromCalendarModal,
    setMultiterritory,
    selectionStart,
    setExportDates,
    selectionEnd,
    onClose,
  ])

  return { highLightToday, unhighLightToday, goToExport, goToMultiterritoryExport }
}
