import type { TomorrowNotificationValues } from '../../hooks/useTomorrowNotificationValues'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import { selectUserAccountPreferences } from '@/features/domain/user'
import { timeUtils } from '@/server-data'
import { useComputeSegmentsCount } from '@/hooks'

import { Field } from '../../../../components/Field'
import { useTexts } from './useTexts'

interface Props {
  values: TomorrowNotificationValues
}

export function TomorrowNotificationView(props: Props) {
  const { values } = props

  const accountPreferences = useSelector(selectUserAccountPreferences)
  const texts = useTexts()

  const notificationCutoffMins = useMemo(() => {
    return timeUtils.formatMinutesFromMidnight(
      values.notificationCutoffMins,
      accountPreferences.timeFormat,
    )
  }, [accountPreferences, values.notificationCutoffMins])

  const isEmailNotification = values.notificationWay === 'EMAIL'
  const isPhoneNotification = !isEmailNotification

  const backupNotificationText = values.backupNotification ? texts.on : texts.off
  const notificationWay = values.notificationWay === 'PHONE' ? 'SMS' : 'E-mail'

  const segmentsCount = useComputeSegmentsCount(
    { ...values.templateDefaultLimits, ...values.templateCustomLimits },
    values.templateCustom,
    values.includeMessageUnsubscriptionInstructions,
    values.includeMessageTrackingLink,
  )

  return (
    <Box paddingLeft={4} paddingRight={4}>
      {/* Notification Time */}
      <Field label={texts.notificationTime}>{notificationCutoffMins}</Field>

      {/* Notification Type */}
      <Field label={texts.notificationType}>{notificationWay}</Field>

      {isEmailNotification && (
        <>
          {/* E-mail subject */}
          <Field label={texts.emailSubject}>{values.subjectCustom}</Field>

          {/* E-mail Body */}
          <Field label={texts.emailText}>{values.templatePreview}</Field>
        </>
      )}

      {isPhoneNotification && (
        <>
          {/* SMS Body */}
          <Field label={`${texts.smsText} • ${texts.segmentsCount(segmentsCount)}`}>
            {values.templatePreview}
          </Field>

          {/* E-mail backup */}
          <Field label={texts.emailBackup}>{backupNotificationText}</Field>

          {/* E-mail backup subject */}
          {values.backupNotification && (
            <Field label={texts.emailBackupSubject}>{values.backupSubjectCustom}</Field>
          )}
        </>
      )}
    </Box>
  )
}
