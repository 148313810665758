import { type CSSProperties } from 'react'

import { SingleColumnContainer } from '../../../Scheduler/components/SingleColumnContainer'
import { UnstyledButton } from '../../../Scheduler/components/UnstyledButton'
import { Spinner } from '../../../Scheduler/components/Spinner'
import { Unlock } from '../../icons/Unlock'
import { Lock } from '../../icons/Lock'

interface Props {
  loading: boolean
  allLocked: boolean
}

const lockIconStyle = {
  '--font-size': '14px',
} as CSSProperties

const unlockIconStyle = {
  '--font-size': '14px',
  marginLeft: -3,
} as CSSProperties

export function LockedHeader(props: Props) {
  if (props.loading) {
    return (
      <div>
        <Spinner size={14} color="inherit" />
      </div>
    )
  }

  if (props.allLocked) {
    return (
      <SingleColumnContainer>
        <UnstyledButton>
          <Unlock className="o-scheduler-svg-icon--color-gray10" style={unlockIconStyle} />
        </UnstyledButton>
      </SingleColumnContainer>
    )
  }

  return (
    <SingleColumnContainer>
      <UnstyledButton>
        <Lock className="o-scheduler-svg-icon--color-black" style={lockIconStyle} />
      </UnstyledButton>
    </SingleColumnContainer>
  )
}
