import { ReactNode, Component } from 'react'

export type ChangeEventHandler = (value: string) => void

export interface Props {
  value: string
  onChange: ChangeEventHandler
  delay: number
  render: (value: string, onChange: ChangeEventHandler) => ReactNode
}

interface State {
  text: string
}

const setText = (text: string) => () => ({
  text,
})

export default class TextInputState extends Component<Props, State> {
  private timer: ReturnType<typeof setTimeout> | undefined

  constructor(props: Props) {
    super(props)

    this.state = {
      text: props.value,
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { value: prevValue } = prevProps
    const { value } = this.props

    if (value !== prevValue) {
      this.setState(setText(value))
    }
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  private resetTimer() {
    const { delay, onChange } = this.props

    if (this.timer) {
      clearTimeout(this.timer)
    }

    this.timer = setTimeout(() => {
      onChange(this.state.text)
    }, delay)
  }

  private handleOnChange = (text: string) => {
    this.setState(setText(text))
    this.resetTimer()
  }

  render() {
    const { render } = this.props
    const { text } = this.state

    return render(text, this.handleOnChange)
  }
}
