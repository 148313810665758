import { useCallback } from 'react'

import { useTexts } from './useTexts'

export function useComposeFieldsWithError() {
  const texts = useTexts()

  return useCallback(
    (errors: uui.domain.ui.forms.GpsVehicleFormValidationValues) => {
      const { device = {}, gps = {} } = errors

      const deviceEntries = Object.entries(device)
      const deviceFieldsWithError: string[] = []
      // this will filter the keys for which value is undefined
      for (const [key, value] of deviceEntries) {
        if (value) {
          deviceFieldsWithError.push(
            texts.deviceField(key as keyof uui.domain.ui.forms.DeviceDataValidation),
          )
        }
      }

      const gpsEntries = Object.entries(gps)
      const gpsFieldsWithError: string[] = []
      // this will filter the keys for which value is undefined
      for (const [key, value] of gpsEntries) {
        if (value) {
          gpsFieldsWithError.push(
            texts.gpsField(key as keyof uui.domain.ui.forms.GpsVehicleDataValidation),
          )
        }
      }

      return [...gpsFieldsWithError, ...deviceFieldsWithError]
    },
    [texts],
  )
}
