import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: (count: number) =>
      translate({ id: 'gps.places.view.bulk.title', values: { count } }),
    headerDescription: (territoryName: string) =>
      translate({
        id: 'gps.places.view.bulk.subtitle',
        values: { territoryName },
      }),
  }))

  return api
}
