import { proxy } from 'valtio'

type EditableAreaAtom = {
  area: uui.domain.ui.map.EditableArea
}

export const defaultEditableArea: uui.domain.ui.map.EditableArea = {
  id: 'none',
  mode: 'none',
  color: '#303030',
}

/**
 * This atom holds the current status of the application Editable Area.
 * An Editable Area is used to draw a Region or a Traffic Region over the Map.
 */
export const editableArea = proxy<EditableAreaAtom>({
  area: { ...defaultEditableArea },
})
