export function getRouteDriverIds(
  routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable,
  routeTrackingData: uui.domain.server.rm.RouteTrackingDataRegistryEntry['routeTrackingData'],
  executedOrderStepsByDriver?: Record<string, string[]>,
) {
  const driverIds = new Set<string>()
  const driversThatExecutedOrders = Object.keys(executedOrderStepsByDriver ?? [])

  // Retrieve driverIds from preparation tracking data
  if (routeTrackingData?.preparationTrackingData.driverId) {
    driverIds.add(routeTrackingData.preparationTrackingData.driverId)
  }

  // Retrieve driverIds from driver tracking data
  for (const driverId of routeTrackingData?.driversInExec ?? []) {
    driverIds.add(driverId)
  }

  // Retrieve driverIds from executed orders
  for (const driverId of driversThatExecutedOrders) {
    driverIds.add(driverId)
  }

  // Retrieve driverIds from closeout tracking data
  if (routeTrackingData?.closeOutTrackingData.driverId) {
    driverIds.add(routeTrackingData.closeOutTrackingData.driverId)
  }

  // Retrieve driverIds from route
  driverIds.add(routeInfo.driverId)

  return [...driverIds]
}
