import { useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@mui/styles'

import { DropdownMenu, MenuTrigger } from '@/components/DropdownMenu'

import { RmViewerLinks } from './components/RmViewerLinks'
import { GpsOnlyLinks } from './components/GpsOnlyLinks'
import { RmGuestLinks } from './components/RmGuestLinks'
import { Rm360Links } from './components/Rm360Links'
import { RmLinks } from './components/RmLinks'

import { useAppLocationLabel } from './hooks/useAppLocationLabel'

interface Props {
  userType: uui.domain.UserType
}

const useStyles = makeStyles({
  trigger: {
    width: 150,
  },
})

export function DropdownNav(props: Props) {
  const { userType } = props

  const classes = useStyles()
  const appLocationLabel = useAppLocationLabel()

  const [open, setOpen] = useState(false)
  const [label, setLabel] = useState(appLocationLabel)

  const Nav = useMemo(() => {
    switch (userType) {
      case 'rmOnly':
        return <RmLinks />

      case 'rmGuest':
        return <RmGuestLinks />

      case 'gpsOnly':
        return <GpsOnlyLinks />

      case 'courier':
      case 'rmViewer':
        return <RmViewerLinks />

      case 'rm360':
      case 'rm360Legacy':
      case 'rm360VirtualDevicesOnly':
      case 'rm360PhysicalDevicesOnly':
        return <Rm360Links />
    }
  }, [userType])

  useEffect(() => {
    setOpen(false)
    setLabel(appLocationLabel)
  }, [appLocationLabel])

  return (
    <DropdownMenu
      open={open}
      setOpen={setOpen}
      trigger={
        <MenuTrigger
          menuOpen={open}
          className={classes.trigger}
          testid="header__collapsed-nav-trigger"
        >
          {label}
        </MenuTrigger>
      }
    >
      <div data-testid="header__collapsed-nav-container">{Nav}</div>
    </DropdownMenu>
  )
}
