import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import OlMap from 'ol/Map'
import View from 'ol/View'

import { selectUserConfiguration, selectUserProfile } from '@/features/domain/user'
import { useIsUnmounted } from '@/hooks'
import { useMap } from '@/map'
import { gis } from '@/server-data'

import { useControllerById } from '../../../../../../hooks/useController'
import { useTexts } from '../useTexts'

import { createLocationEditorMapInteractions } from './utils/createMapInteractions'
import { createLocationEditorPinInteraction } from './utils/createLocationEditorPinInteraction'
import { createLocationEditorTileLayer } from './utils/createLocationEditorTileLayer'
import { createLocationEditorPinLayer } from './utils/createLocationEditorPinLayer'

import { registerOverviewMapControl } from './controls/registerOverviewMapControl'
import { registerScaleControl } from './controls/registerScaleControl'

import { useGeocodePinPosition } from './hooks/useGeocodePinPosition'
import { registerZoomControl } from './controls/registerZoomControl'

export function useLocationEditorMap(
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery',
  pinType: uui.domain.ui.map.LocationPinType,
) {
  const {
    data: { center },
    updateData,
  } = useControllerById(pinId)

  const [pinLatLng, setPinLatLng] = useState<uui.domain.LatLng>(center)

  // Retrieve the distance format from the user profile
  const { distanceFormat } = useSelector(selectUserProfile).account.preferences
  const { language } = useSelector(selectUserConfiguration)

  // Retrieve some data from the main map instance
  const [{ map }] = useMap()

  // Create a ref to the map instance
  const mapInstanceRef = useRef<OlMap>()

  // Create a ref to the center coordinates
  const centerRef = useRef(center)
  useEffect(() => {
    centerRef.current = center
  }, [center])

  const isUnmounted = useIsUnmounted()
  const texts = useTexts()

  useEffect(() => {
    const createMap = () => {
      // If the map instance already exists, return
      if (mapInstanceRef.current) return

      // If the component is unmounted, return
      if (isUnmounted()) return

      // Retrieve the center coordinates
      const centerCoords = gis.fromLatLngToCoordinate(centerRef.current)

      // Create the tiles layer
      const tilesLayer = createLocationEditorTileLayer('hybrid', language)

      // Create the pin layer
      const { layer: pinLayer, source: pinSource } = createLocationEditorPinLayer(
        centerCoords,
        pinType,
      )

      // Create the map instance
      mapInstanceRef.current = new OlMap({
        // Set the map target
        target: 'modalMap',

        // Create the map interactions
        interactions: createLocationEditorMapInteractions(),

        // Create the map layers
        layers: [tilesLayer, pinLayer],

        // Create the map view
        view: new View({
          zoom: 18,
          center: centerCoords,
          minZoom: 3,
          maxZoom: 19,
        }),
      })

      // Register the overview map control
      registerOverviewMapControl(mapInstanceRef.current, 'hybrid', tilesLayer, texts)

      registerZoomControl(mapInstanceRef.current)

      const useMetricSystem = distanceFormat === 'METRIC'

      // Register the scale control
      registerScaleControl(mapInstanceRef.current, useMetricSystem)

      // Add the pin interaction
      mapInstanceRef.current.addInteraction(
        createLocationEditorPinInteraction(
          mapInstanceRef.current,
          pinSource,
          pinLayer,
          setPinLatLng,
        ),
      )
    }

    // Wait for the map to be ready
    setTimeout(createMap, 100)

    // Dispose the map instance when the component unmounts
    return () => {
      mapInstanceRef.current?.dispose()
    }
  }, [map, texts, pinType, distanceFormat, language, isUnmounted, updateData])

  // Geocode the pin position
  useGeocodePinPosition(pinId, pinLatLng, mapInstanceRef.current)

  return mapInstanceRef
}
