import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, resetCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectRegionsListStructure } from '@/features/domain/lists'

export const useDataForList = () => {
  const { setEditing } = useEditingStateWithCtx('region')
  const structure = useSelector(selectRegionsListStructure)
  const [selection, setSelection] = useCrudSelection('regions')

  const lastSelectedRegion = selection.length > 0 ? selection[selection.length - 1] : undefined

  const setCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  const deselectAll = () => resetCrudSelection('regions')

  return {
    structure,
    setCreate,
    setEditing,
    lastSelectedRegion,
    setSelection,
    deselectAll,
  }
}
