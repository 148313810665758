import { ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function SuccessFooter() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalFooterButton
      variant="contained"
      onClick={close}
      testid="send-message-to-driver-modal-success-ok-button"
      trackid="send-message-to-driver-modal-success-ok-button"
    >
      {texts.ok}
    </ModalFooterButton>
  )
}
