import { useEffect, useRef } from 'react'
import { type SchedulerProConfig } from '@bryntum/schedulerpro'

import { ControlledNavigateFunction } from '@/routing'
import { MovePairedOrdersPayload } from '@/components/modals/MovePairedOrdersModal'

import { startSchedulerSync } from '../../../sync'
import { schedulerInstance } from '../../../atoms/project/project'
import { createScheduler } from '../../../utils'

import { setInitialSchedulerZoom } from '../utils/setInitialSchedulerZoom'
import { persistSettings } from '../utils/persistSettings'

export function useInitializeScheduler(
  targetId: string,
  navigate: ControlledNavigateFunction<uui.routing.Routing | uui.routing.Setup>,
  showMovePairedOrdersModal: (payload: MovePairedOrdersPayload) => void,
  onHeaderMenuBeforeShow: SchedulerProConfig['onHeaderMenuBeforeShow'],
) {
  const firstDestroyDone = useRef<boolean>(false)

  useEffect(() => {
    // HACK: We need to prevent a save on first destroy (caused by React 18)
    if (process.env.NODE_ENV === 'development') {
      if (!firstDestroyDone.current) {
        firstDestroyDone.current = true
        return
      }
    }

    // Create scheduler
    schedulerInstance.instance = createScheduler(
      targetId,
      navigate,
      showMovePairedOrdersModal,
      onHeaderMenuBeforeShow,
    )

    // Start data sync
    const stopDataSync = startSchedulerSync(schedulerInstance.instance)

    // Set initial zoom
    setInitialSchedulerZoom(schedulerInstance.instance)

    return () => {
      // Unsuscribe from data sync
      stopDataSync()

      // Store last visible zoom before destroying the instance
      persistSettings(schedulerInstance.instance)

      // Destroy the instance
      schedulerInstance.instance?.destroy()
      schedulerInstance.instance = undefined
    }
  }, [targetId, navigate, showMovePairedOrdersModal, onHeaderMenuBeforeShow])

  return schedulerInstance.instance
}
