import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'settings.languageAndFormats.view.headerTitle' }),
    headerDescription: translate({ id: 'settings.languageAndFormats.view.headerDescription' }),

    language: translate({ id: 'settings.languageAndFormats.view.language' }),
    dateFormat: translate({ id: 'settings.languageAndFormats.view.dateFormat' }),
    timeFormat: translate({ id: 'settings.languageAndFormats.view.timeFormat' }),
    distanceFormat: translate({ id: 'settings.languageAndFormats.view.distanceFormat' }),
    currency: translate({ id: 'settings.languageAndFormats.view.currency' }),
    distanceFormatValue: (format: uui.domain.DistanceFormat) =>
      translate({
        id:
          format === 'METRIC' ? 'global.unitsOfMeasure.kilometers' : 'global.unitsOfMeasure.miles',
      }),
  }))

  return api
}
