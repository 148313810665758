import type { FormFields, FormErrors } from '../../../formFields'
import type { FormValidator, FormError } from '@workwave-tidal/tidal/form-fairy'
import type { ValidationTexts } from '../useTexts'

import { store } from '@/store'
import { isUsernameAvailable } from '@/features/domain/user'

export const createValidateUsername =
  (texts: ValidationTexts): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const { value, required } = formApi.getField('username')
    const { username: originalUsername } = formApi.getInitialValues()

    const requiredError: FormError<keyof FormFields> = {
      id: 'username-required',
      field: 'username',
      message: texts.usernameRequired,
      priority: 5,
    }

    const minLengthError: FormError<keyof FormFields> = {
      id: 'username-min-length',
      field: 'username',
      message: texts.usernameMinLength,
      priority: 4,
    }

    const maxLengthError: FormError<keyof FormFields> = {
      id: 'username-max-length',
      field: 'username',
      message: texts.usernameMaxLength,
      priority: 3,
    }

    const allowedCharactersError: FormError<keyof FormFields> = {
      id: 'username-not-allowed-chars',
      field: 'username',
      message: texts.allowedCharacters,
      priority: 2,
    }

    const usernameAvailableError: FormError<keyof FormFields> = {
      id: 'username-unavailable',
      field: 'username',
      message: texts.usernameNonUnique,
      priority: 1,
    }

    const empty = value.trim().length === 0
    const invalidMaxLength = value.trim().length > 50
    const invalidMinLength = value.trim().length < 6
    const invalidCharacters = /\s/.test(value.trim())
    const isValid = !empty && !invalidMaxLength && !invalidMinLength && !invalidCharacters
    const isChanged = originalUsername !== value.trim()

    let usernameUnavailable = false

    if (isValid && isChanged) {
      const thunkResult = await store.dispatch(isUsernameAvailable(value.trim()))

      if (isUsernameAvailable.rejected.match(thunkResult)) {
        throw new Error(thunkResult.payload?.message ?? 'Internal error')
      }

      usernameUnavailable = !thunkResult.payload
    }

    return {
      [requiredError.id]: required && empty ? requiredError : null,
      [minLengthError.id]: invalidMinLength ? minLengthError : null,
      [maxLengthError.id]: invalidMaxLength ? maxLengthError : null,
      [allowedCharactersError.id]: invalidCharacters ? allowedCharactersError : null,
      [usernameAvailableError.id]: usernameUnavailable ? usernameAvailableError : null,
    }
  }
