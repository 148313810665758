import type { ReactChild, ReactFragment } from 'react'

import { Box } from '@mui/material'

interface Props {
  children: ReactChild | ReactFragment
  paddingTop?: number
  paddingLeft?: number
  paddingRight?: number
  paddingBottom?: number
}

export function ViewFieldsLayout(props: Props) {
  const { children, paddingTop = 0, paddingLeft = 4, paddingRight = 4, paddingBottom = 0 } = props

  return (
    <Box
      paddingTop={paddingTop}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
    >
      {children}
    </Box>
  )
}
