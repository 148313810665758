import { CardCosmetic, SelectionAsRecord } from '@/components/Calendar/typings'

interface Params {
  isToday: boolean
  disabled: boolean
  selected: boolean
  dateAsString: string
  isOnTheEdge: boolean
  highlightToday?: boolean
  selectableInterval?: SelectionAsRecord
}

export function getCosmetic(params: Params) {
  const {
    isToday,
    disabled,
    selected,
    isOnTheEdge,
    dateAsString,
    highlightToday,
    selectableInterval,
  } = params

  const grayedOut = (!!selectableInterval && !selectableInterval[dateAsString]) || disabled

  let cosmetic: CardCosmetic = grayedOut
    ? 'grayed-out'
    : isOnTheEdge
    ? 'edge-of-selection'
    : selected
    ? 'selected'
    : 'normal'

  if (isToday && highlightToday && cosmetic !== 'selected' && cosmetic !== 'edge-of-selection') {
    cosmetic = 'hovered'
  }

  return cosmetic
}
