import type Feature from 'ol/Feature'

import CircleStyle from 'ol/style/Circle'
import { Fill, Stroke, Style } from 'ol/style'

import Polygon from 'ol/geom/Polygon'
import Geometry from 'ol/geom/Geometry'
import MultiPoint from 'ol/geom/MultiPoint'

import { drawStyleCommon } from './common'

const common = drawStyleCommon()

export const normalPoint = new Style({
  image: new CircleStyle({
    radius: common.pointRadius,
    fill: new Fill({
      color: common.black(1),
    }),
    stroke: new Stroke({
      color: common.white(),
      width: common.pointBorderWidth,
    }),
  }),
  geometry: (feature: Feature<Geometry>) => {
    // return the coordinates of the first ring of the polygon
    const geometry = feature?.getGeometry()
    if (geometry instanceof Geometry && geometry.getType() === 'Polygon') {
      const poly = geometry as Polygon
      const coordinates = poly.getCoordinates().at(0)

      if (coordinates) return new MultiPoint(coordinates)
    }
  },
})
