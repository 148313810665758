import type { AsyncThunkApiConfig } from '@/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

export const requestSmsSubscriptionChange = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  void,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/account/requestSmsSubscriptionChange', async (_params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/account/requestSmsSubscriptionChange', {
      type: 'rpc/account/requestSmsSubscriptionChange',
      category: 'rpc',
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        error: result,
        message: result.errorMessage,
      })
    }
  } catch (error) {
    return thunkApi.rejectWithValue({
      error,
      type: 'unknown',
      message: 'Failed to change password',
    })
  }
})
