import type { FormValues } from '@workwave-tidal/tidal/form-fairy'
import type { RouteDetailEntry } from '../../../../../../types'
import type { FormFields } from '../../formFields'

export function computePayload(
  values: FormValues<FormFields>,
  routeDetailsData: RouteDetailEntry[],
  vehicleId: string,
  date: string,
) {
  const events = routeDetailsData.reduce<uui.domain.ui.forms.RouteExecutionEventsUpdate>(
    (acc, detail) => {
      if (detail.type === 'placeholder') return acc

      if (detail.start && values.drivers[detail.driverId].start !== -1) {
        acc.push({
          eventType: 'departure',
          vehicleId,
          driverId: detail.driverId,
          stepId: null,
          latLng:
            detail.start.type === 'routeStart'
              ? detail.start.data.tracked.location
              : detail.start.data.location,
          data: { sec: Math.round(values.drivers[detail.driverId].start * 60) },
          date,
        })
      }

      if (detail.end && values.drivers[detail.driverId].end !== -1) {
        acc.push({
          eventType: 'arrival',
          vehicleId,
          driverId: detail.driverId,
          stepId: null,
          latLng:
            detail.end.type === 'routeEnd'
              ? detail.end.data.tracked.location
              : detail.end.data.location,
          data: { sec: Math.round(values.drivers[detail.driverId].end * 60) },
          date,
        })
      }

      if (
        (detail.preparation?.type === 'complete' || detail.preparation?.type === 'tracked-only') &&
        detail.preparation.tracked.start &&
        values.preparationTimeStart !== -1
      ) {
        acc.push({
          eventType: 'preparationTimeIn',
          vehicleId,
          driverId: detail.driverId,
          stepId: null,
          latLng: detail.preparation.tracked.location,
          data: { sec: Math.round(values.preparationTimeStart * 60) },
          date,
        })
      }

      if (
        (detail.preparation?.type === 'complete' || detail.preparation?.type === 'tracked-only') &&
        detail.preparation.tracked.end &&
        values.preparationTimeEnd !== -1
      ) {
        acc.push({
          eventType: 'preparationTimeOut',
          vehicleId,
          driverId: detail.driverId,
          stepId: null,
          latLng: detail.preparation.tracked.location,
          data: { sec: Math.round(values.preparationTimeEnd * 60) },
          date,
        })
      }

      if (
        (detail.closeout?.type === 'complete' || detail.closeout?.type === 'tracked-only') &&
        detail.closeout.tracked.start &&
        values.closeoutTimeStart !== -1
      ) {
        acc.push({
          eventType: 'closeOutTimeIn',
          vehicleId,
          driverId: detail.driverId,
          stepId: null,
          latLng: detail.closeout.tracked.location,
          data: { sec: Math.round(values.closeoutTimeStart * 60) },
          date,
        })
      }

      if (
        (detail.closeout?.type === 'complete' || detail.closeout?.type === 'tracked-only') &&
        detail.closeout.tracked.end &&
        values.closeoutTimeEnd !== -1
      ) {
        acc.push({
          eventType: 'closeOutTimeOut',
          vehicleId,
          driverId: detail.driverId,
          stepId: null,
          latLng: detail.closeout.tracked.location,
          data: { sec: Math.round(values.closeoutTimeEnd * 60) },
          date,
        })
      }

      return acc
    },
    [],
  )

  return events
}
