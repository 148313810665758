import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type Payload = {
  seconds: number
  routeId: string
  orderStepId: string
  sourceRouteId: string
  moveMate?: boolean
}

/**
 * Move an order step.
 *
 * @private
 */
export const moveOrderStep = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/order/move', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/order/move', {
      category: 'rpc',
      type: 'rpc/order/move',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    } else {
      const dndEvent: uui.domain.pendo.DragAndDrop = {
        type: 'Drag & Drop',
        metadata: {
          paired: !!payload.moveMate,
        },
      }
      thunkApi.extra.journal.pendo(dndEvent.type, {
        info: dndEvent.metadata,
      })

      if (!!payload.moveMate) {
        const event: uui.domain.pendo.DragAndDropPaired = {
          type: 'Drag & Drop paired',
        }
        thunkApi.extra.journal.pendo(event.type)
      } else {
        const event: uui.domain.pendo.DragAndDropSimple = {
          type: 'Drag & Drop no paired',
        }
        thunkApi.extra.journal.pendo(event.type)
      }
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {moveOrderStep}`,
      { tags: ['rpc', 'order'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Move orderStep with id: ${payload.orderStepId} failed`,
      error,
    })
  }
})
