import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { gis } from '@/server-data'
import { useInspectPin } from '@/map'
import { selectLivePositions } from '@/features/domain/device'
import { selectUserConfiguration } from '@/features/domain/user'
import { convertKmToMiles } from '@/local/server-data/utils/smartUtils/conversion'

export function useDistancesByDeviceId() {
  const livePositions = useSelector(selectLivePositions)
  const [inspectPin] = useInspectPin()
  const metric = useSelector(selectUserConfiguration).distanceFormat === 'METRIC'
  const pinLatLng = inspectPin?.latLng

  return useMemo(() => {
    if (!pinLatLng) return {}

    return Object.entries(livePositions).reduce<Record<string, number>>(
      (acc, [deviceId, gpsInfo]) => {
        const distanceInKm = normalizeValue(gis.distanceBetween(gpsInfo.latLng, pinLatLng) / 1000)
        const distanceInMi = normalizeValue(convertKmToMiles(distanceInKm))

        acc[deviceId] = metric ? distanceInKm : distanceInMi

        return acc
      },
      {},
    )
  }, [pinLatLng, livePositions, metric])
}

function normalizeValue(value: number) {
  if (value < 1) {
    return parseFloat(value.toFixed(2))
  }

  return Math.trunc(value)
}
