import styled from 'styled-components'
import { ValidColor, WithTheme } from 'local/components/src/theme'

type ThemedProps = WithTheme<Props>

interface Props {
  tint?: ValidColor
}

const getTint = ({ tint, theme }: ThemedProps): string => {
  const value = tint ? theme.colors[tint] : undefined
  return value ? value : 'transparent'
}

const triangleWidth = 6
const triangleHeight = 10

const span = styled.span<Props>``
export const Triangle = styled(span).attrs<Props>(() => ({
  className: 'o-triangle',
}))`
  position: absolute;
  top: 0;
  right: -${triangleWidth}px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${triangleHeight}px 0 ${triangleHeight}px ${triangleWidth}px;
  border-color: transparent transparent transparent ${getTint};
`

Triangle.displayName = 'Triangle'
