import type { RoadSegmentFormValues } from '../types'

import { useState, useCallback } from 'react'

import { updateRoadSegment, createRoadSegment } from '@/features/domain/roadSegment'
import { setCrudSelection, useListApi, useResetEditingState } from '@/atoms'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { parseRoadSegmentFromFormValues } from '../utils/parseRoadSegmentFromFormValues'

export const useOnSubmit = () => {
  const stopEditing = useResetEditingState()
  const { scrollTo } = useListApi('roadSegments')
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const [submitting, setSubmitting] = useState<boolean>(false)

  const onCreate = useCallback(
    async (formValues: RoadSegmentFormValues) => {
      try {
        setSubmitting(true)

        const roadSegment = parseRoadSegmentFromFormValues(formValues)
        const thunkResult = await dispatch(createRoadSegment(roadSegment))

        if (!createRoadSegment.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()

        setCrudSelection('routingSegments', thunkResult.payload)

        scrollTo(thunkResult.payload[0])
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, scrollTo, toast],
  )

  const onUpdate = useCallback(
    async (formValues: RoadSegmentFormValues) => {
      try {
        if (!formValues.id) {
          throw new Error('a valid id is required to update a route segment')
        }
        setSubmitting(true)

        const roadSegment = parseRoadSegmentFromFormValues(formValues)
        const thunkResult = await dispatch(updateRoadSegment({ id: formValues.id, ...roadSegment }))

        if (!updateRoadSegment.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, toast],
  )

  return {
    submitting,
    onCreate,
    onUpdate,
  }
}
