import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: (isEmail: boolean) =>
      translate({
        id: isEmail
          ? 'orders.smsNotifications.notification.kind.email'
          : 'orders.smsNotifications.notification.kind.sms',
      }),
    sentOn: (date: string, recipient: string) =>
      translate({
        id: 'orders.smsNotifications.notification.modal.sentOn',
        values: { date, recipient },
      }),

    bodyTitle: translate({
      id: 'orders.smsNotifications.notification.modal.bodyTitle',
    }),

    close: translate({
      id: 'orders.smsNotifications.notification.modal.close',
    }),

    copyText: translate({
      id: 'orders.smsNotifications.notification.modal.copyText',
    }),

    copyTextCopied: (success: boolean) =>
      translate({
        id: success
          ? 'orders.smsNotifications.notification.modal.copyText.copied.success'
          : 'orders.smsNotifications.notification.modal.copyText.copied.failed',
      }),
  }))

  return api
}
