import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { DirectionsCar } from '@mui/icons-material'
import { useTheme } from '@mui/material'

import {
  ListItem,
  ListItemAvatarRounded,
  ListItemContent,
  ListItemRow,
  ListItemTitle,
} from '@/components/List'
import { type RenderItemProps } from '@/components/StructuredVirtualList'
import { selectPhysicalDevicesList } from '@/features/domain/device'

import { GeofenceIcon } from '../../../components/GeofenceIcon'
import { Radius } from '../../../components/icons/Radius'

import { useTexts } from './useTexts'

type Props = Extract<RenderItemProps<'geofences'>, { type: 'item' }>

export const GeofenceItem = memo((props: Props) => {
  const { selected, item, onItemClick, onItemDoubleClick } = props

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])

  const physicalDevices = useSelector(selectPhysicalDevicesList)
  const texts = useTexts()

  const { palette } = useTheme()

  const totalCount = physicalDevices.length
  const devicesCount = item.deviceIds.length
  const allDevices = devicesCount === totalCount && totalCount > 0

  return (
    <ListItem
      onClick={onClick}
      selected={selected}
      onDoubleClick={onDoubleClick}
      avatar={
        <ListItemAvatarRounded color="transparent" textColor={palette.text.primary}>
          <GeofenceIcon type={item.type} />
        </ListItemAvatarRounded>
      }
    >
      <ListItemTitle>{item.name}</ListItemTitle>

      <ListItemRow testId="geofence-radius-row">
        <ListItemContent icon={<Radius />}>
          {`${texts.radius} ${getRadiusLabel(item.radius)}`}
        </ListItemContent>
      </ListItemRow>

      <ListItemRow testId="geofence-vehicles-row">
        <ListItemContent icon={<DirectionsCar />}>
          {texts.linkedVehicles(allDevices, devicesCount)}
        </ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})
GeofenceItem.displayName = 'GeofenceItem'

function getRadiusLabel(radius: number) {
  if (radius > 999) {
    return `${(radius / 1000).toFixed(1)}km`
  }
  return `${radius}m`
}
