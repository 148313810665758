import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'settings.userManagement.listUsers.title' }),
    menu: {
      newUser: translate({ id: 'settings.userManagement.listUsers.menu.newUser' }),
      sortAsc: translate({ id: 'settings.userManagement.listUsers.menu.sortASC' }),
      sortDesc: translate({ id: 'settings.userManagement.listUsers.menu.sortDESC' }),
      selectAllDisabled: translate({
        id: 'settings.userManagement.listUsers.menu.selectAll.disabled',
      }),
      selectAll: (count: number) =>
        translate({ id: 'settings.userManagement.listUsers.menu.selectAll', values: { count } }),
      deselectAll: (count: number) =>
        translate({ id: 'settings.userManagement.listUsers.menu.deselectAll', values: { count } }),
    },
    search: {
      placeholder: translate({ id: 'settings.userManagement.listUsers.search.placeholder' }),
    },
    noUsers: translate({ id: 'settings.userManagement.listUsers.noUsers' }),
    addUser: translate({ id: 'settings.userManagement.listUsers.addUser' }),
    tooltips: {
      addUser: translate({ id: 'settings.userManagement.tooltips.addUser' }),
      more: translate({ id: 'settings.userManagement.tooltips.more' }),
    },
    selectAll: translate({ id: 'userManagement.view.selectAll' }),
    unselectAll: translate({ id: 'userManagement.view.unselectAll' }),
    disabled: translate({ id: 'userManagement.view.disabled' }),

    selectBtn: (allSelected: boolean): string =>
      translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' }),

    noEntries: {
      title: translate({ id: 'users.list.noEntries.title' }),
      description: translate({ id: 'users.list.noEntries.description' }),
      action: translate({ id: 'users.list.noEntries.action' }),
    },
    noResults: {
      title: translate({ id: 'users.list.noResults.title' }),
      description: translate({ id: 'users.list.noResults.description' }),
    },
  }))

  return api
}
