import { hasTrackedEvents } from './hasTrackedEvents'

export function hasEditPermission(
  routeTrackingData: uui.domain.client.rm.RouteTrackingData['routeTrackingData'],
  driverTrackingData: Record<string, uui.domain.server.rm.DriverTrackingData>,
  userConfig: uui.domain.UserConfiguration,
) {
  if (userConfig.userType === 'rmOnly') return 'isRmOnly' as const

  if (!hasTrackedEvents(routeTrackingData, driverTrackingData)) {
    return 'gotNoTrackedEvents' as const
  }

  return 'allowed' as const
}
