import type { FormApi } from 'final-form'
import type { DepotFormValues } from '../typings'

import { useState, useCallback, useRef, useEffect } from 'react'

import { updateDepot, createDepot } from '@/features/domain/depot'
import { setCrudSelection, useListApi, useResetEditingState } from '@/atoms'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { parseDepotFromFormValues } from '../utils'

export const useOnSubmit = () => {
  const stopEditing = useResetEditingState()
  const { scrollTo } = useListApi('depots')
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const [submitting, setSubmitting] = useState<boolean>(false)
  const mounted = useRef<boolean>(false)

  const onCreate = useCallback(
    async (formValues: DepotFormValues) => {
      try {
        setSubmitting(true)

        const depot = parseDepotFromFormValues(formValues)
        const thunkResult = await dispatch(createDepot(depot))

        if (mounted.current) {
          setSubmitting(false)
        }

        if (!createDepot.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()

        if (!mounted.current) return

        setCrudSelection('depots', thunkResult.payload)

        scrollTo(thunkResult.payload[0])
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, scrollTo, toast],
  )

  const onUpdate = useCallback(
    async (
      formValues: DepotFormValues,
      formApi: FormApi<DepotFormValues, Partial<DepotFormValues>>,
    ) => {
      try {
        const pristine = formApi.getState().pristine
        if (pristine) {
          stopEditing()
          return
        }

        setSubmitting(true)

        const depot = parseDepotFromFormValues(formValues)
        const thunkResult = await dispatch(updateDepot(depot))

        if (mounted.current) {
          setSubmitting(false)
        }

        if (!updateDepot.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, toast],
  )

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return {
    submitting,
    onCreate,
    onUpdate,
  }
}
