import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Export(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M2 14.562a.539.539 0 00.538.438h10.924a.54.54 0 00.538-.54V6.375a.54.54 0 00-.538-.539h-2.393a.3.3 0 00-.3.3v.478a.3.3 0 00.3.3h1.854v7.009H3.077V6.913H4.93a.3.3 0 00.3-.3v-.478a.3.3 0 00-.3-.3H2.538a.54.54 0 00-.538.54V14.56zM5.341 3.864a.312.312 0 00.32.337h1.245s.202 5.526.286 5.644c.122.172.478.286.808.286.332 0 .736-.114.858-.286.084-.118.236-5.644.236-5.644h1.245c.122 0 .23-.061.286-.169a.329.329 0 00-.017-.336C9.727 2.39 8.261 1.13 8.202 1.084A.326.326 0 008 1a.331.331 0 00-.202.084c-.059.047-1.525 1.306-2.406 2.612a.322.322 0 00-.05.168z" />
      </svg>
    </IconContainer>
  )
}
