import type {
  FormField,
  FormSingleField,
  NarrowFieldsByValueType,
} from '@workwave-tidal/tidal/form-fairy'
import type { BulkLoads, BulkLoadExact } from '../../../../types'
import type { BulkLoadsError } from '../types'

import { useCallback } from 'react'
import { produce } from 'immer'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'

type RequiredFormField = BulkLoads

// type Name = FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>

export function useActions<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
>(name: FIELD_NAME, onCloseDialog: () => void) {
  // const { onChange, validate, value, onCloseDialog } = props

  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const {
    field: { value },
    fieldApi: { change: onChange, validate },
  } = useFormField<FIELD_NAME, PartialForm, BulkLoadsError>(name)

  const onAddLoad = useCallback(
    (loadName: string, warning?: boolean) => {
      const updatedFieldValue = produce(value, draft => {
        for (const orderId of Object.keys(draft.loadsDistribution)) {
          draft.loadsDistribution[orderId].push(loadName)
        }

        draft.loads[loadName] = {
          name: loadName,
          status: 'exact',
          value: '',
          addedTimestamp: new Date().getTime(),
          warning,
        }
      })
      onChange(updatedFieldValue)
      validate()
    },
    [validate, onChange, value],
  )

  const onUpdateLoad = useCallback(
    (loadName: string, loadValue: string) => {
      const updatedFieldValue = produce(value, draft => {
        if (draft.loads[loadName].status !== 'exact') {
          if (process.env.NODE_ENV === 'development') {
            throw new Error('BulkLoads: trying to update a non exact load')
          }
          return
        }
        const load = draft.loads[loadName] as BulkLoadExact
        load.value = loadValue
        load.mixed = false
      })
      onChange(updatedFieldValue)
      validate()
    },
    [validate, onChange, value],
  )

  const onDeleteLoad = useCallback(
    (loadName: string) => {
      const updatedFieldValue = produce(value, draft => {
        for (const orderId of Object.keys(draft.loadsDistribution)) {
          const loads = draft.loadsDistribution[orderId]
          const index = loads.indexOf(loadName)
          if (index > -1) {
            loads.splice(index, 1)
          }
        }

        draft.loads[loadName] = {
          name: loadName,
          status: 'deleted',
        }
      })
      onChange(updatedFieldValue)
      validate()
    },
    [validate, onChange, value],
  )

  const onDeleteAllLoads = useCallback(() => {
    const updatedFieldValue = produce(value, draft => {
      for (const load of Object.values(draft.loads)) {
        if (load.status !== 'deleted') {
          for (const orderId of Object.keys(draft.loadsDistribution)) {
            const loads = draft.loadsDistribution[orderId]
            const index = loads.indexOf(load.name)
            if (index > -1) {
              loads.splice(index, 1)
            }
          }

          draft.loads[load.name] = {
            name: load.name,
            status: 'deleted',
          }
        }
      }
    })
    onChange(updatedFieldValue)
    validate()
  }, [validate, onChange, value])

  const onAddLoadAndCloseDialog = useCallback(
    (tagName: string, warning?: boolean) => {
      onAddLoad(tagName, warning)
      onCloseDialog()
    },
    [onAddLoad, onCloseDialog],
  )

  return {
    onAddLoad,
    onAddLoadAndCloseDialog,
    onUpdateLoad,
    onDeleteAllLoads,
    onDeleteLoad,
  }
}
