import { useRef } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const api = useRef({
    cancel: translate({ id: 'settings.reasonsForNotCompletedOrders.copyModal.button.cancel' }),
    add: translate({
      id: 'settings.reasonsForNotCompletedOrders.copyModal.button.add',
    }),
    replace: translate({
      id: 'settings.reasonsForNotCompletedOrders.copyModal.modalReplaceReasonsMessageTitle',
    }),
    modalTitle: translate({
      id: 'settings.reasonsForNotCompletedOrders.copyModal.modalCopyReasonsTitle',
    }),
    modalSubTitle: translate({
      id: 'settings.reasonsForNotCompletedOrders.copyModal.modalCopyReasonsSubtitle',
    }),
    territoryDropdownTitle: translate({
      id: 'settings.reasonsForNotCompletedOrders.copyModal.territoriesTitle',
    }),
    replaceWarningCardTitle: translate({
      id: 'settings.reasonsForNotCompletedOrders.copyModal.modalReplaceReasonsMessageTitle',
    }),
    replaceWarningCardDescription: translate({
      id: 'settings.reasonsForNotCompletedOrders.copyModal.modalReplaceReasonsMessageDescription',
    }),
    addWarningCardTitle: translate({
      id: 'settings.reasonsForNotCompletedOrders.copyModal.modalAddReasonsMessageTitle',
    }),
    addWarningCardDescription: translate({
      id: 'settings.reasonsForNotCompletedOrders.copyModal.modalAddReasonsMessageDescription',
    }),

    reasons: (counter: number) => {
      if (counter === 0) return '-'

      return translate({
        values: { counter },
        id: 'settings.reasonsForNotCompletedOrders.copyModal.reasonsCounter',
      })
    },

    success: translate({
      id: 'settings.reasonsForNotCompletedOrders.copyModal.success.messageDescription',
    }),
    error: translate({
      id: 'settings.reasonsForNotCompletedOrders.copyModal.failure.messageDescription',
    }),
  })

  return api.current
}
