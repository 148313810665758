import { useSelector } from 'react-redux'

import { Tab as UiTab, Tabs as UiTabs } from '@/components/primitives/Tabs'
import { selectUserConfiguration } from '@/features/domain/user'

import * as OrderTexts from '../intl'
import { TabValues } from '../typings'

type Props = {
  tab: TabValues
  setTab: (tab: TabValues) => void
  disabled?: boolean
}

export function Tabs(props: Props) {
  const { tab, setTab, disabled } = props

  const userConfig = useSelector(selectUserConfiguration)
  const isSimulation = userConfig.planType === 'simulation'

  // notification and execution tabs should be disabled while in simulation
  // there are no order step notifications or execution events while in simulation
  const notificationTabDisabled = isSimulation || disabled
  const executionTabDisabled = isSimulation || disabled

  return (
    <UiTabs>
      <UiTab
        label={OrderTexts.getOrderInformationText()}
        onClick={() => setTab(0)}
        active={tab === 0}
        disabled={disabled}
        testid="order-form-tab__configuration"
      />
      <UiTab
        label={OrderTexts.getNotificationText()}
        onClick={() => setTab(2)}
        active={tab === 2}
        disabled={notificationTabDisabled}
        testid="order-form-tab__notification"
        tooltipTitle={OrderTexts.getNotificationTextDisabled()}
        tooltipEnabled={notificationTabDisabled}
      />
      <UiTab
        label={OrderTexts.getExecutionDetailsText()}
        onClick={() => setTab(1)}
        active={tab === 1}
        disabled={executionTabDisabled}
        testid="order-form-tab__execution"
        tooltipTitle={OrderTexts.getExecutionDetailsTextDisabled()}
        tooltipEnabled={executionTabDisabled}
      />
    </UiTabs>
  )
}
