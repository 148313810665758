import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '@/store'
import { goToNextRevision, goToPreviousRevision } from '@/features/domain/transaction'
import { changeTerritory, selectTerritory } from '@/features/domain/territory'
import { selectOrderStepIdsInRange } from '@/features/domain/order'

import { selectData } from '../cursors/selectData'

export function useSimulationControls() {
  const orderStepIdsInRange = useSelector(selectOrderStepIdsInRange)
  const { prevRevision, nextRevision } = useSelector(selectData)
  const territory = useSelector(selectTerritory)
  const dispatch = useAppDispatch()

  const { id: territoryId } = territory
  const disableCopy = Object.keys(orderStepIdsInRange).length === 0

  const handleOpenOperations = useCallback(() => {
    dispatch(
      changeTerritory({
        target: 'ops',
        territoryId,
      }),
    )
  }, [dispatch, territoryId])

  const handleUndo = useCallback(() => {
    dispatch(goToPreviousRevision())
  }, [dispatch])

  const handleRedo = useCallback(() => {
    dispatch(goToNextRevision())
  }, [dispatch])

  return {
    handleOpenOperations,
    handleUndo,
    handleRedo,
    disableUndo: prevRevision < 0,
    disableRedo: nextRevision < 0,
    disableCopy,
  }
}
