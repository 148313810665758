import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectTrafficProfiles } from '@/features/domain/traffic'

type RecurrencesMap = Record<uui.domain.rm.RecurrenceTypes, uui.domain.rm.Recurrence[]>

export function useRecurrencesMap() {
  const trafficProfiles = useSelector(selectTrafficProfiles)

  return useMemo(() => {
    return Object.values(trafficProfiles).reduce<RecurrencesMap>((acc, curr) => {
      const { type } = curr.recurrence
      const arr = acc[type] || []
      acc[type] = [...arr, curr.recurrence]

      return acc
    }, {} as RecurrencesMap)
  }, [trafficProfiles])
}
