import { useState } from 'react'
import { useIntl } from '@/intl'

export enum NotificationEditorText {
  emailButton = 'emailButton',
  smsButton = 'smsButton',
  invalidEmailMessage = 'invalidEmailMessage',
  invalidSmsMessage = 'invalidSmsMessage',
  duplicatedRecipientMessage = 'duplicatedRecipientMessage',
  addRecipientButton = 'addRecipientButton',
  discardRecipientButton = 'discardRecipientButton',
  addContactButton = 'addContactButton',
  emailTypeLabel = 'emailTypeLabel',
  smsTypeLabel = 'smsTypeLabel',
  addRecipientInputEmailPlaceholder = 'addRecipientInputEmailPlaceholder',
  addRecipientInputSmsPlaceholder = 'addRecipientInputSmsPlaceholder',
  addRecipientInputMessage = 'addRecipientInputMessage',
}

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    alertContacts: translate({ id: 'gps.geofences.alertContacts' }),
    notificationEditorRenderText: (field: NotificationEditorText): string => {
      switch (field) {
        case 'emailButton':
          return translate({
            id: 'components.notificationEditor.emailButton',
          })

        case 'smsButton':
          return translate({
            id: 'components.notificationEditor.smsButton',
          })

        case 'invalidEmailMessage':
          return translate({
            id: 'components.notificationEditor.invalidEmailMessage',
          })

        case 'invalidSmsMessage':
          return translate({
            id: 'components.notificationEditor.invalidSmsMessage',
          })

        case 'duplicatedRecipientMessage':
          return translate({
            id: 'components.notificationEditor.duplicatedRecipientMessage',
          })

        case 'addRecipientButton':
          return translate({
            id: 'components.notificationEditor.addRecipientButton',
          })

        case 'discardRecipientButton':
          return translate({
            id: 'components.notificationEditor.discardRecipientButton',
          })

        case 'addContactButton':
          return translate({
            id: 'components.notificationEditor.addContactButton',
          })

        case 'emailTypeLabel':
          return translate({
            id: 'components.notificationEditor.emailTypeLabel',
          })

        case 'smsTypeLabel':
          return translate({
            id: 'components.notificationEditor.smsTypeLabel',
          })

        case 'addRecipientInputEmailPlaceholder':
          return translate({
            id: 'components.notificationEditor.addRecipientInputEmailPlaceholder',
          })

        case 'addRecipientInputSmsPlaceholder':
          return translate({
            id: 'components.notificationEditor.addRecipientInputSmsPlaceholder',
          })

        case 'addRecipientInputMessage':
          return translate({
            id: 'components.notificationEditor.addRecipientInputMessage',
          })

        default:
          return field
      }
    },
  }))

  return api
}
