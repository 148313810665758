import { useRef, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectDomain } from '@/features/domain'
import { searchDepotsOnMap, searchPlacesOnMap } from '@/server-data'

interface Options {
  assetTypes?: ('depot' | 'place')[]
  strategy?: 'includes' | 'startsWith'
}

/**
 * Get a function that looks for the domain assets that match a given address.
 */
export const useSearchAssetsOnMap = (options: Options) => {
  const { assetTypes = [], strategy = 'includes' } = options
  const domain = useSelector(selectDomain)

  const api = useRef({
    domain,
    strategy,
    assetTypes,
  })
  useEffect(() => {
    api.current = { ...api.current, domain, strategy, assetTypes }
  }, [domain, strategy, assetTypes])

  return useCallback((address: string) => {
    const { assetTypes, strategy, domain } = api.current
    const result: uui.domain.client.gps.SearchOnMapItem[] = []

    if (assetTypes.includes('depot')) {
      result.push(
        ...searchDepotsOnMap(address, domain.rm.depots, strategy).sort((d1, d2) =>
          d1.name.localeCompare(d2.name),
        ),
      )
    }

    if (assetTypes.includes('place')) {
      result.push(
        ...searchPlacesOnMap(address, domain.gps.wwgps.places, strategy).sort((p1, p2) =>
          p1.name.localeCompare(p2.name),
        ),
      )
    }

    return result
  }, [])
}
