import { useEffect } from 'react'

import { changeVoiceEventLayerVisibility } from '../../../layers/voiceEvents/features/changeVoiceEventLayerVisibility'
import { clearVoiceEventMarker } from '../core/clearVoiceEventMarker'

export function useVoiceEventMarkers() {
  useEffect(() => {
    changeVoiceEventLayerVisibility(true)

    return () => {
      // Remove markers
      clearVoiceEventMarker()

      // Hide the layer
      changeVoiceEventLayerVisibility(false)
    }
  }, [])
}
