import { useSelector } from 'react-redux'

import { ViewActionButton } from '@/formUi'

import { selectTerritories } from '@/features/domain/territory'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useRequestChangePlanModal } from './modals/RequestChangePlanModal'
import { useTexts } from './useTexts'

const countriesAllowedToRequestSms = [
  'US',
  'CA',
  'IT',
  'GB',
  'AU',
  'ES',
  'PT',
  'IE',
  'SE',
  'SA',
  'DK',
  'BE',
]

export function ChangePlanButton() {
  const { show: showRequestChangePlan, Modal: RequestChangePlanModal } = useRequestChangePlanModal()
  const territories = useSelector(selectTerritories)
  const texts = useTexts()

  // The user can change the plan if at least one of his territories is allowed to request sms

  const countries = [...new Set(Object.values(territories).map(t => t.country))]
  const canChangePlan = countries.some(country => countriesAllowedToRequestSms.includes(country))

  const buttonDisabled = !canChangePlan

  return (
    <>
      <Tooltip placement="top" title={texts.changePlanDisabled} disabled={canChangePlan}>
        <ViewActionButton
          testId="settings-communication-edit-plan-button"
          onClick={showRequestChangePlan}
          label={texts.changePlan}
          disabled={buttonDisabled}
        />
      </Tooltip>

      <RequestChangePlanModal />
    </>
  )
}
