import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    reportedTimeIn: translate({ id: 'orders.form.reportedTimeIn' }),
    reportedTimeOut: translate({ id: 'orders.form.reportedTimeOut' }),
  }))

  return api
}
