import type { MapTile } from '@/map'
import { createTileLayer } from '@/map'

export function createLocationEditorTileLayer(tile: MapTile, lng: string) {
  return createTileLayer({
    tileType: tile,
    tileSize: 256,
    lng,
  })
}
