import { Component } from 'react'

export type Props = {
  innerColor: string
  outerColor?: string
}

export default class Dot extends Component<Props> {
  render() {
    const { innerColor, outerColor = '#fff' } = this.props
    return (
      <svg viewBox="0 0 100 100">
        <path d="M0 50a50 50 0 1 0 100 0A50 50 0 1 0 0 50" style={{ fill: outerColor }} />
        <path d="M3 50a47 47 0 1 0 94 0 47 47 0 1 0-94 0" style={{ fill: innerColor }} />
      </svg>
    )
  }
}
