import type { VehicleForCopySettings, ModifierKeys } from './typings'

import { useCallback, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { SecondaryColumnHeader, SearchableList, Label } from '@/forms-legacy'
import { recurrenceToString, parseRecurrence } from '@/server-data'
import { selectSortedRMVehicles } from '@/features/domain/vehicle'
import { intl } from '@/intl'
import { useVehicleFormException } from '@/atoms'

import { VehicleListItem } from './components/VehicleListItem'
import { CopySettingsLayout } from './elements/CopySettingsLayout'
import { CopySettingsActionsContainer } from './elements/CopySettingsActionsContainer'
import { CopySettingsButton } from './elements/CopySettingsButton'
import { VehicleListWrapper } from './elements/VehicleListWrapper'

import { getVehiclesForCopySettings } from './utils/getVehiclesForCopySettings'
import { processListSelection } from './utils/processListSelection'
import { toggleIds } from './utils/toggleIds'
import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  switchToDefaultView: () => void
}

export function CopySettings(props: Props) {
  const { unifiedVehicle, switchToDefaultView } = props

  const [{ exception }] = useVehicleFormException()
  const { onUpdateVehiclesSettings } = useActions()
  const texts = useTexts()

  const vehicles = useSelector(selectSortedRMVehicles)

  const vehiclesForCopySettings = useMemo<VehicleForCopySettings[]>(
    () => getVehiclesForCopySettings(vehicles, unifiedVehicle, exception),
    [vehicles, unifiedVehicle, exception],
  )

  const vehicleSettings = unifiedVehicle.hasRoutingLicense
    ? unifiedVehicle.vehicle.settings[exception]
    : undefined

  const [selectedVehicleIds, setSelectedVehicleIds] = useState<string[]>(() =>
    vehiclesForCopySettings.map(vehicle => vehicle.id),
  )

  const [visibleVehicles, setVisibleVehicles] =
    useState<VehicleForCopySettings[]>(vehiclesForCopySettings)

  const handleOnCopySettings = useCallback(() => {
    if (vehicleSettings && selectedVehicleIds.length > 0) {
      onUpdateVehiclesSettings(selectedVehicleIds, exception, vehicleSettings)
      switchToDefaultView()
    }
  }, [
    vehicleSettings,
    selectedVehicleIds,
    onUpdateVehiclesSettings,
    exception,
    switchToDefaultView,
  ])

  const handleOnChangeSelection = useCallback(
    (
      selection: VehicleForCopySettings[],
      selectionLimit: number,
      modifiers: ModifierKeys = {},
      __: boolean = false,
      forceSelection: boolean = false,
    ) => {
      if (forceSelection) {
        setSelectedVehicleIds(selection.map(vehicle => vehicle.id))
        return
      }

      const processedSelection = processListSelection<VehicleForCopySettings, string>(
        vehiclesForCopySettings,
        selectedVehicleIds,
        selection,
        ({ id }: VehicleForCopySettings): string => id,
        modifiers,
      )

      const ids = processedSelection.values

      const clippedIds =
        selectionLimit > 0 && ids.length > selectionLimit ? ids.slice(0, selectionLimit) : ids

      const isModifierActive = Object.values(modifiers).some(mod => !!mod)

      if (isModifierActive) {
        setSelectedVehicleIds(clippedIds)
      } else {
        setSelectedVehicleIds(toggleIds<string>(clippedIds, selectedVehicleIds))
      }
    },
    [selectedVehicleIds, vehiclesForCopySettings],
  )

  const handleOnFilter = useCallback(
    (txt: string) => {
      const filter = txt.toLocaleLowerCase().trim()

      const values = vehiclesForCopySettings.filter(({ externalId }: VehicleForCopySettings) => {
        return externalId.toLocaleLowerCase().trim().includes(filter)
      })

      setVisibleVehicles(values)
    },
    [vehiclesForCopySettings],
  )

  const recurrence = parseRecurrence(exception)

  return (
    <>
      <SecondaryColumnHeader
        title={texts.headerTitle(recurrence ? recurrenceToString(intl.translate)(recurrence) : '')}
        description={texts.headerSubtitle}
        avatar={null}
      />
      <CopySettingsLayout>
        <Label>{texts.listTitle}</Label>
        <VehicleListWrapper>
          <SearchableList<VehicleForCopySettings>
            values={visibleVehicles}
            selectedIds={selectedVehicleIds}
            onChange={handleOnChangeSelection}
            renderText={texts.copySettings}
            ListItem={VehicleListItem}
            visibleRows={8}
            rowHeight={26}
            selectionLimit={-1}
            onFilter={handleOnFilter}
          />
        </VehicleListWrapper>
        <CopySettingsActionsContainer>
          <CopySettingsButton onClick={switchToDefaultView}>{texts.discard}</CopySettingsButton>

          <CopySettingsButton
            onClick={handleOnCopySettings}
            disabled={selectedVehicleIds.length < 1}
            primary
          >
            {texts.confirm(selectedVehicleIds.length)}
          </CopySettingsButton>
        </CopySettingsActionsContainer>
      </CopySettingsLayout>
    </>
  )
}
