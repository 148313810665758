import { PureComponent } from 'react'

import { DropdownItem } from '@/forms-legacy'

import * as OrderTexts from '../../../../../../intl'

import VehicleAssignmentDropDown from '../fields/VehicleAssignmentDropDown'
import { Props } from '../typings'

export default class VehicleAssignmentField extends PureComponent<Props> {
  private readonly defaultValue: DropdownItem

  constructor(props: Props) {
    super(props)

    this.defaultValue = {
      id: 'AUTOMATIC_ASSIGNMENT',
      label: OrderTexts.getAutomaticAssignmentText(),
    }
  }

  render() {
    const { vehicles } = this.props

    return (
      <VehicleAssignmentDropDown
        name="order.forceVehicleId"
        label={OrderTexts.getFieldLabelVehicleAssignmentText()}
        vehicles={vehicles}
        defaultValue={this.defaultValue}
      />
    )
  }
}
