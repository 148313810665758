import { format } from 'date-fns/esm'
import { useSelector } from 'react-redux'
import { useCallback, useEffect, useRef } from 'react'

import { selectUserConfiguration } from '@/features/domain/user'

import { OnCalendar } from './OnCalendar'
import { ByCalendar } from './ByCalendar'
import { computeReferenceDate } from './computeReferenceDate'

type Props = {
  eligibility: uui.domain.client.rm.Eligibility
  onChange: (type: uui.domain.client.rm.Eligibility['type'], dates: Date[]) => void
}

export function Calendar(props: Props) {
  const { onChange, eligibility } = props

  const { today } = useSelector(selectUserConfiguration)
  const referenceDate = useRef(computeReferenceDate(eligibility, today))

  const createOnChange = useCallback(
    (type: uui.domain.client.rm.Eligibility['type']) => (date: Date[]) => {
      onChange(type, date)
    },
    [onChange],
  )

  const onChangeReferenceDate = useCallback((newReferenceDate: Date) => {
    referenceDate.current = format(newReferenceDate, 'yyyyMMdd')
  }, [])

  useEffect(() => {
    if (eligibility.type === 'any') {
      referenceDate.current = today
    }
  }, [today, eligibility.type])

  switch (eligibility.type) {
    case 'on':
      return (
        <OnCalendar
          dates={eligibility.dates}
          onChange={createOnChange('on')}
          initialVisibleDate={referenceDate.current}
          onReferenceDateChange={onChangeReferenceDate}
        />
      )

    case 'by':
      return (
        <ByCalendar
          date={eligibility.date}
          onChange={createOnChange('by')}
          initialVisibleDate={referenceDate.current}
          onReferenceDateChange={onChangeReferenceDate}
        />
      )

    case 'any':
    default:
      return null
  }
}
