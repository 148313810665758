import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'gps.alerts.edit.formErrorsTitle' }),
    cancel: translate({ id: 'global.discard' }),
    submit: (creating?: boolean) =>
      translate({ id: creating ? 'gps.alerts.edit.createButton' : 'gps.alerts.edit.updateButton' }),
    submitTitle: (valid: boolean, readonly: boolean, pristine: boolean) => {
      if (!valid)
        return translate({
          id: 'alerts.form.submitButton.disabled.title.invalid',
        })

      if (readonly)
        return translate({
          id: 'alerts.form.submitButton.disabled.title.planLocked',
        })

      if (pristine)
        return translate({
          id: 'alerts.form.submitButton.disabled.title.pristine',
        })

      return ''
    },
  }))

  return api
}
