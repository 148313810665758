import type { FormError, FormValues as FormValuesType } from '@workwave-tidal/tidal/form-fairy'

export const formFields = {
  distanceFormat: { value: '' },
  dateFormat: { value: '' },
  timeFormat: { value: '' },
  language: { value: '' },
  currency: { value: { value: 'USD', label: 'USD' }, required: true },
}

export type FormFields = typeof formFields
export type FormValues = FormValuesType<FormFields>
export type FormErrors = FormError<keyof FormFields>
