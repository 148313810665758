import type { Props } from './typings'
import { Text } from '@/local/components'

import { HeaderLinkWrapper } from './HeaderLinkWrapper'

export function HeaderLink(props: Props) {
  return (
    <HeaderLinkWrapper {...props}>
      <Text size="$p2" weight="$regular" color={props.color}>
        {props.children}
      </Text>
    </HeaderLinkWrapper>
  )
}
