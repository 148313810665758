import { PureComponent } from 'react'

import { ConfirmPanel, ChangeViewHandler } from '@/forms-legacy'
import * as Texts from '../../../intl'

import { Props as BaseProps } from '../typings'
import { SingleViewActions } from './SingleViewActions'

export enum ViewTypes {
  default,
  delete,
}

export interface Props extends BaseProps {
  view: ViewTypes
  changeView: ChangeViewHandler<ViewTypes>
}

export default class ActionsManager extends PureComponent<Props> {
  private handleOnDelete = (): void => {
    const { simulation, deleteSimulations } = this.props
    if (simulation) {
      deleteSimulations([simulation.id])
      this.reset()
    }
  }

  private reset = (): void => {
    const { changeView } = this.props
    changeView(ViewTypes.default)
  }

  private handleOnShowDeleteConfirmPanel = (): void => {
    const { changeView } = this.props
    changeView(ViewTypes.delete)
  }

  render() {
    const { view } = this.props

    switch (view) {
      case ViewTypes.delete:
        return (
          <ConfirmPanel
            title={Texts.getSimulationsDeleteConfirmTitle(1)}
            description={Texts.getSimulationsDeleteConfirmDescription()}
            onCancel={this.reset}
            confirmationText={Texts.getSimulationsDeleteConfirmButtonText()}
            onConfirm={this.handleOnDelete}
          />
        )
    }

    return (
      <SingleViewActions {...this.props} onShowConfirmPanel={this.handleOnShowDeleteConfirmPanel} />
    )
  }
}
