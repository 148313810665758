import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { updateEditableRoadSegment } from '../../../atoms/editableRoadSegment/core/updateEditableRoadSegment'

export function createOnRoadSegmentChange() {
  /**
   * Update the active Editable RoadSegment with the latest Geometry points
   */
  return (
    editableSegment: { start: uui.domain.LatLng; end: uui.domain.LatLng },
    _feature: Feature<Geometry>,
    _lastChanged: number,
  ) => {
    updateEditableRoadSegment(prev => {
      return { ...prev, editableSegment }
    })
  }
}
