interface Params {
  isSelectionEnd: boolean
}

export function getCosmeticForSelectionInThePast(params: Params) {
  const { isSelectionEnd } = params

  if (isSelectionEnd) {
    return 'edge-of-selection'
  }

  return 'normal'
}
