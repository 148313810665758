import { importWizardSession } from '@/utils'
import { resumeImportWizardFlow } from './resumeImportWizardFlow'

export function resumeImport() {
  const importWizardState = importWizardSession.getState()

  if (!importWizardState) return

  if (importWizardState) return resumeImportWizardFlow(importWizardState)
}
