import type { FormValues } from '../TrackingPageForm/formFields'

import { useMemo } from 'react'

export function useFormInitialValues(
  notificationConfigCompanyDetails: uui.domain.client.rm.NotificationConfigCompanyDetails,
) {
  return useMemo<FormValues>(
    () => computeFormInitialValues(notificationConfigCompanyDetails),
    [notificationConfigCompanyDetails],
  )
}

// this function will normalize NotificationConfigCompanyDetails into FormValues
// removing all the optional values
function computeFormInitialValues(
  companyDetails: uui.domain.client.rm.NotificationConfigCompanyDetails,
): FormValues {
  return {
    companyName: companyDetails.companyName ?? '',
    companySlogan: companyDetails.companySlogan ?? '',
    companyPhone: companyDetails.companyPhone ?? '',
    showContactPhone: !!companyDetails.showContactPhone,
    companyEmail: companyDetails.companyEmail ?? '',
    showContactEmail: !!companyDetails.showContactEmail,
    useCompanyEmailAsReplyTo: !!companyDetails.useCompanyEmailAsReplyTo,
    showEtaRange: !!companyDetails.showEtaRange,
    showPicturePod: !!companyDetails.showPicturePod,
    showSignaturePod: !!companyDetails.showSignaturePod,
    showAudioPod: !!companyDetails.showAudioPod,
    showNotePod: !!companyDetails.showNotePod,
  }
}
