import {
  FlexRow,
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
} from '@/forms-legacy'

import { useTexts } from '../useTexts'

interface Props {
  driverBehaviors: uui.domain.client.gps.wwgps.DriverBehaviorSettings
}

export function AccelerationList(props: Props) {
  const {
    driverBehaviors: { hardAcceleration, harshAcceleration, severeAcceleration },
  } = props

  const texts = useTexts()

  const galsText = texts.gals
  const notSetText = texts.notSet

  const hardValue = hardAcceleration.threshold
    ? `${hardAcceleration.threshold || ''} ${galsText}`
    : notSetText

  const harshValue = harshAcceleration.threshold
    ? `${harshAcceleration.threshold || ''} ${galsText}`
    : notSetText

  const severeValue = severeAcceleration.threshold
    ? `${severeAcceleration.threshold || ''} ${galsText}`
    : notSetText

  return (
    <>
      <ReadonlyLabel primary>{texts.acceleration}</ReadonlyLabel>
      <FlexRow justifyContent="space-between">
        <ReadonlyBlock aThird>
          <ReadonlyLabel>{texts.hard}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{hardValue}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
        <ReadonlyBlock aThird>
          <ReadonlyLabel>{texts.harsh}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{harshValue}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
        <ReadonlyBlock aThird>
          <ReadonlyLabel>{texts.severe}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{severeValue}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      </FlexRow>
    </>
  )
}
