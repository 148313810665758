import type { ChipShape, CoreItem, Modes, PlaceChip } from '../../../components/Navigator/typings'

import { theme } from '@/styles'
import { clsx } from '@/utils'
import { Star } from '@/icons'

import { Chip } from '../../../components/Navigator/components/Chip'

export interface Props<T extends CoreItem, K> {
  item: T
  mode: Modes
  selected?: boolean
  onClick: () => void
  className?: string
  extraItemRendererProps: K
  loopIndex: number
}

export function PlaceItemRenderer(props: Props<PlaceChip, Record<string, any>>) {
  const { onClick, className: cls = '' } = props

  const className = clsx(cls, 'o-chip--circle')

  const chipProps = {
    onClick,
    className,
    shape: 'rounded' as ChipShape,
    background: theme.colors.$copper,
    Icon: <Star size={17} color="$pureWhite" />,
  }

  return <Chip {...chipProps} />
}
