import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserProfile } from '@/features/domain/user'

/**
 * Generate the querystring that allows auto-logging in the external app.
 */
export function useAutoLoginQueryString() {
  const profile = useSelector(selectUserProfile)

  // useMemo helps to limit the journal logs
  return useMemo(() => {
    return `authtoken=${profile.authtoken}`
  }, [profile])
}
