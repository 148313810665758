import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Truck(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 25 25">
        <path d="M4.96687 6C4.43297 6 4 6.4488 4 6.99916v9.19003c0 .55036.4861 1.05405 1.02 1.05405h1.39188C6.5739 18.23965 7.41061 19 8.42 19s1.8461-.76035 2.00812-1.75676h3.145c.53258 0 .96688-.4488.96688-.99915V7.0211C14.54 6.45841 14.11102 6 13.58375 6H4.96687zM15.22 8.45946v8.78378C15.38336 18.23828 16.25062 19 17.26 19c1.00937 0 1.8461-.76172 2.00812-1.75676H19.98c.5339 0 1.02-.50232 1.02-1.05405v-3.38176c0-.70682-.49273-1.42873-.5525-1.5152l-1.41313-1.9544c-.32406-.4035-.83273-.87837-1.43437-.87837h-2.38zm1.7 1.75676h1.90187l1.08376 1.49324c.11554.16881.41437.68623.41437 1.09797v.2196h-3.4c-.34 0-.68-.35135-.68-.7027v-1.40541c0-.3884.34-.7027.68-.7027zm-8.5 5.27027c.7504 0 1.36.62996 1.36 1.4054 0 .77545-.6096 1.4054-1.36 1.4054-.7504 0-1.36-.62995-1.36-1.4054 0-.77544.6096-1.4054 1.36-1.4054zm8.84 0c.75039 0 1.36.62996 1.36 1.4054 0 .77545-.60961 1.4054-1.36 1.4054-.7504 0-1.36-.62995-1.36-1.4054 0-.77544.6096-1.4054 1.36-1.4054z" />
      </svg>
    </IconContainer>
  )
}
