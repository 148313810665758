import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderMapStyles } from '@/features/domain/ui'

export const useActiveRouteMapStyle = (orderStepIds: string[]) => {
  const orderMapStyles = useSelector(selectOrderMapStyles)

  /**
   * This methods retrieve the correct active style for the the route's orders.
   * If there are different styles for the chosen orders it will return undefined.
   */
  return useMemo(() => {
    let commonMapStyle: 'on' | 'off' | 'maximized' | undefined

    for (const id of orderStepIds) {
      const orderMapStyle = orderMapStyles.custom[id] ?? orderMapStyles.mode

      if (commonMapStyle && orderMapStyle !== commonMapStyle) return

      commonMapStyle = orderMapStyle
    }

    return commonMapStyle
  }, [orderMapStyles, orderStepIds])
}
