import { AddLoadCfToOrderStep } from './components/AddLoadsCfToOrderStep/AddLoadCfToOrderStep'

export function DynamicLoadsCfTester() {
  return (
    <>
      <h1>Dynamic loads/cf tester</h1>
      <AddLoadCfToOrderStep />
    </>
  )
}
