import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export interface Dimensions {
  width: number
  height: number
}

const emptyDimension: Dimensions = { width: 0, height: 0 }
const getSizeFromElement = (el: HTMLElement): Dimensions => {
  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  }
}

const getSizeFromEntry = (el: ResizeObserverEntry): Dimensions => {
  const { contentRect } = el

  return {
    width: contentRect.width,
    height: contentRect.height,
  }
}

const createHandleResize =
  (
    mutableAnimationFrameId: React.MutableRefObject<number | undefined>,
    setComponentSize: (value: Dimensions) => void,
  ) =>
  (entries: ResizeObserverEntry[]) => {
    if (entries.length > 1) {
      throw new Error('Multiple UI elements registered to the ResizeObserver')
    }

    // see: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    // see: https://github.com/souporserious/react-measure/issues/104#issuecomment-418270591
    // see: https://github.com/hshoff/vx/pull/335/files
    mutableAnimationFrameId.current = window.requestAnimationFrame(() => {
      setComponentSize(getSizeFromEntry(entries[0]))
    })
  }

const useComponentSize = (
  ref: RefObject<HTMLElement>,
  duration: number = 500,
): [Dimensions, boolean] => {
  const [componentSize, setComponentSize] = useState(
    ref.current ? getSizeFromElement(ref.current) : emptyDimension,
  )
  const { current } = ref

  const [resizing, setResizing] = useState(false)
  const animationFrameId = useRef<number | undefined>()

  useLayoutEffect(() => {
    if (!current?.parentElement) {
      return
    }

    let observer: ResizeObserver | null = new ResizeObserver(
      createHandleResize(animationFrameId, setComponentSize),
    )
    observer.observe(current.parentElement)

    return () => {
      if (observer) {
        observer.disconnect()
      }
      observer = null
    }
  }, [current])

  useEffect(() => {
    setResizing(true)
    const timer = setTimeout(() => setResizing(false), duration)

    return () => {
      clearTimeout(timer)
    }
  }, [componentSize, duration])

  useEffect(
    () => () => {
      if (animationFrameId.current) {
        window.cancelAnimationFrame(animationFrameId.current)
      }
    },
    [],
  )

  return [componentSize, resizing]
}

export default useComponentSize
