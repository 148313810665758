import type { ReactElement } from 'react'
import { createContext } from 'react'

type SetStateReturnType = {
  childrenQueue: ReactElement[]
  setChildrenQueue: (setter: (prev: ReactElement[]) => ReactElement[]) => void
}

export type Context = SetStateReturnType

export const PopupChildrenQueueContext = createContext<Context>({
  childrenQueue: [],
  setChildrenQueue: () => [],
})
