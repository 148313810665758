import { NavigoContainer } from '../../elements/NavigoContainer'
import { VehiclesNavigator } from './components/VehiclesNavigator'

import { Overview } from './views/Overview'
import { MapStyle } from './views/MapStyle'

import { useVehiclesNavigoMachine } from './hooks/useVehiclesNavigoMachine'

export function Vehicles() {
  const { currentState, data, actions } = useVehiclesNavigoMachine()

  switch (currentState) {
    case 'mapStyle':
      return (
        <NavigoContainer data-trackid="navigo-vehicles" data-testid="navigo-vehicles">
          <VehiclesNavigator />

          <MapStyle reset={actions.reset} />
        </NavigoContainer>
      )

    case 'overview':
    default:
      return (
        <NavigoContainer data-trackid="navigo-vehicles" data-testid="navigo-vehicles">
          <VehiclesNavigator />

          <Overview
            expanded={data.expanded}
            permissions={data.permissions}
            goToStyle={actions.goToMapStyle}
            toggleExpanded={actions.toggleExpanded}
          />
        </NavigoContainer>
      )
  }
}
