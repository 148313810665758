import { type SchedulerEventModel } from '@bryntum/schedulerpro'
import { useMemo } from 'react'
import { differenceInSeconds } from 'date-fns/esm'

function hasDelay(value?: number, threshold = 60) {
  return typeof value === 'number' && Math.abs(value) > threshold
}

export function useTooltipData(eventRecord: SchedulerEventModel) {
  return useMemo(() => {
    const startDate = eventRecord.startDate as Date
    const endDate = eventRecord.endDate as Date

    const approvedVersion = eventRecord.getData('approvedVersion') as
      | uui.domain.client.rm.SchedulerEvent['approvedVersion']
      | undefined

    const type = eventRecord.getData('type') as uui.domain.client.rm.SchedulerEvent['type']
    const durationSeconds = differenceInSeconds(endDate as Date, startDate as Date)

    const approvedDurationSeconds = approvedVersion
      ? differenceInSeconds(approvedVersion.endDate, approvedVersion.startDate)
      : undefined

    const delayStartSeconds = approvedVersion
      ? differenceInSeconds(startDate as Date, approvedVersion.startDate)
      : undefined
    const delayEndSeconds = approvedVersion
      ? differenceInSeconds(endDate as Date, approvedVersion.endDate)
      : undefined
    const delayDurationSeconds =
      typeof approvedDurationSeconds === 'number'
        ? durationSeconds - approvedDurationSeconds
        : undefined

    const delay =
      hasDelay(delayStartSeconds) || hasDelay(delayEndSeconds) || hasDelay(delayDurationSeconds)

    return {
      duration: durationSeconds,
      stepType: type,
      start: startDate,
      type: 'setupTime',
      end: endDate,
      delay:
        approvedVersion && delay
          ? {
              endSeconds: delayEndSeconds!,
              startSeconds: delayStartSeconds!,
              durationSeconds: delayDurationSeconds!,
            }
          : undefined,
    }
  }, [eventRecord])
}
