import type { FormFields, FormErrors } from '../formFields'
import type { FormConfig } from '@workwave-tidal/tidal/form-fairy'

import { useState } from 'react'

import { useAppDispatch } from '@/store'

import { createFormValidation } from '../validations/createFormValidation'
import { useTexts } from '../useTexts'

function createInitialValues(source: uui.domain.server.gps.telematics.Source) {
  switch (source.label) {
    case 'Azuga':
      return {
        credentials: [{ fieldName: 'apiKey', value: '' }],
      }
    case 'Linxup':
      return { credentials: [{ fieldName: 'apiKey', value: '' }] }

    case 'Verizon Connect':
    case 'Verizon Connect (EU)':
    case 'Verizon Connect (US)':
      return {
        credentials: [
          { fieldName: 'username', value: '' },
          { fieldName: 'password', value: '' },
        ],
      }

    case 'Geotab':
      return {
        credentials: [
          { fieldName: 'username', value: '' },
          { fieldName: 'password', value: '' },
          { fieldName: 'database', value: '' },
        ],
      }
  }
}

export function useFormConfig(
  source: uui.domain.server.gps.telematics.Source,
  tenantSource: uui.domain.server.gps.telematics.TenantSource,
): FormConfig<FormFields, FormErrors> {
  const dispatch = useAppDispatch()
  const texts = useTexts()

  // since the formConfig must not change (the form library will throw an error otherwise)
  // use the lazy initialization of the useState hook
  const [formConfig] = useState(() => ({
    overrideValues: createInitialValues(source),
    validations: createFormValidation(source, tenantSource, dispatch, texts),
  }))

  return formConfig
}
