import { useCallback } from 'react'

import { deleteVehicleException } from '@/features/domain/vehicle'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useActions(setException: (exception: string) => void) {
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const onDeleteException = useCallback(
    async (vehicleId: string, exception: string) => {
      try {
        const result = await dispatch(deleteVehicleException({ vehicleId, exception }))

        if (!deleteVehicleException.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
        setException('any')
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, toast, setException],
  )

  return {
    onDeleteException,
  }
}
