import { setMainSelection } from '@/atoms'
import { getRouterNavigator } from '@/routing'

import { fitMap } from '../../../../atoms/map/utils/fitMap'
import { getDomainMarkerData } from '../../../../atoms/markerChangelog/domain/getDomainMarkerData'

export function doubleClickOrders(orderIdsRecord?: Record<string, true>) {
  if (orderIdsRecord) {
    const ids = Object.keys(orderIdsRecord)

    const { mapStyles, extendedOrderSteps } = getDomainMarkerData()

    const globalMode = mapStyles.order.mode

    const latLngCoordinates: uui.domain.LatLng[] = []

    for (const id of ids) {
      const extOrderStep = extendedOrderSteps[id]

      if (!extOrderStep) {
        if (process.env.NODE_ENV === 'development') {
          throw new Error(`Trying to double click a non-existent OrderStep with ID: ${id}`)
        }
        continue
      }

      // skip hidden order-steps
      const customMode = mapStyles.order.custom[id]
      const visible = customMode !== 'off' || globalMode !== 'off'

      if (visible) {
        latLngCoordinates.push(extOrderStep.latLng)
      }
    }

    // Set the new main selection
    setMainSelection('orderSteps', ids)

    // queue a pending fitMap request
    // it will be applied by the destination view when it opens
    fitMap(latLngCoordinates, { preventIfVisible: true }, 'add')

    // Navigate to the target page
    const navigate = getRouterNavigator()
    navigate('/routing/orders')
  }
}
