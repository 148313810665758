import styled from 'styled-components'

export type Props = {
  justifyContent?: string
  alignItems?: string
  useAutoWidth?: boolean
  height?: number
}

const FlexRow = styled.div<Props>`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: ${p => p.alignItems ?? 'center'};
  justify-content: ${p => p.justifyContent ?? 'flex-start'};
  flex-wrap: nowrap;

  width: ${p => (p.useAutoWidth ? 'auto' : '100%')};
  height: ${p => (typeof p.height === 'number' ? `${p.height}px` : 'auto')};
`

FlexRow.displayName = 'FlexRow'
export default FlexRow
