import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    depots: (count: number) => translate({ id: 'global.plural.depots', values: { count } }),
    orders: (count: number) => translate({ id: 'global.plural.orders', values: { count } }),
    places: (count: number) => translate({ id: 'global.plural.places', values: { count } }),
    routes: (count: number) => translate({ id: 'global.plural.routes', values: { count } }),
    devices: (count: number) => translate({ id: 'global.plural.devices', values: { count } }),
    vehicles: (count: number) => translate({ id: 'global.plural.vehicles', values: { count } }),
  }))

  return api
}
