import { makeStyles } from '@mui/styles'
import { Link } from '@/routing'
import { clsx } from '@/utils'

import { useTexts } from '../../../useTexts'
import { HeaderLink } from './HeaderLink'

type RoutingRoutes = uui.routing.Routing

const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
  },
})

export function RmGuestLinks() {
  const texts = useTexts()
  const classes = useStyles()

  return (
    <>
      <Link<RoutingRoutes>
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        data-trackid="rm-guest-header__nav-orders-button-link"
        className={clsx(classes.fullHeight, 'pendo-bluebar__orders-menu')}
      >
        <HeaderLink data-testid="header__nav-orders-button-content">{texts.orders}</HeaderLink>
      </Link>
    </>
  )
}
