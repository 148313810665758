import { mapAtom } from '../../../../atoms/map/map'
import { findLayer } from '../../../utils/findLayer'

export function removeVoiceEventFeature(eventId: string) {
  const map = mapAtom.map
  const layer = findLayer(map, 'voiceEvents')
  const feature = layer.getSource()?.getFeatureById(eventId)

  if (feature) {
    layer.getSource()?.removeFeature(feature)
  }
}
