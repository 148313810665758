import { NavigateFunction, useNavigate } from 'react-router'

let navigate: NavigateFunction | undefined = undefined

export function useExposeRouterNavigator() {
  navigate = useNavigate()
}

export function getRouterNavigator(): NavigateFunction {
  if (!navigate) {
    throw new Error(
      `Trying to access the static navigator API before react-router has been mounted`,
    )
  }
  return navigate
}
