import { type CSSProperties } from 'react'
import { type ResourceModel } from '@bryntum/schedulerpro'

import { useIsLoading } from '@/atoms'

import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../Scheduler/components/Typography'
import { EventBusy } from '../icons/EventBusy'
import { Spinner } from '../../Scheduler/components/Spinner'
import { Link } from '../../Scheduler/components/Link'
import { Flag } from '../icons/Flag'
import { Box } from '../../Scheduler/components/Box'

interface Props {
  record: ResourceModel
  field: string
  value: string
}

export function VehicleCell(props: Props) {
  const { value, record } = props
  const available = record.getData('available') as boolean | undefined
  const violations = record.getData('violations') as uui.domain.rm.RouteViolations | undefined

  const gpsDataLoading = useIsLoading('fetchGpsData')

  const unavailable = !available
  const hasVehicleViolations = (violations?.vehicle?.length ?? 0) > 0

  const iconStyle = {
    '--font-size': '14px',
  } as CSSProperties

  // If GPS data are still loading we cannot select/edit driver assignments
  if (gpsDataLoading) {
    return (
      <SingleColumnContainer>
        <Spinner size={14} />
      </SingleColumnContainer>
    )
  }

  return (
    <SingleColumnContainer>
      <Link>
        <Box direction="row" gap={8} alignItems="center" justifyContent="start">
          <Typography fontSize={12}>{value}</Typography>

          {unavailable && <EventBusy style={iconStyle} />}

          <div style={{ marginRight: 4 }}>
            {hasVehicleViolations && (
              <Flag className="o-scheduler-svg-icon--color-error" style={iconStyle} />
            )}
          </div>
        </Box>
      </Link>
    </SingleColumnContainer>
  )
}
