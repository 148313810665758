import type { OnSelectCallbackParams } from '@/components/StructuredVirtualList'

import { useCallback } from 'react'

import { fitMapToSelection } from '@/map'
import { useDataForList } from './useDataForList'

export function useListHandlers() {
  const { selectTrafficAreas, structure, trafficAreaSelection, deselectAll, onCreateTrafficArea } =
    useDataForList()

  const onSelect = useCallback(
    (params: OnSelectCallbackParams<'trafficAreas'>) => {
      const ids: string[] = []

      for (const id of params.newSelectedIds) {
        if (id.startsWith('group')) continue
        ids.push(id)
      }

      selectTrafficAreas(ids)

      // try to select it right away and
      // queue a pending fitMap request
      // it will be applied by the destination view when it opens
      fitMapToSelection({ preventIfVisible: true }, 'add')
    },
    [selectTrafficAreas],
  )

  const handleOnSelectAll = useCallback(() => {
    const allSelected = structure.shownItems <= trafficAreaSelection.length

    const ids: string[] = []

    for (const listItem of structure.list) {
      if (typeof listItem === 'string') {
        ids.push(listItem)
      }
    }

    if (allSelected) {
      deselectAll()
    } else {
      selectTrafficAreas(ids)
    }

    // try to select it right away and
    // queue a pending fitMap request
    // it will be applied by the destination view when it opens
    fitMapToSelection({ preventIfVisible: true }, 'add')
  }, [selectTrafficAreas, trafficAreaSelection, deselectAll, structure])

  return {
    onCreateTrafficArea,
    onSelect,
    handleOnSelectAll,
  }
}
