import type { DepotChip } from '../../../components/Navigator/typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDepots } from '@/features/domain/depot'

import { useNavigator as useCoreNavigator } from '../../../components/Navigator/hooks/useNavigator'

export const useDepotsNavigator = () => {
  const {
    ids,
    pagination: { page, setPage },
  } = useCoreNavigator()

  const depots = useSelector(selectDepots)

  const depotsData = useMemo(() => {
    const selectedDepots: uui.domain.client.rm.Depot[] = []
    for (const selectedId of ids) {
      const depot = depots[selectedId]
      !!depot && selectedDepots.push(depot)
    }

    const chips: DepotChip[] = selectedDepots.map(depot => ({
      id: depot.id,
      source: depot,
      type: 'depot',
    }))

    const currentDepots =
      page === -1 || !selectedDepots?.[page] ? selectedDepots : [selectedDepots[page]]

    const currentDepotIds = page === -1 || !ids?.[page] ? ids : [ids[page]]

    return { chips, currentDepots, currentDepotIds }
  }, [page, ids, depots])

  return { page, setPage, ...depotsData } as const
}
