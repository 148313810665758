import type { Theme } from '@mui/material'

import { MouseEventHandler } from 'react'

import { makeStyles } from '@mui/styles'
import { grey } from '@mui/material/colors'
import { clsx } from '@/utils'

interface Props {
  dragging: boolean
  onMouseDown: MouseEventHandler
}

const useStyles = makeStyles<Theme, Props>(() => ({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',

    '&:hover $indicator': {
      '&::after': {
        opacity: 1,
        transform: 'translateX(-50%) scaleX(1)',
      },
    },

    '&.dragging $indicator': {
      '&::after': {
        opacity: 1,
        transform: 'translateX(-50%) scaleX(1)',
      },
    },
  },
  indicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 20,
    height: 15,
    cursor: 'row-resize',
    background: 'transparent',
    width: '100%',
    willChange: 'transform',
    transition: 'background 0.3s ease',

    '&::after': {
      content: '""',
      bottom: '50%',
      height: 3,
      width: 80,
      left: '50%',
      borderRadius: 5,
      display: 'block',
      position: 'absolute',
      transform: 'translateX(-50%) scaleX(0.4)',
      background: grey[400],
      opacity: 0,
      transition: 'opacity 0.3s ease, transform 0.3s ease',
    },
  },
}))

export function VerticalResizeHandle(props: Props) {
  const { dragging } = props
  const classes = useStyles(props)

  const rootClassName = clsx({
    [classes.container]: true,
    dragging: dragging,
  })

  return (
    <div className={rootClassName}>
      <div className={classes.indicator} onMouseDown={props.onMouseDown} />
    </div>
  )
}
