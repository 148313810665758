import { proxy, snapshot, useSnapshot } from 'valtio'

import { domainProxy } from '@/store'

export type ScrollPositions = {
  ordersGrid: { top: number; left: number }
  scheduler: { top: number; left: number; zoom: number } | null
}

type ScrollPositionsAtom = { positions: ScrollPositions }

// ---------------------------------------------------------------------------
// Default values
// ---------------------------------------------------------------------------
const defaultScrollPositions: ScrollPositions = {
  ordersGrid: { top: 0, left: 0 },
  scheduler: null,
}

// ------------------------------------
// Scroll positions atom
// ------------------------------------

export const scrollPositionAtom = proxy<ScrollPositionsAtom>({
  positions: defaultScrollPositions,
})

// ---------------------------------------------------------------------------
// Read functions
// ---------------------------------------------------------------------------

export function getScrollPosition() {
  return snapshot(scrollPositionAtom).positions
}

// ---------------------------------------------------------------------------
// Write functions
// ---------------------------------------------------------------------------

type SetScrollPosition = (prev: ScrollPositions) => ScrollPositions
type SetScrollPositionParam = SetScrollPosition | Partial<ScrollPositions> | 'reset'

export function resetScrollPosition() {
  scrollPositionAtom.positions = defaultScrollPositions
}

export const setScrollPosition = (valueOrFunc: SetScrollPositionParam) => {
  if (valueOrFunc === 'reset') return resetScrollPosition()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(scrollPositionAtom.positions, valueOrFunc(scrollPositionAtom.positions))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      scrollPositionAtom.positions[field] = valueOrFunc[field]
    }
  }

  return scrollPositionAtom
}

export function setOrdersGridScroll(position: { top: number; left: number }) {
  setScrollPosition({ ordersGrid: position })
}

// @deprecated (used in the legacy scheduler)
export function setSchedulerScroll(position: [number, number]) {
  const [left, top] = position
  setScrollPosition({ scheduler: { top, left, zoom: 0 } })
}

export function setSchedulerZoom(zoom: number) {
  setScrollPosition(prev => {
    const updatedScheduler = prev.scheduler === null ? { top: 0, left: 0, zoom: 0 } : prev.scheduler
    return { ...prev, scheduler: { ...updatedScheduler, zoom } }
  })
}

export function setSchedulerScrollX(x: number) {
  setScrollPosition(prev => {
    const updatedScheduler = prev.scheduler === null ? { top: 0, left: 0, zoom: 0 } : prev.scheduler
    return { ...prev, scheduler: { ...updatedScheduler, left: x } }
  })
}

export function setSchedulerScrollY(y: number) {
  setScrollPosition(prev => {
    const updatedScheduler = prev.scheduler === null ? { top: 0, left: 0, zoom: 0 } : prev.scheduler
    return { ...prev, scheduler: { ...updatedScheduler, top: y } }
  })
}

// ---------------------------------------------------------------------------
// SUBSCRIBE METHOD
// ---------------------------------------------------------------------------

export function subscribeChangeCalendarRange() {
  return domainProxy.subscribeToNotifications(
    notification => {
      if (notification.notificationType === 'changeCalendarRange') {
        setScrollPosition(prev => {
          return { ...prev, scheduler: null }
        })
      }
    },
    ['changeCalendarRange'],
  )
}
export function subscribeReplaceDomainData() {
  return domainProxy.subscribeToDataChangeSuccess(evt => {
    if (evt.mode !== 'replace') return

    setScrollPosition(prev => {
      return { ...prev, scheduler: null }
    })
  })
}

// ------------------------------------
// React Hooks
// ------------------------------------

export function useScrollPositions() {
  return [useSnapshot(scrollPositionAtom).positions, setScrollPosition] as const
}
