import type { PairedOrderSteps } from '../typings'
import { useMemo } from 'react'

import { useCurrentOrderSteps } from './useCurrentOrderSteps'

export const useSelectedOrderSteps = () => {
  const selectedOrderSteps = useCurrentOrderSteps()

  // Retrieves all paired orderSteps not already selected
  const pairedOrderSteps = useMemo(
    () =>
      selectedOrderSteps.ids.reduce<PairedOrderSteps>(
        (pairedOrderStepsAcc: PairedOrderSteps, id: string) => {
          const extOrderStep = selectedOrderSteps.orderSteps[id]
          if (!extOrderStep) return pairedOrderStepsAcc

          if (extOrderStep.type === 'pd') {
            const pairedOrderStep =
              extOrderStep.orderStepType === 'p' ? extOrderStep.delivery : extOrderStep.pickup

            if (pairedOrderStep) {
              if (!selectedOrderSteps.orderSteps[pairedOrderStep.id]) {
                pairedOrderStepsAcc.ids.push(pairedOrderStep.id)
                pairedOrderStepsAcc.orderSteps[pairedOrderStep.id] = pairedOrderStep
              }
            }
          }

          return pairedOrderStepsAcc
        },
        { orderSteps: {}, ids: [] },
      ),
    [selectedOrderSteps],
  )

  return { selectedOrderSteps, pairedOrderSteps } as const
}
