import { ReactNode } from 'react'
import styled from 'styled-components'
import { WithTheme } from '@/local/components'

export interface Props {
  children?: ReactNode
  className?: string
  light?: boolean
}

const getPropsColor = ({ light, theme }: WithTheme<Props>) =>
  light ? theme.colors.$silver : theme.colors.$nightRider

const InputFieldHint = ({ children, className = '' }: Props) => (
  <p className={className}>{children}</p>
)

const StyledInputFieldHint = styled(InputFieldHint)`
  position: relative;
  margin: 0;
  font-size: ${p => p.theme.fonts.$p3};
  font-style: italic;
  color: ${getPropsColor};
`

StyledInputFieldHint.displayName = 'StyledInputFieldHint'

export default StyledInputFieldHint
