import type { ReactNode } from 'react'

interface Props {
  width: number
  renderFrom: number
  children?: ReactNode
}

export function RenderFrom(props: Props) {
  const { width, renderFrom, children } = props

  return width >= renderFrom && children ? <>{children}</> : null
}
