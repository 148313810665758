import { styled } from '@mui/material'

interface TagButtonProps {
  'data-trackid': string
  'data-testid': string
  color: string
}

export const TagButton = styled('button', {
  name: 'TagButton',
  shouldForwardProp: prop => prop !== 'color',
})<TagButtonProps>(({ color }) => ({
  outline: 0,
  borderWidth: 0,
  borderRadius: 4,

  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: color,
}))
