import { useCallback } from 'react'
import { setMainSelection } from '@/atoms'
import { fitMapToSelection } from '@/map'

import { useController } from './useController'

export function useActions() {
  const {
    close,
    data: { lowAccuracyOrderStepIds },
  } = useController()

  const selectImportedOrders = useCallback(() => {
    /*     if (importedOrderStepIds.length > 0) {
      setMainSelection('orderSteps', importedOrderStepIds)
      fitMapToSelection({ preventIfVisible: true })
    } */

    close?.()
  }, [close])

  const selectImportedOrdersWithLowAccuracy = useCallback(() => {
    if (lowAccuracyOrderStepIds.length > 0) {
      setMainSelection('orderSteps', lowAccuracyOrderStepIds)
      fitMapToSelection({ preventIfVisible: true })
    }

    close?.()
  }, [lowAccuracyOrderStepIds, close])

  return {
    selectImportedOrders,
    selectImportedOrdersWithLowAccuracy,
  }
}
