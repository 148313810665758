import CircleStyle from 'ol/style/Circle'
import { Fill, Stroke, Style } from 'ol/style'

import { drawStyleCommon } from './common'

const common = drawStyleCommon()

export const validPoint = new Style({
  image: new CircleStyle({
    radius: common.pointRadius,
    fill: new Fill({
      color: common.blue(1),
    }),
    stroke: new Stroke({
      color: common.white(),
      width: common.pointBorderWidth,
    }),
  }),

  zIndex: Infinity,
})
