import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { addYears, startOfToday } from 'date-fns'

import { selectUserConfiguration } from '@/features/domain/user'
import { DateTimeSelectorField } from '@/forms-legacy'
import { timeUtils } from '@/server-data'

import { useTexts } from './useTexts'

const today: Date = new Date()
today.setHours(0, 0, 0, 0)

export function StartDateTime() {
  const { today: todayAsString } = useSelector(selectUserConfiguration)
  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const calendarRange = useMemo(
    () => ({
      min: startOfToday(),
      max: addYears(today, 100),
    }),
    [],
  )

  const dateRenderer = useCallback(
    (date: Date): string => timeUtils.formatDateTime(userConfig)(date),
    [userConfig],
  )

  return (
    <DateTimeSelectorField
      name="startDateTime"
      label={texts.startGeofence}
      innerLabels={{
        calendar: texts.startDate,
        time: texts.startTime,
      }}
      wrappedInputProps={{
        compactCalendar: {
          dateRenderer,
          limits: calendarRange,
          extraLabels: {
            weekDays: texts.weekDaysLongText,
            tomorrow: texts.tomorrow,
            today: texts.today,
          },
        },
        hourPicker: {
          h24: userConfig.timeFormat === 'HH:mm',
          name: 'startDateTimeHourPicker',
          stepSecs: 60 * 10,
        },
        calendar: {
          months: 1,
          today: todayAsString,
        },
      }}
    />
  )
}
