import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

interface Payload {
  deviceId: string
  date: string
}

/**
 * Fetch breadcrumb data required by Navigo for Breadcrumbs
 *
 */
export const getBreadcrumbData = createAsyncThunk<
  // Return type of the breadcrumbIds creator
  uui.domain.actions.rpc.breadcrumb.GetBreadcrumbDataActionResult['result'],
  // Type of first argument
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/breadcrumb/getBreadcrumbData', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/breadcrumb/getBreadcrumbData', {
      category: 'rpc',
      type: 'rpc/breadcrumb/getBreadcrumbData',
      payload,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {getBreadcrumbData}`,
      { tags: ['rpc', 'breadcrumb'], info: { payload: payload, error } },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `GetBreadcrumbData breadcrumb failed`,
      error,
    })
  }
})
