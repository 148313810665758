import { styled } from '@mui/material'

export const SchedulerHeaderWrapper = styled('div', { name: 'SchedulerHeaderWrapper' })(
  ({ theme }) => ({
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    background: `${theme.palette.background.default2 ?? '#F3F5F7'}`,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
  }),
)
