import { useState, useCallback, useMemo } from 'react'

import { ConfirmPanel } from '@/forms-legacy'

import { Actions } from './Actions'
import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'

interface Props {
  alerts: uui.domain.client.gps.wwgps.EventAlert[]
}

export function ActionsManager(props: Props) {
  const { alerts } = props

  const [view, setView] = useState<'delete' | 'default'>('default')
  const ids = useMemo(() => alerts.map(a => a.id), [alerts])

  const actions = useActions(ids)
  const texts = useTexts()

  const onSwitchToDelete = useCallback(() => {
    setView('delete')
  }, [])

  const onSwitchToDefault = useCallback(() => {
    setView('default')
  }, [])

  switch (view) {
    case 'delete':
      return (
        <ConfirmPanel
          title={texts.title(alerts.length)}
          description={texts.subtitle}
          confirmationText={texts.delete}
          onCancel={onSwitchToDefault}
          onConfirm={actions.onDeleteAlerts}
        />
      )

    case 'default':
      return <Actions onDelete={onSwitchToDelete} />
  }
}
