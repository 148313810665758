import type { RouteStep, Route } from '../../typings'

export const hasBeenStarted = (
  step: RouteStep,
  route: Route,
  nextOrderStep: uui.domain.client.rm.ExtendedOrderStep | null,
  preparationStarted: boolean,
  closeOutStarted: boolean,
): boolean => {
  const { edge } = route
  if (!edge) return false

  const { timeSec } = edge
  const { startSec, type } = step

  if (type === 'preparation')
    return timeSec > startSec || (timeSec === startSec && preparationStarted)

  if (type === 'closeOut') return timeSec > startSec || (timeSec === startSec && closeOutStarted)

  if (!nextOrderStep) {
    return timeSec >= startSec
  }

  if (timeSec > startSec) return true

  const gotTrackedEvents = nextOrderStep.isUnassigned
    ? false
    : nextOrderStep.orderStep.trackingData.timeInSec > -1 ||
      nextOrderStep.orderStep.trackingData.timeOutSec > -1 ||
      nextOrderStep.orderStep.trackingData.autoTimeInSec > -1 ||
      nextOrderStep.orderStep.trackingData.autoTimeOutSec > -1 ||
      nextOrderStep.orderStep.trackingData.statusSec > -1

  if (timeSec === startSec && gotTrackedEvents) return true

  return false
}
