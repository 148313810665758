import { type CSSProperties } from 'react'
import { lighten } from '@mui/material'

type Props = {
  value: number
  color: string
  height?: number
  style?: CSSProperties
}

export function LinearProgress(props: Props) {
  const { value: rawValue, color, height = 2, style } = props

  const value = Math.min(Math.max(0, rawValue), 100)

  const containerBackground = lighten(color, 0.62)

  return (
    <div
      className="o-scheduler-linear-progress-container"
      style={
        {
          '--background': containerBackground,
          '--height': `${height}px`,
          ...(style ?? {}),
        } as CSSProperties
      }
    >
      <div
        className="o-scheduler-linear-progress-inner"
        style={{ '--x': value, '--background': color } as CSSProperties}
      />
    </div>
  )
}
