import { Cross } from '@/icons'
import { WarningCard } from '@/local/components'

import { useTexts } from '../useTexts'

export function InvalidBody() {
  const texts = useTexts()

  return (
    <div
      style={{
        paddingTop: 40,
      }}
    >
      <WarningCard
        title={texts.warningCardEmptyOrdersTitle}
        description={texts.warningCardEmptyOrdersDesc}
        Icon={<Cross />}
        preset="alert"
      />
    </div>
  )
}
