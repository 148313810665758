import { useSelector } from 'react-redux'
import { selectOrderMapStyles } from '@/features/domain/ui'

export const useOrdersMapStyle = () => {
  const { mode, custom } = useSelector(selectOrderMapStyles)

  if (Object.keys(custom).length > 0) return

  return mode
}
