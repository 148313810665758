import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'

import { useExportRoutesModal } from '@/components/modals/ExportRoutesModal'
import { useExportOrderModal } from '@/components/modals/ExportOrderModal'

interface Props {
  children: ReactNode
}

interface Context {
  exportRoutes: {
    show: () => void
    close: () => void
  }
  exportOrders: {
    show: () => void
    close: () => void
  }
}

const noop = () => undefined

function getInitialContext(): Context {
  return {
    exportRoutes: {
      show: noop,
      close: noop,
    },
    exportOrders: {
      show: noop,
      close: noop,
    },
  }
}

const context = createContext<Context>(getInitialContext())

export function ResidentModals(props: Props) {
  const { children } = props

  const {
    show: showExportRoutesModal,
    Modal: ExportRoutesModal,
    close: closeExportRoutesModal,
  } = useExportRoutesModal()

  const {
    show: showExportOrdersModal,
    Modal: ExportOrdersModal,
    close: closeExportOrdersModal,
  } = useExportOrderModal()

  const contextValue = useMemo<Context>(() => {
    return {
      exportRoutes: {
        show: showExportRoutesModal,
        close: closeExportRoutesModal,
      },
      exportOrders: {
        show: showExportOrdersModal,
        close: closeExportOrdersModal,
      },
    }
  }, [showExportRoutesModal, closeExportRoutesModal, showExportOrdersModal, closeExportOrdersModal])

  return (
    <context.Provider value={contextValue}>
      {children}
      <ExportRoutesModal />
      <ExportOrdersModal />
    </context.Provider>
  )
}

export function useResidentModalsApi() {
  return useContext(context)
}
