import type { StyleFunction } from 'ol/style/Style'
import type { FeatureLike } from 'ol/Feature'
import { Geometry, Point } from 'ol/geom'
import { Style, Icon } from 'ol/style'
import mapPinSpriteSheet from '@/resources/map-markers/pins.png'

import { getLocationPinSvgText } from '../../../../../../../../../../../../../../../map/utils/getLocationPinSvgText'
import { getPinGridPlacement } from '../../../../../../../../../../../../../../../map/layers/pin/coordinates/getPinGridPlacement'

import { getTexts } from './getTexts'

// Initialize a cache for storing styles
const styleCache = new Map<string, Style[]>()

// helper function to get the symbol of pin based on the pinType
function getPinSymbol(pinType: uui.domain.ui.map.LocationPinType): uui.spriteSheet.PinSymbol {
  switch (pinType) {
    case 'normal':
    case 'geofence':
      return 'bullsEye'

    case 'depot':
      return 'depot'

    case 'place':
      return 'place'

    case 'loading':
      return 'loading'
  }
}

const texts = getTexts()

// Create a style for the lable
const pinLabelStyle = new Style({
  image: new Icon({
    opacity: 1,
    scale: 1,
    src:
      'data:image/svg+xml;charset=utf-8,' +
      encodeURIComponent(getLocationPinSvgText(texts.dragThePin, 24, 300)),
    displacement: [0, -25],
  }),
})

function getPinLocationStatus(feature: FeatureLike) {
  const pinOriginal = feature.get('originalCoord') as string
  const geometry = feature.getGeometry()

  if (geometry instanceof Geometry && geometry.getType() === 'Point') {
    const center = geometry as Point
    const centerCoordinate = center.getCoordinates()

    if (pinOriginal === `${centerCoordinate[0]}_${centerCoordinate[1]}`) {
      return 'pristine'
    } else {
      return 'dragged'
    }
  } else {
    return 'pristine'
  }
}

// Export a function that returns a style for a given symbol and cell type
export const getLocationPinStyle: StyleFunction = (feature, _resolution) => {
  // get the pin symbol based on the pinType
  const pinSymbol = getPinSymbol(feature.get('symbol'))
  const pinStatus = feature.get('status') as uui.spriteSheet.PinCells
  const pinPositionStatus = getPinLocationStatus(feature)

  // Create a cache key for the symbol and cell type
  const cacheKey = `${pinSymbol}-${pinStatus}-${pinPositionStatus}`

  // Check if the style is already in the cache
  if (styleCache.has(cacheKey)) {
    // If it is, return it
    const style = styleCache.get(cacheKey)
    if (style) style
  }

  // If it's not in the cache, calculate the coordinates for the symbol and cell type
  const coordinates = getPinGridPlacement(pinSymbol, pinStatus)

  // Get the size of the cell
  const cellSize = coordinates[2]

  // Get the x and y coordinates of the cell
  const x = coordinates[0]
  const y = coordinates[1]

  // Create a new style object with the calculated coordinates
  const style = new Style({
    image: new Icon({
      src: mapPinSpriteSheet,
      crossOrigin: 'anonymous',

      // Additional properties for the icon
      scale: 0.5,
      opacity: 1.0,
      rotation: 0.0,
      rotateWithView: true,

      offset: [x, y],
      size: [cellSize, cellSize],

      anchor: [0.5, 21],
      anchorOrigin: 'bottom-left',
      anchorYUnits: 'pixels',
      anchorXUnits: 'fraction',
    }),
  })

  const styles = pinPositionStatus === 'pristine' ? [style, pinLabelStyle] : [style]

  // Add the new style object to the cache
  styleCache.set(cacheKey, styles)

  // Return the new style object
  return styles
}
