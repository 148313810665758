import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'

import { findDeviceEventMarker } from './findDeviceEventMarker'

import { createDeviceEventPointsFeatures } from './deviceEventPoints/createDeviceEventPointsFeatures'

const nonblockingRender = createNonBlockingRenderByTime()

export async function changelogAddDeviceEventFeatures(
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.DeviceEvents>,
  selection: Set<string>,
  markerIds: IterableIterator<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  const pointsFeatures: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findDeviceEventMarker(markers, markerId)
    if (!marker) continue

    // Points features
    pointsFeatures.push(...createDeviceEventPointsFeatures(marker, selection, breadcrumbTimeRange))
  }

  // add all new features to the layers
  layer.getSource()?.addFeatures(pointsFeatures)

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
