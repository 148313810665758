import { TimePickerBody, useValidateOn } from '@/formUi'
import { useTexts } from './hooks/useTexts'

interface Props {
  label: string
  value: number
  onChange: (value: number) => void
  options: number[]
  disabled?: boolean
  errorMessage?: string
  hasError?: boolean
  helperText?: string
  testId?: string
  timeFormat: uui.domain.client.TimeFormat
  validate: (force?: boolean) => void
}

export function TimePicker(props: Props) {
  const {
    value,
    onChange,
    options,
    disabled,
    label,
    errorMessage,
    helperText,
    timeFormat,
    hasError,
    validate,
    testId,
  } = props

  const texts = useTexts()
  const validations = useValidateOn(validate, 'blur change')

  return (
    <TimePickerBody
      label={label}
      format={timeFormat}
      helperText={helperText}
      optionValues={options}
      errorLabels={texts}
      allowUnlistedValues
      value={value}
      required
      disabled={disabled}
      visible
      hasError={hasError}
      errorMessage={errorMessage}
      validations={validations}
      onChange={onChange}
      testId={testId}
    />
  )
}
