import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { getLayerMetadata, setLayerMetadata } from '../../layerMetadata'

import { updateBreadcrumbPointsFeature } from './breadcrumbPoints/updateBreadcrumbPointsFeature'
import { updateBreadcrumbStopsFeature } from './breadcrumbStops/updateBreadcrumbStopsFeature'

import { findBreadcrumbFeature } from './findBreadcrumbFeature'
import { findBreadcrumbMarker } from './findBreadcrumbMarker'

const nonblockingRender = createNonBlockingRenderByTime()

export async function updateBreadcrumbMapSelection(
  pointsLayer: VectorLayer<VectorSource>,
  pathLayer: VectorLayer<VectorSource>,
  mapMarkers: uui.domain.ui.map.markers.MapMarkers['breadcrumb'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
  selection: Set<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  // retrieve selected items in layers (points and stops)
  const pointsLayerSelection = (getLayerMetadata(pointsLayer, 'selection') ??
    new Set()) as Set<string>
  const pathLayerSelection = (getLayerMetadata(pathLayer, 'selection') ?? new Set()) as Set<string>

  const updatedPointsSelection = new Set<string>()
  const updatedStopsSelection = new Set<string>()

  // if prev and next are empty do nothing
  if (
    (selection.size === 0 && (pointsLayerSelection.size !== 0 || pathLayerSelection.size !== 0)) ||
    selection.size !== 0
  ) {
    const breadcrumbsToDeselect = new Set([...pointsLayerSelection, ...pathLayerSelection])

    nonblockingRender.reset()

    for (const id of selection) {
      await nonblockingRender.next()

      // points/stops id format is 'markerId@UID'
      const markerId = id.split('@')[0]
      const marker = findBreadcrumbMarker(mapMarkers, markerId)

      if (!marker) {
        throw new Error(
          `Trying to select Breadcrumb point with ID: ${id} but there's no relative marker`,
        )
      }

      if (!marker.fetched) {
        throw new Error(`Trying to select non-fetched Breadcrumb point with ID: ${id}`)
      }

      breadcrumbsToDeselect.delete(id)

      if (pointsLayerSelection.has(id)) {
        // id was previously selected and it's still selected
        // do nothing
        continue
      }

      const result = findBreadcrumbFeature(pointsLayer, pathLayer, id)

      if (!result) {
        // fail silently in production when deselecting unknown items
        if (process.env.NODE_ENV === 'development') {
          throw new Error(
            `Trying to select Breadcrumb point/stop with ID: ${id} but no feature exist on the map`,
          )
        }

        continue
      }

      const featureToUpdate = result
      const type = featureToUpdate.get('type') as 'breadcrumbPoints' | 'breadcrumbStops'
      const pointOrStop = type === 'breadcrumbPoints' ? marker.points[id] : marker.stops[id]

      if (!pointOrStop) {
        throw new Error(
          `Trying to select Breadcrumb point/stop with ID: ${id} but no point exists on the marker`,
        )
      }

      // the id is a new selection

      switch (type) {
        case 'breadcrumbPoints':
          updateBreadcrumbPointsFeature(
            mapStyles,
            marker,
            featureToUpdate,
            pointOrStop,
            true,
            breadcrumbTimeRange,
          )
          updatedPointsSelection.add(id)
          break

        case 'breadcrumbStops':
          updateBreadcrumbStopsFeature(
            mapStyles,
            marker,
            featureToUpdate,
            pointOrStop,
            true,
            breadcrumbTimeRange,
          )
          updatedPointsSelection.add(id)
          updatedStopsSelection.add(id)
          break
      }
    }

    nonblockingRender.reset()

    for (const id of breadcrumbsToDeselect) {
      await nonblockingRender.next()

      // points/stops id format is 'markerId@UID'
      const markerId = id.split('@')[0]
      const marker = findBreadcrumbMarker(mapMarkers, markerId)

      if (!marker) {
        throw new Error(
          `Trying to select Breadcrumb point with ID: ${id} but there's no relative marker`,
        )
      }

      if (!marker.fetched) {
        throw new Error(`Trying to select non-fetched Breadcrumb point with ID: ${id}`)
      }

      const result = findBreadcrumbFeature(pointsLayer, pathLayer, id)

      if (!result) {
        // fail silently in production when deselecting unknown items
        if (process.env.NODE_ENV === 'development') {
          throw new Error(
            `Trying to deselect Breadcrumb point/stop with ID: ${id} but no feature exist on the map`,
          )
        }

        continue
      }

      const featureToUpdate = result
      const type = featureToUpdate.get('type') as 'breadcrumbPoints' | 'breadcrumbStops'
      const pointOrStop = type === 'breadcrumbPoints' ? marker.points[id] : marker.stops[id]

      if (!pointOrStop) {
        throw new Error(
          `Trying to select Breadcrumb point with ID: ${id} but no point exists on the marker`,
        )
      }

      // the id is a new selection
      // deselect the breadcrumb
      switch (type) {
        case 'breadcrumbPoints':
          updateBreadcrumbPointsFeature(
            mapStyles,
            marker,
            featureToUpdate,
            pointOrStop,
            false,
            breadcrumbTimeRange,
          )

          break
        case 'breadcrumbStops':
          updateBreadcrumbStopsFeature(
            mapStyles,
            marker,
            featureToUpdate,
            pointOrStop,
            false,
            breadcrumbTimeRange,
          )
          break
      }
    }
  }

  // update the stored collection
  setLayerMetadata(pointsLayer, 'selection', updatedPointsSelection)
  setLayerMetadata(pathLayer, 'selection', updatedStopsSelection)
}
