import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Rewind(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 9 6">
        <path d="M8.22692 0v6L4.60449 3.54911V6L.98207 3.54911V6h-.805V0h.805v2.45089L4.60449 0v2.45089z" />
      </svg>
    </IconContainer>
  )
}
