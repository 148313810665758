import type { BulkTagValuePartial } from '../../../../../types'

import { Stack, Chip, Typography, IconButton, Tooltip } from '@mui/material'
import { AddCircleOutline } from '@mui/icons-material'

import { useTexts } from './useTexts'

interface Props {
  onDeleteTag: (tagName: string) => void
  onAddTag: (tagName: string) => void
  tags: BulkTagValuePartial[]
  ordersCount: number
  limitReached: boolean
  bannedTagCategory: 'tagsIn' | 'tagsOut'
}

export function PartialList(props: Props) {
  const { tags, onDeleteTag, onAddTag, ordersCount, bannedTagCategory, limitReached } = props

  const texts = useTexts()

  if (tags.length === 0) return null

  return (
    <Stack paddingTop={1} paddingBottom={2}>
      <Typography variant="caption" paddingBottom={1} color="text.secondary">
        {texts.listTitle}
      </Typography>
      {tags.map(tag => {
        return (
          <Stack direction="row" spacing={2} alignItems="center" key={tag.name}>
            <Typography variant="body2" color="text.disabled">
              {tag.count}/{ordersCount}
            </Typography>
            <Stack direction="row" spacing={0} alignItems="center">
              <Tooltip title={tag.warning ? texts.warningTooltip(bannedTagCategory) : ''}>
                <Chip
                  label={tag.name}
                  variant="outlined"
                  color={tag.warning ? 'error' : undefined}
                  onDelete={() => onDeleteTag(tag.name)}
                />
              </Tooltip>
              <IconButton
                color="success"
                onClick={() => onAddTag(tag.name)}
                disabled={limitReached}
              >
                <AddCircleOutline />
              </IconButton>
            </Stack>
          </Stack>
        )
      })}
    </Stack>
  )
}
