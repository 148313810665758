import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    advanced: translate({ id: 'app.header.setup.advanced' }),
    alerts: translate({ id: 'app.header.setup.alerts' }),
    places: translate({ id: 'app.header.setup.places' }),
    regions: translate({ id: 'app.header.setup.regions' }),
    roadEditor: translate({ id: 'app.header.setup.road' }),
    geofences: translate({ id: 'app.header.setup.geofences' }),
    trafficArea: translate({ id: 'app.header.setup.traffic' }),
    disabledForBeta: translate({ id: 'global.disabledForBeta' }),
    devices: translate({ id: 'app.header.setup.devices' }),
  }))

  return api
}
