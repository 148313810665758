import type { View } from '../types'
import { useState } from 'react'
import { Box, Stack } from '@mui/material'
import { TidalThemeProvider } from '@workwave-tidal/tidal/styles'
import { AdvertisingWindow } from '@workwave-tidal/login-ui/AdvertisingWindow'
import { LoginLayout } from '@workwave-tidal/login-ui/LoginLayout'
import { useEnvironment } from '@/atoms'
import { useResetAtomsOnLogout } from './hooks/useResetAtomsOnLogout'
import { RedirectOnceLogged } from './components/RedirectOnceLogged'
import { useLocationFrom } from './hooks/useLocationFrom'
import { Main } from './components/Main'

interface Props {
  initialView?: View
}

const boxStyle = { background: '#ffffff' }

export function Login(props: Props) {
  const { initialView = 'login' } = props

  // State to keep track of the current view
  const [view, setView] = useState<View>(initialView)

  // Get the current environment
  const environment = useEnvironment()

  // Get the location from where the user came from
  const from = useLocationFrom()

  // Use a hook to reset the atoms on logout
  useResetAtomsOnLogout()

  // Check if the environment is production to show the advertising
  const hideAdv = !environment || environment?.env !== 'production'

  return (
    <TidalThemeProvider>
      <Box width="100vw" height="100vh" sx={boxStyle}>
        {/* Redirect the user once logged in */}
        <RedirectOnceLogged to={from} />

        <LoginLayout
          // Main content of the login page
          main={<Main view={view} setView={setView} />}
          // Secondary content of the login page (advertising or placeholder)
          secondary={
            <AdvertisingWindow
              // URL for the advertising link
              url="https://ww-login.netlify.app/route-manager"
              // Test ID for the advertising window
              testId="login-advertising"
              // Product name for the advertising window
              product="RM"
              // Disable prefetching for the advertising window
              disabledPrefetch
              // Hide the advertising window if the environment is not production
              disabled={hideAdv}
              // Placeholder for the advertising window
              disabledPlaceholder={
                <Stack
                  sx={{ backgroundColor: 'lightblue' }}
                  width="100%"
                  height="100%"
                  alignItems="center"
                  fontWeight="bold"
                  justifyContent="center"
                >
                  ADV PLACEHOLDER
                </Stack>
              }
            />
          }
        />
      </Box>
    </TidalThemeProvider>
  )
}
