export function isPointOutOfTimeRange(
  point: uui.domain.ui.map.markers.BreadcrumbPoint,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  if (breadcrumbTimeRange === 'live') return false

  if (breadcrumbTimeRange.start === -1 || breadcrumbTimeRange.end === -1) return false

  const startTime = point.timestamp
  const endTime = point.timestamp + ('stoppedTime' in point ? (point?.stoppedTime ?? 0) * 1000 : 0)

  return (
    (startTime < breadcrumbTimeRange.start || startTime > breadcrumbTimeRange.end) &&
    (endTime < breadcrumbTimeRange.start || endTime > breadcrumbTimeRange.end)
  )
}
