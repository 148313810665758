import styled from 'styled-components'

const styledComponent = styled.div.attrs<workwave.Div>(() => ({
  className: 'o-try-notidication-input-button-wrapper',
}))`
  display: flex;
  align-items: center;
  border: 2px solid ${p => p.theme.colors.$pureBlack};
  border-radius: 4px;
  width: 100%;
`
styledComponent.displayName = 'TryNotificationInputButtonWrapper'
export default styledComponent
