import { CircularProgress } from '@mui/material'
import { NestedDropdown, DropdownMenuItem } from '@/components/DropdownMenu'
import { StrikedEye } from '@/icons'

import { ActiveIcon } from '../ActiveIcon'
import { useTexts } from './useTexts'
import { useVehiclesMapStyle } from './hooks/useVehiclesMapStyle'
import { useIsEnabled } from './hooks/useIsEnabled'
import { useActions } from './hooks/useActions'

interface Props {
  parentOpen: boolean
}

export function VehiclesSubMenu(props: Props) {
  const { parentOpen } = props

  const {
    methodInExecution,
    actions: { showVehiclesWithLabels, showVehicles, hideVehicles },
  } = useActions()
  const activeStyle = useVehiclesMapStyle()

  const isEnabled = useIsEnabled()
  const texts = useTexts()

  const tooltipTitle =
    isEnabled === true ? undefined : isEnabled === 'notToday' ? texts.notToday : texts.noVehicles

  return (
    <NestedDropdown
      placement="left"
      parentOpen={parentOpen}
      tooltipTitle={tooltipTitle}
      disabled={isEnabled !== true}
      triggerContent={texts.vehicles}
      Icon={
        isEnabled === true && activeStyle === 'off' ? (
          <StrikedEye size={12} color="$silver" />
        ) : undefined
      }
    >
      <DropdownMenuItem
        external
        onClick={showVehiclesWithLabels}
        Icon={
          methodInExecution === 'showVehiclesWithLabels' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'label'} />
          )
        }
      >
        {texts.onWithLabel}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={showVehicles}
        Icon={
          methodInExecution === 'showVehicles' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'on'} />
          )
        }
      >
        {texts.on}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={hideVehicles}
        Icon={
          methodInExecution === 'hideVehicles' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'off'} />
          )
        }
      >
        {texts.off}
      </DropdownMenuItem>
    </NestedDropdown>
  )
}
