import { VerticalLayout } from '@/components/layout'

import { useController } from '../../../../hooks/useController'
import { MessageTypeSelector } from './blocks/MessageTypeSelector'
import { Message } from './blocks/Message'
import { Email } from './blocks/Email'
import { Sms } from './blocks/Sms'

export function GpsOnlyBody() {
  const {
    data: { messageType },
  } = useController()

  return (
    <VerticalLayout marginTop={32}>
      <MessageTypeSelector />

      {messageType === 'email' ? <Email /> : <Sms />}

      <Message />
    </VerticalLayout>
  )
}
