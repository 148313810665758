import type { SearchableListTypes } from '@/forms-legacy'

import { useCallback, MouseEvent } from 'react'
import { Box } from '@mui/material'

import { FlexRow, FlexColumn, Dot, Checkbox } from '@/forms-legacy'
import { normalizeServerColorString } from '@/local/server-data/utils/colors'

import { RegionListItemWrapper } from './RegionListItemWrapper'
import { TransparentButton } from './TransparentButton'

export function RegionListItem(
  props: SearchableListTypes.ListItemProps<uui.domain.client.rm.Region>,
) {
  const {
    onChange,
    value,
    disabled,
    readonly,
    selected,
    value: { id, color, name },
  } = props

  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { shiftKey, metaKey, ctrlKey, altKey } = event
      onChange([value], { shiftKey, metaKey, ctrlKey, altKey })
    },
    [onChange, value],
  )

  const dotColor = normalizeServerColorString(color)

  return (
    <RegionListItemWrapper key={id}>
      <FlexColumn primary>
        <FlexRow>
          <TransparentButton onClick={handleOnClick} disabled={disabled}>
            <Checkbox
              checked={!!selected}
              disabled={disabled || readonly}
              id={id}
              onChange={() => undefined}
              style={{ marginRight: 6, pointerEvents: 'none' }}
            />
            {name}
          </TransparentButton>
        </FlexRow>
      </FlexColumn>
      <FlexColumn>
        <Box width={14} height={14}>
          <Dot innerColor={dotColor} outerColor={dotColor} />
        </Box>
      </FlexColumn>
    </RegionListItemWrapper>
  )
}
