import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (activeBadgesCount: number, totalBadgesCount: number) =>
      translate({
        id: 'modals.filterByBadge.list.title',
        values: {
          badgeStats: activeBadgesCount > 0 ? `(${activeBadgesCount}/${totalBadgesCount})` : '',
        },
      }),
    check: translate({ id: `modals.filterByBadge.list.action.checkAll` }),
    unCheck: translate({ id: `modals.filterByBadge.list.action.uncheckAll` }),
    showEntitiesWithoutBadge: translate({
      id: 'modals.filterByBadge.checkbox.showEntitiesWithoutBadge',
    }),
    filterByBadge: translate({ id: 'modals.filterByBadge.badgeSelect.label' }),
    matchCriteria: translate({
      id: 'modals.filterByBadge.checkbox.matchCriteria',
    }),
    matchCriteriaTitle: (disabled: boolean) =>
      translate({
        id: disabled
          ? 'modals.filterByBadge.checkbox.matchCriteria.title.disabled'
          : 'modals.filterByBadge.checkbox.matchCriteria.title.enabled',
      }),
  }))

  return api
}
