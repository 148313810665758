import type { DevPreferences, DevPreferencesKeys } from './types'

import { produce } from 'immer'
import { journal } from '@/server-data'

/**
 * Store some short-living dev-related configurations (disabled in production).
 */

const storageId = 'routemanager/v1/devPreferences'

// Prefer plain string values over complex objects that could lead to parsing errors
const defaultPreferences: DevPreferences = {
  mainSelectionDevTools: 'disable',
  crudSelectionDevTools: 'disable',
  editingStateDevTools: 'disable',
}

// ATTENTION: This production detection has nothing to do with the environment atom of the UI that comes
// from the config.json file. This is helpful to keep this module extremely simple, but the rest of the app must
// base environment detection on the environment atom.
const isProduction =
  globalThis.location.href.includes('beta.') || // V3 public beta is at beta.routemanager.workwave.com
  globalThis.location.href.includes('//routemanager.workwave.com')

// ----------------------------------------
// INITIAL CONFIG -------------------------
// ----------------------------------------

const initialPreferences = isProduction
  ? defaultPreferences // in production, the preferences are ignored
  : read()

if (initialPreferences !== defaultPreferences) {
  log(initialPreferences)
}

// ----------------------------------------
// PRIVATE --------------------------------
// ----------------------------------------

function read() {
  const storageData = sessionStorage.getItem(storageId)
  if (!storageData) return defaultPreferences

  return JSON.parse(storageData) as DevPreferences
}

function write(value: DevPreferences) {
  if (isProduction) {
    journal.main('Cannot write Dev Preferences', {}, 'warn')
    return
  }

  sessionStorage.setItem(storageId, JSON.stringify(value))
}

function log(preferences: DevPreferences) {
  console.group()
  console.log(
    '%c Dev Preferences enabled ',
    'background: red; color: white; font-weight: bold; display: block;',
  )
  console.table(preferences)
  console.groupEnd()
}

// ----------------------------------------
// GET ------------------------------------
// ----------------------------------------

export function getDevPreference<T extends DevPreferencesKeys = DevPreferencesKeys>(key: T) {
  return read()[key]
}

export function getDevPreferences() {
  return read()
}

// ----------------------------------------
// SET ------------------------------------
// ----------------------------------------

export function updateDevPreference<T extends DevPreferencesKeys = DevPreferencesKeys>(
  key: T,
  value: DevPreferences[T],
) {
  const prevPreferences = read()

  const nextPreferences = produce(prevPreferences, draft => {
    draft[key] = value
  })

  write(nextPreferences)
}

// ----------------------------------------
// RESET ----------------------------------
// ----------------------------------------

export function resetDevPreferences() {
  if (isProduction) {
    journal.main('Cannot reset Dev Preferences', {}, 'warn')
    return
  }

  write(defaultPreferences)
}

export function removeDevPreferences() {
  if (isProduction) {
    journal.main('Cannot remove Dev Preferences', {}, 'warn')
    return
  }

  sessionStorage.removeItem(storageId)
}
