import type { RadioGroupValue } from '@/forms-legacy'
import type { GeofenceFormValues } from '../../types'

import { useMemo } from 'react'
import { useForm } from 'react-final-form'

import { HorizontalRadioGroupField } from '@/forms-legacy'
import { theme } from '@/styles'

import { useTexts } from './useTexts'

export function GeofenceRepeat() {
  const texts = useTexts()
  const form = useForm()

  const { values } = form.getState()
  const { repeat } = values as GeofenceFormValues

  const info: string =
    repeat === 'continuous' ? texts.repeatAlwaysDescription : texts.repeatOneTimeDescription

  const wrappedInputProps = useMemo(() => {
    const colors = {
      background: theme.colors.$pureWhite,
      backgroundSelected: theme.colors.$nightRider,
      color: theme.colors.$nightRider,
      colorSelected: theme.colors.$pureWhite,
      border: theme.colors.$nightRider,
      borderSize: 1,
    }

    const continuous: RadioGroupValue = {
      label: texts.continuous,
      value: 'continuous',
    }

    const onetime: RadioGroupValue = {
      label: texts.onetime,
      value: 'onetime',
    }

    return { colors, values: [continuous, onetime] }
  }, [texts])

  return (
    <HorizontalRadioGroupField
      wrappedInputProps={wrappedInputProps}
      label={texts.repeatTitle}
      name="repeat"
      info={info}
    />
  )
}
