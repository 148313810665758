import { ActionBarItem } from '@workwave-tidal/components/ActionBar'
import { Close } from '@workwave-tidal/icons'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../../hooks/useController'
import { useTexts } from './useTexts'

export function Header() {
  const texts = useTexts()
  const { close } = useController()

  return (
    <ModalHeader
      title={texts.title}
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
    />
  )
}
