import type { ChipShape, CoreItem, Modes, VehicleChip } from '../../../components/Navigator/typings'

import { clsx } from '@/utils'
import { theme } from '@/local/components'
import { getColorValue, isDark } from '@/styles'

import { Chip } from '../../../components/Navigator/components/Chip'

export interface Props<T extends CoreItem, K> {
  item: T
  mode: Modes
  selected?: boolean
  onClick: () => void
  className?: string
  extraItemRendererProps: K
  loopIndex: number
}

export function VehicleItemRenderer(props: Props<VehicleChip, Record<string, any>>) {
  const {
    item: { source },
    onClick,
    className: cls = '',
  } = props

  if (!source.hasRoutingLicense) return null

  const className = clsx(cls, 'o-chip--circle')
  const background: string = getColorValue(source.vehicle.color)

  const textColor = isDark(background) ? theme.colors.$pureWhite : theme.colors.$nightRider

  const chipProps = {
    onClick,
    className,
    background,
    color: textColor,
    borderColor: background,
    row: `${source.vehicle.idx}`,
    shape: 'rounded' as ChipShape,
  }

  return <Chip {...chipProps} />
}
