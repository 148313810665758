import { RouteStats } from './components/RouteStats'
import { VehicleStats } from './components/VehicleStats'
import { useExtendedRoute } from './hooks/useExtendedRoute'

interface Props {
  routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable
}

export function Single(props: Props) {
  const { routeInfo } = props
  const extRoute = useExtendedRoute(routeInfo)

  if (extRoute) {
    return <RouteStats extRoute={extRoute} />
  } else {
    return <VehicleStats routeInfo={routeInfo} />
  }
}
