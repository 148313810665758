import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { refreshSessionUser, selectUserType } from '@/features/domain/user'
import { fetchApiKey } from '@/features/domain/account'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useTexts } from './useTexts'

export function useRefreshRmAccountData() {
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  const userType = useSelector(selectUserType)
  const gotAccessToApiKey = userType === 'admin'

  useEffect(() => {
    const refreshApiKey = async () => {
      try {
        const thunkResult = await dispatch(fetchApiKey())

        if (fetchApiKey.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }
      } catch (error) {
        toast.error(texts.errorFetchingApiKey, { autoClose: 5000 })
      }
    }

    const refreshAccountData = async () => {
      try {
        const thunkResult = await dispatch(refreshSessionUser())

        if (refreshSessionUser.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }
      } catch (error) {
        toast.error(texts.errorFetchingAccountData, { autoClose: 5000 })
      }
    }

    const timer = setTimeout(() => {
      if (gotAccessToApiKey) {
        refreshApiKey()
      }
      refreshAccountData()
    }, 0)

    return () => {
      clearTimeout(timer)
    }
  }, [toast, texts, dispatch, gotAccessToApiKey])
}
