import type { FormFields, FormErrors } from '../../../../../formFields'

import { TextField } from '@workwave-tidal/tidal/form-ui'
import { useTexts } from '../../../../../useTexts'

export function Label() {
  const texts = useTexts()
  return (
    <TextField<'label', FormFields, FormErrors>
      name="label"
      label={texts.linxup.label}
      helperText={texts.linxup.labelHelperText}
      validateOn="change"
      required={false}
      textfieldProps={{ size: 'medium' }}
    />
  )
}
