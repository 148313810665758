import { Component } from 'react'

import { TextButton } from '@/forms-legacy'

import { Props } from './typings'

import Triangle from './Triangle'

export default class CollapseButton extends Component<Props> {
  render() {
    const { text, collapsed, onToggle } = this.props

    return (
      <TextButton
        onClick={onToggle}
        style={{ display: 'flex', alignItems: 'center', marginLeft: 6 }}
      >
        {text}
        <Triangle style={{ transform: collapsed ? 'rotate(180deg)' : 'rotate(0)' }} />
      </TextButton>
    )
  }
}
