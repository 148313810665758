import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  popover: {
    pointerEvents: 'none',
    '& .paper': {
      borderRadius: 4,
    },
  },
  container: { minWidth: 200 },
  noDispatchIcon: { position: 'absolute', top: 21, right: 13 },
})
