import styled from 'styled-components'

type Props = {
  centered?: boolean
}

const styledComponent = styled.div<Props>`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${p => (p.centered ? 'center' : 'space-between')};
  width: 100%;
  padding: 4px 16px;
`

styledComponent.displayName = 'ColumnHeaderRow'
export default styledComponent
