import type { FormProps } from '../types'

import { NotificationForm } from '../../../../../../notificationForms/sections/notifications/components/NotificationForm'
import { NotificationView } from '../../../../../../notificationForms/sections/notifications/components/NotificationView'
import { useFormStateController } from '../../hooks/useFormStateController'
import { useLiveEtaStatus } from '../../hooks/useLiveEtaStatus'

import { AlmostThereNotificationView } from './components/AlmostThereNotificationView'

import { useAlmostThereNotificationInitForm } from './hooks/useAlmostThereNotificationInitForm'
import { useAlmostThereNotificationSubmit } from './hooks/useAlmostThereNotificationSubmit'
import { useAlmostThereNotificationConfig } from './hooks/useAlmostThereNotificationConfig'
import { useAlmostThereNotificationValues } from './hooks/useAlmostThereNotificationValues'
import { useAlmostThereNotificationToggle } from './hooks/useAlmostThereNotificationToggle'
import { useTexts } from './useTexts'

/**
 * Renders the AlmostThere notification form providing all the domain data and apis.
 */
export function AlmostThereNotification(props: FormProps) {
  const { inEditing, setEditingState } = props

  const values = useAlmostThereNotificationValues()

  const { toggleDescription, onToggle } = useAlmostThereNotificationToggle(values)
  const { edit, cancel } = useFormStateController(setEditingState)
  const onSubmit = useAlmostThereNotificationSubmit()
  const config = useAlmostThereNotificationConfig()
  const liveEtaStatus = useLiveEtaStatus()
  const texts = useTexts()

  const available = liveEtaStatus !== 'notActive' && liveEtaStatus !== 'notAvailableForTheUser'

  // Render the Form inside a FormRoot component that will expose the FormState API through a React.Context
  if (inEditing) {
    return (
      <NotificationForm
        initHook={useAlmostThereNotificationInitForm}
        subtitle={texts.description}
        notification="almostThere"
        onSubmit={onSubmit}
        title={texts.title}
        cancel={cancel}
        values={values}
        config={config}
      />
    )
  }

  return (
    <NotificationView
      Form={<AlmostThereNotificationView values={values} />}
      toggleSubtitle={toggleDescription}
      subtitle={texts.description}
      notification="almostThere"
      available={available}
      onToggle={onToggle}
      title={texts.title}
      values={values}
      onEdit={edit}
    />
  )
}
