import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { useMainSelection, useEditingStateWithCtx } from '@/atoms'
import { store, useAppDispatch } from '@/store'
import { selectOrderSteps } from '@/features/domain/order'
import { setOrdersFilter } from '@/features/domain/lists'

export const useOrdersRoot = () => {
  const dispatch = useAppDispatch()

  const editingState = useEditingStateWithCtx('order')
  const [selection] = useMainSelection('orderSteps')
  const orders = useSelector(selectOrderSteps)

  const selectedOrders = useMemo(() => {
    return getSelectedOrders(selection, orders)
  }, [selection, orders])

  const resetOrdersFilter = useCallback(async () => {
    // This is a workaround to avoid the filter to be reset when the user is logging out
    if (!store.getState().domain.publicData.userConfiguration.authenticated) return false

    const nextOptions = produce(
      store.getState().domain.publicData.domain.lists.orderSteps.options,
      draft => {
        draft.filter = [
          {
            field: 'isUnassigned',
            values: [true, false],
            operator: 'or',
          },
          {
            field: 'status',
            values: ['completed', 'notCompleted', 'toDo', 'unassigned', 'undeclared'],
            operator: 'or',
          },
          {
            field: 'violated',
            values: [false, true],
            operator: 'or',
          },
          {
            field: 'expiring',
            values: [false, true],
            operator: 'or',
          },
        ]
      },
    )

    const request = await dispatch(setOrdersFilter(nextOptions))
    return setOrdersFilter.fulfilled.match(request)
  }, [dispatch])

  return {
    orders,
    editing: editingState.editing,
    selection,
    selectedOrders,
    resetOrdersFilter,
  }
}

const getSelectedOrders = (
  selection: workwave.DeepReadonly<string[]>,
  ordersMap: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
): uui.domain.client.rm.ExtendedOrderStep[] => {
  // selection could hold some ids that are not in the ordersMap due to server-data computation
  // ordersMap holds the "truth"
  return selection.reduce<uui.domain.client.rm.ExtendedOrderStep[]>((acc, id) => {
    if (ordersMap[id]) {
      acc.push(ordersMap[id])
    }
    return acc
  }, [])
}
