import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { renderToStaticMarkup } from 'react-dom/server'

import { selectUserConfiguration } from '@/features/domain/user'
import { store } from '@/store'
import { intl } from '@/intl'

import { DateCell, DateTooltip } from '../../../components/cells/DateCell'

export function createDateColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    id: 'dateAsString',
    text: intl.translate({ id: 'rm.scheduler.column.date' }),
    type: 'template',
    field: 'dateAsString',
    width,
    editor: false,
    region: 'cols',
    hidden,

    template: ({ record, field, value }) => {
      const userConfig = selectUserConfiguration(store.getState())

      return renderToStaticMarkup(
        <DateCell
          record={record as ResourceModel}
          field={field}
          value={value}
          userConfig={userConfig}
        />,
      )
    },
    resizable: true,
    groupable: false,
    tooltipRenderer: ({ record }) => {
      const hasViolations = record.getData(
        'hasViolations',
      ) as uui.domain.client.rm.SchedulerResource['hasViolations']

      const violations = record.getData('violations') as uui.domain.rm.RouteViolations

      if (!hasViolations || (violations.vehicle?.length ?? 0) === 0) return ''

      return renderToStaticMarkup(<DateTooltip record={record as ResourceModel} />)
    },
  }
}
