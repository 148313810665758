import type { TrafficTimeWindowsValidation, TrafficTimeWindowsFormValues } from '../types'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { timeWindowUtils } from '@/utils'
import { selectWorkingDayStartSec } from '@/features/domain/territory'

import { useTexts } from './useTexts'

export function useFormValidation() {
  const workingDayStartSec = useSelector(selectWorkingDayStartSec)
  const texts = useTexts()

  return useCallback(
    (formValues: TrafficTimeWindowsFormValues) => {
      const { timeWindow1, timeWindow2, timeWindow1Visible, timeWindow2Visible } = formValues
      const validation: TrafficTimeWindowsValidation = {}

      const normalizedTw1 = timeWindowUtils.normalizeTimeWindow(timeWindow1, workingDayStartSec)
      const normalizedTw2 = timeWindowUtils.normalizeTimeWindow(timeWindow2, workingDayStartSec)

      const isTw1Valid = timeWindowUtils.isTimeWindowValid(normalizedTw1, workingDayStartSec)
      const isTw2Valid = timeWindowUtils.isTimeWindowValid(normalizedTw2, workingDayStartSec)

      if (timeWindow1Visible && timeWindow2Visible) {
        const bothNoEmpty =
          !timeWindowUtils.isTimeWindowEmpty(normalizedTw1) &&
          !timeWindowUtils.isTimeWindowEmpty(normalizedTw2)

        if (bothNoEmpty) {
          if (!isTw1Valid) {
            validation.timeWindow1 = texts.invalidTimeWindow
          }

          if (!isTw2Valid) {
            validation.timeWindow2 = texts.invalidTimeWindow
          }

          if (isTw1Valid && isTw2Valid) {
            if (timeWindowUtils.areTimeWindowsOverlapping(normalizedTw1, normalizedTw2)) {
              validation.timeWindow2 = texts.overlappingTimeWindow
            }
          }
        }
      } else {
        if (!isTw1Valid && timeWindow1Visible) {
          validation.timeWindow1 = texts.invalidTimeWindow
        }

        if (!isTw2Valid && timeWindow2Visible) {
          validation.timeWindow2 = texts.invalidTimeWindow
        }
      }

      return validation
    },
    [workingDayStartSec, texts],
  )
}
