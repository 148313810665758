import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: (count: number) =>
      translate({
        id: 'vehicles.form.exceptions.bulk.header.title',
        values: { count },
      }),
    headerDescription: (vehiclesType: 'gpsOnly' | 'routingOnly' | 'mixed') => {
      switch (vehiclesType) {
        case 'gpsOnly':
          return translate({ id: 'vehicles.form.exceptions.bulk.header.description.gpsOnly' })

        case 'routingOnly':
          return translate({ id: 'vehicles.form.exceptions.bulk.header.description.rmOnly' })

        case 'mixed':
        default:
          return translate({ id: 'vehicles.form.exceptions.bulk.header.description.mixed' })
      }
    },
    deleteTitle: translate({ id: 'vehicles.form.generalSettings.deletePanel.title.bulk' }),
    deleteDescription: translate({ id: 'vehicles.form.generalSettings.deletePanel.description' }),
    deleteConfirm: translate({ id: 'global.deleteAll' }),
    duplicate: translate({ id: 'vehicles.form.exceptions.bulk.header.bulkDuplicate' }),
    delete: translate({ id: 'vehicles.form.exceptions.bulk.header.bulkDelete' }),

    duplicateDisabledTooltipTitle: (addableVehicles: number, isSimulation: boolean) => {
      if (addableVehicles === 0) {
        const id = isSimulation
          ? 'vehicles.form.generalSettings.clonePanel.disabled.simulations.title'
          : 'vehicles.form.generalSettings.clonePanel.disabled.operations.title'
        return translate({ id })
      }

      const id = isSimulation
        ? 'vehicles.form.generalSettings.clonePanel.disabled.simulations.tooMuch.title'
        : 'vehicles.form.generalSettings.clonePanel.disabled.operations.tooMuch.title'

      return translate({ id })
    },

    duplicateDisabledTooltipSubtitle: (
      addableVehicles: number,
      isSimulation: boolean,
      vehiclesLimit: number,
    ) => {
      if (addableVehicles === 0) {
        const id = isSimulation
          ? 'vehicles.form.generalSettings.clonePanel.disabled.simulations.subtitle'
          : 'vehicles.form.generalSettings.clonePanel.disabled.operations.subtitle'
        return translate({ id, values: { count: vehiclesLimit } })
      }

      const id = isSimulation
        ? 'vehicles.form.generalSettings.clonePanel.disabled.simulations.tooMuch.subtitle'
        : 'vehicles.form.generalSettings.clonePanel.disabled.operations.tooMuch.subtitle'
      return translate({ id, values: { addableVehicles, vehiclesLimit } })
    },
  }))

  return api
}
