import type { VoiceEventMarkerAtom } from '../voiceEventMarker'

import { snapshot } from 'valtio'

import { voiceEventMarker } from '../voiceEventMarker'
import { clearVoiceEventMarker } from './clearVoiceEventMarker'

export type SetVoiceEventMarker = (
  prev: workwave.DeepReadonly<VoiceEventMarkerAtom>,
) => VoiceEventMarkerAtom

export type SetVoiceEventMarkerParam = SetVoiceEventMarker | Partial<VoiceEventMarkerAtom> | 'reset'

export function setVoiceEventMarker(valueOrFunc: SetVoiceEventMarkerParam) {
  // reset
  if (valueOrFunc === 'reset') return clearVoiceEventMarker()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(voiceEventMarker, valueOrFunc(snapshot(voiceEventMarker)))
  } else {
    Object.assign(voiceEventMarker, valueOrFunc)
  }

  return snapshot(voiceEventMarker)
}
