import type { MainSelection } from '../typings'

import { snapshot, subscribe } from 'valtio'

import { mainAtom } from '../main'

export const subscribeAllMainSelection = (
  subscriber: (selection: workwave.DeepReadonly<MainSelection>) => void,
) => {
  return subscribe(mainAtom.selection, () => subscriber(snapshot(mainAtom.selection)))
}
