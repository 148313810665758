import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    sms: translate({
      id: 'components.notificationEditor.smsTypeLabel',
    }),

    email: translate({
      id: 'components.notificationEditor.emailButton',
    }),

    bodyLabel: translate({
      id: 'sms4.settings.communication.notifications.form.templateCustom.label',
    }),

    etaRangeLabel: translate({
      id: 'sms4.settings.communication.notifications.form.windowOfCommitmentToleranceMins.label',
    }),

    etaRangeMinutes: (minutes: number) =>
      translate({
        id: 'sms4.settings.communication.notifications.form.windowOfCommitmentToleranceMins.value.minutes',
        values: { minutes },
      }),

    etaRangeHours: (hours: number) =>
      translate({
        id: 'sms4.settings.communication.notifications.form.windowOfCommitmentToleranceMins.value.hours',
        values: { hours },
      }),

    etaRangeHelperText: (etaTime, startTime, endTime) =>
      translate({
        id: 'sms4.settings.communication.notifications.form.windowOfCommitmentToleranceMins.helperText',
        values: { etaTime, startTime, endTime },
      }),

    backupNotificationLabel: translate({
      id: 'sms4.settings.communication.notifications.form.backupNotification.label',
    }),

    backupNotificationHelperText: translate({
      id: 'sms4.settings.communication.notifications.form.backupNotification.helperText',
    }),

    backupSubjectLabel: translate({
      id: 'sms4.settings.communication.notifications.form.backupSubject.label',
    }),

    notificationCutoffMinsLabel: translate({
      id: 'sms4.settings.communication.notifications.form.notificationCutoffMins.label',
    }),

    notificationWayLabel: translate({
      id: 'sms4.settings.communication.notifications.form.notificationWay.label',
    }),

    subjectCustomLabel: translate({
      id: 'sms4.settings.communication.notifications.form.subjectCustom.label',
    }),

    toleranceMinsLabel: translate({
      id: 'sms4.settings.communication.notifications.form.toleranceMins.label',
    }),

    toleranceMinsValue: (minutes?: number) =>
      minutes
        ? translate({
            id: 'sms4.settings.communication.notifications.form.toleranceMins.value',
            values: { minutes },
          })
        : translate({
            id: 'sms4.settings.communication.notifications.form.toleranceMins.label.noTolerance',
          }),

    proximityThresholdMinsLabel: translate({
      id: 'sms4.settings.communication.notifications.form.proximityThresholdMins.label',
    }),

    proximityThresholdMinsValue: (value: number) =>
      translate({
        id: 'global.plural.short.minutes',
        values: { value },
      }),

    trackingLinkLabel: translate({
      id: 'sms4.settings.communication.notifications.form.trackingLink.label',
    }),

    trackingLinkHelperText: translate({
      id: 'sms4.settings.communication.notifications.form.trackingLink.helperText',
    }),

    unsubscribeInstructionsLabel: translate({
      id: 'sms4.settings.communication.notifications.form.unsubscribeInstructions.label',
    }),

    unsubscribeInstructionsHelperText: translate({
      id: 'sms4.settings.communication.notifications.form.unsubscribeInstructions.helperText',
    }),

    unsubscribeInstructionsAlertTitleEmail: translate({
      id: 'settings.customerNotifications.serviceDay.includeUnSubscriptionInstructions.card.title.email',
    }),

    unsubscribeInstructionsAlertTextEmail: translate({
      id: 'settings.customerNotifications.serviceDay.includeUnSubscriptionInstructions.card.text.email',
    }),

    unsubscribeInstructionsAlertTitlePhone: translate({
      id: 'settings.customerNotifications.serviceDay.includeUnSubscriptionInstructions.card.title.sms',
    }),

    unsubscribeInstructionsAlertTextPhone: translate({
      id: 'settings.customerNotifications.serviceDay.includeUnSubscriptionInstructions.card.text.sms',
    }),

    smsMessageLabel: translate({
      id: 'sms4.settings.communication.notifications.form.message.sms.label',
    }),

    emailMessageLabel: translate({
      id: 'sms4.settings.communication.notifications.form.message.email.label',
    }),

    smsEnableConditionMessage:
      'sms4.settings.communication.notifications.form.smsEnableConditionMessage',
  }))

  return api
}
