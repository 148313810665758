export const isOrderAssigned = (
  selectedExtOrder: uui.domain.client.rm.ExtendedOrderStep,
  routes: Record<string, uui.domain.client.rm.ExtendedRoute>,
) => {
  for (const key in routes) {
    if (routes[key].steps.some(s => s.steps.some(ss => ss.orderStepId === selectedExtOrder.id)))
      return true
  }

  return false
}
