import { Component } from 'react'

import { FooterText } from '@/forms-legacy'

import { Props, FooterErrorItem } from './typings'

export default class FooterErrors extends Component<Props> {
  render() {
    const { errors } = this.props

    if (!errors) return null

    return errors.map((error: string | FooterErrorItem, index: number) =>
      typeof error === 'string' ? (
        <FooterText key={error}>- {error}</FooterText>
      ) : (
        <FooterText
          key={`footer-error-${error.text.split(' ').join('_')}_${index}`}
          bold={error.bold}
          indentLevel={error.indentLevel}
          groupHeader={error.groupHeader}
        >
          {error.groupHeader ? error.text : `- ${error.text}`}
        </FooterText>
      ),
    )
  }
}
