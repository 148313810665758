import type { Coordinate } from 'ol/coordinate'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { getLocationPinStyle } from './getLocationPinStyle'

export function createLocationEditorPinLayer(
  coord: Coordinate,
  pinType: uui.domain.ui.map.LocationPinType,
) {
  // Create a new vector source and layer
  const source = new VectorSource()
  const layer = new VectorLayer({ source })

  // Create a new point feature at the specified coordinate
  const feature = new Feature({
    geometry: new Point(coord),
  })

  feature.set('symbol', pinType)
  feature.set('status', 'normal')
  feature.set('originalCoord', `${coord[0]}_${coord[1]}`)

  // Set the style of the feature to the style returned by getPinStyle(pinSymbol, 'normal')
  feature.setStyle(getLocationPinStyle)

  // Add the feature to the source
  layer.getSource()?.addFeature(feature)

  // return the source, layer, and feature
  return { source, layer, feature }
}
