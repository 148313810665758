import type { GeofenceFormValues } from '../types'

import { isSameDay, parseISO } from 'date-fns'

import { convertMilesToKm } from '@/local/server-data/utils/smartUtils/conversion'
import { parseNotificationAddress } from '@/utils'
import { isFakeLocation } from '@/server-data'

import { neverEndingDateTime } from '../../../../../constants'

export function computeFormInitialValues(
  geofence: uui.domain.client.gps.wwgps.Geofence,
  distanceFormat: 'METRIC' | 'IMPERIAL',
): GeofenceFormValues {
  if (isFakeLocation(geofence.location)) {
    throw new Error('Cannot edit a geofence with a fake location')
  }

  const maxSpeedEnabled = geofence.maxSpeed ? 'enabled' : 'disabled'
  const maxSpeedValue = geofence.maxSpeed ?? 65
  const isMetric = distanceFormat === 'METRIC'

  const defaultDate = new Date()
  defaultDate.setHours(0, 0, 0, 0)

  return {
    id: geofence.id,
    maxSpeedEnabled,
    name: geofence.name,
    type: geofence.type,
    userId: geofence.userId,
    radius: geofence.radius,
    repeat: geofence.repeat,
    location: geofence.location,
    latitude: geofence.latitude,
    timezone: geofence.timezone,
    deviceIds: geofence.deviceIds,
    longitude: geofence.longitude,
    //defaultEmailAddress: accountEmails[0],
    organizationId: geofence.organizationId,
    notificationAddresses: geofence.notificationAddresses.map(parseNotificationAddress),

    maxSpeed: isMetric ? parseInt(convertMilesToKm(maxSpeedValue).toFixed()) : maxSpeedValue,

    startDateTime: geofence.startTime.length === 0 ? defaultDate : parseISO(geofence.startTime),
    endDateTime:
      geofence.endTime.length === 0 ||
      isSameDay(parseISO(geofence.endTime), parseISO(neverEndingDateTime))
        ? null
        : parseISO(geofence.endTime),
  }
}
