import { useCallback } from 'react'

type Props = {
  item: uui.domain.client.rm.RouteManagerUser
  onItemClick: ({ event, item }) => void
  onItemDoubleClick: (item: uui.domain.client.rm.RouteManagerUser) => void
}

export function useActions(props: Props) {
  const { item, onItemClick, onItemDoubleClick } = props

  const handleClickOnUser = useCallback(
    event => {
      onItemClick({ event, item })
    },
    [item, onItemClick],
  )

  const handleDoubleClickOnUser = useCallback(() => {
    onItemDoubleClick(item)
  }, [item, onItemDoubleClick])

  return {
    handleClickOnUser,
    handleDoubleClickOnUser,
  }
}
