import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import {
  selectTerritoryId,
  updateVoiceConfig as updateVoiceConfigAction,
} from '@/features/domain/territory'

import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useUpdateVoiceConfig() {
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const territoryId = useSelector(selectTerritoryId)

  const updateVoiceConfig = useCallback(
    async (value: uui.domain.client.rm.VoiceConfig) => {
      try {
        const result = await dispatch(updateVoiceConfigAction({ voiceConfig: value, territoryId }))

        if (updateVoiceConfigAction.rejected.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        //toast.success('SUCCESS')
      } catch (e) {
        toast.error('ERROR') // @VOICE Replace it with correct localized messages
      }
    },
    [dispatch, toast, territoryId],
  )

  return [updateVoiceConfig] as const
}
