import { intl } from '@/intl'

export const getTexts = () => {
  const { translate } = intl

  // try to get the real texts, if @intl is not ready it'll throws
  try {
    return {
      dragThePin: translate({ id: 'lib.locationEditor.dragThePin' }),
    }
  } catch (e) {
    // fallback to placeholder values
    return {
      dragThePin: 'Drag the pin to change the position',
    }
  }
}
