export function SpreadSheet() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 293 171">
      <g clipPath="url(#a)" filter="url(#b)">
        <rect x="1" y="1.54492" width="291" height="168" rx="4" fill="#FAFAFA" />
        <g clipPath="url(#c)">
          <path opacity=".3" d="M17 28.5454c0-.5523.4477-1 1-1h72v12H17v-11Z" fill="#C3C3C3" />
          <rect
            opacity=".3"
            x="23.1514"
            y="32.4531"
            width="61.2489"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#d)">
          <path opacity=".3" fill="#C3C3C3" d="M17 41.5444h73v12H17z" />
          <rect
            opacity=".3"
            x="23.1514"
            y="46.2871"
            width="61.2489"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#e)">
          <path opacity=".3" fill="#C3C3C3" d="M17 55.5439h73v12H17z" />
          <rect
            opacity=".3"
            x="23.1514"
            y="60.1143"
            width="61.2489"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#f)">
          <path opacity=".3" fill="#C3C3C3" d="M17 69.543h73v12H17z" />
          <rect
            opacity=".3"
            x="23.1514"
            y="73.9404"
            width="61.2489"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#g)">
          <path opacity=".3" fill="#C3C3C3" d="M17 82.5425h73v13H17z" />
          <rect
            opacity=".3"
            x="23.1514"
            y="87.7598"
            width="61.2489"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#h)">
          <path opacity=".3" fill="#C3C3C3" d="M17 96.5415h73v13H17z" />
          <rect
            opacity=".3"
            x="23.1514"
            y="101.602"
            width="61.2489"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#i)">
          <path opacity=".3" fill="#C3C3C3" d="M17 110.549h73v12H17z" />
          <rect
            opacity=".3"
            x="23.1514"
            y="115.428"
            width="61.2489"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#j)">
          <path opacity=".3" fill="#C3C3C3" d="M17 124.548h73v12H17z" />
          <rect
            opacity=".3"
            x="23.1514"
            y="129.27"
            width="61.2489"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#k)">
          <path opacity=".3" d="M17 138.547h73v12H18c-.5523 0-1-.448-1-1v-11Z" fill="#C3C3C3" />
          <rect
            opacity=".3"
            x="23.1514"
            y="143.104"
            width="61.2489"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#l)">
          <path opacity=".3" fill="#C3C3C3" d="M91.9995 27.5454h41v12h-41z" />
          <rect
            opacity=".3"
            x="97.8345"
            y="32.4531"
            width="29.2414"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#m)">
          <path opacity=".3" fill="#EAEAEA" d="M91.9995 41.5444h41v12h-41z" />
          <rect
            opacity=".3"
            x="97.8345"
            y="46.2871"
            width="29.2414"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#n)">
          <path opacity=".3" fill="#EAEAEA" d="M91.9995 55.5439h41v12h-41z" />
          <rect
            opacity=".3"
            x="97.8345"
            y="60.1143"
            width="29.2414"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#o)">
          <path opacity=".3" fill="#EAEAEA" d="M91.9995 69.543h41v12h-41z" />
          <rect
            opacity=".3"
            x="97.8345"
            y="73.9404"
            width="29.2414"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#p)">
          <path opacity=".3" fill="#EAEAEA" d="M91.9995 82.5425h41v13h-41z" />
          <rect
            opacity=".3"
            x="97.8345"
            y="87.7598"
            width="29.2414"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#q)">
          <path opacity=".3" fill="#EAEAEA" d="M91.9995 96.5415h41v13h-41z" />
          <rect
            opacity=".3"
            x="97.8345"
            y="101.602"
            width="29.2414"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#r)">
          <path opacity=".3" fill="#EAEAEA" d="M91.9995 110.549h41v12h-41z" />
          <rect
            opacity=".3"
            x="97.8345"
            y="115.428"
            width="29.2414"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#s)">
          <path opacity=".3" fill="#EAEAEA" d="M91.9995 124.548h41v12h-41z" />
          <rect
            opacity=".3"
            x="97.8345"
            y="129.27"
            width="29.2414"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#t)">
          <path opacity=".3" fill="#EAEAEA" d="M91.9995 138.547h41v12h-41z" />
          <rect
            opacity=".3"
            x="97.8345"
            y="143.104"
            width="29.2414"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#u)">
          <path opacity=".3" fill="#C3C3C3" d="M135 27.5454h26v12h-26z" />
          <rect
            opacity=".3"
            x="140.514"
            y="32.4531"
            width="15.0159"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#v)">
          <path opacity=".3" fill="#EAEAEA" d="M135 41.5444h26v12h-26z" />
          <rect
            opacity=".3"
            x="140.514"
            y="46.2871"
            width="15.0159"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#w)">
          <path opacity=".3" fill="#EAEAEA" d="M135 55.5439h26v12h-26z" />
          <rect
            opacity=".3"
            x="140.514"
            y="60.1143"
            width="15.0159"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#x)">
          <path opacity=".3" fill="#EAEAEA" d="M135 69.543h26v12h-26z" />
          <rect
            opacity=".3"
            x="140.514"
            y="73.9404"
            width="15.0159"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#y)">
          <path opacity=".3" fill="#EAEAEA" d="M135 82.5425h26v13h-26z" />
          <rect
            opacity=".3"
            x="140.514"
            y="87.7598"
            width="15.0159"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#z)">
          <path opacity=".3" fill="#EAEAEA" d="M135 96.5415h26v13h-26z" />
          <rect
            opacity=".3"
            x="140.514"
            y="101.602"
            width="15.0159"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#A)">
          <path opacity=".3" fill="#EAEAEA" d="M135 110.549h26v12h-26z" />
          <rect
            opacity=".3"
            x="140.514"
            y="115.428"
            width="15.0159"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#B)">
          <path opacity=".3" fill="#EAEAEA" d="M135 124.548h26v12h-26z" />
          <rect
            opacity=".3"
            x="140.514"
            y="129.27"
            width="15.0159"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#C)">
          <path opacity=".3" fill="#EAEAEA" d="M135 138.547h26v12h-26z" />
          <rect
            opacity=".3"
            x="140.514"
            y="143.104"
            width="15.0159"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#D)">
          <path opacity=".3" fill="#C3C3C3" d="M195.001 27.5454h33v12h-33z" />
          <rect
            opacity=".3"
            x="200.575"
            y="32.4531"
            width="21.7335"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#E)">
          <path opacity=".3" fill="#EAEAEA" d="M195.001 41.5444h33v12h-33z" />
          <rect
            opacity=".3"
            x="200.575"
            y="46.2871"
            width="21.7335"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#F)">
          <path opacity=".3" fill="#EAEAEA" d="M195.001 55.5439h33v12h-33z" />
          <rect
            opacity=".3"
            x="200.575"
            y="60.1143"
            width="21.7335"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#G)">
          <path opacity=".3" fill="#EAEAEA" d="M195.001 69.543h33v12h-33z" />
          <rect
            opacity=".3"
            x="200.575"
            y="73.9404"
            width="21.7335"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#H)">
          <path opacity=".3" fill="#EAEAEA" d="M195.001 82.5425h33v13h-33z" />
          <rect
            opacity=".3"
            x="200.575"
            y="87.7598"
            width="21.7335"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#I)">
          <path opacity=".3" fill="#EAEAEA" d="M195.001 96.5415h33v13h-33z" />
          <rect
            opacity=".3"
            x="200.575"
            y="101.602"
            width="21.7335"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#J)">
          <path opacity=".3" fill="#EAEAEA" d="M195.001 110.549h33v12h-33z" />
          <rect
            opacity=".3"
            x="200.575"
            y="115.428"
            width="21.7335"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#K)">
          <path opacity=".3" fill="#EAEAEA" d="M195.001 124.548h33v12h-33z" />
          <rect
            opacity=".3"
            x="200.575"
            y="129.27"
            width="21.7335"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#L)">
          <path opacity=".3" fill="#EAEAEA" d="M195.001 138.547h33v12h-33z" />
          <rect
            opacity=".3"
            x="200.575"
            y="143.104"
            width="21.7335"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#M)">
          <path opacity=".3" fill="#C3C3C3" d="M163 27.5454h30v12h-30z" />
          <rect
            opacity=".3"
            x="168.965"
            y="32.4531"
            width="18.1771"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#N)">
          <path opacity=".3" fill="#EAEAEA" d="M163 41.5444h30v12h-30z" />
          <rect
            opacity=".3"
            x="168.965"
            y="46.2871"
            width="18.1771"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#O)">
          <path opacity=".3" fill="#EAEAEA" d="M163 55.5439h30v12h-30z" />
          <rect
            opacity=".3"
            x="168.965"
            y="60.1143"
            width="18.1771"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#P)">
          <path opacity=".3" fill="#EAEAEA" d="M163 69.543h30v12h-30z" />
          <rect
            opacity=".3"
            x="168.965"
            y="73.9404"
            width="18.1771"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#Q)">
          <path opacity=".3" fill="#EAEAEA" d="M163 82.5425h30v13h-30z" />
          <rect
            opacity=".3"
            x="168.965"
            y="87.7598"
            width="18.1771"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#R)">
          <path opacity=".3" fill="#EAEAEA" d="M163 96.5415h30v13h-30z" />
          <rect
            opacity=".3"
            x="168.965"
            y="101.602"
            width="18.1771"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#S)">
          <path opacity=".3" fill="#EAEAEA" d="M163 110.549h30v12h-30z" />
          <rect
            opacity=".3"
            x="168.965"
            y="115.428"
            width="18.1771"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#T)">
          <path opacity=".3" fill="#EAEAEA" d="M163 124.548h30v12h-30z" />
          <rect
            opacity=".3"
            x="168.965"
            y="129.27"
            width="18.1771"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#U)">
          <path opacity=".3" fill="#EAEAEA" d="M163 138.547h30v12h-30z" />
          <rect
            opacity=".3"
            x="168.965"
            y="143.104"
            width="18.1771"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#V)">
          <path opacity=".3" d="M230 27.5454h46c.552 0 1 .4477 1 1v11h-47v-12Z" fill="#C3C3C3" />
          <rect
            opacity=".3"
            x="235.749"
            y="32.4531"
            width="35.1687"
            height="2.76608"
            rx="1.38304"
            fill="#606E85"
          />
        </g>
        <g clipPath="url(#W)">
          <path opacity=".3" d="M230 41.5444h47v12h-47v-12Z" fill="#EAEAEA" />
          <rect
            opacity=".3"
            x="235.749"
            y="46.2871"
            width="35.1687"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#X)">
          <path opacity=".3" d="M230 55.5439h47v12h-47v-12Z" fill="#EAEAEA" />
          <rect
            opacity=".3"
            x="235.749"
            y="60.1143"
            width="35.1687"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#Y)">
          <path opacity=".3" d="M230 69.543h47v12h-47v-12Z" fill="#EAEAEA" />
          <rect
            opacity=".3"
            x="235.749"
            y="73.9404"
            width="35.1687"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#Z)">
          <path opacity=".3" d="M230 82.5425h47v13h-47v-13Z" fill="#EAEAEA" />
          <rect
            opacity=".3"
            x="235.749"
            y="87.7598"
            width="35.1687"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#aa)">
          <path opacity=".3" d="M230 96.5415h47v13.0005h-47V96.5415Z" fill="#EAEAEA" />
          <rect
            opacity=".3"
            x="235.749"
            y="101.602"
            width="35.1687"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#ab)">
          <path opacity=".3" d="M230 110.549h47v12h-47v-12Z" fill="#EAEAEA" />
          <rect
            opacity=".3"
            x="235.749"
            y="115.428"
            width="35.1687"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#ac)">
          <path opacity=".3" d="M230 124.548h47v12h-47v-12Z" fill="#EAEAEA" />
          <rect
            opacity=".3"
            x="235.749"
            y="129.27"
            width="35.1687"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
        <g clipPath="url(#ad)">
          <path opacity=".3" d="M230 138.547h47v11c0 .552-.448 1-1 1h-46v-12Z" fill="#EAEAEA" />
          <rect
            opacity=".3"
            x="235.749"
            y="143.104"
            width="35.1687"
            height="2.76608"
            rx="1.38304"
            fill="#979797"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 1.04492h292v169H.5z" />
        </clipPath>
        <clipPath id="c">
          <path fill="#fff" d="M17.2266 27.7095h73.1035v12.2498H17.2266z" />
        </clipPath>
        <clipPath id="d">
          <path fill="#fff" d="M17.2266 41.5444h73.1035v12.2498H17.2266z" />
        </clipPath>
        <clipPath id="e">
          <path fill="#fff" d="M17.2266 55.3643h73.1035v12.2498H17.2266z" />
        </clipPath>
        <clipPath id="f">
          <path fill="#fff" d="M17.2266 69.1992h73.1035V81.449H17.2266z" />
        </clipPath>
        <clipPath id="g">
          <path fill="#fff" d="M17.2266 83.0269h73.1035v12.2498H17.2266z" />
        </clipPath>
        <clipPath id="h">
          <path fill="#fff" d="M17.2266 96.8696h73.1035v12.2498H17.2266z" />
        </clipPath>
        <clipPath id="i">
          <path fill="#fff" d="M17.2266 110.682h73.1035v12.2498H17.2266z" />
        </clipPath>
        <clipPath id="j">
          <path fill="#fff" d="M17.2266 124.524h73.1035v12.2498H17.2266z" />
        </clipPath>
        <clipPath id="k">
          <path fill="#fff" d="M17.2266 138.352h73.1035v12.2498H17.2266z" />
        </clipPath>
        <clipPath id="l">
          <path fill="#fff" d="M91.9136 27.7095h41.096v12.2498h-41.096z" />
        </clipPath>
        <clipPath id="m">
          <path fill="#fff" d="M91.9136 41.5444h41.096v12.2498h-41.096z" />
        </clipPath>
        <clipPath id="n">
          <path fill="#fff" d="M91.9136 55.3643h41.096v12.2498h-41.096z" />
        </clipPath>
        <clipPath id="o">
          <path fill="#fff" d="M91.9136 69.1992h41.096V81.449h-41.096z" />
        </clipPath>
        <clipPath id="p">
          <path fill="#fff" d="M91.9136 83.0269h41.096v12.2498h-41.096z" />
        </clipPath>
        <clipPath id="q">
          <path fill="#fff" d="M91.9136 96.8696h41.096v12.2498h-41.096z" />
        </clipPath>
        <clipPath id="r">
          <path fill="#fff" d="M91.9136 110.682h41.096v12.2498h-41.096z" />
        </clipPath>
        <clipPath id="s">
          <path fill="#fff" d="M91.9136 124.524h41.096v12.2498h-41.096z" />
        </clipPath>
        <clipPath id="t">
          <path fill="#fff" d="M91.9136 138.352h41.096v12.2498h-41.096z" />
        </clipPath>
        <clipPath id="u">
          <path fill="#fff" d="M134.585 27.7095h26.8705v12.2498H134.585z" />
        </clipPath>
        <clipPath id="v">
          <path fill="#fff" d="M134.585 41.5444h26.8705v12.2498H134.585z" />
        </clipPath>
        <clipPath id="w">
          <path fill="#fff" d="M134.585 55.3643h26.8705v12.2498H134.585z" />
        </clipPath>
        <clipPath id="x">
          <path fill="#fff" d="M134.585 69.1992h26.8705V81.449H134.585z" />
        </clipPath>
        <clipPath id="y">
          <path fill="#fff" d="M134.585 83.0269h26.8705v12.2498H134.585z" />
        </clipPath>
        <clipPath id="z">
          <path fill="#fff" d="M134.585 96.8696h26.8705v12.2498H134.585z" />
        </clipPath>
        <clipPath id="A">
          <path fill="#fff" d="M134.585 110.682h26.8705v12.2498H134.585z" />
        </clipPath>
        <clipPath id="B">
          <path fill="#fff" d="M134.585 124.524h26.8705v12.2498H134.585z" />
        </clipPath>
        <clipPath id="C">
          <path fill="#fff" d="M134.585 138.352h26.8705v12.2498H134.585z" />
        </clipPath>
        <clipPath id="D">
          <path fill="#fff" d="M194.649 27.7095h33.5881v12.2498H194.649z" />
        </clipPath>
        <clipPath id="E">
          <path fill="#fff" d="M194.649 41.5444h33.5881v12.2498H194.649z" />
        </clipPath>
        <clipPath id="F">
          <path fill="#fff" d="M194.649 55.3643h33.5881v12.2498H194.649z" />
        </clipPath>
        <clipPath id="G">
          <path fill="#fff" d="M194.649 69.1992h33.5881V81.449H194.649z" />
        </clipPath>
        <clipPath id="H">
          <path fill="#fff" d="M194.649 83.0269h33.5881v12.2498H194.649z" />
        </clipPath>
        <clipPath id="I">
          <path fill="#fff" d="M194.649 96.8696h33.5881v12.2498H194.649z" />
        </clipPath>
        <clipPath id="J">
          <path fill="#fff" d="M194.649 110.682h33.5881v12.2498H194.649z" />
        </clipPath>
        <clipPath id="K">
          <path fill="#fff" d="M194.649 124.524h33.5881v12.2498H194.649z" />
        </clipPath>
        <clipPath id="L">
          <path fill="#fff" d="M194.649 138.352h33.5881v12.2498H194.649z" />
        </clipPath>
        <clipPath id="M">
          <path fill="#fff" d="M163.04 27.7095h30.0317v12.2498H163.04z" />
        </clipPath>
        <clipPath id="N">
          <path fill="#fff" d="M163.04 41.5444h30.0317v12.2498H163.04z" />
        </clipPath>
        <clipPath id="O">
          <path fill="#fff" d="M163.04 55.3643h30.0317v12.2498H163.04z" />
        </clipPath>
        <clipPath id="P">
          <path fill="#fff" d="M163.04 69.1992h30.0317V81.449H163.04z" />
        </clipPath>
        <clipPath id="Q">
          <path fill="#fff" d="M163.04 83.0269h30.0317v12.2498H163.04z" />
        </clipPath>
        <clipPath id="R">
          <path fill="#fff" d="M163.04 96.8696h30.0317v12.2498H163.04z" />
        </clipPath>
        <clipPath id="S">
          <path fill="#fff" d="M163.04 110.682h30.0317v12.2498H163.04z" />
        </clipPath>
        <clipPath id="T">
          <path fill="#fff" d="M163.04 124.524h30.0317v12.2498H163.04z" />
        </clipPath>
        <clipPath id="U">
          <path fill="#fff" d="M163.04 138.352h30.0317v12.2498H163.04z" />
        </clipPath>
        <clipPath id="V">
          <path fill="#fff" d="M229.82 27.7095h47.0234v12.2498H229.82z" />
        </clipPath>
        <clipPath id="W">
          <path fill="#fff" d="M229.82 41.5444h47.0234v12.2498H229.82z" />
        </clipPath>
        <clipPath id="X">
          <path fill="#fff" d="M229.82 55.3643h47.0234v12.2498H229.82z" />
        </clipPath>
        <clipPath id="Y">
          <path fill="#fff" d="M229.82 69.1992h47.0234V81.449H229.82z" />
        </clipPath>
        <clipPath id="Z">
          <path fill="#fff" d="M229.82 83.0269h47.0234v12.2498H229.82z" />
        </clipPath>
        <clipPath id="aa">
          <path fill="#fff" d="M229.82 96.8696h47.0234v12.2498H229.82z" />
        </clipPath>
        <clipPath id="ab">
          <path fill="#fff" d="M229.82 110.682h47.0234v12.2498H229.82z" />
        </clipPath>
        <clipPath id="ac">
          <path fill="#fff" d="M229.82 124.524h47.0234v12.2498H229.82z" />
        </clipPath>
        <clipPath id="ad">
          <path fill="#fff" d="M229.82 138.352h47.0234v12.2498H229.82z" />
        </clipPath>
        <filter
          id="b"
          x="-.319857"
          y=".225065"
          width="293.64"
          height="170.64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius=".819857"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_2779_28856"
          />
          <feOffset />
          <feColorMatrix values="0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2779_28856" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_2779_28856" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
