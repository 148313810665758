import { StructuredVirtualList } from '@/components/StructuredVirtualList'
import { Grow, FormColumn, ComponentSize } from '@/components/layout'
import { ListFooter, LayoutComposer, NoEntries, NoResults } from '@/components/List'
import { useAutoScroll } from '@/atoms'

import { useTexts } from './hooks/useTexts'
import { useDataForList } from './hooks/useDataForList'
import { useListHandlers } from './hooks/useListHandlers'
import { Header } from './components/Header'
import { Item } from './components/Item'

const getItemHeight = () => 79

interface Props {
  deviceEvents: Record<string, uui.domain.client.gps.wwgps.EventInfo>
}

export function EventsList(props: Props) {
  const { deviceEvents } = props
  const { handleOnSelect, handleOnSelectAll } = useListHandlers()
  const { structure, lastSelectedEvent, selection } = useDataForList()
  const texts = useTexts()

  useAutoScroll('eventInfos', lastSelectedEvent)

  const allSelected = selection.length >= structure.shownItems
  const multiSelect = selection.length > 1

  return (
    <FormColumn width={320} testid="form__column-primary">
      <Header />

      <Grow yAxis xAxis position="relative">
        <LayoutComposer
          totalItems={structure.totalItems}
          shownItems={structure.shownItems}
          noResults={
            <NoResults title={texts.noResults.title} description={texts.noResults.description} />
          }
          noEntries={
            <NoEntries title={texts.noEntries.title} description={texts.noEntries.description} />
          }
          list={
            <ComponentSize
              render={size => {
                return (
                  <>
                    <StructuredVirtualList
                      itemsById={deviceEvents}
                      structure={structure}
                      listApiCategory="eventInfos"
                      getItemHeights={getItemHeight}
                      RenderItem={Item}
                      listHeight={size.height}
                      selectedItemIds={selection}
                      onSelect={handleOnSelect}
                      buffer={50}
                    />
                  </>
                )
              }}
            />
          }
        />
      </Grow>

      {multiSelect && (
        <ListFooter
          label={texts.selectBtn(allSelected)}
          onToggleSelection={handleOnSelectAll}
          checked={allSelected}
        />
      )}
    </FormColumn>
  )
}
