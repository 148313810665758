import styled from 'styled-components'
import TimeWindow from './TimeWindow'

const COLUMN_GUTTER = 6

const styledComponent = styled(TimeWindow)`
  position: relative;
  width: 100%;

  .o-time-window__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .o-time-window__row + .o-time-window__row {
    margin-top: ${COLUMN_GUTTER}px;
  }

  .o-time-window__col {
    flex: 0 0 auto;
    width: 100%;
  }

  .o-time-window__col--50 {
    width: 48.5%;
  }
`
export default styledComponent
