import type { Hotword, HotwordId } from '../../types'

import { useRef } from 'react'

import { Button, Menu, MenuItem, Stack } from '@mui/material'

import { useTexts } from '../../hooks/useTexts'

import { useActions } from './hooks/useActions'
import { useMenuPaperProps } from './hooks/useMenuPaperProps'

import { Icon } from './components/Icon'

interface Props {
  hotwords: Hotword[]
  disabled: boolean
  addHotword: (id: HotwordId) => void
}

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
} as const

const anchorPosition = {
  left: 0,
  top: -8,
} as const

export function HotwordsMenu(props: Props) {
  const { hotwords, addHotword, disabled } = props
  const ref = useRef<HTMLButtonElement | null>(null)
  const texts = useTexts()
  const paperProps = useMenuPaperProps(ref)

  const { onSetHotword, onOpen, onClose, open } = useActions(addHotword)

  return (
    <Stack direction="row" justifyContent="flex-start">
      <Button
        disabled={disabled}
        endIcon={<Icon />}
        variant="outlined"
        onClick={onOpen}
        color="primary"
        ref={ref}
      >
        {texts.addHotword}
      </Button>

      <Menu
        anchorEl={ref.current}
        open={open}
        onClose={onClose}
        PaperProps={paperProps}
        anchorOrigin={anchorOrigin}
        anchorPosition={anchorPosition}
      >
        {hotwords.map(word => {
          return (
            <MenuItem
              onClick={onSetHotword}
              data-hotword={word.id}
              disabled={disabled}
              key={word.id}
            >
              {word.label}
            </MenuItem>
          )
        })}
      </Menu>
    </Stack>
  )
}
