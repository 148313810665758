import styled from 'styled-components'

const ReadonlyAudioTextContainer = styled.div`
  display: flex;
  /* height: 48px; */
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  padding: 0;
  color: ${p => p.theme.colors.$nightRider};
  overflow: hidden;

  & > * {
    margin: 0;
    margin-top: 4px;
    display: block;
    width: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & > * + * {
    margin-left: 16px;
  }
`

ReadonlyAudioTextContainer.displayName = 'ReadonlyAudioTextContainer'
export default ReadonlyAudioTextContainer
