import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function HideVehicle(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 21 22">
        <path
          d="M3.37 18.127a.5.5 0 1 1 .708-.708 8.932 8.932 0 0 0 2.301 1.667.5.5 0 1 1-.45.893 9.932 9.932 0 0 1-2.559-1.852zm5.57 2.806a.5.5 0 0 1 .147-.99c.942.14 1.903.131 2.844-.027a.5.5 0 0 1 .166.986c-1.045.176-2.111.186-3.157.03zm6.153-1.038a.5.5 0 1 1-.464-.886 9.117 9.117 0 0 0 2.2-1.614l.103-.103a.5.5 0 1 1 .715.699l-.111.113a10.12 10.12 0 0 1-2.443 1.791zm4.382-4.48a.5.5 0 0 1-.896-.443 9.062 9.062 0 0 0 .846-2.715.5.5 0 1 1 .99.145 10.062 10.062 0 0 1-.94 3.014zm1.052-6.103a.5.5 0 0 1-1 .005 72.78 72.78 0 0 0-.07-2.978.5.5 0 0 1 .999-.047c.044.944.066 1.969.071 3.02zm-.363-6.075a.5.5 0 0 1-.984.18c-.087-.476-.193-.786-.25-.844-.102-.102-.648-.249-1.471-.353a.5.5 0 0 1 .126-.992c1.064.135 1.727.314 2.055.642.249.253.4.697.524 1.367zm-5.618-2.223a.5.5 0 0 1-.034 1c-.97-.033-1.97-.045-2.981-.042a.5.5 0 1 1-.002-1c1.022-.003 2.034.01 3.017.042zm-6.109.187a.5.5 0 0 1 .21.978c-.93.2-1.825.544-2.652 1.024a.5.5 0 0 1-.503-.864A10.048 10.048 0 0 1 8.437 1.2zM3.03 4.347a.5.5 0 1 1 .745.667A9.076 9.076 0 0 0 2.23 7.402a.5.5 0 1 1-.914-.405 10.076 10.076 0 0 1 1.714-2.65zM.506 10.056a.5.5 0 1 1 .995.1 9.072 9.072 0 0 0 .16 2.84.5.5 0 1 1-.978.212 10.072 10.072 0 0 1-.177-3.152zm1.33 6.093a.5.5 0 0 1 .86-.509c.375.635.83 1.224 1.36 1.757a.5.5 0 0 1-.71.705 9.954 9.954 0 0 1-1.51-1.953z"
          fill="rgb(151, 151, 151)"
        ></path>
        <path
          d="M6.744 15.218c2.2 2.2 5.773 2.194 7.98-.014a5.644 5.644 0 0 0 1.66-3.932c.003-.268.066-4.734-.447-5.247-.513-.513-4.884-.445-5.142-.447a5.644 5.644 0 0 0-4.037 1.66c-2.208 2.207-2.214 5.78-.014 7.98z"
          fill="rgb(219, 219, 219)"
        ></path>
      </svg>
    </IconContainer>
  )
}
