import { useMemo } from 'react'

import { colorPalette } from '@/server-data'
import { ColorPickerField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

export function Color() {
  const texts = useTexts()

  const wrappedInputProps = useMemo(
    () => ({
      openText: texts.globalCancel,
      closedText: texts.globalEdit,
      colors: colorPalette,
    }),
    [texts],
  )

  return (
    <ColorPickerField
      name="gps.color.color"
      label={texts.color}
      info={texts.colorInfo}
      testid="deviceColor"
      wrappedInputProps={wrappedInputProps}
    />
  )
}
