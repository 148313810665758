import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type Payload = {
  date: string
  vehicleId: string
}

/**
 * Toggle lock for the given vehicle.
 *
 * @private
 */
export const toggleVehicleLock = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/vehicle/lock/toggle/single', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const { date, vehicleId } = payload

    const result = await rpc('rpc/vehicle/toggleVehicleLock', {
      category: 'rpc',
      type: 'rpc/vehicle/toggleVehicleLock',
      date,
      vehicleId,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {toggleVehicleLock}`,
      { tags: ['rpc', 'vehicle'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Toggling Vehicle Lock for ${payload.vehicleId} Failed`,
      error,
    })
  }
})
