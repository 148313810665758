import type { ConnectedListApi, ConnectedListScrollTo } from '../mapSelection'

import { ref } from 'valtio'
import { mapSelectionAtom } from '../mapSelection'

export function setConnectedList(type: 'idle'): void
export function setConnectedList(
  type: 'connected',
  category: uui.domain.ui.list.Category,
  scrollTo: ConnectedListScrollTo,
  disabled?: boolean,
): void
export function setConnectedList(
  status: ConnectedListApi['status'],
  category?: uui.domain.ui.list.Category,
  scrollTo?: ConnectedListScrollTo,
  disabled?: boolean,
): void {
  if (
    mapSelectionAtom.connectedList.status === 'idle' &&
    mapSelectionAtom.connectedList.status === status
  ) {
    return
  }

  if (status === 'connected') {
    mapSelectionAtom.connectedList.status = 'connected'

    if (mapSelectionAtom.connectedList.status === 'connected') {
      if (category) mapSelectionAtom.connectedList.category = category
      if (scrollTo) mapSelectionAtom.connectedList.scrollTo = ref(scrollTo)
      mapSelectionAtom.connectedList.disabled = !!disabled
    }
  } else {
    delete (mapSelectionAtom.connectedList as any).category
    delete (mapSelectionAtom.connectedList as any).scrollTo
    delete (mapSelectionAtom.connectedList as any).disabled
    mapSelectionAtom.connectedList.status = 'idle'
  }
}
