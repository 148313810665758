import type { FocusEvent } from 'react'
import type { State, UpdateAction } from '../useTimePicker'
import type { TimeFormat } from '../../types'

import { useCallback, useEffect, useRef } from 'react'

import { useValidateOn } from '../../../../hooks/useValidateOn'
import { convertTimeInput, formatTime } from '../../utils'

interface Params {
  updateComponentState: React.Dispatch<UpdateAction>
  validations: ReturnType<typeof useValidateOn>
  allowUnlistedValues: boolean
  timeFormat: TimeFormat
  componentState: State
  options: number[]
}

export function useFocusActions(params: Params) {
  const {
    updateComponentState,
    allowUnlistedValues,
    componentState,
    validations,
    timeFormat,
    options,
  } = params

  const { validateOnBlur, validateOnFocus } = validations

  const componentStateRef = useRef(componentState)
  useEffect(() => {
    componentStateRef.current = componentState
  }, [componentState])

  // Called on blur of the TextInput
  const onBlur = useCallback(() => {
    const { inputValue, timeValue } = componentStateRef.current

    const convertedTime = convertTimeInput(inputValue, timeFormat)

    // If the time value is invalid OR allowUnlistedValues=false AND the value is not in the options
    // let's revert the input to the last valid value
    if (convertedTime === 'invalid' || (!allowUnlistedValues && !options.includes(convertedTime))) {
      updateComponentState({
        selectValue: timeValue,
        inputValue: formatTime(timeValue, timeFormat),
      })
    } else {
      updateComponentState({
        timeValue: convertedTime,
        selectValue: convertedTime,
        inputValue: formatTime(timeValue, timeFormat),
      })
    }

    validateOnBlur()
  }, [
    allowUnlistedValues,
    options,
    timeFormat,
    validateOnBlur,
    updateComponentState,
    componentStateRef,
  ])

  const onFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      e.target.select()
      validateOnFocus()
    },
    [validateOnFocus],
  )

  return { onBlur, onFocus }
}
