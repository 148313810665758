import type { MainSelection } from '../typings'

import { snapshot } from 'valtio'
import { subscribeKey } from 'valtio/utils'

import { mainAtom } from '../main'

export const subscribeMainSelection = <Category extends keyof MainSelection = keyof MainSelection>(
  category: Category,
  subscriber: (selection: workwave.DeepReadonly<MainSelection[Category]>) => void,
) => {
  return subscribeKey(mainAtom.selection, category, selection => subscriber(snapshot(selection)))
}
