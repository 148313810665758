import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    unsubscribeDataFailureTitle: translate({
      id: 'orders.unsubscribeData.failure.title',
    }),

    unsubscribeDataFailureDescription: translate({
      id: 'orders.unsubscribeData.failure.description',
    }),

    unsubscribeSmsDescription: (phoneNumber: string, date: string) =>
      translate({
        id: 'orders.unsubscribeData.sms.description',
        values: {
          phoneNumber,
          date,
        },
      }),
    unsubscribeSmsTitle: translate({
      id: 'orders.unsubscribeData.sms.title',
    }),

    unsubscribeEmailDescription: (email: string, date: string) =>
      translate({
        id: 'orders.unsubscribeData.email.description',
        values: {
          email,
          date,
        },
      }),
    unsubscribeEmailTitle: translate({
      id: 'orders.unsubscribeData.email.title',
    }),
  }))

  return api
}
