import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, Stack } from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'
import { Chip } from '@workwave-tidal/tidal'

import { selectCompanies } from '@/features/domain/company'

import { useTexts } from './hooks/useTexts'

interface Props {
  companyIds: string[]
  isCompanyFiltered: boolean
}

export function Companies(props: Props) {
  const { companyIds, isCompanyFiltered } = props
  const companies = useCompanies(companyIds)

  const texts = useTexts()

  if (!isCompanyFiltered) return null

  return (
    <Box paddingBottom={4} data-testid="companies">
      <Typography variant="caption" color="textSecondary">
        {texts.labels.companies}
      </Typography>

      <Stack pt={0.5} gap={0.5} direction="row" flexWrap="wrap">
        {companies.length === 0 ? (
          <>
            <ErrorOutline color="error" />
            <Typography variant="body1" color="error">
              {texts.errors.companyMissing}
            </Typography>
          </>
        ) : (
          companies.map(company => (
            <Chip size="small" variant="filledOutline" label={company.name} key={company.id} />
          ))
        )}
      </Stack>
    </Box>
  )
}

function useCompanies(companyIds: string[]): uui.domain.client.rm.Company[] {
  const companies = useSelector(selectCompanies)

  return useMemo(
    () => companyIds.map(id => companies[id]).sort(sortCompanies),
    [companies, companyIds],
  )
}

function sortCompanies(a: uui.domain.client.rm.Company, b: uui.domain.client.rm.Company) {
  return a.name.localeCompare(b.name)
}
