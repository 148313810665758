import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    driverMobileApp: translate({ id: 'app.header.setup.driverMobileApp' }),
    driverAssistance: translate({ id: 'app.header.setup.driverAssistance' }),
    reasonsForNotCompletedOrders: translate({
      id: 'app.header.setup.reasonsForNotCompletedOrders',
    }),
    disabledForBeta: translate({ id: 'global.disabledForBeta' }),
    disabledSectionTooltip: translate({ id: 'setup.driverMobileApp.disabled.rmonly' }),
  }))

  return api
}
