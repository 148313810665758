import { useSelector } from 'react-redux'

import { selectDriverAssignmentsListStructure } from '@/features/domain/lists'
import { useEditingStateWithCtx } from '@/atoms'
import { useFilterList } from './useFilterList'
import { useSearchList } from './useSearchList'

export function useFilter() {
  const { editing } = useEditingStateWithCtx('driversAssignment')
  const { query, setQuery } = useSearchList()
  const { filter, setFilter } = useFilterList()
  const structure = useSelector(selectDriverAssignmentsListStructure)

  return {
    query,
    filter,
    editing,
    setQuery,
    setFilter,
    noEntries: structure.totalItems === 0,
  }
}
