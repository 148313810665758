import { conversionUtils } from '@/server-data'

import { explodeLoads } from './explodeLoads'
import { parseBreaks } from './parseBreaks'
import { parseWorkingDayInfo } from './parseWorkingDayInfo'

const disabledDepotValue = 'disabledDepotValue'

export function computeVehicleSettingsFromFormValues(
  values: uui.domain.ui.forms.ExceptionFormValues,
  userConfig: uui.domain.UserConfiguration,
  workingDayStartSec: number,
): [string, uui.domain.client.rm.VehicleSettings] {
  const {
    workingDayInfo,
    workingDayInfo: { flexibleStart },
    speedFactor,
    loads,
    available: vehicleAvailability,
    arrivalDepotId,
    departureDepotId,
    breaks,
    tags,
    regionIds,
    maxDistanceMt,
    maxOrders,
    maxDrivingTimeSec,
    maxWorkingTimeSec,
    minWorkingTimeSec,
    activationCost,
    serviceTimeCost,
    drivingTimeCost,
    idleTimeCost,
    breakTimeCost,
    kmCost,
    perStopCost,
    id,
    applyFromDate,
    perStopTimeSec,
    notes,
    preparationTimeSec,
    closeOutTimeSec,
  } = values

  const metric = userConfig.distanceFormat === 'METRIC'

  const { loadCapacities, minLoadCapacities } = explodeLoads(loads)

  return [
    id,
    {
      available: vehicleAvailability === 'available',

      arrivalDepotId: arrivalDepotId === disabledDepotValue ? null : arrivalDepotId,
      departureDepotId: departureDepotId === disabledDepotValue ? null : departureDepotId,

      breaks: parseBreaks(breaks, workingDayStartSec),
      regionIds,

      timeWindow: parseWorkingDayInfo(workingDayInfo, workingDayStartSec),
      earlyDeparture: !flexibleStart,
      loadCapacities,
      minLoadCapacities,

      maxOrders,
      maxDistanceMt:
        Math.round(conversionUtils.convertDistanceToMeters(userConfig)(maxDistanceMt, 2)) || 0,
      maxDrivingTimeSec,
      maxWorkingTimeSec,
      minWorkingTimeSec: typeof minWorkingTimeSec === 'number' ? minWorkingTimeSec : 0,

      tags,
      speedFactor: speedFactor + 50,

      activationCost: activationCost * 100,
      serviceTimeCost: serviceTimeCost * 100,
      drivingTimeCost: drivingTimeCost * 100,
      idleTimeCost: idleTimeCost * 100,
      breakTimeCost: breakTimeCost * 100,
      kmCost: metric
        ? kmCost * 100
        : Math.trunc(conversionUtils.convertCostPerMilesToCostPerKilometers(kmCost * 100)),
      perStopCost: perStopCost * 100,

      notes,

      applyFromDate,
      perStopTimeSec,

      // this fields are not handled by the form, set it to the default value
      additionalServiceTimePerc: 0,
      additionalServiceTimeSec: 0,
      avoidDepotAdditionalServiceTime: false,

      preparationTimeSec: departureDepotId === 'disabledDepotValue' ? 0 : preparationTimeSec,
      closeOutTimeSec: arrivalDepotId === 'disabledDepotValue' ? 0 : closeOutTimeSec,
    },
  ]
}
