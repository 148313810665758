import { useState, useEffect, useCallback } from 'react'

import { SecondaryColumnHeader } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { resetCrudSelection } from '@/atoms'

import { useTexts } from './hooks/useTexts'
import { setRecurrences } from './hooks/useRecurrences'

import { ActionsManager } from './components/ActionsManager'

interface Props {
  selection: workwave.DeepReadonly<string[]>
  driverAssignments: uui.domain.client.rm.DriverAssignmentsWithId[]
}

export function BulkView(props: Props) {
  const { driverAssignments, selection } = props

  const texts = useTexts()
  const [view, setView] = useState<'default' | 'delete'>(() => 'default')

  setRecurrences(driverAssignments)

  // when selection changes reset to default view
  useEffect(() => {
    setView('default')
  }, [selection])

  const resetSelection = useCallback(() => resetCrudSelection('driverAssignments'), [])
  const count = driverAssignments.length

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.headerTitle(count)}
        description={texts.headerDescription}
        action={resetSelection}
        avatar={null}
      />
      <ActionsManager view={view} setView={setView} />
    </FormColumn>
  )
}
