import { useEffect } from 'react'
import { useListApi } from '@/atoms'

import { setConnectedList } from '../../atoms/selection/write/setConnectedList'

type Props = {
  disabled?: boolean
  category: uui.domain.ui.list.Category
}

export function ConnectMapToList(props: Props) {
  const { category, disabled = false } = props

  const { scrollTo } = useListApi(category)

  useEffect(() => {
    setConnectedList('connected', category, scrollTo, disabled)

    return () => {
      setConnectedList('idle')
    }
  }, [scrollTo, category, disabled])

  return null
}
