import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRoutes } from '@/features/domain/route'

/**
 * That hooks returns all correspondent orderStepIds for some specified routeIds
 *
 * NOTE: Since (almost in Navigo) routeIds are retrieved from routeInfos it can happen that
 * a routeId won't have a correspondent route into the global state.
 * That's because the backend doesn't consider empty routes as real routes (should have at least
 * one assigned orderStep to exists).
 */
export function useOrderStepIdsForRoutes(routeIds: string[]) {
  const routes = useSelector(selectRoutes)

  return useMemo(
    () =>
      Array.from(
        routeIds.reduce<Set<string>>((acc, routeId) => {
          const route = routes[routeId]

          // If there's no correspondent route (e.g. empty route), let's skip it
          if (!route) return acc

          route.steps.forEach(aggregatedSteps =>
            aggregatedSteps.steps.forEach(
              extRouteStep => extRouteStep.orderStepId && acc.add(extRouteStep.orderStepId),
            ),
          )

          return acc
        }, new Set<string>()),
      ),

    [routes, routeIds],
  )
}
