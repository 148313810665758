import {
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyLabel,
} from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  timezone: string
}

export function Timezone(props: Props) {
  const { timezone } = props
  const texts = useTexts()

  return (
    <ReadonlyBlock half>
      <ReadonlyLabel>{texts.timezone}</ReadonlyLabel>
      <ReadonlyField>
        <ReadonlyFieldRow>
          <ReadonlyFieldLabel>{timezone}</ReadonlyFieldLabel>
        </ReadonlyFieldRow>
      </ReadonlyField>
    </ReadonlyBlock>
  )
}
