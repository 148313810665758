import { Style } from 'ol/style'

import { genericStyles } from '../../genericStyles'

const vehicleLabelStylesCache: Map<string, Style> = new Map()

export const getVehicleFeatureLabelStyle = (
  marker: uui.domain.ui.map.markers.Vehicle,
  mode: uui.domain.ui.map.markers.MapStyles['vehicle']['mode'],
) => {
  if (mode !== 'label') return genericStyles.hidden

  const cacheId = `${mode}_${marker.label}`

  if (vehicleLabelStylesCache.has(cacheId)) {
    const cachedStyle = vehicleLabelStylesCache.get(cacheId)

    if (cachedStyle) return cachedStyle
  }

  const style = genericStyles.label.clone()
  const textInstance = style.getText()

  textInstance.setText(marker.label)
  textInstance.setOffsetY(35)

  vehicleLabelStylesCache.set(cacheId, style)

  return style
}
