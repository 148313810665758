import styled from 'styled-components'

import { buttonReset } from '@/styles'

import Arrow from './Arrow'

const WIDTH = 16

const StyledArrow = styled(Arrow)`
  ${buttonReset}
  flex: 0 0 auto;
  position: relative;
  display: block;
  width: ${WIDTH}px;
  height: 100%;
  transition: 0.3s ease;
  transition-property: transform, opacity;
  cursor: pointer;
  opacity: 1;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled).move-left {
    transform: translateX(-15%);
  }

  &:hover:not(:disabled).move-right {
    transform: translateX(15%);
  }

  &:hover:not(:disabled) .o-arrow__icon {
    color: ${p => p.theme.colors.$dodgerBlue};
  }

  &.is-invisible {
    opacity: 0;
  }

  &:disabled.is-invisible {
    cursor: auto;
  }

  .o-arrow__icon {
    display: block;
    width: 100%;
    transition: color 0.3s ease;
  }
`
StyledArrow.displayName = 'StyledArrow'

export default StyledArrow
