import OlMap from 'ol/Map'
// import { SearchOnMapControl } from '../tools/SearchOnMap'

export const registerSearchControl = (map: OlMap) => {
  const uniqueId = 'rm-search-on-map'
  const controls = map.getControls()

  if (controls.get(uniqueId)) return

  // const searchOnMapControl = new SearchOnMapControl({
  //   map,
  //   className: 'ol-search-on-map-control',
  // })
  // searchOnMapControl.set('uid', uniqueId)
  // map.addControl(searchOnMapControl)

  // controls.set(uniqueId, searchOnMapControl)
}
