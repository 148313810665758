import styled from 'styled-components'

import { NumericInputProps as Props, NumericInput as Comp } from '@/forms-legacy'

export const NumericInput = styled(Comp)<Props>`
  border-color: ${p => (p.hasError ? p.theme.colors.$outrageousRed : p.theme.colors.$nightRider)};
  background-color: ${p => p.theme.colors.$pureWhite};
  padding-right: ${p => (p.showCounter && !p.multi ? '44px' : '34px')};

  .o-input-field-text__reset-button {
    top: ${p => (p.multi ? '18%' : '50%')};
  }

  .o-input-field-text__counter {
    right: ${p => (p.multi ? '-3px' : p.showCounter ? '12px' : '0px')};
    bottom: ${p => (p.multi ? '8%' : 'auto')};
  }
`

NumericInput.displayName = 'NumericInput'
