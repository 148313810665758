// This is the Avatar for the MUI version of the list
// the current OrderAvatar is used in other parts of the application
// I'll keep them separated to allow QA to make a punctual test rather than modify all the UI

import { Theme, useTheme } from '@mui/material'

import { ListItemAvatarSquared } from '@/components/List'

interface Props {
  extendedOrderStep: uui.domain.client.rm.ExtendedOrderStep
}

const SPLIT_CHARACTER = '.'

export function Avatar(props: Props) {
  const { extendedOrderStep } = props
  const { palette } = useTheme()

  const color = extendedOrderStep.isUnassigned ? '#fff' : `#${extendedOrderStep.color}`
  const trackingData = !extendedOrderStep.isUnassigned && extendedOrderStep.orderStep.trackingData

  const violated =
    !extendedOrderStep.isUnassigned &&
    Array.isArray(extendedOrderStep.violations) &&
    extendedOrderStep.violations.length > 0

  const executed = !extendedOrderStep.isUnassigned && extendedOrderStep.hasBeenExecuted

  const label = !extendedOrderStep.isUnassigned ? extendedOrderStep.routeStepLabel : ''
  const [text = '', secondaryText = '']: string[] = label.split(SPLIT_CHARACTER)
  const separator = secondaryText.length > 0 ? SPLIT_CHARACTER : ''

  const borderColor = extendedOrderStep.isUnassigned ? palette.divider : undefined

  return (
    <ListItemAvatarSquared
      secondaryText={secondaryText}
      borderColor={borderColor}
      badgeColor={getBadgeColor(trackingData, palette)}
      executed={executed}
      violated={violated}
      color={color}
      text={`${text}${separator}`}
    />
  )
}

function getBadgeColor(
  trackingData: false | uui.domain.client.rm.OrderStepTrackingData,
  palette: Theme['palette'],
) {
  if (!trackingData) return undefined

  switch (trackingData.status) {
    case 'done':
      return palette.success.main

    case 'reschedule':
      return palette.error.main
  }
}
