import { type ResourceModel } from '@bryntum/schedulerpro'
import { type CSSProperties } from 'react'
import { conversionUtils } from '@/server-data'

import { useAggregateData } from './hooks/useAggregateData'
import { useTexts } from './useTexts'

interface Props {
  record: ResourceModel
  userConfig: uui.domain.UserConfiguration
}

const containerStyle: CSSProperties = { display: 'flex', flexDirection: 'column' }
const titleStyle: CSSProperties = {
  letterSpacing: '0.0075em',
  marginBottom: '0.35rem',
  fontWeight: 500,
  fontSize: '1.25rem',
}
const textStyle: CSSProperties = {
  letterSpacing: '0.00714em',
  marginBottom: '0.35rem',
  fontWeight: 500,
  fontSize: '0.875rem',
}

export function ApprovedDistanceTooltip(props: Props) {
  const { record, userConfig } = props

  const formatNumber = Intl.NumberFormat(conversionUtils.intlFormat[userConfig.language]).format

  const aggregateData = useAggregateData(record, userConfig, formatNumber)
  const texts = useTexts()

  if (!aggregateData) return null

  const metric = userConfig.distanceFormat === 'METRIC'

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>{texts.title(metric)}</div>

      <div style={textStyle}>
        {texts.approvedMileage(
          aggregateData.value,
          aggregateData.total,
          aggregateData.saturation,
          metric,
        )}
      </div>
    </div>
  )
}
