import { useMouseTrap } from '@/hooks'

import { navigateHorizontally } from '../../../utils/selection/navigateHorizontally'

// this will take care of the keyboard navigation when the scheduler is not on focus
export function useHandleKeyboardNavigation() {
  useMouseTrap('left', () => navigateHorizontally('left', false), true)
  useMouseTrap('shift+left', () => navigateHorizontally('left', true), true)

  useMouseTrap('right', () => navigateHorizontally('right', false), true)
  useMouseTrap('shift+right', () => navigateHorizontally('right', true), true)
}
