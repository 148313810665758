import styled from 'styled-components'

export const ActiveBadge = styled.span`
  display: flex;
  align-items: center;

  height: 14px;

  font-size: ${p => p.theme.fonts.$xs};
  text-transform: uppercase;
  color: ${p => p.theme.colors.$pigmentGreen};
  padding: 0 8px;
  border: 1px solid ${p => p.theme.colors.$pigmentGreen};
  border-radius: 4px;
`

ActiveBadge.displayName = 'ActiveBadge'
