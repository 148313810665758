import type { ChangeEvent } from 'react'
import type { Props } from './typings'

import { useCallback } from 'react'

import { Tortoise, Rabbit } from '@/icons'

import { InputRange } from './InputRange'
import { InputRangeOuter } from './InputRangeOuter'
import { InputRangeWrapper } from './InputRangeWrapper'
import { InputRangeIcon } from './InputRangeIcon'
import { InputRangeBadge } from './InputRangeBadge'
import { InputRangeScaleContainer } from './InputRangeScaleContainer'
import { InputRangeScaleInner } from './InputRangeScaleInner'
import { InputRangeScaleButton } from './InputRangeScaleButton'

import { getNextSnappedValue, getPrevSnappedValue } from './utils'

function defaultGetCoordinatesStyle(val: number, max: number) {
  return {
    left: `${(1 - Math.abs(val) / max) * 100}%`,
  }
}

export function DrivingTimeSlider(props: Props) {
  const {
    onChange,
    steps = [],
    step,
    min,
    max,
    scaleMax,
    getBadgeText,
    getCoordinatesStyle = defaultGetCoordinatesStyle,
    value,
    disabled,
    hideBadge,
    outerStyle,
  } = props

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(Math.round(e.target.valueAsNumber))
    },
    [onChange],
  )

  const onPrev = useCallback(() => {
    if (!steps) return
    onChange(getPrevSnappedValue(value, steps))
  }, [onChange, steps, value])

  const onNext = useCallback(() => {
    if (!steps) return
    onChange(getNextSnappedValue(value, steps))
  }, [onChange, steps, value])

  return (
    <>
      <InputRangeOuter style={outerStyle}>
        <InputRangeIcon disabled={value === min || disabled} onClick={onPrev}>
          <Tortoise />
        </InputRangeIcon>
        <InputRangeWrapper>
          <InputRange
            type="range"
            min={min}
            max={max}
            step={step ? `${step}` : 'any'}
            value={value}
            onChange={handleOnChange}
            disabled={disabled}
          />
          {!hideBadge && (
            <InputRangeBadge style={getCoordinatesStyle(value, scaleMax)}>
              {getBadgeText(value)}
            </InputRangeBadge>
          )}
        </InputRangeWrapper>
        <InputRangeIcon disabled={value === max || disabled} onClick={onNext}>
          <Rabbit />
        </InputRangeIcon>
      </InputRangeOuter>
      <InputRangeScaleContainer>
        <InputRangeScaleInner>
          {steps.map((step, index: number) => (
            <InputRangeScaleButton
              style={getCoordinatesStyle(step.value, scaleMax)}
              disabled={disabled}
              onClick={() => onChange(step.value)}
              key={`${index}-${step.value}`}
            >
              {step.label}
            </InputRangeScaleButton>
          ))}
        </InputRangeScaleInner>
      </InputRangeScaleContainer>
    </>
  )
}
