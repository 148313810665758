import styled from 'styled-components'
import TimeWindowListItem from './TimeWindowListItem'

const LATERAL_GUTTER = 10
const VERTICAL_ALIGNER_BUTTON = 28

const styledComponent = styled(TimeWindowListItem)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${LATERAL_GUTTER}px;

  > * + * {
    padding-left: ${LATERAL_GUTTER}px;
  }

  > * {
    flex: 0 0 auto;
  }

  .o-time-window-list-item__button {
    margin-top: ${VERTICAL_ALIGNER_BUTTON}px;
  }

  .o-selection-list-item__field {
    flex: 1 0 auto;
    width: auto;
  }
`
export default styledComponent
