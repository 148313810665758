import type { IconContainerProps } from './typings'

import { ValidColor, theme } from '@/local/components'

import { IconContainer } from './elements/IconContainer'

type Props = IconContainerProps & {
  background: ValidColor
}

export function NoStatus(props: Props) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <circle
          strokeWidth={2}
          stroke={theme.colors[props.background]}
          fill={props.color ? theme.colors[props.color] : 'currentColor'}
          cx="8"
          cy="8"
          r="7"
        />
      </svg>
    </IconContainer>
  )
}
