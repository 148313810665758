import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    help: translate({ id: 'app.header.help' }),
    devTools: translate({ id: 'app.header.devTools' }),
    dashboard: translate({ id: 'app.header.dashboard' }),
    setupArea: translate({ id: 'app.header.setupArea' }),
    settingsArea: translate({ id: 'app.header.settingsArea' }),
    gpsUserConsole: translate({ id: 'app.header.gpsUserConsole' }),
    pendoResourceCenter: translate({ id: 'app.header.pendoResourceCenter' }),
    pendoResourceCenterSubtitle: translate({ id: 'app.header.pendoResourceCenter.subtitle' }),
  }))

  return api
}
