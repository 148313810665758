import type {
  DrawEditableAreaInteraction,
  ModifyEditableAreaInteraction,
} from '../../../interactions/EditableAreaInteraction'

import { getMap } from '../../../atoms/map/utils/getMap'
import { findLayer } from '../../../layers/utils/findLayer'

export function resetUseDrawArea(
  drawArea: DrawEditableAreaInteraction,
  modifyArea: ModifyEditableAreaInteraction,
) {
  drawArea.setActive(false)
  modifyArea.setActive(false)

  // reset colors
  drawArea.updateColor('#303030')
  modifyArea.updateColor('#303030')

  // clear the temporary draw layer
  const layer = findLayer(getMap(), 'draw')
  layer.getSource()?.clear(true)
}
