import styled from 'styled-components'

export const TransparentButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 0;
  outline: 0;
  margin: 0;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
`

TransparentButton.displayName = 'TransparentButton'
