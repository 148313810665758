import { Fragment, ReactNode, Component } from 'react'

import { ListActionButton } from '@/forms-legacy'
import { AddRounded } from '@/icons'
import { clsx } from '@/utils'

import TimeWindowListItem, { Props as TimeWindowListItemProps } from './TimeWindowListItem'

import { getAddNewTimeWindowText } from './intl'

export type Callback = (timeWindow: uui.domain.rm.TimeWindow, id: number) => void
export type DeleteCallback = (id: number) => void

export type Props = {
  list: uui.domain.rm.TimeWindow[]
  onChange: (list: uui.domain.rm.TimeWindow[]) => void
  max?: number
  className?: string
  defaultNewValue?: uui.domain.rm.TimeWindow
  renderError?: (index: number) => ReactNode
  h24?: boolean
}

export const DEFAULT_NEW_VALUE: uui.domain.rm.TimeWindow = {
  startSec: 9 * 60 * 60,
  endSec: 18 * 60 * 60,
}

const DEFAULT_MAX = 2

export default class TimeWindowList extends Component<Props> {
  handleAdd = (): void => {
    const { list, onChange, defaultNewValue = DEFAULT_NEW_VALUE } = this.props
    onChange([...list, defaultNewValue])
  }

  handleRemove = (index: number): void => {
    const { list, onChange } = this.props
    const updatedCollection = [...list]
    updatedCollection.splice(index, 1)
    onChange(updatedCollection)
  }

  handleChange = (timeWindow: uui.domain.rm.TimeWindow, id: number): void => {
    const { list, onChange } = this.props
    const updatedCollection: uui.domain.rm.TimeWindow[] = list.map(
      (item: uui.domain.rm.TimeWindow, index: number): uui.domain.rm.TimeWindow => {
        if (id === index) {
          return timeWindow
        }
        return item
      },
    )
    onChange(updatedCollection)
  }

  renderSingle = (item: uui.domain.rm.TimeWindow, index: number): ReactNode => {
    const { h24 } = this.props
    const props: TimeWindowListItemProps = {
      id: index,
      action: this.handleRemove,
      onChange: this.handleChange,
      item,
      h24,
    }
    return <TimeWindowListItem key={`timeWindow-${index}`} {...props} />
  }

  render() {
    const { className = '', list, max = DEFAULT_MAX, renderError } = this.props

    const rootClassName = clsx({
      [className]: true,
      'o-time-window-list': true,
    })

    return (
      <div className={rootClassName}>
        <ListActionButton
          icon={<AddRounded />}
          onClick={this.handleAdd}
          disabled={list.length >= max}
          testid="time-window__add-button"
        >
          {getAddNewTimeWindowText()}
        </ListActionButton>
        <div className="o-time-window-list__list" data-testid="time-window__list">
          {list.map((item: uui.domain.rm.TimeWindow, index: number) => (
            <Fragment key={`timeWindow-row-${index}`}>
              {this.renderSingle(item, index)}
              {renderError?.(index)}
            </Fragment>
          ))}
        </div>
      </div>
    )
  }
}
