import styled from 'styled-components'

const ColorPickerText = styled.h3`
  position: relative;
  color: ${p => p.theme.colors.$nightRider};
  font-size: ${p => p.theme.fonts.$m};
  margin: 8px 0 0 0;
`
ColorPickerText.displayName = 'ColorPickerText'
export default ColorPickerText
