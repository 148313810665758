import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'
import { selectTerritories, selectTerritoryId } from '@/features/domain/territory'

type ModalStatus = 'ready'
interface ModalData {
  id: string
  territories: Record<string, uui.domain.client.rm.Territory>
}

export const modalId = 'copyStartEndRouteSettings'

export const useController = () => useModalController<ModalData, ModalStatus>(modalId)
export const useConfigureController = () => {
  const territoryId = useSelector(selectTerritoryId)
  const territories = { ...useSelector(selectTerritories) }

  // Remove the current territory from the object
  delete territories[territoryId]

  const territoryIds = Object.keys(territories)

  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      invalid: false,
      status: 'ready' as ModalStatus,
      data: { territories, id: territoryIds[0] },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalStatus>(modalId, options)
  const {
    update,
    data: { id },
  } = ctrl

  // Invalid state
  useEffect(() => {
    update({ invalid: !id })
  }, [id, update])

  return ctrl
}
