import { Skeleton } from '@mui/material'

import { Text } from '@/local/components'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useLastPosition } from '../hooks/useLastPosition'
import { useTexts } from '../../../useTexts'

interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
}

const ellipsisContainerStyle = { maxWidth: '100%', overflow: 'hidden' }

export function LastPosition(props: Props) {
  const { device } = props

  const lastPosition = useLastPosition(device)
  const texts = useTexts()

  if (lastPosition === 'loading') return <Skeleton width={200} height={29} animation="wave" />

  const text = lastPosition?.address ?? texts.unknownPosition

  return (
    <div style={ellipsisContainerStyle}>
      <Tooltip placement="top" title={text}>
        <Text
          data-trackid="navigo-device-overview-details-single-lastPosition"
          data-testid="navigo-device-overview-details-single-lastPosition"
          size="$h6"
          ellipsis
          italic
        >
          {text}
        </Text>
      </Tooltip>
    </div>
  )
}
