import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { MapControls } from '@/components/MapControls'

import { useEnsureGeofenceVisibility } from './hooks/useEnsureGeofenceVisibility'
import { GeofencesRoot } from './components/form'
import { Map } from './components/Map'

export function Geofences() {
  useEnsureGeofenceVisibility()

  return (
    <HorizontalLayout data-testid="setup-geofences-root">
      <GeofencesRoot />

      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />

          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
