import type { FormData } from '../../types'

import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectDepots } from '@/features/domain/depot'
import { updateOrders as updateOrdersAction } from '@/features/domain/order'
import { selectCompanies } from '@/features/domain/company'
import { selectLicensingLimits } from '@/features/domain/user'
import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'

import { updateTagsWarning } from '../../utils/updateTagsWarning'

import { computeEligibility } from './utils/computeEligibility'
import { computeEmail } from './utils/computeEmail'
import { computeForceVehicleId } from './utils/computeForceVehicleId'
import { computeLoads } from './utils/computeLoads'
import { computeLocation } from './utils/computeLocation'
import { computeNotes } from './utils/computeNotes'
import { computePhone } from './utils/computePhone'
import { computePriority } from './utils/computePriority'
import { computeServiceTimeSec } from './utils/computeServiceTimeSec'
import { computeTags } from './utils/computeTags'
import { computeTimeWindows } from './utils/computeTimeWindows'
import { computeType } from './utils/computeType'
import { computeCompanyId } from './utils/computeCompanyId'

export function useFormData(orders: uui.domain.client.rm.ExtendedOrderStep[]) {
  const depots = useSelector(selectDepots)
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const maxTagsCount = useSelector(selectLicensingLimits).maxTags
  const companies = useSelector(selectCompanies)

  const initialValues = useMemo<FormData>(() => {
    const values = {
      eligibility: computeEligibility(orders),
      email: computeEmail(orders),
      companyId: computeCompanyId(orders, companies),
      forceVehicleId: computeForceVehicleId(orders),
      loads: computeLoads(orders),
      location: computeLocation(orders, depots),
      notes: computeNotes(orders),
      phone: computePhone(orders),
      priority: computePriority(orders),
      serviceTimeSec: computeServiceTimeSec(orders),
      tagsIn: computeTags(orders, 'in', maxTagsCount),
      tagsOut: computeTags(orders, 'out', maxTagsCount),
      timeWindows: computeTimeWindows(orders),
      type: computeType(orders),
    }

    updateTagsWarning(values.tagsIn, values.tagsOut)

    return values
  }, [orders, depots, maxTagsCount, companies])

  const updateOrders = useCallback(
    async (data: uui.domain.ui.forms.BulkOrderUpdate) => {
      try {
        const thunkResult = await dispatch(updateOrdersAction(data))

        if (updateOrdersAction.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        return true
      } catch (e) {
        toast.error(e.message)
      }

      return false
    },
    [dispatch, toast],
  )

  return [initialValues, updateOrders] as const
}
