import styled from 'styled-components'

import { normalizeCssValue } from '@/local/components'

type Props = {
  alignItems?: 'center' | 'start' | 'end'
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  width?: number | string
  height?: number | string
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse'
  padding?: string
  paddingTop?: string | number
  paddingBottom?: string | number
  paddingLeft?: string | number
  paddingRight?: string | number
  marginTop?: string | number
  marginBottom?: string | number
  marginLeft?: string | number
  marginRight?: string | number
}

export const HorizontalLayout = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: ${p => p.flexWrap || 'nowrap'};
  width: ${p => normalizeCssValue(p.width || '100%')};
  height: ${p => normalizeCssValue(p.height || '100%')};
  align-items: ${p => p.alignItems ?? 'start'};
  justify-content: ${p => {
    switch (p.justifyContent) {
      case 'start':
        return 'flex-start'
      case 'end':
        return 'flex-end'
      default:
        return p.justifyContent ?? 'normal'
    }
  }};
  margin-top: ${p => normalizeCssValue(p.marginTop || 0)};
  margin-bottom: ${p => normalizeCssValue(p.marginBottom || 0)};
  margin-left: ${p => normalizeCssValue(p.marginLeft || 0)};
  margin-right: ${p => normalizeCssValue(p.marginRight || 0)};

  padding-top: ${p => normalizeCssValue(p.paddingTop || 0)};
  padding-bottom: ${p => normalizeCssValue(p.paddingBottom || 0)};
  padding-left: ${p => normalizeCssValue(p.paddingLeft || 0)};
  padding-right: ${p => normalizeCssValue(p.paddingRight || 0)};
  padding: ${p => p.padding || 0};
`

HorizontalLayout.displayName = 'HorizontalLayout'
