import { useMemo } from 'react'

export const useOptions = (activeTabId: string) =>
  useMemo(
    () => [
      {
        id: 'telematics',
        label: 'Telematics',
        selected: activeTabId === 'telematics',
        disabled: false,
      },
      {
        id: 'map',
        label: 'Map',
        selected: activeTabId === 'map',
        disabled: false,
      },
      {
        id: 'devPreferences',
        label: 'Dev Preferences',
        selected: activeTabId === 'devPreferences',
        disabled: false,
      },
      {
        id: 'poly',
        label: 'Poly',
        selected: activeTabId === 'poly',
        disabled: false,
      },
      {
        id: 'error',
        label: 'Error',
        selected: activeTabId === 'error',
        disabled: false,
      },
      {
        id: 'version',
        label: 'Version',
        disabled: false,
        selected: activeTabId === 'version',
      },
      {
        id: 'virtualDevices',
        label: 'Virtual Devices',
        disabled: false,
        selected: activeTabId === 'virtualDevices',
      },
      {
        id: 'dynamicLoadsCf',
        label: 'Dynamic Loads/Custom fields',
        disabled: false,
        selected: activeTabId === 'dynamicLoadsCf',
      },
      {
        id: 'newScheduler',
        label: 'New scheduler',
        disabled: false,
        selected: activeTabId === 'newScheduler',
      },
    ],
    [activeTabId],
  )
