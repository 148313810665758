import { DropdownItem } from '../typings'

export const filterValues = (searchText: string, values: DropdownItem[]): DropdownItem[] => {
  const searchTerm: string = searchText.trim().toLowerCase()

  const filteredValues: DropdownItem[] = values.filter(({ label }: DropdownItem) => {
    const validValue = searchTerm.length === 0 || label.toLowerCase().includes(searchTerm)

    return validValue
  }, [])

  return filteredValues
}
