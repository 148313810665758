import { styled } from '@mui/material'
import { Tick } from '@/icons'

interface Props {
  active: boolean
}

const IconContainer = styled('div', { name: 'IconContainer' })<Props>(({ active }) => ({
  opacity: active ? 1 : 0,
}))

export function ActiveIcon(props: Props) {
  const { active } = props
  return (
    <IconContainer active={active}>
      <Tick size={12} color="$scienceBlue" marginTop={2} marginRight={10} />
    </IconContainer>
  )
}
