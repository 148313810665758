import type { PartialStoreState } from '../typings'

import { createSelector } from '@reduxjs/toolkit'

export const selectPlan = (state: PartialStoreState) => state.domain.publicData.domain.rm.plan

export const selectCurrentRevision = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.plan.currentRevision

export const selectHistory = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.plan.revisionHistory

export const selectPlanSnapshots = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.plan.snapshotsByCalendarRange

export const selectActiveOrdersCountByDate = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.plan.activeOrdersCountByDate

export const selectOpsOrdersCountByDate = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.plan.opsOrdersCountByDate

export const selectPrevHistoryRevision = createSelector(
  selectHistory,
  selectCurrentRevision,
  (revisionHistory, currentRevision): number | -1 => {
    const currentRevisionIndex = revisionHistory.indexOf(currentRevision)

    if (currentRevisionIndex <= 0) {
      return -1
    }

    return revisionHistory[currentRevisionIndex - 1]
  },
)

export const selectNextHistoryRevision = createSelector(
  selectHistory,
  selectCurrentRevision,
  (revisionHistory, currentRevision): number | -1 => {
    const currentRevisionIndex = revisionHistory.indexOf(currentRevision)

    if (currentRevisionIndex < 0 || currentRevisionIndex === revisionHistory.length - 1) {
      return -1
    }

    return revisionHistory[currentRevisionIndex + 1]
  },
)

export const selectCurrentPlanSnapshot = createSelector(
  selectPlanSnapshots,
  selectCurrentRevision,
  (snapshots, currentRevision): uui.domain.client.rm.PlanSummary | undefined => {
    return snapshots[currentRevision]
  },
)
