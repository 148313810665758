import { type SchedulerPro } from '@bryntum/schedulerpro'
import { subscribe } from 'valtio/vanilla'

import { loadingStateAtom, applicationModeAtom } from '@/atoms'

export function startSchedulerLoadingStateSync(scheduler: SchedulerPro) {
  // Subscribe to the loading atom to refresh rows when needed
  let fetchingGpsData = loadingStateAtom.isFetchingGpsData
  let rescheduling = loadingStateAtom.isRescheduling

  return subscribe(loadingStateAtom, () => {
    if (!scheduler) return

    const isReschedulingNow = loadingStateAtom.isRescheduling
    const isFetchingGpsDataNow = loadingStateAtom.isFetchingGpsData

    if (fetchingGpsData !== isFetchingGpsDataNow) {
      fetchingGpsData = isFetchingGpsDataNow
      scheduler.refreshRows()
    }

    if (rescheduling !== isReschedulingNow) {
      rescheduling = isReschedulingNow
      const isAppReadonly = (applicationModeAtom.mode as 'readonly' | 'normal') === 'readonly'

      // eslint-disable-next-line no-param-reassign
      scheduler.readOnly = rescheduling || isAppReadonly
    }
  })
}
