import type { FormValidator } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../../../formFields'
import type { ValidationTexts } from '../useTexts'

import { useValidateEmail } from '@/hooks'

type ValidateEmail = ReturnType<typeof useValidateEmail>

export const createValidateEmail =
  (texts: ValidationTexts, validateEmail: ValidateEmail): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const { value, status } = formApi.getField('email')

    const invalidEmailError: FormErrors = {
      id: 'invalidEmailError',
      field: 'email',
      message: texts.invalidEmail,
      priority: 3,
    }

    const hasToValidate = status !== 'pristine' && value.status === 'exact'
    const email = value.status === 'exact' ? value.value : ''

    if (!hasToValidate) {
      return {
        [invalidEmailError.id]: null,
      }
    }

    const valid = email.length === 0 ? true : await validateEmail(email)

    return {
      [invalidEmailError.id]: !valid ? invalidEmailError : null,
    }
  }
