import { useCallback, useState } from 'react'
import { updateMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'showVehiclesWithLabels' | 'showVehicles' | 'hideVehicles'
export function useActions() {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const showVehiclesWithLabels = useCallback(async () => {
    setMethodInExecution('showVehiclesWithLabels')

    await dispatch(updateMapStyle({ type: 'vehicle', mode: 'label' }))

    setMethodInExecution(null)
  }, [dispatch])

  const showVehicles = useCallback(async () => {
    setMethodInExecution('showVehicles')

    await dispatch(updateMapStyle({ type: 'vehicle', mode: 'on' }))

    setMethodInExecution(null)
  }, [dispatch])

  const hideVehicles = useCallback(async () => {
    setMethodInExecution('hideVehicles')

    await dispatch(updateMapStyle({ type: 'vehicle', mode: 'off' }))

    setMethodInExecution(null)
  }, [dispatch])

  return { methodInExecution, actions: { showVehiclesWithLabels, showVehicles, hideVehicles } }
}
