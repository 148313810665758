import type { BulkLoadPartial } from '../../../../../types'

import { Stack } from '@mui/material'
import { PartialLoad } from './PartialLoad'

interface Props {
  loads: BulkLoadPartial[]
  onDeleteLoad: (name: string) => void
  onAddLoad: (name: string) => void
  ordersCount: number
  addableLoads: Set<string>
  maxLoadsForOrder: number
  disabled?: boolean
}

export function PartialList(props: Props) {
  const { loads, onDeleteLoad, onAddLoad, ordersCount, addableLoads, maxLoadsForOrder, disabled } =
    props

  if (loads.length === 0) return null

  return (
    <Stack spacing={2}>
      {loads.map(load => {
        return (
          <PartialLoad
            key={load.name}
            load={load}
            onDeleteLoad={onDeleteLoad}
            onAddLoad={onAddLoad}
            ordersCount={ordersCount}
            maxLoadsForOrder={maxLoadsForOrder}
            addable={addableLoads.has(load.name)}
            disabled={disabled}
          />
        )
      })}
    </Stack>
  )
}
