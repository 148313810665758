export function computeSendMessagePermission(
  vehiclesCount: number,
  isToday: boolean,
  isLoadingGpsData: boolean,
  trackingProvider: uui.domain.server.gps.TrackingProvider,
  driver?: uui.domain.client.rm.Driver,
  deviceIds?: string[],
) {
  // Hide the button if the tracking provider is not WWGPS
  if (trackingProvider !== 'WWGPS') return 'hidden'

  // Disable the button if is loading gps data
  if (isLoadingGpsData) return 'disabledLoadingGpsData'

  // Disable the button if got multiple vehicles
  if (vehiclesCount !== 1) return 'disabledMultipleVehicles'

  // Disable the button if got no driver
  if (!driver) return 'disabledNoDriver'

  // Disable the button if got no devices
  if (!deviceIds || deviceIds.length === 0) return 'disabledNoDevice'

  // Disable the button if the driver is not tracked
  if (!driver.deployment.gpsDeviceId) return 'disabledDriverNotTracked'

  // Disable the button if is not today
  if (!isToday) return 'disabled'

  return 'enabled'
}
