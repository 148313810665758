import type VectorTile from 'ol/VectorTile'
import type { Geometry } from 'ol/geom'
import type Feature from 'ol/Feature'
import { mapAtom } from '../map'

export function authenticatedMVTTileLoadFunction(tile: VectorTile, url: string) {
  tile.setLoader(async (extent, _resolution, projection) => {
    // Get the authentication token from the map atom
    const { authenticationToken } = mapAtom

    // Abort if the authentication token is not valid
    if (!authenticationToken) return

    // Append the authentication token to the tile URL (if authentication token is of type 'apiKey')
    const finalUrl =
      authenticationToken.type === 'apiKey' ? `${url}apiKey=${authenticationToken.value}` : url

    // Define the headers for the fetch request (if authentication token is of type 'oAuth')
    const headers =
      authenticationToken.type === 'oAuth'
        ? {
            headers: { Authorization: `Bearer ${authenticationToken.value}` },
          }
        : undefined

    try {
      // Fetch the tile data
      const response = await fetch(finalUrl, headers)

      // Retrieve the tile data as an ArrayBuffer
      const data = await response.arrayBuffer()

      // Get the format of the tile
      const format = tile.getFormat() // ol/format/MVT configured as source format

      // Read the features from the tile data
      const features = format.readFeatures(data, {
        extent: extent,
        featureProjection: projection,
      }) as Feature<Geometry>[]

      // Set the features of the tile
      tile.setFeatures(features)
    } catch (e) {
      console.error(e.message)
    }
  })
}
