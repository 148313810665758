import { useSelector } from 'react-redux'
import { selectCompaniesAsArray } from '@/features/domain/company'

export function useCompanies() {
  const companies = useSelector(selectCompaniesAsArray)

  return {
    companies,
  }
}
