import { PureComponent } from 'react'
import { Field as FinalFormField } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import TimeWindowExceptionListField from './TimeWindowExceptionListField'
import { parseField, formatField } from './utils'
import { PublicProps as Props, FieldRenderProps } from './typings'

export default class TimeWindowExceptionListFieldWrapper extends PureComponent<Props> {
  render() {
    const { name, ...props } = this.props

    return (
      <FinalFormField
        {...props}
        name={name}
        parse={parseField}
        format={formatField}
        isEqual={isDeepEqual}
        render={(formProps: FieldRenderProps) => {
          return <TimeWindowExceptionListField {...this.props} formProps={formProps} />
        }}
      />
    )
  }
}
