import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'contextualMenu.stylesOnMap.depot.title' }),
    subtitle: translate({ id: 'contextualMenu.stylesOnMap.depot.subTitle' }),
    show: translate({ id: 'contextualMenu.stylesOnMap.depot.show' }),
    reduce: translate({ id: 'contextualMenu.stylesOnMap.depot.reduce' }),
    hide: translate({ id: 'contextualMenu.stylesOnMap.depot.hide' }),
  }))

  return api
}
