import type { BreadcrumbData } from '../types'

import { useMemo } from 'react'

import { useBreadcrumbsNavigatorData } from './useBreadcrumbsNavigatorData'
import { useEntitiesToShowByZoom } from './useEntitiesToShowByZoom'

export function useBreadcrumbsNavigator(breadcrumbsData: BreadcrumbData, id: string) {
  const entitiesToShowByZoom = useEntitiesToShowByZoom()

  const { points, chips, page } = useBreadcrumbsNavigatorData(
    breadcrumbsData,
    entitiesToShowByZoom,
    id,
  )

  return useMemo(() => {
    return {
      page,
      chips,
      currentPoint: points[page],
      entitiesToShow: entitiesToShowByZoom,
    }
  }, [points, chips, entitiesToShowByZoom, page])
}
