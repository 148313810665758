import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { eachDayOfInterval } from 'date-fns/esm'

import { dateUtils } from '@/utils'
import { selectRoutes } from '@/features/domain/route'
import { selectOrderSteps } from '@/features/domain/order'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectSortedRMVehicles } from '@/features/domain/vehicle'
import { selectRoutePlanTrackingData } from '@/features/domain/routeplan'
import { selectStartOfToday, selectUserConfiguration } from '@/features/domain/user'

export function useData(orderStepIds: string[]) {
  const vehicles = useSelector(selectSortedRMVehicles)
  const extOrderSteps = useSelector(selectOrderSteps)
  const dateRange = useSelector(selectCalendarRange)
  const today = useSelector(selectStartOfToday)
  const routes = useSelector(selectRoutes)
  const userConfig = useSelector(selectUserConfiguration)
  const routePlanTrackingData = useSelector(selectRoutePlanTrackingData)

  const start = useMemo(() => dateUtils.parseDate(dateRange.minDate), [dateRange.minDate])
  const end = useMemo(() => dateUtils.parseDate(dateRange.maxDate), [dateRange.maxDate])
  const dates = useMemo(() => eachDayOfInterval({ start, end }), [start, end])

  const orderSteps = useMemo(
    () => orderStepIds.map(orderStepId => extOrderSteps[orderStepId]),
    [extOrderSteps, orderStepIds],
  )

  const totalServiceTime = useMemo(() => {
    let totalServiceTime = 0

    for (const extOrderStep of orderSteps) {
      totalServiceTime += extOrderStep.orderStep.serviceTimeSec
    }

    return totalServiceTime
  }, [orderSteps])

  // Compute closed route ids
  const closedRouteIds = useMemo(() => {
    const ids = new Set<string>()

    for (const extRouteId in routePlanTrackingData) {
      const trackingData = routePlanTrackingData[extRouteId]

      if (
        (trackingData.routeTrackingData?.closeOutTrackingData?.timeInSec ?? -1) === -1 &&
        (trackingData.routeTrackingData?.endData.sec ?? -1) === -1
      )
        continue

      ids.add(extRouteId)
    }

    return ids
  }, [routePlanTrackingData])

  return {
    today,
    dates,
    routes,
    vehicles,
    orderSteps,
    userConfig,
    closedRouteIds,
    totalServiceTime,
  }
}
