import type { GridValueGetterParams } from '@mui/x-data-grid-pro'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from '@/intl'

import { selectUserConfiguration } from '@/features/domain/user'
import { timeUtils } from '@/server-data'

export function useGetEligibilityValue() {
  const userConfig = useSelector(selectUserConfiguration)
  const { translate } = useIntl()

  return useCallback(
    (params: GridValueGetterParams) => {
      const fieldName = params.field.replace('formattedData.', '')
      const eligibility = params.row[fieldName]

      const eligibilityDate = eligibilityToString(eligibility, userConfig, translate)

      return getEligibilityLabel(eligibility, eligibilityDate, translate)
    },
    [userConfig, translate],
  )
}

function getEligibilityLabel(
  eligibility: uui.domain.server.rm.Eligibility,
  dateAsString: string,
  translate: uui.domain.intl.Translate,
) {
  const { type } = eligibility

  switch (type) {
    case 'any':
      return translate({ id: 'global.eligibility.any' })
    case 'on':
      return translate({
        id: 'global.eligibility.on',
        values: { date: dateAsString },
      })
    case 'by':
      return translate({
        id: 'global.eligibility.by',
        values: { date: dateAsString },
      })
  }
}

function eligibilityToString(
  eligibility: uui.domain.server.rm.Eligibility,
  userConfiguration: uui.domain.UserConfiguration,
  translate: uui.domain.intl.Translate,
) {
  function formatDate(dateAsString: string) {
    return timeUtils.formatDate(userConfiguration)(dateAsString)
  }

  switch (eligibility.type) {
    case 'on':
      return eligibility.dates.length > 0 ? eligibility.dates.map(formatDate).join('; ') : ''

    case 'by':
      return formatDate(eligibility.date)

    default:
    case 'any':
      return translate({ id: 'global.any' })
  }
}
