import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { setListOptions, setOrdersFilter } from '@/features/domain/lists/actions'
import { selectOrderStepsListOptions } from '@/features/domain/lists'
import { useAppDispatch } from '@/store'

/**
 * Set the search' query of the list options.
 */
export function useSetQuery() {
  const listOptions = useSelector(selectOrderStepsListOptions)
  const dispatch = useAppDispatch()

  return useCallback(
    async (newValue: string) => {
      const request = await dispatch(
        setOrdersFilter({ ...listOptions, search: { ...listOptions.search, query: newValue } }),
      )
      return setListOptions.fulfilled.match(request)
    },
    [dispatch, listOptions],
  )
}
