import { useCallback } from 'react'

import { useResetEditingState, setCrudSelection, useListApi } from '@/atoms'
import { updateDriver, createDriver } from '@/features/domain/driver'
import { useNotification, useIsUnmounted } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useUpdateDriver(creating: boolean) {
  const { scrollTo } = useListApi('drivers')
  const stopEditing = useResetEditingState()
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const isUnmounted = useIsUnmounted()

  const onCreate = useCallback(
    async (driver: uui.domain.client.rm.Driver) => {
      try {
        const thunkResult = await dispatch(createDriver(driver))

        if (isUnmounted()) return

        if (createDriver.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
        setCrudSelection('drivers', [thunkResult.payload])
        scrollTo(thunkResult.payload)
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, scrollTo, toast, isUnmounted],
  )

  const onUpdate = useCallback(
    async (driver: uui.domain.client.rm.Driver) => {
      try {
        const thunkResult = await dispatch(updateDriver(driver))

        if (isUnmounted()) return

        if (!updateDriver.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, toast, isUnmounted],
  )

  return creating ? onCreate : onUpdate
}
