import type { PublicProps as Props } from './types'

import { useEditing } from './hooks/useEditing'
import { useFormInitialValues } from './hooks/useFormInitialValues'
import { TrackingPageView } from './TrackingPageView'
import { TrackingPageForm } from './TrackingPageForm'

export function TrackingPage(props: Props) {
  const { trackingAppData, updateTrackingAppData, inEditing, setEditingState } = props
  const { onCancel, onEdit } = useEditing(setEditingState)
  const initialValues = useFormInitialValues(trackingAppData)

  switch (inEditing) {
    case true:
      return (
        <TrackingPageForm
          formValues={initialValues}
          onCancel={onCancel}
          updateTrackingAppData={updateTrackingAppData}
        />
      )
    case false:
      return (
        <TrackingPageView
          formValues={initialValues}
          onEdit={onEdit}
          previewUrl={trackingAppData.testURL}
        />
      )
  }
}
