import type OlMap from 'ol/Map'

/**
 * Generate the coordinates to place the popup in the same screen position of the map.
 */
export function getMapScreenCoordinates(map: OlMap) {
  const target = map.getTarget()
  if (!target || typeof target === 'string') {
    return {
      screenX: Math.round(window.screenTop * 1.1),
      screenY: Math.round(window.screenLeft * 1.1),
      width: Math.round(window.innerWidth * 0.8),
      height: Math.round(window.innerHeight * 0.8),
    }
  }

  const targetBounds = target.getBoundingClientRect()

  return {
    width: targetBounds.width,
    height: targetBounds.height,
    screenX: window.screenX + targetBounds.left,
    screenY: window.screenY + targetBounds.top,
  }
}
