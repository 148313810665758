import OlMap from 'ol/Map'
import FullScreen from 'ol/control/FullScreen'

export const registerFullScreenControl = (map: OlMap) => {
  const uniqueId = 'rm-fullscreen'
  const controls = map.getControls()

  if (controls.get(uniqueId)) return

  const fullscreenControl = new FullScreen({ source: 'map-viewport' })
  fullscreenControl.set('uid', uniqueId)
  map.addControl(fullscreenControl)

  controls.set(uniqueId, fullscreenControl)
}
