import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectOrderSteps } from '@/features/domain/order'

export function useExecutedOrderStepsByDriver(
  ordersExecution?: uui.domain.client.rm.SchedulerRowData['ordersExecution'],
) {
  const extOrderSteps = useSelector(selectOrderSteps)
  return useMemo(() => {
    if (!ordersExecution) return

    return ordersExecution.executed.reduce<Record<string, string[]>>((acc, orderStepId) => {
      const extOrderStep = extOrderSteps[orderStepId]

      const { driverId } = extOrderStep.orderStep.trackingData

      if (!driverId) return acc

      acc[driverId] ||= []
      acc[driverId].push(orderStepId)

      return acc
    }, {})
  }, [ordersExecution, extOrderSteps])
}
