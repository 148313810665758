import { useCallback } from 'react'

import { updateVehiclesSettings } from '@/features/domain/vehicle'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useActions() {
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const onUpdateVehiclesSettings = useCallback(
    async (
      vehicleIds: string[],
      exception: string,
      settings: uui.domain.server.rm.VehicleSettings,
    ) => {
      try {
        const result = await dispatch(updateVehiclesSettings({ vehicleIds, settings, exception }))

        if (!updateVehiclesSettings.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, toast],
  )

  return {
    onUpdateVehiclesSettings,
  }
}
