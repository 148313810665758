import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Content } from './Content'

type Props = {
  driverIds: string[]
  validationResult?: uui.domain.server.rm.DeleteDriverValidationResults
}

export function Root(props: Props) {
  return (
    <Modal modalId={modalId}>
      <Content driverIds={props.driverIds} validationResult={props.validationResult} />
    </Modal>
  )
}
