import type { FormFields } from '../formFields'

import { Box, Stack, Typography } from '@mui/material'
import { Checkbox } from '@workwave-tidal/tidal/form-ui'

import { useTexts } from './useTexts'

export function DynamicLoadsCf() {
  const texts = useTexts()

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h6">{texts.title}</Typography>
        <Typography variant="caption">{texts.description}</Typography>
      </Box>
      <Box>
        <Checkbox<'enableDynamicLoads', FormFields>
          name="enableDynamicLoads"
          testId="enableDynamicLoads"
          label={texts.loads}
          validateOn="change blur"
        />
        <Checkbox<'enableDynamicCustomFields', FormFields>
          name="enableDynamicCustomFields"
          testId="enableDynamicCustomFields"
          label={texts.customFields}
          validateOn="change blur"
        />
      </Box>
    </Stack>
  )
}
