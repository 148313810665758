import { DriverBehaviors } from './DriverBehaviors'
import { SpeedLimit } from './SpeedLimit'
import { MaxSpeedLimit } from './MaxSpeedLimit'
import { IdleStartLimit } from './IdleStartLimit'
import { Badges } from './Badges'
import { MaintenanceMileage } from './MaintenanceMileage'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function GpsData(props: Props) {
  const { unifiedVehicle } = props

  const capabilities =
    unifiedVehicle.isPhysicalDevice && unifiedVehicle.trackingProvider === 'WWGPS'
      ? unifiedVehicle.device.capabilities
      : []

  const hasDriverBehaviorCapability = capabilities.includes('drb-threshold')
  const hasIdleThresholdCapability = capabilities.includes('idle-threshold')
  const speedMonitor =
    unifiedVehicle.isPhysicalDevice && unifiedVehicle.trackingProvider === 'WWGPS'
      ? unifiedVehicle.speedMonitor
      : undefined
  const driverBehaviors =
    unifiedVehicle.isPhysicalDevice && unifiedVehicle.trackingProvider === 'WWGPS'
      ? unifiedVehicle.driverBehaviors
      : undefined

  const gpsTags = unifiedVehicle.allowGps ? unifiedVehicle.gpsTags : []
  const idleStartThreshold =
    unifiedVehicle.isPhysicalDevice && unifiedVehicle.trackingProvider === 'WWGPS'
      ? unifiedVehicle.idleStartThreshold
      : undefined

  const deviceConfiguration =
    unifiedVehicle.isPhysicalDevice && unifiedVehicle.trackingProvider === 'WWGPS'
      ? unifiedVehicle.deviceConfiguration
      : undefined

  return (
    <>
      <Badges gpsTags={gpsTags} />
      {!!driverBehaviors && hasDriverBehaviorCapability && (
        <DriverBehaviors driverBehaviors={driverBehaviors} />
      )}
      {hasIdleThresholdCapability && <IdleStartLimit idleStartThreshold={idleStartThreshold} />}
      {!!speedMonitor && <SpeedLimit speedMonitor={speedMonitor} />}
      {!!driverBehaviors && <MaxSpeedLimit driverBehaviors={driverBehaviors} />}
      {!!deviceConfiguration && <MaintenanceMileage deviceConfiguration={deviceConfiguration} />}
    </>
  )
}
