import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    on: translate({ id: 'global.on' }),
    off: translate({ id: 'global.off' }),
    routePaths: translate({ id: 'global.routePaths' }),
    noRoutePaths: translate({ id: 'globalStyles.routePaths.noRoutePaths' }),
  }))

  return api
}
