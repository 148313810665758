import type { SearchableListTypes } from '../primitives/SearchableList'
import type { Props } from './typings'

import { PureComponent } from 'react'

import { Block, Label, FieldMessage, SearchableList } from '@/forms-legacy'

import RestoreFieldButton from '../primitives/RestoreFieldButton'

import { processListSelection } from './utils/processListSelection'
import { toggleIds } from './utils/toggleIds'

interface ModifierKeys {
  altKey?: boolean
  ctrlKey?: boolean
  metaKey?: boolean
  shiftKey?: boolean
}

export default class SearchableListField<
  T extends SearchableListTypes.ListValue,
> extends PureComponent<Props<T>> {
  private handleOnChangeSelection = (
    selection: T[],
    selectionLimit: number,
    modifiers: ModifierKeys = {},
    __: boolean = false,
    forceSelection: boolean = false,
  ) => {
    const {
      wrappedInputProps: { values },
      formProps: {
        input: { onChange, value: selectedIds },
      },
    } = this.props

    if (forceSelection) {
      onChange(selection.map(({ id }: T): string => id).sort())
      return
    }

    const processedSelection = processListSelection<T, string>(
      values,
      selectedIds,
      selection,
      ({ id }: T): string => id,
      modifiers,
    )

    const ids: string[] = processedSelection.values

    const clippedIds: string[] =
      selectionLimit > 0 && ids.length > selectionLimit ? ids.slice(0, selectionLimit) : ids

    const isModifierActive = Object.values(modifiers).some(mod => !!mod)

    if (isModifierActive) {
      onChange(clippedIds.sort())
    } else {
      onChange(toggleIds<string>(clippedIds, selectedIds).sort())
    }
  }

  render() {
    const {
      label,
      name,
      info,
      half = false,
      wrappedInputProps,
      formProps: {
        input: { onChange, value },
        meta: { error, dirty, initial },
      },
      testid,
    } = this.props

    return (
      <Block half={half} error={!!error} dirty={!!dirty} data-testid={testid} data-trackid={testid}>
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <SearchableList<T>
          {...wrappedInputProps}
          selectedIds={value}
          onChange={this.handleOnChangeSelection}
        />
        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }
}
