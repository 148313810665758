import type { RegionReadonlyValue } from '../../../types'

import { Box } from '@mui/material'

import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
  Dot,
} from '@/forms-legacy'

import { normalizeServerColorString } from '@/local/server-data/utils/colors'

import { useCollapsibleState } from '../../../../../atoms/collapsibleState'
import { CollapseButton } from '../../../../../components/CollapseButton'

import { useTexts } from '../useTexts'

interface Props {
  regions: RegionReadonlyValue[]
}

export function Regions(props: Props) {
  const { regions } = props

  const [collapsed, toggleCollapsed] = useCollapsibleState('regions')
  const texts = useTexts()
  const regionsCount = regions.length

  if (regionsCount === 0) {
    return (
      <ReadonlyBlock>
        <ReadonlyLabel primary empty>
          {texts.noRegions}
        </ReadonlyLabel>
      </ReadonlyBlock>
    )
  }

  return (
    <ReadonlyBlock>
      <CollapseButton collapsed={collapsed} toggleCollapsed={toggleCollapsed}>
        {texts.availableRegions(regionsCount, regionsCount > 0)}
      </CollapseButton>
      {!collapsed &&
        regions.map(region => {
          const dotColor = normalizeServerColorString(region.color)

          return (
            <ReadonlyField key={region.id}>
              <ReadonlyFieldRow>
                <Box width={14} height={14} marginRight={1}>
                  <Dot innerColor={dotColor} outerColor={dotColor} />
                </Box>
                <ReadonlyFieldLabel>{region.name}</ReadonlyFieldLabel>
                <ReadonlyLabel small>{region.value}</ReadonlyLabel>
              </ReadonlyFieldRow>
            </ReadonlyField>
          )
        })}
    </ReadonlyBlock>
  )
}
