import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectGpsTrackingProvider,
  selectUserConfiguration,
  selectUserType,
} from '@/features/domain/user'

export function useCanIntegrate() {
  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const { userType } = useSelector(selectUserConfiguration)
  const userRole = useSelector(selectUserType)

  return useMemo(() => {
    const canIntegrate = userType.startsWith('rm360') && trackingProvider === 'telematics'
    return { canIntegrate, trackingProvider, userType, userRole }
  }, [trackingProvider, userType, userRole])
}
