import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMainSelection, setMainSelection } from '@/atoms'

import { RejectError } from '../../typings'

/**
 * Set breadcrumb time range
 *
 * @private
 */
export const setBreadcrumbTimeRange = createAsyncThunk<
  // Return type of the breadcrumbIds creator
  void,
  // First argument to the breadcrumbIds creator
  uui.domain.BreadcrumbTimeRange,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/breadcrumb/setBreadcrumbTimeRange', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/breadcrumb/setBreadcrumbTimeRange', {
      category: 'rpc',
      type: 'rpc/breadcrumb/setBreadcrumbTimeRange',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    //Verify if we have to breadcrumb stale selections after the update
    if (payload !== 'live') {
      const selection = getMainSelection().breadcrumbs

      switch (selection.length) {
        case 0:
          return

        case 1:
          const timestamp = parseInt(selection[0].split('@')[1])

          if (timestamp < payload[0] || timestamp > payload[1]) {
            setMainSelection('breadcrumbs', [])
            thunkApi.extra.notification.info(
              thunkApi.extra.intl.translate({ id: 'global.selectionUpdated' }),
            )
          }

          break

        default:
          setMainSelection('breadcrumbs', [])
          break
      }
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {setBreadcrumbTimeRange}`,
      { tags: ['rpc', 'breadcrumb'], info: { payload, error } },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `SetBreadcrumbTimeRange breadcrumb failed`,
      error,
    })
  }
})
