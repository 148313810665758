import type { LocationEditorProps } from '../typings'

import { produce } from 'immer'
import Circle from 'ol/geom/Circle'
import { useCallback, useEffect, useRef } from 'react'

import { gis } from '@/server-data'
import { useIsUnmounted } from '@/hooks'
import {
  fitMapToGeometry,
  getLocationPin,
  setLocationPinToSearchResult,
  updateLocationPin,
} from '@/map'
import { useDepotsAsMap } from './useDepotsAsMap'

export function useConnectDropDownToLocationPin(options: LocationEditorProps) {
  const { pinId, onConnectedPlaceChanged, connectedPlaceId } = options
  const isUnmounted = useIsUnmounted()

  const depotsAsMap = useDepotsAsMap()

  const api = useRef({ onConnectedPlaceChanged, connectedPlaceId, depotsAsMap, isUnmounted })
  useEffect(() => {
    api.current = { onConnectedPlaceChanged, connectedPlaceId, depotsAsMap, isUnmounted }
  }, [onConnectedPlaceChanged, connectedPlaceId, depotsAsMap, isUnmounted])

  return useCallback(
    (asset: uui.domain.client.gps.SearchOnMapItem, _location: uui.domain.client.Location) => {
      // the callback could be invoked asynchronously after the component has been unmount
      if (api.current.isUnmounted()) return

      const { onConnectedPlaceChanged, connectedPlaceId, depotsAsMap } = api.current
      const pin = getLocationPin(pinId, true)

      // TODO: do we really need to verify if the dropdown is closed
      // if (!open) return
      if (!pin) return

      let updatedLatLng: uui.domain.LatLng | undefined

      switch (asset.type) {
        case 'place':
          updatedLatLng = asset.place.latLng
          setLocationPinToSearchResult(pinId, asset.place)
          break

        case 'depot':
        case 'address':
          updatedLatLng = asset.location.latLng
          const depots = depotsAsMap[asset.location.rawLatLngAsString] ?? []

          if (depots.length > 1) {
            // JOURNAL: Log into Journal situations where multiple Depots share the same location
            // TODO: should we add specific UX solutions to support it?
            if (process.env.NODE_ENV === 'development') {
              console.warn(`Multiple depots at coordinates: ${asset.id}`, depots)
            }
          }

          const depot = depots[0]
          const nextAddress = depot ? depot.name : asset.location.geoAddress

          updateLocationPin(pinId, prevPin =>
            produce(prevPin, draft => {
              if (!draft.pin.location) {
                if (process.env.NODE_ENV === 'development') {
                  throw new Error(
                    `Trying to update a LocationPin: ${pinId} without a valid Location`,
                  )
                }
              } else {
                if (asset.type === 'depot' || asset.type === 'address') {
                  draft.pin.location = { ...asset.location }
                }

                draft.pin.location.address = nextAddress
                draft.pin.location.geoAddress = nextAddress

                if (updatedLatLng) {
                  draft.pin.latLng = updatedLatLng
                  draft.pin.location.latLng = updatedLatLng
                }
              }
            }),
          )
          break
      }

      // Update connect place when required
      const nextConnectedPlaceId = asset.type === 'depot' ? asset.id : undefined
      if (onConnectedPlaceChanged && connectedPlaceId !== nextConnectedPlaceId) {
        onConnectedPlaceChanged(nextConnectedPlaceId)
      }

      // try to fit the new selection in the current map viewport
      // the action will be conservative, avoiding any zoom/pan if the provided
      // geometry is already visible
      if (updatedLatLng) {
        const radius = pin.pin.radius ?? 0
        const coordinates = gis.fromLatLngToCoordinate(updatedLatLng)
        const geometry = new Circle(coordinates, radius)

        fitMapToGeometry(geometry.getExtent(), { preventIfVisible: true })
      }
    },
    [pinId],
  )
}
