import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({
      id: 'sms4.settings.communication.notifications.confirmToggleModal.header.title',
    }),

    subtitle: (goingToEnable: boolean) =>
      translate({
        id: goingToEnable
          ? 'sms4.settings.communication.notifications.confirmToggleModal.header.subtitle.enable'
          : 'sms4.settings.communication.notifications.confirmToggleModal.header.subtitle.disable',
      }),
  }))

  return api
}
