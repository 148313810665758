import type { FormFields, FormErrors } from '../../../../formFields'

import { useEffect } from 'react'

import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { useTexts } from './useTexts'

export function useValidateTags() {
  const formApi = useFormApi<FormFields, FormErrors>()
  const texts = useTexts()

  useEffect(() => {
    formApi.addValidation('tags', api => {
      const tagsIn = api.getField('tagsIn').value
      const tagsOut = api.getField('tagsOut').value

      const errorTagsIn = Object.values(tagsIn.tags).some(t => t.status !== 'deleted' && t.warning)
      const errorTagsOut = Object.values(tagsOut.tags).some(
        t => t.status !== 'deleted' && t.warning,
      )

      const error = errorTagsIn || errorTagsOut

      const tagsInOverlapping = {
        id: 'tagsInOverlapping',
        message: texts.overlappingTags,
        field: 'tagsIn',
        priority: 10,
      }

      const tagsOutOverlapping = {
        id: 'tagsOutOverlapping',
        message: texts.overlappingTags,
        field: 'tagsOut',
        priority: 10,
      }

      return {
        tagsInOverlapping: error ? tagsInOverlapping : null,
        tagsOutOverlapping: error ? tagsOutOverlapping : null,
      } as Record<string, FormErrors>
    })

    return () => {
      formApi.removeValidation('tags')
    }
  }, [formApi, texts])
}
