import styled from 'styled-components'

type Props = {
  bold?: boolean
}

const LoadLabel = styled.p<Props>`
  margin: 0;
  font-size: ${p => (p.bold ? p.theme.fonts.$p3 : p.theme.fonts.$p2)};
  font-weight: ${p => p.theme.weights.$semiBold};
  color: ${p => p.theme.colors.$nightRider};
`
LoadLabel.displayName = 'LoadLabel'
export default LoadLabel
