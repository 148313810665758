export function computeReportPermission(
  physicalDeviceIds: string[],
  isLoadingGpsData: boolean,
  userType: uui.domain.UserType,
  trackingProvider: uui.domain.server.gps.TrackingProvider,
  deviceIds?: string[],
) {
  // Hide the button if the tracking provider is not WWGPS
  if (trackingProvider !== 'WWGPS') return 'hidden'

  // Hide the button if the user is a courier
  if (userType === 'courier') return 'hidden'

  // Disable the button if is loading gps data
  if (isLoadingGpsData) return 'disabledLoadingGpsData'

  // Disable the button if got no physical devices
  if (physicalDeviceIds.length === 0) {
    if (!deviceIds || deviceIds.length === 0) return 'disabledNoPhysicalDevice'

    return 'disabledNoDevice'
  }

  return 'enabled'
}
