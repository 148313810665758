import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  selectRegionsListOptions,
  setListOptions,
  selectRegionsListStructure,
} from '@/features/domain/lists'
import { useEditingStateWithCtx } from '@/atoms'
import { useAppDispatch } from '@/store'

export function useSorter() {
  const listOptions = useSelector(selectRegionsListOptions)
  const listStructure = useSelector(selectRegionsListStructure)
  const { editing } = useEditingStateWithCtx('region')
  const dispatch = useAppDispatch()

  const [field, setField] = useState(() => listOptions.sort[0].field)
  const [direction, setDirection] = useState(() => listOptions.sort[0].direction)

  useEffect(() => {
    if (!listOptions.sort) return
    if (listOptions.sort[0].field === field && listOptions.sort[0].direction === direction) return

    dispatch(
      setListOptions({
        category: 'regions',
        options: {
          ...listOptions,
          sort: [{ ...listOptions.sort[0], field, direction }],
        },
      }),
    )
  }, [dispatch, field, direction, listOptions])

  return {
    editing,
    field,
    setField,
    direction,
    setDirection,
    noEntries: listStructure.totalItems === 0,
  }
}
