import { SchedulerPro, type SchedulerProConfig } from '@bryntum/schedulerpro'

import { type ControlledNavigateFunction } from '@/routing'
import { type MovePairedOrdersPayload } from '@/components/modals/MovePairedOrdersModal'

import { createSchedulerConfig } from './createSchedulerConfig'

export function createScheduler(
  appendTo: string,
  navigate: ControlledNavigateFunction<uui.routing.Routing | uui.routing.Setup>,
  showMovePairedOrdersModal: (payload: MovePairedOrdersPayload) => void,
  onHeaderMenuBeforeShow: SchedulerProConfig['onHeaderMenuBeforeShow'],
) {
  const scheduler = new SchedulerPro(
    createSchedulerConfig(appendTo, navigate, showMovePairedOrdersModal, onHeaderMenuBeforeShow),
  )

  return scheduler
}
