import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { unproxify } from '@/utils'

import { RejectError } from '../../../typings'

interface Params {
  mapMode?: uui.domain.actions.rpc.ui.SetCustomMapStyleAction['mapMode']
  customStyle: uui.domain.actions.rpc.ui.SetCustomMapStyleAction['customStyle']
}

/**
 * Create a new Driver.
 *
 * @private
 */
export const setCustomMapStyle = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Params,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('ui/mapStyles/setCustomMapStyle', async (params, thunkApi) => {
  try {
    const { customStyle, mapMode } = params

    customStyle.ids = unproxify(customStyle.ids)

    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/ui/mapStyles/setCustomMapStyle', {
      mapMode,
      customStyle,
      category: 'rpc',
      type: 'rpc/ui/mapStyles/setCustomMapStyle',
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        error: result,
        type: 'rpcFailure',
        message: result.errorMessage,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {setCustomMapStyle}`,
      { tags: ['rpc', 'ui', 'map', 'mapStyles'], info: params },
      'error',
    )

    return thunkApi.rejectWithValue({
      error,
      type: 'unknown',
      message: `Failed to update a Map Style`,
    })
  }
})
