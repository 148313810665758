import { Component } from 'react'

import { Props } from './typings'

interface State<T> {
  view: T
}

const setView =
  <T>(view: T) =>
  () => ({
    view,
  })

export default class ViewManager<T> extends Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props)

    this.state = {
      view: props.view,
    }
  }

  componentDidUpdate(prevProps: Props<T>) {
    const { view } = this.props
    const { view: prevView } = prevProps
    const { view: currentView } = this.state

    if (prevView !== view && currentView !== view) {
      this.setState(setView(view))
    }
  }

  private handleOnChangeView = (view: T): void => {
    this.setState(setView(view))
  }

  render() {
    const { render } = this.props
    const { view } = this.state

    return render(view, this.handleOnChangeView)
  }
}
