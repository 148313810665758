import type Geometry from 'ol/geom/Geometry'

import Feature from 'ol/Feature'
import Polygon from 'ol/geom/Polygon'

import { setTrafficAreaFeatureMetadata } from '../trafficAreaFeatureMetadata'
import { getTrafficAreaFeatureStyle } from './getTrafficAreaFeatureStyle'

/**
 * Create a new Traffic area Feature
 */
export function createTrafficAreaFeature(
  marker: uui.domain.ui.map.markers.Area,
  mapStyles: uui.domain.ui.map.markers.MapStyles['trafficProfile'],
  selected: boolean = false,
): Feature<Geometry> {
  const { id, points } = marker

  const feature = new Feature({ geometry: new Polygon([points]) })

  // use marker ID (areaId) as unique ID
  feature.setId(id)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setTrafficAreaFeatureMetadata(feature, 'type', 'trafficProfile')

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['trafficProfile']['mode'] =
    marker.mode ?? mapStyles.mode
  setTrafficAreaFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getTrafficAreaFeatureStyle(marker, mode, selected))

  return feature
}
