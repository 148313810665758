import { type SVGProps } from 'react'
import { clsx } from '@/utils'

type Props = SVGProps<SVGSVGElement>

export function EventBusy(props: Props) {
  const { className, ...rest } = props

  return (
    <svg
      className={clsx(className, 'o-scheduler-svg-icon')}
      focusable="false"
      aria-hidden="true"
      {...rest}
      viewBox="0 0 24 24"
    >
      <path d="m9.31 17 2.44-2.44L14.19 17l1.06-1.06-2.44-2.44 2.44-2.44L14.19 10l-2.44 2.44L9.31 10l-1.06 1.06 2.44 2.44-2.44 2.44L9.31 17zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z" />
    </svg>
  )
}
