import { Entry } from '../Entry'
import { JiraLinks } from './JiraLinks'

const ids = [
  10752, 10950, 10952, 10936, 10935, 10938, 10937, 10829, 10796, 10752, 10915, 10921, 10708, 10750,
]

export function Entry20210616() {
  return (
    <Entry title="06/18/2021 - v3.0.0-beta-7 fixes">
      Full list of Jira issues fixed:
      <br />
      <br />
      <JiraLinks ids={ids} />
      <br />
      <br />
    </Entry>
  )
}
