import { Box } from '@mui/material'

import { Load } from './components/Load'

import { useGetLoads } from './hooks/useGetLoads'

interface Props {
  extRoute: uui.domain.client.rm.ExtendedRoute
}

const multiColumnContainerStyle = {
  height: 44,
  columnGap: 0,
  columnFill: 'auto',
  columnCount: 5,
  columnWidth: 150,
}

export function LoadsBlock(props: Props) {
  const { extRoute } = props

  const loads = useGetLoads(extRoute)

  if (!loads) return null

  const { orders, vehicle } = loads

  return (
    <Box
      sx={multiColumnContainerStyle}
      data-trackid="navigo-routes-overview-details-single-routeStats-loadsBlock"
      data-testid="navigo-routes-overview-details-single-routeStats-loadsBlock"
      marginLeft={3}
    >
      {Object.entries(orders).map(([name, value], index) => (
        <Load
          key={name}
          name={name}
          value={value}
          showTitle={index === 0}
          vehicleLoad={vehicle[name]}
        />
      ))}
    </Box>
  )
}
