export type TagsListTexts =
  | 'addTag'
  | 'suggestedListTitle'
  | 'searchPlaceholder'
  | 'loadMoreTag'
  | 'suggestionsListMessage'
  | 'tagsListMessage'
  | 'noResult'
  | 'maxCountReached'
  | 'noTags'
  | 'bulkTitle'
  | 'addToAll'
  | 'remove'
  | 'toggleVisibility'

/**
 * Render function used to support localization in generic components
 *
 * @param field Identifier of the text to render
 * @param props Properties of the LocationField instance
 */
export type RenderTagsListTexts = (
  field: TagsListTexts,
  props: PublicProps,
  collapsed?: boolean,
  count?: number,
  filterText?: string,
) => string

export interface PublicPropsBase {
  value: string[]
  onChange: (tags: string[], deletedKeys?: string[]) => void
  suggestions: string[]
  renderText: RenderTagsListTexts

  isBanList?: boolean
  maxCount?: number
  enterToAdd?: boolean
  caseSensitive?: boolean
}

export interface PublicPropsSingle extends PublicPropsBase {
  type: 'single'
}

export interface PublicPropsBulk extends PublicPropsBase {
  total: number
  partial: Record<string, number>
  forceDisableAddTag: boolean

  partialTagsLabel: string
  onRemovePartialTags: (tags: string[]) => void
  onAddPartialTags: (tags: string[]) => void
  type: 'bulk'
}

export type PublicProps = PublicPropsSingle | PublicPropsBulk

export type Props = PublicProps & {
  filter: string
  onChangeFilter: (filter: string) => void
  onAddTag: (tag: string) => void
  onRemoveTag: (tag: string) => void
}

export enum Step {
  collapsed,
  search,
}
