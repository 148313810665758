import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
  ReadonlyFieldArrow,
} from '@/forms-legacy'

import { useTexts } from '../useTexts'

interface Props {
  tags: string[]
}

export function Tags(props: Props) {
  const { tags } = props

  const texts = useTexts()

  const empty = tags.length === 0

  return (
    <ReadonlyBlock half>
      <ReadonlyLabel empty={empty}>{texts.tags}</ReadonlyLabel>
      {tags.map(tag => {
        return (
          <ReadonlyFieldArrow key={tag}>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{tag}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyFieldArrow>
        )
      })}
    </ReadonlyBlock>
  )
}
