import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

/**
 * Load Order step Notifications
 *
 * @private
 */
export const loadOrderStepNotifications = createAsyncThunk<
  // Return type of the payload creator
  uui.domain.actions.rpc.order.LoadOrderStepNotificationsActionResult['notifications'],
  // First argument to the payload creator
  uui.domain.actions.rpc.order.LoadOrderStepNotificationsAction['payload'],
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/order/loadOrderStepNotifications', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/order/loadOrderStepNotifications', {
      category: 'rpc',
      type: 'rpc/order/loadOrderStepNotifications',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    return result.notifications
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {loadOrderStepNotifications}`,
      { tags: ['rpc', 'order'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Load order step notifications for ${payload.ordStepId.orderId} failed`,
      error,
    })
  }
})
