import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState({
    cancelButtonTitle: translate({ id: 'demoTools.importNewTerritory.modal.cancelButton.title' }),
    changeFileButtonTitle: translate({
      id: 'demoTools.importNewTerritory.modal.changeFileButton.title',
    }),
    commandButtonTitle: translate({ id: 'demoTools.importNewTerritory.modal.commandButton.title' }),
    failedDataFetchingWarningCardTitle: translate({
      id: 'demoTools.importNewTerritory.modal.failedDataFetching.warningCard.title',
    }),
    failedDataFetchingWarningCardDescription: translate({
      id: 'demoTools.importNewTerritory.modal.failedDataFetching.warningCard.description',
    }),
    genericError: translate({ id: 'demoTools.importNewTerritory.error.generic' }),
    lastUpdate: (lastUpdate: string) =>
      translate({ id: 'demoTools.importNewTerritory.modal.lastUpdate', values: { lastUpdate } }),
    modalTitle: translate({ id: 'demoTools.importNewTerritory.modal.title' }),
    modalSubTitle: translate({ id: 'demoTools.importNewTerritory.modal.subtitle' }),
    territoryFileTitle: translate({
      id: 'demoTools.importNewTerritory.modal.territory.file.title',
    }),
    territoryNameTitle: translate({
      id: 'demoTools.importNewTerritory.modal.territory.name.title',
    }),
    transactionWarningCardTitle: translate({
      id: 'demoTools.importNewTerritory.modal.transaction.warningCard.title',
    }),
    transactionWarningCardDescription: translate({
      id: 'demoTools.importNewTerritory.modal.transaction.warningCard.description',
    }),
  })

  return api
}
