import type { ProviderProps } from './typings'

import { useSelector } from 'react-redux'

import { useReadOnly } from '@/hooks'
import { selectTerritory } from '@/features/domain/territory'
import { selectUserAccountPreferences } from '@/features/domain/user'

import { useResetEditingState, useEnvironmentQa } from '@/atoms'

import { EditExecutionEvents } from './EditExecutionEvents'
import { useExecutionEventMarkers } from './hooks/useExecutionEventMarkers'
import { useOnUpdateExecutionEvents } from './hooks/useOnUpdateExecutionEvents'

export function EditExecutionEventsProvider(props: ProviderProps) {
  const { order } = props

  const qa = useEnvironmentQa()
  const territory = useSelector(selectTerritory)
  const accountPreferences = useSelector(selectUserAccountPreferences)

  const { readonly } = useReadOnly()
  const resetEditingState = useResetEditingState()

  const { activePodMarker, onSetPodOnMap } = useExecutionEventMarkers(order)
  const { onUpdateExecutionEvents, submitting } = useOnUpdateExecutionEvents()

  return (
    <EditExecutionEvents
      tab={props.tab}
      order={props.order}
      qa={qa}
      territory={territory}
      submitting={submitting}
      onCancel={resetEditingState}
      timezone={territory.timeZoneCode}
      activePodMarker={activePodMarker}
      accountPreferences={accountPreferences}
      planLocked={readonly}
      updateExecutionEvent={onUpdateExecutionEvents}
      updateExecutionEventPodMarkers={onSetPodOnMap}
      rescheduleBehavior={territory.rescheduleBehavior}
      workingDayStartSec={territory.workingDayStartSec}
      allowBarcodes={territory.licensingLimits.trackedVehicles > 0}
    />
  )
}
