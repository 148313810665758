import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'scheduler.popContent.break.title' }),
    idleStart: translate({ id: 'scheduler.popContent.chip.idleStart' }),
    idleDuration: translate({ id: 'scheduler.popContent.chip.idleDuration' }),
    idleEnd: translate({ id: 'scheduler.popContent.chip.idleEnd' }),
  }))

  return api
}
