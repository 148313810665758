import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Redo(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path
          d="M3.81818182,0 L0,3.81818182 L3.81818182,7.63636364 L3.81818182,5.09090909 L8.27272727,5.09090909 C10.0450789,5.09090909 11.4545455,6.50037564 11.4545455,8.27272727 C11.4545455,10.0450789 10.0450789,11.4545455 8.27272727,11.4545455 L6.36363636,11.4545455 C5.9046452,11.4480542 5.47771463,11.6892049 5.24632472,12.0856564 C5.01493481,12.4821078 5.01493481,12.9724377 5.24632472,13.3688891 C5.47771463,13.7653405 5.9046452,14.0064913 6.36363636,14.0001278 L8.27272727,14.0001278 C11.2474282,14.0001278 13.6379638,11.6721952 13.9005682,8.76242882 C13.9657423,8.60736802 13.9995373,8.44092745 14,8.27272727 C14,5.12471527 11.4207393,2.54545455 8.27272727,2.54545455 L3.81818182,2.54545455 L3.81818182,0 Z"
          transform="translate(7.000000, 7.000064) scale(-1, 1) translate(-7.000000, -7.000064) "
        ></path>
      </svg>
    </IconContainer>
  )
}
