import { useSelector } from 'react-redux'

import { selectBreadcrumbsMapStyles, selectCalendarRange } from '@/features/domain/ui'

export function useBreadcrumbsStatus(devices: uui.domain.client.gps.wwgps.DeviceInfo[]) {
  const mapStyles = useSelector(selectBreadcrumbsMapStyles)
  const calendarRange = useSelector(selectCalendarRange)

  if (devices.length !== 1 || calendarRange.minDate !== calendarRange.maxDate) return 'off'

  const device = devices[0]

  return mapStyles.custom[device.deviceId]
}
