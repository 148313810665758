import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfiguration, updateGpsUserConfiguration } from '@/features/domain/user'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useTexts } from './useTexts'

type FormatsData = {
  distanceFormat: uui.domain.DistanceFormat
  timeFormat: uui.domain.client.TimeFormat
  dateFormat: uui.domain.DateFormat
}

export function useGpsFormatsData() {
  const userConfiguration = useSelector(selectUserConfiguration)
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  const formats = useMemo<FormatsData>(() => {
    return {
      distanceFormat: userConfiguration.distanceFormat,
      dateFormat: userConfiguration.dateFormat,
      timeFormat: userConfiguration.timeFormat,
    }
  }, [userConfiguration])

  const updateLanguageAndFormats = useCallback(
    async (data: FormatsData) => {
      try {
        const thunkResult = await dispatch(updateGpsUserConfiguration(data))

        if (!updateGpsUserConfiguration.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }
        toast.success(texts.updateGpsFormatsSuccess, { autoClose: 5000 })

        return true
      } catch (e) {
        toast.error(texts.updateGpsFormatsError, { autoClose: 5000 })
      }

      return false
    },
    [dispatch, toast, texts],
  )

  return [formats, updateLanguageAndFormats] as const
}
