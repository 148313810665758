import { useMemo } from 'react'

import { getRecurrenceLabel } from '@/server-data'
import { intl } from '@/intl'

import { useTexts } from './useTexts'

export function useDriverAssignmentInfo(recurrence: uui.domain.rm.Recurrence | null) {
  const texts = useTexts()

  return useMemo(() => {
    const label = !!recurrence
      ? getRecurrenceLabel(recurrence.type, recurrence.value, intl.translate)
      : ''
    const description = !!recurrence ? texts.recurrenceDescription(recurrence.type) : ''

    return {
      label,
      description,
    }
  }, [texts, recurrence])
}
