import { Box, FormHelperText, LinearProgress, Typography } from '@mui/material'
import { differenceInDays } from 'date-fns'
import { useFormatDate } from '@/hooks'

import { useTexts } from './useTexts'

interface Props {
  callCredits: number
  smsCount: number
  smsLimit: number
  planName: string
  expireDate: Date
}

export function ConsumptionCounter(props: Props) {
  const { planName, smsCount, callCredits, smsLimit, expireDate } = props
  const expireDateAsString = useFormatDate(expireDate)
  const texts = useTexts()

  const smsPercentage = Math.round((smsCount * 100) / smsLimit)
  const callsPercentage = Math.round((callCredits * 100) / smsLimit)
  const usagePercentage = smsPercentage + callsPercentage
  const daysToExpire = differenceInDays(expireDate, new Date()) + 1

  let progressBarColor: 'primary' | 'warning' | 'error' = 'primary'

  if (usagePercentage >= 100) progressBarColor = 'error'
  else if (usagePercentage >= 75) progressBarColor = 'warning'

  return (
    <Box marginBottom={2}>
      <Typography>{texts.planUsage(planName)}</Typography>

      <Box paddingTop={2} paddingBottom={2} display="flex" alignItems="center">
        <Box width="100%" marginRight={1}>
          <LinearProgress variant="determinate" value={usagePercentage} color={progressBarColor} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="text.secondary">
            {`${usagePercentage}%`}
          </Typography>
        </Box>
      </Box>

      <Typography>
        {texts.smsConsumption(smsCount.toLocaleString(), `${smsPercentage}%`)}
      </Typography>
      <Typography>
        {texts.callsConsumption(callCredits.toLocaleString(), `${callsPercentage}%`)}
      </Typography>
      <FormHelperText>{texts.expiring(expireDateAsString, daysToExpire)}</FormHelperText>
    </Box>
  )
}
