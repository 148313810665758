import type { LazyCreateFormConfig, FormValues } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../../formFields'

import { useState } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '@/store'

import { selectTerritory } from '@/features/domain/territory'
import { useValidateEmail } from '@/hooks'

import { createValidations } from './validations/createValidations'
import { useTexts } from './useTexts'

export function useCreateFormConfig(
  overrideValues: FormValues<FormFields>,
): LazyCreateFormConfig<FormFields, FormErrors> {
  const texts = useTexts()
  const territory = useSelector(selectTerritory)
  const dispatch = useAppDispatch()

  const validateEmail = useValidateEmail()

  const [createFormConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    return () => ({
      // mode: 'strict',
      overrideValues,
      validations: createValidations(texts, territory.workingDayStartSec, dispatch, validateEmail),
    })
  })

  return createFormConfig
}
