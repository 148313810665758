import { useState } from 'react'
import { useIntl } from '@/intl'

type PlanDetails = { hasSubscription: false } | { hasSubscription: true; planName: string }

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'settings.messagingPlan.view.headerTitle' }),
    headerDescription: (planDetails: PlanDetails) =>
      planDetails.hasSubscription
        ? translate({
            id: 'settings.messagingPlan.view.headerDescription.sms',
            values: { planName: planDetails.planName },
          })
        : translate({ id: 'settings.messagingPlan.view.headerDescription.email' }),

    errorFetchingAccountData: translate({
      id: 'settings.messagingPlan.view.errorFetchingCommunicationPlanData',
    }),
  }))

  return api
}
