import { genericStyles } from '../../../genericStyles'

import { getLocationPinStyle } from './styles/getLocationPinStyle'
import { geofenceCircleStyle } from './styles/geofenceCircleStyle'
import { geofenceRadiusLineStyle } from './styles/geofenceRadiusLineStyle'
import { geofenceCenterStyle } from './styles/geofenceCenterStyle'
import { getGeofenceLabelStyle } from './styles/getGeofenceLabelStyle'
import { getLocationPinLabelStyle } from './styles/getLocationPinLabelStyle'
import { getGeofenceRadiusLabelStyle } from './styles/getGeofenceRadiusLabelStyle'

// const redPoint = new Style({ image: new Circle({ radius: 1, fill: new Fill({ color: 'red' }) }) })
export function getLocationPinFeatureStyle(pin: uui.domain.ui.map.LocationPin) {
  if (!pin) return genericStyles.hidden

  const {
    loading,
    hidden,
    pin: { type, status, radius },
    id,
  } = pin

  if (hidden) return genericStyles.hidden

  const locationPinStyle = getLocationPinStyle(type, loading ? 'loading' : status)

  // If isn't a Geofence Pin let's return the styles
  if (pin.id !== 'geofenceFormPin') return [locationPinStyle, getLocationPinLabelStyle(id)]

  return [
    locationPinStyle,
    geofenceCircleStyle,
    geofenceRadiusLineStyle,
    geofenceCenterStyle,
    getGeofenceLabelStyle(pin.info.source.type, pin.info.source.name),
    getGeofenceRadiusLabelStyle(radius),
  ]
}
