import {
  useState,
  MouseEvent as ReactMouseEvent,
  useCallback,
  MouseEventHandler,
  useRef,
} from 'react'

import useDidUpdate from './useDidUpdate'

export interface DragInfo {
  initialX: number
  initialY: number
  offsetX: number
  offsetY: number
}

const composeDragInfo = (
  initialX: number,
  initialY: number,
  x: number,
  y: number,
  persistentX: number,
  persistentY: number,
): DragInfo => {
  const iX = initialX + persistentX
  const iY = initialY + persistentY

  return {
    initialX: iX,
    initialY: iY,
    offsetX: x - iX,
    offsetY: y - iY,
  }
}

type UseMouseDrag = [
  DragInfo,
  boolean,
  MouseEventHandler<HTMLElement>,
  () => void,
  // eslint-disable-next-line prettier/prettier
  HTMLElement | undefined,
]

export default function useMouseDrag(persist: boolean = false): UseMouseDrag {
  const [dragging, setDragging] = useState(false)
  const [initialX, setInitialX] = useState(0)
  const [initialY, setInitialY] = useState(0)
  const [offsetX, setX] = useState(0)
  const [offsetY, setY] = useState(0)
  const target = useRef<HTMLElement | undefined>()
  const state = useRef({
    persistentX: 0,
    persistentY: 0,
    deltaX: 0,
    deltaY: 0,
  })

  useDidUpdate(() => {
    state.current = {
      ...state.current,
      deltaX: initialX - offsetX,
      deltaY: initialY - offsetY,
    }
  }, [initialX, initialY, offsetX, offsetY])

  const resetOffset = (): void => {
    setInitialX(offsetX)
    setInitialY(offsetY)
    setX(offsetX)
    setY(offsetY)
  }

  const handleMouseMove = (event: MouseEvent): void => {
    const { pageX, pageY } = event

    setDragging(true)
    setX(pageX)
    setY(pageY)
  }

  const handleMouseUp = useCallback((): void => {
    target.current = undefined

    setDragging(false)
    if (persist) {
      state.current.persistentX += state.current.deltaX
      state.current.persistentY += state.current.deltaY
    }
    setInitialX(0)
    setInitialY(0)
    setX(0)
    setY(0)

    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', handleMouseUp)
  }, [persist])

  const mouseHandler = useCallback(
    (event: ReactMouseEvent<HTMLElement>): void => {
      const { pageX, pageY } = event

      target.current = event.currentTarget

      setInitialX(pageX)
      setInitialY(pageY)
      setX(pageX)
      setY(pageY)

      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    },
    [handleMouseUp],
  )

  return [
    composeDragInfo(
      initialX,
      initialY,
      offsetX,
      offsetY,
      state.current.persistentX,
      state.current.persistentY,
    ),
    dragging,
    mouseHandler,
    resetOffset,
    target.current,
  ]
}
