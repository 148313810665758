import type Geometry from 'ol/geom/Geometry'

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { gis } from '@/server-data'

import { setPinFeatureMetadata } from '../../pinFeatureMetadata'
import { getInspectPinFeatureStyle } from './getInspectPinFeatureStyle'

export function createInspectPinFeature(
  inspectPin: uui.domain.ui.map.InspectPin,
): Feature<Geometry> {
  const { latLng } = inspectPin

  const lonLat = gis.fromLatLngToCoordinate(latLng)
  const feature = new Feature({ geometry: new Point(lonLat) })

  // use marker ID (depotId) as unique ID
  feature.setId('inspectPin')

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setPinFeatureMetadata(feature, 'type', 'pin')

  // expose the marker kind
  setPinFeatureMetadata(feature, 'kind', 'inspectPin')

  // ------------------------
  // Feature Style

  const style = getInspectPinFeatureStyle(inspectPin)
  feature.setStyle(style)

  return feature
}
