import type { CSSProperties } from 'react'

import { useMemo } from 'react'
import { makeStyles } from '@mui/styles'

import { IconButton } from '@/components/primitives/buttons'
import { DoubleArrow } from '@/icons'

interface Props {
  expanded: boolean
  onClick: () => void
}

const useClasses = makeStyles({
  expandButton: {
    transform: 'rotate(var(--icon-rotation))',
  },
})

function useStyles(expanded: boolean) {
  const classes = useClasses()

  const styles = useMemo(() => {
    return {
      expandButton: {
        '--icon-rotation': `${expanded ? 0 : -180}deg`,
      } as CSSProperties,
    } as const
  }, [expanded])

  return [classes, styles] as const
}

const testid = 'navigo-routes-overview-actions-expand'

export function ExpandButton(props: Props) {
  const { expanded, onClick } = props

  const [classes, styles] = useStyles(expanded)

  return (
    <IconButton onClick={onClick} data-testid={testid} data-trackid={testid}>
      <DoubleArrow
        size={10}
        color="$nightRider"
        style={styles.expandButton}
        className={classes.expandButton}
      />
    </IconButton>
  )
}
