import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { gis } from '@/server-data'
import { selectTerritoryCenter } from '@/features/domain/territory'

const MAX_DISTANCE_ALLOWED_M = 1000000

type EditableSegment = {
  start: uui.domain.LatLng
  end: uui.domain.LatLng
}

export function useCreateIsSegmentOutsideOfTerritory() {
  const territoryCenter = useSelector(selectTerritoryCenter)

  return useCallback(
    (editableSegment?: EditableSegment) => {
      if (!editableSegment) return false

      const distanceFromStart = gis.distanceBetween(territoryCenter, editableSegment.start)
      const distanceFromEnd = gis.distanceBetween(territoryCenter, editableSegment.end)

      return distanceFromStart > MAX_DISTANCE_ALLOWED_M || distanceFromEnd > MAX_DISTANCE_ALLOWED_M
    },
    [territoryCenter],
  )
}
