import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { timeUtils } from '@/server-data'
import { selectUserAccountPreferences } from '@/features/domain/user'

export function useEventTimings(props: { planned?: number; tracked?: number }) {
  const { planned = -1, tracked = -1 } = props
  const accountPreferences = useSelector(selectUserAccountPreferences)

  return useMemo(() => {
    const plannedTime =
      planned !== -1
        ? timeUtils.formatSecondsFromMidnight(planned, accountPreferences.timeFormat)
        : '-'

    const trackedTime =
      tracked !== -1
        ? timeUtils.formatSecondsFromMidnight(tracked, accountPreferences.timeFormat)
        : '-'

    const delta = tracked !== -1 ? tracked - planned : 0
    const inLate = delta > 0

    return { plannedTime, trackedTime, delta, inLate }
  }, [accountPreferences, planned, tracked])
}
