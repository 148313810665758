import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Text } from '@/local/components'
import { selectDrivers } from '@/features/domain/driver'
import { useGetDriversOfOrders } from './hooks/useGetDriversOfOrders'
import { useTexts } from './useTexts'

interface Props {
  extOrderSteps: uui.domain.client.rm.ExtendedOrderStep[]
}

export function DriversDetail(props: Props) {
  const { extOrderSteps } = props

  const texts = useTexts()

  const drivers = useSelector(selectDrivers)

  const ordersAssignedDrivers = useGetDriversOfOrders(extOrderSteps)

  const assignedDriver = useMemo(() => {
    switch (ordersAssignedDrivers.length) {
      case 0:
        return null

      case 1:
        const extDriver = drivers[ordersAssignedDrivers[0]]
        return extDriver?.driver.name ?? null

      default:
        return texts.multipleDrivers
    }
  }, [ordersAssignedDrivers, drivers, texts])

  if (!assignedDriver) return null

  return (
    <Text size="$s" color="$shadyLady">
      {assignedDriver}
    </Text>
  )
}
