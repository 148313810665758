import { Entry } from '../Entry'

export function Entry20220428() {
  return (
    <Entry title="28/04/2022 - v3.0.1">
      Link to the{' '}
      <a
        href="https://workwave.atlassian.net/projects/RM/versions/12588/tab/release-report-all-issues"
        target="_blank"
        rel="noreferrer"
      >
        Jira Release
      </a>
    </Entry>
  )
}
