import { useState } from 'react'
import { useIsLoading } from '@/atoms'

import { IntegrationPage } from './components/IntegrationPage'
import { Loading } from './components/Loading'
import { Main } from './components/Main'

export function Integrations() {
  const [integrationId, setIntegrationId] = useState<undefined | string>()
  const isLoading = useIsLoading('fetchGpsData')

  if (isLoading) {
    return <Loading />
  }

  switch (integrationId) {
    case undefined:
      return <Main setIntegrationId={setIntegrationId} />
    default:
      return <IntegrationPage setIntegrationId={setIntegrationId} integrationId={integrationId} />
  }
}
