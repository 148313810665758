import type { OnSelectCallbackParams } from '@/components/StructuredVirtualList'

import { useCallback } from 'react'

import { useReadOnly } from '@/hooks'
import { fitMapToSelection } from '@/map'

import { useDataForList } from './useDataForList'

export function useListHandlers(selection: workwave.DeepReadonly<string[]>) {
  const { readonly } = useReadOnly()
  const { structure, setEditing, deselectAll, setSelection } = useDataForList()

  const handleOnSelect = useCallback(
    (params: OnSelectCallbackParams<'places'>) => {
      const ids: string[] = []

      for (const id of params.newSelectedIds) {
        if (id.startsWith('group')) continue

        ids.push(id)
      }

      setSelection(ids)

      // try to select it right away and
      // queue a pending fitMap request
      // it will be applied by the destination view when it opens
      fitMapToSelection({ preventIfVisible: true }, 'add')
    },
    [setSelection],
  )

  const handleOnSelectAll = useCallback(() => {
    const allSelected = structure.shownItems <= selection.length

    const ids: string[] = []

    for (const listItem of structure.list) {
      if (typeof listItem === 'string') {
        ids.push(listItem)
      }
    }

    if (allSelected) {
      deselectAll()
    } else {
      setSelection(ids)

      // try to select it right away and
      // queue a pending fitMap request
      // it will be applied by the destination view when it opens
      fitMapToSelection({ preventIfVisible: true }, 'add')
    }
  }, [setSelection, selection, deselectAll, structure])

  const handleOnDoubleClick = useCallback(() => {
    if (readonly) return

    setEditing([...selection])
  }, [setEditing, readonly, selection])

  const handleOnClick = useCallback(() => {
    if (readonly) return

    deselectAll()
    setEditing([])
  }, [setEditing, readonly, deselectAll])

  return {
    handleOnClick,
    handleOnSelect,
    handleOnSelectAll,
    handleOnDoubleClick,
  }
}
