import type { MouseEvent } from 'react'
import type { HotwordId } from '../../../types'

import { useState, useCallback, useMemo } from 'react'

export function useActions(addHotword: (id: HotwordId) => void) {
  const [open, setOpen] = useState<boolean>(false)

  const onSetHotword = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      const id = event.currentTarget.dataset.hotword as HotwordId
      addHotword(id)
      setOpen(false)
    },
    [addHotword],
  )

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  return useMemo(
    () => ({
      open,
      onClose,
      onOpen,
      onSetHotword,
    }),
    [open, onOpen, onClose, onSetHotword],
  )
}
