import { memo } from 'react'

import { Spinner } from '@/icons'

import renderText from '../../../intl'
import { LocationEditorText } from '../../../typings'

import EmptyStateContainer from './elements/EmptyStateContainer'
import EmptyStateIcon from './elements/EmptyStateIcon'
import EmptyStateText from './elements/EmptyStateText'

function LoadingState() {
  return (
    <EmptyStateContainer>
      <EmptyStateIcon>
        <Spinner animation="spin" />
      </EmptyStateIcon>

      <EmptyStateText>{renderText(LocationEditorText.searchingResult)}</EmptyStateText>
    </EmptyStateContainer>
  )
}

export default memo(LoadingState)
