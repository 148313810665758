import type { FormErrors, FormFields } from '../../formFields'
import type { CurrencyOption } from '../../typings'

import { useCallback, useMemo } from 'react'

import { Select } from '@workwave-tidal/tidal/form-ui'

import { currencies } from '../../../currencies'
import { useTexts } from './useTexts'

export function Currency() {
  const texts = useTexts()

  const getOptionLabel = useCallback((option: CurrencyOption) => {
    return option.label
  }, [])

  const items = useMemo<CurrencyOption[]>(
    () =>
      currencies.map(value => ({
        label: value,
        value,
      })),
    [],
  )

  return (
    <Select<'currency', FormFields, FormErrors, CurrencyOption>
      selectProps={{
        items,
        label: texts.currency,
        getLabel: getOptionLabel,
        optionMode: 'normal',
      }}
      validateOn="blur focus change"
      testId="currency"
      name="currency"
    />
  )
}
