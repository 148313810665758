import type { FormProps } from '../types'

import { NotificationForm } from '../../../../../../notificationForms/sections/notifications/components/NotificationForm'
import { NotificationView } from '../../../../../../notificationForms/sections/notifications/components/NotificationView'

import { useFormStateController } from '../../hooks/useFormStateController'
import { TwoDaysNotificationView } from './components/TwoDaysNotificationView'

import { useTwoDaysNotificationInitForm } from './hooks/useTwoDaysNotificationInitForm'
import { useTwoDaysNotificationSubmit } from './hooks/useTwoDaysNotificationSubmit'
import { useTwoDaysNotificationConfig } from './hooks/useTwoDaysNotificationConfig'
import { useTwoDaysNotificationValues } from './hooks/useTwoDaysNotificationValues'
import { useTwoDaysNotificationToggle } from './hooks/useTwoDaysNotificationToggle'
import { useTexts } from './useTexts'

/**
 * Renders the TwoDays notification form providing all the domain data and apis.
 */
export function TwoDaysNotification(props: FormProps) {
  const { inEditing, setEditingState } = props

  const values = useTwoDaysNotificationValues()

  const { toggleDescription, onToggle } = useTwoDaysNotificationToggle(values)
  const { edit, cancel } = useFormStateController(setEditingState)
  const onSubmit = useTwoDaysNotificationSubmit()
  const config = useTwoDaysNotificationConfig()
  const texts = useTexts()

  // Render the Form inside a FormRoot component that will expose the FormState API through a React.Context
  if (inEditing) {
    return (
      <NotificationForm
        initHook={useTwoDaysNotificationInitForm}
        subtitle={texts.description}
        notification="twoDays"
        onSubmit={onSubmit}
        title={texts.title}
        cancel={cancel}
        values={values}
        config={config}
      />
    )
  }

  return (
    <NotificationView
      Form={<TwoDaysNotificationView values={values} />}
      toggleSubtitle={toggleDescription}
      subtitle={texts.description}
      notification="twoDays"
      onToggle={onToggle}
      title={texts.title}
      values={values}
      onEdit={edit}
    />
  )
}
