import type { YYYYMMDD, OnChangeIntervalSelection, CalendarClickType } from '../../typings'

import { useCallback, useRef } from 'react'
import { isBefore } from 'date-fns/esm'

import { parseDate } from '../../utils/parseDate'

function getInitialValue(a?: Date, b?: Date): Date[] {
  const res: Date[] = []
  if (a) {
    res.push(a)
  }
  if (b) {
    res.push(b)
  }

  if (res.length === 2) {
    return []
  }

  return res
}

export function useUpdateSelection(
  onChange: OnChangeIntervalSelection,
  initialStart?: Date,
  initialEnd?: Date,
) {
  const queue = useRef<Date[]>(getInitialValue(initialStart, initialEnd))

  return useCallback(
    (dateAsString?: YYYYMMDD, type: CalendarClickType = 'click') => {
      if (dateAsString) {
        const date = parseDate(dateAsString)
        queue.current.push(date)

        if (queue.current.length === 1) {
          onChange(date, [date], type)
        } else if (queue.current.length === 2) {
          const a = queue.current[0]
          const b = queue.current[1]

          isBefore(a, b) ? onChange(date, [a, b], type) : onChange(date, [b, a], type)

          queue.current = []
        }
      } else {
        onChange(null, [], type)
        queue.current = []
      }
    },
    [onChange],
  )
}
