import { useEffect, useMemo, useRef } from 'react'

import { useControllerActions } from './useControllerActions'
import { useController } from './useController'

/**
 * Auto submit
 *
 * If the user selected one or more route and their orderSteps got no execution events,
 * the modal should submit automatically and not appear.
 */
export function useAutoSubmit() {
  const { unassignOrders } = useControllerActions()
  const {
    ready,
    close,
    data: { selectedOrderSteps, pairedOrderSteps },
  } = useController()

  const executed = useRef(false)

  const gotPairedOrderSteps = pairedOrderSteps?.length > 0

  const gotExecutionEvents = useMemo(() => {
    if (!ready) return

    const orderSteps = [...selectedOrderSteps, ...pairedOrderSteps]

    return orderSteps.some(orderStep => {
      // Got pickup
      if (
        (orderStep.order.type === 'p' || orderStep.order.type === 'pd') &&
        !orderStep.order.pickup.trackingData.isEmpty
      )
        return true

      // Got delivery
      if (
        (orderStep.order.type === 'd' ||
          orderStep.order.type === 's' ||
          orderStep.order.type === 'pd') &&
        !orderStep.order.delivery.trackingData.isEmpty
      )
        return true
    })
  }, [ready, selectedOrderSteps, pairedOrderSteps])

  useEffect(() => {
    if (executed.current) return
    if (!ready) return

    executed.current = true

    if (gotExecutionEvents === false && gotPairedOrderSteps === false) {
      unassignOrders()
      close?.()
    }
  }, [unassignOrders, close, ready, gotPairedOrderSteps, gotExecutionEvents])
}
