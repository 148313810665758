import { useRef } from 'react'

import { useComponentSize } from '@/local/hooks'

import { StatsContainer } from '../StatsContainer'
import { ExecutedBlock } from './components/ExecutedBlock'
import { NotExecutedBlock } from './components/NotExecutedBlock'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

export function ExecutionDetailsStats(props: Props) {
  const { extOrder } = props

  const ref = useRef<HTMLDivElement | null>(null)
  const [{ width }] = useComponentSize(ref)

  return (
    <StatsContainer ref={ref}>
      {!extOrder.isUnassigned && extOrder.hasBeenExecuted ? (
        <ExecutedBlock extOrder={extOrder} containerWidth={width} />
      ) : (
        <NotExecutedBlock />
      )}
    </StatsContainer>
  )
}
