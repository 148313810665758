import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehicles } from '@/features/domain/vehicle'

import { getLabel } from '../../../../utils/getLabel'

// port of the v2 selector 'getVehiclesLockedNames'
export function useLockedNames(selectedUnifiedId: string) {
  const vehicles = useSelector(selectVehicles)

  return useMemo(() => {
    return Object.values(vehicles).reduce<string[]>((acc, uv) => {
      if (uv.isPhysicalDevice) return acc

      if (uv.unifiedId !== selectedUnifiedId) {
        acc.push(getLabel(uv).trim())
      }
      return acc
    }, [])
  }, [vehicles, selectedUnifiedId])
}
