export const separator = ':'
export const defaultValueAsString = '00:00'

export const defaultStepSecs: number = 5 * 60

export const halfDayInSecs = 12 * 60 * 60
export const fullDayInSecs = 24 * 60 * 60

export const acceptedValues: string[] = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  ':',
  'Shift',
  'ArrowLeft',
  'ArrowRight',
  'Backspace',
  'Delete',
  'Enter',
  'End',
  'Alt',
  'Tab',
]
