import type { Components } from '@mui/material'

import { MuiAccordionSummary } from './MuiAccordionSummary'
import { MuiAccordionDetails } from './MuiAccordionDetails'
import { MuiTypography } from './MuiTypography'
import { MuiAutocomplete } from './MuiAutocomplete'
import { MuiFormControl } from './MuiFormControl'
import { MuiTextField } from './MuiTextField'
import { MuiAccordion } from './MuiAccordion'
import { MuiSelect } from './MuiSelect'
import { MuiDialog } from './MuiDialog'

export const components: Components = {
  MuiAccordionDetails,
  MuiAutocomplete,
  MuiFormControl,
  MuiTypography,
  MuiAccordion,
  MuiTextField,
  MuiSelect,
  MuiDialog,

  // @ts-expect-error
  MuiAccordionSummary,
}
