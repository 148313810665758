export function getLabel(vehicle: uui.domain.client.UnifiedVehicle) {
  if (vehicle.hasRoutingLicense) {
    return vehicle.vehicle.externalId
  }
  if (vehicle.isPhysicalDevice && vehicle.trackingProvider === 'WWGPS') {
    return vehicle.device.label
  }

  return ''
}
