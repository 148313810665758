import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

export function Footer() {
  const texts = useTexts()
  const actions = useControllerActions()
  const {
    data: { territories, id },
    status,
    invalid,
    close,
  } = useController()

  const territory = territories[id]

  const commandButtonText = territory.statusReasons.length > 0 ? texts.replace : texts.add

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          color={territory.statusReasons.length > 0 ? 'error' : 'primary'}
          disabled={invalid || status !== 'ready'}
          onClick={actions.onConfirm}
          variant="contained"
          testid="copy-reasons-for-not-completed-orders-modal-confirm-btn"
        >
          {commandButtonText}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          onClick={close}
          testid="copy-reasons-for-not-completed-orders-modal-cancel-btn"
        >
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}
