import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { conversionUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export const useConvertTime = (seconds: number, showSeconds?: boolean) => {
  const userConfig = useSelector(selectUserConfiguration)

  return useMemo(() => {
    return conversionUtils.convertTime(userConfig)(seconds, showSeconds)
  }, [userConfig, seconds, showSeconds])
}
