import { useCallback } from 'react'

import { useNotification } from '@/hooks'
import { regionUtils } from '@/utils'

export function useOnExport(regions: uui.domain.client.rm.Region[]) {
  const toast = useNotification()

  return useCallback(async () => {
    try {
      regionUtils.exportRegionToKML(regions)
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, regions])
}
