import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectGpsTrackingProvider, selectUserProfile } from '@/features/domain/user'
import { selectHasNoPhysicalDevices } from '@/features/domain/device'
import { useIsSimulation } from '@/hooks'

export function useCanShow() {
  const isSimulation = useIsSimulation()
  const userProfile = useSelector(selectUserProfile)
  const gpsEnabled = userProfile.account.licensingLimits.allowGps

  const noPhysicalDevices = useSelector(selectHasNoPhysicalDevices)
  const trackingProvider = useSelector(selectGpsTrackingProvider)

  return useMemo(
    () => ({
      alerts: gpsEnabled && !isSimulation && !noPhysicalDevices && trackingProvider === 'WWGPS',
      geofences: gpsEnabled && !isSimulation && !noPhysicalDevices && trackingProvider === 'WWGPS',
      devices: gpsEnabled && !isSimulation && trackingProvider === 'WWGPS',
      places: gpsEnabled && !isSimulation && trackingProvider === 'WWGPS',
    }),
    [gpsEnabled, isSimulation, noPhysicalDevices, trackingProvider],
  )
}
