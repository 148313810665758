export function NoEntries() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 270 261"
      style={{ maxWidth: '100%', height: '100%' }}
    >
      <g opacity=".1" filter="url(#a)">
        <path
          d="M9.86377 136.004c0-68.4958 57.99463-124.0186 129.56823-124.0186 10.169.0012 20.304 1.1467 30.208 3.4142C181.115 5.65793 195.877.481752 211.032.885755c15.155.404005 29.61 6.359055 40.532 16.697745 5.496 5.2294 9.87 11.4886 12.865 18.408 2.995 6.9193 4.549 14.3585 4.571 21.8783.014 11.0822-3.337 21.9163-9.625 31.1138 6.375 14.8824 9.648 30.8714 9.625 47.0204C269 204.492 211.005 260 139.432 260 67.8584 260 9.86377 204.492 9.86377 136.004Z"
          fill="#586A7A"
        />
      </g>
      <path
        d="M9 135.14C9 66.6444 66.9947 11.1216 138.568 11.1216c10.169.0012 20.304 1.1467 30.208 3.4142C180.251 4.79416 195.013-.382018 210.168.0219857 225.323.425989 239.779 6.38104 250.7 16.7198c5.496 5.2294 9.87 11.4885 12.865 18.4079 2.995 6.9193 4.549 14.3585 4.571 21.8783.014 11.0823-3.337 21.9163-9.625 31.1138 6.375 14.8822 9.648 30.8712 9.625 47.0202 0 68.488-57.994 123.996-129.568 123.996C66.9947 259.136 9 203.628 9 135.14Z"
        fill="#EAEEF2"
      />
      <path
        d="M32.1431 152.638c0 16.322 13.1814 29.574 29.4826 29.574 16.3011 0 29.4826-13.252 29.4826-29.574 0-16.323-13.1815-29.575-29.4826-29.575-16.2572.044-29.4826 13.252-29.4826 29.575Z"
        fill="#31805B"
      />
      <path
        d="M90.9618 152.726c0 5.309-1.406 10.312-3.8666 14.655-2.3727.615-4.8771.966-7.4695.966-16.3012 0-29.4826-13.252-29.4826-29.574 0-5.31 1.406-10.312 3.8666-14.656 2.3727-.614 4.8771-.965 7.4695-.965 16.3011.043 29.4826 13.251 29.4826 29.574Z"
        fill="#41A776"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.5556 200.638V162.55h1.8505v38.088h-1.8505Z"
        fill="#276547"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m53.3352 179.744 8.8316 9.786-1.3738 1.24-8.8316-9.786 1.3738-1.24Zm13.9664-11.475-5.1407 5.572-1.3601-1.255 5.1407-5.571 1.3601 1.254Z"
        fill="#276547"
      />
      <path
        d="M39.6865 177.733c.703 5.397 6.1075 9.215 12.127 8.424 6.0195-.745 10.3255-5.747 9.6225-11.188-.703-5.397-6.1075-9.215-12.127-8.425-6.0195.746-10.3255 5.748-9.6225 11.189Zm171.2465 21.365H253.4s-3.027-20.8-21.233-20.8c-18.207 0-21.234 20.8-21.234 20.8Z"
        fill="#41A776"
      />
      <path d="M221 200h-81v-67.764L182.581 110 221 132.236V200Z" fill="#FAFAFA" />
      <path d="M140 136H84v64h56v-64Z" fill="#D4E3F3" />
      <path
        d="M191.336 130.337c.461-2.597-1.462-5.047-4.297-5.469-2.834-.424-5.505 1.34-5.967 3.937-.461 2.599 1.462 5.047 4.296 5.47 2.835.424 5.506-1.34 5.968-3.938Z"
        fill="#BAC1C8"
      />
      <path d="M196.604 156.868h-21.667v42.466h21.667v-42.466Z" fill="#A1C1E6" />
      <path d="M182.5 104h-58l-43.2583 28.346h60.2663L182.5 104Z" fill="#70808D" />
      <path
        d="M225 130.5 182.5 104 140 131l-60.4917-.127v8.111h60.5497l80.275.383h4.599L225 130.5Z"
        fill="#8996A1"
      />
      <path d="M140 138.817h7.083v-8.263H140v8.263Z" fill="#70808D" />
      <path d="M147.083 138.817h7.084v-8.263h-7.084v8.263Z" fill="#A1ACB4" />
      <path d="M154.167 138.817h7.083v-8.263h-7.083v8.263Z" fill="#70808D" />
      <path d="M161.25 138.817h7.083v-8.263h-7.083v8.263Z" fill="#A1ACB4" />
      <path d="M168.333 138.817h7.084v-8.263h-7.084v8.263Z" fill="#70808D" />
      <path d="M175.417 138.817h7.083v-8.263h-7.083v8.263Z" fill="#A1ACB4" />
      <path d="M182.5 138.817h7.083v-8.263H182.5v8.263Z" fill="#70808D" />
      <path d="M189.583 138.817h7.084v-8.263h-7.084v8.263Z" fill="#A1ACB4" />
      <path d="M196.667 138.817h7.083v-8.263h-7.083v8.263Z" fill="#70808D" />
      <path d="M203.75 138.817h7.083v-8.263h-7.083v8.263Z" fill="#A1ACB4" />
      <path d="M210.833 138.817h7.084v-8.263h-7.084v8.263Z" fill="#70808D" />
      <path d="M217.917 138.817H225v-8.263h-7.083v8.263Z" fill="#A1ACB4" />
      <path
        d="M140 144.088v-5.272h7.083v5.272c0 1.956-1.585 3.541-3.541 3.541S140 146.044 140 144.088Z"
        fill="#70808D"
      />
      <path
        d="M147.083 144.088v-5.272h7.084v5.272c0 1.956-1.586 3.541-3.542 3.541-1.956 0-3.542-1.585-3.542-3.541Z"
        fill="#A1ACB4"
      />
      <path
        d="M154.167 144.088v-5.272h7.083v5.272c0 1.956-1.586 3.541-3.542 3.541-1.956 0-3.541-1.585-3.541-3.541Z"
        fill="#70808D"
      />
      <path
        d="M161.25 144.088v-5.272h7.083v5.272c0 1.956-1.585 3.541-3.541 3.541s-3.542-1.585-3.542-3.541Z"
        fill="#A1ACB4"
      />
      <path
        d="M168.333 144.088v-5.272h7.084v5.272c0 1.956-1.586 3.541-3.542 3.541-1.956 0-3.542-1.585-3.542-3.541Z"
        fill="#70808D"
      />
      <path
        d="M175.417 144.088v-5.272h7.083v5.272c0 1.956-1.586 3.541-3.542 3.541-1.956 0-3.541-1.585-3.541-3.541Z"
        fill="#A1ACB4"
      />
      <path
        d="M182.5 144.088v-5.272h7.083v5.272c0 1.956-1.585 3.541-3.541 3.541s-3.542-1.585-3.542-3.541Z"
        fill="#70808D"
      />
      <path
        d="M189.583 144.088v-5.272h7.084v5.272c0 1.956-1.586 3.541-3.542 3.541-1.956 0-3.542-1.585-3.542-3.541Z"
        fill="#A1ACB4"
      />
      <path
        d="M196.667 144.088v-5.272h7.083v5.272c0 1.956-1.586 3.541-3.542 3.541-1.956 0-3.541-1.585-3.541-3.541Z"
        fill="#70808D"
      />
      <path
        d="M203.75 144.088v-5.272h7.083v5.272c0 1.956-1.585 3.541-3.541 3.541s-3.542-1.585-3.542-3.541Z"
        fill="#A1ACB4"
      />
      <path
        d="M210.833 144.088v-5.272h7.084v5.272c0 1.956-1.586 3.541-3.542 3.541-1.956 0-3.542-1.585-3.542-3.541Z"
        fill="#70808D"
      />
      <path
        d="M217.917 144.088v-5.272H225v5.272c0 1.956-1.586 3.541-3.542 3.541-1.956 0-3.541-1.585-3.541-3.541Z"
        fill="#A1ACB4"
      />
      <path d="M213.364 118.435h-58.94v12.119h58.94v-12.119Z" fill="#D3D7DC" />
      <path d="M140 130.779h85" stroke="#70808D" strokeWidth=".550847" />
      <path d="M151.669 193.35h13.765v-36.356h-13.765v36.356Z" fill="#A1C1E6" />
      <path d="M161.105 193.35h4.336v-36.356h-4.336v36.356Z" fill="#A1C1E6" />
      <path
        d="M151.669 193.33h13.765v-6.59h-13.765v6.59Zm49.026.02h14.871v-36.356h-14.871v36.356Z"
        fill="#A1C1E6"
      />
      <path d="M210.884 193.35h4.684v-36.356h-4.684v36.356Z" fill="#A1C1E6" />
      <path d="M200.695 193.33h14.871v-6.59h-14.871v6.59Z" fill="#A1C1E6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M260.737 201.071H.737305v-1.738H260.737v1.738Z"
        fill="#D3D7DC"
      />
      <path
        d="M250.678 59.2365h-33.543s1.247-4.9289 6.804-5.3595c0 0 2.397-.4307 4.745 2.2012 2.347 2.6319 5.462-3.1583 13.225-3.1583 7.715.0479 8.769 6.3166 8.769 6.3166Zm-120.089-9.6991H83.5332s1.725-6.9387 9.5358-7.513c0 0 3.3065-.5742 6.6605 3.1105 3.3065 3.6847 7.6675-4.4025 18.5445-4.4025 10.877 0 12.315 8.805 12.315 8.805Z"
        fill="#fff"
      />
      <path
        d="M167.093 126.437c2.394-.536 5.865-1.234 6.818-3.881.078-.214.271-.758-.104-.791-.768-.069-1.685.291-2.218.84-.451.464-.654 1.222-.531 1.854.201 1.042 1.444 1.519 2.34 1.777 1.307.377 3.055.78 4.388.326 1.202-.409 2.021-1.414 2.714-2.419.064-.092.524.58.608.659.592.56 1.253.569 1.986.261.439-.185.824-.455 1.232-.695.214-.125.517-.34.785-.329.232.008.452.222.645.322.527.273 1.053.335 1.639.243.938-.146 1.909-.703 2.812-.173.309.181.575.417.934.5.39.09.802-.057 1.194-.07 1.06-.034 2.085.429 3.163.41.994-.017 2.005-.186 2.978-.382.978-.197 1.775-.552 2.673-.951"
        stroke="#8996A1"
        strokeWidth="1.65254"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="a"
          x="9.31292"
          y=".312922"
          width="260.238"
          height="260.238"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation=".275424" result="effect1_foregroundBlur_2327_33524" />
        </filter>
      </defs>
    </svg>
  )
}
