import { snapshot } from 'valtio'
import { subscribeKey } from 'valtio/utils'

import { locationPin } from './locationPin'

type Subscriber = (pin?: uui.domain.ui.map.LocationPin) => void

export function subscribeToLocationPin(
  id: uui.domain.ui.map.LocationPinId,
  subscriber: Subscriber,
) {
  return subscribeKey(
    locationPin.pins,
    id,
    pin => subscriber(pin ? snapshot(pin) : undefined),
    true,
  )
}
