import type { CrudSelection } from './typings'

export type { CrudSelection, CrudSelectionCategory } from './typings'

export { getCrudSelection } from './read/geCrudSelection'
export { subscribeCrudSelection } from './read/subscribeCrudSelection'
export { subscribeAllCrudSelection } from './read/subscribeAllCrudSelection'

export { setCrudSelection } from './write/setCrudSelection'
export { resetCrudSelection } from './write/resetCrudSelection'
export { setAllCrudSelection } from './write/setAllCrudSelection'
export { cleanUpCrudSelection } from './write/cleanUpCrudSelection'
export { resetAllCrudSelection } from './write/resetAllCrudSelection'

export { useCrudSelection } from './hooks/useCrudSelection'
export { useAllCrudSelection } from './hooks/useAllCrudSelection'
export { useResetCrudSelectionOnUnmount } from './hooks/useResetCrudSelectionOnUnmount'

export const emptyCrudSelection: CrudSelection = {
  depots: [],
  driverAssignments: [],
  drivers: [],
  unifiedVehicles: [],
  regions: [],
  trafficArea: [],
  trafficProfile: [],
  routingSegments: [],
  simulations: [],
  users: [],
  // importOrders: [],
  alerts: [],
  geofences: [],
  places: [],
  reports: [],
  devices: [],
  deviceEvents: [],
}
