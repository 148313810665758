import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    loading: translate({
      id: 'orders.smsNotifications.loading',
    }),

    notificationsListFailureTitle: translate({
      id: 'orders.notificationsList.failure.title',
    }),

    notificationsListFailureDescription: translate({
      id: 'orders.notificationsList.failure.description',
    }),

    trackingLinkFailureTitle: translate({
      id: 'orders.trackingLink.failure.title',
    }),

    trackingLinkFailureDescription: translate({
      id: 'orders.trackingLink.failure.description',
    }),

    trackingLinkLabel: translate({
      id: 'orders.trackingLink.label',
    }),
  }))

  return api
}
