import { getRecurrencesSorted, recurrenceMatchesDate } from '@/local/server-data/utils/recurrences'

export const getCalendarizedSettings = (
  ve: uui.domain.client.rm.Vehicle,
  date?: Date | string,
): uui.domain.client.rm.VehicleSettings => {
  if (date) {
    const recurrencesAsString: string[] = Object.keys(ve.settings)
    const recurrences = getRecurrencesSorted(recurrencesAsString)

    const exception = recurrences.find(recurrence => recurrenceMatchesDate(recurrence, date))

    if (exception && ve.settings[exception.source]) {
      return ve.settings[exception.source]
    }
  }

  return ve.settings['any']
}
