import type { Ticket } from '../types'

import { useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

type ModalState = 'ready' | 'submitting' | 'error'

interface ModalData {
  ticket: Ticket
}

export const modalId = 'supportTicket'

export const useController = () => useModalController<ModalData, ModalState>(modalId)

export const useConfigureController = () => {
  const close = useCloseModal()

  // INITIALIZATION
  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        ticket: {
          topic: null,
          message: '',
        },
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)

  return ctrl
}
