import { useCallback } from 'react'

import { Edit } from '@/icons'
import { useEditingStateWithCtx } from '@/atoms'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function SingleViewGpsActions(props: Props) {
  const {
    unifiedVehicle: { unifiedId },
  } = props

  const { setEditing } = useEditingStateWithCtx('vehicle')

  const texts = useTexts()

  const startEditing = useCallback(() => setEditing([unifiedId]), [setEditing, unifiedId])

  return (
    <SecondaryColumnHeaderActionsRow>
      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.edit}
            icon={<Edit />}
            onClick={startEditing}
            disabled={preventEditing}
            className="pendo-setup-vehicle__editgps"
            testid="setup-vehicle__editgps"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
