import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    message: translate({ id: 'giveFeedback.validations.message' }),
  }))

  return api
}

export type ValidationTexts = ReturnType<typeof useTexts>
