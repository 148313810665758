import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { updateOrderCountsByDate } from '@/features/domain/routeplan/actions/updateOrderCountsByDate'
import { isGpsOnly } from '@/features/domain/user'
import { useAppDispatch } from '@/store'

export function useUpdateOrderCountsByDate() {
  const dispatch = useAppDispatch()

  const gpsOnly = useSelector(isGpsOnly)

  useEffect(() => {
    if (gpsOnly) return

    dispatch(updateOrderCountsByDate())
  }, [gpsOnly, dispatch])
}
