import { useMemo } from 'react'

import { parseRecurrence } from '@/server-data'

type Result = Record<uui.domain.rm.RecurrenceTypes, uui.domain.rm.Recurrence[]>

export function useRecurrencesMap(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
): Result | undefined {
  return useMemo(() => {
    if (!unifiedVehicle.hasRoutingLicense) return undefined

    const vehicleSettings = unifiedVehicle.vehicle.settings
    const map = Object.keys(vehicleSettings).reduce((acc, id) => {
      const rec = parseRecurrence(id)

      if (!rec) return acc

      const { type } = rec
      const arr = acc[type] || []
      acc[type] = [...arr, rec]

      return acc
    }, {})

    return Object.keys(map).length === 0 ? undefined : (map as Result)
  }, [unifiedVehicle])
}
