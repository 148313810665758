import { WarningCard } from '@local/components'
import { VerticalLayout } from '@/components/layout'
import { WarningTriangle } from '@/icons'

import { useController } from '../hooks/useController'

export function Body() {
  const {
    data: { title, subtitle },
  } = useController()

  return (
    <VerticalLayout marginTop={32}>
      <WarningCard
        Icon={<WarningTriangle size={16} color="$pureWhite" background="$darkOrange" />}
        description={subtitle}
        preset="warning"
        title={title}
      />
    </VerticalLayout>
  )
}
