import { useFormatCurrency } from '@/hooks'

interface Props {
  amount: number
}

export function FormatCurrency(props: Props) {
  const { amount } = props
  const value = useFormatCurrency(amount)

  return <>{value}</>
}
