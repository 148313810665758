import { useCallback, useState, useEffect } from 'react'

import { resetCrudSelection } from '@/atoms'

import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'

import { usePendingFitMapOnMount } from '@/map'
import { RegionAvatar } from '../../components/RegionAvatar'
import { ViewDetails } from '../../components/ViewDetails'
import { ActionsManager } from './components/ActionsManager'

import { useTexts } from './hooks/useTexts'

interface Props {
  region: uui.domain.client.rm.Region
}

export function SingleView(props: Props) {
  const { region } = props

  const texts = useTexts()
  const { enterCost, enterTimeSec } = region

  const [view, setView] = useState<'default' | 'delete'>(() => 'default')
  usePendingFitMapOnMount()

  const onReset = useCallback(() => {
    resetCrudSelection('regions')
  }, [])

  useEffect(() => {
    setView('default')
  }, [region])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={region.name || texts.unNamedRegion}
        description={texts.region}
        action={onReset}
        avatar={<RegionAvatar region={region} />}
      />
      <ActionsManager view={view} setView={setView} region={region} />
      <FormContent footer={<></>}>
        <ViewDetails enterCost={enterCost} enterTimeSec={enterTimeSec} />
      </FormContent>
    </FormColumn>
  )
}
