import type { AsyncThunkApiConfig } from '@/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

export const fetchSmsPhoneNumbers = createAsyncThunk<
  // Return type of the payload creator
  string[],
  // First argument to the payload creator
  void,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/account/fetchSmsPhoneNumbers', async (_params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/account/fetchSmsPhoneNumbers', {
      type: 'rpc/account/fetchSmsPhoneNumbers',
      category: 'rpc',
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        error: response,
        message: response.errorMessage,
      })
    }

    return response.result
  } catch (error) {
    return thunkApi.rejectWithValue({
      error,
      type: 'unknown',
      message: 'Failed to update the User Account',
    })
  }
})
