import { Icon, Style } from 'ol/style'
import spriteSheetUrl_1_9 from '@/resources/map-markers/orderLabels_1_9.png'
import spriteSheetUrl_10_99 from '@/resources/map-markers/orderLabels_10_99.png'
import spriteSheetUrl_100_249 from '@/resources/map-markers/orderLabels_100_249.png'
import spriteSheetUrl_250_399 from '@/resources/map-markers/orderLabels_250_399.png'
import spriteSheetUrl_400_549 from '@/resources/map-markers/orderLabels_400_549.png'
import spriteSheetUrl_550_699 from '@/resources/map-markers/orderLabels_550_699.png'
import spriteSheetUrl_700_849 from '@/resources/map-markers/orderLabels_700_849.png'
import spriteSheetUrl_850_999 from '@/resources/map-markers/orderLabels_850_999.png'
import spriteSheetUrl_1000_1149 from '@/resources/map-markers/orderLabels_1000_1149.png'
import spriteSheetUrl_1150_1299 from '@/resources/map-markers/orderLabels_1150_1299.png'
import spriteSheetUrl_1300_1449 from '@/resources/map-markers/orderLabels_1300_1449.png'
import spriteSheetUrl_1450_1599 from '@/resources/map-markers/orderLabels_1450_1599.png'
import spriteSheetUrl_1600_1749 from '@/resources/map-markers/orderLabels_1600_1749.png'
import spriteSheetUrl_1750_1899 from '@/resources/map-markers/orderLabels_1750_1899.png'
import spriteSheetUrl_1900_2049 from '@/resources/map-markers/orderLabels_1900_2049.png'
import spriteSheetUrl_2050_2199 from '@/resources/map-markers/orderLabels_2050_2199.png'
import spriteSheetUrl_2200_2349 from '@/resources/map-markers/orderLabels_2200_2349.png'
import spriteSheetUrl_2350_2499 from '@/resources/map-markers/orderLabels_2350_2499.png'
import spriteSheetUrl_2500_2649 from '@/resources/map-markers/orderLabels_2500_2649.png'
import spriteSheetUrl_2650_2799 from '@/resources/map-markers/orderLabels_2650_2799.png'
import spriteSheetUrl_2800_2949 from '@/resources/map-markers/orderLabels_2800_2949.png'
import spriteSheetUrl_2950_3099 from '@/resources/map-markers/orderLabels_2950_3099.png'
import spriteSheetUrl_3100_3249 from '@/resources/map-markers/orderLabels_3100_3249.png'
import spriteSheetUrl_3250_3399 from '@/resources/map-markers/orderLabels_3250_3399.png'
import spriteSheetUrl_3400_3549 from '@/resources/map-markers/orderLabels_3400_3549.png'
import spriteSheetUrl_3550_3699 from '@/resources/map-markers/orderLabels_3550_3699.png'
import spriteSheetUrl_3700_3849 from '@/resources/map-markers/orderLabels_3700_3849.png'
import spriteSheetUrl_3850_3999 from '@/resources/map-markers/orderLabels_3850_3999.png'
import spriteSheetUrl_4000_4149 from '@/resources/map-markers/orderLabels_4000_4149.png'
import spriteSheetUrl_4150_4299 from '@/resources/map-markers/orderLabels_4150_4299.png'
import spriteSheetUrl_4300_4449 from '@/resources/map-markers/orderLabels_4300_4449.png'
import spriteSheetUrl_4450_4599 from '@/resources/map-markers/orderLabels_4450_4599.png'
import spriteSheetUrl_4600_4749 from '@/resources/map-markers/orderLabels_4600_4749.png'
import spriteSheetUrl_4750_4899 from '@/resources/map-markers/orderLabels_4750_4899.png'
import spriteSheetUrl_4900_5049 from '@/resources/map-markers/orderLabels_4900_5049.png'
import spriteSheetUrl_5050_5199 from '@/resources/map-markers/orderLabels_5050_5199.png'
import spriteSheetUrl_5200_5349 from '@/resources/map-markers/orderLabels_5200_5349.png'
import spriteSheetUrl_5350_5499 from '@/resources/map-markers/orderLabels_5350_5499.png'
import spriteSheetUrl_5500_5649 from '@/resources/map-markers/orderLabels_5500_5649.png'
import spriteSheetUrl_5650_5799 from '@/resources/map-markers/orderLabels_5650_5799.png'
import spriteSheetUrl_5800_5949 from '@/resources/map-markers/orderLabels_5800_5949.png'
import spriteSheetUrl_5950_6099 from '@/resources/map-markers/orderLabels_5950_6099.png'
import spriteSheetUrl_6100_6249 from '@/resources/map-markers/orderLabels_6100_6249.png'
import spriteSheetUrl_6250_6399 from '@/resources/map-markers/orderLabels_6250_6399.png'
import spriteSheetUrl_6400_6549 from '@/resources/map-markers/orderLabels_6400_6549.png'
import spriteSheetUrl_6550_6699 from '@/resources/map-markers/orderLabels_6550_6699.png'
import spriteSheetUrl_6700_6849 from '@/resources/map-markers/orderLabels_6700_6849.png'
import spriteSheetUrl_6850_6999 from '@/resources/map-markers/orderLabels_6850_6999.png'
import spriteSheetUrl_7000_7149 from '@/resources/map-markers/orderLabels_7000_7149.png'
import spriteSheetUrl_7150_7299 from '@/resources/map-markers/orderLabels_7150_7299.png'
import spriteSheetUrl_7300_7449 from '@/resources/map-markers/orderLabels_7300_7449.png'
import spriteSheetUrl_7450_7599 from '@/resources/map-markers/orderLabels_7450_7599.png'
import spriteSheetUrl_7600_7749 from '@/resources/map-markers/orderLabels_7600_7749.png'
import spriteSheetUrl_7750_7899 from '@/resources/map-markers/orderLabels_7750_7899.png'
import spriteSheetUrl_7900_8049 from '@/resources/map-markers/orderLabels_7900_8049.png'
import spriteSheetUrl_8050_8199 from '@/resources/map-markers/orderLabels_8050_8199.png'
import spriteSheetUrl_8200_8349 from '@/resources/map-markers/orderLabels_8200_8349.png'
import spriteSheetUrl_8350_8499 from '@/resources/map-markers/orderLabels_8350_8499.png'
import spriteSheetUrl_8500_8649 from '@/resources/map-markers/orderLabels_8500_8649.png'
import spriteSheetUrl_8650_8799 from '@/resources/map-markers/orderLabels_8650_8799.png'
import spriteSheetUrl_8800_8949 from '@/resources/map-markers/orderLabels_8800_8949.png'
import spriteSheetUrl_8950_9099 from '@/resources/map-markers/orderLabels_8950_9099.png'
import spriteSheetUrl_9100_9249 from '@/resources/map-markers/orderLabels_9100_9249.png'
import spriteSheetUrl_9250_9399 from '@/resources/map-markers/orderLabels_9250_9399.png'
import spriteSheetUrl_9400_9549 from '@/resources/map-markers/orderLabels_9400_9549.png'
import spriteSheetUrl_9550_9699 from '@/resources/map-markers/orderLabels_9550_9699.png'
import spriteSheetUrl_9700_9849 from '@/resources/map-markers/orderLabels_9700_9849.png'
import spriteSheetUrl_9850_9999 from '@/resources/map-markers/orderLabels_9850_9999.png'
import spriteSheetUrl_special from '@/resources/map-markers/orderLabels_special.png'
import { genericStyles } from '../../../genericStyles'

const orderLabelStylesCache: Map<string, Style> = new Map()

function computeSpriteSheetUrl(sprite) {
  switch (sprite) {
    case '1_9':
      return spriteSheetUrl_1_9
    case '10_99':
      return spriteSheetUrl_10_99
    case '100_249':
      return spriteSheetUrl_100_249
    case '250_399':
      return spriteSheetUrl_250_399
    case '400_549':
      return spriteSheetUrl_400_549
    case '550_699':
      return spriteSheetUrl_550_699
    case '700_849':
      return spriteSheetUrl_700_849
    case '850_999':
      return spriteSheetUrl_850_999
    case '1000_1149':
      return spriteSheetUrl_1000_1149
    case '1150_1299':
      return spriteSheetUrl_1150_1299
    case '1300_1449':
      return spriteSheetUrl_1300_1449
    case '1450_1599':
      return spriteSheetUrl_1450_1599
    case '1600_1749':
      return spriteSheetUrl_1600_1749
    case '1750_1899':
      return spriteSheetUrl_1750_1899
    case '1900_2049':
      return spriteSheetUrl_1900_2049
    case '2050_2199':
      return spriteSheetUrl_2050_2199
    case '2200_2349':
      return spriteSheetUrl_2200_2349
    case '2350_2499':
      return spriteSheetUrl_2350_2499
    case '2500_2649':
      return spriteSheetUrl_2500_2649
    case '2650_2799':
      return spriteSheetUrl_2650_2799
    case '2800_2949':
      return spriteSheetUrl_2800_2949
    case '2950_3099':
      return spriteSheetUrl_2950_3099
    case '3100_3249':
      return spriteSheetUrl_3100_3249
    case '3250_3399':
      return spriteSheetUrl_3250_3399
    case '3400_3549':
      return spriteSheetUrl_3400_3549
    case '3550_3699':
      return spriteSheetUrl_3550_3699
    case '3700_3849':
      return spriteSheetUrl_3700_3849
    case '3850_3999':
      return spriteSheetUrl_3850_3999
    case '4000_4149':
      return spriteSheetUrl_4000_4149
    case '4150_4299':
      return spriteSheetUrl_4150_4299
    case '4300_4449':
      return spriteSheetUrl_4300_4449
    case '4450_4599':
      return spriteSheetUrl_4450_4599
    case '4600_4749':
      return spriteSheetUrl_4600_4749
    case '4750_4899':
      return spriteSheetUrl_4750_4899
    case '4900_5049':
      return spriteSheetUrl_4900_5049
    case '5050_5199':
      return spriteSheetUrl_5050_5199
    case '5200_5349':
      return spriteSheetUrl_5200_5349
    case '5350_5499':
      return spriteSheetUrl_5350_5499
    case '5500_5649':
      return spriteSheetUrl_5500_5649
    case '5650_5799':
      return spriteSheetUrl_5650_5799
    case '5800_5949':
      return spriteSheetUrl_5800_5949
    case '5950_6099':
      return spriteSheetUrl_5950_6099
    case '6100_6249':
      return spriteSheetUrl_6100_6249
    case '6250_6399':
      return spriteSheetUrl_6250_6399
    case '6400_6549':
      return spriteSheetUrl_6400_6549
    case '6550_6699':
      return spriteSheetUrl_6550_6699
    case '6700_6849':
      return spriteSheetUrl_6700_6849
    case '6850_6999':
      return spriteSheetUrl_6850_6999
    case '7000_7149':
      return spriteSheetUrl_7000_7149
    case '7150_7299':
      return spriteSheetUrl_7150_7299
    case '7300_7449':
      return spriteSheetUrl_7300_7449
    case '7450_7599':
      return spriteSheetUrl_7450_7599
    case '7600_7749':
      return spriteSheetUrl_7600_7749
    case '7750_7899':
      return spriteSheetUrl_7750_7899
    case '7900_8049':
      return spriteSheetUrl_7900_8049
    case '8050_8199':
      return spriteSheetUrl_8050_8199
    case '8200_8349':
      return spriteSheetUrl_8200_8349
    case '8350_8499':
      return spriteSheetUrl_8350_8499
    case '8500_8649':
      return spriteSheetUrl_8500_8649
    case '8650_8799':
      return spriteSheetUrl_8650_8799
    case '8800_8949':
      return spriteSheetUrl_8800_8949
    case '8950_9099':
      return spriteSheetUrl_8950_9099
    case '9100_9249':
      return spriteSheetUrl_9100_9249
    case '9250_9399':
      return spriteSheetUrl_9250_9399
    case '9400_9549':
      return spriteSheetUrl_9400_9549
    case '9550_9699':
      return spriteSheetUrl_9550_9699
    case '9700_9849':
      return spriteSheetUrl_9700_9849
    case '9850_9999':
      return spriteSheetUrl_9850_9999

    case 'special':
      return spriteSheetUrl_special
    default:
      throw new Error(`Unknown sprite: ${sprite}`)
  }
}

export function getMainLabelStyle(
  mainGridPlacement: uui.domain.ui.map.markers.MultiSpriteGridPlacement,
  gotSecondaryText: boolean,
  mode: uui.domain.ui.map.markers.MapStyles['order']['mode'],
  color: 'white' | 'black',
  selected: boolean,
) {
  if (mode !== 'maximized') return genericStyles.hidden
  const [x, y, cellSize, sprite] = mainGridPlacement

  const cacheId = `${x}_${y}_${sprite}_${gotSecondaryText ? 'down' : 'center'}_${
    selected ? 'selected' : 'not-selected'
  }_${color}`

  if (orderLabelStylesCache.has(cacheId)) {
    return orderLabelStylesCache.get(cacheId)
  }

  const spriteSheetUrl = computeSpriteSheetUrl(sprite)

  const style = new Style({
    zIndex: selected ? 2 : mode === 'maximized' ? 1 : undefined,
    image: new Icon({
      src: spriteSheetUrl,
      color: color === 'black' ? 'rgba(0, 0, 0, 255)' : undefined,
      crossOrigin: 'anonymous',

      scale: 0.5,
      opacity: 1.0,
      rotation: 0.0,
      rotateWithView: true,

      size: [cellSize, cellSize],
      offset: [x, y],

      anchor: [0.5, 0.5],
      displacement: gotSecondaryText ? [0, -3.5] : [0, 0.5],

      anchorOrigin: 'top-left',
      anchorYUnits: 'fraction',
      anchorXUnits: 'fraction',
    }),
  })

  orderLabelStylesCache.set(cacheId, style)

  return style
}
