import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../../typings'

/**
 * Create a new Driver.
 *
 * @private
 */
export const setGlobalRouteStyle = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  'on' | 'off',
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('ui/mapStyles/setGlobalRouteStyle', async (mode, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/ui/mapStyles/setGlobalRouteStyle', {
      type: 'rpc/ui/mapStyles/setGlobalRouteStyle',
      category: 'rpc',
      mode,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        error: result,
        type: 'rpcFailure',
        message: result.errorMessage,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {setGlobalRouteStyle}`,
      { tags: ['rpc', 'ui', 'map', 'mapStyles'], info: mode },
      'error',
    )

    return thunkApi.rejectWithValue({
      error,
      type: 'unknown',
      message: `Failed to update global Route Map Style`,
    })
  }
})
