import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import { isGpsOnly } from '@/features/domain/user'
import { RedirectToHome, PrivateRoute as Private, Navigate } from '@/routing'

import { Login } from './login/login'
import { ResetPassword } from './resetPassword'

import { Error, ErrorPageContent } from './error'
import { Events } from './events/Events'
import { Tracking } from './tracking/Tracking'
import { ImportOrders } from './importOrders/ImportOrders'
import { TooManySessions } from './error/TooManySessions'

import { Setup } from './setup/Setup'
import { Voice } from './setup/pages/voice'
import { Roads } from './setup/pages/roads'
import { Depots } from './setup/pages/depots'
import { Places } from './setup/pages/places'
import { Alerts } from './setup/pages/alerts'
import { Regions } from './setup/pages/regions'
import { Traffic } from './setup/pages/traffic'
import { Devices } from './setup/pages/devices'
import { Drivers } from './setup/pages/drivers/Drivers'
import { Vehicles } from './setup/pages/vehicles'
import { Geofences } from './setup/pages/geofences'
import { Territory } from './setup/pages/territory'
import { DriverActivity } from './setup/pages/driverActivity'
import { DriverAssistance } from './setup/pages/driverAssistance'
import { DriverAssignments } from './setup/pages/driverAssignments'

import { Settings } from './settings/Settings'
import { RmAccount } from './settings/pages/account/RmAccount'
import { GpsAccount } from './settings/pages/gpsAccount/GpsAccount'
import { GpsFormats } from './settings/pages/gpsFormats/GpsFormats'
import { Integrations } from './settings/pages/Integrations'
import { UserManagement } from './settings/pages/UserManagement/UserManagement'
import { Companies } from './settings/pages/companies'
import { Personalization } from './settings/pages/Personalization/Personalization'
import { MessagingSettings } from './setup/pages/notifications/MessagingSettings'
import { CommunicationPlan } from './settings/pages/messagingPlan/CommunicationPlan'
import { GpsPersonalization } from './settings/pages/gpsPersonalization/GpsPersonalization'
import { LanguageAndFormats } from './settings/pages/LanguageAndFormats/LanguageAndFormats'

import { Orders } from './routing/pages/orders'
import { Routing } from './routing/Routing'
import { RoutingHome } from './routing/pages/home'
import { Simulations } from './routing/pages/simulations'
import { NewClientVersionAvailable } from './NewClientVersionAvailable'

/**
 * The routing manager.
 */
export function Sections() {
  const gpsOnly = useSelector(isGpsOnly)

  return (
    <>
      <Routes>
        {/* ------------------------------------------------------------------------------------ */}
        {/* EXTERNAL ROUTES -------------------------------------------------------------------- */}
        {/* ------------------------------------------------------------------------------------ */}
        <Route path="import-orders" element={<Private element={<ImportOrders />} rm noRmGuest />} />

        {/* ------------------------------------------------------------------------------------ */}
        {/* PUBLIC ROUTES ---------------------------------------------------------------------- */}
        {/* ------------------------------------------------------------------------------------ */}

        {/* LOGIN ------------------------ */}
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<Login initialView="forgot-password" />} />
        <Route path="resetpassword" element={<ResetPassword />} />

        {/* ERROR ------------------------ */}
        <Route path="error" element={<Error />}>
          <Route path="404" element={<ErrorPageContent type="404" />} />
          <Route path="500" element={<ErrorPageContent type="500" />} />
          <Route path="logged-out" element={<ErrorPageContent type="logged-out" />} />
          <Route path="unsupported" element={<ErrorPageContent type="unsupported" />} />
          <Route path="generic-error" element={<ErrorPageContent type="generic" />} />
          <Route path="too-many-sessions" element={<TooManySessions />} />
          <Route
            path="invalid-reset-password-token"
            element={<ErrorPageContent type="invalid-reset-password-token" />}
          />

          {/* Fallback */}
          <Route index element={<Navigate<E> to="generic-error" replace />} />
        </Route>
        <Route path="*" element={<Navigate<M> to="error" replace />} />

        {/* ------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------ */}
        {/* PRIVATE ROUTES --------------------------------------------------------------------- */}
        {/* ------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------ */}

        <Route path="/" element={<Private element={<RedirectToHome />} />} />

        {/* ------------------------------ */}
        {/* TRACKING --------------------- */}
        <Route path="tracking" element={<Private element={<Tracking />} gps noSim noRmGuest />} />
        <Route path="events" element={<Private element={<Events />} gps noSim noRmGuest />} />

        {/* ------------------------------ */}
        {/* ROUTING ---------------------- */}
        <Route path="routing" element={<Private element={<Routing />} rm />}>
          <Route path="orders" element={<Private element={<Orders />} rm />} />
          <Route path="home" element={<Private element={<RoutingHome />} rm noRmGuest />} />

          <Route
            path="simulations"
            element={<Private element={<Simulations />} rm noRmViewer noRmGuest />}
          />

          {/* Fallback */}
          <Route index element={<Navigate<R> to="home" replace />} />
          <Route path="*" element={<Navigate<R> to="home" replace />} />
        </Route>

        {/* ------------------------------ */}
        {/* SETUP ------------------------ */}
        <Route path="setup" element={<Private element={<Setup />} noRmGuest />}>
          <Route path="roads" element={<Private element={<Roads />} rm />} />
          <Route path="depots" element={<Private element={<Depots />} rm />} />
          <Route path="vehicles" element={<Private element={<Vehicles />} />} />
          <Route path="drivers" element={<Private element={<Drivers />} rm />} />
          <Route path="regions" element={<Private element={<Regions />} rm />} />
          <Route path="traffic-area" element={<Private element={<Traffic />} rm />} />
          <Route
            path="driver-assignments"
            element={<Private element={<DriverAssignments />} rm noSim />}
          />

          {/* GPS */}
          <Route path="places" element={<Private element={<Places />} gps wwgps noSim />} />
          <Route path="alerts" element={<Private element={<Alerts />} gps wwgps noSim />} />
          <Route path="geofences" element={<Private element={<Geofences />} gps wwgps noSim />} />
          <Route path="devices" element={<Private element={<Devices />} gps wwgps noSim />} />

          {/* Notifications */}
          <Route
            path="tracking-app"
            element={<Private element={<MessagingSettings path="tracking-app" />} rm />}
          />
          <Route
            path="notifications"
            element={<Private element={<MessagingSettings path="notifications" />} rm />}
          />
          <Route
            path="preferences"
            element={<Private element={<MessagingSettings path="preferences" />} rm />}
          />
          <Route path="voice" element={<Private element={<Voice />} rm admin />} />

          {/* Driver Mobile App */}
          <Route path="territory" element={<Private element={<Territory />} rm />} />
          <Route path="driver-activity" element={<Private element={<DriverActivity />} rm />} />

          <Route
            path="driver-assistance"
            element={<Private element={<DriverAssistance />} rm admin />}
          />

          {/* Fallback */}
          <Route
            index
            element={
              <Private element={<Navigate<P> to={gpsOnly ? 'devices' : 'depots'} replace />} />
            }
          />
          <Route
            path="*"
            element={
              <Private element={<Navigate<P> to={gpsOnly ? 'devices' : 'depots'} replace />} />
            }
          />
        </Route>

        {/* ------------------------------ */}
        {/* SETTINGS --------------------- */}
        <Route path="settings" element={<Private element={<Settings />} />}>
          <Route
            path="user-management"
            element={<Private element={<UserManagement />} rm admin />}
          />

          <Route path="companies" element={<Private element={<Companies />} rm admin />} />

          <Route
            path="communication-plan"
            element={<Private element={<CommunicationPlan />} rm noRmGuest />}
          />

          <Route
            path="account"
            element={
              gpsOnly ? <Private element={<GpsAccount />} /> : <Private element={<RmAccount />} />
            }
          />

          <Route
            path="language-and-formats"
            element={
              gpsOnly ? (
                <Private element={<GpsFormats />} />
              ) : (
                <Private element={<LanguageAndFormats />} />
              )
            }
          />

          <Route
            path="personalization"
            element={
              gpsOnly ? (
                <Private element={<GpsPersonalization />} />
              ) : (
                <Private element={<Personalization />} />
              )
            }
          />

          <Route path="integrations" element={<Private element={<Integrations />} rm />} />

          {/* Fallback */}
          <Route path="*" element={<Navigate<S> to="account" replace />} />
          <Route index element={<Navigate<S> to="account" replace />} />
        </Route>

        {/* Blocking screen for new client version release */}
        <Route
          path="new-client-version-available"
          element={<Private element={<NewClientVersionAvailable />} />}
        />
      </Routes>
    </>
  )
}

type E = uui.routing.Error
type P = uui.routing.Setup
type R = uui.routing.Routing
type S = uui.routing.Settings
type M = uui.routing.MainSections
