import { useCallback } from 'react'

import { Button } from '@/local/components'
import { useNavigate } from '@/routing'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useUserAndTerritoryType } from './useUserAndTerritoryType'
import { useTexts } from './useTexts'

export function GoToSettingsButton() {
  const texts = useTexts()

  const type = useUserAndTerritoryType()
  const navigate = useNavigate<uui.routing.Settings>(true)

  const onClick = useCallback(() => {
    navigate('communication-plan')
  }, [navigate])

  return (
    <Tooltip placement="bottom" title={texts.tooltip(type)} disabled={type === 'rm360Admin'}>
      <Button
        preset="cancel"
        disabled={type !== 'rm360Admin'}
        className="pendo-orderform_order-notification-tab-view-settings-button"
        onClick={onClick}
      >
        {texts.text}
      </Button>
    </Tooltip>
  )
}
