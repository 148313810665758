import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

type Params = uui.domain.actions.rpc.simulation.DeleteSimulationsAction['params']

/**
 * Delete one or more0 Simulations.
 *
 * @private
 */
export const deleteSimulations = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Params,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/simulation/delete', async (params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/simulation/delete', {
      category: 'rpc',
      type: 'rpc/simulation/delete',
      params,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      'Unknown error on {deleteSimulations}',
      { tags: ['rpc', 'simulation'] },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Deleting Simulations Failed',
      error,
    })
  }
})
