import { type ReactNode } from 'react'
import { Stack } from '@mui/material'

export type Props = {
  testId?: string
  children: ReactNode
  right?: ReactNode
}

const rootSx = {
  '& > *': {
    flex: 1,
    overflow: 'hidden',
  },
}

export function ListItemRow(props: Props) {
  const { testId = 'ListItemRow', children, right } = props

  return (
    <Stack data-testid={testId} alignItems="center" direction="row" sx={rootSx}>
      {children}
      {!!right && right}
    </Stack>
  )
}
