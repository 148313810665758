import { Box, CircularProgress, Typography } from '@mui/material'
import { useController } from '../../hooks/useController'

export function Body() {
  const {
    status,
    data: { phoneNumbers },
  } = useController()

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      minHeight={50}
      display="flex"
      marginTop={2}
      width="100%"
    >
      {status === 'ready' ? (
        <Box width="100%">
          {phoneNumbers?.map(phoneNumber => (
            <Typography key={phoneNumber}>{phoneNumber}</Typography>
          ))}
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  )
}
