import { useCallback } from 'react'

import { IconButton } from '@/components/primitives/buttons'
import { Tooltip } from '@/components/primitives/Tooltip'
import { FitToMap, FitSelectedToMap } from '@/icons'
import { useFitMapToSelection } from '@/map'

import { useStyles } from '../../hooks/useStyles'
import { useFitAll } from './hooks/useFitAll'
import { useTexts } from './hooks/useTexts'

export function FitAllToggle() {
  const fitMapToSelection = useFitMapToSelection({ forceFit: true })
  const fitSelection = useFitAll()
  const classes = useStyles()
  const texts = useTexts()

  const tooltip = fitSelection ? texts.fitMapToSelection : texts.fitMap

  const testid = 'map-controls-fit-map'

  const onClick = useCallback(() => {
    fitMapToSelection()
  }, [fitMapToSelection])

  return (
    <Tooltip placement="bottom" title={tooltip}>
      <IconButton
        onClick={onClick}
        className={classes.button}
        data-testid={testid}
        data-trackid={testid}
      >
        {fitSelection ? (
          <FitSelectedToMap size={13} color="$nightRider" />
        ) : (
          <FitToMap size={13} color="$nightRider" />
        )}
      </IconButton>
    </Tooltip>
  )
}
