import type { DeviceInfoItem } from './DevicesList/DevicesList'

import { useState } from 'react'
import { useIntl } from '@/intl'

type WeekStartsOn = 'su' | 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa'

const weekStartsOnMap: Record<WeekStartsOn, number> = {
  su: 0,
  mo: 1,
  tu: 2,
  we: 3,
  th: 4,
  fr: 5,
  sa: 6,
}

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    arrDep: translate({ id: 'gps.geofences.arr_dep' }),
    timezone: translate({ id: 'gps.geofences.timezone' }),
    arriving: translate({ id: 'gps.geofences.arriving' }),
    departing: translate({ id: 'gps.geofences.departing' }),

    typeTitle: translate({ id: 'gps.geofences.edit.typeTitle' }),
    onetime: translate({ id: 'gps.geofences.repeatType.oneTime' }),
    endGeofence: translate({ id: 'gps.geofences.edit.endGeofence' }),
    repeatTitle: translate({ id: 'gps.geofences.edit.repeatTitle' }),
    neverEnd: translate({ id: 'gps.geofences.edit.endDateNeverEnd' }),
    continuous: translate({ id: 'gps.geofences.repeatType.continuous' }),
    customDate: translate({ id: 'gps.geofences.edit.endDateCustomDate' }),

    repeatAlwaysDescription: translate({ id: 'gps.geofences.edit.repeatAlwaysDescription' }),
    repeatOneTimeDescription: translate({ id: 'gps.geofences.edit.repeatOneTimeDescription' }),

    typeDescription: (type: 'arriving' | 'departing' | 'arr_dep') => {
      switch (type) {
        case 'arriving':
          return translate({ id: 'gps.geofences.edit.typeDescription.arriving' })
        case 'departing':
          return translate({ id: 'gps.geofences.edit.typeDescription.departing' })
        case 'arr_dep':
          return translate({ id: 'gps.geofences.edit.typeDescription.arrDep' })
      }
    },

    device: (
      key: 'filterPlaceholder' | 'checkAll' | 'unCheckAll' | 'footerDescription',
      props: { values: DeviceInfoItem[] },
      count: number = 0,
    ): string => {
      const { values } = props
      const total: number = values.length || 0

      switch (key) {
        case 'filterPlaceholder':
          return translate({
            id: 'gps.geofences.edit.fields.devicesList.filterPlaceholder',
          })
        case 'checkAll':
          return translate({
            id: 'gps.geofences.edit.fields.devicesList.checkAll',
            values: {
              count: total,
            },
          })

        case 'unCheckAll':
          return translate({
            id: 'gps.geofences.edit.fields.devicesList.uncheckAll',
            values: {
              count: total,
            },
          })

        case 'footerDescription':
          return translate({
            id: 'gps.geofences.edit.fields.devicesList.footerDescription',
            values: {
              total,
              checked: count,
            },
          })

        default:
          return key
      }
    },

    locationTitle: translate({ id: 'gps.geofences.edit.locationTitle' }),

    radiusTitle: translate({ id: 'gps.geofences.edit.radiusTitle' }),
    radiusDescription: translate({ id: 'gps.geofences.edit.radiusDescription' }),

    maxSpeed: translate({ id: 'gps.geofences.edit.maxSpeed' }),
    maxSpeedTitle: translate({ id: 'gps.geofences.edit.maxSpeedTitle' }),
    maxSpeedEnabled: translate({ id: 'gps.geofences.edit.maxSpeedEnabled' }),
    maxSpeedDisabled: translate({ id: 'gps.geofences.edit.maxSpeedDisabled' }),
    maxSpeedDescription: translate({ id: 'gps.geofences.edit.maxSpeedDescription' }),
    monitoredVehicles: translate({ id: 'gps.geofences.edit.monitoredVehicles' }),
    monitoredVehiclesCount: (count: number) =>
      translate({ id: 'gps.geofences.edit.monitoredVehicles', values: { count } }),

    geofenceNameTitle: translate({ id: 'gps.geofences.edit.nameTitle' }),
    geofenceNameDescription: translate({ id: 'gps.geofences.edit.nameDescription' }),

    weekDaysLongText: [
      translate({ id: 'global.calendar.days.long.sunday' }),
      translate({ id: 'global.calendar.days.long.monday' }),
      translate({ id: 'global.calendar.days.long.tuesday' }),
      translate({ id: 'global.calendar.days.long.wednesday' }),
      translate({ id: 'global.calendar.days.long.thursday' }),
      translate({ id: 'global.calendar.days.long.friday' }),
      translate({ id: 'global.calendar.days.long.saturday' }),
    ],

    daysShortLabels: [
      translate({ id: 'global.calendar.days.short.sunday' }),
      translate({ id: 'global.calendar.days.short.monday' }),
      translate({ id: 'global.calendar.days.short.tuesday' }),
      translate({ id: 'global.calendar.days.short.wednesday' }),
      translate({ id: 'global.calendar.days.short.thursday' }),
      translate({ id: 'global.calendar.days.short.friday' }),
      translate({ id: 'global.calendar.days.short.saturday' }),
    ],

    daysShortLabelsText: (value: WeekStartsOn): string[] => {
      const source = [
        translate({ id: 'global.calendar.days.short.sunday' }),
        translate({ id: 'global.calendar.days.short.monday' }),
        translate({ id: 'global.calendar.days.short.tuesday' }),
        translate({ id: 'global.calendar.days.short.wednesday' }),
        translate({ id: 'global.calendar.days.short.thursday' }),
        translate({ id: 'global.calendar.days.short.friday' }),
        translate({ id: 'global.calendar.days.short.saturday' }),
      ]

      const weekStartsOn = weekStartsOnMap[value]

      return weekStartsOn
        ? [...source.slice(weekStartsOn), ...source.slice(0, weekStartsOn)]
        : source
    },

    monthsLabelTexts: [
      translate({ id: 'global.calendar.months.long.january' }),
      translate({ id: 'global.calendar.months.long.february' }),
      translate({ id: 'global.calendar.months.long.march' }),
      translate({ id: 'global.calendar.months.long.april' }),
      translate({ id: 'global.calendar.months.long.may' }),
      translate({ id: 'global.calendar.months.long.june' }),
      translate({ id: 'global.calendar.months.long.july' }),
      translate({ id: 'global.calendar.months.long.august' }),
      translate({ id: 'global.calendar.months.long.september' }),
      translate({ id: 'global.calendar.months.long.october' }),
      translate({ id: 'global.calendar.months.long.november' }),
      translate({ id: 'global.calendar.months.long.december' }),
    ],

    startGeofence: translate({ id: 'gps.geofences.edit.startGeofence' }),
    startDate: translate({ id: 'gps.geofences.edit.startDate' }),
    startTime: translate({ id: 'gps.geofences.edit.startTime' }),
    endDate: translate({ id: 'gps.geofences.edit.endDate' }),
    endTime: translate({ id: 'gps.geofences.edit.endTime' }),
    tomorrow: translate({ id: 'gps.geofences.edit.compactCalendar.tomorrow' }),
    today: translate({ id: 'gps.geofences.edit.compactCalendar.today' }),
  }))

  return api
}
