import type { OptionalSelectionModifiers } from '@/atoms'
import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'

import { setMainSelection } from '@/atoms'

import { getMapSelectionContext } from '../../../../atoms/selection/read/getMapSelectionContext'

export function clickBreadcrumb(feature: Feature<Geometry>, modifiers: OptionalSelectionModifiers) {
  const mapSelectionContext = getMapSelectionContext()

  const breadcrumbId = feature.getId()?.toString()

  if (!breadcrumbId) return

  // select
  if (mapSelectionContext.type === 'main') {
    setMainSelection('breadcrumbs', [breadcrumbId], { modifiers, force: true })
  }
}
