import { Entry } from '../Entry'
import { JiraLinks } from './JiraLinks'

const ids = [
  7373, 7682, 10939, 10946, 10955, 11023, 11055, 11111, 11112, 11113, 11114, 11123, 11130, 11134,
  11166, 11177, 11178, 11179, 11180, 11181, 11182, 11187, 11204, 11227, 11243, 11245, 11246, 11247,
  11262, 11263, 11266, 11269, 11276, 11278, 11286, 11302, 11328, 11335, 11336, 11338, 11341, 11344,
  11345, 11346, 11370, 11371, 11374, 11375, 11376, 11378, 11379, 11389, 11391, 11392, 11393, 11394,
  11395, 11403, 11423, 11424, 11425, 11428, 11429, 11431, 11432, 11433, 11434, 11435, 11436, 11437,
  11438, 11439, 11440, 11441, 11444, 11445, 11446, 11452, 11453, 11456, 11457, 11458, 11462, 11463,
  11464, 11465, 11473, 11475, 11476, 11479, 11481, 11482, 11487, 11492, 11496, 11497, 11499, 11500,
  11503,
]

export function Entry20210922() {
  return (
    <Entry title="09/22/2021 - v3.0.0-beta-8">
      More relevant changes
      <ul>
        <li>Add Export to File</li>
        <li>Add the Orders Grid</li>
        <li>Add the SMS Phase 4</li>
        <li>Add Order Bulk Fit-in</li>
        <li>Add the Driver Assignment form</li>
        <li>Add Copy from Simulations/Archived days</li>
        <li>Add the Region form (no drawing on the Map)</li>
        <li>Add the Vehicle form (no live positions, no GPS settings)</li>
      </ul>
      Full list of Jira issues fixed:
      <br />
      <br />
      <JiraLinks ids={ids} />
      <br />
      <br />
    </Entry>
  )
}
