import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import {
  selectMobileAppInteraction,
  updateMobileAppInteraction as updateMobileAppInteractionAction,
} from '@/features/domain/territory'

import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useDriverAssistanceData() {
  const mobileAppInteraction = useSelector(selectMobileAppInteraction)
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const updateMobileAppInteraction = useCallback(
    async (value: uui.domain.client.rm.MobileAppInteraction) => {
      try {
        const result = await dispatch(updateMobileAppInteractionAction(value))

        if (updateMobileAppInteractionAction.rejected.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        //toast.success('SUCCESS')
      } catch (e) {
        toast.error('ERROR') // @DRIVERASSISTANCE Replace it with correct localized messages
      }
    },
    [dispatch, toast],
  )

  return [mobileAppInteraction, updateMobileAppInteraction] as const
}
