import { ActionBarItem } from '@workwave-tidal/components/ActionBar'
import { Close } from '@workwave-tidal/icons'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../../../../hooks/useController'
import { useTexts } from '../../useTexts'

export function ChooseRecipientHeader() {
  const texts = useTexts()
  const { close, data } = useController()
  const subtitle = data.gpsOnly
    ? texts.vehicles(data.devices.length)
    : texts.drivers(data.drivers.length)

  return (
    <ModalHeader
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      title={texts.shareLocation}
      subtitle={subtitle}
    />
  )
}
