import { ViewActions, ViewActionButton } from '@/formUi'
import { useTexts } from './useTexts'

type Props = {
  onEdit: () => void
}

export function LanguageAndFormatsViewActions(props: Props) {
  const { onEdit } = props

  const texts = useTexts()

  return (
    <ViewActions>
      <ViewActionButton
        onClick={onEdit}
        label={texts.edit}
        testId="edit-button"
        data-testid="settings-rm-account-edit-button"
        data-trackid="settings-rm-account-edit-button"
      />
    </ViewActions>
  )
}
