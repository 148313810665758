import { Alert, Stack } from '@mui/material'

import { RouteDetailsSchedule } from './components/RouteDetailsSchedule'
import { RouteDetailsTitle } from './components/RouteDetailsTitle'
import { CommandBar } from './components/CommandBar'
import { useTexts } from './useTexts'

interface Props {
  idx: string
  onEdit: () => void
  editing: boolean
  userType: uui.domain.UserType
  routeColor: string
  violations: uui.domain.rm.RouteViolations
  plannedEndTime: number
  trackedEndTime?: number
  violationsCount: number
  plannedStartTime: number
  trackedStartTime?: number
  editPermission: 'isRmOnly' | 'gotNoTrackedEvents' | 'allowed'
  endsAfterWorkingDayEnd: boolean
  startsBeforeWorkingDayStart: boolean
}

const containerStyle = { backgroundColor: 'grey.50' }

export function RouteDetailsHeader(props: Props) {
  const {
    idx,
    onEdit,
    editing,
    userType,
    routeColor,
    violations,
    plannedEndTime,
    trackedEndTime,
    editPermission,
    violationsCount,
    plannedStartTime,
    trackedStartTime,
    endsAfterWorkingDayEnd,
    startsBeforeWorkingDayStart,
  } = props

  const texts = useTexts()

  return (
    <Stack
      sx={containerStyle}
      spacing={3}
      padding={2}
      direction="column"
      borderBottom="1px solid"
      borderColor="grey.300"
      data-testid="routeDetailsHeader"
    >
      <RouteDetailsTitle
        idx={idx}
        routeColor={routeColor}
        violations={violations}
        violationsCount={violationsCount}
      />

      <RouteDetailsSchedule
        trackedEndTime={trackedEndTime}
        plannedEndTime={plannedEndTime}
        plannedStartTime={plannedStartTime}
        trackedStartTime={trackedStartTime}
        endsAfterWorkingDayEnd={endsAfterWorkingDayEnd}
        startsBeforeWorkingDayStart={startsBeforeWorkingDayStart}
      />

      {userType === 'rmOnly' ? (
        <Alert severity="info">{texts.rmAlertInfoBox}</Alert>
      ) : (
        <CommandBar onEdit={onEdit} editing={editing} editPermission={editPermission} />
      )}
    </Stack>
  )
}
