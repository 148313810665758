import { type SchedulerPro } from '@bryntum/schedulerpro'

import { domainProxy, store } from '@/store'

import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectDayRangeInfo } from '@/features/domain/scheduler'
import { selectTerritoryId } from '@/features/domain/territory'

import { setDefaultTimeSpanZoom } from './utils/setDefaultTimeSpanZoom'
import { setFullTimeSpan } from './utils/setFullTimeSpan'

let { dayStartOffset: lastDayStartOffset, dayLength: lastDayLength } = selectDayRangeInfo(
  store.getState(),
)
let lastCalendarRangeInterval = selectCalendarRangeInterval(store.getState())
let lastTerritoryId = selectTerritoryId(store.getState())
let lastPlanType = selectUserConfiguration(store.getState()).planType

export function startTimeSpanSync(scheduler: SchedulerPro) {
  return domainProxy.subscribeToDataChangeSuccess(async _domainAction => {
    const state = store.getState()

    // Retrieve new data
    const {
      dayStartOffset,
      dayLength,
      dayRange: { startSec: dayRangeStartSec, endSec: dayRangeEndSec },
    } = selectDayRangeInfo(state)

    const calendarRangeInterval = selectCalendarRangeInterval(state)
    const { planType } = selectUserConfiguration(state)
    const territoryId = selectTerritoryId(state)

    const calendarRangeChanged =
      calendarRangeInterval.start !== lastCalendarRangeInterval?.start ||
      calendarRangeInterval.end !== lastCalendarRangeInterval?.end

    const territoryChanged = territoryId !== lastTerritoryId
    const planTypeChanged = planType !== lastPlanType

    const somethingChanged =
      calendarRangeChanged ||
      territoryChanged ||
      planTypeChanged ||
      dayStartOffset !== lastDayStartOffset ||
      dayLength !== lastDayLength

    if (!somethingChanged) return

    scheduler.suspendRefresh()

    // Update last values
    lastCalendarRangeInterval = calendarRangeInterval
    lastDayStartOffset = dayStartOffset
    lastTerritoryId = territoryId
    lastDayLength = dayLength
    lastPlanType = planType

    // ---------------------------------------------------------------------------------------------
    // Set the full time span
    // ---------------------------------------------------------------------------------------------
    setFullTimeSpan(scheduler, calendarRangeInterval, dayStartOffset, dayLength)

    // ---------------------------------------------------------------------------------------------
    // Sync scheduler zoom level when the calendar range, the territory or the plan type changes
    // ---------------------------------------------------------------------------------------------
    if (calendarRangeChanged || territoryChanged || planTypeChanged) {
      await setDefaultTimeSpanZoom(
        scheduler,
        calendarRangeInterval,
        dayRangeStartSec,
        dayRangeEndSec,
      )
    }

    scheduler.resumeRefresh(true)
  })
}
