import type { CSSProperties } from 'styled-components'

import { makeStyles } from '@mui/styles'
import { useMemo } from 'react'

interface Props {
  minWidth?: number
  maxWidth?: number
}

const useClasses = makeStyles({
  modalContainer: {
    flexDirection: 'column',
    position: 'relative',
    maxHeight: '75%',
    display: 'flex',
    minWidth: 'var(--minWidth)',
    maxWidth: 'var(--maxWidth)',
    top: '50%',
    left: 0,
  },
})

export function useStyles(props: Props) {
  const { maxWidth = 484, minWidth = 484 } = props
  const classes = useClasses()

  const styles = useMemo(() => {
    return {
      modalContainer: {
        '--maxWidth': `${maxWidth}px`,
        '--minWidth': `${minWidth}px`,
      } as CSSProperties,
    } as const
  }, [maxWidth, minWidth])

  return [classes, styles] as const
}
