import { Icon, Style } from 'ol/style'

import { getVehicleIdText } from '../../../utils/getVehicleIdText'
import { genericStyles } from '../../genericStyles'

const vehicleIdStylesCache: Map<string, Style> = new Map()

export const getVehicleIdFeatureStyle = (
  marker: uui.domain.ui.map.markers.Vehicle,
  mode: uui.domain.ui.map.markers.MapStyles['vehicle']['mode'],
) => {
  if (mode === 'off') return genericStyles.hidden

  const cacheId = `${marker.idx}_${marker.textColor}`

  if (vehicleIdStylesCache.has(cacheId)) {
    const cachedStyle = vehicleIdStylesCache.get(cacheId)

    if (cachedStyle) return cachedStyle
  }

  const style = new Style({
    image: new Icon({
      opacity: 1,
      src:
        'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(getVehicleIdText(marker.idx, marker.textColor)),
      scale: 1.0,
    }),
  })

  vehicleIdStylesCache.set(cacheId, style)

  return style
}
