import type { DropDownOption } from '@/components/Dropdown'

import { useMemo } from 'react'

import { useTexts } from '../useTexts'

export function useTerritoryDropdownItems(
  territories: Record<string, uui.domain.client.rm.Territory>,
  territoryId: string,
) {
  const texts = useTexts()

  return useMemo<DropDownOption[]>(
    () =>
      Object.values(territories).map(
        (territory): DropDownOption => ({
          kind: 'item',
          id: territory.id,
          label: territory.name,
          selected: territoryId === territory.id,
          extraLabel: texts.reasons(territory.statusReasons.length),
        }),
      ),
    [texts, territories, territoryId],
  )
}
