import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, resetCrudSelection } from '@/atoms'
import { selectTrafficProfilesListStructure } from '@/features/domain/lists'
import { useReadOnly } from '@/hooks'

import { useEditing } from '../../../../../hooks/useEditing'

import { useSetTrafficAreasFilter } from './useSetTrafficAreasFilter'

export const useDataForList = () => {
  const structure = useSelector(selectTrafficProfilesListStructure)
  const [, setSelection] = useCrudSelection('trafficProfile')
  const { setEditTimeWindows, setCreateTrafficProfile } = useEditing('trafficArea')
  const setTrafficAreasFilter = useSetTrafficAreasFilter()
  const readonly = useReadOnly().readonly

  const deselectAll = useCallback(() => {
    setTrafficAreasFilter([])
    resetCrudSelection('trafficProfile')
  }, [setTrafficAreasFilter])

  const selectTrafficProfiles = useCallback(
    (ids: string[]) => {
      setTrafficAreasFilter(ids)
      setSelection(ids)
    },
    [setTrafficAreasFilter, setSelection],
  )

  return {
    structure,
    selectTrafficProfiles,
    deselectAll,
    readonly,
    onEditTimeWindows: setEditTimeWindows,
    onCreateTrafficProfile: setCreateTrafficProfile,
  }
}
