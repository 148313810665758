import { getLiveTrafficLayer } from './getLiveTrafficLayer'

export function setLiveTrafficLayerVisibility(visible: boolean) {
  const liveTrafficLayer = getLiveTrafficLayer()

  if (!liveTrafficLayer) {
    throw new Error(`No live traffic layer found.`)
  }

  liveTrafficLayer.setVisible(visible)
}
