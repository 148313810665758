import { Dispatch, SetStateAction, useCallback } from 'react'

type Props = {
  setQuery: Dispatch<SetStateAction<string>>
}

export function useOnSearchChange(props: Props) {
  const { setQuery } = props
  return useCallback(e => setQuery(e.currentTarget.value), [setQuery])
}
