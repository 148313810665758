import { Stack, Typography, Button } from '@mui/material'

import { useTexts } from './useTexts'

interface Props {
  onAddTerritory: () => void
  visible?: boolean
}

export function EmptyTerritories(props: Props) {
  const { visible, onAddTerritory } = props
  const texts = useTexts()

  if (!visible) return null

  return (
    <Stack display="flex" alignItems="center" sx={{ padding: 1 }}>
      <Typography variant="subtitle1" sx={{ color: 'grey.600' }}>
        {texts.title}
      </Typography>
      <Button onClick={onAddTerritory}>{texts.button}</Button>
    </Stack>
  )
}
