import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    failureTitle: translate({
      id: 'orders.smsNotifications.failure.title',
    }),

    failureDescription: translate({
      id: 'orders.smsNotifications.failure.description',
    }),
  }))

  return api
}
