import type { Filter } from '../typings'

import { useMemo } from 'react'

import { useTexts } from './useTexts'
import { useController } from './useController'

const filterValues: Filter[] = ['dispatched', 'selected']

export function useFilterOptions() {
  const texts = useTexts()
  const {
    data: { isSimulation, selectedRoutes, approvedRoutesAmount, selectedRoutesIds },
  } = useController()

  const selectedRoutesAmount = selectedRoutes.length

  // Options for filter RadioButton
  return useMemo(
    () =>
      filterValues.map(value => {
        const disabledForSimEnv = value === 'dispatched' && isSimulation
        const valueToCheck = value === 'dispatched' ? approvedRoutesAmount : selectedRoutesAmount
        const disabled = valueToCheck === 0 || disabledForSimEnv

        let label: string
        let description: string
        let tooltip: string = ''

        switch (value) {
          case 'dispatched':
            label = texts.filterDispatchedLabel
            description = texts.filterDispatchedDescription
            break
          case 'selected':
            label = texts.filterSelectedLabel
            description = texts.filterSelectedDescription
            tooltip = texts.filterSelectedDisabledTooltip(selectedRoutesIds.length)
            break
        }

        return {
          value,
          label,
          description,
          disabled,
          tooltip,
        }
      }),
    [isSimulation, approvedRoutesAmount, selectedRoutesAmount, texts, selectedRoutesIds],
  )
}
