import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Scheduler(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 14 14">
        <path d="M0,0.910836582 L0,13.0891634 C-2.39052704e-15,13.3653058 0.223857625,13.5891634 0.5,13.5891634 C0.595092011,13.5891634 0.688212279,13.5620467 0.768437746,13.5109942 L10.3371231,7.42183074 C10.5700938,7.27357667 10.6387702,6.96453294 10.4905161,6.73156225 C10.4512268,6.66982185 10.3988635,6.6174586 10.3371231,6.57816926 L0.768437746,0.489005838 C0.535467059,0.340751765 0.22642333,0.409428149 0.0781692561,0.642398836 C0.0271166859,0.722624304 -2.49272279e-17,0.815744571 0,0.910836582 Z" />
      </svg>
    </IconContainer>
  )
}
