import type { PartialStoreState } from '../typings'
import { createSelector } from '@reduxjs/toolkit'

export const selectLists = (state: PartialStoreState) => state.domain.publicData.domain.lists

// Select list options
export const selectUsersListOptions = createSelector(selectLists, l => l.users.options)
export const selectDepotsListOptions = createSelector(selectLists, l => l.depots.options)
export const selectPlacesListOptions = createSelector(selectLists, l => l.places.options)
export const selectDriversListOptions = createSelector(selectLists, l => l.drivers.options)
export const selectRegionsListOptions = createSelector(selectLists, l => l.regions.options)
export const selectVehiclesListOptions = createSelector(selectLists, l => l.unifiedVehicles.options)
export const selectDevicesListOptions = createSelector(selectLists, l => l.devices.options)
export const selectGeofencesListOptions = createSelector(selectLists, l => l.geofences.options)
export const selectOrderStepsListOptions = createSelector(selectLists, l => l.orderSteps.options)
export const selectOrdersGridListOptions = createSelector(selectLists, l => l.ordersGrid.options)
export const selectEventAlertsListOptions = createSelector(selectLists, l => l.eventAlerts.options)
export const selectSimulationsListOptions = createSelector(selectLists, l => l.simulations.options)
export const selectRoadSegmentsListOptions = createSelector(
  selectLists,
  l => l.roadSegments.options,
)
export const selectTrafficProfilesListOptions = createSelector(
  selectLists,
  l => l.trafficProfiles.options,
)
export const selectTrafficAreasListOptions = createSelector(
  selectLists,
  l => l.trafficAreas.options,
)
export const selectDriverAssignmentsListOptions = createSelector(
  selectLists,
  l => l.driverAssignments.options,
)
export const selectTrackingListOptions = createSelector(selectLists, l => l.tracking.options)
export const selectEventInfosListOptions = createSelector(selectLists, l => l.eventInfos.options)

// select list structure
export const selectUsersListStructure = createSelector(selectLists, l => l.users.structure)
export const selectDepotsListStructure = createSelector(selectLists, l => l.depots.structure)
export const selectPlacesListStructure = createSelector(selectLists, l => l.places.structure)
export const selectDriversListStructure = createSelector(selectLists, l => l.drivers.structure)
export const selectRegionsListStructure = createSelector(selectLists, l => l.regions.structure)
export const selectVehiclesListStructure = createSelector(
  selectLists,
  l => l.unifiedVehicles.structure,
)
export const selectGeofencesListStructure = createSelector(selectLists, l => l.geofences.structure)
export const selectOrderStepsListStructure = createSelector(
  selectLists,
  l => l.orderSteps.structure,
)
export const selectOrdersGridListStructure = createSelector(
  selectLists,
  l => l.ordersGrid.structure,
)
export const selectEventAlertsListStructure = createSelector(
  selectLists,
  l => l.eventAlerts.structure,
)
export const selectSimulationsListStructure = createSelector(
  selectLists,
  l => l.simulations.structure,
)
export const selectRoadSegmentsListStructure = createSelector(
  selectLists,
  l => l.roadSegments.structure,
)
export const selectTrafficProfilesListStructure = createSelector(
  selectLists,
  l => l.trafficProfiles.structure,
)
export const selectTrafficAreasListStructure = createSelector(
  selectLists,
  l => l.trafficAreas.structure,
)
export const selectDriverAssignmentsListStructure = createSelector(
  selectLists,
  l => l.driverAssignments.structure,
)
export const selectDevicesListStructure = createSelector(selectLists, l => l.devices.structure)

export const selectTrackingListStructure = createSelector(selectLists, l => l.tracking.structure)
export const selectEventInfosListStructure = createSelector(
  selectLists,
  l => l.eventInfos.structure,
)
