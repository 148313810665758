import { Up, Down } from '@/icons'
import StepControlsContainer from './StepControlsContainer'
import StepButton from './StepButton'
import StepButtonIconContainer from './StepButtonIconContainer'
import { Props } from './typings'

const StepControls = (props: Props) => {
  const { stepDownDisabled = false, onStepDown, onStepUp, stepUpDisabled = false, value } = props

  return (
    <StepControlsContainer>
      <StepButton data-value={`${value}`} onClick={onStepUp} disabled={stepUpDisabled}>
        <StepButtonIconContainer>
          <Up />
        </StepButtonIconContainer>
      </StepButton>
      <StepButton data-value={`${value}`} onClick={onStepDown} disabled={stepDownDisabled}>
        <StepButtonIconContainer>
          <Down />
        </StepButtonIconContainer>
      </StepButton>
    </StepControlsContainer>
  )
}

export default StepControls
