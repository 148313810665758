import type {
  ValidationStatus,
  EmailValidationStatus,
  PhoneValidationStatus,
  MessageValidationStatus,
} from '../typings'
import type { ModalData } from './useController'

import { useEffect, useReducer } from 'react'
import { produce } from 'immer'

import { isValidSms } from '@/utils'
import { useValidateEmail } from '@/hooks'

import { useController } from './useController'

const maxMessageLength = 160

const initialValidationStatus: ValidationStatus = {
  valid: false,
  email: 'pristine',
  phone: 'pristine',
  message: 'pristine',
}

const validateMessage = (message?: string): MessageValidationStatus => {
  if (message === undefined) return 'pristine'
  if (message.trim() === '') return 'empty'
  if (message.length > maxMessageLength) return 'tooLong'

  return 'valid'
}

const validateEmail = (
  emailValidation: ReturnType<typeof useValidateEmail>,
  email?: string,
): EmailValidationStatus => {
  if (email === undefined) return 'pristine'
  if (email.trim() === '') return 'empty'
  if (!emailValidation(email)) return 'invalidFormat'

  return 'valid'
}

const validatePhone = (phone?: string): PhoneValidationStatus => {
  if (phone === undefined) return 'pristine'
  if (phone.trim() === '') return 'empty'
  if (!isValidSms(phone)) return 'invalidFormat'

  return 'valid'
}

function createReducer(emailValidation: ReturnType<typeof useValidateEmail>) {
  return (prev: ValidationStatus, data: ModalData): ValidationStatus =>
    produce(prev, draft => {
      const { message, messageType } = data
      const phone = data.messageType === 'sms' ? data.phone : undefined
      const email = data.messageType === 'email' ? data.email : undefined

      draft.message = validateMessage(message)
      draft.email = messageType === 'email' ? validateEmail(emailValidation, email) : 'valid'
      draft.phone = messageType === 'sms' ? validatePhone(phone) : 'valid'
      draft.valid = draft.message === 'valid' && draft.email === 'valid' && draft.phone === 'valid'
    })
}

export const useValidations = () => {
  const emailValidation = useValidateEmail()
  const [validationStatus, updateValidation] = useReducer(
    createReducer(emailValidation),
    initialValidationStatus,
  )
  const { data } = useController()

  useEffect(() => {
    updateValidation(data)
  }, [data])

  return validationStatus
}
