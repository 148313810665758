import { useSelector } from 'react-redux'

import { useIsOffline, useApplicationMode, useExternalTransactionExist } from '@/atoms'
import { selectUserConfiguration } from '@/features/domain/user'
import { computeReadonlyStatus } from './utils/computeReadonlyStatus'

/**
 * Check if the users can edit the plan or not.
 */
export function useReadOnly() {
  const offline = useIsOffline()
  const [appMode] = useApplicationMode()
  const { planType, userType } = useSelector(selectUserConfiguration)
  const externalTransactionExist = useExternalTransactionExist()

  return computeReadonlyStatus(offline, appMode, planType, userType, externalTransactionExist)
}
