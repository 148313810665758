import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectEventAlertsListOptions,
  setListOptions,
  selectEventAlertsListStructure,
} from '@/features/domain/lists'
import { useEditingStateWithCtx } from '@/atoms'
import { useAppDispatch } from '@/store'

export function useSorter() {
  const listOptions = useSelector(selectEventAlertsListOptions)
  const listStructure = useSelector(selectEventAlertsListStructure)
  const { editing } = useEditingStateWithCtx('alert')
  const dispatch = useAppDispatch()

  const [field, setField] = useState(() => listOptions.sort[0].field)
  const [direction, setDirection] = useState(() => listOptions.sort[0].direction)

  useEffect(() => {
    if (!listOptions.sort) return
    if (listOptions.sort[0].field === field && listOptions.sort[0].direction === direction) return

    const sort =
      field === 'ui.sort.runningDevices'
        ? [
            { field, direction },
            { field: 'name', direction },
          ]
        : [
            { field, direction },
            { field: 'ui.sort.runningDevices', direction },
          ]

    dispatch(setListOptions({ category: 'eventAlerts', options: { ...listOptions, sort } }))
  }, [dispatch, field, direction, listOptions])

  return {
    editing,
    field,
    setField,
    direction,
    setDirection,
    noEntries: listStructure.totalItems === 0,
  }
}
