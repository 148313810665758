import styled from 'styled-components'

const NoneLabel = styled.p`
  display: block;
  width: 100%;
  margin: 5px 0;
  padding-left: 28px;
  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
`

NoneLabel.displayName = 'NoneLabel'
export default NoneLabel
