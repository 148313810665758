import styled from 'styled-components'

const height = 8
const trackBorderRadius = 2
const thumbHeight = 8
const thumbWidth = 8

export const InputRange = styled.input.attrs(() => ({
  type: 'range',
}))`
  -webkit-appearance: none;
  width: 100%;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${height}px;
    cursor: pointer;
    background: ${p => p.theme.colors.$silver};
    border-radius: ${trackBorderRadius}px;
  }

  &::-webkit-slider-thumb {
    position: relative;
    height: ${thumbHeight}px;
    width: ${thumbWidth}px;
    border: 2px solid ${p => p.theme.colors.$pureWhite};
    border-radius: 50%;
    background: ${p => p.theme.colors.$pureBlack};
    cursor: pointer;
    -webkit-appearance: none;
  }

  &:focus::-webkit-slider-runnable-track {
    background: ${p => p.theme.colors.$silver};
  }

  &::-moz-range-track {
    width: 100%;
    height: ${height}px;
    cursor: pointer;
    background: ${p => p.theme.colors.$silver};
    border-radius: ${trackBorderRadius}px;
  }

  &::-moz-range-thumb {
    height: ${thumbHeight}px;
    width: ${thumbWidth}px;
    border-radius: 50%;
    background: ${p => p.theme.colors.$pureWhite};
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: ${height}px;
    cursor: pointer;
    background: ${p => p.theme.colors.$silver};
    border-radius: ${trackBorderRadius}px;
  }

  &::-ms-thumb {
    height: ${thumbHeight}px;
    width: ${thumbWidth}px;
    border-radius: 50%;
    background: ${p => p.theme.colors.$pureWhite};
    cursor: pointer;
  }
`

InputRange.displayName = 'InputRange'
