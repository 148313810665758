import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    warningSimulationReplace: translate({
      id: 'copyModal.copyMode.warning.simulation.replace.description',
    }),
    warningArchivedReplace: translate({
      id: 'copyModal.copyMode.warning.archived.replace.description',
    }),
    warningSimulationAdd: translate({
      id: 'copyModal.copyMode.warning.simulation.add.description',
    }),
    numberOfOrders: (count: number) =>
      translate({ id: 'territoryCalendar.ordersTooltip', values: { count } }),
    warningArchivedAdd: translate({ id: 'copyModal.copyMode.warning.archived.add.description' }),
    title: (count: number) => translate({ id: 'copyModal.title', values: { count } }),
    areYouSure: translate({ id: 'copyModal.copyMode.warning.title' }),
    replaceOrders: translate({ id: 'copyModal.footer.replace' }),
    replace: translate({ id: 'copyModal.copyMode.replace' }),
    cancel: translate({ id: 'copyModal.footer.cancel' }),
    addOrders: translate({ id: 'copyModal.footer.add' }),
    subtitle: translate({ id: 'copyModal.subtitle' }),
    add: translate({ id: 'copyModal.copyMode.add' }),
    mode: translate({ id: 'copyModal.copyMode' }),
  }))

  return api
}
