import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function WarningRhombus(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 15 15">
        <path d="M14.652 6.634L8.365.346c-.462-.461-1.269-.461-1.73 0L.345 6.634c-.461.462-.461 1.269 0 1.73l6.288 6.288c.462.462 1.269.462 1.73 0l6.288-6.287c.462-.462.462-1.269 0-1.73zm-6.517 4.097c-.174.171-.406.23-.636.23-.23 0-.462-.059-.635-.23-.171-.173-.23-.347-.23-.635 0-.23.059-.462.23-.636.173-.171.406-.23.635-.23.23 0 .462.059.636.23.171.174.23.406.23.636 0 .23-.059.462-.23.635zm-.174-2.655h-.865c-.115-1.327-.52-2.77-.52-3.058 0-.288.117-.518.288-.692.117-.173.347-.288.635-.288.289 0 .519.115.692.288a.953.953 0 0 1 .289.692c-.057.289-.404 1.731-.519 3.058z" />
      </svg>
    </IconContainer>
  )
}
