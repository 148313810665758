import type { SearchableListTypes } from '../primitives/SearchableList'
import type { FieldRenderProps } from 'react-final-form'

import { PureComponent } from 'react'
import { Field as FinalFormField } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import SearchableListField from './SearchableListField'
import { PublicProps as Props } from './typings'

export default class SearchableListFieldWrapper<
  T extends SearchableListTypes.ListValue,
> extends PureComponent<Props<T>> {
  render() {
    const { name, ...props } = this.props

    return (
      <FinalFormField
        {...props}
        name={name}
        isEqual={isDeepEqual}
        render={(formProps: FieldRenderProps<any, any>) => {
          return <SearchableListField<T> {...this.props} formProps={formProps} />
        }}
      />
    )
  }
}
