import styled from 'styled-components'

import { CategoryButton } from './CategoryButton'

interface Props {
  badgeText?: number | string
}

export const CategoryButtonWithBadge = styled(CategoryButton)<Props>`
  &:after {
    top: 0px;
    left: 100%;
    width: 28px;
    height: 18px;
    display: block;
    line-height: 15px;
    border-radius: 9px;
    padding: 0px 4px;
    position: absolute;
    white-space: nowrap;
    text-transform: uppercase;
    content: '${p => p.badgeText}';
    font-size: ${p => p.theme.fonts.$xs};
    transform: translate(-50%, -50%);
    color: ${p => p.theme.colors.$pureWhite};
    background: ${p => p.theme.colors.$outrageousRed};
    border: 2px solid ${p => p.theme.colors.$pureWhite};
  }
`
CategoryButtonWithBadge.displayName = 'CategoryButtonWithBadge'
