import type { FormFields, FormErrors } from '../../formFields'

import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import { TextField } from '@workwave-tidal/tidal/form-ui'

import { selectUserType } from '@/features/domain/user'
import { ViewField } from '@/formUi'

import { useRmAccountData } from '../../../hooks/useRmAccountData'

import { useTexts } from './useTexts'

export function Name() {
  const userType = useSelector(selectUserType)
  const [account] = useRmAccountData()
  const texts = useTexts()

  const canEditName = ['admin', 'planner', 'viewer', 'gpsonly', 'guest'].includes(userType)

  return canEditName ? (
    <Box paddingTop={1}>
      {/* Please remove the above <Box /> when https://workwave.atlassian.net/browse/CUI-419 will be fixed */}
      <TextField<'name', FormFields, FormErrors>
        name="name"
        testId="name"
        label={texts.name}
        validateOn="blur change"
      />
    </Box>
  ) : (
    <ViewField label={texts.name}>{account.name}</ViewField>
  )
}
