import { useRefreshGpsData } from '@/hooks'
import { useResetCrudSelectionOnUnmount } from '@/atoms'

import { useAlertsRoot } from './hooks/useAlertsRoot'

import { List } from './List'
import { SingleView } from './Single/View'
import { BulkView } from './Bulk/View'
import { SingleEdit } from './Single/Edit'

export function AlertsRoot() {
  const { editing, alerts, selection, selectedAlerts } = useAlertsRoot()

  const bulk = selectedAlerts.length > 1
  const single = selectedAlerts.length === 1

  const alert = selectedAlerts[0]

  // Let's request a refresh of GPS data
  useRefreshGpsData('alert')

  useResetCrudSelectionOnUnmount('alerts')

  return (
    <>
      <List editing={editing} alerts={alerts} selection={selection} />
      {single && !editing && alert && <SingleView alert={alert} />}
      {bulk && !editing && <BulkView alerts={selectedAlerts} />}
      {editing && !bulk && <SingleEdit alert={alert} />}
    </>
  )
}
