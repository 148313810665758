import styled from 'styled-components'

export const ExceptionSliderButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 26px;
  width: 26px;

  border-width: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  border-radius: 6px;

  color: ${p => p.theme.colors.$nightRider};
  background: ${p => p.theme.colors.$silver};

  &:hover {
    background: ${p => p.theme.colors.$shadyLady};
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &:disabled:hover {
    background: ${p => p.theme.colors.$silver};
  }
`

ExceptionSliderButton.displayName = 'ExceptionSliderButton'
