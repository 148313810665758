import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    oldPassword: translate({
      id: 'settings.rmAccount.view.changePasswordModal.fields.oldPassword.label',
    }),
    newPassword: translate({
      id: 'settings.rmAccount.view.changePasswordModal.fields.newPassword.label',
    }),
    repeatNewPassword: translate({
      id: 'settings.rmAccount.view.changePasswordModal.fields.repeatNewPassword.label',
    }),
  }))

  return api
}
