import { useSelector } from 'react-redux'

import { selectGpsTrackingProvider } from '@/features/domain/user'
import { selectWorkingDayStartSec } from '@/features/domain/territory'
import { useFormTransaction } from '@/hooks'
import { usePagination } from '@/atoms'

import { createEmptyUnifiedVehicle } from './utils/createEmptyUnifiedVehicle'
import { GeneralSettings } from './GeneralSettings'
import { useTexts } from './hooks/useTexts'
import { Exception } from './Exception'

interface Props {
  unifiedVehicle?: uui.domain.client.UnifiedVehicle
}

export function SingleEdit(props: Props) {
  const { unifiedVehicle } = props
  const { page } = usePagination('vehicleTab')
  const texts = useTexts()

  const workingDayStartSec = useSelector(selectWorkingDayStartSec)
  const trackingProvider = useSelector(selectGpsTrackingProvider)

  const transactionState = useFormTransaction()
  const transactionOpen = transactionState === 'ready'

  if (!unifiedVehicle) {
    const emptyUnifiedVehicle = createEmptyUnifiedVehicle(texts.newVehicle, {
      startSec: workingDayStartSec,
      endSec: workingDayStartSec + 24 * 60 * 60,
    })
    return (
      <GeneralSettings
        trackingProvider={trackingProvider}
        transactionOpen={transactionOpen}
        unifiedVehicle={emptyUnifiedVehicle}
        creating
      />
    )
  }

  switch (page) {
    case 0:
      return (
        <GeneralSettings
          trackingProvider={trackingProvider}
          transactionOpen={transactionOpen}
          unifiedVehicle={unifiedVehicle}
        />
      )

    case 1:
      return <Exception unifiedVehicle={unifiedVehicle} transactionOpen={transactionOpen} />

    default:
      return null
  }
}
