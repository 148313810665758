import { makeStyles } from '@mui/styles'
import { Bulk } from './components/Bulk'
import { Single } from './components/Single'

interface Props {
  routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[]
}

const useStyles = makeStyles({
  container: {
    minWidth: 260,
    height: '100%',
    padding: '2px 0',
  },
})

export function Details(props: Props) {
  const { routeInfos } = props
  const classes = useStyles()

  if (routeInfos.length === 0) return null

  return (
    <div
      className={classes.container}
      data-trackid="navigo-routes-overview-details"
      data-testid="navigo-routes-overview-details"
    >
      {routeInfos.length > 1 ? (
        <Bulk routeInfos={routeInfos} />
      ) : (
        <Single routeInfo={routeInfos[0]} />
      )}
    </div>
  )
}
