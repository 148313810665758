import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage } from '@/features/domain/user'

export function useUpdateLangAttributeOnHtml() {
  const language = useSelector(selectLanguage)

  useEffect(() => {
    document.documentElement.setAttribute('lang', language)
  }, [language])
}
