import { useMemo } from 'react'

export function useLanguageOptions(supportedLanguages: Record<string, string>) {
  return useMemo(() => {
    return Object.keys(supportedLanguages)
      .sort()
      .map(label => ({
        type: 'option',
        value: supportedLanguages[label],
        label,
      }))
  }, [supportedLanguages])
}
