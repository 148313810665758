import type { ReactNode, ReactElement } from 'react'

import { useCallback } from 'react'

import { HorizontalLayout } from '@/components/layout'

import { Indicator } from './components/Indicator'
import { ClickableContainer } from './components/ClickableContainer'

interface Props {
  trigger: ReactElement
  collapsed: boolean
  setCollapsed: (value: boolean) => void
  children?: ReactNode
}

export function Collapsible(props: Props) {
  const { children, trigger, collapsed, setCollapsed } = props

  const handleOnClick = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed, setCollapsed])

  return (
    <>
      <ClickableContainer onClick={handleOnClick} marginBottom={collapsed ? 0 : 8}>
        <HorizontalLayout alignItems="center">
          <Indicator collapsed={collapsed} />
          {trigger}
        </HorizontalLayout>
      </ClickableContainer>
      <>{!collapsed && children}</>
    </>
  )
}
