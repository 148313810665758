import styled from 'styled-components'

type Props = {
  length?: number
  char?: string
  empty?: boolean
}

export const ReadonlyFieldPassword = styled.span<Props>`
  color: ${p => (p.empty ? p.theme.colors.$silver : p.theme.colors.$nightRider)};
  &::after {
    content: '${p => p.char ?? '.'.repeat(p.length ?? 10)}';
    letter-spacing: 2px;
  }
`

ReadonlyFieldPassword.displayName = 'ReadonlyFieldPassword'
