import styled from 'styled-components'

interface Props {
  primary?: boolean
}

export const CopySettingsButton = styled.button.attrs(() => ({
  type: 'button',
}))<Props>`
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 22px;
  margin: 0;
  padding: 0;
  border: 0;
  background: ${p => (p.primary ? p.theme.colors.$scienceBlue : p.theme.colors.$silver)};
  color: ${p => (p.primary ? p.theme.colors.$pureWhite : p.theme.colors.$nightRider)};
  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
  border-radius: 4px;
  line-height: normal;
  cursor: pointer;
  user-select: none;
  outline: 0;

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${p => p.theme.colors.$whiteSmoke};
  }
`

CopySettingsButton.displayName = 'CopySettingsButton'
