import type { PartialStoreState } from '../typings'
import { createSelector } from '@reduxjs/toolkit'
import { selectDevices } from '../device'

export const selectServerGeofences = (state: PartialStoreState) =>
  state.domain.publicData.domain.gps.wwgps.geofences

export const selectGeofences = createSelector(
  selectServerGeofences,
  selectDevices,
  (geofences, devices) => {
    const geofenceIds = Object.keys(geofences)
    const deviceIds = new Set(Object.keys(devices))

    // Filter out deviceIds that are hidden to the user
    return geofenceIds.reduce<Record<string, uui.domain.client.gps.wwgps.Geofence>>(
      (acc, geofenceId) => {
        acc[geofenceId] = {
          ...geofences[geofenceId],
          deviceIds: geofences[geofenceId].deviceIds.filter(deviceId => deviceIds.has(deviceId)),
        }

        return acc
      },
      {},
    )
  },
)
