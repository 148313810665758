import { Stack, Typography, Button } from '@mui/material'

import { ErrorImage } from '../ErrorImage'
import { ErrorPageFooter } from '../ErrorPageFooter'
import { ErrorPageLayout } from '../ErrorPageLayout'

import { useTexts } from './useTexts'

export function GenericErrorPage() {
  const texts = useTexts()

  return (
    <ErrorPageLayout picture={<ErrorImage />} footer={<ErrorPageFooter />}>
      <Stack spacing={4}>
        <Stack spacing={0}>
          <Typography variant="h3">{texts.title}</Typography>
          <Typography variant="h4">{texts.description}</Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={() => (window.location.href = '/')}>
            {texts.primaryAction}
          </Button>
        </Stack>
      </Stack>
    </ErrorPageLayout>
  )
}
