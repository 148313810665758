import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    label: translate({ id: 'supportTicket.fields.topic.label' }),
    routing: translate({ id: 'supportTicket.fields.topic.option.routing' }),
    depotsVehicleDrivers: translate({
      id: 'supportTicket.fields.topic.option.depotsVehicleDrivers',
    }),
    regionsTrafficRoads: translate({ id: 'supportTicket.fields.topic.option.regionsTrafficRoads' }),
    optimizing: translate({ id: 'supportTicket.fields.topic.option.optimizing' }),
    mobileApp: translate({ id: 'supportTicket.fields.topic.option.mobileApp' }),
    notifications: translate({ id: 'supportTicket.fields.topic.option.notifications' }),
    sms: translate({ id: 'supportTicket.fields.topic.option.sms' }),
    email: translate({ id: 'supportTicket.fields.topic.option.email' }),
    tracking: translate({ id: 'supportTicket.fields.topic.option.tracking' }),
    virtualTracking: translate({ id: 'supportTicket.fields.topic.option.virtualTracking' }),
    physicalTracking: translate({ id: 'supportTicket.fields.topic.option.physicalTracking' }),
    other: translate({ id: 'supportTicket.fields.topic.option.other' }),
    api: translate({ id: 'supportTicket.fields.topic.option.api' }),
    users: translate({ id: 'supportTicket.fields.topic.option.users' }),
    styleOnMap: translate({ id: 'supportTicket.fields.topic.option.styleOnMap' }),
    geocoding: translate({ id: 'supportTicket.fields.topic.option.geocoding' }),
    feedback: translate({ id: 'supportTicket.fields.topic.option.feedback' }),
  }))

  return api
}
