import { useMemo, useCallback } from 'react'
import { useEditingStateWithCtx } from '@/atoms'

export function useEditing(ctx: 'trafficArea' | 'trafficProfile') {
  const { editing, editingEntities, setEditing } = useEditingStateWithCtx(ctx)

  const editingEntity = useMemo(() => {
    if (!editing) {
      return 'none'
    }

    const singleEntity = editingEntities.length === 1
    const entity = singleEntity ? editingEntities[0] : undefined

    switch (entity) {
      case 'edit-time-windows':
        return 'edit-time-windows'
      case 'create-traffic-profile':
        return 'create-traffic-profile'
      case 'edit-traffic-profile':
        return 'edit-traffic-profile'

      default:
        return 'traffic-area'
    }
  }, [editing, editingEntities])

  const setEditTimeWindows = useCallback(() => {
    setEditing(['edit-time-windows'])
  }, [setEditing])

  const setCreateTrafficProfile = useCallback(() => {
    setEditing(['create-traffic-profile'])
  }, [setEditing])

  const setEditTrafficProfile = useCallback(() => {
    setEditing(['edit-traffic-profile'])
  }, [setEditing])

  const setEditTrafficArea = useCallback(
    (trafficAreaId: string) => {
      setEditing([trafficAreaId])
    },
    [setEditing],
  )

  const setEditCreateTrafficArea = useCallback(() => {
    setEditing([])
  }, [setEditing])

  return {
    editingEntity,
    setEditTimeWindows,
    setCreateTrafficProfile,
    setEditTrafficProfile,
    setEditTrafficArea,
    setEditCreateTrafficArea,
  } as const
}
