import type { ReactNode, CSSProperties, ReactElement } from 'react'
import type { Theme } from '@mui/material'

import { makeStyles } from '@mui/styles'

import { FlexBox, Text, getColorWithAlpha } from '@/local/components'
import { clsx } from '@/utils'

import { DownArrow } from '../../icons/DownArrow'

type Props = {
  disabled?: boolean
  Icon?: ReactElement
  className?: string
  menuOpen?: boolean
  children?: ReactNode
  testid?: string
}

const downIconStyle: CSSProperties = {
  opacity: 0.4,
}

const useStyles = makeStyles<Theme, { disabled?: boolean; menuOpen?: boolean }>({
  trigger: props => ({
    opacity: props.disabled ? 0.5 : 1,
    transition: 'background 0.3s ease',
    background: getColorWithAlpha('$pureWhite', props.menuOpen ? 0.2 : 0),
  }),
})

export function MenuTrigger(props: Props) {
  const { className, menuOpen, Icon, children, disabled, testid } = props
  const classes = useStyles({ menuOpen, disabled })

  return (
    <FlexBox
      h={36}
      shrink={0}
      borderRadius={4}
      color="$pureWhite"
      position="relative"
      data-testid={testid}
      data-trackid={testid}
      vAlignContent="center"
      padding={'0 8px 0 12px'}
      className={clsx(className, classes.trigger)}
    >
      {Icon && <div>{Icon}</div>}
      {/* <Icon preset={props.icon} size={16} marginRight={10} /> */}

      <Text ellipsis size="$p2" weight="$regular" marginRight={6}>
        {children}
      </Text>
      <DownArrow size={14} style={downIconStyle} />
    </FlexBox>
  )
}
