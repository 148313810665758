import { ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../../../useTexts'

export function ButtonClose() {
  const ctrl = useController()
  const texts = useTexts()

  return (
    <ModalFooterButton
      className="pendo-moving-paired-order__error-close"
      testid="moving-paired-order__error-close"
      onClick={ctrl.close}
      variant="contained"
    >
      {texts.btnErrorClose}
    </ModalFooterButton>
  )
}
