import type { OnSelectCallbackParams } from '@/components/StructuredVirtualList'

import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import {
  usePagination,
  useMainSelection,
  resetMainSelection,
  useEditingStateWithCtx,
} from '@/atoms'
import { useReadOnly } from '@/hooks'
import { selectRoutes } from '@/features/domain/route'
import { selectTerritory } from '@/features/domain/territory'
import { fitMapToSelection } from '@/map'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectOrderStepsListStructure } from '@/features/domain/lists'

import { TabValues } from '../typings'

export function useDataForList(tab: TabValues) {
  const tabRef = useRef(tab)
  useEffect(() => void (tabRef.current = tab), [tab])

  const structure = useSelector(selectOrderStepsListStructure)

  // selection
  const { setPage } = usePagination('orderTab')
  const [selection, select] = useMainSelection('orderSteps')
  const lastSelectedOrderStep = selection.length > 0 ? selection[selection.length - 1] : undefined

  const { setEditing } = useEditingStateWithCtx('order')
  const territory = useSelector(selectTerritory)
  const userConfiguration = useSelector(selectUserConfiguration)
  const routes = useSelector(selectRoutes)

  const { readonly } = useReadOnly()

  const onDoubleClick = useCallback(() => {
    if (readonly) return

    setPage(0)

    setEditing([...selection])
  }, [readonly, setEditing, setPage, selection])

  const onClick = useCallback(() => {
    if (readonly) return

    setPage(0)
    resetMainSelection('orderSteps')
    setEditing([])
  }, [readonly, setEditing, setPage])

  const onSelectAll = useCallback(() => {
    const allSelected = structure.shownItems <= selection.length

    if (allSelected) {
      resetMainSelection('orderSteps')
    } else {
      select(
        structure.list.reduce<string[]>((acc, item) => {
          if (typeof item === 'string') {
            acc.push(item)
          }
          return acc
        }, []),
      )

      fitMapToSelection({ preventIfVisible: true }, 'add')
    }
  }, [select, selection, structure])

  const onSelect = useCallback(
    (params: OnSelectCallbackParams<'orderSteps'>) => {
      select(
        params.newSelectedIds.reduce<string[]>((acc, orderId: string) => {
          if (!orderId.startsWith('group')) {
            acc.push(orderId)
          }
          return acc
        }, []),
      )

      fitMapToSelection({ preventIfVisible: true }, 'add')
    },
    [select],
  )

  return {
    routes,
    onClick,
    onSelect,
    structure,
    onSelectAll,
    onDoubleClick,
    userConfiguration,
    lastSelectedOrderStep,
    workingDayStartSec: territory.workingDayStartSec,
  }
}
