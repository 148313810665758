import type { Components } from '@mui/material'

export const MuiAccordion: Components['MuiAccordion'] = {
  styleOverrides: {
    root: {
      background: 'transparent',
      boxShadow: 'none',
      margin: 0,
      '&::before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        margin: 0,
      },
      '&.Mui-disabled': {
        background: 'transparent',
      },
    },
  },
}
