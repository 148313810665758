import { useEffect, useMemo, useState } from 'react'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { TextField } from '@workwave-tidal/tidal/components'

import { type FormErrors, type FormFields } from '../../../../../formFields'
import { useTexts } from '../../../../../useTexts'

export function Database() {
  const [database, setDatabase] = useState<string>('')
  const { fieldApi, errors } = useFormField<'credentials', FormFields, FormErrors>('credentials')

  const texts = useTexts()

  const { helperText, error } = useMemo(() => {
    const usernameErrors = errors.filter(e => e.index === 2)
    if (usernameErrors.length === 0) {
      return {
        helperText: '',
        error: false,
      }
    }

    return {
      helperText: usernameErrors[0].message,
      error: true,
    }
  }, [errors])

  useEffect(() => {
    const usernameCredential = fieldApi
      .getField()
      .value.find(credential => credential.fieldName === 'username') ?? {
      fieldName: 'username',
      value: '',
    }
    const passwordCredential = fieldApi
      .getField()
      .value.find(credential => credential.fieldName === 'password') ?? {
      fieldName: 'password',
      value: '',
    }

    fieldApi.change([
      usernameCredential,
      passwordCredential,
      { fieldName: 'database', value: database },
    ])
  }, [database, fieldApi])

  return (
    <TextField
      name="database"
      size="medium"
      label={texts.geotab.databaseLabel}
      value={database}
      error={error}
      onChange={e => setDatabase(e.target.value)}
      helperText={helperText}
    />
  )
}
