import styled from 'styled-components'

type Props = {
  selected?: boolean
  hideSelectionUnderline?: boolean
  multiline?: boolean
}

const styledComponent = styled.span<Props>`
  position: relative;

  display: flex;
  flex-direction: ${p => (p.multiline ? 'column' : 'row')};
  align-items: ${p => (p.multiline ? 'center' : 'flex-start')};
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    height: 1px;
    background: ${p => p.theme.colors.$pureWhite};
    opacity: ${p => (p.selected && !p.hideSelectionUnderline ? 1 : 0)};
    transition: 0.3s ease;
    transition-property: background;
  }
`
styledComponent.displayName = 'HorizontalRadioLabelText'
export default styledComponent
