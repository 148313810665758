import { IconButton, Stack } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { Tooltip } from '@/components/primitives/Tooltip'
import { UserMenu } from '../UserMenu'
import { useTexts } from '../hooks/useTexts'
import { useActions } from './hooks/useActions'

type Props = {
  selectedUsers: uui.domain.client.rm.RouteManagerUser[]
}

export function UserActions(props: Props) {
  const { onEditUser, onDeleteUser } = useActions()
  const texts = useTexts()

  return (
    <Stack direction="row" alignItems="center" justifyContent="end">
      <Tooltip placement="bottom" title={texts.tooltips.edit}>
        <IconButton onClick={onEditUser} data-testid="user-edit" data-trackid="user-edit">
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip placement="bottom" title={texts.tooltips.delete}>
        <IconButton onClick={onDeleteUser} data-testid="user-delete" data-trackid="user-delete">
          <Delete />
        </IconButton>
      </Tooltip>
      <UserMenu {...props} />
    </Stack>
  )
}
