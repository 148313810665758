import { useDeviceInfo } from './useDeviceInfo'
import { DriverBehaviors } from './DriverBehaviors'
import { SpeedLimit } from './SpeedLimit'
import { MaxSpeedLimit } from './MaxSpeedLimit'
import { IdleStartLimit } from './IdleStartLimit'
import { Badges } from './Badges'
import { MaintenanceMileage } from './MaintenanceMileage'

interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
}

export function GpsData(props: Props) {
  const { device } = props

  const deviceInfo = useDeviceInfo(device.deviceId)

  const hasDriverBehaviorCapability = device.capabilities.includes('drb-threshold')
  const hasIdleThresholdCapability = device.capabilities.includes('idle-threshold')

  const speedMonitor = deviceInfo.speedMonitor
  const driverBehaviors = deviceInfo.driverBehaviors
  const gpsTags = deviceInfo.gpsTags ?? []
  const idleStartThreshold = deviceInfo.idleStartThreshold

  const deviceConfiguration = deviceInfo.deviceConfiguration

  return (
    <>
      <Badges gpsTags={gpsTags} />
      {!!driverBehaviors && hasDriverBehaviorCapability && (
        <DriverBehaviors driverBehaviors={driverBehaviors} />
      )}
      {hasIdleThresholdCapability && <IdleStartLimit idleStartThreshold={idleStartThreshold} />}
      {!!speedMonitor && <SpeedLimit speedMonitor={speedMonitor} />}
      {!!driverBehaviors && <MaxSpeedLimit driverBehaviors={driverBehaviors} />}
      {!!deviceConfiguration && <MaintenanceMileage deviceConfiguration={deviceConfiguration} />}
    </>
  )
}
