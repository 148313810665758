import {
  selectDriverAssignments,
  createLocalDriverAssignment,
} from '@/features/domain/driverAssignments'

import { setDriverAssignmentEditMode, setCrudSelection, setEditingState } from '@/atoms'
import { getMatchingRecurrenceByDate, parseRecurrence } from '@/server-data'
import { ControlledNavigateFunction } from '@/routing'
import { selectUserType } from '@/features/domain/user'
import { ReadOnlyStatus } from '@/hooks'
import { store } from '@/store'

function setEditing(entities: string[]) {
  setEditingState({ ctx: 'driversAssignment', editing: true, editingEntities: entities })
}
/**
 * Start vehicle editing and navigate to the correct page.
 */
export async function editDriverAssignment(
  date: string,
  readonlyStatus: ReadOnlyStatus,
  navigate: ControlledNavigateFunction<uui.routing.Routing | uui.routing.Setup>,
) {
  const state = store.getState()
  const driverAssignments = selectDriverAssignments(state)
  const userType = selectUserType(state)

  // Try to find the specified recurrence in the driver assignments
  const driverAssignment = driverAssignments[date]

  // The only allowed callback is when a courier user is editing a driver assignment
  const allowEditAssignment = readonlyStatus.readonly
    ? readonlyStatus.cause === 'courier' && userType === 'courier'
    : true

  // If there's no driver assignment for the specified date, we create a new one
  const create = !driverAssignment

  // Parse the recurrence from the date
  const recurrence = parseRecurrence(date)

  if (!recurrence) {
    throw new Error(`Cannot parse recurrence with date: ${date}`)
  }

  // Find the closest recurrence to the specified date
  const closestRecurrence = getMatchingRecurrenceByDate(
    recurrence.source,
    Object.keys(driverAssignments),
  )

  // If the user is not allowed to edit the driver assignment, we navigate to the driver assignment page
  if (!allowEditAssignment) {
    setCrudSelection('driverAssignments', [closestRecurrence])
    navigate('driver-assignments')
    return
  }

  // If we're creating a new driver assignment, we create a new one before editing it
  if (create) {
    const rawAssignments = driverAssignments[closestRecurrence]

    const assignments = Object.entries(
      rawAssignments,
    ).reduce<uui.domain.client.rm.DriverAssignments>((acc, [vehicleId, driverId]) => {
      if (driverId.endsWith('-external')) return acc

      acc[vehicleId] = driverId
      return acc
    }, {})

    const result = await store.dispatch(
      createLocalDriverAssignment({ recurrence: recurrence.source, assignments }),
    )

    if (!createLocalDriverAssignment.fulfilled.match(result)) {
      throw new Error(result.payload?.message ?? 'Internal error')
    }
  }

  // Set the selection to the specified date and start editing the driver assignment
  setCrudSelection('driverAssignments', [date])
  setDriverAssignmentEditMode({ editMode: create ? 'create' : 'edit' })
  setEditing([date])
  navigate('driver-assignments')
}
