export function computeLoads(
  loads: Record<string, number>,
  minLoads: Record<string, number>,
): uui.domain.ui.forms.ExceptionFormValues['loads'] {
  return Object.entries(loads).reduce<uui.domain.ui.forms.ExceptionFormValues['loads']>(
    (acc, [key, value]) => {
      const vehicleLoad = {
        value,
        min: minLoads[key] || 0,
      }
      acc[key] = vehicleLoad
      return acc
    },
    {},
  )
}
