import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../renderingQueue'
import { getLayerMetadata, setLayerMetadata } from '../layerMetadata'
import { findFeature } from '../findFeature'

import { setOrderLayerMetadata } from './orderLayerMetadata'

import { updateOrderFeature } from './utils/updateOrderFeature'
import { findOrderMarker } from './utils/findOrderMarker'

const nonblockingRender = createNonBlockingRenderByTime()

export const updateOrdersMapSelection = async (
  layer: VectorLayer<VectorSource>,
  mapMarkers: uui.domain.ui.map.markers.MapMarkers['order'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['order'],
  selection: Set<string>,
  selectedOrderIds: Set<string>,
) => {
  // retrieve selected items in the current layer
  const layerSelection = (getLayerMetadata(layer, 'selection') ?? new Set()) as Set<string>

  // if prev and next are empty do nothing
  if (selection.size !== 0 || layerSelection.size !== 0) {
    const ordersToDeselect = new Set(layerSelection)

    nonblockingRender.reset()

    for (const markerId of selection) {
      await nonblockingRender.next()

      // remove it from orderId to be deselected
      ordersToDeselect.delete(markerId)

      const marker = findOrderMarker(mapMarkers, markerId)

      if (!marker) {
        throw new Error(
          `Trying to select Order marker with lat-lng: ${markerId} for ID: ${markerId} but no feature exist on the map`,
        )
      }
      const feature = findFeature(layer, marker.id)

      // ATTENTION: since introducing stacked orders' partial selection is not possible to skip already selected stacked orders
      if (layerSelection.has(markerId) && Object.keys(marker.ids).length === 1) {
        // orderId was previously selected and it's still selected do nothing
        continue
      }

      if (!feature) {
        throw new Error(
          `Trying to select Order marker with lat-lng: ${markerId} for ID: ${markerId} but no feature exist on the map`,
        )
      }

      // the orderId is a new selection
      updateOrderFeature(mapStyles, marker, feature, selectedOrderIds, true)
    }

    nonblockingRender.reset()

    for (const markerId of ordersToDeselect) {
      await nonblockingRender.next()

      const marker = findOrderMarker(mapMarkers, markerId)
      if (!marker) {
        console.warn(
          `Trying to deselect Order marker with lat-lng: ${markerId} for ID: ${markerId} but no feature exist on the map`,
        )
        continue
      }

      const feature = findFeature(layer, marker.id)

      if (!feature) {
        // fail silently in production when deselecting unknown items
        if (process.env.NODE_ENV === 'development') {
          console.warn(
            `Trying to deselect Order marker with lat-lng: ${markerId} for ID: ${markerId} but no feature exist on the map`,
          )
        }

        continue
      }

      // deselect the order
      updateOrderFeature(mapStyles, marker, feature, selectedOrderIds, false)
    }
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
  setOrderLayerMetadata(layer, 'selectedOrderIds', selectedOrderIds)
}
