import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { IconButton, Stack, Typography } from '@mui/material'
import { ChevronLeft, InfoOutlined } from '@mui/icons-material'

import { selectUserType } from '@/features/domain/user'
import { Tooltip } from '@/components/primitives/Tooltip'
import {
  selectTotalTelematicsLicenses,
  selectUsedTelematicsLicenses,
} from '@/features/domain/account'

import { useTexts } from '../../../hooks/useTexts'

type Props = {
  setIntegrationId: (integrationId: string | undefined) => void
  tenantSource: uui.domain.server.gps.telematics.TenantSource
}

export function TitleBar(props: Props) {
  const { tenantSource, setIntegrationId } = props

  const totalLicenses = useSelector(selectTotalTelematicsLicenses)
  const usedLicenses = useSelector(selectUsedTelematicsLicenses)
  const userType = useSelector(selectUserType)
  const texts = useTexts()

  const onBackButtonClick = useCallback(() => {
    setIntegrationId(undefined)
  }, [setIntegrationId])

  const isAdmin = userType === 'admin'

  return (
    <Stack spacing={2}>
      <Stack spacing={1} direction="row" alignItems="center">
        <IconButton aria-label="back" onClick={onBackButtonClick}>
          <ChevronLeft />
        </IconButton>

        <Stack direction="row" alignItems="center">
          <Typography variant="h6" fontWeight={500}>
            {texts.integrations}
          </Typography>
          &nbsp;&nbsp;&nbsp;
          <Typography variant="h6" fontWeight={500}>
            {` • `}
          </Typography>
          &nbsp;&nbsp;&nbsp;
          {isAdmin ? (
            <>
              <Typography variant="body2" fontWeight={400}>
                {`${texts.totalUsedLicenses(usedLicenses)}`}
              </Typography>
              &nbsp;
              <Typography
                variant="subtitle4"
                fontWeight={totalLicenses === 0 ? 700 : 400}
                color={totalLicenses === 0 ? '#FF9500' : undefined}
              >
                {totalLicenses}
              </Typography>
              &nbsp;
              <Typography variant="body2" fontWeight={400}>
                {`${texts.ofAvailable}`}
              </Typography>
              &nbsp;
              {totalLicenses === 0 && (
                <Tooltip placement="bottom" title={texts.noIntegrations.noLicenses.tooltip}>
                  <span style={{ display: 'inline-flex', color: '#FF9500' }}>
                    <InfoOutlined />
                  </span>
                </Tooltip>
              )}
            </>
          ) : (
            <Typography
              variant="subtitle4"
              fontWeight={totalLicenses === 0 ? 700 : 400}
              color={totalLicenses === 0 ? '#FF9500' : undefined}
            >
              {texts.totalLicencesAvailable(totalLicenses)}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Typography variant="h5" fontWeight={400}>
        {tenantSource.label}
      </Typography>
    </Stack>
  )
}
