import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    requiredField: translate({ id: 'sms4.validations.requiredField' }),

    messageTooLong: (maxLength: number) =>
      translate({
        id: 'ui.hotWords.maxLengthExceeded',
        values: { maxLength },
      }),

    subjectTooLong: (maxLength: number) =>
      translate({
        id: 'sms4.validations.SmsSubjectTooLong',
        values: { maxLength },
      }),
  }))

  return api
}
