import { useEffect } from 'react'

import { useNotification } from '@/hooks'
import { domainProxy } from '../domain/domainProxy'
import { useJournal } from './useJournal'

export const useDomainMessage = () => {
  const toast = useNotification()
  const journal = useJournal()

  useEffect(() => {
    let effectCleaned = false

    try {
      const unsubscribe = domainProxy.subscribeToNotifications(
        notification => {
          if (effectCleaned) return

          if (notification.notificationType === 'message') {
            const message = notification.payload

            const autoClose: false | undefined = message.sticky ? false : undefined
            const options = { autoClose }

            switch (message.kind) {
              case 'default':
                toast.dark(message.text, options)
                break

              case 'info':
                toast.info(message.text, options)
                break

              case 'success':
                toast.success(message.text, options)
                break

              case 'warn':
                toast.warn(message.text, options)
                break

              case 'error':
                toast.error(message.text, options)
                break

              case 'fatalError':
                // TODO: navigate to 50x page?
                toast.error(message.text, options)
                break
            }

            journal.atoms(`Active RoutePlan info updated`, { info: notification })
          }
        },
        ['message'],
      )

      return () => {
        effectCleaned = true
        unsubscribe()
      }
    } catch (e) {
      journal.atoms(
        `useDomainMessage - Impossible to subscribe to Domain Notifications`,
        { info: e },
        'error',
      )
    }
  }, [toast, journal])
}
