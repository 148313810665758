import { Box } from '@mui/material'

import { useSetupEditing } from '../../hooks/useSetupEditing'
import { useRefreshDriverAssistance } from './hooks/useRefreshDriverAssistance'
import { DriverAssistanceFormLayout } from './components/DriverAssistanceFormLayout'
import { DriverAssistanceView } from './components/DriverAssistanceView'

export function DriverAssistance() {
  const { editing, onEdit, onDiscard } = useSetupEditing('driver-assistance', 'reset')

  // Refresh Driver assistance from the BE to avoid the presence of old data
  useRefreshDriverAssistance()

  return (
    <Box display="flex" width="100%" height="100%">
      {!editing && <DriverAssistanceView onEdit={onEdit} />}
      {editing && <DriverAssistanceFormLayout onDiscard={onDiscard} />}
    </Box>
  )
}
