import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectTrackingListStructure } from '@/features/domain/lists'
import { fitMapToSelection } from '@/map'

import { useListHandlers } from '../../../../hooks/useListHandlers'
import { useDataForList } from '../../../../hooks/useDataForList'

export function useTitle() {
  const listStructure = useSelector(selectTrackingListStructure)
  const { selection, deselectAll } = useDataForList()
  const { handleOnSelectAll: selectAll } = useListHandlers()

  const onSelectAll = useCallback(() => {
    const allSelected = listStructure.shownItems <= selection.length

    if (allSelected) {
      deselectAll()
    } else {
      selectAll()

      fitMapToSelection({ preventIfVisible: true }, 'add')
    }
  }, [selectAll, deselectAll, selection, listStructure])

  return {
    selection,
    onSelectAll,
    listStructure,
    noEntries: listStructure.totalItems === 0,
  }
}
