import { intl } from '@/intl'

const maxSpeedDisabledValue = -1

export function validateMaxSpeedLimit(speedLimit: uui.domain.ui.forms.MaxSpeedLimitData) {
  const { threshold, min, max } = speedLimit

  if (threshold === maxSpeedDisabledValue) return

  if (typeof threshold !== 'number') {
    return intl.translate({
      id: 'vehicles.form.edit.generalSettings.validation.speedLimit.notNumeric',
    })
  }

  if (threshold < min || threshold > max) {
    return intl.translate({
      id: 'vehicles.form.edit.generalSettings.validation.speedLimit.range',
      values: { min, max },
    })
  }
}
