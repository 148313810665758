import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

/**
 * Load Order step Notifications
 *
 * @private
 */
export const loadOrderStepUnsubscribeData = createAsyncThunk<
  // Return type of the payload creator
  uui.domain.actions.rpc.order.LoadOrderStepUnsubscribeDataActionResult['unsubscribeData'],
  // First argument to the payload creator
  uui.domain.actions.rpc.order.LoadOrderStepUnsubscribeDataAction['payload'],
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/order/loadOrderStepUnsubscribeData', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/order/loadOrderStepUnsubscribeData', {
      category: 'rpc',
      type: 'rpc/order/loadOrderStepUnsubscribeData',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    return result.unsubscribeData
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {loadOrderStepUnsubscribeData}`,
      { tags: ['rpc', 'order'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Load order step unsuscribed data for ${payload.ordStepId.orderId} failed`,
      error,
    })
  }
})
