import { Component } from 'react'
import { Box } from '@mui/material'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material'

import ColorPickerContainer from './ColorPickerContainer'
import ColorPickerButton from './ColorPickerButton'
import ColorPickerChip from './ColorPickerChip'
import ColorPickerText from './ColorPickerText'

import { Props } from './typings'

export interface State {
  open: boolean
}

const setOpen = (open: boolean) => () => ({ open })
const toggleOpen =
  () =>
  ({ open }: State) => ({ open: !open })

const isColorEqual = (a: string, b: string): boolean =>
  getColorValue(a).toUpperCase() === getColorValue(b).toUpperCase()

const defaultWildCards: string[] = ['transparent']

const getColorValue = (color: string, wildcards: string[] = defaultWildCards): string => {
  if (wildcards.includes(color)) {
    return color
  }
  if (color.startsWith('#') || color.startsWith('rgb')) return color
  return `#${color}`
}

export class ColorPicker extends Component<Props, State> {
  readonly state: State = {
    open: false,
  }

  private handleOnToggle = () => {
    this.setState(toggleOpen())
  }

  private handleOnChange = (color: string) => () => {
    const { onChange } = this.props
    onChange(color)
    this.setState(setOpen(false))
  }

  render() {
    const { value, colors } = this.props
    const { open } = this.state

    const dots = open
      ? colors.map((c: string) => {
          return (
            <ColorPickerChip
              key={`dot_${c}`}
              innerColor={c}
              onClick={this.handleOnChange(c)}
              selected={isColorEqual(c, value)}
              testid="color-chip-button"
            />
          )
        })
      : []

    return (
      <>
        <ColorPickerButton
          type="button"
          onClick={this.handleOnToggle}
          data-testid="color-picker-button"
        >
          <Box width={15}>
            <svg width="15" height="15">
              <rect
                strokeWidth="3"
                fill={getColorValue(value)}
                rx="3.5"
                height="15"
                width="15"
                y="0"
                x="0"
              />
            </svg>
          </Box>
          <Box style={{ maxHeight: 17, display: 'flex', alignItems: 'center' }}>
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        </ColorPickerButton>

        {open && (
          <>
            <ColorPickerText>Pick a Color</ColorPickerText>
            <ColorPickerContainer data-testid="colors-palette">{dots}</ColorPickerContainer>
          </>
        )}
      </>
    )
  }
}
