import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AccordionSummary, AccordionDetails, Accordion, Box } from '@mui/material'

import { generateRelativePath, Link } from '@/routing'
import { Gears, Down } from '@/icons'

import { MainLink } from '../../../MainLink'

import { useAdvancedCollapsed } from '../../hooks/useCollapseStatus'
import { LinkList } from '../LinkList'
import { SubLink } from '../SubLink'

import { useCanShow } from './hooks/useCanShow'
import { useTexts } from './useTexts'

type SetupRoutes = uui.routing.Setup

export function Advanced() {
  const { pathname } = useLocation()
  const path = generateRelativePath(pathname) as uui.routing.Setup

  const [collapsed, setCollapsed, toggleCollapsed] = useAdvancedCollapsed()

  useEffect(() => {
    switch (path) {
      case 'regions':
      case 'roads':
      case 'traffic-area':
      case 'places':
      case 'geofences':
      case 'alerts':
      case 'devices':
        setCollapsed(false)
        break
    }
  }, [setCollapsed, path])

  const canShow = useCanShow()
  const texts = useTexts()

  return (
    <Box marginTop={1} marginBottom={1}>
      <Accordion data-trackid="setup-advanced" data-testid="setup-advanced" expanded={!collapsed}>
        <AccordionSummary
          expandIcon={<Down size={13} color="$nightRider" />}
          onClick={toggleCollapsed}
        >
          <MainLink
            Icon={<Gears size={20} color="$nightRider" />}
            data-trackid="setup-advanced-expand"
            data-testid="setup-advanced-expand"
            text={texts.advanced}
            summary
          />
        </AccordionSummary>

        <AccordionDetails>
          {
            // @ts-expect-error
            <LinkList component="nav">
              <Link<SetupRoutes> to="regions">
                <SubLink fontSize="$m" text={texts.regions} testid="setup__regions-button" />
              </Link>

              <Link<SetupRoutes> to="roads">
                <SubLink fontSize="$m" text={texts.roadEditor} testid="setup__roads-button" />
              </Link>

              <Link<SetupRoutes> to="traffic-area">
                <SubLink fontSize="$m" text={texts.trafficArea} testid="setup__traffic-button" />
              </Link>

              {canShow.devices && (
                <Link<SetupRoutes> to="devices">
                  <SubLink fontSize="$m" text={texts.devices} testid="setup__devices-button" />
                </Link>
              )}

              {canShow.places && (
                <Link<SetupRoutes> to="places">
                  <SubLink fontSize="$m" text={texts.places} testid="setup__places-button" />
                </Link>
              )}

              {canShow.geofences && (
                <Link<SetupRoutes> to="geofences">
                  <SubLink fontSize="$m" text={texts.geofences} testid="setup__geofences-button" />
                </Link>
              )}

              {canShow.alerts && (
                <Link<SetupRoutes> to="alerts">
                  <SubLink fontSize="$m" text={texts.alerts} testid="setup__alerts-button" />
                </Link>
              )}
            </LinkList>
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
