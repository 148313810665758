import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: (count: number) =>
      translate({
        id: 'vehicles.form.exceptions.bulk.header.title',
        values: { count },
      }),
    headerDescription: translate({
      id: 'vehicles.form.exceptions.bulk.header.description.gpsOnly',
    }),
  }))

  return api
}
