import { useState, useCallback, MouseEvent } from 'react'

import { IconButton, Tooltip } from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'

import { DropdownMenu, DropdownMenuItem } from '@/components/DropdownMenu'

import { useTexts } from './useTexts'

interface Props {
  onDeleteAllTimeWindows: () => void
  deleteAllTimeWindowsDisabled?: boolean
}

const noop = (e: MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault()
  return
}

export function Menu(props: Props) {
  const { onDeleteAllTimeWindows, deleteAllTimeWindowsDisabled } = props

  const [open, setOpen] = useState(false)
  const texts = useTexts()

  const onRemoveAllAndCloseMenu = useCallback(() => {
    onDeleteAllTimeWindows()
    setOpen(false)
  }, [onDeleteAllTimeWindows])

  return (
    <DropdownMenu
      setOpen={setOpen}
      open={open}
      autoWidth
      triggerTestid="bulk-timeWindows-more-btn"
      trigger={
        // href="#" onClick={noop} ==> Hack to have an <a> component and not a button
        <Tooltip title={texts.moreTooltip}>
          <IconButton href="#" onClick={noop}>
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <Tooltip
        title={deleteAllTimeWindowsDisabled ? texts.deleteAllTimeWindowsDisabledTooltip : ''}
      >
        <span>
          <DropdownMenuItem
            size="$l"
            weight="$regular"
            onClick={onRemoveAllAndCloseMenu}
            testid="bulk-timeWindows-remove-all"
            warn
            disabled={deleteAllTimeWindowsDisabled}
          >
            {texts.removeAll}
          </DropdownMenuItem>
        </span>
      </Tooltip>
    </DropdownMenu>
  )
}
