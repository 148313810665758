import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Swap } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  disabled: boolean
}

export function SwapButton(props: Props) {
  const { disabled, onClick } = props
  const texts = useTexts()

  const iconColor = disabled ? '$silver' : '$nightRider'

  return (
    <ReadOnlyTooltip
      render={preventEditing => (
        <NavigoIconButton
          onClick={onClick}
          text={texts.swap}
          disabled={preventEditing}
          Icon={<Swap size={20} color={iconColor} />}
          testid="navigo-routes-overview-actions-swap"
        />
      )}
    ></ReadOnlyTooltip>
  )
}
