import type { CustomOptions, Options, StandardType, Transformers } from '../types'
import { standardTransformers } from './standardTransformers'

export function getParserByType<T>(type: StandardType): Transformers<T>
export function getParserByType<T>(
  type: 'custom',
  options: Options<T> | CustomOptions<T>,
): Transformers<T>
export function getParserByType<T>(
  type: StandardType | 'custom',
  options?: Options<T> | CustomOptions<T>,
): Transformers<T> {
  switch (type) {
    case 'string':
      return standardTransformers.string as any

    case 'boolean':
      return standardTransformers.boolean as any

    case 'integer':
      return standardTransformers.integer as any

    case 'float':
      return standardTransformers.float as any

    case 'timestamp':
      return standardTransformers.timestamp as any

    case 'isoDateTime':
      return standardTransformers.isoDateTime as any

    case 'json':
      return standardTransformers.json as any

    case 'custom':
      // TODO add a proper guard to validate `CustomOptions`
      return options as Transformers<T>
  }
}
