import type { AggregateLoad } from '../types'

import { Stack } from '@mui/material'
import { ReadonlyLabel } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'
import { AggregateLoad as AggregateLoadUi } from './AggregateLoad'

interface Props {
  aggregateLoads: AggregateLoad[]
}

export function DynamicLoads(props: Props) {
  const { aggregateLoads } = props
  const texts = useTexts()

  const testId = 'orders-view-dynamic-loads'

  return (
    <Stack data-testid={testId}>
      <ReadonlyLabel primary>{texts.loads.title}</ReadonlyLabel>

      {aggregateLoads.map(aggregateLoad => (
        <AggregateLoadUi key={aggregateLoad.label} aggregateLoad={aggregateLoad} />
      ))}
    </Stack>
  )
}
