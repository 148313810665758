import { ListHeaderCounter, ListHeaderTitle, ListHeaderTitleContainer } from '@/components/List'

import { useTexts } from './hooks/useTexts'
import { useTitle } from './hooks/useTitle'

const noop = () => {}

export function Title() {
  const { editing, selection, listStructure } = useTitle()

  const texts = useTexts()

  return (
    <ListHeaderTitleContainer>
      <ListHeaderTitle>{texts.title}</ListHeaderTitle>

      <ListHeaderCounter
        allSelected={selection.length >= listStructure.shownItems}
        disabled={editing}
        // traffic profiles selection cannot be multiple
        onClick={noop}
        readonly
      >
        {listStructure.shownItems}
      </ListHeaderCounter>
    </ListHeaderTitleContainer>
  )
}
