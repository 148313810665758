import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { isGpsOnly } from '@/features/domain/user'

export function useBadgesInfo(vehicle: uui.domain.client.UnifiedVehicle) {
  const gpsOnly = useSelector(isGpsOnly)

  return useMemo(() => {
    const badges = vehicle.hasRoutingLicense
      ? vehicle.tags
      : vehicle.allowGps && vehicle.isPhysicalDevice
      ? vehicle.gpsTags
      : []

    const text =
      badges.length === 0
        ? '-'
        : badges.reduce<string>(
            (acc, curr, index: number) => (index === 0 ? curr.value : `${acc}, ${curr.value}`),
            '',
          )

    return {
      text,
      hasNoBadges: badges.length === 0,
      showBadges: !gpsOnly,
    }
  }, [vehicle, gpsOnly])
}
