import { styled } from '@mui/material'

const styledComponent = styled('span', { name: 'OrderAvatarViolations' })`
  position: absolute;
  bottom: -8px;
  left: 50%;
  width: calc(100% - 4px);
  height: 4px;
  border-radius: 2px;
  background: ${p => p.theme.palette.warning.main};
  transform: translateX(-50%);
`

styledComponent.displayName = 'OrderAvatarViolations'
export default styledComponent
