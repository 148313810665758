import type Feature from 'ol/Feature'

import Point from 'ol/geom/Point'
import Circle from 'ol/geom/Circle'
import Geometry from 'ol/geom/Geometry'

import { Fill, Stroke, Style } from 'ol/style'

import { gis } from '@/server-data'

const whiteStroke = new Stroke({ width: 2, color: [255, 255, 255, 1] })
const circleFill = new Fill({ color: [48, 48, 48, 0.3] })

export const geofenceCircleStyle = new Style({
  fill: circleFill,
  stroke: whiteStroke,
  geometry: (feature: Feature<Geometry>) => {
    if (!feature) return

    const radius = feature.getProperties().radius as number
    if (!radius) return

    const geometry = feature.getGeometry()

    if (geometry instanceof Geometry && geometry.getType() === 'Point') {
      const center = geometry as Point
      const centerCoordinate = center.getCoordinates()

      const outerLatLng = gis.computeOffset(
        gis.fromCoordinateToLatLng(centerCoordinate),
        radius,
        90,
      )
      const outerCoordinate = gis.fromLatLngToCoordinate(outerLatLng)

      const dx = centerCoordinate[0] - outerCoordinate[0]
      const dy = centerCoordinate[1] - outerCoordinate[1]
      const radiusInCoordinates = Math.sqrt(dx * dx + dy * dy)

      return new Circle(centerCoordinate, radiusInCoordinates)
    }
  },
})
