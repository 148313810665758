import styled from 'styled-components'

const FooterContainer = styled.footer`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`

FooterContainer.displayName = 'FooterContainer'
export default FooterContainer
