import { useResetVehicleFormException, useResetCrudSelectionOnUnmount } from '@/atoms'

import { useRefreshGpsData } from '@/hooks'

import { useVehiclesRoot } from './hooks/useVehiclesRoot'
import { SingleView } from './Single/View'
import { SingleEdit } from './Single/Edit'
import { BulkView } from './Bulk/View'
import { List } from './List'

export function VehiclesRoot() {
  useResetVehicleFormException()

  const { editing, vehicles, selection, selectedVehicles } = useVehiclesRoot()

  // Let's request a refresh of GPS data
  useRefreshGpsData('unifiedVehicles')

  useResetCrudSelectionOnUnmount('unifiedVehicles')

  const bulk = selectedVehicles.length > 1
  const single = selectedVehicles.length === 1

  const unifiedVehicle = selectedVehicles[0]

  return (
    <>
      <List editing={editing} vehicles={vehicles} selection={selection} />
      {single && !editing && unifiedVehicle && <SingleView unifiedVehicle={unifiedVehicle} />}
      {bulk && <BulkView vehicles={selectedVehicles} />}
      {editing && !bulk && <SingleEdit unifiedVehicle={unifiedVehicle} />}
    </>
  )
}
