import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Cross(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M6.7,2.15a3.62,3.62,0,0,0-5,0A3.34,3.34,0,0,0,1.69,7L11,16l-9.3,9a3.34,3.34,0,0,0,0,4.85,3.62,3.62,0,0,0,5,0l9.3-9,9.3,9a3.62,3.62,0,0,0,5,0,3.34,3.34,0,0,0,0-4.85L21,16l9.3-9a3.34,3.34,0,0,0,0-4.85,3.62,3.62,0,0,0-5,0l-9.3,9Z" />
      </svg>
    </IconContainer>
  )
}
