import { Stroke, Style } from 'ol/style'
import tinycolor from 'tinycolor2'

import { genericStyles } from '../../genericStyles'

const routePolylineMarkerStylesCache: Map<string, Style[]> = new Map()

/**
 * Return the right style to be used by a RoutePolyline feature
 */
export function getRoutePolylineFeatureStyle(
  marker: uui.domain.ui.map.markers.RoutePolyline,
  mode: uui.domain.ui.map.markers.MapStyles['routePolyline']['mode'],
) {
  if (mode === 'off' || !marker.fetched) return genericStyles.hidden

  const cacheId = marker.color

  if (routePolylineMarkerStylesCache.has(cacheId)) {
    const cachedStyle = routePolylineMarkerStylesCache.get(cacheId)
    if (cachedStyle) return cachedStyle
  }

  const { r, g, b } = tinycolor(marker.color).toRgb()

  const styles = [
    // Full stroke
    new Style({
      stroke: new Stroke({
        color: [r, g, b, 1],
        width: 3,
      }),
    }),

    // Dashed stroke
    new Style({
      geometry: 'pedestrianLegs',
      stroke: new Stroke({
        color: [r, g, b, 1],
        lineDashOffset: 6,
        lineDash: [4, 8],
        width: 3,
      }),
    }),
  ]

  routePolylineMarkerStylesCache.set(cacheId, styles)

  return styles
}
