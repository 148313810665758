import {
  SecondaryColumnHeaderActionsRow,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsSeparator,
} from '@/forms-legacy'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { ArrowRoundedUpEdge, Trash } from '@/icons'

import { useActions } from '../hooks/useActions'
import { useTexts } from '../hooks/useTexts'

interface Props {
  onDelete: () => void
}

export function Actions(props: Props) {
  const { onDelete } = props
  const actions = useActions()
  const texts = useTexts()

  return (
    <SecondaryColumnHeaderActionsRow>
      <SecondaryColumnHeaderActionButton
        label={texts.exportKML}
        icon={<ArrowRoundedUpEdge />}
        onClick={actions.onExport}
        testid="setup-traffic__exportkml"
      />

      <SecondaryColumnHeaderActionsSeparator />

      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={onDelete}
            disabled={preventEditing}
            testid="setup-traffic__delete"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
