import { useCallback } from 'react'

import { inspectPin } from '../inspectPin'
import { addInspectPin } from '../core/addInspectPin'
import { removeInspectPin } from '../core/removeInspectPin'

/**
 * Create the inspect location pin or remove it.
 */
export function useToggleInspectPin() {
  return useCallback(() => {
    inspectPin.pin ? removeInspectPin() : addInspectPin()
  }, [])
}
