import { useCallback } from 'react'
import {
  CircularButton,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyLabel,
  ViewManager,
} from '@/forms-legacy'

import { Cross } from '@/icons'
import ActionsManager from './components/ActionsManager/ActionsManager'
import {
  Props,
  ViewType as ActionsManagerTypes,
  NotificationEditorText,
  NotificationEditorRecipient,
} from './typings'

function NotificationEditor(props: Props) {
  const { value, renderText, onChange } = props

  const getPrefixText = useCallback(
    (prefix: 'email' | 'sms'): string =>
      renderText(
        prefix === 'email'
          ? NotificationEditorText.emailTypeLabel
          : NotificationEditorText.smsTypeLabel,
      ),
    [renderText],
  )

  const renderNotifications = useCallback(() => {
    const deleteRecipient = (index: number) => () => {
      const updatedValue: NotificationEditorRecipient[] = value.concat()

      updatedValue.splice(index, 1)
      onChange(updatedValue)
    }

    return value.map(({ address, prefix }: NotificationEditorRecipient, index: number) => {
      return (
        <ReadonlyField key={`notificationEditorAddress_${index}`} style={{ flexWrap: 'nowrap' }}>
          <CircularButton
            style={{ marginRight: 10 }}
            onClick={deleteRecipient(index)}
            Icon={<Cross />}
          />
          <ReadonlyFieldRow
            style={{
              justifyContent: 'space-between',
              flexShrink: 1,
              width: '93%',
            }}
          >
            <ReadonlyLabel
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginRight: 'auto',
                width: '85%',
              }}
            >
              {address}
            </ReadonlyLabel>
            <ReadonlyLabel>{getPrefixText(prefix)}</ReadonlyLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      )
    })
  }, [getPrefixText, value, onChange])

  const isDuplicatedRecipient = useCallback(
    (recipient: string): boolean =>
      value.some(
        ({ address }: NotificationEditorRecipient): boolean => address.trim() === recipient.trim(),
      ),
    [value],
  )

  const handleAddRecipient = useCallback(
    (recipient: NotificationEditorRecipient) => {
      onChange([...value, recipient])
    },
    [onChange, value],
  )

  return (
    <>
      <ViewManager<ActionsManagerTypes>
        view={ActionsManagerTypes.default}
        render={(view, changeView) => (
          <ActionsManager
            {...props}
            view={view}
            changeView={changeView}
            isDuplicatedRecipient={isDuplicatedRecipient}
            onAddRecipient={handleAddRecipient}
          />
        )}
      />
      {value.length > 0 && <ReadonlyBlock>{renderNotifications()}</ReadonlyBlock>}
    </>
  )
}

export default NotificationEditor
