import { useEffect } from 'react'

import { refreshLocations } from '@/features/domain/place'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { useIsLoading } from '@/atoms'

import { useTexts } from './useTexts'

export function useRefreshLocations() {
  const isLoading = useIsLoading('fetchGpsData')
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  useEffect(() => {
    const refreshGeocodeLocations = async () => {
      try {
        const thunkResult = await dispatch(refreshLocations())

        if (refreshLocations.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }
      } catch (error) {
        toast.error(texts.errorGeocodingPlaces, { autoClose: 5000 })
      }
    }

    // Let's wait for the completion of GPS data fetching before refreshing locations
    if (!isLoading) {
      refreshGeocodeLocations()
    }
  }, [dispatch, toast, texts, isLoading])
}
