import styled from 'styled-components'

export const DeviceIconButtonInner = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: block;
  color: currentColor;
  font-size: ${p => p.theme.fonts.$s};
  font-weight: ${p => p.theme.weights.$semiBold};
  text-transform: uppercase;
  margin: 0;
  outline: 0;
  padding: 3px 18px;
  background: ${p => p.theme.colors.$whiteSmoke};
  border-width: 0;
  border-radius: 2px;
`

DeviceIconButtonInner.displayName = 'DeviceIconButtonInner'
