import { memo, useCallback } from 'react'
import { styled } from '@mui/material'
import { History, DirectionsCar } from '@mui/icons-material'

import {
  ListItemContent,
  ListItemTitle,
  ListItemChip,
  ListItemRow,
  ListItem,
} from '@/components/List'
import { type OnItemClickHandlerParams } from '@/components/StructuredVirtualList/typings'
import { useTransactionInProgress } from '@/components/smartUtils/plan/TransactionInProgressTooltip'
import { FormatDate } from '@/components/smartUtils/time/FormatDate'

import * as Texts from '../../intl'

import { OrderIcon } from './OrderIcon'

type Props = {
  today: Date
  planId: string
  item: uui.domain.client.rm.SimulationInfo
  selected: boolean
  onItemDoubleClick?: (item: uui.domain.client.rm.SimulationInfo) => void
  onItemClick: (params: OnItemClickHandlerParams<'simulations'>) => void
}

const ChipPlaceholder = styled('div', { name: 'ChipPlaceholder' })({
  width: 25,
  height: 25,
  color: 'transparent',
  opacity: 0,
})

export const SimulationListItem = memo((props: Props) => {
  const { selected, planId, item, onItemClick, onItemDoubleClick } = props

  const { name, ordersCount, vehiclesCount, id, updated } = item
  const { cause } = useTransactionInProgress()

  const onClick = useCallback(event => onItemClick({ item: item, event }), [onItemClick, item])

  const onDoubleClick = useCallback(() => {
    if (cause === 'transactionInProgress') return
    onItemDoubleClick?.(item)
  }, [onItemDoubleClick, item, cause])

  return (
    <ListItem
      onDoubleClick={onDoubleClick}
      selected={selected}
      onClick={onClick}
      testId="simulation-list-item"
    >
      <ListItemTitle
        rightAdornment={
          planId === id ? (
            <ListItemChip
              variant="filledOutline"
              color="success"
              label={Texts.getSimulationsBadgeText()}
              size="small"
            />
          ) : (
            <ChipPlaceholder>&nbsp;</ChipPlaceholder>
          )
        }
      >
        {name}
      </ListItemTitle>

      <ListItemRow testId="simulation-updated-row">
        <ListItemContent icon={<History />}>
          <FormatDate source={new Date(updated)} />
        </ListItemContent>
      </ListItemRow>

      <ListItemRow
        right={
          <ListItemContent icon={<OrderIcon />} testId="simulation-orders-data">
            {Texts.getSimulationOrdersCountText(ordersCount)}
          </ListItemContent>
        }
      >
        <ListItemContent icon={<DirectionsCar />} testId="simulation-vehicles-data">
          {Texts.getSimulationVehiclesCountText(vehiclesCount)}
        </ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})
SimulationListItem.displayName = 'SimulationListItem'
