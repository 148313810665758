import { getMap } from '../../atoms/map/utils/getMap'
import { findLayer } from '../utils/findLayer'

import { mapStylesUpdateRegionFeatures } from './utils/mapStylesUpdateRegionFeatures'

export function createUpdateRegionFeaturesMapStyleFromChangelog(
  markers: uui.domain.ui.map.markers.MapMarkers['region'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['region'],
  selection: Set<string>,
) {
  // ATTENTION: Application of Map Style changes will not be cached if a delayed rendering mechanism will be implemented
  // After the whole set of delayed marker changelogs is applied a full refresh of Map Styles will be required

  return async () => {
    const map = getMap()
    const layer = findLayer(map, 'region')

    // only the `update` field is populated in a mapStyles changelog
    await mapStylesUpdateRegionFeatures(layer, markers, mapStyles, selection)
  }
}
