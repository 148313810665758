import { proxy, useSnapshot } from 'valtio'

interface ExtendedOrderStepNotification {
  id: string // orderStepId
  requestTs: number
  orderStepNotifications: uui.domain.client.rm.OrderStepNotification[]
}

type ExtendedOrderStepNotifications = Record<string, ExtendedOrderStepNotification>
type UnsubscribeData =
  uui.domain.actions.rpc.order.LoadOrderStepUnsubscribeDataActionResult['unsubscribeData']
type UnsubscriptionData = Record<string, UnsubscribeData>

type OrderStepNotificationAtom = {
  notifications: ExtendedOrderStepNotifications
  unsubscriptionData: UnsubscriptionData
  trackingLink: Record<string, string>
}

const atom = proxy<OrderStepNotificationAtom>({
  notifications: {},
  unsubscriptionData: {},
  trackingLink: {},
})

export const setOrderStepNotification = (notification: ExtendedOrderStepNotification) => {
  atom.notifications[notification.id] = notification
}

export const setOrderStepUnsubscribeData = (
  orderStepId: string,
  unsubscribeData: UnsubscribeData,
) => {
  atom.unsubscriptionData[orderStepId] = unsubscribeData
}

export const setOrderStepTrackingLink = (id: string, trackingLink: string) => {
  atom.trackingLink[id] = trackingLink
}

export const useOrderStepNotification = (id: string) =>
  useSnapshot(atom.notifications)[id]?.orderStepNotifications ?? []

export const useOrderStepTrackingLink = (id: string) => useSnapshot(atom.trackingLink)[id]

export const useOrderStepUnsubscribeData = (id: string) => useSnapshot(atom.unsubscriptionData)[id]

export const hasOrderStepNotification = (id: string) =>
  !!atom.notifications[id]?.orderStepNotifications

export const hasOrderStepTrackingLink = (id: string) => !!atom.trackingLink[id]
