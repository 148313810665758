import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    add: translate({ id: 'global.add' }),
    places: translate({ id: 'gps.places.list.yourPlaces' }),
    addPlace: translate({ id: 'gps.places.list.addPlace' }),
    sortByAscending: translate({ id: 'gps.places.list.sorter.sortBy.ascending' }),
    sortByDescending: translate({
      id: 'gps.places.list.sorter.sortBy.descending',
    }),
    searchPlaceholder: translate({ id: 'gps.places.list.filterPlaceholder' }),
    sortOrderDescending: translate({
      id: 'gps.places.list.sorter.sortOrder.descending',
    }),
    sortOrderAscending: translate({ id: 'gps.places.list.sorter.sortOrder.ascending' }),
    disabledForLoadingTooltip: translate({ id: 'global.gpsLoading.disableEdit' }),
  }))

  return api
}
