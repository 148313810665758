import { PureComponent } from 'react'

import NotificationEditor from '../primitives/NotificationEditor'
import RestoreFieldButton from '../primitives/RestoreFieldButton'
import FieldMessage from '../edit/FieldMessage'
import Block from '../edit/Block'
import Label from '../edit/Label'

import { Props } from './typings'

export default class NotificationEditorField extends PureComponent<Props> {
  render() {
    const { label, name, info, half = false, wrappedInputProps, formProps } = this.props

    const {
      input: { value, onChange },
      meta: { error, dirty, initial },
    } = formProps

    return (
      <Block half={half} error={!!error} dirty={!!dirty}>
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>

        <NotificationEditor {...wrappedInputProps} onChange={onChange} value={value} />
        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }
}
