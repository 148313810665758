import type { BulkFieldValue } from '../../../types'

export function computePhone(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
): BulkFieldValue<string> {
  const phone = orders[0].orderStep.phone
  const exact = orders.every(o => o.orderStep.phone === phone)

  if (exact) {
    return {
      status: 'exact',
      value: phone ?? '',
    }
  }

  return {
    status: 'mixed',
  }
}
