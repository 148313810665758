import type { Theme, SxProps } from '@mui/material'
import { SvgIcon } from '@mui/material'

type Props = {
  width: number | string
  sx?: SxProps<Theme>
}

export function NewVersionImage(props: Props) {
  const { width, sx = {} } = props

  return (
    <SvgIcon
      viewBox="0 0 400 400"
      sx={{ ...sx, height: 'auto', width }}
      className="preserve-natural-fill"
    >
      <path d="M216.666 316.667H183.333V366.667H216.666V316.667Z" fill="black" fillOpacity="0.54" />
      <path d="M83.333 183.333H33.333V216.667H83.333V183.333Z" fill="black" fillOpacity="0.54" />
      <path d="M366.666 183.333H316.666V216.667H366.666V183.333Z" fill="black" fillOpacity="0.54" />
      <path
        d="M288.353 273.238L264.9 296.69L300.255 332.045L323.708 308.593L288.353 273.238Z"
        fill="black"
        fillOpacity="0.54"
      />
      <path
        d="M111.757 273.12L76.4023 308.475L99.8545 331.927L135.209 296.572L111.757 273.12Z"
        fill="black"
        fillOpacity="0.54"
      />
      <path
        d="M249.999 133.667V50H149.999V133.667C129.833 149 116.666 172.833 116.666 200C116.666 246 153.999 283.333 199.999 283.333C245.999 283.333 283.333 246 283.333 200C283.333 172.833 270.166 149 249.999 133.667ZM183.333 83.3333H216.666V118.333C211.333 117.333 205.666 116.667 199.999 116.667C194.333 116.667 188.666 117.333 183.333 118.333V83.3333Z"
        fill="black"
        fillOpacity="0.54"
      />
    </SvgIcon>
  )
}
