import { useMemo } from 'react'

interface BulkData {
  enterCost: number
  enterTimeSec: number
  costMixed: boolean
  timeMixed: boolean
}

export function useBulkData(regions: uui.domain.client.rm.Region[]) {
  return useMemo<BulkData>(() => {
    let enterCost: number = NaN
    let enterTimeSec: number = NaN
    let costMixed: boolean = false
    let timeMixed: boolean = false

    for (const { enterCost: cost, enterTimeSec: time } of regions) {
      if (Number.isNaN(enterCost)) {
        enterCost = cost
      } else if (!costMixed && enterCost !== cost) {
        costMixed = true
      }

      if (Number.isNaN(enterTimeSec)) {
        enterTimeSec = time
      } else if (!timeMixed && enterTimeSec !== time) {
        timeMixed = true
      }
    }

    return {
      enterCost,
      enterTimeSec,
      costMixed,
      timeMixed,
    }
  }, [regions])
}
