import { styled } from '@mui/material'
import { theme } from '@/styles'

export const DeviceListItemWrapper = styled('div', { name: 'AlertDeviceListItemWrapper' })({
  position: 'relative',
  flex: '0 0 auto',
  display: 'flex',
  fontSize: theme.fonts.$p3,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '26px',
  padding: '0 13px',

  transition: 'background 0.3s ease',

  '&:hover': {
    background: theme.colors.$silver,
  },
})
