import { ReadonlyField, ReadonlyFieldRow, ReadonlyFieldLabel } from '@/forms-legacy'

interface Props {
  text: string
}

export function NotSet(props: Props) {
  return (
    <ReadonlyField>
      <ReadonlyFieldRow>
        <ReadonlyFieldLabel>{props.text}</ReadonlyFieldLabel>
      </ReadonlyFieldRow>
    </ReadonlyField>
  )
}
