import styled from 'styled-components'

type Props = {
  height?: number
  disableComputedHeight?: boolean
  error?: {
    errorText?: string
    warningText?: string
  }
}

const computeHeight = ({ height, disableComputedHeight }: Props): string => {
  if (disableComputedHeight === true) return ''

  if (height && !Number.isNaN(height) && Number.isFinite(height)) {
    return `height: ${height}px;`
  }
  return 'height: 100%;'
}

const styleWarningsErrors = ({ error }: Props): string => {
  if (!error) return ''
  const { errorText, warningText } = error

  return errorText ? 'border: 2px solid red;' : warningText ? 'border: 1px solid orange;' : ''
}

const styledComponent = styled.div<Props>`
  display: flex;
  flex-direction: column;
  outline: none;
  ${computeHeight};

  .o-list__wrapper {
    overflow: auto;
    height: 100%;
    ${styleWarningsErrors};
  }

  &.is-disabled {
    pointer-events: none;
  }
`

styledComponent.displayName = 'ListWrapper'
export default styledComponent
