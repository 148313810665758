import { useCallback } from 'react'

import { makeStyles } from '@mui/styles'

import { theme } from '@/local/components'

import HorizontalRadioGroup, {
  HorizontalRadioGroupColors,
  RadioGroupValues,
} from '../HorizontalRadioGroup'

import { Props, SimulationDateType, CalendarRange } from './typings'
import { getRadioGroupTypes } from './utils'
import { ArchivedCalendar } from './ArchivedCalendar'
import { OperationsCalendar } from './OperationsCalendar'

const horizontalGroupColors: HorizontalRadioGroupColors = {
  background: theme.colors.$silver,
  backgroundSelected: theme.colors.$nightRider,
  color: theme.colors.$nightRider,
  colorSelected: theme.colors.$pureWhite,
  border: theme.colors.$pureWhite,
  borderSize: 3,
}

const useStyles = makeStyles({
  calendar: {
    marginTop: 15,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
})

export function SimulationDatesPicker(props: Props) {
  const {
    value,
    today,
    values,
    onChange,
    messages,
    onChangeType,
    maxIntervalLength,
    firstDayNotArchived,
  } = props

  const classes = useStyles()

  const valueType = value.type
  const radioValues: RadioGroupValues = getRadioGroupTypes()
  const radioGroupValues: RadioGroupValues = values || radioValues

  const handleTypeChange = useCallback(
    (value: string) => {
      onChangeType(value as SimulationDateType)
    },
    [onChangeType],
  )

  const handleOnDateSelected = useCallback(
    (date: Date): void => {
      onChange({ min: date, max: date }, valueType)
    },
    [onChange, valueType],
  )

  const handleOnRangeSelected = useCallback(
    (calendarRange: CalendarRange): void => {
      onChange(calendarRange, valueType)
    },
    [onChange, valueType],
  )

  return (
    <div>
      <HorizontalRadioGroup
        name="simulation-type-radio-group"
        value={value.type}
        values={radioGroupValues}
        onChange={handleTypeChange}
        colors={horizontalGroupColors}
        hideSelectionUnderline
        hideOuterBorder
        elementStyle={{
          width: '33%',
          padding: '6px 10px',
          height: '100%',
          textAlign: 'center',
          minHeight: '40px',
        }}
      />

      {!!messages && messages}

      {value.type === 'fromArchived' && (
        <div className={classes.calendar}>
          <ArchivedCalendar
            today={today}
            selection={value.date}
            onChange={handleOnDateSelected}
            firstDayNotArchived={firstDayNotArchived}
          />
        </div>
      )}

      {value.type === 'fromOperations' && (
        <div className={classes.calendar}>
          <OperationsCalendar
            today={today}
            selection={value.dates}
            onChange={handleOnRangeSelected}
            maxIntervalLength={maxIntervalLength}
            firstDayNotArchived={firstDayNotArchived}
          />
        </div>
      )}
    </div>
  )
}
