import { intl } from '@/intl'

export function validateIdleStart(
  idleStart: uui.domain.ui.forms.GpsVehicleFormValues['device']['idleStart'],
) {
  const { enabled, min, max, custom } = idleStart
  if (!enabled) return

  if (typeof custom !== 'number') {
    return intl.translate({
      id: 'vehicles.form.edit.generalSettings.validation.idleStart.notNumeric',
    })
  }

  if (custom < min || custom > max) {
    return intl.translate({
      id: 'vehicles.form.edit.generalSettings.validation.idleStart.range',
      values: { min, max },
    })
  }
}
