import type { Props, Skin } from '../typings'
import type { ValidColor } from '@/local/components'

import { useState, useEffect } from 'react'

import { getColorWithAlpha } from '@/local/components'

const getValidColorFromPreset = (preset: Props['preset']): ValidColor => {
  switch (preset) {
    case 'alert':
      return '$outrageousRed'
    case 'warning':
      return '$darkOrange'
    case 'info':
      return '$scienceBlue'
    case 'success':
      return '$pigmentGreen'
  }
}

export const useSkin = (props: Props): Skin => {
  const { preset } = props

  const [skin, setSkin] = useState<Skin>({
    background: getColorWithAlpha(getValidColorFromPreset(preset), 0.1),
    color: '$nightRider',
  })

  useEffect(() => {
    setSkin({
      color: '$nightRider',
      background: getColorWithAlpha(getValidColorFromPreset(preset), 0.1),
    })
  }, [preset])

  return skin
}
