import { Box, Typography } from '@mui/material'

import { useTexts } from '../../hooks/useTexts'

interface Props {
  showPicturePod: boolean
  showSignaturePod: boolean
  showAudioPod: boolean
  showNotePod: boolean
}

export function Pods(props: Props) {
  const { showNotePod, showSignaturePod, showAudioPod, showPicturePod } = props

  const texts = useTexts()

  const noPodsToShow = !showNotePod && !showSignaturePod && !showAudioPod && !showPicturePod

  return (
    <Box paddingBottom={4} data-testid={texts.podsTitle}>
      <Typography variant="caption" color="textSecondary">
        {texts.podsTitle}
      </Typography>
      {showPicturePod && (
        <Typography component="p" variant="subtitle1">
          {texts.picture}
        </Typography>
      )}
      {showSignaturePod && (
        <Typography component="p" variant="subtitle1">
          {texts.signature}
        </Typography>
      )}
      {showAudioPod && (
        <Typography component="p" variant="subtitle1">
          {texts.voiceMemo}
        </Typography>
      )}
      {showNotePod && (
        <Typography component="p" variant="subtitle1">
          {texts.driverNote}
        </Typography>
      )}
      {noPodsToShow && (
        <Typography component="p" variant="subtitle1">
          -
        </Typography>
      )}
    </Box>
  )
}
