import type { GeofenceFormValidation } from '../types'

import { useTexts } from './useTexts'

export const useComposeFieldsWithError = () => {
  const texts = useTexts()

  return (errors: GeofenceFormValidation) =>
    Object.keys(errors).map(field => {
      switch (field) {
        case 'name':
          return texts.labelName

        default:
          return ''
      }
    })
}
