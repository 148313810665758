import type { FormFields, FormErrors } from '../../../formFields'
import type { FormValidator, FormError } from '@workwave-tidal/tidal/form-fairy'

import { useTexts } from '../useTexts'

const companySloganMaxLength = 50

export const createValidateCompanySlogan =
  (texts: ReturnType<typeof useTexts>): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const field = formApi.getField('companySlogan')
    const invalid = field.value.length > companySloganMaxLength

    const error: FormError<keyof FormFields> = {
      id: 'companySlogan-max-length',
      field: 'companySlogan',
      message: texts.companySloganMaxLength(companySloganMaxLength),
    }

    return {
      'companySlogan-max-length': invalid ? error : null,
    }
  }
