import type { MouseInteractionSubscriber } from '../../MouseInteraction'

import Feature from 'ol/Feature'

import { getFeatureMetadata } from '../../../layers/featureMetadata'

import { resetMapTooltip } from '../../../atoms/mapTooltip'

import { showOrderTooltip } from './utils/showOrderTooltip'

export const subscribeToMouseMove: MouseInteractionSubscriber = (
  features,
  _layersByFeature,
  modifiers,
  _mapBrowserEvent,
) => {
  // currently only single feature hover is supported through mouse-click interactions
  // and narrow FeatureLike to Feature
  if (features.length === 0 || !(features[0] instanceof Feature)) {
    resetMapTooltip()
    return
  }

  const feature = features[0]
  const type = getFeatureMetadata(feature, 'type')

  switch (type) {
    case 'order':
      showOrderTooltip(feature, modifiers)
      break

    // TODO: add other features supporting mouse-hover

    default:
      resetMapTooltip()
      break
  }
}
