import type { TrackingAppViewProps as Props } from '../types'

import { Box } from '@mui/material'
import { FormHeader } from '@workwave-tidal/tidal/form-ui/layout'
import { ViewContainer, ViewContent } from '@/formUi'

import { useTexts } from '../hooks/useTexts'

import { Actions } from './components/Actions'
import { Field } from './components/Field'
import { CompanyEmail } from './components/CompanyEmail'
import { CompanyPhone } from './components/CompanyPhone'
import { Pods } from './components/Pods'

export function TrackingPageView(props: Props) {
  const { onEdit, formValues, previewUrl } = props

  const texts = useTexts()

  return (
    <ViewContainer testid="trackingPage-view" width={360}>
      <ViewContent>
        <FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />
        <Box paddingLeft={4} paddingRight={4}>
          <Actions onEdit={onEdit} previewUrl={previewUrl} />

          <Field label={texts.companyName}>{formValues.companyName || '-'}</Field>

          <Field label={texts.companySlogan}>{formValues.companySlogan || '-'}</Field>

          <CompanyPhone
            companyPhone={formValues.companyPhone || '-'}
            showContactPhone={formValues.showContactPhone && !!formValues.companyPhone}
          />

          <CompanyEmail
            companyEmail={formValues.companyEmail || '-'}
            showContactEmail={formValues.showContactEmail && !!formValues.companyEmail}
            useCompanyEmailAsReplyTo={
              formValues.useCompanyEmailAsReplyTo && !!formValues.companyEmail
            }
          />

          <Field label={texts.showEtaRange}>{texts.onOff(formValues.showEtaRange)}</Field>

          <Pods
            showAudioPod={formValues.showAudioPod}
            showPicturePod={formValues.showPicturePod}
            showNotePod={formValues.showNotePod}
            showSignaturePod={formValues.showSignaturePod}
          />
        </Box>
      </ViewContent>
    </ViewContainer>
  )
}
