import { Component, ReactNode } from 'react'

import { DropdownItem } from '@/forms-legacy'

export interface Props {
  defaultValue: DropdownItem | undefined
  vehicles: uui.domain.client.rm.Vehicle[]
  render: (values: DropdownItem[]) => ReactNode
}

interface State {
  values: DropdownItem[]
}

const composeValues = (
  vehicles: uui.domain.client.rm.Vehicle[],
  defaultValue?: DropdownItem,
): DropdownItem[] => {
  const items = vehicles.map(vehicle => {
    const { id, externalId } = vehicle
    return {
      id,
      label: externalId,
    }
  })

  if (defaultValue) {
    items.unshift(defaultValue)
  }

  return items
}

const setValues = (values: DropdownItem[]) => () => ({
  values,
})

export default class VehicleAssignmentDropDownProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const { vehicles, defaultValue } = props

    this.state = {
      values: composeValues(vehicles, defaultValue),
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { vehicles: prevVehicles, defaultValue: preDefaultValue } = prevProps
    const { vehicles, defaultValue } = prevProps

    if (vehicles !== prevVehicles || preDefaultValue !== defaultValue) {
      this.setState(setValues(composeValues(vehicles, defaultValue)))
    }
  }

  render() {
    const { values } = this.state
    const { render } = this.props

    return render(values)
  }
}
