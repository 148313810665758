import { type EventModel } from '@bryntum/schedulerpro'
import { addSeconds } from 'date-fns/esm'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectDayRangeInfo } from '@/features/domain/scheduler'
import { timeUtils } from '@/server-data'
import { store } from '@/store'

export function tooltipTemplate(params: {
  startClockHtml: string
  endClockHtml: string
  startText: string
  startDate: Date
  endDate: Date
  endText: string
  record: EventModel
}) {
  const { startClockHtml, endClockHtml, startDate, endDate, startText, endText } = params

  const state = store.getState()
  const userConfig = selectUserConfiguration(state)
  const { dayStartOffset } = selectDayRangeInfo(state)
  const { start: calendarStart } = selectCalendarRangeInterval(state)

  const formatTime = timeUtils.formatTime(userConfig)

  calendarStart.setHours(0, 0, 0)

  const updatedStartDate = startDate
  const updatedEndDate = endDate
  const startBoundary = addSeconds(calendarStart, dayStartOffset)
  const endBoundary = addSeconds(calendarStart, dayStartOffset + 24 * 60 * 60)

  const startDateText = formatTime(
    updatedStartDate < startBoundary ? startBoundary : updatedStartDate,
    false,
  )
  const endDateText = formatTime(updatedEndDate > endBoundary ? endBoundary : updatedEndDate, false)

  return `
    <div style="display:flex; flex-direction:column;">
      <div style="display:flex; flex-direction:row; margin-bottom: 8px;">
      ${startClockHtml.replace(startText, startDateText)}
      </div>
      
      <div style="display:flex; flex-direction:row">
      ${endClockHtml.replace(endText, endDateText)}
      </div>
    </div>
  `
}
