import type { ChipShape, CoreItem, Modes, DriverChip } from '../../../components/Navigator/typings'

import { clsx } from '@/utils'
import { theme } from '@/local/components'
import { getColorValue } from '@/styles'
import { User } from '@/icons'

import { Chip } from '../../../components/Navigator/components/Chip'

export interface Props<T extends CoreItem, K> {
  item: T
  mode: Modes
  selected?: boolean
  onClick: () => void
  className?: string
  extraItemRendererProps: K
  loopIndex: number
}

export function DriverItemRenderer(props: Props<DriverChip, Record<string, any>>) {
  const { onClick, className: cls = '' } = props

  const className = clsx(cls, 'o-chip--circle')
  const background: string = getColorValue(theme.colors.$nightRider)
  const textColor: string = getColorValue(theme.colors.$pureWhite)

  const chipProps = {
    onClick,
    className,
    background,
    color: textColor,
    borderColor: background,
    shape: 'rounded' as ChipShape,
    Icon: <User size={24} />,
  }

  return <Chip {...chipProps} />
}
