import { useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

type ModalState = 'ready'

export const modalId = 'goToIntegrationsModal'

export const useController = () => useModalController<void, ModalState>(modalId)

export const useConfigureController = () => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: undefined,
    }),
  }))

  return useConfigureModalController<void, ModalState>(modalId, options)
}
