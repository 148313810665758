import type { GridValueGetterParams } from '@mui/x-data-grid-pro'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import { conversionUtils } from '@/server-data'

export function useGetTimeWindowValue() {
  const userConfiguration = useSelector(selectUserConfiguration)

  return useCallback(
    (params: GridValueGetterParams) => {
      const fieldName = params.field.replace('formattedData.', '')

      const timeWindow = params.row[fieldName]

      if (!timeWindow) return '-'

      const { startSec, endSec } = timeWindow

      return `${conversionUtils.convertTime(userConfiguration)(
        startSec,
        false,
      )} - ${conversionUtils.convertTime(userConfiguration)(endSec, false)}`
    },
    [userConfiguration],
  )
}
