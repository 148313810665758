import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectVehicles } from '@/features/domain/vehicle'

export const useVehiclesRoot = () => {
  const { editing } = useEditingStateWithCtx('vehicle')
  const [selection] = useCrudSelection('unifiedVehicles')
  const vehicles = useSelector(selectVehicles)
  const selectedVehicles = useMemo(
    () => getSelectedVehicles(selection, vehicles),
    [selection, vehicles],
  )

  return {
    vehicles,
    selectedVehicles,
    editing,
    selection,
  }
}

const getSelectedVehicles = (
  selection: workwave.DeepReadonly<string[]>,
  vehiclesMap: Record<string, uui.domain.client.UnifiedVehicle>,
): uui.domain.client.UnifiedVehicle[] => {
  // selection could hold some ids that are not in the vehiclesMap due to server-data computation
  // vehiclesMap holds the "truth"
  return selection.reduce<uui.domain.client.UnifiedVehicle[]>((acc, id) => {
    if (vehiclesMap[id]) {
      acc.push(vehiclesMap[id])
    }
    return acc
  }, [])
}
