import { useCallback, useState } from 'react'

import { setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'hideRoutes' | 'popoutRoutes'
export function useStylerActions(routeIds: string[], orderStepIds: string[]) {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)

  const dispatch = useAppDispatch()

  const hideRoutes = useCallback(async () => {
    setMethodInExecution('hideRoutes')

    await Promise.all([
      dispatch(
        setCustomMapStyle({
          customStyle: { type: 'order', mode: 'off', ids: orderStepIds },
        }),
      ),

      dispatch(
        setCustomMapStyle({
          customStyle: { type: 'routePolyline', mode: 'off', ids: routeIds },
        }),
      ),
    ])

    setMethodInExecution(null)
  }, [dispatch, routeIds, orderStepIds])

  const popoutRoutes = useCallback(async () => {
    setMethodInExecution('popoutRoutes')

    await Promise.all([
      dispatch(
        setCustomMapStyle({
          mapMode: 'on',
          customStyle: { type: 'order', mode: 'maximized', ids: orderStepIds },
        }),
      ),

      dispatch(
        setCustomMapStyle({
          mapMode: 'off',
          customStyle: { type: 'routePolyline', mode: 'on', ids: routeIds },
        }),
      ),
    ])

    setMethodInExecution(null)
  }, [dispatch, routeIds, orderStepIds])

  return { methodInExecution, actions: { hideRoutes, popoutRoutes } }
}
