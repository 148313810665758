import { useCallback, useRef } from 'react'
import { Add, FileUpload } from '@mui/icons-material'

import { Tooltip } from '@/components/primitives/Tooltip'
import {
  ListHeaderActions,
  ListHeaderPrimaryActionButton,
  type ListHeaderSecondaryActionRef,
} from '@/components/List'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { useTexts } from '../../../../useTexts'

import { useActions } from './hooks/useActions'

export function Actions() {
  const { editing, onCreate, onImportKML } = useActions()

  const secondaryActionMenuRef = useRef<ListHeaderSecondaryActionRef>(null)

  const onImportKmlItemClick = useCallback(() => {
    secondaryActionMenuRef.current?.close()
    onImportKML()
  }, [onImportKML])

  const texts = useTexts()

  return (
    <ListHeaderActions>
      <ReadOnlyTooltip
        render={preventEditing => (
          <Tooltip title={texts.addRoadSegment} placement="bottom">
            <ListHeaderPrimaryActionButton
              disabled={preventEditing || editing}
              onClick={onCreate}
              trackId="road-segments__create-new-button"
            >
              <Add />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
      <ReadOnlyTooltip
        render={preventEditing => (
          <Tooltip title={texts.importKml} placement="bottom">
            <ListHeaderPrimaryActionButton
              disabled={preventEditing || editing}
              onClick={onImportKmlItemClick}
              trackId="road-segments__import-KML-button"
              testId="list-header-secondary-action-import-kml"
            >
              <FileUpload />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
    </ListHeaderActions>
  )
}
