import { translate } from './translate'

type DateFormat = 'short' | 'long'

export const getEditText = () => translate({ id: 'global.edit' })

export const getRemoveText = () => translate({ id: 'global.remove' })

export const getViewText = () => translate({ id: 'global.view' })

export const getDateAndTimeText = () => translate({ id: 'global.dateAndTime' })

export const getVehicleText = () => translate({ id: 'global.vehicle' })

export const getVehiclesText = () => translate({ id: 'global.vehicles' })

export const getPlacesText = () => translate({ id: 'global.places' })

export const getDriversText = () => translate({ id: 'global.drivers' })

export const getLocationText = () => translate({ id: 'global.location' })

export const getSpeedText = () => translate({ id: 'global.speed' })

export const getStopStartText = () => translate({ id: 'global.stopStart' })

export const getRadiusText = () => translate({ id: 'global.radius' })

export const getAccelerationText = () => translate({ id: 'global.acceleration' })

export const getBrakingText = () => translate({ id: 'global.braking' })

export const getCorneringText = () => translate({ id: 'global.cornering' })

export const getHeadingText = () => translate({ id: 'global.heading' })

export const getRoutesText = () => translate({ id: 'global.routes' })

export const getRouteText = () => translate({ id: 'global.route' })

export const getEventsText = () => translate({ id: 'global.events' })

export const getEventText = () => translate({ id: 'global.event' })

export const getMultipleValuesText = () => translate({ id: 'global.multipleValues' })

export const getValuesText = () => translate({ id: 'global.values' })

export const getNotSetText = () => translate({ id: 'global.notSet' })

export const getNoAssignmentText = () => translate({ id: 'global.noAssignment' })

export const getShowOnMapText = () => translate({ id: 'global.showOnMap' })

export const getActivatedOnText = (date: string, time: string) =>
  translate({ id: 'global.activatedOn', values: { date, time } })

export const getDeleteText = () => translate({ id: 'global.delete' })

export const getDeleteAllText = () => translate({ id: 'global.deleteAll' })

export const getDuplicateText = () => translate({ id: 'global.duplicate' })

export const getDuplicateAllText = () => translate({ id: 'global.duplicateAll' })

export const getCloneText = () => translate({ id: 'global.clone' })

export const getContactsText = () => translate({ id: 'global.contacts' })

export const getBackText = () => translate({ id: 'global.back' })

export const getOptionsText = () => translate({ id: 'global.options' })

export const getAssignedVehiclesText = () => translate({ id: 'global.assignedVehicles' })

export const getClickToExpandText = () => translate({ id: 'global.clickToExpand' })

export const getClickToCollapseText = () => translate({ id: 'global.clickToCollapse' })

export const getDiscardText = () => translate({ id: 'global.discard' })

export const getDiscardChangesText = () => translate({ id: 'global.discardChanges' })

export const getApplyChangesText = () => translate({ id: 'global.applyChanges' })

export const getDeleteItText = () => translate({ id: 'global.deleteIt' })

export const getKeepItText = () => translate({ id: 'global.keepIt' })

export const getFilterAlertsText = () => translate({ id: 'global.filterAlerts' })

export const getFilterItemsText = () => translate({ id: 'global.filterItems' })

export const getStartText = () => translate({ id: 'global.start' })

export const getEndText = () => translate({ id: 'global.end' })

export const getDateText = () => translate({ id: 'global.date' })

export const getTimeText = () => translate({ id: 'global.time' })

export const getTimeZoneText = () => translate({ id: 'global.timeZone' })

export const getRecurrenceText = () => translate({ id: 'global.recurrence' })

export const getDateRecurrenceText = () => translate({ id: 'global.recurrence.date' })

export const getAnnualRecurrenceText = () => translate({ id: 'global.recurrence.annual' })

export const getMonthlyRecurrenceText = () => translate({ id: 'global.recurrence.monthly' })

export const getWeeklyRecurrenceText = () => translate({ id: 'global.recurrence.weekly' })

export const getEnterToSelectText = () => translate({ id: 'global.enterToSelect' })

export const getLatitudeText = () => translate({ id: 'global.latitude' })

export const getLatitudeInfoText = () => translate({ id: 'global.latitudeInfo' })

export const getLongitudeText = () => translate({ id: 'global.longitude' })

export const getLongitudeInfoText = () => translate({ id: 'global.longitudeInfo' })

export const getDateStartText = () => translate({ id: 'global.dateStart' })

export const getDateEndText = () => translate({ id: 'global.dateEnd' })

export const getLastActivityText = () => translate({ id: 'global.lastActivity' })

export const getFilterAssetsText = () => translate({ id: 'global.filterAssets' })

export const getDriverBehaviorsText = () => translate({ id: 'global.driverBehaviors' })

export const getActiveText = () => translate({ id: 'global.active' })

export const getInactiveText = () => translate({ id: 'global.inactive' })

export const getNoTagsText = () => translate({ id: 'global.noTags' })

export const getNoGpsTagsText = () => translate({ id: 'global.noGpsTags' })

export const getLoadingText = () => translate({ id: 'global.loading' })

export const getBreadcrumbsText = () => translate({ id: 'global.breadcrumbs' })

export const getLivePositionText = () => translate({ id: 'global.livePosition' })

export const getLiveText = () => translate({ id: 'global.live' })

export const getLiveEtaText = () => translate({ id: 'global.liveEta' })

export const getDayStartText = () => translate({ id: 'global.dayStart' })

export const getNextText = () => translate({ id: 'global.next' })

export const getLiveTrackingText = () => translate({ id: 'global.liveTracking' })

export const getSelectText = () => translate({ id: 'global.select' })

export const getOneDayText = () => translate({ id: 'global.oneDay' })

export const getOnDayText = () => translate({ id: 'global.on' })

export const getShowText = () => translate({ id: 'global.show' })

export const getShowAllText = () => translate({ id: 'global.showAll' })

export const getHideAllText = () => translate({ id: 'global.hideAll' })

export const getHideText = () => translate({ id: 'global.hide' })

export const getUnassignedText = () => translate({ id: 'global.unassigned' })

export const getUnassignText = () => translate({ id: 'global.unassign' })

export const getUnassignAllText = () => translate({ id: 'global.unassignAll' })

export const getAssignedText = () => translate({ id: 'global.assigned' })

export const getAllOrdersText = () => translate({ id: 'global.allOrders' })

export const getPickupText = () => translate({ id: 'global.pickup' })

export const getDeliveryText = () => translate({ id: 'global.delivery' })

export const getPickupDeliveryText = () => translate({ id: 'global.pickupDelivery' })

export const getServiceText = () => translate({ id: 'global.service' })

export const getExportText = () => translate({ id: 'global.export' })

export const getExportKmlText = () => translate({ id: 'global.exportKML' })

export const getImportKmlText = () => translate({ id: 'global.importKML' })

export const getMoveText = () => translate({ id: 'global.move' })

export const getExportAllText = () => translate({ id: 'global.exportAll' })

export const getExecutionsDetailText = () => translate({ id: 'global.executionsDetail' })

export const getEligibilityText = () => translate({ id: 'global.eligibility' })

export const getLoadText = () => translate({ id: 'global.load' })

export const getLoadsText = () => translate({ id: 'global.loads' })

export const getAssignedVehicleText = () => translate({ id: 'global.assignedVehicle' })

export const getImportanceText = () => translate({ id: 'global.importance' })

export const getTypeText = () => translate({ id: 'global.type' })

export const getServiceTimeText = () => translate({ id: 'global.serviceTime' })

export const getTimeWindowsText = () => translate({ id: 'global.timeWindows' })

export const getTimeWindowExceptionsAndAlternatives = () =>
  translate({ id: 'global.timeWindowExceptionsAndAlternatives' })

export const getRequiredTagsText = () => translate({ id: 'global.requiredTags' })

export const getBannedTagsText = () => translate({ id: 'global.bannedTags' })

export const getNotesText = () => translate({ id: 'global.notes' })

export const getAutomaticText = () => translate({ id: 'global.automatic' })

export const getAutomaticAssignmentText = () => translate({ id: 'global.automaticAssignment' })

export const getAddressText = () => translate({ id: 'global.address' })

export const getAddressInfoText = () => translate({ id: 'global.addressInfo' })

export const getPriorityText = () => translate({ id: 'global.priority' })

export const getNameText = () => translate({ id: 'global.name' })

export const getSurnameText = () => translate({ id: 'global.surname' })

export const getVehicleAssignmentText = () => translate({ id: 'global.vehicleAssignment' })

export const getPluralDaysText = (count: number) =>
  translate({ id: 'global.plural.days', values: { count } })

export const getPluralOrdersText = (count: number) =>
  translate({ id: 'global.plural.orders', values: { count } })

export const getPluralPlacesText = (count: number) =>
  translate({ id: 'global.plural.places', values: { count } })

export const getPluralDriversText = (count: number) =>
  translate({ id: 'global.plural.drivers', values: { count } })

export const getPluralVehiclesText = (count: number) =>
  translate({ id: 'global.plural.vehicles', values: { count } })

export const getPluralOrderStepsText = (count: number) =>
  translate({ id: 'global.plural.orderSteps', values: { count } })

export const getPluralLongHoursText = (value: number) =>
  translate({ id: 'global.plural.long.hours', values: { value } })

export const getPluralLongMinutesText = (value: number) =>
  translate({ id: 'global.plural.long.minutes', values: { value } })

export const getPluralLongSecondsText = (value: number) =>
  translate({ id: 'global.plural.long.seconds', values: { value } })

export const getPluralShortHoursText = (value: number) =>
  translate({ id: 'global.plural.short.hours', values: { value } })

export const getPluralShortMinutesText = (value: number) =>
  translate({ id: 'global.plural.short.minutes', values: { value } })

export const getPluralShortSecondsText = (value: number) =>
  translate({ id: 'global.plural.short.seconds', values: { value } })

export const getPluralDepotsText = (count: number) =>
  translate({ id: 'global.plural.depots', values: { count } })

export const getPluralBreadcrumbsText = (count: number) =>
  translate({ id: 'global.plural.breadcrumbs', values: { count } })

export const getPluralRoutesText = (count: number) =>
  translate({ id: 'global.plural.routes', values: { count } })

export const getPluralDeviceEventsText = (count: number) =>
  translate({ id: 'global.plural.deviceEvents', values: { count } })

export const getDeselectText = () => translate({ id: 'global.deselect' })

export const getCollapseText = () => translate({ id: 'global.collapse' })

export const getExpandText = () => translate({ id: 'global.expand' })

export const getOpenText = () => translate({ id: 'global.open' })

export const getViolationsCounterText = (count: number) =>
  translate({ id: 'global.counter.violations', values: { count } })

export const getOrdersCounterText = (count: number) =>
  translate({ id: 'global.counter.orders', values: { count } })

export const getDepotsCounterText = (count: number) =>
  translate({ id: 'global.counter.depots', values: { count } })

export const getPlacesCounterText = (count: number) =>
  translate({ id: 'global.counter.places', values: { count } })

export const getNoViolationsText = () => translate({ id: 'global.noViolations' })

export const getNoDataText = () => translate({ id: 'global.noData' })

export const getVariousText = () => translate({ id: 'global.various' })

export const getEmailText = () => translate({ id: 'global.email' })

export const getGpsTrackingText = () => translate({ id: 'global.gpsTracking' })

export const getGpsTrackingSmartphoneText = () => translate({ id: 'global.gpsTrackingSmartphone' })

export const getMoreText = () => translate({ id: 'global.more' })

export const getNoDateText = () => translate({ id: 'global.noDate' })

export const getDownloadStartAutoText = () => translate({ id: 'global.downloadStartAuto' })

export const getEtaText = () => translate({ id: 'global.eta' })

export const getToBeApprovedText = () => translate({ id: 'global.toBeApproved' })

export const getPlannedTimeText = () => translate({ id: 'global.plannedTime' })

export const getTagsText = () => translate({ id: 'global.tags' })

export const getGpsTagsText = () => translate({ id: 'global.gpsTags' })

export const getGoToPairedText = () => translate({ id: 'global.goToPaired' })

export const getChooseDateText = () => translate({ id: 'global.chooseDate' })

export const getSelectDayText = () => translate({ id: 'global.selectDay' })

export const getChooseVehicle = () => translate({ id: 'global.chooseVehicle' })

export const getSubmitText = () => translate({ id: 'global.submit' })

export const getSaveText = () => translate({ id: 'global.save' })

export const getPrintAllText = () => translate({ id: 'global.printAll' })

export const getVehiclesCounterText = (count: number) =>
  translate({ id: 'global.counter.vehicles', values: { count } })

export const getReverseText = () => translate({ id: 'global.reverse' })

export const getReverseAllText = () => translate({ id: 'global.reverseAll' })

export const getSwapText = () => translate({ id: 'global.swap' })

export const getStartLocationText = () => translate({ id: 'global.startLocation' })

export const getEndLocationText = () => translate({ id: 'global.endLocation' })

export const getFromText = () => translate({ id: 'global.from' })

export const getToText = () => translate({ id: 'global.to' })

export const getBreaksText = () => translate({ id: 'global.breaks' })

export const getCostsText = () => translate({ id: 'global.costs' })

export const getConstraintsText = () => translate({ id: 'global.constraints' })

export const getHardText = () => translate({ id: 'global.hard' })

export const getHarshText = () => translate({ id: 'global.harsh' })

export const getSevereText = () => translate({ id: 'global.severe' })

export const getGalsText = () => translate({ id: 'global.unitOfMeasure.gals' })

export const getDoneText = () => translate({ id: 'global.done' })

export const getFindText = () => translate({ id: 'global.find' })

export const getMinutesText = () => translate({ id: 'global.unitOfMeasure.minutes' })

export const getDollarsText = () => translate({ id: 'global.unitOfMeasure.dollars' })

export const getMetersText = () => translate({ id: 'global.unitOfMeasure.meters' })

export const getTodayText = () => translate({ id: 'global.today' })

export const getNowText = () => translate({ id: 'global.now' })

export const getSelectedText = () => translate({ id: 'global.selected' })

export const getNeverText = () => translate({ id: 'global.never' })

export const getNoItemsFoundText = () => translate({ id: 'global.noItemsFound' })

export const getTomorrowText = () => translate({ id: 'global.tomorrow' })

export const getSetText = () => translate({ id: 'global.set' })

export const getBestText = () => translate({ id: 'global.best' })

export const getFitText = () => translate({ id: 'global.fit' })

export const getSelectAllText = () => translate({ id: 'global.selectAll' })

export const getDeselectAllText = () => translate({ id: 'global.deselectAll' })

export const getFilteredText = () => translate({ id: 'global.filtered' })

export const getAvailableText = () => translate({ id: 'global.available' })

export const getCurrentText = () => translate({ id: 'global.current' })

export const getMinText = () => translate({ id: 'global.min' })

export const getMaxText = () => translate({ id: 'global.max' })

export const getDepotsText = () => translate({ id: 'global.depots' })

export const getRegionsText = () => translate({ id: 'global.regions' })

export const getTrafficRegionsText = () => translate({ id: 'global.trafficRegions' })

export const getSegmentsText = () => translate({ id: 'global.segments' })

export const getTrafficAreaText = () => translate({ id: 'global.trafficArea' })

export const getManageSelectionText = () => translate({ id: 'global.manageSelection' })

export const getManageAssignmentText = () => translate({ id: 'global.manageAssignment' })

export const getBulkEditText = () => translate({ id: 'global.bulkEdit' })

export const getEditAllText = () => translate({ id: 'global.editAll' })

export const getConfirmText = () => translate({ id: 'global.confirm' })

export const getMaximizeText = () => translate({ id: 'global.maximize' })

export const getMinimizeText = () => translate({ id: 'global.minimize' })

export const getAddLoadText = () => translate({ id: 'global.addLoad' })

export const getAllAssignedLoadsText = () => translate({ id: 'global.allAssignedLoads' })

export const getDeviceText = () => translate({ id: 'global.device' })

export const translateDriverText = () => translate({ id: 'global.textDriver' })

export const getEachText = () => translate({ id: 'global.each' })

export const getStatusText = () => translate({ id: 'global.status' })

export const getAnyText = () => translate({ id: 'global.any' })

export const getLockAllText = () => translate({ id: 'global.lockAll' })

export const getUnlockAllText = () => translate({ id: 'global.unlockAll' })

export const getLockText = () => translate({ id: 'global.lock' })

export const getUnlockText = () => translate({ id: 'global.unlock' })

export const getDistanceUnitText = (useMetric: boolean) =>
  translate({
    id: useMetric ? 'global.unitOfMeasure.kmh' : 'global.unitOfMeasure.mph',
  })

export const getYesText = () => translate({ id: 'global.yes' })

export const getNoText = () => translate({ id: 'global.no' })

export const getContinueText = () => translate({ id: 'global.continue' })

export const getAddMinimumText = () => translate({ id: 'global.addMinimum' })

export const getAllText = () => translate({ id: 'global.all' })

export const getNoneText = () => translate({ id: 'global.none' })

export const getUnavailableText = () => translate({ id: 'global.unavailable' })

export const getEmptyText = () => translate({ id: 'global.empty' })

export const getValueText = () => translate({ id: 'global.value' })

export const getTrackingText = () => translate({ id: 'global.tracking' })

export const getSearchText = () => translate({ id: 'global.search' })

export const getDashboardText = () => translate({ id: 'global.dashboard' })

export const getYearText = () => translate({ id: 'global.year' })

export const getMonthText = () => translate({ id: 'global.month' })

export const getDayText = () => translate({ id: 'global.day' })

export const getSelectorText = () => translate({ id: 'global.selector' })

export const getShortcutText = () => translate({ id: 'global.shortcut' })

export const getDistanceText = () => translate({ id: 'global.distance' })

export const getCurrencyText = () => translate({ id: 'global.currency' })

export const getCountryText = () => translate({ id: 'global.country' })

export const getCancelText = () => translate({ id: 'global.cancel' })

export const getChangeText = () => translate({ id: 'global.change' })

export const getOrdersText = () => translate({ id: 'global.orders' })

export const getPathsText = () => translate({ id: 'global.paths' })

export const getGenericDropdownFooterText = (count: number) =>
  translate({ id: 'ui.genericDropdown.footer', values: { count } })

export const getGenericDropdownFooterWithSearchText = (count: number) =>
  translate({ id: 'ui.genericDropdown.footerWithSearch', values: { count } })

export const getGenericDropdownFooterNoItemsText = () =>
  translate({ id: 'ui.genericDropdown.footerNoItems' })

export const getDurationText = () => translate({ id: 'global.duration' })

export const getCloseText = () => translate({ id: 'global.close' })

export const getAbsoluteText = () => translate({ id: 'global.absolute' })

export const getPercentageText = () => translate({ id: 'global.percentage' })

export const getClearText = () => translate({ id: 'global.clear' })

export const getCenterOnMapText = () => translate({ id: 'global.centerOnMap' })

export const getHiddenText = () => translate({ id: 'global.hidden' })

export const getVisibleText = () => translate({ id: 'global.visible' })

export const getSmsText = () => translate({ id: 'global.sms' })

export const getCopyToClipboardText = () => translate({ id: 'global.copyToClipboard' })

export const getUsernameText = () => translate({ id: 'global.username' })

export const getCopiedItText = () => translate({ id: 'global.copiedIt' })

export const getDisabledText = () => translate({ id: 'global.disabled' })

export const getEnabledText = () => translate({ id: 'global.enabled' })

export const getRoadEditorText = () => translate({ id: 'global.roadEditor' })

export const getBrandingText = () => translate({ id: 'global.branding' })

export const getSendText = () => translate({ id: 'global.send' })

export const getSkipText = () => translate({ id: 'global.skip' })

export const getSendMessageText = () => translate({ id: 'global.sendMessage' })

// LOADING routeplane

export const getLoadingSimulationText = () => translate({ id: 'sagas.plan.loading.simulation' })
export const getLoadingOperationText = () => translate({ id: 'sagas.plan.loading.operation' })
export const getLoadingArchivedText = () => translate({ id: 'sagas.plan.loading.archived' })
export const getUpdatingInterfaceText = () => translate({ id: 'sagas.plan.updating' })

export const getSelectedCounterText = (count: number) =>
  translate({
    id: 'components.searchableList.selectedCounter',
    values: { count },
  })

export const getLoadMoreText = () => translate({ id: 'global.loadMore' })

export const getSinceText = () => translate({ id: 'global.since' })

export const getEditColumnsText = () => translate({ id: 'global.editColumns' })

export const getDefaultColumnsText = () => translate({ id: 'global.defaultColumns' })

export const getFileText = () => translate({ id: 'global.file' })

const getDayLabels = () => [
  translate({ id: 'global.calendar.days.short.sunday' }),
  translate({ id: 'global.calendar.days.short.monday' }),
  translate({ id: 'global.calendar.days.short.tuesday' }),
  translate({ id: 'global.calendar.days.short.wednesday' }),
  translate({ id: 'global.calendar.days.short.thursday' }),
  translate({ id: 'global.calendar.days.short.friday' }),
  translate({ id: 'global.calendar.days.short.saturday' }),
]

export const getDaysShortLabelsText = (startFromSunday: boolean = false) => {
  const source = getDayLabels()

  return startFromSunday ? source : [...source.slice(1), ...source.slice(0, 1)]
}

const getDayLabelShortFromIndex = (index: number) => {
  const dayIndex = Math.abs(index) % 7
  switch (dayIndex) {
    case 0:
      return translate({ id: 'global.calendar.days.short.sunday' })
    case 1:
      return translate({ id: 'global.calendar.days.short.monday' })
    case 2:
      return translate({ id: 'global.calendar.days.short.tuesday' })
    case 3:
      return translate({ id: 'global.calendar.days.short.wednesday' })
    case 4:
      return translate({ id: 'global.calendar.days.short.thursday' })
    case 5:
      return translate({ id: 'global.calendar.days.short.friday' })
    case 6:
      return translate({ id: 'global.calendar.days.short.saturday' })

    default:
      return ''
  }
}

const getDayLabelLongFromIndex = (index: number) => {
  const dayIndex = Math.abs(index) % 7
  switch (dayIndex) {
    case 0:
      return translate({ id: 'global.calendar.days.long.sunday' })
    case 1:
      return translate({ id: 'global.calendar.days.long.monday' })
    case 2:
      return translate({ id: 'global.calendar.days.long.tuesday' })
    case 3:
      return translate({ id: 'global.calendar.days.long.wednesday' })
    case 4:
      return translate({ id: 'global.calendar.days.long.thursday' })
    case 5:
      return translate({ id: 'global.calendar.days.long.friday' })
    case 6:
      return translate({ id: 'global.calendar.days.long.saturday' })

    default:
      return ''
  }
}

export const getDayLabelFromIndex = (index: number, format: DateFormat) =>
  format === 'short' ? getDayLabelShortFromIndex(index) : getDayLabelLongFromIndex(index)

export const getDayLabelFromDayIndex = (index: number) => {
  const dayIndex = Math.abs(index) % 7
  switch (dayIndex) {
    case 0:
      return translate({ id: 'global.calendar.days.long.sunday' })
    case 1:
      return translate({ id: 'global.calendar.days.long.monday' })
    case 2:
      return translate({ id: 'global.calendar.days.long.tuesday' })
    case 3:
      return translate({ id: 'global.calendar.days.long.wednesday' })
    case 4:
      return translate({ id: 'global.calendar.days.long.thursday' })
    case 5:
      return translate({ id: 'global.calendar.days.long.friday' })
    case 6:
      return translate({ id: 'global.calendar.days.long.saturday' })

    default:
      return ''
  }
}

export const getMonthsLabelsText = () => [
  translate({ id: 'global.calendar.months.long.january' }),
  translate({ id: 'global.calendar.months.long.february' }),
  translate({ id: 'global.calendar.months.long.march' }),
  translate({ id: 'global.calendar.months.long.april' }),
  translate({ id: 'global.calendar.months.long.may' }),
  translate({ id: 'global.calendar.months.long.june' }),
  translate({ id: 'global.calendar.months.long.july' }),
  translate({ id: 'global.calendar.months.long.august' }),
  translate({ id: 'global.calendar.months.long.september' }),
  translate({ id: 'global.calendar.months.long.october' }),
  translate({ id: 'global.calendar.months.long.november' }),
  translate({ id: 'global.calendar.months.long.december' }),
]

export const getMonthsShortLabelsText = () => [
  translate({ id: 'global.calendar.months.short.january' }),
  translate({ id: 'global.calendar.months.short.february' }),
  translate({ id: 'global.calendar.months.short.march' }),
  translate({ id: 'global.calendar.months.short.april' }),
  translate({ id: 'global.calendar.months.short.may' }),
  translate({ id: 'global.calendar.months.short.june' }),
  translate({ id: 'global.calendar.months.short.july' }),
  translate({ id: 'global.calendar.months.short.august' }),
  translate({ id: 'global.calendar.months.short.september' }),
  translate({ id: 'global.calendar.months.short.october' }),
  translate({ id: 'global.calendar.months.short.november' }),
  translate({ id: 'global.calendar.months.short.december' }),
]

const getMonthLabelShortFromMonthIndex = (index: number) => {
  const monthIndex = Math.abs(index) % 12
  switch (monthIndex) {
    case 0:
      return translate({ id: 'global.calendar.months.short.january' })
    case 1:
      return translate({ id: 'global.calendar.months.short.february' })
    case 2:
      return translate({ id: 'global.calendar.months.short.march' })
    case 3:
      return translate({ id: 'global.calendar.months.short.april' })
    case 4:
      return translate({ id: 'global.calendar.months.short.may' })
    case 5:
      return translate({ id: 'global.calendar.months.short.june' })
    case 6:
      return translate({ id: 'global.calendar.months.short.july' })
    case 7:
      return translate({ id: 'global.calendar.months.short.august' })
    case 8:
      return translate({ id: 'global.calendar.months.short.september' })
    case 9:
      return translate({ id: 'global.calendar.months.short.october' })
    case 10:
      return translate({ id: 'global.calendar.months.short.november' })
    case 11:
      return translate({ id: 'global.calendar.months.short.december' })

    default:
      return ''
  }
}

const getMonthLabelLongFromMonthIndex = (index: number) => {
  const monthIndex = Math.abs(index) % 12
  switch (monthIndex) {
    case 0:
      return translate({ id: 'global.calendar.months.long.january' })
    case 1:
      return translate({ id: 'global.calendar.months.long.february' })
    case 2:
      return translate({ id: 'global.calendar.months.long.march' })
    case 3:
      return translate({ id: 'global.calendar.months.long.april' })
    case 4:
      return translate({ id: 'global.calendar.months.long.may' })
    case 5:
      return translate({ id: 'global.calendar.months.long.june' })
    case 6:
      return translate({ id: 'global.calendar.months.long.july' })
    case 7:
      return translate({ id: 'global.calendar.months.long.august' })
    case 8:
      return translate({ id: 'global.calendar.months.long.september' })
    case 9:
      return translate({ id: 'global.calendar.months.long.october' })
    case 10:
      return translate({ id: 'global.calendar.months.long.november' })
    case 11:
      return translate({ id: 'global.calendar.months.long.december' })

    default:
      return ''
  }
}

export const getMonthLabelFromIndex = (index: number, format: DateFormat) =>
  format === 'short'
    ? getMonthLabelShortFromMonthIndex(index)
    : getMonthLabelLongFromMonthIndex(index)
