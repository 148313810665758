import { useMemo } from 'react'

import { DropDownField, DropdownItem } from '@/forms-legacy'

import * as OrderTexts from '../../../intl'
import { Props } from '../typings'
import { ExecutionEventsFormValues } from '../../../typings'

const composeValues = ({ statusReasons }: uui.domain.client.rm.Territory, statusReason: string) => {
  const statusReasonsList = statusReasons.map<DropdownItem>(reason => ({
    id: reason,
    label: reason,
  }))

  if (!statusReasons.includes(statusReason)) {
    const outdatedItem = {
      id: statusReason,
      label: statusReason,
    }

    statusReasonsList.unshift(outdatedItem)
    return statusReasonsList
  }

  return statusReasonsList
}

export function ReportedStatusReason(props: Props) {
  const {
    territory,
    formRenderProps: { form },
  } = props

  const { initialValues } = form.getState()
  const { statusReason: initialStatusReason = '' } = initialValues as ExecutionEventsFormValues

  const dropDownValues: DropdownItem[] = useMemo(() => {
    return composeValues(territory, initialStatusReason)
  }, [territory, initialStatusReason])

  return (
    <DropDownField
      name="statusReason"
      testid="ReportedStatusReason-root"
      label={OrderTexts.getReportedStatusReason()}
      values={dropDownValues}
    />
  )
}
