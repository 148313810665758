import { useSelector } from 'react-redux'

import { selectTrackingListStructure } from '@/features/domain/lists'
import { isGpsOnly } from '@/features/domain/user'

import { useFilterList } from './useFilterList'
import { useSearchList } from './useSearchList'

export function useFilter() {
  const canFilterByBadge = useSelector(isGpsOnly)
  const listStructure = useSelector(selectTrackingListStructure)

  const { query, setQuery } = useSearchList()
  const { filter, setFilter } = useFilterList()

  return {
    query,
    filter,
    setQuery,
    setFilter,
    noEntries: listStructure.totalItems === 0,
    canFilterByBadge,
  }
}
