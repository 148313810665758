import { useHostname } from './useHostname'
import { useAutoLoginQueryString } from './useAutoLoginQueryString'
import { useMapStylesQueryString } from './useMapStylesQueryString'

/**
 * Generate the link to the Import Wizard app.
 */
export function useImportWizardLink() {
  const loginAs = useAutoLoginQueryString()
  const hostname = useHostname()
  const mapStyles = useMapStylesQueryString()

  return `${window.location.protocol}//import.${hostname}/#/import-orders?${loginAs}&${mapStyles}`
}
