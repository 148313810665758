import styled from 'styled-components'

type Props = {
  quote?: boolean
  alignedWithForm?: boolean
}

const FooterMessage = styled.div<Props>`
  position: relative;
  padding: 0 40px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: ${p => (p.alignedWithForm ? 20 : 0)}px;
  margin-bottom: ${p => (p.alignedWithForm ? 20 : 0)}px;

  padding-left: ${p => (p.alignedWithForm ? 0 : 40)}px;

  > * {
    margin-bottom: 0;
    margin-top: 0;
  }

  > * + * {
    margin-top: 2px;
  }

  &::before {
    content: '';
    display: ${p => (p.quote ? 'block' : 'none')};
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${p => (p.alignedWithForm ? -12 : 27)}px;
    width: 2px;
    background: ${p => p.theme.colors.$darkOrange};
  }
`

FooterMessage.displayName = 'FooterMessage'
export default FooterMessage
