import { ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useEnvironmentURLs } from '@/atoms'
import { conversionUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'
import { OnRemovePOD, FlexRow, PodListField } from '@/forms-legacy'

import ReadonlyAudio from '../../../../../../components/Pods/elements/ReadonlyAudio'

import * as OrderTexts from '../../../intl'

import { Props } from '../typings'

import DeleteBtn from './DeleteBtn'
import NoneLabel from './NoneLabel'

type PodAudioProps = {
  onRemove: OnRemovePOD
  audio: uui.domain.client.rm.MediaPod
  index: number
  userConfig: uui.domain.UserConfiguration
} & {
  onTogglePod: Props['onTogglePod']
  activePodMarker: Props['activePodMarker']
}

function PodAudio(props: PodAudioProps) {
  const { audio, onTogglePod, onRemove, index, userConfig, activePodMarker } = props
  const { sec, text, token, latLng } = audio

  const { podsBaseUrl } = useEnvironmentURLs()

  const time = conversionUtils.convertTime(userConfig)(sec)
  const title = text || `${OrderTexts.getVoiceMemoLabel()} ${index}`
  const src = `${podsBaseUrl}${token}`

  return (
    <FlexRow key={`pod_audio_${index}`}>
      <DeleteBtn onClick={() => onRemove(audio.uuid)} style={{ marginRight: 10 }} />
      <ReadonlyAudio
        key={`pod_audio_${index}`}
        uuid={audio.uuid}
        src={src}
        title={title}
        time={time}
        latLng={latLng || undefined}
        onToggle={onTogglePod}
        selected={activePodMarker?.uid === audio.uuid}
      />
    </FlexRow>
  )
}

export function PodAudios(props: Props) {
  const { activePodMarker, onTogglePod } = props
  const userConfig = useSelector(selectUserConfiguration)

  const renderItems = useMemo(
    () =>
      (values: uui.domain.client.rm.Pod[], onRemove: OnRemovePOD): ReactNode => {
        return (
          <>
            {values.length > 0 &&
              values.map((audio, index) => (
                <PodAudio
                  audio={audio as uui.domain.client.rm.MediaPod}
                  index={index}
                  activePodMarker={activePodMarker}
                  onTogglePod={onTogglePod}
                  userConfig={userConfig}
                  onRemove={onRemove}
                  key={index}
                />
              ))}
            {values.length === 0 && (
              <NoneLabel style={{ marginLeft: 10 }}>{OrderTexts.getNoneText()}</NoneLabel>
            )}
          </>
        )
      },
    [activePodMarker, onTogglePod, userConfig],
  )

  return (
    <PodListField
      name="formPods.audios"
      label={OrderTexts.getVoiceMemosLabel()}
      renderItems={renderItems}
    />
  )
}
