import { proxy } from 'valtio'

type EditableRoadSegmentAtom = {
  roadSegment: uui.domain.ui.map.EditableRoadSegment
}

export const defaultEditableRoadSegment: uui.domain.ui.map.EditableRoadSegment = {
  id: 'none',
  mode: 'none',
  type: 'disabled',
}

/**
 * This atom holds the current status of the application Editable RoadSegment.
 * An Editable RoadSegment is used to draw a Region or a Traffic Region over the Map.
 */
export const editableRoadSegment = proxy<EditableRoadSegmentAtom>({
  roadSegment: { ...defaultEditableRoadSegment },
})
