import { useEffect, useState, useCallback } from 'react'

export function useGpsDataCollapsed(deviceId: string) {
  const [collapsed, setCollapsed] = useState(true)

  const toggleCollapsed = useCallback(() => {
    setCollapsed(prev => !prev)
  }, [])

  // product choice: when the vehicle changes, set the collapsed to true
  useEffect(() => {
    setCollapsed(true)
  }, [deviceId])

  return { collapsed, setCollapsed, toggleCollapsed }
}
