import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState({
    unnamedOrder: translate({ id: 'orders.unnamedOrder' }),
    timeWindows: translate({ id: 'map.tooltip.order.timeWindows' }),
    serviceTime: translate({ id: 'map.tooltip.order.serviceTime' }),
    yesterday: translate({ id: 'global.yesterday' }),
    tomorrow: translate({ id: 'global.tomorrow' }),
    today: translate({ id: 'global.today' }),
    eta: translate({ id: 'map.tooltip.order.eta' }),
  })

  return api
}
