import { Fork, Thick, WarningTriangle } from '@/icons'

interface Props {
  dispatchedRoutesCount: number
  routesCount: number
  dispatched?: boolean
}

export function Icon(props: Props) {
  const { dispatched, routesCount, dispatchedRoutesCount } = props

  const noRoutes = routesCount === 0 && dispatchedRoutesCount === 0
  const allDispatched = dispatchedRoutesCount === routesCount

  if (noRoutes || !dispatched) {
    return <Fork color="$silver" as="span" size={12} marginRight={5} />
  }

  if (allDispatched) {
    return <Thick color="$pigmentGreen" as="span" size={12} marginRight={5} />
  }

  return (
    <WarningTriangle
      background="$darkOrange"
      color="$pureWhite"
      as="span"
      size={12}
      marginRight={5}
    />
  )
}
