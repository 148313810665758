import styled from 'styled-components'

type Props = {
  marginLess?: boolean
}

const SecondaryColumnContentContainer = styled.div<Props>`
  position: relative;
  flex: 0 0 auto;

  display: flex;
  flex-direction: column;
  align-items: row;
  justify-content: space-evenly;
  margin: ${p => (p.marginLess ? '0' : '0 30px')};
`

SecondaryColumnContentContainer.displayName = 'SecondaryColumnContentContainer'
export default SecondaryColumnContentContainer
