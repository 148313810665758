import { useCallback, useRef } from 'react'
import { Add, FileUpload } from '@mui/icons-material'

import {
  ListHeaderActions,
  ListHeaderPrimaryActionButton,
  type ListHeaderSecondaryActionRef,
} from '@/components/List'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../../useTexts'

import { useActions } from './hooks/useActions'

export function Actions() {
  const { editing, onCreate, onImportKml } = useActions()
  const texts = useTexts()

  const secondaryActionMenuRef = useRef<ListHeaderSecondaryActionRef>(null)

  const onImportKmlClick = useCallback(() => {
    secondaryActionMenuRef.current?.close()
    onImportKml()
  }, [onImportKml])

  return (
    <ListHeaderActions>
      <ReadOnlyTooltip
        render={preventEditing => (
          <Tooltip title={texts.addRegion} placement="bottom">
            <ListHeaderPrimaryActionButton
              disabled={preventEditing || editing}
              onClick={onCreate}
              trackId="pendo-setup__new-region"
            >
              <Add />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
      <ReadOnlyTooltip
        render={preventEditing => (
          <Tooltip title={texts.importKml} placement="bottom">
            <ListHeaderPrimaryActionButton
              disabled={preventEditing || editing}
              onClick={onImportKmlClick}
              testId="list-header-secondary-action-import-kml"
              trackId="list-header-secondary-action-import-kml"
            >
              <FileUpload />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
    </ListHeaderActions>
  )
}
