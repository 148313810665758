import { useController } from '../../hooks/useController'

import { ShareLocationHeader } from './ShareLocationHeader'
import { SendMessageHeader } from './SendMessageHeader'

export function Header() {
  const { data } = useController()

  return !!data.locationToShare ? <ShareLocationHeader /> : <SendMessageHeader />
}
