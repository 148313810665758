import { proxy, useSnapshot } from 'valtio'

import { parseRecurrence } from '@/server-data'

const initialValue = { value: [] as uui.domain.rm.Recurrence[] } as const

const recurrencesAtom = proxy<{ value: uui.domain.rm.Recurrence[] }>(initialValue)

export function setRecurrences(driverAssignments: uui.domain.client.rm.DriverAssignmentsWithId[]) {
  const ids = driverAssignments.map(d => d.id)
  const recurrences = ids.map(id => {
    const recurrence = parseRecurrence(id)
    if (!recurrence) {
      throw new Error(`impossible to parse recurrence for ${id}`)
    }
    return recurrence
  })

  recurrencesAtom.value = recurrences
}

export function useRecurrences() {
  return useSnapshot(recurrencesAtom).value
}
