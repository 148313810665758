import { useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

type ModalStatus = 'ready' | 'pending'
type ModalData = Record<string, unknown>

export const modalId = 'startTelematicsDemo'

export const useController = () => useModalController<ModalData, ModalStatus>(modalId)
export const useConfigureController = () => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {},
    }),
  }))

  return useConfigureModalController<ModalData, ModalStatus>(modalId, options)
}
