import type { Style } from 'ol/style'

import { genericStyles } from '../../genericStyles'
import { getDeviceSelectionFeatureStyle } from './getDeviceSelectionFeatureStyle'
import { getDeviceOutlineFeatureStyle } from './getDeviceOutlineFeatureStyle'
import { getDeviceCircleFeatureStyle } from './getDeviceCircleFeatureStyle'
import { getDeviceArrowFeatureStyle } from './getDeviceArrowFeatureStyle'
import { getDeviceFeatureLabelStyle } from './getDeviceLabelFeatureStyle'
import { getDeviceIconFeatureStyle } from './getDeviceIconFeatureStyle'

const deviceStylesCache: Map<string, Style[]> = new Map()

export const getDeviceFeatureStyles = (
  marker: uui.domain.ui.map.markers.Device,
  mode: uui.domain.ui.map.markers.MapStyles['device']['mode'],
  selected: boolean = false,
) => {
  if (mode === 'off') return genericStyles.hidden

  const { arrowGrid, circleGrid, iconGrid, outlineGrid, selectionGrid, label } = marker

  let cacheId = `circle_${circleGrid[0]}_${circleGrid[1]}`

  cacheId += `_icon_${iconGrid[0]}_${iconGrid[1]}`
  cacheId += `_selection_${selectionGrid[0]}_${selectionGrid[1]}`
  cacheId += `_${selected ? 'selected' : 'not-selected'}`
  cacheId += `_mode_${mode}`
  cacheId += `_label_${label}`

  if (arrowGrid) {
    cacheId += `_arrow_${arrowGrid[0]}_${arrowGrid[1]}`
  }

  if (outlineGrid) {
    cacheId += `_outline_${outlineGrid[0]}_${outlineGrid[1]}`
  }

  if (deviceStylesCache.has(cacheId)) {
    const cachedStyle = deviceStylesCache.get(cacheId)

    if (cachedStyle) return cachedStyle
  }

  const styles = [
    getDeviceSelectionFeatureStyle(marker, mode, selected),
    getDeviceOutlineFeatureStyle(marker, mode),
    getDeviceArrowFeatureStyle(marker, mode),
    getDeviceCircleFeatureStyle(marker, mode),
    getDeviceIconFeatureStyle(marker, mode),
    getDeviceFeatureLabelStyle(marker, mode),
  ]

  deviceStylesCache.set(cacheId, styles)

  return styles
}
