import styled from 'styled-components'

import { brandColor } from '@/styles'

interface Props {
  planType: uui.domain.UserConfiguration['planType']
}

export const HeaderContainer = styled.div<Props>`
  width: 100%;
  height: 54px;
  color: ${p => p.theme.colors.$pureWhite};
  background-color: ${p => (p.planType === 'simulation' ? p.theme.colors.$nightRider : brandColor)};
`
HeaderContainer.displayName = 'HeaderContainer'
