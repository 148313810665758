import { DropDownField, FieldMessage } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'
import { useDropdownOptions } from './hooks/useDropdownOptions'

export function EventTypesList() {
  const texts = useTexts()
  const options = useDropdownOptions()

  return (
    <>
      <DropDownField name="eventTypeId" label={texts.label} values={options} />
      <FieldMessage>{texts.infoText}</FieldMessage>
    </>
  )
}
