import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useEditingStateWithCtx, useCrudSelection, resetCrudSelection } from '@/atoms'

import { selectRoadSegmentsListStructure } from '@/features/domain/lists'

export const useDataForList = () => {
  const { setEditing } = useEditingStateWithCtx('segment')
  const structure = useSelector(selectRoadSegmentsListStructure)
  const [selection, setSelection] = useCrudSelection('routingSegments')

  const lastSelectedRoadSegment = selection.length > 0 ? selection[selection.length - 1] : undefined

  const setCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  const deselectAll = () => resetCrudSelection('routingSegments')

  return {
    structure,
    setCreate,
    setEditing,
    deselectAll,
    setSelection,
    lastSelectedRoadSegment,
  }
}
