import { Entry } from '../Entry'

export function Entry20210319() {
  return (
    <Entry title="03/19/2021 - v3.0.0-alpha-10">
      <ul>
        <li>Fix some selection issues</li>
        <li>Disable some section and links not included in Phase 1</li>
        <li>Add missing RPCs</li>
        <li>Fix a lot of minor bugs</li>
      </ul>
    </Entry>
  )
}
