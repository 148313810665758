import type { SearchOnMapResult } from '@/hooks'

import { useEffect, useState } from 'react'

import { useGetMapInfo } from '@/map'
import { LocationEditor } from '@/forms-legacy'
import { useSearchOnMap } from '@/hooks'

import { useSearchOnMapActions } from './hooks/useSearchOnMapActions'
import { getEmptyLocation } from './utils'

interface Props {
  closeSearchOnMap: () => void
}

export function SearchBar(props: Props) {
  const { closeSearchOnMap } = props

  const searchOnMap = useSearchOnMap({ assetTypes: ['depot', 'place'] })
  const { center: mapCenter } = useGetMapInfo()

  const [assetList, setAssetList] = useState<uui.domain.client.gps.SearchOnMapItem[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [location, setLocation] = useState<uui.domain.client.Location>(
    getEmptyLocation(mapCenter, mapCenter),
  )

  const { onLocationChange, onClose, onTextChange } = useSearchOnMapActions(
    closeSearchOnMap,
    setLocation,
    setIsLoading,
    setAssetList,
  )

  useEffect(() => {
    searchOnMap('', (result: SearchOnMapResult) => setAssetList(result.items))
  }, [searchOnMap])

  return (
    <LocationEditor
      testId="search-on-map-input"
      height={150}
      status={'ok'}
      pinType="place"
      onClose={onClose}
      queryMinLength={3}
      loading={isLoading}
      location={location}
      assetList={assetList}
      startCollapsed={false}
      onChange={onLocationChange}
      onTextChange={onTextChange}
    />
  )
}
