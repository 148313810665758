import type { PartialStoreState } from '../typings'
import { selectRoutesInfo } from '../scheduler'

export const selectRoutes = (state: PartialStoreState) => state.domain.publicData.domain.rm.routes

export const selectApprovedRoutes = (state: PartialStoreState) => {
  const routesInfo = selectRoutesInfo(state)

  return Object.values(routesInfo).filter(routeInfo => !!routeInfo.approvedVersion)
}
