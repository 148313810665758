import { useEffect } from 'react'

import { subscribeToEditableArea } from '../../atoms/editableArea/subscribeToEditableArea'
import { getEditableArea } from '../../atoms/editableArea/core/getEditableArea'

import { getInteraction } from '../../interactions/interactionMetadata'
import { createOnAreaModeChange } from './utils/createOnAreaModeChange'
import { createOnAreaChange } from './utils/createOnAreaChange'
import { createOnAreaCreate } from './utils/createOnAreaCreate'
import { resetUseDrawArea } from './utils/resetUseDrawArea'

export function useDrawArea() {
  useEffect(() => {
    const drawArea = getInteraction('rm-draw-editable-area-interaction')
    const modifyArea = getInteraction('rm-modify-editable-area-interaction')

    if (!drawArea || !modifyArea) return

    // Listen to the Editable Area Store changes
    const handleAreaModeChange = createOnAreaModeChange(drawArea, modifyArea)
    const unsubscribeToAreaMode = subscribeToEditableArea(handleAreaModeChange, true)

    // listen to the creation of a new Area
    const unsubscribeOnAreaCreate = drawArea.onAreaCreate(createOnAreaCreate(drawArea))

    // listen to changes to an Area
    const unsubscribeOnAreaModify = modifyArea.onAreaChange(createOnAreaChange())

    // manually init interactions
    const area = getEditableArea()
    handleAreaModeChange(area)

    return () => {
      // ATOM subscription
      unsubscribeToAreaMode()

      // INTERACTIONS subscription
      unsubscribeOnAreaCreate()
      unsubscribeOnAreaModify()

      // RESET Open Layers interactions and layers
      resetUseDrawArea(drawArea, modifyArea)
    }
  }, [])
}
