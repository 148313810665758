import type { FormValidator } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../../formFields'
import type { Texts } from '../useTexts'

export const createValidateCompanies =
  (texts: Texts): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const isCompanyFiltered = formApi.getField('isCompanyFiltered')
    const companies = formApi.getField('companies')

    const hasError = isCompanyFiltered.value && companies.value.length === 0

    const companiesRequiredError: FormErrors = {
      id: 'companiesRequiredError',
      message: texts.errors.companies,
      field: 'companies' as const,
      priority: 10,
    }

    return {
      [companiesRequiredError.id]: hasError ? companiesRequiredError : null,
    }
  }
