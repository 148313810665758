import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    header: translate({ id: 'setup.driverAssistance.view.modeHeader' }),
    off: translate({ id: 'setup.driverAssistance.view.mode.off' }),
    suggest: translate({ id: 'setup.driverAssistance.view.mode.suggest' }),
    require: translate({ id: 'setup.driverAssistance.view.mode.require' }),
    checkIn: translate({ id: 'setup.driverAssistance.suggest.checkIn' }),
    checkOut: translate({ id: 'setup.driverAssistance.suggest.checkOut' }),
    includePicture: translate({ id: 'setup.driverAssistance.requires.includePicture' }),
    includeNote: translate({ id: 'setup.driverAssistance.requires.includeNote' }),
    includeSignature: translate({ id: 'setup.driverAssistance.requires.includeSignature' }),
    includeAudio: translate({ id: 'setup.driverAssistance.requires.includeAudio' }),
    orderStatus: translate({ id: 'setup.driverAssistance.requires.orderStatus' }),
    REQUIRED_TO_COMPLETE: translate({ id: 'setup.driverAssistance.requires.REQUIRED_TO_COMPLETE' }),
    REQUIRED_TO_NOT_COMPLETE: translate({
      id: 'setup.driverAssistance.requires.REQUIRED_TO_NOT_COMPLETE',
    }),
    ALWAYS_REQUIRED: translate({ id: 'setup.driverAssistance.requires.ALWAYS_REQUIRED' }),
  }))

  return api
}
