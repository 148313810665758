import styled from 'styled-components'

import { fieldContainerHeight } from '../constants'

type Props = {
  interactive?: boolean
}

const LocationEditorFieldContainer = styled.div<Props>`
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  height: ${fieldContainerHeight}px;
  background: ${p => p.theme.colors.$alabaster};
  padding: 0 10px;
  border-radius: 6px;
  outline: 0;

  transition: border-color 0.3s ease;

  &:hover {
    background: ${p => (p.interactive ? p.theme.colors.$silver : p.theme.colors.$alabaster)};
  }
`

LocationEditorFieldContainer.displayName = 'LocationEditorFieldContainer'
export default LocationEditorFieldContainer
