import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDepots } from '@/features/domain/depot'
import { selectLoadsFromVehicles, selectTagsFromVehicles } from '@/features/domain/vehicle'
import { selectUserConfiguration } from '@/features/domain/user'

import { useSortedRegions } from './hooks/useSortedRegions'

import { Form } from './Form'

interface Props {
  availability: 'available' | 'unavailable'
  initialValues: uui.domain.ui.forms.ExceptionFormValues
}

function sortDepots(
  a: uui.domain.client.rm.Depot,
  b: uui.domain.client.rm.Depot,
  locale: uui.domain.Language,
) {
  return a.name.localeCompare(b.name, locale, { numeric: true, ignorePunctuation: true })
}

export function FormProvider(props: Props) {
  const { availability, initialValues } = props

  const depots = useSelector(selectDepots)
  const userConfig = useSelector(selectUserConfiguration)

  const { language } = userConfig

  const sortedDepots = useMemo(() => {
    return Object.values(depots).sort((a, b) => sortDepots(a, b, language))
  }, [language, depots])

  const loadsSuggestions = useSelector(selectLoadsFromVehicles)
  const tagsFromVehicles = useSelector(selectTagsFromVehicles)
  const regions = useSortedRegions()

  const tagsSuggestions = useMemo(() => {
    return tagsFromVehicles.filter(t => !initialValues.tags.includes(t))
  }, [initialValues, tagsFromVehicles])

  return (
    <Form
      regions={regions}
      depots={sortedDepots}
      availability={availability}
      loadsSuggestions={loadsSuggestions}
      tagsSuggestions={tagsSuggestions}
      userConfig={userConfig}
    />
  )
}
