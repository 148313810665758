import { useMemo } from 'react'
import { HorizontalRadioGroupField, NumericStepperField } from '@/forms-legacy'

import { useTexts } from './useTexts'
import { useRadioGroupValues } from './useRadioGroupValues'

interface Props {
  showCustomField: boolean
}

const numericStepWrappedInputProps = {
  step: 1,
}

export function IdleStart(props: Props) {
  const { showCustomField } = props

  const texts = useTexts()
  const radioGroupValues = useRadioGroupValues()
  const wrappedInputProps = useMemo(() => {
    return {
      values: radioGroupValues,
    }
  }, [radioGroupValues])

  return (
    <>
      <HorizontalRadioGroupField
        name="device.idleStart.preset"
        testid="device.idleStart.preset"
        label={texts.label}
        info={texts.info}
        wrappedInputProps={wrappedInputProps}
      />
      {showCustomField && (
        <NumericStepperField
          name="device.idleStart.custom"
          testid="device.idleStart.custom"
          label={texts.min(texts.customLabel)}
          wrappedInputProps={numericStepWrappedInputProps}
        />
      )}
    </>
  )
}
