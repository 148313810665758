import styled from 'styled-components'

const ErrorLabel = styled.span`
  color: ${p => p.theme.colors.$outrageousRed};
  font-size: ${p => p.theme.fonts.$s};

  margin-top: 5px;
`

ErrorLabel.displayName = 'ErrorLabel'
export default ErrorLabel
