import axios from 'axios'
import { version } from '@/utils'

type Messages = Record<string, string>
export const intlMessagesLoader = (locale: uui.domain.Language) => {
  const controller = new AbortController()

  return {
    cancel: () => {
      controller.abort()
    },
    load: () =>
      new Promise<Messages>(async (resolve, reject) => {
        const appVersion = version.getVersion()
        try {
          const baseDir = '/locales'
          const response = await axios.get<Messages>(`${baseDir}/${locale}.json?v=${appVersion}`, {
            signal: controller.signal,
          })
          if (response.status !== 200) {
            return reject(response)
          }

          resolve(response.data)
        } catch (e) {
          reject(e)
        }
      }),
  }
}
