import { useCallback, useState } from 'react'

import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { usePendingFitMapOnMount } from '@/map'
import { resetCrudSelection } from '@/atoms'

import { GeofenceAvatar } from '../../components/GeofenceAvatar'
import { ViewDetails } from '../../components/ViewDetails'

import { ActionsManager } from './components/ActionsManager'
import { useTexts } from './hooks/useTexts'

interface Props {
  geofence: uui.domain.client.gps.wwgps.Geofence
}

export function SingleView(props: Props) {
  const { geofence } = props
  const { name, location } = geofence

  const [view, setView] = useState<'default' | 'delete'>(() => 'default')
  usePendingFitMapOnMount(true)

  const texts = useTexts()

  const onReset = useCallback(() => {
    resetCrudSelection('geofences')
  }, [])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.title(name)}
        description={location?.geoAddress}
        action={onReset}
        avatar={<GeofenceAvatar type={geofence.type} size={40} marginRight={16} />}
        data-testid="geofences-readonly-header"
      />
      <ActionsManager view={view} setView={setView} geofence={geofence} />
      <FormContent footer={<div />}>
        <ViewDetails geofence={geofence} />
      </FormContent>
    </FormColumn>
  )
}
