import { useMemo } from 'react'

import { HorizontalRadioGroupField } from '@/forms-legacy'
import { theme } from '@/styles'

import { useTexts } from '../hooks/useTexts'

export function Available() {
  const wrappedInputProps = useWrappedInputProps()

  return (
    <HorizontalRadioGroupField name="available" label={''} wrappedInputProps={wrappedInputProps} />
  )
}

function useWrappedInputProps() {
  const texts = useTexts()

  return useMemo(() => {
    return {
      colors: {
        background: theme.colors.$pureWhite,
        backgroundSelected: theme.colors.$nightRider,
        color: theme.colors.$nightRider,
        colorSelected: theme.colors.$pureWhite,
        border: theme.colors.$nightRider,
        borderSize: 1,
      },
      values: [
        {
          label: texts.fieldAvailable(true),
          value: 'available',
        },
        {
          label: texts.fieldAvailable(false),
          value: 'unavailable',
        },
      ],
    }
  }, [texts])
}
