import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    placeholder: translate({ id: 'companies.table.header.search.placeholder' }),
  }))

  return api
}
