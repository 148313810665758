import type { LocationEditorProps } from '../typings'

export function useFormFieldOnChange(options: LocationEditorProps) {
  const {
    formProps: {
      input: { onChange },
    },
  } = options

  return onChange
}
