import { addSeconds, startOfToday } from 'date-fns/esm'

import { timeWindowUtils, conversionUtils, timeUtils } from '@/server-data'
import * as OrderTexts from '../../../../intl'

const getTypeLabel = (type: uui.domain.client.rm.OrderIdentifier): string => {
  switch (type) {
    case 'd':
      return OrderTexts.getDeliveryText()
    case 's':
      return OrderTexts.getServiceText()
    case 'p':
      return OrderTexts.getPickupText()
    case 'pd':
      return OrderTexts.getPickupAndDeliveryText()
  }
}

export function useOrderListItem(
  order: uui.domain.client.rm.ExtendedOrderStep,
  workingDayStartSec: number,
  userConfiguration: uui.domain.UserConfiguration,
  route?: uui.domain.client.rm.ExtendedRoute,
) {
  const address = order.atDepot
    ? order.depot.name
    : order.location.address || order.location.geoAddress
  const title = order.order.name || OrderTexts.getUnnamedOrderText()
  const typeLabel = getTypeLabel(order.orderStep.type)

  const timeWindows = timeWindowUtils.normalizeTimeWindows(
    order.orderStep.timeWindows,
    workingDayStartSec,
  )

  const twStart = conversionUtils.convertTime(userConfiguration)(
    timeWindows.length > 0 ? timeWindows[0].startSec : 0,
    false,
  )
  const twEnd = conversionUtils.convertTime(userConfiguration)(
    timeWindows.length > 0 ? timeWindows[0].endSec : 0,
    false,
  )

  const startSecDate = !order.isUnassigned
    ? addSeconds(startOfToday(), order.stepStartSec)
    : undefined

  const humanTimeStart = startSecDate ? timeUtils.formatTime(userConfiguration)(startSecDate) : ''
  const formattedDate = !!route ? timeUtils.formatDate(userConfiguration)(route.route.date) : ''

  const etaLabel =
    humanTimeStart.length > 0
      ? `${OrderTexts.getETAText()}: ${humanTimeStart} - ${formattedDate}`
      : '-'
  const timeWindowText = timeWindows.length === 0 ? `-` : `${twStart} - ${twEnd}`

  return {
    address,
    title,
    typeLabel,
    etaLabel,
    timeWindowText,
    humanTimeStart,
  }
}
