import type {
  ExceptionReadonlyValues,
  BreakReadonlyValue,
  RegionReadonlyValue,
  LoadReadonlyValue,
} from '../types'

import { timeWindowUtils, numberUtils } from '@/utils'
import { intl } from '@/intl'
import { conversionUtils, durationUtils } from '@/server-data'

import { secToTimeHHmm } from './secToTimeHHmm'

type Params = {
  vs: uui.domain.server.rm.VehicleSettings
  departureDepotData: string
  arrivalDepotData: string
  breaksData: BreakReadonlyValue[]
  regionData: RegionReadonlyValue[]
  loadsData: LoadReadonlyValue[]
  workingDayStartSec: number
  userConfig: uui.domain.UserConfiguration
}

export function computeReadonlyValues(params: Params): ExceptionReadonlyValues {
  const {
    vs,
    regionData,
    workingDayStartSec,
    userConfig,
    breaksData,
    departureDepotData,
    arrivalDepotData,
    loadsData,
  } = params

  const {
    available,
    earlyDeparture,
    arrivalDepotId,
    departureDepotId,
    timeWindow: sourceTimeWindow,
    maxOrders,
    maxDistanceMt,
    maxDrivingTimeSec,
    maxWorkingTimeSec,
    minWorkingTimeSec,
    preparationTimeSec,
    closeOutTimeSec,
    tags,
    speedFactor,
    activationCost,
    serviceTimeCost,
    drivingTimeCost,
    idleTimeCost,
    breakTimeCost,
    kmCost,
    perStopCost,
    notes,
  } = vs

  const { startSec, endSec } = timeWindowUtils.formatTimeWindow(
    sourceTimeWindow,
    workingDayStartSec,
  )

  const minWorkingTimeSecNormalized: number =
    typeof minWorkingTimeSec === 'number' ? minWorkingTimeSec : 0

  const metric = userConfig.distanceFormat === 'METRIC'
  const currency = userConfig.currency
  const timeFormat = userConfig.timeFormat

  const distanceSuffix = metric ? 'km' : 'mi'
  const hourSuffix = 'hr'
  const stopSuffix = 'stop'

  const decimalSeparator = numberUtils.getDecimalSeparator(metric)

  const fixedCost =
    activationCost > 0
      ? withCurrency(withDecimalSeparator(activationCost / 100, decimalSeparator), currency)
      : undefined

  const serviceTime =
    serviceTimeCost > 0
      ? withCurrency(
          withDecimalSeparator(serviceTimeCost / 100, decimalSeparator),
          currency,
          hourSuffix,
        )
      : undefined

  const drivingTime =
    drivingTimeCost > 0
      ? withCurrency(
          withDecimalSeparator(drivingTimeCost / 100, decimalSeparator),
          currency,
          hourSuffix,
        )
      : undefined

  const idleTime =
    idleTimeCost > 0
      ? withCurrency(
          withDecimalSeparator(idleTimeCost / 100, decimalSeparator),
          currency,
          hourSuffix,
        )
      : undefined

  const breakTime =
    breakTimeCost > 0
      ? withCurrency(
          withDecimalSeparator(breakTimeCost / 100, decimalSeparator),
          currency,
          hourSuffix,
        )
      : undefined

  const perMile =
    kmCost > 0
      ? withCurrency(
          withDecimalSeparator(
            metric ? kmCost / 100 : conversionUtils.convertCostPerKmToCostPerMi(kmCost / 100),
            decimalSeparator,
          ),
          currency,
          distanceSuffix,
        )
      : undefined

  const perStop =
    perStopCost > 0
      ? withCurrency(
          withDecimalSeparator(perStopCost / 100, decimalSeparator),
          currency,
          stopSuffix,
        )
      : undefined

  const formatDurationSec = durationUtils.formatSeconds(intl.translate)

  return {
    available,
    startLocation: departureDepotData,
    endLocation: arrivalDepotData,
    flexibleStart: !earlyDeparture,
    workingDayStart: secToTimeHHmm(startSec, '', timeFormat),
    workingDayEnd: secToTimeHHmm(endSec, '', timeFormat),
    breaks: breaksData,
    regions: regionData,
    maxOrders: maxOrders > 0 ? `${maxOrders}` : undefined,
    maxMileage: conversionUtils.convertDistance(userConfig)(maxDistanceMt, 2, true),
    maxDrivingHours:
      maxDrivingTimeSec > 0 ? formatDurationSec(maxDrivingTimeSec, 'SHORT') : undefined,
    maxWorkingHours:
      maxWorkingTimeSec > 0 ? formatDurationSec(maxWorkingTimeSec, 'SHORT') : undefined,
    minWorkingHours:
      minWorkingTimeSecNormalized > 0
        ? formatDurationSec(minWorkingTimeSecNormalized, 'SHORT')
        : undefined,
    loads: loadsData,
    tags,
    speedFactor,
    fixedCost,
    serviceTime,
    drivingTime,
    idleTime,
    breakTime,
    perMile,
    perStop,
    notes: notes.length > 0 ? notes : undefined,
    preparationTimeSec: !!departureDepotId
      ? formatDurationSec(preparationTimeSec, 'SHORT')
      : undefined,
    closeOutTimeSec: !!arrivalDepotId ? formatDurationSec(closeOutTimeSec, 'SHORT') : undefined,
  }
}

function withDecimalSeparator(value: number, decimalSeparator: numberUtils.DecimalSeparator) {
  return numberUtils.fromNumberToString(
    numberUtils.roundNumberToHundredths(value),
    decimalSeparator,
  )
}

function withCurrency(value: string, currency: string, suffix?: string) {
  const sfx = suffix ? `/${suffix}` : ''
  return `${value} ${currency}${sfx}`
}
