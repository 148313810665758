import { navigateHorizontally } from '../selection/navigateHorizontally'

// this will take care of the keyboard navigation when the scheduler has focus
export const navigator = {
  processEvent(e: KeyboardEvent | PointerEvent) {
    // Only handle keyboard events
    if (!(e instanceof KeyboardEvent)) {
      return e
    }

    e.preventDefault()
    e.stopImmediatePropagation()
    e.stopPropagation()

    switch (e.key) {
      case 'ArrowLeft':
      case 'ArrowRight':
        navigateHorizontally(e.key === 'ArrowLeft' ? 'left' : 'right', e.shiftKey)
        break

      case 'ArrowUp':
      case 'ArrowDown':
        // No up/down navigation at the moment
        break
    }
    return false
  },
}
