import type { FormValues, LazyCreateFormConfig } from '@workwave-tidal/tidal/form-fairy'
import type { FormErrors, FormFields } from '../formFields'
import { useState } from 'react'
import { useReasonsForNotCompletedOrdersValidation } from './useReasonsForNotCompletedOrdersValidation'

export function useCreateFormConfig(
  overrideValues: FormValues<FormFields>,
): LazyCreateFormConfig<FormFields, FormErrors> {
  const reasonsForNotCompletedOrdersValidation = useReasonsForNotCompletedOrdersValidation()

  const [createFormConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    return () => ({
      overrideValues,
      validations: [
        {
          id: 'reasonsForNotCompletedOrdersValidation',
          validator: reasonsForNotCompletedOrdersValidation,
          fields: 'reasonsForNotCompletedOrders',
        },
      ],
    })
  })

  return createFormConfig
}
