import styled from 'styled-components'

const SecondaryColumnInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${p => p.theme.colors.$pureWhite};
`
SecondaryColumnInner.displayName = 'SecondaryColumnInner'
export default SecondaryColumnInner
