import type { Patch } from 'immer'
import type { AsyncThunkApiConfig } from '@/store'

import { applyPatches } from 'immer'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { extractOrdersGridRows } from '@/server-data'
import { generateNewExtendedOrderSteps, OrdersError } from '../../utils/replaceDataUtils'

/**
 * Updating public data means receiving and applying Immer' patches manually. This is a different
 * scenario compared to a normal Redux Toolkit reducer which receives an Immer draft to be mutated
 * and that's why a thunk is used.
 *
 * @private
 */
export const updateDomainData = createAsyncThunk<
  // Return type of the payload creator
  {
    publicData: uui.domain.PublicData
    extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>
    ordersGridRows: uui.domain.client.rm.FormattedData[]
  },
  // First argument to the payload creator
  {
    changeSet: Patch[]
    summary: uui.domain.api.ChangeSetSummary
  },
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/updateDomainData', async (params, thunkApi) => {
  const { changeSet, summary } = params

  try {
    const state = thunkApi.getState()
    const prevPublicData = state.domain.publicData
    const nextPublicData = applyPatches<uui.domain.PublicData>(prevPublicData, changeSet)

    let extendedOrderSteps = state.domain.extendedOrderSteps
    let ordersGridRows = state.domain.ordersGridRows

    try {
      if (summary.orders) {
        extendedOrderSteps = generateNewExtendedOrderSteps(
          prevPublicData,
          nextPublicData,
          extendedOrderSteps,
          summary.orders,
        )
      }

      if (summary.ordersGridChanged !== 'none') {
        ordersGridRows = extractOrdersGridRows(
          extendedOrderSteps,
          nextPublicData.domain.lists.ordersGrid.structure,
        )
      }
    } catch (e) {
      if (e instanceof OrdersError) {
        thunkApi.extra.journal.domain(
          `updateDomainData action error: ${e.message}`,
          { info: e.data },
          'warn',
        )
      } else {
        throw e
      }
    }

    return {
      publicData: nextPublicData,
      extendedOrderSteps,
      ordersGridRows,
    }
  } catch (error) {
    // TODO: add a proper localized message
    // JOURNAL: trigger the journal
    return thunkApi.rejectWithValue({
      message: 'updateDomainData failed',
      error,
    })
  }
})

type RejectError = {
  message: string
  error: Error
}
