import { useFormTransaction } from '@/hooks'

import { useNewGeofenceValue } from './hooks/useNewGeofenceValue'
import { SingleEdit } from './SingleEdit'

interface Props {
  geofence?: uui.domain.client.gps.wwgps.Geofence
}

export function SingleEditProvider(props: Props) {
  const { geofence } = props

  const transactionState = useFormTransaction()
  const newValue = useNewGeofenceValue()

  const loading = transactionState === 'loading'

  if (!geofence) {
    return <SingleEdit geofence={newValue} loading={loading} creating />
  }

  return <SingleEdit geofence={geofence} loading={loading} />
}
