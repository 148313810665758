import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectTerritory } from '@/features/domain/territory'

export function useDynamicLoadsCfData() {
  const { enableDynamicCustomFields, enableDynamicLoads } = useSelector(selectTerritory)

  return useMemo(
    () => ({
      enableDynamicLoads,
      enableDynamicCustomFields,
    }),
    [enableDynamicCustomFields, enableDynamicLoads],
  )
}
