import { Stroke, Style } from 'ol/style'
import { drawStyleCommon } from './common'

const common = drawStyleCommon()

export const fullSegment = new Style({
  stroke: new Stroke({
    color: common.black(1),
    lineDashOffset: 6,
    lineDash: [4, 8],
    width: 2,
  }),
})
