import type Feature from 'ol/Feature'
import type Geometry from 'ol/geom/Geometry'
import type { OptionalSelectionModifiers } from '@/atoms'

import { getMainSelection, setMainSelection } from '@/atoms'

import { getConnectedList } from '../../../../atoms/selection/read/getConnectedList'
import { getOrderFeatureMetadata } from '../../../../layers/order/orderFeatureMetadata'

export function clickOrder(feature: Feature<Geometry>, modifiers: OptionalSelectionModifiers) {
  const connectedList = getConnectedList()

  const orderIdsRecord = getOrderFeatureMetadata(feature, 'ids')

  if (orderIdsRecord) {
    const ids = Object.keys(orderIdsRecord)

    if (ids.length > 0) {
      const mainSelection = getMainSelection()
      const withoutModifiers = Object.keys(modifiers).every(key => modifiers[key] === false)

      // If there's no modifier and the orderStep is already selected we must deselect it
      if (withoutModifiers) {
        const mustDeselectAll =
          ids.length === mainSelection.orderSteps.length &&
          ids.every(id => mainSelection.orderSteps.indexOf(id) !== -1)

        if (mustDeselectAll) {
          setMainSelection('orderSteps', [])
          return
        }
      }

      // select
      setMainSelection('orderSteps', ids, { modifiers })

      // try to scroll to
      if (
        connectedList.status === 'connected' &&
        connectedList.category === 'orderSteps' &&
        !connectedList.disabled
      ) {
        const targetId = ids[ids.length - 1]
        connectedList.scrollTo(targetId)
      }
    }
  }
}
