import VectorTileLayer from 'ol/layer/VectorTile'

import { mapAtom } from '../map'

export const getLiveTrafficLayer = () => {
  return mapAtom.map
    .getLayers()
    .getArray()
    .find(l => {
      return l.get('id') === 'liveTrafficTileLayer'
    }) as VectorTileLayer | undefined
}
