import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { setListOptions } from '@/features/domain/lists/actions'
import { selectTrafficProfilesListOptions } from '@/features/domain/lists'
import { useAppDispatch } from '@/store'

/**
 * Set the search' query of the list options.
 */
export function useSetQuery() {
  const listOptions = useSelector(selectTrafficProfilesListOptions)
  const dispatch = useAppDispatch()

  return useCallback(
    async (newValue: string) => {
      const request = await dispatch(
        setListOptions({
          category: 'trafficProfiles',
          options: { ...listOptions, search: { ...listOptions.search, query: newValue } },
        }),
      )
      return setListOptions.fulfilled.match(request)
    },
    [dispatch, listOptions],
  )
}
