import type { CSSProperties } from 'react'

import { styled } from '@mui/material'

import { theme } from '@/styles'
import { Star } from '@/icons'

interface Props {
  size?: number
  style?: CSSProperties
}

export function PlaceAvatar(props: Props) {
  const { size = 32, style = {} } = props

  return (
    <IconContainer style={style} size={size}>
      <Star size={18} />
    </IconContainer>
  )
}

type IconContainerProps = {
  size: number
}

const IconContainer = styled('div', { name: 'IconContainer' })<IconContainerProps>(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  background: '#a38f84',
  color: theme.colors.$pureWhite,
  fontWeight: theme.weights.$semiBold,
  overflow: 'hidden',
  height: `${props.size}px`,
  width: `${props.size}px`,
  fontSize: theme.fonts.$p2,
  border: `1px solid ${theme.colors.$pureWhite}`,
}))
