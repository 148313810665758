import { useState, useCallback } from 'react'

import { useOrderStepNotificationModal } from '@/components/modals/OrderStepNotificationModal'

import { useOrderStepNotification } from '../../../../orderNotificationAtom'

import { Loading } from './components/Loading'
import { NotificationsListFailed } from './components/NotificationsListFailed'
import { OrderStepNotificationList as OrderStepNotificationListComponent } from './components/OrderStepNotificationList'

type Props = {
  extendedOrderId: string
  status: 'loading' | 'failed' | 'ready'
}

export function OrderStepNotificationList(props: Props) {
  const { extendedOrderId, status } = props

  const [modalData, setModalData] = useState<
    uui.domain.client.rm.OrderStepEmailOrSMSNotification | undefined
  >()

  const notifications = useOrderStepNotification(extendedOrderId)

  const { Modal, show } = useOrderStepNotificationModal()

  const showModal = useCallback(
    (data: uui.domain.client.rm.OrderStepEmailOrSMSNotification) => {
      // ATTENTION: data is cloned because it come directly from the readonly valtio atom

      setModalData({ ...data })
      show()
    },
    [show],
  )

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'failed') {
    return <NotificationsListFailed />
  }

  return (
    <>
      <OrderStepNotificationListComponent notifications={notifications} showModal={showModal} />
      {modalData && <Modal data={modalData} />}
    </>
  )
}
