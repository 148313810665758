import type { SelectionRange } from '../typings'

import { Calendar } from '@/components/Calendar'
import { useUpdateOrderCountsByDate } from '@/hooks'

import { useTexts } from '../useTexts'

import { useOperationsCalendarData } from './hooks/useOperationsCalendarData'
import { useOnCalendarChange } from './hooks/useOnCalendarChange'

type Props = {
  selection?: SelectionRange
  today: Date
  onChange: (range: SelectionRange) => void
  firstDayNotArchived: Date
  maxIntervalLength: number
  lastSelectableDate?: Date
}

export function OperationsCalendar(props: Props) {
  const { firstDayNotArchived, today, onChange, selection, maxIntervalLength } = props

  const texts = useTexts()

  // Updates order counts at every open
  useUpdateOrderCountsByDate()

  const onCalendarChange = useOnCalendarChange(onChange)
  const { calendarData, calendarSelection, todayAsString, daysWithOrders } =
    useOperationsCalendarData(firstDayNotArchived, today, selection)

  return (
    <Calendar
      months={1}
      layout="interval"
      today={todayAsString}
      onChange={onCalendarChange}
      highlightedDays={daysWithOrders}
      maxIntervalLength={maxIntervalLength}
      tooltipIntlText={texts.ordersTooltip}
      minDate={calendarData.minDateAsString}
      maxDate={calendarData.maxDateAsString}
      disabledDays={calendarData.disabledDays}
      selectionEnd={calendarSelection.selectionEnd}
      selectionStart={calendarSelection.selectionStart}
      lastSelectableDate={calendarData.lastSelectableDate}
      firstNonArchivedDay={calendarData.firstNonArchivedDay}
    />
  )
}
