import type { RenderItemProps } from '@/components/StructuredVirtualList'

import { memo, useCallback } from 'react'
import { CalendarToday } from '@mui/icons-material'

import { ListItem, ListItemContent, ListItemRow, ListItemTitle } from '@/components/List'

import { useItemInfos } from '../hooks/useItemInfos'

type Props = Extract<RenderItemProps<'driverAssignments'>, { type: 'item' }>

export const DriverAssignmentItem = memo((props: Props) => {
  const { selected, item, onItemClick, onItemDoubleClick } = props

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])

  const { label, description } = useItemInfos(item.id)

  return (
    <ListItem
      onDoubleClick={onDoubleClick}
      selected={selected}
      onClick={onClick}
      testId="driver-assignment-list-item"
    >
      <ListItemTitle>{label}</ListItemTitle>

      <ListItemRow testId="driver-assignment-description-row">
        <ListItemContent icon={<CalendarToday />}>{description}</ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})

DriverAssignmentItem.displayName = 'DriverAssignmentItem'
