import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Content } from './Content'

type Props = {
  targetLayout: 'split' | 'map'
}

export function Root(props: Props) {
  return (
    <Modal modalId={modalId}>
      <Content targetLayout={props.targetLayout} />
    </Modal>
  )
}
