import { GeofenceIn } from './icons/GeofenceIn'
import { GeofenceOut } from './icons/GeofenceOut'
import { GeofenceInOut } from './icons/GeofenceInOut'

type Props = {
  type: uui.domain.server.gps.wwgps.Geofence['type']
}

export function GeofenceIcon(props: Props) {
  const { type } = props

  switch (type) {
    case 'arriving':
      return <GeofenceIn />

    case 'departing':
      return <GeofenceOut />

    case 'arr_dep':
      return <GeofenceInOut />
  }
}
