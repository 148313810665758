import { type EventModel, type EventTooltipConfig } from '@bryntum/schedulerpro'

const allowedTooltipEvents = new Set<uui.domain.client.rm.SchedulerEvent['type']>([
  'brk',
  'pickup',
  'delivery',
  'closeOut',
  'idle-time',
  'preparation',
])

export const listeners: EventTooltipConfig['listeners'] = {
  beforeShow(e: { source: { eventRecord: EventModel } }) {
    const { eventRecord } = e.source
    if (!eventRecord) return false

    // Only show tooltip for allowed events
    const type: uui.domain.client.rm.SchedulerEvent['type'] = eventRecord.getData('type')

    if (!allowedTooltipEvents.has(type)) {
      return false
    }

    // Only show tooltip for events that are not at depot
    const atDepot: uui.domain.client.rm.SchedulerEvent['atDepot'] = eventRecord.getData('atDepot')

    if (atDepot) {
      return false
    }

    // Only show tooltip for events that are not at not approved
    const isApproved: uui.domain.client.rm.SchedulerEvent['isApproved'] =
      eventRecord.getData('isApproved')

    if (isApproved) {
      return false
    }

    return true
  },
}
