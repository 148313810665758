import { WarningCard } from '@local/components'
import { useTexts } from '../useTexts'

export function ErrorBody() {
  const texts = useTexts()

  return (
    <WarningCard description={texts.errorDescription} title={texts.errorTitle} preset="alert" />
  )
}
