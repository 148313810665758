import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    bodyTitle: translate({
      id: 'orders.smsNotifications.notification.modal.bodyTitle',
    }),
  }))

  return api
}
