import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    routeStartPlannedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeStart.planned.tooltip.regular',
    }),
    routeStartNotCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeStart.tracked.tooltip.notCollected',
    }),
    routeStartCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeStart.tracked.tooltip.collected',
    }),
    routeEndPlannedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeEnd.planned.tooltip.regular',
    }),
    routeStartWontBeCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeStart.tracked.tooltip.wontBeCollected',
    }),
    routeEndNotCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeEnd.tracked.tooltip.notCollected',
    }),
    routeEndCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeEnd.tracked.tooltip.collected',
    }),
    routeEndWontBeCollectedTooltip: translate({
      id: 'rm.sidebar.routeDetails.view.routeEnd.tracked.tooltip.wontBeCollected',
    }),
    preparationTime: translate({ id: 'rm.sidebar.routeDetails.view.prepTime' }),
    closeoutTime: translate({ id: 'rm.sidebar.routeDetails.view.closeoutTime' }),
    driverStart: translate({ id: 'rm.sidebar.routeDetails.view.driverStart' }),
    routeStart: translate({ id: 'rm.sidebar.routeDetails.view.routeStart' }),
    driverEnd: translate({ id: 'rm.sidebar.routeDetails.view.driverEnd' }),
    routeEnd: translate({ id: 'rm.sidebar.routeDetails.view.routeEnd' }),
    orders: (count: number) =>
      translate({ id: 'rm.sidebar.routeDetails.view.orders', values: { count } }),

    formRouteStart: translate({ id: 'rm.sidebar.routeDetails.form.routeStart.label' }),
    formRouteStartEmpty: translate({ id: 'rm.sidebar.routeDetails.form.routeStart.error.empty' }),
    formRouteStartInvalid: translate({
      id: 'rm.sidebar.routeDetails.form.routeStart.error.invalid',
    }),

    formRouteEnd: translate({ id: 'rm.sidebar.routeDetails.form.routeEnd.label' }),
    formRouteEndEmpty: translate({ id: 'rm.sidebar.routeDetails.form.routeEnd.error.empty' }),
    formRouteEndInvalid: translate({
      id: 'rm.sidebar.routeDetails.form.routeEnd.error.invalid',
    }),

    formPreparationTimeTitle: translate({
      id: 'rm.sidebar.routeDetails.form.preparationTime.title',
    }),
    formPreparationTimeStart: translate({
      id: 'rm.sidebar.routeDetails.form.preparationTimeStart.label',
    }),
    formPreparationTimeEnd: translate({
      id: 'rm.sidebar.routeDetails.form.preparationTimeEnd.label',
    }),

    formCloseoutTimeTitle: translate({ id: 'rm.sidebar.routeDetails.form.closeoutTime.title' }),
    formCloseoutTimeStart: translate({
      id: 'rm.sidebar.routeDetails.form.closeoutTimeStart.label',
    }),
    formCloseoutTimeEnd: translate({
      id: 'rm.sidebar.routeDetails.form.closeoutTimeEnd.label',
    }),

    save: translate({ id: 'rm.sidebar.routeDetails.form.commands.save' }),
    cancel: translate({ id: 'rm.sidebar.routeDetails.form.commands.cancel' }),
    preparationTimeTooltipPlanned: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.tooltip.planned',
    }),
    preparationTimeTooltipNotCollected: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.tooltip.notCollected',
    }),
    preparationTimeTooltipWontBeCollected: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.tooltip.wontBeCollected',
    }),
    preparationTimeTooltipNotDispatched: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.tooltip.notDispatched',
    }),
    preparationTimeTooltipExecuted: translate({
      id: 'rm.sidebar.routeDetails.view.preparationTime.tooltip.executed',
    }),
    closeoutTimeTooltipExecuted: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.tooltip.executed',
    }),
    closeoutTimeTooltipPlanned: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.tooltip.planned',
    }),
    closeoutTimeTooltipNotCollected: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.tooltip.notCollected',
    }),
    closeoutTimeTooltipWontBeCollected: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.tooltip.wontBeCollected',
    }),
    closeoutTimeTooltipNotDispatched: translate({
      id: 'rm.sidebar.routeDetails.view.closeoutTime.tooltip.notDispatched',
    }),
  }))

  return api
}
