import { ReadonlyLabel, FormLayout, OpaqueContainer, HorizontalRuler } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'

import { DepartureDepotId } from './fields/DepartureDepotId'
import { PreparationTime } from './fields/PreparationTime'
import { ArrivalDepotId } from './fields/ArrivalDepotId'
import { WorkingDayInfo } from './fields/WorkingDayInfo'
import { CloseOutTime } from './fields/CloseOutTime'
import { SpeedFactor } from './fields/SpeedFactor'
import { Available } from './fields/Available'
import { RegionIds } from './fields/RegionIds'
import { Breaks } from './fields/Breaks'
import { Loads } from './fields/Loads'
import { Notes } from './fields/Notes'
import { Tags } from './fields/Tags'

import { Costs } from './components/Costs'
import { Constraints } from './components/Constraints'

interface Props {
  depots: uui.domain.client.rm.Depot[]
  regions: uui.domain.client.rm.Region[]
  loadsSuggestions: string[]
  tagsSuggestions: string[]
  userConfig: uui.domain.UserConfiguration
  availability: 'available' | 'unavailable'
}

export function Form(props: Props) {
  const { depots, regions, loadsSuggestions, tagsSuggestions, availability, userConfig } = props
  const texts = useTexts()

  const metric = userConfig.distanceFormat === 'METRIC'
  const h24 = userConfig.timeFormat !== 'hh:mm aa'

  return (
    <FormLayout>
      <div className="o-pendo__vehicle-form__vehicle-availability">
        <ReadonlyLabel primary>{texts.available}</ReadonlyLabel>
        <Available />
      </div>
      <HorizontalRuler />

      <OpaqueContainer opaque={availability === 'unavailable'}>
        <DepartureDepotId depots={depots} />
        <PreparationTime />

        <ArrivalDepotId depots={depots} />
        <CloseOutTime />

        <HorizontalRuler />

        <WorkingDayInfo h24={h24} />
        <HorizontalRuler />

        <Breaks timeFormat={userConfig.timeFormat} />
        <HorizontalRuler />

        <RegionIds regions={regions} />
        <HorizontalRuler />

        <Constraints metric={!!metric} />
        <HorizontalRuler />

        <Loads loadsSuggestions={loadsSuggestions} metric={metric} />
        <HorizontalRuler />

        <Tags tagsSuggestions={tagsSuggestions} />
        <HorizontalRuler />

        <div className="o-pendo__vehicle-form__speed">
          <SpeedFactor />
        </div>
        <HorizontalRuler />

        <Costs metric={!!metric} currency={userConfig.currency} />
        <HorizontalRuler />

        <Notes />
      </OpaqueContainer>
    </FormLayout>
  )
}
