import type { NotificationFormValues } from '../../NotificationForm'
import type { NotificationFormType } from '@/notificationForms'

import { Box, Typography, Switch } from '@mui/material'

import { useConfirmNotificationToggleModal } from '@/components/modals/ConfirmNotificationToggleModal'

interface Props {
  values: Partial<NotificationFormValues>
  notification: NotificationFormType
  toggleSubtitle: string
  onToggle: () => void
  available: boolean
  subtitle: string
  title: string
}

export function HeaderView(props: Props) {
  const { title, subtitle, onToggle, values, available, toggleSubtitle, notification } = props

  const { Modal: ConfirmNotificationToggleModal, show: showConfirmModal } =
    useConfirmNotificationToggleModal()

  const enabled = available ? !!values.enabled : false

  return (
    <Box
      justifyContent="space-between"
      component="header"
      paddingBottom={2}
      paddingRight={4}
      paddingLeft={4}
      display="flex"
    >
      <Box>
        <Typography component="h1" variant="h6">
          <Box fontWeight={600}>{title}</Box>
        </Typography>

        <Typography variant="caption">{subtitle}</Typography>
      </Box>

      <Box>
        <Switch
          className={`pendo-${notification}-toggle-notification`}
          data-testid={`toggle-notification`}
          data-trackid={`toggle-notification`}
          onClick={showConfirmModal}
          disabled={!available}
          checked={enabled}
          color="primary"
          name="checkedB"
        />
      </Box>

      <ConfirmNotificationToggleModal
        goingToEnable={!values.enabled}
        notification={notification}
        subtitle={toggleSubtitle}
        onToggle={onToggle}
        title={title}
      />
    </Box>
  )
}
