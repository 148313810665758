import { useEffect } from 'react'
import { useAppDispatch } from '@/store'
import { refreshMobileAppInteractionSettings } from '@/features/domain/territory'

export function useRefreshDriverAssistance() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    async function refresh() {
      const result = await dispatch(refreshMobileAppInteractionSettings())

      if (refreshMobileAppInteractionSettings.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    }

    refresh()
  }, [dispatch])
}
