import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    rmField: (key: keyof uui.domain.ui.forms.RouteManagerVehicleDataValidation) => {
      switch (key) {
        case 'name':
          return translate({ id: 'vehicles.form.edit.generalSettings.fields.name.label' })

        case 'color':
          return translate({ id: 'vehicles.form.edit.generalSettings.fields.color.label' })

        default:
          return key
      }
    },
  }))

  return api
}
