import { Typography } from '@mui/material'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function Confirm() {
  const texts = useTexts()

  const {
    data: { driverIds },
  } = useController()

  return <Typography>{texts.body(driverIds.length)}</Typography>
}
