import type { FormFields, FormErrors } from '../formFields'

import { Typography, FormHelperText, Box, Stack } from '@mui/material'
import { Checkbox } from '@workwave-tidal/tidal/form-ui'

import { WarningCard } from '@local/components'
import { TextButton } from '@/forms-legacy'
import { WarningTriangle } from '@/icons'

import { useTexts } from '../../hooks/useTexts'
import { useShowPodWarningCard } from '../hooks/useShowPodWarningCard'

export function Pods() {
  const texts = useTexts()
  const showWaringCard = useShowPodWarningCard()

  return (
    <Stack spacing={0}>
      <Typography variant="body1">{texts.podsTitle}</Typography>
      <Checkbox<'showPicturePod', FormFields, FormErrors>
        name="showPicturePod"
        testId="showPicturePod"
        label={texts.picture}
        validateOn="blur focus"
      />
      <Checkbox<'showSignaturePod', FormFields, FormErrors>
        name="showSignaturePod"
        testId="showSignaturePod"
        label={texts.signature}
        validateOn="blur focus"
      />
      <Checkbox<'showAudioPod', FormFields, FormErrors>
        name="showAudioPod"
        testId="showAudioPod"
        label={texts.voiceMemo}
        validateOn="blur focus"
      />
      <Checkbox<'showNotePod', FormFields, FormErrors>
        name="showNotePod"
        testId="showNotePod"
        label={texts.driverNote}
        validateOn="blur focus"
      />
      <FormHelperText>{texts.podsHelperText}</FormHelperText>
      <>
        {showWaringCard && (
          <Box paddingTop={4} paddingBottom={4}>
            <WarningCard
              preset="warning"
              title={texts.waringCardTitle}
              description={texts.waringCardDescription}
              Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
            >
              <TextButton onClick={() => console.warn('TODO')}>
                {texts.waringCardLinkText}
              </TextButton>
            </WarningCard>
          </Box>
        )}
      </>
    </Stack>
  )
}
