import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    visibilityTooltip: (visible: boolean) =>
      translate({ id: visible ? 'forms.list.item.tooltip.hide' : 'forms.list.item.tooltip.show' }),
  }))

  return api
}
