import { Alert, Stack } from '@mui/material'
import { VerticalLayout } from '@/components/layout'
import { Text } from '@/local/components'

import { useController } from '../hooks/useController'
import { useActions } from '../hooks/useActions'
import { useTexts } from '../useTexts'

export function Body() {
  const texts = useTexts()
  const {
    data: { numberOfImportedOrders, numberOfImportedOrdersWithLowAccuracy },
  } = useController()

  const { selectImportedOrdersWithLowAccuracy } = useActions()

  const showLowAccuracyAlert = numberOfImportedOrdersWithLowAccuracy > 0

  return (
    <VerticalLayout marginTop={32}>
      <Stack spacing={2} width="100%">
        <Text>{texts.ordersImported(numberOfImportedOrders)}</Text>
        {showLowAccuracyAlert && (
          <Alert
            onClick={selectImportedOrdersWithLowAccuracy}
            severity="warning"
            /*             action={
              <Button color="inherit" size="small">
                {texts.view}
              </Button>
            } */
          >
            {texts.lowAccuracyOrders(numberOfImportedOrdersWithLowAccuracy)}
          </Alert>
        )}
      </Stack>
    </VerticalLayout>
  )
}
