import { Footer as FooterUiComponent } from '@/forms-legacy'

import { useTexts } from './useTexts'

interface Props {
  creating?: boolean
  fieldsWithError: string[]
  onSubmit: () => void
  onCancel: () => void
  valid: boolean
  submitting: boolean
  readonly: boolean
  pristine: boolean
  disabled: boolean
}

export function Footer(props: Props) {
  const {
    fieldsWithError,
    submitting,
    valid,
    readonly,
    pristine,
    onSubmit,
    onCancel,
    disabled,
    creating,
  } = props

  const texts = useTexts()

  return (
    <FooterUiComponent
      errorTitle={texts.title}
      errors={fieldsWithError}
      submitting={submitting}
      primary={{
        text: texts.submit(creating),
        title: texts.submitTitle(valid, readonly, pristine),
        disabled,
        onClick: onSubmit,
      }}
      secondary={{
        text: texts.cancel,
        disabled: submitting,
        onClick: onCancel,
      }}
    />
  )
}
