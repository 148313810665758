import type { CrudSelection, MainSelection } from '@/atoms'
import { cleanUpCrudSelection, cleanUpMainSelection } from '@/atoms'

/**
 * Restore the selection and clean up disappeared item ids.
 *
 * Returns a boolean that indicates if crud or main selection are changed
 */

interface SelectionBackup {
  crud: CrudSelection
  main: MainSelection
}

type Params =
  | {
      mode: 'update'
      idsChangelog: uui.domain.api.IdsChangelog
      listChangelog: uui.domain.ui.list.FilterChangelog
      calendarRangeChanged?: { calendarRange: uui.domain.DataRange; today: string }
      selectionBackup: workwave.DeepReadonly<SelectionBackup>
    }
  | {
      mode: 'replace'
      selectionBackup: workwave.DeepReadonly<SelectionBackup>
      publicData: uui.domain.PublicData
    }

export function restoreAndCleanupSelections(params: Params) {
  switch (params.mode) {
    case 'update':
      const mainStatusUpdate = cleanUpMainSelection({
        mode: params.mode,
        idsChangelog: params.idsChangelog,
        listChangelog: params.listChangelog,
        calendarRangeChanged: params.calendarRangeChanged,
      })

      const crudStatusUpdate = cleanUpCrudSelection({
        mode: params.mode,
        idsChangelog: params.idsChangelog,
        listChangelog: params.listChangelog,
      })

      // Let's return the status of the selection
      if (
        (mainStatusUpdate === 'unchanged' || mainStatusUpdate === 'updated') &&
        (crudStatusUpdate === 'unchanged' || crudStatusUpdate === 'updated')
      ) {
        return false
      }

      return true

    case 'replace':
      const mainStatusReplace = cleanUpMainSelection({
        mode: params.mode,
        publicData: params.publicData,
      })

      const crudChangedReplace = cleanUpCrudSelection({
        mode: params.mode,
        publicData: params.publicData,
      })

      // Let's return the status of the selection
      if (
        (mainStatusReplace === 'unchanged' || mainStatusReplace === 'updated') &&
        (crudChangedReplace === 'unchanged' || crudChangedReplace === 'updated')
      ) {
        return false
      }

      return true
  }
}
