import { Alert, AlertTitle } from '@mui/material'
import { useMemo } from 'react'
import { useTexts } from '../../../hooks/useTexts'

type Props = {
  userType: uui.domain.UserType
  trackingProvider: uui.domain.server.gps.TrackingProvider
}

export function UnavailableBanner(props: Props) {
  const { userType, trackingProvider } = props
  const texts = useTexts()

  const { title, description } = useMemo(() => {
    // If the user is a RM360 user and the tracking provider is telematics, we don't show the banner
    if (trackingProvider === 'telematics' && userType.startsWith('rm360')) {
      return { title: '', description: '' }
    }
    const title =
      trackingProvider === 'telematics'
        ? texts.noTrackingAlertTitle
        : texts.otherTrackingProviderAlertTitle

    const description =
      trackingProvider === 'telematics'
        ? texts.noTrackingAlertDescription
        : texts.otherTrackingProviderAlertDescription

    return { title, description }
  }, [texts, trackingProvider, userType])

  // If the user is a RM360 user and the tracking provider is telematics, we don't show the banner
  if (trackingProvider === 'telematics' && userType.startsWith('rm360')) return null

  return (
    <Alert severity="info" title="THE TIEE">
      <AlertTitle>{title}</AlertTitle>
      {description}
    </Alert>
  )
}
