import type { FormErrors, FormFields } from '../../formFields'

import { useMemo } from 'react'
import { SimpleSelect as Select } from '@workwave-tidal/tidal/form-ui'

import { useTexts } from './hooks/useTexts'

type Option = {
  type: 'option'
  label: string
  value: 'OFF' | 'BACK_OFFICE'
}

export function CallbackConfigStatusField() {
  const texts = useTexts()
  const options: Option[] = useMemo(
    () => [
      { type: 'option', label: texts.callbackConfigStatus.values.off, value: 'OFF' },
      { type: 'option', label: texts.callbackConfigStatus.values.backOffice, value: 'BACK_OFFICE' },
    ],
    [texts],
  )

  return (
    <Select<'callbackConfigStatus', 'OFF' | 'BACK_OFFICE', FormFields, FormErrors>
      name="callbackConfigStatus"
      label={texts.callbackConfigStatus.label}
      validateOn="change blur"
      options={options}
    />
  )
}
