import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    livePositionNotAvailable: translate({ id: 'rm.contextual.vehicles.livePositionNotAvailable' }),
    disabledNoDevice: (vehicleName: string) =>
      translate({ id: 'rm.contextual.vehicles.noLinkedDevice', values: { vehicleName } }),

    unknownPosition: translate({ id: 'rm.contextual.positionNA' }),

    vehiclesCounter: (count: number) =>
      translate({ id: 'global.counter.vehicles', values: { count } }),
  }))

  return api
}
