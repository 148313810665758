import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    hideAll: translate({ id: 'global.hideAll' }),

    showAll: translate({ id: 'global.showAll' }),

    radius: translate({ id: 'gps.geofences.radius' }),

    timezone: translate({ id: 'gps.geofences.timezone' }),

    dateTimeEnd: translate({ id: 'gps.geofences.view.end' }),

    dateTimeStart: translate({ id: 'gps.geofences.view.start' }),

    noDateTimeEnd: translate({ id: 'gps.geofences.view.noEnd' }),

    alertContacts: translate({ id: 'gps.geofences.alertContacts' }),

    maximumSpeedThreshold: translate({ id: 'gps.geofences.view.maximumSpeedThreshold' }),

    maximumSpeedThresholdDisabled: translate({
      id: 'gps.geofences.view.maximumSpeedThresholdDisabled',
    }),

    monitoredVehiclesCount: (count: number) =>
      translate({
        id: 'gps.geofences.view.monitoredVehiclesCount',
        values: { count },
      }),

    repeatType: (repeatType: 'continuous' | 'one-time') => {
      return translate({
        id:
          repeatType === 'one-time'
            ? 'gps.geofences.repeatType.oneTime'
            : 'gps.geofences.repeatType.continuous',
      })
    },
  }))

  return api
}
