import { createSelector } from '@reduxjs/toolkit'
import { selectNextHistoryRevision, selectPrevHistoryRevision } from '@/features/domain/history'

export const selectData = createSelector(
  selectPrevHistoryRevision,
  selectNextHistoryRevision,
  (prevRevision, nextRevision) => {
    return {
      prevRevision,
      nextRevision,
    }
  },
)
