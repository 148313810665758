import { useCallback } from 'react'
import { parseISO } from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { fileUtils } from '@/utils'
import { timeUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

import { parseFileContent } from './utils/parseFileContent'
import { useController } from './useController'

type RawParsedFile = fileUtils.RawParsedFile

/**
 * Manage the user dropping a file into the modal.
 */
export const useHandleOnDragAndDropFile = () => {
  const { updateData } = useController()
  const userConfig = useSelector(selectUserConfiguration)

  return useCallback(
    (nextFile: RawParsedFile) => {
      const importedTerritory = parseFileContent(nextFile)
      if (!importedTerritory) return

      const { lastUpdateDate } = importedTerritory.operations

      updateData({
        importedTerritory,
        fileName: nextFile.file.name,
        lastModifiedDate:
          lastUpdateDate && timeUtils.formatDate(userConfig)(parseISO(lastUpdateDate)),
      })
    },
    [updateData, userConfig],
  )
}
