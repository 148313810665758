import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { unproxify } from '@/utils'
import { useNotification, useIsUnmounted } from '@/hooks'
import { deleteDrivers as deleteDriversAction } from '@/features/domain/driver'

import { useController } from '../../../hooks/useController'

export function useDeleteDrivers(driverIds: string[]) {
  const { update, close } = useController()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const isUnmounted = useIsUnmounted()

  return useCallback(async () => {
    try {
      update({ status: 'submitting' })

      const result = await dispatch(deleteDriversAction(unproxify(driverIds)))

      if (isUnmounted()) return

      if (deleteDriversAction.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    } finally {
      update({ status: 'ready' })
      close?.()
    }
  }, [dispatch, toast, driverIds, update, close, isUnmounted])
}
