import type { FormFields, FormErrors } from '../../../../formFields'
import type { BulkTags } from '../../../../types'

import { useEffect } from 'react'

import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { updateTagsWarning } from '../../../../utils/updateTagsWarning'

// to help the user understand what tag is both in the banned and the required list
// warning props depends by both the value of tagsIn and tagsOut
// to keep it up to date a form mutation is the best way to do it
export function useUpdateTagsWarning() {
  const formApi = useFormApi<FormFields, FormErrors>()

  useEffect(() => {
    // Register a form-level mutation
    formApi.addFormMutation('tags-mutation', (api, changed) => {
      if (!changed.tagsIn && !changed.tagsOut) return

      const final = {
        // took the changed value otherwise the field value
        tagsIn: cloneBulkTags(changed.tagsIn ?? api.getField('tagsIn').value),
        tagsOut: cloneBulkTags(changed.tagsOut ?? api.getField('tagsOut').value),
      }

      updateTagsWarning(final.tagsIn, final.tagsOut)

      return final
    })

    return () => {
      // When the component unmounts remove the registered mutation
      formApi.removeFormMutation('tags-mutation')
    }
  }, [formApi])
}

function cloneBulkTags(bulkTags: BulkTags): BulkTags {
  return {
    affectedOrdersCount: bulkTags.affectedOrdersCount,
    remaining: bulkTags.remaining,
    tags: cloneTags(bulkTags.tags),
  }
}

function cloneTags(tags: BulkTags['tags']): BulkTags['tags'] {
  return Object.values(tags).reduce<BulkTags['tags']>((acc, curr) => {
    acc[curr.name] = { ...curr }
    return acc
  }, {})
}
