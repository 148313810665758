import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { produce } from 'immer'

import { useAppDispatch } from '@/store'
import { setListOptions } from '@/features/domain/lists/actions'
import { selectTrafficAreasListOptions } from '@/features/domain/lists'

/**
 * Filter the traffic areas, to show only the one relative to the selected traffic profile recurrence
 */
export const useSetTrafficAreasFilter = () => {
  const dispatch = useAppDispatch()
  const listOptions = useSelector(selectTrafficAreasListOptions)

  return useCallback(
    async (values: string[]) => {
      const nextOptions = produce(listOptions, draft => {
        draft.filter = [
          {
            field: 'recurrence',
            values,
            operator: 'and',
          },
        ]
      })

      // avoid triggering any calculations in case the group didn't change
      if (nextOptions === listOptions) return

      const request = await dispatch(
        setListOptions({ category: 'trafficAreas', options: nextOptions }),
      )
      return setListOptions.fulfilled.match(request)
    },
    [dispatch, listOptions],
  )
}
