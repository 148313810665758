import type { ItemWithPosition } from '../typings'
import { getItemIdOrGroupHeaderId } from './guards'

export const idSeparator = '/@@/'

export const getStringifiedItemIds = <T>(
  items: ItemWithPosition<T>[],
  getItemId: (item: T) => string,
): string =>
  placeSeparatorAtEnds(
    items.map(item => getItemIdOrGroupHeaderId(item.item, getItemId).toString()).join(idSeparator),
  )

export const placeSeparatorAtEnds = (stringifiedIds: string) =>
  `${stringifiedIds.startsWith(idSeparator) ? '' : idSeparator}${stringifiedIds}${
    stringifiedIds.endsWith(idSeparator) ? '' : idSeparator
  }`.trim()

export const getIdsFromStringifiedIds = (stringifiedIds: string): string[] =>
  stringifiedIds.split(idSeparator).filter(id => id !== '')

export const removeSeparators = (id: string): string => id.replace(new RegExp(idSeparator, 'g'), '')
