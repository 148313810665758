import { useRef } from 'react'
import { Add, Settings } from '@mui/icons-material'

import {
  ListHeaderActions,
  ListHeaderPrimaryActionButton,
  type ListHeaderSecondaryActionRef,
} from '@/components/List'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'

export function Actions() {
  const secondaryActionMenuRef = useRef<ListHeaderSecondaryActionRef>(null)
  const { editing, onCreate, onEditTimeWindows } = useActions(secondaryActionMenuRef.current)
  const texts = useTexts()

  return (
    <ListHeaderActions>
      <ReadOnlyTooltip
        render={preventEditing => (
          <Tooltip title={texts.addTrafficProfile} placement="bottom">
            <ListHeaderPrimaryActionButton
              disabled={preventEditing || editing}
              onClick={onCreate}
              trackId="trafficProfiles__create-new-button"
            >
              <Add />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
      <ReadOnlyTooltip
        render={preventEditing => (
          <Tooltip title={texts.editTimeWindows} placement="bottom">
            <ListHeaderPrimaryActionButton
              disabled={preventEditing || editing}
              onClick={onEditTimeWindows}
              testId="list-header-secondary-action-edit-time-windows"
              trackId="list-header-secondary-action-edit-time-windows"
            >
              <Settings />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
    </ListHeaderActions>
  )
}
