import styled from 'styled-components'

interface Props {
  useFullWidth?: boolean
}

export const BaseButton = styled.button.attrs<Props>(() => ({
  className: 'o-field-button__base',
  type: 'button',
}))<Props>`
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0 14px;
  border: 0;
  border-radius: 6px;
  height: 28px;
  width: ${p => (p.useFullWidth ? '100%' : '200px')};

  background: ${p => p.theme.colors.$pigmentGreen};
  color: ${p => p.theme.colors.$pureWhite};

  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
  outline: 0;
  transition: 0.3s ease;
  transition-property: background, color;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
    background: ${p => p.theme.colors.$whiteSmoke};
    color: ${p => p.theme.colors.$pureWhite};
  }
`

BaseButton.displayName = 'BaseButton'
