import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { updateCompany } from '@/features/domain/company'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export interface ModalHandlers {
  onCancel: () => void
  onDeleteOrder: () => void
}

export function useControllerActions() {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const {
    close,
    data: { company },
  } = useController()

  const onDisableCompany = useCallback(async () => {
    try {
      const result = await dispatch(updateCompany({ ...company, enabled: false }))

      if (updateCompany.rejected.match(result)) {
        throw new Error('Internal error')
      }

      toast.success(texts.toast.success)

      close?.()
    } catch (e) {
      toast.error(texts.toast.error)
    }
  }, [texts, toast, close, dispatch, company])

  return {
    onDisableCompany,
  }
}
