import { useMemo } from 'react'
import {
  format,
  isEqual,
  subDays,
  subMonths,
  endOfMonth,
  startOfMonth,
  eachDayOfInterval,
} from 'date-fns/esm'

import { useDaysWithOrders } from '@/hooks'

export function useArchivedCalendarData(today: Date, firstDayNotArchived: Date) {
  const daysWithOrders = useDaysWithOrders()

  const todayAsString = useMemo<string>(() => format(today, 'yyyyMMdd'), [today])

  const calendarData = useMemo(() => {
    const lastArchivedDay = subDays(firstDayNotArchived, 1)
    const firstArchivedDay = subMonths(lastArchivedDay, 13)

    // The minimum date present on the calendar
    const minDate = startOfMonth(firstArchivedDay)

    // The maximum date present on the calendar
    const maxDate = endOfMonth(firstDayNotArchived)

    const disabledDates: Date[] = []

    /**
     * Let's disable all dates between the minimum date on the calendar and the first archived day
     *
     * NOTE: If firstArchivedDay is equal to the start of his month (minDate) we don't have to
     *       disable anything before it
     */
    if (!isEqual(minDate, firstArchivedDay)) {
      disabledDates.push(
        ...eachDayOfInterval({
          start: minDate,
          end: subDays(firstArchivedDay, 1),
        }),
      )
    }

    // Let's disable all dates between the first NON archived day and the maximum date on the calendar
    disabledDates.push(
      ...eachDayOfInterval({
        start: firstDayNotArchived,
        end: maxDate,
      }),
    )

    const disabledDays: Record<string, number> = disabledDates.reduce((acc, d) => {
      acc[format(d, 'yyyyMMdd')] = 1
      return acc
    }, {})

    return {
      minDate: format(minDate, 'yyyyMMdd'),
      maxDate: format(maxDate, 'yyyyMMdd'),
      disabledDays,
    }
  }, [firstDayNotArchived])

  return {
    calendarData,
    todayAsString,
    daysWithOrders,
  }
}
