import type { FormFields, FormErrors } from './formFields'

import { useEffect } from 'react'
import { Box } from '@mui/material'

import { FormValues, useFormMeta } from '@workwave-tidal/tidal/form-fairy'
import { FormHeader, Form } from '@workwave-tidal/tidal/form-ui/layout'

import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'

import { FormFooter } from './components/FormFooter'

import { DistanceFormat } from './components/fields/DistanceFormat'
import { DateFormat } from './components/fields/DateFormat'
import { TimeFormat } from './components/fields/TimeFormat'
import { Language } from './components/fields/Language'
import { Currency } from './components/fields/Currency'

type Props = {
  onDiscard: () => void
  updateLanguageAndFormats: (data: FormValues<FormFields>) => Promise<boolean>
}

export function LanguageAndFormatsForm(props: Props) {
  const { onDiscard, updateLanguageAndFormats } = props

  const texts = useTexts()

  const {
    meta: { submitting },
    api,
  } = useFormMeta<FormFields, FormErrors>()

  // create a submit function linked to
  // - the domain update function
  // - the form API
  // - the application editing status
  const onSubmit = useOnSubmit(updateLanguageAndFormats, onDiscard)

  useEffect(() => {
    api.validate()
  }, [api])

  return (
    <Box width={360} height="100%" data-testid="form__column-secondary" display="flex" paddingY={2}>
      <Form
        header={<FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />}
        footer={<FormFooter onDiscard={onDiscard} onSubmit={onSubmit} submitting={submitting} />}
        testId="rm-language-format-form"
        onEnter={onSubmit}
        fieldsSpacing={4}
        fieldsPadding="0 32px"
      >
        <Language />

        <DateFormat />

        <TimeFormat />

        <DistanceFormat />

        <Currency />
      </Form>
    </Box>
  )
}
