import { useState } from 'react'

import { useConfigureModalController, useModalController, useCloseModal } from '@/atoms'

import { useControllerData } from './useControllerData'

type ModalState = 'ready' | 'submitting'
interface ModalData {
  planType: 'none' | 'operations' | 'archived' | 'simulation'
  calendarRange: { start: Date; end: Date }
  daysWithOrders: Record<string, number>
  disabledDays: Record<string, number>
  firstNonArchivedDay: string
  mode: 'replace' | 'add'
  lastSelectableDate: Date
  intervalLength: number
  selectionStart: Date
  selectionEnd: Date
  minDate: string
  maxDate: string
  dates: string[]
  today: string
}

export const modalId = 'copyOrders'

export const useController = () => useModalController<ModalData, ModalState>(modalId)

export const useConfigureController = () => {
  const close = useCloseModal()

  const {
    startFromDate: firstNonArchivedDay,
    minDateAsString: minDate,
    maxDateAsString: maxDate,
    lastSelectableDate,
    intervalLength,
    daysWithOrders,
    selectionStart,
    calendarRange,
    disabledDays,
    selectionEnd,
    planType,
    today,
  } = useControllerData()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        mode: 'add' as const,
        firstNonArchivedDay,
        lastSelectableDate,
        intervalLength,
        daysWithOrders,
        selectionStart,
        calendarRange,
        disabledDays,
        selectionEnd,
        dates: [],
        planType,
        minDate,
        maxDate,
        today,
      },
    }),
  }))

  return useConfigureModalController<ModalData, ModalState>(modalId, options)
}
