import { BackButton } from '../../../../elements/BackButton'
import { useDevicesNavigator } from '../../hooks/useDevicesNavigator'

import { Styler } from './components/Styler'

interface Props {
  reset: () => void
}

export function MapStyle(props: Props) {
  const { reset } = props
  const { currentDeviceIds } = useDevicesNavigator()

  return (
    <>
      <Styler deviceIds={currentDeviceIds} />
      <BackButton testid="navigo-device-mapStyles-back" onClick={reset} />
    </>
  )
}
