import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    segmentType: translate({ id: 'setup.routing.view.single.title' }),
    multipleValues: translate({ id: 'global.multipleValues' }),
    blocked: translate({ id: 'setup.routing.view.blocked' }),
    disabled: translate({ id: 'global.disabled' }),
    singleMalus: translate({ id: 'setup.routing.view.single.malus' }),
    additionalTime: translate({ id: 'setup.routing.view.single.additionalText' }),
    singleDisabled: translate({ id: 'setup.routing.view.single.disabled' }),
  }))

  return api
}
