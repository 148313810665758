import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

/**
 * Search places on the map
 *
 * @private
 */
export const reverseGeocodeLocationEditorPin = createAsyncThunk<
  // Return type of the payload creator
  uui.domain.client.Location,
  // First argument to the payload creator
  uui.domain.LatLng,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('ui/reverseGeocode', async (latLng, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const respone = await rpc('rpc/ui/reverseGeocodeLocationEditorPin', {
      category: 'rpc',
      type: 'rpc/ui/reverseGeocodeLocationEditorPin',
      latLng,
    })

    if (respone.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: respone.errorMessage,
        error: respone,
      })
    }

    return respone.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {reverseGeocodeLocationEditorPin}`,
      { tags: ['rpc', 'ui', 'reverseGeocodeLocationEditorPin'], info: latLng },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Reverse geocode location editor pin failed`,
      error,
    })
  }
})
