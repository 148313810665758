import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    addDriver: translate({
      id: 'setup.drivers.list.createNew',
    }),
    disabledForLoadingTooltip: translate({
      id: 'global.gpsLoading.disableEdit',
    }),
    disabledForEditingTooltip: translate({
      id: 'form.drivers.header.tooltip.disabledForEditing',
    }),
    noDrivers: translate({
      id: 'form.drivers.noDrivers',
    }),
    addDriverBtn: translate({ id: 'form.drivers.noItems.addNew' }),
    selectBtn: (allSelected: boolean): string =>
      translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' }),

    noEntries: {
      title: translate({ id: 'drivers.list.noEntries.title' }),
      description: translate({ id: 'drivers.list.noEntries.description' }),
      action: translate({ id: 'drivers.list.noEntries.action' }),
    },
    noResults: {
      title: translate({ id: 'drivers.list.noResults.title' }),
      description: translate({ id: 'drivers.list.noResults.description' }),
    },
  }))

  return api
}
