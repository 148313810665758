import type { AxiosResponse } from 'axios'

import axios from 'axios'
import { httpDefaultResponse } from './httpDefaultResponse'

type Request = {
  debug: boolean
  client: string
  authtoken: string
  serverUrl: string
  priority: 'silent' | 'normal' | 'high'
}

export async function setClientVersion({ serverUrl, authtoken, ...params }: Request) {
  const response: AxiosResponse<any> = await axios.post(`${serverUrl}/version`, params, {
    headers: { 'X-Reload-Management-Key': authtoken },
  })

  return httpDefaultResponse(response)
}
