import type { RadioGroupValue } from '@/forms-legacy'
import type { GeofenceFormValues } from '../../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-final-form'

import { HorizontalRadioGroupField, NumericStepperField } from '@/forms-legacy'
// import { convertMilesToKm } from '@/local/server-data/utils/smartUtils/conversion'
import { selectUserConfiguration } from '@/features/domain/user'

import { theme } from '@/styles'
import { useTexts } from './useTexts'

export function MaxSpeed() {
  const { distanceFormat } = useSelector(selectUserConfiguration)
  const texts = useTexts()
  const form = useForm()

  const wrappedInputProps = useMemo(() => {
    const colors = {
      background: theme.colors.$pureWhite,
      backgroundSelected: theme.colors.$nightRider,
      color: theme.colors.$nightRider,
      colorSelected: theme.colors.$pureWhite,
      border: theme.colors.$nightRider,
      borderSize: 1,
    }

    const maxSpeedEnabled: RadioGroupValue = {
      label: texts.maxSpeedEnabled,
      value: 'enabled',
    }

    const maxSpeedDisabled: RadioGroupValue = {
      label: texts.maxSpeedDisabled,
      value: 'disabled',
    }

    return { colors, values: [maxSpeedEnabled, maxSpeedDisabled] }
  }, [texts])

  const { maxSpeedEnabled } = form.getState().values as GeofenceFormValues
  const maxSpeedFieldVisible = maxSpeedEnabled === 'enabled'
  const isMetric = distanceFormat === 'METRIC'

  const maxSpeedLabel = `${texts.maxSpeed} (${isMetric ? 'km/h' : 'mph'})`

  return (
    <>
      <HorizontalRadioGroupField
        name="maxSpeedEnabled"
        label={texts.maxSpeedTitle}
        info={texts.maxSpeedDescription}
        wrappedInputProps={wrappedInputProps}
      />
      {!!maxSpeedFieldVisible && (
        <NumericStepperField
          half
          name="maxSpeed"
          label={maxSpeedLabel}
          wrappedInputProps={{
            step: 1,
            // min: isMetric ? parseInt(convertMilesToKm(minSpeed).toFixed(0)) : minSpeed,
            // max: isMetric ? parseInt(convertMilesToKm(maxSpeed).toFixed(0)) : maxSpeed,
          }}
        />
      )}
    </>
  )
}
