import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function TruckIdle(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 61 37">
        <path d="M13.84375 0C12.273438 0 11 1.277344 11 2.84375V27H7v2h4c0 1.566406 1.429688 3 3 3h4.09375c.476563 2.835938 2.9375 5 5.90625 5s5.429688-2.164062 5.90625-5h9.25C40.722656 32 42 30.722656 42 29.15625v-26.25C42 1.304688 40.73828 0 39.1875 0H13.84375zM44 7v25c.48047 2.83203 3.03125 5 6 5s5.429688-2.16797 5.90625-5H58c1.570313 0 3-1.429687 3-3v-9.625c0-2.01172-1.44922-4.066406-1.625-4.3125L55.21875 9.5C54.265625 8.351563 52.76953 7 51 7h-7zm5 5h5.59375l3.1875 4.25C58.121094 16.73047 59 18.203125 59 19.375V20H49c-1 0-2-1-2-2v-4c0-1.10547 1-2 2-2zM24 27c2.20703 0 4 1.79297 4 4s-1.79297 4-4 4-4-1.79297-4-4 1.79297-4 4-4zm26 0c2.20703 0 4 1.79297 4 4s-1.79297 4-4 4-4-1.79297-4-4 1.79297-4 4-4zM1.90414 15.956486c-.042958.00781-.085914.01952-.124966.031234-.374893.06637-.675588.339664-.781027.702753-.10544.366994.003905.757412.28117 1.015088.73026.722277.76931 1.03461.718545 1.34304-.050767.30843-.347557.77303-.781027 1.34304S.19369 21.63317.029673 22.609217c-.164015.976046.246024 2.096546 1.249644 3.092113.398324.398226 1.038767.398226 1.43709 0 .398324-.398228.398324-1.038514 0-1.43674-.726355-.722275-.769312-1.03461-.718545-1.34304.050766-.30843.378798-.773028.812268-1.34304.43347-.57001.991905-1.24153 1.15592-2.217576.164017-.976046-.246023-2.096547-1.249643-3.092114-.206972-.222538-.507667-.33576-.812268-.312335zm3.998858 0c-.042956.00781-.085912.01952-.124964.031234-.374893.06637-.675588.339664-.781027.702753-.10544.366994.003905.757412.28117 1.015088.73026.722277.76931 1.03461.718545 1.34304-.050767.30843-.347557.77303-.781028 1.34304-.43347.57001-1.023145 1.24153-1.18716 2.217578-.164016.976046.246023 2.096546 1.249642 3.092113.398325.398228 1.038767.398228 1.43709 0 .398325-.398226.398325-1.038512 0-1.43674-.726354-.722273-.76931-1.03461-.718544-1.34304.050766-.30843.378798-.773026.812268-1.34304.43347-.57001.991905-1.24153 1.15592-2.217574.164017-.976046-.246023-2.096547-1.249643-3.092114-.206972-.222536-.507668-.335757-.81227-.312333z" />
      </svg>
    </IconContainer>
  )
}
