import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    notificationLimitationTitle: translate({
      id: 'sms4.settings.communication.notifications.canceledSettings.notificationLimitation.title',
    }),

    notificationLimitationDescription: translate({
      id: 'sms4.settings.communication.notifications.canceledSettings.notificationLimitation.description',
    }),
  }))

  return api
}
