import { useCallback, MouseEvent } from 'react'

import { Button, ButtonToolbar, ButtonToolbarItem } from '@/local/components'
import { useTexts } from '../useTexts'

type EligibilityType = uui.domain.client.rm.Eligibility['type']

type Props = {
  type: EligibilityType
  onChangeType: (type: EligibilityType) => void
}

export function EligibilityTypePicker(props: Props) {
  const { type, onChangeType } = props

  const texts = useTexts()
  const handleOnChange = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const eligibilityType = e.currentTarget.dataset.eligibilityType as EligibilityType
      onChangeType(eligibilityType)
    },
    [onChangeType],
  )

  return (
    <ButtonToolbar justifyContent="start">
      <ButtonToolbarItem gutter={10}>
        <Button
          type="button"
          data-eligibility-type="any"
          preset={type === 'any' ? 'confirm' : 'cancel'}
          onClick={handleOnChange}
        >
          {texts.anyTranslation}
        </Button>
      </ButtonToolbarItem>
      <ButtonToolbarItem gutter={10}>
        <Button
          type="button"
          data-eligibility-type="on"
          preset={type === 'on' ? 'confirm' : 'cancel'}
          onClick={handleOnChange}
        >
          {texts.onDayTranslation}
        </Button>
      </ButtonToolbarItem>
      <ButtonToolbarItem gutter={10}>
        <Button
          type="button"
          data-eligibility-type="by"
          preset={type === 'by' ? 'confirm' : 'cancel'}
          onClick={handleOnChange}
        >
          {texts.byDayTranslation}
        </Button>
      </ButtonToolbarItem>
    </ButtonToolbar>
  )
}
