import { Children } from 'react'

import { FlexBox } from '../../atoms'

import { Props } from './typings'

const getPadYFromSpace = (space: Props['space']): number => {
  switch (space) {
    case 's':
      return 8
    case 'l':
      return 32
    case 'm':
    default:
      return 16
  }
}

/***
 * Stack render every top rendered children as Row
 */
export const Stack = (props: Props) => {
  const {
    children,
    space,
    as = 'div',
    applySpaceToContainer,
    innerFlexBoxProps = {},
    testid,
  } = props

  const stackItems = Children.toArray(children)
  const lastIndex = stackItems.length - 1
  const isList = as === 'ol' || as === 'ul'

  if (stackItems.length <= 1 && !isList) {
    return <>{stackItems}</>
  }

  return (
    <FlexBox
      as={as}
      w="100%"
      paddingTop={applySpaceToContainer ? getPadYFromSpace(space) : 0}
      column
      style={{ alignItems: 'stretch' }}
      data-testid={testid}
    >
      {stackItems.map((child, index) => {
        const isLast = index === lastIndex
        const y = isLast ? 0 : getPadYFromSpace(space)
        return (
          <FlexBox
            column
            key={index}
            paddingBottom={y}
            {...innerFlexBoxProps}
            style={{ alignItems: 'stretch' }}
          >
            {child}
          </FlexBox>
        )
      })}
    </FlexBox>
  )
}
