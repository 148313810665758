import { type SchedulerProConfig } from '@bryntum/schedulerpro'

export const headerMenu: SchedulerProConfig['features']['headerMenu'] = {
  items: {
    groupRemove: false,
    mergeCells: false,
    editFilter: false,
    groupDesc: false,
    multiSort: false,
    groupAsc: false,
    movePrev: false,
    moveNext: false,
    groupBy: false,
    filter: false,
  },
}
