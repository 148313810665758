import { useSelector } from 'react-redux'
import { selectTerritories, selectVoiceConfig } from '@/features/domain/territory'

/**
 * Advanced Voice configuration cannot be copied to any territory. This functions checks if the
 * current Voice config status and the destination territory are compatible or not.
 */
export function useCanCopyToTerritory(destinationTerritoryId: string | undefined) {
  const currentVoiceStatus = useSelector(selectVoiceConfig).status
  const territories = useSelector(selectTerritories)

  if (!destinationTerritoryId) return false

  const destinationTerritory = territories[destinationTerritoryId]

  // Basic/off config is compatible with all territories
  if (currentVoiceStatus !== 'ADVANCED') {
    return true
  }

  // Temporarily, only the US, CA, and UK support advanced Voice configuration.
  const countrySupportAdvancedVoice =
    destinationTerritory.country === 'US' ||
    destinationTerritory.country === 'CA' ||
    destinationTerritory.country === 'GB'

  const notificationsSupportAdvancedVoice =
    destinationTerritory.notificationBehavior === 'EMAIL_AND_PHONE'

  return countrySupportAdvancedVoice && notificationsSupportAdvancedVoice
}
