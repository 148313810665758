import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { timeUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export const useFormatTime = (source: Date, showSeconds: boolean = false) => {
  const userConfig = useSelector(selectUserConfiguration)

  return useMemo(
    () => timeUtils.formatTime(userConfig)(source, showSeconds),
    [userConfig, source, showSeconds],
  )
}
