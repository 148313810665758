import { useCallback } from 'react'
import { validatePhoneNumbers } from '@/features/domain/order/actions/validatePhoneNumbers'
import { useAppDispatch } from '@/store'
import { useTexts } from './useTexts'

const phoneRegex: RegExp = /^\+\d{10,}$/

export function usePhoneValidation() {
  const texts = useTexts()
  const dispatch = useAppDispatch()

  return useCallback(
    async formApi => {
      const status = formApi.getField('status').value
      const phoneNumber = formApi.getField('backOfficePhone').value.trim()
      const callbackConfigStatus = formApi.getField('callbackConfigStatus').value.trim()

      if (status !== 'ADVANCED' || callbackConfigStatus === 'OFF')
        return { phoneError: null, phoneNumberValidError: null }

      // Formal validation
      const formallyValid = phoneRegex.test(phoneNumber)
      if (!formallyValid) {
        return {
          phoneError: {
            id: 'phoneError',
            field: 'backOfficePhone' as const,
            message: texts.backOfficePhone.error.format,
            priority: 10,
          },
          phoneNumberValidError: null,
        }
      }

      // Server validation

      const thunkResult = await dispatch(validatePhoneNumbers([phoneNumber]))

      if (!validatePhoneNumbers.fulfilled.match(thunkResult)) {
        throw new Error(thunkResult.payload?.message ?? 'Internal error')
      }

      if (!thunkResult.payload[phoneNumber]) {
        return {
          phoneNumberValidError: {
            id: 'phoneNumberValidError',
            message: texts.backOfficePhone.error.format,
            field: 'backOfficePhone' as const,
            priority: 10,
          },
          phoneError: null,
        }
      }

      return { phoneError: null, phoneNumberValidError: null }
    },
    [dispatch, texts],
  )
}
