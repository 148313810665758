import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type Payload = {
  srcRoute: uui.domain.client.rm.RouteIdentifier
  dstRoute: uui.domain.client.rm.RouteIdentifier
}

/**
 * Move an order step.
 *
 * @private
 */
export const swapOrderSteps = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/order/swapOrderSteps', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/order/swapOrderSteps', {
      category: 'rpc',
      type: 'rpc/order/swapOrderSteps',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {moveOrderStep}`,
      { tags: ['rpc', 'order'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Swap orderSteps for routes {vehicle: ${payload.srcRoute.vehicleId}, date: ${payload.srcRoute.date} and {vehicle: {${payload.dstRoute.vehicleId} and date: ${payload.dstRoute.date}} failed`,
      error,
    })
  }
})
