import type { EditingStateContext } from '@/atoms'
import { subscribeToEditingState } from '@/atoms'

import { setEditingInteractionMode } from './setEditingInteractionMode'
import { applyMapEditingState } from './applyMapEditingState'
import { resetMapEditingState } from './resetMapEditingState'

export function connectMapToEditingState() {
  let prevEditingContext: EditingStateContext = 'none'

  return subscribeToEditingState(editingState => {
    const { ctx, editingEntities } = editingState

    if (prevEditingContext !== 'none') {
      resetMapEditingState(prevEditingContext)
    }

    prevEditingContext = ctx
    if (ctx === 'none') {
      // restore map interactions
      setEditingInteractionMode('enabled')

      return
    }

    if (editingEntities.length === 0) return

    applyMapEditingState(ctx, editingEntities)

    // disable map interactions
    setEditingInteractionMode('disabled')
  })
}
