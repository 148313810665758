import type { YYYYMMDD, SelectionAsRecord, IntervalSelectionData } from '../../typings'

import { useMemo } from 'react'
import { eachDayOfInterval, subDays, addDays, format, isAfter } from 'date-fns/esm'

import { parseDate } from '../../utils/parseDate'
import { defaultMinDate } from '../../constants'

const emptySelectionAsRecord: SelectionAsRecord = {}

export const useSelectionData = (
  selectionStartInput?: Date,
  selectionEndInput?: Date,
  minDateAsString?: YYYYMMDD,
  maxIntervalLength: number = 0,
  lastSelectableDate?: Date,
) => {
  return useMemo<IntervalSelectionData>(() => {
    if (!selectionStartInput) {
      return {
        selection: emptySelectionAsRecord,
        selectableInterval: undefined,
        edgeOfSelection: emptySelectionAsRecord,
      }
    }

    const isBackwardSelection = selectionEndInput && isAfter(selectionStartInput, selectionEndInput)

    const selectionStart = isBackwardSelection ? selectionEndInput : selectionStartInput

    const selectionEnd = isBackwardSelection
      ? selectionStartInput
      : selectionEndInput || selectionStartInput

    const minDate: Date = minDateAsString ? parseDate(minDateAsString) : defaultMinDate

    const selectedDays = isBackwardSelection
      ? [selectionEnd]
      : eachDayOfInterval({
          start: selectionStart,
          end: selectionEnd,
        })

    const selectableDayStart =
      // If is selecting an archived days range or there's a full selection, it is possible to select
      // all available days (minDate)
      selectionEndInput ? minDate : subDays(selectionStart, maxIntervalLength)

    const selectableDayEnd = lastSelectableDate ?? addDays(selectionStart, maxIntervalLength)

    const selectableDays = eachDayOfInterval({
      start: selectableDayStart,
      end: selectableDayEnd,
    })

    const selection = selectedDays.reduce<SelectionAsRecord>((acc, date) => {
      const dateAsString = format(date, 'yyyyMMdd')
      acc[dateAsString] = 1
      return acc
    }, {})

    const selectableInterval = selectableDays.reduce<SelectionAsRecord>((acc, date) => {
      const dateAsString = format(date, 'yyyyMMdd')
      acc[dateAsString] = 1
      return acc
    }, {})

    const edgeOfSelection: SelectionAsRecord = {
      [format(selectionStart, 'yyyyMMdd')]: 1,
      [format(selectionEnd, 'yyyyMMdd')]: 1,
    }

    return {
      selection,
      selectableInterval,
      edgeOfSelection,
    }
  }, [
    selectionStartInput,
    selectionEndInput,
    maxIntervalLength,
    lastSelectableDate,
    minDateAsString,
  ])
}
