import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'

import { Edit, Trash } from '@/icons'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { useTexts } from './hooks/useTexts'

export interface Props {
  onDelete: () => void
  onEdit: () => void
}

export function Actions(props: Props) {
  const { onDelete, onEdit } = props
  const texts = useTexts()

  return (
    <SecondaryColumnHeaderActionsRow centered>
      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={onDelete}
            disabled={preventEditing}
            testid="setup-alert__form-delete"
          />
        )}
      />
      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.edit}
            icon={<Edit />}
            onClick={onEdit}
            disabled={preventEditing}
            testid="setup-alert__form-edit"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
