import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { generateRelativePath } from '@/routing'

import { useTexts } from '../../../useTexts'
/**
 * This hook retrieves the current location and try return the correct label for that.
 * If not able to get the correct label it will return a placeholder
 */

type Path = uui.routing.MainSections | uui.routing.Routing

export function useAppLocationLabel() {
  const { pathname } = useLocation()
  const texts = useTexts()
  const labels: Partial<Record<Path, string>> = useMemo(
    () => ({
      home: texts.home,
      orders: texts.orders,
      tracking: texts.tracking,
      simulations: texts.simulations,
      reports: texts.reports,
    }),
    [texts],
  )

  const matchingPath = generateRelativePath(pathname)
  return matchingPath && labels[matchingPath] ? labels[matchingPath] : texts.goTo
}
