import { useCallback } from 'react'
import { useCrudSelection, useEditingStateWithCtx, useIsLoading } from '@/atoms'

export function useActions() {
  const { editing, setEditing } = useEditingStateWithCtx('driversAssignment')
  const [selection, setSelection] = useCrudSelection('driverAssignments')
  const isLoading = useIsLoading('fetchGpsData')

  const onCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  return { editing, onCreate, isLoading }
}
