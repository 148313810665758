import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { intializePendo } from './intializePendo'

type Params = {
  territoryId?: string
  simulationId?: string
  txnIdFromCookie?: string
  customCalendarRange?: uui.domain.DataRange
}

type RejectError =
  | {
      type: 'unknown'
      message: string
      error: Error
    }
  | {
      type: 'rpcFailure'
      message: string
      error: uui.domain.api.RpcFailure
    }

export const loadInitialData = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Params,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('lifecycle/loadInitialData', async (data: Params, thunkApi) => {
  try {
    const { territoryId, simulationId, txnIdFromCookie, customCalendarRange } = data

    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/loadInitialData', {
      category: 'rpc',
      type: 'rpc/loadInitialData',
      territoryId,
      simulationId,
      txnIdFromCookie,
      customCalendarRange,
    })

    if (result.type === 'rpc-failure') {
      // TODO: add a proper localized message
      // JOURNAL: trigger the journal
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    // Don't await for it
    thunkApi.dispatch(intializePendo())
  } catch (error) {
    // TODO: add a proper localized message
    // JOURNAL: trigger the journal
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Load initial data Failed',
      error,
    })
  }
})
