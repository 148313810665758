import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    route: translate({ id: 'global.route' }),
    routeEditEnabled: translate({ id: 'rm.sidebar.routeDetails.edit.enabled.tooltip' }),
    unassignedDriver: translate({ id: 'rm.sidebar.routeDetails.unassignedDriver' }),
    routeEditDisabled: (editPermission: 'isRmOnly' | 'gotNoTrackedEvents') => {
      switch (editPermission) {
        case 'gotNoTrackedEvents':
          return translate({
            id: 'rm.sidebar.routeDetails.edit.disabled.tooltip.gotNoTrackedEvents',
          })

        case 'isRmOnly':
          return translate({ id: 'rm.sidebar.routeDetails.edit.disabled.tooltip.isRmOnly' })
      }
    },
  }))

  return api
}
