import { PureComponent } from 'react'

import {
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'
import * as OrderTexts from '../../../intl'

import { Props, FieldMode } from '../typings'

import * as Texts from '../intl'
import ViolationIcon from './ViolationIcon'

export default class Eligibility extends PureComponent<Props> {
  render() {
    const { eligibility, violations } = this.props

    const text: string = eligibility === FieldMode.mixed ? OrderTexts.getMixedText() : eligibility

    const eligibilityViolation: boolean = violations.includes('ELG')

    return (
      <>
        <ReadonlyBlock>
          <ReadonlyLabel primary>
            {Texts.getEligibilityText()}
            {eligibilityViolation && <ViolationIcon />}
          </ReadonlyLabel>

          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{text}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
