import { useState, useCallback } from 'react'

export function useInputValue(onChange: (value: string) => void) {
  /**
   * the "value" state with the value/onChange props combination. This state represents the value selected by the user, for instance when pressing Enter.
   * the "input value" state with the inputValue/onInputChange props combination. This state represents the value displayed in the textbox.
   * ⚠️ These two states are isolated, they should be controlled independently.
   */
  const [inputValue, setInputValue] = useState('')

  const onInputChange = useCallback(
    (_, val: string) => {
      onChange(val)
      setInputValue(val)
    },
    [onChange],
  )

  return {
    inputValue,
    setInputValue,
    onInputChange,
  }
}
