import { useCallback } from 'react'

import { useNavigate } from '@/routing'
import { setInspectPin, setPendingFitMap } from '@/map'

import { setCrudSelection, useEditingStateWithCtx } from '@/atoms'

export function useActions(currentPlaces: uui.domain.client.gps.wwgps.Place[]) {
  const { setEditing: setGeofenceEditing } = useEditingStateWithCtx('geofence')
  const { setEditing: setOrderEditing } = useEditingStateWithCtx('order')
  const { setEditing: setPlaceEditing } = useEditingStateWithCtx('place')
  const navigate = useNavigate(true)

  const createOrder = useCallback(async () => {
    if (currentPlaces.length !== 1)
      throw new Error('To create an order there must be just one selected place')

    const place = currentPlaces[0]

    // Set the inspect pin for order creation
    setInspectPin({ mode: 'converted', target: 'order', latLng: place.latLng })

    // Set pending fit map to center the map on the inspect pin
    setPendingFitMap([place.latLng])

    // Set the order form to editing mode
    setOrderEditing([])

    // Navigate to the correct route
    navigate('orders')
  }, [navigate, setOrderEditing, currentPlaces])

  const createGeofence = useCallback(async () => {
    if (currentPlaces.length !== 1)
      throw new Error('To create a geofence there must be just one selected place')

    const place = currentPlaces[0]

    // Set the inspect pin for geofence creation
    setInspectPin({ mode: 'converted', target: 'geofence', latLng: place.latLng })

    // Set pending fit map to center the map on the inspect pin
    setPendingFitMap([place.latLng])

    // Set the geofence form to editing mode
    setGeofenceEditing([])

    // Navigate to the correct route
    navigate('geofences')
  }, [navigate, setGeofenceEditing, currentPlaces])

  const editPlace = useCallback(async () => {
    if (currentPlaces.length !== 1)
      throw new Error('To edit a place there must be just one selected place')

    const place = currentPlaces[0]

    // Set crud selection
    setCrudSelection('places', [place.id])

    // Set the geofence form to editing mode
    setPlaceEditing([place.id])

    // Navigate to the correct route
    navigate('places')
  }, [navigate, setPlaceEditing, currentPlaces])

  return { createOrder, createGeofence, editPlace }
}
