import { useCallback } from 'react'

import { setMainSelection } from '@/atoms'
import { colorsUtils } from '@/utils'
import { isDark } from '@/styles'
import { Truck } from '@/icons'

import { TagButton } from './components/TagButton'

interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
}

export function DeviceTag(props: Props) {
  const { device } = props

  const onClick = useCallback(() => {
    setMainSelection('devices', [device.deviceId])
  }, [device])

  const backgroundColor = colorsUtils.getValidHexColor(device.iconColor1)

  return (
    <TagButton
      data-trackid="navigo-breadcrumbs-overview-details-single-vehicle"
      data-testid="navigo-breadcrumbs-overview-details-single-vehicle"
      color={backgroundColor}
      onClick={onClick}
    >
      <Truck size={14} color={isDark(backgroundColor) ? '$pureWhite' : '$nightRider'} />
    </TagButton>
  )
}
