import type { FormConfig } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../../../formFields'
import type { ValidationTexts } from '../useTexts'

import { useValidateEmail } from '@/hooks'

import { createValidateUsername } from './createValidateUsername'
import { createValidateSurname } from './createValidateSurname'
import { createValidateEmail } from './createValidateEmail'
import { createValidateName } from './createValidateName'

export function createValidations(
  validateEmail: ReturnType<typeof useValidateEmail>,
  texts: ValidationTexts,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'name',
      fields: 'name',
      validator: createValidateName(texts),
    },
    {
      id: 'surname',
      fields: 'surname',
      validator: createValidateSurname(texts),
    },
    {
      id: 'email',
      fields: 'email',
      validator: createValidateEmail(validateEmail, texts),
    },
    {
      id: 'username',
      fields: 'username',
      validator: createValidateUsername(texts),
    },
  ]
}
