import type { ReactElement } from 'react'

import { useCallback, useState } from 'react'
import { ImportWizardCookieData, importWizardSession } from '@/utils'

import { tryCloseTransaction } from '@/features/domain/transaction'
import { useAppDispatch } from '@/store'

import { useEnvironmentReady } from '../../atoms/environment'

import { useImportWizardResultModal } from './modals/ImportWizardResultModal'
import { Initialize } from './Initialize'

type Props = {
  children: ReactElement
}

export function Bootstrap(props: Props) {
  const { children } = props

  const [importWizardState, setImportWizardState] = useState<ImportWizardCookieData>()
  const [done, setDone] = useState(false)

  const envReady = useEnvironmentReady()
  const dispatch = useAppDispatch()

  const { show: showImportWizardResultModal, Modal: ImportWizardResultModal } =
    useImportWizardResultModal()

  const handleOnComplete = useCallback(() => {
    setDone(true)
  }, [])

  const handleOnLoadInitialDataComplete = useCallback(async () => {
    const importWizardState = importWizardSession.getState()

    if (importWizardState) {
      switch (importWizardState?.step) {
        case 'importCanceled':
          importWizardSession.deleteCookie()

          // If the import has been canceled we try close the active transaction.
          // The saga won't close the transaction if we're on simulations or got a non-empty transaction
          dispatch(tryCloseTransaction())
          break

        case 'importCompleted':
          setImportWizardState(importWizardState)
          showImportWizardResultModal()
      }
    }
  }, [dispatch, showImportWizardResultModal])

  if (!envReady) {
    return null
  }

  return done ? (
    <>
      {children}
      {importWizardState?.step === 'importCompleted' && (
        <ImportWizardResultModal
          startRevision={importWizardState.startRevision}
          numberOfImportedOrders={importWizardState.importedOrders}
          numberOfImportedOrdersWithLowAccuracy={importWizardState.lowAccuracyOrders}
        />
      )}
    </>
  ) : (
    <Initialize
      onComplete={handleOnComplete}
      onLoadInitialDataComplete={handleOnLoadInitialDataComplete}
    />
  )
}
