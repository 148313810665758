import type { Props } from './typings'

import { useState } from 'react'

import { ClosedList } from './ClosedList'
import { OpenList } from './OpenList'

export function DeviceIconList(props: Props) {
  const { icons, value, closedText, openText, description, onChange } = props

  const [mode, setMode] = useState<'closed' | 'open'>('closed')

  const defaultIconKey = Object.keys(icons)[0]

  const item = icons[value] || icons[defaultIconKey]
  if (!item) {
    throw new Error('Cannot find value in icons')
  }

  switch (mode) {
    case 'closed':
      return (
        <ClosedList activePreset={item.preset} setMode={setMode} closedText={closedText ?? ''} />
      )

    case 'open':
      return (
        <OpenList
          activePreset={item.preset}
          setMode={setMode}
          openText={openText}
          description={description ?? ''}
          onChange={onChange}
          icons={icons}
          value={value}
        />
      )
  }
}
