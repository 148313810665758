import { getMap } from '../../atoms/map/utils/getMap'
import { findLayer } from '../utils/findLayer'

import { changelogRemoveRoutePolylineFeatures } from './utils/changelogRemoveRoutePolylineFeatures'
import { changelogAddRoutePolylineFeatures } from './utils/changelogAddRoutePolylineFeatures'
import { changelogUpdateRoutePolylineFeatures } from './utils/changelogUpdateRoutePolylineFeatures'

export function createUpdateRoutePolylineFeaturesFromChangelog(
  markers: uui.domain.ui.map.markers.MapMarkers['routePolyline'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['routePolyline'],
  changelog: uui.domain.api.Changelog,
) {
  // ATTENTION: Currently all changelog are applied as soon as they arrive, also when the map is not visible
  return async (_ignoreSelection: boolean = false) => {
    const map = getMap()
    const layer = findLayer(map, 'routePolyline')

    // remove
    await changelogRemoveRoutePolylineFeatures(layer, changelog.remove.values())

    // add
    await changelogAddRoutePolylineFeatures(layer, markers, mapStyles, changelog.add.values())

    // update
    await changelogUpdateRoutePolylineFeatures(layer, markers, mapStyles, changelog.update.values())
  }
}
