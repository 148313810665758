import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type Payload = uui.domain.client.rm.RouteIdentifier[]

/**
 * Move an order step.
 *
 * @private
 */
export const invertOrderSteps = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/order/invertOrderSteps', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/order/invertOrderSteps', {
      category: 'rpc',
      type: 'rpc/order/invertOrderSteps',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {invertOrderStep}`,
      { tags: ['rpc', 'order'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Invert orderSteps for routes ${payload
        .map(r => `{vehicleId: ${r.vehicleId}, date: ${r.date}}`)
        .join(', ')} failed`,
      error,
    })
  }
})
