import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

type Params = uui.domain.actions.rpc.order.exportArchivedToOperationsAction['params']

/**
 * Export Archived day into Operations of the Active Territory.
 *
 * @private
 */
export const exportArchivedToOperations = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Params,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/order/exportArchivedToOperations', async (params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/order/exportArchivedToOperations', {
      category: 'rpc',
      type: 'rpc/order/exportArchivedToOperations',
      params,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      'Unknown error on {exportArchivedToOperations}',
      { tags: ['rpc', 'archived'] },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Exporting archived failed',
      error,
    })
  }
})
