import { useEffect, useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'
import { useReadOnly } from '@/hooks'

import { useSelectedOrderSteps } from './useSelectedOrderSteps'
import { useAutoSubmit } from './useAutoSubmit'

type ModalState = 'ready' | 'pending'
interface ModalData {
  unassignAlsoPaired: boolean
  pairedOrderSteps: uui.domain.client.rm.ExtendedOrderStep[]
  selectedOrderSteps: uui.domain.client.rm.ExtendedOrderStep[]
}

export const modalId = 'unassignOrder'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = (orderStepIds: string[]) => {
  const { selectedOrderSteps, pairedOrderSteps } = useSelectedOrderSteps(orderStepIds)
  const { readonly } = useReadOnly()
  const close = useCloseModal()

  // INITIALIZATION
  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        pairedOrderSteps,
        selectedOrderSteps,
        unassignAlsoPaired: true,
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const { update, status } = ctrl

  // EFFECTS

  useEffect(() => {
    switch (readonly) {
      case true:
        update({ status: 'pending' })
        break

      case false:
        if (status !== 'pending') return

        update({ status: 'ready' })
        break
    }
  }, [readonly, status, update])

  useAutoSubmit()

  return ctrl
}
