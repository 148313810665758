import { useSearchParams } from 'react-router-dom'
import { useJournal } from '@/store'

const validStyles = [
  'depot',
  'order',
  'vehicle',
  'deviceEvent',
  'place',
  'polyline',
  'breadcrumb',
  'route',
  'region',
  'traffic',
  'routingSegment',
]

const noopStyle = {}

export type QueryParameters = ReturnType<typeof useQueryParameters>
export const useQueryParameters = () => {
  const [searchParams] = useSearchParams()
  const journal = useJournal()

  const resetPassword = searchParams.get('token')
  const loginnonce = searchParams.get('loginnonce')
  const gpsnonce = searchParams.get('gpsnonce')
  const authtoken = searchParams.get('authtoken')
  const loginasnonce = searchParams.get('loginasnonce')
  const territoryId = searchParams.get('territoryId')
  const simulationId = searchParams.get('simulationId')
  const vehicleId = searchParams.get('vehicleId')

  const styles = searchParams.get('styles')
  const noorders = !!searchParams.get('noorders')

  // const stylesOverride: Partial<GlobalStyles> = {}
  const stylesOverride: Record<string, any> = {}

  if (styles && styles.length > 0) {
    try {
      styles
        .split(',')
        .map(pair => pair.split(':'))
        .map(([key, valueAsString]) => {
          if (validStyles.includes(key)) {
            const value = parseInt(valueAsString)

            if (!isNaN(value) && value > -1 && value < 3) {
              stylesOverride[key] = { mode: value }
            }
          }
        })
    } catch (e) {
      journal.main(
        `Style overrides was not well-formatted. Try: '?style=order:1,depot:1'`,
        {
          tags: ['bootstrap'],
          info: e,
        },
        'warn',
      )
    }
  }

  // clear query parameters
  // setSearchParams({})

  return {
    resetPassword,
    loginnonce,
    gpsnonce,
    authtoken,
    loginasnonce,
    stylesOverride: Object.keys(stylesOverride).length ? stylesOverride : noopStyle,
    noorders,
    territoryId,
    simulationId,
    vehicleId,
  } as const
}
