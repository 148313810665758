import type { CSSProperties } from 'react'

import { useMemo } from 'react'
import { makeStyles } from '@mui/styles'

const useClasses = makeStyles({
  title: {
    opacity: 'var(--title-opacity)',
  },
})

export function useStyles(showTitle: boolean) {
  const classes = useClasses()

  const styles = useMemo(() => {
    return {
      title: {
        '--title-opacity': showTitle ? 1 : 0,
      } as CSSProperties,
    } as const
  }, [showTitle])

  return [classes, styles] as const
}
