import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { renderToStaticMarkup } from 'react-dom/server'
import { isWithinInterval } from 'date-fns/esm'
import { toast } from 'react-toastify'

import { setVehiclesLock as setVehiclesLockAction } from '@/features/domain/vehicle'
import { applicationModeAtom, getApplicationMode, loadingStateAtom } from '@/atoms'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'
import { computeReadonlyStatus } from '@/hooks'
import { selectRoutesInfo } from '@/features/domain/scheduler'
import { store } from '@/store'

import { LockedCell, LockedCellTooltip, LockedHeader } from '../../../components/cells/LockedCell'

import { getRouteLoadingState, setRouteLoadingState } from '../../routeLoadingState'

export function createLockedColumn(): Partial<TemplateColumnConfig> {
  return {
    text: 'Locked',
    type: 'template',
    align: 'center',
    width: 33,
    field: 'routeLock',
    editor: false,
    region: 'final',
    hideable: false,
    minWidth: 33,
    maxWidth: 33,
    sortable: false,
    template: ({ record, field, value }) => {
      const state = store.getState()
      const { planType } = selectUserConfiguration(state)

      const disabled = planType === 'archived'

      return renderToStaticMarkup(
        <LockedCell
          disabled={disabled}
          record={record as ResourceModel}
          field={field}
          value={value}
        />,
      )
    },

    headerRenderer: context => {
      const state = store.getState()

      const loading = getRouteLoadingState(true).lockStatus.ALL
      const allLocked = getAllRoutesLocked()

      const { planType } = selectUserConfiguration(state)

      const validPlanType = planType === 'operations' || planType === 'simulation'
      const readonly = getApplicationMode() === 'readonly'

      const disabled = !validPlanType || loading || readonly

      const btn = document.createElement('button')
      btn.style.padding = '0'
      btn.style.margin = '0'
      btn.style.borderWidth = '0'
      btn.style.background = 'transparent'

      if (disabled) {
        btn.style.pointerEvents = 'none'
        btn.style.cursor = 'default'

        if (!loading) {
          btn.style.opacity = '0.25'
        }
      }

      btn.innerHTML = renderToStaticMarkup(<LockedHeader loading={loading} allLocked={allLocked} />)
      btn.onclick = handleClick
      btn.disabled = disabled

      // eslint-disable-next-line no-param-reassign
      context.headerElement.innerHTML = ''
      context.headerElement.appendChild(btn)

      return
    },
    autoWidth: false,
    draggable: false,
    resizable: false,
    groupable: false,

    tooltipRenderer: () => {
      const state = store.getState()
      const { planType } = selectUserConfiguration(state)

      if (planType !== 'archived') return ''

      return renderToStaticMarkup(<LockedCellTooltip />)
    },
  }
}

function getAllRoutesLocked() {
  const state = store.getState()
  const routesInfos = selectRoutesInfo(state)
  const calendarRange = selectCalendarRangeInterval(state)

  return Object.values(routesInfos).every(routeInfo => {
    const { date, type, locked } = routeInfo
    const isRouteInCalendarRange = isWithinInterval(date, calendarRange)
    const unavailable = type === 'routeUnavailable'

    // not in range routes or unavailable ones has not to be consider
    if (!isRouteInCalendarRange || unavailable) return true

    return !!locked
  })
}

async function handleClick() {
  if (loadingStateAtom.isRescheduling) return

  const allRoutesLocked = getAllRoutesLocked()

  const state = store.getState()

  const { planType, userType } = selectUserConfiguration(state)

  const readonlyStatus = computeReadonlyStatus(
    applicationModeAtom.offline,
    applicationModeAtom.uiMode,
    planType,
    userType,
    applicationModeAtom.transactionMode === 'external',
  )

  if (readonlyStatus.readonly) return

  setRouteLoadingState(mutableState => {
    mutableState.lockStatus.ALL = true
    return mutableState
  })
  try {
    const result = await store.dispatch(
      setVehiclesLockAction({
        lockedStatus: !allRoutesLocked,
      }),
    )

    if (!setVehiclesLockAction.fulfilled.match(result)) {
      throw new Error(result.payload?.message ?? 'Internal error')
    }
  } catch (e) {
    toast.error(e.message)
  }

  setRouteLoadingState(mutableState => {
    delete mutableState.lockStatus.ALL
    return mutableState
  })
}
