import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { MapControls } from '@/components/MapControls'

import { DevicesRoot } from './components/form/DevicesRoot'
import { Map } from './components/Map'

import { useEnsureDeviceVisibility } from './hooks/useEnsureDeviceVisibility'

export function Devices() {
  useEnsureDeviceVisibility()

  return (
    <HorizontalLayout data-testid="setup-devices-root">
      <DevicesRoot />

      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />
          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
