import { DrivingTimeSliderField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

import { drivingTimeSliderFieldWrappedProps } from '../constants'

export function Level() {
  const texts = useTexts()

  return (
    <DrivingTimeSliderField
      label={texts.drivingTime}
      name="level"
      wrappedInputProps={drivingTimeSliderFieldWrappedProps}
    />
  )
}
