import { SecondaryColumnHeader } from '@/forms-legacy'
import { RecurrenceSelector } from '@/components/RecurrenceSelector'

import { RecurrenceSelectorOuter } from './elements/RecurrenceSelectorOuter'
import { SelectorLayout } from './elements/SelectorLayout'

import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'
import { useRecurrencesMap } from './hooks/useRecurrencesMap'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  switchToDefaultView: () => void
}

export function CreateException(props: Props) {
  const { unifiedVehicle, switchToDefaultView } = props

  const { onCreate, onOpenExisting } = useActions(unifiedVehicle)
  const recurrencesMap = useRecurrencesMap(unifiedVehicle)
  const texts = useTexts()

  return (
    <>
      <SecondaryColumnHeader title={texts.title} description={texts.description} avatar={null} />
      <SelectorLayout>
        <RecurrenceSelectorOuter>
          <RecurrenceSelector
            onCreate={onCreate}
            onOpenExisting={onOpenExisting}
            onCancel={switchToDefaultView}
            recurrenceMap={recurrencesMap}
          />
        </RecurrenceSelectorOuter>
      </SelectorLayout>
    </>
  )
}
