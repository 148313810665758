import { Add } from '@mui/icons-material'

import { ListHeaderActions, ListHeaderPrimaryActionButton } from '@/components/List'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../../../../hooks/useTexts'
import { useActions } from './hooks/useActions'

export function Actions() {
  const { editing, onCreate } = useActions()
  const texts = useTexts()

  return (
    <ReadOnlyTooltip
      placement="bottom"
      render={preventEditing => (
        <ListHeaderActions>
          <Tooltip title={texts.addDepot} placement="bottom">
            <ListHeaderPrimaryActionButton
              className="pendo-setup__newdepot"
              disabled={preventEditing || editing}
              onClick={onCreate}
              trackId="setup-depot__create-new-button"
            >
              <Add />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        </ListHeaderActions>
      )}
    />
  )
}
