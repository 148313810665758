import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Box } from '@mui/material'
import { selectDevicesList } from '@/features/domain/device'
import { colorsUtils } from '@/utils'
import {
  ReadonlyFieldRowPart,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
  CollapsibleList,
  CollapseButton,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyLabel,
  FlexRow,
  Dot,
} from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  deviceIds: string[]
}

const countLabelStyle = { marginBottom: 0 }

export function DevicesList(props: Props) {
  const { deviceIds } = props

  const devices = useSelector(selectDevicesList)
  const texts = useTexts()

  const deviceInfos = useMemo(
    () => deviceIds.map(id => devices[id]),

    [devices, deviceIds],
  )

  return (
    <ReadonlyBlock>
      <CollapsibleList<uui.domain.client.gps.wwgps.DeviceInfo>
        list={deviceInfos}
        itemsThreshold={0}
        renderHeader={({ itemsCount, collapsed, itemsThreshold, onToggle }) => (
          <FlexRow>
            <ReadonlyLabel primary empty={itemsCount === 0} style={countLabelStyle}>
              {texts.monitoredVehiclesCount(deviceIds.length)}
            </ReadonlyLabel>

            {itemsCount > itemsThreshold && (
              <CollapseButton
                text={collapsed ? texts.showAll : texts.hideAll}
                onToggle={onToggle}
                collapsed={collapsed}
              />
            )}
          </FlexRow>
        )}
        renderLoadMore={() => null}
        renderListItem={({ deviceId, iconColor2: color, label: name }) => (
          <ReadonlyField key={deviceId}>
            <ReadonlyFieldRow style={{ justifyContent: 'space-between' }}>
              <ReadonlyFieldLabel>{name}</ReadonlyFieldLabel>

              <ReadonlyFieldRowPart>
                <ReadonlyLabel small>{deviceId}</ReadonlyLabel>
                <Box width={16} height={16} margin="0 8px">
                  <Dot innerColor={colorsUtils.getValidHexColor(color)} />
                </Box>
              </ReadonlyFieldRowPart>
            </ReadonlyFieldRow>
          </ReadonlyField>
        )}
      />
    </ReadonlyBlock>
  )
}
