import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    resetEmailCounter: translate({ id: 'settings.messagingPlan.view.resetEmailCounter' }),
    sentEmails: (count: string) =>
      translate({ id: 'settings.messagingPlan.view.sentEmails', values: { count } }),

    sentEmailsSince: (date: string, days: number) =>
      translate({
        id: 'settings.messagingPlan.view.sentEmailsSince',
        values: { date, days },
      }),
  }))

  return api
}
