import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectAlerts } from '@/features/domain/alert'

export const useAlertsRoot = () => {
  const { editing } = useEditingStateWithCtx('alert')
  const [selection] = useCrudSelection('alerts')
  const alerts = useSelector(selectAlerts)
  const selectedAlerts = useMemo(() => getSelectedAlerts(selection, alerts), [selection, alerts])

  return {
    alerts,
    selectedAlerts,
    editing,
    selection,
  }
}

const getSelectedAlerts = (
  selection: workwave.DeepReadonly<string[]>,
  alertsMap: Record<string, uui.domain.client.gps.wwgps.EventAlert>,
): uui.domain.client.gps.wwgps.EventAlert[] => {
  // selection could hold some ids that are not in the alertsMap due to server-data computation
  // alertsMap holds the "truth"
  return selection.reduce<uui.domain.client.gps.wwgps.EventAlert[]>((acc, id) => {
    if (alertsMap[id]) {
      acc.push(alertsMap[id])
    }
    return acc
  }, [])
}
