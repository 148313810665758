import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRoutesInfo } from '@/features/domain/scheduler'
import { useOrderStepsNavigator } from '../../../../../hooks/useOrderStepsNavigator'

export function useCanUnassign() {
  const routesInfo = useSelector(selectRoutesInfo)
  const { currentOrders } = useOrderStepsNavigator()

  return useMemo(() => {
    return currentOrders.every(o => {
      if (o.isUnassigned) return true

      // if route is locked, the order cannot be unassigned
      return routesInfo[o.routeId]?.locked ? false : true
    })
  }, [routesInfo, currentOrders])
}
