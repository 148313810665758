import { Component, MouseEventHandler, CSSProperties, ReactElement } from 'react'

import { clsx } from '@/utils'
import Button from '../Button'
import Dot from '../Dot'

export type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>
  label?: string
  icon: ReactElement
  className?: string
  disabled?: boolean
  isActive?: boolean
  isRounded?: boolean
  isMultiSelection?: boolean
  style?: CSSProperties
  dotColor?: string
  type?: 'submit' | 'reset' | 'button'
  title?: string
  testid?: string
}

export default class ActionButton extends Component<Props> {
  props: Props
  render() {
    const {
      label,
      className = '',
      icon,
      onClick,
      disabled,
      isActive,
      isRounded,
      style,
      dotColor,
      type,
      title = '',
      testid,
    } = this.props

    const rootClassName = clsx({
      [className]: true,
      'o-action-button': true,
      'is-active': isActive,
      'is-rounded': isRounded,
    })

    return (
      <Button
        className={rootClassName}
        icon={icon}
        iconPosition="top"
        onClick={onClick}
        disabled={disabled}
        style={style}
        type={type}
        title={title}
        testid={testid}
      >
        {dotColor && (
          <span className="o-action-button__dot">
            <Dot innerColor={dotColor} outerColor={dotColor} />
          </span>
        )}
        {label}
      </Button>
    )
  }
}
