import { useCreateDate } from '@/hooks'
import { FlexBox, Text } from '@/local/components'
import { FormatDateTime } from '@/components/smartUtils/time/FormatDateTime'

import { Avatar } from './Avatar'
import { useTexts } from './useTexts'

interface Props {
  sentTs: number
}

export function SentBy(props: Props) {
  const { sentTs } = props

  const texts = useTexts()

  const date = useCreateDate(sentTs)

  return (
    <FlexBox vAlignContent="center">
      <Avatar />
      <FlexBox column>
        <Text size="$p3" color="$shadyLady">
          {texts.senderText}
        </Text>
        <Text marginTop={2} size="$p3" color="$shadyLady">
          <FormatDateTime source={date} />
        </Text>
      </FlexBox>
    </FlexBox>
  )
}
