import { isBefore, isEqual, parse } from 'date-fns/esm'

export function isRangeWithArchivedDays(
  selectionStart?: Date,
  selectionEnd?: Date,
  firstNonArchivedDay?: string,
) {
  if (firstNonArchivedDay && selectionStart && selectionEnd) {
    const firstNonArchivedDayAsDate = parse(firstNonArchivedDay, 'yyyyMMdd', new Date())
    const isRange = !isEqual(selectionStart, selectionEnd)
    const isStartArchived = isBefore(selectionStart, firstNonArchivedDayAsDate)
    const isEndArchived = isBefore(selectionEnd, firstNonArchivedDayAsDate)

    if (!isRange) {
      return { rangeWithArchivedDays: false, rangeOfArchivedDays: false }
    }

    return {
      rangeWithArchivedDays: isStartArchived || isEndArchived,
      rangeOfArchivedDays: isStartArchived && isEndArchived,
    }
  }

  return { rangeWithArchivedDays: false, rangeOfArchivedDays: false }
}
