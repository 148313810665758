import { SecondaryColumnHeader } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'

import { FormBulkAvatar } from './components/FormBulkAvatar'
import { ActionsManager } from './components/ActionsManager'

import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'
import { useHeaderDescription } from './hooks/useHeaderDescription'

interface Props {
  alerts: uui.domain.client.gps.wwgps.EventAlert[]
}

export function BulkView(props: Props) {
  const { alerts } = props

  const texts = useTexts()
  const actions = useActions()
  const description = useHeaderDescription(alerts)

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.title(alerts.length)}
        description={description}
        action={actions.onClose}
        avatar={<FormBulkAvatar size={44} elementsCount={alerts.length} />}
      />
      <ActionsManager alerts={alerts} />
    </FormColumn>
  )
}
