import styled from 'styled-components'

const LeftActions = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > * + * {
    margin-left: 8px;
  }
`

LeftActions.displayName = 'LeftActions'
export default LeftActions
