import type { BulkTags, BulkTagValuePartial, BulkTagValueExact } from '../../../../types'

import { useMemo } from 'react'

interface SplittedTags {
  exact: BulkTagValueExact[]
  partial: BulkTagValuePartial[]
}

export function useSplitAndSortTagByType(tags: BulkTags) {
  return useMemo(() => {
    const splitted = Object.values(tags.tags).reduce<SplittedTags>(
      (acc, curr) => {
        switch (curr.status) {
          case 'exact':
            acc.exact.push(curr)
            break
          case 'partial':
            acc.partial.push(curr)
            break
        }

        return acc
      },
      { exact: [], partial: [] },
    )

    splitted.exact.sort(sortExact)
    splitted.partial.sort(sortPartial)

    return splitted
  }, [tags])
}

function sortExact(a: BulkTagValueExact, b: BulkTagValueExact) {
  return a.name.localeCompare(b.name)
}

function sortPartial(a: BulkTagValuePartial, b: BulkTagValuePartial) {
  const countDiff = a.count - b.count
  if (countDiff !== 0) return -countDiff

  return a.name.localeCompare(b.name)
}
