import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function StopSign(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 14 14">
        <path d="M7 14a7 7 0 1 1 7-7 7 7 0 0 1-7 7zM3.33 5.44a1 1 0 0 0-1 1v1.12a1 1 0 0 0 1 1h7.34a1 1 0 0 0 1-1V6.44a1 1 0 0 0-1-1z" />
      </svg>
    </IconContainer>
  )
}
