import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectRoadSegments } from '@/features/domain/roadSegment'

export const useRoadsRoot = () => {
  const { editing } = useEditingStateWithCtx('segment')
  const [selection] = useCrudSelection('routingSegments')
  const roadSegments = useSelector(selectRoadSegments)
  const selectedRoadSegments = useMemo(
    () => getSelectedRoadSegments(selection, roadSegments),
    [selection, roadSegments],
  )

  return {
    roadSegments,
    selectedRoadSegments,
    editing,
    selection,
  }
}

const getSelectedRoadSegments = (
  selection: workwave.DeepReadonly<string[]>,
  segments: Record<string, uui.domain.client.rm.SegmentException>,
): uui.domain.client.rm.SegmentException[] => {
  // selection could hold some ids that are not in the vehiclesMap due to server-data computation
  // vehiclesMap holds the "truth"
  return selection.reduce<uui.domain.client.rm.SegmentException[]>((acc, id) => {
    if (segments[id]) {
      acc.push(segments[id])
    }
    return acc
  }, [])
}
