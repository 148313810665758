import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function House(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 10 9">
        <path d="M1.786 0a.714.714 0 00-.715.714v.357L0 2.5a.714.714 0 101.429 0l.714-1.429h1.071L2.857 2.5a.714.714 0 101.429 0V1.071h1.428V2.5a.714.714 0 101.429 0l-.357-1.429h1.071L8.571 2.5A.714.714 0 1010 2.5L8.929 1.071V.714A.714.714 0 008.214 0H1.786zm2.5 3.73a1.414 1.414 0 01-.715.199 1.41 1.41 0 01-.712-.199 1.413 1.413 0 01-.716.199c-.26 0-.5-.076-.71-.197a1.416 1.416 0 01-.719.197v3.928c0 .395.32.714.715.714h4.285V5h2.143v3.571h.714c.395 0 .715-.32.715-.714V3.93c-.262 0-.507-.073-.719-.197-.21.121-.45.197-.71.197-.262 0-.505-.075-.716-.199a1.41 1.41 0 01-.712.199c-.262 0-.504-.076-.715-.2a1.414 1.414 0 01-.714.2c-.261 0-.503-.076-.714-.2zM2.143 5h2.143v2.143H2.143V5z" />
      </svg>
    </IconContainer>
  )
}
