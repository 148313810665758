import type { Validation } from '../types'

import { useTexts } from '../useTexts'

export function useBackupSubjectCustomValidator(): Validation[] {
  const texts = useTexts()

  return [
    {
      id: 'backupSubjectCustomValidator',
      fields: 'backupSubjectCustom',
      validator: formApi => {
        // access the current name form-field
        const field = formApi.getField('backupSubjectCustom')

        return {
          required:
            field.required && field.value.trim().length === 0
              ? {
                  id: 'backupSubjectCustom-required',
                  field: 'backupSubjectCustom',
                  message: texts.requiredField,
                  priority: 10,
                }
              : null,
        }
      },
    },
    {
      id: 'backupSubjectCustomMaxLength',
      fields: 'backupSubjectCustom',
      validator: formApi => {
        // access the current name form-field
        const field = formApi.getField('backupSubjectCustom')
        const maxLength = 50

        return {
          backupSubjectCustomMaxLengthError:
            field.value.length > maxLength
              ? {
                  id: 'backupSubjectCustomMaxLengthError',
                  field: 'backupSubjectCustom',
                  message: texts.subjectTooLong(maxLength),
                  priority: 9,
                }
              : null,
        }
      },
    },
  ]
}
