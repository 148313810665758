import { useSelector } from 'react-redux'
import { styled } from '@mui/material'

import { Truck } from '@/icons'
import { Text } from '@/local/components'
import { getColorValue, isDark } from '@/styles'
import { Tooltip } from '@/components/primitives/Tooltip'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'

import { useTexts } from '../useTexts'

interface Props {
  backgroundColor: string
  onClick?: () => void
  vehicleName: string
  children?: string
}

const TagButton = styled('button', {
  name: 'TagButton',
  shouldForwardProp: prop => prop !== 'backgroundColor',
})<{
  backgroundColor: string
}>(props => ({
  outline: 0,
  display: 'flex',
  alignItems: 'center',
  borderWidth: 0,
  borderRadius: 4,
  backgroundColor: props.backgroundColor,
  cursor: props.disabled ? 'default' : 'cursor',
}))

export function VehicleTag(props: Props) {
  const { backgroundColor, vehicleName, children, onClick } = props

  const calendarRange = useSelector(selectCalendarRange)
  const { today } = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const contrastColor = isDark(backgroundColor) ? '$pureWhite' : '$nightRider'
  const isRange = calendarRange.minDate !== calendarRange.maxDate
  const isFuture = parseInt(calendarRange.maxDate) > parseInt(today)

  return (
    <Tooltip
      placement="top"
      disabled={!isRange && !isFuture}
      title={texts.disabledForRange(vehicleName)}
    >
      <TagButton
        data-trackid="navigo-routes-overview-details-single-vehicle"
        data-testid="navigo-routes-overview-details-single-vehicle"
        backgroundColor={getColorValue(backgroundColor)}
        disabled={isRange || isFuture}
        onClick={onClick}
      >
        <Truck size={14} marginRight={4} color={contrastColor} />

        <Text size="$p3" color={contrastColor}>
          {children}
        </Text>
      </TagButton>
    </Tooltip>
  )
}
