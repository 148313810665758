import type { TrafficAreaFormValuesValidation } from '../types'

import { useMemo } from 'react'
import { Footer as UIFooter } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  errors: TrafficAreaFormValuesValidation
  fieldsWithError: string[]
  creating?: boolean
  valid: boolean
  planLocked: boolean
  pristine: boolean
  saveDisabled: boolean
  onSubmit: () => void
  onCancel: () => void
  submitting: boolean
}

export function Footer(props: Props) {
  const {
    fieldsWithError,
    errors,
    valid,
    planLocked,
    pristine,
    saveDisabled,
    onSubmit,
    onCancel,
    creating,
    submitting,
  } = props
  const texts = useTexts()

  const missingPolyline = !!errors['editableSegment']

  const primary = useMemo(() => {
    return {
      text: creating ? texts.create : texts.save,
      title: texts.saveTitle(valid, planLocked, pristine),
      disabled: saveDisabled,
      onClick: onSubmit,
    }
  }, [texts, valid, planLocked, pristine, saveDisabled, onSubmit, creating])

  const secondary = useMemo(() => {
    return {
      text: texts.cancel,
      disabled: false,
      onClick: onCancel,
    }
  }, [texts, onCancel])

  return (
    <UIFooter
      errorTitle={texts.footerTitle}
      message={texts.footerMessage(missingPolyline)}
      errors={fieldsWithError}
      submitting={submitting}
      secondary={secondary}
      primary={primary}
    />
  )
}
