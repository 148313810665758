import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCustomerNotificationsSettings } from '@/features/domain/territory'

export type TwoDaysNotificationValues = ReturnType<typeof useTwoDaysNotificationValues>
/**
 * That hook grabs twoDaysNotification values from the domains, than filter some of them out and
 * does some type conversion where required
 */
export function useTwoDaysNotificationValues() {
  const notificationSettings = useSelector(selectCustomerNotificationsSettings)

  return useMemo(() => {
    const { notificationCutoffMins, windowOfCommitmentToleranceMins, ...rest } =
      notificationSettings['twoDaysSettings']

    return Object.assign({}, rest, {
      // Number -> string conversion (actually RadioGroup does not manage numbers)
      windowOfCommitmentToleranceMins: windowOfCommitmentToleranceMins.toString(),

      // convert it into seconds
      notificationCutoffMins,
    })
  }, [notificationSettings])
}
