import type { LocationEditStatus } from '../../../LocationEditor/typings'
import type { LocationEditorProps, OnChangeDropDownLocation } from './typings'

import { useCallback } from 'react'
import { EditLocationAlt } from '@mui/icons-material'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { produce } from 'immer'

import { fitMap, updateLocationPin } from '@/map'

import LocationEditor from '../../../LocationEditor'
import TextButton from '../../../LocationEditor/elements/TextButton'

import { useOnChange } from './hooks/useOnChange'
import { useDropDownStateRef } from './hooks/useDropDownStateRef'
import { useSearchOnMapItems } from './hooks/useSearchOnMapItems'
import { useFormFieldLocation } from './hooks/useFormFieldLocation'
import { useDropDownStatusChange } from './hooks/useDropDownStatusChange'
import { useCopyCoordinatesToClipBoard } from './hooks/useCopyCoordinatesToClipBoard'

import { useLocationEditorModal } from './modals/LocationEditorModal'
import { useTexts } from './useTexts'

type LocationEditorDropDownProps = LocationEditorProps & {
  status: LocationEditStatus
  onDropDownChange: OnChangeDropDownLocation
  originalLocation?: uui.domain.client.Location
  showAdjustAddress?: boolean
  onFixInvalidAddress: () => void
  onConfirmLowAccuracy: () => void
  onRenameLocationChange: (address: string) => void
}

export function LocationEditorDropDown(props: LocationEditorDropDownProps) {
  const {
    pinId,
    status,
    pinType,
    formProps,
    queryMinLength,
    connectedPlaceId,
    onDropDownChange,
    showAdjustAddress,
    wrappedInputProps,
    onFixInvalidAddress,
    onConfirmLowAccuracy,
  } = props

  const dropDownStateRef = useDropDownStateRef()

  const { items, onTextChange, cleatItems } = useSearchOnMapItems(props, dropDownStateRef)
  const handleCopyCoordinateToClipboard = useCopyCoordinatesToClipBoard()
  const { onOpen, onClose } = useDropDownStatusChange(cleatItems, dropDownStateRef)

  // For that specific modal we specify the id from the parent component to allow multiple modals to be present (NOTE: not visible) at the same time
  const { show, Modal } = useLocationEditorModal(pinId)
  const handleOnChange = useOnChange(onDropDownChange, dropDownStateRef)
  const location = useFormFieldLocation(props)
  const theme = useTheme()
  const texts = useTexts()

  const onChange = useCallback(
    (location: uui.domain.client.Location) => {
      // Update Pin location
      updateLocationPin(pinId, prevPin =>
        produce(prevPin, draft => {
          draft.pin.location = location
          draft.pin.latLng = location.latLng
        }),
      )

      // Fit map to new location
      fitMap([location.latLng])
    },
    [pinId],
  )

  if (!location) return null

  const isConnectedToPlace = !!connectedPlaceId
  const adjustDisabled = isConnectedToPlace

  const { loading } = dropDownStateRef.current

  return (
    <Stack width="100%" spacing={2}>
      <LocationEditor
        height={100}
        status={status}
        onOpen={onOpen}
        pinType={pinType}
        onClose={onClose}
        loading={loading}
        location={location}
        onChange={handleOnChange}
        assetList={items}
        onTextChange={onTextChange}
        queryMinLength={queryMinLength}
        onCopyCoordinates={handleCopyCoordinateToClipboard}
        isConnectedToPlace={isConnectedToPlace}
        onFixInvalidAddress={onFixInvalidAddress}
        onConfirmLowAccuracy={onConfirmLowAccuracy}
        {...wrappedInputProps}
      />

      {showAdjustAddress && (
        <Stack mt={1} spacing={1}>
          <TextButton
            title={texts.adjustAddress}
            color={adjustDisabled ? theme.palette.grey['900'] : undefined}
            onClick={show}
            disabled={adjustDisabled}
            data-testid="location-editor__adjust-position"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <EditLocationAlt fontSize="small" />
              <Box>{texts.adjustAddress}</Box>
            </Stack>
          </TextButton>

          {adjustDisabled && (
            <Typography fontSize={12} color="grey.700">
              {texts.adjustAddressDisabledHelper}
            </Typography>
          )}

          <Modal
            label={formProps.input.value.address}
            pinId={pinId}
            pinType={pinType}
            onChange={onChange}
            location={formProps.input.value as uui.domain.client.Location}
          />
        </Stack>
      )}
    </Stack>
  )
}
