import styled from 'styled-components'

import { TextButton } from '@/forms-legacy'

type Props = {
  remove?: boolean
}

const StyledTextButton = styled(TextButton)<Props>`
  font-size: ${p => p.theme.fonts.$s};
  color: ${p => (p.remove ? p.theme.colors.$outrageousRed : p.theme.colors.$scienceBlue)};
  white-space: nowrap;

  transition: color 0.3s ease;

  &:disabled {
    color: ${p => p.theme.colors.$silver};
    opacity: 1;
  }
`

StyledTextButton.displayName = 'StyledTextButton'

export default StyledTextButton
