import { Stack, styled } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'

import { useModalsContext } from '../../context/ModalsContextProvider'

import { useColumns } from './hooks/useColumns'
import { useTexts } from './hooks/useTexts'
import { Header } from './components/Header/Header'

interface Props {
  companies: uui.domain.client.rm.Company[]
  companiesFilter: string
  onChangeCompaniesFilter: (a: string) => void
}

export function CompaniesTable(props: Props) {
  const { companies, companiesFilter, onChangeCompaniesFilter } = props
  const columns = useColumns()
  const texts = useTexts()
  const { companyTableApiRef } = useModalsContext()

  return (
    <Stack flex="1 1 auto">
      <Header companiesFilter={companiesFilter} onChangeCompaniesFilter={onChangeCompaniesFilter} />
      <DataGridComponent
        apiRef={companyTableApiRef}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        hideFooter
        columns={columns}
        rows={companies}
        localeText={texts.table.localeTexts}
      />
    </Stack>
  )
}

const DataGridComponent = styled(DataGridPro, { name: 'CompaniesTable', skipSx: true })(
  ({ theme }) => {
    return {
      [`&.${gridClasses.root}`]: {
        border: 'none',
      },
      ['& .no-focus-outline']: {
        outline: 'none !important',
      },
      [`& .${gridClasses.columnHeaders}`]: {
        borderColor: `${theme.palette.divider}`,
      },
      [`& .${gridClasses.cell}`]: {
        borderColor: `${theme.palette.divider}`,
      },
    }
  },
)
