import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../../typings'

/**
 * Refreshes GPS Data
 *
 * @private
 */
export const getDevicesByTenantSource = createAsyncThunk<
  // Return type of the payload creator
  uui.domain.server.gps.telematics.DeviceInfo[],
  // First argument to the payload creator
  string,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/gps/telematics/getDevicesByTenantSource', async (tenantSourceId, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/gps/telematics/getDevicesByTenantSource', {
      tenantSourceId,
      category: 'rpc',
      type: 'rpc/gps/telematics/getDevicesByTenantSource',
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {getDevicesByTenantSource}`,
      { tags: ['rpc'] },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Creation of Telematics tenant source Failed`,
      error,
    })
  }
})
