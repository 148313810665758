import { Checkbox, FormControlLabel, Stack } from '@mui/material'

import { FormGroup } from './components/FormGroup'

interface Props {
  label: string
  checked: boolean
  testId?: string
  onToggleSelection: () => void
  disabled?: boolean
}

export function ListFooter(props: Props) {
  const { label, checked, testId = 'form-list-footer', onToggleSelection, disabled } = props

  return (
    <Stack data-testid={testId} data-trackid={testId} width="100%">
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={onToggleSelection} disabled={disabled} />}
          label={label}
        />
      </FormGroup>
    </Stack>
  )
}
