import type { AddRecipientProps as Props } from '../../../typings'

import { useState, useCallback, useEffect } from 'react'

import { isValidSms } from '@/utils'
import { useValidateEmail } from '@/hooks'

import TextInputState from '../../../../../enhancers/TextInputState'
import HorizontalRadioGroup from '../../../../HorizontalRadioGroup'
import FieldMessage from '../../../../../edit/FieldMessage'
import { RadioGroupValues } from '../../../../RadioGroup'
import Block from '../../../../../edit/Block'

import InputButtonWrapper from './elements/InputButtonWrapper'
import TextInput from './elements/TextInput'
import Button from './elements/Button'
import { useTexts } from './useTexts'

function AddRecipient(props: Props) {
  const {
    onAdd,
    onCancel,
    isDuplicatedRecipient,
    defaultEmailAddress = '',
    allowSms = true,
  } = props

  const [notificationType, setNotificationType] = useState<'email' | 'sms'>('email')
  const [inputValue, setInputValue] = useState<string>(defaultEmailAddress)

  const texts = useTexts()
  const validateEmail = useValidateEmail()

  const [warningMessage, setWarningMessage] = useState('')

  useEffect(() => {
    async function computeWarningText() {
      if (inputValue.length === 0) {
        setWarningMessage('')
        return
      }

      let warningMessage: string | undefined

      if (isDuplicatedRecipient) {
        if (isDuplicatedRecipient(inputValue)) {
          warningMessage = texts.duplicatedRecipientMessage
        }
      }

      if (notificationType === 'sms') {
        if (!isValidSms(inputValue)) {
          warningMessage = texts.invalidSmsMessage
        }
      } else {
        const valid = await validateEmail(inputValue)
        if (!valid) {
          warningMessage = texts.invalidEmailMessage
        }
      }

      setWarningMessage(warningMessage ?? '')
    }

    computeWarningText()
  }, [texts, inputValue, notificationType, isDuplicatedRecipient, validateEmail])

  const radioGroupValues = useCallback(
    (): RadioGroupValues => [
      {
        label: texts.emailButton,
        value: 'email',
      },
      {
        label: texts.smsButton,
        value: 'sms',
      },
    ],
    [texts],
  )

  const handleChangeInput = (value: string) => {
    setInputValue(value)
  }

  const handleChangeType = (type: string): void => {
    setNotificationType(type === 'sms' ? 'sms' : 'email')
  }

  const handleAdd = useCallback(() => {
    onAdd({ address: inputValue, prefix: notificationType })
  }, [onAdd, inputValue, notificationType])

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  const addButtonDisabled = !!warningMessage
  const isInputEmpty = inputValue.length === 0

  return (
    <Block half={false} warning={!!warningMessage} style={{ marginBottom: 30 }}>
      {allowSms && (
        <HorizontalRadioGroup
          name="addRecipientType"
          values={radioGroupValues()}
          onChange={handleChangeType}
          value={notificationType}
        />
      )}

      <TextInputState
        value={inputValue}
        delay={300}
        onChange={handleChangeInput}
        render={(inputValue: string, wrappedOnChange) => (
          <InputButtonWrapper>
            <TextInput
              name="addRecipientValue"
              type="text"
              onChange={wrappedOnChange}
              value={inputValue}
              placeholder={
                notificationType === 'email'
                  ? texts.addRecipientInputEmailPlaceholder
                  : texts.addRecipientInputSmsPlaceholder
              }
            />
            <Button
              type="button"
              onClick={isInputEmpty ? handleCancel : handleAdd}
              disabled={addButtonDisabled}
            >
              {isInputEmpty ? texts.discardRecipientButton : texts.addRecipientButton}
            </Button>
          </InputButtonWrapper>
        )}
      />
      {!!warningMessage && <FieldMessage warning>{warningMessage}</FieldMessage>}
      {/* federico: temporary disabled */}
      {/* {!warning && (
        <FieldMessage>
          {renderText(texts.addRecipientInputMessage)}
        </FieldMessage>
      )} */}
    </Block>
  )
}

export default AddRecipient
