import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useMainSelection, usePagination } from '@/atoms'
import { selectOrderSteps } from '@/features/domain/order'

/**
 * This hook returns the orders currently selected by the user trough the main selection.
 * If the Navigo is active it also filters the results based on the Navigo paginator
 */
export const useCurrentOrders = () => {
  const { page } = usePagination('navigo')
  const orders = useSelector(selectOrderSteps)
  const [selection] = useMainSelection('orderSteps')

  return useMemo(() => {
    const selectedOrders = selection.reduce<uui.domain.client.rm.ExtendedOrderStep[]>((acc, id) => {
      !!orders[id] && acc.push(orders[id])
      return acc
    }, [])

    const currentOrder = selectedOrders[page]

    if (page === -1 || !currentOrder) return selectedOrders

    return [currentOrder]
  }, [orders, selection, page])
}
