import type { View } from '../types'

import { useCallback, useEffect, useRef } from 'react'

import { cloneVehicleMultipleCopies, deleteVehicles } from '@/features/domain/vehicle'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

type SetView = (view: View) => void

export function useActions(vehicleId: string, setView: SetView) {
  const mounted = useRef(true)

  // const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const onDelete = useCallback(async () => {
    try {
      const result = await dispatch(deleteVehicles([vehicleId]))

      if (!deleteVehicles.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      if (mounted.current) {
        setView('default')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [setView, dispatch, toast, vehicleId])

  const onClone = useCallback(
    async (copies: number) => {
      try {
        const result = await dispatch(cloneVehicleMultipleCopies({ copies, vehicleId }))

        if (!cloneVehicleMultipleCopies.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        if (mounted.current) {
          setView('default')
        }
      } catch (e) {
        toast.error(e.message)
      }
    },
    [setView, dispatch, toast, vehicleId],
  )

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  return {
    onDelete,
    onClone,
  }
}
