import { type SchedulerProConfig } from '@bryntum/schedulerpro'
import { getSchedulerColumnAtom } from '../../atoms/columns'

export function createSubGridConfigs(): SchedulerProConfig['subGridConfigs'] {
  return {
    // set a fixed locked section width if desired
    id: { resizable: false, weight: 0, width: 59, scrollable: false, disabled: true },

    cols: {
      resizable: true,
      weight: 1,
      width: getSchedulerColumnAtom(true).columnsSectionWidth,
    },

    final: { resizable: false, weight: 2, width: 66, disabled: true },

    normal: { resizable: false, weight: 3 },
  }
}
