import type { Props } from './typings'

import { useCallback, useState, memo } from 'react'
import { format, isDate, setHours, setMinutes, setSeconds } from 'date-fns'

import { useDidUpdate } from '@/local/hooks'
import { timeUtils } from '@/server-data'

import { Calendar } from '@/components/Calendar'

import { CompactDateSelector } from '../primitives/CompactDateSelector'
import HorizontalRadioGroup from '../primitives/HorizontalRadioGroup'

import RestoreFieldButton from '../primitives/RestoreFieldButton'
import HourPicker from '../primitives/HourPicker'
import FieldMessage from '../edit/FieldMessage'
import { FlexRow } from '../../../lib'
import Block from '../edit/Block'
import Label from '../edit/Label'

function DateTimeSelectorField(props: Props) {
  const {
    label,
    name,
    info,
    half = false,
    wrappedInputProps: {
      compactCalendar: compactCalendarInputProps,
      hourPicker: hourPickerInputProps,
      calendar: calendarProps,
      nullValueButtons: nullValueButtonsProps,
      nullValueButtons: { defaultCustomDate = null } = {},
    },
    innerLabels: { calendar: calendarLabel = '', time: timeLabel = '' } = {},
    formProps: {
      input: { value, onChange },
      meta: { error, dirty, initial },
    },
    useTimePicker = true,
    allowNullValue = false,
  } = props

  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [timeInSeconds, setTimeInSeconds] = useState<number>(
    isDate(value) ? timeUtils.getTimeAsSecondsFromDate(value) : 0,
  )

  useDidUpdate(() => {
    isDate(value)
      ? setTimeInSeconds(timeUtils.getTimeAsSecondsFromDate(value))
      : setTimeInSeconds(-1)
  }, [value])

  const handleToggleCalendar = useCallback(() => {
    setShowCalendar(!showCalendar)
  }, [showCalendar])

  const handleChangeDate = useCallback(
    (date: Date) => {
      const { hours, minutes, seconds } = timeUtils.explodeSeconds(timeInSeconds)
      onChange(setSeconds(setMinutes(setHours(date, hours), minutes), seconds))
    },
    [onChange, timeInSeconds],
  )

  const handleChangeTime = useCallback(
    (timeSec: number) => {
      const { hours, minutes, seconds } = timeUtils.explodeSeconds(timeSec)
      onChange(setSeconds(setMinutes(setHours(value, hours), minutes), seconds))
    },
    [onChange, value],
  )

  const handleRadioChange = useCallback(
    (selectedValue: string) => {
      onChange(selectedValue === 'neverEnd' ? null : defaultCustomDate)
    },
    [onChange, defaultCustomDate],
  )

  const showDateTime: boolean = !allowNullValue || (allowNullValue && !!value)

  return (
    <Block half={half} error={!!error} dirty={!!dirty}>
      <Label htmlFor={name}>
        {label}{' '}
        <RestoreFieldButton
          dirty={dirty}
          initial={initial}
          onChange={onChange}
          style={{ marginLeft: 12 }}
        />
      </Label>

      {allowNullValue && nullValueButtonsProps && (
        <HorizontalRadioGroup
          {...nullValueButtonsProps}
          onChange={handleRadioChange}
          value={nullValueButtonsProps.value}
        />
      )}

      {showDateTime && (
        <>
          <Label>{calendarLabel}</Label>
          <CompactDateSelector
            onChange={handleChangeDate}
            onClick={handleToggleCalendar}
            value={value}
            isOpen={showCalendar}
            {...compactCalendarInputProps}
          />
          {showCalendar && (
            <FlexRow justifyContent="center" style={{ marginTop: 24 }}>
              <Calendar
                layout="date"
                selection={value}
                onChange={handleChangeDate}
                initialVisibleDate={isDate(value) ? format(value, 'yyyyMMdd') : undefined}
                {...calendarProps}
              />
            </FlexRow>
          )}
          {useTimePicker && (
            <FlexRow>
              <Block half={true}>
                <Label>{timeLabel}</Label>
                <HourPicker
                  onChange={handleChangeTime}
                  value={timeInSeconds}
                  {...hourPickerInputProps}
                />
              </Block>
            </FlexRow>
          )}
        </>
      )}

      {!!error && <FieldMessage error>{error}</FieldMessage>}
      {!!info && <FieldMessage>{info}</FieldMessage>}
    </Block>
  )
}

export default memo(DateTimeSelectorField)
