import { useCallback } from 'react'

import { copyToClipboard } from '@/utils'
import { gis } from '@/server-data'

export function useCopyCoordinatesToClipBoard() {
  return useCallback((location: uui.domain.client.Location) => {
    copyToClipboard(gis.latLngToUrlValue(location.latLng))
  }, [])
}
