import { BulkSelect } from '@/components/BulkSelect'

import {
  type FormFields,
  type FormErrors,
  type Priority as PriorityType,
} from '../../../formFields'

import { useTexts } from './useTexts'
import { useOptions } from './useOptions'

export function Priority() {
  const texts = useTexts()
  const options = useOptions()

  return (
    <BulkSelect<'priority', PriorityType, FormFields, FormErrors>
      name="priority"
      mixedLabel={texts.mixed}
      options={options}
      label={texts.priority}
      testId="priority"
    />
  )
}
