import type { useAlmostThereNotificationValues } from './hooks/useAlmostThereNotificationValues'

export function convertValues(formValues: ReturnType<typeof useAlmostThereNotificationValues>) {
  // Convert proximityThresholdMins from string to number
  const proximityThresholdMins = parseInt(formValues.proximityThresholdMins)

  return {
    ...formValues,
    proximityThresholdMins,
  }
}
