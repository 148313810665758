import type { GridSortDirection } from '@mui/x-data-grid-pro'

import { Box } from '@mui/material'
import { useGridApiRef } from '@mui/x-data-grid-pro'

import { type ReactNode, useMemo } from 'react'
import { DataGrid } from '@/components/DataGrid'

import { TopBar } from './components/TopBar'

import { useOrdersGridActions } from './hooks/useOrdersGridActions'
import { useColumnsPanelProps } from './hooks/useColumnsPanelProps'
import { useOrdersGridData } from './hooks/useOrdersGridData'
import { useAutoScroll } from './hooks/useAutoScroll'
import { useStyles } from './hooks/useStyles'

interface Props {
  ResizeHandle?: ReactNode
}

const lockedColumns = ['formattedData.name', 'formattedData.orderId']
const columnMenuProps = { lockedColumns }

const sortingOrder: GridSortDirection[] = ['asc', 'desc']

export function OrdersGrid(props: Props) {
  const { ResizeHandle } = props
  const { rows, columns, sortModel, selectionModel } = useOrdersGridData()
  const selection = selectionModel as string[] // Explicit cast to string[] is selectionModel is readonly (from atom)

  const columnsPanelProps = useColumnsPanelProps(lockedColumns)
  const apiRef = useGridApiRef()
  const classes = useStyles()

  useAutoScroll(apiRef, selection)

  const {
    onColumnVisibilityModelChange,
    onSelectionModelChange,
    onColumnOrderChange,
    onColumnWidthChange,
    onSortModelChange,
    onRowDoubleClick,
  } = useOrdersGridActions(sortModel)

  const orderIds = useMemo(() => rows.map(r => r.id), [rows])

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} className={classes.container}>
      {ResizeHandle}

      <TopBar orderIds={orderIds} apiRef={apiRef} />

      <Box data-testid="orders-grid-data-grid" className={classes.gridContainer}>
        <DataGrid
          hideFooter
          rows={rows}
          rowHeight={30}
          apiRef={apiRef}
          columns={columns}
          headerHeight={35}
          sortingMode="server"
          sortModel={sortModel}
          className={classes.grid}
          sortingOrder={sortingOrder}
          selectionModel={selection}
          columnMenuProps={columnMenuProps}
          onRowDoubleClick={onRowDoubleClick}
          onSortModelChange={onSortModelChange}
          columnsPanelProps={columnsPanelProps}
          onColumnOrderChange={onColumnOrderChange}
          onColumnWidthChange={onColumnWidthChange}
          onSelectionModelChange={onSelectionModelChange}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        />
      </Box>
    </Box>
  )
}
