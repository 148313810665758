import { CircularProgress, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'

interface Props {
  validating: boolean
  onDelete: () => void
  disabled?: boolean
  testId?: string
}

export function DeleteButton(props: Props) {
  const { onDelete, disabled, validating, testId = 'delete-button' } = props

  if (validating)
    return <CircularProgress size={16} sx={{ marginLeft: 1.5, marginRight: 1.5, marginTop: 1.5 }} />

  return (
    <IconButton onClick={onDelete} disabled={disabled} data-testid={testId}>
      <Delete />
    </IconButton>
  )
}
