import type { NotificationFormType } from '@/notificationForms'

import { Box, Button } from '@mui/material'
import { useTexts } from '../useTexts'

interface Props {
  onEdit: () => void
  test: () => void
  notification: NotificationFormType
}

export function ActionsView(props: Props) {
  const { onEdit, test, notification } = props

  const texts = useTexts()

  return (
    <Box display="flex" paddingBottom={4} paddingLeft={4} paddingRight={4}>
      <Button
        variant="text"
        color="primary"
        size="small"
        onClick={onEdit}
        data-testid="edit-button"
        data-trackid="edit-button"
        className={`pendo-${notification}-edit-button`}
      >
        {texts.edit}
      </Button>

      <Button
        variant="text"
        color="primary"
        size="small"
        onClick={test}
        data-testid="test-notification"
        data-trackid="test-notification"
        className={`pendo-${notification}-test-notification`}
      >
        {texts.test}
      </Button>
    </Box>
  )
}
