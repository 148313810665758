import { useTexts } from '../../useTexts'

import { getShortExecutedCustomFieldName } from './getShortExecutedCustomFieldName'
import { getExecutedCustomField } from './getExecutedCustomField'

export function createExecutedCustomFieldColumn(
  colDef: { field: string; hide?: boolean; width?: number },
  texts: ReturnType<typeof useTexts>,
) {
  return {
    type: 'string',
    filterable: false,
    valueGetter: getExecutedCustomField,
    hide: colDef?.hide ?? true,
    width: colDef?.width ?? 200,
    field: colDef?.field ?? `formattedData.customFields.${colDef.field}`,
    headerName: `${getShortExecutedCustomFieldName(colDef.field)} (${texts.executedCustomField})`,
  }
}
