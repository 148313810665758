import { Component } from 'react'

import styled from 'styled-components'
import { clsx } from '@/utils'
import { getColorWithAlpha } from '@/local/components'

import { RowProps } from '../typings'

const height: number = 24
const rowBottomPadding: number = 2
export const rowHeight: number = height + rowBottomPadding

class Row extends Component<RowProps> {
  handleChange = () => {
    const { item, onChange } = this.props
    onChange([item])
  }
  handleHover = () => {
    const { item, onHover } = this.props
    onHover(item)
  }

  render() {
    const {
      item: { label, disabled, info },
      selected,
      highlighted,
      className = '',
      RowIcon,
    } = this.props

    const rootClassName = clsx({
      'o-dropdown-connector-row': true,
      [className]: true,
      'is-highlighted': highlighted,
      'is-selected': selected,
      'is-disabled': disabled && !selected,
    })

    return (
      <div
        tabIndex={-1}
        className={rootClassName}
        onClick={this.handleChange}
        onMouseEnter={this.handleHover}
        data-testid="dropdown__connector-row"
      >
        {!!RowIcon && (
          <span className="o-dropdown-connector-row__icon">
            {RowIcon}
            {/* <Icon size={14} preset={rowIcon} /> */}
          </span>
        )}

        <span className="o-dropdown-connector-row__label">{label}</span>

        {!!info && <span className="o-dropdown-connector-row__info">{info}</span>}
        <span className="o-dropdown-connector-row__select">SELECT</span>
      </div>
    )
  }
}

const styledComponent = styled(Row)`
  position: relative;
  cursor: pointer;
  padding: 0px;
  padding-left: 34px;
  height: ${height}px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  user-select: none;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: ${p => p.theme.colors.$paleSky};
  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
  color: ${p => p.theme.colors.$nightRider};
  outline: none;
  display: flex;
  align-items: center;

  &:not(.is-disabled):hover {
    background: ${getColorWithAlpha('$scienceBlue', 0.75)};
  }

  &.is-disabled {
    pointer-events: none;
    background: ${p => p.theme.colors.$alabaster};
    color: ${p => p.theme.colors.$shadyLady};
  }

  &.is-selected {
    background: ${p => p.theme.colors.$scienceBlue};
    color: ${p => p.theme.colors.$pureWhite};
  }

  &:not(.is-disabled):not(.is-selected).is-highlighted {
    background: ${getColorWithAlpha('$scienceBlue', 0.75)};
    color: ${p => p.theme.colors.$pureWhite};
  }

  &:not(.is-disabled).is-selected.is-highlighted {
    background: ${getColorWithAlpha('$scienceBlue', 0.75)};
    color: ${p => p.theme.colors.$pureWhite};
  }

  .o-dropdown-connector-row__info {
    padding-left: 10px;
    font-size: ${p => p.theme.fonts.$s};
    font-style: italic;
    color: ${p => p.theme.colors.$nightRider};
  }

  &:not(.is-selected).is-highlighted .o-dropdown-connector-row__info {
    color: ${p => p.theme.colors.$pureWhite};
  }
  &:not(.is-disabled).is-highlighted .o-dropdown-connector-row__info {
    color: ${p => p.theme.colors.$pureWhite};
  }

  .o-dropdown-connector-row__select {
    padding-left: 6px;
    letter-spacing: 0.08rem;
    font-size: ${p => p.theme.fonts.$s};
    color: ${p => p.theme.colors.$pureWhite};
    user-select: none;
    position: absolute;
    top: 4px;
    right: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:not(.is-disabled).is-highlighted .o-dropdown-connector-row__select {
    opacity: 1;
  }

  .o-dropdown-connector-row__icon {
    display: block;
    width: 14px;
    color: ${p => p.theme.colors.$paleSky};
    margin-left: 6px;
    margin-right: 6px;
  }

  &.o-dropdown__row--double-label {
    display: flex;
    overflow: hidden;
  }

  &.o-dropdown__row--double-label .o-dropdown-connector-row__label {
    flex-shrink: 0;
  }

  &.o-dropdown__row--double-label .o-dropdown-connector-row__info {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;

    color: currentColor;
    padding-right: 8px;
  }

  &.o-dropdown__row--double-label .o-dropdown-connector-row__select {
    display: none;
  }
`
export default styledComponent
