import { styled } from '@mui/material'

import { theme } from '@/styles'

export const IconButton = styled('button', {
  name: 'IconButton',
})(() => ({
  background: 'transparent',
  padding: '4px 4px 2px 4px',
  margin: '0',
  borderWidth: '0',
  borderRadius: '3px',

  '&:hover': {
    backgroundColor: theme.colors.$silver,
  },
}))
