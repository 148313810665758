import { styled } from '@mui/material'

export const Footer = styled('footer')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderTop: `1px solid ${theme.palette.divider}`,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))
