import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    title: translate({
      id: 'orders.smsNotifications.noNotifications.title',
    }),

    description: (isAdmin: boolean) =>
      translate({
        id: isAdmin
          ? 'orders.smsNotifications.noNotifications.description.admin'
          : 'orders.smsNotifications.noNotifications.description.noAdmin',
      }),

    cta: translate({
      id: 'orders.smsNotifications.noNotifications.description.admin.btn',
    }),
  }))

  return api
}
