import type { Validation } from '../../formFields'
import { useMemo } from 'react'
import { useTexts } from './useTexts'

const closeoutTimeStartField = 'closeoutTimeStart' as const
const closeoutTimeEndField = 'closeoutTimeEnd' as const

export function useCloseoutValidations(): Validation[] {
  const texts = useTexts()

  return useMemo(() => {
    const validateCloseoutTimeStart: Validation = {
      id: 'validateCloseoutTimeStart',
      validator: formApi => {
        const { value: closeoutTimeStartValue } = formApi.getField(closeoutTimeStartField)
        const { value: closeoutTimeEndValue } = formApi.getField(closeoutTimeEndField)

        const incoherenceErrorID = 'closeoutTimeStart_incoherence'
        const gotIncoherence =
          closeoutTimeStartValue !== -1 && closeoutTimeEndValue !== -1
            ? closeoutTimeStartValue > closeoutTimeEndValue
            : false

        const incoherenceError = {
          priority: 20,
          message: texts.closeoutTimeIncoherenceError,
          field: closeoutTimeStartField,
          id: incoherenceErrorID,
        }

        return {
          [incoherenceErrorID]: gotIncoherence ? incoherenceError : null,
        }
      },
    }

    const validateCloseoutTimeEnd: Validation = {
      id: 'validateCloseoutTimeEnd',
      validator: formApi => {
        const { value: closeoutTimeStartValue } = formApi.getField(closeoutTimeStartField)
        const { value: closeoutTimeEndValue } = formApi.getField(closeoutTimeEndField)

        const incoherenceErrorID = 'closeoutTimeEnd_incoherence'
        const gotIncoherence =
          closeoutTimeStartValue !== -1 && closeoutTimeEndValue !== -1
            ? closeoutTimeStartValue > closeoutTimeEndValue
            : false

        const incoherenceError = {
          priority: 20,
          message: texts.closeoutTimeIncoherenceError,
          field: closeoutTimeEndField,
          id: incoherenceErrorID,
        }

        return {
          [incoherenceErrorID]: gotIncoherence ? incoherenceError : null,
        }
      },
    }

    return [validateCloseoutTimeStart, validateCloseoutTimeEnd]
  }, [texts])
}
