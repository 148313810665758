import { ReactElement } from 'react'

import BaseButton from './BaseButton'
import IconContainer from './IconContainer'

import { Props } from './typings'

const CircularButton = (props: Props): ReactElement => {
  const { Icon, ref, ...buttonProps } = props

  return (
    <BaseButton {...buttonProps} type="button">
      {!!Icon && <IconContainer>{Icon}</IconContainer>}
    </BaseButton>
  )
}

export default CircularButton
