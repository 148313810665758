import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRoutePolylineMapStyles } from '@/features/domain/ui'

export const useActiveRoutePathMapStyle = (routeIds: string[]) => {
  const routePolylineMapStyles = useSelector(selectRoutePolylineMapStyles)

  /**
   * This methods retrieve the correct active style for the current route path (polylines).
   * If there are different styles for the chosen route paths it will return undefined.
   */
  return useMemo(() => {
    let commonMapStyle: 'on' | 'off' | undefined

    for (const id of routeIds) {
      const orderMapStyle = routePolylineMapStyles.custom[id] ?? routePolylineMapStyles.mode

      if (commonMapStyle && orderMapStyle !== commonMapStyle) return

      commonMapStyle = orderMapStyle
    }

    return commonMapStyle
  }, [routePolylineMapStyles, routeIds])
}
