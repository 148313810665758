import { Box, Checkbox, FormControl, FormControlLabel, Typography, Stack } from '@mui/material'
import { useController } from '../../../../../hooks/useController'
import { useControllerActions } from '../../../../../hooks/useControllerActions'
import { useTexts } from '../../../../../useTexts'

interface Props {
  testId?: string
  trackId?: string
}

export function CustomizeColumns(props: Props) {
  const { testId = 'export-customize-columns-flag' } = props
  const trackId = props.trackId ?? testId

  const {
    data: { customizeColumns },
  } = useController()

  const handlers = useControllerActions()

  const texts = useTexts()

  return (
    <FormControl>
      <Stack data-testid={testId} data-trackid={trackId}>
        <FormControlLabel
          control={
            <Checkbox onChange={handlers.onChangeCustomizeColumns} checked={customizeColumns} />
          }
          label={texts.customizeColumns}
        />

        <Box ml={4}>
          <Typography variant="caption">{texts.customizeColumnsDescription}</Typography>
        </Box>
      </Stack>
    </FormControl>
  )
}
