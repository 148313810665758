import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { deleteAlerts } from '@/features/domain/alert'
import { useNotification } from '@/hooks'

export function useActions(ids: string[]) {
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const onDeleteAlerts = useCallback(async () => {
    try {
      const result = await dispatch(deleteAlerts(ids))

      if (deleteAlerts.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, toast, ids])

  return {
    onDeleteAlerts,
  }
}
