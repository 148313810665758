import { styled } from '@mui/material'

interface Props {
  selected: boolean
}

export const ListItemContainer = styled('button', {
  name: 'ListItemContainer',
  shouldForwardProp: prop => prop !== 'selected',
})<Props>(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  padding: theme.spacing(1, 2),

  background: selected ? theme.palette.primary.main : theme.palette.common.white,

  borderWidth: 0,
  borderTop: `1px solid`,
  borderBottom: `1px solid`,

  borderColor: selected ? theme.palette.primary.main : theme.palette.divider,

  overflow: 'hidden',
}))
