import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function SquaredDepot(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 10 10">
        <path d="M4.647 1.096L.272 4.085A.625.625 0 000 4.601v3.774C0 8.72.28 9 .625 9h2c.345 0 .625-.28.625-.625v-1.6c0-.954.785-1.73 1.75-1.73s1.75.776 1.75 1.73v1.6c0 .345.28.625.625.625h2C9.72 9 10 8.72 10 8.375V4.604c0-.207-.102-.4-.272-.516L5.353 1.096a.625.625 0 00-.706 0z" />
      </svg>
    </IconContainer>
  )
}
