import type {
  GridSortModel,
  GridRowParams,
  GridSelectionModel,
  GridCallbackDetails,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridColumnOrderChangeParams,
} from '@mui/x-data-grid-pro'
import type { Props } from '../types'

import { useCallback } from 'react'

interface Params {
  onColumnVisibilityModelChange?: Props['onColumnVisibilityModelChange']
  onSelectionModelChange?: Props['onSelectionModelChange']
  onColumnOrderChange?: Props['onColumnOrderChange']
  onColumnWidthChange?: Props['onColumnWidthChange']
  onSortModelChange?: Props['onSortModelChange']
  onRowDoubleClick?: Props['onRowDoubleClick']
}

export function useCallbacks(params: Params) {
  const {
    onColumnVisibilityModelChange: cbColumnVisibilityChange,
    onSelectionModelChange: cbSelectionModelChange,
    onColumnOrderChange: cbColumnOrderChange,
    onColumnWidthChange: cbColumnWidthChange,
    onSortModelChange: cbSortModelChange,
    onRowDoubleClick: cbRowDoubleClick,
  } = params

  const onColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel, details: GridCallbackDetails) => {
      cbColumnVisibilityChange?.(model, details)
    },
    [cbColumnVisibilityChange],
  )

  const onColumnWidthChange = useCallback(
    (callbackParams: GridColumnResizeParams) => {
      cbColumnWidthChange?.(callbackParams.colDef.field, callbackParams.width)
    },
    [cbColumnWidthChange],
  )

  const onColumnOrderChange = useCallback(
    (callbackParams: GridColumnOrderChangeParams) => {
      cbColumnOrderChange?.(callbackParams.oldIndex, callbackParams.targetIndex)
    },
    [cbColumnOrderChange],
  )

  const onSelectionModelChange = useCallback(
    (callbackParams: GridSelectionModel) => {
      cbSelectionModelChange?.(callbackParams as string[])
    },
    [cbSelectionModelChange],
  )

  const onSortModelChange = useCallback(
    (callbackParams: GridSortModel) => {
      cbSortModelChange?.(callbackParams, {})
    },
    [cbSortModelChange],
  )

  const onRowDoubleClick = useCallback(
    (callbackParams: GridRowParams) => {
      cbRowDoubleClick?.(callbackParams.row.id)
    },
    [cbRowDoubleClick],
  )

  return {
    onColumnVisibilityModelChange,
    onSelectionModelChange,
    onColumnOrderChange,
    onColumnWidthChange,
    onSortModelChange,
    onRowDoubleClick,
  }
}
