import { Text } from './Text'
import { Props } from './typings'

export function H6(props: Props) {
  const { children = '', as, className = '', style, size, weight, ...p } = props

  return (
    <Text size="$h6" weight="$regular" as={'h6'} style={style} className={className} {...p}>
      {children}
    </Text>
  )
}
