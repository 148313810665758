export function getRouteViolations(routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable) {
  // Calculate route violations (TWS and TWE)
  const startsBeforeWorkingDayStart =
    routeInfo.violationsCount > 0 ? routeInfo.violations.vehicle?.includes('TWS') ?? false : false

  const endsAfterWorkingDayEnd =
    routeInfo.violationsCount > 0 ? routeInfo.violations.vehicle?.includes('TWE') ?? false : false

  return { startsBeforeWorkingDayStart, endsAfterWorkingDayEnd }
}
