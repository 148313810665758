import type { ImageLayout } from '../typings'
import styled from 'styled-components'

export interface Props extends workwave.Button {
  layout?: ImageLayout
}

const ReadonlyImageButton = styled.button<Props>`
  display: block;
  width: 100%;
  height: 0;

  border-width: 0;
  padding: 0;
  outline: 0;
  padding-top: 62.6%;

  background: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 6px;
  background-color: ${p => p.theme.colors.$pureWhite};
  cursor: ${p => (p.disabled ? 'auto' : p.layout === 'detail' ? 'zoom-out' : 'zoom-in')};
`

ReadonlyImageButton.displayName = 'ReadonlyImageButton'
export default ReadonlyImageButton
