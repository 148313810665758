import { useEffect } from 'react'

import { Check } from '@/icons'
import { FlexBox } from '@/local/components'
import { resetTransactionResolution } from '@/components/Footer/hooks/useTransactionResolution'

import { Container } from '../../elements/Container'
import { useTexts } from './useTexts'

type Props = {
  offline: boolean
}

const timeout = 3000
export function FooterCommit(_props: Props) {
  const texts = useTexts()

  useEffect(() => {
    let effectCleaned = false

    const timer = setTimeout(() => {
      if (effectCleaned) return
      resetTransactionResolution()
    }, timeout)

    return () => {
      effectCleaned = true
      clearTimeout(timer)
    }
  }, [])

  return (
    <Container color="$pureWhite" background="$pigmentGreen">
      <FlexBox vAlignContent="center" grow={0} shrink={0} zIndex={10}>
        <Check size={18} marginRight={14} />
        {texts.savedMessage}
      </FlexBox>
    </Container>
  )
}
