import type {
  DrawEditableRoadSegmentInteraction,
  ModifyEditableRoadSegmentInteraction,
} from '../../../interactions/EditableRoadSegmentInteraction'

import { getMap } from '../../../atoms/map/utils/getMap'
import { findLayer } from '../../../layers/utils/findLayer'

export function resetUseDrawRoadSegment(
  drawRoadSegment: DrawEditableRoadSegmentInteraction,
  modifyRoadSegment: ModifyEditableRoadSegmentInteraction,
) {
  drawRoadSegment.setActive(false)
  modifyRoadSegment.setActive(false)

  // reset colors
  drawRoadSegment.updateColor('disabled')
  modifyRoadSegment.updateColor('disabled')

  // clear the temporary draw layer
  const layer = findLayer(getMap(), 'draw')
  layer.getSource()?.clear(true)
}
