import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectTerritory } from '@/features/domain/territory'

export function useCollectStartEndRoute() {
  const { mobileAppsTrackingType } = useSelector(selectTerritory)

  return useMemo(
    () => (mobileAppsTrackingType === 'ORDERSTEPS_AND_BOUND' ? 'yes' : 'no'),
    [mobileAppsTrackingType],
  )
}
