import { memo, useCallback } from 'react'
import { Star, Place } from '@mui/icons-material'

import { type RenderItemProps } from '@/components/StructuredVirtualList'
import {
  ListItem,
  ListItemAvatarRounded,
  ListItemContent,
  ListItemRow,
  ListItemTitle,
  ListItemVisibilityToggler,
} from '@/components/List'

import { useVisibility } from './useVisibility'

type Props = Extract<RenderItemProps<'places'>, { type: 'item' }>

export const PlaceItem = memo((props: Props) => {
  const { selected, item, onItemClick, onItemDoubleClick } = props

  const [visible, toggleVisibility] = useVisibility(item.id)

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])

  const address = (item.address ?? item.location.geoAddress).replace(/(,(\s?))/, ', ')

  return (
    <ListItem
      onClick={onClick}
      selected={selected}
      onDoubleClick={onDoubleClick}
      invisibleOnMap={!visible}
      avatar={
        <ListItemAvatarRounded textColor="#fff" color="#A38F84">
          <Star />
        </ListItemAvatarRounded>
      }
      right={<ListItemVisibilityToggler toggleVisibility={toggleVisibility} visible={visible} />}
    >
      <ListItemTitle>{item.name}</ListItemTitle>

      <ListItemRow testId="place-address-row">
        <ListItemContent icon={<Place />}>{address}</ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})
PlaceItem.displayName = 'PlaceItem'
