import type { ReactElement } from 'react'

import { Box, Typography } from '@mui/material'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  body: {
    whiteSpace: 'pre-line',
  },
})

interface Props {
  children: ReactElement | string
  label: string
}

/**
 * Generic read-only field. Used read-only mode in Notification Forms
 */
export function Field(props: Props) {
  const classes = useStyles()

  return (
    <Box paddingBottom={4} data-testid={props.label}>
      <Typography variant="caption" color="textSecondary">
        {props.label}
      </Typography>
      <Typography variant="body1" color="textPrimary" className={classes.body}>
        {props.children}
      </Typography>
    </Box>
  )
}
