import { PermissionData } from '../types'

export function computeBuildPermission(permissionData: PermissionData) {
  // If we're not in a simulation or in demo/dev mode, we need to check if there are routes in the past
  if (!permissionData.isDemoOrDev && !permissionData.isSimulation) {
    if (permissionData.routesInThePastCount === permissionData.routesCount)
      return 'disabledBecauseInThePast'
    else {
      if (permissionData.routesInThePastCount > 0) {
        return 'someRouteInThePast'
      }
    }
  }

  // If the range is out of the optimization horizon, we need to disable the button
  if (permissionData.rangeOutOfOptimizationHorizon) return 'disabledOutOfOptimizationRange'

  // If the range is bigger than the optimization horizon, we need to disable the button
  if (permissionData.rangeBiggerThanOptimizationHorizon)
    return 'disabledBiggerThanOptimizationHorizon'

  if (permissionData.allRoutesLocked) {
    return 'disabledAllRoutesLocked'
  }

  return 'enabled'
}
