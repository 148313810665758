import { mapAtom } from '../../atoms/map/map'
import { findLayer } from '../utils/findLayer'

import { changelogRemovePlaceFeatures } from './utils/changelogRemovePlaceFeatures'
import { changelogAddPlaceFeatures } from './utils/changelogAddPlaceFeatures'
import { changelogUpdatePlaceFeatures } from './utils/changelogUpdatePlaceFeatures'

export const createUpdatePlaceFeaturesFromChangelog = (
  markers: uui.domain.ui.map.markers.MapMarkers['place'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['place'],
  selection: Set<string>,
  changelog: uui.domain.api.Changelog,
) => {
  // ATTENTION: Currently all changelog are applied as soon as they arrive, also when the map is not visible
  // `ignoreSelection` it will be used to avoid to apply any selection information to the map when applying cached updater functions.
  // after all cached updates will be applied a refresh selection action will be required
  return async (_ignoreSelection: boolean = false) => {
    const map = mapAtom.map
    const layer = findLayer(map, 'place')

    // remove
    await changelogRemovePlaceFeatures(layer, selection, changelog.remove.values())

    // add
    await changelogAddPlaceFeatures(layer, markers, mapStyles, selection, changelog.add.values())

    // update
    await changelogUpdatePlaceFeatures(
      layer,
      markers,
      mapStyles,
      selection,
      changelog.update.values(),
    )
  }
}
