import type { LayerUID } from './typings'

import BaseLayer from 'ol/layer/Base'

type LayerMetadata = {
  uid: LayerUID
  // TODO: improve selection to be configurable
  selection: Set<unknown>
  editingEntities: Set<unknown>
}

export const setLayerMetadata = <Field extends keyof LayerMetadata>(
  target: BaseLayer,
  field: Field,
  value: LayerMetadata[Field],
) => {
  target.set(field, value)
}

export const getLayerMetadata = <Field extends keyof LayerMetadata>(
  target: BaseLayer,
  field: Field,
): LayerMetadata[Field] | undefined => {
  return target.get(field)
}

export const removeLayerMetadata = <Field extends keyof LayerMetadata>(
  target: BaseLayer,
  field: Field,
) => {
  target.unset(field)
}
