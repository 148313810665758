import BaseLayer from 'ol/layer/Base'

type OrderLayerMetadata = {
  selectedOrderIds: Set<unknown>
}

export const setOrderLayerMetadata = <Field extends keyof OrderLayerMetadata>(
  target: BaseLayer,
  field: Field,
  value: OrderLayerMetadata[Field],
) => {
  target.set(field, value)
}

export const getOrderLayerMetadata = <Field extends keyof OrderLayerMetadata>(
  target: BaseLayer,
  field: Field,
): OrderLayerMetadata[Field] | undefined => {
  return target.get(field)
}

export const removeOrderLayerMetadata = <Field extends keyof OrderLayerMetadata>(
  target: BaseLayer,
  field: Field,
) => {
  target.unset(field)
}
