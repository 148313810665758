import { useConvertTime } from '@/hooks'

interface Props {
  seconds: number
  showSeconds?: boolean
}

export function ConvertTime(props: Props) {
  const { seconds, showSeconds } = props
  const value = useConvertTime(seconds, showSeconds)

  return <>{value}</>
}
