import type { ReactElement } from 'react'

import { Component } from 'react'
import { Scrollbar } from '@workwave-tidal/tidal/components'

import { List } from '@/forms-legacy'

import { DropdownItem } from '../typings'
import Row from './Row'

export interface Props {
  searchText?: string
  height?: number
  RowIcon?: ReactElement
  values: DropdownItem[]
  selectedIds: string[]
  highlightedIndex: number
  onChange: List.ScrollableListItemChangeEventHandler<DropdownItem>
  onHover: List.ScrollableListItemHoverEventHandler<DropdownItem>
  rowClassName?: string
}

export default class Rows extends Component<Props> {
  render() {
    const {
      height = 100,
      RowIcon,
      values,
      onChange,
      onHover,
      highlightedIndex,
      selectedIds,
      rowClassName,
    } = this.props

    const rows = values.map((item: DropdownItem, idx: number) => (
      <Row
        key={idx}
        item={item}
        onChange={onChange}
        onHover={onHover}
        highlighted={highlightedIndex === idx}
        selected={selectedIds.includes(item.id)}
        RowIcon={RowIcon}
        className={rowClassName}
      />
    ))

    return (
      <div style={{ width: '100%', height }}>
        <Scrollbar hideOverflow="x">{rows}</Scrollbar>
      </div>
    )
  }
}
