import styled from 'styled-components'
import { InputFieldText } from '@/forms-legacy'

export const SearchField = styled(InputFieldText)`
  max-height: 26px;
  width: 165px;

  border-color: ${p => p.theme.colors.$pigmentGreen};
  background-color: ${p => p.theme.colors.$pigmentGreen};
  color: ${p => p.theme.colors.$pureWhite};

  .o-input-field-text__base-input {
    font-size: ${p => p.theme.fonts.$m};
    font-weight: ${p => p.theme.weights.$regular};
  }

  .o-input-field-text__base-input::placeholder {
    color: ${p => p.theme.colors.$pureWhite};
  }

  .o-input-field-text__extra-icon {
    flex: 0 0 auto;
    display: flex;
    width: 14px;
    opacity: 1;
    align-items: center;
  }

  .o-input-field-text__reset-button {
    top: ${p => (p.multi ? '18%' : '50%')};
    background: ${p => p.theme.colors.$pureWhite};
    color: ${p => p.theme.colors.$pigmentGreen};
  }
`
SearchField.displayName = 'SearchField'
