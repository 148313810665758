import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { H4, WarningCard } from '@/local/components'
import { Dropdown } from '@/components/Dropdown'
import { Spinner, WarningTriangle } from '@/icons'
import { Block } from '@/forms-legacy'

import { useTerritoryDropdownItems } from '../hooks/useTerritoryDropdownItems'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Body() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const {
    status,
    invalid,
    data: { territories, id },
  } = useController()
  const dropdownItems = useTerritoryDropdownItems(territories, id)

  return (
    <VerticalLayout marginTop={32} style={{ minHeight: 200 }}>
      {status !== 'failed' && (
        <>
          <Block>
            <H4 color="$paleSky" uppercase>
              {texts.territoryDropdownTitle}
            </H4>
          </Block>
          <Block>
            <HorizontalLayout alignItems="center">
              <Grow xAxis>
                <Dropdown
                  options={dropdownItems}
                  onChange={handlers.onTerritoryChange}
                  disabled={!dropdownItems || status !== 'ready' || invalid}
                  maxListHeight={180}
                />
              </Grow>
              <Spinner
                marginLeft={5}
                style={{ opacity: status === 'loading' ? 1 : 0 }}
                size={18}
                animation="spin"
              />
            </HorizontalLayout>
          </Block>
        </>
      )}
      {status === 'failed' && (
        <Block>
          <WarningCard
            title={texts.failedDataFetchingWarningCardTitle}
            description={texts.failedDataFetchingWarningCardDescription}
            preset="warning"
            Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
          />
        </Block>
      )}
    </VerticalLayout>
  )
}
