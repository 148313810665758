import type { Props } from './typings'

import { Block, Label, FieldMessage } from '@/forms-legacy'

import RestoreFieldButton from '../primitives/RestoreFieldButton'
import { DeviceIconList } from '../primitives/DeviceIconList'

export function DeviceIconListField(props: Props) {
  const {
    label,
    name,
    info,
    testid,
    wrappedInputProps,
    formProps: {
      input: { value, onChange },
      meta: { error, dirty, initial },
    },
  } = props

  return (
    <Block error={!!error} dirty={!!dirty} data-testid={testid} data-trackid={testid}>
      <Label htmlFor={name}>
        {label}
        <RestoreFieldButton
          dirty={dirty}
          initial={initial}
          onChange={onChange}
          style={{ marginLeft: 12 }}
        />
      </Label>

      <DeviceIconList {...wrappedInputProps} onChange={onChange} value={value} />

      {!!error && <FieldMessage error>{error}</FieldMessage>}
      {!error && !!info && <FieldMessage>{info}</FieldMessage>}
    </Block>
  )
}
