import { Box, Skeleton } from '@mui/material'

import { VerticalLayout, HorizontalLayout } from '@/components/layout'
import { Text } from '@/local/components'

import { type GpsInfoWithColor } from '../../../../../types'
import { useTexts } from '../useTexts'
import { VehicleTag } from './VehicleTag'

interface Props {
  vehicle: uui.domain.client.UnifiedVehicle
  point: GpsInfoWithColor
}

export function VehicleInfo(props: Props) {
  const { vehicle, point } = props

  const texts = useTexts()

  if (!vehicle.hasRoutingLicense) return null

  return (
    <Box minWidth={260} height={46}>
      <VerticalLayout justifyContent="space-between">
        <Text weight="$semiBold">{`${vehicle.vehicle.externalId} - ${texts.breadcrumbPoint}`}</Text>

        <HorizontalLayout alignItems="center">
          <VehicleTag vehicle={vehicle} />
          <Box marginLeft={1}>
            {point.address ? (
              <Text size="$s" italic>
                {point.address}
              </Text>
            ) : (
              <Skeleton width={200} height={29} animation="wave" />
            )}
          </Box>
        </HorizontalLayout>
      </VerticalLayout>
    </Box>
  )
}
