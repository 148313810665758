import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    add: translate({ id: 'global.add' }),
    any: translate({ id: 'global.any' }),
    type: (type: 'planner' | 'viewer' | 'guest' | 'courier') => {
      switch (type) {
        case 'planner':
          return translate({ id: 'settings.userManagement.listUsers.filter.type.planner' })
        case 'viewer':
          return translate({ id: 'settings.userManagement.listUsers.filter.type.viewer' })
        case 'guest':
          return translate({ id: 'settings.userManagement.listUsers.filter.type.guest' })
        case 'courier':
          return translate({ id: 'settings.userManagement.listUsers.filter.type.courier' })
      }
    },
    users: translate({ id: 'settings.userManagement.listUsers.title' }),
    addUser: translate({ id: 'settings.userManagement.listUsers.addUser' }),
    clearAll: translate({ id: `settings.userManagement.listUsers.filter.clearAll` }),
    filterPlaceholder: translate({ id: `settings.userManagement.listUsers.filter.placeholder` }),
  }))

  return api
}
