import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    trackingOption: translate({ id: 'vehicles.form.generalSettings.trackingOption.info' }),
    info: translate({ id: 'vehicles.form.generalSettings.trackingOption.info.button' }),
  }))

  return api
}
