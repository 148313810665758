import { Box, styled } from '@mui/material'

export const AppContainer = styled(Box, { name: 'AppContainer' })({
  position: 'relative',
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  margin: 0,
  padding: 0,
})
