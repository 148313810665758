import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Search(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M21 3C11.60156 3 4 10.60156 4 20s7.60156 17 17 17c3.35547 0 6.46094-.98438 9.09375-2.65625L42.375 46.625l4.25-4.25L34.5 30.28125C36.6797 27.42187 38 23.8789 38 20c0-9.39844-7.60156-17-17-17zm0 4c7.19922 0 13 5.80078 13 13s-5.80078 13-13 13S8 27.19922 8 20 13.80078 7 21 7z" />
      </svg>
    </IconContainer>
  )
}
