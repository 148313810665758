import { Entry } from '../Entry'

export function Entry20210222() {
  return (
    <Entry title="02/22/2021 - v3.0.0-alpha-5">
      <ul>
        <li>The Order markers are rendered</li>
        <li>
          The markers can be selected (selection on huge number of markers are not optimized yet)
        </li>
        <li>The markers can be selected through the Lasso</li>
        <li>Freezing the map works</li>
        <li>Fitting the map works</li>
        <li>The lists scroll when a marker on the map is selected</li>
        <li>Fix changing the calendar range</li>
        <li>Improve Scheduler performance</li>
        <li>Load simulations list on load</li>
        <li>Open the new simulation once created</li>
        <li>Highlight days in the calendar</li>
        <li>Logout works as expected</li>
        <li>Fix starting the app with an unexisting Simulation</li>
        <li>The territories are listed in the dropdown when they are a few</li>
        <li>Port RM10138 </li>
        <li>Port RM10180 </li>
      </ul>
    </Entry>
  )
}
