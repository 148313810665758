import type { DaysByMonth, Props } from './typings'
import type { DayProps } from '@/components/Calendar/typings'

import { useMemo } from 'react'
import {
  eachDayOfInterval,
  format,
  isSameDay,
  addDays,
  getDay,
  isAfter,
  isBefore,
  subDays,
} from 'date-fns/esm'

import { useDateFnsOptions } from '@/hooks'

import { parseDate } from '../../../../utils/parseDate'

import { getSelectableInterval } from '../../utils/getSelectableInterval'
import { matchHighlightProducer } from '../../matchHighlightProducer'
import { getRoundedBorder } from './getRoundedBorder'
import { getSelectionData } from './getSelectionData'
import { getCosmetic } from './getCosmetic'

const emptyDay = { type: 'empty' } as const

export function useIntervalDays(props: Props): DaysByMonth {
  const dateFnsOptions = useDateFnsOptions()
  const {
    today,
    months,
    selection,
    hoveredDay,
    disabledDays,
    intervalLength,
    highlightToday,
    selectionStart,
    edgeOfSelection,
    fixedRangeStart,
    tooltipIntlText,
    lastSelectableDate,
    firstNonArchivedDay,
    highlightedDays = {},
  } = props

  return useMemo<DaysByMonth>(() => {
    const todayDate = parseDate(today)

    const firstNonArchivedDayDate = !!firstNonArchivedDay
      ? parseDate(firstNonArchivedDay)
      : undefined

    const daysByMonth: DaysByMonth = {}

    const selectableInterval = hoveredDay
      ? getSelectableInterval(hoveredDay, intervalLength, lastSelectableDate)
      : []

    for (const month of months) {
      const { offset, start, end, id: monthId } = month

      const dates = eachDayOfInterval({
        start,
        end,
      })

      const days = dates.map<DayProps>((date, index, values) => {
        const nextDayAsString = format(addDays(date, 1), 'yyyyMMdd')
        const dateAsString = format(date, 'yyyyMMdd')

        const disabled = !!disabledDays && !!disabledDays[dateAsString]
        const isToday = isSameDay(todayDate, date)

        const noSelectableRange = selectableInterval.length === 0
        const ghosted = noSelectableRange
          ? false
          : (isAfter(date, selectableInterval[0]) || isSameDay(date, selectableInterval[0])) &&
            (isBefore(date, selectableInterval[1]) || isSameDay(date, selectableInterval[1]))

        const firstOfGhosted = noSelectableRange ? false : isSameDay(date, selectableInterval[0])
        const lastOfGhosted = noSelectableRange ? false : isSameDay(date, selectableInterval[1])

        const dayNumber = getDay(date)

        const { selected, isOnTheEdge, isSelectionStart, isSelectionEnd } = getSelectionData({
          date,
          selection,
          dateAsString,
          selectionStart,
          nextDayAsString,
          edgeOfSelection,
        })

        const cosmetic = getCosmetic({
          isToday,
          disabled,
          selected,
          isOnTheEdge,
          dateAsString,
          highlightToday,
          ghosted,
        })

        const roundedBorder = getRoundedBorder({
          index,
          values,
          ghosted,
          selected,
          dayNumber,
          dateFnsOptions,
          firstOfGhosted,
          lastOfGhosted,
        })

        const secondaryRangeStartRepresentation =
          !isSelectionEnd && isSelectionStart && fixedRangeStart

        const isHighlighted = matchHighlightProducer(highlightedDays)(dateAsString)
        return {
          date,
          isToday,
          disabled,
          cosmetic,
          type: 'day',
          bold: isToday,
          roundedBorder,
          id: dateAsString,
          underline: isToday,
          dot: isHighlighted,
          selectionEnd: isSelectionEnd,
          selectionStart: isSelectionStart,
          ghostSelectionEnd: lastOfGhosted,
          secondaryRangeStartRepresentation,
          ghostSelectionStart: firstOfGhosted,
          label: format(date, 'd', dateFnsOptions),
          firstOfRow: dayNumber === dateFnsOptions.weekStartsOn,
          leftSeparator: firstNonArchivedDayDate && isSameDay(firstNonArchivedDayDate, date),
          tooltipText: isHighlighted ? tooltipIntlText?.(highlightedDays[dateAsString]) : undefined,
        }
      })

      const firstDay = days[0]
      const lastDay = days[days.length - 1]
      daysByMonth[monthId] = []

      // Let's calculate empty days before the first day paying attention if is the first of the row
      for (let i = offset[0]; i > 0; i--) {
        daysByMonth[monthId].unshift({
          ...emptyDay,
          firstOfRow: getDay(subDays(firstDay.date, i)) === dateFnsOptions.weekStartsOn,
        })
      }

      daysByMonth[monthId].push(...days)

      // Let's calculate empty days after the last day paying attention if is the first of the row
      for (let i = 0; i < offset[1]; i++) {
        daysByMonth[monthId].push({
          ...emptyDay,
          firstOfRow: getDay(subDays(lastDay.date, i)) === dateFnsOptions.weekStartsOn,
        })
      }
    }

    return daysByMonth
  }, [
    firstNonArchivedDay,
    lastSelectableDate,
    tooltipIntlText,
    highlightedDays,
    edgeOfSelection,
    fixedRangeStart,
    dateFnsOptions,
    selectionStart,
    highlightToday,
    intervalLength,
    disabledDays,
    hoveredDay,
    selection,
    months,
    today,
  ])
}
