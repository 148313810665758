import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehiclesMapStyles } from '@/features/domain/ui'
import { useVehiclesNavigator } from './useVehiclesNavigator'

export function useStylerData() {
  const mapStyles = useSelector(selectVehiclesMapStyles)
  const { currentVehicleIds } = useVehiclesNavigator()

  /**
   * This methods retrieve the correct active style for the current vehicle(s).
   * If there are different styles for the chosen vehicles it will return undefined.
   */
  const activeMapStyle = useMemo(() => {
    const appliedStyles = new Set<'on' | 'off' | 'label'>()

    // Using a Set allows to not create duplicated values
    currentVehicleIds.forEach(id => appliedStyles.add(mapStyles.custom[id] ?? mapStyles.mode))

    // If the Set is composed of just one element, means that the style is the same for all vehicles
    return appliedStyles.size === 1 ? appliedStyles.values().next().value : undefined
  }, [mapStyles, currentVehicleIds])

  return {
    mapStyles,
    activeMapStyle,
    currentVehicleIds,
  }
}
