import { useEffect } from 'react'

import { selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { useQueryParameters } from '@/hooks'
import { setCrudSelection } from '@/atoms'
import { store } from '@/store'

export function useQueryParameterSelection() {
  const { vehicleId } = useQueryParameters()

  useEffect(() => {
    if (!vehicleId) return

    const vehiclesByVehicleId = selectVehiclesByVehicleId(store.getState())
    const uvId = vehiclesByVehicleId[vehicleId]
    if (!uvId) {
      console.error(`Vehicle with id ${vehicleId} not found`)
    }

    setCrudSelection('unifiedVehicles', [uvId])
  }, [vehicleId])
}
