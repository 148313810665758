import { GpsReportsCookieData } from '../types'
import { getGpsReportsCookie } from './cookie'

/**
 * check if cookie expired
 * if not parse gpsReports cookie
 * @returns Data if no errors false instead
 */
export function parseGpsReportsCookie() {
  try {
    const rawGpsReportsImportData = getGpsReportsCookie()
    if (!rawGpsReportsImportData) {
      return undefined
    }

    const cookieData = JSON.parse(atob(rawGpsReportsImportData))

    if (!isGpsReportsCookieData(cookieData)) {
      console.warn(`The cookie does not contain valid GpsReports cookie data`)
      console.log(cookieData)
      return undefined
    }

    return cookieData
  } catch (e) {
    console.warn(e)
    return undefined
  }
}

function isGpsReportsCookieData(data: any): data is GpsReportsCookieData {
  const gpsReportsCookieData = data as GpsReportsCookieData

  return !!gpsReportsCookieData.territoryId
}
