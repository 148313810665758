import { useCallback } from 'react'
import { Typography, Stack, Box, Button } from '@mui/material'
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material'

import {
  fitMap,
  setVoiceEventMarker,
  computeVoiceEventId,
  useVisibleVoiceEventMarkerId,
} from '@/map'

import { geo, gis } from '@/server-data'

import { Tooltip } from '@/components/primitives/Tooltip'
import { useTexts } from './hooks/useTexts'
import { Duration } from './components/Duration'
import { useDriverName } from './hooks/useDriverName'
import { useFormattedDate } from './hooks/useFormattedDate'

interface Props {
  item: uui.domain.client.rm.OrderStepVoiceCall
}

export function VoiceCallNotificationItem(props: Props) {
  const { item } = props
  const { startTs, driverId, driverPhoneNumber } = item

  const visibleMarkerId = useVisibleVoiceEventMarkerId()
  const formattedDate = useFormattedDate(startTs)
  const driverName = useDriverName(driverId)
  const texts = useTexts()

  const markerId = computeVoiceEventId(item)
  const visible = markerId === visibleMarkerId

  const invalidLocation =
    !item.startLatLng || (item.startLatLng[0] === 0 && item.startLatLng[1] === 0)

  const showVoiceCallMarker = useCallback(() => {
    if (visible) {
      setVoiceEventMarker({
        event: null,
      })

      return
    }

    const latLng = geo.latLngFromJSON(item.startLatLng)
    const lonLat = gis.fromLatLngToCoordinate(latLng)

    setVoiceEventMarker({
      event: {
        id: markerId,
        latLng,
        lonLat,
        visible: true,
      },
    })

    fitMap([latLng], { minResolution: 3, preventIfVisible: true }, 'clear')
  }, [item, visible, markerId])

  return (
    <Stack spacing={2} width="100%">
      <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          {texts.title}
        </Typography>
        <Duration durationSec={item.durationSec} />
      </Stack>

      <Stack spacing={1} direction="row" alignItems="center">
        <AccountCircleIcon color="action" sx={{ fontSize: '32px' }} />
        <Stack spacing={0}>
          <Typography variant="subtitle2" color="text.secondary" sx={{ lineHeight: 1.2 }}>
            {texts.byDriver(driverName, driverPhoneNumber)}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" sx={{ lineHeight: 1.2 }}>
            {formattedDate}
          </Typography>
        </Stack>
      </Stack>

      <Box width="100%" display="flex">
        <Tooltip placement="top" title={texts.noGpsDataAvailable} disabled={!invalidLocation}>
          <Button
            variant="contained"
            size="small"
            disabled={invalidLocation}
            onClick={showVoiceCallMarker}
          >
            {visible ? texts.hide : texts.show}
          </Button>
        </Tooltip>
      </Box>
    </Stack>
  )
}
