import type { FormFields, FormErrors } from '../formFields'

import { Stack } from '@mui/material'
import { Checkbox, TextField } from '@workwave-tidal/tidal/form-ui'

import { useTexts } from '../../hooks/useTexts'
import { useUpdateEmailRelatedFields } from '../hooks/useUpdateEmailRelatedFields'

export function CompanyEmail() {
  const texts = useTexts()
  useUpdateEmailRelatedFields()

  return (
    <Stack>
      <TextField<'companyEmail', FormFields, FormErrors>
        name="companyEmail"
        testId="companyEmail"
        label={texts.companyEmail}
        validateOn="blur focus"
      />

      <Checkbox<'showContactEmail', FormFields, FormErrors>
        name="showContactEmail"
        testId="showContactEmail"
        label={texts.showContactEmail}
        validateOn="blur focus"
      />

      <Checkbox<'useCompanyEmailAsReplyTo', FormFields, FormErrors>
        name="useCompanyEmailAsReplyTo"
        testId="useCompanyEmailAsReplyTo"
        label={texts.useCompanyEmailAsReplyTo}
        validateOn="blur focus"
      />
    </Stack>
  )
}
