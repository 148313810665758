import { intl } from '@/intl'

export function validateColor(
  value: uui.domain.ui.forms.GpsVehicleFormValues['gps']['color'],
): string | undefined {
  if (!value.color) {
    return intl.translate({
      id: 'vehicles.form.edit.generalSettings.validation.color.notSet',
    })
  }
}
