import { type EventTooltipConfig } from '@bryntum/schedulerpro'
import { renderToStaticMarkup } from 'react-dom/server'

import { selectUserConfiguration } from '@/features/domain/user'
import { store } from '@/store'

import { EventTooltip } from './components/EventTooltip'

export const template: EventTooltipConfig['template'] = e => {
  const userConfig = selectUserConfiguration(store.getState())
  return renderToStaticMarkup(<EventTooltip eventRecord={e.eventRecord} userConfig={userConfig} />)
}
