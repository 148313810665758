import { formatISO } from 'date-fns/esm'
import { getDateInTimezone } from '../timezone/internal/timezone'

type Options = Parameters<typeof formatISO>[1]
export const createISOString =
  (userConfig: uui.domain.UserConfiguration) =>
  (source: uui.domain.AcceptedDate, options?: Options) => {
    const { transitions } = userConfig

    const date = getDateInTimezone(source, transitions)
    return formatISO(date, options)
  }

export const createDate =
  (userConfig: uui.domain.UserConfiguration) => (source: uui.domain.AcceptedDate) => {
    const { transitions } = userConfig

    return getDateInTimezone(source, transitions)
  }

export type GpsTimezone = (typeof gpsValidTimezones)[number]

export const gpsValidTimezones = [
  'America/Puerto_Rico',
  'Asia/Dubai',
  'Asia/Kolkata',
  'Asia/Qatar',
  'Asia/Shanghai',
  'Asia/Tokyo',
  'Australia/Sydney',
  'Canada/Atlantic',
  'Europe/London',
  'Europe/Moscow',
  'Europe/Rome',
  'US/Alaska',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'UTC',
] as const
