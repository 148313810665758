import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectTerritoryNotificationBehavior } from '@/features/domain/territory'
import { useNavigate } from '@/routing'

/**
 * Redirects the user to the depots page if the notificationBehavior is 'NO_NOTIFICATIONS'
 * Useful when the user is on the notification page and changes territory
 */
export function useRedirectOnNotificationDisabled() {
  const notificationBehavior = useSelector(selectTerritoryNotificationBehavior)
  const navigate = useNavigate<uui.routing.MainSections>()

  useEffect(() => {
    if (notificationBehavior === 'NO_NOTIFICATIONS') {
      navigate('setup')
    }
  }, [navigate, notificationBehavior])
}
