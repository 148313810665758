import { FooterErrorItem } from '@/forms-legacy'

import { ExtendedOrderValidation } from '../typings'

import getOrderStepFieldsWithError from './getOrderStepFieldsWithError'
import getOrderFieldsWithError from './getOrderFieldsWithError'

export default function getFieldsWithError(
  errors: ExtendedOrderValidation,
  orderType: uui.domain.client.rm.OrderIdentifier,
  pickupAndDeliveryFromStart: boolean,
): FooterErrorItem[] {
  const fieldsWithError: FooterErrorItem[] = []
  const showHeaders = orderType === 'pd' && !pickupAndDeliveryFromStart

  if (errors.order) {
    fieldsWithError.push(...getOrderFieldsWithError(errors.order, showHeaders))
  }

  if (errors.pickup) {
    fieldsWithError.push(...getOrderStepFieldsWithError(errors.pickup, showHeaders, 'p'))
  }

  if (errors.delivery) {
    fieldsWithError.push(...getOrderStepFieldsWithError(errors.delivery, showHeaders, 'd'))
  }

  return fieldsWithError
}
