import { HorizontalLayout } from '@/components/layout'
import { H2 } from '@/local/components'

import { ClickableContainer } from './components/ClickableContainer'
import { ShrinkableBlock } from './components/ShrinkableBlock'
import { UnShrinkableBlock } from './components/UnShrinkableBlock'

interface Props {
  text: string
  value: string | number
  onClick?: () => void
  disabled?: boolean
  testId?: string
}

export function Row(props: Props) {
  const { text, value, onClick, disabled, testId } = props

  return (
    <ClickableContainer
      onClick={onClick}
      disabled={!onClick || disabled}
      data-testid={testId}
      data-trackid={testId}
    >
      <HorizontalLayout
        height={24}
        flexWrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <ShrinkableBlock>
          <H2 ellipsis>{text}</H2>
        </ShrinkableBlock>
        <UnShrinkableBlock>
          <H2 style={{ paddingLeft: 6 }} textAlign="right">
            {value}
          </H2>
        </UnShrinkableBlock>
      </HorizontalLayout>
    </ClickableContainer>
  )
}
