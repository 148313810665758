import type { ImageLayout } from '../typings'
import styled from 'styled-components'

export interface Props extends workwave.Div {
  layout?: ImageLayout
}

const ReadonlyImageWrapper = styled.div<Props>`
  width: ${p => (p.layout === 'detail' ? '100%' : '70px')};
  flex: 0 0 auto;
`

ReadonlyImageWrapper.displayName = 'ReadonlyImageWrapper'
export default ReadonlyImageWrapper
