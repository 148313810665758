import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'newVersionAvailablePage.title' }),
    description: translate({ id: 'newVersionAvailablePage.description' }),
    primaryAction: translate({ id: 'newVersionAvailablePage.update' }),
  }))

  return api
}
