import { CircularProgress } from '@mui/material'

import { HorizontalLayout } from '@/components/layout'
import { ShowLabelVehicle, ShowVehicle, HideVehicle } from '@/icons'

import { NavigoIconButton } from '../../../../../../elements/NavigoIconButton'
import { useStylerData } from '../../../../hooks/useStylerData'

import { useActions } from '../../hooks/useActions'

import { ButtonsContainer } from './components/ButtonsContainer'
import { useTexts } from './useTexts'

interface Props {
  deviceIds: string[]
}

export function Styler(props: Props) {
  const { deviceIds } = props

  const { activeMapStyle } = useStylerData()
  const { methodInExecution, actions } = useActions(deviceIds)
  const texts = useTexts()

  return (
    <HorizontalLayout
      data-trackid="navigo-device-mapStyles-styler"
      data-testid="navigo-device-mapStyles-styler"
      justifyContent="start"
      height={42}
    >
      <ButtonsContainer>
        <NavigoIconButton
          text={texts.show}
          onClick={actions.showDeviceLabels}
          active={activeMapStyle === 'label'}
          Icon={
            methodInExecution === 'showDeviceLabels' ? (
              <CircularProgress size={18} color="primary" />
            ) : (
              <ShowLabelVehicle size={16} />
            )
          }
          testid="navigo-device-mapStyles-showDeviceLabelsButton"
        />

        <NavigoIconButton
          text={texts.reduce}
          onClick={actions.showDevices}
          active={activeMapStyle === 'on'}
          Icon={
            methodInExecution === 'showDevices' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <ShowVehicle size={16} />
            )
          }
          testid="navigo-device-mapStyles-showDevicesButton"
        />

        <NavigoIconButton
          text={texts.hide}
          onClick={actions.hideDevices}
          active={activeMapStyle === 'off'}
          Icon={
            methodInExecution === 'hideDevices' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <HideVehicle size={16} />
            )
          }
          testid="navigo-device-mapStyles-hideDevicesButton"
        />
      </ButtonsContainer>
    </HorizontalLayout>
  )
}
