import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectDrivers as selectDriversSelector } from '@/features/domain/driver'

export const useDriversRoot = () => {
  const { editing } = useEditingStateWithCtx('driver')
  const [selection] = useCrudSelection('drivers')
  const drivers = useSelector(selectDriversSelector)
  const selectedDrivers = useMemo(
    () => getSelectedDrivers(selection, drivers),
    [selection, drivers],
  )

  return {
    drivers,
    selectedDrivers,
    editing,
    selection,
  }
}

const getSelectedDrivers = (
  selection: workwave.DeepReadonly<string[]>,
  driversMap: Record<string, uui.domain.client.rm.ExtendedDriver>,
): uui.domain.client.rm.ExtendedDriver[] => {
  // selection could hold some ids that are not in the driversMap due to server-data computation
  // driversMap holds the "truth"
  return selection.reduce<uui.domain.client.rm.ExtendedDriver[]>((acc, id) => {
    if (driversMap[id]) {
      acc.push(driversMap[id])
    }
    return acc
  }, [])
}
