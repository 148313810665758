import { proxy } from 'valtio'

export type ExecutionEventMarkerType = 'pod' | 'done' | 'reschedule' | '1' | '2' | '3' | '4'

export type ExecutionEventMarker = {
  type: 'done' | 'reschedule' | '1' | '2' | '3' | '4'
  latLng: uui.domain.LatLng
  lonLat: [lon: number, lat: number]
}

export type PODMarker = {
  type: 'pod'
  uid: string
  latLng: uui.domain.LatLng
  lonLat: [lon: number, lat: number]
}

export type ExecutionEventMarkers = {
  visible: boolean
  pod: PODMarker | null
  events: ExecutionEventMarker[]
}

export const executionEventMarkers = proxy<ExecutionEventMarkers>({
  pod: null,
  events: [],
  visible: false,
})
