import type { ReactNode, ReactElement } from 'react'

import { useRef } from 'react'

import { SubMenu } from './components/SubMenu'
import { Trigger } from './components/Trigger'

import { useHandlers } from './hooks/useHandlers'

interface Props {
  parentOpen: boolean
  Icon?: ReactElement
  children?: ReactNode
  disabled?: boolean
  fontSize?: number
  placement?: 'left' | 'right'
  fontWeight?: number
  tooltipTitle?: string
  triggerContent?: ReactNode
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right'
}

/**
 * Use as a drop-in replacement for `<MenuItem>` when you need to add cascading
 * menu elements as children to this component.
 */
export function NestedDropdown(props: Props) {
  const {
    Icon,
    children,
    fontSize,
    fontWeight,
    parentOpen,
    tooltipTitle,
    triggerContent,
    tooltipPlacement,
    disabled = false,
    placement = 'right',
  } = props

  const menuItemRef = useRef<HTMLLIElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { subMenuOpen, onMouseEnter, onClose } = useHandlers(containerRef, parentOpen)

  return (
    <div
      ref={containerRef}
      onMouseEnter={disabled ? undefined : onMouseEnter}
      onMouseLeave={disabled ? undefined : onClose}
    >
      <Trigger
        open={subMenuOpen}
        Icon={Icon}
        disabled={disabled}
        fontSize={fontSize}
        fontWeight={fontWeight}
        anchorElRef={menuItemRef}
        tooltipTitle={tooltipTitle}
        tooltipPlacement={tooltipPlacement}
      >
        {triggerContent}
      </Trigger>

      <SubMenu
        onClose={onClose}
        open={subMenuOpen}
        placement={placement}
        anchorEl={menuItemRef.current}
      >
        {children}
      </SubMenu>
    </div>
  )
}
