import { useSendMessageToDriverModal } from '@/components/modals/SendMessageToDriverModal'

export const useModalActions = () => {
  const { show: showSendMessageToDriverModal, Modal: SendMessageToDriverModal } =
    useSendMessageToDriverModal()

  return {
    modalActions: {
      showSendMessageToDriverModal,
    },
    modals: (
      <>
        <SendMessageToDriverModal />
      </>
    ),
  }
}
