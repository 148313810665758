import { type CSSProperties, type ReactNode } from 'react'
import { clsx } from '@/utils'

type Props = {
  fontSize?: 10 | 12 | 13
  fontWeight?: 'normal' | 'bold'
  noWrap?: boolean
  color?: string
  children?: ReactNode
  style?: CSSProperties
}

export function Typography(props: Props) {
  const { children, fontSize, color, noWrap, fontWeight = 'normal', style } = props

  const className = clsx('o-scheduler-typography', {
    'o-scheduler-typography--no-wrap': noWrap,
    'o-scheduler-typography--font-size-10': fontSize === 10,
    'o-scheduler-typography--font-size-12': fontSize === 12,
    'o-scheduler-typography--font-size-13': fontSize === 13,
    'o-scheduler-typography--font-weight-bold': fontWeight === 'bold',
  })

  return (
    <p className={className} style={{ '--color': color, ...style } as CSSProperties}>
      {children}
    </p>
  )
}
