import type { IconContainerProps } from './typings'
import { ValidColor, theme } from '@/local/components'

import { IconContainer } from './elements/IconContainer'

type Props = IconContainerProps & {
  background: ValidColor
}

export function Completed(props: Props) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <circle cx="8" cy="8" r="8" fill={theme.colors[props.background]} />
        <path
          fill={props.color ? theme.colors[props.color] : 'currentColor'}
          d="M11.22 4.995l.739.77a.4.4 0 01-.013.565l-5.35 5.128-2.542-2.436a.4.4 0 01-.013-.565l.738-.77a.4.4 0 01.566-.012l1.25 1.198 4.06-3.89a.4.4 0 01.566.012z"
        />
      </svg>
    </IconContainer>
  )
}
