import type { FormError, FormField } from '@workwave-tidal/tidal/form-fairy'
import { Box, Button } from '@mui/material'

import { LoginFormContainer } from '@workwave-tidal/login-ui/LoginFormContainer'
import { TidalThemeProvider } from '@workwave-tidal/tidal/styles'

import { ResetPasswordForm } from '@workwave-tidal/login-ui'
import { RouteManager } from '@workwave-tidal/icons/logos'

import { useQueryParameters, useStandardPasswordValidations } from '@/hooks'
import { AppContent, AppContainer } from '@/components/layout'

import { useNavigate } from '@/routing'

import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'

const boxStyle = { height: 420 }
const logoStyle = { marginBottom: 2 }
const backToLoginButtonStyle = { textTransform: 'uppercase', padding: 1 }
const containerStyle = {
  margin: '0 auto',
  height: '100%',
  display: 'flex',
  padding: '0 20px',
  maxWidth: '400px',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}

type ResetPasswordFormFields = {
  newPassword: FormField<string>
  repeatPassword: FormField<string>
}

type ResetPasswordFormErrors = FormError<keyof ResetPasswordFormFields>

export function ResetPassword() {
  const { validations, validationSummaryItems } = useStandardPasswordValidations<
    ResetPasswordFormFields,
    ResetPasswordFormErrors
  >('newPassword')
  const { resetPassword: token } = useQueryParameters()

  const { onGoToLogin, onResetPassword } = useActions(token)
  const navigate = useNavigate()

  const texts = useTexts()

  if (!token) {
    navigate('/error/invalid-reset-password-token')
  }

  return (
    <AppContainer>
      <AppContent>
        <Box sx={containerStyle}>
          <TidalThemeProvider>
            <LoginFormContainer
              logo={<RouteManager width={340} sx={logoStyle} />}
              title={texts.resetPassword.logo}
              form={
                <ResetPasswordForm
                  style={boxStyle}
                  texts={texts.resetPassword}
                  testId="login-form"
                  mode="single-password"
                  onSubmit={onResetPassword}
                  validations={validations}
                  validationSummaryItems={validationSummaryItems}
                />
              }
              actions={
                <Box paddingLeft={1} paddingRight={1} width="100%">
                  <Button
                    sx={backToLoginButtonStyle}
                    size="large"
                    color="primary"
                    onClick={onGoToLogin}
                    fullWidth
                    data-testid="back-to-login-button"
                  >
                    {texts.backToLogin}
                  </Button>
                </Box>
              }
            />
          </TidalThemeProvider>
        </Box>
      </AppContent>
    </AppContainer>
  )
}
