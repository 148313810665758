import type { RadioGroupOption } from '@/formUi'

import { useMemo } from 'react'

import { useTexts } from '../../hooks/useTexts'

export function useTimeFormatOptions() {
  const texts = useTexts()

  // this options will never change
  return useMemo<RadioGroupOption<uui.domain.client.rm.NotificationConfig['timeFormat']>[]>(() => {
    return [
      {
        value: 'H12',
        label: texts.timeFormatExample('H12'),
      },
      {
        value: 'H24',
        label: texts.timeFormatExample('H24'),
      },
    ]
  }, [texts])
}
