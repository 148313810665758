import styled from 'styled-components'

const ReadonlyAudioTextTitle = styled.p.attrs<workwave.P>(() => ({
  className: 'o-readonly-audio__text-title',
}))`
  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
`

ReadonlyAudioTextTitle.displayName = 'ReadonlyAudioTextTitle'
export default ReadonlyAudioTextTitle
