import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    findOnMap: translate({ id: 'gps.eventslist.view.actions.findOnMap' }),
    noLocationButtonTitle: translate({ id: 'gps.eventslist.view.noLocationButtonTitle' }),
    openGeofence: translate({ id: 'gps.eventslist.view.actions.openGeofence' }),
    geofenceNoLongerExists: translate({ id: 'gps.eventslist.view.geofenceNoLongerExists' }),
    invalidateEvent: translate({
      id: 'gps.eventslist.view.actions.invalidateEvent',
    }),
  }))

  return api
}
