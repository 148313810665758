import type { SegmentType } from '../../../types'
import type { RoadSegmentFormValues } from '../types'

export function computeFormInitialValues(
  roadSegment: uui.domain.client.rm.SegmentException,
  segmentType: SegmentType,
): RoadSegmentFormValues {
  const { id, name, additionalDrivingTimeSec, startPoint, endPoint } = roadSegment

  if (startPoint.lat === 0 && startPoint.lng === 0 && endPoint.lat === 0 && endPoint.lng === 0) {
    return {
      id,
      name,
      additionalDrivingTimeSec,
      type: segmentType.type,
    }
  }

  return {
    id,
    name,
    additionalDrivingTimeSec,
    type: segmentType.type,
    editableSegment:
      startPoint && endPoint
        ? {
            start: startPoint,
            end: endPoint,
          }
        : undefined,
  }
}
