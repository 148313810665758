import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    newAlert: translate({ id: 'gps.alerts.edit.fields.newAlert' }),
    validationMissingName: translate({ id: 'gps.alerts.edit.fields.validation.missingName' }),
    validateNotificationAddress: translate({
      id: 'gps.alerts.edit.fields.validation.notificationAddress',
    }),
    validationDeviceRequired: translate({
      id: 'gps.alerts.edit.fields.validation.deviceRequired',
    }),
    labelName: translate({ id: 'gps.alerts.edit.fields.nameTitle' }),
    labelDeviceIds: translate({ id: 'gps.alerts.edit.fields.devicesList.monitoredVehicles' }),
    labelNotificationAddresses: translate({ id: 'gps.alerts.alertContacts' }),

    headerTitle: translate({ id: 'gps.alerts.edit.header.title' }),
    headerDescription: translate({ id: 'gps.alerts.edit.header.description' }),
  }))

  return api
}
