import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    title: (count: number, days: string) =>
      translate({ id: 'modals.exportRoutesFromCalendar.header.title', values: { count, days } }),

    filterTitle: translate({ id: 'modals.exportRoutesFromCalendar.labels.filter' }),
    filterDescription: translate({
      id: 'modals.exportRoutesFromCalendar.labels.filter.description',
    }),
    formatTitle: translate({ id: 'modals.exportRoutesFromCalendar.labels.format' }),
    cancelButton: translate({ id: 'modals.exportRoutesFromCalendar.button.cancel' }),
    exportButton: translate({ id: 'modals.exportRoutesFromCalendar.button.export' }),

    genericExportRoutesFromCalendarError: translate({
      id: 'modals.exportRoutesFromCalendar.errors.generic',
    }),
    includePod: translate({ id: 'modals.exportRoutesFromCalendar.labels.includePod' }),
    includeBarcode: translate({ id: 'modals.exportRoutesFromCalendar.labels.includeBarcode' }),
    invalidDataSimulationEnvTitle: translate({
      id: 'modals.exportRoutesFromCalendar.invalidData.simulation.title',
    }),
    invalidDataSimulationEnvDesc: translate({
      id: 'modals.exportRoutesFromCalendar.invalidData.simulation.description',
    }),
    invalidDataRoutesEmptyTitle: translate({
      id: 'modals.exportRoutesFromCalendar.invalidData.routesEmpty.title',
    }),
    invalidDataRoutesEmptyHeaderTitle: (count: number) =>
      translate({
        id: 'modals.exportRoutesFromCalendar.invalidData.routesEmpty.header.title',
        values: { count },
      }),
    invalidDataRoutesEmptyDesc: (count: number) =>
      translate({
        id: 'modals.exportRoutesFromCalendar.invalidData.routesEmpty.description',
        values: { count },
      }),
    printButton: translate({ id: 'modals.exportRoutesFromCalendar.button.print' }),

    filterSelectedLabel: translate({ id: 'modals.exportRoutesFromCalendar.filter.current' }),
    filterDispatchedLabel: translate({ id: 'modals.exportRoutesFromCalendar.filter.approved' }),
    filterSelectedDescription: translate({
      id: 'modals.exportRoutesFromCalendar.filter.current.description',
    }),
    filterDispatchedDescription: translate({
      id: 'modals.exportRoutesFromCalendar.filter.approved.description',
    }),

    formatXLSX: translate({ id: 'modals.exportRoutesFromCalendar.exportFormat.xlsx' }),
    formatCSV: translate({ id: 'modals.exportRoutesFromCalendar.exportFormat.csv' }),

    extraColumnTitle: translate({ id: 'modals.exportRoutesFromCalendar.extraColumns.title' }),
    extraColumnDescription: translate({
      id: 'modals.exportRoutesFromCalendar.extraColumns.description',
    }),

    customField: (count: number) =>
      translate({
        id: 'modals.exportRoutesFromCalendar.extraColumns.customFields',
        values: { count },
      }),
    loads: (count: number) =>
      translate({ id: 'modals.exportRoutesFromCalendar.extraColumns.loads', values: { count } }),
    selectAll: translate({ id: 'modals.exportRoutesFromCalendar.extraColumns.selectAll' }),
    deselectAll: translate({ id: 'modals.exportRoutesFromCalendar.extraColumns.deselectAll' }),

    notAllRoutesDispatchedWarningId:
      'modals.exportRoutesFromCalendar.extraColumns.notAllRoutesDispatchedWarning',

    noApprovedRoutesWarning: translate({
      id: 'modals.exportRoutesFromCalendar.extraColumns.noApprovedRoutesWarning',
    }),
    noApprovedRouteWarning: translate({
      id: 'modals.exportRoutesFromCalendar.extraColumns.noApprovedRouteWarning',
    }),
    dismiss: translate({
      id: 'modals.exportRoutesFromCalendar.button.dismiss',
    }),
    exportButtonWarning: (count: number) =>
      translate({
        id: 'modals.exportRoutesFromCalendar.button.warning',
        values: { count },
      }),

    advanced: {
      title: translate({ id: 'modals.exportRoutesFromCalendar.advanced.title' }),
      keepEnglishColumns: translate({
        id: 'modals.exportRoutesFromCalendar.advanced.keepEnglishColumns',
      }),
      keepEnglishColumnsDescription: translate({
        id: 'modals.exportRoutesFromCalendar.advanced.keepEnglishColumns.description',
      }),
    },

    nextStepButton: translate({ id: 'modals.exportRoutesFromCalendar.nextStepButton' }),
    prevStepButton: translate({ id: 'modals.exportRoutesFromCalendar.prevStepButton' }),
    preparingExport: translate({ id: 'modals.exportRoutesFromCalendar.preparingExport' }),
    emptySelectionWarning: translate({
      id: 'modals.exportRoutesFromCalendar.emptySelectionWarning',
    }),
    multiterritoryDropdownLabel: translate({
      id: 'modals.exportRoutesFromCalendar.multiterritoryDropdownLabel',
    }),
    multiterritoryDropdownPlaceholder: translate({
      id: 'modals.exportRoutesFromCalendar.multiterritoryDropdownPlaceholder',
    }),

    file: translate({ id: 'modals.exportRoutesFromCalendar.file' }),
    singleFileExport: translate({ id: 'modals.exportRoutesFromCalendar.singleFileExport' }),
    oneFileEachTerritory: translate({ id: 'modals.exportRoutesFromCalendar.oneFileEachTerritory' }),
    cannotExportPodForCalendarRange: translate({
      id: 'modals.exportRoutesFromCalendar.cannotExportPodForCalendarRange',
    }),
    plusItems: (count: number) =>
      translate({ id: 'modals.exportRoutesFromCalendar.plusTerritories', values: { count } }),
    territoriesValidationError: translate({
      id: 'modals.exportRoutesFromCalendar.territoriesValidationError',
    }),
    noOrdersToExportOnDispatchedRoutes: translate({
      id: 'territoryCalendar.export.noOrdersToExport',
    }),
    noOrdersToExportOnCurrentRoutes: translate({
      id: 'territoryCalendar.export.noOrdersToExportCurrentRoutes',
    }),
    customizeColumns: translate({ id: 'territoryCalendar.export.customizeColumns' }),
    customizeColumnsDescription: translate({
      id: 'territoryCalendar.export.customizeColumnsDescription',
    }),
    customizeColumnsDisabled: translate({
      id: 'territoryCalendar.export.customizeColumnsDisabled',
    }),
    advancedButtonDisabled: translate({ id: 'territoryCalendar.export.advancedButtonDisabled' }),
  }))

  return api
}
