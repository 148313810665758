import {
  ChangeEvent,
  Component,
  FormEvent,
  MouseEvent,
  KeyboardEvent,
  ReactElement,
  HTMLProps,
  FocusEvent,
  MouseEventHandler,
  ReactNode,
} from 'react'

import { clsx } from '@/utils'
import { Cross } from '@/icons'

import { BaseFieldProps } from '../typings'
import { BaseInput, BaseInputWrapper, BaseTextarea, ButtonReset } from '../internals'

export type InputTextChangeCallback = (value: string, e?: FormEvent<HTMLElement>) => void

export type InputType = 'number' | 'text' | 'email' | 'password' | undefined
export type OnChangeType = (value: string, e?: FormEvent<HTMLElement>) => void
export type CounterFormatter = (value: string) => string

export interface Props extends BaseFieldProps {
  className?: string
  value: string
  onChange: OnChangeType
  extraIcon?: ReactElement
  extraLabel?: string
  extraLabelClassName?: string
  extraLabelOnClick?: MouseEventHandler
  type?: InputType
  onReset?: InputTextChangeCallback
  multi?: boolean
  showResetIcon?: boolean
  onFocus?: (event: FormEvent<HTMLElement>) => void
  onBlur?: (event: FormEvent<HTMLElement>, value: string) => void
  onKeyUp?: (event: KeyboardEvent<HTMLElement>) => void
  onKeyDown?: (event: KeyboardEvent<HTMLElement>) => void
  useBigField?: boolean
  innerRef?: React.RefObject<HTMLInputElement>
  suggestedText?: string
  tabIndex?: number
  hintOnFocus?: string
  maxLength?: number
  showCounter?: boolean
  counterFormat?: CounterFormatter | undefined
  externalCounter?: () => string
  renderExtraControls?: () => ReactNode
  disabled?: boolean
  hasError?: boolean
  trackid?: string
}

interface State {
  hasFocus?: boolean
}

const setFocus = (hasFocus: boolean) => () => ({
  hasFocus,
})

const defaultCounterFormat = (v: string = ''): string => v.length.toString()

export default class InputFieldText extends Component<Props, State> {
  static defaultProps = {
    showResetIcon: true,
  }

  state: State = {
    hasFocus: false,
  }

  private inputRef: workwave.NullableHTMLInputElement = null
  public getInputRef = (): workwave.NullableHTMLInputElement => this.inputRef

  private handleResetField = (e: MouseEvent<HTMLElement>): void => {
    // ATTENTION: really weird hack!
    // FIXME: for some still unkwown reason this handler is triggdred when the enter key
    // has been pressed with focus on the text input field
    // To prevent unwanted behaviors we have to check `event.detail` that holds the number of clicks happened.
    // the value equal to zero if the source is not a mouse click.
    // see: https://developer.mozilla.org/en-US/docs/Web/API/UIEvent/detail
    if (e.detail === 0) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const { onChange, onReset } = this.props
    e.preventDefault()

    if (this.inputRef) {
      this.inputRef.focus()
    }

    const callback = onReset ? onReset : onChange
    callback('', e)
  }

  handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { onChange } = this.props
    const {
      target: { value },
    } = e
    onChange(value, e)
  }

  handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
    const { onFocus } = this.props
    this.setState(setFocus(true))
    if (onFocus) {
      onFocus(e)
    }
  }

  handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
    const { onBlur, value } = this.props
    this.setState(setFocus(false))

    if (onBlur) onBlur(e, value)
  }

  renderExtraIcon = (): ReactElement | null => {
    const { extraIcon } = this.props

    if (!extraIcon) return null

    return <span className="o-input-field-text__extra-icon">{extraIcon}</span>
  }

  renderExtraLabel = (): ReactElement | null => {
    const { extraLabel, extraLabelOnClick, extraLabelClassName = '' } = this.props

    if (!extraLabel) return null

    return extraLabelOnClick ? (
      <span
        className={`${extraLabelClassName} o-input-field-text__extra-label o-input-field-text__extra-label-as-link`}
        data-testid="extra-label"
        onClick={extraLabelOnClick}
      >
        {extraLabel}
      </span>
    ) : (
      <span
        data-testid="extra-label"
        className={`${extraLabelClassName} o-input-field-text__extra-label`}
      >
        {extraLabel}
      </span>
    )
  }

  renderInput = (): ReactElement => {
    const {
      name,
      value,
      readonly = false,
      placeholder = '',
      type = 'text',
      multi = false,
      suggestedText,
      onKeyUp,
      onKeyDown,
      tabIndex = -1,
      maxLength,
      innerRef,
      trackid,
    } = this.props

    const inputProps: HTMLProps<HTMLInputElement> = {
      type,
      name,
      value,
      onKeyUp,
      onKeyDown,
      maxLength,
      onChange: this.handleChange,
      disabled: readonly,
      placeholder,
      className: 'o-input-field-text__base-input',
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      autoComplete: 'off',
    }

    // FIXME: Comp must be set to `any` to prevent TS to complain about the not matching `ref` types
    const Comp: any = multi ? BaseTextarea : BaseInput

    return (
      <div className="o-input-field-text__input-wrapper">
        <Comp
          tabIndex={tabIndex}
          {...inputProps}
          ref={innerRef}
          data-testid={multi ? 'input-textarea' : 'input-text'}
          data-trackid={trackid}
        />
        {suggestedText ? (
          <span className="o-input-field-text__suggested-text">{suggestedText}</span>
        ) : null}
      </div>
    )
  }

  renderFocusHint = (): ReactElement | null => {
    const { hintOnFocus, value } = this.props
    const { hasFocus } = this.state
    if (hasFocus && hintOnFocus && value.length > 0) {
      return <span className="o-input-field-text__focus-hint">{hintOnFocus}</span>
    }
    return null
  }

  renderResetButton = (): ReactElement | null => {
    const { showResetIcon, readonly, value, name } = this.props

    const resetButtonClassNames = clsx({
      'o-input-field-text__reset-button': true,
      'is-empty': false,
    })

    const isValueSet: boolean = !!value && value.length > 0

    return isValueSet && showResetIcon ? (
      <ButtonReset
        type="button"
        key={name}
        onClick={this.handleResetField}
        disabled={readonly}
        className={resetButtonClassNames}
        data-testid="clear-input-button"
      >
        <Cross />
      </ButtonReset>
    ) : null
  }

  renderCounter = (): ReactElement | null => {
    const {
      showCounter,
      value = '',
      counterFormat = defaultCounterFormat,
      externalCounter,
    } = this.props

    if (!showCounter || value.length === 0) {
      return null
    }

    return (
      <div className="o-input-field-text__counter">
        {!!externalCounter ? externalCounter() : counterFormat(value)}
      </div>
    )
  }

  render() {
    const {
      className = '',
      readonly = false,
      multi = false,
      renderExtraControls,
      disabled = false,
    } = this.props

    const rootClassName = clsx({
      [className]: true,
      'o-input-field-text': true,
      'is-readonly': readonly,
    })

    return (
      <BaseInputWrapper
        className={rootClassName}
        multi={multi}
        disabled={disabled}
        data-testid="input-text-root"
      >
        {this.renderExtraIcon()}
        {this.renderExtraLabel()}
        {this.renderInput()}
        {!!renderExtraControls && renderExtraControls()}
        {this.renderResetButton()}
        {this.renderCounter()}
        {this.renderFocusHint()}
      </BaseInputWrapper>
    )
  }
}
