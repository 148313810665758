import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import {
  SecondaryColumnContentContainer,
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyFieldLabel,
  ReadonlyField,
  ReadonlyFieldRow,
} from '@/forms-legacy'

import { usePendingFitMapOnMount } from '@/map'

import { useTexts } from './hooks/useTexts'

interface Props {
  setupCost: number
  setupTimeSec: number
  setupCostMixed?: boolean
  setupTimeSecMixed?: boolean
}

export function ViewDetails(props: Props) {
  const { setupCost, setupTimeSec, setupCostMixed, setupTimeSecMixed } = props
  const { currency } = useSelector(selectUserConfiguration)

  const texts = useTexts()

  // When the read-only view mounts
  // try to apply pending fitMap requests
  usePendingFitMapOnMount()

  const setupCostText = setupCostMixed ? texts.multipleValues : texts.setupCost(setupCost, currency)
  const setupTimeText = setupTimeSecMixed ? texts.multipleValues : texts.setupTime(setupTimeSec)

  return (
    <SecondaryColumnContentContainer>
      <SecondaryColumnContentHorizontalRuler bottomSpace />

      <ReadonlyBlock half>
        <ReadonlyLabel>{texts.setupCostLabel}</ReadonlyLabel>

        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>{setupCostText}</ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyBlock>

      <ReadonlyBlock half>
        <ReadonlyLabel>{texts.setupTimeLabel}</ReadonlyLabel>

        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>{setupTimeText}</ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyBlock>
    </SecondaryColumnContentContainer>
  )
}
