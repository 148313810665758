export function getIdleStartValue(
  hasIdleThresholdCapability: boolean,
  idleStartThreshold?: number,
): uui.domain.ui.forms.DeviceData['idleStart'] {
  const min = 2
  const max = 720

  const enabled =
    hasIdleThresholdCapability &&
    typeof idleStartThreshold === 'number' &&
    isFinite(idleStartThreshold)

  if (!enabled) {
    return {
      custom: 0,
      preset: 'custom',
      enabled,
      min,
      max,
    }
  }

  const minutes = (idleStartThreshold || 0) / 60

  let presetValue: uui.domain.ui.forms.DeviceData['idleStart']['preset']
  switch (minutes) {
    case 5:
      presetValue = 'fiveMins'
      break
    case 10:
      presetValue = 'tenMins'
      break
    case 30:
      presetValue = 'thirtyMins'
      break
    default:
      presetValue = 'custom'
      break
  }

  return {
    custom: minutes,
    preset: presetValue,
    enabled: true,
    min,
    max,
  }
}
