import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { findFeature } from '../../findFeature'

import { findRoutePolylineMarker } from './findRoutePolylineMarker'
import { updateRoutePolylineFeature } from './updateRoutePolylineFeature'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Update the rendered style and position for all the RoutePolylines marked to be updated in a Changelog coming form the data-layer
 */
export async function changelogUpdateRoutePolylineFeatures(
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.RoutePolyline>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['routePolyline'],
  markerIds: IterableIterator<string>,
) {
  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findRoutePolylineMarker(markers, markerId)
    const feature = findFeature(layer, marker.id)

    if (!feature) {
      throw new Error(
        `Trying to update RoutePolyline marker with ID: ${marker.id} but no feature exist on the map`,
      )
    }

    updateRoutePolylineFeature(mapStyles, marker, feature)
  }
}
