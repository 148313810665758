import { useMemo } from 'react'

export const useCosts = (depots: uui.domain.client.rm.Depot[]) => {
  return useMemo(() => {
    let setupCost = NaN
    let setupTimeSec = NaN
    let setupCostMixed = false
    let setupTimeSecMixed = false

    for (const { setupCost: cost, setupTimeSec: time } of depots) {
      if (Number.isNaN(setupCost)) {
        setupCost = cost
      } else if (!setupCostMixed && setupCost !== cost) {
        setupCostMixed = true
      }

      if (Number.isNaN(setupTimeSec)) {
        setupTimeSec = time
      } else if (!setupTimeSecMixed && setupTimeSec !== time) {
        setupTimeSecMixed = true
      }
    }

    return {
      setupCost,
      setupTimeSec,
      setupCostMixed,
      setupTimeSecMixed,
    }
  }, [depots])
}
