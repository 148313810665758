import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    resultErrorTitle: translate({ id: 'sendMessageToDriverModal.result.error.title' }),
    resultSuccessTitle: translate({ id: 'sendMessageToDriverModal.result.success.title' }),
    resultErrorDescription: (address: string) =>
      translate({ id: 'sendMessageToDriverModal.result.error.description', values: { address } }),
    resultSuccessDescription: (address: string) =>
      translate({ id: 'sendMessageToDriverModal.result.success.description', values: { address } }),
  }))

  return api
}
