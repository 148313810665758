import { useCallback } from 'react'

import { useNavigate } from '@/routing'

export function useActions() {
  const navigate = useNavigate<uui.routing.MainSections>(true)

  const onNavigateToLogin = useCallback(() => {
    navigate('login')
  }, [navigate])

  return {
    onNavigateToLogin,
  }
}
