import { mapAtom } from '../../atoms/map/map'
import { findLayer } from '../utils/findLayer'

import { mapStylesUpdateDepotFeatures } from './utils/mapStylesUpdateDepotFeatures'

export const createUpdateDepotFeaturesMapStyleFromChangelog = (
  markers: uui.domain.ui.map.markers.MapMarkers['depot'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['depot'],
  selection: Set<string>,
) => {
  // ATTENTION: Application of Map Style changes will not be cached if a delayed rendering mechanism will be implemented
  // After the whole set of delayed marker changelogs is applied a full refresh of Map Styles will be required

  return async () => {
    const map = mapAtom.map
    const layer = findLayer(map, 'depot')

    // only the `update` field is populated in a mapStyles changelog
    await mapStylesUpdateDepotFeatures(layer, markers, mapStyles, selection)
  }
}
