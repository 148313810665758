import type Geometry from 'ol/geom/Geometry'
import LineString from 'ol/geom/LineString'
import Feature from 'ol/Feature'

import { setBreadcrumbFeatureMetadata } from '../../breadcrumbFeatureMetadata'
import { getBreadcrumbPathFeatureStyle } from './getBreadcrumbPathFeatureStyle'

/**
 * Breadcrumbs are composed by multiple features on the map:
 * - One feature that represent the full path (path)
 * - <number_of_points> features that represents the single breadcrumbs on the map
 * - <number_of_stalls> features that represents the single stalls on the map
 * - <number_of_stops> features that represents the single stops on the map
 */
export function createBreadcrumbPathFeature(
  marker: uui.domain.ui.map.markers.Breadcrumb,
  mapStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
): Feature<Geometry> {
  const mode: uui.domain.ui.map.markers.MapStyles['breadcrumb']['mode'] =
    marker.mode ?? mapStyles.mode

  // Path feature
  let line: number[][] = []

  // Filter the line coordinates by given interval
  if (marker.fetched) {
    switch (marker.lineVisibleRange) {
      case 'all':
        line = marker.line
        break

      case 'nothing':
        line = []
        break

      default:
        line = marker.line.slice(
          marker.lineVisibleRange.start,
          marker.lineVisibleRange.end === -1 ? undefined : marker.lineVisibleRange.end,
        )
        break
    }
  }
  const feature = new Feature({ geometry: new LineString(line) })

  setBreadcrumbFeatureMetadata(feature, 'type', 'breadcrumbPath')

  feature.setStyle(getBreadcrumbPathFeatureStyle(marker, mode))
  feature.setId(marker.id)

  return feature
}
