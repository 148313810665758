import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectDrivers } from '@/features/domain/driver'
import { useController } from '../../../hooks/useController'

export type InvalidDriversResult = {
  quantity: number
  details: Record<
    string,
    { lastActivityDate: string; driver: uui.domain.client.rm.ExtendedDriver }[]
  >
}

export function useInvalidData() {
  const drivers = useSelector(selectDrivers)

  const {
    data: { validationResult },
  } = useController()

  return useMemo(() => {
    if (!validationResult) return

    return Object.keys(validationResult).reduce<InvalidDriversResult>(
      (acc, driverId) => {
        if (validationResult[driverId].valid) return acc
        acc.quantity++

        const deletionDate = validationResult[driverId].notValidUntil
        const affectedRouteDates = Array.from(
          new Set(validationResult[driverId].affectedRoutes.map(routeId => routeId.slice(-8))),
        ).sort()

        acc.details[deletionDate] = acc.details[deletionDate] ?? []
        acc.details[deletionDate].push({
          lastActivityDate: affectedRouteDates[affectedRouteDates.length - 1],
          driver: drivers[driverId],
        })

        return acc
      },
      { quantity: 0, details: {} },
    )
  }, [validationResult, drivers])
}
