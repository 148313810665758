import { useCallback } from 'react'

import { abortBuildRoutes } from '@/features/domain/route/actions/abortBuildRoutes'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useController } from './useController'

export function useActions() {
  const { updateData } = useController()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const cancelBuild = useCallback(async () => {
    try {
      updateData({ canceling: true })

      const result = await dispatch(abortBuildRoutes())

      if (!abortBuildRoutes.fulfilled.match(result)) {
        throw new Error('Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, toast, updateData])

  return cancelBuild
}
