import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCustomerNotificationsSettings } from '@/features/domain/territory'

export type DelayedNotificationValues = ReturnType<typeof useDelayedNotificationValues>

/**
 * That hook grabs delayedNotification values from the domains, than filter some of them out and
 * does some type conversion where required
 */
export function useDelayedNotificationValues() {
  const notificationSettings = useSelector(selectCustomerNotificationsSettings)

  return useMemo(() => {
    const { toleranceMins, ...rest } = notificationSettings['delayedSettings']

    return {
      ...rest,
      toleranceMins: toleranceMins.toString(),
    }
  }, [notificationSettings])
}
