import type { Props } from './typings'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import { Block, FieldMessage, Label, HourPicker } from '@/forms-legacy'

import { useTexts } from './useTexts'

export function TimeWindowField(props: Props) {
  const {
    name,
    formProps: {
      input: { value, onChange },
      meta: { error, dirty },
    },
  } = props

  const userConfig = useSelector(selectUserConfiguration)
  const h24 = userConfig.timeFormat !== 'hh:mm aa'

  const texts = useTexts()

  const onChangeStart = useCallback(
    (fieldValue: number) => {
      onChange({
        startSec: fieldValue,
        endSec: value.endSec,
      })
    },
    [onChange, value],
  )

  const onChangeEnd = useCallback(
    (fieldValue: number) => {
      onChange({
        startSec: value.startSec,
        endSec: fieldValue,
      })
    },
    [onChange, value],
  )

  return (
    <>
      <Block error={!!error} dirty={!!dirty}>
        <Label>{texts.start}</Label>
        <HourPicker
          name={`${name}-from`}
          value={value.startSec}
          onChange={onChangeStart}
          h24={h24}
        />
      </Block>
      <Block error={!!error} dirty={!!dirty}>
        <Label>{texts.end}</Label>
        <HourPicker name={`${name}-to`} value={value.endSec} onChange={onChangeEnd} h24={h24} />
      </Block>
      {!!error && <FieldMessage error>{error}</FieldMessage>}
    </>
  )
}
