import { Stack, Typography } from '@mui/material'
import { type LocaleText, type Column, type Selection } from '../../types'
import { DraggableItem } from './components/DraggableItem'
import { ColumnCard } from './components/ColumnCard'
import { useShuffler } from './hooks/useShuffler'
import { NoColumns } from './components/NoColumns'

interface ColumnsShufflerProps {
  columns: Column[]
  onRemoveColumn: (id: string) => void

  localeText: LocaleText
  onSelectionChange: Selection['onSelectionChange']
}

export function ColumnsShuffler(props: ColumnsShufflerProps) {
  const { columns: providedColumns, onRemoveColumn, localeText, onSelectionChange } = props
  const { onMoveColumn, columns } = useShuffler(providedColumns, onSelectionChange)

  if (columns.length === 0) return <NoColumns localeText={localeText} />

  return (
    <Stack
      paddingX={3}
      paddingY={4}
      sx={theme => ({
        background: theme.palette.background.default3,
      })}
      gap={2}
    >
      <Stack>
        <Typography variant="body1">{localeText.columnsSequence}</Typography>
        <Typography variant="caption">{localeText.columnsSequenceDescription}</Typography>
      </Stack>
      <Stack
        gap={2}
        pr={2}
        overflow="auto"
        maxHeight="100%"
        height="100%"
        data-testid="columns-picker-sequence-panel"
        data-trackid="columns-picker-sequence-panel"
      >
        {columns.map(column => (
          <DraggableItem key={column.id} {...column} onMoveColumn={onMoveColumn}>
            <ColumnCard {...column} onRemoveColumn={onRemoveColumn} />
          </DraggableItem>
        ))}
      </Stack>
    </Stack>
  )
}
