export function computeTags(
  formValues: uui.domain.ui.forms.DeviceData,
): uui.domain.client.gps.wwgps.Tag[] {
  const {
    badges: { badges, originalTags },
  } = formValues

  if (badges.length === 0) return []

  return badges.map<uui.domain.client.gps.wwgps.Tag>(badge => {
    const tag = originalTags.find(tag => tag.value === badge)
    if (!tag) {
      return {
        id: '0',
        name: 'tag',
        value: badge,
      }
    }
    return tag
  })
}
