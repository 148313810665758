import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'devices.form.edit.title' }),
    headerDescription: (deviceId: string): string =>
      translate({
        id: 'devices.form.edit.subtitle.gpsOnly',
        values: { deviceId },
      }),
    errors: translate({
      id: 'devices.form.edit.errorTitle',
    }),
    save: _ => translate({ id: 'devices.form.edit.update' }),
    saveTitle: (valid: boolean, readonly: boolean, pristine: boolean) => {
      if (readonly) return translate({ id: 'devices.form.submitButton.disabled.title.planLocked' })
      if (!valid) return translate({ id: 'devices.form.submitButton.disabled.title.invalid' })
      if (pristine) {
        return translate({ id: 'devices.form.submitButton.disabled.title.pristine' })
      }
      return ''
    },
    cancel: translate({ id: 'devices.form.edit.discard' }),
    name: translate({ id: 'devices.form.edit.fields.name.label' }),
    color: translate({ id: 'devices.form.edit.fields.color.label' }),
    colorInfo: translate({ id: 'devices.form.edit.fields.color.info' }),
    globalEdit: translate({ id: 'global.edit' }),
    globalCancel: translate({ id: 'global.cancel' }),
    globalChange: translate({ id: 'global.change' }),
    icon: translate({ id: 'devices.form.edit.fields.icon.label' }),
    iconInfo: translate({ id: 'devices.form.edit.fields.icon.description' }),
    expanding: translate({ id: 'devices.form.gpsExpandingTitle' }),
    deviceSettings: (deviceId: string) =>
      translate({
        id: 'devices.form.deviceSettings',
        values: {
          deviceId,
        },
      }),
  }))

  return api
}
