import type { FormFields } from '../../../formFields'

import { Box } from '@mui/material'
import { TextField } from '@workwave-tidal/tidal/form-ui'

import { useTexts } from '../hooks/useTexts'

export function Name() {
  const texts = useTexts()

  return (
    <Box paddingTop={1}>
      {/* Please remove the above <Box /> when https://workwave.atlassian.net/browse/CUI-419 will be fixed */}
      <TextField<'name', FormFields>
        name="name"
        testId="name"
        label={texts.labels.name}
        placeholder={texts.labels.name}
        validateOn="change blur"
      />
    </Box>
  )
}
