// --------------------------------------------------------
// NOTE: We're using same sprite sheet as execution events
// --------------------------------------------------------

import coordinates from '../../executionEvents/coordinates/execution-events.json'

// --------------------------------------------------------

const cellSize = coordinates?.side

if (cellSize === undefined) {
  throw new Error(`Impossible to find the cellSize for map Voice Events markers`)
}

export function getVoiceEventGridPlacement(): uui.domain.ui.map.markers.GridPlacement {
  const coords = coordinates?.coordinates?.['pod']?.['pod']

  return [coords[0], coords[1], cellSize]
}
