import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    duplicateDisabledTooltipTitle: (isSimulation: boolean) => {
      const id = isSimulation
        ? 'orders.duplicate.disabled.simulations.title'
        : 'orders.duplicate.disabled.operations.title'

      return translate({ id })
    },

    disabledForLockedRouteTooltipTitle: translate({
      id: 'orders.action.unassign.tooltip.disabledForLockedRoute',
      values: { count: 2 },
    }),

    duplicateDisabledTooltipSubtitle: (
      addableOrders: number,
      isSimulation: boolean,
      ordersLimit: number,
    ) => {
      if (addableOrders === 0) {
        const id = isSimulation
          ? 'orders.duplicate.disabled.simulations.subtitle'
          : 'orders.duplicate.disabled.operations.subtitle'
        return translate({ id, values: { count: ordersLimit } })
      }

      const id = isSimulation
        ? 'orders.duplicate.disabled.simulations.tooMuch.subtitle'
        : 'orders.duplicate.disabled.operations.tooMuch.subtitle'
      return translate({ id, values: { addableOrders, ordersLimit } })
    },

    editBtnTitle: (vehicleTracked: boolean) =>
      vehicleTracked
        ? ''
        : translate({ id: 'orders.executionEvents.action.edit.noVehicleTracked' }),
  }))

  return api
}
