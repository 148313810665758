import type { FormErrors, FormFields } from './formFields'

import { useSelector } from 'react-redux'
import { FormProvider } from '@workwave-tidal/tidal/form-ui/layout/FormProvider'

import { selectReasonsForNotCompletedOrders } from '@/features/domain/territory'

import { useDynamicLoadsCfData } from '../../hooks/useDynamicLoadsCfData'
import { useUpdateDriversActivity } from '../../hooks/useUpdateDriversActivity'
import { formFields } from './formFields'
import { DriverActivityForm } from './DriverActivityForm'
import { useCollectStartEndRoute } from './hooks/useCollectStartEndRoute'
import { useCreateFormConfig } from './hooks/useCreateFormConfig'

interface Props {
  onDiscard: () => void
}

export function DriverActivityFormProvider(props: Props) {
  const { onDiscard } = props

  // Get reasons for orders from the domain state and the update method
  const reasonsForNotCompletedOrders = useSelector(selectReasonsForNotCompletedOrders)
  const updateDriversActivity = useUpdateDriversActivity()

  const collectStartEndRoute = useCollectStartEndRoute()
  const { enableDynamicCustomFields, enableDynamicLoads } = useDynamicLoadsCfData()

  const createFormConfig = useCreateFormConfig({
    reasonsForNotCompletedOrders,
    collectStartEndRoute,
    enableDynamicCustomFields,
    enableDynamicLoads,
  })

  return (
    <FormProvider<FormFields, FormErrors>
      name="driver-activity-form"
      fields={formFields}
      verbose={false}
      config={createFormConfig}
    >
      <DriverActivityForm onDiscard={onDiscard} updateDriversActivity={updateDriversActivity} />
    </FormProvider>
  )
}
