import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    disabledNotAvailableInThePast: translate({
      id: 'rm.contextual.vehicles.notAvailableInThePast',
    }),
    disabledMultipleVehicles: translate({ id: 'rm.contextual.vehicles.disabledMultipleVehicles' }),
    disabledDriverNotTracked: translate({ id: 'rm.contextual.vehicles.disabledDriverNotTracked' }),
    disabledLoadingGpsData: translate({ id: 'global.gpsLoading.disableEdit' }),
    disabledNoDriver: translate({ id: 'rm.contextual.vehicles.disabledNoDriver' }),
    disabledNoMarker: translate({ id: 'rm.contextual.vehicles.noMarker' }),
    disabledForBeta: translate({ id: 'global.disabledForBeta' }),
    disabledReport: translate({ id: 'rm.contextual.vehicles.disabledReport' }),
    centerOnMap: translate({ id: 'rm.contextual.centerOnMap' }),
    runReport: translate({ id: 'gps.reports.runReport' }),
    sendMessage: translate({ id: 'global.sendMessage' }),
    breadcrumbs: translate({ id: 'global.breadcrumbs' }),
    events: translate({ id: 'global.events' }),
    edit: translate({ id: 'global.edit' }),

    disabledNoDevice: (vehicleName: string) =>
      translate({ id: 'rm.contextual.vehicles.noLinkedDevice', values: { vehicleName } }),

    disabledTooltipSubtitle: translate({
      id: 'global.disabledTooltip.transactionInProgress.subtitle',
    }),
  }))

  return api
}
