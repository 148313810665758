import styled from 'styled-components'

const ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 208px;
  user-select: none;
`

ColorPickerContainer.displayName = 'ColorPickerContainer'
export default ColorPickerContainer
