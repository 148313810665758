import type { MouseEvent } from 'react'
import type { MainSelectionCategory } from '@/atoms'

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useNavigoSelection } from '@/atoms'

import { selectOrderStepIdsInRange, selectOrderSteps } from '@/features/domain/order'
import { useTexts } from './useTexts'

export const useMultiCategory = () => {
  const { selection, selectedCategories, resetSelection } = useNavigoSelection()
  const orderStepIdsInRange = useSelector(selectOrderStepIdsInRange)
  const extOrderSteps = useSelector(selectOrderSteps)
  const texts = useTexts()

  const { orderSteps: selectionOrderSteps } = selection

  const violationsCount = useMemo(() => {
    if (!selectionOrderSteps) return

    let counter = 0

    for (const orderStepId of selectionOrderSteps) {
      if (!orderStepIdsInRange[orderStepId]) continue

      const extOrder = extOrderSteps[orderStepId]

      if (!extOrder) continue

      if (!extOrder.isUnassigned && (extOrder?.violations || []).length > 0) {
        counter++
      }
    }

    return counter
  }, [extOrderSteps, orderStepIdsInRange, selectionOrderSteps])

  const getCategoryLabel = useCallback(
    (category: MainSelectionCategory, count: number) => {
      switch (category) {
        case 'depots':
          return texts.depots(count)

        case 'unifiedVehicles':
          return texts.vehicles(count)

        case 'orderSteps':
          return texts.orders(count)

        case 'places':
          return texts.places(count)

        case 'devices':
          return texts.devices(count)

        case 'routes':
          return texts.routes(count)
      }
    },
    [texts],
  )

  const handleOnNarrowSelection = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const category = event.currentTarget.dataset.category as MainSelectionCategory

      if (category) resetSelection(category)
    },
    [resetSelection],
  )

  return {
    selection,
    violationsCount,
    selectedCategories,
    getCategoryLabel,
    handleOnNarrowSelection,
  }
}
