import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Calendar(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M12 0c-1.09375 0-2 .90625-2 2v2H4c-1.16016 0-2 .83984-2 2v7h46V6c0-1.16016-.83984-2-2-2h-6V2c0-1.09375-.90625-2-2-2h-2c-1.09375 0-2 .90625-2 2v2H16V2c0-1.09375-.90625-2-2-2zm0 2h2v6h-2zm24 0h2v6h-2zM2 15v31c0 1.16016.83984 2 2 2h42c1.16016 0 2-.83984 2-2V15zm10 6h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5zm-21 7h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5zm-21 7h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5zm7 0h5v5h-5z" />
      </svg>
    </IconContainer>
  )
}
