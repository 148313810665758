import type { CoreItem, CoreItemRendererProps } from '../../typings'

import { Component, ReactNode } from 'react'
import { clsx } from '@/utils'

import Arrow from '../../components/Arrow'
import { Chip } from '../../components/Chip'
import { Props } from './index'
import Rewind from './Rewind'

const BULK_INDEX = -1
const FIRST_INDEX = 0
const noop = (): void => undefined

export default class CollapsedLayoutRenderer<T extends CoreItem> extends Component<Props<T>> {
  goToBulk = (): void => {
    const { onChangeIndex } = this.props
    onChangeIndex(BULK_INDEX)
  }

  goToFirst = (): void => {
    const { onChangeIndex } = this.props
    onChangeIndex(FIRST_INDEX)
  }

  goToLast = (): void => {
    const { onChangeIndex, items } = this.props
    onChangeIndex(items.length - 1)
  }

  goNext = (): void => {
    const { onChangeIndex, selectedIndex } = this.props
    onChangeIndex(selectedIndex + 1)
  }

  goPrev = (): void => {
    const { onChangeIndex, selectedIndex } = this.props
    onChangeIndex(selectedIndex - 1)
  }

  onClickRewind = (): void => {
    const {
      extraLayoutRendererProps: { rewindToFirst },
    } = this.props
    if (rewindToFirst) {
      this.goToFirst()
    } else {
      this.goToBulk()
    }
  }

  renderSingleItem = (
    item: T,
    itemIndex: number,
    pivot: number,
    renderedChips: number,
  ): ReactNode => {
    const {
      ItemRenderer,
      mode,
      extraItemRendererProps,
      extraLayoutRendererProps: { onClick = noop, disabled },
    } = this.props

    let itemClassName: string = 'o-collapsed-item__item'
    if (renderedChips > 1) {
      itemClassName = clsx({
        'o-collapsed-item__item': true,
        'is-prev-n': itemIndex < pivot - 2 && pivot >= 2,
        'has-prev-2': pivot > 1,
        'is-prev-2': itemIndex === pivot - 2,
        'is-prev-1': itemIndex === pivot - 1,
        'is-current': itemIndex === pivot,
        'is-next-1': itemIndex === pivot + 1,
        'is-next-2': itemIndex === pivot + 2,
        'has-next-2': pivot < renderedChips - 2,
        'is-next-n': itemIndex > pivot + 1,
        'is-inactive': Math.abs(itemIndex - pivot) > 2,
      })
    }

    const itemRendererClassName = clsx({
      'is-active': renderedChips === 1 || itemIndex === pivot,
      'use-border': true,
    })

    const { id } = item
    const itemProps: CoreItemRendererProps<T, Record<string, any>> = {
      item,
      selected: pivot === itemIndex,
      mode,
      onClick: disabled ? noop : onClick,
      extraItemRendererProps,
      loopIndex: itemIndex,
      className: itemRendererClassName,
    }

    return (
      <div className={itemClassName} key={id}>
        <ItemRenderer {...itemProps} />
      </div>
    )
  }

  renderExtraChip = (index: number, collectionSize: number) => {
    const {
      extraLayoutRendererProps: { rewindToFirst },
    } = this.props

    if (rewindToFirst) {
      return null
    }

    const chip1 = index === 0 && collectionSize > 1 ? this.renderGhostChip(1) : null
    const chip2 = index === 1 && collectionSize > 2 ? this.renderGhostChip(2) : null

    return (
      <>
        {chip1}
        {chip2}
      </>
    )
  }

  renderGhostChip = (depth: number = 1) => {
    const {
      extraLayoutRendererProps: { chipShape = 'rounded' },
    } = this.props

    const ghostClassName = clsx({
      'o-collapsed-item__item': true,
      'has-prev-2': depth === 2,
      'is-prev-2': depth === 2,
      'is-prev-1': depth === 1,
    })

    const ghostChipProps = {
      row: ``,
      onClick: noop,
      className: ghostClassName,
      shape: chipShape,
    }

    return <Chip {...ghostChipProps} />
  }

  renderDefaultSummary = () => {
    const {
      items = [],
      selectedIndex,
      extraLayoutRendererProps: { chipShape = 'rounded' },
    } = this.props

    const itemSummaryClassName = clsx({
      'o-collapsed-item__item': true,
      'o-collapsed-item__summary': true,
      'is-active': selectedIndex === -1,
      'use-border': true,
    })

    const summaryChipProps = {
      row: `x ${items.length}`,
      onClick: noop,
      className: itemSummaryClassName,
      shape: chipShape,
    }

    return <Chip {...summaryChipProps} />
  }

  render() {
    const {
      className = '',
      items = [],
      selectedIndex: index,
      extraLayoutRendererProps: {
        renderSummary = this.renderDefaultSummary,
        chipShape = 'rounded',
        hideNonSelectedChips,
        hideRewind,
        disabled,
        forceDisableNext,
        forceDisableNextTitle,
        forceDisablePrev,
        forceDisablePrevTitle,
        showFastForward,
        rewindToFirst,
      },
    } = this.props
    const collectionSize: number = items.length

    const rootClassName = clsx({
      'o-collapsed-items-container': true,
      'is-disabled': disabled,
      [className]: true,
    })

    const collapsedChipsClassName = clsx({
      'o-collapsed-items': true,
      'hide-non-selected-chips': collectionSize > 1 && hideNonSelectedChips,
    })

    const prevButtonDisabled: boolean =
      disabled ||
      forceDisablePrev ||
      collectionSize < 2 ||
      (index === 0 && !!rewindToFirst) ||
      (collectionSize > 1 && index === -1)

    const rewindButtonHidden: boolean = !!hideRewind || (index === 0 && !!rewindToFirst)

    const nextButtonDisabled =
      disabled || forceDisableNext || collectionSize < 2 || index === collectionSize - 1

    const itemPlaceholderClassName = clsx({
      'o-collapsed-item__item': true,
      'o-collapsed-item__item--placeholder': true,
    })

    const placeholderChipProps = {
      row: '',
      disabled: true,
      onClick: () => undefined,
      className: itemPlaceholderClassName,
      color: 'transparent',
      background: 'transparent',
      shape: chipShape,
    }

    const isValidSelection: boolean = !!items[index] && index !== -1
    const selectedId = isValidSelection ? items[index].id : ''

    const start: number = index - 2 >= 0 ? index - 2 : 0
    const end: number = start + 6
    const renderedItems = collectionSize > 5 ? items.slice(start, end) : items
    const pivot: number = renderedItems.findIndex(({ id }) => selectedId === id)

    return (
      <div className={rootClassName}>
        {!rewindButtonHidden && (
          <span className="o-rewind-container">
            <Rewind onClick={this.onClickRewind} disabled={prevButtonDisabled} />
          </span>
        )}
        <Arrow
          callback={this.goPrev}
          disabled={prevButtonDisabled}
          direction="left"
          invisible={collectionSize < 2}
          title={prevButtonDisabled ? forceDisablePrevTitle : undefined}
        />
        <div className={collapsedChipsClassName}>
          {renderedItems.map((item, i) =>
            this.renderSingleItem(item, i, pivot, renderedItems.length),
          )}
          {this.renderExtraChip(index, collectionSize)}
          {collectionSize > 1 && renderSummary()}
          <Chip {...placeholderChipProps} />
        </div>
        <Arrow
          callback={this.goNext}
          disabled={nextButtonDisabled}
          direction="right"
          invisible={collectionSize < 2}
          title={nextButtonDisabled ? forceDisableNextTitle : undefined}
        />
        {showFastForward && (
          <span className="o-fast-forward-container">
            <Rewind onClick={this.goToLast} disabled={nextButtonDisabled} />
          </span>
        )}
      </div>
    )
  }
}
