import { getMap } from './getMap'

export function waitForMapToBeMounted() {
  return new Promise<void>(resolve => {
    const map = getMap()

    if (!map.getTargetElement()) {
      map.once('change:target', () => resolve())
    } else {
      resolve()
    }
  })
}
