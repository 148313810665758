import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: (count: number) =>
      translate({ id: 'setup.vehicleAssignments.view.title.bulk', values: { count } }),
    headerDescription: translate({ id: 'setup.vehicleAssignments.view.bulk.description' }),

    confirmPanelTitle: (count: number) =>
      translate({ id: 'setup.vehicleAssignments.delete.title', values: { count } }),
    confirmPanelSubtitle: translate({ id: 'setup.vehicleAssignments.delete.subtitle' }),

    delete: translate({ id: 'global.deleteAll' }),
  }))

  return api
}
