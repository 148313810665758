import { BackOfficeAdvancedProperties } from './BackOfficeAdvancedProperties'
import { OffAdvancedProperties } from './OffAdvancedProperties'

type Props = {
  voice: uui.domain.server.rm.VoiceConfig
}

export function AdvancedProperties(props: Props) {
  const { voice } = props

  if (voice.status !== 'ADVANCED') return null
  else {
    const isBackOffice = voice.callbackConfig.status === 'BACK_OFFICE'

    if (isBackOffice) return <BackOfficeAdvancedProperties voice={voice} />

    return <OffAdvancedProperties voice={voice} />
  }
}
