import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    errorFetchingAccountData: translate({ id: 'settings.rmAccount.view.errorFetchingAccountData' }),
    errorFetchingApiKey: translate({ id: 'settings.rmAccount.view.errorFetchingApiKey' }),
    updateAccountSuccess: translate({ id: 'settings.rmAccount.edit.save.success' }),
    updateAccountError: translate({ id: 'settings.rmAccount.edit.save.error' }),
  }))

  return api
}
