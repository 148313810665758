import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehicles } from '@/features/domain/vehicle'
import { selectOrderSteps } from '@/features/domain/order'

export const useDepotsLinkedToOrderSteps = () => {
  const vehicles = useSelector(selectVehicles)
  const ordersSteps = useSelector(selectOrderSteps)

  return useMemo(() => {
    const ids = new Set<string>()

    for (const orderStep of Object.values(ordersSteps)) {
      const { pickup, delivery } = orderStep
      if (pickup?.atDepot) {
        ids.add(pickup.depotId)
      }

      if (delivery?.atDepot) {
        ids.add(delivery.depotId)
      }
    }

    for (const vehicle of Object.values(vehicles)) {
      if (!vehicle.hasRoutingLicense) continue

      const {
        vehicle: { settings },
      } = vehicle

      for (const key in settings) {
        if (settings.hasOwnProperty(key)) {
          const { departureDepotId, arrivalDepotId } = settings[key]

          if (departureDepotId) {
            ids.add(departureDepotId)
          }

          if (arrivalDepotId) {
            ids.add(arrivalDepotId)
          }
        }
      }
    }

    return ids
  }, [vehicles, ordersSteps])
}
