import { PureComponent } from 'react'

import { DropdownItem, DropDownField, DropDownFieldCoreProps } from '@/forms-legacy'

import VehicleAssignmentDropDownProvider from '../../../../../../utils/VehicleAssignmentDropDownProvider'

export interface Props extends DropDownFieldCoreProps {
  vehicles: uui.domain.client.rm.Vehicle[]
}

export default class VehicleAssignmentDropDown extends PureComponent<Props> {
  render() {
    const { defaultValue } = this.props
    const { children, vehicles, ...props } = this.props

    return (
      <VehicleAssignmentDropDownProvider
        vehicles={vehicles}
        defaultValue={defaultValue}
        render={(values: DropdownItem[]) => (
          <DropDownField {...props} values={values} testid="vehicle-assignment" />
        )}
      />
    )
  }
}
