import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Pickup(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M16.9864 10.86412c-.24703-.0586-1.08773-.97796-1.83546-2.00893l-.03055-.03627C14.77375 8.4534 14.34079 8 13.52 8h-3.05602C9.84242 8 9.44 8.40597 9.44 9.03376v1.82338H5.36c-.85133 0-1.36.53432-1.36 1.42857v2.96736c0 .54827.43828.96122 1.02.96122h.71055C5.8939 17.22573 6.73062 18 7.74 18c1.00938 0 1.8461-.77427 2.00945-1.78571h5.5011C15.4139 17.22573 16.25062 18 17.26 18c1.00937 0 1.84742-.77427 2.00945-1.78571H19.98c.5432 0 1.02-.50084 1.02-1.07143V13c0-1.23047-3.34289-1.99498-4.0136-2.13588zM14.62368 9.3072c.27625.37946.77695 1.03934 1.27102 1.54994H12.5V8.7143h1.02c.52992 0 .78625.26088 1.10367.5929zM10.12 9.03376c0-.12695 0-.31947.34398-.31947H11.82v2.14285h-1.7V9.03376zm-2.38 8.25195c-.7504 0-1.36-.64034-1.36-1.42857 0-.78822.6096-1.42857 1.36-1.42857.7504 0 1.36.64035 1.36 1.42857 0 .78823-.6096 1.42857-1.36 1.42857zm9.52 0c-.7504 0-1.36-.64034-1.36-1.42857 0-.78822.6096-1.42857 1.36-1.42857.75039 0 1.36.64035 1.36 1.42857 0 .78823-.60961 1.42857-1.36 1.42857z" />
      </svg>
    </IconContainer>
  )
}
