import type { VehicleForCopySettings } from '../../typings'
import type { MouseEvent } from 'react'
import type { SearchableListTypes } from '@/forms-legacy'

import { useCallback } from 'react'

import { Box } from '@mui/material'

import { colorsUtils } from '@/utils'
import { FlexRow, FlexColumn, ReadonlyLabel, Dot, Checkbox } from '@/forms-legacy'

import { VehicleListItemWrapper } from '../../elements/VehicleListItemWrapper'
import { TransparentButton } from '../../elements/TransparentButton'

import { useTexts } from './useTexts'

export function VehicleListItem(props: SearchableListTypes.ListItemProps<VehicleForCopySettings>) {
  const { onChange, value, disabled, readonly, selected } = props

  const texts = useTexts()

  const hasExistingException = value.hasExistingException
  const id = value.id
  const color = colorsUtils.getValidHexColor(value.color)
  const vehicleName = value.externalId

  const onClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { shiftKey, metaKey, ctrlKey, altKey } = event
      onChange([value], { shiftKey, metaKey, ctrlKey, altKey })
    },
    [onChange, value],
  )

  return (
    <VehicleListItemWrapper key={id}>
      <FlexColumn primary>
        <FlexRow>
          <TransparentButton onClick={onClick} disabled={disabled}>
            <Box marginRight={1} style={{ pointerEvents: 'none' }}>
              <Checkbox
                checked={!!selected}
                id={id}
                disabled={disabled || readonly}
                onChange={() => undefined}
              />
            </Box>
            {vehicleName}
          </TransparentButton>
        </FlexRow>
      </FlexColumn>
      <FlexColumn>
        <ReadonlyLabel small empty style={{ marginRight: 6 }}>
          {hasExistingException ? texts.override : texts.add}
        </ReadonlyLabel>
      </FlexColumn>
      <FlexColumn>
        <Box width={16} height={16}>
          <Dot outerColor={color} innerColor={color} />
        </Box>
      </FlexColumn>
    </VehicleListItemWrapper>
  )
}
