import type { SelectionOptions } from '../../utils/applySelection'
import type { MainSelection } from '../typings'

import { snapshot } from 'valtio'

import { parseModifier, applySelection } from '../../utils/applySelection'
import { mainAtom } from '../main'
import { resetMainSelection } from './resetMainSelection'

type SetMainSelection<Category extends keyof MainSelection> = (
  prev: workwave.DeepReadonly<MainSelection[Category]>,
) => MainSelection[Category]

export type SetMainSelectionParam<Category extends keyof MainSelection> =
  | SetMainSelection<Category>
  | MainSelection[Category]
  | 'reset'

export const setMainSelection = <Category extends keyof MainSelection = keyof MainSelection>(
  category: Category,
  valueOrFunc: SetMainSelectionParam<Category>,
  options?: SelectionOptions,
) => {
  // reset
  if (valueOrFunc === 'reset') return resetMainSelection(category)

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    mainAtom.selection[category] = valueOrFunc(snapshot(mainAtom.selection[category]))
  } else {
    const ids = valueOrFunc
    const { force, modifiers } = options ?? {}
    const { noModifiers } = parseModifier(modifiers)

    // TODO: drop this behavior favoring only 'reset'? Or do we want to keep both possibilities?
    // RESET the target Selection's atom and exit!
    if (ids.length === 0) {
      return resetMainSelection(category)
    }

    // ATTENTION: MAIN only
    // reset all others MAIN selections
    if (!!force || noModifiers) {
      // reset all others selection
      for (const cat of Object.keys(mainAtom.selection)) {
        // If the category is the same we got to skip it
        if (cat === category) continue

        // If the selection of the category is already empty we avoid to assign another
        // empty array to preserve the reference
        if (mainAtom.selection[cat].length === 0) continue

        mainAtom.selection[cat] = []
      }
    }

    const prevSelection = mainAtom.selection[category]

    mainAtom.selection[category] = applySelection(
      prevSelection,
      ids,
      options,
    ) as MainSelection[Category]
  }

  return mainAtom.selection[category]
}
