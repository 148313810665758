import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useEditingStateWithCtx, resetCrudSelection, useCrudSelection, useIsLoading } from '@/atoms'

import { selectGeofencesListStructure } from '@/features/domain/lists'

export const useDataForList = () => {
  const [selection, setSelection] = useCrudSelection('geofences')
  const { setEditing } = useEditingStateWithCtx('geofence')
  const structure = useSelector(selectGeofencesListStructure)
  const isLoading = useIsLoading('fetchGpsData')

  const lastSelectedGeofence = selection.length > 0 ? selection[selection.length - 1] : undefined

  const setCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  const deselectAll = () => resetCrudSelection('geofences')

  return {
    lastSelectedGeofence,
    setSelection,
    deselectAll,
    setEditing,
    structure,
    setCreate,
    isLoading,
  }
}
