import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    placeholder: translate({ id: 'setup.reasonsForNotCompletedOrders.form.placeholder' }),
    remove: translate({ id: 'setup.reasonsForNotCompletedOrders.form.deleteReason' }),
    moveUp: translate({ id: 'setup.reasonsForNotCompletedOrders.form.moveUpReason' }),
    moveDown: translate({ id: 'setup.reasonsForNotCompletedOrders.form.moveDownReason' }),
    firstReason: translate({ id: 'setup.reasonsForNotCompletedOrders.form.firstReason' }),
    lastReason: translate({ id: 'setup.reasonsForNotCompletedOrders.form.lastReason' }),
    oneReason: translate({ id: 'setup.reasonsForNotCompletedOrders.form.oneReason' }),
  }))

  return api
}
