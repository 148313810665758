import { ListItemAvatarRounded } from '@/components/List'

import { useVehicleCalendarizedInfo } from '../../hooks/useVehicleCalendarizedInfo'

import { DeviceIcon } from './elements/DeviceIcon'
import { useStyles } from './hooks/useStyles'

interface Props {
  vehicle: uui.domain.client.UnifiedVehicle
}

export function Avatar(props: Props) {
  const { vehicle } = props

  const { avatarLabel } = useVehicleCalendarizedInfo(vehicle)
  const avatarLabelFormatted = avatarLabel.toUpperCase().substring(0, 3)
  const { backgroundColor = '#fff' } = useStyles(vehicle)

  const iconId =
    vehicle.isPhysicalDevice && vehicle.trackingProvider === 'WWGPS'
      ? vehicle.device.iconId
      : undefined
  const showLabel = vehicle.hasRoutingLicense || !iconId

  return (
    <ListItemAvatarRounded color={backgroundColor}>
      {showLabel ? avatarLabelFormatted : <DeviceIcon iconId={iconId} />}
    </ListItemAvatarRounded>
  )
}
