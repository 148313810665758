import { mapAtom } from '../../atoms/map/map'
import { findLayer } from '../utils/findLayer'
import { changelogAddBreadcrumbFeatures } from './utils/changelogAddBreadcrumbFeatures'
import { changelogRemoveBreadcrumbFeatures } from './utils/changelogRemoveBreadcrumbFeatures'
import { changelogUpdateBreadcrumbFeatures } from './utils/changelogUpdateBreadcrumbFeatures'

export function createUpdateBreadcrumbFeaturesFromChangelog(
  markers: uui.domain.ui.map.markers.MapMarkers['breadcrumb'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['breadcrumb'],
  selection: Set<string>,
  changelog: uui.domain.api.Changelog,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  // ATTENTION: Currently all changelog are applied as soon as they arrive, also when the map is not visible
  // `ignoreSelection` it will be used to avoid to apply any selection information to the map when applying cached updater functions.
  // after all cached updates will be applied a refresh selection action will be required
  return async (_ignoreSelection: boolean = false) => {
    const map = mapAtom.map
    const breadcrumbPathLayer = findLayer(map, 'breadcrumbPath')
    const breadcrumbPointsLayer = findLayer(map, 'breadcrumbPoints')

    const layers = { breadcrumbPathLayer, breadcrumbPointsLayer }
    // ------------------------
    // Breadcrumb stops layer
    // ------------------------

    // remove
    await changelogRemoveBreadcrumbFeatures(layers, selection, changelog.remove.values())

    // add
    await changelogAddBreadcrumbFeatures(
      layers,
      markers,
      mapStyles,
      selection,
      changelog.add.values(),
      breadcrumbTimeRange,
    )

    // update
    await changelogUpdateBreadcrumbFeatures(
      layers,
      markers,
      mapStyles,
      selection,
      changelog.update.values(),
      breadcrumbTimeRange,
    )
  }
}
