import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    label: translate({ id: 'vehicles.form.driverBehaviors.maxSpeedLimit' }),
    stepLabel: translate({ id: 'vehicles.form.driverBehaviors.maxSpeed' }),
    unitOfMeasure: (label: string, metric: boolean) => {
      const uom = translate({
        id: metric ? 'global.unitOfMeasure.kmh' : 'global.unitOfMeasure.mph',
      })
      return `${label} (${uom})`
    },
  }))

  return api
}
