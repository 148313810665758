import { useRefreshGpsData } from '@/hooks'
import { useResetCrudSelectionOnUnmount } from '@/atoms'
import { useGeofencesRoot } from './hooks/useGeofencesRoot'
import { List } from './List'

import { useHideGeofenceWhileEditing } from './hooks/useHideGeofenceWhileEditing'
import { useRefreshGeofenceLocations } from './hooks/useRefreshGeofenceLocations'
import { SingleView } from './Single/View'
import { SingleEdit } from './Single/Edit'
import { BulkView } from './Bulk/View'

/**
 * HOW DOES GEOFENCES ARE REVERSE-GEOCODED?
 *
 * In RouteManager V2 we used to reverse-geocode a single geofence once the user access the detail
 * of the geofence.
 *
 * In RouteManager V3 we bulk reverse-geocode all the geofences once the user access the
 * Setup->Geofences section.
 *
 * That component will perform asynchronously:
 * - A force poll for geofences entities
 * - A reverse geocode of all the geofences
 *
 * The operations can be performed asynchronously since
 * - If the gps changeset will be ready before the completion of the reverse geocoding all the geofences
 *   will be done by the second operation (useRefreshGeofenceLocations).
 *
 * - If the reverse geocoding will be completed before the changeset, the new/updated geofences
 *   will be reverse-geocoded by the process of the the GPS changeset
 *
 */
export function GeofencesRoot() {
  const { editing, geofences, selection, selectedGeofences } = useGeofencesRoot()

  const single = selectedGeofences.length === 1
  const bulk = selectedGeofences.length > 1
  const geofence = selectedGeofences[0]

  useHideGeofenceWhileEditing(editing, selection[0])

  // Let's request a refresh of GPS data
  useRefreshGpsData('geofence')

  useResetCrudSelectionOnUnmount('geofences')

  // Let's request a refresh of the locations
  useRefreshGeofenceLocations()

  return (
    <>
      <List editing={editing} geofences={geofences} selection={selection} />
      {single && !editing && geofence && <SingleView geofence={geofence} />}
      {bulk && <BulkView geofences={selectedGeofences} />}
      {editing && !bulk && <SingleEdit geofence={geofence} />}
    </>
  )
}
