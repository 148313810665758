import OlMap from 'ol/Map'
import { Extent } from 'ol/extent'
import Geometry from 'ol/geom/Geometry'

import { createNonBlockingRenderByTime } from '../../../../renderingQueue'
import { getDeviceFeatureMetadata } from '../../../../layers/device/deviceFeatureMetadata'
import { findLayer } from '../../../../layers/utils/findLayer'

const nonblockingRender = createNonBlockingRenderByTime()

export async function selectDeviceFeatureInExtent(
  map: OlMap,
  lassoPolygon: Geometry,
  lassoExtent: Extent,
) {
  const unifiedDeviceLayer = findLayer(map, 'device')
  const selectedIds: string[] = []

  const featuresInExtent = unifiedDeviceLayer.getSource()?.getFeaturesInExtent(lassoExtent) ?? []

  nonblockingRender.reset()

  for (const feature of featuresInExtent) {
    await nonblockingRender.next()

    const mode = getDeviceFeatureMetadata(feature, 'mode')
    if (mode === 'off') continue

    const recordId = feature.getId() as string

    if (recordId) {
      const extent = feature.getGeometry()?.getExtent()

      if (extent && lassoPolygon.intersectsExtent(extent)) {
        selectedIds.push(recordId)
      }
    }
  }

  return selectedIds
}
