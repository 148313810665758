import { useMemo } from 'react'
import { getColorValue } from '@/styles'

type Color = string
export function useRouteColorsByOrders(
  extOrders: uui.domain.client.rm.ExtendedOrderStep[],
): Record<string, Color> {
  return useMemo(
    () =>
      extOrders.reduce<Record<string, Color>>((acc, extOrder) => {
        if (!extOrder.isUnassigned && !acc[extOrder.routeId]) {
          acc[extOrder.routeId] = getColorValue(extOrder.color)
        }

        return acc
      }, {}),
    [extOrders],
  )
}
