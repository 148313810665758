import { useCallback } from 'react'

import { useNavigate } from '@/routing'
import { usePagination, setMainSelection } from '@/atoms'

import { useNavigoView } from '../../../hooks/useNavigoView'
import { useOrderStepsNavigator } from '../../../hooks/useOrderStepsNavigator'

export const useExecutionDetailsNavigation = () => {
  const navigate = useNavigate(true)
  const { currentOrderIds } = useOrderStepsNavigator()
  const { setPage: setOrderFormTab } = usePagination('orderTab')
  const {
    navigate: { goToOverview },
  } = useNavigoView()

  const goToDetail = useCallback(() => {
    setMainSelection('orderSteps', currentOrderIds)
    setOrderFormTab(1)
    navigate('orders')
  }, [setOrderFormTab, navigate, currentOrderIds])

  return { goToDetail, goToOverview }
}
