import { Icon, Style } from 'ol/style'

import breadcrumbsSpriteSheet from '@/resources/map-markers/breadcrumbs.png'
import { genericStyles } from '../../../genericStyles'
import { isPointOutOfTimeRange } from '../isPointOutOfTimeRange'

const breadcrumbMarkerStylesCache: Map<string, Style[]> = new Map()

/**
 * Return the right style to be used by a Breadcrumb  feature
 */
export function getBreadcrumbPointsFeatureStyle(
  point: uui.domain.ui.map.markers.BreadcrumbPoint,
  mode: uui.domain.ui.map.markers.MapStyles['breadcrumb']['mode'],
  selected: boolean,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  if (mode === 'off' || isPointOutOfTimeRange(point, breadcrumbTimeRange))
    return genericStyles.hidden

  const coordinates: uui.domain.ui.map.markers.GridPlacement = selected
    ? point.selected
    : point.normal

  const x = coordinates[0]
  const y = coordinates[1]
  const cellSize = coordinates[2]

  const cacheId = `${x}_${y}`

  if (breadcrumbMarkerStylesCache.has(cacheId)) {
    const cachedStyle = breadcrumbMarkerStylesCache.get(cacheId)
    if (cachedStyle) return cachedStyle
  }

  const styles = [
    // Full stroke
    new Style({
      image: new Icon({
        src: breadcrumbsSpriteSheet,
        crossOrigin: 'anonymous',

        scale: 0.5,
        opacity: 1.0,
        rotation: 0.0,
        rotateWithView: true,

        size: [cellSize, cellSize],
        offset: [x, y],

        anchor: [0.5, 0.5],
        anchorOrigin: 'top-left',
        anchorYUnits: 'fraction',
        anchorXUnits: 'fraction',
      }),
      zIndex: selected ? 2 : 0,
    }),
  ]

  breadcrumbMarkerStylesCache.set(cacheId, styles)

  return styles
}
