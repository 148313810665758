import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    configuredCustomField: translate({ id: 'global.configuredCustomField' }),
    'communicatedEtaRanges.twoDaysNotification': translate({
      id: 'orders.grid.column.communicatedEtaRanges.twoDaysNotification',
    }),
    'communicatedEtaRanges.oneDayNotification': translate({
      id: 'orders.grid.column.communicatedEtaRanges.oneDayNotification',
    }),
    'communicatedEtaRanges.sameDayNotification': translate({
      id: 'orders.grid.column.communicatedEtaRanges.sameDayNotification',
    }),
    executedCustomField: translate({ id: 'global.executedCustomField' }),
    shopifyOrderName: translate({ id: 'orders.grid.column.shopifyOrderName' }),
    requiredVehicle: translate({ id: 'orders.grid.column.requiredVehicle' }),
    configuredLoad: translate({ id: 'global.configuredLoad' }),
    scheduleTime: translate({ id: 'orders.grid.column.scheduleTime' }),
    addressCheck: translate({ id: 'orders.grid.column.addressCheck' }),
    executedLoad: translate({ id: 'global.executedLoad' }),
    requiredTags: translate({ id: 'orders.grid.column.requiredTags' }),
    customField: translate({ id: 'global.customField' }),
    eligibility: translate({ id: 'orders.grid.column.eligibility' }),
    plannedTime: translate({ id: 'orders.grid.column.plannedTime' }),
    serviceTime: translate({ id: 'orders.grid.column.serviceTime' }),
    timeWindow1: translate({ id: 'orders.grid.column.timeWindow1' }),
    timeWindow2: translate({ id: 'orders.grid.column.timeWindow2' }),
    assignedTo: translate({ id: 'orders.grid.column.assignedTo' }),
    bannedTags: translate({ id: 'orders.grid.column.bannedTags' }),
    orderType: translate({ id: 'orders.grid.column.orderType' }),
    idleTime: translate({ id: 'orders.grid.column.idleTime' }),
    barcodes: translate({ id: 'orders.grid.column.barcodes' }),
    priority: translate({ id: 'orders.grid.column.priority' }),
    address: translate({ id: 'orders.grid.column.address' }),
    orderId: translate({ id: 'orders.grid.column.orderId' }),
    pairId: translate({ id: 'orders.grid.column.pairId' }),
    stopNo: translate({ id: 'orders.grid.column.stopNo' }),
    status: translate({ id: 'orders.grid.column.status' }),
    notes: translate({ id: 'orders.grid.column.notes' }),
    phone: translate({ id: 'orders.grid.column.phone' }),
    email: translate({ id: 'orders.grid.column.email' }),
    name: translate({ id: 'orders.grid.column.name' }),
    company: translate({ id: 'orders.grid.column.company' }),
    load: translate({ id: 'global.load' }),
  }))

  return api
}
