import { getMatchingRecurrenceByDate } from '@/local/server-data/utils/recurrences'

export const normalizeDeviceIconId = (iconId: number) => {
  if (iconId > 100) {
    return iconId
  }

  if (iconId >= 1 && iconId <= 4) {
    return 103
  }

  if (iconId === 10) {
    return 104
  }

  if (iconId === 20) {
    return 103
  }

  return 103
}

export function getAssociatedUnifiedVehicleId(
  device: uui.domain.client.gps.wwgps.DeviceInfo | uui.domain.client.gps.telematics.DeviceInfo,
  drivers: Record<string, uui.domain.client.rm.ExtendedDriver>,
  driverAssignments: Record<string, uui.domain.client.rm.DriverAssignments>,
  vehiclesByDeviceId: Record<string, string>,
  vehiclesByVehicleId: Record<string, string>,
  recurrence: string = 'any',
) {
  // --------------------------------------
  // Physical device
  // --------------------------------------
  if (!device.virtual) return vehiclesByDeviceId[device.deviceId] // Already UnifiedVehicleId

  // --------------------------------------
  // Virtual device
  // --------------------------------------

  // Find associated driver
  const associatedDriver = Object.values(drivers).find(
    d => d.type === 'linked' && d.device.deviceId === device.deviceId,
  )

  // If there's no associated driver for the virtual device it cannot be associated to any vehicle
  if (!associatedDriver) return

  // Find recurrence for the current date
  const driverAssignmentsRecurrences = Object.keys(driverAssignments)
  const matchingRecurrence = getMatchingRecurrenceByDate(recurrence, driverAssignmentsRecurrences)

  // Loop assignments to find the vehicle and assign the color
  for (const vehicleId in driverAssignments[matchingRecurrence]) {
    if (driverAssignments[matchingRecurrence][vehicleId] === associatedDriver.id)
      return vehiclesByVehicleId[vehicleId]
  }
}
