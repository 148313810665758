import { useController } from '../../../../hooks/useController'

import { MultipleDrivers } from './components/MultipleDrivers'
import { SingleDriver } from './components/SingleDriver'

export function InvalidFooter() {
  const {
    data: { driverIds },
  } = useController()

  return driverIds.length > 1 ? <MultipleDrivers /> : <SingleDriver />
}
