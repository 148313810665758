import type { BulkLoadPartial } from '../../../../../types'

import { Stack, IconButton, TextField, Tooltip } from '@mui/material'
import { RemoveCircleOutline, AddCircleOutline } from '@mui/icons-material'

import { useTexts } from './hooks/useTexts'

interface Props {
  load: BulkLoadPartial
  onDeleteLoad: (name: string) => void
  onAddLoad: (name: string) => void
  addable?: boolean
  ordersCount: number
  maxLoadsForOrder: number
  disabled?: boolean
}

export function PartialLoad(props: Props) {
  const { load, onDeleteLoad, onAddLoad, ordersCount, addable, maxLoadsForOrder, disabled } = props
  const { name, value } = load

  const texts = useTexts()
  const addDisabled = !addable || disabled

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
      <TextField disabled value={name} label={texts.label(value, ordersCount)} />
      <Tooltip
        title={
          disabled
            ? texts.fieldDisabled
            : addDisabled
            ? texts.addTooltipDisabled(maxLoadsForOrder)
            : texts.addTooltip
        }
      >
        <span>
          <IconButton
            disabled={addDisabled || disabled}
            color="success"
            onClick={() => onAddLoad(load.name)}
          >
            <AddCircleOutline />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={disabled ? texts.fieldDisabled : texts.removeTooltip}>
        <span>
          <IconButton color="error" disabled={disabled} onClick={() => onDeleteLoad(load.name)}>
            <RemoveCircleOutline />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  )
}
