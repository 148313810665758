import type ImageTile from 'ol/ImageTile'
import { mapAtom } from '../map'

export async function authenticatedRasterTileLoadFunction(imageTile: ImageTile, src: string) {
  // Get the authentication token from the map atom
  const { authenticationToken } = mapAtom

  // Abort if the authentication token is not valid
  if (!authenticationToken) return

  // Append the authentication token to the tile URL (if authentication token is of type 'apiKey')
  const finalUrl =
    authenticationToken.type === 'apiKey' ? `${src}&apiKey=${authenticationToken.value}` : src

  // Define the headers for the fetch request (if authentication token is of type 'oAuth')
  const params =
    authenticationToken.type === 'oAuth'
      ? {
          headers: { Authorization: `Bearer ${authenticationToken.value}` },
        }
      : undefined

  // Fetch the tile data
  try {
    const response = await fetch(finalUrl, params)

    const image = imageTile.getImage() as HTMLImageElement
    image.src = URL.createObjectURL(await response.blob())
  } catch (e) {
    console.error(e.message)
  }
}
