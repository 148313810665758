import type { ReactElement } from 'react'

import { Text } from '@/local/components'
import { VerticalLayout } from '@/components/layout'

import { ButtonContainer } from './ButtonContainer'

interface Props {
  Icon: ReactElement
  text: string
  onClick: () => void
  active?: boolean
  disabled?: boolean
  className?: string
  testid?: string
  as?: 'span' | 'button'
}

export function NavigoIconButton(props: Props) {
  const {
    text,
    Icon,
    active = false,
    disabled = false,
    onClick,
    className,
    testid,
    as = 'button',
    ...rest
  } = props

  return (
    <ButtonContainer
      {...rest}
      as={as}
      active={active}
      onClick={onClick}
      disabled={disabled}
      data-testid={testid}
      className={className}
      data-trackid={testid}
    >
      <VerticalLayout width="60" justifyContent="space-between" alignItems="center">
        {Icon}

        <Text size="$s" color={disabled ? '$silver' : '$nightRider'} textAlign="center">
          {text}
        </Text>
      </VerticalLayout>
    </ButtonContainer>
  )
}
