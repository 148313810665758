import { ModalFooter, ModalFooterButton } from '@/components/Modal'

import { useController } from '../../hooks/useController'

import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'

export function Footer() {
  const {
    status,
    close,
    data: { userIds },
  } = useController()

  const texts = useTexts()
  const submit = useOnSubmit()

  const submitting = status === 'submitting'

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          color="error"
          variant="contained"
          onClick={submit}
          loading={submitting}
          disabled={submitting}
          maxWidth="50%"
          testid="settings-delete-users-modal-delete-button"
        >
          {texts.delete(userIds.length)}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          variant="text"
          onClick={close}
          disabled={submitting}
          testid="settings-delete-users-modal-cancel-button"
        >
          {texts.cancel(userIds.length)}
        </ModalFooterButton>
      }
    />
  )
}
