import { Modal } from '@/components/Modal'

import { Content } from './Content'

type Props = {
  label: string
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery'
  pinType: uui.domain.ui.map.LocationPinType
  onChange: (payload: uui.domain.client.Location) => void
  location: uui.domain.client.Location
}

export function Root(props: Props) {
  const { label, location, pinType, pinId, onChange } = props

  return (
    <Modal modalId={pinId}>
      <Content
        location={location}
        onChange={onChange}
        pinType={pinType}
        label={label}
        pinId={pinId}
      />
    </Modal>
  )
}
