import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import { selectTerritoryNotificationBehavior } from '@/features/domain/territory'

export function useNotificationBehavior() {
  const userConfig = useSelector(selectUserConfiguration)
  const notificationBehavior = useSelector(selectTerritoryNotificationBehavior)

  const territoryHasNoNotifications = notificationBehavior === 'NO_NOTIFICATIONS'
  const rmOnly = userConfig.userType === 'rmOnly'

  const communicationLinkDisabled = territoryHasNoNotifications || rmOnly

  return {
    rmOnly,
    communicationLinkDisabled,
  }
}
