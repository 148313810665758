import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectPhysicalDevices } from '@/features/domain/device'

import { useTexts } from './useTexts'
import { useAccountEmail } from './useAccountEmail'

export function useNewAlertValue() {
  const texts = useTexts()
  const devices = useSelector(selectPhysicalDevices)
  const defaultEmail = useAccountEmail()

  return useMemo<uui.domain.client.gps.wwgps.EventAlert>(() => {
    const deviceIds = devices.map(device => device.deviceId)

    return {
      id: '-1',
      userId: -1,
      organizationId: -1,
      name: texts.newAlert,
      eventTypeId: 500,
      notificationAddresses: [`mailto:${defaultEmail}`],
      deviceIds,
    }
  }, [texts, defaultEmail, devices])
}
