import type { TestValues } from '../types'
import type { NotificationFormType } from '@/notificationForms'
import { unproxify } from '@/utils'

export function generateTestNotificationPayload(
  notification: NotificationFormType,
  recipient: string,
  values: TestValues,
) {
  return unproxify({
    windowOfCommitmentToleranceMins: values.windowOfCommitmentToleranceMins
      ? parseInt(values.windowOfCommitmentToleranceMins)
      : undefined,
    addUnsubscribe: values.includeMessageUnsubscriptionInstructions || false,
    addTrackingLink: values.includeMessageTrackingLink || false,
    notificationWay: values.notificationWay || 'EMAIL',
    message: values.templateCustom || '',
    subject: values.subjectCustom || '',
    limits: values.templateCustomLimits,
    notificationType: notification,
    recipient,
  })
}
