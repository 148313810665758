import { useReducer } from 'react'

import { IconButton } from '@/components/primitives/buttons'
import { HorizontalLayout } from '@/components/layout'
import { DoubleArrow } from '@/icons'

import { ExpandedActions } from './ExpandedActions'

import { useShareLocation } from './hooks/useShareLocation'
import { useActions } from './hooks/useActions'

const rotateIcon = { transform: 'rotate(-180deg)' }

export function Actions() {
  const [expanded, toggleExpanded] = useReducer(state => !state, true)
  const { currentDepots } = useActions()
  const { modal: shareLocationModal } = useShareLocation()

  if (currentDepots.length === 0) return null

  return (
    <HorizontalLayout width="auto" alignItems="center">
      {expanded && <ExpandedActions />}

      <IconButton onClick={toggleExpanded}>
        <DoubleArrow size={10} color="$nightRider" style={expanded ? undefined : rotateIcon} />
      </IconButton>
      {shareLocationModal}
    </HorizontalLayout>
  )
}
