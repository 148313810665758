import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    switchTerritory: translate({ id: 'app.header.territoryMenu.switch' }),
    renameTerritory: translate({ id: 'app.header.territoryMenu.rename' }),
    deleteTerritory: translate({ id: 'app.header.territoryMenu.delete' }),
    timeShiftPlan: translate({ id: 'app.header.territoryMenu.timeShift' }),
    importNewTerritory: translate({ id: 'app.header.territoryMenu.import' }),
    territorySettings: translate({ id: 'app.header.territoryMenu.settings' }),
    importDemoTerritory: translate({ id: 'app.header.territoryMenu.importDemo' }),
    tooltipRm: (territory: string) =>
      translate({ id: 'app.header.territoryMenu.tooltip.rmUser', values: { territory } }),
    editingTransactionInProgress: translate({
      id: 'global.disabledTooltip.transactionInProgress.title',
    }),
    startTelematicsDemo: translate({ id: 'app.header.territoryMenu.startTelematicsDemo' }),
  }))

  return api
}
