import type { PartialStoreState } from '../typings'

import { createSelector } from '@reduxjs/toolkit'

export const selectOrders = (state: PartialStoreState) => state.domain.publicData.domain.rm.orders
export const selectOrderSteps = (state: PartialStoreState) => state.domain.extendedOrderSteps
export const selectOrderStepIdsInRange = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.orderStepsIdInRange

export const selectOrdersMap = createSelector(selectOrderSteps, extOrderSteps => {
  return Object.values(extOrderSteps).reduce<Record<string, uui.domain.client.rm.Order>>(
    (acc, ext) => {
      acc[ext.order.id] = ext.order
      return acc
    },
    {},
  )
})

export const selectOrderCustomFields = createSelector(selectOrderSteps, extOrders => {
  const customFieldsColumns: Set<string> = new Set()

  for (const order of Object.values(extOrders)) {
    if (order.pickup?.customFields) {
      Object.keys(order.pickup?.customFields).map((name: string) => {
        customFieldsColumns.add(name)
      })
    }

    if (order.delivery?.customFields) {
      Object.keys(order.delivery?.customFields).map((name: string) => {
        customFieldsColumns.add(name)
      })
    }
  }

  return customFieldsColumns
})

export const selectOrdersGridRows = (state: PartialStoreState) => state.domain.ordersGridRows

export const selectLoadsFromOrders = createSelector(selectOrders, orders => {
  const loadsSet = new Set<string>()

  for (const extOrder of Object.values(orders)) {
    const {
      order: { loads },
    } = extOrder

    for (const load of Object.keys(loads)) {
      loadsSet.add(load)
    }
  }

  return Array.from(loadsSet)
})
