import type { FormErrors, FormFields } from '../../formFields'
import { Box, FormControl, FormLabel } from '@mui/material'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { Checkbox } from '@workwave-tidal/tidal/form-ui'
import { useTexts } from './hooks/useTexts'

export function RestrictionsField() {
  const texts = useTexts()
  const {
    field: { visible },
  } = useFormField<'forbidDriverCallsToCompletedOrderSteps', FormFields, FormErrors>(
    'forbidDriverCallsToCompletedOrderSteps',
  )

  if (!visible) return null

  return (
    <FormControl variant="standard" component="fieldset">
      <Box paddingBottom={0.5}>
        <FormLabel component="legend">{texts.restrictions.label}</FormLabel>
      </Box>
      <Checkbox<'forbidDriverCallsToCompletedOrderSteps', FormFields, FormErrors>
        label={texts.forbidDriverCallsToCompletedOrderSteps.label}
        name="forbidDriverCallsToCompletedOrderSteps"
        validateOn="change blur"
      />
      <Checkbox<'forbidDriverCallsToIncompleteOrderSteps', FormFields, FormErrors>
        label={texts.forbidDriverCallsToIncompleteOrderSteps.label}
        name="forbidDriverCallsToIncompleteOrderSteps"
        validateOn="change blur"
      />
    </FormControl>
  )
}
