import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    changePassword: translate({ id: 'settings.gpsAccount.view.changePasswordModal.controls.send' }),
    cancel: translate({ id: 'settings.gpsAccount.view.changePasswordModal.controls.cancel' }),

    changePasswordSuccess: translate({
      id: 'settings.gpsAccount.view.changePasswordModal.toast.success',
    }),
    changePasswordGenericError: translate({
      id: 'settings.gpsAccount.view.changePasswordModal.toast.genericError',
    }),
    changePasswordWrongOldPasswordError: translate({
      id: 'settings.gpsAccount.view.changePasswordModal.toast.wrongOldPasswordError',
    }),
  }))

  return api
}
