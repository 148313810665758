import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Order(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 12 14">
        <path d="M1.6 0h8.8A1.6 1.6 0 0 1 12 1.6v8.8a1.6 1.6 0 0 1-1.6 1.6H8l-1.6 1.6L4.8 12H1.6A1.6 1.6 0 0 1 0 10.4V1.6A1.6 1.6 0 0 1 1.6 0z" />
      </svg>
    </IconContainer>
  )
}
