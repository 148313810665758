import type { FormValidator, FormField, FormError } from '@workwave-tidal/tidal/form-fairy'

interface Validation<
  FORM_FIELDS extends Record<string, FormField>,
  FORM_ERRORS extends FormError<keyof FORM_FIELDS>,
> {
  id: string
  validator: FormValidator<FORM_FIELDS, FORM_ERRORS>
  fields?: keyof FORM_FIELDS
}

export function useValidations<
  FORM_FIELDS extends Record<string, FormField>,
  FORM_ERRORS extends FormError<keyof FORM_FIELDS>,
>() {
  const field = 'label'
  const validateLabel: Validation<FORM_FIELDS, FORM_ERRORS> = {
    id: `validate_label`,
    validator: formApi => {
      const { value } = formApi.getField(field)

      if (typeof value !== 'string')
        throw new Error('The provided address field should be a string')

      const emptyID = `label_too_short`
      const empty = value.trim().length === 0

      const emptyError = {
        id: emptyID,
        field: field,
        message: 'Enter an address name',
        priority: 30,
      }

      return {
        [emptyID]: empty ? emptyError : null,
      }
    },
    fields: field,
  }

  return [validateLabel]
}
