import { getMap } from '../../atoms/map/utils/getMap'
import { findLayer } from '../utils/findLayer'

import { mapStylesUpdateRoutePolylineFeatures } from './utils/mapStylesUpdateRoutePolylineFeatures'

export function createUpdateRoutePolylineFeaturesMapStyleFromChangelog(
  markers: uui.domain.ui.map.markers.MapMarkers['routePolyline'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['routePolyline'],
) {
  // ATTENTION: Application of Map Style changes will not be cached if a delayed rendering mechanism will be implemented
  // After the whole set of delayed marker changelogs is applied a full refresh of Map Styles will be required

  return async () => {
    const map = getMap()
    const layer = findLayer(map, 'routePolyline')

    // only the `update` field is populated in a mapStyles changelog
    await mapStylesUpdateRoutePolylineFeatures(layer, markers, mapStyles)
  }
}
