import styled from 'styled-components'

interface Props {
  opaque?: boolean
}

export const OpaqueContainer = styled.div<Props>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  opacity: ${p => (p.opaque ? 0.25 : 1)};
`

OpaqueContainer.displayName = 'OpaqueContainer'
