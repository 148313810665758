import type { FormErrors, FormFields } from '../../formFields'

import { Checkbox } from '@workwave-tidal/tidal/form-ui'
import { useTexts } from '../../useTexts'

export function TrackingLink() {
  const texts = useTexts()

  return (
    <Checkbox<'includeMessageTrackingLink', FormFields, FormErrors>
      helperText={texts.trackingLinkHelperText}
      name="includeMessageTrackingLink"
      testId="includeMessageTrackingLink"
      label={texts.trackingLinkLabel}
      validateOn="blur focus"
    />
  )
}
