import type { ReactNode } from 'react'

import { useRef, useEffect } from 'react'
import { Scrollbar } from '@workwave-tidal/tidal/components'

import { useComponentSize } from '@/local/hooks'

type Props = {
  height: number
  onUpdateHeight: (value: number) => void
  scrollBars?: boolean
  children?: ReactNode
}

const style = {
  padding: '0 32px',
  position: 'relative',
} as const

export function ModalBody(props: Props) {
  const { height, children = null, onUpdateHeight } = props

  const compRef = useRef(null)
  const [dimensions] = useComponentSize(compRef, 100)

  const { height: dimensionY } = dimensions

  useEffect(() => {
    if (dimensionY > 0) {
      onUpdateHeight(dimensionY)
    }
  }, [dimensionY, onUpdateHeight])

  return props.scrollBars ? (
    <div style={{ width: '100%', height }}>
      <Scrollbar hideOverflow="x">
        <div ref={compRef} style={style}>
          {children}
        </div>
      </Scrollbar>
    </div>
  ) : (
    <div style={style}>{children}</div>
  )
}
