import { FeatureLike } from 'ol/Feature'
import Polygon from 'ol/geom/Polygon'
import Geometry from 'ol/geom/Geometry'

export function getFeatureCoordinates(feature: FeatureLike) {
  const geometry = feature.getGeometry()
  if (geometry instanceof Geometry && geometry.getType() === 'Polygon') {
    const poly = geometry as Polygon
    const coordinates = poly.getCoordinates().at(0)

    if (coordinates) {
      return coordinates
    }
  }

  return []
}
