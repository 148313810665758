import type { NotificationFormType } from '@/notificationForms'
import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

export type Params = {
  recipient: string
  notificationWay: uui.domain.client.rm.NotificationWay
  notificationType: NotificationFormType
  subject: string
  message: string
  windowOfCommitmentToleranceMins?: number
  addUnsubscribe?: boolean
  addTrackingLink?: boolean
  limits?: Record<string, number>
}

/**
 * Update the Almost There notification config.
 */
export const testNotificationMessage = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Params,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/territory/testNotificationMessage', async (payload: Params, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/territory/testNotificationMessage', {
      category: 'rpc',
      type: 'rpc/territory/testNotificationMessage',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {testNotificationMessage}`,
      { tags: ['rpc', 'territory'], info: error },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Test of notification message failed`,
      error,
    })
  }
})
