import { getMap } from '../../atoms/map/utils/getMap'
import { findLayer } from '../utils/findLayer'

import { mapStylesUpdateRoadSegmentFeatures } from './utils/mapStylesUpdateRoadSegmentFeatures'

export function createUpdateRoadSegmentFeaturesMapStyleFromChangelog(
  markers: uui.domain.ui.map.markers.MapMarkers['roadSegment'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['roadSegment'],
  selection: Set<string>,
) {
  // ATTENTION: Application of Map Style changes will not be cached if a delayed rendering mechanism will be implemented
  // After the whole set of delayed marker changelogs is applied a full refresh of Map Styles will be required

  return async () => {
    const map = getMap()
    const layer = findLayer(map, 'roadSegment')

    // only the `update` field is populated in a mapStyles changelog
    for (const profileId in markers) {
      await mapStylesUpdateRoadSegmentFeatures(layer, markers[profileId], mapStyles, selection)
    }
  }
}
