import styled from 'styled-components'

import { Text } from '@/local/components'

export const ErrorMessage = styled(Text)`
  min-height: 28px;
  font-style: italic;
  font-size: ${p => p.theme.fonts.$s};
  color: ${p => p.theme.colors.$outrageousRed};
`

ErrorMessage.displayName = 'ErrorMessage'
