import { Decorator } from 'final-form'
import createDecorator from 'final-form-calculate'
import { intl } from '@/intl'

import { gis, geo, parseOrderStepId } from '@/server-data'

import { timeWindowUtils } from '@/utils'

import { NONE_OPTION } from '../../../constants'

import { OrderFormValues, FormOrderStep } from './typings'

export const VEHICLE_ASSIGNMENT_AUTOMATIC_ID = 'AUTOMATIC_ASSIGNMENT'

const cloneOrderStep = (
  orderStep: uui.domain.client.rm.OrderStep,
): uui.domain.client.rm.OrderStep => {
  return {
    ...orderStep,
    barcodes: [...orderStep.barcodes],
    timeWindows: [...orderStep.timeWindows],
    tagsIn: [...orderStep.tagsIn],
    tagsOut: [...orderStep.tagsOut],
    customFields: { ...orderStep.customFields },
    timeWindowExceptions: Object.entries(orderStep.timeWindowExceptions).reduce(
      (acc: any, [key, val]) => {
        acc[key] = val.map(tw => ({ ...tw }))
        return acc
      },
      {},
    ) as Record<string, uui.domain.rm.TimeWindow[]>,
    // tracking data is not used in the order form so any valid value is correct
    trackingData: {
      timeInSec: -1,
      timeOutSec: -1,
      statusSec: -1,
      autoTimeInSec: -1,
      autoTimeOutSec: -1,
      isEmpty: true,
      pods: {},
      status: 'missing',
      statusReason: '',
      latestTrackedLoadLatLng: null,
      latestTrackedLoadSec: -1,
    },
  }
}

const transformOrderStepInFormOrderStep = (
  os: uui.domain.client.rm.OrderStep,
  depot?: uui.domain.client.rm.Depot,
): FormOrderStep => {
  let depotLocation: uui.domain.client.Location | undefined = undefined
  if (depot) {
    depotLocation = { ...depot.location }
    depotLocation.address = depot.name
  }

  const location = os.atDepot ? depotLocation : os.location

  return {
    ...cloneOrderStep(os),
    location: location ? { ...location } : undefined,
  }
}

const cloneOrder = (order: uui.domain.client.rm.Order): uui.domain.client.rm.Order => {
  return Object.assign(
    {},
    order,
    {
      eligibility: { ...order.eligibility },
      loads: { ...order.loads },
    },
    order.type === 'p' || order.type === 'pd'
      ? { pickup: cloneOrderStep(order.pickup) }
      : undefined,
    order.type === 's' || order.type === 'd' || order.type === 'pd'
      ? { delivery: cloneOrderStep(order.delivery) }
      : undefined,
  )
}

const transformOrderToFormOrder = (
  extOrder: uui.domain.client.rm.ExtendedOrderStep,
  companies: Record<string, uui.domain.client.rm.Company>,
): OrderFormValues => {
  const order = cloneOrder(extOrder.order)
  const depot = extOrder.atDepot ? extOrder.depot : undefined

  const hasZombieCompany = order.companyId !== null && !companies[order.companyId]

  order.loads = toClientLoads(order.loads)
  order.companyId = order.companyId === null || hasZombieCompany ? NONE_OPTION : order.companyId

  return {
    order,
    orderStep: transformOrderStepInFormOrderStep(extOrder.orderStep, depot),
    pickup: extOrder.pickup ? transformOrderStepInFormOrderStep(extOrder.pickup, depot) : undefined,
    delivery: extOrder.delivery
      ? transformOrderStepInFormOrderStep(extOrder.delivery, depot)
      : undefined,
    depot,
    type: extOrder.type,
  }
}

export const getFormInitialValues = (
  mapCenter: uui.domain.LatLng,
  orders: Record<string, uui.domain.client.rm.ExtendedOrder>,
  workingDayStartSec: number,
  depots: Record<string, uui.domain.client.rm.Depot>,
  companies: Record<string, uui.domain.client.rm.Company>,
  source?: uui.domain.client.rm.ExtendedOrderStep,
  latLngFromMapPin?: uui.domain.LatLng,
): OrderFormValues => {
  if (!source) {
    const newOrderText: string = intl.translate({
      id: 'orders.form.edit.newOrderName',
    })

    const orderNumber: number = getNextOrderNumber(newOrderText, orders)

    return getEmptyExtendedOrder(`${newOrderText} ${orderNumber}`, mapCenter, latLngFromMapPin)
  }

  const orderFormValues: OrderFormValues = transformOrderToFormOrder(source, companies)

  if (!orderFormValues.delivery) {
    orderFormValues.delivery = getEmptyFormOrderStep('d', mapCenter, latLngFromMapPin)
  } else {
    if (!orderFormValues.delivery.tagsIn) {
      orderFormValues.delivery.tagsIn = []
    }

    if (!orderFormValues.delivery.tagsOut) {
      orderFormValues.delivery.tagsOut = []
    }

    orderFormValues.delivery.timeWindows = orderFormValues.delivery.timeWindows.map(
      (tw: uui.domain.rm.TimeWindow) => timeWindowUtils.formatTimeWindow(tw, workingDayStartSec),
    )

    if (!orderFormValues.delivery.timeWindowExceptions) {
      orderFormValues.delivery.timeWindowExceptions = {}
    }

    orderFormValues.delivery.timeWindowExceptions = Object.entries(
      orderFormValues.delivery.timeWindowExceptions,
    ).reduce((acc: uui.domain.rm.TimeWindowExceptions, [exceptionDate, timeWindows]) => {
      acc[exceptionDate] = timeWindows.map(
        (tw: uui.domain.rm.TimeWindow) => timeWindowUtils.formatTimeWindow(tw, workingDayStartSec),
        workingDayStartSec,
      )
      return acc
    }, orderFormValues.delivery.timeWindowExceptions)

    if (!orderFormValues.delivery.location) {
      const depot = orderFormValues.delivery.depotId
        ? depots[orderFormValues.delivery.depotId]
        : undefined
      if (depot) {
        orderFormValues.delivery.location = { ...depot.location }
        orderFormValues.delivery.location.address =
          depot.name || orderFormValues.delivery.location.address
      }
    }
  }

  if (!orderFormValues.pickup) {
    orderFormValues.pickup = getEmptyFormOrderStep('p', mapCenter, latLngFromMapPin)
  } else {
    if (!orderFormValues.pickup.tagsIn) {
      orderFormValues.pickup.tagsIn = []
    }

    if (!orderFormValues.pickup.tagsOut) {
      orderFormValues.pickup.tagsOut = []
    }

    orderFormValues.pickup.timeWindows = orderFormValues.pickup.timeWindows.map(
      (tw: uui.domain.rm.TimeWindow) => timeWindowUtils.formatTimeWindow(tw, workingDayStartSec),
    )

    if (!orderFormValues.pickup.timeWindowExceptions) {
      orderFormValues.pickup.timeWindowExceptions = {}
    }

    orderFormValues.pickup.timeWindowExceptions = Object.entries(
      orderFormValues.pickup.timeWindowExceptions,
    ).reduce((acc: uui.domain.rm.TimeWindowExceptions, [exceptionDate, timeWindows]) => {
      acc[exceptionDate] = timeWindows.map(
        (tw: uui.domain.rm.TimeWindow) => timeWindowUtils.formatTimeWindow(tw, workingDayStartSec),
        workingDayStartSec,
      )
      return acc
    }, orderFormValues.pickup.timeWindowExceptions)

    if (!orderFormValues.pickup.location) {
      const depot = orderFormValues.pickup.depotId
        ? depots[orderFormValues.pickup.depotId]
        : undefined
      if (depot) {
        orderFormValues.pickup.location = { ...depot.location }
        orderFormValues.pickup.location.address =
          depot.name || orderFormValues.pickup.location.address
      }
    }
  }

  if (!orderFormValues.order.forceVehicleId) {
    orderFormValues.order.forceVehicleId = VEHICLE_ASSIGNMENT_AUTOMATIC_ID
  }

  if (!orderFormValues.pickup!.notes) {
    orderFormValues.pickup!.notes = ''
  }

  if (!orderFormValues.delivery!.notes) {
    orderFormValues.delivery!.notes = ''
  }

  return orderFormValues
}

const getOrderPatch = (
  fieldName: string,
  formValues: OrderFormValues,
): Partial<uui.domain.ui.forms.OrderChanges> => {
  switch (fieldName) {
    case 'name':
      return { name: formValues.order.name }
    case 'companyId':
      return {
        companyId: formValues.order.companyId === NONE_OPTION ? null : formValues.order.companyId,
      }
    case 'eligibility':
      return { eligibility: formValues.order.eligibility }
    case 'loads':
      return { loads: formValues.order.loads }
    case 'priority':
      return { priority: formValues.order.priority }
    case 'forceVehicleId':
      return {
        forceVehicleId:
          formValues.order.forceVehicleId === VEHICLE_ASSIGNMENT_AUTOMATIC_ID
            ? null
            : formValues.order.forceVehicleId,
      }
    default:
      return {}
  }
}

const getOrderStepPatch = (
  fieldName: string,
  formValues: OrderFormValues,
  type: 'p' | 'd',
  workingDayStartSec: number,
): Partial<uui.domain.ui.forms.OrderStepChanges> => {
  const orderStep = type === 'p' ? formValues.pickup : formValues.delivery
  if (!orderStep) {
    throw new Error('order step not found')
  }

  switch (fieldName) {
    case 'location':
      return { locationOrDepotId: orderStep.depotId ? orderStep.depotId : orderStep.location }
    case 'phone':
      return { phone: orderStep.phone }
    case 'email':
      return { email: orderStep.email }
    case 'serviceTimeSec':
      return { serviceTimeSec: orderStep.serviceTimeSec }
    case 'timeWindows':
      return {
        timeWindows: orderStep.timeWindows.map((tw: uui.domain.rm.TimeWindow) =>
          timeWindowUtils.formatTimeWindowForRPC(
            timeWindowUtils.normalizeTimeWindow(tw, workingDayStartSec),
            workingDayStartSec,
          ),
        ),
      }
    case 'timeWindowExceptions':
      return {
        timeWindowExceptions: Object.entries(orderStep.timeWindowExceptions).reduce(
          (acc: uui.domain.rm.TimeWindowExceptions, [key, tws]) => {
            acc[key] = tws.map((tw: uui.domain.rm.TimeWindow) =>
              timeWindowUtils.normalizeTimeWindow(tw, workingDayStartSec),
            )
            return acc
          },
          {},
        ),
      }
    case 'tagsIn':
      return { tagsIn: orderStep.tagsIn }
    case 'tagsOut':
      return { tagsOut: orderStep.tagsOut }
    case 'notes':
      return { notes: orderStep.notes }
    case 'customFields':
      return { customFields: orderStep.customFields }
    case 'barcodes':
      return { barcodes: orderStep.barcodes }
    default:
      return {}
  }
}

const getDelete = (
  formValues: OrderFormValues,
  dirtyFields: string[],
  initialValues: OrderFormValues,
): uui.domain.ui.forms.OrderUpdate['delete'] | undefined => {
  if (!dirtyFields.includes('type') || formValues.type === 'pd') return
  if (
    (initialValues.type === 's' && formValues.type === 'd') ||
    (initialValues.type === 'd' && formValues.type === 's')
  )
    return
  return formValues.type === 'd' || formValues.type === 's' ? 'p' : 'd'
}

const getAdd = (
  formValues: OrderFormValues,
  dirtyFields: string[],
  initialValues: OrderFormValues,
): uui.domain.ui.forms.OrderUpdate['add'] | undefined => {
  if (!dirtyFields.includes('type')) return

  if (formValues.type === 'pd') {
    return initialValues.type === 'p' ? 'd' : 'p'
  }
}

export const getOrderStepUpdateChanges = (
  currentFormValues: OrderFormValues,
  dirtyFields: string[],
  initialFormValues: OrderFormValues,
  workingDayStartSec: number,
): uui.domain.ui.forms.OrderUpdate => {
  let orderUpdate: Partial<uui.domain.ui.forms.OrderChanges> = {}
  let pickupUpdate: Partial<uui.domain.ui.forms.OrderStepChanges> = {}
  let deliveryUpdate: Partial<uui.domain.ui.forms.OrderStepChanges> = {}

  for (const key of dirtyFields) {
    // type filed will have no purpose here so it will be skipped
    if (key === 'type') {
      orderUpdate.isService = currentFormValues.type === 's'
      continue
    }

    const keyParts = key.split('.')
    if (keyParts.length !== 2) {
      // only order. or pickup. or delivery. are keys that are relevant
      continue
    }

    const [radix, fieldName] = keyParts
    if (radix === 'order') {
      orderUpdate = {
        ...orderUpdate,
        ...getOrderPatch(fieldName, currentFormValues),
      }
    }

    if (radix === 'pickup') {
      pickupUpdate = {
        ...pickupUpdate,
        ...getOrderStepPatch(fieldName, currentFormValues, 'p', workingDayStartSec),
      }
    }

    if (radix === 'delivery') {
      deliveryUpdate = {
        ...deliveryUpdate,
        ...getOrderStepPatch(fieldName, currentFormValues, 'd', workingDayStartSec),
      }
    }
  }

  const update = Object.assign(
    {},
    Object.keys(orderUpdate).length > 0 ? { order: orderUpdate } : undefined,
    Object.keys(pickupUpdate).length > 0 ? { pickup: pickupUpdate } : undefined,
    Object.keys(deliveryUpdate).length > 0 ? { delivery: deliveryUpdate } : undefined,
  )

  const { orderId } = parseOrderStepId(initialFormValues.orderStep.id)

  return {
    delete: getDelete(currentFormValues, dirtyFields, initialFormValues),
    add: getAdd(currentFormValues, dirtyFields, initialFormValues),
    update,
    id: orderId,
  }
}

const toServerLocation = (
  clientLocation: uui.domain.client.Location,
): uui.domain.server.Location => {
  const latLng = geo.latLngToJSON(clientLocation.latLng)

  return {
    ...clientLocation,
    latLng,
    rooftopLatLng: clientLocation.rooftopLatLng ? geo.latLngToJSON(clientLocation.latLng) : latLng,
  }
}

const toServerOrderStep = (
  formOrderStep: FormOrderStep,
  workingDayStartSec: number,
): uui.domain.server.rm.OrderStep => {
  return {
    ...formOrderStep,
    timeWindows: formOrderStep.timeWindows.map((tw: uui.domain.rm.TimeWindow) =>
      timeWindowUtils.formatTimeWindowForRPC(
        timeWindowUtils.normalizeTimeWindow(tw, workingDayStartSec),
        workingDayStartSec,
      ),
    ),
    timeWindowExceptions: Object.entries(formOrderStep.timeWindowExceptions).reduce(
      (acc: uui.domain.rm.TimeWindowExceptions, [key, tws]) => {
        acc[key] = tws.map((tw: uui.domain.rm.TimeWindow) =>
          timeWindowUtils.normalizeTimeWindow(tw, workingDayStartSec),
        )
        return acc
      },
      {},
    ),
    trackingData: undefined,
    depotId: formOrderStep.depotId ? formOrderStep.depotId : null,
    location: formOrderStep.depotId
      ? null
      : !!formOrderStep.location
      ? toServerLocation(formOrderStep.location)
      : null,
    notes: formOrderStep.notes || '',
  }
}

export const getCreateOrderFromFormValues = (
  source: OrderFormValues,
  workingDayStartSec: number,
): uui.domain.ui.forms.OrderCreate => {
  const pickup =
    source.pickup && ['p', 'pd'].includes(source.type)
      ? toServerOrderStep(source.pickup, workingDayStartSec)
      : undefined

  const delivery =
    source.delivery && ['d', 'pd', 's'].includes(source.type)
      ? toServerOrderStep(source.delivery, workingDayStartSec)
      : undefined

  const loads = toServerLoads(source.order.loads)
  const forceVehicleId =
    !source.order.forceVehicleId || source.order.forceVehicleId === VEHICLE_ASSIGNMENT_AUTOMATIC_ID
      ? null
      : source.order.forceVehicleId

  return {
    name: source.order.name,
    companyId: source.order.companyId === NONE_OPTION ? null : source.order.companyId,
    eligibility: source.order.eligibility,
    priority: source.order.priority,
    forceVehicleId,
    loads,
    pickup,
    delivery,
    isService: source.type === 's',
  }
}

export const getEmptyLocation = (
  mapCenter: uui.domain.LatLng,
  latLngFromMapPin?: uui.domain.LatLng,
): uui.domain.client.Location => {
  const lat = latLngFromMapPin ? latLngFromMapPin.lat : mapCenter.lat
  const lng = latLngFromMapPin ? latLngFromMapPin.lng : mapCenter.lng
  const latLng: uui.domain.LatLng = gis.createLatLng(lat, lng)

  return {
    latLng,
    address: '',
    geoAddress: '',
    rawLatLngAsString: '',
    source: 'MANUAL',
    status: 'OK',
  }
}

export const getEmptyOrderStep = (
  type: uui.domain.client.rm.OrderStepIdentifier,
  mapCenter: uui.domain.LatLng,
  latLngFromMapPin?: uui.domain.LatLng,
): uui.domain.client.rm.OrderStep => {
  return {
    id: '',
    type,
    atDepot: false,
    location: getEmptyLocation(mapCenter, latLngFromMapPin),
    barcodes: [],
    customFields: {},
    notes: '',
    serviceTimeSec: 0,
    tagsIn: [],
    tagsOut: [],
    timeWindowExceptions: {},
    timeWindows: [],
    trackingData: {
      timeInSec: -1,
      timeOutSec: -1,
      statusSec: -1,
      autoTimeInSec: -1,
      autoTimeOutSec: -1,
      isEmpty: true,
      pods: {},
      status: 'missing',
      statusReason: '',
      latestTrackedLoadLatLng: null,
      latestTrackedLoadSec: -1,
    },
  }
}

export const getEmptyFormOrderStep = (
  type: uui.domain.client.rm.OrderStepIdentifier,
  mapCenter: uui.domain.LatLng,
  latLngFromMapPin?: uui.domain.LatLng,
): FormOrderStep => {
  return {
    id: '',
    type,
    location: getEmptyLocation(mapCenter, latLngFromMapPin),
    barcodes: [],
    customFields: {},
    notes: '',
    serviceTimeSec: 0,
    tagsIn: [],
    tagsOut: [],
    timeWindowExceptions: {},
    timeWindows: [],
    trackingData: {
      timeInSec: -1,
      timeOutSec: -1,
      statusSec: -1,
      autoTimeInSec: -1,
      autoTimeOutSec: -1,
      isEmpty: true,
      pods: {},
      status: 'missing',
      statusReason: '',
      latestTrackedLoadLatLng: null,
      latestTrackedLoadSec: -1,
    },
  }
}

export const getEmptyExtendedOrder = (
  name: string,
  center: uui.domain.LatLng,
  latLngFromMapPin?: uui.domain.LatLng,
): OrderFormValues => {
  const newOrder: uui.domain.client.rm.Order = {
    id: '',
    name,
    eligibility: {
      type: 'any',
    },
    priority: 0,
    delivery: getEmptyOrderStep('d', center, latLngFromMapPin),
    forceVehicleId: VEHICLE_ASSIGNMENT_AUTOMATIC_ID,
    isService: false,
    loads: {},
    type: 'd',
    uid: -1,
    companyId: null,
  }

  const delivery = getEmptyFormOrderStep('d', center, latLngFromMapPin)
  const pickup = getEmptyFormOrderStep('p', center, latLngFromMapPin)

  return {
    order: newOrder,
    orderStep: delivery,
    delivery,
    pickup,
    type: 'd',
  }
}

const isDelivery = (type: uui.domain.client.rm.OrderIdentifier): boolean =>
  type === 'd' || type === 's'

const isPickup = (type: uui.domain.client.rm.OrderIdentifier): boolean => type === 'p'

const getActiveOrderStep = (
  type: uui.domain.client.rm.OrderIdentifier,
  formValues: OrderFormValues,
): FormOrderStep | undefined => {
  const { delivery, pickup } = formValues
  return type === 'p' ? pickup : delivery
}

const getPassiveOrderStep = (
  type: uui.domain.client.rm.OrderIdentifier,
  formValues: OrderFormValues,
): FormOrderStep | undefined => {
  const { delivery, pickup } = formValues
  return type !== 'p' ? pickup : delivery
}

export const latLngDecorators = (
  fieldToUpdate: uui.domain.client.rm.OrderIdentifier,
  initialValues: OrderFormValues,
): Decorator => {
  const { type: initialType } = initialValues

  let prevType: uui.domain.client.rm.OrderIdentifier = initialType

  const fieldName: string = fieldToUpdate === 'p' ? 'pickup' : 'delivery'
  const isFieldActive = fieldToUpdate === 'p' ? isPickup : isDelivery
  const hasActiveFieldChanged = fieldToUpdate === 'p' ? isDelivery : isPickup

  return createDecorator({
    field: 'type',
    updates: {
      [fieldName]: (type: uui.domain.client.rm.OrderIdentifier, formValues: OrderFormValues) => {
        const activeField = getActiveOrderStep(fieldToUpdate, formValues)
        const passiveField = getPassiveOrderStep(fieldToUpdate, formValues)

        if (prevType === type) {
          return activeField
        }

        const isActive: boolean = isFieldActive(type)
        const hasTypeChanged: boolean = hasActiveFieldChanged(prevType)

        const recreateDelivery: boolean =
          (isActive && hasTypeChanged) || (type === 'pd' && !activeField)

        if (passiveField && recreateDelivery) {
          prevType = type
          return {
            ...passiveField,
            location: {
              ...passiveField.location,
              latLng: gis.createLatLng(
                passiveField.location!.latLng.lat,
                passiveField.location!.latLng.lng,
              ),
            },
            type,
          }
        }

        prevType = type
        return activeField
      },
    },
  })
}

export const connectedDepotDecorator = (
  orderStepType: uui.domain.client.rm.OrderIdentifier,
  depotsAsMapByLatLng: Record<string, uui.domain.client.rm.Depot[]>,
) => {
  const fieldName: string = orderStepType === 'p' ? 'pickup' : 'delivery'

  return createDecorator({
    field: `${fieldName}.location`,
    isEqual: (
      location: uui.domain.client.Location | undefined,
      prevLocation: uui.domain.client.Location | undefined,
    ) => {
      if (!prevLocation || !location) {
        return true
      }

      return (
        prevLocation.rawLatLngAsString.length >= 0 &&
        location.rawLatLngAsString.length >= 0 &&
        prevLocation.rawLatLngAsString === location.rawLatLngAsString
      )
    },
    updates: {
      [fieldName]: (
        location: uui.domain.client.Location,
        formValues: OrderFormValues,
      ): FormOrderStep => {
        const { pickup, delivery } = formValues
        const orderStep = orderStepType === 'p' ? pickup! : delivery!
        const depots = depotsAsMapByLatLng[location.rawLatLngAsString]

        const depot = Array.isArray(depots) ? depots[0] : undefined

        return {
          ...orderStep,
          location: orderStep.location
            ? { ...orderStep.location, address: depot ? depot.name : orderStep.location?.address }
            : undefined,
          depotId: depot?.id,
        }
      },
    },
  })
}

const getNextOrderNumber = (
  prefix: string,
  extOrders: Record<string, uui.domain.client.rm.ExtendedOrder>,
): number => {
  let nextOrderNumber = 1

  for (const id in extOrders) {
    const { name } = extOrders[id].order

    if (!name.startsWith(prefix)) continue

    const lastOrderNumber: number = parseInt(name.replace(prefix, '').trim())

    if (!isNaN(lastOrderNumber) && lastOrderNumber >= nextOrderNumber) {
      nextOrderNumber = lastOrderNumber + 1
    }
  }

  return nextOrderNumber
}

const transformLoads = (
  loads: Record<string, number>,
  toClient: boolean,
): Record<string, number> => {
  if (!loads) {
    return {}
  }
  const entries = Object.entries(loads)
  return entries.reduce((acc: Record<string, number>, [key, val]) => {
    acc[key] = toClient ? val / 100 : val * 100
    return acc
  }, {})
}

const toServerLoads = (loads: Record<string, number>) => transformLoads(loads, false)

const toClientLoads = (loads: Record<string, number>) => transformLoads(loads, true)
