import { useMemo } from 'react'

const width = 40
const height = 40

// create a SVG path from the vertex of the region
// this path will create a preview of the shape of the region
export function useSvgPoints(points: uui.domain.LatLng[]) {
  return useMemo(() => {
    return points.reduce<string>((pathPoints, point: uui.domain.LatLng) => {
      const lat = point.lat
      const lng = -point.lng

      let x = (lng / 360 + 0.5) * width
      let y = (1 - (lat / 180 + 0.5)) * height

      x = (parseInt(x.toFixed(2)) * 100) / 100
      y = (parseInt(y.toFixed(2)) * 100) / 100

      return `${pathPoints} ${x},${y}`
    }, '')
  }, [points])
}
