import type { Props } from '../typings'

import { useCallback, memo } from 'react'

import { WarningCard as WarningCardComponent } from '@/local/components'
import { WarningTriangle } from '@/icons'

import TextButton from '../elements/TextButton'
import { getInfoCardSubText, getInfoCardAction, getInfoCardTitle, getInfoCardText } from '../intl'

const WarningCard = (props: Props) => {
  const { status = 'ok', onConfirmLowAccuracy, onFixInvalidAddress, location } = props

  const onHandleAction = useCallback(() => {
    if (status === 'invalidLatLng') {
      if (onFixInvalidAddress) {
        onFixInvalidAddress(location)
      }
    } else if (status === 'lowAccuracy') {
      if (onConfirmLowAccuracy) {
        onConfirmLowAccuracy(location)
      }
    }
  }, [location, status, onConfirmLowAccuracy, onFixInvalidAddress])

  if (status === 'ok') {
    return null
  }

  const errorMode = status === 'outOfTerritory' || status === 'invalidLatLng'
  const showAction = status === 'lowAccuracy' || status === 'invalidLatLng'
  const outOfTerritory = status === 'outOfTerritory'

  const title = getInfoCardTitle(status, location.status)
  const preset = errorMode ? 'alert' : 'warning'
  const subText = outOfTerritory ? getInfoCardSubText(status) : undefined
  const description = getInfoCardText(status, location.status)

  return (
    <WarningCardComponent
      description={description}
      subText={subText}
      action={
        showAction ? (
          <TextButton onClick={onHandleAction}>{getInfoCardAction(status)}</TextButton>
        ) : undefined
      }
      preset={preset}
      title={title}
      Icon={
        <WarningTriangle
          size={14}
          color={errorMode ? '$pureWhite' : '$pureBlack'}
          background={errorMode ? '$outrageousRed' : '$darkOrange'}
        />
      }
    />
  )
}

export default memo(WarningCard)
