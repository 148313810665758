import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    loads: {
      title: translate({ id: 'orders.form.executionEvents.dynamicLoadsCf.loads' }),
      tooltipLess: (value: string) =>
        translate({
          id: 'orders.form.executionEvents.dynamicLoadsCf.loads.tooltip.less',
          values: { value },
        }),
      tooltipMore: (value: string) =>
        translate({
          id: 'orders.form.executionEvents.dynamicLoadsCf.loads.tooltip.more',
          values: { value },
        }),
    },
    customFields: {
      title: translate({ id: 'orders.form.executionEvents.dynamicLoadsCf.customFields' }),
    },
    undeclared: translate({ id: 'orders.form.executionEvents.dynamicLoadsCf.undeclared' }),
    emptyField: translate({ id: 'orders.form.executionEvents.dynamicLoadsCf.emptyField' }),
  }))

  return api
}
