import { useCallback, useState } from 'react'
import { Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material'
import Editor, { type OnChange } from '@monaco-editor/react'
import { LoadingButton } from '@mui/lab'

import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { requestMaker } from '@/features/domain/demoTools'

import { useTexts } from '../../../useTexts'

interface Props {
  method?: string
  request?: string
}

const editorBaseOptions = {
  formatOnPaste: true,
  formatOnType: true,
  autoIndent: true,
}

export function RequestMaker(props: Props) {
  const { method: defaultMethod = '', request: defaultRequest = '' } = props

  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const [method, setMethod] = useState(defaultMethod)
  const [loading, setLoading] = useState(false)
  const [requestValue, setRequestValue] = useState<string | undefined>(defaultRequest)
  const [responseValue, setResponseValue] = useState('')
  const [useCurrentTxnId, setUseCurrentTxnId] = useState(false)

  const onUseCurrentTxnIdCheckboxChange = useCallback(() => {
    setUseCurrentTxnId(prev => !prev)
  }, [setUseCurrentTxnId])

  const onSendButtonClicked = useCallback(async () => {
    setLoading(true)

    try {
      const response = await dispatch(
        requestMaker({
          method,
          payload: JSON.parse(requestValue ? requestValue : '{}'),
          useCurrentTxnId,
        }),
      )

      if (requestMaker.rejected.match(response)) {
        setLoading(false)
        throw new Error(response.payload?.message ?? 'Internal error')
      }

      setResponseValue(JSON.stringify(response.payload, null, 2))
    } catch (error) {
      toast.error(error.message)
    }

    setLoading(false)
  }, [method, requestValue, useCurrentTxnId, toast, dispatch, setLoading])

  const onRequestValueChange = useCallback<OnChange>(value => {
    setRequestValue(value)
  }, [])

  return (
    <Stack marginTop={2} spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <TextField
          size="small"
          label={texts.requestMaker.method}
          value={method}
          fullWidth
          onChange={e => setMethod(e.target.value)}
          disabled={loading}
        />
        <LoadingButton
          color="primary"
          onClick={onSendButtonClicked}
          loading={loading}
          variant="contained"
        >
          {texts.requestMaker.send}
        </LoadingButton>
      </Stack>

      <FormControlLabel
        control={<Checkbox checked={useCurrentTxnId} onChange={onUseCurrentTxnIdCheckboxChange} />}
        label={texts.requestMaker.useCurrentTxnId}
      />

      <Stack direction="row" gap={1}>
        <Stack width="100%" spacing={1}>
          <Typography variant="caption">{texts.requestMaker.request}</Typography>
          <Editor
            height={400}
            theme="vs-dark"
            value={requestValue}
            loading={loading}
            onChange={onRequestValueChange}
            options={editorBaseOptions}
            language="json"
          />
        </Stack>

        <Stack width="100%" spacing={1}>
          <Typography variant="caption">{texts.requestMaker.response}</Typography>
          <Editor
            value={responseValue}
            theme="vs-dark"
            height={400}
            options={{ ...editorBaseOptions, readOnly: true }}
            language="json"
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
