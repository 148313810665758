import { useSelector } from 'react-redux'

import { useCrudSelection, resetCrudSelection } from '@/atoms'
import { selectEventInfosListStructure } from '@/features/domain/lists'

export const useDataForList = () => {
  const structure = useSelector(selectEventInfosListStructure)
  const [selection, setSelection] = useCrudSelection('deviceEvents')

  const lastSelectedEvent = selection.length > 0 ? selection[selection.length - 1] : undefined

  const deselectAll = () => resetCrudSelection('deviceEvents')

  return {
    selection,
    structure,
    lastSelectedEvent,
    setSelection,
    deselectAll,
  }
}
