import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    fitMap: translate({ id: 'map.controls.fit.fitMap' }),
    fitMapToSelection: translate({ id: 'map.controls.fit.fitMapToSelection' }),
  }))

  return api
}
