import type { FormErrors, FormFields } from '../formFields'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { Checkbox } from '@workwave-tidal/tidal/form-ui'
import { useTexts } from '../hooks/useTexts'

type Props = {
  disabled?: boolean
  helperText?: string
}

export function CheckinField(props: Props) {
  const texts = useTexts()
  const { disabled: fieldDisabled, helperText } = props
  const { formApi } = useFormField<'checkIn', FormFields, FormErrors>('checkIn')
  const { submitting, disabled } = formApi.getMeta()
  const disableField = submitting || disabled || fieldDisabled

  return (
    <Checkbox<'checkIn', FormFields, FormErrors>
      label={texts.checkin}
      name="checkIn"
      validateOn="blur"
      disabled={disableField}
      helperText={helperText}
      testId="driver-assistance-form-check-in-field"
    />
  )
}
