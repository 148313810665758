import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

export const importRegionsFromKML = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  uui.domain.server.rm.Region[],
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/region/importRegionsFromKML', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/region/importRegionsFromKML', {
      category: 'rpc',
      type: 'rpc/region/importRegionsFromKML',
      regions: payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {importRegionsFromKML}`,
      { tags: ['rpc', 'regions'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Import regions failed`,
      error,
    })
  }
})
