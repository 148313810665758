import type { GeofenceFormValidation } from './types'

import { useMemo } from 'react'
import { Footer as UIFooter } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'

interface Props {
  errors: GeofenceFormValidation
  fieldsWithError: string[]
  creating?: boolean
  message?: string[]
  valid: boolean
  planLocked: boolean
  pristine: boolean
  saveDisabled: boolean
  onSubmit: () => void
  onCancel: () => void
  submitting: boolean
}

export function Footer(props: Props) {
  const {
    fieldsWithError,
    valid,
    planLocked,
    pristine,
    saveDisabled,
    onSubmit,
    onCancel,
    creating,
    submitting,
    message,
  } = props
  const texts = useTexts()

  const primary = useMemo(() => {
    return {
      text: creating ? texts.create : texts.save,
      title: texts.saveTitle(valid, planLocked, pristine),
      disabled: saveDisabled,
      onClick: onSubmit,
    }
  }, [texts, valid, planLocked, pristine, saveDisabled, onSubmit, creating])

  const secondary = useMemo(() => {
    return {
      disabled: submitting,
      text: texts.cancel,
      onClick: onCancel,
    }
  }, [texts, onCancel, submitting])

  return (
    <UIFooter
      message={message}
      errorTitle={texts.footerTitle}
      errors={fieldsWithError}
      submitting={submitting}
      secondary={secondary}
      primary={primary}
    />
  )
}
