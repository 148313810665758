import { Box } from '@mui/material'
import { SecondaryColumnHeader } from '@/forms-legacy'
import { RecurrenceSelector } from '@/components/RecurrenceSelector'
import { FormColumn } from '@/components/layout'
import { useResetEditingState } from '@/atoms'

import { useRecurrencesMap } from './hooks/useRecurrencesMap'
import { useTexts } from './hooks/useTexts'
import { useActions } from './hooks/useActions'

interface Props {
  driverAssignments: Record<string, uui.domain.client.rm.DriverAssignmentsWithId>
  setDriverAssignment: (driverAssignment: uui.domain.client.rm.DriverAssignmentsWithId) => void
}

export function SelectRecurrencePanel(props: Props) {
  const { driverAssignments, setDriverAssignment } = props

  const onCancel = useResetEditingState()

  const { onCreate, onOpenExisting } = useActions(driverAssignments, setDriverAssignment)
  const recurrencesMap = useRecurrencesMap(driverAssignments)
  const texts = useTexts()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.headerTitle}
        description={texts.headerDescription}
        avatar={null}
      />
      <Box
        padding="5px 36px"
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <RecurrenceSelector
          onCreate={onCreate}
          onOpenExisting={onOpenExisting}
          onCancel={onCancel}
          recurrenceMap={recurrencesMap}
        />
      </Box>
    </FormColumn>
  )
}
