import { Box } from '@mui/material'

import { theme } from '@/styles'
import { Dot } from '@/forms-legacy'

export interface Props {
  status: 'stopped' | 'stalling' | 'moving'
}

export function TrackingStatus(props: Props) {
  const { status } = props

  let innerColor: string = ''
  let outerColor: string | undefined

  switch (status) {
    case 'moving':
      innerColor = theme.colors.$pigmentGreen
      break
    case 'stopped':
      innerColor = theme.colors.$outrageousRed
      break
    case 'stalling':
      innerColor = theme.colors.$shadyLady
      break
    default:
      innerColor = theme.colors.$pureWhite
      outerColor = theme.colors.$nightRider
  }

  return (
    <Box width={10} height={10} marginRight={1}>
      <Dot innerColor={innerColor} outerColor={outerColor} />
    </Box>
  )
}
