import { intl } from '@/intl'

export function validateName(nameSource: string, lockedNames: string[]): string | undefined {
  const name = nameSource.trim()
  if (name.length === 0) {
    return intl.translate({
      id: 'vehicles.form.edit.generalSettings.validation.name.empty',
    })
  } else if (lockedNames.includes(name)) {
    return intl.translate({
      id: 'vehicles.form.edit.generalSettings.validation.name.unique',
    })
  }
}
