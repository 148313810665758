import styled from 'styled-components'

type Props = {
  bold?: boolean
  groupHeader?: boolean
  indentLevel?: number
}

const FooterText = styled.h3<Props>`
  position: relative;
  color: ${p => p.theme.colors.$nightRider};
  font-size: ${p => p.theme.fonts.$s};
  font-weight: ${p => (p.bold ? p.theme.weights.$semiBold : p.theme.weights.$regular)};
  padding-left: ${p => p.indentLevel ?? 0}px;
  padding-top: ${p => (p.groupHeader ? 10 : 0)}px;
  padding-bottom: ${p => (p.groupHeader ? 10 : 0)}px;
`

FooterText.displayName = 'FooterText'
export default FooterText
