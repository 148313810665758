import type { IconContainerProps } from './typings'

import { ValidColor, theme } from '@/local/components'

import { IconContainer } from './elements/IconContainer'

type Props = IconContainerProps & {
  background: ValidColor
  foreground: ValidColor
}

export function NewGeofence(props: Props) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 25 24">
        <path
          fill={theme.colors[props.background]}
          d="M11.4935151 18.2208407c.1021079.5326421.2767649 1.0541027.523971 1.5484928-3.1822906.6586213-6.6276687-.2500482-9.1014508-2.7238303C-.9849372 13.1445307-.9939486 6.8274936 2.8975417 2.93600326c3.8914904-3.89149035 10.2085275-3.88247889 14.10949994.01849356.31632468.31632467.31632468.82918831 0 1.14551298-.31632467.31632468-.82918831.31632468-1.14551298 0C12.5924751.83095625 7.3011629.82340802 4.0430547 4.08151624.7849465 7.33962447.7924947 12.6309366 4.0615483 15.8999902c2.0231938 2.0231938 4.8209699 2.7971386 7.4319668 2.3208505zm9.18713428-6.1509249c-.27925998-.183854-.57051663-.3419488-.87040831-.4742844-.08089596-.2497083-.02228427-.5346274.17583506-.7333128l2.06159603-2.06748631h-6.82809239c-.39792761 0-.71957977-.3232928-.71957977-.72163571 0-.39906455.32165216-.72163571.71957977-.72163571h6.81945743l-2.05296107-2.05954831c-.14031805-.14071896-.21083687-.3254577-.21083687-.51019644 0-.18473874.07051882-.36947749.21083687-.51019645.28063611-.28215956.7361301-.28215956 1.01748579 0l3.2856012 3.29571028c.135281.13494588.21083688.31824134.21083688.51019644 0 .19123347-.07555588.37452894-.21083687.51019645l-3.28560121 3.29498866c-.09289421.0931596-.20469278.155561-.32291254.1872043zm-2.66863887-.9515739c-1.33203668-.139022-2.7017568.1664748-3.86817701.9164904h-3.6530441l1.8283362 1.83356c-.2745869.4316618-.4885899.8908012-.6420092 1.3652932-.0507177-.0301965-.0985553-.0671878-.1422168-.110974l-3.2856012-3.2949887c-.135281-.1356675-.2108369-.3189629-.2108369-.5101964 0-.1919551.0755559-.3752506.2108369-.5101965l3.2856012-3.29571023c.2813557-.28215956.7368497-.28215956 1.0174858 0 .1403181.14071896.2108369.32545771.2108369.51019645s-.0705188.36947748-.2108369.51019644l-2.0529611 2.05954834h6.81945747c.33057972 0 .60851785.2226229.69312874.526781z"
        />
        <path
          fill={props.foreground ? theme.colors[props.foreground] : 'currentColor'}
          d="M21.0355339 13.67157288c1.95262487 1.95262486 1.95211979 5.118948 0 7.0710678-1.9521198 1.95211978-5.11844292 1.95262486-7.0710678 0-1.95211979-1.9521198-1.9521198-5.11894802 0-7.0710678 1.95211979-1.9521198 5.11894801-1.9521198 7.0710678 0zm-3.0437354 5.66614353l.00043729-1.6381982 1.6381982-.0004373c.275783-.00046568.4935763-.2201447.49357064-.49168489.00046574-.27295446-.22063667-.4921712-.4926483-.49264829l-1.63914107-.00050557-.00003414-1.63866964c-.00094856-.27436876-.22063668-.49311406-.4926483-.4926483-.27342586.00093716-.49121912.22155902-.49168488.49357064l.00003414 1.63866963-1.63866964-.00003414c-.27484015.0004657-.49357628.2201447-.49404204.4921563.0004771.27295449.2201652.49075693.49359112.49170547l1.63866964.00003414.00050557 1.63914107c.00000571.27436875.22110811.49358549.49170547.49359112.2738973-.00046572.492162-.22061614.4921563-.49404204z"
        />
      </svg>
    </IconContainer>
  )
}
