import type { YYYYMMDD, CalendarClickType, OnChangeIntervalSelection } from '../../../typings'

import { useCallback } from 'react'

import { parseDate } from '../../../utils/parseDate'
import { getSelectableInterval } from '../utils/getSelectableInterval'

export function useUpdateSelection(
  onChange: OnChangeIntervalSelection,
  lastSelectableDate: Date,
  intervalLength: number,
) {
  return useCallback(
    (dateAsString: YYYYMMDD, type: CalendarClickType) => {
      const date = parseDate(dateAsString)

      onChange(date, getSelectableInterval(date, intervalLength, lastSelectableDate), type)
    },
    [onChange, intervalLength, lastSelectableDate],
  )
}
