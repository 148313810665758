import type { RoadSegmentFormValues } from '../types'

import * as geo from '@/local/server-data/utils/geo'
import { getColor } from './getColor'
import { getMalus } from './getMalus'

export function parseRoadSegmentFromFormValues(
  formValues: RoadSegmentFormValues,
): uui.domain.server.rm.SegmentException {
  const { editableSegment, name, type, additionalDrivingTimeSec } = formValues

  if (!editableSegment) {
    throw new Error('Unable to save a segment without start/stop coordinates')
  }

  return {
    additionalDrivingTimeSec: type === 'delayed' ? additionalDrivingTimeSec : 0,
    start: geo.latLngToJSON(editableSegment.start),
    end: geo.latLngToJSON(editableSegment.end),
    color: getColor(type),
    malus: getMalus(type),
    name,
  }
}
