import { Typography } from '@mui/material'
import { type ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export function ListHeaderTitle(props: Props) {
  const { children } = props

  return (
    <Typography
      textOverflow="ellipsis"
      data-testid="list-header-title"
      fontWeight={500}
      lineHeight="40px"
      whiteSpace="nowrap"
      overflow="hidden"
      variant="h6"
      color="text.primary"
    >
      {children}
    </Typography>
  )
}
