import type { TrafficAreaFormValues } from '../../types'

import { FormLayout, SectionTitle, DrawAreaField } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'

import { Name } from './components/Name'
import { Level } from './components/Level'
import { FirstTimeWindow } from './components/FirstTimeWindow'
import { SecondTimeWindow } from './components/SecondTimeWindow'
import { Separator } from './components/Separator'

type Props = {
  initialValues: TrafficAreaFormValues
}

export function Form(props: Props) {
  const {
    initialValues: { firstTimeWindowValue, secondTimeWindowValue },
  } = props

  const texts = useTexts()

  return (
    <FormLayout>
      <SectionTitle>{texts.edit}</SectionTitle>

      <Name />
      <Level />

      {!!firstTimeWindowValue && (
        <>
          <Separator />
          <SectionTitle>{texts.timeWindows}</SectionTitle>
        </>
      )}
      <FirstTimeWindow hidden={!firstTimeWindowValue} />
      <SecondTimeWindow hidden={!secondTimeWindowValue} />

      <DrawAreaField name="points" label="" editableAreaId="trafficArea" editableAreaColor="#f00" />
    </FormLayout>
  )
}
