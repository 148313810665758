import { CircularProgress } from '@mui/material'
import { NestedDropdown, DropdownMenuItem } from '@/components/DropdownMenu'
import { StrikedEye } from '@/icons'

import { ActiveIcon } from '../ActiveIcon'

import { useDepotsMapStyle } from './hooks/useDepotsMapStyle'
import { useHasDepots } from './hooks/useHasDepots'
import { useActions } from './hooks/useActions'
import { useTexts } from './useTexts'

interface Props {
  parentOpen: boolean
}

export function DepotsSubMenu(props: Props) {
  const { parentOpen } = props

  const {
    methodInExecution,
    actions: { showDepots, hideDepots, showDepotsWithLabels },
  } = useActions()
  const activeStyle = useDepotsMapStyle()
  const hasDepots = useHasDepots()
  const texts = useTexts()
  const tooltipTitle = hasDepots === true ? undefined : texts.noDepots

  return (
    <NestedDropdown
      placement="left"
      disabled={!hasDepots}
      parentOpen={parentOpen}
      tooltipTitle={tooltipTitle}
      triggerContent={texts.depots}
      Icon={
        hasDepots && activeStyle === 'off' ? <StrikedEye size={12} color="$silver" /> : undefined
      }
    >
      <DropdownMenuItem
        external
        onClick={showDepotsWithLabels}
        Icon={
          methodInExecution === 'showDepotsWithLabels' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'label'} />
          )
        }
      >
        {texts.onWithLabel}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={showDepots}
        Icon={
          methodInExecution === 'showDepots' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'on'} />
          )
        }
      >
        {texts.on}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={hideDepots}
        Icon={
          methodInExecution === 'hideDepots' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'off'} />
          )
        }
      >
        {texts.off}
      </DropdownMenuItem>
    </NestedDropdown>
  )
}
