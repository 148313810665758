import type { Props } from './typings'

import { useSelector } from 'react-redux'
import {
  SecondaryColumnHeader,
  ViewManager,
  FormContent,
  SecondaryColumnContentContainer,
} from '@/forms-legacy'

import { selectUserConfiguration } from '@/features/domain/user'
import { FormColumn } from '@/components/layout'
import { usePendingFitMapOnMount } from '@/map'
import { useFormatEligibility } from '@/hooks'
import { timeWindowUtils } from '@/utils'
import { gis } from '@/server-data'

import { ViewDetails, getViewDetails } from '../../components/ViewDetails'
import { DynamicLoadsCf } from '../../components/DynamicLoadsCf/DynamicLoadsCf'
import { ExecutionEvents } from '../../components/ExecutionEvents'
import { OrderAvatar } from '../../components/OrderAvatar'
import { Tabs } from '../../components/Tabs'
import { Pods } from '../../components/Pods'

import ActionsManager, { ViewTypes as ActionsManagerTypes } from './components/ActionsManager'
import { OrderStepNotification } from './components/OrderStepNotification'
import { useSingleViewActions } from './hooks/useSingleViewActions'
import { useExecutionEventTab } from './hooks/useExecutionEventTab'

export function SingleViewProvider(props: Props) {
  const { order, setTab, tab } = props

  usePendingFitMapOnMount()

  const {
    territory,
    vehiclesMap,
    companiesMap,
    allowBarcodes,
    onStartEditing,
    onSelectPaired,
    userConfiguration,
    onCloseSingleView,
    onDeleteOrderSteps,
    onUnassignOrderSteps,
    isOrderAssignedToFutureRoute,
  } = useSingleViewActions(props)

  const formatEligibility = useFormatEligibility()

  const { activePodMarker, onSetPodOnMap } = useExecutionEventTab(props)
  const { planType } = useSelector(selectUserConfiguration)

  const archived = planType === 'archived'

  // TODO: remove it, in v3 it should not be possible to have the order undefined
  if (!order) {
    return null
  }

  const {
    orderStep: {
      type,
      notes = '',
      tagsIn = [],
      tagsOut = [],
      barcodes,
      timeWindows,
      customFields = {},
      timeWindowExceptions = {},
    },
    order: { loads = {}, integrationData },
  } = order

  const {
    pods,
    email,
    phone,
    title,
    description,
    vehicleText,
    serviceTime,
    trackingData,
    priorityText,
    eligibilityText,
    company,
  } = getViewDetails(order, vehiclesMap, companiesMap, formatEligibility, archived)

  const useMetric = userConfiguration.distanceFormat === 'METRIC'
  const hasUnknownLatLng = !order.atDepot && gis.isNullIslandLatLng(order.location.latLng)

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={title}
        description={description}
        action={onCloseSingleView}
        avatar={<OrderAvatar extendedOrderStep={order} style={{ marginRight: 16 }} size={42} />}
        errors={{ description: hasUnknownLatLng }}
      >
        <Tabs setTab={setTab} tab={tab} />
      </SecondaryColumnHeader>

      {tab === 2 && <OrderStepNotification extendedOrder={order} />}

      {tab !== 2 && (
        <>
          <ViewManager<ActionsManagerTypes>
            view={ActionsManagerTypes.default}
            render={(view, changeView) => (
              <ActionsManager
                tab={tab}
                order={order}
                isOrderAssignedToFutureRoute={isOrderAssignedToFutureRoute}
                startEditing={onStartEditing}
                onSelectPaired={onSelectPaired}
                deleteOrderSteps={onDeleteOrderSteps}
                unassignOrderSteps={onUnassignOrderSteps}
                view={view}
                changeView={changeView}
              />
            )}
          />

          <FormContent footer={<div />}>
            <>
              {tab === 0 && (
                <ViewDetails
                  notificationBehavior={territory.notificationBehavior}
                  eligibility={eligibilityText}
                  company={company}
                  tagsIn={tagsIn}
                  tagsOut={tagsOut}
                  loads={loads}
                  priority={priorityText}
                  vehicle={vehicleText}
                  serviceTime={serviceTime}
                  timeWindows={timeWindowUtils.formatTimeWindows(
                    timeWindows,
                    territory.workingDayStartSec,
                  )}
                  notes={notes}
                  timeWindowExceptions={timeWindowUtils.formatTimeWindowExceptions(
                    timeWindowExceptions,
                    territory.workingDayStartSec,
                  )}
                  type={type}
                  customFields={customFields}
                  violations={order.isUnassigned ? [] : order.violations || []}
                  phone={phone}
                  email={email}
                  barcodes={barcodes}
                  allowBarcodes={allowBarcodes}
                  useMetric={useMetric}
                  integrationData={integrationData}
                />
              )}

              {tab === 1 && trackingData && (
                <SecondaryColumnContentContainer>
                  <ExecutionEvents
                    trackingData={trackingData}
                    rescheduleBehavior={territory.rescheduleBehavior}
                  />
                  <DynamicLoadsCf
                    loads={loads}
                    customFields={customFields}
                    trackedLoads={trackingData.loadTrackedMap}
                    trackedCustomFields={trackingData.customFieldTrackedMap}
                  />
                  {pods && (
                    <Pods
                      pods={pods}
                      activePodMarker={activePodMarker}
                      orderStep={order.orderStep}
                      allowBarcodes={allowBarcodes}
                      onTogglePod={onSetPodOnMap}
                    />
                  )}
                </SecondaryColumnContentContainer>
              )}
            </>
          </FormContent>
        </>
      )}
    </FormColumn>
  )
}
