type Options = {
  mainWindowDoc: Document
  popupDoc: Document

  mutableCache: WeakMap<Node, Node>
  measurePerformance?: boolean
}

const selector = 'link[rel="stylesheet"]'

/**
 * Clone all the externally-loaded stylesheets (ex. the fonts) and cache them if needed.
 */
export function copyExternalStylesheets(options: Options) {
  const { mainWindowDoc, popupDoc, mutableCache, measurePerformance = false } = options

  const loadPromises: Promise<void>[] = []
  const start = measurePerformance ? performance.now() : 0

  // Retrieve all the links from the source document
  const stylesheets = mainWindowDoc.querySelectorAll(selector) as NodeListOf<HTMLLinkElement>

  for (const stylesheet of stylesheets) {
    // Avoid doing anything in case of cache
    if (mutableCache.has(stylesheet)) continue

    // Create an "onload" promise
    let resolver = () => {}
    let rejecter = () => {}
    const loadPromise = new Promise<void>((resolve, reject) => {
      resolver = resolve
      rejecter = reject
    })
    loadPromises.push(loadPromise)

    // Clone the style to the new document
    const clonedStylesheet = stylesheet.cloneNode(true) as HTMLLinkElement
    clonedStylesheet.onload = () => resolver()
    clonedStylesheet.onerror = () => rejecter()

    // Append the style to the new document
    popupDoc.head.appendChild(clonedStylesheet)

    // Update the cache
    mutableCache.set(stylesheet, clonedStylesheet)
  }

  // Log the overall performance
  if (measurePerformance) {
    console.log(
      `Cloning the external stylesheets in the popup took ${performance.now() - start} ms`,
    )
  }

  // ATTENTION: at the time of writing (April, 2022) waiting for the stylesheets to be loaded works
  // only in dev mode.
  return loadPromises
}

/**
 * Remove all the stylesheets.
 */
export function removeStyles(doc: Document) {
  const stylesheets = doc.querySelectorAll(selector)

  for (const stylesheet of stylesheets) {
    stylesheet.parentNode?.removeChild(stylesheet)
  }
}
