import { useCallback } from 'react'

import { SecondaryColumnHeader, FormContent, FormBulkAvatar } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { resetCrudSelection } from '@/atoms'

import { ViewDetails } from '../../components/ViewDetails'

import { Actions } from './Actions'
import { useTexts } from './hooks/useTexts'
import { useBulkInfos } from './hooks/useBulkInfos'

interface Props {
  roadSegments: uui.domain.client.rm.SegmentException[]
}

export function BulkView(props: Props) {
  const { roadSegments } = props
  const { avatarColor, mixed, title, malus, additionalDrivingTimeSec } = useBulkInfos(roadSegments)

  const texts = useTexts()

  const onReset = useCallback(() => {
    resetCrudSelection('routingSegments')
  }, [])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={title}
        description={texts.description}
        action={onReset}
        avatar={<FormBulkAvatar color={avatarColor} size={roadSegments.length} />}
        data-testid="roadSegments-readonly-header-bulk"
      />
      <Actions roadSegments={roadSegments} />
      <FormContent footer={<div />}>
        <ViewDetails
          malus={malus}
          mixed={mixed}
          additionalDrivingTimeSec={additionalDrivingTimeSec}
        />
      </FormContent>
    </FormColumn>
  )
}
