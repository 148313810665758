import { Add, Cached } from '@mui/icons-material'

interface Props {
  mode: 'add' | 'replace'
}
export function SubmitIcon(props: Props) {
  const { mode } = props

  if (mode === 'add') return <Add />

  return <Cached />
}
