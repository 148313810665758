import type { ReactElement } from 'react'

import { Box } from '@mui/material'

interface Props {
  children: ReactElement
}

export function MainSectionContainer(props: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100vh',
        maxWidth: 1600,
        margin: '0 auto',
      }}
    >
      {props.children}
    </Box>
  )
}
