import { alpha, Stack, Typography, useTheme } from '@mui/material'

import { InfoOutlined } from '@mui/icons-material'

import { ViewField } from '@/formUi'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../hooks/useTexts'

interface Props {
  driver: uui.domain.client.rm.ExtendedDriver
  trackingProvider: uui.domain.server.gps.TrackingProvider
}

export function DriverApp(props: Props) {
  const { trackingProvider, driver } = props

  const theme = useTheme()
  const texts = useTexts()

  const tracked = driver.type === 'linked'

  return (
    <Stack data-testid="DriverApp">
      <Typography variant="subtitle3" pb={3}>
        {texts.driverAppTitle}
      </Typography>

      {trackingProvider !== 'telematics' && (
        <ViewField label={texts.labels.driverTracked} color={tracked ? undefined : 'textSecondary'}>
          <Stack gap={1} component="span" direction="row">
            {texts.labels.tracked(tracked)}
            {tracked && (
              <Tooltip
                placement="bottom"
                title={driver.type === 'linked' ? driver.device.label : ''}
              >
                <InfoOutlined />
              </Tooltip>
            )}
          </Stack>
        </ViewField>
      )}

      <Stack gap={1}>
        {trackingProvider !== 'telematics' && (
          <Typography variant="subtitle1" color={alpha(theme.palette.secondary.contrastText, 0.7)}>
            {texts.routeViewPreferences}
          </Typography>
        )}
        <ViewField label={texts.labels.mobileFutureDaysVisibility}>
          {texts.values.mobileFutureDaysVisibility(driver.driver.mobileFutureDaysVisibility)}
        </ViewField>
      </Stack>

      <ViewField label={texts.labels.summarizeFutureMobileRoute}>
        {texts.values.summarizeFutureMobileRoute(driver.driver.summarizeFutureMobileRoute)}
      </ViewField>
    </Stack>
  )
}
