import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    monitoredVehicles: (count: number) =>
      translate({
        id: 'gps.alerts.view.monitoredVehiclesCount',
        values: { count },
      }),
    showAll: translate({ id: 'global.showAll' }),
    hideAll: translate({ id: 'global.hideAll' }),
  }))

  return api
}
