import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Email(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M3 7v.03125l13 7.8125 13-7.8125V7zm0 2.375V25h26V9.375l-13 7.78125z" />
      </svg>
    </IconContainer>
  )
}
