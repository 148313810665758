import styled from 'styled-components'

type Props = {
  primary?: boolean
}

const ItemRendererColumn = styled.div<Props>`
  flex: ${p => (p.primary ? '0 1 auto' : '0 0 auto')};
  width: ${p => (p.primary ? '100%' : 'auto')};
  overflow: ${p => (p.primary ? 'hidden' : 'visible')};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
`

ItemRendererColumn.displayName = 'ItemRendererColumn'
export default ItemRendererColumn
