import styled from 'styled-components'

import TimeWindowList from './TimeWindowList'

const styledComponent = styled(TimeWindowList)`
  position: relative;
  width: 100%;

  > * + * {
    margin-top: 10px;
  }
`
export default styledComponent
