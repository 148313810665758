import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectDevicesAggregateInfo } from '@/features/domain/device'

export function useAvailableDevices() {
  const devicesAggregateInfo = useSelector(selectDevicesAggregateInfo)

  return useMemo(() => {
    const availableDevices: string[] = []
    const values = Object.values(devicesAggregateInfo)

    for (const value of values) {
      if (!value.associatedVehicleId) {
        availableDevices.push(value.device.deviceId)
      }
    }

    return availableDevices
  }, [devicesAggregateInfo])
}
