import { genericStyles } from '../../../genericStyles'

import { getMarkerSelectionStyle } from './getMarkerSelectionStyle'
import { getSecondaryLabelStyle } from './getSecondaryLabelStyle'
import { getMarkerBadgeStyle } from './getMarkerBadgeStyle'
import { getMainLabelStyle } from './getMainLabelStyle'
import { getMarkerStyle } from './getMarkerStyle'
import { getMinimizedLabelStyle } from './getMinimizedLabelStyle'

const emptySet = new Set<string>()

export const getOrderFeatureStyle = (
  marker: uui.domain.ui.map.markers.Order,
  mode: uui.domain.ui.map.markers.MapStyles['order']['mode'],
  selectedOrderIds: Set<string> = emptySet,
  selected: boolean = false,
) => {
  if (mode === 'off') return genericStyles.hidden

  const style = [
    getMarkerSelectionStyle(marker, mode, selectedOrderIds, selected),
    getMarkerStyle(marker, mode, marker.color, selected),
  ]

  if (marker.label && mode === 'maximized') {
    const mainTextStyle = getMainLabelStyle(
      marker.label.mainText,
      !!marker.label.secondaryText,
      mode,
      marker.label.color,
      selected,
    )

    if (mainTextStyle) {
      style.push(mainTextStyle)
    }

    if (marker.label.secondaryText) {
      const secondaryTextStyle = getSecondaryLabelStyle(
        marker.label.secondaryText,
        mode,
        marker.label.color,
        selected,
      )
      if (secondaryTextStyle) {
        style.push(secondaryTextStyle)
      }
    }
  }

  if (marker.label?.minimizedText && mode === 'on') {
    const minimizedTextStyle = getMinimizedLabelStyle(
      marker.label.minimizedText,
      mode,
      marker.label.color,
      selected,
    )

    if (minimizedTextStyle) {
      style.push(minimizedTextStyle)
    }
  }

  if (marker.badgeMinimized || marker.badgeMaximized) {
    const badgeStyle = getMarkerBadgeStyle(marker, mode, selected)

    if (badgeStyle) {
      style.push(badgeStyle)
    }
  }

  return style
}
