import { VerticalLayout } from '@/components/layout'
import { H2, H4, H6 } from '@/local/components'
import { Block } from '@/forms-legacy'

import { useController } from '../../../../../../hooks/useController'
import { useTexts } from './useTexts'

export function Driver() {
  const texts = useTexts()
  const { data } = useController()

  if (data.messageType !== 'email') return null

  return (
    <VerticalLayout marginBottom={15} data-testid="send-message-to-driver-message-type-panel">
      <Block>
        <H4 color="$paleSky" uppercase>
          {texts.messageType}
        </H4>
      </Block>
      <Block data-testid="send-message-to-driver-email-panel">
        <H2>{texts.email}</H2>
        <H6 ellipsis color="$silver">
          {data.email}
        </H6>
      </Block>
    </VerticalLayout>
  )
}
