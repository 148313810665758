import type { HTMLAttributes } from 'react'
import type { AutocompleteRenderOptionState, AutocompleteRenderInputParams } from '@mui/material'
import type { AddLoadOption as Option } from '../../types'

import { useCallback } from 'react'

import { Box, Stack, Typography, IconButton, TextField, Autocomplete } from '@mui/material'

import { Close } from '@mui/icons-material'

import { Option as AutocompleteOption } from './components/Option'
import { ListItem } from './components/ListItem'
import { useTexts } from './hooks/useTexts'
import { useFilterOptions } from './hooks/useFilterOptions'
import { useActions } from './hooks/useActions'

interface Props {
  title: string
  options: Option[]
  onAddTag: (tagName: string, newOption?: boolean) => void
  onClose: () => void
  ordersCount: number
}

function isOptionEqualToValue(optA: Option, optB: Option) {
  return optA.label === optB.label
}

export function AddLoad(props: Props) {
  const { onAddTag, onClose, title, options, ordersCount } = props
  const texts = useTexts()

  const filterOptions = useFilterOptions()
  const { inputValue, onInputChange, open, onChange, onCloseAutocomplete, onKeyDown, onOpen } =
    useActions(options, onAddTag, onClose)

  const renderOption = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      option: Option,
      autoCompleteState: AutocompleteRenderOptionState,
    ) => {
      return (
        // ListItem is just a styled.li
        <ListItem {...props}>
          <AutocompleteOption
            {...option}
            ordersCount={ordersCount}
            selected={autoCompleteState.selected}
          />
        </ListItem>
      )
    },
    [ordersCount],
  )

  const onRenderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField {...params} placeholder={texts.searchLoad} autoFocus />
    ),
    [texts],
  )

  return (
    <Box width={360} padding={3} data-testid="addLoad-dialog">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ pb: 3 }}
      >
        <Typography noWrap variant="h6">
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Autocomplete
        value={null}
        open={open}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        onChange={onChange}
        renderOption={renderOption}
        filterOptions={filterOptions}
        onOpen={onOpen}
        onClose={onCloseAutocomplete}
        isOptionEqualToValue={isOptionEqualToValue}
        options={options}
        renderInput={onRenderInput}
        noOptionsText={texts.noOptionsText}
      />
    </Box>
  )
}
