import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TagsListField } from '@/forms-legacy'
import { selectGpsTags } from '@/features/domain/tags'

import { useTexts } from '../../hooks/useTexts'
import { tagsRenderText } from './tagsRenderTexts'

export function Badges() {
  const texts = useTexts()

  const gpsTags = useSelector(selectGpsTags)
  const wrappedInputProps = useMemo(() => {
    return {
      suggestions: Object.values(gpsTags).map(t => t.value),
      enterToAdd: true,
      renderText: tagsRenderText,
    }
  }, [gpsTags])

  return (
    <TagsListField
      name="device.badges.badges"
      testid="device.badges"
      label={texts.badges}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
