import { useCallback } from 'react'
import { ReadonlyBlock } from '@/forms-legacy'
import { AddRounded } from '@/icons'
import { ViewType, NotificationEditorRecipient, ActionsManagerProps as Props } from '../../typings'

import FieldButton from '../../../Breaks/FieldButton'
import AddRecipient from './AddRecipient/AddRecipient'
import { useTexts } from './useTexts'

export default function ActionsManager(props: Props) {
  const {
    changeView,
    view,
    isDuplicatedRecipient,
    renderText,
    onAddRecipient,
    defaultEmailAddress,
    allowSms,
  } = props

  const texts = useTexts()

  const handleButtonClick = useCallback(() => {
    changeView(ViewType.create)
  }, [changeView])

  const handleCancel = useCallback(() => {
    changeView(ViewType.default)
  }, [changeView])

  const handleAddRecipient = useCallback(
    (recipient: NotificationEditorRecipient) => {
      onAddRecipient(recipient)
      changeView(ViewType.default)
    },
    [onAddRecipient, changeView],
  )

  switch (view) {
    case ViewType.create:
      return (
        <AddRecipient
          onCancel={handleCancel}
          onAdd={handleAddRecipient}
          isDuplicatedRecipient={isDuplicatedRecipient}
          renderText={renderText}
          defaultEmailAddress={defaultEmailAddress}
          allowSms={allowSms}
        />
      )

    default:
      return (
        <ReadonlyBlock>
          <FieldButton Icon={<AddRounded />} onClick={handleButtonClick}>
            <>{texts.addContactButton}</>
          </FieldButton>
        </ReadonlyBlock>
      )
  }
}
