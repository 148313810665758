import { useMemo } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'
import { Tag } from './Tag'
import { useTexts } from './useTexts'

interface Props {
  status: uui.domain.client.rm.OrderStepEmailOrSMSNotification['status']
  errorCode: uui.domain.client.rm.SmsErrorCode | null
}

export function Status(props: Props) {
  const { status, errorCode } = props
  const texts = useTexts()

  const color = useMemo(() => {
    if (status === 'FAILED') return '$outrageousRed'
    if (status === 'PENDING') return '$tangerineYellow'
    return '$pigmentGreen'
  }, [status])

  const title = useMemo(
    () => (!!errorCode ? texts.errorCodeLabel(errorCode) : ''),
    [errorCode, texts],
  )

  const label = useMemo(() => texts.label(status), [status, texts])

  return (
    <Tooltip title={title} placement="top" disabled={!errorCode}>
      <Tag backgroundColor={color} color={color}>
        {label}
      </Tag>
    </Tooltip>
  )
}
