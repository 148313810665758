import { ListHeader } from '@/components/List'

import { Actions } from './components/Actions'
import { Filter } from './components/Filter'
import { Sorter } from './components/Sorter'
import { Title } from './components/Title'

export function Header() {
  return (
    <ListHeader actions={<Actions />} filter={<Filter />} sorter={<Sorter />} title={<Title />} />
  )
}
