import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { produce } from 'immer'

import { useAppDispatch } from '@/store'
import { setListOptions } from '@/features/domain/lists/actions'
import { selectUsersListOptions } from '@/features/domain/lists'

/**
 * Set the search' query of the list options.
 */
export function useSetQuery() {
  const dispatch = useAppDispatch()
  const listOptions = useSelector(selectUsersListOptions)

  return useCallback(
    async (newValue: string) => {
      const nextOptions = produce(listOptions, draft => {
        draft.search.query = newValue
      })

      // avoid triggering any calculations in case the group didn't change
      if (nextOptions === listOptions) return

      const request = await dispatch(setListOptions({ category: 'users', options: nextOptions }))
      return setListOptions.fulfilled.match(request)
    },
    [dispatch, listOptions],
  )
}
