import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Bus(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M8.08 4c-2.23125 0-3.06.7371-3.06 2.72v11.22c0 .46219.13547.79555.34 1.04125V19.98c0 .5618.4582 1.02 1.02 1.02h1.36c.5618 0 1.02-.4582 1.02-1.02v-.34h7.48v.34c0 .5618.4582 1.02 1.02 1.02h1.36c.5618 0 1.02-.4582 1.02-1.02v-.99875c.20453-.2457.34-.57906.34-1.04125V7.06c0-1.48086-.18328-3.06-2.38-3.06H8.08zM9.1 5.36h7.14c.1886 0 .34.15273.34.34v.68c0 .18727-.1514.34-.34.34H9.1c-.18727 0-.34-.15273-.34-.34V5.7c0-.18727.15273-.34.34-.34zM7.74 7.74h9.52c.68 0 1.02.34 1.02 1.02v4.08c0 .68-.66406.99875-1.02.99875L7.74 13.86c-.68 0-1.02-.34-1.02-1.02V8.76c0-.68.34-1.02 1.02-1.02zm-3.06.34c-.37453 0-.68.30547-.68.68v2.72c0 .37453.30547.68.68.68V8.08zm15.64 0v4.08c.37586 0 .68-.30547.68-.68V8.76c0-.37453-.30414-.68-.68-.68zM7.91 15.56c.65742 0 1.19.53258 1.19 1.19 0 .65742-.53258 1.19-1.19 1.19-.65742 0-1.19-.53258-1.19-1.19 0-.65742.53258-1.19 1.19-1.19zm9.18 0c.65742 0 1.19.53258 1.19 1.19 0 .65742-.53258 1.19-1.19 1.19-.65742 0-1.19-.53258-1.19-1.19 0-.65742.53258-1.19 1.19-1.19z" />
      </svg>
    </IconContainer>
  )
}
