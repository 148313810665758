import OlMap from 'ol/Map'
import Zoom from 'ol/control/Zoom'
import { ZoomMapControl } from '@/map'
import { getTexts } from './texts'

const uniqueId = 'rm-zoom'

export const registerZoomControl = (map: OlMap) => {
  const texts = getTexts()
  const controls = map.getControls()

  if (controls.get(uniqueId)) return

  // remove default zoom control
  controls.forEach(c => {
    if (c instanceof Zoom && c.get('uid') !== uniqueId) {
      map.removeControl(c)
    }
  })

  // create custom zoom control
  const zoomControl = new ZoomMapControl({
    minZoom: map.getView().getMinZoom(),
    maxZoom: map.getView().getMaxZoom(),
    zoomInLabel: '+',
    zoomInTipLabel: texts.zoomInToolTip,
    zoomOutLabel: '-',
    zoomOutTipLabel: texts.zoomOutToolTip,
  })
  zoomControl.set('uid', uniqueId)
  map.addControl(zoomControl)

  controls.set(uniqueId, zoomControl)
}

export const refreshZoomControl = (map: OlMap) => {
  const controls = map.getControls()
  const texts = getTexts()

  const ctrl = controls.get(uniqueId) as ZoomMapControl

  if (!ctrl) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('Trying to refresh Map Zoom Control before creating it')
    }
    return
  }

  // try to update texts
  ctrl.changeTexts({
    zoomIn: { tooltip: texts.zoomInToolTip },
    zoomOut: { tooltip: texts.zoomOutToolTip },
  })
}
