import { PartialStoreState } from '../typings'

export const selectApprovedPlans = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.approvedPlans

export const selectSnapshotsByCalendarRange = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.plan.snapshotsByCalendarRange

export const selectRoutePlanTrackingData = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.routePlanTrackingData.routesTrackingData
