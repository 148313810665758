import { useEffect, useState } from 'react'

import {
  useCloseModal,
  setEditingState,
  resetEditingState,
  useModalController,
  useConfigureModalController,
} from '@/atoms'
import { useFormTransaction } from '@/hooks'

import { type FormValues } from '../types'
import { computeBaseFactorForUI } from '../utils/trafficFactor'

type ModalState = 'ready' | 'pending'

interface ModalData {
  submitting: boolean
  formValues: FormValues
  trafficProfile: uui.domain.client.rm.TrafficProfile
}

export const modalId = 'editTrafficProfile'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = (trafficProfile: uui.domain.client.rm.TrafficProfile) => {
  const close = useCloseModal()

  const transactionState = useFormTransaction()

  // INITIALIZATION
  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      status: 'pending' as ModalState,
      data: {
        submitting: false,
        formValues: {
          description: trafficProfile.description,
          baseTrafficFactor: computeBaseFactorForUI(trafficProfile.baseTrafficFactor),
        },
        trafficProfile,
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const { update } = ctrl

  // the modal body will render only when the transaction is ready
  useEffect(() => {
    if (transactionState === 'ready') {
      update({ status: 'ready' })
    }
  }, [transactionState, update])

  useEffect(() => {
    setEditingState({ editing: true, ctx: 'trafficProfile', editingEntities: [trafficProfile.id] })
    return () => {
      resetEditingState()
    }
  }, [trafficProfile])

  return ctrl
}
