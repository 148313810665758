import { useCallback } from 'react'

import { useNavigate } from '@/routing'
import { useFitMapToSelection } from '@/map'

import { setCrudSelection } from '@/atoms'

import { useGeofenceData } from './useGeofenceData'

export function useActions(
  event: uui.domain.client.gps.wwgps.EventInfo,
  setView: (view: 'default' | 'delete') => void,
) {
  const fitMapToSelection = useFitMapToSelection()
  const navigateSetup = useNavigate<uui.routing.Setup>(true)
  const geofenceData = useGeofenceData(event)

  const onFitMap = useCallback(() => {
    fitMapToSelection()
  }, [fitMapToSelection])

  const onOpenGeofence = useCallback(() => {
    if (!geofenceData.geofence) return

    setCrudSelection('geofences', [geofenceData.geofenceId])
    navigateSetup('geofences')
  }, [navigateSetup, geofenceData])

  const onDelete = useCallback(() => {
    setView('delete')
  }, [setView])

  return {
    onFitMap,
    onOpenGeofence,
    onDelete,
  }
}
