import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../renderingQueue'
import { getLayerMetadata, setLayerMetadata } from '../layerMetadata'

import { findFeature } from '../findFeature'

import { updateRoadSegmentFeature } from './utils/updateRoadSegmentFeature'
import { findRoadSegmentMarker } from './utils/findRoadSegmentMarker'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Update the selected status of the existent RoadSegments features
 */
export async function updateRoadSegmentsMapSelection(
  layer: VectorLayer<VectorSource>,
  mapMarkers: uui.domain.ui.map.markers.MapMarkers['roadSegment'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['roadSegment'],
  selection: Set<string>,
) {
  // retrieve selected items in the current layer
  const layerSelection = (getLayerMetadata(layer, 'selection') ?? new Set()) as Set<string>

  // if prev and next are empty do nothing
  if (selection.size !== 0 || layerSelection.size !== 0) {
    const roadSegmentsToDeselect = new Set(layerSelection)

    nonblockingRender.reset()

    for (const profileId of Object.keys(mapMarkers)) {
      for (const markerId of selection) {
        await nonblockingRender.next()

        // remove it from depotId to be deselected
        roadSegmentsToDeselect.delete(markerId)

        if (layerSelection.has(markerId)) {
          // depotId was previously selected and it's still selected
          // do nothing
          continue
        }

        const marker = findRoadSegmentMarker(mapMarkers[profileId], markerId)
        const feature = findFeature(layer, marker.id)

        if (!feature) {
          throw new Error(
            `Trying to select RoadSegment marker with ID: ${markerId} but no feature exist on the map`,
          )
        }

        // the depotId is a new selection
        updateRoadSegmentFeature(mapStyles, marker, feature, true)
      }
    }

    nonblockingRender.reset()

    for (const profileId of Object.keys(mapMarkers)) {
      for (const markerId of roadSegmentsToDeselect) {
        await nonblockingRender.next()

        const marker = findRoadSegmentMarker(mapMarkers[profileId], markerId)
        const feature = findFeature(layer, marker.id)

        if (!feature) {
          // fail silently in production when deselecting unknown items
          if (process.env.NODE_ENV === 'development') {
            console.warn(
              `Trying to deselect RoadSegment marker with ID: ${markerId} but no feature exist on the map`,
            )
          }

          continue
        }

        // deselect the road segment
        updateRoadSegmentFeature(mapStyles, marker, feature, false)
      }
    }
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
