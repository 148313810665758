import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'
import { findFeature } from '../../findFeature'

import { findDeviceMarker } from './findDeviceMarker'
import { updateDeviceFeature } from './updateDeviceFeature'

const nonblockingRender = createNonBlockingRenderByTime()

export const changelogUpdateDeviceFeatures = async (
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.Device>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['device'],
  selection: Set<string>,
  markerIds: IterableIterator<string>,
) => {
  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findDeviceMarker(markers, markerId)
    if (!marker) {
      throw new Error(
        `Trying to update Device feature with ID: ${markerId} but no marker exist on the map`,
      )
    }

    const feature = findFeature(layer, marker.id)

    if (!feature) {
      throw new Error(
        `Trying to update Device feature with ID: ${marker.id} but no feature exist on the map`,
      )
    }

    const selected = selection.has(markerId)
    updateDeviceFeature(mapStyles, marker, feature, selected)
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
