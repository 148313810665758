import { MenuItem as MUIMenuItem, styled } from '@mui/material'

type Props = {
  open: boolean
}

export const MenuItem = styled(MUIMenuItem, { name: 'MenuItem' })<Props>(({ open, theme }) => ({
  backgroundColor: open ? theme.palette.action.hover : 'inherit',
  position: 'relative',

  '&:hover': {
    '.arrow': {
      opacity: 1,
    },

    '.iconContainer': {
      opacity: 0,
    },
  },
}))
