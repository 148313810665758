import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    anyTranslation: translate({ id: 'global.any' }),
    onDayTranslation: translate({ id: 'global.on' }),
    byDayTranslation: translate({ id: 'global.by' }),
  }))

  return api
}
