import type { PublicProps as Props } from './typings'
import { useState, useCallback } from 'react'
import { Box, ClickAwayListener } from '@mui/material'

import { useIsUnmounted } from '@/hooks'

import { CollapsedState } from './components/CollapsedState'
import { ExpandedState } from './components/ExpandedState'

import { defaultQueryMinLength } from './constants'

const LocationEditor = (props: Props) => {
  const {
    onOpen,
    onClose,
    assetList,
    queryMinLength,
    startCollapsed: initiallyCollapsed = true,
  } = props

  const isUnmounted = useIsUnmounted()

  const [collapsed, setCollapsed] = useState<boolean>(initiallyCollapsed)

  const closeList = useCallback(() => {
    if (isUnmounted()) return
    const prevent = onClose ? !!onClose() : false

    if (!prevent) {
      setCollapsed(true)
    }
  }, [onClose, isUnmounted])

  const openList = useCallback(() => {
    const prevent = onOpen ? !!onOpen() : false

    if (!prevent) {
      setCollapsed(false)
    }
  }, [onOpen])

  const Comp = collapsed ? CollapsedState : ExpandedState

  return (
    <ClickAwayListener onClickAway={closeList}>
      <Box>
        <Comp
          {...props}
          collapsed={collapsed}
          closeList={closeList}
          openList={openList}
          assetList={assetList}
          queryMinLength={queryMinLength || defaultQueryMinLength}
        />
      </Box>
    </ClickAwayListener>
  )
}

export default LocationEditor
