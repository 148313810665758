import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    any: translate({ id: 'order.form.bulk.eligibility.type.label.any' }),
    on: translate({ id: 'order.form.bulk.eligibility.type.label.on' }),
    by: translate({ id: 'order.form.bulk.eligibility.type.label.by' }),
    mixed: translate({ id: 'order.form.bulk.eligibility.type.label.mixed' }),
  }))

  return api
}
