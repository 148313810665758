import OlMap from 'ol/Map'

import { findLayer } from '../../layers/utils/findLayer'

import { getInteraction, registerInteraction, setInteractionMetadata } from '../interactionMetadata'
import { DrawEditableRoadSegmentInteraction } from '../EditableRoadSegmentInteraction'

export const registerDrawEditableRoadSegmentInteraction = (map: OlMap) => {
  const uid = 'rm-draw-editable-roadSegment-interaction'

  if (getInteraction(uid)) return

  // -------------------------------------------
  // Initialize the Interaction

  const drawLayer = findLayer(map, 'draw')
  const drawSource = drawLayer.getSource()

  if (!drawSource) {
    throw new Error('Canno register draw editable road segment interaction: Source is undefined')
  }

  const drawEditableRoadSegment = new DrawEditableRoadSegmentInteraction({
    source: drawSource,
    // set the base style for the `OVERLAY` layer
    type: 'disabled',
  })

  // by default the Interaction is disabled
  drawEditableRoadSegment.setActive(false)

  // -------------------------------------------
  // set Interaction Metadata

  setInteractionMetadata(drawEditableRoadSegment, 'rmInteraction', true)
  setInteractionMetadata(drawEditableRoadSegment, 'uid', uid)
  registerInteraction(uid, drawEditableRoadSegment)

  // -------------------------------------------
  // Add the interaction to the map

  // TODO: is this redundant with `registerInteraction` ???
  map.addInteraction(drawEditableRoadSegment)
}
