import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

type RegionFeatureMetadata = {
  mode: 'on' | 'off'
  type: 'region'
}

export function setRegionFeatureMetadata<Field extends keyof RegionFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
  value: RegionFeatureMetadata[Field],
) {
  target.set(field, value)
}

export function getRegionFeatureMetadata<Field extends keyof RegionFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
): RegionFeatureMetadata[Field] | undefined {
  return target.get(field)
}

export function removeRegionFeatureMetadata<Field extends keyof RegionFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
) {
  target.unset(field)
}
