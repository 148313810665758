import { useCallback } from 'react'

import { Trash } from '@/icons'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'

import { useIsDepotLinkedToOrderSteps } from '../../../hooks/useIsDepotLinkedToOrderSteps'
import { useTexts } from '../hooks/useTexts'

interface Props {
  depots: uui.domain.client.rm.Depot[]
  view: 'default' | 'delete'
  setView: (view: 'default' | 'delete') => void
}

export function Actions(props: Props) {
  const { depots, setView } = props

  const ids = depots.map(d => d.id)

  const linked = useIsDepotLinkedToOrderSteps(ids)
  const texts = useTexts()

  const onDelete = useCallback(() => {
    setView('delete')
  }, [setView])

  return (
    <SecondaryColumnHeaderActionsRow centered>
      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={onDelete}
            disabled={linked || preventEditing}
            className="pendo-setup-depot__form-bulkdelete"
            data-testid="setup-depot__bulk-delete-button"
            data-trackid="setup-depot__bulk-delete-button"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
