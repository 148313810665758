import type { SingleViewActionsProps } from '../../typings'

import {
  SecondaryColumnHeaderActionsRow,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsSeparator,
} from '@/forms-legacy'
import { Tooltip } from '@/components/primitives/Tooltip'
import { useFitInOrderModal } from '@/components/modals/FitInOrderModal'
import { useDeleteOrderModal } from '@/components/modals/DeleteOrderModal'
import { useResidentModalsApi } from '@/components/modals/ResidentModals'
import { useUnassignOrderModal } from '@/components/modals/UnassignOrderModal'
import { useDuplicateOrderModal } from '@/components/modals/DuplicateOrderModal'
import { useCopySelectedOrdersModal } from '@/components/modals/CopySelectedOrdersModal'
import { SendBackward, Duplicate, Export, Gears, Trash, Edit, Unassign, CopyOrders } from '@/icons'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import * as OrderTexts from '../../../../intl'

import { useSingleViewData } from './hooks/useSingleViewData'

export function SingleViewActions(props: SingleViewActionsProps) {
  const { order, tab } = props

  const { show: showFitInOrderModal, Modal: FitInOrderModal } = useFitInOrderModal()
  const {
    exportOrders: { show: showExportOrderModal },
  } = useResidentModalsApi()
  const { show: showDeleteOrderModal, Modal: DeleteOrderModal } = useDeleteOrderModal()
  const { show: showUnassignOrderModal, Modal: UnassignOrderModal } = useUnassignOrderModal()
  const { show: showDuplicateOrderModal, Modal: DuplicateOrderModal } = useDuplicateOrderModal()
  const { show: showCopySelectedOrdersModal, Modal: CopySelectedOrdersModal } =
    useCopySelectedOrdersModal()

  const {
    canUnassign,
    canUnassignTitle,
    editTitle,
    hasPaired,
    onGoToPaired,
    isSimulation,
    canAddNewOrder,
    isPairedInRange,
    goToTooltipTitle,
    goToTooltipSubtitle,
    isExecutionEventsTab,
    canEditExecutionEvents,
    duplicateDisabledTooltipTitle,
    duplicateDisabledTooltipSubtitle,
  } = useSingleViewData(props)

  return (
    !!order && (
      <>
        <SecondaryColumnHeaderActionsRow>
          {hasPaired && (
            <Tooltip
              placement="top"
              title={goToTooltipTitle}
              disabled={isPairedInRange}
              subtitle={goToTooltipSubtitle}
            >
              <SecondaryColumnHeaderActionButton
                label={OrderTexts.getGoToPairedText(order.orderStepType)}
                icon={<SendBackward />}
                onClick={onGoToPaired}
                disabled={!isPairedInRange}
                testid="order__form-gotopaired"
              />
            </Tooltip>
          )}

          {isSimulation && (
            <ReadOnlyTooltip
              placement="top"
              canEdit={canAddNewOrder}
              title={duplicateDisabledTooltipTitle}
              subtitle={duplicateDisabledTooltipSubtitle}
              render={preventEditing => (
                <SecondaryColumnHeaderActionButton
                  disabled={preventEditing || !canAddNewOrder}
                  label={OrderTexts.getCopyToOperations()}
                  onClick={showCopySelectedOrdersModal}
                  testid="orders-copy-to-operations"
                  icon={<CopyOrders />}
                />
              )}
            />
          )}

          <ReadOnlyTooltip
            placement="top"
            canEdit={canAddNewOrder}
            title={duplicateDisabledTooltipTitle}
            subtitle={duplicateDisabledTooltipSubtitle}
            render={preventEditing => (
              <SecondaryColumnHeaderActionButton
                disabled={preventEditing || !canAddNewOrder}
                className="pendo-order__form-duplicate"
                label={OrderTexts.getDuplicateText()}
                onClick={showDuplicateOrderModal}
                testid="order__form-duplicate"
                icon={<Duplicate />}
              />
            )}
          />

          {tab === 0 && (
            <SecondaryColumnHeaderActionButton
              label={OrderTexts.getExportText()}
              icon={<Export />}
              onClick={showExportOrderModal}
              testid="order__form-export"
            />
          )}

          {order.isUnassigned && (
            <ReadOnlyTooltip
              render={preventEditing => (
                <SecondaryColumnHeaderActionButton
                  label={OrderTexts.getFitInText()}
                  icon={<Gears />}
                  onClick={showFitInOrderModal}
                  disabled={preventEditing}
                  testid="orders__fit-in-button"
                />
              )}
            />
          )}

          {!order.isUnassigned && (
            <ReadOnlyTooltip
              canEdit={canUnassign}
              title={canUnassignTitle}
              render={preventEditing => (
                <SecondaryColumnHeaderActionButton
                  label={OrderTexts.getUnassignText()}
                  icon={<Unassign />}
                  onClick={showUnassignOrderModal}
                  disabled={preventEditing}
                  testid="orders__unassign-button"
                />
              )}
            />
          )}

          <SecondaryColumnHeaderActionsSeparator />

          <ReadOnlyTooltip
            render={preventEditing => (
              <SecondaryColumnHeaderActionButton
                label={OrderTexts.getDeleteText()}
                icon={<Trash />}
                onClick={showDeleteOrderModal}
                disabled={preventEditing || !order}
                testid="orders__delete-button"
              />
            )}
          />

          <ReadOnlyTooltip
            render={preventEditing => (
              <SecondaryColumnHeaderActionButton
                label={OrderTexts.getEditText()}
                icon={<Edit />}
                onClick={props.startEditing}
                disabled={preventEditing || (isExecutionEventsTab && !canEditExecutionEvents)}
                title={editTitle}
                testid="orders__edit-button"
              />
            )}
          />
        </SecondaryColumnHeaderActionsRow>

        <DeleteOrderModal />
        <DuplicateOrderModal />
        <FitInOrderModal orderStepIds={[order.id]} />
        <UnassignOrderModal orderStepIds={[order.id]} />
        <CopySelectedOrdersModal orderStepIds={[order.id]} />
      </>
    )
  )
}
