type Break = uui.domain.rm.Break

export const deleteBreak = (breaks: Break[], index: number, count: number = 1): Break[] => {
  const tmp = [...breaks]
  tmp.splice(index, count)
  return tmp
}

export const updateBreak = (breaks: Break[], item: Break, itemIndex: number): Break[] => {
  return breaks.reduce((acc: Break[], value: Break, idx: number) => {
    acc.push(idx === itemIndex ? item : value)
    return acc
  }, [])
}

export const getDefaultBreak = (): Break => ({
  durationSec: 30, // 30mins
  startSec: 9 * 60 * 60, // 09:00am
  endSec: 21 * 60 * 60, // 09:00pm
})

export const addBreak = (breaks: Break[], b?: Break): Break[] => [
  ...breaks,
  !!b ? b : getDefaultBreak(),
]
