import { Stack, useTheme } from '@mui/material'
import { useMemo } from 'react'
import { ViewContainer, ViewContent, ViewField, ViewFieldsLayout } from '@/formUi'
import { useDeleteUserConfirmationModal } from '../modals/DeleteUserConfirmationModal'
import { ViewUsersHeader } from './ViewUsersHeader/ViewUsersHeader'
import { useTexts } from './hooks/useTexts'

type Props = {
  users: uui.domain.client.rm.RouteManagerUser[]
  selection: string[]
}

export function ViewUsers(props: Props) {
  const { users, selection } = props
  const texts = useTexts()
  const theme = useTheme()
  const type = useMemo(() => {
    const usersTypes = new Set(users.map(user => user.type))

    return usersTypes.size === 1 ? users[0].type : texts.mixed
  }, [texts, users])

  const { Modal: DeleteUserConfirmationModal } = useDeleteUserConfirmationModal()
  const borderRight = `1px solid ${theme.palette.grey['300']}`
  const role = `${type[0].toUpperCase()}${type.substring(1)}`

  return (
    <Stack borderRight={borderRight} marginTop={-2} data-testid="form__column-secondary">
      <ViewContainer width={320}>
        <ViewUsersHeader count={users.length} />
        <ViewContent>
          <ViewFieldsLayout paddingTop={2}>
            <ViewField label={texts.labels.role}>{role}</ViewField>
          </ViewFieldsLayout>
        </ViewContent>
      </ViewContainer>
      <DeleteUserConfirmationModal userIds={selection} />
    </Stack>
  )
}
