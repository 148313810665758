import styled from 'styled-components'

import { ValidColor, WithTheme } from '../../../theme'

type Props = workwave.Div & {
  tint?: ValidColor
}

type ThemedProps = WithTheme<Props>

const getTint = ({ tint, theme }: ThemedProps): string => {
  const value = tint ? theme.colors[tint] : undefined
  return value ? value : 'transparent'
}

const div = styled.div<Props>``
export const Track: any = styled(div).attrs<Props>(() => ({
  className: 'o-flex-box',
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  background: ${getTint};
  transform-origin: left center;
  transform: scale(0, 1);
  transition: transform 0.1s linear;
  z-index: 0;
`
