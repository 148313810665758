import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'

import { Header } from './Header'
import { Footer } from './Footer'
import { TicketFormProvider, TicketForm } from './TicketForm'

export function Content() {
  const { ready } = useConfigureController()

  if (!ready) return null

  return (
    <TicketFormProvider>
      <ModalContent header={<Header />} footer={<Footer />}>
        <TicketForm />
      </ModalContent>
    </TicketFormProvider>
  )
}
