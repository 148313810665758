import { Box, styled } from '@mui/material'

export const MapContainer = styled(Box, { name: 'MapContainerComponent' })(() => ({
  position: 'relative',
  '& .switch-tiles': {
    position: 'absolute',
    bottom: '0.5em',
    right: '0.5em',
    top: 'auto',
    '& .ol-custom-control': { fontWeight: '400 !important', fontSize: '16px' },
  },

  '& .ol-attribution': {
    bottom: '0.5em',
    right: '2.5em',
    top: 'auto',
  },

  '& .ol-zoom': {
    fontSize: 24,
    bottom: 32,
    right: 8,
    left: 'unset',
    top: 'unset',
  },

  '& .ol-zoom-in, & .ol-zoom-out': {
    fontWeight: '500',
    color: 'rgba(0,0,0,0.54) !important',
    height: 32,
    width: 32,
  },

  '& .ol-zoom .ol-zoom-in': {
    borderRadius: '4px 4px 0 0',
  },

  '& .ol-zoom .ol-zoom-out': {
    borderRadius: '0 0 4px 4px',
  },

  '& .ol-overviewmap.ol-uncollapsible': {
    top: 'unset',
    left: 8,
    bottom: 32,
    cursor: 'pointer',
    border: '1px solid #fff',
    overflow: 'hidden',
    borderRadius: '8px',
  },

  '& .ol-overviewmap .ol-overviewmap-map': {
    width: '55px',
    height: '55px',
    border: 0,
    pointerEvents: 'all',
  },

  '& .ol-overviewmap .ol-overviewmap-box': {
    display: 'none',
  },

  '& .ol-scale-line': {
    left: 'unset',
    right: 129,
    height: 18,
    bottom: 0,
    borderRadius: 0,
  },

  '& .ol-attribution.ol-uncollapsible': {
    top: 'unset',
    right: 0,
    bottom: 0,
    height: 18,
    borderRadius: 0,
  },
}))
