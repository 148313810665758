import type { SearchOnMapResult } from '@/hooks'

import { useState, useEffect, useCallback } from 'react'
import { useSearchOnMap } from '@/hooks'

export function SearchOnMapTester() {
  const [address, setAddress] = useState('')
  const [result, setResult] = useState<SearchOnMapResult | undefined>()
  const [snapToRoad, setSnapToRoad] = useState(false)
  const searchOnMap = useSearchOnMap({ delay: 500, assetTypes: ['depot', 'place'], snapToRoad })

  const searchCallback = useCallback((result: SearchOnMapResult) => {
    setResult(result)
  }, [])

  useEffect(() => {
    searchOnMap(address, searchCallback)
  }, [address, searchOnMap, searchCallback])

  return (
    <>
      <input value={address} onChange={event => setAddress(event.target.value)} />

      <div>
        <label htmlFor="snapToRoad">snapToRoad (forces geocodeAddress/gpsGeocodeAddress)</label>
        <input
          id="snapToRoad"
          checked={snapToRoad}
          onChange={event => setSnapToRoad(event.target.checked)}
          type="checkbox"
        />
      </div>
      <div>Result type: {result?.requestStatus}</div>
      <div>
        items:
        <br />
        {result?.items.map((item, index) => (
          <div key={index}>
            {item.type} - {item.name} - {item.address}
            <br />
          </div>
        ))}
      </div>
    </>
  )
}
