import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { timeUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export const useFormatDate = (source: string | Date, shortFormat: boolean = false) => {
  const userConfig = useSelector(selectUserConfiguration)

  return useMemo(
    () => formatDate(source, shortFormat, userConfig),
    [userConfig, source, shortFormat],
  )
}

export function formatDate(
  source: string | Date,
  shortFormat: boolean = false,
  userConfig: uui.domain.UserConfiguration,
) {
  return timeUtils.formatDate(userConfig)(source, shortFormat)
}
