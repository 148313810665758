import { useCallback } from 'react'

import {
  SecondaryColumnHeaderActionsRow,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsSeparator,
} from '@/forms-legacy'
import { Trash, Edit, Load, Duplicate } from '@/icons'

import { TransactionInProgressTooltip } from '@/components/smartUtils/plan/TransactionInProgressTooltip'

import { Props as BaseProps } from '../typings'

import * as Texts from '../../../intl'

export interface Props extends BaseProps {
  onShowConfirmPanel: () => void
}

export function SingleViewActions(props: Props) {
  const { simulation, startEditing, openSimulation, onShowConfirmPanel, duplicateSimulation } =
    props

  const handleOnOpenSimulation = useCallback(() => {
    const { id: simulationId } = simulation!
    openSimulation(simulationId)
  }, [openSimulation, simulation])

  const handleOnDuplicate = useCallback(() => {
    duplicateSimulation()
  }, [duplicateSimulation])

  return (
    <SecondaryColumnHeaderActionsRow>
      <TransactionInProgressTooltip
        subtitle={Texts.getTransactionInProgressSubtitle()}
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            icon={<Load />}
            label={Texts.getOpenText()}
            disabled={preventEditing}
            onClick={handleOnOpenSimulation}
            testid="open-simulation"
          />
        )}
      />

      <TransactionInProgressTooltip
        subtitle={Texts.getTransactionInProgressSubtitle()}
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={Texts.getDuplicateText()}
            icon={<Duplicate />}
            disabled={preventEditing}
            onClick={handleOnDuplicate}
            testid="duplicate-simulation"
          />
        )}
      />

      <SecondaryColumnHeaderActionsSeparator />

      <TransactionInProgressTooltip
        subtitle={Texts.getTransactionInProgressSubtitle()}
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={Texts.getDeleteText()}
            icon={<Trash />}
            disabled={preventEditing}
            onClick={onShowConfirmPanel}
            testid="delete-simulation"
          />
        )}
      />

      <TransactionInProgressTooltip
        subtitle={Texts.getTransactionInProgressSubtitle()}
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={Texts.getEditText()}
            icon={<Edit />}
            disabled={preventEditing}
            onClick={startEditing}
            testid="edit-simulation"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
