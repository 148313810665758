/**
 * Retrieve a RoadSegment Marker by its ID or throws an Error if it cannot be found
 */
export function findRoadSegmentMarker(
  markers: Record<string, uui.domain.ui.map.markers.RoadSegment>,
  roadSegmentId: string,
) {
  const marker = markers[roadSegmentId]

  if (!marker) {
    throw new Error(`Unknown RoadSegment Map Marker with ID: ${roadSegmentId}`)
  }

  return marker
}
