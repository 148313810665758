import type { AlertFormValues, NotificationAddress } from '../types'

export function parseAlertFromFormValues(
  formValues: AlertFormValues,
): uui.domain.client.gps.wwgps.EventAlert {
  const { id, eventTypeId, notificationAddresses, ...rest } = formValues

  return {
    id: `${id}`,
    eventTypeId: parseInt(eventTypeId) as uui.domain.server.gps.wwgps.EventAlertType,
    notificationAddresses: notificationAddresses.map(formatRecipients),
    ...rest,
  }
}

function formatRecipients(notificationAddress: NotificationAddress) {
  const notificationType = notificationAddress.prefix === 'email' ? 'mailto' : 'sms'

  return `${notificationType}:${notificationAddress.address}`
}
