import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehicles } from '@/features/domain/vehicle'
import { selectDrivers } from '@/features/domain/driver'
import { getDriver } from '@/server-data'

export const useDriverAssignmentsByDate = (today: string) => {
  const driversMap = useSelector(selectDrivers)
  const vehicles = Object.values(useSelector(selectVehicles))

  return useMemo(
    () =>
      vehicles.reduce<uui.domain.client.rm.DriverAssignments>((acc, vehicle) => {
        if (vehicle?.hasRoutingLicense) {
          const driver = getDriver(driversMap, vehicle, today)

          if (driver) acc[vehicle.unifiedId] = driver.id
        }

        return acc
      }, {}),
    [vehicles, driversMap, today],
  )
}
