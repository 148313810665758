import { type SyntheticEvent, useCallback, useState } from 'react'
import { Box, IconButton, Stack, Tab, Tabs } from '@mui/material'
import { Close } from '@mui/icons-material'

import { useTexts } from '../../useTexts'

import { RequestMaker } from './components/RequestMaker'
import { RequestLog } from './components/RequestLog'

interface Props {
  close: () => void
}

export function Body(props: Props) {
  const { close } = props

  const [defaultRequest, setDefaultRequest] = useState<string | undefined>()
  const [defaultMethod, setDefaultMethod] = useState<string | undefined>()
  const [tab, setTab] = useState('request-maker')

  const onTabsChange = useCallback((_event: SyntheticEvent<Element, Event>, newValue: string) => {
    setTab(newValue)
  }, [])

  const preventPropagation = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
  }, [])

  const texts = useTexts()

  return (
    <Stack spacing={0} padding={2} width={900} height={700}>
      <Stack direction="row" justifyContent="flex-end" width="100%" height={30}>
        <Box>
          <IconButton onClick={close}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Stack>
      <Stack spacing={2} onMouseDown={preventPropagation}>
        <Tabs value={tab} onChange={onTabsChange} variant="fullWidth">
          <Tab value="request-maker" label={texts.tabs.requestMaker} />
          <Tab value="request-log" label={texts.tabs.requestLog} />
        </Tabs>
        {tab === 'request-maker' && (
          <RequestMaker request={defaultRequest} method={defaultMethod} />
        )}
        {tab === 'request-log' && (
          <RequestLog
            setRequestMakerRequest={setDefaultRequest}
            setRequestMakerMethod={setDefaultMethod}
            setTab={setTab}
          />
        )}
      </Stack>
    </Stack>
  )
}
