import { useMemo } from 'react'

export function useFieldStatus(orders: uui.domain.client.rm.ExtendedOrderStep[]) {
  return useMemo(() => {
    const allServices = orders.every(o => o.isService)
    const atLeastOneService = orders.some(o => o.isService)

    return allServices ? 'all-service' : atLeastOneService ? 'at-least-one-service' : 'no-service'
  }, [orders])
}
