import { useTransactionState } from '@/atoms'

type TransactionStatus =
  | {
      readonly: true
      cause: 'transactionInProgress'
    }
  | {
      readonly: false
      cause: 'editable'
    }

/**
 * This is a custom version of the useReadOnly.
 * The main difference is that some stuffs can be edited when somebody else is changing the plan but
 * can't be edited when the user is changing the plan.
 */
export function useTransactionInProgress(): TransactionStatus {
  const { mode: transactionMode } = useTransactionState()

  if (transactionMode === 'controlled')
    return {
      cause: 'transactionInProgress',
      readonly: true,
    }

  return {
    cause: 'editable',
    readonly: false,
  }
}
