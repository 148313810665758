import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../renderingQueue'
import { setLayerMetadata } from '../layerMetadata'
import { findLayer } from '../utils/findLayer'
import { mapAtom } from '../../atoms/map/map'

import { createDeviceEventPointsFeatures } from './utils/deviceEventPoints/createDeviceEventPointsFeatures'

const nonblockingRender = createNonBlockingRenderByTime()

export const initializeDeviceEventFeatures = async (
  markers: uui.domain.ui.map.markers.MapMarkers['deviceEvent'],
  selection: Set<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) => {
  const map = mapAtom.map
  const layerId = 'deviceEvent'

  // DeviceEvent layer
  const layer = findLayer(map, layerId)

  const features: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const markerId in markers) {
    await nonblockingRender.next()

    features.push(
      ...createDeviceEventPointsFeatures(markers[markerId], selection, breadcrumbTimeRange),
    )
  }

  // add all new features to the layer
  layer.getSource()?.addFeatures(features)

  // keep track of selected items in the current layer
  setLayerMetadata(layer, 'selection', new Set(selection))
}
