import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Unassign(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M-1-1h18v18H-1z" fillOpacity="0" />
        <path d="M11.172 2.055L2 11.225v-8.17a1 1 0 011-1h8.172zM14 4.883v8.172a1 1 0 01-1 1H4.828L14 4.882zM15.132.216l.707.707a.5.5 0 010 .707L1.697 15.772a.5.5 0 01-.707 0l-.708-.707a.5.5 0 010-.707L14.425.216a.5.5 0 01.707 0z" />
      </svg>
    </IconContainer>
  )
}
