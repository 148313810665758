import type { VehicleForCopySettings } from '../typings'

export function getVehiclesForCopySettings(
  vehicles: uui.domain.client.rm.Vehicle[],
  parentVehicle: uui.domain.client.UnifiedVehicle,
  exception: string,
): VehicleForCopySettings[] {
  return vehicles
    .filter(ve => {
      const parentId = parentVehicle.hasRoutingLicense ? parentVehicle.vehicle.id : ''
      return ve.id !== parentId
    })
    .map(ve => {
      const hasExistingException = !!ve.settings[exception]
      return { ...ve, hasExistingException }
    })
}
