import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    ok: translate({ id: 'sendMessageToDriverModal.okButton.title' }),
    back: translate({ id: 'sendMessageToDriverModal.backButton.title' }),
    cancel: translate({ id: 'sendMessageToDriverModal.canceButton.title' }),
    send: translate({ id: 'sendMessageToDriverModal.commandButton.title' }),
    tryAgain: translate({ id: 'sendMessageToDriverModal.tryAgainButton.title' }),
    okButtonTitle: translate({ id: 'shareLocationToDriverModal.okButton.title' }),
    cancelButtonTitle: translate({ id: 'shareLocationToDriverModal.cancelButton.title' }),
  }))

  return api
}
