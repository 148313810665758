import type { SelectionOptions } from './typings'

import { parseModifier } from './parseModifier'
import { processAdditiveSelection } from './processAdditiveSelection'
import { processSubtractiveSelection } from './processSubtractiveSelection'

export const applySelection = (
  prevSelection: string[],
  nextSelection: string[],
  options?: SelectionOptions,
) => {
  const { force, modifiers } = options ?? {}
  const { additive, subtractive, noModifiers } = parseModifier(modifiers)

  if (!!force || noModifiers) {
    if (prevSelection.length === nextSelection.length) {
      // prevent any update if prev and next are shallowly equal
      // TODO: considering Valtio offer smart updates can we drop this check?
      const changed = nextSelection.some(id => !prevSelection.includes(id))

      return changed ? nextSelection : prevSelection
    } else {
      return nextSelection
    }
  }

  if (additive) return processAdditiveSelection(prevSelection, nextSelection)
  if (subtractive) return processSubtractiveSelection(prevSelection, nextSelection)

  return prevSelection
}
