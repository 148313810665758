import { BackButton } from '../../../../elements/BackButton'
import { useRoutesNavigator } from '../../hooks/useRoutesNavigator'

import { Styler } from './components/Styler'

interface Props {
  reset: () => void
}

export function MapStyle(props: Props) {
  const { reset } = props
  const { currentRouteIds } = useRoutesNavigator()

  return (
    <>
      <Styler routeIds={currentRouteIds} />
      <BackButton testid="navigo-routes-mapStyles-back" onClick={reset} />
    </>
  )
}
