import { type GridCellParams } from '@mui/x-data-grid-pro'
import { OpenInNew } from '@mui/icons-material'

import { type TableRow } from '../../../types'
import { LinkWithIcon } from './LinkWithIcon'

// This function return an URL of the current host with the territoryId as a query parameter
function composeTerritoryUrl(territoryId: string) {
  return `${window.location.origin}/setup/vehicles?territoryId=${territoryId}`
}

export function TerritoryCell(params: GridCellParams<{ id: string; name: string }, TableRow>) {
  const { id, name } = params.row.territory

  const href = composeTerritoryUrl(id)

  return (
    <LinkWithIcon href={href} icon={<OpenInNew />}>
      {name}
    </LinkWithIcon>
  )
}
