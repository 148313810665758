import { getMainSelection, getCrudSelection } from '@/atoms'

/**
 * Backups the current selections
 */
export function backupSelections() {
  const backup = {
    crud: getCrudSelection(true),
    main: getMainSelection(true),
  }

  return backup
}
