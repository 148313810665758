import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectVehicles,
  selectVehiclesByDeviceId,
  selectVehiclesByVehicleId,
} from '@/features/domain/vehicle'
import { selectDriverAssignments } from '@/features/domain/driverAssignments'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectDevices } from '@/features/domain/device'
import { selectDrivers } from '@/features/domain/driver'

import { getAssociatedUnifiedVehicleId } from '@/server-data'

import { type GpsInfoWithColor } from '../../../../types'
import { VehicleInfo } from './components/VehicleInfo'
import { DeviceInfo } from './components/DeviceInfo'

interface Props {
  point: GpsInfoWithColor
}

export function Info(props: Props) {
  const { point } = props

  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const vehiclesByDeviceId = useSelector(selectVehiclesByDeviceId)
  const driverAssignments = useSelector(selectDriverAssignments)
  const calendarRange = useSelector(selectCalendarRange)
  const vehicles = useSelector(selectVehicles)
  const drivers = useSelector(selectDrivers)
  const devices = useSelector(selectDevices)

  const vehicle = useMemo(() => {
    if (calendarRange.minDate !== calendarRange.maxDate) return
    const device = devices[point.deviceId]

    if (!device) return

    const vehicleId = getAssociatedUnifiedVehicleId(
      devices[point.deviceId],
      drivers,
      driverAssignments,
      vehiclesByDeviceId,
      vehiclesByVehicleId,
      calendarRange.minDate,
    )

    if (!vehicleId) return

    return vehicles[vehicleId]
  }, [
    vehiclesByVehicleId,
    vehiclesByDeviceId,
    driverAssignments,
    calendarRange,
    vehicles,
    devices,
    drivers,
    point,
  ])

  if (!vehicle) return null

  if (vehicle.hasRoutingLicense) return <VehicleInfo vehicle={vehicle} point={point} />
  if (vehicle.isPhysicalDevice && vehicle.trackingProvider === 'WWGPS') {
    return <DeviceInfo device={vehicle.device} point={point} />
  }

  return null
}
