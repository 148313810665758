import { useCallback } from 'react'

import { resetCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { useIsUnmounted, useNotification } from '@/hooks'
import { fileUtils, regionUtils } from '@/utils'
import { importRegionsFromKML } from '@/features/domain/region'
import { useAppDispatch } from '@/store'

import { useTexts } from '../../../useTexts'

export function useActions() {
  const { editing, setEditing } = useEditingStateWithCtx('region')
  const isUnmounted = useIsUnmounted()
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  const onCreate = useCallback(() => {
    resetCrudSelection('regions')
    setEditing([])
  }, [setEditing])

  const onImportKml = useCallback(async () => {
    const file = await fileUtils.openFile()
    if (!file) return

    const readFileResult = await fileUtils.readFile(file, { asText: true })
    if (isUnmounted()) return

    if (readFileResult.type !== 'resolved') {
      toast.error(texts.importKmlFailed)
      return
    }

    try {
      const regions = regionUtils.KMLToRegions(readFileResult.file.content)
      const result = await dispatch(importRegionsFromKML(regions))

      if (importRegionsFromKML.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, isUnmounted, toast, texts])

  return { onCreate, onImportKml, editing }
}
