import { Component, ReactNode } from 'react'

import { ReadonlyLabel, ReadonlyField, FlexRow } from '@/forms-legacy'

import TagPlaceholder from '../TagPlaceholder'
import CollapseButton from '../CollapseButton'

import TextButton from './elements/TextButton'
import DeleteBtn from './elements/DeleteBtn'
import TagLabel from './elements/TagLabel'
import { Props } from './typings'

interface State {
  collapsed: boolean
}

const toggleCollapsed =
  () =>
  ({ collapsed }: State) => ({
    collapsed: !collapsed,
  })

export default class PartialTags extends Component<Props, State> {
  readonly state: State = {
    collapsed: false,
  }

  private handleToggleCollapsed = () => {
    this.setState(toggleCollapsed())
  }

  private handleRemoveAll = () => {
    const { onRemovePartialTags, partial } = this.props
    onRemovePartialTags(Object.keys(partial))
  }

  private renderPartialTag = ([key, val]): ReactNode => {
    const { onRemovePartialTags, total, onAddPartialTags, forceDisableAddTag, renderText } =
      this.props

    return (
      <FlexRow key={key} style={{ marginBottom: 5 }}>
        <ReadonlyField style={{ justifyContent: 'space-between', height: 28 }}>
          <FlexRow useAutoWidth>
            <DeleteBtn style={{ marginRight: 10 }} onClick={() => onRemovePartialTags([key])} />
            <TagLabel bold>{key}</TagLabel>
          </FlexRow>
          <TagLabel>
            {val}/{total}
          </TagLabel>
        </ReadonlyField>
        <TextButton
          style={{ marginLeft: 22 }}
          disabled={forceDisableAddTag}
          onClick={() => onAddPartialTags([key])}
        >
          {renderText('addToAll', this.props)}
        </TextButton>
      </FlexRow>
    )
  }

  render() {
    const { partialTagsLabel, partial, renderText } = this.props
    const { collapsed } = this.state
    const partialCount: number = Object.keys(partial).length

    return (
      <div style={{ marginTop: 10 }}>
        <FlexRow>
          <ReadonlyLabel primary style={{ display: 'flex', alignItems: 'baseline' }}>
            {partialTagsLabel}
            {partialCount > 0 && (
              <CollapseButton
                collapsed={collapsed}
                onToggle={this.handleToggleCollapsed}
                text={renderText('toggleVisibility', this.props, collapsed)}
              />
            )}
          </ReadonlyLabel>
          <TextButton style={{ marginLeft: 'auto' }} remove onClick={this.handleRemoveAll}>
            {renderText('remove', this.props, undefined, partialCount)}
          </TextButton>
        </FlexRow>
        {partialCount === 0 && <TagPlaceholder>{renderText('noTags', this.props)}</TagPlaceholder>}
        {!collapsed && Object.entries(partial).map(this.renderPartialTag)}
      </div>
    )
  }
}
