import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    footerTitle: translate({ id: 'gps.places.edit.formErrorsTitle' }),
    create: translate({ id: 'gps.places.edit.createButton' }),
    cancel: translate({ id: 'gps.places.edit.discardButton' }),
    save: translate({ id: 'gps.places.edit.updateButton' }),
    saveTitle: (valid: boolean, planLocked: boolean, pristine: boolean) => {
      if (!valid)
        return translate({
          id: 'places.form.submitButton.disabled.title.invalid',
        })

      if (planLocked)
        return translate({
          id: 'places.form.submitButton.disabled.title.planLocked',
        })

      if (pristine)
        return translate({
          id: 'places.form.submitButton.disabled.title.pristine',
        })

      return ''
    },
  }))

  return api
}
