import { Typography } from '@mui/material'
import { isDark, getColorValue, theme } from '@/styles'

import { useStyles } from './hooks/useStyles'
import { useShowText } from './hooks/useShowText'
import { DeviceIcon } from './DeviceIcon'

interface Props {
  vehicle: uui.domain.client.UnifiedVehicle
  size: number
  marginRight?: number
  icon?: string
}

export function VehicleAvatar(props: Props) {
  const { vehicle, size, marginRight = 0 } = props

  const showText = useShowText(vehicle)

  const backgroundColor = vehicle.hasRoutingLicense
    ? getColorValue(vehicle.vehicle.color)
    : vehicle.isPhysicalDevice && vehicle.trackingProvider === 'WWGPS'
    ? getColorValue(vehicle.device.iconColor1)
    : theme.colors.$pureWhite

  const color = isDark(backgroundColor) ? theme.colors.$pureWhite : theme.colors.$nightRider

  const label = getAvatarLabel(vehicle)

  const iconId =
    vehicle.isPhysicalDevice && vehicle.trackingProvider === 'WWGPS' ? vehicle.deviceIconId : null

  const [classes, styles] = useStyles({ size, backgroundColor, color, marginRight })

  return (
    <div className={classes.container} style={styles.container}>
      {showText ? (
        <Typography variant="subtitle1">{label}</Typography>
      ) : (
        <DeviceIcon iconId={iconId} />
      )}
    </div>
  )
}

function getAvatarLabel(unifiedVehicle: uui.domain.client.UnifiedVehicle) {
  if (unifiedVehicle.hasRoutingLicense) {
    return unifiedVehicle.vehicle.idx !== -1 ? `${unifiedVehicle.vehicle.idx}` : ''
  }
  if (unifiedVehicle.isPhysicalDevice && unifiedVehicle.trackingProvider === 'WWGPS') {
    return unifiedVehicle.device.label
  }

  return ''
}
