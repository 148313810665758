import type { PartialStoreState } from '../typings'

import { createSelector } from '@reduxjs/toolkit'

export const selectDrivers = (state: PartialStoreState) => state.domain.publicData.domain.rm.drivers

export const selectDriversMapByEmail = createSelector(selectDrivers, drivers => {
  const driversMapByEmail = {}
  for (const driver of Object.values(drivers)) {
    driversMapByEmail[driver.driver.deployment.email] = driver
  }
  return driversMapByEmail
})
