import { type ResourceModel } from '@bryntum/schedulerpro'
import { useMemo } from 'react'
import { useTexts } from '../useTexts'

export function useAggregateData(record: ResourceModel) {
  const texts = useTexts()

  return useMemo(() => {
    const hasViolations = record.getData(
      'hasViolations',
    ) as uui.domain.client.rm.SchedulerResource['hasViolations']

    if (!hasViolations) return []

    const result: string[] = []
    const violations = record.getData('violations') as uui.domain.rm.RouteViolations

    const { vehicle = [], loads = [], steps = {} } = violations

    if (vehicle.length > 0) {
      result.push(texts.vehicleViolations(vehicle.length))
    }

    const stepsCount = Object.keys(steps).length

    if (stepsCount > 0) {
      result.push(texts.stepsViolations(stepsCount + loads.length))
    }

    return result
  }, [record, texts])
}
