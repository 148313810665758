import { Stack } from '@mui/material'
import { FormLayout } from '@/forms-legacy'

import { ExecutionEventsFormValues, FormProps as Props } from './typings'

import ExecutionEvents from './components/ExecutionEvents'
import Pods from './components/Pods'
import { DynamicLoadsField } from './components/DynamicLoadsField'
import { CustomFieldsField } from './components/CustomFieldsField'

export function Form(props: Props) {
  const {
    formRenderProps: {
      form: { getState },
    },
    territory: { enableDynamicLoads, enableDynamicCustomFields },
  } = props

  const values = getState().values as ExecutionEventsFormValues

  return (
    <FormLayout>
      <Stack spacing={3} width="100%">
        <ExecutionEvents {...props} values={values} />
        {enableDynamicLoads && <DynamicLoadsField />}
        {enableDynamicCustomFields && <CustomFieldsField />}
        <Pods {...props} values={values} />
      </Stack>
    </FormLayout>
  )
}
