import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'

import { Simulation } from './components/Simulation'
import { Operations } from './components/Operations'

export function RoutingNav() {
  const { planType } = useSelector(selectUserConfiguration)

  return planType === 'simulation' ? <Simulation /> : <Operations />
}
