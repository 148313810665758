import styled from 'styled-components'

type Props = {
  small?: boolean
}

export const ReadonlyVerticalList = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 6px;
  width: ${p => (p.small ? '71%' : '100%')};

  & > * {
    margin-top: 6px;
    width: 100%;
  }
`

ReadonlyVerticalList.displayName = 'ReadonlyVerticalList'
