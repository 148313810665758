import type { PartialStoreState } from '../typings'
import { createSelector } from '@reduxjs/toolkit'

export const selectDriverAssignments = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.driverAssignments

export const selectDriverAssignmentsIds = createSelector(
  selectDriverAssignments,
  driverAssignments => Object.keys(driverAssignments),
)

export const selectDriverAssignmentsWithId = createSelector(
  selectDriverAssignments,
  driverAssignments =>
    Object.entries(driverAssignments).reduce<
      Record<string, uui.domain.client.rm.DriverAssignmentsWithId>
    >((acc, [id, assignments]) => {
      acc[id] = {
        id,
        assignments,
      }
      return acc
    }, {}),
)
