import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { intl } from '@/intl'
import { timeUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export function useFormatEligibility() {
  const userConfig = useSelector(selectUserConfiguration)

  return useCallback(
    (eligibility: uui.domain.client.rm.Eligibility) => {
      function formatDate(dateAsString: string) {
        return timeUtils.formatDate(userConfig)(dateAsString)
      }

      switch (eligibility.type) {
        case 'on':
          return eligibility.dates.length > 0 ? eligibility.dates.map(formatDate).join('; ') : ''

        case 'by':
          return formatDate(eligibility.date)

        default:
        case 'any':
          return intl.translate({ id: 'global.any' })
      }
    },
    [userConfig],
  )
}
