import { type ResourceModel } from '@bryntum/schedulerpro'
import { DoubleColumnContainer } from '../../Scheduler/components/DoubleColumnContainer'

import { DynamicLoadCellContent } from './components/DynamicLoadCellContent'

interface Props {
  language: uui.domain.Language
  record: ResourceModel
  field: string
  value: uui.domain.client.rm.SchedulerResource['loads'][string]
  showApprovedRoutes: boolean
  showAbsoluteValues: boolean
}

export function DynamicLoadCell(props: Props) {
  const { value, language, showApprovedRoutes, showAbsoluteValues } = props

  const secondRow =
    showApprovedRoutes && value?.approved ? (
      <div style={{ transform: 'translateY(4px)' }}>
        {/* the translate will create a little space without changing the layout */}
        <DynamicLoadCellContent
          value={value?.approved}
          language={language}
          showAbsoluteValues={showAbsoluteValues}
        />
      </div>
    ) : undefined

  return (
    <DoubleColumnContainer
      firstRow={
        <DynamicLoadCellContent
          value={value?.current}
          language={language}
          showAbsoluteValues={showAbsoluteValues}
        />
      }
      secondRow={secondRow}
    />
  )
}
