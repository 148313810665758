import type { Theme } from '@/local/components'

import { theme as componentsTheme } from '@/local/components'
import { layoutSizes } from './layoutSizes'

const defaultWildCards: string[] = ['transparent']

export const getColorValue = (color: string, wildcards: string[] = defaultWildCards): string => {
  if (wildcards.includes(color)) {
    return color
  }
  if (color.startsWith('#') || color.startsWith('rgb')) return color
  return `#${color}`
}

export const getSize = (key: string): number => {
  const found = layoutSizes.get(key)

  if (!found) {
    throw new Error(`no size for key: ${key}`)
  }
  return found
}

export type { Theme } from '@/local/components'
export const theme: Theme = {
  ...componentsTheme,
}
