import type { AddTagOption as Option } from '../../types'

import { useState } from 'react'

import { Box, Stack, Typography, IconButton, Autocomplete, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'

import { Option as AutocompleteOption } from './components/Option'
import { ListItem } from './components/ListItem'
import { useTexts } from './hooks/useTexts'

interface Props {
  title: string
  testId?: string
  options: Option[]
  onAddTag: (tagName: string) => void
  onClose: () => void
  bannedTagCategory: 'tagsIn' | 'tagsOut'
}

function isOptionEqualToValue(optA: Option, optB: Option) {
  return optA.label === optB.label
}

export function AddTag(props: Props) {
  const { onAddTag, onClose, title, bannedTagCategory, options, testId } = props
  const texts = useTexts()
  const [open, setOpen] = useState(true)

  const textFieldTestid = `${testId ?? 'add-tag-modal'}-textField`

  return (
    <Box width={360} padding={3} data-testid={testId}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ pb: 3 }}
      >
        <Typography noWrap variant="h6">
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Autocomplete
        value={null}
        onChange={(_, selectedOption) => {
          if (!selectedOption) return
          onAddTag(selectedOption.label)
        }}
        renderOption={(props, option, autoCompleteState) => {
          return (
            // ListItem is just a styled.li
            <ListItem {...props}>
              <AutocompleteOption
                {...option}
                selected={autoCompleteState.selected}
                warningTooltip={texts.warningTooltip(bannedTagCategory)}
              />
            </ListItem>
          )
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={(_, reason) => {
          if (reason === 'escape') {
            onClose()
            return
          }
          setOpen(false)
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={texts.searchTag}
            autoFocus
            data-testid={textFieldTestid}
          />
        )}
        noOptionsText={texts.noOptionsText}
      />
    </Box>
  )
}
