import styled from 'styled-components'

const styledComponent = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px;
`

styledComponent.displayName = 'SecondaryColumnHeaderRow'
export default styledComponent
