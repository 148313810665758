import { Grid, Skeleton, Stack } from '@mui/material'

const ITEMS_IN_TABLE = 24

export function LoadingSkeleton() {
  return (
    <Stack spacing={6}>
      <Stack>
        <Stack spacing={4}>
          <Skeleton variant="text" width={240} />
          <Skeleton variant="text" width={144} />
        </Stack>

        <Stack alignItems="flex-end" spacing={1}>
          <Skeleton variant="rounded" width={40} height={40} />

          <Skeleton variant="text" width={144} />
          <Skeleton variant="text" width={80} />
        </Stack>
      </Stack>

      <Stack spacing={4}>
        <Grid container rowSpacing={4}>
          <Grid item xs={12}>
            <Skeleton variant="text" width={240} />
          </Grid>

          {new Array(ITEMS_IN_TABLE).fill(undefined).map((_item, index) => (
            <Grid item xs={3} key={`fake-item-${index}`}>
              <Skeleton variant="text" width={152} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  )
}
