import { ModalFooter } from '@/components/Modal'

import { useController } from '../../hooks/useController'

import { ButtonCancel } from './components/ButtonCancel'
import { ButtonClose } from './components/ButtonClose'
import { ButtonDoNotMove } from './components/ButtonDoNotMove'
import { ButtonMove } from './components/ButtonMove'

export function Footer() {
  const ctrl = useController()

  if (ctrl.data.issue === 'none') {
    return (
      <ModalFooter
        primaryAction={<ButtonMove />}
        tertiaryAction={<ButtonDoNotMove />}
        secondaryAction={<ButtonCancel />}
      />
    )
  }

  return <ModalFooter primaryAction={<ButtonClose />} />
}
