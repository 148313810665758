import type TileSource from 'ol/source/Tile'
import TileLayer from 'ol/layer/Tile'

import { mapAtom } from '../map'

export const getTilesLayer = () => {
  return mapAtom.map
    .getLayers()
    .getArray()
    .find(l => {
      return l.get('id') === 'tileLayer'
    }) as TileLayer<TileSource> | undefined
}
