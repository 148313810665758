import type { MouseEvent } from 'react'

import { useCallback } from 'react'
import { InputAdornment, IconButton } from '@mui/material'
import { Clear as ClearIcon } from '@mui/icons-material'

interface Props {
  onClick: () => void
  hidden?: boolean
}

export function Adornment(props: Props) {
  const { onClick, hidden } = props

  // if the input is focused without this onMouseDown the callback of the onClick is not called
  const onMouseDown = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }, [])

  if (hidden) return null

  return (
    <InputAdornment position="end">
      <IconButton onClick={onClick} onMouseDown={onMouseDown} edge="end">
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  )
}
