import styled from 'styled-components'

export const InputRangeOuter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 30px;
`

InputRangeOuter.displayName = 'InputRangeOuter'
