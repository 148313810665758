import type { View } from './types'

import { useCallback, useEffect } from 'react'

import { ConfirmPanel } from '@/forms-legacy'
import { useIsLoading } from '@/atoms'

import { useCountRemainingVehicle } from '../../../../hooks/useCountRemainingVehicle'
import { useCanAddNewVehicle } from '../../../../hooks/useCanAddNewVehicle'

import { ClonePanel } from '../components/ClonePanel'
import { SingleViewRmActions } from './SingleViewRmActions'

import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'

interface Props {
  view: View
  changeView: (view: View) => void
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function ActionsManager(props: Props) {
  const { changeView, unifiedVehicle, view } = props

  if (!unifiedVehicle.hasRoutingLicense) {
    throw new Error('unifiedVehicle is missing routingLicense')
  }

  const vehicleId = unifiedVehicle.vehicle.id

  const { onDelete, onClone } = useActions(vehicleId, changeView)
  const isLoading = useIsLoading('fetchGpsData')
  const canAddNewVehicle = useCanAddNewVehicle(isLoading)
  const maxCopies = useCountRemainingVehicle()

  const texts = useTexts()

  const switchToDefaultView = useCallback(() => changeView('default'), [changeView])
  const switchToDeleteView = useCallback(() => changeView('delete'), [changeView])
  const switchToCloneView = useCallback(() => changeView('clone'), [changeView])

  useEffect(() => {
    switchToDefaultView()
  }, [vehicleId, switchToDefaultView])

  switch (view) {
    case 'delete':
      return (
        <ConfirmPanel
          title={texts.deletePanelTitle}
          description={texts.deletePanelDescription}
          confirmationText={texts.delete}
          onCancel={switchToDefaultView}
          onConfirm={onDelete}
        />
      )

    case 'clone':
      return (
        <ClonePanel
          title={texts.cloneVehicles(maxCopies)}
          buttonText={texts.clone}
          onCancel={switchToDefaultView}
          onClone={onClone}
          maxCopies={maxCopies}
          vehicle={unifiedVehicle}
        />
      )

    default:
      return (
        <SingleViewRmActions
          unifiedId={unifiedVehicle.unifiedId}
          canAddNewVehicle={canAddNewVehicle}
          switchToDeleteView={switchToDeleteView}
          switchToCloneView={switchToCloneView}
        />
      )
  }
}
