import { produceWithPatches } from 'immer'

export function patchRm360DataTelematics(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  formValues: uui.domain.ui.forms.Rm360VehicleFormValues,
) {
  if (!unifiedVehicle.hasRoutingLicense) {
    throw new Error('routing license is required to patch rm data')
  }

  return produceWithPatches(unifiedVehicle, draft => {
    const deviceId = formValues.rm.deviceId

    // update vehicle data
    draft.vehicle.color = formValues.rm.color.color
    draft.vehicle.externalId = formValues.rm.name
    draft.vehicle.gpsDeviceId = deviceId === '' || deviceId === null ? undefined : deviceId
    draft.vehicle.tracked = formValues.rm.eventTracking
    draft.vehicle.edgeOverride = formValues.rm.liveEta ? 'GPS' : 'ROUTESTEP'

    draft.isPhysicalDevice = !!deviceId

    if (draft.isPhysicalDevice) {
      draft.trackingProvider = 'telematics'
    }
  })
}
