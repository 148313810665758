import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectOrderStepsListStructure,
  selectOrderStepsListOptions,
  setListOptions,
} from '@/features/domain/lists'
import { useEditingStateWithCtx } from '@/atoms'
import { useAppDispatch } from '@/store'

export function useSorter() {
  const listStructure = useSelector(selectOrderStepsListStructure)
  const listOptions = useSelector(selectOrderStepsListOptions)
  const { editing } = useEditingStateWithCtx('order')
  const dispatch = useAppDispatch()

  const [direction, setDirection] = useState(() => listOptions.sort[0].direction)
  const [groupBy, setGroupBy] = useState<string | undefined>(
    () => listOptions.group[0]?.field ?? undefined,
  )
  const [field, setField] = useState(() => listOptions.sort[0].field)

  useEffect(() => {
    if (!listOptions.sort) return
    const sameField = listOptions.sort[0].field === field
    const sameGroup =
      (listOptions.group.length > 0 && listOptions.group[0].field === groupBy) ||
      (listOptions.group.length === 0 && groupBy === undefined)
    const sameDirection = listOptions.sort[0].direction === direction
    if (sameField && sameDirection && sameGroup) return

    const sort =
      field === 'ui.sort.eta'
        ? [
            { field, direction },
            { field: 'order.name', direction },
          ]
        : [
            { field, direction },
            { field: 'ui.sort.eta', direction },
          ]

    const group = groupBy
      ? [
          {
            field: groupBy,
            direction: 'asc' as const,
          },
        ]
      : []

    dispatch(setListOptions({ category: 'orderSteps', options: { ...listOptions, group, sort } }))
  }, [dispatch, field, direction, groupBy, listOptions])

  return {
    field,
    groupBy,
    editing,
    setField,
    setGroupBy,
    direction,
    setDirection,
    noEntries: listStructure.totalItems === 0,
  }
}
