import type { FormFields, FormErrors } from '../formFields'

import { TextField } from '@workwave-tidal/tidal/form-ui'

import { useTexts } from '../../hooks/useTexts'

interface Props {
  submitting: boolean
}

export function CompanyFromName(props: Props) {
  const texts = useTexts()

  return (
    <TextField<'companyFromName', FormFields, FormErrors>
      validateOn="blur focus change"
      disabled={props.submitting}
      testId="companyFromName"
      label={texts.companyFromName}
      name="companyFromName"
    />
  )
}
