import { useSelector } from 'react-redux'

import { selectCustomerNotificationsSettings } from '@/features/domain/territory'

export type NotCompletedNotificationValues = ReturnType<typeof useNotCompletedNotificationValues>

/**
 * That hook grabs notCompletedNotification values from the domain and returns them
 */
export function useNotCompletedNotificationValues() {
  const notificationSettings = useSelector(selectCustomerNotificationsSettings)

  return notificationSettings['notCompletedSettings']
}
