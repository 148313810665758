import { useExtractedMapWarningModal } from '../modals/ExtractedMapWarningModal'
import { useTargetLayout } from '../atoms/routingLayoutDropdownState'

/**
 * Expose the Extracted map warning modal connected to the RM Layout dropdown menu atom that contains
 * the target layout. The modal cannot be rendered inside the RoutingLayoutDropdownMenu because the
 * modal must survive to the dropdown close (and unmounted).
 */
export function ExtractedMapWarning() {
  const [modalTargetLayout] = useTargetLayout()
  const { Modal: ExtractedMapWarningModal } = useExtractedMapWarningModal()

  return <ExtractedMapWarningModal targetLayout={modalTargetLayout} />
}
