import { useSelector } from 'react-redux'
import { useExportRoutesPreferences } from '@/atoms'
import { selectUserConfiguration } from '@/features/domain/user'
import { useHasPrivileges } from '@/hooks'

export function useData(dates: string[]) {
  const [exportRoutesPreferences] = useExportRoutesPreferences()
  const canExportInSimulation = useHasPrivileges(['export'], true)
  const { planType } = useSelector(selectUserConfiguration)

  const {
    keepEnglishColumns,
    customizeColumns,
    includeBarcodes,
    currentRoutes,
    includePod,
    singleFile,
    format,
  } = exportRoutesPreferences

  const isSimulation = planType === 'simulation'

  // if endDate and startDate are the same the difference is 0
  const canExportPod = dates.length <= 7

  return {
    canExportInSimulation,
    keepEnglishColumns,
    customizeColumns,
    includeBarcodes,
    currentRoutes,
    isSimulation,
    canExportPod,
    singleFile,
    includePod: !canExportPod ? false : includePod,
    format,
  }
}
