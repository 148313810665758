import { reverseGeocodeLocation } from '../../../utils/reverseGeocodeLocation'
import { getMapInfo } from '../../map/hooks/useGetMapInfo'

import { createInspectPin } from './createInspectPin'
import { setInspectPin } from './setInspectPin'

/**
 * Create the inspect pin and immediately reverse geocode it.
 */
export async function addInspectPin(customLatLng?: uui.domain.LatLng) {
  const { center, bounds } = getMapInfo()
  const latLng = customLatLng ?? center

  // immediately add the locationPin.
  // It will be updated later
  setInspectPin(() => createInspectPin(latLng))

  const location = await reverseGeocodeLocation(latLng, bounds)

  if (location) {
    setInspectPin({ status: 'ready', mode: 'normal', latLng: location.latLng, location })
  }
}
