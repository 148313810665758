import { renderToStaticMarkup } from 'react-dom/server'
import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'

import { selectUserAccountPreferences, selectUserConfiguration } from '@/features/domain/user'
import { store } from '@/store'
import { intl } from '@/intl'

import { DistanceLeftCell } from '../../../components/cells/DistanceLeftCell'

export function createDistanceLeftColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  const state = store.getState()

  const accountPreferences = selectUserAccountPreferences(state)
  const userConfiguration = selectUserConfiguration(state)
  const metric = accountPreferences.distanceFormat === 'METRIC'

  return {
    text: metric
      ? intl.translate({ id: 'rm.scheduler.column.distanceLeftMetric' })
      : intl.translate({ id: 'rm.scheduler.column.distanceLeftImperial' }),
    type: 'template',
    field: 'distanceLeft',
    width,
    editor: false,
    hidden,
    region: 'cols',

    template: ({ record, field, value }) => {
      return renderToStaticMarkup(
        <DistanceLeftCell
          userConfig={userConfiguration}
          record={record as ResourceModel}
          field={field}
          value={value}
        />,
      )
    },

    resizable: true,
    groupable: false,
  }
}
