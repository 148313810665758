import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function TractionControl(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M18.84375 2c-1.66797.0039-3.23047.83203-4.15625 2.21875L11.5 9h-3C7.67187 9 7 9.67188 7 10.5c0 .82813.67188 1.5 1.5 1.5h1l-.34375.5c-.34375.46094-.6289.96484-.84375 1.5H15v3H8.125c.25 2.39844.79688 6.53516.875 7.125l.125.875h.0625l.15625 1.25c.1289 1.0078.98438 1.7578 2 1.75H14c1.10547 0 2-.89453 2-2v-1h18v1c0 1.10547.89453 2 2 2h2.71875c1.01563.0078 1.8711-.7422 2-1.75l.125-1.25h.03125l.125-.875c.07813-.58984.625-4.73438.875-7.125H35v-3h6.6875c-.21484-.52344-.47266-1.01563-.8125-1.46875L40.53125 12H41.5c.82813 0 1.5-.67188 1.5-1.5 0-.82813-.67188-1.5-1.5-1.5h-3l-3.1875-4.78125C34.38672 2.83203 32.82422 2.0039 31.15625 2zm0 2h12.3125c1 0 1.91016.51172 2.46875 1.34375L36.75 10h-23.5l3.125-4.65625C16.9336 4.51172 17.84375 4 18.84375 4zM12 30s4 1.89063 4 4.75c0 2.33984-7 4.85156-7 8.53125C9 45.8125 13 48 13 48h5s-6-2.8711-6-4.75c0-2 7-4.5703 7-8.53125C19 31.89062 16 30 16 30zm23 0s4 1.89063 4 4.75c0 2.33984-7 4.85156-7 8.53125C32 45.8125 36 48 36 48h5s-6-2.8711-6-4.75c0-2 7-4.5703 7-8.53125C42 31.89062 39 30 39 30z" />
      </svg>
    </IconContainer>
  )
}
