import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { convertToClientUserProfile } from '@/local/server-data/domain/server/factories/user'
import { replaceDomainData } from '@/features/domain'
import { loginService } from '@/services'

import { loadInitialData } from './loadInitialData'

type LoginData = Parameters<typeof loginService>[0] & {
  territoryId?: string
  simulationId?: string
}

export type LoginError =
  // for all the below errors, the http statusCode is 401
  | { errorCode: 100; errorMessage: 'LOGIN_ERROR_NO_USER' }
  | { errorCode: 103; errorMessage: 'LOGIN_ERROR_ACCOUNT_SUSPENDED' }
  | { errorCode: 104; errorMessage: 'LOGIN_ERROR_ACCOUNT_EXPIRED' }
  | { errorCode: 105; errorMessage: 'LOGIN_ERROR_ACCOUNT_INACTIVE' }
  | { errorCode: 109; errorMessage: 'LOGIN_ERROR_USER_FILTERED_GUEST_NO_COMPANIES' }
  | { errorCode: 110; errorMessage: 'LOGIN_ERROR_USER_NO_TERRITORIES' }
  | { errorCode: 170; errorMessage: 'LOGIN_ERROR_USER_DISABLED' }
  | { errorCode: 403; errorMessage: 'UNAUTHORIZED_USER_NOT_ADMIN_OR_PLANNER' }
  | { errorCode: 404; errorMessage: 'UNAUTHORIZED_ACCOUNT' }

type RejectErrorType =
  | LoginError['errorMessage']
  | 'invalid-login'
  | 'proxy-error'
  | 'too-many-sessions'
  | 'unknown'

export type RejectError = {
  message: string
  error: Error
  type: RejectErrorType
}

export const login = createAsyncThunk<
  // Return type of the payload creator
  uui.domain.client.UserProfile,
  // First argument to the payload creator
  LoginData,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('lifecycle/login', async (data, thunkApi) => {
  let userProfile: uui.domain.server.UserProfile | undefined

  try {
    const { password, username, serverUrl } = data
    thunkApi.extra.journal.store(`Trying to log as: ${username}`)

    userProfile = await loginService({ password, username, serverUrl })
  } catch (e) {
    const errorData = e.response.data as LoginError
    const { errorMessage: message } = errorData

    thunkApi.extra.journal.store(message, { tags: ['login', 'manual-login'] }, 'warn')

    return thunkApi.rejectWithValue({
      message,
      error: { name: 'invalid-login', message },
      type: message,
    })
  }

  if (!userProfile) {
    const message = 'Invalid login credentials'
    thunkApi.extra.journal.store(message, { tags: ['login', 'manual-login'] }, 'warn')
    return thunkApi.rejectWithValue({
      message,
      error: { name: 'invalid-login', message },
      type: 'invalid-login',
    })
  }

  try {
    const clientUserProfile = convertToClientUserProfile(userProfile)

    // initialize server-data web-worker,
    const initialDomainState = await thunkApi.extra.domainProxy.create(
      data.serverUrl,
      clientUserProfile,
    )

    if (!initialDomainState || initialDomainState === 'tooManySessionsError') {
      let message = ''
      let name: RejectErrorType

      switch (initialDomainState) {
        case false:
          message = 'The Domain WebWorker failed to connect (Unknown error).'
          name = 'proxy-error'
          break
        case 'tooManySessionsError':
          message = 'The Domain WebWorker failed to connect (Too many open sessions).'
          name = 'too-many-sessions'
          break
        default:
          message = 'The Domain WebWorker failed to connect (Too many open sessions).'
          name = 'unknown'
          break
      }

      thunkApi.extra.journal.store(message, { tags: ['login', 'auto-login'] }, 'error')

      return thunkApi.rejectWithValue({
        error: { name, message },
        type: name,
        message,
      })
    }

    thunkApi.dispatch(replaceDomainData(initialDomainState))

    const territoryId =
      data.territoryId ||
      (clientUserProfile.user.type !== 'gpsonly'
        ? clientUserProfile.user.uiData.lastOpenedSession?.territoryId
        : undefined)

    const simulationId =
      data.simulationId ||
      (clientUserProfile.user.type !== 'gpsonly'
        ? clientUserProfile.user.uiData.lastOpenedSession?.planId !== 'ops'
          ? clientUserProfile.user.uiData.lastOpenedSession?.planId
          : undefined
        : undefined)

    thunkApi.dispatch(loadInitialData({ simulationId, territoryId }))

    // return the profile
    return clientUserProfile
  } catch (error) {
    const message = 'Login Failed'
    thunkApi.extra.journal.store(message, { tags: ['login', 'manual-login'] }, 'error')
    return thunkApi.rejectWithValue({ type: 'unknown', message, error })
  }
})
