import type { FormFields, FormErrors } from '../../formFields'

import { useMemo } from 'react'
import { RadioGroup } from '@workwave-tidal/tidal/form-ui'
import { getDayLabelFromIndex } from '@/intl'

import { weekDays } from '../../../weekDays'
import { useTexts } from './useTexts'

export function WeekStartsOn() {
  const texts = useTexts()

  const options = useMemo(
    () =>
      Object.entries(weekDays).map(([value, index]) => ({
        value,
        label: getDayLabelFromIndex(index, 'long'),
      })),
    [],
  )

  return (
    <RadioGroup<'weekStartsOn', string, FormFields, FormErrors>
      validateOn="blur focus"
      options={options}
      testId="weekStartsOn"
      label={texts.weekStartsOn}
      name="weekStartsOn"
    />
  )
}
