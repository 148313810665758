import { CSSProperties, PureComponent, ReactNode } from 'react'
import {
  Field as FinalFormField,
  FieldProps as FFieldProps,
  FieldRenderProps as FFieldRenderProps,
} from 'react-final-form'

import { isDeepEqual } from '@/server-data'
import {
  TextButton,
  Block,
  Label,
  FieldMessage,
  HourPicker,
  HourPickerProps,
  RestoreFieldButton,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  ReadonlyField,
} from '@/forms-legacy'

export type FieldProps = FFieldProps<number, any>
export type FieldRenderProps = FFieldRenderProps<number>

export interface Props extends FieldProps {
  name: string
  label: ReactNode
  info?: string
  half?: boolean
  nullValue: number
  defaultValue: number
  removeLabel: string
  editLabel: string

  nullLabel: string
  wrappedInputProps?: Omit<HourPickerProps, 'onChange' | 'value' | 'name'>
  testId?: string
}

const removeStyleBtn: CSSProperties = {
  fontSize: 12,
  color: '#fa5f3f',
  marginLeft: 'auto',
}

export default class ExecutionTimeField extends PureComponent<Props> {
  private renderField = (formProps: FieldRenderProps) => {
    const {
      label,
      name,
      info,
      half = false,
      wrappedInputProps,
      nullValue,
      removeLabel,
      nullLabel,
      defaultValue,
      editLabel,
      testId = 'ExecutionTimeField-root',
    } = this.props
    const {
      input: { value, onChange },
      meta: { error, dirty, initial },
    } = formProps

    const nullMode: boolean = value === nullValue

    return (
      <Block half={half} error={!!error} dirty={!!dirty} data-testid={testId}>
        <Label htmlFor={name}>
          {label}{' '}
          {!nullMode && (
            <RestoreFieldButton
              dirty={dirty}
              initial={initial}
              onChange={onChange}
              style={{ marginLeft: 12 }}
            />
          )}
          {!nullMode && (
            <TextButton style={removeStyleBtn} onClick={() => onChange(nullValue)}>
              {removeLabel}
            </TextButton>
          )}
        </Label>
        {nullMode && (
          <ReadonlyField>
            <ReadonlyFieldRow style={{ justifyContent: 'space-between' }}>
              <ReadonlyFieldLabel>{nullLabel}</ReadonlyFieldLabel>
              <TextButton data-testid={`${testId}_edit`} onClick={() => onChange(defaultValue)}>
                {editLabel}
              </TextButton>
            </ReadonlyFieldRow>
          </ReadonlyField>
        )}
        {!nullMode && (
          <HourPicker name={name} onChange={onChange} value={value} {...wrappedInputProps} />
        )}
        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }

  render() {
    const { name, ...props } = this.props
    return <FinalFormField {...props} isEqual={isDeepEqual} name={name} render={this.renderField} />
  }
}
