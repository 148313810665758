import { DrivingTimeSliderField } from '@/forms-legacy'

import { drivingTimeSliderFieldWrappedProps } from '../constants'
import { useTimeWindowLabels } from '../hooks/useTimeWindowLabels'

interface Props {
  hidden: boolean
}

export function FirstTimeWindow(props: Props) {
  const [label] = useTimeWindowLabels()

  if (props.hidden) {
    return null
  }

  return (
    <DrivingTimeSliderField
      label={label}
      name="firstTimeWindowValue"
      testid="firstTimeWindowValue"
      wrappedInputProps={drivingTimeSliderFieldWrappedProps}
    />
  )
}
