import { useState, useCallback, useRef, useEffect } from 'react'
import { differenceInDays } from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { memoizeOne } from '@/server-data'
import { selectCalendarRangeInterval } from '@/features/domain/ui'

import { CalendarClickType } from '@/components/Calendar/typings'
import { useActions } from './useActions'
import { useShouldRedirectToRouting } from './useShouldRedirectToRouting'

type CalendarState = { start?: Date; end?: Date; count: number }

const getDaysCount = memoizeOne((start?: Date, end?: Date) => {
  if (!start || !end) return 1

  return Math.abs(differenceInDays(start, end)) + 1
})

/**
 * This hook manages the internal state of TerritoryCalendar.
 * The differences from a regular useState are:
 *
 * - Fetch part of the initial value from the global state
 * - Compute the value of `count` before the update of the state
 */
export const useCalendarState = (today: Date, onClose: () => void) => {
  const [submitting, setSubmitting] = useState(false)

  const interval = useSelector(selectCalendarRangeInterval)

  const { changeCalendarRange, navigateToHome } = useActions()

  const [calendarState, setCalendarState] = useState<CalendarState>(() => ({
    end: interval.end,
    start: interval.start,
    count: getDaysCount(interval.start, interval.end),
  }))

  const { start, end } = calendarState
  const calendarStateRef = useRef({ start, end })
  useEffect(() => void (calendarStateRef.current = { start, end }), [start, end])

  const shouldRedirectToRouting = useShouldRedirectToRouting(today, start, end)

  const applyNewRange = useCallback(
    async (startRange?: Date, endRange?: Date) => {
      setSubmitting(true)

      if (shouldRedirectToRouting) navigateToHome()

      await changeCalendarRange(startRange, endRange)

      setSubmitting(false)

      onClose()
    },
    [changeCalendarRange, navigateToHome, onClose, shouldRedirectToRouting],
  )

  const onConfirm = useCallback(() => {
    const { start, end } = calendarStateRef.current
    applyNewRange(start, end)
  }, [applyNewRange])

  const handleOnChangeCalendarRange = useCallback(
    (_, nextInterval: [Date?, Date?], type: CalendarClickType) => {
      const [start, end] = nextInterval

      setCalendarState({ start, end, count: getDaysCount(start, end) })

      if (type === 'dblclick') {
        const targetDate = end ?? start
        applyNewRange(targetDate)
      }
    },
    [setCalendarState, applyNewRange],
  )

  const setCalendarToToday = useCallback(() => {
    setCalendarState({ end: today, start: today, count: 1 })
  }, [today])

  const handleOnChangeGpsOnlyDay = useCallback(
    (start: Date) => {
      setCalendarState({ start, end: start, count: 1 })
    },
    [setCalendarState],
  )

  return {
    onConfirm,
    submitting,
    calendarState,
    setCalendarToToday,
    shouldRedirectToRouting,
    setSingleDate: handleOnChangeGpsOnlyDay,
    setCalendarState: handleOnChangeCalendarRange,
  } as const
}
