import { ListHeaderFilterInput, ListHeaderFilterContainer } from '@/components/List'

import { useTexts } from '../../useTexts'
import { useFilter } from './hooks/useFilter'

export function Filter() {
  const { query, setQuery, editing, noEntries } = useFilter()

  const texts = useTexts()

  const disabled = editing || noEntries

  return (
    <ListHeaderFilterContainer>
      <ListHeaderFilterInput
        placeholder={texts.searchPlaceholder}
        disabled={disabled}
        setQuery={setQuery}
        testId="list-header-filter-input"
        query={query}
      />
    </ListHeaderFilterContainer>
  )
}
