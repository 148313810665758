import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

type DepotFeatureMetadata = {
  mode: 'on' | 'off' | 'label'
  type: 'depot'
  hasViolations: boolean
  ordersCount: number
  ordersWithViolationsCount: number
}

export const setDepotFeatureMetadata = <Field extends keyof DepotFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
  value: DepotFeatureMetadata[Field],
) => {
  target.set(field, value)
}

export const getDepotFeatureMetadata = <Field extends keyof DepotFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
): DepotFeatureMetadata[Field] | undefined => {
  return target.get(field)
}

export const removeDepotFeatureMetadata = <Field extends keyof DepotFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
) => {
  target.unset(field)
}
