import { NavigoContainer } from '../../elements/NavigoContainer'

import { useRouteNavigoMachine } from './hooks/useRouteNavigoMachine'

import { RoutesNavigator } from './components/RoutesNavigator'
import { Overview } from './views/Overview'
import { MapStyle } from './views/MapStyle'

export function Routes() {
  const { currentState, data, actions } = useRouteNavigoMachine()

  switch (currentState) {
    case 'mapStyle':
      return (
        <NavigoContainer data-testid="navigo-route" data-trackid="navigo-route">
          <RoutesNavigator />

          <MapStyle reset={actions.reset} />
        </NavigoContainer>
      )

    case 'overview':
    default:
      return (
        <NavigoContainer data-testid="navigo-route" data-trackid="navigo-route">
          <RoutesNavigator />

          <Overview
            expanded={data.expanded}
            goToStyle={actions.goToMapStyle}
            toggleExpanded={actions.toggleExpanded}
          />
        </NavigoContainer>
      )
  }
}
