import type { PublicProps as Props } from './typings'

import { Field as FinalFormField, FieldRenderProps } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import { DeviceIconListField } from './DeviceIconListField'

export function DeviceIconListFieldWrapper(props: Props) {
  const { name, ...rest } = props

  return (
    <FinalFormField
      {...rest}
      name={name}
      isEqual={isDeepEqual}
      render={(formProps: FieldRenderProps<any>) => {
        return <DeviceIconListField {...props} formProps={formProps} />
      }}
    />
  )
}
