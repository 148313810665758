import { intl } from '@/intl'

export const getOrderGridText = () =>
  intl.translate({
    id: 'app.header.orderGrid',
  })

export const getSchedulerText = () =>
  intl.translate({
    id: 'app.header.scheduler',
  })

export const getOrdersCounterText = (count: number) =>
  intl.translate({ id: 'global.counter.orders', values: { count } })

export const getSearchText = () => intl.translate({ id: 'global.search' })

export const disabledForBeta = () => intl.translate({ id: 'global.disabledForBeta' })
