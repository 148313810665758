import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'
import { Trash } from '@/icons'

import { TransactionInProgressTooltip } from '@/components/smartUtils/plan/TransactionInProgressTooltip'

import * as Texts from '../../intl'

import { Props as BaseProps } from './typings'

export interface Props extends BaseProps {
  onShowConfirmPanel: () => void
}

export default function BulkViewActions(props: Props) {
  const { onShowConfirmPanel } = props

  return (
    <SecondaryColumnHeaderActionsRow>
      <TransactionInProgressTooltip
        subtitle={Texts.getTransactionInProgressSubtitle()}
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={Texts.getDeleteText()}
            icon={<Trash />}
            disabled={preventEditing}
            onClick={onShowConfirmPanel}
            testid="delete-simulations"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
