import type { ImportWizardCookieData } from '../types'
import { addMinutes } from 'date-fns'

const cookieName = 'importWizard'
const domain = '.workwave.com'

export function getImportWizardCookie() {
  const value = document.cookie?.split('; ').find(row => row.startsWith(`${cookieName}=`))

  if (!value) return
  return value.split('=')[1]
}

// To delete a cookie, you can simply provide an expiration date that occurred in the past.
// When the browser sees that the cookie has expired, it will delete the cookie automatically.
export function deleteImportWizardCookie() {
  const date = new Date()
  // -1 means yesterday
  const daysCount = -1
  date.setTime(date.getTime() + daysCount * 24 * 60 * 60 * 1000)

  const cookieValue = ''
  document.cookie = `${cookieName}=${cookieValue}; domain=${domain}; expires=${date.toUTCString()}; path=/${
    globalThis.location.protocol === 'https:' ? '; Secure' : ''
  }`
}

export function updateImportWizardCookie(state: ImportWizardCookieData) {
  const cookieContent = btoa(JSON.stringify(state))

  // 30 minutes expiration
  const expiration = addMinutes(new Date(), 300).toUTCString()

  document.cookie = `${cookieName}=${cookieContent}; Domain=workwave.com; expires=${expiration}; path=/${
    globalThis.location.protocol === 'https:' ? '; Secure' : ''
  }`
}

export function createImportWizardCookie(base64EncodedContent: string) {
  // 30 minutes expiration
  const expiration = addMinutes(new Date(), 300).toUTCString()

  document.cookie = `${cookieName}=${base64EncodedContent}; Domain=workwave.com; expires=${expiration}; path=/${
    globalThis.location.protocol === 'https:' ? '; Secure' : ''
  }`
}
