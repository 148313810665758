import type { SingleViewActionsProps as Props } from '../../../typings'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderStepIdsInRange, selectOrderSteps } from '@/features/domain/order'
import { selectVehiclesByVehicleId, selectVehicles } from '@/features/domain/vehicle'
import { selectUserConfiguration } from '@/features/domain/user'
import { useCanAddNewOrder, useIsSimulation, useOrdersLimit } from '@/hooks'
import { timeUtils } from '@/server-data'

import * as OrderTexts from '../../../../../intl'

import { useTexts } from './useTexts'
import { useCanUnassign } from './useCanUnassign'

export function useSingleViewData(props: Props) {
  const { order, tab, onSelectPaired, isOrderAssignedToFutureRoute } = props
  const {
    orderStep: { type },
  } = order

  const canAddNewOrder = useCanAddNewOrder()
  const isSimulation = useIsSimulation()
  const ordersLimit = useOrdersLimit()
  const texts = useTexts()

  const orderStepIdsInRange = useSelector(selectOrderStepIdsInRange)
  const extOrderSteps = useSelector(selectOrderSteps)
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const vehicles = useSelector(selectVehicles)
  const userConfig = useSelector(selectUserConfiguration)

  const isExecutionEventsTab = tab === 1
  const hasPaired = order.type === 'pd'
  const routeApproved = !order.isUnassigned && order.isRouteApproved

  const vehicle = order.hasVehicle ? vehicles[vehiclesByVehicleId[order.vehicleId]] : undefined
  const vehicleTracked = !!vehicle ? vehicle.hasRoutingLicense && vehicle.vehicle.tracked : false

  const orderName = order.order.name
  const isPairedInRange = hasPaired && orderStepIdsInRange[order.pairedId] === true
  const pairedOrderStep = hasPaired ? extOrderSteps[order.pairedId] : undefined
  const vehicleName = pairedOrderStep?.hasVehicle || false ? pairedOrderStep.vehicleName : ''
  const date =
    !pairedOrderStep?.isUnassigned && pairedOrderStep?.dateAsString
      ? timeUtils.formatDate(userConfig)(pairedOrderStep.dateAsString)
      : ''

  const goToTooltipTitle = OrderTexts.getPairedOutOfRangeTitle(type, orderName)
  const goToTooltipSubtitle = pairedOrderStep
    ? OrderTexts.getPairedOutOfRangeSubtitle(type, vehicleName, date)
    : undefined

  const canEditExecutionEvents =
    order.hasVehicle && routeApproved && !isOrderAssignedToFutureRoute && vehicleTracked

  const onGoToPaired = useCallback(
    () => order?.type === 'pd' && onSelectPaired(),
    [order, onSelectPaired],
  )

  const editTitle = isExecutionEventsTab ? texts.editBtnTitle(vehicleTracked) : ''

  const duplicateDisabledTooltipTitle = texts.duplicateDisabledTooltipTitle(isSimulation)
  const duplicateDisabledTooltipSubtitle = texts.duplicateDisabledTooltipSubtitle(
    isSimulation,
    ordersLimit,
  )

  const canUnassign = useCanUnassign(order)

  return {
    canUnassign,
    canUnassignTitle: texts.canUnassignTooltipTitle,

    duplicateDisabledTooltipSubtitle,
    duplicateDisabledTooltipTitle,
    canEditExecutionEvents,
    isExecutionEventsTab,
    goToTooltipSubtitle,
    goToTooltipTitle,
    isPairedInRange,
    canAddNewOrder,
    isSimulation,
    onGoToPaired,
    editTitle,
    hasPaired,
  }
}
