import styled from 'styled-components'

const ItemRendererAsideLabelAccented = styled.p`
  color: ${p => p.theme.colors.$silver};
  font-size: ${p => p.theme.fonts.$p3};
  margin-top: 0;
  margin-bottom: 0;
  user-select: none;
  text-transform: uppercase;
`

ItemRendererAsideLabelAccented.displayName = 'ItemRendererAsideLabelAccented'
export default ItemRendererAsideLabelAccented
