import { useCallback } from 'react'

import { useTexts } from './useTexts'

export function useComposeFieldsWithError() {
  const texts = useTexts()

  return useCallback(
    (errors: uui.domain.ui.forms.RmVehicleFormValidationValues) => {
      const { rm = {} } = errors

      const entries = Object.entries(rm)
      const rmErrors: string[] = []
      // this will filter the keys for which value is undefined
      for (const [key, value] of entries) {
        if (value) {
          rmErrors.push(
            texts.rmField(key as keyof uui.domain.ui.forms.RouteManagerVehicleDataValidation),
          )
        }
      }

      return rmErrors
    },
    [texts],
  )
}
