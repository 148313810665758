import { useCallback } from 'react'

import { useIsUnmounted, useNotification } from '@/hooks'
import { deletePlaces } from '@/features/domain/place'
import { useEditingStateWithCtx } from '@/atoms'
import { useAppDispatch } from '@/store'

export function useActions(
  place: uui.domain.client.gps.wwgps.Place,
  setView: (view: 'default' | 'delete') => void,
) {
  const toast = useNotification()
  const isUnmounted = useIsUnmounted()
  const { setEditing } = useEditingStateWithCtx('place')
  const dispatch = useAppDispatch()

  const onSwitchToDeletePanel = useCallback(() => setView('delete'), [setView])
  const onSwitchToDefaultPanel = useCallback(() => setView('default'), [setView])
  const onEdit = useCallback(() => setEditing([place.id]), [setEditing, place])

  const onDelete = useCallback(async () => {
    try {
      const result = await dispatch(deletePlaces([place.id]))

      if (deletePlaces.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      if (!isUnmounted()) {
        setView('default')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [setView, dispatch, toast, place, isUnmounted])

  return {
    onSwitchToDeletePanel,
    onSwitchToDefaultPanel,
    onDelete,
    onEdit,
  }
}
