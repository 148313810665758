import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Bell(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M25 6c-1.64453 0-3 1.35547-3 3v1.36328c-4.82813 1.1875-8 5.25-8 10.69922v7.80078c0 2.17188-1.28125 4.6328-2.01953 5.86328l-1.8125 2.71875c-.20313.3047-.22656.7032-.05078 1.0274.1757.3242.5156.52738.8828.52738h28c.3672 0 .707-.20312.8828-.52733.1758-.32422.1523-.71875-.0508-1.02735l-1.7852-2.6797C36.6679 32.47267 36 30.58594 36 29v-7.80078c0-5.51563-3.17188-9.6289-8-10.82813V9c0-1.64453-1.35547-3-3-3zm0 2c.5547 0 1 .4453 1 1v1.04688C25.67187 10.01952 25.33984 10 25 10c-.33984 0-.67188.01953-1 .04688V9c0-.5547.4453-1 1-1zM3.48047 9.47656C1.25 13.10156 0 17.41796 0 22c0 4.58203 1.25 8.89844 3.48047 12.52344l1.70703-1.04688C3.15234 30.16796 2 26.21876 2 22c0-4.21875 1.15234-8.16797 3.1875-11.47656zm43.03906 0l-1.70703 1.04688C46.84766 13.83204 48 17.78124 48 22c0 4.21875-1.15234 8.16797-3.1875 11.47656l1.70703 1.04688C48.75 30.89844 50 26.58204 50 22c0-4.58203-1.25-8.89844-3.48047-12.52344zM7.8164 12.14063C5.9961 15.08203 5 18.35546 5 22c0 3.67188 1.1289 7.04688 2.8086 9.84766l1.71484-1.03125C8.0078 28.2852 7 25.2618 7 22c0-3.28906.8711-6.14844 2.51563-8.8086zm34.3672 0l-1.69922 1.05078C42.1289 15.8516 43 18.711 43 22c0 3.26172-1.0078 6.28516-2.52734 8.8164l1.71875 1.03126C43.8711 29.04686 45 25.67186 45 22c0-3.64453-.9961-6.91797-2.8164-9.85938zM20.42187 41C21.1992 42.76172 22.9531 44 25 44c2.04687 0 3.80077-1.23828 4.57812-3z" />
      </svg>
    </IconContainer>
  )
}
