import TextButton from '../TextButton'
import Triangle from './Triangle'

interface Props {
  collapsed: boolean
  onToggle: () => void
  text: string
}

export function CollapseButton(props: Props) {
  const { text, collapsed, onToggle } = props

  return (
    <TextButton onClick={onToggle} style={{ display: 'flex', alignItems: 'center', marginLeft: 6 }}>
      {text}
      <Triangle style={{ transform: collapsed ? 'rotate(180deg)' : 'rotate(0)' }} />
    </TextButton>
  )
}
