import type { ListValue, ItemsExtra, ModifierKeys } from './typings'
import type { List } from '@/forms-legacy'

export function SearchableListItems<T extends ListValue>(
  props: List.RenderScrollableListAsSFCProps<T, string, ItemsExtra<T>>,
) {
  if (!props.extra) return null

  const {
    values,
    selectedIds,
    highlightedIndex,
    onChange,
    onHover,
    extra: { ListItem, mode, selectionLimit, disabled, selectionRequired = false },
  } = props

  return (
    <>
      {values.map((value: T, index: number) => {
        const { id: valueId } = value

        const selected: boolean = selectedIds.includes(valueId)
        const selectionSize: number = selectedIds.length

        const cannotBeDeselected: boolean = selectionSize === 1 && selectionRequired && selected

        const readonly: boolean =
          (selectionSize >= selectionLimit && !selected) || cannotBeDeselected

        const onChangeHandler = (selection: T[], modifiers?: ModifierKeys) => {
          onChange(selection, modifiers)
        }

        return (
          <ListItem
            key={valueId}
            value={value}
            index={index}
            collection={values}
            readonly={readonly}
            selected={selected}
            mode={mode}
            isHighlighted={highlightedIndex === index}
            disabled={disabled}
            onChange={onChangeHandler}
            onHover={onHover}
          />
        )
      })}
    </>
  )
}
