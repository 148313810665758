import { useSelector } from 'react-redux'

import { convertMilesToKm } from '@/local/server-data/utils/smartUtils/conversion'
import { selectUserConfiguration } from '@/features/domain/user'
import {
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
  ReadonlyLabel,
} from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  maxSpeed: number | null
}

export function MaximumSpeed(props: Props) {
  const { maxSpeed } = props

  const userConfiguration = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const isMetric = userConfiguration.distanceFormat === 'METRIC'
  const title = maxSpeed ? texts.maximumSpeedThreshold : texts.maximumSpeedThresholdDisabled

  return (
    <ReadonlyBlock half>
      <ReadonlyLabel empty={!maxSpeed}>{title}</ReadonlyLabel>
      {maxSpeed && (
        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>
              {isMetric ? `${convertMilesToKm(maxSpeed).toFixed()} km/h` : `${maxSpeed} mph`}
            </ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      )}
    </ReadonlyBlock>
  )
}
