import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    doneStatus: translate({ id: 'order.status.done' }),
    rescheduleStatus: translate({ id: 'order.status.reschedule' }),
  }))

  return api
}
