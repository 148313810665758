import type { ReactNode } from 'react'

import { makeStyles } from '@mui/styles'
import { Text } from '@/local/components'

interface Props {
  title: string
  children?: ReactNode
}

const useStyles = makeStyles({
  container: { marginBottom: 10 },
})

export function Entry(props: Props) {
  const { title, children } = props
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Text size="$xl" weight="$semiBold">
        {title}
      </Text>
      <hr />
      {children}
    </div>
  )
}
