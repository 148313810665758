import type { Props } from './ByCalendar'
import type { IntervalCalendarPublicProps as CalendarProps } from '@/components/Calendar'

import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  startOfMonth,
  eachDayOfInterval,
  parse,
  subDays,
  max,
  format,
  isSameDay,
  addYears,
  subYears,
} from 'date-fns/esm'

import { selectUserConfiguration } from '@/features/domain/user'
import { selectTerritoryLicensingLimits } from '@/features/domain/territory'

const now = new Date()

export const useByCalendarProps = ({ date, onChange }: Props): CalendarProps => {
  const { planType, today } = useSelector(selectUserConfiguration)
  const { horizonView } = useSelector(selectTerritoryLicensingLimits)
  const todayDate = parse(today, 'yyyyMMdd', now)

  const selectionEnd = useMemo<Date>(() => parse(date, 'yyyyMMdd', now), [date])

  const handleOnChange = useCallback(
    (date: Date | null) => {
      if (date) {
        onChange([date])
      }
    },
    [onChange],
  )

  const isSimulation = planType === 'simulation'

  return useMemo(() => {
    const firstDayDisabled = isSimulation ? subYears(todayDate, 10) : startOfMonth(todayDate)
    const lastDayDisabled = isSimulation
      ? addYears(todayDate, 10)
      : max([firstDayDisabled, subDays(todayDate, 1)])

    const disableInterval =
      isSimulation || isSameDay(todayDate, firstDayDisabled)
        ? []
        : eachDayOfInterval({
            start: firstDayDisabled,
            end: lastDayDisabled,
          })

    const disabledDays = disableInterval.reduce((acc, date) => {
      const key = format(date, 'yyyyMMdd')
      acc[key] = 1
      return acc
    }, {})

    const minDate = format(firstDayDisabled, 'yyyyMMdd')
    const maxDate = format(addYears(todayDate, isSimulation ? 10 : 2), 'yyyyMMdd')

    return {
      layout: 'interval',
      selectionStart: todayDate,
      selectionEnd: selectionEnd,
      today,
      months: 1,
      maxIntervalLength: isSimulation ? 365 * 10 : horizonView - 1,
      selection: selectionEnd,
      onChange: handleOnChange,
      disabledDays,
      minDate,
      maxDate,
      fixedRangeStart: true,
    }
  }, [handleOnChange, today, selectionEnd, todayDate, isSimulation, horizonView])
}
