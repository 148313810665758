import { useEffect, useCallback } from 'react'

import {
  togglePoDMarker,
  useActivePodMarker,
  setExecutionEventMarkers,
  useExecutionEventMarkers as useInitExecutionEventMarkers,
} from '@/map'

export function useExecutionEventMarkers(order: uui.domain.client.rm.ExtendedOrderStep) {
  useInitExecutionEventMarkers(order)

  const activePodMarker = useActivePodMarker()

  const onSetPodOnMap = useCallback((uuid: string, latLng?: uui.domain.LatLng) => {
    if (latLng) {
      togglePoDMarker(uuid, latLng)
    }
  }, [])

  useEffect(() => {
    setExecutionEventMarkers({ visible: true })

    return () => {
      setExecutionEventMarkers({ visible: false })
    }
  }, [])

  return { activePodMarker, onSetPodOnMap }
}
