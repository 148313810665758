import type { ReactElement, CSSProperties } from 'react'

import { useCallback } from 'react'

import { ColumnHeader, TextButton, ClearSelectionIcon, FlexRow } from '@/forms-legacy'
import { resetCrudSelection } from '@/atoms'
import { Left } from '@/icons'

import { ExceptionSlider } from './ExceptionSlider'

interface Props {
  children: ReactElement
  style?: CSSProperties
  switchToListExceptions: () => void
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function ExceptionHeader(props: Props) {
  const { children, style = {}, switchToListExceptions, unifiedVehicle } = props

  const onClearSelection = useCallback(() => {
    resetCrudSelection('unifiedVehicles')
  }, [])

  return (
    <ColumnHeader style={style} spaceChildren={!!children}>
      <FlexRow
        justifyContent="space-between"
        style={{ marginTop: 10, paddingRight: 24, height: 42 }}
      >
        <ExceptionSlider
          switchToListExceptions={switchToListExceptions}
          unifiedVehicle={unifiedVehicle}
        />
        <TextButton
          onClick={onClearSelection}
          data-testid="secondary-column__close-btn"
          data-trackid="secondary-column__close-btn"
        >
          <ClearSelectionIcon>
            <Left />
          </ClearSelectionIcon>
        </TextButton>
      </FlexRow>
      {!!children && <div style={{ paddingBottom: 16 }}>{children}</div>}
    </ColumnHeader>
  )
}
