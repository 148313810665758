interface ProcessedSelection<S> {
  values: S[]
  force: boolean | undefined
}

type ItemResolver<T, S> = (item: T) => S

export interface ModifierKeys {
  altKey?: boolean
  ctrlKey?: boolean
  metaKey?: boolean
  shiftKey?: boolean
}

export const processListSelection = <T, S>(
  values: T[],
  selectedIds: S[],
  selectedItems: T[],
  itemResolver: ItemResolver<T, S>,
  modifiers: ModifierKeys = {},
): ProcessedSelection<S> => {
  const { shiftKey = false, metaKey = false, ctrlKey = false, altKey = false } = modifiers

  const noModifiers: boolean = !shiftKey && !metaKey && !ctrlKey && !altKey
  const ids: S[] = selectedItems.map((item: T) => itemResolver(item))

  if (ids.length > 1 || noModifiers || selectedIds.length === 0) {
    return { values: ids, force: undefined }
  }

  if (ctrlKey || metaKey) {
    const finalIds = ids.reduce(
      (acc, tmpId) => {
        if (selectedIds.includes(tmpId)) {
          return acc.filter(id => id !== tmpId)
        }

        acc.push(tmpId)
        return acc
      },
      [...selectedIds],
    )

    return { values: finalIds, force: true }
  }

  if (altKey && selectedIds.includes(ids[0])) {
    const finalIds = selectedIds.filter(id => id !== ids[0])
    return { values: finalIds, force: true }
  }

  if (shiftKey) {
    const newIdIndex: number = values.findIndex((item: T) => itemResolver(item) === ids[0])
    const lastIndex: number = values.findIndex(
      (item: T) => itemResolver(item) === selectedIds[selectedIds.length - 1],
    )

    const start: number = newIdIndex >= lastIndex ? lastIndex : newIdIndex
    const end: number = newIdIndex >= lastIndex ? newIdIndex : lastIndex

    const rangeIds: S[] = values.slice(start, end + 1).map((item: T) => itemResolver(item))

    const finalIds = rangeIds.reduce(
      (acc, tmpId) => {
        if (!acc.includes(tmpId)) {
          acc.push(tmpId)
        }

        return acc
      },
      [...selectedIds],
    )

    return { values: finalIds, force: true }
  }

  return { values: selectedIds, force: true }
}
