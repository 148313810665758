import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    route: translate({ id: 'global.route' }),
    disabledForRange: (vehicleName: string) =>
      translate({ id: 'rm.contextual.route.vehicleTag.disabledForRange', values: { vehicleName } }),
  }))

  return api
}
