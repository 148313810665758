import { useState } from 'react'

import { Body } from './components/Body'
import { MapTesters } from './components/MapTesters'
import { ErrorTester } from './components/ErrorTester'
import { PolylineTester } from './components/PolylineTester'
import { DevPreferencesTester } from './components/DevPreferencesTester'
import { VirtualDevices } from './components/VirtualDevices'
import { RouteManagerVersionTester } from './components/RouteManagerVersionTester'
import { DynamicLoadsCfTester } from './components/DynamicLoadsCfTester'
import { TelematicsTesters } from './components/TelematicsTesters'

import { useOptions } from './hooks/useOptions'

export type Tab =
  | 'map'
  | 'scheduler'
  | 'devPreferences'
  | 'poly'
  | 'error'
  | 'version'
  | 'virtualDevices'
  | 'dynamicLoadsCf'
  | 'newScheduler'
  | 'telematics'

export function TesterModal() {
  const [tab, setTab] = useState<Tab>('devPreferences')
  const options = useOptions(tab)

  return (
    <Body options={options} setTab={setTab} tab={tab}>
      {tab === 'map' && <MapTesters />}
      {tab === 'poly' && <PolylineTester />}
      {tab === 'devPreferences' && <DevPreferencesTester />}
      {tab === 'error' && <ErrorTester />}
      {tab === 'version' && <RouteManagerVersionTester />}
      {tab === 'virtualDevices' && <VirtualDevices />}
      {tab === 'dynamicLoadsCf' && <DynamicLoadsCfTester />}
      {tab === 'telematics' && <TelematicsTesters />}
    </Body>
  )
}
