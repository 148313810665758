import { Stack } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'
import { ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useControllerActions } from '../../../hooks/useControllerActions'
import { useTexts } from '../../../useTexts'
import { Footer } from './Footer'

export function ConfigureFormatFooter() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const {
    close,
    data: { columnsState, customizeColumns, selectedOrders },
    status,
    invalid,
  } = useController()

  const bulk = selectedOrders.length > 1

  return (
    <Footer>
      {/* the empty div will let the stack to align to the right for the justify-content of the Footer */}
      <div />
      <Stack gap={1} direction="row">
        <ModalFooterButton testid="export-order-cancel-button" onClick={close}>
          {texts.cancelButtonTitle}
        </ModalFooterButton>
        {customizeColumns ? (
          <ModalFooterButton
            variant="contained"
            testid="export-order-go-to-column-selection"
            onClick={handlers.onNextButtonClick}
            loading={columnsState.status === 'loading'}
          >
            {texts.nextStepButton}
            <KeyboardArrowRight />
          </ModalFooterButton>
        ) : (
          <ModalFooterButton
            disabled={invalid || status !== 'ready'}
            onClick={handlers.exportOrderLegacy}
            variant="contained"
            loading={handlers.isLoading}
            testid="export-order-export-legacy-button"
          >
            {bulk
              ? texts.exportButtonTitleBulk(selectedOrders.length)
              : texts.exportButtonTitleSingle}
          </ModalFooterButton>
        )}
      </Stack>
    </Footer>
  )
}
