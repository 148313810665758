import OlMap from 'ol/Map'
import { singleClick } from 'ol/events/condition'

import { findLayer } from '../../layers/utils/findLayer'
import { subscribeToMouseSingleClick } from '../reactions/singleClick/subscribeToMouseSingleClick'
import { MouseInteraction } from '../MouseInteraction'
import { getInteraction, registerInteraction, setInteractionMetadata } from '../interactionMetadata'

export const registerMouseSingleClickInteraction = (map: OlMap) => {
  const uid = 'rm-mouse-single-click-interaction'

  if (getInteraction(uid)) return

  const mouseInteraction = new MouseInteraction(uid, {
    multi: false,
    condition: singleClick,
    stopPropagation: false,
    allowDuplicateEvents: true,
    subscribers: [subscribeToMouseSingleClick],
    disabledLayers: [
      findLayer(map, 'pin'),
      findLayer(map, 'executionEvents'),
      findLayer(map, 'draw'),
    ],
  })

  setInteractionMetadata(mouseInteraction, 'rmInteraction', true)
  setInteractionMetadata(mouseInteraction, 'uid', uid)
  registerInteraction(uid, mouseInteraction)

  map.addInteraction(mouseInteraction)
}
