import { useCallback, useEffect, useRef } from 'react'
import { useEditingStateWithCtx } from '@/atoms'

export function useEditing(routeId: string) {
  const { editing, setEditing, resetEditing } = useEditingStateWithCtx('route')

  const routeIdRef = useRef<string | undefined>()
  useEffect(() => {
    routeIdRef.current = routeId
  }, [routeId])

  const onEdit = useCallback(() => {
    if (!routeIdRef.current) return
    setEditing([routeIdRef.current])
  }, [setEditing])

  const onDiscard = useCallback(() => {
    resetEditing()
  }, [resetEditing])

  const clearEditingState = useCallback(() => {
    resetEditing()
  }, [resetEditing])

  return { editing, onEdit, onDiscard, clearEditingState }
}
