import { useSelector } from 'react-redux'

import {
  selectActiveOrdersCountByDate,
  selectOpsOrdersCountByDate,
} from '@/features/domain/history'

export function useDaysWithOrders(env: 'active' | 'ops' = 'active') {
  return useSelector(env === 'active' ? selectActiveOrdersCountByDate : selectOpsOrdersCountByDate)
}
