import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Edit(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M36.90625-.0625c-1.04688 0-2.07813.39063-2.875 1.1875l-.3125.3125c-.04688.03906-.08594.07813-.125.125l-.25.28125-15.5 15.46875c-.1211.1211-.20703.27344-.25.4375l-1.625 6c-.09375.35156.0078.72266.26563.97656.2617.2539.6367.34375.98437.2422l5.96875-1.625c.17578-.03907.33984-.125.46875-.25L39.125 7.625l.25-.25c.04688-.04688.08984-.10156.125-.15625l.3125-.3125c1.59766-1.59766 1.59375-4.1875 0-5.78125-.79688-.79688-1.85938-1.1875-2.90625-1.1875zM34.75 3.25l2.9375 2.9375L22.375 21.53125 19.4375 18.625l.03125-.125zM3 9c-1.65234 0-3 1.34766-3 3v26c0 1.65234 1.34766 3 3 3h44c1.65234 0 3-1.34766 3-3V12c0-1.65234-1.34766-3-3-3h-7.28125l-2 2H47c.55078 0 1 .44922 1 1v26c0 .55078-.44922 1-1 1H3c-.55078 0-1-.44922-1-1V12c0-.55078.44922-1 1-1h19.125l2-2z" />
      </svg>
    </IconContainer>
  )
}
