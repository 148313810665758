import { getMainSelection, setMainSelection } from '@/atoms'
import { fitMapToSelection } from '@/map'
import { selectOrderSteps } from '@/features/domain/order'
import { selectRoutes } from '@/features/domain/route'
import { store } from '@/store'

type Params = {
  extOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>
  selection: workwave.DeepReadonly<string[]>
  shiftKey: boolean
  routes: Record<string, uui.domain.client.rm.ExtendedRoute>
  key: 'left' | 'right'
}

export function navigateHorizontally(direction: 'left' | 'right', shiftKey: boolean) {
  const selectedOrderSteps = getMainSelection(true).orderSteps

  const state = store.getState()
  const extOrderSteps = selectOrderSteps(state)
  const routes = selectRoutes(state)

  const newSelectedStepId = getNextIdToSelect({
    extOrderSteps,
    selection: selectedOrderSteps,
    shiftKey,
    routes,
    key: direction,
  })

  if (!newSelectedStepId) return

  const newSelection = shiftKey
    ? Array.from(
        new Set(
          direction === 'left'
            ? [newSelectedStepId, ...selectedOrderSteps]
            : [...selectedOrderSteps, newSelectedStepId],
        ),
      )
    : [newSelectedStepId]

  setMainSelection('orderSteps', newSelection)
  fitMapToSelection({ preventIfVisible: true })
}

function getNextIdToSelect(params: Params) {
  const { selection, extOrderSteps, routes, key } = params
  const allOrderStepsToSameDepot = allSelectedOrdersReloadToSameDepot(selection, extOrderSteps)

  if (selection.length === 0) return

  const selectionStart = allOrderStepsToSameDepot ? 0 : key === 'left' ? 0 : selection.length - 1
  const extOrderStep = extOrderSteps[selection[selectionStart]]

  if (extOrderStep.isUnassigned) return

  const route = routes[extOrderStep.routeId]

  const index = route.route.orderStepsIds.findIndex(id => id === extOrderStep.id)

  if (index < 0) return

  switch (key) {
    case 'left': {
      if (index === 0) return
      if (allOrderStepsToSameDepot) return route.route.orderStepsIds[index]
      return route.route.orderStepsIds[index - 1]
    }

    case 'right': {
      if (index === route.route.orderStepsIds.length - 1) return
      if (allOrderStepsToSameDepot) return route.route.orderStepsIds[index]
      return route.route.orderStepsIds[index + 1]
    }
  }
}

function allSelectedOrdersReloadToSameDepot(
  selection: workwave.DeepReadonly<string[]>,
  extOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
) {
  if (selection.length < 2) return false

  const orderStep = extOrderSteps[selection[0]]
  const depotId = orderStep.atDepot ? orderStep.depotId : undefined

  if (!depotId) return false

  return selection.every(id => {
    const os = extOrderSteps[id]
    return os.atDepot ? os.depotId === depotId : false
  })
}
