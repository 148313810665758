import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    emailLabel: translate({
      id: 'exportPlacesModal.fields.email.label',
    }),
    emailInvalid: translate({
      id: 'exportPlacesModal.fields.email.error.format',
    }),
    emailEmpty: translate({
      id: 'exportPlacesModal.fields.email.error.empty',
    }),
    title: translate({
      id: 'exportPlacesModal.header.title',
    }),
    subtitle: translate({
      id: 'exportPlacesModal.header.subtitle',
    }),
    success: translate({
      id: 'exportPlacesModal.result.success',
    }),
    failure: translate({
      id: 'exportPlacesModal.result.failure',
    }),
    send: translate({
      id: 'exportPlacesModal.footer.send',
    }),
    cancel: translate({
      id: 'exportPlacesModal.footer.cancel',
    }),
  }))

  return api
}
