import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../renderingQueue'
import { getMap } from '../../atoms/map/utils/getMap'
import { setLayerMetadata } from '../layerMetadata'
import { findLayer } from '../utils/findLayer'

import { createRegionFeature } from './utils/createRegionFeature'

const nonblockingRender = createNonBlockingRenderByTime()

/**
 * Initialize the Features for all the existent Regions
 */
export async function initializeRegionFeatures(
  markers: uui.domain.ui.map.markers.MapMarkers['region'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['region'],
  selection: Set<string>,
) {
  const map = getMap()
  const layer = findLayer(map, 'region')

  const features: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const marker of Object.values(markers)) {
    await nonblockingRender.next()

    const selected = selection.has(marker.id)
    features.push(createRegionFeature(marker, mapStyles, selected))
  }

  // add all new features to the layer
  layer.getSource()?.addFeatures(features)

  // keep track of selected items in the current layer
  setLayerMetadata(layer, 'selection', new Set(selection))
}
