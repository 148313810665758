import type { Props } from '../../typings'

import { Spinner, SquaredDepot, Pin, Search } from '@/icons'

import IconWrapper from './IconWrapper'

const Icon = (props: Props) => {
  const { loading, collapsed, isConnectedToPlace } = props
  const preset = loading
    ? 'spinner'
    : isConnectedToPlace
    ? 'squaredDepot'
    : collapsed
    ? 'pin'
    : 'search'

  const icon = loading ? (
    <Spinner />
  ) : isConnectedToPlace ? (
    <SquaredDepot />
  ) : collapsed ? (
    <Pin />
  ) : (
    <Search />
  )

  return (
    <IconWrapper iconPreset={preset} style={{ height: 14 }}>
      {icon}
    </IconWrapper>
  )
}

export default Icon
