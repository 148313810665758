import { Shopify } from './components/Shopify'

interface Props {
  integrationData: uui.domain.client.rm.OrderIntegrationData
}

export function IntegrationData(props: Props) {
  const { integrationData } = props

  switch (integrationData.integrationType) {
    case 'SHOPIFY':
      return <Shopify integrationData={integrationData} />

    default:
      return null
  }
}
