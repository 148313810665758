import type { PlaceFormValidation, PlaceFormValues } from '../types'

import { locationHasError } from '@/server-data'
import { useTexts } from './useTexts'

const validateForm =
  (texts: ReturnType<typeof useTexts>) =>
  (place: PlaceFormValues): PlaceFormValidation => {
    const validation: PlaceFormValidation = {}

    const { name, location } = place

    if (name.length < 1) {
      validation.name = texts.missingName
    }

    if (!location || locationHasError(location)) {
      validation.location = texts.invalidLocation
    }

    return validation
  }

export const useValidateForm = () => {
  const texts = useTexts()
  return validateForm(texts)
}
