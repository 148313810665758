import { MapStyle } from '@/icons'

import { NavigoIconButton } from '../../../../elements/NavigoIconButton'
import { CloseButton } from '../../../../elements/CloseButton'
import { useNavigoView } from '../../hooks/useNavigoView'
import { useDepotsNavigator } from '../../hooks/useDepotsNavigator'
import { EntityRelations } from './components/EntityRelations'
import { Actions } from './components/Actions'
import { Stats } from './components/Stats'

import { useTexts } from './useTexts'

export function Overview() {
  const texts = useTexts()
  const { currentDepots } = useDepotsNavigator()
  const {
    navigate: { goToMapStyle },
  } = useNavigoView()

  return (
    <>
      <Stats />
      <Actions />
      <NavigoIconButton
        text={currentDepots.length > 1 ? texts.styleAll : texts.style}
        Icon={<MapStyle size={20} color="$nightRider" />}
        onClick={goToMapStyle}
      />
      <EntityRelations />
      <CloseButton />
    </>
  )
}
