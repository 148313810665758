import { Entry } from '../Entry'

export function Entry20240617() {
  return (
    <Entry title="17/06/2024 - v3.7.5">
      Link to the{' '}
      <a
        href="https://workwave.atlassian.net/projects/RM/versions/15943/tab/release-report-all-issues"
        target="_blank"
        rel="noreferrer"
      >
        Jira Release
      </a>
    </Entry>
  )
}
