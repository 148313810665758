import type { RecurrencesMap } from '../typings'

import { useCallback } from 'react'

import { DateCalendar } from './DateCalendar'
import { AnnualCalendar } from './AnnualCalendar'
import { MonthlyCalendar } from './MonthlyCalendar'
import { WeeklyCalendar } from './WeeklyCalendar'

type Props = {
  recurrenceType: uui.domain.rm.RecurrenceTypes
  recurrenceMap: RecurrencesMap
  onChange: (type: uui.domain.rm.RecurrenceTypes, date: Date) => void
  date: Date | null
}

export function CalendarPicker(props: Props) {
  const { onChange, date } = props

  const handleOnChange = useCallback(
    (type: uui.domain.rm.RecurrenceTypes) => (date: Date) => {
      onChange(type, date)
    },
    [onChange],
  )

  switch (props.recurrenceType) {
    case 'DATE':
      return (
        <DateCalendar
          date={date}
          onChange={handleOnChange('DATE')}
          recurrenceMap={props.recurrenceMap}
        />
      )

    case 'ANNUAL':
      return (
        <AnnualCalendar
          recurrenceMap={props.recurrenceMap}
          date={date}
          onChange={handleOnChange('ANNUAL')}
        />
      )

    case 'MONTHLY':
      return (
        <MonthlyCalendar
          recurrenceMap={props.recurrenceMap}
          date={date}
          onChange={handleOnChange('MONTHLY')}
        />
      )

    case 'WEEKLY':
      return (
        <WeeklyCalendar
          recurrenceMap={props.recurrenceMap}
          date={date}
          onChange={handleOnChange('WEEKLY')}
        />
      )

    case 'any':
    default:
      return null
  }
}
