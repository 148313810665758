import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    companyFromNameMaxLength: (maxCharacters: number) =>
      translate({
        id: 'sms4.settings.preferencesPage.fields.companyFromName.error.maxLength',
        values: { maxCharacters },
      }),
    companyFromNameRequired: translate({
      id: 'sms4.settings.preferencesPage.fields.companyFromName.error.required',
    }),
  }))

  return api
}
