import { useSelector } from 'react-redux'
import { type GridCellParams } from '@mui/x-data-grid-pro'
import { differenceInSeconds, parseISO } from 'date-fns/esm'

import { getRelativeTimeDifferenceLabel } from '@/utils'
import { selectUserConfiguration } from '@/features/domain/user'
import { timeUtils } from '@/server-data'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../../../../../hooks/useTexts'
import { type TableRow } from '../../../types'

export function LastPositionCell(params: GridCellParams<number, TableRow>) {
  const texts = useTexts()
  const userConfig = useSelector(selectUserConfiguration)

  if (params?.value === undefined || params?.value === -1 || !params.row.lastActivity.$ISODate) {
    return (
      <Tooltip title={texts.noActivityYet} placement="top">
        -
      </Tooltip>
    )
  }

  const lastPositionDateUtc = parseISO(`${params.row.lastActivity.$ISODate}+00:00`)
  const lastPositionDateInTimezone = new Date(params.value)
  const lastPositionFormattedDate = timeUtils.formatDateTime(userConfig)(lastPositionDateInTimezone)
  const secondsSinceLastPositionUpdate = differenceInSeconds(new Date(), lastPositionDateUtc)

  return (
    <Tooltip placement="top" title={lastPositionFormattedDate}>
      {getRelativeTimeDifferenceLabel(secondsSinceLastPositionUpdate) ?? lastPositionFormattedDate}
    </Tooltip>
  )
}
