import type { BreadcrumbChip } from '../../../components/Navigator/typings'
import type { EntitiesToShow, GpsInfoWithColor, BreadcrumbData } from '../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectVehicles, selectVehiclesByDeviceId } from '@/features/domain/vehicle'
import { selectDevicesAggregateInfo } from '@/features/domain/device'
import { selectGpsTrackingProvider } from '@/features/domain/user'

interface NavigatorData {
  pointBreadcrumbs: GpsInfoWithColor[]
  pointChips: BreadcrumbChip[]

  stopBreadcrumbs: GpsInfoWithColor[]
  stopChips: BreadcrumbChip[]
}

const emptyData: NavigatorData = {
  pointBreadcrumbs: [],
  pointChips: [],

  stopBreadcrumbs: [],
  stopChips: [],
}

export function useBreadcrumbsNavigatorData(
  breadcrumbData: BreadcrumbData,
  entitiesToShow: EntitiesToShow,
  breadcrumbId: string,
) {
  const devicesAggregateInfos = useSelector(selectDevicesAggregateInfo)
  const vehiclesByDeviceId = useSelector(selectVehiclesByDeviceId)
  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const vehicles = useSelector(selectVehicles)

  const color = useMemo(() => {
    const deviceId = breadcrumbId.split('@')[0]
    const uv = vehicles[vehiclesByDeviceId[deviceId]]

    if (uv?.hasRoutingLicense) return uv.vehicle.color

    if (trackingProvider === 'telematics') return '#000000'

    return devicesAggregateInfos[deviceId].device.iconColor1
  }, [devicesAggregateInfos, vehiclesByDeviceId, trackingProvider, vehicles, breadcrumbId])

  const navigatorData = useMemo(() => {
    if (!breadcrumbData.breadcrumb.fetched) return emptyData

    const { points } = breadcrumbData.breadcrumb

    const navigatorData: NavigatorData = {
      pointBreadcrumbs: [],
      pointChips: [],
      stopBreadcrumbs: [],
      stopChips: [],
    }

    for (const point of points) {
      const source: GpsInfoWithColor = { ...point, color }
      const chip: BreadcrumbChip = {
        source: source,
        id: point.breadcrumbId,
        type: 'breadcrumb',
      }

      navigatorData.pointBreadcrumbs.push(source)
      navigatorData.pointChips.push(chip)

      if (source.status !== 'moving') {
        navigatorData.stopBreadcrumbs.push(source)
        navigatorData.stopChips.push(chip)
      }
    }

    return navigatorData
  }, [breadcrumbData, color])

  const stopIsSelected = breadcrumbId in breadcrumbData.indexesByStopId

  return entitiesToShow === 'stops' && stopIsSelected
    ? {
        page: breadcrumbData.indexesByStopId[breadcrumbId],
        points: navigatorData.stopBreadcrumbs,
        chips: navigatorData.stopChips,
      }
    : {
        page: breadcrumbData.indexesByBreadcrumbId[breadcrumbId],
        points: navigatorData.pointBreadcrumbs,
        chips: navigatorData.pointChips,
      }
}
