import { ModalFooterButton } from '@/components/Modal'

import { useControllerActions } from '../../../hooks/useControllerActions'
import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function FailureFooter() {
  const texts = useTexts()
  const { close } = useController()
  const { goToComposeMessage } = useControllerActions()

  return (
    <>
      <ModalFooterButton
        variant="contained"
        onClick={goToComposeMessage}
        data-testid="send-message-to-driver-modal-failure-try-again-button"
      >
        {texts.tryAgain}
      </ModalFooterButton>

      <ModalFooterButton onClick={close}>{texts.cancel}</ModalFooterButton>
    </>
  )
}
