import type {
  NavigoContext,
  NavigoEvents,
  NavigoStates,
  UpdateActionPermissionsEvent,
} from './types'

import { createMachine } from 'xstate'

export const navigoDriversMachine = createMachine<NavigoContext, NavigoEvents, NavigoStates>(
  {
    predictableActionArguments: true,
    initial: 'overview',

    context: {
      expanded: false,
      permissions: {
        vehicleAssignment: 'disabled',
        sendMessage: 'disabled',
      },
    },

    states: {
      overview: {
        on: {
          TOGGLE_EXPANDED: { actions: ['toggleExpanded'] },
        },
      },
    },

    on: {
      UPDATE_ACTION_PERMISSIONS: { actions: 'updateActionPermissions' },
    },
  },
  {
    actions: {
      toggleExpanded: mutableContext => {
        mutableContext.expanded = !mutableContext.expanded
      },
      updateActionPermissions: (mutableContext, eventData: UpdateActionPermissionsEvent) => {
        mutableContext.permissions = eventData.permissions
      },
    },
  },
)
