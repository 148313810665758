import styled from 'styled-components'

const ColorPickerChipButton = styled.button`
  padding: 0;
  border: 3px;
  border-radius: 0;
  width: 26px;
  background: transparent;
  outline: 0;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

ColorPickerChipButton.displayName = 'ColorPickerChipButton'
export default ColorPickerChipButton
