import { proxy } from 'valtio'

type Subscription = {
  // Useful if the API is called before the list isn't rendered yet
  scrollToBuffer: string | undefined
  callbacks: uui.domain.ui.list.SubscriptionCallback[]
}

function getDefaultSubscription(): Subscription {
  return {
    scrollToBuffer: undefined,
    callbacks: [],
  }
}

// ------------------------------------
// Navigo view atom
// ------------------------------------

type ListApiSubscribers = Map<uui.domain.ui.list.Category, Subscription>
type ListApiSubscribersAtom = { subscribers: ListApiSubscribers }

export const listApiSubscribersAtom = proxy<ListApiSubscribersAtom>({
  subscribers: new Map<uui.domain.ui.list.Category, Subscription>(),
})

// ------------------------------------
// Write functions
// ------------------------------------
type SetListSubscriber = (prev: Subscription) => Subscription
type SetListSubscriberParam = SetListSubscriber | Partial<Subscription> | 'reset'

export function resetAllListSubscribers() {
  listApiSubscribersAtom.subscribers.clear()
}
export function resetListSubscriber(category: uui.domain.ui.list.Category) {
  listApiSubscribersAtom.subscribers.delete(category)
}

export function setListSubscribers(
  category: uui.domain.ui.list.Category,
  valueOrFunc: SetListSubscriberParam,
) {
  if (valueOrFunc === 'reset') return resetListSubscriber(category)

  if (!listApiSubscribersAtom.subscribers.get(category)) {
    listApiSubscribersAtom.subscribers.set(category, getDefaultSubscription())
  }

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(
      listApiSubscribersAtom.subscribers.get(category) ?? {},
      valueOrFunc(listApiSubscribersAtom.subscribers.get(category) || getDefaultSubscription()),
    )
  } else {
    const subscriber = listApiSubscribersAtom.subscribers.get(category)

    if (!subscriber) {
      throw new Error(
        'Cannot update an non-existent subscriber. This error should not happen, please check the code',
      )
    }

    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      subscriber[field] = valueOrFunc[category][field]
    }
  }

  return listApiSubscribersAtom
}
