import type { Props } from './typings'

import { ChangeEvent, Component } from 'react'

import { Tick } from '@/icons'

import CheckboxWrapper from './elements/CheckboxWrapper'
import CheckboxIconWrapper from './elements/CheckboxIconWrapper'
import CheckboxIcon from './elements/CheckboxIcon'
import CheckboxInput from './elements/CheckboxInput'
import CheckboxLabel from './elements/CheckboxLabel'

export default class Checkbox extends Component<Props> {
  handleChange = (_: ChangeEvent<HTMLElement>): void => {
    const { onChange, checked } = this.props
    onChange(!checked)
  }

  render() {
    const { checked, disabled, label, id, space = 0, style = {} } = this.props

    return (
      <CheckboxWrapper style={{ marginBottom: space, marginRight: 4, ...style }}>
        <CheckboxIconWrapper>
          <CheckboxIcon checked={checked}>
            <Tick size={14} />
          </CheckboxIcon>

          <CheckboxInput
            onChange={this.handleChange}
            checked={checked}
            disabled={disabled}
            id={id}
          />
        </CheckboxIconWrapper>

        {!!label && (
          <CheckboxLabel htmlFor={id} disabled={disabled}>
            {label}
          </CheckboxLabel>
        )}
      </CheckboxWrapper>
    )
  }
}
