import { Box } from '@mui/material'

import {
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  ReadonlyFieldRowPart,
  Dot,
} from '@/forms-legacy'
import { theme } from '@/styles'

import { useTexts } from './hooks/useTexts'
import { useDeviceInfo } from './hooks/useDeviceInfo'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

const rowStyle = { justifyContent: 'space-between' }

export function DeviceInfo(props: Props) {
  const { unifiedVehicle } = props

  const info = useDeviceInfo(unifiedVehicle)
  const texts = useTexts()

  return (
    <ReadonlyBlock>
      <ReadonlyLabel>{texts.triggeredBy}</ReadonlyLabel>
      <ReadonlyField>
        <ReadonlyFieldRow style={rowStyle}>
          <ReadonlyFieldLabel>{info.label}</ReadonlyFieldLabel>
          <ReadonlyFieldRowPart gap={8}>
            <ReadonlyLabel small>{info.deviceLabel}</ReadonlyLabel>
            <Box width={14} height={14}>
              <Dot outerColor={theme.colors.$pureWhite} innerColor={info.color} />
            </Box>
          </ReadonlyFieldRowPart>
        </ReadonlyFieldRow>
      </ReadonlyField>
    </ReadonlyBlock>
  )
}
