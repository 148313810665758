import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    disable: translate({
      id: 'settings.users.view.disableUserConfirmationModal.controls.send',
    }),

    cancel: translate({
      id: 'settings.users.view.disableUserConfirmationModal.controls.cancel',
    }),

    disableUserSuccess: translate({
      id: 'settings.users.view.disableUserConfirmationModal.toast.success',
    }),

    disableUserError: translate({
      id: 'settings.users.view.disableUserConfirmationModal.toast.error',
    }),
  }))

  return api
}
