import type { MouseEvent, MutableRefObject } from 'react'
import type { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'

import { useCallback, useState } from 'react'
import { IconButton } from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { GridPreferencePanelsValue } from '@mui/x-data-grid-pro'

import { DropdownMenu, DropdownMenuItem } from '@/components/DropdownMenu'
import { useMainSelection } from '@/atoms'

import { useTexts } from '../useTexts'

interface Props {
  orderIds: string[]
  apiRef: MutableRefObject<GridApiPro>
}

function noop(e: MouseEvent) {
  e.preventDefault()
}

export function ContextualMenu(props: Props) {
  const { orderIds, apiRef } = props

  const [selectedOrders, setSelectedOrders] = useMainSelection('orderSteps')

  const [open, setOpen] = useState(false)
  const texts = useTexts()

  const allSelected = orderIds.length === selectedOrders.length

  const onToggleSelection = useCallback(() => {
    setSelectedOrders(allSelected ? [] : orderIds)
    setOpen(false)
  }, [setSelectedOrders, orderIds, allSelected])

  const onEditColumns = useCallback(() => {
    apiRef.current?.showPreferences(GridPreferencePanelsValue.columns)
    setOpen(false)
  }, [apiRef])

  return (
    <DropdownMenu
      setOpen={setOpen}
      open={open}
      autoWidth
      triggerTestid="orders-grid-more-menu"
      trigger={
        // href="#" onClick={noop} ==> Hack to have an <a> component and not a button
        <IconButton href="#" onClick={noop}>
          <MoreVertIcon />
        </IconButton>
      }
    >
      <div
        data-testid="orders-grid-contextual-menu-container"
        data-trackid="orders-grid-contextual-menu-container"
      >
        <DropdownMenuItem
          size="$l"
          weight="$regular"
          onClick={onToggleSelection}
          data-testid="orders-grid-contextual-menu-select-all"
          data-trackid="orders-grid-contextual-menu-select-all"
        >
          {allSelected ? texts.deselectAll(orderIds.length) : texts.selectAll(orderIds.length)}
        </DropdownMenuItem>
        <DropdownMenuItem
          size="$l"
          weight="$regular"
          onClick={onEditColumns}
          data-testid="orders-grid-contextual-menu-edit-columns"
          data-trackid="orders-grid-contextual-menu-edit-columns"
        >
          {texts.editColumns}
        </DropdownMenuItem>
      </div>
    </DropdownMenu>
  )
}
