import { useSelector } from 'react-redux'
import { selectEventInfosListStructure } from '@/features/domain/lists'

import { useSearchList } from './useSearchList'

export function useFilter() {
  const structure = useSelector(selectEventInfosListStructure)
  const editing = false
  const { query, setQuery } = useSearchList()

  return {
    query,
    editing,
    setQuery,
    noEntries: structure.totalItems === 0,
  }
}
