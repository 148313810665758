import type { ChipShape, CoreItem, Modes, DepotChip } from '../../../components/Navigator/typings'

import { Component } from 'react'

import { Depot } from '@/icons'
import { getColorValue, isDark } from '@/styles'

import { Chip } from '../../../components/Navigator/components/Chip'

export interface Props<T extends CoreItem, K> {
  item: T
  mode: Modes
  selected?: boolean
  onClick: () => void
  className?: string
  extraItemRendererProps: K
  loopIndex: number
}

export class DepotItemRenderer extends Component<Props<DepotChip, Record<string, any>>> {
  render() {
    const {
      item: {
        source: { color: depotColor },
      },
      onClick,
      className,
    } = this.props
    const background = getColorValue(depotColor)
    const color = isDark(background) ? '$pureWhite' : '$nightRider'

    const chipProps = {
      color,
      onClick,
      className,
      background,
      Icon: <Depot size={20} color={color} />,
      shape: 'rounded' as ChipShape,
    }
    return <Chip {...chipProps} />
  }
}
