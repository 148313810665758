import styled from 'styled-components'

type Props = {
  error?: boolean
}

const SecondaryColumnTitle = styled.p<Props>`
  color: ${p => (p.error ? p.theme.colors.$outrageousRed : p.theme.colors.$nightRider)};
  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  pointer-events: none;
`

SecondaryColumnTitle.displayName = 'SecondaryColumnTitle'
export default SecondaryColumnTitle
