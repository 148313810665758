import { PureComponent } from 'react'

import {
  FlexRow,
  Block,
  Label,
  FieldMessage,
  Checkbox,
  HourPicker,
  RestoreFieldButton,
} from '@/forms-legacy'

import { Props, WorkingDayInfo } from './typings'

export default class WorkingDayInfoField extends PureComponent<Props> {
  private handleOnChangeStart = (workingDayStart: number) => {
    const {
      formProps: {
        input: { value, onChange },
      },
    } = this.props

    const workingDayInfo: WorkingDayInfo = { ...value, workingDayStart }
    onChange(workingDayInfo)
  }

  private handleOnChangeEnd = (workingDayEnd: number) => {
    const {
      formProps: {
        input: { value, onChange },
      },
    } = this.props

    const workingDayInfo: WorkingDayInfo = { ...value, workingDayEnd }
    onChange(workingDayInfo)
  }

  private handleOnChangeFlexibleStart = (flexibleStart: boolean) => {
    const {
      formProps: {
        input: { value, onChange },
      },
    } = this.props

    const workingDayInfo: WorkingDayInfo = { ...value, flexibleStart }
    onChange(workingDayInfo)
  }

  render() {
    const {
      label,
      labelFlexibleStart,
      labelStart,
      labelEnd,
      labelEndInfo,
      name,
      info,
      half,
      h24,
      testid,
      formProps: {
        input: { value, onChange },
        meta: { error, dirty, initial },
      },
      className,
    } = this.props

    const workingDayInfo: WorkingDayInfo = value as WorkingDayInfo

    return (
      <Block
        half={half}
        error={!!error}
        dirty={!!dirty}
        className={className}
        data-testid={testid}
        data-trackid={testid}
      >
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <FlexRow justifyContent="space-between">
          <Block half={true}>
            <Label>{labelStart}</Label>
            <HourPicker
              name="workingDayStart"
              stepSecs={60 * 10}
              h24={h24}
              onChange={this.handleOnChangeStart}
              value={workingDayInfo.workingDayStart}
            />
          </Block>
          <Block half={true} style={{ justifyContent: 'center', marginTop: 24 }}>
            <Checkbox
              id="flexibleStart"
              checked={workingDayInfo.flexibleStart}
              label={labelFlexibleStart}
              space={0}
              onChange={this.handleOnChangeFlexibleStart}
            />
          </Block>
        </FlexRow>
        <FlexRow justifyContent="space-between">
          <Block half={true}>
            <Label>{labelEnd}</Label>
            <HourPicker
              name="workingDayEnd"
              stepSecs={60 * 10}
              h24={h24}
              onChange={this.handleOnChangeEnd}
              value={workingDayInfo.workingDayEnd}
            />
            <Label small>{labelEndInfo}</Label>
          </Block>
        </FlexRow>

        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }
}
