import { Typography, TextField, Stack } from '@mui/material'

interface Props {
  value: Record<string, string>
  onChange: (key: string, value: string) => void
}

export function CustomFields(props: Props) {
  const { value, onChange } = props

  if (Object.keys(value).length === 0) return null

  return (
    <div>
      <Typography variant="h6">Custom Fields</Typography>
      <Stack spacing={0.5}>
        {Object.entries(value).map(([key, value]) => (
          <div key={key}>
            <label>
              {key}
              <TextField value={value} onChange={e => onChange(key, e.target.value)} />
            </label>
          </div>
        ))}
      </Stack>
    </div>
  )
}
