import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    stepsViolations: (count: number) =>
      translate({ id: 'rm.scheduler.popOver.stepsViolationsRecap', values: { count } }),

    vehicleViolations: (count: number) =>
      translate({ id: 'rm.scheduler.popOver.vehicleViolationsRecap', values: { count } }),
  }))

  return api
}
