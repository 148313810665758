import type { RouteStep, Route } from '../../typings'

export const hasBeenExecuted = (step: RouteStep, route: Route): boolean => {
  const { edge } = route
  if (!edge) return false

  const { timeSec } = edge
  const { endSec } = step

  return endSec <= timeSec
}
