import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

type Payload = uui.domain.actions.rpc.user.CreateUserAction['payload']

/**
 * Create a user
 *
 * @private
 */
export const createUser = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/user/create', async (payload: Payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/user/createUser', {
      category: 'rpc',
      type: 'rpc/user/createUser',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main('Unknown error on {createUser}', { tags: ['rpc', 'user'] }, 'error')

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Create User Failed',
      error,
    })
  }
})
