import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useCallback } from 'react'
import { clsx } from '@/utils'
import { Link, useGpsReportsLink } from '@/routing'
import { Tooltip } from '@/components/primitives/Tooltip'

import { HeaderLink } from '../HeaderLink'

import { useTrackingTooltip } from '../../../../hooks/useTrackingTooltip'
import { useTrackingDisabled } from '../../../../hooks/useTrackingDisabled'
import { getSelectedDeviceIds } from '../../../../utils/getSelectedDeviceIds'
import { useTexts } from '../../../../useTexts'

import { useRm360Links } from './hooks/useRm360Links'

type MainSections = uui.routing.MainSections
type RoutingRoutes = uui.routing.Routing

const useStyles = makeStyles(muiTheme => ({
  fullHeight: {
    height: '100%',
  },
  heightToChildren: {
    '& > *': {
      height: '100%',
    },
  },
  regularFontWeight: {
    fontWeight: muiTheme.typography.fontWeightRegular,
  },
  noHover: {
    '&:hover': {
      //you want this to be the same as the backgroundColor above
      backgroundColor: 'transparent',
    },
  },
}))

export function Rm360Links() {
  const texts = useTexts()
  const classes = useStyles()
  const { canShow } = useRm360Links()
  const { gpsReportsLink, createGpsReportsCookie } = useGpsReportsLink()

  const trackingTooltip = useTrackingTooltip()
  const trackingDisabled = useTrackingDisabled()

  const onGpsReportClick = useCallback(() => {
    createGpsReportsCookie(getSelectedDeviceIds())
  }, [createGpsReportsCookie])

  return (
    <>
      <Link<RoutingRoutes>
        absolute
        to="home"
        className={clsx(classes.fullHeight, 'pendo-bluebar__home-menu')}
        data-testid="header__nav-home-button-link"
      >
        <HeaderLink
          data-testid="header__nav-home-button-content"
          data-trackid="rm360-header__nav-home-button-content"
        >
          {texts.home}
        </HeaderLink>
      </Link>

      <Link<RoutingRoutes>
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        className={clsx(classes.fullHeight, 'pendo-bluebar__orders-menu')}
      >
        <HeaderLink
          data-testid="header__nav-orders-button-content"
          data-trackid="rm360-header__nav-orders-button-content"
        >
          {texts.orders}
        </HeaderLink>
      </Link>

      {canShow.tracking && (
        <div className={clsx(classes.fullHeight, classes.heightToChildren)}>
          <Tooltip
            placement="bottom"
            title={trackingTooltip}
            disabled={trackingTooltip.length === 0}
          >
            <Link<MainSections>
              to="tracking"
              disabled={trackingDisabled}
              data-testid="header__nav-tracking-button-link"
              className={clsx(classes.fullHeight, 'pendo-bluebar__tracking-list-menu')}
            >
              <HeaderLink
                disabled={trackingDisabled}
                data-testid="header__nav-tracking-button-content"
                data-trackid="rm360-header__nav-tracking-button-content"
              >
                {texts.tracking}
              </HeaderLink>
            </Link>
          </Tooltip>
        </div>
      )}

      {canShow.simulations && (
        <Link<RoutingRoutes>
          absolute
          to="simulations"
          data-testid="header__nav-simulations-button-link"
          className={clsx(classes.fullHeight, 'pendo-bluebar__simulations-list-menu')}
        >
          <HeaderLink
            data-testid="header__nav-simulations-button-content"
            data-trackid="rm360-header__nav-simulations-button-content"
          >
            {texts.simulations}
          </HeaderLink>
        </Link>
      )}

      {canShow.reports && (
        <Button
          href={gpsReportsLink}
          target="_blank"
          onClick={onGpsReportClick}
          data-testid="header__nav-reports-button-link"
          className={clsx(
            classes.fullHeight,
            classes.regularFontWeight,
            classes.noHover,
            'pendo-bluebar__GPS-reports-menu',
          )}
        >
          <HeaderLink
            data-testid="header__nav-reports-button-content"
            data-trackid="rm360-header__nav-reports-button-content"
          >
            {texts.gpsReports}
          </HeaderLink>
        </Button>
      )}
    </>
  )
}
