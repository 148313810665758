import type { CrudSelectionCategory, MainSelectionCategory } from '@/atoms'

import { proxy } from 'valtio'

export type MapSelectionContext =
  | { type: 'none' }
  | { type: 'main'; category?: MainSelectionCategory }
  | { type: 'crud'; category: CrudSelectionCategory }

export type ConnectedListScrollTo = (id: string) => void
export type ConnectedListApi =
  | { status: 'idle' }
  | {
      status: 'connected'
      disabled?: boolean
      category: uui.domain.ui.list.Category
      scrollTo: ConnectedListScrollTo
    }

type MapSelection = {
  context: MapSelectionContext
  connectedList: ConnectedListApi
}

// ------------------------------------
// Map Selection atom
// ------------------------------------

export const mapSelectionAtom = proxy<MapSelection>({
  context: { type: 'none' },
  connectedList: { status: 'idle' },
})
