import type { Theme } from '@mui/material'
import type { OrderStatus } from '../../../typings'

import { makeStyles } from '@mui/styles'

import { setAlpha, theme } from '@/styles'

interface Props {
  status: OrderStatus
}

function getStatusColors(status: OrderStatus) {
  switch (status) {
    case 'unassigned':
      return { backgroundColor: theme.colors.$whiteSmoke, color: theme.colors.$nightRider }

    case 'notDispatched':
      return { backgroundColor: theme.colors.$sailBlue, color: theme.colors.$dodgerBlue }

    case 'todo':
      return { backgroundColor: theme.colors.$dodgerBlue, color: theme.colors.$pureWhite }

    case 'completed':
      return { backgroundColor: theme.colors.$pigmentGreen, color: theme.colors.$pureWhite }

    case 'notCompleted':
      return { backgroundColor: theme.colors.$outrageousRed, color: theme.colors.$pureWhite }

    case 'undeclared':
      return {
        backgroundColor: setAlpha(theme.colors.$pigmentGreen, 0.2),
        color: theme.colors.$pigmentGreen,
      }
  }
}

export const useStyles = makeStyles<Theme, Props>({
  container: props => ({
    float: 'left',
    marginTop: 10,
    width: 'auto',
    borderRadius: 2,
    display: 'block',
    padding: '2px 4px',
    ...getStatusColors(props.status),
  }),
})
