import type { CSSProperties } from 'react'

import { useMemo } from 'react'

import { EventAlertIcon } from '@/components/EventAlertIcon'

export interface Props {
  eventTypeId: uui.domain.client.gps.wwgps.EventAlert['eventTypeId']
  size?: number
  style?: CSSProperties
  selected?: boolean
}

export function AlertAvatar(props: Props) {
  const { size = 32, selected = false, style = {}, eventTypeId } = props

  const iconProps = useMemo(() => {
    return {
      size,
      color: selected ? '$pureWhite' : '$nightRider',
      style,
    } as const
  }, [selected, size, style])

  return <EventAlertIcon eventTypeId={eventTypeId} iconProps={iconProps} />
}
