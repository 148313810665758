import styled from 'styled-components'

export const ReadonlyFieldRow = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  width: 100%;
  height: 28px;
`

ReadonlyFieldRow.displayName = 'ReadonlyFieldRow'
