import { FormattedMessage } from 'react-intl'
import { Alert, Typography } from '@mui/material'

import { useTexts } from '../../../../../hooks/useTexts'

type Props = {
  approvedRoutesAmount: number
  selectedRoutesAmount: number
}

export function NotAllRoutesDispatchedWarning(props: Props) {
  const { approvedRoutesAmount, selectedRoutesAmount } = props
  const texts = useTexts()

  return (
    <Alert
      severity="warning"
      sx={{
        '& .MuiAlert-icon': {
          alignItems: 'center',
        },
      }}
    >
      <FormattedMessage
        id={texts.notAllRoutesDispatchedWarningId}
        values={{
          b: chunks => (
            <Typography variant="subtitle2" fontWeight={500} display="inline">
              {chunks}
            </Typography>
          ),
          br: _ => <br />,
          routesAmount: selectedRoutesAmount,
          approvedRoutesAmount,
        }}
      />
    </Alert>
  )
}
