import type { DriverAssignmentFormValues } from '../../typings'
import type { DropDownItem } from '@/components/Dropdown'

import { useCallback } from 'react'
import { FieldRenderProps } from 'react-final-form'

import { Box } from '@mui/material'

import { Dropdown } from '@/components/Dropdown'
import { Block, Label, FieldMessage, RestoreFieldButton } from '@/forms-legacy'

import { NOT_DISPATCHED } from '../../constants'
import { useDropdownOptionsByVehicleId } from './hooks/useDropdownOptionsByVehicleId'

interface Props {
  formProps: FieldRenderProps<DriverAssignmentFormValues['assignments']>
}

export function AssignmentsField(props: Props) {
  const { formProps } = props

  const {
    input: { onChange: change, value },
    meta: { error, dirty, initial },
  } = formProps

  const optionsByVehicleId = useDropdownOptionsByVehicleId(value)

  const onChange = useCallback(
    (vehicleId: string) => (item: DropDownItem) => {
      const driverId = item.id

      const updatedValue = { ...value }

      // look for occurrences of the driverId before update the value
      const entries = Object.entries(updatedValue)
      for (const [vId, dId] of entries) {
        if (dId === driverId) {
          updatedValue[vId] = NOT_DISPATCHED
          break
        }
      }

      // set the updated driverId to the vehicleId
      updatedValue[vehicleId] = driverId

      change(updatedValue)
    },
    [change, value],
  )

  return (
    <Block error={!!error} dirty={!!dirty}>
      <RestoreFieldButton dirty={dirty} initial={initial} onChange={change} />

      {Object.values(optionsByVehicleId).map(data => (
        <Box key={data.vehicleId} width="100%" paddingBottom={1}>
          <Label bold>{data.label}</Label>
          <Dropdown
            maxListHeight={400}
            options={data.options ?? []}
            onChange={onChange(data.vehicleId)}
          />
        </Box>
      ))}
      {!!error && <FieldMessage error>{error}</FieldMessage>}
    </Block>
  )
}
