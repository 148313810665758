import { InspectPinMode, Map as MainMap, MapLayer } from '@/map'

export function Map() {
  return (
    <MainMap selectionContext="none" mapId="ww-map">
      <InspectPinMode mode="none" />

      <MapLayer uid="order" interactive={false} visible />
      <MapLayer uid="region" visible />
      <MapLayer uid="roadSegment" visible />
      <MapLayer uid="geofence" visible={false} />
      <MapLayer uid="routePolyline" visible />
      <MapLayer uid="place" visible />

      {/* TODO: Add remaining layers once they are implemented */}
    </MainMap>
  )
}
