import type { MouseEvent } from 'react'
import type { State, UpdateAction } from '../useTimePicker'

import { useCallback, useEffect, useRef } from 'react'

export function useMouseActions(
  componentState: State,
  updateComponentState: React.Dispatch<UpdateAction>,
) {
  const componentStateRef = useRef(componentState)
  useEffect(() => {
    componentStateRef.current = componentState
  }, [componentState])

  // Called when a click on the TextInput is triggered
  const onClick = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      const inputElement = e.target as HTMLInputElement
      inputElement.select()
      updateComponentState({ open: true })
    },
    [updateComponentState],
  )

  // Called when a click on the Adornment
  const onClickAdornment = useCallback(
    (rootRef: HTMLDivElement | null) => (e: MouseEvent) => {
      e.stopPropagation()
      const inputEl = rootRef?.querySelector('input')
      inputEl?.select()
      updateComponentState({ open: true })
    },
    [updateComponentState],
  )

  // Intercept clicks away of the component to close the dropdown
  const onClickAway = useCallback(() => {
    updateComponentState({ open: false })
  }, [updateComponentState])

  return { onClick, onClickAway, onClickAdornment }
}
