import { setCrudSelection, setEditingState, setMainSelection } from '@/atoms'
import { getRouterNavigator } from '@/routing'
import { gis } from '@/server-data'

import { fitMap } from '../../../../atoms/map/utils/fitMap'
import { getMapSelectionContext } from '../../../../atoms/selection/read/getMapSelectionContext'
import { getDomainMarkerData } from '../../../../atoms/markerChangelog/domain/getDomainMarkerData'

export function doubleClickDevices(deviceId?: string) {
  if (deviceId) {
    const { mapMarkers, gpsRole } = getDomainMarkerData()
    const { type: selectionCtx } = getMapSelectionContext()

    const latLngCoordinates: uui.domain.LatLng[] = []

    const device = mapMarkers.device[deviceId]

    if (!device) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`Trying to double click a non-existent Device with ID: ${deviceId}`)
      }

      return
    }

    latLngCoordinates.push(gis.fromCoordinateToLatLng(device.lonLat))

    // Set the new selection for both selectionContexts
    setMainSelection('devices', [deviceId])
    setCrudSelection('devices', [deviceId])

    // queue a pending fitMap request
    // it will be applied by the destination view when it opens
    fitMap(latLngCoordinates, { preventIfVisible: true }, 'add')

    const canEdit = gpsRole && (gpsRole === 'org-admin' || gpsRole.startsWith('staff-'))

    // If the double click has been performed in the Setup Map let's set the editing state
    if (selectionCtx === 'crud' && canEdit) {
      setEditingState({ editing: true, ctx: 'device', editingEntities: [deviceId] })
    }

    // Navigate to the target page
    const navigate = getRouterNavigator()
    navigate('/setup/devices')
  }
}
