import { type SchedulerProConfig } from '@bryntum/schedulerpro'

import { tooltipTemplate } from './tooltipTemplate'
import { validatorFn } from './validatorFn'

export const eventDrag: SchedulerProConfig['features']['eventDrag'] = {
  tooltipTemplate,
  showTooltip: true,
  validatorFn,
}
