import { useEffect, useRef } from 'react'

import { useControllerActions } from '../hooks/useControllerActions'

/**
 * Open the file dialog on mount.
 */
export function useOpenFileDialogOnMount() {
  const { handleOnChangeFile } = useControllerActions()

  const api = useRef({
    opened: false,
    handleOnChangeFile,
  })
  useEffect(() => {
    api.current.handleOnChangeFile = handleOnChangeFile
  }, [handleOnChangeFile])

  useEffect(() => {
    if (api.current.opened) return

    // subsequent calls should be blocked by the browser but it's better off preventing any unwanted
    // behavior at all
    api.current.opened = true

    // Please note that opening the file dialog programmatically works only if triggered by a
    // user-originated interaction. This works because the user clicked on the "import new territory"
    // button
    api.current.handleOnChangeFile()
  }, [])
}
