import styled from 'styled-components'

type Props = {
  hasTrackingData?: boolean
  hasPOD?: boolean
  isUnassigned?: boolean
  hasBeenExecuted?: boolean
}

const styledComponent = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  padding: 0;
  outline: 0;
  width: 100%;
  height: 100%;

  color: ${p =>
    p.hasPOD
      ? p.theme.colors.$nightRider
      : p.hasTrackingData
      ? p.theme.colors.$whiteSmoke
      : p.theme.colors.$pureWhite};
  background: ${p => (p.hasBeenExecuted ? p.theme.colors.$shadyLady : p.theme.colors.$pureWhite)};

  border-width: 0;
  border-radius: 8px;
  border: 2px solid;
  border-color: ${p => (p.isUnassigned ? p.theme.colors.$whiteSmoke : p.theme.colors.$pureWhite)};

  cursor: pointer;

  transition: 0.3s ease;
  transition-property: background, color, opacity;
`

styledComponent.displayName = 'OrderAvatarInner'
export default styledComponent
