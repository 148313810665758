import type { VehicleChip } from '../../../components/Navigator/typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehicles } from '@/features/domain/vehicle'

import { useNavigator as useCoreNavigator } from '../../../components/Navigator/hooks/useNavigator'

export function useVehiclesNavigator() {
  const {
    ids,
    pagination: { page, setPage },
  } = useCoreNavigator()
  const vehicles = useSelector(selectVehicles)

  return useMemo(() => {
    const selectedVehicles = ids.map(id => vehicles[id]).filter(Boolean)

    const chips: VehicleChip[] = selectedVehicles.map(vehicle => ({
      id: vehicle.unifiedId,
      source: vehicle,
      type: 'vehicle',
    }))

    const currentVehicles =
      page === -1 || !selectedVehicles?.[page] ? selectedVehicles : [selectedVehicles[page]]
    const currentVehicleIds = page === -1 || !ids?.[page] ? ids : [ids[page]]

    return {
      page,
      chips,
      setPage,
      currentVehicles,
      currentVehicleIds,
    }
  }, [vehicles, ids, page, setPage])
}
