import type { FormErrors, FormFields } from '../formFields'

import { Stack } from '@mui/material'
import { ValidationSummary } from '@workwave-tidal/tidal/form-ui/utils/ValidationSummary'
import { Password as PasswordField } from '@workwave-tidal/tidal/form-ui'

import { useStandardPasswordValidations } from '@/hooks'
import { useTexts } from '../hooks/useTexts'

interface Props {
  firstSubmitDone: boolean
}
export function Password(props: Props) {
  const { firstSubmitDone } = props

  const texts = useTexts()
  const { validationSummaryItems } = useStandardPasswordValidations('new-password')

  const validationSummaryMode = firstSubmitDone ? 'show-errors' : 'silent-errors'

  return (
    <Stack spacing={2}>
      <PasswordField<'new-password', FormFields, FormErrors>
        name="new-password"
        testId="new-password"
        validateOn="blur focus change"
        label={texts.labels.password}
        placeholder={texts.placeholders.password}
        errorVisibility={firstSubmitDone ? 'accent-only' : 'none'}
        textfieldProps={{
          autoComplete: 'new-password',
        }}
      />

      <ValidationSummary<FormFields> items={validationSummaryItems} mode={validationSummaryMode} />
    </Stack>
  )
}
