import { ChangeVehicleLoadsEventHandler } from './typings'

export const updateLoads = (
  key: string,
  val: uui.domain.ui.forms.VehicleLoad,
  value: uui.domain.ui.forms.ExceptionFormValues['loads'],
  onChange: ChangeVehicleLoadsEventHandler,
) => {
  const updatedLoads: uui.domain.ui.forms.ExceptionFormValues['loads'] = {
    ...value,
    [key]: val,
  }
  onChange(updatedLoads)
}

export const deleteLoad = (
  key: string,
  value: uui.domain.ui.forms.ExceptionFormValues['loads'],
  onChange: ChangeVehicleLoadsEventHandler,
): void => {
  const v = { ...value }
  delete v[key]
  onChange(v)
}
