import { Text } from '@/local/components'
import { useTexts } from '../useTexts'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

export function OrderName(props: Props) {
  const { extOrder } = props
  const texts = useTexts()

  return (
    <Text size="$m" weight="$semiBold">
      {extOrder.isUnassigned
        ? extOrder.order.name || texts.unnamedOrder
        : `${extOrder.routeStepLabel} - ${extOrder.order.name || texts.unnamedOrder}`}
    </Text>
  )
}
