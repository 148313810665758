import { useCallback, useState } from 'react'

import { useSelector } from 'react-redux'
import { SearchableListField } from '@/forms-legacy'

import { selectPhysicalDevices } from '@/features/domain/device'
import { useTexts } from '../useTexts'

import DeviceListItem from './components/DeviceListItem'

export interface DeviceInfoItem {
  id: string
  name: string
  color: string
}

function convertToDeviceInfoItem(device: uui.domain.client.gps.wwgps.DeviceInfo) {
  return {
    id: device.deviceId,
    name: device.label,
    color: device.iconColor1,
  }
}

export function DevicesList() {
  const devices = useSelector(selectPhysicalDevices) as uui.domain.client.gps.wwgps.DeviceInfo[]

  const [visibleDevices, setVisibleDevices] = useState(() => devices.map(convertToDeviceInfoItem))
  const texts = useTexts()

  const handleOnFilter = useCallback(
    (txt: string) => {
      const filter = txt.toLocaleLowerCase().trim()

      const value = devices
        .filter(({ label }) => {
          return label.toLocaleLowerCase().trim().includes(filter)
        })
        .map(convertToDeviceInfoItem)

      setVisibleDevices(value)
    },
    [devices],
  )

  return (
    <SearchableListField<DeviceInfoItem>
      name="deviceIds"
      label={texts.monitoredVehicles}
      wrappedInputProps={{
        renderText: texts.device,
        ListItem: DeviceListItem,
        onFilter: handleOnFilter,
        values: visibleDevices,
        selectionLimit: -1,
        visibleRows: 8,
        rowHeight: 26,
      }}
    />
  )
}
