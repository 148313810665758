import styled from 'styled-components'

type Props = {
  hasDot?: boolean
  hasContent?: boolean
  invisible?: boolean
  primary?: boolean
  empty?: boolean
  small?: boolean
  ellipsis?: boolean
  truncateFromLeft?: boolean
}

const getDisplayBefore = ({ hasDot }: Props) => (hasDot ? 'inline-block' : 'none')

const getContentRules = ({ hasContent = false }: Props) => {
  if (!hasContent) {
    return ''
  }

  return `
  display: flex;
  align-items: flex-start;

  > * {
    margin-right: 4px;
  }
  `
}

const getEllipsisRules = ({ ellipsis, truncateFromLeft }: Props) => {
  if (!ellipsis) {
    return ''
  }

  return `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${truncateFromLeft ? 'direction: rtl;' : ''}
  `
}

const dotSize = 10

export const ReadonlyLabel = styled.p<Props>`
  position: relative;
  font-size: ${p => (p.small ? p.theme.fonts.$s : p.theme.fonts.$p2)};
  font-weight: ${p => (p.primary ? p.theme.weights.$semiBold : p.theme.weights.$regular)};
  color: ${p => (p.empty ? p.theme.colors.$silver : p.theme.colors.$nightRider)};
  padding-left: 6px;
  letter-spacing: ${p => (p.primary ? '-0.003rem' : 'inherit')};
  margin: 0;
  margin-bottom: ${p => (p.primary && !p.empty ? '12px' : '0')};
  user-select: none;

  ${getContentRules};
  ${getEllipsisRules};
  visibility: ${p => (p.invisible ? 'hidden' : 'visible')};

  &::before {
    content: '';
    display: ${getDisplayBefore};
    width: ${dotSize}px;
    height: ${dotSize}px;
    border-radius: 50%;
    margin-right: ${dotSize / 2}px;
    background: ${p => p.theme.colors.$pigmentGreen};
  }
`

ReadonlyLabel.displayName = 'ReadonlyLabel'
