import type { StandardType } from '../types'

export function isStandardType(source: unknown): source is StandardType {
  if (typeof source !== 'string') return false

  return (
    source === 'string' ||
    source === 'boolean' ||
    source === 'integer' ||
    source === 'float' ||
    source === 'timestamp' ||
    source === 'isoDateTime' ||
    source === 'json'
  )
}
