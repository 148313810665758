import { useState, useCallback } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'
import {
  DropdownMenu,
  MenuIconTrigger,
  DropdownMenuItem,
  DropdownTextItem,
  DropdownListSeparator,
} from '@/components/DropdownMenu'
import { MapStyle } from '@/icons'

import { Text } from '@/local/components'

import { useStyles } from '../../hooks/useStyles'

import { useResetStyles } from './hooks/useResetStyles'
import { Menu } from './components/Menu'
import { useTexts } from './useTexts'

export function GlobalStyles() {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const resetStyles = useResetStyles()
  const texts = useTexts()

  const onReset = useCallback(() => {
    resetStyles()
    setOpen(false)
  }, [resetStyles, setOpen])

  return (
    <DropdownMenu
      open={open}
      setOpen={setOpen}
      trigger={
        <Tooltip placement="bottom" title={texts.title}>
          <MenuIconTrigger
            menuOpen={open}
            className={classes.button}
            Icon={<MapStyle size={15} color={open ? '$scienceBlue' : '$nightRider'} />}
            testid="map-controls-style-on-map"
          />
        </Tooltip>
      }
    >
      <DropdownTextItem>
        <Text color="$paleSky" size="$h4" uppercase>
          {texts.title}
        </Text>
      </DropdownTextItem>

      <Menu menuOpen={open} />

      <DropdownListSeparator />

      <DropdownMenuItem warn onClick={onReset}>
        {texts.resetAll}
      </DropdownMenuItem>
    </DropdownMenu>
  )
}
