import { NotificationsList } from '@/notificationForms'

import { useNotificationsStatus } from './hooks/useNotificationsStatus'

interface Props {
  inEditing: boolean
}

/**
 * Render the notification list providing all the data and the translations.
 */
export function NotificationsListProvider(props: Props) {
  const notificationStatus = useNotificationsStatus()

  return <NotificationsList notificationStatus={notificationStatus} inEditing={props.inEditing} />
}
