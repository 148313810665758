import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { MapControls } from '@/components/MapControls'

import { AlertsRoot } from './components/form/AlertsRoot'
import { Map } from './components/Map'

export function Alerts() {
  return (
    <HorizontalLayout data-testid="setup-alerts-root">
      <AlertsRoot />

      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />

          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
