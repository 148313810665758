import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    header: {
      title: translate({ id: 'voice.form.header.title' }),
      subtitle: translate({ id: 'voice.form.header.subtitle' }),
    },
    alert: translate({ id: 'voice.form.alert' }),
  }))

  return api
}
