export function findIndexOfNearestOption(value: number, options: number[]) {
  let minimumInterval = 99999999 // sufficiently high number to start the loop
  let indexWithMinInterval = -1
  let counter = 0

  while (counter < options.length && minimumInterval > Math.abs(value - options[counter])) {
    minimumInterval = Math.abs(value - options[counter])
    indexWithMinInterval = counter
    counter++
  }

  // If no option has been found, let's return
  if (indexWithMinInterval === -1) return

  return indexWithMinInterval
}
