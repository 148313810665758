import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    on: translate({
      id: 'global.on',
    }),

    off: translate({
      id: 'global.off',
    }),

    notificationTolerance: translate({
      id: 'sms4.settings.communication.notifications.form.toleranceMins.label',
    }),

    notificationToleranceValue: (minutes: string) =>
      translate({
        id: 'sms4.settings.communication.notifications.form.toleranceMins.value',
        values: { minutes },
      }),

    noTolerance: translate({
      id: 'sms4.settings.communication.notifications.form.toleranceMins.label.noTolerance',
    }),

    notificationType: translate({
      id: 'sms4.settings.communication.notifications.form.notificationWay.label',
    }),

    emailSubject: translate({
      id: 'sms4.settings.communication.notifications.form.subjectCustom.label',
    }),

    emailText: translate({
      id: 'sms4.settings.communication.notifications.form.message.email.label',
    }),

    smsText: translate({
      id: 'sms4.settings.communication.notifications.form.message.sms.label',
    }),

    emailBackup: translate({
      id: 'sms4.settings.communication.notifications.form.backupNotification.label',
    }),

    emailBackupSubject: translate({
      id: 'sms4.settings.communication.notifications.form.backupSubject.label',
    }),

    segmentsCount: (count: number) =>
      translate({ id: 'sms4.hotwordsField.segmentsCount', values: { count } }),
  }))

  return api
}
