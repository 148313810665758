import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    recurrenceDescription: (type: uui.domain.rm.RecurrenceTypes) => {
      switch (type) {
        case 'DATE':
          return translate({ id: 'setup.vehicleAssignments.list.type.date' })

        case 'WEEKLY':
          return translate({ id: 'setup.vehicleAssignments.list.type.weekly' })

        case 'MONTHLY':
          return translate({ id: 'setup.vehicleAssignments.list.type.monthly' })

        case 'ANNUAL':
          return translate({ id: 'setup.vehicleAssignments.list.type.annual' })

        case 'any':
          return translate({ id: 'setup.vehicleAssignments.list.type.default' })

        case 'BEFORE':
          return translate({ id: 'setup.vehicleAssignments.list.type.before' })

        default:
          return ''
      }
    },
  }))

  return api
}
