import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { parseISO, isEqual, addSeconds } from 'date-fns/esm'

import { selectVehiclesByDeviceId } from '@/features/domain/vehicle'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectTerritory } from '@/features/domain/territory'
import { selectStartOfToday } from '@/features/domain/user'
import { selectDevices } from '@/features/domain/device'
import { isUnassociatedDevice } from '@/server-data'

export const useIsEnabled = (): 'noDevices' | 'notToday' | true => {
  const { start, end } = useSelector(selectCalendarRangeInterval)
  const { workingDayStartSec } = useSelector(selectTerritory)
  const startOfToday = useSelector(selectStartOfToday)
  const vehiclesByDeviceId = useSelector(selectVehiclesByDeviceId)
  const devices = useSelector(selectDevices)

  return useMemo(() => {
    if (
      // Is not a range
      !isEqual(start, end) ||
      // Is not today
      !isEqual(addSeconds(start, workingDayStartSec), parseISO(startOfToday))
    )
      return 'notToday'

    // Filter out virtual devices and associated devices
    const atLeastOneOneUnassociatedDevice = Object.values(devices).some(device => {
      return !device.virtual && isUnassociatedDevice(vehiclesByDeviceId[device.deviceId])
    }, [])

    if (!atLeastOneOneUnassociatedDevice) return 'noDevices'

    return true
  }, [startOfToday, workingDayStartSec, devices, vehiclesByDeviceId, start, end])
}
