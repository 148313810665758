import { type CSSProperties } from 'react'
import { type ResourceModel } from '@bryntum/schedulerpro'

import { isDark } from '@/styles'

import { Typography } from '../Scheduler/components/Typography'

interface Props {
  record: ResourceModel
  value: number
}

export function IdCell(props: Props) {
  const { value, record } = props

  const routeColor = record.getData('color') as uui.domain.client.rm.SchedulerResource['color']
  const empty = record.getData('empty') as uui.domain.client.rm.SchedulerResource['empty']

  const background = empty ? 'white' : routeColor

  //HACK: The color attribute is set as a CSS variable in the component, since that component won't change text color based on the selection
  const color = isDark(background) ? 'white' : 'black'

  const style = {
    '--background-color': background,
    '--border-color': routeColor,
    '--border-width': empty ? '2px' : 0,
  } as CSSProperties

  return (
    <div className="o-scheduler-id-cell" style={style}>
      <Typography style={{ color: `${color} !important` }} fontSize={12}>
        {value === -1 ? '' : value}
      </Typography>
    </div>
  )
}
