import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Rabbit(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 12 12">
        <path d="M4 .57143c-.30804.00893-.55357.26116-.55357.57143 0 .31473.2567.57143.57143.57143h.03571c.31027-.01563.61607.00892.91072.03571-.24108.07813-.39286.29464-.39286.53571 0 .05804.01786.12054.03571.17858.09822.2991.41518.45535.71429.35714C6.02455 2.59375 6.56696 2.49777 7 2.46429c-.07813.21875-.125.44866-.125.67857 0 .66518-.76786.39285-1.73214.30357-.17634-.01563-.9442 0-1.14286.03571-1.16741.21206-2.26786 1.59822-2.26786 3.94643 0 .0134-.00223.02456 0 .03572-.08928-.03125-.1875-.03572-.28571-.03572-.47322 0-.85714.38393-.85714.85714 0 .47322.38392.85715.85714.85715.43303 0 .78125-.3326.83928-.75.06697.20759.47545.17857.57143.17857H4c.39509 0-.27679.35714-.5.83928-.21875.39733.08259.875.53571.875C7.61384 10.26786 7.40401 11.42857 8 11.42857c.56027.07143.57143-.33259.57143-.57143 0-.26116-.48214-.79687-1.14286-1.14285-.5-.26116-.84821-.4308-1.71428-.57143.53348-.24107 1.22767-.59152 1.53571-1.07143.16741-.29688.17857-.55804.17857-.64286 0-.6317-.51116-1.14286-1.14286-1.14286-.4442 0-.85937.18304-1.14285.57143 0 0 .35044-1.12053 1.14285-1.14285C7.51786 5.71429 8 6.3125 8 7.42857c0 .53572-.20536 1.09822-.57143 1.71429.5-.3683.82143-.78125 1.10714-1.14286.37277-.47098.51786-1.125.60715-2.28571.01116-.14956.00893-.37277.01785-.58929.375-.0134.7433-.05357 1.07143-.10714 1.03572-.16965 1.53125-1.36161.94643-2.23215-.2299-.34375-.53125-.70982-.89286-1.07142-.4375-.4375-.8616-.57143-1.58928-.57143-.2567 0-.5.07143-.73214.19643-.01116-.00224-.02233.00223-.03572 0C7.04241.95982 5.62277.4933 4 .57143zM1.14286 2.2857L.57143 3.42857H2c.51786-.8259 1.17857-1.11607 1.42857-1.14286H1.14286zm8.58928 0c.31473 0 .57143.2567.57143.57143s-.2567.57143-.57143.57143-.57143-.2567-.57143-.57143.2567-.57143.57143-.57143zM.375 4L0 5.14286h1.41071c.05804-.44643.19866-.82143.32143-1.14286H.375z" />
      </svg>
    </IconContainer>
  )
}
