import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { WarningTriangle } from '@/icons'
import { FlexBox } from '@/local/components'
import { selectUserType } from '@/features/domain/user'

import { Container } from '../../elements/Container'
import { useTexts } from './useTexts'

type Props = {
  offline: boolean
  transaction: workwave.DeepReadonly<uui.domain.api.Transaction>
}

const acceptedUserTypes: uui.domain.rm.RouteManagerUserType[] = ['admin', 'planner', 'courier']

export function FooterLocked(props: Props) {
  const { transaction } = props
  const texts = useTexts()
  const userType = useSelector(selectUserType)

  const getOwner = useCallback(() => {
    if (transaction.mode !== 'external' || !acceptedUserTypes.includes(userType)) return ''

    //Anonymous user
    if (transaction.owner === 'anonymous') return texts.anonymousUser

    //Admin user
    if (typeof transaction.owner === 'string') return transaction.owner

    const { type, name, surname } = transaction.owner
    const role = type.charAt(0).toUpperCase() + type.slice(1)

    return `${role} ${name} ${surname}`
  }, [texts, userType, transaction])

  return acceptedUserTypes.includes(userType) ? (
    <Container color="$nightRider" background="$tangerineYellow">
      <FlexBox vAlignContent="center" grow={0} shrink={0} zIndex={10}>
        <WarningTriangle size={16} color="$tangerineYellow" marginRight={14} />
        {texts.lockedByUser(getOwner())}
      </FlexBox>
    </Container>
  ) : null
}
