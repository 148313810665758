import type OlMap from 'ol/Map'
import type { MapTile } from '../../atoms/map/map'

import { listenToLanguageChange } from '@/intl'

import { listenToEnvironmentReady } from '../../../atoms'

import { registerZoomControl, refreshZoomControl } from './registerZoomControl'
import { registerOverviewMapControl } from './registerOverviewMapControl'
import { registerFullScreenControl } from './registerFullScreenControl'
import { registerSearchControl } from './registerSearchControl'
import { registerScaleControl } from './registerScaleControl'
import { getTexts } from './texts'

export const connectMapControlsToLanguage = (map: OlMap) => {
  return listenToLanguageChange(() => {
    const texts = getTexts()

    refreshZoomControl(map, texts)
  })
}

export const registerMapControls = (map: OlMap, tile: MapTile, useMetricSystem: boolean = true) => {
  const texts = getTexts()

  const unsubscribe = listenToLanguageChange(() => {
    registerZoomControl(map, texts)
    registerScaleControl(map, useMetricSystem)
    registerSearchControl(map)
    registerFullScreenControl(map)
    //registerMapTilesControl(map, tile, texts)
    unsubscribe()
  })

  const unsubscribeEnvironmentReadyHandlers = listenToEnvironmentReady(env => {
    if (env.status !== 'ready') return
    registerOverviewMapControl(map, tile, texts)
    unsubscribeEnvironmentReadyHandlers()
  })
}

export { refreshScaleControl } from './registerScaleControl'
