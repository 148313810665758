import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useEditingStateWithCtx, resetCrudSelection } from '@/atoms'
import { deleteSimulations, duplicateSimulation } from '@/features/domain/simulation'
import { changeTerritory, selectTerritoryId } from '@/features/domain/territory'

import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { useNavigate } from '@/routing'

import { SingleView } from './SingleView'
import { useTexts } from './useTexts'

type Props = {
  selection: workwave.DeepReadonly<string[]>
  simulation: uui.domain.client.rm.SimulationInfo
}

export function SingleViewProvider(props: Props) {
  const { simulation, selection } = props

  const { setEditing } = useEditingStateWithCtx('simulation')
  const navigate = useNavigate(true)
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  const territoryId = useSelector(selectTerritoryId)

  const handleOnResetSelection = useCallback(() => {
    resetCrudSelection('simulations')
  }, [])

  const handleOnDeleteSimulations = useCallback(
    async (simulationIds: string[]) => {
      try {
        const result = await dispatch(deleteSimulations({ simulationIds }))

        if (!deleteSimulations.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, toast],
  )

  const handleOnDuplicateSimulation = useCallback(
    async (openAfterCreation?: boolean) => {
      try {
        const result = await dispatch(
          duplicateSimulation({
            name: texts.simulationCopyName(simulation.name),
            simulationId: simulation.id,
            openAfterCreation,
          }),
        )

        if (!duplicateSimulation.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, texts, toast, simulation],
  )

  const handleOnOpenSimulation = useCallback(
    async (simulationId: string) => {
      try {
        const result = await dispatch(
          changeTerritory({
            target: 'simulation',
            simulationId,
            territoryId,
          }),
        )

        if (!changeTerritory.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        navigate('home')
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, navigate, toast, territoryId],
  )

  const handleStartEditing = useCallback(
    () => setEditing(Array.from(selection)),
    [setEditing, selection],
  )

  return (
    <SingleView
      simulation={simulation}
      startEditing={handleStartEditing}
      openSimulation={handleOnOpenSimulation}
      resetSelection={handleOnResetSelection}
      deleteSimulations={handleOnDeleteSimulations}
      duplicateSimulation={handleOnDuplicateSimulation}
    />
  )
}
