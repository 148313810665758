import styled from 'styled-components'

const TransparentButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
`

TransparentButton.displayName = 'TransparentButton'
export default TransparentButton
