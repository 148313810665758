import type { MutableRefObject } from 'react'
import type OlMap from 'ol/Map'
import type { FormErrors, FormFields } from '../../../formFields'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Stack, Typography, Button } from '@mui/material'
import { TipsAndUpdates } from '@mui/icons-material'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { TextField } from '@workwave-tidal/tidal/form-ui'

import { selectUserProfile } from '@/features/domain/user'
import { gis } from '@/server-data'

import { convertMetersToFeet } from '@/local/server-data/utils/smartUtils/conversion'
import { useControllerById } from '../../../../../hooks/useController'
import { useTexts } from '../../../../../useTexts'

type Props = {
  map: MutableRefObject<OlMap | undefined>
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery'
}

const MAX_DISTANCE_IN_METERS = 100

function getFieldLabel(
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery',
  texts: ReturnType<typeof useTexts>,
) {
  switch (pinId) {
    case 'depotFormPin':
      return texts.depotFieldLabel

    case 'orderForm_pickup':
      return texts.pickupFieldLabel

    case 'orderForm_service':
      return texts.serviceFieldLabel

    case 'orderForm_delivery':
      return texts.deliveryFieldLabel

    case 'geofenceFormPin':
    case 'placeFormPin':
      return ''
  }
}

export function Data(props: Props) {
  const { pinId } = props

  // Get the texts
  const texts = useTexts()

  // Get the distance format from the user profile
  const { distanceFormat } = useSelector(selectUserProfile).account.preferences

  // get the center from the controller
  const {
    data: { center, proposedLabel },
  } = useControllerById(pinId)

  // get the newLocation field
  const {
    field: { value: newLocation },
    fieldApi,
  } = useFormField<'newLocation', FormFields, FormErrors>('newLocation')

  // get the label field
  const { fieldApi: labelFieldApi } = useFormField<'label', FormFields, FormErrors>('label')

  // calculate the distance between the center and the newLatLng
  const distanceInMeters = gis.distanceBetween(center, newLocation?.latLng)

  // convert the distance to miles
  const distanceInMi = convertMetersToFeet(distanceInMeters)

  // if the distance is greater than MAX_DISTANCE_IN_METERS, we want to show the alert
  const outOfRange = distanceInMeters > MAX_DISTANCE_IN_METERS

  // if the proposed label is empty, we don't want to change the label
  const labelsAreDifferent = !!proposedLabel && newLocation.address !== proposedLabel

  // Set the label to the proposed label
  const useThisAddress = useCallback(() => {
    // if the proposed label is empty, we don't want to change the label
    if (!proposedLabel) return

    labelFieldApi.change(proposedLabel)
    fieldApi.change({ ...newLocation, address: proposedLabel })
  }, [fieldApi, labelFieldApi, newLocation, proposedLabel])

  // Declare field label
  const fieldLabel = getFieldLabel(pinId, texts)

  return (
    <Stack spacing={4}>
      <Stack spacing={0}>
        {outOfRange && (
          <Alert severity="warning" data-testid="location-editor-modal_out-of-range-warning">
            <Typography>
              {texts.outOfRange(
                distanceFormat === 'IMPERIAL'
                  ? distanceInMi.toFixed(0)
                  : distanceInMeters.toFixed(0),
                distanceFormat === 'IMPERIAL' ? texts.feets : texts.meters,
              )}
            </Typography>
          </Alert>
        )}

        {labelsAreDifferent && (
          <Stack spacing={0}>
            <Alert
              severity="info"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={useThisAddress}
                  data-testid="location-editor-modal_use-proposed-address"
                  data-trackid="location-editor-modal_use-proposed-address"
                >
                  {texts.useThisAddress}
                </Button>
              }
              icon={<TipsAndUpdates />}
              data-testid="location-editor-modal_different-address-alert"
            >
              <Typography
                fontWeight={600}
                data-testid="location-editor-modal_different-address-message"
              >
                {texts.differentAddress}
              </Typography>
              <Typography data-testid="location-editor-modal_proposed-address">
                {proposedLabel}
              </Typography>
            </Alert>
          </Stack>
        )}

        <Typography
          color="text"
          fontSize="small"
          data-testid="location-editor-modal_helper-text"
          mt={1}
        >
          {texts.indicatesAddress}
        </Typography>
      </Stack>

      <Stack>
        <TextField<'label', FormFields, FormErrors>
          testId="location-editor-modal_label"
          helperText={texts.nameImpact}
          validateOn="blur focus"
          label={fieldLabel}
          name="label"
        />
      </Stack>
    </Stack>
  )
}
