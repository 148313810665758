import styled from 'styled-components'

const styledComponent = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70px;
  background: ${p => p.theme.colors.$whiteSmoke};
  padding: 4px 16px;
  margin-bottom: 8px;
  justify-content: center;
`

styledComponent.displayName = 'ColumnHeader'
export default styledComponent
