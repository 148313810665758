import { useNavigoView } from '../../../hooks/useNavigoView'
import { useDepotsNavigator } from '../../../hooks/useDepotsNavigator'

export const useMapStyle = () => {
  const {
    navigate: { goToOverview },
  } = useNavigoView()
  const { currentDepots } = useDepotsNavigator()

  return {
    currentDepots,
    goToOverview,
  }
}
