import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isBefore, parse } from 'date-fns/esm'

import { selectTerritoryLicensingLimits } from '@/features/domain/territory'
import { selectSimulationsList } from '@/features/domain/simulation'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'
import { useTransactionInProgress } from '@/components/smartUtils/plan/TransactionInProgressTooltip'

export function useSingleEditSimulationData(simulation?: uui.domain.client.rm.SimulationInfo) {
  const now = new Date()

  const userConfiguration = useSelector(selectUserConfiguration)
  const calendarSelection = useSelector(selectCalendarRangeInterval)
  const licensingLimits = useSelector(selectTerritoryLicensingLimits)
  const sims = useSelector(selectSimulationsList)

  const today = parse(userConfiguration.today, 'yyyyMMdd', now)
  const firstDayNotArchived = parse(userConfiguration.startFromDate, 'yyyyMMdd', now)
  const isSelectingArchivedDate = isBefore(calendarSelection.start, firstDayNotArchived)

  const { readonly } = useTransactionInProgress()

  const simulationNames = useMemo(() => {
    return sims.reduce<string[]>((acc, sim) => {
      if (sim.id !== simulation?.id) {
        acc.push(sim.name)
      }
      return acc
    }, [])
  }, [sims, simulation])

  return {
    today,
    readonly,
    licensingLimits,
    simulationNames,
    calendarSelection,
    firstDayNotArchived,
    isSelectingArchivedDate,
  }
}
