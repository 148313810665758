import { Box } from '@mui/material'

interface Props {
  width?: number
}

// this icon is hard-coded so it will be much easier to port from the codebase
export function Icon(props: Props) {
  return (
    <Box width={props.width ?? 20} display="flex" alignItems="center">
      <svg viewBox="0 0 50 50">
        <path
          style={{ fill: 'currentcolor' }}
          d="M44.98438 12.96875c-.53126.01563-1.03126.23438-1.39844.6172L25 32.17187 6.41406 13.58593c-.3789-.38672-.89453-.60547-1.4375-.60547-.8125 0-1.54297.4961-1.84765 1.25-.3086.7539-.125 1.6172.45705 2.1836l20 20c.78125.78124 2.04687.78124 2.82812 0l20-20c.59375-.57032.78125-1.44923.46094-2.21094-.3164-.76172-1.0664-1.25-1.89062-1.23438z"
        />
      </svg>
    </Box>
  )
}
