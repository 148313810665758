import CircleStyle from 'ol/style/Circle'
import { Fill, Stroke, Style } from 'ol/style'

const blackStroke = new Stroke({ color: [0, 0, 0, 1] })
const blackFill = new Fill({ color: [0, 0, 0, 1] })

export const geofenceCenterStyle = new Style({
  image: new CircleStyle({
    fill: blackFill,
    stroke: blackStroke,
    radius: 4,
  }),
})
