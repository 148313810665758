import { Box, Skeleton, Stack } from '@mui/material'
import { NavigoContainer } from '../../elements/NavigoContainer'

export function BreadcrumbsLoading() {
  return (
    <NavigoContainer data-trackid="navigo-breadcrumbs" data-testid="navigo-breadcrumbs">
      <Box height={46} display="flex" alignItems="center">
        <Skeleton variant="circular" width={40} height={40} />

        <Stack marginLeft={2} width={260}>
          <Skeleton height={14} width="50%" />
          <Skeleton height={14} />
          <Skeleton height={14} />
        </Stack>
      </Box>
    </NavigoContainer>
  )
}
