import { CircularProgress } from '@mui/material'
import { NestedDropdown, DropdownMenuItem } from '@/components/DropdownMenu'
import { StrikedEye } from '@/icons'

import { ActiveIcon } from '../ActiveIcon'
import { useTexts } from './useTexts'
import { useDevicesMapStyle } from './hooks/useDevicesMapStyle'
import { useIsEnabled } from './hooks/useIsEnabled'
import { useActions } from './hooks/useActions'

interface Props {
  parentOpen: boolean
}

export function DevicesSubMenu(props: Props) {
  const { parentOpen } = props

  const {
    methodInExecution,
    actions: { showDevicesWithLabels, showDevices, hideDevices },
  } = useActions()
  const activeStyle = useDevicesMapStyle()

  const isEnabled = useIsEnabled()
  const texts = useTexts()

  const tooltipTitle =
    isEnabled === true ? undefined : isEnabled === 'notToday' ? texts.notToday : texts.noDevices

  return (
    <NestedDropdown
      placement="left"
      parentOpen={parentOpen}
      tooltipTitle={tooltipTitle}
      disabled={isEnabled !== true}
      triggerContent={texts.devices}
      Icon={
        isEnabled === true && activeStyle === 'off' ? (
          <StrikedEye size={12} color="$silver" />
        ) : undefined
      }
    >
      <DropdownMenuItem
        external
        onClick={showDevicesWithLabels}
        Icon={
          methodInExecution === 'showDevicesWithLabels' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'label'} />
          )
        }
      >
        {texts.onWithLabel}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={showDevices}
        Icon={
          methodInExecution === 'showDevices' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'on'} />
          )
        }
      >
        {texts.on}
      </DropdownMenuItem>

      <DropdownMenuItem
        external
        onClick={hideDevices}
        Icon={
          methodInExecution === 'hideDevices' ? (
            <span style={{ marginRight: 10 }}>
              <CircularProgress size={12} color="primary" />
            </span>
          ) : (
            <ActiveIcon active={activeStyle === 'off'} />
          )
        }
      >
        {texts.off}
      </DropdownMenuItem>
    </NestedDropdown>
  )
}
