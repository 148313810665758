import { type ChangeEvent, useCallback } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Search, Close } from '@mui/icons-material'

import { useTexts } from './hooks/useTexts'

interface Props {
  companiesFilter: string
  onChangeCompaniesFilter: (search: string) => void
}

export function Header(props: Props) {
  const { companiesFilter, onChangeCompaniesFilter } = props
  const texts = useTexts()

  const handleOnSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChangeCompaniesFilter(e.target.value),
    [onChangeCompaniesFilter],
  )

  const onCancel = useCallback(() => onChangeCompaniesFilter(''), [onChangeCompaniesFilter])
  const hideResetButton = companiesFilter === ''

  return (
    <TextField
      id="filter-companies"
      data-testid="filter-companies"
      placeholder={texts.placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" hidden={hideResetButton}>
            <IconButton onClick={onCancel} size="small">
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="standard"
      onChange={handleOnSearch}
      value={companiesFilter}
      sx={{
        width: 220,
      }}
    />
  )
}
