import styled from 'styled-components'

import { DurationStep } from '@/forms-legacy'

const styledComponent = styled(DurationStep).attrs({
  className: 'o-time-picker',
})`
  border-color: ${p => p.theme.colors.$pureBlack};
`
styledComponent.displayName = 'DurationStep'
export default styledComponent
