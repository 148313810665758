export type SegmentType = 'blocked' | 'delayed' | 'disabled'

export function getTypeColor(type: SegmentType) {
  switch (type) {
    case 'blocked':
      return '#fa603f'

    case 'delayed':
      return '#ff9500'

    case 'disabled':
      return '#c3c3c3'
  }
}
