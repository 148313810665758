import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    searchLoad: translate({ id: 'order.form.bulk.tags.searchLoad' }),
    noOptionsText: translate({ id: 'order.form.bulk.loads.noOptionsText' }),
    addOptionLabel: (label: string) =>
      translate({ id: 'order.form.bulk.loads.addLoad.addOptionLabel', values: { label } }),
  }))

  return api
}
