import type { RadioProps as Props } from '@mui/material'

import { withStyles } from '@mui/styles'
import { Radio as MUIRadio } from '@mui/material'

import { RadioIcon } from './RadioIcon'

const StyledButton = withStyles({
  root: {
    padding: '0',
  },
})(MUIRadio)

export function Radio(props: Props) {
  const { disabled } = props

  return (
    <StyledButton
      {...props}
      disableRipple
      icon={<RadioIcon disabled={disabled} />}
      checkedIcon={<RadioIcon disabled={disabled} checked />}
    />
  )
}
