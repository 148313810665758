import { intl } from '@/intl'

export const getFromOperationsText = (): string =>
  intl.translate({ id: 'simualation.create.calendar.fromOperation' })

export const getFromScratchText = (): string =>
  intl.translate({ id: 'simualation.create.calendar.fromScratch' })

export const getFromArchivedText = (): string =>
  intl.translate({ id: 'simualation.create.calendar.fromArchived' })
