import { PureComponent } from 'react'
import { Field as FinalFormField } from 'react-final-form'
import { isDeepEqual } from '@/server-data'

import BarcodesField from './BarcodesField'
import { PublicProps as Props, FieldRenderProps } from './typings'

export default class BarcodesFieldWrapper extends PureComponent<Props> {
  render() {
    const { name, ...props } = this.props

    return (
      <FinalFormField
        {...props}
        name={name}
        isEqual={isDeepEqual}
        render={(formProps: FieldRenderProps) => {
          return <BarcodesField {...this.props} formProps={formProps} />
        }}
      />
    )
  }
}
