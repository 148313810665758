import { Stack } from '@mui/material'
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material'
import { ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useControllerActions } from '../../../hooks/useControllerActions'
import { useTexts } from '../../../hooks/useTexts'
import { Footer } from './Footer'

export function ConfigureFormatFooter() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const {
    close,
    status,
    invalid,
    data: { customizeColumns, multiTerritory, columnsState },
  } = useController()

  return (
    <Footer>
      {multiTerritory ? (
        <ModalFooterButton
          onClick={handlers.onGoToTerritoriesSelection}
          testid="export-route-prev-button"
        >
          <KeyboardArrowLeft />
          {texts.prevStepButton}
        </ModalFooterButton>
      ) : (
        <div />
      )}
      <Stack gap={1} direction="row">
        <ModalFooterButton testid="export-route-cancel-button" onClick={close}>
          {texts.cancelButton}
        </ModalFooterButton>
        {customizeColumns ? (
          <ModalFooterButton
            variant="contained"
            testid="export-route-go-to-column-selection"
            onClick={handlers.onGoToColumnSelection}
            loading={columnsState.status === 'loading'}
          >
            {texts.nextStepButton}
            <KeyboardArrowRight />
          </ModalFooterButton>
        ) : (
          <ModalFooterButton
            disabled={invalid || status !== 'ready'}
            onClick={handlers.onLegacyExport}
            variant="contained"
            loading={handlers.isLoading}
            testid="export-routes-export-button"
          >
            {texts.exportButton}
          </ModalFooterButton>
        )}
      </Stack>
    </Footer>
  )
}
