import { useCallback } from 'react'

export function useActions(
  onUpdateTimeWindow: (timeWindow: uui.domain.rm.TimeWindow, timeWindowIndex: 0 | 1) => void,
  onRemoveTimeWindow: (timeWindowIndex: 0 | 1) => void,
) {
  const onUpdateTimeWindow1 = useCallback(
    (timeWindow: uui.domain.rm.TimeWindow) => {
      onUpdateTimeWindow(timeWindow, 0)
    },
    [onUpdateTimeWindow],
  )

  const onRemoveTimeWindow1 = useCallback(() => {
    onRemoveTimeWindow(0)
  }, [onRemoveTimeWindow])

  const onUpdateTimeWindow2 = useCallback(
    (timeWindow: uui.domain.rm.TimeWindow) => {
      onUpdateTimeWindow(timeWindow, 1)
    },
    [onUpdateTimeWindow],
  )

  const onRemoveTimeWindow2 = useCallback(() => {
    onRemoveTimeWindow(1)
  }, [onRemoveTimeWindow])

  return {
    onUpdateTimeWindow1,
    onRemoveTimeWindow1,
    onUpdateTimeWindow2,
    onRemoveTimeWindow2,
  }
}
