import { useRef } from 'react'

import { BarcodesStatusField, BarcodeStatusOption } from '@/forms-legacy'

import * as OrderTexts from '../../../intl'
import * as Texts from '../../../../../../intl'

import { Props } from '../typings'

const getOptions = (): BarcodeStatusOption[] => {
  const obj: Record<uui.domain.client.rm.BarcodePod['barcodeStatus'], BarcodeStatusOption> = {
    SCANNED: {
      id: 'SCANNED',
      label: Texts.getBarcodesStatusText('SCANNED'),
      kind: 'item',
    },
    MISSING_BARCODE: {
      id: 'MISSING_BARCODE',
      label: Texts.getBarcodesStatusText('MISSING_BARCODE'),
      kind: 'item',
    },
    MISSING_PACKAGE: {
      id: 'MISSING_PACKAGE',
      label: Texts.getBarcodesStatusText('MISSING_PACKAGE'),
      kind: 'item',
    },
    UNREADABLE: {
      id: 'UNREADABLE',
      label: Texts.getBarcodesStatusText('UNREADABLE'),
      kind: 'item',
    },
    UNSCANNED: {
      id: 'UNSCANNED',
      label: Texts.getBarcodesStatusText('UNSCANNED'),
      kind: 'item',
    },
  }
  return Object.values(obj)
}

export const PodBarcodes = (props: Props) => {
  const { accountPreferences } = props

  const options = useRef<BarcodeStatusOption[]>(getOptions())

  return (
    <BarcodesStatusField
      name="formPods.barcodes"
      label={OrderTexts.getBarcodesFieldText()}
      wrappedInputProps={{
        accountPreferences,
        options: options.current,
      }}
    />
  )
}
