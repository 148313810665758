import type { BreadcrumbData } from './types'

import { useResetOnUnmount } from '../../hooks/useResetOnUnmount'
import { NavigoContainer } from '../../elements/NavigoContainer'

import { useBreadcrumbsNavigator } from './hooks/useBreadcrumbsNavigator'

import { BreadcrumbNavigator } from './components/BreadcrumbNavigator'
import { Overview } from './views/Overview'

interface Props {
  id: string
  breadcrumbData: BreadcrumbData
}

export function Breadcrumbs(props: Props) {
  const { breadcrumbData, id } = props

  const { chips, page, currentPoint, entitiesToShow } = useBreadcrumbsNavigator(breadcrumbData, id)
  useResetOnUnmount()

  return (
    <NavigoContainer data-trackid="navigo-breadcrumbs" data-testid="navigo-breadcrumbs">
      <BreadcrumbNavigator chips={chips} page={page} entitiesToShow={entitiesToShow} />
      <Overview currentPoint={currentPoint} />
    </NavigoContainer>
  )
}
