import { useSelector } from 'react-redux'

import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectPhysicalDevices } from '@/features/domain/device'
import { selectUserConfiguration, selectUserProfile } from '@/features/domain/user'

import { useIsCourier } from '../../../../../hooks/useIsCourier'

export function useRmViewerLinks() {
  const physicalDevices = useSelector(selectPhysicalDevices)
  const calendarRange = useSelector(selectCalendarRangeInterval)
  const { planType } = useSelector(selectUserConfiguration)
  const userProfile = useSelector(selectUserProfile)
  const courier = useIsCourier()

  // A viewer user could be enabled to GPS or not, so we got to check if is enabled
  const gpsEnabled = userProfile.account.licensingLimits.allowGps

  const canShow = {
    tracking: gpsEnabled && planType !== 'simulation',
    reports: !courier && gpsEnabled && physicalDevices.length > 0 && planType !== 'simulation',
  }

  return {
    canShow,
    calendarRange,
  }
}
