import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    back: translate({ id: 'global.back' }),
    submitTitle: (valid: boolean, planLocked: boolean): string => {
      if (!valid)
        return translate({
          id: 'traffic.form.submitButton.disabled.title.invalid',
        })

      if (planLocked)
        return translate({
          id: 'traffic.form.submitButton.disabled.title.planLocked',
        })

      return ''
    },
    add: (recurrenceAlreadySet: boolean, recurrence: string) => {
      if (recurrenceAlreadySet)
        return translate({
          id: 'setup.traffic.trafficProfile.openBtnText',
          values: { recurrence },
        })
      return translate({ id: 'setup.traffic.trafficProfile.createBtnTextNoDate' })
    },
  }))

  return api
}
