import { useMemo } from 'react'
import { Box, Paper } from '@mui/material'
import { useMouseDrag } from '@/local/hooks'
import { Body } from './Body'

interface Props {
  close: () => void
}

const modalWidth = 900
const modalHeight = 700

export function Root(props: Props) {
  const { close } = props
  const [{ offsetX, offsetY }, dragging, onMouseDown] = useMouseDrag(true)

  // Prevent the modal from being dragged outside the viewport
  const transform = useMemo(() => {
    // Retrieve viewport size
    const { innerWidth, innerHeight } = window

    // Calculate the maximum and minimum offset
    const maxX = innerWidth / 2 - modalWidth / 2
    const maxY = innerHeight / 2 - modalHeight / 2

    const minX = -maxX
    const minY = -maxY

    // Calculate the final position
    const x = Math.min(Math.max(offsetX, minX), maxX)
    const y = Math.min(Math.max(offsetY, minY), maxY)

    return `translate(${x}px, ${y}px)`
  }, [offsetX, offsetY])

  return (
    <Box
      position="fixed"
      top={`calc(50% - ${modalHeight}px / 2)`}
      left={`calc(50% - ${modalWidth}px / 2)`}
      sx={{ transform, willChange: 'transform' }}
    >
      <Paper
        elevation={10}
        onMouseDown={onMouseDown}
        style={{ cursor: dragging ? 'grabbing' : 'grab' }}
      >
        <Body close={close} />
      </Paper>
    </Box>
  )
}
