import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

interface Payload {
  placeIds: string[]
  emailAddress: string
}

/**
 * Export places
 *
 * @private
 */
export const exportPlaces = createAsyncThunk<
  // Return type of the placeIds creator
  void,
  // First argument to the placeIds creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/place/export', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/place/export', {
      category: 'rpc',
      type: 'rpc/place/export',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {exportPlace}`,
      { tags: ['rpc', 'place'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Export place failed`,
      error,
    })
  }
})
