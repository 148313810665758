import type { ReactElement } from 'react'

import { Typography, Box } from '@mui/material'

interface Props {
  title: ReactElement | string
  subtitle: ReactElement | string
  testId?: string
}

export function FormHeader(props: Props) {
  const { testId } = props
  return (
    <Box paddingBottom={4} paddingLeft={4} paddingRight={4} component="header" data-testid={testId}>
      <Typography component="h1" variant="h5" sx={{ color: 'text.primary' }}>
        {props.title}
      </Typography>
      <Typography variant="caption">{props.subtitle}</Typography>
    </Box>
  )
}
