import { Component, MouseEventHandler } from 'react'

import { Right } from '@/icons'

import { DropdownItem } from '../typings'
import DropDownButton from './DropDownButton'
export interface Props {
  values: DropdownItem[]
  selectedIds: string[]
  onClick: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

export default class NextButton extends Component<Props> {
  render() {
    const { values, selectedIds, disabled: dropDowndisabled, onClick } = this.props

    const noValues: boolean = values.length === 0
    const isLast: boolean =
      !!selectedIds[0] &&
      !!values[values.length - 1] &&
      values[values.length - 1].id === selectedIds[0]
    const disabled = dropDowndisabled || noValues || isLast

    return <DropDownButton type={'button'} icon={<Right />} disabled={disabled} onClick={onClick} />
  }
}
