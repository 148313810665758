import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    edit: translate({ id: 'global.edit' }),
    bulkEdit: translate({ id: 'global.bulkEdit' }),
    newGeofence: translate({ id: 'rm.contextual.depot.newGeofence' }),
    shareLocation: translate({ id: 'rm.contextual.depot.shareLocation' }),
    disabledNoToday: translate({
      id: 'rm.contextual.depot.shareLocation.disabledReason.noToday',
    }),
    disabledNoDriverAssignments: translate({
      id: 'rm.contextual.depot.shareLocation.disabledReason.noDriverAssigments',
    }),
    disabledForBeta: translate({
      id: 'global.disabledForBeta',
    }),
  }))

  return api
}
