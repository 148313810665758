import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderStepIdsInRange, selectOrderSteps } from '@/features/domain/order'
import { selectSnapshotsByCalendarRange } from '@/features/domain/routeplan'
import { selectCurrentRevision } from '@/features/domain/history'

type Result = { importedOrderStepIds: string[]; lowAccuracyOrderStepIds: string[] }

export function useData(startRevision: number) {
  const snapshotsByCalendarRange = useSelector(selectSnapshotsByCalendarRange)
  const orderStepsIdInRange = useSelector(selectOrderStepIdsInRange)
  const currentRevision = useSelector(selectCurrentRevision)
  const orderSteps = useSelector(selectOrderSteps)

  return useMemo(() => {
    const startOrders = snapshotsByCalendarRange[startRevision]?.order.orders ?? []
    const currentOrders = snapshotsByCalendarRange[currentRevision]?.order.orders ?? []

    return currentOrders.reduce<Result>(
      (acc, orderStepId) => {
        if (startOrders.includes(orderStepId) || !orderStepsIdInRange[orderStepId]) return acc

        acc.importedOrderStepIds.push(orderStepId)

        const extOrderStep = orderSteps[orderStepId]
        if (
          ['LOW_ACCURACY', 'POSTCODE_ISSUE', 'POSTCODE_LEVEL_ACCURACY'].includes(
            extOrderStep.location.status,
          )
        ) {
          acc.lowAccuracyOrderStepIds.push(orderStepId)
        }

        return acc
      },
      { importedOrderStepIds: [], lowAccuracyOrderStepIds: [] },
    )
  }, [orderStepsIdInRange, orderSteps, snapshotsByCalendarRange, startRevision, currentRevision])
}
