import { useSnapshot } from 'valtio'

import { updateLocationPin } from '../core/updateLocationPin'
import { locationPin } from '../locationPin'

/**
 * Get a location pin and subscribe to its updates.
 */

export function useLocationPin(
  id: uui.domain.ui.map.LocationPinId,
  sync: boolean = false,
): [uui.domain.ui.map.LocationPin | undefined, typeof updateLocationPin] {
  const targetPin = useSnapshot(locationPin, { sync }).pins[id]

  return [targetPin, updateLocationPin]
}
