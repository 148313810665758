import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../../renderingQueue'
import { findDeviceEventMarker } from '../findDeviceEventMarker'
import { updateDeviceEventPointsFeature } from './updateDeviceEventPointsFeature'

const nonblockingRender = createNonBlockingRenderByTime()

export async function mapStylesUpdateDeviceEventPointsFeatures(
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.DeviceEvents>,
  selection: Set<string>,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  const features = layer.getSource()?.getFeatures() ?? []

  nonblockingRender.reset()

  for (let index = 0; index < features.length; index++) {
    await nonblockingRender.next()

    const feature = features[index]

    if (!feature) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Trying to update DeviceEvent marker style but no feature exist at index ${index}`,
        )
      }

      continue
    }

    const featureId = feature.getId() as string | undefined

    if (!featureId) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Trying to update DeviceEvent marker style but the feature found at index ${index} has no ID`,
        )
      }

      continue
    }

    const markerId = featureId.split('@')[0]
    const marker = findDeviceEventMarker(markers, markerId)

    if (!marker) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Trying to update DeviceEvent marker style but the feature found at index ${index} has no ID`,
        )
      }

      continue
    }

    const customMode = marker.mode

    // do not process marker with custom mode
    // custom mode is are managed through a marker changelog
    if (customMode) continue

    const point = marker.events[featureId]
    updateDeviceEventPointsFeature(feature, point, selection.has(featureId), breadcrumbTimeRange)
  }
}
