import { ExecutionEventsValidation, ExecutionEventsFormValues } from './typings'
import {
  getExecutionEventsTimeInOutFieldText,
  getValidationExecutionEventsTimeOutAcrossDayEndText,
  getValidationExecutionEventsTimeOutOverlappingText,
  getInvalidLoadValueText,
} from './intl'

export const validateForm =
  (workingDayStartSec: number) =>
  (formValues: ExecutionEventsFormValues): ExecutionEventsValidation => {
    const { timeInSec, timeOutSec, dynamicLoads } = formValues

    const validation: ExecutionEventsValidation = {}

    if (timeInSec >= 0 && timeOutSec >= 0) {
      if (
        ((timeInSec < workingDayStartSec && timeOutSec <= workingDayStartSec) ||
          (timeInSec > workingDayStartSec && timeOutSec >= workingDayStartSec)) &&
        timeOutSec < timeInSec
      ) {
        validation.timeInOut = getValidationExecutionEventsTimeOutOverlappingText()
        validation.timeOutSec = ' '
      }

      if (
        workingDayStartSec > 0 &&
        timeInSec <= workingDayStartSec &&
        timeOutSec > workingDayStartSec
      ) {
        validation.timeInOut = getValidationExecutionEventsTimeOutAcrossDayEndText()
        validation.timeOutSec = ' '
      }
    }

    Object.entries(dynamicLoads ?? {}).reduce((acc, [key, value]) => {
      if (value.disabled) return acc

      if (typeof value.value !== 'number' || value.value < 0) {
        acc[key] = getInvalidLoadValueText()
      }
      return acc
    }, validation)

    return validation
  }

export const getFieldsWithError = (errors: ExecutionEventsValidation): string[] => {
  const fieldsWithError: string[] = []

  if (errors.timeInOut) {
    fieldsWithError.push(getExecutionEventsTimeInOutFieldText())
  }

  return fieldsWithError
}
