import type { GeofenceFormValues } from './types'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { useEditingStateWithCtx, useEnvironmentQa, useTransactionState } from '@/atoms'
import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'

import { selectUserConfiguration } from '@/features/domain/user'
import { useStopEditingOnUnmount } from '@/hooks'
import { usePendingFitMapOnMount } from '@/map'

import { FormLoading } from '@/components/FormLoading'
import { useComposeFieldsWithError } from './hooks/useComposeFieldsWithError'
import { computeFormInitialValues } from './utils/computeFormInitialValues'
import { useValidateForm } from './hooks/useValidateForm'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'
import { Form } from './Form/Form'
import { Footer } from './Footer'

interface Props {
  geofence: uui.domain.client.gps.wwgps.Geofence
  creating?: boolean
  loading: boolean
}

export function SingleEdit(props: Props) {
  const { geofence, creating } = props
  const { resetEditing } = useEditingStateWithCtx('geofence')
  const { distanceFormat } = useSelector(selectUserConfiguration)
  const { onCreate, onUpdate, submitting } = useOnSubmit()

  const [initialValues] = useState<GeofenceFormValues>(() =>
    computeFormInitialValues(geofence, distanceFormat),
  )

  const validateForm = useValidateForm()
  const composeFieldsWithError = useComposeFieldsWithError()

  const { mode } = useTransactionState()
  const texts = useTexts()

  const qa = useEnvironmentQa()

  // When the Edit form mounts
  // try to apply pending fitMap requests
  usePendingFitMapOnMount(true)

  useStopEditingOnUnmount()

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <FinalForm<GeofenceFormValues>
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={creating ? onCreate : onUpdate}
        render={(formRenderProps: FormRenderProps<GeofenceFormValues>) => {
          const transactionOpen = !props.loading
          const { handleSubmit, form } = formRenderProps

          const { valid, validating, pristine: rawPristine, errors = {} } = form.getState()

          const pristine = creating ? false : rawPristine

          const planLocked = mode === 'external'

          const saveDisabled = !valid || validating || planLocked || submitting || pristine
          const fieldsWithError = composeFieldsWithError(errors)

          return (
            <>
              <SecondaryColumnHeader
                description={texts.headerDescription}
                title={texts.headerTitle}
                avatar={null}
              />
              <FormContent
                qa={qa}
                testId="geofenceSingleEdit"
                footer={
                  transactionOpen ? (
                    <Footer
                      fieldsWithError={fieldsWithError}
                      saveDisabled={saveDisabled}
                      planLocked={planLocked}
                      onSubmit={handleSubmit}
                      onCancel={resetEditing}
                      submitting={submitting}
                      creating={creating}
                      pristine={pristine}
                      errors={errors}
                      valid={valid}
                    />
                  ) : undefined
                }
              >
                {transactionOpen ? <Form /> : <FormLoading />}
              </FormContent>
            </>
          )
        }}
      />
    </FormColumn>
  )
}
