import { useSelector } from 'react-redux'
import { Text } from '@/local/components'
import { selectDrivers } from '@/features/domain/driver'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

export function DriverName(props: Props) {
  const { extOrder } = props
  const drivers = useSelector(selectDrivers)

  const driverId = !extOrder.isUnassigned ? extOrder.driverId : undefined
  if (!driverId) return null

  const extDriver = drivers[driverId]
  if (!extDriver) return null

  return (
    <Text size="$s" color="$shadyLady">
      {extDriver.driver.name}
    </Text>
  )
}
