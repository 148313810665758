import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    cancel: translate({ id: 'global.cancel' }),
    title: translate({ id: 'app.header.logout' }),
    logout: translate({ id: 'app.header.logout' }),
    logoutError: translate({ id: 'app.header.logout.error' }),
    subtitle: translate({ id: 'app.header.logout.header.subtitle' }),
    cardTitle: translate({ id: 'app.header.logout.warning.title' }),
    cardDescription: translate({ id: 'app.header.logout.warning.description' }),
  }))

  return api
}
