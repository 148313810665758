import { Text } from '@/local/components'
import { VerticalLayout } from '@/components/layout'

import { useTexts } from '../useTexts'

interface Props {
  depots: uui.domain.client.rm.Depot[]
}

export function Bulk(props: Props) {
  const { depots } = props
  const texts = useTexts()

  return (
    <VerticalLayout justifyContent="space-between">
      <Text size="$l" weight="$semiBold">
        {texts.depots(depots.length)}
      </Text>

      <Text size="$p3" italic>
        {texts.multipleValues}
      </Text>
    </VerticalLayout>
  )
}
