import type { RecurrencesMap } from '../../typings'

import { isSameDay } from 'date-fns/esm'

import { Calendar } from '@/components/Calendar'
import { useMount } from '@/local/hooks'

import { useDateCalendarProps } from './useDateCalendarProps'

type Props = {
  recurrenceMap: RecurrencesMap
  onChange: (date: Date) => void
  date: Date | null
}

export function DateCalendar(props: Props) {
  const { recurrenceMap, onChange, date } = props
  const calendarProps = useDateCalendarProps(recurrenceMap, onChange, date)

  const { selection } = calendarProps

  useMount(() => {
    if (!date && selection) {
      onChange(selection)
      return
    }
    if (date && selection && !isSameDay(date, selection)) {
      onChange(selection)
    }
  })

  return <Calendar {...calendarProps} />
}
