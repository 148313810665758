import { useState } from 'react'
import { useIntl } from '@/intl'

// TODO: These translations for the moment should remain as functions instead of values
// The issue is related to the fact that the consumer is instanced with a 500ms timeout, and it looks
// like that, in PROD env, Intl API are not ready when the hooks is triggered.
export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    resolutionWarningText: translate({ id: 'warningOverlay.resolutionMessage' }),
    browserWarningText: translate({ id: 'warningOverlay.browserMessage' }),
    browserResolutionWarningText: translate({ id: 'warningOverlay.browserResolutionMessage' }),
    unsupportedBrowserWarningText: translate({ id: 'warningOverlay.unsupportedBrowserMessage' }),
    titleText: translate({ id: 'warningOverlay.title' }),
  }))

  return api
}
