import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function NewScheduler(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M2 13c0 .5523.44772 1 1 1h5c.55228 0 1-.4477 1-1v-2c0-.5523-.44772-1-1-1H3c-.55228 0-1 .4477-1 1v2Zm0 6c0 .5523.44772 1 1 1h1c.55228 0 1-.4477 1-1v-2c0-.5523-.44772-1-1-1H3c-.55228 0-1 .4477-1 1v2ZM2 7c0 .55228.44772 1 1 1h5c.55228 0 1-.44772 1-1V5c0-.55228-.44772-1-1-1H3c-.55228 0-1 .44772-1 1v2Zm15 6c0 .5523.4477 1 1 1h3c.5523 0 1-.4477 1-1v-2c0-.5523-.4477-1-1-1h-3c-.5523 0-1 .4477-1 1v2ZM7 19c0 .5523.44772 1 1 1h13c.5523 0 1-.4477 1-1v-2c0-.5523-.4477-1-1-1H8c-.55228 0-1 .4477-1 1v2Zm5-15c-.5523 0-1 .44772-1 1v2c0 .55228.4477 1 1 1h9c.5523 0 1-.44772 1-1V5c0-.55228-.4477-1-1-1h-9Zm2 10h-2c-.5523 0-1-.4477-1-1v-2c0-.5523.4477-1 1-1h2c.5523 0 1 .4477 1 1v2c0 .5523-.4477 1-1 1Z" />
      </svg>
    </IconContainer>
  )
}
