import { renderToStaticMarkup } from 'react-dom/server'
import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'

import { store } from '@/store'
import { selectUserConfiguration } from '@/features/domain/user'

import { schedulerPreferencesAtom } from '../../schedulerPreferences'

import { DynamicLoadCell, DynamicLoadTooltip } from '../../../components/cells/DynamicLoadCell'
import { createDynamicLoadSorter } from '../sorters/dynamicLoadSorter'

export function createDynamicLoadColumn(
  id: string,
  loadName: string,
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    id,
    text: loadName,
    type: 'template',
    width,
    field: `loads.${loadName}`,
    editor: false,
    region: 'cols',
    hidden,
    template: ({ record, field, value }) => {
      const { language } = selectUserConfiguration(store.getState())
      return renderToStaticMarkup(
        <DynamicLoadCell
          record={record as ResourceModel}
          field={field}
          value={value}
          language={language}
          showAbsoluteValues={schedulerPreferencesAtom.preferences.showAbsoluteValues}
          showApprovedRoutes={schedulerPreferencesAtom.preferences.showApprovedRoutes}
        />,
      )
    },

    tooltipRenderer: ({ record, column }) => {
      const loadName = column.field.replace('dynamic-load-', '').replace('loads.', '')
      const available = record.getData(
        'available',
      ) as uui.domain.client.rm.SchedulerResource['available']

      if (!available) return ''

      const { language } = selectUserConfiguration(store.getState())

      return renderToStaticMarkup(
        <DynamicLoadTooltip
          record={record as ResourceModel}
          loadName={loadName}
          language={language}
        />,
      )
    },
    sortable: createDynamicLoadSorter(loadName),
    resizable: true,
    groupable: false,
  }
}
