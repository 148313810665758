import { DriverBehaviorField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

export function Acceleration() {
  const texts = useTexts()

  return (
    <DriverBehaviorField
      name="device.acceleration"
      testid="device.acceleration"
      label={texts.acceleration}
      labelHard={texts.gals(texts.hard)}
      labelHarsh={texts.gals(texts.harsh)}
      labelSevere={texts.gals(texts.severe)}
    />
  )
}
