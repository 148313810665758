import type { SearchableListTypes } from '@/forms-legacy'
import type { DeviceInfoItem } from '../DevicesList'

import { memo, useCallback, MouseEvent } from 'react'
import { Box } from '@mui/material'

import { Checkbox, Dot, FlexColumn, FlexRow, ReadonlyLabel } from '@/forms-legacy'
import { colorsUtils } from '@/utils'

import DeviceListItemWrapper from '../elements/DeviceListItemWrapper'
import TransparentButton from '../elements/TransparentButton'

const DeviceListItem = (props: SearchableListTypes.ListItemProps<DeviceInfoItem>) => {
  const {
    onChange,
    value,
    disabled,
    readonly,
    selected,
    value: { id, name, color },
  } = props

  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { shiftKey, metaKey, ctrlKey, altKey } = event
      onChange([value], { shiftKey, metaKey, ctrlKey, altKey })
    },
    [onChange, value],
  )

  return (
    <DeviceListItemWrapper key={id}>
      <FlexColumn primary>
        <FlexRow>
          <TransparentButton onClick={handleOnClick} disabled={disabled}>
            <Checkbox
              checked={!!selected}
              disabled={disabled || readonly}
              id={id}
              onChange={() => undefined}
              style={{ marginRight: 6, pointerEvents: 'none' }}
            />
            {name}
          </TransparentButton>
        </FlexRow>
      </FlexColumn>
      <FlexColumn>
        <ReadonlyLabel small style={{ marginRight: 6 }}>
          {id}
        </ReadonlyLabel>
      </FlexColumn>
      <FlexColumn>
        <Box width={16} height={16} margin="0 8px">
          <Dot innerColor={colorsUtils.getValidHexColor(color)} />
        </Box>
      </FlexColumn>
    </DeviceListItemWrapper>
  )
}

export default memo(DeviceListItem)
