import { useCallback } from 'react'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { useAppDispatch } from '@/store'
import { useNotification, useIsUnmounted } from '@/hooks'
import { createCompany } from '@/features/domain/company'

import { useModalsContext } from '../../../context/ModalsContextProvider'

import { type FormFields, type FormErrors } from '../formFields'

import { useController } from './useController'
import { useTexts } from './useTexts'

export function useControllerActions() {
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const { close } = useController()
  const formApi = useFormApi<FormFields, FormErrors>()
  const { onCompanyAdded } = useModalsContext()
  const texts = useTexts()

  const isUnmounted = useIsUnmounted()

  const onSubmit = useCallback(async () => {
    try {
      formApi.setSubmitting(true)

      const valid = await formApi.validate()
      if (!valid) {
        formApi.setSubmitting(false)
        return
      }

      const formValues = formApi.getValues()
      const result = await dispatch(createCompany(formValues.name))

      if (createCompany.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      toast.success(texts.toast.success)
      onCompanyAdded?.(result.payload.id)

      // if the component has been unmounted during the async operation stop here
      if (isUnmounted()) return

      // Clear FormState submitting mode
      formApi.setSubmitting(false)

      close?.()
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, close, dispatch, formApi, isUnmounted, onCompanyAdded, texts])

  return {
    onSubmit,
  }
}
