import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    text: translate({
      id: 'orders.smsNotifications.noNotifications.description.admin.btn',
    }),
    tooltip: (
      type: 'other' | 'rmOnlyAdmin' | 'rm360AdminNoNotificationsAllowed' | 'rm360Admin',
    ) => {
      switch (type) {
        case 'other':
          return translate({ id: 'orders.smsNotifications.noNotifications.tooltip.other' })

        case 'rmOnlyAdmin':
          return translate({ id: 'orders.smsNotifications.noNotifications.tooltip.rmOnlyAdmin' })

        case 'rm360AdminNoNotificationsAllowed':
          return translate({
            id: 'orders.smsNotifications.noNotifications.tooltip.rm360AdminNoNotificationsAllowed',
          })

        default:
          return ''
      }
    },
  }))

  return api
}
