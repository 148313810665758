import styled from 'styled-components'
import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from 'react'

import { styles } from './BaseInput'

export interface Props {
  type?: string
  name?: string
  value?: string | number | string[] | undefined
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  onKeyPress?: KeyboardEventHandler<HTMLTextAreaElement>
  placeholder?: string
  readOnly?: boolean
  disabled?: boolean
}

const BaseTextArea = styled.textarea<Props>`
  ${styles};
  resize: none;
  height: 100px;
  padding: 10px 0;
`

BaseTextArea.displayName = 'BaseTextArea'

export default BaseTextArea
