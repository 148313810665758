import type { RenderItemProps } from '@/components/StructuredVirtualList'

import { useMemo } from 'react'
import { parse } from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'

import { SimulationListItem } from './SimulationListItem'

const now = new Date()

export function useSimulationListItem(planId: string) {
  const { today } = useSelector(selectUserConfiguration)
  const todayDate = useMemo(() => parse(today, 'yyyyMMdd', now), [today])

  return useMemo(
    () => (props: RenderItemProps<'simulations'>) => {
      return props.type === 'item' ? (
        <SimulationListItem {...props} item={props.item} today={todayDate} planId={planId} />
      ) : null
    },
    [planId, todayDate],
  )
}
