import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { createDateFnsOptions } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'

export const useDateFnsOptions = (): uui.domain.DateFnsOptions => {
  const userConfig = useSelector(selectUserConfiguration)
  return useMemo(() => createDateFnsOptions(userConfig), [userConfig])
}
