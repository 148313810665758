import type { DriverAssignmentFormValues } from './typings'

import { useState } from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { SecondaryColumnHeader, FormContent, FormLayout } from '@/forms-legacy'
import { useFormTransaction } from '@/hooks'
import { useEnvironmentQa } from '@/atoms'
import { FormLoading } from '@/components/FormLoading'
import { FormColumn } from '@/components/layout'

import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'
import { useOnCancel } from './hooks/useOnCancel'
import { useFormRecurrence } from './hooks/useFormRecurrence'

import { AssignmentsField } from './components/AssignmentsField'
import { Footer } from './Footer'

interface Props {
  driverAssignment: uui.domain.client.rm.DriverAssignmentsWithId
}

const emptyArray = []

export function SingleEdit(props: Props) {
  const { driverAssignment } = props
  const recurrence = useFormRecurrence(driverAssignment.id)

  const onCancel = useOnCancel(recurrence.source)
  const { onSubmit, submitting } = useOnSubmit(recurrence)

  const [initialValues] = useState<DriverAssignmentFormValues>(() => ({
    assignments: driverAssignment.assignments,
  }))

  const texts = useTexts()

  const qa = useEnvironmentQa()

  const transactionState = useFormTransaction()
  const transactionOpen = transactionState === 'ready'

  const creating = Object.keys(initialValues).length === 0

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <FinalForm<DriverAssignmentFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={(formRenderProps: FormRenderProps<DriverAssignmentFormValues>) => {
          const { handleSubmit, form } = formRenderProps

          const { valid, validating, pristine } = form.getState()

          const saveDisabled = !valid || validating

          return (
            <>
              <SecondaryColumnHeader
                title={texts.headerTitle(recurrence)}
                description={texts.headerDescription(recurrence)}
                avatar={null}
              />
              <FormContent
                qa={qa}
                testId="driverAssignmentSingleEdit"
                footer={
                  transactionOpen ? (
                    <Footer
                      saveTitle={texts.saveTitle(valid, pristine)}
                      save={texts.save(recurrence, creating)}
                      errorTitle={texts.errorTitle}
                      fieldsWithError={emptyArray}
                      handleSubmit={handleSubmit}
                      saveDisabled={saveDisabled}
                      submitting={submitting}
                      cancel={texts.cancel}
                      onCancel={onCancel}
                    />
                  ) : undefined
                }
              >
                {transactionOpen ? (
                  <FormLayout>
                    <AssignmentsField />
                  </FormLayout>
                ) : (
                  <FormLoading />
                )}
              </FormContent>
            </>
          )
        }}
      />
    </FormColumn>
  )
}
