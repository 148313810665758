import { ScrollableListScrolltoIndex } from '../List'
import { TextToRender, DropdownStatus, ItemIdResolver } from './typings'

export interface State<S> {
  status: DropdownStatus
  textToRender: TextToRender<S>
  isManagingFocus: boolean
  visibleIndex: ScrollableListScrolltoIndex
}

export function getItemIndex<S, T>(
  values: T[],
  itemIdResolver: ItemIdResolver<T, S>,
  suggestedId?: S,
): ScrollableListScrolltoIndex {
  let index: number = -1
  if (suggestedId) {
    index = values.findIndex((item: T) => itemIdResolver(item) === suggestedId)
  }

  return { index }
}

export const setText = <S>(textToRender: TextToRender<S>) => {
  return () => ({ textToRender })
}

export const setVisibleIndex = (visibleIndex: ScrollableListScrolltoIndex) => {
  return () => ({ visibleIndex })
}

export const setStatus = (status: DropdownStatus) => {
  return () => ({ status })
}
