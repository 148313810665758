import styled from 'styled-components'

type Props = {
  selected?: boolean
}

const styledComponent = styled.div<Props>`
  position: relative;
  border: 1px solid ${p => p.theme.colors.$silver};
  background: ${p => p.theme.colors.$pureWhite};
  color: ${p => p.theme.colors.$pureWhite};
  z-index: 5;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    opacity: 0;
    transition: 0.3s ease;
    transition-property: background;
  }

  &::before {
    background: ${p => p.theme.colors.$alabaster};
    opacity: 1;
  }

  &::after {
    background: ${p => p.theme.colors.$nightRider};
    opacity: ${p => (p.selected ? 1 : 0)};
  }
`
styledComponent.displayName = 'RadioHack'
export default styledComponent
