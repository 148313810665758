import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { resetPendingFitMap } from '@/map'
import { useAppDispatch } from '@/store'
import { selectRoadSegmentMapStyles, updateMapStyle } from '@/features/domain/ui'
import { selectTerritoryId } from '@/features/domain/territory'

export function useEnsureRoadSegmentVisibility() {
  const dispatch = useAppDispatch()

  const territoryId = useSelector(selectTerritoryId)

  // ATTENTION: this REF is intentionally created and not updated
  const rOriginalMapStyle = useRef(useSelector(selectRoadSegmentMapStyles))

  useEffect(() => {
    // access previously active road Map Styles
    const originalMapStyle = rOriginalMapStyle.current

    // make road style visible
    dispatch(
      updateMapStyle({
        type: 'roadSegment',
        mode: 'on',
      }),
    )

    return () => {
      resetPendingFitMap()

      // restore previously active road Map Styles
      dispatch(
        updateMapStyle({
          type: 'roadSegment',
          mode: originalMapStyle.mode,
        }),
      )
    }
  }, [dispatch, territoryId])
}
