import { useRef } from 'react'
import { MenuItem } from '@mui/material'
import { Add } from '@mui/icons-material'

import {
  ListHeaderActions,
  ListHeaderPrimaryActionButton,
  ListHeaderSecondaryActionButton,
  type ListHeaderSecondaryActionRef,
} from '@/components/List'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'

interface Props {
  trafficProfileId: string
  onShowEditTrafficProfileModal: () => void
}

export function Actions(props: Props) {
  const { trafficProfileId, onShowEditTrafficProfileModal: onShowModal } = props
  const secondaryActionMenuRef = useRef<ListHeaderSecondaryActionRef>(null)

  const { editing, onCreate, onImportKML, onShowEditTrafficProfileModal, onDeleteProfile } =
    useActions(trafficProfileId, secondaryActionMenuRef.current, onShowModal)

  const texts = useTexts()

  return (
    <ListHeaderActions>
      <ReadOnlyTooltip
        render={preventEditing => (
          <Tooltip title={texts.addTrafficArea} placement="bottom">
            <ListHeaderPrimaryActionButton
              disabled={preventEditing || editing}
              onClick={onCreate}
              trackId="trafficAreas__create-new-button"
            >
              <Add />
            </ListHeaderPrimaryActionButton>
          </Tooltip>
        )}
      />
      <ReadOnlyTooltip
        render={preventEditing => (
          <ListHeaderSecondaryActionButton
            disabled={preventEditing || editing}
            ref={secondaryActionMenuRef}
          >
            <MenuItem
              data-testid="list-header-secondary-action-import-KML"
              data-trackid="list-header-secondary-action-import-KML"
              onClick={onImportKML}
            >
              {texts.importKML}
            </MenuItem>
            <MenuItem
              divider
              data-testid="list-header-secondary-show-edit-traffic-profile-modal"
              data-trackid="list-header-secondary-show-edit-traffic-profile-modal"
              onClick={onShowEditTrafficProfileModal}
            >
              {texts.profileSettings}
            </MenuItem>
            <MenuItem
              data-testid="list-header-secondary-delete-profile"
              data-trackid="list-header-secondary-delete-profile"
              sx={theme => ({
                color: theme.colorSchemes.light.palette.error.main,
              })}
              onClick={onDeleteProfile}
            >
              {texts.deleteProfile}
            </MenuItem>
          </ListHeaderSecondaryActionButton>
        )}
      />
    </ListHeaderActions>
  )
}
