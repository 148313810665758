import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    warningTitle: translate({ id: 'settings.messagingPlan.view.emailPlan.warning.title' }),
    warningDescription: translate({
      id: 'settings.messagingPlan.view.emailPlan.warning.description',
    }),
  }))

  return api
}
