import { Right, Left } from '@/icons'

import { RoundedButton } from './elements/RoundedButton'

type Props = {
  collapsed: boolean
  toggle: () => void
}

export function ToggleSidebarButton(props: Props) {
  const { toggle, collapsed } = props

  return (
    <RoundedButton onClick={toggle}>
      {collapsed ? <Right size={12} /> : <Left size={12} />}
    </RoundedButton>
  )
}
