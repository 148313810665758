import styled from 'styled-components'

const CustomFieldLabel = styled.p`
  margin: 0;
  padding: 0;

  flex: 1 0 auto;
  width: 120px;

  color: ${p => p.theme.colors.$nightRider};
  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
CustomFieldLabel.displayName = 'CustomFieldLabel'
export default CustomFieldLabel
