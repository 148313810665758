import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    rmField: (key: keyof uui.domain.ui.forms.RouteManagerVehicleDataValidation) => {
      switch (key) {
        case 'name':
          return translate({ id: 'vehicles.form.edit.generalSettings.fields.name.label' })

        case 'color':
          return translate({ id: 'vehicles.form.edit.generalSettings.fields.color.label' })

        case 'deviceId':
          return translate({ id: 'vehicles.form.generalSettings.gpsDevice' })

        default:
          return key
      }
    },

    deviceField: (key: keyof uui.domain.ui.forms.DeviceDataValidation) => {
      switch (key) {
        case 'badges':
          return translate({ id: 'vehicles.form.badges' })

        case 'braking':
          return translate({ id: 'vehicles.form.driverBehaviors.braking' })

        case 'acceleration':
          return translate({ id: 'vehicles.form.driverBehaviors.acceleration' })

        case 'cornering':
          return translate({ id: 'vehicles.form.driverBehaviors.cornering' })

        case 'idleStart':
          return translate({ id: 'vehicles.form.driverBehaviors.idleStartLimit' })

        case 'speedLimit':
          return translate({ id: 'vehicles.form.driverBehaviors.tolerance' })

        case 'maxSpeedLimit':
          return translate({ id: 'vehicles.form.driverBehaviors.maxSpeedLimit' })

        case 'maintenanceMileage':
          return translate({
            id: 'vehicles.form.edit.generalSettings.fields.maintenanceMileage.label',
          })

        default:
          return key
      }
    },
  }))

  return api
}
