import type { ModifyEditableAreaInteraction } from '../ModifyEditableAreaInteraction'

import { primaryAction } from 'ol/events/condition'
import MapBrowserEvent from 'ol/MapBrowserEvent'
import Feature from 'ol/Feature'
import { drawFeatureMetadata } from './drawFeatureMetadata'

type GetInteractionInstance = () => ModifyEditableAreaInteraction

export function createModifyCondition(getInteractionInstance: GetInteractionInstance) {
  return (event: MapBrowserEvent<UIEvent>) => {
    const instance = getInteractionInstance()

    // Check if there is a feature to select
    const map = event.map
    const features = map.getFeaturesAtPixel(event.pixel, {
      hitTolerance: 5,
    })

    if (features) {
      const modifyPointLike = features.at(0)

      if (modifyPointLike && modifyPointLike instanceof Feature) {
        const modifyPoint = modifyPointLike

        const [eventX, eventY] = event.pixel
        const closestPointCoords = modifyPoint.getGeometry()?.getClosestPoint(event.coordinate)

        if (closestPointCoords) {
          const [pointX, pointY] = map.getPixelFromCoordinate(closestPointCoords)

          const dx = eventX - pointX
          const dy = eventY - pointY

          if (Math.sqrt(dx * dx + dy * dy) > 8) {
            // NO target point feature
            instance.internal_setActiveVertex()
          } else {
            const modifiedFeature = features.find(feat => {
              return drawFeatureMetadata.isEditableArea(feat)
            }) as Feature | undefined

            // FOUND target point feature'
            instance.internal_setActiveVertex(closestPointCoords, modifiedFeature)
          }
        }
      }
    }

    return primaryAction(event)
  }
}
