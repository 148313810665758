import { useCallback } from 'react'

import { updateTerritorySettings as updateTerritorySettingsAction } from '@/features/domain/territory'

import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { useTexts } from '../components/DriverActivityForm/hooks/useTexts'

export function useUpdateDriversActivity() {
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  return useCallback(
    async (
      trackStartEnd: boolean,
      reasons: string[],
      enableDynamicLoads: boolean,
      enableDynamicCustomFields: boolean,
    ) => {
      try {
        const result = await dispatch(
          updateTerritorySettingsAction({
            reasons,
            trackStartEnd,
            enableDynamicLoads,
            enableDynamicCustomFields,
          }),
        )

        if (updateTerritorySettingsAction.rejected.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        toast.success(texts.resultOk)

        return true
      } catch (e) {
        const msg = e instanceof Error ? e.message : JSON.stringify(e)
        toast.error(msg)
      }

      return false
    },
    [dispatch, texts.resultOk, toast],
  )
}
