import { renderToStaticMarkup } from 'react-dom/server'
import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'

import { selectUserAccountPreferences, selectUserConfiguration } from '@/features/domain/user'
import { store } from '@/store'
import { intl } from '@/intl'

import { schedulerPreferencesAtom } from '../../schedulerPreferences'

import {
  ApprovedDistanceTooltip,
  ApprovedDistanceCell,
} from '../../../components/cells/ApprovedDistanceCell'
import { approvedDistanceSorter } from '../sorters/approvedDistanceSorter'

export function createApprovedDistanceColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  const state = store.getState()

  const accountPreferences = selectUserAccountPreferences(state)
  const useMetric = accountPreferences.distanceFormat === 'METRIC'

  return {
    text: useMetric
      ? intl.translate({ id: 'rm.scheduler.column.approvedDistanceMetric' })
      : intl.translate({ id: 'rm.scheduler.column.approvedDistanceImperial' }),

    type: 'template',
    width,
    field: 'approvedMileage',
    editor: false,
    hidden,
    region: 'cols',

    template: ({ record, field, value }) => {
      const userConfig = selectUserConfiguration(store.getState())

      return renderToStaticMarkup(
        <ApprovedDistanceCell
          value={value}
          field={field}
          record={record as ResourceModel}
          userConfig={userConfig}
          showAbsoluteValues={schedulerPreferencesAtom.preferences.showAbsoluteValues}
        />,
      )
    },

    sortable: approvedDistanceSorter,
    resizable: true,
    groupable: false,

    tooltipRenderer: ({ record }) => {
      const available = record.getData(
        'available',
      ) as uui.domain.client.rm.SchedulerResource['available']

      if (!available) return ''

      const state = store.getState()
      const userConfiguration = selectUserConfiguration(state)
      return renderToStaticMarkup(
        <ApprovedDistanceTooltip userConfig={userConfiguration} record={record as ResourceModel} />,
      )
    },
  }
}
