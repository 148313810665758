import type { CrudSelectionCategory } from '../typings'

import { crudAtom, createDefaultCrudSelection } from '../crud'

export const resetAllCrudSelection = (...skipCategories: CrudSelectionCategory[]) => {
  if (skipCategories.length === 0) {
    // Object.assign is required otherwise Valtio won't update the atom
    Object.assign(crudAtom.selection, createDefaultCrudSelection())
  } else {
    for (const key of Object.keys(crudAtom.selection)) {
      const category = key as CrudSelectionCategory

      if (skipCategories.includes(category)) continue

      crudAtom.selection[category] = []
    }
  }

  return crudAtom.selection
}
