import { NumericStepperField } from '@/forms-legacy'
import { useTexts } from './useTexts'

const wrappedInputProps = {
  step: 10,
  min: 0,
  max: 100000,
}

export function Radius() {
  const texts = useTexts()

  return (
    <NumericStepperField
      half
      name="radius"
      label={texts.radiusTitle}
      info={texts.radiusDescription}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
