import { useState, useEffect, useCallback } from 'react'

import { SecondaryColumnHeader, FormBulkAvatar, FormContent } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { resetCrudSelection } from '@/atoms'

import { ViewDetails } from '../../components/ViewDetails'

import { useCosts } from './hooks/useCosts'
import { useTexts } from './hooks/useTexts'

import { ActionsManager } from './components/ActionsManager'

interface Props {
  selection: workwave.DeepReadonly<string[]>
  depots: uui.domain.client.rm.Depot[]
}

export function BulkView(props: Props) {
  const { depots } = props

  const costs = useCosts(props.depots)
  const texts = useTexts()

  const [view, setView] = useState<'default' | 'delete'>(() => 'default')

  useEffect(() => {
    setView('default')
  }, [depots])

  const resetSelection = useCallback(() => resetCrudSelection('depots'), [])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={texts.headerTitle(depots.length)}
        description={texts.headerDescription}
        action={resetSelection}
        avatar={<FormBulkAvatar size={depots.length} />}
      />
      <ActionsManager view={view} setView={setView} depots={depots} />
      <FormContent footer={<div />}>
        <ViewDetails
          setupCost={costs.setupCost}
          setupTimeSec={costs.setupTimeSec}
          setupCostMixed={costs.setupCostMixed}
          setupTimeSecMixed={costs.setupTimeSecMixed}
        />
      </FormContent>
    </FormColumn>
  )
}
