import { type ReactNode } from 'react'
import { type IconButtonProps, IconButton } from '@mui/material'

export const listItemIconClasses = {
  root: 'ListItemIcon-root',
}

export type Props = {
  testId?: string
  icon: ReactNode
  onClick?: IconButtonProps['onClick']
}

export function ListItemIcon(props: Props) {
  const { icon, testId = 'ListItemIcon', onClick } = props

  return (
    <IconButton
      disableRipple
      data-testid={testId}
      className={listItemIconClasses.root}
      onClick={onClick}
      size="medium"
    >
      {icon}
    </IconButton>
  )
}
