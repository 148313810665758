import type { ValidateOn } from '../Generic/types'

import { useMemo } from 'react'

const noop = () => undefined

type Validate = (force?: boolean) => void | Promise<void>

export type UseValidateOn = {
  validateOnBlur: Validate
  validateOnFocus: Validate
  validateOnChange: Validate
}

export function useValidateOn(
  validate: (force?: boolean) => void | Promise<void>,
  validateOn?: ValidateOn,
): UseValidateOn {
  return useMemo(() => {
    return {
      validateOnBlur: validateOn?.includes('blur') ? validate : noop,
      validateOnFocus: validateOn?.includes('focus') ? validate : noop,
      validateOnChange: validateOn?.includes('change') ? validate : noop,
    }
  }, [validate, validateOn])
}
