import { FlexBox, Text } from '@/local/components'
import { Spinner } from '@/icons'

import { useTexts } from '../useTexts'

export function Loading() {
  const texts = useTexts()

  return (
    <FlexBox column vAlignContent="top" hAlignContent="center" padding="34px 32px">
      <Spinner size={24} animation="spin" />
      <Text marginTop={8} weight="$semiBold" size="$l">
        {texts.loading}
      </Text>
    </FlexBox>
  )
}
