import type { CardCosmetic } from '../../typings'
import type { ValidColor } from '@/local/components'
import { styled } from '@mui/material'
import { theme } from '@/styles'

type Props = {
  background: ValidColor
  cosmetic?: CardCosmetic
}

export const Dot = styled('span', { name: 'Dot' })<Props>(({ background, cosmetic }) => ({
  position: 'absolute',
  bottom: 0,
  left: '50%',
  width: '3px',
  height: '3px',
  borderRadius: '50%',
  transform: 'translate(-50%, -3px)',
  zIndex: 5,
  background:
    cosmetic === 'edge-of-selection'
      ? theme.colors.$pureWhite
      : theme.colors[background] || 'black',
}))
