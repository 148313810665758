import type { AsyncThunkApiConfig } from '@/store'
import type { RejectError } from '../../typings'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const importRoadSegmentsFromKML = createAsyncThunk<
  // Return type of the payload creator
  string[],
  // First argument to the payload creator
  uui.domain.server.rm.SegmentException[],
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/roadSegment/importRoadSegmentsFromKML', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/roadSegment/importRoadSegmentsFromKML', {
      category: 'rpc',
      type: 'rpc/roadSegment/importRoadSegmentsFromKML',
      roadSegments: payload,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {importRoadSegmentsFromKML}`,
      { tags: ['rpc', 'roadSegment'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Import Road Segments failed`,
      error,
    })
  }
})
