import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { selectRoadSegmentMapStyles, setCustomMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

export function useHideRoadSegmentWhileEditing(editing: boolean, roadSegmentId?: string) {
  const dispatch = useAppDispatch()

  // ATTENTION: this REF is intentionally created and not updated
  const rOriginalMapStyle = useRef(useSelector(selectRoadSegmentMapStyles))

  useEffect(() => {
    if (!roadSegmentId || !editing) return

    // access previously active roadSegment Map Styles
    const originalMapStyle = rOriginalMapStyle.current
    const customMode: uui.domain.ui.map.markers.MapMode | undefined =
      originalMapStyle.custom[roadSegmentId]

    if (customMode === 'off') return

    // hide the feature
    dispatch(
      setCustomMapStyle({
        customStyle: {
          type: 'roadSegment',
          mode: 'off',
          ids: [roadSegmentId],
        },
      }),
    )

    return () => {
      dispatch(
        setCustomMapStyle({
          customStyle: {
            type: 'roadSegment',
            mode: customMode ?? 'on',
            ids: [roadSegmentId],
          },
        }),
      )
    }
  }, [dispatch, editing, roadSegmentId])
}
