import type { ModalDevice } from '../typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDevicesAggregateInfo } from '@/features/domain/device'

import { useDistancesByDeviceId } from './useDistancesByDeviceId'

export const useDevices = () => {
  const devicesAggregateInfo = useSelector(selectDevicesAggregateInfo)
  const distancesByDeviceId = useDistancesByDeviceId()

  return useMemo(
    () =>
      Object.values(devicesAggregateInfo)
        .reduce<ModalDevice[]>((acc, aggregateInfo) => {
          const { device } = aggregateInfo

          acc.push({
            ...device,
            id: device.deviceId,
            distance: distancesByDeviceId[device.deviceId],
          })

          return acc
        }, [])
        .sort(
          (a, b) =>
            (a.distance ?? Number.MAX_SAFE_INTEGER) - (b.distance ?? Number.MAX_SAFE_INTEGER),
        ),
    [distancesByDeviceId, devicesAggregateInfo],
  )
}
