import { Typography } from '@mui/material'

interface Props {
  children: string
}

export function Subtitle(props: Props) {
  const { children } = props

  return (
    <Typography component="h4" variant="caption" color="textSecondary" noWrap>
      {children}
    </Typography>
  )
}
