import type Geometry from 'ol/geom/Geometry'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { setDeviceFeatureMetadata } from '../deviceFeatureMetadata'
import { getDeviceFeatureStyles } from './getDeviceFeatureStyles'

export const createDeviceFeature = (
  marker: uui.domain.ui.map.markers.Device,
  mapStyles: uui.domain.ui.map.markers.MapStyles['device'],
  selected: boolean = false,
): Feature<Geometry> => {
  const { lonLat, id } = marker

  const feature = new Feature({ geometry: new Point(lonLat), label: new Point(lonLat) })

  // use marker ID (deviceId) as unique ID
  feature.setId(id)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setDeviceFeatureMetadata(feature, 'type', 'device')

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['device']['mode'] = marker.mode ?? mapStyles.mode
  setDeviceFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getDeviceFeatureStyles(marker, mode, selected))

  return feature
}
