import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    any: translate({ id: 'global.any' }),
    add: translate({ id: 'global.add' }),
    sortBy: (field: string) =>
      translate({ id: 'gps.alerts.list.sorter.sortBy', values: { field } }),
    alerts: translate({ id: 'gps.alerts.yourAlerts' }),
    addAlert: translate({ id: 'gps.alerts.createNew' }),
    clearAll: translate({ id: 'gps.alerts.list.filter.clearAll' }),
    sortByName: translate({ id: 'gps.alerts.list.sorter.sortBy.name' }),
    filterPlaceholder: translate({ id: 'gps.alerts.list.filterPlaceholder' }),
    sortOrderAscending: translate({ id: 'gps.alerts.list.sorter.sortOrder.ascending' }),
    sortOrderDescending: translate({ id: 'gps.alerts.list.sorter.sortOrder.descending' }),
    sortByRunningDevices: translate({ id: 'gps.alerts.list.sorter.sortBy.runningDevices' }),

    disabledForLoadingTooltip: translate({ id: 'global.gpsLoading.disableEdit' }),
  }))

  return api
}
