import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { DropdownMenu, MenuTrigger } from '@/components/DropdownMenu'
import { FormatDateInterval } from '@/components/smartUtils/time/FormatDateInterval'
import { CalendarAlt } from '@/icons'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useExportRoutesFromCalendarModal } from '../../../../../modals/ExportRoutesFromCalendarModal'
import { TerritoryCalendar } from './components/TerritoryCalendar'
import { useTexts } from './useTexts'

export function CalendarDropdownMenu() {
  const [open, setOpen] = useState<boolean>(false)
  const [dates, setDates] = useState<string[]>([])
  const [multiTerritory, setMultiterritory] = useState<boolean>(false)

  const { start, end } = useSelector(selectCalendarRangeInterval)
  const { show: showExportRoutesFromCalendarModal, Modal: ExportRoutesFromCalendarModal } =
    useExportRoutesFromCalendarModal()
  const texts = useTexts()

  const handleOnClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <DropdownMenu
        open={open}
        setOpen={setOpen}
        autoWidth
        trigger={
          <Tooltip placement="bottom" title={texts.calendar}>
            <MenuTrigger
              menuOpen={open}
              testid="header__calendar-menu-trigger"
              className="pendo-bluebar__calendar-menu"
              Icon={<CalendarAlt size={19} marginRight={10} />}
            >
              <FormatDateInterval start={start} end={end} />
            </MenuTrigger>
          </Tooltip>
        }
      >
        <TerritoryCalendar
          onClose={handleOnClose}
          setExportDates={setDates}
          setMultiterritory={setMultiterritory}
          showExportRoutesFromCalendarModal={showExportRoutesFromCalendarModal}
        />
      </DropdownMenu>
      <ExportRoutesFromCalendarModal dates={dates} multiTerritory={multiTerritory} />
    </>
  )
}
