import { Entry } from '../Entry'
import { JiraLinks } from './JiraLinks'

const ids = [
  10262, 10332, 10470, 10267, 10364, 10472, 10291, 10365, 10485, 10294, 10367, 10485, 10322, 10375,
  10486, 10331, 10466, 10494,
]

export function Entry20210326() {
  return (
    <Entry title="03/26/2021 - v3.0.0-alpha-11">
      More relevant changes
      <ul>
        <li>Log error through Sentry</li>
        <li>Order execution markers are visible</li>
        <li>Add orders bulk view</li>
        <li>Manage archived days</li>
        <li>Preserve order steps selection when the order type changes</li>
        <li>Add double-click on orders</li>
        <li>Add double-click on the calendar range</li>
        <li>Load real order notifications</li>
        <li>Fix fit map issues</li>
      </ul>
      Full list of Jira issues fixed:
      <br />
      <br />
      <JiraLinks ids={ids} />
      <br />
      <br />
    </Entry>
  )
}
