import { Chip, IconButton, Stack, Typography, useTheme } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Tooltip } from '@/components/primitives/Tooltip'
import { UserActions } from '../UserActions/UserActions'
import { useTexts } from '../hooks/useTexts'
import { useActions } from './hooks/useActions'

type Props = {
  name: string
  surname: string
  type: string
  id: string
  enabled: boolean
  selectedUsers: uui.domain.client.rm.RouteManagerUser[]
}

const chipHeight = { height: '20px' }

export function ViewUserHeader(props: Props) {
  const { name, surname, type, selectedUsers, enabled } = props
  const theme = useTheme()
  const texts = useTexts()
  const { onCloseUserDetails } = useActions()

  const color = enabled ? theme.palette.text.primary : theme.palette.text.disabled

  const role = `${type[0].toUpperCase()}${type.substring(1)}`

  return (
    <Stack bgcolor={theme.palette.grey['100']} padding={2} borderBottom="1px solid #eaeaea">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack>
          <Stack direction="row" alignItems="center">
            <Typography
              color={color}
              lineHeight="24px"
              component="h1"
              variant="h6"
              fontWeight={600}
              maxWidth="165px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {name} {surname}
            </Typography>
            {!enabled && (
              <Chip
                style={chipHeight}
                label={
                  <Typography fontWeight={600} fontSize="12px">
                    {texts.disabled}
                  </Typography>
                }
                size="small"
              />
            )}
          </Stack>
          <Typography variant="caption" color="textSecondary">
            {role}
          </Typography>
        </Stack>
        <Tooltip placement="bottom" title={texts.tooltips.close}>
          <IconButton onClick={onCloseUserDetails}>
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>
      <UserActions selectedUsers={selectedUsers} />
    </Stack>
  )
}
