import styled from 'styled-components'

import { StepButtonWrapperWidth } from '@/forms-legacy'
import { DurationStep } from './DurationStep'

const additionalPad = 6

const StyledDurationStep = styled(DurationStep)`
  position: relative;
  padding-right: ${StepButtonWrapperWidth + additionalPad}px;
  border-color: ${p => p.theme.colors.$nightRider};

  &.has-error {
    border-color: ${p => p.theme.colors.$outrageousRed};
  }
`
StyledDurationStep.displayName = 'StyledDurationStep'
export default StyledDurationStep
