import { useEffect, useRef } from 'react'

import { useControllerActions } from './useControllerActions'
import { useController } from './useController'

/**
 * Auto submit
 *
 * If the user selected one or more orders without paireds or, in the selection,
 * all paireds are already present, the modal should submit automatically and not appear.
 */
export function useAutoSubmit() {
  const { duplicateOrder } = useControllerActions()
  const { ready, close, data } = useController()

  const api = useRef({ data, actions: { close, duplicateOrder } })
  useEffect(() => {
    api.current = { data, actions: { close, duplicateOrder } }
  }, [duplicateOrder, close, data])

  // The effect will be executed once since `ready` will switch to true and never switch back to false
  useEffect(() => {
    if (!ready) return

    const { data, actions } = api.current

    if (data.selectedOrderSteps.ids.length > 0 && data.pairedOrderSteps.ids.length === 0) {
      actions.duplicateOrder()
      actions.close?.()
    }
  }, [ready])
}
