import { ArrowRoundedIn, ArrowRoundedInOut, ArrowRoundedOut } from '@/icons'

interface Props {
  type: 'arriving' | 'departing' | 'arr_dep'
  marginRight?: number
  selected: boolean
  size: number
}

export function GeofenceAvatarIcon(props: Props) {
  const { type, size, selected, marginRight = 0 } = props

  const color = selected ? '$pureWhite' : '$pureBlack'

  switch (type) {
    case 'arriving':
      return <ArrowRoundedIn size={size} marginRight={marginRight} color={color} />

    case 'departing':
      return <ArrowRoundedOut size={size} marginRight={marginRight} color={color} />

    case 'arr_dep':
      return <ArrowRoundedInOut size={size} marginRight={marginRight} color={color} />

    default:
      return null
  }
}
