import styled from 'styled-components'

const WarningLabel = styled.span`
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.$darkOrange};
  font-size: ${p => p.theme.fonts.$s};
`

WarningLabel.displayName = 'WarningLabel'
export default WarningLabel
