import { type Column, type ColumnStore } from '@bryntum/schedulerpro'

import { selectUserConfiguration } from '@/features/domain/user'
import { selectLiveEta } from '@/features/domain/territory'
import {
  selectRoutesLoads,
  selectSchedulerEventsAsArray,
  selectSchedulerResourcesAsArray,
} from '@/features/domain/scheduler'
import { store } from '@/store'

import { setInitialSelection } from '../../../sync/selection'

import { createDynamicLoadColumn } from '../../columns/defs/dynamicLoad'
import { getSchedulerColumnAtom } from '../../columns'
import { createLiveEtaColumn } from '../../columns/defs/liveEta'
import { createDriverColumn } from '../../columns/defs/driver'

import { getProject, schedulerInstance } from '../project'

export async function syncProjectData(domainAction: uui.domain.api.StateChangeAction) {
  const project = getProject()

  updateColumns()

  // Load data
  if (domainAction.mode === 'update') {
    if (!domainAction.summary.schedulerChangelog) {
      return
    }

    /**
     * Clearing filters before applying changeset and re-adding them after
     * is a workaround to avoid a bug in Bryntum Scheduler Pro.
     *
     * Currently, we didn't find a way to reproduce the bug in a sandbox, so we
     * can't report it to Bryntum.
     **/
    const oldFilters = [...project.resourceStore.filters.allValues]

    project.resourceStore.clearFilters()
    project.applyChangeset(domainAction.summary.schedulerChangelog)

    for (const filter of oldFilters) {
      project.resourceStore.addFilter(filter)
    }
  } else {
    // Retrieve Redux state
    const state = store.getState()

    const resourcesData = selectSchedulerResourcesAsArray(state)
    const eventsData = selectSchedulerEventsAsArray(state)

    await project.loadInlineData({
      resourcesData,
      eventsData,
    })
  }

  if (schedulerInstance?.instance) {
    setInitialSelection(schedulerInstance.instance)
  }
}

let currentPlanType = selectUserConfiguration(store.getState()).planType
let currentLiveEtaActive = selectLiveEta(store.getState())
let currentCalendarizedLoads = selectRoutesLoads(store.getState())

function updateColumns() {
  if (!schedulerInstance.instance) return

  const state = store.getState()
  const columnStore = schedulerInstance.instance.columns as ColumnStore

  // Verify if needed to add/remove Live ETA column
  const liveEtaActive = selectLiveEta(state)

  if (currentLiveEtaActive !== liveEtaActive) {
    currentLiveEtaActive = liveEtaActive
    const liveEtaColumn = columnStore.find(c => c.field === 'liveEta')

    if (liveEtaActive) {
      if (!liveEtaColumn) {
        columnStore.add(createLiveEtaColumn(140, false))
      }
    } else {
      if (liveEtaColumn) {
        columnStore.remove(liveEtaColumn)
      }
    }
  }

  // Verify if needed to add/remove dynamic load columns
  const calendarizedLoads = selectRoutesLoads(state)
  if (currentCalendarizedLoads !== calendarizedLoads) {
    currentCalendarizedLoads = calendarizedLoads

    // Remove all dynamic load columns
    for (const column of columnStore.allRecords as Column[]) {
      if (
        column.field.startsWith('loads.') &&
        !calendarizedLoads.includes(column.field.replace('loads.', ''))
      ) {
        columnStore.remove(column)
      }
    }

    // Add dynamic load columns
    for (const load of calendarizedLoads) {
      const loadColumnInStore = columnStore.find(c => c.field === `loads.${load}`)

      // If a column for the load is already present in the store, skip it
      if (loadColumnInStore) continue

      let width: string | number | undefined = undefined
      let hidden = true
      const storedColumns = getSchedulerColumnAtom(true).columns
      const storedColumn = storedColumns.find(c => c.id === `dynamic-load-${load}`)
      if (storedColumn) {
        width = storedColumn.width
        hidden = storedColumn.hidden
      }

      // If the column is not present in the store, add it
      columnStore.add(createDynamicLoadColumn(load, load, width ?? 140, hidden))
    }
  }

  // Verify if needed to add/remove driver column (on simulations there's no driver column)
  const { planType } = selectUserConfiguration(state)
  if (currentPlanType !== planType) {
    currentPlanType = planType

    const driverColumn = columnStore.find(c => c.field === 'driverName')

    if (planType === 'simulation') {
      if (driverColumn) {
        columnStore.remove(driverColumn)
      }
    } else {
      if (!driverColumn) {
        columnStore.insert(0, createDriverColumn(140, false))
      }
    }
  }

  schedulerInstance.instance.refreshHeaders()
}
