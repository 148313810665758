import { SMSNotificationItem } from './SMSNotificationItem'
import { EmailNotificationItem } from './EmailNotificationItem'
import { VoiceCallNotificationItem } from './VoiceCallNotificationItem'

interface Props {
  item: uui.domain.client.rm.OrderStepNotification
  showModal: (data: uui.domain.client.rm.OrderStepNotification) => void
}

export function OrderStepNotificationItem(props: Props) {
  const { item, showModal } = props

  switch (item.kind) {
    case 'SMS':
      return <SMSNotificationItem item={item} showModal={showModal} />

    case 'EMAIL':
      return <EmailNotificationItem item={item} showModal={showModal} />

    case 'MASKED_CALL':
      return <VoiceCallNotificationItem item={item} />
  }
}
