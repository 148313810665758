import { useCallback, useLayoutEffect, useEffect, useRef } from 'react'
import { useEditingStateWithCtx } from '@/atoms'

type SettingsTarget =
  | 'rm-account'
  | 'gps-account'
  | 'user-management'
  | 'personalization'
  | 'language-and-formats'
  | 'gps-formats'

export function useSettingsEditing(target: SettingsTarget, unmountBehavior: 'reset' | 'none') {
  const { editing, setEditing, resetEditing: onDiscard } = useEditingStateWithCtx('settings')

  const rData = useRef({ unmountBehavior, onDiscard })
  useLayoutEffect(() => void (rData.current = { unmountBehavior, onDiscard }))

  const onEdit = useCallback(() => {
    setEditing([target])
  }, [setEditing, target])

  useEffect(() => {
    return () => {
      // When the component unmounts reset the editing state if requested
      if (rData.current.unmountBehavior === 'reset') {
        rData.current.onDiscard()
      }
    }
  }, [onDiscard])

  return { editing, onEdit, onDiscard } as const
}
