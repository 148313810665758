import type { MouseEvent } from 'react'

import { useState, useCallback } from 'react'
import { parse } from 'date-fns/esm'

const now = new Date()
export function useHoveredDay(): [Date | null, (e: MouseEvent<HTMLButtonElement>) => void] {
  const [hoveredDay, setHoveredDay] = useState<Date | null>(null)

  const onMouseOverDay = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement
    const date = target.dataset['day']

    if (!date) return
    setHoveredDay(parse(date, 'yyyyMMdd', now))
  }, [])

  return [hoveredDay, onMouseOverDay]
}
