import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { timeUtils } from '@/server-data'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectBreadcrumbTimeRange } from '@/features/domain/breadcrumb/cursors'

import { useTexts } from '../useTexts'

export function useInterval() {
  const breadcrumbTimeRange = useSelector(selectBreadcrumbTimeRange)
  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  return useMemo(() => {
    if (breadcrumbTimeRange === 'live') {
      const midnightDate = new Date()
      midnightDate.setHours(0, 0, 0, 0)

      return {
        endTime: texts.now,
        startTime: timeUtils.formatTime(userConfig)(midnightDate),
      }
    }

    return {
      startTime: timeUtils.formatTime(userConfig)(new Date(breadcrumbTimeRange.start)),
      endTime: timeUtils.formatTime(userConfig)(new Date(breadcrumbTimeRange.end)),
    }
  }, [texts, breadcrumbTimeRange, userConfig])
}
