import styled from 'styled-components'

type Props = {
  active?: boolean
}

const styledComponent = styled.button.attrs<Props>(() => ({
  className: 'o-hour-picker__button',
  type: 'button',
}))<Props>`
  margin: 0;
  border: 0;
  display: block;
  width: 32px;
  height: 100%;

  padding: 0;
  outline: 0;
  border-width: 0;

  line-height: 1;

  background: ${p => (p.active ? p.theme.colors.$nightRider : p.theme.colors.$whiteSmoke)};
  color: ${p => (p.active ? p.theme.colors.$pureWhite : p.theme.colors.$nightRider)};
  border-radius: 6px;

  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};

  transition: 0.3s ease;
  transition-property: background, color;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: default;
    ${p => (p.active ? p.theme.colors.$pureWhite : p.theme.colors.$whiteSmoke)};
  }
`

styledComponent.displayName = 'HourPickerButton'
export default styledComponent
