import { useRef } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const api = useRef({
    cancel: translate({ id: 'setup.voice.modals.copyConfig.footer.normal.secondary' }),
    replace: translate({
      id: 'setup.voice.modals.copyConfig.footer.normal.primary',
    }),
    modalTitle: translate({ id: 'setup.voice.modals.copyConfig.header.title' }),
    modalSubTitle: translate({
      id: 'setup.voice.modals.copyConfig.header.subtitle',
    }),
    territoryDropdownTitle: translate({
      id: 'setup.voice.modals.copyConfig.territoryDropdown.header',
    }),
    replaceWarningCardTitle: translate({
      id: 'setup.voice.modals.copyConfig.messages.warning.title',
    }),
    replaceWarningCardDescription: translate({
      id: 'setup.voice.modals.copyConfig.messages.warning.description',
    }),
    noAdvancedVoiceSupportedTitle: translate({
      id: 'setup.voice.modals.copyConfig.messages.noSupport.title',
    }),
    noAdvancedVoiceSupportedDescription: translate({
      id: 'setup.voice.modals.copyConfig.messages.noSupport.description',
    }),
    success: translate({
      id: 'setup.voice.modals.copyConfig.messages.success.description',
    }),
    error: translate({
      id: 'setup.voice.modals.copyConfig.messages.failed.description',
    }),
  })

  return api.current
}
