import { WarningCard as WarningCardUI } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useTexts } from './useTexts'

export function WarningCard() {
  const texts = useTexts()

  return (
    <WarningCardUI
      preset="warning"
      Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
      description={texts.description}
    />
  )
}
