import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    successTitle: translate({
      id: 'sms4.settings.communication.notifications.testModal.email.success.title',
    }),

    successDescription: translate({
      id: 'sms4.settings.communication.notifications.testModal.email.success.description',
    }),

    errorTitle: translate({
      id: 'sms4.settings.communication.notifications.testModal.email.error.title',
    }),

    errorDescription: translate({
      id: 'sms4.settings.communication.notifications.testModal.email.error.description',
    }),
  }))

  return api
}
