import { useMemo } from 'react'
import { usePreparationValidations } from './usePreparationValidations'
import { useCloseoutValidations } from './useCloseoutValidations'
import { useDriversValidations } from './useDriversValidations'

export function useValidations() {
  const preparationValidations = usePreparationValidations()
  const closeoutValidations = useCloseoutValidations()
  const driversValidations = useDriversValidations()

  return useMemo(
    () => [...preparationValidations, ...driversValidations, ...closeoutValidations],
    [driversValidations, preparationValidations, closeoutValidations],
  )
}
