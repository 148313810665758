import type { AsyncThunkApiConfig } from '@/store'
import type { ImportWizardData } from './types'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { convertToClientUserProfile } from '@/local/server-data/domain/server/factories/user'
import { replaceDomainData } from '@/features/domain'
import { autoLoginService } from '@/services'

import { loadInitialData } from './loadInitialData'

type LoginData = Parameters<typeof autoLoginService>[0] & {
  username: string
  serverUrl: string
  territoryId?: string
  simulationId?: string
  importData?: ImportWizardData
}

type RejectErrorType = 'invalid-login' | 'proxy-error' | 'too-many-sessions' | 'unknown'

interface RejectError {
  message: string
  error: Error
  type: RejectErrorType
}

export const autoLogin = createAsyncThunk<
  // Return type of the payload creator
  { clientUserProfile: uui.domain.client.UserProfile; loadInitialDataPromise: Promise<any> },
  // First argument to the payload creator
  LoginData,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('lifecycle/login', async (data, thunkApi) => {
  // ---------------------------------------------------------------
  // AUTHENTICATION
  // ---------------------------------------------------------------

  let userProfile: uui.domain.server.UserProfile | undefined

  try {
    const { username, simulationId, territoryId, importData, ...loginData } = data

    thunkApi.extra.journal.store(`Trying to automatically log as: ${username}`)

    userProfile = await autoLoginService(loginData)
  } catch (e) {
    const message = 'Invalid login credentials'
    thunkApi.extra.journal.store(message, { tags: ['login', 'auto-login'] }, 'warn')
    return thunkApi.rejectWithValue({
      error: { name: 'invalid-login', message },
      type: 'invalid-login',
      message,
    })
  }

  if (!userProfile) {
    const message = 'Invalid login credentials'
    thunkApi.extra.journal.store(message, { tags: ['login', 'auto-login'] }, 'warn')
    return thunkApi.rejectWithValue({
      error: { name: 'invalid-login', message },
      type: 'invalid-login',
      message,
    })
  }

  try {
    // ---------------------------------------------------------------
    // INITIALIZATION
    // ---------------------------------------------------------------

    const clientUserProfile = convertToClientUserProfile(userProfile)

    // Retrieve
    const sessionTokenFromCookie =
      data.importData?.type === 'importWizard' ? data.importData.sessionTokenFromCookie : undefined

    // initialize server-data web-worker,
    const initialDomainState = await thunkApi.extra.domainProxy.create(
      data.serverUrl,
      clientUserProfile,
      sessionTokenFromCookie,
    )

    if (!initialDomainState || initialDomainState === 'tooManySessionsError') {
      let message = ''
      let name: RejectErrorType

      switch (initialDomainState) {
        case false:
          message = 'The Domain WebWorker failed to connect (Unknown error).'
          name = 'proxy-error'
          break
        case 'tooManySessionsError':
          message = 'The Domain WebWorker failed to connect (Too many open sessions).'
          name = 'too-many-sessions'
          break
        default:
          message = 'The Domain WebWorker failed to connect (Too many open sessions).'
          name = 'unknown'
          break
      }

      thunkApi.extra.journal.store(message, { tags: ['login', 'auto-login'] }, 'error')

      return thunkApi.rejectWithValue({
        error: { name, message },
        type: name,
        message,
      })
    }

    thunkApi.dispatch(replaceDomainData(initialDomainState))

    // ---------------------------------------------------------------
    // RETRIEVE DATA FROM PENDING IMPORTS (Import Wizard)
    // ---------------------------------------------------------------
    const { importData } = data

    const territoryId =
      data.territoryId ||
      (clientUserProfile.user.type !== 'gpsonly'
        ? clientUserProfile.user.uiData.lastOpenedSession?.territoryId
        : undefined)

    const simulationId = data.simulationId
      ? data.simulationId
      : clientUserProfile.user.type !== 'gpsonly'
      ? clientUserProfile.user.uiData.lastOpenedSession?.planId !== 'ops'
        ? clientUserProfile.user.uiData.lastOpenedSession?.planId
        : undefined
      : undefined

    let additionalData:
      | {
          territoryId?: string
          simulationId?: string
          txnIdFromCookie?: string
          customCalendarRange?: uui.domain.DataRange
        }
      | undefined = undefined

    if (importData) {
      switch (importData.type) {
        case 'importWizard':
          additionalData = {
            txnIdFromCookie: importData.txnIdFromCookie,
            customCalendarRange: importData.customCalendarRange,
          }
          break
      }
    }

    // ---------------------------------------------------------------
    // LOAD INITIAL DATA
    // ---------------------------------------------------------------

    // Let's return the promise to handle the resolution outside the thunk
    const loadInitialDataPromise = thunkApi.dispatch(
      loadInitialData({
        territoryId,
        simulationId,
        ...additionalData,
      }),
    )

    // return the profile
    return { clientUserProfile, loadInitialDataPromise }
  } catch (error) {
    const message = 'Login Failed'
    thunkApi.extra.journal.store(message, { tags: ['login', 'auto-login'] }, 'error')
    return thunkApi.rejectWithValue({ type: 'unknown', message, error })
  }
})
