import { Tooltip } from '@/components/primitives/Tooltip'

import { Row } from '../Row'
import { Section } from '../Section'
import { useTexts } from './useTexts'

interface Props {
  snapshot: uui.domain.client.rm.PlanSummary
}

export function RoutePlanSummary(props: Props) {
  const { snapshot } = props
  const texts = useTexts()

  const {
    route: { workingTime, drivingTime, milage },
  } = snapshot

  return (
    <Section>
      <Tooltip
        textAlign="left"
        placement="right"
        title={texts.workingTime}
        subtitle={texts.workingTimeTooltip}
      >
        <Row text={texts.workingTime} value={texts.timeValue(workingTime)} />
      </Tooltip>

      <Tooltip
        textAlign="left"
        placement="right"
        title={texts.drivingTime}
        subtitle={texts.drivingTimeTooltip}
      >
        <Row text={texts.drivingTime} value={texts.timeValue(drivingTime)} />
      </Tooltip>

      <Tooltip
        textAlign="left"
        placement="right"
        title={texts.distance}
        subtitle={texts.mileageTooltip}
      >
        <Row text={texts.distance} value={texts.distanceValue(milage)} />
      </Tooltip>
    </Section>
  )
}
