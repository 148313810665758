import { FormLayout, HorizontalRuler } from '@/forms-legacy'

import { Name } from './fields/Name'
import { Address } from './fields/Address'

export function Form() {
  return (
    <FormLayout>
      <Name />
      <Address />
      <HorizontalRuler />
    </FormLayout>
  )
}
