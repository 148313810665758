import { useResetCrudSelectionOnUnmount } from '@/atoms'

import { useDriverAssignmentsRoot } from './hooks/useDriverAssignmentsRoot'
import { setRecurrence } from './hooks/useRecurrence'

import { List } from './List'
import { SingleView } from './Single/View'
import { SingleEdit } from './Single/Edit'
import { BulkView } from './Bulk/View'

export function DriverAssignmentsRoot() {
  const { editing, driverAssignments, selection, selectedDriverAssignments } =
    useDriverAssignmentsRoot()
  const bulk = selectedDriverAssignments.length > 1
  const single = selectedDriverAssignments.length === 1

  const driverAssignment = single ? selectedDriverAssignments[0] : undefined

  setRecurrence(driverAssignment?.id)

  useResetCrudSelectionOnUnmount('driverAssignments')

  return (
    <>
      <List editing={editing} driverAssignments={driverAssignments} selection={selection} />
      {single && !editing && driverAssignment && <SingleView driverAssignment={driverAssignment} />}
      {bulk && <BulkView driverAssignments={selectedDriverAssignments} selection={selection} />}
      {editing && !bulk && (
        <SingleEdit
          selectedDriverAssignment={driverAssignment}
          driverAssignments={driverAssignments}
        />
      )}
    </>
  )
}
