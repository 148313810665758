import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    companyNameMaxLength: (maxCharacters: number) =>
      translate({
        id: 'sms4.settings.trackingPage.fields.companyName.error.maxLength',
        values: { maxCharacters },
      }),
    companySloganMaxLength: (maxCharacters: number) =>
      translate({
        id: 'sms4.settings.trackingPage.fields.companySlogan.error.maxLength',
        values: { maxCharacters },
      }),
    companyPhoneInvalidFormat: translate({
      id: 'sms4.settings.trackingPage.fields.companyPhone.error.invalidFormat',
    }),
    companyEmailInvalidFormat: translate({
      id: 'sms4.settings.trackingPage.fields.companyEmail.error.invalidFormat',
    }),
  }))

  return api
}
