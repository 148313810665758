import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function ZoomRoutes(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M17 4h3c1.1 0 2 .9 2 2v2h-2V6h-3V4ZM4 8V6h3V4H4c-1.1 0-2 .9-2 2v2h2Zm16 8v2h-3v2h3c1.1 0 2-.9 2-2v-2h-2ZM7 18H4v-2H2v2c0 1.1.9 2 2 2h3v-2Z" />
      </svg>
    </IconContainer>
  )
}
