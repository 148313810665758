import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectTelematicsDeviceTransientStatuses } from '@/features/domain/account'
import { Tab as UiTab, Tabs as UiTabs } from '@/components/primitives/Tabs'
import { usePagination } from '@/atoms'

import { useTexts } from './useTexts'

type Props = {
  unifiedVehicle?: uui.domain.client.UnifiedVehicle
  formViewType: 'rmOnly' | 'gspOnly' | 'rm360'
  disabled?: boolean
}

export function Tabs(props: Props) {
  const { disabled, unifiedVehicle, formViewType } = props

  const showExceptionSettings = formViewType === 'rm360' || formViewType === 'rmOnly'
  const deviceTransientStatuses = useSelector(selectTelematicsDeviceTransientStatuses)
  const { page, setPage } = usePagination('vehicleTab')
  const texts = useTexts()

  const setGeneralSettingsTab = useCallback(() => {
    setPage(0)
  }, [setPage])

  const setExceptionsTab = useCallback(() => {
    setPage(1)
  }, [setPage])

  // Check if the vehicle has a device and if got some activation errors
  const deviceId = unifiedVehicle?.hasRoutingLicense
    ? unifiedVehicle.vehicle.gpsDeviceId
    : undefined

  const showDeviceErrorDot =
    ((deviceId && deviceTransientStatuses[deviceId]?.status) ?? '') === 'ERROR'

  return (
    <UiTabs>
      <UiTab
        label={texts.generalSettings}
        error={showDeviceErrorDot}
        onClick={setGeneralSettingsTab}
        active={page === 0}
        disabled={disabled}
        className="o-pendo__vehicle-form__general-settings-tab'"
      />
      {showExceptionSettings && (
        <UiTab
          label={texts.exception}
          onClick={setExceptionsTab}
          active={page === 1}
          disabled={disabled}
          className="o-pendo__vehicle-form__routing-settings-tab"
        />
      )}
    </UiTabs>
  )
}

Tabs.displayName = 'Tabs'
