import { useEditingStateWithCtx } from '@/atoms'
import { useSearchList } from './useSearchList'

export function useFilter() {
  const { editing } = useEditingStateWithCtx('trafficProfile')
  const { query, setQuery } = useSearchList()

  return {
    query,
    editing,
    setQuery,
  }
}
