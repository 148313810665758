import type { Extent } from 'ol/extent'
import { useEffect, useRef } from 'react'

import { snapshot, proxy } from 'valtio'

import { fitMap, fitMapToGeometry } from './map/utils/fitMap'
import { waitForMapToBeMounted } from './map/utils/waitForMapToBeMounted'

type PendingFitMap = uui.domain.LatLng[] | Extent

type PendingFitMapAtom = {
  points: PendingFitMap
}

export const pendingFitMap = proxy<PendingFitMapAtom>({ points: [] })

// ------------------------------------
// PendingFitMap atom
// ------------------------------------

export function getPendingFitMap(immutable: boolean = false) {
  return immutable ? snapshot(pendingFitMap.points) : pendingFitMap.points
}

// ------------------------------------
// Write functions
// ------------------------------------

type SetPendingFitMap = (prev: workwave.DeepReadonly<PendingFitMap>) => PendingFitMap

type SetPendingFitMapParam = SetPendingFitMap | PendingFitMap | 'reset'

export function setPendingFitMap(valueOrFunc: SetPendingFitMapParam) {
  if (valueOrFunc === 'reset') return resetPendingFitMap()

  if (typeof valueOrFunc === 'function') {
    pendingFitMap.points = valueOrFunc(snapshot(pendingFitMap.points))
  } else {
    Object.assign(pendingFitMap, { points: valueOrFunc })
  }

  return snapshot(pendingFitMap.points)
}

export function resetPendingFitMap() {
  pendingFitMap.points.length = 0

  return snapshot(pendingFitMap.points)
}

// ------------------------------------
// Reactions
// ------------------------------------

const wait = (delay: number) => new Promise(resolve => setTimeout(resolve, delay))

export function applyPendingFitMap(preventIfVisible?: boolean, delay: number = 500): () => void {
  const run = async () => {
    await waitForMapToBeMounted()

    if (delay >= 0) await wait(delay)

    const pointsOrExtent = getPendingFitMap(true)

    if (pointsOrExtent.length > 0) {
      const type = typeof pointsOrExtent[0] === 'number' ? 'extent' : 'points'

      // apply the fitMap and clear pending data
      if (type === 'points') {
        await fitMap(pointsOrExtent as uui.domain.LatLng[], { preventIfVisible }, 'clear')
      } else {
        fitMapToGeometry(pointsOrExtent as Extent, { preventIfVisible }, 'clear')
      }
    }
  }

  run()

  return resetPendingFitMap
}

// ------------------------------------
// React hooks
// ------------------------------------

export function usePendingFitMapOnMount(
  forceFit: boolean = false,
  preventClearOnUnmount: boolean = false,
  delay?: number,
) {
  const api = useRef({ forceFit, preventClearOnUnmount, delay })
  useEffect(() => void (api.current = { forceFit, preventClearOnUnmount, delay }))

  useEffect(() => {
    const { forceFit, preventClearOnUnmount, delay } = api.current

    const preventIfVisible = !forceFit
    const resetPendingFitMap = applyPendingFitMap(preventIfVisible, delay)

    return () => {
      if (!preventClearOnUnmount) {
        resetPendingFitMap()
      }
    }
  }, [])
}
