import styled from 'styled-components'

export const DeviceIconButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${p => p.theme.colors.$nightRider};
  border: 2px solid ${p => p.theme.colors.$nightRider};
  border-radius: 6px;
  padding: 2px;
`

DeviceIconButtonContainer.displayName = 'DeviceIconButtonContainer'
