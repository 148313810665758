import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AccordionSummary, AccordionDetails, Accordion, Box } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { generateRelativePath, Link } from '@/routing'
import { Email, Down } from '@/icons'

import { MainLink } from '../../../MainLink'
import { SubLink } from '../SubLink'
import { LinkList } from '../LinkList'

import { useCommunicationCollapsed } from '../../hooks/useCollapseStatus'
import { useNotificationBehavior } from './hooks/useNotificationBehavior'
import { useCanShowVoice } from './hooks/useCanShowVoice'

import { useTexts } from './useTexts'

type SetupRoutes = uui.routing.Setup

export function Communication() {
  const { pathname } = useLocation()
  const path = generateRelativePath(pathname) as uui.routing.Setup
  const { rmOnly, communicationLinkDisabled } = useNotificationBehavior()
  const canShowVoice = useCanShowVoice()

  const [collapsed, setCollapsed, toggleCollapsed] = useCommunicationCollapsed()

  useEffect(() => {
    switch (path) {
      case 'notifications':
      case 'tracking-app':
      case 'preferences':
        setCollapsed(false)
        break
    }
  }, [setCollapsed, path])

  const texts = useTexts()

  return (
    <Box marginTop={1} marginBottom={1}>
      <Accordion
        data-trackid="setup-communicationMenu"
        data-testid="setup-communicationMenu"
        expanded={!collapsed}
      >
        <AccordionSummary
          expandIcon={<Down size={13} color="$nightRider" />}
          onClick={toggleCollapsed}
        >
          <MainLink
            Icon={<Email size={20} color="$nightRider" />}
            testid="setup-communicationMenu-expand"
            text={texts.communication}
            summary
          />
        </AccordionSummary>

        <AccordionDetails>
          {
            // @ts-expect-error
            <LinkList component="nav">
              <Link<SetupRoutes> to="preferences" disabled={communicationLinkDisabled}>
                <Tooltip
                  title={texts.disabledSectionTooltip(rmOnly)}
                  disabled={!communicationLinkDisabled}
                  placement="top"
                >
                  <SubLink
                    testid="setup-communicationMenu-preferences"
                    disabled={communicationLinkDisabled}
                    text={texts.preferences}
                    fontSize="$m"
                  />
                </Tooltip>
              </Link>

              <Link<SetupRoutes> to="tracking-app" disabled={communicationLinkDisabled}>
                <Tooltip
                  placement="top"
                  title={texts.disabledSectionTooltip(rmOnly)}
                  disabled={!communicationLinkDisabled}
                >
                  <SubLink
                    testid="setup-communicationMenu-trackingApp"
                    disabled={communicationLinkDisabled}
                    text={texts.trackingApp}
                    fontSize="$m"
                  />
                </Tooltip>
              </Link>

              <Link<SetupRoutes> to="notifications" disabled={communicationLinkDisabled}>
                <Tooltip
                  placement="top"
                  title={texts.disabledSectionTooltip(rmOnly)}
                  disabled={!communicationLinkDisabled}
                >
                  <SubLink
                    testid="setup-communicationMenu-notifications"
                    disabled={communicationLinkDisabled}
                    text={texts.notifications}
                    fontSize="$m"
                  />
                </Tooltip>
              </Link>

              {canShowVoice && (
                <Link<SetupRoutes> to="voice">
                  <SubLink
                    testid="setup-communicationMenu-voice"
                    text={texts.voice}
                    fontSize="$m"
                  />
                </Link>
              )}
            </LinkList>
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
