import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    notificationLimitationTitle: translate({
      id: 'sms4.settings.communication.notifications.form.notificationLimitation.title',
    }),

    notificationLimitationDescription: translate({
      id: 'sms4.settings.communication.notifications.form.notificationLimitation.description',
    }),

    liveEtaRequiredTitle: translate({
      id: 'sms4.settings.communication.notifications.form.notificationLimitation.liveEtaRequired.title',
    }),

    liveEtaRequiredDescription: translate({
      id: 'sms4.settings.communication.notifications.form.notificationLimitation.liveEtaRequired.description',
    }),

    liveEtaNotAvailableForUserTitle: translate({
      id: 'sms4.settings.communication.notifications.form.notificationLimitation.liveEtaNotAvailableForUser.title',
    }),

    liveEtaRequiredOnAllVehiclesTitle: translate({
      id: 'sms4.settings.communication.notifications.form.notificationLimitation.liveEtaRequiredOnAllVehicles.title',
    }),

    liveEtaNotAvailableForUserDescription: translate({
      id: 'sms4.settings.communication.notifications.form.notificationLimitation.liveEtaNotAvailableForUser.description',
    }),

    liveEtaRequiredOnAllVehiclesDescription: translate({
      id: 'sms4.settings.communication.notifications.form.notificationLimitation.liveEtaRequiredOnAllVehicles.description',
    }),
  }))

  return api
}
