import { useEffect } from 'react'
import LineString from 'ol/geom/LineString'
import { store, domainProxy } from '@/store'
import { fitMapToGeometry } from '@/map'

export function useFitMapOnBreadcrumbFetch(
  devices: (uui.domain.client.gps.wwgps.DeviceInfo | uui.domain.client.gps.telematics.DeviceInfo)[],
) {
  useEffect(() => {
    return domainProxy.subscribeToNotifications(
      notification => {
        // If the notification is not a breadcrumbShown let's bail out
        if (notification.notificationType !== 'breadcrumbShown') return

        const breadcrumbMarkers = store.getState().domain.publicData.domain.mapMarkers.breadcrumb
        const breadcrumbDeviceIds = new Set(notification.payload)

        // Let's collect the points for the geometry to fit
        const points = devices.reduce<number[][]>((acc, device) => {
          // If the notification doesn't include current device let's skip it
          if (!breadcrumbDeviceIds.has(device.deviceId)) return acc

          // If the marker is not fetched let's skip it
          const breadcrumbMarker = breadcrumbMarkers[device.deviceId]
          if (!breadcrumbMarker.fetched) return acc

          acc.push(...breadcrumbMarker.line)

          return acc
        }, [])

        if (points.length === 0) return

        // Let's create and fit the geometry
        const geometry = new LineString(points)
        fitMapToGeometry(geometry.getExtent(), { preventIfVisible: true })
      },
      ['breadcrumbShown'],
    )
  }, [devices])
}
