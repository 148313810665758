import * as Comlink from 'comlink'

export async function create() {
  const worker = new Worker(
    new URL(
      './store/store.web-worker.ts',
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore Prevent the following TS error: The 'import.meta' meta-property is only allowed when the '--module' option is 'es2020', 'es2022', 'esnext', 'system', 'node12', or 'nodenext'.
      import.meta.url,
    ),
    { type: 'module' },
  )

  return Comlink.wrap<uui.domain.api.Store>(worker)
}
