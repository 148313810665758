import { type CSSProperties } from 'react'
import { isDark, theme } from '@/styles'

import { type ChipProps as Props } from './types'
import { useTexts } from './useTexts'

export function Chip(props: Props) {
  const texts = useTexts()
  const { color, name, isReload, isBreak } = props

  return (
    <div
      className="drag-drop-tooltip-chip"
      style={
        {
          '--background-color': color,
          '--foreground-color': isDark(color) ? theme.colors.$pureWhite : theme.colors.$pureBlack,
        } as CSSProperties
      }
    >
      {isReload ? (
        <div className="icon-container">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1326 4.10101C12.0543 4.04134 11.9457 4.04134 11.8674 4.10101L3.08618 10.7915C3.03187 10.8329 3 10.8972 3 10.9655V19.4531C3 19.7552 3.24484 20 3.54687 20H8.30313C8.60516 20 8.85 19.7552 8.85 19.4531V16.1514C8.85 14.4117 10.2603 13.0014 12 13.0014C13.7397 13.0014 15.15 14.4117 15.15 16.1514V19.4531C15.15 19.7552 15.3948 20 15.6969 20H20.4531C20.7552 20 21 19.7552 21 19.4531V10.9655C21 10.8972 20.9681 10.8329 20.9138 10.7915L12.1326 4.10101Z" />
          </svg>
        </div>
      ) : (
        <div className="text-container">{isBreak ? texts.breakTitle : name}</div>
      )}
    </div>
  )
}
