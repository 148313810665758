import {
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
  ReadonlyLabel,
} from '@/forms-legacy'

interface Props {
  label: string
  value: string
}

export function ReadonlyCustomFieldField(props: Props) {
  const { label, value } = props

  return (
    <ReadonlyBlock>
      <ReadonlyLabel>{label}</ReadonlyLabel>

      <ReadonlyField>
        <ReadonlyFieldRow>
          <ReadonlyFieldLabel empty>{value}</ReadonlyFieldLabel>
        </ReadonlyFieldRow>
      </ReadonlyField>
    </ReadonlyBlock>
  )
}
