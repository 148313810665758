import { ReadonlyViolationIcon } from '@/forms-legacy'
import { Flag } from '@/icons'

function ViolationIcon() {
  return (
    <ReadonlyViolationIcon>
      <Flag />
    </ReadonlyViolationIcon>
  )
}

export default ViolationIcon
