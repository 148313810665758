import { useEffect } from 'react'

import { fetchCommunicationPlanData } from '@/features/domain/territory'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useTexts } from './useTexts'

export function useRefreshCommunicationPlanData() {
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  useEffect(() => {
    const refreshCommunicationPlanData = async () => {
      try {
        const thunkResult = await dispatch(fetchCommunicationPlanData())

        if (!fetchCommunicationPlanData.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }
      } catch (error) {
        toast.error(texts.errorFetchingAccountData, { autoClose: 5000 })
      }
    }
    refreshCommunicationPlanData()
  }, [toast, texts, dispatch])
}
