import { Badge, IconButton } from '@mui/material'
import { FilterList } from '@mui/icons-material'

import { Tooltip } from '../../primitives/Tooltip'
import { useTexts } from './hooks/useTexts'

type Props = {
  filterActive?: boolean
  disabled?: boolean
  onClick: () => void
}

export function ListHeaderExternalFilterButton(props: Props) {
  const { onClick, disabled = false, filterActive = false } = props

  const texts = useTexts()

  return (
    <Badge
      color="primary"
      overlap="circular"
      variant="dot"
      invisible={!filterActive}
      data-testid="list-header-filter-menu-badge"
      data-trackid="list-header-filter-menu-badge"
    >
      <Tooltip title={texts.filter} placement="bottom">
        <IconButton
          onClick={onClick}
          disabled={disabled}
          data-testid={'list-header-filter-menu-button'}
          data-trackid={'list-header-filter-menu-button'}
        >
          <FilterList />
        </IconButton>
      </Tooltip>
    </Badge>
  )
}
