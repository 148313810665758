import { PureComponent } from 'react'

import { FormLayout, SectionTitle, TextField, SimulationDatesPickerField } from '@/forms-legacy'

import * as Texts from '../../intl'
import { emptySimulationId } from './utils'
import { FormProps as Props } from './typings'

export default class Form extends PureComponent<Props> {
  render() {
    const {
      initialValues: { id },
      isSelectingArchivedDate,
      firstDayNotArchived,
      today,
      servedOrders,
      maxIntervalLength,
    } = this.props

    const showCalendar: boolean = id === emptySimulationId

    return (
      <FormLayout>
        <SectionTitle>{Texts.getFormTitleText()}</SectionTitle>
        <TextField name="name" label={Texts.getSimulationNameFieldText()} />
        {showCalendar && (
          <>
            <SectionTitle>{Texts.getFormCalendarTitleText()}</SectionTitle>
            <SimulationDatesPickerField
              name="simulationDate"
              label={''}
              wrappedInputProps={{
                firstDayNotArchived: firstDayNotArchived || new Date(),
                isSelectingArchivedDate,
                today,
                servedOrders,
                maxIntervalLength,
              }}
            />
          </>
        )}
        <TextField
          name="label"
          label={Texts.getSimulationLabelFieldText()}
          wrappedInputProps={{
            multi: true,
            showCounter: true,
            showResetIcon: true,
          }}
        />
      </FormLayout>
    )
  }
}
