import { FooterErrorItem } from '@/forms-legacy'

import * as Texts from '../../../../intl'

import { OrderValidation } from '../typings'
import { getErrorField } from './utils'

export default function getOrderFieldsWithError(
  orderErrors: OrderValidation,
  showHeaders?: boolean,
): FooterErrorItem[] {
  const fieldsWithError: FooterErrorItem[] = []

  if (orderErrors.name) {
    fieldsWithError.push(getErrorField(Texts.getFieldLabelNameText(), showHeaders))
  }

  if (orderErrors.eligibility) {
    fieldsWithError.push(getErrorField(Texts.geFieldLabelEligibilityText(), showHeaders))
  }

  if (orderErrors.loads) {
    fieldsWithError.push(getErrorField(Texts.getFieldLabelLoadsText(), showHeaders))
  }

  return fieldsWithError
}
