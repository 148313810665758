import type { FormField, FormError } from '@workwave-tidal/tidal/form-fairy'

import { useEffect, useState } from 'react'
import { useFormMeta } from '@workwave-tidal/tidal/form-fairy'
import { useIsUnmounted } from '@/hooks'

/**
 * Returns the current submitting state of a form and the enabling state of the submit button.
 *
 * It takes care to delay the change of the enabling status for the submit button in order to prevent
 * the fact that a user changes a field (with a validation on `blur`)
 * and directly try to click on the submit button without exit the field.
 *
 * Without using that hook the validation will be triggered on `mousedown` event and the
 * submit button will be disabled before triggering a `click` event, preventing the submission.
 */
export function useDisableSubmit<
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS>,
>() {
  const {
    meta: { submitting, validating },
  } = useFormMeta<FIELDS, ERROR>()
  const [disableSubmit, setDisableSubmit] = useState(() => validating || submitting)

  const isUnmounted = useIsUnmounted()

  useEffect(() => {
    let timeout

    if (validating) {
      timeout = setTimeout(() => {
        if (isUnmounted()) return

        setDisableSubmit(true)
      }, 200)
    } else {
      setDisableSubmit(validating || submitting)
    }

    return () => {
      clearTimeout(timeout ?? 0)
    }
  }, [isUnmounted, submitting, validating])

  return {
    submitting,
    disableSubmit,
  }
}
