type Subscriber<T> = (value: T | undefined) => void

interface Subscription<T> {
  key: string
  subscriber: Subscriber<T>
}
// TODO: move to WeakMap once the iterable spec is officially supported
// see: https://github.com/tc39/proposal-weakrefs#iterable-weakmaps
const defaultValueSubscribers = new Set<Subscription<any>>()

export function subscribeToDefaultValues<T>(subscription: Subscription<T>) {
  defaultValueSubscribers.add(subscription)

  return () => {
    defaultValueSubscribers.delete(subscription)
  }
}

export function notifyDefaultValuesSubscribers<T>(key: string, value: T | undefined) {
  defaultValueSubscribers.forEach(subscription => {
    if (key === subscription.key) {
      subscription.subscriber(value)
    }
  })
}
