import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { MapControls } from '@/components/MapControls'

import { TrafficRoot } from './components/form/TrafficRoot'
import { Map } from './components/Map'

export function Traffic() {
  return (
    <HorizontalLayout data-testid="setup-traffic-area-root">
      <TrafficRoot />

      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />

          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
