import { PureComponent } from 'react'

import {
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyList,
  ReadonlyFieldArrow,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'

import * as Texts from '../intl'
import { Props } from '../typings'

import ViolationIcon from './ViolationIcon'

export default class RequiredTags extends PureComponent<Props> {
  render() {
    const { tagsIn, violations } = this.props

    const requiredTagsViolation: boolean = violations.includes('TGI')

    return (
      <>
        <ReadonlyBlock>
          <ReadonlyLabel primary empty={tagsIn.length === 0}>
            {Texts.getRequiredTagsText()}
            {requiredTagsViolation && <ViolationIcon />}
          </ReadonlyLabel>

          {tagsIn.length > 0 && (
            <ReadonlyList>
              {tagsIn.map(key => (
                <ReadonlyFieldArrow key={key}>
                  <ReadonlyFieldRow>
                    <ReadonlyFieldLabel>{key}</ReadonlyFieldLabel>
                  </ReadonlyFieldRow>
                </ReadonlyFieldArrow>
              ))}
            </ReadonlyList>
          )}
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
