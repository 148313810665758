import type Feature from 'ol/Feature'

import Point from 'ol/geom/Point'
import LineString from 'ol/geom/LineString'
import Geometry from 'ol/geom/Geometry'

import { Stroke, Style } from 'ol/style'

import { gis } from '@/server-data'

const blackStroke = new Stroke({
  width: 0.5,
  lineDash: [2, 2],
  lineDashOffset: 10,
  color: [0, 0, 0, 1],
})

export const geofenceRadiusLineStyle = new Style({
  stroke: blackStroke,
  geometry: (feature: Feature<Geometry>) => {
    if (!feature) return

    const radius = feature.getProperties().radius as number
    if (!radius) return

    const geometry = feature.getGeometry()

    if (geometry instanceof Geometry && geometry.getType() === 'Point') {
      const center = geometry as Point
      const centerCoordinate = center.getCoordinates()

      const outerLatLng = gis.computeOffset(
        gis.fromCoordinateToLatLng(centerCoordinate),
        radius,
        90,
      )
      const outerCoordinate = gis.fromLatLngToCoordinate(outerLatLng)
      return new LineString([centerCoordinate, outerCoordinate])
    }
  },
})
