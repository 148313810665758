import type Feature from 'ol/Feature'

import { Stroke, Style } from 'ol/style'

import LineString from 'ol/geom/LineString'
import Geometry from 'ol/geom/Geometry'

import { MAX_LENGTH_IN_METERS } from '../../constants'

type Color = [number, number, number, number]

export function createValidSegment(mainColor: Color) {
  return new Style({
    stroke: new Stroke({
      color: mainColor,
      width: 4,
    }),

    geometry: (feature: Feature<Geometry>) => {
      const geometry = feature?.getGeometry()

      if (geometry instanceof Geometry && geometry.getType() === 'LineString') {
        const line = geometry as LineString
        const length = line.getLength()
        const firstPoint = line.getCoordinateAt(0)

        // length : 1 = MAX_LENGTH_IN_METERS : x
        const lastPoint =
          length >= MAX_LENGTH_IN_METERS
            ? line.getCoordinateAt(MAX_LENGTH_IN_METERS / length)
            : line.getCoordinateAt(1)

        if (firstPoint && lastPoint) {
          return new LineString([firstPoint, lastPoint])
        }
      }
    },
  })
}
