import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type Payload = {
  unifiedId: string
  recurrence: string
  startFromNormalDay?: boolean
}

/**
 * Create vehicle exception based on 'any' recurrence
 *
 * @private
 */
export const createVehicleException = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('rpc/vehicle/createException', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const result = await rpc('rpc/vehicle/createException', {
      category: 'rpc',
      type: 'rpc/vehicle/createException',
      unifiedId: payload.unifiedId,
      recurrence: payload.recurrence,
      startFromNormalDay: payload.startFromNormalDay,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {createVehicleException}`,
      { tags: ['rpc', 'vehicles'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Create vehicle exception failed`,
      error,
    })
  }
})
