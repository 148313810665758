import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Content } from './Content'

type Props = {
  data: uui.domain.client.rm.OrderStepEmailOrSMSNotification
}

export function Root(props: Props) {
  return (
    <Modal modalId={modalId}>
      <Content data={props.data} />
    </Modal>
  )
}
