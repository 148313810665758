import type { OrdStepId } from './typings'

export const getOrdStepId = (extendedOrder: uui.domain.client.rm.ExtendedOrderStep): OrdStepId => {
  const {
    order: { id: orderId },
    orderStep: { type: orderStepType },
  } = extendedOrder

  return {
    orderId,
    type: orderStepType === 'd' || orderStepType === 's' ? 'delivery' : 'pickup',
  }
}
