import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehicles } from '@/features/domain/vehicle'
import { selectRoutesInfo } from '@/features/domain/scheduler'
import { selectCalendarRange } from '@/features/domain/ui'
import { getColorValue, isDark, theme } from '@/styles'

interface VehicleData {
  totalViolations: number
  foregroundColor: string
  backgroundColor: string
  ordersCount: number
  borderColor: string
  routeIds: string[]
  activeRange?: {
    startDate: string
    endDate?: string
  }
}

export function useVehiclesData(uvIds: string[]): VehicleData {
  const calendarRange = useSelector(selectCalendarRange)
  const routesInfo = useSelector(selectRoutesInfo)
  const vehicles = useSelector(selectVehicles)

  const minDate = parseInt(calendarRange.minDate)
  const maxDate = parseInt(calendarRange.maxDate)

  return useMemo(() => {
    const routeDates = new Set<string>()
    const vehicleIds = uvIds.map(id => id.split('@@')[1])
    let borderColor: string = theme.colors.$nightRider
    let backgroundColor: string = theme.colors.$nightRider
    let foregroundColor: string = theme.colors.$pureWhite

    // Loop routeInfos to collect data for selected vehicles
    const vehiclesData = Object.values(routesInfo).reduce<VehicleData>(
      (acc, routeInfo) => {
        if (routeInfo.type === 'routeUnavailable' || !vehicleIds.includes(routeInfo.vehicleId))
          return acc

        const routeDate = parseInt(routeInfo.dateAsString)

        // If out of range let's bail-out
        if (routeDate - minDate < 0 || maxDate - routeDate < 0) return acc

        acc.ordersCount += routeInfo.ordersCount
        acc.totalViolations += routeInfo.violationsCount
        routeDates.add(routeInfo.dateAsString)
        acc.routeIds.push(routeInfo.id)

        return acc
      },
      {
        backgroundColor,
        foregroundColor,
        totalViolations: 0,
        borderColor,
        ordersCount: 0,
        routeIds: [],
      },
    )

    if (uvIds.length === 1) {
      const uv = vehicles[uvIds[0]]

      if (uv.hasRoutingLicense) {
        borderColor = getColorValue(uv.vehicle.color)

        backgroundColor = vehiclesData.ordersCount > 0 ? borderColor : theme.colors.$pureWhite

        foregroundColor = isDark(backgroundColor)
          ? theme.colors.$pureWhite
          : theme.colors.$pureBlack
      }
    }

    if (routeDates.size === 0) {
      // Colors if there's no available route
      borderColor = theme.colors.$silver
      backgroundColor = theme.colors.$silver

      return {
        ...vehiclesData,
        borderColor,
        foregroundColor,
        backgroundColor,
        activeRange:
          calendarRange.minDate === calendarRange.maxDate
            ? { startDate: calendarRange.minDate }
            : { startDate: calendarRange.minDate, endDate: calendarRange.maxDate },
      }
    }

    const routeDatesArray = Array.from(routeDates)
    const startDate = routeDatesArray[0]
    const endDate =
      routeDatesArray[0] !== routeDatesArray[routeDatesArray.length - 1]
        ? routeDatesArray[routeDatesArray.length - 1]
        : undefined

    return {
      ...vehiclesData,
      foregroundColor,
      backgroundColor,
      borderColor,
      activeRange: { startDate, endDate },
    }
  }, [routesInfo, vehicles, calendarRange, minDate, maxDate, uvIds])
}
