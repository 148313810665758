import { ViewActions, ViewActionButton } from '@/formUi'

import { useTexts } from './useTexts'

type Props = {
  onEdit: () => void
}

export function PersonalizationViewActions(props: Props) {
  const { onEdit } = props

  const texts = useTexts()

  return (
    <ViewActions>
      <ViewActionButton
        testId="settings-personalization-edit-button"
        label={texts.edit}
        onClick={onEdit}
      />
    </ViewActions>
  )
}
