import type { NotificationFormType } from '@/notificationForms'
import type { FormError, FormField } from '@workwave-tidal/tidal/form-fairy'

import { Stack, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useFormMeta } from '@workwave-tidal/tidal/form-fairy'
import { useTestPhoneNotificationModal } from '@/components/modals/TestPhoneNotificationModal'
import { useTestEmailNotificationModal } from '@/components/modals/TestEmailNotificationModal'

import { useSubmitDisabled } from './hooks/useSubmitDisabled'
import { useFooterActions } from './hooks/useFooterActions'
import { useTexts } from './useTexts'

interface Props {
  notification: NotificationFormType
  onSubmit: (formValues: Readonly<any>) => Promise<boolean>
  cancel: () => void
}

export function FormFooter<
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS>,
>(props: Props) {
  const { cancel, onSubmit, notification } = props
  const texts = useTexts()

  // Connect the Footer to the active Form
  const { meta, api } = useFormMeta<FIELDS, ERROR>()

  // Access reset and submit actions
  const { onReset, onSubmit: submit } = useFooterActions<FIELDS, ERROR>(api, onSubmit, cancel)

  // Get submit disabled status
  const submitDisabled = useSubmitDisabled()

  // Resolve the form meta states to improve code readability
  const formPristine = meta.status === 'pristine'

  const { Modal: PhoneModal, show: showPhoneTestNotificationModal } =
    useTestPhoneNotificationModal()
  const { Modal: EmailModal, show: showEmailTestNotificationModal } =
    useTestEmailNotificationModal()

  const values = api.getValues()

  const show =
    values.notificationWay === 'PHONE'
      ? showPhoneTestNotificationModal
      : showEmailTestNotificationModal

  const Modal = values.notificationWay === 'PHONE' ? PhoneModal : EmailModal

  return (
    <Stack
      justifyContent="flex-start"
      direction="row"
      spacing={2}
      flexDirection="row"
      component="footer"
      paddingRight={4}
      paddingLeft={4}
      display="flex"
      paddingTop={4}
    >
      <LoadingButton
        color="primary"
        variant="contained"
        onClick={submit}
        disabled={submitDisabled}
        loading={meta.submitting}
        data-testid="save-notification"
        data-trackid="save-notification"
        className={`pendo-${notification}-save-notification`}
      >
        {texts.save}
      </LoadingButton>

      <Button
        className={`pendo-${notification}-test-notification`}
        data-testid="test-notification"
        data-trackid="test-notification"
        disabled={meta.submitting}
        color="primary"
        onClick={show}
      >
        {texts.test}
      </Button>

      <Button
        className={`pendo-${notification}-reset-notification`}
        disabled={formPristine || meta.submitting}
        data-testid="reset-notification"
        data-trackid="reset-notification"
        onClick={onReset}
        color="primary"
      >
        {texts.reset}
      </Button>

      <Button
        className={`pendo-${notification}-cancel-notification`}
        disabled={meta.submitting}
        data-testid="cancel-notification"
        data-trackid="cancel-notification"
        onClick={cancel}
        color="primary"
      >
        {texts.cancel}
      </Button>

      <Modal notification={notification} values={values} />
    </Stack>
  )
}
