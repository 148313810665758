import { FormHelperText, Typography } from '@mui/material'
import { differenceInDays } from 'date-fns'

import { useFormatDate } from '@/hooks'

import { ViewActions, ViewActionButton } from '@/formUi'

import { useResetEmailCounterModal } from './modals/ResetNotificationEmailCounter'
import { useTexts } from './useTexts'

interface Props {
  emailCount: number
  startDate: Date
}

export function SentEmailsCounter(props: Props) {
  const { emailCount, startDate } = props
  const { Modal: ResetEmailCounterModal, show: showResetEmailCounterModal } =
    useResetEmailCounterModal()
  const startDateAsString = useFormatDate(startDate)
  const texts = useTexts()

  const today = new Date()
  const days = differenceInDays(today, startDate) + 1

  return (
    <>
      <Typography>{texts.sentEmails(emailCount.toLocaleString())}</Typography>
      <FormHelperText>{texts.sentEmailsSince(startDateAsString, days)}</FormHelperText>

      <ViewActions paddingTop={1}>
        <ViewActionButton
          onClick={showResetEmailCounterModal}
          testId="reset-email-counter-button"
          label={texts.resetEmailCounter}
          color="error"
        />
      </ViewActions>

      <ResetEmailCounterModal />
    </>
  )
}
