import type { CSSProperties } from 'react'

import { useMemo } from 'react'
import { makeStyles } from '@mui/styles'

import { theme } from '@/styles'

const useClasses = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    borderRadius: '50%',
    color: 'var(--color)',
    backgroundColor: 'var(--backgroundColor)',
    fontSize: theme.fonts.$p3,
    fontWeight: theme.weights.$semiBold,
    overflow: 'hidden',
    width: 'var(--size)',
    height: 'var(--size)',
    marginRight: 'var(--marginRight)',
    border: `1px solid ${theme.colors.$pureWhite}`,
  },
})

interface Props {
  size: number
  backgroundColor: string
  color: string
  marginRight: number
}

export function useStyles(props: Props) {
  const { size, backgroundColor, color, marginRight } = props

  const classes = useClasses()

  const styles = useMemo(() => {
    return {
      container: {
        '--size': `${size}px`,
        '--color': color,
        '--backgroundColor': backgroundColor,
        '--marginRight': `${marginRight}px`,
      } as CSSProperties,
    } as const
  }, [size, backgroundColor, color, marginRight])

  return [classes, styles] as const
}
