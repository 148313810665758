import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { composeSchedulerLoads } from '@/local/server-data/domain/helpers/routeInfo'
import { selectOrdersMap } from '@/features/domain/order'
import * as veHelpers from '@/local/server-data/domain/helpers/vehicle'

export function useGetLoads(extRoute: uui.domain.client.rm.ExtendedRoute) {
  const { route } = extRoute
  const { vehicleId, steps, date } = route

  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const vehicles = useSelector(selectVehicles)
  const orders = useSelector(selectOrdersMap)

  return useMemo(() => {
    const uvId = vehiclesByVehicleId[vehicleId]

    // Retrieve vehicle
    if (!uvId) return

    const uv = vehicles[uvId]
    if (!uv.hasRoutingLicense) return

    const { vehicle } = uv

    const calendarizedSettings = veHelpers.getCalendarizedSettings(vehicle, date)

    return composeSchedulerLoads(orders, steps, calendarizedSettings)
  }, [vehicles, vehiclesByVehicleId, orders, vehicleId, steps, date])
}
