interface IconProps {
  facing: 'left' | 'right'
  size?: number | string
  fill?: string
  stroke?: string
  strokeWidth?: string | number
}

export function TriangleIcon(props: IconProps) {
  const { stroke = 'white', strokeWidth = 0, fill = 'white', facing } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 100 100"
      stroke={stroke}
      strokeWidth={strokeWidth}
      fill={fill}
    >
      {facing === 'right' && <path d="M 60,50 5,95 5,5 Z" />}
      {facing === 'left' && <path d="M 40 50 L 97.5 5 L 97.5 95 Z" />}
    </svg>
  )
}
