import { useEffect, useState, useRef } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

import { useAppDispatch } from '@/store'
import { fetchTerritoriesDriversAndVehicles } from '@/features/domain/territory'

export type CourierData =
  | {
      data: uui.domain.client.rm.TerritoriesDriversAndVehicles
      status: 'ready'
    }
  | {
      status: 'loading' | 'error'
    }

const CACHE_DURATION = 3 * 1000 // 3s

export function useFetchTerritoriesDriversAndVehicles() {
  const [state, setState] = useState<CourierData>({
    status: 'loading',
  })

  const lastCallTs = useRef<number>(0)

  const dispatch = useAppDispatch()

  useEffect(() => {
    let effectCleaned = false

    async function fetchData() {
      try {
        if (effectCleaned) return

        const now = new Date().getTime()
        if (now - lastCallTs.current < CACHE_DURATION) return

        const request = await dispatch(fetchTerritoriesDriversAndVehicles())

        if (effectCleaned) return

        if (fetchTerritoriesDriversAndVehicles.fulfilled.match(request)) {
          setState({
            status: 'ready',
            data: unwrapResult(request),
          })
          return
        }

        setState({ status: 'error' })
      } catch (e) {
        if (effectCleaned) return

        setState({ status: 'error' })
      }
    }

    fetchData()

    return () => {
      effectCleaned = true
    }
  }, [dispatch])

  return state
}
