import { setCrudSelection, setEditingState, setMainSelection } from '@/atoms'
import { getRouterNavigator } from '@/routing'
import { gis } from '@/server-data'

import { fitMap } from '../../../../atoms/map/utils/fitMap'
import { getMapSelectionContext } from '../../../../atoms/selection/read/getMapSelectionContext'
import { getDomainMarkerData } from '../../../../atoms/markerChangelog/domain/getDomainMarkerData'

export function doubleClickDepots(depotId?: string) {
  if (depotId) {
    const { mapMarkers } = getDomainMarkerData()
    const { type: selectionCtx } = getMapSelectionContext()

    const latLngCoordinates: uui.domain.LatLng[] = []

    const depot = mapMarkers.depot[depotId]

    if (!depot) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`Trying to double click a non-existent Depot with ID: ${depotId}`)
      }

      return
    }

    latLngCoordinates.push(gis.fromCoordinateToLatLng(depot.lonLat))

    // Set the new selection for both selectionContexts
    setMainSelection('depots', [depotId])
    setCrudSelection('depots', [depotId])

    // queue a pending fitMap request
    // it will be applied by the destination view when it opens
    fitMap(latLngCoordinates, { preventIfVisible: true }, 'add')

    // If the double click has been performed in the Setup Map let's set the editing state
    if (selectionCtx === 'crud') {
      setEditingState({ editing: true, ctx: 'depot', editingEntities: [depotId] })
    }

    // Navigate to the target page
    const navigate = getRouterNavigator()
    navigate('/setup/depots')
  }
}
