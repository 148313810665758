import styled from 'styled-components'

export const AddTrafficProfileHeader = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${p => p.theme.colors.$whiteSmoke};
  padding: 4px 16px;
`

AddTrafficProfileHeader.displayName = 'AddTrafficProfileHeader'
