import { useNavigoView } from '../../../hooks/useNavigoView'
import { OrderStats } from './OrderStats'
import { ExecutedOrderStats } from './ExecutedOrderStats'
import { UnassignedOrderStats } from './UnassignedOrderStats'
import { ExecutionDetailsStats } from './ExecutionDetailsStats'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

export function Single(props: Props) {
  const { extOrder } = props

  const { view } = useNavigoView()

  if (view === 'executionDetails') return <ExecutionDetailsStats extOrder={extOrder} />
  if (extOrder.isUnassigned) return <UnassignedOrderStats extOrder={extOrder} />
  if (!extOrder.orderStep.trackingData.isEmpty) return <ExecutedOrderStats extOrder={extOrder} />

  return <OrderStats extOrder={extOrder} />
}
