import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'
import { findFeature } from '../../findFeature'

import { findGeofenceMarker } from './findGeofenceMarker'
import { updateGeofenceFeature } from './updateGeofenceFeature'

const nonblockingRender = createNonBlockingRenderByTime()

export const changelogUpdateGeofenceFeatures = async (
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.Geofence>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['geofence'],
  selection: Set<string>,
  markerIds: IterableIterator<string>,
) => {
  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findGeofenceMarker(markers, markerId)
    if (!marker) {
      throw new Error(
        `Trying to update Geofence feature with lat-lng: ${markerId} but no marker exist on the map`,
      )
    }

    const feature = findFeature(layer, marker.id)

    if (!feature) {
      throw new Error(
        `Trying to update Geofence feature with lat-lng: ${marker.id} but no feature exist on the map`,
      )
    }

    const selected = selection.has(markerId)
    updateGeofenceFeature(mapStyles, marker, feature, selected)
  }

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
