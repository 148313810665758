import type { Notifications } from '../../../../../types'

import { useCallback } from 'react'
import { Box, ButtonBase } from '@mui/material'

import { Thick } from '@/icons'

import { useStyles } from './hooks/useStyles'
import { useTexts } from './useTexts'

type Props = {
  setQuery: (notification: Notifications) => void
  details: { subtitle: string; status?: string; enabled: boolean }
  notification: Notifications
  disabled: boolean
  active: boolean
  testId?: string
}

export function ListItem(props: Props) {
  const { notification, active, details, setQuery, disabled, testId } = props

  const [classes, styles] = useStyles(active, disabled)
  const texts = useTexts()

  const onClick = useCallback(() => setQuery(notification), [setQuery, notification])

  const title = texts.notificationTitle(notification)

  return (
    <ButtonBase
      className={classes.button}
      style={styles.button}
      disabled={disabled}
      onClick={onClick}
      data-testid={testId}
      data-trackid={testId}
    >
      <Box
        justifyContent="space-between"
        className={classes.container}
        style={styles.container}
        flexDirection="row"
        alignItems="center"
        display="flex"
      >
        <Box display="flex" flexDirection="column">
          <div className={classes.title}>{title}</div>

          <div className={classes.subtitle} style={styles.subtitle}>
            {details.subtitle}
          </div>

          {details.status && (
            <div className={classes.subtitle} style={styles.subtitle}>
              {details.status}
            </div>
          )}
        </Box>

        <Box className={classes.iconContainer} style={styles.icon}>
          {details.enabled && <Thick size={14} />}
        </Box>
      </Box>
    </ButtonBase>
  )
}
