import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Content } from './Content'

type Props = {
  orderStepIds: string[]
}

export function Root(props: Props) {
  return (
    <Modal modalId={modalId}>
      <Content orderStepIds={props.orderStepIds} />
    </Modal>
  )
}
