import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (count: number) => `${count} ${translate({ id: 'global.trafficRegions' })}`,
    description: translate({ id: 'setup.traffic.multipleTrafficRegionsSelected' }),
    edit: translate({ id: 'global.edit' }),
    delete: translate({ id: 'global.delete' }),
    exportKML: translate({ id: 'global.exportKML' }),
    confirmPanelTitle: translate({ id: 'setup.traffic.delete.title', values: { count: 2 } }),
  }))

  return api
}
