import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { mapAtom } from '../../../atoms/map/map'
import { findLayer } from '../../utils/findLayer'

import { refreshLocationPinFeature } from './utils/refreshLocationPinFeature'

export function updateLocationPinFeature(pin: uui.domain.ui.map.LocationPin): Feature<Geometry> {
  const map = mapAtom.map
  const layer = findLayer(map, 'pin')
  const feature = layer.getSource()?.getFeatureById(pin.id)

  if (!feature) {
    throw new Error('Feature not found (source is undefined)')
  }

  // update the pin
  refreshLocationPinFeature(pin, feature)

  return feature
}
