import { ViewActions, ViewActionButton } from '@/formUi'

import { useChangePasswordModal } from '../../modals/ChangePasswordModal'
import { useTexts } from './useTexts'

type Props = {
  onEdit: () => void
}

export function RmAccountViewActions(props: Props) {
  const { onEdit } = props

  const texts = useTexts()
  const { Modal: ChangePasswordModal, show: showChangePasswordModal } = useChangePasswordModal()

  return (
    <>
      <ViewActions>
        <ViewActionButton
          label={texts.edit}
          testId="settings-rm-account-edit-button"
          onClick={onEdit}
        />

        <ViewActionButton
          onClick={showChangePasswordModal}
          testId="settings-rm-account-change-password-button"
          label={texts.changePassword}
        />
      </ViewActions>

      {/* Inject the Modal's Root */}
      <ChangePasswordModal />
    </>
  )
}
