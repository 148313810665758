import { type PropsWithChildren } from 'react'

import { useAddCompanyModal } from './components/AddCompanyModal'
import { useRenameCompanyModal } from './components/RenameCompanyModal'
import { useDisableCompanyModal } from './components/DisableCompanyModal'
import { useDeleteCompanyModal } from './components/DeleteCompanyModal'
import { ModalsContextProvider } from './context/ModalsContextProvider'

export function ContextProvider(props: PropsWithChildren) {
  const { children } = props

  const { Modal: AddCompanyModal, show: onShowAddCompanyModal } = useAddCompanyModal()
  const { Modal: RenameCompanyModal, show: onShowRenameCompanyModal } = useRenameCompanyModal()
  const { Modal: DisableCompanyModal, show: onShowDisableCompanyModal } = useDisableCompanyModal()
  const { Modal: DeleteCompanyModal, show: onShowDeleteCompanyModal } = useDeleteCompanyModal()

  return (
    <ModalsContextProvider
      onShowAddCompanyModal={onShowAddCompanyModal}
      onShowRenameCompanyModal={onShowRenameCompanyModal}
      onShowDisableCompanyModal={onShowDisableCompanyModal}
      onShowDeleteCompanyModal={onShowDeleteCompanyModal}
    >
      {children}
      <AddCompanyModal />
      <RenameCompanyModal />
      <DisableCompanyModal />
      <DeleteCompanyModal />
    </ModalsContextProvider>
  )
}
