import type { ReactElement, ReactNode } from 'react'
import type { ValidFontSize } from '@/local/components'

import { HorizontalLayout } from '@/components/layout'
import { Text } from '@/local/components'
import { ExternalLink } from '@/icons'

import { ItemContainer } from './ItemContainer'
import { DropdownMenuIcon } from './DropdownMenuIcon'

type Props = {
  className?: string
  disabled?: boolean
  external?: boolean
  warn?: boolean
  LeftIcon?: ReactElement
  Icon?: ReactElement
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children?: ReactNode
  testid?: string
  size?: ValidFontSize
  weight?: '$light' | '$regular' | '$semiBold' | '$bold'
}

export function DropdownMenuItem(props: Props) {
  const {
    className,
    Icon,
    LeftIcon,
    children,
    onClick,
    external,
    warn,
    disabled,
    testid = 'menu-list-item',
    size = '$p2',
    weight = '$regular',
    ...rest
  } = props

  const IconToShow = Icon || (external ? <ExternalLink color="$scienceBlue" size={12} /> : null)

  return (
    <ItemContainer
      warn={warn}
      onClick={onClick}
      external={external}
      disabled={disabled}
      className={className}
      data-testid={testid}
      data-trackid={testid}
      {...rest}
    >
      <HorizontalLayout alignItems="center">
        {LeftIcon}

        <Text ellipsis weight={weight} size={size}>
          {children}
        </Text>

        {IconToShow && <DropdownMenuIcon>{IconToShow}</DropdownMenuIcon>}
      </HorizontalLayout>
    </ItemContainer>
  )
}
