import { useCallback } from 'react'
import { useIntl } from '@/intl'

export function useDescription() {
  const { translate } = useIntl()

  return useCallback(
    (routesCount: number, ordersCount: number, dispatchedRoutesCount: number) => {
      if (dispatchedRoutesCount) {
        if (dispatchedRoutesCount === routesCount) {
          return translate({
            id: 'dispatchPanel.routesDispatched',
            values: { count: dispatchedRoutesCount },
          })
        }

        return translate({
          id: 'dispatchPanel.routesDispatchedWithTotal',
          values: { count: dispatchedRoutesCount, total: routesCount },
        })
      }

      const routesMsg = `${translate({
        id: 'dispatchPanel.routes',
        values: { count: routesCount },
      })}`

      if (routesCount === 0) {
        return routesMsg
      }

      return `${routesMsg} (${translate({
        id: 'dispatchPanel.orders',
        values: { count: ordersCount },
      })})`
    },
    [translate],
  )
}
