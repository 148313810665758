import { PureComponent } from 'react'

import {
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyTimeWindowExceptions,
} from '@/forms-legacy'

import * as Texts from '../intl'
import { Props, FieldMode } from '../typings'

export default class Exceptions extends PureComponent<Props> {
  render() {
    const { timeWindowExceptions } = this.props

    if (timeWindowExceptions === FieldMode.hidden) {
      return null
    }

    const tweCount: number = Object.keys(timeWindowExceptions).length

    return (
      <>
        <ReadonlyBlock>
          <ReadonlyLabel primary empty={tweCount === 0}>
            {Texts.getTimeWindowExceptionsAndAlternatives()}
          </ReadonlyLabel>

          {tweCount > 0 && (
            <ReadonlyTimeWindowExceptions timeWindowExceptions={timeWindowExceptions} />
          )}
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
