import type { ReactElement, ReactChild, ReactFragment } from 'react'

import { Scrollbar } from '@workwave-tidal/tidal/components'

import { useEffect, useState, useRef } from 'react'

import { useComputeOutOfViewportHeight } from './hooks/useComputeOutOfViewportHeight'

type Props = {
  header?: ReactElement
  footer?: ReactElement
  children?: ReactChild | ReactFragment
}

type FooterMode = 'inside-scrollbar' | 'outside-scrollbar'

export function FormContent(props: Props) {
  const { header = null, footer = null, children } = props

  const rFooter = useRef<HTMLDivElement | null>(null)
  const rContainer = useRef<HTMLDivElement | null>(null)

  const footerHeight = rFooter.current?.offsetHeight ?? 0

  // The Scrollbar notifies when the height of the content outside the visible port change.
  // We track the outside-screen height to determine when the Footer should be inside or outside
  // the scrollable container.
  const { onSizeChange, outOfViewportHeight } = useComputeOutOfViewportHeight(rContainer.current)
  const [footerMode, setFooterMode] = useState<FooterMode>('outside-scrollbar')

  // normalize outOfViewportHeight by removing the footer height
  // when the footer is already inside the scrollbar container.
  const outOfViewportHeightWithoutFooter =
    footerMode === 'inside-scrollbar' ? outOfViewportHeight - footerHeight : outOfViewportHeight

  const scrollbarVisible = outOfViewportHeightWithoutFooter > 0
  const scrollbarWillAppear =
    footerMode === 'outside-scrollbar' &&
    !scrollbarVisible &&
    Math.abs(outOfViewportHeight) < footerHeight

  useEffect(() => {
    let effectCleaned = false

    const nextFooterMode: FooterMode =
      scrollbarVisible || scrollbarWillAppear ? 'outside-scrollbar' : 'inside-scrollbar'

    if (effectCleaned) return

    setFooterMode(nextFooterMode)

    return () => {
      effectCleaned = true
    }
  }, [scrollbarVisible, scrollbarWillAppear])

  return (
    <>
      {header}
      <div style={{ width: '100%', height: '100%' }} ref={rContainer}>
        <Scrollbar hideOverflow="x" onSizeChange={onSizeChange}>
          <div style={{ width: '100%' }} data-testid="scrollbar-content">
            {children}
            {footerMode === 'inside-scrollbar' && <div ref={rFooter}>{footer}</div>}
          </div>
        </Scrollbar>
      </div>

      {footerMode === 'outside-scrollbar' && <div ref={rFooter}>{footer}</div>}
    </>
  )
}
