import { useSelector } from 'react-redux'

import { selectGpsTrackingProvider } from '@/features/domain/user'

import { RoutePathsSubMenu } from '../../RoutePathsSubMenu'
import { RoadEditsSubMenu } from '../../RoadEditsSubMenu'
import { VehiclesSubMenu } from '../../VehiclesSubMenu'
import { DevicesSubMenu } from '../../DevicesSubMenu'
import { RegionsSubMenu } from '../../RegionsSubMenu'
import { OrdersSubMenu } from '../../OrdersSubMenu'
import { DepotsSubMenu } from '../../DepotsSubMenu'
import { PlacesSubMenu } from '../../PlacesSubMenu'

interface Props {
  menuOpen: boolean
}

export function Rm360SubMenu(props: Props) {
  const { menuOpen } = props
  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const isNotTelematics = trackingProvider !== 'telematics'

  return (
    <>
      <OrdersSubMenu parentOpen={menuOpen} />
      <VehiclesSubMenu parentOpen={menuOpen} />
      {isNotTelematics && <DevicesSubMenu parentOpen={menuOpen} />}
      <RoutePathsSubMenu parentOpen={menuOpen} />
      <DepotsSubMenu parentOpen={menuOpen} />
      {isNotTelematics && <PlacesSubMenu parentOpen={menuOpen} />}
      <RegionsSubMenu parentOpen={menuOpen} />
      {isNotTelematics && <RoadEditsSubMenu parentOpen={menuOpen} />}
    </>
  )
}
