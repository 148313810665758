import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

/**
 * Update a Driver.
 *
 * @private
 */
export const updateDrivers = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  uui.domain.ui.forms.BulkDriverUpdate,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/driver/update', async (bulkDriverUpdateValues, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/driver/bulkUpdate', {
      category: 'rpc',
      type: 'rpc/driver/bulkUpdate',
      payload: bulkDriverUpdateValues,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {updateDrivers}`,
      { tags: ['rpc', 'driver'] },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Update Failed`,
      error,
    })
  }
})
