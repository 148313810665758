import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerDescription: (gpsId: string = '') =>
      translate({ id: 'vehicles.form.gpsDeviceHeader', values: { gpsId } }),
    expand: translate({ id: 'vehicles.form.generalSettings.gpsExpandingTitle' }),
    edit: translate({ id: 'global.edit' }),
    deviceSettings: (deviceId: string) =>
      translate({ id: 'vehicles.form.generalSettings.deviceSettings', values: { deviceId } }),
  }))

  return api
}
