import { addDays, isBefore, subDays } from 'date-fns/esm'
import { memoizeOne } from '@/server-data'

export const getSelectableInterval = memoizeOne(
  (date: Date, intervalLength: number, lastSelectableDate: Date): [Date, Date] => {
    const newIntervalEnd = addDays(date, intervalLength - 1)

    if (isBefore(newIntervalEnd, lastSelectableDate)) {
      return [date, newIntervalEnd]
    } else {
      const newIntervalStart = subDays(lastSelectableDate, intervalLength - 1)

      return [newIntervalStart, lastSelectableDate]
    }
  },
)
