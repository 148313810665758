import { useSelector } from 'react-redux'
import { selectTerritories, selectTerritoryId } from '@/features/domain/territory'
import { selectUserPrivileges } from '@/features/domain/user'

export function useIsForeignUser() {
  const territoryId = useSelector(selectTerritoryId)
  const territories = useSelector(selectTerritories)
  const userPrivileges = useSelector(selectUserPrivileges)
  const hasDevPrivileges = userPrivileges.includes('DEV')

  if (!territoryId) return true

  const destinationTerritory = territories[territoryId]

  if (hasDevPrivileges) {
    return (
      destinationTerritory.country !== 'US' &&
      destinationTerritory.country !== 'CA' &&
      destinationTerritory.country !== 'GB' &&
      destinationTerritory.country !== 'IT' // ONLY FOR DEVS
    )
  }

  return (
    destinationTerritory.country !== 'US' &&
    destinationTerritory.country !== 'CA' &&
    destinationTerritory.country !== 'GB'
  )
}
