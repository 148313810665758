import type { ReactElement } from 'react'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns/esm'
import { makeStyles } from '@mui/styles'

import { VerticalLayout } from '@/components/layout'
import { selectUserConfiguration } from '@/features/domain/user'

import { EligibilityTypePicker } from './components/EligibilityTypePicker'
import { CalendarPicker } from './components/CalendarPicker'

type Props = {
  value: uui.domain.client.rm.Eligibility
  onChange: (eligibility: uui.domain.client.rm.Eligibility) => void
  messages?: ReactElement
}

const useStyles = makeStyles({
  calendar: {
    marginTop: 15,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
})

export function EligibilityPicker(props: Props) {
  const { value, onChange, messages } = props

  const classes = useStyles()
  const { today } = useSelector(selectUserConfiguration)

  const handleOnChangeType = useCallback(
    (type: uui.domain.client.rm.Eligibility['type']) => {
      switch (type) {
        case 'any':
          onChange({
            type: 'any',
          })
          return

        case 'on': {
          onChange({
            type: 'on',
            dates: [today],
          })
          return
        }

        case 'by': {
          onChange({
            type: 'by',
            date: today,
          })
          return
        }
      }
    },
    [onChange, today],
  )

  const handleOnChangeDate = useCallback(
    (type: uui.domain.client.rm.Eligibility['type'], dates: Date[]) => {
      switch (type) {
        case 'any':
          onChange({
            type: 'any',
          })
          return

        case 'on': {
          const datesAsString = dates.map(d => format(d, 'yyyyMMdd'))
          onChange({
            type: 'on',
            dates: datesAsString,
          })
          return
        }

        case 'by': {
          const date = format(dates[0], 'yyyyMMdd')
          onChange({
            type: 'by',
            date,
          })
          return
        }
      }
    },
    [onChange],
  )

  return (
    <VerticalLayout>
      <EligibilityTypePicker type={value.type} onChangeType={handleOnChangeType} />

      <div className={classes.calendar}>
        <CalendarPicker eligibility={value} onChange={handleOnChangeDate} />
      </div>
      {!!messages && messages}
    </VerticalLayout>
  )
}
