import { ItemRendererSpec } from '../../typings'

import { ItemRendererDefault } from './ItemRendererDefault'
import { getItemHeight } from './utils'
import { Props } from './typings'

export const ItemRendererDefaultSpec: ItemRendererSpec<Props> = {
  renderer: ItemRendererDefault,
  getItemHeight,
}
