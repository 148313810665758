import { useState } from 'react'
import { useConfigureModalController, useModalController, useCloseModal } from '@/atoms'

type ModalState = 'ready' | 'submitting'
interface ModalData {
  placeIds: string[]
}

export const modalId = 'exportPlaces'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = (placeIds: string[]) => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        placeIds,
      },
    }),
  }))

  return useConfigureModalController<ModalData, ModalState>(modalId, options)
}
