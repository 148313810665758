import { PureComponent } from 'react'

import { Truck, Order } from '@/icons'
import { ItemRendererDetailRow, ItemRendererLabel, ItemRendererIcon } from '@/forms-legacy'

import * as Texts from '../intl'

type Props = {
  vehiclesCount: number
  ordersCount: number
  selected?: boolean
}

export default class DetailRow extends PureComponent<Props> {
  render() {
    const { vehiclesCount, ordersCount, selected } = this.props

    return (
      <ItemRendererDetailRow>
        <ItemRendererIcon selected={selected}>
          <Truck />
        </ItemRendererIcon>
        <ItemRendererLabel selected={selected}>
          {Texts.getVehiclesCountText(vehiclesCount)}
        </ItemRendererLabel>

        <ItemRendererIcon selected={selected}>
          <Order />
        </ItemRendererIcon>
        <ItemRendererLabel selected={selected}>
          {Texts.getSimulationOrdersCountText(ordersCount)}
        </ItemRendererLabel>
      </ItemRendererDetailRow>
    )
  }
}
