import { useEffect, useState } from 'react'
import { reverseGeocode } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'
import { geo } from '@/server-data'

export function useComputedLocation(location: string | uui.domain.server.LatLngTuple | undefined) {
  const [computedLocation, setComputedLocation] = useState<string | undefined>('-')
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!Array.isArray(location)) {
      setComputedLocation(typeof location === 'string' ? location : '-')
      return
    }

    async function getAddress(latLng: uui.domain.LatLng, updateLocation: (value?: string) => void) {
      const response = await dispatch(reverseGeocode({ latLng }))
      if (reverseGeocode.rejected.match(response)) {
        throw new Error('Request failed')
      }
      updateLocation(response.payload.geoAddress)
    }

    getAddress(geo.latLngFromJSON(location), setComputedLocation)
    setComputedLocation(undefined)
  }, [dispatch, location])

  return computedLocation
}
