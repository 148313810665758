import styled from 'styled-components'

const styledComponent = styled.span`
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 20px;
  height: 24px;
  overflow: hidden;
  border-bottom-right-radius: inherit;
  transform: translateZ(0);

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: currentColor;
    transform: translate(42%, 44%) rotate(45deg);
    border-radius: inherit;
  }
`

styledComponent.displayName = 'OrderAvatarSlice'
export default styledComponent
