import { useCallback, useRef, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

import { exportOrders, getExportOrderColumns } from '@/features/domain/order'
import { useDelayedCallback, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { setExportOrdersPreferences } from '@/atoms'

import { columnSelectionAtom, setColumnSelection } from '../atom/columnSelection'
import { useTexts } from '../useTexts'

import { type Column, useController } from './useController'
import { useCreateColumns } from './useCreateColumns'

const unique = (ids: string[]) => [...new Set(ids)]

export const useControllerActions = () => {
  const [isLoading, setIsLoading] = useState(false)
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const toastId = useRef<string | number>(-1)

  const {
    close,
    updateData,
    data: {
      selectedOrders,
      format,
      keepEnglishColumns,
      columnSelection,
      columnsState,
      customizeColumns,
    },
  } = useController()

  const createColumns = useCreateColumns()

  const [showToastAndCloseModalAfterTimer, clearToastTimer] = useDelayedCallback(() => {
    toastId.current = toast(texts.preparingExport, {
      autoClose: false,
      closeOnClick: true,
      theme: 'light',
    })
    close?.()
  }, 2000)

  const exportOrder = useCallback(async () => {
    try {
      if (columnSelection.length === 0) {
        updateData({ showEmptySelectionWarning: true })
        return
      }

      setIsLoading(true)

      // remove some possible duplicates
      const orderIds = unique(selectedOrders.map(order => order.order.id))

      if (columnsState.status !== 'complete') {
        throw new Error('columns status must be complete')
      }

      const columnsSet = columnsState.columns.reduce<Record<string, string>>((acc, curr) => {
        acc[curr.id] = curr.label
        return acc
      }, {})

      const requestedColumns = columnSelection.map(columnId => ({
        columnId,
        label: columnsSet[columnId],
      }))

      showToastAndCloseModalAfterTimer()

      const exportRequest = await dispatch(exportOrders({ orderIds, format, requestedColumns }))

      // DEBUG
      // await new Promise(resolve => setTimeout(resolve, 5000))

      if (exportOrders.fulfilled.match(exportRequest)) {
        const url = unwrapResult(exportRequest)
        if (url) {
          window.open(url)
        }
      }

      setExportOrdersPreferences({
        format,
        customizeColumns,
        keepEnglishColumns,
      })

      setColumnSelection(columnSelection)

      toast.dismiss(toastId.current)
      clearToastTimer()
      close?.()
    } catch (e) {
      toast.error(texts.genericExportOrdersError)
    }

    setIsLoading(false)
  }, [
    close,
    texts,
    toast,
    format,
    columnsState,
    dispatch,
    updateData,
    selectedOrders,
    clearToastTimer,
    columnSelection,
    showToastAndCloseModalAfterTimer,
    customizeColumns,
    keepEnglishColumns,
  ])

  const exportOrderLegacy = useCallback(async () => {
    try {
      setIsLoading(true)

      // remove some possible duplicates
      const orderIds = unique(selectedOrders.map(order => order.order.id))

      showToastAndCloseModalAfterTimer()

      const exportRequest = await dispatch(
        exportOrders({ orderIds, format, requestedColumns: undefined, includeBarcodes: true }),
      )

      // DEBUG
      // await new Promise(resolve => setTimeout(resolve, 5000))

      if (exportOrders.fulfilled.match(exportRequest)) {
        const url = unwrapResult(exportRequest)
        if (url) {
          window.open(url)
        }
      }

      setExportOrdersPreferences({
        format,
        customizeColumns: false,
        keepEnglishColumns: false,
      })

      toast.dismiss(toastId.current)
      clearToastTimer()
      close?.()
    } catch (e) {
      toast.error(texts.genericExportOrdersError)
    }

    setIsLoading(false)
  }, [
    close,
    texts,
    toast,
    format,
    dispatch,
    selectedOrders,
    clearToastTimer,
    showToastAndCloseModalAfterTimer,
  ])

  const onChangeIncludeBarcodes = useCallback(
    (_e, checked) => updateData({ includeBarcodes: checked }),
    [updateData],
  )

  const onChangeCustomizeColumns = useCallback(
    (_e, checked) => updateData({ customizeColumns: checked }),
    [updateData],
  )

  const onChangeFormat = useCallback(
    (_e, value: string) => {
      if (!value) return
      updateData({ format: value as typeof format })
    },
    [updateData],
  )

  const onChangeKeepEnglishColumns = useCallback(
    (keepEnglishColumns: boolean) => {
      updateData({ keepEnglishColumns })
    },
    [updateData],
  )

  const onNextButtonClick = useCallback(async () => {
    try {
      updateData({ columnsState: { status: 'loading' } })

      const orderIds = unique(selectedOrders.map(order => order.order.id))
      const result = await dispatch(getExportOrderColumns(orderIds))

      if (getExportOrderColumns.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      const columns = await createColumns(result.payload, keepEnglishColumns)

      const columnSelection = replacePlaceholderInsideSelection(
        columnSelectionAtom.selection,
        columns,
      )

      updateData({
        wizardStep: 'configure-columns',
        columnsState: { status: 'complete', columns },
        columnSelection,
      })
    } catch (e) {
      updateData({ columnsState: { status: 'error' } })
      toast.error(texts.genericExportOrdersError)
    }
  }, [updateData, keepEnglishColumns, dispatch, createColumns, texts, toast, selectedOrders])

  const onPrevButtonClick = useCallback(() => {
    updateData({ wizardStep: 'configure-format' })
  }, [updateData])

  const onColumnSelectionChange = useCallback(
    (columnSelection: string[]) => {
      updateData({ columnSelection, showEmptySelectionWarning: false })
    },
    [updateData],
  )

  return {
    exportOrder,
    exportOrderLegacy,
    onChangeFormat,
    onChangeIncludeBarcodes,
    isLoading,
    onChangeKeepEnglishColumns,
    onNextButtonClick,
    onPrevButtonClick,
    onColumnSelectionChange,
    onChangeCustomizeColumns,
  }
}

function replacePlaceholderInsideSelection(selection: readonly string[], columns: Column[]) {
  const groups = {
    loads: [] as string[],
    pickupCf: [] as string[],
    deliveryCf: [] as string[],
    serviceCf: [] as string[],
  }

  const columnsIds = new Set(columns.map(c => c.id))

  for (const { id } of columns) {
    if (id.startsWith('CF_D_')) {
      groups.deliveryCf.push(id)
    }
    if (id.startsWith('CF_P_')) {
      groups.pickupCf.push(id)
    }
    if (id.startsWith('CF_S_')) {
      groups.serviceCf.push(id)
    }
    if (id.startsWith('L_')) {
      groups.loads.push(id)
    }
  }

  const result = selection.reduce<string[]>((acc, id) => {
    if (id === 'loads') {
      acc.push(...groups.loads)
      return acc
    }

    if (id === 'P_customfields') {
      acc.push(...groups.pickupCf)
      return acc
    }

    if (id === 'S_customfields') {
      acc.push(...groups.serviceCf)
      return acc
    }

    if (id === 'D_customfields') {
      acc.push(...groups.deliveryCf)
      return acc
    }

    if (columnsIds.has(id)) {
      acc.push(id)
    }

    return acc
  }, [] as string[])

  return result
}
