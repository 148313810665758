import styled from 'styled-components'

const EmptyStateIcon = styled.div`
  width: 20px;
  color: inherit;
  margin-bottom: 6px;
`

EmptyStateIcon.displayName = 'EmptyStateIcon'
export default EmptyStateIcon
