import type { RejectError } from '../domain/typings'
import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { setConnectionStatus } from '@/atoms'

export const updateNetworkConnectionStatus = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  boolean,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('networkConnectionStatus/update', async (online: boolean, thunkApi) => {
  try {
    const { authenticated } = thunkApi.getState().domain.publicData.userConfiguration

    // If the user is authenticated trigger the RPC
    if (authenticated) {
      const rpc = thunkApi.extra.domainProxy.getRpc()

      const result = await rpc('rpc/networkConnectionStatus/update', {
        category: 'rpc',
        type: 'rpc/networkConnectionStatus/update',
        online,
      })

      if (result.type === 'rpc-failure') {
        // JOURNAL: trigger the journal
        return thunkApi.rejectWithValue({
          type: 'rpcFailure',
          message: result.errorMessage,
          error: result,
        })
      }
    }
    // If the user is not authenticated update applicationMode atom
    else {
      setConnectionStatus(online ? 'online' : 'uiOffline')
    }
  } catch (error) {
    // JOURNAL: trigger the journal
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Update connection status Failed',
      error,
    })
  }
})
