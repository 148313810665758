import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectDepots } from '@/features/domain/depot'

export const useDepotsRoot = () => {
  const { editing } = useEditingStateWithCtx('depot')
  const [selection] = useCrudSelection('depots')
  const depots = useSelector(selectDepots)
  const selectedDepots = useMemo(() => getSelectedDepots(selection, depots), [selection, depots])

  return {
    depots,
    selectedDepots,
    editing,
    selection,
  }
}

const getSelectedDepots = (
  selection: workwave.DeepReadonly<string[]>,
  depotsMap: Record<string, uui.domain.client.rm.Depot>,
): uui.domain.client.rm.Depot[] => {
  // selection could hold some ids that are not in the depotsMap due to server-data computation
  // depotsMap holds the "truth"
  return selection.reduce<uui.domain.client.rm.Depot[]>((acc, id) => {
    if (depotsMap[id]) {
      acc.push(depotsMap[id])
    }
    return acc
  }, [])
}
