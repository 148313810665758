import type { ListIconItem } from '../../typings'

import { useCallback } from 'react'

import { Icon } from '../Icon'

import { IconsListContainer } from './IconsListContainer'
import { IconsListIcon } from './IconsListIcon'

interface Props {
  onChange: (value: number) => void
  setMode: (state: 'closed' | 'open') => void
  icons: Record<number, ListIconItem>
  value: number
}

export function IconsList(props: Props) {
  const { onChange, setMode, icons, value } = props

  const onClick = useCallback(
    (id: number) => {
      onChange(id)
      setMode('closed')
    },
    [onChange, setMode],
  )

  return (
    <IconsListContainer data-testid="IconsList">
      {Object.values(icons).map(icon => {
        const { id, preset } = icon
        const selected = value === id

        return (
          <IconsListIcon
            disabled={selected}
            selected={selected}
            data-testid="IconsList-item"
            data-icon={preset}
            onClick={() => onClick(id)}
            key={id}
          >
            <Icon preset={preset} />
          </IconsListIcon>
        )
      })}
    </IconsListContainer>
  )
}
