import { SvgIcon, type SvgIconProps } from '@mui/material'

export function Radius(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
      />
      <path d="M17 9v2h-3.268A2 2 0 0 0 10 12a2 2 0 0 0 3.732 1H17v2l3-3-3-3Z" />
    </SvgIcon>
  )
}
