import { useState } from 'react'

import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

type ModalState = 'ready'

export const modalId = 'orderStepNotificationModal'

export const useController = () =>
  useModalController<uui.domain.client.rm.OrderStepEmailOrSMSNotification, ModalState>(modalId)

export const useConfigureController = (
  data: uui.domain.client.rm.OrderStepEmailOrSMSNotification,
) => {
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data,
    }),
  }))

  return useConfigureModalController<
    uui.domain.client.rm.OrderStepEmailOrSMSNotification,
    ModalState
  >(modalId, options)
}
