import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: translate({ id: 'vehicles.form.edit.generalSettings.title' }),
    headerDescription: translate({ id: 'vehicles.form.edit.generalSettings.subtitle' }),
    errors: translate({
      id: 'vehicles.form.edit.generalSettings.errorTitle',
    }),
    save: (create?: boolean) =>
      translate({
        id: create
          ? 'vehicles.form.edit.generalSettings.create'
          : 'vehicles.form.edit.generalSettings.update',
      }),
    saveTitle: (valid: boolean, planLocked: boolean, pristine: boolean) => {
      if (!valid) return translate({ id: 'vehicles.form.submitButton.disabled.title.invalid' })
      if (planLocked)
        return translate({ id: 'vehicles.form.submitButton.disabled.title.planLocked' })
      if (pristine) {
        return translate({ id: 'vehicles.form.submitButton.disabled.title.pristine' })
      }
      return ''
    },
    cancel: translate({ id: 'vehicles.form.edit.generalSettings.discard' }),
    create: translate({ id: 'vehicles.form.edit.generalSettings.create.info' }),
  }))

  return api
}
