import { useCallback, useEffect, useState, useRef } from 'react'
import { proxy, useSnapshot } from 'valtio'

export interface ModalController<MODAL_DATA = any, MODAL_STATE extends string = string> {
  data: MODAL_DATA
  ready: boolean
  invalid: boolean
  disabled: boolean

  // 'loading' | 'ready' | 'pending' | 'submitting' | 'failure' | 'failure'
  status: MODAL_STATE

  close?: () => void
}

type InitialModalController<MODAL_DATA = any, MODAL_STATE extends string = string> = Partial<
  ModalController<MODAL_DATA, MODAL_STATE>
> & { data: MODAL_DATA }

interface ModalInitializer<MODAL_DATA, MODAL_STATE extends string> {
  disableAutoClear?: boolean
  createInitialState?: () => InitialModalController<MODAL_DATA, MODAL_STATE>
}

type ModalControllerAtom = { modals: Record<string, ModalController> }

// ------------------------------------
// Default value
// ------------------------------------

const defaultModalControllerValue = {
  data: {},
  ready: false,
  invalid: false,
  status: 'ready',
  disabled: false,
}

// ------------------------------------
// Routing layout atom
// ------------------------------------

/**
 * Atom-family used by Modal windows to store data and statuses.
 * The usage of this atom involves a two-step process:
 *
 * 1. The outer possible component of the Modal window, ideally its Root Component,
 *    must use `useConfigureModalController` to initialize the atom-family data for the
 *    active instance of the Modal window.
 * 2. Every UI component interested in reading or writing Modals data should use
 *    `useModalController` to access the atom content.
 */
const modalControllerAtom = proxy<ModalControllerAtom>({
  modals: {},
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetModalController = (prev: ModalController) => ModalController
type SetModalControllerParam = SetModalController | Partial<ModalController>

function resetModalController(modalId: string) {
  modalControllerAtom.modals[modalId] = { ...defaultModalControllerValue }
}

export function resetAllModalControllers() {
  modalControllerAtom.modals = {}
}
function setModalController(modalId: string, valueOrFunc: SetModalControllerParam) {
  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(
      modalControllerAtom.modals[modalId],
      valueOrFunc(modalControllerAtom.modals[modalId]),
    )
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      modalControllerAtom.modals[modalId][field] = valueOrFunc[field]
    }
  }

  return modalControllerAtom
}

function configureModalController(modalId: string) {
  if (modalControllerAtom.modals[modalId]) return

  modalControllerAtom.modals[modalId] = { ...defaultModalControllerValue }
}

// ------------------------------------
// React hooks
// ------------------------------------

function useCtrlState(modalId: string) {
  if (!modalControllerAtom.modals[modalId]) {
    configureModalController(modalId)
  }

  const setCtrlState = useCallback(
    (valueOrFunc: SetModalControllerParam) => {
      setModalController(modalId, valueOrFunc)
    },
    [modalId],
  )

  return [useSnapshot(modalControllerAtom.modals[modalId]), setCtrlState] as const
}

/**
 * Hook used to configure a Modal window data-controller instance.
 * If used more than once per a single Modal instance will fails gracefully
 * ignoring all the configuration hooks after the first.
 *
 * @param modalId - Unique identifier of the Modal window accessing the Modal controller
 * @param initializer - Modal Controller configuration, the configuration is not dynamic, changing its value after the hook is created will produce no visible results.
 * @param initializer.disableAutoClear - Optional flag to prevent the reset of the Modal controller when the hook is unmounted.
 * @param initializer.createInitialState - Optional function providing the initial state for the Modal controller.
 */
export function useConfigureModalController<MODAL_DATA, MODAL_STATE extends string = string>(
  modalId: string,
  initializer?: ModalInitializer<MODAL_DATA, MODAL_STATE>,
) {
  const [ctrlState, setCtrlState] = useCtrlState(modalId)

  const [initialState] = useState<InitialModalController<MODAL_DATA> | undefined>(
    initializer?.createInitialState,
  )

  const [disabled, setDisabled] = useState(false)
  const [disableAutoClear] = useState(() => initializer?.disableAutoClear ?? false)
  const api = useRef({ ready: ctrlState.ready, disabled, disableAutoClear })

  useEffect(() => {
    api.current = { ...api.current, ready: ctrlState.ready, disabled }
  }, [ctrlState, disabled])

  // --------------------------------------------------------------------
  // --------------------------------------------------------------------
  // Run once to initialize the modal controller state

  useEffect(() => {
    if (api.current.ready) {
      if (process.env.NODE_ENV === 'development') {
        console.error(`Trying to configure an already configured Modal Controller. This is a noop!`)
      }

      setDisabled(true)
      return
    }

    if (initialState) {
      // Initialize the modal controller state
      setCtrlState(draft => {
        // required
        draft.ready = true
        draft.data = initialState.data

        // optional
        draft.close = initialState.close ?? draft.close
        draft.status = initialState.status ?? draft.status
        draft.invalid = initialState.invalid ?? draft.invalid
        draft.disabled = initialState.disabled ?? draft.disabled

        return draft
      })
    } else {
      // if no initial data has been provided set the controller state to ready anyway
      setCtrlState(draft => {
        draft.ready = true

        return draft
      })
    }
  }, [setCtrlState, initialState])

  // --------------------------------------------------------------------
  // --------------------------------------------------------------------
  // clearing modal controller when the owner of the hook is unmounted

  useEffect(
    () => () => {
      const enabled = !api.current.disabled
      const autoClear = !api.current.disableAutoClear

      if (enabled && autoClear) {
        resetModalController(modalId)
      }
    },
    [modalId],
  )

  // --------------------------------------------------------------------
  // --------------------------------------------------------------------
  // API

  return useModalController<MODAL_DATA, MODAL_STATE>(modalId)
}

/**
 * Hook used to access a Modal window data-controller instance.
 *
 * @param modalId - Unique identifier of the Modal window accessing the Modal controller
 */
export function useModalController<MODAL_DATA, MODAL_STATE extends string = string>(
  modalId: string,
) {
  const [ctrlState, setCtrlState] = useCtrlState(modalId)
  const resetCtrlState = useCallback(() => {
    resetModalController(modalId)
  }, [modalId])

  // --------------------------------------------------------------------
  // --------------------------------------------------------------------
  // data updater

  const updateCtrlState = useCallback(
    (partialCtrlState: Partial<ModalController<MODAL_DATA, MODAL_STATE>>, replaceData = false) => {
      setCtrlState(draft => {
        for (const [tKey, value] of Object.entries(partialCtrlState)) {
          const key = tKey as keyof ModalController<MODAL_DATA, MODAL_STATE>

          if (key === 'ready') {
            if (process.env.NODE_ENV === 'development') {
              console.error(
                `Trying to manually set a Modal controller "ready" state. This is a noop!`,
              )
            }
            continue
          }

          if (key === 'data' && !replaceData) {
            const data = value as MODAL_DATA
            draft[key] = { ...draft[key], ...data }
          } else {
            // @ts-expect-error
            draft[key] = value
          }
        }

        return draft
      })
    },
    [setCtrlState],
  )

  // --------------------------------------------------------------------
  // --------------------------------------------------------------------
  // API

  const { data, invalid, status, disabled, close, ready } = ctrlState

  const updateData = useCallback(
    // @ts-expect-error
    (nextData: Partial<MODAL_DATA>) => updateCtrlState({ data: nextData }),
    [updateCtrlState],
  )

  const replaceData = useCallback(
    (nextData: MODAL_DATA) => updateCtrlState({ data: nextData }, true),
    [updateCtrlState],
  )

  return {
    // optional method used to close the Modal window instance
    close,

    // ready state, always set to true after the atom-family has been properly initialized
    ready,

    // invalid state, controlled by the Modal logic
    invalid,
    // disabled state, controlled by the Modal logic
    disabled,

    // Modal's data
    data: data as MODAL_DATA,

    // Modal's status, can be any string but should be always narrowed to a limited set of strings
    // suggested values: 'loading' | 'ready' | 'pending' | 'submitting' | 'failure' | 'failure'
    status: status as MODAL_STATE,

    // Main update function, allow to write the whole modal controller object.
    // Doesn't allow to change manually the `ready` state
    update: updateCtrlState,

    // Reset the Modal controller state
    reset: resetCtrlState,

    // Helper method supporting partial update of the Modal controller's data
    updateData,

    // Helper method supporting full replace of the Modal controller's data
    replaceData,
  } as const
}
