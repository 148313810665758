import type { ModalData } from './typings'

import { useState } from 'react'
import { useIntl } from '@/intl'

type ErrorType = Exclude<ModalData['issue'], 'none'>

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: (orderStepId: uui.domain.client.rm.OrderStepIdentifier) => {
      return orderStepId === 'p'
        ? translate({ id: 'modals.movePairedOrders.header.title.pickup' })
        : translate({ id: 'modals.movePairedOrders.header.title.delivery' })
    },

    headerSubTitle: (
      orderStepId: uui.domain.client.rm.OrderStepIdentifier,
      name: string,
      serviceTime: string,
    ) => {
      const id =
        orderStepId === 'p'
          ? 'modals.movePairedOrders.header.text.pickup'
          : 'modals.movePairedOrders.header.text.delivery'

      return translate({ id, values: { name, serviceTime } })
    },

    btnDoNotMove: (pairedUnassigned: boolean) => {
      return pairedUnassigned
        ? translate({ id: 'modals.movePairedOrders.button.doNotMove.unassigned' })
        : translate({ id: 'modals.movePairedOrders.button.doNotMove' })
    },

    btnCancel: translate({ id: 'modals.movePairedOrders.button.cancel' }),

    btnMove: (orderStepId: uui.domain.client.rm.OrderStepIdentifier, pairedUnassigned: boolean) => {
      const pickUp = orderStepId === 'p'
      if (pickUp) {
        return pairedUnassigned
          ? translate({ id: 'modals.movePairedOrders.button.move.pickup.unassigned' })
          : translate({ id: 'modals.movePairedOrders.button.move.pickup' })
      }

      return pairedUnassigned
        ? translate({ id: 'modals.movePairedOrders.button.move.delivery.unassigned' })
        : translate({ id: 'modals.movePairedOrders.button.move.delivery' })
    },

    btnCancelTitle: translate({ id: 'modals.movePairedOrders.button.cancel.title' }),

    savePreference: translate({ id: 'modals.movePairedOrders.savePreferenceText' }),

    cardTitle: (
      orderStepId: uui.domain.client.rm.OrderStepIdentifier,
      pairedUnassigned: boolean,
    ) => {
      const pickUp = orderStepId === 'p'

      if (pickUp) {
        return pairedUnassigned
          ? translate({ id: 'modals.movePairedOrders.card.title.unassigned.pickup' })
          : translate({ id: 'modals.movePairedOrders.card.title.pickup' })
      }

      return pairedUnassigned
        ? translate({ id: 'modals.movePairedOrders.card.title.unassigned.delivery' })
        : translate({ id: 'modals.movePairedOrders.card.title.delivery' })
    },

    cardText: translate({ id: 'modals.movePairedOrders.card.text' }),

    btnErrorClose: translate({ id: 'modals.movePairedOrders.button.errorClose' }),

    errorCardTitle: translate({ id: 'modals.movePairedOrders.errorCard.title' }),

    errorCardText: (errorType: ErrorType) => {
      if (errorType === 'orderStepMissing' || errorType === 'routeUnavailable') {
        return translate({ id: `modals.movePairedOrders.errorCard.text.${errorType}` as const })
      } else {
        // don't know why all the status are not mapped in the lang files
        return ''
      }
    },
  }))

  return api
}
