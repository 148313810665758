import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'
import { ReadonlyBlock, ReadonlyLabel } from '@/forms-legacy'
import { useEnvironmentURLs } from '@/atoms'

import * as OrderTexts from '../../../intl'
import { Props } from '../typings'

import ReadonlyAudio from './ReadonlyAudio'

export function PodAudios(props: Props) {
  const { podsBaseUrl } = useEnvironmentURLs()

  const {
    pods: { audios },
    onTogglePod,
    activePodMarker,
  } = props

  let audiosList: uui.domain.client.rm.MediaPod[] = []
  if (audios) {
    audiosList = Object.values(audios).map((audio: uui.domain.client.rm.MediaPod) => audio)
  }

  return (
    <>
      {audiosList.length > 0 && (
        <ReadonlyBlock>
          <ReadonlyLabel>{OrderTexts.getVoiceMemosLabel()}</ReadonlyLabel>

          {audiosList.map((audio, idx) => {
            const { sec, text, token, latLng } = audio
            const title: string = text || `${OrderTexts.getVoiceMemoLabel()} ${idx}`
            const src: string = `${podsBaseUrl}${token}`

            return (
              <ReadonlyAudio
                key={`pod_audio_${idx}`}
                uuid={audio.uuid}
                src={src}
                title={title}
                time={<ConvertTime seconds={sec} showSeconds={false} />}
                latLng={latLng || undefined}
                onToggle={onTogglePod}
                selected={activePodMarker?.uid === audio.uuid}
              />
            )
          })}
        </ReadonlyBlock>
      )}
    </>
  )
}
