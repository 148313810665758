import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Watch(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 14 14">
        <path d="M7 0a7 7 0 1 1-7 7 7 7 0 0 1 7-7zm-.78 3.11v5.15L9.76 10l.7-1.39L7.78 7.3V3.11z" />
      </svg>
    </IconContainer>
  )
}
