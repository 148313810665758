import styled from 'styled-components'

export const SelectIndicator = styled.span`
  align-items: center;
  display: flex;

  padding-left: 16px;

  height: 14px;

  font-size: ${p => p.theme.fonts.$s};
  text-transform: uppercase;
  color: ${p => p.theme.colors.$scienceBlue};

  transition: opacity 0.3s ease;
`

SelectIndicator.displayName = 'SelectIndicator'
