import { Style } from 'ol/style'

import { genericStyles } from '../../genericStyles'

const placeLabelStylesCache: Map<string, Style> = new Map()

export const getPlaceFeatureLabelStyle = (
  marker: uui.domain.ui.map.markers.Place,
  mode: uui.domain.ui.map.markers.MapStyles['place']['mode'],
) => {
  if (mode === 'off') return genericStyles.hidden

  const text = marker.label

  const cacheId = `${mode}_${text}`

  if (placeLabelStylesCache.has(cacheId)) {
    const cachedStyle = placeLabelStylesCache.get(cacheId)

    if (cachedStyle) return cachedStyle
  }

  const style = genericStyles.label.clone()
  const textInstance = style.getText()

  textInstance.setText(text)
  textInstance.setOffsetY(30)

  placeLabelStylesCache.set(cacheId, style)

  return style
}
