import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    on: translate({
      id: 'global.on',
    }),

    off: translate({
      id: 'global.off',
    }),

    edit: translate({
      id: 'global.edit',
    }),

    test: translate({
      id: 'global.test',
    }),

    notificationTime: translate({
      id: 'sms4.settings.communication.notifications.form.notificationCutoffMins.label',
    }),

    notificationType: translate({
      id: 'sms4.settings.communication.notifications.form.notificationWay.label',
    }),

    emailSubject: translate({
      id: 'sms4.settings.communication.notifications.form.subjectCustom.label',
    }),

    emailText: translate({
      id: 'sms4.settings.communication.notifications.form.message.email.label',
    }),

    smsText: translate({
      id: 'sms4.settings.communication.notifications.form.message.sms.label',
    }),

    etaRange: translate({
      id: 'sms4.settings.communication.notifications.form.templateCustom.label',
    }),

    trackingLink: translate({
      id: 'sms4.settings.communication.notifications.form.trackingLink.label',
    }),

    unsubscribeInstructions: translate({
      id: 'sms4.settings.communication.notifications.form.unsubscribeInstructions.label',
    }),

    emailBackup: translate({
      id: 'sms4.settings.communication.notifications.form.backupNotification.label',
    }),

    emailBackupSubject: translate({
      id: 'sms4.settings.communication.notifications.form.backupSubject.label',
    }),
  }))

  return api
}
