import { defaults as interactionDefaults, MouseWheelZoom } from 'ol/interaction'

export function createLocationEditorMapInteractions() {
  return interactionDefaults({
    altShiftDragRotate: true,
    doubleClickZoom: true,
    keyboard: true,
    mouseWheelZoom: true,
    shiftDragZoom: true,
    dragPan: true,
    pinchRotate: false,
    pinchZoom: true,
    // zoomDelta: 0,
  }).extend([
    new MouseWheelZoom({
      constrainResolution: true,
      duration: 0,
      timeout: 100,
    }),
  ])
}
