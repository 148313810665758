import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'
import { Body } from './Body'
import { Header } from './Header'
import { Footer } from './Footer'
import { InvalidBody } from './InvalidBody'

export function Content() {
  const { ready, invalid } = useConfigureController()

  if (!ready) return null

  return (
    <ModalContent header={<Header />} footer={<Footer />}>
      {invalid ? <InvalidBody /> : <Body />}
    </ModalContent>
  )
}
