import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function SortAscending(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 20 18">
        <path d="M3 0H5V14H8L5 17L4 18L3 17L0 14H3V0ZM12 0H9V2H12V0ZM14 4H9V6H14V4ZM9 8H16V10H9V8ZM18 12H9V14H18V12ZM9 16H20V18H9V16Z" />
      </svg>
    </IconContainer>
  )
}
