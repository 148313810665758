import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState({
    alerts: translate({ id: 'app.header.setup.alerts' }),
    depots: translate({ id: 'app.header.setup.depots' }),
    places: translate({ id: 'app.header.setup.places' }),
    devices: translate({ id: 'app.header.setup.devices' }),
    drivers: translate({ id: 'app.header.setup.drivers' }),
    regions: translate({ id: 'app.header.setup.regions' }),
    roadEditor: translate({ id: 'app.header.setup.road' }),
    vehicles: translate({ id: 'app.header.setup.vehicles' }),
    geofences: translate({ id: 'app.header.setup.geofences' }),
    trafficArea: translate({ id: 'app.header.setup.traffic' }),
    driverAssignment: translate({ id: 'app.header.setup.driverAssignment' }),
    territory: translate({ id: 'app.header.setup.territory' }),
    disabledForBeta: translate({ id: 'global.disabledForBeta' }),
    setup: translate({ id: 'rm.header.setup' }),
  })

  return api
}
