import type { ExecutionEventMarkerType } from '../../../../atoms/executionEventMarkers/executionEventMarkers'

import { mapAtom } from '../../../../atoms/map/map'
import { findLayer } from '../../../utils/findLayer'

export function removeExecutionEventFeature(executionEventMarkerType: ExecutionEventMarkerType) {
  const map = mapAtom.map
  const layer = findLayer(map, 'executionEvents')
  const feature = layer.getSource()?.getFeatureById(executionEventMarkerType)

  if (feature) {
    layer.getSource()?.removeFeature(feature)
  }
}
