import styled from 'styled-components'

import { InputFieldText } from '@/forms-legacy'

const DatePickerInput = styled(InputFieldText)`
  .o-input-field-text__extra-icon {
    position: absolute;
    top: 50%;
    left: auto;
    right: 10px;
    width: 14px;
    transform: translateY(-50%);
    color: ${p => p.theme.colors.$silver};
    opacity: 0.3;
    z-index: 5;
  }

  .o-input-field-text__reset-button {
    display: none;
  }
`
DatePickerInput.displayName = 'DatePickerInput'

export default DatePickerInput
