import type { Option } from '@/components/formUi/fields/Select'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVirtualDevicesList } from '@/features/domain/device'
import { selectDriversMapByEmail } from '@/features/domain/driver'

import { useTexts } from './useTexts'

export function useGpsDeviceIdOptions(initialDeviceId?: string) {
  const virtualDevices = useSelector(selectVirtualDevicesList)
  const driversMap = useSelector(selectDriversMapByEmail)

  const texts = useTexts()

  return useMemo(
    () => composeValues(virtualDevices, driversMap, texts, initialDeviceId),
    [texts, initialDeviceId, virtualDevices, driversMap],
  )
}

function composeValues(
  virtualDevices: uui.domain.client.gps.wwgps.DeviceInfo[],
  driversMap: Record<string, uui.domain.client.rm.ExtendedDriver>,
  texts: ReturnType<typeof useTexts>,
  initialDeviceId?: string,
): Option<string>[] {
  const freeOptions: Option<string>[] = []
  const notAvailableOptions: Option<string>[] = []

  for (const virtualDevice of virtualDevices) {
    const { deviceId, label } = virtualDevice

    // just a check for ts
    if (!virtualDevice.virtual) continue

    const inUse = !!virtualDevice.territoryId && deviceId !== initialDeviceId

    if (!inUse) {
      freeOptions.push({
        label,
        value: deviceId,
      })
      continue
    }

    const secondaryText = computeSecondaryText(virtualDevice, driversMap, texts)

    notAvailableOptions.push({
      preset: 'secondaryText',
      label,
      value: deviceId,
      secondaryText,
      disabled: true,
    })
  }

  freeOptions.sort(sortOption)
  notAvailableOptions.sort(sortOption)

  return freeOptions.length > 0
    ? [...freeOptions, { preset: 'divider' }, ...notAvailableOptions]
    : notAvailableOptions
}

function sortOption(a: Option<string>, b: Option<string>) {
  if (a.preset === 'divider') return -1
  if (b.preset === 'divider') return 1

  return a.label.localeCompare(b.label)
}

function computeSecondaryText(
  virtualDevice: uui.domain.client.gps.wwgps.DeviceInfo,
  driversMap: Record<string, uui.domain.client.rm.ExtendedDriver>,
  texts: ReturnType<typeof useTexts>,
) {
  // just a check for ts
  if (!virtualDevice.virtual) return ''

  if (virtualDevice.externalTerritory) return texts.otherAccount

  // check if the driver associated to the device email match with one in the current territory
  const driver = virtualDevice.email && driversMap[virtualDevice.email]
  if (driver) {
    return driver.driver.name
  }

  return virtualDevice.territoryName ?? ''
}
