import { useRefreshGpsData } from '@/hooks'
import { usePlacesRoot } from './hooks/usePlacesRoot'
import { List } from './List'

import { useHidePlaceWhileEditing } from './hooks/useHidePlaceWhileEditing'
import { useRefreshLocations } from './hooks/useRefreshLocations'
import { SingleView } from './Single/View'
import { SingleEdit } from './Single/Edit'
import { BulkView } from './Bulk/View'

export function PlacesRoot() {
  const { editing, places, selection, selectedPlaces } = usePlacesRoot()

  const single = selectedPlaces.length === 1
  const bulk = selectedPlaces.length > 1
  const place = selectedPlaces[0]

  useHidePlaceWhileEditing(editing, selection[0])

  // Let's request a refresh of GPS data
  useRefreshGpsData('places')

  // Let's request a refresh of the locations
  useRefreshLocations()

  return (
    <>
      <List editing={editing} places={places} selection={selection} />
      {single && !editing && place && <SingleView place={place} />}
      {bulk && <BulkView places={selectedPlaces} />}
      {editing && !bulk && <SingleEdit place={place} />}
    </>
  )
}
