import { useCallback, useState } from 'react'
import { updateMapStyle } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'

type Action = 'maximizeOrders' | 'showOrders' | 'hideOrders'
export function useActions() {
  const [methodInExecution, setMethodInExecution] = useState<Action | null>(null)
  const dispatch = useAppDispatch()

  const maximizeOrders = useCallback(async () => {
    setMethodInExecution('maximizeOrders')

    await dispatch(updateMapStyle({ type: 'order', mode: 'maximized' }))

    setMethodInExecution(null)
  }, [dispatch])

  const showOrders = useCallback(async () => {
    setMethodInExecution('showOrders')

    await dispatch(updateMapStyle({ type: 'order', mode: 'on' }))

    setMethodInExecution(null)
  }, [dispatch])

  const hideOrders = useCallback(async () => {
    setMethodInExecution('hideOrders')

    await dispatch(updateMapStyle({ type: 'order', mode: 'off' }))

    setMethodInExecution(null)
  }, [dispatch])

  return { methodInExecution, actions: { maximizeOrders, showOrders, hideOrders } }
}
