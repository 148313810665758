import type { ReactElement } from 'react'
import type { Theme } from '@mui/material'

import { Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'

interface Props {
  children: ReactElement | ReactElement[]
}

const useClasses = makeStyles<Theme>(theme => ({
  fieldsContainer: {
    // Spacer that allow the label to not be cut (outlined label will overflow the container)
    paddingTop: '5px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

export function FieldsContainer(props: Props) {
  const classes = useClasses()

  return (
    <div className={classes.fieldsContainer}>
      <Stack spacing={3}>{props.children}</Stack>
    </div>
  )
}
