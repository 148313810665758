import type { DeviceChip } from '../../../components/Navigator/typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDevices } from '@/features/domain/device'

import { useNavigator as useCoreNavigator } from '../../../components/Navigator/hooks/useNavigator'

export function useDevicesNavigator() {
  const {
    ids,
    pagination: { page, setPage },
  } = useCoreNavigator()
  const devices = useSelector(selectDevices) as Record<
    string,
    uui.domain.client.gps.wwgps.DeviceInfo
  >

  return useMemo(() => {
    const selectedDevices = ids.map(id => devices[id]).filter(Boolean)

    const chips: DeviceChip[] = selectedDevices.map(device => ({
      id: device.deviceId,
      source: device as uui.domain.client.gps.wwgps.DeviceInfo,
      type: 'device',
    }))

    const currentDevices =
      page === -1 || !selectedDevices?.[page] ? selectedDevices : [selectedDevices[page]]
    const currentDeviceIds = page === -1 || !ids?.[page] ? ids : [ids[page]]

    return {
      page,
      chips,
      setPage,
      currentDevices,
      currentDeviceIds,
    }
  }, [devices, ids, page, setPage])
}
