import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    resetAll: translate({ id: 'globalStyles.resetAll' }),
    title: translate({ id: 'globalStyles.title' }),
  }))

  return api
}
