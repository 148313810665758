import { type ReactNode, Children } from 'react'
import {
  Box,
  ListItemButton,
  styled,
  type ListItemButtonProps as MuiListItemButtonProps,
  listItemButtonClasses,
} from '@mui/material'
import { listItemIconClasses } from './ListItemIcon'

// working example: https://codesandbox.io/s/listitem-components-forked-flz9d4?file=/src/Demo.tsx

export type Props = {
  testId?: string
  avatar?: ReactNode
  children: ReactNode
  right?: ReactNode
  onClick?: MuiListItemButtonProps['onClick']
  onDoubleClick?: MuiListItemButtonProps['onDoubleClick']
  selected?: boolean
  disabled?: boolean
  invisibleOnMap?: boolean
  height?: number
}

const visibile = {
  display: 'flex',
}

const invisible = {
  display: 'none',
}

type ListItemButtonProps = Pick<Props, 'height' | 'invisibleOnMap'>

const Root = styled(ListItemButton, {
  shouldForwardProp: p => p !== 'height' && p !== 'invisibleOnMap',
})<ListItemButtonProps>(({ theme, invisibleOnMap, height }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  gap: theme.spacing(1),
  height,

  [`& .${listItemIconClasses.root}`]: invisibleOnMap ? visibile : invisible,
  [`&.${listItemButtonClasses.selected} .${listItemIconClasses.root}`]: visibile,
  [`&:hover .${listItemIconClasses.root}`]: visibile,
  [`&:focus-within .${listItemIconClasses.root}`]: visibile,
}))

export function ListItem(props: Props) {
  const {
    avatar,
    testId = 'ListItem',
    children,
    right,
    onClick,
    onDoubleClick,
    selected,
    disabled,
    height,
    invisibleOnMap,
  } = props

  const childrenCount = Children.count(children)
  const avatarAlignment = childrenCount < 4 ? 'center' : 'start'

  return (
    <Root
      invisibleOnMap={invisibleOnMap}
      data-testid={testId}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      selected={selected}
      disabled={disabled}
      height={height}
      disableRipple
    >
      {!!avatar && (
        <Box mr={1} alignSelf={avatarAlignment}>
          {avatar}
        </Box>
      )}
      <Box flex={1} overflow="hidden">
        {children}
      </Box>
      {!!right && <Box>{right}</Box>}
    </Root>
  )
}
