import { type ResourceModel } from '@bryntum/schedulerpro'

import { useIsLoading } from '@/atoms'

import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../Scheduler/components/Typography'
import { Spinner } from '../../Scheduler/components/Spinner'
import { Link } from '../../Scheduler/components/Link'

import { useTexts } from './useTexts'

interface Props {
  record: ResourceModel
  field: string
  value: boolean
}

export function LiveEtaCell(props: Props) {
  const { value, record } = props

  const available = record.getData('available') as boolean | undefined

  const gpsDataLoading = useIsLoading('fetchGpsData')

  const texts = useTexts()

  // If GPS data are still loading we cannot select/edit driver assignments
  if (gpsDataLoading) {
    return (
      <SingleColumnContainer>
        <Spinner size={14} />
      </SingleColumnContainer>
    )
  }

  if (!available) {
    return (
      <SingleColumnContainer>
        <Typography fontSize={12}>-</Typography>
      </SingleColumnContainer>
    )
  }

  return (
    <SingleColumnContainer>
      <Link>
        <Typography fontSize={12}>{value ? texts.on : texts.off}</Typography>
      </Link>
    </SingleColumnContainer>
  )
}
