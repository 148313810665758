import type { NotificationFormValues } from '../../../../../../../notificationForms/sections/notifications/components/NotificationForm'

import { useCallback } from 'react'

import { updateNotCompletedNotificationConfig } from '@/features/domain/territory'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

/**
 * That hook returns a submit method for NotCompletedNotification Form
 */
export function useNotCompletedNotificationSubmit() {
  const dispatch = useAppDispatch()
  const toast = useNotification()

  return useCallback(
    async (formValues: NotificationFormValues) => {
      try {
        const result = await dispatch(updateNotCompletedNotificationConfig(formValues))

        if (!updateNotCompletedNotificationConfig.fulfilled.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        return true
      } catch (error) {
        toast.error(error.message)

        return false
      }
    },
    [dispatch, toast],
  )
}
