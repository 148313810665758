import type { MouseEventHandler } from 'react'

import { PureComponent, CSSProperties, MouseEvent } from 'react'

import { Completed, NotCompleted } from '@/icons'

import OrderAvatarContainer from './elements/OrderAvatarContainer'
import OrderAvatarInner from './elements/OrderAvatarInner'
import OrderAvatarText from './elements/OrderAvatarText'
import OrderAvatarTextRow from './elements/OrderAvatarTextRow'
import OrderAvatarViolations from './elements/OrderAvatarViolations'
import OrderAvatarSlice from './elements/OrderAvatarSlice'
import OrderAvatarBadgeContainer from './elements/OrderAvatarBadgeContainer'

import { getColorValue } from './utils'

const SPLIT_CHARACTER = '.'

type Props = {
  className?: string
  style?: CSSProperties
  size?: number
  disabled?: boolean
  disableExecutionEventBadge?: boolean

  extendedOrderStep: uui.domain.client.rm.ExtendedOrderStep
  onClick?: MouseEventHandler<HTMLElement>
}

// type OrderStepTrackingDataStatus = uui.domain.client.rm.OrderStepTrackingData['status']

export class OrderAvatar extends PureComponent<Props> {
  private handleOnClick = (evt: MouseEvent<HTMLElement>): void => {
    const { onClick, disabled } = this.props

    if (onClick && !disabled) {
      onClick(evt)
    }
  }

  render() {
    const {
      className = '',
      style = {},
      size = 42,
      disableExecutionEventBadge,
      extendedOrderStep,
    } = this.props

    const color = extendedOrderStep.isUnassigned ? '#fff' : extendedOrderStep.color

    const hasTrackingData =
      !extendedOrderStep.isUnassigned &&
      extendedOrderStep.hasBeenExecuted &&
      extendedOrderStep.hasPOD

    const hasViolation =
      !extendedOrderStep.isUnassigned &&
      Array.isArray(extendedOrderStep.violations) &&
      extendedOrderStep.violations.length > 0

    const trackingData = !extendedOrderStep.isUnassigned && extendedOrderStep.orderStep.trackingData

    const badgeIcon = !!trackingData ? (
      trackingData.status === 'done' ? (
        <Completed as="div" background="$pigmentGreen" color="$pureWhite" size={16} />
      ) : trackingData.status === 'reschedule' ? (
        <NotCompleted as="div" background="$outrageousRed" color="$pureWhite" size={16} />
      ) : undefined
    ) : undefined

    const label = !extendedOrderStep.isUnassigned ? extendedOrderStep.routeStepLabel : ''

    const [row1 = '', row2 = '']: string[] = label.split(SPLIT_CHARACTER)
    const separator = row2.length > 0 ? SPLIT_CHARACTER : ''

    const hasBeenExecuted = !extendedOrderStep.isUnassigned && extendedOrderStep.hasBeenExecuted

    const innerStyle: CSSProperties = {
      background:
        color && !extendedOrderStep.isUnassigned && !extendedOrderStep.hasBeenExecuted
          ? getColorValue(color)
          : undefined,
    }

    const sliceStyle: CSSProperties = {
      color: hasBeenExecuted ? getColorValue(color) : 'transparent',
    }

    return (
      <OrderAvatarContainer className={className} style={style} size={size}>
        <OrderAvatarInner
          onClick={this.handleOnClick}
          style={innerStyle}
          hasTrackingData={hasTrackingData}
          isUnassigned={extendedOrderStep.isUnassigned}
          hasBeenExecuted={hasBeenExecuted}
          hasPOD={extendedOrderStep.hasPOD}
        >
          <OrderAvatarText>
            <OrderAvatarTextRow>
              {row1}
              {separator}
            </OrderAvatarTextRow>
            {row2 && <OrderAvatarTextRow>{row2}</OrderAvatarTextRow>}
          </OrderAvatarText>

          {hasViolation && <OrderAvatarViolations />}
          {hasBeenExecuted && <OrderAvatarSlice style={sliceStyle} />}
        </OrderAvatarInner>
        {!disableExecutionEventBadge && badgeIcon && (
          <OrderAvatarBadgeContainer>{badgeIcon}</OrderAvatarBadgeContainer>
        )}
      </OrderAvatarContainer>
    )
  }
}
