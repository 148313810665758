import { Component } from 'react'

import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'

import FooterRowFormControls from './elements/FooterRowFormControls'
import FooterContainer from './elements/FooterContainer'
import FooterMessage from './elements/FooterMessage'
import FooterTitle from './elements/FooterTitle'
import FooterText from './elements/FooterText'
import FooterRow from './elements/FooterRow'

import FooterErrors from './FooterErrors'
import { Props } from './typings'

const confirmButtonStyle = { width: 270 }
const cancelButtonStyle = { width: 130 }

export default class Footer extends Component<Props> {
  render() {
    const {
      submitting,
      primary: {
        text: primaryText,
        title: primaryTitle,
        disabled: primaryDisabled = false,
        onClick: primaryOnClick,
        className: primaryClassName,
      },
      secondary: {
        text: secondaryText,
        title: secondaryTitle,
        disabled: secondaryDisabled = false,
        onClick: secondaryOnClick,
        className: secondaryClassName,
      },
      message,
      errorTitle,
      errors,
      showButtonBackground = true,
      fullPage,
    } = this.props

    const enableMessage: boolean = !!message && message.length > 0
    const enableErrors: boolean = !!errors && errors.length > 0
    const enableButtonBackground: boolean = showButtonBackground || enableErrors

    return (
      <FooterContainer data-testid="form__footer-container">
        {enableMessage && (
          <FooterRow fullPage={fullPage}>
            <FooterMessage quote>
              {message!.map((text: string) => (
                <FooterText key={text}>{text}</FooterText>
              ))}
            </FooterMessage>
          </FooterRow>
        )}
        {enableErrors && (
          <FooterRow showBackground hideBottomSpacing fullPage={fullPage}>
            <FooterMessage>
              <FooterTitle>{errorTitle}</FooterTitle>
              <FooterErrors {...this.props} />
            </FooterMessage>
          </FooterRow>
        )}
        <FooterRowFormControls showBackground={enableButtonBackground} fullPage={fullPage}>
          <Button
            data-trackid="footer-secondary-button"
            data-testid="footer-secondary-button"
            className={secondaryClassName}
            disabled={secondaryDisabled}
            onClick={secondaryOnClick}
            style={cancelButtonStyle}
            title={secondaryTitle}
            color="primary"
            variant="text"
            type="button"
            size="small"
          >
            {secondaryText}
          </Button>
          <LoadingButton
            data-trackid="footer-primary-button"
            data-testid="footer-primary-button"
            className={primaryClassName}
            disabled={primaryDisabled}
            style={confirmButtonStyle}
            onClick={primaryOnClick}
            loading={submitting}
            title={primaryTitle}
            variant="contained"
            color="primary"
            type="button"
            size="small"
          >
            {primaryText}
          </LoadingButton>
        </FooterRowFormControls>
      </FooterContainer>
    )
  }
}
