import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    add: translate({ id: 'global.add' }),
    groupBy: translate({ id: 'gps.trackinglist.list.sorter.groupBy' }),
    sortBy: (field: string) =>
      translate({ id: 'gps.trackinglist.list.sorter.sortBy', values: { field } }),
    groupByNone: translate({ id: 'gps.trackinglist.list.sorter.groupBy.none' }),
    groupByType: translate({ id: 'gps.trackinglist.list.sorter.groupBy.type' }),
    vehicles: translate({ id: 'gps.trackinglist.list.yourVehicles' }),
    sortByName: translate({ id: 'gps.trackinglist.list.sorter.sortByName' }),
    searchPlaceholder: translate({ id: 'gps.trackinglist.list.filterVehicles' }),
    sortOrderAscending: translate({
      id: 'gps.trackinglist.list.sorter.ascending',
    }),
    sortOrderDescending: translate({
      id: 'gps.trackinglist.list.sorter.descending',
    }),
    sortByLastActivity: translate({ id: 'gps.trackinglist.list.sorter.sortByLastActivity' }),
  }))

  return api
}
