import type { ValidationSummaryItem } from '@workwave-tidal/tidal/form-ui/utils/ValidationSummary'
import type { FormFields } from '../../../formFields'

import { ValidationSummary } from '@workwave-tidal/tidal/form-ui/utils/ValidationSummary'
import { Form as TidalForm } from '@workwave-tidal/tidal/form-ui/layout'
import { FormContainer } from '@workwave-tidal/tidal/form-ui/core'

import { useOnSubmit } from '../../hooks/useOnSubmit'

import { NewPassword } from './components/fields/NewPassword'

interface Props {
  firstSubmitDone: boolean
  setFirstSubmitDone: (value: boolean) => void
  validationSummaryItems?: ValidationSummaryItem<FormFields>[]
}
export function Form(props: Props) {
  const { firstSubmitDone, setFirstSubmitDone, validationSummaryItems = [] } = props

  const onSubmit = useOnSubmit(setFirstSubmitDone)

  const validationSummaryMode = firstSubmitDone ? 'show-errors' : 'silent-errors'

  return (
    <FormContainer testId="driver-change-password-form" width={300}>
      <TidalForm fieldsSpacing={2} onEnter={onSubmit} disableScroll>
        <NewPassword firstSubmitDone={firstSubmitDone} />

        <ValidationSummary<FormFields>
          items={validationSummaryItems}
          mode={validationSummaryMode}
        />
      </TidalForm>
    </FormContainer>
  )
}
