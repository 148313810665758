import { type ResourceModel } from '@bryntum/schedulerpro'
import { formatSeconds } from '@/hooks'
import { intl } from '@/intl'

import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../Scheduler/components/Typography'
import { LinearProgress } from '../../Scheduler/components/LinearProgress'
import { getPercentageColor } from '../../../styles/colors'

interface Props {
  record: ResourceModel
  field: string
  value: { absolute: number; percentage: number }
  showAbsoluteValues: boolean
}
export function ApprovedTimeCell(props: Props) {
  const { value, showAbsoluteValues } = props

  if (!value) {
    return (
      <SingleColumnContainer>
        {showAbsoluteValues ? <Typography fontSize={12}>-</Typography> : <></>}
      </SingleColumnContainer>
    )
  }

  const normalizedValue =
    value.percentage > 100 ? 100 : value.percentage <= 0 ? 0 : (value.percentage * 100) / 100

  const formattedValue =
    value.percentage <= 0 ? '-' : `${Math.round(value.percentage * 100) / 100}%`

  return (
    <SingleColumnContainer>
      {showAbsoluteValues ? (
        <Typography fontSize={12}>
          {value.absolute > 0 ? formatSeconds(value.absolute, 'EXTRA_SHORT', intl.translate) : '-'}
        </Typography>
      ) : (
        <>
          <LinearProgress color={getPercentageColor(value.percentage)} value={normalizedValue} />

          <Typography noWrap fontSize={10}>
            {formattedValue}
          </Typography>
        </>
      )}
    </SingleColumnContainer>
  )
}
