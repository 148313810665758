import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

/**
 * Update company
 *
 * @private
 */
export const updateCompany = createAsyncThunk<
  // Return type of the payload creator
  uui.domain.client.rm.Company,
  // First argument to the payload creator
  uui.domain.client.rm.Company,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/company/update', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/company/update', {
      category: 'rpc',
      type: 'rpc/company/update',
      company: payload,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {updateCompany}`,
      { tags: ['rpc', 'company'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Update company failed`,
      error,
    })
  }
})
