import type { AsyncThunkApiConfig } from '@/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type UserConfiguration =
  uui.domain.actions.rpc.user.UpdateGpsUserConfigurationAction['configuration']

export const updateGpsUserConfiguration = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  UserConfiguration,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/user/updateGpsUserConfiguration', async (configuration, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/user/updateGpsUserConfiguration', {
      type: 'rpc/user/updateGpsUserConfiguration',
      category: 'rpc',
      configuration,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        error: result,
        message: result.errorMessage,
      })
    }
  } catch (error) {
    return thunkApi.rejectWithValue({
      error,
      type: 'unknown',
      message: 'Failed to update the gps user configuration',
    })
  }
})
