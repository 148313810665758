import { schedulerPreferencesAtom } from '../../atoms/schedulerPreferences'

export const eventLayout = {
  layoutFn: items => {
    let gotApprovedEvents = false
    const approvedRoutesShown = schedulerPreferencesAtom.preferences.showApprovedRoutes

    for (const item of items) {
      const isApproved = item.eventRecord.get('isApproved')
      const type = item.eventRecord.get('type')

      switch (type) {
        case 'edge':
          item.height = approvedRoutesShown ? 46 : 27
          item.top = 0
          break

        case 'brk':
        case 'pickup':
        case 'arrival':
        case 'idle-time':
        case 'closeOut':
        case 'delivery':
        case 'departure':
        case 'preparation':
        case 'route-track':
          if (isApproved) {
            gotApprovedEvents = true
            item.height = 13
            item.top = 31
          } else {
            item.height = 23
            item.top = 2
          }
          break

        case 'route-time-range':
        case 'route-time-range-handles':
          if (isApproved) {
            gotApprovedEvents = true
            item.height = 17
            item.top = 29
          } else {
            item.height = 27
            item.top = 0
          }
          break
      }
    }
    return gotApprovedEvents ? 46 : 27
  },
}
