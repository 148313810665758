import { useCallback } from 'react'

import { useNotification } from '@/hooks'
import { regionUtils } from '@/utils'

export function useOnExport(region: uui.domain.client.rm.Region) {
  const toast = useNotification()

  return useCallback(async () => {
    try {
      regionUtils.exportRegionToKML([region])
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, region])
}
