import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectGeofences } from '@/features/domain/geofence'

export const useGeofencesRoot = () => {
  const { editing } = useEditingStateWithCtx('geofence')
  const [selection] = useCrudSelection('geofences')
  const geofences = useSelector(selectGeofences)
  const selectedGeofences = useMemo(
    () => getSelectedGeofences(selection, geofences),
    [selection, geofences],
  )

  return {
    geofences,
    selectedGeofences,
    editing,
    selection,
  }
}

const getSelectedGeofences = (
  selection: workwave.DeepReadonly<string[]>,
  s: Record<string, uui.domain.client.gps.wwgps.Geofence>,
): uui.domain.client.gps.wwgps.Geofence[] => {
  return selection.reduce<uui.domain.client.gps.wwgps.Geofence[]>((acc, id) => {
    if (s[id]) {
      acc.push(s[id])
    }
    return acc
  }, [])
}
