import { type ReactNode, useCallback } from 'react'
import { type SchedulerPro } from '@bryntum/schedulerpro'
import { useSelector } from 'react-redux'
import { produce } from 'immer'

import { selectUserAccountPreferences } from '@/features/domain/user'
import { useMainSelection } from '@/atoms'

import { useSchedulerPreferencesAtom } from '../../../../atoms/schedulerPreferences'
import { schedulerInstance } from '../../../../atoms/project/project'
import { useResetColumnInfo } from './hooks/useResetColumnsInfo'
import { useOnZoomRoutes } from './hooks/useOnZoomRoutes'
import { useRouteStats } from './hooks/useRouteStats'

import { SchedulerHeader } from './SchedulerHeader'

interface Props {
  scheduler?: SchedulerPro
  editColumns: () => void
  ResizeHandle?: ReactNode
}

export function SchedulerHeaderProvider(props: Props) {
  const { editColumns, ResizeHandle } = props

  const [schedulerPreferences, setSchedulerPreferences] = useSchedulerPreferencesAtom()
  const [selectedRoutes, setSelectedRoutes] = useMainSelection('routes')
  const accountPreferences = useSelector(selectUserAccountPreferences)
  const resetColumnInfo = useResetColumnInfo()
  const onZoomRoutes = useOnZoomRoutes()
  const routeStats = useRouteStats()

  const { routeIds, unavailableRouteIds } = routeStats
  const { showUnavailableRoutes } = schedulerPreferences

  const routesCounter = showUnavailableRoutes
    ? routeIds.length
    : routeIds.length - unavailableRouteIds.length

  const allSelected = routesCounter === selectedRoutes.length

  const handleSetUnitMode = useCallback(
    (unit: 'percentage' | 'absolute') => {
      setSchedulerPreferences(prev =>
        produce(prev, draft => {
          draft.showAbsoluteValues = unit === 'absolute'
        }),
      )
    },
    [setSchedulerPreferences],
  )

  const setShowApprovedRoutes = useCallback(
    (value: boolean) => {
      setSchedulerPreferences(prev =>
        produce(prev, draft => {
          draft.showApprovedRoutes = value
        }),
      )
    },
    [setSchedulerPreferences],
  )

  const toggleSelectAll = useCallback(() => {
    if (allSelected) {
      setSelectedRoutes([])
      return
    }

    setSelectedRoutes(
      showUnavailableRoutes ? routeIds : routeIds.filter(r => !unavailableRouteIds.includes(r)),
    )
  }, [setSelectedRoutes, showUnavailableRoutes, unavailableRouteIds, allSelected, routeIds])

  const removeUnavailableRoutesFromSelection = useCallback(() => {
    setSelectedRoutes(selectedRoutes.filter(r => !unavailableRouteIds.includes(r)))
  }, [setSelectedRoutes, selectedRoutes, unavailableRouteIds])

  const setGroupBy = useCallback(
    (groupBy: 'dateAsString' | 'vehicleId') => {
      schedulerInstance.instance?.expandAll()

      setSchedulerPreferences(prev =>
        produce(prev, draft => {
          draft.groupBy = groupBy
        }),
      )
    },
    [setSchedulerPreferences],
  )

  const setShowUnavailableRoutes = useCallback(
    (show: boolean) => {
      setSchedulerPreferences(prev =>
        produce(prev, draft => {
          draft.showUnavailableRoutes = show
        }),
      )
    },
    [setSchedulerPreferences],
  )

  return (
    <SchedulerHeader
      groupBy={schedulerPreferences.groupBy}
      unitMode={schedulerPreferences.showAbsoluteValues ? 'absolute' : 'percentage'}
      routeStats={routeStats}
      setGroupBy={setGroupBy}
      allSelected={allSelected}
      editColumns={editColumns}
      setUnitMode={handleSetUnitMode}
      ResizeHandle={ResizeHandle}
      onZoomRoutes={onZoomRoutes}
      routesCounter={routesCounter}
      selectedRoutes={selectedRoutes}
      toggleSelectAll={toggleSelectAll}
      accountPreferences={accountPreferences}
      showApprovedRoutes={setShowApprovedRoutes}
      approvedRoutesVisible={schedulerPreferences.showApprovedRoutes}
      setShowUnavailableRoutes={setShowUnavailableRoutes}
      resetSchedulerColumnsInfo={resetColumnInfo}
      areUnavailableRoutesVisible={showUnavailableRoutes}
      removeUnavailableRoutesFromSelection={removeUnavailableRoutesFromSelection}
    />
  )
}
