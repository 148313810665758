import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDepotMapStyles } from '@/features/domain/ui'

import { useDepotsNavigator } from '../../../hooks/useDepotsNavigator'
import { useNavigoView } from '../../../hooks/useNavigoView'

export const useData = () => {
  const {
    navigate: { goToOverview },
  } = useNavigoView()
  const { currentDepotIds } = useDepotsNavigator()
  const mapStyles = useSelector(selectDepotMapStyles)

  /**
   * This methods retrieve the correct active style for the current depot(s).
   * If there are different styles for the chosen orders it will return undefined.
   */
  const activeMapStyle = useMemo(() => {
    const appliedStyles = new Set<'on' | 'off' | 'label'>()

    // Using a Set allows to not create duplicated values
    currentDepotIds.forEach(id => appliedStyles.add(mapStyles.custom[id] ?? mapStyles.mode))

    // If the Set is composed of just one element, means that the style is the same for all orders
    return appliedStyles.size === 1 ? appliedStyles.values().next().value : undefined
  }, [mapStyles, currentDepotIds])

  return {
    mapStyles,
    goToOverview,
    activeMapStyle,
    currentDepotIds,
  }
}
