import { TextField, FieldMessage } from '@/forms-legacy'

import { useTexts } from './useTexts'

export function Name() {
  const texts = useTexts()

  return (
    <>
      <TextField name="name" label={texts.label} half />
      <FieldMessage>{texts.info}</FieldMessage>
    </>
  )
}
