import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    searchPlaceholder: translate({ id: 'devices.form.filterDevicesPlaceholder' }),
    yourDevices: translate({ id: 'devices.form.list.yourDevices' }),

    entitiesCount: (selected: number, count: number): string =>
      translate({
        id:
          selected > 0
            ? 'setup.devices.list.footerCountWithSelection'
            : 'setup.devices.list.footer.countDevices',
        values: { count, selected },
      }),

    selectBtn: (allSelected: boolean): string =>
      translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' }),
    visibilityBtn: (someVisible?: boolean): string =>
      translate({
        id: someVisible ? 'global.hideAll' : 'global.showAll',
      }),

    sortByAscending: translate({ id: 'setup.devices.list.sorter.sortBy.ascending' }),
    sortByDescending: translate({
      id: 'setup.devices.list.sorter.sortBy.descending',
    }),
    sortOrderAscending: translate({ id: 'setup.devices.list.sorter.sortOrder.ascending' }),
    sortOrderDescending: translate({
      id: 'setup.devices.list.sorter.sortOrder.descending',
    }),
    noEntries: {
      title: translate({ id: 'devices.list.noEntries.title' }),
      description: translate({ id: 'devices.list.noEntries.description' }),
    },
    noResults: {
      title: translate({ id: 'devices.list.noResults.title' }),
      description: translate({ id: 'devices.list.noResults.description' }),
    },
  }))

  return api
}
