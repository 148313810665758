import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectPlaces } from '@/features/domain/place'

export const usePlacesRoot = () => {
  const { editing } = useEditingStateWithCtx('place')
  const [selection] = useCrudSelection('places')
  const places = useSelector(selectPlaces)
  const selectedPlaces = useMemo(() => getSelectedPlaces(selection, places), [selection, places])

  return {
    places,
    selectedPlaces,
    editing,
    selection,
  }
}

const getSelectedPlaces = (
  selection: workwave.DeepReadonly<string[]>,
  s: Record<string, uui.domain.client.gps.wwgps.Place>,
) => {
  return selection.reduce<uui.domain.client.gps.wwgps.Place[]>((acc, id) => {
    if (s[id]) {
      acc.push(s[id])
    }
    return acc
  }, [])
}
