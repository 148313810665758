import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function DepotCircle(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 19 19">
        <path d="M9.5 19a9.5 9.5 0 1 1 0-19 9.5 9.5 0 0 1 0 19zm0-14L5.45 7.931 5 8.256v4.508c0 .13.101.236.225.236H7.7a.231.231 0 0 0 .225-.236v-2.12c0-.91.707-1.65 1.575-1.65.869 0 1.575.74 1.575 1.65v2.12c0 .13.101.236.225.236h2.475a.231.231 0 0 0 .225-.236V8.26l-.45-.325L9.5 5z" />
      </svg>
    </IconContainer>
  )
}
