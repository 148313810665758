import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { timeWindowUtils } from '@/utils'

import { selectTerritory } from '@/features/domain/territory'
import { useTexts } from './useTexts'

function isOverlappingBreaks(
  b1: uui.domain.rm.Break,
  b2: uui.domain.rm.Break,
  workingDayStartSec: number,
) {
  const { startSec: s1, durationSec: d1, endSec: e1 } = b1
  const { startSec: s2, durationSec: d2, endSec: e2 } = b2

  const tw1 = timeWindowUtils.normalizeTimeWindow({ startSec: s1, endSec: e1 }, workingDayStartSec)
  const tw2 = timeWindowUtils.normalizeTimeWindow({ startSec: s2, endSec: e2 }, workingDayStartSec)

  const { startSec: breakStart1, endSec: breakEnd1 } = tw1
  const { startSec: breakStart2, endSec: breakEnd2 } = tw2

  return breakStart1 <= breakEnd2 + d2 * 60 && breakStart2 <= breakEnd1 + d1 * 60
}

function hasOverlappingBreaks(breaks: uui.domain.rm.Break[], workingDayStartSec: number) {
  return breaks.some((b: uui.domain.rm.Break, index: number) => {
    for (let i = index + 1; i < breaks.length; i++) {
      if (isOverlappingBreaks(b, breaks[i], workingDayStartSec)) {
        return true
      }
    }
    return false
  })
}

function isBreakInValid(workingDayStartSec: number) {
  return ({ startSec, endSec, durationSec }: uui.domain.rm.Break) => {
    const tw = timeWindowUtils.normalizeTimeWindow({ startSec, endSec }, workingDayStartSec)

    const { startSec: normalizeTimeWindowStart, endSec: normalizeTimeWindowEnd } = tw

    return !timeWindowUtils.isTimeWindowValid(
      { startSec: normalizeTimeWindowStart, endSec: normalizeTimeWindowEnd + durationSec * 60 },
      workingDayStartSec,
    )
  }
}

function areBreaksInValid(breaks: uui.domain.rm.Break[], workingDayStartSec: number) {
  return breaks.some(isBreakInValid(workingDayStartSec))
}

function isLoadInvalid({ value, min }: uui.domain.ui.forms.VehicleLoad) {
  return value === 0 || (min > 0 && min > value)
}

function areLoadsInvalid(loads: Record<string, uui.domain.ui.forms.VehicleLoad>) {
  return Object.values(loads).some(isLoadInvalid)
}

export function useOnValidate() {
  const { workingDayStartSec } = useSelector(selectTerritory)
  const texts = useTexts()

  return useCallback(
    (
      formValues: uui.domain.ui.forms.ExceptionFormValues,
    ): uui.domain.ui.forms.ExceptionFormValuesValidation => {
      const result: uui.domain.ui.forms.ExceptionFormValuesValidation = {}

      const {
        workingDayInfo: { workingDayStart, workingDayEnd },
        breaks,
        loads,
      } = formValues

      const workingDayTimeWindow = timeWindowUtils.normalizeTimeWindow(
        { startSec: workingDayStart, endSec: workingDayEnd },
        workingDayStartSec,
      )

      const { startSec: normalizeTimeWindowStartSec, endSec: normalizeTimeWindowEndSec } =
        workingDayTimeWindow

      const isTwValid = timeWindowUtils.isTimeWindowValid(workingDayTimeWindow, workingDayStartSec)

      if (!isTwValid) {
        if (normalizeTimeWindowStartSec < 0 || normalizeTimeWindowEndSec < 0) {
          result.workingDayInfo = texts.validationTWInvalidBounds
        } else if (normalizeTimeWindowStartSec > normalizeTimeWindowEndSec) {
          result.workingDayInfo = texts.validationTWEndLessThanStart
        } else if (normalizeTimeWindowStartSec === normalizeTimeWindowEndSec) {
          // Working day duration must be at least 1 minute long
          result.workingDayInfo = texts.validationTWInvalidRange
        } else {
          result.workingDayInfo = texts.validationTWEndGreaterThanWorkingDayStart
        }
      }

      if (areBreaksInValid(breaks, workingDayStartSec)) {
        result.breaks = texts.validationBreaksInvalidDuration
      } else if (hasOverlappingBreaks(breaks, workingDayStartSec)) {
        result.breaks = texts.validationBreaksOverlapping
      }

      if (areLoadsInvalid(loads)) {
        result.loads = texts.validationLoadsInvalid
      }

      return result
    },
    [texts, workingDayStartSec],
  )
}
