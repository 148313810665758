import { styled } from '@mui/material'

export const HeaderLayout = styled('div', { name: 'HeaderLayout' })(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey['100'],
  borderBottom: `1px solid ${theme.palette.grey['200']}`,

  '& > * + *': {
    paddingTop: theme.spacing(2),
  },
}))
