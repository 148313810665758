import styled from 'styled-components'

import { HorizontalLayout } from '@/components/layout'

export const NavigoContainer = styled(HorizontalLayout)`
  align-items: center;
  background-color: ${p => p.theme.colors.$pureWhite};
  height: 60px;
  padding: 7px 17px 7px 11px;

  & > * + * {
    margin-left: 24px;
  }
`

NavigoContainer.displayName = 'NavigoContainer'
