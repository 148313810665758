import { getMap } from '../../atoms/map/utils/getMap'
import { findLayer } from '../utils/findLayer'

import { changelogRemoveTrafficAreaFeatures } from './utils/changelogRemoveTrafficAreaFeatures'
import { changelogAddTrafficAreaFeatures } from './utils/changelogAddTrafficAreaFeatures'
import { changelogUpdateTrafficAreaFeatures } from './utils/changelogUpdateTrafficAreaFeatures'

export function createUpdateTrafficAreaFeaturesFromChangelog(
  markers: uui.domain.ui.map.markers.MapMarkers['trafficProfile'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['trafficProfile'],
  selection: Set<string>,
  changelog: uui.domain.api.Changelog,
) {
  // ATTENTION: Currently all changelog are applied as soon as they arrive, also when the map is not visible
  // `ignoreSelection` it will be used to avoid to apply any selection information to the map when applying cached updater functions.
  // after all cached updates will be applied a refresh selection action will be required
  return async (_ignoreSelection: boolean = false) => {
    const map = getMap()
    const layer = findLayer(map, 'trafficProfile')

    // remove
    await changelogRemoveTrafficAreaFeatures(layer, selection, changelog.remove.values())

    // add
    await changelogAddTrafficAreaFeatures(
      layer,
      markers,
      mapStyles,
      selection,
      changelog.add.values(),
    )

    // update
    await changelogUpdateTrafficAreaFeatures(
      layer,
      markers,
      mapStyles,
      selection,
      changelog.update.values(),
    )
  }
}
