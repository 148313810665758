import type { TerritoryConfig } from '../../../../../types'

import { useCallback } from 'react'
import { produce } from 'immer'

export function useActions(
  fieldValue: uui.domain.client.rm.CourierFilter,
  apiChange: (fieldValue: uui.domain.client.rm.CourierFilter) => void,
) {
  const onChange = useCallback(
    (territoryId: string, territoryValue: TerritoryConfig) => {
      if (!fieldValue[territoryId]) {
        throw new Error('TerritoryId has no occurrences in fieldValue')
      }

      apiChange({
        ...fieldValue,
        [territoryId]: territoryValue,
      })
    },
    [apiChange, fieldValue],
  )
  const onRemoveTerritory = useCallback(
    (territoryId: string) => {
      if (!fieldValue[territoryId]) {
        throw new Error('TerritoryId has no occurrences in fieldValue')
      }

      const updatedValue = produce(fieldValue, draft => {
        delete draft[territoryId]
      })

      apiChange(updatedValue)
    },
    [apiChange, fieldValue],
  )

  return {
    onChange,
    onRemoveTerritory,
  }
}
