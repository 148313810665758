import { useMemo } from 'react'

import { countVehicleViolations } from '@/local/server-data/domain/helpers/violation'

export interface RouteViolationsCount {
  routeIds: string[]
  violationsCount: number
  constraintsCount: number
}

export function useViolationsCountFromRoutes(
  routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[],
) {
  return useMemo(
    () =>
      routeInfos.reduce<RouteViolationsCount>(
        (violationsAcc, routeInfo) => {
          if (routeInfo.violationsCount === 0) return violationsAcc

          violationsAcc.constraintsCount += countVehicleViolations(routeInfo.violations)
          violationsAcc.violationsCount++
          violationsAcc.routeIds.push(routeInfo.id)

          return violationsAcc
        },
        { violationsCount: 0, constraintsCount: 0, routeIds: [] },
      ),
    [routeInfos],
  )
}
