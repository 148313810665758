import { Portal } from '@mui/base'
import { ToastContainer } from 'react-toastify'

const style = { bottom: '2.5rem', fontSize: 14, lineHeight: '18px' }

export function ToastArea() {
  return (
    <Portal>
      <ToastContainer
        limit={4}
        style={style}
        theme="colored"
        autoClose={5000}
        draggable
        newestOnTop
        closeOnClick
        pauseOnHover
        hideProgressBar
        pauseOnFocusLoss
        position="bottom-right"
        // Classname used as a replacement for data-testid
        className="test__notification__toast-container"
      />
    </Portal>
  )
}
