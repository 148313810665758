import { useSelector } from 'react-redux'
import { selectRoadSegmentsListStructure } from '@/features/domain/lists'

import { useEditingStateWithCtx } from '@/atoms'
import { useFilterList } from './useFilterList'
import { useSearchList } from './useSearchList'

export function useFilter() {
  const { editing } = useEditingStateWithCtx('segment')
  const listStructure = useSelector(selectRoadSegmentsListStructure)
  const { query, setQuery } = useSearchList()
  const { filter, setFilter } = useFilterList()

  return {
    query,
    filter,
    editing,
    setQuery,
    setFilter,
    noEntries: listStructure.totalItems === 0,
  }
}
