import { useCallback } from 'react'
import { Chip, styled } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { useMainSelection } from '@/atoms'

import { useTexts } from './useTexts'

export const Counter = styled(Chip, { name: 'Counter' })(({ theme }) => ({
  marginRight: theme.spacing(1),
  fontWeight: 'bold',
  paddingTop: '2px',
}))

interface Props {
  orderIds: string[]
}

export function OrdersCounter(props: Props) {
  const { orderIds } = props

  const [selectedOrders, setSelectedOrders] = useMainSelection('orderSteps')
  const texts = useTexts()

  const allSelected = orderIds.length === selectedOrders.length
  const color = allSelected ? 'secondary' : undefined

  const onToggleSelection = useCallback(() => {
    if (allSelected) {
      setSelectedOrders([])
      return
    }

    setSelectedOrders(orderIds)
  }, [setSelectedOrders, orderIds, allSelected])

  const title = allSelected ? texts.unselectAll : texts.selectAll

  const testid = 'orders-grid-orders-counter'

  return (
    <Tooltip title={title} placement="bottom">
      <Counter
        onClick={onToggleSelection}
        label={orderIds.length}
        size="small"
        color={color}
        data-testid={testid}
        data-trackid={testid}
      />
    </Tooltip>
  )
}
