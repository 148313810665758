import { Icon, Style } from 'ol/style'
import spriteSheetUrlMaximized from '@/resources/map-markers/orders_badge.png'
import spriteSheetUrlNormal from '@/resources/map-markers/orders_badge_min.png'
import { genericStyles } from '../../../genericStyles'

const orderLabelStylesCache: Map<string, Style> = new Map()

export function getMarkerBadgeStyle(
  marker: uui.domain.ui.map.markers.Order,
  mode: uui.domain.ui.map.markers.MapStyles['order']['mode'],
  selected: boolean = false,
) {
  const placement = mode === 'on' ? marker.badgeMinimized : marker.badgeMaximized
  const spriteSheetUrl = mode === 'on' ? spriteSheetUrlNormal : spriteSheetUrlMaximized

  if (mode === 'off' || !placement) return genericStyles.hidden

  const cacheId = `${placement[0]}_${placement[1]}_${mode}_${
    selected ? 'selected' : 'not-selected'
  }`

  if (orderLabelStylesCache.has(cacheId)) {
    return orderLabelStylesCache.get(cacheId)
  }

  const [x, y, cellSize] = placement

  const style = new Style({
    zIndex: selected ? 2 : mode === 'maximized' ? 1 : undefined,
    image: new Icon({
      src: spriteSheetUrl,
      crossOrigin: 'anonymous',

      scale: 0.5,
      opacity: 1.0,
      rotation: 0.0,
      rotateWithView: true,

      size: [cellSize, cellSize],
      offset: [x, y],

      displacement: mode === 'on' ? [1, 12.5] : [6.5, 20.5],

      anchor: [0, 0],
      anchorOrigin: 'top-left',
      anchorYUnits: 'fraction',
      anchorXUnits: 'fraction',
    }),
  })

  orderLabelStylesCache.set(cacheId, style)

  return style
}
