import type { CSSProperties } from 'react'
import type { Theme } from '@mui/material'

import { useMemo } from 'react'
import { makeStyles } from '@mui/styles'
import { theme } from '@/styles'

const useClasses = makeStyles<Theme>({
  container: {
    width: 40,
    height: 40,
    background: 'var(--background)',
    border: `1px solid ${theme.colors.$pureWhite}`,
    borderRadius: '50%',
    marginRight: 16,
  },
})

interface Props {
  color: string
}

export function useStyles(props: Props) {
  const classes = useClasses()
  const { color } = props

  const styles = useMemo(() => {
    return {
      container: {
        '--background': `#${color}`,
      } as CSSProperties,
    } as const
  }, [color])

  return [classes, styles] as const
}
