import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function ImportOrders(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 17 18">
        <path d="M9.86 1.125H3.797c-.852 0-1.547.695-1.547 1.547v12.375c0 .852.695 1.547 1.547 1.547h9.281c.852 0 1.547-.695 1.547-1.547V5.89c0-.08-.032-.156-.088-.212l-4.465-4.465c-.056-.056-.132-.088-.212-.088zm-.65 5.114V3.01c0-.165.135-.3.3-.3.08 0 .157.032.213.088l3.23 3.23c.117.117.117.307 0 .424-.057.056-.133.088-.212.088H9.51c-.166 0-.3-.134-.3-.3z" />
      </svg>
    </IconContainer>
  )
}
