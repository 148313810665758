import { Component } from 'react'

import { Collapsed } from './components/Collapsed'
import Search from './components/Search'
import { SuggestionsList } from './components/SuggestionsList'
import UiCustomFields from './components/CustomFields'
import CustomFieldsListWrapper from './components/CustomFieldsListWrapper'
import { CustomField } from './components/CustomFields/typings'
import { Props, CustomFields, Step } from './typings'
import { hasAnyPropChanged } from './utils'

interface State {
  step: Step
}

const setStep = (step: Step) => () => ({
  step,
})

const defaultMaxCount: number = 10

const formatValues = (source: CustomFields): CustomField[] =>
  Object.entries(source).map(([key, value]) => ({ key, value }))

const parseValues = (source: CustomField[]): CustomFields =>
  source.reduce((acc: CustomFields, { key, value }: CustomField) => {
    acc[key] = value
    return acc
  }, {})

export default class CustomFieldsList extends Component<Props, State> {
  readonly state: State = {
    step: 'collapsed',
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return hasAnyPropChanged(nextProps, this.props) || hasAnyPropChanged(nextState, this.state)
  }

  private handleOnChange = (key: string, val: string, originalKey: string) => {
    const { onChange, value } = this.props
    const updatedCustomFields: CustomField[] = [...formatValues(value)]
    const customField: CustomField = { key, value: val }

    if (key === originalKey) {
      const index: number = updatedCustomFields.findIndex(
        ({ key: elKey }: CustomField) => elKey === key,
      )

      index === -1
        ? updatedCustomFields.unshift(customField)
        : updatedCustomFields.splice(index, 1, customField)
    } else {
      const originalIndex: number = updatedCustomFields.findIndex(
        ({ key: elKey }: CustomField) => elKey === originalKey,
      )

      if (originalIndex !== -1) {
        updatedCustomFields.splice(originalIndex, 1, customField)
      }
    }

    if (updatedCustomFields.length > 0) {
      onChange(parseValues(updatedCustomFields))
    }
  }

  private handleOnAdd = (customField: string) => {
    this.handleOnChange(customField, '', customField)
  }

  private handleOnDelete = (keyToDelete: string) => {
    const { value, onChange } = this.props

    const updatedCustomFields: CustomFields = Object.entries(value).reduce(
      (acc: CustomFields, [key, val]) => {
        if (key !== keyToDelete) {
          acc[key] = val
        }
        return acc
      },
      {},
    )

    onChange(updatedCustomFields)
  }

  private handleOnChangeStep = () => {
    this.setState(setStep('search'))
  }

  render() {
    const { step } = this.state
    const {
      filter,
      onChangeFilter,
      suggestions,
      value,
      maxCount = defaultMaxCount,
      allowEmptyCustomFields,
    } = this.props

    const hasReachedMax: boolean = Object.keys(value).length === maxCount

    return (
      <CustomFieldsListWrapper data-testid="custom-fields__list-wrapper">
        {(step === 'collapsed' || hasReachedMax) && (
          <Collapsed onChangeStep={this.handleOnChangeStep} disabled={hasReachedMax} />
        )}

        {step === 'search' && !hasReachedMax && (
          <>
            <Search onChange={onChangeFilter} filter={filter} onAdd={this.handleOnAdd} />
            <SuggestionsList list={suggestions} onAdd={this.handleOnAdd} />
          </>
        )}

        <UiCustomFields
          onChange={this.handleOnChange}
          onDelete={this.handleOnDelete}
          customFields={formatValues(value)}
          allowEmptyCustomFields={allowEmptyCustomFields}
        />
      </CustomFieldsListWrapper>
    )
  }
}
