import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    drivingTime: (speedFactor: number) =>
      translate({
        id: 'setup.traffic.defaultDrivingTimeText',
        values: { speedFactor },
      }),
    timeWindowsCount: (count: number) =>
      translate({ id: 'setup.traffic.timeWindowsText', values: { count } }),
  }))

  return api
}
