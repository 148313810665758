import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    changePlan: translate({ id: 'settings.messagingPlan.view.changePlan' }),
    changePlanDisabled: translate({ id: 'settings.messagingPlan.view.changePlan.tooltip.title' }),
  }))

  return api
}
