import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { setLayerMetadata } from '../../layerMetadata'

import { findPlaceMarker } from './findPlaceMarker'
import { createPlaceFeature } from './createPlaceFeature'

const nonblockingRender = createNonBlockingRenderByTime()

export const changelogAddPlaceFeatures = async (
  layer: VectorLayer<VectorSource>,
  markers: Record<string, uui.domain.ui.map.markers.Place>,
  mapStyles: uui.domain.ui.map.markers.MapStyles['place'],
  selection: Set<string>,
  markerIds: IterableIterator<string>,
) => {
  const features: Feature<Geometry>[] = []

  nonblockingRender.reset()

  for (const markerId of markerIds) {
    await nonblockingRender.next()

    const marker = findPlaceMarker(markers, markerId)
    if (!marker) {
      throw new Error(
        `Trying to add Place feature with lat-lng: ${markerId} but no marker exist on the map`,
      )
    }

    const selected = selection.has(markerId)

    features.push(createPlaceFeature(marker, mapStyles, selected))
  }

  // add all new features to the layer
  layer.getSource()?.addFeatures(features)

  // update the stored collection
  setLayerMetadata(layer, 'selection', selection)
}
