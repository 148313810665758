import type Feature from 'ol/Feature'

import CircleStyle from 'ol/style/Circle'
import { Fill, Stroke, Style } from 'ol/style'

import MultiPoint from 'ol/geom/MultiPoint'
import Geometry from 'ol/geom/Geometry'
import LineString from 'ol/geom/LineString'

import { drawStyleCommon } from './common'

const common = drawStyleCommon()

export const points = new Style({
  image: new CircleStyle({
    radius: common.pointRadius,
    fill: new Fill({
      color: common.black(1),
    }),
    stroke: new Stroke({
      color: common.white(),
      width: common.pointBorderWidth,
    }),
  }),
  geometry: (feature: Feature<Geometry>) => {
    const geometry = feature?.getGeometry()

    if (geometry instanceof Geometry && geometry.getType() === 'LineString') {
      const line = geometry as LineString
      const coordinates = line.getCoordinates()

      if (!coordinates || coordinates.length === 0) return

      // first point
      return new MultiPoint(coordinates)
    }
  },
})
