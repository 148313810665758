import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
} from '@/forms-legacy'

import { useCollapsibleState } from '../../../../../atoms/collapsibleState'
import { CollapseButton } from '../../../../../components/CollapseButton'

import { useTexts } from '../useTexts'

interface Props {
  metric?: boolean
  fixedCost?: string
  serviceTime?: string
  drivingTime?: string
  idleTime?: string
  breakTime?: string
  perMile?: string
  perStop?: string
  notes?: string
}

export function Costs(props: Props) {
  const { fixedCost, serviceTime, drivingTime, idleTime, breakTime, perMile, perStop, metric } =
    props

  const [collapsed, toggleCollapsed] = useCollapsibleState('costs')
  const texts = useTexts()

  return (
    <>
      <CollapseButton toggleCollapsed={toggleCollapsed} collapsed={collapsed}>
        {texts.costs}
      </CollapseButton>
      {!collapsed && (
        <>
          <ReadonlyBlock half>
            <ReadonlyLabel empty={!fixedCost}>{texts.fixedCosts}</ReadonlyLabel>
            {!!fixedCost && (
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>{fixedCost}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            )}
          </ReadonlyBlock>

          <ReadonlyBlock half>
            <ReadonlyLabel empty={!serviceTime}>{texts.serviceTimeCosts}</ReadonlyLabel>
            {!!serviceTime && (
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>{serviceTime}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            )}
          </ReadonlyBlock>

          <ReadonlyBlock half>
            <ReadonlyLabel empty={!drivingTime}>{texts.drivingTimeCosts}</ReadonlyLabel>
            {!!drivingTime && (
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>{drivingTime}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            )}
          </ReadonlyBlock>

          <ReadonlyBlock half>
            <ReadonlyLabel empty={!idleTime}>{texts.idleTimeCosts}</ReadonlyLabel>
            {!!idleTime && (
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>{idleTime}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            )}
          </ReadonlyBlock>

          <ReadonlyBlock half>
            <ReadonlyLabel empty={!breakTime}>{texts.breakTimeCosts}</ReadonlyLabel>
            {!!breakTime && (
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>{breakTime}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            )}
          </ReadonlyBlock>

          <ReadonlyBlock half>
            <ReadonlyLabel empty={!perMile}>{texts.costMeasure(!!metric)}</ReadonlyLabel>
            {!!perMile && (
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>{perMile}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            )}
          </ReadonlyBlock>

          <ReadonlyBlock half>
            <ReadonlyLabel empty={!perStop}>{texts.stopCosts}</ReadonlyLabel>
            {!!perStop && (
              <ReadonlyField>
                <ReadonlyFieldRow>
                  <ReadonlyFieldLabel>{perStop}</ReadonlyFieldLabel>
                </ReadonlyFieldRow>
              </ReadonlyField>
            )}
          </ReadonlyBlock>
        </>
      )}
    </>
  )
}
