import { styled } from '@mui/material'

export const TopBarContainer = styled('div', { name: 'TopBarContainer' })(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  background: `${theme.palette.background.default2 ?? '#F3F5F7'}`,
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  display: 'flex',
  width: '100%',
}))
