import { type PropsWithChildren } from 'react'
import { Stack } from '@mui/material'

export function CenteredLayout(
  props: PropsWithChildren<{ testId?: string; width: string | number }>,
) {
  const { testId, width, children } = props

  return (
    <Stack
      gap={3}
      alignItems="center"
      justifyContent="center"
      height="inherit"
      max-height="100%"
      data-testid={testId}
      data-trackid={testId}
      width={width}
      margin="0 auto"
      paddingY={4}
      paddingX={6}
    >
      {children}
    </Stack>
  )
}
