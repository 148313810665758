import type { MapTile } from '../map'

import XYZ from 'ol/source/XYZ'

import { mapAtom } from '../map'
import { getTileUrl } from './tiles'
import { getTilesLayer } from './getTilesLayer'

export const changeMapTiles = (tile: MapTile) => {
  const tileLayer = getTilesLayer()

  if (!tileLayer || !mapAtom.authenticationToken) {
    throw new Error(`Trying to change map tiles before the map is ready.`)
  }

  const tileSource = tileLayer.getSource()

  if (!(tileSource instanceof XYZ)) {
    throw new Error(`The Map Tile Source is not valid.`)
  }

  // update the atom
  mapAtom.tile = tile

  // change source
  tileSource.setUrl(getTileUrl({ tileType: tile }))
}
