import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDevicesAggregateInfo } from '@/features/domain/device'

export function useDeviceLabel(deviceId: string) {
  const deviceAggregateInfo = useSelector(selectDevicesAggregateInfo)

  return useMemo(() => {
    const device = deviceAggregateInfo[deviceId]
    if (!device) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('cannot find device')
      }
      return deviceId
    }

    return device.device.label ?? deviceId
  }, [deviceAggregateInfo, deviceId])
}
