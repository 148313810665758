import type { DayProps } from '../../../typings'
import type { DaysByMonth, Props } from './typings'

import { useMemo } from 'react'
import {
  format,
  getDay,
  addDays,
  subDays,
  isBefore,
  isSameDay,
  eachDayOfInterval,
} from 'date-fns/esm'

import { useDateFnsOptions } from '@/hooks'

import { parseDate } from '../../../utils/parseDate'
import { matchHighlightProducer } from '../matchHighlightProducer'

import { getRoundedBorder } from './getRoundedBorder'
import { getSelectionData } from './getSelectionData'
import { getCosmetic } from './getCosmetic'
import { getCosmeticForSelectionInThePast } from './getCosmeticForSelectionInThePast'

const emptyDay = { type: 'empty' } as const

export function useIntervalDays(props: Props): DaysByMonth {
  const dateFnsOptions = useDateFnsOptions()
  const {
    today,
    months,
    selection,
    highlightToday,
    disabledDays,
    selectionStart,
    edgeOfSelection,
    fixedRangeStart,
    tooltipIntlText,
    selectableInterval,
    firstNonArchivedDay,
    selectionEnd,
    highlightedDays = {},
  } = props

  return useMemo<DaysByMonth>(() => {
    const todayDate = parseDate(today)

    const firstNonArchivedDayDate = !!firstNonArchivedDay
      ? parseDate(firstNonArchivedDay)
      : undefined

    const daysByMonth: DaysByMonth = {}

    const selectingAnIntervalInThePast =
      !!selectionStart && !!selectionEnd && isBefore(selectionEnd, selectionStart)

    for (const month of months) {
      const { offset, start, end, id: monthId } = month

      const dates = eachDayOfInterval({
        start,
        end,
      })

      const days = dates.map<DayProps>((date, index, values) => {
        const nextDayAsString = format(addDays(date, 1), 'yyyyMMdd')
        const dateAsString = format(date, 'yyyyMMdd')

        const disabled = !!disabledDays && !!disabledDays[dateAsString]
        const isToday = isSameDay(todayDate, date)
        const dayNumber = getDay(date)

        const { selected, isOnTheEdge, isSelectionStart, isSelectionEnd } = getSelectionData({
          date,
          selection,
          dateAsString,
          selectionStart,
          selectionEnd,
          nextDayAsString,
          edgeOfSelection,
        })

        const cosmetic = selectingAnIntervalInThePast
          ? getCosmeticForSelectionInThePast({ isSelectionEnd })
          : getCosmetic({
              isToday,
              disabled,
              selected,
              isOnTheEdge,
              dateAsString,
              highlightToday,
              selectableInterval,
            })

        const roundedBorder = getRoundedBorder({
          index,
          values,
          selected,
          dayNumber,
          dateFnsOptions,
        })

        const secondaryRangeStartRepresentation =
          !isSelectionEnd && isSelectionStart && fixedRangeStart

        const isHighlighted = matchHighlightProducer(highlightedDays)(dateAsString)

        return {
          date,
          isToday,
          disabled,
          cosmetic,
          type: 'day',
          bold: isToday,
          roundedBorder,
          id: dateAsString,
          underline: isToday,
          dot: isHighlighted,
          selectionEnd: selectingAnIntervalInThePast ? false : isSelectionEnd,
          selectionStart: isSelectionStart,
          secondaryRangeStartRepresentation,
          label: format(date, 'd', dateFnsOptions),
          firstOfRow: dayNumber === dateFnsOptions.weekStartsOn,
          leftSeparator: firstNonArchivedDayDate && isSameDay(firstNonArchivedDayDate, date),
          tooltipText: isHighlighted ? tooltipIntlText?.(highlightedDays[dateAsString]) : undefined,
        }
      })

      const firstDay = days[0]
      const lastDay = days[days.length - 1]
      daysByMonth[monthId] = []

      // Let's calculate empty days before the first day paying attention if is the first of the row
      for (let i = offset[0]; i > 0; i--) {
        daysByMonth[monthId].unshift({
          ...emptyDay,
          firstOfRow: getDay(subDays(firstDay.date, i)) === dateFnsOptions.weekStartsOn,
        })
      }

      daysByMonth[monthId].push(...days)

      // Let's calculate empty days after the last day paying attention if is the first of the row
      for (let i = 0; i < offset[1]; i++) {
        daysByMonth[monthId].push({
          ...emptyDay,
          firstOfRow: getDay(subDays(lastDay.date, i)) === dateFnsOptions.weekStartsOn,
        })
      }
    }

    return daysByMonth
  }, [
    months,
    selectionEnd,
    dateFnsOptions,
    today,
    firstNonArchivedDay,
    highlightedDays,
    disabledDays,
    edgeOfSelection,
    selection,
    selectableInterval,
    fixedRangeStart,
    selectionStart,
    highlightToday,
    tooltipIntlText,
  ])
}
