import { useEffect, useState } from 'react'
import { useAppDispatch } from '@/store'
import { loadSimulationsList } from '@/features/domain/simulation'

type FetchStatus = 'ready' | 'failed' | 'loading'

/**
 * Simulations can be created and deleted by other users, that's why they should be refreshed when
 * the list mounts
 */
export const useFetchSimulations = () => {
  const dispatch = useAppDispatch()
  const [status, setStatus] = useState<FetchStatus>('ready')

  useEffect(() => {
    let effectCleaned = false

    const fetchSimulations = async () => {
      try {
        if (effectCleaned) return

        setStatus('loading')
        const response = await dispatch(loadSimulationsList())

        if (effectCleaned) return

        setStatus(loadSimulationsList.fulfilled.match(response) ? 'ready' : 'failed')
      } catch (e) {
        if (effectCleaned) return

        setStatus('failed')
      }
    }

    fetchSimulations()

    return () => {
      effectCleaned = true
    }
  }, [dispatch])

  return status
}
