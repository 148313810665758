import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    id: translate({ id: 'rm.scheduler.popOver.order.id' }),
    status: translate({ id: 'rm.scheduler.popOver.order.status' }),
    statusDescription: (status?: uui.domain.client.rm.OrderStepTrackingData['status']) => {
      switch (status) {
        case 'missing':
          return translate({ id: 'rm.scheduler.popover.status.assigned' })
        case 'reschedule':
          return translate({ id: 'rm.scheduler.popover.status.reschedule' })
        case 'done':
          return translate({ id: 'rm.scheduler.popover.status.done' })
        default:
          return ''
      }
    },
    arrival: translate({ id: 'rm.scheduler.popOver.order.arrival' }),
    departure: translate({ id: 'rm.scheduler.popOver.order.departure' }),
    onTime: translate({ id: 'rm.scheduler.popOver.order.onTime' }),
    service: translate({ id: 'rm.scheduler.popOver.order.service' }),
    violations: translate({ id: 'rm.scheduler.popOver.order.violations' }),
    violation: (violationType: uui.domain.rm.RouteStepViolationsType) => {
      switch (violationType) {
        case 'VE':
          return translate({ id: 'rm.scheduler.violations.routeStep.forcedVehicle' })
        case 'TGI':
          return translate({ id: 'rm.scheduler.violations.routeStep.tagsIn' })
        case 'TGO':
          return translate({ id: 'rm.scheduler.violations.routeStep.tagsOut' })
        case 'ELG':
          return translate({ id: 'rm.scheduler.violations.routeStep.eligibility' })
        case 'TW':
          return translate({ id: 'rm.scheduler.violations.routeStep.timeWindow' })
        case 'RGN':
          return translate({ id: 'rm.scheduler.violations.routeStep.region' })
        case 'PDM':
          return translate({ id: 'rm.scheduler.violations.routeStep.missingPaired' })
        case 'PDI':
          return translate({
            id: 'rm.scheduler.violations.routeStep.invertedPickupDelivery',
          })
        case 'OWB':
          return translate({
            id: 'rm.scheduler.violations.routeStep.outsideWorkingTimeBounds',
          })
        default:
          return violationType
      }
    },
  }))

  return api
}
