import { useEditingStateWithCtx } from '@/atoms'

import { useSelectedUsers } from './useSelectedUsers'

type Mode = { selection: string[]; selectedUsers: uui.domain.client.rm.RouteManagerUser[] } & (
  | { mode: 'editUser' }
  | { mode: 'viewUser' }
  | { mode: 'createUser' }
  | { mode: 'noUserSelected' }
  | { mode: 'moreUsersSelected' }
)

export function useMode(): Mode {
  const { editing } = useEditingStateWithCtx('settings')

  const { selection, selectedUsers } = useSelectedUsers()

  // EMPTY SELECTION
  if (selectedUsers.length === 0 && editing) {
    return { mode: 'createUser', selection, selectedUsers }
  }

  if (selectedUsers.length === 0 && !editing) {
    return { mode: 'noUserSelected', selection, selectedUsers }
  }

  // ONE USER SELECTED
  if (selectedUsers.length === 1 && editing) {
    return { mode: 'editUser', selection, selectedUsers }
  }

  if (selectedUsers.length === 1 && !editing) {
    return { mode: 'viewUser', selection, selectedUsers }
  }

  // MORE USERS SELECTED
  if (selectedUsers.length > 1) {
    return { mode: 'moreUsersSelected', selection, selectedUsers }
  }

  // UNKNOWN
  throw new Error(`Unknown users state`)
}
