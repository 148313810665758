import type { AxiosResponse } from 'axios'

import axios from 'axios'
import { httpDefaultResponse } from './httpDefaultResponse'

type Request = {
  authtoken: string
  serverUrl: string
}
export async function logout(req: Request) {
  const { authtoken, serverUrl } = req

  const response: AxiosResponse<any> = await axios.get(`${serverUrl}/logout`, {
    params: { authtoken },
  })

  return httpDefaultResponse(response)
}
