import { PureComponent } from 'react'

import {
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyVerticalList,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
  ReadonlyFieldRowRightSideLabel,
} from '@/forms-legacy'

import { numberUtils } from '@/utils'
import * as OrderTexts from '../../../intl'

import * as Texts from '../intl'
import { Props, FieldMode } from '../typings'

export default class Loads extends PureComponent<Props> {
  render() {
    const { loads, useMetric } = this.props

    const loadsList: [string, number | FieldMode.mixed][] = Object.entries(loads)

    return (
      <>
        <ReadonlyBlock>
          <ReadonlyLabel primary empty={loadsList.length === 0}>
            {Texts.getLoadsText()}
          </ReadonlyLabel>

          {loadsList.length > 0 && (
            <ReadonlyVerticalList small>
              {loadsList.map(([key, value]) => (
                <ReadonlyField key={key}>
                  <ReadonlyFieldRow>
                    <ReadonlyFieldLabel>{key}</ReadonlyFieldLabel>
                    <ReadonlyFieldRowRightSideLabel small>
                      {value === FieldMode.mixed
                        ? OrderTexts.getMixedText()
                        : numberUtils.fromNumberToString(
                            value / 100,
                            numberUtils.getDecimalSeparator(useMetric),
                          )}
                    </ReadonlyFieldRowRightSideLabel>
                  </ReadonlyFieldRow>
                </ReadonlyField>
              ))}
            </ReadonlyVerticalList>
          )}
        </ReadonlyBlock>
        <SecondaryColumnContentHorizontalRuler bottomSpace />
      </>
    )
  }
}
