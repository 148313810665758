import type { State, UpdateAction } from '../useTimePicker'
import type { TimeFormat } from '../../types'

import { useValidateOn } from '../../../../hooks/useValidateOn'
import { useKeyboardActions } from './useKeyboardActions'
import { useChangeActions } from './useChangeActions'
import { useFocusActions } from './useFocusActions'
import { useMouseActions } from './useMouseActions'

interface Params {
  updateComponentState: React.Dispatch<UpdateAction>
  validations: ReturnType<typeof useValidateOn>
  allowUnlistedValues: boolean
  timeFormat: TimeFormat
  componentState: State
  options: number[]
}

export function useActions(params: Params) {
  const {
    updateComponentState,
    allowUnlistedValues,
    componentState,
    validations,
    timeFormat,
    options,
  } = params

  const mouseActions = useMouseActions(componentState, updateComponentState)
  const focusActions = useFocusActions({
    updateComponentState,
    allowUnlistedValues,
    componentState,
    validations,
    timeFormat,
    options,
  })
  const keyboardActions = useKeyboardActions({
    onBlur: focusActions.onBlur,
    updateComponentState,
    allowUnlistedValues,
    componentState,
    validations,
    timeFormat,
    options,
  })
  const changeActions = useChangeActions({
    updateComponentState,
    allowUnlistedValues,
    timeFormat,
    options,
  })

  return {
    keyboardActions,
    changeActions,
    mouseActions,
    focusActions,
  }
}
