import type { ActionPermissions } from '../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCalendarRange } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'

import { useDevicesNavigator } from '../../useDevicesNavigator'
import { useStylerData } from '../../useStylerData'
import { useIsGpsOnly } from '../../useIsGpsOnly'

import { computeCenterOnMapPermission } from './utils/computeCenterOnMapPermission'
import { computeBreadcrumbsPermission } from './utils/computeBreadcrumbsPermission'
import { computeSendMessagePermission } from './utils/computeSendMessagePermission'
import { computeReportPermission } from './utils/computeReportPermission'
import { computeEventsPermission } from './utils/computeEventsPermission'
import { computeStylePermission } from './utils/computeStylePermission'
import { computeEditPermission } from './utils/computeEditPermission'
import { useCanEditGpsData } from './useCanEditGpsData'

export function usePermissions(): ActionPermissions {
  const { today, userType } = useSelector(selectUserConfiguration)
  const { currentDevices } = useDevicesNavigator()
  const { activeMapStyle } = useStylerData()
  const canEditGpsData = useCanEditGpsData()
  const calendarRange = useSelector(selectCalendarRange)
  const isGpsOnly = useIsGpsOnly()

  const devicesCount = currentDevices.length

  const isToday = calendarRange.minDate === calendarRange.maxDate && calendarRange.minDate === today

  return useMemo(
    () => ({
      centerOnMap: computeCenterOnMapPermission(isToday, activeMapStyle),
      sendMessage: computeSendMessagePermission(devicesCount, isToday, isGpsOnly),
      breadcrumbs: computeBreadcrumbsPermission(devicesCount),
      events: computeEventsPermission(),
      report: computeReportPermission(devicesCount, userType),
      style: computeStylePermission(isToday),
      edit: computeEditPermission(devicesCount, canEditGpsData),
    }),
    [devicesCount, isToday, userType, activeMapStyle, canEditGpsData, isGpsOnly],
  )
}
