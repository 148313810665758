import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Edit } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
  disabled: boolean
}

export function EditButton(props: Props) {
  const { disabled, onClick } = props
  const texts = useTexts()

  return (
    <ReadOnlyTooltip
      canEdit={!disabled}
      title={texts.notAvailableInThePast}
      render={preventEditing => (
        <NavigoIconButton
          text={texts.edit}
          Icon={<Edit size={17} color={preventEditing || disabled ? '$silver' : '$nightRider'} />}
          onClick={onClick}
          disabled={preventEditing || disabled}
          testid="navigo-device-overview-actions-edit"
        />
      )}
    />
  )
}
