import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function VehicleAssignment(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 15 17">
        <path d="M4.76 0a2.692 2.692 0 0 0-2.72 2.72 2.692 2.692 0 0 0 2.72 2.72 2.692 2.692 0 0 0 2.72-2.72A2.692 2.692 0 0 0 4.76 0zm4.218 1.36a.347.347 0 0 0-.191.053l-.393.276-.383-.276a.325.325 0 0 0-.117-.042c.174.411.266.867.266 1.349 0 .486-.1.946-.276 1.36h3.336c.09 0 .18-.032.244-.096l.68-.68a.345.345 0 0 0 .096-.244v-.68c0-.09-.032-.18-.096-.244l-.68-.68a.345.345 0 0 0-.244-.096h-.574a.32.32 0 0 0-.234.096l-.382.393-.382-.393a.345.345 0 0 0-.245-.096h-.425zm-4.898.68a.68.68 0 1 1 0 1.36.68.68 0 0 1 0-1.36zm-.106 4.08c-.687 0-1.311.422-1.562 1.052L1.402 9.52H1.02C.458 9.52 0 9.978 0 10.54s.458 1.02 1.02 1.02v4.42c0 .562.458 1.02 1.02 1.02h1.02c.562 0 1.02-.458 1.02-1.02v-.34h6.12v.34c0 .562.458 1.02 1.02 1.02h1.02c.562 0 1.02-.458 1.02-1.02v-4.42c.562 0 1.02-.458 1.02-1.02s-.458-1.02-1.02-1.02h-.383l-.998-2.337a1.704 1.704 0 0 0-1.573-1.063H3.974zm0 .68h6.332c.412 0 .79.26.946.648l.956 2.253-.33.223c-.266.187-.578.276-.934.276H3.336c-.356 0-.661-.096-.935-.287l-.33-.212.968-2.264c.152-.38.523-.637.935-.637zm-.404 5.44c.475 0 .85.375.85.85s-.375.85-.85.85a.842.842 0 0 1-.85-.85c0-.475.375-.85.85-.85zm7.14 0c.475 0 .85.375.85.85s-.375.85-.85.85a.842.842 0 0 1-.85-.85c0-.475.375-.85.85-.85z" />
      </svg>
    </IconContainer>
  )
}
