import type Feature from 'ol/Feature'

import Icon from 'ol/style/Icon'
import { Style } from 'ol/style'
import Point from 'ol/geom/Point'
import Geometry from 'ol/geom/Geometry'

import { gis } from '@/server-data'

import { getGeofencePinHandle } from '../../../../../utils/getGeofencePinHandle'
import { mapAtom } from '../../../../../atoms/map/map'

const labelStylesCache: Map<string, Style> = new Map()

export function getGeofenceRadiusLabelStyle(radius: number) {
  const labelCacheId = `${radius}`

  if (labelStylesCache.has(labelCacheId)) {
    const cachedLabelStyle = labelStylesCache.get(labelCacheId)

    if (cachedLabelStyle) return cachedLabelStyle
  }

  const labelToRender = `${radius}m`

  const style = new Style({
    image: new Icon({
      opacity: 1,
      scale: 1,
      src:
        'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(getGeofencePinHandle(labelToRender)),
    }),

    geometry: (feature: Feature<Geometry>) => {
      if (!feature) return

      const radius = feature.getProperties().radius as number
      if (!radius) return

      const geometry = feature.getGeometry()

      if (geometry instanceof Geometry && geometry.getType() === 'Point') {
        const center = geometry as Point
        const centerCoordinate = center.getCoordinates()

        const rightLatLng = gis.computeOffset(
          gis.fromCoordinateToLatLng(centerCoordinate),
          radius,
          90,
        )
        const rightCoordinate = gis.fromLatLngToCoordinate(rightLatLng)

        const rightPixels = mapAtom.map.getPixelFromCoordinate(rightCoordinate)

        const [x, y] = rightPixels
        const shiftedRightCoord = mapAtom.map.getCoordinateFromPixel([x + 40, y])

        return new Point(shiftedRightCoord)
      }
    },
  })

  labelStylesCache.set(labelCacheId, style)
  return style
}
