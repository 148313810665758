import type { FormFields, FormErrors } from '../../../../../formFields'

import { Password } from '@workwave-tidal/tidal/form-ui'

import { useTexts } from './useTexts'

export function OldPassword() {
  const texts = useTexts()

  return (
    <Password<'oldPassword', FormFields, FormErrors>
      name="oldPassword"
      label={texts.oldPassword}
      testId="old-password-field"
      autoFocus
      validateOn="blur focus change"
    />
  )
}
