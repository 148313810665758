import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: (vehicleLabel: string, deviceId: string) =>
      `${vehicleLabel} - ${translate({
        id: 'gps.trackinglist.list.tooltip.deviceId',
        values: { deviceId },
      })}`,

    updatedJustNow: translate({
      id: 'gps.trackinglist.list.updated.justNow',
    }),

    updatedSomeMinutesAgo: minutes =>
      translate({
        id: 'gps.trackinglist.list.updated.minutesAgo',
        values: { minutes },
      }),

    updatedSomeHoursAgo: hours =>
      translate({
        id: 'gps.trackinglist.list.updated.hoursAgo',
        values: { hours },
      }),

    updatedYesterday: translate({
      id: 'gps.trackinglist.list.updated.yesterday',
    }),

    updatedSomeDaysAgo: days =>
      translate({
        id: 'gps.trackinglist.list.updated.daysAgo',
        values: { days },
      }),

    lastUpdate: lastUpdateDate =>
      translate({
        id: 'gps.trackinglist.list.lastUpdate',
        values: { lastUpdate: lastUpdateDate },
      }),

    noTags: translate({ id: 'global.noTags' }),
    noBadges: translate({ id: 'global.noGpsTags' }),
    badgeDevice: translate({ id: 'trackingList.badge.device' }),
  }))

  return api
}
