import { useCallback, useState } from 'react'

import { SecondaryColumnHeader, FormBulkAvatar } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'
import { resetCrudSelection } from '@/atoms'
import { theme } from '@/styles'

import { ActionsManager } from './components/ActionsManager'
import { useTexts } from './hooks/useTexts'

interface Props {
  geofences: uui.domain.client.gps.wwgps.Geofence[]
}

export function BulkView(props: Props) {
  const { geofences } = props

  const [view, setView] = useState<'default' | 'delete'>(() => 'default')
  const texts = useTexts()

  const onReset = useCallback(() => {
    resetCrudSelection('geofences')
  }, [])

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        action={onReset}
        title={texts.title(geofences.length)}
        description={texts.description}
        data-testid="geofences-readonly-header-bulk"
        avatar={<FormBulkAvatar color={theme.colors.$nightRider} size={geofences.length} />}
      />

      <ActionsManager view={view} setView={setView} geofences={geofences} />
    </FormColumn>
  )
}
