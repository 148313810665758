import type { CardCosmetic } from '../../typings'

export function computeTestLabel(cosmetic?: CardCosmetic) {
  if (cosmetic === 'edge-of-selection' || cosmetic === 'selected') {
    return 'selected'
  }

  if (cosmetic === 'grayed-out') {
    return 'disabled'
  }

  return undefined
}

export function computeTestId(underline?: boolean): string | undefined {
  return underline ? 'today' : undefined
}
