import { CircularProgress } from '@mui/material'
import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'

import { useIsLoading } from '@/atoms'
import { Breadcrumbs, NoBreadcrumbs } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  status: uui.domain.ui.map.markers.MapMode
  onClick: () => void
  disabled: boolean
}

export function BreadcrumbsButton(props: Props) {
  const { disabled, status, onClick } = props

  const isLoading = useIsLoading('fetchBreadcrumbs')
  const texts = useTexts()

  const iconColor = disabled ? '$silver' : '$nightRider'

  const Icon = isLoading ? (
    <CircularProgress size={16} color="inherit" />
  ) : status ? (
    <Breadcrumbs size={16} color={iconColor} />
  ) : (
    <NoBreadcrumbs size={16} color={iconColor} />
  )

  return (
    <NavigoIconButton
      Icon={Icon}
      onClick={onClick}
      disabled={disabled}
      text={texts.breadcrumbs}
      testid="navigo-device-overview-actions-breadcrumbs"
      data-breadcrumbs-enabled={status === 'on'}
    />
  )
}
