import { CSSProperties, ReactNode, ReactElement } from 'react'
import { intl } from '@/intl'

import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'

import { Check, RotateLeft } from '@/icons'
import * as OrderTexts from '../../intl'
import { StatusData, CalculatedServiceTime } from './typings'

export const parseSecondToText = (seconds: number): ReactElement =>
  seconds > 0 ? (
    <ConvertTime seconds={seconds} showSeconds={false} />
  ) : (
    <>{OrderTexts.getNoneText()}</>
  )

export const getStatusData = ({
  status,
  statusSec,
}: uui.domain.client.rm.OrderStepTrackingData): StatusData => ({
  status,
  statusSec,
})

export const getOrderStatusBadgeColor = (
  status: workwave.Nullable<uui.domain.client.rm.OrderStepTrackingData['status']>,
): string => {
  if (!status) {
    return 'transparent'
  }

  switch (status) {
    case 'done':
      return '#7ed321'

    case 'missing':
      return 'transparent'

    case 'reschedule':
      return '#ff9500'
  }
}

export const getOrderStatusBadgeStyle = (
  status: workwave.Nullable<uui.domain.client.rm.OrderStepTrackingData['status']>,
): CSSProperties => {
  if (!status || status === 'missing') {
    return {
      marginRight: 6,
      marginTop: 2,
      borderColor: '#aeaeae',
      borderStyle: 'dashed',
    }
  }

  return {
    marginRight: 6,
    marginTop: 2,
  }
}

export const getOrderStatusBadgeIcon = (
  status: workwave.Nullable<uui.domain.client.rm.OrderStepTrackingData['status']>,
): ReactNode => {
  if (!status) {
    return null
  }

  switch (status) {
    case 'done':
      return <Check />

    case 'reschedule':
      return <RotateLeft />

    case 'missing':
      return null
  }
}

// ----------------------------- DETECTED

export const getCalculatedServiceTime = (
  trackingData: uui.domain.client.rm.OrderStepTrackingData,
  rescheduleBehavior: uui.domain.client.rm.Territory['rescheduleBehavior'],
): CalculatedServiceTime => {
  const { timeInSec, timeOutSec, autoTimeInSec, autoTimeOutSec } = trackingData

  const hasReportedValue: boolean = timeInSec > -1 && timeOutSec > -1
  const hasDetectedValue: boolean = autoTimeInSec > -1 && autoTimeOutSec > -1

  const deltaReportedValue: number = timeOutSec - timeInSec
  const deltaDetectedValue: number = autoTimeOutSec - autoTimeInSec

  const locReported: uui.domain.intl.LocalizationIdentifier = 'orders.form.basedOnReportedTime'
  const locDetected: uui.domain.intl.LocalizationIdentifier = 'orders.form.basedOnDetectedTime'

  let timeDeltaSec: number = -1
  let locID: workwave.Nullable<uui.domain.intl.LocalizationIdentifier>

  if (hasDetectedValue || hasReportedValue) {
    switch (rescheduleBehavior) {
      // reschedule based on reported times. Fallback to detected if no reported is available
      case 'ON_REPORTED_FIRST':
        if (hasReportedValue) {
          timeDeltaSec = deltaReportedValue
          locID = locReported
        } else if (hasDetectedValue) {
          timeDeltaSec = deltaDetectedValue
          locID = locDetected
        }
        break

      // reschedule based on detected times. Fallback to reported if no detected is available
      case 'ON_DETECTED_FIRST':
        if (hasDetectedValue) {
          timeDeltaSec = deltaDetectedValue
          locID = locDetected
        } else if (hasReportedValue) {
          timeDeltaSec = deltaReportedValue
          locID = locReported
        }
        break

      case 'ON_REPORTED_ONLY':
        if (hasReportedValue) {
          timeDeltaSec = deltaReportedValue
          locID = locReported
        }
        break

      case 'ON_DETECTED_ONLY':
        if (hasDetectedValue) {
          timeDeltaSec = deltaDetectedValue
          locID = locDetected
        }
        break

      default:
        timeDeltaSec = -1
        break
    }
  }

  return {
    time: timeDeltaSec,
    locID,
    text: locID ? intl.translate({ id: locID }) : '',
  }
}
