import { useCallback } from 'react'

import { useEditingStateWithCtx } from '@/atoms'

export function useActions(
  geofence: uui.domain.client.gps.wwgps.Geofence,
  setView: (view: 'default' | 'delete') => void,
) {
  const { setEditing } = useEditingStateWithCtx('geofence')

  const onDelete = useCallback(() => setView('delete'), [setView])
  const onEdit = useCallback(() => setEditing([geofence.id]), [setEditing, geofence])

  return {
    onDelete,
    onEdit,
  }
}
