import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type Payload = {
  vehicleIds: string[]
  exception: string
  settings: uui.domain.server.rm.VehicleSettings
}

/**
 * Update vehicles settings
 *
 * @private
 */
export const updateVehiclesSettings = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('rpc/vehicle/updateVehiclesSettings', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const result = await rpc('rpc/vehicle/updateVehiclesSettings', {
      category: 'rpc',
      type: 'rpc/vehicle/updateVehiclesSettings',
      vehicleIds: payload.vehicleIds,
      settings: payload.settings,
      exception: payload.exception,
    })
    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {deleteVehicles}`,
      { tags: ['rpc', 'vehicles'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Delete vehicles failed`,
      error,
    })
  }
})
