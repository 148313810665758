import type { CrudSelectionCategory, MainSelectionCategory } from '@/atoms'

import { useSnapshot } from 'valtio'

import { mapSelectionAtom } from '../mapSelection'

export function useMatchMapSelectionContext(type: 'main', category?: MainSelectionCategory): boolean
export function useMatchMapSelectionContext(type: 'crud', category: CrudSelectionCategory): boolean
export function useMatchMapSelectionContext(
  type: 'crud' | 'main',
  category?: CrudSelectionCategory | MainSelectionCategory,
): boolean {
  const context = useSnapshot(mapSelectionAtom.context)

  if (type !== context.type) return false

  switch (context.type) {
    case 'crud':
      if (!category) return true
      return category === context.category

    case 'main':
      if (!category) return true
      return category === context.category
  }

  return false
}
