import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '@/store'

import { selectOrderMapStyles, setCustomMapStyle } from '@/features/domain/ui'

export const useVisibility = (id: string): [boolean, React.MouseEventHandler<HTMLDivElement>] => {
  const dispatch = useAppDispatch()
  const mapStyles = useSelector(selectOrderMapStyles)

  const globalStyle = mapStyles.mode
  const customStyle = mapStyles.custom[id]
  const mode = customStyle || globalStyle

  return [
    mode !== 'off',
    useCallback(
      e => {
        e.stopPropagation()
        dispatch(
          setCustomMapStyle({
            customStyle: { type: 'order', mode: mode !== 'off' ? 'off' : 'on', ids: [id] },
          }),
        )
      },
      [mode, dispatch, id],
    ),
  ]
}
