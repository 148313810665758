import { useCallback, useEffect, useRef } from 'react'
import { setCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { useNavigate } from '@/routing'

export function useActions(drivers: uui.domain.client.rm.ExtendedDriver[]) {
  const navigate = useNavigate<uui.routing.Setup>(true)
  const { setEditing } = useEditingStateWithCtx('driver')

  const driversRef = useRef(drivers)
  useEffect(() => {
    driversRef.current = drivers
  }, [drivers])

  const edit = useCallback(() => {
    const driverIds = driversRef.current.map(d => d.id)
    setCrudSelection('drivers', driverIds)
    setEditing(driverIds)

    navigate('drivers')
  }, [navigate, setEditing])

  const vehicleAssignment = useCallback(() => {
    navigate('driver-assignments')
  }, [navigate])

  return { edit, vehicleAssignment }
}
