import type {
  NavigoContext,
  NavigoEvents,
  NavigoStates,
  UpdateActionPermissionsEvent,
} from './types'

import { createMachine } from 'xstate'

export const navigoVehiclesMachine = createMachine<NavigoContext, NavigoEvents, NavigoStates>(
  {
    predictableActionArguments: true,
    initial: 'overview',

    context: {
      expanded: true,
      permissions: {
        breadcrumbs: 'hidden',
        report: 'disabledNoDevice',
        events: 'hidden',

        centerOnMap: 'disabled',
        sendMessage: 'disabled',
        style: 'disabled',
        edit: 'disabled',
      },
    },

    states: {
      overview: {
        on: {
          GO_TO_MAP_STYLE: 'mapStyle',
          TOGGLE_EXPANDED: { actions: ['toggleExpanded'] },
        },
      },

      mapStyle: {
        on: { RESET: { target: 'overview', actions: 'expand' } },
      },
    },

    on: {
      UPDATE_ACTION_PERMISSIONS: { actions: 'updateActionPermissions' },
    },
  },
  {
    actions: {
      expand: mutableContext => {
        mutableContext.expanded = true
      },
      toggleExpanded: mutableContext => {
        mutableContext.expanded = !mutableContext.expanded
      },
      updateActionPermissions: (mutableContext, eventData: UpdateActionPermissionsEvent) => {
        mutableContext.permissions = eventData.permissions
      },
    },
  },
)
