import { useRef, useCallback, useMemo } from 'react'
import { FormControlLabel, MenuItem, Radio, Typography } from '@mui/material'
import {
  ListHeaderFilterInput,
  ListHeaderFilterMenu,
  ListHeaderFilterContainer,
  type ListHeaderSecondaryActionRef,
} from '@/components/List'

import { useTexts } from '../../../../useTexts'
import { useFilter } from './hooks/useFilter'

export function Filter() {
  const { query, filter, setFilter, setQuery, editing, noEntries } = useFilter()

  const texts = useTexts()

  const filterMenuRef = useRef<ListHeaderSecondaryActionRef>(null)
  const filterActive = useMemo(() => filter.type !== 'all', [filter])

  const onAllTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'all',
    })
  }, [setFilter])

  const onDisabledTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'disabled',
    })
  }, [setFilter])

  const onBlockingTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'blocked',
    })
  }, [setFilter])

  const onDelayedTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'delayed',
    })
  }, [setFilter])

  const onClearAllClick = useCallback(() => {
    setFilter({ type: 'all' })
    filterMenuRef.current?.close()
  }, [setFilter])

  const disabled = noEntries || editing

  return (
    <ListHeaderFilterContainer>
      <ListHeaderFilterInput
        placeholder={texts.filterPlaceholder}
        disabled={disabled}
        setQuery={setQuery}
        testId="list-header-filter-input"
        query={query}
      />

      <ListHeaderFilterMenu filterActive={filterActive} ref={filterMenuRef} disabled={disabled}>
        <MenuItem
          onClick={onAllTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-any"
          data-trackid="list-header-filter-filterOption-type-any"
        >
          <FormControlLabel
            label={texts.filterTypeAny}
            control={
              <Radio
                size="small"
                value="all"
                checked={filter.type === 'all'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onDisabledTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-disabled"
          data-trackid="list-header-filter-filterOption-type-disabled"
        >
          <FormControlLabel
            label={texts.filterTypeDisabled}
            control={
              <Radio
                size="small"
                value="all"
                checked={filter.type === 'disabled'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onBlockingTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-blocking"
          data-trackid="list-header-filter-filterOption-type-blocking"
        >
          <FormControlLabel
            label={texts.filterTypeBlocking}
            control={
              <Radio
                size="small"
                value="all"
                checked={filter.type === 'blocked'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onDelayedTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-delayed"
          data-trackid="list-header-filter-filterOption-type-delayed"
        >
          <FormControlLabel
            label={texts.filterTypeAdditionalDrivingTime}
            control={
              <Radio
                size="small"
                value="all"
                checked={filter.type === 'delayed'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        {filterActive && (
          <MenuItem
            onClick={onClearAllClick}
            data-testid="list-header-filter-filterOption-clear"
            data-trackid="list-header-filter-filterOption-clear"
          >
            <Typography fontSize={15} color="primary">
              {texts.clearAll}
            </Typography>
          </MenuItem>
        )}
      </ListHeaderFilterMenu>
    </ListHeaderFilterContainer>
  )
}
