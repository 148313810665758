import type { SelectionOptions } from '../../utils/applySelection'
import type { MainSelection } from '../typings'

import { snapshot } from 'valtio'

import { parseModifier, applySelection } from '../../utils/applySelection'
import { mainAtom } from '../main'
import { resetAllMainSelection } from './resetAllMainSelection'

type SetAllMainSelection = (prev: workwave.DeepReadonly<MainSelection>) => Partial<MainSelection>

type SetAllMainSelectionParam = SetAllMainSelection | Partial<MainSelection> | 'reset'

export const setAllMainSelection = (
  valueOrFunc: SetAllMainSelectionParam,
  options?: SelectionOptions,
) => {
  // reset
  if (valueOrFunc === 'reset') return resetAllMainSelection()

  const { force, modifiers } = options ?? {}
  const { noModifiers, additive } = parseModifier(modifiers)

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    const nextSelection = valueOrFunc(snapshot(mainAtom.selection))

    for (const field of Object.keys(mainAtom.selection)) {
      if (force || noModifiers) {
        // overwrite the selection if provided or reset it
        mainAtom.selection[field] = nextSelection[field] ?? []
      } else if (additive) {
        // for additive bulk selection we don't want the toggle behavior offered by `applySelection`
        // do nothing if the new provided selection is empty
        if (nextSelection[field]) {
          mainAtom.selection[field] = Array.from(
            new Set([...mainAtom.selection[field], ...nextSelection[field]]),
          )
        }
      } else {
        // subtract the selection
        const prevSelection: string[] = mainAtom.selection[field]
        mainAtom.selection[field] = applySelection(prevSelection, nextSelection[field], options)
      }
    }
  } else {
    // atomic update
    for (const field of Object.keys(mainAtom.selection)) {
      if (force || noModifiers) {
        // overwrite the selection, Set it empty if no data has been provided
        mainAtom.selection[field] = valueOrFunc[field] ?? []
      } else if (additive) {
        // for additive bulk selection we don't want the toggle behavior offered by `applySelection`
        // do nothing if the new provided selection is empty
        if (valueOrFunc[field]) {
          mainAtom.selection[field] = Array.from(
            new Set([...mainAtom.selection[field], ...valueOrFunc[field]]),
          )
        }
      } else {
        // if the field has not been provided and modifiers are on do nothing on that category
        if (!valueOrFunc[field]) continue

        // subtract the selection
        const prevSelection: string[] = mainAtom.selection[field]
        mainAtom.selection[field] = applySelection(prevSelection, valueOrFunc[field], options)
      }
    }
  }
}
