import styled from 'styled-components'

const OrderAvatarBadgeContainer = styled.span`
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-50%, -50%);
  background: ${p => p.theme.colors.$pureWhite};
  border-radius: 50%;
  border: 2px solid ${p => p.theme.colors.$pureWhite};
`

OrderAvatarBadgeContainer.displayName = 'OrderAvatarBadgeContainer'
export default OrderAvatarBadgeContainer
