import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function WarningShield(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M46.21484 7.84766C38.8125 6.22266 34.4414 3.92186 31.25 2.2422 28.76953.9414 26.98047 0 25 0c-1.98047 0-3.76953.9414-6.25 2.2422-3.1914 1.67968-7.5625 3.98046-14.96484 5.60546L3 8.01953v.8047C3 39.28515 24.36328 49.5039 24.58203 49.60155l.4375.20313.4336-.21876C25.66796 49.47656 47 38.27344 47 8.82422v-.8047zM26.79687 36.9375c0 .21484-.14453.35547-.35546.35547h-2.72653c-.21875 0-.35937-.14453-.35937-.35547v-3.08594c0-.21484.1406-.35937.35936-.35937h2.72657c.21485 0 .35547.1445.35547.35933zm0-7.29688c0 .21485-.14453.35938-.35546.35938h-2.72653c-.21875 0-.35937-.14453-.35937-.35938V12.5586c0-.21485.1406-.35938.35936-.35938h2.72657c.21485 0 .35547.14453.35547.35937z" />
      </svg>
    </IconContainer>
  )
}
