import { useMemo } from 'react'

import { parseRecurrence } from '@/server-data'

export function useFormRecurrence(source: string) {
  return useMemo(() => {
    const recurrence = parseRecurrence(source)
    if (!recurrence) {
      throw new Error(`Can not parse a valid recurrence from ${source}`)
    }
    return recurrence
  }, [source])
}
