import styled from 'styled-components'

type Props = {
  disabled?: boolean
  background?: string
  color?: string
  borderColor?: string
}

export const ReadonlyBadge = styled.span<Props>`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 18px;
  height: 18px;

  line-height: 16px;
  font-size: ${p => p.theme.fonts.$s};
  font-weight: ${p => p.theme.weights.$semiBold};
  color: ${p => p.borderColor ?? p.theme.colors.$nightRider};
  background: ${p => p.background ?? p.theme.colors.$silver};

  border-radius: 50%;
  border: 1px solid ${p => p.borderColor ?? p.theme.colors.$pureWhite};

  opacity: ${p => (p.disabled ? 0.3 : 1)};

  > * {
    width: 12px;
  }
`

ReadonlyBadge.displayName = 'ReadonlyBadge'
