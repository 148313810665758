import styled from 'styled-components'

type Props = {
  external?: boolean
  warn?: boolean
}

export const ItemContainer = styled.button.attrs({ type: 'button' })<Props>`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 32px;
  background: ${p => p.theme.colors.$pureWhite};
  color: ${p => (p.warn ? p.theme.colors.$outrageousRed : p.theme.colors.$nightRider)};
  padding: 0 16px;
  transition: background 0.3s ease;
  border-width: 0;
  outline: 0;
  text-align: left;
  cursor: pointer;
  font-weight: ${p => p.theme.weights.$semiBold};

  &:hover {
    background: ${p => p.theme.colors.$whiteSmoke};
  }

  &:disabled,
  &[disabled] {
    opacity: 0.3;
    color: ${p => p.theme.colors.$nightRider};
    cursor: default;
    pointer-events: none;
  }
`

ItemContainer.displayName = 'ItemContainer'
