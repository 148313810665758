import { type ChangeEvent, useCallback, useEffect, useState } from 'react'

import { IconButton, InputAdornment } from '@mui/material'
import { TextField } from '@workwave-tidal/tidal'
import { Close, Search } from '@mui/icons-material'
import { type SearchBarProps } from '../types'

export function SearchBar(props: SearchBarProps) {
  const { filter, onChangeFilter, localeText } = props
  const [localFilter, setLocalFilter] = useState(() => filter)

  const onChangeLocalFilter = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setLocalFilter(event.target.value)
  }, [])

  const onResetFilter = useCallback(() => {
    setLocalFilter('')
  }, [])

  useEffect(() => {
    onChangeFilter(localFilter)
  }, [localFilter, onChangeFilter])

  return (
    <TextField
      value={localFilter}
      data-testid="columns-picker-search-input"
      onChange={onChangeLocalFilter}
      placeholder={localeText.filterPlaceholder}
      InputProps={{
        startAdornment: <Search sx={{ mr: 1 }} />,
        endAdornment: (
          <InputAdornment position="end">
            {filter && (
              <IconButton onClick={onResetFilter}>
                <Close />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  )
}
