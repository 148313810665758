import { type ReactNode } from 'react'
import { Stack } from '@mui/material'

type Props = {
  children: ReactNode
}
export function ListHeaderTitleContainer(props: Props) {
  const { children } = props

  return (
    <Stack
      data-testid="list-header-title-container"
      alignItems="center"
      direction="row"
      maxWidth="100%"
      overflow="hidden"
      gap={0.5}
    >
      {children}
    </Stack>
  )
}
