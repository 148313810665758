import { intl } from '@/intl'

export function validateDeviceId(
  deviceId: string | null | undefined,
  telematics?: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics['telematics'],
): string | undefined {
  const { gpsTracking, tenantSourceId } = telematics ?? {}

  if (!deviceId && gpsTracking && tenantSourceId) {
    return intl.translate({
      id: 'vehicles.form.edit.generalSettings.validation.deviceId.noDeviceSelected',
    })
  }
}
