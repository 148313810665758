import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { MapControls } from '@/components/MapControls'

import { VehiclesRoot } from './components/form/VehiclesRoot'
import { Map } from './components/Map'

export function Vehicles() {
  return (
    <HorizontalLayout data-testid="setup-vehicles-root">
      <VehiclesRoot />

      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />
          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
