import { useCallback } from 'react'

import { changeTerritory } from '@/features/domain/territory'
import { useAppDispatch } from '@/store'

export function useChangeTerritory() {
  const dispatch = useAppDispatch()

  return useCallback(
    async (newTerritoryId: string) => {
      dispatch(changeTerritory({ target: 'ops', territoryId: newTerritoryId }))
    },
    [dispatch],
  )
}
