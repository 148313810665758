import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function SortDescending(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 20 18">
        <path d="M3 0H5V14H8L5 17L4 18L3 17L0 14H3V0ZM9 0H20V2H9V0ZM18 4H9V6H18V4ZM9 8H16V10H9V8ZM14 12H9V14H14V12ZM12 16H9V18H12V16Z" />
      </svg>
    </IconContainer>
  )
}
