import { Props } from './typings'

import { ButtonPrimitive } from './ButtonPrimitive'

export const Button = (props: Props) => {
  const {
    ref,
    title,
    Icon,
    children = '',
    className = '',
    canFocus = true,
    disableTitle = false,
    ...buttonPrimitiveProps
  } = props
  const { iconPlacement = 'inlineLeft' } = props

  const btnTitle: string = !!title ? title : typeof children === 'string' ? children : ''

  const tabIndex: number = canFocus ? 0 : -1

  const renderAbsolutePlacedIcon = iconPlacement === 'absoluteRight'
  const renderInlineIcon = iconPlacement === 'inlineLeft'

  return (
    <ButtonPrimitive
      tabIndex={tabIndex}
      className={className}
      title={disableTitle ? undefined : btnTitle}
      {...buttonPrimitiveProps}
    >
      {Icon && renderInlineIcon && { Icon }}

      {children}

      {Icon && renderAbsolutePlacedIcon && <div className="o-molecule-button__icon">{Icon}</div>}
    </ButtonPrimitive>
  )
}
