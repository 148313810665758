import { FieldMessage, TextField } from '@/forms-legacy'
import { useTexts } from './useTexts'

export function GeofenceName() {
  const texts = useTexts()

  return (
    <>
      <TextField name="name" label={texts.geofenceNameTitle} half />
      <FieldMessage>{texts.geofenceNameDescription}</FieldMessage>
    </>
  )
}
