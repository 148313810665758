import type { MonthlySelectionAsRecord, MonthlyValidSelection } from '../../typings'

import { useMemo } from 'react'

export const useSelectionData = (selection?: MonthlyValidSelection) => {
  return useMemo<MonthlySelectionAsRecord | undefined>(() => {
    if (selection) {
      const result = {} as MonthlySelectionAsRecord
      result[selection] = 1
      return result
    }
    return undefined
  }, [selection])
}
