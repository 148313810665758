import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headers: {
      name: translate({ id: 'companies.table.headers.name' }),
      status: translate({ id: 'companies.table.headers.status' }),
    },
    status: {
      enabled: translate({ id: 'companies.table.status.enabled' }),
      disabled: translate({ id: 'companies.table.status.disabled' }),
    },
    menu: {
      rename: translate({ id: 'companies.table.dropdownMenu.rename' }),
      delete: translate({ id: 'companies.table.dropdownMenu.delete' }),
      toggleEnabled: (enabled: boolean) =>
        translate({
          id: enabled
            ? 'companies.table.dropdownMenu.disable'
            : 'companies.table.dropdownMenu.enable',
        }),
    },
    toast: {
      enableCompanySuccess: translate({ id: 'companies.table.dropdownMenu.enable.toast.success' }),
    },
    table: {
      localeTexts: {
        noRowsLabel: translate({ id: 'companies.table.noRows' }),
      },
    },
  }))

  return api
}
