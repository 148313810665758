import { useMemo } from 'react'
import { intl } from '@/intl'
import { getRecurrenceLabel } from '@/server-data'

import { ListHeader } from '@/components/List'

import { Actions } from './components/Actions'
import { Sorter } from './components/Sorter'
import { Filter } from './components/Filter'
import { Title } from './components/Title'

interface Props {
  trafficProfile: uui.domain.client.rm.TrafficProfile
  onShowEditTrafficProfileModal: () => void
}

export function Header(props: Props) {
  const { trafficProfile, onShowEditTrafficProfileModal } = props
  const { type, value } = trafficProfile.recurrence
  const { translate } = intl

  const trafficProfileTitle = useMemo(
    () => getRecurrenceLabel(type, value, translate),
    [type, value, translate],
  )

  return (
    <ListHeader
      actions={
        <Actions
          trafficProfileId={trafficProfile.id}
          onShowEditTrafficProfileModal={onShowEditTrafficProfileModal}
        />
      }
      title={<Title title={trafficProfileTitle} />}
      sorter={<Sorter />}
      filter={<Filter />}
    />
  )
}
