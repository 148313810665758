import { Left } from '@/icons'

import { CircleIconButton } from './CircleIconButton'

interface Props {
  onClick: () => void
  testid?: string
}

export function BackButton(props: Props) {
  return (
    <CircleIconButton
      Icon={<Left size={10} color="$pureWhite" />}
      data-trackid={props.testid}
      data-testid={props.testid}
      onClick={props.onClick}
    />
  )
}
