import { Entry } from '../Entry'

export function Entry20210212() {
  return (
    <Entry title="02/12/2021 - v3.0.0-alpha-4">
      <ul>
        <li>The Scheduler is resizable</li>
        <li>Some Scheduler bugs are fixed</li>
        <li>The MovedPairedOrderModal is available (but not connected to the server yet)</li>
        <li>The base UI of the MapControls is available (the buttons do nothing)</li>
        <li>The demo territories can be imported</li>
        <li>The territory can be renamed</li>
        <li>New territories can be imported from JSON files</li>
        <li>The Assigned Orders tab of the Orders list now works as expected</li>
      </ul>
    </Entry>
  )
}
