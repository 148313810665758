import type { ReactElement } from 'react'

import { Box } from '@mui/material'

import { FullScreenContainer } from './FullScreenContainer'
import { MainSectionContainer } from './MainSectionContainer'

interface Props {
  picture: ReactElement
  children: ReactElement
  footer: ReactElement
}

const mainRowSx = { display: 'flex', flex: 1 }
const pictureContainerSx = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
}
const contentContainerSx = { display: 'flex', flex: 1, alignItems: 'center' }

export function MainLayout(props: Props) {
  const { picture, children, footer } = props

  return (
    <FullScreenContainer>
      <MainSectionContainer>
        <>
          <Box sx={mainRowSx}>
            <Box sx={pictureContainerSx}>{picture}</Box>
            <Box sx={contentContainerSx}>
              <Box sx={{ paddingX: 8 }}>{children}</Box>
            </Box>
          </Box>
          {footer}
        </>
      </MainSectionContainer>
    </FullScreenContainer>
  )
}
