import { Fragment } from 'react'

import { WarningTriangle } from '@/icons'
import { useFormatSeconds } from '@/hooks'
import { Checkbox } from '@/components/primitives/Checkbox'
import { WarningCard, Text } from '@/local/components'
import { VerticalLayout } from '@/components/layout/VerticalLayout'
import { HorizontalLayout } from '@/components/layout/HorizontalLayout'

import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'

const Block = Fragment

export function Body() {
  const texts = useTexts()
  const actions = useControllerActions()

  const {
    status,
    data: {
      selectedOrderSteps: { orderSteps, ids: orderStepIds },
      pairedOrderSteps: { orderSteps: pairedOrderSteps, ids: pairedOrderStepIds },
      cloneAlsoPairedStep,
      mode,
    },
  } = useController()

  const serviceTimeSec =
    pairedOrderStepIds.length === 1 ? pairedOrderSteps[pairedOrderStepIds[0]].serviceTimeSec : 0
  const serviceTime = useFormatSeconds(serviceTimeSec)

  const title =
    status !== 'pending'
      ? texts.warningCardTitle(orderStepIds.length)
      : texts.transactionWarningCardTitle

  const description =
    status !== 'pending'
      ? texts.warningCardDescription(orderStepIds.length)
      : texts.transactionWarningCardDescription

  return (
    <VerticalLayout marginTop={32}>
      <Block>
        <WarningCard
          title={title}
          preset="warning"
          description={description}
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
        />
      </Block>

      {!!pairedOrderSteps && pairedOrderStepIds.length > 0 && (
        <Block>
          <HorizontalLayout marginTop={32}>
            <Checkbox
              style={{ marginTop: 4 }}
              checked={cloneAlsoPairedStep}
              onChange={actions.cloneAlsoPairedStepChange}
            />

            <VerticalLayout>
              <Text size="$h2" weight="$semiBold" color="$nightRider" marginLeft={5} ellipsis>
                {mode === 'bulk'
                  ? texts.duplicateAlsoBulk
                  : texts.duplicateAlso(orderSteps[orderStepIds[0]].orderStepType)}
              </Text>

              <Text
                ellipsis
                size="$p3"
                marginTop={2}
                marginLeft={5}
                weight="$regular"
                color="$nightRider"
              >
                {mode === 'bulk'
                  ? texts.subtitleBulk(pairedOrderStepIds.length)
                  : texts.subtitle({
                      name: orderSteps[orderStepIds[0]].order.name,
                      serviceTime: serviceTimeSec !== undefined ? serviceTime : null,
                    })}
              </Text>
            </VerticalLayout>
          </HorizontalLayout>
        </Block>
      )}
    </VerticalLayout>
  )
}
