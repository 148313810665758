import type { RegionReadonlyValue } from '../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRegions } from '@/features/domain/region'
import { selectUserConfiguration } from '@/features/domain/user'

import { intl } from '@/intl'
import { durationUtils } from '@/server-data'

export function useParseRegionData(regionIds: string[]): RegionReadonlyValue[] {
  const regions = useSelector(selectRegions)
  const { currency } = useSelector(selectUserConfiguration)

  return useMemo(() => {
    return regionIds.map((regionId: string) => {
      const region = regions[regionId]

      if (!region) {
        throw new Error(`region id: ${regionId} does not match in ${regions.toString()}`)
      }

      const { name, color } = region
      return {
        id: regionId,
        color,
        name,
        value: computeRegionDescription(region, currency),
      }
    })
  }, [regionIds, regions, currency])
}

function computeRegionDescription(region: uui.domain.client.rm.Region, currency: string) {
  const { enterCost, enterTimeSec } = region

  const descriptionArray: string[] = []
  if (enterCost > 0) {
    descriptionArray.push(`${enterCost} ${currency}`)
  }
  if (enterTimeSec > 0) {
    descriptionArray.push(`${durationUtils.formatSeconds(intl.translate)(enterTimeSec, 'LONG')}`)
  }
  if (enterCost > 0 || enterTimeSec > 0) {
    return `${descriptionArray.join(', ')}`
  }
  return ''
}
