import type Geometry from 'ol/geom/Geometry'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { setDepotFeatureMetadata } from '../depotFeatureMetadata'
import { getDepotFeatureStyle } from './getDepotFeatureStyle'
import { getDepotFeatureLabelStyle } from './getDepotFeatureLabelStyle'

export const createDepotFeature = (
  marker: uui.domain.ui.map.markers.Depot,
  mapStyles: uui.domain.ui.map.markers.MapStyles['depot'],
  selected: boolean = false,
): Feature<Geometry> => {
  const { lonLat, id } = marker

  const feature = new Feature({ geometry: new Point(lonLat), label: new Point(lonLat) })

  // use marker ID (depotId) as unique ID
  feature.setId(id)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setDepotFeatureMetadata(feature, 'type', 'depot')

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['depot']['mode'] = marker.mode ?? mapStyles.mode
  setDepotFeatureMetadata(feature, 'mode', mode)

  // OrdersWithViolationsCount is currently not used but can became useful in the future

  // update hasViolations
  setDepotFeatureMetadata(feature, 'hasViolations', marker.hasViolations)

  // update order counts
  setDepotFeatureMetadata(feature, 'ordersCount', marker.ordersCount)
  setDepotFeatureMetadata(feature, 'ordersWithViolationsCount', marker.ordersWithViolationsCount)

  // ------------------------
  // Feature Style

  feature.setStyle([
    getDepotFeatureStyle(marker, mode, selected),
    getDepotFeatureLabelStyle(marker, mode),
  ])

  return feature
}
