import styled from 'styled-components'

export const ReadonlyTimeWindowGroup = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > * + * {
    margin-left: 4px;
  }
`

ReadonlyTimeWindowGroup.displayName = 'ReadonlyTimeWindowGroup'
