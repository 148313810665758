import { timeWindowUtils } from '@/utils'

export function parseWorkingDayInfo(
  workingDayInfo: uui.domain.ui.forms.ExceptionFormValues['workingDayInfo'],
  workingDayStartSec: number,
) {
  const timeWindow = {
    startSec: workingDayInfo.workingDayStart,
    endSec: workingDayInfo.workingDayEnd,
  } as const

  return timeWindowUtils.formatTimeWindowForRPC(
    timeWindowUtils.normalizeTimeWindow(timeWindow, workingDayStartSec),
    workingDayStartSec,
  )
}
