import { theme } from '@/styles'

const getWidth = (
  label: string,
  pixelPerCharacter: number,
  pad: number,
  maxWidth: number,
): number => {
  const res = Math.ceil(label.length * pixelPerCharacter) + 2 * pad
  return Math.min(res % 2 === 0 ? res : res + 1, maxWidth)
}

export const getSvgText = (
  label: string,
  selected: boolean = false,
  pad: number = 6,
  maxWidth: number = 200,
): string => {
  // arbitrary value based on browser render
  // this has to change if we change font or font weight or font size
  const pixelPerCharacter: number = 6.4

  const w: number = getWidth(label, pixelPerCharacter, pad, maxWidth)
  const h: number = 18

  const maxChars: number = Math.ceil((w - pad * 2) / pixelPerCharacter)

  const renderedLabel = label.length > maxChars ? `${label.substring(0, maxChars - 5)}...` : label

  const bgColor = selected ? theme.colors.$dodgerBlue : theme.colors.$pureWhite

  const color = selected ? theme.colors.$pureWhite : theme.colors.$nightRider

  const svg = `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <rect width="${w}" height="${h}" rx="3" fill="${bgColor}" />
  <text x="${w / 2}" y="${
    h / 2
  }" font-family="Roboto, sans-serif" font-size="12" font-weight="600" fill="${color}" text-anchor="middle" alignment-baseline="central">
    ${renderedLabel}
  </text>
</svg>
  `

  return svg
}
