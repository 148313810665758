import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useReadOnly } from '@/hooks'
import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'
import { selectCompanies } from '@/features/domain/company'

import { useModalsContext } from '../../../context/ModalsContextProvider'

type ModalState = 'ready' | 'pending'
type ModalData = {
  company: uui.domain.client.rm.Company
}

export const modalId = 'deleteCompany'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = () => {
  const close = useCloseModal()
  const { readonly } = useReadOnly()

  const { companyId } = useModalsContext()

  if (!companyId) {
    throw new Error('companyId is required')
  }

  const companies = useSelector(selectCompanies)
  const company = companies[companyId]

  if (!company) {
    throw new Error('company not found')
  }

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      data: {
        company,
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const { status, update } = ctrl

  useEffect(() => {
    switch (readonly) {
      case true:
        update({ status: 'pending' })
        break

      case false:
        if (status !== 'pending') return

        update({ status: 'ready' })
        break
    }
  }, [readonly, status, update])

  return ctrl
}
