import { useController } from '../../hooks/useController'
import { Confirm } from './components/Confirm'
import { Invalid } from './components/Invalid'

export function Body() {
  const { status } = useController()

  switch (status) {
    case 'submitting':
    case 'ready':
      return <Confirm />

    case 'invalid':
      return <Invalid />
  }
}
