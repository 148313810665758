import { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { HorizontalRadioGroupField, NumericStepperField, HorizontalRuler } from '@/forms-legacy'
import { selectUserConfiguration } from '@/features/domain/user'

import { useTexts } from './useTexts'
import { useRadioGroupValues } from './useRadioGroupValues'

interface Props {
  showToleranceField: boolean
}

export function SpeedLimit(props: Props) {
  const { showToleranceField } = props

  const texts = useTexts()
  const userConfig = useSelector(selectUserConfiguration)
  const radioGroupValues = useRadioGroupValues()
  const wrappedInputProps = useMemo(() => {
    return {
      values: radioGroupValues,
    }
  }, [radioGroupValues])

  const metric = userConfig.distanceFormat === 'METRIC'

  return (
    <>
      <HorizontalRadioGroupField
        name="device.speedLimit.enabled"
        testid="device.speedLimit.enabled"
        label={texts.label}
        wrappedInputProps={wrappedInputProps}
      />
      {showToleranceField && (
        <>
          <NumericStepperField
            label={texts.unitOfMeasure(texts.tolerance, metric)}
            name="device.speedLimit.threshold"
            testid="device.speedLimit.threshold"
            wrappedInputProps={{
              step: 1,
            }}
          />
          <HorizontalRuler />
        </>
      )}
    </>
  )
}
