import type { BulkFieldValue } from '../../../types'

export function computeLocation(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
  depots: Record<string, uui.domain.client.rm.Depot>,
): BulkFieldValue<uui.domain.client.Location> {
  const location = getLocation(orders[0], depots)
  const exact = orders.every(o => isEqual(getLocation(o, depots), location))

  if (exact) {
    return {
      status: 'exact',
      value: location,
    }
  }

  return {
    status: 'mixed',
  }
}

function getLocation(
  order: uui.domain.client.rm.ExtendedOrderStep,
  depots: Record<string, uui.domain.client.rm.Depot>,
): uui.domain.client.Location {
  if (order.orderStep.atDepot) {
    return depots[order.orderStep.depotId].location
  }

  return order.orderStep.location
}

function isEqual(a: uui.domain.client.Location, b: uui.domain.client.Location) {
  return a.latLng.lat === b.latLng.lat && a.latLng.lng === b.latLng.lng
}
