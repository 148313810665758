import type { ChangeEvent } from 'react'

import { useCallback, useEffect, useRef } from 'react'

import { cloneOrderSteps } from '@/features/domain/order'
import { setMainSelection } from '@/atoms'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useController } from './useController'

export function useControllerActions() {
  const toast = useNotification()
  const unmounted = useRef(false)
  const dispatch = useAppDispatch()

  const {
    close,
    updateData,
    data: { selectedOrderSteps, cloneAlsoPairedStep },
  } = useController()

  const cloneAlsoPairedStepChange = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) =>
      updateData({ cloneAlsoPairedStep: checked }),
    [updateData],
  )

  const duplicateOrder = useCallback(async () => {
    try {
      const thunkResult = await dispatch(
        cloneOrderSteps({ orderStepIds: selectedOrderSteps.ids, cloneAlsoPairedStep }),
      )

      if (!cloneOrderSteps.fulfilled.match(thunkResult)) {
        throw new Error(thunkResult.payload?.message ?? 'Internal error')
      }
      if (!unmounted.current) {
        close?.()
      }

      setMainSelection('orderSteps', thunkResult.payload)
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, close, dispatch, selectedOrderSteps, cloneAlsoPairedStep])

  useEffect(() => {
    return () => {
      unmounted.current = true
    }
  }, [])

  return {
    duplicateOrder,
    cloneAlsoPairedStepChange,
  }
}
