import { useState } from 'react'

export function ErrorTester() {
  const [throwError, setThrowError] = useState(false)

  const setError = () => {
    setThrowError(true)
  }

  return (
    <div>
      <button onClick={setError}>Boom</button>
      {throwError && <Boom />}
    </div>
  )
}

function Boom() {
  throw new Error('Error tester')
  return null
}
