import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTrafficProfilesListOptions, setListOptions } from '@/features/domain/lists'
import { useEditingStateWithCtx } from '@/atoms'
import { useAppDispatch } from '@/store'

export function useSorter() {
  const listOptions = useSelector(selectTrafficProfilesListOptions)
  const { editing } = useEditingStateWithCtx('trafficProfile')
  const dispatch = useAppDispatch()

  const [direction, setDirection] = useState(() => listOptions.sort[0].direction)

  useEffect(() => {
    if (!listOptions.sort) return
    if (listOptions.sort[0].direction === direction) return

    dispatch(
      setListOptions({
        category: 'trafficProfiles',
        options: {
          ...listOptions,
          sort: [{ ...listOptions.sort[0], direction }],
        },
      }),
    )
  }, [dispatch, direction, listOptions])

  return { editing, direction, setDirection }
}
