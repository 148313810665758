import { TidalForm } from '@workwave-tidal/tidal/form-ui/layout'

import { useConfigureController } from '../hooks/useController'

import { type FormFields, type FormErrors, formFields } from '../formFields'
import { useFormConfig } from '../hooks/useFormConfig'

import { Form } from './Form'

type Props = {
  source: uui.domain.server.gps.telematics.Source
}

export function Content(props: Props) {
  const { source } = props

  const formConfig = useFormConfig(source)
  const { ready } = useConfigureController(source)

  if (!ready) return null

  return (
    <TidalForm<FormFields, FormErrors>
      fieldsSpacing={2}
      disableScroll
      config={formConfig}
      fields={formFields}
      name="create-telematics-tenant-source-form"
    >
      <Form />
    </TidalForm>
  )
}
