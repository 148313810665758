import { Style } from 'ol/style'
import { FeatureLike } from 'ol/Feature'

import { drawFeatureMetadata } from '../utils/drawFeatureMetadata'
import { validPoint } from './utils/validPoint'

// used for the point rendered by the MODIFY interaction
export const createModifyPointStyle = () => {
  const validStyle = [validPoint]

  const invalidStyle = []

  /**
   * Open Layers Style function used to apply the correct visual stylers to the layer
   */
  return (feature: FeatureLike, _resolution: number): Style[] => {
    // try to access the `invalid` custom field to determine which style must be used
    const invalid = drawFeatureMetadata.isInvalid(feature)

    return invalid ? invalidStyle : validStyle
  }
}
