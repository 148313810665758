import { type ReactNode } from 'react'
import { Box, Stack, Typography } from '@mui/material'

import { CenteredLayout } from './CenteredLayout'
import { IllustrationContainer } from './illustrations'

export interface Props {
  title: string
  description: string
  action?: ReactNode
  testId: string
  illustration: ReactNode
}

const maxIllustrationWidth = 224

export function ListPlaceholder(props: Props) {
  const { title, description, action, testId, illustration } = props

  return (
    <CenteredLayout testId={testId} width="100%">
      <IllustrationContainer maxHeight={maxIllustrationWidth}>{illustration}</IllustrationContainer>

      <Stack gap={1} flexShrink={0} alignItems="center" paddingX={1}>
        <Typography variant="h6" textAlign="center">
          {title}
        </Typography>
        <Typography variant="body2" textAlign="center">
          {description}
        </Typography>
      </Stack>

      <Box flexShrink={0}>{Boolean(action) && action}</Box>
    </CenteredLayout>
  )
}
