import { Entry } from '../Entry'

export function Entry20210205() {
  return (
    <Entry title="02/05/2021 - v3.0.0-alpha-3">
      <ul>
        <li>The Setup area has its own sidebar</li>
        <li>The Simulations footer shows Simulation name</li>
        <li>FitInOrder modal notifies if an external transaction exists</li>
        <li>The initial order of the Scheduler columns is now correct</li>
        <li>The Driver email validation works</li>
        <li>ImportDemoTerritory modal does not scroll anymore</li>
      </ul>
    </Entry>
  )
}
