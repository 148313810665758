import type { ReactElement, ReactNode } from 'react'

import type {
  ScrollableListItemChangeEventHandler,
  ScrollableListItemHoverEventHandler,
} from '../List'
import type { State } from './useData'
import type { DropdownItem } from './typings'

import { useCallback } from 'react'

import PrevButton from './components/PrevButton'
import NextButton from './components/NextButton'
import Footer from './components/Footer'
import Rows from './components/Rows'

export function useRenderers() {
  const renderItems = useCallback(
    (height?: number, RowIcon?: ReactElement, rowClassName?: string) => {
      return (
        values: DropdownItem[],
        selectedIds: string[],
        highlightedIndex: number,
        onChange: ScrollableListItemChangeEventHandler<DropdownItem>,
        onHover: ScrollableListItemHoverEventHandler<DropdownItem>,
      ): ReactNode => {
        return (
          <Rows
            height={height}
            RowIcon={RowIcon}
            onChange={onChange}
            onHover={onHover}
            highlightedIndex={highlightedIndex}
            selectedIds={selectedIds}
            values={values}
            rowClassName={rowClassName}
          />
        )
      }
    },
    [],
  )

  const renderPrevArrow = useCallback((renderArrow: boolean = true, onClickPrev: () => void) => {
    return (values: DropdownItem[], selectedIds: string[], disabled: boolean): ReactNode => {
      if (renderArrow) {
        return (
          <PrevButton
            values={values}
            selectedIds={selectedIds}
            disabled={disabled}
            onClick={onClickPrev}
          />
        )
      }
      return null
    }
  }, [])

  const renderNextArrow = useCallback((renderArrow: boolean = true, onClickNext: () => void) => {
    return (values: DropdownItem[], selectedIds: string[], disabled: boolean): ReactNode => {
      if (renderArrow) {
        return (
          <NextButton
            values={values}
            selectedIds={selectedIds}
            disabled={disabled}
            onClick={onClickNext}
          />
        )
      }
      return null
    }
  }, [])

  const renderFooter = useCallback((state: State, secondaryText: string) => {
    return () => {
      return (
        <Footer
          count={state.filteredValues.length}
          isSearching={state.searchText.length > 0}
          status={state.status}
          secondaryText={secondaryText}
        />
      )
    }
  }, [])

  return {
    renderItems,
    renderPrevArrow,
    renderNextArrow,
    renderFooter,
  }
}
