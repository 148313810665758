import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState({
    languageAndFormats: translate({ id: 'settings.sidebar.languageAndFormats' }),
    communicationPlan: translate({ id: 'settings.sidebar.communicationPlan' }),
    personalization: translate({ id: 'settings.sidebar.personalization' }),
    disabledTooltip: translate({ id: 'global.disabledForBeta' }),
    userManagement: translate({ id: 'settings.sidebar.userManagement' }),
    settingsTitle: translate({ id: 'settings.sidebar.title' }),
    integrations: translate({ id: 'settings.sidebar.integrations' }),
    companies: translate({ id: 'settings.sidebar.companies' }),
    account: translate({ id: 'settings.sidebar.account' }),
    formats: translate({ id: 'settings.sidebar.formats' }),

    communicationPlanTooltip: (isRmOnly: boolean) =>
      translate({
        id: isRmOnly
          ? 'settings.sidebar.communicationPlan.tooltip'
          : 'settings.sidebar.communicationPlan.noPlanDetails',
      }),
  })

  return api
}
