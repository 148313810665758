/**
 * Retrieve a Region Marker by its ID or throws an Error if it cannot be found
 */
export function findRegionMarker(
  markers: Record<string, uui.domain.ui.map.markers.Area>,
  regionId: string,
) {
  const marker = markers[regionId]

  if (!marker) {
    throw new Error(`Unknown Region Map Marker with ID: ${regionId}`)
  }

  return marker
}
