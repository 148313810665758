import { type MouseEventHandler, useCallback } from 'react'
import { Box, ButtonBase, CircularProgress, styled } from '@mui/material'
import { Error } from '@mui/icons-material'

import { Chip } from '@workwave-tidal/tidal'

import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../../../../../hooks/useTexts'

type Props = {
  integration: uui.domain.server.gps.telematics.TenantSource
  changeCredentials: (tenantSource: uui.domain.server.gps.telematics.TenantSource) => void
}

const StatusContainer = styled(Box, { name: 'StatusContainer' })(() => ({
  position: 'absolute',
  right: 16,
  top: 0,
}))

export function StatusChip(props: Props) {
  const { integration, changeCredentials } = props

  const texts = useTexts()

  const onReconnectClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    e => {
      e.preventDefault()
      e.stopPropagation()

      changeCredentials(integration)
      return false
    },
    [changeCredentials, integration],
  )

  const { active, statusCode, statusDescription } = integration

  // If the integration is active and the status code is 2000, we don't need to show anything
  if (active && statusCode === 2000) return null

  switch (statusCode) {
    // Delete in progress (Chip + Loader)
    case 2000: {
      const rightAdornment = active ? undefined : (
        <CircularProgress
          size={16}
          sx={{ marginLeft: '-1px', marginTop: 0.5, color: '#00000061' }}
        />
      )
      const label = active ? undefined : texts.deleting

      return (
        <StatusContainer>
          <Chip
            variant="outlined"
            size="medium"
            rightAdornment={rightAdornment}
            disabled
            label={label}
          />
        </StatusContainer>
      )
    }

    // Credentials issues (Reconnect button)
    case 4000:
    case 4100:
    case 4500: {
      return (
        <StatusContainer>
          <Tooltip placement="bottom" title={texts.tenantSourceErrorMessages.tooltip[statusCode]}>
            <ButtonBase href="#" onClick={onReconnectClick}>
              <Chip
                variant="outlined"
                label={texts.reconnect}
                color="error"
                size="medium"
                sx={{ cursor: 'pointer' }}
              />
            </ButtonBase>
          </Tooltip>
        </StatusContainer>
      )
    }
    // Other errors (Error icon + Tooltip with error message)
    default: {
      return (
        <StatusContainer>
          <Tooltip
            placement="bottom"
            title={texts.tenantSourceErrorMessages.tooltip[statusCode] ?? statusDescription}
          >
            <Error color="error" fontSize="large" />
          </Tooltip>
        </StatusContainer>
      )
    }
  }
}
