import { useEffect, useRef, useState } from 'react'

import { gpsReverseGeocode } from '@/features/domain/ui'
import { useAppDispatch } from '@/store'
import { useGetMapInfo } from '@/map'
import * as gis from '@/local/server-data/utils/gis'

import { useTexts } from './useTexts'

/**
 * Custom hook that returns the location for a given event
 *
 * @param {uui.domain.client.gps.wwgps.EventInfo} event - The event for which to fetch the location
 *
 * @return The location for the event or null if the location is not yet known
 */
export function useDescription(event: uui.domain.client.gps.wwgps.EventInfo) {
  // State to store the location
  const [location, setLocation] = useState<null | string>(null)

  // Get the current map bounds
  const { bounds } = useGetMapInfo()

  // Get the dispatch function from the store
  const dispatch = useAppDispatch()

  // Get the texts
  const texts = useTexts()

  // UseEffect to reset the location when the event changes
  useEffect(() => {
    setLocation(null)
  }, [event])

  // UseRef to store the bounds
  const boundsRef = useRef<uui.domain.LatLngBounds>(bounds)
  // UseEffect to update the boundsRef when bounds change
  useEffect(() => {
    boundsRef.current = bounds
  }, [bounds])

  // UseEffect to fetch the location from the server
  useEffect(() => {
    // Async function to reverse geocode the location
    const reverseGeocodeLocation = async (event: uui.domain.client.gps.wwgps.EventInfo) => {
      // Check if the event's latLng is on Null Island
      if (gis.isNullIslandLatLng(event.latLng)) {
        setLocation(texts.noLocation)
        return
      }

      // Dispatch the reverse geocode action
      const response = await dispatch(
        gpsReverseGeocode({ latLng: event.latLng, bounds: boundsRef.current }),
      )

      // Check if the request failed
      if (gpsReverseGeocode.rejected.match(response)) {
        throw new Error('Request failed')
      }

      // Update the location state
      setLocation(response.payload.geoAddress)
    }

    // Call the async function
    reverseGeocodeLocation(event)
  }, [dispatch, event, texts])

  // Return the location
  return location
}
