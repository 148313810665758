import { Button } from '@mui/material'

import { Grow, FormColumn, ComponentSize, ListOverlayEditCover } from '@/components/layout'
import { ListFooter, LayoutComposer, NoEntries, NoResults } from '@/components/List'
import { useAutoScroll } from '@/atoms'
import { StructuredVirtualList } from '@/components/StructuredVirtualList'

import { Header } from './components/Header'
import { useDataForList } from './hooks/useDataForList'
import { useListSelection } from './hooks/useListSelection'
import { useFetchSimulations } from './hooks/useFetchSimulations'
import { useTexts } from './hooks/useTexts'
import { useSimulationListItem } from './Item'

type Props = {
  simulations: Record<string, uui.domain.client.rm.SimulationInfo>
  selection: workwave.DeepReadonly<string[]>
  editing: boolean
}

const getItemHeight = () => 81

export function List(props: Props) {
  const { simulations, selection, editing } = props
  const { planId, structure, onDoubleClick, onSetEditing } = useDataForList(selection)

  const { allSelected, onSelect, onSelectAll, lastSelectedSimulation } = useListSelection()

  useAutoScroll('simulations', lastSelectedSimulation)

  // TODO: show a spinner or an error based on the fetching status
  useFetchSimulations()

  const Item = useSimulationListItem(planId)
  const texts = useTexts()

  const multiSelect = selection.length > 1

  return (
    <FormColumn width={320} testid="form__column-primary">
      <Header />

      <Grow yAxis xAxis position="relative">
        <LayoutComposer
          totalItems={structure.totalItems}
          shownItems={structure.shownItems}
          noResults={
            <NoResults title={texts.noResults.title} description={texts.noResults.description} />
          }
          noEntries={
            <NoEntries
              title={texts.noEntries.title}
              description={texts.noEntries.description}
              action={
                <Button
                  size="large"
                  variant="outlined"
                  onClick={onSetEditing}
                  disabled={editing}
                  data-testid="no-entries-add-item"
                  data-trackid="no-entries-add-item"
                >
                  {texts.noEntries.action}
                </Button>
              }
            />
          }
          list={
            <ComponentSize
              render={size => {
                // TODO: reset the scroll when the list options change
                return (
                  <>
                    <StructuredVirtualList
                      itemsById={simulations}
                      structure={structure}
                      listApiCategory="simulations"
                      getItemHeights={getItemHeight}
                      RenderItem={Item}
                      listHeight={size.height}
                      selectedItemIds={selection}
                      onSelect={onSelect}
                      onDoubleClick={onDoubleClick}
                      buffer={50}
                    />
                    {editing && <ListOverlayEditCover />}
                  </>
                )
              }}
            />
          }
        />
      </Grow>

      {multiSelect && (
        <ListFooter
          label={texts.selectBtn(allSelected)}
          onToggleSelection={onSelectAll}
          checked={allSelected}
        />
      )}
    </FormColumn>
  )
}
