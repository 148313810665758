import { useCallback } from 'react'
import { useDeleteUserConfirmationModal } from '../../modals/DeleteUserConfirmationModal'

export function useOnDeleteUser() {
  const { show: showDeleteUserConfirmationModal } = useDeleteUserConfirmationModal()

  return useCallback(() => {
    showDeleteUserConfirmationModal()
  }, [showDeleteUserConfirmationModal])
}
