import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserAccount } from '@/features/domain/user'
import { TagsListField } from '@/forms-legacy'

import { useTexts } from '../../hooks/useTexts'
import renderText from './tagsRenderTexts'

interface Props {
  tagsSuggestions: string[]
}

export function Tags(props: Props) {
  const { tagsSuggestions } = props
  const { maxTags } = useSelector(selectUserAccount).licensingLimits
  const texts = useTexts()

  const wrappedInputProps = useMemo(() => {
    return {
      enterToAdd: true,
      renderText,
      suggestions: tagsSuggestions,
    }
  }, [tagsSuggestions])

  return (
    <TagsListField
      name="tags"
      testid="tags"
      label={texts.tags}
      maxCount={maxTags}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
