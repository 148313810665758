import { snapshot } from 'valtio'

import { gis } from '@/server-data'
import { executionEventMarkers } from '../executionEventMarkers'

export function togglePoDMarker(uid: string, latLng: uui.domain.LatLng) {
  const lonLat = gis.fromLatLngToCoordinate(latLng)

  if (!executionEventMarkers.pod) {
    // ------------
    // Add
    executionEventMarkers.pod = { type: 'pod', uid, lonLat, latLng }
  } else if (executionEventMarkers.pod.uid === uid) {
    // ------------
    // remove
    executionEventMarkers.pod = null
  } else {
    // ------------
    // update
    Object.assign(executionEventMarkers.pod, { uid, lonLat, latLng })
  }

  return executionEventMarkers.pod ? snapshot(executionEventMarkers.pod) : undefined
}
