import { DropDownOption } from '../../../../typings'

import { itemHeight } from './constants'

export function getMarginTop(item: DropDownOption, index: number): number {
  if (item.kind === 'header') {
    return index === 0 ? 0 : 10
  }

  return index > 0 ? 3 : 0
}

export function getItemHeight(item: DropDownOption, index: number): number {
  return itemHeight + getMarginTop(item, index)
}
