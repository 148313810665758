import { ActionBarItem } from '@workwave-tidal/components/ActionBar'
import { Close } from '@workwave-tidal/icons'
import { useFormatSeconds } from '@/hooks'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const { data, close } = useController()
  const { selectedOrderSteps } = data

  const serviceTime = useFormatSeconds(
    selectedOrderSteps.length > 1 ? 0 : selectedOrderSteps[0].orderStep.serviceTimeSec,
  )
  const title =
    selectedOrderSteps.length > 1
      ? texts.titleBulk
      : texts.title(selectedOrderSteps[0].orderStepType)

  const subtitle =
    selectedOrderSteps.length > 1
      ? texts.subtitleBulk(selectedOrderSteps.length)
      : texts.subtitle({ name: selectedOrderSteps[0].order.name, serviceTime })

  return (
    <ModalHeader
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      title={title}
      subtitle={subtitle}
    />
  )
}
