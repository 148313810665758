import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    disabledForLoadingTooltip: translate({ id: 'global.gpsLoading.disableEdit' }),

    searchPlaceholder: translate({ id: 'gps.geofences.list.filterPlaceholder' }),

    yourSegments: translate({ id: 'gps.geofences.list.yourGeofences' }),

    addGeofence: translate({ id: 'gps.geofences.createNewGeofence' }),

    entitiesCount: (count: number, selected: number): string =>
      translate({
        id:
          selected > 0
            ? 'gps.geofences.list.footerCountWithSelection'
            : 'gps.geofences.list.footerCount',
        values: { count, selected },
      }),

    selectBtn: (allSelected: boolean): string =>
      translate({ id: allSelected ? 'global.deselectAll' : 'global.selectAll' }),

    disabledTooltipTitle: translate({ id: 'global.disabledTooltip.transactionInProgress.title' }),

    disabledTooltipSubtitle: translate({
      id: 'global.disabledTooltip.transactionInProgress.subtitle',
    }),
    noEntries: {
      title: translate({ id: 'geofences.list.noEntries.title' }),
      description: translate({ id: 'geofences.list.noEntries.description' }),
      action: translate({ id: 'geofences.list.noEntries.action' }),
    },
    noResults: {
      title: translate({ id: 'geofences.list.noResults.title' }),
      description: translate({ id: 'geofences.list.noResults.description' }),
    },
  }))

  return api
}
