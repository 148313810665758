import type { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from 'react'

import styled from 'styled-components'

type Props = {
  type?: string
  name?: string
  value?: string | number | string[] | undefined
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>
  placeholder?: string
  readOnly?: boolean
  disabled?: boolean
}

const BaseInput = styled.input<Props>`
  width: 100%;
  height: 100%;
  outline: 0;
  border-width: 0;
  background: transparent;
  padding: 0;
  color: inherit;
  font-size: ${p => p.theme.fonts.$p2};
  font-weight: ${p => p.theme.weights.$semiBold};

  &::placeholder {
    color: ${p => p.theme.colors.$paleSky};
  }
`

BaseInput.displayName = 'BaseInput'

export default BaseInput
