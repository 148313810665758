import { Text } from '@/local/components'

import { useTexts } from './useTexts'

interface Props {
  kind: uui.domain.client.rm.OrderStepNotification['kind']
}

export function Kind(props: Props) {
  const { kind } = props
  const texts = useTexts()

  return (
    <Text size="$l" weight="$semiBold">
      {kind === 'EMAIL' ? texts.email : texts.sms}
    </Text>
  )
}
