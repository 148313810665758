import { useController } from '../../hooks/useController'
import { ConfigureColumnsFooter } from './components/ConfigureColumnsFooter'
import { ConfigureFormatFooter } from './components/ConfigureFormatFooter'

export function Footer() {
  const {
    data: { wizardStep },
  } = useController()

  switch (wizardStep) {
    case 'configure-columns':
      return <ConfigureColumnsFooter />

    case 'configure-format':
      return <ConfigureFormatFooter />

    default:
      throw new Error(`unhandled wizard step: ${wizardStep}`)
  }
}
