import { useMemo } from 'react'

import { NumericStepperField } from '@/forms-legacy'

import classes from './styles.module.css'
import { useStyles } from './useStyles'

interface Props {
  disabled: boolean
}

export function AdditionalDrivingTimeSec(props: Props) {
  const { disabled } = props
  const styles = useStyles(disabled)

  const wrappedInputProps = useMemo(
    () => ({
      step: 1,
      canBeNaN: false,
      showResetIcon: true,
      defaultValue: 1,
    }),
    [],
  )

  return (
    <div className={classes.root} style={styles.root}>
      <NumericStepperField
        name="additionalDrivingTimeSec"
        label=""
        parse={minToSec}
        format={secToMin}
        wrappedInputProps={wrappedInputProps}
        half
      />
    </div>
  )
}

function secToMin(seconds: number): number {
  return Math.round(seconds / 60)
}

function minToSec(minutes: number): number {
  return minutes * 60
}
