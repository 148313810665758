import { useSelector } from 'react-redux'
import { selectDepotMapStyles, selectOrderMapStyles } from '@/features/domain/ui'

/**
 * Generate the querystring to keep the current map styles in the external apps too.
 */
export function useMapStylesQueryString() {
  const depotMapStyles = useSelector(selectDepotMapStyles)
  const orderMapStyles = useSelector(selectOrderMapStyles)

  // Depots cannot be hidden because nor the Import Wizard neither the GPS Reports allow re-showing them
  const depotsQuery = depotMapStyles.mode === 'label' ? 'depot:1' : 'depot:2'

  // Orders cannot be hidden because nor the Import Wizard neither the GPS Reports allow re-showing them
  const ordersQuery = orderMapStyles.mode === 'maximized' ? 'order:1' : 'order:2'

  return `styles=${ordersQuery},${depotsQuery}`
}
