import styled from 'styled-components'

import { SelectIndicator } from './SelectIndicator'

export const ExceptionRow = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 20px;
  border-width: 0;
  height: 55px;

  cursor: pointer;

  background: ${p => p.theme.colors.$pureWhite};

  transition: background 0.4s ease;

  & ${SelectIndicator} {
    opacity: 0;
  }

  &:hover {
    background: ${p => p.theme.colors.$whiteSmoke};
  }

  &:hover ${SelectIndicator} {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    height: 1px;
    width: 100%;
    background: ${p => p.theme.colors.$whiteSmoke};
  }
`
ExceptionRow.displayName = 'ExceptionRow'
