import { Stack } from '@mui/material'

import { useModalsContext } from './context/ModalsContextProvider'
import { Header } from './components/Header'
import { NoEntries } from './components/NoEntries'
import { CompaniesTable } from './components/CompaniesTable'

import { useCompanies } from './hooks/useCompanies'

export function Companies() {
  const { noEntries, companies, companiesFilter, onChangeCompaniesFilter } = useCompanies()
  const { onShowAddCompanyModal } = useModalsContext()

  return (
    <>
      <Stack gap={4} height="100%" padding={4} margin="0 auto">
        <Header onShowModal={onShowAddCompanyModal} />
        {noEntries ? (
          <NoEntries onShowModal={onShowAddCompanyModal} />
        ) : (
          <CompaniesTable
            companies={companies}
            companiesFilter={companiesFilter}
            onChangeCompaniesFilter={onChangeCompaniesFilter}
          />
        )}
      </Stack>
    </>
  )
}
