import type { TerritoryReference } from '../typings'
import type { DropDownOption } from '@/components/Dropdown'

import { useMemo } from 'react'

import { useTexts } from '../useTexts'

export function useTerritoryDropdownItems(
  territories?: TerritoryReference[],
  territoryId?: string,
) {
  const texts = useTexts()

  return useMemo<DropDownOption[]>(
    () => [
      // Head option
      {
        id: '',
        label: texts.territoryDropdownNullState,
        kind: 'item',
      },
      // Territories
      ...(territories?.map(
        (territory): DropDownOption => ({
          id: territory.id,
          label: territory.name,
          kind: 'item',
          selected: territoryId === territory.id,
        }),
      ) ?? []),
    ],
    [territories, territoryId, texts],
  )
}
