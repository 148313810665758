import type { ReactNode } from 'react'

export type AssetListType = 'depot' | 'address' | 'place' | 'gpsPlace'

export type LocationEditorMode = 'copyCoordinates' | 'renameLocation' | 'accuracy'

export type LocationEditStatus = 'ok' | 'invalidLatLng' | 'outOfTerritory' | 'lowAccuracy'

export interface RenderSideComponentProps {
  collapsed: boolean
  toggleState: () => void
}

export type RenderSideComponent = (props: RenderSideComponentProps) => ReactNode

export interface PublicProps {
  location: uui.domain.client.Location
  pinType: uui.domain.ui.map.LocationPinType
  assetList: uui.domain.client.gps.SearchOnMapItem[]
  loading?: boolean
  modes?: LocationEditorMode[]
  isConnectedToPlace?: boolean

  // layout
  height?: number

  // callbacks
  onTextChange: (text: string, location: uui.domain.client.Location) => void
  onChange: (
    asset: uui.domain.client.gps.SearchOnMapItem,
    location: uui.domain.client.Location,
  ) => void
  onConfirmLowAccuracy?: (location: uui.domain.client.Location) => void
  onFixInvalidAddress?: (location: uui.domain.client.Location) => void

  onCopyCoordinates?: (location: uui.domain.client.Location) => void
  onRenameLocation?: (location: uui.domain.client.Location) => void

  onOpen?: () => boolean | void
  onClose?: () => boolean | void

  status?: LocationEditStatus

  queryMinLength?: number

  startCollapsed?: boolean
  onInputBlur?: () => void

  onKeyDown?: (event: KeyboardEvent) => boolean | void

  renderSideOnCollapsed?: RenderSideComponent

  testId?: string
}

export enum LocationEditorText {
  copyCoordinates = 'lib.locationEditor.copyCoordinates',
  renameLocation = 'lib.locationEditor.renameLocation',
  renameLocationDisabledTitleText = 'lib.locationEditor.renameLocation.DisabledTitleText',
  yourAssets = 'lib.locationEditor.yourAssets',
  addresses = 'lib.locationEditor.addresses',
  infoCardOutOfTerritoryTitle = 'lib.locationEditor.infoCardOutOfTerritoryTitle',
  infoCardOutOfTerritoryText = 'lib.locationEditor.infoCardOutOfTerritoryText',
  infoCardOutOfTerritorySubText = 'lib.locationEditor.infoCardOutOfTerritorySubText',
  infoCardLowAccuracyTitle = 'lib.locationEditor.infoCardLowAccuracyTitle',
  infoCardLowAccuracyText = 'lib.locationEditor.infoCardLowAccuracyText',
  infoCardLowAccuracyTitleLowAccuracy = 'lib.locationEditor.infoCardLowAccuracyTitle.lowAccuracy',
  infoCardLowAccuracyTitlePostcodeIssue = 'lib.locationEditor.infoCardLowAccuracyTitle.postcodeIssue',
  infoCardLowAccuracyTitlePostcodeAccuracy = 'lib.locationEditor.infoCardLowAccuracyTitle.postcodeAccuracy',
  infoCardLowAccuracyTextLowAccuracy = 'lib.locationEditor.infoCardLowAccuracyText.lowAccuracy',
  infoCardLowAccuracyTextPostcodeIssue = 'lib.locationEditor.infoCardLowAccuracyText.postcodeIssue',
  infoCardLowAccuracyTextPostcodeAccuracy = 'lib.locationEditor.infoCardLowAccuracyText.postcodeAccuracy',

  infoCardLowAccuracyAction = 'lib.locationEditor.infoCardLowAccuracyAction',
  infoCardInvalidLatLngTitle = 'lib.locationEditor.infoCardInvalidLatLngTitle',
  infoCardInvalidLatLngText = 'lib.locationEditor.infoCardInvalidLatLngText',
  infoCardInvalidLatLngAction = 'lib.locationEditor.infoCardInvalidLatLngAction',
  notFoundTitle = 'lib.locationEditor.notFoundTitle',
  notFoundText = 'lib.locationEditor.notFoundText',
  addSearchTitle = 'lib.locationEditor.addSearchTitle',
  addSearchText = 'lib.locationEditor.addSearchText',
  copied = 'lib.locationEditor.copied',
  addDetailsToSearch = 'lib.locationEditor.addDetailsToSearch',
  searchingResult = 'lib.locationEditor.searchingResult',
}

export interface Props extends PublicProps {
  collapsed?: boolean
  openList: () => void
  closeList: () => void
  assetList: uui.domain.client.gps.SearchOnMapItem[]
  queryMinLength: number
}

export interface AssetListItemProps {
  asset: uui.domain.client.gps.SearchOnMapItem
  onClick: () => void
  id: string
  selected?: boolean
}
