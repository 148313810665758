import type {
  FormField,
  FormError,
  FormValues as FormValuesType,
} from '@workwave-tidal/tidal/form-fairy'

const requiredStringField: FormField<string> = {
  value: '',
  required: true,
}

const dateFormatField: FormField<uui.domain.client.rm.NotificationConfig['dateFormat']> = {
  value: 'MMMD',
}

const timeFormatField: FormField<uui.domain.client.rm.NotificationConfig['timeFormat']> = {
  value: 'H12',
}

export const formFields = {
  companyFromName: requiredStringField,
  dateFormat: dateFormatField,
  timeFormat: timeFormatField,
  notificationLanguage: requiredStringField,
}

export type FormFields = typeof formFields
export type FormValues = FormValuesType<FormFields>
export type FormErrors = FormError<keyof FormFields>
