import { Stack, Typography } from '@mui/material'
import { ChangeEvent } from 'react'

import { FileInputButton } from './FileInputButton'

interface Props {
  json: any
  setJson: (json: any) => void
  jsonValid: boolean
  setJsonValid: (jsonValid: boolean) => void
}

export function UploadJson(props: Props) {
  const { json, setJson, setJsonValid, jsonValid } = props

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = e => {
        try {
          setJsonValid(false)
          const result = e.target?.result
          if (!result) return

          const json = JSON.parse(result as unknown as string)

          const hasBreadcrumbs = json?.breadcrumbs && Array.isArray(json?.breadcrumbs)
          const hasStops = json?.stops && Array.isArray(json?.stops)

          if (hasBreadcrumbs || hasStops) {
            setJsonValid(true)
          }

          setJson(json)
        } catch (error) {
          console.error('Error parsing JSON', error)
        }
      }
      reader.readAsText(file)
    }
  }

  return (
    <Stack>
      <FileInputButton type="file" accept=".json" onChange={handleFileChange} />

      {json && (
        <Stack gap={0.5} mt={1}>
          {!jsonValid ? (
            <Typography variant="body2" color="error">
              Error parsing JSON, stops or breadcrumbs not found
            </Typography>
          ) : (
            <>
              <Typography variant="body2">
                Breadcrumbs count: {json?.breadcrumbs?.length ?? 0}
              </Typography>
              <Typography variant="body2">Stops count: {json?.stops?.length ?? 0}</Typography>
            </>
          )}
        </Stack>
      )}
    </Stack>
  )
}
