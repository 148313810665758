import styled from 'styled-components'

interface Props {
  light?: boolean
  empty?: boolean
}

export const ReadonlyFieldParagraph = styled.p<Props>`
  color: ${p => (p.empty ? p.theme.colors.$paleSky : p.theme.colors.$nightRider)};
  font-size: ${p => (p.light ? p.theme.fonts.$p3 : p.theme.fonts.$p2)};
  height: auto;
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
`

ReadonlyFieldParagraph.displayName = 'ReadonlyFieldParagraph'
