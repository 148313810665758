import { useCloseModal, useShowModal } from '@/atoms'

import { Root } from './components/Root'
import { modalId } from './hooks/useController'

export function useGoToIntegrationsModal() {
  return {
    show: useShowModal(modalId),
    close: useCloseModal(),
    Modal: Root,
  }
}
