import type { PermissionData } from '../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfigurationType } from '@/features/domain/user'
import { selectHasNoPhysicalDevices } from '@/features/domain/device'

/**
 * This hook computes every data required to compose permissions
 */
export function usePermissionsData(): PermissionData {
  const userType = useSelector(selectUserConfigurationType)
  const hasNoPhysicalDevices = useSelector(selectHasNoPhysicalDevices)

  return useMemo(() => {
    return {
      rmOnly: userType === 'rmOnly',
      hasNoPhysicalDevices,
    }
  }, [userType, hasNoPhysicalDevices])
}
