import styled from 'styled-components'

const ItemRendererDetailRow = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  user-select: none;

  > * + * {
    margin-left: 6px;
  }
`

ItemRendererDetailRow.displayName = 'ItemRendererDetailRow'
export default ItemRendererDetailRow
