import tinycolor from 'tinycolor2'

export const darken = (color: string, percentage: number): string =>
  tinycolor(color).darken(percentage).toString()

export const lighten = (color: string, percentage: number): string =>
  tinycolor(color).lighten(percentage).toString()

export const saturate = (color: string, percentage: number): string =>
  tinycolor(color).saturate(percentage).toString()

export const desaturate = (color: string, percentage: number): string =>
  tinycolor(color).desaturate(percentage).toString()

export const brighten = (color: string, percentage: number): string =>
  tinycolor(color).brighten(percentage).toString()

export const toHex = (color: string): string => tinycolor(color).toHex()

export const isLight = (color: string): boolean => tinycolor(color).isLight()
export const isDark = (color: string): boolean => tinycolor(color).isDark()

export const isWhite = (color: string): boolean => tinycolor(color).getLuminance() === 1

export const setAlpha = (color: string, percentage: number): string =>
  tinycolor(color).setAlpha(percentage).toString()

export const isValidColor = (color: string): boolean => tinycolor(color).isValid()
