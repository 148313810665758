import { useNavigoSelection } from '@/atoms'
import { useInspectPin } from '@/map'

export function useNavigoCategory() {
  const [inspectPin] = useInspectPin()
  const { selectedCategories } = useNavigoSelection()

  if (inspectPin) return 'inspectPin'

  if (selectedCategories.length === 0) return
  if (selectedCategories.length > 1) return 'multiCategory'

  const category = selectedCategories[0]

  return category
}
