import styled from 'styled-components'

import { buttonReset } from '@/styles'
import { Button } from '@/components/primitives/buttons'

interface Props {
  badge?: string | number
  borderColor?: string
  backgroundColor?: string
  foregroundColor?: string
  fullOpacityIfDisabled?: boolean
}

export const CategoryButton = styled(Button)<Props>`
  ${buttonReset}
  height: 36px;
  transition: none;
  min-width: 128px;
  padding: 0px 40px;
  border: 2px solid ${p => p.borderColor || p.theme.colors.$nightRider};
  border-radius: 6px;
  font-size: ${p => p.theme.fonts.$h2};
  color: ${p => p.foregroundColor || p.theme.colors.$pureWhite};
  font-weight: ${p => p.theme.weights.$semiBold};
  background-color: ${p => p.backgroundColor || p.theme.colors.$nightRider};

  ${p => p.fullOpacityIfDisabled && `  &:disabled { opacity: 1; }`}

  &:hover&:enabled {
    background-color: ${p => p.backgroundColor || p.theme.colors.$nightRider};
  }

  &:after {
    top: 0px;
    left: 100%;
    height: 18px;
    display: ${p => (p.badge ? 'block' : 'none')};
    line-height: 15px;
    border-radius: 9px;
    padding: 0px 4px;
    position: absolute;
    white-space: nowrap;
    text-transform: uppercase;
    content: '${p => p.badge}';
    font-size: ${p => p.theme.fonts.$xs};
    transform: translate(-50%, -50%);
    color: ${p => p.theme.colors.$nightRider};
    background: #f9ba1b;
    border: 2px solid ${p => p.theme.colors.$pureWhite};
  }
`
CategoryButton.displayName = 'CategoryButton'
