import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Edit } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
}

export function EditButton(props: Props) {
  const { onClick } = props
  const texts = useTexts()

  return (
    <ReadOnlyTooltip
      render={preventEditing => (
        <NavigoIconButton
          text={texts.edit}
          onClick={onClick}
          disabled={preventEditing}
          Icon={<Edit size={17} color={preventEditing ? '$silver' : '$nightRider'} />}
          testid="navigo-drivers-actions-editButton"
        />
      )}
    />
  )
}
