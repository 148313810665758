import type { MainSelection } from '../typings'

import { produce } from 'immer'

import { mainAtom } from '../main'
import { setAllMainSelection } from './setAllMainSelection'

type Params =
  | { mode: 'replace'; publicData: uui.domain.PublicData }
  | {
      mode: 'update'
      idsChangelog: uui.domain.api.IdsChangelog
      listChangelog: uui.domain.ui.list.FilterChangelog
      calendarRangeChanged?: { calendarRange: uui.domain.DataRange; today: string }
    }

/**
 * Removes stale ids from selection arrays
 */
function removeStaleItems(
  idsChangelog: uui.domain.api.IdsChangelog,
  listChangelog: uui.domain.ui.list.FilterChangelog,
  calendarRangeChanged?: { calendarRange: uui.domain.DataRange; today: string },
) {
  let isSelectionChanged = false

  for (const [category, idsToRemove] of Object.entries(idsChangelog.remove)) {
    // short-circuit the categories that don't belong to the selection
    if (!mainAtom.selection[category] || !idsToRemove) continue

    // Use a for-loop instead of a .filter() to preserve array reference
    for (let index = mainAtom.selection[category].length - 1; index >= 0; index--) {
      if (idsToRemove.includes(mainAtom.selection[category][index])) {
        mainAtom.selection[category].splice(index, 1)

        isSelectionChanged = true
      }
    }
  }

  for (const [category, idsToRemove] of Object.entries(listChangelog.remove)) {
    // short-circuit the categories that don't belong to the selection
    if (!mainAtom.selection[category]) continue

    // Use a for-loop instead of a .filter() to preserve array reference
    for (let index = mainAtom.selection[category].length - 1; index >= 0; index--) {
      if (idsToRemove.has(mainAtom.selection[category][index])) {
        mainAtom.selection[category].splice(index, 1)

        isSelectionChanged = true
      }
    }
  }

  if (calendarRangeChanged) {
    let breadcrumbSelectionChanged = false
    let deviceSelectionChanged = false
    let unifiedVehicleSelectionChanged = false

    // Breadcrumbs are always deselected when calendar range changes
    if (mainAtom.selection.breadcrumbs.length > 0) {
      mainAtom.selection.breadcrumbs = []
      breadcrumbSelectionChanged = true
    }

    // Routes are always deselected when calendar range changes
    if (mainAtom.selection.routes.length > 0) {
      mainAtom.selection.routes = []
    }

    // Devices and unifiedVehicles are deselected when calendar range
    // is composed by multiple days or is in the future
    const { calendarRange, today } = calendarRangeChanged
    const isRange = calendarRange.minDate !== calendarRange.maxDate
    const isFuture = parseInt(calendarRange.minDate) - parseInt(today) > 0

    if (mainAtom.selection.devices.length > 0) {
      if (isRange || isFuture) {
        mainAtom.selection.devices = []
        deviceSelectionChanged = true
      }
    }

    if (mainAtom.selection.unifiedVehicles.length > 0) {
      if (isRange || isFuture) {
        mainAtom.selection.unifiedVehicles = []
        unifiedVehicleSelectionChanged = true
      }
    }

    if (breadcrumbSelectionChanged || deviceSelectionChanged || unifiedVehicleSelectionChanged) {
      isSelectionChanged = true
    }
  }

  return isSelectionChanged
}

/**
 * Update the selection for the items whose id is changed.
 */
function updateStaleItems(idsChangelog: uui.domain.api.IdsChangelog) {
  let selectionUpdated = false

  for (const [category, idsToUpdate] of Object.entries(idsChangelog.update)) {
    // short-circuit the categories that don't belong to the selection
    if (!mainAtom.selection[category] || !idsToUpdate) continue

    for (const { prev, next } of idsToUpdate) {
      mainAtom.selection[category] = mainAtom.selection[category].map(id =>
        id === prev ? next : id,
      )
      selectionUpdated = true
    }
  }

  return selectionUpdated
}

/**
 * Generates a new selection array filtering out stale items
 */
function replaceSelection(items: Record<string, unknown>, selection: string[]): string[] {
  // short-circuit long loops
  if (selection.length > 1000) return []

  return selection.filter(id => !!items[id])
}

/**
 * Replace all selections with an updated version (w/out stale items)
 */
function updateAndReplaceAllSelections(publicData: uui.domain.PublicData) {
  let isSelectionChanged = false

  setAllMainSelection(prevMainSelection => {
    // Collect all the selection sizes before the update
    const sizes = {
      depots: prevMainSelection.depots.length,
      routes: prevMainSelection.routes.length,
      places: prevMainSelection.places.length,
      drivers: prevMainSelection.drivers.length,
      orderSteps: prevMainSelection.orderSteps.length,
      breadcrumbs: prevMainSelection.breadcrumbs.length,
      unifiedVehicles: prevMainSelection.unifiedVehicles.length,
    }

    const newMainSelection = produce(prevMainSelection, draft => {
      // TODO: manage deviceEvents

      // Breadcrumb are always deselected after a replace
      draft.breadcrumbs = []

      draft.orderSteps = replaceSelection(
        publicData.domain.rm.orderStepsIdInRange,
        draft.orderSteps,
      )
      draft.depots = replaceSelection(publicData.domain.rm.depots, draft.depots)
      draft.routes = replaceSelection(publicData.domain.rm.routes, draft.routes)

      draft.places = replaceSelection(publicData.domain.gps.wwgps.places, draft.places)
      draft.drivers = replaceSelection(publicData.domain.rm.drivers, draft.drivers)
      draft.unifiedVehicles = replaceSelection(publicData.domain.vehicles, draft.unifiedVehicles)
    })

    // If at least on size is changed means that the selection is changed
    for (const key in sizes) {
      if (newMainSelection[key].length !== sizes[key]) {
        isSelectionChanged = true

        break
      }
    }

    return newMainSelection as MainSelection
  })

  return isSelectionChanged ? 'removed' : 'unchanged'
}

/**
 * Remove and update the stale selections and returns a value that indicates what happened to selection items
 * The return value can be:
 * - unchanged (no changes)
 * - removed (some items have been removed)
 * - updated (some items have been updated)
 * - removedAndUpdated (some items have been removed and some updated)
 */
export function cleanUpMainSelection(params: Params) {
  switch (params.mode) {
    case 'replace':
      return updateAndReplaceAllSelections(params.publicData)

    case 'update':
      const itemsRemoved = removeStaleItems(
        params.idsChangelog,
        params.listChangelog,
        params.calendarRangeChanged,
      )
      const itemsUpdated = updateStaleItems(params.idsChangelog)

      if (itemsRemoved && itemsUpdated) return 'removedAndUpdated'
      if (itemsRemoved) return 'removed'
      if (itemsUpdated) return 'updated'

      return 'unchanged'
  }
}
