import { useState } from 'react'

import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    disabled: translate({
      id: `sms4.settings.communication.notifications.generic.disabled`,
    }),

    notificationTitle: (title: string) =>
      translate({
        //@ts-expect-error
        id: `sms4.settings.communication.notifications.${title}`,
      }),

    notificationWay: (notificationWay: uui.domain.client.rm.NotificationWay) => {
      switch (notificationWay) {
        case 'EMAIL':
          return translate({ id: `sms4.settings.communication.notificationType.email` })
        case 'PHONE':
          return translate({ id: `sms4.settings.communication.notificationType.phone` })
      }
    },
  }))

  return api
}
