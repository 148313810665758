import { useMemo } from 'react'

import { useCrudSelection } from '@/atoms'

import { useDeviceEvents } from './useDeviceEvents'

type EventsData =
  | { type: 'empty' }
  | { type: 'single'; event: uui.domain.client.gps.wwgps.EventInfo }
  | { type: 'bulk'; events: uui.domain.client.gps.wwgps.EventInfo[] }

export const useEventsRoot = () => {
  const [selection] = useCrudSelection('deviceEvents')
  const deviceEvents = useDeviceEvents()
  const eventsData = useMemo<EventsData>(() => {
    const selectedEvents = getSelectedEvents(selection, deviceEvents)
    return computeEventsData(selectedEvents)
  }, [selection, deviceEvents])

  return {
    deviceEvents,
    eventsData,
    selection,
    noEvents: Object.keys(deviceEvents).length === 0,
  }
}

function getSelectedEvents(
  selection: workwave.DeepReadonly<string[]>,
  deviceEvents: Record<string, uui.domain.client.gps.wwgps.EventInfo>,
) {
  // selection could hold some ids that are not in the depotsMap due to server-data computation
  // depotsMap holds the "truth"
  return selection.reduce<uui.domain.client.gps.wwgps.EventInfo[]>((acc, id) => {
    if (deviceEvents[id]) {
      acc.push(deviceEvents[id])
    }
    return acc
  }, [])
}

function computeEventsData(selectedEvents: uui.domain.client.gps.wwgps.EventInfo[]): EventsData {
  switch (selectedEvents.length) {
    case 0:
      return {
        type: 'empty',
      }

    case 1:
      return {
        type: 'single',
        event: selectedEvents[0],
      }

    default:
      return {
        type: 'bulk',
        events: selectedEvents,
      }
  }
}
