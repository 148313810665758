import styled from 'styled-components'

import { buttonReset } from '@/styles'
import { Button } from '@/components/primitives/buttons'

export const EntityButton = styled(Button)`
  ${buttonReset}
  height: 36px;
  transition: none;
  min-width: 128px;
  padding: 0px 8px;
  border-radius: 6px;
  font-size: ${p => p.theme.fonts.$h2};
  color: ${p => p.theme.colors.$pureWhite};
  font-weight: ${p => p.theme.weights.$semiBold};
  background-color: ${p => p.theme.colors.$nightRider};

  &:hover&:enabled {
    background-color: ${p => p.theme.colors.$nightRider};
  }
`
EntityButton.displayName = 'EntityButton'
