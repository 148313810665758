import { clearDepotFeatures } from '../../../layers/depot/clearDepotFeatures'
import { clearOrderFeatures } from '../../../layers/order/clearOrderFeatures'
import { clearPlaceFeatures } from '../../../layers/place/clearPlaceFeatures'
import { clearDeviceFeatures } from '../../../layers/device/clearDeviceFeatures'
import { clearRegionFeatures } from '../../../layers/region/clearRegionFeatures'
import { clearVehicleFeatures } from '../../../layers/vehicle/clearVehicleFeatures'
import { clearGeofenceFeatures } from '../../../layers/geofence/clearGeofenceFeatures'
import { clearBreadcrumbFeatures } from '../../../layers/breadcrumb/clearBreadcrumbFeatures'
import { clearRoadSegmentFeatures } from '../../../layers/roadSegment/clearRoadSegmentFeatures'
import { clearTrafficAreaFeatures } from '../../../layers/trafficArea/clearTrafficAreaFeatures'
import { clearRoutePolylineFeatures } from '../../../layers/routePolyline/clearRoutePolylineFeatures'

export const clearMarkerLayers = () => {
  clearOrderFeatures()
  clearDepotFeatures()
  clearPlaceFeatures()
  clearDeviceFeatures()
  clearRegionFeatures()
  clearVehicleFeatures()
  clearGeofenceFeatures()
  clearBreadcrumbFeatures()
  clearRoadSegmentFeatures()
  clearTrafficAreaFeatures()
  clearRoutePolylineFeatures()
}
