import type { MutableRefObject } from 'react'
import { styled } from '@mui/material'

interface Props {
  mapId: string
  targetRef: MutableRefObject<HTMLDivElement>
  bottomContentPadding?: number
}

const MapContainerComponent = styled('div', {
  name: 'MapContainerComponent',
  shouldForwardProp: prop =>
    prop !== 'bottomContentPadding' && prop !== 'mapId' && prop !== 'targetRef',
})<{
  bottomContentPadding?: number
}>(({ bottomContentPadding }) => ({
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0,
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'stretch',

  // ATTENTION: OpenLayers add all Map styling as inline style properties.
  // Because of that to override the cursor the rule must be applied to the map canvas element with the important modifier.

  '& > .ol-pointer canvas': {
    cursor: 'pointer !important',
  },

  '& > .ol-pointer-lasso canvas': {
    cursor: 'crosshair !important',
  },

  '& > .ol-pointer-lasso-additive canvas': {
    cursor: 'copy !important',
  },

  '& > .ol-pointer-lasso-subtractive canvas': {
    cursor: 'copy !important',
  },

  '& > .ol-pointer-draw-valid canvas': {
    cursor: 'crosshair !important',
  },

  '& > .ol-pointer-draw-invalid canvas': {
    cursor: 'no-drop !important',
  },

  '& > .ol-pointer-draw-close-shape canvas': {
    cursor: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="font-size: 20px;"><text y="20">✅</text></svg>'), auto !important`,
  },

  '& > .ol-pointer-modify-remove-point canvas': {
    cursor: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="font-size: 20px;"><text y="20">❌</text></svg>'), auto !important`,
  },

  '& > .ol-pointer-modify-add-point canvas': {
    cursor: 'copy !important',
  },

  '& > .ol-pointer-modify-move-point canvas': {
    cursor: 'all-scroll !important',
  },

  '& > *': {
    width: '100%',
    height: '100%',
  },

  '& .ol-control > *:focus': {
    outline: 0,
  },
  '& .ol-control:focus': {
    outline: 0,
  },

  '& .ol-full-screen': {
    fontSize: 20,
    bottom: 113,
    right: 24,
    top: 'unset',
  },

  '& .ol-full-screen button': {
    width: 32,
    color: 'rgba(0,0,0,0.54) !important',
    height: 32,
    borderRadius: 4,
  },

  '& .ol-rotate': {
    right: '2.5em',
    top: 'auto',
    bottom: bottomContentPadding || '0.5em',
  },

  '& .ol-attribution.ol-uncollapsible': {
    top: 'unset',
    right: 0,
    bottom: 0,
    height: 18,
    borderRadius: 0,
  },

  '& .ol-search-on-map-control': {
    position: 'absolute',
    right: '0.5em',
    top: '0.5em',
    zIndex: 10,
  },

  '& .ol-search-on-map': {
    position: 'relative',
    top: 0,
    left: 0,
  },

  '& .ol-search-on-map button': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '& .ol-search-on-map svg': {
    width: 16,
  },

  '& .ol-search-on-map button.smaller-icon svg': {
    width: 12,
  },

  '& .ol-zoom': {
    fontSize: 24,
    bottom: 32,
    right: 24,
    top: 'unset',
    left: 'unset',
  },

  '& .ol-zoom-in, & .ol-zoom-out': {
    fontWeight: '500',
    color: 'rgba(0,0,0,0.54) !important',
    height: 32,
    width: 32,
  },

  '& .ol-zoom .ol-zoom-in': {
    borderRadius: '4px 4px 0 0',
  },

  '& .ol-zoom .ol-zoom-out': {
    borderRadius: '0 0 4px 4px',
  },

  '& .ol-overviewmap.ol-uncollapsible': {
    top: 'unset',
    left: 24,
    bottom: 32,
    cursor: 'pointer',
    border: '1px solid #fff',
    overflow: 'hidden',
    borderRadius: '8px',
  },

  '& .ol-overviewmap.ol-uncollapsible:hover': {
    border: '3px solid #fff',
    transform: 'translate(-2px, 2px)',
  },

  '& .ol-overviewmap .ol-overviewmap-map': {
    width: '55px',
    height: '55px',
    border: 0,
    pointerEvents: 'all',
  },

  '& .ol-overviewmap .ol-overviewmap-box': {
    display: 'none',
  },

  '& .ol-scale-line': {
    left: 'unset',
    right: 129,
    height: 18,
    bottom: 0,
    borderRadius: 0,
  },
}))

export function MapContainer(props: Props) {
  const { mapId, targetRef, bottomContentPadding } = props

  return (
    <MapContainerComponent id={mapId} ref={targetRef} bottomContentPadding={bottomContentPadding} />
  )
}
