import { type ReactNode, useCallback, useMemo, useState, useEffect } from 'react'
import { type SelectChangeEvent, Typography, Stack, Button } from '@mui/material'

import { useSelector } from 'react-redux'
import { SimpleSelect, TextField } from '@workwave-tidal/tidal/components'
import { selectTelematicsTenantSources } from '@/features/domain/account'

export function TenantSourceTester() {
  const [statusDescription, setStatusDescription] = useState<string | undefined>(undefined)
  const [tenantSourceId, setTenantSourceId] = useState<string | undefined>(undefined)
  const [activeStatus, setActiveStatus] = useState<string | undefined>(undefined)
  const [statusCode, setStatusCode] = useState<number | undefined>(undefined)

  const tenantSources = useSelector(selectTelematicsTenantSources)

  const options = useMemo(() => {
    return Object.values(tenantSources).map(tenantSource => ({
      label: tenantSource.label,
      value: tenantSource.id,
    }))
  }, [tenantSources])

  const onChangeTenantSource = useCallback(
    (event: SelectChangeEvent<string>, _child: ReactNode) => {
      setTenantSourceId(event.target.value)
    },
    [],
  )

  const onChangeActiveStatus = useCallback(
    (event: SelectChangeEvent<string>, _child: ReactNode) => {
      setActiveStatus(event.target.value)
    },
    [],
  )

  const onChangeStatusCode = useCallback(event => {
    setStatusCode(event.target.value)
  }, [])

  const onChangeStatusDescription = useCallback(event => {
    setStatusDescription(event.target.value)
  }, [])

  const onSubmit = useCallback(() => {
    globalThis.testActions.telematics.setTenantSourceState({
      statusDescription,
      tenantSourceId,
      statusCode,
      active: activeStatus === 'true' ? true : false,
    })
  }, [tenantSourceId, activeStatus, statusCode, statusDescription])

  useEffect(() => {
    if (!tenantSourceId) return
    const tenantSource = tenantSources[tenantSourceId]

    if (!tenantSource) return

    setActiveStatus(tenantSource.active ? 'true' : 'false')
    setStatusCode(tenantSource.statusCode)
    setStatusDescription(tenantSource.statusDescription)
  }, [tenantSources, tenantSourceId])

  return (
    <>
      <Typography variant="h6">Tenant Source status tester</Typography>

      <Stack spacing={3} margin={2}>
        <SimpleSelect
          value={tenantSourceId}
          options={options}
          onChange={onChangeTenantSource}
          label="Tenant source"
        />

        <hr />

        {tenantSourceId && (
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <Stack spacing={1} margin={2} flex={1}>
              <Typography variant="h6">Current values</Typography>

              <Typography variant="body1">Id: {tenantSourceId}</Typography>
              <Typography variant="body1">
                Active: {tenantSources[tenantSourceId].active ? `Yes` : `No`}
              </Typography>

              <Typography variant="body1">
                Status code: {tenantSources[tenantSourceId].statusCode}
              </Typography>

              <Typography variant="body1">
                Status description: {tenantSources[tenantSourceId].statusDescription}
              </Typography>
            </Stack>

            <Stack spacing={2} flex={1}>
              <Typography variant="h6">New values</Typography>

              <SimpleSelect
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
                onChange={onChangeActiveStatus}
                value={activeStatus}
                label="Active"
              />

              <TextField value={statusCode} label="Status code" onChange={onChangeStatusCode} />
              <TextField
                value={statusDescription}
                label="Status description"
                onChange={onChangeStatusDescription}
              />
              <Button onClick={onSubmit} variant="contained">
                Submit
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  )
}
