import type { MutableRefObject } from 'react'
import type { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'

import { Box, Typography } from '@mui/material'
import { ContextualMenu } from './components/ContextualMenu'
import { OrdersCounter } from './components/OrdersCounter'
import { useTexts } from './useTexts'

interface Props {
  orderIds: string[]
  apiRef: MutableRefObject<GridApiPro>
}

export function OrdersGridDropdown(props: Props) {
  const { orderIds, apiRef } = props

  const texts = useTexts()

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography fontWeight="bold" fontSize={20} marginRight={2}>
        {texts.orders}
      </Typography>

      <OrdersCounter orderIds={orderIds} />

      <ContextualMenu orderIds={orderIds} apiRef={apiRef} />
    </Box>
  )
}
