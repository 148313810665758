import { memo } from 'react'
import { Box, Skeleton, Stack } from '@mui/material'

function ListLoadingComponent() {
  return (
    <Box padding={2} maxHeight="100%" overflow="hidden" width="100%">
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={30} />
        <Skeleton />
        <Skeleton width="75%" />
        <Skeleton width="75%" />
        <Skeleton width="50%" />
        <Skeleton variant="rectangular" height={30} />
        <Skeleton />
        <Skeleton width="75%" />
        <Skeleton width="75%" />
        <Skeleton width="50%" />
        <Skeleton variant="rectangular" height={30} />
        <Skeleton />
        <Skeleton width="75%" />
        <Skeleton width="75%" />
        <Skeleton width="50%" />
        <Skeleton variant="rectangular" height={30} />
        <Skeleton />
        <Skeleton width="75%" />
        <Skeleton width="75%" />
        <Skeleton width="50%" />
        <Skeleton variant="rectangular" height={30} />
        <Skeleton />
        <Skeleton width="75%" />
        <Skeleton width="75%" />
        <Skeleton width="50%" />
        <Skeleton variant="rectangular" height={30} />
        <Skeleton />
        <Skeleton width="75%" />
        <Skeleton width="75%" />
        <Skeleton width="50%" />
      </Stack>
    </Box>
  )
}

export const ListLoading = memo(ListLoadingComponent)
