import type { StoreState, AppDispatch } from '../typings'
import type { Middleware } from '@reduxjs/toolkit'
import type { DomainProxy } from '../domain/domainProxy'

import { toast } from 'react-toastify'

import { replaceDomainData, updateDomainData } from '@/features/domain'
import { subscribeMapToSelection, unsubscribeMapFromSelection } from '@/map'
import { resetEditingState } from '@/atoms'
import { intl } from '@/intl'

import { initialState } from '@/features/domain/initialState'
import { restoreAndCleanupSelections } from './helpers/restoreAndCleanupSelections'
import { backupSelections } from './helpers/backupSelections'

export interface DomainMiddlewareOptions {
  domainProxy: DomainProxy
}

/**
 * TODO: Add Description
 *
 * @param options Middleware options.
 *
 * @public
 */
export function createDomainMiddleware(
  options: DomainMiddlewareOptions,
): Middleware<unknown, StoreState> {
  // TODO: maybe add different implementation for DEV and PROD?

  // if (process.env.NODE_ENV === 'production') {
  //   return () => next => action => next(action)
  // }

  const { domainProxy } = options

  return ({ dispatch }) => {
    // The correct type is not inferred, dispatching a type would cause a TS error
    const appDispatch = dispatch as AppDispatch

    // On forceful close, reset to initial domain state
    domainProxy.subscribeToForcefulClose(() => {
      appDispatch(replaceDomainData(initialState.publicData))
    })

    domainProxy.subscribeToDataChangeRequest(async domainAction => {
      let isSelectionChanged = false

      // Unsubscribe map selection listeners
      unsubscribeMapFromSelection()

      // $$::update::selection::changeTerritory(backup, [force-reload]) The selections are saved and reset
      const selectionBackup = backupSelections()

      if (domainAction.mode === 'replace') {
        // Reset editing state
        resetEditingState()

        isSelectionChanged = restoreAndCleanupSelections({
          mode: 'replace',
          selectionBackup,
          publicData: domainAction.state,
        })

        // Domain replace (sync action)
        appDispatch(replaceDomainData(domainAction.state))

        // Notifying server-data: dispatch post store action
        notifyDomainUpdated(domainProxy, domainAction)
      }

      if (domainAction.mode === 'update') {
        // $$::update::selection::changeTerritory(apply, [backup]) The selections are restored and cleaned up
        isSelectionChanged = restoreAndCleanupSelections({
          mode: 'update',
          selectionBackup,
          idsChangelog: domainAction.summary.idsChangelog,
          listChangelog: domainAction.summary.listChangelog,
          calendarRangeChanged: domainAction.summary.calendarRangeChanged,
        })

        // Reset editing state
        if (isSelectionChanged) {
          resetEditingState()
        }

        // Domain update (async action)
        await executeUpdateDomainData(appDispatch, domainProxy, domainAction)
      }

      // Subscribe map selection listeners
      subscribeMapToSelection()

      // Notify
      if (isSelectionChanged) {
        notifySelectionChanges()
      }
    })

    return next => action => next(action)
  }
}

async function executeUpdateDomainData(
  dispatch: AppDispatch,
  domainProxy: DomainProxy,
  domainAction: uui.domain.api.StateChangeAction,
) {
  if (domainAction.mode === 'update') {
    // Domain update
    const actionResult = await dispatch(
      updateDomainData({
        changeSet: domainAction.changeSet,
        summary: domainAction.summary,
      }),
    )

    // Notifying server-data
    if (updateDomainData.fulfilled.match(actionResult)) {
      await notifyDomainUpdated(domainProxy, domainAction)
    }
    // TODO: what should happens if that action fails?
  }
}

/**
 * This method notifies that the domain has been updated also with the origin of the update,
 * in that way processDomainUpdated can check if is part of the buffer
 * */
async function notifyDomainUpdated(
  domainProxy: DomainProxy,
  domainAction: uui.domain.api.StateChangeAction,
) {
  const rpc = domainProxy.getRpc()
  rpc('rpc/domainUpdated', {
    category: 'rpc',
    type: 'rpc/domainUpdated',
    origin: domainAction.origin,
  })

  // dispatch post store action
  domainProxy.notifyChangeDataSuccess(domainAction)
}

function notifySelectionChanges() {
  toast.info(intl.translate({ id: 'global.selectionUpdated' }))
}
