import type { Props } from './typings'

import { ReactElement, useCallback } from 'react'

import { AddRounded } from '@/icons'
import { FlexColumn } from '@/forms-legacy'

import BreakRow from './BreakRow'
import FieldButton from './FieldButton'
import { deleteBreak, updateBreak, addBreak } from './utils'

const Breaks = (props: Props): ReactElement => {
  const { labelButton, max } = props
  const { value, onChange, ...breakProps } = props

  const addButtonDisabled: boolean = !!max ? value.length >= max : false

  const onAddBreak = useCallback(() => {
    onChange(addBreak(value))
  }, [onChange, value])

  const onDeleteBreak = useCallback(
    (index: number) => {
      onChange(deleteBreak(value, index))
    },
    [onChange, value],
  )

  const onUpdateBreak = useCallback(
    (b: uui.domain.rm.Break, index: number) => {
      onChange(updateBreak(value, b, index))
    },
    [onChange, value],
  )

  return (
    <FlexColumn primary>
      <FieldButton disabled={addButtonDisabled} Icon={<AddRounded />} onClick={onAddBreak}>
        <>{labelButton}</>
      </FieldButton>
      {value.map((brk: uui.domain.rm.Break, index: number) => {
        const { durationSec, startSec, endSec } = brk
        return (
          <BreakRow
            key={`${durationSec}-${startSec}-${endSec}-${index}`}
            onRemove={() => onDeleteBreak(index)}
            onUpdate={(b: uui.domain.rm.Break) => onUpdateBreak(b, index)}
            break={brk}
            fieldProps={breakProps}
          />
        )
      })}
    </FlexColumn>
  )
}

export default Breaks
