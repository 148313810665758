import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function DoubleArrow(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 10 8">
        <path d="M2.804 4L.09 6.922a.347.347 0 0 0 .002.469l.486.512c.124.13.324.13.448-.002l3.453-3.665A.34.34 0 0 0 4.57 4a.34.34 0 0 0-.092-.236L1.026.1A.305.305 0 0 0 .578.097L.092.61a.347.347 0 0 0-.002.469L2.804 4zm4.571 0L4.661 6.922a.347.347 0 0 0 .003.469l.485.512c.125.13.325.13.448-.002L9.05 4.236A.34.34 0 0 0 9.143 4a.34.34 0 0 0-.093-.236L5.597.1A.305.305 0 0 0 5.15.097L4.664.61a.347.347 0 0 0-.003.469L7.375 4z"></path>
      </svg>
    </IconContainer>
  )
}
