import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFormatEligibility } from '@/hooks'
import { selectUserConfiguration } from '@/features/domain/user'

import { FieldMode } from '../../../components/ViewDetails'
import { useTexts } from '../useTexts'

import { intersection, uniq } from './utils'
import { computeBulkTimeWindows } from './computeBulkTimeWindows'
import { computePartialBulkViewData } from './computePartialBulkViewData'

interface ComposedBulkViewData {
  title: string
  description: string

  eligibility: string | FieldMode.mixed
  loads: Record<string, number | FieldMode.mixed>
  priority: string | FieldMode.mixed
  serviceTime: number | FieldMode.mixed
  tagsIn: string[]
  tagsOut: string[]
  timeWindows: uui.domain.rm.TimeWindow[] | FieldMode.mixed
  vehicle: string | FieldMode.mixed
  violations: uui.domain.rm.RouteStepViolationsType[]
  useMetric: boolean
  email: string
  company: string | null | FieldMode.mixed
  phone: string
  notes: string
}

export function useBulkViewData(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
  vehiclesMap: Record<string, uui.domain.client.rm.Vehicle>,
  companiesMap: Record<string, uui.domain.client.rm.Company>,
): ComposedBulkViewData {
  const texts = useTexts()
  const { distanceFormat, planType } = useSelector(selectUserConfiguration)

  const formatEligibility = useFormatEligibility()

  return useMemo(() => {
    const vehiclesInfo = computePartialBulkViewData(
      orders,
      vehiclesMap,
      companiesMap,
      formatEligibility,
      planType === 'archived',
    )

    const title: string = `${orders.length} ${texts.ordersText}`
    const description: string =
      vehiclesInfo.description === FieldMode.mixed
        ? texts.mixedAddressesText
        : vehiclesInfo.description

    const bulkTagsIn: string[] = intersection(vehiclesInfo.tagsIn)
    const bulkTagsOut: string[] = intersection(vehiclesInfo.tagsOut)
    const bulkViolations = [...vehiclesInfo.violations]

    const bulkLoads = Object.entries(vehiclesInfo.loads).reduce(
      (acc: Record<string, number | FieldMode.mixed>, [key, data]) => {
        if (data.length === orders.length) {
          const uniqueValues = uniq(data)

          acc[key] = uniqueValues.length === 1 ? uniqueValues[0] : FieldMode.mixed
        }
        return acc
      },
      {},
    )

    return {
      title,
      description,
      tagsIn: bulkTagsIn,
      tagsOut: bulkTagsOut,
      loads: bulkLoads,
      timeWindows: computeBulkTimeWindows(vehiclesInfo.timeWindows),
      violations: bulkViolations,
      eligibility: vehiclesInfo.eligibility,
      priority: vehiclesInfo.priority,
      serviceTime: vehiclesInfo.serviceTime,
      vehicle: vehiclesInfo.vehicle,
      useMetric: distanceFormat === 'METRIC',
      phone: vehiclesInfo.phone,
      email: vehiclesInfo.email,
      notes: vehiclesInfo.notes,
      company: vehiclesInfo.company,
    }
  }, [orders, vehiclesMap, texts, distanceFormat, formatEligibility, companiesMap, planType])
}
