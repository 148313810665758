import { Modal } from '@/components/Modal'

import { modalId } from '../hooks/useController'
import { Content } from './Content'

interface Props {
  dates: string[]
  multiTerritory?: boolean
}

export function Root(props: Props) {
  const { dates, multiTerritory = false } = props
  return (
    <Modal modalId={modalId}>
      <Content dates={dates} multiTerritory={multiTerritory} />
    </Modal>
  )
}
