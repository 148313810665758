import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { parse, subMonths, addMonths, subSeconds } from 'date-fns/esm'

import { selectTerritory } from '@/features/domain/territory'
import { selectAggregateDepotsByLatLng, selectDepots } from '@/features/domain/depot'
import {
  selectUserAccount,
  selectUserConfiguration,
  selectUserProfile,
  selectUserAccountPreferences,
} from '@/features/domain/user'
import { selectOrders, selectOrderCustomFields } from '@/features/domain/order'

import {
  selectTagsFromVehicles,
  selectSortedRMVehicles,
  selectLoadsFromVehicles,
} from '@/features/domain/vehicle'
import { selectCompanies } from '@/features/domain/company'
import { useFormTransaction, useReadOnly, useValidateEmail } from '@/hooks'
import { selectCalendarRange } from '@/features/domain/ui'
import { useResetEditingState, useEnvironmentQa } from '@/atoms'
import { useInspectPin, removeInspectPin, useGetMapInfo, usePendingFitMapOnMount } from '@/map'

import { TabValues } from '../../../typings'

import { SingleEdit } from './SingleEdit'
import { useOnCreateOrder, useOnUpdateOrder } from './useActions'

type Props = {
  order: uui.domain.client.rm.ExtendedOrderStep
  tab: TabValues
}

export function SingleEditProvider(props: Props) {
  const { today, planType, distanceFormat, weekStartsOn, timeFormat } =
    useSelector(selectUserConfiguration)

  const { minDate } = useSelector(selectCalendarRange)
  const userProfile = useSelector(selectUserProfile)
  const {
    name,
    workingDayStartSec,
    notificationBehavior,
    licensingLimits: { orderRadiusMt, horizonView, trackedVehicles },
  } = useSelector(selectTerritory)

  const { maxCustomFieldsPerOrder, maxTags } = useSelector(selectUserAccount).licensingLimits
  const depotsAsMapByLatLng = useSelector(selectAggregateDepotsByLatLng)
  const customFields = useSelector(selectOrderCustomFields)
  const vehicles = useSelector(selectSortedRMVehicles)
  const loads = useSelector(selectLoadsFromVehicles)
  const tags = useSelector(selectTagsFromVehicles)
  const { allowEmptyCustomFields } = useSelector(selectUserAccountPreferences)
  const resetEditingState = useResetEditingState()
  const { readonly } = useReadOnly()
  const orders = useSelector(selectOrders)
  const depots = useSelector(selectDepots)
  const qa = useEnvironmentQa()
  const companies = useSelector(selectCompanies)

  const { onCreateOrder, submitting: createSubmitting } = useOnCreateOrder()
  const { onUpdateOrder, submitting: updateSubmitting } = useOnUpdateOrder()

  const todayDate = useMemo(() => parse(today, 'yyyyMMdd', new Date()), [today])

  const todayMinusMidnightCross = useMemo(() => {
    return subSeconds(todayDate, workingDayStartSec)
  }, [todayDate, workingDayStartSec])

  const calendarMinDate = useMemo(() => parse(minDate, 'yyyyMMdd', new Date()), [minDate])

  const forcedCalendarRange = useMemo(() => {
    return {
      min: subMonths(todayDate, 120),
      max: addMonths(todayDate, 120),
    }
  }, [todayDate])

  const handleStopEditing = useCallback(() => {
    resetEditingState()
  }, [resetEditingState])

  const isSimulation = planType === 'simulation'

  const [inspectPin] = useInspectPin()
  const initialLatLngFromMapPin =
    inspectPin?.mode === 'converted' && inspectPin.target === 'order'
      ? inspectPin.latLng
      : undefined

  // try to fit pending on mount
  // and clear pending fitMap on unmount
  usePendingFitMapOnMount(true)

  const { center: mapCenter } = useGetMapInfo()

  const transactionState = useFormTransaction()

  const loading = transactionState === 'loading'

  const validateEmail = useValidateEmail()

  return (
    <SingleEdit
      qa={qa}
      tags={tags}
      loads={loads}
      tab={props.tab}
      orders={orders}
      depots={depots}
      loading={loading}
      order={props.order}
      vehicles={vehicles}
      territoryName={name}
      mapCenter={mapCenter}
      planLocked={readonly}
      companies={companies}
      maxTagsCount={maxTags}
      customFields={customFields}
      weekStartsOn={weekStartsOn}
      createOrder={onCreateOrder}
      h24={timeFormat === 'HH:mm'}
      updateOrders={onUpdateOrder}
      validateEmail={validateEmail}
      today={todayMinusMidnightCross}
      territoryRadius={orderRadiusMt}
      stopEditing={handleStopEditing}
      allowBarcodes={trackedVehicles > 0}
      removeInspectPin={removeInspectPin}
      workingDayStartSec={workingDayStartSec}
      useMetric={distanceFormat === 'METRIC'}
      depotsAsMapByLatLng={depotsAsMapByLatLng}
      notificationBehavior={notificationBehavior}
      allowEmptyCustomFields={allowEmptyCustomFields}
      maxCustomFieldsPerOrder={maxCustomFieldsPerOrder}
      submitting={createSubmitting || updateSubmitting}
      horizonView={isSimulation ? undefined : horizonView}
      initialLatLngFromInspectPin={initialLatLngFromMapPin}
      lockDate={isSimulation ? undefined : calendarMinDate}
      maxBarcodes={userProfile.account.licensingLimits.maxBarcodes}
      forcedCalendarRange={isSimulation ? undefined : forcedCalendarRange}
      forcedInteractiveRange={isSimulation ? undefined : forcedCalendarRange}
    />
  )
}
