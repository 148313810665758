import { ListHeaderExternalFilterButton } from '@/components/List'

import { useFilterByBadgeModal } from '../../../../../modals/FilterByBadgeModal'
import { useIsFilterByBadgeActive } from './hooks/useIsFilterByBadgeActive'

export function FilterByBadge() {
  const { Modal: FilterByBadgeModal, show: showFilterByBadgeModal } = useFilterByBadgeModal()
  const isFilterByBadgeActive = useIsFilterByBadgeActive()

  return (
    <>
      <ListHeaderExternalFilterButton
        onClick={() => {
          showFilterByBadgeModal()
        }}
        filterActive={isFilterByBadgeActive}
      />
      <FilterByBadgeModal />
    </>
  )
}
