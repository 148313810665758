import type { TerritoryConfig as TerritoryConfigType } from '../../../../../../../../../types'

import { useCallback, useState } from 'react'

export type VehiclesRestrictionValue = 'not-restricted' | 'restricted'

export function useVehiclesRestriction(
  fieldValue: TerritoryConfigType,
  onChange: (value: TerritoryConfigType) => void,
) {
  const [value, setValue] = useState<VehiclesRestrictionValue>(
    allSelected(fieldValue.vehicleIds) ? 'not-restricted' : 'restricted',
  )

  const handleOnChange = useCallback(
    (_, radioValue: VehiclesRestrictionValue) => {
      // empty the selection
      if (radioValue === 'restricted') {
        onChange({ ...fieldValue, vehicleIds: [] })
      }

      // select all possible values
      if (radioValue === 'not-restricted') {
        onChange({ ...fieldValue, vehicleIds: ['*'] })
      }

      setValue(radioValue)
    },
    [onChange, fieldValue],
  )

  return {
    onChange: handleOnChange,
    value,
  }
}

function allSelected(fieldValue: string[]) {
  return fieldValue.includes('*')
}
