import { useSelector } from 'react-redux'

import { selectAllVehiclesHaveActiveLiveEta } from '@/features/domain/vehicle'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectLiveEta } from '@/features/domain/territory'

/**
 * Returns the status of the Live ETA for the territory/vehicles
 */
export function useLiveEtaStatus() {
  const liveEtaActiveOnAllVehicles = useSelector(selectAllVehiclesHaveActiveLiveEta)
  const liveEtaActiveOnTerritory = useSelector(selectLiveEta)
  const userConfiguration = useSelector(selectUserConfiguration)

  if (userConfiguration.userType !== 'rm360') return 'notAvailableForTheUser'

  if (liveEtaActiveOnAllVehicles) return 'active'
  if (liveEtaActiveOnTerritory) return 'notActiveOnAllVehicles'

  return 'notActive'
}
