import { subscribe, useSnapshot } from 'valtio'
import { proxyWithComputed } from 'valtio/utils'

import { journal } from '@/server-data'
import { domainProxy } from '@/store'

interface AccountData {
  timestamp: number
  token: string | null
  username: string
}
type AccountId = string
type AccountDataMap = Record<AccountId, AccountData>

interface AccountDataAtom {
  accounts: AccountDataMap
}
interface ComputedData {
  autoLoginAccountData: AccountData | undefined
  lastLoginAccountData: AccountData | undefined
}

// ------------------------------------
// Restore from Local Storage
// ------------------------------------

const localStorageId = 'routemanager/v1/atoms/accountsData'

const initialAccountsData = JSON.parse(
  localStorage.getItem(localStorageId) ?? '{}',
) as AccountDataMap

// ------------------------------------
// Account Data atom
// ------------------------------------

export const accountsDataAtom = proxyWithComputed<AccountDataAtom, ComputedData>(
  {
    accounts: initialAccountsData,
  },
  {
    autoLoginAccountData: snap =>
      Object.values(snap.accounts).find(account => account.token !== null),

    // Please leave that method in that form, otherwise eslint-plugin-valtio will start screaming for IDK reason
    // route-manager: Oops! Something went wrong! :(
    // route-manager: ESLint: 7.32.0
    // route-manager: TypeError: Cannot read property 'type' of undefined
    lastLoginAccountData: snap => {
      const accounts = Object.values(snap.accounts)
      const sortedAccounts = accounts.sort((a: AccountData, b: AccountData) => {
        return a.timestamp - b.timestamp
      })

      return sortedAccounts.slice(0, 1)[0]
    },
  },
)

// ------------------------------------
// Write to Local Storage
// ------------------------------------

subscribe(accountsDataAtom.accounts, () => {
  localStorage.setItem(localStorageId, JSON.stringify(accountsDataAtom.accounts))
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetAccountsData = (prev: AccountDataMap) => AccountDataMap
type SetAccountsDataParam = SetAccountsData | Partial<AccountDataMap> | 'reset'

export function resetAccountsData() {
  for (const accountId in accountsDataAtom.accounts) {
    delete accountsDataAtom.accounts[accountId]
  }
}

export function setAccountsData(valueOrFunc: SetAccountsDataParam) {
  if (valueOrFunc === 'reset') return resetAccountsData()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(accountsDataAtom.accounts, valueOrFunc(accountsDataAtom.accounts))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      const value = valueOrFunc[field]

      if (value) {
        accountsDataAtom.accounts[field] = value
      }
    }
  }

  return accountsDataAtom
}

export function updateAccountDataToken(username: string, token: string, rememberMe?: boolean) {
  const now = Date.now()

  for (const accountId in accountsDataAtom.accounts) {
    // clean all previous tokens and remove old entries after a successful login
    if (now - accountsDataAtom.accounts[accountId].timestamp < 5 * 24 * 3600) {
      accountsDataAtom.accounts[accountId].token = null
    }
  }

  // update token and timestamp after a successful login
  accountsDataAtom.accounts[username] = {
    ...accountsDataAtom.accounts[username],
    timestamp: Date.now(),
    token: null,
  }

  // store the token only when the rememberMe flag is set to true
  if (rememberMe) {
    accountsDataAtom.accounts[username].token = token
  }
}

export function clearTokens() {
  for (const accountId in accountsDataAtom.accounts) {
    accountsDataAtom.accounts[accountId].token = null
  }

  return accountsDataAtom.accounts
}

// ---------------------------------------------------------------------------
// SUBSCRIBE METHOD
// ---------------------------------------------------------------------------

export function subscribeAccountsData() {
  return domainProxy.subscribeToNotifications(
    notification => {
      if (notification.notificationType === 'routePlanInfo') {
        const routePlanInfo = notification.payload
        const { username } = routePlanInfo

        setAccountsData(prevValue => {
          const updatedData = { ...prevValue }

          if (!updatedData[username]) {
            journal.atoms('Trying to set data for an unknown user', { info: { username } }, 'warn')
            return prevValue
          }

          updatedData[username] = {
            ...updatedData[username],
            username,
          }

          journal.atoms(`Active RoutePlan info updated`, { info: notification.payload })
          return updatedData
        })
      }
    },
    ['routePlanInfo'],
  )
}

// ---------------------------------------------------------------------------
// REACT HOOKS
// ---------------------------------------------------------------------------

export function useSelectAutoLoginAccountData() {
  return useSnapshot(accountsDataAtom).autoLoginAccountData
}

export function useSelectLastLoginAccountData() {
  return useSnapshot(accountsDataAtom).lastLoginAccountData
}

export function useSelectAccountsData() {
  return useSnapshot(accountsDataAtom).accounts
}
