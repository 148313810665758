import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useSendMessageToDriverModal } from '@/components/modals/SendMessageToDriverModal'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectCalendarRange } from '@/features/domain/ui'
import { useDriverAssignmentsByDate } from '@/hooks'

import { useDepotsNavigator } from '../../../../../hooks/useDepotsNavigator'
import { useTexts } from '../useTexts'

export const useShareLocation = () => {
  const texts = useTexts()
  const { currentDepots } = useDepotsNavigator()
  const { today } = useSelector(selectUserConfiguration)
  const vehicleAssignments = useDriverAssignmentsByDate(today)
  const { minDate, maxDate } = useSelector(selectCalendarRange)
  const { show: showSendMessageToDriver, Modal: SendMessageToDriver } =
    useSendMessageToDriverModal()

  const canBeShown = useMemo(() => {
    const todaySelected = minDate === maxDate && minDate === today
    const atLeastOneAssignment = Object.keys(vehicleAssignments).length > 0

    if (!todaySelected) return { result: false, reason: texts.disabledNoToday }
    if (!atLeastOneAssignment) return { result: false, reason: texts.disabledNoDriverAssignments }

    return { result: true }
  }, [texts, minDate, maxDate, today, vehicleAssignments])

  const modal = useMemo(
    () =>
      currentDepots.length > 0 ? (
        <SendMessageToDriver locationToShare={currentDepots[0].location.latLng} />
      ) : null,
    [SendMessageToDriver, currentDepots],
  )

  return {
    modal,
    canBeShown,
    showSendMessageToDriver,
  }
}
