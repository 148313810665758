import type { Options as IconOptions } from 'ol/style/Icon'
import { Icon, Style } from 'ol/style'

import placesSpriteSheet from '@/resources/map-markers/places.png'

import { genericStyles } from '../../genericStyles'

const placeMarkerStylesCache: Map<string, Style> = new Map()

export const getPlaceFeatureStyle = (
  marker: uui.domain.ui.map.markers.Place,
  mode: uui.domain.ui.map.markers.MapStyles['place']['mode'],
  selected: boolean = false,
) => {
  if (mode === 'off') return genericStyles.hidden

  const coordinates = selected ? marker.gridSelected : marker.grid

  const spriteSheetUrl = placesSpriteSheet
  const cellSize = coordinates[2]
  const x = coordinates[0]
  const y = coordinates[1]

  const cacheId = `${x}_${y}`

  if (placeMarkerStylesCache.has(cacheId)) {
    const cachedStyle = placeMarkerStylesCache.get(cacheId)

    if (cachedStyle) return cachedStyle
  }

  const opts: IconOptions = {
    anchor: [0.5, 0.5],
    anchorOrigin: 'top-left',
    anchorYUnits: 'fraction',
    anchorXUnits: 'fraction',
  }

  const style = new Style({
    image: new Icon({
      src: spriteSheetUrl,
      crossOrigin: 'anonymous',

      scale: 0.5,
      opacity: 1.0,
      rotation: 0.0,
      rotateWithView: true,

      size: [cellSize, cellSize],
      offset: [x, y],

      ...opts,
    }),
  })

  placeMarkerStylesCache.set(cacheId, style)

  return style
}
