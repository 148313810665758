import type {
  FormError,
  FormField,
  FormSingleField,
  UseFormFieldOptions,
  NarrowFieldsByValueType,
} from '@workwave-tidal/tidal/form-fairy'
import type { BulkTags as BulkTagsType } from '../../../types'

import { useCallback } from 'react'
import { produce } from 'immer'
import { Dialog, IconButton, FormHelperText, Stack, Tooltip, Box } from '@mui/material'
import { Add } from '@mui/icons-material'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'

import { FieldHeader } from '../../FieldHeader'

import { ExactList } from './components/ExactList'
import { PartialList } from './components/PartialList'
import { AddTag } from './components/AddTag'
import { Menu } from './components/Menu'
import { useSplitAndSortTagByType } from './hooks/useSplitAndSortTagByType'
import { useDialogState } from './hooks/useDialogState'
import { useTexts } from './hooks/useTexts'
import { useAddTagOptions } from './hooks/useAddTagOptions'

type RequiredFormField = BulkTagsType

type Props<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
> = {
  label: string
  addTagLabel: string
  name: FIELD_NAME
  helperText?: string
  testId?: string
} & UseFormFieldOptions<FIELDS, ERROR>

export function BulkTags<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
>(props: Props<FIELD_NAME, FIELDS, ERROR>) {
  const { helperText, label, name, addTagLabel, testId, ...options } = props

  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const {
    field,
    errors,
    fieldApi: { change: apiChange, validate },
  } = useFormField<FIELD_NAME, PartialForm, FormError<FIELD_NAME>>(
    name,
    options as UseFormFieldOptions<PartialForm, FormError<FIELD_NAME>>,
  )

  const { visible, value: fieldValue, status } = field

  const { exact, partial } = useSplitAndSortTagByType(fieldValue)
  const { open: dialogOpen, onClose: onCloseDialog, onOpen: onOpenDialog } = useDialogState()

  // Resolve the field meta states to improve code readability
  const fieldInvalid = status === 'invalid'
  const fieldIndeterminate = status === 'indeterminate'
  const fieldHasError = fieldInvalid || (fieldIndeterminate && errors.length > 0)

  const errorText = fieldHasError ? errors[0]?.message ?? 'Unknown Error' : undefined

  const bannedTagCategory = name === 'tagsIn' ? 'tagsOut' : 'tagsIn'

  const texts = useTexts()

  const addTagOptions = useAddTagOptions(fieldValue, bannedTagCategory)

  const onAddTag = useCallback(
    (tagName: string) => {
      const updatedFieldValue = produce(fieldValue, draft => {
        draft.remaining -= 1
        draft.tags[tagName] = {
          name: tagName,
          status: 'exact',
        }
      })
      apiChange(updatedFieldValue)
      validate()
    },
    [validate, apiChange, fieldValue],
  )

  const onDeleteTag = useCallback(
    (tagName: string) => {
      const updatedFieldValue = produce(fieldValue, draft => {
        draft.remaining += 1
        draft.tags[tagName] = {
          name: tagName,
          status: 'deleted',
        }
      })
      apiChange(updatedFieldValue)
      validate()
    },
    [validate, apiChange, fieldValue],
  )

  const onDeleteAllTags = useCallback(() => {
    const updatedFieldValue = produce(fieldValue, draft => {
      for (const tag of Object.values(draft.tags)) {
        if (tag.status !== 'deleted') {
          draft.tags[tag.name] = {
            name: tag.name,
            status: 'deleted',
          }
          draft.remaining += 1
        }
      }
    })
    apiChange(updatedFieldValue)
    validate()
  }, [validate, apiChange, fieldValue])

  const onAddTagAndCloseDialog = useCallback(
    (tagName: string) => {
      onAddTag(tagName)
      onCloseDialog()
    },
    [onAddTag, onCloseDialog],
  )

  if (!visible) return null

  const noTags = addTagOptions.length === 0
  const limitReached = fieldValue.remaining === 0

  const deleteAllTagsDisabled =
    Object.values(fieldValue.tags).filter(t => t.status !== 'deleted').length === 0

  const disableTooltipReason = noTags ? 'noTags' : limitReached ? 'limitReached' : undefined

  return (
    <Stack data-testid={testId}>
      <FieldHeader
        label={label}
        action1={
          <Tooltip title={texts.addTagTooltip(disableTooltipReason)}>
            {/* A disabled element does not fire events. */}
            {/* Tooltip needs to listen to the child element's events to display the title. */}
            <span>
              <IconButton
                onClick={onOpenDialog}
                disabled={noTags || limitReached}
                data-testid="bulkTags-add-btn"
              >
                <Add />
              </IconButton>
            </span>
          </Tooltip>
        }
        action2={
          <Menu
            onDeleteAllTags={onDeleteAllTags}
            deleteAllTagsDisabled={deleteAllTagsDisabled}
            data-testid="bulkTags-more-btn"
          />
        }
      />
      <ExactList tags={exact} onDeleteTag={onDeleteTag} bannedTagCategory={bannedTagCategory} />
      <PartialList
        tags={partial}
        onAddTag={onAddTag}
        onDeleteTag={onDeleteTag}
        ordersCount={fieldValue.affectedOrdersCount}
        limitReached={limitReached}
        bannedTagCategory={bannedTagCategory}
      />
      <Box hidden={fieldHasError ? !errorText : !helperText} paddingBottom={2}>
        <FormHelperText error={fieldHasError}>
          {fieldHasError ? errorText : helperText}
        </FormHelperText>
      </Box>
      <Dialog open={dialogOpen} onClose={onCloseDialog} transitionDuration={{ exit: 100 }}>
        <AddTag
          title={addTagLabel}
          options={addTagOptions}
          onAddTag={onAddTagAndCloseDialog}
          onClose={onCloseDialog}
          bannedTagCategory={bannedTagCategory}
          testId={`${testId}-add-tag`}
        />
      </Dialog>
    </Stack>
  )
}
