import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function BreadcrumbStop(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 26 26">
        <path d="M7.4 7h11.2c.22 0 .4.18.4.4v11.2a.4.4 0 01-.4.4H7.4a.4.4 0 01-.4-.4V7.4c0-.22.18-.4.4-.4z" />
      </svg>
    </IconContainer>
  )
}
