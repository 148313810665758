import { IconButton } from '@/components/primitives/buttons'
import { Tooltip } from '@/components/primitives/Tooltip'
import { useShowMapTrafficTiles } from '@/map'
import { Traffic, NoTraffic } from '@/icons'

import { useStyles } from '../hooks/useStyles'
import { useTexts } from '../useTexts'

export function LiveTrafficToggle() {
  const [active, , toggleLiveTraffic] = useShowMapTrafficTiles()
  const classes = useStyles()
  const texts = useTexts()

  const testid = 'map-controls-live-traffic'

  return (
    <Tooltip placement="bottom" title={active ? texts.liveTrafficOff : texts.liveTrafficOn}>
      <IconButton
        onClick={toggleLiveTraffic}
        className={classes.button}
        data-testid={testid}
        data-trackid={testid}
      >
        {active ? (
          <Traffic size={18} color="$nightRider" />
        ) : (
          <NoTraffic size={18} color="$nightRider" />
        )}
      </IconButton>
    </Tooltip>
  )
}
