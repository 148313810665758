import { ReactElement } from 'react'

import { BaseButton } from './BaseButton'
import { IconContainer } from './IconContainer'

import { Props } from './typings'

const FieldButton = (props: Props): ReactElement => {
  const { Icon, children, useFullWidth, ref, ...buttonProps } = props

  return (
    <BaseButton useFullWidth={useFullWidth} {...buttonProps}>
      {!!Icon && <IconContainer>{Icon}</IconContainer>}
      {children}
    </BaseButton>
  )
}

export default FieldButton
