import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    delete: (count: number) =>
      translate({
        id: 'settings.users.view.deleteUserConfirmationModal.controls.send',
        values: { count },
      }),

    cancel: (count: number) =>
      translate({
        id: 'settings.users.view.deleteUserConfirmationModal.controls.cancel',
        values: { count },
      }),

    deleteUserSuccess: (count: number) =>
      translate({
        id: 'settings.users.view.deleteUserConfirmationModal.toast.success',
        values: { count },
      }),

    deleteUserError: (count: number) =>
      translate({
        id: 'settings.users.view.deleteUserConfirmationModal.toast.error',
        values: { count },
      }),
  }))

  return api
}
