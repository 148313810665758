import type { FormFields, FormErrors } from '../../../formFields'

import { BulkEligibility } from '../../primitives/BulkEligibility'
import { useTexts } from './hooks/useTexts'

export function Eligibility() {
  const texts = useTexts()

  return (
    <BulkEligibility<'eligibility', FormFields, FormErrors>
      name="eligibility"
      label={texts.eligibility}
      validateOn="change"
      testId="eligibility"
    />
  )
}
