import { IconButton, Stack, Typography, useTheme } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Tooltip } from '@/components/primitives/Tooltip'
import { useTexts } from '../hooks/useTexts'
import { ViewUsersActions } from '../ViewUsersActoins/ViewUsersActions'
import { useActions } from './hooks/useActions'

type Props = {
  count: number
}

export function ViewUsersHeader(props: Props) {
  const { count } = props
  const texts = useTexts()
  const theme = useTheme()
  const { onCloseUserDetails } = useActions()

  return (
    <Stack bgcolor={theme.palette.grey['100']} padding={2} borderBottom="1px solid #eaeaea">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography component="h1" variant="h6" fontWeight={600}>
          {texts.title(count)}
        </Typography>
        <Tooltip placement="bottom" title={texts.tooltips.close}>
          <IconButton onClick={onCloseUserDetails}>
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>
      <ViewUsersActions />
    </Stack>
  )
}
