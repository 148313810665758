import type Feature from 'ol/Feature'

import { Stroke, Style } from 'ol/style'

import Polygon from 'ol/geom/Polygon'
import Geometry from 'ol/geom/Geometry'
import LineString from 'ol/geom/LineString'

import { drawStyleCommon } from './common'

const common = drawStyleCommon()

type Color = [number, number, number, number]

export function createMainOutline(mainColor: Color) {
  return new Style({
    stroke: new Stroke({
      color: mainColor,
      width: common.lineWidth,
    }),
    geometry: (feature: Feature<Geometry>) => {
      // return the coordinates of the first ring of the polygon
      const geometry = feature?.getGeometry()

      if (geometry instanceof Geometry && geometry.getType() === 'Polygon') {
        const poly = geometry as Polygon
        return new LineString(poly.getCoordinates().at(0) ?? [])
      }
    },
  })
}
