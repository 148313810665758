import { Link } from '@/routing'

import { useTexts } from '../useTexts'
import { MainLink } from './MainLink'

type SetupRoutes = uui.routing.Setup

export function GpsSetupSidebar() {
  const texts = useTexts()

  return (
    <>
      <Link<SetupRoutes> to="devices">
        <MainLink fontSize="$m" text={texts.devices} testid="gps-setup__devices-button" />
      </Link>

      <Link<SetupRoutes> to="places">
        <MainLink fontSize="$m" text={texts.places} testid="gps-setup__places-button" />
      </Link>

      <Link<SetupRoutes> to="geofences">
        <MainLink fontSize="$m" text={texts.geofences} testid="gps-setup__geofences-button" />
      </Link>

      <Link<SetupRoutes> to="alerts">
        <MainLink fontSize="$m" text={texts.alerts} testid="gps-setup__alerts-button" />
      </Link>
    </>
  )
}
