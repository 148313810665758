import { useCallback } from 'react'
import { proxy, useSnapshot } from 'valtio'
import { useLocation } from 'react-router-dom'

import { generateRelativePath } from '@/routing'

interface State {
  collapsed: boolean
}

interface Status {
  state: State
}

// ------------------------------------
// Default values
// ------------------------------------
const defaultState: State = {
  collapsed: false,
}

const atom = proxy<Status>({ state: defaultState })

export const useToggleSidebar = () => {
  const collapsed = useSnapshot(atom).state.collapsed
  const toggleCollapsed = useCallback(() => {
    atom.state.collapsed = !collapsed
  }, [collapsed])

  const location = useLocation()
  const path = generateRelativePath(location.pathname)

  const canBeToggled =
    path !== 'orders' && path !== 'events' && path !== 'simulations' && path !== 'tracking'

  return {
    canBeToggled,
    toggleSidebar: toggleCollapsed,
    isSidebarOpen: !collapsed,
  }
}
