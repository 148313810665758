import styled from 'styled-components'

import { HeaderContainer } from './HeaderContainer'

export const FooterContainer = styled(HeaderContainer)`
  flex-wrap: nowrap;
  overflow: hidden;
  height: 22px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`

FooterContainer.displayName = 'FooterContainer'
