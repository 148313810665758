import { useEffect } from 'react'

import { fetchGpsAccountData } from '@/features/domain/user'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useTexts } from './useTexts'

export function useRefreshGpsAccountData() {
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  useEffect(() => {
    const refreshAccountData = async () => {
      try {
        const thunkResult = await dispatch(fetchGpsAccountData())

        if (!fetchGpsAccountData.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }
      } catch (error) {
        toast.error(texts.errorFetchingAccountData, { autoClose: 5000 })
      }
    }

    const timer = setTimeout(() => {
      refreshAccountData()
    }, 0)

    return () => {
      clearTimeout(timer)
    }
  }, [toast, texts, dispatch])
}
