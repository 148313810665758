import type { FormFields, FormErrors } from '../../formFields'

import { useEffect } from 'react'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { createValidateCompanyFromName } from './validations/createValidateCompanyFromName'

import { useTexts } from './useTexts'

export function useFormValidation() {
  const formApi = useFormApi<FormFields, FormErrors>()
  const texts = useTexts()

  useEffect(() => {
    formApi.addValidation(
      'validation-companyFromName',
      createValidateCompanyFromName(texts),
      'companyFromName',
    )

    return () => {
      formApi.removeValidation('validation-companyFromName')
    }
  }, [formApi, texts])
}
