import type { BulkLoads } from '../../../types'

type LoadCounter = Record<
  string,
  {
    cardinality: number
    // values will hold the load value for each load
    // Set is convenient because if all the loads share the same value
    // the set will have size === 1
    values: Set<number>
  }
>

export function computeLoads(orders: uui.domain.client.rm.ExtendedOrderStep[]): BulkLoads {
  const bulkLoads: BulkLoads['loads'] = {}

  const loadCounter: LoadCounter = {}

  const loadsDistribution: Record<string, string[]> = {}

  for (const order of orders) {
    const loads = order.order.loads
    loadsDistribution[order.id] = []

    for (const [load, value] of Object.entries(loads)) {
      loadCounter[load] = loadCounter[load] ?? { cardinality: 0, values: new Set<number>() }
      loadCounter[load].cardinality++
      loadCounter[load].values.add(value)
      loadsDistribution[order.id].push(load)
    }
  }

  const ordersCount = orders.length
  for (const [load, { cardinality, values }] of Object.entries(loadCounter)) {
    // all loads share the same value
    const exact = cardinality === ordersCount
    const mixed = exact && values.size > 1
    const exactValue = Array.from(values.values())[0]
    const partialValue = values.size

    bulkLoads[load] = exact
      ? {
          name: load,
          status: 'exact',
          value: `${exactValue / 100}`,
          addedTimestamp: -1,
          mixed,
        }
      : {
          name: load,
          status: 'partial',
          value: partialValue,
        }
  }

  return {
    loads: bulkLoads,
    affectedOrdersCount: orders.length,
    loadsDistribution,
  }
}
