import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    yourDevices: translate({ id: 'devices.form.list.yourDevices' }),
  }))

  return api
}
