import { useMemo } from 'react'

import { useDeviceData } from './useDeviceData'
import { useGpsVehicleData } from './useGpsVehicleData'

export function useFormInitialValue(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
): uui.domain.ui.forms.GpsVehicleFormValues {
  const deviceData = useDeviceData(unifiedVehicle)
  const gpsVehicleData = useGpsVehicleData(unifiedVehicle)

  return useMemo(
    () => ({
      device: deviceData,
      gps: gpsVehicleData,
    }),
    [deviceData, gpsVehicleData],
  )
}
