import type { FormFields, FormErrors } from '../../formFields'

import { useCallback } from 'react'
import { useFormApi } from '@workwave-tidal/tidal/form-fairy'

import { changePassword } from '@/features/domain/user'
import { useAppDispatch } from '@/store'
import { useIsUnmounted, useNotification } from '@/hooks'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../components/Footer/hooks/useTexts'

const WRONG_OLD_PASSWORD_ERROR_CODE = 1400

export function useOnSubmit(setFirstSubmitDone: (done: boolean) => void) {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const isUnmounted = useIsUnmounted()
  const { update, close } = useController()
  const formApi = useFormApi<FormFields, FormErrors>()

  return useCallback(async () => {
    try {
      formApi.setSubmitting(true)
      setFirstSubmitDone(true)

      update({ status: 'submitting' })

      const valid = await formApi.validate()

      if (!valid) {
        formApi.setSubmitting(false)
        update({ status: 'ready' })
        return
      }

      const formValues = formApi.getValues()
      const result = await dispatch(changePassword(formValues))

      if (changePassword.rejected.match(result)) {
        const message =
          result.payload?.type === 'rpcFailure' &&
          result.payload.error.errorCode === WRONG_OLD_PASSWORD_ERROR_CODE
            ? texts.changePasswordWrongOldPasswordError
            : result.payload?.message ?? 'Internal error'

        throw new Error(message)
      }

      toast.success(texts.changePasswordSuccess)

      if (isUnmounted()) return

      // Clear FormState submitting mode
      formApi.setSubmitting(false)
      update({ status: 'ready' })

      close?.()
    } catch (e) {
      formApi.setSubmitting(false)
      update({ status: 'ready' })

      toast.error(e.message)
    }
  }, [formApi, dispatch, isUnmounted, setFirstSubmitDone, toast, texts, close, update])
}
