import type { FormFields, FormErrors } from './formFields'

import { Box } from '@mui/material'
import { FormValues, useFormMeta } from '@workwave-tidal/tidal/form-fairy'
import { Form, FormHeader } from '@workwave-tidal/tidal/form-ui/layout'

import { useTexts } from './hooks/useTexts'
import { useOnSubmit } from './hooks/useOnSubmit'
import { FormFooter } from './components/FormFooter'

import { WeekStartsOn } from './components/fields/WeekStartsOn'

type Props = {
  onDiscard: () => void
  updateAccount: (data: FormValues<FormFields>) => Promise<boolean>
}

export function PersonalizationForm(props: Props) {
  const { onDiscard, updateAccount } = props

  const texts = useTexts()

  const {
    meta: { submitting },
  } = useFormMeta<FormFields, FormErrors>()

  // create a submit function linked to
  // - the domain update function
  // - the form API
  // - the application editing status
  const onSubmit = useOnSubmit(updateAccount, onDiscard)

  return (
    <Box width={360} height="100%" data-testid="form__column-secondary" display="flex" paddingY={2}>
      <Form
        header={<FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />}
        footer={<FormFooter onDiscard={onDiscard} onSubmit={onSubmit} submitting={submitting} />}
        testId="rm-language-format-form"
        onEnter={onSubmit}
        fieldsSpacing={4}
        fieldsPadding="0 32px"
      >
        <WeekStartsOn />
      </Form>
    </Box>
  )
}
