import type { Coordinate } from 'ol/coordinate'
import type Feature from 'ol/Feature'

import { Stroke, Style } from 'ol/style'

import Polygon from 'ol/geom/Polygon'
import Geometry from 'ol/geom/Geometry'
import LineString from 'ol/geom/LineString'

import { drawFeatureMetadata } from '../../utils/drawFeatureMetadata'
import { drawStyleCommon } from './common'

const common = drawStyleCommon()

export const invalidTemporarySegment = new Style({
  stroke: new Stroke({
    color: common.red(1),
    width: common.lineWidth,
  }),
  zIndex: 5,
  geometry: (feature: Feature<Geometry>) => {
    // return the coordinates of the first ring of the polygon
    const geometry = feature?.getGeometry()
    if (geometry instanceof Geometry && geometry.getType() === 'Polygon') {
      const poly = geometry as Polygon

      const activeIndex = drawFeatureMetadata.getActiveIndex(feature)

      if (activeIndex > -1) {
        const sourceCoords = poly.getCoordinates().at(0) ?? []
        const coordinates: Coordinate[] = []

        // PREV
        const prevPointIndex = activeIndex - 1 > -1 ? activeIndex - 1 : sourceCoords.length - 2
        coordinates.push(sourceCoords[prevPointIndex])

        // SELECTED
        coordinates.push(sourceCoords[activeIndex])

        // NEXT
        const nextPointIndex = activeIndex + 1 < sourceCoords.length - 1 ? activeIndex + 1 : 0
        coordinates.push(sourceCoords[nextPointIndex])

        return new LineString(coordinates)
      }
    }
  },
})
