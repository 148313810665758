import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type Payload = {
  vehicleIds: string[]
}

/**
 * Clone vehicles
 *
 * @private
 */
export const cloneVehicles = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/vehicle/clone', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const result = await rpc('rpc/vehicle/clone', {
      category: 'rpc',
      type: 'rpc/vehicle/clone',
      vehicleIds: payload.vehicleIds,
    })
    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {cloneVehicles}`,
      { tags: ['rpc', 'vehicles'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Clone vehicles failed`,
      error,
    })
  }
})
