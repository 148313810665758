import { useConfigureController } from '../hooks/useController'
import { Content } from './Content'

import { FormProvider } from './FormProvider'

export function FormWrapper() {
  const { ready } = useConfigureController()

  if (!ready) return null

  return (
    <FormProvider>
      <Content />
    </FormProvider>
  )
}
