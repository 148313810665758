import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    summaryConstraints: (count: number) =>
      translate({ id: 'rm.summary.constraints', values: { count } }),

    summaryViolations: (count: number) =>
      translate({ id: 'rm.summary.violations', values: { count } }),

    violation: (violationType: uui.domain.rm.RouteStepViolationsType) => {
      switch (violationType) {
        case 'VE':
          return translate({ id: 'rm.scheduler.violations.routeStep.forcedVehicle' })
        case 'TGI':
          return translate({ id: 'rm.scheduler.violations.routeStep.tagsIn' })
        case 'TGO':
          return translate({ id: 'rm.scheduler.violations.routeStep.tagsOut' })
        case 'ELG':
          return translate({ id: 'rm.scheduler.violations.routeStep.eligibility' })
        case 'TW':
          return translate({ id: 'rm.scheduler.violations.routeStep.timeWindow' })
        case 'RGN':
          return translate({ id: 'rm.scheduler.violations.routeStep.region' })
        case 'PDM':
          return translate({ id: 'rm.scheduler.violations.routeStep.missingPaired' })
        case 'PDI':
          return translate({
            id: 'rm.scheduler.violations.routeStep.invertedPickupDelivery',
          })
        case 'OWB':
          return translate({
            id: 'rm.scheduler.violations.routeStep.outsideWorkingTimeBounds',
          })
        default:
          return violationType
      }
    },
  }))

  return api
}
