import type { AsyncThunkApiConfig } from '@/store'
import type { BestOptionOr, RouteStep, Vehicle } from '@/components/modals/FitInOrderModal/typings'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

type YYYYMMDD = string

export type FitInPayload = {
  date: BestOptionOr<YYYYMMDD>
  vehicle: BestOptionOr<Vehicle>
  step: BestOptionOr<RouteStep>
} & ({ force: true; orderStepIds: string[] } | { force: false; orderIds: string[] })

/**
 * FitIn an order by his ID.
 *
 * @private
 */
export const fitInOrderSteps = createAsyncThunk<
  // Return type of the payload creator
  uui.domain.actions.rpc.order.FitInOrderStepsActionResult,
  // First argument to the payload creator
  FitInPayload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/order/fitIn', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/order/fitIn', {
      category: 'rpc',
      type: 'rpc/order/fitIn',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    return result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {fitInOrders}`,
      { tags: ['rpc', 'order'], info: { payload, error } },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Fitting in [${payload}] Failed`,
      error,
    })
  }
})
