import type { SelectionAsRecord } from '../../typings'

import { useMemo } from 'react'
import { format } from 'date-fns/esm'

export const useSelectionData = (selection?: Date) => {
  return useMemo<SelectionAsRecord>(() => {
    const selectionAsRecord: SelectionAsRecord = {}
    if (selection) {
      selectionAsRecord[format(selection, 'yyyyMMdd')] = 1
    }

    return selectionAsRecord
  }, [selection])
}
