import type { Listener } from '@/server-data'
import { createPubSub } from '@/server-data'

type Event = {
  language: uui.domain.Language
}

const emitter = createPubSub<Event>()

export const notifyLanguageChange = (language: uui.domain.Language) => {
  emitter.notify({ language })
}

export const listenToLanguageChange = (listener: Listener<Event>) => {
  return emitter.listen(listener)
}
