import type { LayerUID } from '../../../typings'

import { useEffect } from 'react'

import { getMap } from '../../../../atoms/map/utils/getMap'
import { findLayer } from '../../../utils/findLayer'

export const useMapLayerVisible = (uid: LayerUID, visible: boolean) => {
  useEffect(() => {
    try {
      findLayer(getMap(), uid).setVisible(visible)
    } catch (e) {}
  }, [uid, visible])
}
