import styled from 'styled-components'

export const ReadonlyInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  background: ${p => p.theme.colors.$whiteSmoke};
  border-radius: 6px;
  user-select: text;
`

ReadonlyInput.displayName = 'ReadonlyInput'
