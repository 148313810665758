import { useCrudSelection } from '@/atoms'

import { useTrafficProfile } from '../../../../hooks/useTrafficProfile'

export function useTrafficAreas() {
  const trafficProfile = useTrafficProfile()
  const [trafficAreaSelection] = useCrudSelection('trafficArea')

  if (trafficAreaSelection.length < 1) {
    throw new Error('trafficAreaSelection has to have length > 1')
  }

  return trafficProfile.regions.filter(r => trafficAreaSelection.includes(r.id))
}
