import { journal } from '@/server-data'
import { domainProxy } from '@/store'
import { getMainSelection, getCrudSelection } from '@/atoms'

import { getMapSelectionContext } from '../selection/read/getMapSelectionContext'
import { clearMarkerLayers } from './core/clearMarkerLayers'
import { initializeMarkerLayers } from './core/initializeMarkerLayers'
import { updateMarkerLayerFromChangelog } from './core/updateMarkerLayerFromChangelog'
import { updateMarkerLayerFromMapStyleChangelog as updateMarkerLayerMapStyle } from './core/updateMarkerLayerMapStyle'

import { getDomainMarkerData } from './domain/getDomainMarkerData'
import { updateMarkerLayerAfterBreadcrumbTimeRangeChange } from './core/updateMarkerLayerAfterBreadcrumbTimeRangeChange'

type UnsubscribeMapMarkersChangelog = ReturnType<typeof domainProxy.subscribeToDataChangeRequest>

export async function subscribeToMapMarkersChangelog(): Promise<UnsubscribeMapMarkersChangelog> {
  try {
    // the provided callbacks will be invoked after the redux store successfully apply a `dataChangeRequest`
    // meaning the redux store can be safely accessed without risks of it being stale
    return domainProxy.subscribeToDataChangeSuccess(domainAction => {
      const mapSelectionContext = getMapSelectionContext()
      const mainSelection = getMainSelection(true)
      const crudSelection = getCrudSelection(true)

      switch (domainAction.mode) {
        case 'replace': {
          const { mapMarkers, mapStyles, extendedOrderSteps, breadcrumbTimeRange } =
            getDomainMarkerData()

          // clear marker layers
          clearMarkerLayers()

          // We pass selections for consistencies but RM doesn't support selection to be available when the active territory changes or reloads
          // regenerate marker layers from scratch
          initializeMarkerLayers(
            mapMarkers,
            mapStyles,
            extendedOrderSteps,
            mapSelectionContext,
            mainSelection,
            crudSelection,
            breadcrumbTimeRange,
          )
          break
        }

        case 'update': {
          const { summary } = domainAction
          const { mapMarkers, mapStyles, extendedOrderSteps, breadcrumbTimeRange } =
            getDomainMarkerData()

          if (summary.mapMarkers) {
            const categories = Object.keys(
              summary.mapMarkers,
            ) as uui.domain.ui.map.markers.MapMarkersCategory[]

            for (const category of categories) {
              const changelog = summary.mapMarkers[category]

              if (changelog) {
                journal.atoms(
                  `subscribeToMapMarkersChangelog - Update Map Markers category: ${category}`,
                  {
                    info: { category, changelog },
                  },
                )

                // update `category` layers
                updateMarkerLayerFromChangelog(
                  mapMarkers,
                  mapStyles,
                  extendedOrderSteps,
                  mapSelectionContext,
                  mainSelection,
                  crudSelection,
                  changelog,
                  category,
                  breadcrumbTimeRange,
                )
              }
            }
          }

          if (summary.mapStyles) {
            for (const category of summary.mapStyles) {
              journal.atoms(
                `subscribeToMapMarkersChangelog - Update Map Styles category: ${category}`,
                {
                  info: { category, mode: mapStyles[category].mode },
                },
              )

              // refresh `category` layers
              updateMarkerLayerMapStyle(
                mapMarkers,
                mapStyles,
                extendedOrderSteps,
                mapSelectionContext,
                mainSelection,
                crudSelection,
                category,
                breadcrumbTimeRange,
              )
            }
          }

          if (summary.breadcrumbTimeRangeChanged) {
            journal.atoms(`subscribeToBreadcrumbTimeRangeChanged - Update breadcrumbs`)

            updateMarkerLayerAfterBreadcrumbTimeRangeChange(
              mapMarkers,
              mapStyles,
              mapSelectionContext,
              mainSelection,
              crudSelection,
              'breadcrumbs',
              breadcrumbTimeRange,
            )

            updateMarkerLayerAfterBreadcrumbTimeRangeChange(
              mapMarkers,
              mapStyles,
              mapSelectionContext,
              mainSelection,
              crudSelection,
              'deviceEvents',
              breadcrumbTimeRange,
            )
          }
          break
        }
      }
    })
  } catch (e) {
    journal.atoms(
      `subscribeToMapMarkersChangelog - Impossible subscribing to map markers changelog`,
      { info: e },
      'error',
    )

    // rethrow the error
    throw e
  }
}
