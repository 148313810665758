import type { Validation } from '../../formFields'
import { useMemo } from 'react'
import { useTexts } from './useTexts'

const driversField = 'drivers' as const

export function useDriversValidations(): Validation[] {
  const texts = useTexts()

  return useMemo(() => {
    const validateDrivers: Validation = {
      id: 'validateDrivers',
      validator: formApi => {
        const { value: drivers } = formApi.getField(driversField)
        const errors = {}

        for (const driverId in drivers) {
          const driver = drivers[driverId]
          const { start, end } = driver

          if (start !== -1 && end !== -1) {
            if (start > end) {
              errors[`${driverId}_driver_start_end`] = {
                id: `${driverId}_driver_start_end`,
                message: texts.routeStartIncoherenceError,
                priority: 20,
                field: driversField,
              }
            } else {
              errors[`${driverId}_driver_start_end`] = null
            }
            continue
          } else {
            errors[`${driverId}_driver_start_end`] = null
          }
        }

        return errors
      },
      fields: 'drivers',
    }

    return [validateDrivers]
  }, [texts])
}
