import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function DriverAlt(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path d="M5.10645 25.9527C5.10645 25.9527 5.10645 24.3906 5.10645 24.1831C5.10645 21.8928 8.56031 19.81 12.3334 19.0777C12.3334 19.0777 13.4874 18.4553 13.1723 17.3081C12.0183 15.8518 11.7032 14.2896 11.7032 14.2896C11.4945 14.1839 11.1794 13.9764 10.9707 13.7689C10.6556 13.3499 10.2381 12.101 10.3445 11.1654C10.4468 10.3314 10.6556 10.6447 10.762 10.4372C9.92304 8.56184 10.3445 6.16576 11.2858 4.39616C13.2746 0.958657 17.4651 2.00008 18.0953 3.24897C22.0771 2.52079 21.9707 8.76931 21.3445 10.3314C21.3445 10.3314 21.6556 10.4372 21.6556 11.8936C21.5532 13.4557 20.2969 14.3913 20.2969 14.3913C20.2969 14.8104 19.7731 16.0592 18.9342 17.2064C18.2017 18.6628 19.6667 18.976 19.6667 18.976C23.4398 19.7042 26.8936 21.7871 26.8936 24.0774C26.8936 24.2848 26.8936 25.847 26.8936 25.847C26.8936 27.3074 21.2381 28.8695 16 28.8695C10.8684 28.8695 5.10645 28.3488 5.10645 25.9527Z" />
      </svg>
    </IconContainer>
  )
}
