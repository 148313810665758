import {
  Gps,
  Unplug,
  TruckIdle,
  Speed,
  TimeInterval,
  Cornering,
  TractionControl,
  Acceleration,
  Attention,
} from '@/icons'

interface Props {
  eventType: uui.domain.client.gps.wwgps.EventInfoType
  size: number
}

export function EventInfoIcon(props: Props) {
  const { eventType, size } = props

  switch (eventType) {
    case 'geofence-trigger':
      return <Gps size={size} />

    case 'device-unplug':
      return <Unplug size={size} />

    case 'idle-start':
    case 'idle-update':
    case 'idle-stop':
      return <TruckIdle size={size} />

    case 'device-speed-alert':
    case 'max-speed-threshold':
      return <Speed size={size} />

    case 'moving-outside-hours':
      return <TimeInterval size={size} />

    case 'hard-cornering':
      return <Cornering size={size} />

    case 'harsh-braking':
    case 'hard-braking':
    case 'severe-braking':
      return <TractionControl size={size} />

    case 'severe-acceleration':
    case 'harsh-acceleration':
    case 'hard-acceleration':
      return <Acceleration size={size} />

    default:
      return <Attention size={size} />
  }
}
