import { Box } from '@mui/material'
import { FormLoading } from '@/components/FormLoading'

export function LoadingTerritoriesData() {
  return (
    <Box height="100%" width={360}>
      <FormLoading />
    </Box>
  )
}
