import {
  type EventModel,
  type ResourceModel,
  type AssignmentModel,
  type EventRenderData,
} from '@bryntum/schedulerpro'
import { renderToStaticMarkup } from 'react-dom/server'

import { getMainSelection } from '@/atoms'
import { isDark } from '@/styles'
import { clsx } from '@/utils'

import { TriangleIcon } from './components/TriangleIcon'

interface Props {
  eventRecord: EventModel
  resourceRecord: ResourceModel
  assignmentRecord: AssignmentModel
  renderData: EventRenderData
}

const MIN_EVENT_SIZE_FOR_ICON = 23

const departureIcon = renderToStaticMarkup(
  <div style={{ display: 'flex', width: 20, height: 20 }}>
    <TriangleIcon facing="right" strokeWidth={5} stroke="white" fill="currentColor" />
  </div>,
)
const approvedDepartureIcon = renderToStaticMarkup(
  <div style={{ display: 'flex', width: 13, height: 13, transform: 'translateX(-3px)' }}>
    <TriangleIcon facing="right" strokeWidth={5} stroke="white" fill="currentColor" />
  </div>,
)

const arrivalIcon = renderToStaticMarkup(
  <div style={{ display: 'flex', width: 20, height: 20, transform: 'translateX(-19px)' }}>
    <TriangleIcon facing="left" strokeWidth={5} stroke="white" fill="currentColor" />
  </div>,
)

const approvedArrivalIcon = renderToStaticMarkup(
  <div style={{ display: 'flex', width: 12, height: 12, transform: 'translateX(-16px)' }}>
    <TriangleIcon facing="left" strokeWidth={5} stroke="white" fill="currentColor" />
  </div>,
)

function getDepotEventInfo(selection: readonly string[], steps: { id: string; name: string }[]) {
  const selectionSet = new Set(selection)

  const selectedSteps: { id: string; name: string }[] = []

  for (const step of steps) {
    if (!selectionSet.has(step.id)) continue
    selectedSteps.push(step)
  }

  // If all steps are selected, or none are selected, don't show any text
  if (
    selectedSteps.length === 0 ||
    (selectedSteps.length !== 1 && selectedSteps.length === steps.length)
  ) {
    return { text: '', numberOfSelectedSteps: selectedSteps.length }
  }

  // If only one step is selected, show its name
  if (selectedSteps.length === 1) {
    return { text: selectedSteps[0].name, numberOfSelectedSteps: selectedSteps.length }
  }

  // If more than one step is selected, but not all, show the number of selected steps
  return {
    text: `${selectedSteps.length}/${steps.length}`,
    numberOfSelectedSteps: selectedSteps.length,
  }
}

export function EventRenderer(props: Props) {
  const { eventRecord, renderData } = props

  const type = eventRecord.getData('atDepot')
    ? 'depot'
    : (eventRecord.getData('type') as uui.domain.client.rm.SchedulerEvent['type'])

  switch (type) {
    case 'pickup':
    case 'delivery':
      const label = eventRecord.getData('label')

      if (label.length * 6 + 4 > renderData.width) renderData.wrapperCls.add('hidden-content')
      return label

    case 'departure':
      renderData.wrapperCls.add('rm-on-top')
      return eventRecord.getData('isApproved') ? approvedDepartureIcon : departureIcon

    case 'arrival':
      renderData.wrapperCls.add('rm-on-top')
      return eventRecord.getData('isApproved') ? approvedArrivalIcon : arrivalIcon

    case 'edge':
      renderData.wrapperCls.add('rm-on-top')
      return ''

    case 'route-track':
      renderData.wrapperCls.add('rm-track')
      return ''

    case 'route-time-range':
      renderData.wrapperCls.add('rm-on-bottom')
      return ''

    case 'route-time-range-handles':
      renderData.wrapperCls.add('rm-on-top')
      return ''

    //TODO: Refactor also these three icons to a static markup
    case 'depot': {
      const steps = eventRecord.getData('steps') as { id: string; name: string }[]
      const isApproved = eventRecord.getData('isApproved')

      const selection = getMainSelection(true).orderSteps
      const { text, numberOfSelectedSteps } = getDepotEventInfo(selection, steps)
      const fontSizeClass = isApproved ? 'small-text' : 'big-text'
      const iconSizeClass = isApproved ? 'small-icon' : 'big-icon'
      const backgroundColor = eventRecord.getData('routeColor') as string
      const iconColorClass =
        numberOfSelectedSteps > 0
          ? 'white-icon'
          : isDark(backgroundColor)
          ? 'white-icon'
          : 'black-icon'

      const event = renderToStaticMarkup(
        <div className={clsx('event-icon-container', iconColorClass, iconSizeClass)}>
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1326 4.10101C12.0543 4.04134 11.9457 4.04134 11.8674 4.10101L3.08618 10.7915C3.03187 10.8329 3 10.8972 3 10.9655V19.4531C3 19.7552 3.24484 20 3.54687 20H8.30313C8.60516 20 8.85 19.7552 8.85 19.4531V16.1514C8.85 14.4117 10.2603 13.0014 12 13.0014C13.7397 13.0014 15.15 14.4117 15.15 16.1514V19.4531C15.15 19.7552 15.3948 20 15.6969 20H20.4531C20.7552 20 21 19.7552 21 19.4531V10.9655C21 10.8972 20.9681 10.8329 20.9138 10.7915L12.1326 4.10101Z" />
          </svg>
          <span className={fontSizeClass}>{'$$label$$'}</span>
        </div>,
      )

      return event.replace('$$label$$', text)
    }

    case 'idle-time': {
      if (renderData.width < MIN_EVENT_SIZE_FOR_ICON) renderData.wrapperCls.add('hidden-content')
      const isApproved = eventRecord.getData('isApproved')
      const routeColor = eventRecord.getData('routeColor')

      const color = isApproved ? '#979797' : routeColor

      return renderToStaticMarkup(
        <div className="event-icon-container event-icon-container-idle-time">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6z"
              fill={color}
            />
          </svg>
        </div>,
      )
    }

    case 'brk':
      if (renderData.width < MIN_EVENT_SIZE_FOR_ICON) renderData.wrapperCls.add('hidden-content')

      const isApproved = eventRecord.getData('isApproved')
      const routeColor = eventRecord.getData('routeColor')

      const color = isApproved ? '#979797' : routeColor

      return renderToStaticMarkup(
        <div className="event-icon-container">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20 3H4v10c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.9 2-2V5c0-1.11-.89-2-2-2zm0 5h-2V5h2v3zM4 19h16v2H4z"
              fill={color}
              stroke="white"
            />
          </svg>
        </div>,
      )

    default:
      return ''
  }
}
