import { Component } from 'react'

import { TextField } from '@/forms-legacy'

import * as OrderTexts from '../../../intl'
import { Props } from '../typings'

export default class PodNotes extends Component<Props> {
  render() {
    return <TextField testid="notes" name="formPods.note" label={OrderTexts.getNoteLabel()} />
  }
}
