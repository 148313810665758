import { getDeviceTagId } from './deviceTags'

export function updateDeviceTerritoryName(
  translate: uui.domain.intl.Translate,
  mutableDevice: uui.domain.client.gps.wwgps.DeviceInfo,
) {
  if (!mutableDevice.virtual) return

  if (!mutableDevice.territoryId) {
    mutableDevice.territoryName = translate({ id: 'global.unknownTerritory' })
  }
}

export function extractDeviceTagsFromAggregatedInfo(
  deviceAggregateInfos: Record<string, uui.domain.client.gps.wwgps.DeviceAggregateInfo>,
) {
  const result: Record<string, uui.domain.client.gps.wwgps.DeviceTag> = {}
  for (const [deviceId, deviceAggregateInfo] of Object.entries(deviceAggregateInfos)) {
    if (!deviceAggregateInfo?.gpsTags) continue

    for (const tag of deviceAggregateInfo.gpsTags) {
      const deviceTag: uui.domain.client.gps.wwgps.DeviceTag = { tagId: tag.id, deviceId }
      const deviceTagId = getDeviceTagId(deviceTag)
      result[deviceTagId] = deviceTag
    }
  }

  return result
}

export function isUnassociatedDevice(id: string) {
  return id.endsWith('@@[VEHICLE-ID]')
}

export function getDeviceIdFromUnifiedVehicleId(id: string) {
  return id.slice(0, id.indexOf('@@[VEHICLE-ID]'))
}
