import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    updatePersonalizationSuccess: translate({ id: 'settings.personalization.edit.save.success' }),
    updatePersonalizationError: translate({ id: 'settings.personalization.edit.save.error' }),
  }))

  return api
}
