import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    delete: translate({ id: 'global.deleteAll' }),
    subtitle: translate({ id: 'gps.alerts.view.delete.subtitle' }),
    title: (selected: number) =>
      translate({
        id: 'gps.alerts.view.delete.title',
        values: { selected },
      }),
  }))

  return api
}
