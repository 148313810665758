import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectVehiclesListOptions } from '@/features/domain/lists'

import { useSetQuery } from './useSetQuery'

/**
 * Prevent searching with an empty query string when the search has not been performed yet. After
 * the first search, an empty search means returning to the un-searched list.
 */
let preventEmptyQuerySearch = true

export function useSearchList() {
  const listOptions = useSelector(selectVehiclesListOptions)
  const [query, setQuery] = useState(() => listOptions.search.query)
  const setListQuery = useSetQuery()

  const setListQueryRef = useRef(setListQuery)
  useEffect(() => void (setListQueryRef.current = setListQuery), [setListQuery])

  useEffect(() => {
    if (!query && preventEmptyQuerySearch) {
      return
    }
    preventEmptyQuerySearch = false

    const id = setTimeout(() => {
      setListQueryRef.current(query)
    }, 300)
    return () => clearTimeout(id)
  }, [query])

  return {
    query,
    setQuery,
  }
}
