import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Breadcrumbs(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path
          d="M1.8773875,3.50099905 C1.42919578,3.47847604 1,3.83893825 1,4.363639 L1,11.3860669 C1,12.0856679 1.76248567,12.4940477 2.31608133,12.0903322 L7.13192777,8.5791176 C7.60285983,8.2357869 7.60285983,7.51391944 7.13192777,7.17058874 L2.31608133,3.65937431 C2.17768241,3.55844552 2.02678476,3.50850669 1.8773875,3.50099905 Z M9.39226052,3.50099905 C8.9440693,3.47847604 8.51487319,3.83893825 8.51487319,4.363639 L8.51487319,11.3860669 C8.51487319,12.0856679 9.27735894,12.4940477 9.8309546,12.0903322 L14.646801,8.5791176 C15.117733,8.2357869 15.117733,7.51391944 14.646801,7.17058874 L9.8309546,3.65937431 C9.69255568,3.55844552 9.54165786,3.50850669 9.39226052,3.50099905 Z"
          transform="translate(8.000000, 7.875000) rotate(180.000000) translate(-8.000000, -7.875000) "
        ></path>
      </svg>
    </IconContainer>
  )
}
