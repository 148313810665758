import { useCallback } from 'react'

import { useEditingStateWithCtx, setCrudSelection } from '@/atoms'
import { setPendingFitMap } from '@/map'
import { useNavigate } from '@/routing'

import { useDepotsNavigator } from '../../../../../hooks/useDepotsNavigator'

export const useActions = () => {
  const navigate = useNavigate(true)
  const { currentDepots, currentDepotIds } = useDepotsNavigator()
  const { setEditing } = useEditingStateWithCtx('depot')
  const { setEditing: setEditingGeofences } = useEditingStateWithCtx('geofence')

  const goToNewGeofence = useCallback(() => {
    setEditingGeofences([])
    navigate('geofences')
  }, [navigate, setEditingGeofences])

  const goToEdit = useCallback(() => {
    setCrudSelection('depots', currentDepotIds)

    setEditing(currentDepotIds)

    // queue a pending fitMap request
    // it will be applied by the destination view when it opens
    const latLngCoordinates = currentDepots.map(depot => depot.location.latLng)
    setPendingFitMap(latLngCoordinates)

    navigate('depots')
  }, [navigate, setEditing, currentDepotIds, currentDepots])

  return {
    currentDepots,
    currentDepotIds,
    actions: {
      goToEdit,
      goToNewGeofence,
    },
  }
}
