import { useMemo } from 'react'

import { Stack, H4, FlexBox } from '@/local/components'

import { useController } from '../../hooks/useController'

import { NotificationContent } from './NotificationContent'
import { NotificationSubject } from './NotificationSubject'
import { useTexts } from './useTexts'

export function Body() {
  const { data } = useController()
  const { bodyPlainText } = data

  const texts = useTexts()
  const rows = useMemo(() => bodyPlainText.split('\n'), [bodyPlainText])

  return (
    <FlexBox column w="100%" padding="20px 0 0">
      <Stack space="s">
        <H4 color="$paleSky" uppercase>
          {texts.bodyTitle}
        </H4>
        {data.kind === 'EMAIL' && <NotificationSubject data={data} />}
        <NotificationContent rows={rows} />
      </Stack>
    </FlexBox>
  )
}
