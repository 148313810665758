import type { RoadSegmentFormValues } from './types'

import { useState } from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { usePendingFitMapOnMount } from '@/map'
import { FormColumn } from '@/components/layout'
import { FormLoading } from '@/components/FormLoading'
import { useFormTransaction, useStopEditingOnUnmount, useReadOnly } from '@/hooks'
import { useEditingStateWithCtx, useEnvironmentQa } from '@/atoms'
import { SecondaryColumnHeader, FormContent } from '@/forms-legacy'

import { useSegmentType } from '../../hooks/useSegmentType'

import { Footer } from './Footer'
import { Form } from './Form/Form'
import { useTexts } from './hooks/useTexts'
import { useCreateIsSegmentOutsideOfTerritory } from './hooks/useCreateIsSegmentOutsideOfTerritory'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useValidateForm } from './hooks/useValidateForm'

import { computeFormInitialValues } from './utils/computeFormInitialValues'
import { useComposeFieldsWithError } from './hooks/useComposeFieldsWithError'

interface Props {
  roadSegment: uui.domain.client.rm.SegmentException
  creating?: boolean
}

export function SingleEdit(props: Props) {
  const { roadSegment, creating } = props
  const { onCreate, onUpdate, submitting } = useOnSubmit()
  const { resetEditing } = useEditingStateWithCtx('region')
  const { readonly } = useReadOnly()

  const segmentType = useSegmentType(roadSegment)

  const [initialValues] = useState<RoadSegmentFormValues>(() =>
    computeFormInitialValues(roadSegment, segmentType),
  )

  const validateForm = useValidateForm()
  const composeFieldsWithError = useComposeFieldsWithError()
  const isSegmentOutsideOfTerritory = useCreateIsSegmentOutsideOfTerritory()

  const texts = useTexts()

  const qa = useEnvironmentQa()

  // When the Edit form mounts
  // try to apply pending fitMap requests
  usePendingFitMapOnMount()

  useStopEditingOnUnmount()

  const transactionState = useFormTransaction()
  const transactionOpen = transactionState === 'ready'

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <FinalForm<RoadSegmentFormValues>
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={creating ? onCreate : onUpdate}
        render={(formRenderProps: FormRenderProps<RoadSegmentFormValues>) => {
          const { handleSubmit, form } = formRenderProps

          const {
            valid,
            validating,
            pristine: rawPristine,
            errors = {},
            values: { name, editableSegment },
          } = form.getState()

          const pristine = creating ? false : rawPristine

          const fieldsWithError = composeFieldsWithError(errors)

          const outOfTerritory = isSegmentOutsideOfTerritory(editableSegment)

          const saveDisabled =
            !valid || outOfTerritory || validating || readonly || submitting || pristine
          const footerMessages = editableSegment ? [] : texts.creatingLine

          return (
            <>
              <SecondaryColumnHeader
                description={texts.headerDescription(segmentType)}
                title={texts.headerTitle(name)}
                avatar={null}
              />
              <FormContent
                qa={qa}
                testId="roadSingleEdit"
                footer={
                  transactionOpen ? (
                    <Footer
                      fieldsWithError={fieldsWithError}
                      saveDisabled={saveDisabled}
                      message={footerMessages}
                      planLocked={readonly}
                      onSubmit={handleSubmit}
                      onCancel={resetEditing}
                      submitting={submitting}
                      creating={creating}
                      pristine={pristine}
                      errors={errors}
                      valid={valid}
                    />
                  ) : undefined
                }
              >
                {transactionOpen ? (
                  <Form formRenderProps={formRenderProps} outOfTerritory={outOfTerritory} />
                ) : (
                  <FormLoading />
                )}
              </FormContent>
            </>
          )
        }}
      />
    </FormColumn>
  )
}
