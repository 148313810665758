import { useCloseModal, useShowModal } from '@/atoms'

import { modalId } from './hooks/useController'
import { Root } from './components/Root'

export const useExportOrderModal = () => ({
  show: useShowModal(modalId),
  close: useCloseModal(),
  Modal: Root,
})
