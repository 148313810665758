import { styled } from '@mui/material'

export const TransparentButton = styled('button', { name: 'AlertDeviceListItemWrapper' })({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  height: '100%',
  background: 'transparent',
  padding: '0',
  outline: '0',
  margin: '0',
  borderWidth: '0',
  borderRadius: '0',
  cursor: 'pointer',
})
