import { PureComponent, KeyboardEvent, createRef, RefObject } from 'react'
import styled from 'styled-components'

type Form = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
  className?: string
}

export interface Props extends Form {
  onContentHeightChange?: (contentHeight: number) => void
}

const form = styled.form<Form>``
const Form = styled(form).attrs<Form>(() => ({
  className: 'o-form-layout',
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  padding: 4px 36px;
`
Form.displayName = 'FormLayout'

export default class FormLayout extends PureComponent<Props> {
  private readonly ref: RefObject<HTMLFormElement>
  private contentHeight: number = 0
  private readonly observer: MutationObserver

  constructor(props: Props) {
    super(props)
    this.ref = createRef()

    this.observer = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((_: MutationRecord) => {
        this.refreshContentHeight()
      })
    })
  }

  componentDidMount() {
    this.observeHeight()
    this.refreshContentHeight()
  }

  componentWillUnmount() {
    this.observer.disconnect()
  }

  private observeHeight() {
    if (this.ref.current) {
      this.observer.observe(this.ref.current, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true,
      })
    }
  }

  private refreshContentHeight() {
    const { onContentHeightChange } = this.props

    if (this.ref.current) {
      const contentHeight = this.ref.current.clientHeight

      if (this.contentHeight !== contentHeight) {
        this.contentHeight = contentHeight

        if (onContentHeightChange) {
          onContentHeightChange(contentHeight)
        }
      }
    }
  }

  private onKeyPress = (event: KeyboardEvent<HTMLFormElement>) => {
    const target: HTMLFormElement = event.target as any

    if (target.type !== 'textarea' && event.which === 13 /* Enter */) {
      event.preventDefault()
    }
  }

  render() {
    const { children, ref, onContentHeightChange, ...props } = this.props

    return (
      <Form {...props} onKeyPress={this.onKeyPress} ref={this.ref}>
        {children}
      </Form>
    )
  }
}
