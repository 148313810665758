import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { VerticalLayout, HorizontalLayout } from '@/components/layout'
import { selectUserAccountPreferences } from '@/features/domain/user'
import { timeUtils } from '@/server-data'
import { Text } from '@/local/components'

import { useTexts } from '../../../useTexts'

interface Props {
  start: number
  end: number
  violations: uui.domain.rm.RouteViolations
}

function checkRouteVehicleViolationByType(
  violations: uui.domain.rm.RouteViolations,
  violationType: uui.domain.rm.RouteViolationsType,
) {
  const { vehicle } = violations

  if (vehicle) {
    return vehicle.some(
      (type: uui.domain.rm.RouteViolationsType): boolean => violationType === type,
    )
  }

  return false
}

export function Regular(props: Props) {
  const { start, end, violations } = props

  const accountPreferences = useSelector(selectUserAccountPreferences)
  const texts = useTexts()

  const plannedStartTitle = `${texts.plannedStart}:`
  const plannedEndTitle = `${texts.plannedEnd}:`

  const plannedEndTitleColor = useMemo(() => {
    return checkRouteVehicleViolationByType(violations, 'TWE') ? '$outrageousRed' : '$pureBlack'
  }, [violations])

  const startTime = timeUtils.formatSecondsFromMidnight(start, accountPreferences.timeFormat)
  const endTime = timeUtils.formatSecondsFromMidnight(end, accountPreferences.timeFormat)

  return (
    <VerticalLayout
      data-trackid="navigo-routes-overview-details-single-routeStats-timeBlock"
      data-testid="navigo-routes-overview-details-single-routeStats-timeBlock"
      justifyContent="space-between"
      marginLeft={24}
      width="auto"
    >
      <HorizontalLayout height="auto">
        <Text size="$p3">{plannedStartTitle}&nbsp;</Text>

        <Text
          testid="navigo-routes-overview-details-single-routeStats-timeBlock-startTime"
          size="$p3"
          weight="$semiBold"
        >
          {startTime}
        </Text>
      </HorizontalLayout>

      <HorizontalLayout height="auto">
        <Text size="$p3">{plannedEndTitle}&nbsp;</Text>

        <Text
          testid="navigo-routes-overview-details-single-routeStats-timeBlock-endTime"
          color={plannedEndTitleColor}
          weight="$semiBold"
          size="$p3"
        >
          {endTime}
        </Text>
      </HorizontalLayout>
    </VerticalLayout>
  )
}
