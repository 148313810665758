import { ModalFooterButton } from '@/components/Modal'

import { useControllerActions } from '../../../hooks/useControllerActions'
import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

export function ComposeMessageFooter() {
  const texts = useTexts()
  const { dispatchSendMessage, goToChooseRecipient } = useControllerActions()

  const {
    close,
    invalid,
    data: { locationToShare },
  } = useController()

  return (
    <>
      <ModalFooterButton
        testid="send-message-to-driver-modal-send-button"
        variant="contained"
        onClick={dispatchSendMessage}
        disabled={invalid}
      >
        {texts.send}
      </ModalFooterButton>

      {locationToShare && (
        <ModalFooterButton
          testid="send-message-to-driver-modal-back-button"
          onClick={goToChooseRecipient}
        >
          {texts.back}
        </ModalFooterButton>
      )}

      <ModalFooterButton onClick={close} testid="send-message-to-driver-modal-close-button">
        {texts.cancel}
      </ModalFooterButton>
    </>
  )
}
