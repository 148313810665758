import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

/**
 * Export Orders by their ID.
 *
 * @private
 */
export const exportOrders = createAsyncThunk<
  // Return type of the payload creator
  string,
  // First argument to the payload creator
  uui.domain.actions.rpc.order.ExportOrdersAction['payload'],
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/order/export', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/order/export', {
      category: 'rpc',
      type: 'rpc/order/export',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    return result.url
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {exportOrders}`,
      { tags: ['rpc', 'order'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Exporting ${payload.orderIds.join(' ,')} failed`,
      error,
    })
  }
})
