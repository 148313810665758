import { PureComponent } from 'react'
import {
  Field as FinalFormField,
  FieldProps as FFieldProps,
  FieldRenderProps as FFieldRenderProps,
} from 'react-final-form'

import { isDeepEqual } from '@/server-data'
import {
  Block,
  Label,
  InnerBlock,
  FieldMessage,
  DurationStep,
  RestoreFieldButton,
  DurationStepProps,
} from '@/forms-legacy'

export type FieldProps = FFieldProps<number, any>
export type FieldRenderProps = FFieldRenderProps<number>

export interface Props extends FieldProps {
  name: string
  label: string
  info?: string
  half?: boolean
  wrappedInputProps: Omit<DurationStepProps, 'onChange' | 'value' | 'name'>
  testid?: string
}

export default class DurationStepField extends PureComponent<Props> {
  private renderField = (formProps: FieldRenderProps) => {
    const { label, name, info, half = false, wrappedInputProps, format, testid } = this.props

    const {
      input: { value, onChange },
      meta: { error, dirty, initial },
    } = formProps

    const valueToRestore = format ? format(initial || 0, name) : initial || 0

    return (
      <Block error={!!error} dirty={!!dirty} data-testid={testid}>
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={dirty}
            initial={valueToRestore}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <InnerBlock half={half}>
          <DurationStep
            {...wrappedInputProps}
            canBeNaN
            name={name}
            value={value}
            onChange={onChange}
          />
        </InnerBlock>
        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }

  render() {
    const { name, ...props } = this.props
    return <FinalFormField {...props} isEqual={isDeepEqual} name={name} render={this.renderField} />
  }
}
