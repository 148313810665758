import styled from 'styled-components'

export const SelectorLayout = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 5px 36px;
`

SelectorLayout.displayName = 'SelectorLayout'
