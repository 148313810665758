import type { VoiceEventMarker } from '../../../../atoms/voiceEventMarkers/voiceEventMarker'

import { Icon, Style } from 'ol/style'

import spriteSheet from '@/resources/map-markers/execution-events.png'

import { getVoiceEventGridPlacement } from '../../coordinates/geVoiceEventGridPlacement'
import { genericStyles } from '../../../genericStyles'

const mapStylesCache: Map<string, Style> = new Map()

export function getVoiceEventFeatureStyle(marker: workwave.DeepReadonly<VoiceEventMarker>) {
  const { visible } = marker

  if (!visible) return genericStyles.hidden

  const cacheId = `UNIQUE_CACHE_ID`

  if (mapStylesCache.has(cacheId)) {
    return mapStylesCache.get(cacheId)
  }

  const coordinates = getVoiceEventGridPlacement()

  const spriteSheetUrl = spriteSheet
  const cellSize = coordinates[2]
  const x = coordinates[0]
  const y = coordinates[1]

  const style = new Style({
    image: new Icon({
      src: spriteSheetUrl,
      crossOrigin: 'anonymous',

      scale: 1.0,
      opacity: 1.0,
      rotation: 0.0,
      rotateWithView: true,

      offset: [x, y],
      size: [cellSize, cellSize],

      anchor: [15, 1],
      anchorOrigin: 'bottom-left',
      anchorYUnits: 'pixels',
      anchorXUnits: 'pixels',
    }),
  })

  mapStylesCache.set(cacheId, style)

  return style
}
