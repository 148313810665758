import type { Validation } from '../types'

import { useTexts } from '../useTexts'

export function useSubjectCustomValidator(): Validation[] {
  const texts = useTexts()

  return [
    {
      id: 'subjectCustomRequired',
      fields: 'subjectCustom',
      validator: formApi => {
        // access the current name form-field
        const field = formApi.getField('subjectCustom')

        return {
          subjectCustomRequiredError:
            field.required && field.value.trim().length === 0
              ? {
                  id: 'subjectCustomRequiredError',
                  field: 'subjectCustom',
                  message: texts.requiredField,
                  priority: 10,
                }
              : null,
        }
      },
    },
    {
      id: 'subjectCustomMaxLength',
      fields: 'subjectCustom',
      validator: formApi => {
        // access the current name form-field
        const field = formApi.getField('subjectCustom')
        const maxLength = 50

        return {
          subjectCustomMaxLengthError:
            field.value.length > maxLength
              ? {
                  id: 'subjectCustomMaxLengthError',
                  field: 'subjectCustom',
                  message: texts.subjectTooLong(maxLength),
                  priority: 9,
                }
              : null,
        }
      },
    },
  ]
}
