import axios from 'axios'
import { useEffect } from 'react'

import { setErrorTrackingStage, setErrorTrackingServerUrl } from '@/server-data'

import { generateEnvironmentFile } from '../utils/generateEnvironmentFile'
import { environment } from '../environment'

export const useLoadExternalConfig = (configFile: string) => {
  useEffect(() => {
    let effectCleared = false

    const loadConfig = async () => {
      try {
        const response = await axios.get(configFile)

        if (effectCleared || response.status !== 200) {
          return
        }

        const core = generateEnvironmentFile(response.data)

        // set the running stage environment for error tracking
        setErrorTrackingStage(core.env)

        // set the active RM server URL for error tracking
        setErrorTrackingServerUrl(`${core.httpHost}:${core.httpPort}`)

        environment.env = {
          core,
          status: 'ready',
          urls: {
            podsBaseUrl: `${core.httpHost}/pod/`,
            serverUrl: `${core.httpHost}:${core.httpPort}`,
            importWizardTemplateBaseUrl: `${core.httpHost}/download/tmplImportExcel/`,
          },
        }
      } catch (e) {
        // JOURNAL: Log the error
        // FIXME: The application should navigate to a 50x error page
      }
    }

    loadConfig()

    return () => {
      effectCleared = true
    }
  }, [configFile])
}
