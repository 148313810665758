import { Grow, HorizontalLayout, VerticalLayout } from '@/components/layout'
import { MapControls } from '@/components/MapControls'

import { OrdersRoot } from './form/OrdersRoot'
import { Map } from './components/Map'

export function Orders() {
  return (
    <HorizontalLayout data-testid="orders-root">
      <OrdersRoot />
      <Grow xAxis yAxis>
        <VerticalLayout>
          <MapControls />
          <Map />
        </VerticalLayout>
      </Grow>
    </HorizontalLayout>
  )
}
