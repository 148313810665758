import { useFormatDateInterval } from '@/hooks'

interface Props {
  printWeekDayWhenPossible?: boolean
  extendedMonth?: boolean
  start: Date
  end: Date
}

export function FormatDateInterval(props: Props) {
  const { start, end, extendedMonth = false, printWeekDayWhenPossible = true } = props

  const value = useFormatDateInterval(start, end, printWeekDayWhenPossible, extendedMonth)

  return <>{value}</>
}
