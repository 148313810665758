import styled from 'styled-components'
import Comp from './CollapsedLayoutRenderer'

const ITEMS_SPACER = 14
const BUTTON_SIZE = 16

const StyledCollapsedLayourRenderer = styled(Comp)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  &.is-disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  .o-item {
    margin: 0;
    user-select: none;
  }

  .o-collapsed-items {
    position: relative;
    margin: 0 ${ITEMS_SPACER}px;
  }

  .o-collapsed-item__item {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    transition: 0.3s ease-out;
    transition-property: transform, opacity;
  }

  .o-collapsed-item__summary {
    transform: translateX(-20%) scale(0.8);
    opacity: 0;
    z-index: 8;
    transition: transform 0.3s ease-in-out, opacity 0.18s ease 0.1s;
  }

  .o-collapsed-item__summary.is-active {
    transform: translateX(0) scale(1);
    opacity: 1;
  }

  .is-next-2 .o-item__inner,
  .is-next-3 .o-item__inner,
  .is-prev-2 .o-item__inner,
  .is-prev-3 .o-item__inner {
    opacity: 0;
  }

  .o-collapsed-item__item.is-next-n {
    transform: translateX(50%) scale(0.4);
  }

  .o-collapsed-item__item.is-prev-n {
    transform: translateX(-50%) scale(0.4);
  }

  .o-collapsed-item__item.is-current {
    transform: translateX(0) scale(1);
    transition-timing-function: ease-in-out;
    z-index: 10;
  }

  .o-collapsed-item__item.is-prev-1 {
    transform: translateX(-20%) scale(0.8);
    z-index: 8;
  }

  .o-collapsed-item__item.is-prev-2 {
    transform: translateX(-38%) scale(0.6);
    opacity: 0;
    z-index: 6;
  }

  .o-collapsed-item__item.is-prev-2.has-prev-2 {
    opacity: 1;
  }

  .o-collapsed-item__item.is-next-1 {
    transform: translateX(20%) scale(0.8);
    z-index: 8;
  }

  .o-collapsed-item__item.is-next-2 {
    transform: translateX(38%) scale(0.6);
    opacity: 0;
    z-index: 6;
  }

  .o-collapsed-item__item.is-next-2.has-next-2 {
    opacity: 1;
  }

  .o-collapsed-item__item.is-inactive {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition-duration: 0s;
  }

  .o-collapsed-item__item--placeholder {
    opacity: 0;
    position: relative;
    visibility: hidden;
  }

  .o-collapsed-items-button {
    position: relative;
    width: ${BUTTON_SIZE}px;
    height: 100%;
    padding: 0;
    background: transparent;
    border-width: 0;
    outline: 0;
    color: gray;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
    opacity: 1;
  }

  .o-collapsed-items-button__icon-placer {
    display: block;
    width: 100%;
    transition: transform 0.3s ease;
  }

  .o-collapsed-items-button__icon-placer > * {
    width: 100%;
    display: block;
  }

  .o-collapsed-items-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .o-collapsed-items-button:hover .o-collapsed-items-button__icon-placer {
    transform: scale(1.4);
  }

  .o-collapsed-items-button.is-invisible {
    opacity: 0;
    cursor: auto;
  }

  .o-rewind-container {
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px 6px;
    transform: translateY(-60%);
  }

  .o-fast-forward-container {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 6px;
    transform: translateY(-60%) scaleX(-1);
  }

  // I don't love these selectors
  .hide-non-selected-chips .o-collapsed-item__item,
  .hide-non-selected-chips .is-prev-2.has-prev-2,
  .hide-non-selected-chips .is-prev-3.has-prev-3,
  .hide-non-selected-chips .is-next-2.has-next-2,
  .hide-non-selected-chips .is-next-3.has-next-3 {
    opacity: 0;
  }

  .hide-non-selected-chips .is-current,
  .hide-non-selected-chips .is-active {
    opacity: 1;
  }

  /* Overrides for default styles of Chip */
  .o-collapsed-item__summary .o-chip__inner {
    color: ${p => p.theme.colors.$pureWhite};
    background: ${p => p.theme.colors.$nightRider};
  }
`

StyledCollapsedLayourRenderer.displayName = 'StyledCollapsedLayourRenderer'

export default StyledCollapsedLayourRenderer
