import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    title: translate({ id: 'companies.disableModal.title' }),
    footer: {
      disable: translate({ id: 'companies.disableModal.footer.disable' }),
      cancel: translate({ id: 'companies.disableModal.footer.cancel' }),
    },
    toast: {
      success: translate({ id: 'companies.disableModal.toast.success' }),
      error: translate({ id: 'companies.disableModal.toast.error' }),
    },
    body: translate({ id: 'companies.disableModal.body' }),
  }))

  return api
}
