import type Geometry from 'ol/geom/Geometry'
import type { VoiceEventMarker } from '../../../../atoms/voiceEventMarkers/voiceEventMarker'

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { setFeatureMetadata } from '../../../featureMetadata'
import { getVoiceEventFeatureStyle } from './getVoiceEventFeatureStyle'

export function createVoiceEventFeature(
  marker: workwave.DeepReadonly<VoiceEventMarker>,
): Feature<Geometry> {
  const { id, lonLat } = marker

  const feature = new Feature({ geometry: new Point(Array.from(lonLat)) })

  // use event type as unique ID
  feature.setId(id)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setFeatureMetadata(feature, 'type', 'voiceEvents')

  // ------------------------
  // Feature Style

  const style = getVoiceEventFeatureStyle(marker)
  feature.setStyle(style)

  return feature
}
