import type Geometry from 'ol/geom/Geometry'
import Feature from 'ol/Feature'

import Polygon from 'ol/geom/Polygon'

import { setRegionFeatureMetadata } from '../regionFeatureMetadata'
import { getRegionFeatureStyle } from './getRegionFeatureStyle'

/**
 * Create a new Region Feature
 */
export function createRegionFeature(
  marker: uui.domain.ui.map.markers.Area,
  mapStyles: uui.domain.ui.map.markers.MapStyles['region'],
  selected: boolean = false,
): Feature<Geometry> {
  const { id, points } = marker

  const feature = new Feature({ geometry: new Polygon([points]) })

  // use marker ID (regionId) as unique ID
  feature.setId(id)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setRegionFeatureMetadata(feature, 'type', 'region')

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['region']['mode'] = marker.mode ?? mapStyles.mode
  setRegionFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getRegionFeatureStyle(marker, mode, selected))

  return feature
}
