import { FindOnMap, SurroundedPin, Trash } from '@/icons'
import * as gis from '@/local/server-data/utils/gis'
import { SecondaryColumnHeaderActionsRow, SecondaryColumnHeaderActionButton } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'
import { useActions } from './hooks/useActions'
import { useGeofenceData } from './hooks/useGeofenceData'

interface Props {
  event: uui.domain.client.gps.wwgps.EventInfo
  view: 'default' | 'delete'
  setView: (view: 'default' | 'delete') => void
}

export function Actions(props: Props) {
  const { event, setView } = props
  const texts = useTexts()
  const actions = useActions(event, setView)
  const geofenceData = useGeofenceData(event)

  const hasInvalidLatLng = gis.isNullIslandLatLng(event.latLng)

  return (
    <SecondaryColumnHeaderActionsRow centered>
      <SecondaryColumnHeaderActionButton
        label={texts.findOnMap}
        icon={<FindOnMap />}
        onClick={actions.onFitMap}
        testid="events-list__find-on-map"
        disabled={hasInvalidLatLng}
        title={hasInvalidLatLng ? texts.noLocationButtonTitle : ''}
      />
      {geofenceData.geofence && (
        <SecondaryColumnHeaderActionButton
          label={texts.openGeofence}
          icon={<SurroundedPin />}
          onClick={actions.onOpenGeofence}
          testid="events-list__open-geofence"
          disabled={geofenceData.geofenceNoLongerExists}
          title={geofenceData.geofenceNoLongerExists ? texts.geofenceNoLongerExists : ''}
        />
      )}
      {event.type === 'device-speed-alert' && (
        <SecondaryColumnHeaderActionButton
          label={texts.invalidateEvent}
          icon={<Trash />}
          onClick={actions.onDelete}
          testid="events-list__invalidate-event"
        />
      )}
    </SecondaryColumnHeaderActionsRow>
  )
}
