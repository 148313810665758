import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

/**
 * Create a new Driver.
 *
 * @private
 */
export const changeCalendarRange = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  uui.domain.DataRange,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('ui/calendarRange/change', async (range, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/ui/changeCalendarRange', {
      category: 'rpc',
      type: 'rpc/ui/changeCalendarRange',
      range,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {changeCalendarRange}`,
      { tags: ['rpc', 'ui', 'calendar'], info: range },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Change Calendar range Failed`,
      error,
    })
  }
})
