import { Box } from '@mui/material'

import { RouteManager } from '@workwave-tidal/icons/logos'

export function ErrorPageFooter() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ paddingBottom: 9 }}>
        <a href="/">
          <RouteManager width={350} />
        </a>
      </Box>
    </Box>
  )
}
