import { useCallback } from 'react'
import { useMachine } from '@xstate/react'

import { navigoRouteMachine } from './stateMachine'

export function useRouteNavigoMachine() {
  // ----------------------------------
  // INITIALIZATION
  // ----------------------------------
  const [stateMachine, send] = useMachine(navigoRouteMachine)

  // ----------------------------------
  // ACTIONS
  // ----------------------------------
  const actions = {
    toggleExpanded: useCallback(() => send('TOGGLE_EXPANDED'), [send]),
    goToMapStyle: useCallback(() => send('GO_TO_MAP_STYLE'), [send]),
    reset: useCallback(() => send('RESET'), [send]),
  }

  // ----------------------------------
  // RETURN
  // ----------------------------------
  return {
    actions,
    currentState: stateMachine.value,
    data: {
      expanded: stateMachine.context.expanded,
    },
  }
}
