import { type CSSProperties } from 'react'
import { type ResourceModel } from '@bryntum/schedulerpro'
import { timeUtils } from '@/server-data'
import { schedulerPreferencesAtom } from '../../../../atoms/schedulerPreferences'

interface Props {
  userConfig: uui.domain.UserConfiguration
  record: ResourceModel
}

const containerStyle: CSSProperties = { display: 'flex', flexDirection: 'column' }
const separatorStyle: CSSProperties = { width: '100%', margin: '0 0 8px 0' }

const textStyle: CSSProperties = {
  letterSpacing: '0.00714em',
  marginBottom: '0.35rem',
  fontWeight: 500,
  fontSize: '0.875rem',
}

export function RouteStartTooltip(props: Props) {
  const { record, userConfig } = props
  const routeStart = record.get(
    'routeStart',
  ) as uui.domain.client.rm.SchedulerResource['routeStart']

  const formatDateTime = timeUtils.formatDateTime(userConfig)

  const current = routeStart?.current ? formatDateTime(new Date(routeStart.current.value)) : '-'
  const approved = routeStart?.approved
    ? formatDateTime(new Date(routeStart.approved.value))
    : undefined

  const { showApprovedRoutes } = schedulerPreferencesAtom.preferences

  return (
    <div style={containerStyle}>
      <div style={textStyle}>{current}</div>
      {approved && showApprovedRoutes && (
        <>
          <hr style={separatorStyle} />
          <div style={textStyle}>{approved}</div>
        </>
      )}
    </div>
  )
}
