/**
 * Create a new inspect pin from scratch
 */
export function createInspectPin(latLng: uui.domain.LatLng): uui.domain.ui.map.InspectPin {
  return {
    latLng,
    mode: 'normal',
    status: 'loading',
  }
}
