import type { BulkTagValueExact } from '../../../../../types'

import { Grid, Chip, Tooltip } from '@mui/material'

import { useTexts } from './useTexts'
interface Props {
  onDeleteTag: (tagName: string) => void
  tags: BulkTagValueExact[]

  bannedTagCategory: 'tagsIn' | 'tagsOut'
}

export function ExactList(props: Props) {
  const { tags, onDeleteTag, bannedTagCategory } = props
  const texts = useTexts()

  if (tags.length === 0) return null

  return (
    <Grid container rowSpacing={1} columnSpacing={1} paddingBottom={2} paddingTop={1}>
      {tags.map(tag => {
        return (
          <Grid item key={tag.name}>
            <Tooltip title={tag.warning ? texts.warningTooltip(bannedTagCategory) : ''}>
              <Chip
                label={tag.name}
                color={tag.warning ? 'error' : undefined}
                onDelete={() => onDeleteTag(tag.name)}
              />
            </Tooltip>
          </Grid>
        )
      })}
    </Grid>
  )
}
