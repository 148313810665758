import { Text } from '@/local/components'
import { Gears } from '@/icons'

import { useController } from '../hooks/useController'
import { useActions } from '../hooks/useActions'
import { useStyles } from '../hooks/useStyles'
import { useTexts } from '../useTexts'

import { FlashingDots } from './FlashingDots'

export function Body() {
  const {
    data: { progress, canCancel, canceling },
  } = useController()
  const cancelBuild = useActions()
  const texts = useTexts()

  const [classes, styles] = useStyles(progress)

  return (
    <div className={classes.container}>
      <Gears size={60} marginBottom={30} />

      <div className={classes.titleContainer}>
        <Text size="$h1">{texts.optimizationInProgress}</Text>
        <FlashingDots dimension={6} speed="fast" />
      </div>

      <div className={classes.progressBarContainer}>
        <div
          data-trackid="buildModal-progress"
          data-testid="buildModal-progress"
          className={classes.progressBar}
          style={styles.progressBarWidth}
        ></div>
      </div>

      <Text size="$h2">{texts.buildingYourRoutes}</Text>

      {canCancel && (
        <button
          disabled={canceling}
          className={classes.cancelButton}
          onClick={cancelBuild}
          data-trackid="buildModal-cancel"
          data-testid="buildModal-cancel"
        >
          {texts.cancelOptimization}
        </button>
      )}
    </div>
  )
}
