import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { renderToStaticMarkup } from 'react-dom/server'
import { toast } from 'react-toastify'

import { selectRouteMapStylesInList } from '@/features/domain/scheduler'
import { setGlobalRouteStyle } from '@/features/domain/ui'
import { loadingStateAtom } from '@/atoms'
import { store } from '@/store'

import { VisibilityCell, VisibilityHeader } from '../../../components/cells/VisibilityCell'

import { getRouteLoadingState, setRouteLoadingState } from '../../routeLoadingState'

export function createVisibilityColumn(): Partial<TemplateColumnConfig> {
  return {
    text: 'Visibility',
    type: 'template',
    align: 'center',
    field: 'routeVisible',
    width: 33,
    editor: false,
    region: 'final',
    sortable: false,
    minWidth: 33,
    maxWidth: 33,
    hideable: false,
    template: ({ record, field, value }) => {
      return renderToStaticMarkup(
        <VisibilityCell record={record as ResourceModel} field={field} value={value} />,
      )
    },
    autoWidth: false,
    draggable: false,
    resizable: false,
    groupable: false,
    headerRenderer: context => {
      const allRoutesState = getAllRoutesState()
      const loading = getRouteLoadingState(true).visibility.ALL

      const disabled = allRoutesState === 'empty' || loading

      const btn = document.createElement('button')
      btn.style.padding = '0'
      btn.style.margin = '0'
      btn.style.borderWidth = '0'
      btn.style.background = 'transparent'

      if (disabled) {
        btn.style.pointerEvents = 'none'
        btn.style.cursor = 'default'
      }

      btn.innerHTML = renderToStaticMarkup(
        <VisibilityHeader loading={loading} allRoutesState={allRoutesState} />,
      )
      btn.onclick = handleClick
      btn.disabled = disabled

      // eslint-disable-next-line no-param-reassign
      context.headerElement.innerHTML = ''
      context.headerElement.appendChild(btn)

      return
    },
  }
}

function getAllRoutesState() {
  const state = store.getState()
  const routeStyles = selectRouteMapStylesInList(state)

  const routeStates = new Set(Object.values(routeStyles))

  if (routeStates.size === 0) return 'empty'

  // In case of multiple states empty got no value
  if (routeStates.size > 1 && routeStates.has('empty')) {
    routeStates.delete('empty')
  }

  if (routeStates.size === 1) return [...routeStates][0]

  return 'mixed'
}

async function handleClick() {
  if (loadingStateAtom.isRescheduling) return

  const allRoutesState = getAllRoutesState()

  setRouteLoadingState(mutableState => {
    mutableState.visibility.ALL = true
    return mutableState
  })

  const mode = allRoutesState !== 'on' ? 'on' : 'off'

  try {
    const result = await store.dispatch(setGlobalRouteStyle(mode))

    if (setGlobalRouteStyle.rejected.match(result)) {
      throw new Error(result.payload?.message ?? 'Internal error')
    }
  } catch (e) {
    toast.error(e.message)
  }

  setRouteLoadingState(mutableState => {
    delete mutableState.visibility.ALL
    return mutableState
  })
}
