import { useCallback } from 'react'

export function useActions() {
  const onUpdate = useCallback(() => {
    window.location.href = '/'
  }, [])

  return {
    onUpdate,
  }
}
