import { isEqual, isBefore } from 'date-fns/esm'
import { useLocation } from 'react-router-dom'

const trackingPath = '/tracking'

export function useShouldRedirectToRouting(today: Date, start?: Date, end?: Date) {
  const { pathname } = useLocation()
  const isTracking = pathname.includes(trackingPath)

  const startInTheFuture = start ? isBefore(today, start) : false
  const selectedMoreThanOneDay = start && end && !isEqual(start, end)

  return isTracking && (startInTheFuture || selectedMoreThanOneDay)
}
