import { useMemo } from 'react'
import {
  type GridValueGetterParams,
  type GridColDef,
  DataGridPro,
  gridClasses,
} from '@mui/x-data-grid-pro'
import { Stack, styled } from '@mui/material'

import { type TableRow } from '../../types'
import { useTexts } from '../../../../hooks/useTexts'

import { LastPositionCell } from './components/LastPositionCell'
import { TerritoryCell } from './components/TerritoryCell'
import { VehicleCell } from './components/VehicleCell'
import { Header } from './components/Header'

type Props = {
  tenantSource: uui.domain.server.gps.telematics.TenantSource
  data: TableRow[]
  filter: string
  onChangeFilter: (search: string) => void
}

const DataGridComponent = styled(DataGridPro, { name: 'CompaniesTable', skipSx: true })(
  ({ theme }) => {
    return {
      [`&.${gridClasses.root}`]: {
        border: 'none',
      },
      ['& .no-focus-outline']: {
        outline: 'none !important',
      },
      [`& .${gridClasses.columnHeaders}`]: {
        borderColor: `${theme.palette.divider}`,
      },
      [`& .${gridClasses.cell}`]: {
        borderColor: `${theme.palette.divider}`,
      },
    }
  },
)

export function DeviceTable(props: Props) {
  const { data, filter, onChangeFilter } = props
  const texts = useTexts()
  const columns = useMemo<GridColDef<TableRow>[]>(
    () => [
      {
        disableColumnMenu: true,
        headerName: texts.integrationDevicesTable.column.name.title,
        field: 'name',
        flex: 1,
      },
      {
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => params.row.vehicle.name,
        renderCell: VehicleCell,
        headerName: texts.integrationDevicesTable.column.vehicle.title,
        field: 'vehicle',
        flex: 1,
      },
      {
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => params.row.territory.name,
        headerName: texts.integrationDevicesTable.column.territory.title,
        renderCell: TerritoryCell,
        field: 'territory',
        flex: 1,
      },
      {
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => params.row.lastActivity?.ts ?? -1,
        headerName: texts.integrationDevicesTable.column.lastActivity.title,
        renderCell: LastPositionCell,
        field: 'lastActivity',
        flex: 1,
      },
    ],
    [texts],
  )

  return (
    <Stack flex="1 1 auto">
      <Header filter={filter} onChangeFilter={onChangeFilter} />
      <DataGridComponent
        disableDensitySelector
        disableColumnSelector
        disableColumnFilter
        autoHeight
        hideFooter
        localeText={texts.integrationDevicesTable.localeTexts}
        initialState={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
        columns={columns}
        rows={data}
      />
    </Stack>
  )
}
