import { useEffect } from 'react'
import { setMainSelection, setCrudSelection, getMainSelection, getCrudSelection } from '@/atoms'

/**
 * Expose a test action that allows getting and setting the selection
 */
export function useExposeSelection(qaEnabled: boolean) {
  useEffect(() => {
    if (!qaEnabled) return

    window.testActions ??= {}
    window.testActions.selection ??= {
      setCrudSelection,
      setMainSelection,
      getMainSelection,
      getCrudSelection,
    }

    return () => void delete window.testActions?.selection
  }, [qaEnabled])
}
