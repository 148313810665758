import type { Paths } from '@/notificationForms'

import { useCallback } from 'react'
import { Box } from '@mui/material'

import { Root } from '@/notificationForms'

import { useEditingStateWithCtx } from '@/atoms'

import { NotificationsListProvider } from './forms/NotificationsListProvider/NotificationsListProvider'
import { TrackingPageProvider } from './forms/TrackingPage/TrackingPageProvider'
import { PreferencesProvider } from './forms/Preferences/PreferencesProvider'

import { NotCompletedNotification } from './forms/NotCompletedNotification'
import { AlmostThereNotification } from './forms/AlmostThereNotification'
import { CompletedNotification } from './forms/CompletedNotification'
import { TomorrowNotification } from './forms/TomorrowNotification'
import { CanceledNotification } from './forms/CanceledNotification'
import { TwoDaysNotification } from './forms/TwoDaysNotification'
import { DelayedNotification } from './forms/DelayedNotification'
import { TodayNotification } from './forms/TodayNotification'
import { EarlyNotification } from './forms/EarlyNotification'

import { useRedirectOnNotificationDisabled } from './hooks/useRedirectWhenNotificationDisabled'
import { useGetFirstActiveNotification } from './hooks/useGetFirstActiveNotification'
import { useResetEditingStateOnUnmount } from './hooks/useResetEditingStateOnUnmount'

interface Props {
  path: Paths
}

export function MessagingSettings(props: Props) {
  const { path = 'notifications' } = props

  const { editing, setEditing, resetEditing } = useEditingStateWithCtx('customerNotifications')

  const defaultNotification = useGetFirstActiveNotification()

  const setEditingState = useCallback(
    (inEditing: boolean) => {
      if (inEditing) {
        setEditing([])
      } else {
        resetEditing()
      }
    },
    [setEditing, resetEditing],
  )

  useRedirectOnNotificationDisabled()
  useResetEditingStateOnUnmount(resetEditing, path)

  return (
    <Box display="flex" width="100%" height="100%">
      <Root
        NotCompletedNotification={
          <NotCompletedNotification inEditing={editing} setEditingState={setEditingState} />
        }
        AlmostThereNotification={
          <AlmostThereNotification inEditing={editing} setEditingState={setEditingState} />
        }
        PreferencesProvider={
          <PreferencesProvider inEditing={editing} setEditingState={setEditingState} />
        }
        TrackingPageProvider={
          <TrackingPageProvider inEditing={editing} setEditingState={setEditingState} />
        }
        CompletedNotification={
          <CompletedNotification inEditing={editing} setEditingState={setEditingState} />
        }
        TomorrowNotification={
          <TomorrowNotification inEditing={editing} setEditingState={setEditingState} />
        }
        CanceledNotification={
          <CanceledNotification inEditing={editing} setEditingState={setEditingState} />
        }
        TwoDaysNotification={
          <TwoDaysNotification inEditing={editing} setEditingState={setEditingState} />
        }
        DelayedNotification={
          <DelayedNotification inEditing={editing} setEditingState={setEditingState} />
        }
        TodayNotification={
          <TodayNotification inEditing={editing} setEditingState={setEditingState} />
        }
        EarlyNotification={
          <EarlyNotification inEditing={editing} setEditingState={setEditingState} />
        }
        NotificationsList={<NotificationsListProvider inEditing={editing} />}
        defaultNotification={defaultNotification}
        path={path}
      />
    </Box>
  )
}
