import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

/**
 * Create a new Driver.
 *
 * @private
 */
export const createDriver = createAsyncThunk<
  // Return type of the payload creator
  string,
  // First argument to the payload creator
  uui.domain.client.rm.Driver,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/driver/create', async (driver, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const response = await rpc('rpc/driver/create', {
      category: 'rpc',
      type: 'rpc/driver/create',
      driver,
    })

    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }

    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {createDriver}`,
      { tags: ['rpc', 'driver'], info: driver },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Create ${driver.name} Failed`,
      error,
    })
  }
})
