import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    emailLabel: translate({
      id: 'sms4.settings.communication.notifications.testModal.body.label.email',
    }),

    send: translate({
      id: 'sms4.settings.communication.notifications.testModal.send',
    }),

    cancel: translate({
      id: 'sms4.settings.communication.notifications.testModal.cancel',
    }),

    ok: translate({
      id: 'sms4.settings.communication.notifications.testModal.ok',
    }),

    close: translate({
      id: 'sms4.settings.communication.notifications.testModal.close',
    }),

    tryAgain: translate({
      id: 'sms4.settings.communication.notifications.testModal.tryAgain',
    }),
  }))

  return api
}
