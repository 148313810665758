import styled from 'styled-components'
import { HorizontalRadioGroupColors } from './typings'

type Props = {
  disabled?: boolean
  selected?: boolean
  colors?: HorizontalRadioGroupColors
  first?: boolean
  last?: boolean
  multiline?: boolean
}

const styledComponent = styled.label<Props>`
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: ${p => p.theme.fonts.$p3};
  font-weight: ${p => p.theme.weights.$semiBold};
  cursor: pointer;

  opacity: ${p => (p.disabled ? 0.3 : 1)};
  user-select: none;

  color: ${p =>
    p.selected
      ? p.colors?.colorSelected ?? p.theme.colors.$pureWhite
      : p.colors?.color ?? p.theme.colors.$nightRider};
  background: ${p =>
    p.selected
      ? p.colors?.backgroundSelected ?? p.theme.colors.$nightRider
      : p.colors?.background ?? 'transparent'};

  height: ${p => (p.multiline ? 46 : 24)}px;
  padding: 0 10px;
  border-left-style: solid;
  border-radius: ${p => (p.first ? '4px 0 0 4px' : p.last ? '0 4px 4px 0' : '0')};
  border-left-width: ${p => p.colors?.borderSize ?? 1}px;
  border-left-color: ${p =>
    p.first ? 'transparent' : p.colors?.border ?? p.theme.colors.$nightRider};
`

styledComponent.displayName = 'HorizontalRadioLabel'
export default styledComponent
