import { computeRmData } from '../utils/computeRmData'

export function computeTelematicsFormInitialValue(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  devices: Record<string, uui.domain.client.gps.telematics.DeviceInfo>,
): uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics {
  if (unifiedVehicle.isPhysicalDevice && unifiedVehicle.trackingProvider !== 'telematics') {
    throw new Error('Invalid tracking provider')
  }

  const rm = computeRmData(unifiedVehicle)

  const device =
    unifiedVehicle.hasRoutingLicense && unifiedVehicle.vehicle.gpsDeviceId
      ? devices[unifiedVehicle.vehicle.gpsDeviceId]
      : undefined

  const tenantSourceId = device?.tenantSourceId ?? ''

  const telematics = {
    gpsTracking:
      unifiedVehicle.hasRoutingLicense && unifiedVehicle.isPhysicalDevice
        ? !!unifiedVehicle.vehicle.gpsDeviceId
        : false,
    tenantSourceId,
  }

  return {
    rm,
    telematics,
  }
}
