import type { PartialStoreState } from '../typings'

import { createSelector } from '@reduxjs/toolkit'
import { parse } from 'date-fns/esm'

export const selectCalendarRange = (state: PartialStoreState) =>
  state.domain.publicData.calendarRange

export const selectCalendarRangeInterval = createSelector(selectCalendarRange, calendarRange => {
  const now = new Date()

  return {
    start: parse(calendarRange.minDate, 'yyyyMMdd', now),
    end: parse(calendarRange.maxDate, 'yyyyMMdd', now),
  }
})

export const selectMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles

export const selectDepotMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.depot

export const selectOrderMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.order

export const selectPlaceMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.place

export const selectRegionMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.region

export const selectTrafficRegionMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.trafficProfile

export const selectRoutePolylineMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.routePolyline

export const selectRoadSegmentMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.roadSegment

export const selectVehiclesMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.vehicle

export const selectDevicesMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.device

export const selectGeofencesMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.geofence

export const selectDeviceMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.device

export const selectBreadcrumbsMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.breadcrumb

export const selectDeviceEventMapStyles = (state: PartialStoreState) =>
  state.domain.publicData.domain.mapStyles.deviceEvent
