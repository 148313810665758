import { InspectPinMode, Map as MainMap, MapLayer } from '@/map'
import { SearchOnMap } from '@/components/SearchOnMap'

interface Props {
  bottomContentPadding?: number
}

export function Map(props: Props) {
  return (
    <MainMap
      selectionContext="main"
      mapId="ww-map"
      bottomContentPadding={props.bottomContentPadding}
    >
      <InspectPinMode mode="normal" />

      <MapLayer uid="order" interactive visible />
      <MapLayer uid="place" interactive visible />
      <MapLayer uid="depot" interactive visible />
      <MapLayer uid="vehicle" interactive visible />
      <MapLayer uid="region" visible />
      <MapLayer uid="roadSegment" visible />
      <MapLayer uid="geofence" visible={false} />
      <MapLayer uid="routePolyline" visible />

      <MapLayer uid="breadcrumbPath" visible />
      <MapLayer uid="breadcrumbPoints" visible interactive />

      <SearchOnMap />
      {/* TODO: Add remaining layers once they are implemented */}
    </MainMap>
  )
}
