import { secToTimeHHmm } from './time'

const secondsInADay = 24 * 60 * 60

export const formatTimeWindow = (
  timeWindow: uui.domain.rm.TimeWindow,
  workingDayStartSec: number,
): uui.domain.rm.TimeWindow => {
  const { startSec: start, endSec: end } = timeWindow
  const endOfTheDay: number = secondsInADay + workingDayStartSec

  const startSec: number = start >= secondsInADay ? start - secondsInADay : start

  const endSec: number =
    end === endOfTheDay
      ? workingDayStartSec > 0
        ? end - secondsInADay - 1
        : endOfTheDay - 1
      : end > secondsInADay
      ? end - secondsInADay
      : end

  return {
    startSec,
    endSec,
  }
}

export const formatTimeWindows = (
  tw: uui.domain.rm.TimeWindow[],
  workingDayStartSec: number,
): uui.domain.rm.TimeWindow[] =>
  tw.map((tw: uui.domain.rm.TimeWindow) => formatTimeWindow(tw, workingDayStartSec))

export const formatTimeWindowExceptions = (
  timeWindowExceptions: Record<string, uui.domain.rm.TimeWindow[]>,
  workingDayStartSec: number,
): Record<string, uui.domain.rm.TimeWindow[]> =>
  Object.entries(timeWindowExceptions).reduce(
    (acc: Record<string, uui.domain.rm.TimeWindow[]>, [key, tws]) => {
      acc[key] = tws.map(
        (tw: uui.domain.rm.TimeWindow) => formatTimeWindow(tw, workingDayStartSec),
        workingDayStartSec,
      )
      return acc
    },
    {},
  )

export const isValid = (tw: uui.domain.rm.TimeWindow, workingDayStartSec: number): boolean => {
  const { startSec, endSec }: uui.domain.rm.TimeWindow = tw

  return (
    startSec < endSec &&
    startSec >= workingDayStartSec &&
    endSec < workingDayStartSec + secondsInADay
  )
}

export const formatTimeWindowForRPC = (
  { startSec, endSec }: uui.domain.rm.TimeWindow,
  workingDayStartSec: number,
): uui.domain.rm.TimeWindow => {
  return {
    startSec,
    endSec:
      endSec >= workingDayStartSec + secondsInADay - 60 &&
      endSec < workingDayStartSec + secondsInADay
        ? workingDayStartSec + secondsInADay
        : endSec,
  }
}

export const isTimeWindowValid = (tw: uui.domain.rm.TimeWindow, workingDayStartSec: number) => {
  const { startSec, endSec }: uui.domain.rm.TimeWindow = tw

  return startSec < endSec && startSec >= workingDayStartSec && endSec < workingDayStartSec + 86400
}

export const normalizeTimeWindow = (tw: uui.domain.rm.TimeWindow, workingDayStartSec: number) => {
  const { startSec, endSec } = tw

  const applyOvernightToStart: boolean = startSec < workingDayStartSec && startSec >= 0
  const applyOvernightToEnd: boolean =
    applyOvernightToStart || (endSec <= workingDayStartSec && endSec >= 0)

  return {
    startSec: applyOvernightToStart
      ? startSec + secondsInADay // overnight
      : startSec,
    endSec: applyOvernightToEnd
      ? endSec + secondsInADay // overnight
      : endSec,
  }
}

export const isTimeWindowEmpty = ({ startSec, endSec }: uui.domain.rm.TimeWindow) =>
  startSec === 0 && endSec === 0

export const areTimeWindowsOverlapping = (
  tw1?: uui.domain.rm.TimeWindow,
  tw2?: uui.domain.rm.TimeWindow,
): boolean => {
  if (!tw1 || !tw2) {
    return false
  }

  const { startSec: a1, endSec: a2 } = tw1
  const { startSec: b1, endSec: b2 } = tw2

  if (b1 >= a1 && b1 <= a2) {
    return true
  }

  if (a1 >= b1 && a1 <= b2) {
    return true
  }

  return false
}

export function toString(
  tw: uui.domain.rm.TimeWindow,
  timeFormat: uui.domain.client.TimeFormat = 'hh:mm aa',
  separator: string = ' - ',
): string {
  const { startSec, endSec } = tw
  return `${secToTimeHHmm(startSec, undefined, timeFormat)}${separator}${secToTimeHHmm(
    endSec,
    undefined,
    timeFormat,
  )}`
}
