import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'

import { Scrollbar } from '@workwave-tidal/tidal/components'

import { resetEditingState, resetRoutingSidebar } from '@/atoms'
import { normalizeServerColorString } from '@/local/server-data/utils/colors'
import { selectUserConfiguration } from '@/features/domain/user'

import { RouteDetailsContent } from './components/RouteDetailsContent'
import { RouteDetailsHeader } from './components/RouteDetailsHeader'
import { useRouteDetails } from './hooks/useRouteDetails'
import { useEditing } from './hooks/useEditing'

interface Props {
  routeId: string
}

const containerStyle = { background: 'white' }

export function RouteDetails(props: Props) {
  const { routeId } = props

  const routeDetails = useRouteDetails(routeId)
  const { userType } = useSelector(selectUserConfiguration)
  const { editing, onEdit, onDiscard, clearEditingState } = useEditing(routeId)

  useEffect(() => {
    return () => {
      resetEditingState()
    }
  }, [routeId])

  if (!routeDetails) {
    resetRoutingSidebar()
    return null
  }

  const {
    uv,
    routeInfo,
    editPermission,
    trackedRouteEnd,
    routeDetailsData,
    trackedRouteStart,
    endsAfterWorkingDayEnd,
    startsBeforeWorkingDayStart,
  } = routeDetails

  if (!routeInfo.approvedVersion) return null

  const idx = uv.hasRoutingLicense ? `${uv.vehicle.idx}` : '-'
  const date = routeInfo.dateAsString
  const vehicleId = uv.hasRoutingLicense ? uv.vehicleId : '-'
  const routeColor = uv.hasRoutingLicense ? normalizeServerColorString(uv.vehicle.color) : '#ffffff'
  const plannedEndTime = routeInfo.approvedVersion.routeArrivalSec
  const plannedStartTime = routeInfo.approvedVersion.routeDepartureSec

  return (
    <Stack height="100%" sx={containerStyle}>
      <RouteDetailsHeader
        idx={idx}
        onEdit={onEdit}
        editing={editing}
        userType={userType}
        routeColor={routeColor}
        violations={routeInfo.violations}
        editPermission={editPermission}
        plannedEndTime={plannedEndTime}
        trackedEndTime={trackedRouteEnd}
        violationsCount={routeInfo.violationsCount}
        plannedStartTime={plannedStartTime}
        trackedStartTime={trackedRouteStart}
        endsAfterWorkingDayEnd={endsAfterWorkingDayEnd}
        startsBeforeWorkingDayStart={startsBeforeWorkingDayStart}
      />

      <Scrollbar hideOverflow="x">
        <RouteDetailsContent
          date={date}
          editing={editing}
          onDiscard={onDiscard}
          vehicleId={vehicleId}
          routeColor={routeColor}
          routeDetailsData={routeDetailsData}
          clearEditingState={clearEditingState}
        />
      </Scrollbar>
    </Stack>
  )
}
