import type { MapSelectionContext } from '../../selection/mapSelection'
import type { CrudSelection, MainSelection } from '@/atoms'

import { connectToRenderingQueue } from '../../../renderingQueue'
import { parseOrderSelection } from '../../selection/read/parseOrderSelection'
import { getMapCrudSelection } from '../../selection/utils/getMapCrudSelection'
import { getMapMainSelection } from '../../selection/utils/getMapMainSelection'
import { createUpdateDepotFeaturesFromChangelog } from '../../../layers/depot/createUpdateDepotFeaturesFromChangelog'
import { createUpdateOrderFeaturesFromChangelog } from '../../../layers/order/createUpdateOrderFeaturesFromChangelog'
import { createUpdatePlaceFeaturesFromChangelog } from '../../../layers/place/createUpdatePlaceFeaturesFromChangelog'
import { createUpdateDeviceFeaturesFromChangelog } from '../../../layers/device/createUpdateDeviceFeaturesFromChangelog'
import { createUpdateRegionFeaturesFromChangelog } from '../../../layers/region/createUpdateRegionFeaturesFromChangelog'
import { createUpdateVehicleFeaturesFromChangelog } from '../../../layers/vehicle/createUpdateVehicleFeaturesFromChangelog'
import { createUpdateGeofenceFeaturesFromChangelog } from '../../../layers/geofence/createUpdateGeofenceFeaturesFromChangelog'
import { createUpdateBreadcrumbFeaturesFromChangelog } from '../../../layers/breadcrumb/createUpdateBreadcrumbFeaturesFromChangelog'
import { createUpdateDeviceEventFeaturesFromChangelog } from '../../../layers/deviceEvent/createUpdateDeviceEventFeaturesFromChangelog'
import { createUpdateRoadSegmentFeaturesFromChangelog } from '../../../layers/roadSegment/createUpdateRoadSegmentFeaturesFromChangelog'
import { createUpdateTrafficAreaFeaturesFromChangelog } from '../../../layers/trafficArea/createUpdateTrafficAreaFeaturesFromChangelog'
import { createUpdateRoutePolylineFeaturesFromChangelog } from '../../../layers/routePolyline/createUpdateRoutePolylineFeaturesFromChangelog'

const emptyStringSelection = new Set<string>()

const [getTicketAndRunAction] = connectToRenderingQueue()

const prepareAction = (
  markers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
  mapSelectionContext: MapSelectionContext,
  mainSelection: workwave.DeepReadonly<MainSelection>,
  crudSelection: workwave.DeepReadonly<CrudSelection>,
  changelog: uui.domain.api.Changelog,
  category: uui.domain.ui.map.markers.MapMarkersCategory,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) => {
  const crudMapSelection = getMapCrudSelection(mapSelectionContext, crudSelection)
  const mainMapSelection = getMapMainSelection(mapSelectionContext, mainSelection)

  switch (category) {
    case 'order':
      return createUpdateOrderFeaturesFromChangelog(
        markers.order,
        mapStyles.order,
        parseOrderSelection(mapSelectionContext, mainSelection.orderSteps, extendedOrderSteps),
        new Set(mainSelection.orderSteps),
        changelog,
      )

    case 'depot':
      return createUpdateDepotFeaturesFromChangelog(
        markers.depot,
        mapStyles.depot,
        mapSelectionContext.type === 'main' ? mainMapSelection.depots : crudMapSelection.depots,
        changelog,
      )

    case 'region':
      return createUpdateRegionFeaturesFromChangelog(
        markers.region,
        mapStyles.region,
        mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.regions,
        changelog,
      )

    case 'roadSegment':
      return createUpdateRoadSegmentFeaturesFromChangelog(
        markers.roadSegment,
        mapStyles.roadSegment,
        mapSelectionContext.type === 'main'
          ? emptyStringSelection
          : crudMapSelection.routingSegments,
        changelog,
      )

    case 'geofence':
      return createUpdateGeofenceFeaturesFromChangelog(
        markers.geofence,
        mapStyles.geofence,
        mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.geofences,
        changelog,
      )

    case 'trafficProfile':
      return createUpdateTrafficAreaFeaturesFromChangelog(
        markers.trafficProfile,
        mapStyles.trafficProfile,
        mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.trafficAreas,
        changelog,
      )

    case 'routePolyline':
      return createUpdateRoutePolylineFeaturesFromChangelog(
        markers.routePolyline,
        mapStyles.routePolyline,
        changelog,
      )

    case 'place':
      return createUpdatePlaceFeaturesFromChangelog(
        markers.place,
        mapStyles.place,
        mapSelectionContext.type === 'main' ? mainMapSelection.places : crudMapSelection.places,
        changelog,
      )

    case 'breadcrumb':
      return createUpdateBreadcrumbFeaturesFromChangelog(
        markers.breadcrumb,
        mapStyles.breadcrumb,
        mapSelectionContext.type === 'main' ? mainMapSelection.breadcrumbs : emptyStringSelection,
        changelog,
        breadcrumbTimeRange,
      )

    case 'device':
      return createUpdateDeviceFeaturesFromChangelog(
        markers.device,
        mapStyles.device,
        mapSelectionContext.type === 'main' ? mainMapSelection.devices : crudMapSelection.devices,
        changelog,
      )

    case 'vehicle':
      return createUpdateVehicleFeaturesFromChangelog(
        markers.vehicle,
        mapStyles.vehicle,
        mapSelectionContext.type === 'main'
          ? mainMapSelection.unifiedVehicles
          : crudMapSelection.unifiedVehicles,
        changelog,
      )

    case 'deviceEvent':
      return createUpdateDeviceEventFeaturesFromChangelog(
        markers.deviceEvent,
        mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.deviceEvents,
        changelog,
        breadcrumbTimeRange,
      )

    default:
      console.log(`Trying to update not yet implemented Marker Layer for ${category}`)
  }
}

export const updateMarkerLayerFromChangelog = (
  markers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
  mapSelectionContext: MapSelectionContext,
  mainSelection: workwave.DeepReadonly<MainSelection>,
  crudSelection: workwave.DeepReadonly<CrudSelection>,
  changelog: uui.domain.api.Changelog,
  category: uui.domain.ui.map.markers.MapMarkersCategory,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) => {
  const action = prepareAction(
    markers,
    mapStyles,
    extendedOrderSteps,
    mapSelectionContext,
    mainSelection,
    crudSelection,
    changelog,
    category,
    breadcrumbTimeRange,
  )

  if (action) {
    getTicketAndRunAction(action, `updateMarkerFromChangelog_${category}`)
  }
}
