import styled from 'styled-components'

const styledComponent = styled.div.attrs({
  className: 'o-warning-overlay__dialog-message',
})`
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
`

export default styledComponent
