import { useMemo } from 'react'

import { useRmVehicleData } from '../../hooks/useRmVehicleData'

export function useFormInitialValue(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
): uui.domain.ui.forms.RmVehicleFormValues {
  const rmVehicleData = useRmVehicleData(unifiedVehicle)

  return useMemo(
    () => ({
      rm: rmVehicleData,
    }),
    [rmVehicleData],
  )
}
