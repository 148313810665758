import { parse, format } from 'date-fns/esm'

import { TimeWindowExceptionData } from '@/forms-legacy'

const now = new Date()

export const formatField = (
  exceptions?: Record<string, uui.domain.rm.TimeWindow[]>,
): TimeWindowExceptionData[] => {
  const values = !!exceptions ? Object.entries(exceptions) : []
  return values.map(([dateAsString, list]) => {
    const date: Date = parse(dateAsString, 'yyyyMMdd', now)
    return {
      date,
      list,
    }
  })
}

export const parseField = (
  field: TimeWindowExceptionData[],
): Record<string, uui.domain.rm.TimeWindow[]> => {
  return field.reduce((acc: uui.domain.rm.TimeWindowExceptions, tw: TimeWindowExceptionData) => {
    const { date, list } = tw
    const dateAsString: string = format(date, 'yyyyMMdd')
    acc[dateAsString] = list
    return acc
  }, {})
}
