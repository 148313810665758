function seconds(val: number) {
  return val * 60
}

export function computeIdleStartThreshold(formValues: uui.domain.ui.forms.DeviceData) {
  const {
    idleStart: { enabled, custom, preset },
  } = formValues

  if (!enabled) return 0

  switch (preset) {
    case 'custom':
      return seconds(custom)
    case 'fiveMins':
      return seconds(5)
    case 'tenMins':
      return seconds(10)
    case 'thirtyMins':
      return seconds(30)
  }
}
