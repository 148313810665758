import { ConnectMapToList, InspectPinMode, Map as MainMap, MapLayer, DrawRoadSegment } from '@/map'

export function Map() {
  return (
    <MainMap selectionCategory="routingSegments" selectionContext="crud" mapId="ww-map">
      <InspectPinMode mode="none" />

      <MapLayer uid="order" visible />
      <MapLayer uid="region" visible />
      <MapLayer uid="roadSegment" interactive={true} visible />
      <MapLayer uid="geofence" visible={false} />
      <MapLayer uid="routePolyline" visible />
      <MapLayer uid="place" visible />

      <ConnectMapToList category="roadSegments" />

      <DrawRoadSegment />

      {/* TODO: Add remaining layers once they are implemented */}
    </MainMap>
  )
}
