import { Alert, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSelector } from 'react-redux'

import { selectTelematicsTenantSourcesAsArray } from '@/features/domain/account'

import { useControllerActions } from '../hooks/useControllerActions'
import { useTexts } from '../useTexts'

import { NoTenantSourcesAlert } from './NoTenantSourcesAlert'

export function Body() {
  const { onConfigureButtonClicked, loading } = useControllerActions()
  const tenantSources = useSelector(selectTelematicsTenantSourcesAsArray)
  const texts = useTexts()

  if (tenantSources.length === 0) {
    return <NoTenantSourcesAlert />
  }

  return (
    <Stack paddingY={1} spacing={3}>
      <Alert severity="error">
        <Stack spacing={1}>
          <Typography variant="h6">{texts.warningTitle}</Typography>
          <Typography variant="body2">{texts.warningDescription1}</Typography>
          <Typography variant="body2">{texts.warningDescription2}</Typography>
        </Stack>
      </Alert>

      <Stack spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <Stack spacing={1} alignItems="flex-start">
            <Typography variant="h6">{texts.configureTitle}</Typography>
            <Typography variant="body2">{texts.configureDescription1}</Typography>
            <ul>
              <li>
                <Typography variant="body2">{texts.configureDescription2}</Typography>
              </li>
              <li>
                <Typography variant="body2">{texts.configureDescription3}</Typography>
              </li>
              <li>
                <Typography variant="body2">{texts.configureDescription4}</Typography>
              </li>
              <li>
                <Typography variant="body2">{texts.configureDescription5}</Typography>
              </li>
            </ul>
            <Typography variant="body2">{texts.configureDescription6}</Typography>
            <LoadingButton
              fullWidth
              onClick={onConfigureButtonClicked}
              variant="contained"
              loading={loading}
              color="primary"
              size="medium"
              type="submit"
            >
              {texts.configureButton}
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
