import styled from 'styled-components'
import { EventHandler, KeyboardEventHandler } from 'react'

export const DEFAULT_SIZE = 28

export const LEFT_PAD = 10
export const RADIUS = 6
export const BORDER = 2
export const BUTTON_SIZE = 14

export const RIGHT_PAD = LEFT_PAD * 2 + BUTTON_SIZE

const setHeight = ({ multi = false, height = DEFAULT_SIZE }) => {
  if (multi) return ``
  return `
    height: ${height}px;
    line-height: ${height - 2 * BORDER}px;
  `
}

const getOpacity = ({ disabled }: Props) => (!!disabled ? 0.1 : 1)
const getPointerEvents = ({ disabled }: Props) => (disabled ? 'none' : 'auto')

export interface Props {
  className?: string
  multi?: boolean
  onBlur?: EventHandler<any>
  onFocus?: EventHandler<any>
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>
  disabled?: boolean
  children: React.ReactNode
}

const div = styled.div<Props>``
const styledComponent = styled(div).attrs<Props>(() => ({
  className: 'o-base-input-wrapper',
}))`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding-left: ${LEFT_PAD}px;
  padding-right: ${RIGHT_PAD}px;
  border-radius: ${RADIUS}px;
  ${setHeight};

  opacity: ${getOpacity};
  pointer-events: ${getPointerEvents};

  color: ${p => p.theme.colors.$pureBlack};
  font-size: ${p => p.theme.fonts.$h4};

  border: ${BORDER}px solid ${p => p.theme.colors.$whiteSmoke};

  transition: background 0.3s ease;

  &.is-readonly {
    color: ${p => p.theme.colors.$shadyLady};
    background: ${p => p.theme.colors.$whiteSmoke};
    cursor: auto;
  }
`

styledComponent.displayName = 'BaseInputWrapper'
export default styledComponent
