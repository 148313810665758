import type { ExceptionReadonlyViews, ExceptionReadonlyValues } from './types'

import { useEffect, useCallback } from 'react'

import {
  FormContent,
  SecondaryColumnContentHorizontalRuler,
  SecondaryColumnContentContainer,
} from '@/forms-legacy'

import { Tabs } from '../../../components/Tabs'
import { useFormViewType } from '../../../hooks/useFormViewType'

import { ViewDetails } from './components/ViewDetails'
import { ExceptionHeader } from './components/ExceptionHeader'
import { ExceptionsList } from './components/ExceptionsList'
import { CreateException } from './components/CreateException'
import { CopySettings } from './components/CopySettings'

import { ExceptionActions } from './components/ExceptionActions'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  view: ExceptionReadonlyViews
  changeView: (view: ExceptionReadonlyViews) => void
  viewDetailsData: ExceptionReadonlyValues
}

export function ExceptionsViewManager(props: Props) {
  const { unifiedVehicle, view, changeView, viewDetailsData } = props
  const { unifiedId } = unifiedVehicle

  const formViewType = useFormViewType(unifiedVehicle)

  useEffect(() => {
    changeView('default')
  }, [unifiedId, changeView])

  const switchToListExceptions = useCallback(() => {
    changeView('listExceptions')
  }, [changeView])

  const switchToDefaultView = useCallback(() => {
    changeView('default')
  }, [changeView])

  const switchToCreateNewExceptionView = useCallback(() => {
    changeView('createNewException')
  }, [changeView])

  const switchToCopySettingsView = useCallback(() => {
    changeView('copySettings')
  }, [changeView])

  switch (view) {
    case 'listExceptions':
      return (
        <ExceptionsList
          switchToDefaultView={switchToDefaultView}
          switchToCreateNewExceptionView={switchToCreateNewExceptionView}
          unifiedVehicle={unifiedVehicle}
        />
      )

    case 'createNewException':
      return (
        <CreateException
          unifiedVehicle={unifiedVehicle}
          switchToDefaultView={switchToDefaultView}
        />
      )

    case 'copySettings':
      return (
        <CopySettings unifiedVehicle={unifiedVehicle} switchToDefaultView={switchToDefaultView} />
      )

    case 'default':
      return (
        <>
          <ExceptionHeader
            switchToListExceptions={switchToListExceptions}
            unifiedVehicle={unifiedVehicle}
          >
            <Tabs unifiedVehicle={unifiedVehicle} formViewType={formViewType} />
          </ExceptionHeader>
          <ExceptionActions
            unifiedVehicle={unifiedVehicle}
            switchToCreateNewExceptionView={switchToCreateNewExceptionView}
            switchToCopySettingsView={switchToCopySettingsView}
          />
          <FormContent footer={<div />}>
            <SecondaryColumnContentContainer>
              <SecondaryColumnContentHorizontalRuler bigBottomSpace />
              <ViewDetails data={viewDetailsData} />
            </SecondaryColumnContentContainer>
          </FormContent>
        </>
      )
  }
}
