import type Geometry from 'ol/geom/Geometry'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Circle from 'ol/geom/Circle'

import { setGeofenceFeatureMetadata } from '../geofenceFeatureMetadata'
import { getGeofenceFeatureStyle } from './getGeofenceFeatureStyle'

export const createGeofenceFeature = (
  marker: uui.domain.ui.map.markers.Geofence,
  mapStyles: uui.domain.ui.map.markers.MapStyles['geofence'],
  selected: boolean = false,
): Feature<Geometry> => {
  const { lonLat, radius, id } = marker

  const feature = new Feature({ geometry: new Circle(lonLat, radius), label: new Point(lonLat) })

  // use marker ID (geofenceId) as unique ID
  feature.setId(id)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setGeofenceFeatureMetadata(feature, 'type', 'geofence')

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['geofence']['mode'] =
    marker.mode ?? mapStyles.mode
  setGeofenceFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style
  feature.setStyle(getGeofenceFeatureStyle(marker, mode, selected))

  return feature
}
