import type OlMap from 'ol/Map'
import type { Popup } from '../types'
import type { Context, LoadingResult } from './PopupContext'

import { ReactElement, useEffect, useMemo, useState } from 'react'

import { useMapInstance } from '@/map'

import {
  MainWindowTemporaryMapContainer,
  id as mainWindowTemporaryContainerId,
} from '../components/MainWindowTemporaryMapContainer'

import { createRouteManagerPopup } from '../core/createRouteManagerPopup'
import { rootId } from '../plugins/createReactRootPopupPlugin'
import { PopupWindowRenderer } from '../components/PopupWindowRenderer'

import { PopupContext } from './PopupContext'
import { PopupChildrenQueueContext } from './PopupChildrenQueueContext'

// Create a promise and steal its resolver
function createLoadingPromise() {
  let resolver: (value: LoadingResult) => void = () => {}
  const promise = new Promise<LoadingResult>(resolve => (resolver = resolve))

  return { resolver, promise }
}

const placeHolderPopupContext: Context = {
  status: 'close',
  open: () => {
    throw new Error('The popup context is not ready yet')
  },
}

/**
 * Store a reference to the popup and its API for all the application.
 */
export function PopupRoot(props: { children: ReactElement }) {
  const map = useMapInstance()

  // INITIAL CONTEXT VALUE
  const [popupContextValue, setPopupContextValue] = useState<Context>(placeHolderPopupContext)

  useEffect(() => {
    // Create context on mount
    if (popupContextValue !== placeHolderPopupContext) return

    setPopupContextValue({
      status: 'close',
      open: createPopupOpener(map, createLoadingPromise(), setPopupContextValue),
    })
  }, [
    // The following dependencies never change
    map,
    popupContextValue,
  ])

  const [childrenQueue, setChildrenQueue] = useState<ReactElement[]>([])

  const popupChildrenQueueValue = useMemo(
    () => ({ childrenQueue, setChildrenQueue }),
    [childrenQueue],
  )

  return (
    <PopupContext.Provider value={popupContextValue}>
      <PopupChildrenQueueContext.Provider value={popupChildrenQueueValue}>
        <MainWindowTemporaryMapContainer />
        <PopupWindowRenderer />

        {props.children}
      </PopupChildrenQueueContext.Provider>
    </PopupContext.Provider>
  )
}

const placeholderPopup = {
  open: () => {
    throw new Error('Popup not created yet')
  },
  close: () => {
    throw new Error('Popup not created yet')
  },
  focus: () => {
    throw new Error('Popup not created yet')
  },
}

// TODO: write because you created this function!Map/hooks/useIsInPopup.ts:8
/**
 * Create a function that opens a popup and keep the react context in sync with the popup status.
 */
function createPopupOpener(
  map: OlMap,
  loadingPromise: { resolver: (value: LoadingResult) => void; promise: Promise<LoadingResult> },
  setPopupContextValue: React.Dispatch<React.SetStateAction<Context>>,
) {
  let popup: Popup = placeholderPopup

  return () => {
    popup = createRouteManagerPopup({
      map,
      mainWindowTemporaryContainerId,
      plugins: [
        {
          onOpen: () => {
            setPopupContextValue({
              status: 'opening',
              loadingPromise: loadingPromise.promise,
              close: popup.close,
            })
          },

          onLoad: popupData => {
            const { popupWindow } = popupData

            const rootContainer = popupWindow.document.getElementById(rootId)
            if (!rootContainer) {
              throw new Error('Cannot find the root container')
            }

            setPopupContextValue({
              status: 'open',
              close: popup.close,
              focus: popup.focus,
              rootContainer,
            })

            // Resolve the loading promise
            loadingPromise.resolver('success')
          },

          onClose: () => {
            setPopupContextValue({
              status: 'close',
              open: createPopupOpener(map, createLoadingPromise(), setPopupContextValue),
            })
          },

          onError: popupData => {
            setPopupContextValue({
              status: 'error',
              type: popupData.errorType,
              open: createPopupOpener(map, createLoadingPromise(), setPopupContextValue),
            })
          },
        },
      ],
    })

    popup.open()

    return loadingPromise.promise
  }
}
