import styled from 'styled-components'

// grid-template-columns: repeat(3, 125px);
// justify-content: space-between;

// grid is cool but not compatible with IE11
// this use flex instead: https://codepen.io/Albe-14/pen/eQgzYy

const SuggestionsListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 405px;

  > * {
    margin-right: 15px;
    border-width: 0;

    margin-top: 5px;
  }

  > *:nth-child(3n) {
    margin-right: 0;
  }
`
SuggestionsListContainer.displayName = 'SuggestionsListContainer'
export default SuggestionsListContainer
