import { useEffect, useRef } from 'react'

export const useResizeObserver = (element: Element | null, callback: ResizeObserverCallback) => {
  const observerRef = useRef(new ResizeObserver(callback))

  useEffect(() => {
    if (!element) return

    const observer = observerRef.current
    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [element])
}
