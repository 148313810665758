/**
 * This atom is used to store the last valid time window that was resized.
 * This is required because the time window received by onEventResizeEnd is the real position of the mouse, that can be outside the valid range.
 * @see apps/route-manager/src/scheduler/utils/createSchedulerConfig/features/eventResize/validatorFn.ts
 * @see apps/route-manager/src/scheduler/utils/createSchedulerConfig/onEventResizeEnd.ts
 */
type ResizeTimeWindowAtom = {
  lastTimeWindow?: uui.domain.rm.TimeWindow
}

export const resizeTimeWindowAtom: ResizeTimeWindowAtom = {}

export function setResizeTimeWindowAtom(timeWindow: uui.domain.rm.TimeWindow) {
  resizeTimeWindowAtom.lastTimeWindow = timeWindow
}

export function getResizeTimeWindowAtom() {
  return resizeTimeWindowAtom.lastTimeWindow
}
