import type { PermissionData } from '../types'

export function computeExportPermission(permissionData: PermissionData) {
  if (permissionData.isSimulation && !permissionData.canExportInSimulation) {
    return 'disabled'
  }

  if (permissionData.approvedRoutesCount === 0 && permissionData.notEmptyRoutesCount === 0) {
    return 'disabled'
  }

  return 'enabled'
}
