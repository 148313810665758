import { BulkCheckbox } from '@/components/BulkCheckbox'

import { type FormFields, type FormErrors } from '../../../formFields'
import { useTexts } from '../hooks/useTexts'

export function SummarizeFutureMobileRoute() {
  const texts = useTexts()

  return (
    <BulkCheckbox<'summarizeFutureMobileRoute', FormFields, FormErrors>
      name="summarizeFutureMobileRoute"
      testId="summarizeFutureMobileRoute"
      label={texts.labels.summarizeFutureMobileRoute}
      validateOn="change"
      helperText={texts.helpers.summarizeFutureMobileRoute}
    />
  )
}
