import styled from 'styled-components'

import { normalizeCssValue } from '@/local/components'

export type Props = {
  maxWidth?: string | number
  textAlign?: 'left' | 'center' | 'right'
}

export const Container = styled.div<Props>`
  background-color: ${p => p.theme.colors.$nightRider};
  border-radius: 4px;
  overflow: hidden;
  padding: 6px 20px;
  pointer-events: none;
  text-align: ${p => p.textAlign || 'center'};
  z-index: 999;
  max-width: ${p => normalizeCssValue(p.maxWidth || '40ch')};
`

Container.displayName = 'Container'
