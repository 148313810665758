import { useSelector } from 'react-redux'

import { selectDepots } from '@/features/domain/depot'

import { useTexts } from './useTexts'

export function useParseDepotData(depotId: string | null) {
  const depots = useSelector(selectDepots)
  const texts = useTexts()

  if (!depotId) {
    return texts.notSet
  }

  const depot = depots[depotId]
  if (!depot) {
    throw new Error(`Depot with id: ${depotId} not found`)
  }
  return `${depot.name} - ${depot.location.geoAddress}`
}
