import type { Props } from '../typings'
import { useCallback } from 'react'
import { useFormState } from 'react-final-form'

import { LocationEditorField, LocationEditorFieldWrappedInputProps } from '@/forms-legacy'

import * as OrderTexts from '../../../../../../intl'

const wrappedInputProps: LocationEditorFieldWrappedInputProps = {
  modes: ['copyCoordinates', 'renameLocation'],
  height: 150,
}

function Address(props: Props) {
  const {
    orderStepType,
    orderStep,
    formRenderProps: {
      form: { change },
    },
  } = props

  const {
    values: { type },
  } = useFormState()

  const handleOnConnectedPlaceChanged = useCallback(
    (connectedId: string | undefined) => {
      const fieldName: string = `${orderStepType}.depotId`
      change(fieldName, connectedId)
    },
    [orderStepType, change],
  )

  // orderStepType === 'service' become 'delivery'
  const name = orderStepType === 'pickup' ? 'pickup.location' : 'delivery.location'

  const pinId: uui.domain.ui.map.LocationPinId =
    type === 'p'
      ? 'orderForm_pickup'
      : type === 's'
      ? 'orderForm_service'
      : type === 'pd'
      ? orderStepType === 'pickup'
        ? 'orderForm_pickup'
        : 'orderForm_delivery'
      : 'orderForm_delivery'

  return (
    <LocationEditorField
      inactive={false}
      snapToRoad={true}
      pinType={'normal'}
      assetTypes={['depot']}
      ignoreMapBounds={false}
      name={name}
      connectedPlaceId={orderStep.depotId}
      wrappedInputProps={wrappedInputProps}
      label={OrderTexts.getFieldLabelAddressText()}
      onConnectedPlaceChanged={handleOnConnectedPlaceChanged}
      pinId={pinId}
      testid="address"
      showAdjustAddress
    />
  )
}

export default Address
