import { useCallback } from 'react'

import { deleteDriverAssignments } from '@/features/domain/driverAssignments'
import { ConfirmPanel } from '@/forms-legacy'
import { useNotification, useIsUnmounted } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useRecurrence } from '../../../hooks/useRecurrence'
import { useTexts } from '../hooks/useTexts'

interface Props {
  setView: (view: 'default' | 'delete') => void
}

export function DeletePanel(props: Props) {
  const { setView } = props
  const unmounted = useIsUnmounted()

  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const recurrence = useRecurrence()

  const onCancel = useCallback(() => {
    setView('default')
  }, [setView])

  const onConfirm = useCallback(async () => {
    try {
      if (!recurrence) {
        throw new Error('recurrence not found')
      }
      const result = await dispatch(deleteDriverAssignments([recurrence.source]))

      if (!deleteDriverAssignments.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      if (!unmounted()) {
        setView('default')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [setView, dispatch, toast, recurrence, unmounted])

  return (
    <ConfirmPanel
      title={texts.confirmPanelTitle}
      description={texts.confirmPanelSubtitle}
      confirmationText={texts.delete}
      onCancel={onCancel}
      onConfirm={onConfirm}
      testid="setup-driverAssignments__single-delete-confirm-panel"
    />
  )
}
