import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'

import { createNonBlockingRenderByTime } from '../../../renderingQueue'
import { findFeature } from '../../findFeature'
import { setLayerMetadata } from '../../layerMetadata'

type BreadcrumbLayers = {
  breadcrumbPathLayer: VectorLayer<VectorSource>
  breadcrumbPointsLayer: VectorLayer<VectorSource>
}

const nonblockingRender = createNonBlockingRenderByTime()

export async function changelogRemoveBreadcrumbFeatures(
  layers: BreadcrumbLayers,
  selection: Set<string>,
  markerIds: IterableIterator<string>,
) {
  nonblockingRender.reset()

  for (const markerId of markerIds) {
    // Remove path feature
    const pathFeature = findFeature(layers.breadcrumbPathLayer, markerId)
    if (pathFeature) {
      layers.breadcrumbPathLayer.getSource()?.removeFeature(pathFeature)
    }

    // Remove points features
    const pointsFeatures = layers.breadcrumbPointsLayer.getSource()?.getFeatures() ?? []

    for (const feature of pointsFeatures) {
      const featureId = feature.getId()
      if (typeof featureId === 'string' && featureId.startsWith(`${markerId}@`)) {
        await nonblockingRender.next()

        layers.breadcrumbPointsLayer.getSource()?.removeFeature(feature)
      }
    }

    // Remove stops features
    const stopsFeaturesOnPathLayer = layers.breadcrumbPathLayer.getSource()?.getFeatures() ?? []

    for (const feature of stopsFeaturesOnPathLayer) {
      const featureId = feature.getId()
      if (typeof featureId === 'string' && featureId.startsWith(`${markerId}@`)) {
        await nonblockingRender.next()

        layers.breadcrumbPathLayer.getSource()?.removeFeature(feature)
      }
    }

    const stopsFeaturesOnPointsLayer = layers.breadcrumbPointsLayer.getSource()?.getFeatures() ?? []

    for (const feature of stopsFeaturesOnPointsLayer) {
      const featureId = feature.getId()
      if (typeof featureId === 'string' && featureId.startsWith(`${markerId}@`)) {
        await nonblockingRender.next()

        layers.breadcrumbPointsLayer.getSource()?.removeFeature(feature)
      }
    }
  }

  // update the stored collection
  setLayerMetadata(layers.breadcrumbPathLayer, 'selection', selection)
  setLayerMetadata(layers.breadcrumbPointsLayer, 'selection', selection)
}
