import type { ObjectEvent } from 'ol/Object'

import { useEffect, useState } from 'react'

import { gis } from '@/server-data'
import { mapAtom } from '../map'

type MapInfo = {
  center: uui.domain.LatLng
  bounds: uui.domain.LatLngBounds
}

const centerOfTheWorld = gis.createLatLng(85, 65)

export const getMapInfo = (): MapInfo => {
  // recompute latLng bounds
  const bounds = gis.getMapBounds(mapAtom.map)

  const coordinates = mapAtom.map.getView().getCenter()
  const center = coordinates ? gis.fromCoordinateToLatLng(coordinates) : centerOfTheWorld

  return { center, bounds }
}

export const useGetMapInfo = () => {
  const [info, setInfo] = useState<MapInfo>(() => getMapInfo())

  useEffect(() => {
    const onChange = (_evt: ObjectEvent) => {
      setInfo(getMapInfo())
    }

    mapAtom.map.getView().on('change:center', onChange)
    mapAtom.map.getView().on('change:resolution', onChange)

    return () => {
      mapAtom.map.getView().un('change:center', onChange)
      mapAtom.map.getView().un('change:resolution', onChange)
    }
  }, [])

  return info
}
