import { Icon, Style } from 'ol/style'

import mapPinSpriteSheet from '@/resources/map-markers/pins.png'

import { genericStyles } from '../../../genericStyles'
import { getPinGridPlacement } from '../../coordinates/getPinGridPlacement'

const depotStylesCache: Map<string, Style[]> = new Map()

export function getInspectPinFeatureStyle(inspectPin?: uui.domain.ui.map.InspectPin) {
  if (!inspectPin) return genericStyles.hidden
  if (inspectPin.mode === 'converted') return genericStyles.hidden

  const { status } = inspectPin

  const coordinates = getPinGridPlacement('droppedPin', status === 'loading' ? 'loading' : 'normal')

  const spriteSheetUrl = mapPinSpriteSheet
  const cellSize = coordinates[2]
  const x = coordinates[0]
  const y = coordinates[1]

  const cacheId = `inspectPin_${x}_${y}_${status ? 1 : 0}`

  if (depotStylesCache.has(cacheId)) {
    return depotStylesCache.get(cacheId)
  }

  const style = [
    new Style({
      image: new Icon({
        src: spriteSheetUrl,
        crossOrigin: 'anonymous',

        scale: 0.5,
        opacity: 1.0,
        rotation: 0.0,
        rotateWithView: true,

        offset: [x, y],
        size: [cellSize, cellSize],

        anchor: [0.5, 0],
        anchorOrigin: 'bottom-left',
        anchorYUnits: 'fraction',
        anchorXUnits: 'fraction',
      }),
    }),
  ]

  depotStylesCache.set(cacheId, style)

  return style
}
