import { styled } from '@mui/material'
import { theme } from '@/styles'

interface Props {
  color?: string
}

const FormBulkAvatarContainer = styled('div', { name: 'FormBulkAvatarContainer' })(
  ({ color }: Props) => ({
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: '50%',
    marginRight: '16px',
    alignItems: 'center',
    background: color ?? theme.colors.$nightRider,
    fontWeight: theme.weights.$semiBold,
    fontSize: theme.fonts.$p3,
    display: 'flex',
    border: `1px solid ${theme.colors.$pureWhite}`,
    height: 40,
    width: 40,
    color: theme.colors.$pureWhite,
    flex: '0 0 auto',
  }),
)

export default FormBulkAvatarContainer
