import { useRoutingLayout } from '@/atoms'

import { OrdersGridLayout } from './components/OrdersGridLayout'
import { SchedulerLayout } from './components/SchedulerLayout'
import { SplitLayout } from './components/SplitLayout'
import { MapLayout } from './components/MapLayout'

export function RoutingView() {
  const [
    {
      layout: { mode, bottomElement },
    },
  ] = useRoutingLayout()

  switch (mode) {
    case 'map':
      return <MapLayout />
    case 'scheduler':
      return <SchedulerLayout />
    case 'orders-grid':
      return <OrdersGridLayout />
    case 'split':
      return <SplitLayout visibleItem={bottomElement} />
    default:
      return null
  }
}
