import { useCallback, useState } from 'react'

import { useModalsContext } from '../../../../../context/ModalsContextProvider'

import { useEnableCompany } from './useEnableCompany'

export function useMenu(company: uui.domain.client.rm.Company) {
  const { id: companyId, enabled } = company
  const [open, setOpen] = useState(false)
  const {
    onSetCompanyId,
    onShowRenameCompanyModal,
    onShowDisableCompanyModal,
    onShowDeleteCompanyModal,
  } = useModalsContext()
  const enableCompany = useEnableCompany(company)

  const onRename = useCallback(() => {
    onSetCompanyId(companyId)
    onShowRenameCompanyModal()
    setOpen(false)
  }, [companyId, onSetCompanyId, onShowRenameCompanyModal])

  const onToggleDisable = useCallback(async () => {
    onSetCompanyId(companyId)
    enabled ? onShowDisableCompanyModal() : enableCompany()
    setOpen(false)
  }, [companyId, onSetCompanyId, enabled, enableCompany, onShowDisableCompanyModal])

  const onDelete = useCallback(() => {
    onSetCompanyId(companyId)
    onShowDeleteCompanyModal()
    setOpen(false)
  }, [companyId, onSetCompanyId, onShowDeleteCompanyModal])

  return {
    open,
    setOpen,
    onRename,
    onToggleDisable,
    onDelete,
  }
}
