import { useNavigoCategory } from '@/hooks'

import { MultiCategory } from './entities/MultiCategory'
import { Breadcrumbs } from './entities/Breadcrumbs'
import { InspectPin } from './entities/InspectPin'
import { OrderSteps } from './entities/OrderSteps'
import { Vehicles } from './entities/Vehicles'
import { Devices } from './entities/Devices'
import { Drivers } from './entities/Drivers'
import { Depots } from './entities/Depots'
import { Places } from './entities/Places'
import { Routes } from './entities/Routes'

export function Navigo() {
  const category = useNavigoCategory()

  switch (category) {
    case 'inspectPin':
      return <InspectPin />

    //-----------------------------------

    case 'multiCategory':
      return <MultiCategory />

    case 'breadcrumbs':
      return <Breadcrumbs />

    case 'depots':
      return <Depots />

    case 'devices':
      return <Devices />

    case 'drivers':
      return <Drivers />

    case 'orderSteps':
      return <OrderSteps />

    case 'places':
      return <Places />

    case 'routes':
      return <Routes />

    case 'unifiedVehicles':
      return <Vehicles />

    default:
      return null
  }
}
