import { useController } from '../../hooks/useController'

import { ChooseRecipientBody } from './components/ChooseRecipientBody'
import { ComposeMessageBody } from './components/ComposeMessageBody'
import { SuccessBody } from './components/SuccessBody'
import { FailureBody } from './components/FailureBody'

export function Body() {
  const { status } = useController()

  switch (status) {
    case 'success':
      return <SuccessBody />

    case 'failure':
      return <FailureBody />

    case 'chooseRecipient':
      return <ChooseRecipientBody />

    case 'composeMessage':
    default:
      return <ComposeMessageBody />
  }
}
