export function drawStyleCommon() {
  return {
    white: (alpha = 1) => [255, 255, 255, alpha],
    blue: (alpha = 1) => [0, 123, 255, alpha],
    green: (alpha = 1) => [46, 210, 72, alpha],
    black: (alpha = 1) => [0, 0, 0, alpha],
    gray: (alpha = 1) => [48, 48, 48, alpha],
    red: (alpha = 1) => [250, 97, 63, alpha],

    lineWidth: 2,
    pointRadius: 6,
    pointBorderWidth: 2,
  }
}
