import type { CSSProperties } from 'react'

import { Dashboard, DevTools, Wrench, Dinosaur, Info } from '@/icons'
import { IconButton } from '@/components/primitives/buttons/IconButton'
import { HasPrivileges } from '@/components/HasPrivileges'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Grow } from '@/components/layout'
import { useTesterModal } from '@/dev'
import { type ExtendedPrivilege } from '@/hooks'

import { HeaderSlice } from '../../elements/HeaderSlice'

import { LinkIcon } from './components/LinkIcon'
import { HelpDropdownMenu } from './components/HelpDropdownMenu'
import { CalendarDropdownMenu } from './components/CalendarDropdownMenu'
import { SettingsDropdownMenu } from './components/SettingsDropdownMenu'
import { TerritoryDropdownMenu } from './components/TerritoryDropdownMenu'
import { RoutingLayoutDropdownMenu } from './components/RoutingLayoutDropdownMenu'
import { ExtractedMapWarning } from './components/RoutingLayoutDropdownMenu/components/ExtractedMapWarning'
import { GpsUserConsole } from './components/GpsUserConsole'

import { usePermissions } from './hooks/usePermissions'
import { useTexts } from './useTexts'

const dashboardUrl = 'https://wwrm.workwave.com/analytics'

const iconButtonStyle: CSSProperties = { height: 36, padding: 8, position: 'relative' }
const devPrivileges: ExtendedPrivilege[] = ['dev', 'loginAsPasspartout']

export function MenuArea() {
  const { showDashboard, showDevTools, showUserConsole, showLayoutMenu, showSetup, showHelp } =
    usePermissions()
  const { Modal: TesterModal, toggleTesterModal } = useTesterModal()
  const texts = useTexts()

  return (
    <Grow yAxis>
      <HeaderSlice gutter={20} paddingX={34}>
        <CalendarDropdownMenu />

        <TerritoryDropdownMenu />

        {showSetup && (
          <LinkIcon
            to="setup"
            testid="header__setup-button"
            tooltipTitle={texts.setupArea}
            className="pendo-bluebar__setup-area-button"
            Icon={<Wrench size={14} color="$pureWhite" />}
          />
        )}

        {showLayoutMenu && (
          <>
            <RoutingLayoutDropdownMenu />
            <ExtractedMapWarning />
          </>
        )}

        <HasPrivileges accountPrivileges={devPrivileges}>
          <Tooltip placement="bottom" title="Toggle testers ( Ctrl + Shift + \ )">
            <IconButton
              style={iconButtonStyle}
              onClick={toggleTesterModal}
              data-testid="header__toggle-testers-button"
            >
              <Dinosaur size={14} color="$pureWhite" />
            </IconButton>
          </Tooltip>
        </HasPrivileges>

        {showDevTools && (
          <HasPrivileges accountPrivileges={devPrivileges}>
            {/* //TODO: Disabled for V3 beta */}
            <Tooltip placement="bottom" title={texts.devTools}>
              <IconButton
                disabled
                style={iconButtonStyle}
                data-testid="header__devtools-button"
                className="pendo-bluebar__devtools-button"
                onClick={() => console.log(' //TODO: implement the opening of DevTools ')}
              >
                <DevTools size={16} color="$silver" />
              </IconButton>
            </Tooltip>
          </HasPrivileges>
        )}

        {showUserConsole && <GpsUserConsole />}

        {showDashboard && (
          <Tooltip placement="bottom" title={texts.dashboard}>
            <IconButton
              style={iconButtonStyle}
              data-testid="header__dashboard-button"
              data-trackid="header__dashboard-button"
            >
              <a
                target="_blank"
                href={dashboardUrl}
                rel="noopener noreferrer"
                style={{ display: 'flex' }}
                className="pendo-bluebar__dashboard-button"
              >
                <Dashboard size={16} color="$pureWhite" />
              </a>
            </IconButton>
          </Tooltip>
        )}

        <Tooltip placement="bottom" title={texts.pendoResourceCenter}>
          <IconButton style={iconButtonStyle} data-trackid="Pendo-resourcecenter">
            <Info size={16} color="$pureWhite" />
          </IconButton>
        </Tooltip>

        {showHelp && <HelpDropdownMenu />}

        <SettingsDropdownMenu />
      </HeaderSlice>

      {TesterModal}
    </Grow>
  )
}
