import type { ChangeEvent, ReactNode } from 'react'

import { Component } from 'react'
import { clsx } from '@/utils'

import Checkbox, { Props as CheckboxProps } from '../Checkbox'
import InputFieldHint from '../InputFieldHint'
import InputFieldLabel from '../InputFieldLabel'

export type Props = {
  children?: ReactNode
  className?: string
  showCheckbox?: boolean
  useListRenderer?: boolean
  label?: string
  labelClassName?: string
  labelAfter?: boolean
  readonly?: boolean
  error?: string
  info?: string
  onCheck?: (value: boolean, e?: ChangeEvent<HTMLElement>) => void
}

const noop = () => undefined

export default class Field extends Component<Props> {
  renderLabel(): ReactNode {
    const {
      label = '',
      labelClassName = '',
      showCheckbox = true,
      readonly = false,
      onCheck = noop,
    } = this.props
    const checkBoxProps: CheckboxProps = {
      checked: !readonly,
      onChange: onCheck,
    }
    if (!label && !showCheckbox) return null
    return (
      <div className="o-field__label-wrapper">
        {showCheckbox && <Checkbox {...checkBoxProps} />}
        {label && <InputFieldLabel className={labelClassName}>{label}</InputFieldLabel>}
      </div>
    )
  }

  // TODO @albe: this can be abstracted into a generic Info Component inside Field
  renderInfo(): ReactNode {
    const { error, info } = this.props
    const infoClassName = clsx({
      'o-field__info': true,
      'is-error-message': !!error,
    })
    let children: ReactNode = null
    if (info) {
      children = <InputFieldHint>{info}</InputFieldHint>
    }
    if (error) {
      children = <InputFieldHint>{error}</InputFieldHint>
    }
    if (!children) return children
    return <div className={infoClassName}>{children}</div>
  }

  render() {
    const { className = '', children = null, labelAfter } = this.props

    const rootClassName = clsx({
      'o-field': true,
      'label-after': labelAfter,
      [className]: true,
    })

    return (
      <div className={rootClassName}>
        {this.renderLabel()}
        {children}
        {this.renderInfo()}
      </div>
    )
  }
}
