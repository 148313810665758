import { type ReactNode } from 'react'
import { IconButton } from '@mui/material'

const buttonStyle = { minWidth: 40 }

type Props = {
  className?: string
  disabled?: boolean
  children: ReactNode
  trackId?: string
  onClick: () => void
  testId?: string
}

export function ListHeaderPrimaryActionButton(props: Props) {
  const {
    className,
    children,
    disabled = false,
    onClick,
    trackId,
    testId = 'list-header-primary-action-button',
  } = props

  return (
    <IconButton
      data-trackid={trackId}
      data-testid={testId}
      className={className}
      disabled={disabled}
      onClick={onClick}
      sx={buttonStyle}
    >
      {children}
    </IconButton>
  )
}
