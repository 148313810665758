import { useRef } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const api = useRef({
    cancelButtonTitle: translate({ id: 'demoTools.importDemoTerritory.modal.cancelButton.title' }),
    commandButtonTitle: translate({
      id: 'demoTools.importDemoTerritory.modal.commandButton.title',
    }),
    failedDataFetchingWarningCardTitle: translate({
      id: 'demoTools.importDemoTerritory.modal.failedDataFetching.warningCard.title',
    }),
    failedDataFetchingWarningCardDescription: translate({
      id: 'demoTools.importDemoTerritory.modal.failedDataFetching.warningCard.description',
    }),
    genericError: translate({ id: 'demoTools.importDemoTerritory.error.generic' }),
    modalTitle: translate({ id: 'demoTools.importDemoTerritory.modal.title' }),
    modalSubTitle: translate({ id: 'demoTools.importDemoTerritory.modal.subtitle' }),
    territoryDropdownNullState: translate({
      id: 'demoTools.importDemoTerritory.modal.dropdown.nullState',
    }),
    territoryDropdownTitle: translate({
      id: 'demoTools.importDemoTerritory.modal.territoryDropdown.title',
    }),
    transactionWarningCardTitle: translate({
      id: 'demoTools.importDemoTerritory.modal.transaction.warningCard.title',
    }),
    transactionWarningCardDescription: translate({
      id: 'demoTools.importDemoTerritory.modal.transaction.warningCard.description',
    }),
  })

  return api.current
}
