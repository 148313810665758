import styled from 'styled-components'

const styledComponent = styled.div.attrs({
  className: 'o-store-links__row',
})`
  padding-top: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

export default styledComponent
