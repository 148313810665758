import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getCalendarizedSettings } from '@/server-data'

import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { selectDepots } from '@/features/domain/depot'

export function useVehicleSettings(vehicleId?: string, date?: string) {
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const vehicles = useSelector(selectVehicles)
  const depots = useSelector(selectDepots)

  return useMemo(() => {
    if (!vehicleId || !date) return
    const uv = vehicles[vehiclesByVehicleId[vehicleId]]

    if (!uv?.hasRoutingLicense) return

    const vehicleSettings = getCalendarizedSettings(uv.vehicle, date)

    // Get planned departure Depot ('-' if not specified)
    const departureDepot = vehicleSettings.departureDepotId
      ? depots[vehicleSettings.departureDepotId].name
      : '-'

    // Get planned arrival Depot ('-' if not specified)
    const arrivalDepot = vehicleSettings.arrivalDepotId
      ? depots[vehicleSettings.arrivalDepotId].name
      : '-'

    return { departureDepot, arrivalDepot }
  }, [vehicleId, vehicles, vehiclesByVehicleId, depots, date])
}
