import type { ReactElement } from 'react'

import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  selectUserPrivileges,
  selectUserConfiguration,
  selectGpsTrackingProvider,
  selectUserType,
} from '@/features/domain/user'

import { RedirectToHome } from './RedirectToHome'

type Props = {
  rm?: boolean
  dev?: boolean
  gps?: boolean
  noSim?: boolean
  admin?: boolean
  wwgps?: boolean
  noCourier?: boolean
  noRmGuest?: boolean
  telematics?: boolean
  noRmViewer?: boolean
  adminOrPlanner?: boolean

  element: ReactElement
}

/**
 * Blocks the user from navigating some routes.
 */
export function PrivateRoute(props: Props) {
  const {
    rm,
    gps,
    dev,
    admin,
    noSim,
    wwgps,
    element,
    noCourier,
    noRmGuest,
    noRmViewer,
    telematics,
    adminOrPlanner,
  } = props

  const location = useLocation()
  const { authenticated, userType, rmAdmin, planType, transitions } =
    useSelector(selectUserConfiguration)
  const profileUserType = useSelector(selectUserType)
  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const userPrivileges = useSelector(selectUserPrivileges)
  const hasDevPrivileges = userPrivileges.includes('DEV')

  if (!authenticated) {
    return <Navigate to="/login" replace state={{ from: location }} />
  }

  /**
   * Currently the UI doesn't have a proper way to determine when the Domain Data is loaded the
   * first time after a login. To workaround that the UI will use the absence of Timezone
   * transitions information.
   */
  if (transitions.length === 0) {
    // TODO: Render some visual placeholder for the UI
    return null
  }

  const adminOrPlannerValid = adminOrPlanner ? rmAdmin || profileUserType === 'planner' : true
  const simulationValid = noSim ? planType !== 'simulation' : true
  const telematicsValid = telematics ? trackingProvider === 'telematics' : true
  const adminUserValid = admin ? rmAdmin : true
  const rmViewerValid = noRmViewer ? userType !== 'rmViewer' : true
  const devUserValid = dev ? hasDevPrivileges : true
  const rmGuestValid = noRmGuest ? userType !== 'rmGuest' : true
  const courierValid = noCourier ? userType !== 'courier' : true
  const gpsUserValid = gps ? userType === 'gpsOnly' || userType !== 'rmOnly' : true // TODO: is `rmViewer` valid for GPS?
  const rmUserValid = rm ? userType !== 'gpsOnly' : true
  const wwgpsValid = wwgps ? trackingProvider === 'WWGPS' : true

  const redirectToHome =
    !wwgpsValid ||
    !rmUserValid ||
    !devUserValid ||
    !rmGuestValid ||
    !gpsUserValid ||
    !courierValid ||
    !rmViewerValid ||
    !adminUserValid ||
    !simulationValid ||
    !telematicsValid ||
    !adminOrPlannerValid

  if (redirectToHome) {
    console.warn('Redirect to user home')
    console.table({
      wwgpsValid,
      rmUserValid,
      devUserValid,
      rmGuestValid,
      gpsUserValid,
      rmViewerValid,
      adminUserValid,
      simulationValid,
      telematicsValid,
      adminOrPlannerValid,
    })
    return <RedirectToHome />
  }

  return element
}
