import type { BulkTags } from '../types'

export function updateTagsWarning(tagsIn: BulkTags, tagsOut: BulkTags) {
  updateWarningForBulkTags(tagsIn, tagsOut)
  updateWarningForBulkTags(tagsOut, tagsIn)
}

function updateWarningForBulkTags(tagsToUpdate: BulkTags, tagsToCompare: BulkTags) {
  for (const tag of Object.values(tagsToUpdate.tags)) {
    if (tag.status === 'deleted') continue

    if (tag.status === 'partial') {
      tag.warning = tagsToCompare.tags[tag.name]?.status === 'exact'
    }

    if (tag.status === 'exact') {
      tag.warning =
        tagsToCompare.tags[tag.name]?.status === 'partial' ||
        tagsToCompare.tags[tag.name]?.status === 'exact'
    }
  }
}
