import type { MapSelectionContext } from '../../selection/mapSelection'
import type { CrudSelection, MainSelection } from '@/atoms'

import { createUpdateRoutePolylineFeaturesMapStyleFromChangelog } from '../../../layers/routePolyline/createUpdateRoutePolylineFeaturesMapStyleFromChangelog'
import { createUpdateDeviceEventFeaturesMapStyleFromChangelog } from '../../../layers/deviceEvent/createUpdateDeviceEventFeaturesMapStyleFromChangelog'
import { createUpdateRoadSegmentFeaturesMapStyleFromChangelog } from '../../../layers/roadSegment/createUpdateRoadSegmentFeaturesMapStyleFromChangelog'
import { createUpdateTrafficAreaFeaturesMapStyleFromChangelog } from '../../../layers/trafficArea/createUpdateTrafficAreaFeaturesMapStyleFromChangelog'
import { createUpdateBreadcrumbFeaturesMapStyleFromChangelog } from '../../../layers/breadcrumb/createUpdateBreadcrumbFeaturesMapStyleFromChangelog'
import { createUpdateGeofenceFeaturesMapStyleFromChangelog } from '../../../layers/geofence/createUpdateGeofenceFeaturesMapStyleFromChangelog'
import { createUpdateVehicleFeaturesMapStyleFromChangelog } from '../../../layers/vehicle/createUpdateVehicleFeaturesMapStyleFromChangelog'
import { createUpdateDeviceFeaturesMapStyleFromChangelog } from '../../../layers/device/createUpdateDeviceFeaturesMapStyleFromChangelog'
import { createUpdateRegionFeaturesMapStyleFromChangelog } from '../../../layers/region/createUpdateRegionFeaturesMapStyleFromChangelog'
import { createUpdateDepotFeaturesMapStyleFromChangelog } from '../../../layers/depot/createUpdateDepotFeaturesMapStyleFromChangelog'
import { createUpdateOrderFeaturesMapStyleFromChangelog } from '../../../layers/order/createUpdateOrderFeaturesMapStyleFromChangelog'
import { createUpdatePlaceFeaturesMapStyleFromChangelog } from '../../../layers/place/createUpdatePlaceFeaturesMapStyleFromChangelog'
import { getMapCrudSelection } from '../../selection/utils/getMapCrudSelection'
import { getMapMainSelection } from '../../selection/utils/getMapMainSelection'
import { parseOrderSelection } from '../../selection/read/parseOrderSelection'
import { connectToRenderingQueue } from '../../../renderingQueue'

const emptyStringSelection = new Set<string>()

const [getTicketAndRunAction] = connectToRenderingQueue()

function prepareAction(
  markers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
  mapSelectionContext: MapSelectionContext,
  mainSelection: workwave.DeepReadonly<MainSelection>,
  crudSelection: workwave.DeepReadonly<CrudSelection>,
  category: uui.domain.ui.map.markers.MapStylesCategory,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  const crudMapSelection = getMapCrudSelection(mapSelectionContext, crudSelection)
  const mainMapSelection = getMapMainSelection(mapSelectionContext, mainSelection)

  switch (category) {
    case 'order':
      return createUpdateOrderFeaturesMapStyleFromChangelog(
        markers.order,
        mapStyles.order,

        parseOrderSelection(mapSelectionContext, mainSelection.orderSteps, extendedOrderSteps),
        new Set(mainSelection.orderSteps),
      )

    case 'depot':
      return createUpdateDepotFeaturesMapStyleFromChangelog(
        markers.depot,
        mapStyles.depot,
        mapSelectionContext.type === 'main' ? mainMapSelection.depots : crudMapSelection.depots,
      )

    case 'region':
      return createUpdateRegionFeaturesMapStyleFromChangelog(
        markers.region,
        mapStyles.region,
        mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.regions,
      )

    case 'roadSegment':
      return createUpdateRoadSegmentFeaturesMapStyleFromChangelog(
        markers.roadSegment,
        mapStyles.roadSegment,
        mapSelectionContext.type === 'main'
          ? emptyStringSelection
          : crudMapSelection.routingSegments,
      )

    case 'geofence':
      return createUpdateGeofenceFeaturesMapStyleFromChangelog(
        markers.geofence,
        mapStyles.geofence,
        mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.geofences,
      )

    case 'trafficProfile':
      return createUpdateTrafficAreaFeaturesMapStyleFromChangelog(
        markers.trafficProfile,
        mapStyles.trafficProfile,
        mapSelectionContext.type === 'main' ? emptyStringSelection : crudMapSelection.trafficAreas,
      )

    case 'routePolyline':
      return createUpdateRoutePolylineFeaturesMapStyleFromChangelog(
        markers.routePolyline,
        mapStyles.routePolyline,
      )

    case 'place':
      return createUpdatePlaceFeaturesMapStyleFromChangelog(
        markers.place,
        mapStyles.place,
        mapSelectionContext.type === 'main' ? mainMapSelection.places : crudMapSelection.places,
      )

    case 'breadcrumb':
      return createUpdateBreadcrumbFeaturesMapStyleFromChangelog(
        markers.breadcrumb,
        mapStyles.breadcrumb,
        mapSelectionContext.type === 'main' ? mainMapSelection.breadcrumbs : emptyStringSelection,
        breadcrumbTimeRange,
      )

    case 'device':
      return createUpdateDeviceFeaturesMapStyleFromChangelog(
        markers.device,
        mapStyles.device,
        mapSelectionContext.type === 'main' ? mainMapSelection.devices : crudMapSelection.devices,
      )

    case 'vehicle':
      return createUpdateVehicleFeaturesMapStyleFromChangelog(
        markers.vehicle,
        mapStyles.vehicle,
        mapSelectionContext.type === 'main'
          ? mainMapSelection.unifiedVehicles
          : crudMapSelection.unifiedVehicles,
      )

    case 'deviceEvent':
      return createUpdateDeviceEventFeaturesMapStyleFromChangelog(
        markers.deviceEvent,
        mapSelectionContext.type === 'main'
          ? emptyStringSelection
          : crudMapSelection.unifiedVehicles,
        breadcrumbTimeRange,
      )

    default:
      console.log(`Trying to update not yet implemented Marker Layer for ${category}`)
  }
}

export function updateMarkerLayerFromMapStyleChangelog(
  markers: uui.domain.ui.map.markers.MapMarkers,
  mapStyles: uui.domain.ui.map.markers.MapStyles,
  extendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
  mapSelectionContext: MapSelectionContext,
  mainSelection: workwave.DeepReadonly<MainSelection>,
  crudSelection: workwave.DeepReadonly<CrudSelection>,
  category: uui.domain.ui.map.markers.MapStylesCategory,
  breadcrumbTimeRange: uui.domain.BreadcrumbTimeRange,
) {
  // ATTENTION: Application of Map Style changes will not be cached if a delayed rendering mechanism will be implemented
  // After the whole set of delayed marker changelogs is applied a full refresh of Map Styles will be required

  const action = prepareAction(
    markers,
    mapStyles,
    extendedOrderSteps,
    mapSelectionContext,
    mainSelection,
    crudSelection,
    category,
    breadcrumbTimeRange,
  )

  if (action) {
    getTicketAndRunAction(action)
  }
}
