import type { CSSProperties } from 'react'

import { useMemo } from 'react'

import { makeStyles } from '@mui/styles'
import { theme } from '@/styles'

const useClasses = makeStyles({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    padding: 50,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  progressBarContainer: {
    background: theme.colors.$nightRider,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 10,
    margin: '20px 0',
    minWidth: 400,
    width: '100%',
    height: 10,
  },
  progressBar: {
    background: theme.colors.$pigmentGreen,
    transition: 'width 180ms ease-in-out',
    width: 'var(--bar-width)',
    height: '100%',
  },
  cancelButton: {
    background: theme.colors.$nightRider,
    color: theme.colors.$pureWhite,
    fontSize: theme.fonts.$p2,
    padding: '0 26px',
    borderRadius: 10,
    borderWidth: 0,
    marginTop: 30,
    outline: 0,
    height: 26,

    '&:disabled': {
      pointerEvents: 'none',
      opacity: 0.6,
    },
  },
})

export function useStyles(progress: number | 'completed') {
  const classes = useClasses()

  const styles = useMemo(() => {
    return {
      progressBarWidth: {
        '--bar-width': progress === 'completed' ? '100%' : `${progress}%`,
      } as CSSProperties,
    } as const
  }, [progress])

  return [classes, styles] as const
}
