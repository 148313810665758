import { mapAtom } from '../../atoms/map/map'
import { findLayer } from '../utils/findLayer'

import { mapStylesUpdatePlaceFeatures } from './utils/mapStylesUpdatePlaceFeatures'

export const createUpdatePlaceFeaturesMapStyleFromChangelog = (
  markers: uui.domain.ui.map.markers.MapMarkers['place'],
  mapStyles: uui.domain.ui.map.markers.MapStyles['place'],
  selection: Set<string>,
) => {
  // ATTENTION: Application of Map Style changes will not be cached if a delayed rendering mechanism will be implemented
  // After the whole set of delayed marker changelogs is applied a full refresh of Map Styles will be required

  return async () => {
    const map = mapAtom.map
    const layer = findLayer(map, 'place')

    // only the `update` field is populated in a mapStyles changelog
    await mapStylesUpdatePlaceFeatures(layer, markers, mapStyles, selection)
  }
}
