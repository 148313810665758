import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

type Payload = {
  deviceId: string
  breadcrumbs: uui.domain.server.gps.telematics.BreadcrumbGpsInfo[]
  stops: uui.domain.server.gps.telematics.StopInfo[]
  intervalDurationSeconds: number
}

export const debugSendPeriodicTestDataToTelematics = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>(
  'domain/demoTools/telematics/debugSendPeriodicTestDataToTelematics',
  async (payload, thunkApi) => {
    try {
      const rpc = thunkApi.extra.domainProxy.getRpc()

      const result = await rpc('rpc/demoTools/telematics/debugSendPeriodicTestDataToTelematics', {
        category: 'rpc',
        payload,
        type: 'rpc/demoTools/telematics/debugSendPeriodicTestDataToTelematics',
      })

      if (result.type === 'rpc-failure') {
        return thunkApi.rejectWithValue({
          type: 'rpcFailure',
          message: result.errorMessage,
          error: result,
        })
      }
    } catch (error) {
      thunkApi.extra.journal.main(
        `Unknown error on debugSendPeriodicTestDataToTelematics`,
        { tags: ['rpc'] },
        'error',
      )

      return thunkApi.rejectWithValue({
        type: 'unknown',
        message: `Send test data to telematics Failed`,
        error,
      })
    }
  },
)
