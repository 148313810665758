import type Geometry from 'ol/geom/Geometry'

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { gis } from '@/server-data'

import { setPinFeatureMetadata } from '../../pinFeatureMetadata'
import { getLocationPinFeatureStyle } from './getLocationPinFeatureStyle'

export function createLocationPinFeature(pin: uui.domain.ui.map.LocationPin): Feature<Geometry> {
  const {
    pin: { latLng, radius },
    info,
    id,
  } = pin

  const lonLat = gis.fromLatLngToCoordinate(latLng)
  const feature = new Feature({ geometry: new Point(lonLat) })

  // use marker ID (depotId) as unique ID
  feature.setId(id)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setPinFeatureMetadata(feature, 'type', 'pin')

  // expose the marker kind
  setPinFeatureMetadata(feature, 'kind', 'locationPin')

  // expose the marker radius
  setPinFeatureMetadata(feature, 'radius', radius)

  // expose the marker info
  setPinFeatureMetadata(feature, 'info', info)

  // ------------------------
  // Feature Style

  const style = getLocationPinFeatureStyle(pin)
  feature.setStyle(style)

  return feature
}
