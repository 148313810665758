import { Component } from 'react'

import { Block, Label, FieldMessage, BarcodesStatus, RestoreFieldButton } from '@/forms-legacy'

import { Props } from './typings'

export default class BarcodesStatusField extends Component<Props> {
  render() {
    const {
      label,
      name,
      info,
      wrappedInputProps,
      formProps: {
        input: { value, onChange },
        meta: { error, dirty, initial },
      },
    } = this.props

    return (
      <Block error={!!error} dirty={!!dirty}>
        <Label htmlFor={name}>
          {label}{' '}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>
        <BarcodesStatus {...wrappedInputProps} onChange={onChange} value={value} />
        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }
}
