import { useEffect, useState } from 'react'

export function useRows() {
  // rows is a prop for the TextField of the HotwordsField
  // material-ui TextField when set as multiline will instantiate a TextareaAutoResize (https://next.material-ui.com/api/textarea-autosize/)
  // TextareaAutoResize try to grow as much as possible, becoming tall as much as the whole form itself
  // this is a hack, set rows to 5 will avoid that TextareaAutoResize component to do that
  // as soon the form is set up, rows will set to undefined so the TextareaAutoResize can adapt to his container
  const [rows, setRows] = useState<number | undefined>(5)

  useEffect(() => {
    const timeout = globalThis.setTimeout(() => {
      setRows(undefined)
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return rows
}
