import { Button } from '@/components/primitives/buttons'
import { HorizontalLayout } from '@/components/layout'
import { Separator } from '@/components/Separator'
import { Text } from '@/local/components'

import { Breadcrumbs } from '@/icons'

import { useIsVisible } from './hooks/useIsVisible'
import { useInterval } from './hooks/useInterval'
import { useSetBreadcrumbTimeRangeModal } from './modals/SetBreadcrumbTimeRangeModal'

export function MapTimeRange() {
  const { Modal: SetBreadcrumbTimeRangeModal, show: showSetBreadcrumbTimeRangeModal } =
    useSetBreadcrumbTimeRangeModal()

  const { startTime, endTime } = useInterval()
  const visible = useIsVisible()

  if (!visible) return null

  return (
    <HorizontalLayout width="auto" alignItems="center">
      <Button onClick={showSetBreadcrumbTimeRangeModal}>
        <Breadcrumbs color="$scienceBlue" size={17} />

        <Text marginLeft={8} marginRight={22} size="$m" color="$nightRider" weight="$regular">
          {startTime} - {endTime}
        </Text>
      </Button>
      <Separator />

      <SetBreadcrumbTimeRangeModal />
    </HorizontalLayout>
  )
}
