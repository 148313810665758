import { LocationEditorField, LocationEditorFieldWrappedInputProps } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

const wrappedInputProps: LocationEditorFieldWrappedInputProps = {
  modes: ['copyCoordinates'],
  height: 150,
}

export function Address() {
  const texts = useTexts()

  return (
    <>
      <LocationEditorField
        name="location"
        pinType="depot"
        inactive={false}
        snapToRoad={true}
        pinId="depotFormPin"
        ignoreMapBounds={false}
        label={texts.labelLocation}
        wrappedInputProps={wrappedInputProps}
        testid="depot-address"
        showAdjustAddress
      />
    </>
  )
}

Address.displayName = 'Address'
