import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatISO, subDays, subMinutes } from 'date-fns/esm'

import {
  debugSendPeriodicTestDataToTelematics,
  debugSendTestDataToTelematics,
} from '@/features/domain/demoTools'
import { useNotification, useIsUnmounted } from '@/hooks'
import { selectTelematicsTenantSources } from '@/features/domain/account'
import { configureTelematicsDemo } from '@/features/domain/territory'
import { selectTelematicsDevices } from '@/features/domain/device'
import { store, useAppDispatch } from '@/store'

import { useController } from './useController'

import * as nyRoute1 from './demo-routes/ny-route-1.json'
import * as nyRoute2 from './demo-routes/ny-route-2.json'
import * as nyRoute3 from './demo-routes/ny-route-3.json'
import * as nyRoute4 from './demo-routes/ny-route-4.json'

const demoRoutes = [nyRoute1, nyRoute2, nyRoute3, nyRoute4]

function computeHeading(
  prevPoint: { lat: number; lng: number },
  point: { lat: number; lng: number },
) {
  return (
    Math.trunc((Math.atan2(prevPoint.lng - point.lng, prevPoint.lat - point.lat) * 180) / Math.PI) +
    180
  )
}

function computeSpeed(dist: number, secondsOfInterval: number) {
  const MAX_SPEED = 125
  return Math.min(Math.trunc((3.6 * dist) / secondsOfInterval), 255, MAX_SPEED)
}

function computeDaysOfHistoricalData(
  route: {
    lat: number
    lng: number
    dist: number
  }[],
  from: Date,
  to: Date,
  mustComputeHeading: boolean = true,
  secondsOfInterval: number = 60 * 5,
) {
  const startTimestamp = from.getTime()
  const endTimestamp = to.getTime()

  let pointTimestamp = endTimestamp
  let index = route['default'].length - 1

  const breadcrumbs: {
    timestamp: string
    lat: number
    lng: number
    speed: number
    heading: number
    breadcrumbId: string
    odometer: number
    trackingProvider: 'telematics'
  }[] = []

  while (pointTimestamp > startTimestamp) {
    if (index < 0) index = route['default'].length - 1

    const { lat, lng, dist } = route['default'][index]

    const timestamp = formatISO(new Date(pointTimestamp))
    const heading = mustComputeHeading
      ? index > 0
        ? computeHeading(route['default'][index - 1], route['default'][index])
        : 0
      : -1

    const speed = computeSpeed(dist, secondsOfInterval)

    breadcrumbs.push({
      timestamp,
      lat,
      lng,
      speed,
      heading,
      odometer: dist,
      breadcrumbId: `breadcrumb-${index}`,
      trackingProvider: 'telematics',
    })

    pointTimestamp -= secondsOfInterval * 1000
    index--
  }

  return breadcrumbs.reverse()
}

function computeLiveBreadcrumbs(
  route: {
    lat: number
    lng: number
    dist: number
  }[],
  mustComputeHeading: boolean = true,
  secondsOfInterval: number = 60 * 5,
) {
  const breadcrumbs: {
    timestamp: string
    lat: number
    lng: number
    speed: number
    heading: number
    breadcrumbId: string
    odometer: number
    trackingProvider: 'telematics'
  }[] = []

  for (const [index, point] of route['default'].entries()) {
    const { lat, lng, dist } = point

    const timestamp = formatISO(new Date())
    const heading = mustComputeHeading
      ? index > 0
        ? computeHeading(route['default'][index - 1], point)
        : 0
      : -1

    const speed = computeSpeed(dist, secondsOfInterval)

    breadcrumbs.push({
      timestamp,
      lat,
      lng,
      speed,
      heading,
      odometer: dist,
      breadcrumbId: `breadcrumb-${index}`,
      trackingProvider: 'telematics',
    })
  }

  return breadcrumbs
}

export function useControllerActions() {
  const tenantSources = useSelector(selectTelematicsTenantSources)
  const isUnmounted = useIsUnmounted()
  const { close } = useController()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const [loading, setLoading] = useState(false)

  const onConfigureButtonClicked = useCallback(async () => {
    try {
      setLoading(true)
      // Delete all vehicles, create fake devices and create new vehicles
      const result = await dispatch(configureTelematicsDemo())

      if (configureTelematicsDemo.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      const deviceIds = Object.values(result.payload).flat().flat()

      const now = new Date()

      // Calculate the start and end date for the historical data (from 2 days ago to 5 minutes ago)
      const startDate = subDays(now, 2)
      const endDate = subMinutes(now, 5)

      // Calculate the seconds of interval for the historical data (5 minutes)
      const secondsOfInterval = 60 * 5

      const devices = selectTelematicsDevices(store.getState())

      for (let i = 0; i < deviceIds.length; i++) {
        const deviceId = deviceIds[i]
        const device = devices[deviceId]
        if (!device) {
          throw new Error(`Device not found: ${deviceId}`)
        }
        const tenantSource = tenantSources[device.tenantSourceId]
        const computeHeading = tenantSource.sourceLabel.toLowerCase() === 'linxup'

        const historyBreadcrumbs = computeDaysOfHistoricalData(
          demoRoutes[i],
          startDate,
          endDate,
          computeHeading,
          secondsOfInterval,
        )

        const liveBreadcrumbs = computeLiveBreadcrumbs(
          demoRoutes[i],
          computeHeading,
          secondsOfInterval,
        )

        dispatch(
          debugSendTestDataToTelematics({
            breadcrumbs: historyBreadcrumbs as uui.domain.server.gps.telematics.BreadcrumbGpsInfo[],
            deviceId,
            stops: [],
          }),
        )

        dispatch(
          debugSendPeriodicTestDataToTelematics({
            intervalDurationSeconds: 20,
            breadcrumbs: liveBreadcrumbs as uui.domain.server.gps.telematics.BreadcrumbGpsInfo[],
            deviceId,
            stops: [],
          }),
        )
      }

      setTimeout(() => {
        toast.success('Telematics demo configured successfully')

        if (isUnmounted()) return

        setLoading(false)
        close?.()
      }, 1000)
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, tenantSources, dispatch, isUnmounted, setLoading, close])

  return {
    onConfigureButtonClicked,
    loading,
  }
}
