import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function TargetWithArrow(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M7.36 0v1.31062C4.16598 1.6143 1.615 4.16592 1.31125 7.36H0v1.28h1.31125c.30376 3.19408 2.85473 5.7457 6.04875 6.04937V16h1.28v-1.31125c3.194-.30375 5.745-2.85476 6.04875-6.04875H16V7.36h-1.31125C14.385 4.166 11.83399 1.615 8.64 1.31125V0H7.36zm1.28 2.60062c2.49792.2912 4.46819 2.26146 4.75937 4.75938H12.48v1.28h.91937c-.29118 2.49792-2.26145 4.46819-4.75937 4.75937V12.48H7.36v.91875C4.86284 13.10717 2.89179 11.13771 2.60063 8.64H3.52V7.36h-.91937C2.89179 4.86229 4.86283 2.89283 7.36 2.60125V3.52h1.28v-.91938zM6.71313 6.07375c-.26032.00032-.49451.15827-.59235.3995-.09784.24123-.03983.51769.14672.69925L7.095 8l-.8275.8275a.64006.64006 0 1 0 .905.905L8 8.905l.8275.8275a.64006.64006 0 1 0 .905-.905L8.905 8l.8275-.8275c.18929-.184.2462-.46532.14332-.70842-.10288-.2431-.34446-.39811-.60832-.39033a.64006.64006 0 0 0-.44.19375L8 7.095l-.8275-.8275a.64006.64006 0 0 0-.45937-.19375z" />
      </svg>
    </IconContainer>
  )
}
