import { Component } from 'react'

import { ReadonlyLabel } from '../../../../../readonly/ReadonlyLabel'

import Search from './components/Search'
import { Collapsed } from './components/Collapsed'
import { SuggestionsList } from './components/SuggestionsList'
import UiLoads from './components/Loads'
import LoadsListWrapper from './components/LoadsListWrapper'
import PartialLoads from './components/PartialLoads'
import LoadPlaceholder from './components/LoadPlaceholder'

import { Props, PublicPropsBulk, Step } from './typings'

interface State {
  step: Step
  filterIsAlreadyUsed: boolean
}

const setStep = (step: Step) => () => ({
  step,
})

const setFilterIsAlreadyUsed = (filterIsAlreadyUsed: boolean) => () => ({
  filterIsAlreadyUsed,
})

const getFilterIsAlreadyUsed = (props: Props) => {
  const { value, filter } = props
  return !!Object.keys(value).find(v => v === filter)
}

const defaultMax: number = 20

export default class LoadsList extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      step: 'collapsed',
      filterIsAlreadyUsed: getFilterIsAlreadyUsed(this.props),
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { value: prevValue, filter: prevFilter } = prevProps
    const { value, filter } = this.props

    if (prevValue !== value || prevFilter !== filter) {
      this.setState(setFilterIsAlreadyUsed(getFilterIsAlreadyUsed(this.props)))
    }
  }

  private handleOnChangeLoad = (key: string, val: number) => {
    const { value, onChange } = this.props

    const updatedLoads: uui.domain.client.rm.Order['loads'] = {
      ...value,
      [key]: val,
    }

    onChange(updatedLoads)
  }

  private handleOnAddLoad = (load: string) => {
    const { onChangeFilter } = this.props
    const { filterIsAlreadyUsed } = this.state

    if (!filterIsAlreadyUsed) {
      this.handleOnChangeLoad(load, 0)
      onChangeFilter('')
    }
  }

  private handleOnDeleteLoad = (keyToDelete: string) => {
    const { value, onChange } = this.props

    const updatedLoads: uui.domain.client.rm.Order['loads'] = Object.entries(value).reduce(
      (acc: uui.domain.client.rm.Order['loads'], [key, val]) => {
        if (key !== keyToDelete) {
          acc[key] = val
        }
        return acc
      },
      {},
    )

    onChange(updatedLoads, [keyToDelete])
  }

  private handleOnChangeStep = () => {
    this.setState(setStep('search'))
  }

  render() {
    const { step, filterIsAlreadyUsed } = this.state
    const {
      filter,
      suggestions,
      value,
      onChangeFilter,
      maxCount = defaultMax,
      notInSuggestionListLabel,
      allSuggestions,
      type,
      renderText,
      useMetric,
    } = this.props

    const hasReachedMax: boolean = Object.keys(value).length >= maxCount

    const hasNoLoads: boolean = Object.keys(value).length === 0

    const bulkProps: PublicPropsBulk = this.props as PublicPropsBulk

    return (
      <LoadsListWrapper>
        {(step === 'collapsed' || hasReachedMax) && (
          <Collapsed
            onChangeStep={this.handleOnChangeStep}
            disabled={hasReachedMax}
            buttonText={renderText('addLoad')}
            labelText={renderText('maxCountReached')}
          />
        )}

        {step === 'search' && !hasReachedMax && (
          <>
            <Search
              onChange={onChangeFilter}
              filter={filter}
              onAddLoad={this.handleOnAddLoad}
              placeholderText={renderText('searchPlaceholder')}
            />
            <SuggestionsList
              list={suggestions}
              onAddLoad={this.handleOnAddLoad}
              renderText={renderText}
              filter={filter}
              filterIsAlreadyUsed={filterIsAlreadyUsed}
            />
          </>
        )}

        {type === 'bulk' && (
          <ReadonlyLabel primary style={{ marginTop: 10 }}>
            {renderText('sharedLoad')}
          </ReadonlyLabel>
        )}

        {type === 'bulk' && hasNoLoads && (
          <LoadPlaceholder>{renderText('noLoads')}</LoadPlaceholder>
        )}

        <UiLoads
          loads={value}
          suggestions={allSuggestions}
          mixedNumericValue={bulkProps.mixedNumericValue || undefined}
          notInSuggestionListLabel={notInSuggestionListLabel}
          onChangeLoad={this.handleOnChangeLoad}
          onDeleteLoad={this.handleOnDeleteLoad}
          renderText={renderText}
          useMetric={useMetric}
        />

        {type === 'bulk' && <PartialLoads {...bulkProps} />}
      </LoadsListWrapper>
    )
  }
}
