import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function NoDispatch(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 10 10">
        <path
          d="M2.763 4.088l1.524 1.525-.803.65-.186 1.898 1.274-1.438.346-.479 2.48 2.48-.13.52c-.01.045-.04.083-.078.107l-.042.017c-.016.005-.032.007-.047.007-.046 0-.09-.018-.122-.05L4.71 7.09 3.204 8.791c-.034.039-.081.06-.13.06-.023 0-.047-.006-.07-.016-.068-.03-.11-.1-.102-.176l.23-2.364-2.388-.78c-.066-.021-.112-.08-.118-.15-.007-.068.029-.135.09-.167l2.047-1.11zM9.119.646c.06-.033.134-.027.188.016.055.041.079.112.063.179L7.928 6.602 6.03 4.704 6.9 3.5l-1.147.927-1.271-1.271zM.606.606c.166-.166.427-.182.61-.045l.053.045 8.125 8.125c.183.183.183.48 0 .663-.166.166-.427.182-.61.045l-.053-.045L.606 1.269c-.183-.183-.183-.48 0-.663z"
          transform="translate(-326 -632) translate(103 618) translate(46 2) translate(177 12)"
        />
      </svg>
    </IconContainer>
  )
}
