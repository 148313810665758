import { useCallback, useRef } from 'react'

import { useDebounce } from '@/hooks'
import { Block, Label, TextInput, FieldMessage } from '@/forms-legacy'

interface Props {
  label: string
  value: string
  onChange: (label: string, value: string) => void
  error?: string
  testId?: string
  disabled?: boolean
}

export function CustomFieldField(props: Props) {
  const { error, testId, label, value, onChange, disabled } = props

  const inputRef = useRef(null)

  const handleOnChangeValue = useCallback(
    (value: string) => {
      onChange(label, value)
    },
    [onChange, label],
  )

  const [debounceValue, debounceOnChange] = useDebounce({
    value,
    onChange: handleOnChangeValue,
    delay: 100,
  })

  return (
    <Block error={!!error} data-testid={testId} data-trackid={testId}>
      <Label>{label}</Label>
      <TextInput
        name={label}
        onChange={debounceOnChange}
        value={debounceValue}
        innerRef={inputRef}
        disabled={disabled}
      />

      {!!error && <FieldMessage error>{error}</FieldMessage>}
    </Block>
  )
}
