import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    drivingTime: (speedFactor: string) =>
      translate({ id: 'setup.traffic.drivingTimeText', values: { speedFactor } }),
    view: translate({ id: 'global.view' }),
    recurrence: (recurrence: uui.domain.rm.Recurrence) => {
      switch (recurrence.type) {
        case 'any':
          return translate({ id: 'setup.traffic.recurrence.any' })
        case 'ANNUAL':
          return translate({ id: 'setup.traffic.recurrence.annual' })
        case 'MONTHLY':
          return translate({ id: 'setup.traffic.recurrence.monthly' })
        case 'WEEKLY':
          return translate({ id: 'setup.traffic.recurrence.weekly' })
        case 'DATE':
          return translate({ id: 'setup.traffic.recurrence.date' })
        default:
          return ''
      }
    },
  }))

  return api
}
