import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    addConnectionButtonTitle: translate({
      id: 'settings.integrations.createTenantSource.addConnectionButtonTitle',
    }),
    cancelButtonTitle: translate({
      id: 'global.cancel',
    }),
    azuga: {
      headerTitle: translate({
        id: 'settings.integrations.createTenantSource.azuga.headerTitle',
      }),
      label: translate({
        id: 'settings.integrations.createTenantSource.azuga.label',
      }),
      labelHelperText: translate({
        id: 'settings.integrations.createTenantSource.azuga.label.helperText',
      }),
      instructions: translate({
        id: 'settings.integrations.createTenantSource.azuga.instructions',
      }),
      apiKeyLabel: translate({
        id: 'settings.integrations.createTenantSource.azuga.apiKey',
      }),
      suggestion: translate({
        id: 'settings.integrations.createTenantSource.azuga.suggestion',
      }),
      errors: {
        apiKeyIncorrect: translate({
          id: 'settings.integrations.createTenantSource.azuga.errors.apiKeyIncorrect',
        }),
        apiKeyRequired: translate({
          id: 'settings.integrations.createTenantSource.azuga.errors.apiKeyRequired',
        }),
        apiKeyAlreadyUsed: translate({
          id: 'settings.integrations.createTenantSource.azuga.errors.apiKeyAlreadyUsed',
        }),
        labelRequired: translate({
          id: 'settings.integrations.createTenantSource.azuga.errors.labelRequired',
        }),
        labelUnique: translate({
          id: 'settings.integrations.createTenantSource.azuga.errors.labelUnique',
        }),
      },
    },
    linxup: {
      headerTitle: translate({
        id: 'settings.integrations.createTenantSource.linxup.headerTitle',
      }),
      label: translate({
        id: 'settings.integrations.createTenantSource.linxup.label',
      }),
      labelHelperText: translate({
        id: 'settings.integrations.createTenantSource.linxup.label.helperText',
      }),
      instructions: translate({
        id: 'settings.integrations.createTenantSource.linxup.instructions',
      }),
      apiKeyLabel: translate({
        id: 'settings.integrations.createTenantSource.linxup.apiKey',
      }),
      suggestion: translate({
        id: 'settings.integrations.createTenantSource.linxup.suggestion',
      }),
      errors: {
        apiKeyIncorrect: translate({
          id: 'settings.integrations.createTenantSource.linxup.errors.apiKeyIncorrect',
        }),
        apiKeyRequired: translate({
          id: 'settings.integrations.createTenantSource.linxup.errors.apiKeyRequired',
        }),
        apiKeyAlreadyUsed: translate({
          id: 'settings.integrations.createTenantSource.linxup.errors.apiKeyAlreadyUsed',
        }),
        labelRequired: translate({
          id: 'settings.integrations.createTenantSource.linxup.errors.labelRequired',
        }),
        labelUnique: translate({
          id: 'settings.integrations.createTenantSource.linxup.errors.labelUnique',
        }),
      },
    },
    verizonConnect: {
      headerTitle: translate({
        id: 'settings.integrations.createTenantSource.verizonConnect.headerTitle',
      }),
      label: translate({
        id: 'settings.integrations.createTenantSource.verizonConnect.label',
      }),
      labelHelperText: translate({
        id: 'settings.integrations.createTenantSource.verizonConnect.label.helperText',
      }),
      instructions: translate({
        id: 'settings.integrations.createTenantSource.verizonConnect.instructions',
      }),
      usernameLabel: translate({
        id: 'settings.integrations.createTenantSource.verizonConnect.username',
      }),
      passwordLabel: translate({
        id: 'settings.integrations.createTenantSource.verizonConnect.password',
      }),
      errors: {
        credentialsAlreadyInUse: translate({
          id: 'settings.integrations.createTenantSource.verizonConnect.errors.credentialsAlreadyInUse',
        }),

        usernameIncorrect: translate({
          id: 'settings.integrations.createTenantSource.verizonConnect.errors.usernameIncorrect',
        }),
        passwordIncorrect: translate({
          id: 'settings.integrations.createTenantSource.verizonConnect.errors.passwordIncorrect',
        }),
        databaseIncorrect: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.databaseIncorrect',
        }),

        labelRequired: translate({
          id: 'settings.integrations.createTenantSource.verizonConnect.errors.labelRequired',
        }),
        labelUnique: translate({
          id: 'settings.integrations.createTenantSource.verizonConnect.errors.labelUnique',
        }),

        passwordRequired: translate({
          id: 'settings.integrations.createTenantSource.verizonConnect.errors.passwordRequired',
        }),
        usernameRequired: translate({
          id: 'settings.integrations.createTenantSource.verizonConnect.errors.usernameRequired',
        }),

        databaseRequired: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.databaseRequired',
        }),

        databaseUnique: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.databaseUnique',
        }),
      },
    },

    geotab: {
      headerTitle: translate({
        id: 'settings.integrations.createTenantSource.geotab.headerTitle',
      }),
      label: translate({
        id: 'settings.integrations.createTenantSource.geotab.label',
      }),
      labelHelperText: translate({
        id: 'settings.integrations.createTenantSource.geotab.label.helperText',
      }),
      instructions: translate({
        id: 'settings.integrations.createTenantSource.geotab.instructions',
      }),
      usernameLabel: translate({
        id: 'settings.integrations.createTenantSource.geotab.username',
      }),
      passwordLabel: translate({
        id: 'settings.integrations.createTenantSource.geotab.password',
      }),
      databaseLabel: translate({
        id: 'settings.integrations.createTenantSource.geotab.database',
      }),
      errors: {
        credentialsAlreadyInUse: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.credentialsAlreadyInUse',
        }),
        usernameIncorrect: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.usernameIncorrect',
        }),
        passwordIncorrect: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.passwordIncorrect',
        }),
        labelRequired: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.labelRequired',
        }),
        labelUnique: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.labelUnique',
        }),
        passwordRequired: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.passwordRequired',
        }),
        databaseRequired: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.databaseRequired',
        }),
        databaseUnique: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.databaseUnique',
        }),
        databaseIncorrect: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.databaseIncorrect',
        }),
        usernameRequired: translate({
          id: 'settings.integrations.createTenantSource.geotab.errors.usernameRequired',
        }),
      },
    },

    integrationConnectedToast: translate({
      id: 'settings.integrations.createTenantSource.integrationConnectedToast',
    }),
  }))

  return api
}
