import { IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

import { resetRoutingSidebar } from '@/atoms'
import { isDark, theme } from '@/styles'

import { useTexts } from '../../../../hooks/useTexts'
import { RouteBadge } from './components/RouteBadge'
import { RouteTitle } from './components/RouteTitle'

interface Props {
  idx: string
  routeColor: string
  violations: uui.domain.rm.RouteViolations
  violationsCount: number
}

export function RouteDetailsTitle(props: Props) {
  const { idx, routeColor, violations, violationsCount } = props
  const texts = useTexts()

  const title = `${texts.route} ${idx}`
  const textColor = isDark(routeColor) ? theme.colors.$pureWhite : theme.colors.$nightRider

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={2}>
        <RouteBadge
          textColor={textColor}
          data-testid="routeBadge"
          hasViolations={violationsCount > 0}
          backgroundColor={routeColor}
        >
          <Typography variant="h6" data-testid="routeId">
            {idx}
          </Typography>
        </RouteBadge>

        <RouteTitle violations={violations}>{title}</RouteTitle>
      </Stack>

      <IconButton onClick={resetRoutingSidebar} data-testid="closePanelButton">
        <Close />
      </IconButton>
    </Stack>
  )
}
