import { Component, ReactNode } from 'react'

import { intl } from '@/intl'
import { clsx } from '@/utils'
import { Field, HourPicker } from '@/forms-legacy'

export const getStartText = (): string => intl.translate({ id: 'global.start' })
export const getEndText = (): string => intl.translate({ id: 'global.end' })

export type Props = uui.domain.rm.TimeWindow & {
  name: string
  onChange: (timeWindow: uui.domain.rm.TimeWindow) => void
  labelStart?: string
  labelEnd?: string
  className?: string
  h24?: boolean
}

export default class TimeWindow extends Component<Props> {
  onChangeStart = (startSec: number): void => {
    const { endSec, onChange } = this.props
    const res: uui.domain.rm.TimeWindow = {
      startSec,
      endSec,
    }
    onChange(res)
  }

  onChangeEnd = (endSec: number): void => {
    const { startSec, onChange } = this.props
    const res: uui.domain.rm.TimeWindow = {
      startSec,
      endSec,
    }
    onChange(res)
  }

  renderStart(): ReactNode {
    const { labelStart: label = getStartText(), startSec, name, h24 } = this.props

    return (
      <div className="o-time-window__row">
        <Field label={label} showCheckbox={false}>
          <HourPicker
            name={`${name}-from`}
            value={startSec}
            onChange={this.onChangeStart}
            h24={!!h24}
          />
        </Field>
      </div>
    )
  }

  renderEnd(): ReactNode {
    const { labelEnd: label = getEndText(), endSec, name, h24 } = this.props

    return (
      <div className="o-time-window__row">
        <Field label={label} showCheckbox={false}>
          <HourPicker name={`${name}-to`} value={endSec} onChange={this.onChangeEnd} h24={h24} />
        </Field>
      </div>
    )
  }

  render() {
    const { className = '' } = this.props
    const rootClassName = clsx({
      [className]: true,
      'o-time-window': true,
    })

    return (
      <div className={rootClassName}>
        <div className="o-time-window__row">
          <div className="o-time-window__col o-time-window__col--50">{this.renderStart()}</div>
          <div className="o-time-window__col o-time-window__col--50">{this.renderEnd()}</div>
        </div>
      </div>
    )
  }
}
