import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Van(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 25 25">
        <path d="M20.63344 13.42969l-.96953-1.20052-1.72258-3.31901C17.64914 8.34896 17.06875 8 16.42593 8H5.36C4.6096 8 4 8.59766 4 9.33333v7c0 .73568.6096 1.33334 1.36 1.33334h1.05453C6.57258 18.42578 7.25922 19 8.08 19s1.50742-.57422 1.66547-1.33333h6.52906C16.43258 18.42578 17.11922 19 17.94 19s1.50742-.57422 1.66547-1.33333H19.98c.5618 0 1.02-.44922 1.02-1v-2.20313c0-.3737-.13016-.74219-.36656-1.03385zM17.3357 9.21354l1.58047 3.04427-2.84219-.21875c-.14476-.01172-.26695-.11328-.3028-.2513l-.79555-3.1211h1.4503c.3865 0 .73446.20964.90977.54688zM8.08 18.33334c-.5618 0-1.02-.44923-1.02-1 0-.55079.4582-1 1.02-1 .5618 0 1.02.44921 1.02 1 0 .55077-.4582 1-1.02 1zm9.86 0c-.5618 0-1.02-.44923-1.02-1 0-.55079.4582-1 1.02-1 .5618 0 1.02.44921 1.02 1 0 .55077-.4582 1-1.02 1z" />
      </svg>
    </IconContainer>
  )
}
