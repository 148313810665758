import type { View } from './types'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import {
  SecondaryColumnHeaderActionsRow,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsSeparator,
  SecondaryColumnContentHorizontalRuler,
  ConfirmPanel,
} from '@/forms-legacy'

import { useIsSimulation } from '@/hooks'
import { Duplicate, Trash } from '@/icons'
import { selectTerritoryLicensingLimits } from '@/features/domain/territory'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { useCountRemainingVehicle } from '../../hooks/useCountRemainingVehicle'

import { useActions } from './hooks/useActions'
import { useFlags } from './hooks/useFlags'
import { useTexts } from './hooks/useTexts'

interface Props {
  view: View
  setView: (view: View) => void
  unifiedVehicles: uui.domain.client.UnifiedVehicle[]
}

export function ActionsManager(props: Props) {
  const { view, unifiedVehicles, setView } = props

  const territoryLicensingLimits = useSelector(selectTerritoryLicensingLimits)

  const { duplicateDisabled } = useFlags(unifiedVehicles)
  const { onClone, onDelete } = useActions(unifiedVehicles, setView)
  const addableVehicles = useCountRemainingVehicle()
  const isSimulation = useIsSimulation()

  const texts = useTexts()

  const setDefaultView = useCallback(() => setView('default'), [setView])
  const setDeleteView = useCallback(() => setView('delete'), [setView])

  const vehiclesLimit = isSimulation
    ? territoryLicensingLimits.vehiclesSim
    : territoryLicensingLimits.vehiclesOps

  switch (view) {
    case 'delete':
      return (
        <ConfirmPanel
          title={texts.deleteTitle}
          description={texts.deleteDescription}
          confirmationText={texts.deleteConfirm}
          onCancel={setDefaultView}
          onConfirm={onDelete}
        />
      )

    case 'default':
    default:
      return (
        <>
          <SecondaryColumnHeaderActionsRow>
            <ReadOnlyTooltip
              canEdit={!duplicateDisabled}
              title={texts.duplicateDisabledTooltipTitle(addableVehicles, isSimulation)}
              subtitle={texts.duplicateDisabledTooltipSubtitle(
                addableVehicles,
                isSimulation,
                vehiclesLimit,
              )}
              render={preventEditing => (
                <SecondaryColumnHeaderActionButton
                  label={texts.duplicate}
                  icon={<Duplicate />}
                  onClick={onClone}
                  disabled={preventEditing || !!duplicateDisabled}
                  className="pendo-setup-vehicle__bulkduplicate"
                  testid="setup-vehicle__bulkduplicate"
                />
              )}
            />

            <SecondaryColumnHeaderActionsSeparator />

            <ReadOnlyTooltip
              render={preventEditing => (
                <SecondaryColumnHeaderActionButton
                  label={texts.delete}
                  icon={<Trash />}
                  onClick={setDeleteView}
                  disabled={preventEditing}
                  className="pendo-setup-vehicle__bulkdelete"
                  testid="setup-vehicle__bulkdelete"
                />
              )}
            />
          </SecondaryColumnHeaderActionsRow>
          <SecondaryColumnContentHorizontalRuler bigBottomSpace />
        </>
      )
  }
}
