import { Tick } from '@/icons'

import { useStyles } from './hooks/useStyles'

export function ActiveIcon() {
  const classes = useStyles()

  return (
    <span className={classes.tick}>
      <Tick size={12} color="$scienceBlue" marginTop={2} marginRight={10} />
    </span>
  )
}
