import { useSelector } from 'react-redux'
import { selectUserType } from '@/features/domain/user'

export const useIsUserType = (userTypes: uui.domain.rm.RouteManagerUserType[]) => {
  const userType = useSelector(selectUserType)

  if (userTypes.length === 0) {
    return true
  }

  return userTypes.includes(userType)
}
