import type { Props as FlexRowProps } from '../../../../lib/FlexRow'

import styled from 'styled-components'

import FlexRow from '../../../../lib/FlexRow'

import AssetListText from './AssetListText'

type Props = FlexRowProps & {
  selected?: boolean
}

const comp = styled(FlexRow)<Props>``
const AssetListItemWrapper = styled(comp).attrs<Props>(() => ({
  as: 'button',
  type: 'button',
}))`
  width: 100%;
  cursor: pointer;
  border: 0;
  padding: 0;
  background: ${p => (p.selected ? p.theme.colors.$alabaster : 'transparent')};
  outline: 0;
  height: 24px;
  border-radius: 4px;
  text-align: left;

  &:hover {
    ${AssetListText} {
      color: ${p => p.theme.colors.$scienceBlue};
    }
  }
`

AssetListItemWrapper.displayName = 'AssetListItemWrapper'
export default AssetListItemWrapper
