import { Button } from '@mui/material'

import { useCallback } from 'react'

import { DropdownMenuItem } from '@/components/DropdownMenu'
import { Link, useGpsReportsLink } from '@/routing'
import { clsx } from '@/utils'

import { getSelectedDeviceIds } from '../../../utils/getSelectedDeviceIds'
import { useTexts } from '../../../useTexts'
import { ActiveIcon } from './ActiveIcon'

import { useStyles } from './hooks/useStyles'

type MainSections = uui.routing.MainSections

export function GpsOnlyLinks() {
  const { gpsReportsLink, createGpsReportsCookie } = useGpsReportsLink()
  const classes = useStyles()
  const texts = useTexts()

  const onGpsReportClick = useCallback(() => {
    createGpsReportsCookie(getSelectedDeviceIds())
  }, [createGpsReportsCookie])

  return (
    <>
      <Link<MainSections>
        to="tracking"
        data-testid="header__nav-tracking-button-link"
        className={clsx(classes.link, 'pendo-bluebar__tracking-list-menu')}
      >
        <DropdownMenuItem
          data-testid="header__nav-tracking-button-content"
          data-trackid="gpsonly-header__nav-tracking-button-content"
        >
          <ActiveIcon />
          {texts.tracking}
        </DropdownMenuItem>
      </Link>

      <Button
        href={gpsReportsLink}
        target="_blank"
        data-testid="header__nav-reports-button-link"
        className={clsx(classes.link, 'pendo-bluebar__GPS-reports-menu')}
        onClick={onGpsReportClick}
      >
        <DropdownMenuItem
          data-testid="header__nav-reports-button-content"
          data-trackid="gpsonly-header__nav-reports-button-content"
        >
          <ActiveIcon />
          {texts.reports}
        </DropdownMenuItem>
      </Button>
    </>
  )
}
