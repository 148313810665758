import type { SegmentType } from '../../types'

import { StopSign, Watch } from '@/icons'

interface Props {
  segmentType: SegmentType
  size: number
}

export function RoutingSegmentAvatarIcon(props: Props) {
  const { segmentType, size } = props

  switch (segmentType.type) {
    case 'blocked':
      return <StopSign size={size} />
    case 'delayed':
      return <Watch size={size} />
    case 'disabled':
      return null
  }
}
