import type { ActionPermissions } from './types'

import { useMemo } from 'react'

import { useRoutesNavigator } from '@/components/Navigo/entities/Routes/hooks/useRoutesNavigator'

import { computeExportToEmailPermission } from './utils/computeExportToEmailPermission'
import { computeRouteDetailsPermission } from './utils/computeRouteDetailsPermission'
import { computeVehicleJsonPermission } from './utils/computeVehicleJsonPermission'
import { computeUnassignPermission } from './utils/computeUnassignPermission'
import { computeDispatchPermission } from './utils/computeDispatchPermission'
import { computeReversePermission } from './utils/computeReversePermission'
import { computeRevokePermission } from './utils/computeRevokePermission'
import { computeExportPermission } from './utils/computeExportPermission'
import { computeUnlockPermission } from './utils/computeUnlockPermission'
import { computeBuildPermission } from './utils/computeBuildPermission'
import { computeLockPermission } from './utils/computeLockPermission'
import { computeSwapPermission } from './utils/computeSwapPermission'
import { computeEditPermission } from './utils/computeEditPermission'

import { usePermissionsData } from './hooks/usePermissionsData'

export function usePermissions(): ActionPermissions {
  const { currentRoutes } = useRoutesNavigator()

  const permissionsData = usePermissionsData(currentRoutes)

  return useMemo(
    () => ({
      exportToEmail: computeExportToEmailPermission(permissionsData),
      routeDetails: computeRouteDetailsPermission(permissionsData),
      vehicleJson: computeVehicleJsonPermission(permissionsData),
      unassign: computeUnassignPermission(permissionsData),
      dispatch: computeDispatchPermission(permissionsData),
      reverse: computeReversePermission(permissionsData),
      export: computeExportPermission(permissionsData),
      revoke: computeRevokePermission(permissionsData),
      unlock: computeUnlockPermission(permissionsData),
      build: computeBuildPermission(permissionsData),
      lock: computeLockPermission(permissionsData),
      swap: computeSwapPermission(permissionsData),
      edit: computeEditPermission(permissionsData),
    }),
    [permissionsData],
  )
}
