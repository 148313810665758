import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    sortBy: (field: string) => {
      switch (field) {
        case 'name':
          return translate({ id: 'setup.regions.list.header.sortBy.name' })

        case 'enterCost':
          return translate({ id: 'setup.regions.list.header.sortBy.additionalCost' })

        case 'enterTimeSec':
          return translate({ id: 'setup.regions.list.header.sortBy.additionalDrivingTime' })

        default:
          console.warn(`Unknown region field: ${field}`)
          return ''
      }
    },

    sortByField: (field: string) =>
      translate({ id: 'setup.regions.list.header.sortBy', values: { field } }),

    sortByName: translate({ id: 'setup.regions.list.header.sortBy.name' }),
    sortAscending: translate({ id: 'setup.regions.list.header.sortBy.ascending' }),
    sortDescending: translate({ id: 'setup.regions.list.header.sortBy.descending' }),
    sortByAdditionalCost: translate({ id: 'setup.regions.list.header.sortBy.additionalCost' }),
    sortByAdditionalDrivingTime: translate({
      id: 'setup.regions.list.header.sortBy.additionalDrivingTime',
    }),
  }))

  return api
}
