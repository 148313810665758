import type { OnSelectCallbackParams } from '@/components/StructuredVirtualList'

import { useCallback } from 'react'
import { fitMapToSelection } from '@/map'
import { useDataForList } from './useDataForList'

export function useListHandlers() {
  const { selectTrafficProfiles } = useDataForList()

  const onSelect = useCallback(
    (params: OnSelectCallbackParams<'trafficProfiles'>) => {
      const ids: string[] = []

      for (const id of params.newSelectedIds) {
        if (id.startsWith('group')) continue
        ids.push(id)
      }

      selectTrafficProfiles(ids)

      // try to select it right away and
      // queue a pending fitMap request
      // it will be applied by the destination view when it opens
      fitMapToSelection({ preventIfVisible: true }, 'add')
    },
    [selectTrafficProfiles],
  )

  return {
    onSelect,
  }
}
