import { useMemo } from 'react'

import { Text, Stack } from '@/local/components'

import { NotificationBody } from './NotificationBody'

interface Props {
  body: string
  subject: string
}

export function EmailBody(props: Props) {
  const { body, subject } = props

  const rows = useMemo(() => body.split('\n'), [body])

  return (
    <Stack space="s">
      <Text weight="$semiBold" size="$p3">
        {subject}
      </Text>
      <NotificationBody rows={rows} />
    </Stack>
  )
}
