import { Box } from '@mui/material'
import { ArrowUpward } from '@mui/icons-material'

import { HorizontalLayout } from '@/components/layout'
import { Text } from '@/local/components'
import { geo } from '@/server-data'

import { useTexts } from '../useTexts'

type Props = {
  heading: number
}

const noHeadingIconStyle = {
  width: 10,
  height: 10,
  border: '1px dashed rgba(14, 40, 63, 0.55)',
  marginTop: -3,
  marginLeft: 3,
  marginRight: 2,
  borderRadius: 5,
}

function HeadingIcon(props: Props) {
  const { heading } = props

  const cardinalDirection = geo.computeCardinalDirection(heading)

  switch (cardinalDirection) {
    case 'INVALID':
      return <div style={noHeadingIconStyle} />

    case 'N':
      return <ArrowUpward fontSize="inherit" />

    case 'NNE':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '22.5deg' }} />
      )

    case 'NE':
      return <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '45deg' }} />

    case 'ENE':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '67.5deg' }} />
      )

    case 'E':
      return <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '90deg' }} />

    case 'ESE':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '112.5deg' }} />
      )

    case 'SE':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '135deg' }} />
      )

    case 'SSE':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '157.5deg' }} />
      )

    case 'S':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '180deg' }} />
      )

    case 'SSW':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '202.5deg' }} />
      )

    case 'SW':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '225deg' }} />
      )

    case 'WSW':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '247.5deg' }} />
      )

    case 'W':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '270deg' }} />
      )

    case 'WNW':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '292.5deg' }} />
      )

    case 'NW':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '315deg' }} />
      )

    case 'NNW':
      return (
        <ArrowUpward fontSize="inherit" sx={{ transformOrigin: '50% 50%', rotate: '337.5deg' }} />
      )
  }
}

export function Heading(props: Props) {
  const { heading } = props

  const texts = useTexts()

  return (
    <HorizontalLayout alignItems="center">
      <HorizontalLayout alignItems="center">
        <Box marginRight={0.8} marginLeft={-0.4} marginTop={0.3}>
          <HeadingIcon heading={heading} />
        </Box>

        <Text size="$p3">{heading === -1 ? texts.noHeading : texts.heading}</Text>

        {heading !== -1 && (
          <Text size="$p3" weight="$semiBold" style={{ fontSize: 11 }}>
            :&nbsp;{geo.computeVerboseCardinalDirection(heading)}
          </Text>
        )}
      </HorizontalLayout>
    </HorizontalLayout>
  )
}
