import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { resetMainSelection, useEditingStateWithCtx, usePagination } from '@/atoms'
import { selectUserConfiguration } from '@/features/domain/user'
import { useImportWizardLink } from '@/routing'
import { importWizardSession } from '@/utils'
import { startImportWizard } from '@/features/domain/transaction'
import { useAppDispatch } from '@/store'

export function useActions() {
  const { editing, setEditing } = useEditingStateWithCtx('order')
  const userConfiguration = useSelector(selectUserConfiguration)
  const { setPage } = usePagination('orderTab')
  const dispatch = useAppDispatch()
  const url = useImportWizardLink()

  const onCreate = useCallback(() => {
    resetMainSelection('orderSteps')
    setPage(0)
    setEditing([])
  }, [setEditing, setPage])

  const onImport = useCallback(async () => {
    const { planType } = userConfiguration

    if (!['operations', 'simulation'].includes(planType)) {
      throw new Error(
        `It is not possible to import orders when planType = ${planType}. It should not happen`,
      )
    }

    // Retrieve cookie content from server-data
    const response = await dispatch(startImportWizard(globalThis.location.href))

    if (startImportWizard.rejected.match(response) || !response.payload) {
      throw new Error('Request failed')
    }

    importWizardSession.initializeImport(response.payload)

    // Redirect to import wizard
    window.location.href = url
  }, [dispatch, url, userConfiguration])

  return { onCreate, onImport, editing }
}
