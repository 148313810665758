import type OlMap from 'ol/Map'

import VectorImageLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { setLayerMetadata } from '../layerMetadata'

export const createGeofenceLayer = (map: OlMap) => {
  const layer = new VectorImageLayer({ source: new VectorSource() })

  setLayerMetadata(layer, 'uid', 'geofence')

  map.addLayer(layer)
}
