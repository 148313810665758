import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    cancelButtonTitle: translate({ id: 'unassignOrderModal.cancelButton.title' }),
    commandButtonTitle: translate({ id: 'unassignOrderModal.commandButton.title' }),
    unassignAlso: (type: string) =>
      translate({
        id:
          type === 'p'
            ? 'unassignOrderModal.unassignAlso.dropoff'
            : 'unassignOrderModal.unassignAlso.pickup',
      }),
    unassignAlsoBulk: translate({ id: 'unassignOrderModal.unassignAlso.bulk' }),
    genericUnassignOrderError: translate({ id: 'unassignOrderModal.error.generic' }),
    title: (type: string) =>
      translate({
        id: type === 'p' ? 'unassignOrderModal.title.pickup' : 'unassignOrderModal.title.dropoff',
      }),
    titleBulk: translate({ id: 'unassignOrderModal.title.bulk' }),
    subtitle: ({ name, serviceTime }: { name: string; serviceTime: string | null }) =>
      !!serviceTime
        ? translate({
            id: 'unassignOrderModal.subtitle.serviceTime',
            values: { name, serviceTime },
          })
        : translate({
            id: 'unassignOrderModal.subtitle',
            values: { name },
          }),
    subtitleBulk: (ordersAmount: number) =>
      translate({
        id:
          ordersAmount > 1
            ? 'unassignOrderModal.subtitle.bulk.plural'
            : 'unassignOrderModal.subtitle.bulk.singular',
        values: { ordersAmount },
      }),
    transactionWarningCardTitle: translate({
      id: 'unassignOrderModal.transaction.warningCard.title',
    }),
    transactionWarningCardDescription: translate({
      id: 'unassignOrderModal.transaction.warningCard.description',
    }),
    warningCardTitle: ordersAmount =>
      translate({
        id:
          ordersAmount > 1
            ? 'unassignOrderModal.warningCard.title.plural'
            : 'unassignOrderModal.warningCard.title.singular',
      }),
    warningCardDescription: translate({ id: 'unassignOrderModal.warningCard.description' }),
  }))

  return api
}
