import type { Option } from '../types'

import { useMemo } from 'react'

import { useTexts } from './useTexts'

export function useOptions(includeMixedOption: boolean = false): Option[] {
  const texts = useTexts()

  return useMemo(() => {
    const optionMixed = {
      value: 'mixed' as const,
      label: texts.mixed,
      disabled: true,
    }

    const optionAny = {
      value: 'any' as const,
      label: texts.any,
    }

    const optionOn = {
      value: 'on' as const,
      label: texts.on,
    }

    const optionBy = {
      value: 'by' as const,
      label: texts.by,
    }

    return includeMixedOption
      ? [optionMixed, optionAny, optionOn, optionBy]
      : [optionAny, optionOn, optionBy]
  }, [texts, includeMixedOption])
}
