import { useCallback } from 'react'

import { useNavigoView as useCoreNavigoView } from '@/atoms'

export const useNavigoView = () => {
  const [state, setState] = useCoreNavigoView('orderSteps')

  const navigate = {
    goToOverview: useCallback(
      () => setState({ category: 'orderSteps', view: 'overview' }),
      [setState],
    ),

    goToMapStyle: useCallback(
      () => setState({ category: 'orderSteps', view: 'mapStyle' }),
      [setState],
    ),

    goToExecutionDetails: useCallback(
      () => setState({ category: 'orderSteps', view: 'executionDetails' }),
      [setState],
    ),
  }

  if (state.category === 'none') return { navigate, view: 'none' }

  return {
    view: state.view,
    navigate,
  }
}
