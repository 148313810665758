export function hasOrdersWithExecutionEvents(
  route: uui.domain.client.rm.ExtendedRoute,
  orders: Record<string, uui.domain.client.rm.Order>,
) {
  return route.route.steps.some(routeStep => {
    if (!routeStep.ref) return false
    const order = orders[routeStep.ref]
    if (!order) return false

    return hasOrderStepExecutionEvents(order, routeStep.type)
  })
}

function hasOrderStepExecutionEvents(
  order: uui.domain.client.rm.Order,
  type: uui.domain.client.rm.RouteStep['type'],
) {
  switch (type) {
    case 'pickup':
      return order.type === 'p' ? !order.pickup.trackingData.isEmpty : false
    case 'delivery':
      return order.type === 'd' ? !order.delivery.trackingData.isEmpty : false
    default:
      return false
  }
}
