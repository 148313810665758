import { useRef, useCallback, useMemo } from 'react'
import { Checkbox, FormControlLabel, MenuItem, Radio, Typography } from '@mui/material'
import {
  ListHeaderFilterInput,
  ListHeaderFilterMenu,
  ListHeaderFilterContainer,
  ListHeaderFilterMenuTitle,
  type ListHeaderSecondaryActionRef,
} from '@/components/List'

import { useTexts } from '../../useTexts'
import { useFilter } from './hooks/useFilter'

export function Filter() {
  const { query, filter, setFilter, setQuery, editing, noEntries } = useFilter()

  const texts = useTexts()

  const filterMenuRef = useRef<ListHeaderSecondaryActionRef>(null)
  const filterActive = useMemo(
    () => filter.type !== 'all' || filter.state !== 'all' || filter.violated || filter.expiring,
    [filter],
  )

  const onAllTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'all',
      state: filter.state,
      violated: filter.violated,
      expiring: filter.expiring,
    })
  }, [filter, setFilter])

  const onAssignedTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'assigned',
      state: filter.state,
      violated: filter.violated,
      expiring: filter.expiring,
    })
  }, [filter, setFilter])

  const onUnassignedTypeFilterClick = useCallback(() => {
    setFilter({
      type: 'unassigned',
      state: filter.state,
      violated: filter.violated,
      expiring: filter.expiring,
    })
  }, [filter, setFilter])

  const onAllStateFilterClick = useCallback(() => {
    setFilter({
      type: filter.type,
      state: 'all',
      violated: filter.violated,
      expiring: filter.expiring,
    })
  }, [filter, setFilter])

  const onToDoStateFilterClick = useCallback(() => {
    setFilter({
      type: filter.type,
      state: 'toDo',
      violated: filter.violated,
      expiring: filter.expiring,
    })
  }, [filter, setFilter])

  const onCompletedStateFilterClick = useCallback(() => {
    setFilter({
      type: filter.type,
      state: 'completed',
      violated: filter.violated,
      expiring: filter.expiring,
    })
  }, [filter, setFilter])

  const onNotCompletedStateFilterClick = useCallback(() => {
    setFilter({
      type: filter.type,
      state: 'notCompleted',
      violated: filter.violated,
      expiring: filter.expiring,
    })
  }, [filter, setFilter])

  const onUndeclaredStateFilterClick = useCallback(() => {
    setFilter({
      type: filter.type,
      state: 'undeclared',
      violated: filter.violated,
      expiring: filter.expiring,
    })
  }, [filter, setFilter])

  const onViolatedFilterClick = useCallback(() => {
    setFilter({
      type: filter.type,
      state: filter.state,
      violated: !filter.violated,
      expiring: filter.expiring,
    })
  }, [filter, setFilter])

  const onExpiringFilterClick = useCallback(() => {
    setFilter({
      type: filter.type,
      state: filter.state,
      violated: filter.violated,
      expiring: !filter.expiring,
    })
  }, [filter, setFilter])

  const onClearAllClick = useCallback(() => {
    setFilter({ type: 'all', state: 'all', violated: false, expiring: false })
    filterMenuRef.current?.close()
  }, [setFilter])

  const disabled = editing || noEntries

  return (
    <ListHeaderFilterContainer>
      <ListHeaderFilterInput
        placeholder={texts.filterPlaceholder}
        disabled={disabled}
        setQuery={setQuery}
        testId="list-header-filter-input"
        query={query}
      />

      <ListHeaderFilterMenu filterActive={filterActive} ref={filterMenuRef} disabled={disabled}>
        <ListHeaderFilterMenuTitle>{texts.orderType}</ListHeaderFilterMenuTitle>

        <MenuItem
          onClick={onAllTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-all"
          data-trackid="list-header-filter-filterOption-type-all"
        >
          <FormControlLabel
            label={texts.filterTypeAny}
            control={
              <Radio
                size="small"
                value="all"
                checked={filter.type === 'all'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onAssignedTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-assigned"
          data-trackid="list-header-filter-filterOption-type-assigned"
        >
          <FormControlLabel
            label={texts.filterTypeAssigned}
            control={
              <Radio
                size="small"
                value="assigned"
                checked={filter.type === 'assigned'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onUnassignedTypeFilterClick}
          data-testid="list-header-filter-filterOption-type-unassigned"
          data-trackid="list-header-filter-filterOption-type-unassigned"
        >
          <FormControlLabel
            label={texts.filterTypeUnassigned}
            control={
              <Radio
                size="small"
                value="unassigned"
                checked={filter.type === 'unassigned'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <ListHeaderFilterMenuTitle>{texts.orderStates}</ListHeaderFilterMenuTitle>

        <MenuItem
          onClick={onAllStateFilterClick}
          data-testid="list-header-filter-filterOption-state-all"
          data-trackid="list-header-filter-filterOption-state-all"
        >
          <FormControlLabel
            label={texts.filterStateAll}
            control={
              <Radio
                size="small"
                value="all"
                checked={filter.state === 'all'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onToDoStateFilterClick}
          data-testid="list-header-filter-filterOption-state-todo"
          data-trackid="list-header-filter-filterOption-state-todo"
        >
          <FormControlLabel
            label={texts.filterStateToDo}
            control={
              <Radio
                size="small"
                value="toDo"
                checked={filter.state === 'toDo'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onCompletedStateFilterClick}
          data-testid="list-header-filter-filterOption-state-completed"
          data-trackid="list-header-filter-filterOption-state-completed"
        >
          <FormControlLabel
            label={texts.filterStateCompleted}
            control={
              <Radio
                size="small"
                value="completed"
                checked={filter.state === 'completed'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          onClick={onNotCompletedStateFilterClick}
          data-testid="list-header-filter-filterOption-state-notCompleted"
          data-trackid="list-header-filter-filterOption-state-notCompleted"
        >
          <FormControlLabel
            label={texts.filterStateNotCompleted}
            control={
              <Radio
                size="small"
                value="notCompleted"
                checked={filter.state === 'notCompleted'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          divider
          onClick={onUndeclaredStateFilterClick}
          data-testid="list-header-filter-filterOption-state-undeclared"
          data-trackid="list-header-filter-filterOption-state-undeclared"
        >
          <FormControlLabel
            label={texts.filterStateUndeclared}
            control={
              <Radio
                size="small"
                value="undeclared"
                checked={filter.state === 'undeclared'}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          divider
          onClick={onViolatedFilterClick}
          data-testid="list-header-filter-filterOption-violated"
          data-trackid="list-header-filter-filterOption-violated"
        >
          <FormControlLabel
            label={texts.filterViolated}
            control={
              <Checkbox
                size="small"
                value="violated"
                checked={filter.violated}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        <MenuItem
          divider={filterActive}
          onClick={onExpiringFilterClick}
          data-testid="list-header-filter-filterOption-state-expiring"
          data-trackid="list-header-filter-filterOption-state-expiring"
        >
          <FormControlLabel
            label={texts.filterExpiring}
            control={
              <Checkbox
                size="small"
                value="expiring"
                checked={filter.expiring}
                readOnly
                disableRipple
              />
            }
          />
        </MenuItem>

        {filterActive && (
          <MenuItem
            onClick={onClearAllClick}
            data-testid="list-header-filter-filterOption-clear"
            data-trackid="list-header-filter-filterOption-clear"
          >
            <Typography fontSize={15} color="primary">
              {texts.clearAll}
            </Typography>
          </MenuItem>
        )}
      </ListHeaderFilterMenu>
    </ListHeaderFilterContainer>
  )
}
