import styled from 'styled-components'

const StepButtonIconContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  transform: translate(-50%, -50%);
`

StepButtonIconContainer.displayName = 'StepButtonIconContainer'
export default StepButtonIconContainer
