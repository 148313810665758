import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    filterTypeAdditionalDrivingTime: translate({
      id: 'setup.routing.list.segments.filter.filterType.additionalDrivingTime',
    }),
    sortOrderDescending: translate({
      id: 'setup.routing.list.segments.sorter.sortOrder.descending',
    }),
    filterTypeDisabled: translate({
      id: 'setup.routing.list.segments.filter.filterType.disabled',
    }),
    filterTypeBlocking: translate({
      id: 'setup.routing.list.segments.filter.filterType.blocking',
    }),
    sortOrderAscending: translate({ id: 'setup.routing.list.segments.sorter.sortOrder.ascending' }),
    sortByAscending: translate({ id: 'setup.routing.list.sorter.sortBy.ascending' }),
    sortByDescending: translate({
      id: 'setup.routing.list.sorter.sortBy.descending',
    }),
    filterPlaceholder: translate({ id: 'setup.routing.list.segments.filter.placeholder' }),
    importKMLFailed: translate({ id: 'global.importKML.failed' }),
    addRoadSegment: translate({ id: 'setup.routing.list.segments.createNew' }),
    filterTypeAny: translate({ id: 'setup.routing.list.segments.filter.filterType.any' }),
    roadSegments: translate({ id: 'setup.routing.list.segments.yourSegments' }),
    sortByName: translate({ id: 'setup.routing.list.segments.sorter.sortBy.name' }),
    importKml: translate({ id: 'global.importKML' }),
    clearAll: translate({ id: 'setup.routing.list.segments.filter.clearAll' }),
    add: translate({ id: 'global.add' }),
  }))

  return api
}
