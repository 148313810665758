import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    all: translate({ id: 'global.all' }),
    expiring: translate({ id: 'rm.summary.expiring' }),
    expiringTooltip: translate({ id: 'rm.summary.expiring.title' }),
    violated: translate({ id: 'rm.summary.violated' }),
    violatedTooltip: translate({ id: 'rm.summary.violated.title' }),
    ordersToDo: translate({ id: 'rm.summary.orders.toDo' }),
    ordersToDoTooltip: translate({ id: 'rm.summary.orders.toDo.title' }),
    ordersExecuted: translate({ id: 'rm.summary.orders.executed' }),
    ordersExecutedTooltip: translate({ id: 'rm.summary.orders.executed.title' }),
    ordersExecutedDone: translate({ id: 'rm.summary.orders.executedDone' }),
    ordersExecutedDoneTooltip: translate({ id: 'rm.summary.orders.executedDone.title' }),
    ordersExecutedReschedule: translate({ id: 'rm.summary.orders.executedReschedule' }),
    ordersExecutedRescheduleTooltip: translate({
      id: 'rm.summary.orders.executedReschedule.title',
    }),
    ordersExecutedUndeclared: translate({ id: 'rm.summary.orders.executedUndeclared' }),
    ordersExecutedUndeclaredTooltip: translate({
      id: 'rm.summary.orders.executedUndeclared.title',
    }),
    orders: (count: number) => translate({ id: 'rm.summary.orders', values: { count } }),
    ordersTooltip: translate({ id: 'rm.summary.orders.title' }),
    assigned: translate({ id: 'rm.summary.assigned' }),
    assignedTooltip: translate({ id: 'rm.summary.assigned.title' }),
    unassigned: translate({ id: 'rm.summary.unassigned' }),
    unassignedTooltip: translate({ id: 'rm.summary.unassigned.title' }),
    notDispatched: translate({ id: 'rm.summary.notDispatched' }),
    notDispatchedTooltip: translate({ id: 'rm.summary.notDispatched.title' }),
  }))

  return api
}
