import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectPhysicalDevices } from '@/features/domain/device'

export function useDevicesInfo(deviceIds: string[]) {
  const devices = useSelector(selectPhysicalDevices)

  return useMemo(() => {
    // iterates once over all devices to create a data structure
    // this avoid to "find" the deviceInfo in the while looping over deviceIds
    const devicesMap = devices.reduce<Record<string, uui.domain.client.gps.wwgps.DeviceInfo>>(
      (acc, device) => {
        acc[device.deviceId] = device as uui.domain.client.gps.wwgps.DeviceInfo
        return acc
      },
      {},
    )

    return deviceIds.reduce<uui.domain.client.gps.wwgps.DeviceInfo[]>((acc, curr) => {
      const deviceInfo = devicesMap[curr]
      if (deviceInfo) {
        acc.push(deviceInfo)
      }
      return acc
    }, [])
  }, [deviceIds, devices])
}
