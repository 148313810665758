import { useCallback } from 'react'

import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { roadSegmentsUtils } from '@/utils'
import { deleteRoadSegments } from '@/features/domain/roadSegment'

export function useActions(segments: uui.domain.client.rm.SegmentException[]) {
  const toast = useNotification()
  const dispatch = useAppDispatch()

  const onExport = useCallback(async () => {
    try {
      roadSegmentsUtils.exportRoadSegmentsToKML(segments)
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, segments])

  const onDelete = useCallback(async () => {
    try {
      const ids = segments.map(s => s.id)
      const result = await dispatch(deleteRoadSegments(ids))
      if (!deleteRoadSegments.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, segments, dispatch])

  return {
    onExport,
    onDelete,
  }
}
