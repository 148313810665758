import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    goTo: translate({ id: 'app.header.goTo' }),
    home: translate({ id: 'app.header.home' }),
    orders: translate({ id: 'app.header.orders' }),
    tracking: translate({ id: 'app.header.tracking' }),
    simulations: translate({ id: 'app.header.simulations' }),
    reports: translate({ id: 'app.header.reports' }),
    gpsReports: translate({ id: 'app.header.gpsReports' }),
  }))

  return api
}
