import type { PermissionData } from '../types'

export function computeDispatchPermission(permissionData: PermissionData) {
  if (permissionData.isSimulation || permissionData.allRoutesApproved) return 'hidden'

  if (permissionData.routesInThePastCount > 0) return 'disabledBecauseInThePast'

  // If the range is out of the optimization horizon, we need to disable the button
  if (permissionData.rangeOutOfOptimizationHorizon) return 'disabledOutOfOptimizationRange'

  return 'enabled'
}
