import type { SelectionRange } from '../../typings'

import { useCallback } from 'react'

export function useOnCalendarChange(onChange: (range: SelectionRange) => void) {
  return useCallback(
    (_date: Date | null, interval: [] | [Date] | [Date, Date]) => {
      switch (interval.length) {
        case 1:
          onChange({ min: interval[0] })
          break
        case 2:
          onChange({ min: interval[0], max: interval[1] })
          break
      }
    },
    [onChange],
  )
}
