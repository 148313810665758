import { useEffect } from 'react'

import { subscribeToEditableRoadSegment } from '../../atoms/editableRoadSegment/subscribeToEditableRoadSegment'
import { getEditableRoadSegment } from '../../atoms/editableRoadSegment/core/getEditableRoadSegment'

import { getInteraction } from '../../interactions/interactionMetadata'
import { createOnRoadSegmentModeChange } from './utils/createOnRoadSegmentModeChange'
import { createOnRoadSegmentChange } from './utils/createOnRoadSegmentChange'
import { createOnRoadSegmentCreate } from './utils/createOnRoadSegmentCreate'
import { resetUseDrawRoadSegment } from './utils/resetUseDrawRoadSegment'

export function useDrawRoadSegment() {
  useEffect(() => {
    const drawRoadSegment = getInteraction('rm-draw-editable-roadSegment-interaction')
    const modifyRoadSegment = getInteraction('rm-modify-editable-roadSegment-interaction')

    if (!drawRoadSegment || !modifyRoadSegment) return

    // Listen to the Editable RoadSegment Store changes
    const handleRoadSegmentModeChange = createOnRoadSegmentModeChange(
      drawRoadSegment,
      modifyRoadSegment,
    )
    const unsubscribeToRoadSegmentMode = subscribeToEditableRoadSegment(
      handleRoadSegmentModeChange,
      true,
    )

    // listen to the creation of a new RoadSegment
    const unsubscribeOnRoadSegmentCreate = drawRoadSegment.onRoadSegmentCreate(
      createOnRoadSegmentCreate(drawRoadSegment),
    )

    // listen to changes to an RoadSegment
    const unsubscribeOnRoadSegmentModify = modifyRoadSegment.onRoadSegmentChange(
      createOnRoadSegmentChange(),
    )

    // manually init interactions
    const RoadSegment = getEditableRoadSegment()
    handleRoadSegmentModeChange(RoadSegment)

    return () => {
      // ATOM subscription
      unsubscribeToRoadSegmentMode()

      // INTERACTIONS subscription
      unsubscribeOnRoadSegmentCreate()
      unsubscribeOnRoadSegmentModify()

      // RESET Open Layers interactions and layers
      resetUseDrawRoadSegment(drawRoadSegment, modifyRoadSegment)
    }
  }, [])
}
