import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function SendBackward(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M6 3C4.35547 3 3 4.35547 3 6v23c0 1.64453 1.35547 3 3 3h12v12c0 1.65234 1.34766 3 3 3h23c1.65234 0 3-1.34766 3-3V21c0-1.65234-1.34766-3-3-3H32V6c0-1.64453-1.35547-3-3-3zm0 2h23c.5664 0 1 .4336 1 1v12h-9c-1.65234 0-3 1.34766-3 3v9H6c-.5664 0-1-.4336-1-1V6c0-.5664.4336-1 1-1z" />
      </svg>
    </IconContainer>
  )
}
