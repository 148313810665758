import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

type Payload = {
  lockedStatus: boolean
  vehicleIds?: string[]
}

/**
 * Toggle lock for the given or all vehicles.
 *
 * @private
 */
export const setVehiclesLock = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/vehicle/lock/toggle/bulk', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const { lockedStatus, vehicleIds } = payload

    const result = await rpc('rpc/vehicle/setVehiclesLock', {
      category: 'rpc',
      type: 'rpc/vehicle/setVehiclesLock',
      lockedStatus,
      vehicleIds,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    // return true
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {setVehiclesLock}`,
      { tags: ['rpc', 'vehicle'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Setting Vehicles Lock Failed`,
      error,
    })
  }
})
