import { addSeconds, differenceInSeconds, addMinutes } from 'date-fns/esm'
import {
  parseAcceptedDate,
  parseTimeZoneOffsetToMinutes,
  getTimezoneFromTransitions,
} from './timezoneUtils'

export const getDateInTimezone = (
  date: uui.domain.AcceptedDate,
  transitions: uui.domain.TimezoneTransition[],
): Date => {
  const parsedDate: Date | undefined = parseAcceptedDate(
    date,
    getTimezoneFromTransitions(date, transitions),
  )

  if (!parsedDate) {
    throw new Error(`Impossible to create a date in: [getDateInTimezone].`)
  }

  return parsedDate
}

export const getUTCRangeInISOForTimezone = (
  date: uui.domain.AcceptedDate,
  startSec: number,
  endSec: number,
  transitions: uui.domain.TimezoneTransition[],
) => {
  const parsedDate: Date | undefined = parseAcceptedDate(
    date,
    getTimezoneFromTransitions(date, transitions),
  )

  if (!parsedDate) {
    throw new Error(`Impossible to create a date in: [getUTCRangeInISOForTimezone].`)
  }

  const startDate: Date = addSeconds(parsedDate, startSec)
  const endDate: Date = addSeconds(parsedDate, endSec)

  const isoStartDate = getISOString(startDate, transitions)
  const isoEndDate = getISOString(endDate, transitions)

  return {
    startTime: isoStartDate,
    endTime: isoEndDate,
  }
}

export const getISOString = (
  source: Date,
  transitions: uui.domain.TimezoneTransition[],
): string => {
  // current browser offset to UTC
  const localOffsetInMinutes: number = source.getTimezoneOffset()

  // correct UTC time in the browser timezone
  let date = addMinutes(source, -localOffsetInMinutes)

  // correct UTC time in the territory timezone
  const offsetTerritory = getTimezoneFromTransitions(source, transitions)
  const territoryOffsetInMinutes = parseTimeZoneOffsetToMinutes(offsetTerritory)
  date = addMinutes(date, -territoryOffsetInMinutes)

  return date.toISOString()
}

export const getTimestampInTimezone = (
  date: uui.domain.AcceptedDate,
  transitions: uui.domain.TimezoneTransition[],
): number => getDateInTimezone(date, transitions).getTime()

export const getDateInUTC = (date: uui.domain.AcceptedDate): Date => {
  const parsedDate: Date | undefined = parseAcceptedDate(date, 'UTC')

  if (!parsedDate) {
    throw new Error(`Impossible to create a date in: [getDateInUTC].`)
  }

  return parsedDate
}

export const getISORangeForGpsReportForm = (
  transitions: uui.domain.TimezoneTransition[],
  start: Date,
  end?: Date,
  defaultRangeLengthInSec: number = 86400,
) => {
  const durationInSecs: number = end ? differenceInSeconds(end, start) : defaultRangeLengthInSec

  const startDate = start
  const endDate: Date = addSeconds(startDate, durationInSecs)

  // console.log(
  //   getISOString(startDate, transitions),
  //   getISOString(endDate, transitions),
  // )

  return {
    startTime: getISOString(startDate, transitions),
    endTime: getISOString(endDate, transitions),
  }
}
