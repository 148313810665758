import { memo } from 'react'
import { ListItem, ListItemContent, ListItemRow, ListItemTitle } from '@/components/List'

import { useTexts } from '../hooks/useTexts'
import { useActions } from './hooks/useActions'

type Props = {
  item: uui.domain.client.rm.RouteManagerUser
  onItemClick: ({ event, item }) => void
  onItemDoubleClick: (user: uui.domain.client.rm.RouteManagerUser) => void
  selected: boolean
}

export const UserItem = memo((props: Props) => {
  const { item: user, selected } = props
  const texts = useTexts()
  const { handleClickOnUser, handleDoubleClickOnUser } = useActions(props)

  const fullName = `${user.name} ${user.surname}`
  const role = `${user.type[0].toUpperCase()}${user.type.substring(1)}`

  return (
    <ListItem
      onDoubleClick={handleDoubleClickOnUser}
      onClick={handleClickOnUser}
      selected={selected}
    >
      <ListItemTitle rightAdornment={user.enabled ? null : texts.disabled}>
        {fullName}
      </ListItemTitle>
      <ListItemRow testId="user-role-row">
        <ListItemContent>{role}</ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})
UserItem.displayName = 'UserItem'
