import type { ModalState } from '@/atoms'

import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'
import { Body } from './Body'

const modalConfig: Partial<ModalState['config']> = {
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

export function Content() {
  const { ready } = useConfigureController()

  if (!ready) return null

  return (
    <ModalContent config={modalConfig} data-testid="buildModal" data-trackid="buildModal">
      <Body />
    </ModalContent>
  )
}
