import type { ModalData, Tag } from './useController'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { naturalSort } from '@/server-data'
import { selectTrackingListOptions } from '@/features/domain/lists'
import { selectGpsTags } from '@/features/domain/tags'

export function useData() {
  const badges = useSelector(selectGpsTags)
  const listOptions = useSelector(selectTrackingListOptions)

  return useMemo<ModalData>(() => {
    const sortedBadges = Object.values(badges)
      .map(a => ({ id: a.id, label: a.value }))
      .sort((a, b) => naturalSort(a.label, b.label))

    const filterIncludeDevicesWithNoBadges = listOptions.filter.find(
      f => f.field === 'filter.includeDevicesWithNoBadges',
    )
    const includeDevicesWithNoBadges =
      filterIncludeDevicesWithNoBadges?.values.length === 1 &&
      !!filterIncludeDevicesWithNoBadges.values[0]

    const filterBadgesBadges = listOptions.filter.find(f => f.field === 'filter.badges')
    const filterBadgesBadgesSet = new Set((filterBadgesBadges?.values ?? []) as string[])
    const badgeFilter = sortedBadges.reduce<Tag[]>((acc, badge) => {
      if (filterBadgesBadgesSet.has(badge.id)) acc.push(badge)

      return acc
    }, [])

    return {
      badges: sortedBadges,
      badgeFilter,
      filterOperator: filterBadgesBadges?.operator ?? 'or',
      filterPristine: badgeFilter.length === 0,
      allBadgesChecked: badgeFilter.length === sortedBadges.length,
      includeDevicesWithNoBadges,
    }
  }, [badges, listOptions])
}
