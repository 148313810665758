import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    title: translate({ id: 'sms4.settings.communication.notifications.canceledSettings' }),

    description: translate({
      id: 'sms4.settings.communication.notifications.canceledSettings.description',
    }),

    disableDescription: translate({
      id: 'sms4.settings.communication.notifications.canceledSettings.toggle.disable',
    }),

    enableDescription: translate({
      id: 'sms4.settings.communication.notifications.canceledSettings.toggle.enable',
    }),
  }))

  return api
}
