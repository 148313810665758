import { useCallback } from 'react'
import { resetCrudSelection, useEditingStateWithCtx } from '@/atoms'

export function useActions() {
  const { editing, setEditing } = useEditingStateWithCtx('place')

  const onCreate = useCallback(() => {
    resetCrudSelection('places')
    setEditing([])
  }, [setEditing])

  return { onCreate, editing }
}
