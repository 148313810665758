import { type ChangeEvent, useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useNotification } from '@/hooks'
import { useResetEditingState } from '@/atoms'
import { updateTrafficProfile } from '@/features/domain/traffic'

import { normalizeFormValues } from '../utils/formValues'

import { useController } from './useController'
import { useTexts } from './useTexts'

export interface ModalHandlers {
  onCancel: () => void
  onSubmit: () => void
}

export function useControllerActions() {
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const resetEditingState = useResetEditingState()
  const texts = useTexts()
  const {
    close,
    updateData,
    data: { trafficProfile: sourceTrafficProfile, formValues },
  } = useController()

  const onSubmit = useCallback(async () => {
    try {
      updateData({ submitting: true })

      // parse and stringify will remove any proxy attached to the form values
      const trafficProfile = JSON.parse(
        JSON.stringify(normalizeFormValues(formValues, sourceTrafficProfile)),
      )
      const thunkResult = await dispatch(updateTrafficProfile(trafficProfile))

      updateData({ submitting: false })

      if (updateTrafficProfile.rejected.match(thunkResult)) {
        throw new Error(thunkResult.payload?.message ?? 'Internal error')
      }

      toast.success(texts.toast.success)

      resetEditingState()
      close?.()
    } catch (e) {
      toast.error(e.message)
    }
  }, [
    dispatch,
    resetEditingState,
    toast,
    close,
    sourceTrafficProfile,
    updateData,
    texts,
    formValues,
  ])

  const onChangeDescription = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      updateData({ formValues: { ...formValues, description: event.target.value } })
    },
    [updateData, formValues],
  )

  const onChangeBaseTrafficFactor = useCallback(
    (_: Event, val: number | number[]) => {
      const value = Array.isArray(val) ? val[0] : val
      updateData({ formValues: { ...formValues, baseTrafficFactor: value } })
    },
    [updateData, formValues],
  )

  return { onSubmit, onChangeDescription, onChangeBaseTrafficFactor }
}
