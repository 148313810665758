import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function InfoCartoonCloud(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M8 0C3.582 0 0 3.03 0 6.77c0 2.072 1.104 3.924 2.838 5.166.044.946-.102 2.25-1.407 2.852l-.002.002a.308.308 0 00.136.593c1.498-.01 2.77-.819 3.647-1.674a1.19 1.19 0 011.048-.333c.56.107 1.142.162 1.74.162 4.418 0 8-3.03 8-6.769C16 3.031 12.418 0 8 0zm0 2.462a2.464 2.464 0 012.462 2.461c0 1.055-.66 1.664-1.19 2.154-.407.376-.657.623-.657.923h-1.23c0-.855.584-1.394 1.053-1.828.491-.454.793-.757.793-1.249a1.232 1.232 0 00-2.462 0H5.54A2.464 2.464 0 018 2.462zm0 7.076a.923.923 0 110 1.847.923.923 0 010-1.847z" />
      </svg>
    </IconContainer>
  )
}
