import styled from 'styled-components'

type Props = {
  bold?: boolean
}

const PairedOrderPanelText = styled.p<Props>`
  color: ${p => p.theme.colors.$nightRider};
  font-size: ${p => p.theme.fonts.$s};
  font-weight: ${p => (p.bold ? p.theme.weights.$semiBold : p.theme.weights.$regular)};
  margin-top: 0;
  margin-bottom: 0;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

PairedOrderPanelText.displayName = 'PairedOrderPanelText'
export default PairedOrderPanelText
