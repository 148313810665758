import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    badges: translate({ id: 'vehicles.form.badges' }),
    warning: translate({ id: 'vehicles.form.edit.gps.driverBehaviors.changeWarning' }),
    braking: translate({ id: 'vehicles.form.driverBehaviors.braking' }),
    acceleration: translate({ id: 'vehicles.form.driverBehaviors.acceleration' }),
    cornering: translate({ id: 'vehicles.form.driverBehaviors.cornering' }),
    hard: translate({ id: 'vehicles.form.driverBehaviors.hard' }),
    harsh: translate({ id: 'vehicles.form.driverBehaviors.harsh' }),
    severe: translate({ id: 'vehicles.form.driverBehaviors.severe' }),
    gals: (value: string) => `${value} (${translate({ id: 'global.unitOfMeasure.gals' })})`,
  }))

  return api
}
