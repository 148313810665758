import type Geometry from 'ol/geom/Geometry'

import MultiLineString from 'ol/geom/MultiLineString'
import LineString from 'ol/geom/LineString'
import Feature from 'ol/Feature'

import { setRoutePolylineFeatureMetadata } from '../routePolylineFeatureMetadata'
import { getRoutePolylineFeatureStyle } from './getRoutePolylineFeatureStyle'

/**
 * Create a new RoutePolyline Feature
 */
export function createRoutePolylineFeature(
  marker: uui.domain.ui.map.markers.RoutePolyline,
  mapStyles: uui.domain.ui.map.markers.MapStyles['routePolyline'],
): Feature<Geometry> {
  /**
   * Let's create two geometries
   * - One for full stroke legs (ok and straightLine)
   * - One for dashed stroke legs (pedestrian)
   */
  const regularLegs: LineString[] = []
  const pedestrianLegs: LineString[] = []

  if (marker.fetched) {
    for (const leg of marker.legs) {
      switch (leg.status) {
        case 'pedestrian':
          pedestrianLegs.push(new LineString(leg.coordinates))
          break

        case 'ok':
        case 'straightLine':
        default:
          regularLegs.push(new LineString(leg.coordinates))
      }
    }
  }

  const feature = new Feature({
    // Regular geometry (ok | straightLine)
    geometry: new MultiLineString(regularLegs),

    // Geometry for pedestrian legs
    pedestrianLegs: new MultiLineString(pedestrianLegs),
  })

  // use marker ID (routePolylineId) as unique ID
  feature.setId(marker.id)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // expose the marker type
  setRoutePolylineFeatureMetadata(feature, 'type', 'routePolyline')

  // update current feature mode
  const mode: uui.domain.ui.map.markers.MapStyles['routePolyline']['mode'] =
    marker.mode ?? mapStyles.mode
  setRoutePolylineFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle(getRoutePolylineFeatureStyle(marker, mode))

  return feature
}
