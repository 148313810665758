import type { RecurrencesMap } from '../../typings'
import type { WeekCalendarPublicProps, WeeklyValidSelection } from '@/components/Calendar'

import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { parse, format, setDay, getDay } from 'date-fns/esm'

import { selectUserConfiguration } from '@/features/domain/user'

const now = new Date()

export function useWeeklyCalendarProps(
  recurrenceMap: RecurrencesMap,
  onChangeCallback: (date: Date) => void,
  date: Date | null,
): WeekCalendarPublicProps {
  const { today } = useSelector(selectUserConfiguration)
  const todayDate = parse(today, 'yyyyMMdd', now)

  const selection = useMemo<WeeklyValidSelection>(
    () => getDay(date || todayDate) as WeeklyValidSelection,
    [date, todayDate],
  )

  const onChange = useCallback(
    (newSelection: WeeklyValidSelection, currentSelection?: WeeklyValidSelection) => {
      if (currentSelection && currentSelection === newSelection) {
        return
      }
      const epoch = new Date(1970, 0, 1)
      onChangeCallback(setDay(epoch, newSelection))
    },
    [onChangeCallback],
  )

  return useMemo(() => {
    const daysWithRecurrences =
      !!recurrenceMap && recurrenceMap['WEEKLY']
        ? recurrenceMap['WEEKLY'].reduce((acc, curr) => {
            if (curr.value) {
              const key = format(curr.value, 'yyyyMMdd')
              acc[key] = 1
            }
            return acc
          }, {})
        : undefined

    return {
      today,
      layout: 'weekly',
      selection,
      onChange,
      highlightedDays: daysWithRecurrences,
      allowAutoMonthHeight: true,
    }
  }, [onChange, recurrenceMap, today, selection])
}
