import { PureComponent } from 'react'
import { Field as FinalFormField } from 'react-final-form'

import { isDeepEqual } from '@/server-data'

import SimulationDatesPickerField from './SimulationDatesPickerField'
import { PublicProps as Props, SimulationFieldProps } from './typings'

export default class SimulationDatesPickerFieldWrapper extends PureComponent<Props> {
  render() {
    const { name, ...props } = this.props

    return (
      <FinalFormField
        {...props}
        name={name}
        isEqual={isDeepEqual}
        render={(formProps: SimulationFieldProps) => {
          return <SimulationDatesPickerField {...this.props} formProps={formProps} />
        }}
      />
    )
  }
}
