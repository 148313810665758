import { Stack, Typography, useTheme } from '@mui/material'
import { ViewContainer, ViewContent, ViewField, ViewFieldsLayout } from '@/formUi'

import { Tooltip } from '@/components/primitives/Tooltip'

import { useDeleteUserConfirmationModal } from '../modals/DeleteUserConfirmationModal'
import { useChangePasswordModal } from '../modals/ChangePasswordModal'
import { useDisableUserConfirmationModal } from '../modals/DisableUserConfirmationModal'
import { useTerritoryNames } from '../hooks/useTerritoryNames'

import { useTexts } from './hooks/useTexts'
import { CourierConfig } from './CourierConfig'
import { Companies } from './Companies'
import { Territories } from './Territories'
import { ViewUserHeader } from './ViewUserHeader/ViewUserHeader'

type Props = {
  user: uui.domain.client.rm.RouteManagerUser
  selectedUsers: uui.domain.client.rm.RouteManagerUser[]
}

export function ViewUser(props: Props) {
  const { user, selectedUsers } = props
  const texts = useTexts()
  const theme = useTheme()
  const { Modal: DeleteUserConfirmationModal } = useDeleteUserConfirmationModal()
  const { Modal: ChangePasswordModal } = useChangePasswordModal()
  const { Modal: DisableUserConfirmationModal } = useDisableUserConfirmationModal()

  const territoryNames = useTerritoryNames()

  const color = user.enabled ? theme.palette.text.primary : theme.palette.text.disabled
  const borderRight = `1px solid ${theme.palette.grey['300']}`
  const usernameTooltipDisabled = user.username.length < 32
  const emailTooltipDisabled = user.email.length < 32
  const role = `${user.type[0].toUpperCase()}${user.type.substring(1)}`

  return (
    <Stack borderRight={borderRight} marginTop={-2} data-testid="form__column-secondary">
      <ViewContainer width={360}>
        <ViewUserHeader
          name={user.name}
          surname={user.surname}
          id={user.id}
          type={user.type}
          enabled={user.enabled}
          selectedUsers={selectedUsers}
        />
        <ViewContent>
          <ViewFieldsLayout paddingTop={2}>
            <ViewField label={texts.labels.name} color={color} testId="name">
              {user.name}
            </ViewField>
            <ViewField label={texts.labels.surname} color={color} testId="surname">
              {user.surname}
            </ViewField>
            <ViewField label={texts.labels.email} color={color} testId="email">
              <Tooltip
                placement="top"
                disabled={emailTooltipDisabled}
                title={user.email}
                maxWidth={600}
              >
                <Typography textOverflow="ellipsis" overflow="hidden" component="span">
                  {user.email}
                </Typography>
              </Tooltip>
            </ViewField>
            <ViewField label={texts.labels.username} color={color} testId="username">
              <Tooltip
                placement="top"
                disabled={usernameTooltipDisabled}
                title={user.username}
                maxWidth={600}
              >
                <Typography textOverflow="ellipsis" overflow="hidden" component="span">
                  {user.username}
                </Typography>
              </Tooltip>
            </ViewField>
            <ViewField label={texts.labels.role} color={color} testId="role">
              {role}
            </ViewField>

            <Companies isCompanyFiltered={user.isCompanyFiltered} companyIds={user.companies} />

            {user.type !== 'courier' && <Territories territoryFilter={user.territoryFilter} />}
            {user.type === 'courier' && (
              <CourierConfig courierFilter={user.courierFilter} territoryNames={territoryNames} />
            )}
          </ViewFieldsLayout>
        </ViewContent>
      </ViewContainer>
      <DeleteUserConfirmationModal userIds={[user.id]} />
      <ChangePasswordModal userId={user.id} />
      <DisableUserConfirmationModal userId={user.id} />
    </Stack>
  )
}
