import styled from 'styled-components'

const EmptyStateText = styled.p`
  margin: 0;
  text-align: center;
  font-weight: ${p => p.theme.weights.$semiBold};
  font-size: ${p => p.theme.fonts.$s};
  color: ${p => p.theme.colors.$nightRider};
`

EmptyStateText.displayName = 'EmptyStateText'
export default EmptyStateText
