import type { View } from './types'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  SecondaryColumnHeader,
  FormContent,
  ReadonlyLabel,
  HorizontalRuler,
  SecondaryColumnContentHorizontalRuler,
  SecondaryColumnContentContainer,
  ViewManager,
} from '@/forms-legacy'
import { selectGpsTrackingProvider } from '@/features/domain/user'
import { resetCrudSelection } from '@/atoms'
import { useIsSimulation } from '@/hooks'
import { selectLiveEta } from '@/features/domain/territory'
import { FormColumn } from '@/components/layout'

import { useFormViewType } from '../../../../hooks/useFormViewType'
import { VehicleAvatar } from '../../../../components/VehicleAvatar'
import { getLabel } from '../../../../utils/getLabel'
import { Tabs } from '../../../../components/Tabs'

import { TelematicsTrackingSection } from './components/TelematicsTrackingSection'
import { WwgpsTrackingSection } from './components/WwgpsTrackingSection'
import { useTexts } from './hooks/useTexts'

import { ActionsManager } from './ActionsManager'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function SingleViewRm(props: Props) {
  const { unifiedVehicle } = props

  const liveEtaActiveOnTerritory = useSelector(selectLiveEta)

  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const formViewType = useFormViewType(unifiedVehicle)
  const isSimulation = useIsSimulation()
  const texts = useTexts()

  // Retreive the vehicle label
  const vehicleLabel = getLabel(unifiedVehicle)

  // Check if the vehicle has a live ETA active
  const hasLiveEta = unifiedVehicle.hasRoutingLicense
    ? unifiedVehicle.vehicle.edgeOverride === 'GPS'
    : false

  // Check if the vehicle is tracked
  const tracked = unifiedVehicle.hasRoutingLicense ? unifiedVehicle.vehicle.tracked : false

  const resetSelection = useCallback(() => {
    resetCrudSelection('unifiedVehicles')
  }, [])

  const trackingSection =
    trackingProvider === 'WWGPS' ? (
      <WwgpsTrackingSection unifiedVehicle={unifiedVehicle} />
    ) : trackingProvider === 'telematics' ? (
      <TelematicsTrackingSection unifiedVehicle={unifiedVehicle} />
    ) : null

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <SecondaryColumnHeader
        title={vehicleLabel}
        description={texts.headerDescription}
        action={resetSelection}
        avatar={<VehicleAvatar vehicle={unifiedVehicle} marginRight={16} size={42} />}
        data-testid="vehicles-readonly-header"
      >
        <Tabs unifiedVehicle={unifiedVehicle} formViewType={formViewType} />
      </SecondaryColumnHeader>
      <ViewManager<View>
        view="default"
        render={(view, changeView) => (
          <ActionsManager unifiedVehicle={unifiedVehicle} view={view} changeView={changeView} />
        )}
      />
      <FormContent footer={<div />}>
        <SecondaryColumnContentContainer>
          <SecondaryColumnContentHorizontalRuler bigBottomSpace />

          {!isSimulation && (
            <>
              <ReadonlyLabel primary empty={!tracked} style={{ marginBottom: 12 }}>
                {texts.eventTracking(tracked)}
              </ReadonlyLabel>
              {liveEtaActiveOnTerritory && (
                <ReadonlyLabel primary empty={!hasLiveEta} style={{ marginBottom: 12 }}>
                  {texts.liveEta(hasLiveEta)}
                </ReadonlyLabel>
              )}
              <HorizontalRuler />
            </>
          )}

          {trackingSection}

          <HorizontalRuler style={{ height: 0, marginBottom: 60 }} />
        </SecondaryColumnContentContainer>
      </FormContent>
    </FormColumn>
  )
}
