import type { DepotFormValues } from './typings'

import { FormRenderProps } from 'react-final-form'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import {
  FormLayout,
  HorizontalRuler,
  FieldMessage,
  TextField,
  ColorPickerField,
  NumericStepperField,
  DurationStepField,
} from '@/forms-legacy'
import { colorPalette } from '@/server-data'

import { useTexts } from './hooks/useTexts'
import { Address } from './components/Address'
interface Props {
  formRenderProps: FormRenderProps<DepotFormValues>
  initialValues: DepotFormValues
}

export function Form(props: Props) {
  const {
    initialValues: { id },
  } = props

  const texts = useTexts()
  const { currency } = useSelector(selectUserConfiguration)
  const isNewEntity = id.length === 0

  return (
    <FormLayout>
      <TextField testid="depot-name" name="name" label={texts.labelName} half />
      <FieldMessage>{texts.labelNameInfo}</FieldMessage>

      <ColorPickerField
        name="color"
        label={texts.labelColor}
        testid="depot-color"
        wrappedInputProps={{
          colors: colorPalette,
          openText: texts.cancel.toUpperCase(),
          closedText: texts.change.toUpperCase(),
        }}
        half
      />
      <FieldMessage>{texts.labelColorInfo}</FieldMessage>

      {isNewEntity && (
        <>
          <HorizontalRuler />
          <Address />
          <HorizontalRuler />

          <NumericStepperField
            name="setupCost"
            label={texts.labelSetupCost}
            wrappedInputProps={{
              step: 1,
              min: 0,
              canBeNaN: false,
              unit: currency,
            }}
            half
            testid="depot-setupCost"
          />

          <DurationStepField
            name="setupTime"
            label={texts.labelSetupTime}
            wrappedInputProps={{
              min: 5,
              max: 8 * 60,
              step: 10,
              useSnap: false,
              children: 'hh:mm',
            }}
            half
            testid="depot-setupTime"
          />
        </>
      )}
      <HorizontalRuler />
    </FormLayout>
  )
}
