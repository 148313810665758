import type { PopoverOrigin } from '@mui/material'
import { useMemo } from 'react'

interface Origins {
  anchorOrigin: PopoverOrigin
  transformOrigin: PopoverOrigin
}

export const usePlacement = (placement: 'left' | 'right'): Origins =>
  useMemo(
    () => ({
      anchorOrigin: { vertical: 'top', horizontal: placement },
      transformOrigin: { vertical: 'top', horizontal: placement === 'left' ? 'right' : 'left' },
    }),
    [placement],
  )
