import styled from 'styled-components'

import { CopySettingsButton } from './CopySettingsButton'

export const CopySettingsActionsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: center;
  margin-bottom: 18px;

  ${CopySettingsButton} + ${CopySettingsButton} {
    margin-left: 9px;
  }
`

CopySettingsActionsContainer.displayName = 'CopySettingsActionsContainer'
