import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectHasNoPhysicalDevices } from '@/features/domain/device'

export function usePermissions() {
  const hasNoPhysicalDevices = useSelector(selectHasNoPhysicalDevices)

  return useMemo(
    () => ({
      createGeofence: hasNoPhysicalDevices ? 'hidden' : 'visible',
    }),
    [hasNoPhysicalDevices],
  )
}
