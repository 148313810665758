import type { FormValidator } from '@workwave-tidal/tidal/form-fairy'
import type { FormFields, FormErrors } from '../../formFields'
import type { Texts } from '../useTexts'

export const createValidateSurname =
  (texts: Texts): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const { value } = formApi.getField('surname')
    const surname = value.trim()

    const requiredError = surname.length === 0
    const maxLengthError = surname.length > 30

    const surnameRequiredError: FormErrors = {
      id: 'surnameRequiredError',
      message: texts.errors.required,
      field: 'surname' as const,
      priority: 10,
    }

    const surnameMaxLengthError: FormErrors = {
      id: 'surnameMaxLengthError',
      message: texts.errors.maxLength(30),
      field: 'surname' as const,
      priority: 10,
    }

    return {
      [surnameRequiredError.id]: requiredError ? surnameRequiredError : null,
      [surnameMaxLengthError.id]: maxLengthError ? surnameMaxLengthError : null,
    }
  }
