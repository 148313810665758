import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Reverse(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 18 16">
        <path d="M18 4.599a.747.747 0 0 0-.22-.519l-3-3a.744.744 0 0 0-.73-.205.742.742 0 0 0-.535.536c-.07.261.008.542.205.73l1.72 1.72H6v1.5h9.44l-1.72 1.72a.744.744 0 0 0-.205.729.742.742 0 0 0 .536.536c.26.07.542-.009.73-.205l3-3A.752.752 0 0 0 18 4.599zm0 6.762h-1.5v1.5H18v-1.5zm-3 0h-1.5v1.5H15v-1.5zm-3 0H2.56l1.72-1.72a.756.756 0 0 0 .229-.54.75.75 0 0 0-1.29-.521l-3 3a.75.75 0 0 0 0 1.06l3 3a.744.744 0 0 0 .73.206.742.742 0 0 0 .536-.536.744.744 0 0 0-.205-.73l-1.72-1.72H12v-1.5zm-7.5-7.5H3v1.5h1.5v-1.5zm-3 0H0v1.5h1.5v-1.5z" />
      </svg>
    </IconContainer>
  )
}
