import { proxy, useSnapshot } from 'valtio'

interface RoutingLayoutDropdownState {
  // If the dropdown is opened or not.
  open: boolean

  // The destination layout the user chose
  targetLayout: 'split' | 'map'
}

const initialValue = {
  open: false,
  targetLayout: 'map',
} as const

const routingLayoutDropdownStateAtom = proxy<RoutingLayoutDropdownState>(initialValue)

export function setDropdownOpen(open: boolean) {
  routingLayoutDropdownStateAtom.open = open
}

export function useDropdownOpen() {
  return [useSnapshot(routingLayoutDropdownStateAtom).open, setDropdownOpen] as const
}

export function setTargetLayout(targetLayout: RoutingLayoutDropdownState['targetLayout']) {
  routingLayoutDropdownStateAtom.targetLayout = targetLayout
}

export function useTargetLayout() {
  return [useSnapshot(routingLayoutDropdownStateAtom).targetLayout, setTargetLayout] as const
}
