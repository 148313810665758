import { renderToStaticMarkup } from 'react-dom/server'
import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { intl } from '@/intl'

import { IdCell } from '../../../components/cells/IdCell'

export function createIdColumn(): Partial<TemplateColumnConfig> {
  return {
    text: intl.translate({ id: 'rm.scheduler.column.id' }),
    type: 'template',
    field: 'vehicleAvatar',
    region: 'id',
    hideable: false,
    minWidth: 59,
    maxWidth: 59,
    template: ({ value, record }) => {
      return renderToStaticMarkup(<IdCell value={value} record={record as ResourceModel} />)
    },
    draggable: false,
    resizable: false,
    groupable: false,
  }
}
