import { type ResourceModel } from '@bryntum/schedulerpro'
import { conversionUtils } from '@/server-data'

import { SingleColumnContainer } from '../Scheduler/components/SingleColumnContainer'
import { Typography } from '../Scheduler/components/Typography'

interface Props {
  userConfig: uui.domain.UserConfiguration
  record: ResourceModel
  field: string
  value: number
}

export function DistanceLeftCell(props: Props) {
  const { value, userConfig } = props

  if (!value) return <>-</>

  return (
    <SingleColumnContainer>
      <Typography fontSize={12}>
        {value <= 0 ? '-' : conversionUtils.convertDistance(userConfig)(value, 2)}
      </Typography>
    </SingleColumnContainer>
  )
}
