import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'
import { VerticalLayout, HorizontalLayout } from '@/components/layout'
import { Text } from '@/local/components'

import { useTexts } from '../useTexts'

interface Props {
  extOrderStep: uui.domain.client.rm.ExtendedOrderStep
}

export function OrderServiceTime(props: Props) {
  const texts = useTexts()
  const {
    extOrderStep: {
      orderStep: {
        serviceTimeSec,
        trackingData: { isEmpty, timeInSec, timeOutSec },
      },
    },
  } = props

  let delta = 0
  let realServiceTime = 0

  if (!isEmpty && timeInSec !== -1 && timeOutSec !== -1) {
    realServiceTime = timeOutSec - timeInSec
    delta = realServiceTime - serviceTimeSec
  }

  return (
    <VerticalLayout marginTop={8}>
      <Text size="$s" weight="$semiBold" color="$shadyLady" uppercase>
        {texts.serviceTime}
      </Text>

      <HorizontalLayout>
        <Text size="$m" color="$nightRider">
          <FormatSeconds seconds={serviceTimeSec} />
        </Text>

        {!isEmpty && delta !== 0 ? (
          <Text size="$m" color={delta > 0 ? '$outrageousRed' : '$pigmentGreen'}>
            &nbsp;&nbsp;
            {delta > 0 ? '+' : '-'}
            <FormatSeconds seconds={delta} format="MINUTES_ONLY" />
          </Text>
        ) : null}
      </HorizontalLayout>
    </VerticalLayout>
  )
}
