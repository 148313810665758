import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useEditingStateWithCtx, useCrudSelection, resetCrudSelection, useIsLoading } from '@/atoms'

import { selectVehiclesListStructure } from '@/features/domain/lists'

export const useDataForList = () => {
  const { setEditing } = useEditingStateWithCtx('vehicle')
  const isLoading = useIsLoading('fetchGpsData')

  const structure = useSelector(selectVehiclesListStructure)
  const [selection, setSelection] = useCrudSelection('unifiedVehicles')

  const lastSelectedVehicle = selection.length > 0 ? selection[selection.length - 1] : undefined

  const setCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  const deselectAll = () => resetCrudSelection('unifiedVehicles')

  return {
    structure,
    setCreate,
    isLoading,
    setEditing,
    lastSelectedVehicle,
    setSelection,
    deselectAll,
  }
}
