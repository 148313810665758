import { PureComponent, CSSProperties } from 'react'

import { ReadonlyLabel, TextField, HorizontalRuler } from '@/forms-legacy'

import * as OrderTexts from '../../../../../../intl'
import { Props } from '../typings'

const contactInformationTitleStyles: CSSProperties = {
  marginBottom: 10,
  marginTop: 10,
  paddingLeft: 0,
}

export default class ContactInformation extends PureComponent<Props> {
  render() {
    const { orderStepType } = this.props

    return (
      <>
        <ReadonlyLabel style={contactInformationTitleStyles} primary>
          {OrderTexts.getContactInformationTitle()}
        </ReadonlyLabel>

        <TextField
          name={`${orderStepType}.phone`}
          label={OrderTexts.getFieldLabelPhoneText()}
          wrappedInputProps={{
            showCounter: true,
            showResetIcon: true,
          }}
          testid="phone"
        />

        <TextField
          name={`${orderStepType}.email`}
          label={OrderTexts.getFieldLabelEmailText()}
          wrappedInputProps={{
            showCounter: true,
            showResetIcon: true,
          }}
          testid="email"
        />

        <HorizontalRuler />
      </>
    )
  }
}
