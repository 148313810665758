import type { BulkFieldValue } from '../../../types'

import { toString } from '@/local/server-data/domain/helpers/eligibility'

export function computeEligibility(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
): BulkFieldValue<uui.domain.client.rm.Eligibility> {
  const eligibility = orders[0].order.eligibility
  const exact = orders.every(o => isEqual(o.order.eligibility, eligibility))

  if (exact) {
    return {
      status: 'exact',
      value: eligibility,
    }
  }

  return {
    status: 'mixed',
  }
}

function isEqual(a: uui.domain.client.rm.Eligibility, b: uui.domain.client.rm.Eligibility) {
  if (a.type !== b.type) return false
  return toString(a) === toString(b)
}
