import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function UserConsole(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 21 17">
        <path d="M2.318 0A2.319 2.319 0 0 0 0 2.318v12.364A2.319 2.319 0 0 0 2.318 17h15.455a2.319 2.319 0 0 0 2.318-2.318V2.318A2.319 2.319 0 0 0 17.773 0H2.318zm-.773 3.864h17v10.818a.774.774 0 0 1-.772.773H2.318a.774.774 0 0 1-.773-.773V3.864zm1.86 2.318l2.704 2.704-2.704 2.705H5.36l2.704-2.705-2.704-2.704H3.405zm6.64 3.863a.774.774 0 0 0-.772.773c0 .426.347.773.772.773h3.864a.774.774 0 0 0 .773-.773.774.774 0 0 0-.773-.773h-3.864z" />
      </svg>
    </IconContainer>
  )
}
