import type { OnChangeDropDownLocation } from '../typings'
import type { MutableDropDownState } from './useDropDownStateRef'

import { useCallback } from 'react'

export function useOnChange(
  onDropDownChange: OnChangeDropDownLocation,
  dropDownStateRef: MutableDropDownState,
) {
  return useCallback(
    (asset: uui.domain.client.gps.SearchOnMapItem, relatedLocation: uui.domain.client.Location) => {
      if (dropDownStateRef.current.open) {
        onDropDownChange(asset, relatedLocation)
      }
    },
    [onDropDownChange, dropDownStateRef],
  )
}
