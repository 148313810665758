import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, resetCrudSelection, useEditingStateWithCtx, useIsLoading } from '@/atoms'
import { selectEventAlertsListStructure } from '@/features/domain/lists'

export const useDataForList = () => {
  const isLoading = useIsLoading('fetchGpsData')
  const { setEditing } = useEditingStateWithCtx('alert')
  const structure = useSelector(selectEventAlertsListStructure)
  const [selection, setSelection] = useCrudSelection('alerts')

  const lastSelectedAlert = selection.length > 0 ? selection[selection.length - 1] : undefined

  const setCreate = useCallback(() => {
    setSelection('reset')
    setEditing([...selection])
  }, [setEditing, setSelection, selection])

  const deselectAll = () => resetCrudSelection('alerts')

  return {
    isLoading,
    structure,
    setCreate,
    setEditing,
    lastSelectedAlert,
    setSelection,
    deselectAll,
  }
}
