import type { AsyncThunkApiConfig } from '@/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { unproxify } from '@/utils'

import { RejectError } from '../../typings'

type Payload = {
  orderStepIds: string[]
  cloneAlsoPairedStep?: boolean
}

/**
 * Clone order steps.
 *
 * @private
 */
export const cloneOrderSteps = createAsyncThunk<
  // Return type of the payload creator
  string[],
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/order/clone', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const result = await rpc('rpc/order/clone', {
      category: 'rpc',
      type: 'rpc/order/clone',
      payload: unproxify(payload),
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    return result.orderStepIds
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {cloneOrders}`,
      { tags: ['rpc', 'order'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Cloning [${payload.orderStepIds}] Failed`,
      error,
    })
  }
})
