import type { CSSProperties } from 'react'

import { useMemo } from 'react'

export function useStyles(disabled: boolean) {
  return useMemo(() => {
    return {
      root: {
        '--opacity': `${disabled ? 0.3 : 1}`,
        '--pointerEvents': `${disabled ? 'none' : 'unset'}`,
      } as CSSProperties,
    }
  }, [disabled])
}
