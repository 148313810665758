import type { ModifierKeys } from './typings'
import type { ReactNode } from 'react'

import { useCallback, useState, useEffect, useRef } from 'react'
import { Scrollbar } from '@workwave-tidal/tidal/components'

import { FlexColumn, TextButton } from '@/forms-legacy'

import { Search } from '@/icons'

import { HeaderContainer } from './elements/HeaderContainer'
import { FooterContainer } from './elements/FooterContainer'
import { TextInput } from './elements/TextInput'
import { ScrollableList } from './elements/ScrollableList'
import { FooterDescription } from './elements/FooterDescription'

import { SearchableListItems } from './SearchableListItems'

import { Props, ListValue, ItemsExtra } from './typings'

const renderList = (children: ReactNode, height?: number) => {
  if (height === undefined) {
    return children
  }

  return (
    <div style={{ width: '100%', height }}>
      <Scrollbar hideOverflow="x">{children}</Scrollbar>
    </div>
  )
}

const warn = (message: string): void => {
  console.warn(message)
}

export function SearchableList<T extends ListValue>(props: Props<T>) {
  const {
    values,
    ListItem,
    selectedIds,
    onChange,
    visibleRows,
    rowHeight,
    selectionLimit,
    selectionRequired = false,
    onFilter,
    renderText,
    disabled,
    listProps = {},
    footerElements,
  } = props

  // this will make stable values and selectedIds otherwise onFilter effect will go in loop
  const rValues = useRef(values)
  useEffect(() => {
    rValues.current = values
  }, [values])

  const rSelectedIds = useRef(selectedIds)
  useEffect(() => {
    rSelectedIds.current = selectedIds
  }, [selectedIds])

  const mode = selectionRequired && selectionLimit === 1 ? 'single' : 'multiple'

  const [extra, setExtra] = useState<ItemsExtra<T>>({
    ListItem,
    mode,
    selectionLimit,
    selectionRequired,
    disabled,
  })

  const [filter, setFilter] = useState('')

  useEffect(() => {
    setExtra({
      ListItem,
      mode,
      selectionLimit,
      disabled,
      selectionRequired,
    })
  }, [ListItem, mode, selectionLimit, disabled, selectionRequired])

  const listSize = values.length
  const selectionSize = selectedIds.length
  const noScrollbars: boolean = visibleRows === -1

  if (selectionRequired && selectionSize === 0) {
    warn('SearchableList: selection is required but none was provided')
  }

  const rowHeightInPixel =
    typeof rowHeight === 'function' ? rowHeight(values[0], 0, values) : rowHeight

  const listHeight = noScrollbars || listSize === 0 ? undefined : rowHeightInPixel * visibleRows

  const showFilterModule = typeof onFilter === 'function'

  useEffect(() => {
    if (onFilter) {
      onFilter(filter, rValues.current, rSelectedIds.current)
    }
  }, [filter, onFilter])

  const allChecked = selectionSize === listSize

  const selectButtonText = renderText(allChecked ? 'unCheckAll' : 'checkAll', props)

  const handleOnToggleSelection = useCallback(() => {
    onChange(allChecked ? [] : values, selectionLimit, undefined, undefined, true)
  }, [onChange, allChecked, values, selectionLimit])

  const handleOnChangeFilter = useCallback(
    (txt: string) => {
      setFilter(txt)
    },
    [setFilter],
  )

  const handleOnChange = useCallback(
    (selection: T[], modifiers: ModifierKeys = {}) => {
      onChange(selection, selectionLimit, modifiers, selectionRequired)
    },
    [selectionLimit, onChange, selectionRequired],
  )

  const toggleSelectionButtonDisabled =
    (selectionLimit > 0 && selectionLimit < listSize) || selectionRequired

  const hideFooter = !!footerElements && footerElements.length === 0

  const showFooterSelectionControl =
    footerElements === undefined ? true : footerElements.includes('selectionControls')

  const showFooterSummary = footerElements === undefined ? true : footerElements.includes('summary')

  const showFooterAction =
    footerElements === undefined ? true : footerElements.includes('actionButton')

  return (
    <FlexColumn primary>
      <HeaderContainer>
        {showFilterModule && (
          <TextInput
            onChange={handleOnChangeFilter}
            placeholder={renderText('filterPlaceholder', props)}
            name="list-filter"
            extraIcon={<Search />}
            value={filter}
          />
        )}
      </HeaderContainer>

      {renderList(
        <ScrollableList<T, ItemsExtra<T>>
          values={values}
          selectedIds={selectedIds}
          onChanged={handleOnChange}
          rowHeight={rowHeight}
          Items={SearchableListItems}
          disableComputedHeight={true}
          mode="sfc"
          extra={extra}
          {...listProps}
        />,
        listHeight,
      )}

      {!hideFooter && (
        <FooterContainer>
          {showFooterSelectionControl && (
            <FlexColumn
              style={{
                width: '25%',
              }}
            >
              <TextButton
                onClick={handleOnToggleSelection}
                disabled={toggleSelectionButtonDisabled}
              >
                {selectButtonText}
              </TextButton>
            </FlexColumn>
          )}

          {showFooterSummary && (
            <FlexColumn
              primary
              style={{
                alignItems: 'center',
              }}
            >
              <FooterDescription>
                {renderText('footerDescription', props, selectionSize)}
              </FooterDescription>
            </FlexColumn>
          )}

          {showFooterAction && (
            <FlexColumn
              style={{
                width: '25%',
              }}
            />
          )}
        </FooterContainer>
      )}
    </FlexColumn>
  )
}
