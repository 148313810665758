import { Component, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  parse,
  addDays,
  subDays,
  isAfter,
  isBefore,
} from 'date-fns/esm'

import { clsx } from '@/utils'
import { Button } from '@/forms-legacy'

import { Calendar as CalendarIcon } from '@/icons'
import { selectTerritoryLicensingLimits } from '@/features/domain/territory'
import { selectUserConfiguration } from '@/features/domain/user'
import { Calendar } from '@/components/Calendar'

import DatePickerInput from './DatePickerInput'

export type PublicProps = {
  name: string
  onChange: (value: Date) => void
  onToggleCalendar?: (open: boolean) => void
  value?: Date
  inputDateFormatter?: string
  calendarStart?: string
  calendarEnd?: string
  showCancel?: boolean
  className?: string
  todayLabel?: string
  selectableRangeLength?: number
}

type Props = PublicProps & {
  calendarData: {
    minDate: string
    maxDate: string
    today: string
    disabledDays: Record<string, number>
  }
}

export interface State {
  showCalendar: boolean
  currentDate: Date | undefined
}

const DEFAULT_DATE_FORMATTER = 'MMM d, yyyy'

const noop = () => undefined
const now = new Date()

function DatePickerWithToday(props: PublicProps) {
  const { today } = useSelector(selectUserConfiguration)
  const { horizonView } = useSelector(selectTerritoryLicensingLimits)

  const calendarData = useMemo(() => {
    const todayDate = parse(today, 'yyyyMMdd', now)
    const minDate = startOfMonth(todayDate)
    const yesterday = subDays(todayDate, 1)
    const maxDateSelectable = addDays(todayDate, horizonView)
    const firstNonSelectableDateAfterHorizon = addDays(maxDateSelectable, 1)
    const maxDate = endOfMonth(maxDateSelectable)

    const disabledDates: Date[] = []
    if (isAfter(yesterday, minDate)) {
      disabledDates.push(
        ...eachDayOfInterval({
          start: minDate,
          end: yesterday,
        }),
      )
    }
    if (isBefore(firstNonSelectableDateAfterHorizon, maxDate)) {
      disabledDates.push(
        ...eachDayOfInterval({
          start: firstNonSelectableDateAfterHorizon,
          end: maxDate,
        }),
      )
    }

    const disabledDays: Record<string, number> = disabledDates.reduce((acc, d) => {
      acc[format(d, 'yyyyMMdd')] = 1
      return acc
    }, {})

    return {
      today,
      minDate: format(minDate, 'yyyyMMdd'),
      maxDate: format(maxDate, 'yyyyMMdd'),
      disabledDays,
    }
  }, [today, horizonView])

  return <DatePicker {...props} calendarData={calendarData} />
}

export default DatePickerWithToday

class DatePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showCalendar: false,
      currentDate: props.value,
    }
  }

  toggleCalendar = (isOpen: boolean): void => {
    const { onToggleCalendar } = this.props
    if (onToggleCalendar) {
      onToggleCalendar(isOpen)
    }
  }

  handleClickCancel = (): void => {
    this.setState(() => ({ showCalendar: false }))
    this.toggleCalendar(false)
  }

  handleSelectionChange = (newSelection: Date | null): void => {
    const { onChange } = this.props
    if (!newSelection) {
      return
    }
    this.setState(() => ({
      showCalendar: false,
    }))
    onChange(newSelection)
    this.toggleCalendar(false)
  }

  handleActiveDateChange = (currentDate: Date): void => {
    this.setState(() => ({
      currentDate,
    }))
  }

  handleInputFocus = (): void => {
    this.setState(() => ({
      showCalendar: true,
    }))

    this.toggleCalendar(true)
  }

  formatInputDate = (date: Date, formatter: string): string => format(date, formatter)

  renderInput() {
    const { value, name, inputDateFormatter = DEFAULT_DATE_FORMATTER } = this.props
    return (
      <div onClick={this.handleInputFocus}>
        <DatePickerInput
          name={name}
          onChange={noop}
          value={value ? this.formatInputDate(value, inputDateFormatter) : ''}
          extraIcon={<CalendarIcon />}
        />
      </div>
    )
  }

  renderCalendar() {
    const { currentDate } = this.state
    const { calendarData, showCancel } = this.props
    return (
      <>
        <Calendar
          months={1}
          layout="date"
          selection={currentDate}
          onChange={this.handleSelectionChange}
          {...calendarData}
        />
        {showCancel && (
          <Button className="o-date-picker__cancel" onClick={this.handleClickCancel}>
            Cancel
          </Button>
        )}
      </>
    )
  }

  render() {
    const { className = '' } = this.props
    const { showCalendar = false } = this.state
    const rootClassName = clsx({
      [className]: true,
      'o-date-picker': true,
    })
    const children = showCalendar ? this.renderCalendar() : this.renderInput()
    return <div className={rootClassName}>{children}</div>
  }
}
