import { useCloseModal, useShowModal } from '@/atoms'

import { Root } from './components/Root'

// For that specific modal we specify the id from the parent component to allow multiple modals to be present (NOTE: not visible) at the same time
export function useLocationEditorModal(
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery',
) {
  const show = useShowModal(pinId)
  const close = useCloseModal()

  return {
    show,
    close,
    Modal: Root,
  }
}
