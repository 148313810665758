import type { CSSProperties, ReactElement } from 'react'
import type { ValidColor } from '@/local/components'

import { ButtonPrimitive, IconContainer } from './ButtonPrimitive'

export type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  autoWidth?: boolean
  background?: ValidColor
  backgroundAlpha?: number
  canFocus?: boolean
  color?: ValidColor
  ellipsis?: boolean
  hover?: {
    background?: ValidColor
    backgroundAlpha?: number
    color?: ValidColor
  }
  Icon?: ReactElement
  iconPlacement?: 'absoluteRight' | 'inlineLeft'
  marginBottom?: string | number
  marginLeft?: string | number
  marginRight?: string | number
  marginTop?: string | number
  paddingBottom?: string | number
  paddingLeft?: string | number
  paddingRight?: string | number
  paddingTop?: string | number
  preset?: 'cancel' | 'confirm' | 'alert' | 'textButton' | 'footerButton'
  style?: CSSProperties
  textAlign?: 'center' | 'end' | 'justify' | 'left' | 'match-parent' | 'right' | 'start'
  testid?: string
}

export const Button = (props: Props) => {
  const { className = '', children = '', ref, title, canFocus = true, Icon, testid, ...p } = props
  const { iconPlacement = 'inlineLeft' } = props

  const btnTitle: string = !!title ? title : typeof children === 'string' ? children : ''

  const tabIndex: number = canFocus ? 0 : -1

  const renderAbsolutePlacedIcon = iconPlacement === 'absoluteRight'
  const renderInlineIcon = iconPlacement === 'inlineLeft'

  return (
    <ButtonPrimitive
      Icon={Icon}
      className={className}
      title={btnTitle}
      tabIndex={tabIndex}
      data-testid={testid}
      {...p}
    >
      {Icon && renderInlineIcon && <IconContainer placement="left">{Icon}</IconContainer>}

      {children}

      {Icon && renderAbsolutePlacedIcon && <div className="o-molecule-button__icon">{Icon}</div>}
    </ButtonPrimitive>
  )
}
