import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 17px;
  font-size: ${p => p.theme.fonts.$p2};

  color: ${p => p.theme.colors.$pureWhite};
  font-weight: ${p => p.theme.weights.$bold};

  > * {
    margin-left: 9px;
  }
`
Container.displayName = 'Container'
