import { useSelector } from 'react-redux'
import { selectRoadSegmentMapStyles } from '@/features/domain/ui'

export const useRoadEditsMapStyle = () => {
  const { mode, custom } = useSelector(selectRoadSegmentMapStyles)

  if (Object.keys(custom).length > 0) return

  return mode
}
