import { ColumnsShuffler } from './components/ColumnsShuffler'
import { ColumnsPicker } from './components/ColumnsPicker'
import { useColumns } from './hooks/useColumns'
import { useLocaleText } from './hooks/useLocaleText'
import { Root } from './components/Root'
import { type ColumnsConfiguratorProps } from './types'

export function ColumnsConfigurator(props: ColumnsConfiguratorProps) {
  const {
    localeText: userLocaleText = {},
    columns: providedColumns,
    selection,
    onSelectionChange,
    warningCard,
  } = props

  const { selectedColumns, onRemoveColumn, filter, onChangeFilter, columns } = useColumns(
    providedColumns,
    selection,
    onSelectionChange,
  )
  const defaultLocaleText = useLocaleText()

  const localeText = { ...defaultLocaleText, ...userLocaleText }

  return (
    <Root direction="row" marginX={-4}>
      <ColumnsPicker
        columns={columns}
        filter={filter}
        selection={selection}
        onSelectionChange={onSelectionChange}
        onChangeFilter={onChangeFilter}
        localeText={localeText}
        warningCard={warningCard}
      />
      <ColumnsShuffler
        columns={selectedColumns}
        onSelectionChange={onSelectionChange}
        onRemoveColumn={onRemoveColumn}
        localeText={localeText}
      />
    </Root>
  )
}
