import type {
  DrawEditableAreaInteraction,
  ModifyEditableAreaInteraction,
} from '../../../interactions/EditableAreaInteraction'

import Feature from 'ol/Feature'
import Polygon from 'ol/geom/Polygon'

import { gis } from '@/server-data'

import { drawFeatureMetadata } from '../../../interactions/EditableAreaInteraction/utils/drawFeatureMetadata'

import { getMap } from '../../../atoms/map/utils/getMap'
import { findLayer } from '../../../layers/utils/findLayer'

import { changeAreaColor } from './createChangeAreaColor'

export function createOnAreaModeChange(
  drawArea: DrawEditableAreaInteraction,
  modifyArea: ModifyEditableAreaInteraction,
) {
  let prevMode: uui.domain.ui.map.EditableArea['mode'] = 'none'
  let prevColor: uui.domain.ui.map.EditableArea['color'] = ''

  /**
   * Listen to the Editable Area Store changes and update the drawing into the Map accordingly.
   * It's responsible to activate and deactivate the `draw` and `modify` interactions.
   */
  return (area: workwave.DeepReadonly<uui.domain.ui.map.EditableArea>) => {
    const { mode } = area

    if (prevColor !== area.color) {
      // Update the Active area color when it change in the Editable Area Store
      changeAreaColor(drawArea, modifyArea, area.color)
      prevColor = area.color
    }

    // if `area.mode` is not changed it means that the change is coming from the Map interactions.
    // Nothing to do here.
    if (prevMode === area.mode) return

    switch (mode) {
      // Start drawing a new Area
      case 'create':
        modifyArea?.setActive(false)
        drawArea?.setActive(true)
        break

      // Start editing an Area
      case 'edit': {
        // try to retrieve the active drawing feature
        const layer = findLayer(getMap(), 'draw')
        let feature = layer.getSource()?.getFeatureById(area.id)

        // if the feature doesn't exist it means the editing is not part of the creation of a new Area.
        // The editing started from an Edit action in a form.
        // We need to create a feature to support the edit of the Area.
        if (!feature) {
          // convert available points to the Map's coordinate system
          const coords: number[][] = area.points?.map(point => gis.fromLatLngToCoordinate(point))

          // if there's no point we stop here.
          // It shouldn't be possible but it's here for extra safety at runtime.
          if (coords.length === 0) {
            drawArea?.setActive(false)
            modifyArea?.setActive(false)

            if (process.env.NODE_ENV === 'development') {
              throw new Error(`Trying to edit an area without points.`)
            }
            return
          }

          // Open Layers Polygon expect to have the first point of the shape to be added again as last
          // but RM server doesn't
          const finalPoint = [...coords[0]]
          coords.push(finalPoint)

          // create the feature and add it to the `draw` layer
          feature = new Feature({ geometry: new Polygon([coords]) })
          drawFeatureMetadata.setAsEditableArea(feature)
          feature.setId(area.id)
          layer.getSource()?.addFeature(feature)
        }

        // disable the DRAW interaction and enable the MODIFY interaction
        drawArea?.setActive(false)
        modifyArea?.setActive(true)
        break
      }

      // stop editing or drawing
      case 'none': {
        // disable the interactions
        drawArea?.setActive(false)
        modifyArea?.setActive(false)

        // clear the map layer of any feature
        const layer = findLayer(getMap(), 'draw')
        layer.getSource()?.clear(true)
        break
      }
    }

    // store the previous Area Mode
    prevMode = area.mode
  }
}
