import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'

import { secToTimeHHmm, isMultipleValues, getDrivingTimeFactorLabel } from '../utils'
import { useTexts } from './useTexts'

interface TimeWindowsValue {
  key: string
  value: string
}

export function useComputeTimeWindowsValues(
  timeWindows: [uui.domain.rm.TimeWindow?, uui.domain.rm.TimeWindow?],
  timeWindowLevels: number[][],
) {
  const { timeFormat } = useSelector(selectUserConfiguration)
  const texts = useTexts()

  return useMemo(() => {
    return timeWindows.reduce<TimeWindowsValue[]>((acc, curr, index) => {
      if (!curr) return acc
      const key = toString(curr, timeFormat)
      const values = generateValues(index, timeWindowLevels)

      const value = isMultipleValues(values)
        ? texts.multipleValues
        : getDrivingTimeFactorLabel(values[0])

      acc.push({
        key,
        value,
      })

      return acc
    }, [])
  }, [timeWindows, timeWindowLevels, timeFormat, texts])
}

function toString(
  tw: uui.domain.rm.TimeWindow,
  timeFormat: uui.domain.client.TimeFormat = 'hh:mm aa',
  separator: string = ' - ',
): string {
  const { startSec, endSec } = tw
  return `${secToTimeHHmm(startSec, undefined, timeFormat)}${separator}${secToTimeHHmm(
    endSec,
    undefined,
    timeFormat,
  )}`
}

function generateValues(index: number, timeWindowLevels: number[][]): number[] {
  return timeWindowLevels.map((twl: number[]) => {
    if (twl[index] === undefined) {
      throw new Error('index not found')
    }
    return twl[index]
  })
}
