import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { format, isEqual } from 'date-fns/esm'

import { useDateFnsOptions } from '@/hooks'
import { selectUserAccountPreferences, selectUserConfiguration } from '@/features/domain/user'

const getDateFormatStyle = (dateFormat: uui.domain.DateFormat) => {
  switch (dateFormat) {
    case 'DMMMY':
    case 'DMY':
      return 'startWithDay'
    case 'MDY':
    case 'MMMDY':
    case 'YMD':
      return 'startWithMonth'
  }
}

export const useFormatDateInterval = (
  start: Date,
  end: Date,
  printWeekDayWhenPossible: boolean = true,
  extendedMonth: boolean = false,
) => {
  const { dateFormat } = useSelector(selectUserAccountPreferences)
  const { dateFormatShort, dateFormatShortWithExtendedMonth } = useSelector(selectUserConfiguration)
  const options = useDateFnsOptions()

  const singleMonthShortFormat = extendedMonth ? dateFormatShortWithExtendedMonth : dateFormatShort

  const formats = {
    startWithMonth: {
      singleDate: printWeekDayWhenPossible
        ? [`EEEE - ${singleMonthShortFormat}`]
        : [singleMonthShortFormat],

      singleMonthInterval: [singleMonthShortFormat, 'dd'],

      multiMonthInterval: [dateFormatShort, dateFormatShort],
    },
    startWithDay: {
      singleDate: printWeekDayWhenPossible
        ? [`EEEE - ${singleMonthShortFormat}`]
        : [singleMonthShortFormat],

      singleMonthInterval: ['dd', singleMonthShortFormat],

      multiMonthInterval: [dateFormatShort, dateFormatShort],
    },
  }

  const intervalType = isEqual(start, end)
    ? 'singleDate'
    : start.getMonth() === end?.getMonth()
    ? 'singleMonthInterval'
    : 'multiMonthInterval'

  const dateFormatStyle = getDateFormatStyle(dateFormat)
  const intervalFormat = formats[dateFormatStyle][intervalType]

  return useMemo(
    () =>
      intervalType === 'singleDate'
        ? format(start, intervalFormat[0], options)
        : `${format(start, intervalFormat[0], options)} - ${format(end, intervalFormat[1])}`,

    [intervalType, intervalFormat, options, start, end],
  )
}
