import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function EyeCrossed(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 14 14">
        <path d="M.796615 0L.136719.673041 13.203385 14l.659896-.67304-2.811849-2.867863c1.91138-1.319958 2.854845-3.165601 2.867448-3.190438C13.971824 7.189479 14.000097 7.09584 14 7c-.000071-.107356-.035725-.211536-.101172-.295618-.048623-.07983-2.736956-4.46401-6.898828-4.46401-1.180733 0-2.240312.357063-3.157292.8664L.796615 0zM7 3.66826c1.804133 0 3.266666 1.491668 3.266666 3.33174 0 .748193-.247313 1.434004-.65625 1.989376l-1.34987-1.37676C8.348507 7.42733 8.4 7.220298 8.4 7c0-.78867-.626733-1.427888-1.4-1.427888-.215995 0-.418982.05252-.600651.14223l-1.34987-1.376759c.544525-.417083 1.216941-.669323 1.95052-.669323zm-4.689453.512218C.923607 5.370363.128324 6.660653.102083 6.703453.036115 6.78766.000123 6.892213 0 7c.000083.092098.026362.182194.075651.259363a.4766.4766 0 0 0 .001823.002788C.085498 7.27904 2.342121 11.759628 7 11.759628c.863333 0 1.642914-.15541 2.342448-.406242l-1.207682-1.232669c-.3542.134222-.7353.211023-1.134766.211023-1.804133 0-3.266667-1.491668-3.266667-3.33174 0-.407424.075301-.796115.206901-1.15737L2.310547 4.180477z" />
      </svg>
    </IconContainer>
  )
}
