import type { LazyCreateFormConfig } from '@workwave-tidal/tidal/form-fairy'
import type { FormErrors, FormFields } from '../formFields'
import type { RouteDetailEntry } from '../../../../../types'

import { useState } from 'react'
import { useValidations } from './useValidations'

export function useFormConfig(
  routeDetailsData: RouteDetailEntry[],
): LazyCreateFormConfig<FormFields, FormErrors> {
  const validations = useValidations()
  let overrideValues = {}

  if (routeDetailsData.length !== 0) {
    let preparationTimeStart = 0
    let preparationTimeEnd = 0

    let closeoutTimeStart = 0
    let closeoutTimeEnd = 0

    const drivers: { start: number; end: number }[] = []

    for (const detail of routeDetailsData) {
      if (detail.type === 'placeholder') continue

      if (
        (detail.preparation?.type === 'complete' || detail.preparation?.type === 'tracked-only') &&
        detail.preparation?.tracked
      ) {
        preparationTimeStart =
          detail.preparation.tracked.start === -1
            ? -1
            : Math.round(detail.preparation.tracked.start / 60) // Seconds -> minutes
        preparationTimeEnd =
          detail.preparation.tracked.end === -1
            ? -1
            : Math.round(detail.preparation.tracked.end / 60) // Seconds -> minutes
      }

      if (
        (detail.closeout?.type === 'complete' || detail.closeout?.type === 'tracked-only') &&
        detail.closeout?.tracked
      ) {
        closeoutTimeStart =
          detail.closeout.tracked.start === -1 ? -1 : Math.round(detail.closeout.tracked.start / 60) // Seconds -> minutes

        closeoutTimeEnd =
          detail.closeout.tracked.end === -1 ? -1 : Math.round(detail.closeout.tracked.end / 60) // Seconds -> minutes
      }

      const driverStart = detail.start
        ? detail.start.type === 'routeStart'
          ? detail.start.data.tracked.sec
          : detail.start.data.sec
        : undefined

      const driverEnd = detail.end
        ? detail.end.type === 'routeEnd'
          ? detail.end.data.tracked.sec
          : detail.end.data.sec
        : undefined

      if (driverStart !== undefined && driverEnd !== undefined) {
        drivers[detail.driverId] = {
          start: driverStart !== -1 ? Math.round(driverStart / 60) : -1,
          end: driverEnd !== -1 ? Math.round(driverEnd / 60) : -1,
        }
      }
    }

    overrideValues = {
      preparationTimeStart,
      preparationTimeEnd,
      closeoutTimeStart,
      closeoutTimeEnd,
      drivers,
    }
  }

  const [createFormConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => () => ({
    validations,
    overrideValues,
  }))

  return createFormConfig
}
