import styled from 'styled-components'
import TimeWindowExceptionListItem from './TimeWindowExceptionListItem'

const styledComponent = styled(TimeWindowExceptionListItem)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;

  > * + * {
    padding-left: 10px;
  }

  > * {
    flex: 0 0 auto;
  }

  .o-time-window-exception-list-item__button {
    margin-top: 28px;
  }

  .o-selection-list-item__field {
    flex: 1 0 auto;
    width: auto;
  }
`
export default styledComponent
