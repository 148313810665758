import type { RoadSegmentFormValues } from '../types'

export function getColor(type: RoadSegmentFormValues['type']) {
  switch (type) {
    case 'blocked':
      return 'fa603f'
    case 'delayed':
      return 'ff9500'
    case 'disabled':
      return 'c3c3c3'
  }
}
