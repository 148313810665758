import type { ReactElement } from 'react'
import { FlexBox, Text } from '@/local/components'
import { CollapseText } from '@/components/CollapseText'

type Props = {
  testId?: string
  icon?: ReactElement
  maxWidth: number
  text: string
}

export function SingleDropdownMenuItem(props: Props) {
  const { icon, text, maxWidth, testId = 'single-dropdown-menu-item' } = props

  return (
    <FlexBox
      h={36}
      shrink={0}
      borderRadius={4}
      color="$pureWhite"
      position="relative"
      data-testid={testId}
      data-trackid={testId}
      vAlignContent="center"
      padding={'0 8px 0 12px'}
    >
      {icon && <div>{icon}</div>}
      <Text ellipsis size="$p2" weight="$regular" marginRight={6}>
        <CollapseText maxWidth={maxWidth}>{text}</CollapseText>
      </Text>
    </FlexBox>
  )
}
