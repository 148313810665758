import type { HotwordId, Hotword } from '../types'
import type { Notifications } from '@/notificationForms'

import { useCallback, useMemo } from 'react'

import { useConfigureHotwordsByNotification } from './useConfigureHotwordsByNotification'

export function useHotwords(
  notification: Notifications,
  value: string,
  onChange: (t: string) => void,
) {
  const { hotwordsMap } = useConfigureHotwordsByNotification(notification)

  const hotwords = useMemo(() => computeHotwords(hotwordsMap), [hotwordsMap])

  const addHotword = useCallback(
    (id: HotwordId) => {
      onChange(`${value} @@${id}@@`)
    },
    [value, onChange],
  )

  return {
    addHotword,
    hotwords,
  }
}

function computeHotwords(hotwordsAndLabel: Record<string, string>) {
  return Object.entries(hotwordsAndLabel).map<Hotword>(([id, label]) => ({
    id: id as HotwordId,
    label,
  }))
}
