import { validateName } from './validations/validateName'
import { validateColor } from './validations/validateColor'
import { validateIcon } from './validations/validateIcon'

export function validateGpsData(
  values: uui.domain.ui.forms.GpsVehicleData,
  lockedNames: string[],
): uui.domain.ui.forms.GpsVehicleDataValidation {
  const { name, icon, color } = values

  const nameValidation = validateName(name, lockedNames)
  const colorValidation = validateColor(color)
  const iconValidation = validateIcon(icon)

  return {
    name: nameValidation,
    color: colorValidation,
    icon: iconValidation,
  }
}
