import { Stack, styled } from '@mui/material'

export const Root = styled(Stack)(() => {
  return {
    height: '100%',

    [`& > *`]: {
      flex: '1 1 0px',
      flexBasis: '15%',
      overflow: 'hidden',
    },

    [`& > * + *`]: {
      flexBasis: 0,
    },
  }
})
