import type { Props, Skin } from './typings'

import { useState, useEffect } from 'react'

import { FlexBox, H3, P2 } from '../../atoms'
import { getColorWithAlpha, ValidColor } from '../../theme'

export const WarningCard = (props: Props) => {
  const { background, color } = useSkin(props)

  const {
    Icon,
    title,
    subText,
    children,
    description,
    w = '100%',
    style = {},
    className = '',
    testId = 'WarningCard',
    action,
  } = props

  return (
    <FlexBox
      padding="14px 26px 22px 12px"
      color={color}
      w={w}
      style={{
        background,
        borderRadius: 3,
        ...style,
      }}
      data-testid={testId}
      className={className}
    >
      {!!Icon && (
        <FlexBox column shrink={0} marginRight={18} marginTop={2}>
          {Icon}
        </FlexBox>
      )}
      <FlexBox column grow={1}>
        {title ? (
          <>
            <H3>{title}</H3>
            <P2 style={{ marginTop: 8 }}>{description}</P2>
          </>
        ) : (
          <P2>{description}</P2>
        )}
        {subText ? <P2>{subText}</P2> : null}
        {action}
        {children}
      </FlexBox>
    </FlexBox>
  )
}

const useSkin = (props: Props): Skin => {
  const { preset } = props

  const [skin, setSkin] = useState<Skin>({
    background: getColorWithAlpha(getValidColorFromPreset(preset), 0.1),
    color: '$nightRider',
  })

  useEffect(() => {
    setSkin({
      color: '$nightRider',
      background: getColorWithAlpha(getValidColorFromPreset(preset), 0.1),
    })
  }, [preset])

  return skin
}

const getValidColorFromPreset = (preset: Props['preset']): ValidColor => {
  switch (preset) {
    case 'alert':
      return '$outrageousRed'
    case 'warning':
      return '$darkOrange'
    case 'info':
      return '$scienceBlue'
    case 'success':
      return '$pigmentGreen'
  }
}
