export const validNumberMetricRegexp: RegExp = /^(\d*)(\,)?([0-9]{1,2}?)?$/
export const validNumberImperialRegexp: RegExp = /^(\d*)(\.)?([0-9]{1,2}?)?$/

// TODO: these three methods below could be moved into a more general utils module (like conversions.ts or something similar)

export type DecimalSeparator = '.' | ','

export function getDecimalSeparator(useMetric: boolean): DecimalSeparator {
  return useMetric ? ',' : '.'
}

export function fromNumberToString(value: number | string, decimalSeparator: DecimalSeparator) {
  return value.toString().replace(/[\.\,]/, decimalSeparator)
}

export function fromStringToNumber(value: string) {
  return parseFloat(value.toString().replace(/[\.\,]/, '.'))
}

export function roundNumberToHundredths(number: number) {
  return Math.round(number * 100) / 100
}
