import { useCallback } from 'react'

import { useNotification } from '@/hooks'
import { useEditingStateWithCtx } from '@/atoms'
import { useAppDispatch } from '@/store'
import { roadSegmentsUtils } from '@/utils'
import { deleteRoadSegments } from '@/features/domain/roadSegment'

export function useActions(segment: uui.domain.client.rm.SegmentException) {
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const { setEditing } = useEditingStateWithCtx('segment')

  const onExport = useCallback(async () => {
    try {
      roadSegmentsUtils.exportRoadSegmentsToKML([segment])
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, segment])

  const onDelete = useCallback(async () => {
    try {
      const result = await dispatch(deleteRoadSegments([segment.id]))
      if (!deleteRoadSegments.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, segment, dispatch])

  const onEdit = useCallback(() => setEditing([segment.id]), [setEditing, segment])

  return {
    onExport,
    onDelete,
    onEdit,
  }
}
