import { useState } from 'react'

import { useIntl } from '@/intl'
import { getRecurrenceLabel, getRecurrenceDescriptionMap } from '@/server-data'

export function useTexts() {
  const { translate } = useIntl()

  const getRecurrencesDescriptionMap = getRecurrenceDescriptionMap(translate)

  const [api] = useState(() => ({
    duplicateDriver: translate({ id: 'setup.vehicleAssignments.form.errors.duplicateDriver' }),
    headerTitle: (recurrence: uui.domain.rm.Recurrence) => {
      const label = getRecurrenceLabel(recurrence.type, recurrence.value, translate)
      if (recurrence.type === 'any') {
        return label
      }
      return translate({
        id: 'setup.vehicleAssignments.form.title.edit',
        values: { exception: label },
      })
    },
    headerDescription: (recurrence: uui.domain.rm.Recurrence) =>
      getRecurrencesDescriptionMap()[recurrence.type],

    save: (recurrence: uui.domain.rm.Recurrence, creating?: boolean) => {
      if (recurrence.type === 'any') {
        return translate({
          id: 'setup.vehicleAssignments.form.updateDefaultAssignment',
        })
      }
      return translate({
        id: creating
          ? 'setup.vehicleAssignments.form.create'
          : 'setup.vehicleAssignments.form.save',
      })
    },
    saveTitle: (valid: boolean, pristine: boolean) => {
      if (!valid)
        return translate({ id: 'vehicleAssignments.form.submitButton.disabled.title.invalid' })
      if (pristine)
        return translate({ id: 'vehicleAssignments.form.submitButton.disabled.title.pristine' })

      return ''
    },
    errorTitle: translate({ id: 'setup.routing.edit.formErrorsTitle' }),
    cancel: translate({ id: 'global.cancel' }),
  }))

  return api
}
