import type { ExecutionEventMarker } from '../../executionEventMarkers'

import { gis, geo } from '@/server-data'

export function getExecutionEventMarkers(orderStep?: uui.domain.client.rm.ExtendedOrderStep) {
  if (!orderStep || orderStep.orderStep.trackingData.isEmpty) return []

  const {
    status,
    statusLatLng,
    timeInSec,
    timeInLatLng,
    timeOutSec,
    timeOutLatLng,
    autoTimeInSec,
    autoTimeInLatLng,
    autoTimeOutSec,
    autoTimeOutLatLng,
  } = orderStep.orderStep.trackingData

  const markers: ExecutionEventMarker[] = []

  if (timeInSec !== -1 && timeInLatLng) {
    const latLng = geo.latLngFromJSON(timeInLatLng)

    markers.push({
      latLng,
      type: '1',
      lonLat: gis.fromLatLngToCoordinate(latLng),
    })
  }

  if (timeOutSec !== -1 && timeOutLatLng) {
    const latLng = geo.latLngFromJSON(timeOutLatLng)

    markers.push({
      latLng,
      type: '2',
      lonLat: gis.fromLatLngToCoordinate(latLng),
    })
  }

  if (autoTimeInSec !== -1 && autoTimeInLatLng) {
    const latLng = geo.latLngFromJSON(autoTimeInLatLng)

    markers.push({
      latLng,
      type: '1',
      lonLat: gis.fromLatLngToCoordinate(latLng),
    })
  }

  if (autoTimeOutSec !== -1 && autoTimeOutLatLng) {
    const latLng = geo.latLngFromJSON(autoTimeOutLatLng)

    markers.push({
      latLng,
      type: '2',
      lonLat: gis.fromLatLngToCoordinate(latLng),
    })
  }

  if (status !== 'missing' && statusLatLng) {
    const latLng = geo.latLngFromJSON(statusLatLng)

    markers.push({
      latLng,
      type: status, // done or rescheduled
      lonLat: gis.fromLatLngToCoordinate(latLng),
    })
  }

  return markers
}
