import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderSteps } from '@/features/domain/order'

export function useTooltipText(orderStepId: string) {
  const extOrderSteps = useSelector(selectOrderSteps)

  return useMemo(() => {
    const extOrderStep = extOrderSteps[orderStepId]

    if (!extOrderStep) return

    if (extOrderStep.atDepot) {
      return extOrderStep.depot.location.geoAddress
        ? `Normalized to: ${extOrderStep.depot.location.geoAddress}`
        : undefined
    } else {
      return extOrderStep.location.geoAddress
        ? `Normalized to: ${extOrderStep.location.geoAddress}`
        : undefined
    }
  }, [extOrderSteps, orderStepId])
}
