import styled from 'styled-components'

export const IconContainer = styled.span`
  flex: 0 0 auto;
  width: 12px;
  line-height: 1;
  margin-right: 8px;
`

IconContainer.displayName = 'IconContainer'
