import { useCallback } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

import { useAppDispatch } from '@/store'
import { useIsUnmounted, useNotification } from '@/hooks'
import { isUsernameAvailable } from '@/features/domain/user/actions'

import { useTexts } from './useTexts'

type Status = 'available' | 'unavailable' | 'unknown'
type Result = [status: Status, username: string]

export type UsernameAvailable = ReturnType<typeof useIsUsernameAvailable>

/**
 * Check if a given username is available. Since the consumer could start multiple requests without
 * waiting for the completion of the previous ones, the checked-out username is returned along with the
 * availability status.
 */
export function useIsUsernameAvailable() {
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const isUnmounted = useIsUnmounted()
  const texts = useTexts()

  const availableUsername = useCallback<(username: string) => Promise<Result>>(
    async (username: string) => {
      const availableRequest = await dispatch(isUsernameAvailable(username))

      if (isUnmounted()) return ['unknown', username]

      if (isUsernameAvailable.fulfilled.match(availableRequest)) {
        const available = unwrapResult(availableRequest)
        return [available ? 'available' : 'unavailable', username]
      }
      toast.error(texts.errors.unique)
      return ['unknown', username]
    },
    [dispatch, isUnmounted, toast, texts.errors.unique],
  )

  return availableUsername
}
