/**
 * NOTE: The only purpose of this component is to provide a way to replace MUI Stack component
 *       because of the issue with React renderers in Bryntum Scheduler.
 *       Please remove this component and use MUI Stack instead when the issue is fixed.
 */
import { type CSSProperties, type ReactNode } from 'react'
import { type Variant } from '@mui/material/styles/createTypography'

interface Props {
  color?: string
  variant?: Variant
  children?: ReactNode
}

const h6Variant: CSSProperties = {
  margin: 0,
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '32px',
  letterSpacing: '0.15px',
}

const subtitle2Variant: CSSProperties = {
  margin: 0,
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: 1.57,
  letterSpacing: '0.00714em',
}

const body2Variant: CSSProperties = {
  margin: 0,
  fontWeight: 400,
  fontSize: `14px`,
  lineHeight: 1.43,
  letterSpacing: `0.01071em`,
}

const captionVariant: CSSProperties = {
  margin: 0,
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  display: 'block',
}

export function Typography(props: Props) {
  const { children, variant, color } = props
  let style: CSSProperties | undefined = { color }

  switch (variant) {
    case 'h6':
      style = { ...style, ...h6Variant }
      break

    case 'subtitle2':
      style = { ...style, ...subtitle2Variant }
      break

    case 'body2':
      style = { ...style, ...body2Variant }
      break

    case 'caption':
      style = { ...style, ...captionVariant }
      break
  }

  return <div style={style}>{children}</div>
}
