import { Stack, Typography } from '@mui/material'

import { useTexts } from '../useTexts'

export function Body() {
  const texts = useTexts()

  return (
    <Stack paddingTop={3} paddingBottom={1}>
      <Typography variant="body2">{texts.body}</Typography>
    </Stack>
  )
}
