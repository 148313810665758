import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    adjustAddress: translate({ id: 'lib.locationEditor.adjustAddress' }),
    adjustAddressDisabledHelper: translate({
      id: 'lib.locationEditor.adjustAddress.disabled.helper',
    }),
  }))

  return api
}
