import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { setPlaceFeatureMetadata } from '../placeFeatureMetadata'
import { getPlaceFeatureStyle } from './getPlaceFeatureStyle'
import { getPlaceFeatureLabelStyle } from './getPlaceFeatureLabelStyle'

export const updatePlaceFeature = (
  mapStyles: uui.domain.ui.map.markers.MapStyles['place'],
  marker: uui.domain.ui.map.markers.Place,
  feature: Feature<Geometry>,
  selected: boolean = false,
): Feature<Geometry> => {
  // ------------------------
  // Feature Geometry
  // ------------------------
  const newPoint = new Point(marker.lonLat)

  // Set default feature as 'label' and update geometry
  feature.setGeometryName('label')
  feature.setGeometry(newPoint)

  // Switch back to default feature and update geometry
  feature.setGeometryName('geometry')
  feature.setGeometry(newPoint)

  // ------------------------
  // Feature Metadata
  // ------------------------

  // update current feature mode

  const mode: uui.domain.ui.map.markers.MapStyles['place']['mode'] = marker.mode ?? mapStyles.mode
  setPlaceFeatureMetadata(feature, 'mode', mode)

  // ------------------------
  // Feature Style

  feature.setStyle([
    getPlaceFeatureStyle(marker, mode, selected),
    getPlaceFeatureLabelStyle(marker, mode),
  ])

  return feature
}
