import type { FormApi } from '@workwave-tidal/tidal/form-fairy'
import type { FormErrors, FormFields } from '../../formFields'

export function getFormValues(api: Readonly<FormApi<FormFields, FormErrors>>) {
  const values = api.getValues()

  const {
    status,
    backOfficePhone,
    automatedMessage,
    callbackConfigStatus,
    automatedMessageVoice,
    automatedMessageEnabled,
    forbidDriverCallsToCompletedOrderSteps,
    forbidDriverCallsToIncompleteOrderSteps,
  } = values

  // If the status is BASIC or OFF just return the status
  if (status !== 'ADVANCED') return { status } as uui.domain.server.rm.VoiceConfig

  const baseAdvancedValues = {
    status,
    forbidDriverCallsToCompletedOrderSteps,
    forbidDriverCallsToIncompleteOrderSteps,
  }

  // If the callback config is BACK_OFFICE return the proper callback config
  if (callbackConfigStatus === 'BACK_OFFICE') {
    return {
      ...baseAdvancedValues,

      callbackConfig: {
        status: callbackConfigStatus,
        backOfficePhone,
      },
    } as uui.domain.server.rm.VoiceConfig
  }

  // If the callback config is OFF manage data for automated message
  return {
    ...baseAdvancedValues,

    callbackConfig:
      automatedMessageEnabled === 'ON'
        ? {
            status: callbackConfigStatus,
            automatedMessage,
            automatedMessageVoice,
            automatedMessageEnabled: true,
          }
        : {
            status: callbackConfigStatus,
            automatedMessage: '',
            automatedMessageVoice: 'FEMALE',
            automatedMessageEnabled: false,
          },
  } as uui.domain.server.rm.VoiceConfig
}
