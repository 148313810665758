import { PureComponent } from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { Footer } from '@/forms-legacy'
import { FormColumn } from '@/components/layout'

import * as Texts from '../../intl'

import { Props, SimulationFormValues } from './typings'
import { validateForm, getFieldErrors } from './formValidation'
import { emptySimulationId, getFormInitialValues, computeCreationPayload } from './utils'

import Form from './Form'

interface State {
  initialValues: SimulationFormValues
}

export class SingleEdit extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    const { today, simulation, calendarSelection, isSelectingArchivedDate } = this.props

    this.state = {
      initialValues: getFormInitialValues(
        simulation,
        today,
        calendarSelection,
        isSelectingArchivedDate,
      ),
    }
  }

  componentWillUnmount() {
    const { stopEditing } = this.props
    stopEditing()
  }

  private handleOnSubmit = (values: SimulationFormValues) => {
    const { updateSimulation, createSimulation, stopEditing } = this.props
    const { name, label, id: simulationId } = values

    if (simulationId !== emptySimulationId) {
      updateSimulation({
        simulationId,
        name,
        label,
      })
    } else {
      createSimulation(computeCreationPayload(values))
    }
    stopEditing()
  }

  private handleOnCancel = () => {
    const { stopEditing } = this.props
    stopEditing()
  }

  render() {
    const { simulation, ...props } = this.props
    const { simulationsNames, readonly, submitting } = this.props
    const { initialValues } = this.state

    const { id } = initialValues
    const isCreatingForm: boolean = id === emptySimulationId

    return (
      <FinalForm
        initialValues={initialValues}
        validate={validateForm(simulationsNames)}
        onSubmit={this.handleOnSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { handleSubmit, form } = formRenderProps
          const { valid, pristine, errors = {} } = form.getState()

          const saveDisabled: boolean = (isCreatingForm ? false : pristine) || !valid || readonly
          const fieldErrors: string[] = getFieldErrors(errors)

          return (
            <FormColumn width={484} testid="form__column-secondary">
              <Form {...props} initialValues={initialValues} />
              <Footer
                errorTitle={Texts.getFormErrorsTitleText()}
                submitting={submitting}
                errors={fieldErrors}
                primary={{
                  text: Texts.getFormSaveText(isCreatingForm),
                  title: Texts.getSubmitButtonTitle(valid, readonly, pristine),
                  disabled: saveDisabled,
                  onClick: handleSubmit,
                }}
                secondary={{
                  onClick: this.handleOnCancel,
                  text: Texts.getCancelText(),
                  disabled: submitting,
                }}
                message={[Texts.getFormFooterMessageText()]}
              />
            </FormColumn>
          )
        }}
      />
    )
  }
}
