import type { FormFields, FormErrors } from './formFields'
import type { UpdateDriverActivity } from './types'

import { Box, Divider } from '@mui/material'
import { useFormMeta } from '@workwave-tidal/tidal/form-fairy'
import { Form, FormHeader } from '@workwave-tidal/tidal/form-ui/layout'

import { useTexts } from './hooks/useTexts'
import { ReasonFields } from './ReasonFields'
import { useOnSubmit } from './hooks/useOnSubmit'
import { DriverActivityFooter } from './DriverActivityFooter'
import { StartEndRoute } from './StartEndRoute/StartEndRoute'
import { DynamicLoadsCf } from './DynamicLoadsCf/DynamicLoadsCf'
import { ReasonsForNotCompletedOrdersFormHeader } from './ReasonsForNotCompletedOrdersFormHeader'

type Props = {
  onDiscard: () => void
  updateDriversActivity: UpdateDriverActivity
}

export function DriverActivityForm(props: Props) {
  const { onDiscard, updateDriversActivity } = props

  const texts = useTexts()

  // create a submit function linked to
  // - the domain update function
  // - the form API
  // - the application editing status
  const onSubmit = useOnSubmit(updateDriversActivity, onDiscard)

  const {
    meta: { submitting, validating },
  } = useFormMeta<FormFields, FormErrors>()

  return (
    <Box width={442} height="100%" data-testid="form__column-secondary" display="flex" paddingY={2}>
      <Form
        testId="driver-activity-form"
        header={<FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />}
        footer={
          <DriverActivityFooter
            onSubmit={onSubmit}
            onDiscard={onDiscard}
            submitting={submitting}
            disableSubmit={submitting || validating}
          />
        }
        onEnter={onSubmit}
        fieldsSpacing={2}
        fieldsPadding="0 32px"
      >
        <StartEndRoute />
        <DynamicLoadsCf />
        <Divider />
        <div data-testid="reason-for-not-completed-orders-field">
          <ReasonsForNotCompletedOrdersFormHeader />
          <ReasonFields />
        </div>
      </Form>
    </Box>
  )
}
