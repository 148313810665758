import { useSelector } from 'react-redux'

import { selectDevicesAggregateInfo } from '@/features/domain/device'
import { useIsSimulation } from '@/hooks'
import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
} from '@/forms-legacy'

import { TrackingOptionDisclaimer } from '../../../../../components/TrackingOptionDisclaimer'
import { canRenderGpsDataToggler } from '../../../../../utils/canRenderGpsDataToggler'
import { GpsDataViewToggler } from '../../../../../components/GpsDataViewToggler'
import { getDeviceId } from '../../../../../utils/getDeviceId'

import { useGpsDataCollapsed } from '../../hooks/useGpsDataCollapsed'
import { GpsData } from '../../components/GpsData'

import { useAllowGps } from '../hooks/useAllowGps'
import { useTexts } from '../hooks/useTexts'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function WwgpsTrackingSection(props: Props) {
  const { unifiedVehicle } = props

  const devicesAggregateInfo = useSelector(selectDevicesAggregateInfo)

  const { collapsed, toggleCollapsed } = useGpsDataCollapsed(unifiedVehicle)
  const isSimulation = useIsSimulation()
  const allowGps = useAllowGps()
  const texts = useTexts()

  // Check if the vehicle can render the GPS data toggler
  const canRenderToggler = canRenderGpsDataToggler(unifiedVehicle, devicesAggregateInfo)

  // Retreive the device id
  const deviceId = getDeviceId(unifiedVehicle) ?? ''

  // Check if the vehicle has a device
  const hasDevice = !!deviceId

  // Retreive the physical device
  const device = unifiedVehicle.isPhysicalDevice ? unifiedVehicle.device : undefined

  return (
    <>
      {allowGps && !isSimulation && (
        <ReadonlyBlock>
          <ReadonlyLabel>{texts.trackingOption}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{texts.trackingOptionValue(hasDevice)}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
          {!hasDevice && <TrackingOptionDisclaimer />}
        </ReadonlyBlock>
      )}

      {hasDevice && !isSimulation && (
        <ReadonlyBlock>
          <ReadonlyLabel>{texts.assignedGpsDevice}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>
                {`${deviceId} - ${(device as uui.domain.client.gps.wwgps.DeviceInfo)?.label}`}
              </ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      )}

      {canRenderToggler && (
        <GpsDataViewToggler
          title={texts.expanding}
          description={texts.deviceSettings(deviceId)}
          collapsed={collapsed}
          onToggle={toggleCollapsed}
        />
      )}

      {canRenderToggler && !collapsed && <GpsData unifiedVehicle={unifiedVehicle} />}
    </>
  )
}
