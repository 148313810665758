import { IconButton, Stack } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { useTexts } from '../../../hooks/useTexts'

interface Props {
  editing: boolean // whether the current plan is being edited
  onEdit: () => void // callback function for the edit button
  editPermission: 'isRmOnly' | 'gotNoTrackedEvents' | 'allowed' // permission status for editing the plan
}

export function CommandBar(props: Props) {
  const { editing, editPermission, onEdit } = props
  const texts = useTexts()

  // Check if the user has permission to edit and is not already in editing mode
  const canEdit = editPermission === 'allowed' && !editing

  // Determine the state of the button based on permission and editing status (disabled if editing)
  const forceState = editPermission === 'allowed' && editing ? 'disabled' : 'enabled'

  // Determine the tooltip text based on permission status
  const tooltipText =
    editPermission === 'allowed' ? texts.routeEditEnabled : texts.routeEditDisabled(editPermission)

  return (
    <Stack direction="row" justifyContent="flex-end">
      <ReadOnlyTooltip
        title={tooltipText}
        canEdit={canEdit}
        placement="bottom"
        forceState={forceState}
        render={preventEditing => (
          <IconButton
            onClick={onEdit}
            disabled={preventEditing}
            data-testid="routeDetailsEditButton"
          >
            <EditIcon fontSize="medium" />
          </IconButton>
        )}
      />
    </Stack>
  )
}
