import { type CSSProperties } from 'react'

import { Spinner } from '../../../Scheduler/components/Spinner'

import { VisibilityOff } from '../../icons/VisibilityOff'
import { Visibility } from '../../icons/Visibility'

interface Props {
  loading: boolean
  allRoutesState: uui.domain.ui.map.markers.RouteMapStyle
}

const iconStyle = {
  '--font-size': '14px',
} as CSSProperties

const iconContainerStyle = {
  marginTop: 4,
}

export function VisibilityHeader(props: Props) {
  if (props.loading) {
    return <Spinner size={14} />
  }

  switch (props.allRoutesState) {
    case 'empty':
      return (
        <div style={iconContainerStyle}>
          <Visibility
            className="o-scheduler-svg-icon--color-black"
            style={{ ...iconStyle, opacity: 0.25 }}
          />
        </div>
      )

    case 'on':
    case 'mixed':
      return (
        <div style={iconContainerStyle}>
          <Visibility className="o-scheduler-svg-icon--color-black" style={iconStyle} />
        </div>
      )

    case 'off':
      return (
        <div style={iconContainerStyle}>
          <VisibilityOff className="o-scheduler-svg-icon--color-black" style={iconStyle} />
        </div>
      )
  }
}
