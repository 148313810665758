import type { FormFields, FormErrors } from '../../../formFields'
import type { FormValidator, FormError } from '@workwave-tidal/tidal/form-fairy'
import type { ValidationTexts } from '../useTexts'

export const createValidateName =
  (texts: ValidationTexts): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const { value, required } = formApi.getField('name')

    const maxLengthError: FormError<keyof FormFields> = {
      id: 'name-max-length',
      field: 'name',
      message: texts.nameMaxLength,
      priority: 3,
    }

    const requiredError: FormError<keyof FormFields> = {
      id: 'name-required',
      field: 'name',
      message: texts.nameRequired,
      priority: 7,
    }

    const empty = value.trim().length === 0
    const invalidMaxLength = value.trim().length > 30

    return {
      [requiredError.id]: required && empty ? requiredError : null,
      [maxLengthError.id]: invalidMaxLength ? maxLengthError : null,
    }
  }
