import { useMemo } from 'react'
import { Footer as FooterComp } from '@/forms-legacy'

interface Props {
  handleSubmit: () => Promise<any> | void
  submitBtnClassName?: string
  fieldsWithError: string[]
  saveDisabled: boolean
  onCancel: () => void
  readonly: boolean
  submitting: boolean
  creating: boolean
  pristine: boolean
  valid: boolean
  texts: {
    save: (creating: boolean) => string
    saveTitle: (valid: boolean, readonly: boolean, pristine: boolean) => string
    cancel: string
    errors: string
  }
}

export function Footer(props: Props) {
  const {
    submitBtnClassName,
    fieldsWithError,
    handleSubmit,
    saveDisabled,
    readonly,
    submitting,
    onCancel,
    creating,
    pristine,
    valid,
    texts,
  } = props

  const primary = useMemo(
    () => ({
      title: texts.saveTitle(valid, readonly, pristine),
      className: submitBtnClassName,
      text: texts.save(creating),
      disabled: saveDisabled,
      onClick: handleSubmit,
    }),
    [submitBtnClassName, saveDisabled, handleSubmit, readonly, creating, pristine, texts, valid],
  )

  const secondary = useMemo(
    () => ({
      disabled: submitting,
      text: texts.cancel,
      onClick: onCancel,
    }),
    [onCancel, texts, submitting],
  )

  return (
    <FooterComp
      errorTitle={texts.errors}
      errors={fieldsWithError}
      submitting={submitting}
      secondary={secondary}
      primary={primary}
    />
  )
}
