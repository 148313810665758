import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import { conversionUtils } from '@/server-data'

export const useFormatNumber = (source: number): string => {
  const { language } = useSelector(selectUserConfiguration)

  return useMemo(() => {
    return formatNumber(source, language)
  }, [language, source])
}

export function useCreateFormatNumber() {
  const { language } = useSelector(selectUserConfiguration)

  return useCallback(
    (source: number) => {
      const format = conversionUtils.intlFormat[language]
      return Intl.NumberFormat(format).format(source)
    },
    [language],
  )
}

export function formatNumber(source: number, language: string) {
  const format = conversionUtils.intlFormat[language]
  return Intl.NumberFormat(format).format(source)
}
