import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Hamburger(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M15.5 13.176a.5.5 0 01.5.5V15.5a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5v-1.824a.5.5 0 01.5-.5h15zm0-6.588a.5.5 0 01.5.5v1.824a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5V7.088a.5.5 0 01.5-.5h15zM15.5 0a.5.5 0 01.5.5v1.824a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5V.5A.5.5 0 01.5 0h15z" />
      </svg>
    </IconContainer>
  )
}
