import type { ExtendedPrivilege } from './core'

import { useSelector } from 'react-redux'

import { selectUserPrivileges } from '@/features/domain/user'
import { useIsLoginAs } from '@/hooks'

import { checkPrivileges } from './core'

export const useHasPrivileges = (
  accountPrivileges: ExtendedPrivilege[],
  exactPrivileges: boolean,
) => {
  const userPrivileges = useSelector(selectUserPrivileges)
  const isLoginAs = useIsLoginAs()

  return checkPrivileges(userPrivileges, accountPrivileges, exactPrivileges, isLoginAs)
}
