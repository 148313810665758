type MapMode = 'on' | 'off'

interface MapStylesCache {
  depot: MapMode | 'label'
  order: MapMode | 'maximized'
  vehicle: MapMode | 'label'
  route: MapMode
  routePolyline: MapMode
  region: MapMode
  trafficProfile: MapMode
  roadSegment: MapMode

  geofence: MapMode
  breadcrumb: MapMode
  deviceEvent: MapMode
  place: MapMode | 'label'
}

// ------------------------------------------------------------------------------------------------
// local-storage management
// ------------------------------------------------------------------------------------------------

// const mapStylesCacheId = 'uui/v3/utils/mapStylesCache'

/**
 * Try to access the cache for Map Styles preferences
 */
export const getMapStylesCache = (): Partial<MapStylesCache> => {
  // FIXME: migrate to localForage, see #194
  console.log(`Retrieving map-styles from localStorage`)

  // try {
  //   return JSON.parse(localStorage.getItem(mapStylesCacheId) ?? '{}')
  // } catch (e) {
  //   // JOURNAL: should it be tracked?
  // }

  return {}
}

/**
 * Write the cache for Map Styles preferences
 */
export const setMapStylesCache = (mapStyles: uui.domain.ui.map.markers.MapStyles) => {
  // FIXME: migrate to localForage, see #194
  console.log(`Writing map-styles into localStorage`, mapStyles)

  // try {
  //   const cache: Partial<MapStylesCache> = {}

  //   for (const key of Object.keys(mapStyles)) {
  //     cache[key] = mapStyles[key as keyof uui.domain.ui.map.markers.MapStyles].mode
  //   }

  //   localStorage.setItem(mapStylesCacheId, JSON.stringify(cache))
  // } catch (e) {
  //   // JOURNAL: should it be tracked?
  // }
}

/**
 * Clear the cache for Map Styles preferences
 */
export const clearMapStylesCache = () => {
  // FIXME: migrate to localForage, see #194
  console.log(`Clearing map-styles from localStorage`)

  // try {
  //   localStorage.removeItem(mapStylesCacheId)
  // } catch (e) {
  //   // JOURNAL: should it be tracked?
  // }
}
