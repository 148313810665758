import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Recover(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 16 16">
        <path
          d="M8.001 2c-3.3 0-5.999 2.72-5.999 6.05 0 1.258.386 2.43 1.041 3.401l-.922.93c-.26.262-.075.71.293.71h2.41c.328.07.67-.031.908-.269.25-.248.351-.61.27-.953V9.476c0-.371-.445-.557-.705-.296l-.801.808c-.313-.574-.494-1.232-.494-1.938 0-2.239 1.78-4.033 4-4.033C10.221 4.017 12 5.81 12 8.05c0 1.962-1.376 3.58-3.198 3.953-.35.071-.636.326-.75.667-.114.342-.038.719.198.989.237.27.598.392.948.321C11.938 13.42 14 10.967 14 8.05 14 4.72 11.302 2 8.001 2z"
          transform="translate(-751 -425) translate(718 398) translate(33 27)"
        />
      </svg>
    </IconContainer>
  )
}
