import { ActionPermission } from '../../types'

export function computeEditPermission(
  devicesCount: number,
  canEditGpsData: boolean,
): ActionPermission {
  if (!canEditGpsData) return 'hidden'
  if (devicesCount !== 1) return 'disabled'
  return 'enabled'
}
