import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    override: translate({ id: 'vehicles.exception.copySettings.vehiclesList.item.override' }),
    add: translate({ id: 'vehicles.exception.copySettings.vehiclesList.item.add' }),
  }))

  return api
}
