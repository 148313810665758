import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function PreparationTime(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 32 32">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 4C5.79086 4 4 5.79086 4 8V24C4 26.2091 5.79086 28 8 28H24C26.2091 28 28 26.2091 28 24V8C28 5.79086 26.2091 4 24 4H8ZM13.7297 16.8395C14.0081 16.5147 14.0081 16.0355 13.7297 15.7107L10.5355 11.9842C10.0115 11.3728 9.00977 11.7434 9.00977 12.5486L9.00977 20.0016C9.00977 20.8068 10.0115 21.1774 10.5355 20.566L13.7297 16.8395ZM19.8007 16.8395C20.0791 16.5147 20.0791 16.0355 19.8007 15.7107L16.6065 11.9842C16.0825 11.3728 15.0808 11.7434 15.0808 12.5486L15.0808 20.0016C15.0808 20.8068 16.0825 21.1774 16.6065 20.566L19.8007 16.8395ZM25.8717 15.7107C26.1501 16.0355 26.1501 16.5147 25.8717 16.8395L22.6775 20.566C22.1535 21.1774 21.1518 20.8068 21.1518 20.0016L21.1518 12.5486C21.1518 11.7434 22.1535 11.3728 22.6775 11.9842L25.8717 15.7107Z"
        />
      </svg>
    </IconContainer>
  )
}
