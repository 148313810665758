import { useState, useEffect, useRef } from 'react'

import { useConfigureModalController, useModalController, useCloseModal } from '@/atoms'
import { useBuildProgress } from './useBuildProgress'

type ModalState = 'ready' | 'pending'
interface ModalData {
  progress: number | 'completed'
  canCancel: boolean
  canceling: boolean
}

export const modalId = 'buildProgress'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = () => {
  const progress = useBuildProgress()
  const close = useCloseModal()

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      data: { progress, canCancel: false, canceling: false },
      close,
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const { updateData, close: closeModal } = ctrl

  const api = useRef({ updateData, closeModal })

  // EFFECTS

  // API ref management
  useEffect(() => {
    api.current.updateData = updateData
    api.current.closeModal = closeModal
  }, [updateData, closeModal])

  // Updates the progress value or closes the modal
  useEffect(() => {
    if (progress === 'completed') {
      api.current.closeModal?.()
      return
    }

    api.current.updateData({ progress: progress })
  }, [progress])

  // Enables build cancel button after 8 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      api.current.updateData({ canCancel: true })
    }, 8000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return ctrl
}
