import { type ResourceModel } from '@bryntum/schedulerpro'
import { clsx } from '@/utils'

import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../Scheduler/components/Typography'

interface Props {
  record: ResourceModel
  field: string
  value: number
}

export function ViolationsCell(props: Props) {
  const { value } = props

  const className = clsx({
    'o-scheduler-violation': true,
    'o-scheduler-violation--has-violations': value > 0,
  })

  return (
    <SingleColumnContainer style={{ alignItems: 'center' }}>
      <div className={className}>
        <Typography fontSize={12}>{value}</Typography>
      </div>
    </SingleColumnContainer>
  )
}
