import type { MouseEvent } from 'react'
import type { RenderItemProps } from '@workwave-tidal/tidal/components/VirtualList'

import { useMemo, useCallback } from 'react'

import { FlexBox, InvisibleButton, Text } from '@/local/components'
import { Radio } from '@/components/primitives/Radio'
import { Tag } from '@/components/primitives/Tag'

import { useTexts } from '../useTexts'

type Props = RenderItemProps<uui.domain.client.rm.Territory> & {
  territoryId: string
}

function TerritoryListItem(props: Props) {
  const { item, onClick, selected, territoryId } = props
  const texts = useTexts()
  const activeTerritory = territoryId === item.id

  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      onClick({ item, event })
    },
    [item, onClick],
  )

  return (
    <InvisibleButton
      disabled={activeTerritory}
      onClick={handleOnClick}
      key={item.id}
      style={{ width: 'calc(100% - 12px)' }}
      data-testid="change-territory__button"
    >
      <FlexBox w="100%" h={20} paddingBottom={4}>
        <FlexBox vAlignContent="center" shrink={1} w="100%" h="100%">
          <Radio checked={selected} disabled={activeTerritory} />
          <Text
            size="$m"
            weight="$regular"
            color={activeTerritory ? '$silver' : '$nightRider'}
            marginLeft={5}
            ellipsis
          >
            {item.name}
          </Text>
        </FlexBox>
        <FlexBox vAlignContent="center" shrink={0} w="auto" h="100%">
          {activeTerritory && (
            <Tag color="$pureWhite" backgroundColor="$pigmentGreen" style={{ marginRight: 10 }}>
              {texts.openedTerritoryText}
            </Tag>
          )}
          {item.stats && (
            <Text size="$p3" color="$silver">
              {texts.vehicleStatsText(
                item.stats.operationsVehicles,
                item.licensingLimits.vehiclesOps,
              )}
            </Text>
          )}
        </FlexBox>
      </FlexBox>
    </InvisibleButton>
  )
}

export const useTerritoryListItem = (territoryId: string) => {
  return useMemo(
    () => (props: RenderItemProps<uui.domain.client.rm.Territory>) => {
      return <TerritoryListItem {...props} territoryId={territoryId} />
    },
    [territoryId],
  )
}
