import { Stack, Box, Typography } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { ViewActionButton } from '@/formUi'

import { useCopyDynamicLoadsCfModal } from '../../../modals/CopyDynamicLoadsCfModal'
import { useTexts } from './useTexts'

interface Props {
  territory: uui.domain.client.rm.Territory
  hasOnlyOneTerritory: boolean
}

export function DynamicLoadsCfView(props: Props) {
  const {
    hasOnlyOneTerritory,
    territory: { enableDynamicCustomFields, enableDynamicLoads },
  } = props
  const texts = useTexts()

  const { show: showModal, Modal: CopyDynamicLoadsCfModal } = useCopyDynamicLoadsCfModal()

  const nothingSelected = !enableDynamicLoads && !enableDynamicCustomFields

  return (
    <>
      <Stack spacing={2} data-testid="driver-activity-dynamic-loads-cf">
        <Box>
          <Typography variant="h6">{texts.title}</Typography>
          <Typography variant="caption">{texts.description}</Typography>
        </Box>

        <Tooltip placement="top" title={texts.copyTooltip} disabled={!hasOnlyOneTerritory}>
          <ViewActionButton
            disabled={hasOnlyOneTerritory}
            onClick={showModal}
            label={texts.copy}
            testId="driver-activity-dynamic-loads-cf_copy-btn"
          />
        </Tooltip>

        <Stack spacing={0}>
          {nothingSelected ? (
            <Typography variant="caption" color="text.disabled">
              Nothing selected
            </Typography>
          ) : (
            <>
              {enableDynamicLoads && (
                <Typography variant="caption" color="text.disabled">
                  {texts.loads}
                </Typography>
              )}
              {enableDynamicCustomFields && (
                <Typography variant="caption" color="text.disabled">
                  {texts.customFields}
                </Typography>
              )}
            </>
          )}
        </Stack>
      </Stack>
      <CopyDynamicLoadsCfModal />
    </>
  )
}
