import { SuperCarIndicator } from '@/components/SuperCarIndicator'

import { useMapRenderingQueue } from '../renderingQueue'

interface Props {
  delay?: number
  minDuration?: number
}

export function RenderingQueueIndicator(props: Props) {
  const { delay, minDuration } = props

  const queueRunning = useMapRenderingQueue(delay, minDuration)

  return queueRunning ? <SuperCarIndicator size={200} duration={3} /> : null
}
