import { ProjectModel, type SchedulerPro } from '@bryntum/schedulerpro'
import { subscribe } from 'valtio/vanilla'

import {
  selectSchedulerEventsAsArray,
  selectSchedulerResourcesAsArray,
} from '@/features/domain/scheduler'

import { domainProxy, store } from '@/store'

import { schedulerPreferencesAtom, setSchedulerPreferences } from '../schedulerPreferences'
import { getSorterByField } from '../columns/utils/getSorterByField'

import { computeTimeRanges, syncProjectTimeRanges } from './sync/timeRange'
import { syncUnavailableRoutesVisibility } from './sync/unavailableRoutes'
import { syncApprovedRoutesVisibility } from './sync/approvedRoutes'
import { syncProjectGrouping } from './sync/grouping'
import { syncProjectSorting } from './sync/sorting'
import { syncProjectFilter } from './sync/filter'
import { syncProjectData } from './sync/data'

import { approvedRouteFilter, unavailableRouteFilter } from './filters'
import { dateGrouper, vehicleGrouper } from './groupers'

export type SchedulerInstance = {
  instance?: SchedulerPro
}

// -----------------------------------
// Scheduler atom
// ------------------------------------

export const project = new ProjectModel({
  events: selectSchedulerEventsAsArray(store.getState()),
  resources: selectSchedulerResourcesAsArray(store.getState()),
  timeRanges: computeTimeRanges(),

  eventStore: {
    syncDataOnLoad: true,
    useRawData: true,
    filters: schedulerPreferencesAtom.preferences.showApprovedRoutes ? [] : [approvedRouteFilter],
  },

  resourceStore: {
    reapplyFilterOnUpdate: true,
    reapplyFilterOnAdd: true,
    syncDataOnLoad: true,
    useRawData: true,
    onSort: ({ source }) => {
      setSchedulerPreferences({
        sorters: source.sorters.map(s => ({ field: s.field, ascending: s.ascending })),
      })
    },
    filters: schedulerPreferencesAtom.preferences.showUnavailableRoutes
      ? []
      : [unavailableRouteFilter],
    groupers: [
      schedulerPreferencesAtom.preferences.groupBy === 'vehicleId' ? vehicleGrouper : dateGrouper,
    ],
    sorters: [
      {
        ...schedulerPreferencesAtom.preferences.sorters[0],
        fn: getSorterByField(schedulerPreferencesAtom.preferences.sorters[0].field),
      },
    ],
  },

  timeRangeStore: {
    useRawData: true,
  },
})

export const schedulerInstance: SchedulerInstance = {
  instance: undefined,
}

// ------------------------------------
// Read functions
// ------------------------------------

export function getProject() {
  return project
}

export function subscribeSchedulerProject() {
  // start listening to server-data events
  const unsubscribeDataChange = domainProxy.subscribeToDataChangeSuccess(domainAction => {
    // Suspend refresh to avoid multiple refreshes
    schedulerInstance.instance?.suspendRefresh()

    // SYNC
    syncProjectTimeRanges()
    syncProjectFilter()
    syncProjectData(domainAction)

    // Resume refresh
    schedulerInstance.instance?.resumeRefresh(true)
  })

  const unsubscribePreferences = subscribe(schedulerPreferencesAtom, () => {
    // Suspend refresh to avoid multiple refreshes
    schedulerInstance.instance?.suspendRefresh()

    // SYNC
    syncProjectSorting()
    syncProjectGrouping()
    syncApprovedRoutesVisibility()
    syncUnavailableRoutesVisibility()

    // Resume refresh
    schedulerInstance.instance?.resumeRefresh(true)
  })

  return () => {
    unsubscribeDataChange()
    unsubscribePreferences()
  }
}
