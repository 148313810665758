import { PureComponent } from 'react'

import { CustomFieldsListField } from '@/forms-legacy'

import { Props } from '../typings'
import * as OrderTexts from '../../../../../../intl'

export default class CustomFields extends PureComponent<Props> {
  render() {
    const { customFields, orderStepType, allowEmptyCustomFields, maxCustomFieldsPerOrder } =
      this.props

    return (
      <CustomFieldsListField
        name={`${orderStepType}.customFields`}
        label={OrderTexts.getFieldLabelCustomFieldsText()}
        wrappedInputProps={{
          allowEmptyCustomFields,
          suggestions: customFields,
          maxCount: maxCustomFieldsPerOrder,
        }}
        testid="customFields"
      />
    )
  }
}
