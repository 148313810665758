import type { PartialStoreState } from '../typings'

import { isWithinInterval } from 'date-fns'

import { createSelector } from '@reduxjs/toolkit'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { getCalendarizedSettings } from '@/server-data'

import { selectWorkingDayStartSec } from '../territory'
import { selectVehicles, selectVehiclesByVehicleId } from '../vehicle'

// ATTENTION: All the Scheduler' components must consume the routesInfo through `useRoutesInfoList`
export const selectRoutesInfo = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.scheduler.routesInfo

export const selectRoutesInfoIdsInRange = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.scheduler.routesInfoIdsInRange

export const selectSchedulerRoutes = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.scheduler.schedulerRoutes

export const selectSchedulerRoutesAsMap = createSelector(
  selectSchedulerRoutes,
  routes => new Map(Object.entries(routes)),
)
export const selectOrderStepIdSchedulerMapping = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.scheduler.orderStepIdSchedulerMapping

export const selectSchedulerResources = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.scheduler.resources

export const selectSchedulerResourcesAsArray = createSelector(selectSchedulerResources, resources =>
  structuredClone(Object.values(resources)),
)

export const selectSchedulerEvents = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.scheduler.events

export const selectSchedulerEventsAsArray = createSelector(selectSchedulerEvents, events =>
  structuredClone(Object.values(events)),
)

export const selectRoutesInfoInRange = createSelector(
  selectRoutesInfo,
  selectCalendarRangeInterval,
  (routesInfos, calendarRange) =>
    Object.keys(routesInfos).reduce<
      Record<string, uui.domain.client.rm.SchedulerRowRouteOrUnavailable>
    >((acc, id) => {
      const { date } = routesInfos[id]
      const isRouteInCalendarRange = isWithinInterval(date, calendarRange)
      if (isRouteInCalendarRange) acc[id] = routesInfos[id]

      return acc
    }, {}),
)

export const selectApprovedVersionRoutes = (state: PartialStoreState) => {
  const routesInfo = selectRoutesInfo(state)
  const approvedVersions: Record<
    string,
    uui.domain.client.rm.SchedulerRowRouteOrUnavailable['approvedVersion']
  > = Object.keys(routesInfo).reduce((acc, id) => {
    if (!!routesInfo[id].approvedVersion) acc[id] = routesInfo[id].approvedVersion

    return acc
  }, {})

  return approvedVersions
}

export const selectAllLockedRoutes = createSelector(
  selectRoutesInfo,
  selectCalendarRangeInterval,
  (routesInfos, calendarRange) =>
    Object.values(routesInfos).every(routeInfo => {
      const { date, type, locked } = routeInfo
      const isRouteInCalendarRange = isWithinInterval(date, calendarRange)
      const unavailable = type === 'routeUnavailable'

      // not in range routes or unavailable ones has not to be consider
      if (!isRouteInCalendarRange || unavailable) return true

      return !!locked
    }),
)

export const selectDayRangeInfo = createSelector(
  selectRoutesInfoInRange,
  selectWorkingDayStartSec,
  selectVehicles,
  selectVehiclesByVehicleId,
  (routesInfos, workingDayStartSec, unifiedVehicles, unifiedIdMap) => {
    const result: uui.domain.client.rm.SchedulerDayRangeInfo = {
      dayLength: -1,
      dayRange: { startSec: -1, endSec: -1 },
      dayStartOffset: workingDayStartSec,
      emptyDay: false,
    }

    const day = 24 * 3600
    const routesInfosArr = Object.values(routesInfos)

    const dayRange = routesInfosArr.reduce<uui.domain.rm.TimeWindow>(
      (timeWindow, row) => {
        if (row.type !== 'route') return timeWindow

        const vehicle = unifiedVehicles[unifiedIdMap[row.vehicleId]]

        // getCalendarized settings and access vehicle time window, default to row active range (it is an unreachable case tho)
        const vehicleTimeWindow = vehicle.hasRoutingLicense
          ? getCalendarizedSettings(vehicle.vehicle, row.dateAsString).timeWindow
          : row.activeRange

        const { activeRange } = row

        const skipRow =
          activeRange.startSec === -1 &&
          activeRange.endSec === -1 &&
          vehicleTimeWindow.startSec === -1 &&
          vehicleTimeWindow.endSec === -1

        if (skipRow) return timeWindow

        // startSec is the min from the row start sec and the vehicle start sec
        const startSec =
          activeRange.startSec !== -1
            ? Math.min(activeRange.startSec, vehicleTimeWindow.startSec)
            : vehicleTimeWindow.startSec

        // endSec is the max from the row end sec and the vehicle end sec
        const endSec =
          activeRange.endSec !== -1
            ? Math.max(activeRange.endSec, vehicleTimeWindow.endSec)
            : vehicleTimeWindow.endSec

        const updateStartSec =
          timeWindow.startSec === -1 || startSec < timeWindow.startSec
            ? startSec
            : timeWindow.startSec

        const updateEndSec =
          timeWindow.endSec === -1 || endSec > timeWindow.endSec ? endSec : timeWindow.endSec

        return {
          startSec: updateStartSec,
          endSec: updateEndSec,
        }
      },
      {
        startSec: -1,
        endSec: -1,
      },
    )

    // remove 1 hour from startSec
    dayRange.startSec =
      dayRange.startSec !== -1 ? Math.max(dayRange.startSec - 3600, workingDayStartSec) : -1

    // add 1 hour to endSec
    // endSec has no cap to 24 hours
    dayRange.endSec = dayRange.endSec !== -1 ? dayRange.endSec + 3600 : -1

    const emptyDay =
      dayRange.startSec === -1 || dayRange.endSec === -1 || routesInfosArr.length === 0

    if (dayRange.startSec === -1) {
      dayRange.startSec = 8 * 3600 + workingDayStartSec
    }

    if (dayRange.endSec === -1) {
      dayRange.endSec = 17 * 3600 + workingDayStartSec
    }

    const dayLength = Math.max(day, dayRange.endSec - workingDayStartSec)

    result.dayRange = dayRange
    result.emptyDay = emptyDay
    result.dayLength = dayLength

    return result
  },
)

export const selectRouteMapStyles = (state: PartialStoreState) => {
  return Object.keys(state.domain.publicData.domain.rm.scheduler.resources).reduce<
    Record<string, uui.domain.ui.map.markers.RouteMapStyle>
  >((acc, routeId) => {
    const route = state.domain.publicData.domain.rm.scheduler.resources[routeId]
    acc[routeId] = route.routeVisible

    return acc
  }, {})
}

export const selectRouteMapStylesInList = createSelector(
  selectRouteMapStyles,
  selectSchedulerResources,
  (
    routeMapStyles: Record<string, uui.domain.ui.map.markers.RouteMapStyle>,
    resources: Record<string, uui.domain.client.rm.SchedulerResource>,
  ) =>
    Object.keys(resources).reduce<Record<string, uui.domain.ui.map.markers.RouteMapStyle>>(
      (acc, routeId) => {
        if (typeof routeId !== 'string') return acc

        acc[routeId] = routeMapStyles[routeId]

        return acc
      },
      {},
    ),
)

export const selectRoutesLoads = createSelector(
  selectRoutesInfoInRange,
  selectVehicles,
  selectVehiclesByVehicleId,
  (routesInfosMap, unifiedVehicles, unifiedIdMap) => {
    const routesInfo = Object.values(routesInfosMap)

    const loadsSet = new Set<string>()

    for (const routeInfo of routesInfo) {
      if (routeInfo.type !== 'route') continue

      const vehicle = unifiedVehicles[unifiedIdMap[routeInfo.vehicleId]]
      const loadsMap = vehicle.hasRoutingLicense
        ? getCalendarizedSettings(vehicle.vehicle, routeInfo.dateAsString).loadCapacities ?? {}
        : {}
      const loads = Object.keys(loadsMap)

      for (const load of loads) {
        loadsSet.add(load)
      }
    }

    return Array.from(loadsSet)
  },
)
