import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function CopyOrders(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 59 55">
        <path d="M22.8731 38.7785L22.874 38.774C23.4926 35.6789 25.413 33.9884 28.1749 32.9398C29.4057 32.4724 30.723 32.175 31.9997 31.9945V34.4999C31.9997 34.5202 31.9998 34.5405 32 34.5607V52C32 53.6569 30.6569 55 29 55H3C1.34315 55 0 53.6569 0 52V26C0 24.3431 1.34315 23 3 23H13.604C11.3961 26.8164 10 31.5919 10 37.5C10 40.8399 12.5314 43.6359 15.854 43.968C16.1022 43.993 16.3226 44 16.501 44C19.5737 44 22.2587 41.8375 22.8731 38.7785Z" />
        <path d="M19.6514 23C18.1327 24.8799 16.8689 27.1528 16.0438 29.9008C15.6421 31.2387 15.3444 32.6892 15.1719 34.2617C15.0693 35.1965 15.011 36.1745 15.0014 37.1975L15.0014 37.2004C15.0005 37.2998 15 37.3997 15 37.5C15 38.271 15.585 38.917 16.353 38.993C16.402 38.998 16.452 39 16.501 39C17.209 39 17.829 38.501 17.971 37.794C17.9907 37.6951 18.0114 37.5971 18.0329 37.5C18.2393 36.5668 18.5251 35.7128 18.8776 34.9314C19.344 33.8979 19.9272 32.9915 20.5986 32.1972C23.5202 28.7406 28.1108 27.4056 31.9997 26.9537C31.9996 26.9537 31.9998 26.9537 31.9997 26.9537L32 26C32 24.3431 30.6569 23 29 23H19.6514Z" />
        <path d="M16.353 38.993C16.402 38.998 16.452 39 16.501 39C17.209 39 17.829 38.501 17.971 37.794C20.0689 27.2978 32.0078 26.5509 36.9997 26.7829V34.5C36.9997 35.106 37.3647 35.653 37.9257 35.886C38.4857 36.117 39.1307 35.99 39.5607 35.561L53.5605 21.5608C54.1465 20.9758 54.1465 20.0248 53.5605 19.4398L39.5607 5.43964C39.1317 5.01063 38.4867 4.88163 37.9257 5.11464C37.3647 5.34564 36.9997 5.89264 36.9997 6.49965V14.1947C31.3668 14.9307 15 18.6668 15 37.5C15 38.271 15.585 38.917 16.353 38.993Z" />
      </svg>
    </IconContainer>
  )
}
