import type { LoadReadonlyValue } from '../../../types'

import {
  ReadonlyLabel,
  ReadonlyLabelSup,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldLabelSeparator,
  ReadonlyFieldRow,
  ReadonlyFieldRowPart,
} from '@/forms-legacy'

import { useTexts } from '../useTexts'

interface Props {
  loads: LoadReadonlyValue[]
}

export function Loads(props: Props) {
  const { loads } = props
  const texts = useTexts()

  const empty: boolean = loads.length === 0
  const separatorStyle = {
    marginRight: 10,
  } as const

  return (
    <ReadonlyBlock>
      <ReadonlyLabel empty={empty}>{texts.loads}</ReadonlyLabel>
      {loads.map(({ name, value, min }: LoadReadonlyValue) => {
        const minEmpty = min === undefined
        const maxValueAsNumber = parseInt(value) / 100
        const minValueAsNumber = min ? parseInt(min) / 100 : 0

        return (
          <ReadonlyField key={name}>
            <ReadonlyFieldRow>
              <ReadonlyFieldRowPart primary>
                <ReadonlyFieldLabel style={{ paddingRight: 10 }}>{name}</ReadonlyFieldLabel>
              </ReadonlyFieldRowPart>
              <ReadonlyFieldRowPart style={{ width: 100 }}>
                <ReadonlyFieldLabelSeparator style={separatorStyle} />
                <ReadonlyLabelSup style={{ marginRight: 3 }}>{texts.max}</ReadonlyLabelSup>
                <ReadonlyFieldLabel>{maxValueAsNumber}</ReadonlyFieldLabel>
              </ReadonlyFieldRowPart>
              <ReadonlyFieldRowPart style={{ width: 100 }}>
                <ReadonlyFieldLabelSeparator style={separatorStyle} />
                <ReadonlyLabelSup style={{ marginRight: 3 }} empty={minEmpty}>
                  {texts.min}
                </ReadonlyLabelSup>
                <ReadonlyFieldLabel empty={minEmpty}>
                  {minEmpty ? texts.none : minValueAsNumber}
                </ReadonlyFieldLabel>
              </ReadonlyFieldRowPart>
            </ReadonlyFieldRow>
          </ReadonlyField>
        )
      })}
      {!empty && <ReadonlyLabel small>{texts.loadsInfo}</ReadonlyLabel>}
    </ReadonlyBlock>
  )
}
