import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    switchTerritory: translate({ id: 'app.header.territoryMenu.switch' }),
    territories: translate({ id: 'territoriesListModal.territoryList.title' }),
  }))

  return api
}
