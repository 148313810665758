import { useEffect, useCallback, useState, useRef } from 'react'

import { tryCloseTransaction, tryOpenTransaction } from '@/features/domain/transaction'
import { pauseGpsPoll, resumeGpsPoll } from '@/features/domain/gps'
import { resetEditingState } from '@/atoms'

import { useAppDispatch } from '@/store'
import { journal } from '@/server-data'

import { useNotification } from './useNotification'

/**
 * That hook creates an effect that tries to open a transaction and pause GPS poll on mount and
 * tries to close a transaction and resumes GPS poll on unmount
 */
export function useFormTransaction() {
  const [state, setState] = useState<'loading' | 'ready'>('loading')
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const tryOpenRan = useRef(false)

  const tryOpen = useCallback(async () => {
    try {
      // Let's try to open a new transaction
      const openTransactionResult = await dispatch(tryOpenTransaction())

      if (tryOpenTransaction.rejected.match(openTransactionResult)) {
        throw new Error(openTransactionResult.error.message)
      }
    } catch (error) {
      // If not able to open a transaction, let's reset the editing state
      resetEditingState()
      toast.error(error.message)

      return
    }

    try {
      // Let's pause GPS poll
      const pausePollResult = await dispatch(pauseGpsPoll())

      if (pauseGpsPoll.rejected.match(pausePollResult)) {
        throw new Error(pausePollResult.error.message)
      }
    } catch (error) {
      // If the GPS polls pause throws an error, it should be fixed, because it should not happen.
      const message = `Failed while trying to pause GPS poll:  ${error.message}`

      if (process.env.NODE_ENV === 'development') {
        throw new Error(message)
      } else {
        journal.store(message)
      }
    }

    setState('ready')
  }, [dispatch, toast])

  const tryClose = useCallback(async () => {
    try {
      // Let's try to close the active transaction
      const result = await dispatch(tryCloseTransaction())

      if (tryCloseTransaction.rejected.match(result)) {
        throw new Error(result.error.message)
      }
    } catch (error) {
      toast.error(error.message)
      return
    }

    try {
      // Let's resume GPS poll
      const resumePollResult = await dispatch(resumeGpsPoll())

      if (resumeGpsPoll.rejected.match(resumePollResult)) {
        throw new Error(resumePollResult.error.message)
      }
    } catch (error) {
      // If the GPS polls pause throws an error, it should be fixed, because it should not happen.
      const message = `Failed while trying to resume GPS poll:  ${error.message}`

      if (process.env.NODE_ENV === 'development') {
        throw new Error(message)
      } else {
        journal.store(message)
      }
    }

    setState('ready')
  }, [dispatch, toast])

  useEffect(() => {
    if (!tryOpenRan.current) {
      tryOpen()
      tryOpenRan.current = true
    }

    return () => {
      tryOpenRan.current = true
      tryClose()
    }
  }, [tryOpen, tryClose])

  return state
}
