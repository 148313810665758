import { ReactElement, useContext, useEffect } from 'react'

import { PopupChildrenQueueContext } from '../context/PopupChildrenQueueContext'

type Props = { children: ReactElement }

/**
 * Render the passed children in the external popup, if any.
 */
export function PopupWindow(props: Props) {
  const { setChildrenQueue } = useContext(PopupChildrenQueueContext)
  const { children } = props

  useEffect(() => {
    setChildrenQueue(prev => {
      return [...prev, children]
    })

    return () => {
      setChildrenQueue(prev => {
        return prev.filter(child => child !== children)
      })
    }
  }, [setChildrenQueue, children])

  return null
}
