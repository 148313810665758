import type { Props } from '../primitives/NumericInput/typings'

import styled from 'styled-components'

import { default as CoreNumericInput } from '../primitives/NumericInput'

const input = styled(CoreNumericInput)<Props>``
export const NumericInput = styled(input).attrs<Props>(() => ({
  className: 'o-numeric-input-field',
}))`
  border-color: ${p => p.theme.colors.$pureBlack};
`
NumericInput.displayName = 'NumericInput'
