import { useCallback, useEffect, useRef, useState } from 'react'

interface Args<T = string> {
  value: T
  onChange: (value: T) => void
  delay?: number
}

export function useDebounce<T = string>(args: Args<T>) {
  const { value, onChange, delay = 500 } = args
  const [text, setText] = useState(value)

  const api = useRef({
    delay,
    text,
    onChange,
  })

  useEffect(() => {
    api.current.text = text
  }, [text])
  useEffect(() => {
    api.current.delay = delay
  }, [delay])
  useEffect(() => {
    api.current.onChange = onChange
  }, [onChange])

  const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)

  useEffect(() => {
    setText(value)
  }, [value])

  const resetTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      api.current.onChange(api.current.text)
    }, api.current.delay)
  }, [])

  useEffect(() => {
    // handle unmount
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])

  const handleOnChange = useCallback(
    (text: T) => {
      setText(text)
      resetTimer()
    },
    [resetTimer],
  )

  return [text, handleOnChange] as const
}
