import type { RadioGroupOption } from '@/formUi'

import { useMemo } from 'react'

import { useTexts } from '../../hooks/useTexts'

export function useDateFormatOptions() {
  const texts = useTexts()

  // this options will never change
  return useMemo<RadioGroupOption<uui.domain.client.rm.NotificationConfig['dateFormat']>[]>(() => {
    return [
      {
        value: 'MMMD',
        label: texts.dateFormatExample('MMMD'),
      },
      {
        value: 'DMMM',
        label: texts.dateFormatExample('DMMM'),
      },
    ]
  }, [texts])
}
