import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useReadOnly } from '@/hooks'
import { selectTerritory } from '@/features/domain/territory'
import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

type ModalState = 'ready' | 'pending'
interface ModalData {
  id: string
  oldName: string
  newName: string
}

export const modalId = 'renameTerritory'

export const useController = () => useModalController<ModalData, ModalState>(modalId)
export const useConfigureController = () => {
  const close = useCloseModal()
  const { readonly } = useReadOnly()
  const { id, name } = useSelector(selectTerritory)

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      invalid: true,
      data: { id, oldName: name, newName: name },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const {
    status,
    update,
    data: { newName, oldName },
  } = ctrl

  // EFFECTS

  // External transaction
  useEffect(() => {
    switch (readonly) {
      case true:
        update({ status: 'pending' })
        break

      case false:
        if (status !== 'pending') return

        update({ status: 'ready' })
        break
    }
  }, [readonly, status, update])

  // Invalid state
  useEffect(() => {
    update({ invalid: newName === oldName || newName.trim() === '' })
  }, [oldName, newName, update])

  return ctrl
}
