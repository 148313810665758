import styled from 'styled-components'

type Props = {
  progress: number
}

export const ProgressBar = styled.div<Props>`
  background-color: ${p => p.theme.colors.$pigmentGreen};
  height: 40px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  transform: scale(${p => (p.progress ? p.progress : 0)}, 1);
  transform-origin: left center;
  transition: transform 1s linear;
  width: 100%;
`

ProgressBar.displayName = 'ProgressBar'
