import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    executionDetails: translate({ id: 'rm.contextual.order.executionDetails' }),
    goToPickup: translate({ id: 'orders.goTo.pickup' }),
    goToDropoff: translate({ id: 'orders.goTo.dropoff' }),
  }))

  return api
}
