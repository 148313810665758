import styled from 'styled-components'

export const FooterContainer = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: start;
  align-items: center;
  font-size: ${p => p.theme.fonts.$p3};
  z-index: 15;
`
FooterContainer.displayName = 'FooterContainer'
