import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    login: {
      logo: translate({ id: 'login-next.login.welcome' }),
      fields: {
        username: {
          info: '',
          label: translate({ id: 'login-next.login.fields.username.label' }),
          required: translate({ id: 'login-next.login.fields.username.validations.required' }),
          placeholder: translate({ id: 'login-next.login.fields.username.placeholder' }),
        },
        password: {
          info: '',
          label: translate({ id: 'login-next.login.fields.password.label' }),
          required: translate({ id: 'login-next.login.fields.password.validations.required' }),
          placeholder: translate({ id: 'login-next.login.fields.password.placeholder' }),
        },
        rememberMe: translate({ id: 'login-next.login.fields.password.remember' }),
      },
      login: {
        label: translate({ id: 'login-next.login.submit.label' }),
        tooltip: translate({ id: 'login-next.login.submit.tooltip' }),
      },
      goToForgotPassword: {
        label: translate({ id: 'login-next.login.gotoResetPassword.label' }),
        tooltip: translate({ id: 'login-next.login.gotoResetPassword.tooltip' }),
      },
    },
    forgotPassword: {
      logo: translate({ id: 'login-next.forgotPassword.welcome' }),
      fields: {
        username: {
          info: '',
          label: translate({ id: 'login-next.login.fields.username.label' }),
          required: translate({ id: 'login-next.login.fields.username.validations.required' }),
          placeholder: translate({ id: 'login-next.login.fields.username.placeholder' }),
        },
      },
      forgot: {
        label: translate({ id: 'login-next.forgotPassword.submit.label' }),
      },
      forgotDescription: translate({ id: 'login-next.forgotPassword.description' }),
      goToLogin: {
        label: translate({ id: 'login-next.forgotPassword.gotoLogin.label' }),
      },
      goToLoginDescription: translate({ id: 'login-next.forgotPassword.gotoLogin.description' }),
    },
  }))

  return api
}
