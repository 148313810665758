import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    copy: translate({
      id: 'orders.trackingLink.copy',
    }),
    copied: translate({
      id: 'orders.trackingLink.copied',
    }),
    open: translate({
      id: 'orders.trackingLink.open',
    }),
  }))

  return api
}
