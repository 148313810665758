import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Note(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 18 18">
        <path d="M12.95 0c-.337 0-.691.198-.956.54L9.013 4.725a5.653 5.653 0 0 0-1.305-.146 5.649 5.649 0 0 0-4.016 1.665.34.34 0 0 0-.102.247c0 .096.034.191.102.259l7.526 7.526c.07.07.155.102.247.102a.375.375 0 0 0 .26-.113 5.667 5.667 0 0 0 1.495-5.389l4.208-2.868c.315-.235.516-.557.54-.878a.792.792 0 0 0-.225-.619L13.513.225A.78.78 0 0 0 12.95 0zM5.92 9.99L.046 17.415a.366.366 0 0 0 .022.484c.07.07.166.101.259.101a.365.365 0 0 0 .225-.079l7.414-5.872L5.919 9.99z" />
      </svg>
    </IconContainer>
  )
}
