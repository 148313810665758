import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { selectDriverAssignmentsWithId } from '@/features/domain/driverAssignments'

export function useDriverAssignmentsRoot() {
  const { editing } = useEditingStateWithCtx('driversAssignment')
  const [selection] = useCrudSelection('driverAssignments')
  const driverAssignmentsWithId = useSelector(selectDriverAssignmentsWithId)
  const selectedDriverAssignmentsWithId = useMemo(
    () => getSelectedDriverAssignmentsWithId(selection, driverAssignmentsWithId),
    [selection, driverAssignmentsWithId],
  )

  return {
    driverAssignments: driverAssignmentsWithId,
    selectedDriverAssignments: selectedDriverAssignmentsWithId,
    editing,
    selection,
  }
}

const getSelectedDriverAssignmentsWithId = (
  selection: workwave.DeepReadonly<string[]>,
  driverAssignmentsWithIdMap: Record<string, uui.domain.client.rm.DriverAssignmentsWithId>,
): uui.domain.client.rm.DriverAssignmentsWithId[] => {
  // selection could hold some ids that are not in the driverAssignmentsMap due to server-data computation
  // driverAssignmentsMap holds the "truth"
  return selection.reduce<uui.domain.client.rm.DriverAssignmentsWithId[]>((acc, id) => {
    if (driverAssignmentsWithIdMap[id]) {
      acc.push(driverAssignmentsWithIdMap[id])
    }
    return acc
  }, [])
}
