import type { DelayedNotificationValues } from './useDelayedNotificationValues'

import { useState, useCallback, useEffect, useRef } from 'react'

import { updateDelayedNotificationConfig } from '@/features/domain/territory'
import { useAppDispatch } from '@/store'

import { convertValues } from '../convertValues'
import { useTexts } from '../useTexts'

/**
 * That hook returns a toggle method for DelayedNotification that will allow the read-only form to
 * enable/disable the notification and an action's description that will be used in confirmation
 * modal
 */
export function useDelayedNotificationToggle(formValues: DelayedNotificationValues) {
  const dispatch = useAppDispatch()
  const values = useRef(formValues)
  const texts = useTexts()

  const [toggleDescription, setToggleDescription] = useState(() =>
    values.current.enabled ? texts.disableDescription : texts.enableDescription,
  )

  useEffect(() => {
    values.current = formValues

    setToggleDescription(
      values.current.enabled ? texts.disableDescription : texts.enableDescription,
    )
  }, [texts, formValues])

  // Toggle method
  const onToggle = useCallback(() => {
    const currentValues = values.current
    const goingToEnable = !currentValues.enabled

    const payload = {
      ...currentValues,
      enabled: goingToEnable,
    }

    dispatch(updateDelayedNotificationConfig(convertValues(payload)))
  }, [dispatch])

  return { onToggle, toggleDescription }
}
