import type { PopoverOrigin, PopoverPosition } from '@mui/material'
import type { OrderStatus } from '../../typings'

import { Popover } from '@mui/material'

import { NoDispatch } from '@/icons'
import { useIsInPopup } from '@/atoms'
import { VerticalLayout } from '@/components/layout'

import { useOrdersStatus } from '../../hooks/useOrdersStatus'
import { useOpenWithDelay } from '../../hooks/useOpenWithDelay'

import { OrderStatusBadge } from '../OrderStatusBadge'

import { useFixPopupPosition } from '../../hooks/useFixPopupPosition'
import { OrderEta } from './components/OrderEta'
import { OrderName } from './components/OrderName'
import { OrderTimeWindow } from './components/OrderTimeWindow'
import { OrderServiceTime } from './components/OrderServiceTime'
import { DriverName } from './components/DriverName'

import { useStyles } from './hooks/useStyles'

interface Props {
  extOrderStep: uui.domain.client.rm.ExtendedOrderStep
  delay?: number
  transformOrigin: PopoverOrigin
  anchorPosition: PopoverPosition
}

export function SingleOrder(props: Props) {
  const { extOrderStep, delay, ...popoverProps } = props

  const orderStatus = useOrdersStatus(extOrderStep) as OrderStatus
  const actionsRef = useFixPopupPosition()
  const isInPopup = useIsInPopup()
  const classes = useStyles()
  const isOpen = useOpenWithDelay(delay)

  const disablePortal = isInPopup || window.innerHeight == window.screen.height

  return (
    <Popover
      open={isOpen}
      action={actionsRef}
      className={classes.popover}
      // Avoid MUI moves the tooltip back to the main window through a portal
      disablePortal={disablePortal}
      anchorReference="anchorPosition"
      {...popoverProps}
    >
      <VerticalLayout
        paddingTop={16}
        paddingBottom={16}
        paddingLeft={16}
        paddingRight={orderStatus === 'notDispatched' ? 32 : 16}
        background="$alabaster"
        className={classes.container}
      >
        {orderStatus === 'notDispatched' && (
          <NoDispatch className={classes.noDispatchIcon} size={10} />
        )}

        <DriverName extOrder={extOrderStep} />

        <OrderName extOrder={extOrderStep} />

        <OrderStatusBadge status={orderStatus} />

        {!extOrderStep.isUnassigned && (
          <OrderEta
            status={orderStatus}
            orderStepId={extOrderStep.id}
            routeId={extOrderStep.routeId}
            stepStartSec={extOrderStep.stepStartSec}
          />
        )}

        {extOrderStep.orderStep.timeWindows.length > 0 && (
          <OrderTimeWindow
            timeWindows={extOrderStep.orderStep.timeWindows}
            violations={!extOrderStep.isUnassigned ? extOrderStep.violations : undefined}
          />
        )}

        <OrderServiceTime extOrderStep={extOrderStep} />
      </VerticalLayout>
    </Popover>
  )
}
