import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

type PinFeatureMetadata = {
  type: 'pin'
  radius: number
  kind: 'inspectPin' | 'locationPin'
  info: uui.domain.ui.map.LocationPinGeofenceInfo | uui.domain.ui.map.LocationPinGenericInfo
}

export const setPinFeatureMetadata = <Field extends keyof PinFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
  value: PinFeatureMetadata[Field],
) => {
  target.set(field, value)
}

export const getPinFeatureMetadata = <Field extends keyof PinFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
): PinFeatureMetadata[Field] | undefined => {
  return target.get(field)
}

export const removePinFeatureMetadata = <Field extends keyof PinFeatureMetadata>(
  target: Feature<Geometry>,
  field: Field,
) => {
  target.unset(field)
}
