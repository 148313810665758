import type { DropDownOption, Props, DropDownItem, WithIndexReference } from './typings'

import { naturalSort } from '@/server-data'

export function isValidOption(option: DropDownOption): boolean {
  return option.kind === 'item' && !option.disabled
}

export function isValidHeadOption(option: DropDownOption): boolean {
  return option.kind === 'item'
}

function isInCollection(item: DropDownItem, options: DropDownOption[]): boolean {
  return !!options.find(opt => opt.id === item.id)
}

export function getNearestValidItemIndex(
  options: DropDownOption[],
  currentIndex: number,
  forward: boolean,
): number {
  if (options.length === 1) {
    return isValidOption(options[0]) ? 0 : -1
  }

  const start: number = forward ? Math.max(currentIndex + 1, 0) : 0
  const end: number = forward ? options.length : currentIndex

  let items = options.slice(start, end)

  if (!forward) {
    items = items.reverse()
  }

  const found = items.find(isValidOption)

  if (!found) {
    return -1
  }

  return options.findIndex(o => o.id === found.id)
}

export function getDropDownItemForHead(props: Props): DropDownItem {
  const { options, defaultOption } = props

  if (defaultOption) {
    if (!isValidHeadOption(defaultOption)) {
      throw new Error('[Dropdown]: default option is not a valid option')
    }

    if (!isInCollection(defaultOption, options)) {
      throw new Error('[Dropdown]: default option is not in the options list')
    }

    return defaultOption
  }

  const selectedItem = options.find(i => isValidHeadOption(i) && (i as DropDownItem).selected)
  if (selectedItem) {
    return selectedItem as DropDownItem
  }

  const firstAvailableIndex = getNearestValidItemIndex(options, -1, true)

  if (firstAvailableIndex === -1) {
    throw new Error('[Dropdown]: no valid option found in the options list')
  }

  return options[firstAvailableIndex] as DropDownItem
}

export function getSortedList(options: DropDownOption[]): WithIndexReference<DropDownItem>[] {
  const clonedWithIndexRef: WithIndexReference<DropDownOption>[] = options.map((a, indexRef) => ({
    ...a,
    indexRef,
  }))

  return clonedWithIndexRef
    .sort((a, b) => naturalSort(a.label, b.label))
    .filter(isValidOption) as WithIndexReference<DropDownItem>[]
}
