import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { fitMapToOrdersAndDepots, useMap } from '@/map'
import { selectTerritoryId, selectTerritoryCenter } from '@/features/domain/territory'
import { isGpsOnly } from '@/features/domain/user'
import { selectCalendarRange } from '@/features/domain/ui'
import { equalityUtils } from '@/utils'

export function useFitOrdersAndDepotsOnTerritoryAndCalendarChange() {
  const territoryId = useSelector(selectTerritoryId)
  const gpsOnly = useSelector(isGpsOnly)
  const calendarRange = useSelector(selectCalendarRange)
  const territoryCenter = useSelector(selectTerritoryCenter)
  const [{ status }] = useMap()

  const isTerritoryCenterEqualRef = useRef(equalityUtils.createIsValueEqual(territoryCenter))
  const isCalendarRangeEqualRef = useRef(equalityUtils.createIsValueEqual(calendarRange))

  const rMapMounted = useRef(status)
  useEffect(() => {
    rMapMounted.current = status
  }, [status])

  useEffect(() => {
    // If the user is a gpsOnly we can bail-out
    if (gpsOnly) return
    if (
      isTerritoryCenterEqualRef.current(territoryCenter) &&
      isCalendarRangeEqualRef.current(calendarRange)
    )
      return

    let timer: ReturnType<typeof setInterval> | null = null

    function fitOrdersAndDepotsOnTerritoryAndCalendarChange() {
      if (rMapMounted.current === 'unmounted') return

      fitMapToOrdersAndDepots(territoryCenter)

      if (timer) {
        clearTimeout(timer)
      }
    }

    timer = setInterval(fitOrdersAndDepotsOnTerritoryAndCalendarChange, 10)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [territoryId, gpsOnly, calendarRange, territoryCenter])
}
