import { useContext } from 'react'

import { createPortal } from 'react-dom'
import { PopupChildrenQueueContext } from '../context/PopupChildrenQueueContext'

import { PopupContentRoot } from '../context/PopupContentRoot'
import { usePopupRootContainer } from '../hooks/usePopupRootContainer'

/**
 * TODO:
 */
export function PopupWindowRenderer() {
  const popupContainer = usePopupRootContainer()

  const { childrenQueue } = useContext(PopupChildrenQueueContext)
  const lastChild = childrenQueue[childrenQueue.length - 1]

  if (!popupContainer) return null

  // TODO: add a loading screen?

  return createPortal(<PopupContentRoot>{lastChild}</PopupContentRoot>, popupContainer)
}
