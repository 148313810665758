import { VerticalLayout } from '@/components/layout/VerticalLayout'
import { WarningCard } from '@/local/components'
import { WarningTriangle } from '@/icons'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Body() {
  const { status } = useController()
  const texts = useTexts()
  return (
    <VerticalLayout marginTop={32}>
      {status !== 'pending' ? (
        <WarningCard
          title={texts.warningCardTitle}
          description={texts.warningCardDescription}
          preset="warning"
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
        />
      ) : (
        <WarningCard
          title={texts.transactionWarningCardTitle}
          description={texts.transactionWarningCardDescription}
          preset="warning"
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
        />
      )}
    </VerticalLayout>
  )
}
