import type { DevPreferencesKeys } from '@/dev'
import { ChangeEventHandler, useCallback, useState } from 'react'
import {
  getDevPreferences,
  resetDevPreferences,
  updateDevPreference,
  removeDevPreferences,
} from '@/dev'

export function AtomsDevToolsTester() {
  const devPreferences = getDevPreferences()
  const [reloadRequired, setReloadRequired] = useState(false)

  const togglePreference = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    const key = e.currentTarget.dataset.key as DevPreferencesKeys
    updateDevPreference(key, e.currentTarget.checked ? 'enable' : 'disable')
    setReloadRequired(true)
  }, [])

  const resetPreferences = useCallback(() => {
    resetDevPreferences()
    setReloadRequired(true)
  }, [])

  const removePreferences = useCallback(() => {
    removeDevPreferences()
    setReloadRequired(true)
  }, [])

  const reload = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <>
      {reloadRequired && <p>Reload the page to apply the changes</p>}
      <ul>
        <li>
          <label>
            <input
              type="checkbox"
              data-key="mainSelectionDevTools"
              onChange={togglePreference}
              defaultChecked={devPreferences.mainSelectionDevTools === 'enable'}
            />
            Main Selection Devtools
          </label>
        </li>

        <li>
          <label>
            <input
              type="checkbox"
              data-key="crudSelectionDevTools"
              onChange={togglePreference}
              defaultChecked={devPreferences.crudSelectionDevTools === 'enable'}
            />
            Crud Selection Devtools
          </label>
        </li>

        <li>
          <label>
            <input
              type="checkbox"
              data-key="editingStateDevTools"
              onChange={togglePreference}
              defaultChecked={devPreferences.editingStateDevTools === 'enable'}
            />
            Editing State Devtools
          </label>
        </li>
      </ul>
      <div>
        <button onClick={resetPreferences}>Reset dev preferences</button>
        <button onClick={removePreferences}>Remove dev preferences</button>
        <button onClick={reload} disabled={!reloadRequired}>
          Reload
        </button>
      </div>
    </>
  )
}
