import { useCallback } from 'react'
import { ModalFooter, ModalFooterButton } from '@/components/Modal'
import { copyToClipboard } from '@/utils'

import { useNotification } from '@/hooks'
import { useController } from '../../hooks/useController'
import { useTexts } from './useTexts'

export function Footer() {
  const {
    close,
    status,
    data: { phoneNumbers },
  } = useController()
  const texts = useTexts()
  const toast = useNotification()

  const copyPhoneNumbers = useCallback(async () => {
    if (!phoneNumbers) return

    const copied = await copyToClipboard(phoneNumbers.join(' '))
    copied
      ? toast.success(texts.copyPhoneNumbersSuccess, { autoClose: 5000 })
      : toast.error(texts.copyPhoneNumbersError, { autoClose: 5000 })
  }, [toast, texts, phoneNumbers])

  const isLoading = status === 'pending'

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          testid="settings-rm-account-change-password-modal-change-button"
          onClick={copyPhoneNumbers}
          disabled={isLoading}
          variant="contained"
          maxWidth="50%"
        >
          {texts.copyNumbers}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          testid="settings-sms-plane-phone-numbers-modal-cancel-button"
          onClick={close}
          variant="text"
        >
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}
