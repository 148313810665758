import { memo, useCallback } from 'react'
import { Place, AccessTime } from '@mui/icons-material'

import {
  ListItem,
  ListItemContent,
  ListItemRow,
  ListItemTitle,
  ListItemVisibilityToggler,
} from '@/components/List'
import { Depot } from '@/icons'
import { type OnItemClickHandlerParams } from '@/components/StructuredVirtualList/typings'

import { Avatar } from '../../../components/Avatar'

import { useOrderListItem } from './hooks/useOrderListItem'
import { useVisibility } from './hooks/useVisibility'

interface Props {
  selected: boolean
  workingDayStartSec: number
  route?: uui.domain.client.rm.ExtendedRoute
  item: uui.domain.client.rm.ExtendedOrderStep
  userConfiguration: uui.domain.UserConfiguration
  onItemClick: (params: OnItemClickHandlerParams<'orderSteps'>) => void
  onItemDoubleClick?: (item: uui.domain.client.rm.ExtendedOrderStep) => void
}

export const OrderListItem = memo((props: Props) => {
  const {
    item,
    route,
    selected,
    onItemClick,
    userConfiguration,
    onItemDoubleClick,
    workingDayStartSec,
  } = props

  const [visible, toggleVisibility] = useVisibility(item.id)
  const { address, title, typeLabel, etaLabel, timeWindowText } = useOrderListItem(
    item,
    workingDayStartSec,
    userConfiguration,
    route,
  )

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])

  return (
    <ListItem
      selected={selected}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      invisibleOnMap={!visible}
      avatar={<Avatar extendedOrderStep={item} />}
      right={<ListItemVisibilityToggler toggleVisibility={toggleVisibility} visible={visible} />}
    >
      <ListItemTitle rightAdornment={typeLabel}>{title}</ListItemTitle>

      <ListItemRow testId="order-address-row">
        <ListItemContent showTooltip icon={item.atDepot ? <Depot size={10} as="div" /> : <Place />}>
          {address}
        </ListItemContent>
      </ListItemRow>

      <ListItemRow testId="order-eta-row">
        <ListItemContent icon={<AccessTime />}>
          {!!etaLabel && etaLabel !== '-' ? etaLabel : timeWindowText}
        </ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})
OrderListItem.displayName = 'OrderListItem'
