export type BarcodesByStatus = Record<
  uui.domain.client.rm.BarcodePod['barcodeStatus'],
  uui.domain.client.rm.BarcodePod[]
>

export const getBarcodesByStatus = (
  podArray: uui.domain.client.rm.BarcodePod[],
): BarcodesByStatus => {
  const barcodesByStatusInitialValue: BarcodesByStatus = {
    SCANNED: [],
    UNREADABLE: [],
    MISSING_BARCODE: [],
    MISSING_PACKAGE: [],
    UNSCANNED: [],
  }

  if (!podArray || podArray.length === 0) {
    return barcodesByStatusInitialValue
  }

  return podArray.reduce((acc, pod) => {
    acc[pod.barcodeStatus] = [...acc[pod.barcodeStatus], pod]
    return acc
  }, barcodesByStatusInitialValue)
}

export const fromPodBarcodeToPodBarcodeOrUnscanned = (
  podBarcodes: uui.domain.client.rm.BarcodePod[],
  orderStep: uui.domain.client.rm.OrderStep,
): uui.domain.client.rm.BarcodePod[] => {
  const { barcodes: osBarcodes } = orderStep

  const clonePodBarcodes = [...podBarcodes]

  const barcodesInOs = osBarcodes.map(barcode => {
    const podIndex = clonePodBarcodes.findIndex(barcodePod => barcodePod.barcode === barcode)
    if (podIndex >= 0) {
      const result = clonePodBarcodes.splice(podIndex, 1)
      return result[0]
    }
    const unscanned: uui.domain.client.rm.BarcodePod = {
      uuid: 'unscanned',
      sec: -1,
      text: '',
      barcode,
      barcodeStatus: 'UNSCANNED',
      podType: 'barcodes',
      latLng: null,
    }
    return unscanned
  })

  // this case should never happen but it can
  // this one are the barcodes that can be deleted but a pending update
  // from the mobile apps could be queued already when the deletion request resolves
  return [...barcodesInOs, ...clonePodBarcodes]
}

export const getPairedOrderStep = (
  source: uui.domain.client.rm.Order,
  type: uui.domain.client.rm.OrderStepIdentifier,
): uui.domain.client.rm.OrderStep | undefined => {
  let entity: string = ''
  if (type === 'p') {
    entity = 'delivery'
  }
  if (type === 'd') {
    entity = 'pickup'
  }
  if (entity.length === 0) return
  return source[entity]
}
