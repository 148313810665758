import type { CSSProperties } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'
import { UserConsole } from '@/icons'
import { IconButton } from '@/components/primitives/buttons/IconButton'

import { useTexts } from './useTexts'
import { useToken } from './useToken'

const iconButtonStyle: CSSProperties = { height: 36, padding: 8, position: 'relative' }

export function GpsUserConsole() {
  const texts = useTexts()
  const token = useToken()

  return (
    <div>
      <form
        action="https://console.routemanager.workwave.com/accounts/login/sso-jwt"
        method="POST"
        target="_blank"
      >
        <input type="text" hidden name={'Authorization'} value={`Bearer ${token}`} readOnly />
        <Tooltip placement="bottom" title={texts.gpsUserConsole}>
          <IconButton
            type="submit"
            style={iconButtonStyle}
            disabled={!token}
            data-testid="header__gps-user-console-button"
            data-trackid="header__gps-user-console-button"
          >
            <UserConsole size={16} color="$pureWhite" />
          </IconButton>
        </Tooltip>
      </form>
    </div>
  )
}
