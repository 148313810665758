import type { PopoverActions } from '@mui/material'

import { useEffect, useRef } from 'react'
import { useIsInPopup } from '@/atoms'

/**
 * In a popup, the left position is not calculated/applied correctly the first time. By forcing
 * `updatePosition`, we ensure the tooltip is placed at the correct position.
 */
export function useFixPopupPosition() {
  const isInPopup = useIsInPopup()
  const actionsRef = useRef<PopoverActions>(null)

  useEffect(() => {
    if (!isInPopup) return

    requestAnimationFrame(() => {
      actionsRef.current?.updatePosition()
    })
  })

  return actionsRef
}
