import { useSelector } from 'react-redux'
import {
  isGpsOnly,
  hasGps,
  selectUserConfiguration,
  selectIsFilteredGuest,
} from '@/features/domain/user'

export const useMenuConfiguration = () => {
  const gpsOnly = useSelector(isGpsOnly)
  const { planType } = useSelector(selectUserConfiguration)
  const gotGps = useSelector(hasGps)
  const isFilteredGuest = useSelector(selectIsFilteredGuest)

  if (isFilteredGuest) return 'filteredGuest'

  if (planType === 'simulation') return 'simulation'

  return gpsOnly ? 'gpsOnly' : gotGps ? 'rm360' : 'rm'
}
