import styled from 'styled-components'

export const InputRangeIcon = styled.button.attrs(() => ({
  type: 'button',
}))`
  margin: 0;
  padding: 0;
  border-width: 0;
  outline: 0;

  background: transparent;

  flex: 0 0 auto;
  height: 15px;
  display: flex;
  cursor: pointer;

  transition: opacity 0.3s ease;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  > * {
    width: 15px;
  }
`

InputRangeIcon.displayName = 'InputRangeIcon'
