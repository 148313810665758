import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    depotFieldLabel: translate({ id: 'lib.locationEditor.modal.depotFieldLabel' }),
    pickupFieldLabel: translate({ id: 'lib.locationEditor.modal.pickupFieldLabel' }),
    serviceFieldLabel: translate({ id: 'lib.locationEditor.modal.serviceFieldLabel' }),
    deliveryFieldLabel: translate({ id: 'lib.locationEditor.modal.deliveryFieldLabel' }),

    indicatesAddress: translate({ id: 'lib.locationEditor.modal.indicatesAddress' }),
    differentAddress: translate({ id: 'lib.locationEditor.modal.differentAddress' }),
    confirmChanges: translate({ id: 'lib.locationEditor.modal.footer.confirmChanges' }),
    useThisAddress: translate({ id: 'lib.locationEditor.modal.useThisAddress' }),
    nameImpact: translate({ id: 'lib.locationEditor.modal.nameImpact' }),
    outOfRange: (value: string, unit: string) =>
      translate({ id: 'lib.locationEditor.modal.outOfRange', values: { value, unit } }),
    cancel: translate({ id: 'lib.locationEditor.modal.footer.cancel' }),
    reset: translate({ id: 'lib.locationEditor.modal.footer.reset' }),
    meters: translate({ id: 'global.unitOfMeasure.meters' }),
    feets: translate({ id: 'global.unitsOfMeasure.feets' }),
  }))

  return api
}
