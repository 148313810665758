import { useSettingsEditing } from '../../hooks/useSettingsEditing'

import { useRefreshGpsAccountData } from '../gpsAccount/hooks/useRefreshGpsAccountData'
import { GpsFormatsView } from './GpsFormatsView'
import { GpsFormatsForm } from './GpsFormatsForm'

export function GpsFormats() {
  const { editing, onEdit, onDiscard } = useSettingsEditing('gps-formats', 'reset')

  useRefreshGpsAccountData()

  return (
    <>
      {!editing && <GpsFormatsView onEdit={onEdit} />}
      {editing && <GpsFormatsForm onDiscard={onDiscard} />}
    </>
  )
}
