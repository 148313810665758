import { NotificationEditorField } from '@/forms-legacy'

import { useDefaultEmailAddress } from './hooks/useDefaultEmailAddress'
import { useTexts } from './useTexts'

export function NotificationEditor() {
  const defaultEmailAddress = useDefaultEmailAddress()
  const texts = useTexts()

  return (
    <NotificationEditorField
      name="notificationAddresses"
      label={texts.alertContacts}
      wrappedInputProps={{
        renderText: texts.notificationEditorRenderText,
        defaultEmailAddress,
        allowSms: false,
      }}
    />
  )
}
