import styled from 'styled-components'

type Props = {
  padding?: string
}

const styledComponent = styled.div<Props>`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 70px;
  background: ${p => p.theme.colors.$whiteSmoke};
  padding: ${p => p.padding || '4px 16px'};
  margin-bottom: 8px;
  justify-content: center;

  > * + * {
    margin-top: 20px;
  }
`

styledComponent.displayName = 'ColumnHeader'
export default styledComponent
