import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { RejectError } from '../../typings'

interface Payload {
  method: string
  payload: any
  useCurrentTxnId: boolean
}

export const requestMaker = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/demoTools/requestMaker', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/demoTools/requestMaker', {
      category: 'rpc',
      type: 'rpc/demoTools/requestMaker',
      payload,
    })

    return result
  } catch (error) {
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Error while making request',
      error,
    })
  }
})
