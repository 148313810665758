import type { AlertFormValues } from '../types'

import { useState, useCallback } from 'react'

import { createAlert, updateAlert } from '@/features/domain/alert'

import { setCrudSelection, useListApi, useResetEditingState } from '@/atoms'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { parseAlertFromFormValues } from '../utils/parseAlertFormValues'

export const useOnSubmit = () => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { scrollTo } = useListApi('eventAlerts')
  const stopEditing = useResetEditingState()
  const isUnmounted = useIsUnmounted()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const onCreate = useCallback(
    async (formValues: AlertFormValues) => {
      try {
        setSubmitting(true)

        const alert = parseAlertFromFormValues(formValues)
        const thunkResult = await dispatch(createAlert(alert))

        if (!isUnmounted()) {
          setSubmitting(false)
        }

        if (createAlert.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        if (!isUnmounted()) {
          stopEditing()
        }

        setCrudSelection('alerts', [thunkResult.payload])

        scrollTo(thunkResult.payload)
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, isUnmounted, stopEditing, scrollTo, toast],
  )

  const onUpdate = useCallback(
    async (formValues: AlertFormValues) => {
      try {
        setSubmitting(true)

        const alert = parseAlertFromFormValues(formValues)
        const thunkResult = await dispatch(updateAlert(alert))

        if (!isUnmounted()) {
          setSubmitting(false)
        }

        if (updateAlert.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        if (!isUnmounted()) {
          stopEditing()
        }
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, isUnmounted, stopEditing, toast],
  )

  return {
    submitting,
    onCreate,
    onUpdate,
  }
}
