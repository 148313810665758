import styled from 'styled-components'

const RoundBtn = styled.button`
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: 0;
  border-width: 0;

  flex: 0 0 auto;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;

  border-radius: 50%;

  color: ${p => p.theme.colors.$pureWhite};
  background: ${p => p.theme.colors.$outrageousRed};
`

RoundBtn.displayName = 'RoundBtn'
export default RoundBtn
