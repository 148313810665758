import type { BaseProps as Props } from './typings'

import styled from 'styled-components'

import { InvisibleButton } from '@/local/components'

export const DropdownHeadContainer = styled(InvisibleButton)<Props>`
  position: relative;
  display: block;
  width: 100%;
  color: ${p => (p.disabled ? p.theme.colors.$silver : p.theme.colors.$nightRider)};
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  background: ${p => p.theme.colors.$alabaster};
  padding: 0 12px;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  text-align: left;

  &:hover {
    border-color: ${p => (p.disabled ? 'transparent' : p.theme.colors.$whiteSmoke)};
  }

  &:focus {
    border-color: ${p => (p.disabled ? 'transparent' : p.theme.colors.$scienceBlue)};
  }
`

DropdownHeadContainer.displayName = 'DropdownHeadContainer'
