import type Geometry from 'ol/geom/Geometry'
import type Feature from 'ol/Feature'

import { mapAtom } from '../../../atoms/map/map'
import { findLayer } from '../../utils/findLayer'

import { createLocationPinFeature } from './utils/createLocationPinFeature'

export function addLocationPinFeature(pin: uui.domain.ui.map.LocationPin): Feature<Geometry> {
  const map = mapAtom.map
  const layer = findLayer(map, 'pin')

  // create the pin
  const feature = createLocationPinFeature(pin)
  layer.getSource()?.addFeature(feature)

  return feature
}
