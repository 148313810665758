import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    day: translate({ id: 'global.day' }),
    days: translate({ id: 'global.days' }),
  }))

  return api
}
