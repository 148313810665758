import type { RenderItemProps } from '@/components/StructuredVirtualList'

import { memo, useCallback } from 'react'

import {
  ListItemVisibilityToggler,
  ListItemAvatarRounded,
  ListItemContent,
  ListItemTitle,
  ListItemRow,
  ListItem,
} from '@/components/List'

import { useSegmentType } from '../../../hooks/useSegmentType'

import { useVisibility } from '../hooks/useVisibility'

import { RoutingSegmentAvatar } from './components/RouteSegmentAvatar'
import { useTexts } from './hooks/useTexts'

type Props = Extract<RenderItemProps<'roadSegments'>, { type: 'item' }>

export const RoadSegmentItem = memo((props: Props) => {
  const { selected, item, onItemClick, onItemDoubleClick } = props

  const [visible, toggleVisibility] = useVisibility(item.id)
  const segmentType = useSegmentType(item)
  const texts = useTexts()

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])

  return (
    <ListItem
      invisibleOnMap={!visible}
      onDoubleClick={onDoubleClick}
      selected={selected}
      onClick={onClick}
      avatar={
        <ListItemAvatarRounded color="transparent">
          <RoutingSegmentAvatar segment={item} />
        </ListItemAvatarRounded>
      }
      testId="road-segment-list-item"
      right={<ListItemVisibilityToggler toggleVisibility={toggleVisibility} visible={visible} />}
    >
      <ListItemTitle>{item.name}</ListItemTitle>

      <ListItemRow testId="road-editor-segment-description-row">
        <ListItemContent>{texts.segmentDescription(segmentType)}</ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})

RoadSegmentItem.displayName = 'RoadSegmentItem'
