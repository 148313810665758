import type {
  TerritoryConfig as TerritoryConfigType,
  TerritoryConfigError,
} from '../../../../../../../../types'

import { Stack } from '@mui/material'
import { MultiSelect } from '@workwave-tidal/tidal/components'

import { RadioButtons } from '../RadioButtons'

import { useTexts } from './hooks/useTexts'
import { useAutocompleteProps } from './hooks/useAutocompleteProps'
import { useDriversRestriction } from './hooks/useDriversRestriction'

interface Props {
  territoryId: string
  fieldValue: TerritoryConfigType
  error?: TerritoryConfigError
  onChange: (value: TerritoryConfigType) => void
  territoriesConfig: uui.domain.client.rm.TerritoriesDriversAndVehicles
}

export function DriversRestriction(props: Props) {
  const { territoryId, fieldValue, onChange, territoriesConfig, error } = props

  const texts = useTexts()

  const { value: radioButtonsValue, onChange: radioButtonsOnChange } = useDriversRestriction(
    fieldValue,
    onChange,
  )

  const {
    items,
    value,
    onChange: onValueChange,
    getOptionLabel,
    autocompleteProps,
  } = useAutocompleteProps(territoryId, fieldValue, onChange, territoriesConfig)

  const errorText = error?.drivers

  return (
    <Stack spacing={1}>
      <RadioButtons
        id={`${territoryId}-drivers-radio`}
        title={texts.title}
        value={radioButtonsValue}
        onChange={radioButtonsOnChange}
      />

      {radioButtonsValue === 'restricted' && (
        <MultiSelect
          items={items}
          value={value}
          label={texts.fieldLabel}
          error={!!errorText}
          testId="driver-access-selector"
          getLabel={getOptionLabel}
          onChange={onValueChange}
          inputMode="normal"
          optionMode="normal"
          placeholder={texts.placeholder}
          autocompleteProps={autocompleteProps}
          maxSelectedItemsToShow={2}
          maxSelectedItemsToShowOnFocus={2}
        />
      )}
    </Stack>
  )
}
