import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

/**
 * Retrieve cookie content that should be used to start the import wizard
 * and starts a pending transaction
 *
 * @private
 */
export const startImportWizard = createAsyncThunk<
  // Return type of the payload creator
  string | false,
  // First argument to the payload creator
  string,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('transaction/startImportWizard', async (initiatorUrl, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/transaction/startImportWizard', {
      category: 'rpc',
      type: 'rpc/transaction/startImportWizard',
      initiatorUrl,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }

    return result.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {getPlace}`,
      { tags: ['rpc', 'ui'], info: initiatorUrl },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Get place failed`,
      error,
    })
  }
})
