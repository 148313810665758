import { Alert, AlertTitle, Box, Button } from '@mui/material'
import { useTexts } from './useTexts'

type Props = {
  type: 'off' | 'suggest' | 'require'
}

export function DriverAssistanceFormAlert(props: Props) {
  const { type } = props
  const texts = useTexts()
  const severity = type === 'require' ? 'warning' : 'info'

  return (
    <Alert severity={severity} data-testid="driver-assistance-form-alert">
      <AlertTitle>
        <b>{texts[type].title}</b>
      </AlertTitle>
      {texts[type].description}
      <Box>
        <Button
          href="https://workwave.my.site.com/routemanager/s/article/Enhanced-Required-Actions-for-Drivers"
          target="_blank"
        >
          {texts.action}
        </Button>
      </Box>
    </Alert>
  )
}
