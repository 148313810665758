import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    cancelButtonTitle: translate({ id: 'modals.exportOrders.button.cancel' }),

    exportButtonTitleSingle: translate({ id: 'modals.exportOrders.button.export.single' }),
    exportButtonTitleBulk: (count: number) =>
      translate({ id: 'modals.exportOrders.button.export.bulk', values: { count } }),

    genericExportOrdersError: translate({ id: 'modals.exportOrders.error.generic' }),
    includeBarcode: translate({ id: 'modals.exportOrders.labels.includeBarcode' }),

    invalidDataEmptyTitle: (count: number) =>
      translate({ id: 'modals.exportOrders.invalid.emptyOrders.title', values: { count } }),
    invalidDataEmptyDesc: (count: number) =>
      translate({ id: 'modals.exportOrders.invalid.emptyOrders.description', values: { count } }),
    modalTitle: (count: number) =>
      translate({ id: 'modals.exportOrders.header.title', values: { count } }),
    modalSubtitle: translate({ id: 'modals.exportOrders.header.subtitle' }),
    warningCardExportWithBarcodeTitle: translate({
      id: 'modals.exportOrders.warningCard.barcode.on.title',
    }),
    warningCardExportWithoutBarcodeTitle: translate({
      id: 'modals.exportOrders.warningCard.barcode.off.title',
    }),
    warningCardExportWithBarcodeDescription: translate({
      id: 'modals.exportOrders.warningCard.barcode.on.description',
    }),
    warningCardExportWithoutBarcodeDescription: translate({
      id: 'modals.exportOrders.warningCard.barcode.off.description',
    }),

    format: translate({ id: 'modals.exportOrders.labels.format' }),
    formatXLSX: translate({ id: 'modals.exportOrders.exportFormat.xlsx' }),
    formatCSV: translate({ id: 'modals.exportOrders.exportFormat.csv' }),

    extraColumn: translate({ id: 'modals.exportOrders.labels.extraColumn' }),
    extraColumnDescription: translate({ id: 'modals.exportOrders.labels.extraColumn.description' }),
    dismiss: translate({ id: 'modals.exportOrders.button.dismiss' }),
    exportButtonWarning: (count: number) =>
      translate({
        id: 'modals.exportOrders.button.warning',
        values: { count },
      }),

    advanced: {
      title: translate({ id: 'modals.exportOrders.advanced.title' }),
      keepEnglishColumns: translate({ id: 'modals.exportOrders.advanced.keepEnglishColumns' }),
      keepEnglishColumnsDescription: translate({
        id: 'modals.exportOrders.advanced.keepEnglishColumns.description',
      }),
    },

    nextStepButton: translate({ id: 'modals.exportOrders.nextStepButton' }),
    prevStepButton: translate({ id: 'modals.exportOrders.prevStepButton' }),
    preparingExport: translate({ id: 'modals.exportOrders.preparingExport' }),
    emptySelectionWarning: translate({ id: 'modals.exportOrders.emptySelectionWarning' }),

    customizeColumns: translate({ id: 'modals.exportOrders.customizeColumns' }),
    customizeColumnsDescription: translate({
      id: 'modals.exportOrders.customizeColumns.description',
    }),

    advancedDisabledTooltip: translate({ id: 'modals.exportOrders.advanced.disabled.tooltip' }),
  }))

  return api
}
