import styled from 'styled-components'
import { WithTheme } from '../../theme'
import { parseSizeValue } from '../utils/parseSizeValue'
import { Props } from './typings'
type ThemedProps = WithTheme<Props>

const getColor = ({ color, theme }: ThemedProps): string => {
  return !!color ? theme.colors[color] : 'currentColor'
}
const getFontSize = ({ size, theme }: ThemedProps): string => {
  return !!size ? theme.fonts[size] : 'inherit'
}
const getFontWeight = ({ weight, theme }: ThemedProps): number | string => {
  return !!weight ? theme.weights[weight] : 'inherit'
}
const getItalic = ({ italic }: ThemedProps): string => {
  return !!italic ? 'italic' : 'normal'
}
const getEllipsis = ({ ellipsis }: ThemedProps): string => {
  return !!ellipsis
    ? `
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;`
    : ''
}
const getUppercase = ({ uppercase }: ThemedProps): string => {
  return !!uppercase ? 'uppercase' : 'none'
}
const getUnderline = ({ underline }: ThemedProps): string => {
  return !!underline ? 'underline' : 'none'
}
const getMarginTop = ({ marginTop }: Props) => {
  if (marginTop) {
    return parseSizeValue(marginTop)
  }
  return 0
}
const getMarginBottom = ({ marginBottom }: Props) => {
  if (marginBottom) {
    return parseSizeValue(marginBottom)
  }
  return 0
}
const getMarginLeft = ({ marginLeft, gutter }: Props) => {
  if (marginLeft) {
    return parseSizeValue(marginLeft)
  }
  if (gutter) {
    return parseSizeValue(gutter)
  }
  return 0
}
const getMarginRight = ({ marginRight, gutter }: Props) => {
  if (marginRight) {
    return parseSizeValue(marginRight)
  }
  if (gutter) {
    return parseSizeValue(gutter)
  }
  return 0
}
const getDisplay = ({ as }: Props) => {
  if (as && as === 'span') {
    return 'inline-block'
  }
  return 'block'
}
const getTint = ({ tint, theme }: ThemedProps): string => {
  const value = tint ? theme.colors[tint] : undefined
  return value ? value : 'transparent'
}
const p = styled.p<Props>``
const TextPrimitive = styled(p).attrs<Props>(() => ({
  className: 'o-atom-text',
}))`
  display: ${getDisplay};
  overflow: visible;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: ${getTint};
  font: inherit;
  line-height: 1.2em;
  user-select: none;
  color: ${getColor};
  font-size: ${getFontSize};
  font-weight: ${getFontWeight};
  font-style: ${getItalic};
  ${getEllipsis};
  text-transform: ${getUppercase};
  text-decoration: ${getUnderline};
  margin-top: ${getMarginTop};
  margin-bottom: ${getMarginBottom};
  margin-left: ${getMarginLeft};
  margin-right: ${getMarginRight};
  text-align: ${p => p.textAlign || 'inherit'};
  &:disabled {
    opacity: 0.6;
  }
`

export function Text(props: Props) {
  const { children = '', as = 'p', className = '', style, testid, ...p } = props
  return (
    <TextPrimitive
      {...p}
      as={as}
      style={style}
      className={className}
      data-testid={testid}
      data-trackid={testid}
    >
      {children}
    </TextPrimitive>
  )
}
