import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()
  const [api] = useState(() => ({
    warningSimulationReplace: translate({
      id: 'copySelectedOrdersModal.copyMode.warning.simulation.replace.description',
    }),
    warningSimulationAdd: translate({
      id: 'copySelectedOrdersModal.copyMode.warning.simulation.add.description',
    }),
    numberOfOrders: (count: number) =>
      translate({ id: 'territoryCalendar.ordersTooltip', values: { count } }),
    title: (count: number, mode: 'partial' | 'full') => {
      if (mode === 'full')
        return translate({ id: 'copySelectedOrdersModal.title', values: { count } })

      return translate({ id: 'copySelectedOrdersModal.title.orders', values: { count } })
    },

    areYouSure: translate({ id: 'copySelectedOrdersModal.copyMode.warning.title' }),
    replaceOrders: translate({ id: 'copySelectedOrdersModal.footer.replace' }),
    replace: translate({ id: 'copySelectedOrdersModal.copyMode.replace' }),
    cancel: translate({ id: 'copySelectedOrdersModal.footer.cancel' }),
    addOrders: translate({ id: 'copySelectedOrdersModal.footer.add' }),
    subtitle: translate({ id: 'copySelectedOrdersModal.subtitle' }),
    add: translate({ id: 'copySelectedOrdersModal.copyMode.add' }),
    mode: translate({ id: 'copySelectedOrdersModal.copyMode' }),
  }))

  return api
}
