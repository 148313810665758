import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    edit: translate({ id: 'global.edit' }),
    editAll: translate({ id: 'global.editAll' }),
    newOrder: translate({ id: 'rm.contextual.place.newOrder' }),
    newGeofence: translate({ id: 'rm.contextual.place.newGeofence' }),
    sendToDriver: translate({ id: 'rm.contextual.place.sendToDriver' }),
  }))

  return api
}
