import type { ListIconPreset } from './typings'

import { useCallback } from 'react'

import { DeviceIconButton } from './elements/DeviceIconButton'

interface Props {
  setMode: (mode: 'open' | 'closed') => void
  activePreset: ListIconPreset
  closedText: string
}

export function ClosedList(props: Props) {
  const { setMode, activePreset, closedText } = props
  const onClick = useCallback(() => {
    setMode('open')
  }, [setMode])

  return <DeviceIconButton onClick={onClick} label={closedText} preset={activePreset} />
}
