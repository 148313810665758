import { Link } from '@/routing'

import { DriverAssignment, DriverAlt, Depot, Car, Timezone } from '@/icons'

import { useTexts } from '../../useTexts'
import { Separator } from '../Separator'
import { MainLink } from '../MainLink'

import { DriverMobileApp } from './components/DriverMobileApp'
import { Communication } from './components/Communication'
import { Advanced } from './components/Advanced'

import { useCanShow } from './hooks/useCanShow'

type SetupRoutes = uui.routing.Setup

export function RmSetupSidebar() {
  const canShow = useCanShow()
  const texts = useTexts()

  return (
    <>
      {canShow.depots && (
        <Link<SetupRoutes> to="depots">
          <MainLink text={texts.depots} Icon={<Depot size={18} />} testid="setup__depots-button" />
        </Link>
      )}

      {canShow.vehicles && (
        <Link<SetupRoutes> to="vehicles">
          <MainLink
            text={texts.vehicles}
            Icon={<Car size={18} />}
            testid="setup__vehicles-button"
          />
        </Link>
      )}

      {canShow.drivers && (
        <Link<SetupRoutes> to="drivers">
          <MainLink
            text={texts.drivers}
            Icon={<DriverAlt size={18} />}
            testid="setup__drivers-button"
          />
        </Link>
      )}

      {canShow.driverAssignments && (
        <Link<SetupRoutes> to="driver-assignments">
          <MainLink
            text={texts.driverAssignment}
            Icon={<DriverAssignment size={18} />}
            testid="setup__driver-assignments-button"
          />
        </Link>
      )}

      <Separator />
      <Advanced />

      {canShow.communication && (
        <>
          {/* TODO: move this separator upward when other sections are added below */}
          <Separator />
          <Communication />
        </>
      )}

      {canShow.driverMobileApp && <DriverMobileApp />}

      {canShow.territory && (
        <Link<SetupRoutes> to="territory">
          <MainLink
            text={texts.territory}
            Icon={<Timezone size={18} />}
            testid="setup__territory-button"
          />
        </Link>
      )}
    </>
  )
}
