import { useCallback, useState } from 'react'

export function useDialogState() {
  const [open, setOpen] = useState(false)

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  return {
    open,
    onOpen,
    onClose,
  }
}
