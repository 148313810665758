import type { NotificationEditorTypes } from '@/forms-legacy'

import { useCallback } from 'react'

import { useIntl } from '@/intl'

export function useCreateNotificationEditorRenderText() {
  const { translate } = useIntl()

  return useCallback(
    (key: NotificationEditorTypes.NotificationEditorText) => {
      switch (key) {
        case 'emailButton':
          return translate({
            id: 'components.notificationEditor.emailButton',
          })

        case 'smsButton':
          return translate({
            id: 'components.notificationEditor.smsButton',
          })

        case 'invalidEmailMessage':
          return translate({
            id: 'components.notificationEditor.invalidEmailMessage',
          })

        case 'invalidSmsMessage':
          return translate({
            id: 'components.notificationEditor.invalidSmsMessage',
          })

        case 'duplicatedRecipientMessage':
          return translate({
            id: 'components.notificationEditor.duplicatedRecipientMessage',
          })

        case 'addRecipientButton':
          return translate({
            id: 'components.notificationEditor.addRecipientButton',
          })

        case 'discardRecipientButton':
          return translate({
            id: 'components.notificationEditor.discardRecipientButton',
          })

        case 'addContactButton':
          return translate({
            id: 'components.notificationEditor.addContactButton',
          })

        case 'emailTypeLabel':
          return translate({
            id: 'components.notificationEditor.emailTypeLabel',
          })

        case 'smsTypeLabel':
          return translate({
            id: 'components.notificationEditor.smsTypeLabel',
          })

        case 'addRecipientInputEmailPlaceholder':
          return translate({
            id: 'components.notificationEditor.addRecipientInputEmailPlaceholder',
          })

        case 'addRecipientInputSmsPlaceholder':
          return translate({
            id: 'components.notificationEditor.addRecipientInputSmsPlaceholder',
          })

        case 'addRecipientInputMessage':
          return translate({
            id: 'components.notificationEditor.addRecipientInputMessage',
          })

        default:
          return key
      }
    },
    [translate],
  )
}
