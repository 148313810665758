import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useNotification, useIsUnmounted } from '@/hooks'
import { deleteTerritory } from '@/features/domain/territory'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export function useControllerActions() {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const {
    close,
    data: { id },
  } = useController()
  const isUnmounted = useIsUnmounted()

  const onDeleteTerritory = useCallback(async () => {
    if (!id) return

    try {
      const result = await dispatch(deleteTerritory({ territoryId: id }))

      if (!deleteTerritory.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      if (isUnmounted()) return

      close?.()
    } catch (e) {
      toast.error(texts.genericError)
    }
  }, [id, texts, toast, close, dispatch, isUnmounted])

  return {
    onDeleteTerritory,
  }
}
