import type { DepotValidation, DepotFormValues } from '../typings'

import { locationHasError } from '@/server-data'

import { useTexts } from './useTexts'

const validateForm =
  (texts: ReturnType<typeof useTexts>) =>
  (formValues: DepotFormValues): DepotValidation => {
    const { name, setupCost, setupTime, location } = formValues

    const validation: DepotValidation = {}

    if (!name) {
      validation.name = texts.errorName
    }

    if (setupCost && isNaN(setupCost)) {
      validation.setupCost = texts.errorSetupCost
    }

    if (setupTime && isNaN(setupTime)) {
      validation.setupCost = texts.errorSetupTime
    }

    if (!location || locationHasError(location)) {
      validation.location = texts.errorLocation
    }

    return validation
  }

export const useValidateForm = () => {
  const texts = useTexts()
  return validateForm(texts)
}
