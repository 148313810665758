import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectRoutesInfoInRange } from '@/features/domain/scheduler'

/**
 * Return all the showed route ids and the unavailable routes ids.
 */
export function useRouteStats() {
  const routesInfo = useSelector(selectRoutesInfoInRange)

  return useMemo(() => {
    const routeIds = Object.keys(routesInfo)
    const unavailableRouteIds = routeIds.filter(id => routesInfo[id].type === 'routeUnavailable')

    return { routeIds, unavailableRouteIds }
  }, [routesInfo])
}
