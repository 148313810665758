import { PureComponent, ComponentType } from 'react'

import { Search } from '@/icons'

import GenericDropdown, { DropdownItem } from '../../../GenericDropdown'

import Block from '../edit/Block'
import Label from '../edit/Label'
import FieldMessage from '../edit/FieldMessage'

import RestoreFieldButton from '../primitives/RestoreFieldButton'
import Checkbox from '../primitives/Checkbox'
import CheckboxContainer from './CheckboxContainer'

import { Props } from './typings'

interface State {
  selectedIds: string[]
}

const setSelectedIds = (value?: string) => () => ({
  selectedIds: value ? [value] : [],
})

const defaultDisabledState: ComponentType<Record<string, unknown>> = () => null

export default class DropDownField extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    const {
      formProps: {
        input: { value },
      },
    } = props

    const valueAsString = value as string

    this.state = {
      selectedIds: valueAsString ? [valueAsString] : [],
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      formProps: {
        input: { value: prevValue },
      },
    } = prevProps

    const {
      formProps: {
        input: { value },
      },
    } = this.props

    if (prevValue !== value) {
      this.setState(setSelectedIds(value))
    }
  }

  private handleOnChange = (items: DropdownItem[]) => {
    const {
      formProps: {
        input: { onChange },
      },
    } = this.props

    if (items.length > 0) {
      const { id } = items[0]
      onChange(id)
    }
  }

  private handleOnChangeCheckBox = (checked: boolean) => {
    const {
      values,
      preferredValue,
      formProps: {
        input: { onChange },
      },
      disabledValue,
    } = this.props

    if (!disabledValue) {
      throw new Error('disabled values must be provided')
    }

    if (checked) {
      if (preferredValue) {
        onChange(preferredValue)
      } else {
        const firstNotDisabled = values.find((item: DropdownItem) => !item.disabled)
        if (!firstNotDisabled) {
          throw new Error('cannot find any values')
        }
        onChange(firstNotDisabled.id)
      }
    } else {
      onChange(disabledValue)
    }
  }

  render() {
    const { selectedIds } = this.state

    const {
      values,
      label,
      name,
      info,
      wrappedInputProps = {},
      formProps: {
        input: { onChange, value },
        meta: { error, dirty, initial },
      },
      disabledValue,
      DisabledState = defaultDisabledState,
      labelClassName = '',
      testid,
    } = this.props

    const canBeDisabled: boolean = !!disabledValue
    const isDisabled: boolean = canBeDisabled && value === disabledValue

    const allValuesDisabled: boolean = values.every(
      ({ disabled = false }: DropdownItem) => disabled,
    )

    return (
      <Block error={!!error} dirty={!!dirty} data-testid={testid} data-trackid={testid}>
        <Label htmlFor={name} className={labelClassName}>
          {canBeDisabled && (
            <CheckboxContainer>
              <Checkbox
                id={`${name}__disabled__`}
                checked={!isDisabled}
                onChange={this.handleOnChangeCheckBox}
                disabled={allValuesDisabled}
                space={0}
              />
            </CheckboxContainer>
          )}
          {label}
          <RestoreFieldButton
            dirty={dirty}
            initial={initial}
            onChange={onChange}
            style={{ marginLeft: 12 }}
          />
        </Label>

        {isDisabled ? (
          <DisabledState />
        ) : (
          <GenericDropdown
            className="o-dropdown-field"
            // ----------------
            // default values
            // ----------------
            editable
            showResetIcon
            openWithArrows
            defaultToFirstItem
            extraIcon={<Search />}
            placeholder=""
            // ----------------
            // user provided values
            // ----------------
            {...wrappedInputProps}
            // ----------------
            // hard coded values
            // ----------------
            onChanged={this.handleOnChange}
            selectedIds={selectedIds}
            values={values}
          />
        )}

        {!!error && <FieldMessage error>{error}</FieldMessage>}
        {!error && !!info && <FieldMessage>{info}</FieldMessage>}
      </Block>
    )
  }
}
