export function computeMatches(text: string, regex: RegExp) {
  let matches = regex.exec(text)
  const result: string[] = []

  while (matches !== null) {
    result.push(matches[1])
    matches = regex.exec(text)
  }

  return result
}
