import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    close: translate({ id: 'global.close' }),
    edit: translate({ id: 'global.edit' }),
    delete: translate({ id: 'global.delete' }),
    more: translate({ id: 'global.more' }),
  }))

  return api
}
