import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehiclesByDeviceId } from '@/features/domain/vehicle'
import { useMainSelection } from '@/atoms'

export function useListSelection() {
  const vehiclesByDeviceId = useSelector(selectVehiclesByDeviceId)
  const [vehicleSelection] = useMainSelection('unifiedVehicles')
  const [deviceSelection] = useMainSelection('devices')

  return useMemo(() => {
    const selection = new Set<string>(vehicleSelection)

    for (const deviceId of deviceSelection) {
      if (vehiclesByDeviceId[deviceId]) {
        selection.add(vehiclesByDeviceId[deviceId])
      }
    }

    return [...selection]
  }, [vehiclesByDeviceId, deviceSelection, vehicleSelection])
}
