export function computeBreadcrumbsPermission(
  vehiclesCount: number,
  isLoadingGpsData: boolean,
  deviceIds?: string[],
) {
  if (isLoadingGpsData) return 'disabledLoadingGpsData'
  if (!deviceIds || deviceIds.length === 0) return 'disabledNoDevice'
  if (vehiclesCount !== 1) return 'disabledMultipleVehicles'

  return 'enabled'
}
