import type { PermissionData } from '../types'

export function computeExportToEmailPermission(permissionData: PermissionData) {
  if (permissionData.isSimulation) return 'hidden'

  if (permissionData.approvedRoutesCount === 0 || permissionData.driversCount === 0) {
    return 'disabled'
  }

  return 'enabled'
}
