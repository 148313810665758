import { ConfirmPanel as ConfirmPanelComp } from '@/forms-legacy'

import { useTexts } from './hooks/useTexts'
import { useActions } from './hooks/useActions'

interface Props {
  place: uui.domain.client.gps.wwgps.Place
  setView: (view: 'default' | 'delete') => void
}

export function ConfirmPanel(props: Props) {
  const { setView, place } = props
  const actions = useActions(place, setView)

  const texts = useTexts()

  return (
    <ConfirmPanelComp
      title={texts.confirmPanelTitle}
      description={texts.confirmPanelSubtitle}
      confirmationText={texts.delete}
      onCancel={actions.onSwitchToDefaultPanel}
      onConfirm={actions.onDelete}
      testid="setup-place__single-delete-confirm-panel"
    />
  )
}
