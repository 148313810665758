import * as gis from './gis'

// Playground: https://codesandbox.io/s/crazy-voice-8bolb
export const isDeepEqual = (a: any, b: any): boolean => {
  if (a === b) {
    return true
  }

  // null
  if (a === null || b === null) return false

  // undefined
  if (a === undefined || b === undefined) return false

  // NaN
  if (a !== a && b !== b) return true

  // different type
  if (typeof a !== typeof b) return false

  // Boolean
  const typeOfA = typeof a
  const typeOfB = typeof b

  const isBoolA = typeOfA === 'boolean'
  const isBoolB = typeOfB === 'boolean'
  if (isBoolA && isBoolB) {
    return a === b
  }

  // Number
  const isNumberA = typeOfA === 'number'
  const isNumberB = typeOfB === 'number'
  if (isNumberA && isNumberB) {
    return a === b
  }

  // String
  const isStringA = typeOfA === 'string'
  const isStringB = typeOfB === 'string'
  if (isStringA && isStringB) {
    return a === b
  }

  // Function
  const isFunctionA = typeOfA === 'function'
  const isFunctionB = typeOfB === 'function'
  if (isFunctionA && isFunctionB) {
    return a.toString() === b.toString()
  }

  // latlng
  const isLatLngA = gis.isLatLng(a)
  const isLatLngB = gis.isLatLng(b)
  if (isLatLngA !== isLatLngB) return false
  if (isLatLngA && isLatLngB) return gis.latLngEquals(a, b)

  // array
  const isArrA = Array.isArray(a)
  const isArrB = Array.isArray(b)
  if (isArrA !== isArrB) return false
  if (isArrA && isArrB) return compareArrays(a, b)

  // date
  const isDateA = a instanceof Date
  const isDateB = b instanceof Date
  if (isDateA !== isDateB) return false
  if (isDateA && isDateB) return compareDates(a, b)

  // regex
  const isRegexpA = a instanceof RegExp
  const isRegexpB = b instanceof RegExp
  if (isRegexpA !== isRegexpB) return false
  if (isRegexpA && isRegexpB) return compareRegex(a, b)

  // map
  const isMapA = a instanceof Map
  const isMapB = b instanceof Map
  if (isMapA !== isMapB) return false
  if (isMapA && isMapB) return compareMaps(a, b)

  // set
  const isSetA = a instanceof Set
  const isSetB = b instanceof Set
  if (isSetA !== isSetB) return false
  if (isSetA && isSetB) return compareSets(a, b)

  // all the remaining cases fall under the "object" check
  if (typeof a === 'object' && typeof b === 'object') {
    return compareObjects(a, b)
  }

  if (process.env.NODE_ENV === 'development') {
    console.warn(`isDeepEqual - can not compare `, a, b, typeof a, typeof b)
  }

  // if for any reason we arrive the code has not recognized the value
  return false
}

function compareObjects(a: Record<string, unknown>, b: Record<string, unknown>) {
  const keysOfA = Object.keys(a)
  const keysOfB = Object.keys(b)

  if (keysOfA.length !== keysOfB.length) {
    return false
  }

  for (let i = 0; i < keysOfA.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(b, keysOfA[i])) {
      return false
    }
  }

  for (let i = 0; i < keysOfA.length; i++) {
    const key: string = keysOfA[i]
    if (!isDeepEqual(a[key], b[key])) {
      return false
    }
  }

  return true
}

function compareRegex(a: RegExp, b: RegExp) {
  return a.toString() === b.toString()
}

function compareDates(a: Date, b: Date) {
  return a.getTime() === b.getTime()
}

function compareArrays(a: any[], b: any[]) {
  if (a.length !== b.length) {
    return false
  }

  for (let i = 0; i < a.length; i++) {
    if (!isDeepEqual(a[i], b[i])) {
      return false
    }
  }

  return true
}

// @see https://github.com/epoberezkin/fast-deep-equal
function compareMaps(a: Map<any, any>, b: Map<any, any>) {
  if (a.size !== b.size) return false
  for (const i of a.entries()) {
    if (!b.has(i[0])) return false
  }
  for (const i of a.entries()) {
    if (!isDeepEqual(i[1], b.get(i[0]))) return false
  }
  return true
}

// @see https://github.com/epoberezkin/fast-deep-equal
function compareSets(a: Set<any>, b: Set<any>) {
  if (a.size !== b.size) return false
  for (const val of a.values()) {
    if (!b.has(val)) return false
  }
  return true
}
