import type { RouteChip } from '@/components/Navigo/components/Navigator/typings'

import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectApprovedPlans } from '@/features/domain/routeplan'
import { selectRoutesInfo } from '@/features/domain/scheduler'
import { routingSidebarAtom, setRoutingSidebar } from '@/atoms'

import { useNavigator as useCoreNavigator } from '../../../components/Navigator/hooks/useNavigator'

export function useRoutesNavigator() {
  const {
    ids,
    pagination: { page, setPage },
  } = useCoreNavigator()
  const routesInfo = useSelector(selectRoutesInfo)
  const approvedPlans = useSelector(selectApprovedPlans)

  const [selectedIds, chips] = useMemo(() => {
    const selectedRouteInfos = ids.map(id => routesInfo[id]).filter(Boolean)

    const chips = selectedRouteInfos.map<RouteChip>(routeInfo => ({
      id: routeInfo.id,
      source: routeInfo,
      type: 'route',
    }))

    return [selectedRouteInfos, chips] as const
  }, [routesInfo, ids])

  const currentRoutes = page === -1 || !selectedIds?.[page] ? selectedIds : [selectedIds[page]]

  const currentRouteIds = page === -1 || !ids?.[page] ? ids : [ids[page]]

  if (routingSidebarAtom.settings.view === 'routeDetails') {
    const extRoute = routesInfo[currentRouteIds[0]]
    const isApprovedRoute =
      !!approvedPlans[extRoute.dateAsString]?.approvedRoutes[extRoute.vehicleId]

    setRoutingSidebar(
      currentRouteIds.length === 1 && isApprovedRoute
        ? { routeId: currentRouteIds[0] }
        : { view: 'main' },
    )
  }

  useEffect(
    () => () => {
      setRoutingSidebar({ view: 'main' })
    },
    [],
  )

  return {
    page,
    chips,
    setPage,
    currentRoutes,
    currentRouteIds,
  } as const
}
