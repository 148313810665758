import styled from 'styled-components'

const IconWrapper = styled.div`
  flex: 0 0 auto;
  width: 14px;
  color: ${p => p.theme.colors.$silver};
  margin-right: 10px;
`

IconWrapper.displayName = 'IconWrapper'
export default IconWrapper
