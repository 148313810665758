import { type SVGProps } from 'react'
import { clsx } from '@/utils'

type Props = SVGProps<SVGSVGElement>

export function Flag(props: Props) {
  const { className, ...rest } = props

  return (
    <svg
      className={clsx(className, 'o-scheduler-svg-icon')}
      focusable="false"
      aria-hidden="true"
      {...rest}
      viewBox="0 0 32 32"
    >
      <path d="M5 2.46875V29h2v-8.34375L27.03125 12z" />
    </svg>
  )
}
