import { useState } from 'react'
import { useCommonValidations } from '../../../commonValidations'

/**
 * That hook produces a configuration for the TodayNotification form
 */
export function useTodayNotificationConfig() {
  const commonValidations = useCommonValidations()

  const [config] = useState(() => ({ validations: commonValidations }))

  return config
}
