import type { AsyncThunkApiConfig } from '@/store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { resetAllCrudSelection, resetAllMainSelection } from '@/atoms'

import { RejectError } from '../../typings'

type Params = {
  territoryId: string
} & (
  | { target: 'simulation'; simulationId: string }
  | { target: 'archived'; archivedDate: string }
  | { target: 'ops' }
)

export const changeTerritory = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Params,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('domain/territory/change', async (params: Params, thunkApi) => {
  try {
    // Reset selections before performing a changeTerritory
    resetAllMainSelection()
    resetAllCrudSelection()

    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/territory/change', {
      category: 'rpc',
      type: 'rpc/territory/change',
      ...params,
    })

    if (result.type === 'rpc-failure') {
      // TODO: add a proper localized message
      // TODO: trigger the journal
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    // TODO: add a proper localized message
    // TODO: trigger the journal
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: 'Change Territory Failed',
      error,
    })
  }
})
