import {
  FlexRow,
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadonlyFieldRow,
} from '@/forms-legacy'

import { useTexts } from '../useTexts'

interface Props {
  driverBehaviors: uui.domain.client.gps.wwgps.DriverBehaviorSettings
}

export function BrakingList(props: Props) {
  const {
    driverBehaviors: { hardBraking, harshBraking, severeBraking },
  } = props

  const texts = useTexts()

  const galsText = texts.gals
  const notSetText = texts.notSet

  const hardValue: string = hardBraking.threshold
    ? `${hardBraking.threshold || ''} ${galsText}`
    : notSetText

  const harshValue: string = harshBraking.threshold
    ? `${harshBraking.threshold || ''} ${galsText}`
    : notSetText

  const severeValue: string = severeBraking.threshold
    ? `${severeBraking.threshold || ''} ${galsText}`
    : notSetText

  return (
    <>
      <ReadonlyLabel primary>{texts.braking}</ReadonlyLabel>
      <FlexRow justifyContent="space-between">
        <ReadonlyBlock aThird>
          <ReadonlyLabel>{texts.hard}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{hardValue}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
        <ReadonlyBlock aThird>
          <ReadonlyLabel>{texts.harsh}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{harshValue}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
        <ReadonlyBlock aThird>
          <ReadonlyLabel>{texts.severe}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{severeValue}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      </FlexRow>
    </>
  )
}
