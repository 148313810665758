import type { OrderSteps } from '../typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useMainSelection, usePagination } from '@/atoms'
import { selectOrderSteps } from '@/features/domain/order'

/**
 * This hook returns the orders currently selected by the user trough the main selection.
 * If the Navigo is active it also filters the results based on the Navigo paginator
 */
export const useCurrentOrderSteps = (): OrderSteps => {
  const { page } = usePagination('navigo')
  const orders = useSelector(selectOrderSteps)
  const [selection] = useMainSelection('orderSteps')

  return useMemo(() => {
    const selectedOrderSteps = selection.reduce<
      Record<string, uui.domain.client.rm.ExtendedOrderStep>
    >((acc, id) => {
      if (orders[id]) {
        acc[id] = orders[id]
      }
      return acc
    }, {})

    const currentOrderStep = selectedOrderSteps[selection[page]]

    if (page === -1 || !currentOrderStep)
      return { orderSteps: selectedOrderSteps, ids: [...selection] }

    return { orderSteps: { [currentOrderStep.id]: currentOrderStep }, ids: [...selection] }
  }, [orders, selection, page])
}
