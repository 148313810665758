import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    from: translate({ id: 'global.from' }),
    to: translate({ id: 'global.to' }),
    notSet: translate({ id: 'global.notSet' }),
    half: translate({ id: 'vehicles.form.exceptions.vehicle.speed.step.half' }),
    normal: translate({ id: 'vehicles.form.exceptions.vehicle.speed.step.normal' }),
    double: translate({ id: 'vehicles.form.exceptions.vehicle.speed.step.double' }),
    newException: translate({ id: 'vehicles.form.exceptions.newException' }),
    copySettings: translate({ id: 'vehicles.form.exceptions.copySettings' }),
    deleteException: translate({ id: 'vehicles.form.exceptions.deleteException' }),
    editException: (normalDay: boolean) =>
      translate({
        id: normalDay
          ? 'vehicles.form.exceptions.editException.normalDay'
          : 'vehicles.form.exceptions.editException',
      }),
  }))

  return api
}
