import { useSnapshot, snapshot, proxy, subscribe } from 'valtio'
import { devtools } from 'valtio/utils'
import { getDevPreference } from '@/dev'

export type EditingStateContext =
  | 'none'
  // Forms
  | 'region'
  | 'device'
  | 'order'
  | 'depot'
  | 'driver'
  | 'segment'
  | 'driversAssignment'
  | 'vehicle'
  | 'device'
  | 'geofence'
  | 'place'
  | 'trafficArea'
  | 'trafficProfile'
  | 'alert'
  // special sections
  | 'comparisonTool'
  | 'simulation'
  | 'customerNotifications'
  // TODO: add sub-settings state if needed or just `settings`
  | 'settings'
  // TODO: add sub-settings state if needed or just `setup`
  | 'setup'
  | 'route'

type EditingState = {
  editing: boolean
  ctx: EditingStateContext
  editingEntities: string[]
}

const editingState = proxy<{ state: EditingState }>({
  state: {
    ctx: 'none',
    editing: false,
    editingEntities: [],
  },
})

// ------------------------------------
// Read functions
// ------------------------------------

export function getEditingState(immutable: boolean = true) {
  return immutable ? snapshot(editingState.state) : editingState.state
}

// ------------------------------------
// Subscribe functions
// ------------------------------------

type Subscriber = (state: workwave.DeepReadonly<EditingState>) => void

export function subscribeToEditingState(subscriber: Subscriber) {
  return subscribe(editingState, () => {
    subscriber(snapshot(editingState.state))
  })
}

// ------------------------------------
// Write functions
// ------------------------------------

type SetEditingState = (prev: workwave.DeepReadonly<EditingState>) => EditingState

type SetEditingStateParam = SetEditingState | Partial<EditingState> | 'reset'

export function setEditingState(valueOrFunc: SetEditingStateParam) {
  if (valueOrFunc === 'reset') return resetEditingState()

  if (typeof valueOrFunc === 'function') {
    editingState.state = valueOrFunc(snapshot(editingState.state))
  } else {
    Object.assign(editingState.state, valueOrFunc)
  }

  return snapshot(editingState)
}

export function resetEditingState() {
  editingState.state.ctx = 'none'
  editingState.state.editing = false
  editingState.state.editingEntities = []

  return editingState
}

function setEditing(ctx: EditingStateContext, editingEntities: string[]) {
  editingState.state.ctx = ctx
  editingState.state.editing = true
  editingState.state.editingEntities = editingEntities
}

// ------------------------------------
// React Hooks
// ------------------------------------

export function useEditingState() {
  return [useSnapshot(editingState.state), setEditingState] as const
}

export function useResetEditingState() {
  return resetEditingState
}

export function useIsEditing() {
  return useSnapshot(editingState.state).editing
}

/**
 * Generic Helper allowing to start and stop an editing session.
 *
 * @param ctx The target Editing context
 */
export function useEditingStateWithCtx(ctx: EditingStateContext) {
  const state = useSnapshot(editingState.state)

  const editing = state.editing && state.ctx === ctx
  const editingEntities = editing ? state.editingEntities : []

  return {
    editing,
    editingEntities,
    activeEditingContext: state.ctx,
    resetEditing: resetEditingState,
    setEditing: (entities: string[]) => setEditing(ctx, entities),
  } as const
}

if (getDevPreference('editingStateDevTools') === 'enable') {
  devtools(editingState, 'editingState')
}
