import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Pedestrian(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 25 25">
        <path d="M14.41667 4c.91093 0 1.65.7449 1.65 1.66304 0 .91814-.73907 1.66305-1.65 1.66305-.91094 0-1.65-.7449-1.65-1.66305 0-.91814.73906-1.66304 1.65-1.66304zM12.844 8.06522H13.68333c.70899 0 1.28334.57889 1.28334 1.29348 0 .12559-.01862.24974-.053.36956v.00144l-1.047 4.25 1.36784 2.34588.7319 3.33042a.93015.93015 0 0 1 .10026.42009c0 .5096-.41107.92391-.91667.92391-.3552 0-.6789-.20788-.8293-.5327l-.00286.00145-.0043-.01732c-.05013-.11405-.07734-.23531-.0802-.35946l-.6918-3.12832-1.68581-1.83627c-.12175-.09672-.2306-.21077-.3151-.34214l-.0043-.00433-.00143-.00433c-.14753-.22953-.2349-.50382-.2349-.79832 0-.1357.02435-.26418.0573-.38833v-.00722s.45116-2.10335.69179-3.23225l-1.67292.96433-.93385 2.10912c-.08308.32482-.37526.55435-.70899.55435-.40534 0-.73333-.33059-.73333-.73913 0-.14003.0401-.27717.11458-.39555l1.03555-2.46136.00286-.00578c.05157-.12848.1375-.23964.24922-.32048l.0043-.00433c.01719-.01155.03438-.0231.053-.03465l2.49218-1.64716c.25638-.19777.57005-.3046.89232-.3046zm2.84596 1.41474l.98829 1.41041 1.82903.54136v.00288c.28503.10106.49271.37246.49271.69582 0 .40855-.328.73914-.73333.73914-.0931 0-.1819-.01877-.26355-.05197v.00144l-.02148-.00722h-.00143l-2.05677-.59332-.68607-.76512.43971-1.80452c.0086-.05486.01003-.11116.0129-.1689zm-4.59479 5.93037l1.49531 1.5028-.32799 1.23862-1.82187 2.39351-.0043-.00144C10.27734 20.81522 9.98659 21 9.65 21c-.50703 0-.91667-.41287-.91667-.92391 0-.25697.10456-.48939.2707-.65685l1.7474-2.2708.34375-1.73811z" />
      </svg>
    </IconContainer>
  )
}
