import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useReadOnly } from '@/hooks'
import { selectTerritory } from '@/features/domain/territory'
import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

type ModalStatus = 'pending' | 'ready'
interface ModalData {
  id: string
  name: string
}

export const modalId = 'deleteTerritory'
export const useController = () => useModalController<ModalData, ModalStatus>(modalId)

export const useConfigureController = () => {
  const close = useCloseModal()
  const { readonly } = useReadOnly()
  const { id, name } = useSelector(selectTerritory)

  // INITIALIZATION

  const [options] = useState(() => ({
    createInitialState: () => ({
      status: 'ready' as ModalStatus,
      disabled: false,
      invalid: false,
      data: { id, name },
      close,
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalStatus>(modalId, options)
  const { update, status } = ctrl

  // EFFECTS

  useEffect(() => {
    switch (readonly) {
      case true:
        update({ status: 'pending' })
        break

      case false:
        if (status !== 'pending') return

        update({ status: 'ready' })
        break
    }
  }, [readonly, status, update])

  return ctrl
}
