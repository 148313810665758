import {
  SecondaryColumnHeaderActionsRow,
  SecondaryColumnHeaderActionButton,
  SecondaryColumnHeaderActionsSeparator,
} from '@/forms-legacy'
import { ArrowRoundedUpEdge, Trash, Edit } from '@/icons'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'

interface Props {
  roadSegment: uui.domain.client.rm.SegmentException
}

export function Actions(props: Props) {
  const { roadSegment } = props
  const actions = useActions(roadSegment)
  const texts = useTexts()

  return (
    <SecondaryColumnHeaderActionsRow>
      <SecondaryColumnHeaderActionButton
        label={texts.exportKML}
        icon={<ArrowRoundedUpEdge />}
        onClick={actions.onExport}
        testid="roadSegment-exportKML"
      />

      <SecondaryColumnHeaderActionsSeparator />

      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.delete}
            icon={<Trash />}
            onClick={actions.onDelete}
            disabled={preventEditing}
            testid="roadSegment-delete"
          />
        )}
      />
      <ReadOnlyTooltip
        render={preventEditing => (
          <SecondaryColumnHeaderActionButton
            label={texts.edit}
            icon={<Edit />}
            onClick={actions.onEdit}
            disabled={preventEditing}
            testid="roadSegment-edit"
          />
        )}
      />
    </SecondaryColumnHeaderActionsRow>
  )
}
