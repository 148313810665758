import { theme } from '@/local/components'

interface Rows {
  row: string
  optRow?: string
}

export const getBackgroundColor = (
  hasBeenExecuted: boolean = false,
  isUnassigned: boolean = false,
  color: string,
): string => {
  if (hasBeenExecuted) return theme.colors.$shadyLady
  if (isUnassigned) return theme.colors.$pureWhite

  return color
}

export const getRows = (label: string = ''): Rows => {
  const isMultiRow: boolean = label.indexOf('.') > -1
  const [row = '', optRow = ''] = label.split('.')
  return {
    row: isMultiRow ? `${row}.` : row,
    optRow: isMultiRow ? optRow : undefined,
  }
}
