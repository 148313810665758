import type { PermissionData } from '../types'

export function computeUnassignPermission(permissionData: PermissionData) {
  if (permissionData.notEmptyRoutesCount === 0) return 'hidden'

  if (permissionData.someRoutesLocked) {
    return 'disabledAllRoutesLocked'
  }

  return 'enabled'
}
