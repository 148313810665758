import type { ReactChild, ReactFragment } from 'react'

import { Box } from '@mui/material'

interface Props {
  className?: string
  paddingTop?: number | string
  paddingLeft?: number | string
  paddingRight?: number | string
  paddingBottom?: number | string
  children: ReactChild | ReactFragment
}

export function ViewActions(props: Props) {
  const {
    children,
    className = '',
    paddingTop = 0,
    paddingLeft = 0,
    paddingRight = 0,
    paddingBottom = 4,
  } = props

  return (
    <Box
      display="flex"
      className={className}
      paddingTop={paddingTop}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
    >
      {children}
    </Box>
  )
}
