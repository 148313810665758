import { ModalContent } from '@/components/Modal'

import { useConfigureController } from './hooks/useController'

import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { Body } from './components/Body'

export function Content() {
  const { ready } = useConfigureController()

  if (!ready) return null

  return (
    <ModalContent header={<Header />} footer={<Footer />}>
      <Body />
    </ModalContent>
  )
}
