import type { PartialStoreState } from '../typings'

export const selectRoadSegments = (state: PartialStoreState) => {
  // HINT: default is hard coded until we handle multiple routing profiles
  const defaultRoutingProfile = state.domain.publicData.domain.rm.routing.profiles.default
  if (!defaultRoutingProfile) {
    throw new Error('default routing profile is missing')
  }

  return defaultRoutingProfile.segmentExceptions.reduce<
    Record<string, uui.domain.client.rm.SegmentException>
  >((acc, segmentException) => {
    acc[segmentException.id] = segmentException
    return acc
  }, {})
}
