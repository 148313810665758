import { useCallback } from 'react'

import { useAppDispatch } from '@/store'
import { useNotification, useIsUnmounted } from '@/hooks'
import { renameTerritory } from '@/features/domain/territory'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export function useControllerActions() {
  const texts = useTexts()
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const isUnmounted = useIsUnmounted()
  const {
    close,
    updateData,
    data: { id, newName },
  } = useController()

  const onNameChange = useCallback((newName: string) => updateData({ newName }), [updateData])

  const onConfirm = useCallback(async () => {
    if (!id) return

    try {
      const result = await dispatch(renameTerritory({ territoryId: id, name: newName }))

      if (!renameTerritory.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      if (isUnmounted()) return

      close?.()
    } catch (e) {
      toast.error(texts.genericError)
    }
  }, [newName, id, texts, toast, close, dispatch, isUnmounted])

  return { onConfirm, onNameChange }
}
