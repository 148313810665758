import styled from 'styled-components'

import { fieldContainerHeight } from '../../../constants'

const styledComponent = styled.div`
  position: absolute;
  top: ${fieldContainerHeight + 5}px;
  left: 0;
  width: 100%;
  z-index: 10;

  background: ${p => p.theme.colors.$pureWhite};

  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 ${p => p.theme.colors.$nightRider};
`

styledComponent.displayName = 'AssetListContainer'
export default styledComponent
