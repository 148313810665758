import { type SchedulerPro } from '@bryntum/schedulerpro'
import {
  setSchedulerTimeSpan,
  setSchedulerTimeSpanFirstVisibleRow,
  resetSchedulerTimeSpanFirstVisibleRow,
} from '../../../../atoms/schedulerLastTimeSpan'

export function storeLastVisibleTimeSpan(scheduler: SchedulerPro) {
  // Store the current visible date range and scroll position
  setSchedulerTimeSpan(
    scheduler.visibleDateRange.startDate as Date,
    scheduler.visibleDateRange.endDate as Date,
  )

  if (scheduler.visibleResources.first) {
    setSchedulerTimeSpanFirstVisibleRow(scheduler.visibleResources.first.id as string)
  } else {
    resetSchedulerTimeSpanFirstVisibleRow()
  }
}
