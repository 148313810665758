import styled from 'styled-components'

import { IconButton } from '@/local/components'

export const CircleIconButton = styled(IconButton)`
  height: 18px;
  display: flex;
  min-width: 18px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.colors.$shadyLady};
`

CircleIconButton.displayName = 'CircleIconButton'
