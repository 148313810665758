import { memo } from 'react'

import { Search } from '@/icons'

import renderText from '../../../intl'
import { LocationEditorText } from '../../../typings'

import { Props } from '../typings'
import EmptyStateContainer from './elements/EmptyStateContainer'
import EmptyStateIcon from './elements/EmptyStateIcon'
import EmptyStateTitle from './elements/EmptyStateTitle'
import EmptyStateText from './elements/EmptyStateText'

const EmptyState = (props: Props) => {
  return (
    <EmptyStateContainer>
      <EmptyStateIcon>
        <Search />
      </EmptyStateIcon>

      <EmptyStateTitle>{renderText(LocationEditorText.notFoundTitle)}</EmptyStateTitle>

      <EmptyStateText>{renderText(LocationEditorText.notFoundText, props)}</EmptyStateText>
    </EmptyStateContainer>
  )
}

export default memo(EmptyState)
