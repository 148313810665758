import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { isDeepEqual } from '@/server-data'

import { type Selection, type Column } from '../../../types'

type ColumnsById = Record<string, Column>

type State = {
  columnsById: ColumnsById
  columns: Column[]
  canRender: boolean
}

export function useShuffler(
  providedColumns: Column[],
  onSelectionChange: Selection['onSelectionChange'],
) {
  const [columns, setColumns] = useState<Column[]>(() => providedColumns)

  const stateRef = useRef<State>({
    columnsById: {},
    columns,
    canRender: true,
  })

  useEffect(() => {
    const columnsById = columns.reduce<Record<string, Column>>((acc, card) => {
      acc[card.id] = card
      return acc
    }, {})

    stateRef.current.columns = columns
    stateRef.current.columnsById = columnsById
  }, [columns])

  // when columns get shuffled update the selection
  useEffect(() => {
    onSelectionChange(columns.map(c => c.id))
  }, [columns, onSelectionChange])

  useLayoutEffect(() => {
    // prev can be already equal to the new columns
    // try to avoid unnecessary renders
    setColumns(prev => {
      const equals = isDeepEqual(prev, providedColumns)

      if (equals) return prev
      return providedColumns
    })
  }, [providedColumns])

  const onMoveColumn = useCallback(
    (id: string, afterId: string) => {
      // canRender will avoid to call the onMoveColumn too much
      // it will call it maximum 1 time for each requestAnimationFrame
      if (!stateRef.current.canRender) {
        requestAnimationFrame(() => {
          stateRef.current.canRender = true
        })
        return
      }

      stateRef.current.canRender = false

      const columnsById = stateRef.current.columnsById as Record<string, Column>
      const columns = stateRef.current.columns

      const column = columnsById[id]
      const columnIndex = columns.indexOf(column)

      const afterColumn = columnsById[afterId]
      const afterIndex = columns.indexOf(afterColumn)

      // setColumns is much more performant than change the selection
      // and wait for all the hooks to recompute the new collection
      setColumns(prev => {
        const next = [...prev]
        const [removed] = next.splice(columnIndex, 1)
        next.splice(afterIndex, 0, removed)

        return next
      })
    },
    [setColumns],
  )

  return {
    onMoveColumn,
    columns,
  }
}
