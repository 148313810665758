import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function Tortoise(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 13 9">
        <path d="M10.62132.01654c-.11167.01655-.21093.03723-.2978.08272-1.11258.63489-.89958 1.85295-1.58823 2.64706-1.48276 1.85294-4.28906 2.8125-7.14705 3.0772C1.00506 5.8773.2647 6.29918 0 6.88236c.52941-.15923 1.37937-.04963 1.8033.21508 0 .31847-1.05468.62454-.57904 1.20772.37018.21093.89339.22541 1.4228.06617.47564-.31847.53355-.53561.74448-.95956 2.27689.47565 3.85478-.04963 3.85478-.04963s.16958.62454.64522.94302c.52942.2647 1.21186.21093 1.6875 0C10.0547 7.8295 9 7.571 9 6.61765c0-1.429.42394-2.43199 1.58824-3.49081.37017.63488 1.429.53355 1.85294-.04963.15923-.26471.2647-.54182.2647-.8603 0-1.66682-1.29871-2.31204-2.08456-2.20037zm-6.1875.51287c-.37637.01861-.78998.08686-1.25735.21508C.42394 1.59237.46944 3.80515.89338 4.33456c0 0-.63488.2647-.2647.79412.31847.37017 2.02458.11167 3.50735-.36397C5.6188 4.34076 6.88856 3.65625 7.5772 3.12684c.63488-.47564.35776-1.32974-.33089-1.22427-.34329-.64522-1.1767-1.45795-2.8125-1.37316zm7.21324.69485c.21094 0 .36397.15304.36397.36398 0 .21093-.15303.36397-.36397.36397-.21094 0-.36397-.15304-.36397-.36397 0-.21094.15303-.36398.36397-.36398z" />
      </svg>
    </IconContainer>
  )
}
