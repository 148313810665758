import { Typography, Stack } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { ViewActionButton } from '@/formUi'

import { useCopyStartEndRouteConfigurationModal } from '../../../modals/CopyStartEndRouteSettingsModal'

import { useTexts } from './useTexts'

interface Props {
  singleTerritory: boolean
  startEndRouteActive: boolean
}

export function StartEndRoute(props: Props) {
  const { singleTerritory, startEndRouteActive } = props
  const texts = useTexts()

  const { show: showModal, Modal: CopyStartEndRouteConfigurationModal } =
    useCopyStartEndRouteConfigurationModal()

  return (
    <>
      <Stack spacing={2} data-testid="driver-activity-start-end-route">
        <div>
          <Typography variant="h6">{texts.header.title}</Typography>
          <Typography variant="caption">{texts.header.description}</Typography>
        </div>

        <Tooltip placement="top" title={texts.copyTooltip.title} disabled={!singleTerritory}>
          <ViewActionButton
            disabled={singleTerritory}
            onClick={showModal}
            label={texts.copy}
            testId="driver-activity-start-end-route_copy-btn"
          />
        </Tooltip>

        <div>
          <Typography variant="body1">{texts.collect}</Typography>
          <Typography variant="caption" color="textSecondary">
            {startEndRouteActive ? texts.yes : texts.no}
          </Typography>
        </div>
      </Stack>
      <CopyStartEndRouteConfigurationModal />
    </>
  )
}
