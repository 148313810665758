import type { ReactElement } from 'react'
import type { FormErrors, FormFields } from './formFields'

import { FormProvider } from '@workwave-tidal/tidal/form-ui/layout/FormProvider'

import { useController } from '../../hooks/useController'

import { useFormConfig } from './hooks/useFormConfig'
import { formFields } from './formFields'

interface Props {
  children: ReactElement
}

export function FeedbackFormProvider(props: Props) {
  const {
    data: { feedback },
  } = useController()

  const formConfig = useFormConfig(feedback)

  return (
    <FormProvider<FormFields, FormErrors>
      name="give-feedback-form"
      fields={formFields}
      verbose={false}
      config={formConfig}
    >
      {props.children}
    </FormProvider>
  )
}
