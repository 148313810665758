import { ModalFooter, ModalFooterButton } from '@/components/Modal'
import { useActions } from '../hooks/useActions'

import { useTexts } from '../useTexts'

export function Footer() {
  const { selectImportedOrders } = useActions()
  const texts = useTexts()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton variant="contained" onClick={selectImportedOrders}>
          {texts.ok}
        </ModalFooterButton>
      }
    />
  )
}
