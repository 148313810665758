import { PureComponent } from 'react'

import { Props } from './typings'

import { PodNotes } from './elements/PodNotes'
import { PodPictures } from './elements/PodPictures'
import { PodSignatures } from './elements/PodSignatures'
import { PodAudios } from './elements/PodAudios'
import PodBarcodes from './elements/PodBarcodes'

export default class Pods extends PureComponent<Props> {
  render() {
    const { allowBarcodes } = this.props

    return (
      <>
        <PodNotes {...this.props} />
        <PodPictures {...this.props} />
        <PodSignatures {...this.props} />
        <PodAudios {...this.props} />
        {allowBarcodes && <PodBarcodes {...this.props} />}
      </>
    )
  }
}
