import { CSSProperties } from 'styled-components'

type Props = {
  size: number
  color?: string
}

// reference: https://codesandbox.io/s/busy-smoke-nqggks?file=/src/VanillaCircularProgress.tsx:159-163
export function Spinner(props: Props) {
  const { size, color } = props

  const sizeStyle = { '--size': `${size}px` } as CSSProperties
  const colorStyle = color ? ({ '--color': color } as CSSProperties) : {}

  return (
    <div className="o-scheduler-spinner-container" style={sizeStyle}>
      <svg className="o-scheduler-spinner-svg" viewBox="0 0 40 40">
        <circle className="o-scheduler-spinner-circle" style={colorStyle} cx="20" cy="20" r="18" />
      </svg>
    </div>
  )
}
